export const ShuffleIcon = (props: { className?: string }): JSX.Element => {
  return (
    <svg
      className={props.className ?? 'w-12.5 h-12.5 fill-current'}
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 50 50'
    >
      <path
        fill='white'
        d='M16.269 12.056V10.89l2.848 2.71a2.207 2.207 0 003.086-.05 2.123 2.123 0 00-.052-3.039l-6.208-5.908a2.183 2.183 0 00-1.857-1.032c-.02 0-.04.006-.06.007a2.188 2.188 0 00-1.592.623l-6.438 6.336a2.124 2.124 0 000 3.038 2.207 2.207 0 003.087 0l2.82-2.777v1.26c0 7.462 6.51 12.704 12.805 17.774 5.762 4.64 11.204 9.022 11.204 14.448 0 1.186.978 2.149 2.183 2.149 1.205 0 2.183-.963 2.183-2.149 0-7.463-6.51-12.705-12.805-17.775-5.762-4.639-11.204-9.022-11.204-14.449z'
      ></path>
      <path
        fill='white'
        d='M34.057 4.602l-6.21 5.91a2.124 2.124 0 00-.05 3.038c.838.853 2.22.875 3.086.05l2.847-2.71v1.166c0 3.012-1.622 5.908-5.26 9.39a2.123 2.123 0 00-.042 3.038c.842.85 2.222.869 3.087.042 2.825-2.705 6.58-6.94 6.58-12.47v-1.259l2.822 2.778a2.207 2.207 0 003.087 0 2.12 2.12 0 000-3.038l-6.438-6.336a2.19 2.19 0 00-1.592-.623c-.021-.001-.04-.006-.061-.006a2.18 2.18 0 00-1.856 1.03zM14.086 44.28c0-.543.055-1.089.163-1.624.555-2.762 2.532-5.53 6.409-8.973a2.126 2.126 0 00.158-3.034 2.208 2.208 0 00-3.083-.155c-3.111 2.764-6.805 6.549-7.765 11.328a12.405 12.405 0 00-.247 2.458c0 1.186.978 2.149 2.183 2.149 1.204 0 2.182-.962 2.182-2.149z'
      ></path>
    </svg>
  );
};
