import React, { type ReactNode, useContext, useEffect, useMemo } from 'react';
import { proxy, useSnapshot } from 'valtio';

import { useInstance } from '../../hooks/useInstance';
import { ValtioUtils } from '../../utils/valtio';
import {
  getSoundEffectMap,
  type SoundEffectKeys,
  type SoundEffectMap,
} from './consts';
import { type SoundEffect, type SoundEffectControl } from './types';
import { useRawSoundEffect } from './useSoundEffect';

type State = {
  soundEffects: SoundEffectMap;
};

function initialState(): State {
  return { soundEffects: getSoundEffectMap() };
}

class SoundEffectAPI {
  constructor(private state: State) {}
  updateVolume(key: SoundEffectKeys, vol: number): void {
    if (!(key in this.state.soundEffects)) return;
    this.state.soundEffects[key].volume = vol;
  }
  reset(): void {
    ValtioUtils.reset(this.state, initialState());
  }
}

type SFXContext = {
  state: State;
  api: SoundEffectAPI;
};

const Context = React.createContext<SFXContext | null>(null);

function useSFXContext(): SFXContext {
  const ctx = useContext(Context);
  if (!ctx) {
    throw new Error('SFXContext is not in the tree!');
  }
  return ctx;
}

export function useSoundEffectAPI(): SoundEffectAPI {
  return useSFXContext().api;
}

function useSoundEffectFile(key: SoundEffectKeys): SoundEffect {
  const { state } = useSFXContext();
  const soundEffects = useSnapshot(state).soundEffects;
  return soundEffects[key];
}

export function useSoundEffectFiles(): State['soundEffects'] {
  const { state } = useSFXContext();
  return useSnapshot(state).soundEffects;
}

export function useSoundEffect(key: SoundEffectKeys): SoundEffectControl {
  const sfx = useSoundEffectFile(key);
  return useRawSoundEffect(
    sfx.url,
    key,
    sfx.volume,
    sfx.delayMs,
    sfx.loop,
    sfx.echoCanceled
  );
}

export function SFXProvider(props: { children?: ReactNode }): JSX.Element {
  const state = useInstance(() => proxy<State>(initialState()));
  const api = useMemo(() => new SoundEffectAPI(state), [state]);

  useEffect(() => {
    return () => {
      api.reset();
    };
  }, [api]);

  const ctx: SFXContext = useMemo(
    () => ({
      state,
      api,
    }),
    [api, state]
  );

  return <Context.Provider value={ctx}>{props.children}</Context.Provider>;
}
