// @ts-check

// NOTE: avoid putting too much in here since it's JS and very cumbersome to
// typecheck.

/**
 * @typedef {{
 *   translateX?: string;
 *   translateY?: string;
 *   translatePostX?: string;
 *   translatePostY?: string;
 *   scaleX?: number;
 *   scaleY?: number;
 *   rotateZ?: number;
 *   rotateA?: string;
 * }} Transform3dProps
 **/

/**
 * The CSS `transform` property is very powerful, and only recently can
 * transform functions be specified separately. This utility prevents
 * repetitions value passing, and composes well with tailwind's existing and
 * augmented CSS Custom Properties (variables).
 *
 * NOTE: these are performed from right to left:
 * https://codepen.io/bali_balo/post/chaining-transforms#right-to-left-1
 *
 * translate-post-[x|y]: allows for "pulling" the element to its relative center
 * independent of the translation. For example, `--tw-translate-x: 10px`, but
 * then additionally ensure the element is centered on the destination point
 * using `--tw-translate-post-x: -50%`.
 *
 * See tailwind.config.js for the default definition of the properties
 * themselves.
 *
 *
 * @param {Transform3dProps} props
 */
const xform = function xform(props = {}) {
  return [
    `translate3d(${props.translatePostX ?? 'var(--tw-translate-post-x, 0)'}, ${
      props.translatePostX ?? 'var(--tw-translate-post-y, 0)'
    }, var(--tw-translate-post-z, 0))`,
    `translate3d(${props.translateX ?? 'var(--tw-translate-x, 0)'}, ${
      props.translateY ?? 'var(--tw-translate-y, 0)'
    }, var(--tw-translate-z, 0))`,
    `skewX(var(--tw-skew-x, 0))`,
    `skewY(var(--tw-skew-y, 0))`,
    `scale3d(${props.scaleX ?? 'var(--tw-scale-x, 1)'}, ${
      props.scaleY ?? 'var(--tw-scale-y, 1)'
    }, var(--tw-scale-z, 1))`,
    `rotate3d(var(--tw-rotate-x, 0), var(--tw-rotate-y, 0), ${
      props.rotateZ ?? 'var(--tw-rotate-z, 0)'
    }, ${props.rotateA ?? 'var(--tw-rotate-a, 0)'})`,
  ].join(' ');
};

export { xform };
