import { ProfileIndex } from '@lp-lib/crowd-frames-schema';

import { TrackState } from '../../services/webrtc';
import { type Participant } from '../../types';
import { CrowdFramesAvatar } from '../CrowdFrames';
import { Loading } from '../Loading';
import {
  useParticipantByClientId,
  useParticipantFlag,
  useParticipantSkim,
} from '../Player';

function CrowdFrames(props: {
  clientId: string;
  showLoadingIndicator?: boolean;
}): JSX.Element | null {
  const { showLoadingIndicator } = props;
  // TODO: do this deeper in the CrowdFramesAvatar
  const participant = useParticipantByClientId(props.clientId);
  return (
    <div className='relative flex-grow-0 flex-shrink-0 w-full h-full'>
      {!!showLoadingIndicator && (
        <div className='w-full h-full bg-black opacity-40 absolute z-15 flex items-center justify-center'>
          <Loading text='' imgClassName='w-1/4 h-1/4' />
        </div>
      )}
      {participant && (
        <CrowdFramesAvatar
          profileIndex={ProfileIndex.wh100x100fps8}
          enablePointerEvents={false}
          participant={participant}
          reflectOnStageStatus={false}
          renderFrameOnStage={true}
          roundedClassname='rounded-none'
        />
      )}
    </div>
  );
}

type VideoTrackStateViewProps = {
  videoTrackState: TrackState | null;
  clientId: Participant['clientId'];
  showErrorLabel?: boolean;
  showUsername?: boolean;
  showLoadingIndicator?: boolean;
};

export function VideoTrackStateView({
  videoTrackState,
  clientId,
  showErrorLabel,
  showUsername,
  showLoadingIndicator,
}: VideoTrackStateViewProps): JSX.Element {
  const username = useParticipantSkim(clientId, 'username');
  const video = useParticipantFlag(clientId, 'video');
  const hasCamera = useParticipantFlag(clientId, 'hasCamera');
  const showLoading =
    video &&
    hasCamera !== false &&
    (videoTrackState === null || videoTrackState === TrackState.Subscribed);

  return (
    <div
      className={`w-full h-full absolute z-5 left-0 top-0 flex items-center justify-center ${
        showLoading ? 'bg-black' : 'bg-transparent'
      }`}
    >
      {showLoading && (
        <div className='w-full h-full flex items-center justify-center relative'>
          <CrowdFrames
            clientId={clientId}
            showLoadingIndicator={showLoadingIndicator}
          />
          {showUsername && (
            <div className='absolute bottom-2 text-white text-2xs font-bold'>
              {username}
            </div>
          )}
        </div>
      )}
      {showErrorLabel && videoTrackState === TrackState.Unknown && (
        <div className='text-gray-500 text-[0.8vw] text-center'>
          Load Failed
        </div>
      )}
      {showErrorLabel && videoTrackState === TrackState.Disconnected && (
        <div className='text-gray-500 text-[0.8vw] text-center'>
          Video Disconnected
        </div>
      )}
    </div>
  );
}

export function Reconnecting(props: {
  participant: Participant;
  showDetail: boolean;
}): JSX.Element {
  const { participant, showDetail } = props;
  return (
    <div className='w-full h-full absolute left-0 top-0 z-15 bg-black flex flex-col items-center justify-center'>
      <Loading
        text=''
        imgClassName='w-full h-full'
        containerClassName='w-2/5 h-2/5'
      />
      {showDetail && <div className='text-3xs font-bold'>Reconnecting</div>}
      {showDetail && (
        <div
          className='w-2/3 absolute bottom-2 text-white text-3xs text-center font-bold truncate'
          title={participant.username}
        >
          {participant.username}
        </div>
      )}
    </div>
  );
}

export function VideoSourceCrowdFrames({
  clientId,
  visible,
}: {
  clientId: Participant['clientId'];
  visible: boolean;
}): JSX.Element {
  return (
    <div
      className={`w-full h-full absolute z-5 inset-0 ${
        visible ? 'visible' : 'invisible'
      }`}
    >
      <CrowdFrames clientId={clientId} />
    </div>
  );
}
