import logger from '../logger/logger';

class SignalOwner<S> extends Set<ISignalRegistration<S>> {}

interface ISignalRegistration<S> {
  name: S;
  before?: () => Promise<void>;
  after?: () => Promise<void>;
}

interface ISignalManager<S> {
  connect(signal: ISignalRegistration<S>): () => void;
  disconnect(signal: ISignalRegistration<S>): void;
  fire(name: S, firingPhase: 'before' | 'after'): Promise<void>;
}

const log = logger.scoped('signal-manager');

/**
 * A Signal is a named event emitter, popularized by QT. This abstract class
 * auto initializes the provided named signals when used in a concrete
 * implementation. It includes a signal firing phase, before|after, as the
 * primary interface. Listeners can therefore choose whether they wish to be
 * notified before or after a particular lifecycle or signal has been reached in
 * the code. It's up to the SignalManager owner to determine what before|after
 * imply. There is no "during" phase.
 */
export abstract class SignalManager<S extends string>
  implements ISignalManager<S>
{
  protected stages: { [K in S]: SignalOwner<K> };

  /**
   *
   * @param subject A useful name for debugging and log messages.
   * @param names An array of signal names to initialize. These are effectively
   * the named events that this manager can fire.
   */
  constructor(public readonly subject: string, names: readonly S[]) {
    const stages = [];
    for (const n of names) {
      stages.push([n, new Set()]);
    }
    this.stages = Object.fromEntries(stages);
  }

  /**
   * Listen for a specific signal. The return function will disconnect. The
   * registration uses referential equality.
   */
  connect(reg: ISignalRegistration<S>): () => void {
    this.stages[reg.name].add(reg);
    return () => {
      this.disconnect(reg);
    };
  }

  disconnect(reg: ISignalRegistration<S>): void {
    this.stages[reg.name].delete(reg);
  }

  /**
   * Trigger any signals waiting for the named. Unlike an event listener, there
   * is no transferred state, only a notification that the signal is firing.
   */
  async fire(name: S, firingPhase: 'before' | 'after'): Promise<void> {
    log.info(`${this.subject} fire ${name}:${firingPhase}`);
    const signals = this.stages[name];
    for (const signal of signals) {
      const phase = signal[firingPhase];
      if (phase) await phase();
    }
  }
}
