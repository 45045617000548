import { type IRTCService } from '../../services/webrtc';
import { useMyClientId } from '../Venue/VenuePlaygroundProvider';
import { LocalStreamView } from './LocalStreamView';
import { RemoteStreamView } from './RemoteStreamView';
import { type StageMemberVO } from './types';

interface StreamViewProps {
  className: string;
  member: StageMemberVO | undefined | null;
  rtcService: IRTCService;
  disableRemove?: boolean;
  localHover?: React.ReactNode;
  remoteHover?: React.ReactNode;
  style?: React.CSSProperties;
  noPlaceholderIndicators?: boolean;
}

export function StreamView(props: StreamViewProps): JSX.Element | null {
  const {
    className,
    member,
    rtcService,
    disableRemove,
    localHover,
    remoteHover,
    style,
    noPlaceholderIndicators,
  } = props;
  const myClientId = useMyClientId();

  if (!member) return null;

  return (
    <div className='w-full h-full'>
      {member.id === myClientId ? (
        <LocalStreamView
          key={member.id}
          className={className}
          id={`stage-member-${member.id}`}
          rtcService={rtcService}
          targetMember={member}
          disableRemove={disableRemove ?? false}
          hover={localHover}
          style={style}
          noPlaceholderIndicators={noPlaceholderIndicators}
        />
      ) : (
        <RemoteStreamView
          key={member.id}
          id={`stage-member-${member.id}`}
          className={className}
          targetMember={member}
          rtcService={rtcService}
          disableRemove={disableRemove ?? false}
          hover={remoteHover}
          style={style}
          noPlaceholderIndicators={noPlaceholderIndicators}
        />
      )}
    </div>
  );
}
