import { useCallback } from 'react';

import { useAsyncCall } from '../../hooks/useAsyncCall';
import { apiService } from '../../services/api-service';
import {
  ConfirmCancelModalText,
  useAwaitFullScreenConfirmCancelModal,
} from '../ConfirmCancelModalContext';
import { Loading } from '../Loading';
import { useInviteUsersFromChannel } from './hooks/useInviteUsers';

export function SyncChannelMemberButton(props: {
  channelId: string;
  afterSync?: () => void;
}): JSX.Element {
  const { channelId, afterSync } = props;
  const triggerConfirmCancelModal = useAwaitFullScreenConfirmCancelModal();
  const inviteUsersFromChannel = useInviteUsersFromChannel();
  const {
    state: { transformed: state },
    call,
  } = useAsyncCall(
    useCallback(
      async (channelId: string) => {
        const resp = await apiService.channel.getChannel(channelId);
        const channel = resp.data.channel;
        const newInvitees = await inviteUsersFromChannel(channel, {
          removeUnmatched: true,
        });
        return { newInvitees };
      },
      [inviteUsersFromChannel]
    )
  );
  const onClick = async () => {
    const resp = await call(channelId);
    if (!resp) return;
    if (resp.newInvitees.length > 0) {
      await triggerConfirmCancelModal({
        kind: 'confirm-cancel',
        prompt: (
          <ConfirmCancelModalText className='text-xl font-medium'>
            {`${resp.newInvitees.length} new participant(s) added.`}
          </ConfirmCancelModalText>
        ),
        confirmOnly: true,
        confirmBtnLabel: 'Okay',
      });
    }

    afterSync?.();
  };
  return (
    <button
      type='button'
      className='btn text-primary px-4 h-8 flex items-center justify-center text-sm'
      disabled={state.isRunning}
      onClick={onClick}
    >
      {state.isRunning && (
        <Loading text='' imgClassName='w-5 h-5' containerClassName='mr-2' />
      )}
      <div>Sync Participants</div>
    </button>
  );
}
