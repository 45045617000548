import {
  type ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

import { type Block, type BlockAction, type BlockType } from '@lp-lib/game';
import { type Media, MediaFormatVersion } from '@lp-lib/media';

import { useOutsideClick } from '../../../../../hooks/useOutsideClick';
import logger from '../../../../../logger/logger';
import { apiService } from '../../../../../services/api-service';
import { assertExhaustive } from '../../../../../utils/common';
import { TimeUtils } from '../../../../../utils/time';
import { Modal } from '../../../../common/Modal';
import { useClearUpload } from '../../../../GameRecorder/hooks/uploads';
import { ControllerActionUtils } from '../../../../GameRecorder/types';
import { DoubleRightArrow } from '../../../../icons/Arrows';
import { SpotlightBlockIcon } from '../../../../icons/Block';
import { DownloadIcon } from '../../../../icons/DownloadIcon';
import { ErrorIcon } from '../../../../icons/ErrorIcon';
import { GreenScreenIcon } from '../../../../icons/GreenScreenIcon';
import { InfoIcon } from '../../../../icons/InfoIcon';
import { OndIcon } from '../../../../icons/OndIcon';
import { PlayIcon } from '../../../../icons/PlayIcon';
import { RefreshIcon } from '../../../../icons/RefreshIcon';
import { ScoreboardIcon } from '../../../../icons/ScoreboardIcon';
import { FilledSquareIcon } from '../../../../icons/SquareIcon';
import { TimerIcon } from '../../../../icons/TimerIcon';
import { MediaUploader } from '../../../../MediaUploader/MediaUploader';
import { VideoEffectsSettingsBlockPanel } from '../../../../VideoEffectsSettingsPanels/Block';
import { BlockKnifeUtils } from '../../Shared';
import { BlockIcon } from '../BlockUtils';

const log = logger.scoped('ond-recording-details');

function OndRecordingDetailsLink(props: {
  disabled: boolean;
  outdatedRecording: boolean;
  versionMismatch: boolean;
  onOpenDetails: () => void;
  badgeOnly?: boolean;
}) {
  if (props.badgeOnly) {
    return (
      <button type='button' className='pr-2' onClick={props.onOpenDetails}>
        <OndIcon className='w-6 h-6 justify-center items-center' />
      </button>
    );
  } else {
    return (
      <div className={`flex gap-2 ${props.disabled ? 'invisible' : ''}`}>
        <OndRecordingDangerouslyOutdatedIcon
          outdatedRecording={props.outdatedRecording}
          versionMismatch={props.versionMismatch}
        />
        <OndIcon className='w-8 h-8' />
        <button
          type='button'
          disabled={props.disabled}
          className='text-white text-sms underline'
          onClick={props.onOpenDetails}
        >
          OnD Recording Details
        </button>
      </div>
    );
  }
}

function download(filename: string, url: string) {
  const a = document.createElement('a');

  // This is only allowed for same-domain.
  a.style.display = 'none';
  a.href = url;
  a.download = filename;
  a.target = '_blank';
  a.rel = 'nofollow noreferrer noopener';

  document.body.appendChild(a);
  a.click();
  a.remove();
}

function OndRecordingDetailsModal(props: {
  open: boolean;
  autoCloseDetailsModel: () => void;
  children?: ReactNode;
}): JSX.Element {
  const ref = useRef<HTMLDivElement>(null);

  useOutsideClick(ref, props.autoCloseDetailsModel);

  if (!props.open) return <></>;

  return (
    <Modal borderStyle='gray' className='w-5/6 h-5/6 max-w-[1024px]'>
      <div ref={ref} className='w-full h-full'>
        {props.children}
      </div>
    </Modal>
  );
}

function OndRecordingVideoEffectsSettingsModal(props: {
  block: Block;
  open: boolean;
  setOpen: (open: boolean) => void;
  refreshBlocks: RefreshBlocksCallback;
}): JSX.Element {
  const ref = useRef<HTMLDivElement>(null);

  useOutsideClick(ref, () => {
    props.setOpen(false);
  });

  if (!props.open) {
    return <></>;
  }

  return (
    <Modal borderStyle='gray' className='w-full h-5/6 p-9 m-10'>
      <div ref={ref} className='w-full h-full overflow-hidden flex'>
        <div className='flex-1'>
          <VideoEffectsSettingsBlockPanel
            block={props.block}
            onClose={() => {
              props.refreshBlocks(props.block.gameId);
              props.setOpen(false);
            }}
          />
        </div>
      </div>
    </Modal>
  );
}

function RecordingVideoPreview(props: {
  block: Block;
  media: Media | null | undefined;
  onReplace: (media: Media) => void;
  onUploadStart: () => void;
  onUploadFailed: () => void;
}) {
  const id = `${props.block.id}-recording-preview`;

  return (
    <MediaUploader
      id={id}
      replace
      duration
      allowDelete={false}
      allowInitialUpload={false}
      media={props.media}
      objectFit='object-contain'
      onUploadSuccess={props.onReplace}
      onUploadStart={props.onUploadStart}
      onUploadFailed={props.onUploadFailed}
    />
  );
}

function ControllerActionApproximation(props: {
  blockType: BlockType;
  action: BlockAction;
}) {
  const desc = ControllerActionUtils.ForBlockAction(
    props.blockType,
    props.action
  );

  const iconClassName = 'w-4 h-4 fill-current';

  const icon = !desc ? (
    '?'
  ) : desc?.icon === 'play' ? (
    <PlayIcon className={iconClassName} />
  ) : desc?.icon === 'replay' ? (
    <RefreshIcon className={iconClassName} />
  ) : desc?.icon === 'reveal' ? (
    <DoubleRightArrow className={iconClassName} />
  ) : desc?.icon === 'scoreboard' ? (
    <ScoreboardIcon className={iconClassName} />
  ) : desc?.icon === 'stop' ? (
    <FilledSquareIcon className={iconClassName} />
  ) : desc?.icon === 'timer' ? (
    <TimerIcon className={iconClassName} />
  ) : desc.icon === 'info' ? (
    <InfoIcon className={iconClassName} />
  ) : desc.icon === 'spotlight' ? (
    <SpotlightBlockIcon className={iconClassName} fill='white' />
  ) : (
    (assertExhaustive(desc?.icon), null)
  );

  return (
    <div className='flex gap-2 items-center'>
      {icon}
      {desc?.label ?? 'Unknown Recorded Action'}
    </div>
  );
}

type AsyncRecordingDetailsProps = {
  block: Block;
  outdatedRecording: boolean;
  versionMismatch: boolean;
  setVideoEffectsSettingsOpen: (open: boolean) => void;
  confirmDelete: SetConfirmDelete;
  operationsAreDisabled: boolean;
  setOperationsAreDisabled: (disabled: boolean) => void;
  refreshBlocks: RefreshBlocksCallback;
  close?: () => void;
};

function AsyncRecordingDetails(props: AsyncRecordingDetailsProps) {
  const clearUpload = useClearUpload();

  const { operationsAreDisabled, setOperationsAreDisabled } = props;

  const onReplace = async (nextMedia: Media) => {
    const { block } = props;

    if (!block.recording) throw new Error('No existing recording for block!');

    try {
      setOperationsAreDisabled(true);

      await apiService.block.updateRecordingMediaId(block.id, {
        mediaId: nextMedia.id,
      });

      // TODO: this should happen via backend during updateRecordingMediaId
      await apiService.block.updateOutdatedRecording(block.id, {
        outdatedRecording: false,
      });

      props.refreshBlocks(block.gameId);
    } catch (err) {
      log.error('Failed to update block with new media', err, {
        block,
        ...nextMedia,
      });
    } finally {
      setOperationsAreDisabled(false);
    }
  };

  const onDownloadClick = () => {
    const raw = props.block.recording?.media?.formats.find(
      (f) => f.version === MediaFormatVersion.Raw
    );
    const filename = raw?.url.split('/').pop();

    if (!raw || !filename) {
      log.error('No RAW version found', new Error('EmptyMedia'), {
        block: props.block,
      });
      return;
    }

    download(filename, raw.url);
  };

  const blockSummary = BlockKnifeUtils.Summary(props.block);

  return (
    <div className='w-full h-full relative text-white scrollbar overflow-y-auto'>
      <div className='px-10 py-6 relative border-b border-secondary'>
        <header className='flex items-center gap-5'>
          <OndIcon className='w-10 h-10' />
          <span className='text-2xl'>OnD Recording Details</span>
          <OndRecordingDangerouslyOutdatedIcon
            outdatedRecording={props.outdatedRecording}
            versionMismatch={props.versionMismatch}
            iconClassName='w-6 h-6'
          />
        </header>
        <div className='flex gap-6'>
          <RecordingVideoPreview
            block={props.block}
            media={props.block.recording?.media}
            onReplace={onReplace}
            onUploadStart={() => setOperationsAreDisabled(true)}
            onUploadFailed={() => setOperationsAreDisabled(false)}
          />
          <div className='flex-grow flex flex-col gap-1.5 pb-4 text-sms'>
            <div className='flex items-center gap-1 text-secondary'>
              <BlockIcon blockType={props.block.type} />
              {blockSummary.prettyTypeName}
            </div>
            <ul className='flex flex-col gap-0.5'>
              <li>
                <span className='text-secondary'>Last Modified: </span>
                {props.block.recording?.updatedAt
                  ? new Intl.DateTimeFormat([], {
                      month: 'long',
                      day: 'numeric',
                      year: 'numeric',
                      hour: 'numeric',
                      second: 'numeric',
                      minute: 'numeric',
                      timeZoneName: 'short',
                    }).format(new Date(props.block.recording?.updatedAt))
                  : 'never'}
              </li>

              <li>
                <span className='text-secondary'>Run Time: </span>
                {TimeUtils.DurationFormattedHHMMSS(
                  props.block.recording?.durationMs,
                  false
                )}
              </li>
              <li>
                <span className='text-secondary'>Recording Version: </span>
                {props.block.recording?.version}
              </li>
            </ul>
            <button
              type='button'
              className='flex gap-3 text-secondary'
              disabled={operationsAreDisabled}
              onClick={onDownloadClick}
            >
              <DownloadIcon />
              Download Recording
            </button>
            <div className='flex mt-auto justify-between'>
              <button
                type='button'
                className='flex items-center gap-3 text-primary'
                disabled={operationsAreDisabled}
                onClick={() => props.setVideoEffectsSettingsOpen(true)}
              >
                <span className='text-secondary'>
                  <GreenScreenIcon className='w-5 h-5 fill-current' />
                </span>
                Video Effects Settings
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className='px-10 py-7.5'>
        <header className='text-xl font-bold'>
          Time Stamps of Progressions{' '}
          <span className='text-secondary text-base'>
            ({props.block.recording?.actions.length})
          </span>
        </header>

        <table className='table-fixed w-108 font-normal text-sms text-left'>
          <thead>
            <tr className='h-10'>
              <th className='w-6 font-normal invisible'>Number</th>
              <th className='w-52 font-normal'>Key Actions</th>
              <th className='w-22 font-normal'>Time Stamps</th>
            </tr>
          </thead>
          <tbody>
            {props.block.recording?.actions.map((action, idx) => (
              <tr key={`${action.timestamp}-${idx}`} className='h-10'>
                <td>{idx + 1}</td>
                <td>
                  <ControllerActionApproximation
                    blockType={props.block.type}
                    action={action}
                  />
                </td>
                <td>{TimeUtils.DurationFormattedHHMMSS(action.timestamp)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className='flex gap-5 absolute top-3.5 right-3.5'>
        <button
          type='button'
          className='btn-delete w-34 h-10'
          disabled={operationsAreDisabled}
          onClick={() => {
            props.confirmDelete(() => {
              apiService.block.deleteRecording(props.block.id).then(() => {
                props.refreshBlocks(props.block.gameId);
                clearUpload(props.block.id);
              });

              props.close?.();
            });
          }}
        >
          Delete
        </button>
        {props.close && (
          <button
            type='button'
            className='btn-secondary w-34 h-10'
            disabled={operationsAreDisabled}
            onClick={props.close}
          >
            Done
          </button>
        )}
      </div>
    </div>
  );
}

export function OndRecordingDangerouslyOutdatedIcon(props: {
  outdatedRecording: boolean;
  versionMismatch?: boolean;
  iconClassName?: string;
  title?: string;
}): JSX.Element | null {
  const errors = [];
  if (props.outdatedRecording) errors.push('Block edited after recording');
  if (props.versionMismatch) errors.push('Block recording version mismatch');

  return errors.length === 0 ? null : (
    <span
      className='flex justify-center items-center'
      title={errors.join(', ')}
    >
      <ErrorIcon className={props.iconClassName} />
    </span>
  );
}

type SetConfirmDelete = (onConfirmed: () => void) => void;
type RefreshBlocksCallback = (gameId?: string) => void;

export type BlockEditorOndRecordingDetails = {
  setWarn: (warn: boolean) => void;
  warningModal: JSX.Element | null;
  detailsModal: JSX.Element | null;
  detailsLink: JSX.Element | null;
  details: JSX.Element | null;
};

export function useOndRecordingDetails(
  block: Block | null,
  recordingVersionContext: number,
  refreshBlocks: RefreshBlocksCallback,
  badgeOnly?: boolean,
  deleteConfirmConfig?: {
    primaryText?: string | JSX.Element;
    secondaryText?: string | JSX.Element;
  }
): BlockEditorOndRecordingDetails {
  const [warn, setWarn] = useState(false);
  const [minor, setMinor] = useState(false);

  const setWarnExt = useCallback(
    (warn: boolean) => {
      if (block?.recording && warn === true) setWarn(warn);
      else setWarn(false);
    },
    [block?.recording]
  );

  const [confirmDeleteCallback, setConfirmDeleteCallback] = useState<
    null | (() => void)
  >(null);

  const extSetConfirmDeleteCallback = useCallback((onConfimed: () => void) => {
    setConfirmDeleteCallback(() => onConfimed);
  }, []);

  const [detailsLink, details, detailsModal] = useOndRecordingDetailsModal(
    block,
    block?.outdatedRecording ?? false,
    !!block?.recording && block?.recording.version !== recordingVersionContext,
    extSetConfirmDeleteCallback,
    !!confirmDeleteCallback,
    refreshBlocks,
    badgeOnly
  );

  const prevBlockId = useRef<Block['id'] | null>(null);
  useEffect(() => {
    if (prevBlockId.current !== block?.id) {
      setWarn(false);
      setMinor(false);
    }

    prevBlockId.current = block?.id ?? null;
  }, [block?.id, block?.outdatedRecording]);

  const markBlockRecordingAsOutdated = async () => {
    if (!block) return;
    await apiService.block.updateOutdatedRecording(block.id, {
      outdatedRecording: true,
    });
    refreshBlocks();
  };

  const deleteConfirmModal =
    confirmDeleteCallback === null ? null : (
      <Modal borderStyle='gray' className='w-88'>
        <div className='w-full h-full flex flex-col gap-4 p-7 pt-5 text-sms text-white text-center'>
          <header className='flex flex-col justify-center items-center text-2xl'>
            {deleteConfirmConfig?.primaryText ?? (
              <span>Are you sure you want to delete this OnD Recording?</span>
            )}
          </header>
          <p>
            {deleteConfirmConfig?.secondaryText ?? (
              <>
                Doing so will delete both the video recording and the Key
                Actions forever.
              </>
            )}
          </p>
          <div className='pt-4 flex justify-between'>
            <button
              type='button'
              className='btn-secondary w-34 h-10'
              onClick={() => {
                setConfirmDeleteCallback(null);
              }}
            >
              Cancel
            </button>
            <button
              type='button'
              className='btn-delete w-34 h-10'
              onClick={() => {
                if (confirmDeleteCallback !== null) confirmDeleteCallback();
                setConfirmDeleteCallback(null);
              }}
            >
              Delete
            </button>
          </div>
        </div>
      </Modal>
    );

  const outdatedModal =
    !warn || block?.outdatedRecording ? null : (
      <Modal borderStyle='gray' className='w-88'>
        <div className='w-full h-full flex flex-col gap-4 p-7 pt-5 text-sms text-white text-center'>
          <header className='flex flex-col justify-center items-center text-2xl'>
            <span className='text-red-002'>HOLD UP!</span>
            <span>This Block already has an OnD Recording</span>
          </header>
          <p>
            Making changes to this Block may render the OnD Recording obsolete.
            Please confirm if this is a major or minor change.
          </p>
          <label className='flex justify-center gap-2.5'>
            <input
              className='checkbox-dark'
              type='checkbox'
              checked={minor}
              onChange={() => setMinor((m) => !m)}
            />
            This is a minor change
          </label>
          <div className='pt-4 flex justify-center'>
            <button
              type='button'
              className='btn-delete w-34 h-10'
              onClick={async () => {
                if (!minor) {
                  markBlockRecordingAsOutdated();
                }
                setWarn(false);
                setMinor(false);
              }}
            >
              Confirm
            </button>
          </div>
        </div>
      </Modal>
    );

  return {
    setWarn: setWarnExt,
    warningModal: deleteConfirmModal ?? outdatedModal,
    detailsLink,
    detailsModal,
    details,
  };
}

function useOndRecordingDetailsModal(
  block: Block | null,
  outdatedRecording: boolean,
  versionMismatch: boolean,
  confirmDelete: SetConfirmDelete,
  confirming: boolean,
  refreshBlocks: RefreshBlocksCallback,
  badgeOnly?: boolean
): readonly [JSX.Element | null, JSX.Element | null, JSX.Element | null] {
  const [open, setOpen] = useState(false);
  const [videoEffectsSettingsOpen, setVideoEffectsSettingsOpen] =
    useState(false);
  const [operationsAreDisabled, setOperationsAreDisabled] = useState(false);

  const detailsProps: Omit<AsyncRecordingDetailsProps, 'block'> = {
    outdatedRecording,
    versionMismatch,
    setVideoEffectsSettingsOpen,
    confirmDelete,
    operationsAreDisabled,
    setOperationsAreDisabled,
    refreshBlocks,
  };

  const vfxSettings = block ? (
    <OndRecordingVideoEffectsSettingsModal
      block={block}
      setOpen={setVideoEffectsSettingsOpen}
      open={videoEffectsSettingsOpen}
      refreshBlocks={refreshBlocks}
    />
  ) : null;

  return [
    <OndRecordingDetailsLink
      disabled={!block?.recording}
      outdatedRecording={outdatedRecording}
      versionMismatch={versionMismatch}
      onOpenDetails={() => setOpen(true)}
      badgeOnly={badgeOnly}
    />,
    block ? (
      <>
        <AsyncRecordingDetails block={block} {...detailsProps} />
        {vfxSettings}
      </>
    ) : null,
    block ? (
      <>
        <OndRecordingDetailsModal
          open={open}
          autoCloseDetailsModel={() => {
            if (!confirming && !operationsAreDisabled) setOpen(false);
          }}
        >
          <AsyncRecordingDetails
            block={block}
            {...detailsProps}
            close={() => setOpen(false)}
          />
        </OndRecordingDetailsModal>
        {vfxSettings}
      </>
    ) : null,
  ] as const;
}
