import { useLayoutEffect } from 'react';

import { type QuestionBlock } from '@lp-lib/game';

import { type GameControlProps } from '../Common/GameControl/types';
import { useGradeAPI } from './grader';

export function QuestionBlockGameControl(
  props: GameControlProps<QuestionBlock>
): JSX.Element | null {
  const block = props.block;

  const gradeAPI = useGradeAPI(block);

  useLayoutEffect(() => {
    gradeAPI.start();
    return () => {
      gradeAPI.stop();
    };
  }, [gradeAPI]);

  return null;
}
