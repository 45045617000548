import { EnumsProgramType } from '@lp-lib/api-service-client/public';

export const ONBOARDING_PROGRAM_TYPES = [
  EnumsProgramType.ProgramTypeIntros,
  EnumsProgramType.ProgramTypeWaterCooler,
  EnumsProgramType.ProgramTypeBirthdayAndCelebrations,
  EnumsProgramType.ProgramTypeRecognition,
];

export const ONBOARDING_PROGRAM_TYPES_SELECTED = [
  EnumsProgramType.ProgramTypeIntros,
  EnumsProgramType.ProgramTypeWaterCooler,
];
