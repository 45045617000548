import { addDays, format, isBefore, isWeekend } from 'date-fns';
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';

import {
  type Event,
  type EventType,
} from '../../services/api-service/event.api';
import { assertExhaustive } from '../../utils/common';
import { Clock } from '../Clock';

export type EventDayAvailability =
  | {
      status: 'available';
    }
  | {
      status: 'unavailable';
      reason: 'too-soon' | 'holiday';
    }
  | {
      status: 'require-confirmation';
    };

export function formatTypeformSlug(orgName: string, startAt: string): string {
  const trimmedOrgName = orgName.replace(/\W/g, '');
  const formattedDate = format(
    utcToZonedTime(startAt, 'America/New_York'),
    'MMddyy_HHmm'
  );
  return `${trimmedOrgName}_${formattedDate}`.toLowerCase();
}

export class EventUtils {
  static IsEventEnded(event: { endAt: string }, exp = 30 * 60): boolean {
    const d = new Date(event.endAt);
    return Clock.instance().now() - d.getTime() > exp * 1000;
  }
  static NYTime(time: Date | string) {
    return format(
      utcToZonedTime(time, 'America/New_York'),
      "M/d/yyyy @ h:mm aa 'ET'"
    );
  }
  static CheckDayAvailability(
    day: Date,
    eventType: EventType
  ): EventDayAvailability {
    switch (eventType) {
      case 'live':
        if (isBefore(day, addDays(new Date(), 2))) {
          return { status: 'unavailable', reason: 'too-soon' };
        }
        if (isWeekend(day)) {
          return { status: 'unavailable', reason: 'holiday' };
        }
        return { status: 'require-confirmation' };
      case 'ond':
        return { status: 'available' };
      default:
        assertExhaustive(eventType);
        return { status: 'available' };
    }
  }

  static IsInLPWorkingHours(time: Date, timezone: string): boolean {
    const d = utcToZonedTime(
      zonedTimeToUtc(time, timezone),
      'America/New_York'
    );
    const h = d.getHours();
    return h >= 9 && h < 22;
  }
}

export function eventFieldString(
  event: Event,
  field: 'organization' | 'organizer'
): string {
  switch (field) {
    case 'organization':
      return event.organizer?.organization?.name ?? event.data?.orgName ?? '';
    case 'organizer':
      return event.data?.organizerName
        ? event.data?.organizerName
        : event.organizer
        ? `${event.organizer?.firstName} ${event.organizer?.lastName}`
        : '';
    default:
      assertExhaustive(field);
      return '';
  }
}
