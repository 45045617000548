import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { usePrevious } from 'react-use';
import { useSnapshot } from 'valtio';

import {
  type JeopardyBlock,
  JeopardyBlockGameSessionStatus,
} from '@lp-lib/game';

import { assertExhaustive } from '../../../../utils/common';
import { useGainPointsAnimationGamePlayTrigger } from '../../../GainPointsAnimation/useGainPointsAnimationGamePlayTrigger';
import { FloatLayout } from '../../../Layout';
import { LayoutAnchor } from '../../../LayoutAnchors/LayoutAnchors';
import { useSyncPersistentPointsRevealAnswer } from '../../../PersistentPoints/Provider';
import { useGameSessionLocalTimer, useGameSessionStatus } from '../../hooks';
import { countdownV2, resetTimer, setTimer } from '../../store';
import {
  buildGamePlayMedia,
  GamePlayMediaPlayer,
  type GamePlayMediaPlayerLayout,
  useGamePlayMediaPlayable,
  useGamePlayMediaUISync,
} from '../Common/GamePlay/GamePlayMedia';
import { GamePlayMediaLayout } from '../Common/GamePlay/GamePlayMediaLayout';
import { GamePlayResult } from '../Common/GamePlay/GamePlayResult';
import {
  useGamePlayUITransitionControl,
  useIsGamePlayReady,
} from '../Common/GamePlay/GamePlayUtilities';
import { ProgressRing } from '../Common/GamePlay/ProgressRing';
import { StageBgSingleFrame } from '../Common/GamePlay/StageBgSingleFrame';
import {
  type GamePlayMedia,
  type GamePlayProps,
} from '../Common/GamePlay/types';
import { useRankedTeamScores, useStableBlock } from '../Common/hooks';
import { JeopardyBlockPlayground } from './JeopardyBlockPlayground';
import {
  useJeopardyGameSharedAPI,
  useSubscribeSharedState,
} from './JeopardyBlockProvider';
import { JeopardyUtils } from './utils';

type GamePlayLocalState = {
  gamePlayMedia: Nullable<GamePlayMedia, false>;
  mediaPlayerLayout: GamePlayMediaPlayerLayout;
  showResults: boolean;
};

interface GamePlayLocalControlAPI {
  initGame: () => void;
  startGame: () => void;
  endGameTimer: () => void;
  stopGame: () => void;
  presentOutro: () => void;
  revealResults: () => void;
  reset: () => void;
}

function useGamePlayLocalControl(
  block: JeopardyBlock
): [GamePlayLocalState, GamePlayLocalControlAPI] {
  const [gamePlayMedia, setGamePlayMedia] =
    useState<Nullable<GamePlayMedia, false>>(null);
  const [mediaPlayerLayout, setMediaPlayerLayout] =
    useState<GamePlayMediaPlayerLayout>(undefined);
  const [showResults, setShowResults] = useState(false);
  const initedRef = useRef(false);

  const updateGamePlayMedia = useCallback(
    (
      media: Nullable<GamePlayMedia, false>,
      layout: GamePlayMediaPlayerLayout
    ) => {
      setGamePlayMedia(media);
      setMediaPlayerLayout(layout);
    },
    []
  );

  const initGame = useCallback(() => {
    if (initedRef.current) return;
    setMediaPlayerLayout(undefined);
    const media = buildGamePlayMedia(
      {
        media: JeopardyUtils.GetBackgroundMedia(block),
        mediaData: block.fields.backgroundMediaData,
      },
      {
        stage: 'custom',
        isBackgroundMedia: true,
      }
    );
    if (media) updateGamePlayMedia(media, 'fullscreen');
    initedRef.current = true;
  }, [block, updateGamePlayMedia]);

  const startGame = useCallback(async () => {
    if (!initedRef.current) {
      initGame();
    }
  }, [initGame]);

  const endGameTimer = useCallback(() => {
    resetTimer('submission');
  }, []);

  const stopGame = useCallback(() => {
    updateGamePlayMedia(null, undefined);
  }, [updateGamePlayMedia]);

  const presentOutro = useCallback(() => {
    updateGamePlayMedia(
      buildGamePlayMedia(
        {
          media: block.fields.outroMedia,
          mediaData: block.fields.outroMediaData,
        },
        {
          stage: 'outro',
        }
      ),
      'anchored'
    );
  }, [block, updateGamePlayMedia]);

  const revealResults = useCallback(() => {
    updateGamePlayMedia(null, undefined);
    setShowResults(true);
  }, [updateGamePlayMedia]);

  const reset = useCallback(() => {
    resetTimer('submission');
    updateGamePlayMedia(null, undefined);
    initedRef.current = false;
    setShowResults(false);
  }, [updateGamePlayMedia]);

  return [
    {
      gamePlayMedia,
      showResults,
      mediaPlayerLayout,
    },
    useMemo(
      () => ({
        initGame,
        startGame,
        endGameTimer,
        stopGame,
        presentOutro,
        revealResults,
        reset,
      }),
      [
        initGame,
        startGame,
        endGameTimer,
        stopGame,
        presentOutro,
        revealResults,
        reset,
      ]
    ),
  ];
}

function Results(): JSX.Element | null {
  const teamScores = useRankedTeamScores('currentScore', null, 'always');
  return (
    <>
      <GamePlayResult teamScores={teamScores} />
      <FloatLayout className='flex items-center justify-center'>
        <LayoutAnchor
          id='gameplay-points-animation-top'
          className='w-full max-w-4xl'
        />
      </FloatLayout>
    </>
  );
}

export function JeopardyBlockGamePlay(props: GamePlayProps<JeopardyBlock>) {
  const block = useStableBlock(props.block);
  const gameSessionStatus =
    useGameSessionStatus<JeopardyBlockGameSessionStatus>();
  const prevGameSessionStatus = usePrevious(gameSessionStatus);
  const isGamePlayReady = useIsGamePlayReady(block, gameSessionStatus);
  const inGame = Boolean(
    gameSessionStatus &&
      gameSessionStatus >= JeopardyBlockGameSessionStatus.GAME_START &&
      gameSessionStatus <= JeopardyBlockGameSessionStatus.GAME_END
  );
  const [state, control] = useGamePlayLocalControl(block);
  const [uiState, uiControl] = useGamePlayUITransitionControl();

  useSubscribeSharedState();
  useJeopardyGamePlayTimers(props);
  useSyncPersistentPointsRevealAnswer(state.showResults);
  useGainPointsAnimationGamePlayTrigger();

  useEffect(() => {
    return () => {
      control.reset();
    };
  }, [control]);

  useEffect(() => {
    if (prevGameSessionStatus === gameSessionStatus) return;
    switch (gameSessionStatus) {
      case JeopardyBlockGameSessionStatus.LOADED:
        control.reset();
        break;
      case JeopardyBlockGameSessionStatus.GAME_INIT:
        control.initGame();
        break;
      case JeopardyBlockGameSessionStatus.GAME_START:
        control.startGame();
        break;
      case JeopardyBlockGameSessionStatus.GAME_END:
        control.endGameTimer();
        break;
      case JeopardyBlockGameSessionStatus.OUTRO:
        control.stopGame();
        control.presentOutro();
        break;
      case JeopardyBlockGameSessionStatus.RESULTS:
        control.revealResults();
        break;
      case JeopardyBlockGameSessionStatus.END:
      case null:
      case undefined:
        break;
      default:
        assertExhaustive(gameSessionStatus);
        break;
    }
  }, [gameSessionStatus, prevGameSessionStatus, control]);

  const { onMediaEnded, onMediaReplaying } = useGamePlayMediaUISync({
    block,
    gameSessionStatus,
    media: state.gamePlayMedia,
    state: uiState,
    control: uiControl,
  });

  const mediaPlayable = useGamePlayMediaPlayable({
    block,
    gameSessionStatus,
    media: state.gamePlayMedia,
    state: uiState,
    custom: () =>
      !!gameSessionStatus &&
      gameSessionStatus === JeopardyBlockGameSessionStatus.GAME_START,
  });

  if (!isGamePlayReady) return null;

  const fullscreen = state.mediaPlayerLayout === 'fullscreen';

  return (
    <div className='fixed w-screen h-screen text-white'>
      {state.gamePlayMedia && state.mediaPlayerLayout && (
        <StageBgSingleFrame gamePlayMedia={state.gamePlayMedia} />
      )}
      <GamePlayMediaLayout fullscreen={fullscreen}>
        {state.gamePlayMedia && state.mediaPlayerLayout && (
          <GamePlayMediaPlayer
            gamePlayMedia={state.gamePlayMedia}
            play={mediaPlayable}
            mode={fullscreen || !uiState.mediaEndEffect ? 'full' : 'small'}
            onMediaEnded={!fullscreen ? onMediaEnded : undefined}
            onMediaReplaying={!fullscreen ? onMediaReplaying : undefined}
            layout={state.mediaPlayerLayout}
          />
        )}
      </GamePlayMediaLayout>

      {inGame && (
        <FloatLayout className='left-[5%] right-[5%]' useCustomPaddingX>
          <JeopardyBlockPlayground block={props.block} />
          <Timer className='absolute top-0 left-0 transform -translate-x-1/2 -translate-y-1/2' />
        </FloatLayout>
      )}
      {state.showResults && <Results />}
    </div>
  );
}

function useJeopardyGamePlayTimers(props: GamePlayProps<JeopardyBlock>) {
  const block = useStableBlock(props.block);
  const shared = useJeopardyGameSharedAPI();
  const status = useSnapshot(shared.state).game?.state;
  const prevStatus = usePrevious(status);

  useEffect(() => {
    if (prevStatus === status) return;

    switch (status) {
      case 'WAIT_FOR_CLUE_SELECTION':
        startTimer(block.fields.clueSelectionTimeSec ?? 10);
        break;
      case 'WAIT_FOR_BUZZERS':
        startTimer(block.fields.buzzerTimeSec ?? 10);
        break;
      case 'WAIT_FOR_ANSWER':
        startTimer(block.fields.answerTimeSec ?? 10);
        break;
      case 'WAIT_FOR_JUDGEMENTS':
        startTimer(block.fields.judgingTimeSec ?? 10);
        break;
      case 'REVEAL_VERDICT':
        resetTimer('submission');
        break;
      default:
        break;
    }
  }, [
    status,
    prevStatus,
    block.fields.clueSelectionTimeSec,
    block.fields.buzzerTimeSec,
    block.fields.answerTimeSec,
    block.fields.judgingTimeSec,
  ]);
}

async function startTimer(durationSec: number) {
  await resetTimer('submission');
  setTimer('submission', durationSec);
  await countdownV2({
    debug: 'JeopardyBlockGamePlay',
    startTimeWorker: true,
    flushCountingStatus: true,
  });
}

function Timer(props: { className: string }) {
  const shared = useJeopardyGameSharedAPI();
  const gameState = useSnapshot(shared.state).game;
  const status = gameState?.state;
  const timers = gameState?.timers;
  const remainingTime = useGameSessionLocalTimer() ?? 0;

  const totalTime = useMemo(() => {
    if (!timers || !status) return 0;

    switch (status) {
      case 'WAIT_FOR_CLUE_SELECTION':
        return timers.clueSelectionTimeSec;
      case 'WAIT_FOR_BUZZERS':
        return timers.buzzerTimeSec;
      case 'WAIT_FOR_ANSWER':
        return timers.answerTimeSec;
      case 'WAIT_FOR_JUDGEMENTS':
        return timers.judgingTimeSec;
      default:
        return 0;
    }
  }, [timers, status]);

  if (!totalTime) return null;

  const durationFormattedMMSS = totalTime >= 60;

  return (
    <ProgressRing
      className={props.className}
      currentTime={remainingTime}
      totalTime={totalTime}
      withPingAnimations
      textClassName={durationFormattedMMSS ? 'text-lg' : 'text-xl'}
      durationFormattedMMSS={durationFormattedMMSS}
    />
  );
}
