import { type Audio } from '../../types/music';

export type MusicPlayerDTO = {
  currentAudio: Audio | null;
  isPlaying: boolean;
};

export const getMusicPlayerDTOFBPath = (venueId: string): string => {
  return `music/${venueId}`;
};
