import { proxy } from 'valtio';

import { RTDBServerValueTIMESTAMP } from '@lp-lib/firebase-typesafe';
import type { Logger } from '@lp-lib/logger-base';

import {
  markSnapshottable,
  type Snapshot,
  ValtioUtils,
} from '../../../../../../utils/valtio';
import {
  type FirebaseService,
  FirebaseValueHandle,
} from '../../../../../Firebase';

function makeRootHandle(
  svc: FirebaseService,
  venueId: string,
  buzzerId: string
): FirebaseValueHandle<unknown> {
  return new FirebaseValueHandle(
    svc.prefixedSafeRef(`buzzers/${venueId}/${buzzerId}`)
  );
}

function makeSubmissionsHandle(
  svc: FirebaseService,
  venueId: string,
  buzzerId: string
): FirebaseValueHandle<BuzzerSharedState['submissions']> {
  return new FirebaseValueHandle(
    svc.prefixedSafeRef(`buzzers/${venueId}/${buzzerId}/submissions`)
  );
}

export type BuzzerSubmission = {
  uid: string;
  clientId: string;
  teamId: string;
  submittedAt: number | RTDBServerValueTIMESTAMP;
};

export type BuzzerSharedState = {
  submissions: Nullable<Record<string, Nullable<BuzzerSubmission>>>;
};

export class BuzzerSharedAPI {
  private _state;

  constructor(
    venueId: string,
    buzzerId: string,
    svc: FirebaseService,
    private log: Logger,
    private submissionsHandle = makeSubmissionsHandle(svc, venueId, buzzerId)
  ) {
    this._state = markSnapshottable(
      proxy<BuzzerSharedState>(this.initialState())
    );
  }

  get state() {
    return this._state;
  }

  on(): void {
    this.submissionsHandle.on((val) =>
      ValtioUtils.set(this._state, 'submissions', val)
    );
  }

  off(): void {
    this.submissionsHandle.off();
  }

  reset() {
    ValtioUtils.reset(this._state, this.initialState());
  }

  async buzzIn(uid: string, clientId: string, teamId: string) {
    this.log.info('buzz in', { uid, clientId, teamId });
    return await this.submissionsHandle.ref.child(uid).set({
      uid,
      clientId,
      teamId,
      submittedAt: RTDBServerValueTIMESTAMP,
    });
  }

  getSubmissions(
    snap: Snapshot<BuzzerSharedState> = this._state
  ): BuzzerSubmission[] {
    if (!snap.submissions) return [];

    return Object.values(snap.submissions)
      .filter((a): a is BuzzerSubmission & { submittedAt: number } =>
        Boolean(a && typeof a.submittedAt === 'number')
      )
      .sort((a, b) => {
        return a.submittedAt - b.submittedAt;
      });
  }

  getMySubmission(
    snap: Snapshot<BuzzerSharedState> = this._state,
    uid: string | null | undefined
  ): Nullable<BuzzerSubmission> {
    if (!snap.submissions || !uid) return null;
    return snap.submissions[uid] ?? null;
  }

  private initialState(): BuzzerSharedState {
    return {
      submissions: null,
    };
  }
}

export class BuzzerControlAPI {
  constructor(
    venueId: string,
    buzzerId: string,
    svc: FirebaseService,
    private log: Logger,
    private rootHandle = makeRootHandle(svc, venueId, buzzerId),
    private submissionsHandle = makeSubmissionsHandle(svc, venueId, buzzerId)
  ) {}

  async reset() {
    this.log.info('reset');
    await this.rootHandle.remove();
  }

  async getSubmissions(): Promise<BuzzerSubmission[]> {
    const val = await this.submissionsHandle.get();
    if (!val) return [];
    return Object.values(val)
      .filter((a): a is BuzzerSubmission & { submittedAt: number } =>
        Boolean(a && typeof a.submittedAt === 'number')
      )
      .sort((a, b) => {
        return a.submittedAt - b.submittedAt;
      });
  }
}
