import { Link } from '@remix-run/react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Waypoint } from 'react-waypoint';

import { type MediaFormatVersion } from '@lp-lib/media';

import placeholder from '../../assets/img/placeholder/game-cover.png';
import { useAsyncCall } from '../../hooks/useAsyncCall';
import { apiService } from '../../services/api-service';
import { type Game } from '../../types/game';
import { type Tag } from '../../types/tag';
import { ImagePickPriorityLowToHigh, MediaUtils } from '../../utils/media';
import { DownloadIcon } from '../icons/DownloadIcon';
import { PlusIcon } from '../icons/PlusIcon';
import { Loading } from '../Loading';
import {
  type CreatingGameRequest,
  MyBadge,
  useGameLikeEventEmitter,
  useGameLikeWorkspace,
} from './GameCenter';
import {
  GamePackCoverPres,
  type GamePackCoverPresProps,
} from './GamePackCoverPres';

export const GamePackCover = (props: GamePackCoverPresProps): JSX.Element => {
  return <GamePackCoverPres {...props} />;
};

export const GameCover = (props: {
  game: Game;
  className?: string;
  imgClassName?: string;
  myBadge?: boolean;
  imagePriority?: readonly MediaFormatVersion[];
}): JSX.Element => {
  const src =
    MediaUtils.PickMediaUrl(props.game.cover, {
      priority: props.imagePriority ?? ImagePickPriorityLowToHigh,
    }) || placeholder;
  return (
    <div className={props.className}>
      <img
        src={src}
        alt='cover'
        className={`object-cover bg-black border border-secondary rounded-lg flex-shrink-0 w-full h-full ${props.imgClassName}`}
      />
      {props.myBadge && <MyBadge item={props.game} />}
    </div>
  );
};

export type AddGameHandler = (
  game: Game,
  sendNotification?: boolean
) => Promise<void>;

const AddGameButton = (props: {
  target: Game;
  autoAdded: boolean;
  handleAdd?: AddGameHandler;
}): JSX.Element => {
  const { target, handleAdd } = props;
  const [added, setAdded] = useState<boolean>(props.autoAdded);
  const {
    state: { transformed: callState },
    error,
    call,
  } = useAsyncCall(
    useCallback(async () => {
      if (!handleAdd) return;
      await handleAdd(target);
      setAdded(true);
    }, [target, handleAdd])
  );

  const text = error ? 'Failed' : added ? 'Added' : 'Add';
  const className = error
    ? 'btn-secondary border border-black-001 text-red-004'
    : added
    ? 'btn-secondary border border-black-001'
    : 'btn-primary';

  const handleAddClick = async () => {
    await call();
  };

  return (
    <button
      className={`${className} absolute top-1 right-4 w-16 h-6.5 invisible group-hover:visible flex items-center justify-center`}
      onClick={handleAddClick}
      disabled={added || !!error}
    >
      {callState.isRunning && <Loading text='' />}
      {!callState.isRunning && (
        <>
          {!added && !error && (
            <DownloadIcon className='w-4 h-4 fill-current mr-1' />
          )}
          <p>{text}</p>
        </>
      )}
    </button>
  );
};

interface MyGameDropdownProps {
  handleAdd?: AddGameHandler;
  handleCreate?: (game: Game, fn?: AddGameHandler) => Promise<void>;
  src?: Game;
}

export const MyGameDropdown = (props: MyGameDropdownProps): JSX.Element => {
  const { src, handleAdd, handleCreate } = props;
  const [games, setGames] = useState<Game[]>([]);
  const paginator = useMemo(() => apiService.game.getMyGames(), []);
  const [, setCreatingGame] = useGameLikeWorkspace('game', 'create');
  const [autoAdded, setAutoAdded] = useState<Record<string, boolean>>({});
  const emitter = useGameLikeEventEmitter('game');
  const {
    state: { transformed: loadingState },
    error,
    call: load,
    reset,
  } = useAsyncCall(
    useCallback(async () => {
      const next = await paginator.next();
      setGames((prev) => {
        return [...prev, ...next];
      });
    }, [paginator])
  );

  const resetAll = useCallback(() => {
    setGames([]);
    paginator.reset();
    reset();
  }, [setGames, paginator, reset]);

  useEffect(() => {
    load();
    return () => {
      resetAll();
    };
  }, [load, resetAll]);

  useEffect(() => {
    const handleCreated = async (game: Game) => {
      if (handleCreate) {
        await handleCreate(game, handleAdd);
        setAutoAdded({ ...autoAdded, [game.id]: true });
      }
      setGames((prev) => {
        return [game, ...prev];
      });
    };
    emitter.on('created', handleCreated);
    return () => {
      emitter.off('created', handleCreated);
    };
  }, [autoAdded, emitter, handleCreate, handleAdd]);

  const handleWaypointEnter = () => {
    if (loadingState) return;
    if (paginator.hasMore()) {
      load();
    }
  };

  const handleShowCreateGame = async () => {
    if (src) {
      setCreatingGame({
        initialValues: src,
        openEditorAfterCreated: false,
        initBlock: false,
      } as CreatingGameRequest);
    } else {
      setCreatingGame(true);
    }
  };

  return (
    <div className='text-sms flex flex-col max-h-65 w-75 bg-black border border-secondary rounded-xl'>
      <div
        className='text-primary px-2 py-2 hover:bg-secondary border-b border-secondary flex items-center cursor-pointer'
        onClick={handleShowCreateGame}
      >
        <PlusIcon />
        <p className='ml-1'>Create a New Minigame</p>
      </div>
      <div className='flex flex-col overflow-y-scroll scrollbar'>
        {games.map((g) => (
          <div
            key={g.id}
            className='px-6 py-2 hover:bg-secondary relative group'
          >
            <p className='truncate'>{g.name}</p>
            <AddGameButton
              target={g}
              handleAdd={handleAdd}
              autoAdded={autoAdded[g.id]}
            />
          </div>
        ))}
        {loadingState.isRunning && <Loading containerClassName='my-2' />}
        {loadingState.isStarted && !loadingState.isRunning && !error && (
          <Waypoint onEnter={handleWaypointEnter} fireOnRapidScroll></Waypoint>
        )}
      </div>
    </div>
  );
};

export const Tags = (props: {
  routePrefix: string;
  tags: Tag[];
}): JSX.Element => {
  return (
    <div className='text-base font-bold break-words'>
      {props.tags?.map((t, i) => (
        <span key={t.id} className='mx-0.5 inline'>
          <Link
            className='hover:underline'
            to={`${props.routePrefix}/tags/${t.slug}`}
          >
            {t.name}
          </Link>
          {i !== props.tags.length - 1 && <>,</>}
        </span>
      ))}
    </div>
  );
};
