import { type CSSProperties, useState } from 'react';
import { useInterval } from 'react-use';

import { ProfileIndex } from '@lp-lib/crowd-frames-schema';

import { type Participant } from '../../../../types';
import { CrowdFramesAvatar } from '../../../CrowdFrames';
import { useParticipantByUserIds } from '../../../Player';
import { useOverRoastedGame } from './OverRoastedProvider';
import {
  useOverRoastedGamePlayAPI,
  useOverRoastedGroupMachines,
  useOverRoastedGroupTrucks,
  useOverRoastedTruckActivePlayerIds,
  useOverRoastedTruckTheme,
} from './OverRoastedProvider/OverRoastedGamePlayProvider';
import { Sprite } from './Sprite';
import {
  DispenserState,
  GameState,
  OVERFILL_WARNING_IMAGE_URL,
  type Truck,
  type TruckId,
} from './types';

function ActivePlayer(props: {
  participant: Participant;
  style?: CSSProperties;
}): JSX.Element {
  const { participant, style } = props;

  return (
    <div className='w-9 h-9 absolute border rounded-full' style={style}>
      <CrowdFramesAvatar
        participant={participant}
        profileIndex={ProfileIndex.wh100x100fps8}
        enablePointerEvents={false}
      />
    </div>
  );
}

function ActivePlayers(props: { truck: Truck }): JSX.Element | null {
  const { truck } = props;
  const activePlayerIds = useOverRoastedTruckActivePlayerIds(truck.id);
  const participants = useParticipantByUserIds(activePlayerIds, true);
  const rowsCount = 4;

  return (
    <div className='absolute top-0 left-0 w-full h-full'>
      {participants
        .map((p, i) => {
          return (
            <ActivePlayer
              key={p.id}
              participant={p}
              style={{
                left: `${Math.floor(i / rowsCount) * 18}px`,
                bottom: `${(i % rowsCount) * 18}px`,
              }}
            />
          );
        })
        .reverse()}
      {/* reverse to make the users at the front to appear on top  */}
    </div>
  );
}

export function useShowTruckFillingWarning(truckId: TruckId): boolean {
  const machines = useOverRoastedGroupMachines(truckId);
  const api = useOverRoastedGamePlayAPI();
  const game = useOverRoastedGame();
  const activePlayerIds = useOverRoastedTruckActivePlayerIds(truckId);

  const [showWarning, setShowWarning] = useState(false);
  useInterval(
    () => {
      setShowWarning(() => {
        if (activePlayerIds.length > 0) return false;

        for (const machine of machines) {
          if (machine.dispenser.state === DispenserState.Delete) {
            return true;
          }

          const percentage = api.calFillingPercentage(machine.id);
          if (percentage >= 0.5) {
            return true;
          }
        }
        return false;
      });
    },
    game?.state === GameState.InProgress ? 500 : null
  );

  return showWarning;
}

function TruckTab(props: {
  truck: Truck;
  active?: boolean;
  onClick?: () => void;
  showActivePlayers?: boolean;
}): JSX.Element {
  const { truck, active, onClick, showActivePlayers } = props;
  const theme = useOverRoastedTruckTheme(truck.id);
  const showWarning = useShowTruckFillingWarning(truck.id);

  return (
    <div
      className={`relative border-12 border-b-0 transition-colors rounded-t-2.5xl ${
        active ? ' bg-white' : 'border-transparent cursor-pointer'
      }`}
      style={{
        borderColor: active ? theme.colorCode : undefined,
      }}
      onClick={!active ? onClick : undefined}
    >
      <Sprite
        name={`truck-${theme.colorName}`}
        widthBreakpoints={
          active
            ? {
                '': 90,
                xl: 90,
                'lp-sm': 90,
                '2xl': 156,
                '3xl': 156,
                'lp-md': 156,
                'lp-lg': 156,
              }
            : {
                '': 90,
                xl: 90,
                'lp-sm': 90,
                '2xl': 90,
                '3xl': 90,
                'lp-md': 90,
                'lp-lg': 90,
              }
        }
      />

      {showActivePlayers && <ActivePlayers truck={truck} />}

      {showWarning && (
        <img
          className='absolute w-15 -top-7.5 -right-6 z-15'
          src={OVERFILL_WARNING_IMAGE_URL}
          alt='warning'
        />
      )}
    </div>
  );
}

export function OverRoastedTrucksNavigationBar(props: {
  activeTruckId: number;
  switchTruck: (truck: Truck) => Promise<void>;
}): JSX.Element {
  const { activeTruckId, switchTruck } = props;
  const trucks = useOverRoastedGroupTrucks();

  const showActivePlayers = trucks.length > 1;

  return (
    <div className='flex items-end mx-4.5'>
      {trucks.map((t) => (
        <TruckTab
          key={t.id}
          truck={t}
          active={activeTruckId === t.id}
          onClick={() => switchTruck(t)}
          showActivePlayers={showActivePlayers}
        />
      ))}
    </div>
  );
}
