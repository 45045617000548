import { useMemo } from 'react';

import { useLiveCallback } from '../../hooks/useLiveCallback';
import { useRSHook } from '../../hooks/useRSHook';
import {
  type Participant,
  type ParticipantFlagMap,
  type ParticipantMap,
} from '../../types/user';
import { rsIncrement } from '../../utils/rstats.client';
import { isSetContainedWithinSet } from '../../utils/set-utils';
import {
  useParticipantByClientId,
  useParticipants,
  useParticipantsFlags,
  useParticipantsFlagsGetter,
  useParticipantsGetter,
} from './exposed';
import {
  type ParticipantFilterValue,
  type ParticipantFlagFilterKey,
  type ParticipantSkimFilterKey,
  type ParticipantSorterValue,
  ParticipantSyntheticFilterMatchers,
} from './filter-sorting';

export interface ParticipantArrayOptions {
  filters?: ParticipantFilterValue[];
  sorts?: ParticipantSorterValue[];
}

const SkimKeys = new Set(
  Object.keys({
    clientId: true,
    joinedAt: true,
    teamId: true,
    orgId: true,
    userAgent: true,
    firstName: true,
    lastName: true,
    id: true,
    username: true,
    status: true,
    disconnectedAt: true,
    disconnectedReason: true,
    cohost: true,
    icon: true,
  } satisfies { [K in ParticipantSkimFilterKey]: true }) as ParticipantSkimFilterKey[]
);

const FlagKeys = new Set(
  Object.keys({
    audio: true,
    video: true,
    lite: true,
    hasMicrophone: true,
    hasCamera: true,
    onStage: true,
    onStageMuted: true,
    spectator: true,
    voiceOverLocale: true,
  } satisfies { [K in ParticipantFlagFilterKey]: true }) as ParticipantFlagFilterKey[]
);

const NotFound = Symbol('NotFound');

function filterSortParticipants(
  participants: ParticipantMap,
  participantFlags: ParticipantFlagMap,
  options: ParticipantArrayOptions = {}
): Participant[] {
  const { filters, sorts } = options;

  const f = filters ?? [];
  const presplit = f.map((filter) => filter.split(':'));

  rsIncrement('eval-select-participants-with-filters-c');

  const ps = Object.values(participants);
  const filtered = ps.filter((p): p is Participant => {
    if (!p || !p.clientId) return false;
    const flags = participantFlags[p.clientId];

    // Filters: if any are false, exclude item. This handles both "natural"
    // filters (e.g. `username:foo`) and synthetic filters (e.g. `host:true`).
    for (let i = 0; i < f?.length; i++) {
      const split = presplit[i];
      const [filterKey, filterStringValue] = split;

      let found = false;

      const v = FlagKeys.has(filterKey as ParticipantFlagFilterKey)
        ? String(flags?.[filterKey as ParticipantFlagFilterKey])
        : SkimKeys.has(filterKey as ParticipantSkimFilterKey)
        ? String(p[filterKey as ParticipantSkimFilterKey])
        : NotFound;

      if (v !== NotFound) {
        // Allow falsy value to be coerced to `:false`
        if (
          (v === 'undefined' && filterStringValue !== 'false') ||
          (v !== 'undefined' && v !== filterStringValue)
        )
          return false;
        found = true;
      } else {
        for (let k = 0; k < ParticipantSyntheticFilterMatchers.length; k++) {
          const [prefix, matcher] = ParticipantSyntheticFilterMatchers[k];

          if (prefix === filterKey) {
            found = true;
            if (matcher(p) === (filterStringValue === 'false')) return false;
          }
        }
      }

      if (!found) {
        const filter = f[i];
        throw new Error(`Unsupported filter: ${filter}. Please add it.`);
      }
    }

    return true;
  });

  if (!sorts || !sorts.length) return filtered;

  const backwardCompatSorts = new Set([
    'username:asc',
    'joinedAt:asc',
    'joinedAt:desc',
  ]);
  const sortLookup = new Set(sorts);

  if (!isSetContainedWithinSet(sortLookup, backwardCompatSorts))
    throw new Error('NonBackwardsCompatibleSortOption');

  return filtered.sort((a, b) => {
    if (sortLookup.has('username:asc'))
      return a.username.localeCompare(b.username);
    else if (sortLookup.has('joinedAt:asc')) return a.joinedAt - b.joinedAt;
    else if (sortLookup.has('joinedAt:desc')) return b.joinedAt - a.joinedAt;
    return 0;
  });
}

export { filterSortParticipants as TESTING_ONLY_selectParticipantsWithFilters };

const flagsFilters = new Set(
  Object.entries({
    audio: true,
    video: true,
    lite: true,
    hasMicrophone: true,
    hasCamera: true,
    onStage: true,
    onStageMuted: true,
    spectator: true,
  } as const).flatMap(([key]) => [`${key}:true`, `${key}:false`])
);

function useFlagsWithFilters(filters: ParticipantFilterValue[]) {
  let needsFlags = false;
  for (let i = 0; i < filters.length; i++) {
    const filter = filters[i];
    if (flagsFilters.has(filter)) {
      needsFlags = true;
      break;
    }
  }

  return useParticipantsFlags(needsFlags);
}

export const useParticipantsAsArray = (
  options?: ParticipantArrayOptions
): Participant[] => {
  const { miss } = useRSHook('select-participants-as-array');
  const participants = useParticipants();

  const { filters, sorts } = {
    ...options,
  };

  const flags = useFlagsWithFilters(filters ?? []);

  const getLatestFilter = useLiveCallback(() => filters ?? []);
  const getLatestSort = useLiveCallback(() => sorts ?? []);

  return useMemo(() => {
    miss();
    return filterSortParticipants(participants, flags, {
      sorts: getLatestSort(),
      filters: getLatestFilter(),
    });
  }, [miss, participants, flags, getLatestSort, getLatestFilter]);
};

export const useParticipantsAsArrayGetter = () => {
  const getParticipants = useParticipantsGetter();
  const getParticipantsFlag = useParticipantsFlagsGetter();
  return useLiveCallback((options?: ParticipantArrayOptions) => {
    const participants = getParticipants();
    const participantFlags = getParticipantsFlag();
    return filterSortParticipants(participants, participantFlags, options);
  });
};

export const useNumOfParticipants = (
  options?: ParticipantArrayOptions
): number => {
  const participants = useParticipantsAsArray(options);
  return useMemo(() => participants.length, [participants.length]);
};

export const useNumOfParticipantsGetter = () => {
  const getParticipants = useParticipantsAsArrayGetter();
  return useLiveCallback((options?: ParticipantArrayOptions) => {
    const participants = getParticipants(options);
    return participants.length;
  });
};

export function useParticipant(clientId: Nullable<string>): Participant | null {
  return useParticipantByClientId(clientId);
}

// the set of filters to be used for venue seat checking.
const seatOccupyingParticipantFilters: ParticipantFilterValue[] = [
  'status:connected',
  'host:false',
  'cohost:false',
  'staff:false',
];

/**
 * Returns the number of participants that are "occupying" a seat in the venue.
 */
export const useNumSeatOccupyingParticipants = () => {
  return useNumOfParticipants({ filters: seatOccupyingParticipantFilters });
};

/**
 * Returns the number of participants that are "occupying" a seat in the venue, as a getter.
 */
export const useNumSeatOccupyingParticipantsGetter = () => {
  const getNumParticipants = useNumOfParticipantsGetter();
  return useLiveCallback(() => {
    return getNumParticipants({ filters: seatOccupyingParticipantFilters });
  });
};

/**
 * Returns the participants that are "occupying" a seat in the venue.
 */
export const useSeatOccupyingParticipants = () => {
  return useParticipantsAsArray({
    filters: seatOccupyingParticipantFilters,
    sorts: ['joinedAt:asc'],
  });
};
