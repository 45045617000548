import React, { useEffect, useMemo, useRef, useState } from 'react';

import { EnumsSpotlightBlockStageLayout } from '@lp-lib/api-service-client/public';
import {
  type SpotlightBlock,
  SpotlightBlockGameSessionStatus,
} from '@lp-lib/game';
import { type Media, MediaType } from '@lp-lib/media';

import { useFeatureQueryParam } from '../../../../hooks/useFeatureQueryParam';
import { ClientType } from '../../../../types';
import { playWithCatch } from '../../../../utils/playWithCatch';
import { useCohostVisibility } from '../../../Cohost/CohostPositionManagerProvider';
import { useConfettiAnimation } from '../../../ConfettiAnimation';
import DupHostStreamView from '../../../Host/DupHostStreamView';
import { FloatLayout } from '../../../Layout';
import {
  useCohost,
  useParticipant,
  useParticipantsByClientIds,
} from '../../../Player';
import { StageMode, useSelectOnStageMembers } from '../../../Stage';
import { PlayerGroupView } from '../../../Stage/PlayerGroupView';
import { StreamView } from '../../../Stage/StreamView';
import { type StageMemberVO } from '../../../Stage/types';
import { useTeam, useTeamWithStaff } from '../../../TeamAPI/TeamV1';
import { useRTCService } from '../../../WebRTC';
import {
  useGameSessionStatus,
  useIsGamePlayPaused,
  useIsLiveGamePlay,
  useOndGameState,
} from '../../hooks';
import {
  buildGamePlayMedia,
  GamePlayMediaPlayer,
} from '../Common/GamePlay/Internal';
import { SpotlightBlockUtils } from './utils';

const SpotlightMessage = ({ message }: { message: string }): JSX.Element => {
  const isPaused = useIsGamePlayPaused();

  const pref = useRef<HTMLDivElement>(null);
  const textRef = useRef<HTMLDivElement>(null);

  const [width, setWidth] = useState(0);
  const [textWidth, setTextWidth] = useState(0);
  const [stage, setStage] = useState(1);

  const count = textWidth === 0 ? 0 : Math.ceil(width / textWidth);
  const speed = 100;

  useEffect(() => {
    setWidth(pref.current?.clientWidth ?? 0);
    setTextWidth(textRef.current?.clientWidth ?? 0);
  }, [message]);

  return (
    <div
      className='relative w-full h-12 flex items-center font-black text-5xl text-shadow-header font-cairo'
      ref={pref}
    >
      <div className='absolute whitespace-nowrap pr-48 invisible' ref={textRef}>
        {message}
      </div>

      {stage === 1 && (
        <div
          className='absolute flex items-center animate-slide-x-stage1'
          style={
            {
              '--tw-translate-x-from': `${width}px`,
              '--tw-translate-x-to': `${0}px`,
              '--tw-slide-duration': `${width / speed}s`,
              animationPlayState: isPaused ? 'paused' : undefined,
            } as React.CSSProperties
          }
          onAnimationEnd={() => {
            setStage(2);
          }}
        >
          {Array.from(new Array(count + 1).keys()).map((index) => (
            <div className='whitespace-nowrap pr-48' key={index}>
              {message}
            </div>
          ))}
        </div>
      )}

      {stage === 2 && (
        <div
          className='absolute flex items-center animate-slide-x'
          style={
            {
              '--tw-translate-x-from': `${0}px`,
              '--tw-translate-x-to': `${-textWidth}px`,
              '--tw-slide-duration': `${textWidth / speed}s`,
              animationPlayState: isPaused ? 'paused' : undefined,
            } as React.CSSProperties
          }
        >
          {Array.from(new Array(count + 1).keys()).map((index) => (
            <div className='whitespace-nowrap pr-48' key={index}>
              {message}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

const SpotlightOverlayMedia = ({
  media,
}: {
  media: Media;
}): JSX.Element | null => {
  const ref = useRef<HTMLVideoElement | null>(null);
  const [ended, setEnded] = useState(false);
  const isPaused = useIsGamePlayPaused();

  useEffect(() => {
    if (ended || isPaused) return;

    const el = ref.current;
    playWithCatch(el);
    return () => {
      el?.pause();
    };
  }, [ended, isPaused]);

  if (ended) return null;

  return (
    <div>
      {media.type === MediaType.Video && (
        <video
          ref={ref}
          src={media.url}
          className='w-full h-full object-contain'
          onEnded={() => setEnded(true)}
        ></video>
      )}
      {media.type === MediaType.Image && (
        <img
          className='w-full h-full object-contain'
          src={media.url}
          alt='luna-park'
        />
      )}
    </div>
  );
};

const SpotlightStreamView = ({
  player,
  size,
  aspectRatio = '1/1',
  noPlaceholderIndicators,
}: {
  player: StageMemberVO;
  size: string;
  aspectRatio?: string;
  noPlaceholderIndicators?: boolean;
}): JSX.Element | null => {
  const rtcService = useRTCService('stage');
  const participant = useParticipant(player.id);
  const team = useTeamWithStaff(participant?.teamId);

  const [subtitle, subtitleColor] = useMemo(() => {
    if (!participant) return [null, null];
    switch (participant.clientType) {
      case ClientType.Audience:
        if (participant.cohost) {
          return ['Host', '#FBB707'];
        } else {
          return team ? [team.name, team.color] : [null, null];
        }
      case ClientType.Host:
        return ['Host', '#FBB707'];
      default:
        return [null, null];
    }
  }, [participant, team]);

  if (!player) return null;

  return (
    <div className='flex flex-col items-center gap-1'>
      <div className={size} style={{ aspectRatio }}>
        <StreamView
          className={`w-full h-full rounded-5.5xl`}
          member={player}
          rtcService={rtcService}
          disableRemove
          noPlaceholderIndicators={noPlaceholderIndicators}
        />
      </div>
      <div
        className={`
          w-full max-w-50 h-12 flex flex-col items-center justify-center
          bg-secondary text-white rounded-lg
        `}
      >
        {participant && (
          <div className='text-center text-sm font-bold'>
            {participant?.firstName ?? participant?.username}
          </div>
        )}
        {subtitle && (
          <div
            className='text-center text-2xs'
            style={{ color: subtitleColor ?? '#FFFFFF' }}
          >
            {subtitle}
          </div>
        )}
      </div>
    </div>
  );
};

const DefaultSpotlightPlayers = ({
  players,
}: {
  players: StageMemberVO[];
}): JSX.Element | null => {
  if (players.length === 0) return null;

  const firstLen =
    players.length <= 4 ? players.length : Math.ceil(players.length / 2);

  const rows = [
    players.slice(0, firstLen),
    players.slice(firstLen, players.length),
  ];

  const size = SpotlightBlockUtils.GetStreamViewSize(players.length);
  if (players.length === 1) {
    return (
      <div className='w-full max-w-200'>
        <SpotlightStreamView
          player={players[0]}
          size='w-full h-full'
          aspectRatio='16/9'
          noPlaceholderIndicators
        />
      </div>
    );
  }

  return (
    <div className='w-full space-y-2.5'>
      {rows.map((row) => (
        <div
          key={row.map((p) => p.id).join(',')}
          className='flex flex-row items-center justify-center gap-2.5'
        >
          {row.map((p) => (
            <SpotlightStreamView key={p.id} player={p} size={size} />
          ))}
        </div>
      ))}
    </div>
  );
};

const CohostInterviewSpotlightPlayers = ({
  players,
}: {
  players: StageMemberVO[];
}): JSX.Element | null => {
  const participant = useParticipant(players[0]?.id);
  const team = useTeam(participant?.teamId);
  if (players.length === 0) return null;

  return (
    <div className='w-1/3 flex flex-col absolute right-0'>
      <div className='relative' style={{ aspectRatio: '1/1' }}>
        <PlayerGroupView stageMembers={players} groupColor={team?.color} />
      </div>
      {team && (
        <div
          className='w-full max-w-70 h-7.5 bg-dark-gray 
        flex items-center justify-center mt-5 self-center rounded 
        text-sms font-bold gap-2'
        >
          {players.length === 1 && (
            <p>{participant?.firstName || participant?.username}</p>
          )}
          <p style={{ color: team.color }}>{team.name}</p>
        </div>
      )}
    </div>
  );
};

type SpotlightStyle = {
  containerClassName: string;
  players: 'default' | 'cohost-interview';
};

function useSpotlightCohostAwareStyle(block: SpotlightBlock): SpotlightStyle {
  const cohost = useCohost();

  const cohostVisibility = useCohostVisibility();

  return useMemo(() => {
    const isInterviewLayout =
      block.fields.stageLayout ===
      EnumsSpotlightBlockStageLayout.SpotlightBlockStageLayoutInterview;
    const hasVisibleCohost = !!cohost && cohostVisibility === 'visible';
    return {
      containerClassName: hasVisibleCohost ? 'z-5' : '',
      players: isInterviewLayout ? 'cohost-interview' : 'default',
    };
  }, [block.fields.stageLayout, cohost, cohostVisibility]);
}

const SpotlightBlockView = ({
  gss,
  block,
}: {
  gss: SpotlightBlockGameSessionStatus;
  block: SpotlightBlock;
}): JSX.Element => {
  const bgMediaEnabled = useFeatureQueryParam(
    'spotlight-block-background-media'
  );
  const welcomeMessageEnabled = useFeatureQueryParam(
    'spotlight-block-welcome-message'
  );
  const playersEnabled = useFeatureQueryParam('spotlight-block-players');
  const overlayMediaEnabled = useFeatureQueryParam(
    'spotlight-block-overlay-media'
  );

  const stageMembers = useSelectOnStageMembers(StageMode.SPOTLIGHT_BLOCK, {
    sort: true,
  });
  const selectedParticipants = useParticipantsByClientIds(
    stageMembers.map((m) => m.id)
  );

  const message = useMemo(() => {
    return SpotlightBlockUtils.FormatSpotlightMessage(
      block.fields.message,
      selectedParticipants.map((p) => p.username)
    );
  }, [block.fields.message, selectedParticipants]);

  const inCelebrating = gss === SpotlightBlockGameSessionStatus.CELEBRATING;
  const bgMedia = useMemo(
    () =>
      buildGamePlayMedia(
        {
          media: block.fields.backgroundMedia,
          mediaData: block.fields.backgroundMediaData,
        },
        {
          stage: 'custom',
          isBackgroundMedia: true,
        }
      ),
    [block.fields.backgroundMedia, block.fields.backgroundMediaData]
  );
  const isLiveGamePlay = useIsLiveGamePlay();
  const ondState = useOndGameState();
  const mediaPlayable = isLiveGamePlay || ondState === 'running' ? true : false;

  const { fire, canvasConfetti } = useConfettiAnimation();
  const style = useSpotlightCohostAwareStyle(block);

  useEffect(() => {
    if (!inCelebrating || !block.fields.playConfetti) return;
    fire();
  }, [block.fields.playConfetti, fire, inCelebrating]);

  const overlayMedia = block.fields.overlayMedia;
  return (
    <div
      className={`fixed w-screen h-screen text-white ${style.containerClassName}`}
    >
      {bgMediaEnabled && inCelebrating && bgMedia && (
        <div className='absolute w-full h-full z-0'>
          <GamePlayMediaPlayer
            gamePlayMedia={bgMedia}
            layout='fullscreen'
            play={mediaPlayable}
          />
          <div className='absolute w-full h-full bg-black bg-opacity-60' />
        </div>
      )}

      <div className={`absolute w-full h-full z-20`}>
        <FloatLayout className='flex items-center justify-center'>
          {isLiveGamePlay && (
            <DupHostStreamView containerClassName='absolute -left-45 top-30 w-45 h-45 rounded-5.5xl border-4 overflow-hidden' />
          )}
          {welcomeMessageEnabled && inCelebrating && (
            <div className='absolute top-0'>
              <SpotlightMessage message={message} />
            </div>
          )}
          {playersEnabled && (
            <>
              {style.players === 'cohost-interview' ? (
                <CohostInterviewSpotlightPlayers players={stageMembers} />
              ) : (
                <DefaultSpotlightPlayers players={stageMembers} />
              )}
            </>
          )}
          {canvasConfetti}
        </FloatLayout>
      </div>

      {overlayMediaEnabled && inCelebrating && overlayMedia && (
        <div className='absolute w-full h-full z-30'>
          <SpotlightOverlayMedia media={overlayMedia} />
        </div>
      )}
    </div>
  );
};

export const SpotlightBlockGamePlay = ({
  gameSessionBlock,
}: {
  gameSessionBlock: SpotlightBlock;
}): JSX.Element | null => {
  const gss = useGameSessionStatus<SpotlightBlockGameSessionStatus>();

  if (
    gss === SpotlightBlockGameSessionStatus.PRESENTING ||
    gss === SpotlightBlockGameSessionStatus.CELEBRATING
  ) {
    return <SpotlightBlockView gss={gss} block={gameSessionBlock} />;
  }

  return null;
};
