import {
  type DtoSharedAsset,
  type EnumsSharedAssetPurpose,
} from '@lp-lib/api-service-client/public';
import { type Media, type MediaData, MediaType } from '@lp-lib/media';

import { MediaUploader, type MediaUploaderOptions } from './MediaUploader';
import { VolumeSelect } from './VolumeSelect';

export type MediaEditorProps = {
  volumeSelectable?: boolean;
  loopSelectable?: boolean;
  onChange: (mediaData: MediaData | null, media: Media | null) => void;
  sharedAssetPurposes?: EnumsSharedAssetPurpose[];
  onSharedAssetSelected?: (item: DtoSharedAsset) => void;
} & MediaUploaderOptions;

export function MediaEditor(props: MediaEditorProps): JSX.Element {
  const {
    volumeSelectable,
    loopSelectable,
    mediaData,
    media,
    title,
    extraNotice,
    sharedAssetPurposes,
    onSharedAssetSelected,
    onChange,
  } = props;

  const handleUploadSuccess = (media: Media) => {
    onChange(
      {
        id: media.id,
      },
      media
    );
  };

  const handleDelete = () => {
    onChange(
      {
        id: '',
      },
      null
    );
  };

  const handleMediaDataChange = (updates: Partial<MediaData>) => {
    if (!mediaData || !media) return;

    onChange(
      {
        ...mediaData,
        ...updates,
      },
      media
    );
  };

  return (
    <MediaUploader
      {...props}
      onUploadSuccess={handleUploadSuccess}
      onMediaDelete={handleDelete}
      sharedAssetPurposes={sharedAssetPurposes}
      onSharedAssetSelected={onSharedAssetSelected}
      title={
        !volumeSelectable && title === undefined ? undefined : (
          <div className='w-full flex justify-between items-center'>
            {title || ''}
            {volumeSelectable && (
              <VolumeSelect
                volumeLevel={mediaData?.volumeLevel}
                onChange={(updated) =>
                  handleMediaDataChange({
                    volumeLevel: updated,
                  })
                }
                disabled={media?.type !== MediaType.Video}
              />
            )}
          </div>
        )
      }
      extraNotice={
        <div className='w-full flex justify-between items-center gap-2'>
          {extraNotice || ''}
          {loopSelectable && (
            <label className='flex justify-end items-center gap-2'>
              <input
                type={'checkbox'}
                className='checkbox-dark'
                checked={mediaData?.loop ?? false}
                onChange={(event) =>
                  handleMediaDataChange({
                    loop: event.target.checked,
                  })
                }
                disabled={media?.type !== MediaType.Video}
              />
              <p
                className={`${
                  media?.type !== MediaType.Video
                    ? 'text-secondary'
                    : 'text-white'
                }`}
              >
                Loop
              </p>
            </label>
          )}
        </div>
      }
    />
  );
}
