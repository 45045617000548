export const MusicPlaylistIcon = (props: {
  className?: string;
}): JSX.Element => {
  return (
    <svg
      className={props.className ?? 'w-6 h-6 fill-current'}
      viewBox='0 0 24 24'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path d='M16.8556 5.33319V13.4755C15.9155 12.9612 14.7554 12.7613 13.5252 13.1707C12.1851 13.6279 11.155 14.7611 10.915 16.0944C10.4549 18.7037 12.7752 20.9321 15.5055 20.5226C17.4657 20.2274 18.8558 18.5132 18.8558 16.6181V7.23782H20.2848C21.3849 7.23782 22.285 6.38074 22.285 5.33319C22.285 4.28564 21.3849 3.42856 20.2848 3.42856H18.8558C17.7557 3.42856 16.8556 4.28564 16.8556 5.33319Z' />
      <rect
        x='1.71387'
        y='8.95239'
        width='13.3333'
        height='1.90476'
        rx='0.952381'
      />
      <rect
        x='1.71387'
        y='12.7619'
        width='7.61905'
        height='1.90476'
        rx='0.952381'
      />
      <rect
        x='1.71387'
        y='16.5714'
        width='7.61905'
        height='1.90476'
        rx='0.95238'
      />
    </svg>
  );
};
