import { useCallback, useEffect, useRef } from 'react';

import { useThrottledCounterLog } from '../../hooks/useThrottledCounterLog';
import logger from '../../logger/logger';
import {
  decoder as downloader,
  preloadAssets,
} from '../../services/audio/downloader';
import {
  playLocalNonEchoCanceledSoundEffect,
  playLocalSoundEffect,
} from '../../services/audio/play-sound-effect';
import { useAudioEnabled } from '../Venue/VenuePlaygroundProvider';
import { type SoundEffectControl } from './types';

const log = logger.scoped('sfx-play');

export function useRawSoundEffect(
  src: string,
  debugName: string,
  gainValue = 1,
  delay = 0,
  loop = false,
  echoCanceled = true,
  preload = true
): SoundEffectControl {
  const bufferRef = useRef<AudioBuffer | null>(null);
  const stopRef = useRef<() => void>();
  const audioEnabled = useAudioEnabled();
  const audioEnabledRef = useRef(audioEnabled);

  useEffect(() => {
    audioEnabledRef.current = audioEnabled;
  }, [audioEnabled]);

  useEffect(() => {
    if (!preload) return;
    preloadAssets(src);
  }, [src, preload]);

  const increment = useThrottledCounterLog(`playcount ${debugName}`, log);

  const play = useCallback(async () => {
    if (!audioEnabledRef.current) return; // Stop playing sfx if user stays on entry gate

    if (!bufferRef.current) {
      bufferRef.current = await downloader.get(src);
    }

    increment(1);

    if (echoCanceled) {
      stopRef.current = playLocalSoundEffect(
        bufferRef.current,
        gainValue,
        delay,
        loop
      );
    } else {
      stopRef.current = playLocalNonEchoCanceledSoundEffect(
        bufferRef.current,
        gainValue,
        delay,
        loop
      );
    }
  }, [increment, echoCanceled, src, gainValue, delay, loop]);

  const stop = useCallback(() => {
    if (stopRef.current) {
      stopRef.current();
    }
  }, []);

  return { play, stop };
}
