import 'react-datepicker/dist/react-datepicker.css';

import { useNavigate } from '@remix-run/react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import useSWR from 'swr';

import { apiService } from '../../../services/api-service';
import {
  type GlobalPairingRound,
  GlobalPairingRoundStatus,
} from '../../../types';
import {
  ConfirmCancelModalText,
  useAwaitFullScreenConfirmCancelModal,
} from '../../ConfirmCancelModalContext';
import { useGlobalPairingRoundDetails } from './Details';
import {
  GlobalPairingRoundEditor,
  type GlobalPairingRoundFormData,
} from './Editor';

function isRoundStatusChangeSoon(r: GlobalPairingRound): boolean {
  return (
    [
      GlobalPairingRoundStatus.New,
      GlobalPairingRoundStatus.Configuring,
    ].includes(r.status) &&
    new Date().getTime() - new Date(r.createdAt).getTime() < 10 * 60 * 1000
  );
}

const statusOrder = [
  GlobalPairingRoundStatus.New,
  GlobalPairingRoundStatus.Configuring,
  GlobalPairingRoundStatus.Scheduled,
  GlobalPairingRoundStatus.InProgress,
  GlobalPairingRoundStatus.Completed,
];

export interface UseGlobalRoundsReturned {
  globalRounds: GlobalPairingRound[] | undefined;
  isLoading: boolean;
  error: Error;
  reload: () => void;
  add: (org: GlobalPairingRound) => void;
  del: (orgId: string) => void;
}

export function useGlobalRounds(): UseGlobalRoundsReturned {
  const { data, error, isValidating, mutate } = useSWR(
    '/global-pairing-rounds',
    async () =>
      (await apiService.pairing.queryGlobalRounds()).data.globalRounds || [],
    {
      refreshInterval: (latestData) => {
        if (!latestData) return 0;
        if (latestData.find(isRoundStatusChangeSoon)) {
          return 1000;
        }

        return 0;
      },
    }
  );

  const globalRounds = useMemo(() => {
    if (!data) return data;

    const sorted = data.sort((a, b) => {
      if (a.status === b.status) {
        return b.startedAt.localeCompare(a.startedAt);
      }

      return (
        statusOrder.findIndex((status) => status === a.status) -
        statusOrder.findIndex((status) => status === b.status)
      );
    });

    return sorted;
  }, [data]);

  const reload = useCallback(() => {
    mutate();
  }, [mutate]);

  const add = useCallback(
    (globalRound: GlobalPairingRound) => {
      mutate(
        (current) => (current ? [globalRound, ...current] : [globalRound]),
        {
          revalidate: false,
        }
      );
    },
    [mutate]
  );

  const del = useCallback(
    (id: string) => {
      mutate((current) => current?.filter((o) => o.id !== id), {
        revalidate: false,
      });
    },
    [mutate]
  );

  return useMemo(
    () => ({
      globalRounds,
      isLoading: !data && isValidating,
      error,
      reload,
      add,
      del,
    }),
    [globalRounds, data, isValidating, error, reload, add, del]
  );
}

export interface UseGlobalRoundReturned {
  globalRound: GlobalPairingRound | undefined;
  isLoading: boolean;
  error: Error;
  revalidatedAfterMount: boolean;
  set: (data: GlobalPairingRound) => void;
}

export function useGlobalPairingRound(props: {
  id?: string | null;
}): UseGlobalRoundReturned {
  const { data, error, isValidating, mutate } = useSWR(
    props.id ? `/global-pairing-rounds/${props.id}` : null,
    async () =>
      (await apiService.pairing.getGlobalRoundById(props.id || '')).data
        .globalRound,
    {
      refreshInterval: (latestData) => {
        if (!latestData) return 0;
        if (isRoundStatusChangeSoon(latestData)) {
          return 1000;
        }

        return 0;
      },
    }
  );

  const set = useCallback(
    (data: GlobalPairingRound): void => {
      mutate(data, {
        revalidate: false,
      });
    },
    [mutate]
  );

  const [revalidatedAfterMount, setRevalidatedAfterMount] = useState(false);
  useEffect(() => {
    if (isValidating) return;
    setRevalidatedAfterMount(true);
  }, [isValidating]);

  return useMemo(
    () => ({
      globalRound: data,
      isLoading: !data && isValidating,
      error: error,
      revalidatedAfterMount,
      set,
    }),
    [data, error, isValidating, revalidatedAfterMount, set]
  );
}

export function useDeleteGlobalPairingRound(): (
  id: string
) => Promise<boolean> {
  const triggerConfirmCancelModal = useAwaitFullScreenConfirmCancelModal();

  return useCallback(
    async (id: string) => {
      const confirmCancel = await triggerConfirmCancelModal({
        kind: 'confirm-cancel',
        prompt: (
          <ConfirmCancelModalText className='text-2xl font-medium'>
            Are you sure you want to delete this Global Pair Round?
          </ConfirmCancelModalText>
        ),
        confirmBtnLabel: 'Confirm',
        cancelBtnLabel: 'Cancel',
      });
      if (confirmCancel.result === 'canceled') return false;

      const resp = await apiService.pairing.deleteGlobalRound(id);
      if (!resp) return false;

      return true;
    },
    [triggerConfirmCancelModal]
  );
}

export function useRepairGlobalPairingRound(): (
  id: string
) => Promise<boolean> {
  const triggerConfirmCancelModal = useAwaitFullScreenConfirmCancelModal();

  return useCallback(
    async (id: string) => {
      const confirmCancel = await triggerConfirmCancelModal({
        kind: 'confirm-cancel',
        prompt: (
          <ConfirmCancelModalText className='text-2xl font-medium'>
            Are you sure you want to repair this Global Pair Round?
          </ConfirmCancelModalText>
        ),
        confirmBtnLabel: 'Confirm',
        confirmBtnVariant: 'primary',
        cancelBtnLabel: 'Cancel',
      });
      if (confirmCancel.result === 'canceled') return false;

      const resp = await apiService.pairing.repairGlobalRound(id);
      if (!resp) return false;

      return true;
    },
    [triggerConfirmCancelModal]
  );
}

export function GlobalPairingRoundCreate(): JSX.Element {
  const navigate = useNavigate();

  const handleSave = async (data: GlobalPairingRoundFormData) => {
    if (
      !data.mainGamePack ||
      !data.startedAt ||
      !data.endedAt ||
      !data.participationMode
    )
      return;

    await apiService.pairing.createGlobalRound({
      mainGamePackId: data.mainGamePack.id,
      shadowGamePackIds: data.shadowGamePacks.map((gp) => gp?.id || ''),
      asLevels: data.asLevels,
      startedAt: data.startedAt.toJSON(),
      endedAt: data.endedAt.toJSON(),
      messageCampaignId: data.messageCampaignId,
      orgIds: data.orgIds,
      participationMode: data.participationMode,
    });

    navigate('../list');
  };

  const handleCancel = () => {
    navigate('../list');
  };

  return (
    <GlobalPairingRoundEditor
      round={null}
      onSave={handleSave}
      onCancel={handleCancel}
    />
  );
}

export function GlobalPairingRoundEdit(): JSX.Element | null {
  const navigate = useNavigate();

  const { globalRound, revalidatedAfterMount, set } =
    useGlobalPairingRoundDetails();

  if (!revalidatedAfterMount) return null;

  const handleSave = async (data: GlobalPairingRoundFormData) => {
    const resp = await apiService.pairing.updateGlobalRound(globalRound.id, {
      mainGamePackId: data.mainGamePack?.id,
      shadowGamePackIds: data.shadowGamePacks.map((gp) => gp?.id || ''),
      asLevels: data.asLevels,
      startedAt: data.startedAt?.toJSON(),
      endedAt: data.endedAt?.toJSON(),
      messageCampaignId: data.messageCampaignId,
    });
    if (!resp) return;

    set(resp.data.globalRound);
    navigate(-1);
  };

  const handleCancel = () => {
    navigate(-1);
  };

  return (
    <GlobalPairingRoundEditor
      round={globalRound}
      onSave={handleSave}
      onCancel={handleCancel}
    />
  );
}
