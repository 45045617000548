import { useEffect, useState } from 'react';
import { usePrevious } from 'react-use';
import { useSnapshot } from 'valtio';

import { GameSessionUtil } from '@lp-lib/game';

import { gameSessionStore } from '../Game/store';
import { getSynchronousRawAnchorRect } from '../LayoutAnchors/LayoutAnchors';
import {
  useNextPersistentPointsScore,
  usePersistentPoints,
  usePersistentPointsScore,
} from '../PersistentPoints/Provider';
import { usePixelFxSceneControl } from '../PixelFx/PixelFxSceneProvider';
import {
  useIsTeamRecoveryRunning,
  useIsTeamStoreInited,
} from '../TeamAPI/TeamV1';

function useAutoResettingHasPlayedForCurrentBlock() {
  const [hasPlayedForCurrentBlock, setHasPlayedForCurrentBlock] =
    useState(false);

  // const currentBlock = useSelector(selectGameSessionBlock);
  const gameSession = useSnapshot(gameSessionStore.session);
  const currentBlock = gameSession.blockSession?.block;
  const previousBlock = usePrevious(currentBlock);
  useEffect(() => {
    if (hasPlayedForCurrentBlock && currentBlock?.id === previousBlock?.id)
      return;
    setHasPlayedForCurrentBlock(false);
  }, [currentBlock?.id, hasPlayedForCurrentBlock, previousBlock?.id]);

  return [hasPlayedForCurrentBlock, setHasPlayedForCurrentBlock] as const;
}

/**
 * This hook manages _triggering_ the GainPoints animation and passing relevant
 * measurements of dependent DOM elements to the animation. It is scoped to
 * whatever component might trigger it. Generally this means per-block.
 */
export function useGainPointsAnimationGamePlayTrigger(): void {
  // const blockType = useSelector(selectGameSessionBlockType);
  // const gameSessionStatus = useSelector(selectGameSessionStatus);
  const gameSession = useSnapshot(gameSessionStore.session);
  const blockType = gameSession.blockSession?.block?.type ?? null;
  const gameSessionStatus = gameSession.status;

  const teamsReady = useIsTeamStoreInited();
  const teamsRecoveryRunning = useIsTeamRecoveryRunning();
  const { revealingAnswer, setSafeToReveal } = usePersistentPoints();
  const nowPoints = usePersistentPointsScore();
  const nextPoints = useNextPersistentPointsScore();

  const [playState, startGainPointsAnim, updateTarget] =
    usePixelFxSceneControl('gain-points');

  const [hasPlayedForCurrentBlock, setHasPlayedForCurrentBlock] =
    useAutoResettingHasPlayedForCurrentBlock();

  // Display the points badge (the team header is by default hidden) when the
  // answer screen is displayed. Eventually this will also trigger an animation.
  useEffect(() => {
    const map = GameSessionUtil.StatusMapFor(blockType);
    const pointDistributed = map?.pointDistributed || null;
    const pointDistributedSafe = map?.pointDistributedSafe || null;

    switch (gameSessionStatus) {
      case pointDistributed: {
        // This is somewhat dangerous to call this so often, since it forces
        // layout. But we need the latest value, and things might have shifted
        // due to animations.
        const pointsBadgeRect =
          getSynchronousRawAnchorRect('points-badge-mini') ??
          getSynchronousRawAnchorRect('points-badge-team-header') ??
          getSynchronousRawAnchorRect('points-badge-venue-header');
        const topRect = getSynchronousRawAnchorRect(
          'gameplay-points-animation-top'
        );

        const targetX = !pointsBadgeRect
          ? 0
          : pointsBadgeRect.x + pointsBadgeRect.width / 2;
        const targetY = !pointsBadgeRect
          ? 0
          : pointsBadgeRect.y + pointsBadgeRect.height / 2;

        // 1000 is arbitrary just to prevent a 0, null, or undefined
        const spawnWidth = topRect?.width ?? 1000;
        const spawnHeight =
          pointsBadgeRect && topRect
            ? pointsBadgeRect.y - topRect.y
            : spawnWidth * (9 / 16);

        const centerX = !topRect ? 0 : topRect.x + topRect?.width / 2;
        const centerY = !topRect ? 0 : topRect.y + spawnHeight / 2;

        if (revealingAnswer && targetX !== 0 && targetY !== 0) {
          // Always sync the physical location of the points target whenever we
          // render
          updateTarget(targetX, targetY);
        }

        if (playState === 'finished' && !hasPlayedForCurrentBlock) {
          setHasPlayedForCurrentBlock(true);
          // Immediately set this to avoid a flickering between the initial
          // points and final points gained values in the badge.
          setSafeToReveal(true);
        }

        if (
          (playState === 'finished' || playState === 'none') &&
          hasPlayedForCurrentBlock
        ) {
          // Only reveal the points earned (in the persistent points badge) for
          // the current block after the animation has finished.
          setSafeToReveal(true);
        }

        if (
          !revealingAnswer ||
          hasPlayedForCurrentBlock ||
          playState !== 'none' ||
          !teamsReady ||
          teamsRecoveryRunning ||
          nextPoints === null ||
          nextPoints === 0 ||
          targetX === 0 ||
          targetY === 0 ||
          centerX === 0 ||
          centerY === 0
        )
          break;

        startGainPointsAnim({
          initialPoints: nowPoints,
          gainedPoints: nextPoints,
          targetX,
          targetY,
          spawnCenterX: centerX,
          spawnCenterY: centerY,
          spawnHeight,
        });
        break;
      }

      case pointDistributedSafe:
        setSafeToReveal(true);
        break;

      default: {
        setSafeToReveal(false);
        break;
      }
    }
  }, [
    gameSessionStatus,
    hasPlayedForCurrentBlock,
    setHasPlayedForCurrentBlock,
    nextPoints,
    nowPoints,
    playState,
    revealingAnswer,
    setSafeToReveal,
    startGainPointsAnim,
    teamsReady,
    teamsRecoveryRunning,
    updateTarget,
    blockType,
  ]);
}
