import { type Block } from '@lp-lib/game';

import { getFeatureQueryParamArray } from '../../hooks/useFeatureQueryParam';
import { apiService } from '../../services/api-service';
import { pickRecordingMediaUrl } from '../Game/OndPhaseRunner';
import { VideoEffectsSettingsCore } from '../VideoEffectsSettings/Core';
import { PreviewVideoEffectsSettingsProvider } from '../VideoEffectsSettings/Provider';
import { VideoEffectsSettingsUtils } from '../VideoEffectsSettings/VideoEffectsSettingsUtils';

export function VideoEffectsSettingsBlockPanel(props: {
  block: Block;
  onClose: () => void;
}): JSX.Element | null {
  const url = pickRecordingMediaUrl(props.block.recording ?? null);
  if (!url) return null;

  const initialSettings = VideoEffectsSettingsUtils.WithDefaults(
    VideoEffectsSettingsUtils.FromBlock(props.block)
  );

  return (
    <PreviewVideoEffectsSettingsProvider initialSettings={initialSettings}>
      <VideoEffectsSettingsCore
        streamOrSrc={url}
        targetVideoProfile={getFeatureQueryParamArray(
          'game-on-demand-host-video-profile'
        )}
        initialEditingDisabled={false}
        settingsEditable={true}
        initialSettings={initialSettings}
        controls
        onCancel={props.onClose}
        onSave={async (values) => {
          await apiService.block.updateVideoEffectsSettings(
            props.block.id,
            VideoEffectsSettingsUtils.ToBlock(values)
          );
          props.onClose();
        }}
        title='Video Effects Settings'
        stagePodiumSettings
      />
    </PreviewVideoEffectsSettingsProvider>
  );
}
