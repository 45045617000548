import { type MessageTemplate } from '../../../types/messageTemplate';
import { useAwaitFullScreenConfirmCancelModal } from '../../ConfirmCancelModalContext';
import { EditMessageTemplateModal } from './Editor';

export function useTriggerEditMessageTemplateModal(): (
  template: MessageTemplate,
  cb?: (template: MessageTemplate) => void
) => void {
  const triggerFullScreenModal = useAwaitFullScreenConfirmCancelModal();

  return async (
    template: MessageTemplate,
    cb?: (template: MessageTemplate) => void
  ) => {
    triggerFullScreenModal({
      kind: 'custom',
      element: (p) => (
        <EditMessageTemplateModal
          template={template}
          onSubmit={(template) => {
            p.internalOnConfirm();
            cb && cb(template);
          }}
          onClose={p.internalOnCancel}
        />
      ),
    });
  };
}
