import pluralize from 'pluralize';

import { type ScoreboardBlock, ScoreboardMode } from '@lp-lib/game';

import { useScoreboardData } from '../../hooks';
import { type EditPointData, type SetEditPointsData } from '../../store';
import { SubmissionsActionBox } from '../Common/Controller/Internal';

interface SBSubmissionsViewProps {
  block: ScoreboardBlock;
  setEditPointsData?: SetEditPointsData;
  selectedBlockId?: string | null;
}

export const SBSubmissionsView = (
  props: SBSubmissionsViewProps
): JSX.Element => {
  const { setEditPointsData } = props;

  const scoreboardData = useScoreboardData(ScoreboardMode.VenueTeams);

  return (
    <div className='relative w-full h-full flex flex-col justify-between items-center'>
      <div className='w-full'>
        <div className='w-full mb-2.5 pt-1 px-2.5 max-h-64 scrollbar-hide overflow-y-scroll'>
          {scoreboardData.map((data, i) => {
            const editPointData: EditPointData = {
              teamName: data.teamName || '',
              teamId: data.teamId,
              submitterUid: '',
              defaultPoints: data.score || 0,
            };
            return (
              <SubmissionsActionBox
                key={`sub-${i}`}
                teamId={data.teamId}
                view='result'
                editPointData={editPointData}
                setEditPointsData={setEditPointsData}
              >
                <div className='w-4/5 h-full pl-2 flex flex-col'>
                  <p className='pt-1 text-2xs font-bold'>{data.teamName}</p>
                  <p className='text-xs text-icon-gray font-medium h-full flex items-center'>
                    {data.score.toLocaleString('en-US')}{' '}
                    {pluralize('Point', data.score)}
                  </p>
                </div>
              </SubmissionsActionBox>
            );
          })}
        </div>
      </div>
    </div>
  );
};
