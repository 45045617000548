const BuzzerIcon = (props: { fill?: string }): JSX.Element => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='15'
      height='15'
      fill='none'
      viewBox='0 0 15 15'
    >
      <path
        fill='#05DEFC'
        d='M3.552 7.107v3.558h7.104V7.107a3.52 3.52 0 00-1.042-2.51 3.55 3.55 0 00-6.062 2.51zM7.103 4.74a2.367 2.367 0 012.368 2.367.594.594 0 01-.592.593.594.594 0 01-.592-.593c0-.65-.533-1.183-1.184-1.183a.594.594 0 01-.592-.593.595.595 0 01.592-.591zM1.185 13.617c0 .327.265.592.592.592h10.656a.592.592 0 00.592-.592c0-.977-.791-1.767-1.767-1.767H2.952c-.976 0-1.767.79-1.767 1.767zM7.696 1.776V.592a.592.592 0 00-1.184 0v1.184a.592.592 0 001.184 0zM11.29 3.756l.838-.837a.591.591 0 10-.837-.837l-.837.837a.591.591 0 10.837.837zM13.617 6.514h-1.184a.592.592 0 100 1.184h1.184a.592.592 0 100-1.184zM.592 7.697h1.184a.592.592 0 100-1.184H.592a.592.592 0 100 1.184zM2.918 3.755a.592.592 0 00.837-.837l-.837-.837a.592.592 0 10-.837.837l.837.837z'
      ></path>
      <path
        fill={props.fill ?? '#fff'}
        d='M3.552 7.107v3.558h7.104V7.107a3.52 3.52 0 00-1.042-2.51 3.55 3.55 0 00-6.062 2.51zM7.103 4.74a2.367 2.367 0 012.368 2.367.594.594 0 01-.592.593.594.594 0 01-.592-.593c0-.65-.533-1.183-1.184-1.183a.594.594 0 01-.592-.593.595.595 0 01.592-.591zM1.185 13.617c0 .327.265.592.592.592h10.656a.592.592 0 00.592-.592c0-.977-.791-1.767-1.767-1.767H2.952c-.976 0-1.767.79-1.767 1.767zM7.696 1.776V.592a.592.592 0 00-1.184 0v1.184a.592.592 0 001.184 0zM11.29 3.756l.838-.837a.591.591 0 10-.837-.837l-.837.837a.591.591 0 10.837.837zM13.617 6.514h-1.184a.592.592 0 100 1.184h1.184a.592.592 0 100-1.184zM.592 7.697h1.184a.592.592 0 100-1.184H.592a.592.592 0 100 1.184zM2.918 3.755a.592.592 0 00.837-.837l-.837-.837a.592.592 0 10-.837.837l.837.837z'
      ></path>
    </svg>
  );
};

export { BuzzerIcon };
