import debounce from 'lodash/debounce';
import { useEffect, useState } from 'react';

type WindowDimensionsInfo = { width: number; height: number; ratio: number };

export function createWindowDimensionsInfo(
  width: number,
  height: number
): WindowDimensionsInfo {
  return {
    width,
    height,
    ratio: width / height,
  };
}

function useWindowDimensions(delay = 800): WindowDimensionsInfo {
  const [info, setInfo] = useState<WindowDimensionsInfo>(
    createWindowDimensionsInfo(window.innerWidth, window.innerHeight)
  );

  useEffect(() => {
    const debouncedResize = debounce(
      () =>
        setInfo(
          createWindowDimensionsInfo(window.innerWidth, window.innerHeight)
        ),
      delay,
      { leading: false, trailing: true }
    );
    window.addEventListener('resize', debouncedResize);

    return () => {
      window.removeEventListener('resize', debouncedResize);
    };
  }, [delay]);

  return info;
}

export { useWindowDimensions };
