import composeRefs from '@seznam/compose-react-refs';
import { type UID } from 'agora-rtc-sdk-ng';
import { forwardRef, useCallback, useEffect, useRef, useState } from 'react';
import { usePrevious } from 'react-use';

import { getFeatureQueryParam } from '../../../../../hooks/useFeatureQueryParam';
import { useForceUpdate } from '../../../../../hooks/useForceUpdate';
import { useInstance } from '../../../../../hooks/useInstance';
import { useIsController } from '../../../../../hooks/useMyInstance';
import logger from '../../../../../logger/logger';
import { loadImageAsPromise } from '../../../../../utils/media';
import { playWithCatch } from '../../../../../utils/playWithCatch';
import { Loading } from '../../../../Loading';
import {
  useDebugStream,
  useIsCoreChannelJoined,
  useRTCService,
} from '../../../../WebRTC';
import {
  EchoCanceledVideo,
  type EchoCanceledVideoProps,
} from '../../../EchoCanceledVideo';
import { useGameHostingController } from '../../../GameHostingProvider';
import { useGameVideoStreamProxy } from '../../../GameSessionBundle';
import { useUpdateGameStreaming } from '../../../GameStreamingStatusProvider';
import {
  useGameVideoPlaybackState,
  useOndGameState,
  useOndGameVideoProgress,
} from '../../../hooks';
import {
  setOndGamePlayVideoProgress,
  updateGameVideoPlaybackState,
  type VideoPlaybackState,
} from '../../../store';

const log = logger.scoped('GamePlayMedia');

/**
 * It is _very_ likely that `ended` will never fire: the HTMLVideoElement is
 * usually removed from the DOM (unmounted) before the video actually ends,
 * especially during an OND game because nothing is actually waiting for the
 * ended event at this level in the tree. Additionally, the `ref` that
 * `updateVideoPlayback` writes to is set to `null` (deleted) on unmount of this
 * hook, which would also result in `ended` being missed. This issue has existed
 * since the initial creation of GamePlayVideo/Media. GamePlayMedia explicitly
 * handles these cases by setting its own timer that matches the duration of the
 * media, and calls `onMediaEnded` manually. But this only works for callers of
 * `GamePlayMediaPlayer`, such as the QuestionBlockGamePlay, and NOT at the
 * level at which this function operates (which is the HTMLVideoElement itself).
 */
function useTrackGameVideoPlayback(
  mediaId: string,
  playBackgroundMusicWithMedia: boolean | undefined,
  video: HTMLVideoElement,
  likelyFullscreen: boolean
): void {
  useEffect(() => {
    const ctrl = new AbortController();
    const signal = ctrl.signal;

    const update = async (state: VideoPlaybackState) => {
      await updateGameVideoPlaybackState({
        mediaId: mediaId,
        state: state,
        playBackgroundMusicWithMedia: playBackgroundMusicWithMedia ?? false,
        updatedAt: Date.now(),
        fullscreen: likelyFullscreen,
      });
    };

    const opts = { signal };
    video.addEventListener('waiting', () => update('waiting'), opts);
    video.addEventListener('canplay', () => update('canplay'), opts);
    video.addEventListener('loadedmetadata', () => update('loaded'), opts);
    video.addEventListener('play', () => update('playing'), opts);
    video.addEventListener('ended', () => update('ended'), opts);
    return () => {
      ctrl.abort();
      updateGameVideoPlaybackState(null).catch();
    };
  }, [likelyFullscreen, mediaId, playBackgroundMusicWithMedia, video]);
}

function LocalStream(props: {
  mediaId: string;
  playBackgroundMusicWithMedia: boolean;
  video: HTMLVideoElement;
  likelyFullscreen: boolean;
}): JSX.Element {
  const { mediaId, video } = props;
  const videoProxy = useGameVideoStreamProxy();
  const ondState = useOndGameState();
  const prevOndState = usePrevious(ondState);
  const videoProgress = useOndGameVideoProgress();
  useTrackGameVideoPlayback(
    mediaId,
    props.playBackgroundMusicWithMedia,
    video,
    props.likelyFullscreen
  );

  useEffect(() => {
    videoProxy.pipe(video);
    return () => videoProxy.pipe(null);
  }, [videoProxy, video]);

  useEffect(() => {
    if (!ondState) return;

    const duration = video.duration || 0;

    if (
      ondState === 'running' &&
      prevOndState === 'resuming' &&
      video.paused &&
      video.currentTime < duration &&
      video.currentTime > 0
    ) {
      // Resume playback for async game play
      playWithCatch(video, log);
    } else if (ondState === 'paused') {
      video.pause();
    } else if (
      ondState === 'running' &&
      prevOndState === 'resuming' &&
      video.paused &&
      video.currentTime === 0 &&
      videoProgress &&
      videoProgress > 0
    ) {
      // Recover video progress
      video.currentTime = videoProgress;
      playWithCatch(video, log);
    }
  }, [ondState, prevOndState, video, videoProgress]);

  return <></>;
}

function RemoteStreamWrapper(props: {
  mediaId: string;
  containerClassName: string;
  className: string;
  lastThumbnail?: string | null;
  onPlaying?: () => void;
  onEnded?: () => void;
  onReplaying?: () => void;
}): JSX.Element | null {
  const { onPlaying, onEnded, onReplaying } = props;
  const videoPlayback = useGameVideoPlaybackState();
  const joined = useIsCoreChannelJoined('game');
  const [showLastThumbnail, setShowLastThumbnail] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const playedCount = useRef(0);
  const controllerClientId = useGameHostingController()?.clientId;
  const rtcService = useRTCService('game');

  // preload the last thumbnail
  useEffect(() => {
    if (!props.lastThumbnail) return;
    loadImageAsPromise(props.lastThumbnail);
  }, [props.lastThumbnail]);

  useEffect(() => {
    if (videoPlayback?.state !== 'waiting') return;
    setShowLoading(true);
    return () => {
      setShowLoading(false);
    };
  }, [videoPlayback?.state]);

  useEffect(() => {
    if (videoPlayback?.state !== 'loaded') return;
    setShowLastThumbnail(false);
  }, [videoPlayback?.state]);

  useEffect(() => {
    if (videoPlayback?.state !== 'playing') return;
    playedCount.current++;
    if (controllerClientId) {
      rtcService.setRemoteVideoStreamType(controllerClientId, 0);
    }
    if (playedCount.current === 1) {
      onPlaying && onPlaying();
    }
    if (playedCount.current > 1) {
      onReplaying && onReplaying();
    }
  }, [
    videoPlayback?.state,
    rtcService,
    controllerClientId,
    onReplaying,
    onPlaying,
  ]);

  useEffect(() => {
    if (videoPlayback?.state !== 'ended' || !props.lastThumbnail) return;
    setShowLastThumbnail(true);
    onEnded && onEnded();
    return () => {
      setShowLastThumbnail(false);
    };
  }, [videoPlayback?.state, onEnded, props.lastThumbnail]);

  useEffect(() => {
    playedCount.current = 0;
    return () => {
      setShowLastThumbnail(false);
    };
  }, [props.mediaId]);

  useEffect(() => {
    return () => {
      setShowLastThumbnail(false);
    };
  }, []);

  return (
    <div className={`${props.containerClassName}`}>
      <RemoteStream
        {...props}
        containerClassName={`${props.className} absolute overflow-hidden ${
          showLastThumbnail ? 'invisible' : 'visible'
        }`}
      />
      {props.lastThumbnail && (
        <img
          src={props.lastThumbnail}
          className={`${props.className} absolute ${
            showLastThumbnail ? 'visible' : 'invisible'
          }`}
          alt='thumbnail'
        />
      )}
      {(showLoading || !joined) && (
        <Loading
          text=''
          containerClassName='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2'
          imgClassName='w-9 h-9'
        />
      )}
    </div>
  );
}

function RemoteStream(props: {
  containerClassName: string;
}): JSX.Element | null {
  const ref = useRef<HTMLDivElement>(null);
  const rtcService = useRTCService('game');
  const controllerClientId = useGameHostingController()?.clientId;
  useDebugStream(ref.current, controllerClientId, rtcService);
  const fit = useInstance(() => {
    const matched = props.containerClassName.match(
      /object-(cover|contain|fill)/
    );
    if (!matched) return 'contain';
    switch (matched[1]) {
      case 'cover':
      case 'contain':
      case 'fill':
        return matched[1];
      default:
        return 'contain';
    }
  });
  const updateGameStreaming = useUpdateGameStreaming();

  useEffect(() => {
    const el = ref.current;
    if (!controllerClientId || !el) return;
    const ret = rtcService.play(controllerClientId, el, { fit });
    updateGameStreaming('game', ret.video);
    return () => {
      rtcService.stop(controllerClientId);
      updateGameStreaming('game', false);
    };
  }, [rtcService, controllerClientId, fit, updateGameStreaming]);

  const publishedCallback = useCallback(
    async (uid: UID, mediaType: 'audio' | 'video') => {
      if (mediaType === 'audio') {
        rtcService.playAudio(uid);
      }
      if (mediaType === 'video') {
        if (ref.current) {
          const played = rtcService.playVideo(uid, ref.current, { fit });
          updateGameStreaming('game', played);
        }
        try {
          await rtcService.setRemoteVideoStreamType(uid, 0);
        } catch (error) {
          log.error('setRemoteVideoStreamType failed', error);
        }
      }
    },
    [rtcService, fit, updateGameStreaming]
  );

  const unpublishedCallback = useCallback(
    (uid: UID, mediaType: 'audio' | 'video') => {
      if (mediaType === 'audio') {
        rtcService.stopAudio(uid);
      }
      if (mediaType === 'video') {
        rtcService.stopVideo(uid);
        updateGameStreaming('game', false);
      }
    },
    [rtcService, updateGameStreaming]
  );

  useEffect(() => {
    rtcService.on('remote-user-published', publishedCallback);
    rtcService.on('remote-user-unpublished', unpublishedCallback);
    return () => {
      rtcService.off('remote-user-published', publishedCallback);
      rtcService.off('remote-user-unpublished', unpublishedCallback);
    };
  }, [rtcService, publishedCallback, unpublishedCallback]);

  return <div ref={ref} className={props.containerClassName}></div>;
}

export const GamePlayVideo = forwardRef<
  HTMLVideoElement,
  EchoCanceledVideoProps & {
    lastThumbnail?: string | null;
    playBackgroundMusicWithMedia: boolean;
    likelyFullscreen: boolean;
  }
>((props, externalRef): JSX.Element | null => {
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const isController = useIsController();
  const forceUpdate = useForceUpdate();
  const useLocalThumbnail = getFeatureQueryParam(
    'game-play-video-use-local-thumbnail'
  );
  const videoProxy = useGameVideoStreamProxy();

  const onBeforeVideoRelease = useCallback(async (v: HTMLVideoElement) => {
    try {
      if (!v) return;

      let videoProgress: number | null = null;
      if (v.duration - v.currentTime > 0.5) {
        videoProgress = v.currentTime;
      }

      await setOndGamePlayVideoProgress(videoProgress);
    } catch (err) {
      log.error('onBeforeVideoRelease failed', err);
    }
  }, []);

  if (!isController) {
    return (
      <RemoteStreamWrapper
        mediaId={props.mediaId}
        containerClassName={props.containerClassName}
        className={props.className}
        lastThumbnail={useLocalThumbnail ? props.lastThumbnail : undefined}
        onPlaying={props.onPlaying}
        onEnded={props.onEnded}
        onReplaying={props.onReplaying}
      />
    );
  } else {
    return (
      <>
        <EchoCanceledVideo
          ref={composeRefs(externalRef, videoRef)}
          {...props}
          volumeControl='game'
          onInited={forceUpdate}
          onBeforeRelease={onBeforeVideoRelease}
          externalEC={isController ? true : props.externalEC}
          externalVolumeController={isController ? videoProxy : undefined}
          debugKey='game-play-video'
        />
        {isController && videoRef.current && (
          <LocalStream
            mediaId={props.mediaId}
            playBackgroundMusicWithMedia={props.playBackgroundMusicWithMedia}
            video={videoRef.current}
            likelyFullscreen={props.likelyFullscreen}
          />
        )}
      </>
    );
  }
});
