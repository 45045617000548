import {
  type ReactNode,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import useMeasure from 'react-use-measure';

import {
  type BoundingBox,
  compareBoundingBox,
  GreenScreenValuesUtils,
} from '@lp-lib/game';

import { useLiveCallback } from '../../hooks/useLiveCallback';
import logger from '../../logger/logger';
// NOTE: avoiding index.ts to avoid circular dependency
import {
  type CustomVideoQualityPreset,
  profileForCustomVideo,
} from '../../services/webrtc/types';
import { xDomainifyUrl } from '../../utils/common';
import { playWithCatch } from '../../utils/playWithCatch';
import { ResizeObserver } from '../../utils/ResizeObserver';
import { TimeUtils } from '../../utils/time';
import {
  UnplayableImageImpl,
  UnplayableMediaFactory,
  UnplayableVideoImpl,
} from '../../utils/unplayable';
import { PauseIcon } from '../icons/PauseIcon';
import { PlayIcon } from '../icons/PlayIcon';
import { type VideoEffectsSettings } from '../VideoEffectsSettings/types';
import { FitOperation } from './FitOperation';
import { BoundingBoxProcessor } from './processors/BoundingBoxProcessor';
import { ChromakeyProcessor } from './processors/ChromakeyProcessor';
import { LoopingSourceProcessor } from './processors/LoopingVideoProcessor';
import { VideoElementFrameProcessorPipeline } from './VideoElementFrameProcessorPipeline';

export type VideoEffectsMediaElementProps = {
  streamOrSrc: MediaStream | string;
  settings: VideoEffectsSettings;
  // Target this output size when compositing the input streamOrSrc against the
  // various videoEffectSettings. If this is not specified, the input
  // streamOrSrc dimensions will be used.
  targetVideoProfile?: CustomVideoQualityPreset;
  // Whether to mute the element
  muted?: boolean;
  // Whether the element should be autoplayed. Otherwise it requires user
  // interaction via controls.
  autoplay?: boolean;
  // Whether to show the custom player controls (play/pause, playhead/seek,
  // currentTime/duration).
  controls?: boolean;
  // Whether to draw a visual representation of the bounding box
  boundingBox?: boolean;
  // Allow a parent component to be notified when the video has a non-zero size.
  onVideoSize?: (
    // The box of the source, relative to the container in CSS Pixels
    sourceBox: Readonly<BoundingBox>,
    // The DOMRect of the canvas that was drawn to, in CSS Pixels
    canvasBox: Readonly<BoundingBox>,
    // The DOMRect of the container element of the canvas, responsible for
    // props.fit, in CSS Pixels.
    containerRect: Readonly<BoundingBox>
  ) => void;
  // Simulate object-fit of the element itself
  fit?: 'object-cover' | 'object-contain' | 'object-fill';
  className?: string;
  children?: ReactNode;
};

export const log = logger.scoped('vfpp-chromakeyvideoelement');

/**
 * A component that processes the src video or stream using the given
 * VideoEffectsSettings and optionally composites against a background video (aka
 * a stage). This component attempts to be as similar to a `video` element as
 * possible, with regard to sizing, aspect-ratio, and controls. It will
 * eventually have a width and height, just like a typical video element. Note:
 * it cannot be passed to `createMediaElementSourceNode`, nor can
 * `captureStream()` be called upon it.
 */
export function VideoEffectsMediaElement(
  props: VideoEffectsMediaElementProps
): JSX.Element {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [measureRef, containerRect] = useMeasure({
    polyfill: ResizeObserver,
  });

  const prevBoxDiff = useRef<null | {
    sourceBox: BoundingBox;
    canvasBox: BoundingBox;
    containerBox: BoundingBox;
  }>(null);

  const [sourceDimensions, setSourceDimensions] = useState<{
    width: number;
    height: number;
  } | null>(null);

  const idealField = props.targetVideoProfile
    ? profileForCustomVideo(props.targetVideoProfile)
    : null;

  const [vfpp] = useState(() => {
    const chroma = new ChromakeyProcessor();
    const boundingBox = new BoundingBoxProcessor();
    const stage = new LoopingSourceProcessor();
    const podium = new LoopingSourceProcessor('over');
    const pipeline = new VideoElementFrameProcessorPipeline([
      chroma,
      boundingBox,
      stage,
      podium,
    ]).addObserver({
      processingLatencyTooHigh(delta) {
        log.warn(`Frame processing took ${delta.toFixed(4)}ms`);
      },
    });

    return {
      chroma,
      boundingBox,
      stage,
      podium,
      pipeline,
    };
  });

  useEffect(() => {
    return () => {
      vfpp.pipeline.destroy();
    };
  }, [vfpp.pipeline]);

  const videoRef = useRef<null | HTMLVideoElement>(null);
  const [durationSecs, setDurationSecs] = useState(Infinity);
  const [hasFirstFrame, setHasFirstFrame] = useState(false);

  useEffect(() => {
    const unplayableVideo = new UnplayableVideoImpl(
      typeof props.streamOrSrc === 'string'
        ? xDomainifyUrl(props.streamOrSrc)
        : props.streamOrSrc
    );
    videoRef.current = unplayableVideo.media;

    // Add the stage/background if present
    const unplayableBg = props.settings.stage?.config.mediaFormat.url
      ? new UnplayableVideoImpl(
          xDomainifyUrl(props.settings.stage?.config.mediaFormat.url)
        )
      : null;

    const unplayablePodium = props.settings.podium?.config.mediaFormat.url
      ? new UnplayableImageImpl(
          xDomainifyUrl(props.settings.podium?.config.mediaFormat.url)
        )
      : null;

    // Process both at once so that if the stage is present, it too gets
    // rendered due to the pipeline.start() call.
    Promise.all([
      unplayableBg?.intoPlayable() ?? Promise.resolve(null),
      unplayablePodium?.intoPlayable() ?? Promise.resolve(null),
      // Only a finite-video can be seeked, a stream cannot. If the first-frame
      // technique is attempted it will hang waiting for a seek event
      // indefinitely.
      unplayableVideo.intoPlayable(typeof props.streamOrSrc === 'string'),
    ]).then(([bg, podium, video]) => {
      if (bg) {
        vfpp.stage.setSource(bg);
        // stage / background is always muted during preview
        bg.muted = true;
        bg.loop = true;
        if (props.autoplay) vfpp.stage.play();
      }

      if (podium) {
        vfpp.podium.setSource(podium);
      }

      if (video.duration) {
        setDurationSecs(video.duration);
      }

      if (props.autoplay) video.play();
      if (props.muted) video.muted = true;

      vfpp.pipeline.setInput(video);
      vfpp.pipeline.process(); // process first frame regardless of play state
      vfpp.pipeline.start();

      setSourceDimensions({
        width: video.videoWidth,
        height: video.videoHeight,
      });
      setHasFirstFrame(true);
    });

    return () => {
      UnplayableMediaFactory.Release(unplayableBg);
      UnplayableMediaFactory.Release(unplayablePodium);
      UnplayableMediaFactory.Release(unplayableVideo);
    };
  }, [
    props.autoplay,
    props.muted,
    props.settings.podium?.config.mediaFormat.url,
    props.settings.stage?.config.mediaFormat.url,
    props.streamOrSrc,
    vfpp.pipeline,
    vfpp.podium,
    vfpp.stage,
  ]);

  // Setup/update output canvas sizing and DOM presence
  const onVideoSize = useLiveCallback(props.onVideoSize ?? (() => void 0));
  useLayoutEffect(() => {
    const cvs = vfpp.pipeline.getOutputAsCanvas();

    if (!containerRef.current || !hasFirstFrame) return;

    if (!cvs.parentNode) {
      containerRef.current.appendChild(cvs);
    }

    // object-fit behavior, it's just a canvas not actually a replace element
    // like an image / video. This assumes the canvas will be roughly
    // widescreen ratio.
    const cvsWidth = cvs.width;
    const cvsHeight = cvs.height;

    // Sometimes the containerRect from useMeasure is not up to date, so grab
    // a fresh measurement. The reactive measurement is still needed to know
    // if the window resizes or the layout otherwise changes.
    const rect = containerRef.current.getBoundingClientRect();

    // Still reference the measure rect to allow useEffect dependencies to be maintained
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    containerRect.width + containerRect.height;

    // Example ratios:
    // rect: 640 x 480: 1.3333
    // cvs: 1280 x 720: 1.7777

    const rectRatio = rect.width / rect.height;
    const cvsRatio = cvsWidth / cvsHeight;

    const ratiosEqual = rectRatio.toFixed(2) === cvsRatio.toFixed(2);

    switch (props.fit) {
      case 'object-cover': {
        if (ratiosEqual) {
          cvs.classList.add('w-full', 'h-full');
        } else if (rectRatio > cvsRatio) {
          cvs.classList.remove('h-full');
          cvs.classList.add('w-full');
        } else {
          cvs.classList.add('h-full');
          cvs.classList.remove('w-full');
        }
        break;
      }
      case 'object-fill': {
        cvs.classList.add('w-full', 'h-full');
        break;
      }
      default:
      case 'object-contain': {
        if (ratiosEqual) {
          cvs.classList.add('w-full', 'h-full');
        } else if (rectRatio < cvsRatio) {
          cvs.classList.remove('h-full');
          cvs.classList.add('w-full');
        } else {
          cvs.classList.add('h-full');
          cvs.classList.remove('w-full');
        }
        break;
      }
    }

    // Ensure the BoundingBox processor has the valid field size, which is
    // generally the target output (720p).
    const fieldWidth = idealField?.width ?? cvsWidth;
    const fieldHeight = idealField?.height ?? cvsHeight;
    vfpp.boundingBox.setFieldSize(fieldWidth, fieldHeight);

    // Compute the position and dimensions of the _source_, if it were drawn
    // without the mask. This allows for properly positioning the
    // MaskRectHandles. Note that because the MaskRectHandles are DOM elements
    // (and absolutely positioned) we need to manually scale from "field space"
    // (draw space, aka the canvas pixels) to the "DOM space" (CSS pixels).
    // Reminder that DOM space via getBoundingClientRect uses 0,0 as the upper
    // left of the window, and measures the position and dimensions of an
    // element _absolutely_.

    // NOTE: these computations are extremely similar to the computations within
    // the BoundingBox processor. There is likely a way to unify them, but it is
    // complex since the target output of the BoundingBoxProcessor calculations
    // is a slice into the source, accounting for the mask.

    const boxSettings = props.settings.boundingBox.box;

    // Field Pixels (canvas): Reify the percentage-based bounding box to field space.
    const boundingBoxPx: BoundingBox = {
      x: boxSettings.x * fieldWidth,
      y: boxSettings.y * fieldHeight,
      width: boxSettings.width * fieldWidth,
      height: boxSettings.height * fieldHeight,
    };

    // Field Pixels (canvas), offset from BoundingBox
    const sourceOffsetBB = sourceDimensions
      ? FitOperation[props.settings.boundingBox.fit](
          boundingBoxPx.width,
          boundingBoxPx.height,
          sourceDimensions.width,
          sourceDimensions.height
        )
      : {
          x: 0,
          y: 0,
          width: 0,
          height: 0,
        };

    // Field Pixels (canvas), offset from Canvas (upper left is 0,0)
    const sourceBB: BoundingBox = {
      x: boundingBoxPx.x + sourceOffsetBB.x,
      y: boundingBoxPx.y + sourceOffsetBB.y,
      width: sourceOffsetBB.width,
      height: sourceOffsetBB.height,
    };

    // compute ratio to convert field -> DOM Pixels
    const cvsRect = cvs.getBoundingClientRect();
    const ratio = fieldWidth / cvsRect.width;

    // DOM/CSS Pixels, offset from canvas element
    const sourceRelativeToCanvasDomPxBB: BoundingBox = {
      x: sourceBB.x / ratio,
      y: sourceBB.y / ratio,
      width: sourceBB.width / ratio,
      height: sourceBB.height / ratio,
    };

    // DOM/CSS Pixels, offset from assumed relative container
    const sourceRelativeToContainerDomPxBB: BoundingBox = {
      x: cvsRect.x - rect.x + sourceRelativeToCanvasDomPxBB.x,
      y: cvsRect.y - rect.y + sourceRelativeToCanvasDomPxBB.y,
      width: sourceRelativeToCanvasDomPxBB.width,
      height: sourceRelativeToCanvasDomPxBB.height,
    };

    // Do some manual diffing to ensure an infinite react state loop is not
    // created by always emitting new object references.
    if (
      !prevBoxDiff.current ||
      !compareBoundingBox(
        sourceRelativeToContainerDomPxBB,
        prevBoxDiff.current.sourceBox
      ) ||
      !compareBoundingBox(cvsRect, prevBoxDiff.current.canvasBox) ||
      !compareBoundingBox(rect, prevBoxDiff.current.containerBox)
    ) {
      prevBoxDiff.current = {
        sourceBox: sourceRelativeToContainerDomPxBB,
        canvasBox: cvsRect.toJSON(),
        containerBox: rect.toJSON(),
      };
      onVideoSize?.(
        prevBoxDiff.current.sourceBox,
        prevBoxDiff.current.canvasBox,
        prevBoxDiff.current.containerBox
      );
    }
  }, [
    containerRect.height,
    containerRect.width,
    props.fit,
    props.streamOrSrc,
    vfpp.boundingBox,
    vfpp.pipeline,
    hasFirstFrame,
    onVideoSize,
    idealField?.width,
    idealField?.height,
    props.settings.boundingBox.fit,
    props.settings.boundingBox.box.width,
    props.settings.boundingBox.box.height,
    props.settings.boundingBox.box.x,
    props.settings.boundingBox.box.y,
    props.settings.boundingBox.box,
    sourceDimensions,
  ]);

  const [videoState, setVideoState] = useState<{
    paused: boolean;
    currentTime: number;
    userSeeking: boolean;
    waitingForSeekTo: null | number;
  }>({
    paused: videoRef.current?.paused ?? true,
    currentTime: 0,
    userSeeking: false,
    waitingForSeekTo: null,
  });

  useEffect(() => {
    const interval = setInterval(() => {
      setVideoState((s) => {
        if (!videoRef.current) return s;
        return {
          ...s,
          paused: videoRef.current.paused,
          currentTime: videoRef.current.currentTime,
        };
      });
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    vfpp.podium.enabled = props.settings.podium?.enabled ?? false;
    vfpp.chroma.enabled = props.settings.greenScreen.enabled;
    vfpp.boundingBox.maskRectEnabled = props.settings.greenScreen.enabled;
    vfpp.stage.enabled =
      (props.settings.greenScreen.enabled && props.settings.stage?.enabled) ??
      false;

    const mapped = GreenScreenValuesUtils.ToGLCompat(
      props.settings.greenScreen
    );
    vfpp.chroma.setParameters(mapped);
    vfpp.boundingBox.setMaskRect(props.settings.greenScreen.maskPct);
    vfpp.boundingBox.setBB(props.settings.boundingBox.box);
    vfpp.boundingBox.setBBFit(props.settings.boundingBox.fit);
    vfpp.boundingBox.boundingBoxDrawEnabled = props.boundingBox ?? false;

    if (props.settings.podiumBoundingBox) {
      vfpp.podium.setBoundingBox({
        ...props.settings.podiumBoundingBox,
        stroke: '#1FB148',
      });
    }

    // If the video is paused, force a render so the user sees processing
    // enabled/disabled
    if (videoState.paused) vfpp.pipeline.process();
  }, [
    vfpp.chroma,
    vfpp.boundingBox,
    vfpp.pipeline,
    vfpp.stage,
    videoState.paused,
    vfpp.podium,
    props.settings.greenScreen,
    props.settings.boundingBox.box,
    props.settings.boundingBox.fit,
    props.settings.stage?.enabled,
    props.settings.podium?.enabled,
    props.settings.podium,
    props.boundingBox,
    props.settings.podiumBoundingBox,
  ]);

  const seekInputRef = useRef<null | HTMLInputElement>(null);

  useEffect(() => {
    if (
      seekInputRef.current &&
      !videoState.userSeeking &&
      videoState.waitingForSeekTo === null
    ) {
      seekInputRef.current.value = String(videoState.currentTime);
    }
  }, [
    videoState.currentTime,
    videoState.userSeeking,
    videoState.waitingForSeekTo,
  ]);

  useEffect(() => {
    const video = videoRef.current;

    const onSeeked = () => {
      setVideoState((s) => ({
        ...s,
        currentTime: s.waitingForSeekTo ?? s.currentTime,
        waitingForSeekTo: null,
      }));
    };

    if (videoState.waitingForSeekTo !== null && video) {
      video.addEventListener('seeked', onSeeked, { once: true });
      video.currentTime = videoState.waitingForSeekTo;
    }

    return () => {
      if (!video) return;
      video.removeEventListener('seeked', onSeeked);
    };
  }, [videoState.waitingForSeekTo]);

  useEffect(() => {
    if (!videoRef.current) return;

    const onPlaying = () => setVideoState((s) => ({ ...s, playing: true }));
    const onPaused = () => setVideoState((s) => ({ ...s, playing: false }));

    videoRef.current.addEventListener('playing', onPlaying);
    videoRef.current.addEventListener('paused', onPaused);

    return () => {
      videoRef.current?.removeEventListener('playing', onPlaying);
      videoRef.current?.removeEventListener('paused', onPaused);
    };
  }, []);

  const classesRequiredForObjectFitLike = 'flex justify-center items-center';

  return (
    <div
      data-debugid='chromakey-video-element'
      className={`${
        props.className ?? ''
      } relative group ${classesRequiredForObjectFitLike}`}
      ref={(r) => {
        containerRef.current = r;
        measureRef(r);
      }}
    >
      {!Number.isFinite(durationSecs) || !props.controls ? null : (
        <div
          className={`
          absolute bottom-0 z-3
          w-full p-2 flex gap-2
          bg-lp-gray-002
          invisible
          group-hover:visible
        `}
        >
          <button
            type='button'
            className='btn-secondary rounded-full p-2 flex justify-center items-center'
            onClick={async () => {
              if (videoState.paused) {
                await Promise.all([
                  playWithCatch(videoRef.current),
                  await vfpp.stage.play(),
                ]);
                setVideoState((s) => ({
                  ...s,
                  paused: false,
                }));
              } else {
                videoRef.current?.pause();
                vfpp.stage.pause();
                setVideoState((s) => ({
                  ...s,
                  paused: true,
                }));
              }
            }}
          >
            {videoState.paused ? (
              <PlayIcon className='w-4 h-4' />
            ) : (
              <PauseIcon className='w-4 h-4' />
            )}
          </button>

          <div className='font-mono text-white text-xs flex items-center gap-1 whitespace-nowrap'>
            <span>
              {TimeUtils.DurationFormattedHHMMSS(videoState.currentTime * 1000)}
            </span>
            <span>/</span>
            <span>
              {TimeUtils.DurationFormattedHHMMSS(durationSecs * 1000)}
            </span>
          </div>

          <input
            className='flex-grow'
            type='range'
            ref={seekInputRef}
            min={0}
            max={durationSecs}
            defaultValue={0}
            onPointerDown={() =>
              setVideoState((s) => ({ ...s, userSeeking: true }))
            }
            onPointerUp={() =>
              setVideoState((s) => ({ ...s, userSeeking: false }))
            }
            onChange={(ev) => {
              const seekTo = ev.currentTarget.valueAsNumber;
              setVideoState((s) => ({ ...s, waitingForSeekTo: seekTo }));
            }}
          />
        </div>
      )}
      {props.children}
    </div>
  );
}
