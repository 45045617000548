import { useEffect, useMemo } from 'react';

import { type RoundRobinQuestionBlock } from '@lp-lib/game';

import { useLiveCallback } from '../../../../hooks/useLiveCallback';
import { useMyInstance } from '../../../../hooks/useMyInstance';
import { type Participant } from '../../../../types';
import {
  type MultipleChoiceAnswer,
  type Question,
  QuestionStatus,
} from './types';
import { RoundRobinQuestionUtils } from './utils';

function MultipleChoiceButton(props: {
  question: Question;
  answer: MultipleChoiceAnswer;
  index: number;
  disabled: boolean;
  reveal: boolean;
  onSubmit: (value: string) => Promise<void>;
}) {
  const { question, answer, index, disabled, reveal, onSubmit } = props;
  const label = String.fromCharCode(index + 65);

  const buttonStyle = useMemo(() => {
    if (!reveal) {
      return 'btn-secondary active:border-primary';
    }
    if (question.submittedAnswer === answer.id) {
      return `btn disabled:opacity-100 shadow-inner-lg ${
        answer.correct ? 'bg-lp-green-003' : 'bg-lp-red-001'
      }`;
    } else {
      return `btn-secondary disabled:opacity-100 ${
        answer.correct ? 'border-green-001' : ''
      }`;
    }
  }, [answer.correct, answer.id, question.submittedAnswer, reveal]);

  return (
    <div className='relative'>
      <div
        className='bg-gradient-to-tr from-primary-start to-primary-end 
      w-7 lp-sm:w-7.5 h-7 lp-sm:h-7.5 absolute left-2 transform-gpu 
      -translate-x-full translate-y-1/2 flex items-center justify-center 
      rounded text-xl font-bold select-none z-5'
      >
        {label}
      </div>
      <button
        type='button'
        className={`w-56 h-14 z-0 lp-sm:w-60 lp-sm:h-16 px-2 text-xs lp-sm:text-sms font-medium ${buttonStyle}`}
        disabled={disabled}
        onClick={() => onSubmit(answer.id)}
      >
        {answer.text}
      </button>
    </div>
  );
}

export function MultipleChoiceInput(props: {
  block: RoundRobinQuestionBlock;
  question: Question;
  submitter: Participant;
  onSubmit: (value: string) => Promise<void>;
  isGamePlaying: boolean;
}): JSX.Element | null {
  const { block, question, submitter } = props;
  const me = useMyInstance();
  const isMyTurn = me?.id === submitter.id;
  const reveal = RoundRobinQuestionUtils.ShowRevealAnswer(block, question);
  const submit = useLiveCallback(props.onSubmit);

  // submit empty value as wrong answer
  useEffect(() => {
    if (!isMyTurn) return;
    if (!!question.grade) return;

    if (reveal) {
      submit('');
    }
  }, [isMyTurn, submit, question.grade, question.status, reveal]);

  if (!question.multipleChoiceAnswers) return null;
  const enabled =
    isMyTurn &&
    question.status === QuestionStatus.ShowQuestion &&
    !question.grade;

  return (
    <div className='relative z-10 w-68 flex flex-col gap-3'>
      {question.multipleChoiceAnswers.map((a, i) => (
        <MultipleChoiceButton
          key={a.id}
          question={question}
          answer={a}
          index={i}
          disabled={!enabled}
          reveal={reveal}
          onSubmit={props.onSubmit}
        />
      ))}
    </div>
  );
}
