import { useState } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';

import { useLoadGame } from '../../hooks/useLoadGame';
import { ClientTypeUtils } from '../../types';
import {
  ConfirmCancelModalText,
  useCancelConfirmModalStateRoot,
} from '../ConfirmCancelModalContext';
import { useLocalLoadedGameLike } from '../Game/GamePlayStore';
import {
  useIsGameSessionInited,
  useIsLiveGamePlay,
  usePlayedBlockIds,
} from '../Game/hooks';
import { switchPlayMode } from '../Game/store';
import {
  useIsGameRecordModeActive,
  useToggleGameRecordMode,
} from '../GameRecorder/BlockRecorderProvider';
import { useCanUseGameRecordMode } from '../GameRecorder/hooks/useCanUseGameRecordMode';
import { GameModeIcon } from '../icons/GameModeIcon';
import { useMyClientType } from '../Venue/VenuePlaygroundProvider';
import { StreamingActiveMode, useStreamingMode } from './hooks';
import { useShowStreamingToolsToggle } from './StreamingToolsContext';
import { Control } from './Utilities';

export function StreamingModeControl(): JSX.Element {
  const loadedGameLike = useLocalLoadedGameLike();
  const isLive = useIsLiveGamePlay();
  const playedBlockIds = usePlayedBlockIds();
  const loadGame = useLoadGame();
  const canToggleRecordMode = useCanUseGameRecordMode();
  const recordModeEnabled = useIsGameRecordModeActive();
  const recordModeToggle = useToggleGameRecordMode();
  const activeMode = useStreamingMode();
  const [confirmModal, awaitConfirmCancelModal] =
    useCancelConfirmModalStateRoot();
  const isHost = ClientTypeUtils.isHost(useMyClientType());

  const [, setShowStreamingTools] = useShowStreamingToolsToggle();
  const [isLoading, setIsLoading] = useState(false);
  const isGameSessionInited = useIsGameSessionInited();

  const nextActionMap = new Map([
    [StreamingActiveMode.Live, { toLiveMode: true, toRecordMode: true }],
    [StreamingActiveMode.Rec, { toLiveMode: false, toRecordMode: false }],
    [StreamingActiveMode.OnD, { toLiveMode: true, toRecordMode: false }],
  ]);

  const SwitchGamePlayMode = async (
    toLiveMode: boolean,
    toRecordMode: boolean
  ) => {
    // check playedBlockIds maybe is wrong
    if (playedBlockIds.length > 0) {
      const response = await awaitConfirmCancelModal({
        kind: 'confirm-cancel',
        prompt: (
          <ConfirmCancelModalText>
            Are you sure you want to end the current Game session and reset its
            data?
          </ConfirmCancelModalText>
        ),
        confirmBtnLabel: 'Confirm',
        cancelBtnLabel: 'Cancel',
      });
      if (response.result === 'canceled') return;
    }

    if (toRecordMode !== recordModeEnabled) {
      await recordModeToggle();
    }

    if (loadedGameLike) {
      if (toLiveMode !== isLive) {
        setIsLoading(true);
        await loadGame(loadedGameLike);
        setIsLoading(false);
      }
    }
    await switchPlayMode(toLiveMode);
  };

  const onSwitchGamePlayMode = async (
    toLiveMode: boolean,
    toRecordMode: boolean
  ) => {
    await SwitchGamePlayMode(toLiveMode, toRecordMode);
    setShowStreamingTools(false);
  };

  useHotkeys(
    'ctrl+shift+n',
    (event) => {
      event.preventDefault();
      if (!isLoading) {
        const nextAction = nextActionMap.get(activeMode);
        if (nextAction) {
          SwitchGamePlayMode(nextAction.toLiveMode, nextAction.toRecordMode);
        }
      }
    },
    {
      enabled: isHost,
    },
    [SwitchGamePlayMode]
  );

  return (
    <div className='mb-4'>
      <Control
        text='Stream Mode'
        icon={<GameModeIcon className='w-full h-full fill-current' />}
        info=''
        margin='mt-0 mb-0'
      ></Control>
      {confirmModal}
      <div className='flex items-center justify-center'>
        <div className='inline-flex'>
          <button
            type='button'
            data-testid='switch-to-live-btn'
            className={`${
              activeMode === StreamingActiveMode.Live
                ? 'btn-group-secondary-checked cursor-default'
                : 'btn-group-secondary'
            } w-17 h-10 mr-2.5`}
            onClick={() => onSwitchGamePlayMode(true, false)}
            disabled={isLoading || activeMode === StreamingActiveMode.Live}
          >
            Live
          </button>
          <button
            type='button'
            data-testid='switch-to-rec-btn'
            className={`${
              activeMode === StreamingActiveMode.Rec
                ? 'btn-group-secondary-checked cursor-default'
                : 'btn-group-secondary'
            } w-17 h-10 mr-2.5`}
            onClick={() => onSwitchGamePlayMode(true, true)}
            disabled={
              isLoading ||
              activeMode === StreamingActiveMode.Rec ||
              !canToggleRecordMode ||
              !isGameSessionInited
            }
          >
            Rec
          </button>

          <button
            type='button'
            data-testid='switch-to-ond-btn'
            className={`${
              activeMode === StreamingActiveMode.OnD
                ? 'btn-group-secondary-checked cursor-default'
                : 'btn-group-secondary'
            } w-17 h-10 mr-2.5`}
            onClick={() => onSwitchGamePlayMode(false, false)}
            disabled={isLoading || activeMode === StreamingActiveMode.OnD}
          >
            OnD
          </button>
        </div>
      </div>
    </div>
  );
}
