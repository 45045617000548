import { useNavigate, useSearchParams } from '@remix-run/react';
import { useMemo, useState } from 'react';
import { useEffectOnce } from 'react-use';
import { Waypoint } from 'react-waypoint';

import {
  type DtoSharedAsset,
  type DtoSharedAssetListResponse,
  EnumsSharedAssetFanOutJobStatus,
  EnumsSharedAssetPurpose,
} from '@lp-lib/api-service-client/public';
import { type Media, MediaType } from '@lp-lib/media';

import { useListLoader } from '../../hooks/useListLoader';
import { getQueryParam } from '../../hooks/useQueryParam';
import { apiService } from '../../services/api-service';
import { fromMediaDTO } from '../../utils/api-dto';
import { MediaUtils } from '../../utils/media';
import { type Action, ActionSheet } from '../ActionSheet';
import {
  ConfirmCancelModalHeading,
  ConfirmCancelModalText,
  useAwaitFullScreenConfirmCancelModal,
} from '../ConfirmCancelModalContext';
import { ErrorMessage as GameCenterErrorMessage } from '../Game/GameCenter';
import { DeleteIcon } from '../icons/DeleteIcon';
import { EditIcon } from '../icons/EditIcon';
import { PlayIcon } from '../icons/PlayIcon';
import { RefreshIcon } from '../icons/RefreshIcon';
import { Loading } from '../Loading';
import { TTSPreviewButton } from '../VoiceOver/TTSPreviewButton';
import { SharedAssetEditorModal } from './SharedAssetEditor';
import { getPurposeLabel } from './utils';

export function SharedAssetCover(props: {
  media: Media | null;
  className?: string;
  cliclable?: boolean;
}) {
  const media = props.media;
  const preview = MediaUtils.PickMediaUrl(media, { videoThumbnail: 'first' });
  const url = MediaUtils.PickMediaUrl(media);

  const onClick = () => {
    props.cliclable && window.open(url ?? '', '_blank');
  };

  return (
    <div
      className={`relative bg-black border border-secondary rounded-lg 
      flex-shrink-0 flex items-center justify-center ${props.className ?? ''}`}
    >
      {preview ? (
        <>
          <img
            src={preview}
            alt='cover'
            className={`object-cover w-full h-full ${
              props.cliclable ? 'cursor-pointer' : ''
            }`}
            onClick={onClick}
          />
          {media?.type === MediaType.Video && (
            <div
              className={`absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 border border-secondary rounded-full w-8 h-8 flex items-center justify-center 
        bg-black bg-opacity-80 ${props.cliclable ? 'cursor-pointer' : ''}`}
              onClick={onClick}
            >
              <PlayIcon />
            </div>
          )}
        </>
      ) : (
        <p className='text-2xs text-secondary'>no media</p>
      )}
    </div>
  );
}

function SharedAssetPreview(props: { item: DtoSharedAsset }) {
  switch (props.item.purpose) {
    case EnumsSharedAssetPurpose.SharedAssetPurposeVoice: {
      return (
        <div className='flex items-center gap-2 mr-auto'>
          <span className='text-sms font-bold'>Preview</span>
          <TTSPreviewButton
            script='Hello, world! This is a preview script.'
            settings={props.item.data?.ttsRenderSettings}
          />
        </div>
      );
    }
    case EnumsSharedAssetPurpose.SharedAssetPurposePersonality: {
      return <div>N/A</div>;
    }
    default: {
      const media = fromMediaDTO(props.item.media);
      return (
        <SharedAssetCover media={media} className='w-40 h-22.5' cliclable />
      );
    }
  }
}

type ActionSheetKeys = 'edit' | 'delete';

function ShareAssetItem(props: {
  item: DtoSharedAsset;
  onViewLinks: () => void;
  onEdit: () => void;
  onDelete: () => void;
  onRetry: () => Promise<void>;
}): JSX.Element {
  const { item, onViewLinks, onEdit, onDelete } = props;
  const [retrying, setRetrying] = useState(false);

  const actions: Action<ActionSheetKeys>[] = [
    {
      kind: 'button',
      key: 'edit',
      icon: <EditIcon />,
      text: 'Edit',
      onClick: onEdit,
    },
    {
      kind: 'button',
      key: 'delete',
      icon: <DeleteIcon />,
      text: 'Delete',
      className: 'text-red-002',
      onClick: onDelete,
    },
  ];

  const jobFailed =
    item.jobStatus ===
    EnumsSharedAssetFanOutJobStatus.SharedAssetFanOutJobStatusFailed;

  const onRetry = async () => {
    setRetrying(true);
    try {
      await props.onRetry();
    } catch (error) {
      throw error;
    } finally {
      setRetrying(false);
    }
  };

  return (
    <tr className='text-sms border-b border-secondary'>
      <td className='py-1.5'>
        <SharedAssetPreview item={item} />
      </td>
      <td className='py-1.5'>{item.label}</td>
      <td className='py-1.5'>{getPurposeLabel(item.purpose)}</td>
      <td className='py-1.5'>
        <p className='max-w-80 max-h-50 overflow-ellipsis overflow-hidden'>
          {item.data ? JSON.stringify(item.data) : 'N/A'}
        </p>
      </td>
      <td className='py-1.5 text-center'>
        <button className='underline p-1' type='button' onClick={onViewLinks}>
          {item.linksCount}
        </button>
      </td>
      <td className='py-1.5'>
        <div className='flex items-center justify-start gap-1'>
          <div className={`${jobFailed ? 'text-red-002' : ''}`}>
            {item.jobStatus}
          </div>
          {jobFailed && (
            <>
              {retrying ? (
                <Loading text='' imgClassName='w-3.5 h-3.5' />
              ) : (
                <button type='button' className='underline' onClick={onRetry}>
                  <RefreshIcon />
                </button>
              )}
            </>
          )}
        </div>
      </td>
      <td className='py-1.5'>
        <ActionSheet<ActionSheetKeys> actions={actions} placement='left' />
      </td>
    </tr>
  );
}

export function SharedAssetList(): JSX.Element {
  const [params, setParams] = useSearchParams();
  const q = params.get('q');

  const paginator = useMemo(
    () => apiService.media.searchSharedAsset({ q }),
    [q]
  );
  const { items, state, error, handleLoadMore, handleRetry, dao } =
    useListLoader<DtoSharedAssetListResponse, DtoSharedAsset>(
      paginator,
      (a, b) => a.id === b.id
    );
  const showEmptyMsg =
    state.isDone && !error && items.length === 0 && !paginator.hasMore();
  const canLoadMore = state.isDone && !error && paginator.hasMore();

  const navigate = useNavigate();

  const triggerModal = useAwaitFullScreenConfirmCancelModal();

  const handleAdd = () => {
    triggerModal({
      kind: 'custom',
      element: (p) => (
        <SharedAssetEditorModal
          item={null}
          onCancel={p.internalOnCancel}
          onSave={(added) => {
            dao.addItem(added);
            p.internalOnConfirm();
          }}
        />
      ),
    });
  };

  const handleEdit = (item: DtoSharedAsset) => {
    triggerModal({
      kind: 'custom',
      element: (p) => (
        <SharedAssetEditorModal
          item={item}
          onCancel={p.internalOnCancel}
          onSave={(updated) => {
            dao.updateItem(updated);
            p.internalOnConfirm();
          }}
        />
      ),
    });
  };

  const handleDelete = async (item: DtoSharedAsset) => {
    if (item.linksCount > 0) {
      const decision = await triggerModal({
        kind: 'confirm-cancel',
        prompt: (
          <div className='px-5 py-2'>
            <ConfirmCancelModalHeading>
              Wait up! Are you sure?
            </ConfirmCancelModalHeading>
            <ConfirmCancelModalText className='mt-4 text-sms font-normal'>
              {`This media is utilized across ${item.linksCount} game blocks! Be sure
            you’re not meaningfully changing the meaning of the media or it
            might make live games in the library obsolete`}
            </ConfirmCancelModalText>
          </div>
        ),
        confirmBtnLabel: 'Delete',
        confirmBtnVariant: 'delete',
        cancelBtnLabel: 'Cancel',
      });
      if (decision.result === 'canceled') return;
    }
    await apiService.media.deleteSharedAsset(item.id);
    dao.deleteItem(item);
  };

  const handleRetryJob = async (item: DtoSharedAsset) => {
    const resp = await apiService.media.retrySharedAssetFanOutJob(item.id);
    dao.updateItem(resp.data.sharedAsset);
  };

  useEffectOnce(() => {
    const run = async () => {
      const id = getQueryParam('id');
      if (!id) return;

      const resp = await apiService.media.getSharedAsset(id);
      if (resp) {
        handleEdit(resp.data.sharedAsset);
      }
    };

    run();
  });

  return (
    <div className='w-full h-full flex flex-col gap-6'>
      <div className='flex items-center justify-between'>
        <input
          className='field h-10 w-80 mb-0'
          placeholder='Search Internal Label'
          onChange={(e) => {
            setParams({ q: e.target.value });
          }}
          autoFocus
          value={q ?? ''}
        />
        <button
          type='button'
          className='btn-primary w-50 h-10 flex justify-center items-center'
          onClick={handleAdd}
        >
          New Shared Media
        </button>
      </div>
      <div>
        <table className='w-full'>
          <thead>
            <tr className='text-left'>
              <th className='pb-3'>Media</th>
              <th className='pb-3'>Internal Label</th>
              <th className='pb-3'>Category</th>
              <th className='pb-3 w-1/5'>Data</th>
              <th className='pb-3 text-center'>Ref Cnt</th>
              <th className='pb-3'>Job Status</th>
              <th className='pb-3'></th>
            </tr>
          </thead>
          <tbody>
            {items.map((i) => (
              <ShareAssetItem
                key={i.id}
                item={i}
                onEdit={() => handleEdit(i)}
                onDelete={() => handleDelete(i)}
                onViewLinks={() => navigate(`./${i.id}/links`)}
                onRetry={() => handleRetryJob(i)}
              />
            ))}
          </tbody>
        </table>
        <div>
          {state.isRunning && (
            <div className='flex items-center justify-center mt-50'>
              <Loading />
            </div>
          )}
          {error && (
            <div className='w-full flex items-center justify-center text-white mt-50'>
              <GameCenterErrorMessage
                text='Something went wrong'
                handleRetry={handleRetry}
              />
            </div>
          )}
          {showEmptyMsg && (
            <div className='w-full flex items-center justify-center text-white mt-50'>
              No Shared Media.
            </div>
          )}
          {canLoadMore && (
            <Waypoint onEnter={handleLoadMore} fireOnRapidScroll>
              <div>&nbsp;</div>
            </Waypoint>
          )}
        </div>
      </div>
    </div>
  );
}
