import { type ReactNode } from 'react';

import { WarningIcon } from '../icons/WarningIcon';

export function BannerWarningMessage(props: {
  children: ReactNode;
  className?: string;
}) {
  return (
    <aside
      className={`
        w-full h-12 px-7
        flex-shrink-0
        overflow-hidden
        flex items-center
        ${props.className ?? ''}
      `}
    >
      {props.children}
    </aside>
  );
}

export function BannerWarningMessages(props: {
  errors: Error[];
  reset: () => void;
}) {
  const { errors, reset } = props;
  return (
    <>
      {errors.map((err, i) => (
        <BannerWarningMessage key={i} className='text-white gap-2'>
          <span className='text-red-005'>
            <WarningIcon />
          </span>
          <span>Error: {err.message}</span>
          <span className='flex-grow flex'>
            <button
              type='button'
              onClick={reset}
              className='btn-secondary h-9 px-8'
            >
              Clear
            </button>
          </span>
        </BannerWarningMessage>
      ))}
    </>
  );
}
