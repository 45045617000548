import { type Game } from '../../types/game';

export function useRecordingDeleteConfirmConfigForGame(game: Nullable<Game>) {
  if (!game || !game.isPrime) return;
  return {
    primaryText: (
      <>
        <span className='text-red-002'>HOLD UP!</span>
        <span>
          You are about to <span className='text-red-002'>DELETE</span> a Prime
          Minigame's OnD Recording!
        </span>
      </>
    ),
    secondaryText: (
      <>
        Doing so will delete the both the video recording and the Key Actions
        wherever the Prime Minigame is used, forever!
      </>
    ),
  };
}
