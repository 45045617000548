import merge from 'lodash/merge';

import {
  type DtoChannel,
  type DtoChannelProgramLink,
  type EnumsProgramType,
} from '@lp-lib/api-service-client/public';
import { type Media } from '@lp-lib/game';

import { type Message, type Organizer } from '../../../types';
import { type SlackChannel } from '../../../types/slack';
import { InfoIcon } from '../../icons/InfoIcon';
import { type Program } from '../../Program';

export type SlackBindingModalStep =
  | 'bind-new'
  | 'bind-exist'
  | 'bind-private'
  | 'invite-users'
  | 'share-message'
  | 'bind-done';

export type SlackModalBinding = {
  slackChannel?: SlackChannel;
  channel?: DtoChannel;
  programLink?: DtoChannelProgramLink;
  newInvitees?: Organizer[];
  sharedMessage?: Message;
  inviteRemoveUnmatched?: boolean;
};

export type SlackBindingStepConfig = {
  initStep: SlackBindingModalStep;
  bindingNewModal: {
    channelName: string;
    title: string;
    description?: (props: { program: Program }) => JSX.Element | null;
  };
  bindingExistModal: {
    title: string;
    backButtonText: string;
    description?: (props: { program: Program }) => JSX.Element | null;
  };
  inviteUserModal: {
    description?: (props: { program: Program }) => JSX.Element | null;
  };
  shareMessageModal: {
    enabled: boolean;
    initialText: string;
    attachedMedia: Media | null;
    mediaTitle: string;
  };
  bindingDoneModal: {
    title: string;
    enabled: boolean;
    description?: (props: { binding: SlackModalBinding }) => JSX.Element | null;
    buttonText?: string;
  };
};

export type SlackBindingStepConfigOverride =
  RecursivePartial<SlackBindingStepConfig>;

function defaultConfig(): SlackBindingStepConfig {
  return {
    initStep: 'bind-new',
    bindingNewModal: {
      channelName: 'lunapark',
      title: 'Create a Luna Park Channel',
      description: (props: { program: Program }): JSX.Element => (
        <div className='text-center mt-4.5 w-full text-sms'>
          Create a new Slack channel in which Luna Park will run the{' '}
          {props.program.name} program. Doing this will not trigger any messages
          to anyone besides you.
        </div>
      ),
    },
    bindingExistModal: {
      title: 'Add Luna Park to an Existing Slack Channel',
      backButtonText: 'Back',
      description: () => (
        <div className='flex items-start justify-center text-center mt-6'>
          <InfoIcon className='w-5 h-5' />
          <div className='text-xs font-light mt-0.5'>
            Luna Park works best when people can opt in or out, so we recommend
            that you don’t choose a channel that’s used for core work-related
            communications (ex: #general)
          </div>
        </div>
      ),
    },
    inviteUserModal: {
      description: () => (
        <>
          Share access to Luna Park with your teammates!
          <br />
          The more you invite, the more fun Luna Park becomes!
        </>
      ),
    },
    shareMessageModal: {
      enabled: false,
      initialText: '',
      attachedMedia: null,
      mediaTitle: '',
    },
    bindingDoneModal: {
      title: 'Luna Park Channel Setup Successfully',
      description: () => (
        <div className='flex items-start justify-center text-center mt-6'></div>
      ),
      enabled: true,
    },
  };
}

export function makeSlackBindingStepConfig(
  overrides?: (SlackBindingStepConfigOverride | undefined)[]
): SlackBindingStepConfig {
  let result = defaultConfig();
  for (const override of overrides ?? []) {
    result = merge(result, override);
  }
  return result;
}

export type ProgramTargetCreator = (
  channelId: string,
  programId: string,
  programType: EnumsProgramType
) => Promise<string>;
