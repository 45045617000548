import { type ReactNode, useMemo } from 'react';

import { WebRTCUtils } from '../../services/webrtc';
import { type Participant } from '../../types/user';
import { MicrophoneOffIcon2 } from '../icons/MicrophoneOffIcon2';
import { PoorConnectionIcon } from '../icons/PoorConnectionIcon';
import { useParticipantFlags, useParticipantNetworkQuality } from '../Player';

export type IndicatorProps = {
  clientId: Participant['clientId'];
  volume: number;
  local: boolean;
  miniMode: boolean;
  children?: ReactNode;
};

export const Indicator = ({
  clientId,
  volume,
  local,
  miniMode = false,
  children,
}: IndicatorProps): JSX.Element => {
  const quality = useParticipantNetworkQuality(clientId);
  const flags = useParticipantFlags(clientId);

  const showPoorConnection = useMemo(
    () => WebRTCUtils.CheckNetworkQuality(local, quality),
    [local, quality]
  );

  return (
    <div className='w-full h-full absolute left-0 top-0 flex items-center justify-center z-20 pointer-events-none'>
      {showPoorConnection && (
        <div
          className={`absolute ${
            miniMode ? '-top-2 filter drop-shadow-lp-sm' : 'top-0'
          }`}
        >
          <PoorConnectionIcon />
        </div>
      )}
      {flags?.hasMicrophone && !flags.audio && (
        <div
          className={`absolute ${
            miniMode ? '-bottom-2' : 'bottom-1'
          } filter drop-shadow-lp-sm`}
        >
          <MicrophoneOffIcon2
            className={`${miniMode ? 'w-4 h-4' : 'w-5 h-5'} fill-current`}
          />
        </div>
      )}
      <div
        className={`absolute ${
          miniMode ? '-bottom-1' : 'bottom-1'
        } filter drop-shadow-lp-sm ${
          flags?.audio && volume > 0 ? 'block' : 'hidden'
        }`}
      >
        <div className='rounded-full w-3.5 h-3.5 bg-lp-blue-001 border-primary' />
      </div>
      {children}
    </div>
  );
};
