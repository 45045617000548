import { EnumsMediaScene } from '@lp-lib/api-service-client/public';
import {
  type CreativePromptBlock,
  type CreativePromptBlockMedia,
} from '@lp-lib/game';

import { BLOCK_TIMER_OPTIONS } from '../Common/Editor';
import {
  AdditionalSettings,
  AdditionalSharedSettingsEditor,
  BlockMediaEditor,
  EditorBody,
  EditorLayout,
  type EditorProps,
  PointsInput,
  RHFCheckbox,
  RHFSelectField,
  useEditor,
} from '../Common/Editor/Internal';

export function CreativePromptBlockEditor(
  props: EditorProps<CreativePromptBlock>
): JSX.Element | null {
  const { block } = props;
  const { updateField } = useEditor(props);

  return (
    <EditorLayout
      bottomAccessory={
        <AdditionalSettings>
          <AdditionalSharedSettingsEditor {...props} />
        </AdditionalSettings>
      }
    >
      <EditorBody>
        <h2 className='text-2xl text-white'>Creative Prompt Editor</h2>
        <div className='w-full my-7.5 flex flex-col text-white'>
          <section className='w-full flex'>
            <div className='w-3/5 flex flex-col'>
              <div>
                <span className='text-white'>Prompt Text</span>
                <textarea
                  className={`h-23.5 mt-1 mb-0 py-2 resize-none field`}
                  placeholder='Max 300 characters'
                  defaultValue={block.fields.prompt}
                  onBlur={(e) => {
                    updateField('prompt', e.target.value);
                  }}
                />
              </div>
            </div>

            <div className='w-2/5 flex flex-col items-center'>
              <BlockMediaEditor<CreativePromptBlockMedia>
                blockId={block.id}
                title='Prompt Image/Video'
                scene={EnumsMediaScene.MediaSceneBlockMedia}
                volumeSelectable
                mediaData={block.fields?.submissionMediaData ?? null}
                media={block.fields?.submissionMedia ?? null}
                extraNotice='Media will display to the audience when the question is presented.'
                objectFit='object-contain'
                field='submissionMedia'
              />
            </div>
          </section>

          <hr className='w-full my-5 border border-secondary' />

          <section className='w-full flex flex-col gap-6'>
            <div className='w-full flex gap-5'>
              <div className='w-75'>
                <RHFSelectField<CreativePromptBlock>
                  className='w-full h-10 text-white mt-1'
                  label='Submission Time Limit'
                  name='submissionTime'
                  options={BLOCK_TIMER_OPTIONS}
                  onChange={(_, value) => {
                    if (typeof value !== 'number') return;
                    updateField('submissionTime', value);
                  }}
                  value={block.fields.submissionTime}
                />
              </div>

              <div className='w-75'>
                <RHFCheckbox<CreativePromptBlock>
                  label='Start Video With Timer'
                  name='startVideoWithTimer'
                  value={block.fields.startVideoWithTimer}
                  onChange={(_, checked: boolean): void => {
                    updateField('startVideoWithTimer', checked);
                  }}
                  description={{
                    enabled:
                      'Enabled: Presenting this Block will show both the Prompt Text and Video. Video will only play once the timer starts, and it cannot be replayed after.',
                    disabled:
                      'Disabled: Presenting this Block will auto-play the Prompt Video. Prompt Text will only show after the video finishes.',
                  }}
                />
              </div>
            </div>

            <div className='w-full flex gap-5'>
              <div className='w-75 flex flex-col gap-2'>
                <span className='text-white font-bold'>Voting Time Limit</span>
                <div className='text-icon-gray text-3xs font-medium'>
                  Timer for voting on favorite Answers is dynamic based on total
                  number of Answer submissions. 5s + 2s for every Answer,
                  rounded to the nearest 5
                </div>
              </div>

              <div className='w-75 flex flex-col gap-2'>
                <span className='text-white font-bold'>Points Multiplier</span>
                <PointsInput
                  defaultValue={block.fields.pointsMultiplier}
                  max={1000}
                  min={0}
                  placeholder={'Max 1000 points'}
                  onChange={(value) => {
                    updateField('pointsMultiplier', value);
                  }}
                />
                <div className='text-icon-gray text-3xs font-medium'>
                  Teams earn Points based on the % of Votes their Answer gets.
                  Points Earned = (% of Votes) * the multiplier above + 50.
                  Answers with 0 votes receive 0 Points.
                </div>
              </div>
            </div>
          </section>
        </div>
      </EditorBody>
    </EditorLayout>
  );
}
