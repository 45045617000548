import { ProfileIndex } from '@lp-lib/crowd-frames-schema';

import { type Participant, type TeamV0 } from '../../../../types';
import { CrowdFramesAvatar } from '../../../CrowdFrames';
import { useSelectTeamParticipants } from '../../../Player';
import { useTeams } from '../../../TeamAPI/TeamV1';

function InstructionPlayerView(props: {
  participant: Participant;
}): JSX.Element {
  const { participant } = props;
  return (
    <div className='flex-1 flex items-center gap-1.5 overflow-hidden'>
      <div className={`relative w-9 h-9`}>
        <CrowdFramesAvatar
          profileIndex={ProfileIndex.wh100x100fps8}
          enablePointerEvents={false}
          participant={participant}
          reflectOnStageStatus={false}
          renderFrameOnStage={true}
          roundedClassname='w-9 h-9 rounded-full'
          throttleRegistration={true}
        />
      </div>

      <div className='flex-1 truncate text-2xs font-normal'>
        {participant.username}
      </div>
    </div>
  );
}

function InstructionTeamView(props: { team: TeamV0 }): JSX.Element {
  const { team } = props;
  const participants = useSelectTeamParticipants(
    team.id,
    undefined,
    team.captainScribe ?? undefined
  );

  return (
    <div
      className='w-full flex flex-col gap-4 border-2 rounded-lg bg-lp-gray-009'
      style={{ borderColor: team.color }}
    >
      <div
        className='text-sms font-bold truncate p-2'
        style={{ color: team.color }}
      >
        {team.name}
      </div>

      <div className='grid grid-cols-2 gap-y-2.5 gap-x-2 px-2 pb-2'>
        {participants.map((participant) => (
          <InstructionPlayerView
            key={participant.id}
            participant={participant}
          />
        ))}
      </div>
    </div>
  );
}

export interface InstructionBlockTeamsProps {
  teamId?: string | null;
  accessory?: React.ReactNode;
}

export function InstructionBlockTeams(
  props: InstructionBlockTeamsProps
): JSX.Element {
  const { teamId, accessory } = props;

  const teams = useTeams({
    updateStaffTeam: true,
    excludeStaffTeam: true,
    pinTeamId: teamId,
  });

  return (
    <div className='w-full h-full'>
      <div className='w-full h-full flex flex-col rounded-lg bg-layer-001 bg-opacity-80'>
        <div className='w-full flex items-center justify-between px-4 py-2'>
          <div className='text-white tracking-wide font-medium'>Teams</div>
          {accessory}
        </div>
        <div className='w-full flex-1 min-h-0 overflow-auto scrollbar flex flex-col gap-2.5 px-1'>
          {teams.map((team) => (
            <InstructionTeamView key={team.id} team={team} />
          ))}
        </div>
      </div>
    </div>
  );
}

export function InstructionBlockRightPanel(props: {
  children: React.ReactNode;
}): JSX.Element {
  return <div className='col-span-1'>{props.children}</div>;
}
