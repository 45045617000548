import { useLocation, useNavigate } from '@remix-run/react';
import { useMemo, useState } from 'react';
import useSWR from 'swr';

import {
  type DtoBlockPlayedSnapshot,
  type DtoBrand,
  type EnumsGamePackVersion,
  type GetGamePackParams,
  type ModelsPlaybackSettings,
} from '@lp-lib/api-service-client/public';
import { type Block } from '@lp-lib/game';

import { getFeatureQueryParamNumber } from '../../../hooks/useFeatureQueryParam';
import { useLiveCallback } from '../../../hooks/useLiveCallback';
import { useGamePackContext } from '../../../pages/GamePack/Context';
import { apiService } from '../../../services/api-service';
import { GamePackUtils } from './utils';

function extraFrom(state: unknown): string | null {
  if (!state || typeof state !== 'object' || !('from' in state)) return null;
  const from = state['from'];
  if (typeof from === 'string') return from;
  return null;
}

export function useCloseRoutedGamePackEditor() {
  const navigate = useNavigate();
  const location = useLocation();
  const ctx = useGamePackContext();
  // extract on load
  const [from] = useState(extraFrom(location.state));

  return useLiveCallback(async () => {
    if (ctx.embed) return;
    if (from) {
      navigate(from);
      return;
    }
    navigate(`${ctx.editRoutePrefix}`);
  });
}

type OpenGamePackEditorParams =
  | {
      type: 'create';
      version: EnumsGamePackVersion;
    }
  | {
      type: 'edit';
      packId: string;
    };

export function useOpenRoutedGamePackEditor(): (
  params: OpenGamePackEditorParams
) => void {
  const navigate = useNavigate();
  const location = useLocation();
  const ctx = useGamePackContext();
  return useLiveCallback((params: OpenGamePackEditorParams) => {
    if (ctx.embed) return;
    const from = `${location.pathname}${location.search}`;
    if (params.type === 'create') {
      navigate(`${ctx.editRoutePrefix}/v${params.version}/create`, {
        state: { from },
      });
    } else {
      navigate(`${ctx.editRoutePrefix}/edit/${params.packId}`, {
        state: { from },
      });
    }
  });
}

export function useGamePack(
  id: Nullable<string>,
  params?: Omit<GetGamePackParams, 'id'>
) {
  return useSWR(id ? [`game-packs`, id, params] : null, async ([, id]) => {
    const resp = await apiService.gamePack.getGamePackById(id, params);
    return resp.data;
  });
}

export function useGamePackLinkedGames(id: Nullable<string>) {
  return useSWR(
    id ? [`game-packs`, id, 'linked-games'] : null,
    async ([, id]) => (await apiService.gamePack.getLinkedGames(id)).data.games
  );
}

export function useGamePackLinkedBrands(id: Nullable<string>) {
  return useSWR(
    id ? [`game-packs`, id, 'linked-brands'] : null,
    async ([, id]) =>
      (await apiService.gamePack.getLinkedBrands(id)).data.brands
  );
}

export function useGameScores(id: Nullable<string>) {
  return useSWR(
    id ? ['/game-scores', id] : null,
    async ([, id]) =>
      (
        await apiService.gameScore.searchGameScore(id, {
          size: 100,
          sortByUpdatedAt: true,
        })
      ).data.gameScores
  );
}

export function useMakePlaybackPreview(
  blocks: Block[],
  playbackSettings: Nullable<ModelsPlaybackSettings>
) {
  // It's werid that the _playbackSettings_ it self doesn't change even if the
  // fields inside it change. So use fields as deps instead.
  const {
    defaultUnitsPerSession,
    instructionRules,
    leaderboardRules,
    lockUnitsPerSession,
    makeUnitsFrom,
  } = playbackSettings ?? {};
  return useMemo(() => {
    return GamePackUtils.MakePlaybackPreview(blocks, {
      defaultUnitsPerSession,
      instructionRules,
      leaderboardRules,
      lockUnitsPerSession,
      makeUnitsFrom,
    });
  }, [
    blocks,
    defaultUnitsPerSession,
    instructionRules,
    leaderboardRules,
    lockUnitsPerSession,
    makeUnitsFrom,
  ]);
}

export function useBrandMap(brands: Map<string, DtoBrand> | DtoBrand[]) {
  // It's tempting to use some sort of memoization or caching here. But it's
  // tricky because react doesn't know when the map is written to, so it won't
  // update the parent component if a write happens. This results in out of date
  // "views" of the map. And if it appears to work, it's because something else
  // caused the parent component to rerender and "pull" from the map again! Plus
  // you don't know if keys should be deleted from the map or not in the case of
  // an incoming array.

  // This is cheap since most gamepacks likely have less than 100 brands.
  if (Array.isArray(brands))
    return new Map(brands.map((brand) => [brand.id, brand]));
  else return brands;
}

export function useFakePlayedHistory(
  blocks: Block[],
  n = getFeatureQueryParamNumber('gpv2-fake-played-history')
) {
  return useMemo(() => {
    const snapshots: DtoBlockPlayedSnapshot[] = [];
    for (let i = 0; i < n; i++) {
      const block = blocks[i];
      if (!block) break;
      snapshots.push({
        blockId: block.id,
        playedAt: new Date().toISOString(),
      });
    }
    return snapshots;
  }, [blocks, n]);
}
