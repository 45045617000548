import { useEffect, useMemo, useState } from 'react';
import { useEffectOnce } from 'react-use';

import { type Block } from '@lp-lib/game';

import { ModalWrapper } from '../../ConfirmCancelModalContext/ModalWrapper';
import { useGameSessionPreconfigAPI } from '../../Game/Blocks/Common/GamePlay/GameSessionPreconfigProvider';
import { BlockKnifeUtils } from '../../Game/Blocks/Shared';
import { VIPPreConfig } from './VIPPreConfig';

function Completed(props: { onComplete: () => void }): JSX.Element | null {
  const { onComplete } = props;
  useEffectOnce(() => {
    onComplete();
  });
  return null;
}

export function PreLaunchChecklist(props: {
  blocks: Block[];
  recordingSeconds: number;
  onComplete: () => void;
  onCancel: () => void;
  skip?: boolean;
}): JSX.Element | null {
  const [step, setStep] = useState(0);
  const next = () => setStep((step) => step + 1);

  const api = useGameSessionPreconfigAPI();
  useEffect(() => {
    api.reset();
  }, [api]);

  const steps = useMemo(() => {
    const res = [];

    const requiresVIP = props.blocks.some(BlockKnifeUtils.RequiresVIP);
    if (requiresVIP) {
      res.push(<VIPPreConfig onConfirm={next} onCancel={props.onCancel} />);
    }

    return res;
  }, [props]);

  if (props.skip || step >= steps.length) {
    return <Completed onComplete={props.onComplete} />;
  }

  return <ModalWrapper>{steps[step]}</ModalWrapper>;
}
