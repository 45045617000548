export function MemoryMatchBlockIcon(
  props: React.SVGProps<SVGSVGElement>
): JSX.Element {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      className={props.className ?? 'w-3.5 h-3.5 fill-current'}
      viewBox='0 0 14 14'
    >
      <path
        fill='#353DFF'
        d='M5.4 6.84c0-.85.74-1.54 1.65-1.54h3.3c.912 0 1.65.69 1.65 1.54v4.62c0 .85-.738 1.54-1.65 1.54h-3.3c-.91 0-1.65-.69-1.65-1.54V6.84z'
      ></path>
      <path
        fill='#353DFF'
        fillRule='evenodd'
        d='M2.65.9C1.739.9 1 1.59 1 2.44v4.62c0 .85.739 1.54 1.65 1.54H4.3V6.84c0-1.528 1.304-2.64 2.75-2.64h.55V2.44C7.6 1.59 6.861.9 5.95.9h-3.3zM7.6 5.3h-.55c-.911 0-1.65.69-1.65 1.54V8.6h.55c.911 0 1.65-.69 1.65-1.54V5.3z'
        clipRule='evenodd'
      ></path>
    </svg>
  );
}
