export const ToolsIcon = (props: { className?: string }): JSX.Element => {
  return (
    <svg
      className={props.className ?? `w-4 h-4 fill-current`}
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect
        x='1.14258'
        y='1.14285'
        width='5.71429'
        height='5.71429'
        rx='1'
        fill='white'
      />
      <rect
        x='9.14258'
        y='1.14285'
        width='5.71428'
        height='5.71429'
        rx='1'
        fill='white'
      />
      <rect
        x='1.14258'
        y='9.14285'
        width='5.71429'
        height='5.71428'
        rx='1'
        fill='white'
      />
      <rect
        x='9.14258'
        y='9.14285'
        width='5.71428'
        height='5.71428'
        rx='1'
        fill='white'
      />
    </svg>
  );
};
