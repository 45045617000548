import React, { useContext, useEffect, useMemo, useState } from 'react';
import { type ReactNode } from 'react';
import { proxy } from 'valtio';

import {
  markSnapshottable,
  useSnapshot,
  type ValtioSnapshottable,
} from '../../utils/valtio';
import { GlobalLoading } from '../GlobalLoading';

type State = {
  inited: boolean;
};

type Context = {
  state: ValtioSnapshottable<State>;
};

const context = React.createContext<Context | null>(null);

function useVenueInitContext() {
  const ctx = useContext(context);
  if (!ctx) throw new Error('VenueInitContext is not in the tree!');
  return ctx;
}

export const VenueInitProvider = (props: {
  children?: ReactNode;
}): JSX.Element | null => {
  const [state] = useState(() =>
    markSnapshottable<State>(
      proxy({
        inited: false,
      })
    )
  );

  const ctxValue = useMemo(() => ({ state }), [state]);

  return <context.Provider value={ctxValue}>{props.children}</context.Provider>;
};

export function VenueInitLoading(): JSX.Element | null {
  const { state } = useVenueInitContext();
  const inited = useSnapshot(state).inited;
  if (inited) return null;
  return <GlobalLoading debug='venue-init-loading' />;
}
export function useMarkVenueInitCompleted(condition: boolean) {
  const { state } = useVenueInitContext();
  useEffect(() => {
    if (!condition) return;
    state.inited = true;
  }, [condition, state]);
}
