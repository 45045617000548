import sample from 'lodash/sample';

import { ProfileIndex } from '@lp-lib/crowd-frames-schema';
import { assertExhaustive, IcebreakerOnStageSelection } from '@lp-lib/game';

import { type Participant } from '../../../../types';
import { CrowdFramesAvatar } from '../../../CrowdFrames';
import { useParticipantFlags } from '../../../Player';
import { useParticipantsAsArray } from '../../../Player';
import { StageMode, useSelectOnStageMembers } from '../../../Stage';
import { StreamView } from '../../../Stage/StreamView';
import { useRTCService } from '../../../WebRTC';

export function pickFirstIcebreakerParticipant(
  getParticipants: () => ReturnType<typeof useParticipantsAsArray>,
  getVipUserIds: () => string[],
  selection: IcebreakerOnStageSelection
): Participant | null {
  switch (selection) {
    case IcebreakerOnStageSelection.VIP:
      const participants = getParticipants();
      const vipUserIds = getVipUserIds();
      const vipParticipants = participants.filter((p) =>
        vipUserIds.includes(p.id)
      );
      return sample(vipParticipants) || sample(participants) || null;
    case IcebreakerOnStageSelection.Random:
      return sample(getParticipants()) || null;
    case IcebreakerOnStageSelection.None:
      return null;
    default:
      assertExhaustive(selection);
      return null;
  }
}

export function pickIcebreakerNextParticipant(
  currentUid: string,
  getParticipants: () => ReturnType<typeof useParticipantsAsArray>
): Participant | null {
  const participants = getParticipants();

  const sorted = participants.sort((a, b) => {
    if (a.joinedAt !== b.joinedAt) return a.joinedAt - b.joinedAt;
    return a.id.localeCompare(b.id);
  });

  const currentPlayerIndex = sorted.findIndex((p) => p.id === currentUid);
  const nextPlayerIndex = currentPlayerIndex + 1;
  return sorted[nextPlayerIndex] || sorted[0] || null;
}

export function OnStagePlayer(props: {
  participant: Participant | null;
}): JSX.Element {
  const rtcService = useRTCService('stage');
  const stageMembers = useSelectOnStageMembers(StageMode.BLOCK_CONTROLLED);
  const flags = useParticipantFlags(props.participant?.clientId);

  const stageMember = stageMembers.find(
    (m) => m.id === props.participant?.clientId
  );

  return (
    <div className='absolute w-76 h-76 rounded-5xl'>
      <StreamView
        className={`w-full h-full ${
          flags?.onStageMuted
            ? 'rounded-full border-blue-700 border-4'
            : `rounded-xl`
        } drop-shadow-lg`}
        member={stageMember}
        rtcService={rtcService}
        disableRemove
      />
    </div>
  );
}

export function IcebreakerBlockParticipantPicker(props: {
  onPick: (p: Participant) => void;
  onCancel?: () => void;
  isParticipantDisabled: (p: Participant, ps: Participant[]) => boolean;
}): JSX.Element {
  const participants = useParticipantsAsArray({
    filters: ['host:false', 'cohost:false', 'status:connected', 'staff:false'],
    sorts: ['username:asc'],
  });

  return (
    <div className='relative w-full h-full p-8 flex flex-col items-center overflow-auto scrollbar'>
      {props.onCancel && (
        <button
          type='button'
          className='absolute right-3 top-3 btn text-sms font-normal'
          onClick={props.onCancel}
        >
          Cancel
        </button>
      )}

      <p className='max-w-144 text-2xl font-medium text-center'>
        Who’s up next? Choose the player who you want to take the next turn.
      </p>

      <div className='mt-7.5 w-full grid grid-cols-3 gap-4'>
        {participants.map((p) => (
          <button
            type='button'
            key={p.id}
            className='w-full h-15 btn-secondary p-2.5 flex items-center'
            onClick={() => props.onPick(p)}
            disabled={props.isParticipantDisabled(p, participants)}
          >
            <div className='relative w-10 h-10'>
              <CrowdFramesAvatar
                profileIndex={ProfileIndex.wh36x36fps4}
                enablePointerEvents={false}
                participant={p}
                reflectOnStageStatus={false}
                renderFrameOnStage={true}
                roundedClassname='w-10 h-10 rounded-full'
                throttleRegistration={true}
              />
            </div>
            <p className='flex-1 h-full flex justify-center items-center text-center text-base font-medium'>
              {p.username}
            </p>
          </button>
        ))}
      </div>
    </div>
  );
}

export function IcebreakerBlockVoterList(props: {
  participants: Participant[];
}) {
  let showParticipants = props.participants;
  let trimmedCount = 0;
  if (props.participants.length > 20) {
    showParticipants = props.participants.slice(0, 19);
    trimmedCount = props.participants.length - 19;
  }

  return (
    <div className='mt-1 h-fit-content grid grid-flow-col grid-rows-5 gap-2'>
      {showParticipants.map((p) => (
        <div className='relative w-12.5 h-12.5' key={p.id}>
          <CrowdFramesAvatar
            profileIndex={ProfileIndex.wh36x36fps4}
            enablePointerEvents={false}
            participant={p}
            reflectOnStageStatus={false}
            renderFrameOnStage={true}
            roundedClassname='w-12.5 h-12.5 rounded-full'
            throttleRegistration={true}
          />
        </div>
      ))}

      {trimmedCount > 0 && (
        <div
          style={{
            boxShadow: '0px 1px 4px 0px rgba(0, 0, 0, 0.40)',
          }}
          className='w-12.5 h-12.5 bg-modal rounded-full flex justify-center items-center text-sms'
        >
          +{trimmedCount}
        </div>
      )}
    </div>
  );
}
