import { type ReactNode, useEffect } from 'react';

import {
  REDIRECT_CUSTOM_TARGET_MAP,
  useUserRedirect,
} from '../../hooks/useRedirection';
import { RoleUtils } from '../../types/user';
import { GlobalLoading } from '../GlobalLoading';
import { useIsUserLoaded, useUser } from '../UserContext';

export function AdminAccessDenied(): JSX.Element {
  return (
    <div className='w-full h-full flex items-center justify-center text-white'>
      <h1>Sorry, you are not allowed to access this page.</h1>
    </div>
  );
}

export function AdminAccess(props: {
  children?: ReactNode;
}): JSX.Element | null {
  const user = useUser({ init: true });
  const redirect = useUserRedirect();
  const isUserLoaded = useIsUserLoaded();

  useEffect(() => {
    if (!isUserLoaded) return;
    redirect([
      { kind: 'admin', target: REDIRECT_CUSTOM_TARGET_MAP.noop },
      { kind: 'organizer', target: '/access-denied' },
      { kind: 'venueActivated', target: '/access-denied' },
      { kind: 'guest', target: '/access-denied' },
      { kind: 'final', target: '/access-denied' },
    ]);
  }, [isUserLoaded, redirect]);

  if (!isUserLoaded) {
    return <GlobalLoading />;
  }
  if (!RoleUtils.isAdmin(user)) {
    return null;
  }
  return <>{props.children ?? null}</>;
}
