import capitalize from 'lodash/capitalize';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import range from 'lodash/range';
import React, { forwardRef, type ReactNode, useMemo, useState } from 'react';
import { usePopperTooltip } from 'react-popper-tooltip';
import Select from 'react-select';
import useFitText from 'use-fit-text';

import {
  EnumsJeopardyHostScriptKey,
  EnumsMediaScene,
  type ModelsTTSLabeledRenderSettings,
  type ModelsTTSScript,
} from '@lp-lib/api-service-client/public';
import {
  type BlockFields,
  type GridSize,
  type JeopardyBlock,
  type JeopardyBlockMedia,
  type JeopardyCategory,
  type JeopardyClue,
} from '@lp-lib/game';

import { useLiveAsyncCall } from '../../../../hooks/useAsyncCall';
import { useLiveCallback } from '../../../../hooks/useLiveCallback';
import { uuidv4 } from '../../../../utils/common';
import { csvToArray } from '../../../../utils/csv';
import { buildReactSelectStyles } from '../../../../utils/react-select';
import { type Option } from '../../../common/Utilities';
import { useAwaitFullScreenConfirmCancelModal } from '../../../ConfirmCancelModalContext';
import { AIRedIcon } from '../../../icons/AIIcon';
import { ArrowDownIcon } from '../../../icons/Arrows';
import { Loading } from '../../../Loading';
import { TTSOptionEditor } from '../../../VoiceOver/TTSOptionEditor';
import { TTSPreviewButton } from '../../../VoiceOver/TTSPreviewButton';
import { TTSScriptEditor } from '../../../VoiceOver/TTSScriptEditor';
import {
  type ScriptScenarioOption,
  TTSScriptsEditor,
} from '../../../VoiceOver/TTSScriptsEditor';
import { VariableRegistry } from '../../../VoiceOver/VariableRegistry';
import {
  AdditionalSettings,
  AdditionalSharedSettingsEditor,
  type BlockFieldUpdater,
  BlockMediaEditor,
  CSVUtils,
  type EditorProps,
  useEditor,
} from '../Common/Editor/EditorUtilities';
import {
  FieldDescription,
  FieldPanel,
  FieldTitle,
  SimpleFieldEditor,
} from '../Common/Editor/FieldEditorUtilities';
import { JeopardyBoard } from './JeopardyBoard';
import { type BoardView, type Category, type Clue } from './types';
import { type BoardDirtyMap, JeopardyUtils } from './utils';

/// some jeopardy specific stuff...

const supportedVariables = new VariableRegistry()
  .set('contestantName', async () => 'Jesse')
  .set('contestantTeamName', async () => 'Smart Dolphins');

const hostScriptScenarioOptions: ScriptScenarioOption[] = [
  {
    label: 'Categories Introduction',
    description: 'Spoken just before the categories are revealed.',
    tags: [EnumsJeopardyHostScriptKey.JeopardyHostScriptKeyCategoriesIntro],
  },
  {
    label: 'Select a Clue',
    description: 'Spoken to the contestant that should select a clue.',
    tags: [EnumsJeopardyHostScriptKey.JeopardyHostScriptKeySelectClue],
    supportedVariables,
    supplementalTags: [
      EnumsJeopardyHostScriptKey.JeopardyHostScriptKeyFirstTime,
    ],
  },
  {
    label: 'Correct Response',
    description: 'Spoken to the contestant when they answer correctly.',
    tags: [EnumsJeopardyHostScriptKey.JeopardyHostScriptKeyCorrectResponse],
    supportedVariables,
    supplementalTags: [
      EnumsJeopardyHostScriptKey.JeopardyHostScriptKeyFirstTime,
      EnumsJeopardyHostScriptKey.JeopardyHostScriptKeyGameOver,
    ],
  },
  {
    label: 'Incorrect – Next Buzzer',
    description:
      'Spoken to the contestant when they answer incorrectly, and there are more contestants that have buzzed in.',
    tags: [EnumsJeopardyHostScriptKey.JeopardyHostScriptKeyIncorrectNextBuzzer],
    supportedVariables,
    supplementalTags: [
      EnumsJeopardyHostScriptKey.JeopardyHostScriptKeyFirstTime,
    ],
  },
  {
    label: 'Incorrect – No More Buzzers',
    description:
      'Spoken to the contestant when they answer incorrectly, and there are no more contestants that have buzzed in.',
    tags: [
      EnumsJeopardyHostScriptKey.JeopardyHostScriptKeyIncorrectNoBuzzQueue,
    ],
    supportedVariables,
    supplementalTags: [
      EnumsJeopardyHostScriptKey.JeopardyHostScriptKeyFirstTime,
      EnumsJeopardyHostScriptKey.JeopardyHostScriptKeyGameOver,
    ],
  },
];

function getTagLabel(tag: string): string {
  switch (tag) {
    case EnumsJeopardyHostScriptKey.JeopardyHostScriptKeyCategoriesIntro:
      return 'Categories Introduction';
    case EnumsJeopardyHostScriptKey.JeopardyHostScriptKeySelectClue:
      return 'Select a Clue';
    case EnumsJeopardyHostScriptKey.JeopardyHostScriptKeyCorrectResponse:
      return 'Correct Response';
    case EnumsJeopardyHostScriptKey.JeopardyHostScriptKeyIncorrectNextBuzzer:
      return 'Incorrect – Next Buzzer';
    case EnumsJeopardyHostScriptKey.JeopardyHostScriptKeyIncorrectNoBuzzQueue:
      return 'Incorrect – No More Buzzers';
    case EnumsJeopardyHostScriptKey.JeopardyHostScriptKeyFirstTime:
      return 'First Time';
    case EnumsJeopardyHostScriptKey.JeopardyHostScriptKeyGameOver:
      return 'Game Over';
    default:
      return tag;
  }
}

function TimerInput(props: {
  value: number;
  onChange: (value: number) => void;
}) {
  return (
    <div className='w-full flex items-center'>
      <input
        type='number'
        defaultValue={props.value}
        onBlur={(e) => props.onChange(e.target.valueAsNumber)}
        className='field flex-1 h-10 m-0 w-full rounded-r-none'
        min={1}
      />
      <div className='bg-layer-002 rounded-r-xl h-10 flex items-center justify-center border-secondary border border-l-0 font-bold text-sms text-white px-3'>
        seconds
      </div>
    </div>
  );
}

type AwardInputProps = {
  value: number;
};

const AwardInput = forwardRef<HTMLInputElement, AwardInputProps>(
  (props: AwardInputProps, ref) => {
    return (
      <div className='w-full flex items-center'>
        <div className='bg-layer-002 rounded-l-xl h-10 flex items-center justify-center border-secondary border border-r-0 font-bold text-sms text-white px-3'>
          $
        </div>
        <input
          ref={ref}
          type='number'
          defaultValue={props.value}
          className='field flex-1 h-10 m-0 w-full rounded-l-none'
          min={1}
        />
      </div>
    );
  }
);

function BoardSizeEditor(props: {
  value: GridSize;
  onChange: (value: GridSize) => void;
}) {
  const styles = useMemo(() => buildReactSelectStyles<Option<number>>(), []);
  const rowOptions: Option<number>[] = useMemo(
    () => range(2, 7).map((v) => ({ label: v.toString(), value: v })),
    []
  );
  const colOptions: Option<number>[] = useMemo(
    () => range(1, 7).map((v) => ({ label: v.toString(), value: v })),
    []
  );

  return (
    <div className='w-full flex items-center justify-end gap-4 text-white'>
      <div className='flex flex-col items-center gap-2'>
        <span className='text-base text-white font-bold'>Rows</span>
        <Select<Option<number>, false>
          classNamePrefix='select-box-v2'
          className='w-full text-xs'
          styles={styles}
          value={{
            label: props.value.numRows.toString(),
            value: props.value.numRows,
          }}
          options={rowOptions}
          onChange={(option) => {
            if (option)
              props.onChange({ ...props.value, numRows: option.value });
          }}
          isSearchable={false}
          isClearable={false}
        />
      </div>
      <div className='flex flex-col items-center gap-2'>
        <span className='text-base text-white font-bold'>Cols</span>
        <Select<Option<number>, false>
          classNamePrefix='select-box-v2'
          className='w-full text-xs'
          styles={styles}
          value={{
            label: props.value.numCols.toString(),
            value: props.value.numCols,
          }}
          options={colOptions}
          onChange={(option) => {
            if (option)
              props.onChange({ ...props.value, numCols: option.value });
          }}
          isSearchable={false}
          isClearable={false}
        />
      </div>
    </div>
  );
}

function createCSVBoardFile(
  board: BlockFields<JeopardyBlock>['board']
): string {
  const categories = board.categories ?? [];
  const rows: string[] = [];
  for (const category of categories) {
    for (const clue of category.clues) {
      rows.push(
        [category.name, clue.question, clue.answer, clue.value.toString()]
          .map((v) => `"${v.replace(/"/g, '""')}"`)
          .join(',')
      );
    }
  }

  return URL.createObjectURL(
    new Blob(
      [
        [['category', 'question', 'answer', 'value'].join(','), ...rows].join(
          '\n'
        ),
      ],
      {
        type: 'text/csv',
      }
    )
  );
}

function CategoryEditor(props: {
  value: JeopardyCategory;
  ttsRenderSettings: ModelsTTSLabeledRenderSettings | null | undefined;
  onCancel: () => void;
  onSave: (value: JeopardyCategory) => Promise<void>;
}) {
  const [name, setName] = useState(props.value.name);
  const [nameScript, setNameScript] = useState(props.value.nameScript);
  const {
    call: handleSave,
    state: { state },
  } = useLiveAsyncCall(async () => {
    await props.onSave({
      ...props.value,
      name,
      nameScript,
    });
  });

  return (
    <div className='border border-secondary bg-black rounded-xl px-5 py-3 min-w-152 w-4/5 xl:w-1/2 min-h-45'>
      <div className='w-full h-full flex flex-col text-white'>
        <div className='flex-none w-full pt-2 pb-4'>
          <div className='font-bold text-2xl'>Edit Category</div>
        </div>

        <div className='flex-grow flex-shrink-0 w-full py-6 space-y-5'>
          <div className='grid grid-cols-2 gap-x-6'>
            <FieldPanel>
              <FieldTitle>Category Name</FieldTitle>
              <FieldDescription>
                The name of the category. This is visible throughout the game.
              </FieldDescription>
            </FieldPanel>
            <input
              className='field h-13.5 m-0 w-full'
              placeholder='Max 150 characters'
              maxLength={150}
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <div className='grid grid-cols-2 gap-x-6'>
            <FieldPanel>
              <FieldTitle>Category Voice Over</FieldTitle>
              <FieldDescription>
                The category name is read when categories are initially
                presented. You can override the pronunciation of the category
                name by specifying a custom script.
              </FieldDescription>
            </FieldPanel>
            <TTSScriptEditor
              value={nameScript}
              placeholder={name}
              onChange={setNameScript}
              settings={props.ttsRenderSettings}
              onBeforeRender={async (v) => v || name}
            />
          </div>
        </div>

        <div className='mt-auto w-full py-2 flex items-center justify-end gap-4'>
          <button
            type='button'
            className='btn-secondary w-40 py-2'
            onClick={props.onCancel}
          >
            Cancel
          </button>
          <button
            type='button'
            className='btn-primary w-40 py-2 flex items-center justify-center gap-2'
            onClick={handleSave}
            disabled={state.isRunning}
          >
            {state.isRunning && <Loading imgClassName='w-5 h-5' text='' />}
            Save
          </button>
        </div>
      </div>
    </div>
  );
}

function ClueEditor(props: {
  value: JeopardyClue;
  ttsRenderSettings: ModelsTTSLabeledRenderSettings | null | undefined;
  onCancel: () => void;
  onSave: (clue: JeopardyClue) => Promise<void>;
}) {
  const promptRef = React.useRef<HTMLTextAreaElement>(null);
  const answerRef = React.useRef<HTMLTextAreaElement>(null);
  const awardRef = React.useRef<HTMLInputElement>(null);

  const {
    call: handleSave,
    state: { state },
  } = useLiveAsyncCall(async () => {
    if (!promptRef.current || !answerRef.current || !awardRef.current) return;
    let value = awardRef.current.valueAsNumber;
    if (isNaN(value)) value = 0;

    await props.onSave({
      ...props.value,
      question: promptRef.current.value,
      answer: answerRef.current.value,
      value,
    });
  });

  return (
    <div className='border border-secondary bg-black rounded-xl px-5 py-3 min-w-152 w-4/5 xl:w-1/2 min-h-45'>
      <div className='w-full h-full flex flex-col text-white'>
        <div className='flex-none w-full pt-2 pb-4'>
          <div className='font-bold text-2xl'>Edit Clue</div>
        </div>

        <div className='flex-grow flex-shrink-0 w-full py-6 space-y-5'>
          <div className='grid grid-cols-2 gap-x-6'>
            <FieldPanel>
              <FieldTitle>Clue</FieldTitle>
              <FieldDescription>
                The prompt for this clue. This is visible to users when this
                clue is selected. It's traditional in Jeopardy to phrase this as
                a statement (ex: The third planet from the sun).
              </FieldDescription>
            </FieldPanel>
            <div className='w-full flex gap-2'>
              <textarea
                ref={promptRef}
                className='flex-1 h-30 py-2 px-2.5 resize-y mb-0 scrollbar field'
                placeholder='Max 150 characters'
                defaultValue={props.value.question}
              />
              <TTSPreviewButton
                script={props.value.question}
                settings={props.ttsRenderSettings}
                onBeforeRender={async (v) => promptRef.current?.value ?? v}
              />
            </div>
          </div>

          <div className='grid grid-cols-2 gap-x-6'>
            <FieldPanel>
              <FieldTitle>Correct Response</FieldTitle>
              <FieldDescription>
                The correct response for this clue. This is visible to users
                when they are judging a user response, and visible to all users
                on answer reveal. This is traditionally phrased as a question
                (ex: What is Earth?).
              </FieldDescription>
            </FieldPanel>
            <div className='w-full flex gap-2'>
              <textarea
                ref={answerRef}
                className='flex-1 h-30 py-2 px-2.5 resize-y mb-0 scrollbar field'
                placeholder='Max 150 characters'
                defaultValue={props.value.answer}
              />
              <TTSPreviewButton
                script={props.value.answer}
                settings={props.ttsRenderSettings}
                onBeforeRender={async (v) => answerRef.current?.value ?? v}
              />
            </div>
          </div>

          <div className='grid grid-cols-2 gap-x-6'>
            <FieldPanel>
              <FieldTitle>Award</FieldTitle>
              <FieldDescription>
                The award for this clue. This will be shown as a monetary value,
                but will be awarded as points.
              </FieldDescription>
            </FieldPanel>
            <AwardInput ref={awardRef} value={props.value.value} />
          </div>
        </div>

        <div className='mt-auto w-full py-2 flex items-center justify-end gap-4'>
          <button
            type='button'
            className='btn-secondary w-40 py-2'
            onClick={props.onCancel}
          >
            Cancel
          </button>
          <button
            type='button'
            className='btn-primary w-40 py-2 flex items-center justify-center gap-2'
            onClick={handleSave}
            disabled={state.isRunning}
          >
            {state.isRunning && <Loading imgClassName='w-5 h-5' text='' />}
            Save
          </button>
        </div>
      </div>
    </div>
  );
}

export function JeopardyBoardEditor(
  props: EditorProps<JeopardyBlock> & {
    fieldUpdater: BlockFieldUpdater<JeopardyBlock>;
    setBusyMsg: (msg: string | undefined) => void;
    onCategoryUpdated?: (
      prev: JeopardyCategory,
      next: JeopardyCategory
    ) => void;
    onClueUpdated?: (prev: JeopardyClue, next: JeopardyClue) => void;
    onCSVUploaded?: (
      board: BlockFields<JeopardyBlock>['board'],
      numRows: number,
      numCols: number
    ) => void;
    csvUtilsEnabled?: boolean;
    dirtyMap?: BoardDirtyMap;
  }
) {
  const { block, setBusyMsg, fieldUpdater: updateField } = props;
  const triggerModal = useAwaitFullScreenConfirmCancelModal();

  const handleUpload = useLiveCallback(
    async (board: BlockFields<JeopardyBlock>['board']) => {
      if (!board.categories) return;

      const resp = await triggerModal({
        kind: 'confirm-cancel',
        prompt: (
          <div className='p-5 text-white text-center'>
            <p className='text-2xl font-medium'>Are you sure?</p>
            <p className='mt-4 text-sms'>
              This action will overwrite and save any existing board data.
            </p>
          </div>
        ),
        confirmBtnLabel: 'Continue',
        cancelBtnLabel: 'Cancel',
      });
      if (resp.result === 'canceled') return;

      setBusyMsg('Importing...');
      try {
        const numCols = board.categories.length;
        const numRows =
          Math.max(...board.categories.map((c) => c.clues.length)) + 1;

        await updateField('board', board);
        await updateField('boardSize', { numRows, numCols });

        const defaultTTSSettings = block.fields.ttsOptions?.[0];
        if (!defaultTTSSettings) return;

        props.onCSVUploaded?.(board, numRows, numCols);
      } finally {
        setBusyMsg(undefined);
      }
    }
  );

  const handleBoard = useLiveCallback(
    async (board: BlockFields<JeopardyBlock>['board']) => {
      await updateField('board', board);
    }
  );

  const handleClickCategory = useLiveCallback(
    async (category: JeopardyCategory, index: number) => {
      await triggerModal({
        kind: 'custom',
        element: (p) => {
          const handleSave = async (next: JeopardyCategory) => {
            const categories = [...(block.fields.board.categories ?? [])];
            categories[index] = next;
            await updateField('board', { categories });

            props.onCategoryUpdated?.(category, next);
            p.internalOnConfirm();
          };
          return (
            <CategoryEditor
              value={category}
              ttsRenderSettings={block.fields.ttsOptions?.[0]}
              onCancel={p.internalOnCancel}
              onSave={handleSave}
            />
          );
        },
      });
    }
  );

  const handleClickClue = useLiveCallback(
    async (clue: JeopardyClue, row: number, col: number) => {
      await triggerModal({
        kind: 'custom',
        element: (p) => {
          const handleSave = async (next: JeopardyClue) => {
            const categories = [...(block.fields.board.categories ?? [])];
            const category = categories[col];
            if (!category) {
              p.internalOnConfirm();
              return;
            }

            const clues = [...category.clues];
            clues[row] = {
              ...clue,
              ...next,
            };

            categories[col] = {
              ...category,
              clues,
            };
            await updateField('board', { categories });

            const defaultTTSSettings = block.fields.ttsOptions?.[0];
            if (!defaultTTSSettings) {
              p.internalOnConfirm();
              return;
            }

            props.onClueUpdated?.(clue, next);
            p.internalOnConfirm();
          };
          return (
            <ClueEditor
              value={clue}
              ttsRenderSettings={block.fields.ttsOptions?.[0]}
              onCancel={p.internalOnCancel}
              onSave={handleSave}
            />
          );
        },
      });
    }
  );

  const handleClickCell = useLiveCallback(async (cell: Clue | Category) => {
    const categories = [...(block.fields.board.categories ?? [])];
    const category = categories[cell.col];
    if (!category) return;

    if (cell.type === 'category') {
      await handleClickCategory(category, cell.col);
      return;
    } else {
      const row = cell.row - 1;
      const clue = category.clues[row];
      if (!clue) return;
      await handleClickClue(clue, row, cell.col);
    }
  });

  const handleCSVUpload = useLiveCallback((content: string | ArrayBuffer) => {
    const csv = csvToArray(content.toString());
    if (csv.length <= 1) return;
    const rows = csv.splice(1);

    const categories: JeopardyCategory[] = [];
    let currentCategory: JeopardyCategory | null = null;

    rows.forEach((row) => {
      if (row.length <= 1) return;

      const [categoryName = '', question = '', answer = '', valueStr = '0'] =
        row.map((r) => r.trim());
      let value = parseInt(valueStr, 10);
      if (isNaN(value)) value = 0;

      if (!currentCategory || currentCategory.name !== categoryName) {
        currentCategory = {
          id: uuidv4(),
          name: categoryName,
          clues: [],
        };
        categories.push(currentCategory);
      }

      currentCategory.clues.push({
        id: uuidv4(),
        question,
        answer,
        value,
      });
    });
    handleUpload({ categories });
  });

  const downloadHref = useMemo(() => {
    return createCSVBoardFile(block.fields.board);
  }, [block.fields.board]);

  return (
    <JeopardyBlockPreview
      board={block.fields.board}
      boardSize={block.fields.boardSize}
      onClickCell={handleClickCell}
      onUpdateBoard={handleBoard}
      rightAccessory={
        props.csvUtilsEnabled ? (
          <CSVUtils
            id={block.id}
            onUpload={handleCSVUpload}
            downloadHref={downloadHref}
            downloadName={`jeopardy-${props.block.id}.csv`}
          />
        ) : null
      }
      dirtyMap={props.dirtyMap}
    />
  );
}

export function JeopardyBlockEditor(
  props: EditorProps<JeopardyBlock>
): JSX.Element {
  const { block } = props;
  const { updateField } = useEditor(props);
  const triggerModal = useAwaitFullScreenConfirmCancelModal();
  const [busyMsg, setBusyMsg] = useState<string | undefined>(undefined);

  const handleTTSOptionsChange = useLiveCallback(
    async (value: ModelsTTSLabeledRenderSettings[]) => {
      const defaultTTSSettings = value[0];
      if (!defaultTTSSettings) return;
      await updateField('ttsOptions', value);
    }
  );

  const handleAddTTSScript = useLiveCallback(async (value: ModelsTTSScript) => {
    await updateField('ttsScripts', [
      ...(block.fields.ttsScripts ?? []),
      value,
    ]);
  });

  const handleRemoveTTSScript = useLiveCallback(
    async (value: ModelsTTSScript) => {
      const nextTTSScripts = (block.fields.ttsScripts ?? []).filter(
        (s) => s.id !== value.id
      );

      await updateField('ttsScripts', nextTTSScripts);
    }
  );

  const handleChangeTTSScript = useLiveCallback(
    async (prev: ModelsTTSScript, next: ModelsTTSScript) => {
      const nextTTSScripts = [...(block.fields.ttsScripts ?? [])];
      const index = nextTTSScripts.findIndex((s) => s.id === prev.id);
      if (index === -1) return;

      const hasChanged = !isEqual(prev, next);
      if (hasChanged) {
        // only update the field if there's a change.
        nextTTSScripts[index] = next;
        await updateField('ttsScripts', nextTTSScripts);
      }
    }
  );

  const handleUploadTTSScripts = useLiveCallback(
    async (nextTTSScripts: ModelsTTSScript[]) => {
      await updateField('ttsScripts', nextTTSScripts);
    }
  );

  const handleBoardSizeChange = useLiveCallback(async (value: GridSize) => {
    const { numRows: nextNumRows, numCols: nextNumCols } = value;
    if (
      nextNumRows < block.fields.boardSize.numRows ||
      nextNumCols < block.fields.boardSize.numCols
    ) {
      const resp = await triggerModal({
        kind: 'confirm-cancel',
        prompt: (
          <div className='p-5 text-white text-center'>
            <p className='text-2xl font-medium'>Are you sure?</p>
            <p className='mt-4 text-sms'>
              This action may remove existing categories and clues.
            </p>
          </div>
        ),
        confirmBtnLabel: 'Continue',
        cancelBtnLabel: 'Cancel',
      });
      if (resp.result === 'canceled') return;
    }

    // update the board
    const categories = block.fields.board.categories ?? [];
    const nextCategories = categories.slice(0, nextNumCols);
    for (let i = 0; i < nextNumCols; i++) {
      const category = categories[i] ?? { id: uuidv4(), name: '', clues: [] };

      const clues = category.clues.slice(0, nextNumRows - 1);
      for (let j = 0; j < nextNumRows - 1; j++) {
        clues[j] = category.clues[j] ?? {
          id: uuidv4(),
          question: '',
          answer: '',
          value: 0,
        };
      }
      nextCategories[i] = { ...category, clues };
    }

    await updateField('board', { categories: nextCategories });
    await updateField('boardSize', value);
  });

  return (
    <div className='w-full'>
      {busyMsg && (
        <div className='fixed inset-0 bg-black bg-opacity-80 flex items-center justify-center z-50'>
          <div className='flex flex-col items-center gap-2'>
            <Loading text='' />
            <p className='text-white'>{busyMsg}</p>
          </div>
        </div>
      )}
      <h2 className='text-2xl text-white mb-7'>Jeopardy!</h2>
      <JeopardyBoardEditor
        {...props}
        csvUtilsEnabled
        setBusyMsg={setBusyMsg}
        fieldUpdater={updateField}
      />
      <div className='pt-5 space-y-3'>
        <SimpleFieldEditor
          name='Board Size'
          description={
            <>
              Determines how many rows an columns are on the Jeopardy board.
              Each column will have a category and each row has a clue and
              answer.
            </>
          }
        >
          <BoardSizeEditor
            value={block.fields.boardSize}
            onChange={handleBoardSizeChange}
          />
        </SimpleFieldEditor>
        <SimpleFieldEditor
          name='Background Media'
          description={
            <>
              Set the immersive theme for your Jeopardy block by uploading a
              full-bleed image or video to serve as the game's background. The
              selected media will display and loop throughout the duration of
              the block.
            </>
          }
        >
          <BlockMediaEditor<JeopardyBlockMedia>
            blockId={props.block.id}
            title={<span />}
            field='backgroundMedia'
            video={true}
            scene={EnumsMediaScene.MediaSceneBlockMedia}
            volumeSelectable
            mediaData={block.fields.backgroundMediaData}
            media={block.fields.backgroundMedia}
            width='w-full'
            loopSelectable
          />
        </SimpleFieldEditor>
        <SimpleFieldEditor
          name='Outro Media'
          description={
            <>
              Upload an image or short video (MP4 or WEBM, max 100MB) that will
              play for all team members when all questions on the board have
              been answered, adding a celebratory moment to the game experience.
            </>
          }
        >
          <BlockMediaEditor<JeopardyBlockMedia>
            blockId={props.block.id}
            title={<span />}
            field='outroMedia'
            video={true}
            scene={EnumsMediaScene.MediaSceneBlockMedia}
            volumeSelectable
            mediaData={block.fields.outroMediaData}
            media={block.fields.outroMedia}
            width='w-full'
          />
        </SimpleFieldEditor>
      </div>
      <AdditionalSettings>
        <AdditionalSharedSettingsEditor {...props} />
        <SimpleFieldEditor
          name='Clue Selection Timer'
          description={
            <>
              Determines how long players have to select a clue. If time runs
              out and a selection has not been made, a clue is chosen randomly.
            </>
          }
        >
          <TimerInput
            value={block.fields.clueSelectionTimeSec}
            onChange={(value) => updateField('clueSelectionTimeSec', value)}
          />
        </SimpleFieldEditor>
        <SimpleFieldEditor
          name='Buzzer Timer'
          description={
            <>
              Determines how long players have to buzz in if they know the
              answer. If time runs out and no one has buzzed, the answer is
              displayed.
            </>
          }
        >
          <TimerInput
            value={block.fields.buzzerTimeSec}
            onChange={(value) => updateField('buzzerTimeSec', value)}
          />
        </SimpleFieldEditor>
        <SimpleFieldEditor
          name='Answer Prepare Timer'
          description={
            <>
              Determines how long we wait before the player on stage is asked to
              give an answer. This is a delay, primarily to allow time for the
              user on stage to prepare their answer, and to allow the system to
              handle changing channels, etc.
            </>
          }
        >
          <TimerInput
            value={block.fields.answerPrepareTimeSec}
            onChange={(value) => updateField('answerPrepareTimeSec', value)}
          />
        </SimpleFieldEditor>
        <SimpleFieldEditor
          name='Answer Timer'
          description={
            <>
              Determines how long the player on stage has to give an answer. If
              time runs out, the next player in the buzz queue is brought on
              stage. If no one is in the buzz queue the answer is displayed.
            </>
          }
        >
          <TimerInput
            value={block.fields.answerTimeSec}
            onChange={(value) => updateField('answerTimeSec', value)}
          />
        </SimpleFieldEditor>
        <SimpleFieldEditor
          name='Judging Timer'
          description={
            <>
              Determines how long the judging period lasts. When time runs out,
              the final judgement is made (majority wins). Non-votes count as
              “incorrect”.
            </>
          }
        >
          <TimerInput
            value={block.fields.judgingTimeSec}
            onChange={(value) => updateField('judgingTimeSec', value)}
          />
        </SimpleFieldEditor>
        <TTSScriptsEditor
          title='Host Scripts'
          description={
            <>
              There are various times in the experience where the host will
              speak instructions to the audience or on stage players. You can
              add additional options for these moments below.
            </>
          }
          value={block.fields.ttsScripts}
          scenarioOptions={hostScriptScenarioOptions}
          onAdd={handleAddTTSScript}
          onRemove={handleRemoveTTSScript}
          onChange={handleChangeTTSScript}
          onUpload={handleUploadTTSScripts}
          ttsRenderSettings={block.fields.ttsOptions?.[0]}
          csvDownloadName={`jeopardy-host-scripts-${props.block.id}.csv`}
          getTagLabel={getTagLabel}
        />
        <TTSOptionEditor
          value={block.fields.ttsOptions}
          onChange={handleTTSOptionsChange}
        />
      </AdditionalSettings>
    </div>
  );
}

export function JeopardyBlockPreview(props: {
  board: BlockFields<JeopardyBlock>['board'];
  boardSize: BlockFields<JeopardyBlock>['boardSize'];
  onClickCell: (cell: Clue | Category) => void;
  onUpdateBoard: (board: BlockFields<JeopardyBlock>['board']) => void;
  rightAccessory?: ReactNode;
  dirtyMap?: BoardDirtyMap;
}) {
  const { board, boardSize, onClickCell, onUpdateBoard, dirtyMap } = props;

  const adaptedBoard = useMemo(() => {
    return JeopardyUtils.AdaptBoardTypes(board, boardSize);
  }, [board, boardSize]);

  const [view, setView] = useState<'clues' | 'answers' | 'points'>('clues');
  const handleViewChange = (newView: 'clues' | 'answers' | 'points') => {
    setView(newView);
  };

  const viewDirtyState = useMemo(() => {
    const items = Object.values(adaptedBoard.items);
    const state: { [property in BoardView]: boolean } = {
      clues: false,
      answers: false,
      points: false,
    };
    for (const item of items) {
      switch (item?.type) {
        case 'category':
          if (dirtyMap?.isCategoryDirty(item.id)) state.clues = true;
          break;
        case 'clue':
          if (dirtyMap?.isClueDirty(item.id, 'clues')) state.clues = true;
          if (dirtyMap?.isClueDirty(item.id, 'answers')) state.answers = true;
          if (dirtyMap?.isClueDirty(item.id, 'points')) state.points = true;
          break;

        default:
          break;
      }
    }
    return state;
  }, [adaptedBoard.items, dirtyMap]);

  return (
    <div className='w-full'>
      <div className='w-full flex items-center justify-between'>
        <div className='flex items-center gap-4 pb-2'>
          <span className='font-bold text-icon-gray'>View:</span>
          {['clues', 'answers', 'points'].map((v) => (
            <button
              key={v}
              type='button'
              className={`
                btn-secondary rounded-lg w-25 h-8 text-base transition-colors relative
                ${
                  view === v
                    ? 'text-white border border-white'
                    : 'text-icon-gray hover:text-white'
                }
              `}
              onClick={() => handleViewChange(v as typeof view)}
            >
              {capitalize(v)}
              {viewDirtyState[v as BoardView] && view !== v && (
                <div className='-top-3 -right-3 absolute'>
                  <AIRedIcon />
                </div>
              )}
            </button>
          ))}
          {view === 'points' && (
            <ChangeScoringButton board={board} onUpdateBoard={onUpdateBoard} />
          )}
        </div>
        {props.rightAccessory}
      </div>

      <div className='w-full'>
        <JeopardyBoard
          board={adaptedBoard}
          renderCell={(item) => (
            <CustomBoardCell
              key={item?.id}
              item={item}
              view={view}
              dirtyMap={dirtyMap}
              onClick={() => {
                if (item) onClickCell(item);
              }}
            />
          )}
        />
      </div>
    </div>
  );
}

function ChangeScoringButton(props: {
  board: BlockFields<JeopardyBlock>['board'];
  onUpdateBoard: (board: BlockFields<JeopardyBlock>['board']) => void;
}) {
  const [showActionSheet, setShowActionSheet] = useState(false);
  const { getTooltipProps, setTooltipRef, setTriggerRef, visible } =
    usePopperTooltip({
      trigger: 'click',
      placement: 'auto',
      visible: showActionSheet,
      onVisibleChange: setShowActionSheet,
    });

  const handleUpdateScoring = (multiplier = 1) => {
    if (!props.board) return;
    const copy = cloneDeep(props.board);
    if (!copy.categories) return;
    for (const category of copy.categories) {
      for (let i = 0; i < category.clues.length; i++) {
        category.clues[i].value = (i + 1) * 200 * multiplier;
      }
    }
    props.onUpdateBoard(copy);
    setShowActionSheet(false);
  };

  return (
    <div>
      <button
        className={`
          btn flex justify-center items-center gap-1
          text-icon-gray hover:text-white text-xs
          transition-colors
        `}
        ref={setTriggerRef}
        type='button'
      >
        Change Scoring
        <ArrowDownIcon className='w-3 h-3 fill-current' />
      </button>
      {visible && (
        <div
          ref={setTooltipRef}
          {...getTooltipProps({
            className: `
              w-auto h-auto max-h-full
              border border-[#303436] rounded-lg
              text-white flex flex-col p-1 z-50
              transition-opacity bg-black whitespace-nowrap
            `,
          })}
        >
          <div className='flex flex-col gap-1'>
            <ChangeScoringOption
              text='Single Jeopardy (200, 400, ...)'
              onClick={() => handleUpdateScoring(1)}
            />
            <ChangeScoringOption
              text='Double Jeopardy (400, 800, ...)'
              onClick={() => handleUpdateScoring(2)}
            />
          </div>
        </div>
      )}
    </div>
  );
}

function ChangeScoringOption(props: { text: string; onClick: () => void }) {
  const { text, onClick } = props;
  const handleClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    onClick();
  };
  return (
    <button
      className={`
        btn w-full h-8 px-2 hover:bg-dark-gray rounded-lg
        flex items-center gap-2 text-3xs
      `}
      onClick={handleClick}
      type='button'
    >
      {text}
    </button>
  );
}

function dirtyStyle(
  item: Clue | Category,
  view: 'clues' | 'answers' | 'points',
  dirtyMap?: BoardDirtyMap
) {
  const dirty =
    item.type === 'category'
      ? dirtyMap?.isCategoryDirty(item.id)
      : item.type === 'clue'
      ? dirtyMap?.isClueDirty(item.id, view)
      : false;
  return !!dirty ? 'bg-lp-green-003 bg-opacity-60' : '';
}

function CustomBoardCell(props: {
  item: Clue | Category | undefined;
  view: 'clues' | 'answers' | 'points';
  onClick: () => void;
  dirtyMap?: BoardDirtyMap;
}) {
  const { item, view, onClick, dirtyMap } = props;
  if (!item) {
    return <div className='bg-[#0B1887] h-25' />;
  }

  if (item.type === 'category') {
    return (
      <EditableCell onClick={onClick}>
        <span
          className={`uppercase font-bold 
            ${dirtyStyle(item, 'clues', dirtyMap)}`}
        >
          {item.category}
        </span>
      </EditableCell>
    );
  }

  if (item.type === 'clue') {
    if (view === 'points') {
      return (
        <EditableCell onClick={onClick}>
          <span
            className={`text-tertiary font-bold text-xl 
              ${dirtyStyle(item, view, dirtyMap)}`}
          >
            ${item.value}
          </span>
        </EditableCell>
      );
    } else if (view === 'answers') {
      return (
        <EditableCell onClick={onClick}>
          <div
            className={`w-full h-full p-1 flex items-center justify-center 
              ${dirtyStyle(item, view, dirtyMap)}`}
          >
            {item.answer}
          </div>
        </EditableCell>
      );
    } else {
      return (
        <EditableCell onClick={onClick}>
          <div
            className={`w-full h-full p-1 flex items-center justify-center 
              ${dirtyStyle(item, view, dirtyMap)}`}
          >
            {item.clue}
          </div>
        </EditableCell>
      );
    }
  }
}

function EditableCell(props: {
  onClick: () => void;
  children: React.ReactNode;
}) {
  const { onClick, children } = props;
  const { fontSize, ref } = useFitText({ logLevel: 'none' });
  return (
    <div
      ref={ref}
      className={`
        bg-[#0B1887] h-25 relative
        flex items-center justify-center text-center text-white p-1 cursor-pointer group
      `}
      onClick={onClick}
      style={{ fontSize }}
    >
      <div className='absolute inset-0 opacity-0 group-hover:opacity-100 transition-opacity flex items-center justify-center'>
        <div className='absolute inset-0 bg-black bg-opacity-50' />
        <div className='relative bg-black text-white text-sms font-bold rounded-full px-5 py-1'>
          Edit
        </div>
      </div>
      {children}
    </div>
  );
}
