export function isEqualSets(a: Set<unknown>, b: Set<unknown>) {
  if (a === b) return true;
  if (a.size !== b.size) return false;
  for (const value of a) if (!b.has(value)) return false;
  return true;
}
export function isSetContainedWithinSet(a: Set<unknown>, b: Set<unknown>) {
  if (a === b) return true;
  for (const value of a) if (!b.has(value)) return false;
  return true;
}
