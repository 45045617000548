import { useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import useSWR from 'swr';
import { useSnapshot } from 'valtio';

import {
  type DtoBlock,
  type DtoBlockPlayedSnapshot,
  type DtoBrand,
} from '@lp-lib/api-service-client/public';
import { RTDBServerValueTIMESTAMP } from '@lp-lib/firebase-typesafe';
import {
  aggregateAnswers,
  type AggregatedAnswer,
  type Block,
  type BlockDetailScore,
  BlockType,
  type CreativePromptBlockAnswerData,
  type GameSessionScoreSummary,
  type GameSessionStatus,
  type QuestionBlockAnswerData,
  type QuestionBlockDetailScore,
  type RapidBlockAnswerData,
  type RapidBlockAnswersData,
  ScoreboardMode,
  type TeamDataList,
  type TeamScoreboardData,
} from '@lp-lib/game';

import { useLiveCallback } from '../../../hooks/useLiveCallback';
import { useIsCoordinator } from '../../../hooks/useMyInstance';
import logger from '../../../logger/logger';
import { apiService } from '../../../services/api-service';
import { type GameScore } from '../../../services/api-service/gameScore.api';
import {
  type PairingGame,
  PairingGameUtils,
  SessionMode,
  type TeamV0,
} from '../../../types';
import { type Game, type GamePack } from '../../../types/game';
import { fromDTOGamePack } from '../../../utils/api-dto';
import { backoffWait } from '../../../utils/backoffWait';
import { nullOrUndefined } from '../../../utils/common';
import { type TeamFilterOptions } from '../../../utils/filterTeams';
import { TimeUtils } from '../../../utils/time';
import { uncheckedIndexAccess_UNSAFE } from '../../../utils/uncheckedIndexAccess_UNSAFE';
import { firebaseService } from '../../Firebase';
import { useMyOrgId } from '../../Organization';
import { usePairing } from '../../Pairing';
import { useMyTeamId } from '../../Player';
import { useIsStreamSessionAlive } from '../../Session';
import { useTeams, useTeamsGetter } from '../../TeamAPI/TeamV1';
import { type VideoMixer } from '../../VideoMixer';
import { type GamePlayMedia } from '../Blocks/Common/GamePlay/Internal';
import {
  useSubmissionStatusAllSubmitted,
  useSubmissionStatusWaitEnder,
} from '../Blocks/Common/GamePlay/SubmissionStatusProvider';
import { rankTeamScoreboardData } from '../Blocks/Common/GamePlay/utils';
import { BlockKnifeUtils } from '../Blocks/Shared';
import { useLocalLoadedBlocks } from '../GamePlayStore';
import {
  type GameSessionOndWaitModeDerivedData,
  OndPhaseContext,
  ondWaitEnd,
} from '../OndPhaseRunner';
import { type OndPlaybackJump } from '../OndPhaseRunner/OndPhaseRunnerTypes';
import { type PlaybackDesc } from '../Playback/intoPlayback';
import {
  type BlockSessionData,
  countdown,
  countdownV2,
  type FirebaseRefs,
  type GamePlayState,
  type GameSession,
  type GameSessionStore,
  gameSessionStore,
  type OndGamePlayState,
  pauseOndSubmissionTimer,
  refreshBlock,
  type SessionStatusHookManager,
  type VideoPlayback,
} from '../store';
import { type GameSessionActionsSignalManager } from '../store/GameSessionActionsSignalManager';

export const useAggregatedAnswers = (): AggregatedAnswer[] => {
  const store = useSnapshot(gameSessionStore);

  const block = store.session.blockSession?.block ?? null;
  const detailScores = store.detailScores;

  return useMemo(() => {
    const answerList: AggregatedAnswer[] = [];
    if (
      block &&
      block.id &&
      block.type === BlockType.QUESTION &&
      detailScores
    ) {
      const blockScoreData = detailScores[
        block.id
      ] as TeamDataList<QuestionBlockDetailScore>;

      const allAggregatedAnswers = [
        ...Object.entries(aggregateAnswers(blockScoreData, true)),
        ...Object.entries(aggregateAnswers(blockScoreData, false)),
      ];

      allAggregatedAnswers.forEach(([answer, data]) => {
        answerList.push({
          ...data,
          answer,
        });
      });

      answerList.sort((a, b) => (a.submittedAt || 0) - (b.submittedAt || 0));
    }
    return answerList;
  }, [block, detailScores]);
};

function deriveAssociatedGamePackIds(
  mainGamePackId: string | null,
  pairing: PairingGame | null
) {
  // In Pairs game, there are multiple shadow game packs in a round, a team will play them one by one.
  // The goal here is to let the team see the history of all game packs in every game pack.
  if (!pairing) return mainGamePackId;
  if (pairing.asLevels) return mainGamePackId;

  const allPairingGamePackIds = PairingGameUtils.GetAllGamePackIds(pairing);
  if (mainGamePackId && allPairingGamePackIds.includes(mainGamePackId)) {
    return allPairingGamePackIds.join(',');
  }

  return mainGamePackId;
}

export function useAssociatedGamePackIds(
  mainGamePackId: string | null
): string | null {
  const pairing = usePairing();
  const gamePackIds = useMemo(() => {
    return deriveAssociatedGamePackIds(mainGamePackId, pairing);
  }, [mainGamePackId, pairing]);
  return gamePackIds;
}

const scoreboardTeamsQuery: TeamFilterOptions = {
  updateStaffTeam: true,
  excludeStaffTeam: true,
};

export const useScoreboardData = (
  mode: ScoreboardMode | null
): TeamScoreboardData[] => {
  const scoreSummary = useSnapshot(gameSessionStore).scoreSummary;
  const teams = useTeams(scoreboardTeamsQuery);
  const organizationId = useMyOrgId();
  const [historyScores, setHistoryScores] = useState<GameScore[]>([]);
  const [inited, setInited] = useState(true);
  const gameSessionGamePackId = useGameSessionGamePackId();
  const gamePackIds = useAssociatedGamePackIds(gameSessionGamePackId);

  useEffect(() => {
    async function init() {
      const historicals = await retrieveHistoricalGameScores(
        mode,
        organizationId,
        gamePackIds
      );
      if (historicals) setHistoryScores(historicals);
      setInited(true);
    }
    init();
  }, [gamePackIds, mode, organizationId]);

  return useMemo(() => {
    if (!inited) {
      return [];
    } else {
      return computeScoreboardData(historyScores, scoreSummary, teams);
    }
  }, [scoreSummary, inited, historyScores, teams]);
};

export function useScoreboardDataGetter() {
  const getTeams = useTeamsGetter();
  const pairing = usePairing();
  const gameSessionGamePackId = useGameSessionGamePackId();
  const orgId = useMyOrgId();
  return useLiveCallback(async (mode: ScoreboardMode | null) => {
    const associatedGamePackIds = deriveAssociatedGamePackIds(
      gameSessionGamePackId,
      pairing
    );
    const historicals = await retrieveHistoricalGameScores(
      mode,
      orgId,
      associatedGamePackIds
    );
    const teams = getTeams(scoreboardTeamsQuery);
    const scoreSummary = gameSessionStore.scoreSummary;
    return computeScoreboardData(historicals ?? [], scoreSummary, teams);
  });
}

async function retrieveHistoricalGameScores(
  mode: ScoreboardMode | null,
  orgId: string | null,
  associatedGamePackIds: string | null
) {
  if (mode && mode !== ScoreboardMode.VenueTeams && associatedGamePackIds) {
    try {
      const resp = await apiService.gameScore.searchGameScore(
        associatedGamePackIds,
        {
          size: 999,
          organizationId:
            mode === ScoreboardMode.OrgTeams ? orgId ?? '' : undefined,
        }
      );
      return resp.data.gameScores;
    } catch (error) {
      logger.error('search game scores failed', error);
    }
  }

  return null;
}

function computeScoreboardData(
  historyScores: GameScore[],
  scoreSummary: TeamDataList<GameSessionScoreSummary> | null,
  teams: ReturnType<typeof useTeams>
) {
  const scoreMap: Map<string, TeamScoreboardData> = new Map();

  if (historyScores) {
    historyScores.forEach((gameScore) => {
      const scoreData: TeamScoreboardData = {
        score: gameScore.score,
        currentScore: null,
        teamId: gameScore.teamId,
        teamName: gameScore.teamName,
        orgName: gameScore.organizationName,
        orgLogo: gameScore.organizationLogo,
        fullNames: gameScore.fullNames,
        shortNames: gameScore.shortNames,
        createdTimestamp: Date.parse(gameScore.createdAt) || 0,
        history: true,
      };
      if (!scoreMap.has(gameScore.teamId)) {
        scoreMap.set(gameScore.teamId, scoreData);
      } else {
        if ((scoreMap.get(gameScore.teamId)?.score ?? 0) < gameScore.score) {
          scoreMap.set(gameScore.teamId, scoreData);
        }
      }
    });
  }

  const teamsById: Record<string, TeamV0> = {};
  for (const team of teams) {
    teamsById[team.id] = team;
  }

  if (scoreSummary) {
    Object.keys(scoreSummary).forEach((teamId) => {
      const scoresData = scoreSummary[teamId];
      const totalScore = scoresData.totalScore;
      const team = teamsById[teamId];
      const timestampNow = Date.now();

      if (scoresData && totalScore !== null && team) {
        scoreMap.set(teamId, {
          score: totalScore,
          currentScore: totalScore - scoresData.prevScore || 0,
          teamId,
          teamName: team.name ?? '',
          createdTimestamp: timestampNow,
          history: false,
        });
      }
    });
  }

  const results: TeamScoreboardData[] = Array.from(scoreMap.values());
  rankTeamScoreboardData(results);

  return results;
}

export const useTotalScoreMap = (): Record<string, number> => {
  const scoreSummary = useSnapshot(gameSessionStore).scoreSummary;

  return useMemo(() => {
    const mp: Record<string, number> = {};

    if (!scoreSummary) return mp;
    Object.entries(scoreSummary).forEach(([teamId, scoreSummary]) => {
      if (!teamId || !scoreSummary) {
        return;
      }
      mp[teamId] = scoreSummary.totalScore as number;
    });

    return mp;
  }, [scoreSummary]);
};

export const useTeamScore = (
  teamId: string | null,
  excludeCurrentBlock?: boolean
): number | null => {
  const scoreSummary = useSnapshot(gameSessionStore).scoreSummary;
  if (!scoreSummary || !teamId) return null;

  const teamScore = scoreSummary[teamId];
  if (!teamScore) return null;

  let score = teamScore.totalScore;

  if (excludeCurrentBlock) {
    score = score - (teamScore.currentBlockScore || 0);
  }

  return score;
};

export const useCurrentBlockScore = (teamId: string | null): number | null => {
  const scoreSummary = useSnapshot(gameSessionStore).scoreSummary;
  if (!scoreSummary || !teamId) return null;

  return scoreSummary[teamId]?.currentBlockScore ?? null;
};

export const useGameSessionVenueId = (): string | null => {
  const store = useSnapshot(gameSessionStore);
  return store.venueId;
};

export const useGameSessionName = (): string | null => {
  const session = useSnapshot(gameSessionStore).session;
  return session.name;
};

export const useGameSessionGamePackId = (): string | null => {
  const session = useSnapshot(gameSessionStore).session;
  return session.gamePackId;
};

export const useFetchGameById = (
  gameId: string | undefined | null,
  setGame: (g: Game | null) => void
): void => {
  useMemo(async () => {
    if (!gameId) {
      setGame(null);
      return;
    }
    const game = (await apiService.game.getGameById(gameId)).data.game;
    setGame(game);
  }, [gameId, setGame]);
};

export function useFetchGameSessionGamePackPlus(
  includePlus: boolean,
  playHistoryTargetId: string | null
): {
  gamePack: GamePack | null;
  blockPlayedHistory?: Record<string, DtoBlockPlayedSnapshot[]>;
  blocks?: DtoBlock[];
  brands?: DtoBrand[];
  mutate: () => void;
} | null {
  const gamePackId = useGameSessionGamePackId();
  const key = gamePackId
    ? ([gamePackId, playHistoryTargetId, includePlus] as const)
    : null;
  const { data, mutate } = useSWR(
    key,
    async ([id, phtId, incPlus]) =>
      (
        await apiService.gamePack.getGamePackById(id, {
          brands: incPlus,
          blocks: incPlus,
          playHistory: phtId ?? undefined,
        })
      ).data,
    { revalidateOnFocus: false }
  );

  return useMemo(
    () =>
      data
        ? { ...data, gamePack: fromDTOGamePack(data?.gamePack ?? null), mutate }
        : null,
    [data, mutate]
  );
}

export function useFetchGameSessionGamePack() {
  return useFetchGameSessionGamePackPlus(false, null)?.gamePack ?? null;
}

export const useRefreshGameSessionBlock = (block: Block): void => {
  useMemo(async () => {
    await refreshBlock(block);
  }, [block]);
};

export const useIsEndedBlock = (blockId: string | null): boolean => {
  const controls = useSnapshot(gameSessionStore).controls;
  if (!blockId) return false;
  return new Set((controls.endedBlockIds || '').split(',') || []).has(blockId);
};

export const useBlockTitleAnimInfo =
  (): GameSession['blockTitleTransition'] => {
    return useSnapshot(gameSessionStore).session.blockTitleTransition ?? null;
  };

export const useAllTeamsFinishedAnimInfo =
  (): GameSession['allTeamsFinishedTransition'] => {
    return (
      useSnapshot(gameSessionStore).session.allTeamsFinishedTransition ?? null
    );
  };

export const useGameSessionStatus = <
  T extends GameSessionStatus = GameSessionStatus
>(): Nullable<T> => {
  const session = useSnapshot(gameSessionStore).session;
  return session.status as Nullable<T>;
};

export const useGameSessionStatusChangedAt = (): number => {
  const session = useSnapshot(gameSessionStore).session;
  return session.statusChangedAt;
};

export const useGameSessionBlock = (): Block | null => {
  const session = (useSnapshot(gameSessionStore) as typeof gameSessionStore)
    .session;
  return session.blockSession?.block ?? null;
};

export const useGameSessionBlockGetter = () => {
  return useLiveCallback(() => gameSessionStore.session.blockSession?.block);
};

export const useGameSessionBlockId = (): string | null => {
  const session = useSnapshot(gameSessionStore).session;
  return session.blockSession?.block?.id ?? null;
};

export const useBlockSessionData = (): BlockSessionData | null => {
  const session = useSnapshot(gameSessionStore).session;
  return session.blockSession?.data ?? null;
};

export const useGameSessionLocalTimer = (): number | null => {
  const timers = useSnapshot(gameSessionStore).timers;
  return timers?.submission;
};

export const usePlayedBlockIds = (): string[] => {
  const gameSession = useSnapshot(gameSessionStore);
  return Object.keys(gameSession.detailScores || {});
};

export const useGameVideoPlaybackState = (): VideoPlayback | null => {
  const session = useSnapshot(gameSessionStore).session;
  return session.blockSession?.videoPlayback ?? null;
};

/**
 * This value is only `true` once the ref has been received from the server
 * (firebase). This allows the consumer to differentiate (or wait to decide)
 * between the default ref values or purposefully set.
 */
export const useHasReceivedInitial = (key: keyof FirebaseRefs) => {
  return useSnapshot(gameSessionStore).refsInitialReceived[key];
};

export const useIsLiveGamePlay = (): boolean => {
  return useSnapshot(gameSessionStore).session.isLive;
};

export function useCurrentSessionMode() {
  const isLive = useIsLiveGamePlay();
  const recv = useHasReceivedInitial('session');
  return !recv ? null : isLive ? SessionMode.Live : SessionMode.OnDemand;
}

/**
 * This value is only `true` once the session has been received from the server.
 * NOTE that any hook that includes this value now returns at an additional
 * value of `null`. It means that `null` is an explicit state of UNKNOWN that
 * must be handled.
 */
export function useReceivedIsLiveGamePlay() {
  const val = useSnapshot(gameSessionStore).session.isLive;
  const recv = useHasReceivedInitial('session');
  return recv ? val : null;
}

// NOTE(drew): this utility likely does not sync properly from the
// BlockRecordingProvider and should be replaced with a more consistent way of
// referencing Recording status safely from within gameplay components. This
// value appears to be written/synced using Firebase to all clients. Gameplay
// components should not need this value synced!
export const useIsRecording = (): boolean => {
  const session = useSnapshot(gameSessionStore).session;
  return session.isRecording;
};

export const useOndGameCurrentPlaybackItemId = () => {
  const ondState = useSnapshot(gameSessionStore).ondState;
  return ondState?.currentPlaybackItemId;
};

export const useGetOndGameCurrentPlaybackItem = () => {
  return useLiveCallback(() => {
    const currentPlaybackItemId =
      gameSessionStore.ondState?.currentPlaybackItemId;
    const playbackDesc = gameSessionStore.ondState?.preparedPlayback;
    if (!currentPlaybackItemId || !playbackDesc) return null;
    return (
      playbackDesc.items.find((i) => i.id === currentPlaybackItemId) ?? null
    );
  });
};

export const useOndGameCurrentPlaybackItem = () => {
  const { preparedPlayback, currentPlaybackItemId } =
    useSnapshot(gameSessionStore).ondState ?? {};

  return useMemo(() => {
    if (!currentPlaybackItemId || !preparedPlayback) return null;
    return (
      preparedPlayback.items.find((i) => i.id === currentPlaybackItemId) ?? null
    );
  }, [preparedPlayback, currentPlaybackItemId]);
};

export const useOndGameNextPlaybackItem = () => {
  const { preparedPlayback, currentPlaybackItemId, jump } =
    useSnapshot(gameSessionStore).ondState ?? {};

  return useMemo(() => {
    if (!preparedPlayback) return null;

    return OndPhaseContext.GetFollowingPlaybackItem(
      preparedPlayback as PlaybackDesc,
      currentPlaybackItemId,
      jump
    );
  }, [preparedPlayback, currentPlaybackItemId, jump]);
};

export const useGetOndGameResumePlaybackItemId = () => {
  return useLiveCallback(() => gameSessionStore.ondState?.resumePlaybackItemId);
};

export const useOndGameConfiguredJump = (): OndPlaybackJump | null => {
  const ondState = useSnapshot(gameSessionStore).ondState;
  return ondState?.jump ?? null;
};

export const useGetOndGameConfiguredJump = () => {
  return useLiveCallback(() => gameSessionStore.ondState?.jump ?? null);
};

export const useOndGameState = (): OndGamePlayState | null => {
  const ondState = useSnapshot(gameSessionStore).ondState;
  return ondState?.state ?? null;
};

export function useOndGameProgress(): number {
  const ondState = useSnapshot(gameSessionStore).ondState;
  return ondState?.sessionProgressSec ?? 0;
}

export function useOndFormattedGameProgress(): string {
  const ondState = useSnapshot(gameSessionStore).ondState;
  return TimeUtils.DurationFormattedHHMMSS(
    (ondState?.sessionProgressSec ?? 0) * 1000
  );
}

export const useOndGameVideoProgress = (): number | null => {
  const ondState = useSnapshot(gameSessionStore).ondState;
  return ondState?.gamePlayVideoProgress ?? null;
};

export function useOndPreparedContextReady(): boolean {
  const ondState = useSnapshot(gameSessionStore).ondState;
  return ondState?.preparedContextReady ?? false;
}

export const useOndStateGameBlock = (): {
  blockEndingSec?: number;
  blockProgressSec?: number;
  blockRemainderMs?: number;
} => {
  const ondState = useSnapshot(gameSessionStore).ondState;

  const { blockEndingSec, blockProgressSec, blockRemainderMs } = ondState ?? {};

  return {
    blockEndingSec,
    blockProgressSec,
    blockRemainderMs,
  };
};

export function useOndStateBlockBrandName() {
  const ondState = useSnapshot(gameSessionStore).ondState;
  return ondState?.blockBrandName;
}

export function useOndStateBlockHasHostedTutorial() {
  const ondState = useSnapshot(gameSessionStore).ondState;
  return ondState?.blockBrandHasHostedTutorial ?? false;
}

export const useVideoMixerOutputStream = (): MediaStream | null => {
  const videoMixer = useSnapshot(gameSessionStore).videoMixers.ondHostVideo;
  const [stream, setStream] = useState<MediaStream | null>(null);

  useEffect(() => {
    setStream((s) => {
      const next = videoMixer?.getOutputMediaStream() ?? null;
      return s === next ? s : next;
    });
  }, [videoMixer]);

  return stream;
};

export function useOndVideoMixer_UNSTABLE(): VideoMixer | null | undefined {
  // Unclear if valtio will at least track that the property was read or not
  // when using `ref()`. So this useSnapshot may be unnecessary.
  const videoMixer = useSnapshot(gameSessionStore).videoMixers
    .ondHostVideo as VideoMixer | null;
  return videoMixer;
}

export const usePauseOndSubmissionTimer = async (): Promise<void> => {
  const [isToResume, setIsToResume] = useState(false);
  const ondState = useSnapshot(gameSessionStore).ondState?.state;

  useMemo(async () => {
    if (!ondState) return;

    if (ondState === 'paused') {
      await pauseOndSubmissionTimer();
      setIsToResume(true);
    } else if (ondState === 'running' && isToResume) {
      gameSessionStore.isController
        ? await countdownV2({
            debug: 'usePauseOndSubmissionTimer',
            startTimeWorker: true,
          })
        : await countdown({ debug: 'usePauseOndSubmissionTimer' });
      setIsToResume(false);
    }
  }, [ondState, isToResume]);
};

export const useOndGameResumingProgress = (): number => {
  const ondState = useSnapshot(gameSessionStore).ondState;
  return ondState?.resumingProgress ?? 0;
};

export const useOndPlaybackVersion = (): number | null | undefined => {
  const ondState = useSnapshot(gameSessionStore).ondState;
  return ondState?.playbackVersion;
};

export const useVideoReplayAt = (): number | null => {
  const session = useSnapshot(gameSessionStore).session;
  return session.blockSession?.videoReplayAt ?? null;
};

// TODO: split the logic, move them to the block level hooks
export const useAudienceTeamData = ():
  | QuestionBlockAnswerData
  | RapidBlockAnswerData
  | CreativePromptBlockAnswerData
  | null => {
  const { blockSession } = useSnapshot(gameSessionStore).session;
  const teamData = useSnapshot(gameSessionStore).teamData;

  return useMemo(() => {
    if (!teamData?.data || !blockSession?.block?.type) return null;

    const blockType = blockSession.block.type;
    switch (blockType) {
      case BlockType.QUESTION:
        return teamData.data as QuestionBlockAnswerData;
      case BlockType.MULTIPLE_CHOICE:
        // Note(falcon): multiple uses the same answer data structure.
        return teamData.data as QuestionBlockAnswerData;
      case BlockType.CREATIVE_PROMPT:
        return teamData.data as CreativePromptBlockAnswerData;
      case BlockType.RAPID:
        const rapidAnswerData = teamData.data as RapidBlockAnswersData;

        if (Object.keys(rapidAnswerData).length === 0) return null;

        return Object.values(rapidAnswerData).reduce((prev, cur) => {
          return (prev?.submittedAt || 0) < (cur?.submittedAt || 0)
            ? cur
            : prev;
        });
      default:
        // TODO: assertExhaustive
        return null;
    }
  }, [blockSession?.block?.type, teamData?.data]);
};

export const useSubscribeGameSessionTeamData = (): void => {
  const venueId = useGameSessionVenueId();
  const blockId = useGameSessionBlockId();
  const myTeamId = useMyTeamId();

  useEffect(() => {
    if (!blockId || !myTeamId || !venueId) return;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const ref = firebaseService.prefixedRef<any>(
      `game-session-team-data/${venueId}/${blockId}/${myTeamId}`
    );

    ref
      .get()
      .then((snap) => (gameSessionStore.teamData = snap.val()))
      .catch();
    ref.on('value', (snap) => (gameSessionStore.teamData = snap.val()));

    gameSessionStore.refs.teamData = ref;

    return () => {
      if (ref) {
        ref.off('value');
      }
      gameSessionStore.teamData = null;
    };
  }, [blockId, myTeamId, venueId]);
};

export function useScoreSummary(): TeamDataList<GameSessionScoreSummary> | null {
  // NOTE(drew): it appears that scoreSummary is directly set by a firebase ref
  // update, generally. This means that when the block ends or game is reset,
  // this will be nulled out via gameSessionActions/core#end().
  return useSnapshot(gameSessionStore).scoreSummary;
}

export function useDetailScores<T extends BlockDetailScore = BlockDetailScore>(
  blockId: string | null
): TeamDataList<T> | null {
  const map = useSnapshot(gameSessionStore).detailScores;
  if (!map || !blockId) return null;
  return (map[blockId] as TeamDataList<T>) ?? null;
}

export function useOverrideDetailScores(blockId: string): {
  before: TeamDataList<BlockDetailScore>;
  after: TeamDataList<BlockDetailScore>;
} {
  const detailScores = useDetailScores(blockId);
  return useMemo(() => {
    const blockDetailScores = detailScores || {};
    const before = uncheckedIndexAccess_UNSAFE({});
    const after = uncheckedIndexAccess_UNSAFE({});
    Object.keys(blockDetailScores).forEach((teamId) => {
      const scores = blockDetailScores[teamId];
      const { previewPoints, scoreOverride } = scores;
      before[teamId] = { ...scores };
      after[teamId] = { ...scores };
      after[teamId].score =
        (!nullOrUndefined(scoreOverride) ? scoreOverride : previewPoints) ?? 0;
      after[teamId].scoreOverride = null;
      after[teamId].scoredAt = RTDBServerValueTIMESTAMP;
    });
    return { before, after };
  }, [detailScores]);
}

export function useGameSessionFBRef<T extends keyof FirebaseRefs>(
  key: T
): FirebaseRefs[T] {
  return gameSessionStore.refs[key];
}

/**
 * useGamePlayState eliminates the context of whether it's a live game or on-demand game
 * @returns
 */
export const useGamePlayState = (): GamePlayState | null => {
  const isLiveGame = useIsLiveGamePlay();
  const ondState = useSnapshot(gameSessionStore).ondState;
  if (isLiveGame) {
    // there is no clear stage of when the live game is started/running/ended
    return null;
  }
  return ondState?.state ?? null;
};

export const useIsGamePlayPaused = (): boolean => {
  const state = useGamePlayState();
  return state === 'paused' || state === 'resuming';
};

/**
 * These hooks only fire on the Controller! Not the coordinator or audience.
 */
export function useSessionStatusHookManager(): SessionStatusHookManager {
  return gameSessionStore.sessionStatusHookManager;
}

/**
 * Will fire on the coordinator and the controller, although not all actions are
 * mirrored in all coordinators, such as `reset-block` being controller-only
 * since there is no `reset-block` coordinator command.
 */
export function useGameSessionActionsSignalManager(): GameSessionActionsSignalManager {
  return gameSessionStore.gameSessionActionsSignalManager;
}

export const useIsGameSessionInited = (): boolean => {
  return useSnapshot(gameSessionStore).initialized;
};

export const useIsGameSessionController = (): boolean => {
  return useSnapshot(gameSessionStore).isController;
};

export function useIsHotSeatBlock(): boolean {
  const gameSessionBlock = useGameSessionBlock();
  return gameSessionBlock?.type === BlockType.ROUND_ROBIN_QUESTION;
}

export function useAllowEveryoneSubmits(): boolean {
  const isSessionAlive = useIsStreamSessionAlive();
  const gameSessionBlock = useGameSessionBlock();
  const isRapidBlock = gameSessionBlock?.type === BlockType.RAPID;
  return (
    isSessionAlive && isRapidBlock && gameSessionBlock.fields.everyoneSubmits
  );
}

export function useIsGamePlayBlocksCompleted(): boolean {
  const mode = useCurrentSessionMode();
  const loadedBlocks = useLocalLoadedBlocks(mode);
  const gameSessionBlockId = useGameSessionBlockId();

  return useMemo(() => {
    const index = loadedBlocks.findIndex(
      (block) => block.id === gameSessionBlockId
    );
    if (index < 0) return false;
    return loadedBlocks
      .slice(index + 1)
      .every((block) => !BlockKnifeUtils.QualifiesAsGameplay(block));
  }, [gameSessionBlockId, loadedBlocks]);
}

/**
 * If readyForSkip is false, the other data present is placeholder and should
 * not be considered.
 */
export function useOndWaitModeInfo(): GameSessionOndWaitModeDerivedData {
  const ond = useSnapshot(gameSessionStore).ondState;
  // Checking for mode !== 'wait' to provide instant feedback. The
  // OndPhaseRunner is on a 1hz tick, which means waitModeInfo will only change
  // somewhere between 0-1000ms from when the mode is told to change.
  if (!ond?.waitModeInfo || ond.waitModeExtSignal?.mode !== 'wait')
    return {
      readyForSkip: false,
      userSkippableMaxDurationSec: 0,
      remainingSec: Infinity,
      lastWaitBeforeEnd: false,
    };
  return ond.waitModeInfo.derived;
}

export function useOndWaitMode(): Nullable<'wait' | 'resume'> {
  const ond = useSnapshot(gameSessionStore).ondState;
  return ond?.waitModeExtSignal?.mode;
}

/**
 * A non-null prepared context is only ever present on the controller.
 */
export function useGetOndPreparedContext(): () => OndPhaseContext | null {
  return useLiveCallback(() => gameSessionStore.ondPreparedContext);
}

function getOndPreparedPlayback(store: GameSessionStore) {
  return store.ondState?.preparedPlayback ?? null;
}

export function useOndPreparedPlayback(): null | PlaybackDesc {
  return getOndPreparedPlayback(
    useSnapshot(gameSessionStore) as GameSessionStore
  );
}

export function useGetOndPreparedPlayback(): () => PlaybackDesc | null {
  return useLiveCallback(() => getOndPreparedPlayback(gameSessionStore));
}

export async function waitForOndPreparedPlayback(
  log = logger.scoped('ond-game')
): Promise<PlaybackDesc> {
  return await backoffWait(
    () => {
      log.debug('attempt to read prepared playback');
      return getOndPreparedPlayback(gameSessionStore);
    },
    // Slightly less than 5000ms, which should be plenty of time for firebase to
    // transfer and the client to receive the playbackdesc.
    { label: 'OndPreparedPlayback', maxAttempts: 50, maxMs: 100 },
    // No randomness, we want this as fast as possible since it's local.
    { randImpl: () => 0 }
  );
}

// If the blocks turn on the _startVideoWithTimer_, we are not going to trigger
// the _ondWaitEnd_ (auto progress) because we want to play through the full
// intro video. That assumes the intro video is longer than game timer, and the
// most common use case is that we put the answer revealing at the end of the
// intro video.
// However, if the game timer is longer (or much longer) than intro video, we
// are going to have a problem that the video is fully played, and all teams
// have submitted answers, but the game is still waiting for the timer to run
// without auto progress.
// This hook checks all the required conditions and tracks if the intro video
// has ended. If so, when all teams submitted, it will trigger the _ondWaitEnd_
// to auto progress the playback.
export function useOndWaitEndWithIntroMediaProgressCheck(
  blockId: string,
  startIntroWithTimer: boolean,
  currGss: Nullable<GameSessionStatus>,
  targetGss: GameSessionStatus,
  gamePlayMedia: GamePlayMedia | null,
  onMediaEnded: () => void
) {
  const isCoordinator = useIsCoordinator();
  const isLive = useIsLiveGamePlay();
  const [introMediaEnded, setIntroMediaEnded] = useState(false);
  const hasTriggered = useRef(false);
  const allSubmitted = useSubmissionStatusAllSubmitted();
  useSubmissionStatusWaitEnder(() => void 0);

  useLayoutEffect(() => {
    return () => {
      hasTriggered.current = false;
      setIntroMediaEnded(false);
    };
  }, [blockId]);

  useLayoutEffect(() => {
    if (
      isLive ||
      !isCoordinator ||
      !allSubmitted ||
      hasTriggered.current ||
      currGss !== targetGss
    )
      return;

    logger.scoped('ond-game').info('ondWaitEnd w/ intro media progress check', {
      blockId,
      startIntroWithTimer,
      introMediaEnded,
    });

    if (startIntroWithTimer) {
      if (introMediaEnded) {
        hasTriggered.current = true;
        ondWaitEnd();
      }
    } else {
      hasTriggered.current = true;
      ondWaitEnd();
    }
  }, [
    allSubmitted,
    startIntroWithTimer,
    introMediaEnded,
    isCoordinator,
    isLive,
    currGss,
    targetGss,
    blockId,
  ]);

  return useLiveCallback(() => {
    if (gamePlayMedia?.stage === 'intro') {
      setIntroMediaEnded(true);
    }
    onMediaEnded();
  });
}
