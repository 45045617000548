import { type VirtualBackgroundEffectOptions } from 'agora-extension-virtual-background';
import AgoraRTC, { type ILocalVideoTrack } from 'agora-rtc-sdk-ng';

import { type Logger } from '@lp-lib/logger-base';

import { assertDefinedFatal, xDomainifyUrl } from '../../utils/common';
import {
  type IVirtualBackgroundProcessorPatched,
  type VirtualBackgroundExtensionPatched,
} from './types';

export type VirtualBackgroundEffects = Omit<
  VirtualBackgroundEffectOptions,
  'source'
> & {
  source?: string;
  type: 'color' | 'img' | 'video' | 'blur' | 'none';
};

// The test fails if we import the extension directly from the module.
// https://github.com/Narvii/lunapark/actions/runs/10160139767/job/28095902032
export async function initVirtualBackgroundExtension() {
  const VirtualBackgroundExtension = (
    await import('agora-extension-virtual-background')
  ).default;
  const extension =
    new VirtualBackgroundExtension() as VirtualBackgroundExtensionPatched;
  if (!extension.checkCompatibility()) {
    throw new Error('Virtual background extension is not supported');
  }
  AgoraRTC.registerExtensions([extension]);
  return extension;
}

export type VirtualBackgroundOptions = {
  enabled: boolean;
  effects?: VirtualBackgroundEffects | null;
};

export class VirtualBackgroundMixer {
  private state: {
    processor: IVirtualBackgroundProcessorPatched | null;
    error?: unknown;
    inited?: boolean;
    effects?: VirtualBackgroundEffects | null;
    track?: ILocalVideoTrack;
    enabled: boolean;
  };
  constructor(options: VirtualBackgroundOptions, readonly log: Logger) {
    this.state = {
      processor: null,
      inited: false,
      effects: options.effects,
      enabled: options.enabled,
    };
  }

  async init() {
    if (!this.state.enabled) return false;
    try {
      const extension = await initVirtualBackgroundExtension();
      const processor = extension.createProcessor();
      await processor.init();
      this.state.processor = processor;
      this.state.inited = true;
      this.log.info('Virtual background processor inited');
      return true;
    } catch (error) {
      this.log.error('Failed to init virtual background processor', error);
      this.state.error = error;
      return false;
    }
  }

  get enabled() {
    return this.state.enabled;
  }

  get available() {
    return !!this.state.inited;
  }

  async updateCameraTrack(track: MediaStreamTrack | null) {
    this.log.info('update camera track', {
      trackId: track?.id ?? null,
    });
    if (!track) return null;
    if (this.state.enabled && this.state.inited) {
      return await this.updateWithVirtualBackground(track);
    } else {
      return track;
    }
  }

  private async updateWithVirtualBackground(sourceTrack: MediaStreamTrack) {
    if (!this.state?.processor) return null;
    const processor = this.state.processor;
    processor.unpipe();
    this.disposeTrack();
    const track = AgoraRTC.createCustomVideoTrack({
      mediaStreamTrack: sourceTrack,
    });
    this.state.track = track;
    track.pipe(processor).pipe(track.processorDestination);
    await this.setVirtualBackgroundEffects(this.state.effects ?? null);
    const processedTrack = await processor.getProcessedTrack();
    if (!processedTrack) {
      this.log.warn('Failed to get processed track');
      return null;
    }
    this.log.info('add processed track to output stream', {
      track: { id: processedTrack.id, label: processedTrack.label },
    });
    return processedTrack;
  }

  async toggle(enabled: boolean) {
    if (!this.state?.processor) return;
    if (enabled) {
      await this.state.processor.enable();
    } else {
      await this.state.processor.disable();
    }
  }

  async setVirtualBackgroundEffects(effects: VirtualBackgroundEffects | null) {
    const processor = this.state.processor;
    if (!processor) return;
    if (!effects) {
      await processor.disable();
      return;
    }
    await processor.enable();
    if (effects.type === 'img') {
      assertDefinedFatal(effects.source);
      const image = document.createElement('img');
      image.crossOrigin = 'anonymous';
      image.onload = async () => {
        processor.setOptions({
          type: 'img',
          source: image,
        });
      };
      image.src = xDomainifyUrl(effects.source, 'virtual-background');
    } else if (effects.type === 'video') {
      const video = document.createElement('video');
      assertDefinedFatal(effects.source);
      video.crossOrigin = 'anonymous';
      video.autoplay = true;
      video.muted = true;
      video.preload = 'metadata';
      video.onloadedmetadata = async () => {
        processor.setOptions({
          type: 'video',
          source: video,
        });
      };
      video.src = xDomainifyUrl(effects.source, 'virtual-background');
    } else if (effects.type === 'blur') {
      processor.setOptions({
        type: 'blur',
        blurDegree: effects.blurDegree,
      });
    } else if (effects.type === 'none') {
      processor.setOptions({ type: 'none' });
    } else if (effects.type === 'color') {
      processor.setOptions({ type: 'color', color: effects.color });
    } else {
      this.log.warn('Unsupported virtual background effect', effects);
    }
    this.state.effects = effects;
  }

  async dispose() {
    if (this.state?.processor) {
      await this.state.processor.disable();
      this.state.processor.unpipe();
      await this.state.processor.release();
    }
    this.disposeTrack();
  }

  private disposeTrack() {
    if (this.state?.track) {
      this.state.track.unpipe();
      this.state.track.stop();
      this.state.track.close();
    }
  }
}
