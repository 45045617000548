/**
 * Always allow pushing a new item, but remove old items once the limit has been
 * reached.
 */
export class LengthLimitedList<T> {
  private storage: T[] = [];

  constructor(private readonly limit = 50) {}

  push(item: T): void {
    this.storage.push(item);

    if (this.storage.length > this.limit) {
      // if it has 105 entries
      // 0-104 are filled
      // toRemove = 5
      // 0, 1, 2, 3, 4 are removed
      // 104 - 5 = 99, which is < (MAX_HISTORY_LENGTH=100)
      const toRemove = this.storage.length - this.limit;
      this.storage.splice(0, toRemove);
    }
  }

  all(): T[] {
    return this.storage.slice(0);
  }

  empty(): void {
    this.storage.length = 0;
  }
}
