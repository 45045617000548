import { type SVGProps } from 'react';

export function TBDIcon(props: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 14 14'
      className={props.className || 'w-3 h-3.5 fill-current'}
    >
      <path d='M4.12424 3.5V4.90088H3.00352V10.5001H1.11623V4.90088H0V3.5H4.12424Z' />
      <path d='M4.5546 3.5H6.4374C7.03213 3.5 7.48191 3.54468 7.78675 3.63403C8.09457 3.72339 8.34262 3.90499 8.5309 4.17882C8.71918 4.44977 8.81332 4.88791 8.81332 5.49323C8.81332 5.90254 8.74608 6.1879 8.6116 6.34932C8.4801 6.51074 8.2186 6.63468 7.82709 6.72116C8.26343 6.81628 8.5593 6.97482 8.7147 7.19677C8.87011 7.41583 8.94781 7.75308 8.94781 8.20851V8.85707C8.94781 9.32979 8.89103 9.68001 8.77746 9.90773C8.66688 10.1354 8.48906 10.2911 8.244 10.3747C7.99894 10.4583 7.49686 10.5001 6.73776 10.5001H4.5546V3.5ZM6.44189 4.69767V6.2542C6.52258 6.25132 6.58534 6.24987 6.63017 6.24987C6.81546 6.24987 6.93351 6.20664 6.98431 6.12016C7.03512 6.03081 7.06052 5.77859 7.06052 5.36352C7.06052 5.14445 7.0396 4.99168 6.99776 4.9052C6.95592 4.81585 6.90063 4.75964 6.8319 4.73658C6.76615 4.71352 6.63614 4.70055 6.44189 4.69767ZM6.44189 7.34377V9.30241C6.70787 9.29376 6.87672 9.25341 6.94845 9.18134C7.02316 9.10928 7.06052 8.93201 7.06052 8.64953V7.99665C7.06052 7.69687 7.02765 7.51528 6.9619 7.45186C6.89615 7.38845 6.72281 7.35242 6.44189 7.34377Z' />
      <path d='M9.62921 3.5H11.0413C11.9528 3.5 12.5685 3.54035 12.8883 3.62106C13.211 3.70177 13.4561 3.83437 13.6234 4.01884C13.7908 4.20332 13.8954 4.40942 13.9372 4.63713C13.9791 4.86197 14 5.30587 14 5.96883V8.42037C14 9.04875 13.9686 9.46959 13.9059 9.68289C13.8461 9.89331 13.74 10.0591 13.5876 10.1801C13.4352 10.2983 13.2469 10.3819 13.0227 10.4309C12.7986 10.477 12.4609 10.5001 12.0096 10.5001H9.62921V3.5ZM11.5165 4.69767V9.30241C11.7885 9.30241 11.9558 9.25052 12.0186 9.14676C12.0813 9.0401 12.1127 8.7533 12.1127 8.28634V5.56673C12.1127 5.24966 12.1023 5.04644 12.0813 4.95709C12.0604 4.86773 12.0126 4.80288 11.9379 4.76252C11.8632 4.71928 11.7227 4.69767 11.5165 4.69767Z' />
    </svg>
  );
}
