import { assertExhaustive } from './common';

function pickAndRemoveRandomly<T>(arr: T[]): T {
  const idx = Math.floor(Math.random() * arr.length);
  const val = arr[idx];
  arr.splice(idx, 1);
  return val;
}

function pickAndRemoveSequentially<T>(arr: T[]): T {
  const ele = arr.shift();
  if (ele === undefined) throw new Error('unexpected empty array');
  return ele;
}

export class InfiniteGenerator<T> {
  private pool: T[];
  constructor(
    private source: T[],
    private pick: 'random' | 'sequential' = 'random'
  ) {
    this.pool = [];
    this.reset();
  }
  private reset(): void {
    this.pool = [...this.source];
  }
  generate(): T {
    if (this.pool.length === 0) {
      this.reset();
    }
    switch (this.pick) {
      case 'random':
        return pickAndRemoveRandomly(this.pool);
      case 'sequential':
        return pickAndRemoveSequentially(this.pool);
      default:
        assertExhaustive(this.pick);
        throw new Error('unreachable');
    }
  }
  empty(): boolean {
    return this.pool.length === 0;
  }
}
