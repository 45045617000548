import { ref } from 'valtio';

import { SignalManager } from '../../../utils/SignalManager';

// Feel free to add more if they map well to the Game Session lifecycles.
const GameSessionActions = ['reset', 'reset-block'] as const;
type GameSessionAction = (typeof GameSessionActions)[number];

/**
 * Notifies during various life cycle events during a game session, such as
 * `reset()`.
 */
export class GameSessionActionsSignalManager extends SignalManager<GameSessionAction> {
  static CreateVRefed(): GameSessionActionsSignalManager {
    return ref(
      new GameSessionActionsSignalManager(
        'game-session-actions',
        GameSessionActions
      )
    );
  }
}
