export function toUnitGrid(
  point: { x: number; y: number },
  fromCoordinateSystem: { maxX: number; maxY: number }
): { x: number; y: number } {
  return {
    x: point.x / fromCoordinateSystem.maxX,
    y: point.y / fromCoordinateSystem.maxY,
  };
}

export function fromUnitGrid(
  point: { x: number; y: number },
  toCoordinateSystem: { maxX: number; maxY: number }
): { x: number; y: number } {
  return {
    x: point.x * toCoordinateSystem.maxX,
    y: point.y * toCoordinateSystem.maxY,
  };
}
