import { type MessageTemplate } from '../../types/messageTemplate';

export type MessageVariableKeysRound =
  | 'billingAdminName' // organizationOwnerName
  | 'pairingPlayers'
  | 'organizationName'
  | 'gameURL'
  | 'round'
  | 'organizerName'
  | 'score'
  | 'winningPlayers'
  | 'secondPlacePlayers'
  | 'thirdPlacePlayers'
  | 'teamName'
  | 'ofTotalParticipants'
  | 'currentHighScore'
  | 'teamNameListWithEmojis'
  | 'leaderboardURL'
  | 'recipientName'
  | 'startDate'
  | 'endDate';

export type MessageVariableKeysJoinChannel =
  | 'organizationName'
  | 'playerDisplayName'
  | 'channelName'
  | 'loginLink';

export type MessageVariableKeysAddSlackApp = 'creatorDisplayName';

export type MessageVariableKeysDemoRound =
  | 'organizerName'
  | 'pairingPlayers'
  | 'gameURL';

export type MessageVariableKeysPromotion =
  | 'organizationName'
  | 'channelName'
  | 'recipientName';

export type MessageVariableKeys =
  | MessageVariableKeysRound
  | MessageVariableKeysJoinChannel
  | MessageVariableKeysAddSlackApp;

export type MessageVariableMapping = {
  [key in MessageVariableKeys]: string;
};

export const MOCK_MESSAGE_VARIABLES_ROUND: {
  [key in MessageVariableKeysRound]: string;
} = {
  billingAdminName: 'James',
  pairingPlayers: 'Ethan and Sarah',
  organizationName: 'Pets Paradise',
  gameURL: 'https://lunapark.com',
  round: 'April 2022',
  organizerName: 'John',
  score: '100',
  winningPlayers: 'Charles and Larry',
  secondPlacePlayers: 'Mike and Peter',
  thirdPlacePlayers: 'Georgia and lily',
  teamName: 'Blue Kangaroos',
  ofTotalParticipants: '32',
  currentHighScore: '500',
  teamNameListWithEmojis:
    ':monkey_face: Team Name 1\n:parrot: Team Name 2\n:rosette: Team Name 3',
  leaderboardURL: 'https://lunapark.com',
  recipientName: 'James, Mike and Peter',
  startDate: 'Monday, January 2',
  endDate: 'Monday, January 2',
};

export const MOCK_MESSAGE_VARIABLES_JOIN_CHANNEL: {
  [key in MessageVariableKeysJoinChannel]: string;
} = {
  organizationName: 'Pets Paradise',
  playerDisplayName: 'Stephen',
  channelName: '#lunapark-channel',
  loginLink: 'https://lunapark.com/user-join',
};

export const MOCK_MESSAGE_VARIABLES_ADD_APP: {
  [key in MessageVariableKeysAddSlackApp]: string;
} = {
  creatorDisplayName: 'Stephen',
};

export const MOCK_MESSAGE_VARIABLES_DEMO_ROUND: {
  [key in MessageVariableKeysDemoRound]: string;
} = {
  pairingPlayers: 'Ethan, Sarah',
  organizerName: 'John',
  gameURL: 'https://lunapark.com',
};

export const MOCK_MESSAGE_VARIABLES: MessageVariableMapping = {
  ...MOCK_MESSAGE_VARIABLES_ROUND,
  ...MOCK_MESSAGE_VARIABLES_JOIN_CHANNEL,
  ...MOCK_MESSAGE_VARIABLES_ADD_APP,
};

export const MOCK_MESSAGE_VARIABLES_PROMOTION: {
  [key in MessageVariableKeysPromotion]: string;
} = {
  organizationName: 'Pets Paradise',
  channelName: '#lunapark-channel',
  recipientName: 'James',
};

export const PREDEFINED_MESSAGE_TEMPLATES: MessageTemplate[] = [
  {
    id: '16332c35-1251-4dcf-88b2-9f696dcf9e09',
    title: 'Game URL',
    body: '%gameURL%',
    uid: '00000000-0000-0000-0000-000000000001',
    createdAt: '2022-06-16T13:21:21Z',
    updatedAt: '2022-06-16T13:21:21Z',
    withPlainText: true,
    media: null,
    mediaTemplateId: null,
    messagesSentCount: 0,
    recipientsCount: 0,
    reactionsCount: 0,
  },
];

export interface TranslatedMessage {
  title: string;
  body: string;
}
