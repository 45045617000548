import { useNavigate, useSearchParams } from '@remix-run/react';
import escapeRegExp from 'lodash/escapeRegExp';
import { Fragment, useEffect, useMemo, useState } from 'react';
import { Waypoint } from 'react-waypoint';

import {
  type DtoBlockDoc,
  type DtoBlockSearchResponse,
  type DtoBrand,
  type EnumsBlockSearchSortBy,
} from '@lp-lib/api-service-client/public';
import { type BlockType, fromAPIBlockType, type Media } from '@lp-lib/game';
import { fromAPIBlockTypes } from '@lp-lib/game/src/api-integration';

import placeholder from '../../assets/img/placeholder/game-cover.png';
import { useListLoader } from '../../hooks/useListLoader';
import { apiService, type BlockSearchParams } from '../../services/api-service';
import { getStaticAssetPath } from '../../utils/assets';
import { ImagePickPriorityLowToHigh, MediaUtils } from '../../utils/media';
import { BrandSelect } from '../Brand/BrandSelect';
import { BrandUtils } from '../Brand/utils';
import { BlockIcon } from '../Game/Blocks';
import { BlockKnifeUtils } from '../Game/Blocks/Shared';
import { ErrorMessage } from '../Game/GameCenter';
import { Loading } from '../Loading';
import { BlockActionSheet } from './BlockActionSheet';
import { BlockSortSelect } from './BlockSortSelect';
import { BlockTypeSelect } from './BlockTypeSelect';

export function BlockCover(props: {
  doc: {
    type: BlockType;
    coverMedia?: Media | null;
  };
  className?: string;
  imgClassName?: string;
}) {
  const { doc } = props;
  const src = useMemo(() => {
    switch (doc.type) {
      case 'scoreboard':
        return getStaticAssetPath('images/scoreboard-block-cover.png');
      case 'overRoasted':
        return getStaticAssetPath('images/over-roasted-cover.png');
      default:
        return MediaUtils.PickMediaUrl(doc.coverMedia, {
          videoThumbnail: 'first',
          priority: ImagePickPriorityLowToHigh,
        });
    }
  }, [doc.coverMedia, doc.type]);
  return (
    <div className={props.className}>
      <img
        src={src || placeholder}
        alt='cover'
        className={`object-cover bg-black border border-secondary rounded-lg flex-shrink-0 w-full h-full ${props.imgClassName}`}
      />
    </div>
  );
}

function HighlightedTitle(props: { title: string; q?: string }) {
  const { title, q } = props;
  const items = useMemo(() => {
    if (!q || !title) return [];
    const reg = new RegExp(escapeRegExp(q), 'ig');
    const matchArray = [...title.matchAll(reg)];
    const items: { className: string; text: string }[] = [];
    let pos = 0;
    for (const match of matchArray) {
      if (match.index === undefined) continue;
      items.push({
        text: title.slice(pos, match.index),
        className: 'text-white',
      });
      items.push({
        text: match[0],
        className: 'text-tertiary',
      });
      pos = match.index + match[0].length;
    }
    items.push({
      text: title.slice(pos),
      className: 'text-white',
    });
    return items;
  }, [q, title]);

  if (!q) return <span className='text-white'>{title}</span>;
  return (
    <>
      {items.map((item, index) => (
        <Fragment key={index}>
          <span className={item.className}>{item.text}</span>
        </Fragment>
      ))}
    </>
  );
}

export function BlockDocCard(props: {
  doc: DtoBlockDoc;
  q?: string;
  onClick?: (doc: DtoBlockDoc) => void;
  menu?: React.ReactNode;
  showSubtitle?: boolean;
}) {
  const { doc, onClick, menu } = props;
  const summary = useMemo(
    () => BlockKnifeUtils.Summary(fromAPIBlockTypes(doc.block), true),
    [doc]
  );
  const title =
    (doc.data?.parentTitle
      ? `${doc.data?.parentTitle}: ${summary.title}`
      : summary.title) ?? '';
  const subtitle = useMemo(() => {
    const items: string[] = [];
    if (summary.subtitle) items.push(summary.subtitle);
    items.push(`Game Packs it in: ${doc.data?.gamePacksCount ?? 0}`);
    if (doc.block.fields.internalLabel)
      items.push(doc.block.fields.internalLabel);
    return items.join(' | ');
  }, [doc, summary]);
  const clickable = !!onClick;
  return (
    <div
      className={`flex flex-row items-center justify-between hover:bg-lp-gray-002 rounded-xl p-2 ${
        clickable ? 'cursor-pointer' : ''
      }`}
      onClick={() => onClick?.(doc)}
    >
      <div className='relative overflow-hidden flex-shrink-0'>
        <BlockCover doc={summary} className='w-22.5 h-12.5' />
      </div>
      <div className='flex flex-col gap-2 mx-5 lg:w-160 w-120 flex-shrink-0'>
        <div className='text-sm font-medium' title={title}>
          <HighlightedTitle title={title} q={props.q} />
        </div>
        <div className='flex items-center text-2xs gap-1'>
          <BlockIcon
            className='w-3.5 h-3.5 flex-shrink-0'
            blockType={fromAPIBlockType(doc.block.type)}
          />
          {props.showSubtitle && (
            <span className='text-secondary truncate'>{subtitle}</span>
          )}
        </div>
      </div>
      <div className='ml-auto'></div>
      {menu}
    </div>
  );
}

export function BlockList(props: {
  loader: ReturnType<typeof useListLoader<DtoBlockSearchResponse, DtoBlockDoc>>;
  render: (doc: DtoBlockDoc) => JSX.Element | null;
}) {
  const { loader } = props;
  const { items, state, error, handleRetry, handleLoadMore, hasMore } = loader;

  const showEmptyMessage = !hasMore() && items.length === 0 && !state.isRunning;
  const showErrorMessage = !!error;
  const canLoad = state.isStarted && !state.isRunning && !error;

  return (
    <div className='w-full h-full min-h-100 scrollbar'>
      <div className='flex flex-col gap-3'>
        {items.map((doc) => (
          <Fragment key={doc.block.id}>{props.render(doc)}</Fragment>
        ))}
      </div>
      {state.isRunning && (
        <div className='w-full flex items-center justify-center text-white my-8'>
          <Loading />
        </div>
      )}
      {showErrorMessage && (
        <div className='w-full flex items-center justify-center text-white my-8'>
          <ErrorMessage text='Something went wrong' handleRetry={handleRetry} />
        </div>
      )}
      {showEmptyMessage && (
        <div className='w-full pt-60 flex justify-center text-white text-xl'>
          <ErrorMessage text={`No matching blocks found`} />
        </div>
      )}
      {canLoad && (
        <Waypoint onEnter={handleLoadMore} fireOnRapidScroll>
          <div>&nbsp;</div>
        </Waypoint>
      )}
    </div>
  );
}

export function useBlockListLoader(q?: string, params?: BlockSearchParams) {
  const paginator = useMemo(
    () => apiService.block.searchBlocks(q ?? '', params),
    [q, params]
  );
  return useListLoader<DtoBlockSearchResponse, DtoBlockDoc>(
    paginator,
    (a, b) => a.block.id === b.block?.id
  );
}

function BlockTypeFilter(props: {
  value: BlockType | null;
  onChange: (value: BlockType | null) => void;
}) {
  return (
    <div className='flex items-center gap-2 text-white'>
      <label className='text-sms tracking-wide font-medium'>Block Type:</label>
      <BlockTypeSelect
        value={props.value}
        onChange={props.onChange}
        className='w-60 h-10'
        placeholder='Choose...'
        isClearable
      />
    </div>
  );
}

function BlockSortFilter(props: {
  value: EnumsBlockSearchSortBy | null;
  onChange: (value: EnumsBlockSearchSortBy | null) => void;
}) {
  return (
    <div className='flex items-center gap-2 text-white'>
      <label className='text-sms tracking-wide font-medium'>Sort By:</label>
      <BlockSortSelect
        value={props.value}
        onChange={props.onChange}
        className='w-60 h-10'
        placeholder='Choose...'
        isClearable
      />
    </div>
  );
}

function BlockIncludeAllFilter(props: {
  value: boolean;
  onChange: (value: boolean) => void;
}) {
  return (
    <div className='flex items-center gap-2 text-white'>
      <label className='flex items-center gap-1 cursor-pointer'>
        <p className='ml-2 text-sms tracking-wide font-medium'>Include All:</p>
        <input
          type='checkbox'
          className='checkbox-dark'
          checked={props.value}
          onChange={(e) => props.onChange(e.target.checked)}
        />
      </label>
    </div>
  );
}

function BrandFilter(props: {
  value: string | null;
  onChange: (type: string | null) => void;
}) {
  const [selected, setSelected] = useState<DtoBrand | null>(null);

  useEffect(() => {
    if (props.value === null) {
      setSelected(null);
    } else if (props.value === '') {
      setSelected(BrandUtils.FakeBrandForOrphanBlocks());
    } else {
      apiService.brand.get(props.value).then((resp) => {
        setSelected(resp.data.brand);
      });
    }
  }, [props.value]);

  const onChange = (brand: DtoBrand | null) => {
    setSelected(brand);
    props.onChange(brand?.id ?? null);
  };

  return (
    <div className='flex items-center gap-2 text-white'>
      <label className='text-sms tracking-wide font-medium'>Brand:</label>
      <BrandSelect
        value={selected}
        onChange={onChange}
        className='w-60 h-10'
        placeholder='Choose...'
        isClearable
        orphan
      />
    </div>
  );
}

export type BlockSearchFilterValues = {
  type: BlockType | null;
  brandId: string | null;
  sortBy: EnumsBlockSearchSortBy | null;
  all: boolean;
};

export function ControlledBlockSearchFilters(props: {
  defaultValues: BlockSearchFilterValues;
  onChange: (values: BlockSearchFilterValues) => void;
}) {
  const [values, setValues] = useState(props.defaultValues);

  return (
    <section className='flex items-center gap-4 border-b border-secondary pb-2'>
      <BlockTypeFilter
        value={values.type}
        onChange={(v) => {
          setValues((prev) => {
            const next = { ...prev, type: v };
            props.onChange(next);
            return next;
          });
        }}
      />
      <BrandFilter
        value={values.brandId}
        onChange={(v) => {
          setValues((prev) => {
            const next = { ...prev, brandId: v };
            props.onChange(next);
            return next;
          });
        }}
      />
      <BlockSortFilter
        value={values.sortBy}
        onChange={(v) => {
          setValues((prev) => {
            const next = { ...prev, sortBy: v };
            props.onChange(next);
            return next;
          });
        }}
      />
      <BlockIncludeAllFilter
        value={values.all}
        onChange={(v) => {
          setValues((prev) => {
            const next = { ...prev, all: v };
            props.onChange(next);
            return next;
          });
        }}
      />
    </section>
  );
}

export function DefaultBlockList(props: {
  q?: string;
  type: BlockType | null;
  brandId: string | null;
  sortBy: EnumsBlockSearchSortBy | null;
  all: boolean;
}) {
  const { q, type, brandId, sortBy, all } = props;
  const navigate = useNavigate();
  const params = useMemo(
    () => ({ type, brandIds: brandId, sortBy, all }),
    [type, brandId, sortBy, all]
  );
  const loader = useBlockListLoader(q, params);
  const [searchParams, setSearchParams] = useSearchParams();

  const onChangeParams = (values: BlockSearchFilterValues) => {
    if (values.type != null) {
      searchParams.set('type', values.type);
    } else {
      searchParams.delete('type');
    }
    if (values.brandId !== null) {
      searchParams.set('brand-id', values.brandId);
    } else {
      searchParams.delete('brand-id');
    }
    if (values.sortBy !== null) {
      searchParams.set('sort-by', values.sortBy);
    } else {
      searchParams.delete('sort-by');
    }
    if (values.all) {
      searchParams.set('all', 'true');
    } else {
      searchParams.delete('all');
    }
    setSearchParams(searchParams);
  };

  return (
    <div className='w-full flex flex-col gap-4'>
      <ControlledBlockSearchFilters
        defaultValues={{
          type,
          brandId,
          sortBy,
          all,
        }}
        onChange={onChangeParams}
      />
      <BlockList
        loader={loader}
        render={(doc) => (
          <BlockDocCard
            doc={doc}
            q={props.q}
            onClick={(doc) => navigate(`/admin/blocks/${doc.block.id}`)}
            menu={
              <BlockActionSheet
                block={doc.block}
                onDuplicate={(b) => navigate(`/admin/blocks/${b.id}`)}
                onDelete={() => loader.dao.deleteItem(doc)}
              />
            }
          />
        )}
      />
    </div>
  );
}
