import { useState } from 'react';

import {
  BlockType,
  type MultipleChoiceBlock,
  type MultipleChoiceBlockDetailScore,
  MultipleChoiceGameSessionStatus,
  QuestionBlockAnswerGrade,
  type QuestionBlockDetailScore,
} from '@lp-lib/game';

import { nullOrUndefined } from '../../../../utils/common';
import {
  useDetailScores,
  useGameSessionStatus,
  useIsEndedBlock,
} from '../../hooks';
import { type EditPointData, grade, type SetEditPointsData } from '../../store';
import {
  type Grade,
  PartialCreditGradingCard,
  QuestionBlockAnswerGradeConverter,
  ResultView,
  SubmissionsActionBox,
} from '../Common/Controller/Internal';
import { type TeamScore, useRankedTeamScores } from '../Common/hooks';

function SubmissionRegrader(props: {
  block: MultipleChoiceBlock;
  teamId: string | undefined;
  onRegrade: () => void;
}): JSX.Element | null {
  const teamDetailScoreMap = useDetailScores<MultipleChoiceBlockDetailScore>(
    props.block.id
  );

  if (!props.teamId || !teamDetailScoreMap) return null;

  const teamSubmission = teamDetailScoreMap[props.teamId];
  if (!teamSubmission || teamSubmission.answer === undefined) {
    return null;
  }

  const teamsWithTheSameSubmission = Object.entries(teamDetailScoreMap)
    .filter(([_, submission]) => submission.answer === teamSubmission.answer)
    .map(([teamId, submission]) => {
      return {
        submitterUid: submission.submitterUid,
        teamId,
      };
    });

  const handleRegrade = async (newGrade: Grade) => {
    const teamIds = teamsWithTheSameSubmission.map((s) => s.teamId) || [];
    if (teamIds.length === 0) return;

    await grade<MultipleChoiceBlock, QuestionBlockDetailScore>(
      QuestionBlockAnswerGradeConverter.To(newGrade),
      teamIds,
      BlockType.MULTIPLE_CHOICE,
      (block) => block.fields.decreasingPointsTimer,
      (block) => block.fields.questionTimeSec,
      (block) => block.fields.points,
      () => false
    );

    props.onRegrade();
  };

  return (
    <PartialCreditGradingCard
      answer={teamSubmission.answer}
      submitters={teamsWithTheSameSubmission}
      currentGrade={QuestionBlockAnswerGradeConverter.From(
        teamSubmission.grade ?? QuestionBlockAnswerGrade.NONE
      )}
      onRegrade={handleRegrade}
    />
  );
}

function SubmissionRow(props: {
  score: TeamScore;
  isAfterTimesUp: boolean;
  detailScore?: MultipleChoiceBlockDetailScore;
  revealResults?: boolean;
  setEditPointsData?: SetEditPointsData;
  canRegradeAnswer: boolean;
  onRegradeAnswer: () => void;
}): JSX.Element {
  const {
    score,
    isAfterTimesUp,
    detailScore,
    revealResults,
    setEditPointsData,
    canRegradeAnswer,
    onRegradeAnswer,
  } = props;

  const actions = [
    {
      key: 'action-regrade-answer',
      text: 'Regrade Answer',
      disabled: !canRegradeAnswer,
      handleClick: onRegradeAnswer,
    },
  ];

  const editPointData: EditPointData = {
    teamName: score.team.name,
    teamId: score.team.id,
    submitterUid: '',
    defaultPoints: score.currentScore,
  };

  return (
    <SubmissionsActionBox
      teamId={score.team.id}
      view={revealResults ? 'result' : undefined}
      editPointData={editPointData}
      setEditPointsData={setEditPointsData}
      customActionItems={actions}
    >
      <div className='w-4/5 h-full pl-2'>
        <p className='h-1/3 pt-1 font-bold'>{score.team.name}</p>
        <div
          className={`w-full h-2/3 font-medium text-icon-gray flex flex-col justify-center ${
            !detailScore?.answer && 'opacity-20'
          }`}
        >
          <p className='w -full line-clamp-2 hyphens-auto'>
            {detailScore?.answer
              ? detailScore.answer
              : isAfterTimesUp
              ? '[No Answer Submitted]'
              : '[Waiting for Answer]'}
          </p>
        </div>
      </div>
      <div className='w-1/5 h-full bg-secondary border-l border-secondary flex flex-col justify-center items-center'>
        {revealResults ? (
          <ResultView
            totalScore={score.totalScore}
            previewPoints={score.currentScore}
          />
        ) : detailScore !== undefined ? (
          <div className='w-8 h-7 text-white font-bold text-2xs text-center flex flex-col justify-center'>
            <span>+{score.currentScore}</span>
            <span className='text-3xs'>Points</span>
          </div>
        ) : null}
      </div>
    </SubmissionsActionBox>
  );
}

export const MCSubmissionsView = (props: {
  block: MultipleChoiceBlock;
  revealResults?: boolean;
  setEditPointsData?: SetEditPointsData;
  sortByTotalScore?: boolean;
}): JSX.Element => {
  const { block, revealResults, setEditPointsData } = props;
  const teamDetailScoreMap = useDetailScores<MultipleChoiceBlockDetailScore>(
    block.id
  );
  const teamScores = useRankedTeamScores(
    props.sortByTotalScore ? 'totalScore' : 'teamName',
    teamDetailScoreMap,
    'always'
  );
  const gameSessionStatus =
    useGameSessionStatus<MultipleChoiceGameSessionStatus>();
  const isEndedBlock = useIsEndedBlock(block.id);
  const [regradeTeamId, setRegradeTeamId] = useState<string | undefined>();

  const correctAnswer = block.fields.answerChoices.find(
    (choice) => choice.correct
  );
  // edge case if there is no configured correct answer.
  const correctAnswerText = correctAnswer?.text ?? '???';

  const canRegradeAnswer =
    !isEndedBlock &&
    !nullOrUndefined(gameSessionStatus) &&
    gameSessionStatus < MultipleChoiceGameSessionStatus.PRESENTING_ANSWER;

  if (nullOrUndefined(gameSessionStatus)) return <></>;

  const title = `Correct Answer: ${correctAnswerText}`;
  const answeredTeamCount = Object.keys(teamDetailScoreMap ?? {}).length;
  const totalTeamCount = teamScores.length;

  const isAfterTimesUp =
    gameSessionStatus >= MultipleChoiceGameSessionStatus.SUBMISSION_TIMER_DONE;

  return (
    <>
      <div className='relative w-full h-full flex flex-col justify-between items-center'>
        <div className='w-full'>
          <div className='w-full h-10 px-2.5 py-1 leading-4 bg-black text-white text-2xs font-medium line-clamp-2'>
            {title}
          </div>

          <div className='w-full h-8 px-2.5 text-white text-2xs font-bold truncate flex flex-col justify-center'>
            Submissions {`(${answeredTeamCount}/${totalTeamCount} Teams)`}
          </div>
          <div className='w-full mb-2.5 pt-1 px-2.5 max-h-64 scrollbar-hide overflow-y-scroll'>
            {teamScores.map((score) => {
              return (
                <SubmissionRow
                  key={score.team.id}
                  score={score}
                  isAfterTimesUp={isAfterTimesUp}
                  detailScore={teamDetailScoreMap?.[score.team.id]}
                  revealResults={revealResults}
                  setEditPointsData={setEditPointsData}
                  canRegradeAnswer={canRegradeAnswer}
                  onRegradeAnswer={() => setRegradeTeamId(score.team.id)}
                />
              );
            })}
          </div>
        </div>
      </div>

      <SubmissionRegrader
        block={block}
        teamId={regradeTeamId}
        onRegrade={() => setRegradeTeamId(undefined)}
      />
    </>
  );
};
