import React, {
  type ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { type Settings as SliderSettings } from 'react-slick';
import { useWindowSize } from 'react-use';

import { GamePackPreviewMuteControlProvider } from '../../../app/components/GamePack/GamePackDetailGamePreview';
import {
  BreadcrumbChain,
  type BreadcrumbNode,
} from '../../components/Breadcrumbs';
import { makeSlickArrowButtons } from '../../components/common/Slick';
import {
  type EmbedContext,
  type GameLikeContext,
  type GameLikeMode,
  type GameLikePageType,
  useActiveInstanceWatcher,
  useGameLikeWorkspace,
  type WritableEmbedContext,
} from '../../components/Game/GameCenter';
import { LoadingGamePackCard } from '../../components/Game/GamePack/GamePackCard';
import { ProvidersList } from '../../components/ProvidersList';
import { useInstance } from '../../hooks/useInstance';
import { useQueryParam } from '../../hooks/useQueryParam';
import { apiService } from '../../services/api-service';
import { type Tag } from '../../types';
import { type GamePack } from '../../types/game';
import { fromDTOGamePack } from '../../utils/api-dto';

const settings = {
  num: 11,
  responsive: [
    {
      breakpoint: 3940,
      num: 10,
    },
    {
      breakpoint: 3440,
      num: 9,
    },
    {
      breakpoint: 3008,
      num: 8,
    },
    {
      breakpoint: 2560,
      num: 7,
    },
    {
      breakpoint: 1920,
      num: 6,
    },
    {
      breakpoint: 1680,
      num: 5,
    },
    {
      breakpoint: 1280,
      num: 4,
    },
    {
      breakpoint: 1024,
      num: 3,
    },
  ],
};

const getNumPerRow = (w: number): number => {
  for (let index = settings.responsive.length - 1; index >= 0; index--) {
    const e = settings.responsive[index];
    if (w <= e.breakpoint) {
      return e.num;
    }
  }
  return settings.num;
};

export function useNumPerRow(): number {
  const { width } = useWindowSize();
  return useMemo(() => getNumPerRow(width), [width]);
}

const [ArrowPrev, ArrowNext] = makeSlickArrowButtons({
  base: 'rounded-full border border-secondary bg-lp-black-002 w-12 h-12 md:w-15 md:h-15 absolute top-1/2 transform -translate-y-1/2 z-5 flex items-center justify-center cursor-pointer',
  prev: {
    container: '-left-6',
    disabled: 'opacity-25',
    arrow: 'w-7 h-7 fill-current',
  },
  next: {
    container: '-right-6',
    disabled: 'opacity-25',
    arrow: 'w-7 h-7 fill-current',
  },
});

export function useGamePackSliderSettings(): SliderSettings {
  const sliderSettings: SliderSettings = useMemo(() => {
    const s: SliderSettings = {
      infinite: false,
      slidesToShow: settings.num,
      slidesToScroll: settings.num,
      responsive: [],
      nextArrow: <ArrowNext />,
      prevArrow: <ArrowPrev />,
    };
    for (const e of settings.responsive) {
      s.responsive?.push({
        breakpoint: e.breakpoint,
        settings: {
          infinite: false,
          slidesToShow: e.num,
          slidesToScroll: e.num,
        },
      });
    }
    return s;
  }, []);
  return sliderSettings;
}

export function useGamePackLoader(n: number): JSX.Element[] {
  return useMemo(
    () =>
      [...Array(n)].map((_, i) => (
        <LoadingGamePackCard key={i} styles={{ size: 'w-full mx-2' }} />
      )),
    [n]
  );
}

async function getGamePackById(id: string): Promise<GamePack> {
  const resp = await apiService.gamePack.getGamePackById(id);
  return fromDTOGamePack(resp.data.gamePack);
}

export type GamePackPickedProps = {
  onGamePackClick?: (pack: GamePack) => void;
};

type GamePackContext = GameLikeContext<GamePack>;

const Context = React.createContext<GamePackContext | null>(null);

export function useGamePackContext(): GamePackContext {
  const ctx = useContext(Context);
  if (!ctx) {
    throw new Error('GamePackContext is not in the tree!');
  }
  return ctx;
}

function useEditingInstanceWatcher(): void {
  const view = useQueryParam('view');
  const [activeGamePack] = useGameLikeWorkspace('gamePack', 'active');
  const [, setEditingGamePack] = useGameLikeWorkspace('gamePack', 'edit');

  useEffect(() => {
    if (!activeGamePack || view !== 'edit') return;
    setEditingGamePack(activeGamePack);
  }, [activeGamePack, setEditingGamePack, view]);
}

type SharedProps = {
  breadcrumb?: BreadcrumbNode;
  pageType: GameLikePageType;
  children?: ReactNode;
};

type ProviderProps =
  | (SharedProps & {
      embed: false;
      routePrefix: string;
      editRoutePrefix: string;
      mode: GameLikeMode;
    })
  | (SharedProps & {
      embed: true;
      tag?: Tag;
      handleLoad?: (item: GamePack) => Promise<boolean>;
      handleSchedule?: (item: GamePack) => void;
      noOnboardingTasks?: boolean;
      noPlayButton?: boolean;
      isLegacyPublicHome?: boolean;
    });

export function GamePackContextProvider(props: ProviderProps): JSX.Element {
  const { pageType } = props;
  const handleLoad = props.embed ? props.handleLoad : undefined;
  const handleSchedule = props.embed ? props.handleSchedule : undefined;
  const mode = props.embed ? 'public-home' : props.mode;
  const tag = props.embed ? props.tag : undefined;
  const noOnboardingTasks = props.embed ? props.noOnboardingTasks : undefined;
  const noPlayButton = props.embed ? props.noPlayButton : undefined;
  const isLegacyPublicHome = props.embed ? props.isLegacyPublicHome : undefined;

  const breadcrumbs = useInstance(() => new BreadcrumbChain(props.breadcrumb));
  const [embedCtx, setEmbedCtx] = useState<EmbedContext<GamePack>>({
    handleLoad,
    tag,
  });

  useEffect(() => {
    setEmbedCtx((prev) => {
      return {
        ...prev,
        handleLoad,
        handleSchedule,
        noOnboardingTasks,
        noPlayButton,
        isLegacyPublicHome,
      };
    });
  }, [
    handleLoad,
    handleSchedule,
    isLegacyPublicHome,
    noOnboardingTasks,
    noPlayButton,
  ]);

  useActiveInstanceWatcher({
    type: 'gamePack',
    key: 'packId',
    getInstanceById: getGamePackById,
    disabled: props.embed,
  });

  useEditingInstanceWatcher();

  useEffect(() => {
    return () => {
      breadcrumbs.reset();
    };
  }, [breadcrumbs]);

  const updateEmbedCtx = useCallback((ctx: WritableEmbedContext | null) => {
    setEmbedCtx((prev) => {
      if (ctx === null) {
        return { ...prev, search: undefined, tag: undefined };
      }
      return { ...prev, ...ctx };
    });
  }, []);

  const routePrefix = !props.embed ? props.routePrefix : '';
  const editRoutePrefix = !props.embed ? props.editRoutePrefix : '';

  const providers = [<GamePackPreviewMuteControlProvider />];

  const ctxValue: GamePackContext = useMemo(
    () =>
      props.embed
        ? {
            embed: true,
            embedCtx,
            updateEmbedCtx,
            breadcrumbs,
            pageType,
          }
        : {
            routePrefix,
            editRoutePrefix,
            embed: false,
            mode,
            breadcrumbs,
            pageType,
          },
    [
      props.embed,
      mode,
      embedCtx,
      updateEmbedCtx,
      breadcrumbs,
      pageType,
      routePrefix,
      editRoutePrefix,
    ]
  );
  return (
    <ProvidersList providers={providers}>
      <Context.Provider value={ctxValue}>{props.children}</Context.Provider>
    </ProvidersList>
  );
}
