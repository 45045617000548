export function RoundRobinQuestionBlockIcon(
  props: React.SVGProps<SVGSVGElement>
): JSX.Element {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      className={props.className ?? 'w-3.5 h-3.5 fill-current'}
      viewBox='0 0 14 14'
    >
      <path
        fill='#C62192'
        d='M5.607 2.724V.976c-1.774.328-3.34 1.268-4.367 2.622C.21 4.95-.2 6.613.092 8.236c.29 1.623 1.263 3.08 2.712 4.067H.468V14H5.14c.248 0 .485-.09.66-.248a.81.81 0 00.274-.6V8.909H4.206v2.21c-1.107-.655-1.894-1.673-2.196-2.841a4.302 4.302 0 01.573-3.433C3.253 3.81 4.336 3.05 5.607 2.72v.003zM13.082 0H8.41c-.517 0-.934.38-.934.848v4.243h1.868V3.057c1.281.596 2.228 1.651 2.612 2.912.383 1.259.167 2.607-.596 3.715-.763 1.108-2.002 1.875-3.417 2.115v1.718c1.943-.242 3.686-1.212 4.805-2.675 1.118-1.463 1.51-3.285 1.082-5.023-.43-1.738-1.642-3.233-3.342-4.122h2.594V0z'
      ></path>
    </svg>
  );
}
