import { type GeneralNotification } from '../../../types';
import { InfoIcon } from '../../icons/InfoIcon';
import { XIcon } from '../../icons/XIcon';
import { useNotificationDataSource } from '../Context';
import { type BaseNotificationProps } from '../type';

function GeneralNotificationView(
  props: BaseNotificationProps & {
    notification: GeneralNotification;
  }
): JSX.Element {
  const metadata = props.notification.metadata;
  const notificationDataSource = useNotificationDataSource();

  const handleClose = (event: React.MouseEvent) => {
    event.stopPropagation();
    notificationDataSource.dismiss(props.notification.id);
  };

  return (
    <div className='w-full h-15 flex justify-between items-center px-5 bg-secondary'>
      <div className='flex items-center'>
        <InfoIcon className='w-5 h-5 fill-current mr-3' />
        {metadata.message}
      </div>
      <div className='cursor-pointer' onClick={handleClose}>
        <XIcon />
      </div>
    </div>
  );
}

// Required for lazy import
// eslint-disable-next-line import/no-default-export
export default GeneralNotificationView;
