import { Link } from '@remix-run/react';
import { useState } from 'react';
import { useEffectOnce } from 'react-use';
import useSWR from 'swr';
import { match } from 'ts-pattern';

import {
  type DtoProgram,
  EnumsProgramLinkStatus,
  EnumsProgramType,
} from '@lp-lib/api-service-client/public';
import { assertExhaustive } from '@lp-lib/game';

import { useArrayState } from '../../hooks/useArrayState';
import { useInstance } from '../../hooks/useInstance';
import { apiService } from '../../services/api-service';
import { getStaticAssetPath } from '../../utils/assets';
import { useCheckSlackScopes } from '../Channel';
import { useConfettiAnimation } from '../ConfettiAnimation';
import { SlackIcon } from '../icons/SlackIcon';
import {
  OnboardingMenuBox,
  OnboardingModalLayout,
} from '../Onboarding/OnboardingLayout';
import { useIsMyOrgSlackConnected, useMyOrganizer } from '../Organization';
import { SlackInstallButton } from '../Slack';
import { ProgramIcon } from './ProgramKnifes';
import { ProgramsConfigure } from './ProgramsConfigure';

function ConnectToSlack(props: {
  mode: 'upgrade' | 'install';
  onSkip: () => void;
}) {
  return (
    <div className='w-140 bg-modal rounded-2.5xl p-8 flex flex-col items-center overflow-visible'>
      <h2 className='relative z-5 text-2xl font-medium text-tertiary flex justify-center items-center gap-2'>
        Set Up Your Slack Tools!
      </h2>
      <p className='relative z-5 mt-2.5 px-8 text-base font-bold text-white text-center'>
        Engagement software that nurtures team connections, boosts morale, and
        promotes a collaborative culture.
      </p>

      <img
        className='relative z-1 w-124 h-112 -ml-16 -mt-7 -mb-22 transform scale-110'
        src={getStaticAssetPath('images/programs/ads-v2.png')}
        alt=''
      ></img>

      <p className='relative z-5 text-white font-bold'>
        {match(props.mode)
          .with(
            'upgrade',
            () =>
              'Exciting update: New enhancements are available for Luna Park Slack programs! Please update your Slack app now.'
          )
          .with('install', () => 'Included in your plan. Just connect Slack!')
          .exhaustive()}
      </p>
      <SlackInstallButton
        className='relative z-5 mt-9 w-100 h-15 btn-primary'
        text={match(props.mode)
          .with('upgrade', () => 'Update Slack App')
          .with('install', () => 'Connect to Slack')
          .exhaustive()}
      />
      <button
        type='button'
        className={`
        relative z-5
        btn text-base text-icon-gray underline 
        flex justify-center items-center mt-5
      `}
        onClick={props.onSkip}
      >
        Skip for now
      </button>
    </div>
  );
}

interface ProgramMenuItem {
  program: DtoProgram;
  checked: boolean;
}

function ProgramCard(props: {
  program: DtoProgram;
  checked: boolean;
  onChecked: (checked: boolean) => void;
  disabled?: boolean;
}) {
  const { program, checked, onChecked, disabled } = props;

  return (
    <OnboardingMenuBox
      clickable={!disabled}
      className={`pl-6 pr-24 py-6 ${disabled ? 'opacity-40' : ''}`}
    >
      <input
        type='checkbox'
        checked={checked}
        onChange={(e) => onChecked(e.target.checked)}
        disabled={disabled}
        className='checkbox-dark w-4.5 h-4.5 disabled:cursor-default flex-none'
      />
      <ProgramIcon
        programType={program.type}
        className={`w-17 h-15 object-contain rounded`}
      />

      <div className='flex-1'>
        <h3 className='text-lgfont-bold text-white'>{program.name}</h3>
        <p className='text-3xs text-white'>
          {program.basicSettings?.shortDescription || ''}
        </p>
      </div>
    </OnboardingMenuBox>
  );
}

export function ProgramsMenu(props: {
  programs: DtoProgram[];
  initSelectedProgramIds: string[];
  onConfirm: (programs: DtoProgram[]) => void;
}) {
  const { programs, initSelectedProgramIds, onConfirm } = props;

  const initItems = useInstance(() =>
    programs.map((p) => ({
      program: p,
      checked: initSelectedProgramIds.includes(p.id),
    }))
  );
  const [items, , dao] = useArrayState({
    init: initItems,
    compare: (a, b) => a.program.id === b.program?.id,
  });

  const handleCheck = (item: ProgramMenuItem, checked: boolean) => {
    dao.updateItem({
      ...item,
      checked,
    });
  };

  const handleConfirm = () => {
    onConfirm(items.filter((i) => i.checked).map((i) => i.program));
  };

  return (
    <div className='w-140 bg-modal rounded-2.5xl p-8 flex flex-col items-center'>
      <h2 className='text-2xl font-medium text-tertiary flex justify-center items-center gap-2'>
        <SlackIcon className='w-5 h-5' />
        Let's Get Started!
      </h2>
      <p className='mt-2.5 px-8 text-base font-bold text-white text-center'>
        We’ll get you up and running quickly! What would you like to do first?
      </p>
      <div className='mt-10 w-full flex flex-col gap-2'>
        {items.map((item) => (
          <ProgramCard
            key={item.program.id}
            program={item.program}
            checked={item.checked}
            onChecked={(checked) => handleCheck(item, checked)}
          />
        ))}
      </div>

      <button
        type='button'
        onClick={handleConfirm}
        disabled={items.every((i) => !i.checked)}
        className='mt-8 w-100 h-15 btn-primary'
      >
        Continue
      </button>
    </div>
  );
}

export function ProgramsActivationCompleted() {
  const organizer = useMyOrganizer();

  const { fire, canvasConfetti } = useConfettiAnimation();

  useEffectOnce(() => {
    fire();
  });

  return (
    <OnboardingModalLayout className='gap-10'>
      {canvasConfetti}

      <audio
        src={getStaticAssetPath('audios/v2/spotlight-confetti.mp3')}
        autoPlay={true}
      />

      <h2 className='text-2xl font-medium text-tertiary'>
        High five {organizer?.firstName}! You’re all set up!
      </h2>
      <img
        src={getStaticAssetPath('images/high-five.png')}
        className='w-25 h-25'
        alt='high-five'
      ></img>
      <p className='font-bold px-8 text-center'>
        What’s next? You can customize your Slack programs to work exactly how
        you want them to, or visit the Library to see the available experiences
        for your team!
      </p>
      <div className='flex justify-center items-center gap-5'>
        <Link
          to='/home'
          className='w-60 h-15 btn-secondary flex justify-center items-center'
        >
          See the Library
        </Link>
        <Link
          to='/channels'
          className='w-60 h-15 btn-primary flex justify-center items-center'
        >
          Edit My Programs
        </Link>
      </div>
    </OnboardingModalLayout>
  );
}

const SUPPORTED_PROGRAM_TYPES = [
  EnumsProgramType.ProgramTypeWaterCooler,
  EnumsProgramType.ProgramTypeBirthdayAndCelebrations,
  EnumsProgramType.ProgramTypeIntros,
  EnumsProgramType.ProgramTypeRecognition,
];

export function useProgramActivationData(
  orgId: string,
  options?: {
    initSelectedProgramIds?: string[];
    additionalSelectedProgramIds?: string[];
  }
) {
  return useSWR(`/programs/activation/${orgId}`, async () => {
    const [activePrograms, channels] = await Promise.all([
      (await apiService.program.getActivePrograms()).data.activePrograms,
      (await apiService.channel.queryChannels(orgId)).data.channels,
    ]);

    const programs = activePrograms.filter((p) =>
      SUPPORTED_PROGRAM_TYPES.includes(p.type)
    );
    const installedProgramLinks = channels
      .flatMap((c) => c.programLinks)
      .filter(
        (l) => l.status === EnumsProgramLinkStatus.ProgramLinkStatusActive
      );
    const uninstalledPrograms = programs.filter(
      (p) => !installedProgramLinks?.find((l) => l.programId === p.id)
    );
    const initSelectedPrograms = uninstalledPrograms.filter((p) => {
      if (!!options?.initSelectedProgramIds) {
        return options.initSelectedProgramIds.includes(p.id);
      }

      return (
        [
          EnumsProgramType.ProgramTypeWaterCooler,
          EnumsProgramType.ProgramTypeBirthdayAndCelebrations,
        ].includes(p.type) ||
        options?.additionalSelectedProgramIds?.includes(p.id)
      );
    });

    return {
      programs,
      channels,
      installedProgramLinks,
      uninstalledPrograms,
      initSelectedPrograms,
    };
  });
}

export type ProgramsActivationStage = 'select' | 'configure' | 'completed';

export function ProgramsActivation(props: {
  orgId: string;
  programs: DtoProgram[];
  initSelectedPrograms: DtoProgram[];
  onSkip: () => void;
  onStageChange?: (stage: ProgramsActivationStage) => void;
  renderCompleted?: (props: { activatedPrograms: DtoProgram[] }) => JSX.Element;
}) {
  const { programs, initSelectedPrograms, onSkip, renderCompleted } = props;

  const isSlackConnected = useIsMyOrgSlackConnected();
  const isSlackAppUpToDate = useCheckSlackScopes(
    'im:history',
    'mpim:history',
    'groups:history',
    'channels:history',
    'reactions:read'
  );

  const [stage, setStage] = useState<ProgramsActivationStage>('select');
  const [selectedPrograms, setSelectedPrograms] =
    useState<DtoProgram[]>(initSelectedPrograms);

  if (!isSlackConnected) {
    return <ConnectToSlack mode='install' onSkip={onSkip} />;
  }
  if (isSlackAppUpToDate === 'unknown') return null;
  if (isSlackAppUpToDate === false) {
    return <ConnectToSlack mode='upgrade' onSkip={onSkip} />;
  }

  const handleStageChange = (stage: ProgramsActivationStage) => {
    setStage(stage);
    props.onStageChange?.(stage);
  };

  switch (stage) {
    case 'select':
      return (
        <ProgramsMenu
          programs={programs}
          initSelectedProgramIds={selectedPrograms.map((p) => p.id)}
          onConfirm={(selected) => {
            setSelectedPrograms(selected);
            handleStageChange('configure');
          }}
        />
      );
    case 'configure':
      return (
        <ProgramsConfigure
          orgId={props.orgId}
          programs={selectedPrograms}
          onBack={() => {
            handleStageChange('select');
          }}
          onComplete={() => {
            handleStageChange('completed');
          }}
        />
      );
    case 'completed':
      return renderCompleted ? (
        renderCompleted({
          activatedPrograms: selectedPrograms,
        })
      ) : (
        <ProgramsActivationCompleted />
      );
    default:
      assertExhaustive(stage);
      return null;
  }
}
