import { useEffect, useRef } from 'react';

import { type GameSessionStatus } from '@lp-lib/game';

import { useLiveCallback } from '../../../hooks/useLiveCallback';
import logger from '../../../logger/logger';
import { nullOrUndefined } from '../../../utils/common';
import { useIsFirebaseConnected } from '../../Firebase';
import {
  useIsStreamSessionAlive,
  useIsStreamSessionInited,
} from '../../Session';
import { countdownV2, setTimer } from '../store';
import {
  useGameSessionBlock,
  useGameSessionStatus,
  useGameSessionStatusChangedAt,
  useIsGameSessionInited,
} from './gameSessionHooks';

const log = logger.scoped('game-session-timer-recover');

export function useTimerRecover(
  getTimeSec: (
    status: GameSessionStatus | undefined
  ) => Promise<number | undefined>
): void {
  const firebaseConnected = useIsFirebaseConnected();
  const isStreamSessionInited = useIsStreamSessionInited();
  const isStreamSessionAlive = useIsStreamSessionAlive();
  const status = useGameSessionStatus();
  const block = useGameSessionBlock();
  const timerRecoveryTriggered = useRef(false);
  const statusChangedAt = useGameSessionStatusChangedAt();
  const isGameSessionInited = useIsGameSessionInited();
  const stableGetTimeSec = useLiveCallback(getTimeSec);

  useEffect(() => {
    if (
      !isGameSessionInited ||
      !firebaseConnected ||
      !isStreamSessionInited ||
      !isStreamSessionAlive ||
      nullOrUndefined(status) ||
      !block ||
      timerRecoveryTriggered.current
    )
      return;

    timerRecoveryTriggered.current = true;

    log.info('timer recovery triggered');

    if (statusChangedAt === -1) {
      log.warn('statusChangedAt is invalid', { statusChangedAt });
      return;
    }

    stableGetTimeSec(status).then((time) => {
      if (!time) {
        log.warn('time not available', {
          blockId: block.id,
          status,
          time,
        });
        return;
      }

      const diff = Math.round((Date.now() - statusChangedAt) / 1000);
      if (diff > 1 && diff < time) {
        log.info('timer recovered, start countdown', {
          diff,
          timerOverride: time - diff,
        });
        setTimer('submission', time - diff);
        countdownV2({
          debug: 'useTimerRecover',
          startTimeWorker: true,
        });
      }
    });
  }, [
    firebaseConnected,
    isStreamSessionInited,
    isStreamSessionAlive,
    status,
    block,
    statusChangedAt,
    isGameSessionInited,
    stableGetTimeSec,
  ]);
}
