import {
  type ComponentType,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react';
import { useEffectOnce } from 'react-use';

import { useLobbyAnalytics } from '../../analytics/lobbyRecommendation';
import gameController2 from '../../assets/img/game-controller-2.png';
import leaderboardTrophy from '../../assets/img/leaderboard-trophy.png';
import { useFeatureQueryParam } from '../../hooks/useFeatureQueryParam';
import { getStaticAssetPath } from '../../utils/assets';
import { OthersPlayingTicker } from '../Game/GamePack/OthersPlayingTicker';
import { useOndGameUIControl } from '../OnDGameUIControl';
import { useVenueId } from '../Venue';
import { LobbyGameConsole } from './LobbyGameConsole';
import { LobbyLeaderboards } from './LobbyLeaderboards';
import { LobbyMemories } from './LobbyMemories';
import { useLobbyAPI } from './Provider';
import { type LobbyDecisionData } from './useLobbyDecisionData';

export enum LobbyWidgetKey {
  Leaderboards = 'leaderboards',
  Memories = 'memories',
  GameConsole = 'game console',
}

export type LobbyWidgetProps = LobbyDecisionData;

type LobbyWidget = {
  key: LobbyWidgetKey;
  title: string;
  iconSrc: string;
  component: ComponentType<LobbyWidgetProps>;
};

const polaroidCamera = getStaticAssetPath('images/polaroid-camera.png');

const widgetMap: { [key in LobbyWidgetKey]: LobbyWidget } = {
  [LobbyWidgetKey.Leaderboards]: {
    key: LobbyWidgetKey.Leaderboards,
    title: 'Leaderboards',
    iconSrc: leaderboardTrophy,
    component: LobbyLeaderboards,
  },
  [LobbyWidgetKey.Memories]: {
    key: LobbyWidgetKey.Memories,
    title: 'Memories',
    iconSrc: polaroidCamera,
    component: LobbyMemories,
  },
  [LobbyWidgetKey.GameConsole]: {
    key: LobbyWidgetKey.GameConsole,
    title: 'Game Console',
    iconSrc: gameController2,
    component: LobbyGameConsole,
  },
};

export function LobbyGameWidgets(props: {
  decisionData: LobbyDecisionData;
}): JSX.Element | null {
  const memoriesEnabled = useFeatureQueryParam('memories');
  const otherPlayingEnabled = useFeatureQueryParam('lobby-others-playing');
  const widgetMenu = useMemo(() => {
    let menu;
    if (props.decisionData.isOndGame && props.decisionData.gameOrGamePack) {
      menu = [LobbyWidgetKey.GameConsole, LobbyWidgetKey.Leaderboards];
    } else {
      menu = [LobbyWidgetKey.GameConsole];
    }

    if (props.decisionData.gameOrGamePack) {
      menu.push(LobbyWidgetKey.Memories);
    }
    if (!memoriesEnabled) {
      menu = menu.filter((k) => k !== LobbyWidgetKey.Memories);
    }

    const disabledWidgets = props.decisionData.disabledWidgets ?? [];
    menu = menu.filter((k) => !disabledWidgets.includes(k));

    return menu;
  }, [
    props.decisionData.isOndGame,
    props.decisionData.gameOrGamePack,
    props.decisionData.disabledWidgets,
    memoriesEnabled,
  ]);

  const api = useLobbyAPI();
  const ondUICtrl = useOndGameUIControl();
  const analytics = useLobbyAnalytics();
  const venueId = useVenueId();

  const [currentWidgetKey, setCurrentWidgetKey] = useState<
    LobbyWidgetKey | undefined
  >(widgetMenu[0]);

  const [widgetGreenDot, setWidgetGreenDot] = useState(
    new Map<LobbyWidgetKey, boolean>()
  );

  const updateWidgetGreenDot = useCallback(
    (key: LobbyWidgetKey, value: boolean) => {
      setWidgetGreenDot(new Map(widgetGreenDot.set(key, value)));
    },
    [widgetGreenDot]
  );

  const isCoordinator = props.decisionData.userIsCoordinator && !!ondUICtrl;

  // reset the selected menu on game change.
  useEffect(() => {
    setCurrentWidgetKey(widgetMenu[0]);
  }, [props.decisionData.gameOrGamePack?.id, widgetMenu]);

  useEffectOnce(() => {
    return api.offBoardingSignal.connect({
      name: 'offboarding',
      after: async () => {
        if (props.decisionData.isOndGame && !props.decisionData.isPairingGame) {
          updateWidgetGreenDot(LobbyWidgetKey.Memories, true);
        }

        updateWidgetGreenDot(LobbyWidgetKey.Leaderboards, true);
      },
    });
  });

  useLayoutEffect(() => {
    return api.offBoardingSignal.connect({
      name: 'offboarding',
      after: async () => {
        if (props.decisionData.isOndGame && !props.decisionData.isPairingGame) {
          setCurrentWidgetKey(LobbyWidgetKey.GameConsole);
          return;
        }

        if (!memoriesEnabled) return;
        setCurrentWidgetKey(LobbyWidgetKey.Memories);
      },
    });
  }, [
    setCurrentWidgetKey,
    memoriesEnabled,
    props.decisionData.gameOrGamePack,
    api,
    props.decisionData.isOndGame,
    props.decisionData.isPairingGame,
    isCoordinator,
    updateWidgetGreenDot,
  ]);

  // NOTE(drew): not sure if this memoization is necessary. It was here before,
  // so I kept it.
  const {
    isPairingGame,
    shadowGamePacksInfo,
    userHasTeam,
    userIsAlone,
    userIsHostClient,
    gameIsInProgress,
    gameOrGamePack,
    gamePackPlus,
    isOndGame,
    coordinator,
    userIsCoordinator,
    townhallEnabled,
    event,
    featureChecker,
  } = props.decisionData;

  const body = useMemo(() => {
    if (currentWidgetKey === undefined) return null;
    const currentWidget = widgetMap[currentWidgetKey];
    if (currentWidget === undefined) return null;
    const Widget = currentWidget.component;
    return (
      <Widget
        isPairingGame={isPairingGame}
        shadowGamePacksInfo={shadowGamePacksInfo}
        userHasTeam={userHasTeam}
        userIsAlone={userIsAlone}
        userIsHostClient={userIsHostClient}
        gameIsInProgress={gameIsInProgress}
        gameOrGamePack={gameOrGamePack}
        gamePackPlus={gamePackPlus}
        isOndGame={isOndGame}
        coordinator={coordinator}
        userIsCoordinator={userIsCoordinator}
        townhallEnabled={townhallEnabled}
        event={event}
        featureChecker={featureChecker}
      />
    );
  }, [
    coordinator,
    currentWidgetKey,
    event,
    featureChecker,
    gameIsInProgress,
    gameOrGamePack,
    gamePackPlus,
    isOndGame,
    isPairingGame,
    shadowGamePacksInfo,
    townhallEnabled,
    userHasTeam,
    userIsAlone,
    userIsCoordinator,
    userIsHostClient,
  ]);

  useEffect(() => {
    if (currentWidgetKey) {
      analytics.trackWidgetViewed({ widgetKey: currentWidgetKey, venueId });
    }
  }, [analytics, currentWidgetKey, venueId]);

  const coordinatorDisplayName =
    coordinator?.firstName ?? coordinator?.username ?? 'the organizer';

  if (body === null) return null;

  return (
    <>
      <div className='bg-lp-black-001 rounded-xl'>
        <div
          className={`
            mx-3 mt-1 border-b border-white-002
            py-2 px-3
            flex items-center justify-between gap-4
          `}
        >
          {isOndGame && (
            <div className='flex-none flex items-center gap-5'>
              {userIsCoordinator && (
                <div className='w-14 text-tertiary text-base font-bold flex-none'>
                  STEP 2
                </div>
              )}
              <div className='text-white font-bold flex-none text-sm'>
                {userIsCoordinator ? (
                  <>Start your experience!</>
                ) : (
                  <>
                    <>Ready to play?</>
                    <p className='text-tertiary text-2xs font-bold'>
                      {`Ask ${coordinatorDisplayName} to start the experience!`}
                    </p>
                  </>
                )}
              </div>
            </div>
          )}

          <div className='flex items-center justify-end gap-4 text-xs'>
            {widgetMenu.map((key) => {
              const w = widgetMap[key];
              return (
                <button
                  key={w.key}
                  type='button'
                  className={`
                flex items-center gap-1 btn
                ${w.key === currentWidgetKey ? 'font-bold underline' : ''}
              `}
                  onClick={() => {
                    analytics.trackWidgetClicked({ widgetKey: w.key });
                    setCurrentWidgetKey(w.key);
                    updateWidgetGreenDot(w.key, false);
                  }}
                >
                  <img
                    src={w.iconSrc}
                    alt={`${w.title} Icon`}
                    className='w-4 h-4'
                  />
                  {w.title}
                  {widgetGreenDot.get(w.key) ? (
                    <span className='text-green-400 text-3xs w-1 no-underline'>
                      ●
                    </span>
                  ) : (
                    <div className='w-1' />
                  )}
                </button>
              );
            })}
          </div>
        </div>

        <div>{body}</div>
      </div>

      {otherPlayingEnabled &&
        isOndGame &&
        currentWidgetKey === LobbyWidgetKey.GameConsole &&
        gameOrGamePack?.type === 'gamePack' && (
          <OthersPlayingTicker
            packId={gameOrGamePack.id}
            onClick={() => {
              analytics.trackOthersPlayingClicked({
                gamePackId: gameOrGamePack.id,
                gamePackName: gameOrGamePack.name,
              });
              setCurrentWidgetKey(LobbyWidgetKey.Leaderboards);
            }}
          />
        )}
    </>
  );
}
