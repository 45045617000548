import * as bpInterop from './breakpoints.config.cjs';

// This dance is to get around the following:
// - breakpoints.config.cjs is imported by tailwind.config.cjs. Tailwind 2 is
//   not ESM (.mjs) aware/able.
// - vite serves everything in ESM. This file is served as ESM, but fails
//   because it's actually CJS and vite does not transform it.
// - We transform this file to ESM using @originjs/vite-plugin-commonjs, which
//   results in all the exports being grouped into a single `default` (unnamed)
//   export.
type BPMod = typeof bpInterop;
const unknownBp = bpInterop as unknown;
const bp =
  unknownBp && typeof unknownBp === 'object' && 'default' in unknownBp
    ? (unknownBp.default as BPMod)
    : bpInterop;

// Mostly for quick iteration
export const BreakpointsEntries: readonly [string, number][] = Object.entries(
  bp.breakpointsUnitless
);

// These are the properties we actually care about when deriving/choosing
// unitless values.
export type BPUnitlessProperties = {
  [K in
    | Exclude<keyof typeof bp.breakpointsUnitless, 'sm' | 'md' | 'lg'>
    | '']: number;
};

// "Primaries" are the lp- prefixed breakpoints
// "Secondaries" are breakpoints without lp-prefix

type BPClassArrayWithPrimariesSecondaries = [
  `${string}`,
  `xl:${string}`,
  `lp-sm:${string}`,
  `2xl:${string}`,
  `3xl:${string}`,
  `lp-md:${string}`,
  `lp-lg:${string}`
];

type BPClassNameWithPrimariesSecondaries =
  `${BPClassArrayWithPrimariesSecondaries[0]} \
${BPClassArrayWithPrimariesSecondaries[1]} \
${BPClassArrayWithPrimariesSecondaries[2]} \
${BPClassArrayWithPrimariesSecondaries[3]} \
${BPClassArrayWithPrimariesSecondaries[4]} \
${BPClassArrayWithPrimariesSecondaries[5]}`;

type BPClassArrayWithPrimaries = [
  `${string}`,
  `lp-sm:${string}`,
  `lp-md:${string}`,
  `lp-lg:${string}`
];

type BPClassNameWithPrimaries = `${BPClassArrayWithPrimaries[0]} \
${BPClassArrayWithPrimaries[1]} \
${BPClassArrayWithPrimaries[2]} \
${BPClassArrayWithPrimaries[3]}`;

/**
 *
 * Provide either an array of arrays of breakpoint-prefixed classes, or array of
 * one large well-formatted class name. This utility requires all desktop breakpoints.
 */
export function bps(...breaks: BPClassArrayWithPrimariesSecondaries[]): string;
export function bps(...breaks: BPClassNameWithPrimariesSecondaries[]): string;
export function bps(
  ...breaks:
    | BPClassNameWithPrimariesSecondaries[]
    | BPClassArrayWithPrimariesSecondaries[]
): string {
  return breaks
    .map((stringOrArray) =>
      Array.isArray(stringOrArray) ? stringOrArray.join(' ') : stringOrArray
    )
    .join(' ');
}

/**
 *
 * Provide either an array of arrays of breakpoint-prefixed classes, or array of
 * one large well-formatted class name. This utility requires only LP- prefixed
 * desktop breakpoints.
 */
export function bpsLP(...breaks: BPClassArrayWithPrimaries[]): string;
export function bpsLP(...breaks: BPClassNameWithPrimaries[]): string;
export function bpsLP(
  ...breaks: BPClassArrayWithPrimaries[] | BPClassNameWithPrimaries[]
): string {
  return breaks
    .map((stringOrArray) =>
      Array.isArray(stringOrArray) ? stringOrArray.join(' ') : stringOrArray
    )
    .join(' ');
}
