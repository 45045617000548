import { type TitleBlockV2, type TitleCard } from '@lp-lib/game';

import { uuidv4 } from '../../../../utils/common';
import { TagQuery } from '../../../../utils/TagQuery';
import {
  type BlockRecordingExtra,
  type GameInfoSnapshot,
  type GenerateV3RecordingOpts,
} from '../../OndPhaseRunner/types';
import { uncheckedIndexAccess_UNSAFE } from '../../../../utils/uncheckedIndexAccess_UNSAFE';

/**
 * Unique key that indicates this card is ephemeral: it was created during this
 * playback session. This goes into Firebase, so it cannot be a symbol.
 */
const IS_EPHEMERAL_CARD_KEY = 'ephemeral-card';

export function isEphemeralTitleCard(card: TitleCard) {
  return uncheckedIndexAccess_UNSAFE(card)[IS_EPHEMERAL_CARD_KEY] === true;
}

export function createEphemeralTitleCard(
  sourceCard: TitleCard | null,
  cardText: string,
  isTeamIntro: boolean
) {
  const card: TitleCard = {
    id: uuidv4(),
    text: cardText,
    fullscreen: true,
    animatedTransition: false,
    playBackgroundMusicWithMedia: false,
    teamIntroEnabled: isTeamIntro,
  } satisfies TitleCard;

  uncheckedIndexAccess_UNSAFE(card)[IS_EPHEMERAL_CARD_KEY] = true;

  if (sourceCard && isTeamIntro) {
    card.media = sourceCard.media;
    card.mediaData = sourceCard.mediaData;
  }

  return card;
}

export async function expandCardIntoTeamIntro(
  card: TitleCard,
  block: TitleBlockV2,
  out_titleCardsToTeams: NonNullable<BlockRecordingExtra['titleCardsToTeams']>,
  out_titleCardsToVOPlans: NonNullable<
    BlockRecordingExtra['titleCardsToVoiceOverPlans']
  >,
  out_titleCardsToVoiceOverDelayStartMs: NonNullable<
    BlockRecordingExtra['titleCardsToVoiceOverDelayStartMs']
  >,
  extra: GameInfoSnapshot,
  opts: GenerateV3RecordingOpts
): Promise<TitleCard[]> {
  const voCards: TitleCard[] = [];

  const q2 = new TagQuery(block.fields.ttsPlaybackOptions);
  const introductionOpts = q2.selectFirst(['introduction', card.id]);
  const individualTeamOpts = q2.selectFirst(['individual-team', card.id]);
  const conclusionOpts = q2.selectFirst(['conclusion', card.id]);

  // These are the "shared" plans that were created during playback generation
  // (intoPlayback). They are shared because the script is the same for all
  // instances, even though there will be many teams. So playback generation
  // creates one plan for each up front. We just pass references to them along,
  // tagged with the target cardId, into the "extra" so they are accessible
  // within the block for play control.
  const q3 = new TagQuery(opts.voiceOverPlans);
  const introPlan = q3.selectFirst(['introduction', card.id]);
  const teamPlan = q3.selectFirst(['individual-team', card.id]);
  const conclusionPlan = q3.selectFirst(['conclusion', card.id]);

  // Introduction Script
  const voCardIntro = createEphemeralTitleCard(card, '', true);
  if (introPlan) {
    out_titleCardsToVOPlans[voCardIntro.id] = introPlan.plan;
    out_titleCardsToVoiceOverDelayStartMs[voCardIntro.id] =
      introductionOpts?.delayMs ?? 0;
  }

  voCards.push(voCardIntro);

  // Individual Teams Script
  for (const team of Object.values(extra.teams)) {
    const voCard = createEphemeralTitleCard(card, team.name, true);

    // Mark this card as a BOS card for this team.
    out_titleCardsToTeams[voCard.id] = team.id;
    if (teamPlan) {
      out_titleCardsToVOPlans[voCard.id] = teamPlan.plan;
      out_titleCardsToVoiceOverDelayStartMs[voCard.id] =
        individualTeamOpts?.delayMs ?? 0;
    }
    voCards.push(voCard);
  }

  // Conclusion Script
  const voCardConclusion = createEphemeralTitleCard(card, '', true);
  if (conclusionPlan) {
    out_titleCardsToVOPlans[voCardConclusion.id] = conclusionPlan.plan;
    out_titleCardsToVoiceOverDelayStartMs[voCardConclusion.id] =
      conclusionOpts?.delayMs ?? 0;
  }
  voCards.push(voCardConclusion);

  return voCards;
}

export async function expandCardIntoNormal(
  card: TitleCard,
  block: TitleBlockV2,
  // ttsOptions: ModelsTTSLabeledRenderSettings,
  out_titleCardsToVOPlans: NonNullable<
    BlockRecordingExtra['titleCardsToVoiceOverPlans']
  >,
  out_titleCardsToVoiceOverDelayStartMs: NonNullable<
    BlockRecordingExtra['titleCardsToVoiceOverDelayStartMs']
  >,
  _extra: GameInfoSnapshot,
  opts: GenerateV3RecordingOpts
): Promise<TitleCard[]> {
  const q2 = new TagQuery(block.fields.ttsPlaybackOptions);
  // const fallbackOpts = q2.selectFirst(['fallback', card.id]);
  const runtimeOpts = q2.selectFirst(['runtime', card.id]);

  const q3 = new TagQuery(opts.voiceOverPlans);
  const cardPlan = q3.selectFirst(['card', card.id]);

  if (cardPlan) {
    out_titleCardsToVOPlans[card.id] = cardPlan.plan;
    out_titleCardsToVoiceOverDelayStartMs[card.id] = runtimeOpts?.delayMs ?? 0;
  }

  return [card];
}
