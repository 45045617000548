import React, { type ReactNode, useContext, useMemo, useState } from 'react';

type Context = {
  box: { top?: string; right?: string; bottom?: string; left?: string };
};

const context = React.createContext<Context | null>(null);

export function SafeZoneProvider(
  props: { top?: string } & { children?: ReactNode }
): JSX.Element {
  const { children, ...boxParams } = props;
  const [box] = useState(boxParams);
  const ctx = useMemo(() => ({ box }), [box]);

  return <context.Provider value={ctx}>{props.children}</context.Provider>;
}

export function useSafeBoundingBoxTop(): Context['box']['top'] {
  const ctx = useContext(context);
  if (!ctx) return;
  return ctx.box.top;
}
