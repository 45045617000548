import { getDay } from 'date-fns';
import pluralize from 'pluralize';
import { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import Select from 'react-select';

import { EnumsProgramCadenceFrequency } from '@lp-lib/api-service-client/public';

import { buildReactSelectStyles } from '../../utils/react-select';
import { DateTimePicker } from '../common/DateTimePicker';
import { type Option } from '../common/Utilities';
import { CalendarIcon } from '../icons/CalendarIcon';
import { ProgramUtils } from './utils';

type CadenceConfig = {
  frequencies: readonly EnumsProgramCadenceFrequency[] | 'all';
  roundTimeToMinutes: number;
};

export type ProgramCadenceFormData = {
  cadenceSettings: {
    frequency: EnumsProgramCadenceFrequency;
    nextTriggerTime: {
      date: Date;
      timezone: string;
    };
    weekdays: number[];
  };
};

function frequencyToOption(v: EnumsProgramCadenceFrequency) {
  if (v === 0) {
    return {
      label: 'Multiple Times Per Week',
      value: v,
    };
  }
  return {
    label: `Every ${v} ${pluralize('Week', v)}`,
    value: v,
  };
}

const FREQUENCY_OPTIONS = Object.freeze(
  Object.values(EnumsProgramCadenceFrequency)
    .filter((v): v is number => typeof v === 'number')
    .map(frequencyToOption)
);

const REACT_SELECT_STYLES = buildReactSelectStyles<Option<number>>({
  override: { control: { height: 48 } },
});

export const WEEKDAY_OPTIONS = [
  { label: 'Mon', value: 1 },
  { label: 'Tue', value: 2 },
  { label: 'Wed', value: 3 },
  { label: 'Thu', value: 4 },
  { label: 'Fri', value: 5 },
];

function useFrequencyOptions(config: CadenceConfig['frequencies']) {
  if (config === 'all') {
    return FREQUENCY_OPTIONS;
  }
  return FREQUENCY_OPTIONS.filter((o) => config.includes(o.value));
}

function FrequencyEditor(props: {
  config: CadenceConfig['frequencies'];
  hint?: string;
  disabled?: boolean;
}) {
  const options = useFrequencyOptions(props.config);
  const { control } = useFormContext<ProgramCadenceFormData>();
  return (
    <Controller
      name='cadenceSettings.frequency'
      control={control}
      render={({ field: { value, onChange } }) => (
        <div className='flex flex-col w-85'>
          <div className='font-bold mb-2'>
            <div>Frequency</div>
            {props.hint && (
              <div className='text-3xs text-icon-gray font-medium'>
                {props.hint}
              </div>
            )}
          </div>
          <Select<Option<number>>
            options={options}
            value={frequencyToOption(value)}
            classNamePrefix='select-box-v2'
            className='w-full'
            styles={REACT_SELECT_STYLES}
            onChange={(option) => {
              onChange(option?.value);
            }}
            isDisabled={props.disabled}
          />
        </div>
      )}
    />
  );
}

function WeekdaysEditor(props: { disabled?: boolean }) {
  const { watch, control } = useFormContext<ProgramCadenceFormData>();
  const frequency = watch('cadenceSettings.frequency');

  if (frequency !== 0) {
    return null;
  }

  return (
    <Controller
      name='cadenceSettings.weekdays'
      control={control}
      rules={{
        validate: (weekdays) => {
          return weekdays.length >= 2 || 'You must select at least 2 weekdays.';
        },
      }}
      render={({ field: { value, onChange }, fieldState }) => {
        return (
          <div className='flex flex-col gap-1'>
            <div className='flex gap-1'>
              {WEEKDAY_OPTIONS.map((o) => (
                <button
                  key={o.value}
                  type='button'
                  className={`btn w-30 h-10 bg-secondary rounded-xl border ${
                    value.includes(o.value)
                      ? 'border-primary'
                      : 'border-secondary'
                  }`}
                  onClick={() => {
                    if (value.includes(o.value)) {
                      onChange(value.filter((v) => v !== o.value));
                    } else {
                      onChange([...value, o.value].sort());
                    }
                  }}
                  disabled={props.disabled}
                >
                  {o.label}
                </button>
              ))}
            </div>
            {fieldState.error && (
              <div className='text-sms text-red-002'>
                {fieldState.error.message}
              </div>
            )}
          </div>
        );
      }}
    />
  );
}

function NextTriggerTimeEditor(props: {
  title: string;
  roundToMinutes: number;
  hint?: string;
  disabled?: boolean;
}) {
  const { roundToMinutes } = props;
  const { watch, control, setValue, getValues } =
    useFormContext<ProgramCadenceFormData>();
  const frequency = watch('cadenceSettings.frequency');
  const weekdays = watch('cadenceSettings.weekdays');

  useEffect(() => {
    setValue('cadenceSettings.nextTriggerTime', {
      date: ProgramUtils.NextCadenceAfter(
        frequency,
        weekdays,
        getValues('cadenceSettings.nextTriggerTime.date'),
        roundToMinutes
      ),
      timezone: getValues('cadenceSettings.nextTriggerTime.timezone'),
    });
  }, [frequency, getValues, roundToMinutes, setValue, weekdays]);

  return (
    <Controller
      name='cadenceSettings.nextTriggerTime'
      control={control}
      rules={{
        validate: (nextTriggerTime) => {
          return (
            nextTriggerTime.date > new Date() ||
            'You must select a future time.'
          );
        },
      }}
      render={({ field: { value, onChange }, fieldState }) => (
        <div className='flex flex-col'>
          <div className='font-bold mb-2'>
            <div>{props.title}</div>
            {props.hint && (
              <div className='text-3xs text-icon-gray font-medium'>
                {props.hint}
              </div>
            )}
          </div>
          <DateTimePicker
            value={value}
            onChange={onChange}
            filterDate={(date) => {
              const day = getDay(date);
              if (frequency === 0) {
                return weekdays.includes(day);
              }
              return day !== 0 && day !== 6;
            }}
            timeIntervals={props.roundToMinutes}
            disabled={props.disabled}
          />
          {fieldState.error && (
            <div className='text-sms text-red-002'>
              {fieldState.error.message}
            </div>
          )}
        </div>
      )}
    />
  );
}

export function ProgramCadenceEditor(props: {
  cadenceConfig: CadenceConfig;
  title: string;
  uiHints?: Partial<{
    frequency: string;
    nextTriggerTime: string;
  }>;
  disabled?: boolean;
}) {
  const { cadenceConfig, disabled } = props;
  return (
    <section className='w-full flex flex-col gap-5'>
      <header className='flex items-center gap-2 text-2xl font-medium'>
        <CalendarIcon className='w-7.5 h-7.5 fill-current' />
        <div>Select Cadence</div>
      </header>
      <main className='flex flex-col gap-10'>
        <div className='flex flex-col gap-2'>
          <FrequencyEditor
            config={cadenceConfig.frequencies}
            hint={props.uiHints?.frequency}
            disabled={disabled}
          />
          <WeekdaysEditor disabled={disabled} />
        </div>
        <NextTriggerTimeEditor
          title={props.title}
          roundToMinutes={cadenceConfig.roundTimeToMinutes}
          hint={props.uiHints?.nextTriggerTime}
          disabled={disabled}
        />
      </main>
    </section>
  );
}
