import { useMemo } from 'react';

import { useInstance } from '../../../../hooks/useInstance';
import {
  type Classes,
  StagedTailwindTransition,
  type TailwindTransitionStage,
} from '../../../common/TailwindTransition';
import { LayoutAnchor } from '../../../LayoutAnchors/LayoutAnchors';
import {
  useOverRoastedGameSettings,
  useOverRoastedMyGroupSummary,
} from './OverRoastedProvider';
import {
  useOverRoastedActiveTruckId,
  useOverRoastedGroupMachine,
  useOverRoastedTruckOrders,
  useOverRoastedTruckTheme,
  useOverRoastedTutorialProgress,
} from './OverRoastedProvider/OverRoastedGamePlayProvider';
import { Sprite, type SpriteNames, useScaledSpriteInfo } from './Sprite';
import { type Order, TutorialStep } from './types';
import { OverRoastedUtils } from './utils';

function OrderIngredients(props: {
  order: Order;
  width: number;
}): JSX.Element | null {
  const { order, width } = props;
  const ingredients = useMemo(() => {
    if (!order.ingredients) return [];

    return order.ingredients.map((ingredient, i) => {
      if (i === 0) {
        return { ingredient, left: '0px', top: '0px', hide: false };
      } else if (i === 1) {
        return {
          ingredient,
          left: `${width * 0.6}px`,
          top: `${width * 0.6}px`,
          hide: false,
        };
      } else if (i === 2) {
        return {
          ingredient,
          left: '0px',
          top: `${width * 2 * 0.6}px`,
          hide: false,
        };
      } else {
        return { ingredient, left: '0px', top: '0px', hide: true };
      }
    });
  }, [order.ingredients, width]);

  return (
    <div
      className='absolute'
      style={{
        left: `${(width * 2) / 10}px`,
        top: `${(width * 2) / 10}px`,
      }}
    >
      {ingredients.map((i, index) => (
        <div
          key={index}
          className='absolute'
          style={{
            left: i.left,
            top: i.top,
            display: i.hide ? 'none' : undefined,
          }}
        >
          <Sprite name={`ingredient-${i.ingredient}-raw`} width={width} />
        </div>
      ))}
    </div>
  );
}

function OrderMatchedState(props: { order: Order }): JSX.Element | null {
  const { order } = props;
  const machine = useOverRoastedGroupMachine(order.matchedMachineId);
  const settings = useOverRoastedGameSettings();

  const spriteName = useMemo<SpriteNames>(() => {
    const fallback = 'dispenser-order-matched-default';
    if (!machine?.id || !settings.useCupColorForMatchedOrder) return fallback;
    const color = OverRoastedUtils.GetCupColor(machine.index);
    if (color) {
      return `dispenser-order-matched-${color}`;
    }
    return fallback;
  }, [machine?.id, machine?.index, settings.useCupColorForMatchedOrder]);

  if (!machine) return null;

  return (
    <div className='absolute -top-1 -right-1'>
      <Sprite name={spriteName} width={22} />
    </div>
  );
}

function OrderTicket(props: { order: Order; width: number }): JSX.Element {
  const { order, width } = props;
  const stages = useInstance<TailwindTransitionStage<Classes>[]>(() => {
    return [{ classes: 'opacity-60' }, { classes: 'opacity-100' }];
  });

  return (
    <StagedTailwindTransition stages={stages} debugKey='over-roasted-cup-cap'>
      {(ref, initial) => (
        <div
          ref={ref}
          className={`w-full relative transform transition-all ${initial}`}
        >
          <LayoutAnchor
            id={`over-roasted-order-${order.id}`}
            className='absolute w-full h-full'
          />

          <div
            className={`w-full relative transition-opacity duration-500 ${
              order.matchedMachineId ? 'opacity-60' : ''
            }`}
          >
            <Sprite name='order-ticket-bg' width={width} />
            <OrderIngredients order={props.order} width={width / 2} />
            <div
              className='absolute text-[#676767] font-black italic'
              style={{
                right: order.seqNum >= 10 ? '11px' : '14.5px',
                bottom: '3px',
              }}
            >
              {order.seqNum}
            </div>
          </div>
          <OrderMatchedState order={order} />
        </div>
      )}
    </StagedTailwindTransition>
  );
}

function OrderPlaceholder(props: { width: number }): JSX.Element {
  const computed = useScaledSpriteInfo('order-ticket-bg', props.width);
  return (
    <div
      className='relative border border-white rounded-[7px]'
      style={{
        width: `${computed.sprite.w}px`,
        height: `${computed.sprite.h}px`,
      }}
    ></div>
  );
}

export function OverRoastedOrdersPanel(): JSX.Element {
  const activeTruckId = useOverRoastedActiveTruckId();
  const orders = useOverRoastedTruckOrders(activeTruckId);
  const numOfOrders = useOverRoastedGameSettings().numOfOrders;
  const numOfPlaceholders = Math.max(numOfOrders - orders.length, 0);
  const width = 80;
  const truckTheme = useOverRoastedTruckTheme(activeTruckId);
  const summary = useOverRoastedMyGroupSummary();
  const tutorialProgress = useOverRoastedTutorialProgress();

  return (
    <div
      className='relative rounded-1.5lg flex flex-col items-center bg-lp-black-004'
      style={{
        filter:
          tutorialProgress?.step === TutorialStep.LearnOrder
            ? 'drop-shadow(0px 0px 15px #01ACC4)'
            : undefined,
      }}
    >
      <LayoutAnchor
        id='over-roasted-orders-panel'
        className='w-full h-full absolute'
        layoutReportDelayMs={500}
      />

      <div
        className='rounded-1.5lg p-2 transition-colors flex flex-col items-center gap-1'
        style={{ backgroundColor: truckTheme.colorCode }}
      >
        <div className='text-lg font-bold'>ORDERS</div>
        <div className='grid grid-cols-2 gap-2 content-start flex-grow'>
          {orders.map((o) => (
            <OrderTicket key={o.id} order={o} width={width} />
          ))}
          {Array(numOfPlaceholders)
            .fill(0)
            .map((_, i) => (
              <OrderPlaceholder key={`order-placeholder-${i}`} width={width} />
            ))}
        </div>
      </div>

      <div className='w-full p-2 flex items-center gap-2 text-3xs font-medium'>
        <div className='w-1/2'>Served: {summary?.completedOrders || 0}</div>
        <div className='w-1/2'>Points: {summary?.score || 0}</div>
      </div>
    </div>
  );
}
