import { ref } from 'valtio';

import {
  type Block,
  type BlockAction,
  type BlockRecording,
  type NamedBlockAction,
} from '@lp-lib/game';
import { type Logger } from '@lp-lib/logger-base';

import { type TrackId, type VideoMixer } from '../../VideoMixer';
import { asBlockId } from './asBlockId';
import { type BlockToVideoMixerTrackMap } from './BlockToVideoMixerTrackMap';
import { OndTransitionConfig } from './ondPlaybackConfig';

export type SimplifiedRecordingAction = NamedBlockAction | number;
export type SecondQuantizedRecordingAction = {
  second: number;
  simple: SimplifiedRecordingAction;
  original: BlockAction;
  fractionalShiftMs: number;
  voiceOverDelayStartMs: number;
};
export type SecondQuantizedActionMap = {
  [key: number]: SecondQuantizedRecordingAction[];
};

export class SecondQuantizedActionMapUtils {
  static HasMoreWaitableActions(
    actionMap: SecondQuantizedActionMap,
    time: number
  ): boolean {
    let hasFoundKey = false;
    for (const [k, value] of Object.entries(actionMap)) {
      if (parseInt(k, 10) >= time) {
        hasFoundKey = true;
      }
      if (!hasFoundKey) continue;
      if (value.some((v) => !!v.original.waitMode?.wait)) return true;
    }
    return false;
  }

  static GetTimeForNextActionAfterWaitable(
    actionMap: SecondQuantizedActionMap,
    waitModeSetAtSec: number | undefined | null
  ): number | null {
    let hasFoundKey = false;
    for (const [k] of Object.entries(actionMap)) {
      const seconds = parseInt(k, 10);
      if (seconds === waitModeSetAtSec) {
        hasFoundKey = true;
      }
      if (!hasFoundKey) continue;
      if (hasFoundKey && seconds !== waitModeSetAtSec) return seconds;
    }

    return null;
  }
}

function quantizeRecordingActions(
  behindTimeMs: number,
  correctedRecordingDurationMs: number,
  blockEndingSec: number,
  actions: BlockAction[],
  fractionalShiftActionsEnabled: boolean,
  transitionDuration = OndTransitionConfig.videoDurationHalfMs
): SecondQuantizedActionMap {
  // Group the actions by the second on which they will fall, because the game
  // system was designed to tick at 1hz. However, it's likely that the game
  // system's notion of time is behind that of the continuous VideoMixer/host
  // video playback, so shorten the recording duration and then negatively shift
  // all actions to compensate. This could produce overlapping actions in the
  // same second that will be immediately sequentially executed. This is an
  // inherent problem in general, and exacerbated by the system only ticking at
  // 1hz.
  const endingEdge = correctedRecordingDurationMs - transitionDuration;
  const actionMap = actions.reduce((acc, curr) => {
    const action = curr.action ? curr.action : curr.gameSessionStatus;
    if (action === null || action === undefined) return acc;

    // Shift the actions so that they arrive earlier if the game is behind. Do
    // not let the correction become negative
    const correctedTimestampMs = Math.max(curr.timestamp - behindTimeMs, 0);

    // The game system ticks at 1hz, and for sub-second events such as the end
    // of a block actually schedules a setTimeout using the remainder
    // milliseconds. Any actions beyond the final second would be skipped, so
    // this corrects for that by shifting it to the final second instead.
    if (correctedTimestampMs >= endingEdge) {
      const existing = acc[blockEndingSec] ?? [];
      existing.push({
        second: blockEndingSec,
        simple: action,
        original: curr,
        fractionalShiftMs: correctedTimestampMs - endingEdge,
        voiceOverDelayStartMs: curr.voiceOver?.delayStartMs ?? 0,
      });
      acc[blockEndingSec] = existing;
    } else {
      const key = fractionalShiftActionsEnabled
        ? Math.floor(correctedTimestampMs / 1000)
        : Math.round(correctedTimestampMs / 1000);
      const existing = acc[key] ?? [];
      existing.push({
        second: key,
        simple: action,
        original: curr,
        // `n / 1000 % 1` converts to seconds and leaves only the fractional
        // remainder (e.g. fraction of a second). Then we convert back to whole
        // MS.
        fractionalShiftMs: Math.floor(
          ((correctedTimestampMs / 1000) % 1) * 1000
        ),
        voiceOverDelayStartMs: curr.voiceOver?.delayStartMs ?? 0,
      });
      acc[key] = existing;
    }

    return acc;
  }, {} as { [key: number]: SecondQuantizedRecordingAction[] });

  return actionMap;
}

type QuantizedBlockDuration = {
  blockEndingSec: number;
  blockRemainderMs: number;
};

function quantizeBlockDuration(
  recordingDurationMs: number,
  transitionDuration = OndTransitionConfig.videoDurationHalfMs
): QuantizedBlockDuration {
  // Calcuate accurate ending time based on possible block overlap transition
  // amounts. The playback system allows block recordings to overlap and
  // crossfade, which means the block duration must be artificially shortened
  // because only one block can be active at any time.

  let blockEndingSec = Math.floor(recordingDurationMs / 1000);
  let blockRemainderMs = recordingDurationMs % 1000;
  if (blockRemainderMs < transitionDuration) {
    blockEndingSec -= 1;
    blockRemainderMs = 1000 - (transitionDuration - blockRemainderMs);
  } else {
    blockRemainderMs = blockRemainderMs - transitionDuration;
  }

  return {
    blockEndingSec,
    // Flooring to prevent floating point inequality in useEffect and firebase
    blockRemainderMs: Math.floor(blockRemainderMs),
  };
}

function summarizeActionMap(map: SecondQuantizedActionMap) {
  const lines = [];

  for (const [second, actions] of Object.entries(map)) {
    const thisSecond = actions
      .map((action) => `(${action.simple}, ${action.original.timestamp})`)
      .join(', ');
    lines.push(`${('0000' + second).slice(-4)}: ${thisSecond}`);
  }

  return lines;
}

export function logBlockTickInfo(
  logODG: Logger,
  plan: BlockPlaybackPlan,
  timer: number,
  gameTimer: number,
  videoMixer: VideoMixer
): void {
  const timelineTimeStartMs = plan.extra.blockTimelineTimeStartMs ?? 0;
  const execTimeExpectedMs = timelineTimeStartMs + timer * 1000;
  // If timer=0, this is the start of the block's main timer loop
  const execTimeActualMs = videoMixer.playheadMs;
  const execTimeDeltaMs = execTimeActualMs - execTimeExpectedMs;

  if (timer === 0) {
    // Log action plot only once for debugging

    const plotActions = (map: SecondQuantizedActionMap) => {
      const lines: string[] = [];
      Object.entries(map).forEach(([second, actions]) => {
        const projections = actions.map((action) => {
          // When would the action fire using millisecond precision and perfect
          // start timing? Note: this will always be the same for both corrected
          // and uncorrected
          const perfectMsExecPlayheadMs =
            timelineTimeStartMs + action.original.timestamp;
          // When would the action fire using second precision and perfect start
          // timing? Note: this may differ between corrected and uncorrected
          const perfectSecondExecPlayheadMs =
            timelineTimeStartMs + action.second * 1000;
          // When would the action fire using second precision and the timeline
          // time when gameSessionStore.block.timer=0? Note: this will differ
          // between corrected and uncorrected
          const expectedExecPlayheadMs = Math.floor(
            Math.round(execTimeActualMs + action.second * 1000)
          );

          // What is the effect of quantization on the value, regardless of
          // timing? negative value means it executes earlier
          const perfectQuantizationDeltaMs =
            perfectSecondExecPlayheadMs - perfectMsExecPlayheadMs;

          // How far off from "perfect" do we actually expect
          const expectedDriftMs =
            expectedExecPlayheadMs - perfectMsExecPlayheadMs;

          return `(${[
            `${action.simple}`,
            `${action.original.timestamp}`,
            `pms ${perfectMsExecPlayheadMs}`,
            `ps ${perfectSecondExecPlayheadMs}`,
            `pqd ${perfectQuantizationDeltaMs}`,
            `ex ${expectedExecPlayheadMs}`,
            `exd ${expectedDriftMs}`,
          ].join(', ')})`;
        });

        lines.push(`${('0000' + second).slice(-4)}: ${projections.join(', ')}`);
      });
      return lines;
    };

    logODG.info('block tick actions plot', {
      blockId: plan.blockId,
      blockProgressSec: timer,
      gameTimer,
      vmPlayheadMs: videoMixer.playheadMs.toFixed(4),
      correctedActionPlan: plotActions(plan.extra.correctedActionMap),
      uncorrectedActionMap: plotActions(plan.extra.uncorrectedActionMap),
      execTimeExpectedMs: execTimeExpectedMs.toFixed(4),
      execTimeActualMs: execTimeActualMs.toFixed(4),
      execTimeDeltaMs: execTimeDeltaMs.toFixed(4),
      blockCorrectedBy: plan.blockCorrectedBy.toFixed(4),
    });
  } else {
    logODG.info(`block tick: ${timer}`, {
      blockId: plan.blockId,
      blockProgressSec: timer,
      gameTimer,
      vmPlayheadMs: videoMixer.playheadMs.toFixed(4),
      execTimeExpectedMs: execTimeExpectedMs.toFixed(4),
      execTimeActualMs: execTimeActualMs.toFixed(4),
      execTimeDeltaMs: execTimeDeltaMs.toFixed(4),
      blockCorrectedBy: plan.blockCorrectedBy.toFixed(4),
    });
  }
}

export const createActionExecutedObserver =
  (logODG: Logger, plan: BlockPlaybackPlan, videoMixer: VideoMixer) =>
  (action: SecondQuantizedRecordingAction): void => {
    const expectedVmPlayheadTimeMs =
      (plan.extra.blockTimelineTimeStartMs ?? 0) + action.second * 1000;
    const vmPlayheadMs = videoMixer.playheadMs;
    logODG.info(`execute action ${action.simple}`, {
      action,
      expectedVmPlayheadTimeMs,
      vmPlayheadMs: vmPlayheadMs.toFixed(4),
      deltaVmPlayheadTimeMs: (vmPlayheadMs - expectedVmPlayheadTimeMs).toFixed(
        4
      ),
    });
  };

export type BlockPlaybackPlan = {
  /**
   * Make sure this plan is for the intended block
   * */
  readonly blockId: Block['id'];

  /**
   * The host video track id this plan was created for.
   */
  readonly trackId: TrackId | null;

  /**
   * How much time the block needs to be corrected by (always negative) due to
   * drift/delays between blocks
   */
  readonly blockCorrectedBy: number;

  /**
   * Which 1hz timer value will be the block's final tick
   */
  readonly blockEndingSec: number;
  /**
   * How many ms will remain once the final 1hz tick is executed
   */
  readonly blockRemainderMs: number;

  /**
   * The recorded actions, mapped and quantized to 1hz (either corrected or
   * uncorrected depending on flags)
   */
  readonly actionMap: SecondQuantizedActionMap;

  /**
   * These are for extra information when debugging or logging, and should not
   * be used to derive any timing from.
   */
  readonly extra: {
    /**
   * When the block's recorded host video started playback according to the
     VideoMixer timeline.
  */
    readonly blockTimelineTimeStartMs: number | null;

    /**
     * The recorded actions, mapped to the original block recording duration and
     * quantized to 1hz.
     */
    readonly uncorrectedActionMap: SecondQuantizedActionMap;
    /**
     * The recorded actions, mapped to the corrected block recording duration and
     * quantized to 1hz.
     */
    readonly correctedActionMap: SecondQuantizedActionMap;

    /**
     * The block recording's corrected quantized duration after accounting for
     * game system delays.
     */
    readonly correctedBlockDuration: QuantizedBlockDuration;

    /**
     * The block recording's natural/original quantized duration.
     */
    readonly uncorrectedBlockDuration: QuantizedBlockDuration;

    /**
     * The block recording's corrected duration
     */
    readonly correctedRecordingDurationMs: number;

    /**
     * The block recording's corrected duration
     */
    readonly uncorrectedRecordingDurationMs: number;

    /**
     * How far the block is considered "behind" where it should be.
     */
    readonly behindTimeMs: number;

    /**
     * The game timer "second" this plan was created on. Usually zero, unless
     * the plan was created in repsonse to a refresh / transfer.
     */
    readonly blockProgressSec: number;

    /**
     * Whether this plan's primary values are corrected for desync or not.
     */
    readonly desyncCorrectionEnabled: boolean;
  };
};

/**
 * Create the plan. See BlockPlaybackPlan for a description of what all the
 * values mean. Many are mostly for logging / debugging.
 */
export function createBlockPlaybackPlan(
  initer: BlockPlaybackPlanInit
): BlockPlaybackPlan {
  const {
    blockId,
    ondTrackIds,
    recording,
    videoMixer,
    blockProgressSec,
    desyncCorrectionEnabled,
    fractionalShiftActionsEnabled,
  } = initer;

  // Get the block's currently scheduled host video (single host video) as a
  // source of truth for when the block _should_ have started.
  const trackId =
    ondTrackIds.get(asBlockId(blockId))?.get('primary')?.trackId ?? null;
  const blockTimelineTimeStartMs =
    videoMixer.getTrackActiveTimelineTimeMs(trackId)?.[0] ?? null;
  const vmTrackElapsedTimeMs = videoMixer.getTrackElapsedTimeMs(trackId);

  // We know the scheduled track represents the start of the block, so the
  // distance between now and the scheduled start of the _video_ (past) tells us
  // how delayed block execution is due to async/await+network. If
  // blockProgressSec is 0, this is the start of the block. If it's not 0, that
  // implies the block is somehow resuming or being recreated mid-block (e.g. a
  // cloud controller transfer). If this is the case, assume that everything is
  // on time already.
  const behindTimeMs =
    vmTrackElapsedTimeMs !== null && blockProgressSec === 0
      ? vmTrackElapsedTimeMs
      : 0;

  // Shrink the recording by the behind amount. Later, the actions will be
  // compressed as well.
  const correctedRecordingDurationMs = recording.durationMs - behindTimeMs;
  const uncorrectedRecordingDurationMs = recording.durationMs;

  // quantize the block duration according to transitions
  const correctedBlockDuration = quantizeBlockDuration(
    correctedRecordingDurationMs
  );
  const uncorrectedBlockDuration = quantizeBlockDuration(recording.durationMs);

  // Group (quantize) the actions by the second on which they will fire.
  const correctedActionMap = quantizeRecordingActions(
    behindTimeMs,
    correctedRecordingDurationMs,
    correctedBlockDuration.blockEndingSec,
    recording.actions,
    fractionalShiftActionsEnabled
  );
  const uncorrectedActionMap = quantizeRecordingActions(
    0,
    recording.durationMs,
    uncorrectedBlockDuration.blockEndingSec,
    recording.actions,
    fractionalShiftActionsEnabled
  );

  // Everything is computed, but only use the corrected values if desync
  // correction is enabled.
  const { blockEndingSec, blockRemainderMs } = desyncCorrectionEnabled
    ? correctedBlockDuration
    : uncorrectedBlockDuration;
  const oldBlockRemainderMsCorrection = Math.max(
    Math.floor(blockRemainderMs - behindTimeMs),
    0
  );
  const actionMap = desyncCorrectionEnabled
    ? correctedActionMap
    : uncorrectedActionMap;
  const blockCorrectedBy = desyncCorrectionEnabled ? behindTimeMs : 0;
  return {
    blockId,
    trackId,
    blockCorrectedBy,

    blockEndingSec,
    // Note(drew): The previous version tried to correct for limited desync by
    // removing it from the blockRemainderMs. We've seen it be much larger in
    // practice, hence the `desyncCorrectionEnabled` capability. This correction
    // preserves the old behavior and should be deleted once
    // `desyncCorrectionEnabled` is enabled by default.
    // https://github.com/Narvii/lunapark/commit/b44769657343aba8eb5301f4e2cba3adc40016b0#diff-60acb4c82a4f951a9a87f6f9e125d6b0d4016b828b9a2cf6e8d1c2bf3b89b67eL265-L275
    blockRemainderMs: desyncCorrectionEnabled
      ? blockRemainderMs
      : oldBlockRemainderMsCorrection,
    extra: {
      blockTimelineTimeStartMs:
        blockTimelineTimeStartMs === null && blockProgressSec === 0
          ? videoMixer.playheadMs
          : blockTimelineTimeStartMs,
      uncorrectedActionMap,
      correctedActionMap,
      correctedBlockDuration,
      uncorrectedBlockDuration,
      correctedRecordingDurationMs,
      uncorrectedRecordingDurationMs,
      behindTimeMs,
      blockProgressSec,
      desyncCorrectionEnabled,
    },

    actionMap,
  };
}

export type BlockPlaybackPlanInit = {
  blockId: Block['id'];
  ondTrackIds: BlockToVideoMixerTrackMap;
  recording: BlockRecording;
  videoMixer: VideoMixer;
  blockProgressSec: number;
  desyncCorrectionEnabled: boolean;
  fractionalShiftActionsEnabled: boolean;
};

/**
 * Retrieve or create a plan for the block. If the retreived plan is for a
 * different block a new one will be created.
 */
export function getOrCreateBlockPlaybackPlan(
  store: {
    ondBlockPlaybackPlan: BlockPlaybackPlan | null;
  },
  initer: BlockPlaybackPlanInit
): BlockPlaybackPlan {
  const existingPlan = store.ondBlockPlaybackPlan;
  const plan =
    existingPlan && existingPlan.blockId === initer.blockId
      ? existingPlan
      : createBlockPlaybackPlan(initer);
  store.ondBlockPlaybackPlan = ref(plan);
  return plan;
}

export function logBlockPlaybackPlan(
  timer: number,
  logODG: Logger,
  videoMixer: VideoMixer,
  plan: BlockPlaybackPlan
): void {
  if (timer !== 0) return;
  const { extra, ...primary } = plan;

  const vmTrackElapsedMs = videoMixer.getTrackElapsedTimeMs(plan.trackId);

  logODG.info('starting block', {
    ...primary,
    ...plan.extra,
    vmPlayheadMs: videoMixer.playheadMs.toFixed(4),
    vmTrackElapsedMs,
    actionMap: summarizeActionMap(plan.actionMap),
    uncorrectedActionMap: summarizeActionMap(plan.extra.uncorrectedActionMap),
    correctedActionMap: summarizeActionMap(plan.extra.correctedActionMap),
  });
}
