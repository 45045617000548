import logger from '../../../logger/logger';
import { type FirebaseService, FirebaseValueHandle } from '../../Firebase';
import {
  type PreGameRootState,
  type PreGameSharedState,
  type PreGameStage,
} from './types';

export const log = logger.scoped('pre-game');

export class PreGameFBUtils {
  static Path(venueId: string, kind: 'root' | 'shared'): string {
    if (kind === 'root') return `pre-game/${venueId}`;
    return `pre-game/${venueId}/${kind}`;
  }

  static RootHandle(
    svc: FirebaseService,
    venueId: string
  ): FirebaseValueHandle<PreGameRootState> {
    return new FirebaseValueHandle(
      svc.prefixedSafeRef(this.Path(venueId, 'root'))
    );
  }

  static SharedHandle(
    svc: FirebaseService,
    venueId: string
  ): FirebaseValueHandle<PreGameSharedState> {
    return new FirebaseValueHandle(
      svc.prefixedSafeRef(this.Path(venueId, 'shared'))
    );
  }
}
export function showPreGame(stage: Nullable<PreGameStage>) {
  return stage === 'bootstrap' || stage === 'present';
}
