import { type UID } from 'agora-rtc-sdk-ng';
import { useCallback, useEffect } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';

import { useIsController, useMyInstance } from '../../hooks/useMyInstance';
import { useStatsAwareTaskQueue } from '../../hooks/useTaskQueue';
import { WebRTCUtils } from '../../services/webrtc';
import { ClientTypeUtils } from '../../types/user';
import { err2s } from '../../utils/common';
import { useMyClientType } from '../Venue/VenuePlaygroundProvider';
import { useVenueId } from '../Venue/VenueProvider';
import { useJoinRTCService, useRTCService } from '../WebRTC';
import { useMusicPlayerContext } from './Context';
import { MUSIC_PLAYER_DEFAULT_LIST_ID } from './types';

export const useJoinMusicRTCService = (): boolean => {
  const rtcService = useRTCService('music');
  const venueId = useVenueId();
  const me = useMyInstance();
  const isController = useIsController();
  const taskQueue = useStatsAwareTaskQueue({
    shouldProcess: true,
    stats: 'task-queue-music-rtc-join-ms',
  });
  const isReady = !!me;

  return useJoinRTCService(
    rtcService,
    WebRTCUtils.ChannelFor('music', venueId),
    isController ? 'host' : 'audience',
    taskQueue,
    {
      subscribeEvents: true,
      ready: isReady,
    }
  );
};

export const usePublishMusicRTC = (isPlaying: boolean): void => {
  const rtcService = useRTCService('music');
  const isController = useIsController();

  useEffect(() => {
    if (!isController || !isPlaying) {
      return;
    }

    rtcService.publishAudio().catch((err) => {
      rtcService.log.error('publish failed', err2s(err));
    });

    return () => {
      rtcService.unpublish().catch((err) => {
        rtcService.log.error('unpublish failed', err2s(err));
      });
    };
  }, [isPlaying, rtcService, isController]);
};

export const useSubscribeMusicRTC = (): void => {
  const rtcService = useRTCService('music');
  const isController = useIsController();

  const publishedCallback = useCallback(
    async (uid: UID, mediaType: 'audio' | 'video') => {
      if (mediaType === 'audio') {
        rtcService.playAudio(uid);
      }
    },
    [rtcService]
  );

  const unpublishedCallback = useCallback(
    (uid: UID, mediaType: 'audio' | 'video') => {
      if (mediaType === 'audio') {
        rtcService.stopAudio(uid);
      }
    },
    [rtcService]
  );

  useEffect(() => {
    if (isController) {
      return;
    }

    rtcService.on('remote-user-published', publishedCallback);
    rtcService.on('remote-user-unpublished', unpublishedCallback);

    return () => {
      rtcService.off('remote-user-published', publishedCallback);
      rtcService.off('remote-user-unpublished', unpublishedCallback);
    };
  }, [isController, publishedCallback, rtcService, unpublishedCallback]);
};

export const useMusicPlayerHotKeys = (): void => {
  const isHost = ClientTypeUtils.isHost(useMyClientType());
  const { setPlaylistById, play, pause, playlist } = useMusicPlayerContext();

  const handlePlay = useCallback(async () => {
    if (!playlist) {
      await setPlaylistById(MUSIC_PLAYER_DEFAULT_LIST_ID, true);
      return;
    }
    play('hotkey');
  }, [play, playlist, setPlaylistById]);

  const handlePause = useCallback(() => {
    pause('hotkey');
  }, [pause]);

  useHotkeys(
    'ctrl+shift+,, ctrl+shift+m',
    (event) => {
      event.preventDefault();
      handlePlay();
    },
    {
      enabled: isHost,
    },
    [handlePlay]
  );

  useHotkeys(
    'ctrl+shift+k',
    (event) => {
      event.preventDefault();
      handlePause();
    },
    {
      enabled: isHost,
    },
    [handlePause]
  );
};
