/**
 * Runs `callback` shortly after the next browser Frame is produced.
 */
export function runAfterFramePaint(callback: () => void) {
  const aborter = new AbortController();

  if (document.visibilityState !== 'visible') {
    aborter.abort();
    return noop;
  }

  // Queue a "before Render Steps" callback via requestAnimationFrame.
  const id = requestAnimationFrame(() => {
    if (typeof MessageChannel === 'undefined') {
      callback();
      return;
    }
    const messageChannel = new MessageChannel();

    // Setup the callback to run in a Task
    messageChannel.port1.onmessage = () => {
      if (aborter.signal.aborted) return;
      callback();
    };

    // Queue the Task on the Task Queue
    messageChannel.port2.postMessage(undefined);
  });

  return () => {
    cancelAnimationFrame(id);
    aborter.abort();
  };
}

const noop = () => void 0;
