import capitalize from 'lodash/capitalize';
import { useMemo } from 'react';

import { LayoutAnchor } from '../../../LayoutAnchors/LayoutAnchors';
import { useParticipantByUserId } from '../../../Player';
import { useUser } from '../../../UserContext';
import { useOverRoastedGroupPropBags } from './OverRoastedProvider';
import { SpriteButton, type SpriteButtonProps } from './Sprite';
import { type Ingredient, type PropBag } from './types';

function useIngredientButtonSprites(
  ingredient: Ingredient
): Pick<SpriteButtonProps, 'default' | 'hover' | 'clicked'> {
  return {
    default: `ingredient-${ingredient}-default`,
    hover: `ingredient-${ingredient}-hover`,
    clicked: `ingredient-${ingredient}-clicked`,
  };
}

function IngredientButton(props: {
  ingredient: Ingredient;
  isSmallSize: boolean;
  disabled?: boolean;
  onClick: (ingredient: Ingredient) => Promise<void>;
}): JSX.Element {
  const { ingredient, disabled, isSmallSize, onClick } = props;

  const sprites = useIngredientButtonSprites(ingredient);
  const showLabel = !isSmallSize;
  const label = useMemo(
    () =>
      ingredient
        .split('-')
        .map((e) => capitalize(e))
        .join(' '),
    [ingredient]
  );
  const width = !isSmallSize ? 64 : 35;

  return (
    <div className='flex flex-col items-center relative'>
      {showLabel && (
        <div className='text-2xs font-medium text-black'>{label}</div>
      )}
      <SpriteButton
        {...sprites}
        width={width}
        disabled={disabled}
        onClick={() => onClick(ingredient)}
      />
    </div>
  );
}

function PlayerBag(props: {
  bag: PropBag;
  isSmallSize: boolean;
  onClickIngredient: (ingredient: Ingredient) => Promise<void>;
}): JSX.Element {
  const { bag, isSmallSize, onClickIngredient } = props;
  const user = useUser();
  const p = useParticipantByUserId(bag.playerId, true);
  const disabled = !!p && p.id !== user.id;

  return (
    <div
      className={`relative bg-[#F5F3EB] rounded-2.5xl flex flex-col ${
        isSmallSize ? 'min-w-25' : 'min-w-45'
      } ${disabled ? 'opacity-60' : ''}`}
    >
      <LayoutAnchor
        id={`over-roasted-bag-${bag.playerId}`}
        className='absolute w-full h-full'
        layoutReportDelayMs={500}
      />

      <div
        className={`flex justify-center items-center ${
          isSmallSize ? 'gap-1 pt-1.5 pb-1 px-3' : 'gap-2 px-6 py-2'
        }`}
      >
        {bag.ingredients.map((i) => (
          <IngredientButton
            key={i}
            ingredient={i}
            isSmallSize={isSmallSize}
            disabled={disabled}
            onClick={onClickIngredient}
          />
        ))}
      </div>
      <div
        className={`${
          isSmallSize ? 'h-4' : 'h-8'
        } bg-lp-black-005 rounded-b-2.5xl flex items-center justify-center`}
      >
        <p
          className={`${
            isSmallSize ? 'max-w-20 text-2xs' : 'max-w-40 text-sms'
          } text-black font-bold truncate`}
        >
          {p?.username ?? 'Everyone'}
        </p>
      </div>
    </div>
  );
}

export function OverRoastedBagsPanel(props: {
  onClickIngredient: (ingredient: Ingredient) => Promise<void>;
}): JSX.Element | null {
  const { onClickIngredient } = props;

  const bags = useOverRoastedGroupPropBags();

  if (bags.length === 0) return null;

  if (bags.length <= 4) {
    return (
      <div className='flex items-center justify-center gap-2.5'>
        {bags.map((b) => (
          <PlayerBag
            key={b.playerId}
            bag={b}
            isSmallSize={false}
            onClickIngredient={onClickIngredient}
          />
        ))}
      </div>
    );
  }

  const myBag = bags?.[0];
  const otherBags = bags.slice(1);

  return (
    <div className='flex items-center justify-center gap-2.5'>
      <PlayerBag
        bag={myBag}
        isSmallSize={false}
        onClickIngredient={onClickIngredient}
      />

      <div className='grid grid-cols-3 gap-2'>
        {otherBags.map((b) => (
          <PlayerBag
            key={b.playerId}
            bag={b}
            isSmallSize={bags.length > 4}
            onClickIngredient={onClickIngredient}
          />
        ))}
      </div>
    </div>
  );
}
