import { useMemo } from 'react';
import { type SingleValue } from 'react-select';
import Select from 'react-select';
import useSWRImmutable from 'swr/immutable';

import { type ModelsHeyGenTemplate } from '@lp-lib/api-service-client/public';

import { apiService } from '../../services/api-service';
import { err2s } from '../../utils/common';
import { buildReactSelectStyles } from '../../utils/react-select';
import { type Option } from '../common/Utilities';
import { Loading } from '../Loading';

function useTemplates() {
  return useSWRImmutable(
    '/heygen/templates',
    async () =>
      (await apiService.tts.listHeyGenTemplates()).data.templates || []
  );
}

function useTemplate(templateId: string | null) {
  const { data: templates } = useTemplates();
  return templateId
    ? templates?.find((t) => t.template_id === templateId)
    : null;
}

function useTemplateVariables(templateId: string | null) {
  return useSWRImmutable(
    templateId
      ? [templateId, `/heygen/templates/${templateId}/variables`]
      : null,
    async ([id]) =>
      (await apiService.tts.getHeyGenTemplateVariables(id)).data.variables
  );
}

export function HeyGenTemplateSelect(props: {
  templateId: string | null;
  onChange: (template: ModelsHeyGenTemplate) => void;
}) {
  const styles = useMemo(
    () =>
      buildReactSelectStyles<Option<ModelsHeyGenTemplate | null>>({
        override: {
          control: {
            height: '40px',
          },
        },
      }),
    []
  );
  const { data: templates, isLoading, error } = useTemplates();

  const options = useMemo(() => {
    if (!templates) return [];

    const opts: Option<ModelsHeyGenTemplate | null>[] = templates
      .sort((a, b) => a.name.localeCompare(b.name))
      .map((t) => ({
        label: t.name,
        value: t,
      }));
    return opts;
  }, [templates]);

  const handleTemplateChange = (
    option: SingleValue<Option<ModelsHeyGenTemplate | null>>
  ) => {
    if (!option?.value) return;
    props.onChange(option.value);
  };

  if (isLoading) {
    return <Loading containerClassName='justify-start' />;
  }

  if (error) {
    return <div className='text-3xs text-red-002'>{err2s(error)}</div>;
  }

  return (
    <Select<Option<ModelsHeyGenTemplate | null>>
      className='w-full'
      classNamePrefix='select-box-v2'
      options={options}
      onChange={handleTemplateChange}
      styles={styles}
      formatOptionLabel={(opt) => (
        <span className='text-white'>{opt.label}</span>
      )}
      value={
        options.find((o) => o.value?.template_id === props.templateId) ?? null
      }
      placeholder='Select Template'
    />
  );
}

export function HeyGenTemplatePreview(props: { templateId: string | null }) {
  const template = useTemplate(props.templateId);
  const { data: variables, isLoading: isVariablesLoading } =
    useTemplateVariables(props.templateId);

  if (!template || isVariablesLoading) return null;

  const audioVariable = variables?.['audio'];

  return (
    <div className='flex flex-col gap-2'>
      <img
        src={template.thumbnail_image_url}
        className={'w-full object-cover rounded-lg border border-secondary'}
        style={{ aspectRatio: '16 / 9' }}
        alt={template.name}
      />
      <div className='text-sms text-secondary'>
        Variables: {Object.keys(variables || {}).join(', ')}
      </div>
      {audioVariable?.type !== 'audio' && (
        <div className={'text-2xs text-red-002'}>
          "audio" variable is required for this template
        </div>
      )}
    </div>
  );
}
