import { useLocation } from '@remix-run/react';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { usePrevious } from 'react-use';
import { proxy, useSnapshot } from 'valtio';
import { devtools } from 'valtio/utils';

import {
  type Block,
  type GameSessionStatus,
  GameSessionUtil,
  type NamedBlockAction,
} from '@lp-lib/game';

import { getFeatureQueryParamArray } from '../../hooks/useFeatureQueryParam';
import { useInstance } from '../../hooks/useInstance';
import { SessionMode } from '../../types';
import { type Game } from '../../types/game';
import { assertExhaustive } from '../../utils/common';
import { TimeUtils } from '../../utils/time';
import { ValtioUtils } from '../../utils/valtio';
import {
  BlockControllerActionButton,
  BlockControllerActionNone,
} from '../Game/Blocks/Common/Controller';
import { BlockKnifeUtils } from '../Game/Blocks/Shared';
import {
  useLocalDerivedBlockRecorderVersion,
  useLocalLoadedGameLike,
  useRefreshLocalBlocksForGamePlay,
} from '../Game/GamePlayStore';
import { useIsGameSessionInited } from '../Game/hooks';
import { updateIsRecording } from '../Game/store';
import { RecordIcon } from '../icons/RecordIcon';
import { FilledSquareIcon } from '../icons/SquareIcon';
import { useIsStreamSessionAlive } from '../Session';
import { useLocalVideoEffectsSettings } from '../VideoEffectsSettings/Storage';
import { BlockRecordingUtils } from './BlockRecordingUtils';
import {
  useBlockRecorderUploadForBlock,
  useEnqueueUploads,
  useManageUploadResults,
} from './hooks/uploads';
import { useCanUseGameRecordMode } from './hooks/useCanUseGameRecordMode';
import { useGrabHostMediaStream } from './hooks/useGrabHostMediaStream';
import { IDBChunkedStreamRecorder } from './IDBChunkedStreamRecorder';
import {
  blockRecorderContext,
  initializeState,
  resetAutoAdvanceRecordingState,
  resetRecordingState,
  useBlockRecorderState,
  useBlockRecorderStateGetter,
  useResetRecordingState,
} from './state';
import {
  BlockActionDataUtils,
  type BlockRecorderState,
  BlockRecordingVideoDataUtils,
  isGameRecordLocationState,
  RecordingState,
} from './types';

export const BlockRecorderProvider = (props: {
  configAPIBaseURL: string;
  /**
   * optionally defines a pinned version for the recorder. exposed for testing.
   */
  pinnedRecorderVersion?: number;
  children?: React.ReactChild;
}): JSX.Element => {
  const location = useLocation();
  const canToggleMode = useCanUseGameRecordMode();
  // Note(falcon): moving this hook into this provider to avoid excessive rerendering. When this is used in the venue
  // and the settings passed as props into this provider, it causes a ton of rerendering. See:
  // https://github.com/pmndrs/valtio/wiki/Some-gotchas#usesnapshotstate-without-property-access-will-always-trigger-re-render
  const videoEffectsSettings = useLocalVideoEffectsSettings();

  const state = useInstance(() =>
    proxy<BlockRecorderState>(
      initializeState(props.configAPIBaseURL, props.pinnedRecorderVersion)
    )
  );

  useEffect(() => {
    return devtools(state, { name: 'BlockRecorderState' });
  }, [state]);

  // Allow toggling Record Mode -> Primed from another part of the app
  if (
    isGameRecordLocationState(location.state) &&
    location.state.recordMode !== undefined
  ) {
    switch (state.recording.state) {
      case RecordingState.Disabled: {
        if (canToggleMode) {
          state.recording.state = location.state.recordMode
            ? RecordingState.Primed
            : RecordingState.Disabled;
        }
        break;
      }

      case RecordingState.CountIn:
      case RecordingState.Ended:
      case RecordingState.Primed:
      case RecordingState.Recording: {
        break;
      }

      default: {
        assertExhaustive(state.recording.state);
        break;
      }
    }
  }

  useGrabHostMediaStream(state);
  // refresh blocks in GamePlayStore
  const reloadBlocks = useRefreshLocalBlocksForGamePlay();
  useEnqueueUploads(state, videoEffectsSettings, reloadBlocks);
  useManageUploadResults(state);
  useWatchRecorderVersion(state, props.pinnedRecorderVersion);

  const session = useIsStreamSessionAlive();
  const gameSessionInited = useIsGameSessionInited();

  // TODO: write a test for this
  useEffect(() => {
    if (!gameSessionInited) return;
    if (!session) {
      resetRecordingState(state, canToggleMode);
      resetAutoAdvanceRecordingState(state);
    }
  }, [canToggleMode, gameSessionInited, session, state]);

  return (
    <blockRecorderContext.Provider value={state}>
      {props.children}
    </blockRecorderContext.Provider>
  );
};

function filenameFor(block: Block, recordStartTimestamp: number) {
  return `rec-${recordStartTimestamp}-block-${block.id}`;
}

const useWatchRecorderVersion = (
  state: BlockRecorderState,
  /**
   * optionally defines a pinned version for the recorder. exposed for testing.
   */
  pinnedRecorderVersion?: number
) => {
  const loadedGameLike = useLocalLoadedGameLike();
  const derivedBlockRecorderVersion = useLocalDerivedBlockRecorderVersion(
    SessionMode.Live
  );
  const previouslyLoadedId = usePrevious(loadedGameLike?.id);
  const loadedId = loadedGameLike?.id;

  useEffect(() => {
    if (pinnedRecorderVersion) {
      state.recorderVersion = pinnedRecorderVersion;
      return;
    }

    if (loadedId && previouslyLoadedId !== loadedId) {
      state.recorderVersion = derivedBlockRecorderVersion;
    }
  }, [
    pinnedRecorderVersion,
    previouslyLoadedId,
    loadedId,
    derivedBlockRecorderVersion,
    state,
  ]);
};

export const useStartRecordingBlock = (): ((
  block: Block,
  gameId: Game['id']
) => void) => {
  const getState = useBlockRecorderStateGetter();

  const [initialCountInDurationMs] = useState(() => {
    const raw = getFeatureQueryParamArray('block-recorder-count-in-ms');
    if (raw === 'disabled') return null;
    const parsed = parseInt(raw, 10);
    return isNaN(parsed) ? 3000 : parsed;
  });

  return useCallback(
    async (block: Block, gameId: Game['id']) => {
      const state = getState();

      if (state.recording.state !== RecordingState.Primed || !block.id) return;

      const { recording } = state;

      if (recording.countInRef) clearInterval(recording.countInRef);
      recording.state = RecordingState.CountIn;
      await updateIsRecording(true);

      const nextCountIn = (decrement = true) => {
        if (recording.countIn === null) return;

        if (decrement) recording.countIn -= 1;

        if (recording.countIn === 0) {
          const start = Date.now();
          state.hasManuallyStartedRecordingOnce = true;
          recording.startTimestampMs = start;
          recording.data = BlockRecordingUtils.Blank(
            block,
            gameId,
            state.recorderVersion
          );
          recording.state = RecordingState.Recording;

          if (state.refs.hostVideoStream) {
            state.refs.hostVideoFileHandle = new IDBChunkedStreamRecorder(
              state.refs.hostVideoStream,
              filenameFor(block, start)
            );
          }

          // Setup elapsed interval
          if (recording.elapsedSecondsRef)
            clearInterval(recording.elapsedSecondsRef);
          recording.elapsedSecondsRef = setInterval(() => {
            if (recording.startTimestampMs === null) return;
            const now = Date.now();
            const deltaMs = now - recording.startTimestampMs;
            const deltaSeconds = Math.floor(deltaMs / 1000);

            if (recording.elapsedSeconds !== deltaSeconds)
              recording.elapsedSeconds = deltaSeconds;
          }, 1000);
        }

        if (recording.countIn === -1) {
          countInCleanup();
        }
      };

      const countInCleanup = () => {
        recording.countIn = null;
        if (recording.countInRef) clearInterval(recording.countInRef);
        recording.countInRef = null;
      };

      if (initialCountInDurationMs === null) {
        // enable fast mode with no count-in
        recording.countIn = 0;
        nextCountIn(false);
        countInCleanup();
      } else {
        // use count-in
        recording.countIn = initialCountInDurationMs / 1000;
        recording.countInRef = setInterval(nextCountIn, 1000);
      }
    },
    [getState, initialCountInDurationMs]
  );
};

export const useStopRecordingBlock = (): ((
  block: Block | null,
  isCancel: boolean
) => Promise<void>) => {
  const getState = useBlockRecorderStateGetter();
  const reset = useResetRecordingState();

  return useCallback(
    async (block, isCancel) => {
      const state = getState();

      if (isCancel) {
        resetAutoAdvanceRecordingState(state);
        reset();
        return;
      }

      if (
        state.recording.state === RecordingState.Ended ||
        !state.recording.startTimestampMs ||
        !state.recording.data
      ) {
        reset();
        return;
      }

      if (!block) return;

      state.recording.state = RecordingState.Ended;

      const end = Date.now();
      const durationMs = end - state.recording.startTimestampMs;
      state.recording.data.durationMs = durationMs;

      // stop and flush data
      // TODO: how long will this take and does the UI need a spinner?
      const filename = state.refs.hostVideoFileHandle?.filename;
      await state.refs.hostVideoFileHandle?.stop();

      state.uploads[block.id] = {
        actions: {
          kind: 'actions',
          status: 'none',
          // detatch from valtio
          data: ValtioUtils.detachCopy(state.recording.data),
        },
        hostVideo: filename
          ? {
              kind: 'host-video',
              status: 'none',
              progress: null,
              data: BlockRecordingVideoDataUtils.From(block.id, filename, null),
            }
          : null,
      };

      state.refs.hostVideoFileHandle = null;
      reset();
    },
    [getState, reset]
  );
};

export const useMarkNamedBlockRecorderAction = (): ((
  action: NamedBlockAction
) => void) => {
  const get = useBlockRecorderStateGetter();

  return useCallback(
    (action) => {
      const state = get();
      const {
        recording: { startTimestampMs },
      } = state;

      if (
        state.recording.state !== RecordingState.Recording ||
        startTimestampMs === null
      )
        return;

      state.recording.data?.actions.push(
        BlockActionDataUtils.ForNamedAction({
          startTimestampMs,
          action,
        })
      );
    },
    [get]
  );
};

export const useRecordGameSessionStatusChanges = (
  gameSessionStatus: GameSessionStatus | undefined
): void => {
  const state = useBlockRecorderState();
  const gameRecordingModeIsRecording = useIsGameRecordModeRecording();
  const {
    recording: { startTimestampMs },
  } = state;

  const prevSessionStatus = useRef<GameSessionStatus | undefined>(undefined);

  useEffect(() => {
    if (
      gameRecordingModeIsRecording &&
      prevSessionStatus.current !== gameSessionStatus &&
      gameSessionStatus !== undefined &&
      startTimestampMs !== null
    ) {
      const action = BlockActionDataUtils.ForGameSessionStatus({
        startTimestampMs,
        gameSessionStatus,
      });

      if (action) state.recording.data?.actions.push(action);
    }

    if (gameRecordingModeIsRecording) {
      prevSessionStatus.current = gameSessionStatus;
    } else {
      prevSessionStatus.current = undefined;
    }
  }, [
    gameRecordingModeIsRecording,
    gameSessionStatus,
    prevSessionStatus,
    startTimestampMs,
    state.recording.data?.actions,
  ]);
};

export function useElapsedRecordingDurationFormatted(): string {
  const { elapsedSeconds } = useSnapshot(useBlockRecorderState()).recording;
  const elapsedMs =
    elapsedSeconds === null ? elapsedSeconds : elapsedSeconds * 1000;
  return TimeUtils.DurationFormattedHHMMSS(elapsedMs, false);
}

// Whether the record toggle is "active" and primed, running, etc. Just not "disabled".
export const useIsGameRecordModeActive = (): boolean => {
  const { state } = useSnapshot(useBlockRecorderState()).recording;
  return state !== RecordingState.Disabled;
};

// Are we considered "Recording" visually? Includes count-in
export const useIsGameRecordModeRunning = (): boolean => {
  const { state } = useSnapshot(useBlockRecorderState()).recording;
  return state === RecordingState.Recording || state === RecordingState.CountIn;
};

// Are we actively recording state changes?
export const useIsGameRecordModeRecording = (): boolean => {
  const { state } = useSnapshot(useBlockRecorderState()).recording;
  return state === RecordingState.Recording;
};

export const useIsGameRecordAutoAdvance = (): boolean => {
  return useSnapshot(useBlockRecorderState()).autoAdvance;
};

export const useHasManuallyStartedRecordingOnce = (): boolean => {
  return useSnapshot(useBlockRecorderState()).hasManuallyStartedRecordingOnce;
};

export const useManageAutoAdvanceRecording = (
  selectedBlock: null | Block
): void => {
  const autoAdvance = useIsGameRecordAutoAdvance();
  const manualOnce = useHasManuallyStartedRecordingOnce();
  const getState = useBlockRecorderStateGetter();
  const recorderVersion = useBlockRecorderVersion();
  const startRecording = useStartRecordingBlock();
  const isWriteable = useIsGameRecordModeActive();
  const hasUpload = useBlockRecorderUploadForBlock(selectedBlock);
  const { state } = useSnapshot(useBlockRecorderState()).recording;

  useEffect(() => {
    if (!autoAdvance) return;

    if (selectedBlock && manualOnce && !hasUpload) {
      if (selectedBlock.recording) {
        // Stop and reset if a recording is already present.
        resetAutoAdvanceRecordingState(getState());
      } else if (
        !BlockKnifeUtils.IsRecordable(selectedBlock, recorderVersion) ||
        !isWriteable
      ) {
        // Next block is not recordable, reset auto-advance state.
        resetAutoAdvanceRecordingState(getState());
      } else if (state === RecordingState.Primed) {
        // Current block does not already have an upload in progress, ok to
        // auto-start. It's necessary to have the check for `Primed` so that
        // this hook rerenders when the previous recording finishes and is
        // elegible for the next.
        startRecording(selectedBlock, selectedBlock?.gameId);
      }
    }
  }, [
    autoAdvance,
    getState,
    hasUpload,
    isWriteable,
    manualOnce,
    recorderVersion,
    selectedBlock,
    startRecording,
    state,
  ]);

  // No more blocks, reset autoadvance state
  useEffect(() => {
    if (manualOnce && selectedBlock === null) {
      resetAutoAdvanceRecordingState(getState());
    }
  }, [getState, manualOnce, selectedBlock]);
};

export const useShouldShowStartRecordingButtonForBlock = (
  block: Block | null,
  gameSessionStatus: GameSessionStatus | null | undefined,
  sessionIsLive: boolean
): boolean => {
  const isWriteable = useIsGameRecordModeActive();
  const recorderVersion = useBlockRecorderVersion();
  const { state } = useSnapshot(useBlockRecorderState().recording);

  if (
    !block ||
    !BlockKnifeUtils.IsRecordable(block, recorderVersion) ||
    !isWriteable
  )
    return false;

  const map = GameSessionUtil.StatusMapFor(block);

  if (!map) return false;

  switch (state) {
    case RecordingState.CountIn:
    case RecordingState.Primed: {
      return sessionIsLive && gameSessionStatus === map.loaded;
    }

    case RecordingState.Disabled:
    case RecordingState.Ended:
    case RecordingState.Recording: {
      return false;
    }

    default: {
      assertExhaustive(state);
      return false;
    }
  }
};

export const useShouldShowEndRecordingButtonForBlock = (
  block: Block | null,
  gameSessionStatus: GameSessionStatus | null | undefined,
  sessionIsLive: boolean
): boolean => {
  const isWriteable = useIsGameRecordModeActive();
  const recorderVersion = useBlockRecorderVersion();
  const { state } = useSnapshot(useBlockRecorderState().recording);

  if (
    !block ||
    !BlockKnifeUtils.IsRecordable(block, recorderVersion) ||
    !isWriteable
  )
    return false;

  const map = GameSessionUtil.StatusMapFor(block);

  if (!map) return false;

  switch (state) {
    case RecordingState.Recording: {
      return sessionIsLive && map.end === gameSessionStatus;
    }

    case RecordingState.Disabled:
    case RecordingState.Ended:
    case RecordingState.Primed:
    case RecordingState.CountIn: {
      return false;
    }

    default: {
      assertExhaustive(state);
      return false;
    }
  }
};

export const useToggleGameRecordMode = (): (() => void) => {
  const canUseRecordMode = useCanUseGameRecordMode();
  const getState = useBlockRecorderStateGetter();
  const stopRecording = useStopRecordingBlock();

  return useCallback(() => {
    const state = getState();
    switch (state.recording.state) {
      case RecordingState.Disabled: {
        state.recording.state = canUseRecordMode
          ? RecordingState.Primed
          : state.recording.state;
        updateIsRecording(canUseRecordMode);
        break;
      }

      case RecordingState.CountIn:
      case RecordingState.Ended:
      case RecordingState.Primed:
      case RecordingState.Recording: {
        state.recording.state = RecordingState.Disabled;
        updateIsRecording(false);
        stopRecording(null, true);
        break;
      }

      default: {
        assertExhaustive(state.recording.state);
      }
    }
  }, [canUseRecordMode, getState, stopRecording]);
};

export const useBlockRecorderVersion = (): number => {
  const snapshot = useSnapshot(useBlockRecorderState());
  return snapshot.recorderVersion;
};

export const useSetBlockRecorderVersion = (): ((version: number) => void) => {
  const get = useBlockRecorderStateGetter();
  return useCallback(
    (version) => {
      const state = get();
      state.recorderVersion = version;
    },
    [get]
  );
};

export const GameControllerStartRecordingButton = (props: {
  gameSessionStatus: GameSessionStatus | null | undefined;
  onClick: () => void;
}): null | JSX.Element => {
  const state = useSnapshot(useBlockRecorderState());

  let desc: {
    text: string;
    icon: (props: { className: string }) => JSX.Element;
    waiting: boolean;
  } | null = null;

  switch (state.recording.state) {
    case RecordingState.Primed: {
      desc = {
        text: 'Start Recording',
        waiting: false,
        icon: (props) => <RecordIcon flat className={props.className} />,
      };
      break;
    }

    case RecordingState.CountIn: {
      desc = {
        text: `Recording starts in ${state.recording.countIn}...`,
        waiting: true,
        icon: (props) => <RecordIcon className={props.className} />,
      };
      break;
    }
  }

  return !desc ? null : desc.waiting ? (
    <BlockControllerActionNone icon={desc.icon}>
      {desc.text}
    </BlockControllerActionNone>
  ) : (
    <BlockControllerActionButton
      isDelete={true}
      icon={desc.icon}
      onClick={props.onClick}
    >
      {desc.text}
    </BlockControllerActionButton>
  );
};

export const GameControllerEndRecordingButton = (props: {
  block: Block;
  canRecordNext: boolean;
  gameSessionStatus: GameSessionStatus | null | undefined;
  onRecordingEnd: () => void;
}): null | JSX.Element => {
  const state = useSnapshot(useBlockRecorderState());
  const stop = useStopRecordingBlock();

  let desc: {
    text: string;
    icon: (props: { className: string }) => JSX.Element;
    waiting: boolean;
  } | null = null;

  switch (state.recording.state) {
    case RecordingState.Recording: {
      desc = {
        text:
          state.autoAdvance && props.canRecordNext
            ? 'End Recording and Start Next'
            : 'End Recording',
        waiting: false,
        icon: (props) => <FilledSquareIcon className={props.className} />,
      };
      break;
    }
  }

  return !desc ? null : (
    <BlockControllerActionButton
      isDelete={true}
      icon={desc.icon}
      onClick={() => {
        stop(props.block, false);
        props.onRecordingEnd();
      }}
      testId={'controller-action-end-recording'}
    >
      {desc.text}
    </BlockControllerActionButton>
  );
};

export function BlockRecordingFullScreenCountIn(props: {
  zIndex: `z-${number}`;
}): JSX.Element | null {
  const { countIn } = useSnapshot(useBlockRecorderState()).recording;

  return countIn === null || countIn === 0 ? null : (
    <div
      className={`
        ${props.zIndex}
        absolute w-full h-full gap-4
        bg-lp-black-001
        flex flex-col items-center justify-center
    `}
    >
      <header className='text-white text-xl font-bold'>
        Recording starts in
      </header>
      <span className='text-red-002 text-6xl font-bold h-52'>{countIn}</span>
    </div>
  );
}
