import debounce from 'lodash/debounce';
import throttle from 'lodash/throttle';
import {
  createContext,
  type ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react';
import { proxy } from 'valtio';

import {
  getFeatureQueryParam,
  getFeatureQueryParamNumber,
} from '../../../../hooks/useFeatureQueryParam';
import { markSnapshottable, useSnapshot } from '../../../../utils/valtio';

class GifSyncer {
  private enqueuedSyncElements = new Set<HTMLImageElement>();
  private ext = proxy({
    syncNum: 0,
    enabled: true,
    buster: this.enableCacheBuster ? Date.now() : 0,
  });

  constructor(
    private syncDebounceMs: number,
    private enableCacheBuster: boolean
  ) {}

  reset() {
    this.ext.syncNum = Date.now();
    this.ext.enabled = true;
    this.ext.buster = this.enableCacheBuster ? Date.now() : 0;
    this.enqueuedSyncElements.clear();
  }

  async manageAutoDisable(url: string) {
    // Any non-gif usage disables the syncer until it is reset.
    if (!this.ext.enabled) return;
    if (url.indexOf('.gif') === -1) {
      this.ext.enabled = false;
      return;
    }
  }

  /**
   * Notify all GIF image elements to register themselves for a synchronization.
   * This is decounced to avoid excessive restarting of the GIFs.
   */
  triggerSyncRegistration = throttle(
    () => {
      this.ext.syncNum += 1;
    },
    this.syncDebounceMs,
    { trailing: true }
  );

  /**
   * Perform GIF synchronization with the known, registered GIFS. This is
   * debounced to ensure all the GIFs are registered before syncing. The
   * technique to "sync" a GIF is to trigger a reload of the GIF. This can be
   * done by one of the following:
   * - changing the src: `img.src = img.src`
   * - removing the element and re-adding it
   */
  private elementSync = debounce(
    () => {
      if (this.enqueuedSyncElements.size === 1) return;

      for (const img of this.enqueuedSyncElements) {
        const src = img.src;
        img.src = '';
        img.src = src;
      }

      this.enqueuedSyncElements.clear();
    },
    // There needs to be time for React to have rendered its layoutEffects and
    // therefore to have registered all the image elements in one tick. As long
    // as the effects are synchronous, 0 (aka next tick) should be enough.
    0,
    { trailing: true }
  );

  /**
   * Enqueue the element for synchronization. This should only happen when the
   * syncId changes.
   */
  enqueueSync = (img: HTMLImageElement) => {
    this.enqueuedSyncElements.add(img);
    this.elementSync();
  };

  listenable() {
    return markSnapshottable(this.ext);
  }
}

const context = createContext<GifSyncer | null>(null);

function useGFSContext() {
  const syncer = useContext(context);
  if (!syncer) throw new Error('No GIFSyncer found');
  return syncer;
}

export function GifSyncerProvider({ children }: { children: ReactNode }) {
  const [m] = useState(
    () =>
      new GifSyncer(
        getFeatureQueryParamNumber('puzzle-gif-sync-throttle-ms'),
        getFeatureQueryParam('puzzle-gif-sync-cache-buster')
      )
  );
  return <context.Provider value={m}>{children}</context.Provider>;
}

export function useSyncedGIF(url: string) {
  const syncer = useGFSContext();
  const snap = useSnapshot(syncer.listenable());
  syncer.manageAutoDisable(url);
  const syncId = `${snap.syncNum}-${url}`;

  let bustedUrl = url;

  if (bustedUrl) {
    const buster = new URL(url);
    buster.searchParams.set('b', snap.buster.toString());
    bustedUrl = buster.toString();
  }

  return {
    url: bustedUrl,
    syncId,
    enqueueSync: syncer.enqueueSync,
  };
}

/**
 * Notify the GIFSyncer that a synchronization is likely needed.
 */
export function useSyncedGIFSyncTrigger() {
  const syncer = useContext(context);
  if (!syncer) throw new Error('No GIFSyncer found');
  return syncer.triggerSyncRegistration;
}

/**
 *
 * @param uniqueId Force a reset when a known unique
 * entity changes, likely the block id.
 */
export function useSyncedGIFLifecycleOwner(uniqueId: string) {
  const syncer = useGFSContext();
  useEffect(() => {
    return () => syncer?.reset();
  }, [syncer, uniqueId]);
}
