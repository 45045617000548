const SpeakerIcon = ({ className }: { className?: string }): JSX.Element => {
  return (
    <svg
      className={className || 'w-3.5 h-3.5 fill-current'}
      viewBox='0 0 18 18'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M10.8519 0.710449V2.66788C13.5279 3.48506 15.4816 6.03162 15.4816 9.04378C15.4816 12.0559 13.5279 14.593 10.8519 15.4102V17.3771C14.5556 16.5124 17.3334 13.1107 17.3334 9.04378C17.3334 4.97689 14.5556 1.57514 10.8519 0.710449ZM13.1667 9.04379C13.1667 7.36192 12.2408 5.9176 10.8519 5.21445V12.8446C12.2408 12.17 13.1667 10.7162 13.1667 9.04379ZM0.666748 11.8944V6.19316H4.37045L9.00008 1.44211V16.6455L4.37045 11.8944H0.666748Z'
        fill='currentColor'
      />
    </svg>
  );
};

export { SpeakerIcon };
