import { useCallback, useEffect, useMemo, useState } from 'react';
import Select, { type SingleValue } from 'react-select';
import { useDebounce } from 'react-use';
import useSWR from 'swr';

import { apiService } from '../../services/api-service';
import { type Organizer, OrganizerUtils } from '../../types';
import { buildReactSelectStyles } from '../../utils/react-select';

interface OrganizerOption {
  label: string;
  value: string;
  organizer: Organizer;
}

const convertToOption = (organizer: Organizer): OrganizerOption => {
  return {
    organizer,
    label: OrganizerUtils.GetDisplayName(organizer),
    value: organizer.uid,
  };
};

interface OrganizerPickerProps {
  orgId: string | null;
  defaultOrganizer?: Organizer;
  onChange: (organizer: Organizer) => void;
  filterOrganizers?: (organizers: Organizer[]) => Organizer[];
  autoFocus?: boolean;
  placeholder?: string;
  isDisabled?: boolean;
  className?: string;
}

export const OrganizerPicker = (props: OrganizerPickerProps): JSX.Element => {
  const { filterOrganizers, orgId } = props;
  const styles = useMemo(
    () =>
      buildReactSelectStyles<OrganizerOption>({
        override: {
          control: { height: '100%' },
        },
      }),
    []
  );

  const [emailOrName, setEmailOrName] = useState<string | null>(null);
  const [query, setQuery] = useState('');
  const [options, setOptions] = useState<OrganizerOption[]>([]);

  const handleValueChange = useCallback(() => {
    if (emailOrName === query) return;
    setQuery(emailOrName ?? '');
  }, [emailOrName, query]);

  useDebounce(handleValueChange, 200, [emailOrName]);

  const { data, error, isValidating } = useSWR(
    ['/organizers', orgId, query],
    async () => {
      if (orgId === null) return [];
      const resp = apiService.organization.getOrganizers(orgId, {
        size: 100,
        q: query,
      });
      const organizers = await resp.next();
      const validOrganizers = filterOrganizers
        ? filterOrganizers(organizers ?? [])
        : organizers || [];
      return validOrganizers;
    },
    {
      shouldRetryOnError: false,
    }
  );

  useEffect(() => {
    if (data) setOptions(data.map(convertToOption));
  }, [data]);

  const handleChange = (option: SingleValue<OrganizerOption>) => {
    if (!option) return;
    props.onChange(option.organizer);
  };

  return (
    <Select<OrganizerOption>
      isSearchable
      isLoading={isValidating}
      noOptionsMessage={() =>
        isValidating ? 'Loading...' : 'No options found.'
      }
      placeholder={error ?? props.placeholder ?? 'Search...'}
      isDisabled={props.isDisabled ?? orgId === null}
      autoFocus={props.autoFocus}
      classNamePrefix='select-box-v2'
      className={`${props.className}`}
      options={options}
      onChange={handleChange}
      styles={styles}
      formatOptionLabel={(opt) => (
        <span className='text-white'>{opt.label}</span>
      )}
      onInputChange={(inputValue) => {
        setEmailOrName(inputValue);
      }}
      defaultValue={
        props.defaultOrganizer
          ? convertToOption(props.defaultOrganizer)
          : undefined
      }
    />
  );
};
