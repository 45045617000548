import { useMemo } from 'react';
import useMeasure from 'react-use-measure';

import { ResizeObserver } from '../../utils/ResizeObserver';
import { getInitials } from '../../utils/string';
import { LiteModeIcon } from '../LiteMode';
import { useParticipantFlag, useParticipantSkim } from '../Player';

const bgArray = [
  'bg-placeholder-1',
  'bg-placeholder-2',
  'bg-placeholder-3',
  'bg-placeholder-4',
  'bg-placeholder-5',
  'bg-placeholder-6',
];

interface PlaceholderPresProps {
  noIndicators?: boolean;
  showLiteMode?: boolean;
  participantUsername: Nullable<string>;
  hasCamera?: boolean;
  hasMicrophone?: boolean;
  lite?: boolean;
  noFontResizing?: boolean;
}

interface PlaceholderProps {
  noIndicators?: boolean;
  showLiteMode?: boolean;
  clientId: string;
  noFontResizing?: boolean;
}

export function Placeholder(props: PlaceholderProps) {
  const { clientId, ...rest } = props;
  const hasCamera = useParticipantFlag(clientId, 'hasCamera');
  const hasMicrophone = useParticipantFlag(clientId, 'hasMicrophone');
  const username = useParticipantSkim(clientId, 'username');
  return (
    <PlaceholderPres
      {...rest}
      hasCamera={!!hasCamera}
      hasMicrophone={!!hasMicrophone}
      participantUsername={username}
    />
  );
}

export const PlaceholderPres = ({
  participantUsername,
  hasCamera,
  hasMicrophone,
  lite,
  noIndicators,
  showLiteMode,
  noFontResizing,
}: PlaceholderPresProps): JSX.Element => {
  const bgClassName = useMemo(
    () => bgArray[Math.floor(Math.random() * bgArray.length)],
    []
  );

  const [containerRef, containerRect] = useMeasure({
    polyfill: ResizeObserver,
  });

  const containerWidth = containerRect.width;

  return (
    <div
      ref={containerRef}
      className={`w-full h-full absolute top-0 left-0 z-0 flex flex-col items-center justify-center ${bgClassName} bg-no-repeat bg-cover bg-center`}
    >
      <div
        className={`text-white font-semibold username tracking-widest`}
        style={{
          fontSize:
            !noFontResizing && containerWidth ? containerWidth / 4 : undefined,
        }}
      >
        {participantUsername ? getInitials(participantUsername) : null}
      </div>
      {hasCamera === false && !noIndicators && (
        <div
          className='text-icon-gray'
          style={{
            fontSize:
              !noFontResizing && containerWidth
                ? containerWidth / 10
                : undefined,
          }}
        >
          No Camera
        </div>
      )}
      {hasMicrophone === false && !noIndicators && (
        <div
          className='text-icon-gray absolute bottom-1 z-15'
          style={{
            fontSize:
              !noFontResizing && containerWidth
                ? containerWidth / 10
                : undefined,
          }}
        >
          No Mic
        </div>
      )}
      {lite === true && showLiteMode && (
        <div className='absolute top-0 w-full flex justify-center items-center pointer-events-off z-15'>
          <LiteModeIcon />
        </div>
      )}
    </div>
  );
};
