const PlayFillIcon = ({ className }: { className?: string }): JSX.Element => {
  return (
    <svg
      className={`${className ? className : 'fill-current w-16 h-18'}`}
      viewBox='0 0 65 72'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M58.3176 31.3666C61.8941 33.4259 61.8941 38.5741 58.3176 40.6334L12.0471 67.2752C8.47058 69.3345 4 66.7604 4 62.6419L4 9.35813C4 5.23958 8.47059 2.66549 12.0471 4.72477L58.3176 31.3666Z'
        fillOpacity='0.8'
      />
    </svg>
  );
};

export { PlayFillIcon };
