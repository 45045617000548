import { useMemo } from 'react';
import { type ConnectDragSource } from 'react-dnd';
import Select from 'react-select';

import {
  type ClientMessageButton,
  ClientMessageButtonAction,
  ClientMessageButtonStyle,
} from '@lp-lib/api-service-client/public';

import { useInstance } from '../../hooks/useInstance';
import { uuidv4 } from '../../utils/common';
import { buildReactSelectStyles } from '../../utils/react-select';
import { DragDropList } from '../common/DragDrop';
import { type Option } from '../common/Utilities';
import { DeleteIcon } from '../icons/DeleteIcon';
import { MenuIcon } from '../icons/MenuIcon';
import { type MessageVars } from './MessageCampaign';
import { MessageTemplateUtils } from './utils';

const STYLE_CONFIG = {
  [ClientMessageButtonStyle.MESSAGEBUTTONSTYLE_DEFAULT]: {
    label: 'Default',
    value: ClientMessageButtonStyle.MESSAGEBUTTONSTYLE_DEFAULT,
    className: 'border-secondary text-icon-gray',
  },
  [ClientMessageButtonStyle.MESSAGEBUTTONSTYLE_PRIMARY]: {
    label: 'Primary',
    value: ClientMessageButtonStyle.MESSAGEBUTTONSTYLE_PRIMARY,
    className: 'border-green-600 text-green-600',
  },
  [ClientMessageButtonStyle.MESSAGEBUTTONSTYLE_DANGER]: {
    label: 'Danger',
    value: ClientMessageButtonStyle.MESSAGEBUTTONSTYLE_DANGER,
    className: 'border-red-600 text-red-600',
  },
};

function MessageTemplateButtonStyleSelect(props: {
  value: ClientMessageButtonStyle;
  onChange: (value: ClientMessageButtonStyle) => void;
}) {
  const { value, onChange } = props;

  const options = useMemo(() => Object.values(STYLE_CONFIG), []);
  const styles = useInstance(() =>
    buildReactSelectStyles<Option<ClientMessageButtonStyle>>({
      override: { control: { height: 48 } },
    })
  );

  return (
    <Select<Option<ClientMessageButtonStyle>>
      options={options}
      value={STYLE_CONFIG[value]}
      classNamePrefix='select-box-v2'
      className='w-full'
      styles={styles}
      onChange={(option) => {
        if (!option) return;
        onChange(option.value);
      }}
    />
  );
}

interface ActionConfig {
  label: string;
  value: ClientMessageButtonAction;
  placeholder?: string;
  hiddenValue?: boolean;
}

const ACTION_CONFIG: {
  [key in ClientMessageButtonAction]: ActionConfig;
} = {
  [ClientMessageButtonAction.MESSAGEBUTTONACTION_OPEN_URL]: {
    label: 'Open URL',
    value: ClientMessageButtonAction.MESSAGEBUTTONACTION_OPEN_URL,
    placeholder: 'https://lunapark.com',
  },
  [ClientMessageButtonAction.MESSAGEBUTTONACTION_GAME_PACK_PROMO_BUTTON_SCHEDULE_GAME]:
    {
      label: 'Schedule Game',
      value:
        ClientMessageButtonAction.MESSAGEBUTTONACTION_GAME_PACK_PROMO_BUTTON_SCHEDULE_GAME,
      placeholder: 'https://lunapark.com/event/create?game-pack-id=',
    },
  [ClientMessageButtonAction.MESSAGEBUTTONACTION_PROGRAM_GROUP_GAME_COMPLETE]: {
    label: 'Confirm Met',
    value:
      ClientMessageButtonAction.MESSAGEBUTTONACTION_PROGRAM_GROUP_GAME_COMPLETE,
    hiddenValue: true,
  },
  [ClientMessageButtonAction.MESSAGEBUTTONACTION_ADD_DATES]: {
    label: 'Update Dates',
    value: ClientMessageButtonAction.MESSAGEBUTTONACTION_ADD_DATES,
    hiddenValue: true,
  },
  [ClientMessageButtonAction.MESSAGEBUTTONACTION_PROMOTION_PROGRAM_NOT_INTERESTED]:
    {
      label: 'Not Interested',
      value:
        ClientMessageButtonAction.MESSAGEBUTTONACTION_PROMOTION_PROGRAM_NOT_INTERESTED,
      hiddenValue: true,
    },
};

function MessageTemplateButtonActionSelect(props: {
  value: ClientMessageButtonAction;
  onChange: (value: ClientMessageButtonAction) => void;
}) {
  const { value, onChange } = props;

  const options = useMemo(() => Object.values(ACTION_CONFIG), []);
  const styles = useInstance(() =>
    buildReactSelectStyles<Option<ClientMessageButtonAction>>({
      override: { control: { height: 48 } },
    })
  );

  return (
    <Select<Option<ClientMessageButtonAction>>
      options={options}
      value={ACTION_CONFIG[value]}
      classNamePrefix='select-box-v2'
      className='w-full'
      styles={styles}
      onChange={(option) => {
        if (!option) return;
        onChange(option.value);
      }}
    />
  );
}

function MessageTemplateButtonEditor(props: {
  button: ClientMessageButton;
  drag: ConnectDragSource;
  onChange: (updates: Partial<ClientMessageButton>) => void;
  onDelete: () => void;
}) {
  const { button, drag, onChange, onDelete } = props;

  return (
    <div className='w-full flex gap-2'>
      <div className='flex-1 bg-main-layer rounded-lg p-3 flex items-center gap-2'>
        <button type='button' ref={drag} className='btn cursor-move'>
          <MenuIcon />
        </button>

        <div className='flex-1 flex flex-col gap-2'>
          <div className='flex items-center gap-2'>
            <label className='w-1/2'>
              <div className='text-3xs font-medium text-icon-gray mb-1'>
                Function
              </div>
              <MessageTemplateButtonActionSelect
                value={button.action}
                onChange={(value) => onChange({ action: value })}
              />
            </label>{' '}
            <label className='w-1/2'>
              <div className='text-3xs font-medium text-icon-gray mb-1'>
                Style
              </div>
              <MessageTemplateButtonStyleSelect
                value={button.style}
                onChange={(value) => onChange({ style: value })}
              />
            </label>
          </div>
          <label className='w-full'>
            <div className='text-3xs font-medium text-icon-gray mb-1'>Text</div>
            <input
              value={button.text}
              onChange={(e) => onChange({ text: e.target.value })}
              className={`field w-full mb-0`}
              placeholder='Text'
            />
          </label>
          {!ACTION_CONFIG[button.action].hiddenValue && (
            <label className='w-full'>
              <div className='text-3xs font-medium text-icon-gray mb-1'>
                Value
              </div>
              <input
                value={button.value}
                onChange={(e) => onChange({ value: e.target.value })}
                className={`field w-full mb-0`}
                placeholder={ACTION_CONFIG[button.action].placeholder}
              />
            </label>
          )}
        </div>
      </div>
      <button
        type='button'
        onClick={onDelete}
        className='w-7.5 h-7.5 rounded-lg border border-secondary btn flex justify-center items-center text-red-002 bg-black'
      >
        <DeleteIcon />
      </button>
    </div>
  );
}

export function MessageTemplateButtonsEditor(props: {
  buttons: ClientMessageButton[];
  onChange: (index: number, updates: Partial<ClientMessageButton>) => void;
  onDelete: (index: number) => void;
  onAdd: (button: ClientMessageButton) => void;
  onMove: (from: number, to: number) => void;
}) {
  return (
    <div className='w-full'>
      <div className='mb-2 font-bold'>Buttons</div>
      <div className='w-full flex flex-col gap-2'>
        <DragDropList
          type='message-template-buttons'
          items={props.buttons}
          onMove={props.onMove}
          render={({ item, index, drag, ref, style }) => (
            <div className={`w-full`} ref={ref} style={style}>
              <MessageTemplateButtonEditor
                button={item}
                drag={drag}
                onChange={(updates) => props.onChange(index, updates)}
                onDelete={() => props.onDelete(index)}
              />
            </div>
          )}
        />
      </div>
      <button
        type='button'
        className='mt-2 btn text-sms text-secondary'
        onClick={() =>
          props.onAdd({
            id: uuidv4(),
            action: ClientMessageButtonAction.MESSAGEBUTTONACTION_OPEN_URL,
            value: '',
            style: ClientMessageButtonStyle.MESSAGEBUTTONSTYLE_DEFAULT,
            text: '',
            actionId: '',
            url: '',
          })
        }
      >
        + Add Button
      </button>
    </div>
  );
}

function MessageTemplateButtonPreview(props: {
  button: ClientMessageButton;
  vars: MessageVars['text'];
}): JSX.Element {
  const { button, vars } = props;

  const text = useMemo(
    () => MessageTemplateUtils.TranslateText(button.text, vars),
    [button.text, vars]
  );

  const value = useMemo(
    () => MessageTemplateUtils.TranslateText(button.value, vars),
    [button.value, vars]
  );

  return (
    <button
      type='button'
      className={`
        btn px-3 min-w-16 h-8 rounded-lg border
        flex justify-center items-center
        ${STYLE_CONFIG[button.style].className}
      `}
      title={value}
    >
      {text}
    </button>
  );
}

export function MessageTemplateButtonsPreview(props: {
  buttons: ClientMessageButton[];
  vars: MessageVars['text'];
}): JSX.Element {
  return (
    <div className='flex flex-row gap-2'>
      {props.buttons.map((button, index) => (
        <MessageTemplateButtonPreview
          key={index}
          button={button}
          vars={props.vars}
        />
      ))}
    </div>
  );
}
