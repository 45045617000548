import composeRefs from '@seznam/compose-react-refs';
import { forwardRef, useEffect, useMemo, useRef, useState } from 'react';

import { type VolumeController } from '../../services/audio/types';
import { VideoEchoCanceller } from '../../services/audio/video-aec';
import { assertExhaustive, xDomainifyUrl } from '../../utils/common';
import { useReleaseVideoOnUnmount } from '../../utils/video';
import { Loading } from '../Loading';
import {
  useAudioEnabled,
  useGameBGMScale,
  useGameTeamVolumeBalancerValue,
} from '../Venue/VenuePlaygroundProvider';

export type EchoCanceledVideoProps = {
  mediaId: string;
  containerClassName: string;
  className: string;
  autoPlay: boolean;
  volumeControl?: 'host' | 'music' | 'game';
  pauseOnEnded?: boolean;
  poster?: string;
  useloadingIndicator?: boolean;
  loop?: boolean;
  externalControlMute?: boolean;
  onEnded?: () => void;
  onPlaying?: () => void;
  onReplaying?: () => void;
  onInited?: () => void;
  onBeforeRelease?: (video: HTMLVideoElement) => Promise<void> | void;
  externalEC?: boolean;
  externalVolumeController?: VolumeController;
  debugKey?: string;
  releaseOnUnmount?: boolean;
} & (EchoCanceledMediaProps | EchoCanceledVideoStreamProps);

interface EchoCanceledMediaProps {
  mediaUrl: string;
}

interface EchoCanceledVideoStreamProps {
  srcObject: MediaStream | null;
}

function convertVolumeBalancerValue(vol: number): number {
  return vol / 100;
}

const EchoCanceledVideo = forwardRef<HTMLVideoElement, EchoCanceledVideoProps>(
  (props, externalRef): JSX.Element => {
    const {
      mediaId,
      containerClassName,
      className,
      autoPlay,
      pauseOnEnded = true,
      poster,
      useloadingIndicator = false,
      loop = false,
      externalControlMute = false,
      onEnded,
      onPlaying,
      onInited,
      onBeforeRelease,
      volumeControl = 'host',
      externalEC,
      externalVolumeController,
      releaseOnUnmount = true,
    } = props;

    const mediaUrl = 'srcObject' in props ? null : props.mediaUrl;
    const srcObject = 'srcObject' in props ? props.srcObject : null;

    const videoRef = useRef<HTMLVideoElement | null>(null);
    const [gameTeamVolumeBalancerValue] = useGameTeamVolumeBalancerValue();
    const [gameBGMScale] = useGameBGMScale();
    const defaultVolume = useRef<number>(
      convertVolumeBalancerValue(gameTeamVolumeBalancerValue)
    );
    const audioEnabled = useAudioEnabled();
    const [showLoadingIndicator, setShowLoadingIndicator] =
      useState(useloadingIndicator);

    // Unfortunately the volume control gets over complicated because of the limitation of using Web Audio API.
    // case 1, EC is controlled internally:
    //  The volume is updated through the echoCanceller.
    // example: Venue Background Video
    //
    // case 2 EC is controlled from external:
    //  The most simple way is that we update the volume via videoRef.current.
    //  However, if the video is also streamed out with WebRTC, changing the volume on the video instance will be
    //  propagated to the remote peer too. As a work around, we have too create multiple gain nodes, one for the local,
    //  and another one for the remote. When user (organizer especially) changes the (local) volume, we can only
    //  only that changes on the local gain node, but not the remote gain node.
    // example: Video Stream Proxy (for game media)
    const [echoCanceller, volumeController] = useMemo<
      [VideoEchoCanceller | undefined, VolumeController]
    >(() => {
      if (externalEC) {
        if (!externalVolumeController)
          throw new Error(
            'externalVolumeController is required if EC is controlled from external'
          );
        return [undefined, externalVolumeController];
      }
      const ec = new VideoEchoCanceller();
      return [ec, ec];
    }, [externalEC, externalVolumeController]);

    useReleaseVideoOnUnmount(videoRef, releaseOnUnmount, onBeforeRelease);

    const onVideoPlaying = () => {
      if (onPlaying) {
        onPlaying();
      }
      if (!useloadingIndicator) return;
      setShowLoadingIndicator(false);
    };

    const onVideoWaiting = () => {
      if (!useloadingIndicator) return;
      setShowLoadingIndicator(true);
    };

    const onVideoCanPlay = () => {
      if (!useloadingIndicator) return;
      setShowLoadingIndicator(false);
    };

    const onVideoEnded =
      pauseOnEnded || onEnded
        ? () => {
            pauseOnEnded && videoRef.current && videoRef.current.pause();
            onEnded && onEnded();
          }
        : undefined;

    useEffect(() => {
      switch (volumeControl) {
        case 'host':
          volumeController.updateVolume(
            convertVolumeBalancerValue(gameTeamVolumeBalancerValue)
          );
          return;
        case 'music':
          volumeController.updateVolume(
            convertVolumeBalancerValue(gameTeamVolumeBalancerValue) * 0.625
          );
          return;
        case 'game':
          volumeController.updateVolume(
            convertVolumeBalancerValue(gameTeamVolumeBalancerValue) *
              (gameBGMScale ?? 1)
          );
          return;
        default:
          assertExhaustive(volumeControl);
      }
    }, [
      gameBGMScale,
      gameTeamVolumeBalancerValue,
      volumeControl,
      volumeController,
    ]);

    // The srcUrl & srcObject have different behaviors w/ Web Audio API, check the link below for more info
    // https://codesandbox.io/s/web-audio-test-w-video-srcobject-srcurl-forked-g3d390
    useEffect(() => {
      if (!videoRef.current) return;
      if (mediaUrl) videoRef.current.src = xDomainifyUrl(mediaUrl);
      if (srcObject) videoRef.current.srcObject = srcObject;
      if (!echoCanceller) return;
      const el = videoRef.current;
      let off: () => void;
      if (srcObject) {
        el.muted = true;
        off = () => {
          el.muted = false;
        };
        echoCanceller.attach(srcObject, defaultVolume.current);
      } else {
        echoCanceller.attach(videoRef.current, defaultVolume.current);
      }
      return () => {
        echoCanceller.detach();
        if (off) off();
      };
    }, [echoCanceller, mediaUrl, srcObject]);

    useEffect(() => {
      return () => echoCanceller?.dispose();
    }, [echoCanceller]);

    useEffect(() => {
      if (!onInited) return;
      onInited();
    }, [onInited]);

    return (
      <div className={`${containerClassName}`}>
        <video
          id={`video-${mediaId}`}
          data-debugkey={props.debugKey}
          crossOrigin={'anonymous'}
          ref={composeRefs(externalRef, videoRef)}
          className={className}
          autoPlay={autoPlay}
          preload='auto'
          tabIndex={-1}
          // Setting `poster` when it is empty string or undefined triggers a
          // blank image request to the server
          {...(poster ? { poster } : {})}
          playsInline={true}
          disablePictureInPicture={true}
          muted={externalControlMute ? undefined : !audioEnabled}
          loop={loop}
          onEnded={onVideoEnded}
          onPlaying={onVideoPlaying}
          onWaiting={onVideoWaiting}
          onCanPlay={onVideoCanPlay}
          onCanPlayThrough={onVideoCanPlay}
        />
        {showLoadingIndicator && (
          <Loading
            text=''
            containerClassName='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2'
            imgClassName='w-9 h-9'
          />
        )}
      </div>
    );
  }
);

export { EchoCanceledVideo };
