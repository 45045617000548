import { useFeatureQueryParam } from '../../hooks/useFeatureQueryParam';
import { useIsCoordinator } from '../../hooks/useMyInstance';
import { useIsLiveGamePlay } from '../Game/hooks';
import { useVenueDerivedSettings, useVenueSlug } from '../Venue';
import { CopyLinkButtonWithPreview } from './CopyButton';

export function useAudienceURLWithPairingInfoFromLocation(): URL {
  const venueSlug = useVenueSlug();
  const venueCapacityCheckEnabled = useFeatureQueryParam(
    'venue-capacity-check'
  );
  const venueSeatCap = useVenueDerivedSettings()?.seatCap;
  const params = cloneParams(new URLSearchParams(window.location.search), [
    'featured-game',
    'pairing-id',
    'townhall',
  ]);

  if (venueCapacityCheckEnabled && venueSeatCap) {
    params.set('guest-users', 'enabled');
  }

  const url = new URL(window.location.origin);
  url.pathname = `/venue/${venueSlug}`;
  url.search = params.toString();
  return url;
}

export function CopyVenueLinkBanner(): JSX.Element {
  const urlToCopy = useAudienceURLWithPairingInfoFromLocation();
  return (
    <div className='w-full h-14 lobby-instruction flex items-center justify-center flex-shrink-0'>
      <div className='text-tertiary font-bold mr-3'>Invite others to join:</div>
      <CopyLinkButtonWithPreview urlToCopy={urlToCopy.toString()} />
    </div>
  );
}

export function CopyVenueLinkStepBanner(): JSX.Element {
  const urlToCopy = useAudienceURLWithPairingInfoFromLocation();
  const userIsCoordinator = useIsCoordinator();
  const isLiveGamePlay = useIsLiveGamePlay();
  return (
    <div className='bg-lp-black-001 rounded-xl w-full flex items-center gap-5 py-[13px] px-6'>
      {userIsCoordinator && !isLiveGamePlay && (
        <div className='w-14 text-tertiary text-base font-bold flex-none'>
          STEP 1
        </div>
      )}
      <div className='text-white font-bold flex-none text-sm mr-4'>
        Invite others to join
      </div>
      <CopyLinkButtonWithPreview
        dimensionsClassName='flex-1 h-10'
        urlToCopy={urlToCopy.toString()}
      />
    </div>
  );
}

function cloneParams(
  source: URLSearchParams,
  names: string[]
): URLSearchParams {
  const cloned = new URLSearchParams();
  names.forEach((name) => {
    const val = source.get(name);
    if (val) {
      cloned.set(name, val);
    }
  });
  return cloned;
}
