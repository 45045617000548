import { type UID } from 'agora-rtc-sdk-ng';

import { apiService } from '../api-service';
import { NetworkQualityBad, type NetworkQualityState } from './types';

type ChannelType =
  | 'stage'
  | 'game'
  | 'ond'
  | 'music'
  | 'broadcast'
  | 'team'
  | 'teamV2';

// https://docs.agora.io/en/Voice/API%20Reference/web_ng/interfaces/iagorartcclient.html#join
// "The length must be within 64 bytes."
const AGORA_MAX_CHANNEL_SIZE_BYTES = 64;

const AGORA_MAX_UID_SIZE_CHARACTERS = 255;

export class WebRTCUtils {
  static ChannelFor(
    type: ChannelType,
    venueId: string,
    teamId?: string
  ): string {
    if (type === 'team') {
      if (!teamId) throw new Error('teamId is required for team channel');
      return `v:${venueId}:team:${teamId}`;
    }
    return `v:${venueId}:${type}`;
  }

  static AssertAgoraChannelLength(chan: string): string {
    if (new TextEncoder().encode(chan).length > AGORA_MAX_CHANNEL_SIZE_BYTES)
      throw new Error(`AgoraRangeError: Channel name too many bytes. ${chan}`);
    return chan;
  }

  static AssertAgoraUIDLength(uid: string): string {
    if (new TextEncoder().encode(uid).length > AGORA_MAX_UID_SIZE_CHARACTERS)
      throw new Error(`AgoraRangeError: UID too many bytes. ${uid}`);
    return uid;
  }

  static CheckNetworkQuality(
    local: boolean,
    state: NetworkQualityState | null
  ): boolean {
    const downlinkNetworkQuality = state?.downlinkNetworkQuality || 0;
    const uplinkNetworkQuality = state?.uplinkNetworkQuality || 0;

    let showPoorConnection = false;
    if (local) {
      if (
        downlinkNetworkQuality >= NetworkQualityBad ||
        uplinkNetworkQuality >= NetworkQualityBad
      ) {
        showPoorConnection = true;
      }
    } else {
      if (uplinkNetworkQuality >= NetworkQualityBad) {
        showPoorConnection = true;
      }
    }

    return showPoorConnection;
  }

  static IsSpeaking(volume: number): boolean {
    return volume >= 60;
  }
}

export async function defaultGetToken(
  uid: UID,
  channel: string
): Promise<string> {
  const res = await apiService.auth.getAgoraRTCToken({
    sessionUid: uid.toString(),
    channelName: channel,
  });
  return res.data.token;
}
