export enum MediaKind {
  AudioInput = 'audioinput',
  VideoInput = 'videoinput',
  AudioOutput = 'audiooutput',
}

export interface DeviceOption {
  label: string;
  value: string;
}

export const DefaultSpeakerDeviceOption: DeviceOption = {
  label: 'Default Speaker',
  value: '',
};
export const NoCameraDeviceOption: DeviceOption = {
  value: '',
  label: 'No cameras found',
};
export const NoMicDeviceOption: DeviceOption = {
  value: '',
  label: 'No microphones found',
};

export interface DeviceUpdatePolicy {
  audio: boolean;
  video: boolean;
}

export enum MediaErrorName {
  OverconstrainedError = 'OverconstrainedError',
  NotAllowedError = 'NotAllowedError',
  NotFoundError = 'NotFoundError',
}
export interface DeviceOptionsState {
  audioInputOptions: DeviceOption[];
  videoInputOptions: DeviceOption[];
  audioOutputOptions: DeviceOption[];
  isGroupedAudioDevices: boolean;
}

export const DefaultDeviceId = 'default';

export type UserMediaResult = {
  audioStream: MediaStream | null;
  videoStream: MediaStream | null;
  audioError: Error | null;
  videoError: Error | null;
  audioDeviceOption: DeviceOption | null;
  videoDeviceOption: DeviceOption | null;
};

export interface ActiveDeviceState {
  numOfAudioInputs: number;
  numOfAudioOutputs: number;
  numOfVideoInputs: number;
}
