import { useEffect, useMemo, useState } from 'react';

import {
  assertExhaustive,
  type OverRoastedBlock,
  OverRoastedBlockGameSessionStatus,
} from '@lp-lib/game';

import { DoubleRightArrow } from '../../../icons/Arrows';
import { PlayIcon } from '../../../icons/PlayIcon';
import { RefreshIcon } from '../../../icons/RefreshIcon';
import { FilledSquareIcon } from '../../../icons/SquareIcon';
import { TimerIcon } from '../../../icons/TimerIcon';
import { useParticipantByUserIds } from '../../../Player';
import {
  useGameSessionLocalTimer,
  useGameSessionStatus,
  useIsEndedBlock,
} from '../../hooks';
import { countdownV2, next, present, triggerBlockTitleAnim } from '../../store';
import {
  BlockControllerActionButton,
  BlockControllerActionNone,
  BlockControllerActionTimeRemaining,
  ControllerLayout,
  ControllerProgress,
  type ControllerProps,
  type MediaPlayback,
  MediaPreviewer,
  useBlockControllerBlockTitlePlaying,
  useControllerMediaPlayback,
  useControllerMediaPlayText,
} from '../Common/Controller/Internal';
import { useStableBlock } from '../Common/hooks';
import {
  useOverRoastedGameSettings,
  useOverRoastedSummaryMap,
} from './OverRoastedProvider';
import { OverRoastedUtils } from './utils';

type SharedProps = ControllerProps<OverRoastedBlock>;
type StageProps = SharedProps & {
  playback: MediaPlayback;
};

function BlockDetails(props: { block: OverRoastedBlock }): JSX.Element {
  const { block } = props;

  return (
    <div className='w-full flex flex-col items-center text-sms font-normal'>
      <p>{block.fields.title}</p>
      <br />
      {block.fields.tutorialMode ? (
        <p>TUTORIAL MODE!</p>
      ) : (
        <>
          <p>Trucks: {block.fields.trucksCount}</p>
          <p>Dispensers: {block.fields.dispensersCountPerTruck}</p>
          <p>
            Max Ingredients per Player: {block.fields.maxIngredientsPerPlayer}
          </p>
          <p>
            Max Ingredients per Order: {block.fields.maxIngredientsPerOrder}
          </p>
          <p>Points: {block.fields.pointsPerOrder}</p>
          <p>Timer: {block.fields.gameTimeSec}</p>
        </>
      )}
    </div>
  );
}

function TutorialModeProgress(): JSX.Element {
  const summaryMap = useOverRoastedSummaryMap();
  const participants = useParticipantByUserIds(Object.keys(summaryMap), true);
  const sorted = useMemo(
    () => participants.sort((a, b) => a.username.localeCompare(b.username)),
    [participants]
  );
  const totalCount = participants.length;
  const completedCount = participants.filter(
    (p) => summaryMap[p.id]?.tutorialCompleted
  ).length;

  return (
    <div className='relative w-full h-full flex flex-col justify-start items-center'>
      <header className='w-full h-10 px-4 py-1 leading-4 bg-black text-white text-2xs font-medium flex items-center'>
        Tutorial Mode ({completedCount} of {totalCount} Players Finished)
      </header>
      <div className='w-full mb-2.5 pt-1 px-2.5 max-h-64 scrollbar-hide overflow-y-scroll'>
        {sorted.map((p) => (
          <div
            key={p.id}
            className='w-full mb-2.5 h-15 flex flex-row border border-secondary rounded-lg overflow-hidden '
          >
            <div className='w-4/5 h-full pl-2'>
              <p className={`font-bold h-1/3 pt-1`}>{p.username}</p>
              <div
                className={`w-full h-2/3 text-xs font-bold flex flex-col justify-center`}
              >
                {summaryMap[p.id]?.tutorialCompleted ? (
                  <p className='text-white'>FINISHED!</p>
                ) : (
                  <p className='text-lp-gray-002'>In progress...</p>
                )}
              </div>
            </div>
            <div className='w-1/5 h-full bg-secondary border-l border-secondary flex flex-col justify-center items-center'></div>
          </div>
        ))}
      </div>
    </div>
  );
}

function NormalModeProgress(
  props: StageProps & { revealResults?: boolean; hideProgressDetail?: boolean }
): JSX.Element {
  const {
    selectedBlock: block,
    setEditPointsData,
    hideProgressDetail,
    revealResults,
  } = props;

  const settings = useOverRoastedGameSettings();
  const summaryMap = useOverRoastedSummaryMap();

  const header = useMemo(
    () =>
      `${OverRoastedUtils.CalOrderPoints(
        settings.pointsPerOrder,
        1
      )} / ${OverRoastedUtils.CalOrderPoints(
        settings.pointsPerOrder,
        2
      )} / ${OverRoastedUtils.CalOrderPoints(
        settings.pointsPerOrder,
        3
      )} pts per Order`,
    [settings.pointsPerOrder]
  );

  return (
    <ControllerProgress
      blockId={block.id}
      setEditPointsData={setEditPointsData}
      header={header}
      revealResults={revealResults}
      hideProgressDetail={hideProgressDetail}
      progressDetail={(teamId: string) => {
        return `[${summaryMap?.[teamId]?.completedOrders ?? 0} orders filled]`;
      }}
    />
  );
}

function GameProgress(
  props: StageProps & { revealResults?: boolean; hideProgressDetail?: boolean }
) {
  if (props.selectedBlock.fields.tutorialMode) {
    return <TutorialModeProgress />;
  }

  return <NormalModeProgress {...props} />;
}

function Ended(props: StageProps): JSX.Element {
  return (
    <ControllerLayout
      action={
        <BlockControllerActionButton
          onClick={() => props.setShowResetConfirmation(true)}
          icon={RefreshIcon}
          isDelete={true}
        >
          Reset Completed Block
        </BlockControllerActionButton>
      }
    >
      <GameProgress {...props} hideProgressDetail revealResults />
    </ControllerLayout>
  );
}

function Loaded(props: StageProps): JSX.Element {
  const { selectedBlock: block, playback } = props;

  const { switchMedia, reset: resetPlayback } = playback.api;

  useEffect(() => {
    resetPlayback();
    const media = OverRoastedUtils.GetMedia(block, 'introMedia');
    switchMedia(media);
  }, [block, switchMedia, resetPlayback]);

  const onPresent = async () => {
    await triggerBlockTitleAnim(block);
    await present(block);
  };

  const actionComponent = useBlockControllerBlockTitlePlaying(block) ?? (
    <BlockControllerActionButton onClick={onPresent} icon={PlayIcon}>
      {playback.preview
        ? `Present Intro Media (${playback.preview.durationSec}s)`
        : 'Present Block'}
    </BlockControllerActionButton>
  );
  return (
    <ControllerLayout action={actionComponent}>
      <div className='w-full flex flex-col gap-6'>
        <MediaPreviewer playback={playback} alt={block.type} />
        <BlockDetails block={block} />
      </div>
    </ControllerLayout>
  );
}

function Intro(props: StageProps): JSX.Element | null {
  const { selectedBlock: block, playback } = props;
  const mediaActionText = useControllerMediaPlayText(playback);
  const [presentIntro, setPresentIntro] = useState(!!playback.preview);
  const { play: playMedia } = playback.api;

  useEffect(() => {
    if (!playback.isVideoPlayed) return;
    setPresentIntro(false);
  }, [playback.isVideoPlayed]);

  useEffect(() => {
    playMedia();
  }, [playMedia]);

  useEffect(() => {
    if (presentIntro) return;
    next();
  }, [presentIntro]);

  if (presentIntro) {
    return (
      <ControllerLayout
        action={
          <BlockControllerActionNone icon={PlayIcon}>
            {mediaActionText}
          </BlockControllerActionNone>
        }
      >
        <div className='w-full flex flex-col gap-6'>
          <MediaPreviewer playback={playback} alt={block.type} />
          <BlockDetails block={block} />
        </div>
      </ControllerLayout>
    );
  }
  return null;
}

function GameInit(props: StageProps): JSX.Element {
  const { selectedBlock: block, playback } = props;

  const [processing, setProcessing] = useState(false);
  const gameTimeSec = useGameSessionLocalTimer();

  const onStartGame = async () => {
    if (gameTimeSec === 0) return;
    setProcessing(true);
    await countdownV2({
      debug: 'OverRoastedBlockGameController',
      startTimeWorker: true,
      flushCountingStatus: true,
      triggerTimesup: true,
    });
  };

  return (
    <ControllerLayout
      action={
        <BlockControllerActionButton
          onClick={onStartGame}
          icon={TimerIcon}
          disable={processing || gameTimeSec === 0}
        >
          {block.fields.tutorialMode
            ? 'Start Tutorial'
            : `Start Game Timer (${gameTimeSec}s)`}
        </BlockControllerActionButton>
      }
    >
      <div className='w-full flex flex-col gap-6'>
        <MediaPreviewer playback={playback} alt={block.type} />
        <BlockDetails block={block} />
      </div>
    </ControllerLayout>
  );
}

function GameStart(props: StageProps): JSX.Element {
  const { selectedBlock } = props;
  const time = useGameSessionLocalTimer();

  const handleEndGame = () => {
    next();
  };

  return (
    <ControllerLayout
      action={
        selectedBlock.fields.tutorialMode ? (
          <>
            <BlockControllerActionButton
              onClick={handleEndGame}
              isSecondary={true}
            >
              Skip to End of Tutorial
            </BlockControllerActionButton>
            <BlockControllerActionNone icon={TimerIcon}>
              Waiting for Players
            </BlockControllerActionNone>
          </>
        ) : (
          <BlockControllerActionTimeRemaining remainingSec={time} />
        )
      }
    >
      <GameProgress {...props} />
    </ControllerLayout>
  );
}

function GameEnd(props: StageProps): JSX.Element {
  const { selectedBlock, onEndBlock } = props;

  const [processing, setProcessing] = useState(false);

  const onRevealResult = async () => {
    setProcessing(true);
    await next();
  };

  return (
    <ControllerLayout
      action={
        selectedBlock.fields.tutorialMode ? (
          <BlockControllerActionButton
            onClick={onEndBlock}
            icon={FilledSquareIcon}
          >
            End Block Sequence
          </BlockControllerActionButton>
        ) : (
          <BlockControllerActionButton
            onClick={onRevealResult}
            icon={DoubleRightArrow}
            disable={processing}
          >
            Reveal Results
          </BlockControllerActionButton>
        )
      }
    >
      <GameProgress {...props} revealResults />
    </ControllerLayout>
  );
}

function Outro(props: StageProps): JSX.Element | null {
  const { selectedBlock: block, playback } = props;
  const [inited, setInited] = useState(false);
  const mediaActionText = useControllerMediaPlayText(playback);
  const { switchMedia, play: playMedia } = playback.api;

  useEffect(() => {
    if (!inited) return;
    if (!playback.isVideoPlayed) return;

    next();
  }, [inited, playback.isVideoPlayed]);

  useEffect(() => {
    const media = OverRoastedUtils.GetMedia(block, 'outroMedia');
    if (media) {
      switchMedia(media, () => playMedia());
    } else {
      next();
    }

    setInited(true);
  }, [block, switchMedia, playMedia]);

  if (inited && playback.preview) {
    return (
      <ControllerLayout
        action={
          <BlockControllerActionNone icon={PlayIcon}>
            {mediaActionText}
          </BlockControllerActionNone>
        }
      >
        <GameProgress {...props} revealResults />
      </ControllerLayout>
    );
  }
  return null;
}

function Results(props: StageProps): JSX.Element {
  const onShowScoreboard = async () => {
    await next();
  };

  return (
    <ControllerLayout
      action={
        <>
          <BlockControllerActionButton
            onClick={onShowScoreboard}
            isSecondary={true}
          >
            Show Scoreboard
          </BlockControllerActionButton>
          <BlockControllerActionButton
            onClick={props.onEndBlock}
            icon={FilledSquareIcon}
          >
            End Block Sequence
          </BlockControllerActionButton>
        </>
      }
    >
      <GameProgress {...props} revealResults />
    </ControllerLayout>
  );
}

function Scoreboard(props: StageProps): JSX.Element {
  return (
    <ControllerLayout
      action={
        <BlockControllerActionButton
          onClick={props.onEndBlock}
          icon={FilledSquareIcon}
        >
          End Block Sequence
        </BlockControllerActionButton>
      }
    >
      <GameProgress {...props} revealResults />
    </ControllerLayout>
  );
}

export function OverRoastedBlockController(
  props: ControllerProps<OverRoastedBlock>
): JSX.Element | null {
  const { selectedBlockIndex } = props;
  const block = useStableBlock(props.selectedBlock);
  const isEndedBlock = useIsEndedBlock(block.id);
  const gameSessionStatus =
    useGameSessionStatus<OverRoastedBlockGameSessionStatus>();
  const playback = useControllerMediaPlayback();

  if (selectedBlockIndex === null) return null;

  if (isEndedBlock) {
    return <Ended {...props} playback={playback} />;
  }

  switch (gameSessionStatus) {
    case OverRoastedBlockGameSessionStatus.LOADED:
      return <Loaded {...props} playback={playback} />;
    case OverRoastedBlockGameSessionStatus.INTRO:
      return <Intro {...props} playback={playback} />;
    case OverRoastedBlockGameSessionStatus.GAME_INIT:
      return <GameInit {...props} playback={playback} />;
    case OverRoastedBlockGameSessionStatus.GAME_START:
      return <GameStart {...props} playback={playback} />;
    case OverRoastedBlockGameSessionStatus.GAME_END:
      return <GameEnd {...props} playback={playback} />;
    case OverRoastedBlockGameSessionStatus.OUTRO:
      return <Outro {...props} playback={playback} />;
    case OverRoastedBlockGameSessionStatus.RESULTS:
      return <Results {...props} playback={playback} />;
    case OverRoastedBlockGameSessionStatus.SCOREBOARD:
      return <Scoreboard {...props} playback={playback} />;
    case OverRoastedBlockGameSessionStatus.END:
    case null:
    case undefined:
      return null;
    default:
      assertExhaustive(gameSessionStatus);
      return null;
  }
}
