import {
  type BoundingBoxSettings,
  type GreenScreenSettingsV0,
} from '@lp-lib/game';
import { type MediaFormat } from '@lp-lib/media';

export type VideoEffectsFormData = VideoEffectsSettings;

export type GenericSettings<T> = {
  enabled: boolean;
  config: T;
};

export type MediaItem = { mediaFormat: MediaFormat };

export type ContentRegistryItemBase = {
  id: string;
  name: string;
};

// The "full" type used throughout the app for the UI, playback, and preview.
export type VideoEffectsSettings = {
  greenScreen: GreenScreenSettingsV0;
  boundingBox: BoundingBoxSettings;
  podium: GenericSettings<ContentRegistryItemBase & MediaItem> | null;
  stage: GenericSettings<ContentRegistryItemBase & MediaItem> | null;

  // Overlays on top of the podium
  orgIconSrc?: MediaItem | null;
  orgIcon?: { brandCSSColor: string };

  // This is used to sync the position of the host & podium if the host is not
  // in the center position
  podiumBoundingBox?: BoundingBoxSettings | null;

  // Only applicable during a live game session
  intro: GenericSettings<ContentRegistryItemBase & MediaItem> | null;
  // Only applicable during a live game session
  outro: GenericSettings<ContentRegistryItemBase & MediaItem> | null;
};

export type VideoEffectsSettingsEnableable = {
  greenScreen: { enabled: boolean };
  podium: { enabled: boolean } | null;
  stage: { enabled: boolean } | null;
  intro?: { enabled: boolean } | null;
  outro?: { enabled: boolean } | null;
};

export interface VideoEffectsSettingsStorage {
  getSettings(key: string): Readonly<VideoEffectsSettings>;
  replaceFromExternal(key: string, ves: VideoEffectsSettings): Promise<void>;
  toggleEffect(
    key: string,
    effect: 'greenScreen' | 'podium' | 'intro' | 'outro',
    enabled: boolean
  ): void;
  load(): Promise<void>;
  persist(): Promise<void>;
}

export enum VesLocalProfileKey {
  Default = '',
}
