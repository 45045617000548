import { type ReactNode } from 'react';

import { FloatLayout } from '../../../../Layout';
import { LayoutAnchor } from '../../../../LayoutAnchors/LayoutAnchors';

export function GamePlayAnswerLayout(props: {
  zIndex?: string;
  children: ReactNode;
}): JSX.Element {
  return (
    <>
      <div
        className={`${
          props.zIndex ?? 'z-25'
        } absolute w-full h-full bg-gradient-to-b from-answer-blue-bg-start to-answer-blue-bg-end bg-opacity-80`}
      />
      <FloatLayout className={`${props.zIndex ?? 'z-25'} opacity-100`}>
        <div className='w-full h-full flex flex-col items-center justify-center'>
          {props.children}
          <LayoutAnchor
            id='gameplay-points-animation-top'
            className='w-full max-w-4xl'
          />
        </div>
      </FloatLayout>
    </>
  );
}
