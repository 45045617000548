import { type IMediaDeviceRTCService } from '../../../services/webrtc';
import { useRTCService } from '../../WebRTC';
import { useTownhallEnabled } from './../Provider';

// It's not the best place to put this hook, but this should be a temporary
// solution, finally we will move to v2.
export function useModeAwareAudienceRTCService():
  | IMediaDeviceRTCService
  | undefined {
  const v1 = useRTCService('audience');
  const v2 = useRTCService('audienceV2');
  const townhallEnabled = useTownhallEnabled();
  return townhallEnabled ? v2 : v1;
}
