import {
  type DtoChannel,
  type DtoChannelProgramLink,
  type DtoNewMember,
  type DtoProgram,
  EnumsExConnectType,
  EnumsProgramLinkStatus,
  EnumsProgramType,
} from '@lp-lib/api-service-client/public';

import { apiService } from '../../services/api-service';
import { type Organization, type Organizer } from '../../types';
import { type SlackChannel } from '../../types/slack';
import { assertExhaustive } from '../../utils/common';
import { ProgramKnifeFactory } from '../Program';
import {
  makeSlackBindingStepConfig,
  type SlackBindingStepConfig,
  type SlackBindingStepConfigOverride,
} from './Slack/config';

export class ChannelUtils {
  static ChannelName(
    channel: Nullable<DtoChannel>,
    org?: Organization
  ): string | null {
    if (!channel) return null;
    switch (channel.exConnectType) {
      case EnumsExConnectType.ExConnectTypeSlack:
        return `#${channel.name}`;
      case EnumsExConnectType.ExConnectTypeTeams:
        return channel.name;
      case EnumsExConnectType.ExConnectTypeLpOrgAdmin:
        return org ? `${org.name} Workspace Admin` : channel.name;
      case EnumsExConnectType.ExConnectTypeLpOrgAllMembers:
        return org ? `${org.name} Workspace` : channel.name;
      case undefined:
      case null:
        return channel.name;
      default:
        assertExhaustive(channel.exConnectType);
        return channel.name;
    }
  }
  static MakeSlackBindingStepConfig(
    overrides?: (SlackBindingStepConfigOverride | undefined)[]
  ): SlackBindingStepConfig {
    return makeSlackBindingStepConfig(overrides);
  }

  static ActiveProgramLinks(channel: DtoChannel): DtoChannelProgramLink[] {
    return channel.programLinks.filter((l) => l.status === 'active');
  }

  static async InviteChannelMembers(props: {
    channel: DtoChannel;
    removeUnmatched?: boolean;
    skipJoinExGroup?: boolean;
  }) {
    const { channel, removeUnmatched, skipJoinExGroup } = props;

    if (!channel.exGroupId) throw new Error('connect failed');

    const {
      data: { users },
    } = await apiService.slack.queryUsers({
      type: 'byChannelId',
      channelId: channel.exGroupId,
      orgId: channel.orgId,
    });

    const {
      data: { organizers, newInviteeUids },
    } = await apiService.organization.inviteOrganizers(channel.orgId, {
      invitedUsers: users.map((u) => ({
        email: u.email,
        fullName: u.fullName,
        exUserId: u.id,
      })),
      webEndpoint: window.location.origin,
      sendEmail: true,
    });

    const newMembers = users.map<DtoNewMember>((u) => {
      const organizer = organizers.find((o) => o.exUserId === u.id);
      const uid = organizer?.uid;
      return {
        uid: uid ?? null,
        exUserId: u.id,
        isNewInvited: newInviteeUids.includes(uid ?? ''),
      };
    });
    await apiService.channel.addMembers(channel.id, {
      members: newMembers,
      removeUnmatched: removeUnmatched,
      skipJoinExGroup: skipJoinExGroup,
    });

    const newInvitees: Organizer[] = [];
    for (const uid of newInviteeUids) {
      const organizer = organizers.find((o) => o.uid === uid);
      if (organizer) newInvitees.push(organizer);
    }

    return {
      organizers,
      newInvitees,
    };
  }

  static async InstallProgram(
    slackChannel: SlackChannel,
    channel: DtoChannel,
    program: DtoProgram
  ) {
    const link = channel.programLinks.find(
      (l) => l.programType === program.type
    );
    if (link) {
      if (link.status === EnumsProgramLinkStatus.ProgramLinkStatusActive) {
        return link;
      }

      await apiService.channel.activateProgramLink(channel.id, link.id);
      link.status = EnumsProgramLinkStatus.ProgramLinkStatusActive;
      return link;
    }

    const req = await ProgramKnifeFactory[
      program.type
    ].MakeAddProgramLinkRequest({
      slackChannel,
      channel,
      program,
    });

    const {
      data: { programLink },
    } = await apiService.channel.addProgramLink(channel.id, req);
    return programLink;
  }

  static async InstallProgramInNewChannel(props: {
    orgId: string;
    channelName: string;
    program: DtoProgram;
  }) {
    const { orgId, program, channelName } = props;

    if (program.type === EnumsProgramType.ProgramTypeRecognition) {
      const {
        data: { channel: slackChannel },
      } = await apiService.slack.findOrCreateChannel({
        name: channelName,
        inviteLunaPark: true,
        inviteOperator: true,
      });

      // invite current user to slack channel

      return this.InstallRecognitionProgram({
        orgId,
        program,
        slackChannel,
      });
    }

    const {
      data: { channel: slackChannel, isNewCreated },
    } = await apiService.slack.findOrCreateChannel({ name: channelName });

    if (!isNewCreated) {
      return this.InstallProgramInExistingChannel({
        orgId,
        program,
        slackChannel,
      });
    }

    const {
      data: { channel },
    } = await apiService.channel.createChannel({
      orgId,
      name: slackChannel.name,
      exGroupId: slackChannel.id,
    });

    const programLink = await this.InstallProgram(
      slackChannel,
      channel,
      program
    );

    return { slackChannel, channel, programLink };
  }

  static async InstallProgramInExistingChannel(props: {
    orgId: string;
    program: DtoProgram;
    slackChannel: SlackChannel;
  }) {
    const { orgId, program, slackChannel } = props;

    if (program.type === EnumsProgramType.ProgramTypeRecognition) {
      return this.InstallRecognitionProgram(props);
    }

    const {
      data: { channel },
    } = await apiService.channel.createChannel({
      orgId,
      name: slackChannel.name,
      exGroupId: slackChannel.id,
    });

    const { newInvitees } = await this.InviteChannelMembers({
      channel,
      skipJoinExGroup: true,
    });

    const programLink = await this.InstallProgram(
      slackChannel,
      channel,
      program
    );

    return { slackChannel, channel, programLink, newInvitees };
  }

  static async InstallRecognitionProgram(props: {
    orgId: string;
    slackChannel: SlackChannel;
    program: DtoProgram;
  }) {
    const { orgId, program, slackChannel } = props;

    const channels = (await apiService.channel.queryChannels(orgId)).data
      .channels;
    const channel = channels.find(
      (c) => c.exConnectType === EnumsExConnectType.ExConnectTypeLpOrgAllMembers
    );
    if (!channel) throw new Error('channel not found');

    const programLink = await this.InstallProgram(
      slackChannel,
      channel,
      program
    );

    return {
      slackChannel,
      channel,
      programLink,
      newInvitees: [] as Organizer[],
    };
  }
}
