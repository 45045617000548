import {
  type AnalyticsBrowser,
  type EventProperties,
} from '@segment/analytics-next';
import { useMemo } from 'react';

import { useMyOrgId } from '../components/Organization';
import { useAnalytics } from './AnalyticsContext';

class MarkAsAwayAnalytics {
  constructor(
    private readonly analytics: AnalyticsBrowser,
    readonly orgId: string | null
  ) {}

  trackMarkAsAwayViewed(props: EventProperties) {
    this.analytics.track('Mark As Away Viewed', this.buildEventProps(props));
  }

  trackMarkAsAwayDismissed(props: EventProperties) {
    this.analytics.track('Mark As Away Dismissed', this.buildEventProps(props));
  }

  trackMarkAsAwayConfirmed(props: EventProperties) {
    this.analytics.track('Mark As Away Confirmed', this.buildEventProps(props));
  }

  trackMarkedAsAwayViewed() {
    this.analytics.track('Marked As Away Viewed', this.buildEventProps());
  }

  trackMarkedAsAwayRejoined() {
    this.analytics.track('Marked As Away Rejoined', this.buildEventProps());
  }

  private buildEventProps(props?: EventProperties): EventProperties {
    return {
      ...props,
      orgId: this.orgId,
    };
  }
}

export function useMarkAsAwayAnalytics(): MarkAsAwayAnalytics {
  const analytics = useAnalytics();
  const orgId = useMyOrgId();

  return useMemo(
    () => new MarkAsAwayAnalytics(analytics, orgId),
    [analytics, orgId]
  );
}
