import { useEffect, useState } from 'react';

import { useStopBroadcasting } from './BroadcastProvider';

export function useOneTimeAutomaticBroadcastToggleOff(
  condition: () => boolean,
  resetOnChange?: boolean | number | null
): void {
  const [hasToggedOffBroadcast, setHasToggledOffBroadcast] = useState(false);
  const stopBroadcasting = useStopBroadcasting();

  useEffect(() => {
    if (resetOnChange !== undefined) setHasToggledOffBroadcast(false);
  }, [resetOnChange]);

  useEffect(() => {
    if (condition() && !hasToggedOffBroadcast) {
      stopBroadcasting();
      setHasToggledOffBroadcast(true);
    }
  }, [condition, hasToggedOffBroadcast, stopBroadcasting]);
}
