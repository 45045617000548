import { useEffect } from 'react';
import { usePrevious } from 'react-use';

import { useQueryParam } from '../../../hooks/useQueryParam';
import logger from '../../../logger/logger';
import { apiService } from '../../../services/api-service';
import { SessionStatus } from '../../../types';
import {
  useIsStreamSessionInited,
  useStreamSessionId,
  useStreamSessionStatus,
} from '../../Session';

const log = logger.scoped('intros-game');

function IntrosGameStartTracking(props: { introId: string }) {
  const { introId } = props;

  const isStreamSessionInited = useIsStreamSessionInited();
  const sessionId = useStreamSessionId();
  const curr = useStreamSessionStatus();
  const prev = usePrevious(curr);

  useEffect(() => {
    if (!isStreamSessionInited) return;
    if (!sessionId) return;
    if (prev === curr || curr !== SessionStatus.Live) return;

    try {
      apiService.program.startGroupGame(introId, {
        sessionId,
      });
    } catch (err) {
      log.error('Failed to track intro game start events', err);
    }
  }, [curr, introId, isStreamSessionInited, prev, sessionId]);

  return null;
}

export function IntrosGameTracking() {
  const introId = useQueryParam('intro-id');

  if (!introId) return null;
  return <IntrosGameStartTracking introId={introId} />;
}
