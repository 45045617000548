import { apiService } from '../../services/api-service';
import { type OrgConnection } from '../../types';
import { uncheckedIndexAccess_UNSAFE } from '../../utils/uncheckedIndexAccess_UNSAFE';

type SlackInstallScenario = 'register' | 'connect';

type SlackInstalledState =
  | {
      success: true;
      connection: OrgConnection;
    }
  | {
      success: false;
      error: string;
      scenario: SlackInstallScenario;
    };

export class SlackUtils {
  static IsSlackInstalledState(state: unknown): state is SlackInstalledState {
    if (typeof state !== 'object' || state === null || !('success' in state))
      return false;
    if (typeof state['success'] !== 'boolean') return false;
    if (state['success']) {
      return 'connection' in state;
    } else {
      return 'error' in state;
    }
  }

  static async GenerateSlackInstallURL(props: {
    scenario: SlackInstallScenario;
    excludeSearchKeys?: string[];
    redirectTo?: string;
  }) {
    let search = window.location.search;
    if (props.excludeSearchKeys) {
      const searchParams = new URLSearchParams(search);
      props.excludeSearchKeys?.forEach((key) => searchParams.delete(key));
      search = searchParams.toString();
    }

    const params = {
      scenario: props.scenario,
      from: window.location.pathname,
      search,
    };
    if (props.redirectTo) {
      uncheckedIndexAccess_UNSAFE(params)['redirect-to'] = props.redirectTo;
    }
    const resp = await apiService.slack.generateInstallUrl(params);
    return resp.data.url;
  }
}
