import { useEffect, useState } from 'react';

import {
  installUserActivationWatcher,
  onUserActivationChange,
  userActivationHasBeenActive,
} from '../utils/user-activation';

export function useUserActivationHasBeenActive() {
  const [active, setActive] = useState(userActivationHasBeenActive());
  useEffect(() => {
    const aborter = new AbortController();
    onUserActivationChange(setActive, aborter.signal);
    installUserActivationWatcher(aborter);
    return () => aborter.abort();
  }, []);
  return active;
}
