export type FirebaseReconnectTrackerStorage = {
  disconnectedAt: null | number;
};

export class FirebaseReconnectTracker {
  static DateNowImpl = (): number => Date.now();

  DateNowImpl = FirebaseReconnectTracker.DateNowImpl;

  private ext: FirebaseReconnectTrackerStorage = {
    disconnectedAt: null,
  };

  constructor(hydrateWith: FirebaseReconnectTrackerStorage | null) {
    if (hydrateWith) {
      this.ext = { ...hydrateWith };
    }
  }

  trackConnection(connected: boolean, overrideDurationMs = 0): number {
    let durationMs = 0;
    const now = this.DateNowImpl();

    if (!connected && this.ext.disconnectedAt === null) {
      this.ext.disconnectedAt = now;
    }

    if (connected && this.ext.disconnectedAt !== null) {
      // Ensure we get at least 1ms duration, mostly for testing. In practice it
      // will always be greater than 1ms.
      durationMs = Math.max(now - this.ext.disconnectedAt, 1);
      this.ext.disconnectedAt = null;
    }

    // Returning non-zero indicates that a reconnect did occur.
    return overrideDurationMs > 0 ? overrideDurationMs : durationMs;
  }

  intoStorage(): FirebaseReconnectTrackerStorage {
    return {
      ...this.ext,
    };
  }
}
