const MicrophoneOffIcon2 = ({
  className,
}: {
  className?: string;
}): JSX.Element => {
  return (
    <svg
      className={className || 'w-5 h-5 fill-current'}
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 14 16'
    >
      <g filter='url(#filter0_d)'>
        <path
          fill='#fff'
          d='M5.622 4.327a.483.483 0 01-.57-.05.583.583 0 01-.175-.585c.143-.54.463-1.008.901-1.314a2.045 2.045 0 011.481-.356c.518.078.992.354 1.335.778.343.424.532.967.532 1.529v2.923a.575.575 0 01-.15.39.497.497 0 01-.365.163.497.497 0 01-.363-.162.575.575 0 01-.15-.391V4.329c0-.295-.1-.58-.28-.803a1.116 1.116 0 00-.701-.408 1.074 1.074 0 00-.778.187 1.22 1.22 0 00-.473.69.548.548 0 01-.244.332zM4.858 6.286l3.658 3.658a2.143 2.143 0 01-3.658-1.515V6.286zM10.435 7.3a.482.482 0 01.543-.116c.06.026.116.066.162.115a.538.538 0 01.146.378 5.292 5.292 0 01-.368 1.955.538.538 0 01-.108.175.497.497 0 01-.356.156.47.47 0 01-.353-.16.539.539 0 01-.142-.383.566.566 0 01.041-.205c.192-.486.29-1.01.289-1.538 0-.142.052-.277.146-.378z'
        ></path>
        <path
          fill='#fff'
          d='M8.586 10.949c-.478.3-1.025.46-1.585.462a3.103 3.103 0 01-2.189-.947 3.82 3.82 0 01-.815-1.259 4.04 4.04 0 01-.285-1.501.575.575 0 00-.135-.383.503.503 0 00-.343-.178.469.469 0 00-.366.149.537.537 0 00-.153.385 5.18 5.18 0 00.363 1.942 4.9 4.9 0 001.055 1.627 4.147 4.147 0 002.242 1.18c.058.01.112.042.15.09.039.049.06.11.06.174v.754a.571.571 0 00.137.382c.09.104.214.166.346.174a.47.47 0 00.363-.15.567.567 0 00.151-.383v-.808c0-.061.02-.121.057-.169a.246.246 0 01.144-.092 4.044 4.044 0 001.516-.669l-.713-.78z'
        ></path>
        <path
          fill='#EE3529'
          fillRule='evenodd'
          d='M10.906 13.49L2.253 4.011a.514.514 0 11.76-.694l8.653 9.478a.514.514 0 11-.76.693z'
          clipRule='evenodd'
        ></path>
      </g>
      <defs>
        <filter
          id='filter0_d'
          width='16'
          height='16'
          x='-1'
          y='0'
          colorInterpolationFilters='sRGB'
          filterUnits='userSpaceOnUse'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix'></feFlood>
          <feColorMatrix
            in='SourceAlpha'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
          ></feColorMatrix>
          <feOffset></feOffset>
          <feGaussianBlur stdDeviation='1'></feGaussianBlur>
          <feColorMatrix values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0'></feColorMatrix>
          <feBlend
            in2='BackgroundImageFix'
            result='effect1_dropShadow'
          ></feBlend>
          <feBlend
            in='SourceGraphic'
            in2='effect1_dropShadow'
            result='shape'
          ></feBlend>
        </filter>
      </defs>
    </svg>
  );
};

export { MicrophoneOffIcon2 };
