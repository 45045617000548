import { type IStorage, StorageFactory } from '../utils/storage';

export class GameStorage {
  private static _storage = StorageFactory<string, string>('raw-local');
  static Get(): IStorage<string, string> {
    return GameStorage._storage;
  }
  static Set(storage: IStorage<string, string>): void {
    GameStorage._storage = storage;
  }
}
