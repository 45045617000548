import { format } from 'date-fns';
import pluralize from 'pluralize';
import { useRef } from 'react';

import { isGamePackLaunched } from '../../../app/components/GamePack/utils';
import { useLobbyAnalytics } from '../../analytics/lobbyRecommendation';
import { getFeatureQueryParamArray } from '../../hooks/useFeatureQueryParam';
import { type Game, type GamePack } from '../../types/game';
import { GameCover, GamePackCover } from '../Game/Utilities';
import { PlayIcon } from '../icons/PlayIcon';
import { RefreshIcon } from '../icons/RefreshIcon';
import {
  useOndGameUIControl,
  useOndGameUIControlState,
} from '../OnDGameUIControl';
import { type OndGameUIControl } from '../OnDGameUIControl/OndGameUICtrl';
import {
  OrgSubscriptionUpgradeButton,
  useMyOrganizationFeatureChecker,
} from '../Organization';
import { useAmICohost } from '../Player';
import { type IFeatureChecker } from '../Product/FeatureChecker';
import { useVenueUrlOpener } from '../Venue';
import {
  type LobbyDecisionData,
  type ShadowGamePackInfo,
} from './useLobbyDecisionData';

export function BannerGame(props: {
  className?: string;
  decisionData: LobbyDecisionData;
  onGameDetailsClick: () => void;
}): JSX.Element {
  if (!props.decisionData?.isOndGame) {
    return (
      <BannerLiveGame
        className={props.className}
        decisionData={props.decisionData}
        onGameDetailsClick={props.onGameDetailsClick}
      />
    );
  } else
    return (
      <BannerOnDGame
        className={props.className}
        decisionData={props.decisionData}
        onGameDetailsClick={props.onGameDetailsClick}
      />
    );
}

const GameOrGamePackCover = ({
  gameOrGamePack,
}: {
  gameOrGamePack: Game | GamePack | null;
}): JSX.Element => {
  if (!gameOrGamePack) {
    return (
      <div
        className={`w-full h-full bg-dark-gray game-cover-clip p-0.5 rounded`}
      >
        <div
          className='
            w-full h-full rounded
            flex flex-col items-center justify-center
            bg-dark-gray game-cover-clip object-cover
            font-bold text-xl
          '
        ></div>
      </div>
    );
  }

  if (gameOrGamePack.type === 'game') {
    return <GameCover game={gameOrGamePack} />;
  }

  return <GamePackCover pack={gameOrGamePack} />;
};

export function OnDPlayNowButton(props: {
  ondUICtrl: OndGameUIControl;
  variant: 'primary' | 'secondary';
  gameOrGamePack: Game | GamePack | null;
  disabled?: boolean;
  replay?: boolean;
  featureChecker?: IFeatureChecker;
}): JSX.Element {
  const analytics = useLobbyAnalytics();
  const orgFeatureChecker = useMyOrganizationFeatureChecker();
  const featureChecker = props.featureChecker ?? orgFeatureChecker;
  const { gameOrGamePack } = props;
  const { onClickStartGamePlay } = props.ondUICtrl;
  const openUrl = useVenueUrlOpener();
  const inputRef = useRef<HTMLInputElement | null>(null);
  const isCohost = useAmICohost();

  const showUpgrade =
    gameOrGamePack &&
    gameOrGamePack.type === 'gamePack' &&
    !featureChecker.canAccessGamePackForOnd(gameOrGamePack);

  const showIncludeHostedTutorial =
    gameOrGamePack &&
    gameOrGamePack.type === 'gamePack' &&
    gameOrGamePack.replayable;

  if (
    gameOrGamePack?.type === 'gamePack' &&
    !isGamePackLaunched(gameOrGamePack)
  ) {
    return (
      <div className='h-10 flex items-center text-tertiary font-bold'>
        Launching{' '}
        {format(
          new Date(
            gameOrGamePack.detailSettings?.availability?.launchDate || ''
          ),
          'M/d/yy'
        )}
      </div>
    );
  }

  if (showUpgrade) {
    return (
      <OrgSubscriptionUpgradeButton
        openUrl={openUrl}
        className='w-35 h-10 p-0.5'
      />
    );
  }

  return (
    <div className='flex flex-col items-end relative'>
      <button
        type='button'
        className={`
          ${props.variant === 'primary' ? 'btn-primary' : 'btn-secondary'}
          w-full h-10 px-5 flex items-center justify-center gap-3
          text-base tracking-wider text-white
        `}
        disabled={props.disabled}
        onClick={() => {
          if (props.replay) {
            analytics.trackPlayAgainClicked({
              gamePackId: props.gameOrGamePack?.id,
              gamePackName: props.gameOrGamePack?.name,
            });
          } else {
            analytics.trackPlayNowClicked({
              gamePackId: props.gameOrGamePack?.id,
              gamePackName: props.gameOrGamePack?.name,
            });
          }
          const includeHostedTutorialRef = inputRef.current;
          const skipHostedTutorial =
            includeHostedTutorialRef !== null && // if the ref isn't there, then they cannot skip
            !includeHostedTutorialRef.checked; // when checked, we want to _include_ tutorial

          const useAnimatedRandomization =
            isCohost &&
            getFeatureQueryParamArray('cohost-team-randomization') ===
              'animated';

          onClickStartGamePlay({
            skipHostedTutorial,
            useAnimatedRandomization,
          });
        }}
      >
        {props.replay ? (
          <>
            <RefreshIcon className='flex-none w-4 h-4 fill-current' />
            <>Play Again</>
          </>
        ) : (
          <>
            <PlayIcon className='w-4 h-4 fill-current' />
            <p>Start</p>
          </>
        )}
      </button>
      {showIncludeHostedTutorial && (
        <div
          className={`
            text-xs font-medium text-white tracking-wide
            px-1 py-2 mx-2
            flex items-center justify-center gap-1
          `}
        >
          <input
            ref={inputRef}
            key={gameOrGamePack.id}
            type='checkbox'
            className='checkbox-dark'
            defaultChecked={true}
          />
          Include Hosted Tutorial
        </div>
      )}
    </div>
  );
}

export function ShadowGamePackText(props: {
  info: LobbyDecisionData['shadowGamePacksInfo'];
}) {
  if (!props.info) return null;

  return (
    <div className='flex flex-col items-end gap-1.5'>
      {props.info.highScore !== null && (
        <div className='font-bold text-tertiary'>
          Your High Score: {props.info.highScore}
        </div>
      )}

      <div className='text-3xs'>
        {props.info.totalCount === props.info.remainingCount
          ? `You have ${props.info.totalCount} ${pluralize(
              'try',
              props.info.totalCount
            )} to top the`
          : `${props.info.remainingCount} ${pluralize(
              'try',
              props.info.remainingCount
            )} left to top the`}{' '}
        <a
          href={`/leaderboards/${props.info.roundId}`}
          target='_blank'
          rel='noreferrer'
          className='underline'
        >
          Leaderboard
        </a>
      </div>
    </div>
  );
}

function ConfigurableLobbyGameBanner(props: {
  className?: string;
  gameOrGamePack: Game | GamePack | null;
  onGameDetailsClick?: () => void;
  onSwitchGamesClick?: () => void;
  cta: JSX.Element | null;
  shadowGamePackInfo?: ShadowGamePackInfo | null;
}) {
  return (
    <div
      className={`relative w-full ${
        props.shadowGamePackInfo ? 'pt-4' : 'pt-7.5'
      } pr-5 ${
        props.shadowGamePackInfo ? 'pb-4' : 'pb-7.5'
      } pl-7.5 flex flex-col gap-3 ${props.className}`}
    >
      <div className='flex items-center w-full'>
        <div className='w-32 h-18'>
          <GameOrGamePackCover gameOrGamePack={props.gameOrGamePack} />
        </div>

        <div className='flex-1 flex flex-col ml-5 truncate'>
          <p className='italic font-medium text-2xs text-icon-gray'>
            Currently loaded game
          </p>
          <p className='text-xl font-medium truncate'>
            {props.gameOrGamePack
              ? props.gameOrGamePack.name
              : 'No Game Loaded'}
          </p>

          <div className='mt-2 flex gap-2'>
            {!props.onGameDetailsClick ||
            !props.gameOrGamePack ||
            props.gameOrGamePack.type === 'game' ? null : (
              <button
                type='button'
                className='btn underline text-2xs text-icon-gray'
                onClick={props.onGameDetailsClick}
              >
                Game Details
              </button>
            )}
            {!props.onSwitchGamesClick ? null : (
              <button
                type='button'
                className='btn underline text-2xs text-icon-gray'
                onClick={props.onSwitchGamesClick}
              >
                {props.gameOrGamePack ? 'Switch Game' : 'Load Game'}
              </button>
            )}
          </div>
        </div>

        <div className='flex-shrink max-w-56 flex flex-col justify-center items-end gap-2.5'>
          {props.cta}

          <ShadowGamePackText info={props.shadowGamePackInfo || null} />
        </div>
      </div>
    </div>
  );
}

export function BannerTextCTA(props: {
  primary: string;
  secondary: string;
}): JSX.Element {
  return (
    <div className='flex flex-col items-end gap-2 text-right'>
      <p className='text-white text-base font-bold'>{props.primary}</p>
      <p className='text-tertiary text-xs font-bold'>{props.secondary}</p>
    </div>
  );
}

function BannerLiveGame(props: {
  className?: string;
  decisionData: LobbyDecisionData;
  onGameDetailsClick: () => void;
}) {
  if (props.decisionData.gameIsInProgress) {
    return (
      <ConfigurableLobbyGameBanner
        className={props.className}
        gameOrGamePack={props.decisionData.gameOrGamePack}
        onGameDetailsClick={props.onGameDetailsClick}
        cta={
          <BannerTextCTA
            primary={'Ready to Play?'}
            secondary='Join any Team to jump into the Game!'
          />
        }
      />
    );
  } else {
    return (
      <ConfigurableLobbyGameBanner
        className={props.className}
        gameOrGamePack={props.decisionData.gameOrGamePack}
        onGameDetailsClick={props.onGameDetailsClick}
        cta={
          <BannerTextCTA
            primary={'Hang out with your team!'}
            secondary='The Host will randomize the Teams and start the Game shortly!'
          />
        }
      />
    );
  }
}

const BannerOnDGame = (props: {
  className?: string;
  decisionData: LobbyDecisionData;
  onGameDetailsClick: () => void;
}): JSX.Element | null => {
  const { decisionData } = props;

  const ondUICtrl = useOndGameUIControl();
  const ondUICtrlState = useOndGameUIControlState();
  const displayName =
    decisionData.coordinator?.firstName ??
    decisionData.coordinator?.username ??
    'organizer';

  const coordinatorId = decisionData.coordinator?.id;

  if (
    decisionData.userIsCoordinator &&
    coordinatorId &&
    ondUICtrl &&
    decisionData.gamePackPlus
  ) {
    const { onClickGameExplore } = ondUICtrl;

    return (
      <ConfigurableLobbyGameBanner
        className={props.className}
        gameOrGamePack={decisionData.gameOrGamePack}
        onGameDetailsClick={props.onGameDetailsClick}
        cta={
          <OnDPlayNowButton
            ondUICtrl={ondUICtrl}
            variant={
              decisionData.userIsAlone ||
              !decisionData.userHasTeam ||
              decisionData.gameIsInProgress
                ? 'secondary'
                : 'primary'
            }
            gameOrGamePack={decisionData.gameOrGamePack}
            disabled={ondUICtrlState.actionDisabled}
          />
        }
        onSwitchGamesClick={onClickGameExplore}
        shadowGamePackInfo={decisionData.shadowGamePacksInfo}
      />
    );
  } else if (!decisionData.gameOrGamePack) {
    // Condition: user is not a coordinator and there is no game pack loaded
    // and townhall is not enabled, hide BannerGame.

    // NOTE(drew): Leaving this here in the event it needs to be brought back.
    // Delete it if major changes are made to the banner.
    // Note(jialin): Thanks Drew, I did bring it back.

    if (decisionData.townhallEnabled) {
      return (
        <ConfigurableLobbyGameBanner
          className={props.className}
          gameOrGamePack={decisionData.gameOrGamePack}
          onGameDetailsClick={props.onGameDetailsClick}
          cta={
            <BannerTextCTA
              primary={'Awaiting Game Selection...'}
              secondary={`Ask ${displayName} to load a Game into the Venue!`}
            />
          }
        />
      );
    }
    return null;
  } else if (decisionData.gameIsInProgress) {
    return (
      <ConfigurableLobbyGameBanner
        className={props.className}
        gameOrGamePack={decisionData.gameOrGamePack}
        onGameDetailsClick={props.onGameDetailsClick}
        cta={
          <BannerTextCTA
            primary={'Ready to Play?'}
            secondary={`Join any Team to jump into the Game!`}
          />
        }
        shadowGamePackInfo={decisionData.shadowGamePacksInfo}
      />
    );
  } else {
    return (
      <ConfigurableLobbyGameBanner
        className={props.className}
        gameOrGamePack={decisionData.gameOrGamePack}
        onGameDetailsClick={props.onGameDetailsClick}
        cta={
          <BannerTextCTA
            primary={'Ready to Start?'}
            secondary={`Ask ${displayName} to click "Play Now" on their screen!`}
          />
        }
        shadowGamePackInfo={decisionData.shadowGamePacksInfo}
      />
    );
  }
};
