import { useIsLiveGamePlay } from '../Game/hooks';
import { useIsGameRecordModeActive } from '../GameRecorder/BlockRecorderProvider';

export enum StreamingActiveMode {
  Live = 'Live',
  Rec = 'Rec',
  OnD = 'OnD',
}

export const useStreamingMode = (): StreamingActiveMode => {
  const isGameRecordModeActive = useIsGameRecordModeActive();
  const isLiveGame = useIsLiveGamePlay();
  return isGameRecordModeActive
    ? StreamingActiveMode.Rec
    : isLiveGame
    ? StreamingActiveMode.Live
    : StreamingActiveMode.OnD;
};
