import groupBy from 'lodash/groupBy';
import keyBy from 'lodash/keyBy';
import sampleSize from 'lodash/sampleSize';
import shuffle from 'lodash/shuffle';
import React, { type ReactNode, useContext, useEffect, useMemo } from 'react';
import { proxy } from 'valtio';

import {
  type GuessWhoBlockDetailScore,
  type GuessWhoPrompt,
} from '@lp-lib/game';
import { type Logger } from '@lp-lib/logger-base';

import { useMyInstance } from '../../../../hooks/useMyInstance';
import { type MemberId, type Participant } from '../../../../types';
import { sleep, uuidv4 } from '../../../../utils/common';
import { uncheckedIndexAccess_UNSAFE } from '../../../../utils/uncheckedIndexAccess_UNSAFE';
import {
  markSnapshottable,
  useSnapshot,
  ValtioUtils,
} from '../../../../utils/valtio';
import { Clock } from '../../../Clock';
import {
  type FirebaseService,
  useFirebaseContext,
  useIsFirebaseConnected,
} from '../../../Firebase';
import { increment } from '../../../Firebase/utils';
import { useParticipantsAsArray } from '../../../Player';
import { useIsStreamSessionAlive } from '../../../Session';
import { useStageControlAPI } from '../../../Stage';
import { useTeams } from '../../../TeamAPI/TeamV1';
import { useVenueId } from '../../../Venue/VenueProvider';
import { useGameSessionStatusChangedAt } from '../../hooks';
import { updateBlockDetailScore } from '../../store';
import { PromptPoolManager } from '../Common/GamePlay/utils';
import {
  type Game,
  type GeneratedMatchPrompts,
  type MatchPrompt,
  type MatchPromptState,
  type PlayerStateMap,
  type PlayerSubmission,
  type PlayerSubmissionSummary,
  type TeamGuessSummary,
  type TeamSubmissionProgressSummary,
} from './types';
import {
  GuessWhoFBUtils,
  log,
  MAX_CHOICES_PER_PROMPT,
  MAX_MATCH_PROMPTS,
  REVEAL_TRANSITION_SEC,
} from './utils';

export type GuessWhoSharedState = {
  game: Nullable<Game>;
  playerStateMap: Nullable<PlayerStateMap>;
  generatedMatchPrompts: Nullable<GeneratedMatchPrompts>;
  matchPromptState: Nullable<MatchPromptState>;
};

export type GuessWhoGamePlayerState = {
  submission: Nullable<PlayerSubmission>;
};

class GuessWhoSharedAPI {
  private _state;

  constructor(
    venueId: string,
    svc: FirebaseService,
    private gameHandle = GuessWhoFBUtils.GameHandle(svc, venueId),
    private playerStateHandle = GuessWhoFBUtils.PlayerStateHandle(svc, venueId),
    private mpsHandle = GuessWhoFBUtils.MatchPromptStateHandle(svc, venueId),
    private generatedMatchPrompts = GuessWhoFBUtils.GeneratedMatchPromptsHandle(
      svc,
      venueId
    )
  ) {
    this._state = markSnapshottable(
      proxy<GuessWhoSharedState>(this.initialState())
    );
  }

  get state() {
    return this._state;
  }

  on(): void {
    this.gameHandle.on((val) => ValtioUtils.set(this._state, 'game', val));
    this.playerStateHandle.on((val) =>
      ValtioUtils.set(this._state, 'playerStateMap', val)
    );
    this.mpsHandle.on((val) =>
      ValtioUtils.set(this._state, 'matchPromptState', val)
    );
    this.generatedMatchPrompts.on((val) =>
      ValtioUtils.set(this._state, 'generatedMatchPrompts', val)
    );
  }

  off(): void {
    this.gameHandle.off();
    this.playerStateHandle.off();
    this.mpsHandle.off();
    this.generatedMatchPrompts.off();
  }

  reset() {
    ValtioUtils.reset(this._state, this.initialState());
  }

  private initialState(): GuessWhoSharedState {
    return {
      game: null,
      playerStateMap: null,
      matchPromptState: null,
      generatedMatchPrompts: null,
    };
  }
}

class GuessWhoGameControlAPI {
  constructor(
    venueId: string,
    svc: FirebaseService,
    private stageControl: ReturnType<typeof useStageControlAPI>,
    private log: Logger,
    private rootHandle = GuessWhoFBUtils.RootHandle(svc, venueId),
    private gameHandle = GuessWhoFBUtils.GameHandle(svc, venueId),
    private playerStateHandle = GuessWhoFBUtils.PlayerStateHandle(svc, venueId),
    private generatedMatchPromptsHandle = GuessWhoFBUtils.GeneratedMatchPromptsHandle(
      svc,
      venueId
    ),
    private mpsHandle = GuessWhoFBUtils.MatchPromptStateHandle(svc, venueId),
    private promptManager = new PromptPoolManager<GuessWhoPrompt>(),
    private clock = Clock.instance()
  ) {}

  async initGame(prompts: GuessWhoPrompt[]) {
    this.log.info('init game', { numOfPrompts: prompts.length });
    if (prompts.length === 0) return;

    this.promptManager.init(prompts);
    await this.gameHandle.update({
      prompt: this.promptManager.pick(),
    });
  }

  async configureMatchPromptPhase(
    guessTimePerSubmissionSec: number,
    revealTimePerSubmissionSec: number,
    showGuessers: boolean,
    participants: Participant[]
  ) {
    this.log.info('configure match prompt', {
      guessTimePerSubmissionSec,
      revealTimePerSubmissionSec,
      showGuessers,
    });
    await this.gameHandle.update({
      guessTimePerSubmissionSec,
      revealTimePerSubmissionSec,
      showGuessers,
    });

    // here we need to initialize all the "match prompts"
    const participantsById = keyBy(participants, (p) => p.id);
    const playerState = (await this.playerStateHandle.get()) ?? {};
    let submissions: PlayerSubmission[] = Object.values(playerState)
      .map((state) => state.submission)
      .filter((s) => s !== undefined) as PlayerSubmission[];

    if (submissions.length === 0) return;
    if (submissions.length > MAX_MATCH_PROMPTS) {
      submissions = sampleSize(submissions, MAX_MATCH_PROMPTS);
    }

    const matchPrompts = submissions.map((submission) => {
      const playerId = submission.playerId;
      const participant = participantsById[playerId];
      // try to get the most recent name if possible.
      const playerName =
        participant?.firstName ??
        participant?.username ??
        submission.playerName;

      let participantPool = participants.filter((p) => p.id !== playerId);
      // minus 1 since we want to exclude the responding player.
      if (participantPool.length > MAX_CHOICES_PER_PROMPT - 1) {
        participantPool = sampleSize(
          participantPool,
          MAX_CHOICES_PER_PROMPT - 1
        );
      }

      const choices = shuffle([
        {
          playerId,
          playerName,
        },
        ...participantPool.map((p) => ({
          playerId: p.id,
          playerName: p.firstName ?? p.username,
        })),
      ]);

      return {
        id: uuidv4(),
        submission,
        playerId,
        playerName,
        choices,
      };
    });

    const generated = keyBy(matchPrompts, (mp) => mp.id);
    await this.generatedMatchPromptsHandle.set({
      sequence: shuffle(Object.keys(generated)),
      generated,
    });
  }

  async startRevealSequence(
    currentMatchPrompt: MatchPrompt,
    points: number,
    participants: Participant[],
    debug?: string
  ) {
    this.log.info('start reveal sequence', {
      debug,
    });
    // snapshot the votes.
    const mps = await this.mpsHandle.get();
    await this.mpsHandle.update({
      phase: 'reveal-transition',
      updatedAt: this.clock.now(),
    });
    await sleep(REVEAL_TRANSITION_SEC * 1000);
    await this.mpsHandle.update({
      phase: 'reveal',
      updatedAt: this.clock.now(),
    });

    const votes = mps?.votes ?? {};
    const participantsById: Record<string, Participant | undefined> = keyBy(
      participants,
      (p) => p.id
    );

    const scores: Record<string, number> = Object.entries(votes).reduce(
      (accum, [playerId, votedPlayerId]) => {
        if (votedPlayerId === currentMatchPrompt.playerId) {
          // this was a correct vote, award the team points.
          const teamId = participantsById[playerId]?.teamId;
          if (teamId) {
            if (!accum[teamId]) accum[teamId] = 0;
            accum[teamId] += points;
          }
        }
        return accum;
      },
      uncheckedIndexAccess_UNSAFE({})
    );

    const promises: Promise<void>[] = [];
    for (const [teamId, points] of Object.entries(scores)) {
      promises.push(
        updateBlockDetailScore<GuessWhoBlockDetailScore>(teamId, {
          score: increment(points),
          submittedAt: Date.now(),
        })
      );
    }
    await Promise.all(promises);
  }

  async nextMatch(nextIndex: number) {
    this.log.info('move to next match', { nextIndex });
    await this.mpsHandle.set({
      index: nextIndex,
      phase: 'vote',
      updatedAt: this.clock.now(),
      votes: {},
    });
  }

  async endReveal(currentIndex: number) {
    this.log.info('end reveal');
    await this.mpsHandle.ref.transaction((state) => {
      if (!state || state.index !== currentIndex) return;
      return {
        ...state,
        phase: 'done',
      };
    });
  }

  async endMatch(excludeMemberIds: Nullable<MemberId>[]) {
    this.log.info('end match');
    await this.stageControl.leaveAll(excludeMemberIds);
  }

  async resetGame() {
    this.log.info('reset game');
    await this.rootHandle.remove();
  }
}

class GuessWhoGamePlayAPI {
  private _state;
  constructor(
    venueId: string,
    private playerId: string,
    svc: FirebaseService,
    readonly log: Logger,
    private clock = Clock.instance(),
    private playerSubmissionHandle = GuessWhoFBUtils.PlayerSubmissionHandle(
      svc,
      venueId,
      playerId
    ),
    private mpsHandle = GuessWhoFBUtils.MatchPromptStateHandle(svc, venueId)
  ) {
    this._state = markSnapshottable(
      proxy<GuessWhoGamePlayerState>(this.initialState())
    );
  }

  async submitResponse(response: string, playerName: string) {
    const submission = {
      playerId: this.playerId,
      playerName,
      response,
      submittedAt: this.clock.now(),
    };

    await this.playerSubmissionHandle.set(submission);
    return submission;
  }

  async submitGuess(guessedPlayerId: string) {
    this.mpsHandle.ref.child('votes').update({
      [this.playerId]: guessedPlayerId,
    });
  }

  async endReveal(currentIndex: number) {
    this.log.info('end reveal');
    await this.mpsHandle.ref.transaction((state) => {
      if (!state || state.index !== currentIndex) return;
      return {
        ...state,
        phase: 'done',
      };
    });
  }

  get state() {
    return this._state;
  }

  on(): void {
    this.playerSubmissionHandle.on((val) =>
      ValtioUtils.set(this._state, 'submission', val)
    );
  }

  off(): void {
    this.playerSubmissionHandle.off();
  }

  reset() {
    ValtioUtils.reset(this._state, this.initialState());
  }

  private initialState(): GuessWhoGamePlayerState {
    return {
      submission: null,
    };
  }
}

type Context = {
  sharedAPI: GuessWhoSharedAPI;
  gameControlAPI: GuessWhoGameControlAPI;
  gamePlayAPI: GuessWhoGamePlayAPI;
};

const context = React.createContext<Context | null>(null);

function useGuessWhoContext(): Context {
  const ctx = useContext(context);
  if (!ctx) throw new Error('GuessWhoContext is not in the tree!');
  return ctx;
}

export function useGuessWhoGameControlAPI(): Context['gameControlAPI'] {
  return useGuessWhoContext().gameControlAPI;
}

export function useGuessWhoGamePlayAPI(): Context['gamePlayAPI'] {
  return useGuessWhoContext().gamePlayAPI;
}

export function useGuessWhoSharedAPI(): Context['sharedAPI'] {
  return useGuessWhoContext().sharedAPI;
}

export function useGuessWhoGame(): Nullable<Game> {
  const api = useGuessWhoSharedAPI();
  return useSnapshot(api.state).game;
}

export function useGuessWhoPrompt(): Nullable<GuessWhoPrompt> {
  const api = useGuessWhoSharedAPI();
  return useSnapshot(api.state).game?.prompt;
}

export function useGuessWhoShowGuessers(): boolean {
  const api = useGuessWhoSharedAPI();
  return useSnapshot(api.state).game?.showGuessers ?? true;
}

export function useGeneratedMatchPrompts(): Nullable<GeneratedMatchPrompts> {
  const api = useGuessWhoSharedAPI();
  return useSnapshot(api.state)
    .generatedMatchPrompts as Nullable<GeneratedMatchPrompts>;
}

export function useMySubmission(): Nullable<PlayerSubmission> {
  const api = useGuessWhoGamePlayAPI();
  return useSnapshot(api.state).submission;
}

export function useCurrMatchState(): MatchPromptState {
  const api = useGuessWhoSharedAPI();
  const statusChangedAt = useGameSessionStatusChangedAt();
  const matchPromptState = useSnapshot(api.state).matchPromptState;
  return (
    matchPromptState ?? {
      index: 0,
      phase: 'vote',
      updatedAt: statusChangedAt,
      votes: {},
    }
  );
}

export function useCurrMatchVotesByChoice(): Record<string, string[]> {
  const currentMatchState = useCurrMatchState();
  return useMemo(() => {
    const votes = currentMatchState.votes ?? {};
    return Object.entries(votes).reduce((acc, [playerId, vote]) => {
      if (!acc[vote]) acc[vote] = [];
      acc[vote].push(playerId);
      return acc;
    }, uncheckedIndexAccess_UNSAFE({}));
  }, [currentMatchState.votes]);
}

export function useCurrMatchPrompt(): MatchPrompt | undefined {
  const currMatch = useCurrMatchState();
  const generatedMatchPrompts = useGeneratedMatchPrompts();
  if (!generatedMatchPrompts) return;

  if (
    !generatedMatchPrompts ||
    currMatch.index < 0 ||
    currMatch.index >= generatedMatchPrompts.sequence.length
  )
    return;

  const id = generatedMatchPrompts.sequence[currMatch.index];
  return generatedMatchPrompts.generated[id];
}

export function useTeamSubmissionProgressSummary(): TeamSubmissionProgressSummary {
  const api = useGuessWhoSharedAPI();
  const playerStateMap = useSnapshot(api.state).playerStateMap;

  const teams = useTeams({
    excludeStaffTeam: true,
    updateStaffTeam: true,
  });

  const players = useParticipantsAsArray({
    filters: ['host:false', 'cohost:false', 'status:connected', 'team:true'],
  });

  const playersByTeamId = groupBy(players, (p) => p.teamId);

  return useMemo(() => {
    const summary: TeamSubmissionProgressSummary = {};
    for (const team of teams) {
      const players = playersByTeamId[team.id] ?? [];
      const numOfSubmissions = players
        .map((p) => playerStateMap?.[p.id]?.submission)
        .reduce((acc, curr) => {
          return acc + (curr ? 1 : 0);
        }, 0);

      summary[team.id] = {
        numOfSubmissions,
        numOfPlayers: players.length,
      };
    }
    return summary;
  }, [playerStateMap, playersByTeamId, teams]);
}

export function useMyTeamSubmissionProgressSummary(): TeamSubmissionProgressSummary['string'] {
  const me = useMyInstance();
  const allTeams = useTeamSubmissionProgressSummary();

  return useMemo(() => {
    return me?.teamId ? allTeams[me.teamId] : null;
  }, [allTeams, me?.teamId]);
}

export function usePlayerSubmissionSummary(): PlayerSubmissionSummary {
  const api = useGuessWhoSharedAPI();
  const playerStateMap = useSnapshot(api.state).playerStateMap;
  const players = useParticipantsAsArray({
    filters: ['host:false', 'cohost:false', 'status:connected', 'team:true'],
  });
  const playersById = keyBy(players, (p) => p.id);

  return useMemo(() => {
    const summary: PlayerSubmissionSummary = {};
    const playerSubmissions = Object.entries(playerStateMap ?? {});
    for (const [playerId, submission] of playerSubmissions) {
      if (!submission.submission) continue;
      summary[playerId] = {
        playerId,
        playerName:
          playersById[playerId]?.firstName ??
          playersById[playerId]?.username ??
          playerId,
        response: submission.submission.response,
      };
    }
    return summary;
  }, [playerStateMap, playersById]);
}

export function useTeamGuessSummary(): TeamGuessSummary {
  const currentMatchPrompt = useCurrMatchPrompt();
  const currentMatchState = useCurrMatchState();

  const teams = useTeams({
    excludeStaffTeam: true,
    updateStaffTeam: true,
  });

  const players = useParticipantsAsArray({
    filters: ['host:false', 'cohost:false', 'status:connected', 'team:true'],
  });

  const playersByTeamId = groupBy(players, (p) => p.teamId);
  return useMemo(() => {
    const votes = currentMatchState.votes ?? {};
    const summary: TeamGuessSummary = {};
    for (const team of teams) {
      const players = (playersByTeamId[team.id] ?? []).filter(
        (p) => p.id !== currentMatchPrompt?.playerId
      );
      const playerGuesses = players.map((p) => votes[p.id]);
      const numCorrect = playerGuesses.reduce((acc, curr) => {
        return acc + (curr === currentMatchPrompt?.playerId ? 1 : 0);
      }, 0);

      summary[team.id] = {
        numOfGuesses: playerGuesses.filter((g) => !!g).length,
        numOfPlayers: players.length,
        numCorrect,
      };
    }
    return summary;
  }, [
    currentMatchPrompt?.playerId,
    currentMatchState.votes,
    playersByTeamId,
    teams,
  ]);
}

export function GuessWhoProvider(props: {
  children?: ReactNode;
}): JSX.Element | null {
  const venueId = useVenueId();
  const { svc } = useFirebaseContext();

  const firebaseConnected = useIsFirebaseConnected();
  const isSessionAlive = useIsStreamSessionAlive();
  const stageControl = useStageControlAPI();

  const ready = firebaseConnected && isSessionAlive;
  const me = useMyInstance();
  const playerId = me?.id ?? '';

  const ctx = useMemo(() => {
    return {
      sharedAPI: new GuessWhoSharedAPI(venueId, svc),
      gameControlAPI: new GuessWhoGameControlAPI(
        venueId,
        svc,
        stageControl,
        log
      ),
      gamePlayAPI: new GuessWhoGamePlayAPI(venueId, playerId, svc, log),
    };
  }, [venueId, svc, stageControl, playerId]);

  useEffect(() => {
    if (!ready) return;
    ctx.sharedAPI.on();
    return () => ctx.sharedAPI.off();
  }, [ctx.sharedAPI, ready]);

  useEffect(() => {
    if (!ready || !playerId) return;
    ctx.gamePlayAPI.on();
    return () => ctx.gamePlayAPI.off();
  }, [ctx.gamePlayAPI, ready, playerId]);

  return <context.Provider value={ctx}>{props.children}</context.Provider>;
}
