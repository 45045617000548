import { useEffect, useState } from 'react';
import { useLatest, usePrevious } from 'react-use';

import { useLoadGame } from '../../../hooks/useLoadGame';
import { useIsCoordinator } from '../../../hooks/useMyInstance';
import { useQueryParam } from '../../../hooks/useQueryParam';
import { useStatsAwareTaskQueue } from '../../../hooks/useTaskQueue';
import logger from '../../../logger/logger';
import { apiService } from '../../../services/api-service';
import { SessionStatus } from '../../../types';
import {
  useGameSessionGamePackId,
  useIsGamePlayBlocksCompleted,
  useOndGameState,
  useTeamScore,
} from '../../Game/hooks';
import { useMyTeamId } from '../../Player';
import { useStreamSessionStatus } from '../../Session';
import { usePairing, usePairingGameControlAPI } from './Context';

const log = logger.scoped('pairs-game-control');

export interface PairingGameSnapshot {
  gameSessionGamePackId: string | null;
  score: number;
  isGamePlayBlocksCompleted: boolean;
  isPairingGamePack: boolean;
}

function useGameSnapshot(): PairingGameSnapshot | null {
  const ondState = useOndGameState();
  const gameSessionGamePackId = useGameSessionGamePackId();
  const isGamePlayBlocksCompleted = useIsGamePlayBlocksCompleted();
  const teamId = useMyTeamId();
  const score = useTeamScore(teamId) || 0;
  const pairing = usePairing();
  const isPairingGamePack = !!pairing?.gamePacks?.find(
    (gp) => gp.id === gameSessionGamePackId
  );

  const [snapshot, setSnapshot] = useState<PairingGameSnapshot | null>(null);

  useEffect(() => {
    // Note(guoqiang): Most of the data will be cleaned after game ended.
    // Do a snapshot here to avoid it.
    if (ondState !== 'running') return;

    setSnapshot({
      gameSessionGamePackId,
      score,
      isGamePlayBlocksCompleted,
      isPairingGamePack,
    });
  }, [
    gameSessionGamePackId,
    isGamePlayBlocksCompleted,
    isPairingGamePack,
    ondState,
    score,
  ]);

  return snapshot;
}

function useWatchPairingGame(snapshot: PairingGameSnapshot | null) {
  const pairingId = useQueryParam('pairing-id');
  const { addTask } = useStatsAwareTaskQueue({
    shouldProcess: true,
    stats: 'task-queue-pairing-game-ms',
  });
  const isCoordinator = useIsCoordinator();
  const api = usePairingGameControlAPI();
  const ondState = useOndGameState();
  const prevOndState = usePrevious(ondState);

  // subscribe pairing game from remote
  useEffect(() => {
    api.subscribe();
    return () => {
      api.reset();
    };
  }, [api]);

  // publish pairing game to remote
  useEffect(() => {
    if (!isCoordinator) return;

    addTask(async () => {
      const pairing = pairingId
        ? (await apiService.pairing.getPairingGame(pairingId)).data.pairingGame
        : null;
      api.publish(pairing);
    });
    return () => {
      addTask(() => {
        api.unpublish();
      });
    };
  }, [addTask, api, isCoordinator, pairingId]);

  // update when game session completed
  useEffect(() => {
    if (prevOndState === ondState || ondState !== 'ended') return;
    if (!isCoordinator) return;

    // Note(guoqiang): unfortunately, there is no way to know pairing data is synced to db
    // or not currently, because it's synced by session end api call and asynchronously.
    // In the future, we may need to have a new api call to decouple from session system,
    // and also move the pairing updates logics to server side.
    api.updatePairing((prev) => {
      if (
        !snapshot ||
        !snapshot.gameSessionGamePackId ||
        !snapshot.isPairingGamePack ||
        !snapshot.isGamePlayBlocksCompleted
      )
        return null;

      if (
        prev.gamePacks.length > 0 &&
        prev.completedGamePackIds?.includes(snapshot.gameSessionGamePackId)
      )
        return null;

      return {
        isGameCompleted: true,
        score: Math.max(prev.score ?? 0, snapshot.score),
        completedGamePackIds: [
          ...(prev?.completedGamePackIds || []),
          snapshot.gameSessionGamePackId,
        ],
      };
    });
  }, [api, isCoordinator, ondState, prevOndState, snapshot]);
}

function useAutoLoadNextOnDGame(snapshot: PairingGameSnapshot | null) {
  const isCoordinator = useIsCoordinator();
  const sessionStatus = useStreamSessionStatus();
  const prevSessionStatus = usePrevious(sessionStatus);
  const pairing = usePairing();
  const loadGame = useLoadGame();
  const currentGamePackIdRef = useLatest(useGameSessionGamePackId());

  useEffect(() => {
    if (
      prevSessionStatus === sessionStatus ||
      sessionStatus !== SessionStatus.Ended
    )
      return;
    if (!isCoordinator) return;

    if (
      !snapshot ||
      !snapshot.gameSessionGamePackId ||
      !snapshot.isPairingGamePack ||
      !snapshot.isGamePlayBlocksCompleted ||
      !pairing?.gamePacks.length
    )
      return;

    const playedGamePackIndex = pairing.gamePacks.findIndex(
      (gp) => gp.id === snapshot.gameSessionGamePackId
    );
    if (playedGamePackIndex === -1) return;
    let nextIndex = playedGamePackIndex + 1;
    if (nextIndex >= pairing.gamePacks.length) {
      nextIndex = pairing.asLevels ? pairing.gamePacks.length - 1 : 0;
    }
    const targetGamePack = pairing.gamePacks[nextIndex];
    if (currentGamePackIdRef.current === targetGamePack.id) return;

    log.info('Auto load next game pack', {
      nextGamePackId: targetGamePack.id,
      nextGamePackName: targetGamePack.name,
    });
    loadGame(targetGamePack);
  }, [
    currentGamePackIdRef,
    isCoordinator,
    loadGame,
    pairing?.asLevels,
    pairing?.gamePacks,
    prevSessionStatus,
    sessionStatus,
    snapshot,
  ]);
}

export const PairingGameControl = (): JSX.Element | null => {
  const snapshot = useGameSnapshot();

  useWatchPairingGame(snapshot);
  useAutoLoadNextOnDGame(snapshot);

  return null;
};
