/**
 * Keep a list of items, as long as their timestamps is newer than `now -
 * windowSizeMs`
 */
export class RollingWindowList<T extends { timestampMs: number }> {
  private storage: T[] = [];

  constructor(
    private getNowMs: () => number,
    private readonly windowSizeMs = 30000
  ) {}

  private cull(nowMs: number) {
    // Trim entries outside the window, using the newest item as "now".
    while (true) {
      const existing = this.storage[0];
      if (!existing) break;
      const timestamp = existing.timestampMs;
      const deltaMs = nowMs - timestamp;
      // If duration is within the window, then assume subsequent ones are too. Stop.
      if (deltaMs <= this.windowSizeMs) break;
      // Otherwise, remove the current item.
      this.storage.splice(0, 1);
    }
  }

  /**
   * Add the item to the window. This function tries to avoid sorting, and only
   * does so when necessary. It's best to only append with monotonically
   * increasing timestamps to avoid needing the sort operation.
   */
  push(item: T): void {
    let needsSort = false;

    const last = this.storage[this.storage.length - 1];
    if (last && item.timestampMs < last.timestampMs) {
      needsSort = true;
    }

    this.storage.push(item);
    if (needsSort) this.storage.sort((a, b) => a.timestampMs - b.timestampMs);
    this.cull(item.timestampMs);
  }

  all(): T[] {
    this.cull(this.getNowMs());
    return this.storage.slice(0);
  }

  empty(): void {
    this.storage.length = 0;
  }
}
