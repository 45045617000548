import { useCallback, useEffect } from 'react';

import {
  getFeatureQueryParam,
  getFeatureQueryParamArray,
} from '../../hooks/useFeatureQueryParam';
import { useInstance } from '../../hooks/useInstance';
import { useVideoElementFromMediaStream } from '../../hooks/useVideoElementFromMediaStream';
import {
  type ClientId,
  profileFor,
  ProfileIndex,
} from '../../services/crowd-frames';
import { HiddenCanvas } from '../../utils/canvas';
import { useCloneSingletonMediaStream } from '../Device';
import { useUserStates } from '../UserContext';
import { useMyClientId } from '../Venue/VenuePlaygroundProvider';
import { useCrowdFramesContext } from './CrowdFramesContext';
import { useInfrequentAnimationFrame } from './useInfrequentAnimationFrame';

const extractorEnabled = getFeatureQueryParam('crowd-frames-extractor');
const extractors = new Set([
  getFeatureQueryParamArray('crowd-frames-send-slot-0'),
  getFeatureQueryParamArray('crowd-frames-send-slot-1'),
  getFeatureQueryParamArray('crowd-frames-send-slot-2'),
  getFeatureQueryParamArray('crowd-frames-send-slot-3'),
]);

function ExtractorForFilmstripsWithSameRate(props: {
  filmstrips: Filmstrip[];
  video: HTMLVideoElement;
  pause: boolean;
}): null | JSX.Element {
  const ctx = useCrowdFramesContext();
  const clientId = useMyClientId() as ClientId;
  const { filmstrips, video } = props;

  const pause = filmstrips.length === 0 ? true : props.pause;

  const delay = filmstrips.length ? filmstrips[0].profile.delayMs : 10000;

  useInfrequentAnimationFrame(
    useCallback(() => {
      const targets = [];

      for (let i = 0; i < filmstrips.length; i++) {
        const filmstrip = filmstrips[i];
        if (video) filmstrip.expose(video);
        if (filmstrip.isFull()) {
          const data = filmstrip.print();
          targets.push({
            profile: filmstrip.profileIndex,
            filmstrip: { data },
          });
        }
      }

      if (targets.length) {
        ctx.service.uploadFilmstripToServer(clientId, targets.slice(0));
        targets.length = 0;
      }
    }, [clientId, ctx.service, filmstrips, video]),
    delay,
    pause
  );

  return null;
}

export function CrowdFramesExtractor(): null | JSX.Element {
  const localVideoTrack = useCloneSingletonMediaStream();
  const video = useVideoElementFromMediaStream(localVideoTrack);
  const { video: hasVideo } = useUserStates();
  const pauseExtraction = !hasVideo || !localVideoTrack || !extractorEnabled;

  // Group by FPS/delay since that is more efficient when exposing, printing,
  // and sending (fewer network messages since they can be batched, and fewer
  // render callbacks)

  const filmstrips8strip = useInstance(() => {
    const strips = [];

    const sendAll = extractors.has('all-strip8-profiles');

    if (sendAll || extractors.has('wh100x100fps8')) {
      strips.push(new Filmstrip(ProfileIndex.wh100x100fps8));
    }

    if (sendAll || extractors.has('wh75x75fps8')) {
      strips.push(new Filmstrip(ProfileIndex.wh75x75fps8));
    }

    if (sendAll || extractors.has('wh50x50fps8')) {
      strips.push(new Filmstrip(ProfileIndex.wh50x50fps8));
    }

    if (sendAll || extractors.has('wh36x36fps8')) {
      strips.push(new Filmstrip(ProfileIndex.wh36x36fps8));
    }

    return strips;
  });

  const filmstrips6strip = useInstance(() => {
    const strips = [];

    const sendAll = extractors.has('all-strip6-profiles');

    if (sendAll || extractors.has('wh100x100fps6')) {
      strips.push(new Filmstrip(ProfileIndex.wh100x100fps6));
    }

    if (sendAll || extractors.has('wh75x75fps6')) {
      strips.push(new Filmstrip(ProfileIndex.wh75x75fps6));
    }

    if (sendAll || extractors.has('wh50x50fps6')) {
      strips.push(new Filmstrip(ProfileIndex.wh50x50fps6));
    }

    if (sendAll || extractors.has('wh36x36fps6')) {
      strips.push(new Filmstrip(ProfileIndex.wh36x36fps6));
    }

    return strips;
  });

  const filmstrips4strip = useInstance(() => {
    const strips = [];

    const sendAll = extractors.has('all-strip4-profiles');

    if (sendAll || extractors.has('wh100x100fps4')) {
      strips.push(new Filmstrip(ProfileIndex.wh100x100fps4));
    }

    if (sendAll || extractors.has('wh75x75fps4')) {
      strips.push(new Filmstrip(ProfileIndex.wh75x75fps4));
    }

    if (sendAll || extractors.has('wh50x50fps4')) {
      strips.push(new Filmstrip(ProfileIndex.wh50x50fps4));
    }

    if (sendAll || extractors.has('wh36x36fps4')) {
      strips.push(new Filmstrip(ProfileIndex.wh36x36fps4));
    }

    return strips;
  });

  const filmstrips16strip = useInstance(() => {
    const strips = [];

    const sendAll = extractors.has('all-strip16-profiles');

    if (sendAll || extractors.has('wh100x100fps8strip16')) {
      strips.push(new Filmstrip(ProfileIndex.wh100x100fps8strip16));
    }

    if (sendAll || extractors.has('wh75x75fps8strip16')) {
      strips.push(new Filmstrip(ProfileIndex.wh75x75fps8strip16));
    }

    if (sendAll || extractors.has('wh50x50fps8strip16')) {
      strips.push(new Filmstrip(ProfileIndex.wh50x50fps8strip16));
    }

    if (sendAll || extractors.has('wh36x36fps8strip16')) {
      strips.push(new Filmstrip(ProfileIndex.wh36x36fps8strip16));
    }

    return strips;
  });

  const filmstrips1strip = useInstance(() => {
    const strips = [];

    const sendAll = extractors.has('all-strip1-profiles');

    if (sendAll || extractors.has('wh100x100fps8strip1')) {
      strips.push(new Filmstrip(ProfileIndex.wh100x100fps8strip1));
    }

    if (sendAll || extractors.has('wh75x75fps8strip1')) {
      strips.push(new Filmstrip(ProfileIndex.wh75x75fps8strip1));
    }

    if (sendAll || extractors.has('wh50x50fps8strip1')) {
      strips.push(new Filmstrip(ProfileIndex.wh50x50fps8strip1));
    }

    if (sendAll || extractors.has('wh36x36fps8strip1')) {
      strips.push(new Filmstrip(ProfileIndex.wh36x36fps8strip1));
    }

    return strips;
  });

  useEffect(() => {
    return () => {
      filmstrips8strip.forEach((f) => f.destroy());
      filmstrips6strip.forEach((f) => f.destroy());
      filmstrips4strip.forEach((f) => f.destroy());
      filmstrips16strip.forEach((f) => f.destroy());
      filmstrips1strip.forEach((f) => f.destroy());
    };
  }, [
    filmstrips16strip,
    filmstrips1strip,
    filmstrips4strip,
    filmstrips6strip,
    filmstrips8strip,
  ]);

  return (
    <>
      <ExtractorForFilmstripsWithSameRate
        filmstrips={filmstrips8strip}
        video={video}
        pause={pauseExtraction}
      />
      <ExtractorForFilmstripsWithSameRate
        filmstrips={filmstrips6strip}
        video={video}
        pause={pauseExtraction}
      />
      <ExtractorForFilmstripsWithSameRate
        filmstrips={filmstrips4strip}
        video={video}
        pause={pauseExtraction}
      />
      <ExtractorForFilmstripsWithSameRate
        filmstrips={filmstrips16strip}
        video={video}
        pause={pauseExtraction}
      />
      <ExtractorForFilmstripsWithSameRate
        filmstrips={filmstrips1strip}
        video={video}
        pause={pauseExtraction}
      />
    </>
  );
}

class Filmstrip {
  constructor(
    public readonly profileIndex: ProfileIndex,
    public readonly profile = profileFor(profileIndex),
    private ocvs = new HiddenCanvas('crowd-frames'),
    private ctx = ocvs.cvs.getContext('2d', {
      alpha: false,
      willReadFrequently: getFeatureQueryParam(
        'crowd-frames-extractor-will-read-frequently'
      ),
    }),
    private frameIdx = 0
  ) {
    ocvs.cvs.width = profile.width;
    ocvs.cvs.height = profile.height * profile.perStrip;
    this.ocvs.attach();
  }

  destroy() {
    this.ocvs.detach();
  }

  isFull() {
    return this.profile.perStrip === this.frameIdx;
  }

  print() {
    this.frameIdx = 0;

    // toBlob results in a smaller message, but tends to take inconsistent time
    // in Chrome (Firefox is super fast).
    return this.ocvs.cvs.toDataURL('image/jpeg', 0.3);
  }

  expose(source: HTMLVideoElement) {
    const width = source.videoWidth;
    const height = source.videoHeight;

    const a = width > height ? height : width;

    const offsetX = width / 2 - a / 2;
    const offsetY = height / 2 - a / 2;

    if (!this.ctx) return;

    this.ctx.drawImage(
      source,
      offsetX,
      offsetY,
      a,
      a,
      0,
      this.frameIdx * this.profile.height,
      this.profile.width,
      this.profile.height
    );
    this.frameIdx++;
  }
}
