import Sockette from 'sockette';

import {
  type CFMsg,
  CFMsgKind,
  type ClientId,
  decode,
  encode,
  fromProfileAddress,
  type ProfileAddress,
  type ProfileIndex,
  ReceiveFilmstripFromServerMsg,
  RegisterClientForTargetReceptionMsg,
  UnregisterClientForTargetReceptionMsg,
  UploadClientFilmstripToServerMsg,
} from '@lp-lib/crowd-frames-schema';

import defaultConfig from '../../config';
import { default as globalLogger } from '../../logger/logger';
import { assertExhaustive } from '../../utils/common';
import { rsCounter } from '../../utils/rstats.client';

export * from '@lp-lib/crowd-frames-schema';

type MessageData = ArrayBuffer;

// TODO: gross, this be default present in the schema
export type ReceiveFilmstripFromServerMsgData = NonNullable<
  ReturnType<typeof ReceiveFilmstripFromServerMsg.from>
>;

export type ReceiveFilmstripHandler = (
  msg: ReceiveFilmstripFromServerMsgData
) => void;

// https://github.com/lukeed/sockette/issues/43
type SocketEvent = Event & { target: WebSocket };

const MAX_BUFFERED_AMOUNT = 1024 * 1000; // 1MB

const logger = globalLogger.scoped('crowd-frames');

type ProfileIndexFilmstrip = {
  profile: ProfileIndex;
  filmstrip: { data: string };
};

export interface ICrowdFramesService {
  outgoing: MessageData[];
  destroy(): void;
  enqueueOutgoing(msg: CFMsg): void;
  uploadFilmstripToServer(
    cid: ClientId,
    targets: ProfileIndexFilmstrip[]
  ): void;
  registerUserProfilePairReceptions(addresses: ProfileAddress[]): void;
  unregisterUserProfilePairReceptions(addresses: ProfileAddress[]): void;
  addReceiveFilmstripListener(cb: ReceiveFilmstripHandler): void;
  removeReceiveFilmstripListener(cb: ReceiveFilmstripHandler): void;
}

export class CrowdFramesService implements ICrowdFramesService {
  // This is public readonly to allow for test introspection.
  public readonly outgoing: MessageData[] = [];
  // private incoming: MessageData[] = [];
  // private handlers = new Set<Handler>();
  private socket: WebSocket | null = null;
  private drainRef: null | number = null;

  private ws: Sockette;

  private onReceiveFilmstripCbs = new Set<ReceiveFilmstripHandler>();

  private registers = new Set<ProfileAddress>();

  constructor(
    private userId: string,
    private clientId: ClientId,
    token: string | null,
    config = defaultConfig.crowdFrames,
    WSSocketteCtor = Sockette
  ) {
    if (!token) throw new Error('Authentication token is required');

    // Websockets do not support custom headers, so we have to use a query
    // string instead.
    const url = new URL(config.url);
    url.searchParams.append('token', token);
    url.searchParams.append('cid', clientId);

    // TODO: send traceparent header once supported by apm:
    // https://github.com/elastic/apm-agent-rum-js/issues/468

    this.ws = new WSSocketteCtor(url.toString(), {
      timeout: 5000,
      onopen: (ev) => {
        logger.info('open');

        this.socket = (ev as SocketEvent).target;
        this.socket.binaryType = 'arraybuffer';

        // If we reconnect, resend the known registrations to resume receiving those frames.
        if (this.registers.size)
          this.registerUserProfilePairReceptions([...this.registers]);
      },
      onclose: () => {
        logger.info('close');
        this.socket = null;
      },
      onerror: (ev) => {
        const code =
          (ev as Event & { code: string | number | undefined }).code ??
          'unknown';
        const readyState = this.socket?.readyState ?? `unknown`;
        const url = this.socket?.url ?? `unknown`;
        const cause = {
          code,
          readyState,
          url,
        };
        const error = new Error(`CrowdFramesSocketError`, { cause });
        logger.error('crowdframes socket error', error, cause);
      },
      onmessage: (ev) => {
        if (ev.data instanceof ArrayBuffer) {
          rsCounter('crowd-frame-payload-decoded-ms')?.start();
          const parsed = decode(ev.data);
          rsCounter('crowd-frame-payload-decoded-ms')?.end();
          if (!parsed) {
            logger.info('recv corrupted data');
            return;
          }

          switch (parsed.kind) {
            case CFMsgKind.ReceiveFilmstripFromServer: {
              rsCounter('crowd-frame-accepted-ms')?.start();
              const msg = ReceiveFilmstripFromServerMsg.from(parsed);
              if (!msg) {
                logger.info(
                  'failed to convert parsed message to ReceiveFilmstripFromServerMsg'
                );
                return;
              }

              for (const cb of this.onReceiveFilmstripCbs) {
                cb(msg);
              }
              rsCounter('crowd-frame-accepted-ms')?.end();
              return;
            }

            case CFMsgKind.RegisterClientForTargetReception:
            case CFMsgKind.UnregisterClientForTargetReception:
            case CFMsgKind.UploadClientFilmstripToServer: {
              return;
            }

            default: {
              assertExhaustive(parsed);
              return;
            }
          }
        } else {
          logger.info('recv unknown data type');
        }
      },
    });

    this.drainRef = setInterval(
      () => this.drainOutgoing(),
      1000
    ) as unknown as number;
  }

  destroy(): void {
    this.ws.close();
    if (this.drainRef) clearInterval(this.drainRef);
    this.registers.clear();
    this.outgoing.length = 0;
    this.onReceiveFilmstripCbs.clear();
    logger.info('destroy');
  }

  private drainOutgoing(): void {
    while (
      this.outgoing.length &&
      this.socket &&
      this.socket.bufferedAmount < MAX_BUFFERED_AMOUNT
    ) {
      const msg = this.outgoing.shift();
      if (!msg) continue;
      this.ws.send(msg);
    }
  }

  enqueueOutgoing(msg: CFMsg): void {
    const shouldQueue = messageShouldQueue(msg);
    const socketIsClear =
      this.socket && this.socket.bufferedAmount < MAX_BUFFERED_AMOUNT;

    const buffer = encode(msg);

    if (socketIsClear) {
      this.ws.send(buffer);
    } else if (!socketIsClear && shouldQueue) {
      logger.debug('queued msg', {
        msgKind: msg.kind,
        shouldQueue,
        socketIsClear,
      });
      this.outgoing.push(buffer);
    } else {
      logger.debug('dropped msg', {
        msgKind: msg.kind,
        shouldQueue,
        socketIsClear,
      });
    }
  }

  uploadFilmstripToServer(
    cid: ClientId,
    targets: ProfileIndexFilmstrip[]
  ): void {
    const msg = UploadClientFilmstripToServerMsg.create({
      cid,
      targets,
    });
    this.enqueueOutgoing(msg);
  }

  registerUserProfilePairReceptions(addresses: ProfileAddress[]): void {
    const targets = addresses
      .map((p) => {
        const parsed = fromProfileAddress(p);
        if (!parsed) return null;
        return { cid: parsed.clientId, profile: parsed.profile };
      })
      .filter(Boolean) as { cid: ClientId; profile: ProfileIndex }[];

    // Nothing to do
    if (addresses.length === 0) return;

    addresses.forEach((address) => this.registers.add(address));
    logger.debug('registering', { clientId: this.clientId, addresses });

    const msg = RegisterClientForTargetReceptionMsg.create({
      cid: this.clientId,
      uid: this.userId,
      targets,
    });
    this.enqueueOutgoing(msg);
  }

  unregisterUserProfilePairReceptions(addresses: ProfileAddress[]): void {
    const targets = addresses
      .map((p) => {
        const parsed = fromProfileAddress(p);
        if (!parsed) return null;
        return { cid: parsed.clientId, profile: parsed.profile };
      })
      .filter(Boolean) as { cid: ClientId; profile: ProfileIndex }[];

    // Nothing to do
    if (addresses.length === 0) return;

    addresses.forEach((address) => this.registers.delete(address));
    logger.debug('unregistering', { clientId: this.clientId, addresses });

    const msg = UnregisterClientForTargetReceptionMsg.create({
      cid: this.clientId,
      uid: this.userId,
      targets,
    });
    this.enqueueOutgoing(msg);
  }

  addReceiveFilmstripListener(cb: ReceiveFilmstripHandler): void {
    this.onReceiveFilmstripCbs.add(cb);
  }

  removeReceiveFilmstripListener(cb: ReceiveFilmstripHandler): boolean {
    return this.onReceiveFilmstripCbs.delete(cb);
  }
}

function messageShouldQueue(msg: CFMsg): boolean {
  switch (msg.kind) {
    case CFMsgKind.RegisterClientForTargetReception:
    case CFMsgKind.UnregisterClientForTargetReception: {
      return true;
    }

    case CFMsgKind.UploadClientFilmstripToServer:
    case CFMsgKind.ReceiveFilmstripFromServer: {
      return false;
    }

    default: {
      assertExhaustive(msg);
      return false;
    }
  }
}

/* eslint-disable @typescript-eslint/no-empty-function */
export class DummyCrowdFramesService implements ICrowdFramesService {
  outgoing = [];
  destroy(): void {}
  enqueueOutgoing(): void {}
  addReceiveFilmstripListener(): void {}
  removeReceiveFilmstripListener(): void {}
  registerUserProfilePairReceptions(): void {}
  unregisterUserProfilePairReceptions(): void {}
  uploadFilmstripToServer(): void {}
}
/* eslint-enable @typescript-eslint/no-empty-function */
