import sortBy from 'lodash/sortBy';

import { EnumsProgramCadenceFrequency } from '@lp-lib/api-service-client/public';

import config from '../../../config';
import { type IntrosProgramRound } from '../../../types/program';
import { useProgramRounds } from '../usePrograms';

const FREQUENCY_CONFIG = Object.freeze([
  EnumsProgramCadenceFrequency.ProgramCadenceFrequencyEveryWeek,
  EnumsProgramCadenceFrequency.ProgramCadenceFrequencyEveryTwoWeeks,
  EnumsProgramCadenceFrequency.ProgramCadenceFrequencyEveryFourWeeks,
]);

export class IntrosProgramUtils {
  static SortRounds(rounds: IntrosProgramRound[]) {
    return sortBy(rounds, (r) => r.extensions?.frequency ?? Number.MAX_VALUE);
  }

  static FormatRoundLabel(r: IntrosProgramRound) {
    return `${r.extensions?.frequency ?? 0} Week Cadence`;
  }

  static GetCadenceConfig(cfg = config) {
    return {
      frequencies: FREQUENCY_CONFIG,
      roundTimeToMinutes: cfg.program.introsRoundTimeToMinutes,
    };
  }
}

export function useIntrosPastRounds(linkId: string) {
  const swr = useProgramRounds(linkId);
  const rounds = swr.data ?? [];

  const filteredRounds = rounds
    .filter((r) => r.status === 'completed' || r.status === 'inProgress')
    .sort((a, b) => {
      const aTs = a.startedAt ? new Date(a.startedAt) : new Date();
      const bTs = b.startedAt ? new Date(b.startedAt) : new Date();
      return bTs.getTime() - aTs.getTime();
    });

  return { ...swr, data: filteredRounds };
}
