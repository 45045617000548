import { useNavigate } from '@remix-run/react';
import { useEffect, useState } from 'react';

import { useFirebaseContext } from '../../components/Firebase';
import {
  useGameHostingControllerGetter,
  useGameHostingCoordinatorGetter,
} from '../../components/Game/GameHostingProvider';
import { LoadingSpinner } from '../../components/LoadingSpinner';
import { useNotificationDataSourceRedux } from '../../components/Notification/Context';
import {
  useIsParticipantStoreInited,
  useLastJoinedParticipantGetter,
  useNumSeatOccupyingParticipantsGetter,
} from '../../components/Player';
import { useUserGetter } from '../../components/UserContext';
import {
  useMyClientIdGetter,
  useMyClientTypeGetter,
  useVenueId,
  useVenueOwnerGetter,
} from '../../components/Venue';
import { useFetchVenueSeatCapFromFirebase } from '../../components/Venue/useFetchVenueSeatCapFromFirebase';
import { useFeatureQueryParam } from '../../hooks/useFeatureQueryParam';
import { useLiveCallback } from '../../hooks/useLiveCallback';
import logger from '../../logger/logger';
import { ClientType, isStaff, NotificationType, RoleUtils } from '../../types';
import { uuidv4 } from '../../utils/common';

const log = logger.scoped('venue-capacity-check');

export function useCanJoinGetter() {
  const enabled = useFeatureQueryParam('venue-capacity-check');
  const getVenueOwner = useVenueOwnerGetter();
  const getSeatCap = useFetchVenueSeatCapFromFirebase();
  const getNumParticipants = useNumSeatOccupyingParticipantsGetter();
  const getMyClientId = useMyClientIdGetter();
  const getMyClientType = useMyClientTypeGetter();
  const getUser = useUserGetter();
  const getCoordinator = useGameHostingCoordinatorGetter();
  const getController = useGameHostingControllerGetter();
  const notifyCapacityLimit = useCapacityLimitNotifier();
  const { svc } = useFirebaseContext();

  return useLiveCallback(async () => {
    if (!enabled) {
      log.info('capacity check passed – feature disabled');
      return true;
    }

    // host can always join (including cloud controller)
    const clientType = getMyClientType();
    if (clientType === ClientType.Host) {
      log.info('capacity check passed – host');
      return true;
    }

    // permit admins and staff to join
    const user = getUser();
    if (RoleUtils.isAdmin(user) || isStaff(user)) {
      log.info('capacity check passed – admin or staff');
      return true;
    }

    // venue owner can always join.
    const venueOwner = getVenueOwner();
    if (venueOwner?.id === user.id) {
      log.info('capacity check passed – venue owner');
      return true;
    }

    // coordinator/controller can always join
    const myClientId = getMyClientId();
    const coordinator = getCoordinator();
    const controller = getController();
    if (
      coordinator?.clientId === myClientId ||
      controller?.clientId === myClientId
    ) {
      log.info('capacity check passed – coordinator or controller');
      return true;
    }

    log.debug('fetching seat cap from firebase');
    let seatCap = undefined;
    try {
      seatCap = await getSeatCap(svc);
    } catch (e) {
      log.error('failed to fetch seat cap from firebase', e);
      // let them in...this is an edge case, and we should prefer to not hinder the experience.
    }
    if (!seatCap) {
      log.info('capacity check passed – seat cap not set');
      return true;
    }
    const numParticipants = getNumParticipants();

    const canJoin = numParticipants < seatCap;
    log.info('capacity check result', {
      canJoin,
      seatCap,
      numParticipants,
    });
    if (!canJoin) {
      notifyCapacityLimit(seatCap);
    }
    return canJoin;
  });
}

function useCapacityLimitNotifier() {
  const notificationDataSource = useNotificationDataSourceRedux();
  const findParticipant = useLastJoinedParticipantGetter();
  const getCoordinator = useGameHostingCoordinatorGetter();
  const getVenueOwner = useVenueOwnerGetter();

  return useLiveCallback((seatCap: number) => {
    let toUserClientId = undefined;

    const coordinator = getCoordinator();
    if (coordinator) {
      toUserClientId = coordinator.clientId;
    } else {
      // look for the venue owner...
      const venueOwner = getVenueOwner();
      if (venueOwner) {
        const participant = findParticipant(venueOwner.id);
        toUserClientId = participant?.clientId;
      }
    }

    if (!toUserClientId) return;

    notificationDataSource.send({
      id: uuidv4(),
      toUserClientId,
      type: NotificationType.GuestCouldNotJoin,
      createdAt: Date.now(),
      metadata: {
        seatCap,
      },
    });
  });
}

export function VenueCapacityCheck(props: { children?: React.ReactNode }) {
  const isParticipantStoreInited = useIsParticipantStoreInited();
  const getCanJoin = useCanJoinGetter();
  const venueId = useVenueId();
  const navigate = useNavigate();
  const enabled = useFeatureQueryParam('venue-capacity-check');

  // we may need to wait to know if we can join or not. undefined means we don't know yet.
  const [canJoin, setCanJoin] = useState<boolean | undefined>(
    !enabled ? true : undefined
  );

  const redirectToAtCapacity = useLiveCallback(() => {
    navigate(`/venue/${venueId}/at-capacity`);
  });

  useEffect(() => {
    async function run() {
      // we've already answered. no need to do anything.
      if (canJoin !== undefined || !isParticipantStoreInited) return;

      log.debug('running capacity check');
      const enabled = await getCanJoin();
      setCanJoin(enabled);

      if (!enabled) {
        log.info('capacity check failed, redirecting out of venue');
        redirectToAtCapacity();
      }
    }
    run();
  }, [isParticipantStoreInited, getCanJoin, redirectToAtCapacity, canJoin]);

  if (canJoin) {
    return <>{props.children}</>;
  } else {
    return <LoadingSpinner />;
  }
}
