const ErrorIcon = ({ className }: { className?: string }): JSX.Element => {
  return (
    <svg
      className={className || 'w-3.5 h-3.5 fill-[#EE3529]'}
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 14 14'
    >
      <path
        fillRule='evenodd'
        d='M7 13A6 6 0 107 1a6 6 0 000 12z'
        clipRule='evenodd'
      />
      <path fill='#fff' d='M7 11.673a1.2 1.2 0 100-2.4 1.2 1.2 0 000 2.4z' />
      <path
        fill='#fff'
        fillRule='evenodd'
        d='M5.688 3.686c0-.71.587-1.286 1.312-1.286.725 0 1.312.576 1.312 1.286 0 .097-.01.193-.032.284l-.405 3.573A.866.866 0 017 8.4a.866.866 0 01-.875-.857L5.72 3.97a1.265 1.265 0 01-.032-.284z'
        clipRule='evenodd'
      />
    </svg>
  );
};

export { ErrorIcon };
