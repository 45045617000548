import {
  assertExhaustive,
  type ScoreboardBlock,
  ScoreboardBlockGameSessionStatus,
} from '@lp-lib/game';

import { ScoreboardIcon } from '../../../icons/ScoreboardIcon';
import { FilledSquareIcon } from '../../../icons/SquareIcon';
import { useGameSessionBlock, useGameSessionStatus } from '../../hooks';
import { next, present, triggerBlockTitleAnim } from '../../store';
import {
  BlockControllerActionButton,
  type ControllerProps,
  StreamRequiredNotice,
  useBlockControllerBlockTitlePlaying,
} from '../Common/Controller/Internal';
import { SBSubmissionsView } from './ScoreboardSubmissionsView';

export const ScoreboardBlockController = (
  props: ControllerProps<ScoreboardBlock>
): JSX.Element => {
  const { selectedBlockIndex, onEndBlock, setEditPointsData } = props;

  const gameSessionStatus =
    useGameSessionStatus<ScoreboardBlockGameSessionStatus>();
  const gameSessionBlock = useGameSessionBlock();
  const block = props.selectedBlock;
  const blockTitlePlayingAction = useBlockControllerBlockTitlePlaying(block);

  if (!block) return <></>;

  const onShowScoreboard = async () => {
    await triggerBlockTitleAnim(block);
    await present(block);
    await next();
  };

  let contentComponent = (
    <SBSubmissionsView block={block} setEditPointsData={setEditPointsData} />
  );

  let actionComponent = (
    <>
      <BlockControllerActionButton onClick={onEndBlock} isSecondary={true}>
        Skip Scoreboard
      </BlockControllerActionButton>
      <BlockControllerActionButton
        onClick={onShowScoreboard}
        icon={ScoreboardIcon}
      >
        Show Scoreboard
      </BlockControllerActionButton>
    </>
  );

  if (block.id === gameSessionBlock?.id) {
    switch (gameSessionStatus) {
      case ScoreboardBlockGameSessionStatus.LOADED: {
        break;
      }
      case ScoreboardBlockGameSessionStatus.PRESENTING:
        actionComponent = <></>;
        break;
      case ScoreboardBlockGameSessionStatus.SCOREBOARD: {
        actionComponent = (
          <BlockControllerActionButton
            onClick={onEndBlock}
            icon={FilledSquareIcon}
          >
            End Block Sequence
          </BlockControllerActionButton>
        );
        break;
      }
      case ScoreboardBlockGameSessionStatus.END:
      case null:
      case undefined: {
        contentComponent = <></>;
        actionComponent = <></>;
        break;
      }

      default: {
        assertExhaustive(gameSessionStatus);
      }
    }
  }

  // NOTE: this must be outside of the main gameSessionStatus truthy check
  // because it mainly activates on the transition from LOADED (0), which is
  // falsy.
  actionComponent = blockTitlePlayingAction ?? actionComponent;

  return (
    <>
      {selectedBlockIndex !== null ? (
        <div className='w-full p-2.5 pt-0 relative h-full flex flex-col justify-between items-center text-white text-sms overflow-hidden'>
          {contentComponent}
          {actionComponent}
          <StreamRequiredNotice />
        </div>
      ) : null}
    </>
  );
};
