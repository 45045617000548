import { proxy } from 'valtio';

import { type OverRoastedBlockDetailScore } from '@lp-lib/game';

import {
  markSnapshottable,
  type ValtioSnapshottable,
  ValtioUtils,
} from '../../../../../utils/valtio';
import { Clock } from '../../../../Clock';
import { type FirebaseService } from '../../../../Firebase';
import { increment } from '../../../../Firebase/utils';
import { updateBlockDetailScore } from '../../../store';
import { CupState, DispenserState, type Machine, type Order } from '../types';
import { log, OverRoastedFirebaseUtils, OverRoastedUtils } from '../utils';

export interface OverRoastedGroupControlState {
  machineMap: Nullable<Record<string, Machine>>;
  orderMap: Nullable<Record<string, Order>>;
}

function initialState(): OverRoastedGroupControlState {
  return {
    machineMap: null,
    orderMap: null,
  };
}

export class OverRoastedGroupControl {
  public state: ValtioSnapshottable<OverRoastedGroupControlState>;

  constructor(
    venueId: string,
    public groupId: string,
    firebaseService: FirebaseService,
    private machinesHandle = OverRoastedFirebaseUtils.MachinesHandle(
      firebaseService,
      venueId,
      groupId
    ),
    private ordersHandle = OverRoastedFirebaseUtils.OrdersHandle(
      firebaseService,
      venueId,
      groupId
    ),
    private summaryHandle = OverRoastedFirebaseUtils.SummaryHandle(
      firebaseService,
      venueId
    )
  ) {
    this.state = markSnapshottable(
      proxy<OverRoastedGroupControlState>(initialState())
    );
  }

  init(): void {
    this.machinesHandle.on((val) =>
      ValtioUtils.set(this.state, 'machineMap', val)
    );
    this.ordersHandle.on((val) => ValtioUtils.set(this.state, 'orderMap', val));
  }

  start(): void {
    return;
  }

  stop(): void {
    this.machinesHandle.off();
    this.ordersHandle.off();
  }

  async resetMachine(machineId: string): Promise<void> {
    const result = await this.machinesHandle.ref
      .child(machineId)
      .transaction((machine) => {
        if (!machine) return;

        if (
          ![CupState.Deleting, CupState.Matched, CupState.Mismatched].includes(
            machine.cup.state
          )
        )
          return;

        machine.dispenser = {
          state: DispenserState.Default,
          stateChangedAt: Clock.instance().now(),
        };
        machine.cup = {
          state: CupState.Default,
          stateChangedAt: Clock.instance().now(),
          ingredients: [],
        };

        return machine;
      });
    if (!result.committed) {
      log.warn('failed to resetMachine');
      return;
    }
  }

  async matchOrder(machineId: string): Promise<void> {
    // This is for the edge case, for example:
    //  - two orders both require only chocolate
    //  - two cups are both filled with chocolate and start filling at the same time
    for (let i = 0; i < 10; i++) {
      const machine = this.state.machineMap?.[machineId];
      if (!machine) {
        log.warn('match order failed, machine not found', { machineId });
        return;
      }

      const matchedOrder = Object.values(this.state.orderMap ?? {})
        .sort((a, b) => a.seqNum - b.seqNum)
        .find(
          (o) =>
            o.truckId === machine.truckId &&
            !o.matchedMachineId &&
            OverRoastedUtils.IsMatchOrder(machine.cup, o)
        );
      if (!matchedOrder) {
        log.warn('no order matched', { machine });
        return;
      }

      const result = await this.ordersHandle.ref
        .child(matchedOrder.id)
        .transaction((order) => {
          if (!order) return;
          if (!!order.matchedMachineId) return;
          order.matchedMachineId = machineId;
          return order;
        });
      if (result.committed) return;

      log.warn('match order failed, transaction not committed', {
        machine,
        matchedOrder,
      });
    }
  }

  async releaseOrder(machineId: string): Promise<void> {
    const machine = this.state.machineMap?.[machineId];
    if (!machine) {
      log.warn('match order failed, machine not found', { machineId });
      return;
    }

    const order = Object.values(this.state.orderMap ?? {}).find(
      (o) => o.truckId === machine.truckId && o.matchedMachineId === machineId
    );
    if (!order) return;

    await this.ordersHandle.ref.child(order.id).transaction((order) => {
      if (!order) return;
      if (order.matchedMachineId !== machineId) return;
      delete order['matchedMachineId'];
      return order;
    });
  }

  async deleteOrder(machineId: string): Promise<void> {
    const machine = this.state.machineMap?.[machineId];
    if (!machine) {
      log.warn('match order failed, machine not found', { machineId });
      return;
    }

    const order = Object.values(this.state.orderMap ?? {}).find(
      (o) => o.truckId === machine.truckId && o.matchedMachineId === machineId
    );
    if (!order) return;

    this.ordersHandle.ref.child(order.id).remove();
  }

  async updateGameSummary(score: number, perfect: boolean): Promise<void> {
    await this.summaryHandle.ref.child(this.groupId).transaction((summary) => {
      if (!summary) {
        return {
          groupId: this.groupId,
          score,
          completedOrders: 1,
          perfectOrders: perfect ? 1 : 0,
          tutorialCompleted: false,
        };
      }
      return {
        groupId: this.groupId,
        score: summary.score + score,
        completedOrders: summary.completedOrders + 1,
        perfectOrders: summary.perfectOrders + (perfect ? 1 : 0),
        tutorialCompleted: summary.tutorialCompleted || false,
      };
    });
  }

  async incrBlockDetailScore(score: number): Promise<void> {
    if (score === 0) return;

    await updateBlockDetailScore<OverRoastedBlockDetailScore>(this.groupId, {
      score: increment(score),
      submittedAt: Clock.instance().now(),
    });
  }
}
