import {
  createContext,
  type ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from 'react';
import { proxy, useSnapshot } from 'valtio';
import { devtools } from 'valtio/utils';

import { type Logger } from '@lp-lib/logger-base';
import { ConnectionStatus } from '@lp-lib/shared-schema';

import logger from '../../logger/logger';
import { type Participant } from '../../types';
import {
  markSnapshottable,
  type ValtioSnapshottable,
  ValtioUtils,
} from '../../utils/valtio';
import {
  type FirebaseService,
  FirebaseValueHandle,
  useFirebaseContext,
  useIsFirebaseConnected,
} from '../Firebase';
import { useHeartbeat, useIsHeartbeatExpired, useParticipant } from '../Player';

type Identifiable = {
  clientId: string;
};

type GameHostingState = {
  coordinator: Nullable<Identifiable>;
  controller: Nullable<Identifiable>;
};

type Dependencies = {
  isHeartbeatExpired: (lastHeartbeatAt: number | undefined) => boolean;
};

class GameHostingAPI {
  private _state;
  // this is used to track if the current user is registered as the
  // coordinator or controller
  private registered: {
    coordinator: string | null;
    controller: string | null;
  };
  private handles;
  constructor(
    readonly venueId: string,
    readonly svc: FirebaseService,
    readonly log: Logger,
    private deps: Dependencies
  ) {
    this._state = markSnapshottable(
      proxy<GameHostingState>(this.initialState())
    );
    this.registered = {
      coordinator: null,
      controller: null,
    };
    this.handles = {
      coordinator: this.buildHandle(svc, venueId, 'coordinator'),
      controller: this.buildHandle(svc, venueId, 'controller'),
    };
  }

  async register(
    val: string,
    params: { asController?: boolean; asCoordinator?: boolean; force?: boolean }
  ) {
    this.log.debug('update game hosting', { val, params });
    if (params.asCoordinator) {
      await this.registerByType('coordinator', val, params.force);
    }
    if (params.asController) {
      await this.registerByType('controller', val, params.force);
    }
  }

  get state(): Readonly<ValtioSnapshottable<GameHostingState>> {
    return this._state;
  }

  private async registerByType(
    type: keyof GameHostingAPI['registered'],
    val: string,
    force?: boolean
  ) {
    const snap = await this.handles[type].ref.get();
    const currVal = snap.val();
    if (currVal?.clientId) {
      const participant = (
        await this.svc
          .prefixedSafeRef<Nullable<Participant>>(
            `participants/${currVal.clientId}`
          )
          .get()
      ).val();
      const heartbeat = (
        await this.svc
          .prefixedSafeRef<Nullable<{ lastHeartbeatAt: number }>>(
            `heartbeat/${currVal.clientId}`
          )
          .get()
      ).val();
      const disconnected =
        participant?.status === ConnectionStatus.Disconnected;
      const heartbeatExpired = this.deps.isHeartbeatExpired(
        heartbeat?.lastHeartbeatAt
      );
      if (!participant || disconnected || heartbeatExpired) {
        force = true;
      }
      this.log.info(`detect existing ${type}`, {
        curr: currVal,
        participant,
        heartbeat,
        heartbeatExpired,
        force,
      });
    }
    const result = await this.handles[type].ref.transaction((current) => {
      this.log.info(`attempt to register user as the ${type}`, {
        val,
        force,
      });
      if (current && !force) return;
      return { clientId: val };
    });
    if (!result.committed) {
      this.log.info(`fail to register as the ${type}, it's taken`, {
        val: result.snapshot?.val(),
        force,
      });
      return;
    }
    this.log.info(`registered user as the ${type}`, {
      val,
      force,
    });
    this.registered[type] = val;
  }

  on(): void {
    this.onByType('coordinator');
    this.onByType('controller');
  }
  off(): void {
    this.handles.coordinator.off();
    this.handles.controller.off();
  }

  private onByType(type: keyof GameHostingAPI['registered']) {
    this.handles[type].on(async (val) => {
      if (this.registered[type] && val?.clientId !== this.registered[type]) {
        this.registered[type] = null;
      }
      ValtioUtils.set(this._state, type, val);
    });
  }

  private buildHandle(
    svc: FirebaseService,
    venueId: string,
    path: 'coordinator' | 'controller'
  ): FirebaseValueHandle<Identifiable> {
    return new FirebaseValueHandle(
      svc.prefixedSafeRef(`game-hosting/${venueId}/${path}`)
    );
  }

  private initialState(): GameHostingState {
    return {
      coordinator: null,
      controller: null,
    };
  }
}

type GameHostingContext = GameHostingAPI;

const Context = createContext<null | GameHostingContext>(null);

function useGameHostingContext(): GameHostingContext {
  const ctx = useContext(Context);
  if (!ctx) throw new Error('GameHostingContext is not in the tree!');
  return ctx;
}

export function useGameHostingAPI(): GameHostingAPI {
  return useGameHostingContext();
}

export function useGameHostingCoordinator(): Nullable<Identifiable> {
  const api = useGameHostingAPI();
  return useSnapshot(api.state).coordinator;
}

export function useGameHostingController(): Nullable<Identifiable> {
  const api = useGameHostingAPI();
  return useSnapshot(api.state).controller;
}

export function useGameHostingCoordinatorGetter() {
  const api = useGameHostingAPI();
  return useCallback(() => api.state.coordinator, [api]);
}

export function useGameHostingControllerGetter() {
  const api = useGameHostingAPI();
  return useCallback(() => api.state.controller, [api]);
}

/**
 * There could be an existing value in firebase, but the coordinator could
 * actually be disconnected or our local client could be out of date in a way
 * that prevents us from knowing if the coordinator is truly active. Return
 * `null` until we know for sure.
 */
export function useGameHostingCoordinatorActive(): Nullable<Identifiable> {
  const coordinator = useGameHostingCoordinator();
  const lastHeartbeatAt = useHeartbeat(coordinator?.clientId);
  const pCoordinator = useParticipant(coordinator?.clientId);
  const isHeartbeatExpired = useIsHeartbeatExpired();
  if (
    !pCoordinator ||
    pCoordinator.status === ConnectionStatus.Disconnected ||
    isHeartbeatExpired(lastHeartbeatAt)
  )
    return null;
  else return coordinator;
}

export function GameHostingProvider(props: {
  venueId: string;
  children?: ReactNode;
}): JSX.Element {
  const firebaseConnected = useIsFirebaseConnected();
  const { svc } = useFirebaseContext();
  const { venueId } = props;
  const isHeartbeatExpired = useIsHeartbeatExpired();
  const api = useMemo(
    () =>
      new GameHostingAPI(venueId, svc, logger.scoped('game-hosting'), {
        isHeartbeatExpired,
      }),
    [isHeartbeatExpired, svc, venueId]
  );

  useEffect(() => {
    if (!firebaseConnected) return;
    api.on();
    return () => api.off();
  }, [api, firebaseConnected]);

  useEffect(() => {
    const unsub = devtools(api.state, { name: 'Game Hosting Store' });
    return () => {
      unsub?.();
    };
  }, [api.state]);

  return <Context.Provider value={api}>{props.children}</Context.Provider>;
}
