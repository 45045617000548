import { type RapidBlock } from '@lp-lib/game';

import { ClientTypeUtils } from '../../../../types';
import { useNextPersistentPointsScore } from '../../../PersistentPoints/Provider';
import { useMyTeamId } from '../../../Player';
import { useMyClientType } from '../../../Venue';
import { useGameSessionBlock } from '../../hooks';
import { GamePlayAnswerLayout } from '../Common/GamePlay/GamePlayAnswer';
import { useRapidCorrectAnswers } from './hooks';

export function RapidAnswer(): JSX.Element {
  const nextScoreDisplay = Math.round(useNextPersistentPointsScore() ?? 0);

  const myClientType = useMyClientType();
  const isHost = ClientTypeUtils.isHost(myClientType);
  const teamId = useMyTeamId();
  const correctAnswers = useRapidCorrectAnswers();
  const gameSessionBlock = useGameSessionBlock() as RapidBlock | null;

  if (!gameSessionBlock) return <></>;

  let allAnswers = [];
  try {
    allAnswers = JSON.parse(gameSessionBlock.fields.answers);
  } catch (_) {}

  const totalAnswerCount = allAnswers.length;
  const myCorrectAnswerCount = correctAnswers?.length ?? 0;

  return (
    <GamePlayAnswerLayout>
      <div className='max-w-4xl max-h-48 font-cairo font-extrabold text-6xl break-words line-clamp-3 overflow-hidden leading-normal text-center'>
        {isHost ? (
          <>Distributing points to teams...</>
        ) : (
          <>
            {`You got ${myCorrectAnswerCount} out of ${totalAnswerCount}`}
            <p>Correct Answers!</p>
          </>
        )}
      </div>
      {!isHost && (
        <div className='mt-10 mb-5 text-3xl h-12 font-cairo text-tertiary'>
          {teamId ? (
            <div className='flex items-center justify-center gap-2'>
              {`Your Team Earned ${nextScoreDisplay} points!`}
            </div>
          ) : (
            `You Earned ${nextScoreDisplay} Points!`
          )}
        </div>
      )}
    </GamePlayAnswerLayout>
  );
}
