import { proxy } from 'valtio';
import { devtools, subscribeKey } from 'valtio/utils';

import { RTDBServerValueTIMESTAMP } from '@lp-lib/firebase-typesafe';
import { ConnectionStatus } from '@lp-lib/shared-schema';

import { getFeatureQueryParamArray } from '../../hooks/useFeatureQueryParam';
import logger from '../../logger/logger';
import { FBPathUtils } from '../../store/utils';
import {
  ClientTypeUtils,
  intoParticipantFlags,
  intoParticipantSkim,
  type Participant,
  type ParticipantFlagMap,
  type ParticipantFlags,
  type ParticipantFull,
  type ParticipantFullMap,
  type ParticipantMap,
} from '../../types/user';
import { BrowserIntervalCtrl } from '../../utils/BrowserIntervalCtrl';
import { type EmitterListener } from '../../utils/emitter';
import { rsIncrement } from '../../utils/rstats.client';
import {
  markSnapshottable,
  type ValtioSnapshottable,
  ValtioUtils,
} from '../../utils/valtio';
import { type GetTimeMs } from '../Clock';
import {
  type FirebaseEvents,
  type FirebaseService,
  FirebaseValueHandle,
} from '../Firebase';
import { FirebaseUtils } from '../Firebase/utils';

type HeartbeatMap = {
  [clientId: string]: {
    lastHeartbeatAt: RTDBServerValueTIMESTAMP;
  };
};

type State = {
  skims: ParticipantMap;
  flags: ParticipantFlagMap;
  fulls: ParticipantFullMap;
  myClientId: string | null;
  inited: boolean;
};

function makeParticipantHandle(
  svc: FirebaseService,
  venueId: string
): FirebaseValueHandle<ParticipantFullMap> {
  return new FirebaseValueHandle(
    svc.safeRef(FBPathUtils.ForParticipants(svc, venueId))
  );
}

function makeHeartbeatHandle(
  svc: FirebaseService,
  venueId: string
): FirebaseValueHandle<HeartbeatMap> {
  return new FirebaseValueHandle(
    svc.safeRef(FBPathUtils.ForHeartbeat(svc, venueId))
  );
}

type Dependencies = {
  emitter: EmitterListener<FirebaseEvents>;
  getTime: GetTimeMs;
};

/**
 * When a participant does not exist in an object lookup, we use this known key
 * to prevent valtio from assuming that no lookup means an object reference, and
 * thus would trigger a change on any read.
 *
 * Example that will be considered an object reference:
 *
 * ```
 * if (!clientId) return null;
 * return state.participants[clientId];
 * ```
 *
 * Example that will only rerender when a specific participant changes:
 *
 * ```
 * return state.participants[clientId ?? VALTIO_NOOP];
 * ```
 */

const VALTIO_NOOP = '' as const;

export class ParticipantStore {
  private _state = markSnapshottable(proxy<State>(this.initialState()));
  private flushJob = {
    ctrl: new BrowserIntervalCtrl(),
    pendingUpsert: [] as Participant[],
    pendingRemove: [] as string[], // clientIds
  };
  private hbCtrl = new BrowserIntervalCtrl();
  private offConnectionStateChanged?: () => void;

  constructor(
    readonly venueId: string,
    svc: FirebaseService,
    private deps: Dependencies,
    private flushStyle = getFeatureQueryParamArray('participant-flush'),
    readonly log = logger.scoped('participantStore'),
    private participantHandle = makeParticipantHandle(svc, venueId),
    private heartbeatHandle = makeHeartbeatHandle(svc, venueId),
    private config = {
      heartbeatMs: 30 * 1000,
      flushMs: 200,
      recoveryTimeoutMs: FirebaseUtils.RecoveryConfig().totalMs,
    }
  ) {}

  async init() {
    const participants = await this.participantHandle.get();
    if (participants) {
      for (const p of Object.values(participants)) {
        if (p) this.addParticipant(p);
      }
    }
    if (this.flushStyle === 'queued') this.startFlushJob();
    this.participantHandle.ref.on('child_added', (snapshot) => {
      const data = snapshot.val();
      this.log.debug('participants child_added', { participant: data });
      if (!data) return;
      this.flushJob.pendingUpsert.push(data);
      if (this.flushStyle === 'immediate') this.flush();
    });
    this.participantHandle.ref.on('child_changed', (snapshot) => {
      const data = snapshot.val();
      this.log.debug('participants child_changed', { participant: data });
      if (!data) return;
      this.flushJob.pendingUpsert.push(data);
      if (this.flushStyle === 'immediate') this.flush();
    });
    this.participantHandle.ref.on('child_removed', (snapshot) => {
      const data = snapshot.val();
      this.log.debug('participants child_removed', { participant: data });
      if (!data) return;
      this.flushJob.pendingRemove.push(data.clientId);
      if (this.flushStyle === 'immediate') this.flush();
    });
    this._state.inited = true;
    this.log.info('inited');
    return this.reset.bind(this);
  }

  reset() {
    this.stopFlushJob();
    this.participantHandle.ref.off();
    this.offConnectionStateChanged?.();
    this.offConnectionStateChanged = undefined;
    ValtioUtils.reset(this._state, this.initialState());
    this.log.info('disposed');
  }

  async join(data: Omit<Participant, 'joinedAt'>) {
    const participant: ParticipantFull = {
      id: data.id,
      clientId: data.clientId,
      clientType: data.clientType,
      cohost: data.cohost,
      username: data.username,
      joinedAt: Date.now(),
      status: ConnectionStatus.Connected,
      // set default value to true
      hasCamera: true,
      hasMicrophone: true,
    };

    if (data.firstName && data.lastName) {
      participant.firstName = data.firstName;
      participant.lastName = data.lastName;
    }
    if (data.orgId) {
      participant.orgId = data.orgId;
    }

    this.addParticipant(participant);
    this._state.myClientId = participant.clientId;

    const ref = FirebaseUtils.Rewrap(
      this.participantHandle.ref.child(participant.clientId)
    );
    await ref.set(participant);
    await this.registerOnDisconnect(participant.clientId, 'join');
    this.offConnectionStateChanged?.();
    this.offConnectionStateChanged = this.deps.emitter.on(
      'connection-state-changed',
      this.onConnectionStateChanged.bind(this)
    );
    this.startHeartbeat(participant.clientId);
  }

  async leave(clientId: string) {
    const participant = this._state.skims[clientId];
    if (!participant) return;
    const promises = [];
    const ref = FirebaseUtils.Rewrap(
      this.participantHandle.ref.child(clientId)
    );
    const now = Date.now();
    this.updateParticipant({
      clientId,
      status: ConnectionStatus.Disconnected,
      disconnectedAt: now,
    });
    promises.push(
      ref.update({
        status: ConnectionStatus.Disconnected,
        disconnectedAt: now,
        disconnectedReason: 'ParticipantStoreV2#leave',
      })
    );
    promises.push(
      this.unregisterOnDisconnect(clientId, 'ParticipantStoreV2#leave')
    );
    this.offConnectionStateChanged?.();
    this.stopHeartbeat();
    await Promise.all(promises);
  }

  async syncFromFirebase() {
    const fulls = await this.participantHandle.get();
    if (fulls) {
      const skims: { [key: string]: Participant } = {};
      const flags: { [key: string]: ParticipantFlags } = {};

      for (const [clientId, full] of Object.entries(fulls)) {
        if (!full) continue;
        skims[clientId] = intoParticipantSkim(full);
        flags[clientId] = intoParticipantFlags(full);
      }

      ValtioUtils.set(this._state, 'fulls', fulls);
      ValtioUtils.set(this._state, 'skims', skims);
      ValtioUtils.set(this._state, 'flags', flags);
    }
  }

  async update(clientId: string, data: Partial<Participant>) {
    this.updateParticipant({ clientId, ...data });
    const ref = FirebaseUtils.Rewrap(
      this.participantHandle.ref.child(clientId)
    );
    await ref.update(data);
  }

  inited(snap = this._state) {
    return snap.inited;
  }

  selectHostClientId(snap = this._state.skims) {
    return this.selectHost(true, snap)?.clientId ?? null;
  }

  selectHost(excludedDisconnected = true, snap = this._state.skims) {
    const hostCandidates: Participant[] = [];
    for (const [, p] of Object.entries(snap)) {
      if (p && ClientTypeUtils.isHost(p)) hostCandidates.push(p);
    }
    return this.selectLastJoined(hostCandidates, excludedDisconnected);
  }

  selectAllHostClientIds(snap = this._state.skims) {
    const hostClientIds: string[] = [];
    for (const [, p] of Object.entries(snap)) {
      if (p && ClientTypeUtils.isHost(p)) hostClientIds.push(p.clientId);
    }
    return hostClientIds;
  }

  selectParticipantByClientId(
    clientId: Nullable<string>,
    snap = this._state.skims
  ) {
    return snap[clientId ?? VALTIO_NOOP] ?? null;
  }

  selectParticipantFlagsByClientId(
    clientId: Nullable<string>,
    snap = this._state.flags
  ) {
    return snap[clientId ?? VALTIO_NOOP] ?? null;
  }

  selectMyInstance(snap = this._state) {
    return this.selectParticipantByClientId(snap.myClientId, snap.skims);
  }

  selectParticipantsByClientIds(clientIds: string[], snap = this._state.skims) {
    const result: Participant[] = [];

    clientIds.forEach((clientId) => {
      const target = snap[clientId];
      if (target) {
        result.push(target);
      }
    });

    return result;
  }

  selectParticipantByUserIds = (
    userIds: string[],
    excludedDisconnected?: boolean,
    snap = this._state.skims
  ): Participant[] => {
    return Object.values(snap).filter((p) => {
      if (!p || !ClientTypeUtils.isAudience(p)) return false;
      if (userIds.findIndex((userId) => userId === p.id) === -1) return false;
      if (excludedDisconnected) {
        return p.status === ConnectionStatus.Connected;
      }
      return true;
    }) as Participant[];
  };

  selectParticipantByUserId(
    userId: Nullable<string>,
    excludedDisconnected?: boolean,
    snap = this._state.skims
  ) {
    if (!userId) return null;
    const participants = this.selectParticipantByUserIds(
      [userId],
      excludedDisconnected,
      snap
    );
    return participants.length > 0 ? participants[0] : null;
  }

  selectMyTeamId(snap = this._state) {
    const myClientId = snap.myClientId;
    if (snap.skims && myClientId) {
      const self = snap.skims[myClientId];
      return self?.teamId ?? null;
    }
    return null;
  }

  selectLastJoinedParticipantByUserId = (
    userId: string | null | undefined,
    excludedDisconnected = true,
    snap = this._state.skims
  ): Participant | null => {
    const candidates = Object.values(snap).filter(
      (p) => p && p.id === userId
    ) as Participant[];
    return this.selectLastJoined(candidates, excludedDisconnected);
  };

  selectLastJoined(
    candidates: Participant[],
    excludedDisconnected = true
  ): Participant | null {
    if (candidates.length === 0) return null;
    const sorted = candidates.sort((a, b) => b.joinedAt - a.joinedAt);
    const active = candidates.find(
      (p) => p.status === ConnectionStatus.Connected
    );
    if (active) return active;

    const lastJoined = sorted[0];
    if (excludedDisconnected) {
      if (
        lastJoined.status === ConnectionStatus.Disconnected &&
        lastJoined.disconnectedAt &&
        this.deps.getTime() - lastJoined.disconnectedAt <
          this.config.recoveryTimeoutMs
      ) {
        return lastJoined;
      }
    } else {
      return lastJoined ?? null;
    }

    return null;
  }

  isHeartbeatExpired(lastHeartbeatAt: number | undefined) {
    if (lastHeartbeatAt === undefined) return false;
    return this.deps.getTime() - lastHeartbeatAt > this.config.heartbeatMs * 2;
  }

  registerDevtools() {
    return devtools(this._state, { name: 'Participant Store' });
  }

  get state(): Readonly<ValtioSnapshottable<State>> {
    return this._state;
  }

  handles_myInstance(on: (p: Participant | null) => void) {
    const unsubs: (() => void)[] = [];

    const emit = () => {
      const value = this.selectParticipantByClientId(this._state.myClientId);
      on(value ? { ...value } : null);
    };

    // set initial value
    emit();

    unsubs.push(
      subscribeKey(this._state, 'myClientId', (myClientId) => {
        if (!myClientId) return on(null);

        // emit the initial value, again, since it presumably exists now
        emit();

        // listen for future changes
        unsubs.push(
          subscribeKey(this._state.skims, myClientId, () => {
            emit();
          })
        );
      })
    );

    // cleanup
    return () => unsubs.forEach((s) => s());
  }

  handles_inited(on: (v: boolean | null) => void) {
    const unsubs: (() => void)[] = [];
    unsubs.push(subscribeKey(this._state, 'inited', (v) => on(v)));
    on(this._state.inited);
    return () => unsubs.forEach((s) => s());
  }

  handles_myClientId(on: (id: string | null) => void) {
    const unsubs: (() => void)[] = [];

    unsubs.push(
      subscribeKey(this._state, 'myClientId', (myClientId) => on(myClientId))
    );

    on(this._state.myClientId);

    // cleanup
    return () => unsubs.forEach((s) => s());
  }

  private addParticipant(full: ParticipantFull) {
    if (this._state.skims[full.clientId]) return this.updateParticipant(full);

    rsIncrement('participant-add-c');
    const flags = intoParticipantFlags(full);
    const skim = intoParticipantSkim(full);

    this._state.skims[full.clientId] = skim;
    this._state.flags[full.clientId] = flags;
    this._state.fulls[full.clientId] = full;
  }

  private updateParticipant(data: Partial<ParticipantFull>) {
    if (!data.clientId) return;
    const full = this._state.fulls[data.clientId];
    const skim = this._state.skims[data.clientId];
    const flags = this._state.flags[data.clientId];
    if (!skim || !flags || !full) return;

    if (data.joinedAt) delete data.joinedAt;

    rsIncrement('participant-update-c');

    const nextSkim = intoParticipantSkim(data);
    const nextFlags = intoParticipantFlags(data);

    ValtioUtils.update(skim, nextSkim);
    ValtioUtils.update(flags, nextFlags);
    ValtioUtils.update(full, data);
  }

  private async flush() {
    if (this.flushJob.pendingUpsert.length > 0) {
      for (const p of this.flushJob.pendingUpsert) {
        this.addParticipant(p);
      }
      this.flushJob.pendingUpsert.length = 0;
    }
    if (this.flushJob.pendingRemove.length > 0) {
      for (const clientId of this.flushJob.pendingRemove) {
        rsIncrement('participant-remove-c');
        delete this._state.skims[clientId];
        delete this._state.flags[clientId];
      }
      this.flushJob.pendingRemove.length = 0;
    }
  }

  private startFlushJob() {
    this.flushJob.ctrl.set(async () => this.flush(), this.config.flushMs);
  }

  private stopFlushJob() {
    this.flushJob.ctrl.clear();
  }

  private startHeartbeat(clientId: string) {
    this.stopHeartbeat();
    const ref = this.heartbeatHandle.ref.child(clientId);
    this.hbCtrl.set(async () => {
      await ref.update({
        lastHeartbeatAt: RTDBServerValueTIMESTAMP,
      });
    }, this.config.heartbeatMs);
  }

  private stopHeartbeat() {
    this.hbCtrl.clear();
  }

  private async onConnectionStateChanged(connected: boolean) {
    const clientId = this._state.myClientId;
    if (!clientId) {
      this.log.warn('myClientId is null, skip recovery', {
        connected,
      });
      return;
    }
    if (connected) {
      const ref = FirebaseUtils.Rewrap(
        this.participantHandle.ref.child(clientId)
      );
      const snapshot = await ref.get();
      if (!snapshot.exists()) {
        this.log.warn('snapshot does not exist, skip recovery.', {
          clientId,
        });
        return;
      }
      await ref.update({
        status: ConnectionStatus.Connected,
        // delete the disconnectedAt
        disconnectedAt: null as never,
      });
      await this.registerOnDisconnect(clientId, 'onConnectionStateChanged');
    } else {
      this.log.info('firebase disconnected, mark self as disconnected');
      this.updateParticipant({
        clientId,
        status: ConnectionStatus.Disconnected,
        disconnectedAt: this.deps.getTime(),
      });
    }
  }

  private async registerOnDisconnect(clientId: string, reason: string) {
    const ref = FirebaseUtils.Rewrap(
      this.participantHandle.ref.child(clientId)
    );
    await ref.onDisconnect().update(
      {
        status: ConnectionStatus.Disconnected,
        disconnectedAt: RTDBServerValueTIMESTAMP,
        disconnectedReason: `ParticipantStoreV2#${reason}`,
      },
      (err) => {
        if (err) {
          this.log.error('participants onDisconnect.update failed', err);
        } else {
          this.log.info('registered onDisconnect.update', {
            disconnectedReason: `ParticipantStoreV2#${reason}`,
          });
        }
      }
    );
  }

  private async unregisterOnDisconnect(clientId: string, reason: string) {
    const ref = FirebaseUtils.Rewrap(
      this.participantHandle.ref.child(clientId)
    );
    await ref.onDisconnect().cancel((err) => {
      if (err) {
        this.log.error('participants onDisconnect.cancel failed', err);
      } else {
        this.log.info('canceled onDisconnect.update', {
          disconnectedReason: `ParticipantStoreV2#${reason}`,
        });
      }
    });
  }

  private initialState(): State {
    return {
      skims: {},
      flags: {},
      fulls: {},
      myClientId: null,
      inited: false,
    };
  }
}
