import { useRef } from 'react';

import { type BlockType } from '@lp-lib/game';

import { useFeatureQueryParam } from '../../hooks/useFeatureQueryParam';
import { useOutsideClick } from '../../hooks/useOutsideClick';
import { BlockIcon } from '../Game/Blocks';
import { BlockChoiceMap } from '../Game/Blocks/Shared';

function Choice(props: {
  onClick: () => void;
  type: BlockType;
  primary: string;
  secondary: string;
}) {
  const { onClick, primary, secondary } = props;

  return (
    <div className='p-2 hover:bg-lp-black-003'>
      <button
        className='text-left relative pl-5'
        type='button'
        onClick={onClick}
      >
        <BlockIcon
          blockType={props.type}
          className='absolute left-0 top-0 w-3.5 h-3.5'
          aria-hidden='true'
        />
        <div className='text-xs'>{primary}</div>
        <div className='text-3xs text-secondary'>{secondary}</div>
      </button>
    </div>
  );
}

export function BlockTypeChooser(props: {
  onAddBlock: (type: BlockType) => void;
  onCancel: () => void;
}): JSX.Element | null {
  const { onAddBlock, onCancel } = props;
  const ref = useRef<null | HTMLDivElement>(null);
  const includeHiddenBlocks = useFeatureQueryParam('show-hidden-blocks');

  useOutsideClick(ref, () => onCancel());

  return (
    <div
      ref={ref}
      className='w-120 bg-modal border border-secondary rounded-xl text-white'
    >
      <header className='text-xs p-3 text-left whitespace-nowrap'>
        Choose a block type to add
      </header>
      <div className='grid grid-cols-2 gap-2 pl-3 pb-3 max-h-165 overflow-y-scroll scrollbar'>
        {Object.values(BlockChoiceMap)
          .sort((a, b) => a.idx - b.idx)
          .filter((block) => includeHiddenBlocks || !block.hidden)
          .map((c) => (
            <Choice
              key={c.type}
              onClick={() => onAddBlock(c.type)}
              type={c.type}
              primary={c.primary}
              secondary={c.secondary}
            />
          ))}
      </div>
    </div>
  );
}
