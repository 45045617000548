import { Outlet, useParams } from '@remix-run/react';
import React, { useContext, useMemo } from 'react';

import { type PairingRound } from '../../../types';
import { err2s } from '../../../utils/common';
import { Loading } from '../../Loading';
import { usePairingRound } from './CRUD';

interface PairingRoundDetailsContext {
  round: PairingRound;
  set: ReturnType<typeof usePairingRound>['set'];
}

const context = React.createContext<PairingRoundDetailsContext | null>(null);

export const usePairingRoundDetails = (): PairingRoundDetailsContext => {
  const ctx = useContext(context);
  if (!ctx) throw new Error('No value for GlobalPairingRoundContext');
  return ctx;
};

export const PairingRoundDetailsLayout = (): JSX.Element => {
  const { roundId } = useParams<'roundId'>();

  const { round, isLoading, error, set } = usePairingRound({
    id: roundId,
  });

  const ctxValue = useMemo(() => (round ? { round, set } : null), [round, set]);

  if (isLoading) {
    return <Loading />;
  }
  if (error) {
    return (
      <div className='text-center text-white'>
        Get Round Err: {err2s(error)}
      </div>
    );
  }
  if (!ctxValue?.round) {
    return <div className='text-center text-white'>Not Found</div>;
  }

  return (
    <context.Provider value={ctxValue}>
      <div className='w-full h-full pl-8 pt-10 pr-12.5'>
        <Outlet />
      </div>
    </context.Provider>
  );
};
