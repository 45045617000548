import { type CSSProperties, useCallback, useEffect, useRef } from 'react';

import { useForceUpdate } from '../../hooks/useForceUpdate';
import { useMyInstance } from '../../hooks/useMyInstance';
import { type IRTCService } from '../../services/webrtc';
import { OnStage, Placeholder } from '../Participant';
import { useParticipantFlag } from '../Player';
import { useMyClientId } from '../Venue/VenuePlaygroundProvider';
import {
  useDebugStream,
  useIsCoreChannelJoined,
  useLookupCoreChannelName,
} from './RTCServiceContext';

interface LocalStreamViewProps {
  id: string;
  className: string;
  publishVideoTrackIfChanged: boolean;
  publishAudioTrackIfChanged?: boolean;
  enablePlaceholder?: boolean;
  enableOnStage?: boolean;
  /**
   * LocalStreamView handles publishing the stream. But it should not
   * always play the raw video locally, such as when a green screen preview is
   * being shown to the host.
   */
  rtcServicePlaysVideo?: boolean;
  rtcService: IRTCService;
  children?: React.ReactNode;
  onStageConfig?: { showLabel: boolean };
  style?: CSSProperties;
  mirror?: boolean;
  noPlaceholderIndicators?: boolean;
  fit?: 'cover' | 'contain' | 'fill';
}

export const BaseLocalStreamView = ({
  id,
  className,
  publishVideoTrackIfChanged,
  publishAudioTrackIfChanged = false,
  enablePlaceholder = true,
  enableOnStage = true,
  rtcServicePlaysVideo = true,
  rtcService,
  children,
  onStageConfig,
  style,
  mirror,
  fit,
  noPlaceholderIndicators,
}: LocalStreamViewProps): JSX.Element => {
  const myClientId = useMyClientId();
  const participant = useMyInstance();
  const video = useParticipantFlag(myClientId, 'video');
  const hasCamera = useParticipantFlag(myClientId, 'hasCamera');
  const onStage = useParticipantFlag(myClientId, 'onStage');
  const ref = useRef<HTMLDivElement>(null);
  useDebugStream(ref.current, myClientId, rtcService);
  const forceUpdate = useForceUpdate();

  const channel = useLookupCoreChannelName(rtcService);
  const channelJoined = useIsCoreChannelJoined(channel);

  const checkChannelJoined = useCallback(() => {
    if (channel) return channelJoined;
    return !!rtcService.joinStatus();
  }, [channel, channelJoined, rtcService]);

  useEffect(() => {
    const playVideo = async () => {
      if (rtcServicePlaysVideo && ref.current) {
        rtcService.playVideo(myClientId, ref.current, { mirror, fit });
      }
      if (publishVideoTrackIfChanged && checkChannelJoined()) {
        try {
          await rtcService.publishVideo();
        } catch (error) {
          rtcService.log.error('publish video error', error);
        }
      }
    };
    playVideo();
    const off = rtcService.on('video-switched', playVideo);
    return () => {
      if (rtcServicePlaysVideo) rtcService.stopVideo(myClientId);
      off();
    };
  }, [
    rtcService,
    myClientId,
    publishVideoTrackIfChanged,
    rtcServicePlaysVideo,
    mirror,
    fit,
    checkChannelJoined,
  ]);

  useEffect(() => {
    if (!publishAudioTrackIfChanged) return;

    async function publishAudio() {
      if (publishAudioTrackIfChanged && checkChannelJoined()) {
        try {
          await rtcService.publishAudio();
        } catch (error) {
          rtcService.log.error('publish audio error', error);
        }
      }
    }

    publishAudio();
    const off = rtcService.on('audio-switched', publishAudio);
    return () => {
      off();
    };
  }, [checkChannelJoined, publishAudioTrackIfChanged, rtcService]);

  useEffect(() => {
    const off = rtcService.on('video-toggled', async (enabled: boolean) => {
      if (enabled) {
        if (rtcServicePlaysVideo && ref.current) {
          rtcService.playVideo(myClientId, ref.current, { mirror, fit });
        }
        if (publishVideoTrackIfChanged && checkChannelJoined()) {
          try {
            await rtcService.publishVideo();
          } catch (error) {
            rtcService.log.error('publish video error', error);
          }
        }
      } else {
        if (rtcServicePlaysVideo) rtcService.stop(myClientId);
      }
    });
    return () => off();
  }, [
    myClientId,
    publishVideoTrackIfChanged,
    rtcService,
    rtcServicePlaysVideo,
    mirror,
    fit,
    checkChannelJoined,
  ]);

  useEffect(() => {
    const off = rtcService.on('audio-toggled', async (enabled: boolean) => {
      if (!enabled) return;
      if (publishAudioTrackIfChanged && checkChannelJoined()) {
        try {
          await rtcService.publishAudio();
        } catch (error) {
          rtcService.log.error('publish audio error', error);
        }
      }
    });
    return () => off();
  }, [checkChannelJoined, publishAudioTrackIfChanged, rtcService]);

  let showVideo = video && hasCamera !== false;

  if (enableOnStage) {
    showVideo = showVideo && !onStage;
  }

  return (
    <div className={`${className}`} style={style} onTransitionEnd={forceUpdate}>
      <div
        id={id}
        ref={ref}
        className={`w-full h-full ${
          showVideo ? 'block' : 'hidden'
        } absolute z-10 left-0 top-0`}
      />
      {enableOnStage && participant && (
        <OnStage
          zIndex='z-10'
          onStage={onStage}
          showLabel={onStageConfig?.showLabel}
          showDimMask={!!onStage}
        />
      )}
      {enablePlaceholder && participant && (
        <Placeholder
          clientId={myClientId}
          showLiteMode
          noIndicators={noPlaceholderIndicators}
        />
      )}
      {children}
    </div>
  );
};
