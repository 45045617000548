import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
import isEqual from 'lodash/isEqual';
import uniq from 'lodash/uniq';
import { useCallback, useRef } from 'react';
import ReactDatePicker from 'react-datepicker';
import {
  Controller,
  FormProvider,
  useFieldArray,
  useForm,
  useFormContext,
} from 'react-hook-form';
import Select from 'react-select';
import useSWRImmutable from 'swr/immutable';

import {
  EnumsGamePackChangeLevel,
  EnumsProgramType,
} from '@lp-lib/api-service-client/public';

import config from '../../../config';
import { useAsyncCall } from '../../../hooks/useAsyncCall';
import { getFeatureQueryParamString } from '../../../hooks/useFeatureQueryParam';
import { useInstance } from '../../../hooks/useInstance';
import { apiService } from '../../../services/api-service';
import { MessageCampaignType } from '../../../services/api-service/messageCampaign.api';
import { type UpdateMessageLogicRequest } from '../../../services/api-service/messageLogic.api';
import {
  type GlobalPairingRound,
  GlobalPairingRoundStatus,
  type MessageLogic,
  type Organization,
  ParticipationMode,
} from '../../../types';
import { type GamePack } from '../../../types/game';
import { fromDTOGamePack, toMediaDataDTO } from '../../../utils/api-dto';
import { err2s } from '../../../utils/common';
import { DateUtils } from '../../../utils/date';
import { buildReactSelectStyles } from '../../../utils/react-select';
import { type Option } from '../../common/Utilities';
import { useAwaitFullScreenConfirmCancelModal } from '../../ConfirmCancelModalContext';
import { GamePackSelect } from '../../Game/GameCenter/GamePackSelect';
import { CheckedIcon } from '../../icons/CheckedIcon';
import { DeleteIcon } from '../../icons/DeleteIcon';
import { ErrorIcon } from '../../icons/ErrorIcon';
import { Loading } from '../../Loading';
import { MessageCampaign } from '../../MessageLogic';
import { OrganizationSelector } from '../../Organization';
import { FeatureChecker } from '../../Product/FeatureChecker';
import { useUser } from '../../UserContext';

export interface GlobalPairingRoundFormData {
  id: string | null;
  mainGamePack: GamePack | null;
  shadowGamePacks: (GamePack | null)[];
  asLevels: boolean;
  startedAt: Date | null;
  endedAt: Date | null;
  messageCampaignId: string | null;
  orgIds: string[] | null;
  participationMode: ParticipationMode | null;
  // not submit
  orgCount: number;
  gameDropsChannelCount: number;
  managerCount: number;
}

const useValidShadowGamePacks = (): GamePack[] => {
  const { watch } = useFormContext<GlobalPairingRoundFormData>();
  const shadowGamePacks = watch('shadowGamePacks');
  return shadowGamePacks.filter((gp) => !!gp && !!gp.id) as GamePack[];
};

const useGamePacks = (): GamePack[] => {
  const { watch } = useFormContext<GlobalPairingRoundFormData>();
  const mainGamePack = watch('mainGamePack');
  const validShadowGamePacks = useValidShadowGamePacks();
  return mainGamePack
    ? [mainGamePack, ...validShadowGamePacks]
    : [...validShadowGamePacks];
};

const SyncGamePacksButton = (): JSX.Element | null => {
  const triggerWaitConfirmModal = useAwaitFullScreenConfirmCancelModal();
  const { watch, setValue } = useFormContext<GlobalPairingRoundFormData>();
  const asLevels = watch('asLevels');
  const mainGamePack = watch('mainGamePack');
  const shadowGamePacks = watch('shadowGamePacks');
  const validShadowGamePacks = useValidShadowGamePacks();

  if (!mainGamePack || validShadowGamePacks.length === 0 || asLevels)
    return null;

  const unSyncedShadowPacks = validShadowGamePacks.filter((p) => {
    return (
      p.description !== mainGamePack.description ||
      p.cover?.hash !== mainGamePack.cover?.hash ||
      p.venueBackground?.hash !== mainGamePack.venueBackground?.hash ||
      !isEqual(
        p.teamRandomizationSettings,
        mainGamePack.teamRandomizationSettings
      )
    );
  });

  if (unSyncedShadowPacks.length === 0)
    return (
      <div className='flex items-center gap-1 text-sm text-green-001'>
        <CheckedIcon />
        Game Packs Synced!
      </div>
    );

  const handleSync = async () => {
    const decision = await triggerWaitConfirmModal({
      kind: 'confirm-cancel',
      prompt: (
        <div className='text-white text-center'>
          <h2 className=' text-2xl font-medium'>
            Are you sure you want to sync changes?
          </h2>
          <p className='text-sms mt-4'>This action can't be undone.</p>
        </div>
      ),
      confirmBtnLabel: 'Confirm',
      cancelBtnLabel: 'Cancel',
    });
    if (decision.result === 'canceled') return;

    const updatedGamePacks = await Promise.all(
      unSyncedShadowPacks.map(async (p) => {
        const resp = await apiService.gamePack.update(p.id, {
          description: mainGamePack.description ?? '',
          coverMediaId: mainGamePack.cover?.id || '',
          venueBackgroundMediaData: toMediaDataDTO(
            mainGamePack.venueBackgroundMediaData
          ),
          teamRandomizationSettings:
            mainGamePack.teamRandomizationSettings ?? undefined,
          changeLevel: EnumsGamePackChangeLevel.GamePackChangeLevelNegligible,
        });
        if (!resp) return;
        return resp.data.gamePack;
      })
    );

    for (const updatedGamePack of updatedGamePacks) {
      if (!updatedGamePack) continue;

      const index = shadowGamePacks.findIndex(
        (sgp) => sgp?.id === updatedGamePack.id
      );
      if (index === -1) continue;
      setValue(`shadowGamePacks.${index}`, fromDTOGamePack(updatedGamePack));
    }
  };

  return (
    <button
      type='button'
      className='btn flex items-center gap-1 text-sm font-bold text-red-002 underline'
      onClick={handleSync}
    >
      <ErrorIcon />
      Sync Changes
    </button>
  );
};

const MainGamePack = (props: { disabled: boolean }): JSX.Element => {
  const { control, formState } = useFormContext<GlobalPairingRoundFormData>();
  const gamePacks = useGamePacks();

  return (
    <div className='w-144 flex flex-col gap-2'>
      <div className='flex justify-between items-center'>
        <h3 className='text-base font-bold'>Game Pack 1</h3>
        <SyncGamePacksButton />
      </div>

      <Controller
        rules={{ required: true }}
        control={control}
        name='mainGamePack'
        render={({ field: { onChange, value } }) => (
          <div className='w-full h-15'>
            <GamePackSelect
              value={value}
              onChange={onChange}
              filter={(pack) => {
                if (!pack || pack.id === value?.id) return true;

                return !gamePacks.find((p) => p.id === pack.id);
              }}
              isError={!!formState.errors.mainGamePack}
              disabled={props.disabled}
            />
          </div>
        )}
      />
    </div>
  );
};

const ShadowGamePack = (props: { disabled: boolean }): JSX.Element => {
  const { control, formState } = useFormContext<GlobalPairingRoundFormData>();
  const { fields, append, remove } = useFieldArray<GlobalPairingRoundFormData>({
    name: 'shadowGamePacks',
  });
  const gamePacks = useGamePacks();

  return (
    <div className='ml-6 flex flex-col items-start gap-4'>
      {fields.length > 0 && (
        <ul className='ml-8 mr-8 flex flex-col gap-4'>
          {fields.map((item, index) => (
            <li key={item.id} className='flex flex-col gap-2'>
              <div className='flex justify-between'>
                <h3>Game Pack {index + 2}</h3>
              </div>

              <div className='relative flex items-center gap-10'>
                <Controller
                  rules={{
                    required: true,
                    validate: (val) => {
                      return !!val?.id;
                    },
                  }}
                  control={control}
                  name={`shadowGamePacks.${index}`}
                  render={({ field: { onChange, value } }) => (
                    <GamePackSelect
                      value={value?.id ? value : null}
                      onChange={onChange}
                      filter={(pack) => {
                        if (!pack || pack.id === value?.id) return true;

                        return !gamePacks.find((p) => p.id === pack.id);
                      }}
                      className='w-144'
                      isError={!!formState.errors.shadowGamePacks?.[index]}
                      disabled={props.disabled}
                    />
                  )}
                />

                {!props.disabled && (
                  <button
                    type='button'
                    // position:absolute and a width is a hack to allow the Levels UI button
                    // to flush-align with the GamePackSelect right edge
                    className='absolute left-full w-12 btn text-red-002 flex items-center justify-center'
                    onClick={() => remove(index)}
                  >
                    <DeleteIcon className='w-7 h-7 fill-current' />
                  </button>
                )}
              </div>
            </li>
          ))}
        </ul>
      )}

      {!props.disabled && (
        <button
          type='button'
          className='btn text-primary font-bold text-base'
          onClick={() => {
            append(null);
          }}
        >
          + Add Shadow Game Pack
        </button>
      )}
    </div>
  );
};

const StartDate = (props: { disabled: boolean; tz: string }): JSX.Element => {
  const { formState, control, watch } =
    useFormContext<GlobalPairingRoundFormData>();
  const endedAt = watch('endedAt');

  return (
    <div className='w-70 flex flex-col gap-2'>
      <h3 className='text-base font-bold'>Start Date</h3>

      <Controller
        rules={{
          required: true,
        }}
        control={control}
        name='startedAt'
        render={({ field: { onChange, value } }) => (
          <ReactDatePicker
            className={`${
              formState.errors.startedAt ? 'field-error' : 'field'
            } h-15 mb-0`}
            selected={value ? utcToZonedTime(value, props.tz) : null}
            onChange={(date) =>
              onChange(date ? zonedTimeToUtc(date, props.tz) : null)
            }
            selectsStart
            maxDate={endedAt ? utcToZonedTime(endedAt, props.tz) : null}
            monthsShown={2}
            showTimeInput
            dateFormat='eee, MMM do yyyy h:mm aa'
            placeholderText='N/A'
            disabled={props.disabled}
          />
        )}
      />

      <input
        type='select'
        className='w-full field h-15 mb-0'
        value={DateUtils.FormatTimezone(props.tz)}
        disabled
      />
    </div>
  );
};

const EndDate = (props: { disabled: boolean; tz: string }): JSX.Element => {
  const { formState, control, watch } =
    useFormContext<GlobalPairingRoundFormData>();
  const startedAt = watch('startedAt');

  return (
    <div className='w-70 flex flex-col gap-2'>
      <h3 className='text-base font-bold'>End Date</h3>

      <Controller
        rules={{
          required: true,
          validate: (val) => {
            if (val && startedAt && val <= startedAt) {
              return 'The end date must be greater than the start date';
            }
            return;
          },
          deps: ['startedAt'],
        }}
        control={control}
        name='endedAt'
        render={({ field: { onChange, value } }) => (
          <ReactDatePicker
            className={`${
              formState.errors.endedAt ? 'field-error' : 'field'
            } h-15 mb-0`}
            selected={value ? utcToZonedTime(value, props.tz) : null}
            onChange={(date) =>
              onChange(date ? zonedTimeToUtc(date, props.tz) : null)
            }
            selectsEnd
            minDate={startedAt ? utcToZonedTime(startedAt, props.tz) : null}
            monthsShown={2}
            showTimeInput
            dateFormat='eee, MMM do yyyy h:mm aa'
            placeholderText='N/A'
            disabled={props.disabled}
          />
        )}
      />
      <input
        type='select'
        className='w-full field h-15 mb-0'
        value={DateUtils.FormatTimezone(props.tz)}
        disabled
      />
    </div>
  );
};

const MessageCampaignField = (props: {
  round: GlobalPairingRound | null;
  onMessageTemplateChange: (data: UpdateMessageLogicRequest) => void;
}): JSX.Element => {
  const { control, watch } = useFormContext<GlobalPairingRoundFormData>();
  const startedAt = watch('startedAt');
  const parentId = watch('id');
  const messageCampaignId = watch('messageCampaignId');
  const selectDisabled =
    !!props.round &&
    (props.round.status === GlobalPairingRoundStatus.Completed ||
      props.round.status === GlobalPairingRoundStatus.InProgress);

  return (
    <div>
      <Controller
        control={control}
        name='messageCampaignId'
        render={({ field: { onChange } }) => (
          <MessageCampaign
            parentId={parentId}
            campaignType={MessageCampaignType.GlobalRound}
            messageCampaignId={messageCampaignId}
            onChange={onChange}
            onMessageTemplateChange={props.onMessageTemplateChange}
            disabled={selectDisabled}
            checkLogicDisabled={(logic: MessageLogic) => {
              if (!props.round) return false;
              if (props.round.status === GlobalPairingRoundStatus.Completed)
                return true;
              if (
                logic.triggerType === 'relativeTimed' &&
                startedAt &&
                startedAt.getTime() +
                  logic.triggerDetails.delayedSeconds * 1000 <=
                  new Date().getTime()
              ) {
                return true;
              }
              return false;
            }}
          />
        )}
      />
    </div>
  );
};

const ParticipationModeField = (props: {
  round: GlobalPairingRound | null;
}): JSX.Element => {
  const { control, formState } = useFormContext<GlobalPairingRoundFormData>();
  const options = useInstance<Option<ParticipationMode>[]>(() => {
    return [
      { value: ParticipationMode.None, label: 'Default (All Opt-In)' },
      { value: ParticipationMode.OptInOnly, label: 'User Opt-In Only' },
      { value: ParticipationMode.OptInAndOut, label: 'User Opt-In/Out' },
    ];
  });
  const disabled = !!props.round;
  return (
    <div>
      <Controller
        rules={{ required: true }}
        control={control}
        name='participationMode'
        render={({ field: { onChange, value } }) => {
          const styles = buildReactSelectStyles<Option<ParticipationMode>>({
            isError: !!formState.errors.participationMode,
          });
          return (
            <div className='w-144'>
              <Select<Option<ParticipationMode>, false>
                options={options}
                classNamePrefix='select-box-v2'
                styles={styles}
                value={options.find((o) => o.value === value)}
                onChange={(val) => onChange(val?.value)}
                isDisabled={disabled}
              />
            </div>
          );
        }}
      />
    </div>
  );
};

const Header = (props: {
  mode: 'Create' | 'Edit';
  onCancel: () => void;
  isSubmitting: boolean;
  error: Error | null;
}): JSX.Element => {
  const { mode, onCancel, isSubmitting, error } = props;

  return (
    <header className='flex justify-between'>
      <h1 className='text-2xl font-medium'>{`${mode} Global Pairs Round`}</h1>

      <div className='flex flex-col gap-1'>
        <div className='flex justify-center items-center gap-4'>
          <button
            type='button'
            className='btn-secondary w-40 h-10'
            onClick={onCancel}
          >
            Cancel
          </button>
          <button
            type='submit'
            className='btn-primary w-40 h-10'
            disabled={isSubmitting}
          >
            {isSubmitting ? 'Saving' : 'Save'}
          </button>
        </div>
        {error && (
          <div className='text-sms font-normal text-red-002'>
            {err2s(error)}
          </div>
        )}
      </div>
    </header>
  );
};

const TimeRangeSection = (props: {
  round: GlobalPairingRound | null;
}): JSX.Element => {
  const tz = useInstance(() => getFeatureQueryParamString('toolkit-tz'));
  return (
    <section>
      <h2 className='text-2xl font-medium mb-6'>Time Range</h2>

      <main className='flex gap-4'>
        <StartDate
          disabled={
            !!props.round &&
            (props.round.status !== GlobalPairingRoundStatus.Scheduled ||
              !!props.round.messageCampaignId)
          }
          tz={tz}
        />
        <EndDate
          disabled={
            !!props.round &&
            ![
              GlobalPairingRoundStatus.Scheduled,
              GlobalPairingRoundStatus.InProgress,
            ].includes(props.round.status)
          }
          tz={tz}
        />
      </main>
    </section>
  );
};

const GamePackSection = (props: {
  round: GlobalPairingRound | null;
}): JSX.Element => {
  const selectDisabled =
    !!props.round &&
    (props.round.status === GlobalPairingRoundStatus.Completed ||
      props.round.status === GlobalPairingRoundStatus.InProgress);
  return (
    <section>
      <h2 className='text-2xl font-medium mb-6'>Game Pack</h2>

      <main className='flex flex-col gap-4'>
        <MainGamePack disabled={selectDisabled} />
        <ShadowGamePack disabled={selectDisabled} />
      </main>
    </section>
  );
};

const ParticipationSection = (props: {
  round: GlobalPairingRound | null;
}): JSX.Element => {
  return (
    <section>
      <h2 className='text-2xl font-medium mb-6'>Opt-In/Out Mode</h2>

      <main className='flex flex-col gap-4'>
        <ParticipationModeField round={props.round} />
      </main>
    </section>
  );
};

const MessageLogicSection = (props: {
  round: GlobalPairingRound | null;
  onMessageTemplateChange: (data: UpdateMessageLogicRequest) => void;
}): JSX.Element => {
  return (
    <section>
      <h2 className='text-2xl font-medium mb-6'>Message Campaign</h2>

      <MessageCampaignField
        round={props.round}
        onMessageTemplateChange={props.onMessageTemplateChange}
      />
    </section>
  );
};

function qualifiesAsPairingOrganization(
  uid: string,
  organization: Organization
) {
  return new FeatureChecker(
    uid,
    organization.subscription.features
  ).canAccessProgramOfType(EnumsProgramType.ProgramTypeGlobalPairing);
}

function compareOrganizations(a: Organization, b: Organization) {
  return (
    Number(b.id === config.misc.lunaParkOrgId) -
    Number(a.id === config.misc.lunaParkOrgId)
  );
}

function OrganizationSelectorSection(props: {
  uid: string;
  round: GlobalPairingRound | null;
}): JSX.Element | null {
  const { uid, round } = props;
  // Internal used only, ingore error for simplicity
  const { data: summaries, isValidating: loadingSummaries } = useSWRImmutable(
    '/channels/org-summaries',
    async () => {
      const resp = await apiService.channel.getOrgSummaries();
      return resp.data.summaries;
    }
  );
  const { data: orgIds, isValidating: loadingOrgIds } = useSWRImmutable(
    ['/pairing-rounds', round?.id],
    async () => {
      if (round?.id) {
        const resp = await apiService.pairing.queryRounds({
          globalRoundId: round?.id,
        });
        return uniq(resp.data.rounds.map((r) => r.orgId));
      }
      return null;
    }
  );
  const { setValue } = useFormContext<GlobalPairingRoundFormData>();

  if (loadingSummaries || loadingOrgIds) return <Loading />;

  return (
    <OrganizationSelector
      initOrgIds={orgIds ?? null}
      maxTableHeight='max-h-90'
      columns={{
        names: ['Channels', 'Participants'],
        render: (org) => {
          const summary = summaries?.[org.id];
          return [
            summary?.default?.channelsCount ?? 0,
            summary?.default?.membersCount ?? 0,
          ];
        },
      }}
      onChange={(change) => {
        if (change.selectAll) {
          setValue('orgIds', null);
        } else {
          setValue('orgIds', change.orgIds);
        }
      }}
      disabled={!!round}
      filterOrganization={(org) => qualifiesAsPairingOrganization(uid, org)}
      compareOrganizations={compareOrganizations}
    />
  );
}

export const GlobalPairingRoundEditor = (props: {
  round: GlobalPairingRound | null;
  onCancel: () => void;
  onSave: (data: GlobalPairingRoundFormData) => Promise<void>;
}): JSX.Element => {
  const { round, onCancel, onSave } = props;
  const user = useUser();

  const {
    state: { transformed: state },
    error,
    call,
  } = useAsyncCall(
    useCallback(
      (data: GlobalPairingRoundFormData) => {
        return onSave(data);
      },
      [onSave]
    )
  );

  const formReturned = useForm<GlobalPairingRoundFormData>({
    defaultValues: {
      id: round?.id ?? null,
      mainGamePack: round?.mainGamePack ?? null,
      shadowGamePacks: round?.shadowGamePacks ?? [],
      asLevels: round?.config?.asLevels ?? false,
      startedAt: round ? new Date(round.startedAt) : null,
      endedAt: round ? new Date(round.endedAt) : null,
      messageCampaignId: round?.messageCampaignId ?? null,
      participationMode: round?.config?.participationMode ?? null,
    },
  });

  const updatedMessageLogicsRef = useRef<UpdateMessageLogicRequest[]>([]);
  const onSubmit = formReturned.handleSubmit(
    async (data: GlobalPairingRoundFormData) => {
      if (!data.mainGamePack || !data.startedAt || !data.endedAt) return;

      if (updatedMessageLogicsRef.current.length > 0) {
        Promise.all(
          updatedMessageLogicsRef.current.map((req) =>
            apiService.messageLogic.update(req)
          )
        );
      }

      call(data);
    }
  );

  const showMessageLogicSection =
    !round ||
    round.messageCampaignId ||
    ![
      GlobalPairingRoundStatus.InProgress,
      GlobalPairingRoundStatus.Completed,
    ].includes(round.status);

  return (
    <FormProvider {...formReturned}>
      <form className='w-full px-10 text-white' onSubmit={onSubmit}>
        <Header
          mode={!props.round ? 'Create' : 'Edit'}
          onCancel={onCancel}
          isSubmitting={state.isRunning}
          error={error}
        />

        <main className='flex flex-col gap-8 mt-10'>
          <div className='flex gap-8'>
            <div className='flex flex-col gap-8'>
              <GamePackSection round={round} />
              <TimeRangeSection round={round} />
              <ParticipationSection round={round} />
            </div>
            <div className='flex flex-col w-full'>
              <OrganizationSelectorSection uid={user.id} round={round} />
            </div>
          </div>
          <div>
            {showMessageLogicSection && (
              <MessageLogicSection
                round={round}
                onMessageTemplateChange={(data) =>
                  updatedMessageLogicsRef.current.push(data)
                }
              />
            )}
          </div>
        </main>
      </form>
    </FormProvider>
  );
};
