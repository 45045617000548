import { type FirebaseService, FirebaseValueHandle } from '../Firebase';
import { type EmojiSendCommandMap } from './type';

export class EmojiFBUtils {
  static Path(venueId: string, kind: 'root' | 'commands'): string {
    if (kind === 'root') return `emojis/${venueId}`;
    return `emojis/${venueId}/${kind}`;
  }

  static EmojiCommandsHandle(
    svc: FirebaseService,
    venueId: string
  ): FirebaseValueHandle<EmojiSendCommandMap> {
    return new FirebaseValueHandle(
      svc.prefixedSafeRef(this.Path(venueId, 'commands'))
    );
  }
}
