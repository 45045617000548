import { type Logger } from '@lp-lib/logger-base';

import { getFeatureQueryParam } from '../../../hooks/useFeatureQueryParam';
import {
  type ProfileAddress,
  profileFor,
  type ProfileIndex,
  type ReceiveFilmstripFromServerMsgData,
  toProfileAddress,
} from '../../../services/crowd-frames';
import { loadImageAsPromise } from '../../../utils/media';
import { rsCounter } from '../../../utils/rstats.client';
import {
  type FPSContext,
  type ImageFilmstrip,
  type RenderableFilmstrip,
  type RenderTarget,
} from './types';

const asyncImageDecodeEnabled = getFeatureQueryParam(
  'crowd-frames-async-image-decode'
);

export async function acceptCrowdFramesMessage(
  ctx: FPSContext,
  msg: ReceiveFilmstripFromServerMsgData,
  logger: Logger,
  asyncDecode = asyncImageDecodeEnabled
): Promise<void> {
  // Early out optimization.
  if (ctx.targets.size === 0 && ctx.renderlessTargets.size === 0) return;

  const address = toProfileAddress(msg.target.cid, msg.target.profile);

  // A shared cache of in-progress or completed filmstrips in the event that
  // there are multiple targets with the same uuid. We decode once, and all
  // matching targets will wait on the promise.
  const decoded = new Map<ProfileAddress, Promise<ImageFilmstrip>>();

  const loadFilmstrip = async (address: ProfileAddress) => {
    if (!decoded.has(address)) {
      const promise = loadImageAsPromise(
        msg.target.filmstrip.data
      ).then<ImageFilmstrip>((img) => {
        if (asyncDecode) {
          return img.decode().then(() => img as ImageFilmstrip);
        } else {
          return img as ImageFilmstrip;
        }
      });

      decoded.set(address, promise);
    }
  };

  const loadAndFan = async (target: RenderTarget) => {
    if (target.profileAddress !== address) return;
    await loadFilmstrip(target.profileAddress);

    try {
      const p = decoded.get(address);
      if (!p) return;
      decodes.push(p);
      const filmstrip = await p;

      fanOutFilmstripToMatchingTarget(
        address,
        msg.target.profile,
        filmstrip,
        target,
        logger
      );
    } catch {
      // Chrome's image decoder occasionally gets completely overwhelmed with
      // high-volume requests, and just starts throwing. If this happens,
      // ignore it.
    }
  };

  rsCounter('crowd-frame-renderful-accepted-ms')?.start();
  const decodes = [];
  for (const [, target] of ctx.targets) {
    decodes.push(loadAndFan(target));
  }
  Promise.allSettled(decodes).then(() =>
    rsCounter('crowd-frame-renderful-accepted-ms')?.end()
  );

  rsCounter('crowd-frame-renderless-accepted-ms')?.start();
  for (const targetProfileAddress of ctx.renderlessTargets.keys()) {
    if (targetProfileAddress !== address) return;
    await loadFilmstrip(targetProfileAddress);
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const filmstrip = await decoded.get(address)!;
    const renderable: RenderableFilmstrip = {
      filmstrip,
      frameIndex: 0,
      elapsedSinceLastRender: 0,
      profile: profileFor(msg.target.profile),
    };
    ctx.setMostRecentFilmstripForAddress(address, renderable);
  }
  rsCounter('crowd-frame-renderless-accepted-ms')?.end();
}

function targetNeedsTrimming(target: RenderTarget, goalDurationMs: number) {
  let total = 0;
  for (let i = 0; i < target.filmstrips.length; i++) {
    const renderable = target.filmstrips[i];
    // Take into account what frames are actually remaining for render
    total +=
      (renderable.profile.perStrip - renderable.frameIndex) *
      renderable.profile.delayMs;
  }
  return total > goalDurationMs;
}

function trimTargetFilmstripsIfNecessary(
  target: RenderTarget,
  goalDurationMs: number,
  _logger: Logger
) {
  while (targetNeedsTrimming(target, goalDurationMs)) {
    target.filmstrips.shift();
  }
}

// Create a unique RenderableFilmstrip for each target, but using the same
// shared filmstrip (img) data.
function fanOutFilmstripToMatchingTarget(
  address: ProfileAddress,
  profile: ProfileIndex,
  filmstrip: ImageFilmstrip,
  target: RenderTarget,
  logger: Logger
): void {
  if (target.profileAddress !== address) return;

  // There is a bug where sometimes a Profile is not unregistered even though
  // the avatar is not in view. When this happens, the the target will always
  // need to be trimmed as a new filmstrip arrives.
  // TODO: fix the registration bug.
  const MAX_BUFFER_MS = 1100;
  trimTargetFilmstripsIfNecessary(target, MAX_BUFFER_MS, logger);

  const renderable: RenderableFilmstrip = {
    filmstrip,
    frameIndex: 0,
    elapsedSinceLastRender: 0,
    profile: profileFor(profile),
  };

  target.filmstrips.push(renderable);

  if (
    !target.hasReceivedAtLeastOneFilmstrip &&
    target.setHasReceivedAtLeastOneFilmstrip
  ) {
    target.setHasReceivedAtLeastOneFilmstrip(true);
    // Optimization: never allow the loading screen if we have had at least
    // one frame in the past
    target.immediatelyRender = true;
  }
}
