import { useSearchParams } from '@remix-run/react';
import { useEffect, useMemo, useState } from 'react';
import {
  Controller,
  FormProvider,
  useForm,
  useFormContext,
} from 'react-hook-form';
import Select from 'react-select';
import { useTitle } from 'react-use';
import useSWRImmutable from 'swr/immutable';

import {
  type DtoRecognitionSummaryItem,
  EnumsRecognitionQueryTimeRange,
  EnumsRecognitionQueryType,
  type QueryRecognitionSummaryParams,
} from '@lp-lib/api-service-client/public';

import { useLiveAsyncCall } from '../../../hooks/useAsyncCall';
import { apiService } from '../../../services/api-service';
import {
  castProgramLink,
  type RecognitionProgramLink,
} from '../../../types/program';
import { makeTitle } from '../../../utils/common';
import { buildReactSelectStyles } from '../../../utils/react-select';
import { ChannelMemberAvatar } from '../../Channel';
import { type Option } from '../../common/Utilities';
import { ScoreboardIcon } from '../../icons/ScoreboardIcon';
import { Loading } from '../../Loading';
import { useOrgFeatureContext } from '../../Organization';
import { type ProgramDetailProps } from '../types';

type FormData = Omit<QueryRecognitionSummaryParams, 'id'>;

const QUERY_TYPE_OPTIONS: Option<EnumsRecognitionQueryType>[] = [
  {
    label: 'Shoutout Received',
    value: EnumsRecognitionQueryType.RecognitionQueryTypeReceived,
  },
  {
    label: 'Shoutout Given',
    value: EnumsRecognitionQueryType.RecognitionQueryTypeGiven,
  },
  {
    label: 'Shoutout Combined',
    value: EnumsRecognitionQueryType.RecognitionQueryTypeCombined,
  },
];

function resolveQueryType(
  val: string | null,
  fallback = QUERY_TYPE_OPTIONS[0]
) {
  const option = QUERY_TYPE_OPTIONS.find((item) => item.value === val);
  return option ?? fallback;
}

const QUERY_TIME_OPTIONS: Option<EnumsRecognitionQueryTimeRange>[] = [
  {
    label: 'All Time',
    value: EnumsRecognitionQueryTimeRange.RecognitionQueryTimeRangeAllTime,
  },
  {
    label: 'Past 7 days',
    value: EnumsRecognitionQueryTimeRange.RecognitionQueryTimeRangeLastWeek,
  },
  {
    label: 'Past 14 days',
    value: EnumsRecognitionQueryTimeRange.RecognitionQueryTimeRangeLastTwoWeeks,
  },
  {
    label: 'Past 30 days',
    value: EnumsRecognitionQueryTimeRange.RecognitionQueryTimeRangeLastMonth,
  },
  {
    label: 'Past 90 days',
    value:
      EnumsRecognitionQueryTimeRange.RecognitionQueryTimeRangeLastThreeMonths,
  },
  {
    label: 'Past 180 days',
    value:
      EnumsRecognitionQueryTimeRange.RecognitionQueryTimeRangeLastSixMonths,
  },
  {
    label: 'Past Year',
    value: EnumsRecognitionQueryTimeRange.RecognitionQueryTimeRangeLastYear,
  },
];

function resolveTimeRange(
  val: string | null,
  fallback = QUERY_TIME_OPTIONS[0]
) {
  const option = QUERY_TIME_OPTIONS.find((item) => item.value === val);
  return option ?? fallback;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const STYLES = buildReactSelectStyles<Option<any>>({
  override: {
    control: { height: '100%' },
  },
});

function ChannelFilter(props: { orgId: string }) {
  const { orgId } = props;
  const { control } = useFormContext<FormData>();
  const { data, isLoading } = useSWRImmutable(
    `/organizations/${orgId}/recognition-groups`,
    async () => {
      const resp = await apiService.program.queryRecognitionExGroups(orgId);
      return resp.data.groups;
    }
  );
  const options = useMemo(() => {
    const options: Option<string>[] = [{ label: 'All channels', value: '' }];
    data?.forEach((item) => {
      options.push({ label: item.name, value: item.id });
    });
    return options;
  }, [data]);

  if (isLoading) return <Loading />;

  return (
    <Controller
      name='exGroupId'
      control={control}
      rules={{ required: true }}
      render={({ field: { value, onChange } }) => (
        <Select<Option<string>>
          options={options}
          value={options.find((o) => o.value === value)}
          classNamePrefix='select-box-v2'
          className='w-50 h-13.5'
          styles={STYLES}
          onChange={(option) => onChange(option?.value)}
        />
      )}
    />
  );
}

function RecognitionLeaderboard(props: ProgramDetailProps) {
  const { channel } = props;
  const programLink = castProgramLink<RecognitionProgramLink>(
    props.programLink
  );
  const { org } = useOrgFeatureContext();
  const [searchParams, setSearchParams] = useSearchParams();
  const form = useForm<FormData>({
    defaultValues: {
      type: resolveQueryType(searchParams.get('type')).value,
      timeRange: resolveTimeRange(searchParams.get('timeRange')).value,
      exGroupId: searchParams.get('exGroupId') ?? '',
    },
  });

  const [items, setItems] = useState<DtoRecognitionSummaryItem[]>([]);

  const {
    call: load,
    state: { state },
  } = useLiveAsyncCall(async (data: FormData) => {
    setSearchParams(data);
    const resp = await apiService.program.queryRecognitionSummary(org.id, data);
    setItems(resp.data.items);
  });

  const formData = form.watch();

  useEffect(() => {
    load({
      type: formData.type,
      timeRange: formData.timeRange,
      exGroupId: formData.exGroupId,
    });
  }, [formData.exGroupId, formData.timeRange, formData.type, load]);

  return (
    <FormProvider {...form}>
      <div className='flex flex-col gap-6 text-white'>
        <header className='flex items-center'>
          <div className='flex items-center gap-2'>
            {state.isRunning ? (
              <Loading text='' />
            ) : (
              <ScoreboardIcon className='w-7.5 h-7.5 fill-current' />
            )}
            <div className='text-2xl font-medium'>Leaderboard</div>
          </div>
          <div className='ml-auto'></div>
          <div className='flex items-center gap-4'>
            <div>Filter</div>
            <Controller
              name='type'
              control={form.control}
              rules={{ required: true }}
              render={({ field: { value, onChange } }) => (
                <Select<Option<EnumsRecognitionQueryType>>
                  options={QUERY_TYPE_OPTIONS}
                  value={resolveQueryType(value)}
                  classNamePrefix='select-box-v2'
                  className='w-50 h-13.5'
                  styles={STYLES}
                  onChange={(option) => onChange(option?.value)}
                  isSearchable={false}
                />
              )}
            />
            <Controller
              name='timeRange'
              control={form.control}
              rules={{ required: true }}
              render={({ field: { value, onChange } }) => (
                <Select<Option<EnumsRecognitionQueryTimeRange>>
                  options={QUERY_TIME_OPTIONS}
                  value={resolveTimeRange(value)}
                  classNamePrefix='select-box-v2'
                  className='w-50 h-13.5'
                  styles={STYLES}
                  onChange={(option) => onChange(option?.value)}
                  isSearchable={false}
                />
              )}
            />
            <ChannelFilter orgId={org.id} />
          </div>
        </header>
        <main className='w-full'>
          <table
            className='w-full border-separate'
            style={{
              borderSpacing: '0 10px',
            }}
          >
            <thead>
              <tr>
                <th className='w-1/5 text-left'>Rank</th>
                <th className='w-1/5 text-left'>Person</th>
                <th className='text-right'>Total Shout-outs</th>
              </tr>
            </thead>
            <tbody>
              {items
                ?.filter((item) => !!item.member)
                .map((item, i) => (
                  <tr className='text-sms' key={item.uid}>
                    <td className='w-1/5 text-left'>{i + 1}</td>
                    <td className='w-1/5 text-left'>
                      {item.member && (
                        <div className='flex items-center gap-2'>
                          <ChannelMemberAvatar
                            exConnectType={channel.exConnectType}
                            member={item.member}
                            className='w-9 h-9'
                          />
                          <div>{item.member.fullName}</div>
                        </div>
                      )}
                    </td>
                    <td className='text-right'>{`${item.amount} ${
                      programLink.extensions?.emoji?.token ?? ''
                    }`}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </main>
      </div>
    </FormProvider>
  );
}

export function RecognitionProgramDetails(
  props: ProgramDetailProps
): JSX.Element {
  useTitle(makeTitle('Ice Cream Shout-outs Leaderboard'));
  return (
    <div>
      <RecognitionLeaderboard {...props} />
    </div>
  );
}
