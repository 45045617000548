import { type ReactNode } from 'react';

import { useLayoutAnchorRect } from '../LayoutAnchors/LayoutAnchors';
import { useIsTeamsOnTop } from '../TeamAPI/TeamV1';
import { useSafeBoundingBoxTop } from './SafeZoneProvider';

const DEFAULT_LEFT_POSITION = 'left-48 lg:left-65 2xl:left-72';
const DEFAULT_RIGHT_POSITION = 'right-48 lg:right-65 2xl:right-72';
const DEFAULT_TOP_POSITION = 'top-45 2xl:top-60';
const DEFAULT_BOTTOM_POSITION = 'bottom-[10vh]';

const DEFAULT_X_POSITION = `${DEFAULT_LEFT_POSITION} ${DEFAULT_RIGHT_POSITION}`;

const BOTTOM_SPACE_RESERVED_TOP_POSITION = 'top-[7vh]';
const BOTTOM_SPACE_RESERVED_BOTTOM_POSITION = 'bottom-55 2xl:bottom-60';

function BottomSpaceReservedLayout(props: {
  className?: string;
  useCustomPaddingX?: boolean;
  children?: ReactNode;
}): JSX.Element {
  const px = props.useCustomPaddingX ? '' : DEFAULT_X_POSITION;
  return (
    <div
      className={`
      w-auto absolute ${BOTTOM_SPACE_RESERVED_TOP_POSITION} ${BOTTOM_SPACE_RESERVED_BOTTOM_POSITION} ${px} ${
        props.className ?? ''
      }`}
    >
      {props.children}
    </div>
  );
}

function TopSpaceReservedLayout(props: {
  className?: string;
  useCustomPaddingX?: boolean;
  children?: ReactNode;
}): JSX.Element {
  const safeTop = useSafeBoundingBoxTop();
  const px = props.useCustomPaddingX ? '' : DEFAULT_X_POSITION;
  const top = safeTop ?? DEFAULT_TOP_POSITION;
  const bottom = DEFAULT_BOTTOM_POSITION;
  const rect = useLayoutAnchorRect('lobby-top-spacing-anchor');
  return (
    <div
      className={`
      w-auto absolute mt-1 ${top} ${bottom} ${px} ${props.className ?? ''}`}
      style={{
        // NOTE(drew): this will override whatever `top-` value is passed in,
        // but we still allow `safeTop` to override even this because Zoom has a
        // custom townhall layout.
        top: safeTop ? undefined : rect ? rect.y + rect.height : undefined,
      }}
    >
      {props.children}
    </div>
  );
}

interface BoardProps {
  children?: ReactNode;
  containerZIndex: string;
  containerDisplay?: 'hidden' | 'block' | 'flex';
  bgOpacity?: 'opacity-100' | 'opacity-0';
  bgStyle?: string;
  rightPanel?: JSX.Element | null;
  title?: string;
  bgImgSrc?: string | null;
}

export function FloatBoard(props: BoardProps): JSX.Element {
  const {
    containerZIndex,
    bgOpacity,
    bgStyle = 'bg-board bg-no-repeat bg-center bg-stretch',
    title,
    rightPanel,
    bgImgSrc,
  } = props;

  const width = rightPanel ? 'w-3/4' : 'w-full';

  const containerDisplay =
    props.containerDisplay === 'flex'
      ? 'flex items-center justify-center'
      : props.containerDisplay;

  return (
    <FloatLayout className={`${containerZIndex} ${containerDisplay ?? ''}`}>
      <div
        className={`relative ${width} h-[95%] ${bgStyle} bg-no-repeat bg-center bg-stretch flex flex-col items-center transition-opacity duration-500 ease-in-out ${
          bgOpacity || ''
        }`}
      >
        {bgImgSrc && (
          <div className='absolute -z-1 w-full h-full border-0 rounded-xl overflow-hidden'>
            <img
              className='absolute -z-1 top-0 w-full h-full object-cover'
              src={bgImgSrc}
              alt='submission'
            />
            <div className='absolute -z-1 w-full h-full bg-black bg-opacity-40' />
          </div>
        )}
        {title && (
          <>
            <span className='absolute top-[-4%] font-cairo font-extrabold lp-lg:text-9xl xl:text-5xl md:text-3xl text-white text-center'>
              {title}
            </span>
            <span className='absolute top-[-3%] font-cairo font-extrabold lp-lg:text-9xl xl:text-5xl md:text-3xl text-white text-center text-opacity-40'>
              {title}
            </span>
          </>
        )}
        {props.children}
      </div>
      {rightPanel && <div className='relative w-1/4 h-full'>{rightPanel}</div>}
    </FloatLayout>
  );
}

export function FloatLayout(props: {
  className?: string;
  useCustomPaddingX?: boolean;
  children?: ReactNode;
}): JSX.Element {
  const teamsOnTop = useIsTeamsOnTop();
  if (teamsOnTop) return <TopSpaceReservedLayout {...props} />;
  return <BottomSpaceReservedLayout {...props} />;
}

/**
 * Floats a div in a manner similar to FloatLayout. This div controls the x
 * position of the div (and thereby affecting the width), but does not control
 * for the y position. This is useful for when you want to float a div with a
 * width that matches the FloatLayout, but doesn't require a div with the same
 * height as the float layout.
 */
export function FloatDiv(props: {
  xPosition?: string;
  className?: string;
  children: ReactNode;
}): JSX.Element {
  return (
    <div
      className={`
        absolute
        ${props.xPosition ?? DEFAULT_X_POSITION}
        ${props.className ?? ''}
      `}
    >
      {props.children}
    </div>
  );
}

/**
 * A Layout that by default behaves like FloatLayout (respects TeamsOnTop, has
 * left/right spacing, etc). But you can disable any dimension by passing
 * `none`, or completely override by passing a className as a dimensional prop.
 *
 * @example <SafeZoneLayout right='none' bottom='bottom-2' left='none'>
 */
export function SafeZoneLayout(props: {
  top?: 'none' | 'safe' | string;
  right?: 'none' | 'safe' | string;
  bottom?: 'none' | 'safe' | string;
  left?: 'none' | 'safe' | string;
  children: ReactNode;
  className?: string;
  debugName?: string;
}) {
  const teamsOnTop = useIsTeamsOnTop();
  const safeTop = useSafeBoundingBoxTop();

  const defaultTop = teamsOnTop
    ? safeTop ?? DEFAULT_TOP_POSITION
    : BOTTOM_SPACE_RESERVED_TOP_POSITION;
  const defaultBottom = teamsOnTop
    ? DEFAULT_BOTTOM_POSITION
    : BOTTOM_SPACE_RESERVED_BOTTOM_POSITION;

  const classes = [];

  if (props.top === 'safe' || !props.top) {
    classes.push(defaultTop);
  } else if (props.top === 'none') {
    classes.push('top-0');
  } else {
    classes.push(props.top);
  }

  if (props.bottom === 'safe' || !props.bottom) {
    classes.push(defaultBottom);
  } else if (props.bottom === 'none') {
    classes.push('bottom-0');
  } else {
    classes.push(props.bottom);
  }

  if (props.left === 'safe' || !props.left) {
    classes.push(DEFAULT_LEFT_POSITION);
  } else if (props.left === 'none') {
    classes.push('left-0');
  } else {
    classes.push(props.left);
  }

  if (props.right === 'safe' || !props.right) {
    classes.push(DEFAULT_RIGHT_POSITION);
  } else if (props.right === 'none') {
    classes.push('right-0');
  } else {
    classes.push(props.right);
  }

  return (
    <div
      data-debug-name={props.debugName}
      className={`absolute ${props.className ?? ''} ${classes.join(' ')}`}
    >
      {props.children}
    </div>
  );
}
