import {
  type CSSProperties,
  type ReactNode,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useEffectOnce, usePrevious } from 'react-use';

import { type HeadToHeadBlock } from '@lp-lib/game';
import { ConnectionStatus } from '@lp-lib/shared-schema';

import { useIsCoordinator } from '../../../../hooks/useMyInstance';
import { isReconnecting } from '../../../../store/utils';
import { type TeamV0 } from '../../../../types';
import { type Participant } from '../../../../types/user';
import { BrowserIntervalCtrl } from '../../../../utils/BrowserIntervalCtrl';
import { CloseIcon } from '../../../icons/CloseIcon';
import {
  useLastJoinedParticipantByUserId,
  useParticipantByClientId,
  useParticipantsAsArray,
  useSelectTeamParticipants,
} from '../../../Player';
import { StageMode, useSelectOnStageMembers } from '../../../Stage';
import { PlayerGroupView } from '../../../Stage/PlayerGroupView';
import { useTeam, useTeamMembers, useTeams } from '../../../TeamAPI/TeamV1';
import {
  useHeadToHeadGameControlAPI,
  useHeadToHeadGameInfo,
  useHeadToHeadGamePlayAPI,
  useHeadToHeadGameProgress,
} from './HeadToHeadBlockProvider';
import { type H2HPlayerRole } from './types';
import { HeadToHeadUtils } from './utils';

export function GroupPickerButton(props: {
  block: HeadToHeadBlock;
  onClick: () => void;
  theme: 'primary' | 'secondary';
}) {
  const { block, onClick } = props;
  const singleMode = HeadToHeadUtils.SingleMode(block);
  return (
    <button
      type='button'
      className={`${
        props.theme === 'primary' ? 'btn-primary text-2xs' : 'text-2xs'
      } h-8 px-4`}
      style={{
        textShadow:
          props.theme === 'secondary' ? '0px 0px 10px black' : undefined,
      }}
      onClick={onClick}
    >
      Change {singleMode ? 'Player' : 'Team'}
    </button>
  );
}

export function OnStageGroup(props: {
  block: HeadToHeadBlock;
  groupId: string;
  groupName: string;
  groupColor: string;
  groupPicker?: React.ReactNode;
  groupActive?: boolean;
  accessory?: React.ReactNode;
}) {
  const { block, groupId, groupName, groupColor, groupPicker, groupActive } =
    props;
  const singleMode = HeadToHeadUtils.SingleMode(block);
  const allStageMembers = useSelectOnStageMembers(StageMode.BLOCK_CONTROLLED);
  const p = useLastJoinedParticipantByUserId(groupId);
  const teamMemberIds = useTeamMembers(groupId)?.map((m) => m.id);
  const stageMembers = singleMode
    ? allStageMembers.filter((m) => m.id === p?.clientId)
    : allStageMembers.filter((m) => teamMemberIds?.includes(m.id));
  const isCoordinator = useIsCoordinator();

  return (
    <div className='w-full flex flex-col gap-2.5'>
      <div className='relative'>
        {props.accessory}
        <div
          className='w-full rounded-5xl relative'
          style={{
            aspectRatio: '1/1',
            backgroundColor: `${groupColor}66`,
          }}
        >
          {groupActive && (
            <div
              className={`w-full h-full absolute inset-0 rounded-5xl animate-plusing -z-1`}
              style={
                {
                  '--tw-plusing-duration': '2s',
                  backgroundColor: `${groupColor}66`,
                } as CSSProperties
              }
            />
          )}
          <div className='w-full h-full absolute top-0 left-0 flex items-center justify-center z-5'>
            {isCoordinator && stageMembers.length === 0 ? groupPicker : null}
          </div>
          <PlayerGroupView
            stageMembers={stageMembers}
            groupColor={groupColor}
          />
        </div>
      </div>
      <div className='w-full h-10 flex flex-col items-center justify-center gap-px bg-dark-gray text-sms font-bold rounded z-5'>
        {stageMembers.length === 1 && (
          <div>
            <PlayerName clientId={stageMembers[0].id} />
          </div>
        )}
        <div style={{ color: groupColor }}>{groupName}</div>
      </div>
    </div>
  );
}

export function useTrackPlayedGroups(_block: HeadToHeadBlock) {
  const progress = useHeadToHeadGameProgress();
  const currStatus = progress.roundPhase;
  const prevStatus = usePrevious(currStatus);
  const api = useHeadToHeadGamePlayAPI();
  useEffect(() => {
    if (prevStatus === 'playing' && currStatus !== 'playing') {
      api.trackPlayedGroups();
    }
  }, [api, currStatus, prevStatus]);
}

function useClearDisconnectedPlayer(
  groupId: string,
  group: 'groupA' | 'groupB',
  enabled: boolean
) {
  const api = useHeadToHeadGamePlayAPI();
  const p = useLastJoinedParticipantByUserId(groupId);
  const disconnected = p?.status === ConnectionStatus.Disconnected;

  useEffect(() => {
    if (!enabled || !p?.id || !disconnected) return;
    const ctrl = new BrowserIntervalCtrl();
    ctrl.set(async () => {
      if (!isReconnecting(p)) {
        await api.clearGroupId(group);
        ctrl.clear();
      }
    }, 1000);
    return () => {
      ctrl.clear();
    };
  }, [api, disconnected, enabled, group, p]);
}

function useClearEmptyTeams(
  groupId: string,
  group: 'groupA' | 'groupB',
  enabled: boolean
) {
  const api = useHeadToHeadGamePlayAPI();
  const team = useTeam(groupId);
  const membersCount = team?.membersCount ?? 0;

  useEffect(() => {
    if (!enabled || membersCount > 0) return;
    api.clearGroupId(group);
  }, [api, enabled, group, membersCount]);
}

function useCleanupOnStagePlayers(block: HeadToHeadBlock) {
  const singleMode = HeadToHeadUtils.SingleMode(block);
  const progress = useHeadToHeadGameProgress();
  useClearDisconnectedPlayer(progress.currentAGroupId, 'groupA', singleMode);
  useClearDisconnectedPlayer(progress.currentBGroupId, 'groupB', singleMode);
}

function useCleanupOnStageTeams(block: HeadToHeadBlock) {
  const singleMode = HeadToHeadUtils.SingleMode(block);
  const progress = useHeadToHeadGameProgress();
  useClearEmptyTeams(progress.currentAGroupId, 'groupA', !singleMode);
  useClearEmptyTeams(progress.currentBGroupId, 'groupB', !singleMode);
}

export function useCleanupOnStageGroups(block: HeadToHeadBlock) {
  useCleanupOnStagePlayers(block);
  useCleanupOnStageTeams(block);
}

function GroupCandidateButton(props: {
  groupId: string;
  groupName: ReactNode;
  played: boolean;
  pickedAs?: 'groupA' | 'groupB';
  onPick: (groupId: string) => void;
  groupAccessory?: React.ReactNode;
  disabled?: boolean;
}) {
  const borderColor = props.pickedAs
    ? HeadToHeadUtils.GroupColor(props.pickedAs)
    : '#303436';
  return (
    <button
      type='button'
      className={`btn-secondary w-full h-15 flex items-center justify-center p-2.5 relative rounded-xl bg-secondary border`}
      style={{
        borderColor,
      }}
      onClick={() => props.onPick(props.groupId)}
      disabled={props.disabled}
    >
      {props.groupAccessory}
      <div className='flex-grow text-center text-white font-medium truncate'>
        {props.groupName}
      </div>
      {props.played && (
        <div className='absolute text-3xs right-1 bottom-1 text-green-001'>
          Played
        </div>
      )}
    </button>
  );
}

type GroupPickState = {
  aGroupId: string | null;
  bGroupId: string | null;
  next: 'groupA' | 'groupB';
};

export function useGroupPickStateFromProgress(): GroupPickState {
  const progress = useHeadToHeadGameProgress();
  return useMemo(() => {
    const aGroupId = progress.currentAGroupId || null;
    const bGroupId = progress.currentBGroupId || null;
    return {
      aGroupId,
      bGroupId,
      next: aGroupId ? 'groupB' : 'groupA',
    };
  }, [progress.currentAGroupId, progress.currentBGroupId]);
}

function PlayerPicker(props: {
  state: GroupPickState;
  onPick: (groupId: string) => void;
  targetGroup?: H2HPlayerRole;
}) {
  const { state, onPick, targetGroup } = props;
  const info = useHeadToHeadGameInfo();
  const playedGroupIds = useMemo(
    () => info.playedGroupIds ?? {},
    [info.playedGroupIds]
  );
  const participants = useParticipantsAsArray({
    filters: [
      'staff:false',
      'host:false',
      'cohost:false',
      'status:connected',
      'team:true',
    ],
    sorts: ['username:asc'],
  });
  const api = useHeadToHeadGamePlayAPI();

  useEffectOnce(() => {
    if (!!targetGroup) return;
    const picked = api.deps.playerPicker
      .pickNext(Object.keys(playedGroupIds))
      .filter(
        (p): p is Participant =>
          !!p && p?.id !== state.aGroupId && p?.id !== state.bGroupId
      );
    for (const p of picked) {
      onPick(p.id);
    }
  });

  return (
    <div className='grid grid-cols-3 gap-4'>
      {participants.map((p) => {
        const pickedAsGroup =
          p.id === state.aGroupId
            ? 'groupA'
            : p.id === state.bGroupId
            ? 'groupB'
            : undefined;
        return (
          <GroupCandidateButton
            key={p.id}
            groupId={p.id}
            groupName={<PlayerName userId={p.id} />}
            played={!!playedGroupIds[p.id]}
            pickedAs={pickedAsGroup}
            onPick={onPick}
            disabled={
              targetGroup && pickedAsGroup && targetGroup !== pickedAsGroup
            }
          />
        );
      })}
    </div>
  );
}

function TeamPicker(props: {
  state: GroupPickState;
  onPick: (groupId: string) => void;
  targetGroup?: H2HPlayerRole;
}) {
  const { state, onPick, targetGroup } = props;
  const info = useHeadToHeadGameInfo();
  const playedGroupIds = useMemo(
    () => info.playedGroupIds ?? {},
    [info.playedGroupIds]
  );
  const teams = useTeams({
    active: true,
    updateStaffTeam: true,
    excludeStaffTeam: true,
    sort: true,
  });
  const api = useHeadToHeadGamePlayAPI();

  useEffectOnce(() => {
    if (!!targetGroup) return;
    const picked = api.deps.teamPicker
      .pickNext(Object.keys(playedGroupIds))
      .filter(
        (t): t is TeamV0 =>
          !!t && t?.id !== state.aGroupId && t?.id !== state.bGroupId
      );
    for (const p of picked) {
      onPick(p.id);
    }
  });

  return (
    <div className='grid grid-cols-3 gap-4'>
      {teams.map((t) => {
        const pickedAsGroup =
          t.id === state.aGroupId
            ? 'groupA'
            : t.id === state.bGroupId
            ? 'groupB'
            : undefined;
        return (
          <GroupCandidateButton
            key={t.id}
            groupId={t.id}
            groupName={<TeamName teamId={t.id} />}
            played={!!playedGroupIds[t.id]}
            pickedAs={pickedAsGroup}
            onPick={onPick}
            disabled={
              targetGroup && pickedAsGroup && targetGroup !== pickedAsGroup
            }
          />
        );
      })}
    </div>
  );
}

function PlayerName(props: {
  userId?: Nullable<string>;
  clientId?: Nullable<string>;
}) {
  const participantByUserId = useLastJoinedParticipantByUserId(props.userId);
  const participantByClientId = useParticipantByClientId(props.clientId);
  const p = participantByUserId ?? participantByClientId;
  return p?.firstName || p?.username || 'N/A';
}

function TeamName(props: { teamId: Nullable<string> }) {
  const teamId = props.teamId ?? '';
  const team = useTeam(teamId);
  const participants = useSelectTeamParticipants(teamId);

  if (participants.length > 0) {
    return participants.map((p) => p.firstName || p.username).join(', ');
  }

  return team?.name || 'N/A';
}

type GroupPickerProps = {
  block: HeadToHeadBlock;
  label?: string;
  initState?: GroupPickState;
  onClose?: () => void;
  confirmBtn?: (pickedGroupIds: string[]) => ReactNode;
  targetGroup?: H2HPlayerRole;
};

export function NextGroupPicker(props: Omit<GroupPickerProps, 'confirmBtn'>) {
  const { block, onClose } = props;
  const info = useHeadToHeadGameInfo();
  const api = useHeadToHeadGameControlAPI();

  const onConfirm = async (pickedGroupIds: string[]) => {
    await api.configureGame(block, pickedGroupIds, {
      playedGroupIds: info.playedGroupIds,
      roundCount: info.roundCount + 1,
    });
    onClose?.();
  };

  return (
    <GroupPicker
      {...props}
      confirmBtn={(pickedGroupIds) => (
        <button
          type='button'
          className='btn-primary w-52 h-full text-sms font-medium px-4'
          disabled={pickedGroupIds.length < 2}
          onClick={() => onConfirm(pickedGroupIds)}
        >
          Confirm & Play Again ({pickedGroupIds.length}/2)
        </button>
      )}
    />
  );
}

export function CurrentGroupPicker(props: Omit<GroupPickerProps, 'confirm'>) {
  const api = useHeadToHeadGamePlayAPI();
  const onConfirm = async (pickedGroupIds: string[]) => {
    await api.updateCurrentGroups(pickedGroupIds);
    props.onClose?.();
  };
  return (
    <GroupPicker
      {...props}
      confirmBtn={(pickedGroupIds) => (
        <button
          type='button'
          className='btn-primary w-52 h-full text-sms font-medium px-4'
          disabled={pickedGroupIds.length < 2}
          onClick={() => onConfirm(pickedGroupIds)}
        >
          Confirm ({pickedGroupIds.length}/2)
        </button>
      )}
    />
  );
}

export function GroupPicker(props: GroupPickerProps) {
  const { block, label, onClose, targetGroup } = props;
  const singleMode = HeadToHeadUtils.SingleMode(block);
  const [state, setState] = useState<GroupPickState>(
    props.initState ?? {
      aGroupId: null,
      bGroupId: null,
      next: 'groupA',
    }
  );

  const onPick = (groupId: string) => {
    if (targetGroup) {
      const groupKey = targetGroup === 'groupA' ? 'aGroupId' : 'bGroupId';
      setState((prev) => {
        return {
          ...prev,
          [groupKey]: prev[groupKey] === groupId ? null : groupId,
        };
      });
      return;
    }
    setState((prev) => {
      if (groupId === prev.aGroupId) {
        return {
          ...prev,
          aGroupId: null,
          next: 'groupA',
        };
      } else if (groupId === prev.bGroupId) {
        return {
          ...prev,
          bGroup: null,
          next: 'groupB',
        };
      } else {
        return {
          ...prev,
          [prev.next === 'groupA' ? 'aGroupId' : 'bGroupId']: groupId,
          next: prev.next === 'groupA' ? 'groupB' : 'groupA',
        };
      }
    });
  };

  const pickedGroupIds = [state.aGroupId, state.bGroupId].filter(
    (g): g is string => !!g
  );

  return (
    <div className='w-full h-full flex flex-col rounded-2.5xl bg-main-layer border border-secondary p-5 gap-5 relative'>
      {onClose && (
        <button
          type='button'
          className='absolute right-2 top-2'
          onClick={onClose}
        >
          <CloseIcon className='w-4 h-4 fill-[#BFBFBF]' />
        </button>
      )}
      <div className='text-white text-xl font-bold text-center'>
        {label ??
          `Who’s up next? Choose the ${
            singleMode ? 'player' : 'team'
          } who you want to take the next turn.`}
      </div>
      <div className='scrollbar overflow-auto flex-grow'>
        {singleMode ? (
          <PlayerPicker
            state={state}
            onPick={onPick}
            targetGroup={targetGroup}
          />
        ) : (
          <TeamPicker state={state} onPick={onPick} targetGroup={targetGroup} />
        )}
      </div>
      <div className='w-full h-10 flex items-center justify-between flec-shrink-0'>
        <div className='flex flex-col gap-1'>
          <div className='text-sms font-medium flex items-center gap-2'>
            <span
              style={{
                color: HeadToHeadUtils.GroupColor('groupA'),
              }}
            >
              {HeadToHeadUtils.GroupName('groupA')}:
            </span>
            <span>
              {singleMode ? (
                <PlayerName userId={state.aGroupId} />
              ) : (
                <TeamName teamId={state.aGroupId} />
              )}
            </span>
          </div>
          <div className='text-sms font-medium flex items-center gap-2'>
            <span
              style={{
                color: HeadToHeadUtils.GroupColor('groupB'),
              }}
            >
              {HeadToHeadUtils.GroupName('groupB')}:
            </span>
            <span>
              {singleMode ? (
                <PlayerName userId={state.bGroupId} />
              ) : (
                <TeamName teamId={state.bGroupId} />
              )}
            </span>
          </div>
        </div>
        {props.confirmBtn?.(pickedGroupIds)}
      </div>
    </div>
  );
}
