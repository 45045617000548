import { type EnumsMediaType } from '@lp-lib/api-service-client/public';
import { type HeadToHeadCardPrompt } from '@lp-lib/game';

export type H2HPlayerRole = 'groupA' | 'groupB';
export type H2HParticipantRole = H2HPlayerRole | 'audience';

export type RolePrompt = {
  role: H2HParticipantRole;
  prompt: HeadToHeadCardPrompt | null;
};

export type PromptVisibility = H2HParticipantRole | 'self';

export type H2HGamePlayCardPrompt = {
  text: string;
  mediaId?: string;
  mediaType?: EnumsMediaType;
  visibility: readonly PromptVisibility[];
  voRuntimeHash?: string;
};

export type H2HGamePlayCard = {
  id: string;
  index: number;
  audience: H2HGamePlayCardPrompt;
  groupA: H2HGamePlayCardPrompt;
  groupB: H2HGamePlayCardPrompt;
  phase: 'ready' | 'revealed';
};

export type H2HGamePlayCardMap = {
  [index: string]: H2HGamePlayCard;
};

export type HeadToHeadVote = {
  groupId: string;
  votedAt: number;
};

export type H2HRoundProgress = {
  currentCardIndex: number;
  currentCardPhase: 'ready' | 'revealed';
  currentAGroupId: string;
  currentBGroupId: string;
  currentTurn?: H2HPlayerRole;
  roundPhase:
    | 'playing'
    | 'judging-after-playing'
    | 'reveal-winner'
    | 'select-next';
  roundPhaseChangedAt: number;
  initedAt: number;
  votes?: Record<string, HeadToHeadVote>; // voterUid -> vote
};

export type H2HGameInfo = {
  playedGroupIds?: Record<string, number>; // groupId -> timestamp;
  roundCount: number;
};

export type H2HGameRoot = {
  cards: H2HGamePlayCardMap;
  progress: H2HRoundProgress;
  info: H2HGameInfo;
};

export const H2H_VOICE_OVER_VARIABLES = [
  'pinkSideNames',
  'blueSideNames',
  'coordinatorName',
] as const;

export type H2HVoiceOverVariableKeys =
  (typeof H2H_VOICE_OVER_VARIABLES)[number];

export type H2HVoiceOverVariables = Record<
  H2HVoiceOverVariableKeys,
  Nullable<string>
>;
