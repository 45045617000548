import sortBy from 'lodash/sortBy';
import { useEffect, useMemo, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { useTimeoutFn } from 'react-use';
import useMeasure, { type RectReadOnly } from 'react-use-measure';

import {
  EnumsHiddenPictureAsymmetricPinDropAudibility,
  EnumsHiddenPictureAsymmetricPinDropVisibility,
} from '@lp-lib/api-service-client/public';
import { ProfileIndex } from '@lp-lib/crowd-frames-schema';
import {
  type CircleHotSpotData,
  type HiddenPictureBlock,
  HotSpotShape,
  type HotSpotV2,
  type RectangleHotSpotData,
} from '@lp-lib/game';
import {
  type Media,
  type MediaData,
  MediaType,
  VolumeLevelUtils,
} from '@lp-lib/media';

import fireImg from '../../../../assets/img/hot-seat-fire.png';
import { useFeatureQueryParam } from '../../../../hooks/useFeatureQueryParam';
import { useLiveCallback } from '../../../../hooks/useLiveCallback';
import { useMyInstance } from '../../../../hooks/useMyInstance';
import { useWindowDimensions } from '../../../../hooks/useWindowDimensions';
import { TEAM_COLORS, type TeamId } from '../../../../types';
import { getStaticAssetPath } from '../../../../utils/assets';
import { assertExhaustive } from '../../../../utils/common';
import {
  MediaPickPriorityHD,
  MediaUtils,
  type PickMediaUrlOptions,
} from '../../../../utils/media';
import { ResizeObserver } from '../../../../utils/ResizeObserver';
import { useClock } from '../../../Clock';
import { CrowdFramesAvatar } from '../../../CrowdFrames';
import { FilledCheckIcon } from '../../../icons/CheckIcon';
import { SearchIcon } from '../../../icons/SearchIcon';
import { XIcon } from '../../../icons/XIcon';
import { useParticipant } from '../../../Player';
import {
  type SoundEffectKeys,
  useRawSoundEffect,
  useSoundEffect,
  useSoundEffectFiles,
} from '../../../SFX';
import { type SoundEffect, type SoundEffectControl } from '../../../SFX/types';
import { useIsTeamCaptainScribe, useTeam } from '../../../TeamAPI/TeamV1';
import { useGameSessionBlock, useGameSessionLocalTimer } from '../../hooks';
import { ContainLayout } from '../Common/GamePlay/ContainLayout';
import { useGamePlayEmitter } from '../Common/GamePlay/GamePlayProvider';
import { ProgressRing } from '../Common/GamePlay/ProgressRing';
import { useTeamSubmissionStatusAPI } from '../Common/GamePlay/SubmissionStatusProvider';
import {
  useHiddenPictureAllFound,
  useHiddenPictureCompleted,
  useHiddenPictureCurrentEveryoneClicks,
  useHiddenPictureCurrentHotSpots,
  useHiddenPictureCurrentIncorrectAnswerPenalty,
  useHiddenPictureCurrentPicture,
  useHiddenPictureCurrentSequenced,
  useHiddenPictureFailed,
  useHiddenPictureFoundHotSpots,
  useHiddenPictureGame,
  useHiddenPictureGamePictures,
  useHiddenPictureGamePlayAPI,
  useHiddenPictureNextPicture,
  useHiddenPictureNumPenaltiesRemaining,
  useHiddenPicturePins,
  useHiddenPictureScore,
  useHiddenPictureScorer,
  useHiddenPictureSignals,
  useHiddenPictureToolAssigner,
  useHiddenPictureToolAssignment,
  useHiddenPictureTools,
} from './HiddenPictureProvider';
import { GameState, type Pin, type Signal } from './types';
import { HiddenPictureUtils, type Point } from './utils';

function PointsAnimation(props: {
  points: number;
  name?: string;
}): JSX.Element | null {
  const { points, name } = props;
  return (
    <div
      className={`font-cairo text-lg lp-sm:text-4.25xl font-black  ${
        points >= 0 ? 'text-green-001' : 'text-red-002'
      } text-shadow flex flex-col animate-pin-points select-none`}
    >
      {name && <p className='text-sm lp-sm:text-xl leading-tight'>{name}</p>}
      <p className='leading-none'>
        {points >= 0 && '+'}
        {points}
      </p>
    </div>
  );
}

const longDuration = 240;

function PromptView(props: {
  time: number;
  totalTime: number;
  question?: string;
}): JSX.Element {
  const { time, totalTime, question } = props;
  const durationFormattedMMSS = totalTime >= longDuration;

  return (
    <div className='w-full md:w-3/4 lp-sm:w-3/5 min-w-130 flex items-center justify-center relative ml-16 mb-2 isolate'>
      <ProgressRing
        className='absolute -left-16 z-[2]'
        textClassName={durationFormattedMMSS ? 'text-lg' : 'text-xl'}
        currentTime={time}
        totalTime={totalTime}
        withPingAnimations
        durationFormattedMMSS={durationFormattedMMSS}
      />
      <div
        className={`
          w-full h-15 rounded-2.5xl 
          flex items-center justify-center 
          bg-dark-gray z-[1]
        `}
      >
        <p className='text-sms font-bold px-7 text-center'>{question}</p>
      </div>
    </div>
  );
}

function Prompt(props: { time: number; totalTime: number }): JSX.Element {
  const { time, totalTime } = props;
  const question = useHiddenPictureCurrentPicture()?.question;
  return <PromptView time={time} totalTime={totalTime} question={question} />;
}

const ZOOM_LEVEL = 2;
const TOOL_SIZE = 0.3; // as a percentage of the container height.

function ControllableMagnifier(props: {
  clientId: string;
  teamId: string;
  mediaUrl: string;
  containerRect: RectReadOnly;
  zoomLevel: number;
  hidePointsAnimation?: boolean;
}): JSX.Element | null {
  const {
    containerRect,
    clientId,
    teamId,
    mediaUrl,
    zoomLevel,
    hidePointsAnimation,
  } = props;
  const { updateToolPosition } = useHiddenPictureGamePlayAPI(clientId, teamId);
  const [cursorPos, setCursorPos] = useState<Point | undefined>(undefined);

  const handleMouseMove = useLiveCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      if (!containerRect) return;
      const { left, top, width, height } = containerRect;
      const x = (e.pageX - left) / width;
      const y = (e.pageY - top) / height;

      setCursorPos({ x, y });
      updateToolPosition('magnifier', { x, y });
    }
  );

  const handleMouseLeave = () => {
    setCursorPos(undefined);
    updateToolPosition('magnifier', null);
  };

  const lensWidth = containerRect.height * TOOL_SIZE;
  return (
    <div
      className='absolute inset-0'
      onMouseMove={handleMouseMove}
      onMouseLeave={handleMouseLeave}
    >
      {cursorPos && (
        <MagnifyingLens
          mediaUrl={mediaUrl}
          position={cursorPos}
          lensWidth={lensWidth}
          containerHeight={containerRect.height}
          containerWidth={containerRect.width}
          zoomLevel={zoomLevel}
          hidePointsAnimation={hidePointsAnimation}
        />
      )}
    </div>
  );
}

function ReadOnlyMagnifier(props: {
  containerRect: RectReadOnly;
  mediaUrl: string;
  onDropPin: (x: number, y: number) => void;
  zoomLevel: number;
  hidePointsAnimation?: boolean;
}): JSX.Element | null {
  const { containerRect, mediaUrl, onDropPin, zoomLevel, hidePointsAnimation } =
    props;
  const { magnifier } = useHiddenPictureTools();
  if (!magnifier) return null;

  const lensWidth = containerRect.height * TOOL_SIZE;
  return (
    <MagnifyingLens
      mediaUrl={mediaUrl}
      position={magnifier}
      lensWidth={lensWidth}
      containerHeight={containerRect.height}
      containerWidth={containerRect.width}
      zoomLevel={zoomLevel}
      hidePointsAnimation={hidePointsAnimation}
      onClick={({ x, y }) => {
        onDropPin(x, y);
      }}
    />
  );
}

function MagnifyingLens(props: {
  mediaUrl: string;
  position: { x: number; y: number };
  lensWidth: number;
  containerHeight: number;
  containerWidth: number;
  zoomLevel: number;
  onClick?: (normalized: Point) => void;
  hidePointsAnimation?: boolean;
}): JSX.Element | null {
  const {
    containerWidth,
    containerHeight,
    mediaUrl,
    position,
    lensWidth,
    zoomLevel,
    onClick,
    hidePointsAnimation,
  } = props;

  const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (!onClick) return;
    e.stopPropagation();

    const rect = e.currentTarget.getBoundingClientRect();
    const clickX = e.clientX - rect.left; // x position within the element
    const clickY = e.clientY - rect.top; // y position within the element

    // normalized distance from the center
    const dx = (clickX - lensWidth / 2) / containerWidth;
    const dy = (clickY - lensWidth / 2) / containerHeight;

    const x = dx / zoomLevel + position.x;
    const y = dy / zoomLevel + position.y;
    onClick({ x, y });
  };

  return (
    <div
      className={`
        absolute overflow-hidden
        rounded-full bg-no-repeat cursor-none
        select-none ${
          onClick === undefined ? 'pointer-events-none' : 'pointer-events-auto'
        }
        border border-black
        shadow-2xl bg-lp-black-003
      `}
      onClick={handleClick}
      style={{
        width: `${lensWidth}px`,
        height: `${lensWidth}px`,
        top: `${position.y * 100}%`,
        left: `${position.x * 100}%`,
        transform: 'translate(-50%, -50%)',
      }}
    >
      <div
        className='absolute'
        style={{
          width: `${containerWidth * zoomLevel}px`,
          height: `${containerHeight * zoomLevel}px`,
          top: `-${position.y * zoomLevel * containerHeight}px`,
          left: `-${position.x * zoomLevel * containerWidth}px`,
          transform: `translate(${lensWidth / 2}px, ${lensWidth / 2}px)`,
        }}
      >
        <img
          src={mediaUrl}
          className='w-full h-full rounded-xl pointer-events-none select-none'
          alt='Hidden Picutre'
        />
        <Pins silent hidePointsAnimation={hidePointsAnimation} />
      </div>
    </div>
  );
}

function Magnifier(props: {
  clientId: string;
  teamId: string;
  containerRect: RectReadOnly;
  controllable: boolean;
  onDropPin: (x: number, y: number) => void;
  hidePointsAnimation?: boolean;
}): JSX.Element | null {
  const mainMedia = useHiddenPictureCurrentPicture()?.mainMedia;
  const mediaUrl = MediaUtils.PickMediaUrl(mainMedia, {
    priority: MediaPickPriorityHD,
  });
  if (mainMedia?.type !== MediaType.Image || !mediaUrl) return null;

  if (props.controllable) {
    return (
      <ControllableMagnifier
        {...props}
        mediaUrl={mediaUrl}
        zoomLevel={ZOOM_LEVEL}
      />
    );
  } else {
    return (
      <ReadOnlyMagnifier
        {...props}
        mediaUrl={mediaUrl}
        zoomLevel={ZOOM_LEVEL}
      />
    );
  }
}

function ControllableUnblur(props: {
  clientId: string;
  teamId: string;
  containerRect: RectReadOnly;
  hidePointsAnimation?: boolean;
}): JSX.Element | null {
  const { containerRect, clientId, teamId, hidePointsAnimation } = props;
  const { updateToolPosition } = useHiddenPictureGamePlayAPI(clientId, teamId);
  const [cursorPos, setCursorPos] = useState<Point | undefined>(undefined);

  const handleMouseMove = useLiveCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      if (!containerRect) return;
      const { left, top, width, height } = containerRect;
      const x = (e.pageX - left) / width;
      const y = (e.pageY - top) / height;

      setCursorPos({ x, y });
      updateToolPosition('unblur', { x, y });
    }
  );

  const handleMouseLeave = () => {
    setCursorPos(undefined);
    updateToolPosition('unblur', null);
  };

  const gradientSize = (containerRect.height * TOOL_SIZE) / 2;
  return (
    <div
      className='absolute inset-0 rounded-xl'
      onMouseMove={handleMouseMove}
      onMouseLeave={handleMouseLeave}
    >
      <div className='absolute inset-0 rounded-xl backdrop-filter backdrop-blur-lg' />
      {cursorPos !== undefined && (
        <div
          className='absolute inset-0 rounded-xl'
          style={{
            clipPath: `circle(${gradientSize}px at ${cursorPos.x * 100}% ${
              cursorPos.y * 100
            }%)`,
          }}
        >
          <MainMedia />
          <Pins silent hidePointsAnimation={hidePointsAnimation} />
        </div>
      )}
    </div>
  );
}

function ReadOnlyUnblur(props: {
  containerRect: RectReadOnly;
  hidePointsAnimation?: boolean;
}): JSX.Element | null {
  const { containerRect, hidePointsAnimation } = props;
  const { unblur } = useHiddenPictureTools();

  const gradientSize = (containerRect.height * TOOL_SIZE) / 2;
  return (
    <>
      <div className='absolute inset-0 rounded-xl backdrop-filter backdrop-blur-lg' />
      {unblur !== undefined && (
        <div
          className='absolute inset-0 rounded-xl'
          style={{
            clipPath: `circle(${gradientSize}px at ${unblur.x * 100}% ${
              unblur.y * 100
            }%)`,
          }}
        >
          <MainMedia />
          <Pins silent hidePointsAnimation={hidePointsAnimation} />
        </div>
      )}
    </>
  );
}

function Unblur(props: {
  clientId: string;
  teamId: string;
  containerRect: RectReadOnly;
  controllable: boolean;
  hidePointsAnimation?: boolean;
}): JSX.Element | null {
  if (props.controllable) {
    return <ControllableUnblur {...props} />;
  } else {
    return <ReadOnlyUnblur {...props} />;
  }
}

function ControllableFlashlight(props: {
  clientId: string;
  teamId: string;
  containerRect: RectReadOnly;
}): JSX.Element | null {
  const { containerRect, clientId, teamId } = props;
  const { updateToolPosition } = useHiddenPictureGamePlayAPI(clientId, teamId);
  const [cursorPos, setCursorPos] = useState<Point | undefined>(undefined);

  const handleMouseMove = useLiveCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      if (!containerRect) return;
      const { left, top, width, height } = containerRect;
      const x = (e.pageX - left) / width;
      const y = (e.pageY - top) / height;

      setCursorPos({ x, y });
      updateToolPosition('flashlight', { x, y });
    }
  );

  const handleMouseLeave = () => {
    setCursorPos(undefined);
    updateToolPosition('flashlight', null);
  };

  const gradientSize = (containerRect.height * TOOL_SIZE) / 2;
  return (
    <div
      className='absolute inset-0 rounded-xl bg-black bg-opacity-90'
      style={{
        background:
          cursorPos === undefined
            ? ''
            : `radial-gradient(circle ${gradientSize}px at ${
                cursorPos.x * 100
              }% ${cursorPos.y * 100}%, rgba(0,0,0,0), rgba(0,0,0,0.90))`,
      }}
      onMouseMove={handleMouseMove}
      onMouseLeave={handleMouseLeave}
    />
  );
}

function ReadOnlyFlashlight(props: {
  containerRect: RectReadOnly;
}): JSX.Element | null {
  const { containerRect } = props;
  const { flashlight } = useHiddenPictureTools();

  const gradientSize = (containerRect.height * TOOL_SIZE) / 2;
  return (
    <div
      className='absolute inset-0 rounded-xl bg-black bg-opacity-90'
      style={{
        background:
          flashlight === undefined
            ? ''
            : `radial-gradient(circle ${gradientSize}px at ${
                flashlight.x * 100
              }% ${flashlight.y * 100}%, rgba(0,0,0,0), rgba(0,0,0,0.90))`,
      }}
    />
  );
}

function Flashlight(props: {
  clientId: string;
  teamId: string;
  containerRect: RectReadOnly;
  controllable: boolean;
  hidePointsAnimation?: boolean;
}): JSX.Element | null {
  if (props.controllable) {
    return <ControllableFlashlight {...props} />;
  } else {
    return <ReadOnlyFlashlight {...props} />;
  }
}

function Tool(props: {
  clientId: string;
  teamId: string;
  containerRect: RectReadOnly;
  disabled?: boolean;
  onDropPin: (x: number, y: number) => void;
  hidePointsAnimation?: boolean;
}): JSX.Element | null {
  const assignment = useHiddenPictureToolAssignment();
  if (!assignment || props.disabled) return null;

  const controllable = assignment.clientId === props.clientId;

  switch (assignment.tool) {
    case 'flashlight':
      return <Flashlight {...props} controllable={controllable} />;
    case 'magnifier':
      return <Magnifier {...props} controllable={controllable} />;
    case 'unblur':
      return <Unblur {...props} controllable={controllable} />;
    case 'none':
      return null;
    default:
      assertExhaustive(assignment.tool);
      return null;
  }
}

const coverUrl = getStaticAssetPath('images/memory-match-card-cover.png');

function MainMediaView(props: {
  media: Media | undefined | null;
  mediaData: MediaData | undefined | null;
  hide?: boolean;
}): JSX.Element | null {
  let body = null;
  if (props.hide) {
    body = (
      <img
        className='rounded-xl w-full h-full object-cover pointer-events-none select-none'
        src={coverUrl}
        alt='luna-park'
      />
    );
  } else {
    const { media, mediaData } = props;
    const mediaUrl = MediaUtils.PickMediaUrl(media, {
      priority: MediaPickPriorityHD,
    });

    if (media?.type && mediaUrl) {
      switch (media.type) {
        case MediaType.Image:
          body = (
            <img
              className='rounded-xl w-full h-full object-cover pointer-events-none select-none'
              src={mediaUrl}
              alt='luna-park'
            />
          );
          break;
        case MediaType.Video:
          body = (
            <video
              src={mediaUrl}
              muted
              loop={mediaData?.loop}
              autoPlay
              className='rounded-xl w-full h-full object-cover pointer-events-none select-none'
            />
          );
          break;
        case MediaType.Audio:
          break;
        default:
          assertExhaustive(media.type);
          break;
      }
    }
  }
  return <div className='w-full h-full bg-lp-black-001 rounded-xl'>{body}</div>;
}

function MainMedia(props: { hide?: boolean }): JSX.Element | null {
  const { mainMedia, mainMediaData } = useHiddenPictureCurrentPicture() ?? {};
  return (
    <MainMediaView media={mainMedia} mediaData={mainMediaData} {...props} />
  );
}

const magnifierToolIcon = getStaticAssetPath(
  'images/hidden-pictures-magnifier.png'
);
const glassesToolIcon = getStaticAssetPath(
  'images/hidden-pictures-glasses.png'
);
const flashlightToolIcon = getStaticAssetPath(
  'images/hidden-pictures-flashlight.png'
);

function ToolAssignment(): JSX.Element | null {
  const toolAssignment = useHiddenPictureToolAssignment();
  const participant = useParticipant(toolAssignment?.clientId);
  const team = useTeam(participant?.teamId);

  const toolIcon = useMemo(() => {
    switch (toolAssignment?.tool) {
      case 'flashlight':
        return (
          <div className='absolute -bottom-1 -right-1/2 z-20 animate-fade-in-up'>
            <img
              src={flashlightToolIcon}
              alt='magnifier'
              className='w-8.5 h-8.5'
            />
          </div>
        );
      case 'magnifier':
        return (
          <div className='absolute -bottom-1 -right-1/2 z-20 animate-fade-in-up'>
            <img
              src={magnifierToolIcon}
              alt='magnifier'
              className='w-8.5 h-8.5'
            />
          </div>
        );
      case 'unblur':
        return (
          <div className='absolute -bottom-1 left-0 w-10 h-5 z-20 animate-fade-in-up'>
            <img
              src={glassesToolIcon}
              alt='magnifier'
              className='w-full h-full'
            />
          </div>
        );
      default:
        return null;
    }
  }, [toolAssignment?.tool]);

  if (!toolAssignment || !participant) return null;

  return (
    <div className='w-full mb-3.5 py-3 px-3 flex items-center gap-4 bg-lp-gray-001'>
      <div className='relative flex-none'>
        {toolIcon}
        <div
          className='relative w-8 h-8 rounded-full overflow-hidden border'
          style={{ borderColor: team?.color }}
        >
          <CrowdFramesAvatar
            participant={participant}
            profileIndex={ProfileIndex.wh100x100fps8}
            enablePointerEvents={false}
            roundedClassname='rounded-none'
          />
        </div>
      </div>

      <div className='flex-1 text-sm'>
        <div className='font-bold capitalize'>{toolAssignment.tool}</div>
        <div>{participant.firstName ?? participant.username}</div>
      </div>
    </div>
  );
}

function ItemRow(props: {
  name: string;
  points?: number;
  index?: number;
}): JSX.Element {
  const color =
    props.points === undefined
      ? 'text-white opacity-60'
      : props.points >= 0
      ? 'text-green-001'
      : 'text-red-001';

  return (
    <div
      className={`w-full flex items-center justify-between gap-1 px-3.5 ${color} font-bold text-xs xl:text-sms`}
    >
      <div className='font-bold'>
        {props.index !== undefined && (
          <span className='mr-1'>{props.index + 1}.</span>
        )}
        {props.name || 'unknown'}
      </div>
      {props.points !== undefined && (
        <div className='font-bold'>{`${props.points > 0 ? '+' : ''}${
          props.points
        }`}</div>
      )}
    </div>
  );
}

function Sidebar() {
  const game = useHiddenPictureGame();
  const currentScore = useHiddenPictureScore();
  const { maxPenaltyLimit, maxPenaltyLimitLabel } = game ?? {};

  return (
    <div
      className={`
        w-full h-full relative
        bg-gradient-to-b from-rightpanel-start to-rightpanel-end
        border border-black
        rounded-tr-1.5lg rounded-br-1.5lg
        py-3.5
      `}
    >
      {currentScore !== undefined && (
        <div className='absolute -top-5.5 -right-5.5'>
          <div className='w-11 h-11 mr-0.75 ml-3 rounded-full bg-warning text-black flex flex-col justify-center items-center'>
            <div
              className={`font-extrabold ${
                currentScore < 0 || currentScore > 99 ? 'text-lg' : 'text-xl'
              } leading-5 font-cairo`}
            >
              {currentScore}
            </div>
            <p className='text-3xs text-black'>pts</p>
          </div>
        </div>
      )}

      <div className='w-full h-full'>
        {maxPenaltyLimit ? (
          <MaxPenaltyLimitSidebar
            label={maxPenaltyLimitLabel || 'Max Penalty Limit'}
          />
        ) : (
          <ItemList />
        )}
      </div>
    </div>
  );
}

function MaxPenaltyLimitSidebar(props: { label: string }) {
  const numPenaltiesRemaining = useHiddenPictureNumPenaltiesRemaining();
  if (numPenaltiesRemaining === null) return null;

  return (
    <div className='w-full h-full flex flex-col items-center justify-center gap-2 px-2'>
      <div className='relative'>
        <div
          style={{
            display: numPenaltiesRemaining <= 1 ? 'block' : 'none',
          }}
          className='absolute w-32 h-44 bottom-0 left-1/2 -translate-x-1/2 transform scale-110 animate-fade-in'
        >
          <img
            src={fireImg}
            alt='fire'
            className='w-full h-full object-contain'
          ></img>
        </div>
        <div
          className={`
            relative
            w-18 h-18 flex items-center justify-center rounded-full
            bg-gradient-to-t from-[#9D0303] to-[#FE0653]
            text-white font-bold text-2xl
            ring-2 ring-white
          `}
        >
          {numPenaltiesRemaining}
        </div>
      </div>
      <div className='text-center font-bold text-white text-base xl:text-lg'>
        {props.label}
      </div>
    </div>
  );
}

function ItemList(): JSX.Element {
  const sequenced = useHiddenPictureCurrentSequenced();
  const hotSpots = useHiddenPictureCurrentHotSpots();
  const incorrectAnswerPenalty =
    useHiddenPictureCurrentIncorrectAnswerPenalty();
  const foundHotSpots = useHiddenPictureFoundHotSpots();
  const pins = useHiddenPicturePins();

  const [numHotSpots, numFoundHotSpots] = useMemo(() => {
    const progressableHotSpots =
      HiddenPictureUtils.GetProgressableHotSpots(hotSpots);
    const numHotSpots = progressableHotSpots.length;
    const numFound = HiddenPictureUtils.CountFoundProgressableHotSpots(
      hotSpots,
      foundHotSpots
    );
    return [numHotSpots, numFound];
  }, [foundHotSpots, hotSpots]);

  const [numPenalties, penaltyScore] = useMemo(() => {
    const numPenalties = Object.values(pins).filter(
      (p) => p.grade === 'miss'
    ).length;
    return [numPenalties, numPenalties * incorrectAnswerPenalty];
  }, [pins, incorrectAnswerPenalty]);

  const items = useMemo(() => {
    const items = [];
    if (sequenced) {
      sortBy(
        Object.entries(foundHotSpots),
        ([_, value]) => value.createdAt
      ).forEach(([hotSpotId], index) => {
        const hotSpot = hotSpots.find((hotSpot) => hotSpot.id === hotSpotId);
        if (!hotSpot) return;
        items.push(
          <ItemRow
            key={hotSpotId}
            name={hotSpot.name}
            points={hotSpot.points}
            index={index}
          />
        );
      });
    } else {
      for (const hotSpot of hotSpots) {
        if (hotSpot.points < 0) continue;
        const isFound = foundHotSpots[hotSpot.id] !== undefined;
        items.push(
          <ItemRow
            key={hotSpot.id}
            name={hotSpot.name}
            points={isFound ? hotSpot.points : undefined}
          />
        );
      }
    }

    if (numPenalties > 0 && penaltyScore > 0) {
      items.push(
        <ItemRow
          key='penalty'
          name={`Penalties (${numPenalties})`}
          points={-penaltyScore}
        />
      );
    }
    return items.reverse();
  }, [foundHotSpots, hotSpots, sequenced, numPenalties, penaltyScore]);

  return (
    <div className='w-full h-full flex flex-col items-center justify-start overflow-y-auto scrollbar'>
      <p className='mb-3.5 font-cairo font-bold text-xs xl:text-sm sticky'>
        Your Team’s Progress
      </p>

      <div className='w-full mb-3.5'>
        <div className='text-center text-lg xl:text-xl text-white font-bold'>
          {`${numFoundHotSpots} / ${numHotSpots}`}
        </div>
        <div className='text-center text-2xs xl:text-xs text-white'>
          Items Found
        </div>
      </div>
      <ToolAssignment />
      <div className='w-full space-y-3'>{items}</div>
    </div>
  );
}

function NextPictureMediaPreloader(): JSX.Element | null {
  const maybeNext = useHiddenPictureNextPicture();
  return (
    <MediaPreloader
      media={maybeNext?.mainMedia}
      pickOptions={{
        priority: MediaPickPriorityHD,
      }}
    />
  );
}

// this is best effort.
function MediaPreloader(props: {
  media?: Media | null;
  pickOptions?: PickMediaUrlOptions;
}): JSX.Element | null {
  if (!props.media) return null;

  const mediaUrl = MediaUtils.PickMediaUrl(props.media, props.pickOptions);
  if (!mediaUrl) return null;

  return (
    <div className='hidden'>
      {props.media.type === MediaType.Image && (
        <img key={props.media.id} src={mediaUrl} alt='luna-park' />
      )}
      {props.media.type === MediaType.Video && (
        <video
          key={props.media.id}
          src={mediaUrl}
          preload='auto'
          autoPlay={false}
          muted
        />
      )}
    </div>
  );
}

function EnlargeButton(props: { onClick: () => void }): JSX.Element {
  const [expanded, setExpanded] = useState(false);
  return (
    <button
      type='button'
      onClick={props.onClick}
      className={`
        bg-white border border-black p-2 rounded-xl cursor-pointer text-black flex items-center gap-2
        ${expanded ? 'max-w-40' : 'max-w-8'}
        transition-size overflow-hidden ease-in-out duration-300 shadow-xl
      `}
      onMouseEnter={() => setExpanded(true)}
      onMouseLeave={() => setExpanded(false)}
    >
      <SearchIcon className='flex-none w-4 h-4 fill-current' />
      <div className='flex-none text-sms font-bold leading-none'>
        Enlarge Picture
      </div>
    </button>
  );
}

function HiddenPicturePlayable(props: {
  block: HiddenPictureBlock;
}): JSX.Element | null {
  const me = useMyInstance();
  const clientId = me?.clientId;
  const teamId = me?.teamId;
  const game = useHiddenPictureGame();

  const inGame = !!game?.state && game.state === GameState.InProgress;
  const hideMedia = !game?.state || game.state < GameState.InProgress;
  const isTeamCaptainScribe = useIsTeamCaptainScribe(me?.teamId, me?.clientId);
  const everyoneClicks = useHiddenPictureCurrentEveryoneClicks();
  const tool = !everyoneClicks && !isTeamCaptainScribe ? 'signal' : 'pin';

  const completed = useHiddenPictureCompleted();
  const failed = useHiddenPictureFailed();

  const [enlarge, setEnlarge] = useState(false);
  useEffect(() => {
    if (completed || failed || !inGame) setEnlarge(false);
  }, [completed, failed, inGame]);

  const gamePlayArea = useMemo(() => {
    if (!clientId || !teamId) return null;
    const container = (
      <PinContainer
        block={props.block}
        clientId={clientId}
        clientName={me?.firstName ?? me?.username ?? ''}
        teamId={teamId}
        inGame={inGame}
        pinType={tool}
        hideMedia={hideMedia}
      />
    );
    return enlarge ? (
      createPortal(
        <div className='fixed inset-0'>
          <div className='absolute inset-0 bg-lp-black-001' />
          <div className='absolute inset-[8%] flex flex-col items-center justify-center gap-2'>
            <div className='flex-1 w-full overflow-hidden flex items-center justify-center'>
              {container}
            </div>
            <div
              onClick={() => setEnlarge(false)}
              className='cursor-pointer hover:underline text-white text-sms font-bold'
            >
              Close Enlarged Picture
            </div>
          </div>
        </div>,
        document.body
      )
    ) : (
      <div className='relative'>
        {container}
        <div className='absolute top-0 -left-10'>
          <EnlargeButton onClick={() => setEnlarge(true)} />
        </div>
      </div>
    );
  }, [
    clientId,
    enlarge,
    hideMedia,
    inGame,
    me?.firstName,
    me?.username,
    props.block,
    teamId,
    tool,
  ]);

  if (!clientId || !teamId) return null;
  return (
    <>
      <div className='w-full h-full grid grid-cols-5 relative isolate'>
        <div
          className={`flex-none z-5 ${
            props.block.fields.showItemList
              ? 'col-span-5 lg:col-span-4'
              : 'col-span-5'
          }`}
        >
          {gamePlayArea}
          {inGame && isTeamCaptainScribe && (
            <TeamCaptainWatch teamId={teamId} />
          )}
        </div>
        {props.block.fields.showItemList && (
          <div className='hidden h-full lg:flex lg:col-span-1 items-center relative'>
            <div className='absolute top-[10%] bottom-[10%] left-0 right-0 z-0'>
              <Sidebar />
            </div>
          </div>
        )}
      </div>
      <NextPictureMediaPreloader />
    </>
  );
}

function HotSpotsView(props: {
  hotSpots: HotSpotV2[];
  sequenced: boolean;
}): JSX.Element {
  const { hotSpots, sequenced } = props;
  return (
    <>
      {hotSpots.map((hotSpot, index) => {
        const bgColor =
          hotSpot.points >= 0 ? 'bg-lp-green-001' : 'bg-lp-red-001';
        const sequenceIndex = sequenced ? index + 1 : undefined;
        switch (hotSpot.shape) {
          case HotSpotShape.Circle:
            if (!hotSpot.shapeData.circle) return null;
            return (
              <HotSpotCircle
                key={hotSpot.id}
                shape={hotSpot.shapeData.circle}
                bgColor={bgColor}
                index={sequenceIndex}
              />
            );
          case HotSpotShape.Rectangle:
            if (!hotSpot.shapeData.rectangle) return null;
            return (
              <HotSpotRectangle
                key={hotSpot.id}
                shape={hotSpot.shapeData.rectangle}
                bgColor={bgColor}
                index={sequenceIndex}
              />
            );
          default:
            return null;
        }
      })}
    </>
  );
}

function HotSpotCircle(props: {
  shape: CircleHotSpotData;
  bgColor: string;
  index: number | undefined;
}) {
  const { shape, bgColor, index } = props;
  return (
    <div
      className='absolute select-none'
      style={{
        height: `${shape.radius * 2 * 100}%`,
        width: 'auto',
        aspectRatio: '1/1',
        top: `${shape.top * 100}%`,
        left: `${shape.left * 100}%`,
      }}
    >
      <div
        className={`
          ${bgColor} bg-opacity-50 rounded-full w-full h-full flex items-center justify-center
        `}
      >
        {index !== undefined && (
          <div className={`font-mono font-bold`}>{index}</div>
        )}
      </div>
    </div>
  );
}

function HotSpotRectangle(props: {
  shape: RectangleHotSpotData;
  bgColor: string;
  index: number | undefined;
}) {
  const { shape, bgColor, index } = props;
  return (
    <div
      className='absolute select-none'
      style={{
        height: `${shape.height * 100}%`,
        width: `${shape.width * 100}%`,
        top: `${shape.top * 100}%`,
        left: `${shape.left * 100}%`,
      }}
    >
      <div
        className={`
          ${bgColor} bg-opacity-50 w-full h-full flex items-center justify-center
        `}
      >
        {index !== undefined && (
          <div className={`font-mono font-bold`}>{index}</div>
        )}
      </div>
    </div>
  );
}

function HotSpots(): JSX.Element {
  const hotSpots = useHiddenPictureCurrentHotSpots();
  const sequenced = useHiddenPictureCurrentSequenced();
  return <HotSpotsView hotSpots={hotSpots} sequenced={sequenced} />;
}

const HIDE_MISS_PIN_AFTER_MS = 5000;

function MissPin(props: {
  pin: Pin;
  animateAfter: number;
  penalty: number;
  everyoneClicks: boolean;
  silent?: boolean;
  hidePointsAnimation?: boolean;
}): JSX.Element | null {
  const {
    pin,
    animateAfter,
    penalty,
    everyoneClicks,
    silent,
    hidePointsAnimation,
  } = props;
  const shouldAnimate =
    typeof pin.createdAt === 'number' && pin.createdAt > animateAfter;
  const [showPenalty, setShowPenalty] = useState(shouldAnimate);
  useTimeoutFn(() => setShowPenalty(false), HIDE_MISS_PIN_AFTER_MS);

  const { play: playWrongSFX } = useSoundEffect('rapidWrong');
  useEffect(() => {
    if (shouldAnimate && !silent) playWrongSFX();
  }, [playWrongSFX, shouldAnimate, silent]);

  if (!showPenalty) return null;

  return (
    <div
      className='absolute transform -translate-x-1/2 -translate-y-1/2 select-none'
      style={{
        top: `${pin.y * 100}%`,
        left: `${pin.x * 100}%`,
        height: '4%',
        width: 'auto',
        aspectRatio: '1/1',
      }}
    >
      <div className='relative w-full h-full'>
        <div
          className={`
            bg-lp-red-001 bg-opacity-80 
            rounded-full w-full h-full p-px lp-sm:p-1 flex items-center justify-center text-white
            ${shouldAnimate ? 'animate-scale-up' : ''}
          `}
          style={
            {
              '--tw-scale-up-duration': '100ms',
            } as React.CSSProperties
          }
        >
          <XIcon className='w-full h-full fill-current' />
        </div>
        <div className='absolute inset-0 flex items-center justify-start transform translate-x-full pl-2'>
          {!hidePointsAnimation && penalty > 0 && shouldAnimate && (
            <PointsAnimation
              points={-penalty}
              name={everyoneClicks ? pin.clientName : undefined}
            />
          )}
        </div>
      </div>
    </div>
  );
}

const HIDE_SIGNAL_PIN_AFTER_MS = 5000;

function getColorForClientId(clientId: string): string {
  let sum = 0;
  for (let i = 0; i < clientId.length; i++) {
    sum += clientId.charCodeAt(i);
  }
  const index = sum % TEAM_COLORS.length;
  return TEAM_COLORS[index];
}

function SignalPin(props: {
  signal: Signal;
  animateAfter: number;
}): JSX.Element | null {
  const { signal, animateAfter } = props;
  const shouldAnimate =
    typeof signal.createdAt === 'number' && signal.createdAt > animateAfter;
  const [showSignal, setShowSignal] = useState(shouldAnimate);
  useTimeoutFn(() => setShowSignal(false), HIDE_SIGNAL_PIN_AFTER_MS);

  if (!showSignal) return null;

  return (
    <div
      className='absolute transform -translate-x-1/2 -translate-y-1/2 pointer-events-none'
      style={{
        top: `${signal.y * 100}%`,
        left: `${signal.x * 100}%`,
        height: '8%',
        width: 'auto',
        aspectRatio: '1/1',
      }}
    >
      <div
        className={`
          absolute inset-0 overflow-hidden
          bg-opacity-80
          rounded-full
        `}
        style={{
          backgroundColor: getColorForClientId(signal.clientId),
          animation: 'ping 1s cubic-bezier(0, 0, 0.2, 1) 3 forwards',
        }}
      />
    </div>
  );
}

function useSoundEffectWithFallback(
  audioMedia: Media | null | undefined,
  audioMediaData: MediaData | null | undefined,
  fallbackKey: SoundEffectKeys
): SoundEffectControl {
  const sfxs = useSoundEffectFiles();
  const fallback = sfxs[fallbackKey];

  const [sfx, debugName]: [SoundEffect, string] = useMemo(() => {
    const audioMediaUrl = MediaUtils.PickMediaUrl(audioMedia);
    if (!audioMediaUrl) return [fallback, fallbackKey];
    return [
      {
        url: audioMediaUrl,
        volume: VolumeLevelUtils.ConvertToScale(audioMediaData?.volumeLevel),
      },
      'hiddenPictureCustom',
    ];
  }, [audioMedia, audioMediaData?.volumeLevel, fallback, fallbackKey]);

  return useRawSoundEffect(
    sfx.url,
    debugName,
    sfx.volume,
    sfx.delayMs,
    sfx.loop,
    sfx.echoCanceled
  );
}

function FoundHotSpot(props: {
  pin: Pin;
  hotSpot: HotSpotV2;
  animateAfter: number;
  everyoneClicks: boolean;
  successMediaUrl?: string | null;
  successAudioMedia?: Media | null;
  successAudioMediaData?: MediaData | null;
  failMediaUrl?: string | null;
  failAudioMedia?: Media | null;
  failAudioMediaData?: MediaData | null;
  silent?: boolean;
  hidePointsAnimation?: boolean;
}): JSX.Element | null {
  const {
    pin,
    hotSpot,
    animateAfter,
    everyoneClicks,
    silent,
    hidePointsAnimation,
  } = props;
  const shouldAnimate =
    typeof pin.createdAt === 'number' && pin.createdAt > animateAfter;

  const { play: playSuccessSFX } = useSoundEffectWithFallback(
    props.successAudioMedia,
    props.successAudioMediaData,
    'rapidCorrect'
  );
  const { play: playFailSFX } = useSoundEffectWithFallback(
    props.failAudioMedia,
    props.failAudioMediaData,
    'rapidWrong'
  );

  useEffect(() => {
    if (shouldAnimate && !silent && hotSpot.points >= 0) playSuccessSFX();
  }, [playSuccessSFX, shouldAnimate, silent, hotSpot.points]);

  useEffect(() => {
    if (shouldAnimate && !silent && hotSpot.points < 0) playFailSFX();
  }, [playFailSFX, shouldAnimate, silent, hotSpot.points]);

  const [borderColor, bgColor] =
    hotSpot.points >= 0
      ? ['border-green-001', 'bg-lp-green-001']
      : ['border-red-002', 'bg-lp-red-001'];

  const indicator =
    props.successMediaUrl && hotSpot.points >= 0 ? (
      <div className='relative w-full h-full'>
        <img
          src={props.successMediaUrl}
          className='w-full h-full object-contain'
          draggable={false}
          alt={hotSpot.name}
        />
      </div>
    ) : props.failMediaUrl && hotSpot.points < 0 ? (
      <div className='relative w-full h-full'>
        <img
          src={props.failMediaUrl}
          className='w-full h-full object-contain'
          draggable={false}
          alt={hotSpot.name}
        />
      </div>
    ) : (
      <>
        <div
          className={`
            w-full h-full
            ${hotSpot.shape === HotSpotShape.Circle ? 'rounded-full' : ''}
            ${borderColor} border-2 lp-sm:border-[3px]
            ${bgColor} bg-opacity-30
          `}
        />
        <div
          className={`
            absolute
            w-3 lp-sm:w-5 h-auto p-px
            flex items-center justify-center
            ${bgColor} bg-opacity-80
            rounded-full text-white
          `}
          style={{
            aspectRatio: '1/1',
            right:
              hotSpot.shape === HotSpotShape.Circle
                ? 'calc(50% - (50% * 0.7071) - 10px)'
                : 0,
            bottom:
              hotSpot.shape === HotSpotShape.Circle
                ? 'calc(50% - (50% * 0.7071) - 10px)'
                : 0,
          }}
        >
          {hotSpot.points >= 0 ? (
            <FilledCheckIcon className='w-full h-full fill-current' />
          ) : (
            <XIcon className='w-full h-full fill-current' />
          )}
        </div>
      </>
    );

  const style = useMemo(() => {
    switch (hotSpot.shape) {
      case HotSpotShape.Circle: {
        const shape = hotSpot.shapeData.circle;
        if (!shape) return undefined;
        return {
          top: `${shape.top * 100}%`,
          left: `${shape.left * 100}%`,
          height: `${shape.radius * 2 * 100}%`,
          width: 'auto',
          aspectRatio: '1/1',
          '--tw-scale-up-duration': '100ms',
        } as React.CSSProperties;
      }
      case HotSpotShape.Rectangle: {
        const shape = hotSpot.shapeData.rectangle;
        if (!shape) return undefined;

        return {
          top: `${shape.top * 100}%`,
          left: `${shape.left * 100}%`,
          height: `${shape.height * 100}%`,
          width: `${shape.width * 100}%`,
          '--tw-scale-up-duration': '100ms',
        } as React.CSSProperties;
      }
      default:
        return undefined;
    }
  }, [hotSpot]);

  // here, we render the indicator in the middle of the hot spot, not where the pin was dropped.
  return (
    <div
      className='absolute flex items-center justify-center select-none animate-scale-up'
      style={style}
    >
      {indicator}
      <div className='absolute inset-0 flex items-center justify-start transform translate-x-full pl-2'>
        {!hidePointsAnimation && hotSpot.points !== 0 && shouldAnimate && (
          <PointsAnimation
            points={hotSpot.points}
            name={everyoneClicks ? pin.clientName : undefined}
          />
        )}
      </div>
    </div>
  );
}

function Pins(props: {
  silent?: boolean;
  hidden?: boolean;
  hidePointsAnimation?: boolean;
}): JSX.Element {
  const clock = useClock();
  const pins = useHiddenPicturePins();
  const signals = useHiddenPictureSignals();
  const foundHotSpots = useHiddenPictureFoundHotSpots();
  const block = useGameSessionBlock() as HiddenPictureBlock | null;
  const successMediaUrl = MediaUtils.PickMediaUrl(block?.fields.successMedia);
  const failMediaUrl = MediaUtils.PickMediaUrl(block?.fields.failMedia);
  const animateAfter = useRef(clock.now());

  const hotSpots = useHiddenPictureCurrentHotSpots();
  const penalty = useHiddenPictureCurrentIncorrectAnswerPenalty();
  const everyoneClicks = useHiddenPictureCurrentEveryoneClicks();

  const renderedElements = useMemo(() => {
    // Create array of elements along with their creation times
    const allElements = [
      ...Object.entries(pins)
        .filter(([_, pin]) => {
          return pin.grade === 'miss';
        })
        .map(([key, pin]) => ({
          createdAt: pin.createdAt as number,
          element: (
            <MissPin
              key={key}
              pin={pin}
              animateAfter={animateAfter.current}
              penalty={penalty}
              everyoneClicks={everyoneClicks}
              silent={props.silent}
              hidePointsAnimation={props.hidePointsAnimation}
            />
          ),
        })),
      ...Object.entries(signals).map(([key, signal]) => ({
        createdAt: signal.createdAt as number,
        element: (
          <SignalPin
            key={key}
            signal={signal}
            animateAfter={animateAfter.current}
          />
        ),
      })),
      ...Object.entries(foundHotSpots).map(([hotSpotId, pin]) => {
        const hotSpot = hotSpots.find((hs) => hs.id === hotSpotId);
        return {
          createdAt: pin.createdAt as number,
          element: hotSpot && (
            <FoundHotSpot
              key={hotSpotId}
              pin={pin}
              hotSpot={hotSpot}
              animateAfter={animateAfter.current}
              everyoneClicks={everyoneClicks}
              successMediaUrl={successMediaUrl}
              successAudioMedia={block?.fields.successAudioMedia}
              successAudioMediaData={block?.fields.successAudioMediaData}
              failMediaUrl={failMediaUrl}
              failAudioMedia={block?.fields.failAudioMedia}
              failAudioMediaData={block?.fields.failAudioMediaData}
              silent={props.silent}
              hidePointsAnimation={props.hidePointsAnimation}
            />
          ),
        };
      }),
    ];

    // sort by creation time for better z-indexing
    allElements.sort((a, b) => a.createdAt - b.createdAt);
    return allElements.filter((el) => el !== null).map((el) => el.element);
  }, [
    pins,
    signals,
    foundHotSpots,
    penalty,
    everyoneClicks,
    props.silent,
    props.hidePointsAnimation,
    hotSpots,
    successMediaUrl,
    block?.fields.successAudioMedia,
    block?.fields.successAudioMediaData,
    block?.fields.failAudioMedia,
    block?.fields.failAudioMediaData,
    failMediaUrl,
  ]);

  if (props.hidden) {
    return <div className='hidden'>{renderedElements}</div>;
  }
  return <>{renderedElements}</>;
}

function useShouldSeeAsymmetricGamePlay(): boolean {
  const { everyoneClicks, asymmetricGamePlay } =
    useHiddenPictureCurrentPicture() ?? {};

  const me = useMyInstance();
  const isTeamCaptain = useIsTeamCaptainScribe(me?.teamId, me?.clientId);
  return Boolean(asymmetricGamePlay && !everyoneClicks && !isTeamCaptain);
}

function GamePlayMedia(props: { hide: boolean }) {
  const shouldSeeAsymmetricGamePlay = useShouldSeeAsymmetricGamePlay();
  const { mainMedia, mainMediaData, asymmetricMedia, asymmetricMediaData } =
    useHiddenPictureCurrentPicture() ?? {};

  if (shouldSeeAsymmetricGamePlay) {
    return (
      <MainMediaView
        media={asymmetricMedia}
        mediaData={asymmetricMediaData}
        // show the cover in case there's nothing...
        hide={props.hide || (!asymmetricMedia && !asymmetricMediaData)}
      />
    );
  } else {
    return (
      <MainMediaView
        media={mainMedia}
        mediaData={mainMediaData}
        hide={props.hide}
      />
    );
  }
}

function PinContainer(props: {
  block: HiddenPictureBlock;
  clientId: string;
  clientName: string;
  teamId: string;
  inGame: boolean;
  pinType: 'pin' | 'signal';
  hideMedia: boolean;
}): JSX.Element {
  const debugHotSpots = useFeatureQueryParam('hidden-picture-show-hotspots');
  const { dropPin, dropSignal } = useHiddenPictureGamePlayAPI(
    props.clientId,
    props.teamId
  );
  const allFound = useHiddenPictureAllFound();
  const currentPicture = useHiddenPictureCurrentPicture();
  const asymmetricPinDropVisibility =
    currentPicture?.asymmetricPinDropVisibility ??
    EnumsHiddenPictureAsymmetricPinDropVisibility.HiddenPictureAsymmetricPinDropVisibilityHidden;
  const asymmetricPinDropAudibility =
    currentPicture?.asymmetricPinDropAudibility ??
    EnumsHiddenPictureAsymmetricPinDropAudibility.HiddenPictureAsymmetricPinDropAudibilityMuted;
  const shouldSeeAsymmetricGamePlay = useShouldSeeAsymmetricGamePlay();
  const [containerRef, containerRect] = useMeasure({
    polyfill: ResizeObserver,
  });

  const containerCoords = useMemo(() => {
    if (!containerRect) return undefined;
    return {
      maxX: containerRect.width,
      maxY: containerRect.height,
    };
  }, [containerRect]);

  const handleDropPin = useLiveCallback(async (x: number, y: number) => {
    if (props.pinType === 'pin') {
      await dropPin(x, y, props.clientName);
    } else {
      await dropSignal(x, y);
    }
  });

  const handleClick = useLiveCallback(
    async (e: React.MouseEvent<HTMLDivElement>) => {
      if (
        !containerRect ||
        !containerCoords ||
        !props.inGame ||
        allFound ||
        shouldSeeAsymmetricGamePlay
      )
        return;

      const x = e.clientX - containerRect.left;
      const y = e.clientY - containerRect.top;
      const normalized = HiddenPictureUtils.ToUnitGrid(
        { x, y },
        containerCoords
      );
      await handleDropPin(normalized.x, normalized.y);
    }
  );

  const windowDimensions = useWindowDimensions(50);
  const style = useMemo(() => {
    return windowDimensions.ratio > 16 / 9
      ? {
          height: '100%',
          aspectRatio: '16/9',
        }
      : {
          width: '100%',
          aspectRatio: '16/9',
        };
  }, [windowDimensions.ratio]);

  const hidePins = useMemo(() => {
    if (shouldSeeAsymmetricGamePlay)
      return (
        asymmetricPinDropVisibility ===
        EnumsHiddenPictureAsymmetricPinDropVisibility.HiddenPictureAsymmetricPinDropVisibilityHidden
      );
    return currentPicture?.pinDropHidden;
  }, [
    asymmetricPinDropVisibility,
    currentPicture?.pinDropHidden,
    shouldSeeAsymmetricGamePlay,
  ]);

  const mutePins = useMemo(() => {
    if (shouldSeeAsymmetricGamePlay) {
      return (
        asymmetricPinDropAudibility ===
        EnumsHiddenPictureAsymmetricPinDropAudibility.HiddenPictureAsymmetricPinDropAudibilityMuted
      );
    }
    return currentPicture?.pinDropMuted;
  }, [
    asymmetricPinDropAudibility,
    currentPicture?.pinDropMuted,
    shouldSeeAsymmetricGamePlay,
  ]);

  return (
    <div
      ref={containerRef}
      className={`relative isolate ${
        shouldSeeAsymmetricGamePlay ? '' : 'cursor-pointer'
      }`}
      onClick={handleClick}
      style={style}
    >
      <div className='absolute inset-0'>
        <GamePlayMedia hide={props.hideMedia} />
      </div>
      {props.inGame && (
        <>
          {debugHotSpots && <HotSpots />}
          <Pins
            silent={mutePins}
            hidden={hidePins}
            hidePointsAnimation={currentPicture?.hidePointsAnimation}
          />
          <Tool
            clientId={props.clientId}
            teamId={props.teamId}
            containerRect={containerRect}
            disabled={allFound || !props.inGame}
            onDropPin={handleDropPin}
            hidePointsAnimation={currentPicture?.hidePointsAnimation}
          />
        </>
      )}
    </div>
  );
}

function TeamCaptainWatch(props: { teamId: TeamId }): JSX.Element | null {
  const { teamId } = props;

  useHiddenPictureScorer(teamId);
  useHiddenPictureToolAssigner(teamId);

  const submissionStatusAPI = useTeamSubmissionStatusAPI();
  const emitter = useGamePlayEmitter();

  useEffect(() => {
    return emitter.once('ended', (_, state) => {
      if (state === 'finished') {
        submissionStatusAPI.markSubmitted();
      }
    });
  }, [emitter, submissionStatusAPI]);

  return null;
}

export function HiddenPictureHostView(props: {
  block: HiddenPictureBlock;
}): JSX.Element | null {
  const { block } = props;
  const { gameTimeSec } = block.fields;
  const pictures = useHiddenPictureGamePictures();
  const time = useGameSessionLocalTimer();
  const [index, setIndex] = useState(0);
  const currentPicture = useMemo(() => {
    if (index < 0 || index >= pictures.length) return undefined;
    return pictures[index];
  }, [pictures, index]);

  if (time === null || !currentPicture) return null;

  const {
    mainMedia,
    mainMediaData,
    hotSpotsV2 = [],
    sequenced,
    question,
  } = currentPicture;
  return (
    <>
      <PromptView time={time} totalTime={gameTimeSec} question={question} />
      <ContainLayout>
        <div className='flex flex-col items-center w-full'>
          <div className='w-full'>
            <div className='relative aspect-w-16 aspect-h-9 isolate overflow-hidden'>
              <div className='absolute inset-0'>
                <MainMediaView media={mainMedia} mediaData={mainMediaData} />
              </div>
              <HotSpotsView hotSpots={hotSpotsV2} sequenced={sequenced} />
            </div>
            {pictures.length > 1 && (
              <div className='w-full flex items-center justify-center gap-4'>
                <button
                  type='button'
                  className='btn btn-primary py-2 w-24'
                  onClick={() => setIndex(index - 1)}
                  disabled={index === 0}
                >
                  Previous
                </button>
                <button
                  type='button'
                  className='btn btn-primary py-2 w-24'
                  onClick={() => setIndex(index + 1)}
                  disabled={index === pictures.length - 1}
                >
                  Next
                </button>
              </div>
            )}
          </div>
        </div>
      </ContainLayout>
    </>
  );
}

export function HiddenPictureNonHost(props: {
  block: HiddenPictureBlock;
}): JSX.Element | null {
  const { block } = props;
  const time = useGameSessionLocalTimer();

  if (time === null) return null;
  return (
    <>
      <Prompt time={time} totalTime={block.fields.gameTimeSec} />
      <ContainLayout>
        <div className={`flex flex-col items-center w-full`}>
          <HiddenPicturePlayable block={block} />
        </div>
      </ContainLayout>
      <MediaPreloader media={block.fields.successAudioMedia} />
    </>
  );
}

export function HiddenPicturePlayground(props: {
  block: HiddenPictureBlock;
  isHost: boolean;
}): JSX.Element | null {
  return props.isHost ? (
    <HiddenPictureHostView block={props.block} />
  ) : (
    <HiddenPictureNonHost block={props.block} />
  );
}
