import { useEffect } from 'react';
import { useLatest } from 'react-use';

import {
  type CreativePromptBlock,
  type CreativePromptBlockDetailScore,
  CreativePromptBlockGameSessionStatus,
} from '@lp-lib/game';

import {
  useDetailScores,
  useGameSessionFBRef,
  useOverrideDetailScores,
  useSessionStatusHookManager,
} from '../../hooks';
import { type GameControlProps } from '../Common/GameControl/types';
import { uncheckedIndexAccess_UNSAFE } from '../../../../utils/uncheckedIndexAccess_UNSAFE';

type SharedProps = GameControlProps<CreativePromptBlock>;

function calPoints(
  myTeamVotesCount: number,
  totalVotesCount: number,
  pointsMultiplier: number
) {
  if (totalVotesCount === 0) return 0;
  if (myTeamVotesCount === 0) return 0;
  return (
    Math.round((myTeamVotesCount / totalVotesCount) * pointsMultiplier) + 50
  );
}

export function CreativePromptBlockGameControl(
  props: SharedProps
): JSX.Element | null {
  const { block } = props;
  const hookManager = useSessionStatusHookManager();
  const latestDetailScores = useLatest(
    useDetailScores<CreativePromptBlockDetailScore>(block.id)
  );
  const latestOverrideDetailScores = useLatest(
    useOverrideDetailScores(block.id)
  );
  const detailScoresFBRef = useGameSessionFBRef('detailScores');

  // update votes
  useEffect(() => {
    const hook = {
      blockId: block.id,
      sessionStatus: CreativePromptBlockGameSessionStatus.RESULTS,
      before: async () => {
        const detailScores = latestDetailScores.current;
        if (!detailScores) return;
        let totalVoteCount = 0;
        Object.keys(detailScores).forEach((teamId: string) => {
          totalVoteCount += detailScores[teamId].votes || 0;
        });
        const updated = uncheckedIndexAccess_UNSAFE({});
        Object.keys(detailScores).forEach((teamId: string) => {
          const scores = detailScores[teamId];
          updated[teamId] = { ...scores };

          updated[teamId].previewPoints = calPoints(
            scores.votes || 0,
            totalVoteCount,
            block.fields.pointsMultiplier
          );
        });

        await detailScoresFBRef?.child(`${block.id}`).set(updated);
      },
    };
    hookManager.register(hook);
    return () => {
      hookManager.unregister(hook);
    };
  }, [
    block.fields.pointsMultiplier,
    block.id,
    detailScoresFBRef,
    hookManager,
    latestDetailScores,
  ]);

  // update points
  useEffect(() => {
    const hook = {
      blockId: block.id,
      sessionStatus: CreativePromptBlockGameSessionStatus.POINTS_DISTRIBUTED,
      before: async () => {
        const { after: afterOverride } = latestOverrideDetailScores.current;
        await detailScoresFBRef?.child(`${block.id}`).set(afterOverride);
      },
    };
    hookManager.register(hook);
    return () => {
      hookManager.unregister(hook);
    };
  }, [block.id, detailScoresFBRef, hookManager, latestOverrideDetailScores]);

  return null;
}
