import { useEffect, useState } from 'react';

import { type IRTCService } from '../../services/webrtc';
import { type TeamMember } from '../../types/team';
import { useParticipant } from '../Player';
import { useMyClientId } from '../Venue/VenuePlaygroundProvider';
import { useRTCService } from '../WebRTC';
import { useSelectOnStageMembers } from './Context/hooks';
import { StreamView } from './StreamView';
import { StageMode } from './types';

const HeadStreamView = ({
  player,
  rtcService,
  additionalClassName,
  color = 'pink',
}: {
  player: TeamMember | null;
  rtcService: IRTCService;
  additionalClassName?: string;
  color?: 'pink' | 'blue';
}): JSX.Element => {
  const participant = useParticipant(player?.id);

  if (!player) {
    return <div className='w-1/2 h-full' />;
  }

  const tagColor = color === 'pink' ? 'bg-lp-pink-001' : 'bg-lp-blue-002';
  const borderColor = color === 'pink' ? 'border-pink-001' : 'border-blue-004';

  return (
    <div
      className={`relative w-1/2 h-full flex flex-col justify-center ${
        additionalClassName ? additionalClassName : ''
      }`}
    >
      <div className='aspect-w-1 aspect-h-1'>
        <div className='w-full h-full'>
          <StreamView
            member={player}
            className={`w-full h-full border-4 ${borderColor} rounded-5.5xl`}
            rtcService={rtcService}
          />
          <div
            className={`absolute top-0 w-2/3 m-auto max-w-72 left-0 right-0 ${tagColor} z-10 rounded`}
          >
            <p className='w-auto truncate font-cairo font-extrabold text-4xl text-center'>
              {participant?.username}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

type PlayerPair = {
  left: TeamMember | null;
  right: TeamMember | null;
};

export function HeadToHead(): JSX.Element | null {
  const myClientId = useMyClientId();
  const stageRTCService = useRTCService('stage');
  const stageMembers = useSelectOnStageMembers(StageMode.H2H, {
    myClientId,
  });

  const [pair, setPair] = useState<PlayerPair>({
    left: stageMembers[0] ?? null,
    right: stageMembers[1] ?? null,
  });

  useEffect(() => {
    if (stageMembers.length === 0) {
      setPair({ left: null, right: null });
    } else if (stageMembers.length === 1) {
      const m = stageMembers[0];
      setPair((prev) => {
        if (m.id === prev.left?.id) return { left: prev.left, right: null };
        if (m.id === prev.right?.id) return { left: null, right: prev.right };
        return { left: m, right: null };
      });
    } else if (stageMembers.length === 2) {
      setPair((prev) => {
        // 1st player -> left, 2nd player -> right
        // maintain the existing left and right players
        return prev.left?.id === stageMembers[0].id ||
          prev.right?.id === stageMembers[1].id
          ? {
              left: stageMembers[0],
              right: stageMembers[1],
            }
          : { left: stageMembers[1], right: stageMembers[0] };
      });
    }
  }, [stageMembers]);

  if (stageMembers.length === 0) return null;

  return (
    <div className='w-full h-full flex flex-row items-center'>
      <HeadStreamView
        player={pair.left}
        rtcService={stageRTCService}
        additionalClassName='transform -translate-x-2'
        color='pink'
      />
      <HeadStreamView
        player={pair.right}
        rtcService={stageRTCService}
        additionalClassName='transform translate-x-2'
        color='blue'
      />
    </div>
  );
}
