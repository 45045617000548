import isEqual from 'lodash/isEqual';

import {
  EnumsMediaScene,
  type ModelsTTSScript,
} from '@lp-lib/api-service-client/public';
import {
  type BlockFields,
  type SpotlightBlockMedia,
  type SpotlightBlockV2,
  type VoiceOver,
} from '@lp-lib/game';

import { useLiveCallback } from '../../../../hooks/useLiveCallback';
import { uuidv4 } from '../../../../utils/common';
import type { TagQuery } from '../../../../utils/TagQuery';
import { useAwaitFullScreenConfirmCancelModal } from '../../../ConfirmCancelModalContext';
import {
  defaultTTSOptions,
  TTSOptionEditor,
} from '../../../VoiceOver/TTSOptionEditor';
import {
  type ScriptScenarioOption,
  TTSScriptsEditor,
} from '../../../VoiceOver/TTSScriptsEditor';
import { VoiceOverUtils } from '../../../VoiceOver/utils';
import {
  hasLLMCodeFences,
  hasVariables,
} from '../../../VoiceOver/VariableRegistry';
import { VoiceOverEditor } from '../../../VoiceOver/VoiceOverEditor';
import { makeExampleVariableRegistrySnapshot } from '../../hooks/useCreateGameInfoSnapshot';
import {
  FieldContainer,
  FieldDescription,
  FieldPanel,
  FieldTitle,
  SimpleFieldEditor,
} from '../Common/Editor/FieldEditorUtilities';
import {
  AdditionalSettings,
  AdditionalSharedSettingsEditor,
  BlockMediaEditor,
  BlockMediaUploader,
  type EditorProps,
  PointsInput,
  RHFCheckbox,
  RHFSelectField,
  useEditor,
} from '../Common/Editor/Internal';
import {
  spotlightV2AskforVolunteers,
  spotlightV2HeadToHead,
  spotlightV2LastPlaceTeam,
  spotlightV2RandomSelection,
} from './utils';

const preSelectedTeamOrderOptions = [
  {
    label: 'None',
    value: 0,
  },
  {
    label: 'Random Selection',
    value: spotlightV2RandomSelection,
  },
  {
    label: 'Ask for volunteers',
    value: spotlightV2AskforVolunteers,
  },
  {
    label: 'Head 2 Head',
    value: spotlightV2HeadToHead,
  },
  {
    label: '1st Place Team',
    value: 1,
  },
  {
    label: '2nd Place Team',
    value: 2,
  },
  {
    label: '3rd Place Team',
    value: 3,
  },
  {
    label: 'Last Place Team',
    value: spotlightV2LastPlaceTeam,
  },
];

const exampleVariableRegistry = makeExampleVariableRegistrySnapshot();
const spotlightScriptScenarioOptions: ScriptScenarioOption[] = [
  {
    label: 'Preferred',
    description:
      'The preferred scripts to use during playback. A script is chosen at random and played.',
    tags: ['runtime'],
    supportedVariables: exampleVariableRegistry,
    templateRenderer: exampleVariableRegistry,
  },
  {
    label: 'Fallback',
    description:
      'Used in case a preferred script cannot be rendered in time. These scripts should not use variables.',
    tags: ['fallback'],
  },
];

export function SpotlightBlockV2Editor(
  props: EditorProps<SpotlightBlockV2>
): JSX.Element | null {
  const { block } = props;
  const { updateFieldLocalFirst: updateField } = useEditor(props);
  const triggerModal = useAwaitFullScreenConfirmCancelModal();

  const handleVoiceOverChange = useLiveCallback(
    (voiceOver: VoiceOver | null) => {
      updateField('voiceOver', {
        ...block.fields.voiceOver,
        ...voiceOver,
      });
    }
  );

  const handleTTSOptionsChange = useLiveCallback(
    (value: BlockFields<SpotlightBlockV2>['ttsOptions']) => {
      updateField('ttsOptions', value);
    }
  );

  const handleAddTTSScript = useLiveCallback(async (value: ModelsTTSScript) => {
    await updateField('ttsScripts', [
      ...(props.block.fields.ttsScripts ?? []),
      value,
    ]);
  });

  const handleRemoveTTSScript = useLiveCallback(
    async (value: ModelsTTSScript) => {
      const nextTTSScripts = (props.block.fields.ttsScripts ?? []).filter(
        (s) => s.id !== value.id
      );
      await updateField('ttsScripts', nextTTSScripts);
    }
  );

  const handleChangeTTSScript = useLiveCallback(
    async (prev: ModelsTTSScript, next: ModelsTTSScript) => {
      const nextTTSScripts = [...(props.block.fields.ttsScripts ?? [])];
      const index = nextTTSScripts.findIndex((s) => s.id === prev.id);
      if (index === -1) return;

      const hasChanged = !isEqual(prev, next);
      if (hasChanged) {
        // only update the field if there's a change.
        nextTTSScripts[index] = next;
        await updateField('ttsScripts', nextTTSScripts);
      }
    }
  );

  const handleUploadTTSScripts = useLiveCallback(
    async (nextTTSScripts: ModelsTTSScript[]) => {
      await updateField('ttsScripts', nextTTSScripts);
    }
  );

  const handleMigrate = useLiveCallback(async () => {
    const voiceOver = props.block.fields.voiceOver;
    if (!voiceOver) return;

    if (voiceOver.fallback?.media) {
      const hasFallbackDescription = !!voiceOver.fallback.renderDescription;
      if (hasFallbackDescription) {
        const resp = await triggerModal({
          kind: 'confirm-cancel',
          prompt: (
            <div className='p-5 text-white text-center'>
              <p className='text-2xl font-medium'>Are you sure?</p>
              <p className='mt-4 text-sms'>
                The current voice over configuration has fallback media.
                However, there is a script that was used to generate the media
                that can be ported to the new voice over settings.
              </p>
              <p className='mt-4 text-sms text-tertiary'>
                {voiceOver.fallback.renderDescription?.script}
              </p>
              <p className='mt-4 text-sms'>
                We will no longer use the media, but can copy over the script.
              </p>
            </div>
          ),
          confirmBtnLabel: 'Continue',
          cancelBtnLabel: 'Cancel',
        });
        if (resp.result === 'canceled') return;
      } else {
        await triggerModal({
          kind: 'confirm-cancel',
          prompt: (
            <div className='p-5 text-white text-center'>
              <p className='text-2xl font-medium'>Are you sure?</p>
              <p className='mt-4 text-sms'>
                The current voice over configuration has fallback media, but
                there is no script that describes how the media was generated.
                We no longer support this moving forward. Please either remove
                the media or provide a fallback script.
              </p>
            </div>
          ),
          confirmBtnLabel: 'Ok',
          confirmOnly: true,
        });
        return;
      }
    }

    const nextTTSScripts: ModelsTTSScript[] = [];
    if (voiceOver.runtime) {
      nextTTSScripts.push({
        id: uuidv4(),
        script: voiceOver.runtime.script,
        tags: ['runtime'],
      });
    }
    if (voiceOver.fallback?.renderDescription) {
      nextTTSScripts.push({
        id: uuidv4(),
        script: voiceOver.fallback.renderDescription.script,
        tags: ['fallback'],
      });
    }

    await updateField('ttsScripts', nextTTSScripts);
    await updateField('voiceOver', {});
    if (
      !props.block.fields.ttsOptions ||
      props.block.fields.ttsOptions.length === 0
    ) {
      await updateField('ttsOptions', defaultTTSOptions);
    }
  });

  const checkForFallbacks = useLiveCallback(
    (query: TagQuery<ModelsTTSScript>) => {
      const needsFallbacks = query
        .select(['runtime'])
        .some((s) => hasVariables(s.script) || hasLLMCodeFences(s.script));

      const ok = !needsFallbacks || query.select(['fallback']).length > 0;
      if (ok) return { ok };
      return {
        ok,
        message:
          'At least one of your preferred scripts uses variables. Define a fallback script to be used in case of failure.',
      };
    }
  );

  const hasLegacyVoiceOvers = VoiceOverUtils.HasAtLeastOneConfig(
    props.block.fields.voiceOver
  );

  return (
    <div className='w-full'>
      <h2 className='text-2xl text-white'>Spotlight Block V2</h2>
      <div className='pt-5 space-y-3'>
        <SimpleFieldEditor
          name='Message'
          description={
            <>
              What should be displayed to the audience when the block is
              presented. Use %Name% to dynamically input the Username(s) of the
              person/people on stage. e.g. “Happy Birthday %Name%” will show
              “Happy Birthday Ben” if user Ben is on stage.
            </>
          }
        >
          <input
            className='field h-10 mb-0'
            defaultValue={block.fields.message}
            onBlur={(e) => {
              if (e.target.value.length > 30) return;
              updateField('message', e.target.value);
            }}
            maxLength={30}
            placeholder='Max 30 characters'
          />
        </SimpleFieldEditor>
        <SimpleFieldEditor
          name='Preselect Players to Spotlight'
          description={
            <>
              Selects the players to spotlight. When set to 'None', the game
              organizer will be prompted to select a player before starting the
              game.
              {block.fields.preselectedTeamOrder ===
                spotlightV2AskforVolunteers && (
                <div className='w-full mt-1'>
                  <strong>Ask For Volunteers:</strong> 5 or more teams = 1
                  volunteer max/team, 3-4 teams = 2 volunteers max/team, 2 teams
                  = 4 volunteers max/team, 1 team = 8 volunteers max for that
                  team
                </div>
              )}
            </>
          }
        >
          <RHFSelectField<SpotlightBlockV2>
            className='w-full h-10 text-white'
            name='preselectedTeamOrder'
            options={preSelectedTeamOrderOptions}
            value={block.fields.preselectedTeamOrder}
            onChange={(_, value) => {
              if (typeof value !== 'number') return;
              updateField('preselectedTeamOrder', value);
            }}
          />
        </SimpleFieldEditor>
        <SimpleFieldEditor
          name='Voting Mode'
          description={
            block.fields.votingMode
              ? 'The host can solicit votes from Team Captains. Leave points field empty to award no points to correct votes.'
              : 'Voting disabled. Enable voting to allow Team Captains to solicit votes from Team Captains.'
          }
        >
          <div className='w-full flex items-center gap-2'>
            <div className='flex-none'>
              <RHFCheckbox<SpotlightBlockV2>
                label=''
                name='votingMode'
                value={block.fields.votingMode}
                onChange={(_, checked: boolean): void => {
                  updateField('votingMode', checked);
                }}
              />
            </div>

            <PointsInput
              className='flex-1'
              defaultValue={block.fields.votingPoints}
              max={1000}
              min={0}
              placeholder='Max 1000 points'
              nullValueToZero={true}
              onChange={(value) => {
                updateField('votingPoints', value);
              }}
              renderValue={(value) => (value === 0 ? '' : String(value))}
              disabled={!block.fields.votingMode}
            />
          </div>
        </SimpleFieldEditor>
        <SimpleFieldEditor
          name='Instant Winner Points'
          description={
            <>
              Points awarded to the team of the instant winner selected by the
              host.
            </>
          }
        >
          <PointsInput
            className='w-full'
            defaultValue={block.fields.instantWinnerPoints}
            max={1000}
            min={0}
            placeholder='Max 1000 points'
            nullValueToZero={true}
            onChange={(value) => {
              updateField('instantWinnerPoints', value);
            }}
            renderValue={(value) => (value === 0 ? '' : String(value))}
          />
        </SimpleFieldEditor>
        <SimpleFieldEditor
          name='Background Media'
          description={
            'Media will display to the audience when the block is presented.'
          }
        >
          <BlockMediaEditor<SpotlightBlockMedia>
            blockId={props.block.id}
            title={<span />}
            field='backgroundMedia'
            video={true}
            scene={EnumsMediaScene.MediaSceneBlockBackground}
            volumeSelectable
            mediaData={block.fields.backgroundMediaData}
            media={block.fields.backgroundMedia}
            width='w-full'
          />
        </SimpleFieldEditor>
        <SimpleFieldEditor
          name='Overlay Media'
          description={
            'Media will display to the audience when the block is presented.'
          }
        >
          <BlockMediaUploader<SpotlightBlockMedia>
            blockId={props.block.id}
            title={<span />}
            field='overlayMedia'
            video={true}
            scene={EnumsMediaScene.MediaSceneBlockMedia}
            media={block.fields.overlayMedia}
            extraNotice=''
            width='w-full'
          />
        </SimpleFieldEditor>
        {hasLegacyVoiceOvers ? (
          <FieldContainer styles={{ layout: 'flex flex-col gap-4' }}>
            <FieldPanel>
              <FieldTitle>Host Scripts</FieldTitle>
              <FieldDescription>
                <div className='w-full flex gap-4 justify-between items-center'>
                  <div className='text-tertiary'>
                    The current configuration of this block is using deprecated
                    voice over settings. Please migrate to the new settings.
                  </div>
                  <button
                    type='button'
                    className='w-40 h-10 btn-secondary'
                    onClick={handleMigrate}
                  >
                    Migrate
                  </button>
                </div>
              </FieldDescription>
            </FieldPanel>
            <div className='w-full'>
              <VoiceOverEditor
                id={block.id}
                voiceOver={block.fields.voiceOver}
                onChange={handleVoiceOverChange}
                bottomAccessory={
                  <div className='text-icon-gray text-sms'>
                    Voice over audio. If uploaded, audio will start immediately
                    with the background media.
                  </div>
                }
                previewMedia={block.fields.backgroundMedia}
                previewLabel='Background Media'
              />
            </div>
          </FieldContainer>
        ) : (
          <TTSScriptsEditor
            title='Host Scripts'
            description={
              <>
                When specified, the AI host will read one of these scripts when
                the spotlight is presented.
              </>
            }
            value={props.block.fields.ttsScripts}
            scenarioOptions={spotlightScriptScenarioOptions}
            onAdd={handleAddTTSScript}
            onRemove={handleRemoveTTSScript}
            onChange={handleChangeTTSScript}
            onUpload={handleUploadTTSScripts}
            ttsRenderSettings={props.block.fields.ttsOptions?.[0]}
            csvDownloadName={`spotlight-v2-host-scripts-${props.block.id}.csv`}
            checkForFallbacks={checkForFallbacks}
          />
        )}
      </div>
      <AdditionalSettings>
        <AdditionalSharedSettingsEditor {...props} />
        <TTSOptionEditor
          value={props.block.fields.ttsOptions}
          onChange={handleTTSOptionsChange}
        />
      </AdditionalSettings>
    </div>
  );
}
