export const OndIcon = (props: { className?: string }): JSX.Element => {
  return (
    <svg
      className={props.className ?? `w-3 h-3 fill-current`}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g filter='url(#filter0_d_4072_331983)'>
        <path
          fill='#EE3529'
          d='M21.285 12a9.286 9.286 0 11-18.571 0 9.286 9.286 0 0118.571 0z'
        ></path>
        <path
          fill='#fff'
          d='M9.862 12.09c-.104.615-.308 1.135-.614 1.559a3.015 3.015 0 01-2.505 1.286c-.527 0-.982-.117-1.367-.351a2.016 2.016 0 01-.84-1.024c-.173-.449-.207-.993-.1-1.633.1-.615.302-1.136.605-1.562a3.037 3.037 0 012.525-1.3c.523 0 .976.118 1.359.354.385.236.664.58.837 1.03.173.45.206.997.1 1.64zm-1.38-.163c.057-.359.057-.66 0-.906s-.166-.431-.33-.556c-.164-.126-.377-.19-.639-.19-.29 0-.552.072-.787.216a1.69 1.69 0 00-.583.619c-.156.27-.265.596-.326.98-.064.362-.066.664-.006.906.061.242.176.424.343.547.168.12.38.181.64.181.287 0 .547-.07.778-.209.23-.14.423-.343.577-.611.156-.268.267-.593.332-.977zM11.596 12.413l-.408 2.444H9.824l.714-4.286h1.297l-.128.787h.048c.134-.262.328-.468.583-.616.255-.151.541-.226.86-.226.299 0 .549.067.75.2.2.134.343.322.427.564.083.24.097.522.041.846l-.457 2.731h-1.364l.41-2.464c.04-.236.012-.42-.087-.555-.097-.136-.255-.204-.474-.204a.826.826 0 00-.69.36 1.052 1.052 0 00-.158.419zM16.545 14.857h-2.143l.949-5.714h2.079c.58 0 1.064.118 1.45.354.39.235.664.571.824 1.01.162.437.192.958.092 1.563-.097.584-.291 1.084-.583 1.5-.29.418-.663.736-1.116.958-.454.22-.971.33-1.552.33zm-.569-1.177h.656c.32 0 .602-.058.848-.173.247-.117.452-.304.614-.561.162-.259.277-.598.346-1.019.067-.401.064-.718-.009-.951a.783.783 0 00-.413-.502c-.202-.103-.467-.154-.795-.154h-.686l-.561 3.36z'
        ></path>
      </g>
      <defs>
        <filter
          id='filter0_d_4072_331983'
          width='22.571'
          height='22.571'
          x='0.714'
          y='0.714'
          colorInterpolationFilters='sRGB'
          filterUnits='userSpaceOnUse'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix'></feFlood>
          <feColorMatrix
            in='SourceAlpha'
            result='hardAlpha'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
          ></feColorMatrix>
          <feOffset></feOffset>
          <feGaussianBlur stdDeviation='1'></feGaussianBlur>
          <feComposite in2='hardAlpha' operator='out'></feComposite>
          <feColorMatrix values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0'></feColorMatrix>
          <feBlend
            in2='BackgroundImageFix'
            result='effect1_dropShadow_4072_331983'
          ></feBlend>
          <feBlend
            in='SourceGraphic'
            in2='effect1_dropShadow_4072_331983'
            result='shape'
          ></feBlend>
        </filter>
      </defs>
    </svg>
  );
};
