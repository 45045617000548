import { type SDK_CODEC } from 'agora-rtc-sdk-ng';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import {
  getFeatureQueryParam,
  getFeatureQueryParamArray,
  useFeatureQueryParam,
} from '../../../hooks/useFeatureQueryParam';
import { useInstance } from '../../../hooks/useInstance';
import { useLiveCallback } from '../../../hooks/useLiveCallback';
import { useTaskQueue } from '../../../hooks/useTaskQueue';
import {
  CameraVMMediaDeviceRTCService,
  CustomRTCService,
  type CustomRTCServiceOptions,
  type ICustomRTCService,
  type IMediaDeviceRTCService,
  MediaDeviceRTCService,
  type MediaDeviceRTCServiceOptions,
  profileForCustomVideo,
} from '../../../services/webrtc';
import {
  DummyCustomRTCService,
  DummyMediaDeviceRTCService,
} from '../../../services/webrtc/dummy';
import { err2s, sleep } from '../../../utils/common';
import { getDeploymentAssetPath } from '../../../utils/getDeploymentAssetPath';
import {
  getAlternativeDeviceOption,
  MediaKind,
  useCloneSingletonMediaStream,
  useDeviceAPI,
  useDeviceState,
} from '../../Device';
import { type MixMode } from '../../Device/video-stream-mixer';
import { useUserStates } from '../../UserContext';
import { type RTCServiceMap } from '../types';

interface ExtraOptions {
  disableVideo?: boolean;
  creator?: (options: MediaDeviceRTCServiceOptions) => IMediaDeviceRTCService;
}

function useSwitchVideo(
  rtcService: IMediaDeviceRTCService,
  enabled: boolean,
  mixMode: MixMode
) {
  const { addTask } = useTaskQueue({ shouldProcess: true });

  // not mix anything, raw camera stream
  const { activeVideoInputDeviceOption } = useDeviceState();
  useEffect(() => {
    if (!enabled || mixMode !== 'none') return;
    addTask(async () => {
      if (!activeVideoInputDeviceOption) return;
      try {
        const alternativeDeviceOption = await getAlternativeDeviceOption(
          activeVideoInputDeviceOption,
          MediaKind.VideoInput
        );
        await rtcService.switchVideo(alternativeDeviceOption.value);
      } catch (error) {
        rtcService.log.error('switch video error', error);
      }
    });
  }, [activeVideoInputDeviceOption, rtcService, addTask, enabled, mixMode]);

  // mixed either with virtual background or video effects
  const videoMediaStream = useCloneSingletonMediaStream();
  const lastUsedTrack = useRef<MediaStreamTrack | null>(null);
  const seq = useRef(0);

  // Note(Jialin): It seems there is a race condition of setting encoder
  // configuration right after switching video track. I tried to check
  // if _track.enabled_ or _track.readyState_ is not correct, but it
  // doesn't seem to be the problem. So I added a simple delay & retry
  // mechanism here. From testing, we don't need backoff strategy here.
  const updateEncoderConfiguration = useCallback(
    async (maxAttempts = 5) => {
      seq.current += 1;
      const mySeq = seq.current;
      let n = maxAttempts;
      while (n > 0) {
        if (mySeq !== seq.current) {
          rtcService.log.info('updateEncoderConfiguration cancelled', {
            mySeq,
            currSeq: seq.current,
          });
          return;
        }
        try {
          rtcService.log.info(
            `setEncoderConfiguration attempts: ${maxAttempts - n + 1}`,
            { seq: mySeq }
          );
          await rtcService.setEncoderConfiguration('default', {
            suppressError: false,
          });
          break;
        } catch (error) {
          rtcService.log.error('setEncoderConfiguration error', err2s(error), {
            seq: mySeq,
          });
          n = n - 1;
        }
        await sleep(200);
      }
    },
    [rtcService]
  );

  useEffect(() => {
    if (!enabled || mixMode === 'none') return;
    addTask(async () => {
      const track = videoMediaStream?.getVideoTracks()[0];
      if (!track || lastUsedTrack.current === track) return;
      try {
        await rtcService.switchVideo(track);
        lastUsedTrack.current = track;
        updateEncoderConfiguration();
      } catch (error) {
        rtcService.log.error('switch video error', error);
      }
    });
  }, [
    rtcService,
    videoMediaStream,
    addTask,
    enabled,
    mixMode,
    updateEncoderConfiguration,
  ]);
}

function useMediaDeviceRTCService(
  options: MediaDeviceRTCServiceOptions & ExtraOptions
): IMediaDeviceRTCService {
  const rtcServiceRef = useRef<{
    instance: IMediaDeviceRTCService;
  }>();

  const createInstance = useLiveCallback(() => {
    if (options.creator) {
      return options.creator(options);
    }
    return new MediaDeviceRTCService(options);
  });

  if (!rtcServiceRef.current) {
    rtcServiceRef.current = { instance: createInstance() };
  } else {
    if (rtcServiceRef.current.instance.uid !== options.uid) {
      rtcServiceRef.current.instance.close();
      rtcServiceRef.current.instance = createInstance();
    }
  }
  const rtcService = rtcServiceRef.current.instance;
  const { activeAudioInputDeviceOption, activeAudioOutputDeviceOption } =
    useDeviceState();
  const mixer = useDeviceAPI().mixer;
  const mixMode = mixer.mixMode;

  const { audio, video, joined, micOpen } = useUserStates();

  // use a local copy so we can control the reacting order of toggle/switch audio/video
  const [audioOn, setAudioOn] = useState(audio);
  const [videoOn, setVideoOn] = useState(video);

  useEffect(() => {
    if (!joined || !audioOn) return;
    const run = async (): Promise<void> => {
      if (!activeAudioInputDeviceOption) return;
      try {
        const alternativeDeviceOption = await getAlternativeDeviceOption(
          activeAudioInputDeviceOption,
          MediaKind.AudioInput
        );
        await rtcService.switchAudioInput(alternativeDeviceOption.value);
      } catch (error) {
        rtcService.log.error('switch video error', error);
      }
    };
    run();
  }, [activeAudioInputDeviceOption, joined, audioOn, rtcService]);

  useSwitchVideo(
    rtcService,
    joined && videoOn && !options.disableVideo,
    mixMode
  );

  useEffect(() => {
    if (!joined || !audioOn || !activeAudioOutputDeviceOption) return;
    rtcService
      .switchAudioOuptut(activeAudioOutputDeviceOption.value)
      .catch((error) =>
        rtcService.log.error('switch audio ouput error', error)
      );
  }, [activeAudioOutputDeviceOption, audioOn, joined, rtcService]);

  useEffect(() => {
    if (options?.disableVideo) return;
    rtcService
      .toggleVideo(video)
      .catch((error) => rtcService.log.error('toggle video error', error))
      .finally(() => setVideoOn(video));
  }, [options?.disableVideo, rtcService, video]);

  useEffect(() => {
    rtcService
      .toggleAudio(audio)
      .catch((error) => rtcService.log.error('toggle audio error', error))
      .finally(() => setAudioOn(audio));
  }, [audio, rtcService]);

  useEffect(() => {
    rtcService.muteAudio(!micOpen);
  }, [micOpen, rtcService]);

  return rtcService;
}

function useCustomRTCService(
  options: CustomRTCServiceOptions
): ICustomRTCService {
  const rtcServiceRef = useRef<{
    instance: ICustomRTCService;
  }>();

  if (!rtcServiceRef.current) {
    rtcServiceRef.current = { instance: new CustomRTCService(options) };
  } else {
    if (rtcServiceRef.current.instance.uid !== options.uid) {
      rtcServiceRef.current.instance.close();
      rtcServiceRef.current.instance = new CustomRTCService(options);
    }
  }

  return rtcServiceRef.current.instance;
}

const codec = getFeatureQueryParamArray('stream-codec');
const useDualStream = getFeatureQueryParam('stream-use-dual-stream');

function adaptCodec(configured: typeof codec, adaptive: SDK_CODEC): SDK_CODEC {
  if (configured !== 'adaptive') return configured;
  return adaptive;
}

// Both host and audience (as an organizer) are the publisher of the agora channels.
// We share configurations here.
function useSharedRTCServiceMap(
  clientId: string
): Omit<RTCServiceMap, 'audience' | 'stage'> {
  const gamePlayVideoProfile = useInstance(() =>
    profileForCustomVideo(getFeatureQueryParamArray('game-play-video-profile'))
  );
  const ondVideoProfile = useInstance(() =>
    profileForCustomVideo(
      getFeatureQueryParamArray('game-on-demand-host-video-profile')
    )
  );
  return {
    broadcast: useMediaDeviceRTCService(
      useMemo(
        () => ({
          uid: clientId,
          disableVideo: true,
          codec: adaptCodec(codec, 'vp8'),
          name: 'broadcast',
          useDualStream: false,
        }),
        [clientId]
      )
    ),
    game: useCustomRTCService(
      useMemo(
        () => ({
          name: 'game',
          uid: clientId,
          audioEncoderConfig: 'music_standard',
          videoEncoderConfig: {
            optimizationMode: gamePlayVideoProfile.optimizationMode,
            bitrateMin: gamePlayVideoProfile.bitrateMin,
            bitrateMax: gamePlayVideoProfile.bitrateMax,
          },
          videoEncoderLowQualityConfig: '540p',
          codec: adaptCodec(codec, 'h264'),
          useDualStream,
        }),
        [clientId, gamePlayVideoProfile]
      )
    ),
    music: useCustomRTCService(
      useMemo(
        () => ({
          name: 'music',
          uid: clientId,
          audioEncoderConfig: 'high_quality',
          codec: adaptCodec(codec, 'vp8'),
          useDualStream: false,
        }),
        [clientId]
      )
    ),
    ond: useCustomRTCService(
      useMemo(
        () => ({
          name: 'ond',
          uid: clientId,
          audioEncoderConfig: 'music_standard',
          videoEncoderConfig: {
            optimizationMode: ondVideoProfile.optimizationMode,
            bitrateMin: ondVideoProfile.bitrateMin,
            bitrateMax: ondVideoProfile.bitrateMax,
          },
          codec: adaptCodec(codec, 'h264'),
          useDualStream,
        }),
        [ondVideoProfile, clientId]
      )
    ),
  };
}

export function useHostRTCServiceMap(clientId: string): RTCServiceMap {
  const sharedRTCServices = useSharedRTCServiceMap(clientId);
  const videoProfile = useInstance(() =>
    getFeatureQueryParamArray('host-video-profile')
  );
  const videoLowQualityProfile = useInstance(() =>
    getFeatureQueryParamArray('host-stream-low-quality-profile')
  );

  return {
    stage: useMediaDeviceRTCService(
      useMemo(
        () => ({
          name: 'stage',
          uid: clientId,
          videoEncoderConfig: videoProfile,
          videoEncoderLowQualityConfig: videoLowQualityProfile,
          micEncoderConfig: 'high_quality',
          micVolumeMeterEnabled: getFeatureQueryParam(
            'stage-stream-microphone-meter'
          ),
          audioBusOptions: {
            broadcastQuality: {
              bitrate: 128,
              stereo: true,
              sampleRate: 48000,
            },
            initialMode: getFeatureQueryParam('host-audio-bus-processing')
              ? 'process'
              : 'passthrough',
            initialEffects: { musicSidechain: true },
          },
          micDeviceSettings: {
            autoGainControl: false,
            noiseSuppression: false,
          },
          codec: adaptCodec(codec, 'vp8'),
          useAudioVolumeDetector: true,
          useDualStream,
          creator: (options) => new CameraVMMediaDeviceRTCService(options),
        }),
        [clientId, videoLowQualityProfile, videoProfile]
      )
    ),
    ...sharedRTCServices,
  };
}

export function useAudienceRTCServiceMap(clientId: string): RTCServiceMap {
  const sharedRTCServices = useSharedRTCServiceMap(clientId);
  const teamAudioDenoiserEnabled = useFeatureQueryParam('team-audio-denoiser');

  return {
    stage: useMediaDeviceRTCService(
      useMemo(
        () => ({
          name: 'stage',
          uid: clientId,
          videoEncoderConfig: '240p',
          micVolumeMeterEnabled: getFeatureQueryParam(
            'stage-stream-microphone-meter'
          ),
          codec: adaptCodec(codec, 'vp8'),
          useAudioVolumeDetector: true,
          useDualStream,
        }),
        [clientId]
      )
    ),
    audience: useMediaDeviceRTCService(
      useMemo(
        () => ({
          name: 'team',
          uid: clientId,
          videoEncoderConfig: '240p',
          codec: adaptCodec(codec, 'vp8'),
          useVideoRecovery: true,
          useAudioVolumeDetector: true,
          useDualStream,
        }),
        [clientId]
      )
    ),
    audienceV2: useMediaDeviceRTCService(
      useMemo(
        () => ({
          name: 'teamV2',
          uid: clientId,
          videoEncoderConfig: '240p',
          codec: adaptCodec(codec, 'vp8'),
          useVideoRecovery: true,
          useAudioVolumeDetector: true,
          useDualStream,
          autoSubscribe: {
            audio: false,
            video: false,
          },
          denoiser: {
            enabled: teamAudioDenoiserEnabled,
            assetsPath: getDeploymentAssetPath('models/denosier'),
          },
        }),
        [clientId, teamAudioDenoiserEnabled]
      )
    ),
    ...sharedRTCServices,
  };
}

export function useDummyHostRTCServiceMap(clientId: string): RTCServiceMap {
  return useMemo(() => {
    return {
      stage: new DummyMediaDeviceRTCService({ uid: clientId, name: 'stage' }),
      broadcast: new DummyMediaDeviceRTCService({
        uid: clientId,
        name: 'broadcast',
      }),
      game: new DummyCustomRTCService({ uid: clientId, name: 'game' }),
      music: new DummyCustomRTCService({ uid: clientId, name: 'music' }),
      ond: new DummyCustomRTCService({ uid: clientId, name: 'ond' }),
    };
  }, [clientId]);
}

export function useDummyAudienceRTCServiceMap(clientId: string): RTCServiceMap {
  return useMemo(() => {
    return {
      stage: new DummyMediaDeviceRTCService({ uid: clientId, name: 'stage' }),
      broadcast: new DummyMediaDeviceRTCService({
        uid: clientId,
        name: 'broadcast',
      }),
      audience: new DummyMediaDeviceRTCService({
        uid: clientId,
        name: 'audience',
      }),
      game: new DummyCustomRTCService({ uid: clientId, name: 'game' }),
      music: new DummyCustomRTCService({ uid: clientId, name: 'music' }),
      ond: new DummyCustomRTCService({ uid: clientId, name: 'ond' }),
    };
  }, [clientId]);
}
