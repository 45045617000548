import { type BoundingBoxSettings } from '@lp-lib/game';

import { type VirtualBackgroundEffects } from '../../services/webrtc/virtual-background';
import { type VideoEffectsSettings } from '../VideoEffectsSettings/types';
import { VideoEffectsSettingsUtils } from '../VideoEffectsSettings/VideoEffectsSettingsUtils';
import { type CohostNamedPosition } from './types';

export function defaultCohostVideoEffectsSettings(
  position: CohostNamedPosition
): VideoEffectsSettings {
  const ves = VideoEffectsSettingsUtils.FromUser({
    greenScreen: {
      enabled: true,
      sharpPrefixedKeyColor: '#00ff00',
      similarity: 400,
      smoothness: 80,
      spill: 1,
      maskPct: {
        left: 0,
        top: 0,
        right: 0,
        bottom: 0,
      },
    },
    boundingBox: cohostBoundingBoxFor(position),
    podium: { enabled: false, id: null },
    stage: {
      enabled: true,
      id: 'stage-016',
    },
    intro: { enabled: false, id: null },
    outro: { enabled: false, id: null },
  });
  if (!ves) throw new Error('Failed to create default cohost VES');
  ves.podium = cohostPodiumFor(position);
  ves.podiumBoundingBox = cohostPodiumBoundingBoxFor(position);
  return ves;
}

export function cohostVideoEffectsSettingsFor(
  position: CohostNamedPosition
): Partial<VideoEffectsSettings> {
  return {
    boundingBox: cohostBoundingBoxFor(position),
    podium: cohostPodiumFor(position),
    podiumBoundingBox: cohostPodiumBoundingBoxFor(position),
  };
}

export function cohostBoundingBoxFor(
  position: CohostNamedPosition
): BoundingBoxSettings {
  switch (position) {
    case 'fullscreen-solo':
      return {
        box: {
          width: 0.42,
          height: 0.45,
          x: 0.289,
          y: 0.407,
        },
        fit: 'contain' as const,
      };
    case 'fullscreen-interview':
      return {
        box: {
          width: 0.42,
          height: 0.45,
          x: 0.1,
          y: 0.407,
        },
        fit: 'contain' as const,
      };
    case 'default':
    case 'center':
    default:
      return {
        box: {
          width: 1,
          height: 1,
          x: 0,
          y: 0,
        },
        fit: 'contain' as const,
      };
  }
}

export function cohostPodiumBoundingBoxFor(
  position: CohostNamedPosition
): BoundingBoxSettings | null {
  switch (position) {
    case 'fullscreen-interview':
      return {
        box: {
          width: 1,
          height: 1,
          x: -0.19,
          y: 0,
        },
        fit: 'contain' as const,
      };
    case 'fullscreen-solo':
      return {
        box: {
          width: 1,
          height: 1,
          x: 0,
          y: 0,
        },
        fit: 'contain' as const,
      };
    case 'default':
    case 'center':
    default:
      return null;
  }
}

export function cohostPodiumFor(
  position: CohostNamedPosition
): VideoEffectsSettings['podium'] {
  switch (position) {
    case 'fullscreen-interview':
    case 'fullscreen-solo':
      return VideoEffectsSettingsUtils.PodiumFromUser({
        enabled: true,
        id: 'podium-010',
      });
    case 'default':
    case 'center':
    default:
      return VideoEffectsSettingsUtils.PodiumFromUser({
        enabled: false,
        id: null,
      });
  }
}

export function isCohostFullscreenPosition(position: CohostNamedPosition) {
  return position === 'fullscreen-solo' || position === 'fullscreen-interview';
}

export function getCohostPositions(
  mixerEnabled: boolean
): readonly CohostNamedPosition[] {
  if (mixerEnabled) {
    return [
      'default',
      'center',
      'fullscreen-solo',
      'fullscreen-interview',
    ] as const;
  }
  return ['default', 'center'] as const;
}

export function defaultCohostVBGSettings(): VirtualBackgroundEffects {
  return {
    type: 'color',
    color: '#00ff00',
  };
}
