const ArrowIcon = (): JSX.Element => {
  return (
    <svg
      width='15'
      height='10'
      viewBox='0 0 15 10'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M0.123776 0.818936C0.287259 0.438349 0.728314 0.262352 1.1089 0.425835L12.576 5.3516L11.654 2.92413C11.5069 2.53691 11.7016 2.10377 12.0888 1.95669C12.476 1.80961 12.9091 2.00428 13.0562 2.3915L14.6064 6.47252C14.7535 6.85974 14.5588 7.29288 14.1716 7.43996L10.0905 8.99011C9.70332 9.13719 9.27018 8.94252 9.1231 8.5553C8.97601 8.16808 9.17068 7.73495 9.5579 7.58786L11.9055 6.69613L0.516877 1.80406C0.13629 1.64058 -0.039707 1.19952 0.123776 0.818936Z'
        fill='white'
      />
    </svg>
  );
};

export { ArrowIcon };
