import { useState } from 'react';

import {
  assertExhaustive,
  type IcebreakerBlock,
  IcebreakerBlockGameSessionStatus,
  IcebreakerSelectNextStrategy,
} from '@lp-lib/game';

import { PlayIcon } from '../../../icons/PlayIcon';
import { RefreshIcon } from '../../../icons/RefreshIcon';
import { FilledSquareIcon } from '../../../icons/SquareIcon';
import { TimerIcon } from '../../../icons/TimerIcon';
import { useGameSessionStatus, useIsEndedBlock } from '../../hooks';
import { next, present, triggerBlockTitleAnim } from '../../store';
import {
  BlockControllerActionButton,
  ControllerLayout,
  type ControllerProps,
  useBlockControllerBlockTitlePlaying,
} from '../Common/Controller/Internal';
import { useStableBlock } from '../Common/hooks';
import {
  useIcebreakerCards,
  useIcebreakerProgress,
  useIcebreakerSharedAPI,
} from './IcebreakerBlockProvider';
import { IcebreakerUtils } from './utils';

type SharedProps = ControllerProps<IcebreakerBlock>;

function BlockDetails(props: SharedProps): JSX.Element {
  return (
    <div className='w-full flex flex-col p-4'>
      <div>{props.selectedBlock.fields.title}</div>
      <div className='h-4'></div>
      <div>{props.selectedBlock.fields.gameName}</div>
      <div>{props.selectedBlock.fields.cards.length} Cards in Deck</div>
    </div>
  );
}

function GameProgress(
  props: SharedProps & {
    playing?: boolean;
  }
): JSX.Element {
  const api = useIcebreakerSharedAPI();

  const cards = useIcebreakerCards();
  const progress = useIcebreakerProgress();

  const selectPlayer = IcebreakerUtils.ShouldSelectOnStagePlayer(
    props.selectedBlock
  );

  return (
    <div className='w-full flex flex-col p-4'>
      <div>{props.selectedBlock.fields.title}</div>
      <div className='h-4'></div>
      <div>{props.selectedBlock.fields.gameName}</div>
      <div>
        Deck: {Object.keys(cards).length - progress.currentCardIndex - 1}
        remaining / Total {Object.keys(cards).length} cards
      </div>

      {props.playing && (
        <div className='mt-5 w-full flex flex-col gap-2.5'>
          {selectPlayer && (
            <button
              type='button'
              onClick={() => {
                const uid = api.pickNextPlayerUid(
                  props.selectedBlock.fields.onStageSelection,
                  IcebreakerSelectNextStrategy.Default
                );
                api.nextPlayer(uid);
              }}
              className='btn-secondary px-5 h-10 text-sms flex  items-center gap-2'
            >
              <RefreshIcon className='w-4 h-4 fill-current' />
              New Volunteer
            </button>
          )}

          {selectPlayer && (
            <button
              type='button'
              onClick={() => api.toggleNextPlayerPicker(true)}
              className='btn-secondary px-5 h-10 text-sms flex  items-center gap-2'
            >
              <RefreshIcon className='w-4 h-4 fill-current' />
              Choose Volunteer
            </button>
          )}

          <button
            type='button'
            onClick={() => {
              api.nextCard();
            }}
            className='btn-secondary px-5 h-10 text-sms flex  items-center gap-2'
          >
            <RefreshIcon className='w-4 h-4 fill-current' />
            New Card
          </button>

          {selectPlayer && (
            <button
              type='button'
              onClick={() => {
                const uid = api.pickNextPlayerUid(
                  props.selectedBlock.fields.onStageSelection,
                  IcebreakerSelectNextStrategy.Default
                );
                api.nextCardAndPlayer(uid);
              }}
              className='btn-secondary px-5 h-10 text-sms flex items-center gap-2'
            >
              <RefreshIcon className='w-4 h-4 fill-current' />
              New Volunteer & Card
            </button>
          )}
        </div>
      )}
    </div>
  );
}

function Ended(props: SharedProps): JSX.Element {
  return (
    <ControllerLayout
      action={
        <BlockControllerActionButton
          onClick={() => props.setShowResetConfirmation(true)}
          icon={RefreshIcon}
          isDelete={true}
        >
          Reset Completed Block
        </BlockControllerActionButton>
      }
    ></ControllerLayout>
  );
}

function Loaded(props: SharedProps): JSX.Element {
  const { selectedBlock: block } = props;

  const [processing, setProcessing] = useState(false);

  const onPresent = async () => {
    setProcessing(true);
    await triggerBlockTitleAnim(block);
    await present(block);
  };

  const actionComponent = useBlockControllerBlockTitlePlaying(block) ?? (
    <BlockControllerActionButton
      onClick={onPresent}
      icon={PlayIcon}
      disable={processing || block.fields.cards.length === 0}
    >
      {block.fields.cards.length === 0 ? 'No Cards' : 'Present Block'}
    </BlockControllerActionButton>
  );
  return (
    <ControllerLayout action={actionComponent}>
      <div className='w-full flex flex-col gap-6'>
        <BlockDetails {...props} />
      </div>
    </ControllerLayout>
  );
}

function GameInit(props: SharedProps): JSX.Element {
  // TODO: may auto start?
  const [processing, setProcessing] = useState(false);
  const handleStartGame = async () => {
    setProcessing(true);
    next();
  };

  return (
    <ControllerLayout
      action={
        <BlockControllerActionButton
          onClick={handleStartGame}
          icon={TimerIcon}
          disable={processing}
        >
          Start Game
        </BlockControllerActionButton>
      }
    >
      <div className='w-full flex flex-col gap-6'>
        <GameProgress {...props} />
      </div>
    </ControllerLayout>
  );
}

function GameStart(props: SharedProps): JSX.Element {
  const [processing, setProcessing] = useState(false);
  const handleEndGame = async () => {
    setProcessing(true);
    next();
  };

  return (
    <ControllerLayout
      action={
        <BlockControllerActionButton
          onClick={handleEndGame}
          icon={FilledSquareIcon}
          disable={processing}
        >
          End Game
        </BlockControllerActionButton>
      }
    >
      <GameProgress {...props} playing />
    </ControllerLayout>
  );
}

function Results(props: SharedProps): JSX.Element {
  return (
    <ControllerLayout
      action={
        <BlockControllerActionButton
          onClick={props.onEndBlock}
          icon={FilledSquareIcon}
        >
          End Block Sequence
        </BlockControllerActionButton>
      }
    >
      <GameProgress {...props} />
    </ControllerLayout>
  );
}

export function IcebreakerBlockController(
  props: SharedProps
): JSX.Element | null {
  const { selectedBlockIndex } = props;
  const block = useStableBlock(props.selectedBlock);
  const isEndedBlock = useIsEndedBlock(block.id);
  const gameSessionStatus =
    useGameSessionStatus<IcebreakerBlockGameSessionStatus>();

  if (selectedBlockIndex === null) return null;

  if (isEndedBlock) {
    return <Ended {...props} />;
  }

  switch (gameSessionStatus) {
    case IcebreakerBlockGameSessionStatus.LOADED:
      return <Loaded {...props} />;
    case IcebreakerBlockGameSessionStatus.GAME_INIT:
      return <GameInit {...props} />;
    case IcebreakerBlockGameSessionStatus.GAME_START:
      return <GameStart {...props} />;
    case IcebreakerBlockGameSessionStatus.RESULTS:
      return <Results {...props} />;
    case IcebreakerBlockGameSessionStatus.END:
    case null:
    case undefined:
      return null;
    default:
      assertExhaustive(gameSessionStatus);
      return null;
  }
}
