import AgoraRTC, {
  type CustomAudioTrackInitConfig,
  type ILocalAudioTrack,
} from 'agora-rtc-sdk-ng';
import {
  Compressor,
  connect,
  Gain,
  gainToDb,
  getContext,
  Meter,
  Time,
} from 'tone';

import { assertExhaustive } from '../../utils/common';
import { Emitter } from '../../utils/emitter';
import { releaseMediaStream } from '../../utils/media';
import {
  getAudioContext,
  getEchoCancelledAudioDestination,
} from './audio-context';

type MediaStreamAudio = {
  stream: MediaStream;
  source: MediaStreamAudioSourceNode;
  gain: Gain;
};

function msaFromTrack(track: MediaStreamTrack): MediaStreamAudio {
  const ctx = getAudioContext();
  const stream = new MediaStream([track.clone()]);
  const source = ctx.createMediaStreamSource(stream);
  const gain = new Gain();
  return { source, stream, gain };
}

function disposeMsa(msa: MediaStreamAudio) {
  releaseMediaStream(msa.stream);
  msa.gain.dispose();
  msa.source.disconnect();
}

function convertMeterValueToDbNumber(val: number | number[]) {
  if (Array.isArray(val)) {
    let total = 0;

    for (let i = 0; i < val.length; i++) {
      const chanLevel = val[i];
      total += chanLevel === -Infinity ? -100 : chanLevel;
    }

    return val.length ? total / val.length : 0;
  } else {
    const ret = val === -Infinity ? -100 : val;
    return ret;
  }
}

class MusicAudioPipeline {
  private msa: MediaStreamAudio;
  private transportScheduleRef = 0;
  private musicTrackMeter: Meter;

  constructor(
    inputTrack: MediaStreamTrack,
    output: Gain,
    private micPipeline: MicAudioPipeline | null
  ) {
    const ctx = getContext();
    const transport = ctx.transport;
    if (transport.state !== 'started') {
      transport.start();
    }

    const msa = msaFromTrack(inputTrack);
    const meter = new Meter();

    connect(msa.source, msa.gain);
    connect(msa.gain, meter);
    connect(msa.gain, output);

    this.msa = msa;
    this.musicTrackMeter = meter;
  }

  setSidechainControlSignal(micPipeline: MicAudioPipeline | null) {
    const ctx = getContext();
    const transport = ctx.transport;

    if (this.micPipeline) {
      // detatch existing
      transport.clear(this.transportScheduleRef);
      this.micPipeline = null;
    }

    if (micPipeline) {
      // setup sidechain linkage logic
      this.transportScheduleRef = transport.scheduleRepeat((time) => {
        if (!micPipeline) return;

        const { threshold, reductionRatio, makeupGain } = micPipeline;

        const signalRatio = micPipeline.signalLevelDb / threshold;

        // Only adjust music gain if signal is above this.
        const signalGainThreshold = 0;

        const asGain =
          1 -
          (signalRatio > signalGainThreshold
            ? signalRatio * reductionRatio
            : 0);
        // Math.max to prevent negative gain
        const total = Math.max(asGain + makeupGain, 0);
        this.msa.gain.gain.setValueAtTime(total, time);
      }, Time(256 / ctx.sampleRate));
    }

    this.micPipeline = micPipeline;
  }

  get reductionLevelDb() {
    const gain = this.msa.gain.gain.value;
    const db = gainToDb(gain === 0 ? 0.00001 : gain);
    return db;
  }

  get musicLevelDb(): number {
    const val = this.musicTrackMeter.getValue();
    return convertMeterValueToDbNumber(val);
  }

  dispose() {
    disposeMsa(this.msa);
    const ctx = getContext();
    const transport = ctx.transport;
    transport.clear(this.transportScheduleRef);
  }
}

class MicAudioPipeline {
  private msa: MediaStreamAudio;
  private measure: Compressor | null = null;
  private meter: Meter | null = null;
  private pump: Gain | null = null;
  readonly threshold: number = -100;
  // TODO: this should be computed based on the delta / ratio between music loudness and mic loudness
  makeupGain = 0.5;
  // TODO: computed based on the delta/ ratio between music loudness and mic loudness
  reductionRatio = 1;

  constructor(
    inputTrack: MediaStreamTrack,
    output: Gain,
    effectsConfig: AudioBusEffectConfiguration
  ) {
    const msa = msaFromTrack(inputTrack);
    this.msa = msa;

    // TODO: use this instead of the compressor for sidechain. Will need to confirm/invert ratios.
    this.meter = new Meter(0.8);
    connect(msa.gain, this.meter);

    if (effectsConfig.musicSidechain) {
      // measure the mic level using a compressor
      // TODO: it might be easier to use Meter/DCMeter
      const measure = new Compressor();
      measure.knee.value = 0;
      measure.attack.value = 0.001;
      measure.release.value = 0.001;
      measure.threshold.value = this.threshold;
      measure.ratio.value = 20;

      // Chrome will not process disjoint nodes that have no destination.
      const pump = new Gain(0);
      measure.connect(pump);
      pump.toDestination();

      connect(msa.source, measure);

      this.measure = measure;
      this.pump = pump;
    }

    // TODO: add microphone effects processing here, such as EQ, compressor, etc.
    connect(msa.source, msa.gain);
    connect(msa.gain, output);
  }

  get signalLevelDb(): number {
    return this.measure?.reduction ?? 0;
  }

  get signalLevelDb2(): number {
    if (!this.meter) return 0;
    const val = this.meter.getValue();
    return convertMeterValueToDbNumber(val);
  }

  get preGainValue() {
    return this.msa.gain.gain.value;
  }

  set preGainValue(value: number) {
    this.msa.gain.gain.targetRampTo(value, 0.01);
  }

  dispose() {
    this.measure?.dispose();
    this.pump?.dispose();
    disposeMsa(this.msa);
  }
}

type AudioBusEffectConfiguration = {
  musicSidechain: boolean;
  voiceBoost: boolean;
  musicBoost: boolean;
};

export type AudioBusOptions = {
  broadcastQuality: CustomAudioTrackInitConfig['encoderConfig'];
  initialMode?: AudioBus['mode'];
  initialEffects?: {
    [K in keyof AudioBusEffectConfiguration]?: boolean;
  };
};

type ParamValueNames =
  | 'musicReductionRatio'
  | 'musicMakeupGain'
  | 'musicReductionDb'
  | 'musicLevelDb'
  | 'micLevelDb'
  | 'micPreGain'
  | 'busGain'
  | 'busLevelDb';

type AudioBusEvents = {
  'mode-changed': (mode: 'process' | 'passthrough') => void;
  'music-track-changed': (hasTrack: boolean) => void;
  'music-monitor-toggled': (enabled: boolean) => void;
  'mic-track-changed': (hasTrack: boolean) => void;
  'mic-toggled': (enabled: boolean) => void;
  'param-value-changed': (name: ParamValueNames, value: number) => void;
  'effect-changed': (
    name: keyof AudioBusEffectConfiguration,
    enabled: boolean
  ) => void;
};

export class AudioBus {
  private emitter = new Emitter<AudioBusEvents>();
  on = this.emitter.on.bind(this.emitter);
  off = this.emitter.off.bind(this.emitter);

  // all audio that will be streamed into webrtc must terminate here
  private msd = getAudioContext().createMediaStreamDestination();

  // what will be published to Agora / output to webrtc
  outputTrack: ILocalAudioTrack;

  // controls the ultimate master volume of the music. Mostly used for
  // automatically silencing music if another primary audio source is playing.
  private musicGain = new Gain();
  private micGain = new Gain();

  private busGain = new Gain();
  private busMeter = new Meter();

  private micTrack: MediaStreamTrack | null = null;
  private musicTrack: MediaStreamTrack | null = null;

  private micPassthroughStream: MediaStreamAudio | null = null;
  private musicPassthroughStream: MediaStreamAudio | null = null;

  private micPipeline: MicAudioPipeline | null = null;
  private musicPipeline: MusicAudioPipeline | null = null;

  readonly mode: 'process' | 'passthrough' = 'passthrough';
  private initialized = false;

  private micIsOpen = true;

  private effectConfig: AudioBusEffectConfiguration = {
    musicBoost: false,
    musicSidechain: false,
    voiceBoost: false,
  };

  constructor(options?: AudioBusOptions) {
    this.micGain.connect(this.busGain);
    this.musicGain.connect(this.busGain);
    this.busGain.connect(this.msd);
    this.busGain.connect(this.busMeter);

    this.effectConfig = { ...this.effectConfig, ...options?.initialEffects };

    // Firefox stutters if using Agora's "high_quality_stereo" preset.
    // Anything over 128kbps bitrate causes firefox to have trouble.
    if (
      (options?.broadcastQuality &&
        options.broadcastQuality === 'high_quality_stereo') ||
      (typeof options?.broadcastQuality === 'object' &&
        (options.broadcastQuality.bitrate ?? 0) > 128)
    ) {
      throw new Error('Bitrate too high!');
    }

    this.outputTrack = AgoraRTC.createCustomAudioTrack({
      encoderConfig: options?.broadcastQuality ?? 'music_standard',
      mediaStreamTrack: this.msd.stream.getAudioTracks()[0],
    });

    this.setMode(options?.initialMode ?? this.mode);
  }

  enableEffect(
    effect: keyof AudioBusEffectConfiguration,
    enabled: boolean
  ): void {
    const prev = this.effectConfig[effect];
    this.effectConfig[effect] = enabled;
    if (prev === enabled) return;

    // Have to practically rebuild all since they have dependencies on each other.
    this.rebuild('mic');
    this.rebuild('music');

    this.emitter.emit('effect-changed', effect, enabled);
  }

  isEffectEnabled(name: keyof AudioBusEffectConfiguration): boolean {
    return this.effectConfig[name];
  }

  setMicrophoneTrack(track: MediaStreamTrack | null): void {
    if (track === this.micTrack) return;
    this.micTrack = track;
    this.rebuild('mic');
    this.emitter.emit('mic-track-changed', !!track);
  }

  setMusicTrack(track: MediaStreamTrack | null): void {
    if (track === this.musicTrack) return;
    this.musicTrack = track;
    this.rebuild('music');
    this.emitter.emit('music-track-changed', !!track);
  }

  setMode(mode: AudioBus['mode']): void {
    // Ensure this function executes the first time it is called during
    // initialization to ensure audio flows regardless of mode.
    if (this.mode === mode && this.initialized) return;
    (this as Mutable<AudioBus>).mode = mode;
    this.initialized = true;

    this.rebuild('mic');
    this.rebuild('music');

    this.emitter.emit('mode-changed', this.mode);
  }

  toggleMicrophone(enabled: boolean): void {
    this.micIsOpen = enabled;
    this.micGain.gain.value = enabled ? 1 : 0;
    this.emitter.emit('mic-toggled', this.micIsOpen);
  }

  get microphoneIsOpen(): boolean {
    return this.micIsOpen;
  }

  toggleMusicMonitor(enabled: boolean): void {
    const monitorDestination = getEchoCancelledAudioDestination();

    if (!enabled) {
      this.musicGain.disconnect(monitorDestination);
    } else {
      this.musicGain.connect(monitorDestination);
    }

    this.emitter.emit('music-monitor-toggled', enabled);
  }

  // duckMusicFrom(whenSeconds: number, durationSeconds: number): Promise<void> {
  //   // TODO: remember to handle canceling the duck
  //   // this is for videos / other media, when you don't want to hear the bg music
  // }

  dispose(): void {
    this.teardownPassthrough('mic');
    this.teardownPassthrough('music');
    this.teardownPipeline('mic');
    this.teardownPipeline('music');

    this.musicGain.disconnect();
    this.micGain.disconnect();
    this.busGain.disconnect();
    this.outputTrack.stop();
  }

  // The public interface for setting / getting audio-specific values.
  readParamValue(name: ParamValueNames): number {
    switch (name) {
      case 'musicReductionRatio': {
        if (!this.micPipeline) return 1;
        return this.micPipeline.reductionRatio;
      }
      case 'musicMakeupGain': {
        if (!this.micPipeline) return 0;
        return this.micPipeline.makeupGain;
      }

      case 'musicReductionDb': {
        if (!this.musicPipeline) return 0;
        return this.musicPipeline.reductionLevelDb;
      }

      case 'musicLevelDb': {
        if (!this.musicPipeline) return 0;
        return this.musicPipeline.musicLevelDb;
      }

      case 'micLevelDb': {
        if (!this.micPipeline) return 0;
        return this.micPipeline.signalLevelDb2;
      }

      case 'micPreGain': {
        if (this.micPipeline) return this.micPipeline.preGainValue;
        else return this.micPassthroughStream?.gain.gain.value ?? 1;
      }

      case 'busLevelDb': {
        return convertMeterValueToDbNumber(this.busMeter.getValue());
      }

      case 'busGain': {
        return this.busGain.gain.value;
      }

      default:
        assertExhaustive(name);
        return 0;
    }
  }

  setParamValue(name: ParamValueNames, value: number): void {
    switch (name) {
      case 'musicReductionRatio': {
        if (!this.micPipeline) break;
        this.micPipeline.reductionRatio = value;
        this.emitter.emit('param-value-changed', name, value);
        break;
      }
      case 'musicMakeupGain': {
        if (!this.micPipeline) break;
        this.micPipeline.makeupGain = value;
        this.emitter.emit('param-value-changed', name, value);
        break;
      }

      case 'micPreGain': {
        if (this.micPipeline) {
          this.micPipeline.preGainValue = value;
        } else if (this.micPassthroughStream) {
          this.micPassthroughStream.gain.gain.value = value;
        }

        this.emitter.emit('param-value-changed', name, value);
        break;
      }

      case 'busGain': {
        this.busGain.gain.value = value;
        this.emitter.emit('param-value-changed', name, value);
        break;
      }

      // These properties are readonly
      case 'musicReductionDb':
      case 'musicLevelDb':
      case 'micLevelDb':
      case 'busLevelDb': {
        break;
      }

      default:
        assertExhaustive(name);
    }
  }

  private rebuild(kind: 'mic' | 'music') {
    this.teardownPassthrough(kind);
    this.teardownPipeline(kind);

    if (this.mode === 'process') {
      this.buildPipeline(kind);
    } else {
      this.buildPassthrough(kind);
    }
  }

  private teardownPassthrough(kind: 'mic' | 'music') {
    if (this.micPassthroughStream && kind === 'mic') {
      disposeMsa(this.micPassthroughStream);
      this.micPassthroughStream = null;
    }

    if (this.musicPassthroughStream && kind === 'music') {
      disposeMsa(this.musicPassthroughStream);
      this.musicPassthroughStream = null;
    }
  }

  private buildPassthrough(kind: 'mic' | 'music') {
    if (this.micTrack && kind === 'mic') {
      this.micPassthroughStream = msaFromTrack(this.micTrack);
      connect(this.micPassthroughStream.source, this.micPassthroughStream.gain);
      connect(this.micPassthroughStream.gain, this.micGain);
      this.toggleMicrophone(this.micIsOpen);
    }

    if (this.musicTrack && kind === 'music') {
      this.musicPassthroughStream = msaFromTrack(this.musicTrack);
      connect(
        this.musicPassthroughStream.source,
        this.musicPassthroughStream.gain
      );
      connect(this.musicPassthroughStream.gain, this.musicGain);
    }
  }

  private teardownPipeline(kind: 'mic' | 'music') {
    if (this.micPipeline && kind === 'mic') {
      this.micPipeline.dispose();
      this.micPipeline = null;
      this.musicPipeline?.setSidechainControlSignal(null);
    }

    if (this.musicPipeline && kind === 'music') {
      this.musicPipeline.dispose();
      this.musicPipeline = null;
    }
  }

  private buildPipeline(kind: 'mic' | 'music') {
    if (this.micTrack && kind === 'mic') {
      this.micPipeline = new MicAudioPipeline(
        this.micTrack,
        this.micGain,
        this.effectConfig
      );
      this.toggleMicrophone(this.micIsOpen);
    }

    if (this.musicTrack && kind === 'music') {
      this.musicPipeline = new MusicAudioPipeline(
        this.musicTrack,
        this.musicGain,
        this.micPipeline
      );

      if (this.effectConfig.musicSidechain) {
        this.musicPipeline.setSidechainControlSignal(this.micPipeline);
      }
    }
  }
}
