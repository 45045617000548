import { type Uppy } from '@uppy/core';

import {
  AIChatBlockGameSessionStatus,
  type Block,
  type BlockAction,
  type BlockRecording,
  BlockType,
  CreativePromptBlockGameSessionStatus,
  DrawingPromptBlockGameSessionStatus,
  type GameSessionStatus,
  GuessWhoBlockGameSessionStatus,
  HeadToHeadBlockGameSessionStatus,
  HiddenPictureBlockGameSessionStatus,
  IcebreakerBlockGameSessionStatus,
  InstructionBlockGameSessionStatus,
  JeopardyBlockGameSessionStatus,
  MarketingBlockGameSessionStatus,
  MemoryMatchBlockGameSessionStatus,
  MultipleChoiceGameSessionStatus,
  type NamedBlockAction,
  OverRoastedBlockGameSessionStatus,
  PuzzleBlockGameSessionStatus,
  QuestionBlockGameSessionStatus,
  RandomizerGameSessionStatus,
  RapidBlockGameSessionStatus,
  RoundRobinQuestionBlockGameSessionStatus,
  ScoreboardBlockGameSessionStatus,
  SpotlightBlockGameSessionStatus,
  SpotlightBlockV2GameSessionStatus,
  TeamRelayBlockGameSessionStatus,
  TitleBlockV2GameSessionStatus,
} from '@lp-lib/game';

import { type Game } from '../../types/game';
import { assertExhaustive } from '../../utils/common';
import { type IDBChunkedStreamRecorder } from './IDBChunkedStreamRecorder';

export type BlockRecordingId = string;
export type BlockId = string;
export type UserId = string;

export interface GameRecordLocationState {
  recordMode?: boolean;
}

export function isGameRecordLocationState(
  state: unknown
): state is GameRecordLocationState {
  return typeof state === 'object' && state !== null && 'recordMode' in state;
}

export enum RecordingState {
  Disabled = 'disabled',
  Primed = 'primed',
  CountIn = 'count-in',
  Recording = 'recording',
  Ended = 'ended',
}

export class BlockActionDataUtils {
  static ForGameSessionStatus(
    desc: RelativeTimeDescription & {
      gameSessionStatus: GameSessionStatus | undefined | null;
    }
  ): BlockAction | null {
    if (desc.gameSessionStatus === null || desc.gameSessionStatus === undefined)
      return null;

    return {
      timestamp: relativeFromActionDescription(desc),
      gameSessionStatus: desc.gameSessionStatus,
    };
  }

  static ForNamedAction(
    desc: RelativeTimeDescription & {
      action: NamedBlockAction;
    }
  ): BlockAction {
    return {
      timestamp: relativeFromActionDescription(desc),
      action: desc.action,
    };
  }
}

type KnownIcons =
  | 'play'
  | 'timer'
  | 'stop'
  | 'info'
  | 'reveal'
  | 'scoreboard'
  | 'replay'
  | 'spotlight';

type ControllerActionDesc = {
  label: string;
  icon: KnownIcons;
};

function forNamedBlockAction(
  action: NamedBlockAction | null | undefined
): ControllerActionDesc | null {
  if (action === 'replay-video') {
    return { label: 'Replay Video', icon: 'replay' };
  } else if (action === 'townhall-to-crowd') {
    return { label: 'Townhall to Crowd', icon: 'info' };
  } else if (action === 'townhall-to-teams') {
    return { label: 'Townhall to Teams', icon: 'info' };
  } else if (action === undefined || action === null) {
    return null;
  } else {
    assertExhaustive(action);
    return null;
  }
}

export class ControllerActionUtils {
  static ForBlockAction(
    blockType: null | BlockType,
    action: BlockAction
  ): ControllerActionDesc | null {
    switch (blockType) {
      case BlockType.CREATIVE_PROMPT: {
        const status = action.gameSessionStatus as
          | CreativePromptBlockGameSessionStatus
          | undefined;
        switch (status) {
          case undefined:
          case CreativePromptBlockGameSessionStatus.END:
            return { label: 'Block Ended', icon: 'stop' };
          case CreativePromptBlockGameSessionStatus.LOADED:
            return { label: 'Block Loaded', icon: 'info' };
          case CreativePromptBlockGameSessionStatus.PRESENTING:
            return { label: 'Present Creative Prompt', icon: 'play' };
          case CreativePromptBlockGameSessionStatus.SUBMISSION_COUNTING:
            return { label: 'Waiting for Submissions', icon: 'timer' };
          case CreativePromptBlockGameSessionStatus.SUBMISSION_END:
            return { label: "Time's Up", icon: 'timer' };
          case CreativePromptBlockGameSessionStatus.SHOW_SUBMISSIONS:
            return { label: 'Show Submissions', icon: 'reveal' };
          case CreativePromptBlockGameSessionStatus.VOTE_COUNTING:
            return { label: 'Voting Timer Start', icon: 'timer' };
          case CreativePromptBlockGameSessionStatus.VOTE_END:
            return { label: 'Voting Timer Stop', icon: 'timer' };
          case CreativePromptBlockGameSessionStatus.RESULTS:
            return { label: 'Reveal Voting Results', icon: 'reveal' };
          case CreativePromptBlockGameSessionStatus.POINTS_DISTRIBUTED:
            return { label: 'Distribute Points', icon: 'reveal' };
          case CreativePromptBlockGameSessionStatus.SCOREBOARD:
            return { label: 'Show Scoreboard', icon: 'scoreboard' };
          default: {
            try {
              assertExhaustive(status);
            } catch (err) {}
            return forNamedBlockAction(action.action);
          }
        }
      }

      case BlockType.QUESTION: {
        const status = action.gameSessionStatus as
          | QuestionBlockGameSessionStatus
          | undefined;

        switch (status) {
          case undefined:
          case QuestionBlockGameSessionStatus.END:
            return { label: 'Block Ended', icon: 'stop' };
          case QuestionBlockGameSessionStatus.LOADED:
            return { label: 'Block Loaded', icon: 'info' };
          case QuestionBlockGameSessionStatus.PRESENTING:
            return { label: 'Present Question Block', icon: 'play' };
          case QuestionBlockGameSessionStatus.COUNTING:
            return { label: 'Start Question Timer', icon: 'timer' };
          case QuestionBlockGameSessionStatus.TIMESUP:
            return { label: "Time's Up", icon: 'timer' };
          case QuestionBlockGameSessionStatus.ANSWER:
            return { label: 'Reveal Answer', icon: 'reveal' };
          case QuestionBlockGameSessionStatus.SCOREBOARD:
            return { label: 'Show Scoreboard', icon: 'scoreboard' };
          default: {
            try {
              assertExhaustive(status);
            } catch (err) {}
            return forNamedBlockAction(action.action);
          }
        }
      }

      case BlockType.RAPID: {
        const status = action.gameSessionStatus as
          | RapidBlockGameSessionStatus
          | undefined;

        switch (status) {
          case undefined:
          case RapidBlockGameSessionStatus.END:
            return { label: 'Block Ended', icon: 'stop' };
          case RapidBlockGameSessionStatus.LOADED:
            return { label: 'Block Loaded', icon: 'info' };
          case RapidBlockGameSessionStatus.PRESENTING:
            return { label: 'Present Question', icon: 'play' };
          case RapidBlockGameSessionStatus.QUESTION_COUNTING:
            return { label: 'Start Timer', icon: 'timer' };
          case RapidBlockGameSessionStatus.QUESTION_END:
            return { label: "Time's Up", icon: 'timer' };
          case RapidBlockGameSessionStatus.RESULTS:
            return { label: 'Reveal Correct Answers', icon: 'reveal' };
          case RapidBlockGameSessionStatus.POINTS_DISTRIBUTED:
            return { label: 'Distribute Points', icon: 'reveal' };
          case RapidBlockGameSessionStatus.SCOREBOARD:
            return { label: 'Show Scoreboard', icon: 'scoreboard' };
          default: {
            try {
              assertExhaustive(status);
            } catch (err) {}
            return forNamedBlockAction(action.action);
          }
        }
      }

      case BlockType.SCOREBOARD: {
        const status = action.gameSessionStatus as
          | ScoreboardBlockGameSessionStatus
          | undefined;
        switch (status) {
          case undefined:
          case ScoreboardBlockGameSessionStatus.END:
            return { label: 'Block Ended', icon: 'stop' };
          case ScoreboardBlockGameSessionStatus.LOADED:
            return { label: 'Block Loaded', icon: 'info' };
          case ScoreboardBlockGameSessionStatus.PRESENTING:
          case ScoreboardBlockGameSessionStatus.SCOREBOARD:
            return {
              label: 'Show Scoreboard',
              icon: 'scoreboard',
            };
          default: {
            try {
              assertExhaustive(status);
            } catch (err) {}
            return forNamedBlockAction(action.action);
          }
        }
      }
      case BlockType.SPOTLIGHT: {
        const status = action.gameSessionStatus as
          | SpotlightBlockGameSessionStatus
          | undefined;
        switch (status) {
          case undefined:
          case SpotlightBlockGameSessionStatus.END:
            return { label: 'Block Ended', icon: 'stop' };
          case SpotlightBlockGameSessionStatus.LOADED:
            return { label: 'Block Loaded', icon: 'info' };
          case SpotlightBlockGameSessionStatus.PRESENTING:
            return { label: 'Spotlight Player(s)', icon: 'spotlight' };
          case SpotlightBlockGameSessionStatus.CELEBRATING:
            return { label: 'Play Block Media', icon: 'play' };
          default: {
            try {
              assertExhaustive(status);
            } catch (err) {}
            return forNamedBlockAction(action.action);
          }
        }
      }
      case BlockType.SPOTLIGHT_V2: {
        const status = action.gameSessionStatus as
          | SpotlightBlockV2GameSessionStatus
          | undefined;
        switch (status) {
          case undefined:
          case SpotlightBlockV2GameSessionStatus.END:
            return { label: 'Block Ended', icon: 'stop' };
          case SpotlightBlockV2GameSessionStatus.LOADED:
            return { label: 'Block Loaded', icon: 'info' };
          case SpotlightBlockV2GameSessionStatus.PRESENTING:
            return { label: 'Spotlight Player(s)', icon: 'spotlight' };
          case SpotlightBlockV2GameSessionStatus.CELEBRATING:
            return { label: 'Play Block Media', icon: 'play' };
          case SpotlightBlockV2GameSessionStatus.RESULTS:
            return { label: 'Reveal Results', icon: 'reveal' };
          default: {
            try {
              assertExhaustive(status);
            } catch (err) {}
            return forNamedBlockAction(action.action);
          }
        }
      }
      case BlockType.TEAM_RELAY: {
        const status = action.gameSessionStatus as
          | TeamRelayBlockGameSessionStatus
          | undefined;
        switch (status) {
          case undefined:
          case TeamRelayBlockGameSessionStatus.END:
            return { label: 'Block Ended', icon: 'stop' };
          case TeamRelayBlockGameSessionStatus.LOADED:
            return { label: 'Block Loaded', icon: 'info' };
          case TeamRelayBlockGameSessionStatus.INTRO:
            return { label: 'Present Intro', icon: 'play' };
          case TeamRelayBlockGameSessionStatus.GAME_INIT:
            return { label: 'Prepare Game', icon: 'timer' };
          case TeamRelayBlockGameSessionStatus.GAME_START:
            return { label: 'Start Game', icon: 'timer' };
          case TeamRelayBlockGameSessionStatus.GAME_END:
            return { label: "Time's Up", icon: 'timer' };
          case TeamRelayBlockGameSessionStatus.OUTRO:
            return { label: 'Present Outro', icon: 'play' };
          case TeamRelayBlockGameSessionStatus.RESULTS:
            return { label: 'Reveal Results', icon: 'reveal' };
          case TeamRelayBlockGameSessionStatus.SCOREBOARD:
            return { label: 'Show Scoreboard', icon: 'scoreboard' };
          default: {
            try {
              assertExhaustive(status);
            } catch (err) {}
            return forNamedBlockAction(action.action);
          }
        }
      }
      case BlockType.RANDOMIZER: {
        const status = action.gameSessionStatus as
          | RandomizerGameSessionStatus
          | undefined;
        switch (status) {
          case undefined:
          case RandomizerGameSessionStatus.END:
            return { label: 'Block Ended', icon: 'stop' };
          case RandomizerGameSessionStatus.LOADED:
            return { label: 'Block Loaded', icon: 'info' };
          case RandomizerGameSessionStatus.STARTING:
            return { label: 'Start Randomizer', icon: 'play' };
          case RandomizerGameSessionStatus.RESULT:
            return { label: 'none', icon: 'play' };
          default: {
            try {
              assertExhaustive(status);
            } catch (err) {}
            return forNamedBlockAction(action.action);
          }
        }
      }

      case BlockType.MULTIPLE_CHOICE: {
        const status = action.gameSessionStatus as
          | MultipleChoiceGameSessionStatus
          | undefined;

        switch (status) {
          case undefined:
          case MultipleChoiceGameSessionStatus.END:
            return { label: 'Block Ended', icon: 'stop' };
          case MultipleChoiceGameSessionStatus.LOADED:
            return { label: 'Block Loaded', icon: 'info' };
          case MultipleChoiceGameSessionStatus.PRESENTING_QUESTION:
            return { label: 'Presenting Question Media', icon: 'play' };
          case MultipleChoiceGameSessionStatus.PRESENTED_QUESTION:
            return { label: 'Presented Question Media', icon: 'play' };
          case MultipleChoiceGameSessionStatus.SUBMISSION_TIMER_COUNTING:
            return { label: 'Start Question Timer', icon: 'timer' };
          case MultipleChoiceGameSessionStatus.SUBMISSION_TIMER_DONE:
            return { label: "Time's Up", icon: 'timer' };
          case MultipleChoiceGameSessionStatus.PRESENTING_ANSWER:
            return { label: 'Presenting Answer Media', icon: 'reveal' };
          case MultipleChoiceGameSessionStatus.PRESENTED_ANSWER:
            return { label: 'Revealing Answer', icon: 'reveal' };
          case MultipleChoiceGameSessionStatus.RESULTS:
            return { label: 'Show Results', icon: 'reveal' };
          case MultipleChoiceGameSessionStatus.SCOREBOARD:
            return { label: 'Show Scoreboard', icon: 'scoreboard' };
          default: {
            try {
              assertExhaustive(status);
            } catch (err) {}
            return forNamedBlockAction(action.action);
          }
        }
      }

      case BlockType.MEMORY_MATCH: {
        const status = action.gameSessionStatus as
          | MemoryMatchBlockGameSessionStatus
          | undefined;

        switch (status) {
          case undefined:
          case MemoryMatchBlockGameSessionStatus.END:
            return { label: 'Block Ended', icon: 'stop' };
          case MemoryMatchBlockGameSessionStatus.LOADED:
            return { label: 'Block Loaded', icon: 'info' };
          case MemoryMatchBlockGameSessionStatus.GAME_INIT:
            return { label: 'Present Block', icon: 'play' };
          case MemoryMatchBlockGameSessionStatus.GAME_START:
            return { label: 'Start Game', icon: 'timer' };
          case MemoryMatchBlockGameSessionStatus.GAME_END:
            return { label: "Time's Up", icon: 'timer' };
          case MemoryMatchBlockGameSessionStatus.RESULTS:
            return { label: 'Show Results', icon: 'reveal' };
          case MemoryMatchBlockGameSessionStatus.SCOREBOARD:
            return { label: 'Show Scoreboard', icon: 'scoreboard' };
          default: {
            try {
              assertExhaustive(status);
            } catch (err) {}
            return forNamedBlockAction(action.action);
          }
        }
      }

      case BlockType.PUZZLE: {
        const status = action.gameSessionStatus as
          | PuzzleBlockGameSessionStatus
          | undefined;

        switch (status) {
          case undefined:
          case PuzzleBlockGameSessionStatus.END:
            return { label: 'Block Ended', icon: 'stop' };
          case PuzzleBlockGameSessionStatus.LOADED:
            return { label: 'Block Loaded', icon: 'info' };
          case PuzzleBlockGameSessionStatus.INTRO:
            return { label: 'Present Intro', icon: 'play' };
          case PuzzleBlockGameSessionStatus.GAME_INIT:
            return { label: 'Present Block', icon: 'play' };
          case PuzzleBlockGameSessionStatus.GAME_START:
            return { label: 'Start Game', icon: 'timer' };
          case PuzzleBlockGameSessionStatus.GAME_END:
            return { label: "Time's Up", icon: 'timer' };
          case PuzzleBlockGameSessionStatus.OUTRO:
            return { label: 'Present Outro', icon: 'play' };
          case PuzzleBlockGameSessionStatus.RESULTS:
            return { label: 'Show Results', icon: 'reveal' };
          case PuzzleBlockGameSessionStatus.SCOREBOARD:
            return { label: 'Show Scoreboard', icon: 'scoreboard' };
          default: {
            try {
              assertExhaustive(status);
            } catch (err) {}
            return forNamedBlockAction(action.action);
          }
        }
      }

      case BlockType.ROUND_ROBIN_QUESTION: {
        const status = action.gameSessionStatus as
          | RoundRobinQuestionBlockGameSessionStatus
          | undefined;

        switch (status) {
          case undefined:
          case RoundRobinQuestionBlockGameSessionStatus.END:
            return { label: 'Block Ended', icon: 'stop' };
          case RoundRobinQuestionBlockGameSessionStatus.LOADED:
            return { label: 'Block Loaded', icon: 'info' };
          case RoundRobinQuestionBlockGameSessionStatus.GAME_INIT:
            return { label: 'Present Block', icon: 'play' };
          case RoundRobinQuestionBlockGameSessionStatus.GAME_START:
            return { label: 'Start Game', icon: 'timer' };
          case RoundRobinQuestionBlockGameSessionStatus.GAME_END:
            return { label: "Time's Up", icon: 'timer' };
          case RoundRobinQuestionBlockGameSessionStatus.RESULTS:
            return { label: 'Show Results', icon: 'reveal' };
          case RoundRobinQuestionBlockGameSessionStatus.SCOREBOARD:
            return { label: 'Show Scoreboard', icon: 'scoreboard' };
          default: {
            try {
              assertExhaustive(status);
            } catch (err) {}
            return forNamedBlockAction(action.action);
          }
        }
      }

      case BlockType.TITLE_V2: {
        const status = action.gameSessionStatus as
          | TitleBlockV2GameSessionStatus
          | undefined;
        switch (status) {
          case undefined:
          case TitleBlockV2GameSessionStatus.END:
            return { label: 'Block Ended', icon: 'stop' };
          case TitleBlockV2GameSessionStatus.LOADED:
            return { label: 'Block Loaded', icon: 'info' };
          default:
            return {
              label: `Present Title Card ${status}`,
              icon: 'play',
            };
        }
      }

      case BlockType.INSTRUCTION: {
        const status = action.gameSessionStatus as
          | InstructionBlockGameSessionStatus
          | undefined;
        switch (status) {
          case undefined:
          case InstructionBlockGameSessionStatus.LOADED:
            return { label: 'Block Loaded', icon: 'info' };
          case InstructionBlockGameSessionStatus.GAME_INIT:
            return { label: 'Present Block', icon: 'play' };
          case InstructionBlockGameSessionStatus.GAME_START:
            return { label: 'Start Game', icon: 'timer' };
          case InstructionBlockGameSessionStatus.GAME_END:
            return { label: 'Game End', icon: 'stop' };
          case InstructionBlockGameSessionStatus.END:
            return { label: 'Block Ended', icon: 'stop' };
          default: {
            try {
              assertExhaustive(status);
            } catch (err) {}
            return forNamedBlockAction(action.action);
          }
        }
      }

      case BlockType.OVERROASTED: {
        const status = action.gameSessionStatus as
          | OverRoastedBlockGameSessionStatus
          | undefined;

        switch (status) {
          case undefined:
          case OverRoastedBlockGameSessionStatus.END:
            return { label: 'Block Ended', icon: 'stop' };
          case OverRoastedBlockGameSessionStatus.LOADED:
            return { label: 'Block Loaded', icon: 'info' };
          case OverRoastedBlockGameSessionStatus.INTRO:
            return { label: 'Present Intro', icon: 'play' };
          case OverRoastedBlockGameSessionStatus.GAME_INIT:
            return { label: 'Present Block', icon: 'play' };
          case OverRoastedBlockGameSessionStatus.GAME_START:
            return { label: 'Start Game', icon: 'timer' };
          case OverRoastedBlockGameSessionStatus.GAME_END:
            return { label: "Time's Up", icon: 'timer' };
          case OverRoastedBlockGameSessionStatus.OUTRO:
            return { label: 'Present Outro', icon: 'play' };
          case OverRoastedBlockGameSessionStatus.RESULTS:
            return { label: 'Show Results', icon: 'reveal' };
          case OverRoastedBlockGameSessionStatus.SCOREBOARD:
            return { label: 'Show Scoreboard', icon: 'scoreboard' };
          default: {
            try {
              assertExhaustive(status);
            } catch (err) {}
            return forNamedBlockAction(action.action);
          }
        }
      }

      case BlockType.DRAWING_PROMPT: {
        const status = action.gameSessionStatus as
          | DrawingPromptBlockGameSessionStatus
          | undefined;

        switch (status) {
          case undefined:
          case DrawingPromptBlockGameSessionStatus.END:
            return { label: 'Block Ended', icon: 'stop' };
          case DrawingPromptBlockGameSessionStatus.LOADED:
            return { label: 'Block Loaded', icon: 'info' };
          case DrawingPromptBlockGameSessionStatus.INIT:
            return { label: 'Present Block', icon: 'info' };
          case DrawingPromptBlockGameSessionStatus.DRAWING_START:
            return { label: 'Start Drawing', icon: 'play' };
          case DrawingPromptBlockGameSessionStatus.DRAWING_END:
            return { label: "Time's Up", icon: 'timer' };
          case DrawingPromptBlockGameSessionStatus.TEAM_VOTE_COUNTING:
            return { label: 'Start Team Vote', icon: 'play' };
          case DrawingPromptBlockGameSessionStatus.TEAM_VOTE_DONE:
            return { label: 'End Team Vote', icon: 'timer' };
          case DrawingPromptBlockGameSessionStatus.TITLE_CREATION_COUNTING:
            return { label: 'Start Title Creation', icon: 'play' };
          case DrawingPromptBlockGameSessionStatus.TITLE_CREATION_DONE:
            return { label: 'End Title Creation', icon: 'timer' };
          case DrawingPromptBlockGameSessionStatus.MATCH_PROMPT_INIT:
            return { label: 'Present Match Prompt Intro', icon: 'play' };
          case DrawingPromptBlockGameSessionStatus.MATCH_PROMPT_COUNTING:
            return { label: 'Start Match Prompt', icon: 'play' };
          case DrawingPromptBlockGameSessionStatus.MATCH_PROMPT_DONE:
            return { label: 'End Match Prompt', icon: 'timer' };
          case DrawingPromptBlockGameSessionStatus.REVIEW_ALL_DRAWINGS:
            return { label: 'Start Review Drawings', icon: 'play' };
          case DrawingPromptBlockGameSessionStatus.RESULTS:
            return { label: 'Show Results', icon: 'reveal' };
          case DrawingPromptBlockGameSessionStatus.SCOREBOARD:
            return { label: 'Show Scoreboard', icon: 'scoreboard' };
          default: {
            try {
              assertExhaustive(status);
            } catch (err) {}
            return forNamedBlockAction(action.action);
          }
        }
      }

      case BlockType.HIDDEN_PICTURE: {
        const status = action.gameSessionStatus as
          | HiddenPictureBlockGameSessionStatus
          | undefined;

        switch (status) {
          case undefined:
          case HiddenPictureBlockGameSessionStatus.END:
            return { label: 'Block Ended', icon: 'stop' };
          case HiddenPictureBlockGameSessionStatus.LOADED:
            return { label: 'Block Loaded', icon: 'info' };
          case HiddenPictureBlockGameSessionStatus.INTRO:
            return { label: 'Present Intro', icon: 'play' };
          case HiddenPictureBlockGameSessionStatus.GAME_INIT:
            return { label: 'Present Block', icon: 'play' };
          case HiddenPictureBlockGameSessionStatus.GAME_START:
            return { label: 'Start Game', icon: 'timer' };
          case HiddenPictureBlockGameSessionStatus.GAME_END:
            return { label: "Time's Up", icon: 'timer' };
          case HiddenPictureBlockGameSessionStatus.OUTRO:
            return { label: 'Present Outro', icon: 'play' };
          case HiddenPictureBlockGameSessionStatus.RESULTS:
            return { label: 'Show Results', icon: 'reveal' };
          case HiddenPictureBlockGameSessionStatus.SCOREBOARD:
            return { label: 'Show Scoreboard', icon: 'scoreboard' };
          default: {
            try {
              assertExhaustive(status);
            } catch (err) {}
            return forNamedBlockAction(action.action);
          }
        }
      }

      case BlockType.AI_CHAT: {
        const status = action.gameSessionStatus as
          | AIChatBlockGameSessionStatus
          | undefined;

        switch (status) {
          case undefined:
          case AIChatBlockGameSessionStatus.END:
            return { label: 'Block Ended', icon: 'stop' };
          case AIChatBlockGameSessionStatus.LOADED:
            return { label: 'Block Loaded', icon: 'info' };
          case AIChatBlockGameSessionStatus.INTRO:
            return { label: 'Present Intro', icon: 'play' };
          case AIChatBlockGameSessionStatus.GAME_INIT:
            return { label: 'Present Block', icon: 'play' };
          case AIChatBlockGameSessionStatus.GAME_START:
            return { label: 'Start Game', icon: 'timer' };
          case AIChatBlockGameSessionStatus.GAME_END:
            return { label: "Time's Up", icon: 'timer' };
          case AIChatBlockGameSessionStatus.OUTRO:
            return { label: 'Present Outro', icon: 'play' };
          case AIChatBlockGameSessionStatus.RESULTS:
            return { label: 'Show Results', icon: 'reveal' };
          case AIChatBlockGameSessionStatus.SCOREBOARD:
            return { label: 'Show Scoreboard', icon: 'scoreboard' };
          default: {
            try {
              assertExhaustive(status);
            } catch (err) {}
            return forNamedBlockAction(action.action);
          }
        }
      }

      case BlockType.GUESS_WHO: {
        const status = action.gameSessionStatus as
          | GuessWhoBlockGameSessionStatus
          | undefined;

        switch (status) {
          case undefined:
          case GuessWhoBlockGameSessionStatus.END:
            return { label: 'Block Ended', icon: 'stop' };
          case GuessWhoBlockGameSessionStatus.LOADED:
            return { label: 'Block Loaded', icon: 'info' };
          case GuessWhoBlockGameSessionStatus.INTRO:
            return { label: 'Present Intro', icon: 'play' };
          case GuessWhoBlockGameSessionStatus.PROMPT_INIT:
            return { label: 'Present Block', icon: 'play' };
          case GuessWhoBlockGameSessionStatus.PROMPT_COUNTING:
            return { label: 'Start Game', icon: 'timer' };
          case GuessWhoBlockGameSessionStatus.PROMPT_DONE:
            return { label: "Time's Up", icon: 'timer' };
          case GuessWhoBlockGameSessionStatus.MATCH_PROMPT_INIT:
            return { label: 'Present Match Prompt Intro', icon: 'play' };
          case GuessWhoBlockGameSessionStatus.MATCH_PROMPT_COUNTING:
            return { label: 'Start Guessing', icon: 'timer' };
          case GuessWhoBlockGameSessionStatus.MATCH_PROMPT_DONE:
            return { label: 'End Match Prompt', icon: 'timer' };
          case GuessWhoBlockGameSessionStatus.RESULTS:
            return { label: 'Show Results', icon: 'reveal' };
          case GuessWhoBlockGameSessionStatus.SCOREBOARD:
            return { label: 'Show Scoreboard', icon: 'scoreboard' };
          default: {
            try {
              assertExhaustive(status);
            } catch (err) {}
            return forNamedBlockAction(action.action);
          }
        }
      }

      case BlockType.ICEBREAKER: {
        const status = action.gameSessionStatus as
          | IcebreakerBlockGameSessionStatus
          | undefined;

        switch (status) {
          case undefined:
          case IcebreakerBlockGameSessionStatus.END:
            return { label: 'Block Ended', icon: 'stop' };
          case IcebreakerBlockGameSessionStatus.LOADED:
            return { label: 'Block Loaded', icon: 'info' };
          case IcebreakerBlockGameSessionStatus.GAME_INIT:
            return { label: 'Present Block', icon: 'play' };
          case IcebreakerBlockGameSessionStatus.GAME_START:
            return { label: 'Start Game', icon: 'timer' };
          case IcebreakerBlockGameSessionStatus.RESULTS:
            return { label: 'Show Results', icon: 'reveal' };
          default: {
            try {
              assertExhaustive(status);
            } catch (err) {}
            return forNamedBlockAction(action.action);
          }
        }
      }

      case BlockType.MARKETING: {
        const status = action.gameSessionStatus as
          | MarketingBlockGameSessionStatus
          | undefined;

        switch (status) {
          case undefined:
          case MarketingBlockGameSessionStatus.END:
            return { label: 'Block Ended', icon: 'stop' };
          case MarketingBlockGameSessionStatus.LOADED:
            return { label: 'Block Loaded', icon: 'info' };
          case MarketingBlockGameSessionStatus.PRESENTING:
            return { label: 'Present Block', icon: 'play' };
          default: {
            try {
              assertExhaustive(status);
            } catch (err) {}
            return forNamedBlockAction(action.action);
          }
        }
      }

      case BlockType.JEOPARDY: {
        const status = action.gameSessionStatus as
          | JeopardyBlockGameSessionStatus
          | undefined;

        switch (status) {
          case undefined:
          case JeopardyBlockGameSessionStatus.END:
            return { label: 'Block Ended', icon: 'stop' };
          case JeopardyBlockGameSessionStatus.LOADED:
            return { label: 'Block Loaded', icon: 'info' };
          case JeopardyBlockGameSessionStatus.GAME_INIT:
            return { label: 'Present Block', icon: 'play' };
          case JeopardyBlockGameSessionStatus.GAME_START:
            return { label: 'Start Game', icon: 'timer' };
          case JeopardyBlockGameSessionStatus.GAME_END:
            return { label: "Time's Up", icon: 'timer' };
          case JeopardyBlockGameSessionStatus.OUTRO:
            return { label: 'Present Outro', icon: 'play' };
          case JeopardyBlockGameSessionStatus.RESULTS:
            return { label: 'Show Results', icon: 'reveal' };
          default: {
            try {
              assertExhaustive(status);
            } catch (err) {}
            return forNamedBlockAction(action.action);
          }
        }
      }
      case BlockType.HEAD_TO_HEAD: {
        const status = action.gameSessionStatus as
          | HeadToHeadBlockGameSessionStatus
          | undefined;
        switch (status) {
          case undefined:
          case HeadToHeadBlockGameSessionStatus.END:
            return { label: 'Block Ended', icon: 'stop' };
          case HeadToHeadBlockGameSessionStatus.LOADED:
            return { label: 'Block Loaded', icon: 'info' };
          case HeadToHeadBlockGameSessionStatus.GAME_INIT:
            return { label: 'Present Block', icon: 'play' };
          case HeadToHeadBlockGameSessionStatus.GAME_INTRO:
            return { label: 'Present Intro', icon: 'play' };
          case HeadToHeadBlockGameSessionStatus.GAME_START:
            return { label: 'Start Game', icon: 'timer' };
          case HeadToHeadBlockGameSessionStatus.GAME_END:
            return { label: "Time's Up", icon: 'timer' };
          case HeadToHeadBlockGameSessionStatus.RESULTS:
            return { label: 'Show Results', icon: 'reveal' };
          default: {
            try {
              assertExhaustive(status);
            } catch (err) {}
            return forNamedBlockAction(action.action);
          }
        }
      }
      case BlockType.SLIDE: {
        return { label: 'Present Block', icon: 'play' };
      }
      case null:
        return null;
      default: {
        assertExhaustive(blockType);
        return null;
      }
    }
  }
}

// TODO: rectify this with the backend dto/request/response objects
export type BlockRecordingData = Omit<
  BlockRecording,
  'updatedAt' | 'updatedByUid'
> & {
  block: Block;
  gameId: Game['id'];
  updatedAt?: string;
  updatedByUid?: string;
};

export type BlockRecordingVideoData = {
  blockId: BlockId;
  filename: string;
  uppyId: string | null;
};

const Reffed: unique symbol = Symbol('reffed');
export type ReffedBlockRecordingVideoData = BlockRecordingVideoData & {
  [Reffed]: true;
};

export class BlockRecordingVideoDataUtils {
  static IsReffed(
    vdata: BlockRecordingVideoData
  ): vdata is ReffedBlockRecordingVideoData {
    return Reffed in vdata && vdata[Reffed] === true;
  }

  static From(
    blockId: BlockId,
    filename: string,
    uppyId: string | null
  ): ReffedBlockRecordingVideoData {
    return {
      blockId,
      filename,
      uppyId,
      [Reffed]: true,
    };
  }
}

export type ActionsDataUpload = {
  kind: 'actions';
  data: BlockRecordingData;
} & BlockRecordingUploadStatusable;

export type HostVideoDataUpload = {
  kind: 'host-video';
  data: ReffedBlockRecordingVideoData;
  progress: number | null;
} & BlockRecordingUploadStatusable;

export interface BlockRecordingUploadStatusable {
  status: 'uploading' | 'failed' | 'saved' | 'none';
}

export type BlockRecordingDataUpload = {
  actions: ActionsDataUpload;
  hostVideo: HostVideoDataUpload | null;
};

export type BlockRecorderState = {
  uploads: {
    [K in BlockId]: BlockRecordingDataUpload | undefined;
  };

  refs: {
    uppy: Uppy;
    hostVideoFileHandle: null | IDBChunkedStreamRecorder;
    hostVideoStream: MediaStream | null;
  };

  autoAdvance: boolean;
  hasManuallyStartedRecordingOnce: boolean;

  // TODO: add current block / block id to recording?
  recording: {
    state: RecordingState;
    data: null | BlockRecordingData;
    startTimestampMs: number | null;
    countInRef: ReturnType<typeof setInterval> | null;
    countIn: number | null;
    elapsedSecondsRef: ReturnType<typeof setInterval> | null;
    elapsedSeconds: number | null;
  };

  /**
   * the user configured version for the recorder. this value is null if the user has not set it.
   */
  recorderVersion: number;
};

type RelativeTimeDescription = {
  startTimestampMs: number;
  nowTimestampMs?: number;
};

function relativeFromActionDescription(desc: RelativeTimeDescription) {
  return (desc.nowTimestampMs ?? Date.now()) - desc.startTimestampMs;
}
