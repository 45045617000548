import { useEffect, useState } from 'react';

import { useLiveCallback } from '../../../hooks/useLiveCallback';
import { SiteBaseURL } from '../../../services/public';
import { type GamePack } from '../../../types/game';
import { CopyLinkButtonWithPreview } from '../../common/CopyButton';
import { useAwaitFullScreenConfirmCancelModal } from '../../ConfirmCancelModalContext';
import { OrganizationSelect } from '../../Organization/OrganizationSelect';

function TrainingShareModal(props: { packId: GamePack['id'] }) {
  const [guestUsers, setGuestUsers] = useState(true);
  const [asOrgId, setAsOrgId] = useState<string | null>(null);
  const [shareLink, setShareLink] = useState<string | null>(null);

  useEffect(() => {
    const url = new SiteBaseURL();
    url.pathname = `/game-packs/${props.packId}/share`;

    if (guestUsers) {
      url.searchParams.set('guest-users', 'enabled');
    } else {
      url.searchParams.delete('guest-users');
    }

    if (asOrgId) {
      url.searchParams.set('as-org-id', asOrgId);
    } else {
      url.searchParams.delete('as-org-id');
    }

    setShareLink(url.toString());
  }, [asOrgId, guestUsers, props.packId]);

  return (
    <div className='w-full text-white text-sms flex flex-col gap-4'>
      <div className='text-2xl font-medium text-center'>Craft Share Link</div>

      <label className='w-full flex justify-between items-center gap-8'>
        <span>Masquerade As Organization (Optional) </span>
        <div className='w-50'>
          <OrganizationSelect
            className='w-full'
            orgId={asOrgId}
            onChange={(org) => setAsOrgId(org?.id ?? null)}
            isClearable
            placeholder='Organization'
          />
        </div>
      </label>

      <label className='w-full flex justify-between items-center gap-8'>
        <span>Allow Guest Users</span>
        <input
          checked={guestUsers}
          type='checkbox'
          onChange={(ev) => setGuestUsers(ev.target.checked)}
        />
      </label>

      <label className='w-full flex justify-between items-center gap-8'>
        <CopyLinkButtonWithPreview
          urlToCopy={shareLink ?? ''}
          ctaVariant='secondary'
          dimensionsClassName='w-full h-10'
        />
      </label>
    </div>
  );
}

export function useTrainingShareModal() {
  const triggerModal = useAwaitFullScreenConfirmCancelModal();

  return useLiveCallback(async (gamePackId: GamePack['id']) => {
    await triggerModal({
      kind: 'confirm-cancel',
      prompt: <TrainingShareModal packId={gamePackId} />,
      confirmBtnLabel: 'Done',
      confirmBtnVariant: 'primary',
      confirmOnly: true,
      boxDimensionsClassName: 'px-3 pt-3 min-h-45 w-125',
    });
  });
}
