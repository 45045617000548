import { memo } from 'react';

import { useIsTeamCaptainScribe } from '../TeamAPI/TeamV1';
import { TeamCaptainScribeBadge } from '../TeamCaptainScribe/TeamCaptainScribeBadge';

export const Badge = memo(function Badge(props: {
  clientId: string;
  teamId: Nullable<string>;
  showCaptainIcon: boolean;
}): JSX.Element | null {
  const { teamId, clientId, showCaptainIcon } = props;
  const isTeamCaptain = useIsTeamCaptainScribe(teamId, clientId);

  if (!isTeamCaptain || !showCaptainIcon) return null;
  return (
    <div className='absolute w-full h-full inset-0 z-10'>
      <div className='absolute -bottom-1 -left-1 w-1/4'>
        <TeamCaptainScribeBadge overrideClassName='w-full' />
      </div>
    </div>
  );
});
