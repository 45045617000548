import useSWR from 'swr';

import { apiService } from '../../services/api-service';
import { type Event } from '../../services/api-service/event.api';

async function loadEvent(eventId: string): Promise<Event> {
  const resp = await apiService.event.getEvent(eventId);
  return resp.data.event;
}

export function useEvent(eventId: string | null | undefined) {
  return useSWR(
    eventId ? ['/events/', eventId] : null,
    ([_, eventId]) => loadEvent(eventId),
    {
      shouldRetryOnError: false,
    }
  );
}
