import { useMemo } from 'react';

import { useLayoutAnchorRect } from '../../../LayoutAnchors/LayoutAnchors';

// approximate aspectRatio from the design spec for simplify the math
export function useCardWidth(
  numOfCards: number,
  aspectRatio = 2 / 3,
  minWidth = 200
): number {
  const { width: cw, height: ch } = useLayoutAnchorRect(
    'drawing-present-spacing-anchor'
  ) ?? { width: 0, height: 0 };
  return useMemo(() => {
    let width = cw / numOfCards;
    const height = width / aspectRatio;
    if (height > ch) {
      width = ch * aspectRatio;
    }
    return Math.max(width, minWidth);
  }, [aspectRatio, ch, cw, minWidth, numOfCards]);
}
