const LeaveIcon = (props: { className?: string }): JSX.Element => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 14 14'
      className={props.className}
    >
      <path d='M12.233 12.756V2.232H5.267v3.877a.633.633 0 01-1.267 0V2.232C4 1.402 4.567 1 5.267 1h6.966c.7 0 1.267.401 1.267 1.232v10.524c0 .83-.567 1.244-1.267 1.244H5.267C4.567 14 4 13.586 4 12.756v-.87a.633.633 0 111.267 0v.87h6.966z'></path>
      <path d='M6.815 9.741H2.526v.95a.2.2 0 01-.326.155L.381 9.366a.4.4 0 010-.62l1.82-1.48a.2.2 0 01.325.155v.95h4.289a.685.685 0 110 1.37z'></path>
    </svg>
  );
};

export { LeaveIcon };
