import { useEffect } from 'react';

import {
  type InstructionBlock,
  InstructionBlockGameSessionStatus,
} from '@lp-lib/game';

import { assertExhaustive } from '../../../../utils/common';
import { PlayIcon } from '../../../icons/PlayIcon';
import { FilledSquareIcon } from '../../../icons/SquareIcon';
import {
  useGameSessionLocalTimer,
  useGameSessionStatus,
  useIsRecording,
} from '../../hooks';
import { countdownV2, next, present, triggerBlockTitleAnim } from '../../store';
import {
  BlockControllerActionButton,
  BlockControllerActionTimeRemaining,
  ControllerLayout,
  type ControllerProps,
  useBlockControllerBlockTitlePlaying,
} from '../Common/Controller/Internal';

function Loaded(props: ControllerProps<InstructionBlock>): JSX.Element {
  const { selectedBlock: block } = props;

  const handlePresent = async () => {
    await triggerBlockTitleAnim(block);
    await present(block);
  };

  const actionComponent = useBlockControllerBlockTitlePlaying(block) ?? (
    <BlockControllerActionButton onClick={handlePresent} icon={PlayIcon}>
      Present Block
    </BlockControllerActionButton>
  );

  return (
    <ControllerLayout action={actionComponent}>
      <div></div>
    </ControllerLayout>
  );
}

function Init(_props: ControllerProps<InstructionBlock>): JSX.Element | null {
  const gameTimeSec = useGameSessionLocalTimer();

  useEffect(() => {
    if (!gameTimeSec) return;

    countdownV2({
      debug: 'InstructionBlockGameControl',
      startTimeWorker: true,
      flushCountingStatus: true,
      triggerTimesup: true,
    });
  }, [gameTimeSec]);

  return null;
}

function GameInProgress(
  _props: ControllerProps<InstructionBlock>
): JSX.Element | null {
  const time = useGameSessionLocalTimer();
  const isRecording = useIsRecording();

  useEffect(() => {
    if (!isRecording) return;

    next();
  }, [isRecording]);

  return (
    <ControllerLayout
      action={<BlockControllerActionTimeRemaining remainingSec={time} />}
    >
      <div></div>
    </ControllerLayout>
  );
}

function GameEnd(props: ControllerProps<InstructionBlock>): JSX.Element {
  const handleEndBlock = () => {
    props.onEndBlock();
  };

  return (
    <ControllerLayout
      action={
        <BlockControllerActionButton
          onClick={handleEndBlock}
          icon={FilledSquareIcon}
        >
          End Block
        </BlockControllerActionButton>
      }
    >
      <div></div>
    </ControllerLayout>
  );
}

export function InstructionBlockController(
  props: ControllerProps<InstructionBlock>
): JSX.Element | null {
  const gameSessionStatus =
    useGameSessionStatus<InstructionBlockGameSessionStatus>();

  if (gameSessionStatus === undefined || gameSessionStatus === null)
    return null;

  switch (gameSessionStatus) {
    case InstructionBlockGameSessionStatus.LOADED:
      return <Loaded {...props} />;
    case InstructionBlockGameSessionStatus.GAME_INIT:
      return <Init {...props} />;
    case InstructionBlockGameSessionStatus.GAME_START:
      return <GameInProgress {...props} />;
    case InstructionBlockGameSessionStatus.GAME_END:
      return <GameEnd {...props} />;
    case InstructionBlockGameSessionStatus.END:
    case null:
    case undefined:
      break;
    default:
      assertExhaustive(gameSessionStatus);
      break;
  }

  return null;
}
