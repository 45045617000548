export function GreenScreenIcon(props: { className?: string }): JSX.Element {
  return (
    <svg
      className={props.className ?? 'w-6.5 h-6.5 fill-current'}
      viewBox='0 0 14 14'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M13 12V2C13 1.44772 12.5523 1 12 1H2C1.44772 1 1 1.44772 1 2V12C1 12.5523 1.44772 13 2 13H12C12.5523 13 13 12.5523 13 12ZM2 11.6464L2 8.85355L4.34738 6.50616C4.44256 7.00783 4.67653 7.4601 5.00732 7.82174C4.64785 8.2916 4.41509 8.85941 4.25594 9.39049L2 11.6464ZM4.03072 10.3228L2.35354 12H3.79569L3.80616 11.9058C3.85289 11.4851 3.91585 10.9184 4.03072 10.3228ZM9.5 12C9.47684 11.7916 9.45047 11.5542 9.41685 11.3C9.28592 10.3096 9.04513 9.06306 8.45672 8.27357C8.30624 8.07166 8.13301 7.89964 7.93307 7.76945C8.14994 7.65486 8.3432 7.50156 8.50366 7.31875C8.81266 6.9667 9 6.50523 9 6C9 4.89543 8.10457 4 7 4C5.89543 4 5 4.89543 5 6C5 6.50523 5.18734 6.9667 5.49634 7.31875C5.65679 7.50156 5.85005 7.65486 6.06692 7.76945C5.86698 7.89964 5.69376 8.07166 5.54327 8.27357C4.95486 9.06307 4.71407 10.3096 4.58314 11.3C4.54953 11.5542 4.52315 11.7916 4.5 12L9.5 12ZM10.1474 11.499L12 9.64644V6.35355L9.5421 8.81146C9.63892 9.05052 9.71759 9.29185 9.78271 9.52376C9.97565 10.2108 10.0792 10.9286 10.1474 11.499ZM9.31723 8.32922L12 5.64645V2.35354L9.46248 4.89105C9.61507 5.22937 9.7 5.60476 9.7 6C9.7 6.70257 9.43156 7.34193 8.99267 7.82174C9.11479 7.98135 9.22228 8.15227 9.31723 8.32922ZM9.205 4.44143L11.6464 2H8.85355L7.50618 3.34737C8.20522 3.47995 8.80887 3.88202 9.205 4.44143ZM6.84189 3.30455L8.14644 2H5.35354L2 5.35354L2 8.14644L4.30455 5.84189C4.38338 4.47663 5.47663 3.38338 6.84189 3.30455ZM10.3535 12H12V10.3535L10.3535 12ZM4.64643 2H2L2 4.64643L4.64643 2Z'
      />
    </svg>
  );
}
