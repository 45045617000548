import { isServerValue } from '@lp-lib/firebase-typesafe';

import { getFeatureQueryParamNumber } from '../../../hooks/useFeatureQueryParam';
import logger from '../../../logger/logger';
import { Clock } from '../../Clock';
import { type CloudHostingConfig, type OnDGameController } from './types';

export const log = logger.scoped('ond-game-controller');

export class CloudHostingUtils {
  static GetDefaultConfig(): CloudHostingConfig {
    return {
      launchTimeout: getFeatureQueryParamNumber('cloud-hosting-launch-timeout'),
      heartbeatInterval: getFeatureQueryParamNumber(
        'cloud-hosting-controller-heartbeat'
      ),
      heartbeatTimeout:
        getFeatureQueryParamNumber('cloud-hosting-controller-heartbeat') * 3,
      getTimeout: 10000,
      healthCheckInternal: getFeatureQueryParamNumber(
        'cloud-hosting-health-check-interval'
      ),
      coordinator: {
        callTimeout: getFeatureQueryParamNumber(
          'cloud-hosting-coordinator-call-timeout'
        ),
        checkInterval: getFeatureQueryParamNumber(
          'cloud-hosting-coordinator-check-interval'
        ),
      },
    };
  }
  static NoHeartbeat(
    controller: OnDGameController,
    timeoutMs = CloudHostingUtils.GetDefaultConfig().heartbeatTimeout,
    now = Clock.instance().now
  ): boolean {
    return (
      !isServerValue(controller.pingedAt) &&
      now() - controller.pingedAt > timeoutMs
    );
  }
}
