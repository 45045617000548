import { proxy } from 'valtio';

import { RTDBServerValueTIMESTAMP } from '@lp-lib/firebase-typesafe';
import type { Logger } from '@lp-lib/logger-base';

import {
  markSnapshottable,
  type Snapshot,
  ValtioUtils,
} from '../../../../../../utils/valtio';
import {
  type FirebaseService,
  FirebaseValueHandle,
} from '../../../../../Firebase';
import { type OptionMap, type Poll, type VoteMap } from './types';

function makeRootHandle<E = unknown>(
  svc: FirebaseService,
  venueId: string,
  pollId: string
): FirebaseValueHandle<Poll<E>> {
  return new FirebaseValueHandle(
    svc.prefixedSafeRef(`polls/${venueId}/${pollId}`)
  );
}

function makeOptionsHandle<E = unknown>(
  svc: FirebaseService,
  venueId: string,
  pollId: string
): FirebaseValueHandle<Poll<E>['options']> {
  return new FirebaseValueHandle(
    svc.prefixedSafeRef(`polls/${venueId}/${pollId}/options`)
  );
}

function makeVotesHandle(
  svc: FirebaseService,
  venueId: string,
  pollId: string
): FirebaseValueHandle<Poll['votes']> {
  return new FirebaseValueHandle(
    svc.prefixedSafeRef(`polls/${venueId}/${pollId}/votes`)
  );
}

export class PollControlAPI {
  constructor(
    venueId: string,
    pollId: string,
    svc: FirebaseService,
    private log: Logger,
    private rootHandle = makeRootHandle(svc, venueId, pollId),
    private votesHandle = makeVotesHandle(svc, venueId, pollId)
  ) {}

  async init<E = unknown>(options: OptionMap<E>) {
    this.log.info('init', { options });
    await this.rootHandle.set({
      options: options as never,
      votes: null as never,
    });
  }

  async reset() {
    this.log.info('reset');
    await this.rootHandle.remove();
  }

  async getVoteMap(): Promise<Nullable<VoteMap>> {
    return await this.votesHandle.get();
  }
}

export type PollSharedState<E = unknown> = {
  options: Poll<E>['options'];
  votes: Poll<E>['votes'];
};

export class PollSharedAPI<E = unknown> {
  private _state;

  constructor(
    venueId: string,
    pollId: string,
    svc: FirebaseService,
    private log: Logger,
    private optionsHandle = makeOptionsHandle<E>(svc, venueId, pollId),
    private votesHandle = makeVotesHandle(svc, venueId, pollId)
  ) {
    this._state = markSnapshottable(
      proxy<PollSharedState>(this.initialState())
    );
  }

  get state() {
    return this._state;
  }

  on(): void {
    this.optionsHandle.on((val) =>
      ValtioUtils.set(this._state, 'options', val)
    );
    this.votesHandle.on((val) => ValtioUtils.set(this._state, 'votes', val));
  }

  off(): void {
    this.optionsHandle.off();
    this.votesHandle.off();
  }

  reset() {
    ValtioUtils.reset(this._state, this.initialState());
  }

  async vote(optionId: string, voter: { uid: string; teamId: string }) {
    this.log.info('vote', { optionId, voter });
    return await this.votesHandle.ref.child(voter.uid).set({
      uid: voter.uid,
      teamId: voter.teamId,
      optionId,
      timestamp: RTDBServerValueTIMESTAMP,
    });
  }

  getMyVote(
    snap: Snapshot<PollSharedState> = this._state,
    uid: string | null | undefined
  ) {
    if (!uid) return undefined;
    return snap.votes?.[uid];
  }

  private initialState(): PollSharedState {
    return {
      options: null,
      votes: null,
    };
  }
}
