import shuffle from 'lodash/shuffle';

import { getStaticAssetPath } from '@lp-lib/email-templates/src/utils';
import {
  Delimited,
  type Media,
  MediaType,
  type RoundRobinQuestion,
  type RoundRobinQuestionBlock,
} from '@lp-lib/game';

import logger from '../../../../logger/logger';
import { type TeamId, type TeamMember } from '../../../../types';
import { InfiniteGenerator } from '../../../../utils/generator';
import { MediaUtils } from '../../../../utils/media';
import { ondTemporaryStageMedia } from '../../ondTemporaryStage';
import {
  roundRobinQuestionNoTimer,
  roundRobinQuestionNoTimerMaxSec,
} from './RoundRobinQuestionBlockEditor';
import {
  type MultipleChoiceAnswer,
  type Question,
  type QuestionMap,
  QuestionStatus,
} from './types';

export const log = logger.scoped('round-robin-question');

export class RoundRobinQuestionUtils {
  static GetFBRootPath(venueId: string): string {
    return `round-robin-question/${venueId}`;
  }

  static GetFBTeamsPath(venueId: string): string {
    return `${this.GetFBRootPath(venueId)}/teams`;
  }

  static GetFBTeamPath(venueId: string, teamId: string): string {
    return `${this.GetFBTeamsPath(venueId)}/${teamId}`;
  }

  static GetFBPathByTeam(
    venueId: string,
    teamId: TeamId,
    kind: 'questions' | 'progress'
  ): string {
    return `${this.GetFBTeamsPath(venueId)}/${teamId}/${kind}`;
  }

  static GetFBPathQuestion(
    venueId: string,
    teamId: TeamId,
    index: number
  ): string {
    return `${this.GetFBPathByTeam(venueId, teamId, 'questions')}/${index}`;
  }

  static GetFBPathQuestionField(
    venueId: string,
    teamId: TeamId,
    index: number,
    field:
      | 'answers'
      | 'submitterUid'
      | 'grade'
      | 'submittedAnswer'
      | 'submittedAt'
      | 'gainedPoints'
  ): string {
    return `${this.GetFBPathByTeam(
      venueId,
      teamId,
      'questions'
    )}/${index}/${field}`;
  }

  static GetFBPathQuestionClue(
    venueId: string,
    teamId: TeamId,
    questionIndex: number,
    clueIndex: number
  ): string {
    return `${this.GetFBPathByTeam(
      venueId,
      teamId,
      'questions'
    )}/${questionIndex}/clues/${clueIndex}`;
  }

  static GetBackgroundMedia(
    block: RoundRobinQuestionBlock,
    fallback = ondTemporaryStageMedia
  ): Media {
    return block.fields.backgroundMedia ?? fallback;
  }

  static GetGamePlayableQuestions(
    block: RoundRobinQuestionBlock
  ): RoundRobinQuestion[] {
    return block.fields.questions.filter((q) => !!q.question && !!q.answers);
  }

  static GetCorrectAnswerId(q: RoundRobinQuestion): string {
    return `q-${q.createdAt}-correct`;
  }

  static BuildMultipleChoiceAnswers(
    q: RoundRobinQuestion
  ): MultipleChoiceAnswer[] | null {
    if (!q.incorrectAnswers || q.incorrectAnswers.length === 0) return null;
    const answers = q.incorrectAnswers.map((a) => ({ ...a, correct: false }));
    answers.unshift({
      id: this.GetCorrectAnswerId(q),
      text: q.answers,
      correct: true,
    });
    if (!!q.answerOrder?.randomize) return shuffle(answers);
    const orderedAnswerIds = q.answerOrder?.orderedAnswerIds ?? [];
    const orderedAnswers: MultipleChoiceAnswer[] = [];
    for (const answerId of orderedAnswerIds) {
      const answer = answers.find((a) => a.id === answerId);
      if (!answer) continue;
      orderedAnswers.push(answer);
    }
    // in case some answers are missing in orderedAnswerIds
    for (const answer of answers) {
      if (orderedAnswers.includes(answer)) continue;
      orderedAnswers.push(answer);
    }
    return orderedAnswers;
  }

  static ToGamePlayQuestion(q: RoundRobinQuestion, index: number): Question {
    return {
      index,
      question: q.question,
      backgroundMedia: q.backgroundMedia || null,
      questionMedia: q.questionMedia || null,
      questionMediaLoop: q.questionMediaLoop,
      answers: q.answers,
      answerMedia: q.answerMedia || null,
      multipleChoiceAnswers: this.BuildMultipleChoiceAnswers(q),
      timeSec: q.timeSec,
      points: q.points,
      status: QuestionStatus.NotStarted,
      statusChangedAt: Date.now(),
      clueIndex: -1,
      clues:
        q.clues?.map((clue) => {
          return {
            ...clue,
            used: false,
          };
        }) ?? [],
      isReplay: false,
    };
  }

  static CloneReplayQuestion(q: Question, index: number): Question {
    return {
      index: index,
      question: q.question,
      backgroundMedia: q.backgroundMedia || null,
      questionMedia: q.questionMedia || null,
      questionMediaLoop: q.questionMediaLoop,
      answers: q.answers,
      answerMedia: q.answerMedia || null,
      multipleChoiceAnswers: q.multipleChoiceAnswers || null,
      timeSec: q.timeSec,
      points: q.points,
      status: QuestionStatus.NotStarted,
      statusChangedAt: Date.now(),
      clueIndex: -1,
      clues:
        q.clues?.map((clue) => ({
          ...clue,
          used: false,
        })) ?? [],
      isReplay: true,
    };
  }

  static GetGamePlayQuestionMap(block: RoundRobinQuestionBlock): QuestionMap {
    const rawQuestions = this.GetGamePlayableQuestions(block);
    const orderedRawQuestions = block.fields.randomizeQuestions
      ? shuffle(rawQuestions)
      : rawQuestions;

    const mp: QuestionMap = {};
    orderedRawQuestions.forEach((rq, index) => {
      mp[index] = this.ToGamePlayQuestion(rq, index);
    });
    return mp;
  }

  static OrderMembers(
    members: TeamMember[] | null,
    currentMember?: { clientId: string; joinedAt: number }
  ): TeamMember[] {
    if (!members) return [];
    const ordered = members.sort((a, b) => {
      if (a.joinedAt !== b.joinedAt) return a.joinedAt - b.joinedAt;
      return a.id.localeCompare(b.id);
    });
    const index = ordered.findIndex((m) => m.id === currentMember?.clientId);

    if (index === -1) return ordered;
    return [...ordered.slice(index + 1), ...ordered.slice(0, index + 1)];
  }

  static NormalizeAnswer(text: string): string {
    return text.trim().toLocaleLowerCase();
  }

  static CorrectAnswers(question: Question): string[] {
    const delimited = new Delimited();
    return delimited
      .parse(question.answers)
      .map((t) => this.NormalizeAnswer(t))
      .filter((t) => !!t);
  }

  static IsAnswerCorrect(question: Question, submittedText: string): boolean {
    const normalizedSubmittedText = this.NormalizeAnswer(submittedText);
    const normalizedAnswers = this.CorrectAnswers(question);
    return normalizedAnswers.includes(normalizedSubmittedText);
  }

  static ShowQuestionDurationSec(question: Question): number {
    if (question.timeSec === roundRobinQuestionNoTimer)
      return roundRobinQuestionNoTimerMaxSec;
    return question.timeSec;
  }

  static ShowAnswerDurationSec(question: Question): number {
    if (!question.answerMedia) return 0;
    if (question.answerMedia.type === MediaType.Image) return 2;
    return MediaUtils.GetAVPlayingDurationSeconds(question.answerMedia);
  }

  static GetAnswerOrder(q: RoundRobinQuestion, answerId: string): number {
    if (q.answerOrder?.randomize) return -1;
    const index = q.answerOrder?.orderedAnswerIds.findIndex(
      (id) => id === answerId
    );
    if (index === undefined || index === -1) return -1;
    return index + 1;
  }

  static ShowRevealAnswer(block: RoundRobinQuestionBlock, question: Question) {
    if (question.status <= QuestionStatus.ShowQuestion) return false;
    // do not reveal answer if replayIncorrectQuestions and answer is incorrect
    if (block.fields.replayIncorrectQuestions && question.grade !== 'Correct')
      return false;
    return true;
  }

  static GameTimeSec(block: RoundRobinQuestionBlock) {
    return block.fields.gameTimeSec || 2 * 60 * 60;
  }
}

type RaceUnitSimpleMedia = {
  thumbnailUrl?: string | null;
  mediaUrl: string;
};

const STATIC_RACE_UNITS: RaceUnitSimpleMedia[] = [
  {
    thumbnailUrl: getStaticAssetPath('images/nyan-cats/easter.png'),
    mediaUrl: getStaticAssetPath('images/nyan-cats/easter.gif'),
  },
  {
    thumbnailUrl: getStaticAssetPath('images/nyan-cats/gb.png'),
    mediaUrl: getStaticAssetPath('images/nyan-cats/gb.gif'),
  },
  {
    thumbnailUrl: getStaticAssetPath('images/nyan-cats/grumpy.png'),
    mediaUrl: getStaticAssetPath('images/nyan-cats/grumpy.gif'),
  },
  {
    thumbnailUrl: getStaticAssetPath('images/nyan-cats/jazz.png'),
    mediaUrl: getStaticAssetPath('images/nyan-cats/jazz.gif'),
  },
  {
    thumbnailUrl: getStaticAssetPath('images/nyan-cats/melon.png'),
    mediaUrl: getStaticAssetPath('images/nyan-cats/melon.gif'),
  },
  {
    thumbnailUrl: getStaticAssetPath('images/nyan-cats/mummy.png'),
    mediaUrl: getStaticAssetPath('images/nyan-cats/mummy.gif'),
  },
  {
    thumbnailUrl: getStaticAssetPath('images/nyan-cats/newyear.png'),
    mediaUrl: getStaticAssetPath('images/nyan-cats/newyear.gif'),
  },
  {
    thumbnailUrl: getStaticAssetPath('images/nyan-cats/original.png'),
    mediaUrl: getStaticAssetPath('images/nyan-cats/original.gif'),
  },
  {
    thumbnailUrl: getStaticAssetPath('images/nyan-cats/pikanyan.png'),
    mediaUrl: getStaticAssetPath('images/nyan-cats/pikanyan.gif'),
  },
  {
    thumbnailUrl: getStaticAssetPath('images/nyan-cats/pumpkin.png'),
    mediaUrl: getStaticAssetPath('images/nyan-cats/pumpkin.gif'),
  },
  {
    thumbnailUrl: getStaticAssetPath('images/nyan-cats/vday.png'),
    mediaUrl: getStaticAssetPath('images/nyan-cats/vday.gif'),
  },
];

export class SpriteGenerator extends InfiniteGenerator<RaceUnitSimpleMedia> {
  constructor(source = STATIC_RACE_UNITS) {
    super(source, 'random');
  }
}
