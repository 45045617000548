import { useEffect } from 'react';

import { type TeamId } from '../../../../../types';
import { FloatBoard } from '../../../../Layout';
import { useMyTeamId } from '../../../../Player';
import { useSoundEffect } from '../../../../SFX';
import { type TeamScore } from '../hooks';

export function GamePlayResult(props: {
  teamScores: TeamScore[];
  renderDetail?: (teamId: TeamId) => JSX.Element;
}) {
  const { teamScores, renderDetail } = props;
  const myTeamId = useMyTeamId();
  const { play } = useSoundEffect('showResults');

  useEffect(() => {
    play();
  }, [play]);

  return (
    <FloatBoard
      containerZIndex='z-20'
      containerDisplay='flex'
      bgStyle='border-2 border-cyan rounded-xl bg-black bg-opacity-60'
      title='GAME RESULTS'
    >
      <div className='w-full h-full pt-10 pb-20 px-16 overflow-y-auto scrollbar flex flex-col'>
        {teamScores.map((s) => {
          return (
            <div
              key={s.team.id}
              className={`w-full animate-fade-in-up min-h-12 mb-4 pl-6 rounded-3xl bg-black bg-opacity-95 flex flex-row justify-between items-center ${
                s.team.id === myTeamId ? 'text-tertiary' : 'text-white'
              }`}
            >
              <p className={`text-lg font-bold font-cairo`}>{s.team.name}</p>
              <div className='flex flex-row justify-center items-center'>
                {renderDetail && (
                  <p className='text-3xs font-bold'>
                    {renderDetail(s.team.id)}
                  </p>
                )}
                <div className='w-11 h-11 mr-0.75 ml-3 rounded-full bg-warning text-black flex flex-col justify-center items-center'>
                  <div
                    className={`font-extrabold ${
                      s.currentScore > 1000 ? ' text-sm' : 'text-xl'
                    } leading-5 font-cairo`}
                  >
                    {s.currentScore}
                  </div>
                  <p className='text-3xs text-black'>pts</p>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </FloatBoard>
  );
}
