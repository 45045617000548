import { Fragment, type ReactNode, useLayoutEffect, useMemo } from 'react';

import { type HeadToHeadBlock } from '@lp-lib/game';

import { useLiveCallback } from '../../../../hooks/useLiveCallback';
import { TimeUtils } from '../../../../utils/time';
import { useLastJoinedParticipantByUserId } from '../../../Player';
import { useGameSessionLocalTimer } from '../../hooks';
import { countdownV2, resetTimer, setTimer } from '../../store';
import {
  useCurrentHeadToHeadGameCard,
  useHeadToHeadGameProgress,
} from './HeadToHeadBlockProvider';
import { type H2HRoundProgress } from './types';
import { HeadToHeadUtils } from './utils';

export function useSyncLocalTimer(block: HeadToHeadBlock, enabled: boolean) {
  const card = useCurrentHeadToHeadGameCard();
  const progress = useHeadToHeadGameProgress();

  const setSubTimer = useLiveCallback(async (start: boolean) => {
    await resetTimer('submission');
    setTimer('submission', block.fields.subTimeSec);
    if (start) {
      await countdownV2({
        debug: 'HeadToHeadBlockGamePlay',
        startTimeWorker: true,
        flushCountingStatus: false,
      });
    }
  });

  const resetSubTimer = useLiveCallback(async () => {
    await resetTimer('submission');
  });

  const hash = card
    ? HeadToHeadUtils.TurnsMode(block)
      ? `turns:${card.id}:${progress.currentTurn}`
      : `debate:${progress.initedAt}`
    : undefined;

  useLayoutEffect(() => {
    if (!enabled || !hash) return;
    setSubTimer(progress.currentCardPhase === 'revealed');
    return () => {
      resetSubTimer();
    };
  }, [enabled, hash, progress.currentCardPhase, resetSubTimer, setSubTimer]);
}

function PinkArrow() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='15'
      height='32'
      viewBox='0 0 15 32'
      fill='none'
    >
      <path
        d='M0.566946 16.6547C0.241518 16.2789 0.241518 15.7211 0.566946 15.3453L13.2441 0.707061C13.8502 0.00715577 15 0.435829 15 1.36171L15 30.6383C15 31.5642 13.8502 31.9928 13.2441 31.2929L0.566946 16.6547Z'
        fill={HeadToHeadUtils.GroupColor('groupA')}
      />
    </svg>
  );
}

function BlueArrow() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='15'
      height='32'
      viewBox='0 0 15 32'
      fill='none'
    >
      <path
        d='M14.4331 15.3453C14.7585 15.7211 14.7585 16.2789 14.4331 16.6547L1.75593 31.2929C1.14979 31.9928 -1.55456e-06 31.5642 -1.51409e-06 30.6383L-2.34368e-07 1.36172C-1.93896e-07 0.435833 1.14979 0.0071591 1.75593 0.707063L14.4331 15.3453Z'
        fill={HeadToHeadUtils.GroupColor('groupB')}
      />
    </svg>
  );
}

function TurnIndicator(props: {
  targetTurn: 'groupA' | 'groupB';
  currentTurn: H2HRoundProgress['currentTurn'];
  enabled: boolean;
}) {
  const { targetTurn, currentTurn, enabled } = props;
  const n = 2;
  const arrow = targetTurn === 'groupA' ? <PinkArrow /> : <BlueArrow />;
  const arrows = new Array<ReactNode>(n).fill(arrow);

  const visible = enabled && targetTurn === currentTurn;

  return (
    <div
      className={`flex items-center justify-center ${
        visible ? 'visible' : 'invisible'
      }`}
    >
      {arrows.map((a, i) => (
        <Fragment key={i}>{a}</Fragment>
      ))}
    </div>
  );
}

export function GameTimer(props: { block: HeadToHeadBlock }) {
  const { block } = props;
  const totalTime = block.fields.subTimeSec;
  const localTimer = useGameSessionLocalTimer();
  const progress = useHeadToHeadGameProgress();
  const currentTurn = progress.currentTurn;
  const revealed = progress.currentCardPhase === 'revealed';
  const remainingTime = progress.roundPhase === 'playing' ? localTimer ?? 0 : 0;
  const percentage = totalTime > 0 ? remainingTime / totalTime : 1;
  const warn = HeadToHeadUtils.TimeWarn(percentage);
  const turnsMode = HeadToHeadUtils.TurnsMode(block);
  const singleMode = HeadToHeadUtils.SingleMode(block);
  const p = useLastJoinedParticipantByUserId(
    currentTurn === 'groupA'
      ? progress.currentAGroupId
      : currentTurn === 'groupB'
      ? progress.currentBGroupId
      : ''
  );

  const progressColor = useMemo(() => {
    // card is not revealed yet
    if (!revealed) return '#01ACC4';
    if (warn) return '#FF0935';
    // not turn, debate mode
    if (!currentTurn) return '#01ACC4';
    return HeadToHeadUtils.GroupColor(currentTurn);
  }, [currentTurn, revealed, warn]);

  if (localTimer === null) return;

  const displayTime =
    totalTime > 120
      ? TimeUtils.DurationFormattedHHMMSS(remainingTime * 1000)
      : remainingTime;

  return (
    <div className='w-full h-10 flex items-center justify-center gap-1'>
      <TurnIndicator
        targetTurn='groupA'
        currentTurn={currentTurn}
        enabled={revealed}
      />
      <div className='w-full h-full max-w-85 relative border border-black rounded-1.5lg overflow-hidden'>
        <div className='w-full h-full bg-main-layer absolute rounded-1.5lg'></div>
        <div
          className='absolute inset-0 h-full rounded-1.5lg transform-gpu transition-size'
          style={{
            backgroundColor: progressColor,
            width: `${percentage * 100}%`,
          }}
        />
        {turnsMode && singleMode && p && revealed && (
          <div className='w-full h-full flex items-center justify-center absolute text-xl font-bold'>
            {p?.firstName || p?.username || 'N/A'}’s Turn
          </div>
        )}
        {totalTime > 0 && (
          <div className='w-full h-full flex items-center justify-between absolute px-2'>
            <p className='text-sms font-bold'>Timer</p>
            <p className='text-sms font-bold'>{displayTime}</p>
          </div>
        )}
      </div>
      <TurnIndicator
        targetTurn='groupB'
        currentTurn={currentTurn}
        enabled={revealed}
      />
    </div>
  );
}
