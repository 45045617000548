import { useEffect, useMemo, useRef } from 'react';
import { usePrevious } from 'react-use';
import { proxy, useSnapshot } from 'valtio';

import {
  assertExhaustive,
  type IcebreakerBlock,
  IcebreakerBlockGameSessionStatus,
  IcebreakerMode,
  IcebreakerOnStageTimerMode,
} from '@lp-lib/game';

import { markSnapshottable, ValtioUtils } from '../../../../utils/valtio';
import { useGainPointsAnimationGamePlayTrigger } from '../../../GainPointsAnimation/useGainPointsAnimationGamePlayTrigger';
import { FloatBoard, FloatLayout } from '../../../Layout';
import { LayoutAnchor } from '../../../LayoutAnchors/LayoutAnchors';
import { useSyncPersistentPointsRevealAnswer } from '../../../PersistentPoints/Provider';
import { useLastJoinedParticipantByUserId, useMyTeamId } from '../../../Player';
import { useSoundEffect } from '../../../SFX';
import { GoAnimation } from '../../GameBlockCardAnimations';
import { useGameSessionLocalTimer, useGameSessionStatus } from '../../hooks';
import { countdownV2, resetTimer, setTimer } from '../../store';
import { AutoScale } from '../Common/GamePlay/ContainLayout';
import { GamePlayMediaLayout } from '../Common/GamePlay/GamePlayMediaLayout';
import {
  buildGamePlayMedia,
  type GamePlayMedia,
  GamePlayMediaPlayer,
  type GamePlayMediaPlayerLayout,
  type GamePlayProps,
  useCountdownPlaySFX,
  useGamePlayMediaPlayable,
  useGamePlayMediaUISync,
  useGamePlayUITransitionControl,
} from '../Common/GamePlay/Internal';
import { StageBgSingleFrame } from '../Common/GamePlay/StageBgSingleFrame';
import { useRankedTeamScores, useStableBlock } from '../Common/hooks';
import { BlockKnifeUtils } from '../Shared';
import { IcebreakerPlayground } from './IcebreakerBlockPlayground';
import {
  useAllTeamsProgressData,
  useIcebreakerCards,
  useIcebreakerProgress,
  useIcebreakerSharedAPI,
  useIcebreakerTextFormat,
} from './IcebreakerBlockProvider';
import { type IcebreakerGamePlayCard } from './types';
import { IcebreakerUtils } from './utils';

type IcebreakerGamePlayLocalState = {
  gamePlayMedia: Nullable<GamePlayMedia, false>;
  mediaPlayerLayout: GamePlayMediaPlayerLayout | null;
  mediaPlayerLoop: boolean;
  showResults: boolean;
};

class IcebreakerGamePlayLocalAPI {
  private _state;
  constructor(private block: IcebreakerBlock) {
    this._state = markSnapshottable(
      proxy<IcebreakerGamePlayLocalState>(this.initState())
    );
  }
  async load() {
    await this.reset();
  }
  async initGame() {
    this.updateBackgroundMedia();
  }
  revealResults() {
    this.updateGamePlayMedia(null, undefined);
    if (!this.block.fields.skipGameRecap) {
      this._state.showResults = true;
    }
  }
  hideResults() {
    this._state.showResults = false;
  }
  async reset() {
    ValtioUtils.reset(this._state, this.initState());
  }

  private updateBackgroundMedia() {
    const media = buildGamePlayMedia(
      {
        media: BlockKnifeUtils.Media(this.block, 'backgroundMedia'),
        mediaData: this.block.fields.backgroundMediaData,
      },
      {
        stage: 'custom',
        isBackgroundMedia: true,
      }
    );
    this.updateGamePlayMedia(
      media,
      'fullscreen',
      !!this.block.fields.backgroundMediaData?.loop
    );
  }

  private updateGamePlayMedia(
    media: Nullable<GamePlayMedia, false>,
    layout: GamePlayMediaPlayerLayout,
    loop?: boolean
  ) {
    this.state.gamePlayMedia = media;
    this.state.mediaPlayerLayout = layout;
    this.state.mediaPlayerLoop = !!loop;
  }

  private initState(): IcebreakerGamePlayLocalState {
    return {
      gamePlayMedia: null,
      mediaPlayerLayout: null,
      mediaPlayerLoop: false,
      showResults: false,
    };
  }

  get state() {
    return this._state;
  }
}

function CardResult(props: {
  block: IcebreakerBlock;
  card: IcebreakerGamePlayCard;
}) {
  const player = useLastJoinedParticipantByUserId(props.card.playedBy);
  const format = useIcebreakerTextFormat();

  const showOptions = useMemo(() => {
    // if the card is on stage selection, only show the selected option
    if (
      props.block.fields.onStageCardSelection &&
      props.card.choiceMap &&
      props.card.playedBy
    ) {
      const selectedIndex = props.card.choiceMap[props.card.playedBy];
      const selected = props.card.options[selectedIndex];
      if (selected) {
        return [selected];
      }
    }

    return props.card.options;
  }, [
    props.block.fields.onStageCardSelection,
    props.card.choiceMap,
    props.card.options,
    props.card.playedBy,
  ]);

  return (
    <div className='flex'>
      {player && (
        <div className='w-1/4 text-sms font-bold'>{player.username}</div>
      )}
      <div className='flex-1 flex flex-col gap-1'>
        {showOptions.map((o, index) => (
          <p key={index} className='text-sms'>
            {format(o.text, player)}
          </p>
        ))}
      </div>
    </div>
  );
}

function DefaultResults(props: { block: IcebreakerBlock }) {
  const cards = useIcebreakerCards();
  const progress = useIcebreakerProgress();

  const showCards = useMemo(() => {
    if (!IcebreakerUtils.ShouldSelectOnStagePlayer(props.block))
      return cards.slice(0, progress.currentCardIndex + 1);
    return cards.filter((card) => !!card.playedBy);
  }, [cards, progress.currentCardIndex, props.block]);

  return (
    <FloatBoard
      containerZIndex='z-20'
      containerDisplay='flex'
      bgStyle='border-2 border-cyan rounded-xl bg-black bg-opacity-60'
      title='GAME RECAP'
    >
      <div className='w-full h-full pt-15 pb-20 px-20 overflow-y-auto scrollbar'>
        <p className='font-bold text-xl text-center'>
          Here’s a quick summary of the content you just played through!
        </p>
        <div className='mt-9 flex flex-col gap-4'>
          {showCards.map((c) => (
            <CardResult key={c.id} block={props.block} card={c} />
          ))}
        </div>
      </div>
    </FloatBoard>
  );
}

function HeadsUpResults(): JSX.Element | null {
  const teamScores = useRankedTeamScores('currentScore', null, 'always');
  const myTeamId = useMyTeamId();
  const summary = useAllTeamsProgressData();

  const { play } = useSoundEffect('showResults');
  useEffect(() => {
    play();
  }, [play]);

  return (
    <>
      <FloatBoard
        containerZIndex='z-20'
        containerDisplay='flex'
        bgStyle='border-2 border-cyan rounded-xl bg-black bg-opacity-60'
        title='GAME RESULTS'
      >
        <div className='w-full h-full pt-10 pb-20 px-16 overflow-y-auto scrollbar flex flex-col'>
          {teamScores.map((s) => {
            const numFoundItems = summary[s.team.id]?.numCorrect ?? 0;
            return (
              <div
                key={s.team.id}
                className={`w-full animate-fade-in-up min-h-12 mb-4 pl-6 rounded-3xl bg-black bg-opacity-95 flex flex-row justify-between items-center ${
                  s.team.id === myTeamId ? 'text-tertiary' : 'text-white'
                }`}
              >
                <p className={`text-lg font-bold font-cairo`}>{s.team.name}</p>
                <div className='flex flex-row justify-center items-center'>
                  <p className='text-3xs font-bold'>{numFoundItems} Correct</p>
                  <div className='w-11 h-11 mr-0.75 ml-3 rounded-full bg-warning text-black flex flex-col justify-center items-center'>
                    <div className='font-extrabold text-xl leading-5 font-cairo'>
                      {s.currentScore}
                    </div>
                    <p className='text-3xs text-black'>pts</p>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </FloatBoard>
      <FloatLayout className='flex items-center justify-center'>
        <LayoutAnchor
          id='gameplay-points-animation-top'
          className='w-full max-w-4xl'
        />
      </FloatLayout>
    </>
  );
}

function Results(props: { block: IcebreakerBlock }) {
  if (props.block.fields.mode === IcebreakerMode.HeadsUp) {
    return <HeadsUpResults />;
  } else {
    return <DefaultResults {...props} />;
  }
}

export function IcebreakerBlockGamePlay(
  props: GamePlayProps<IcebreakerBlock>
): JSX.Element | null {
  const block = useStableBlock(props.block);
  const gss = useGameSessionStatus<IcebreakerBlockGameSessionStatus>();
  const prevGss = usePrevious(gss);

  const [uiState, uiControl] = useGamePlayUITransitionControl();
  const control = useMemo(() => {
    return new IcebreakerGamePlayLocalAPI(block);
  }, [block]);
  const state = useSnapshot(control.state);

  useEffect(() => {
    if (prevGss === gss) return;

    switch (gss) {
      case IcebreakerBlockGameSessionStatus.LOADED:
        control.load();
        break;
      case IcebreakerBlockGameSessionStatus.GAME_INIT:
        control.initGame();
        break;
      case IcebreakerBlockGameSessionStatus.GAME_START:
        break;
      case IcebreakerBlockGameSessionStatus.RESULTS:
        control.revealResults();
        break;
      case IcebreakerBlockGameSessionStatus.END:
        control.hideResults();
        break;
      case null:
      case undefined:
        break;
      default:
        assertExhaustive(gss);
        break;
    }
  });

  const { onMediaEnded, onMediaReplaying } = useGamePlayMediaUISync({
    block,
    gameSessionStatus: gss,
    media: state.gamePlayMedia,
    state: uiState,
    control: uiControl,
  });

  const mediaPlayable = useGamePlayMediaPlayable({
    block,
    gameSessionStatus: gss,
    media: state.gamePlayMedia,
    state: uiState,
    // This is only used for background media.
    // Play the background media when the game started
    custom: () => !!gss && gss === IcebreakerBlockGameSessionStatus.GAME_START,
  });

  useSyncPersistentPointsRevealAnswer(state.showResults);
  useGainPointsAnimationGamePlayTrigger();

  const fullscreen = state.mediaPlayerLayout === 'fullscreen';
  const inGame =
    !!gss &&
    gss >= IcebreakerBlockGameSessionStatus.GAME_INIT &&
    gss <= IcebreakerBlockGameSessionStatus.GAME_START;

  useIcebreakerGamePlayTimers(block, inGame);

  return (
    <div className='fixed w-screen h-screen text-white'>
      {state.gamePlayMedia && state.mediaPlayerLayout && (
        <StageBgSingleFrame gamePlayMedia={state.gamePlayMedia} />
      )}
      {uiState.playGoAnimation && <GoAnimation />}
      <GamePlayMediaLayout fullscreen={fullscreen}>
        {state.gamePlayMedia && state.mediaPlayerLayout && (
          <GamePlayMediaPlayer
            gamePlayMedia={state.gamePlayMedia}
            play={mediaPlayable}
            mode={fullscreen || !uiState.mediaEndEffect ? 'full' : 'small'}
            onMediaEnded={!fullscreen ? onMediaEnded : undefined}
            onMediaReplaying={!fullscreen ? onMediaReplaying : undefined}
            layout={state.mediaPlayerLayout}
            loop={state.mediaPlayerLoop}
          />
        )}
      </GamePlayMediaLayout>

      {inGame && (
        <FloatLayout className='flex items-center justify-center'>
          <AutoScale>
            <IcebreakerPlayground block={block} />
          </AutoScale>
        </FloatLayout>
      )}

      {state.showResults && <Results block={block} />}
    </div>
  );
}

function useIcebreakerGamePlayTimers(block: IcebreakerBlock, inGame: boolean) {
  const api = useIcebreakerSharedAPI();
  const progress = useSnapshot(api.state).progress;
  const currentTimer = useRef<{
    uid?: string;
    cardIndex: number;
    timestamp: number | null;
  } | null>(null);
  useIcebreakerGamePlayTimerSFX(block, inGame);

  useEffect(() => {
    if (block.fields.onStageTimeSec === 0) return;
    if (
      block.fields.onStageTimerMode === IcebreakerOnStageTimerMode.PlayerReset
    ) {
      // reset the timer when the current player changes
      if (
        currentTimer.current &&
        currentTimer.current.uid === progress.currentPlayerUid &&
        currentTimer.current.timestamp === progress.readyAt
      )
        return;
      if (!progress.currentPlayerUid) return;

      if (
        !currentTimer.current ||
        currentTimer.current.uid !== progress.currentPlayerUid
      ) {
        resetTimer('submission');
        setTimer('submission', block.fields.onStageTimeSec);
        currentTimer.current = {
          uid: progress.currentPlayerUid,
          cardIndex: progress.currentCardIndex,
          timestamp: null,
        };
      }
    } else if (
      block.fields.onStageTimerMode === IcebreakerOnStageTimerMode.CardReset
    ) {
      // reset the timer when the current card changes
      if (
        !currentTimer.current ||
        currentTimer.current.cardIndex !== progress.currentCardIndex
      ) {
        resetTimer('submission');
        setTimer('submission', block.fields.onStageTimeSec);
        currentTimer.current = {
          uid: progress.currentPlayerUid,
          cardIndex: progress.currentCardIndex,
          timestamp: null,
        };
      }
    } else {
      return;
    }

    if (!inGame) return;

    if (block.fields.onStageTimerAutoStart) {
      if (currentTimer.current && !currentTimer.current.timestamp) {
        currentTimer.current.timestamp = Date.now();
        countdownV2({
          debug: 'IcebreakerBlockOnStageTimer',
          startTimeWorker: true,
          flushCountingStatus: true,
        });
      }
    } else {
      if (
        progress.readyAt &&
        currentTimer.current.timestamp !== progress.readyAt
      ) {
        currentTimer.current.timestamp = progress.readyAt;
        countdownV2({
          debug: 'IcebreakerBlockOnStageTimer',
          startTimeWorker: true,
          flushCountingStatus: true,
        });
      }
    }
  }, [
    block.fields.onStageTimeSec,
    block.fields.onStageTimerAutoStart,
    block.fields.onStageTimerMode,
    inGame,
    progress.currentCardIndex,
    progress.currentPlayerUid,
    progress.readyAt,
  ]);
}

function useIcebreakerGamePlayTimerSFX(
  block: IcebreakerBlock,
  inGame: boolean
) {
  const time = useGameSessionLocalTimer();
  useCountdownPlaySFX(block.fields.onStageTimeSec, time, inGame);
}
