import { TEAM_COLORS } from '../../types';
import { InfiniteGenerator } from '../../utils/generator';

const colors = [
  'Adventurous',
  'Affectionate',
  'Agreeable',
  'Ambitious',
  'Amusing',
  'Aquatic',
  'Artistic',
  'Brave',
  'Bright',
  'Calm',
  'Careful',
  'Charismatic',
  'Charming',
  'Cheerful',
  'Clever',
  'Colorful',
  'Courageous',
  'Creative',
  'Decisive',
  'Dependable',
  'Diligent',
  'Diplomatic',
  'Dynamic',
  'Efficient',
  'Energetic',
  'Enthusiastic',
  'Exuberant',
  'Fearless',
  'Fierce',
  'Flying',
  'Friendly',
  'Funny',
  'Generous',
  'Gentle',
  'Giving',
  'Graceful',
  'Gregarious',
  'Helpful',
  'Hilarious',
  'Honest',
  'Humorous',
  'Imaginative',
  'Industrious',
  'Intellectual',
  'Intelligent',
  'Inventive',
  'Joyful',
  'Kind',
  'Kooky',
  'Loving',
  'Loyal',
  'Lucky',
  'Playful',
  'Shy',
  'Silly',
  'Sincere',
  'Smart',
  'Speedy',
  'Sympathetic',
  'Talkative',
  'Thoughtful',
  'Tidy',
  'Tough',
  'Upbeat',
  'Versatile',
  'Wild',
  'Wise',
  'Witty',
];
const animals = [
  'Badgers',
  'Bats',
  'Bears',
  'Bees',
  'Buffalo',
  'Camels',
  'Cats',
  'Cobras',
  'Crocodiles',
  'Crows',
  'Dogs',
  'Dolphins',
  'Donkeys',
  'Eagles',
  'Elephants',
  'Elk',
  'Falcons',
  'Ferrets',
  'Flamingos',
  'Foxes',
  'Frogs',
  'Geese',
  'Giraffes',
  'Hippopotami',
  'Hyenas',
  'Jaguars',
  'Jellyfish',
  'Kangaroos',
  'Lemurs',
  'Leopards',
  'Lions',
  'Mice',
  'Moles',
  'Mules',
  'Otters',
  'Oxen',
  'Owls',
  'Parrots',
  'Porcupines',
  'Rabbits',
  'Ravens',
  'Rhinoceroses',
  'Sharks',
  'Snakes',
  'Squirrels',
  'Stingrays',
  'Swans',
  'Tigers',
  'Toads',
  'Turkeys',
  'Turtles',
  'Weasels',
  'Wolves',
  'Zebras',
];

export class NameGenerator {
  private firstNameGenerator;
  private lastNameGenerator;
  constructor(source1 = colors, source2 = animals) {
    this.firstNameGenerator = new InfiniteGenerator(source1, 'random');
    this.lastNameGenerator = new InfiniteGenerator(source2, 'random');
  }

  generate(): string {
    const firstName = this.firstNameGenerator.generate();
    const lastName = this.lastNameGenerator.generate();
    return `${firstName} ${lastName}`;
  }
  empty(): boolean {
    return this.firstNameGenerator.empty() || this.lastNameGenerator.empty();
  }
}

export class ColorGenerator extends InfiniteGenerator<string> {
  constructor(source = TEAM_COLORS) {
    super(source, 'sequential');
  }
}
