export const Control = (props: {
  text: string;
  info: string | React.ReactNode;
  icon?: React.ReactNode;
  disabled?: boolean;
  children?: React.ReactNode;
  hover?: boolean;
  margin?: string;
  padding?: string;
}): JSX.Element => {
  return (
    <div
      className={`w-full flex flex-col items-center first:mt-0 last:mb-0 
      ${props.margin ? props.margin : 'mt-4 mb-4'} 
      ${props.padding ? props.padding : 'p-4'} 
      ${props.hover ? 'hover:bg-lp-gray-002' : ''}`}
    >
      <div className='w-full flex justify-between'>
        <div className='flex flex-col w-3/4'>
          <div
            className={`flex items-center text-2xs ${
              props.disabled ? 'text-icon-gray' : 'text-white'
            }`}
          >
            {props.icon && <div className='w-4 h-4 mr-2'>{props.icon}</div>}
            <span>{props.text}</span>
          </div>
          {props.info && (
            <div className='mt-2 text-3xs text-icon-gray'>{props.info}</div>
          )}
        </div>
        {props.children}
      </div>
    </div>
  );
};
