export function GamePlaySubmitButton(
  props: React.ButtonHTMLAttributes<HTMLButtonElement> & {
    style?: Partial<{
      skew: string;
    }>;
  }
): JSX.Element {
  return (
    <button
      type='button'
      className={`
        w-auto h-9 px-5 bg-black text-white font-cairo font-extrabold
        rounded border border-primary-002
        transform ${props.style?.skew ?? '-skew-x-12'}
        hover:bg-gradient-to-tr hover:from-red-001 hover:via-white
        hover:to-blue-001 hover:text-black
        appearance-none outline-none focus:outline-none
        disabled:cursor-auto disabled:pointer-events-off
        disabled:bg-dark-gray disabled:text-secondary
        disabled:border-black
        shadow-gamecard-btn disabled:shadow-none 
        active:shadow-none
      `}
      {...props}
    />
  );
}

// unskews text for the gameplay submit button.
export function GamePlaySubmitButtonText(props: {
  children: React.ReactNode;
  style?: Partial<{
    gradient: string;
    skew: string;
  }>;
}): JSX.Element {
  return (
    <p
      className={`
        text-transparent bg-black bg-clip-text hover:bg-none
        ${
          props.style?.gradient ??
          'bg-gradient-to-r from-gamecard-btn-start via-white to-gamecard-btn-end'
        }
        transform ${props.style?.skew ?? 'skew-x-12'}
      `}
    >
      {props.children}
    </p>
  );
}
