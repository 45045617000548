import { type Media } from '@lp-lib/game';
import { type ConnectionStatus } from '@lp-lib/shared-schema';

import { type ISO8601String } from './api';

export type VenueOwner = {
  id: string;
  username: string;
  orgId: string | null;
};

export interface Venue {
  id: string;
  name: string | null;
  displayName: string;
  background: Media | null;
  uid: string;
  guestEnabled: boolean;
  createdAt: ISO8601String;
  updatedAt: ISO8601String;
  nameModifiedAt: ISO8601String | null;
  shareControl: boolean;
  coordinatorPolicyOverride: 'everyone' | 'venue-owner' | 'cohost' | null;
}

export enum VenueMode {
  Lobby,
  Game,
}

export type CloudController = {
  id: string;
  kind: 'cloud'; // it can only be cloud
  acquiredAt: number;
  pingedAt: number;
  env: string;
  status: ConnectionStatus;
  disconnectedAt?: number;
};

export type CloudInstanceBinding = {
  id: string;
  state: string;
  controller: CloudController | null;
  createdAt: string;
  updatedAt: string;
};
