import { indicesFromMiddle } from './fromMiddle';

export class RowAllocator<T> {
  private pool = new Set<T>();
  private knownMaxHeapItems = new Set<T>();

  constructor(private perRow: number) {}

  manage(item: T): void {
    const known = this.knownMaxHeapItems.has(item);
    if (known) return;
    this.pool.add(item);
    this.knownMaxHeapItems.add(item);
  }

  forget(item: T): void {
    this.pool.delete(item);
    this.knownMaxHeapItems.delete(item);
  }

  fillAndCompact(row: (T | null)[]): [(T | null)[], 0 | 1] {
    let written: 0 | 1 = 0;

    if (row.length === 0) {
      row.length = this.perRow;
      row.fill(null);
      written |= 1;
    }

    indicesFromMiddle(row, (idx) => {
      const item = row[idx];
      if (item === null) {
        const next = this.pool.values().next().value;
        if (!next) return;
        this.pool.delete(next);
        written |= 1;
        row[idx] = next;
      }
    });

    return [row, written as 0 | 1];
  }

  retainRow(): (T | null)[] {
    const [row] = this.fillAndCompact([]);
    return row;
  }

  emptyRow(): (T | null)[] {
    const row = new Array(this.perRow);
    row.fill(null);
    return row;
  }

  releaseRow(row: (T | null)[]): void {
    for (let i = 0; i < row.length; i++) {
      const item = row[i];
      if (item !== null) this.pool.add(item);
    }
    // purposefully not blanking out the row because it may be needed for
    // effects / exiting / cleanup.
  }
}
