export function AlertIcon(): JSX.Element {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='18'
      height='18'
      fill='none'
      viewBox='0 0 18 18'
    >
      <rect
        width='17'
        height='17'
        x='0.5'
        y='0.5'
        fill='#EE3529'
        stroke='#fff'
        rx='8.5'
      ></rect>
      <path
        fill='#fff'
        d='M9.933 3.364h-1.87l.148 8.29h1.574l.148-8.29zm-.938 11.744a1.14 1.14 0 001.136-1.136c0-.62-.517-1.131-1.136-1.131-.625 0-1.136.511-1.136 1.13 0 .631.511 1.137 1.136 1.137z'
      ></path>
    </svg>
  );
}
