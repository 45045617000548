import { useEffect, useMemo, useState } from 'react';

import {
  assertExhaustive,
  type Media,
  MediaType,
  type MultipleChoiceBlock,
  type MultipleChoiceBlockDetailScore,
  MultipleChoiceGameSessionStatus,
  QuestionBlockAnswerGrade,
} from '@lp-lib/game';

import { type TeamId } from '../../../../types';
import { IMAGE_DURATION_MS, MediaUtils } from '../../../../utils/media';
import { DoubleRightArrow } from '../../../icons/Arrows';
import { PlayIcon } from '../../../icons/PlayIcon';
import { RefreshIcon } from '../../../icons/RefreshIcon';
import { FilledSquareIcon } from '../../../icons/SquareIcon';
import { TimerIcon } from '../../../icons/TimerIcon';
import {
  useDetailScores,
  useGameSessionLocalTimer,
  useGameSessionStatus,
  useIsEndedBlock,
  usePlayedBlockIds,
} from '../../hooks';
import { countdownV2, next, present, triggerBlockTitleAnim } from '../../store';
import {
  BlockControllerActionButton,
  BlockControllerActionNone,
  BlockControllerActionTimeRemaining,
  ControllerLayout,
  type ControllerProps,
  DecreasingPointsBadge,
  type MediaPlayback,
  MediaPreviewer,
  useBlockControllerBlockTitlePlaying,
  useControllerMediaPlayback,
  useControllerMediaPlayText,
} from '../Common/Controller/Internal';
import { PointsBadge } from '../Common/Controller/PointsBadge';
import { useStableBlock } from '../Common/hooks';
import { BlockKnifeUtils } from '../Shared';
import { MCSubmissionsView } from './MultipleChoiceSubmissionsView';

type Props = ControllerProps<MultipleChoiceBlock>;
type PropsWithPlayback = Props & { playback: MediaPlayback };

export function MultipleChoiceBlockTeamProgressSummary(props: {
  block: MultipleChoiceBlock;
  teamId: TeamId;
}) {
  const scores = useDetailScores<MultipleChoiceBlockDetailScore>(
    props.block.id
  );
  const teamScore = useMemo(() => {
    return scores?.[props.teamId];
  }, [scores, props.teamId]);
  return (
    <>
      Submission:{' '}
      {teamScore?.answer ? (
        <>
          {`${
            teamScore.grade === QuestionBlockAnswerGrade.CORRECT ? '✅' : '❌'
          } ${teamScore.answer}`}
        </>
      ) : (
        <em>pending</em>
      )}
    </>
  );
}

const Badges = (props: { block: MultipleChoiceBlock }): JSX.Element => {
  const { block } = props;

  const playedBlockIds = usePlayedBlockIds();
  const gameSessionStatus = useGameSessionStatus();

  if (!block) return <></>;

  const isPlayedBlock = block.id
    ? playedBlockIds.indexOf(block.id) >= 0
    : false;

  const showBadges =
    !isPlayedBlock &&
    (gameSessionStatus === MultipleChoiceGameSessionStatus.LOADED ||
      gameSessionStatus === MultipleChoiceGameSessionStatus.END ||
      !gameSessionStatus);

  return (
    <div className='w-full px-2.5 mb-3 flex flex-row items-center justify-start'>
      <PointsBadge
        block={block}
        disabled={!showBadges}
        getPoints={(block) => block.fields.points}
        setPoints={(block, points) => {
          const copy = JSON.parse(JSON.stringify(block));
          copy.fields.points = points;
          return copy;
        }}
      />
      <DecreasingPointsBadge
        decreasingPointsTimer={block.fields.decreasingPointsTimer}
        disabled={!showBadges}
      />
    </div>
  );
};

function getMediaDurationSeconds(
  media: Media | null,
  hasPointsAnimation: boolean
): number {
  if (!media) return 0;

  switch (media.type) {
    case MediaType.Image:
      return Math.round(IMAGE_DURATION_MS / 1000);
    case MediaType.Video:
    case MediaType.Audio:
      return MediaUtils.GetAVPlayingDurationSeconds(media, hasPointsAnimation);
    default:
      assertExhaustive(media.type);
      return 0;
  }
}

function Loaded(props: PropsWithPlayback): JSX.Element {
  const { selectedBlock: block, playback } = props;
  const { switchMedia, reset: resetPlayback } = playback.api;

  // Note(falcon): the MVP assumes there will always be configured question
  // media. if there is no question media, nothing breaks but the UX is poor.
  const media = BlockKnifeUtils.Media(block, 'questionMedia');
  const hasPointsAnimation = BlockKnifeUtils.DisplaysPointsMultiplier(block);
  const mediaDurationSeconds = getMediaDurationSeconds(
    media,
    !!hasPointsAnimation
  );

  useEffect(() => {
    resetPlayback();
    switchMedia(media, undefined, {
      videoDurationSec: mediaDurationSeconds,
    });
  }, [media, mediaDurationSeconds, switchMedia, resetPlayback]);

  const onPresent = async () => {
    await triggerBlockTitleAnim(block);
    await present(block);
  };

  const label = block.fields.startVideoWithTimer
    ? 'Present Block'
    : media
    ? `Play Question Media (${mediaDurationSeconds}s)`
    : 'Present Question';

  const actionComponent = useBlockControllerBlockTitlePlaying(block) ?? (
    <BlockControllerActionButton onClick={onPresent} icon={PlayIcon}>
      {label}
    </BlockControllerActionButton>
  );

  return (
    <ControllerLayout action={actionComponent}>
      <div className='w-full flex flex-col'>
        <Badges block={block} />
        <MediaPreviewer
          playback={playback}
          alt={block.type}
          text={block.fields.question}
        />
      </div>
    </ControllerLayout>
  );
}

function PresentingQuestion(props: PropsWithPlayback): JSX.Element | null {
  const { selectedBlock: block, playback } = props;
  const mediaActionText = useControllerMediaPlayText(playback);
  const [presentQuestion, setPresentQuestion] = useState(
    !!playback.preview && !block.fields.startVideoWithTimer
  );
  const { play: playMedia } = playback.api;

  useEffect(() => {
    if (!playback.isVideoPlayed) return;
    setPresentQuestion(false);
  }, [playback.isVideoPlayed]);

  useEffect(() => {
    if (!block.fields.startVideoWithTimer) playMedia();
  }, [playMedia, block.fields.startVideoWithTimer]);

  useEffect(() => {
    if (presentQuestion) return;
    next();
  }, [presentQuestion]);

  const action = (
    <BlockControllerActionNone icon={PlayIcon}>
      {mediaActionText}
    </BlockControllerActionNone>
  );

  return (
    <ControllerLayout action={action}>
      <div className='w-full flex flex-col'>
        <Badges block={block} />
        <MediaPreviewer
          playback={playback}
          alt={block.type}
          text={block.fields.question}
        />
      </div>
    </ControllerLayout>
  );
}

function PresentedQuestion(props: PropsWithPlayback): JSX.Element | null {
  const { selectedBlock: block, playback } = props;
  const [starting, setStarting] = useState(false);

  const startSubmissionTimer = async () => {
    setStarting(true);
    await countdownV2({
      startTimeWorker: true,
      flushCountingStatus: true,
      triggerTimesup: true,
    });
  };

  const action = (
    <BlockControllerActionButton
      onClick={startSubmissionTimer}
      icon={TimerIcon}
      disable={starting}
    >
      {`Start ${block.fields.startVideoWithTimer ? 'Video &' : 'Game'} Timer (${
        block.fields.questionTimeSec
      }s)`}
    </BlockControllerActionButton>
  );

  return (
    <ControllerLayout action={action}>
      <div className='w-full flex flex-col'>
        <Badges block={block} />
        <MediaPreviewer
          playback={playback}
          alt={block.type}
          text={block.fields.question}
        />
      </div>
    </ControllerLayout>
  );
}

function SubmissionTimerCounting(props: Props): JSX.Element | null {
  // note(falcon): copying this pattern from teamrelay block. one thing to keep
  // in mind about this is that the timer for the host is actually started in
  // the gameplay file. this is an instance of where the host controller bleeds
  // into, or even  _depends_ on the gameplay.
  const time = useGameSessionLocalTimer();

  return (
    <ControllerLayout
      action={<BlockControllerActionTimeRemaining remainingSec={time} />}
    >
      <MCSubmissionsView block={props.selectedBlock} />
    </ControllerLayout>
  );
}

function SubmissionTimerDone(props: PropsWithPlayback): JSX.Element | null {
  const { switchMedia, reset: resetPlayback } = props.playback.api;

  const revealCorrectAnswer = () => {
    resetPlayback();
    const media = BlockKnifeUtils.Media(props.selectedBlock, 'questionMedia');
    switchMedia(media);
    next();
  };

  return (
    <ControllerLayout
      action={
        <BlockControllerActionButton
          onClick={revealCorrectAnswer}
          icon={DoubleRightArrow}
        >
          Reveal Correct Answer
        </BlockControllerActionButton>
      }
    >
      <MCSubmissionsView block={props.selectedBlock} />
    </ControllerLayout>
  );
}

function PresentingAnswer(props: PropsWithPlayback): JSX.Element | null {
  const { selectedBlock: block, playback } = props;

  const mediaActionText = useControllerMediaPlayText(playback);
  const { switchMedia, play: playMedia } = playback.api;

  useEffect(() => {
    if (!playback.isVideoPlayed) return;
    next();
  }, [playback.isVideoPlayed]);

  useEffect(() => {
    const media = BlockKnifeUtils.Media(block, 'answerMedia');
    if (media) {
      switchMedia(media, () => playMedia());
    } else {
      next();
    }
  }, [block, switchMedia, playMedia]);

  if (playback.preview) {
    const action = (
      <BlockControllerActionNone icon={PlayIcon}>
        {mediaActionText}
      </BlockControllerActionNone>
    );

    return (
      <ControllerLayout action={action}>
        <MCSubmissionsView
          block={props.selectedBlock}
          setEditPointsData={props.setEditPointsData}
          revealResults
          sortByTotalScore
        />
      </ControllerLayout>
    );
  }
  return null;
}

function PresentedAnswer(props: PropsWithPlayback): JSX.Element | null {
  const revealResults = async () => {
    next();
  };

  const action = (
    <BlockControllerActionButton
      onClick={revealResults}
      icon={DoubleRightArrow}
    >
      Reveal Results
    </BlockControllerActionButton>
  );

  return (
    <ControllerLayout action={action}>
      <MCSubmissionsView
        block={props.selectedBlock}
        setEditPointsData={props.setEditPointsData}
        revealResults
        sortByTotalScore
      />
    </ControllerLayout>
  );
}

function Results(props: Props): JSX.Element {
  const showScoreboard = () => {
    next();
  };

  return (
    <ControllerLayout
      action={
        <>
          <BlockControllerActionButton
            onClick={showScoreboard}
            isSecondary={true}
          >
            Show Scoreboard
          </BlockControllerActionButton>
          <BlockControllerActionButton
            onClick={props.onEndBlock}
            icon={FilledSquareIcon}
          >
            End Block Sequence
          </BlockControllerActionButton>
        </>
      }
    >
      <MCSubmissionsView
        block={props.selectedBlock}
        revealResults
        sortByTotalScore
      />
    </ControllerLayout>
  );
}

function Scoreboard(props: Props): JSX.Element {
  return (
    <ControllerLayout
      action={
        <BlockControllerActionButton
          onClick={props.onEndBlock}
          icon={FilledSquareIcon}
        >
          End Block Sequence
        </BlockControllerActionButton>
      }
    >
      <MCSubmissionsView
        block={props.selectedBlock}
        revealResults
        sortByTotalScore
      />
    </ControllerLayout>
  );
}

function Ended(props: Props): JSX.Element {
  return (
    <ControllerLayout
      action={
        <BlockControllerActionButton
          onClick={() => props.setShowResetConfirmation(true)}
          icon={RefreshIcon}
          isDelete={true}
        >
          Reset Completed Block
        </BlockControllerActionButton>
      }
    >
      <MCSubmissionsView
        block={props.selectedBlock}
        revealResults
        sortByTotalScore
      />
    </ControllerLayout>
  );
}

export function MultipleChoiceBlockController(
  props: Props
): JSX.Element | null {
  const { selectedBlockIndex } = props;
  const block = useStableBlock(props.selectedBlock);
  const isEndedBlock = useIsEndedBlock(block.id);
  const gameSessionStatus =
    useGameSessionStatus<MultipleChoiceGameSessionStatus>();
  const playback = useControllerMediaPlayback();

  if (selectedBlockIndex === null) return null;

  if (isEndedBlock) {
    return <Ended {...props} />;
  }

  switch (gameSessionStatus) {
    case MultipleChoiceGameSessionStatus.LOADED:
      return <Loaded {...props} playback={playback} />;
    case MultipleChoiceGameSessionStatus.PRESENTING_QUESTION:
      return <PresentingQuestion {...props} playback={playback} />;
    case MultipleChoiceGameSessionStatus.PRESENTED_QUESTION:
      return <PresentedQuestion {...props} playback={playback} />;
    case MultipleChoiceGameSessionStatus.SUBMISSION_TIMER_COUNTING:
      return <SubmissionTimerCounting {...props} />;
    case MultipleChoiceGameSessionStatus.SUBMISSION_TIMER_DONE:
      return <SubmissionTimerDone {...props} playback={playback} />;
    case MultipleChoiceGameSessionStatus.PRESENTING_ANSWER:
      return <PresentingAnswer {...props} playback={playback} />;
    case MultipleChoiceGameSessionStatus.PRESENTED_ANSWER:
      return <PresentedAnswer {...props} playback={playback} />;
    case MultipleChoiceGameSessionStatus.RESULTS:
      return <Results {...props} />;
    case MultipleChoiceGameSessionStatus.SCOREBOARD:
      return <Scoreboard {...props} />;
    case MultipleChoiceGameSessionStatus.END:
    case null:
    case undefined:
      break;
    default:
      assertExhaustive(gameSessionStatus);
      break;
  }

  return null;
}
