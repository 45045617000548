import React from 'react';

import { type ModelsTTSLabeledRenderSettings } from '@lp-lib/api-service-client/public';
import type { Media } from '@lp-lib/media';

import { useLiveCallback } from '../../hooks/useLiveCallback';
import { useAwaitFullScreenConfirmCancelModal } from '../ConfirmCancelModalContext';
import { ModalWrapper } from '../ConfirmCancelModalContext/ModalWrapper';
import { ArrowsOutIcon } from '../icons/ArrowsOutIcon';
import { TTSPreviewButton } from './TTSPreviewButton';
import { TTSScriptEditorFullScreen } from './TTSScriptEditorFullScreen';
import { type VariableRegistry } from './VariableRegistry';

type TTSScriptEditorProps = {
  value?: string | null;
  placeholder?: string | null;
  onChange: (value: string) => void;
  defaultValue?: string | null;
  onBeforeRender?: (value: Nullable<string>) => Promise<Nullable<string>>;
  settings?: ModelsTTSLabeledRenderSettings | null;
};

export function TTSScriptEditor(props: TTSScriptEditorProps) {
  const handleChange = useLiveCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      props.onChange(e.target.value);
    }
  );

  const controlProps =
    props.value !== undefined
      ? { value: props.value ?? '', onChange: handleChange }
      : {
          defaultValue: props.defaultValue ?? '',
          onBlur: (e: React.FocusEvent<HTMLTextAreaElement>) =>
            props.onChange(e.target.value ?? ''),
        };

  return (
    <div className='w-full flex gap-2'>
      <textarea
        className='field m-0 min-h-13.5 py-2 resize-y scrollbar'
        {...controlProps}
        placeholder={props.placeholder ?? 'Enter script...'}
      />
      <div className='flex-none'>
        <TTSPreviewButton
          script={props.value ?? props.defaultValue}
          settings={props.settings}
          onBeforeRender={props.onBeforeRender}
        />
      </div>
    </div>
  );
}

export function TTSScriptEditorExpandable(
  props: TTSScriptEditorProps & {
    previewMedia?: Media | null;
    previewLabel?: string;
    supportedVariables?: VariableRegistry;
    delayedStartMs?: number;
    onDelayedStartMsChange?: (delayedStartMs: number) => void;
  }
) {
  const triggerFullScreenModal = useAwaitFullScreenConfirmCancelModal();
  const textAreaRef = React.useRef<HTMLTextAreaElement>(null);

  const handleChange = useLiveCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      props.onChange(e.target.value);
    }
  );

  const handleEnlargeClick = useLiveCallback(() => {
    triggerFullScreenModal({
      kind: 'custom',
      element: (p) => {
        const handleChange = (script: string, delayedStartMs?: number) => {
          if (script !== props.value) props.onChange(script);
          if (delayedStartMs && delayedStartMs !== props.delayedStartMs)
            props.onDelayedStartMsChange?.(delayedStartMs);

          if (props.value === undefined && textAreaRef.current) {
            // uncontrolled, so let's reset the textarea
            textAreaRef.current.value = script;
          }
          p.internalOnConfirm();
        };

        return (
          <ModalWrapper borderStyle='none' containerClassName='w-full h-full'>
            <TTSScriptEditorFullScreen
              {...props}
              defaultValue={props.value ?? props.defaultValue}
              onCancel={p.internalOnCancel}
              onChange={handleChange}
            />
          </ModalWrapper>
        );
      },
    });
  });

  const controlProps =
    props.value !== undefined
      ? { value: props.value ?? '', onChange: handleChange }
      : {
          defaultValue: props.defaultValue ?? '',
          onBlur: (e: React.FocusEvent<HTMLTextAreaElement>) =>
            props.onChange(e.target.value ?? ''),
        };

  return (
    <div className='w-full flex items-start gap-2'>
      <textarea
        ref={textAreaRef}
        className='field m-0 min-h-13.5 py-2 resize-y scrollbar'
        {...controlProps}
        placeholder={props.placeholder ?? 'Enter script...'}
      />

      <div className='flex-none flex items-center gap-2'>
        <button
          type='button'
          className='btn text-white'
          onClick={handleEnlargeClick}
        >
          <ArrowsOutIcon className='w-4 h-4 fill-current transform transition-transform hover:scale-110' />
        </button>

        <TTSPreviewButton
          script={props.value ?? props.defaultValue}
          settings={props.settings}
          onBeforeRender={props.onBeforeRender}
        />
      </div>
    </div>
  );
}
