import { useCallback, useRef } from 'react';
import { useLatest } from 'react-use';

import { useTaskQueueRunUntilEmptyUnsafe } from '../../../hooks/useTaskQueue';
import { type IRTCService } from '../../../services/webrtc';
import { err2s } from '../../../utils/common';

interface Enqueuer<RTC extends IRTCService> {
  /**
   * Enqueue a "synchronize", aka a potential publish or unpublish operation.
   * This gives the caller control over when the publish/unpublish will occur.
   * This is effectively a shortcut to calling `enqueueOp` and doing boilerplat
   * publish/unpublish + error handling + logging. NOTE: if the same value is
   * enqueued as the immediately preceding operation, the synchronization is a
   * noop.
   *
   * @deprecated this is too complex, it's better to just use the enqueueOp directly
   */
  enqueueSynchronize: (publish: boolean) => void;
  /**
   * Enqueue an operation to be performed against the RTCService, using the same
   * ordering guarantees as the `enqueueSynchronize`. This way, an unpublish can be
   * guaranteed to happen before `switchVideo`.
   */
  enqueueOp: (switcher: (rtcService: RTC) => Promise<void> | void) => void;
}

export function usePublishStream<RTC extends IRTCService>(
  rtcService: RTC,
  options?: {
    afterPublish?: () => Promise<void>;
    afterUnpublish?: () => Promise<void>;
  }
): Enqueuer<RTC> {
  const { addTask } = useTaskQueueRunUntilEmptyUnsafe();
  const afterPublishCb = useLatest(options?.afterPublish);
  const afterUnpublishCb = useLatest(options?.afterUnpublish);

  const prevSync = useRef<null | boolean>(null);

  const enqueueSynchronize: Enqueuer<RTC>['enqueueSynchronize'] = (publish) => {
    if (prevSync.current === publish) return;

    async function execUnpublish() {
      try {
        await rtcService.unpublish();
        if (afterUnpublishCb.current) afterUnpublishCb.current();
      } catch (error) {
        rtcService.log.error('unpublish failed', err2s(error));
      }
    }

    async function execPublish() {
      try {
        await rtcService.publish();
        if (afterPublishCb.current) afterPublishCb.current();
      } catch (error) {
        rtcService.log.error('publish failed', err2s(error));
      }
    }

    if (publish === false) {
      addTask(execUnpublish);
    } else {
      addTask(execPublish);
    }

    prevSync.current = publish;
  };

  const enqueueOp: Enqueuer<RTC>['enqueueOp'] = (switcher) => {
    addTask(() => switcher(rtcService));
  };

  return {
    enqueueSynchronize: useCallback(enqueueSynchronize, [
      addTask,
      afterPublishCb,
      afterUnpublishCb,
      rtcService,
    ]),
    enqueueOp: useCallback(enqueueOp, [addTask, rtcService]),
  };
}
