import { type AnalyticsBrowser } from '@segment/analytics-next';
import { useEffect, useMemo } from 'react';

import { type Block } from '@lp-lib/game';

import { type ExperienceMetrics } from '../components/ExperienceMetrics';
import {
  useGameSessionBlock,
  useGameSessionGamePackId,
  useIsLiveGamePlay,
  useOndPlaybackVersion,
} from '../components/Game/hooks';
import { useLiteModeEnabled } from '../components/LiteMode';
import { useMyOrgId } from '../components/Organization';
import { useMyTeamId, useNumOfParticipants } from '../components/Player';
import {
  useIsStreamSessionAliveOrAborted,
  useStreamSessionId,
} from '../components/Session';
import {
  useConnectedTeamMemberCount,
  useTeamRaw,
  useTeamsCount,
} from '../components/TeamAPI/TeamV1';
import { useTownhallEnabled } from '../components/Townhall';
import { useVenueId } from '../components/Venue/VenueProvider';
import { useIsCoordinator, useMyInstance } from '../hooks/useMyInstance';
import { type Organization, type Venue } from '../types';
import { type Game } from '../types/game';
import { getAgentInfo } from '../utils/user-agent';
import { useAnalytics } from './AnalyticsContext';

type ExperienceMeasuredExtra = {
  sessionId?: string;
  blockType?: Block['type'];
  gamePackId?: Game['id'];
  orgId?: Organization['id'];
  venueId?: Venue['id'];
  isCoordinator?: boolean;
  mode?: 'live' | 'ond';
  ondPlaybackVersion?: number;
  participantCount?: number;
  teamCount?: number;
  teamName?: string;
  teamId?: string;
  teamMemberCount?: number;
  teamMemberIsCaptain?: boolean;
  hardwareConcurrency?: number;
  deviceMemory?: number;
  osName?: string;
  liteModeEnabled?: boolean;
  townhallEnabled?: boolean;
};

type ExperienceMeasuredProps = ExperienceMeasuredExtra & ExperienceMetrics;

class ExperienceAnalytics {
  constructor(
    readonly analytics: AnalyticsBrowser,
    private extra?: ExperienceMeasuredExtra
  ) {}

  /**
   * Allow syncing the static data without recreating this instance. Recreating
   * the instance would invalidate anything that depends on this, such as the
   * BrowserInterval that calls `trackExperienceMeasured`.
   */
  update(extra: ExperienceMeasuredExtra) {
    this.extra = {
      ...this.extra,
      ...extra,
    };
  }

  trackExperienceMeasured(props: ExperienceMetrics): void {
    this.analytics.track('Experience Measured', this.addAllExtra(props));
  }

  trackSessionExperienceMeasured(props: ExperienceMetrics): void {
    this.analytics.track(
      'Session Experience Measured',
      this.addAllExtra(props)
    );
  }

  private addAllExtra(props: ExperienceMetrics): ExperienceMeasuredProps {
    return {
      ...this.extra,
      ...props,
    };
  }
}

export function useExperienceAnalytics(): ExperienceAnalytics {
  const analytics = useAnalytics();
  const sessionId = useStreamSessionId();
  const includeSessionId = useIsStreamSessionAliveOrAborted();
  const block = useGameSessionBlock();
  const gamePackId = useGameSessionGamePackId();
  const venueId = useVenueId();
  const orgId = useMyOrgId();
  const isCoordinator = useIsCoordinator();
  const mode = useIsLiveGamePlay() ? 'live' : 'ond';
  const ondPlaybackVersion = useOndPlaybackVersion();
  const team = useTeamRaw(useMyTeamId());
  const teamMemberCount = useConnectedTeamMemberCount();
  const myInstance = useMyInstance();
  const teamId = team?.id;
  const teamName = team?.name;
  const isCaptainScribe = team?.captainScribe === myInstance?.clientId;
  const participantCount = useNumOfParticipants({
    filters: ['status:connected'],
  });
  const teamCount = useTeamsCount();
  const xpAnalytics = useMemo(
    () => new ExperienceAnalytics(analytics),
    [analytics]
  );
  const agentInfo = useMemo(() => getAgentInfo(), []);
  const liteModeEnabled = useLiteModeEnabled();
  const townhallEnabled = useTownhallEnabled();
  useEffect(() => {
    xpAnalytics.update({
      sessionId: includeSessionId ? sessionId : undefined,
      blockType: block?.type,
      gamePackId: gamePackId ?? undefined,
      orgId: orgId ?? undefined,
      venueId,
      isCoordinator,
      mode,
      ondPlaybackVersion: ondPlaybackVersion ?? undefined,
      liteModeEnabled,
      townhallEnabled,
      teamCount,
      teamName,
      teamId,
      teamMemberCount,
      teamMemberIsCaptain: isCaptainScribe,
      participantCount,
      hardwareConcurrency: navigator.hardwareConcurrency,
      deviceMemory: navigator.deviceMemory,
      osName: agentInfo.os.name,
    });
  });
  return xpAnalytics;
}
