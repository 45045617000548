import countBy from 'lodash/countBy';
import isNumber from 'lodash/isNumber';
import maxBy from 'lodash/maxBy';
import shuffle from 'lodash/shuffle';
import React, {
  type ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from 'react';
import { proxy, useSnapshot } from 'valtio';

import { RTDBServerValueTIMESTAMP } from '@lp-lib/firebase-typesafe';
import {
  assertExhaustive,
  type IcebreakerBlock,
  IcebreakerBlockGameSessionStatus,
  IcebreakerMode,
  IcebreakerOnStageSelection,
  IcebreakerSelectNextStrategy,
} from '@lp-lib/game';
import { type Logger } from '@lp-lib/logger-base';

import { type Participant, type TeamId } from '../../../../types';
import { markSnapshottable, ValtioUtils } from '../../../../utils/valtio';
import {
  type FirebaseService,
  useFirebaseContext,
  useFirebaseValue,
  useIsFirebaseConnected,
} from '../../../Firebase';
import { increment } from '../../../Firebase/utils';
import {
  useLastJoinedParticipantByUserId,
  useParticipantByUserIds,
  useParticipantsAsArray,
  useParticipantsAsArrayGetter,
} from '../../../Player';
import { useIsStreamSessionAlive } from '../../../Session';
import { useUser } from '../../../UserContext';
import { useVenueId } from '../../../Venue/VenueProvider';
import {
  useGameSessionLocalTimer,
  useGameSessionStatus,
  useIsGamePlayPaused,
} from '../../hooks';
import { incrementBlockDetailScore } from '../../store';
import {
  useGameSessionPreconfigVIPParticipants,
  useGameSessionPreconfigVIPUserIdsGetter,
} from '../Common/GamePlay/GameSessionPreconfigProvider';
import { pickFirstIcebreakerParticipant } from './IcebreakerBlockParticipants';
import {
  type AllTeamsProgressData,
  type IcebreakerGamePlayCard,
  type IcebreakerGamePlayCardMap,
  type IcebreakerGameProgress,
  type IcebreakerRoot,
  type TeamProgressData,
} from './types';
import { IcebreakerFBUtils, IcebreakerUtils, log } from './utils';

export type IcebreakerState = IcebreakerRoot & {
  showNextPlayerPicker: boolean;
};

type Dependencies = {
  getParticipants: () => ReturnType<typeof useParticipantsAsArray>;
  getVipUserIds: () => string[];
};

class IcebreakerAPI {
  private _state;
  constructor(
    private venueId: string,
    private svc: FirebaseService,
    private deps: Dependencies,
    private rootHandle = IcebreakerFBUtils.RootHandle(svc, venueId),
    private cardsHandle = IcebreakerFBUtils.CardsHandle(svc, venueId),
    private progressHandle = IcebreakerFBUtils.ProgressHandle(svc, venueId)
  ) {
    this._state = markSnapshottable(
      proxy<IcebreakerState>(this.initialState())
    );
  }
  get state() {
    return this._state;
  }
  on(): void {
    this.cardsHandle.on((val) =>
      ValtioUtils.set(this._state, 'cards', val ?? this.initialState().cards)
    );
    this.progressHandle.on((val) =>
      ValtioUtils.set(
        this._state,
        'progress',
        val ?? this.initialState().progress
      )
    );
    this.rootHandle.on((val) => {
      ValtioUtils.set(
        this._state,
        'onStageTimerSec',
        val?.onStageTimerSec ?? this.initialState().onStageTimerSec
      );
      ValtioUtils.set(
        this._state,
        'onStageTimerAutoStart',
        val?.onStageTimerAutoStart ?? this.initialState().onStageTimerAutoStart
      );
      ValtioUtils.set(
        this._state,
        'points',
        val?.points ?? this.initialState().points
      );
    });
  }
  off() {
    this.cardsHandle.off();
    this.progressHandle.off();
  }
  reset() {
    ValtioUtils.reset(this._state, this.initialState());
  }
  private initialState(): IcebreakerState {
    return {
      cards: {},
      progress: {
        currentCardIndex: 0,
      },
      showNextPlayerPicker: false,
      onStageTimerSec: 0,
      onStageTimerAutoStart: false,
      points: 0,
    };
  }

  toggleNextPlayerPicker(next: boolean) {
    this._state.showNextPlayerPicker = next;
  }

  async revealCard(index?: number, playedBy?: string) {
    const updates: Partial<IcebreakerGamePlayCard> = {
      phase: 'reveal',
    };
    if (isNumber(index)) {
      updates.revealIndex = index;
    }
    if (playedBy) {
      updates.playedBy = playedBy;
    }
    await this.getCurrentCardHandle().update(updates);
  }

  async completeCard(
    playedBy?: string,
    headsUpResult?: IcebreakerGamePlayCard['headsUpResult']
  ) {
    const updates: Partial<IcebreakerGamePlayCard> = {
      phase: 'completed',
    };
    if (playedBy) {
      updates.playedBy = playedBy;
    }
    if (headsUpResult) {
      updates.headsUpResult = headsUpResult;
    }
    await this.getCurrentCardHandle().update(updates);
  }

  async playerReady() {
    await this.progressHandle.update({
      readyAt: RTDBServerValueTIMESTAMP,
    } as never);
  }

  async markHeadsUpCardCorrect(playedBy: string, teamId: string) {
    const ref = `${IcebreakerFBUtils.Path(this.venueId, 'teams')}/${teamId}`;
    await this.svc.prefixedSafeRef<TeamProgressData>(ref).update({
      numCorrect: increment(1),
      score: increment(this._state.points),
    });
    await incrementBlockDetailScore(teamId, this._state.points);
    await this.completeCard(playedBy, 'correct');
  }

  async markHeadsUpCardSkipped(playedBy: string) {
    await this.completeCard(playedBy, 'skipped');
  }

  async nextCard() {
    const nextCardIndex = this.nextCardIndex();
    await this.progressHandle.update({
      currentCardIndex: nextCardIndex,
    });
  }

  async nextPlayer(uid: string | null) {
    if (!uid) return;

    const currentCard = this.getCurrentCard();
    if (!currentCard) return;

    const update: Partial<IcebreakerGameProgress> = {
      currentPlayerUid: uid,
    };

    if (this._state.onStageTimerSec > 0) {
      // null out the ready flag, so they indicate when they are ready.
      update.readyAt = null;
    }

    if (currentCard.phase === 'active') {
      // if the card is active, we only update the player
      await this.progressHandle.update(update as never);
    } else {
      // if the card is played, we update both card and player
      update.currentCardIndex = this.nextCardIndex();
      await this.progressHandle.update(update as never);
    }
  }

  async nextCardAndPlayer(uid?: string | null) {
    if (!uid) {
      this.nextCard();
      return;
    }

    const update: Partial<IcebreakerGameProgress> = {
      currentPlayerUid: uid,
      currentCardIndex: this.nextCardIndex(),
    };

    if (this._state.onStageTimerSec > 0) {
      // null out the ready flag, so they indicate when they are ready.
      update.readyAt = null;
    }

    await this.progressHandle.update(update as never);
  }

  async vote(uid: string, index: number) {
    await this.getCurrentCardHandle().child('choiceMap').child(uid).set(index);
  }

  getCurrentCard(): IcebreakerGamePlayCard | null {
    return this._state.cards[this._state.progress.currentCardIndex] || null;
  }

  private getCurrentCardHandle() {
    return this.cardsHandle.ref.child(
      `${this._state.progress.currentCardIndex}`
    );
  }

  private nextCardIndex() {
    return this._state.progress.currentCardIndex + 1;
  }

  pickNextPlayerUid(
    onStageSelection: IcebreakerOnStageSelection,
    selectNextStrategy: IcebreakerSelectNextStrategy
  ): string | null {
    const currentUid = this._state.progress.currentPlayerUid;

    switch (selectNextStrategy) {
      case IcebreakerSelectNextStrategy.Default:
        return this.roundRobinNextParticipantUid(
          currentUid,
          this.deps.getParticipants()
        );
      case IcebreakerSelectNextStrategy.OnStageChoose:
        return null;
      case IcebreakerSelectNextStrategy.KeepCurrent:
        if (onStageSelection === IcebreakerOnStageSelection.VIP) {
          const vipUserIds = this.deps.getVipUserIds();
          const participants = this.deps.getParticipants();
          const vipParticipants = participants.filter((p) =>
            vipUserIds.includes(p.id)
          );
          // if all vips are down, fallback to all participants
          return (
            this.roundRobinNextParticipantUid(currentUid, vipParticipants) ||
            this.roundRobinNextParticipantUid(currentUid, participants)
          );
        }
        return currentUid || null;
      default:
        assertExhaustive(selectNextStrategy);
        return null;
    }
  }

  private roundRobinNextParticipantUid(
    currentUid: string | null | undefined,
    candidates: Participant[]
  ): string | null {
    const currentPlayerIndex = candidates.findIndex((p) => p.id === currentUid);
    const nextPlayerIndex = (currentPlayerIndex + 1) % candidates.length;
    const player = candidates[nextPlayerIndex] || candidates[0] || null;
    return player.id || null;
  }

  getMostVotedIndex(
    choiceMap: IcebreakerGamePlayCard['choiceMap']
  ): number | null {
    const counts = countBy(Object.values(choiceMap || {}));
    const max = maxBy(Object.entries(counts), ([, count]) => count);
    return max ? parseInt(max[0]) : null;
  }
}

class IcebreakerGameControlAPI {
  constructor(
    venueId: string,
    svc: FirebaseService,
    private log: Logger,
    private deps: Dependencies,
    private rootHandle = IcebreakerFBUtils.RootHandle(svc, venueId)
  ) {}

  async initGame(block: IcebreakerBlock) {
    const cardMap: IcebreakerGamePlayCardMap = {};
    let cards = block.fields.cards;
    if (!block.fields.playCardsInOrder) {
      cards = shuffle(cards);
    }

    let index = 0;
    for (const card of cards.values()) {
      const gamePlayCard = IcebreakerUtils.IcebreakerCardToGamePlay(
        card,
        index,
        block
      );
      if (!gamePlayCard) continue;
      cardMap[index] = gamePlayCard;
      index++;
    }

    const progress: IcebreakerGameProgress = {
      currentCardIndex: 0,
    };
    if (IcebreakerUtils.ShouldSelectOnStagePlayer(block)) {
      const currentPlayer = pickFirstIcebreakerParticipant(
        this.deps.getParticipants,
        this.deps.getVipUserIds,
        block.fields.onStageSelection
      );
      if (currentPlayer) {
        progress.currentPlayerUid = currentPlayer.id;
      }
    }

    await this.rootHandle.set({
      cards: cardMap,
      progress: progress as never,
      onStageTimerSec: block.fields.onStageTimeSec,
      onStageTimerAutoStart: block.fields.onStageTimerAutoStart,
      points: block.fields.points,
    });
  }

  async resetGame() {
    this.log.info('reset game');
    await this.rootHandle.remove();
  }
}

function useIcebreakerContext(): Context {
  const ctx = useContext(context);
  if (!ctx) throw new Error('IcebreakerContext is not in the tree!');
  return ctx;
}

export function useIcebreakerSharedAPI(): Context['sharedAPI'] {
  return useIcebreakerContext().sharedAPI;
}

export function useIcebreakerGameControlAPI(): Context['gameControlAPI'] {
  return useIcebreakerContext().gameControlAPI;
}
export function useIcebreakerCards(): IcebreakerGamePlayCard[] {
  const api = useIcebreakerSharedAPI();
  const cardMap = useSnapshot(api.state).cards;
  return useMemo(
    () =>
      Object.values(cardMap).sort(
        (a, b) => a.index - b.index
      ) as IcebreakerGamePlayCard[],
    [cardMap]
  );
}

export function useIcebreakerProgress(): IcebreakerGameProgress {
  const api = useIcebreakerSharedAPI();
  return useSnapshot(api.state).progress;
}

export function useCurrentCard(): IcebreakerGamePlayCard | null {
  const cards = useIcebreakerCards();
  const progress = useIcebreakerProgress();
  return cards[progress.currentCardIndex] || null;
}

export function useCurrentPlayer(): Participant | null {
  const progress = useIcebreakerProgress();
  return useLastJoinedParticipantByUserId(progress.currentPlayerUid);
}

export function useIsAllCardsPlayed() {
  const cards = useIcebreakerCards();
  const progress = useIcebreakerProgress();
  return progress.currentCardIndex >= cards.length;
}

export function useIsMyTurn() {
  const me = useUser();
  const currentPlayer = useCurrentPlayer();
  return me.id === currentPlayer?.id;
}

export function useChoiceMap() {
  const card = useCurrentCard();
  if (!card || !card.choiceMap) return {};
  return card.choiceMap;
}

export function useMyVotedIndex(): number | null {
  const me = useUser();
  const choiceMap = useChoiceMap();
  return choiceMap[me.id] ?? null;
}

export function useOnStageVotedIndex() {
  const onStagePlayer = useCurrentPlayer();
  const choiceMap = useChoiceMap();
  if (!onStagePlayer) return null;
  return choiceMap[onStagePlayer.id] ?? null;
}

export function useVotedOffStageParticipants(index: number) {
  const choiceMap = useChoiceMap();
  const currentPlayer = useCurrentPlayer();
  const userIds = Object.entries(choiceMap)
    .filter(
      ([uid, votedIndex]) => votedIndex === index && uid !== currentPlayer?.id
    )
    .map(([uid]) => uid);
  return useParticipantByUserIds(userIds, true);
}

export function useIsGamePlaying() {
  const gss = useGameSessionStatus<IcebreakerBlockGameSessionStatus>();
  const isPaused = useIsGamePlayPaused();
  return gss === IcebreakerBlockGameSessionStatus.GAME_START && !isPaused;
}

export function useShowNextPlayerPicker() {
  const api = useIcebreakerSharedAPI();
  return useSnapshot(api.state).showNextPlayerPicker;
}

export function useShowNextPlayerPickerOnComplete(block: IcebreakerBlock) {
  const isMyTurn = useIsMyTurn();
  const currentCard = useCurrentCard();
  const time = useGameSessionLocalTimer();

  return useMemo(() => {
    return (
      isMyTurn &&
      block.fields.selectNextStrategy ===
        IcebreakerSelectNextStrategy.OnStageChoose &&
      ((block.fields.mode === IcebreakerMode.Default &&
        currentCard?.phase === 'completed') ||
        (block.fields.mode === IcebreakerMode.HeadsUp && time === 0))
    );
  }, [
    block.fields.mode,
    block.fields.selectNextStrategy,
    currentCard?.phase,
    isMyTurn,
    time,
  ]);
}

export function useShowGetReady() {
  const api = useIcebreakerSharedAPI();
  const state = useSnapshot(api.state);
  const hasUserControlledOnStageTimer =
    state.onStageTimerSec > 0 && !state.onStageTimerAutoStart;
  const progress = state.progress;

  return (
    hasUserControlledOnStageTimer &&
    progress.currentPlayerUid &&
    !progress.readyAt
  );
}

export function useIcebreakerTextFormat() {
  const vipParticipants = useGameSessionPreconfigVIPParticipants();
  const vipNames = vipParticipants
    .map((p) => p.firstName || p.username)
    .join(', ');

  return useCallback(
    (text: string, onStagePlayer: Participant | null) => {
      return text
        .replaceAll(
          '%onStagePlayer%',
          onStagePlayer?.firstName || onStagePlayer?.username || ''
        )
        .replaceAll('%vipNames%', vipNames);
    },
    [vipNames]
  );
}

export function useTeamProgressData(teamId: Nullable<TeamId>) {
  const venueId = useVenueId();
  const isSessionAlive = useIsStreamSessionAlive();
  const [value] = useFirebaseValue<Nullable<TeamProgressData>>(
    `${IcebreakerFBUtils.Path(venueId, 'teams')}/${teamId ?? ''}`,
    {
      enabled: Boolean(isSessionAlive && teamId),
      seedValue: null,
      seedEnabled: false,
      readOnly: true,
      resetWhenUmount: true,
    }
  );
  return value;
}

export function useAllTeamsProgressData(): AllTeamsProgressData {
  const venueId = useVenueId();
  const isSessionAlive = useIsStreamSessionAlive();
  const [teamInfo] = useFirebaseValue<Nullable<AllTeamsProgressData>>(
    IcebreakerFBUtils.Path(venueId, 'teams'),
    {
      enabled: isSessionAlive,
      seedValue: null,
      seedEnabled: false,
      readOnly: true,
      resetWhenUmount: true,
    }
  );
  return teamInfo ?? {};
}

export function useIcebreakerParticipants() {
  return useParticipantsAsArray({
    filters: [
      'staff:false',
      'host:false',
      'cohost:false',
      'status:connected',
      'away:false',
    ],
    sorts: ['username:asc'],
  });
}

type Context = {
  sharedAPI: IcebreakerAPI;
  gameControlAPI: IcebreakerGameControlAPI;
};

const context = React.createContext<Context | null>(null);

export function IcebreakerProvider(props: {
  children?: ReactNode;
}): JSX.Element | null {
  const venueId = useVenueId();
  const { svc } = useFirebaseContext();

  const firebaseConnected = useIsFirebaseConnected();
  const isSessionAlive = useIsStreamSessionAlive();
  const ready = firebaseConnected && isSessionAlive;
  const getter = useParticipantsAsArrayGetter();
  const vipUserIdsGetter = useGameSessionPreconfigVIPUserIdsGetter();

  const ctx: Context = useMemo(() => {
    const getParticipants = () =>
      getter({
        filters: [
          'staff:false',
          'host:false',
          'cohost:false',
          'status:connected',
          'away:false',
        ],
        sorts: ['username:asc'],
      });

    return {
      sharedAPI: new IcebreakerAPI(venueId, svc, {
        getParticipants,
        getVipUserIds: vipUserIdsGetter,
      }),
      gameControlAPI: new IcebreakerGameControlAPI(venueId, svc, log, {
        getParticipants,
        getVipUserIds: vipUserIdsGetter,
      }),
    };
  }, [venueId, svc, vipUserIdsGetter, getter]);

  useEffect(() => {
    if (!ready) return;
    ctx.sharedAPI.on();
    return () => ctx.sharedAPI.off();
  }, [ctx.sharedAPI, ready]);

  return <context.Provider value={ctx}>{props.children}</context.Provider>;
}
