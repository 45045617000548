import { type FBReference, type FBSnapable } from '@lp-lib/firebase-typesafe';

import { getLogger } from '../../logger/logger';
import { BrowserTimeoutCtrl } from '../../utils/BrowserTimeoutCtrl';
import { TimeoutError, uuidv4 } from '../../utils/common';
import { makeFirebaseSafe } from './makeFirebaseSafe';

interface Msg<Req extends Record<string, unknown> = Record<string, unknown>> {
  id: string;
  state: 'send' | 'recv';
  req: Req;
  ack: boolean;
  ret:
    | null
    | undefined
    | {
        ok: boolean;
        value: unknown; // value or error
      };
}

export type FBMsgPassStorage = Record<string, Msg>;

class NotOkError extends Error {
  name = 'NotOkError';
}

function isErrorLike(obj: unknown): obj is { message: string; name: string } {
  return (
    typeof obj === 'object' &&
    !!obj &&
    'message' in obj &&
    typeof obj.message === 'string' &&
    'name' in obj &&
    typeof obj.name === 'string'
  );
}

/**
 * Send a message along the Firebase message bus. Optionally, you can wait for
 * an ack and therefore the return value. Be careful: there is no handling of
 * return values from a multicast operation! The latest will clobber all.
 */
export async function send<Req extends Record<string, unknown>>(
  ref: FBReference<FBMsgPassStorage>,
  req: Req,
  options?: {
    ack?: boolean;
    ackTimeoutMs?: number;
    cleanupAfterAck?: boolean;
    /**
     * Remove the sent message automatically after this many milliseconds.
     * Remember that this requires the sender to still be present; this is
     * a client operation, not a server-driven one. If the sender goes offline
     * before cleanup happens, the message will remain indefinitely.
     */
    cleanupAfterMs?: number | false;
  },
  log = getLogger().scoped('fbmsgpass:send')
) {
  const msg = {
    id: uuidv4(),
    state: 'send' as const,
    req: makeFirebaseSafe(req),
    ack: options?.ack ?? false,
    ret: null,
  };

  const cleanupAfterMs = options?.cleanupAfterMs ?? 30_000;

  log.info('send', { msg });
  const safe = makeFirebaseSafe(msg);
  await ref.child(msg.id).set(safe);

  if (msg.ack) {
    const { promise, resolve } = Promise.withResolvers<Msg['ret']>();

    const cleanup = async () => {
      if (options?.cleanupAfterAck !== false) {
        await ref.child(msg.id).remove();
      }
    };

    // Create the error outside of the timeout, so it has a proper stack trace
    const strMsg = JSON.stringify(msg);
    const error = new TimeoutError(`ack timeout: ${msg.id} ${strMsg}`);

    // Give the ack a deadline
    const timeout = new BrowserTimeoutCtrl();
    timeout.set(() => {
      // it has failed to be acked: clean it up.
      cleanup();
      // Note: not rejecting because otherwise the sender has to try/catch. The
      // "send" was successful, it's just the ack that failed.
      resolve({ ok: false, value: error });
    }, options?.ackTimeoutMs ?? 5000);

    // Wait for the ack value
    ref.child(msg.id).on('value', async function onValue(snap) {
      const data = snap.val();
      if (data?.ret) {
        timeout.clear();
        ref.child(msg.id).off('value', onValue);

        // cleanup since we were waiting for the ack
        cleanup();

        if (data.ret.ok) {
          resolve(data.ret);
        } else {
          let cause;

          // convert the transported value into a real Error, if it's similar
          if (isErrorLike(data.ret.value)) {
            cause = new Error(data.ret.value.message);
            cause.name = data.ret.value.name;
          }

          const nok = new NotOkError('remote recv handler threw', { cause });

          // NOTE: not rejecting because otherwise the sender has to try/catch
          // even if reception is successful
          resolve({ ok: false, value: nok });
        }
      }
    });

    return promise;
  } else if (cleanupAfterMs) {
    const timeout = new BrowserTimeoutCtrl();
    timeout.set(() => {
      ref.child(msg.id).remove();
    }, cleanupAfterMs);
  }
}

/**
 * Register a callback to receive messages from the Firebase message bus. The
 * callback is async, and if you return a value, it will attempt to be returned
 * to the sender. A thrown error will also be returned, if the sender configured
 * `ack: true`.
 *
 * The messages may be typed by specifying a generic type for `Req`.
 */
export function recv<Req extends Record<string, unknown>>(
  ref: FBReference<FBMsgPassStorage>,
  callback: (req: Req, id: string) => Promise<unknown>,
  options?: {
    signal?: AbortSignal;
  },
  log = getLogger().scoped('fbmsgpass:recv')
) {
  async function listener(snap: FBSnapable<Msg<Record<string, unknown>>>) {
    const data = snap.val();

    if (!data) return;

    log.info('recv', { data });

    if (data.state === 'recv') return;

    if (data.ack) {
      try {
        const rawRet = await callback(data.req as Req, data.id);

        const ret =
          rawRet && typeof rawRet === 'object'
            ? makeFirebaseSafe(rawRet as Record<string, unknown>)
            : null;

        ref.child(data.id).update({
          state: 'recv',
          ret: {
            ok: true,
            value: ret,
          },
        });
      } catch (err) {
        // Recv handler threw, try to return it as the ack value to the sender.

        // Serialize the error using best effort. Firebase cannot handle Error
        // instances.
        const objErr =
          err instanceof Error
            ? {
                name: err.name,
                message: err.message,
              }
            : null;

        // set the return value to the error
        ref.child(data.id).update({
          state: 'recv',
          ret: {
            ok: false,
            value: objErr ? makeFirebaseSafe(objErr) : undefined,
          },
        });
      }
    } else {
      await callback(data.req as Req, data.id);
    }
  }

  const off = () => {
    ref.off('child_added', listener);
  };

  ref.on('child_added', listener);
  options?.signal?.addEventListener('abort', off);

  return off;
}
