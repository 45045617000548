import Lethargy from 'lethargy';
import React, {
  type ReactNode,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { v4 } from 'uuid';

import CrowdFramesShadowImg from '../../assets/img/crowd-frames-shadow.png';
import {
  getFeatureQueryParamArray,
  getFeatureQueryParamNumber,
} from '../../hooks/useFeatureQueryParam';
import { useInstance } from '../../hooks/useInstance';
import { useOutsideClick } from '../../hooks/useOutsideClick';
import { useVisibilityChangeEffect } from '../../hooks/useVisibililityChangeEffect';
import {
  type ClientId,
  profileFor,
  ProfileIndex,
  toProfileAddress,
} from '../../services/crowd-frames';
import {
  ClientTypeUtils,
  isNotStaff,
  isStaff,
  type Participant,
} from '../../types/user';
import { assertExhaustive } from '../../utils/common';
import {
  ContextMenuScope,
  useContextMenuContext,
} from '../ContextMenu/Context';
import { useLiteModeEnabled } from '../LiteMode';
import { OnStage, Placeholder } from '../Participant';
import { useMyInstance, useMyTeamId, useParticipantFlag } from '../Player';
import { useParticipantsAsArray } from '../Player';
import { useTeamWithStaff } from '../TeamAPI/TeamV1';
import {
  useMyClientId,
  useMyClientType,
} from '../Venue/VenuePlaygroundProvider';
import { useCrowdFramesIconRenderable } from './CrowdFramesContext';
import { diffMaps } from './diffList';
import { FPSRender, useRenderlessTarget } from './FPSManager';
import { nullIfPresent } from './nullIfPresent';
import { RowAllocator } from './RowAllocator';
import { ResumableTimeout } from './useResumableTimeout';

const PER_ROW = 8;

const profileOverride = getFeatureQueryParamArray('crowd-view-cf-profile');
if (!(profileOverride in ProfileIndex))
  throw new Error('Invalid profile requested in crowd view');
const profileOverrideIndex = ProfileIndex[profileOverride];

type CrowdViewMode = 'collapsed' | 'full';

export type Row = {
  row: (ClientId | null)[];
  key: string;
};

type handleSelectedCb = (clientId: ClientId) => void;

/**
 * The public technique for displaying a CrowdFrames Avatar, anywhere. The only
 * requirement is that it receives its layout size from a relative parent
 * container. The internal canvas will render natively at the given
 * ProfileIndex, but will stretch to fill the parent container.
 */
export function CrowdFramesAvatar(props: {
  profileIndex: ProfileIndex;
  enablePointerEvents: boolean;
  hoverLabelAppearance?: 'cover' | 'under';
  handleSelected?: handleSelectedCb;
  selectedId?: ClientId | null;
  parentOccluding?: HTMLElement;
  participant: Participant;
  reflectOnStageStatus?: boolean;
  throttleRegistration?: boolean;
  renderFrameOnStage?: boolean;
  roundedClassname?: string;
}): JSX.Element | null {
  // z0: placeholder
  // z10: fps
  // z20: bring on stage / banned (host-view only)

  const profile = profileFor(props.profileIndex);
  const team = useTeamWithStaff(props.participant.teamId || null);
  const ref = useRef<HTMLDivElement>(null);
  const contextMenuCtx = useContextMenuContext();
  const liteMode = useLiteModeEnabled();
  const isHost = ClientTypeUtils.isHost(useMyClientType());
  const onStage = useParticipantFlag(props.participant.clientId, 'onStage');
  const video = useParticipantFlag(props.participant.clientId, 'video');
  const iconRenderable = useCrowdFramesIconRenderable();

  const handleSelected = (event: React.PointerEvent): void => {
    // Prevent this event from bubbling to the crowd view container, which
    // handles deselection.
    event.stopPropagation();
    contextMenuCtx.setOptions({
      x: event.pageX,
      y: event.pageY,
      clientId: props.participant.clientId,
      scope: ContextMenuScope.Other,
    });
    if (props.handleSelected) {
      props.handleSelected(props.participant.clientId as ClientId);
    }
  };

  const selectStyles = ['ring-2', 'ring-primary'];
  const roundedClassname = props.roundedClassname || 'rounded-full';
  const renderFrameOnStage = onStage ? props.renderFrameOnStage : true;

  return (
    <div ref={ref} className='group'>
      <div
        onPointerUp={(event) =>
          props.enablePointerEvents && handleSelected(event)
        }
        className={`absolute w-full h-full z-10 ${roundedClassname} overflow-hidden ${
          // hover: is much faster than managing the hover state via react.
          props.enablePointerEvents
            ? selectStyles.map((s) => `group-hover:${s}`).join(' ')
            : ''
        } ${
          props.selectedId === props.participant.clientId
            ? selectStyles.join(' ')
            : ''
        } pointer-events-on`}
      >
        {props.participant.clientId &&
          renderFrameOnStage &&
          video &&
          !liteMode && (
            <FPSRender
              className='z-10'
              clientId={props.participant.clientId as ClientId}
              parentOccluding={props.parentOccluding}
              profile={props.profileIndex}
              renderedWidth={profile.width}
              renderedHeight={profile.height}
              throttleRegistration={props.throttleRegistration}
            />
          )}
        {!renderFrameOnStage && (
          <OnStage
            zIndex='z-20'
            onStage={onStage}
            showLabel={props.reflectOnStageStatus}
          />
        )}
        {props.hoverLabelAppearance === 'cover' && (
          <div
            className='z-25
              absolute top-0 left-0
              bg-lp-black-001
              flex justify-center items-center
              w-full h-full
              invisible group-hover:visible
            '
          >
            <div
              className='
                p-1
                font-bold text-3xs text-white text-center
                overflow-hidden
              '
            >
              <div className='truncate'>{props.participant.username}</div>
              <div className='truncate'>{team ? `(${team.name})` : ''}</div>
            </div>
          </div>
        )}
        {iconRenderable(props.participant.icon ?? '') && (
          <img
            src={props.participant.icon}
            alt='icon'
            className='w-full h-full absolute z-5 top-0 left-0 object-cover'
          />
        )}
        <Placeholder
          clientId={props.participant.clientId}
          showLiteMode={isHost}
          noIndicators
        />
      </div>
      {props.hoverLabelAppearance === 'under' && (
        <div
          className='
          absolute z-15
          top-full left-1/2 transform -translate-x-1/2
          w-full pt-2 pb-2 pl-2 pr-2 rounded
          bg-black bg-opacity-80
          font-bold text-3xs text-white text-center
          overflow-hidden
          '
        >
          <div className='truncate'>{props.participant.username}</div>
          <div className='truncate'>{team ? `(${team.name})` : ''}</div>
        </div>
      )}
      {Boolean(props.participant.away) && (
        <div
          className={`
            absolute w-full h-full rounded-full
            bg-lp-black-001 z-25
            flex items-center justify-center
            text-icon-gray text-3xs font-bold
          `}
        >
          Away
        </div>
      )}
    </div>
  );
}

/**
 * The public API to force crowd frames subscription w/o rendering
 */
export function CrowdFramesRenderlessAvatar(props: {
  clientId: string;
  profileIndex: ProfileIndex;
}): JSX.Element | null {
  useRenderlessTarget(
    toProfileAddress(props.clientId as ClientId, props.profileIndex)
  );
  return null;
}

function CrowdViewSeat(props: {
  avatar: null | ReturnType<typeof CrowdFramesAvatar>;
}) {
  return (
    <div className='flex-none h-[80px] xl:h-[100px] w-[80px] xl:w-[100px] relative z-10 pointer-events-off'>
      {props.avatar ? props.avatar : null}
      <img
        className='absolute -bottom-1 z-0 pointer-events-off'
        src={CrowdFramesShadowImg}
        alt='A shadow representing a seat in the crowd'
      />
    </div>
  );
}

function participantRowFromClientIds(
  participants: Map<ClientId, Participant>,
  row: (ClientId | null)[]
): (Participant | null)[] {
  return row.map((id) => {
    return (id ? participants.get(id) : null) ?? null;
  });
}

function useRows(props: {
  myClientId: ClientId;
  participants: Map<ClientId, Participant>;
  perRow: number;
}) {
  const { participants, myClientId } = props;
  const [allocator] = useState(() => new RowAllocator<ClientId>(props.perRow));

  const [prevParticipants, setPrevParticipants] = useState<
    typeof participants | null
  >(null);

  const [rows, setRows] = useState<Set<Row>>(new Set());

  const participantAdded = useCallback(
    (clientId: ClientId) => {
      allocator.manage(clientId);
      setRows((rs) => {
        let modified = 0;

        // Fill existing rows with new participants if possible
        rs.forEach((row) => {
          const [, written] = allocator.fillAndCompact(row.row);
          modified |= written;
        });

        if (modified) return new Set(rs);
        else return rs;
      });
    },
    [allocator]
  );

  const participantRemoved = useCallback(
    (clientId: ClientId) => {
      allocator.forget(clientId);
      setRows((rs) => {
        let modified = 0;
        rs.forEach((row) => {
          modified |= nullIfPresent(row.row, clientId, (a, b) => a === b)
            ? 1
            : 0;
        });
        if (modified) return new Set(rs);
        else return rs;
      });
    },
    [allocator]
  );

  useEffect(() => {
    if (prevParticipants !== participants) {
      const curr = participants;
      const prev = prevParticipants ?? new Map();

      const { added, removed } = diffMaps(prev, curr);

      if (removed.length) {
        removed.forEach((id) => participantRemoved(id));
      }

      if (added.length) {
        added.forEach((p) => participantAdded(p));
      }

      setPrevParticipants(participants);
    }
  }, [
    myClientId,
    participantAdded,
    participantRemoved,
    participants,
    prevParticipants,
  ]);

  const addRow = useCallback(() => {
    const next = allocator.retainRow();
    setRows(
      (rs) =>
        new Set([
          ...rs,
          {
            key: v4(),
            row: next,
          },
        ])
    );
  }, [allocator]);

  // Release the participants to the pool without removing the row itself (for
  // exiting purposes)
  const releaseRowParticipants = useCallback(
    (row: Row) => {
      allocator.releaseRow(row.row);
    },
    [allocator]
  );

  // Be sure to call release at some point too!
  const removeRow = useCallback((row: Row) => {
    setRows((rs) => {
      rs.delete(row);
      return new Set(rs);
    });
  }, []);

  return { rows, addRow, removeRow, releaseRowParticipants };
}

export const CrowdViewContainer = React.forwardRef<
  HTMLDivElement,
  { className?: string; onPointerUp?: () => void; children?: ReactNode }
>((props, ref) => {
  return (
    <div
      ref={ref}
      onPointerUp={props.onPointerUp}
      className={`h-[80px] xl:h-[100px] w-[42rem] xl:w-208 relative preserve-3d ${props.className}`}
    >
      {props.children}
    </div>
  );
});

export function CrowdView(props: {
  viewMode: CrowdViewMode;
  handleSelected: handleSelectedCb;
  classNames?: string;
}): JSX.Element | null {
  const myClientId = useMyClientId() as ClientId;
  const myClientType = useMyClientType();
  const myTeamId = useMyTeamId();
  const me = useMyInstance();

  const participantsSelection = useParticipantsAsArray({
    filters: ['host:false', 'cohost:false', 'status:connected'],
  });

  const participants = useMemo(() => {
    const filterFn =
      ClientTypeUtils.isAudience(myClientType) && !isStaff(me)
        ? isNotStaff
        : undefined;

    const m = new Map<ClientId, Participant>();

    for (const p of participantsSelection) {
      if (filterFn && !filterFn(p)) continue;
      if (p.clientId === myClientId) continue;
      if (myTeamId && p.teamId === myTeamId) continue;
      m.set(p.clientId as ClientId, p);
    }

    return m;
  }, [me, myClientId, myClientType, myTeamId, participantsSelection]);

  const [paused, setPaused] = useState(false);
  const [selectedId, setSelectedId] = useState<ClientId | null>(null);

  useVisibilityChangeEffect(() => {
    if (selectedId === null && props.viewMode !== 'collapsed') setPaused(false);
    return () => {
      setPaused(true);
    };
  });

  const handleDeselect = useCallback(() => {
    setPaused(props.viewMode === 'collapsed' ? true : false);
    setSelectedId(null);
  }, [props.viewMode]);

  const outsideRef = useRef<HTMLDivElement | null>(null);
  useOutsideClick(outsideRef, handleDeselect);

  const handleSelectedWithPause = useCallback(
    (clientId: ClientId) => {
      setPaused(true);
      setSelectedId(clientId);
      props.handleSelected(clientId);
    },
    [props]
  );

  useEffect(() => {
    setPaused(props.viewMode === 'collapsed' ? true : false);
  }, [props.viewMode]);

  return (
    <CrowdViewContainer
      ref={outsideRef}
      onPointerUp={handleDeselect}
      className={`h-[80px] xl:h-[100px] w-[42rem] xl:w-208 relative preserve-3d
    ${props.classNames} ${props.viewMode === 'collapsed' ? 'z-0' : ''}`}
    >
      <RowMachine
        myClientId={myClientId}
        participants={participants}
        handleSelected={handleSelectedWithPause}
        selectedId={selectedId}
        paused={paused}
        isHostView={ClientTypeUtils.isHost(myClientType)}
      />
    </CrowdViewContainer>
  );
}

type RowAnimationStage = 0 | 0.5 | 1;

const nextStageFor = (stage: RowAnimationStage): RowAnimationStage => {
  switch (stage) {
    case 0:
      return 0.5;
    case 0.5:
      return 1;
    case 1:
      return 1;
    default:
      assertExhaustive(stage);
      break;
  }
  return 1;
};

const ENTERING_DURATION_MS = 500;
const STATIC_DURATION_MS = getFeatureQueryParamNumber(
  'crowd-view-anim-static-duration-ms'
);
const TRANSIT_DURATION_MS = 500;
const EXIT_DURATION_MS = 750;

function keyframesForStage(
  stage: RowAnimationStage,
  entering: boolean
): Keyframe[] {
  switch (stage) {
    case 0: {
      const transform0 = `${'translate3d(-50%, -60%, 0) scale(0.7)'}`;
      const transform1 = 'translate3d(-50%, -32.5%, 50px) scale(0.825)';
      return entering
        ? [
            {
              opacity: 0,
              transform: transform0,
            },
            {
              opacity: 0.3,
              transform: transform0,
            },
          ]
        : [
            {
              opacity: 0.3,
              transform: transform0,
            },
            {
              opacity: 0.7,
              transform: transform1,
            },
          ];
    }
    case 0.5: {
      const transform0 = 'translate3d(-50%, -32.5%, 50px) scale(0.825)';
      const transform1 = 'translate3d(-50%, 0%, 100px) scale(1)';
      return entering
        ? [
            {
              opacity: 0,
              transform: transform0,
            },
            {
              opacity: 0.7,
              transform: transform0,
            },
          ]
        : [
            {
              opacity: 0.7,
              transform: transform0,
            },
            {
              opacity: 1,
              transform: transform1,
            },
          ];
    }
    case 1: {
      const transform0 = 'translate3d(-50%, 0%, 100px) scale(1)';
      const transform1 = 'translate3d(-50%, 80%, 150px) scale(1.3)';
      return entering
        ? [
            {
              opacity: 0,
              transform: transform0,
            },
            {
              opacity: 1,
              transform: transform0,
            },
          ]
        : [
            {
              opacity: 1,
              transform: transform0,
            },
            {
              opacity: 0,
              transform: transform1,
            },
          ];
    }
    default:
      assertExhaustive(stage);
      break;
  }

  return [
    { opacity: 0, transform: 'translate3d(-50%, 80%, 150px) scale(1.3)' },
  ];
}

function initialRowAnimationStageForRowIndex(idx: number): RowAnimationStage {
  if (idx === 0) return 1;
  if (idx === 1) return 0.5;
  if (idx === 2) return 0;
  return 0;
}

type MachineRowData = {
  el: HTMLDivElement;
  initialStage: RowAnimationStage;
  currentStage: RowAnimationStage;
  entering: boolean;
  exiting: boolean;
  anim: Animation | null;
  row: Row;
};

function RowMachine(props: {
  myClientId: ClientId;
  participants: Map<ClientId, Participant>;
  handleSelected: handleSelectedCb;
  selectedId: ClientId | null;
  isHostView: boolean;
  paused: boolean;
}) {
  const { myClientId, participants } = props;

  const { rows, addRow, removeRow, releaseRowParticipants } = useRows({
    myClientId,
    participants,
    perRow: PER_ROW,
  });

  useEffect(() => {
    addRow();
    addRow();
    addRow();
  }, [addRow]);

  const map = useRef(new Map<MachineRowData['el'], MachineRowData>());

  const registerRef = (
    el: HTMLDivElement,
    data: Omit<
      MachineRowData,
      'el' | 'currentStage' | 'anim' | 'entering' | 'exiting'
    >
  ) => {
    const existing = map.current.get(el) || {
      ...data,
      el,
      entering: true,
      exiting: false,
      currentStage: data.initialStage,
      anim: null,
    };

    // Must keep reference valid since we write to this object from elsewhere
    Object.assign(existing, { el });
    map.current.set(el, existing);
  };

  const resumableRef = useRef<ResumableTimeout | null>(null);
  const waitingForInitialRows = useRef<Promise<unknown> | null>(null);

  const continueAnimation = function continueAnimation(next: () => void) {
    for (const [, data] of map.current) {
      // setup current stage animation
      const options: EffectTiming = {
        easing: 'ease-in-out',
        duration:
          data.currentStage === 1 ? EXIT_DURATION_MS : TRANSIT_DURATION_MS,
        fill: 'both',
      };
      const keyframes = keyframesForStage(data.currentStage, data.entering);

      if (!data.exiting) {
        // Once a row is exiting or has exited, it is effectively forgotten.
        // Don't assign a new animation, it might have a different duration
        data.anim = data.el.animate(keyframes, options);
        data.anim.finished.then(() => {
          if (data.currentStage === 1) {
            removeRow(data.row);
            map.current.delete(data.el);
          }
        });
      }

      if (data.currentStage === 1 && !data.exiting) {
        // Release the participants if exiting so that for small venues they
        // will appear in the _next_ row.
        releaseRowParticipants(data.row);
        data.exiting = true;
      }
    }

    // Add the row _after_ we've potentially released exiting participants so
    // they have a chance of being in the new row for small venues
    addRow();

    // Get all non-exiting animations. They determine when the next row is
    // needed and allow the exit animation to be independent.
    const arr = Array.from(map.current)
      .filter(([, data]) => data.currentStage !== 1)
      .map(([, data]) => data.anim?.finished)
      .filter((p): p is Promise<Animation> => !!p);

    if (!arr.length) return;

    Promise.all(arr).then(() => {
      for (const [, data] of map.current) {
        if (!data.entering) {
          data.currentStage = nextStageFor(data.currentStage);
        }

        data.anim = null;
        data.entering = false;
      }

      // Schedule the next iteration
      next();
    });
  };

  const initializeSpawnLoop = () => {
    // just in case, but not actually necessary
    cancelSpawnLoop();
    resumableRef.current = new ResumableTimeout(
      () => continueAnimation(initializeSpawnLoop),
      STATIC_DURATION_MS
    );
  };

  const cancelSpawnLoop = () => {
    resumableRef.current?.cancel();
  };

  const manualEngaged = useRef(false);
  const lethargy = useInstance(() => new Lethargy.Lethargy());

  const immediateNextRow = (ev: React.WheelEvent<HTMLDivElement>) => {
    const l = lethargy.check(ev.nativeEvent);

    if (manualEngaged.current || l === false) return;
    manualEngaged.current = true;
    cancelSpawnLoop();
    continueAnimation(() => {
      manualEngaged.current = false;
      initializeSpawnLoop();
    });
  };

  useLayoutEffect(() => {
    const datas = Array.from(map.current).map(([, data]) => data);

    // Handle entrance animation for each new row, whenever that may happen

    for (const data of datas) {
      if (data.anim || !data.entering) continue;

      // setup entering anim
      data.anim = data.el.animate(
        keyframesForStage(data.currentStage, data.entering),
        {
          duration: ENTERING_DURATION_MS,
          fill: 'both',
        }
      );
    }

    // Handle the interval initialization after initial view appearance.

    if (
      datas.length &&
      datas.every((d) => d.entering) &&
      waitingForInitialRows.current === null
    ) {
      // rows are initializing at first load
      // grab promises and when done, register timeout for primary loop

      const finisheds = datas
        .map((d) => d.anim?.finished)
        .filter((p): p is Promise<Animation> => !!p);

      waitingForInitialRows.current = Promise.all(finisheds).then(() => {
        datas.forEach((data) => {
          data.anim = null;
          data.entering = false;
        });

        initializeSpawnLoop();
      });
    }
  });

  useEffect(() => {
    // Sync the incoming props with the resumable paused state
    resumableRef.current?.pause(props.paused);
  });

  useEffect(() => {
    return () => {
      // Destroy on unmount
      if (resumableRef.current) resumableRef.current.cancel();
    };
  }, []);

  return (
    <>
      {Array.from(rows).map((row, idx) => (
        <div
          key={row.key}
          ref={(el) =>
            el &&
            registerRef(el, {
              row: row,
              initialStage:
                waitingForInitialRows.current === null
                  ? initialRowAnimationStageForRowIndex(idx)
                  : 0,
            })
          }
          onWheel={immediateNextRow}
          className={`absolute bottom-0 left-1/2 transform -translate-x-1/2 will-change-transform-opacity pointer-events-off`}
        >
          <div className='flex flex-row space-x-1 justify-center pointer-events-off'>
            {participantRowFromClientIds(participants, row.row).map(
              (p, idx) => (
                <CrowdViewSeat
                  key={p ? p.clientId : idx}
                  avatar={
                    p ? (
                      <CrowdFramesAvatar
                        handleSelected={props.handleSelected}
                        selectedId={props.selectedId}
                        profileIndex={profileOverrideIndex}
                        enablePointerEvents={true}
                        hoverLabelAppearance={
                          props.isHostView ? 'under' : 'cover'
                        }
                        participant={p}
                        reflectOnStageStatus={false}
                      />
                    ) : null
                  }
                />
              )
            )}
          </div>
        </div>
      ))}
    </>
  );
}
