import { type ConnectionStatusMixin } from '@lp-lib/shared-schema';

import config from '../../config';
import {
  type NetworkQualityState,
  type RemoteStreamState,
} from '../../services/webrtc';
import { type MemberId, type TeamMember } from '../../types';

export enum StageMode {
  BOS = 0,
  H2H = 1,
  SPOTLIGHT_BLOCK = 2,
  // defines a block controlling the players on stage, how they are displayed, etc.
  // this is general purpose, and can be used for multiple blocks.
  BLOCK_CONTROLLED = 3,
}

export type Stage = {
  mode: StageMode;
};

export type StageMemberSettings = {
  disableInvitedNotice?: Nullable<boolean>;
};

export type StageMember = TeamMember &
  NetworkQualityState &
  StageMemberSettings;

export type StageMemberVO = StageMember & Readonly<ConnectionStatusMixin>;

export type MemberMap = Record<MemberId, StageMember>;
export type MemberVOMap = Record<MemberId, StageMemberVO>;
export type StreamStateMap = Record<MemberId, RemoteStreamState>;

export const MAX_STAGE_MEMBERS = config.stage.maxMembers + 1; // one additional host
