import { useMemo } from 'react';
import Select, { type SingleValue } from 'react-select';
import useSWRImmutable from 'swr/immutable';

import { apiService } from '../../services/api-service';
import {
  type MessageCampaign,
  type MessageCampaignType,
} from '../../services/api-service/messageCampaign.api';
import { buildReactSelectStyles } from '../../utils/react-select';
import { type Option } from '../common/Utilities';

export function MessageCampaignSelect(props: {
  campaignId: string | null;
  onChange?: (campaign: MessageCampaign) => void;
  disabled?: boolean;
  campaignType: MessageCampaignType;
}): JSX.Element {
  const styles = useMemo(
    () =>
      buildReactSelectStyles<Option<MessageCampaign>>({
        override: {
          singleValue: { color: '#8B9294' },
        },
      }),
    []
  );
  const { data, isValidating } = useSWRImmutable(
    ['/message-campaigns', props.campaignType],
    async () => {
      return (await apiService.messageCampaign.query(props.campaignType)).data;
    }
  );

  const options = useMemo(() => {
    if (isValidating || !data) return [];
    const opts = (data.campaigns || [])
      .sort((a, b) => b.createdAt.localeCompare(a.createdAt))
      .map((o) => ({ label: o.name, value: o }));
    return opts;
  }, [data, isValidating]);

  const onTemplateChange = (option: SingleValue<Option<MessageCampaign>>) => {
    if (!props.onChange || !option) return;
    props.onChange(option.value);
  };

  return (
    <div className='flex flex-row items-center'>
      <div className='w-120'>
        <Select<Option<MessageCampaign>>
          classNamePrefix='select-box-v2'
          options={options}
          onChange={onTemplateChange}
          styles={styles}
          value={options.find((o) => o.value.id === props.campaignId)}
          isDisabled={props.disabled}
          noOptionsMessage={() => 'No Message Campaign'}
        />
      </div>
    </div>
  );
}
