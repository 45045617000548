import {
  type AnalyticsBrowser,
  type EventProperties,
} from '@segment/analytics-next';
import { useMemo } from 'react';

import { useMyOrgId } from '../components/Organization';
import { useAnalytics } from './AnalyticsContext';

class SlackAnalytics {
  constructor(
    private readonly analytics: AnalyticsBrowser,
    readonly orgId: string | null
  ) {}

  trackSlackInstallButtonClicked(props: EventProperties) {
    this.analytics.track(
      'Slack Install Button Clicked',
      this.buildEventProps(props)
    );
  }

  trackSlackSearchSelected(props: EventProperties) {
    this.analytics.track('Slack Search Selected', this.buildEventProps(props));
  }

  private buildEventProps(props?: EventProperties): EventProperties {
    return {
      ...props,
      orgId: this.orgId,
    };
  }
}

export function useSlackAnalytics(): SlackAnalytics {
  const analytics = useAnalytics();
  const orgId = useMyOrgId();

  return useMemo(
    () => new SlackAnalytics(analytics, orgId),
    [analytics, orgId]
  );
}
