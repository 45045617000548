import { Outlet, useParams } from '@remix-run/react';
import React, { useContext, useMemo } from 'react';

import { type GlobalPairingRound } from '../../../types';
import { err2s } from '../../../utils/common';
import { Loading } from '../../Loading';
import { useGlobalPairingRound } from './CRUD';

interface GlobalPairingRoundContext {
  globalRound: GlobalPairingRound;
  revalidatedAfterMount: boolean;
  set: ReturnType<typeof useGlobalPairingRound>['set'];
}

const context = React.createContext<GlobalPairingRoundContext | null>(null);

export const useGlobalPairingRoundDetails = (): GlobalPairingRoundContext => {
  const ctx = useContext(context);
  if (!ctx) throw new Error('No value for GlobalPairingRoundContext');
  return ctx;
};

export const GlobalPairingRoundDetailsLayout = (): JSX.Element => {
  const { id } = useParams<'id'>();

  const { globalRound, isLoading, error, revalidatedAfterMount, set } =
    useGlobalPairingRound({
      id,
    });

  const ctxValue = useMemo(
    () => (globalRound ? { globalRound, revalidatedAfterMount, set } : null),
    [globalRound, revalidatedAfterMount, set]
  );

  if (isLoading) {
    return <Loading />;
  }
  if (error) {
    return (
      <div className='text-center text-white'>
        Get Round Err: {err2s(error)}
      </div>
    );
  }
  if (!globalRound) {
    return <div className='text-center text-white'>Not Found</div>;
  }

  return (
    <context.Provider value={ctxValue}>
      <Outlet />
    </context.Provider>
  );
};
