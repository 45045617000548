import { useMemo } from 'react';

import { type DtoBlock } from '@lp-lib/api-service-client/public';
import { fromAPIBlockTypes } from '@lp-lib/game/src/api-integration';

import { apiService } from '../../services/api-service';
import { type Action, ActionSheet } from '../ActionSheet';
import {
  ConfirmCancelModalHeading,
  ConfirmCancelModalText,
  useAwaitFullScreenConfirmCancelModal,
} from '../ConfirmCancelModalContext';
import { BlockKnifeUtils } from '../Game/Blocks/Shared';
import { CopyLinkIcon } from '../icons/CopyLinkIcon';
import { DeleteIcon } from '../icons/DeleteIcon';
import { DuplicateIcon } from '../icons/DuplicateIcon';
import { EditIcon } from '../icons/EditIcon';

export function BlockActionSheet(props: {
  block: DtoBlock;
  onDuplicate?: (block: DtoBlock) => void;
  onDelete?: () => void;
}): JSX.Element {
  const { block } = props;
  const summary = useMemo(
    () => BlockKnifeUtils.Summary(fromAPIBlockTypes(block)),
    [block]
  );

  const triggerModal = useAwaitFullScreenConfirmCancelModal();

  const handleDuplicate = async () => {
    const response = await triggerModal({
      kind: 'confirm-cancel',
      prompt: (
        <div className='px-5 py-2'>
          <ConfirmCancelModalHeading>
            {`Duplicate ${summary.title}`}
          </ConfirmCancelModalHeading>
          <ConfirmCancelModalText className='mt-4 text-sms font-normal'>
            Duplicate a block will create a new block with the same content, but
            will not attach to any game, brand or game pack.
          </ConfirmCancelModalText>
        </div>
      ),
      confirmBtnLabel: 'Duplicate',
      confirmBtnVariant: 'primary',
    });
    if (response.result !== 'confirmed') return;

    const resp = await apiService.block.duplicate(block.id);
    if (props.onDuplicate) {
      props.onDuplicate(resp.data.block);
    }
  };

  const handleDelete = async () => {
    const response = await triggerModal({
      kind: 'confirm-cancel',
      prompt: (
        <div className='px-5 py-2'>
          <ConfirmCancelModalHeading>
            {`Delete ${summary.title}`}
          </ConfirmCancelModalHeading>
          <ConfirmCancelModalText className='mt-4 text-sms font-normal'>
            Delete a block will delete all its content and cannot be undone.
          </ConfirmCancelModalText>
        </div>
      ),
      confirmBtnLabel: 'Delete',
      confirmBtnVariant: 'delete',
    });
    if (response.result !== 'confirmed') return;

    await apiService.block.delete(block.id);
    if (props.onDelete) {
      props.onDelete();
    }
  };

  const actions: Action<string>[] = [
    {
      kind: 'link',
      key: 'edit',
      icon: <EditIcon />,
      text: 'Edit',
      href: `/admin/blocks/${block.id}`,
    },
    {
      kind: 'button',
      key: 'duplicate',
      icon: <DuplicateIcon />,
      text: 'Duplicate',
      onClick: handleDuplicate,
    },
    {
      kind: 'button',
      key: 'delete',
      icon: <DeleteIcon />,
      text: 'Delete',
      className: 'text-red-002',
      onClick: handleDelete,
    },
  ];

  if (block.brandId) {
    actions.push({
      kind: 'link',
      key: 'view-brand',
      icon: <CopyLinkIcon />,
      text: 'View Brand',
      href: `/admin/brands/${block.brandId}`,
    });
  }

  if (block.gameId) {
    actions.push({
      kind: 'link',
      key: 'view-game',
      icon: <CopyLinkIcon />,
      text: 'View Mini-Game',
      href: `/admin/minigames?gameId=${block.gameId}`,
    });
  }

  return (
    <ActionSheet
      actions={actions}
      btnSizingClassName='w-7.5 h-7.5'
      placement='bottom-end'
    />
  );
}
