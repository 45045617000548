import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useLatest, usePrevious } from 'react-use';
import { match } from 'ts-pattern';

import { type FirebaseSafeRead } from '@lp-lib/firebase-typesafe';
import {
  calculateScore,
  QuestionBlockAnswerGrade,
  type RoundRobinClue,
  RoundRobinMode,
  type RoundRobinQuestionBlock,
} from '@lp-lib/game';
import { MediaType } from '@lp-lib/media';

import magnifier from '../../../../assets/img/magnifier.png';
import star from '../../../../assets/img/star.png';
import { useMyInstance } from '../../../../hooks/useMyInstance';
import { type Participant } from '../../../../types';
import { MediaUtils } from '../../../../utils/media';
import { playWithCatch } from '../../../../utils/playWithCatch';
import { useClock } from '../../../Clock';
import { Tooltip } from '../../../common/Tooltip';
import { useDatabaseSafeRef, useFirebaseBatchWrite } from '../../../Firebase';
import { XIcon } from '../../../icons/XIcon';
import { FloatLayout } from '../../../Layout';
import { LayoutAnchor } from '../../../LayoutAnchors/LayoutAnchors';
import { useMyTeamId } from '../../../Player';
import { useSoundEffect } from '../../../SFX';
import { useVenueId } from '../../../Venue/VenueProvider';
import { EchoCanceledVideo } from '../../EchoCanceledVideo';
import { useCurrentBlockScore } from '../../hooks';
import { AnchoredGamePlayMediaLayout } from '../Common/GamePlay/GamePlayMediaLayout';
import { type RoundRobinQuestionGradeAPI } from './grader';
import { roundRobinQuestionNoTimer } from './RoundRobinQuestionBlockEditor';
import { RoundRobinQuestionBlockPrompt } from './RoundRobinQuestionBlockPrompt';
import { MultipleChoiceInput } from './RoundRobinQuestionMultipleChoice';
import { RoundRobinRace } from './RoundRobinRace';
import { type Question, QuestionStatus } from './types';
import { RoundRobinQuestionUtils } from './utils';

interface QuestionPlayState {
  remainingSec: number;
}

interface QuestionPlayControl {
  submit: (value: string, isAutoSubmit?: boolean) => Promise<void>;
  skip: () => Promise<void>;
  resetSubmission: () => Promise<void>;
  countdown: () => () => void;
}

function useQuestionPlay(props: {
  block: RoundRobinQuestionBlock;
  question: Question;
  grader: RoundRobinQuestionGradeAPI;
}): [QuestionPlayState, QuestionPlayControl] {
  const { block, question, grader } = props;

  const venueId = useVenueId();
  const me = useMyInstance();
  const myTeamId = me?.teamId || '';
  const ref = useDatabaseSafeRef<Question>(
    RoundRobinQuestionUtils.GetFBPathQuestion(venueId, myTeamId, question.index)
  );

  const batchWrite = useFirebaseBatchWrite();

  const [remainingSec, setRemainingSec] = useState(
    RoundRobinQuestionUtils.ShowQuestionDurationSec(question)
  );
  const latestRemainingSec = useLatest(remainingSec);
  const clock = useClock();

  const singleChoiceSubmit = useCallback(
    async (value: string, isAutoSubmit?: boolean) => {
      if (!me?.id) return;
      const submittedAt = clock.now();
      const submittedSec = latestRemainingSec.current;

      if (!isAutoSubmit) {
        await ref.update({
          submittedAnswer: value,
          submittedAt,
          grade: 'Unknown',
        });
      }

      const result = await grader.grade(me.id, question, value);
      const isCorrect = result.grade === QuestionBlockAnswerGrade.CORRECT;
      const grade =
        result.grade === QuestionBlockAnswerGrade.CORRECT ? 'Correct' : 'Wrong';

      if (!block.fields.rapidSubmissions || isCorrect) {
        const currentPoints = calculateScore(
          QuestionBlockAnswerGrade.CORRECT,
          question.timeSec === roundRobinQuestionNoTimer
            ? false
            : block.fields.decreasingPointsTimer,
          submittedSec,
          question.timeSec,
          question.points,
          block.fields.startDescendingImmediately
        );
        const updates: Partial<FirebaseSafeRead<Question>> = {
          submittedAnswer: value,
          grade,
          submittedAt,
          gainedPoints: isCorrect
            ? currentPoints
            : block.fields.incorrectAnswerPenalty
            ? -currentPoints
            : 0,
        };
        if (!isAutoSubmit) {
          updates.status = QuestionStatus.ShowAnswer;
          updates.statusChangedAt = submittedAt;
        }
        await ref.update(updates);
      } else {
        // wrong rapid submission
        await ref.update({
          submittedAnswer: value,
          grade,
          submittedAt,
          gainedPoints: block.fields.incorrectAnswerPenalty
            ? -question.points
            : 0,
        });
      }
    },
    [
      block.fields.decreasingPointsTimer,
      block.fields.incorrectAnswerPenalty,
      block.fields.rapidSubmissions,
      block.fields.startDescendingImmediately,
      clock,
      grader,
      latestRemainingSec,
      me?.id,
      question,
      ref,
    ]
  );

  const multipleChoiceSubmit = useCallback(
    async (value: string) => {
      if (!question.multipleChoiceAnswers) return;
      const answer = question.multipleChoiceAnswers.find(
        (answer) => answer.id === value
      );
      const isCorrect = !!answer?.correct;
      const grade = isCorrect ? 'Correct' : 'Wrong';
      const submittedSec = latestRemainingSec.current;
      const currentPoints = calculateScore(
        QuestionBlockAnswerGrade.CORRECT,
        question.timeSec === roundRobinQuestionNoTimer
          ? false
          : block.fields.decreasingPointsTimer,
        submittedSec,
        question.timeSec,
        question.points,
        block.fields.startDescendingImmediately
      );
      await ref.update({
        submittedAnswer: value,
        grade,
        submittedAt: clock.now(),
        gainedPoints: isCorrect
          ? currentPoints
          : block.fields.incorrectAnswerPenalty
          ? -currentPoints
          : 0,
        status: QuestionStatus.ShowAnswer,
        statusChangedAt: Date.now(),
      });
    },
    [
      block.fields.decreasingPointsTimer,
      block.fields.incorrectAnswerPenalty,
      block.fields.startDescendingImmediately,
      clock,
      latestRemainingSec,
      question.multipleChoiceAnswers,
      question.points,
      question.timeSec,
      ref,
    ]
  );

  const skip = useCallback(async () => {
    await ref.update({
      grade: 'Skipped',
      status: QuestionStatus.ShowAnswer,
    });
  }, [ref]);

  const resetSubmission = useCallback(async () => {
    await batchWrite({
      [RoundRobinQuestionUtils.GetFBPathQuestionField(
        venueId,
        myTeamId,
        question.index,
        'grade'
      )]: null,
      [RoundRobinQuestionUtils.GetFBPathQuestionField(
        venueId,
        myTeamId,
        question.index,
        'submittedAnswer'
      )]: null,
      [RoundRobinQuestionUtils.GetFBPathQuestionField(
        venueId,
        myTeamId,
        question.index,
        'submittedAt'
      )]: null,
    });
  }, [batchWrite, myTeamId, question.index, venueId]);

  // TODO: move to TimersWorker
  const countdown = useCallback(() => {
    const timer = setInterval(() => {
      setRemainingSec((current) => {
        const next = current - 1;
        if (next <= 0) {
          clearInterval(timer);
          return 0;
        }
        return next;
      });
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  const state: QuestionPlayState = {
    remainingSec,
  };

  const isMultipleChoice = !!question.multipleChoiceAnswers;
  const control: QuestionPlayControl = {
    submit: isMultipleChoice ? multipleChoiceSubmit : singleChoiceSubmit,
    skip,
    resetSubmission,
    countdown,
  };

  return [state, control];
}

function useSubmitterControl(props: {
  enabled: boolean;
  block: RoundRobinQuestionBlock;
  isGamePlaying: boolean;
  question: Question;
  control: QuestionPlayControl;
}) {
  const {
    enabled,
    block,
    isGamePlaying,
    question,
    control: { resetSubmission },
  } = props;

  // Reset wrong answer after 500ms in rapid submission mode
  useEffect(() => {
    if (!block.fields.rapidSubmissions || !enabled) return;

    if (!isGamePlaying) return;
    if (question.status !== QuestionStatus.ShowQuestion) return;
    if (question.grade !== 'Wrong' || !question.submittedAt) return;

    const resetAt = Date.now() + 500;
    const remainMS = resetAt > Date.now() ? resetAt - Date.now() : 0;
    const timer = setTimeout(() => {
      resetSubmission();
    }, remainMS);
    return () => {
      clearTimeout(timer);
    };
  }, [
    block.fields.rapidSubmissions,
    enabled,
    isGamePlaying,
    question.grade,
    question.status,
    question.submittedAt,
    resetSubmission,
  ]);
}

function usePlayerControl(props: {
  question: Question;
  isGamePlaying: boolean;
  state: QuestionPlayState;
  control: QuestionPlayControl;
}) {
  const {
    question,
    isGamePlaying,
    control: { countdown },
  } = props;

  useEffect(() => {
    if (!isGamePlaying) return;
    if (question.status !== QuestionStatus.ShowQuestion) return;

    return countdown();
  }, [countdown, isGamePlaying, question.status]);
}

const useQuestionSoundEffect = (props: {
  question: Question;
  remainTimeSec: number;
  isSubmitter: boolean;
}): void => {
  const { question, remainTimeSec, isSubmitter: isMyTurn } = props;

  const previousIsMyTurn = usePrevious(isMyTurn);

  const { play: playCorrectSFX } = useSoundEffect('rapidCorrect');
  const { play: playWrongSFX } = useSoundEffect('rapidWrong');
  const { play: playBeepSFX } = useSoundEffect('beep');
  const { play: playCardWhooshAway } = useSoundEffect('cardWhooshAway');
  const { play: playYourTurnAlter } = useSoundEffect('yourTurnAlter');

  useEffect(() => {
    if (!question.grade) return;
    if (question.grade === 'Correct') playCorrectSFX();
    if (question.grade === 'Wrong') playWrongSFX();
  }, [playCorrectSFX, playWrongSFX, question.grade]);

  useEffect(() => {
    if (question.status !== QuestionStatus.ShowQuestion) return;
    if (remainTimeSec > question.timeSec * 0.3) return;
    if (remainTimeSec === 0) return;

    playBeepSFX();
  }, [playBeepSFX, question.status, question.timeSec, remainTimeSec]);

  useEffect(() => {
    if (question.status !== QuestionStatus.Ended) return;

    playCardWhooshAway();
  }, [playCardWhooshAway, question.status]);

  useEffect(() => {
    if (question.status !== QuestionStatus.NotStarted) return;
    if (question.index === 0) return;
    if (!isMyTurn) return;

    playYourTurnAlter();
  }, [isMyTurn, playYourTurnAlter, question.index, question.status]);

  useEffect(() => {
    if (question.status !== QuestionStatus.ShowQuestion) return;
    if (previousIsMyTurn || !isMyTurn) return;

    playYourTurnAlter();
  }, [isMyTurn, playYourTurnAlter, previousIsMyTurn, question.status]);
};

function GamePlayMedia(props: {
  question: Question;
  isPlaying: boolean;
  block: RoundRobinQuestionBlock;
}): JSX.Element {
  const { question, isPlaying, block } = props;

  const videoRef = useRef<HTMLVideoElement | null>(null);

  const media = useMemo(() => {
    if (!question.answerMedia) return question.questionMedia;

    const revealAnswer = RoundRobinQuestionUtils.ShowRevealAnswer(
      block,
      question
    );
    if (revealAnswer) return question.answerMedia;
    return question.questionMedia;
  }, [block, question]);

  const mediaUrl = MediaUtils.PickMediaUrl(media);

  useEffect(() => {
    if (!isPlaying) return;

    const el = videoRef.current;
    if (!el) return;

    if (!el.ended) playWithCatch(el);
    return () => {
      if (!el.ended) el.pause();
    };
  }, [isPlaying, media?.id]);

  if (!media || !mediaUrl) return <></>;

  return (
    <AnchoredGamePlayMediaLayout
      debugName='round-robin-question'
      mode='small'
      z='z-30'
      disableTransition
      justify='start'
    >
      {media.type === MediaType.Video && (
        <EchoCanceledVideo
          mediaId={media.id}
          ref={videoRef}
          containerClassName=''
          className='rounded-xl w-full h-full object-contain'
          mediaUrl={mediaUrl}
          autoPlay={false}
          loop={
            question.status < QuestionStatus.ShowAnswer &&
            question.questionMediaLoop
          }
          debugKey='rr-question-video'
          volumeControl='game'
        />
      )}
      {media.type === MediaType.Image && (
        <img
          className={`rounded-xl w-full h-full object-contain`}
          src={mediaUrl}
          alt='luna-park'
        />
      )}
    </AnchoredGamePlayMediaLayout>
  );
}

export function RoundRobinQuestionPlay(props: {
  isSubmitter: boolean;
  isGamePlaying: boolean;
  block: RoundRobinQuestionBlock;
  question: Question;
  submitter: Participant;
  isTransition: boolean;
  subsequentSubmitter: Participant[];
  grader: RoundRobinQuestionGradeAPI;
  showPrompt: boolean;
}): JSX.Element | null {
  const {
    isSubmitter,
    block,
    question,
    isGamePlaying,
    submitter,
    isTransition,
    subsequentSubmitter,
    grader,
    showPrompt,
  } = props;

  const isMultipleChoice = !!question.multipleChoiceAnswers;

  const [state, control] = useQuestionPlay({
    block,
    question,
    grader,
  });

  usePlayerControl({
    question,
    isGamePlaying,
    state,
    control,
  });

  useSubmitterControl({
    enabled: isSubmitter,
    block,
    isGamePlaying,
    question,
    control,
  });

  const teamId = useMyTeamId();

  const myCurrentBlockTeamScore = useCurrentBlockScore(teamId);

  useQuestionSoundEffect({
    question,
    remainTimeSec: state.remainingSec,
    isSubmitter: isSubmitter,
  });

  const mode = block.fields.mode ?? RoundRobinMode.Default;

  const withInputField = !isMultipleChoice && !block.fields.hideAnswerField;

  return (
    <FloatLayout
      className={`flex flex-col z-30 ${
        mode === RoundRobinMode.Race
          ? 'bottom-[0vh] left-74 2xl:left-80 right-74 2xl:right-80'
          : 'bg-white bg-opacity-5 rounded-xl bottom-[2vh]'
      }`}
      useCustomPaddingX={mode === RoundRobinMode.Race}
    >
      <RoundRobinQuestionBlockPrompt
        key={submitter.clientId}
        block={block}
        question={question}
        submitter={submitter}
        remainTimeSec={state.remainingSec}
        currentPoints={myCurrentBlockTeamScore ?? 0}
        onSubmit={control.submit}
        onSkip={control.skip}
        isTransition={isTransition}
        candidates={subsequentSubmitter}
        isGamePlaying={isGamePlaying}
        withInputField={withInputField}
        hidePoints={block.fields.hideAnswerField}
        show={showPrompt}
      />
      {match(mode)
        .with(RoundRobinMode.Race, () => (
          <RoundRobinRace block={block} isGamePlaying={isGamePlaying} />
        ))
        .otherwise(() => {
          if (!isMultipleChoice) {
            return (
              <div className='flex flex-grow relative'>
                <GamePlayMedia
                  question={question}
                  isPlaying={isGamePlaying}
                  block={block}
                />
              </div>
            );
          }
          return (
            <div className='flex flex-grow items-start gap-2'>
              <div className='flex z-5 relative w-3/4 h-full'>
                <GamePlayMedia
                  question={question}
                  isPlaying={isGamePlaying}
                  block={block}
                />
              </div>
              <MultipleChoiceInput
                block={block}
                question={question}
                submitter={submitter}
                onSubmit={control.submit}
                isGamePlaying={isGamePlaying}
              />
            </div>
          );
        })}
      <LayoutAnchor
        id={'gameplay-question-text-anchor'}
        className='w-full h-0 absolute bottom-0'
        layoutReportDelayMs={0}
      />
    </FloatLayout>
  );
}

export function RoundRobinQuestionClues(props: {
  block: RoundRobinQuestionBlock;
  question: Question;
  isSubmitter: boolean;
}): JSX.Element {
  const { block, question, isSubmitter } = props;
  const [showClues, setShowClues] = useState(false);

  const me = useMyInstance();
  const venueId = useVenueId();

  const ref = useDatabaseSafeRef<Question>(
    RoundRobinQuestionUtils.GetFBPathQuestion(
      venueId,
      me?.teamId || '',
      question.index
    )
  );

  useEffect(() => {
    if (question.status !== QuestionStatus.ShowQuestion) {
      setShowClues(false);
      return;
    }
    const timer = setTimeout(() => {
      setShowClues(true);
    }, question.statusChangedAt + block.fields.clueTimeSec * 1000 - Date.now());
    return () => {
      clearTimeout(timer);
      setShowClues(false);
    };
  }, [block.fields.clueTimeSec, question.status, question.statusChangedAt]);

  if (
    !showClues ||
    !question.clues ||
    block.fields.hotSeatUI ||
    question.clues.length === 0
  ) {
    return <div className='w-32 h-60'></div>;
  }

  return (
    <div className='relative flex flex-col gap-3 w-32 h-60'>
      {question.clues.map((clue, index) => {
        return (
          <RoundRobinQuestionClue
            key={clue.clueId}
            questionIndex={question.index}
            clueIndex={index}
            currentClueIndex={question.clueIndex}
            clue={clue}
            clickable={isSubmitter}
            onChange={() => ref.update({ clueIndex: index })}
            onClose={() => ref.update({ clueIndex: -1 })}
          />
        );
      })}
    </div>
  );
}

export function RoundRobinQuestionClue(props: {
  questionIndex: number;
  clueIndex: number;
  currentClueIndex: number;
  clue: RoundRobinClue;
  clickable: boolean;
  onChange: () => void;
  onClose: () => void;
}): JSX.Element {
  const {
    questionIndex,
    clueIndex,
    currentClueIndex,
    clue,
    clickable,
    onChange,
    onClose,
  } = props;

  const venueId = useVenueId();
  const me = useMyInstance();
  const ref = useDatabaseSafeRef<RoundRobinClue>(
    RoundRobinQuestionUtils.GetFBPathQuestionClue(
      venueId,
      me?.teamId || '',
      questionIndex,
      clueIndex
    )
  );

  const handleClickClueButton = () => {
    if (!clickable) {
      return;
    }
    onChange();
    ref.update({ used: true });
  };

  const handleCloseClue = () => {
    if (!clickable) {
      return;
    }
    onClose();
  };

  return (
    <div className='relative'>
      <button
        type='button'
        className={`relative btn w-32 h-19 border focus:none rounded-2xl ${
          clue.used ? `bg-escape-room-used-clue` : ` bg-escape-room-unused-clue`
        } ${
          clickable ? `` : `hover:cursor-default`
        } border-white border-opacity-40`}
        onClick={handleClickClueButton}
      >
        {!clue.used && (
          <img src={star} alt='logo' className='absolute right-0.5 top-0.5' />
        )}
        <img src={magnifier} alt='logo' className='absolute left-5 top-0' />

        <div
          className={`w-full text-xl font-black text-yellow-200 absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2`}
        >
          {/* A quick & dirty solution to auto scale font size */}
          {clue.title ? (
            <div
              style={{
                fontSize: `${
                  clue.title.length <= 7
                    ? 20
                    : clue.title.length <= 11
                    ? 16
                    : 13
                }px`,
              }}
            >
              {clue.title}
            </div>
          ) : (
            <div className=''>Clues {clueIndex + 1}</div>
          )}
          {!clue.used && (
            <div className='text-sm text-white'>
              {clue.points === 0 ? 'Free' : `${clue.points} pts`}
            </div>
          )}
        </div>
      </button>
      {clueIndex === currentClueIndex && (
        <div className='absolute -left-15 -top-5 z-35'>
          <Tooltip
            position={'bottom'}
            backgroundColor={'#FBB707'}
            borderRadius={13}
            filter={'drop-shadow(0px 2px 6px rgba(0, 0, 0, 0.5))'}
          >
            <div className='w-72 h-42 text-black text-center flex flex-col pt-1 pr-1 pb-1'>
              {clickable && (
                <div
                  className='absolute text-center w-5 h-5 top-1 right-0.5 rounded-full cursor-pointer'
                  onClick={handleCloseClue}
                >
                  <XIcon />
                </div>
              )}
              <p className='h-4/6 w-72 text-base break-words p-3 font-bold'>
                {clue.text}
              </p>
            </div>
          </Tooltip>
        </div>
      )}
    </div>
  );
}
