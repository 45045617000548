import { useParticipantByUserId } from '../../../../../Player';
import { useTeam } from '../../../../../TeamAPI/TeamV1';
import { type Grade } from '../../grader';

const UserBadge = ({
  userId,
  teamId,
}: {
  userId?: string;
  teamId?: string | null;
}): JSX.Element => {
  const team = useTeam(teamId);
  const participant = useParticipantByUserId(userId);
  const username = participant?.username ?? '';
  const teamName = team ? `(${team.name})` : '';
  return (
    <div className='mb-1'>
      <span className='h-4 px-2 inline-block font-bold text-3xs text-white rounded-lg bg-lp-gray-003'>
        {`${username} ${teamName}`}
      </span>
    </div>
  );
};

export type PartialCreditGradingCardProps = {
  // the answer to regrade
  answer: string;

  // the teams that submitted this answer
  submitters: {
    submitterUid?: string;
    teamId: string;
  }[];

  // the current grade for this answer, or null if ungraded
  currentGrade: Grade | null;

  // called when the user selects a _new_ grade.
  onRegrade: (grade: Grade) => void;
};

export function PartialCreditGradingCard(
  props: PartialCreditGradingCardProps
): JSX.Element {
  const { answer, submitters, currentGrade, onRegrade } = props;
  const handleRegrade = (grade: Grade) => {
    if (grade === currentGrade) return;
    onRegrade(grade);
  };

  return (
    <div className='absolute bottom-0 pb-3 mx-3 flex flex-col justify-end items-end inset-0 z-10 outline-none focus:outline-none'>
      <div className='w-full h-80 p-3 relative flex flex-col justify-between bg-black rounded-xl border border-transparent border-solid box-border bg-clip-padding before:gradientBorder'>
        <p className='text-2xs'>Answer submitted:</p>
        <div className='flex flex-col justify-center items-center'>
          <p className='w-44 my-2 text-center font-bold text-base whitespace-normal overflow-ellipsis break-words'>
            {answer}
          </p>
        </div>
        <div className='relative h-32 overflow-y-scroll scrollbar-hide'>
          <p className='sticky top-0 text-2xs mb-1 bg-black'>
            Answer submitted by:
          </p>
          {submitters.map((user, i) => (
            <UserBadge
              key={`user-badge-${i}`}
              userId={user.submitterUid}
              teamId={user.teamId}
            />
          ))}
        </div>
        <div className='flex flex-row justify-between items-center'>
          <button
            type='button'
            id='controller-grading-wrong'
            onClick={() => handleRegrade('incorrect')}
            className={`w-18 h-8 btn border border-red-600 bg-red-600 ${
              currentGrade === 'incorrect' ? 'bg-opacity-100' : 'bg-opacity-30'
            } hover:bg-opacity-100 rounded-lg text-white flex flex-row justify-center items-center text-2xs`}
          >
            Wrong
          </button>
          <button
            type='button'
            id='controller-grading-half'
            onClick={() => handleRegrade('half')}
            className={`w-15 h-8 btn border border-secondary ${
              currentGrade === 'half'
                ? 'border-tertiary bg-tertiary'
                : 'hover:border-gray-500'
            } hover:border-tertiary hover:bg-tertiary rounded-lg text-white flex flex-row justify-center items-center text-2xs`}
          >
            Half
          </button>
          <button
            type='button'
            id='controller-grading-correct'
            onClick={() => handleRegrade('correct')}
            className={`w-18 h-8 btn border border-primary bg-primary ${
              currentGrade === 'correct' ? 'bg-opacity-100' : 'bg-opacity-30'
            } hover:bg-opacity-100 rounded-lg text-white flex flex-row justify-center items-center text-2xs`}
          >
            Correct
          </button>
        </div>
      </div>
    </div>
  );
}
