import { useMemo } from 'react';
import { useSnapshot } from 'valtio';

import {
  type CreativePromptBlockAnswerData,
  type CreativePromptBlockDetailScore,
  CreativePromptBlockGameSessionStatus,
  type TeamDataList,
} from '@lp-lib/game';

import { store as reduxStore } from '../../../../store/configureStore';
import { useMyTeamId, useParticipants } from '../../../Player';
import { useTeams } from '../../../TeamAPI/TeamV1';
import { useMyClientId } from '../../../Venue';
import {
  fetchAllTeamsData,
  fetchDetailScoresData,
  gameSessionStore,
  type GameSessionTeamData,
} from '../../store/';

export interface Submission {
  answer: string;
  teamId: string;
  userId: string;
}

export interface CreativePromptResults {
  teamId: string;
  teamName: string;
  answer: string;
  votes: number;
}

export interface CreativePromptTeamData {
  teamId: string;
  userId: string | null;
  teamName: string | null;
  answer: string | null;
  votes: number;
  totalScore: number | null;
  previewPoints: number;
}

export interface CreativePromptSummaryData {
  teamDataList: CreativePromptTeamData[];
  totalTeamCount: number;
  submittedTeamCount: number;
}

export interface CreativePromptVoteCounts {
  myTeamVoteCount: number;
  totalVoteCount: number;
}

export const useCreativePromptVoteCounts = (
  setVoteCounts: (data: CreativePromptVoteCounts | null) => void
): void => {
  const blockSession = useSnapshot(gameSessionStore).session.blockSession;
  const blockId = blockSession?.block?.id ?? null;
  const myTeamId = useMyTeamId();

  useMemo(async () => {
    let data = null;

    if (blockId) {
      const blockDetailScores = (
        gameSessionStore.detailScores
          ? gameSessionStore.detailScores[blockId]
          : await fetchDetailScoresData(blockId)
      ) as TeamDataList<CreativePromptBlockDetailScore> | null;

      const blockDetailScoresTeamIds = Object.keys(blockDetailScores || {});

      if (blockDetailScores && blockDetailScoresTeamIds.length > 0) {
        let totalVoteCount = 0;
        let myTeamVoteCount = 0;
        blockDetailScoresTeamIds.forEach((teamId) => {
          const votes = blockDetailScores[teamId].votes || 0;
          if (teamId === myTeamId) {
            myTeamVoteCount = votes;
          }
          totalVoteCount += votes;
        });

        data = {
          totalVoteCount,
          myTeamVoteCount,
        };
      }
    }

    setVoteCounts(data);
  }, [blockId, myTeamId, setVoteCounts]);
};

export const useCreativePromptTeamData = (
  selectedBlockId?: string | null
): CreativePromptSummaryData => {
  const teamList = useTeams();

  const session = useSnapshot(gameSessionStore).session;
  const blockId = selectedBlockId || (session.blockSession?.block?.id ?? null);
  const detailScores = useSnapshot(gameSessionStore).detailScores;
  const scoreSummary = useSnapshot(gameSessionStore).scoreSummary;
  const blockDetailScores = blockId ? detailScores?.[blockId] ?? null : null;
  const gameSessionStatus =
    session.status as CreativePromptBlockGameSessionStatus;
  const sortBy =
    gameSessionStatus === null ||
    gameSessionStatus === CreativePromptBlockGameSessionStatus.LOADED ||
    gameSessionStatus >= CreativePromptBlockGameSessionStatus.POINTS_DISTRIBUTED
      ? 'score'
      : 'vote';

  return useMemo(() => {
    const teamDataList: CreativePromptTeamData[] = [];
    let submittedTeamCount = 0;

    teamList.forEach((t) => {
      const detailScore = blockDetailScores?.[
        t.id
      ] as CreativePromptBlockDetailScore | null;

      const score = detailScore?.score ?? null;
      const scoreOverride = detailScore?.scoreOverride ?? null;
      const votes = detailScore?.votes ?? 0;
      const previewPoints =
        score === null
          ? scoreOverride === null
            ? detailScore?.previewPoints ?? 0
            : scoreOverride
          : score;

      teamDataList.push({
        teamId: t.id,
        teamName: t.name,
        answer: detailScore?.answer ?? null,
        votes,
        userId: detailScore?.submitterUid ?? null,
        totalScore: scoreSummary?.[t.id]?.totalScore || 0,
        previewPoints,
      });

      if (detailScore?.answer && detailScore.answer.length > 0) {
        submittedTeamCount++;
      }
    });

    teamDataList.sort(
      (a, b) =>
        a.teamName?.localeCompare(b.teamName || '', 'en', {
          sensitivity: 'base',
        }) || 0
    );

    const sortFn =
      sortBy === 'score'
        ? (a: CreativePromptTeamData, b: CreativePromptTeamData) =>
            (b.totalScore || 0) - (a.totalScore || 0)
        : (a: CreativePromptTeamData, b: CreativePromptTeamData) =>
            (b.votes || 0) - (a.votes || 0);

    teamDataList.sort(sortFn);

    return {
      teamDataList,
      submittedTeamCount,
      totalTeamCount: teamList.length,
    };
  }, [blockDetailScores, scoreSummary, sortBy, teamList]);
};

export const useCreativePromptResults = (
  blockId: string,
  setResults: (results: CreativePromptResults[]) => void
): void => {
  useMemo(async () => {
    const results = [] as CreativePromptResults[];
    const allTeamsData = (await fetchAllTeamsData(blockId)) as TeamDataList<
      GameSessionTeamData<CreativePromptBlockAnswerData>
    > | null;

    if (allTeamsData) {
      const teams = reduxStore.getState().team.teams;

      Object.keys(allTeamsData).forEach((teamId) => {
        const data = allTeamsData[teamId]?.data ?? null;

        if (data && data.answer) {
          results.push({
            teamId,
            teamName: teams[teamId]?.name ?? '',
            answer: data.answer,
            votes: new Set(data.voterUserIds.split(',').filter(Boolean) || [])
              .size,
          });
        }
      });
    }

    results.sort(
      (a: CreativePromptResults, b: CreativePromptResults) =>
        (b.votes || 0) - (a.votes || 0)
    );

    setResults(results);
  }, [blockId, setResults]);
};

export const useCreativePromptSubmissions = (
  blockId: string,
  setSubmissions: (submissions: Submission[]) => void
): void => {
  const myClientId = useMyClientId();
  const participants = useParticipants();
  const myTeamId =
    participants && myClientId
      ? participants[myClientId]?.teamId ?? null
      : null;

  useMemo(async () => {
    const results = [] as Submission[];
    const allTeamsData = (await fetchAllTeamsData(blockId)) as TeamDataList<
      GameSessionTeamData<CreativePromptBlockAnswerData>
    > | null;

    if (allTeamsData) {
      Object.keys(allTeamsData).forEach((teamId) => {
        const data = allTeamsData[teamId]?.data ?? null;
        if (data && data.answer) {
          results.push({
            answer: data.answer,
            teamId,
            userId: data.submitterUid,
          });
        }
      });
    }

    const myTeamIndex = results.findIndex((data) => data.teamId === myTeamId);

    if (myTeamIndex > 0) {
      const myTeamData = results[myTeamIndex];
      results.splice(myTeamIndex, 1);
      results.unshift(myTeamData);
    }

    setSubmissions(results);
  }, [blockId, myTeamId, setSubmissions]);
};
