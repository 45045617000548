import React, { useContext, useEffect, useMemo } from 'react';

import { type Logger } from '@lp-lib/logger-base';

import { uuidv4 } from '../../../../../utils/common';
import { Clock } from '../../../../Clock';
import { type FirebaseService } from '../../../../Firebase';
import { useParticipantsAsArray } from '../../../../Player';
import { useTeamMembersByTeamIds, useTeams } from '../../../../TeamAPI/TeamV1';
import { type GameSettings, GameState, type GroupPlayersMap } from '../types';
import { log, OverRoastedFirebaseUtils, OverRoastedUtils } from '../utils';
import { OverRoastedGroupControl } from './OverRoastedGroupControl';
import { OrderManager } from './OverRoastedOrderManager';
import {
  type OverRoastedSharedState,
  useOverRoastedSharedContext,
} from './OverRoastedSharedProvider';
import { TutorialManager } from './OverRoastedTutorialManager';
import { uncheckedIndexAccess_UNSAFE } from '../../../../../utils/uncheckedIndexAccess_UNSAFE';

class OverRoastedGameControlAPI {
  private orderManagers: OrderManager[] = [];
  private tutorialManagers: TutorialManager[] = [];
  private groupControls: OverRoastedGroupControl[] = [];

  constructor(
    private venueId: string,
    private sharedState: OverRoastedSharedState,
    private log: Logger,
    private firebaseService: FirebaseService,
    private rootRef = OverRoastedFirebaseUtils.RootHandle(
      firebaseService,
      venueId
    ),
    private gameHandle = OverRoastedFirebaseUtils.GameHandle(
      firebaseService,
      venueId
    ),
    private settingsHandle = OverRoastedFirebaseUtils.SettingsHandle(
      firebaseService,
      venueId
    ),
    private summaryHandle = OverRoastedFirebaseUtils.SummaryHandle(
      firebaseService,
      venueId
    )
  ) {}

  async resetGame() {
    this.log.info('reset game');
    for (const m of this.orderManagers) {
      m.stopAutomation();
    }
    for (const m of this.tutorialManagers) {
      m.stop();
    }
    this.orderManagers = [];
    this.tutorialManagers = [];
    for (const c of this.groupControls) {
      c.stop();
    }
    this.groupControls = [];
    await this.rootRef.remove();
  }

  async initGame(
    groupPlayersMap: GroupPlayersMap,
    settingsOverride: Partial<GameSettings>
  ) {
    const settings = {
      ...this.sharedState.settings,
      ...settingsOverride,
    };

    const updates = uncheckedIndexAccess_UNSAFE({});
    for (const [groupId, playerIds] of Object.entries(groupPlayersMap)) {
      // 1. create trucks
      const trucks = OverRoastedUtils.BuildTrucks(settings.numOfTrucks);
      updates[OverRoastedFirebaseUtils.GroupPath(groupId, 'trucks')] = trucks;
      // 2. distribute ingredients
      const props = OverRoastedUtils.BuildProps(
        playerIds,
        settings.maxIngredientsPerPlayer
      );
      updates[OverRoastedFirebaseUtils.GroupPath(groupId, 'props')] = props;
      // 3. create machines (cups + dispensers)
      for (const truck of trucks) {
        const machines = OverRoastedUtils.BuildMachines(
          truck.id,
          settings.numOfDispensersPerTruck
        );
        for (const machine of machines) {
          updates[
            `${OverRoastedFirebaseUtils.GroupPath(groupId, 'machines')}/${
              machine.id
            }`
          ] = machine;
        }
      }
      // 4. create order managers & tutorial manager
      const ingredients = props.map((p) => p.ingredient);
      for (const truck of trucks) {
        const orderManager = new OrderManager(
          groupId,
          truck.id,
          ingredients,
          settings.maxIngredientsPerOrder,
          settings.numOfOrders,
          this.log,
          OverRoastedFirebaseUtils.OrdersHandle(
            this.firebaseService,
            this.venueId,
            groupId
          )
        );
        this.orderManagers.push(orderManager);

        const tutorialManager = new TutorialManager(
          groupId,
          OverRoastedFirebaseUtils.TutorialProgressHandle(
            this.firebaseService,
            this.venueId,
            groupId
          ),
          orderManager
        );
        this.tutorialManagers.push(tutorialManager);
      }
      // 5. create players
      const players = OverRoastedUtils.BuildPlayerMap(playerIds);
      updates[OverRoastedFirebaseUtils.GroupPath(groupId, 'players')] = players;

      const groupControl = new OverRoastedGroupControl(
        this.venueId,
        groupId,
        this.firebaseService
      );
      this.groupControls.push(groupControl);
    }
    await this.rootRef.update(updates);

    await Promise.all(this.groupControls.map((c) => c.init()));

    // we are leveraging summaryMap to know the participated groups
    const summaryMap = OverRoastedUtils.BuildSummaryMap(
      Object.keys(groupPlayersMap)
    );
    await this.summaryHandle.set(summaryMap);

    await this.settingsHandle.set(settings);

    await this.gameHandle.set({
      id: uuidv4(),
      state: GameState.Inited,
      stateUpdatedAt: Clock.instance().now(),
    });

    this.log.info('init game', {
      groupsCount: Object.keys(groupPlayersMap).length,
      settings,
      updates,
      summaryMap,
    });
  }

  async startGame() {
    await Promise.all(this.groupControls.map((c) => c.start()));

    if (!this.sharedState.settings.tutorialMode) {
      await Promise.all(
        this.orderManagers.map(async (m) => {
          await m.build(2);
          m.startAutomation(2);
        })
      );
    } else {
      await Promise.all(this.tutorialManagers.map((m) => m.start()));
    }

    await this.gameHandle.update({
      state: GameState.InProgress,
      stateUpdatedAt: Clock.instance().now(),
    });

    this.log.info('start game', {
      isTutorial: this.sharedState.settings.tutorialMode,
    });
  }

  async stopGame() {
    for (const m of this.orderManagers) {
      m.stopAutomation();
    }
    for (const m of this.tutorialManagers) {
      m.stop();
    }

    await this.gameHandle.update({
      state: GameState.Ended,
      stateUpdatedAt: Clock.instance().now(),
    });

    this.log.info('stop game');
  }

  async deinitGame() {
    for (const m of this.groupControls) {
      m.stop();
    }

    await this.gameHandle.update({
      state: GameState.None,
      stateUpdatedAt: Clock.instance().now(),
    });

    this.log.info('deinit game');
  }

  getGroupControls(): OverRoastedGroupControl[] {
    return this.groupControls;
  }
}

type Context = {
  api: OverRoastedGameControlAPI;
};

const context = React.createContext<Context | null>(null);

function useOverRoastedGameControlContext(): Context {
  const ctx = useContext(context);
  if (!ctx)
    throw new Error('OverRoastedGameControlContext is not in the tree!');
  return ctx;
}

export function useOverRoastedGameControlAPI(): OverRoastedGameControlAPI {
  const { api } = useOverRoastedGameControlContext();
  return api;
}

export function useSelectCandidateGroupPlayersMap(
  tutorialMode: boolean
): GroupPlayersMap {
  const teams = useTeams({
    updateStaffTeam: true,
    excludeStaffTeam: true,
  });
  const teamIds = teams.map((t) => t.id);
  const teamMembers = useTeamMembersByTeamIds(teamIds);
  const participants = useParticipantsAsArray({
    filters: ['host:false', 'cohost:false', 'status:connected', 'team:true'],
  });

  return useMemo(() => {
    const mp: GroupPlayersMap = {};

    if (tutorialMode) {
      for (const participant of participants) {
        mp[participant.id] = [participant.id];
      }
    } else {
      const userMap = new Map<string, string>();
      participants.forEach((p) => {
        userMap.set(p.clientId, p.id);
      });

      for (const [teamId, members] of Object.entries(teamMembers)) {
        mp[teamId] = [];
        for (const member of members) {
          const uid = userMap.get(member.id);
          if (!uid) {
            log.warn('uid not found', { clientId: member.id });
            continue;
          }
          mp[teamId].push(uid);
        }
      }
    }

    return mp;
  }, [tutorialMode, participants, teamMembers]);
}

export function OverRoastedGameControlProvider(props: {
  venueId: string;
  firebaseService: FirebaseService;
  ready: boolean;
  children?: React.ReactNode;
}): JSX.Element | null {
  const { venueId, firebaseService, ready, children } = props;

  const { state: sharedState } = useOverRoastedSharedContext();

  const ctxValue = useMemo(
    () => ({
      api: new OverRoastedGameControlAPI(
        venueId,
        sharedState,
        log,
        firebaseService
      ),
    }),
    [sharedState, firebaseService, venueId]
  );

  useEffect(() => {
    if (!ready) return;
    return () => {
      ctxValue.api.resetGame();
    };
  }, [ctxValue.api, ready]);

  return <context.Provider value={ctxValue}>{children}</context.Provider>;
}
