import { useMemo } from 'react';

import {
  QuestionBlockAnswerGrade,
  type QuestionBlockAnswerGradeModel,
  type RoundRobinQuestionBlock,
} from '@lp-lib/game';
import { type Logger } from '@lp-lib/logger-base';

import {
  getFeatureQueryParamArray,
  getFeatureQueryParamNumber,
} from '../../../../hooks/useFeatureQueryParam';
import { apiService } from '../../../../services/api-service';
import { type AIGradeAPI } from '../../../../services/api-service/ai.grade.api';
import { assertExhaustive } from '../../../../utils/common';
import { Emitter, type EmitterListener } from '../../../../utils/emitter';
import { useVenueId } from '../../../Venue/VenueProvider';
import { useIsLiveGamePlay } from '../../hooks';
import { type GradeEvents, type GradeResult } from '../Common/grader';
import { type Question } from './types';
import { log, RoundRobinQuestionUtils } from './utils';

export class RoundRobinQuestionGradeAPI
  implements EmitterListener<GradeEvents>
{
  private emitter = new Emitter<GradeEvents>();
  on = this.emitter.on.bind(this.emitter);
  off = this.emitter.off.bind(this.emitter);

  constructor(
    readonly venueId: string,
    readonly block: RoundRobinQuestionBlock,
    readonly aiGrade: {
      ctrl: 'enabled' | 'disabled' | 'standard';
      temperature: number;
      grader: AIGradeAPI['gradeSubmission'];
    },
    readonly logger?: Logger
  ) {}

  private async gradeWithPreset(
    question: Question,
    answer: string
  ): Promise<GradeResult> {
    const value = answer.trim().toLowerCase();

    const isCorrect = RoundRobinQuestionUtils.IsAnswerCorrect(question, value);

    return {
      model: 'Preset',
      grade: isCorrect
        ? QuestionBlockAnswerGrade.CORRECT
        : QuestionBlockAnswerGrade.NONE,
      timeMs: 0,
    };
  }

  private gradeWithGPT3 = this.makeGradeWithGPT('GPTv3');
  private gradeWithGPT4 = this.makeGradeWithGPT('GPTv4');

  public async grade(userId: string, question: Question, value: string) {
    const correctAnswers = RoundRobinQuestionUtils.CorrectAnswers(question);
    const answer = RoundRobinQuestionUtils.NormalizeAnswer(value);

    const graders = [this.gradeWithPreset.bind(this)];

    switch (this.aiGrade.ctrl) {
      case 'enabled':
        graders.push(this.gradeWithGPT3.bind(this));
        graders.push(this.gradeWithGPT4.bind(this));
        break;
      case 'standard':
        if (!this.block.fields.rapidSubmissions) {
          graders.push(this.gradeWithGPT3.bind(this));
          graders.push(this.gradeWithGPT4.bind(this));
        }
        break;
      case 'disabled':
        break;
      default:
        assertExhaustive(this.aiGrade.ctrl);
        break;
    }

    const results: GradeResult[] = [];
    for (const grader of graders) {
      const r = await grader(question, answer);
      results.push(r);
      if (r.grade !== QuestionBlockAnswerGrade.NONE) {
        break;
      }
    }
    this.emitter.emit('submission-graded', {
      question: question.question,
      results,
      userSubmission: answer,
      submitterUid: userId,
      correctAnswers: this.firstNCorrectAnswers(correctAnswers),
    });
    const finalResult = results[results.length - 1] ?? {
      model: 'Preset',
      grade: QuestionBlockAnswerGrade.NONE,
    };
    return finalResult;
  }

  private firstNCorrectAnswers(correctAnswers: string[], n = 3) {
    return correctAnswers.slice(0, n);
  }

  private makeGradeWithGPT(
    model: Exclude<QuestionBlockAnswerGradeModel, 'Preset'>
  ) {
    return async (
      question: Question,
      userSubmission: string
    ): Promise<GradeResult> => {
      const correctAnswers = RoundRobinQuestionUtils.CorrectAnswers(question);
      const req = {
        model,
        temperature: this.aiGrade.temperature,
        correctAnswers: this.firstNCorrectAnswers(correctAnswers),
        userSubmission,
      };
      const t1 = Date.now();
      try {
        const resp = await this.aiGrade.grader(req);
        return {
          model: model,
          grade: resp.data.evaluation
            ? QuestionBlockAnswerGrade.CORRECT
            : QuestionBlockAnswerGrade.NONE,
          timeMs: Date.now() - t1,
          error: null,
        };
      } catch (error) {
        this.logger?.error('failed to grade by GPT', error, {
          blockId: this.block.id,
          venueId: this.venueId,
          req,
        });
        return {
          model: model,
          grade: QuestionBlockAnswerGrade.NONE,
          timeMs: Date.now() - t1,
          error,
        };
      }
    };
  }
}

export function useGradeAPI(block: RoundRobinQuestionBlock) {
  const venueId = useVenueId();
  const isLiveGame = useIsLiveGamePlay();
  return useMemo(
    () =>
      new RoundRobinQuestionGradeAPI(
        venueId,
        block,
        {
          ctrl: isLiveGame
            ? 'disabled'
            : getFeatureQueryParamArray('round-robin-block-ai-grade'),
          temperature: getFeatureQueryParamNumber(
            'round-robin-block-ai-grade-temperature',
            true
          ),
          grader: apiService.aiGrade.gradeSubmission.bind(apiService.aiGrade),
        },
        log
      ),
    [venueId, block, isLiveGame]
  );
}
