import { useEffect } from 'react';
import { usePreviousDistinct } from 'react-use';

import {
  type MemoryMatchBlock,
  MemoryMatchBlockGameSessionStatus,
} from '@lp-lib/game';

import { assertExhaustive, nullOrUndefined } from '../../../../utils/common';
import { useGameSessionLocalTimer, useGameSessionStatus } from '../../hooks';
import { type GameControlProps } from '../Common/GameControl/types';
import { useStableBlock } from '../Common/hooks';
import {
  useMemoryMatchGame,
  useMemoryMatchGameControl,
} from './MemoryMatchProvider';
import { GameState } from './types';

type SharedProps = GameControlProps<MemoryMatchBlock>;

function Loaded(): JSX.Element | null {
  const { resetGame } = useMemoryMatchGameControl();
  useEffect(() => {
    resetGame('loaded');
  }, [resetGame]);
  return null;
}

function GameInit(props: SharedProps): JSX.Element | null {
  const { block } = props;
  const { initGame } = useMemoryMatchGameControl();

  useEffect(() => {
    initGame(block);
  }, [block, initGame]);

  return null;
}

function GameStart(_props: SharedProps): JSX.Element | null {
  const timeLeft = useGameSessionLocalTimer();
  const { startGame, stopGame } = useMemoryMatchGameControl();

  const game = useMemoryMatchGame();
  const gameState = game?.state;

  useEffect(() => {
    startGame();
  }, [startGame]);

  useEffect(() => {
    if (
      nullOrUndefined(timeLeft) ||
      nullOrUndefined(gameState) ||
      timeLeft > 0 ||
      gameState < GameState.InProgress
    )
      return;
    stopGame();
  }, [gameState, stopGame, timeLeft]);

  return null;
}

function GameEnd(_props: SharedProps): JSX.Element | null {
  const { deinitGame, stopGame } = useMemoryMatchGameControl();

  useEffect(() => {
    stopGame();
  }, [stopGame]);

  useEffect(() => {
    return () => {
      deinitGame();
    };
  }, [deinitGame]);

  return null;
}

export function MemoryMatchBlockGameControl(
  props: SharedProps
): JSX.Element | null {
  const block = useStableBlock(props.block);
  const gameSessionStatus =
    useGameSessionStatus<MemoryMatchBlockGameSessionStatus>();
  const { resetGame } = useMemoryMatchGameControl();
  const currBlockId = block.id;
  const prevBlockId = usePreviousDistinct(currBlockId);

  useEffect(() => {
    if (prevBlockId && prevBlockId !== currBlockId) {
      resetGame(`block id changed prev:${prevBlockId} curr:${currBlockId}`);
    }
  }, [currBlockId, resetGame, prevBlockId]);

  useEffect(() => {
    return () => {
      resetGame('game contol unload');
    };
  }, [resetGame]);

  switch (gameSessionStatus) {
    case MemoryMatchBlockGameSessionStatus.LOADED:
      return <Loaded />;
    case MemoryMatchBlockGameSessionStatus.GAME_INIT:
      return <GameInit block={block} />;
    case MemoryMatchBlockGameSessionStatus.GAME_START:
      return <GameStart block={block} />;
    case MemoryMatchBlockGameSessionStatus.GAME_END:
      return <GameEnd block={block} />;
    case MemoryMatchBlockGameSessionStatus.RESULTS:
    case MemoryMatchBlockGameSessionStatus.SCOREBOARD:
    case MemoryMatchBlockGameSessionStatus.END:
    case null:
    case undefined:
      break;
    default:
      assertExhaustive(gameSessionStatus);
      break;
  }

  return null;
}
