import {
  EnumsProgramCadenceFrequency,
  type ModelsProgramCadenceSettings,
  type ModelsWaterCoolerTopicLink,
} from '@lp-lib/api-service-client/public';

import config from '../../../config';
import { type WaterCoolerProgramRound } from '../../../types/program';
import { ProgramUtils } from '../utils';
import { type WaterCoolerTopic } from './types';

const FREQUENCY_CONFIG = Object.freeze([
  EnumsProgramCadenceFrequency.ProgramCadenceFrequencyMultipleTimesPerWeek,
  EnumsProgramCadenceFrequency.ProgramCadenceFrequencyEveryWeek,
  EnumsProgramCadenceFrequency.ProgramCadenceFrequencyEveryTwoWeeks,
  EnumsProgramCadenceFrequency.ProgramCadenceFrequencyEveryFourWeeks,
]);

export class WaterCoolerProgramUtils {
  static GetCadenceConfig(cfg = config) {
    return {
      frequencies: FREQUENCY_CONFIG,
      roundTimeToMinutes: cfg.program.waterCoolerRoundTimeToMinutes,
    };
  }

  static DefaultCadence(
    timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
  ): ModelsProgramCadenceSettings {
    const frequency =
      EnumsProgramCadenceFrequency.ProgramCadenceFrequencyMultipleTimesPerWeek;
    return {
      frequency,
      weekdays: [1, 2, 3, 4, 5],
      nextTriggerTime: ProgramUtils.NextCadenceAfter(
        EnumsProgramCadenceFrequency.ProgramCadenceFrequencyEveryWeek,
        [1, 2, 3, 4, 5]
      ).toISOString(),
      timezone,
    };
  }

  static BuildTopic(
    round: WaterCoolerProgramRound,
    link?: ModelsWaterCoolerTopicLink | null
  ): WaterCoolerTopic {
    return {
      id: round.id,
      isActive: link?.isActive ?? true,
      text: round.extensions?.text || '',
      media: round.extensions?.media,
      tags: round.tags || [],
      parentId: round.parentId,
      parentType: round.parentType,
      createdAt: round.createdAt,
      sendAt: round.endedAt ? new Date(round.endedAt) : undefined,
    };
  }

  static BuildTopics(rounds: WaterCoolerProgramRound[]): WaterCoolerTopic[] {
    return rounds.map((round) => this.BuildTopic(round));
  }

  static BuildTopicLinks(
    topics: WaterCoolerTopic[]
  ): ModelsWaterCoolerTopicLink[] {
    const links: ModelsWaterCoolerTopicLink[] = [];
    for (const topic of topics) {
      links.push({
        roundId: topic.id,
        isActive: topic.isActive ?? true,
      });
    }
    return links;
  }
}
