import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { snapshot } from 'valtio';

import {
  GameSessionUtil,
  type RapidBlock,
  type RapidBlockAnswerData,
  RapidBlockAnswerGrade,
  RapidBlockGameSessionStatus,
} from '@lp-lib/game';

import PointsIncreasingURL from '../../../../assets/img/points-increasing.gif';
import logger from '../../../../logger/logger';
import { nullOrUndefined } from '../../../../utils/common';
import { useOneTimeAutomaticBroadcastToggleOff } from '../../../Broadcast';
import { OneShotAnimatedImg } from '../../../common/Utilities';
import { useGainPointsAnimationGamePlayTrigger } from '../../../GainPointsAnimation/useGainPointsAnimationGamePlayTrigger';
import { FloatBoard } from '../../../Layout';
import { useSyncPersistentPointsRevealAnswer } from '../../../PersistentPoints/Provider';
import { useMyTeamId } from '../../../Player';
import { useSoundEffect } from '../../../SFX';
import { useUser } from '../../../UserContext';
import { GoAnimation } from '../../GameBlockCardAnimations';
import {
  useAudienceTeamData,
  useGameSessionLocalTimer,
  useGameSessionStatus,
  useTimerRecover,
} from '../../hooks';
import { gameSessionStore, resetTimer, setTimer } from '../../store';
import { GamePlayMediaLayout } from '../Common/GamePlay/GamePlayMediaLayout';
import {
  buildGamePlayMedia,
  GamePlayInputLayout,
  type GamePlayMedia,
  GamePlayMediaPlayer,
  GamePlayPrimaryText,
  type GamePlayUIState,
  type GamePlayUIStateControl,
  GameSubmissionInput,
  useCountdownPlaySFX,
  useGamePlayMediaPlayable,
  useGamePlayMediaUISync,
  useGamePlayUITransitionControl,
  useIsGamePlayReady,
} from '../Common/GamePlay/Internal';
import { StageBgSingleFrame } from '../Common/GamePlay/StageBgSingleFrame';
import {
  type RapidResult,
  useRapidCorrectAnswers,
  useRapidMissedAnswers,
  useRapidResults,
  useRapidSFXAnswer,
  useRapidTeamPoints,
} from './hooks';
import { RapidAnswer } from './RapidBlockGamePlayAnswer';
import { RapidBlockUtils } from './utils';

const usePlaySoundEffect = (everyoneSubmits: boolean): void => {
  const user = useUser();
  const currentSfxAnswer = useRapidSFXAnswer(everyoneSubmits);
  const lastPlayedAnswerSubmittedAt = useRef<number>(0);

  const { play: playCorrectSFX } = useSoundEffect('rapidCorrect');
  const { play: playSlightCorrectSFX } = useSoundEffect('rapidSlightCorrect');
  const { play: playDuplicateSFX } = useSoundEffect('rapidDuplicate');
  const { play: playWrongSFX } = useSoundEffect('rapidWrong');

  useEffect(() => {
    if (currentSfxAnswer === null) {
      return;
    }

    if (currentSfxAnswer.submittedAt <= lastPlayedAnswerSubmittedAt.current)
      return;
    lastPlayedAnswerSubmittedAt.current = currentSfxAnswer.submittedAt;

    switch (currentSfxAnswer.grade) {
      case RapidBlockAnswerGrade.CORRECT:
        everyoneSubmits && user.id !== currentSfxAnswer.submitterUid
          ? playSlightCorrectSFX()
          : playCorrectSFX();
        break;
      case RapidBlockAnswerGrade.DUPLICATE:
        playDuplicateSFX();
        break;
      case RapidBlockAnswerGrade.WRONG:
        playWrongSFX();
    }
  }, [
    currentSfxAnswer,
    everyoneSubmits,
    playCorrectSFX,
    playDuplicateSFX,
    playSlightCorrectSFX,
    playWrongSFX,
    user.id,
  ]);
};

enum AnswerAnimationType {
  IN = 'in',
  OUT = 'out',
  DOWN = 'down',
}

const CurrentAnswer = ({
  rapidCurrentAnswer,
  animationType,
  showSubmitter,
}: {
  rapidCurrentAnswer: RapidBlockAnswerData | null;
  animationType: AnswerAnimationType | null;
  showSubmitter: boolean;
}): JSX.Element | null => {
  let gradeText = '';
  let currentAnswerStyles = '';
  const answerGrade = rapidCurrentAnswer?.grade ?? null;

  switch (answerGrade) {
    case RapidBlockAnswerGrade.CORRECT:
      currentAnswerStyles = 'text-green-001';
      gradeText = 'Correct';
      break;
    case RapidBlockAnswerGrade.WRONG:
      currentAnswerStyles = 'text-red-002';
      gradeText = 'Wrong';
      break;
    case RapidBlockAnswerGrade.DUPLICATE:
      currentAnswerStyles = 'text-tertiary';
      gradeText = 'Duplicate';
  }

  let animation = 'animate-slide-in-left';
  switch (animationType) {
    case AnswerAnimationType.DOWN:
      animation = 'animate-slide-down';
      break;
    case AnswerAnimationType.OUT:
      animation = 'animate-slide-out';
      break;
    default:
      animation = 'animate-slide-in-left';
  }

  return (
    <div className={`w-full px-3.5 ${currentAnswerStyles} ${animation}`}>
      <div
        className={`w-full text-sms font-normal flex flex-row items-center justify-between`}
      >
        <p className='w-3/5 truncate'>
          {rapidCurrentAnswer?.submittedAnswer ?? ''}
        </p>
        <p className='w-2/5 text-right'>{gradeText}</p>
      </div>

      {showSubmitter && (
        <div className='text-3xs font-bold truncate'>
          {rapidCurrentAnswer?.submitterUsername ?? ''}
        </div>
      )}
    </div>
  );
};

const RightPanel = ({
  everyoneSubmits,
  showMissedAnswers,
}: {
  everyoneSubmits: boolean;
  showMissedAnswers: boolean;
}): JSX.Element => {
  const gameSessionStatus = useGameSessionStatus<RapidBlockGameSessionStatus>();
  const [animationType, setAnimationType] =
    useState<AnswerAnimationType | null>(null);
  const [showPointsAnimation, setShowPointsAnimation] = useState(false);
  const rapidCurrentAnswer = useAudienceTeamData() as RapidBlockAnswerData;
  const currentAnswer = useRef<RapidBlockAnswerData | null>(null);
  const rapidCorrectAnswers = useRapidCorrectAnswers();
  usePlaySoundEffect(everyoneSubmits);

  const [correctAnswersLatestSubmittedAt, setCorrectAnswersLatestSubmittedAt] =
    useState(
      rapidCorrectAnswers && rapidCorrectAnswers.length > 0
        ? rapidCorrectAnswers[0].submittedAt
        : 0
    );
  const correctAnswers = useRef<RapidBlockAnswerData[] | null>(
    rapidCorrectAnswers
  );

  const rapidMissedAnswers = useRapidMissedAnswers();
  const numberOfAnswers =
    (correctAnswers.current?.length || 0) + rapidMissedAnswers.length;
  const teamPoints = useRapidTeamPoints();
  useEffect(() => {
    // Clean up
    return () => {
      currentAnswer.current = null;
      setCorrectAnswersLatestSubmittedAt(0);
      correctAnswers.current = null;
    };
  }, []);

  useEffect(() => {
    let timeout: NodeJS.Timeout | null = null;

    if (timeout) {
      clearTimeout(timeout);
      timeout = null;
    }

    if (!animationType) {
      timeout = setTimeout(() => {
        const currentGrade = rapidCurrentAnswer?.grade ?? null;
        if (currentGrade === RapidBlockAnswerGrade.CORRECT) {
          setAnimationType(AnswerAnimationType.DOWN);
        } else if (
          currentGrade === RapidBlockAnswerGrade.DUPLICATE ||
          currentGrade === RapidBlockAnswerGrade.WRONG
        ) {
          setAnimationType(AnswerAnimationType.OUT);
        }
      }, 2000);
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout);
        timeout = null;
      }
    };
  }, [animationType, rapidCurrentAnswer?.grade]);

  useEffect(() => {
    if (
      animationType === AnswerAnimationType.DOWN ||
      animationType === AnswerAnimationType.OUT
    ) {
      setCorrectAnswersLatestSubmittedAt(
        currentAnswer.current?.submittedAt || 0
      );
    }
  }, [animationType]);

  useEffect(() => {
    if (rapidCurrentAnswer === null) {
      currentAnswer.current = null;
      return;
    }

    if (
      rapidCurrentAnswer.submittedAt === currentAnswer.current?.submittedAt &&
      rapidCurrentAnswer.submitterUid === currentAnswer.current?.submitterUid
    ) {
      return;
    }

    // Fade out previous answer if exist
    const currentGrade = currentAnswer.current?.grade ?? null;
    if (currentGrade === RapidBlockAnswerGrade.CORRECT) {
      setAnimationType(AnswerAnimationType.DOWN);
    } else if (
      currentGrade === RapidBlockAnswerGrade.DUPLICATE ||
      currentGrade === RapidBlockAnswerGrade.WRONG
    ) {
      setAnimationType(AnswerAnimationType.OUT);
    }

    setShowPointsAnimation(false);
    // Set new current answer
    const timeout = setTimeout(() => {
      currentAnswer.current = rapidCurrentAnswer;
      if (rapidCurrentAnswer.grade === RapidBlockAnswerGrade.CORRECT) {
        setShowPointsAnimation(true);
        setTimeout(() => setShowPointsAnimation(false), 500);
      }
      setAnimationType(null);
    }, 100);

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [rapidCurrentAnswer]);

  useEffect(() => {
    if (!rapidCorrectAnswers) {
      correctAnswers.current = null;
      return;
    }

    let scoredAnswerList = Object.assign(
      [],
      rapidCorrectAnswers
    ) as RapidBlockAnswerData[];

    // Cap number of the showing scored answers when timer is counting down
    if (
      gameSessionStatus === RapidBlockGameSessionStatus.QUESTION_COUNTING &&
      scoredAnswerList.length > 0
    ) {
      scoredAnswerList = scoredAnswerList.filter(
        (val) => val.firstSubmittedAt <= correctAnswersLatestSubmittedAt
      );
    }
    correctAnswers.current = scoredAnswerList;
  }, [rapidCorrectAnswers, gameSessionStatus, correctAnswersLatestSubmittedAt]);

  const bgStyles =
    gameSessionStatus === RapidBlockGameSessionStatus.QUESTION_COUNTING
      ? 'bg-gradient-to-b from-rightpanel-start to-rightpanel-end'
      : 'bg-gradient-to-b from-rightpanel-dark-start to-rightpanel-dark-end';

  const isShowingResults =
    gameSessionStatus === RapidBlockGameSessionStatus.RESULTS;

  const middleContent = isShowingResults ? (
    !showMissedAnswers ? (
      <div></div>
    ) : (
      <>
        <div className='w-full px-3.5'>
          <p className='w-full mb-3.5 font-cairo font-bold text-sm text-red-002 text-left'>
            {`Missed (${rapidMissedAnswers?.length ?? 0})`}
          </p>
          {rapidMissedAnswers.map((a, i) => (
            <div
              key={`missed-answer-${i}`}
              className={`w-full mt-3.5 text-white text-opacity-40 text-xs 2xl:text-sms font-normal flex flex-row items-center justify-between cursor-default`}
              title={a.answer}
            >
              <p className='w-4/5 truncate'>{a.answer}</p>
              <p className='w-1/5 text-right'>+{a.points}</p>
            </div>
          ))}
        </div>
      </>
    )
  ) : (
    <CurrentAnswer
      key={
        currentAnswer.current?.submittedAnswer +
        '-' +
        currentAnswer.current?.grade
      }
      rapidCurrentAnswer={currentAnswer.current}
      animationType={animationType}
      showSubmitter={everyoneSubmits}
    />
  );

  return (
    <div className='absolute w-full h-full flex flex-col justify-center items-center'>
      <div
        className={`absolute top-4 w-full h-[90%] ${bgStyles} border border-black rounded-tr-1.5lg rounded-br-1.5lg`}
      >
        <div className='absolute -top-5.5 -right-5.5'>
          <div className='w-11 h-11 mr-0.75 ml-3 rounded-full bg-warning text-black flex flex-col justify-center items-center'>
            <div className='font-extrabold text-xl leading-5 font-cairo'>
              {teamPoints}
            </div>
            <p className='text-3xs text-black'>pts</p>
          </div>
        </div>
        {showPointsAnimation && (
          <div className='absolute -top-10 -right-9'>
            <OneShotAnimatedImg
              imgClassName='w-20 h-20'
              src={PointsIncreasingURL}
              alt='points-increasing'
            />
          </div>
        )}
        <div className='w-full h-full flex flex-col items-center justify-start overflow-y-auto scrollbar'>
          <p className='my-3.5 font-cairo font-bold text-sm sticky'>
            Your Team’s Answers
          </p>
          {middleContent}
          <div className='w-full h-auto px-3.5 mb-5'>
            {isShowingResults && (
              <p className='w-full mt-3.5 font-cairo font-bold text-sm text-green-001 text-left'>
                {`Scored (${rapidCorrectAnswers?.length ?? 0})`}
              </p>
            )}
            {isShowingResults && !showMissedAnswers && (
              <p className='w-full mt-3.5 font-cairo text-sm text-green-500 text-left'>
                {`${
                  numberOfAnswers > 0
                    ? Math.round(
                        ((rapidCorrectAnswers?.length || 0) / numberOfAnswers) *
                          100
                      )
                    : 0
                }% of total possible answers`}
              </p>
            )}
            {correctAnswers.current?.map((a, i) => (
              <div
                key={`correct-answer-${i}`}
                title={a.firstSubmittedAnswer}
                className={`w-full text-white cursor-default ${
                  everyoneSubmits ? 'mt-2' : 'mt-3.5'
                }`}
              >
                <div
                  className={`w-full text-xs 2xl:text-sms font-normal flex flex-row items-center justify-between`}
                >
                  <p className='w-4/5 truncate'>{a.firstSubmittedAnswer}</p>
                  <p className='w-1/5 text-right'>+{a.points}</p>
                </div>
                {everyoneSubmits && (
                  <div className='text-4xs 2xl:text-3xs font-bold truncate'>
                    {a.firstSubmitterUsername}
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

const ResultStage = (props: {
  gameSessionBlock: RapidBlock;
  uiState: GamePlayUIState;
  uiControl: GamePlayUIStateControl;
}): JSX.Element => {
  const { gameSessionBlock, uiState, uiControl } = props;
  const gameSessionStatus = useGameSessionStatus<RapidBlockGameSessionStatus>();
  const [results, setResults] = useState<RapidResult[]>([]);

  const myTeamId = useMyTeamId();
  const { play: showResultsSFX } = useSoundEffect('showResults');
  useRapidResults(gameSessionBlock.id, setResults);

  useEffect(() => {
    if (myTeamId && gameSessionStore.teamData?.data) {
      try {
        const teamData = snapshot(gameSessionStore.teamData.data);
        logger
          .scoped('game-session-store')
          .info(
            `rsb-audience-team-data-${myTeamId}-${gameSessionBlock.id}`,
            teamData
          );
      } catch (_) {}
    }
  }, [gameSessionBlock.id, myTeamId]);

  useEffect(() => {
    return () => {
      setResults([]);
    };
  }, []);

  useEffect(() => {
    // Recover from audience reloads the page
    if (!uiState.showBoard) {
      uiControl.update({ showBoard: true });
    }
  }, [uiState.showBoard, uiControl]);

  useEffect(() => {
    if (gameSessionStatus === RapidBlockGameSessionStatus.RESULTS) {
      showResultsSFX();
    }
  }, [gameSessionStatus, showResultsSFX]);

  return (
    <div className='w-full h-[90%] px-16 pt-17'>
      <div className='w-full h-full flex flex-col overflow-y-auto scrollbar'>
        {results.map((data, i) => (
          <div
            key={`results-${i}`}
            className={`w-full animate-fade-in-up min-h-12 mb-4 pl-6 rounded-3xl bg-black bg-opacity-50 flex flex-row justify-between items-center ${
              data.teamId === myTeamId ? 'text-tertiary' : 'text-white'
            }`}
          >
            <p className={`text-lg font-bold font-cairo`}>{data.teamName}</p>
            <div className='flex flex-row justify-center items-center'>
              <p className='text-3xs font-bold'>{`${
                data.corrects || 0
              } Correct`}</p>
              <div className='w-11 h-11 mr-0.75 ml-3 rounded-full bg-warning text-black flex flex-col justify-center items-center'>
                <div className='font-extrabold text-xl leading-5 font-cairo'>
                  {data.previewPoints || 0}
                </div>
                <p className='text-3xs text-black'>pts</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

const SubmissionStage = (props: {
  gameSessionBlock: RapidBlock;
  uiState: GamePlayUIState;
  uiControl: GamePlayUIStateControl;
  setShowResultStage: (b: boolean) => void;
}): JSX.Element | null => {
  const { gameSessionBlock, uiState, uiControl, setShowResultStage } = props;
  const gameSessionStatus = useGameSessionStatus<RapidBlockGameSessionStatus>();
  const gameSessionLocalTimer = useGameSessionLocalTimer();

  const [gamePlayMedia, setGamePlayMedia] =
    useState<Nullable<GamePlayMedia, false>>(null);

  const submissionTime = gameSessionBlock?.fields.questionTime ?? null;

  const showAnswer = useMemo(() => {
    if (!gameSessionStatus) return false;
    // TODO(guoqiang): it's possible that gameSessionStatus has moved to next stage,
    // but answer media is not completed (uiState.showAnswer is false).
    // Still need to dig in for the root cause.
    if (
      gameSessionStatus > RapidBlockGameSessionStatus.RESULTS &&
      gameSessionStatus !== RapidBlockGameSessionStatus.POINTS_DISTRIBUTED
    )
      return true;
    return (
      gameSessionStatus === RapidBlockGameSessionStatus.RESULTS &&
      (uiState.showAnswer || !gamePlayMedia)
    );
  }, [gamePlayMedia, gameSessionStatus, uiState.showAnswer]);

  // When countdown starts
  useOneTimeAutomaticBroadcastToggleOff(
    () => gameSessionStatus === RapidBlockGameSessionStatus.QUESTION_COUNTING
  );

  useEffect(() => {
    return () => {
      resetTimer('submission');
    };
  }, []);

  useEffect(() => {
    const map = GameSessionUtil.StatusMapFor(gameSessionBlock);
    if (!map || nullOrUndefined(gameSessionStatus)) return;
    const fields = gameSessionBlock.fields;
    const startVideoWithTimer = !!fields.startVideoWithTimer;
    if (map.introMediaRetained.includes(gameSessionStatus)) {
      setGamePlayMedia(
        buildGamePlayMedia(
          {
            media: fields.questionMedia,
            mediaData: fields.questionMediaData,
          },
          {
            stage: 'intro',
            startVideoWithTimer,
          }
        )
      );
    } else if (gameSessionStatus === map.outro && fields.answerMedia) {
      setGamePlayMedia(
        buildGamePlayMedia(
          {
            media: fields.answerMedia,
            mediaData: fields.answerMediaData,
          },
          {
            stage: 'outro',
          }
        )
      );
    }
  }, [gameSessionBlock, gameSessionStatus]);

  useEffect(() => {
    if (
      submissionTime !== null &&
      (gameSessionStatus === RapidBlockGameSessionStatus.PRESENTING ||
        gameSessionStatus === RapidBlockGameSessionStatus.QUESTION_COUNTING)
    ) {
      setTimer('submission', submissionTime);
    }
  }, [gameSessionStatus, submissionTime]);

  useEffect(() => {
    if (showAnswer) setShowResultStage(true);
  }, [setShowResultStage, showAnswer]);

  const { onMediaEnded, onMediaReplaying } = useGamePlayMediaUISync({
    block: gameSessionBlock,
    gameSessionStatus,
    media: gamePlayMedia,
    state: uiState,
    control: uiControl,
    onOutro: useCallback(() => {
      uiControl.update({
        mediaEndEffect: true,
        showBoard: true,
      });
    }, [uiControl]),
  });

  const mediaPlayable = useGamePlayMediaPlayable({
    block: gameSessionBlock,
    gameSessionStatus,
    media: gamePlayMedia,
    state: uiState,
  });

  if (nullOrUndefined(gameSessionStatus)) return null;

  return (
    <>
      <StageBgSingleFrame gamePlayMedia={gamePlayMedia} />
      <GamePlayInputLayout
        zIndex='z-30'
        visible={uiState.showBoard}
        hasMedia={!!gamePlayMedia}
      >
        <div className='w-full'>
          <div
            className={`relative z-30 w-3/4 ${
              gamePlayMedia ? '' : 'h-full'
            } flex flex-col items-center justify-center`}
          >
            <GamePlayPrimaryText
              text={gameSessionBlock.fields.question}
              hasMedia={!!gamePlayMedia}
            />
            <GameSubmissionInput
              timer={gameSessionLocalTimer}
              gameSessionBlock={gameSessionBlock}
              gameSessionStatus={gameSessionStatus}
              adaptor={RapidBlockUtils.ToSubmissionInputAdaptor(
                gameSessionBlock
              )}
            />
          </div>
        </div>
      </GamePlayInputLayout>
      {uiState.playGoAnimation && <GoAnimation />}
      <GamePlayMediaLayout fullscreen={false}>
        {gamePlayMedia ? (
          <>
            <div
              className={`absolute ${
                uiState.showBoard ? 'w-3/4' : 'w-full'
              } h-full`}
            >
              <GamePlayMediaPlayer
                gamePlayMedia={gamePlayMedia}
                play={mediaPlayable}
                mode={uiState.mediaEndEffect ? 'small' : 'full'}
                onMediaEnded={onMediaEnded}
                onMediaReplaying={onMediaReplaying}
                layout='anchored'
              />
            </div>
            <div
              className={`absolute ${
                uiState.showBoard ? 'w-1/4' : 'hidden'
              } h-full`}
            />
          </>
        ) : null}
      </GamePlayMediaLayout>
    </>
  );
};

const RapidBlockGamePlay = (props: {
  gameSessionBlock: RapidBlock;
}): JSX.Element | null => {
  const { gameSessionBlock } = props;

  const gameSessionStatus = useGameSessionStatus<RapidBlockGameSessionStatus>();
  const gameSessionLocalTimer = useGameSessionLocalTimer();

  const [uiState, uiControl] = useGamePlayUITransitionControl();
  const isGamePlayReady = useIsGamePlayReady(
    gameSessionBlock,
    gameSessionStatus
  );
  const [showResultStage, setShowResultStage] = useState(false);

  const isShowingResults = showResultStage;

  const showPointsDistributed =
    gameSessionStatus === RapidBlockGameSessionStatus.POINTS_DISTRIBUTED;

  useTimerRecover(async (status) => {
    if (status === RapidBlockGameSessionStatus.QUESTION_COUNTING) {
      return gameSessionBlock.fields.questionTime;
    }
  });
  useSyncPersistentPointsRevealAnswer(showPointsDistributed);
  useGainPointsAnimationGamePlayTrigger();

  useEffect(() => {
    return () => {
      resetTimer('submission');
    };
  }, []);

  useCountdownPlaySFX(
    gameSessionBlock.fields.questionTime,
    gameSessionLocalTimer,
    gameSessionStatus === RapidBlockGameSessionStatus.QUESTION_COUNTING ||
      gameSessionStatus === RapidBlockGameSessionStatus.QUESTION_END
  );

  useEffect(() => {
    if (gameSessionStatus === RapidBlockGameSessionStatus.QUESTION_END) {
      resetTimer('submission');
    }
  }, [gameSessionStatus]);

  useEffect(() => {
    if (
      gameSessionStatus === null ||
      gameSessionStatus === RapidBlockGameSessionStatus.LOADED ||
      gameSessionStatus === RapidBlockGameSessionStatus.END
    ) {
      uiControl.reset();
      setShowResultStage(false);
      resetTimer('submission');
    }
  }, [gameSessionStatus, uiControl]);

  if (!isGamePlayReady || nullOrUndefined(gameSessionStatus)) return null;

  const overlayStyles = uiState.showBoard
    ? 'bg-black bg-opacity-40'
    : 'bg-black bg-opacity-60';

  const boardZIndex =
    gameSessionStatus >= RapidBlockGameSessionStatus.RESULTS ? 'z-20' : 'z-30';

  return (
    <div className={`fixed w-screen h-screen ${overlayStyles} text-white`}>
      <FloatBoard
        containerZIndex={boardZIndex}
        containerDisplay={'flex'}
        bgOpacity={uiState.showBoard ? 'opacity-100' : 'opacity-0'}
        title={isShowingResults ? 'TEAM RESULTS' : undefined}
        rightPanel={
          uiState.showBoard ? (
            <RightPanel
              everyoneSubmits={gameSessionBlock.fields.everyoneSubmits}
              showMissedAnswers={gameSessionBlock.fields.showMissedAnswers}
            />
          ) : null
        }
      >
        {showResultStage && (
          <ResultStage
            gameSessionBlock={gameSessionBlock}
            uiState={uiState}
            uiControl={uiControl}
          />
        )}
      </FloatBoard>
      {!showPointsDistributed && !showResultStage && (
        <SubmissionStage
          gameSessionBlock={gameSessionBlock}
          uiState={uiState}
          uiControl={uiControl}
          setShowResultStage={setShowResultStage}
        />
      )}
      {showPointsDistributed && !showResultStage && <RapidAnswer />}
    </div>
  );
};

export { RapidBlockGamePlay };
