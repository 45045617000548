import { useRef, useState } from 'react';

import { useFeatureQueryParam } from '../../hooks/useFeatureQueryParam';
import { useOutsideClick } from '../../hooks/useOutsideClick';
import { assertExhaustive } from '../../utils/common';
import { FirebaseStatus, useFirebaseStatus } from '../Firebase';
import { type LatencyResult } from '../FirebaseLatency/LatencyResult';
import {
  useFirebaseLatencyMeterColor,
  useFirebaseLatencyMeterPeriod,
  useFirebaseLatencyStats,
} from '../FirebaseLatency/Provider';
import { ReplaceIcon } from '../icons/ReplaceIcon';
import { XIcon } from '../icons/XIcon';

function LatencyStats(props: { header: string; result: LatencyResult }) {
  return (
    <div className='flex flex-col gap-1'>
      <header className='text-2xs font-bold text-white'>{props.header}</header>
      <div className='flex flex-col text-white text-2xs'>
        {Object.entries(props.result).map(([k, v]) => (
          <div key={`${k}`}>
            {k}: {v}
          </div>
        ))}
      </div>
    </div>
  );
}

function LatencyDetails() {
  const stats = useFirebaseLatencyStats();
  const periodLabel = useFirebaseLatencyMeterPeriod();
  return (
    <div
      className='
        absolute top-full right-0 w-48
        bg-lp-black-004
        border border-[#303436] rounded-xl
        p-4
        flex flex-col gap-4
    '
    >
      <header className='text-2xs font-bold text-white'>
        Connection Details
      </header>

      <section className='flex flex-col gap-2'>
        <LatencyStats header={'Ping'} result={stats.latest} />
        <LatencyStats header={`Avg Ping (${periodLabel})`} result={stats.avg} />
      </section>
    </div>
  );
}

export function FirebaseLatencyMeter(): JSX.Element | null {
  const meterEnabled = useFeatureQueryParam('firebase-latency-meter');
  const color = useFirebaseLatencyMeterColor();
  const status = useFirebaseStatus();

  let bgColor;
  switch (color) {
    case 'green':
      bgColor = 'bg-lp-green-001';
      break;
    case 'yellow':
      bgColor = 'bg-warning';
      break;
    case 'red':
      bgColor = 'bg-lp-red-001';
      break;
    default:
      assertExhaustive(color);
      bgColor = 'bg-lp-red-001';
  }

  let icon;
  switch (status) {
    case FirebaseStatus.Disconnected:
      icon = (
        <XIcon
          className={`fill-current stroke-current w-3.5 h-3.5 text-red-001`}
        />
      );
      break;
    case FirebaseStatus.Connected:
      icon = <div className={`w-2 h-2 rounded-full ${bgColor}`} />;
      break;
    case FirebaseStatus.None:
    case FirebaseStatus.Initializing:
    case FirebaseStatus.Connecting:
      icon = (
        <ReplaceIcon
          className={`fill-current w-3.5 h-3.5 text-disabled-gray`}
        />
      );
      break;
    default:
      assertExhaustive(status);
      icon = <ReplaceIcon />;
      break;
  }

  const ref = useRef<HTMLDivElement | null>(null);
  const [meterOpen, setMeterOpen] = useState(false);
  useOutsideClick(ref, () => setMeterOpen(false));

  return !meterEnabled ? null : (
    <div
      className='w-4 h-full relative flex items-center justify-center'
      ref={ref}
    >
      <button
        type='button'
        className={`w-4 h-4 btn flex items-center justify-center`}
        onClick={() => setMeterOpen((v) => !v)}
      >
        {icon}
      </button>
      {!meterOpen ? null : <LatencyDetails />}
    </div>
  );
}
