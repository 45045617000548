import { format } from 'date-fns';
import pluralize from 'pluralize';
import { useMemo } from 'react';
import {
  FormProvider,
  useController,
  useForm,
  useFormContext,
} from 'react-hook-form';
import { match } from 'ts-pattern';

import {
  type DtoProgram,
  EnumsProgramType,
} from '@lp-lib/api-service-client/public';

import { useInstance } from '../../hooks/useInstance';
import { type SlackChannel, type SlackChannelType } from '../../types/slack';
import { assertExhaustive, err2s } from '../../utils/common';
import { ChannelUtils } from '../Channel';
import { Tooltip } from '../common/Tooltip';
import { CalendarIcon } from '../icons/CalendarIcon';
import { RefreshIcon } from '../icons/RefreshIcon';
import { SlackIcon } from '../icons/SlackIcon';
import { TeamIcon } from '../icons/TeamIcon';
import { Loading } from '../Loading';
import { useMyOrgId } from '../Organization';
import { SlackChannelPicker } from '../Slack';
import { SlackChannelInput } from '../Slack/ChannelNameInput';
import { WEEKDAY_OPTIONS } from './ProgramCadenceEditor';
import { ProgramIcon, ProgramKnifeFactory } from './ProgramKnifes';

export type ChannelSource = 'new' | 'existing';

export function ChannelSelectNew(props: {
  name?: string | null;
  error?: string | null;
  disabled?: boolean;
  supportedSources: ChannelSource[];
  onChange: (name: string) => void;
  onChangeSource: (source: ChannelSource) => void;
}) {
  const { name, error, disabled, supportedSources, onChange, onChangeSource } =
    props;

  return (
    <div className='w-full text-white'>
      <div className='flex items-center gap-1.5'>
        <SlackIcon className='w-4 h-4' />
        <p className='text-xs font-bold'>New Slack channel name</p>
      </div>

      <SlackChannelInput
        name={name || ''}
        onChange={onChange}
        disabled={disabled}
        className={`mt-2.5 w-full ${error ? 'field-error' : 'field'} mb-0 h-10`}
      />

      <div className='mt-1.5 flex items-center gap-5 text-icon-gray text-2xs h-3 font-medium underline'>
        {supportedSources.includes('existing') && (
          <button
            type='button'
            onClick={() => onChangeSource('existing')}
            className='btn'
          >
            Want to use an existing slack channel?
          </button>
        )}
      </div>
    </div>
  );
}

export function ChannelSelectExisting(props: {
  channel?: SlackChannel | null;
  error?: string | null;
  disabled?: boolean;
  supportedSources: ChannelSource[];
  supportedChannelTypes: SlackChannelType[];
  onChange: (channel: SlackChannel) => void;
  onChangeSource: (source: ChannelSource) => void;
}) {
  const {
    channel,
    error,
    disabled,
    supportedSources,
    supportedChannelTypes,
    onChange,
    onChangeSource,
  } = props;

  const orgId = useMyOrgId();

  return (
    <div className='w-full text-white'>
      <div className='flex items-center gap-1.5'>
        <SlackIcon className='w-4 h-4' />
        <p className='text-xs font-bold'>Existing Slack channel name</p>
      </div>

      <div className='mt-2.5 w-full h-10'>
        <SlackChannelPicker
          orgId={orgId || ''}
          types={supportedChannelTypes}
          defaultChannel={channel || undefined}
          onChange={onChange}
          disabled={disabled}
          error={error}
        />
      </div>

      <div className='mt-1 flex items-center gap-5 text-icon-gray text-2xs h-3 font-medium underline'>
        {supportedSources.includes('new') && (
          <button
            type='button'
            onClick={() => onChangeSource('new')}
            className='btn'
          >
            Create a new slack channel
          </button>
        )}
        {supportedChannelTypes.includes('private') && (
          <div className='relative group flex justify-center items-center'>
            <div className='absolute invisible group-hover:visible z-5 bottom-full'>
              <Tooltip
                position={'top'}
                backgroundColor='#383838'
                borderRadius={8}
                arrowWidth={8}
                fadeInDurationMS={1000}
              >
                <p className='w-60 px-4 py-3 text-3xs font-medium text-white'>
                  Don’t see your private channel? Go to the channel you’d like
                  to use in Slack and type “/invite @Luna Park”, and then return
                  to this screen and try again.
                </p>
              </Tooltip>
            </div>
            <button type='button' className='btn underline'>
              Use a private channel
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

type ChannelSettings = {
  source: ChannelSource;
  supportedSources: ChannelSource[];
  supportedChannelTypes: SlackChannelType[];
  name?: string | null;
  channel?: SlackChannel | null;
};

type FormItem = {
  index: number;
  program: DtoProgram;
  channelSettings: ChannelSettings;
  progress:
    | null
    | {
        status: 'waiting' | 'activating' | 'activated';
      }
    | {
        status: 'failed';
        error: string;
      };
};

type FormData = {
  items: FormItem[];
};

function makeFormDefaultValues(programs: DtoProgram[]): FormData {
  const items = programs.map<FormItem>((program, index) => {
    const knife = ProgramKnifeFactory[program.type];

    const channelSettings: ChannelSettings = {
      source: 'new',
      supportedSources: ['new', 'existing'],
      supportedChannelTypes: ['public', 'private'],
      name: knife.DefaultChannelName(),
    };

    return {
      index,
      program,
      channelSettings,
      progress: null,
    };
  });
  return {
    items,
  };
}

function ChannelSelectField(props: { index: number; disabled?: boolean }) {
  const { index, disabled } = props;

  const {
    fieldState,
    field: { value: settings, onChange },
  } = useController<FormData, `items.${number}.channelSettings`>({
    name: `items.${index}.channelSettings`,
    rules: {
      validate: (value) => {
        if (value.source === 'new') {
          if (!value.name) {
            return 'Please enter a channel name!';
          }
        } else {
          if (!value.channel) {
            return 'Please select an existing channel in your slack workspace!';
          }
        }
        return true;
      },
    },
  });

  const handleChange = (updates: Partial<ChannelSettings>) => {
    onChange({
      ...settings,
      ...updates,
    });
  };

  const source = settings.source;
  switch (source) {
    case 'new':
      return (
        <ChannelSelectNew
          name={settings.name}
          supportedSources={settings.supportedSources}
          onChange={(name) => handleChange({ name })}
          onChangeSource={(source) => handleChange({ source })}
          error={fieldState.error?.message}
          disabled={disabled}
        />
      );
    case 'existing':
      return (
        <ChannelSelectExisting
          channel={settings.channel}
          supportedSources={settings.supportedSources}
          supportedChannelTypes={settings.supportedChannelTypes}
          onChange={(channel) => handleChange({ channel })}
          onChangeSource={(source) =>
            handleChange({
              source,
            })
          }
          error={fieldState.error?.message}
          disabled={disabled}
        />
      );
    default:
      assertExhaustive(source);
      return null;
  }
}

function ProgressField(props: { index: number }) {
  const { watch } = useFormContext<FormData>();
  const progress = watch(`items.${props.index}.progress`);

  return (
    <div className='absolute top-2 right-4 text-2xs'>
      {match(progress)
        .with({ status: 'waiting' }, () => (
          <p className='text-icon-gray'>Waiting to be activated</p>
        ))
        .with({ status: 'activating' }, () => (
          <p className='text-tertiary'>Activating ...</p>
        ))
        .with({ status: 'activated' }, () => (
          <p className='text-green-001'>Activated!</p>
        ))
        .with({ status: 'failed' }, ({ error }) => (
          <p className='text-red-002'>{error}</p>
        ))
        .otherwise(() => null)}
    </div>
  );
}

function ProgramCardLayout(props: {
  item: FormItem;

  children: React.ReactNode;
}) {
  const { item, children } = props;
  return (
    <div className='relative w-full bg-modal rounded-2xl pt-4 pb-6 pl-8'>
      <ProgressField index={item.index} />

      <div className='flex items-center'>
        <ProgramIcon
          programType={item.program.type}
          className='w-28 h-22 object-contain'
        />
        <div className='flex-none ml-5 w-80 h-full'>
          <h2 className='text-xl font-bold text-white mb-2'>{`${item.program.name} Setup`}</h2>
          <ChannelSelectField index={item.index} />
        </div>
        <div className='flex-none ml-12 w-60 text-3xs font-bold text-icon-gray flex flex-col gap-2'>
          {children}
        </div>
      </div>
    </div>
  );
}

function ProgramCardIntros(props: { item: FormItem }) {
  const { item } = props;

  const settings = useInstance(() =>
    ProgramKnifeFactory[EnumsProgramType.ProgramTypeIntros].DefaultSettings()
  );
  return (
    <ProgramCardLayout item={item}>
      <p>Default Settings</p>
      <div className='flex items-center gap-1'>
        <RefreshIcon className='w-3.5 h-3.5 fill-current' />
        <p>
          Cadence:{' '}
          {`Every ${pluralize(
            'Week',
            settings.cadenceSettings.frequency,
            true
          )}`}
        </p>
      </div>
      <div className='flex items-center gap-1'>
        <TeamIcon className='w-3.5 h-3.5 fill-current' />
        <p>Group Size: {settings.groupSettings.size}</p>
      </div>
      <div className='flex items-center gap-1'>
        <CalendarIcon className='w-3.5 h-3.5 fill-current' />
        <p>
          Starts on:{' '}
          {format(new Date(settings.cadenceSettings.nextTriggerTime), 'MMMM d')}
        </p>
      </div>
      <p>(you can edit these later)</p>
    </ProgramCardLayout>
  );
}

function ProgramCardWaterCooler(props: { item: FormItem }) {
  const { item } = props;

  const settings = useMemo(
    () =>
      ProgramKnifeFactory[
        EnumsProgramType.ProgramTypeWaterCooler
      ].DefaultSettings(item.program),
    [item.program]
  );

  return (
    <ProgramCardLayout item={item}>
      <p>Default Settings</p>
      <div className='flex items-center gap-1'>
        <RefreshIcon className='w-3.5 h-3.5 fill-current' />
        <p>
          Frequency:{' '}
          {settings.cadenceSettings.weekdays
            .map((day) => WEEKDAY_OPTIONS.find((o) => o.value === day)?.label)
            .join(',')}
        </p>
      </div>
      <div className='flex items-center gap-1'>
        <TeamIcon className='w-3.5 h-3.5 fill-current' />
        <p>
          Topic Category:{' '}
          {item.program.tagSettings?.selectedTags
            ?.map((t) => t.name)
            .join(', ') || 'N/A'}
        </p>
      </div>
      <div className='flex items-center gap-1'>
        <CalendarIcon className='w-3.5 h-3.5 fill-current' />
        <p>
          Starts on:{' '}
          {format(
            new Date(settings.cadenceSettings.nextTriggerTime || ''),
            'MMMM d'
          )}
        </p>
      </div>
      <p>(you can edit these later)</p>
    </ProgramCardLayout>
  );
}

function ProgramCardCelebrations(props: { item: FormItem }) {
  const { item } = props;

  return (
    <ProgramCardLayout item={item}>
      <p>Default Settings</p>
      <div className='flex items-center gap-1'>
        <RefreshIcon className='w-3.5 h-3.5 fill-current' />
        <p>Cadence: Daily</p>
      </div>
      <div className='flex items-center gap-1'>
        <CalendarIcon className='w-3.5 h-3.5 fill-current' />
        <p>Starts On: Upload your dates first!</p>
      </div>
      <p>(you can edit these later)</p>
    </ProgramCardLayout>
  );
}

function ProgramCardRecognition(props: { item: FormItem }) {
  const { item } = props;

  const settings = useMemo(
    () =>
      ProgramKnifeFactory[
        EnumsProgramType.ProgramTypeRecognition
      ].DefaultSettings(),
    []
  );

  return (
    <ProgramCardLayout item={item}>
      <p>Default Settings</p>
      <div className='flex items-center gap-1'>
        Employees can send {settings.membersDailyGivingAmount} ice creams each
        day
      </div>
      <div className='flex items-center gap-1'>
        Admins can send {settings.adminDailyGivingAmount} ice creams each day
      </div>
      <p>(you can edit these later)</p>
    </ProgramCardLayout>
  );
}

function ProgramCard(props: { index: number }) {
  const { index } = props;
  const {
    field: { value: item },
  } = useController<FormData, `items.${number}`>({
    name: `items.${index}`,
  });

  switch (item.program.type) {
    case EnumsProgramType.ProgramTypeIntros:
      return <ProgramCardIntros item={item} />;
    case EnumsProgramType.ProgramTypeWaterCooler:
      return <ProgramCardWaterCooler item={item} />;
    case EnumsProgramType.ProgramTypeBirthdayAndCelebrations:
      return <ProgramCardCelebrations item={item} />;
    case EnumsProgramType.ProgramTypeRecognition:
      return <ProgramCardRecognition item={item} />;
    default:
      return null;
  }
}

export function ProgramsConfigure(props: {
  orgId: string;
  programs: DtoProgram[];
  onComplete: (programs: DtoProgram[]) => void;
  onBack: () => void;
}) {
  const { orgId, programs, onBack, onComplete } = props;

  const defaultValues = useInstance(() => makeFormDefaultValues(programs));
  const form = useForm<FormData>({
    defaultValues,
  });
  const {
    formState: { isSubmitting },
    handleSubmit,
    setValue,
  } = form;

  const onSubmit = handleSubmit(async (data) => {
    const pending = data.items.filter(
      (item) => item.progress?.status !== 'activated'
    );

    for (const item of pending) {
      setValue<`items.${number}.progress`>(`items.${item.index}.progress`, {
        status: 'waiting',
      });
    }
    let isCompleted = true;
    for (const item of pending) {
      setValue<`items.${number}.progress`>(`items.${item.index}.progress`, {
        status: 'activating',
      });
      try {
        if (item.channelSettings.source === 'new') {
          await ChannelUtils.InstallProgramInNewChannel({
            orgId,
            program: item.program,
            channelName: item.channelSettings.name || '',
          });
        } else {
          const slackChannel = item.channelSettings.channel;
          if (!slackChannel) {
            throw new Error('channelSettings.channel is null');
          }
          await ChannelUtils.InstallProgramInExistingChannel({
            orgId,
            program: item.program,
            slackChannel,
          });
        }
        setValue<`items.${number}.progress`>(`items.${item.index}.progress`, {
          status: 'activated',
        });
      } catch (err) {
        isCompleted = false;
        setValue<`items.${number}.progress`>(`items.${item.index}.progress`, {
          status: 'failed',
          error: err2s(err) || '',
        });
      }
    }
    if (isCompleted) {
      onComplete(programs);
    }
  });

  return (
    <FormProvider {...form}>
      <form className='w-208 flex flex-col items-center' onSubmit={onSubmit}>
        <h2 className='text-2xl font-medium text-tertiary'>
          Configure your programs below
        </h2>
        <p className='mt-2.5 text-base font-bold text-white text-center'>
          Don’t worry, we’ll show you how to edit the default settings in the
          next step
        </p>

        <div className='mt-10 w-full flex flex-col gap-2.5'>
          {defaultValues.items.map((_, index) => (
            <ProgramCard key={index} index={index} />
          ))}
        </div>

        <button
          type='submit'
          disabled={isSubmitting}
          className='mt-10 w-100 h-15 btn-primary flex justify-center items-center'
        >
          {isSubmitting && <Loading text='' />}
          Activate Programs
        </button>

        <button
          type='button'
          onClick={onBack}
          className='mt-7.5 btn font-medium tracking-wide text-white'
        >
          {'<< Back'}
        </button>
      </form>
    </FormProvider>
  );
}
