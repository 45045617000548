import {
  type BoundingBoxSettings,
  defaultBoundingBoxSettings,
} from '@lp-lib/game';
import { MediaFormatVersion } from '@lp-lib/media';

import { getStaticAssetPath } from '../../utils/assets';
import { ContentRegistry } from './ContentRegistry';
import { type MediaItem } from './types';

export const Stages = new ContentRegistry<MediaItem, 'stage'>('stage');

// TO ADD A NEW VIDEO ASSET (or multiple):
//
// 1) place mp4s in a folder, such as ~/Downlaods/temp. Make sure they are named
//    appropriately, and match the existing conventions here. Example:
//    my-file.mp4 -> /videos/my-file_fhd.mp4 on S3.
//    - Good:
//      - ond-intro-nightime-2023.mp4
//    - Bad:
//      - ond-intro-nightime-2023_fhd.mp4
//      - New Ond Intro.mp4
// 2) run the script from the root of the repo:
//   ```sh
//   for f in ~/Downloads/temp/*.mp4; do ./scripts/optimize-normalize-upload-video fhd "$f"; done;
//   ```
// 3) Create a new entry here, using the filesize and duration metadata from the
//    script output

Stages.insert('001', 'Live - Stage 2022', {
  mediaFormat: {
    version: MediaFormatVersion.FHD,
    url: getStaticAssetPath('videos/live-stage-001_fhd.mp4'),
    width: 1920,
    height: 1080,
    size: 19071364,
    length: 126230,
  },
});

Stages.insert('002', 'Ond - Stage Default', {
  mediaFormat: {
    version: MediaFormatVersion.HD,
    width: 1280,
    height: 720,
    size: 18938319,
    url: getStaticAssetPath('videos/async-temporary-stage-001_720p.mp4'),
    length: 126209,
  },
});

Stages.insert('003', 'Live - Stage Halloween 2022', {
  mediaFormat: {
    version: MediaFormatVersion.FHD,
    url: getStaticAssetPath('videos/live-stage-halloween-2022_fhd.mp4'),
    width: 1920,
    height: 1080,
    size: 20752241,
    length: 87702,
  },
});

Stages.insert('004', 'Live - Stage Holiday 2023', {
  mediaFormat: {
    version: MediaFormatVersion.FHD,
    url: getStaticAssetPath('videos/live-stage-holiday-2022_fhd.mp4'),
    width: 1920,
    height: 1080,
    size: 1540196,
    length: 10710,
  },
});

Stages.insert('005', 'Live - Stage 2023', {
  mediaFormat: {
    version: MediaFormatVersion.FHD,
    url: getStaticAssetPath('videos/live-stage-2023_fhd.mp4'),
    width: 1920,
    height: 1080,
    size: 19434772,
    length: 120034,
  },
});

Stages.insert('006', 'Ond - Stage 2023', {
  mediaFormat: {
    version: MediaFormatVersion.FHD,
    url: getStaticAssetPath('videos/ond-stage-2023-002_fhd.mp4'),
    width: 1920,
    height: 1080,
    size: 5539786,
    length: 89967,
  },
});

Stages.insert('007', 'Pairs - Over-Roasted [Feb]', {
  mediaFormat: {
    version: MediaFormatVersion.FHD,
    url: getStaticAssetPath('videos/pairs-stage-over-roasted-2023-feb_fhd.mp4'),
    width: 1920,
    height: 1120,
    size: 69073,
    length: 1000,
  },
});

Stages.insert('008', 'Ond - Stage 2023 - Dark', {
  mediaFormat: {
    version: MediaFormatVersion.FHD,
    url: getStaticAssetPath('videos/ond-stage-2023-dark_fhd.mp4'),
    width: 1920,
    height: 1080,
    size: 6462150,
    length: 89984,
  },
});

Stages.insert('009', 'Live - Stage 2023 - Townhall', {
  mediaFormat: {
    version: MediaFormatVersion.FHD,
    url: getStaticAssetPath('videos/live-stage-townhall-2023_fhd.mp4'),
    width: 1920,
    height: 1080,
    size: 15216348,
    length: 188736,
  },
});

Stages.insert('010', 'Ond - Stage 2023 - Stadium', {
  mediaFormat: {
    version: MediaFormatVersion.FHD,
    url: getStaticAssetPath('videos/ond-stage-stadium-2023_fhd.mp4'),
    width: 1920,
    height: 1080,
    size: 1465450,
    length: 6720,
  },
});

Stages.insert('011', 'Live - Spotify SpotiFEST Stage 2023', {
  mediaFormat: {
    version: MediaFormatVersion.FHD,
    url: getStaticAssetPath('videos/live-stage-spotifest-2033_fhd.mp4'),
    width: 1920,
    height: 1080,
    size: 24769909,
    length: 188767,
  },
});

Stages.insert('012', 'Live - Stage 2023 - Summer Theme', {
  mediaFormat: {
    version: MediaFormatVersion.FHD,
    url: getStaticAssetPath('videos/live-stage-summer-2023_fhd.mp4'),
    width: 1920,
    height: 1080,
    size: 2106937,
    length: 12438,
  },
});

Stages.insert('013', 'Live - Stage 2024', {
  mediaFormat: {
    version: MediaFormatVersion.FHD,
    url: getStaticAssetPath('videos/live-stage-2024_720p.mp4'),
    width: 1920,
    height: 1080,
    size: 1953403,
    length: 20054,
  },
});

Stages.insert('014', 'Ond - Stage 2024', {
  mediaFormat: {
    version: MediaFormatVersion.FHD,
    url: getStaticAssetPath('videos/ond-stage-2024-004_fhd.mp4'),
    width: 1920,
    height: 1080,
    size: 1244439,
    length: 10048,
  },
});

Stages.insert('015', 'Cohost - Stage 2024 001', {
  mediaFormat: {
    version: MediaFormatVersion.FHD,
    url: getStaticAssetPath('videos/cohost-stage-2024-001.mp4'),
    width: 1920,
    height: 1080,
    size: 360102,
    length: 4054,
  },
});

Stages.insert('016', 'Cohost - Stage 2024 002', {
  mediaFormat: {
    version: MediaFormatVersion.FHD,
    url: getStaticAssetPath('videos/cohost-stage-2024-002.mp4'),
    width: 1920,
    height: 1080,
    size: 111405,
    length: 10000,
  },
});

Stages.insert('017', 'Cohost - Klook Stage 2024', {
  mediaFormat: {
    version: MediaFormatVersion.FHD,
    url: getStaticAssetPath('videos/cohost-stage-klook-2024.mp4'),
    width: 1920,
    height: 1080,
    size: 49844,
    length: 10000,
  },
});

export const IntroMedias = new ContentRegistry<MediaItem, 'intro'>('intro');

IntroMedias.insert('001', 'Live - Intro 2022', {
  mediaFormat: {
    version: MediaFormatVersion.FHD,
    url: getStaticAssetPath('videos/live-intro-001_fhd.mp4'),
    width: 1920,
    height: 1080,
    size: 39556507,
    length: 48640,
  },
});

IntroMedias.insert('002', 'Ond - Intro 2022', {
  mediaFormat: {
    version: MediaFormatVersion.HD,
    width: 1280,
    height: 720,
    size: 4199487,
    url: getStaticAssetPath('videos/async-block-intro-002_720p.mp4'),
    length: 10510,
  },
});

IntroMedias.insert('003', 'Live - Intro Halloween 2022', {
  mediaFormat: {
    version: MediaFormatVersion.FHD,
    url: getStaticAssetPath('videos/live-intro-halloween-2022_fhd.mp4'),
    width: 1920,
    height: 1080,
    size: 46181144,
    length: 48534,
  },
});

IntroMedias.insert('004', 'Live - Intro Holiday 2022', {
  mediaFormat: {
    version: MediaFormatVersion.FHD,
    url: getStaticAssetPath('videos/live-intro-holiday-2022_fhd.mp4'),
    width: 1920,
    height: 1080,
    size: 46740290,
    length: 48534,
  },
});

IntroMedias.insert('005', 'Live - Intro Less-Holiday 2022', {
  mediaFormat: {
    version: MediaFormatVersion.FHD,
    url: getStaticAssetPath('videos/live-intro-less-holiday-2022_fhd.mp4'),
    width: 1920,
    height: 1080,
    size: 46218513,
    length: 48534,
  },
});

IntroMedias.insert('006', 'Live - Intro 2023', {
  mediaFormat: {
    version: MediaFormatVersion.FHD,
    url: getStaticAssetPath('videos/live-intro-2023_fhd.mp4'),
    width: 1920,
    height: 1080,
    size: 26164152,
    length: 40085,
  },
});

IntroMedias.insert('007', 'Ond - Intro 2023', {
  mediaFormat: {
    version: MediaFormatVersion.HD,
    url: getStaticAssetPath('videos/ond-intro-2023_720p.mp4'),
    width: 1280,
    height: 720,
    size: 635182,
    length: 7240,
  },
});

IntroMedias.insert('008', 'Live - Intro 2023 - Townhall', {
  mediaFormat: {
    version: MediaFormatVersion.FHD,
    url: getStaticAssetPath('videos/live-intro-townhall-2023_fhd.mp4'),
    width: 1920,
    height: 1080,
    size: 25443796,
    length: 40086,
  },
});

IntroMedias.insert('009', 'Live - Intro Holiday 2023', {
  mediaFormat: {
    version: MediaFormatVersion.FHD,
    url: getStaticAssetPath('videos/live-intro-holiday-2023_720p.mp4'),
    width: 1280,
    height: 720,
    size: 25710117,
    length: 48534,
  },
});

IntroMedias.insert('010', 'Live - Intro 2024', {
  mediaFormat: {
    version: MediaFormatVersion.FHD,
    url: getStaticAssetPath('videos/live-intro-2024_720p.mp4'),
    width: 1280,
    height: 720,
    size: 24984778,
    length: 71851,
  },
});

export const OutroMedias = new ContentRegistry<MediaItem, 'outro'>('outro');

OutroMedias.insert('001', 'Live - Outro', {
  mediaFormat: {
    version: MediaFormatVersion.FHD,
    url: getStaticAssetPath('videos/live-outro-001_fhd.mp4'),
    width: 1920,
    height: 1080,
    size: 3212897,
    length: 5014,
  },
});

OutroMedias.insert('002', 'Ond - Outro', {
  mediaFormat: {
    version: MediaFormatVersion.HD,
    width: 1280,
    height: 720,
    size: 1764848,
    url: getStaticAssetPath('videos/async-block-outro_720p.mp4'),
    length: 5005,
  },
});

OutroMedias.insert('003', 'Live - Outro - Member Promo March 2023', {
  mediaFormat: {
    version: MediaFormatVersion.FHD,
    url: getStaticAssetPath('videos/live-outro-member-promo-2023_fhd.mp4'),
    width: 1920,
    height: 1080,
    size: 18276918,
    length: 34518,
  },
});

OutroMedias.insert('004', 'Live - Outro - Spotify Member Promo March 2023', {
  mediaFormat: {
    version: MediaFormatVersion.FHD,
    url: getStaticAssetPath(
      'videos/live-outro-member-promo-spotify-2023_fhd.mp4'
    ),
    width: 1920,
    height: 1080,
    size: 17742750,
    length: 36523,
  },
});

export const Podiums = new ContentRegistry<MediaItem, 'podium'>('podium');

// NOTE: these podiums PNGs were run through https://tinypng.com/ to optimize
// their filesize. Then uploaded from the root using an example like:
//
// ```
// ./scripts/upload-static-assets '/Users/drew/Downloads/Live - Podium Holiday 2023.png' images/live-podium-holiday-2023_fhd.png
// ```

Podiums.insert('001', 'Live - Podium 2022', {
  mediaFormat: {
    version: MediaFormatVersion.FHD,
    url: getStaticAssetPath('images/live-podium-001_fhd.png'),
    width: 1920,
    height: 1080,
    size: 422259,
    length: 0,
  },
});

Podiums.insert('002', 'Live - Podium Halloween 2022', {
  mediaFormat: {
    version: MediaFormatVersion.FHD,
    url: getStaticAssetPath('images/live-podium-halloween-2022_fhd.png'),
    width: 1920,
    height: 1080,
    size: 389215,
    length: 0,
  },
});

Podiums.insert('003', 'Live - Podium Holiday 2022', {
  mediaFormat: {
    version: MediaFormatVersion.FHD,
    url: getStaticAssetPath('images/live-podium-holiday-2022_fhd.png'),
    width: 1920,
    height: 1080,
    size: 137755,
    length: 0,
  },
});

Podiums.insert('004', 'Live - Podium 2023', {
  mediaFormat: {
    version: MediaFormatVersion.FHD,
    url: getStaticAssetPath('images/live-podium-2023_fhd.png'),
    width: 1920,
    height: 1080,
    size: 115485,
    length: 0,
  },
});

Podiums.insert('005', 'Live - Podium Townhall 2023', {
  mediaFormat: {
    version: MediaFormatVersion.FHD,
    url: getStaticAssetPath('images/live-podium-townhall-2023_fhd.png'),
    width: 1920,
    height: 1080,
    size: 105407,
    length: 0,
  },
});

Podiums.insert('006', 'Live - Podium Spotify 2023', {
  mediaFormat: {
    version: MediaFormatVersion.FHD,
    url: getStaticAssetPath('images/live-podium-spotify-2023_fhd.png'),
    width: 1920,
    height: 1080,
    size: 107111,
    length: 0,
  },
});

Podiums.insert('007', 'Live - Podium Holiday 2023', {
  mediaFormat: {
    version: MediaFormatVersion.FHD,
    url: getStaticAssetPath('images/live-podium-holiday-2023_fhd.png'),
    width: 1920,
    height: 1080,
    size: 99355,
    length: 0,
  },
});

// Podiums.insert('008', 'Live - Podium 2024', {
//   mediaFormat: {
//     version: MediaFormatVersion.FHD,
//     url: getStaticAssetPath('images/live-podium-2024_fhd.png'),
//     width: 1920,
//     height: 1080,
//     size: 52084,
//     length: 0,
//   },
// });

Podiums.insert('009', 'Live - Podium 2024', {
  mediaFormat: {
    version: MediaFormatVersion.FHD,
    url: getStaticAssetPath('images/live-podium-2024-2_fhd.png'),
    width: 1920,
    height: 1080,
    size: 54764,
    length: 0,
  },
});

Podiums.insert('010', 'Cohost - Podium 2024 001 Solo', {
  mediaFormat: {
    version: MediaFormatVersion.FHD,
    url: getStaticAssetPath('images/cohost-podium-2024-002-solo-logo.png'),
    width: 1920,
    height: 1080,
    size: 57875,
    length: 0,
  },
});

export const FallbackIds = {
  OndStage: 'stage-002',
  LiveStage: 'stage-001',
  LiveIntro: 'intro-001',
  LiveOutro: 'outro-001',
  Podium: 'podium-001',
} as const;

export const Cameras = new ContentRegistry<BoundingBoxSettings, 'camera'>(
  'camera'
);

Cameras.insert('000', 'Default', defaultBoundingBoxSettings());

Cameras.insert('001', 'Ond', {
  box: { width: 1.5, height: 1.5, x: -0.25, y: -0.25 },
  fit: 'contain',
});

Cameras.insert('002', 'Live', {
  box: { width: 0.5, height: 0.5, x: 0.25, y: 0.25 },
  fit: 'contain',
});

// Cameras.insert('002', 'Live 0.658 contain', {
//   box: { width: 0.658, height: 0.658, x: 0.171, y: 0.2335 },
//   fit: 'contain',
// });

// Cameras.insert('003', 'Live 0.7 contain', {
//   box: { width: 0.7, height: 0.7, x: 0.15, y: 0.15 },
//   fit: 'contain',
// });

// Cameras.insert('004', 'Live 0.7 contain top', {
//   box: { width: 0.7, height: 0.7, x: 0.15, y: 0.1 },
//   fit: 'contain',
// });

// Cameras.insert('005', 'Live 0.8 contain top', {
//   box: { width: 0.8, height: 0.8, x: 0.1, y: 0.04 },
//   fit: 'contain',
// });
