import { useMemo } from 'react';

import { useLiveCallback } from '../../hooks/useLiveCallback';
import { apiService } from '../../services/api-service';
import { type Participant, type TeamId } from '../../types';
import { useFirebaseValue } from '../Firebase';
import { useTeamMembers } from '../TeamAPI/TeamV1';
import { useParticipantsByClientIds } from './exposed';
import { useParticipantsAsArrayGetter } from './useParticipantsAsArray';

export function useSelectTeamParticipants(
  teamId: TeamId,
  excludeMemberId?: string,
  pinMemberId?: string
): Participant[] {
  const teamMembers = useTeamMembers(teamId, true, pinMemberId);
  const memberIds = useMemo(() => {
    const candidates = excludeMemberId
      ? teamMembers?.filter((m) => m.id !== excludeMemberId)
      : teamMembers;
    return candidates?.map((m) => m.id);
  }, [teamMembers, excludeMemberId]);
  const teamParticipants = useParticipantsByClientIds(memberIds ?? []);
  return teamParticipants;
}

export function useHeartbeat(clientId: Nullable<string>) {
  const [val] = useFirebaseValue<Nullable<{ lastHeartbeatAt: number }>>(
    `heartbeat/${clientId ?? ''}`,
    {
      enabled: !!clientId,
      seedValue: null,
      seedEnabled: false,
      readOnly: true,
    }
  );
  return val?.lastHeartbeatAt;
}

/**
 * Returns a list of "new" player uids in the venue. "New" players are players that have no block play history.
 */
export function useNewPlayersGetter() {
  const getParticipantsAsArray = useParticipantsAsArrayGetter();
  return useLiveCallback(async () => {
    const uids = getParticipantsAsArray({
      filters: [
        'host:false',
        'cohost:false',
        'staff:false',
        'status:connected',
        'away:false',
      ],
    }).map((p) => p.id);
    const resp = await apiService.user.lookupNewUsers({ uids });
    return resp.data.uids;
  });
}
