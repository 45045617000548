export const scoreboardReorderDurationMs = 550;
export const scoreboardReorderDelayDurationMs = 500;
export const scoreChangeDurationMs = 1000;
export const scoreChangeFPS = 10;
export const scoreDeltaDurationMs = 2000;
// this is staggered
export const scoreDeltaDelayDurationMs = 75;
export const firstPlaceResizeDurationMs = 350;
export const firstPlaceResizeDelayDurationMs = 500;
export const animatedScoreboardStartDelayMs = 1250;

export function getApproximateTotalAnimationDurationMs(
  numScores: number,
  epsilon = 150
): number {
  if (numScores === 0) return epsilon;

  return (
    // there's react in the mix, so the timing is not exact
    animatedScoreboardStartDelayMs +
    scoreboardReorderDelayDurationMs +
    scoreboardReorderDurationMs +
    Math.max(scoreDeltaDelayDurationMs * (numScores - 1), 0) +
    scoreDeltaDurationMs +
    scoreChangeDurationMs +
    firstPlaceResizeDelayDurationMs +
    firstPlaceResizeDurationMs +
    epsilon
  );
}
