import { useEffect, useMemo, useState } from 'react';

import {
  assertExhaustive,
  type Block,
  type BlockDetailScore,
  type TeamDataList,
} from '@lp-lib/game';

import { type TeamV0 } from '../../../../types/team';
import { useTeams } from '../../../TeamAPI/TeamV1';
import { useScoreSummary } from '../../hooks';

export type TeamScore = {
  team: TeamV0;
  currentScore: number;
  totalScore: number;
};

export function useRankedTeamScores(
  sortBy: 'currentScore' | 'totalScore' | 'teamName' = 'currentScore',
  teamDetailScoreMap?: TeamDataList<BlockDetailScore> | null,
  preventZerosForDisplay: 'always' | 'never' = 'never'
): TeamScore[] {
  const teams = useTeams({
    active: true,
    updateStaffTeam: true,
    excludeStaffTeam: true,
    excludeCohostTeam: true,
  });
  const teamScoreSummaryMap = useScoreSummary();

  const [nonZeroCurrentScores] = useState(
    () => new Map<TeamV0['id'], number>()
  );

  return useMemo(() => {
    const summayMap = teamScoreSummaryMap || {};
    const detailScoreMap = teamDetailScoreMap || {};
    const teamScores: TeamScore[] = [];
    for (const team of teams) {
      const scoreSummary = summayMap[team.id] || {};
      const detailScore = detailScoreMap[team.id] || {};
      const latestCurrentScore =
        detailScore.score || scoreSummary.currentBlockScore || 0;
      if (latestCurrentScore !== 0)
        nonZeroCurrentScores.set(team.id, latestCurrentScore);
      // There is a race condition here. When a component calls this hook, it
      // might live for longer than the data that powers `useScoreSummary()`.
      // `useScoreSummary()` is nulled as part of the controller calling `end()`
      // for the block. So that can potentially happen while this component is
      // still mounted due to network and rendering being non-deterministic,
      // resulting in zeros flashing on the screen as `currentBlockScore`
      // becomes null / undefined. This can be jarring to the user. To cover for
      // it, this hook tracks non-zero values with the assumption the values
      // will only be used for display and not computational (source of truth)
      // purposes. Therefore, it's best to use `always` for player-facing
      // situations and `none` (default) for host-facing situations since they
      // may need to actually set the score to zero. See [LP-1793] for examples.
      const currentScore =
        preventZerosForDisplay === 'always'
          ? nonZeroCurrentScores.get(team.id) ?? latestCurrentScore
          : latestCurrentScore;
      teamScores.push({
        team,
        currentScore,
        totalScore: scoreSummary.totalScore || 0,
      });
    }
    switch (sortBy) {
      case 'currentScore':
        return teamScores.sort((a, b) => b.currentScore - a.currentScore);
      case 'totalScore':
        return teamScores.sort((a, b) => b.totalScore - a.totalScore);
      case 'teamName':
        return teamScores.sort((a, b) =>
          a.team.name.localeCompare(b.team.name || '', 'en', {
            sensitivity: 'base',
          })
        );
      default:
        assertExhaustive(sortBy);
        return teamScores;
    }
  }, [
    teamScoreSummaryMap,
    teamDetailScoreMap,
    sortBy,
    teams,
    nonZeroCurrentScores,
    preventZerosForDisplay,
  ]);
}

/**
 * This hook assume the block unchanged as long as the id keeps same.
 * @param block
 * @returns
 */
export function useStableBlock<T extends Block>(block: T): T {
  const [stableBlock, setStableBlock] = useState<T>(block);

  useEffect(() => {
    setStableBlock((prev) => {
      if (prev.id === block.id) return prev;
      return block;
    });
  }, [block]);

  return stableBlock;
}
