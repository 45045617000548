import { type UID } from 'agora-rtc-sdk-ng';

import { type AudioTrack } from './types';

export class AudioVolumeDetector {
  private audioTrackMap: Record<UID, AudioTrack>;
  private timerId?: ReturnType<typeof setTimeout>;
  constructor() {
    this.audioTrackMap = {};
  }

  start(callback: (volumeMap: Record<UID, number>) => void): void {
    this.stop();
    this.timerId = setInterval(() => {
      const volumeMap: Record<UID, number> = {};
      for (const [uid, audioTrack] of Object.entries(this.audioTrackMap)) {
        volumeMap[uid] = audioTrack.getVolumeLevel() * 100;
      }
      callback(volumeMap);
    }, 1000);
  }

  stop(): void {
    if (this.timerId) {
      clearTimeout(this.timerId);
    }
  }

  addAudioTrack(uid: UID, audioTrack: AudioTrack): void {
    this.audioTrackMap[uid] = audioTrack;
  }

  removeAudioTrack(uid: UID): void {
    delete this.audioTrackMap[uid];
  }
}
