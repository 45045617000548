import { useEffect, useRef, useState } from 'react';

interface DraggableProps<
  CElement extends HTMLElement,
  HElement extends HTMLElement
> {
  children: (
    containerRef: React.MutableRefObject<CElement | null>,
    handleRef: React.MutableRefObject<HElement | null>
  ) => JSX.Element;
  initialOffsetX?: number;
  initialOffsetY?: number;
  limitX?: number;
  limitY?: number;
  onMove?: (offset: DraggableXYPoint) => void;
  onPointerDownUp?: (down: boolean) => void;
}

export type DraggableXYPoint = { x: number; y: number };

export function Draggable<
  CElement extends HTMLElement = HTMLDivElement,
  HElement extends HTMLElement = HTMLDivElement
>(props: DraggableProps<CElement, HElement>): JSX.Element {
  const containerRef = useRef<CElement | null>(null);
  const handleRef = useRef<HElement | null>(null);
  const onMoveCbRef = useRef<DraggableProps<CElement, HElement>['onMove']>(
    props.onMove
  );
  const onPointerDownUpCbRef = useRef<
    DraggableProps<CElement, HElement>['onPointerDownUp']
  >(props.onPointerDownUp);
  const [pointerStart, setPointerStart] = useState<null | DraggableXYPoint>(
    null
  );
  const [containerOffset, setContainerOffset] =
    useState<null | DraggableXYPoint>({
      x: 0,
      y: 0,
    });
  const positionRef = useRef<null | DraggableXYPoint>(null);

  const offsetsApplied = useRef(false);

  // Apply initial offset if defined
  useEffect(() => {
    if (!containerRef.current || offsetsApplied.current) return;
    containerRef.current.style.setProperty(
      '--tw-translate-x',
      `${props.initialOffsetX ?? 0}px`
    );
    containerRef.current.style.setProperty(
      '--tw-translate-y',
      `${props.initialOffsetY ?? 0}px`
    );

    setContainerOffset({
      x: props.initialOffsetX ?? 0,
      y: props.initialOffsetY ?? 0,
    });
    offsetsApplied.current = true;
  }, [props.initialOffsetX, props.initialOffsetY]);

  useEffect(() => {
    const onPointerMove = (ev: PointerEvent) => {
      if (!pointerStart || !containerOffset) return;
      ev.preventDefault();

      const deltaX = ev.pageX - pointerStart.x;
      const deltaY = ev.pageY - pointerStart.y;

      positionRef.current = {
        x: containerOffset.x + deltaX,
        y: containerOffset.y + deltaY,
      };

      if (props.limitX !== undefined) {
        positionRef.current.x = Math.max(
          0,
          Math.min(positionRef.current.x, props.limitX)
        );
      }

      if (props.limitY !== undefined) {
        positionRef.current.y = Math.max(
          0,
          Math.min(positionRef.current.y, props.limitY)
        );
      }

      if (containerRef.current) {
        containerRef.current.style.setProperty(
          '--tw-translate-x',
          `${positionRef.current.x}px`
        );
        containerRef.current.style.setProperty(
          '--tw-translate-y',
          `${positionRef.current.y}px`
        );
      }
      onMoveCbRef.current?.(positionRef.current);
    };

    const onPointerUp = () => {
      onPointerDownUpCbRef.current?.(false);
      setPointerStart(null);
      if (positionRef.current) {
        setContainerOffset(positionRef.current);
      }
    };

    document.addEventListener('pointermove', onPointerMove);
    document.addEventListener('pointerup', onPointerUp);

    return () => {
      document.removeEventListener('pointermove', onPointerMove);
      document.removeEventListener('pointerup', onPointerUp);
    };
  }, [containerOffset, pointerStart, props.limitX, props.limitY]);

  useEffect(() => {
    const handler = handleRef.current || containerRef.current;
    const container = containerRef.current;
    const onPointerDown = (ev: PointerEvent) => {
      setPointerStart({ x: ev.pageX, y: ev.pageY });
      onPointerDownUpCbRef.current?.(true);
      ev.preventDefault();
    };
    handler?.addEventListener('pointerdown', onPointerDown);
    const addTransform = !container?.classList.contains('transform');
    if (addTransform) container?.classList.add('transform');
    return () => {
      handler?.removeEventListener('pointerdown', onPointerDown);
      if (addTransform) container?.classList.remove('transform');
    };
  }, []);
  return props.children(containerRef, handleRef);
}
