import { useEffect, useMemo, useRef } from 'react';
import { useLatest, usePreviousDistinct } from 'react-use';

import { EnumsHiddenPicturePenaltyResetStrategy } from '@lp-lib/api-service-client/public';
import {
  type HiddenPictureBlock,
  HiddenPictureBlockGameSessionStatus,
} from '@lp-lib/game';

import { assertExhaustive, nullOrUndefined } from '../../../../utils/common';
import { useDatabaseSafeRef, useFirebaseValue } from '../../../Firebase';
import { increment } from '../../../Firebase/utils';
import { useIsStreamSessionAlive } from '../../../Session';
import { useTeams } from '../../../TeamAPI/TeamV1';
import { useVenueId } from '../../../Venue/VenueProvider';
import {
  useGameSessionBlockId,
  useGameSessionLocalTimer,
  useGameSessionStatus,
} from '../../hooks';
import { type GameControlProps } from '../Common/GameControl/types';
import { useStableBlock } from '../Common/hooks';
import {
  useHiddenPictureGame,
  useHiddenPictureGameControl,
  useHiddenPictureGamePictures,
  useHiddenPicturePenaltyResetStrategy,
} from './HiddenPictureProvider';
import {
  GameState,
  type TeamGamePlayData,
  type TeamProgressData,
} from './types';
import { HiddenPictureUtils } from './utils';

type SharedProps = GameControlProps<HiddenPictureBlock>;

function Loaded(): JSX.Element | null {
  const { resetGame } = useHiddenPictureGameControl();
  useEffect(() => {
    resetGame('loaded');
  }, [resetGame]);
  return null;
}

function GameInit(props: SharedProps): JSX.Element | null {
  const { block } = props;
  const { initGame } = useHiddenPictureGameControl();

  useEffect(() => {
    initGame(block);
  }, [block, initGame]);

  return null;
}

function TeamAdvancer(props: {
  teamId: string;
  block: HiddenPictureBlock;
}): JSX.Element {
  const gameStartedAt = useLatest(useHiddenPictureGame()?.startTime);
  const pictures = useHiddenPictureGamePictures();
  const penaltyResetStrategy = useHiddenPicturePenaltyResetStrategy();
  const venueId = useVenueId();
  const isSessionAlive = useIsStreamSessionAlive();
  const blockId = useGameSessionBlockId() ?? '';
  const advancing = useRef(false);

  const teamGamePlayRef = useDatabaseSafeRef<TeamGamePlayData>(
    HiddenPictureUtils.TeamGamePlayPath(venueId, blockId, props.teamId)
  );

  const [teamProgressData, updateTeamProgressData] = useFirebaseValue<
    Nullable<TeamProgressData>
  >(HiddenPictureUtils.TeamProgressPath(venueId, blockId, props.teamId), {
    enabled: isSessionAlive && !!props.teamId,
    seedValue: {
      currentPictureIndex: 0,
      numPicturesCompleted: 0,
      numItemsFound: 0,
      numPenalties: 0,
      score: 0,
      found: {},
      completedAllAt: null,
    },
    seedEnabled: true,
    readOnly: false,
    resetWhenUmount: true,
  });

  useEffect(() => {
    async function run() {
      if (
        advancing.current ||
        nullOrUndefined(teamProgressData) ||
        teamProgressData.numPicturesCompleted === pictures.length
      )
        return;

      const { currentPictureIndex, found } = teamProgressData;
      if (
        currentPictureIndex === null ||
        currentPictureIndex < 0 ||
        currentPictureIndex >= pictures.length
      ) {
        return;
      }

      const hotSpots = pictures[currentPictureIndex].hotSpotsV2 ?? [];
      const foundItems = Object.keys(found ?? {});
      let numItemsFound = 0;
      for (const itemId of foundItems) {
        const hotSpot = hotSpots.find((hs) => hs.id === itemId);
        if (hotSpot && hotSpot.points >= 0) {
          numItemsFound++;
        }
      }

      const allFound = HiddenPictureUtils.AllFound(hotSpots, found);
      if (allFound) {
        advancing.current = true;
        const update: Partial<TeamProgressData> = {
          numPicturesCompleted: increment(1),
          numItemsFound: increment(numItemsFound),
        };

        // done with this one. can we advance?
        if (currentPictureIndex + 1 < pictures.length) {
          update.currentPictureIndex = increment(1);
          update.found = {};
          if (
            penaltyResetStrategy ===
            EnumsHiddenPicturePenaltyResetStrategy.HiddenPicturePenaltyResetStrategyPerPicture
          ) {
            update.numPenalties = 0;
          }
        } else {
          // this was the last one.
          const completedAllAt = Date.now();
          update.completedAllAt = completedAllAt;
          // compute a time bonus.
          if (
            props.block.fields.bonusPointsPerRemainingSec !== 0 &&
            gameStartedAt.current
          ) {
            const totalTimeSec = props.block.fields.gameTimeSec;
            const timeToCompleteSec =
              (completedAllAt - gameStartedAt.current) / 1000;

            const remainingSec = Math.max(0, totalTimeSec - timeToCompleteSec);
            const bonus = Math.round(
              props.block.fields.bonusPointsPerRemainingSec * remainingSec
            );
            update.score = increment(bonus);
          }
        }

        await teamGamePlayRef.set({} as never);
        await updateTeamProgressData(update as never);
      }
    }
    run().finally(() => {
      advancing.current = false;
    });
  }, [
    gameStartedAt,
    penaltyResetStrategy,
    pictures,
    props.block.fields.bonusPointsPerRemainingSec,
    props.block.fields.gameTimeSec,
    props.teamId,
    teamGamePlayRef,
    teamProgressData,
    updateTeamProgressData,
    venueId,
  ]);
  return <></>;
}

function GameStart(props: SharedProps): JSX.Element | null {
  const { block } = props;
  const timeLeft = useGameSessionLocalTimer();
  const { startGame, stopGame } = useHiddenPictureGameControl();
  const game = useHiddenPictureGame();
  const teams = useTeams({
    updateStaffTeam: true,
    excludeStaffTeam: true,
  });
  const teamIds = useMemo(() => {
    return teams.map((t) => t.id);
  }, [teams]);

  const gameState = game?.state;

  useEffect(() => {
    startGame();
  }, [startGame]);

  useEffect(() => {
    if (
      nullOrUndefined(timeLeft) ||
      nullOrUndefined(gameState) ||
      timeLeft > 0 ||
      gameState < GameState.InProgress
    )
      return;
    stopGame();
  }, [gameState, stopGame, timeLeft]);

  return (
    <>
      {teamIds.map((teamId) => (
        <TeamAdvancer key={teamId} teamId={teamId} block={block} />
      ))}
    </>
  );
}

function GameEnd(_props: SharedProps): JSX.Element | null {
  const { deinitGame, stopGame } = useHiddenPictureGameControl();

  useEffect(() => {
    stopGame();
  }, [stopGame]);

  useEffect(() => {
    return () => {
      deinitGame();
    };
  }, [deinitGame]);

  return null;
}

export function HiddenPictureBlockGameControl(
  props: SharedProps
): JSX.Element | null {
  const block = useStableBlock(props.block);
  const gameSessionStatus =
    useGameSessionStatus<HiddenPictureBlockGameSessionStatus>();
  const { resetGame } = useHiddenPictureGameControl();
  const currBlockId = block.id;
  const prevBlockId = usePreviousDistinct(currBlockId);

  useEffect(() => {
    if (prevBlockId && prevBlockId !== currBlockId) {
      resetGame(`block id changed prev:${prevBlockId} curr:${currBlockId}`);
    }
  }, [currBlockId, resetGame, prevBlockId]);

  useEffect(() => {
    return () => {
      resetGame('game control unload');
    };
  }, [resetGame]);

  switch (gameSessionStatus) {
    case HiddenPictureBlockGameSessionStatus.LOADED:
      return <Loaded />;
    case HiddenPictureBlockGameSessionStatus.GAME_INIT:
      return <GameInit block={block} />;
    case HiddenPictureBlockGameSessionStatus.GAME_START:
      return <GameStart block={block} />;
    case HiddenPictureBlockGameSessionStatus.GAME_END:
      return <GameEnd block={block} />;
    case HiddenPictureBlockGameSessionStatus.INTRO:
    case HiddenPictureBlockGameSessionStatus.OUTRO:
    case HiddenPictureBlockGameSessionStatus.RESULTS:
    case HiddenPictureBlockGameSessionStatus.SCOREBOARD:
    case HiddenPictureBlockGameSessionStatus.END:
    case null:
    case undefined:
      break;
    default:
      assertExhaustive(gameSessionStatus);
      break;
  }

  return null;
}
