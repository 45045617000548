import Uppy, { type UppyFile } from '@uppy/core';
import { FileInput } from '@uppy/react';
import pluralize from 'pluralize';
import { useState } from 'react';

import { EnumsMediaScene } from '@lp-lib/api-service-client/public';
import {
  type GuessWhoBlock,
  type GuessWhoBlockMedia,
  type GuessWhoPrompt,
} from '@lp-lib/game';

import { useLiveCallback } from '../../../../hooks/useLiveCallback';
import { useUppy } from '../../../../hooks/useUppy';
import { uuidv4 } from '../../../../utils/common';
import { createDownloadCSVFile, csvToArray } from '../../../../utils/csv';
import { DeleteIcon } from '../../../icons/DeleteIcon';
import { DownloadIcon } from '../../../icons/DownloadIcon';
import {
  AdditionalSettings,
  AdditionalSharedSettingsEditor,
  BlockMediaEditor,
  EditorBody,
  EditorLayout,
  type EditorProps,
  type Option,
  RHFCheckbox,
  RHFSelectField,
  useEditor,
} from '../Common/Editor/EditorUtilities';

const timeOptions: Option[] = [
  10, 15, 20, 30, 45, 60, 90, 120, 150, 180, 210, 240, 300, 600, 900, 1800,
].map((secs) => {
  if (secs < 60) {
    return { label: `${secs} ${pluralize('second', secs)}`, value: secs };
  } else {
    const mins = secs / 60;
    return { label: `${mins} ${pluralize('minute', mins)}`, value: secs };
  }
});

const pointOptions: Option[] = [0, 25, 50, 100, 150, 200].map((i) => ({
  value: i,
  label: `${i}`,
}));

function PromptHeader(props: {
  blockId: string;
  promptCount: number;
  handleAddBatch: (prompts: GuessWhoPrompt[]) => void;
}) {
  const uploaderId = `csv-uploader-${props.blockId}`;
  const fileParser = useLiveCallback((currentFile: UppyFile) => {
    const reader = new FileReader();

    reader.onload = (e) => {
      const content = e.target?.result ?? '';
      if (!content) return;

      const csv = csvToArray(content.toString());
      if (csv.length <= 1) return;
      const rows = csv.splice(1);
      const prompts = rows.map<GuessWhoPrompt>((row) => ({
        id: uuidv4(),
        text: row[0],
      }));
      props.handleAddBatch(prompts);
    };

    reader.readAsText(currentFile.data);
    return false;
  });

  const uppy = useUppy(
    uploaderId,
    () =>
      new Uppy({
        id: uploaderId,
        autoProceed: false,
        allowMultipleUploads: true,
        debug: true,
        restrictions: {
          maxFileSize: 1024 * 1024,
          minFileSize: null,
          maxTotalFileSize: 1024 * 1024,
          maxNumberOfFiles: null,
          minNumberOfFiles: 1,
          allowedFileTypes: ['.csv'],
        },
        locale: {
          strings: {
            chooseFiles: 'Upload CSV',
            exceedsSize: 'Max file size exceeded',
            youCanOnlyUploadFileTypes: 'File type not supported',
          },
        },
        onBeforeFileAdded: fileParser,
      })
  );

  return (
    <div className='flex gap-10 items-center'>
      <div className='text-white font-bold'>Prompts ({props.promptCount})</div>
      <div id='editor-csv-uploader'>
        <FileInput uppy={uppy} inputName={'files'} />
      </div>
      <a
        className='flex items-center justify-center text-xs text-icon-gray font-light border-b border-icon-gray'
        href={createDownloadCSVFile(['PROMPTS'])}
        download='Guess Who Prompts Template.csv'
      >
        <DownloadIcon className='w-3.5 h-3.5 fill-current' />
        Template
      </a>
    </div>
  );
}

function Prompt(props: {
  initialValue: GuessWhoPrompt;
  handleUpdate: (prompt: GuessWhoPrompt) => void;
  handleDelete: (prompt: GuessWhoPrompt) => void;
}): JSX.Element {
  return (
    <div className='flex items-center justify-center gap-3'>
      <input
        className='field h-10 mb-0'
        defaultValue={props.initialValue.text}
        onBlur={(event) => {
          props.handleUpdate({
            ...props.initialValue,
            text: event.target.value,
          });
        }}
        maxLength={300}
        placeholder='Max 300 characters'
      />

      <button
        type='button'
        className='btn w-7.5 h-7.5 bg-black flex-shrink-0 border border-secondary rounded-lg flex items-center justify-center text-red-002 hover:bg-secondary'
        onClick={() => props.handleDelete(props.initialValue)}
      >
        <DeleteIcon />
      </button>
    </div>
  );
}

function Prompts(props: {
  blockId: string;
  onUpdate: (updated: GuessWhoPrompt[]) => void;
  initialValue?: GuessWhoPrompt[];
}): JSX.Element {
  const { onUpdate, initialValue } = props;
  const [prompts, setPrompts] = useState<GuessWhoPrompt[]>(
    initialValue ? [...initialValue] : []
  );

  const handleAddMore = () => {
    setPrompts((current) => {
      const next = [...current, { id: uuidv4(), text: '' }];
      onUpdate(next);
      return next;
    });
  };

  const handleUpdate = (updated: GuessWhoPrompt) => {
    setPrompts((current) => {
      const index = current.findIndex((c) => c.id === updated.id);
      if (index === -1) return current;
      const next = [...current];
      next[index] = updated;
      onUpdate(next);
      return next;
    });
  };

  const handleDelete = (updated: GuessWhoPrompt) => {
    setPrompts((current) => {
      const index = current.findIndex((c) => c.id === updated.id);
      if (index === -1) return current;
      const next = [...current];
      next.splice(index, 1);
      onUpdate(next);
      return next;
    });
  };

  const handleAddBatch = (newPrompts: GuessWhoPrompt[]) => {
    setPrompts((current) => {
      const next = [...current, ...newPrompts];
      onUpdate(next);
      return next;
    });
  };

  return (
    <div className='w-full'>
      <div className='mb-5'>
        <PromptHeader
          blockId={props.blockId}
          handleAddBatch={handleAddBatch}
          promptCount={prompts.length}
        />
      </div>
      <div className='w-4/5 flex flex-col gap-2'>
        {prompts.map((prompt) => (
          <Prompt
            key={prompt.id}
            initialValue={prompt}
            handleUpdate={handleUpdate}
            handleDelete={handleDelete}
          />
        ))}
      </div>
      <button
        type='button'
        className='btn underline text-icon-gray text-sms mt-6'
        onClick={handleAddMore}
      >
        Add More
      </button>
    </div>
  );
}

export function GuessWhoBlockEditor(
  props: EditorProps<GuessWhoBlock>
): JSX.Element {
  const { block } = props;
  const blockId = block.id;
  const { updateField } = useEditor(props);

  return (
    <EditorLayout
      bottomAccessory={
        <AdditionalSettings>
          <AdditionalSharedSettingsEditor {...props} />
        </AdditionalSettings>
      }
    >
      <EditorBody>
        <p className='text-2xl text-white'>Guess Who</p>
        <form className='w-full my-7.5'>
          <div className='w-full flex flex-col'>
            <div className='w-full flex flex-row'>
              <div className='w-2/3 text-base font-bold'>
                <Prompts
                  blockId={blockId}
                  onUpdate={(prompts) => updateField('prompts', prompts)}
                  initialValue={block.fields.prompts}
                />
              </div>
              <div className='w-1/3 text-base font-bold flex flex-col items-center'>
                <label htmlFor='introMedia' className='w-full my-2'>
                  <BlockMediaEditor<GuessWhoBlockMedia>
                    blockId={blockId}
                    width='w-full'
                    title='Intro Media'
                    field='introMedia'
                    video
                    scene={EnumsMediaScene.MediaSceneBlockMedia}
                    volumeSelectable
                    mediaData={block.fields.introMediaData}
                    media={block.fields.introMedia}
                    extraNotice='Media will display to the audience when the question is presented.'
                  />
                </label>
                <label htmlFor='backgroundMedia' className='w-full my-2'>
                  <BlockMediaEditor<GuessWhoBlockMedia>
                    blockId={blockId}
                    width='w-full'
                    title='Background Media'
                    field='backgroundMedia'
                    video
                    scene={EnumsMediaScene.MediaSceneBlockBackground}
                    volumeSelectable
                    loopSelectable
                    mediaData={block.fields.backgroundMediaData}
                    media={block.fields.backgroundMedia}
                    extraNotice='Media will display in the background during the Game Timer.'
                  />
                </label>
                <hr className='w-full my-5 border border-secondary' />
                <label htmlFor='promptTimeSec' className='w-full my-2'>
                  <RHFSelectField<GuessWhoBlock>
                    className='w-full h-10 text-white'
                    label='Prompt Timer'
                    name='promptTimeSec'
                    options={timeOptions}
                    onChange={updateField}
                    value={block.fields.promptTimeSec}
                  />
                </label>

                <div className='w-full my-2'>
                  <span className='text-white font-bold'>Guess Timer</span>
                  <div className='text-icon-gray text-sms mt-2 font-normal'>
                    Min time 5 seconds. Max time 2 minutes. Auto progress after
                    everyone votes.
                  </div>
                </div>

                <label htmlFor='pointsPerCorrect' className='w-full my-2'>
                  <RHFSelectField<GuessWhoBlock>
                    className='w-full h-10 text-white'
                    label='Points Per Correct Guess'
                    name='pointsPerCorrect'
                    options={pointOptions}
                    onChange={updateField}
                    value={block.fields.pointsPerCorrect}
                  />
                </label>
                <label htmlFor='decreasingPointsTimer' className='w-full my-2'>
                  <RHFCheckbox<GuessWhoBlock>
                    label='Show Other Guessers'
                    name='showGuessers'
                    value={block.fields.showGuessers}
                    onChange={(_, checked: boolean): void => {
                      updateField('showGuessers', checked);
                    }}
                    description={{
                      enabled:
                        'Enabled: User guesses are public and visible immediately to all users when submitted.',
                      disabled:
                        'Disabled: Users guesses are not visible until everyone has submitted.',
                    }}
                  />
                </label>
              </div>
            </div>
            <div className='w-full h-60'></div>
          </div>
        </form>
      </EditorBody>
    </EditorLayout>
  );
}
