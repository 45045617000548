import { ScoreboardMode } from '@lp-lib/game';
import {
  type Media,
  type MediaFormat,
  MediaFormatVersion,
} from '@lp-lib/media';

import { MediaUtils } from '../../../../utils/media';
import { ContentRegistry } from '../../../VideoEffectsSettings/ContentRegistry';
import { getApproximateTotalAnimationDurationMs } from '../Common/GamePlay/GamePlayScoreboardAnimationConstants';

const ScoreboardVoiceOverFallbacks = new ContentRegistry<
  MediaFormat,
  'sb-vo-fallbacks'
>('sb-vo-fallbacks');

ScoreboardVoiceOverFallbacks.insert('single', 'single', {
  version: MediaFormatVersion.Raw,
  width: 0,
  height: 0,
  size: 124551,
  url: 'https://assets.golunapark.com/audios/19643/a193a09dba0a49778b77c603efbb9a1d.mp3',
  length: 7784,
});

ScoreboardVoiceOverFallbacks.insert('first', 'first', {
  version: MediaFormatVersion.Raw,
  width: 0,
  height: 0,
  size: 114938,
  url: 'https://assets.golunapark.com/audios/19643/58738620cebb475180da4aac960924b2.mp3',
  length: 7183,
});

ScoreboardVoiceOverFallbacks.insert('mid', 'mid', {
  version: MediaFormatVersion.Raw,
  width: 0,
  height: 0,
  size: 93622,
  url: 'https://assets.golunapark.com/audios/19643/e4aae48cbce148e3ada53bb5110a04ef.mp3',
  length: 5851,
});

ScoreboardVoiceOverFallbacks.insert('last', 'last', {
  version: MediaFormatVersion.Raw,
  width: 0,
  height: 0,
  size: 129985,
  url: 'https://assets.golunapark.com/audios/19643/2a0fa0c4ed5b4198ae8465eb48675d66.mp3',
  length: 8124,
});

export class ScoreboardUtils {
  static GetWaitModeExitableAfterSec(
    mode: ScoreboardMode,
    numScores: number,
    media: Media | null | undefined,
    animatedScoreboardEnabled: boolean
  ): number {
    const animationEnabled =
      animatedScoreboardEnabled &&
      (mode === ScoreboardMode.VenueTeams ||
        mode === ScoreboardMode.VenueGlobalTeams);

    const animationDuration = animationEnabled
      ? getApproximateTotalAnimationDurationMs(numScores)
      : 0;

    return (
      Math.max(
        MediaUtils.GetMediaDurationMs(media ?? null),
        animationDuration
      ) / 1000
    );
  }
}
