import { type UID } from 'agora-rtc-sdk-ng';
import { useCallback, useEffect, useRef, useState } from 'react';

import logger from '../logger/logger';
import { type IRTCService } from '../services/webrtc';
import { err2s } from '../utils/common';
import { releaseMediaStream } from '../utils/media';

const log = logger.scoped('useRemoteStream');

export function useRemoteStream(
  rtcService: IRTCService,
  targetUid: string | null | undefined,
  constraints?: MediaTrackConstraints
): MediaStream | null {
  const [mediaStream, setMediaStream] = useState<MediaStream | null>(null);
  const mediaStreamRef = useRef<MediaStream | null>(null);

  const updateMediaStream = useCallback(() => {
    if (!targetUid) return;
    const [, videoTrack] = rtcService.getTracksByUid(targetUid);
    if (mediaStreamRef.current) {
      releaseMediaStream(mediaStreamRef.current);
    }
    if (videoTrack) {
      const stream = new MediaStream();
      const v = videoTrack.getMediaStreamTrack().clone();
      if (constraints) {
        v.applyConstraints(constraints).catch((err) => {
          log.error('applyConstraints failed', err2s(err));
        });
      }
      stream.addTrack(v);
      setMediaStream(stream);
      mediaStreamRef.current = stream;
    } else {
      setMediaStream(null);
      mediaStreamRef.current = null;
    }
  }, [constraints, rtcService, targetUid]);

  const publishedCallback = useCallback(
    (uid: UID, mediaType: 'audio' | 'video') => {
      if (uid === targetUid && mediaType === 'video') {
        updateMediaStream();
      }
    },
    [targetUid, updateMediaStream]
  );

  const unpublishedCallback = useCallback(
    (uid: UID, mediaType: 'audio' | 'video') => {
      if (uid === targetUid && mediaType === 'video') {
        updateMediaStream();
      }
    },
    [targetUid, updateMediaStream]
  );

  useEffect(() => {
    if (!rtcService) return;
    updateMediaStream();
    rtcService.on('video-switched', updateMediaStream);
    rtcService.on('remote-user-published', publishedCallback);
    rtcService.on('remote-user-unpublished', unpublishedCallback);
    return () => {
      rtcService.off('video-switched', updateMediaStream);
      rtcService.off('remote-user-published', publishedCallback);
      rtcService.off('remote-user-unpublished', unpublishedCallback);
    };
  }, [rtcService, publishedCallback, unpublishedCallback, updateMediaStream]);

  useEffect(() => {
    return () => {
      releaseMediaStream(mediaStreamRef.current);
      mediaStreamRef.current = null;
      setMediaStream(null);
    };
  }, []);

  return mediaStream;
}
