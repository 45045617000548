import { captureException } from '@sentry/remix';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  useLatest,
  useMountedState,
  usePrevious,
  usePreviousDistinct,
} from 'react-use';

import { ProfileIndex } from '@lp-lib/crowd-frames-schema';
import { RTDBServerValueTIMESTAMP } from '@lp-lib/firebase-typesafe';

import { useLiveCallback } from '../../../../hooks/useLiveCallback';
import logger from '../../../../logger/logger';
import { type SequenceConfig } from '../../../../types/block';
import { assertExhaustive } from '../../../../utils/common';
import { TertiaryTooltipBackground } from '../../../common/Utilities';
import { CrowdFramesAvatar } from '../../../CrowdFrames';
import { LayoutAnchor } from '../../../LayoutAnchors/LayoutAnchors';
import { useParticipantByUserId } from '../../../Player';
import { useSoundEffect } from '../../../SFX';
import { useUser } from '../../../UserContext';
import {
  useSharedContext,
  useTeamRelayGameSettings,
  useTeamRelayGameState,
  useTeamSequenceFinished,
} from './Context';
import { NodeIndicator } from './NodeIndicator';
import {
  ColorPalette,
  GameState,
  HITTABLE,
  type PlayerId,
  type Progress,
  type RelayNode,
  RelayNodeState,
  RenderType,
  type Sequence,
} from './types';
import { isIgnorableKey } from './utils';

const log = logger.scoped('team-relay');

type OnHitHandler = (trackId: number) => void;
type OnMissHandler = (trackId: number, src?: string) => void;

function useTeamRelayPlaySFX(
  progress: Nullable<Progress>,
  sequence: Nullable<Sequence>
): void {
  const finishedSequenceIdx = useTeamSequenceFinished();

  const { play: playHitSFX } = useSoundEffect('teamRelayHit');
  const { play: playMissSFX } = useSoundEffect('teamRelayMiss');
  const { play: playFinishedSFX } = useSoundEffect('teamRelaySequenceFinished');
  const gameState = useTeamRelayGameState();
  const lastNodeState = progress?.lastNodeState;
  const updatedAt = progress?.localUpdatedAt;

  useEffect(() => {
    if (gameState !== GameState.InProgress) return;
    switch (lastNodeState) {
      case RelayNodeState.Hit:
        playHitSFX();
        break;
      case RelayNodeState.Miss:
        playMissSFX();
        break;
      case RelayNodeState.NotPlayed:
      case undefined:
        break;
      default:
        assertExhaustive(lastNodeState);
        break;
    }
  }, [playHitSFX, playMissSFX, lastNodeState, gameState, updatedAt]);

  useEffect(() => {
    if (finishedSequenceIdx === null) return;
    playFinishedSFX();
  }, [playFinishedSFX, finishedSequenceIdx]);

  const curr = sequence?.direction;
  const prev = usePreviousDistinct(curr);
  const { play: playReverseSFX } = useSoundEffect('teamRelayReverse');
  useEffect(() => {
    if (!prev || !curr || curr === prev) return;
    playReverseSFX();
  }, [curr, playReverseSFX, prev]);
}

type SharedProps = {
  trackId: number;
  onHit: OnHitHandler;
  onMiss: OnMissHandler;
  paused: boolean;
};

type NodeProps = {
  trackId: number;
  inputKey: string;
  playable: boolean;
  direction: SequenceConfig['direction'];
  progress: Progress;
  onHit: OnHitHandler;
  onMiss: OnMissHandler;
};

function Placeholder(): JSX.Element {
  return <div className='w-10 h-10'></div>;
}

function YourTurnToolip(props: {
  inputKey: string;
  paused: boolean;
}): JSX.Element {
  return (
    <div
      className={`relative ${props.paused ? '' : 'motion-safe:animate-bounce'}`}
    >
      <div className='z-0 w-full h-full'>
        <TertiaryTooltipBackground
          className='w-64 h-16'
          preserveAspectRatio='none'
        />
      </div>
      <div
        className={`w-full h-full
            rounded-xl flex flex-col items-center justify-center 
            text-black text-center absolute inset-0 -top-px z-5`}
      >
        <div className='font-bold'>It's your turn!</div>
        <div>Press the "{props.inputKey}" key to progress.</div>
      </div>
    </div>
  );
}

function TapNode(props: NodeProps): JSX.Element {
  const { trackId, inputKey, playable, onHit, onMiss } = props;

  useEffect(() => {
    if (!playable) return;
    function keyDown(ev: KeyboardEvent) {
      log.info('tap node key down', { evKey: ev.key, inputKey });
      if (isIgnorableKey(ev.key) || ev.repeat) return;
      if (ev.key.toUpperCase() === inputKey) {
        onHit(trackId);
      } else {
        onMiss(trackId, 'TapNode');
      }
    }
    document.addEventListener('keydown', keyDown);
    return () => {
      document.removeEventListener('keydown', keyDown);
    };
  }, [inputKey, onHit, onMiss, playable, trackId]);

  return (
    <div className={`w-10 h-10 ${!playable && 'opacity-60'} relative`}>
      <div
        className={`w-full h-full flex items-center justify-center transform rotate-45 scale-71 absolute z-0 inset-0 ${
          playable ? 'border rounded-sm p-1 scale-90' : 'scale-71'
        }`}
        style={{
          borderColor: playable ? ColorPalette.Primary[trackId] : undefined,
        }}
      >
        <div
          className='w-full h-full  rounded'
          style={{
            background: ColorPalette.Outline[trackId],
            boxShadow: `0px 0px 8px ${ColorPalette.Primary[trackId]}`,
          }}
        >
          <div
            className='team-relay-node-space rounded'
            style={{
              background: ColorPalette.Background[trackId],
            }}
          />
        </div>
      </div>
      <div className='w-full h-full absolute z-5 flex items-center justify-center text-white font-bold text-2xl font-cairo select-none'>
        {inputKey}
      </div>
    </div>
  );
}

function HoldNodeStart(props: NodeProps): JSX.Element {
  const { trackId, inputKey, playable, onHit, onMiss, progress } = props;
  const rightKeyPressed = useRef(false);
  const currentNodeIdx = progress.currentNodeIdx;

  // sequence restarts from beginning, reset the press state.
  useEffect(() => {
    if (currentNodeIdx === 0) {
      rightKeyPressed.current = false;
    }
  }, [currentNodeIdx]);

  useEffect(() => {
    if (!playable) return;
    function keyDown(ev: KeyboardEvent) {
      log.info('hold node start key down', { evKey: ev.key, inputKey });
      if (isIgnorableKey(ev.key)) return;
      if (rightKeyPressed.current === false && ev.repeat) return;
      if (ev.key.toUpperCase() === inputKey) {
        onHit(trackId);
        rightKeyPressed.current = true;
      } else {
        onMiss(trackId, 'HoldNodeStart#keyDown');
      }
    }
    function keyUp(ev: KeyboardEvent) {
      log.info('hold node start key up', { evKey: ev.key, inputKey });
      if (isIgnorableKey(ev.key) || !rightKeyPressed.current) return;
      onMiss(trackId, 'HoldNodeStart#keyUp');
    }
    document.addEventListener('keydown', keyDown);
    document.addEventListener('keyup', keyUp);
    return () => {
      document.removeEventListener('keydown', keyDown);
      document.removeEventListener('keyup', keyUp);
    };
  }, [inputKey, onHit, onMiss, playable, trackId]);

  return (
    <div className={`w-10 h-10 ${!playable && 'opacity-60'} relative`}>
      <div
        className={`w-12 h-12 rounded-full flex items-center justify-center 
        absolute z-0 -top-1 -left-1 ${playable ? 'border' : ''}`}
        style={{
          borderColor: playable ? ColorPalette.Primary[trackId] : undefined,
        }}
      >
        <div
          className='w-10 h-10 rounded-full'
          style={{
            background: ColorPalette.Background[trackId],
            boxShadow: `0px 0px 8px ${ColorPalette.Primary[trackId]}`,
          }}
        ></div>
      </div>
      <div className='w-full h-full absolute z-5 flex items-center justify-center text-white font-bold text-2xl font-cairo select-none'>
        {inputKey}
      </div>
      <div
        className='text-xs font-cairo font-bold italic absolute -top-3 -right-5'
        style={{
          color: ColorPalette.Primary[trackId],
        }}
      >
        HOLD
      </div>
    </div>
  );
}

function HoldNodeJoin(props: NodeProps): JSX.Element {
  const { trackId, inputKey, playable, onMiss } = props;

  useEffect(() => {
    if (!playable) return;
    function keyDown(ev: KeyboardEvent) {
      log.info('hold node join key down', { evKey: ev.key, inputKey });
      if (isIgnorableKey(ev.key)) return;
      if (ev.key.toUpperCase() === inputKey && ev.repeat) return;
      onMiss(trackId, 'HoldNodeJoin#keyDown');
    }
    function keyUp(ev: KeyboardEvent) {
      log.info('hold node join key up', { evKey: ev.key, inputKey });
      if (isIgnorableKey(ev.key)) return;
      onMiss(trackId, 'HoldNodeJoin#keyUp');
    }
    document.addEventListener('keydown', keyDown);
    document.addEventListener('keyup', keyUp);
    return () => {
      document.removeEventListener('keydown', keyDown);
      document.removeEventListener('keyup', keyUp);
    };
  }, [inputKey, onMiss, playable, trackId]);
  return (
    <div className='w-10 h-10 opacity-60 flex items-center justify-center'>
      <div
        className='w-full h-1'
        style={{ backgroundColor: ColorPalette.Primary[trackId] }}
      ></div>
    </div>
  );
}

function HoldNodeEnd(props: NodeProps): JSX.Element {
  const { trackId, inputKey, playable, onHit, onMiss, direction } = props;
  useEffect(() => {
    if (!playable) return;
    function keyDown(ev: KeyboardEvent) {
      log.info('hold node end key down', { evKey: ev.key, inputKey });
      if (isIgnorableKey(ev.key)) return;
      if (ev.key.toUpperCase() === inputKey) return;
      onMiss(trackId, 'HoldNodeEnd#keyDown');
    }
    function keyUp(ev: KeyboardEvent) {
      log.info('hold node end key up', { evKey: ev.key, inputKey });
      if (isIgnorableKey(ev.key)) return;
      if (ev.key.toUpperCase() === inputKey) {
        onHit(trackId);
      } else {
        onMiss(trackId, 'HoldNodeEnd#keyUp');
      }
    }
    document.addEventListener('keydown', keyDown);
    document.addEventListener('keyup', keyUp);
    return () => {
      document.removeEventListener('keydown', keyDown);
      document.removeEventListener('keyup', keyUp);
    };
  }, [inputKey, onHit, onMiss, playable, trackId]);
  return (
    <div
      className={`w-10 h-10 ${!playable && 'opacity-60'} flex ${
        direction === 'forward' ? 'flex-row' : 'flex-row-reverse'
      } items-center justify-start rounded-full`}
    >
      <div
        className='w-2 h-1'
        style={{ backgroundColor: ColorPalette.Primary[trackId] }}
      ></div>
      <div
        className={`rounded-full ${playable ? 'border p-0.5' : ''}`}
        style={{
          borderColor: playable ? ColorPalette.Primary[trackId] : undefined,
        }}
      >
        <div
          className={`w-6 h-6 rounded-full flex items-center justify-center `}
          style={{
            background: ColorPalette.Background[trackId],
            boxShadow: `0px 0px 8px ${ColorPalette.Primary[trackId]}`,
          }}
        >
          <div className='w-4 h-4 rounded-full bg-black'></div>
        </div>
      </div>
    </div>
  );
}

const NODE_MAP: { [key in RenderType]: (props: NodeProps) => JSX.Element } = {
  [RenderType.Tap]: TapNode,
  [RenderType.HoldStart]: HoldNodeStart,
  [RenderType.HoldJoin]: HoldNodeJoin,
  [RenderType.HoldEnd]: HoldNodeEnd,
  [RenderType.Placeholder]: Placeholder,
};

function NodeItem(
  props: SharedProps & {
    sequence: Sequence;
    progress: Progress;
    relayNode: RelayNode & { playable: boolean };
    playerId: PlayerId;
  }
): JSX.Element | null {
  const { trackId, relayNode, sequence, progress, onHit, onMiss } = props;
  const playable = relayNode.playable;
  const inputKey = useMemo(
    () => relayNode.inputKey || `${trackId + 1}`,
    [relayNode.inputKey, trackId]
  );
  const [showTooltip, setShowTooltip] = useState(false);

  useEffect(() => {
    if (!playable || !HITTABLE.has(relayNode.renderType)) return;
    const timer = setTimeout(() => {
      setShowTooltip(true);
    }, 2000);
    return () => {
      setShowTooltip(false);
      clearTimeout(timer);
    };
  }, [playable, relayNode.renderType]);

  const args = {
    trackId,
    inputKey,
    playable,
    onHit,
    onMiss,
    direction: sequence.direction,
    progress: progress,
  };

  let Node: (props: NodeProps) => JSX.Element;

  switch (relayNode.renderType) {
    case RenderType.Tap:
    case RenderType.HoldStart:
    case RenderType.HoldJoin:
    case RenderType.HoldEnd:
    case RenderType.Placeholder:
      Node = NODE_MAP[relayNode.renderType];
      break;
    default:
      assertExhaustive(relayNode.renderType);
      return null;
  }

  return (
    <div className='relative'>
      <Node {...args} />
      {showTooltip && (
        <div className='absolute left-5 -top-17'>
          <YourTurnToolip inputKey={inputKey} paused={props.paused} />
        </div>
      )}
    </div>
  );
}

function Track(
  props: SharedProps & {
    sequence: Nullable<Sequence>;
    progress: Nullable<Progress>;
    trackId: number;
    playerId: PlayerId;
    ready: boolean;
  }
): JSX.Element | null {
  const { trackId, sequence, progress } = props;
  const nodes = sequence?.grid[trackId] || [];
  const direction = sequence?.direction || 'forward';
  const participant = useParticipantByUserId(props.playerId, true);
  const updatedAt = progress?.localUpdatedAt;
  const prevUpdatedAt = usePrevious(updatedAt);
  const [lastNodeState, setLastNodeState] = useState<
    RelayNodeState.Hit | RelayNodeState.Miss | null
  >(null);
  const isMounted = useMountedState();
  const gameState = useTeamRelayGameState();
  const user = useUser();

  // correct/wrong animation
  useEffect(() => {
    if (
      !sequence?.grid ||
      !progress?.lastNodeState ||
      progress?.lastPlayerIdx === undefined ||
      updatedAt === prevUpdatedAt
    )
      return;
    if (progress.lastPlayerIdx !== trackId) return;
    setLastNodeState(progress.lastNodeState);
    setTimeout(() => {
      if (isMounted()) setLastNodeState(null);
    }, 100);
  }, [
    prevUpdatedAt,
    sequence?.grid,
    trackId,
    updatedAt,
    isMounted,
    progress?.lastNodeState,
    progress?.lastPlayerIdx,
  ]);

  const playableNodes = nodes.map((n) => {
    const playable =
      progress?.currentNodeIdx === n.seqId &&
      user.id === props.playerId &&
      gameState === GameState.InProgress &&
      props.ready &&
      !props.paused;
    return { ...n, playable };
  });

  const logPlayable = useLiveCallback(() => {
    log.info('track playable', { nodes: playableNodes });
  });

  useEffect(() => {
    logPlayable();
  }, [logPlayable, progress?.currentNodeIdx]);

  return (
    <div
      className={`w-full h-10 my-0.5 bg-gradient-to-r ${
        lastNodeState === RelayNodeState.Hit
          ? 'from-relay-track-correct-bg-start to-relay-track-bg-end'
          : lastNodeState === RelayNodeState.Miss
          ? 'from-relay-track-wrong-bg-start to-relay-track-wrong-bg-end'
          : 'from-relay-track-bg-start to-relay-track-bg-end'
      } to-relay-track-bg-end flex`}
    >
      <div className='w-10 h-10 relative -left-2'>
        {participant && (
          <CrowdFramesAvatar
            participant={participant}
            profileIndex={ProfileIndex.wh100x100fps8}
            enablePointerEvents={false}
          />
        )}
        <div
          className={`w-full h-full rounded-full bg-black bg-opacity-40 absolute z-15 ${
            lastNodeState ? 'invisible' : 'visible'
          }`}
        />
        <div
          className='w-full h-full rounded-full absolute z-0 -right-1'
          style={{
            backgroundColor: ColorPalette.Primary[props.trackId],
          }}
        />
      </div>
      <div
        className={`w-full flex ${
          direction === 'forward' ? 'flex-row' : 'flex-row-reverse'
        }`}
      >
        {progress &&
          sequence &&
          playableNodes.map((n) => (
            <NodeItem
              key={n.seqId}
              relayNode={n}
              {...props}
              progress={progress}
              sequence={sequence}
            />
          ))}
      </div>
    </div>
  );
}

function useWrongTurnPenalty(onMiss: OnMissHandler): boolean {
  const { playerIds, sequence, progress } = useSharedContext();
  const latestProgress = useLatest(progress);
  const latestSequence = useLatest(sequence);
  const user = useUser();
  const myTrackId = useMemo(
    () => playerIds.findIndex((playerId) => playerId === user.id),
    [user.id, playerIds]
  );
  const gameState = useTeamRelayGameState();
  const settings = useTeamRelayGameSettings();
  const enabled =
    !!settings?.wrongTurnPenalty && gameState === GameState.InProgress;
  const [inited, setInited] = useState(false);

  useEffect(() => {
    if (!enabled || myTrackId === -1) return;
    function keyDown(ev: KeyboardEvent) {
      if (isIgnorableKey(ev.key) || ev.repeat) return;
      if (
        !latestProgress.current ||
        !latestSequence.current ||
        latestSequence.current.grid.length === 0
      )
        return;
      const currNodeIdx = latestProgress.current.currentNodeIdx;
      // When the sequence finished, the currNodeIdx is equal to the length of the sequence
      // before the next sequence being created.
      if (currNodeIdx >= latestSequence.current.grid[0].length) return;
      try {
        const hittableTrackId = latestSequence.current.grid.findIndex((r) =>
          HITTABLE.has(r[currNodeIdx].renderType)
        );
        if (hittableTrackId !== myTrackId) {
          onMiss(myTrackId, 'wrong turn penalty');
        }
      } catch (error) {
        captureException(error, {
          extra: {
            sequence: JSON.stringify(latestSequence.current),
            progress: JSON.stringify(latestProgress.current),
          },
        });
      }
    }
    document.addEventListener('keydown', keyDown);
    setInited(true);
    return () => {
      document.removeEventListener('keydown', keyDown);
      setInited(false);
    };
  }, [enabled, latestProgress, latestSequence, myTrackId, onMiss]);

  return !!settings?.wrongTurnPenalty ? inited : true;
}

export function TeamRelayPlayground(props: {
  paused: boolean;
}): JSX.Element | null {
  const { playerIds, sequence, progress, updateProgress } = useSharedContext();
  const latestProgress = useLatest(progress);

  const onHit = useCallback(
    (trackId: number) => {
      if (!latestProgress.current) return;
      updateProgress({
        idx: latestProgress.current.idx,
        currentNodeIdx: latestProgress.current.currentNodeIdx + 1,
        lastNodeState: RelayNodeState.Hit,
        lastPlayerIdx: trackId,
        localUpdatedAt: Date.now(),
        updatedAt: RTDBServerValueTIMESTAMP,
        initedGameTime: latestProgress.current.initedGameTime,
      });
    },
    [latestProgress, updateProgress]
  );

  const onMiss = useCallback(
    (trackId: number, src?: string) => {
      if (!latestProgress.current) return;
      log.info('onMiss', {
        src,
        idx: latestProgress.current.idx,
        lastPlayerIdx: trackId,
      });
      updateProgress({
        idx: latestProgress.current.idx,
        currentNodeIdx: 0,
        lastNodeState: RelayNodeState.Miss,
        lastPlayerIdx: trackId,
        localUpdatedAt: Date.now(),
        updatedAt: RTDBServerValueTIMESTAMP,
        initedGameTime: latestProgress.current.initedGameTime,
      });
    },
    [latestProgress, updateProgress]
  );

  useTeamRelayPlaySFX(progress, sequence);
  const penaltyReady = useWrongTurnPenalty(onMiss);

  return (
    <div className='flex flex-col items-center justify-center relative w-full'>
      <LayoutAnchor
        id='team-relay-game-playground-anchor'
        className='absolute w-full h-0 top-0'
      />
      <NodeIndicator
        progress={progress}
        direction={sequence?.direction || 'forward'}
      />
      <div className='w-full flex flex-col items-center justify-center bg-team-relay-playground py-6 relative'>
        <div className='w-full h-0.5 bg-team-relay-border opacity-40 absolute -top-0.5'></div>
        {playerIds.map((playerId, idx) => (
          <Track
            trackId={idx}
            key={playerId}
            playerId={playerId}
            sequence={sequence}
            progress={progress}
            onHit={onHit}
            onMiss={onMiss}
            ready={penaltyReady}
            paused={props.paused}
          />
        ))}
        <div className='w-full h-0.5 bg-team-relay-border opacity-40 absolute -bottom-0.5'></div>
      </div>
    </div>
  );
}
