import React, {
  type ReactNode,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';

import { type GamePack } from '../../../types/game';
import { GameCenterContextProvider, useGameLikeWorkspace } from '../GameCenter';

export type GameLibraryType =
  | 'game'
  | 'gamePack'
  | 'publicLibrary'
  | 'bottomPublicLibrary'
  | 'zoom';

type Library = {
  type: GameLibraryType;
  canLoad?: () => Promise<boolean>;
  onGamePackClick?: (pack: GamePack) => Promise<boolean>;
  gamePack?: GamePack;
};

type GameLibraryContext = {
  library: Library | null;
  open: (props?: {
    type?: Library['type'];
    canLoad?: Library['canLoad'];
    gamePack?: Library['gamePack'];
    onGamePackClick?: Library['onGamePackClick'];
  }) => void;
  close: () => void;
  update: (props: Partial<Library>) => void;
};

const Context = React.createContext<null | GameLibraryContext>(null);

function useGameLibraryContext(): GameLibraryContext {
  const ctx = useContext(Context);
  if (!ctx) {
    throw new Error('GameLibraryContext is not in the tree!');
  }
  return ctx;
}

export function useOpenGameLibrary(): GameLibraryContext['open'] {
  const ctx = useGameLibraryContext();
  return ctx.open;
}

export function useCloseGameLibrary(): GameLibraryContext['close'] {
  const ctx = useGameLibraryContext();
  return ctx.close;
}

export function useUpdateGameLibrary(): GameLibraryContext['update'] {
  const ctx = useGameLibraryContext();
  return ctx.update;
}

export function useGameLibrary(): GameLibraryContext['library'] {
  const ctx = useGameLibraryContext();
  return ctx.library;
}

export function useOpenGamePackEditor(): (pack: GamePack) => void {
  const [, setEditingGamePack] = useGameLikeWorkspace('gamePack', 'edit');

  return (pack: GamePack) => {
    setEditingGamePack(pack);
  };
}

export function useOpenGamePackPreview(): (pack: GamePack) => void {
  const [, setPreviewGamePack] = useGameLikeWorkspace('gamePack', 'preview');

  return (pack: GamePack) => {
    setPreviewGamePack(pack);
  };
}

export function GameLibraryProvider(props: {
  children?: ReactNode;
}): JSX.Element {
  const [library, setLibrary] = useState<Library | null>(null);

  const open = useCallback(
    (props?: Parameters<GameLibraryContext['open']>[0]) => {
      const library: Library = Object.assign({ type: 'gamePack' }, props);
      setLibrary(library);
    },
    []
  );

  const close = useCallback(() => {
    setLibrary(null);
  }, []);

  const update = useCallback((data: Partial<Library>) => {
    setLibrary((prev) => (prev ? { ...prev, ...data } : null));
  }, []);

  const ctxValue: GameLibraryContext = useMemo(
    () => ({
      library,
      open,
      close,
      update,
    }),
    [close, library, open, update]
  );
  return (
    <Context.Provider value={ctxValue}>
      <GameCenterContextProvider>{props.children}</GameCenterContextProvider>
    </Context.Provider>
  );
}
