import React, { useContext } from 'react';

import { type FPSContext } from './types';

// See FPSManager.ts for how to initialize the actual context. It's best to do
// it lazily for the sake of testing.
export const Context = React.createContext<FPSContext | null>(null);

export function useFPSContext(): FPSContext {
  const ctx = useContext(Context);
  if (!ctx) throw new Error('FPSContext was undefined');
  return ctx;
}
