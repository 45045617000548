export const getDryLevel = (mix: number): number => {
  if (mix > 1 || mix < 0) return 0;

  if (mix <= 0.5) return 1;

  return 1 - (mix - 0.5) * 2;
};

export const getWetLevel = (mix: number): number => {
  if (mix > 1 || mix < 0) return 0;

  if (mix >= 0.5) return 1;

  return 1 - (0.5 - mix) * 2;
};

// Convert the raw slider value into something nicer for the human ear, which
// generally perceives in terms of logarithms. This allows the slider, when
// _visually_ at 50%, to _sound_ like 50% volume in terms of human perception.
// https://ux.stackexchange.com/a/116300. An exponent of 3 here was chosen
// mostly arbitrarily, since that is what many popular music players use.
export const mapLinearVolumeToLogarithmic = (
  value: number,
  min: number,
  max: number,
  exp = 3
): number => {
  const mappedValue = value - min;
  const mappedMax = max - min;
  const of1 = mappedValue / mappedMax;
  const logish = Math.pow(of1, exp);
  return (max - min) * logish + min;
};

export const mapLogarithmicVolumeToLinear = (
  value: number,
  min: number,
  max: number,
  exp = 3
): number => {
  const mappedValue = value - min;
  const mappedMax = max - min;
  const of1 = mappedValue / mappedMax;
  const linear = Math.pow(of1, 1 / exp);
  return (max - min) * linear + min;
};
