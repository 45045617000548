import composeRefs from '@seznam/compose-react-refs';
import { forwardRef, useEffect, useRef, useState } from 'react';
import { usePromise } from 'react-use';

import { useForceUpdate } from '../../hooks/useForceUpdate';
import { PlayIcon } from '../icons/PlayIcon';

type AudioPreviewProps = {
  src?: string;
  switchable?: boolean;
  deviceId?: string;
};

export const AudioPreview = forwardRef<HTMLAudioElement, AudioPreviewProps>(
  (props, externalRef): JSX.Element | null => {
    const { src, switchable, deviceId } = props;
    const ref = useRef<HTMLDivElement | null>(null);
    const audioRef = useRef<HTMLAudioElement | null>(null);
    const [playing, setPlaying] = useState(false);
    const [currentTime, setCurrentTime] = useState(0);
    const mounted = usePromise();
    const forceUpdate = useForceUpdate();

    const duration = audioRef.current?.duration || 0;
    const percentage = duration ? (currentTime / duration) * 100 : 0;

    useEffect(() => {
      if (!switchable) return;
      const switchAudioOutput = async () => {
        if (!deviceId) return;
        const audioEl = audioRef.current;
        if (!audioEl?.setSinkId) return;
        try {
          await mounted(audioEl.setSinkId(deviceId));
        } catch (error: UnassertedUnknown) {}
        forceUpdate();
      };
      switchAudioOutput();
    }, [mounted, forceUpdate, switchable, deviceId]);

    useEffect(() => {
      if (!src || !audioRef.current) return;
      const el = audioRef.current;
      const abort = new AbortController();
      el.preload = 'metadata';
      el.src = src;
      el.addEventListener(
        'ended',
        () => {
          setPlaying(false);
        },
        { signal: abort.signal }
      );
      el.addEventListener(
        'timeupdate',
        () => {
          setCurrentTime(el.currentTime);
        },
        { signal: abort.signal }
      );
      return () => abort.abort();
    }, [src]);

    const toggle = () => {
      if (playing) {
        audioRef.current?.pause();
      } else {
        audioRef.current?.play();
      }
      setPlaying(!playing);
    };

    const handleSeektime = (event: React.MouseEvent) => {
      if (!ref.current || !duration) return;

      const rect = ref.current.getBoundingClientRect();
      const barWidth = ref.current.clientWidth;
      const clickedX = event.clientX - rect.left;
      const clickedTime = (duration * clickedX) / barWidth;

      if (audioRef.current) {
        audioRef.current.currentTime = clickedTime;
      }
      setCurrentTime(clickedTime);
    };

    return (
      <div className='w-full flex items-center gap-2.5 group'>
        <button
          type='button'
          className='w-30 h-7 flex-shrink-0 btn-secondary text-2xs rounded-lg
           text-secondary hover:text-white flex items-center justify-center gap-2
           font-medium tracking-wider'
          onClick={toggle}
        >
          {playing ? (
            <>Stop</>
          ) : (
            <>
              <PlayIcon className='w-3 h-3 text-secondary group-hover:text-white' />
              Test Speaker
            </>
          )}
        </button>
        <div
          ref={ref}
          className={`flex-grow h-[5px] rounded-sm relative overflow-hidden ${
            duration > 0 ? 'cursor-pointer' : ''
          }`}
          onClick={handleSeektime}
        >
          <div className='bg-[#232325] w-full h-full absolute z-0'></div>
          <div
            className='bg-[#2cff67] absolute h-full'
            style={{
              width: `${percentage}%`,
            }}
          ></div>
        </div>
        <audio ref={composeRefs(externalRef, audioRef)} className='hidden' />
      </div>
    );
  }
);
