import { useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { useEffectOnce, usePrevious } from 'react-use';

import { ProfileIndex } from '@lp-lib/crowd-frames-schema';
import {
  type AIChatBlock,
  AIChatBlockGameSessionStatus,
  getTimedScoringFunction,
  getTimedScoringKind,
} from '@lp-lib/game';

import { useLiveCallback } from '../../../../hooks/useLiveCallback';
import { useMyInstance } from '../../../../hooks/useMyInstance';
import {
  ClientTypeUtils,
  displayName,
  type Participant,
  type TeamId,
} from '../../../../types';
import { assertExhaustive } from '../../../../utils/common';
import { CrowdFramesAvatar } from '../../../CrowdFrames';
import { useFirebaseContext } from '../../../Firebase';
import { RefreshIcon } from '../../../icons/RefreshIcon';
import { Loading } from '../../../Loading';
import { useParticipantByUserId } from '../../../Player';
import { useParticipant } from '../../../Player';
import {
  useAutoPickNextPlayerIfCurrentDropped,
  useCurrentPlayer,
} from '../../../RoundRobinPlayer';
import {
  useIsTeamCaptainScribe,
  useTeamMembersGetter,
} from '../../../TeamAPI/TeamV1';
import { useTeamColor } from '../../../TeamAPI/TeamV1';
import {
  useMyClientId,
  useMyClientType,
} from '../../../Venue/VenuePlaygroundProvider';
import { useVenueId } from '../../../Venue/VenueProvider';
import { useGameSessionLocalTimer } from '../../hooks';
import { useGamePlayEmitter } from '../Common/GamePlay/GamePlayProvider';
import { InputBarView } from '../Common/GamePlay/GamePlaySubmissionInput';
import { ProgressRing } from '../Common/GamePlay/ProgressRing';
import { useTeamSubmissionStatusAPI } from '../Common/GamePlay/SubmissionStatusProvider';
import {
  AIChatGamePlayAPI,
  useAIChatGamePlayAPI,
  useAutoGrading,
  useGameResult,
  useHostInspectingTeamId,
  useIsConversationInited,
  useIsConversationLoaded,
  useLastMessage,
  useMessages,
  useNarrowedPromptTemplate as usePromptTemplate,
  useSetupGamePlay,
} from './AIChatProvider';
import { type AIChatMessage } from './types';
import { AIChatUtils, log } from './utils';
import { uncheckedIndexAccess_UNSAFE } from '../../../../utils/uncheckedIndexAccess_UNSAFE';

function useScoreFunction(
  block: AIChatBlock
): ReturnType<typeof getTimedScoringFunction> {
  return useMemo(() => {
    return getTimedScoringFunction(
      getTimedScoringKind(
        block.fields.decreasingPointsTimer,
        block.fields.startDescendingImmediately
      ),
      block.fields.winningPoints,
      block.fields.gameTimeSec
    );
  }, [
    block.fields.decreasingPointsTimer,
    block.fields.gameTimeSec,
    block.fields.startDescendingImmediately,
    block.fields.winningPoints,
  ]);
}

function ChatMessageBody(props: {
  message: AIChatMessage;
}): JSX.Element | null {
  const { message } = props;
  switch (message.role) {
    case 'system':
    case 'function':
      return null;
    case 'user':
      return <>{message.content}</>;
    case 'assistant':
      return <>{message.parsed?.dialogue ?? message.content}</>;
    default:
      assertExhaustive(message.role);
      return null;
  }
}

function ChatMessageError(props: { message: AIChatMessage }) {
  const { message } = props;
  const api = useAIChatGamePlayAPI();
  // host can inspect the chat conversation
  const isHost = ClientTypeUtils.isHost(useMyClientType());

  return (
    <div className='flex items-center gap-2'>
      <div className='text-red-002'>
        Something went wrong. Please click here to reload
      </div>
      {!isHost && (
        <button
          type='button'
          className='btn-primary w-7.5 h-7.5 flex items-center justify-center rounded-lg'
          onClick={() => api.retry(message.id)}
        >
          <RefreshIcon />
        </button>
      )}
    </div>
  );
}

function ChatMessage(props: {
  block: AIChatBlock;
  bot: { avatar: string; name: string };
  message: AIChatMessage;
  teamId: TeamId;
}): JSX.Element | null {
  const { bot, message, teamId } = props;
  const color = useTeamColor(teamId) ?? '#232325';
  const sender = useParticipantByUserId(message.sender.uid);
  const senderName = displayName(sender);
  if (message.role === 'system') return null;
  return (
    <div
      className='w-full p-6 opacity-80 rounded-5xl'
      style={{
        backgroundColor: message.role === 'assistant' ? '#000000' : color,
      }}
    >
      <div className='text-sm italic mb-1'>
        {message.role === 'assistant' ? bot.name : senderName}
      </div>
      <div className='font-medium break-words'>
        {message.status === 'loading' ? (
          <Loading text='' containerClassName='w-7.5 h-7.5' />
        ) : message.status === 'error' ? (
          <ChatMessageError message={message} />
        ) : (
          <ChatMessageBody message={message} />
        )}
      </div>
    </div>
  );
}

function ChatConversation(props: {
  block: AIChatBlock;
  bot: { avatar: string; name: string };
  teamId: TeamId;
  messages: AIChatMessage[];
}) {
  const { messages } = props;
  const ref = useRef<HTMLDivElement>(null);
  const messagesCount = messages.length;

  const scrollToBottom = useLiveCallback(() => {
    if (!ref.current) return;
    const scrollHeight = ref.current.scrollHeight;
    const height = ref.current.clientHeight;
    const maxScrollTop = scrollHeight - height;
    ref.current.scrollTop = maxScrollTop > 0 ? maxScrollTop : 0;
  });

  useLayoutEffect(() => scrollToBottom(), [scrollToBottom]);
  useLayoutEffect(() => scrollToBottom(), [messagesCount, scrollToBottom]);

  return (
    <div
      ref={ref}
      className='flex-grow flex flex-col-reverse gap-2 w-full h-full overflow-y-auto scrollbar chat-list-mask-image'
    >
      {Object.keys(messages)
        .reverse()
        .map((index) => (
          <ChatMessage
            key={index}
            {...props}
            message={uncheckedIndexAccess_UNSAFE(messages)[index]}
          />
        ))}
    </div>
  );
}

function ChatMessageInput(props: {
  block: AIChatBlock;
  isMyTurn: boolean;
  playable: boolean;
  currentPlayer: Participant;
}) {
  const { block, isMyTurn, playable, currentPlayer } = props;
  const [inputValue, setInputValue] = useState('');
  const inited = useIsConversationInited();
  const lastMessage = useLastMessage();
  const sendable = inited && !lastMessage.status && playable;
  const api = useAIChatGamePlayAPI();
  const gameResult = useGameResult();
  const playerName = displayName(currentPlayer);
  const gameDone = gameResult === 'win' || gameResult === 'lose';
  const maxLength = 500;

  const send = async (value: string) => {
    const trimmed = value.trim().slice(0, maxLength);
    if (!trimmed) return;
    setInputValue('');
    api.send(
      {
        uid: currentPlayer.id,
        displayName: playerName,
      },
      trimmed,
      block.fields.temperature
    );
  };

  const status =
    gameResult === 'win'
      ? 'Correct'
      : gameResult === 'lose'
      ? 'Wrong'
      : sendable
      ? 'InProgress'
      : 'NotStarted';
  const buttonText = gameDone ? 'Done' : sendable ? 'Send' : 'Waiting';
  const placeholder =
    gameResult === 'win'
      ? 'You Win!'
      : gameResult === 'lose'
      ? 'You Lose!'
      : isMyTurn
      ? 'Enter your answer here'
      : `${playerName} is answering the question`;

  return (
    <InputBarView
      isMyTurn={isMyTurn}
      submitterName={playerName}
      status={status}
      value={inputValue}
      onChange={setInputValue}
      onSubmit={send}
      textAlign='text-left'
      customPlaceholder={placeholder}
      customButtonText={buttonText}
      maxLength={maxLength}
    />
  );
}

function TeamCaptainWatch(props: {
  block: AIChatBlock;
  teamId: TeamId;
  points: number;
}): JSX.Element | null {
  const { points, teamId } = props;

  useAutoGrading(teamId, points);
  const submissionStatusAPI = useTeamSubmissionStatusAPI();
  const emitter = useGamePlayEmitter();

  useEffect(() => {
    return emitter.once('ended', (_, state) => {
      if (state === 'finished') {
        submissionStatusAPI.markSubmitted();
      }
    });
  }, [emitter, submissionStatusAPI]);

  return null;
}

function Bot(props: { bot: { avatar: string; name: string } }) {
  const { bot } = props;

  return (
    <div className='flex flex-col items-center text-xl font-bold text-shadow-md'>
      <div
        className='w-50 h-50 rounded-5xl lp-sm:w-60 lp-sm:h-60 lp-sm:rounded-6.25xl'
        style={{
          backgroundImage: `url("${bot.avatar}")`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
        }}
      ></div>
      <div>{bot.name}</div>
    </div>
  );
}

function useIsMyTurn(
  block: AIChatBlock,
  isTeamCaptain: boolean,
  currentPlayer: Participant | null
) {
  const myClientId = useMyClientId();
  return block.fields.roundRobinMode
    ? currentPlayer?.clientId === myClientId
    : isTeamCaptain;
}

function useBot(block: AIChatBlock) {
  return useMemo(
    () => ({
      avatar: AIChatUtils.GetBotAvatar(block.fields.bot),
      name: AIChatUtils.GetBotName(block.fields.bot),
    }),
    [block.fields.bot]
  );
}

function Player(props: { clientId: string }) {
  const currClientId = props.clientId;
  const prevClientId = usePrevious(currClientId);
  const p = useParticipant(props.clientId);
  if (!p || prevClientId !== currClientId) return null;
  return (
    <CrowdFramesAvatar
      participant={p}
      profileIndex={ProfileIndex.wh100x100fps8}
      enablePointerEvents={false}
    />
  );
}

export function AIChatPlayground(props: {
  block: AIChatBlock;
  gss: AIChatBlockGameSessionStatus;
}): JSX.Element | null {
  const { block, gss } = props;
  const me = useMyInstance();
  const isTeamCaptain = useIsTeamCaptainScribe(me?.teamId, me?.clientId);
  const api = useAIChatGamePlayAPI();
  const loaded = useIsConversationLoaded();
  const started = gss > AIChatBlockGameSessionStatus.GAME_INIT;
  const playable = gss === AIChatBlockGameSessionStatus.GAME_START;
  const timer = useGameSessionLocalTimer();
  const bot = useBot(block);
  const scoring = useScoreFunction(block);
  const messages = useMessages(api);

  useEffectOnce(() => {
    if (!isTeamCaptain) return;
    api.roundRobinAPI.nextPlayer('ai-chat-first-pick');
  });
  useAutoPickNextPlayerIfCurrentDropped(api.roundRobinAPI, isTeamCaptain, true);
  const currentPlayer = useCurrentPlayer(api.roundRobinAPI);
  const isMyTurn = useIsMyTurn(block, isTeamCaptain, currentPlayer);

  useEffect(() => {
    if (!isMyTurn || !loaded) return;
    api.kickoff(
      block.fields.systemPrompt,
      block.fields.systemPromptTemperature ?? 0.1
    );
  }, [
    isMyTurn,
    loaded,
    api,
    block.fields.systemPrompt,
    block.fields.systemPromptTemperature,
  ]);

  if (!currentPlayer || !started || !currentPlayer.teamId || timer === null)
    return null;

  const points = scoring.get(timer);

  const durationFormattedMMSS = block.fields.gameTimeSec > 60;

  return (
    <div className='flex flex-col w-[95%] h-full gap-4 relative'>
      <div className='absolute transform-gpu -translate-x-full -left-3 top-0'>
        <Bot bot={bot} />
      </div>
      <ChatConversation
        block={block}
        bot={bot}
        messages={messages}
        teamId={currentPlayer.teamId}
      />
      <div className='flex items-center relative'>
        <div className='flex items-center absolute transform-gpu -translate-x-full -left-1'>
          <ProgressRing
            className='relative'
            currentTime={timer}
            totalTime={block.fields.gameTimeSec}
            withPingAnimations
            durationFormattedMMSS={durationFormattedMMSS}
            radiusBreakpoints={{
              '': 42,
              xl: 42,
              'lp-sm': 42,
              '2xl': 42,
              '3xl': 42,
              'lp-md': 42,
              'lp-lg': 42,
            }}
          />
          <div className='relative w-16 h-16 overflow-hidden'>
            <Player clientId={currentPlayer.clientId} />
          </div>
        </div>
        <ChatMessageInput
          block={block}
          isMyTurn={isMyTurn}
          playable={playable}
          currentPlayer={currentPlayer}
        />
      </div>
      {isTeamCaptain && (
        <TeamCaptainWatch
          block={block}
          teamId={currentPlayer.teamId}
          points={points}
        />
      )}
    </div>
  );
}

function InspectingChatConversation(props: {
  block: AIChatBlock;
  teamId: TeamId;
}) {
  const { block, teamId } = props;
  const venueId = useVenueId();
  const { svc } = useFirebaseContext();
  const getTeamMembers = useTeamMembersGetter();
  const api = useMemo(
    () => new AIChatGamePlayAPI(venueId, teamId, svc, log, getTeamMembers),
    [getTeamMembers, svc, teamId, venueId]
  );
  const bot = useBot(block);
  useSetupGamePlay(
    api,
    block.fields.model ?? undefined,
    usePromptTemplate(block.fields.promptTemplateId),
    true
  );
  const messages = useMessages(api);

  return (
    <div className='flex flex-col w-[95%] h-full gap-4 relative'>
      <div className='absolute transform-gpu -translate-x-full -left-3 top-0'>
        <Bot bot={bot} />
      </div>
      <ChatConversation
        block={block}
        bot={bot}
        teamId={teamId}
        messages={messages}
      />
    </div>
  );
}

export function AIChatHostView(props: {
  block: AIChatBlock;
  gss: AIChatBlockGameSessionStatus;
}): JSX.Element | null {
  const { block } = props;
  const inspectingTeamId = useHostInspectingTeamId();

  if (!inspectingTeamId) return null;

  return <InspectingChatConversation block={block} teamId={inspectingTeamId} />;
}
