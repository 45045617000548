import isEqual from 'lodash/isEqual';
import throttle from 'lodash/throttle';
import React, {
  type ReactNode,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
} from 'react';
import { shallowEqual } from 'react-redux';
import { useLatest } from 'react-use';
import { proxy } from 'valtio';
import { devtools } from 'valtio/utils';

import { type Logger } from '@lp-lib/logger-base';

import {
  getFeatureQueryParam,
  getFeatureQueryParamArray,
  getFeatureQueryParamNumber,
} from '../../hooks/useFeatureQueryParam';
import { useInstance } from '../../hooks/useInstance';
import { useIsController, useIsCoordinator } from '../../hooks/useMyInstance';
import { useStatsAwareTaskQueue } from '../../hooks/useTaskQueue';
import logger from '../../logger/logger';
import { ClientTypeUtils } from '../../types';
import { BrowserTimeoutCtrl } from '../../utils/BrowserTimeoutCtrl';
import {
  markSnapshottable,
  useSnapshot,
  type ValtioSnapshottable,
  ValtioUtils,
} from '../../utils/valtio';
import { useClock } from '../Clock';
import { type FirebaseService } from '../Firebase';
import { useIsLiveGamePlay, useOndGameState } from '../Game/hooks';
import { usePreGamePresented } from '../Game/PreGame/Provider';
import { useParticipantsGetter } from '../Player';
import { useIsStreamSessionAliveOrAborted } from '../Session';
import { useTeamRandomizerStepDetail } from '../TeamRandomizer';
import { useUserContextAPI, useUserStates } from '../UserContext';
import {
  useMyClientId,
  useMyClientType,
} from '../Venue/VenuePlaygroundProvider';
import { useVenueId } from '../Venue/VenueProvider';
import { useWebRTCMVMProcessor } from '../WebRTC';
import { TownhallSwitchMode } from './SwitchMode';
import {
  type LastSpokenAtMap,
  type TownhallConfig,
  type TownhallMode,
  type TownhallNext,
} from './types';

type State = {
  inited: boolean;
  config: TownhallConfig;
  next: Nullable<TownhallNext>;
  // track the last spoken timestamp in crowd mode
  crowdLastSpokenAtMap: Nullable<LastSpokenAtMap>;
  // track the last spoken timestamp when people move from off stage to on stage
  onsCrowdLastSpokenAtMap: Nullable<LastSpokenAtMap>;
};

function initialState(config?: Partial<TownhallConfig>): State {
  return {
    inited: false,
    config: {
      enabled: false,
      mode: 'crowd',
      debug: false,
      numOfcrowdSeats: 20,
      forceMode: 'disabled',
      streamStrategy: {
        video: {
          activeSpeakerThreshold: 5,
          forceCrowdFrames: 'disabled',
        },
        audio: {
          micEnabledThreshold: 45,
        },
      },
      ondAutoMuteThreshold: 45,
      ...config,
    },
    next: null,
    crowdLastSpokenAtMap: null,
    onsCrowdLastSpokenAtMap: null,
  };
}

type InitTownhalConfig = {
  enabled?: boolean;
  streamStrategy: TownhallConfig['streamStrategy'];
  ondAutoMuteThreshold?: number;
};

class TownhallAPI {
  constructor(
    venueId: string,
    private state: State,
    svc: FirebaseService,
    readonly log: Logger,
    readonly deps: {
      getParticipants: ReturnType<typeof useParticipantsGetter>;
      getForceModeFeatureFlag: () => 'auto' | TownhallMode;
    },
    private configRef = svc.prefixedSafeRef<TownhallConfig>(
      `townhall/${venueId}/config`
    ),
    private nextRef = svc.prefixedSafeRef<Nullable<TownhallNext>>(
      `townhall/${venueId}/next`
    ),
    private speakRef = svc.prefixedSafeRef<Nullable<LastSpokenAtMap>>(
      `townhall/${venueId}/last-spoken-ts`
    ),
    private onsSpeakRef = svc.prefixedSafeRef<Nullable<LastSpokenAtMap>>(
      `townhall/${venueId}/last-ons-spoken-ts`
    )
  ) {}

  async init() {
    const config = (await this.configRef.get()).val();
    if (config) {
      ValtioUtils.update(this.state.config, config);
    }
    this.state.next = (await this.nextRef.get()).val();
    this.state.crowdLastSpokenAtMap = (await this.speakRef.get()).val();
    this.configRef.on('value', (snapshot) => {
      const config = snapshot.val();
      if (config) ValtioUtils.update(this.state.config, config);
    });
    this.nextRef.on('value', (snapshot) => {
      this.state.next = snapshot.val();
    });

    const upsertLastSpokenAt = (
      key: 'crowdLastSpokenAtMap' | 'onsCrowdLastSpokenAtMap',
      clientId: string | null,
      speakAt: number | null
    ) => {
      if (clientId && speakAt) {
        const lsam = this.state[key] ?? {};
        lsam[clientId] = speakAt;
        this.state[key] = lsam;
      }
    };

    this.speakRef.on('child_added', (snapshot) =>
      upsertLastSpokenAt('crowdLastSpokenAtMap', snapshot.key, snapshot.val())
    );
    this.speakRef.on('child_changed', (snapshot) =>
      upsertLastSpokenAt('crowdLastSpokenAtMap', snapshot.key, snapshot.val())
    );
    this.speakRef.on('child_removed', (snapshot) => {
      if (!this.state.crowdLastSpokenAtMap || !snapshot.key) return;
      delete this.state.crowdLastSpokenAtMap[snapshot.key];
    });

    this.onsSpeakRef.on('child_added', (snapshot) =>
      upsertLastSpokenAt(
        'onsCrowdLastSpokenAtMap',
        snapshot.key,
        snapshot.val()
      )
    );
    this.onsSpeakRef.on('child_changed', (snapshot) =>
      upsertLastSpokenAt(
        'onsCrowdLastSpokenAtMap',
        snapshot.key,
        snapshot.val()
      )
    );
    this.onsSpeakRef.on('child_removed', (snapshot) => {
      if (!this.state.onsCrowdLastSpokenAtMap || !snapshot.key) return;
      delete this.state.onsCrowdLastSpokenAtMap[snapshot.key];
    });
    this.state.inited = true;
  }

  async configure(initConfig?: InitTownhalConfig) {
    const updates: Partial<TownhallConfig> = {};
    if (initConfig?.enabled !== undefined) {
      updates.enabled = initConfig.enabled;
    }
    const streamStrategy = initConfig?.streamStrategy;
    // only broadcast the customized configuration to Firebase
    if (
      !!streamStrategy &&
      !isEqual(streamStrategy, this.state.config.streamStrategy)
    ) {
      updates.streamStrategy = streamStrategy;
    }
    const ondAutoMuteThreshold = initConfig?.ondAutoMuteThreshold;
    if (
      ondAutoMuteThreshold !== undefined &&
      ondAutoMuteThreshold !== this.state.config.ondAutoMuteThreshold
    ) {
      updates.ondAutoMuteThreshold = ondAutoMuteThreshold;
    }
    if (Object.keys(updates).length > 0) {
      await this.configRef.update(updates);
    }
  }

  async setForceMode(mode: TownhallMode) {
    await this.configRef.update({ forceMode: mode });
  }

  async disableForceMode() {
    await this.configRef.update({ forceMode: 'disabled' });
  }

  async setEnabled(val: boolean) {
    await this.configRef.update({ enabled: val });
  }

  async setMode(mode: TownhallMode, source?: string) {
    this.log.info('set mode', { source });
    await this.configRef.update({ mode: mode });
  }

  async setDebug(debug: boolean) {
    await this.configRef.update({ debug });
  }

  async setNext(next: TownhallNext) {
    if (
      this.state.config.forceMode !== 'disabled' &&
      next.policy !== 'mandatory'
    ) {
      this.log.info('set next ignored due to forceMode', {
        next,
        forceMode: this.state.config.forceMode,
      });
      return;
    }
    this.log.info('set next', { next });
    await this.nextRef.set(next);
  }

  async clearNext() {
    await this.nextRef.remove();
  }

  async setLastSpokenAt(clientId: string, speakAt: EpochTimeStamp) {
    if (this.state.config.mode !== 'crowd') return;
    await this.speakRef.child(clientId).set(speakAt);

    const onsCrowdLastSpokenAtMap = this.state.onsCrowdLastSpokenAtMap ?? {};
    const participants = this.deps.getParticipants();
    const sorted = Object.entries(onsCrowdLastSpokenAtMap)
      .map(([clientId, lastSpokenAt]) => ({
        clientId,
        lastSpokenAt,
      }))
      .filter((p) => participants[p.clientId]?.status === 'connected')
      .sort((a, b) => b.lastSpokenAt - a.lastSpokenAt);
    const index = sorted.findIndex((p) => p.clientId === clientId);
    if (index === -1 || index > this.state.config.numOfcrowdSeats - 1) {
      this.log.info('setLastSpokenAtIfOffStage', { pos: index, speakAt });
      await this.onsSpeakRef.child(clientId).set(speakAt);
    }
  }

  async setNumOfCrowdSeats(num: number) {
    await this.configRef.update({ numOfcrowdSeats: num });
  }

  get mode() {
    return this.state.config.mode;
  }

  get next() {
    return this.state.next;
  }

  async reset() {
    const state = initialState();
    await Promise.all([
      this.configRef.set(state.config),
      this.nextRef.remove(),
    ]);
  }

  deinit(): void {
    this.configRef.off();
    this.nextRef.off();
    this.speakRef.off();
    this.onsSpeakRef.off();
    ValtioUtils.reset(this.state, initialState());
  }
}

type TownhallContext = {
  state: ValtioSnapshottable<State>;
  api: TownhallAPI;
};

const Context = React.createContext<TownhallContext | null>(null);

function useTownhallContext(): TownhallContext {
  const ctx = useContext(Context);
  if (!ctx) {
    throw new Error('TownhallContext is not in the tree!');
  }
  return ctx;
}

export function useTownhallConfig(): TownhallConfig {
  const ctx = useTownhallContext();
  return useSnapshot(ctx.state).config;
}

export function useTownhallEnabled(): boolean {
  return useTownhallConfig().enabled;
}

export function useTownhallInited(): boolean {
  const ctx = useTownhallContext();
  return useSnapshot(ctx.state).inited;
}

export function useTownhallCrowdLastSpokenAtMap(): Nullable<LastSpokenAtMap> {
  const ctx = useTownhallContext();
  return useSnapshot(ctx.state).crowdLastSpokenAtMap;
}

export function useTownhallOnsCrowdLastSpokenAtMap(): Nullable<LastSpokenAtMap> {
  const ctx = useTownhallContext();
  return useSnapshot(ctx.state).onsCrowdLastSpokenAtMap;
}

export function useSortedSpeakers(validClientIds: string[]): string[] {
  const crowdLastSpokenAtMap = useTownhallCrowdLastSpokenAtMap();
  const prev = useRef<string[]>([]);

  const next = Object.entries(crowdLastSpokenAtMap ?? {})
    .filter((p) => validClientIds.includes(p[0]))
    .sort((a, b) => b[1] - a[1])
    .map((p) => p[0]);

  if (!shallowEqual(prev.current, next)) {
    // TODO: not concurrent mode safe
    prev.current = next;
    return next;
  }

  return prev.current;
}

export function useTownhallNext() {
  const ctx = useTownhallContext();
  return useSnapshot(ctx.state).next as State['next'];
}

export function useTownhallAPI(): TownhallAPI {
  const ctx = useTownhallContext();
  return ctx.api;
}

function useTrackingMyLastSpokenAt(volumeThreshold = 30, throttleMs = 5000) {
  const processor = useWebRTCMVMProcessor();
  const clientId = useMyClientId();
  const clientType = useMyClientType();
  const api = useTownhallAPI();
  const clock = useClock();
  const { audio } = useUserStates();
  const latestAudio = useLatest(audio);
  const isHost = ClientTypeUtils.isHost(clientType);
  const useCtxAPI = useUserContextAPI();

  // If user umute, update last spoken at immediately so that he will not be
  // selected as candidate for auto mute again. This is a hack solution so we
  // don't need to introduce a new state to track the user's mute/unmute time.
  useLayoutEffect(() => {
    if (isHost) return;
    return useCtxAPI.on('audio-toggled', (prev, curr) => {
      if (prev === false && curr === true) {
        api.setLastSpokenAt(clientId, clock.now());
      }
    });
  }, [api, clientId, clock, isHost, useCtxAPI]);

  useLayoutEffect(() => {
    if (!processor || isHost) return;
    const throttledTick = throttle(() => {
      api.setLastSpokenAt(clientId, clock.now());
    }, throttleMs);
    return processor.on('volume-changed', (vol) => {
      if (!latestAudio.current || vol < volumeThreshold) return;
      throttledTick();
    });
  }, [
    api,
    clientId,
    clock,
    isHost,
    latestAudio,
    processor,
    throttleMs,
    volumeThreshold,
  ]);
}

export function useIsTownhallInited(): boolean {
  const ctx = useTownhallContext();
  return useSnapshot(ctx.state).inited;
}

export function useTownhallShowTeam(): boolean {
  const enabled = useTownhallEnabled();
  const detail = useTeamRandomizerStepDetail();
  const isStreamSessionAliveOrAborted = useIsStreamSessionAliveOrAborted();
  const ondState = useOndGameState();
  const preGamePresented = usePreGamePresented();
  const isLiveGamePlay = useIsLiveGamePlay();

  if (!enabled) return false;
  if (isStreamSessionAliveOrAborted) return true;

  // live game
  if (isLiveGamePlay) {
    return detail?.step === 'results';
  }

  // ond game
  return preGamePresented || ondState === 'preparing' || ondState === 'ended';
}

/**
 * This is used to update the "offical" townhall mode after next being consumed.
 * The "official" townhall mode is mostly used to initialize the participant.
 */
function useTownhallNextAck(bufferMs = 100) {
  const enabled = useTownhallEnabled();
  const api = useTownhallAPI();
  const next = useTownhallNext();
  const isController = useIsController();

  useEffect(() => {
    if (!enabled || next?.type !== 'global' || !isController) return;
    const ctrl = new BrowserTimeoutCtrl();
    ctrl.set(async () => {
      await api.setMode(next.mode, next.source);
      await api.clearNext();
    }, next.countdownSec * 1000 + bufferMs);
    return () => {
      ctrl.clear();
    };
  }, [api, bufferMs, enabled, isController, next]);
}

function Bootstrap(): JSX.Element | null {
  useTownhallNextAck();
  useTrackingMyLastSpokenAt();
  return null;
}

export function TownhallProvider(props: {
  ready: boolean;
  svc: FirebaseService;
  autoToggleEnabled?: boolean;
  children?: ReactNode;
}): JSX.Element {
  const { ready, svc, autoToggleEnabled } = props;
  const venueId = useVenueId();
  // townhall config is controlled by coordinator. Introduce localInitConfig,
  // so that when non-organizer come first, it can control it by themselves.
  const state = useInstance(() =>
    markSnapshottable(
      proxy<State>(initialState({ enabled: getFeatureQueryParam('townhall') }))
    )
  );
  const log = useInstance(() => logger.scoped('townhall'));
  const getParticipants = useParticipantsGetter();
  const api = useMemo(
    () =>
      new TownhallAPI(venueId, state, svc, log, {
        getParticipants,
        getForceModeFeatureFlag: () =>
          getFeatureQueryParamArray('townhall-force-mode'),
      }),
    [venueId, state, svc, log, getParticipants]
  );
  const { addTask } = useStatsAwareTaskQueue({
    shouldProcess: true,
    stats: 'task-queue-townhall-api-ms',
  });
  const isCoordinator = useIsCoordinator();

  useEffect(() => {
    if (!ready) return;
    addTask(async function init() {
      await api.init();
    });
    return () => {
      addTask(async function deinit() {
        api.deinit();
      });
    };
  }, [addTask, api, ready, state]);

  useEffect(() => {
    if (!isCoordinator) return;
    api.configure({
      streamStrategy: {
        video: {
          activeSpeakerThreshold: getFeatureQueryParamNumber(
            'townhall-video-strategy-active-speaker-threshold'
          ),
          forceCrowdFrames: getFeatureQueryParamArray(
            'townhall-video-strategy-force-crowd-frames'
          ),
        },
        audio: {
          micEnabledThreshold: getFeatureQueryParamNumber(
            'townhall-audio-strategy-mic-enabled-threshold'
          ),
        },
      },
      ondAutoMuteThreshold: getFeatureQueryParamNumber(
        'townhall-ond-auto-mute-threshold'
      ),
      enabled: !autoToggleEnabled
        ? getFeatureQueryParam('townhall')
        : undefined,
    });
  }, [api, autoToggleEnabled, isCoordinator]);

  useEffect(() => {
    const unsub = devtools(state, { name: 'Townhall Store' });
    return () => {
      unsub?.();
    };
  }, [state]);

  const ctx: TownhallContext = useMemo(
    () => ({
      state,
      api,
    }),
    [state, api]
  );

  return (
    <Context.Provider value={ctx}>
      {ready && <Bootstrap />}
      {ready && <TownhallSwitchMode />}
      {props.children}
    </Context.Provider>
  );
}
