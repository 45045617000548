import { useEffect } from 'react';
import { useLatest } from 'react-use';

import { type Media, MediaType } from '@lp-lib/media';

import { useInstance } from '../../../hooks/useInstance';
import logger from '../../../logger/logger';
import { xDomainifyUrl } from '../../../utils/common';
import { MediaPickPriorityHD, MediaUtils } from '../../../utils/media';
import { useLocalLoadedBlocks } from '../GamePlayStore';
import {
  useCurrentSessionMode,
  useGameSessionBlockId,
} from './gameSessionHooks';

const log = logger.scoped('block-video-preloader');

export function useAutoPreloadBlockVideos(enabled: boolean): void {
  const mode = useCurrentSessionMode();
  const blockId = useGameSessionBlockId();
  const blocks = useLatest(useLocalLoadedBlocks(mode));
  const loaded = useInstance(() => new Set<string>());
  useEffect(() => {
    if (!enabled) return;
    const nextBlockIdx = blockId
      ? blocks.current.findIndex((b) => b.id === blockId) + 1
      : 0;
    const nextBlock = blocks.current[nextBlockIdx];
    if (!nextBlock || loaded.has(nextBlock.id)) return;
    loaded.add(nextBlock.id);
    const mediaList: { name: string; val: Media }[] = [];
    for (const [fieldName, fieldValue] of Object.entries(nextBlock.fields)) {
      if (MediaUtils.IsMediaObject(fieldValue)) {
        mediaList.push({ name: fieldName, val: fieldValue });
      }
    }
    mediaList.forEach((m) => {
      if (m.val.type !== MediaType.Video) return;
      const mediaUrl = MediaUtils.PickMediaUrl(m.val, {
        priority: MediaPickPriorityHD,
      });
      if (!mediaUrl) return;
      log.info('preload block video', {
        blockId: nextBlock.id,
        name: m.name,
        url: mediaUrl,
      });
      const video = document.createElement('video');
      video.addEventListener('canplay', () => {
        log.debug('block video canplay', { url: mediaUrl });
      });
      video.addEventListener('canplaythrough', () => {
        log.debug('block video canplaythrough', { url: mediaUrl });
      });
      video.crossOrigin = 'anonymous';
      video.muted = true;
      video.loop = true;
      video.preload = 'auto';
      video.src = xDomainifyUrl(mediaUrl);
    });
  }, [blockId, blocks, enabled, loaded]);

  useEffect(() => {
    return () => loaded.clear();
  }, [loaded]);
}
