export const spotlightMaxPlayer = 8;

export enum SpotlightPreSelectedTeamOrder {
  None = 0,
  FirstPlaceTeam = 1,
  SecondPlaceTeam = 2,
  ThirdPlaceTeam = 3,
  LastPlaceTeam = -1,
  // intentionally leave some space for future options. this option is intended to describe a scenario where we want
  // a team on stage, but we'll let the "system" decide which team to bring on stage. how it makes that selection is
  // not relevant, other than we want to defer to the system to make that decision.
  SystemSelected = 201,
  Coordinator = -1000,
}
