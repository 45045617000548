export function TitleBlockIcon(
  props: React.SVGProps<SVGSVGElement>
): JSX.Element {
  return (
    <svg
      className={props.className ?? 'w-3.5 h-3.5 fill-current'}
      viewBox='0 0 14 14'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M12.5 12.5V3.88661C12.5 3.76222 12.4536 3.64231 12.37 3.55027L11.1094 2.16366C11.0147 2.05943 10.8803 2 10.7394 2H4C3.72386 2 3.5 2.22386 3.5 2.5V12.5C3.5 12.7761 3.72386 13 4 13H12C12.2761 13 12.5 12.7761 12.5 12.5ZM4 1.5C3.44772 1.5 3 1.94772 3 2.5V12.5C3 13.0523 3.44772 13.5 4 13.5H12C12.5523 13.5 13 13.0523 13 12.5V3.88661C13 3.63784 12.9073 3.39801 12.7399 3.21393L11.4794 1.82733C11.2899 1.61885 11.0212 1.5 10.7394 1.5H4Z'
        fill='#FBB707'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M2 0C1.44772 0 1 0.447714 1 0.999999V11.0714C1 11.6237 1.44771 12.0714 2 12.0714H10.2143C10.7666 12.0714 11.2143 11.6237 11.2143 11.0714V2.40289C11.2143 2.1515 11.1196 1.90933 10.9491 1.72461L9.65412 0.32172C9.46481 0.116638 9.19841 0 8.91931 0H2ZM3.78571 3.71429V4.93304H5.39246V9.28571H6.82183V4.93304H8.42857V3.71429H3.78571Z'
        fill='#FBB707'
      />
    </svg>
  );
}
