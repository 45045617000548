import useSWR from 'swr';

import { apiService } from '../../services/api-service';

export function useSiteI18nSettings() {
  return useSWR(['/site-settings/voice-over-locales'], async () => {
    const resp = await apiService.settings.getData('voice-over-locales');
    return resp.data.data.voiceOverLocales;
  });
}
