import {
  useCallback,
  useDebugValue,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import {
  assertExhaustive,
  type RoundRobinQuestionBlock,
  RoundRobinQuestionBlockGameSessionStatus,
} from '@lp-lib/game';

import { useMyInstance } from '../../../../hooks/useMyInstance';
import { type Participant } from '../../../../types';
import { useMusicPlayerContext } from '../../../MusicPlayer/Context';
import { useParticipantsByClientIds } from '../../../Player';
import { useParticipant } from '../../../Player';
import { useTeamMembers } from '../../../TeamAPI/TeamV1';
import { countdownV2, resetTimer, setTimer } from '../../store';
import {
  buildGamePlayMedia,
  type GamePlayMediaPlayerLayout,
} from '../Common/GamePlay/GamePlayMedia';
import { type GamePlayMedia } from '../Common/GamePlay/types';
import {
  useRoundRobinProgress,
  useRoundRobinQuestions,
} from './RoundRobinQuestionProvider';
import { type Question, QuestionStatus } from './types';
import { RoundRobinQuestionUtils } from './utils';

export type GamePlayLocalState = {
  gamePlayMedia: Nullable<GamePlayMedia, false>;
  mediaPlayerLayout: GamePlayMediaPlayerLayout;
  showResults: boolean;

  questions: Question[];
  candidates: Participant[];
  currentQuestion: Question | null;
  currentSubmitter: Participant | null;

  isTransition: boolean;
};

interface GamePlayLocalControlAPI {
  setShowBackgroundMedia: (next: boolean) => void;
  setShowResults: (next: boolean) => void;
  setIsTransition: (next: boolean) => void;
}

export function useGamePlayLocalControl(
  block: RoundRobinQuestionBlock
): [GamePlayLocalState, GamePlayLocalControlAPI] {
  const me = useMyInstance();
  const teamMembers = useTeamMembers(me?.teamId || '', true);

  const questions = useRoundRobinQuestions();
  const progress = useRoundRobinProgress();
  const [gamePlayMedia, setGamePlayMedia] =
    useState<Nullable<GamePlayMedia, false>>(null);
  const [mediaPlayerLayout, setMediaPlayerLayout] =
    useState<GamePlayMediaPlayerLayout>(undefined);
  const [showResults, setShowResults] = useState(false);
  const [isTransition, setIsTransition] = useState(false);

  const currentQuestion = progress ? questions[progress.questionIndex] : null;

  const currentSubmitter = useParticipant(progress?.submitterClientId);
  const orderedTeamMembers = useMemo(() => {
    return RoundRobinQuestionUtils.OrderMembers(teamMembers, {
      clientId: progress?.submitterClientId || '',
      joinedAt: progress?.submitterJoinedAt || 0,
    });
  }, [progress?.submitterClientId, progress?.submitterJoinedAt, teamMembers]);
  const candidates = useParticipantsByClientIds(
    orderedTeamMembers.map((m) => m.id)
  );

  const setShowBackgroundMedia = useCallback(
    (next: boolean) => {
      if (!next) {
        setGamePlayMedia(null);
        setMediaPlayerLayout(undefined);
        return;
      }

      if (!!gamePlayMedia) return;

      const media = buildGamePlayMedia(
        {
          media: RoundRobinQuestionUtils.GetBackgroundMedia(block),
          mediaData: block.fields.backgroundMediaData,
        },
        {
          stage: 'custom',
          isBackgroundMedia: true,
        }
      );
      if (!media) return;

      setGamePlayMedia(media);
      setMediaPlayerLayout('fullscreen');
    },
    [block, gamePlayMedia]
  );

  const state: GamePlayLocalState = {
    gamePlayMedia,
    mediaPlayerLayout,
    showResults,

    currentQuestion,
    currentSubmitter,
    questions,
    candidates,

    isTransition,
  };
  const control: GamePlayLocalControlAPI = {
    setShowBackgroundMedia,
    setShowResults,
    setIsTransition,
  };

  useDebugValue(state);

  return [state, control];
}

function useSyncMusicPlayer(
  block: RoundRobinQuestionBlock,
  gss: Nullable<RoundRobinQuestionBlockGameSessionStatus>
) {
  const { play, pause, isPlaying } = useMusicPlayerContext();

  const [resumable, setResumable] = useState(false);

  useEffect(() => {
    if (!block.fields.muteBackgroundMusic) return;
    if (!isPlaying) return;
    if (!gss) return;
    if (
      gss < RoundRobinQuestionBlockGameSessionStatus.GAME_INIT ||
      gss >= RoundRobinQuestionBlockGameSessionStatus.END
    )
      return;

    pause('round robin block pause');
    setResumable(true);
  }, [isPlaying, pause, play, block.fields.muteBackgroundMusic, gss]);

  useEffect(() => {
    if (!block.fields.muteBackgroundMusic) return;
    if (gss !== RoundRobinQuestionBlockGameSessionStatus.END) return;
    if (!resumable) return;

    play('round robin block resume');
  }, [block.fields.muteBackgroundMusic, gss, play, resumable]);
}

export function GamePlayLocalControl(props: {
  isHost: boolean;
  isLive: boolean;
  block: RoundRobinQuestionBlock;
  gameSessionStatus: RoundRobinQuestionBlockGameSessionStatus;
  state: GamePlayLocalState;
  control: GamePlayLocalControlAPI;
}): JSX.Element | null {
  const {
    isHost,
    isLive,
    block,
    gameSessionStatus,
    state: { currentQuestion, gamePlayMedia, questions },
    control: { setShowBackgroundMedia, setShowResults, setIsTransition },
  } = props;

  useSyncMusicPlayer(block, gameSessionStatus);

  const lastRunGameSessionStatus =
    useRef<RoundRobinQuestionBlockGameSessionStatus | null>(null);
  useEffect(() => {
    if (gameSessionStatus === lastRunGameSessionStatus.current) return;
    lastRunGameSessionStatus.current = gameSessionStatus;

    switch (gameSessionStatus) {
      case RoundRobinQuestionBlockGameSessionStatus.LOADED:
        setShowBackgroundMedia(false);
        resetTimer('submission');
        setShowResults(false);
        return;
      case RoundRobinQuestionBlockGameSessionStatus.GAME_INIT:
        setShowBackgroundMedia(true);
        // make sure the timer is not zero, so the _gameSessionLocalTimer_
        // can be ticked
        setTimer('submission', RoundRobinQuestionUtils.GameTimeSec(block));
        return;
      case RoundRobinQuestionBlockGameSessionStatus.GAME_START:
        setShowBackgroundMedia(true);
        countdownV2({
          debug: 'RoundRobinQuestionBlockGamePlay',
          startTimeWorker: isHost ? !isLive : true,
          flushCountingStatus: false,
        });
        return;
      case RoundRobinQuestionBlockGameSessionStatus.GAME_END:
        setShowBackgroundMedia(true);
        resetTimer('submission');
        return;
      case RoundRobinQuestionBlockGameSessionStatus.RESULTS:
        setShowBackgroundMedia(true);
        setShowResults(true);
        return;
      case RoundRobinQuestionBlockGameSessionStatus.SCOREBOARD:
        setShowBackgroundMedia(false);
        setShowResults(false);
        return;
      case RoundRobinQuestionBlockGameSessionStatus.END:
        setShowBackgroundMedia(false);
        setShowResults(false);
        return;
      default:
        assertExhaustive(gameSessionStatus);
    }
  }, [
    block,
    gamePlayMedia,
    gameSessionStatus,
    isHost,
    isLive,
    setShowBackgroundMedia,
    setShowResults,
  ]);

  useEffect(() => {
    if (currentQuestion?.status !== QuestionStatus.Ended) return;
    if (currentQuestion.index === questions.length - 1) return;

    setIsTransition(true);
    return () => {
      setIsTransition(false);
    };
  }, [
    currentQuestion?.index,
    currentQuestion?.status,
    questions.length,
    setIsTransition,
  ]);

  return null;
}
