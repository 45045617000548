import {
  type AnalyticsBrowser,
  type EventProperties,
} from '@segment/analytics-next';
import { useLayoutEffect, useMemo, useRef, useState } from 'react';
import useSWRImmutable from 'swr/immutable';
import { match } from 'ts-pattern';

import {
  type DtoBrand,
  EnumsBlockDownVoteReason,
  EnumsBlockVoteValue,
  EnumsGamePackMakeup,
  EnumsGamePackVersion,
} from '@lp-lib/api-service-client/public';
import { type Block } from '@lp-lib/game';

import {
  useAnalytics,
  useReadAnalyticsGlobalStore,
} from '../../../../../analytics/AnalyticsContext';
import { useLiveAsyncCall } from '../../../../../hooks/useAsyncCall';
import { useFeatureQueryParam } from '../../../../../hooks/useFeatureQueryParam';
import { useLiveCallback } from '../../../../../hooks/useLiveCallback';
import { apiService } from '../../../../../services/api-service';
import { type GamePack } from '../../../../../types/game';
import { BrowserTimeoutCtrl } from '../../../../../utils/BrowserTimeoutCtrl';
import { assertExhaustive } from '../../../../../utils/common';
import { EnterExitTailwindTransition } from '../../../../common/TailwindTransition';
import { FloatDiv } from '../../../../Layout/FloatLayout';
import { useLayoutAnchorRectValue } from '../../../../LayoutAnchors/LayoutAnchors';
import { Loading } from '../../../../Loading';
import {
  useAmICohost,
  useCohostGetter,
  useNumOfParticipants,
} from '../../../../Player';
import { useStreamSessionId } from '../../../../Session';
import {
  useFetchGameSessionGamePack,
  useGameSessionBlock,
  useIsLiveGamePlay,
  useOndWaitModeInfo,
} from '../../../hooks';
import { BlockKnifeUtils } from '../../Shared';
import { BlockVoteUtils } from '../BlockUtils';

class BlockVoteAnalytics {
  constructor(readonly analytics: AnalyticsBrowser) {}

  trackBlockVoted(properties?: EventProperties) {
    this.analytics.track('Block Voted', properties);
  }
}

function useVoteTitle(
  pack: GamePack,
  brand?: DtoBrand | null,
  defaultTitle = 'Did you enjoy that round?'
) {
  switch (pack.version) {
    case EnumsGamePackVersion.GamePackVersionV1:
      return defaultTitle;
    case EnumsGamePackVersion.GamePackVersionV2:
      const gameMakeup = pack.playbackSettings?.gameMakeup;
      switch (gameMakeup) {
        case EnumsGamePackMakeup.GamePackMakeupMultipleLevels:
          return brand
            ? `Did you enjoy that level of ${brand.name}?`
            : defaultTitle;
        case EnumsGamePackMakeup.GamePackMakeupMultipleRounds:
          return brand
            ? `Did you enjoy that round of ${brand.name}?`
            : defaultTitle;
        case EnumsGamePackMakeup.GamePackMakeupOneBigGame:
        case undefined:
        case null:
          return defaultTitle;
        default:
          assertExhaustive(gameMakeup);
          return defaultTitle;
      }
    default:
      assertExhaustive(pack.version);
      return defaultTitle;
  }
}

function useAddBlockVote(blockId: string, sessionId: string) {
  return useLiveAsyncCall(
    async (
      value: EnumsBlockVoteValue,
      reason?: EnumsBlockDownVoteReason,
      customReason?: string
    ) => {
      return await apiService.block.addBlockVote(blockId, {
        sessionId,
        value,
        reason,
        customReason,
      });
    }
  );
}

function VoteStage(props: {
  pack: GamePack;
  block: Block;
  brand: Nullable<DtoBrand>;
  sessionId: string;
  analytics: BlockVoteAnalytics;
  onComplete: (val: EnumsBlockVoteValue) => void;
}) {
  const { block, pack, brand, sessionId } = props;
  const title = useVoteTitle(pack, brand);

  const {
    call,
    state: {
      state: { isRunning },
    },
  } = useAddBlockVote(block.id, sessionId);

  const handleVote = async (val: EnumsBlockVoteValue) => {
    const resp = await call(val);
    if (!resp) return;
    props.onComplete(val);
  };

  return (
    <div className='h-full flex items-center justify-between gap-3'>
      <p className='text-white text-sms font-medium line-clamp-2'>{title}</p>
      {isRunning ? (
        <div className='flex items-center justify-center w-26'>
          <Loading text='' />
        </div>
      ) : (
        <div className='flex items-center justify-center gap-1'>
          <button
            type='button'
            className='btn w-12.5 h-12.5 border border-secondary bg-dark-gray rounded-xl text-2xl font-bold'
            onClick={() => handleVote(EnumsBlockVoteValue.BlockVoteValueDOWN)}
          >
            👎
          </button>
          <button
            type='button'
            className='btn w-12.5 h-12.5 border border-secondary bg-dark-gray rounded-xl text-2xl font-bold'
            onClick={() => handleVote(EnumsBlockVoteValue.BlockVoteValueUP)}
          >
            👍
          </button>
        </div>
      )}
    </div>
  );
}

function VotedStage() {
  return (
    <div className='h-full flex items-center justify-center gap-2 text-white'>
      <span className='text-3.5xl font-medium animate-wave'>👋</span>
      <span className='text-sms font-medium'>Thank you for your feedback!</span>
    </div>
  );
}

function ChooseReasonStage(props: {
  blockId: string;
  sessionId: string;
  value: EnumsBlockVoteValue;
  onComplete: (reason: EnumsBlockDownVoteReason) => void;
}) {
  const { blockId, sessionId, value } = props;
  const {
    call,
    state: {
      state: { isRunning },
    },
  } = useAddBlockVote(blockId, sessionId);

  const handleChooseReason = async (reason: EnumsBlockDownVoteReason) => {
    const resp = await call(value, reason);
    if (!resp) return;
    props.onComplete(reason);
  };
  return (
    <div className='w-full h-full flex items-center justify-center text-white gap-2'>
      <p className='text-sms font-medium'>What happened?</p>
      <div className='flex-grow flex items-center justify-center gap-1'>
        {BlockVoteUtils.GetOptions().map((option) => (
          <button
            key={option.value}
            type='button'
            className='btn w-1/4 h-10 border border-secondary bg-dark-gray rounded-xl text-xs lg:text-sm font-bold'
            onClick={() => handleChooseReason(option.value)}
            disabled={isRunning}
          >
            {`${option.emoji} ${option.label}`}
          </button>
        ))}
      </div>
      {isRunning && (
        <div className='absolute'>
          <Loading text='' />
        </div>
      )}
    </div>
  );
}

function CustomReasonStage(props: {
  blockId: string;
  sessionId: string;
  value: EnumsBlockVoteValue;
  onComplete: (reason: EnumsBlockDownVoteReason, customReason?: string) => void;
}) {
  const { blockId, sessionId, value } = props;
  const [response, setResponse] = useState<string>('');
  const {
    call,
    state: {
      state: { isRunning },
    },
  } = useAddBlockVote(blockId, sessionId);

  const reason = EnumsBlockDownVoteReason.BlockDownVoteReasonCustom;

  const handleSubmit = async () => {
    const resp = await call(value, reason, response);
    if (!resp) return;
    props.onComplete(reason, response);
  };
  return (
    <form
      className='w-full'
      onSubmit={(e) => {
        e.preventDefault();
        handleSubmit();
      }}
    >
      <div className='w-full h-full flex items-center justify-center text-white gap-2'>
        <p className='text-sms font-medium px-1'>Thanks - What went wrong?</p>
        <div className='flex-grow flex items-center justify-center'>
          <input
            className='w-full h-10 field mb-0'
            placeholder='Enter your response here'
            max={500}
            value={response}
            onChange={(e) => setResponse(e.target.value)}
          />
        </div>
        <button
          type='submit'
          className='btn-primary w-25 h-10 flex items-center justify-center'
          disabled={isRunning || response.trim().length === 0}
        >
          {isRunning && <Loading text='' containerClassName='mr-1' />}
          <div>Send</div>
        </button>
      </div>
    </form>
  );
}

function BlockVoteWidgetInternal(props: {
  pack: GamePack;
  block: Block;
  sessionId: string;
  analytics: BlockVoteAnalytics;
}) {
  const { pack, block, sessionId, analytics } = props;
  const blockId = block.id;
  const brandId = block.brandId;
  const { data: vote, isLoading: isVoteLoading } = useSWRImmutable(
    `/blocks/${blockId}/vote`,
    async () => {
      const resp = await apiService.block.getBlockVote(blockId);
      return resp.data.vote;
    }
  );
  const { data: brand, isLoading: isBrandLoading } = useSWRImmutable(
    brandId ? `/brands/${brandId}` : null,
    async () => {
      if (!brandId) return null;
      const resp = await apiService.brand.getPublic(brandId);
      return resp.data.brand;
    }
  );
  const isLoading = isVoteLoading || isBrandLoading;
  const revotable = useFeatureQueryParam('block-revotable');
  const outerRef = useRef<HTMLDivElement | null>(null);
  const [state, setState] = useState<'init' | 'follow-up' | 'done' | 'closed'>(
    'init'
  );
  const marginBottom =
    useLayoutAnchorRectValue('emojis-expanded-board-anchor', 'height') ?? 0;

  const hasVoteHistory = revotable ? false : !!vote;
  const customReasonEnabled = useFeatureQueryParam('block-vote-custom-reason');

  const votedValue = useRef<EnumsBlockVoteValue | null>(null);
  const eventTracked = useRef<boolean>(false);
  const playersCount = useNumOfParticipants({
    filters: ['host:false', 'cohost:false', 'status:connected', 'staff:false'],
  });
  const readStore = useReadAnalyticsGlobalStore();
  const getCohost = useCohostGetter();

  const trackBlockVoted = useLiveCallback(
    (reason?: EnumsBlockDownVoteReason, customReason?: string) => {
      if (!votedValue.current || eventTracked.current) return;
      const cohost = getCohost();
      const cohostUid = cohost?.id ?? null;
      const cohostName = !cohost
        ? null
        : cohost.firstName
        ? `${cohost.firstName} ${cohost.lastName}`.trim()
        : cohost.username;

      analytics.trackBlockVoted({
        blockId: block.id,
        blockType: block.type,
        sessionId,
        packId: pack.id,
        packName: pack.name,
        packVersion: pack.version,
        gameType: pack.detailSettings?.gameType,
        tagIds: pack.tags?.map((t) => t.id),
        tags: pack.tags?.map((t) => t.name),
        brandId: brand?.id,
        brandName: brand?.name,
        value:
          votedValue.current === EnumsBlockVoteValue.BlockVoteValueUP
            ? 'up'
            : 'down',
        reason:
          customReason ??
          BlockVoteUtils.GetOptions().find((r) => r.value === reason)?.label,
        maxPlayers: playersCount,
        platform: readStore('platform'),
        cohostUid,
        cohostName,
      });
      eventTracked.current = true;
    }
  );

  // if user doesn't select the reason, we track the event before unmouted.
  useLayoutEffect(() => {
    return () => trackBlockVoted();
  }, [trackBlockVoted]);

  if (isLoading || hasVoteHistory || state === 'closed') return null;

  const initialClasses = 'translate-y-20 opacity-0';
  const enteredClasses = 'translate-y-0 opacity-100';

  const onVotedDone = () => {
    setState('done');
    const ctrl = new BrowserTimeoutCtrl();
    ctrl.set(() => {
      outerRef.current?.classList.remove(...enteredClasses.split(' '));
      outerRef.current?.classList.add(...initialClasses.split(' '));
      ctrl.set(() => {
        setState('closed');
      }, 1500);
    }, 1500);
  };

  const onVoted = (val: EnumsBlockVoteValue) => {
    votedValue.current = val;
    if (val === EnumsBlockVoteValue.BlockVoteValueUP) {
      onVotedDone();
      trackBlockVoted();
    } else {
      setState('follow-up');
    }
  };

  const onReasonSubmitted = (
    reason: EnumsBlockDownVoteReason,
    customReason?: string
  ) => {
    trackBlockVoted(reason, customReason);
    onVotedDone();
  };

  const FollowUpStage = customReasonEnabled
    ? CustomReasonStage
    : ChooseReasonStage;

  return (
    <FloatDiv className='bottom-[0px]'>
      <EnterExitTailwindTransition
        initialClasses={initialClasses}
        enteredClasses={enteredClasses}
      >
        {(innerRef, initial) => (
          <div
            ref={(node) => {
              innerRef.current = node;
              outerRef.current = node;
            }}
            className={`w-full min-w-170 max-w-220 h-17.5 flex items-center justify-center flex-shrink-0 
      bg-layer-002 rounded-lg absolute bottom-3 left-1/2 transform-gpu 
      -translate-x-1/2 px-4 transition-all ${initial}`}
            style={{
              boxShadow: '0px 4px 6px 0px rgba(0, 0, 0, 0.25)',
              marginBottom,
            }}
          >
            {match(state)
              .with('init', () => (
                <VoteStage {...props} brand={brand} onComplete={onVoted} />
              ))
              .with('follow-up', () =>
                votedValue.current ? (
                  <FollowUpStage
                    blockId={blockId}
                    sessionId={sessionId}
                    value={votedValue.current}
                    onComplete={onReasonSubmitted}
                  />
                ) : null
              )
              .with('done', () => <VotedStage />)
              .exhaustive()}
          </div>
        )}
      </EnterExitTailwindTransition>
    </FloatDiv>
  );
}

export function BlockVoteWidget() {
  const isOndGame = !useIsLiveGamePlay();
  const block = useGameSessionBlock();
  const pack = useFetchGameSessionGamePack();
  const sessionId = useStreamSessionId();
  const { readyForSkip, lastWaitBeforeEnd } = useOndWaitModeInfo();
  const v1Enabled = useFeatureQueryParam('block-vote-gpv1');
  const blockVotable = useFeatureQueryParam('block-vote')
    ? block
      ? BlockKnifeUtils.Votable(block)
      : false
    : false;
  const gamePackVotable = !pack
    ? false
    : pack.version === EnumsGamePackVersion.GamePackVersionV2
    ? true
    : v1Enabled;
  const analytics = useAnalytics();
  const blockVoteAnalytics = useMemo(
    () => new BlockVoteAnalytics(analytics),
    [analytics]
  );
  const amICohost = useAmICohost();

  if (
    !isOndGame ||
    !pack ||
    !block ||
    !sessionId ||
    !readyForSkip ||
    !lastWaitBeforeEnd ||
    !blockVotable ||
    !gamePackVotable ||
    amICohost
  ) {
    return null;
  }

  return (
    <BlockVoteWidgetInternal
      pack={pack}
      block={block}
      sessionId={sessionId}
      analytics={blockVoteAnalytics}
    />
  );
}
