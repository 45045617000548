import { useLocation, useNavigate } from '@remix-run/react';
import { useCallback, useEffect, useState } from 'react';
import { match, P } from 'ts-pattern';

import { type Block } from '@lp-lib/game';

import {
  type GamePlayStore,
  useLocalGamePlayStore,
} from '../components/Game/GamePlayStore';
import {
  useIsGameSessionInited,
  useIsLiveGamePlay,
} from '../components/Game/hooks';
import {
  usePlaybackInfoEmpty,
  usePlaybackInfoWrite,
} from '../components/Game/Playback/PlaybackInfoProvider';
import {
  loadGameSession,
  reset,
  switchPlayMode,
} from '../components/Game/store';
import { useUserStates } from '../components/UserContext';
import { useVenueSlug } from '../components/Venue/VenueProvider';
import { ValtioUtils } from '../utils/valtio';
import { useLiveCallback } from './useLiveCallback';
import { useQueryParam, useSetQueryParam } from './useQueryParam';

const FEATURED_GAME_KEY = 'featured-game';

export const useQueryParamGamePackId = (): [
  string | null,
  (val: string | null) => void
] => {
  const featuredGamePackId = useQueryParam(FEATURED_GAME_KEY);
  const setQueryParam = useSetQueryParam();

  const updateFeaturedGamePackId = useCallback(
    (next: string | null) => {
      setQueryParam(FEATURED_GAME_KEY, next, {
        replace: true,
      });
    },
    [setQueryParam]
  );

  return [featuredGamePackId, updateFeaturedGamePackId];
};

export interface AutoloadGameLocationState {
  gameLike?: { id: string; type: 'game' | 'gamePack' };
  blockId?: Block['id'];
}

export function makeAutoloadGameLocationState(
  state: AutoloadGameLocationState
): AutoloadGameLocationState {
  return state;
}

export function isAutoloadGameLocationState(
  state: unknown
): state is AutoloadGameLocationState {
  return (
    typeof state === 'object' &&
    state !== null &&
    ('gameLike' in state || 'blockId' in state)
  );
}

export function useMakeHostViewAutoloadHandler(
  gameLike: { id: string; type: 'game' | 'gamePack' } | null | undefined
) {
  const venueSlug = useVenueSlug();
  const navigate = useNavigate();

  const handleClick = () => {
    if (!gameLike) return;
    navigate(`/host/venue/${venueSlug}`, {
      state: makeAutoloadGameLocationState({
        gameLike: ValtioUtils.detachCopy(gameLike),
      }),
    });
  };

  return handleClick;
}

export const useLoadGame = () => {
  const store = useLocalGamePlayStore();
  const isLive = useIsLiveGamePlay();
  const [, updateQueryParamGamePackId] = useQueryParamGamePackId();
  const pbWrite = usePlaybackInfoWrite();
  const pbEmpty = usePlaybackInfoEmpty();
  return useLiveCallback(
    // NOTE(drew): this takes on the same signature as `store.load` to avoid
    // needing extra logic for the playback argument.
    async (...[command]: Parameters<GamePlayStore['load']>): Promise<void> => {
      if (store.hasLoadedGameLike()) {
        store.unload();
        await pbEmpty();
        await reset({ retainPreloadBlock: false });
      }

      await store.load(command);
      await loadGameSession();

      // Share the resolved playback with other clients/the venue.
      await pbWrite(store);

      // NOTE(drew): we only care about the gamepackid here, so this is a
      // simplified/duplicated version of some of the matching from within
      // `store.load()`.
      const gamePackId = match(command)
        .with({ pack: P.string }, (c) => c.pack)
        .with({ pack: { id: P.string } }, (c) => c.pack.id)
        .with({ type: 'gamePack' }, (c) => c.id)
        .otherwise(() => null);

      // Note(guoqiang): it's only used for organizer based OnD game, but no
      // side effects for host based OnD game.
      if (!isLive && gamePackId) {
        updateQueryParamGamePackId(gamePackId);
      }
    }
  );
};

export function useAutoloadLastGame(): void {
  const isGameSessionInited = useIsGameSessionInited();
  const [isLoaded, setIsLoaded] = useState(false);
  const store = useLocalGamePlayStore();
  const loadGame = useLoadGame();
  const { joined } = useUserStates();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    async function run() {
      if (!joined || isLoaded || !isGameSessionInited) return;

      if (
        isAutoloadGameLocationState(location.state) &&
        location.state.gameLike
      ) {
        const { gameLike, ...rest } = location.state;
        // Do not switch PlayMode until game is loaded, because `loadGame`
        // implicitely destroys previous game state, such as OnD, via `reset`
        // and `unloadGame`. If PlayMode is switched during or before that
        // process, the venue is possibly allowed to be in Ond+Live mode: an Ond
        // session exists and could be resumed, but since "Live" is true the
        // Stream Controls are shown and also functional.)
        if (gameLike.type === 'game') {
          await loadGame({ game: gameLike.id });
        } else {
          await loadGame({ pack: gameLike.id });
        }
        await switchPlayMode(true);
        navigate(location.pathname, { state: rest, replace: true });
        setIsLoaded(true);
      } else {
        await store.autoloadLastGameLike();
        setIsLoaded(true);
      }
    }

    run();
  }, [
    store,
    location,
    loadGame,
    joined,
    navigate,
    isLoaded,
    isGameSessionInited,
  ]);
}
