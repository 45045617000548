import { isValid, parseISO } from 'date-fns';

export type EventTime = {
  startDate: Date;
  timezone: string;
};

export class DateUtils {
  static FormattedMMDDYYYY(
    value: string | number,
    options?: Intl.DateTimeFormatOptions
  ): string {
    return new Date(value).toLocaleDateString('en-US', {
      month: '2-digit',
      day: '2-digit',
      year: 'numeric',
      ...options,
    });
  }

  static FormatTimezone(tz: string): string {
    const short = Intl.DateTimeFormat('en-EN', {
      timeZone: tz,
      timeZoneName: 'short',
    });
    const long = Intl.DateTimeFormat('en-EN', {
      timeZone: tz,
      timeZoneName: 'longGeneric',
    });
    const now = Date.now();
    return `${long.format(now).split(',')[1]} (${tz.split('/')[1]}) (${
      short.format(now).split(',')[1]
    })`;
  }

  static FormatDatetime(datetime: string, timeZone?: string): string {
    const date = new Date(datetime);
    const dateFormatted = new Intl.DateTimeFormat('en-US', {
      timeZone,
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    }).format(date);
    const timeFormatted = new Intl.DateTimeFormat('en-US', {
      timeZone,
      timeStyle: 'short',
    }).format(date);
    return `${dateFormatted} at ${timeFormatted}`;
  }

  static ParseEventTime(
    startDate: Nullable<string>,
    timezone: Nullable<string>
  ): EventTime | null {
    if (!startDate) return null;
    const maybeParsed = parseISO(startDate);
    if (isValid(maybeParsed)) {
      return {
        startDate: maybeParsed,
        timezone: timezone ?? Intl.DateTimeFormat().resolvedOptions().timeZone,
      };
    } else {
      return null;
    }
  }
}
