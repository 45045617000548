import sample from 'lodash/sample';
import shuffle from 'lodash/shuffle';
import React, {
  type ReactNode,
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useLatest, usePrevious } from 'react-use';
import { proxy, useSnapshot } from 'valtio';

import { EnumsHiddenPicturePenaltyResetStrategy } from '@lp-lib/api-service-client/public';
import { RTDBServerValueTIMESTAMP } from '@lp-lib/firebase-typesafe';
import {
  type HiddenPicture,
  type HiddenPictureBlock,
  type HiddenPictureBlockDetailScore,
  type HotSpotV2,
} from '@lp-lib/game';

import { useIsController } from '../../../../hooks/useMyInstance';
import { type TeamId } from '../../../../types';
import { uuidv4 } from '../../../../utils/common';
import { uncheckedIndexAccess_UNSAFE } from '../../../../utils/uncheckedIndexAccess_UNSAFE';
import {
  useDatabaseSafeRef,
  useFirebaseBatchWrite,
  useFirebaseContext,
  useFirebaseValue,
} from '../../../Firebase';
import { useMyTeamId } from '../../../Player';
import { useIsStreamSessionAlive } from '../../../Session';
import {
  useTeamCaptainLookup,
  useTeamMembers,
  useTeams,
} from '../../../TeamAPI/TeamV1';
import { useVenueId } from '../../../Venue/VenueProvider';
import { useGameSessionBlockId, useGameSessionLocalTimer } from '../../hooks';
import { updateBlockDetailScore } from '../../store';
import {
  type GamePlayEndedEventFinishOptions,
  useGamePlayEmitter,
} from '../Common/GamePlay/GamePlayProvider';
import { type GamePlayEndedState } from '../Common/GamePlay/types';
import {
  type AllTeamsProgressData,
  type FoundHotSpots,
  type Game,
  GameState,
  type Pin,
  type Pins,
  type Signal,
  type Signals,
  type TeamProgressData,
  type ToolAssignment,
  type Tools,
} from './types';
import { HiddenPictureUtils, log } from './utils';

interface HiddenPictureGameControlAPI {
  initGame: (block: HiddenPictureBlock) => Promise<void>;
  startGame: () => Promise<void>;
  stopGame: () => Promise<void>;
  resetGame: (debug: string) => Promise<void>;
  deinitGame: () => Promise<void>;
}

export interface HiddenPictureGamePlayAPI {
  dropPin: (x: number, y: number, clientName: string) => Promise<void>;
  dropSignal: (x: number, y: number) => Promise<void>;
  updateToolPosition: (
    tool: HiddenPicture['tool'],
    point: { x: number; y: number } | null
  ) => Promise<void>;
}

type Context = {
  game: Nullable<Game>;
  updateGame: (next: Nullable<Partial<Game>, false>) => Promise<void>;
  pins: Pins;
  signals: Signals;
  toolAssignment: ToolAssignment | null;
  updateToolAssignment: (
    toolAssignment: Nullable<ToolAssignment, false>
  ) => Promise<void>;
  tools: Tools;
  progress: TeamProgressData | null;
};

const context = React.createContext<Nullable<Context, false>>(null);

function useHiddenPictureContextProxy(): Context {
  const ctx = useContext(context);
  if (!ctx) throw new Error('HiddenPictureContext is not in the tree!');
  return ctx;
}

function useHiddenPictureContext(): Context {
  const proxy = useHiddenPictureContextProxy();
  return useSnapshot(proxy) as Context;
}

function useInitGame(options?: { enabled: boolean; readonly: boolean }) {
  const venueId = useVenueId();
  const blockId = useGameSessionBlockId() ?? '';
  return useFirebaseValue<Nullable<Game, false>>(
    HiddenPictureUtils.GamePath(venueId, blockId),
    {
      enabled: !!options?.enabled,
      seedValue: null,
      seedEnabled: false,
      readOnly: !!options?.readonly,
      resetWhenUmount: true,
    }
  );
}

export function useHiddenPictureGame(): Context['game'] {
  return useHiddenPictureContext().game;
}

// shared list of pictures for all teams.
export function useHiddenPictureGamePictures(): HiddenPicture[] {
  return useHiddenPictureGame()?.pictures ?? [];
}

export function useHiddenPicturePenaltyResetStrategy(): EnumsHiddenPicturePenaltyResetStrategy {
  return (
    useHiddenPictureGame()?.penaltyResetStrategy ??
    EnumsHiddenPicturePenaltyResetStrategy.HiddenPicturePenaltyResetStrategyNever
  );
}

export function useHiddenPictureCurrentPicture(): HiddenPicture | null {
  const allPictures = useHiddenPictureGame()?.pictures ?? [];
  const currentPictureIndex =
    useHiddenPictureContext().progress?.currentPictureIndex;

  return currentPictureIndex === null ||
    currentPictureIndex === undefined ||
    currentPictureIndex < 0 ||
    currentPictureIndex >= allPictures.length
    ? null
    : allPictures[currentPictureIndex];
}

export function useHiddenPictureNextPicture(): HiddenPicture | null {
  const allPictures = useHiddenPictureGame()?.pictures ?? [];
  const currentPictureIndex =
    useHiddenPictureContext().progress?.currentPictureIndex;

  return currentPictureIndex === null ||
    currentPictureIndex === undefined ||
    currentPictureIndex < 0 ||
    currentPictureIndex + 1 >= allPictures.length
    ? null
    : allPictures[currentPictureIndex + 1];
}

export function useHiddenPictureFoundHotSpots(): FoundHotSpots {
  const currentHotSpots = useHiddenPictureCurrentHotSpots();
  const found = useHiddenPictureContext().progress?.found;

  return useMemo(() => {
    if (!found) return {};

    // filter the found object to contain only hotspots from currentHotSpots
    const foundHotSpots = uncheckedIndexAccess_UNSAFE({});
    for (const hotSpot of currentHotSpots) {
      if (hotSpot.id in found) {
        foundHotSpots[hotSpot.id] = found[hotSpot.id];
      }
    }
    return foundHotSpots;
  }, [currentHotSpots, found]);
}

export function useHiddenPictureScore(): number {
  return useHiddenPictureContext().progress?.score ?? 0;
}

export function useHiddenPictureCurrentHotSpots(): HotSpotV2[] {
  return useHiddenPictureCurrentPicture()?.hotSpotsV2 ?? [];
}

export function useHiddenPictureCurrentSequenced(): boolean {
  return useHiddenPictureCurrentPicture()?.sequenced ?? false;
}

export function useHiddenPictureCurrentIncorrectAnswerPenalty(): number {
  return useHiddenPictureCurrentPicture()?.incorrectAnswerPenalty ?? 0;
}

export function useHiddenPictureCurrentEveryoneClicks(): boolean {
  return useHiddenPictureCurrentPicture()?.everyoneClicks ?? false;
}

export function useHiddenPictureCurrentTool(): HiddenPicture['tool'] {
  return useHiddenPictureCurrentPicture()?.tool ?? 'none';
}

export function useHiddenPicturePins(): Pins {
  return useHiddenPictureContext().pins;
}

export function useHiddenPictureSignals(): Signals {
  return useHiddenPictureContext().signals;
}

export function useHiddenPictureToolAssignment(): ToolAssignment | null {
  return useHiddenPictureContext().toolAssignment;
}

export function useHiddenPictureTools(): Tools {
  return useHiddenPictureContext().tools;
}

export function useHiddenPictureGamePlayAPI(
  clientId: string,
  teamId: TeamId
): HiddenPictureGamePlayAPI {
  const venueId = useVenueId();
  const firebaseContext = useFirebaseContext();
  const hotSpots = useLatest(useHiddenPictureCurrentHotSpots());
  const incorrectAnswerPenalty = useLatest(
    useHiddenPictureCurrentIncorrectAnswerPenalty()
  );
  const sequenced = useLatest(useHiddenPictureCurrentSequenced());
  const blockId = useGameSessionBlockId() ?? '';

  const dropPin = useCallback(
    async (x: number, y: number, clientName: string) => {
      const pinsRef = firebaseContext.svc.prefixedSafeRef<Pins>(
        HiddenPictureUtils.TeamGamePlayPath(venueId, blockId, teamId, 'pins')
      );
      const progressRef = firebaseContext.svc.prefixedSafeRef<TeamProgressData>(
        HiddenPictureUtils.TeamProgressPath(venueId, blockId, teamId)
      );

      const intersectingHotSpots = HiddenPictureUtils.FindIntersectingHotSpots(
        { x, y },
        hotSpots.current
      );

      const pin: Pin = {
        x,
        y,
        clientId,
        clientName,
        createdAt: RTDBServerValueTIMESTAMP,
        intersectingHotSpotIds: intersectingHotSpots.map(
          (hotSpot) => hotSpot.id
        ),
        grade: 'miss',
        foundHotSpotIds: null,
      };

      await progressRef.transaction((progress) => {
        pin.grade = 'miss';
        pin.foundHotSpotIds = null;

        const foundHotSpots = new Set<string>(
          Object.keys(progress.found ?? {})
        );

        const hits = HiddenPictureUtils.GradeIntersectingHotSpots(
          intersectingHotSpots,
          hotSpots.current,
          sequenced.current,
          foundHotSpots
        );

        if (hits.length === 0) {
          if (incorrectAnswerPenalty.current > 0) {
            return {
              ...progress,
              numPenalties: (progress.numPenalties ?? 0) + 1,
              score: (progress.score ?? 0) - incorrectAnswerPenalty.current,
            };
          }
          return progress;
        }

        pin.grade = 'hit';
        pin.foundHotSpotIds = hits.map((hit) => hit.id);

        let score = progress.score ?? 0;
        let additionalPenalties = 0;
        const updatedFound = progress.found ?? {};
        for (const hit of hits) {
          if (hit.id in updatedFound) continue;
          updatedFound[hit.id] = pin as never;
          score += hit.points;
          if (hit.points < 0) {
            additionalPenalties++;
          }
        }
        return {
          ...progress,
          numPenalties: (progress.numPenalties ?? 0) + additionalPenalties,
          found: updatedFound,
          score,
        };
      });

      if (pin.grade === 'miss') {
        const pinRef = pinsRef.push();
        await pinRef.set(pin as never);
      }
    },
    [
      firebaseContext.svc,
      venueId,
      blockId,
      teamId,
      hotSpots,
      clientId,
      sequenced,
      incorrectAnswerPenalty,
    ]
  );

  const dropSignal = useCallback(
    async (x: number, y: number) => {
      const signalsRef = firebaseContext.svc.prefixedSafeRef<Pins>(
        HiddenPictureUtils.TeamGamePlayPath(venueId, blockId, teamId, 'signals')
      );

      const signal: Signal = {
        x,
        y,
        clientId,
        createdAt: RTDBServerValueTIMESTAMP,
      };

      const signalRef = signalsRef.push();
      await signalRef.set(signal as never);
    },
    [firebaseContext.svc, venueId, blockId, teamId, clientId]
  );

  const updateToolPosition: HiddenPictureGamePlayAPI['updateToolPosition'] =
    useCallback(
      async (tool, point) => {
        if (tool === 'none') return;
        const toolRef = firebaseContext.svc.prefixedSafeRef<{
          x: number;
          y: number;
        }>(
          HiddenPictureUtils.TeamGamePlayToolPath(
            venueId,
            blockId,
            teamId,
            tool
          )
        );
        await toolRef.set(point);
      },
      [firebaseContext.svc, venueId, blockId, teamId]
    );

  return {
    dropPin,
    dropSignal,
    updateToolPosition,
  };
}

export function useHiddenPictureGameControl(): HiddenPictureGameControlAPI {
  const venueId = useVenueId();
  const { updateGame, game } = useHiddenPictureContext();
  const gameId = game?.id;
  const batchWrite = useFirebaseBatchWrite();
  const ref = useDatabaseSafeRef(HiddenPictureUtils.RootPath(venueId));
  const teams = useLatest(
    useTeams({
      updateStaffTeam: true,
      excludeStaffTeam: true,
    })
  );

  const resetGame = useCallback(
    async (debug: string) => {
      log.info(`reset game: ${debug}`);
      await ref.remove();
    },
    [ref]
  );

  const initGame = useCallback(
    async (block: HiddenPictureBlock) => {
      const updates = uncheckedIndexAccess_UNSAFE({});
      let pictures = block.fields.pictures ? [...block.fields.pictures] : [];
      if (block.fields.randomizePictures) {
        pictures = shuffle(pictures);
      }

      for (const team of teams.current) {
        updates[
          HiddenPictureUtils.TeamProgressPath(venueId, block.id, team.id)
        ] = {
          score: 0,
          found: {},
          currentPictureIndex: 0,
        };
      }
      await batchWrite(updates);
      await updateGame({
        id: uuidv4(),
        state: GameState.Inited,
        pictures,
        maxPenaltyLimit: block.fields.maxPenaltyLimit ?? null,
        maxPenaltyLimitLabel: block.fields.maxPenaltyLimitLabel ?? null,
        penaltyResetStrategy:
          block.fields.penaltyResetStrategy ??
          EnumsHiddenPicturePenaltyResetStrategy.HiddenPicturePenaltyResetStrategyNever,
      });
    },
    [updateGame, teams, batchWrite, venueId]
  );

  const startGame = useCallback(async () => {
    if (!gameId) return;
    await updateGame({
      id: gameId,
      state: GameState.InProgress,
      startTime: Date.now(),
    });
  }, [gameId, updateGame]);

  const stopGame = useCallback(async () => {
    if (!gameId) return;
    await updateGame({ id: gameId, state: GameState.Ended });
  }, [updateGame, gameId]);

  const deinitGame = useCallback(async () => {
    if (!gameId) return;
    await updateGame({ id: gameId, state: GameState.None });
  }, [updateGame, gameId]);

  return {
    initGame,
    startGame,
    stopGame,
    resetGame,
    deinitGame,
  };
}

export function useHiddenPictureAllFound(): boolean {
  const hotSpots = useHiddenPictureCurrentHotSpots();
  const foundHotSpots = useHiddenPictureFoundHotSpots();

  // all matched when all hotspots are found
  return useMemo(() => {
    if (!hotSpots || !foundHotSpots || hotSpots.length === 0) return false;
    return HiddenPictureUtils.AllFound(hotSpots, foundHotSpots);
  }, [foundHotSpots, hotSpots]);
}

export function useHiddenPictureCompleted(): boolean {
  const numPictures = useHiddenPictureContext().game?.pictures?.length ?? 0;
  const numPicturesCompleted =
    useHiddenPictureContext().progress?.numPicturesCompleted ?? 0;
  return numPictures > 0 && numPicturesCompleted === numPictures;
}

export function useHiddenPictureNumPenaltiesRemaining(): number | null {
  const game = useHiddenPictureGame();
  const { maxPenaltyLimit } = game ?? {};

  const numPenalties = useHiddenPictureContext().progress?.numPenalties;

  return useMemo(() => {
    if (!maxPenaltyLimit) return null;
    return Math.min(
      Math.max(maxPenaltyLimit - (numPenalties ?? 0), 0),
      maxPenaltyLimit
    );
  }, [maxPenaltyLimit, numPenalties]);
}

export function useHiddenPictureFailed(): boolean {
  const numPenaltiesRemaining = useHiddenPictureNumPenaltiesRemaining();
  return numPenaltiesRemaining === 0;
}

export function useHiddenPictureScorer(teamId: TeamId): void {
  const score = useHiddenPictureScore();

  useEffect(() => {
    async function run() {
      if (score === undefined) return;
      await updateBlockDetailScore<HiddenPictureBlockDetailScore>(teamId, {
        score,
        submittedAt: Date.now(),
      });
    }
    run();
  }, [score, teamId]);
}

export function useHiddenPictureToolAssigner(teamId: TeamId): void {
  const { updateToolAssignment } = useHiddenPictureContext();

  const currentPicture = useHiddenPictureCurrentPicture();
  const previousPictureId = usePrevious(currentPicture?.id);
  const tool = useHiddenPictureCurrentTool();
  const assignment = useHiddenPictureToolAssignment();
  const teamMembers = useTeamMembers(teamId);
  const teamCaptains = useTeamCaptainLookup();

  useEffect(() => {
    if (tool === 'none' && assignment) {
      updateToolAssignment(null);
      return;
    }

    if (tool === 'none' || teamMembers?.length === 0) return;

    // if we already have an assigment validate it.
    if (assignment && previousPictureId === currentPicture?.id) {
      const assignedTeamMember = teamMembers?.find(
        (tm) => tm.id === assignment.clientId
      );
      if (assignedTeamMember) return;
    }

    // we either don't have an assignment or the client is no longer present.
    if (teamMembers?.length === 1) {
      updateToolAssignment({
        tool,
        clientId: teamMembers[0].id,
      });
    } else {
      // exclude team captain.
      const assignableTeamMembers = teamMembers?.filter((tm) =>
        teamCaptains(tm.id)
      );
      const teamMember = sample(assignableTeamMembers);
      if (teamMember) {
        updateToolAssignment({
          tool,
          clientId: teamMember.id,
        });
      }
    }
  }, [
    assignment,
    currentPicture?.id,
    previousPictureId,
    teamCaptains,
    teamMembers,
    tool,
    updateToolAssignment,
  ]);
}

export function useEmitGamePlayEndedState(block: HiddenPictureBlock): void {
  const emitter = useGamePlayEmitter();
  const [finalState, setFinalState] = useState<Nullable<GamePlayEndedState>>();
  const time = useGameSessionLocalTimer();
  const gameState = useHiddenPictureGame()?.state;
  const completed = useHiddenPictureCompleted();
  const failed = useHiddenPictureFailed();

  // timesup
  useEffect(() => {
    if (finalState) return;
    if (gameState === GameState.Ended) {
      emitter.emit('ended', block.id, 'timesup');
      setFinalState('timesup');
    }
  }, [time, gameState, emitter, block.id, finalState]);

  // finished
  useEffect(() => {
    if (
      finalState ||
      gameState !== GameState.InProgress ||
      (!completed && !failed)
    )
      return;

    let opts: GamePlayEndedEventFinishOptions;
    if (failed) {
      opts = {
        animationMedia: block.fields.penaltiesFailureMedia,
        muteAnimationMedia: false,
        primaryTextNode: (
          <>
            MISSION
            <br />
            UNSUCCESSFUL
          </>
        ),
        secondaryTextNode: (
          <>Nice try! You'll join the rest of your group when the timer ends.</>
        ),
      };
    } else {
      opts = {
        animationMedia: block.fields.gameCompletionMedia,
        muteAnimationMedia: false,
      };
    }

    emitter.emit(
      opts.animationMedia ? 'ended-awaiting-goal-media' : 'ended',
      block.id,
      'finished',
      opts
    );
    setFinalState('finished');
  }, [
    completed,
    block.id,
    emitter,
    finalState,
    gameState,
    block.fields.gameCompletionMedia,
    block.fields.penaltiesFailureMedia,
    failed,
  ]);

  useEffect(() => {
    return () => {
      setFinalState(undefined);
    };
  }, [block.id]);
}

export function useHiddenPictureAllTeamsProgressData(): AllTeamsProgressData {
  const venueId = useVenueId();
  const blockId = useGameSessionBlockId() ?? '';
  const isSessionAlive = useIsStreamSessionAlive();
  const [teamInfo] = useFirebaseValue<Nullable<AllTeamsProgressData>>(
    HiddenPictureUtils.TeamProgressPath(venueId, blockId),
    {
      enabled: isSessionAlive,
      seedValue: null,
      seedEnabled: false,
      readOnly: true,
      resetWhenUmount: true,
    }
  );

  return teamInfo ?? {};
}

export function useHiddenPictureTeamProgressData(
  teamId: TeamId
): Nullable<TeamProgressData> {
  const venueId = useVenueId();
  const blockId = useGameSessionBlockId() ?? '';
  const isSessionAlive = useIsStreamSessionAlive();
  const [teamInfo] = useFirebaseValue<Nullable<TeamProgressData>>(
    HiddenPictureUtils.TeamProgressPath(venueId, blockId, teamId),
    {
      enabled: isSessionAlive,
      seedValue: null,
      seedEnabled: false,
      readOnly: true,
      resetWhenUmount: true,
    }
  );
  return teamInfo;
}

export function HiddenPictureProvider(props: {
  children?: ReactNode;
}): JSX.Element | null {
  const venueId = useVenueId();
  const blockId = useGameSessionBlockId() ?? '';
  const isController = useIsController();
  const isSessionAlive = useIsStreamSessionAlive();
  const teamId = useMyTeamId() ?? '';
  const [game, updateGame] = useInitGame({
    enabled: isSessionAlive,
    readonly: !isController,
  });
  const [pins] = useFirebaseValue<Nullable<Pins>>(
    HiddenPictureUtils.TeamGamePlayPath(venueId, blockId, teamId, 'pins'),
    {
      enabled: isSessionAlive && !!teamId,
      seedValue: null,
      seedEnabled: false,
      readOnly: true,
      resetWhenUmount: true,
    }
  );
  const [signals] = useFirebaseValue<Nullable<Pins>>(
    HiddenPictureUtils.TeamGamePlayPath(venueId, blockId, teamId, 'signals'),
    {
      enabled: isSessionAlive && !!teamId,
      seedValue: null,
      seedEnabled: false,
      readOnly: true,
      resetWhenUmount: true,
    }
  );
  const [toolAssignment, updateToolAssignment] = useFirebaseValue<
    Nullable<ToolAssignment>
  >(
    HiddenPictureUtils.TeamGamePlayPath(
      venueId,
      blockId,
      teamId,
      'toolAssignment'
    ),
    {
      enabled: isSessionAlive && !!teamId,
      seedValue: null,
      seedEnabled: false,
      readOnly: false, // will be writeable by the team captain.
      resetWhenUmount: true,
    }
  );
  const [tools] = useFirebaseValue<Nullable<FoundHotSpots>>(
    HiddenPictureUtils.TeamGamePlayPath(venueId, blockId, teamId, 'tools'),
    {
      enabled: isSessionAlive && !!teamId,
      seedValue: null,
      seedEnabled: false,
      readOnly: true,
      resetWhenUmount: true,
    }
  );
  const [teamProgressData] = useFirebaseValue<Nullable<TeamProgressData>>(
    HiddenPictureUtils.TeamProgressPath(venueId, blockId, teamId),
    {
      enabled: isSessionAlive && !!teamId,
      seedValue: null,
      seedEnabled: false,
      readOnly: true,
      resetWhenUmount: true,
    }
  );

  const ref = useRef<Context>(
    proxy({
      game,
      updateGame: updateGame as never,
      pins: pins ?? {},
      signals: signals ?? {},
      toolAssignment: toolAssignment ?? null,
      updateToolAssignment,
      tools: tools ?? {},
      progress: teamProgressData ?? null,
    })
  );

  useLayoutEffect(() => {
    ref.current.game = game;
    ref.current.updateGame = updateGame as never;
  }, [game, updateGame]);

  useLayoutEffect(() => {
    ref.current.pins = pins ?? {};
  }, [pins]);

  useLayoutEffect(() => {
    ref.current.signals = signals ?? {};
  }, [signals]);

  useLayoutEffect(() => {
    ref.current.toolAssignment = toolAssignment ?? null;
    ref.current.updateToolAssignment = updateToolAssignment;
  }, [toolAssignment, updateToolAssignment]);

  useLayoutEffect(() => {
    ref.current.tools = tools ?? {};
  }, [tools]);

  useEffect(() => {
    ref.current.progress = teamProgressData ?? null;
  }, [teamProgressData]);

  return (
    <context.Provider value={ref.current}>{props.children}</context.Provider>
  );
}
