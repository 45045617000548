import { useEffect, useState } from 'react';

import { type OverRoastedBlock } from '@lp-lib/game';

import { useMyInstance } from '../../../../hooks/useMyInstance';
import { FloatLayout } from '../../../Layout';
import { useSoundEffect } from '../../../SFX';
import { AutoScale } from '../Common/GamePlay/ContainLayout';
import {
  GamePlayEndTransition,
  Timesup,
} from '../Common/GamePlay/GamePlayEndTransition';
import { useGamePlayEmitter } from '../Common/GamePlay/GamePlayProvider';
import { ProgressRing } from '../Common/GamePlay/ProgressRing';
import { OverRoastedBagsPanel } from './OverRoastedBagsPanel';
import { OverRoastedOrdersPanel } from './OverRoastedOrdersPanel';
import {
  useOverRoastedGame,
  useOverRoastedGamePlayable,
  useOverRoastedGameSettings,
  useOverRoastedMyGroupId,
} from './OverRoastedProvider';
import {
  useOverRoastedActiveMachine,
  useOverRoastedActiveTruckId,
  useOverRoastedGamePlayAPI,
  useOverRoastedTutorialProgress,
} from './OverRoastedProvider/OverRoastedGamePlayProvider';
import { OverRoastedTooltips } from './OverRoastedTooltips';
import { OverRoastedTrucksPanel } from './OverRoastedTrucksPanel';
import { GameState, type Ingredient, TutorialStep } from './types';

export function useEmitGamePlayEndedState(block: OverRoastedBlock): void {
  const emitter = useGamePlayEmitter();
  const game = useOverRoastedGame();

  const { play: playGameEnd } = useSoundEffect('gameEnd');

  // timesup
  useEffect(() => {
    if (game?.state === GameState.Ended) {
      emitter.emit('ended', block.id, 'timesup');
    }
  }, [block.id, emitter, game?.state]);

  useEffect(() => {
    if (game?.state !== GameState.Ended) return;

    playGameEnd();
  }, [game?.state, playGameEnd]);
}

export function OverRoastedPlayground(props: {
  block: OverRoastedBlock;
  timer: number;
}): JSX.Element | null {
  const { block, timer } = props;

  const me = useMyInstance();
  const groupId = useOverRoastedMyGroupId();
  const playerId = me?.id;
  const tutorialProgress = useOverRoastedTutorialProgress();
  const playable =
    useOverRoastedGamePlayable() &&
    tutorialProgress?.step !== TutorialStep.LearnOrder;
  const api = useOverRoastedGamePlayAPI();
  const settings = useOverRoastedGameSettings();
  const timerVisible = !settings.tutorialMode;

  const activeTruckId = useOverRoastedActiveTruckId();
  const activeMachine = useOverRoastedActiveMachine(activeTruckId);
  const [
    clickIngredientWithoutActiveMachineIn2Sec,
    setClickIngredientWithoutActiveMachineIn2Sec,
  ] = useState(false);

  useEmitGamePlayEndedState(block);

  if (!groupId || !playerId) return null;

  const handleClickIngredient = async (ingredient: Ingredient) => {
    if (!activeMachine) {
      setClickIngredientWithoutActiveMachineIn2Sec(true);
      setTimeout(() => {
        setClickIngredientWithoutActiveMachineIn2Sec(false);
      }, 2000);
      return;
    }

    await api.addIngredient(activeMachine.id, ingredient);
  };

  const showSelectCupTooltip =
    clickIngredientWithoutActiveMachineIn2Sec && !activeMachine;

  return (
    <>
      <FloatLayout
        useCustomPaddingX
        className='left-0 right-48 lg:right-65 2xl:right-72'
      >
        <AutoScale>
          <div className={`flex`}>
            <div className='mx-7 2xl:mx-13 flex flex-col items-center'>
              <ProgressRing
                className={timerVisible ? '' : 'invisible'}
                currentTime={timer}
                totalTime={settings.gameTimeSec}
                withPingAnimations
              />
              <OverRoastedOrdersPanel />
            </div>

            <div className='w-198 2xl:w-216 relative flex flex-col items-center gap-6'>
              <OverRoastedTrucksPanel
                groupId={groupId}
                playerId={playerId}
                showSelectCupTooltip={showSelectCupTooltip}
              />

              <OverRoastedBagsPanel onClickIngredient={handleClickIngredient} />

              {!playable && (
                <div className='w-full h-full absolute inset-0 cursor-not-allowed z-30'></div>
              )}
            </div>
          </div>
        </AutoScale>
      </FloatLayout>

      <OverRoastedTooltips />
      <GamePlayEndTransition
        timesup={() => (
          <Timesup text='Closing Time!' textClassName='text-tertiary' />
        )}
      />
    </>
  );
}
