import { useEffect, useMemo, useRef, useState } from 'react';
import { usePrevious } from 'react-use';
import { proxy } from 'valtio';

import {
  type AIChatBlock,
  AIChatBlockGameSessionStatus,
  assertExhaustive,
} from '@lp-lib/game';
import { type Media, MediaFormatVersion } from '@lp-lib/media';

import { ClientTypeUtils } from '../../../../types';
import { MediaUtils } from '../../../../utils/media';
import { playWithCatch } from '../../../../utils/playWithCatch';
import { markSnapshottable, ValtioUtils } from '../../../../utils/valtio';
import { useGainPointsAnimationGamePlayTrigger } from '../../../GainPointsAnimation/useGainPointsAnimationGamePlayTrigger';
import { FloatLayout } from '../../../Layout';
import { LayoutAnchor } from '../../../LayoutAnchors/LayoutAnchors';
import { useSyncPersistentPointsRevealAnswer } from '../../../PersistentPoints/Provider';
import { useMyTeamId } from '../../../Player';
import { useMyClientType } from '../../../Venue/VenuePlaygroundProvider';
import { GoAnimation } from '../../GameBlockCardAnimations';
import {
  useGameSessionLocalTimer,
  useGameSessionStatus,
  useIsGamePlayPaused,
  useIsLiveGamePlay,
  useTimerRecover,
} from '../../hooks';
import { countdownV2, resetTimer, setTimer } from '../../store';
import { GamePlayEndTransition } from '../Common/GamePlay/GamePlayEndTransition';
import {
  buildGamePlayMedia,
  GamePlayMediaPlayer,
  type GamePlayMediaPlayerLayout,
  useGamePlayMediaPlayable,
  useGamePlayMediaUISync,
} from '../Common/GamePlay/GamePlayMedia';
import { GamePlayMediaLayout } from '../Common/GamePlay/GamePlayMediaLayout';
import { GamePlayResult } from '../Common/GamePlay/GamePlayResult';
import {
  useCountdownPlaySFX,
  useGamePlayUITransitionControl,
} from '../Common/GamePlay/GamePlayUtilities';
import { StageBgSingleFrame } from '../Common/GamePlay/StageBgSingleFrame';
import { useSubmissionStatusWaitEnder } from '../Common/GamePlay/SubmissionStatusProvider';
import {
  type GamePlayMedia,
  type GamePlayProps,
} from '../Common/GamePlay/types';
import { useRankedTeamScores, useStableBlock } from '../Common/hooks';
import { BlockKnifeUtils } from '../Shared';
import { AIChatHostView, AIChatPlayground } from './AIChatPlayground';
import {
  useAIChatGamePlayAPI,
  useAIChatGameSharedAPI,
  useEmitGamePlayEndedState,
  useNarrowedPromptTemplate as usePromptTemplate,
  useSetupGamePlay,
  useSubscribeSharedState,
  useTrackMessages,
} from './AIChatProvider';

type AIChatGamePlayLocalState = {
  gamePlayMedia: Nullable<GamePlayMedia, false>;
  mediaPlayerLayout: GamePlayMediaPlayerLayout | null;
  mediaPlayerLoop: boolean;
  showResults: boolean;
};

class AIChatGamePlayLocalAPI {
  private _state;
  constructor(
    private block: AIChatBlock,
    isHost: boolean,
    isLive: boolean,
    private startTimerWorker = isHost ? !isLive : true
  ) {
    this._state = markSnapshottable(
      proxy<AIChatGamePlayLocalState>(this.initState())
    );
  }

  presentIntro() {
    this.updateGamePlayMedia(
      buildGamePlayMedia(
        {
          media: BlockKnifeUtils.Media(this.block, 'introMedia'),
          mediaData: this.block.fields.introMediaData,
        },
        {
          stage: 'intro',
        }
      ),
      'anchored',
      false
    );
  }

  async initGame() {
    this.updateBackgroundMedia();
    setTimer('submission', this.block.fields.gameTimeSec);
  }

  async startGame() {
    await this.startTimer();
  }

  async endGame() {
    await resetTimer('submission');
  }

  presentOutro() {
    this.updateGamePlayMedia(null, undefined, false);
    this.updateGamePlayMedia(
      buildGamePlayMedia(
        {
          media: BlockKnifeUtils.Media(this.block, 'outroMedia'),
          mediaData: this.block.fields.outroMediaData,
        },
        {
          stage: 'outro',
        }
      ),
      'anchored',
      false
    );
  }

  revealResults() {
    this.updateGamePlayMedia(null, undefined);
    this._state.showResults = true;
  }

  hideResults() {
    this._state.showResults = false;
  }

  async resetTimer() {
    await resetTimer('submission');
  }

  async load() {
    await this.reset();
  }

  async reset() {
    await resetTimer('submission');
    ValtioUtils.reset(this._state, this.initState());
  }

  updateBackgroundMedia() {
    const media = buildGamePlayMedia(
      {
        media: BlockKnifeUtils.Media(this.block, 'backgroundMedia'),
        mediaData: this.block.fields.backgroundMediaData,
      },
      {
        stage: 'custom',
        isBackgroundMedia: true,
      }
    );
    this.updateGamePlayMedia(
      media,
      'fullscreen',
      !!this.block.fields.backgroundMediaData?.loop
    );
  }

  private updateGamePlayMedia(
    media: Nullable<GamePlayMedia, false>,
    layout: GamePlayMediaPlayerLayout,
    loop?: boolean
  ) {
    this.state.gamePlayMedia = media;
    this.state.mediaPlayerLayout = layout;
    this.state.mediaPlayerLoop = !!loop;
  }

  private async startTimer(startTimerWorker?: boolean) {
    await countdownV2({
      debug: 'AIChatBlockGamePlay',
      startTimeWorker: startTimerWorker ?? this.startTimerWorker,
      flushCountingStatus: false,
    });
  }

  private initState(): AIChatGamePlayLocalState {
    return {
      gamePlayMedia: null,
      mediaPlayerLayout: null,
      mediaPlayerLoop: false,
      showResults: false,
    };
  }

  get state() {
    return this._state;
  }
}

function Finished(props: { media: Nullable<Media> }): JSX.Element | null {
  const { media } = props;
  const paused = useIsGamePlayPaused();
  const mediaUrl = MediaUtils.PickMediaUrl(media, {
    priority: [MediaFormatVersion.Raw],
  });
  const videoRef = useRef<HTMLVideoElement>(null);
  const [ended, setEnded] = useState(false);

  useEffect(() => {
    if (!mediaUrl || paused) return;
    const el = videoRef.current;
    playWithCatch(el);
    return () => {
      el?.pause();
    };
  }, [mediaUrl, paused]);

  if (!mediaUrl || ended) return null;

  return (
    <div className='w-full h-full z-40 inset-0 absolute flex items-center justify-center bg-black bg-opacity-60'>
      {mediaUrl && (
        <video
          ref={videoRef}
          className='w-full h-full inset-0 absolute object-cover'
          src={mediaUrl}
          muted
          onEnded={() => setEnded(true)}
        />
      )}
    </div>
  );
}

function Results(): JSX.Element | null {
  const teamScores = useRankedTeamScores('currentScore', null, 'always');
  return (
    <>
      <GamePlayResult teamScores={teamScores} />
      <FloatLayout className='flex items-center justify-center'>
        <LayoutAnchor
          id='gameplay-points-animation-top'
          className='w-full max-w-4xl'
        />
      </FloatLayout>
    </>
  );
}

export function AIChatBlockGamePlay(
  props: GamePlayProps<AIChatBlock>
): JSX.Element | null {
  const block = useStableBlock(props.block);
  const gss = useGameSessionStatus<AIChatBlockGameSessionStatus>();
  const prevGss = usePrevious(gss);
  const [uiState, uiControl] = useGamePlayUITransitionControl();
  const isHost = ClientTypeUtils.isHost(useMyClientType());
  const isLive = useIsLiveGamePlay();
  const control = useMemo(() => {
    return new AIChatGamePlayLocalAPI(block, isHost, isLive);
  }, [block, isHost, isLive]);
  const state = control.state;
  const time = useGameSessionLocalTimer();
  const inGame =
    !!gss &&
    gss >= AIChatBlockGameSessionStatus.GAME_INIT &&
    gss <= AIChatBlockGameSessionStatus.GAME_END;
  const sharedAPI = useAIChatGameSharedAPI();
  const gamePlayAPI = useAIChatGamePlayAPI();
  const teamId = useMyTeamId();

  useCountdownPlaySFX(block.fields.gameTimeSec, time, inGame);
  useTimerRecover(async (status) => {
    if (status === AIChatBlockGameSessionStatus.GAME_START) {
      return block.fields.gameTimeSec;
    }
  });
  useSyncPersistentPointsRevealAnswer(state.showResults);
  useGainPointsAnimationGamePlayTrigger();
  useSubmissionStatusWaitEnder();
  useEmitGamePlayEndedState(block);
  useSetupGamePlay(
    gamePlayAPI,
    block.fields.model ?? undefined,
    usePromptTemplate(block.fields.promptTemplateId),
    !!teamId
  );
  useSubscribeSharedState();
  useTrackMessages();

  useEffect(() => {
    return () => {
      control.reset();
      sharedAPI.reset();
      gamePlayAPI.reset();
    };
  }, [control, gamePlayAPI, sharedAPI]);

  useEffect(() => {
    if (prevGss === gss) return;
    switch (gss) {
      case AIChatBlockGameSessionStatus.LOADED:
        control.load();
        break;
      case AIChatBlockGameSessionStatus.INTRO:
        control.presentIntro();
        break;
      case AIChatBlockGameSessionStatus.GAME_INIT:
        control.initGame();
        break;
      case AIChatBlockGameSessionStatus.GAME_START:
        control.startGame();
        break;
      case AIChatBlockGameSessionStatus.GAME_END:
        control.endGame();
        break;
      case AIChatBlockGameSessionStatus.OUTRO:
        control.presentOutro();
        break;
      case AIChatBlockGameSessionStatus.RESULTS:
        control.revealResults();
        break;
      case AIChatBlockGameSessionStatus.SCOREBOARD:
        control.hideResults();
        break;
      case AIChatBlockGameSessionStatus.END:
        control.hideResults();
        break;
      case null:
      case undefined:
        break;
      default:
        assertExhaustive(gss);
        break;
    }
  }, [gss, prevGss, control]);

  const { onMediaEnded, onMediaReplaying } = useGamePlayMediaUISync({
    block,
    gameSessionStatus: gss,
    media: state.gamePlayMedia,
    state: uiState,
    control: uiControl,
  });

  const mediaPlayable = useGamePlayMediaPlayable({
    block,
    gameSessionStatus: gss,
    media: state.gamePlayMedia,
    state: uiState,
    // This is only used for background media.
    // Play the background media when the game started
    custom: () => !!gss && gss === AIChatBlockGameSessionStatus.GAME_START,
  });

  if (gss === null || gss === undefined) return null;

  const fullscreen = state.mediaPlayerLayout === 'fullscreen';

  return (
    <div className='fixed w-screen h-screen text-white'>
      {state.gamePlayMedia && state.mediaPlayerLayout && (
        <StageBgSingleFrame gamePlayMedia={state.gamePlayMedia} />
      )}
      {uiState.playGoAnimation && <GoAnimation />}
      <GamePlayMediaLayout fullscreen={fullscreen}>
        {state.gamePlayMedia && state.mediaPlayerLayout && (
          <GamePlayMediaPlayer
            gamePlayMedia={state.gamePlayMedia}
            play={mediaPlayable}
            mode={fullscreen || !uiState.mediaEndEffect ? 'full' : 'small'}
            onMediaEnded={!fullscreen ? onMediaEnded : undefined}
            onMediaReplaying={!fullscreen ? onMediaReplaying : undefined}
            layout={state.mediaPlayerLayout}
            loop={state.mediaPlayerLoop}
          />
        )}
      </GamePlayMediaLayout>
      {inGame && (
        <FloatLayout className='flex items-center justify-center'>
          {isHost ? (
            <AIChatHostView block={block} gss={gss} />
          ) : (
            <AIChatPlayground block={block} gss={gss} />
          )}
        </FloatLayout>
      )}
      {inGame && !isHost && (
        <GamePlayEndTransition
          finished={(media) => <Finished media={media} />}
        />
      )}
      {state.showResults && <Results />}
    </div>
  );
}
