interface TagQueryable {
  tags: string[];
}

export class TagQuery<T extends TagQueryable> {
  constructor(private items: T[] | undefined | null) {}

  selectFirst(requiredTags: string[], mustNotTags: string[] = []) {
    if (!this.items) return null;
    for (const script of this.items) {
      if (matches(script, requiredTags, mustNotTags)) return script;
    }
    return null;
  }

  select(tags: string[], mustNotTags: string[] = []) {
    if (!this.items) return [];
    const results = [];
    for (const script of this.items) {
      if (matches(script, tags, mustNotTags)) results.push(script);
    }
    return results;
  }

  selectWith(tags: Record<string, boolean>) {
    const mustTags = [];
    const mustNotTags = [];
    for (const [tag, value] of Object.entries(tags)) {
      if (value) {
        mustTags.push(tag);
      } else {
        mustNotTags.push(tag);
      }
    }
    return this.select(mustTags, mustNotTags);
  }
}

function matches(
  s: TagQueryable,
  requiredTags: string[],
  mustNotTags: string[]
) {
  for (const t of requiredTags) {
    if (!s.tags.includes(t)) {
      return false;
    }
  }
  for (const t of mustNotTags) {
    if (s.tags.includes(t)) {
      return false;
    }
  }
  return true;
}
