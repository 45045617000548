import { type NetworkQuality, type UID } from 'agora-rtc-sdk-ng';
import { useCallback, useEffect, useRef } from 'react';

import { useUpdateNetworkQuality } from '../../../../components/Player';
import { useMyInstance } from '../../../../hooks/useMyInstance';
import {
  type IMediaDeviceRTCService,
  type TrackState,
  WebRTCUtils,
} from '../../../../services/webrtc';
import { useRemoteStreamStateAPI } from '../RemoteStreamStateProvider';

function MakeGenericCallback(
  api: ReturnType<typeof useRemoteStreamStateAPI>,
  key: 'Subscribed' | 'Published',
  val: boolean
) {
  return (uid: UID, mediaType: 'audio' | 'video') => {
    api.updateStreamState(uid.toString(), {
      [`${mediaType}${key}`]: val,
    });
  };
}

export function useSubscribeAudienceRTCEvents(
  rtcService: IMediaDeviceRTCService
): void {
  const volumeMapCacheRef = useRef<Record<UID, number>>({});
  const me = useMyInstance();
  const api = useRemoteStreamStateAPI();
  const updateNetworkQuality = useUpdateNetworkQuality();

  const volumedChangedCallback = useCallback(
    (volumeMap: Record<UID, number>) => {
      const normalizedVolumeMap: Record<UID, number> = {};
      for (const [uid, vol] of Object.entries(volumeMap)) {
        let normalizedVolume = 0;
        if (WebRTCUtils.IsSpeaking(vol)) {
          normalizedVolume = 1;
        }
        normalizedVolumeMap[uid] = normalizedVolume;
        if (volumeMapCacheRef.current[uid] !== normalizedVolume) {
          api.updateStreamState(uid.toString(), {
            volume: normalizedVolume,
          });
        }
      }
      volumeMapCacheRef.current = normalizedVolumeMap;
    },
    [api]
  );

  const videoTrackStateChangedCallback = useCallback(
    (uid: UID, _oldState: TrackState | null, newState: TrackState | null) => {
      api.updateStreamState(uid.toString(), {
        trackState: newState,
      });
    },
    [api]
  );

  const networkQualityCallback = useCallback(
    (stats: NetworkQuality) => {
      if (!me?.clientId) return;
      updateNetworkQuality(
        me.clientId,
        stats.uplinkNetworkQuality,
        stats.downlinkNetworkQuality
      );
    },
    [me?.clientId, updateNetworkQuality]
  );

  useEffect(() => {
    // NOTE(drew): We cannot subscribe to these events _after_ joining the
    // channel, because agora will not refire them via the act of calling
    // `on()`. Agora only refires events if the listener is in place before
    // joining the channel. From the agora docs
    // https://api-ref.agora.io/en/video-sdk/web/4.x/interfaces/iagorartcclient.html#event_user_published:
    // > The SDK also triggers this callback to report the existing tracks in
    // > the channel when a user joins the channel.
    //
    // Key words being "_when_ a user joins". If we wait until `joined` is true,
    // we'll have missed the events.

    const disposers: (() => void)[] = [];
    disposers.push(
      rtcService.on(
        'remote-user-published',
        MakeGenericCallback(api, 'Published', true)
      )
    );
    disposers.push(
      rtcService.on(
        'remote-user-unpublished',
        MakeGenericCallback(api, 'Published', false)
      )
    );
    disposers.push(
      rtcService.on(
        'remote-user-subscribed',
        MakeGenericCallback(api, 'Subscribed', true)
      )
    );
    disposers.push(
      rtcService.on(
        'remote-user-unsubscribed',
        MakeGenericCallback(api, 'Subscribed', false)
      )
    );
    disposers.push(
      rtcService.on('user-volume-changed', volumedChangedCallback)
    );
    disposers.push(
      rtcService.on('video-track-state-changed', videoTrackStateChangedCallback)
    );
    disposers.push(rtcService.on('network-quality', networkQualityCallback));
    rtcService.subscribeEvents();
    return () => {
      disposers.forEach((disposer) => disposer());
      rtcService.unsubscribeEvents();
    };
  }, [
    api,
    networkQualityCallback,
    rtcService,
    videoTrackStateChangedCallback,
    volumedChangedCallback,
  ]);
}
