import { type ReactNode, useEffect, useMemo, useRef } from 'react';
import { match } from 'ts-pattern';

import { EnumsH2HJudgingUserGroup } from '@lp-lib/api-service-client/public';
import { ProfileIndex } from '@lp-lib/crowd-frames-schema';
import { getStaticAssetPath } from '@lp-lib/email-templates/src/utils';
import { assertExhaustive, type HeadToHeadBlock } from '@lp-lib/game';

import { useIsCoordinator } from '../../../../hooks/useMyInstance';
import { hexToRgbaCSS } from '../../../../utils/css';
import { CrowdFramesAvatar } from '../../../CrowdFrames';
import { DoubleLeftArrow, DoubleRightArrow } from '../../../icons/Arrows';
import {
  useLastJoinedParticipantByUserId,
  useMyInstance,
} from '../../../Player';
import { useSoundEffect } from '../../../SFX';
import {
  useGroupVoters,
  useHeadToHeadGamePlayAPI,
  useHeadToHeadGameProgress,
  useMyCurrentHeadToHeadRole,
  useMyVote,
  usePlayerVotable,
  useTotalVoters,
} from './HeadToHeadBlockProvider';
import { type H2HPlayerRole } from './types';
import { HeadToHeadUtils } from './utils';

function useVotable(block: HeadToHeadBlock) {
  const role = useMyCurrentHeadToHeadRole(block);
  const isCoordinator = useIsCoordinator();
  const playerVotable = usePlayerVotable();
  return useMemo(() => {
    switch (block.fields.judgingUserGroup) {
      case EnumsH2HJudgingUserGroup.H2HJudgingUserGroupAudience:
        return role === 'audience' || playerVotable;
      case EnumsH2HJudgingUserGroup.H2HJudgingUserGroupGameCoordinator:
        return isCoordinator;
      default:
        assertExhaustive(block.fields.judgingUserGroup);
        return false;
    }
  }, [block.fields.judgingUserGroup, isCoordinator, playerVotable, role]);
}

function EndGameVoteButton(props: {
  groupId: string;
  groupColor: string;
  children?: ReactNode;
  onClick?: (groupId: string) => void;
  voted?: boolean;
}) {
  const { groupId, onClick, voted } = props;
  return (
    <button
      type='button'
      className={`w-36 h-10 rounded-xl flex items-center justify-center gap-2 flex-shrink-0 text-white text-sms font-medium ${
        voted ? 'border border-witte' : ''
      }`}
      style={{
        background: props.groupColor,
      }}
      onClick={() => onClick?.(groupId)}
    >
      {props.children}
    </button>
  );
}

export function EndGameJudgingPanel(props: { block: HeadToHeadBlock }) {
  const { block } = props;
  const progress = useHeadToHeadGameProgress();
  const vote = useMyVote();
  const api = useHeadToHeadGamePlayAPI();
  const me = useMyInstance();
  const { play: playVoteSFX } = useSoundEffect('headToHeadVote');

  const onVote = async (groupId: string) => {
    if (!me) return;
    playVoteSFX();
    await api.vote(me.id, groupId);
  };

  const background = match(vote)
    .when(
      (v) => v?.groupId === progress.currentAGroupId,
      () => 'linear-gradient(90deg, #8B002A 0%, #1B1B1E 100%)'
    )
    .when(
      (v) => v?.groupId === progress.currentBGroupId,
      () => 'linear-gradient(90deg, #1B1B1E 0%, #003686 100%)'
    )
    .otherwise(() => '#1B1B1E');

  const votable = useVotable(block);

  if (!votable) {
    return (
      <div className='w-full text-icon-gray uppercase flex items-center justify-center'>
        Judging in progress...
      </div>
    );
  }

  return (
    <div
      className='w-full h-25 border border-secondary rounded-2.5xl flex items-center justify-center px-7.5'
      style={{
        background,
      }}
    >
      <EndGameVoteButton
        groupId={progress.currentAGroupId}
        groupColor={HeadToHeadUtils.GroupColor('groupA')}
        onClick={onVote}
        voted={vote?.groupId === progress.currentAGroupId}
      >
        <DoubleLeftArrow className='w-5 h-5 fill-current' />
        <div>{HeadToHeadUtils.GroupName('groupA')}</div>
      </EndGameVoteButton>
      <div className='flex flex-col gap-2 flex-grow items-center'>
        <div className='text-tertiary xl:text-xl font-bold text-center mx-2'>
          {block.fields.judgingHeadline || 'You’re on the Judging Panel'}
        </div>
        <div className='text-white text-2xs xl:text-base text-center mx-2'>
          {!!vote
            ? 'You can change your mind before time runs out.'
            : block.fields.judgingPrompt ??
              'Choose who you think won the contest, pink or blue?'}
        </div>
      </div>
      <EndGameVoteButton
        groupId={progress.currentBGroupId}
        groupColor={HeadToHeadUtils.GroupColor('groupB')}
        onClick={onVote}
        voted={vote?.groupId === progress.currentBGroupId}
      >
        <DoubleRightArrow className='w-5 h-5 fill-current' />
        <div>{HeadToHeadUtils.GroupName('groupB')}</div>
      </EndGameVoteButton>
    </div>
  );
}

function EndGameJudgingVoter(props: { uid: string }) {
  const p = useLastJoinedParticipantByUserId(props.uid);
  if (!p) return null;

  return (
    <div className='relative w-10 h-10 rounded-full overflow-hidden'>
      <CrowdFramesAvatar
        participant={p}
        profileIndex={ProfileIndex.wh36x36fps8}
        enablePointerEvents={false}
        roundedClassname='rounded-none'
      />
    </div>
  );
}

export function EndGameJudgingVoterList(props: {
  groupId: string;
  maxPreviews: number;
  rightToLeft?: boolean;
}) {
  const { groupId } = props;
  const voters = useGroupVoters(groupId);
  const n = voters.length - props.maxPreviews;
  return (
    <div className='flex flex-col gap-0.5'>
      <div
        className='grid grid-cols-3'
        style={{
          direction: props.rightToLeft ? 'rtl' : undefined,
        }}
      >
        {voters.map((v) => (
          <EndGameJudgingVoter key={v.voterId} uid={v.voterId} />
        ))}
      </div>
      {n > 0 && (
        <div
          className='text-2xs text-white'
          style={{
            textAlign: props.rightToLeft ? 'right' : undefined,
          }}
        >
          +{n} more
        </div>
      )}
    </div>
  );
}

function useJudgingBalanceOffset(block: HeadToHeadBlock, sentiment = 1) {
  const progress = useHeadToHeadGameProgress();
  const numOfAVotes = useGroupVoters(progress.currentAGroupId).length;
  const numOfBVotes = useGroupVoters(progress.currentBGroupId).length;
  const totalVoters = useTotalVoters(block);
  const base = totalVoters;

  if (base === 0) return 50;
  const offsetDistance =
    50 +
    (Math.abs(numOfAVotes - numOfBVotes) / base) *
      50 *
      (numOfAVotes > numOfBVotes ? -1 : 1) *
      sentiment;
  return offsetDistance;
}

export function EndGameJudgingBalance(props: { block: HeadToHeadBlock }) {
  const { block } = props;
  const ref = useRef<HTMLImageElement>(null);
  const leftOffset = useJudgingBalanceOffset(block);
  const topOffset = useMemo(() => {
    const topAtMiddle = (1 / 3) * 100;
    return topAtMiddle - (Math.abs(50 - leftOffset) / 50) * topAtMiddle;
  }, [leftOffset]);

  useEffect(() => {
    if (!ref.current) return;
    ref.current.style.left = `${leftOffset}%`;
    ref.current.style.top = `${-topOffset}%`;
  }, [leftOffset, topOffset]);

  return (
    <div className='w-[80%] relative flex items-center justify-center'>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='100%'
        height='100%'
        viewBox='0 0 704 33'
        fill='none'
      >
        <path
          d='M4 29C4 29 109 4.5 353.5 4.5C572 4.5 700 29.0001 700 29.0001'
          stroke='url(#paint0_linear_54143_131610)'
          strokeOpacity='0.4'
          strokeWidth='8'
          strokeLinecap='round'
          strokeDasharray='20 20'
        />
        <defs>
          <linearGradient
            id='paint0_linear_54143_131610'
            x1='700'
            y1='6.35612'
            x2='697.943'
            y2='55.9337'
            gradientUnits='userSpaceOnUse'
          >
            <stop stopColor='#FFCA3F' />
            <stop offset='1' stopColor='#D69A00' />
          </linearGradient>
        </defs>
      </svg>
      <img
        ref={ref}
        src={getStaticAssetPath('images/winner-crown.png')}
        className='absolute w-20 lp-sm:w-25 2xl:w-30 z-30 left-1/2 -top-1/3 transform-gpu -translate-x-1/2 -translate-y-1/2 transition-position'
        alt='crown'
      />
    </div>
  );
}

function InGameCenterContainer(props: {
  children?: ReactNode;
  className?: string;
}) {
  return (
    <div
      className={`bg-gradient-to-bl from-pairing-start to-pairing-end p-px pb-0 rounded-t-[80px] ${
        props.className ?? ''
      }`}
    >
      <div
        className={`w-full h-full bg-modal rounded-t-[80px] flex justify-center`}
      >
        {props.children}
      </div>
    </div>
  );
}

function InGameVoteButton(props: {
  groupId: string;
  groupColor: string;
  twGroupColor: string;
  children?: ReactNode;
  onClick?: (groupId: string) => void;
  voted?: boolean;
}) {
  const { groupId, onClick } = props;
  return (
    <div
      className='w-full h-full relative rounded-t-[20px]'
      style={{
        boxShadow: props.voted
          ? `0px -4px 10px 0px ${hexToRgbaCSS(props.groupColor, 0.6)}`
          : 'none',
      }}
    >
      <button
        type='button'
        className={`w-full h-full rounded-t-[20px] flex items-center justify-center gap-2 flex-shrink-0 text-white font-bold ${props.twGroupColor} hover:bg-opacity-80 active:shadow-h2h-vote-btn-clicked`}
        onClick={() => onClick?.(groupId)}
      >
        {props.children}
      </button>
    </div>
  );
}

function InGameVotedCounter(props: {
  groupId: string;
  groupColor: string;
  className?: string;
  negative?: boolean;
}) {
  const { groupId, groupColor, negative } = props;
  const voters = useGroupVoters(groupId);

  const value = negative ? -voters.length : voters.length;
  return (
    <div
      className={`text-3.5xl font-bold w-20 ${props.className ?? ''}`}
      style={{
        color: groupColor,
      }}
    >
      {value}
    </div>
  );
}

function InGameJudgingBalance(props: { block: HeadToHeadBlock }) {
  const { block } = props;
  const ref = useRef<HTMLImageElement>(null);
  const sentiment = HeadToHeadUtils.JudgingSentiment(props.block);

  const leftOffset = useJudgingBalanceOffset(block, sentiment);

  useEffect(() => {
    if (!ref.current) return;
    ref.current.style.left = `${leftOffset}%`;
  }, [leftOffset]);

  return (
    <img
      ref={ref}
      src={getStaticAssetPath('images/winner-crown.png')}
      className='absolute w-7.5 z-5 left-1/2 -top-1 transform-gpu -translate-x-1/2 -translate-y-1/2 transition-position'
      alt='crown'
    />
  );
}

export function InGameJudgingPanel(props: { block: HeadToHeadBlock }) {
  const { block } = props;
  const me = useMyInstance();
  const progress = useHeadToHeadGameProgress();
  const api = useHeadToHeadGamePlayAPI();
  const role = useMyCurrentHeadToHeadRole(block);

  const vote = useMyVote();
  const { play: playVoteSFX } = useSoundEffect('headToHeadVote');

  const onVote = async (group: H2HPlayerRole, groupId: string) => {
    if (!me) return;
    playVoteSFX();
    api.sendEmoji(group, block.fields.judgingInGameSendingEmoji, me.id);
    await api.vote(me.id, groupId);
  };

  const votable = useVotable(block);

  let judgingPrompt = block.fields.judgingPrompt;
  if (role !== 'audience') {
    judgingPrompt =
      block.fields.judgingUserGroup ===
      EnumsH2HJudgingUserGroup.H2HJudgingUserGroupGameCoordinator
        ? 'The organizer will determine the winner'
        : 'The audience will determine the winner';
  }

  const negative = HeadToHeadUtils.JudgingSentiment(block) === -1;

  return (
    <div className='w-full flex items-end justify-center'>
      <div className='w-36 xl:w-42 2xl:w-50 h-15 transform translate-x-12 flex-shrink-0'>
        {votable && (
          <InGameVoteButton
            groupId={progress.currentAGroupId}
            groupColor={HeadToHeadUtils.GroupColor('groupA')}
            twGroupColor={HeadToHeadUtils.GroupColorForTW('groupA')}
            onClick={(groupId) => onVote('groupA', groupId)}
            voted={vote?.groupId === progress.currentAGroupId}
          >
            <div className='transform -translate-x-6'>
              {block.fields.judgingInGameButtonText}
            </div>
            {vote?.groupId === progress.currentAGroupId && me && (
              <div className='absolute left-0 top-1/2 transform -translate-x-1/2 -translate-y-1/2 w-7.5 h-7.5'>
                <CrowdFramesAvatar
                  participant={me}
                  profileIndex={ProfileIndex.wh36x36fps8}
                  enablePointerEvents={false}
                />
              </div>
            )}
          </InGameVoteButton>
        )}
      </div>
      <InGameCenterContainer
        className={`w-[60%] xl:min-w-120 2xl:min-w-150 ${
          votable ? 'h-17' : 'h-12'
        } z-5`}
      >
        <div className='w-4/5 flex items-center justify-center relative'>
          <InGameVotedCounter
            groupId={progress.currentAGroupId}
            groupColor={HeadToHeadUtils.GroupColor('groupA')}
            className='text-left'
            negative={negative}
          />
          <div className='flex-grow flex flex-col items-center justify-center gap-px'>
            <div className='text-tertiary text-sm font-bold'>
              {block.fields.judgingHeadline || 'Cast Your Vote'}
            </div>
            <div className='text-2xs text-white text-center'>
              {judgingPrompt}
            </div>
          </div>
          <InGameVotedCounter
            groupId={progress.currentBGroupId}
            groupColor={HeadToHeadUtils.GroupColor('groupB')}
            className='text-right'
            negative={negative}
          />
          <InGameJudgingBalance block={block} />
        </div>
      </InGameCenterContainer>
      <div className='w-36 xl:w-42 2xl:w-50 h-15 transform -translate-x-12 flex-shrink-0'>
        {votable && (
          <InGameVoteButton
            groupId={progress.currentBGroupId}
            groupColor={HeadToHeadUtils.GroupColor('groupB')}
            twGroupColor={HeadToHeadUtils.GroupColorForTW('groupB')}
            onClick={(groupId) => onVote('groupB', groupId)}
            voted={vote?.groupId === progress.currentBGroupId}
          >
            <div className='transform translate-x-6'>
              {block.fields.judgingInGameButtonText}
            </div>
            {vote?.groupId === progress.currentBGroupId && me && (
              <div className='absolute right-0 top-1/2 transform translate-x-1/2 -translate-y-1/2 w-7.5 h-7.5'>
                <CrowdFramesAvatar
                  participant={me}
                  profileIndex={ProfileIndex.wh36x36fps8}
                  enablePointerEvents={false}
                />
              </div>
            )}
          </InGameVoteButton>
        )}
      </div>
    </div>
  );
}
