import { useEffect, useState } from 'react';

import {
  type AIChatBlock,
  AIChatBlockGameSessionStatus,
  assertExhaustive,
} from '@lp-lib/game';

import { type TeamId } from '../../../../types';
import { DoubleRightArrow } from '../../../icons/Arrows';
import { PlayIcon } from '../../../icons/PlayIcon';
import { RefreshIcon } from '../../../icons/RefreshIcon';
import { FilledSquareIcon } from '../../../icons/SquareIcon';
import { TimerIcon } from '../../../icons/TimerIcon';
import {
  useGameSessionLocalTimer,
  useGameSessionStatus,
  useIsEndedBlock,
} from '../../hooks';
import { countdownV2, next, present, triggerBlockTitleAnim } from '../../store';
import {
  BlockControllerActionButton,
  BlockControllerActionNone,
  BlockControllerActionTimeRemaining,
  ControllerLayout,
  ControllerProgress,
  type ControllerProps,
  DecreasingPointsBadge,
  type MediaPlayback,
  MediaPreviewer,
  useBlockControllerBlockTitlePlaying,
  useControllerMediaPlayback,
  useControllerMediaPlayText,
} from '../Common/Controller/Internal';
import { useStableBlock } from '../Common/hooks';
import { BlockKnifeUtils } from '../Shared';
import { useAIChatGameControlAPI, useTeamSummaryMap } from './AIChatProvider';

type SharedProps = ControllerProps<AIChatBlock>;
type StageProps = SharedProps & {
  playback: MediaPlayback;
};

function BlockDetails(props: SharedProps): JSX.Element {
  const { selectedBlock: block } = props;

  return (
    <div className='w-full flex flex-col items-center text-sms font-normal'>
      <p>{block.fields.title}</p>
    </div>
  );
}

function GameProgress(
  props: StageProps & { revealResults?: boolean; hideProgressDetail?: boolean }
): JSX.Element | null {
  const {
    selectedBlock: block,
    setEditPointsData,
    hideProgressDetail,
    revealResults,
  } = props;
  const teamSummaryMap = useTeamSummaryMap();
  const api = useAIChatGameControlAPI();

  const actions = [
    {
      key: 'action-view-conversation',
      text: 'View Conversation',
      disabled: revealResults,
      handleClick: (teamId: TeamId) => {
        api.inspectingTeamId = teamId;
      },
    },
  ];

  return (
    <ControllerProgress
      blockId={block.id}
      setEditPointsData={setEditPointsData}
      revealResults={revealResults}
      hideProgressDetail={hideProgressDetail}
      customActionItems={actions}
      progressDetail={(teamId: string) => {
        const summary = teamSummaryMap?.[teamId];
        const result =
          summary?.result === 'win' || summary?.result === 'lose'
            ? summary.result
            : 'N/A';
        return `[${summary?.attempts ?? 0} attempts, status: ${result}]`;
      }}
    />
  );
}

function Badges(props: {
  block: AIChatBlock;
  disabled?: boolean;
}): JSX.Element {
  return (
    <div className='w-full px-2.5 flex flex-row items-center justify-start self-start'>
      <DecreasingPointsBadge
        decreasingPointsTimer={props.block.fields.decreasingPointsTimer}
        disabled={props.disabled}
      />
    </div>
  );
}

function Ended(props: StageProps): JSX.Element {
  return (
    <ControllerLayout
      action={
        <BlockControllerActionButton
          onClick={() => props.setShowResetConfirmation(true)}
          icon={RefreshIcon}
          isDelete={true}
        >
          Reset Completed Block
        </BlockControllerActionButton>
      }
    >
      <GameProgress {...props} hideProgressDetail revealResults />
    </ControllerLayout>
  );
}

function Loaded(props: StageProps): JSX.Element {
  const { selectedBlock: block, playback } = props;

  const { switchMedia, reset: resetPlayback } = playback.api;

  useEffect(() => {
    resetPlayback();
    const media = BlockKnifeUtils.Media(block, 'introMedia');
    switchMedia(media);
  }, [block, switchMedia, resetPlayback]);

  const onPresent = async () => {
    await triggerBlockTitleAnim(block);
    await present(block);
  };

  const actionComponent = useBlockControllerBlockTitlePlaying(block) ?? (
    <BlockControllerActionButton onClick={onPresent} icon={PlayIcon}>
      {playback.preview
        ? `Present Intro Media (${playback.preview.durationSec}s)`
        : 'Present Block'}
    </BlockControllerActionButton>
  );
  return (
    <ControllerLayout action={actionComponent}>
      <div className='w-full flex flex-col gap-6'>
        <Badges block={block} />
        <MediaPreviewer playback={playback} alt={block.type} />
        <BlockDetails {...props} />
      </div>
    </ControllerLayout>
  );
}

function Intro(props: StageProps): JSX.Element | null {
  const { selectedBlock: block, playback } = props;
  const mediaActionText = useControllerMediaPlayText(playback);
  const [presentIntro, setPresentIntro] = useState(!!playback.preview);
  const { play: playMedia } = playback.api;

  useEffect(() => {
    if (!playback.isVideoPlayed) return;
    setPresentIntro(false);
  }, [playback.isVideoPlayed]);

  useEffect(() => {
    playMedia();
  }, [playMedia]);

  useEffect(() => {
    if (presentIntro) return;
    next();
  }, [presentIntro]);

  if (presentIntro) {
    return (
      <ControllerLayout
        action={
          <BlockControllerActionNone icon={PlayIcon}>
            {mediaActionText}
          </BlockControllerActionNone>
        }
      >
        <div className='w-full flex flex-col gap-6'>
          <Badges block={block} disabled />
          <MediaPreviewer playback={playback} alt={block.type} />
          <BlockDetails {...props} />
        </div>
      </ControllerLayout>
    );
  }
  return null;
}

function GameInit(props: StageProps): JSX.Element {
  const { selectedBlock: block, playback } = props;

  const [processing, setProcessing] = useState(false);
  const gameTimeSec = useGameSessionLocalTimer();

  const onStartGame = async () => {
    if (gameTimeSec === 0) return;
    setProcessing(true);
    await countdownV2({
      debug: 'AIChatBlockController',
      startTimeWorker: true,
      flushCountingStatus: true,
      triggerTimesup: true,
    });
  };

  return (
    <ControllerLayout
      action={
        <BlockControllerActionButton
          onClick={onStartGame}
          icon={TimerIcon}
          disable={processing || gameTimeSec === 0}
        >
          {`Start Game Timer (${gameTimeSec}s)`}
        </BlockControllerActionButton>
      }
    >
      <div className='w-full flex flex-col gap-6'>
        <Badges block={block} disabled />
        <MediaPreviewer playback={playback} alt={block.type} />
        <BlockDetails {...props} />
      </div>
    </ControllerLayout>
  );
}

function GameStart(props: StageProps): JSX.Element {
  const time = useGameSessionLocalTimer();

  return (
    <ControllerLayout
      action={<BlockControllerActionTimeRemaining remainingSec={time} />}
    >
      <GameProgress {...props} />
    </ControllerLayout>
  );
}

function GameEnd(props: StageProps): JSX.Element {
  const [processing, setProcessing] = useState(false);

  const onRevealResult = async () => {
    setProcessing(true);
    await next();
  };

  return (
    <ControllerLayout
      action={
        <BlockControllerActionButton
          onClick={onRevealResult}
          icon={DoubleRightArrow}
          disable={processing}
        >
          Reveal Results
        </BlockControllerActionButton>
      }
    >
      <GameProgress {...props} revealResults />
    </ControllerLayout>
  );
}

function Outro(props: StageProps): JSX.Element | null {
  const { selectedBlock: block, playback } = props;
  const [inited, setInited] = useState(false);
  const mediaActionText = useControllerMediaPlayText(playback);
  const { switchMedia, play: playMedia } = playback.api;

  useEffect(() => {
    if (!inited) return;
    if (!playback.isVideoPlayed) return;

    next();
  }, [inited, playback.isVideoPlayed]);

  useEffect(() => {
    const media = BlockKnifeUtils.Media(block, 'outroMedia');
    if (media) {
      switchMedia(media || null, () => playMedia());
    } else {
      next();
    }

    setInited(true);
  }, [block, switchMedia, playMedia]);

  if (inited && playback.preview) {
    return (
      <ControllerLayout
        action={
          <BlockControllerActionNone icon={PlayIcon}>
            {mediaActionText}
          </BlockControllerActionNone>
        }
      >
        <GameProgress {...props} revealResults />
      </ControllerLayout>
    );
  }
  return null;
}

function Results(props: StageProps): JSX.Element {
  const onShowScoreboard = async () => {
    await next();
  };

  return (
    <ControllerLayout
      action={
        <>
          <BlockControllerActionButton
            onClick={onShowScoreboard}
            isSecondary={true}
          >
            Show Scoreboard
          </BlockControllerActionButton>
          <BlockControllerActionButton
            onClick={props.onEndBlock}
            icon={FilledSquareIcon}
          >
            End Block Sequence
          </BlockControllerActionButton>
        </>
      }
    >
      <GameProgress {...props} revealResults />
    </ControllerLayout>
  );
}

function Scoreboard(props: StageProps): JSX.Element {
  return (
    <ControllerLayout
      action={
        <BlockControllerActionButton
          onClick={props.onEndBlock}
          icon={FilledSquareIcon}
        >
          End Block Sequence
        </BlockControllerActionButton>
      }
    >
      <GameProgress {...props} revealResults />
    </ControllerLayout>
  );
}

export function AIChatBlockController(props: SharedProps): JSX.Element | null {
  const { selectedBlockIndex } = props;
  const block = useStableBlock(props.selectedBlock);
  const isEndedBlock = useIsEndedBlock(block.id);
  const gameSessionStatus =
    useGameSessionStatus<AIChatBlockGameSessionStatus>();
  const playback = useControllerMediaPlayback();

  if (selectedBlockIndex === null) return null;

  if (isEndedBlock) {
    return <Ended {...props} playback={playback} />;
  }

  switch (gameSessionStatus) {
    case AIChatBlockGameSessionStatus.LOADED:
      return <Loaded {...props} playback={playback} />;
    case AIChatBlockGameSessionStatus.INTRO:
      return <Intro {...props} playback={playback} />;
    case AIChatBlockGameSessionStatus.GAME_INIT:
      return <GameInit {...props} playback={playback} />;
    case AIChatBlockGameSessionStatus.GAME_START:
      return <GameStart {...props} playback={playback} />;
    case AIChatBlockGameSessionStatus.GAME_END:
      return <GameEnd {...props} playback={playback} />;
    case AIChatBlockGameSessionStatus.OUTRO:
      return <Outro {...props} playback={playback} />;
    case AIChatBlockGameSessionStatus.RESULTS:
      return <Results {...props} playback={playback} />;
    case AIChatBlockGameSessionStatus.SCOREBOARD:
      return <Scoreboard {...props} playback={playback} />;
    case AIChatBlockGameSessionStatus.END:
    case null:
    case undefined:
      return null;
    default:
      assertExhaustive(gameSessionStatus);
      return null;
  }
}
