import { useEffect, useState } from 'react';

import {
  EnumsExConnectStatus,
  EnumsExConnectType,
} from '@lp-lib/api-service-client/public';
import { assertExhaustive } from '@lp-lib/game';
import { MediaFormatVersion } from '@lp-lib/media';

import logger from '../../logger/logger';
import { apiService } from '../../services/api-service';
import {
  type Organization,
  type Organizer,
  OrganizerRole,
  type OrgConnectionBase,
  type OrgConnectionTypeInfo,
} from '../../types';
import { MediaUtils } from '../../utils/media';
import { DefaultLogoIcon } from '../icons/LogoIcon';
import { SlackIcon } from '../icons/SlackIcon';
import { TeamsIcon } from '../icons/TeamsIcon';

export const log = logger.scoped('organization');

export const getOrganizerDisplayName = (
  organizer: Organizer | null
): string => {
  if (!organizer) return 'N/A';

  return organizer.activated
    ? `${organizer.firstName} ${organizer.lastName}`
    : organizer.email;
};

export const getOrgOwner = async (orgId: string): Promise<Organizer | null> => {
  const pagination = apiService.organization.getOrganizers(orgId, {
    role: OrganizerRole.Owner,
  });
  const organizers = await pagination.next();
  if (!organizers || organizers.length === 0) return null;
  return organizers[0];
};

export const useOrgOwner = (orgId: string): Organizer | null => {
  const [admin, setAdmin] = useState<Organizer | null>(null);

  useEffect(() => {
    const init = async () => {
      const res = await getOrgOwner(orgId);
      setAdmin(res);
    };

    init();
  }, [orgId]);

  return admin;
};

function LogoIcon(props: { logoSrc: string | null; className?: string }) {
  const { logoSrc } = props;
  return logoSrc ? (
    <img
      src={logoSrc}
      className={`w-full h-full rounded ${props.className ?? ''}`}
      alt='logo'
    />
  ) : null;
}

export class OrgUtils {
  static GetConnTypeInfo(
    org: Organization,
    type: EnumsExConnectType | null | undefined
  ): OrgConnectionTypeInfo {
    switch (type) {
      case EnumsExConnectType.ExConnectTypeSlack:
        return { label: 'Slack', icon: SlackIcon };
      case EnumsExConnectType.ExConnectTypeTeams:
        return { label: 'Teams', icon: TeamsIcon };
      case EnumsExConnectType.ExConnectTypeLpOrgAdmin:
      case EnumsExConnectType.ExConnectTypeLpOrgAllMembers:
        const logoSrc = MediaUtils.PickMediaUrl(org.logo, {
          priority: [MediaFormatVersion.SM],
        });
        return {
          label: `${org.name}`,
          icon: (props) => <LogoIcon logoSrc={logoSrc} {...props} />,
        };
      case undefined:
      case null:
        return { label: 'Luna Park', icon: DefaultLogoIcon };
      default:
        assertExhaustive(type);
        throw new Error(`unknown connection type: ${type}`);
    }
  }

  static IsSlackActive(
    connection: OrgConnectionBase | null | undefined
  ): boolean {
    return (
      !!connection &&
      connection.type === EnumsExConnectType.ExConnectTypeSlack &&
      connection.status === EnumsExConnectStatus.ExConnectStatusActive
    );
  }
}
