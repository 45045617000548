import React, {
  type ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { type Media } from '@lp-lib/media';

import { useInstance } from '../../../../../hooks/useInstance';
import { Emitter } from '../../../../../utils/emitter';
import { type GamePlayEndedState, type GamePlayMedia } from './Internal';

export type GamePlayEndedEventFinishOptions = {
  animationMedia?: Media | null;
  muteAnimationMedia?: boolean;
  primaryTextNode?: ReactNode;
  secondaryTextNode?: ReactNode;
};

export type GamePlayEvents = {
  'ended-awaiting-goal-media': (
    blockId: string,
    state: 'finished',
    options: GamePlayEndedEventFinishOptions
  ) => void;
  ended: (
    blockId: string,
    state: GamePlayEndedState,
    options?: GamePlayEndedEventFinishOptions
  ) => void;
  'title-card-game-play-media-ended': (blockId: string) => void;
  'h2h-intro-media-ended': (blockId: string) => void;
};

type Context = {
  emitter: Emitter<GamePlayEvents>;

  // this is used to track the game play media from the block,
  // so that we can consume it somewhere else.
  gamePlayMedia: GamePlayMedia | null;
  setGamePlayMedia: (media: GamePlayMedia | null) => void;
};

const context = React.createContext<Nullable<Context, false>>(null);

function useGamePlayContext(): Context {
  const ctx = useContext(context);
  if (!ctx) throw new Error('GamePlayContext is not in the tree!');
  return ctx;
}

export function useGamePlayEmitter(): Context['emitter'] {
  return useGamePlayContext().emitter;
}

export function useGamePlayMedia(): Context['gamePlayMedia'] {
  const ctx = useGamePlayContext();
  return ctx.gamePlayMedia;
}

export function useTrackGamePlayMedia(input: GamePlayMedia | null) {
  const { setGamePlayMedia } = useGamePlayContext();
  useEffect(() => {
    setGamePlayMedia(input);
    return () => {
      setGamePlayMedia(null);
    };
  }, [input, setGamePlayMedia]);

  useEffect(() => {
    return () => {
      setGamePlayMedia(null);
    };
  }, [setGamePlayMedia]);
}

export function GamePlayProvider(props: {
  children?: ReactNode;
}): JSX.Element | null {
  const [gamePlayMedia, setGamePlayMedia] = useState<GamePlayMedia | null>(
    null
  );
  const emitter = useInstance(() => new Emitter<GamePlayEvents>());
  const ctxValue = useMemo(
    () => ({
      emitter,
      gamePlayMedia,
      setGamePlayMedia,
    }),
    [emitter, gamePlayMedia, setGamePlayMedia]
  );

  useEffect(() => {
    return () => {
      ctxValue.emitter.clear();
    };
  }, [ctxValue.emitter]);

  return <context.Provider value={ctxValue}>{props.children}</context.Provider>;
}
