import capitalize from 'lodash/capitalize';
import { useMemo } from 'react';
import Select, {
  components,
  type SingleValue,
  type SingleValueProps,
} from 'react-select';

import {
  type DtoProduct,
  EnumsOrgSubscriptionStatus,
  type ModelsPrice,
} from '@lp-lib/api-service-client/public';

import { getStaticAssetPath } from '../../utils/assets';
import { err2s } from '../../utils/common';
import { buildReactSelectStyles } from '../../utils/react-select';
import { Tooltip } from '../common/Tooltip';
import { type Option } from '../common/Utilities';
import { Loading } from '../Loading';
import { useProducts } from '../Product/useProducts';
import { useNavigateToSubscriptionChange } from '../Product/utils';

type ProductOption = Option<{ product: DtoProduct; price: ModelsPrice | null }>;

const SingleValueComp = ({
  children,
  ...props
}: SingleValueProps<ProductOption>) => {
  return (
    <components.SingleValue {...props}>
      {props.data.value.product.name}
    </components.SingleValue>
  );
};

export function OrgSubscriptionProductSelect(props: {
  value: { productId?: string | null; priceId?: string | null };
  onChange: (value: {
    productId: string | null;
    priceId: string | null;
  }) => void;
}): JSX.Element {
  const { value, onChange } = props;

  const styles = useMemo(
    () =>
      buildReactSelectStyles<ProductOption>({
        override: {
          control: {
            width: '100%',
            height: '100%',
          },
          groupHeading: {
            fontSize: '0.75rem',
            fontWeight: 600,
            color: '#4A4A4A',
            textTransform: 'uppercase',
            letterSpacing: '0.05rem',
          },
        },
      }),
    []
  );

  // note(falcon): not using an async select component because there's no search really.
  const { data, isLoading, error } = useProducts();
  const options = useMemo(() => {
    if (!data) return [];
    return data.published.map((d) => {
      const prices = (d.prices ?? []).filter((p) => !p.archived);

      if (prices.length === 0) {
        return {
          label: d.name,
          value: { product: d, price: null },
        };
      }

      const opts: ProductOption[] = prices.map((p) => {
        return {
          label: `${p.maxSeats} Seats – $${p.amount}`,
          value: { product: d, price: p },
        };
      });

      opts.push({
        label: 'Custom Price',
        value: { product: d, price: null },
      });

      return {
        label: d.name,
        options: opts,
      };
    });
  }, [data]);

  const selected: ProductOption | null = useMemo(() => {
    if (!data) return null;
    const product = [...data.published, ...data.archived].find(
      (p) => p.id === value.productId
    );
    if (!product) return null;
    const price = product.prices?.find((p) => p.id === value.priceId);
    return {
      label: product.name,
      value: { product, price: price ?? null },
    };
  }, [data, value.priceId, value.productId]);

  const handleChange = (opt: SingleValue<ProductOption>) => {
    if (!opt) return;
    const { product, price } = opt.value;
    onChange({ productId: product.id, priceId: price?.id ?? null });
  };

  if (isLoading) {
    return (
      <div className='w-full flex items-center justify-center'>
        <Loading text='' />
      </div>
    );
  }

  if (error) {
    return (
      <div className='w-full flex items-center justify-center'>
        <p className='text-red-001'>{err2s(error)}</p>
      </div>
    );
  }

  return (
    <Select<ProductOption, false>
      styles={styles}
      classNamePrefix='select-box-v2'
      className='w-full h-full'
      value={selected}
      options={options}
      components={{ SingleValue: SingleValueComp }}
      onChange={handleChange}
      isSearchable
      isMulti={false}
    />
  );
}

const subscriptionStatusOptions = Object.values(EnumsOrgSubscriptionStatus).map(
  (t) => ({
    label: capitalize(t.toString()),
    value: t,
  })
);

export function OrgSubscriptionStatusSelect(props: {
  value: EnumsOrgSubscriptionStatus;
  onChange: (value: EnumsOrgSubscriptionStatus) => void;
  className?: string;
}): JSX.Element {
  const { value, onChange, className } = props;

  const styles = useMemo(
    () =>
      buildReactSelectStyles<Option<EnumsOrgSubscriptionStatus>>({
        override: {
          control: {
            width: '100%',
            height: '100%',
          },
        },
      }),
    []
  );

  const selected = useMemo(() => {
    return subscriptionStatusOptions.find((o) => o.value === value) ?? null;
  }, [value]);

  return (
    <Select<Option<EnumsOrgSubscriptionStatus>, false>
      className={className}
      classNamePrefix='select-box-v2'
      styles={styles}
      value={selected}
      options={subscriptionStatusOptions}
      onChange={(v) => v && onChange(v.value)}
      isSearchable={false}
      isMulti={false}
    />
  );
}

const CROWN_ICON_URL = getStaticAssetPath('images/crown.png');

export function OrgSubscriptionCrownIcon(props: {
  className?: string;
}): JSX.Element {
  return (
    <img
      src={CROWN_ICON_URL}
      alt='icon'
      className={props.className || 'w-9 h-9'}
    />
  );
}

export function OrgSubscriptionUpgradeButton(props: {
  text?: string;
  disabled?: boolean;
  className?: string;
  innerClassName?: string;
  openUrl?: (url: string) => void;
}): JSX.Element | null {
  const navigate = useNavigateToSubscriptionChange(props.openUrl);
  const handleClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    navigate();
  };

  return (
    <button
      type='button'
      onClick={handleClick}
      disabled={props.disabled}
      className={`btn rounded-xl bg-gradient-to-bl from-pairing-start to-pairing-end flex-none ${
        props.className || 'w-35 h-10 p-0.5'
      }`}
    >
      <p
        className={`w-full h-full rounded-xl 
          bg-main-layer text-white text-medium
            flex justify-center items-center
          ${props.innerClassName}`}
      >
        <OrgSubscriptionCrownIcon className='w-9 h-9' />
        {props.text || 'Upgrade'}
      </p>
    </button>
  );
}

export function OrgSubscriptionUpgradeIcon(props: {
  className?: string;
}): JSX.Element {
  return (
    <div className='relative group-premium flex justify-center'>
      <div
        className={`rounded-full bg-gradient-to-bl from-pairing-start to-pairing-end flex-none ${
          props.className || 'w-5 h-5 p-0.25'
        }`}
      >
        <div className='w-full h-full rounded-full bg-main-layer'>
          <OrgSubscriptionCrownIcon className='w-full h-full' />
        </div>
      </div>

      <div className='absolute invisible group-premium-hover:visible z-5 bottom-full'>
        <Tooltip
          position={'top'}
          backgroundColor='black'
          borderRadius={4}
          arrowWidth={4}
          borderColor={'rgba(255, 255, 255, 0.4)'}
          borderWidth={1}
        >
          <p className='px-2 py-1 text-3xs whitespace-nowrap text-white'>
            Premium Feature
          </p>
        </Tooltip>
      </div>
    </div>
  );
}
