import { match } from 'ts-pattern';

import {
  EnumsH2HCardSource,
  EnumsH2HJudgingMode,
  EnumsH2HJudgingSentiment,
  EnumsH2HMode,
  EnumsH2HSelectPlayerStrategy,
} from '@lp-lib/api-service-client/public';
import {
  type HeadToHeadBlock,
  type HeadToHeadCard,
  type HeadToHeadCardPrompt,
} from '@lp-lib/game';

import { useLiveCallback } from '../../../../hooks/useLiveCallback';
import { getLogger } from '../../../../logger/logger';
import { fromMediaDataDTO, fromMediaDTO } from '../../../../utils/api-dto';
import { getStaticAssetPath } from '../../../../utils/assets';
import { hashObject } from '../../../../utils/hash-object';
import { loadImageAsPromise } from '../../../../utils/media';
import { type FirebaseService, FirebaseValueHandle } from '../../../Firebase';
import { getSynchronousRawAnchorRect } from '../../../LayoutAnchors/LayoutAnchors';
import { VariableRegistry } from '../../../VoiceOver/VariableRegistry';
import {
  type H2HGamePlayCard,
  type H2HGamePlayCardPrompt,
  type H2HGameRoot,
  type H2HParticipantRole,
  type H2HVoiceOverVariableKeys,
  type PromptVisibility,
  type RolePrompt,
} from './types';

export const log = getLogger().scoped('head-to-head');

export class HeadToHeadFBUtils {
  static Path(
    venueId: string,
    kind:
      | 'root'
      | 'cards'
      | 'progress'
      | 'info'
      | 'group-a-emoji'
      | 'group-b-emoji'
  ): string {
    if (kind === 'root') return `head-to-head/${venueId}`;
    return `head-to-head/${venueId}/${kind}`;
  }

  static RootHandle(
    svc: FirebaseService,
    venueId: string
  ): FirebaseValueHandle<H2HGameRoot> {
    return new FirebaseValueHandle(
      svc.prefixedSafeRef(this.Path(venueId, 'root'))
    );
  }

  static CardsHandle(
    svc: FirebaseService,
    venueId: string
  ): FirebaseValueHandle<H2HGameRoot['cards']> {
    return new FirebaseValueHandle(
      svc.prefixedSafeRef(this.Path(venueId, 'cards'))
    );
  }

  static ProgressHandle(
    svc: FirebaseService,
    venueId: string
  ): FirebaseValueHandle<H2HGameRoot['progress']> {
    return new FirebaseValueHandle(
      svc.prefixedSafeRef(this.Path(venueId, 'progress'))
    );
  }

  static InfoHandle(
    svc: FirebaseService,
    venueId: string
  ): FirebaseValueHandle<H2HGameRoot['info']> {
    return new FirebaseValueHandle(
      svc.prefixedSafeRef(this.Path(venueId, 'info'))
    );
  }
}

export class HeadToHeadUtils {
  static async CardToGamePlay(
    card: HeadToHeadCard,
    index: number
  ): Promise<H2HGamePlayCard> {
    const rolePrompts: RolePrompt[] = [
      {
        role: 'audience',
        prompt: card.audience,
      },
      {
        role: 'groupA',
        prompt: card.groupA,
      },
      {
        role: 'groupB',
        prompt: card.groupB,
      },
    ];
    const customized = rolePrompts.some(
      (e) => e.prompt?.source === EnumsH2HCardSource.H2HCardSourceMyCard
    );

    const makeGamePlayPrompt = async (
      prompt: HeadToHeadCardPrompt | null,
      visibility: PromptVisibility[]
    ) => {
      const p: H2HGamePlayCardPrompt = {
        text: prompt?.text || '',
        visibility,
      };
      const mediaId = prompt?.cover?.data?.id;
      const mediaType = prompt?.cover?.media?.type;
      if (mediaId && mediaType) {
        p.mediaId = mediaId;
        p.mediaType = mediaType;
      }
      if (prompt?.voiceOver?.runtime) {
        p.voRuntimeHash = await hashObject(prompt.voiceOver.runtime);
      }
      return p;
    };

    const prompts = await match(customized)
      .with(true, async () => ({
        audience: await makeGamePlayPrompt(card.audience, ['self']),
        groupA: await makeGamePlayPrompt(card.groupA, ['self']),
        groupB: await makeGamePlayPrompt(card.groupB, ['self']),
      }))
      .otherwise(async () => {
        const visibility = rolePrompts
          .filter(
            (e) =>
              e.prompt?.source === EnumsH2HCardSource.H2HCardSourceDefaultCard
          )
          .map((e) => e.role);
        return {
          audience: await makeGamePlayPrompt(card.default, visibility),
          groupA: await makeGamePlayPrompt(card.default, visibility),
          groupB: await makeGamePlayPrompt(card.default, visibility),
        };
      });

    return {
      id: card.id,
      index,
      phase: 'ready',
      ...prompts,
    };
  }

  static SingleMode(block: HeadToHeadBlock) {
    return (
      block.fields.selectPlayerStrategy ===
      EnumsH2HSelectPlayerStrategy.H2HSelectPlayerStrategyRandomPlayer
    );
  }

  static TurnsMode(block: HeadToHeadBlock) {
    return block.fields.gameMode === EnumsH2HMode.H2HModeTurns;
  }

  static LookupCardMediaAssets(
    block: HeadToHeadBlock,
    mediaId: string | undefined
  ) {
    if (mediaId) {
      for (const card of block.fields.cards) {
        const prompts = [card.default, card.audience, card.groupA, card.groupB];
        for (const prompt of prompts) {
          if (prompt?.cover?.data?.id === mediaId) {
            return {
              media: fromMediaDTO(prompt.cover?.media),
              mediaData: fromMediaDataDTO(prompt.cover?.data),
            };
          }
        }
      }
    }
    return { media: null, mediaData: null };
  }

  static async LookupCardRuntimeVoiceOver(
    block: HeadToHeadBlock,
    cardId: string,
    hash: string | undefined
  ) {
    if (!hash) return;
    const card = block.fields.cards.find((e) => e.id === cardId);
    if (!card) return;
    const prompts = [card.default, card.audience, card.groupA, card.groupB];
    for (const prompt of prompts) {
      if (!prompt?.voiceOver?.runtime) continue;
      const hashHex = await hashObject(prompt.voiceOver.runtime);
      if (hash === hashHex) return prompt.voiceOver.runtime;
    }
  }

  static WhoCanSee(prompt: H2HGamePlayCardPrompt, role: H2HParticipantRole) {
    if (prompt.visibility.includes('self')) return '';
    return (
      match(role)
        .with('audience', () => {
          const aVisible = prompt.visibility.includes('groupA');
          const bVisible = prompt.visibility.includes('groupB');
          if (aVisible && bVisible) {
            return 'Only the person on stage can see it';
          } else if (aVisible) {
            return 'Only pink team can see it';
          } else if (bVisible) {
            return 'Only blue team can see it';
          } else {
            return 'You can not see this card';
          }
        })
        // on stage person only needs to know their own visibility
        .otherwise(() => 'You can not see this card')
    );
  }

  static WhoCanNotSee(prompt: H2HGamePlayCardPrompt, role: H2HParticipantRole) {
    if (prompt.visibility.includes('self')) return '';
    return (
      match(role)
        .with('audience', () => {
          const aInvisible = !prompt.visibility.includes('groupA');
          const bInvisible = !prompt.visibility.includes('groupB');
          if (aInvisible && bInvisible) {
            return 'The person on stage can not see this';
          } else if (aInvisible) {
            return 'The pink team can not see it';
          } else if (bInvisible) {
            return 'The blue team can not see it';
          } else {
            return '';
          }
        })
        // on stage person only needs to know their own visibility
        .otherwise(() => '')
    );
  }

  static JudgingEnabled(block: HeadToHeadBlock) {
    return (
      block.fields.judgingMode !== EnumsH2HJudgingMode.H2HJudgingModeDisabled
    );
  }

  static JudgingAfterGame(block: HeadToHeadBlock) {
    return (
      block.fields.judgingMode ===
      EnumsH2HJudgingMode.H2HJudgingModeEndOfGamePlay
    );
  }

  static JudgingDuringGame(block: HeadToHeadBlock) {
    return (
      block.fields.judgingMode ===
      EnumsH2HJudgingMode.H2HJudgingModeDuringGamePlay
    );
  }

  static async PreloadAssets() {
    try {
      await Promise.all([
        loadImageAsPromise(getStaticAssetPath('images/winner-crown.png')),
      ]);
    } catch (error) {
      log.error("couldn't preload assets", error);
    }
  }

  static TimeWarn(percentage: number, threshold = 1 / 3) {
    return percentage > 0 && percentage < threshold;
  }

  static GroupColor(group: 'groupA' | 'groupB') {
    return group === 'groupA' ? '#FF3975' : '#3988FF';
  }

  static GroupColorForTW(group: 'groupA' | 'groupB') {
    return group === 'groupA' ? 'bg-[#FF3975]' : 'bg-[#3988FF]';
  }

  static GroupName(group: 'groupA' | 'groupB') {
    return group === 'groupA' ? 'Pink Side' : 'Blue Side';
  }

  static JudgingSentiment(block: HeadToHeadBlock) {
    if (this.JudgingAfterGame(block)) return 1;
    return block.fields.judgingInGameSentiment ===
      EnumsH2HJudgingSentiment.H2HJudgingSentimentPositive
      ? 1
      : -1;
  }

  static PromptByRole(
    card: H2HGamePlayCard,
    turnMode: boolean,
    role: H2HParticipantRole,
    nextTurn: H2HParticipantRole
  ) {
    if (turnMode) {
      if (role === 'audience') return card.audience;
      return role === nextTurn ? card.groupA : card.groupB;
    } else {
      if (role === 'groupA') return card.groupA;
      if (role === 'groupB') return card.groupB;
      return card.audience;
    }
  }
}

type GroupAnchorId = 'h2h-group-a-anchor' | 'h2h-group-b-anchor';

function getGroupBoundingRect(anchorId: GroupAnchorId) {
  return () => {
    const rect = getSynchronousRawAnchorRect(anchorId);
    const width = rect?.width ?? 0;
    const height = window.innerHeight - (rect?.top ?? 0);
    const bottom = window.innerHeight - 60;
    const left = rect?.left ?? 0;
    const renderRect = new DOMRectReadOnly(
      left,
      bottom - height,
      width,
      height
    );
    return renderRect;
  };
}

export function useGetGroupBoundingRect(anchorId: GroupAnchorId) {
  return useLiveCallback(getGroupBoundingRect(anchorId));
}

export function makeExampleHeadToHeadVariables(): VariableRegistry {
  const examples: Record<H2HVoiceOverVariableKeys, string> = {
    pinkSideNames: 'Pink Side',
    blueSideNames: 'Blue Side',
    coordinatorName: 'Organizer',
  };
  return VariableRegistry.FromRecord(examples);
}
