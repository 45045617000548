import { type QuestionBlock } from '@lp-lib/game';

import { useNextPersistentPointsScore } from '../../../PersistentPoints/Provider';
import { useMyTeamId } from '../../../Player';
import { useGameSessionBlock } from '../../hooks';
import { GamePlayAnswerLayout } from '../Common/GamePlay/GamePlayAnswer';
import { useQuestionCorrectAnswerRatioData } from './hooks';

export function QuestionAnswer(): JSX.Element | null {
  const nextScoreDisplay = Math.round(useNextPersistentPointsScore() ?? 0);

  const { data: ratioData, resolved } = useQuestionCorrectAnswerRatioData();

  const teamId = useMyTeamId();
  const gameSessionBlock = useGameSessionBlock() as QuestionBlock | null;

  return (
    <GamePlayAnswerLayout>
      {resolved && (
        <>
          {' '}
          <p className='font-cairo text-3xl'>The Correct Answer:</p>
          <p className='max-w-4xl max-h-48 font-cairo font-extrabold text-6xl break-words line-clamp-3 overflow-hidden leading-normal text-center'>
            {gameSessionBlock?.fields.answer}
          </p>
          {ratioData && (
            <p className='font-cairo text-lg'>
              {`${ratioData.corrects} out of ${ratioData.total} Teams got it correct! (${ratioData.percentage}%)`}
            </p>
          )}
          <div className='mt-10 mb-5 text-3xl h-12 font-cairo text-tertiary'>
            {teamId ? (
              <div className='flex items-center justify-center gap-2'>
                {`Your Team Scored ${nextScoreDisplay} points!`}{' '}
              </div>
            ) : (
              `You Scored ${nextScoreDisplay} Points!`
            )}
          </div>
        </>
      )}
    </GamePlayAnswerLayout>
  );
}
