import logger from '../../logger/logger';

export const log = logger.scoped('pairing');

export const percentage = (numerator: number, denominator: number): string => {
  return denominator > 0
    ? `${Math.round(
        (numerator / denominator) * 100
      )}% (${numerator}/${denominator})`
    : '0/0';
};
