import {
  getAudioContext,
  getEchoCancelledAudioDestination,
} from './audio-context';
import { type VolumeController } from './types';

export class VideoEchoCanceller implements VolumeController {
  private gain?: GainNode;
  private mesnMap = new Map<HTMLVideoElement, MediaElementAudioSourceNode>();
  private mssnMap = new Map<MediaStream, MediaStreamAudioSourceNode>();
  private latestAudioSource?: HTMLVideoElement | MediaStream;

  updateVolume(volume: number): void {
    if (!this.gain) return;
    this.gain.gain.value = volume;
  }

  private getSourceNode(
    audioSource: HTMLVideoElement | MediaStream
  ): AudioNode | undefined {
    if ('getTracks' in audioSource) {
      return this.mssnMap.get(audioSource);
    } else {
      return this.mesnMap.get(audioSource);
    }
  }

  private createSourceNode(
    audioSource: HTMLVideoElement | MediaStream
  ): AudioNode {
    const audioCtx = getAudioContext();
    if ('getTracks' in audioSource) {
      const mssn = audioCtx.createMediaStreamSource(audioSource);
      this.mssnMap.set(audioSource, mssn);
      return mssn;
    } else {
      const mesn = audioCtx.createMediaElementSource(audioSource);
      this.mesnMap.set(audioSource, mesn);
      return mesn;
    }
  }

  private getGainNode() {
    const audioCtx = getAudioContext();
    if (!this.gain) {
      this.gain = audioCtx.createGain();
    }
    return this.gain;
  }

  attach(
    audioSource: HTMLVideoElement | MediaStream,
    initVolume: number
  ): void {
    this.detach();
    let audioNode = this.getSourceNode(audioSource);
    if (!audioNode) audioNode = this.createSourceNode(audioSource);
    const gain = this.getGainNode();
    gain.gain.value = initVolume;
    audioNode.connect(gain);
    gain.connect(getEchoCancelledAudioDestination());
    this.latestAudioSource = audioSource;
  }

  detach(): void {
    if (this.gain) {
      this.gain.disconnect();
    }
    if (this.latestAudioSource) {
      const audioNode = this.getSourceNode(this.latestAudioSource);
      if (audioNode) audioNode.disconnect();
    }
  }

  dispose(): void {
    this.detach();
    if (this.gain) this.gain = undefined;
    this.mesnMap.clear();
    this.mssnMap.clear();
  }
}
