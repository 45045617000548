import { useMemo } from 'react';

import { isServerValue } from '@lp-lib/firebase-typesafe';
import {
  calculateScore,
  type GameSessionPlayerData,
  type MultipleChoiceBlock,
  type MultipleChoiceBlockDetailScore,
  QuestionBlockAnswerGrade,
  type QuestionBlockDetailScore,
  type TeamDataList,
} from '@lp-lib/game';
import { type Logger } from '@lp-lib/logger-base';

import logger from '../../../../logger/logger';
import { type User } from '../../../../types';
import { type FirebaseService, useFirebaseContext } from '../../../Firebase';
import { useVenueId } from '../../../Venue/VenueProvider';
import { BlockOutputDAO } from '../Common/block-outputs';

export class MutipleChoiceGradeAPI {
  constructor(
    readonly venueId: string,
    readonly block: MultipleChoiceBlock,
    private svc: FirebaseService,
    readonly gameaudit: Logger,
    private userSubmissionsRef = svc.prefixedSafeRef<{
      [key: User['id']]: GameSessionPlayerData;
    }>(`game-session-player-data/${venueId}/${block.id}`),
    private teamScoresRef = svc.prefixedRef<
      TeamDataList<QuestionBlockDetailScore>
    >(`game-session-scores/${venueId}/${block.id}`)
  ) {}

  start(): void {
    this.userSubmissionsRef.on('child_added', (snapshot) => {
      const userId = snapshot.key;
      const data = snapshot.val();
      if (!userId || !data) {
        this.gameaudit.warn(`recv empty answer / userId`, {
          blockId: this.block.id,
          venueId: this.venueId,
          userId,
          data,
        });
        return;
      }
      this.grade(userId, data);
      // store the output for this block: last write wins
      new BlockOutputDAO(this.venueId, this.svc).writeForBlock(
        this.block.id,
        String(data.answer)
      );
    });
  }

  stop(): void {
    this.userSubmissionsRef.off();
  }

  private async grade(userId: string, data: GameSessionPlayerData) {
    const { answer, teamId, timerWhenSubmitted, submittedAt } = data;

    if (!answer || !teamId) return;

    const correctChoice = this.block.fields.answerChoices.find(
      (choice) => choice.correct
    );

    if (!correctChoice) {
      this.gameaudit.info(
        `misconfigured multiple choice block, no correct choice`,
        {
          blockId: this.block.id,
          venueId: this.venueId,
          userId,
          retrievedBlockId: this.block.id,
          retrievedBlockType: this.block.type,
          ...data,
        }
      );
    }

    let score = null;
    let grade = QuestionBlockAnswerGrade.NONE;

    // these are not user inputted submission so we expect an exact match.
    if (correctChoice && answer === correctChoice.text) {
      grade = QuestionBlockAnswerGrade.CORRECT;
      score = calculateScore(
        grade,
        this.block.fields.decreasingPointsTimer,
        timerWhenSubmitted || 0,
        this.block.fields.questionTimeSec,
        this.block.fields.points
      );

      this.gameaudit.info(`answer scored, matched correct choice index`, {
        blockId: this.block.id,
        venueId: this.venueId,
        userId,
        grade,
        score,
        ...data,
      });
    }

    const detail: Partial<MultipleChoiceBlockDetailScore> = {
      answer,
      score,
      grade,
      submitterUid: userId,
      submittedAt: isServerValue(submittedAt) ? undefined : submittedAt,
      timerWhenSubmitted,
    };

    await this.teamScoresRef.child(teamId).update(detail);

    this.gameaudit.info(`wrote team detail score`, {
      blockId: this.block.id,
      venueId: this.venueId,
      userId,
      teamId,
      ...detail,
    });
  }
}

export function useGradeAPI(block: MultipleChoiceBlock) {
  const venueId = useVenueId();
  const { svc } = useFirebaseContext();
  return useMemo(
    () =>
      new MutipleChoiceGradeAPI(
        venueId,
        block,
        svc,
        logger.scoped('game-system-audit')
      ),
    [block, svc, venueId]
  );
}
