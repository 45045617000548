import { useEffect, useState } from 'react';

import { type Block, GameSessionUtil } from '@lp-lib/game';

import { useIsMounted } from '../../../../../hooks/useIsMounted';
import logger from '../../../../../logger/logger';
import { BrowserIntervalCtrl } from '../../../../../utils/BrowserIntervalCtrl';
import { TimeUtils } from '../../../../../utils/time';
import {
  ConfirmCancelModalText,
  useAwaitFullScreenConfirmCancelModal,
} from '../../../../ConfirmCancelModalContext';
import { DoubleRightArrow } from '../../../../icons/Arrows';
import { PlayIcon } from '../../../../icons/PlayIcon';
import { TimerIcon } from '../../../../icons/TimerIcon';
import { useIsStreamSessionAlive } from '../../../../Session';
import {
  useBlockTitleAnimInfo,
  useSessionStatusHookManager,
} from '../../../hooks';
import { next, type SessionStatusHook } from '../../../store';

const log = logger.scoped('game-controller-action-btn');

export const BlockControllerActionButton = (props: {
  onClick: () => void;
  icon?: (props: { className: string }) => JSX.Element;
  isSecondary?: boolean;
  isDelete?: boolean;
  disable?: boolean;
  children: JSX.Element | JSX.Element[] | string | string[];
  testId?: string;
}): JSX.Element => {
  const { isSecondary = false, isDelete = false } = props;
  const isSessionAlive = useIsStreamSessionAlive();
  const Icon = props.icon;
  const buttonClassName = isSecondary
    ? 'w-full h-4 mb-2 text-sms text-tertiary font-medium flex flex-row justify-center items-center appearance-none outline-none focus:outline-none'
    : `${
        isDelete ? 'btn-delete' : 'btn-primary'
      } w-full h-10 flex-shrink-0 flex flex-row justify-start items-center`;
  const childrenClassName = isSecondary ? '' : 'ml-2';

  const id = `controller-action-${isSecondary ? 'secondary' : 'primary'}`;

  return (
    <button
      id={id}
      data-testid={props.testId ?? id}
      className={buttonClassName}
      disabled={!isSessionAlive || props.disable}
      onClick={() => {
        log.debug('click', {
          isSecondary,
          label: props.children.toString(),
          isDelete,
        });
        props.onClick();
      }}
    >
      {Icon && <Icon className='w-4 h-4 ml-3 fill-current' />}
      <span className={childrenClassName}>{props.children}</span>
    </button>
  );
};

export const BlockControllerActionNone = (props: {
  children: React.ReactNode | null;
  icon: (props: { className: string }) => JSX.Element;
}): JSX.Element => {
  const Icon = props.icon;
  return (
    <div
      data-testid='block-controller-action-none'
      className='w-full h-10 px-3 flex-shrink-0 flex flex-row justify-start items-center gap-2 border border-secondary rounded-lg text-white text-sms'
    >
      <Icon className='w-4 h-4 fill-current' />

      {props.children}
    </div>
  );
};

function defaultTimeRemainingLabel(
  remainingSec: number,
  durationFormattedMMSS?: boolean
) {
  return `Time Remaining (${
    durationFormattedMMSS
      ? TimeUtils.DurationFormattedHHMMSS(remainingSec * 1000)
      : `${remainingSec}s`
  })`;
}

export const BlockControllerActionTimeRemaining = (props: {
  remainingSec: number | null;
  durationFormattedMMSS?: boolean;
  customizeLabel?: (remainingSec: number) => string;
  disableSkip?: boolean;
  onSkip?: () => void;
}): JSX.Element => {
  const triggerModal = useAwaitFullScreenConfirmCancelModal();
  const isMounted = useIsMounted();
  const remainingSec = props.remainingSec ?? 0;

  const handleClick = async () => {
    if (props.disableSkip) return;
    const response = await triggerModal({
      kind: 'confirm-cancel',
      prompt: (
        <ConfirmCancelModalText className='text-2xl font-medium'>
          Skip remaining time and progress to next step?
        </ConfirmCancelModalText>
      ),
      confirmBtnLabel: 'Yes',
      confirmBtnVariant: 'delete',
      cancelBtnLabel: 'Cancel',
    });
    if (response.result !== 'confirmed') return;
    if (!isMounted()) return;

    if (props.onSkip) {
      props.onSkip();
    } else {
      next();
    }
  };

  const label = props.customizeLabel
    ? props.customizeLabel(remainingSec)
    : defaultTimeRemainingLabel(remainingSec, props.durationFormattedMMSS);

  return (
    <BlockControllerActionNone icon={TimerIcon}>
      <div className='flex flex-1 items-center justify-between'>
        <p>{label}</p>

        {!props.disableSkip && (
          <button
            onClick={handleClick}
            disabled={!props.remainingSec}
            className='btn w-5 h-5 rounded-full bg-red-600 flex justify-center items-center'
          >
            <DoubleRightArrow />
          </button>
        )}
      </div>
    </BlockControllerActionNone>
  );
};

export function useBlockControllerBlockTitlePlaying(
  block: Block
): JSX.Element | null {
  const [count, setCount] = useState<null | number>(null);
  const blockTitleAnimInfo = useBlockTitleAnimInfo();

  useEffect(() => {
    const ctrl = new BrowserIntervalCtrl();

    if (
      blockTitleAnimInfo?.state === 'running' &&
      blockTitleAnimInfo.durationMs
    ) {
      // Set the initial countdown duration and start ticking!
      setCount(Math.floor(blockTitleAnimInfo.durationMs / 1000));
      ctrl.set(() => setCount((c) => (c !== null ? --c : null)), 1000);
    }

    return () => {
      ctrl.clear();
    };
  }, [blockTitleAnimInfo?.durationMs, blockTitleAnimInfo?.state]);

  const statusHookMan = useSessionStatusHookManager();
  useEffect(() => {
    const map = GameSessionUtil.StatusMapFor(block);
    if (!map) return;

    // Register a hook to ensure that the Present Block button doesn't flicker
    // between "Block Title Playing (0)" -> "Present Block" -> "Video Playing".
    // Without this, there is a moment when the user could potentially click the
    // Primary Controller button again, after the Title animation has finished
    // and before the block media (or whatever the block is going to do
    // on-present) has started. Since this is primarily here for the Live Host
    // and not Ond, it is not included or coordinated from the `present()` core
    // action itself.

    const hook: SessionStatusHook = {
      blockId: block.id,
      sessionStatus: map.intro,
      after: async () => setCount(null),
    };

    statusHookMan.register(hook);

    return () => {
      statusHookMan.unregister(hook);
    };
  }, [block, statusHookMan]);

  return count === null ? null : (
    <BlockControllerActionNone icon={PlayIcon}>
      Block Title Playing ({String(count)}s)
    </BlockControllerActionNone>
  );
}
