export function diffMaps<K, V>(
  prev: Map<K, V>,
  curr: Map<K, V>
): { added: K[]; removed: K[] } {
  const added = [];
  const removed = [];

  for (const key of curr.keys()) {
    if (!prev.has(key)) added.push(key);
  }

  for (const key of prev.keys()) {
    if (!curr.has(key)) removed.push(key);
  }

  return { added, removed };
}
