import { useEffect, useState } from 'react';

import { VariableRegistry } from '../../VoiceOver/VariableRegistry';
import { useLocalGamePlayStore } from '../GamePlayStore';

export function useNewVariableRegistry() {
  const [variableRegistry] = useState(() => new VariableRegistry());
  return variableRegistry;
}

export function useNewVariableRegistryWithCommonVariables() {
  const variableRegistry = useNewVariableRegistry();

  const gamePlayStore = useLocalGamePlayStore();
  useEffect(() => {
    variableRegistry.set('gamePackTitle', async () => {
      return gamePlayStore.getLoadedGameLike()?.name ?? '';
    });
  }, [gamePlayStore, variableRegistry]);

  return variableRegistry;
}
