import { type Uppy } from '@uppy/core';
import { useEffect, useRef } from 'react';

type UppyMap = {
  [key: string]: Uppy;
};

function useUppy(uppyId: string, factory: () => Uppy): Uppy {
  const uppy = useRef<UppyMap | undefined>(undefined);

  if (uppy.current === undefined) {
    uppy.current = {
      [uppyId]: factory(),
    };
  }

  if (!uppy.current[uppyId]) {
    uppy.current[uppyId] = factory();
  }

  useEffect(() => {
    return () => {
      if (uppy?.current) {
        Object.values(uppy.current).forEach((u) => {
          u.close();
        });
      }
    };
  }, []);

  return uppy.current[uppyId];
}

export { useUppy };
