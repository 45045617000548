import { useEffect, useMemo, useState } from 'react';
import { useMountedState } from 'react-use';
import { useSnapshot } from 'valtio';

import {
  QuestionBlockAnswerGrade,
  type QuestionBlockDetailScore,
  QuestionBlockGameSessionStatus,
  type TeamDataList,
} from '@lp-lib/game';

import { useTeams } from '../../../TeamAPI/TeamV1';
import { useGameSessionStatus } from '../../hooks';
import { fetchDetailScoresData, gameSessionStore } from '../../store';

export interface QuestionTeamData {
  teamId: string;
  userId: string | null;
  answer: string | null;
  teamName: string | null;
  score: number | null;
  totalScore: number | null;
  scoreOverride: number | null;
}

export interface QuestionRatioData {
  corrects: number;
  total: number;
  percentage: number;
}

export const useQuestionCorrectAnswerRatioData = (): {
  data: QuestionRatioData | null;
  resolved: boolean;
} => {
  const [ratioData, setRatioData] = useState<{
    data: QuestionRatioData | null;
    resolved: boolean;
  }>({ data: null, resolved: false });
  const blockSession = useSnapshot(gameSessionStore).session.blockSession;
  const blockId = blockSession?.block?.id ?? null;
  const isMounted = useMountedState();
  const teams = useTeams({
    active: true,
    updateStaffTeam: true,
    excludeStaffTeam: true,
  });

  useEffect(() => {
    async function run() {
      let data: QuestionRatioData = { corrects: 0, total: 0, percentage: 0 };

      if (blockId) {
        const blockDetailScores = (
          gameSessionStore.detailScores
            ? gameSessionStore.detailScores[blockId]
            : await fetchDetailScoresData(blockId)
        ) as TeamDataList<QuestionBlockDetailScore> | null;

        const blockDetailScoresTeamIds = Object.keys(blockDetailScores || {});

        if (
          blockDetailScores &&
          blockDetailScoresTeamIds.length > 0 &&
          teams.length > 0
        ) {
          let corrects = 0;
          blockDetailScoresTeamIds.forEach((teamId) => {
            if (
              blockDetailScores[teamId].grade ===
              QuestionBlockAnswerGrade.CORRECT
            )
              corrects++;
          });

          data = {
            corrects,
            total: teams.length,
            percentage: Math.round((corrects / teams.length) * 100),
          };
        }
      }

      if (isMounted()) setRatioData({ data, resolved: true });
    }
    run();
  }, [blockId, isMounted, teams.length]);

  return ratioData;
};

export const useQuestionTeamData = (
  selectedBlockId?: string | null
): {
  questionData: QuestionTeamData[];
  totalTeamCount: number;
  answeredTeamCount: number;
} => {
  const teamList = useTeams();

  const store = useSnapshot(gameSessionStore);
  const session = store.session;
  const blockId = selectedBlockId || (session.blockSession?.block?.id ?? null);
  const detailScores = store.detailScores;
  const scoreSummary = store.scoreSummary;
  const blockDetailScores = blockId ? detailScores?.[blockId] ?? null : null;
  const gameSessionStatus =
    useGameSessionStatus<QuestionBlockGameSessionStatus>();
  const sortBy =
    gameSessionStatus &&
    gameSessionStatus >= QuestionBlockGameSessionStatus.ANSWER
      ? 'totalScore'
      : undefined;

  return useMemo(() => {
    const questionData: QuestionTeamData[] = [];
    let answeredTeamCount = 0;

    teamList.forEach((t) => {
      const detailScore = blockDetailScores?.[
        t.id
      ] as QuestionBlockDetailScore | null;

      questionData.push({
        teamId: t.id,
        teamName: t.name,
        answer: detailScore?.answer ?? null,
        userId: detailScore?.submitterUid ?? null,
        scoreOverride: detailScore?.scoreOverride ?? null,
        score: detailScore?.score ? Math.round(detailScore.score) : null,
        totalScore: scoreSummary?.[t.id]?.totalScore || 0,
      });

      if (detailScore?.answer && detailScore?.answer.length > 0) {
        answeredTeamCount++;
      }
    });

    questionData.sort(
      (a, b) =>
        a.teamName?.localeCompare(b.teamName || '', 'en', {
          sensitivity: 'base',
        }) || 0
    );

    if (sortBy && sortBy === 'totalScore') {
      questionData.sort(
        (a: QuestionTeamData, b: QuestionTeamData) =>
          (b.totalScore || 0) - (a.totalScore || 0)
      );
    }

    return {
      questionData,
      answeredTeamCount,
      totalTeamCount: teamList.length,
    };
  }, [blockDetailScores, scoreSummary, sortBy, teamList]);
};
