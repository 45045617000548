import { useCallback, useEffect, useMemo, useState } from 'react';

import {
  GameSessionUtil,
  MediaType,
  type QuestionBlock,
  QuestionBlockGameSessionStatus,
} from '@lp-lib/game';

import { nullOrUndefined } from '../../../../utils/common';
import { useOneTimeAutomaticBroadcastToggleOff } from '../../../Broadcast';
import { useGainPointsAnimationGamePlayTrigger } from '../../../GainPointsAnimation/useGainPointsAnimationGamePlayTrigger';
import { DupHostStreamViewWrapper } from '../../../Host/DupHostStreamView';
import { FloatBoard } from '../../../Layout';
import { LayoutAnchor } from '../../../LayoutAnchors/LayoutAnchors';
import { useSyncPersistentPointsRevealAnswer } from '../../../PersistentPoints/Provider';
import { StageMode, useStageMode } from '../../../Stage';
import { GoAnimation } from '../../GameBlockCardAnimations';
import {
  useGameSessionLocalTimer,
  useGameSessionStatus,
  useOndWaitEndWithIntroMediaProgressCheck,
  useTimerRecover,
} from '../../hooks';
import { resetTimer, setTimer } from '../../store';
import { GamePlayMediaLayout } from '../Common/GamePlay/GamePlayMediaLayout';
import { useGamePlayUIConfiguration } from '../Common/GamePlay/GamePlayUIConfigurationProvider';
import {
  buildGamePlayMedia,
  GamePlayInput,
  type GamePlayMedia,
  GamePlayMediaPlayer,
  GameSubmissionInput,
  useCountdownPlaySFX,
  useGamePlayMediaPlayable,
  useGamePlayMediaUISync,
  useGamePlayUITransitionControl,
  useIsGamePlayReady,
} from '../Common/GamePlay/Internal';
import { StageBgSingleFrame } from '../Common/GamePlay/StageBgSingleFrame';
import { SubmissionStatusWidgetAttachedOnLeft } from '../Common/GamePlay/SubmissionStatusWidget';
import { pointsTo2x3xCmp } from '../Common/pointUtils';
import { BlockKnifeUtils } from '../Shared';
import { GamePlayH2H } from './GamePlayH2H';
import { QuestionAnswer } from './QuestionBlockGamePlayAnswer';
import { QuestionBlockUtils } from './utils';

const QuestionBlockGamePlay = (props: {
  gameSessionBlock: QuestionBlock;
}): JSX.Element | null => {
  const { gameSessionBlock } = props;
  const gameSessionStatus =
    useGameSessionStatus<QuestionBlockGameSessionStatus>();
  const gameSessionLocalTimer = useGameSessionLocalTimer();

  const [gamePlayMedia, setGamePlayMedia] =
    useState<Nullable<GamePlayMedia, false>>(null);
  const [uiState, uiControl] = useGamePlayUITransitionControl();
  const isGamePlayReady = useIsGamePlayReady(
    gameSessionBlock,
    gameSessionStatus
  );
  const map = useMemo(
    () => GameSessionUtil.StatusMapFor(gameSessionBlock),
    [gameSessionBlock]
  );
  const stageMode = useStageMode();
  const gamePlayConfig = useGamePlayUIConfiguration();
  const showHost = stageMode === StageMode.H2H;
  const fields = gameSessionBlock.fields;

  const stopPointsAnimation = useCallback(
    () => uiControl.update({ playPointsMultiplierAnimation: false }),
    [uiControl]
  );

  const initialBlockTime = gameSessionBlock?.fields.time ?? null;

  const showAnswer =
    gameSessionStatus === QuestionBlockGameSessionStatus.ANSWER &&
    (uiState.showAnswer || !gamePlayMedia);

  const pointsAnimation = pointsTo2x3xCmp(
    BlockKnifeUtils.DisplaysPointsMultiplier(gameSessionBlock)
  );

  useEffect(() => {
    if (!gamePlayConfig.showPointsMultiplierAnimation) return;

    if (gameSessionStatus === QuestionBlockGameSessionStatus.PRESENTING) {
      if (pointsAnimation) {
        uiControl.update({
          playPointsMultiplierAnimation: true,
          pointsMultiplierAnimation: pointsAnimation,
        });
      }
    }
  }, [
    gamePlayConfig.showPointsMultiplierAnimation,
    fields.points,
    gameSessionStatus,
    uiControl,
    pointsAnimation,
  ]);

  useEffect(() => {
    if (!map || nullOrUndefined(gameSessionStatus)) return;
    const startVideoWithTimer = !!fields.startVideoWithTimer;
    if (map.introMediaRetained.includes(gameSessionStatus)) {
      setGamePlayMedia(
        buildGamePlayMedia(
          {
            media: fields.questionMedia,
            mediaData: fields.questionMediaData,
          },
          {
            stage: 'intro',
            startVideoWithTimer,
          }
        )
      );
    } else if (gameSessionStatus === map.outro) {
      setGamePlayMedia(
        buildGamePlayMedia(
          {
            media: fields.answerMedia,
            mediaData: fields.answerMediaData,
          },
          {
            stage: 'outro',
            imageDurationMs: 5000,
          }
        )
      );
      uiControl.update({
        playPointsMultiplierAnimation: false,
        pointsMultiplierAnimation: null,
      });
    }
  }, [
    gameSessionBlock,
    fields.answerMedia,
    fields.points,
    fields.questionMedia,
    fields.startVideoWithTimer,
    gameSessionStatus,
    map,
    uiControl,
    fields.questionMediaData,
    fields.answerMediaData,
  ]);

  useEffect(() => {
    return () => {
      resetTimer('submission');
    };
  }, []);

  useEffect(() => {
    if (
      gameSessionStatus === null ||
      gameSessionStatus === QuestionBlockGameSessionStatus.LOADED ||
      gameSessionStatus === QuestionBlockGameSessionStatus.END
    ) {
      uiControl.reset();
      resetTimer('submission');
    }
  }, [gameSessionStatus, uiControl]);

  useEffect(() => {
    if (
      initialBlockTime !== null &&
      (gameSessionStatus === QuestionBlockGameSessionStatus.PRESENTING ||
        gameSessionStatus === QuestionBlockGameSessionStatus.COUNTING)
    ) {
      setTimer('submission', initialBlockTime);
    }
  }, [gameSessionStatus, initialBlockTime]);

  useEffect(() => {
    if (
      gameSessionStatus === QuestionBlockGameSessionStatus.TIMESUP &&
      gameSessionLocalTimer !== 0
    ) {
      resetTimer('submission');
    }
  }, [gameSessionLocalTimer, gameSessionStatus]);

  useCountdownPlaySFX(
    gameSessionBlock.fields.time,
    gameSessionLocalTimer,
    gameSessionStatus === QuestionBlockGameSessionStatus.COUNTING ||
      gameSessionStatus === QuestionBlockGameSessionStatus.TIMESUP
  );

  // When countdown starts
  useOneTimeAutomaticBroadcastToggleOff(
    () => gameSessionStatus === QuestionBlockGameSessionStatus.COUNTING
  );
  // When answer is revealed (regardless if there is a video or not)
  useOneTimeAutomaticBroadcastToggleOff(
    () => showAnswer && gamePlayMedia?.type === MediaType.Video
  );

  useTimerRecover(async (status) => {
    if (status === QuestionBlockGameSessionStatus.COUNTING) {
      return gameSessionBlock.fields.time;
    }
  });

  useSyncPersistentPointsRevealAnswer(showAnswer);
  useGainPointsAnimationGamePlayTrigger();

  const { onMediaEnded, onMediaReplaying } = useGamePlayMediaUISync({
    block: gameSessionBlock,
    gameSessionStatus,
    media: gamePlayMedia,
    state: uiState,
    control: uiControl,
  });

  const wrappedOnMediaEnd = useOndWaitEndWithIntroMediaProgressCheck(
    gameSessionBlock.id,
    !!gameSessionBlock.fields.startVideoWithTimer,
    gameSessionStatus,
    QuestionBlockGameSessionStatus.COUNTING,
    gamePlayMedia,
    onMediaEnded
  );

  const mediaPlayable = useGamePlayMediaPlayable({
    block: gameSessionBlock,
    gameSessionStatus,
    media: gamePlayMedia,
    state: uiState,
  });

  if (!isGamePlayReady) return null;

  const wrapperZIndex =
    gameSessionStatus === QuestionBlockGameSessionStatus.ANSWER
      ? 'z-20'
      : 'z-30';
  const inputZIndex =
    gameSessionStatus === QuestionBlockGameSessionStatus.ANSWER
      ? 'z-20'
      : 'z-30';

  const overlayStyles =
    uiState.playPointsMultiplierAnimation || !uiState.showBoard
      ? 'bg-black bg-opacity-60'
      : uiState.showBoard
      ? 'bg-black bg-opacity-40'
      : '';

  if (nullOrUndefined(gameSessionStatus)) return null;

  return (
    <div className={`fixed w-screen h-screen ${overlayStyles} text-white`}>
      {uiState.playPointsMultiplierAnimation &&
        uiState.pointsMultiplierAnimation &&
        uiState.pointsMultiplierAnimation({
          cb: stopPointsAnimation,
          showText: true,
        })}
      <StageBgSingleFrame gamePlayMedia={gamePlayMedia} />
      <FloatBoard
        containerZIndex={wrapperZIndex}
        containerDisplay={
          uiState.playPointsMultiplierAnimation ? 'hidden' : 'flex'
        }
        bgOpacity={uiState.showBoard ? 'opacity-100' : 'opacity-0'}
      />
      <GamePlayInput
        text={gameSessionBlock.fields.question}
        zIndex={inputZIndex}
        visible={uiState.showBoard && !uiState.playPointsMultiplierAnimation}
        hasMedia={!!gamePlayMedia}
        inputBox={
          <GameSubmissionInput
            timer={gameSessionLocalTimer}
            gameSessionBlock={gameSessionBlock}
            gameSessionStatus={gameSessionStatus}
            adaptor={QuestionBlockUtils.ToSubmissionInputAdaptor(
              gameSessionBlock
            )}
          />
        }
      />
      {uiState.playGoAnimation && <GoAnimation />}
      <GamePlayMediaLayout
        fullscreen={false}
        hide={uiState.playPointsMultiplierAnimation}
      >
        {gamePlayMedia ? (
          <>
            <GamePlayMediaPlayer
              gamePlayMedia={gamePlayMedia}
              play={mediaPlayable}
              mode={uiState.mediaEndEffect ? 'small' : 'full'}
              z20={gameSessionStatus === QuestionBlockGameSessionStatus.ANSWER}
              onMediaEnded={wrappedOnMediaEnd}
              onMediaReplaying={onMediaReplaying}
              layout='anchored'
            />
            <GamePlayH2H />
            <LayoutAnchor
              id='dup-host-stream-anchor'
              className='absolute left-0 top-0'
            />
          </>
        ) : null}
      </GamePlayMediaLayout>
      {showHost && <DupHostStreamViewWrapper zIndex={wrapperZIndex} />}
      {showAnswer ? <QuestionAnswer /> : null}
      {gamePlayConfig.showSubmissionStatusWidget && uiState.showBoard && (
        <SubmissionStatusWidgetAttachedOnLeft />
      )}
    </div>
  );
};

export { QuestionBlockGamePlay };
