const GameIcon = (props: { className?: string }): JSX.Element => {
  return (
    <svg
      className={props.className ?? 'text-white fill-current w-6.5 h-6.5'}
      viewBox='0 0 14 14'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M4.08678 3.2L1.2 6.22375V10.8H12.8V3.2H4.08678ZM0.296071 5.43278C0.106592 5.63579 0 5.91224 0 6.20064V10.9189C0 11.516 0.447716 12 1 12H13C13.5523 12 14 11.516 14 10.9189V3.08108C14 2.48402 13.5523 2 13 2H3.97826C3.71459 2 3.4616 2.11257 3.27433 2.31322L0.296071 5.43278Z'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M10.6528 5.44784C10.8952 5.58913 10.9843 5.91333 10.8519 6.17196L8.1173 11.5139C7.9849 11.7725 7.68113 11.8676 7.43879 11.7263C7.19645 11.585 7.10733 11.2608 7.23972 11.0022L9.97432 5.66029C10.1067 5.40167 10.4105 5.30655 10.6528 5.44784Z'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M10.6528 5.44784C10.8952 5.58913 10.9843 5.91333 10.8519 6.17196L8.1173 11.5139C7.9849 11.7725 7.68113 11.8676 7.43879 11.7263C7.19645 11.585 7.10733 11.2608 7.23972 11.0022L9.97432 5.66029C10.1067 5.40167 10.4105 5.30655 10.6528 5.44784Z'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M10.6528 5.44784C10.8952 5.58913 10.9843 5.91333 10.8519 6.17196L8.1173 11.5139C7.9849 11.7725 7.68113 11.8676 7.43879 11.7263C7.19645 11.585 7.10733 11.2608 7.23972 11.0022L9.97432 5.66029C10.1067 5.40167 10.4105 5.30655 10.6528 5.44784Z'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M10.6528 5.44784C10.8952 5.58913 10.9843 5.91333 10.8519 6.17196L8.1173 11.5139C7.9849 11.7725 7.68113 11.8676 7.43879 11.7263C7.19645 11.585 7.10733 11.2608 7.23972 11.0022L9.97432 5.66029C10.1067 5.40167 10.4105 5.30655 10.6528 5.44784Z'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M13.6107 5.80262C13.8504 5.94883 13.9566 6.23209 13.8478 6.43529L11.0652 11.6321C10.9564 11.8353 10.6739 11.8815 10.4341 11.7353C10.1944 11.589 10.0882 11.3058 10.197 11.1026L12.9796 5.9058C13.0884 5.7026 13.3709 5.6564 13.6107 5.80262Z'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M13.6107 5.80262C13.8504 5.94883 13.9566 6.23209 13.8478 6.43529L11.0652 11.6321C10.9564 11.8353 10.6739 11.8815 10.4341 11.7353C10.1944 11.589 10.0882 11.3058 10.197 11.1026L12.9796 5.9058C13.0884 5.7026 13.3709 5.6564 13.6107 5.80262Z'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M13.6107 5.80262C13.8504 5.94883 13.9566 6.23209 13.8478 6.43529L11.0652 11.6321C10.9564 11.8353 10.6739 11.8815 10.4341 11.7353C10.1944 11.589 10.0882 11.3058 10.197 11.1026L12.9796 5.9058C13.0884 5.7026 13.3709 5.6564 13.6107 5.80262Z'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M13.6107 5.80262C13.8504 5.94883 13.9566 6.23209 13.8478 6.43529L11.0652 11.6321C10.9564 11.8353 10.6739 11.8815 10.4341 11.7353C10.1944 11.589 10.0882 11.3058 10.197 11.1026L12.9796 5.9058C13.0884 5.7026 13.3709 5.6564 13.6107 5.80262Z'
      />
      <path d='M4.81821 5.13026C4.88255 4.95658 5.11745 4.95658 5.18179 5.13026L5.57054 6.17961C5.5982 6.25428 5.66507 6.3051 5.7415 6.30955L6.81556 6.37208C6.99333 6.38243 7.06592 6.61615 6.92792 6.73384L6.09411 7.44491C6.03478 7.49551 6.00924 7.57774 6.02881 7.65516L6.30387 8.74316C6.3494 8.92323 6.15936 9.06768 6.00972 8.96674L5.10566 8.35685C5.04133 8.31345 4.95867 8.31345 4.89434 8.35685L3.99028 8.96674C3.84064 9.06768 3.6506 8.92323 3.69613 8.74316L3.97119 7.65516C3.99076 7.57774 3.96522 7.49551 3.90589 7.44491L3.07209 6.73384C2.93408 6.61615 3.00667 6.38243 3.18444 6.37208L4.2585 6.30955C4.33493 6.3051 4.4018 6.25428 4.42946 6.17961L4.81821 5.13026Z' />
    </svg>
  );
};

export { GameIcon };
