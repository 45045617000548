import { assertExhaustive } from './asserts';
import { QuestionBlockAnswerGrade } from './session';

interface TimedScoringFunction {
  get(timeLeft: number): number;
}

export class Constant implements TimedScoringFunction {
  constructor(private score: number) {}
  get(_: number): number {
    return this.score;
  }
}

type LinearDecayOptions = {
  score: number;
  timeTotal: number;
};

export class LinearDecay implements TimedScoringFunction {
  constructor(private options: LinearDecayOptions) {}
  get(timeLeft: number): number {
    if (this.options.timeTotal === 0) return 0;
    return Math.round(this.options.score * (timeLeft / this.options.timeTotal));
  }
}

type CustomDecayOptions = {
  score: number;
  timeTotal: number;
  fn: (timeElaspedInSec: number, totalSec: number) => number;
  decayOffsetRatio?: number;
  minScore?: number;
};

export class CustomDecay implements TimedScoringFunction {
  constructor(private options: CustomDecayOptions) {}

  get(timeLeft: number): number {
    const minScore = this.options.minScore || 0;
    if (this.options.timeTotal === 0) return minScore ?? this.options.score;
    const decayOffsetRatio = this.options.decayOffsetRatio || 0;
    const adjustedTimeTotal = (1 - decayOffsetRatio) * this.options.timeTotal;
    if (timeLeft >= adjustedTimeTotal) {
      return this.options.score;
    }
    if (adjustedTimeTotal === 0) return minScore ?? this.options.score;
    return Math.min(
      Math.max(
        Math.round(
          this.options.score *
            this.options.fn(adjustedTimeTotal - timeLeft, adjustedTimeTotal)
        ),
        minScore
      ),
      this.options.score
    );
  }
}

/**
 * The equation is derived from the curve fitting, check the link below for more info.
 * https://docs.google.com/spreadsheets/d/1X0x06wUkO--sbqBZtKeOMZV0DVK65E9_bWE7kpSxxUk/edit?usp=sharing
 *
 * @param timeElaspedInSec
 * @param
 * @returns
 */
function polynomialRegression(
  timeElaspedInSec: number,
  totalSec: number
): number {
  if (totalSec === 0) return 0;
  const x = timeElaspedInSec * (30 / totalSec);
  return (
    1.004183 -
    0.02223582 * x +
    0.004332773 * Math.pow(x, 2) -
    0.0005790432 * Math.pow(x, 3) +
    0.00002323131 * Math.pow(x, 4) -
    2.950313e-7 * Math.pow(x, 5)
  );
}

export function getTimedScoringKind(
  decreasingPointsTimer?: boolean,
  startDescendingImmediately?: boolean
) {
  return decreasingPointsTimer
    ? startDescendingImmediately
      ? 'custom-decay-without-offset'
      : 'custom-decay'
    : 'constant';
}

export function getTimedScoringFunction(
  kind:
    | 'constant'
    | 'linear-decay'
    | 'custom-decay'
    | 'custom-decay-without-offset',
  score: number,
  timeTotal: number
): TimedScoringFunction {
  switch (kind) {
    case 'constant':
      return new Constant(score);
    case 'linear-decay':
      return new LinearDecay({ score, timeTotal });
    case 'custom-decay':
      return new CustomDecay({
        score,
        timeTotal,
        fn: polynomialRegression,
        decayOffsetRatio: 0.25,
        minScore: Math.round(score * 0.25),
      });
    case 'custom-decay-without-offset':
      return new CustomDecay({
        score,
        timeTotal,
        fn: polynomialRegression,
        decayOffsetRatio: 0,
        minScore: Math.round(score * 0.25),
      });
    default:
      assertExhaustive(kind);
      throw new Error(`unknown kind: ${kind}`);
  }
}

function getBasePointsFromGrade(
  grade: QuestionBlockAnswerGrade,
  points: number
): number {
  switch (grade) {
    case QuestionBlockAnswerGrade.CORRECT:
      return points;
    case QuestionBlockAnswerGrade.HALF:
      return points / 2;
    case QuestionBlockAnswerGrade.INCORRECT:
    case QuestionBlockAnswerGrade.NONE:
      return 0;
    default:
      throw new Error(`unexpected grade: ${grade}`);
  }
}

export function calculateScore(
  grade: QuestionBlockAnswerGrade,
  decreasingPointsTimer: boolean,
  timerWhenSubmitted: number,
  timerLimit: number,
  points: number,
  startDescendingImmediately?: boolean
): number {
  const scoring = getTimedScoringFunction(
    getTimedScoringKind(decreasingPointsTimer, startDescendingImmediately),
    getBasePointsFromGrade(grade, points),
    timerLimit
  );
  return scoring.get(timerWhenSubmitted);
}
