import { useEffect } from 'react';
import { usePreviousDistinct } from 'react-use';

import { BlockType, GameSessionUtil } from '@lp-lib/game';

import {
  getFeatureQueryParamNumber,
  useFeatureQueryParam,
} from '../../hooks/useFeatureQueryParam';
import { useInstance } from '../../hooks/useInstance';
import { useIsController, useIsCoordinator } from '../../hooks/useMyInstance';
import { BrowserTimeoutCtrl } from '../../utils/BrowserTimeoutCtrl';
import { useSubmissionStatusSubmittedTeamIds } from '../Game/Blocks/Common/GamePlay/SubmissionStatusProvider';
import { useLocalDerivedOndPlaybackVersion } from '../Game/GamePlayStore';
import {
  useCurrentSessionMode,
  useGameSessionBlock,
  useGameSessionStatus,
  useIsLiveGamePlay,
  useOndGameState,
  useOndPlaybackVersion,
} from '../Game/hooks';
import { useOndPhaseEmitterListener } from '../Game/OndPhaseRunner';
import { OndVersionChecks } from '../Game/OndVersionChecks';
import { useTownhallAPI, useTownhallConfig } from './Provider';
import { type TownhallMode } from './types';
import { TownhallUtils } from './utils';

/**
 * Switch teams back to crowd once they finish the gameplay
 */
function useOndV3TeamSubmittedTrigger(idealCountdownSec: number) {
  const ondTownhallEnabled = OndVersionChecks(
    useOndPlaybackVersion()
  ).ondTownhallEnabled;
  const api = useTownhallAPI();
  const teams = useSubmissionStatusSubmittedTeamIds();
  const status = useGameSessionStatus();
  const block = useGameSessionBlock();
  const currBlockId = block?.id;

  useEffect(() => {
    if (!ondTownhallEnabled || !currBlockId || teams.length === 0) return;
    const map = GameSessionUtil.StatusMapFor(block?.type);
    if (!map) return;
    if (status !== map.gameStart) return;
    api.setNext({
      mode: 'crowd',
      countdownSec: idealCountdownSec,
      type: 'team',
      source: 'OndV3TeamSubmitted',
      teams,
    });
  }, [
    api,
    block?.type,
    currBlockId,
    idealCountdownSec,
    ondTownhallEnabled,
    status,
    teams,
  ]);
}

/**
 * Switch users back to crowd after timesup. This is also a final trigger if
 * some of the teams didn't finish the game within time.
 */
function useOndGameTimesupTrigger(idealCountdownSec: number) {
  const currStatus = useGameSessionStatus();
  const prevStatus = usePreviousDistinct(currStatus);
  const block = useGameSessionBlock();
  const api = useTownhallAPI();
  // Note(Jialin): technically, instruction block is not a "game",
  // it should not define gameStart and gameEnd.
  const blockDoNotSwitch = block?.type === BlockType.INSTRUCTION;

  useEffect(() => {
    if (blockDoNotSwitch) return;
    const map = GameSessionUtil.StatusMapFor(block?.type);
    if (!map) return;
    const transitToGameEnd =
      prevStatus !== map.gameEnd && currStatus === map.gameEnd;
    if (!transitToGameEnd) return;
    api.setNext({
      mode: 'crowd',
      countdownSec: idealCountdownSec,
      type: 'global',
      source: 'OndGameTimesup',
    });
  }, [
    api,
    block?.type,
    blockDoNotSwitch,
    currStatus,
    idealCountdownSec,
    prevStatus,
  ]);
}

function useOndGameEndedTrigger(idealCountdownSec: number) {
  const currState = useOndGameState();
  const prevState = usePreviousDistinct(currState);
  const api = useTownhallAPI();

  useEffect(() => {
    if (
      prevState !== undefined &&
      prevState !== currState &&
      (currState === 'ended' || currState === null)
    ) {
      api.setNext({
        mode: 'crowd',
        countdownSec: idealCountdownSec,
        type: 'global',
        source: 'OndGameEnded',
        policy: 'mandatory',
      });
    }
  }, [api, currState, idealCountdownSec, prevState]);
}

/**
 * Switch to the target mode for the next block
 */
function useOndNextBlockTrigger(idealCountdownSec: number) {
  const listener = useOndPhaseEmitterListener();
  // the map stores the block transition key as 'blockId:nextBlockId'. that's because a given blockId can appear
  // multiple times in a given session, for instance, a shared brand instruction block.
  const transitionTriggered = useInstance(() => new Map<string, number>());
  const api = useTownhallAPI();

  useEffect(() => {
    const off = listener.on(
      'block-tick',
      (block, blockProgressSec, blockEndSec, getNextBlock) => {
        if (blockEndSec - blockProgressSec <= idealCountdownSec) {
          const nextBlock = getNextBlock();
          if (!nextBlock) return;
          const transitionKey = `${block.id}:${nextBlock.id}`;
          if (transitionTriggered.has(transitionKey)) return;

          const nextMode = TownhallUtils.ModeForBlock(nextBlock.type);
          if (api.mode !== nextMode) {
            api.setNext({
              mode: nextMode,
              countdownSec: Math.ceil(blockEndSec - blockProgressSec),
              type: 'global',
              source: 'OndNextBlock',
            });
          }
          transitionTriggered.set(transitionKey, Date.now());
        }
      }
    );
    return () => {
      off();
      transitionTriggered.clear();
    };
  }, [api, transitionTriggered, idealCountdownSec, listener]);
}

/**
 * Make sure we switch to the right mode for the first block
 */
function useOndNextBlockAfterIntroTrigger(idealCountdownSec: number) {
  const listener = useOndPhaseEmitterListener();
  const api = useTownhallAPI();

  useEffect(() => {
    let ctrl: BrowserTimeoutCtrl | null = null;
    // It seems we can't tell the "progress" of intro media. This is triggered
    // when starting intro. Schedule the next mode based on the remaining time.
    const off = listener.on(
      'boot-play-intro',
      (introMediaDurationSec, nextBlock) => {
        if (!nextBlock) return;
        const nextMode = TownhallUtils.ModeForBlock(nextBlock.type);
        const delaySec = Math.max(introMediaDurationSec - idealCountdownSec, 0);
        ctrl = new BrowserTimeoutCtrl();
        ctrl.set(() => {
          api.setNext({
            mode: nextMode,
            // this is not gonna be the negative value.
            countdownSec: Math.ceil(introMediaDurationSec - delaySec),
            type: 'global',
            source: 'OndNextBlockAfterIntro',
          });
        }, delaySec * 1000);
      }
    );
    return () => {
      ctrl?.clear();
      off();
    };
  }, [api, idealCountdownSec, listener]);
}

function Triggers(props: { idealCountdownSec: number }): JSX.Element | null {
  const { idealCountdownSec } = props;
  useOndNextBlockAfterIntroTrigger(idealCountdownSec);
  useOndNextBlockTrigger(idealCountdownSec);
  useOndV3TeamSubmittedTrigger(idealCountdownSec);
  useOndGameTimesupTrigger(idealCountdownSec);
  useOndGameEndedTrigger(0);
  return null;
}

function useOndGameForceMode(mode: TownhallMode) {
  const api = useTownhallAPI();
  useEffect(() => {
    api.setNext({
      mode,
      countdownSec: 0,
      type: 'global',
      source: 'OndGameForceMode',
      policy: 'mandatory',
    });
  }, [api, mode]);
}

function ForceCrowdModeTrigger(): JSX.Element | null {
  useOndGameForceMode('crowd');
  return null;
}

function ForceTeamModeTrigger(): JSX.Element | null {
  useOndGameForceMode('team');
  useOndGameEndedTrigger(0);
  return null;
}

export function OndGameAutoSwitchTownhallMode(): JSX.Element | null {
  const isOndGame = !useIsLiveGamePlay();
  const townhallConfig = useTownhallConfig();
  const isController = useIsController();
  const isCoordinator = useIsCoordinator();
  const ondAutoSwitchIdealCountdownSec = useInstance(() =>
    getFeatureQueryParamNumber('townhall-ond-auto-switch-ideal-countdown-sec')
  );

  if (!isOndGame || !townhallConfig.enabled) return null;

  if (isController || isCoordinator) {
    switch (townhallConfig.forceMode) {
      case 'crowd':
        return <ForceCrowdModeTrigger />;
      case 'team':
        return <ForceTeamModeTrigger />;
      case 'disabled':
      default:
        break;
    }
  }

  if (!isController) return null;
  return <Triggers idealCountdownSec={ondAutoSwitchIdealCountdownSec} />;
}

export function useOndGameAutoToggleTownhall(): void {
  const isOndGame = !useIsLiveGamePlay();
  const isCoordinator = useIsCoordinator();
  const autoToggleEnabled = useFeatureQueryParam(
    'townhall-ond-auto-activate-for-v3-game'
  );
  const mode = useCurrentSessionMode();
  const playbackVersion = useLocalDerivedOndPlaybackVersion(mode);
  const ondTownhallEnabled =
    OndVersionChecks(playbackVersion).ondTownhallEnabled;
  const api = useTownhallAPI();

  useEffect(() => {
    if (!isOndGame || !isCoordinator || !autoToggleEnabled) return;
    api.setEnabled(ondTownhallEnabled);
  }, [api, autoToggleEnabled, isCoordinator, isOndGame, ondTownhallEnabled]);
}
