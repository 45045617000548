import React, { type ReactNode, useContext, useEffect, useMemo } from 'react';
import { proxy, useSnapshot } from 'valtio';

import { type Logger } from '@lp-lib/logger-base';

import logger from '../../../logger/logger';
import { markSnapshottable, ValtioUtils } from '../../../utils/valtio';
import {
  type FirebaseService,
  useFirebaseContext,
  useIsFirebaseConnected,
} from '../../Firebase';
import { useVenueId } from '../../Venue/VenueProvider';
import {
  type PreGameInstructionBlockDesc,
  type PreGameRootState,
} from './types';
import { PreGameFBUtils, showPreGame } from './utils';

class PreGameSharedAPI {
  private _state;

  constructor(
    venueId: string,
    svc: FirebaseService,
    private rootHandle = PreGameFBUtils.RootHandle(svc, venueId)
  ) {
    this._state = markSnapshottable(
      proxy<PreGameRootState>(this.initialState())
    );
  }

  get state() {
    return this._state;
  }

  on(): void {
    this.rootHandle.on((val) => {
      this.state.stage = val?.stage;
      ValtioUtils.set(this._state, 'shared', val?.shared);
    });
  }

  off(): void {
    this.rootHandle.off();
  }

  reset() {
    ValtioUtils.reset(this._state, this.initialState());
  }

  private initialState(): PreGameRootState {
    return {
      stage: 'none',
      shared: null,
    };
  }
}

class PreGameControlAPI {
  constructor(
    venueId: string,
    svc: FirebaseService,
    private log: Logger,
    private rootHandle = PreGameFBUtils.RootHandle(svc, venueId)
  ) {}

  async bootstrap() {
    await this.rootHandle.update({ stage: 'bootstrap' });
    this.log.info('bootstrap pregame');
  }

  async present(shared: PreGameInstructionBlockDesc) {
    await this.rootHandle.set({
      stage: 'present',
      shared: {
        brandName: (shared.brandName ?? null) as never, // TODO(falcon): fix when firebase types are updated.
        blockId: (shared.blockId ?? null) as never, // TODO(falcon): fix when firebase types are updated.
        hasHostedTutorial: shared.hasHostedTutorial ?? false,
      },
    });
    this.log.info('presented pregame');
  }

  /**
   * aka `reset()`
   */
  async unpresent() {
    await this.rootHandle.remove();
    this.log.info('unpresented pregame');
  }
}

type Context = {
  sharedAPI: PreGameSharedAPI;
  controlAPI: PreGameControlAPI;
};

const context = React.createContext<Context | null>(null);

export function PreGameProvider(props: {
  children?: ReactNode;
}): JSX.Element | null {
  const venueId = useVenueId();
  const { svc } = useFirebaseContext();
  const firebaseConnected = useIsFirebaseConnected();

  const ctx = useMemo(() => {
    return {
      sharedAPI: new PreGameSharedAPI(venueId, svc),
      controlAPI: new PreGameControlAPI(
        venueId,
        svc,
        logger.scoped('pre-game-ctrl')
      ),
    };
  }, [venueId, svc]);

  useEffect(() => {
    if (!firebaseConnected) return;
    ctx.sharedAPI.on();
    return () => ctx.sharedAPI.off();
  }, [ctx.sharedAPI, firebaseConnected]);

  return <context.Provider value={ctx}>{props.children}</context.Provider>;
}

function usePreGameContext(): Context {
  const ctx = useContext(context);
  if (!ctx) throw new Error('PreGameContext is not in the tree!');
  return ctx;
}

export function usePreGameControlAPI(): Context['controlAPI'] {
  return usePreGameContext().controlAPI;
}

export function usePreGameSharedAPI(): Context['sharedAPI'] {
  return usePreGameContext().sharedAPI;
}

export function useShowPreGame(): boolean {
  return showPreGame(useSnapshot(usePreGameSharedAPI().state).stage);
}

export function usePreGamePresented(): boolean {
  return useSnapshot(usePreGameSharedAPI().state).stage === 'present';
}
