import { useLocation } from '@remix-run/react';
import { useCallback } from 'react';

import { apiService } from '../../services/api-service';
import {
  type Organization,
  type Organizer,
  type OrgConnection,
} from '../../types';
import { useAwaitFullScreenConfirmCancelModal } from '../ConfirmCancelModalContext';
import { ModalWrapper } from '../ConfirmCancelModalContext/ModalWrapper';
import { SlackInstallButton } from '../Slack';
import { useUser } from '../UserContext';
import { useTriggerEmailInviteModal } from './OrganizerEmailInvite';
import { useTriggerImportSlackMembersModal } from './OrganizerImporterSlack';

function ConnectSlackModal(props: {
  organization: Organization;
  onClose: () => void;
  onAddByEmail: () => void;
  onDoNotUseSlack: () => void;
}): JSX.Element {
  const location = useLocation();

  const handleDoNotUseSlack = async () => {
    await apiService.organization.updateOrganizationUsesSlack(
      props.organization.id,
      false
    );
    props.onDoNotUseSlack();
  };

  return (
    <ModalWrapper
      containerClassName='w-160'
      borderStyle='white'
      onClose={props.onClose}
    >
      <div className='w-full p-8'>
        <header className='w-full text-center'>
          <h1 className='text-2xl font-medium'>Import Team</h1>
          <p className='text-sms font-normal text-icon-gray'>
            Share access to Luna Park with your teammates. <br />
            The more you invite, the more fun Luna Park becomes!
          </p>
        </header>
        <main className='mt-18 w-98 mx-auto text-xl font-bold text-center'>
          Please add Luna Park to your Slack workspace first!
        </main>
        <footer className='mt-18 w-full flex flex-col items-center'>
          <SlackInstallButton
            text='Connect to Slack'
            from={location.pathname}
            className='btn-primary w-62 h-12'
          />
          <button
            type='button'
            onClick={props.onAddByEmail}
            className='mt-2.5 btn-secondary w-62 h-12'
          >
            Add Users by Email
          </button>
          <button
            type='button'
            onClick={handleDoNotUseSlack}
            className='mt-4 btn text-sms font-medium text-icon-gray underline'
          >
            We don't use Slack
          </button>
        </footer>
      </div>
    </ModalWrapper>
  );
}

interface TriggerConnectSlackModalProps {
  organization: Organization;
  onAddByEmail: () => void;
  onDoNotUseSlack?: () => void;
}

function useTriggerConnectSlackModal(): (
  props: TriggerConnectSlackModalProps
) => void {
  const triggerFullScreenModal = useAwaitFullScreenConfirmCancelModal();

  return useCallback(
    (props: TriggerConnectSlackModalProps) => {
      triggerFullScreenModal({
        kind: 'custom',
        element: (p) => (
          <ConnectSlackModal
            organization={props.organization}
            onClose={p.internalOnCancel}
            onAddByEmail={() => {
              p.internalOnCancel();
              props.onAddByEmail();
            }}
            onDoNotUseSlack={() => {
              p.internalOnCancel();
              if (props.onDoNotUseSlack) {
                props.onDoNotUseSlack();
              }
            }}
          />
        ),
      });
    },
    [triggerFullScreenModal]
  );
}

export interface TriggerImportOrganizersModalProps {
  organization: Organization;
  connection: OrgConnection | null | undefined;
  onImported: (organizers: Organizer[]) => void;
  onDoNotUseSlack?: () => void;
}

export function useTriggerImportOrganizersModal(): (
  props: TriggerImportOrganizersModalProps
) => void {
  const user = useUser();
  const triggerEmailInviteModal = useTriggerEmailInviteModal();
  const triggerConnectSlackModal = useTriggerConnectSlackModal();
  const triggerImportSlackMembersModal = useTriggerImportSlackMembersModal();

  return useCallback(
    (props: TriggerImportOrganizersModalProps) => {
      if (props.connection) {
        triggerImportSlackMembersModal({
          organization: props.organization,
          connection: props.connection,
          onImport: props.onImported,
          onAddByEmail: () => {
            triggerEmailInviteModal({
              organization: props.organization,
              onInvited: props.onImported,
            });
          },
        });
        return;
      }

      if (
        !props.organization.usesSlack ||
        // Add members by admin
        user.organizer?.orgId !== props.organization.id
      ) {
        triggerEmailInviteModal({
          organization: props.organization,
          onInvited: props.onImported,
        });
        return;
      }

      triggerConnectSlackModal({
        organization: props.organization,
        onAddByEmail: () => {
          triggerEmailInviteModal({
            organization: props.organization,
            onInvited: props.onImported,
          });
        },
        onDoNotUseSlack: props.onDoNotUseSlack,
      });
      return;
    },
    [
      triggerConnectSlackModal,
      triggerEmailInviteModal,
      triggerImportSlackMembersModal,
      user.organizer?.orgId,
    ]
  );
}
