import Uppy, { type UppyFile } from '@uppy/core';
import { FileInput } from '@uppy/react';
import pluralize from 'pluralize';
import { useEffect } from 'react';
import { usePreviousDistinct } from 'react-use';

import { EnumsMediaScene } from '@lp-lib/api-service-client/public';
import {
  type BlockFields,
  type DrawingPrompt,
  type DrawingPromptBlock,
  type DrawingPromptBlockMedia,
} from '@lp-lib/game';

import { useArrayState } from '../../../../hooks/useArrayState';
import { useLiveCallback } from '../../../../hooks/useLiveCallback';
import { useUppy } from '../../../../hooks/useUppy';
import { apiService } from '../../../../services/api-service';
import { uuidv4 } from '../../../../utils/common';
import { createDownloadCSVFile, csvToArray } from '../../../../utils/csv';
import { DeleteIcon } from '../../../icons/DeleteIcon';
import { DownloadIcon } from '../../../icons/DownloadIcon';
import { useBlockEditorStore } from '../../../RoutedBlock';
import {
  AdditionalSettings,
  AdditionalSharedSettingsEditor,
  BlockMediaEditor,
  EditorBody,
  EditorLayout,
  type EditorProps,
  type Option,
  RHFLabel,
  RHFSelectField,
  useEditor,
} from '../Common/Editor/EditorUtilities';

const MAX_PROMPTS = 10000;
const timeOptions: Option[] = [30, 45, 60, 90, 120, 180, 240].map((secs) => {
  if (secs < 60) {
    return { label: `${secs} ${pluralize('second', secs)}`, value: secs };
  } else {
    const mins = secs / 60;
    return { label: `${mins} ${pluralize('minute', mins)}`, value: secs };
  }
});
const pointOptions: Option[] = [25, 50, 100, 150, 200].map((i) => ({
  value: i,
  label: `${i}`,
}));

function PromptHeader(props: {
  blockId: string;
  handleAddBatch: (prompts: DrawingPrompt[]) => void;
}) {
  const uploaderId = `csv-uploader-${props.blockId}`;
  const fileParser = useLiveCallback((currentFile: UppyFile) => {
    const reader = new FileReader();

    reader.onload = (e) => {
      const content = e.target?.result ?? '';
      if (!content) return;

      const csv = csvToArray(content.toString());
      if (csv.length <= 1) return;
      const rows = csv.splice(1);
      const prompts = rows.map<DrawingPrompt>((row) => ({
        id: uuidv4(),
        correct: row[0],
      }));
      props.handleAddBatch(prompts);
    };

    reader.readAsText(currentFile.data);

    return false;
  });

  const uppy = useUppy(
    uploaderId,
    () =>
      new Uppy({
        id: uploaderId,
        autoProceed: false,
        allowMultipleUploads: true,
        debug: true,
        restrictions: {
          maxFileSize: 1024 * 1024,
          minFileSize: null,
          maxTotalFileSize: 1024 * 1024,
          maxNumberOfFiles: null,
          minNumberOfFiles: 1,
          allowedFileTypes: ['.csv'],
        },
        locale: {
          strings: {
            chooseFiles: 'Upload CSV',
            exceedsSize: 'Max file size exceeded',
            youCanOnlyUploadFileTypes: 'File type not supported',
          },
        },
        onBeforeFileAdded: fileParser,
      })
  );

  return (
    <div className='flex gap-10 items-center'>
      <div className='text-white'>Prompts</div>
      <div id='editor-csv-uploader'>
        <FileInput uppy={uppy} inputName={'files'} />
      </div>
      <a
        className='flex items-center justify-center text-xs text-icon-gray font-light border-b border-icon-gray'
        href={createDownloadCSVFile(['PROMPTS'])}
        download='Drawing Prompts Template.csv'
      >
        <DownloadIcon className='w-3.5 h-3.5 fill-current' />
        Template
      </a>
    </div>
  );
}

function PromptManagement(props: {
  blockId: string;
  prompts: DrawingPrompt[];
  onUpdate: (prompts: DrawingPrompt[]) => void;
}) {
  const onUpdate = useLiveCallback(props.onUpdate);
  const [prompts, , dao] = useArrayState<DrawingPrompt>({
    init: props.prompts,
    compare: (a, b) => a.id === b.id,
    prepend: false,
  });

  const prevPrompts = usePreviousDistinct(prompts);

  useEffect(() => {
    if (!prevPrompts) return;
    onUpdate(prompts);
  }, [onUpdate, prevPrompts, prompts]);

  const handleAdd = (prompt?: DrawingPrompt) => {
    if (prompts.length >= MAX_PROMPTS) {
      alert(`Max prompts reached: ${MAX_PROMPTS}`);
      return;
    }
    dao.addItem(prompt ?? { id: uuidv4(), correct: '' });
  };

  const handleAddBatch = (newPrompts: DrawingPrompt[]) => {
    const totalAfterAdd = prompts.length + newPrompts.length;
    if (totalAfterAdd > MAX_PROMPTS) {
      dao.addItems(newPrompts.slice(0, MAX_PROMPTS - prompts.length));
      alert(
        `Max prompts reached: ${MAX_PROMPTS}, last ${
          totalAfterAdd - MAX_PROMPTS
        } prompt(s) are dropped`
      );
    } else {
      dao.addItems(newPrompts);
    }
  };

  const handleDelete = (prompt: DrawingPrompt) => {
    dao.deleteItem(prompt);
  };

  const handleUpdate = (id: string, updates: Partial<DrawingPrompt>) => {
    dao.updateItem({
      id,
      ...updates,
    });
  };

  return (
    <div>
      <PromptHeader blockId={props.blockId} handleAddBatch={handleAddBatch} />
      <div>
        <div className='my-4 flex flex-col gap-3'>
          {prompts.map((p) => (
            <div key={p.id} className='w-full h-10 flex items-center gap-4'>
              <input
                className='field h-full mb-0 flex-1'
                defaultValue={p.correct}
                onBlur={(e) => handleUpdate(p.id, { correct: e.target.value })}
                maxLength={100}
                placeholder='Max 100 characters'
              />

              <button
                type='button'
                onClick={() => handleDelete(p)}
                className='w-10 h-10 rounded-lg border border-secondary 
            btn flex justify-center items-center text-red-002 bg-black'
              >
                <DeleteIcon />
              </button>
            </div>
          ))}
        </div>
        <button
          type='button'
          disabled={prompts.length >= MAX_PROMPTS}
          className='btn text-secondary text-sms underline'
          onClick={() => handleAdd()}
        >
          {prompts.length >= MAX_PROMPTS ? 'Max Prompts Reached' : 'Add More'}
        </button>
      </div>
    </div>
  );
}

export function DrawingPromptBlockEditor(
  props: EditorProps<DrawingPromptBlock>
): JSX.Element | null {
  const { block } = props;
  const blockId = block.id;
  const store = useBlockEditorStore();
  const { updateField } = useEditor(props);

  const onUpdatePrompts = async (prompts: DrawingPrompt[]) => {
    props.setSavingChanges(true);
    try {
      await apiService.block.updateField<DrawingPromptBlock>(block.id, {
        field: 'prompts',
        value: prompts,
      });
      store.setBlockField({
        blockId: block.id,
        blockField: { prompts },
      });
    } catch (err) {
      throw err;
    } finally {
      props.setSavingChanges(false);
    }
  };

  const selectOnChange = (
    name: keyof BlockFields<DrawingPromptBlock>,
    value: Nullable<Option['value'], false>
  ): void => {
    updateField(name, value);
  };

  return (
    <EditorLayout
      bottomAccessory={
        <AdditionalSettings>
          <AdditionalSharedSettingsEditor {...props} />
        </AdditionalSettings>
      }
    >
      <EditorBody>
        <h2 className='text-2xl text-white'>Drawing: Guess the Prompt</h2>
        <div className='w-full flex my-7.5'>
          <div className='w-3/5'>
            <PromptManagement
              blockId={blockId}
              prompts={block.fields.prompts}
              onUpdate={onUpdatePrompts}
            />
          </div>
          <div className='w-2/5 text-base font-bold flex flex-col items-center gap-4'>
            <label htmlFor='background-media'>
              <BlockMediaEditor<DrawingPromptBlockMedia>
                blockId={blockId}
                title='Background Media'
                field='backgroundMedia'
                video={true}
                scene={EnumsMediaScene.MediaSceneBlockBackground}
                volumeSelectable
                mediaData={block.fields.backgroundMediaData}
                media={block.fields.backgroundMedia}
                extraNotice='Media will display behind the gameplay for the duration of the game'
              />
            </label>
            <label htmlFor='canvas-media'>
              <BlockMediaEditor<DrawingPromptBlockMedia>
                blockId={blockId}
                title='Canvas Media'
                field='canvasMedia'
                video={false}
                scene={EnumsMediaScene.MediaSceneBlockMedia}
                mediaData={block.fields.canvasMediaData}
                media={block.fields.canvasMedia}
                extraNotice='Media will display on the drawing canvas. Leaving this blank will result in a white canvas.'
              />
            </label>
            <hr className='w-full my-2 border border-secondary' />
            <label htmlFor='drawingTimeSec' className='w-5/6'>
              <RHFSelectField<DrawingPromptBlock>
                className='w-full h-10 text-white'
                label='Drawing Time'
                name='drawingTimeSec'
                options={timeOptions}
                onChange={selectOnChange}
                value={block.fields.drawingTimeSec}
                description='How long do players have to draw their prompt?'
              />
            </label>
            <label className='w-5/6 my-4'>
              <RHFSelectField<DrawingPromptBlock>
                className='w-full h-10 text-white'
                label='Vote on Team Drawing Timer'
                name='votingTimeSec'
                options={timeOptions}
                onChange={selectOnChange}
                value={block.fields.votingTimeSec}
                description='How long do teams have to vote their winning drawing?'
              />
            </label>
            <label className='w-5/6 my-4'>
              <RHFLabel
                label='Write Fake Prompts Timer'
                description='Dynamic based on total number of teams. 40s per drawing. '
              />
            </label>
            <label className='w-5/6 my-4'>
              <RHFLabel
                label='Pick the Real Prompt Timer'
                description='Dynamic based on total number of teams. 15s + 3s for every team, rounded to the nearest 5'
              />
            </label>
            <label htmlFor='correctPromptPoints' className='w-5/6'>
              <RHFSelectField<DrawingPromptBlock>
                className='w-full h-10 text-white'
                label='Correct Prompt Points'
                name='correctPromptPoints'
                options={pointOptions}
                onChange={selectOnChange}
                value={block.fields.correctPromptPoints}
                description='Points that are awarded to the team who submitted the drawing whose prompt was guessed correctly AND to teams whose players guess the correct prompt. Each correct guess on a team earns points. '
              />
            </label>
            <label htmlFor='incorrectPromptPoints' className='w-5/6'>
              <RHFSelectField<DrawingPromptBlock>
                className='w-full h-10 text-white'
                label='Incorrect Prompt Points'
                name='incorrectPromptPoints'
                options={pointOptions}
                onChange={selectOnChange}
                value={block.fields.incorrectPromptPoints}
                description='Points that are awarded to teams whose player submitted a fake prompt that was picked by another team. Each incorrect guess earns points for the team that wrote it.'
              />
            </label>
          </div>
        </div>
      </EditorBody>
    </EditorLayout>
  );
}
