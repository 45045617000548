import { Uppy } from '@uppy/core';
import XHRUpload from '@uppy/xhr-upload';
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
} from 'react';
import { ref } from 'valtio';

import { EnumsMediaScene } from '@lp-lib/api-service-client/public';

import { getFeatureQueryParamArray } from '../../hooks/useFeatureQueryParam';
import logger from '../../logger/logger';
import { getToken } from '../../utils/getToken';
import { updateIsRecording } from '../Game/store';
import { useCanUseGameRecordMode } from './hooks/useCanUseGameRecordMode';
import { IDBChunkedStreamRecorder } from './IDBChunkedStreamRecorder';
import { type BlockRecorderState, RecordingState } from './types';
import { uncheckedIndexAccess_UNSAFE } from '../../utils/uncheckedIndexAccess_UNSAFE';

export const blockRecorderContext = createContext<null | BlockRecorderState>(
  null
);

export const useBlockRecorderState = (): BlockRecorderState => {
  const ctx = useContext(blockRecorderContext);
  if (!ctx) throw new Error('BlockRecorderContext not in tree');
  return ctx;
};

const useOptionalBlockRecorderState = (): BlockRecorderState | null => {
  const ctx = useContext(blockRecorderContext);
  return ctx;
};

export const useBlockRecorderStateGetter = (): (() => BlockRecorderState) => {
  const ctx = useBlockRecorderState();
  const ref = useRef(ctx);
  useEffect(() => {
    ref.current = ctx;
  });
  return useCallback(() => ref.current, []);
};

/** Use this in places where the Provider is not guaranteed to be in the tree */
export const useOptionalBlockRecorderStateGetter =
  (): (() => BlockRecorderState | null) => {
    const ctx = useOptionalBlockRecorderState();
    const ref = useRef(ctx);
    useEffect(() => {
      ref.current = ctx;
    });
    return useCallback(() => ref.current, []);
  };

const log = logger.scoped('block-recorder');

async function cleanupRecorder(handle: IDBChunkedStreamRecorder) {
  try {
    await handle.stop();
    await IDBChunkedStreamRecorder.DeleteRecording(handle.filename);
  } catch (err) {
    log.error(
      `failed to cleanup block recording video ${handle.filename}`,
      err
    );
  }
}

export async function resetRecordingState(
  state: BlockRecorderState,
  recordingIsAllowed: boolean
): Promise<void> {
  if (state.recording.countInRef) clearTimeout(state.recording.countInRef);
  if (state.refs.hostVideoFileHandle) {
    const handle = state.refs.hostVideoFileHandle;
    cleanupRecorder(handle);
  }

  const targetState =
    state.recording.state !== RecordingState.Disabled && recordingIsAllowed
      ? RecordingState.Primed
      : RecordingState.Disabled;

  await updateIsRecording(targetState !== RecordingState.Disabled);

  state.recording = {
    state: targetState,
    data: null,
    startTimestampMs: null,
    countInRef: null,
    countIn: null,
    elapsedSecondsRef: null,
    elapsedSeconds: null,
  };
}

export async function resetAutoAdvanceRecordingState(
  state: BlockRecorderState
): Promise<void> {
  state.autoAdvance =
    getFeatureQueryParamArray('block-recorder-auto-advance') === 'continue';
  state.hasManuallyStartedRecordingOnce = false;
}

export function useResetRecordingState(): () => void {
  const allowed = useCanUseGameRecordMode();
  const getState = useBlockRecorderStateGetter();

  return useCallback(() => {
    const state = getState();
    resetRecordingState(state, allowed);
  }, [allowed, getState]);
}

export function initializeState(
  configAPIBaseURL: string,
  initialRecorderVersion = 3
): BlockRecorderState {
  const UPLOADING_TIMEOUT = 1800 * 1000; // 30 Mins

  return {
    uploads: {},

    refs: ref({
      uppy: new Uppy({
        logger: logger.scoped('block-recorder-uppy'),
        autoProceed: true,
        allowMultipleUploads: true,
      }).use(XHRUpload, {
        endpoint: `${configAPIBaseURL}/media/upload`,
        method: 'post',
        formData: false,
        headers: (file) => {
          const extraHeaders = {
            authorization: `Bearer ${getToken()}`,
            'x-lp-scene': EnumsMediaScene.MediaSceneBlockHostRecordingV2,
          };
          if (file.type) {
            uncheckedIndexAccess_UNSAFE(extraHeaders)['Content-Type'] =
              file.type;
          }
          return extraHeaders;
        },
        timeout: UPLOADING_TIMEOUT,
        limit: 10,
      }),

      hostVideoFileHandle: null,
      hostVideoStream: null,
    }),

    autoAdvance:
      getFeatureQueryParamArray('block-recorder-auto-advance') === 'continue',
    hasManuallyStartedRecordingOnce: false,

    recording: {
      data: null,
      state: RecordingState.Disabled,
      startTimestampMs: null,
      countInRef: null,
      countIn: null,
      elapsedSecondsRef: null,
      elapsedSeconds: null,
    },

    recorderVersion: initialRecorderVersion,
  };
}
