import { format } from 'date-fns';
import type pptxgen from 'pptxgenjs';

import { type DtoGetOrgPresentationSummaryResponse } from '@lp-lib/api-service-client/public';

import lunaParkLogo from '../../../assets/img/luna-park-logo.png';
import { type Organization } from '../../../types';
import { getStaticAssetPath } from '../../../utils/assets';
import { xDomainifyUrl } from '../../../utils/common';
import { ImagePickPriorityLowToHigh, MediaUtils } from '../../../utils/media';

export interface AnalyticsSlideSharedProps {
  pres: pptxgen;
  organization: Organization;
  summary: DtoGetOrgPresentationSummaryResponse;
}

export function pxToInch(px: number) {
  return px / 96;
}

export function pxToPt(px: number) {
  return px * 0.75;
}

export function addSlideLayout(
  props: AnalyticsSlideSharedProps & {
    slide: pptxgen.Slide;
    title: string;
    subtitle: string;
  }
) {
  const { slide, organization, title, subtitle, summary } = props;

  slide.background = {
    path: getStaticAssetPath('images/analytics/slide-bg.png'),
  };

  const url = MediaUtils.PickMediaUrl(organization.logo, {
    priority: ImagePickPriorityLowToHigh,
  });
  if (url) {
    const path = xDomainifyUrl(url, 'analytics-slides');
    slide.addImage({
      path,
      x: pxToInch(50),
      y: pxToInch(50),
      w: pxToInch(120),
      h: pxToInch(120),
      rounding: true,
    });
  }

  slide.addText(title, {
    x: url ? pxToInch(200) : pxToInch(64),
    y: pxToInch(64),
    w: pxToInch(1200),
    h: pxToInch(58),
    color: 'FFFFFF',
    fontSize: pxToPt(48),
    bold: true,
    align: 'left',
  });
  slide.addText(subtitle, {
    x: url ? pxToInch(200) : pxToInch(64),
    y: pxToInch(130),
    w: pxToInch(1200),
    h: pxToInch(80),
    color: 'FFFFFF',
    fontSize: pxToPt(32),
    align: 'left',
    valign: 'top',
  });

  // Powered by
  slide.addImage({
    path: lunaParkLogo,
    x: pxToInch(1920 - 30 - 115),
    y: pxToInch(30),
    w: pxToInch(115),
    h: pxToInch(32),
  });
  slide.addText('Powered by', {
    x: pxToInch(1920 - 30 - 115 - 16 - 200),
    y: pxToInch(38),
    w: pxToInch(200),
    h: pxToInch(32),
    color: 'FFFFFF',
    fontSize: pxToPt(20),
    align: 'right',
    valign: 'bottom',
  });

  // Date
  // hide start date if it's all time
  const dateText = !summary.startAt
    ? format(new Date(summary.endAt), 'MM/dd/yyyy')
    : `${format(new Date(summary.startAt), 'MM/dd/yyyy')} - ${format(
        new Date(summary.endAt),
        'MM/dd/yyyy'
      )}`;
  slide.addText(dateText, {
    x: pxToInch(1920 - 30 - 600),
    y: pxToInch(1080 - 30 - 30),
    w: pxToInch(600),
    h: pxToInch(30),
    color: '8B9294',
    fontSize: pxToPt(20),
    align: 'right',
    valign: 'bottom',
  });
}

export function addBenchmarkCard(props: {
  slide: pptxgen.Slide;
  x: number;
  y: number;
  w: number;
  h: number;
  title: string;
  content: string;
}) {
  const { slide, x, y, w, h, title, content } = props;

  slide.addImage({
    path: getStaticAssetPath('images/analytics/slides-benchmark-bg.png'),
    x: pxToInch(x),
    y: pxToInch(y),
    w: pxToInch(w),
    h: pxToInch(h),
  });
  slide.addText(title, {
    fontSize: pxToPt(20),
    bold: true,
    color: 'FFFFFF',
    align: 'center',
    x: pxToInch(x + 20),
    y: pxToInch(y + 30),
    w: pxToInch(w - 40),
    h: pxToInch(30),
  });
  slide.addText(content, {
    fontSize: pxToPt(20),
    color: 'FFFFFF',
    align: 'center',
    x: pxToInch(x + 20),
    y: pxToInch(y + 80),
    w: pxToInch(w - 40),
    h: pxToInch(h - 30 - 60 - 20),
  });
}
