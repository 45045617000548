import { Link } from '@remix-run/react';
import pluralize from 'pluralize';
import {
  type KeyboardEventHandler,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { usePopperTooltip } from 'react-popper-tooltip';
import {
  components,
  type MultiValue,
  type MultiValueGenericProps,
  type SingleValue,
} from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { useDrop } from 'react-use';
import useSWRMutation from 'swr/mutation';

import {
  type DtoCheckInvitationResult,
  type DtoInvitationFailureDetails,
} from '@lp-lib/api-service-client/public';

import {
  apiService,
  type InvitedUser,
  type InviteOrganizersRequest,
} from '../../services/api-service';
import { type Organization, type Organizer } from '../../types';
import { err2s } from '../../utils/common';
import { buildReactSelectStyles } from '../../utils/react-select';
import { EMAIL_PATTERN } from '../Access';
import { useAwaitFullScreenConfirmCancelModal } from '../ConfirmCancelModalContext';
import { ModalWrapper } from '../ConfirmCancelModalContext/ModalWrapper';
import { AlertIcon } from '../icons/AlertIcon';

type InviteValidateResult =
  | 'success'
  | DtoCheckInvitationResult['failedReason'];

export interface InviteOption {
  label: string;
  value: string;
  failedReason: Nullable<InviteValidateResult>;
  failureDetails?: Nullable<DtoInvitationFailureDetails>;
}

function createNewOptions(old: InviteOption[], input: string) {
  const emails = input
    .trim()
    .split(/,|\s+/)
    .filter((s) => !!s);
  if (emails.length === 0) return old;

  const res = [...old];
  emails.forEach((email) => {
    if (
      res.findIndex(
        (o) => o.value.toLocaleLowerCase() === email.toLocaleLowerCase()
      ) === -1
    ) {
      res.push({
        label: email,
        value: email,
        failedReason: null,
      } as InviteOption);
    }
  });
  return res;
}

async function validateOptions(orgId: string, options: InviteOption[]) {
  for (const option of options) {
    if (!!option.failedReason) continue;
    if (!option.value.match(EMAIL_PATTERN)) {
      option.failedReason = 'invalid-format';
    }
  }

  const pendingValidateOptions = options.filter((o) => !o.failedReason);
  if (pendingValidateOptions.length === 0) return;
  const resp = await apiService.organization.checkOrganizersInvite(orgId, {
    emails: pendingValidateOptions.map((o) => o.value),
  });
  if (resp) {
    pendingValidateOptions.forEach((o) => {
      const failedResult = resp.data.failedResults.find(
        (r) => r.email.toLowerCase() === o.value.toLowerCase()
      );
      o.failedReason = failedResult ? failedResult.failedReason : 'success';
      o.failureDetails = failedResult?.failureDetails;
    });
  }
}

function InviteEmailAlter(props: {
  options: InviteOption[];
  onOptionsChange: (options: InviteOption[]) => void;
  disabled?: boolean;
}) {
  const { options, onOptionsChange, disabled } = props;

  const summary = useMemo(
    () => ({
      existingMemberCount: options.filter(
        (o) => o.failedReason === 'existing-member'
      ).length,
      anotherOrgCount: options.filter(
        (o) => o.failedReason === 'in-another-org'
      ).length,
      invalidFormatCount: options.filter(
        (o) => o.failedReason === 'invalid-format'
      ).length,
    }),
    [options]
  );

  return (
    <div className='text-sms flex flex-col gap-2'>
      {summary.invalidFormatCount > 0 && (
        <div className='flex items-center gap-2'>
          <AlertIcon />
          {pluralize('invalid email', summary.invalidFormatCount, true)}.
          <button
            type='button'
            className={`btn text-primary ${
              disabled ? 'pointer-events-none' : ''
            }`}
            onClick={() =>
              onOptionsChange(
                options.filter((o) => o.failedReason !== 'invalid-format')
              )
            }
          >
            Remove invalid emails
          </button>
        </div>
      )}

      {summary.existingMemberCount > 0 && (
        <div className='flex items-center gap-2'>
          <AlertIcon />
          {pluralize('existing member', summary.existingMemberCount, true)}.
          <button
            type='button'
            className={`btn text-primary ${
              disabled ? 'pointer-events-none' : ''
            }`}
            onClick={() =>
              onOptionsChange(
                options.filter((o) => o.failedReason !== 'existing-member')
              )
            }
          >
            Remove Duplicate
          </button>
        </div>
      )}

      {summary.anotherOrgCount > 0 && (
        <div className='flex items-center gap-2'>
          <AlertIcon />
          {pluralize('email', summary.anotherOrgCount, true)} in another
          Organization.
          <button
            type='button'
            className={`btn text-primary ${
              disabled ? 'pointer-events-none' : ''
            }`}
            onClick={() =>
              onOptionsChange(
                options.filter((o) => o.failedReason !== 'in-another-org')
              )
            }
          >
            Remove
          </button>
        </div>
      )}
    </div>
  );
}

function MultiValueContainer(props: MultiValueGenericProps<InviteOption>) {
  const { getTooltipProps, setTooltipRef, setTriggerRef, visible } =
    usePopperTooltip({
      placement: 'top',
      trigger: 'hover',
      interactive: true,
      delayHide: 150,
    });

  const option = props.data as InviteOption;
  if (
    option.failedReason === 'in-another-org' &&
    option.failureDetails?.inAnotherOrg
  ) {
    const { orgId, orgName } = option.failureDetails.inAnotherOrg;
    const params = new URLSearchParams({ q: option.value });
    const href = `/admin/organizations/${orgId}/members?${params.toString()}`;
    return (
      <div ref={setTriggerRef}>
        <components.MultiValueContainer {...props} />
        {visible && (
          <div
            ref={setTooltipRef}
            {...getTooltipProps({
              className: `w-auto h-auto rounded-md text-white text-2xs px-3 py-2 bg-black whitespace-nowrap border border-secondary`,
            })}
          >
            Already in{' '}
            <Link to={href} className='btn text-primary' target='_blank'>
              {orgName}
            </Link>
          </div>
        )}
      </div>
    );
  }

  return <components.MultiValueContainer {...props} />;
}

export function EmailInviteInput(props: {
  organization: Organization;
  options: InviteOption[];
  onChange: (options: InviteOption[]) => void;
  autoFocus?: boolean;
  isDisabled?: boolean;
}): JSX.Element {
  const { organization, options, onChange, autoFocus, isDisabled } = props;

  const styles = useMemo(
    () =>
      buildReactSelectStyles<InviteOption, true>({
        override: {
          control: {
            height: '100%',
            flexDirection: 'column',
            borderColor: 'transparent',
          },
          valueContainer: {
            width: '100%',
            padding: '10px',
            alignContent: 'flex-start',
            overflow: 'auto',
          },
          indicatorsContainer: {
            flexDirection: 'row-reverse',
          },
          multiValue: (data) => ({
            background: data.failedReason !== 'success' ? '#EE3529' : '#01ACC4',
          }),
        },
      }),
    []
  );
  const [input, setInput] = useState('');

  const convertInputToOptions = async (text?: string) => {
    const newOptions = createNewOptions(options, (text || '') + input);
    await validateOptions(organization.id, newOptions);
    onChange(newOptions);
    setInput('');
  };

  // todo: just listen to the copy & paste events in InputBox
  useDrop({
    onText: convertInputToOptions,
  });

  const handleInputChange = (inputValue: string) => {
    setInput(inputValue);
  };

  const handleBlur = () => {
    convertInputToOptions();
  };

  const handleKeyDown: KeyboardEventHandler<HTMLDivElement> = async (event) => {
    if (!input) return;
    if (!['Enter', ', ', ' ', 'Tab'].includes(event.key)) return;

    convertInputToOptions();
    event.preventDefault();
  };

  const handleChange = (
    newValue: MultiValue<InviteOption> | SingleValue<InviteOption>
  ) => {
    if (!newValue || !Array.isArray(newValue)) return;
    onChange([...newValue]);
  };

  const allSuccess = options.every((o) => o.failedReason === 'success');

  return (
    <div className='w-full flex flex-col gap-4'>
      <CreatableSelect
        isDisabled={isDisabled}
        menuIsOpen={false}
        isClearable={true}
        isMulti
        inputValue={input}
        value={options}
        onChange={handleChange}
        onBlur={handleBlur}
        onInputChange={handleInputChange}
        onKeyDown={handleKeyDown}
        placeholder='name@example.com'
        classNamePrefix='select-box-v2'
        className={`field mb-0 h-50 p-0 overflow-hidden scrollbar`}
        styles={styles}
        components={{
          DropdownIndicator: null,
          ClearIndicator: (props) => (
            <components.ClearIndicator {...props} children={'Clear all'} />
          ),
          MultiValueContainer,
        }}
        autoFocus={autoFocus}
      />

      {!allSuccess && (
        <div className='px-4'>
          <InviteEmailAlter
            options={options}
            onOptionsChange={onChange}
            disabled={input.length > 0}
          />
        </div>
      )}
    </div>
  );
}

function ResultStage(props: {
  members: Organizer[];
  onInviteMore: () => void;
  onCancel: () => void;
}) {
  const { members, onInviteMore, onCancel } = props;

  return (
    <div className='w-full px-10 py-8 text-white flex flex-col items-center'>
      <div>{`You've invited ${pluralize('person', members.length, true)}`}</div>
      <div className='mt-8 w-22 h-19 bg-confetti bg-no-repeat'></div>
      <div className='mt-8 px-4 w-full max-h-50 overflow-y-auto scrollbar'>
        {members.map((m) => (
          <div key={m.uid} className='my-3'>
            {`✓ ${m.email}`}
          </div>
        ))}
      </div>
      <div className='mt-8'>
        <button
          type='button'
          className='btn-secondary w-40 h-10 mx-2'
          onClick={onInviteMore}
        >
          Invite More
        </button>
        <button
          type='button'
          className='btn-primary w-40 h-10 mx-2'
          onClick={onCancel}
        >
          Done
        </button>
      </div>
    </div>
  );
}

function InviteStage(props: {
  organization: Organization;
  onCancel: () => void;
  onInvited: (organizers: Organizer[]) => void;
}): JSX.Element {
  const { organization, onCancel, onInvited } = props;

  const { trigger, isMutating, error } = useSWRMutation(
    `/organizations/${organization.id}/organizers/invite`,
    async (_, { arg }: { arg: InviteOrganizersRequest }) =>
      await apiService.organization.inviteOrganizers(organization.id, arg)
  );

  const [options, setOptions] = useState<InviteOption[]>([]);
  const [sendEmail, setSendEmail] = useState(true);

  const canInvite =
    options.length > 0 &&
    options.every((o) => o.failedReason === 'success') &&
    !isMutating;

  const handleInvite = async () => {
    const invitedUsers = options.map<InvitedUser>((v) => ({
      email: v.value,
    }));
    const resp = await trigger({
      invitedUsers,
      webEndpoint: window.location.origin,
      sendEmail: sendEmail,
    });
    if (resp) {
      onInvited(resp.data.organizers);
    }
  };

  return (
    <div className='w-full px-5 py-10 text-white'>
      <header className='w-full text-2xl text-center font-medium'>
        Invite Members
      </header>

      <main className='mt-8 w-full'>
        <label className='w-full flex flex-col items-start gap-2.5'>
          <h3 className='text-base font-bold'>Email Address</h3>
          <p className='text-base font-normal text-icon-gray'>
            Separate emails by comma, space or Enter/Return.
          </p>
          <EmailInviteInput
            organization={organization}
            options={options}
            onChange={setOptions}
            autoFocus
            isDisabled={isMutating}
          />
        </label>

        <label
          htmlFor='sendEmail'
          className='mt-8 w-full flex justify-center items-center gap-2.5 hover:cursor-pointer'
        >
          <input
            id='sendEmail'
            name='sendEmail'
            type='checkbox'
            className='w-4 h-4 checkbox-dark'
            checked={sendEmail}
            onChange={(event) => {
              setSendEmail(event.target.checked);
            }}
            disabled={isMutating}
          ></input>
          <p className='text-secondary text-sms'>
            Send invite notification via email
          </p>
        </label>
      </main>

      <footer className='mt-6 w-full'>
        {error && (
          <div className='w-full text-center text-sms text-red-002 mb-2'>
            {err2s(error)}
          </div>
        )}
        <div className='w-full flex justify-center items-center gap-4'>
          <button
            type='button'
            onClick={onCancel}
            className='btn btn-secondary w-40 h-10'
          >
            Cancel
          </button>
          <button
            type='button'
            onClick={handleInvite}
            className='btn btn-primary w-40 h-10'
            disabled={!canInvite}
          >
            {isMutating ? 'Inviting' : 'Invite'}
          </button>
        </div>
      </footer>
    </div>
  );
}

function EmailInviteModal(props: {
  organization: Organization;
  onClose: () => void;
  onInvited: (organizers: Organizer[]) => void;
}): JSX.Element {
  const { organization, onClose, onInvited } = props;

  const [invitedMembers, setInvitedMembers] = useState<Organizer[]>([]);

  const handleInvited = (members: Organizer[]) => {
    onInvited(members);
    setInvitedMembers(members);
  };

  const handleInviteMore = () => {
    setInvitedMembers([]);
  };

  return (
    <ModalWrapper borderStyle='white' containerClassName='w-160'>
      {invitedMembers.length > 0 ? (
        <ResultStage
          members={invitedMembers}
          onCancel={onClose}
          onInviteMore={handleInviteMore}
        />
      ) : (
        <InviteStage
          organization={organization}
          onCancel={onClose}
          onInvited={handleInvited}
        />
      )}
    </ModalWrapper>
  );
}

export interface TriggerEmailInviteModalProps {
  organization: Organization;
  onInvited: (organizers: Organizer[]) => void;
}

export function useTriggerEmailInviteModal(): (
  props: TriggerEmailInviteModalProps
) => void {
  const triggerFullScreenModal = useAwaitFullScreenConfirmCancelModal();

  return useCallback(
    (props: TriggerEmailInviteModalProps) => {
      triggerFullScreenModal({
        kind: 'custom',
        element: (p) => (
          <EmailInviteModal
            organization={props.organization}
            onClose={p.internalOnCancel}
            onInvited={props.onInvited}
          />
        ),
      });
    },
    [triggerFullScreenModal]
  );
}
