import type { DtoDerivedVenueSettings } from '@lp-lib/api-service-client/public';
import { useCallback } from 'react';
import { type FirebaseService, FirebaseValueHandle } from '../Firebase';
import { useVenueAPI } from './VenueProvider';

export function useFetchVenueSeatCapFromFirebase() {
  const api = useVenueAPI();
  return useCallback(
    async (svc: FirebaseService) => {
      const venueId = api.state.venue?.id;
      if (!venueId) return undefined;

      const handle = new FirebaseValueHandle<
        DtoDerivedVenueSettings['seatCap']
      >(svc.prefixedSafeRef(`venues/${venueId}/derived-settings/seatCap`));

      return handle.get();
    },
    [api]
  );
}
