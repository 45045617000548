import { useMyInstance } from '../../hooks/useMyInstance';
import { ClientTypeUtils, isStaff, type TeamV0 } from '../../types';
import { useTeams } from '../TeamAPI/TeamV1';
import { useMyClientType } from '../Venue/VenuePlaygroundProvider';

export function useLobbyTeams(): TeamV0[] {
  const me = useMyInstance();
  const myClientType = useMyClientType();
  const isAudience = ClientTypeUtils.isAudience(myClientType);
  const teams = useTeams({
    sort: true,
    pinFullTeamsToBottom: true,
    pinTeamId: me?.teamId,
    updateStaffTeam: true,
    excludeStaffTeam: isAudience && !isStaff(me),
  });
  return teams;
}
