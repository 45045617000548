import { type ReactNode } from 'react';

import { ArrowIcon } from '../../../../icons/ArrowIcon';
import { useIsStreamSessionAlive } from '../../../../Session';
import { useIsLiveGamePlay } from '../../../hooks';

export function ControllerLayout(props: {
  children?: ReactNode;
  action?: ReactNode;
}): JSX.Element {
  const isLive = useIsLiveGamePlay();
  return (
    <div
      className={`relative w-full h-full pt-0 pb-2.5 flex flex-col ${
        isLive ? 'justify-between' : 'justify-end'
      } items-center text-white text-sms overflow-hidden`}
    >
      {props.children}
      {props.action && <div className='w-full px-2.5'>{props.action}</div>}
      {isLive && <StreamRequiredNotice />}
    </div>
  );
}

export function StreamRequiredNotice(): JSX.Element | null {
  const isSessionAlive = useIsStreamSessionAlive();
  return !isSessionAlive ? (
    <div className='absolute bottom-0 w-full h-full bg-black bg-opacity-90 text-red-005 flex flex-col justify-center items-center text-sm font-bold'>
      Start Stream to activate the Block
    </div>
  ) : null;
}

export function DecreasingPointsBadge({
  decreasingPointsTimer,
  disabled,
}: {
  decreasingPointsTimer: boolean | undefined;
  disabled?: boolean;
}): JSX.Element | null {
  if (!decreasingPointsTimer) return null;

  return (
    <button
      id='controller-editing-toggle-decreasingpoints'
      disabled={disabled}
      className='btn mr-2 w-8 h-8 bg-gradient-to-tr from-primary-start to-primary-end border border-primary rounded-full text-xs font-bold flex flex-col items-center justify-center pointer-events-off'
    >
      <ArrowIcon />
      <span className='text-4xs'>PTS</span>
    </button>
  );
}

export const formatVideoCounting = (count: number): string =>
  `(${new Date(count * 1000).toISOString().substring(14, 19)})`;
