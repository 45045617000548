import { format } from 'date-fns';
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
import { useMemo } from 'react';
import ReactDatePicker from 'react-datepicker';
import { type ITimezoneOption } from 'react-timezone-select';

import { useInstance } from '../../hooks/useInstance';
import { buildReactSelectStyles } from '../../utils/react-select';
import { TimeUtils } from '../../utils/time';
import { ReactTimezoneSelectTypeFixed } from '../ReactTimezoneSelectTypeFixed';

type Datetime = {
  date: Date;
  timezone: string;
};

export function DateTimePicker(props: {
  value?: Datetime;
  onChange: (value: Datetime) => void;
  disabled?: boolean;
  disableTimezonePicker?: boolean;
  filterDate?: (date: Date) => boolean;
  timeIntervals?: number;
}): JSX.Element {
  const localTimeZone = useInstance(
    () => Intl.DateTimeFormat().resolvedOptions().timeZone
  );
  const timeIntervals = props.timeIntervals ?? 30;
  const { date, timezone } = useMemo(() => {
    return {
      date:
        props.value?.date ??
        TimeUtils.RoundToNearest(new Date(), timeIntervals),
      timezone: props.value?.timezone ?? localTimeZone,
    };
  }, [localTimeZone, props.value?.date, props.value?.timezone, timeIntervals]);
  const localizedStartDate = useMemo(() => {
    const selected = zonedTimeToUtc(date, timezone);
    const user = zonedTimeToUtc(date, localTimeZone);
    return selected.getTime() !== user.getTime() ? user : undefined;
  }, [date, timezone, localTimeZone]);

  const styles = useMemo(
    () =>
      buildReactSelectStyles<ITimezoneOption>({
        override: {
          control: { height: '40px' },
        },
      }),
    []
  );

  return (
    <div>
      <div className='flex items-center text-white'>
        <div>
          <ReactDatePicker
            className='field h-10 mb-0'
            selected={utcToZonedTime(date, timezone)}
            onChange={(change) => {
              if (!change) return;
              props.onChange({
                date: zonedTimeToUtc(change, timezone),
                timezone,
              });
            }}
            minDate={new Date()}
            dateFormat='MMMM d, yyyy'
            disabled={props.disabled}
            filterDate={props.filterDate}
          />
        </div>

        <div
          className={`mx-4 ${props.disabled ? 'text-secondary' : 'text-white'}`}
        >
          at
        </div>

        <div>
          <ReactDatePicker
            className='field h-10 mb-0 flex-none w-24 text-center'
            selected={utcToZonedTime(date, timezone)}
            onChange={(change) => {
              if (!change) return;
              // note: there seems to be a bug in the library where manually inputting the time will reset the day.
              // as a workaround, we manually set the time here.
              const updated = utcToZonedTime(date, timezone);
              updated.setHours(change.getHours());
              updated.setMinutes(change.getMinutes());
              updated.setSeconds(0);
              updated.setMilliseconds(0);

              props.onChange({
                date: zonedTimeToUtc(updated, timezone),
                timezone,
              });
            }}
            minDate={new Date()}
            showTimeSelect
            showTimeSelectOnly
            timeIntervals={timeIntervals}
            dateFormat='h:mm aa'
            disabled={props.disabled}
          />
        </div>

        <div className='hidden md:flex ml-2'>
          <ReactTimezoneSelectTypeFixed
            value={timezone}
            className='select-box text-white w-80'
            classNamePrefix='select-box-v2'
            styles={styles}
            onChange={(change) => {
              if (!change) return;
              props.onChange({
                date: zonedTimeToUtc(
                  utcToZonedTime(date, timezone),
                  change.value
                ),
                timezone: change.value,
              });
            }}
            isDisabled={props.disabled || props.disableTimezonePicker}
          />
        </div>
      </div>

      {localizedStartDate && (
        <div className='mt-2 text-xs text-secondary'>
          For your local time zone that‘s:{' '}
          {format(localizedStartDate, 'MMMM d, yyyy h:mm aa')}
        </div>
      )}
    </div>
  );
}
