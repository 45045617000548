import { useEffect } from 'react';
import { usePreviousDistinct } from 'react-use';

import { assertExhaustive } from '@lp-lib/game';

import { LayoutAnchor } from '../../../LayoutAnchors/LayoutAnchors';
import { useSoundEffect } from '../../../SFX';
import { useOverRoastedGamePlayAPI } from './OverRoastedProvider/OverRoastedGamePlayProvider';
import { Sprite, type SpriteNames } from './Sprite';
import {
  type Cup,
  type Dispenser,
  DispenserState,
  type GroupId,
  type Machine,
} from './types';

function useDispenserSpriteName(dispenser: Dispenser): SpriteNames {
  switch (dispenser.state) {
    case DispenserState.Default:
      return 'dispenser-default';
    case DispenserState.Ready:
      return 'dispenser-ready';
    case DispenserState.Filling:
      return 'dispenser-disabled';
    case DispenserState.Serve:
      return 'dispenser-serve';
    case DispenserState.Served:
      return 'dispenser-disabled';
    case DispenserState.Delete:
      return 'dispenser-delete';
    default:
      assertExhaustive(dispenser.state);
      throw new Error(`unkown state: ${dispenser.state}`);
  }
}

export function DispenserButton(props: {
  groupId: GroupId;
  machine: Machine;
  cup: Cup;
  onDelete: () => Promise<void>;
}): JSX.Element {
  const { groupId, machine, onDelete } = props;

  const dispenser = machine.dispenser;
  const spriteName = useDispenserSpriteName(dispenser);
  const api = useOverRoastedGamePlayAPI();
  const clickable =
    dispenser.state === DispenserState.Ready ||
    dispenser.state === DispenserState.Serve ||
    dispenser.state === DispenserState.Delete;
  const { play: playMakeCoffeeSFX } = useSoundEffect('overRoastedMakeCoffee');

  const currState = dispenser.state;
  const prevState = usePreviousDistinct(dispenser.state);

  useEffect(() => {
    if (
      prevState !== undefined &&
      prevState !== DispenserState.Filling &&
      currState === DispenserState.Filling
    ) {
      playMakeCoffeeSFX();
    }
  }, [currState, playMakeCoffeeSFX, prevState]);

  const onClick = async () => {
    if (!clickable) return;

    switch (dispenser.state) {
      case DispenserState.Ready:
        await api.startDispenser(machine.truckId, machine.id);
        break;
      case DispenserState.Serve:
        await api.serve(groupId, machine.truckId, machine.id);
        break;
      case DispenserState.Delete:
        await onDelete();
        break;
      case DispenserState.Default:
      case DispenserState.Filling:
      case DispenserState.Served:
        break;
      default:
        assertExhaustive(dispenser.state);
        break;
    }
  };

  return (
    <div
      className={`relative z-[2] ${clickable ? 'cursor-pointer' : ''}`}
      onClick={onClick}
    >
      <LayoutAnchor
        id={`over-roasted-dispenser-${machine.id}`}
        className='absolute w-full h-full'
        layoutReportDelayMs={500}
      />

      <Sprite name={spriteName} />
    </div>
  );
}
