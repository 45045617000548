// @ts-check

// Specify app-wide breakpoints here, and with TS 4.5+ {const} annotation, so
// the breakpoint keys and values themselves can be referenced in both the app
// and the tailwind config. An alternative is to write this as TS, and output JS
// as a pre-build watcher step. Might need that eventually, but resisting that
// complexity for now.

const breakpointsUnitless = /** @type {const} */ Object.freeze({
  sm: 640,
  md: 768,
  lg: 1024,
  xl: 1280,
  'lp-sm': 1366,
  '2xl': 1440,
  '3xl': 1536,
  'lp-md': 1920,
  'lp-lg': 2200,
});

/**
 * @typedef {{ [K in keyof typeof breakpointsUnitless]: `${typeof breakpointsUnitless[K]}px`; }} ScreensWithUnit
 * Type that converts the "unitless" numbers into a string with a `px` suffix.
 */

/** @type {ScreensWithUnit} */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const tailwindBreakpointConfig = Object.keys(breakpointsUnitless).reduce(
  (screens, unitless) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    screens[unitless] = `${breakpointsUnitless[unitless]}px`;
    return screens;
  },
  {
    sm: '0px',
    md: '0px',
    lg: '0px',
    xl: '0px',
    'lp-sm': '0px',
    '2xl': '0px',
    '3xl': '0px',
    'lp-md': '0px',
    'lp-lg': '0px',
  }
);

const ratio = 16 / 10;

/**
 * @typedef {{ [K in `h-${keyof typeof breakpointsUnitless}`]: { raw: `(max-height: ${number}px)` } }} MaxHeightWithUnit
 * Type that converts the "unitless" numbers into a string with a `px` suffix.
 */

/** @type {MaxHeightWithUnit} */
const tailwindMaxHeightBreakpointConfig = Object.keys(
  breakpointsUnitless
).reduce(
  (screens, unitless) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    screens[`h-${unitless}`] = {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      raw: `(max-height: ${breakpointsUnitless[unitless] / ratio}px)`,
    };
    return screens;
  },
  {
    'h-sm': { raw: '(max-height: 0px)' },
    'h-md': { raw: '(max-height: 0px)' },
    'h-lg': { raw: '(max-height: 0px)' },
    'h-xl': { raw: '(max-height: 0px)' },
    'h-lp-sm': { raw: '(max-height: 0px)' },
    'h-2xl': { raw: '(max-height: 0px)' },
    'h-3xl': { raw: '(max-height: 0px)' },
    'h-lp-md': { raw: '(max-height: 0px)' },
    'h-lp-lg': { raw: '(max-height: 0px)' },
  }
);

exports.breakpointsUnitless = breakpointsUnitless;
exports.tailwindBreakpointConfig = tailwindBreakpointConfig;
exports.tailwindMaxHeightBreakpointConfig = tailwindMaxHeightBreakpointConfig;

// export {
//   breakpointsUnitless,
//   tailwindBreakpointConfig,
//   tailwindMaxHeightBreakpointConfig,
// };
