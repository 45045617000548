import { type CSSProperties, useEffect, useMemo } from 'react';
import { usePreviousDistinct } from 'react-use';

import { assertExhaustive } from '@lp-lib/game';

import { useInstance } from '../../../../hooks/useInstance';
import { nullOrUndefined, uuidv4 } from '../../../../utils/common';
import {
  type Classes,
  StagedTailwindTransition,
  type TailwindTransitionStage,
} from '../../../common/TailwindTransition';
import { LayoutAnchor } from '../../../LayoutAnchors/LayoutAnchors';
import { useSoundEffect } from '../../../SFX';
import { ImgSFXAnimation } from '../../GameBlockCardAnimations';
import {
  useOverRoastedGamePlayAPI,
  useOverRoastedOrderPoints,
} from './OverRoastedProvider/OverRoastedGamePlayProvider';
import { Sprite, type SpriteNames } from './Sprite';
import {
  type Cup,
  CupState,
  type Machine,
  TRASH_ANIMATED_IMAGE_URL,
  type Truck,
} from './types';
import { OverRoastedUtils } from './utils';

function CupMatched(props: { machine: Machine }): JSX.Element {
  const { play } = useSoundEffect('overRoastedServeCoffee');
  const points = useOverRoastedOrderPoints(props.machine);

  const capStages = useInstance<TailwindTransitionStage<Classes>[]>(() => {
    return [
      { classes: '-top-5' },
      { classes: 'top-0' },
      { classes: 'top-0', delay: 300 },
    ];
  });
  const cupStages = useInstance<TailwindTransitionStage<Classes>[]>(() => {
    return [{ classes: '-bottom-5' }, { classes: 'bottom-0' }];
  });

  useEffect(() => {
    play();
  }, [play]);

  return (
    <div className='w-full h-full flex flex-col items-center justify-center relative'>
      <StagedTailwindTransition
        stages={capStages}
        debugKey='over-roasted-cup-cap'
      >
        {(ref, initial) => (
          <div
            ref={ref}
            className={`absolute ${initial} z-[2] duration-700 transition-position`}
          >
            <Sprite name='cup-cap' />
          </div>
        )}
      </StagedTailwindTransition>
      <StagedTailwindTransition stages={cupStages} debugKey='over-roasted-cup'>
        {(ref, initial) => (
          <div
            ref={ref}
            className={`absolute ${initial} duration-700 transition-position`}
          >
            <Sprite name='cup-filled' />
            <div className='text-green-001 text-2xl font-bold text-center absolute top-1/2 left-1/2 transform -translate-x-1/2 '>
              {points}
            </div>
          </div>
        )}
      </StagedTailwindTransition>
    </div>
  );
}

function CupMismatched(props: {
  machine: Machine;
  spriteName: SpriteNames;
}): JSX.Element {
  const { play } = useSoundEffect('rapidWrong');
  useEffect(() => {
    play();
  }, [play]);
  return (
    <div className='w-full h-full relative'>
      <Sprite name={props.spriteName} width={100} />
      <div className='absolute w-full h-full inset-0 z-[4]'>
        <Sprite name='overlay-no-match' width={100} />
      </div>
    </div>
  );
}

function CupSprite(props: { machine: Machine; cup: Cup }): JSX.Element | null {
  const { machine, cup } = props;
  const color = OverRoastedUtils.GetCupColor(machine.index);
  switch (cup.state) {
    case CupState.Default:
      return <Sprite name={`cup-${color}-default`} width={100} />;
    case CupState.Selected:
      return <Sprite name={`cup-${color}-selected`} width={100} />;
    case CupState.Filling:
      return <Sprite name={`cup-${color}-default`} width={100} />;
    case CupState.Overfilled:
      return <Sprite name={`cup-${color}-overfilled`} width={100} />;
    case CupState.Deleting:
      return <TrashOverlay />;
    case CupState.Matched:
      return <CupMatched machine={machine} />;
    case CupState.Mismatched:
      return (
        <CupMismatched machine={machine} spriteName={`cup-${color}-default`} />
      );
    default:
      assertExhaustive(cup.state);
      return null;
  }
}

function CoffeeCupIngredients(props: { cup: Cup }): JSX.Element | null {
  const { cup } = props;
  const ingredients = useMemo(() => {
    if (!cup.ingredients) return [];
    return cup.ingredients.map((ingredient, i) => {
      if (i === 0) {
        return { ingredient, left: '40px', bottom: '8px', hide: false };
      } else if (i === 1) {
        return { ingredient, left: '24px', bottom: '30px', hide: false };
      } else if (i === 2) {
        return { ingredient, left: '44px', bottom: '48px', hide: false };
      } else {
        return { ingredient, left: '0px', bottom: '0px', hide: true };
      }
    });
  }, [cup.ingredients]);

  const { play } = useSoundEffect('overRoastedAddIngredient');
  const currNum = ingredients.length;
  const prevNum = usePreviousDistinct(currNum);

  useEffect(() => {
    if (currNum > 0 && !nullOrUndefined(prevNum) && currNum > prevNum) {
      play();
    }
  }, [currNum, play, prevNum]);

  return (
    <div className='absolute top-0 left-0 w-full h-full z-[3]'>
      {ingredients.map((i, index) => (
        <div
          key={`${i.ingredient}-${index}`}
          className='absolute'
          style={{
            left: i.left,
            bottom: i.bottom,
            display: i.hide ? 'none' : undefined,
          }}
        >
          <Sprite name={`ingredient-${i.ingredient}-placed`} width={32} />
        </div>
      ))}
    </div>
  );
}

function DeleteOverlay(props: { onClick?: () => void }): JSX.Element | null {
  const { onClick } = props;
  return (
    <div
      className='absolute top-full w-auto opacity-0 group-hover:opacity-100 cursor-pointer transition-opacity z-5'
      onClick={onClick}
    >
      <Sprite name='cup-trash-button' width={36} />
    </div>
  );
}

function TrashOverlay(): JSX.Element | null {
  return (
    <ImgSFXAnimation
      className='w-25 h-25 flex items-center justify-center'
      imgSrc={TRASH_ANIMATED_IMAGE_URL}
      imgClassName='w-full'
      imgDurationMs={500}
      imgDelayMs={0}
      imgAlt='trash'
      sfxKey='overRoastedTrashCup'
      useFloatLayout={false}
    />
  );
}

function SelectCupTooltipBackground(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      className={props.className}
      xmlns='http://www.w3.org/2000/svg'
      width='292'
      height='64'
      fill='none'
      viewBox='0 0 292 64'
    >
      <path
        fill='#EE3529'
        fillRule='evenodd'
        d='M150.318 8L141.5 0l-8.818 8H12C5.373 8 0 13.373 0 20v32c0 6.627 5.373 12 12 12h268c6.627 0 12-5.373 12-12V20c0-6.627-5.373-12-12-12H150.318z'
        clipRule='evenodd'
      ></path>
    </svg>
  );
}

function SelectCupTooltip() {
  return (
    <div
      className='absolute z-5 top-[calc(100%+12px)] w-72 h-16 transform animate-vertical-shake-repeated'
      style={
        {
          '--tw-vertical-shake-duration': '1s',
        } as CSSProperties
      }
    >
      <div className='absolute w-full h-full'>
        <SelectCupTooltipBackground className='w-full h-full' />
      </div>

      <div
        className={`absolute pt-2 px-8 w-full h-full flex items-center justify-center text-center text-base text-white font-medium`}
        style={{
          textShadow: '0px 1px 2px rgba(0, 0, 0, 0.8)',
        }}
      >
        Select a cup before adding an ingredient!
      </div>
    </div>
  );
}

function usePlaySelectCupSFX(cup: Cup) {
  const { play } = useSoundEffect('overRoastedSelectCup');
  const currState = cup.state;
  const prevState = usePreviousDistinct(currState);

  useEffect(() => {
    const toSelected =
      prevState === CupState.Default && currState === CupState.Selected;
    if (toSelected) {
      play();
    }
  }, [currState, play, prevState]);
}

function useClearLocalState(truckId: number, machineId: string, cup: Cup) {
  const currState = cup.state;
  const prevState = usePreviousDistinct(currState);
  const api = useOverRoastedGamePlayAPI();

  useEffect(() => {
    const toFilling =
      currState === CupState.Filling && prevState !== CupState.Filling;
    const toDeleting =
      currState === CupState.Deleting && prevState !== CupState.Deleting;
    if (toFilling || toDeleting) {
      api.clearLocalState(truckId, machineId);
    }
  }, [api, currState, machineId, prevState, truckId]);
}

export function CoffeeCup(props: {
  truck: Truck;
  machine: Machine;
  cup: Cup;
  fillingPercentage: number;
  delete: {
    deletable: boolean;
    onDelete: () => void;
  };
  showSelectCupTooltip?: boolean;
}): JSX.Element {
  const { truck, machine, cup, fillingPercentage, showSelectCupTooltip } =
    props;
  const api = useOverRoastedGamePlayAPI();
  const clickable = cup.state === CupState.Default;
  usePlaySelectCupSFX(cup);
  useClearLocalState(truck.id, machine.id, cup);

  const handleClick = async () => {
    if (!clickable) return;
    await api.activateMachine(machine.truckId, machine.id);
  };

  const { deletable, onDelete } = props.delete;

  return (
    <div
      className={`${clickable ? 'cursor-pointer' : ''} relative group w-25 ${
        cup.state === CupState.Matched ? 'h-25' : ''
      } flex justify-center items-center`}
      onClick={handleClick}
    >
      <LayoutAnchor
        id={`over-roasted-cup-${machine.id}`}
        className='absolute w-full h-full'
        layoutReportDelayMs={500}
      />

      <CupSprite machine={machine} cup={cup} />

      {[CupState.Filling, CupState.Mismatched].includes(cup.state) && (
        <FillingState percentage={fillingPercentage} />
      )}

      {[
        CupState.Default,
        CupState.Selected,
        CupState.Filling,
        CupState.Mismatched,
      ].includes(cup.state) && <CoffeeCupIngredients cup={cup} />}

      {deletable && <DeleteOverlay onClick={onDelete} />}

      {showSelectCupTooltip && <SelectCupTooltip />}
    </div>
  );
}

function FillingState(props: { percentage: number }): JSX.Element | null {
  const { percentage } = props;
  const normalized = Math.min(Math.max(percentage, 0), 1);
  const maxHeight = useInstance(
    () => OverRoastedUtils.CupStyleConfig().fillingMaxHeight
  );
  const filledY = maxHeight * (1 - normalized);
  const id = useInstance(() => uuidv4());

  return (
    <div className='absolute bottom-1 left-4.5'>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='64'
        height={maxHeight}
        fill='none'
        viewBox={`0 0 64 ${maxHeight}`}
      >
        <g filter={`url(#filter_${id})`}>
          <path
            fill={`url(#paint_${id})`}
            d='M54.588 92H9.412L0 0h64l-9.412 92z'
          ></path>
        </g>
        <defs>
          <filter
            id={`filter_${id}`}
            width='64'
            height={maxHeight}
            x='0'
            y={filledY}
            colorInterpolationFilters='sRGB'
            filterUnits='userSpaceOnUse'
          >
            <feFlood floodOpacity='0' result='BackgroundImageFix'></feFlood>
            <feBlend
              in='SourceGraphic'
              in2='BackgroundImageFix'
              result='shape'
            ></feBlend>
            <feColorMatrix
              in='SourceAlpha'
              result='hardAlpha'
              values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            ></feColorMatrix>
            <feOffset></feOffset>
            <feGaussianBlur stdDeviation='8'></feGaussianBlur>
            <feComposite
              in2='hardAlpha'
              k2='-1'
              k3='1'
              operator='arithmetic'
            ></feComposite>
            <feColorMatrix values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0'></feColorMatrix>
            <feBlend in2='shape' result='effect1_innerShadow_26_190'></feBlend>
          </filter>
          <linearGradient
            id={`paint_${id}`}
            x1='6'
            x2='32'
            y1='46'
            y2='44'
            gradientUnits='userSpaceOnUse'
          >
            <stop offset='0' stopColor='#4A2611'></stop>
            <stop offset='0.318' stopColor='#824726'></stop>
            <stop offset='1' stopColor='#4A2611'></stop>
          </linearGradient>
        </defs>
      </svg>
    </div>
  );
}
