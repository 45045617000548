import range from 'lodash/range';
import pluralize from 'pluralize';
import { useMemo } from 'react';
import { useController } from 'react-hook-form';
import Select from 'react-select';

import {
  EnumsMessageTriggerEventName,
  EnumsMessageTriggerTimeOffsetDirection,
  EnumsMessageTriggerType,
  type ModelsMessageTrigger,
  type ModelsMessageTriggerTimeOffset,
  type ModelsMessageTriggerWeekdaysOffset,
} from '@lp-lib/api-service-client/public';

import { useInstance } from '../../hooks/useInstance';
import { buildReactSelectStyles } from '../../utils/react-select';
import { type Option } from '../common/Utilities';
import { type MessageTriggerConfig } from './MessageCampaign';
import { type MessageLogicFormData } from './MessageLogicEditor';

export interface MessageTriggerTypeConfig {
  label: string;
  value: EnumsMessageTriggerType;
  makeDefault: (config: MessageTriggerConfig) => ModelsMessageTrigger;
}

export const MESSAGE_TRIGGER_TYPE_CONFIG: Record<
  EnumsMessageTriggerType,
  MessageTriggerTypeConfig
> = {
  [EnumsMessageTriggerType.MessageTriggerTypeTimeOffset]: {
    label: 'Time Offset',
    value: EnumsMessageTriggerType.MessageTriggerTypeTimeOffset,
    makeDefault: (config: MessageTriggerConfig) => ({
      type: EnumsMessageTriggerType.MessageTriggerTypeTimeOffset,
      timeOffset: {
        baseTime: config.startTime,
        days: 0,
        hours: 0,
        minutes: 0,
        direction:
          EnumsMessageTriggerTimeOffsetDirection.MessageTriggerTimeOffsetDirectionAfter,
      },
    }),
  },
  [EnumsMessageTriggerType.MessageTriggerTypeWeekdaysOffset]: {
    label: 'Weekdays Offset',
    value: EnumsMessageTriggerType.MessageTriggerTypeWeekdaysOffset,
    makeDefault: (config: MessageTriggerConfig) => ({
      type: EnumsMessageTriggerType.MessageTriggerTypeWeekdaysOffset,
      weekdaysOffset: {
        baseTime: config.startTime,
        days: 0,
        hours: 0,
        minutes: 0,
        direction:
          EnumsMessageTriggerTimeOffsetDirection.MessageTriggerTimeOffsetDirectionAfter,
      },
    }),
  },
  [EnumsMessageTriggerType.MessageTriggerTypeEventDriven]: {
    label: 'Event Driven',
    value: EnumsMessageTriggerType.MessageTriggerTypeEventDriven,
    makeDefault: (config: MessageTriggerConfig) => ({
      type: EnumsMessageTriggerType.MessageTriggerTypeEventDriven,
      eventName: config.events?.[0],
    }),
  },
};

export const makeDefaultMessageTrigger = (
  config: MessageTriggerConfig
): ModelsMessageTrigger => {
  return MESSAGE_TRIGGER_TYPE_CONFIG[config.types[0]].makeDefault(config);
};

const TRIGGER_TYPE_SELECT_STYLES = buildReactSelectStyles<
  Option<EnumsMessageTriggerType>
>({
  override: { control: { height: 48 } },
});

function MessageTriggerTypeSelect(props: {
  value: EnumsMessageTriggerType;
  supportedSources: EnumsMessageTriggerType[];
  onChange: (value: EnumsMessageTriggerType) => void;
}) {
  const { value, supportedSources, onChange } = props;

  const options = useMemo(
    () => supportedSources.map((source) => MESSAGE_TRIGGER_TYPE_CONFIG[source]),
    [supportedSources]
  );

  return (
    <Select<Option<EnumsMessageTriggerType>>
      options={options}
      value={MESSAGE_TRIGGER_TYPE_CONFIG[value]}
      classNamePrefix='select-box-v2'
      className='w-80'
      styles={TRIGGER_TYPE_SELECT_STYLES}
      onChange={(option) => {
        if (!option) return;
        onChange(option.value);
      }}
    />
  );
}

function timeToOption(count: number, word: string) {
  return {
    label: pluralize(word, count, true),
    value: count,
  };
}

const DAYS_OPTIONS = range(0, 100).map((days) => timeToOption(days, 'day'));
const HOURS_OPTIONS = range(0, 24).map((hours) => timeToOption(hours, 'hour'));
const MINUTES_OPTIONS = range(0, 60).map((minutes) =>
  timeToOption(minutes, 'min')
);

const TIME_STYLES = buildReactSelectStyles<Option<number>>({
  override: { control: { height: 48 } },
});

function MessageTriggerDaysSelect(props: {
  value: number;
  onChange: (value: number) => void;
}) {
  return (
    <Select<Option<number>>
      options={DAYS_OPTIONS}
      value={timeToOption(props.value, 'day')}
      classNamePrefix='select-box-v2'
      className='w-40'
      styles={TIME_STYLES}
      onChange={(option) => {
        if (!option) return;
        props.onChange(option.value);
      }}
    />
  );
}

function MessageTriggerHoursSelect(props: {
  value: number;
  onChange: (value: number) => void;
}) {
  return (
    <Select<Option<number>>
      options={HOURS_OPTIONS}
      value={timeToOption(props.value, 'hour')}
      classNamePrefix='select-box-v2'
      className='w-40'
      styles={TIME_STYLES}
      onChange={(option) => {
        if (!option) return;
        props.onChange(option.value);
      }}
    />
  );
}

function MessageTriggerMinutesSelect(props: {
  value: number;
  onChange: (value: number) => void;
}) {
  return (
    <Select<Option<number>>
      options={MINUTES_OPTIONS}
      value={timeToOption(props.value, 'min')}
      classNamePrefix='select-box-v2'
      className='w-40'
      styles={TIME_STYLES}
      onChange={(option) => {
        if (!option) return;
        props.onChange(option.value);
      }}
    />
  );
}

const DIRECTION_CONFIG = {
  [EnumsMessageTriggerTimeOffsetDirection.MessageTriggerTimeOffsetDirectionBefore]:
    {
      label: 'Before',
      value:
        EnumsMessageTriggerTimeOffsetDirection.MessageTriggerTimeOffsetDirectionBefore,
    },
  [EnumsMessageTriggerTimeOffsetDirection.MessageTriggerTimeOffsetDirectionAfter]:
    {
      label: 'After',
      value:
        EnumsMessageTriggerTimeOffsetDirection.MessageTriggerTimeOffsetDirectionAfter,
    },
};

function MessageTriggerTimeDirectionSelect(props: {
  value: EnumsMessageTriggerTimeOffsetDirection;
  onChange: (value: EnumsMessageTriggerTimeOffsetDirection) => void;
}) {
  const styles = useInstance(() =>
    buildReactSelectStyles<Option<EnumsMessageTriggerTimeOffsetDirection>>({
      override: { control: { height: 48 } },
    })
  );

  return (
    <Select<Option<EnumsMessageTriggerTimeOffsetDirection>>
      options={Object.values(DIRECTION_CONFIG)}
      value={DIRECTION_CONFIG[props.value]}
      classNamePrefix='select-box-v2'
      className='w-40'
      styles={styles}
      onChange={(option) => {
        if (!option) return;
        props.onChange(option?.value);
      }}
    />
  );
}

export function MessageTriggerTimeOffsetEditor(props: {
  timeOffset: ModelsMessageTriggerTimeOffset;
  onChange: (timeOffset: ModelsMessageTriggerTimeOffset) => void;
}) {
  const handleChange = (updates: Partial<ModelsMessageTriggerTimeOffset>) => {
    props.onChange({ ...props.timeOffset, ...updates });
  };

  return (
    <div className='flex items-center gap-2'>
      <MessageTriggerDaysSelect
        value={props.timeOffset.days}
        onChange={(days) => handleChange({ days })}
      />
      <MessageTriggerHoursSelect
        value={props.timeOffset.hours}
        onChange={(hours) => handleChange({ hours })}
      />
      <MessageTriggerMinutesSelect
        value={props.timeOffset.minutes}
        onChange={(minutes) => handleChange({ minutes })}
      />
      <MessageTriggerTimeDirectionSelect
        value={props.timeOffset.direction}
        onChange={(direction) => handleChange({ direction })}
      />
      <p>the start date.</p>
    </div>
  );
}

export function MessageTriggerWeekdaysOffsetEditor(props: {
  weekdaysOffset: ModelsMessageTriggerWeekdaysOffset;
  onChange: (weekdaysOffset: ModelsMessageTriggerWeekdaysOffset) => void;
}) {
  const handleChange = (
    updates: Partial<ModelsMessageTriggerWeekdaysOffset>
  ) => {
    props.onChange({ ...props.weekdaysOffset, ...updates });
  };

  return (
    <div className='flex items-center gap-2'>
      <MessageTriggerDaysSelect
        value={props.weekdaysOffset.days}
        onChange={(days) => handleChange({ days })}
      />
      <MessageTriggerHoursSelect
        value={props.weekdaysOffset.hours}
        onChange={(hours) => handleChange({ hours })}
      />
      <MessageTriggerMinutesSelect
        value={props.weekdaysOffset.minutes}
        onChange={(minutes) => handleChange({ minutes })}
      />
      <MessageTriggerTimeDirectionSelect
        value={props.weekdaysOffset.direction}
        onChange={(direction) => handleChange({ direction })}
      />
      <p>the start date.</p>
    </div>
  );
}

export const MESSAGE_TRIGGER_EVENT_CONFIG: {
  [Property in EnumsMessageTriggerEventName]: Option<EnumsMessageTriggerEventName>;
} = {
  [EnumsMessageTriggerEventName.MessageTriggerEventNameGroupsAssigned]: {
    label: 'Groups Assigned',
    value: EnumsMessageTriggerEventName.MessageTriggerEventNameGroupsAssigned,
  },
  [EnumsMessageTriggerEventName.MessageTriggerEventNameGroupMembersDownToOne]: {
    label: 'Group Members Down To One',
    value:
      EnumsMessageTriggerEventName.MessageTriggerEventNameGroupMembersDownToOne,
  },
  [EnumsMessageTriggerEventName.MessageTriggerEventNameGroupRepaired]: {
    label: 'Groups Repaired',
    value: EnumsMessageTriggerEventName.MessageTriggerEventNameGroupRepaired,
  },
  [EnumsMessageTriggerEventName.MessageTriggerEventNameRecognitionGiven]: {
    label: 'Recognition Given',
    value: EnumsMessageTriggerEventName.MessageTriggerEventNameRecognitionGiven,
  },
  [EnumsMessageTriggerEventName.MessageTriggerEventNameRecognitionReceived]: {
    label: 'Recognition Received',
    value:
      EnumsMessageTriggerEventName.MessageTriggerEventNameRecognitionReceived,
  },
  [EnumsMessageTriggerEventName.MessageTriggerEventNameProgramActivated]: {
    label: 'Program Activated',
    value: EnumsMessageTriggerEventName.MessageTriggerEventNameProgramActivated,
  },
};

const EVENT_DRIVEN_STYLES = buildReactSelectStyles<
  Option<EnumsMessageTriggerEventName>
>({
  override: { control: { height: 48 } },
});

export function MessageTriggerEventDrivenEditor(props: {
  value: EnumsMessageTriggerEventName;
  supportedEvents: EnumsMessageTriggerEventName[];
  onChange: (value: EnumsMessageTriggerEventName) => void;
}) {
  const options = useMemo(
    () =>
      props.supportedEvents.map((event) => {
        const option = MESSAGE_TRIGGER_EVENT_CONFIG[event];
        if (!option) {
          throw new Error(
            `${event} is not found in MESSAGE_TRIGGER_EVENT_CONFIG`
          );
        }
        return option;
      }),
    [props.supportedEvents]
  );

  return (
    <Select<Option<EnumsMessageTriggerEventName>>
      options={options}
      value={MESSAGE_TRIGGER_EVENT_CONFIG[props.value]}
      classNamePrefix='select-box-v2'
      className='w-80'
      styles={EVENT_DRIVEN_STYLES}
      onChange={(option) => {
        if (!option) return;
        props.onChange(option.value);
      }}
    />
  );
}

export function MessageTriggerEditor(props: { config: MessageTriggerConfig }) {
  const { config } = props;

  const {
    field: { value, onChange },
  } = useController<MessageLogicFormData, 'trigger'>({
    name: 'trigger',
  });

  const handleTypeChange = (triggerType: EnumsMessageTriggerType) => {
    onChange(MESSAGE_TRIGGER_TYPE_CONFIG[triggerType].makeDefault(config));
  };

  const handleChange = (updates: Partial<ModelsMessageTrigger>) => {
    onChange({ ...value, ...updates });
  };

  const triggerType =
    value?.type || EnumsMessageTriggerType.MessageTriggerTypeTimeOffset;
  return (
    <div>
      <div className='mb-2 font-bold'>Trigger</div>
      <div className='flex flex-col gap-2'>
        <MessageTriggerTypeSelect
          value={triggerType}
          onChange={handleTypeChange}
          supportedSources={props.config.types}
        />

        {triggerType === EnumsMessageTriggerType.MessageTriggerTypeTimeOffset &&
          value.timeOffset && (
            <MessageTriggerTimeOffsetEditor
              timeOffset={value.timeOffset}
              onChange={(timeOffset) => handleChange({ timeOffset })}
            />
          )}

        {triggerType ===
          EnumsMessageTriggerType.MessageTriggerTypeWeekdaysOffset &&
          value.weekdaysOffset && (
            <MessageTriggerWeekdaysOffsetEditor
              weekdaysOffset={value.weekdaysOffset}
              onChange={(weekdaysOffset) => handleChange({ weekdaysOffset })}
            />
          )}

        {triggerType ===
          EnumsMessageTriggerType.MessageTriggerTypeEventDriven &&
          value.eventName && (
            <MessageTriggerEventDrivenEditor
              value={value.eventName}
              supportedEvents={config.events || []}
              onChange={(eventName) => handleChange({ eventName })}
            />
          )}
      </div>
    </div>
  );
}
