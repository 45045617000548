import { type ReactNode, useCallback, useEffect, useMemo, useRef } from 'react';
import { usePreviousDistinct } from 'react-use';
import { proxy, useSnapshot } from 'valtio';

import {
  type Block,
  type GameSessionStatus,
  GameSessionUtil,
} from '@lp-lib/game';

import { useInstance } from '../../../../../hooks/useInstance';
import { ValtioUtils } from '../../../../../utils/valtio';
import { FloatLayout } from '../../../../Layout';
import { LayoutAnchor } from '../../../../LayoutAnchors/LayoutAnchors';
import { useSoundEffect } from '../../../../SFX';
import { type GamePlayUIState } from './types';
import { GamePlayStyle } from './utils';

function initGamePlayUIState(): GamePlayUIState {
  return {
    showBoard: false,
    mediaEndEffect: false,
    showAnswer: false,
    playPointsMultiplierAnimation: false,
    pointsMultiplierAnimation: null,
    playGoAnimation: false,
  };
}

export type GamePlayUIStateControl = {
  update: (state: Partial<GamePlayUIState>) => void;
  reset: () => void;
};

export function useGamePlayUITransitionControl(): [
  GamePlayUIState,
  GamePlayUIStateControl
] {
  const state = useInstance(() =>
    proxy<GamePlayUIState>(initGamePlayUIState())
  );
  const update = useCallback(
    (changes: Partial<GamePlayUIState>) => {
      ValtioUtils.update(state, changes);
    },
    [state]
  );

  const reset = useCallback(() => {
    ValtioUtils.reset(state, initGamePlayUIState());
  }, [state]);

  return [
    useSnapshot(state),
    useMemo(
      () => ({
        update,
        reset,
      }),
      [update, reset]
    ),
  ];
}

export function useOneTimePlayGoAnimation(
  control: GamePlayUIStateControl,
  condition: () => boolean
): void {
  const played = useRef(false);
  const needPlay = condition();

  useEffect(() => {
    if (played.current || !needPlay) return;
    control.update({ playGoAnimation: true });
    played.current = true;
    return () => {
      control.update({ playGoAnimation: false });
    };
  }, [control, needPlay, played]);
}

export function useIsGamePlayReady<T extends Block>(
  block: Nullable<T, false>,
  gameSessionStatus: Nullable<GameSessionStatus>
): boolean {
  return useMemo(() => {
    const map = GameSessionUtil.StatusMapFor(block?.type);
    const notReady =
      !map ||
      !block?.id ||
      gameSessionStatus === null ||
      gameSessionStatus === undefined ||
      gameSessionStatus === map.loaded ||
      gameSessionStatus === map.scoreboard ||
      gameSessionStatus === map.end;
    return !notReady;
  }, [block?.id, block?.type, gameSessionStatus]);
}

export function GamePlayPrimaryText(props: {
  text?: string;
  hasMedia?: boolean;
}): JSX.Element {
  return (
    <div
      className={`w-auto flex flex-col justify-center items-center 
          ${GamePlayStyle.UseBlockTextLayout(!!props.hasMedia)}`}
    >
      <p
        className={`text-white font-bold hyphens-auto text-center overflow-hidden 
            ${GamePlayStyle.UseBlockTextSize(!!props.hasMedia)}`}
      >
        {props.text}
      </p>
    </div>
  );
}

export function GamePlayInputLayout(props: {
  zIndex?: string;
  visible?: boolean;
  hasMedia?: boolean;
  className?: string;
  children?: ReactNode;
}): JSX.Element {
  return (
    <FloatLayout
      className={`flex flex-col items-center justify-end 
        ${props.className ?? ''} ${props.zIndex ?? ''}  
        ${GamePlayStyle.UseFloatLayoutPadding(!!props.hasMedia)}
        ${props.visible ? 'opacity-100' : 'opacity-0'}`}
    >
      <LayoutAnchor
        id={'gameplay-question-text-anchor'}
        className='w-full'
        layoutReportDelayMs={800}
      />
      {props.children}
    </FloatLayout>
  );
}

export function GamePlayInput(props: {
  text?: string;
  zIndex?: string;
  visible?: boolean;
  hasMedia?: boolean;
  inputBox?: ReactNode;
}): JSX.Element {
  const { text, inputBox, ...rest } = props;
  return (
    <GamePlayInputLayout {...rest}>
      <GamePlayPrimaryText text={text} hasMedia={props.hasMedia} />
      {inputBox}
    </GamePlayInputLayout>
  );
}

export function useCountdownStartTime(totalTime: number | null): number {
  return useMemo(() => {
    if (totalTime === null) return 3;
    const startTime = Math.round(totalTime * 0.1);
    if (startTime > 10) return 10;
    if (startTime < 3) return 3;
    return startTime;
  }, [totalTime]);
}

export function useCountdownPlaySFX(
  totalTime: number | null,
  time: number | null,
  enabled: boolean
): void {
  const { play: playBeepSFX } = useSoundEffect('beep');
  const { play: playBeepEndSFX } = useSoundEffect('beepEnd');
  const prevTime = usePreviousDistinct(time);
  const startTime = useCountdownStartTime(totalTime);

  useEffect(() => {
    if (!enabled || time === null) return;
    if (time >= 1 && time <= startTime) playBeepSFX();
    // The time will be set to zero when reseting a block or game. Avoid playing
    // the end sfx in that scenario.
    if (prevTime === 1 && time === 0) playBeepEndSFX();
  }, [enabled, playBeepEndSFX, playBeepSFX, prevTime, startTime, time]);
}
