import { type InstructionBlock, type InstructionCard } from '@lp-lib/game';

import logger from '../../../../logger/logger';

export const log = logger.scoped('instruction-block');

export class InstructionBlockUtils {
  static GetValidCards(block: InstructionBlock): InstructionCard[] {
    return block.fields?.cards?.filter((card) => !!card.media) || [];
  }
}
