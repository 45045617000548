import pluralize from 'pluralize';
import { useEffect, useMemo, useState } from 'react';

import {
  type BlockFields,
  type RandomizerBlock,
  type RandomizerBlockTierIndex,
} from '@lp-lib/game';

import { type TeamRandomizationSettings } from '../../../../types/game';
import { uncheckedIndexAccess_UNSAFE } from '../../../../utils/uncheckedIndexAccess_UNSAFE';
import {
  AdditionalSettings,
  AdditionalSharedSettingsEditor,
  EditorBody,
  EditorLayout,
  type EditorProps,
  type Option,
  RHFCheckbox,
  RHFSelectField,
  useEditor,
} from '../Common/Editor/EditorUtilities';
import { SettingTierData } from './config';
import { type TierData } from './types';
import { defaultGamePackRandomizationSettings } from './utils';

type TeamSizeFieldNames = `t${RandomizerBlockTierIndex}TeamSize`;
type TeamSizeMaxFieldNames = `t${RandomizerBlockTierIndex}TeamSizeMax`;

const defaultSettings = [
  [1, 0],
  [2, 3],
  [3, 4],
  [4, 5],
];

const TEAM_SIZE_OPTIONS: Option[] = [
  {
    value: 0,
    label: 'None',
  },
  ...[1, 2, 3, 4, 5].map((i) => ({
    value: i,
    label: i.toString(),
  })),
];

const TEAM_SIZE_MAX_OPTIONS: Option[] = [0, 3, 4, 5, 6, 7, 8].map((i) => ({
  value: i,
  label: i ? i.toString() : 'NA',
}));

const TIME_OPTIONS: Option[] = [0, 10, 20, 30, 40].map((i) => ({
  value: i,
  label: i === 0 ? 'No Timer' : `${i} ${pluralize('second', i)}`,
}));

function isNotMaxField(
  name: keyof BlockFields<RandomizerBlock>
): name is TeamSizeFieldNames {
  return !name.includes('Max');
}

function getCorrespondingMaxField(
  name: TeamSizeFieldNames
): TeamSizeMaxFieldNames {
  return `${name}Max`;
}

function castTeamSizeField<
  B extends boolean = boolean,
  T = B extends true ? TeamSizeMaxFieldNames : TeamSizeFieldNames
>(index: number, isMaxField: B): T {
  if (isMaxField) {
    return `t${index}TeamSizeMax` as unknown as T;
  }
  return `t${index}TeamSize` as unknown as T;
}

function SettingRow(props: {
  index: number;
  settings: BlockFields<RandomizerBlock>;
  data: TierData;
  updateField?: <T extends keyof BlockFields<RandomizerBlock>>(
    field: T,
    value: BlockFields<RandomizerBlock>[T]
  ) => void;
  onUpdate?: (settings: BlockFields<RandomizerBlock>) => void;
  disabled?: boolean;
}): JSX.Element {
  const { index, settings, data, updateField, onUpdate } = props;
  const tierLabel = Number.isFinite(data.end)
    ? `${data.start}-${data.end}`
    : `${data.start}+`;
  const rowBgStyle = index % 2 === 0 ? 'bg-white bg-opacity-20' : '';
  const tierIndex = (index + 1) as RandomizerBlockTierIndex;
  const teamSizeFieldName = `t${tierIndex}TeamSize` as const;
  const teamSizeMaxFieldName = `t${tierIndex}TeamSizeMax` as const;
  const teamSizeValue = settings[teamSizeFieldName];
  const teamSizeMaxValue = settings[teamSizeMaxFieldName];

  const teamSizeOptions = useMemo(() => {
    return TEAM_SIZE_OPTIONS;
  }, []);

  const [teamSizeMaxOptions, setTeamSizeMaxOptions] = useState<Option[]>(
    TEAM_SIZE_MAX_OPTIONS
  );

  useEffect(() => {
    const defaultOptions: Option[] = JSON.parse(
      JSON.stringify(TEAM_SIZE_MAX_OPTIONS)
    );
    const newOptions = defaultOptions.map((opt) => {
      if (Number(opt.value) < teamSizeValue && opt.isDisabled !== true) {
        opt.isDisabled = true;
      } else if (
        Number(opt.value) >= teamSizeValue &&
        opt.isDisabled === true
      ) {
        opt.isDisabled = false;
      }

      return opt;
    });
    setTeamSizeMaxOptions(newOptions);
  }, [teamSizeValue]);

  const selectOnChange = (
    name: keyof BlockFields<RandomizerBlock>,
    value: Nullable<Option['value'], false>
  ): void => {
    if (value === null) return;
    let numValue = value;
    if (typeof numValue === 'string') {
      numValue = parseInt(numValue);
    }

    const settings = uncheckedIndexAccess_UNSAFE({ ...props.settings });
    settings[name] = numValue;
    updateField?.(name, numValue);

    if (isNotMaxField(name)) {
      const maxFieldName = getCorrespondingMaxField(name);

      if (numValue === 0 || numValue === 1) {
        // for teamSize 0 and 1, the max team size is always 0.
        settings[maxFieldName] = 0;
        updateField?.(maxFieldName, 0);
      } else {
        const maxValue: number | undefined = settings[maxFieldName];
        if (maxValue === undefined || numValue > maxValue) {
          settings[maxFieldName] = numValue + 1;
          updateField?.(maxFieldName, numValue + 1);
        }
      }
    }
    onUpdate?.(settings as BlockFields<RandomizerBlock>);
  };

  return (
    <tr className={`h-14 font-medium text-sms ${rowBgStyle}`}>
      <td className='pl-2'>{tierLabel}</td>
      <td>
        <RHFSelectField<RandomizerBlock>
          className='w-22 h-10 text-white'
          name={teamSizeFieldName}
          options={teamSizeOptions}
          onChange={selectOnChange}
          value={props.disabled ? 0 : teamSizeValue}
          disabled={props.disabled}
        />
      </td>
      <td>
        <RHFSelectField<RandomizerBlock>
          className={`w-22 h-10 text-white ${
            teamSizeMaxValue === 0 && teamSizeValue <= 1
              ? 'pointer-events-none opacity-20'
              : ''
          }`}
          name={teamSizeMaxFieldName}
          options={teamSizeMaxOptions}
          onChange={selectOnChange}
          value={props.disabled ? 0 : teamSizeMaxValue}
          disabled={props.disabled}
        />
      </td>
    </tr>
  );
}

export function RandomizerBlockEditor(
  props: EditorProps<RandomizerBlock>
): JSX.Element {
  const { updateField } = useEditor(props);

  const selectOnChange = (
    name: keyof BlockFields<RandomizerBlock>,
    value: Nullable<Option['value'], false>
  ): void => {
    if (!value) return;
    let numValue = value;
    if (typeof numValue === 'string') {
      numValue = parseInt(numValue);
    }
    updateField(name, numValue);
  };

  const resetToDefault = (): void => {
    defaultSettings.forEach((s, i) => {
      updateField(castTeamSizeField(i + 1, false), s[0]);
      updateField(castTeamSizeField(i + 1, true), s[1]);
    });
  };

  return (
    <EditorLayout
      bottomAccessory={
        <AdditionalSettings>
          <AdditionalSharedSettingsEditor {...props} />
        </AdditionalSettings>
      }
    >
      <EditorBody>
        <p className='text-2xl text-white'>Team Randomizer Block</p>
        <form className='w-full my-7.5'>
          <div className='w-full flex flex-row'>
            <div className='w-3/5'>
              <div className='w-136 flex flex-row justify-between text-white font-bold text-base'>
                <div>Randomization Settings</div>
                <div
                  onClick={resetToDefault}
                  className='underline cursor-pointer'
                >
                  Reset to default
                </div>
              </div>
              <table className='w-136 mt-14 text-white table-auto'>
                <thead>
                  <tr className='h-8'>
                    <th className='text-left align-top font-normal'>Players</th>
                    <th className='text-left align-top font-normal'>
                      Team Size
                    </th>
                    <th className='text-left align-top font-normal'>
                      Max Team Size
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {SettingTierData.map((tier, i) => (
                    <SettingRow
                      key={`row-${i}`}
                      index={i}
                      settings={props.block.fields}
                      data={tier}
                      updateField={updateField}
                    />
                  ))}
                </tbody>
              </table>
            </div>
            <div className='w-2/5 text-base font-bold flex flex-col items-center'>
              <label htmlFor='text' className='w-3/4 my-2'>
                <div className='mb-1.5 text-white'>Icebreaker Timer</div>
                <RHFSelectField<RandomizerBlock>
                  className='w-full h-10 text-white'
                  name='timer'
                  options={TIME_OPTIONS}
                  onChange={selectOnChange}
                  value={props.block.fields.timer}
                />
                <div className='w-full mt-2 text-icon-gray text-sms font-normal'>
                  Buffer for Players to say hello to their new Teammates after
                  randomization.
                </div>
              </label>
            </div>
          </div>
        </form>
      </EditorBody>
    </EditorLayout>
  );
}

// Note(falcon): this is not the right logical place for this component, but it allows us to
// reuse the block editor components to speed up development. The component is tweaked slightly
// to fit the desired styles in the gamepack editor.
export function GamePackRandomizerEditor(props: {
  className?: string;
  value: TeamRandomizationSettings | null | undefined;
  onChange: (settings: TeamRandomizationSettings) => void;
  icebreakerEnabled?: boolean;
}): JSX.Element {
  const { onChange } = props;
  const settings: TeamRandomizationSettings =
    props.value ?? defaultGamePackRandomizationSettings;

  const handleUpdateTimer = (value: Nullable<Option['value'], false>): void => {
    if (value === null) return;
    let numValue = value;
    if (typeof numValue === 'string') {
      numValue = parseInt(numValue);
    }
    onChange({ ...settings, timer: numValue });
  };

  const handleReset = () => {
    onChange(defaultGamePackRandomizationSettings);
  };

  return (
    <div className={`w-full flex flex-col gap-8 ${props.className ?? ''}`}>
      <div className='flex flex-col gap-2'>
        <div className='flex flex-row items-center justify-between text-white'>
          <div className='font-bold text-base'>Randomization Settings</div>
          <div
            onClick={handleReset}
            className='text-sm underline cursor-pointer'
          >
            Reset to default
          </div>
        </div>

        <table className='text-white table-auto'>
          <thead>
            <tr className='h-8 text-sms'>
              <th className='w-1/3 text-left'>Players</th>
              <th className='w-1/3 text-left'>Team Size</th>
              <th className='w-1/3 text-left'>Max Team Size</th>
            </tr>
          </thead>
          <tbody>
            {SettingTierData.map((tier, i) => (
              <SettingRow
                key={`row-${i}`}
                index={i}
                settings={settings}
                data={tier}
                onUpdate={onChange}
                disabled={settings.oneTeam}
              />
            ))}
          </tbody>
        </table>
      </div>

      <div className='text-base font-bold'>
        <label htmlFor='text' className='w-3/4 my-2'>
          <RHFCheckbox<RandomizerBlock>
            label='Force One Team'
            name='oneTeam'
            value={settings.oneTeam}
            onChange={(_, checked: boolean): void => {
              onChange({ ...settings, oneTeam: checked });
            }}
          />
        </label>
      </div>

      {props.icebreakerEnabled && (
        <div className='text-base font-bold'>
          <label htmlFor='text' className='w-3/4 my-2'>
            <div className='mb-1.5 text-white'>Icebreaker Timer</div>
            <RHFSelectField<RandomizerBlock, 'timer'>
              className='w-full h-10 text-white'
              name='timer'
              options={TIME_OPTIONS}
              onChange={(_, value) => handleUpdateTimer(value)}
              value={settings.timer}
            />
          </label>
        </div>
      )}
    </div>
  );
}
