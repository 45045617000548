export function PuzzleBlockIcon(
  props: React.SVGProps<SVGSVGElement>
): JSX.Element {
  return (
    <svg
      className={props.className ?? 'w-3.5 h-3.5 fill-current'}
      viewBox='0 0 14 14'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M3.36049 11.4801H4.79381C4.85943 11.4801 4.92233 11.4544 4.96826 11.4079C5.01475 11.362 5.04045 11.2991 5.04045 11.2335V11.1804C5.0399 11.1241 5.02022 11.0694 4.98576 11.0251C4.84029 10.8337 4.76099 10.6002 4.76045 10.3601C4.76209 9.79737 5.18099 9.32322 5.73936 9.25212C6.29772 9.18102 6.82163 9.5354 6.96381 10.0801C7.05076 10.4093 6.98131 10.7599 6.77513 11.0306C6.74068 11.0749 6.72154 11.129 6.72045 11.1848V11.2379C6.72263 11.3724 6.83255 11.4801 6.96708 11.4801H8.4004C8.86415 11.4801 9.24041 11.1039 9.24041 10.6401V9.20679C9.24041 9.14116 9.26611 9.07827 9.31259 9.03233C9.35853 8.98585 9.42142 8.96014 9.48705 8.96014H9.54009C9.59587 8.96124 9.65002 8.98038 9.69377 9.01483C9.9962 9.2396 10.3916 9.29756 10.7454 9.16851C11.0998 9.03999 11.3656 8.74139 11.4525 8.37498C11.5302 8.04193 11.4514 7.69138 11.2387 7.42341C11.026 7.15543 10.7028 6.99958 10.3604 7.00012C10.1198 7.00067 9.88574 7.07942 9.69379 7.22544C9.64949 7.2599 9.5948 7.27958 9.53847 7.28013H9.48542C9.3498 7.27958 9.24042 7.16911 9.24042 7.03349V5.60015C9.24042 5.1364 8.86417 4.76015 8.40042 4.76015H6.9671C6.83093 4.76015 6.72046 4.64968 6.72046 4.5135V4.46046C6.72156 4.40467 6.7407 4.35053 6.77515 4.30678C6.99992 4.00435 7.05788 3.60895 6.92883 3.25513C6.80031 2.90076 6.50172 2.63496 6.13531 2.54801C5.80226 2.47035 5.45171 2.5491 5.18374 2.76183C4.91577 2.97456 4.75992 3.29777 4.76046 3.64012C4.76101 3.88075 4.83976 4.11481 4.98578 4.30676C5.02023 4.35051 5.03937 4.40465 5.04047 4.46044V4.51349C5.04047 4.64966 4.93 4.76013 4.79383 4.76013H3.36051C2.89676 4.76013 2.52051 5.13639 2.52051 5.60014V10.6402C2.52051 11.1039 2.89676 11.4802 3.36051 11.4802L3.36049 11.4801Z'
        fill='#FF7628'
      />
    </svg>
  );
}
