import { ClientTypeUtils, type Participant } from '../../types/user';
import { useAmICohost } from '../Player';
import { useTeamWithStaff } from '../TeamAPI/TeamV1';
import {
  useMyClientId,
  useMyClientType,
} from '../Venue/VenuePlaygroundProvider';
import { useStageControlAPI } from './Context/Provider';

interface HoverStateViewProps {
  hoveredParticipant: Participant | null;
  disableRemove: boolean;
}

export function HoverStateView({
  hoveredParticipant,
  disableRemove,
}: HoverStateViewProps): JSX.Element {
  const myClientId = useMyClientId();
  const myClientType = useMyClientType();
  const team = useTeamWithStaff(hoveredParticipant?.teamId);
  const hoveredByMe = myClientId === hoveredParticipant?.clientId;
  const isHost = ClientTypeUtils.isHost(myClientType);
  const isCohost = useAmICohost();
  const showRemove = !disableRemove && (isHost || isCohost || hoveredByMe);
  const stageControl = useStageControlAPI();

  const handleRemove = async () => {
    if (!hoveredParticipant) return;
    await stageControl.leave(hoveredParticipant.clientId);
  };
  return (
    <div className='inset-0 bg-lp-black-001 absolute z-20 flex items-center justify-center'>
      {showRemove && (
        <button
          type='button'
          className='btn-delete py-1.5 px-4 text-white text-sms rounded-xl'
          onClick={handleRemove}
        >
          {isHost || isCohost ? 'Remove' : 'Leave'}
        </button>
      )}

      <div className='absolute w-full bottom-2 text-white text-2xs font-bold text-center px-4'>
        <div title={hoveredParticipant?.username} className='w-full truncate'>
          {hoveredParticipant?.username}
        </div>
        {team && (
          <div title={team.name} className='w-full truncate'>
            {hoveredParticipant?.cohost ? <>(Host)</> : <>({team.name})</>}
          </div>
        )}
      </div>
    </div>
  );
}
