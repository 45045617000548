import { useState } from 'react';
import { type ConnectDragSource } from 'react-dnd';

import { uuidv4 } from '../../utils/common';
import { type Action, ActionSheet } from '../ActionSheet';
import { DragDropList } from '../common/DragDrop';
import { DeleteIcon } from '../icons/DeleteIcon';
import { EditIcon } from '../icons/EditIcon';
import { MenuIcon } from '../icons/MenuIcon';
import { FAQRenderedParagraph } from './FAQList';
import { type FAQ } from './types';

function FAQMenu(props: { onEdit: () => void; onDelete: () => void }) {
  const actions: Action<string>[] = [
    {
      kind: 'button',
      key: 'edit',
      icon: <EditIcon />,
      text: 'Edit',
      onClick: props.onEdit,
    },
    {
      kind: 'button',
      key: 'delete',
      icon: <DeleteIcon />,
      text: 'Delete',
      onClick: props.onDelete,
    },
  ];

  return (
    <ActionSheet
      actions={actions}
      btnSizingClassName='w-7.5 h-7.5'
      placement='bottom-end'
    />
  );
}

function FAQRow(props: {
  item: FAQ;
  editing: boolean;
  drag: ConnectDragSource;
  action: JSX.Element;
  onChange: (faq: FAQ) => void;
  onDone: () => void;
}) {
  const { item, editing, drag, action, onChange, onDone } = props;

  if (editing) {
    return (
      <div className='w-full'>
        <div className='w-full bg-secondary rounded-xl p-3 flex justify-between items-center gap-3.5'>
          <button type='button' ref={drag} className='btn cursor-move'>
            <MenuIcon />
          </button>
          <FAQRenderedParagraph
            text={item.question}
            className='flex-1 truncate h-6'
          />
          <button type='button' className='text-primary' onClick={onDone}>
            Done
          </button>
        </div>
        <div className='mt-2 px-4'>
          <div className={`text-icon-gray text-3xs font-medium text-left mb-1`}>
            {`*bold* _italics_ ~strike~ :emoji: <https://www.link.com|link>`}
          </div>
          <div>
            <p className='text-sm mb-1'>Question</p>
            <input
              className='w-full rounded-xl p-3 field'
              value={item.question}
              onChange={(e) => onChange({ ...item, question: e.target.value })}
            />
          </div>
          <div>
            <p className='text-sm mb-1'>Answer</p>
            <textarea
              className='w-full rounded-xl p-3 field h-40'
              value={item.answer}
              onChange={(e) => onChange({ ...item, answer: e.target.value })}
            />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className='w-full flex items-center gap-2'>
      <div className='flex-1 bg-secondary rounded-xl p-3 flex items-center gap-3.5'>
        <button type='button' ref={drag} className='btn cursor-move'>
          <MenuIcon />
        </button>
        <div className='flex-1 truncate h-6'>{item.question}</div>
      </div>
      {action}
    </div>
  );
}

export function FAQListEditor(props: {
  faqs: FAQ[];
  onMove: (from: number, to: number) => void;
  onAdd: (faq: FAQ) => void;
  onDelete: (index: number) => void;
  onChange: (index: number, faq: FAQ) => void;
}) {
  const { faqs, onMove, onAdd, onDelete, onChange } = props;

  const [editingId, setEditingId] = useState<string | null>(null);

  const handleAdd = () => {
    const faq: FAQ = { id: uuidv4(), question: '', answer: '' };
    onAdd(faq);
    setEditingId(faq.id);
  };

  return (
    <div className='w-full flex flex-col gap-2'>
      <h3 className='text-base font-bold'>FAQs ({faqs.length})</h3>

      <DragDropList
        type='faq-list'
        items={faqs}
        onMove={onMove}
        render={({ item, index, drag, ref, style }) => (
          <div className={`w-full`} ref={ref} style={style}>
            <FAQRow
              item={item}
              drag={drag}
              editing={editingId === item.id}
              onChange={(faq) => onChange(index, faq)}
              onDone={() => setEditingId(null)}
              action={
                <FAQMenu
                  onDelete={() => onDelete(index)}
                  onEdit={() => setEditingId(item.id)}
                />
              }
            />
          </div>
        )}
      />

      <div>
        <button
          type='button'
          className='text-primary text-sms font-medium'
          onClick={handleAdd}
        >
          + New FAQ
        </button>
      </div>
    </div>
  );
}
