import { useEffect, useState } from 'react';

import { WarningIcon } from '../../../icons/WarningIcon';
import { log } from './utils';

function useWindowFocused(): boolean {
  const [focused, setFocused] = useState(document.hasFocus());
  useEffect(() => {
    const focus = () => setFocused(true);
    const blur = () => setFocused(false);
    window.addEventListener('focus', focus);
    window.addEventListener('blur', blur);
    return () => {
      window.removeEventListener('focus', focus);
      window.removeEventListener('blur', focus);
    };
  }, []);
  return focused;
}

export function TeamRelayLostFocusModal(): JSX.Element | null {
  const focused = useWindowFocused();

  useEffect(() => {
    log.info(`${focused ? 'has' : 'lost'} focus`);
  }, [focused]);

  if (focused) return null;
  return (
    <div className='h-30 absolute mb-4 border-2 border-red-001 rounded-xl bg-modal bg-opacity-80 flex flex-col items-center justify-center text-white px-8'>
      <div className='flex items-center justify-center text-xl font-bold'>
        <WarningIcon className='w-6 h-6 fill-current' />
        <p className='ml-4'>Browser Window Not Focused</p>
      </div>
      <div className='text-sms mt-4'>Click anywhere on screen to resolve</div>
    </div>
  );
}
