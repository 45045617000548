export enum RightPanelTabState {
  People = 'People',
  MiniChat = 'MiniChat',
  FullChat = 'FullChat',
}

export type RightPanelUIAction = {
  input:
    | 'click-people'
    | 'click-chat'
    | 'click-collapse'
    | 'click-chat-message'
    | 'click-outside-panel'
    | 'send-emoji';
};

/**
 * The internal state of the provider.
 */
export type RightPanelUIState = {
  /**
   * The currently selected tab. This value may be undefined when the app starts since
   * we don't yet know which tab to set by default.
   */
  tab?: RightPanelTabState;
};

export type RightPanelHandleUIAction = React.Dispatch<RightPanelUIAction>;

export type RightPanelTabProps = {
  tab: RightPanelTabState;
  handlePanelUIAction: RightPanelHandleUIAction;
};
