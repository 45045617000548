import { PlayIcon } from '../icons/PlayIcon';
import { Loading } from '../Loading';
import {
  type TTSCustomPrevievProps,
  TTSCustomPreview,
} from './TTSCustomPreview';

export function TTSPreviewButton(
  props: TTSCustomPrevievProps & {
    className?: string;
  }
) {
  const { className, ...rest } = props;
  return (
    <TTSCustomPreview {...rest}>
      {({ audioRef, handlePreview, disabled, isGenerating }) => (
        <button
          type='button'
          className={
            props.className ??
            'btn w-11.5 h-11.5 rounded-full bg-layer-002 text-white flex items-center justify-center'
          }
          onClick={handlePreview}
          disabled={disabled}
        >
          <div className='w-3.5 h-3.5'>
            {isGenerating ? (
              <Loading text='' imgClassName='w-full h-full object-contain' />
            ) : (
              <PlayIcon className='w-full h-full' />
            )}
          </div>
          <audio ref={audioRef} className='hidden' />
        </button>
      )}
    </TTSCustomPreview>
  );
}
