export function CreativePromptBlockIcon(
  props: React.SVGProps<SVGSVGElement>
): JSX.Element {
  return (
    <svg
      className={props.className ?? 'w-3.5 h-3.5 fill-current'}
      viewBox='0 0 14 14'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M7.94227 2.26281L9.67876 0.532843L9.68338 2.984L11.9497 2.05025L11.1464 4H10.6056L11.03 2.96995L9.18479 3.7302L9.18103 1.73448L7.76718 3.14301L6.99998 1.30065L6.23278 3.14301L4.81893 1.73448L4.81517 3.7302L2.96993 2.96995L3.39432 4H2.85354L2.05023 2.05025L4.31658 2.984L4.3212 0.532843L6.05769 2.26281L6.99998 0L7.94227 2.26281Z'
        fill='#00BCC4'
      />
      <path
        d='M2.85354 10L2.05023 11.9497L4.31658 11.016L4.3212 13.4672L6.05769 11.7372L6.99998 14L7.94227 11.7372L9.67876 13.4672L9.68338 11.016L11.9497 11.9497L11.1464 10H10.6056L11.03 11.0301L9.18479 10.2698L9.18103 12.2655L7.76718 10.857L6.99998 12.6994L6.23278 10.857L4.81893 12.2655L4.81517 10.2698L2.96993 11.0301L3.39432 10H2.85354Z'
        fill='#00BCC4'
      />
      <path
        d='M2.25 7.5C2.25 6.80964 2.80964 6.25 3.5 6.25H10.5C11.1904 6.25 11.75 6.80964 11.75 7.5C11.75 8.19036 11.1904 8.75 10.5 8.75H3.5C2.80964 8.75 2.25 8.19036 2.25 7.5Z'
        stroke='#00BCC4'
        strokeWidth='0.5'
        fill='none'
      />
      <rect x='1' y='5' width='10' height='3' rx='1.5' fill='#00BCC4' />
    </svg>
  );
}
