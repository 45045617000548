import React, {
  type CSSProperties,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import useMeasure from 'react-use-measure';
import { useSnapshot } from 'valtio';

import { ProfileIndex } from '@lp-lib/crowd-frames-schema';
import { type JeopardyBlock } from '@lp-lib/game';

import { useLiveCallback } from '../../../../hooks/useLiveCallback';
import { type Participant } from '../../../../types';
import { getStaticAssetPath } from '../../../../utils/assets';
import { formatCurrency } from '../../../../utils/currency';
import { ResizeObserver } from '../../../../utils/ResizeObserver';
import { type ValtioSnapshottable } from '../../../../utils/valtio';
import { CrowdFramesAvatar } from '../../../CrowdFrames';
import {
  useLastJoinedParticipantGetter,
  useMyInstance,
  useMyTeamId,
  useParticipant,
  useParticipantsByClientIds,
} from '../../../Player';
import { useSoundEffect } from '../../../SFX';
import { useSelectStageMember } from '../../../Stage';
import { StreamView } from '../../../Stage/StreamView';
import { useTeam, useTeamMembers } from '../../../TeamAPI/TeamV1';
import { useUser } from '../../../UserContext';
import { useRTCService } from '../../../WebRTC';
import { useTeamScore } from '../../hooks';
import type {
  BuzzerSharedState,
  BuzzerSubmission,
} from '../Common/GamePlay/Buzzer/BuzzerAPI';
import { useRankedTeamScores } from '../Common/hooks';
import {
  useJeopardyGamePlayAPI,
  useJeopardyGameSharedAPI,
} from './JeopardyBlockProvider';
import { JeopardyBoard } from './JeopardyBoard';
import { JeopardyScore } from './JeopardyScore';
import { type Board, type Category, type Clue } from './types';

const handIcon = getStaticAssetPath('images/hand.png');

export function JeopardyBlockPlayground(_props: {
  block: JeopardyBlock;
}): JSX.Element | null {
  return (
    <Container>
      <MainContent />
      <Sidebar />
    </Container>
  );
}

function Container(props: { children: React.ReactNode }): JSX.Element {
  return (
    <div className='w-full h-full flex gap-2 bg-black rounded-xl overflow-hidden'>
      {props.children}
    </div>
  );
}

function MainContent() {
  const shared = useJeopardyGameSharedAPI();
  const play = useJeopardyGamePlayAPI();
  const sharedState = useSnapshot(shared.state);
  const me = useMyInstance();
  const board = sharedState.board;
  const gameState = sharedState.game?.state;
  const clueSelectorUid = sharedState.game?.clueSelectorUid;
  const availableClues = shared.getAvailableClues(sharedState);
  const availableClueIds = useMemo(() => {
    return availableClues.map((clue) => clue.id);
  }, [availableClues]);
  const { play: playJeopardyBoardFill } = useSoundEffect('jeopardyBoardFill');
  const { play: playJeopardyDing } = useSoundEffect('jeopardyDing');
  const { play: playJeopardyBoardClear } = useSoundEffect('jeopardyBoardClear');
  const animateBoardFill = gameState === 'INIT_BOARD';
  const animateGameEnd = gameState === 'GAME_OVER';

  useEffect(() => {
    if (!animateBoardFill) return;
    playJeopardyBoardFill();
  }, [playJeopardyBoardFill, animateBoardFill]);

  useEffect(() => {
    if (!animateGameEnd) return;
    playJeopardyBoardClear();
  }, [playJeopardyBoardClear, animateGameEnd]);

  const hasControl = useMemo(() => {
    return (
      me?.id === clueSelectorUid && gameState === 'WAIT_FOR_CLUE_SELECTION'
    );
  }, [clueSelectorUid, gameState, me?.id]);

  const showClueId = useMemo(() => {
    if (
      gameState === 'INIT_CLUE_SELECTOR' ||
      gameState === 'INSTRUCT_CLUE_SELECTOR' ||
      gameState === 'WAIT_FOR_CLUE_SELECTION' ||
      gameState === 'GAME_OVER'
    )
      return undefined;
    // eslint-disable-next-line valtio/state-snapshot-rule
    return sharedState.game?.selectedClueId;
  }, [gameState, sharedState.game?.selectedClueId]);

  const cluesDinged = useRef<Set<string>>(new Set());
  useEffect(() => {
    if (
      gameState !== 'START_TURN' ||
      !showClueId ||
      cluesDinged.current.has(showClueId)
    )
      return;
    cluesDinged.current.add(showClueId);
    playJeopardyDing();
  }, [playJeopardyDing, gameState, showClueId]);

  const handleClickCell = useLiveCallback((item: Clue | Category) => {
    if (me?.id && item.type === 'clue') {
      play.selectClue(item.id, me.id);
    }
  });

  return (
    <div className='w-2/3 h-full rounded-tl-xl rounded-bl-xl'>
      <div className='relative w-full h-full p-2 pr-0'>
        {board && (
          <JeopardyBoard
            board={board}
            availableClueIds={availableClueIds}
            selectedClueId={showClueId}
            onClickCell={hasControl ? handleClickCell : undefined}
            renderSelectedClue={(clue) => <ConnectedClue clue={clue} />}
            animateBoardFill={animateBoardFill}
            animateGameEnd={animateGameEnd}
            hideCategories={
              gameState === 'INIT_BOARD' || gameState === 'PRESENT_CATEGORIES'
            }
          />
        )}
        {board && <CategoryPresentation board={board} />}
      </div>
    </div>
  );
}

function CategoryPresentation(props: { board: Board }) {
  const categoryCards = useMemo(() => {
    const categories: Category[] = Object.values(props.board.items).filter(
      (item) => item?.type === 'category'
    ) as Category[];
    categories.sort((a, b) => a.col - b.col);
    return categories.map((c) => (
      <div
        key={c.id}
        className={`
            w-full h-full flex-none
            flex items-center justify-center
            text-center text-white
            border-12 border-black bg-[#0B1887]
            p-4
          `}
      >
        <JeopardyText className='uppercase text-1.5xl md:text-2.5xl lg:text-3.5xl xl:text-4xl lp-sm:text-4.5xl'>
          {c.category}
        </JeopardyText>
      </div>
    ));
  }, [props.board.items]);

  const shared = useJeopardyGameSharedAPI();
  const game = useSnapshot(shared.state).game;
  const state = game?.state;
  const index = game?.presentCategory ?? -1;

  if (
    index < 0 ||
    index >= categoryCards.length ||
    state !== 'PRESENT_CATEGORIES'
  )
    return null;

  return (
    <div className='absolute inset-0 overflow-hidden'>
      <div
        className='h-full min-w-0 flex items-center transform transition-transform'
        style={{
          transform: `translateX(${index * -100}%)`,
          transitionDuration: '0.5s',
        }}
      >
        {categoryCards}
      </div>
    </div>
  );
}

function ConnectedClue(props: { clue: Clue }) {
  return (
    <div className='w-full h-full flex flex-col items-center justify-between text-center text-white text-3xl'>
      <div className='w-full flex-1 flex items-center justify-center p-4 lg:p-6 lp-sm:p-8 text-center text-white'>
        <JeopardyText>{props.clue.clue}</JeopardyText>
      </div>
      <ClueAnswer clue={props.clue} />
      <div className='flex-none w-full min-h-17.5'>
        <ClueAction />
      </div>
      <JudgementGamePlayModal clue={props.clue} />
    </div>
  );
}

function ClueAction() {
  const shared = useJeopardyGameSharedAPI();
  const gameState = useSnapshot(shared.state).game?.state;
  const { play } = useSoundEffect('jeopardyTimesUp');

  const seenClueIds = useRef<Set<string>>(new Set());
  useEffect(() => {
    if (gameState !== 'REVEAL_ANSWER') return;
    const currentTurn = shared.getCurrentTurn();
    if (!currentTurn) return;
    const clueId = currentTurn.clueId;
    if (seenClueIds.current.has(clueId)) return;
    seenClueIds.current.add(clueId);

    const buzzQueueLength = currentTurn.buzzQueue?.length ?? 0;
    if (buzzQueueLength !== 0) return;
    play();
  }, [play, gameState, shared]);

  switch (gameState) {
    case 'START_TURN':
    case 'WAIT_FOR_BUZZERS':
      return <BuzzInButton />;
    case 'WAIT_FOR_ANSWER':
      return <AnswerPhaseBanner />;
    case 'WAIT_FOR_JUDGEMENTS':
    case 'REVEAL_VERDICT':
      return <JudgementPhaseBanner />;
    default:
      return null;
  }
}

function ClueAnswer(props: { clue: Clue }) {
  const shared = useJeopardyGameSharedAPI();
  const state = useSnapshot(shared.state);
  const showAnswer = state.game?.state === 'REVEAL_ANSWER';

  return (
    <div className='text-center text-tertiary h-20'>
      {showAnswer && <JeopardyText>Answer: {props.clue.answer}</JeopardyText>}
    </div>
  );
}

function JeopardyText(props: {
  className?: string;
  children: React.ReactNode;
}) {
  return (
    <div
      className={
        props.className ??
        'uppercase text-lg md:text-xl lg:text-2xl xl:text-3xl lp-sm:text-3.5xl'
      }
      style={{
        textShadow: '2px 5px 4px #000000',
        lineHeight: '1.5',
      }}
    >
      {props.children}
    </div>
  );
}

function Sidebar() {
  const shared = useJeopardyGameSharedAPI();
  const gameState = useSnapshot(shared.state).game?.state;

  const body = useMemo(() => {
    switch (gameState) {
      case 'INIT_CLUE_SELECTOR':
      case 'INSTRUCT_CLUE_SELECTOR':
      case 'WAIT_FOR_CLUE_SELECTION':
      case 'START_TURN':
        return (
          <SidebarWithContestant>
            <Scoreboard />
          </SidebarWithContestant>
        );
      case 'WAIT_FOR_BUZZERS':
        return <LiveBuzzerSidebar />;
      case 'PREPARE_CONTESTANT_TO_ANSWER':
      case 'WAIT_FOR_ANSWER':
      case 'WAIT_FOR_JUDGEMENTS':
      case 'REVEAL_VERDICT':
      case 'REVEAL_ANSWER':
        return (
          <SidebarWithContestant>
            <BuzzQueue />
          </SidebarWithContestant>
        );
      default:
        return null;
    }
  }, [gameState]);

  return <div className='w-1/3 h-full rounded-tr-xl rounded-br-xl'>{body}</div>;
}

function SidebarWithContestant(props: { children: React.ReactNode }) {
  return (
    <div className='w-full h-full flex flex-col items-center gap-2'>
      <div className='flex-none w-full min-h-0 pt-2 pr-2'>
        <ContestantView />
      </div>
      <div className='flex-1 w-full min-h-0 max-h-[50%] overflow-hidden pb-2 pr-2'>
        {props.children}
      </div>
    </div>
  );
}

function LiveBuzzerSidebar() {
  return (
    <div className='w-full h-full flex flex-col items-center gap-2'>
      <div className='w-full h-1/2 flex flex-col items-center justify-center'>
        <img
          src={handIcon}
          alt='logo'
          className='w-18 h-18 md:w-20 md:h-20 lg:w-22 lg:h-22 xl:w-24.5 xl:h-24.5 opacity-50'
        />
        <div className='text-icon-gray font-bold text-center'>
          Click the buzzer to buzz in.
        </div>
      </div>
      <div className='w-full h-1/2 overflow-hidden pb-2 pr-2'>
        <LiveBuzzers />
      </div>
    </div>
  );
}

function ContestantView(): JSX.Element | null {
  const rtcService = useRTCService('stage');
  const shared = useJeopardyGameSharedAPI();
  const state = useSnapshot(shared.state);
  const contestantOnStage = shared.getContestantOnStage(state);
  const stageMember = useSelectStageMember(contestantOnStage?.clientId);
  const contestantTeam = useTeam(contestantOnStage?.teamId);
  const contestantTeamScore = useTeamScore(contestantOnStage?.teamId ?? null);

  const showGetReady = state.game?.state === 'PREPARE_CONTESTANT_TO_ANSWER';
  const invisible = !stageMember || !contestantOnStage;
  return (
    <div
      className={`w-full flex flex-col items-center justify-center gap-2.5 pt-4 ${
        invisible ? 'invisible' : ''
      }`}
    >
      <div className='relative w-40 h-40 md:w-48 md:h-48 lg:w-50 lg:h-50 xl:w-60 xl:h-60 lp-sm:w-65 lp-sm:h-65 rounded-3.5xl'>
        <StreamView
          className={`w-full h-full rounded-xl ring-2 drop-shadow-lg`}
          style={
            {
              '--tw-ring-color': contestantTeam?.color ?? 'transparent',
            } as CSSProperties
          }
          member={stageMember}
          rtcService={rtcService}
          disableRemove
        />
        {showGetReady && (
          <div className='absolute inset-0 bg-black bg-opacity-80 flex items-center justify-center text-white font-bold rounded-xl'>
            Get ready...
          </div>
        )}
      </div>
      <div className='w-full text-center'>
        <div className='font-bold'>
          {contestantOnStage?.firstName ?? contestantOnStage?.username}
        </div>
        {contestantTeam && (
          <>
            <div
              className='text-sms'
              style={{ color: contestantTeam?.color ?? 'white' }}
            >
              {contestantTeam.name}
            </div>

            <div
              className={`text-sms font-bold ${
                (contestantTeamScore ?? 0) < 0 ? 'text-red-001' : 'text-white'
              } flex justify-center`}
            >
              <JeopardyScore
                key={contestantOnStage?.teamId}
                points={contestantTeamScore}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
}

function BuzzQueue() {
  const shared = useJeopardyGameSharedAPI();
  const gameState = useSnapshot(shared.state).game;

  const submissions = useMemo(() => {
    if (!gameState?.selectedClueId || !gameState?.turns) return [];
    const turn = gameState.turns[gameState.selectedClueId];
    if (!turn || !turn.buzzQueue) return [];
    return turn.buzzQueue.map((submission, index) => {
      const hasContestantIndex =
        turn.contestantIndex !== null && turn.contestantIndex !== undefined;
      return (
        <ConnectedBuzzerSubmissionRow
          key={submission.uid}
          submission={submission}
          noAnimate
          hasAnswered={hasContestantIndex && index < turn.contestantIndex}
          isCurrentContestant={index === turn.contestantIndex}
        />
      );
    });
  }, [gameState?.selectedClueId, gameState?.turns]);

  return (
    <div className='w-full h-full space-y-0.5 overflow-y-scroll scrollbar'>
      {submissions}
    </div>
  );
}

function LiveBuzzers() {
  const buzzer = useJeopardyGameSharedAPI().buzzer;
  const buzzerState = useSnapshot(buzzer.state);
  const buzzers = buzzer.getSubmissions(buzzerState);
  const submissions = useMemo(() => {
    return buzzers.map((submission) => (
      <ConnectedBuzzerSubmissionRow
        key={submission.uid}
        submission={submission}
      />
    ));
  }, [buzzers]);

  return (
    <div className='w-full h-full space-y-0.5 overflow-y-scroll scrollbar'>
      {submissions}
    </div>
  );
}

function ConnectedBuzzerSubmissionRow(props: {
  submission: BuzzerSubmission;
  noAnimate?: boolean;
  hasAnswered?: boolean;
  isCurrentContestant?: boolean;
}) {
  const participant = useParticipant(props.submission.clientId);
  const team = useTeam(props.submission.teamId);
  const myTeamId = useMyTeamId();
  const uid = useUser().id;
  const isMyTeamSubmission = myTeamId === props.submission.teamId;
  if (!participant || !team) return null;
  return (
    <BuzzerSubmissionRow
      name={participant.firstName ?? participant.username}
      teamName={team.name}
      teamColor={team.color}
      isMyTeamSubmission={isMyTeamSubmission}
      isMySubmission={props.submission.uid === uid}
      noAnimate={props.noAnimate}
      hasAnswered={props.hasAnswered}
      isCurrentContestant={props.isCurrentContestant}
    />
  );
}

function BuzzerSubmissionRow(props: {
  name: string;
  teamName: string;
  teamColor?: string | null;
  isMyTeamSubmission?: boolean;
  isMySubmission?: boolean;
  noAnimate?: boolean;
  hasAnswered?: boolean;
  isCurrentContestant?: boolean;
}) {
  const {
    name,
    teamName,
    teamColor,
    isMyTeamSubmission,
    isMySubmission,
    noAnimate,
    hasAnswered,
    isCurrentContestant,
  } = props;
  const ref = useRef<HTMLDivElement>(null);
  const hasAnimated = useRef(noAnimate);
  const { play } = useSoundEffect('spotlightBuzzer');

  useLayoutEffect(() => {
    if (!ref.current || hasAnimated.current) return;
    hasAnimated.current = true;
    ref.current.animate(
      [
        {
          transform: 'translate3d(100%, 0, 0) skew(4deg)',
          opacity: 0,
        },
        { transform: 'translate3d(0, 0, 0) skew(0)', opacity: 1 },
      ],
      {
        duration: 180,
        fill: 'both',
        easing: 'ease-out',
      }
    );
    play();
  }, [play]);
  useLayoutEffect(() => {
    if (!ref.current || !isCurrentContestant) return;
    ref.current.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  }, [isCurrentContestant]);

  return (
    <div
      ref={ref}
      className={`
        w-full bg-main-layer px-2 py-1.5 flex items-center gap-2
        ${hasAnswered ? 'opacity-50' : ''}
      `}
      style={{
        color: teamColor ?? 'white',
      }}
    >
      <img src={handIcon} alt='logo' className='w-5 h-5 flex-none' />
      <div className='flex-1'>
        <span className='font-bold text-xs'>{name}</span>{' '}
        <span className='font-normal text-2xs'>from {teamName}</span>
      </div>
      {isMySubmission ? (
        <div className='text-2xs'>You!</div>
      ) : isMyTeamSubmission ? (
        <div className='text-2xs'>Your team!</div>
      ) : null}
    </div>
  );
}

function Scoreboard() {
  const teamScores = useRankedTeamScores('totalScore');
  return (
    <div className='w-full h-full space-y-0.5 overflow-y-scroll scrollbar'>
      {teamScores.map((teamScore) => (
        <ConnectedScoreboardRow
          key={teamScore.team.id}
          teamId={teamScore.team.id}
          teamName={teamScore.team.name}
          teamColor={teamScore.team.color}
          score={teamScore.totalScore}
        />
      ))}
    </div>
  );
}

function ConnectedScoreboardRow(props: {
  teamId: string;
  teamName: string;
  teamColor?: string;
  score: number;
}) {
  const teamMembers = useTeamMembers(props.teamId) ?? [];
  const participants = useParticipantsByClientIds(teamMembers.map((m) => m.id));
  const playerNames = useMemo(() => {
    if (!participants || participants.length === 0) return [];
    return participants.map((p) => p.firstName ?? p.username);
  }, [participants]);
  const myTeamId = useMyTeamId();
  const isMyTeam = myTeamId === props.teamId;

  return (
    <ScoreboardRow
      teamName={props.teamName}
      teamColor={props.teamColor}
      playerNames={playerNames}
      score={props.score}
      isMyTeam={isMyTeam}
    />
  );
}

function ScoreboardRow(props: {
  teamName: string;
  teamColor?: string;
  playerNames: string[];
  score: number;
  isMyTeam?: boolean;
}) {
  const { teamName, teamColor, playerNames, score, isMyTeam } = props;
  const playerNamesDisplay = useMemo(() => {
    if (playerNames.length <= 3) return playerNames.join(', ');
    return `${playerNames[0]}, ${playerNames[1]}, +${
      playerNames.length - 2
    } more`;
  }, [playerNames]);

  return (
    <div className='w-full bg-main-layer px-2 py-1.5 flex items-center justify-between gap-2'>
      <div className='flex-1'>
        <div
          className='text-xs font-bold'
          style={{ color: teamColor ?? 'white' }}
        >
          {teamName}
          {isMyTeam ? ' (Your team)' : ''}
        </div>
        <div className='text-2xs text-icon-gray'>{playerNamesDisplay}</div>
      </div>
      <div
        className={`flex-none text-right text-sms font-bold ${
          score < 0 ? 'text-red-001' : 'text-white'
        } tabular-nums`}
      >
        {formatCurrency(score)}
      </div>
    </div>
  );
}

function AnswerPhaseBanner() {
  const shared = useJeopardyGameSharedAPI();
  const state = useSnapshot(shared.state);
  const me = useMyInstance();
  const turnRole = shared.getTurnRole(shared.state, me?.id);

  const contestant = shared.getContestantOnStage(state);
  const play = useJeopardyGamePlayAPI();

  const handleClickDone = useLiveCallback(() => {
    if (!me || !me.teamId) return;
    return play.finishedAnswering();
  });

  switch (turnRole) {
    case 'contestant':
      return (
        <SayYourAnswerBanner
          name={me?.firstName ?? me?.username}
          onClickDone={handleClickDone}
        />
      );
    case 'judge':
      return (
        <WaitingForAnswerBanner
          contestantName={contestant?.firstName ?? contestant?.username}
        />
      );
    case 'buzzer':
      return <YouAreInTheBuzzQueue />;
    case undefined:
    default:
      return null;
  }
}

function SayYourAnswerBanner(props: {
  name?: string;
  onClickDone: () => void;
}) {
  return (
    <div className='w-full h-full bg-gradient-to-bl from-yellow-start to-yellow-end flex items-center justify-between gap-4 px-5'>
      <div className='text-black text-lg font-bold text-left'>
        {props.name ? (
          <>{props.name} – say your answer out loud!</>
        ) : (
          <>Say your answer out loud!</>
        )}
      </div>
      <button
        type='button'
        className='flex-none btn-secondary w-40 h-10 text-base'
        onClick={props.onClickDone}
      >
        I’m done
      </button>
    </div>
  );
}

function WaitingForAnswerBanner(props: { contestantName?: string }) {
  return (
    <div className='w-full h-full bg-gradient-to-bl from-yellow-start to-yellow-end flex items-center justify-between px-5'>
      <div className='flex-1 text-black text-lg font-bold text-left'>
        You’re on the judging panel!
      </div>
      <div className='flex-1 text-black text-sms font-base text-right'>
        Waiting for {props.contestantName ?? 'the player'} to answer
      </div>
    </div>
  );
}

function YouAreInTheBuzzQueue() {
  return (
    <div className='w-full h-full bg-gradient-to-bl from-yellow-start to-yellow-end flex items-center justify-between px-5'>
      <div className='flex-1 text-black text-lg font-bold text-left'>
        You’re in the buzz queue!
      </div>
      <div className='flex-1 text-black text-xs font-base text-right'>
        You might have a chance to answer this question if everyone before you
        answers incorrectly.
      </div>
    </div>
  );
}

function JudgementPhaseBanner() {
  const shared = useJeopardyGameSharedAPI();
  const state = useSnapshot(shared.state);
  const me = useMyInstance();
  const turnRole = shared.getTurnRole(state, me?.id);

  switch (turnRole) {
    case 'buzzer':
      return <YouAreInTheBuzzQueue />;
    default:
      return null;
  }
}

function JudgementGamePlayModal(props: { clue: Clue }) {
  const shared = useJeopardyGameSharedAPI();
  const gameState = useSnapshot(shared.state).game?.state;

  return useMemo(() => {
    switch (gameState) {
      case 'WAIT_FOR_JUDGEMENTS':
        return (
          <div className='absolute inset-0 bg-black bg-opacity-10'>
            <div className='absolute inset-[10%]'>
              <JudgementGamePlay
                clue={props.clue}
                inProgress={gameState === 'WAIT_FOR_JUDGEMENTS'}
              />
            </div>
          </div>
        );
      case 'REVEAL_VERDICT':
        return (
          <div className='absolute inset-0 bg-black bg-opacity-10'>
            <div className='absolute inset-[10%]'>
              <ConnectedJudgementResults />
            </div>
          </div>
        );
      default:
        return null;
    }
  }, [gameState, props.clue]);
}

function JudgementGamePlay(props: { clue: Clue; inProgress?: boolean }) {
  const shared = useJeopardyGameSharedAPI();
  const state = useSnapshot(shared.state);
  const me = useMyInstance();
  const turnRole = shared.getTurnRole(state, me?.id);

  const contestant = shared.getContestantOnStage(state);
  const poll = useJeopardyGameSharedAPI().poll;
  const pollState = useSnapshot(poll.state);
  const myVote = poll.getMyVote(pollState, me?.id);

  const handleSubmit = useLiveCallback((judgement: 'correct' | 'incorrect') => {
    if (!me || !me.teamId) return;
    poll.vote(judgement, { uid: me.id, teamId: me.teamId });
  });

  if (myVote)
    return <ConnectedJudgementResults inProgress={props.inProgress} />;

  const contestantName =
    contestant?.firstName ?? contestant?.username ?? 'the player';

  switch (turnRole) {
    case 'contestant':
      return (
        <SubmitJudgement
          text={
            me
              ? `${me.firstName ?? me.username} – how did you do?`
              : 'How did you do?'
          }
          contestant={contestant}
          answer={props.clue.answer}
          onSubmit={handleSubmit}
        />
      );
    case 'judge':
      return (
        <SubmitJudgement
          text={`How did ${contestantName} do?`}
          contestant={contestant}
          answer={props.clue.answer}
          onSubmit={handleSubmit}
        />
      );
    case 'buzzer':
    case undefined:
    default:
      return <ConnectedJudgementResults inProgress={props.inProgress} />;
  }
}

function SubmitJudgement(props: {
  text: string;
  contestant: Participant | null;
  answer: string;
  onSubmit: (judgement: 'correct' | 'incorrect') => void;
}) {
  const contestantTeam = useTeam(props.contestant?.teamId);
  return (
    <div className='w-full h-full bg-[#0029FF] border-[3px] border-white flex flex-col items-center shadow-xl rounded-xl'>
      <div className='pt-5 pb-2 px-5 flex-none'>
        <div className='text-white text-center font-bold text-base lg:text-lg uppercase'>
          Judging in progress...
        </div>
        <div className='text-tertiary text-center text-xl lg:text-2xl uppercase font-bold'>
          Answer: {props.answer}
        </div>
      </div>

      <div className='flex-1 flex flex-col items-center justify-center px-5'>
        {props.contestant && (
          <div
            className='relative rounded-full w-15.5 h-15.5 overflow-hidden bg-lp-gray-004 shadow-xl ring-2 mb-4 hidden lg:flex'
            style={
              {
                '--tw-ring-color': contestantTeam?.color ?? 'transparent',
              } as CSSProperties
            }
          >
            <CrowdFramesAvatar
              participant={props.contestant}
              profileIndex={ProfileIndex.wh100x100fps8}
              enablePointerEvents={false}
              renderFrameOnStage
            />
          </div>
        )}
        <div className='min-w-0 text-white text-lg lg:text-xl font-bold text-center'>
          {props.text}
        </div>
        <div className='mt-5 flex items-center justify-center gap-2'>
          <button
            type='button'
            className='flex-none btn-secondary w-30 md:w-35 lg:w-40 h-10 text-base text-white'
            onClick={() => props.onSubmit('incorrect')}
          >
            👎 Incorrect
          </button>
          <button
            type='button'
            className='flex-none btn-secondary w-30 md:w-35 lg:w-40 h-10 text-base text-white'
            onClick={() => props.onSubmit('correct')}
          >
            👍 Correct
          </button>
        </div>
      </div>

      <div className='hidden md:flex pb-5 pt-2 px-5 flex-none text-white font-bold md:text-xs lg:text-sms xl:text-lg uppercase'>
        Keep the answer a secret until judging is done!
      </div>
    </div>
  );
}

function ConnectedJudgementResults(props: { inProgress?: boolean }) {
  const shared = useJeopardyGameSharedAPI();
  const sharedState = useSnapshot(shared.state);
  const turn = shared.getCurrentTurn(sharedState);
  const poll = shared.poll;
  const votes = useSnapshot(poll.state).votes;
  const getParticipant = useLastJoinedParticipantGetter();

  const [correctVoters, incorrectVoters] = useMemo(() => {
    const totalVotes = Object.values(votes ?? {}).length;
    if (!votes || totalVotes === 0) return [[], []];
    const corrects: (Participant | null)[] = [];
    const incorrects: (Participant | null)[] = [];
    for (const vote of Object.values(votes)) {
      if (!vote) continue;
      if (vote.optionId === 'correct') {
        corrects.push(getParticipant(vote.uid));
      } else if (vote.optionId === 'incorrect') {
        incorrects.push(getParticipant(vote.uid));
      }
    }
    return [corrects, incorrects];
  }, [getParticipant, votes]);

  // in case the buzz queue was empty, then don't show the judgments...
  if (!turn || !turn.buzzQueue || turn.buzzQueue.length === 0) return null;

  return (
    <JudgementResults
      inProgress={props.inProgress}
      corrects={correctVoters}
      incorrects={incorrectVoters}
    />
  );
}

function BuzzInButton() {
  const shared = useJeopardyGameSharedAPI();
  const buzzer = shared.buzzer;
  const gameState = useSnapshot(shared.state).game?.state;
  const me = useMyInstance();
  const onBuzzIn = useLiveCallback(() => {
    if (!me || !me.teamId) return;
    buzzer.buzzIn(me.id, me.clientId, me.teamId);
  });
  const buzzerState = useSnapshot(
    buzzer.state
  ) as ValtioSnapshottable<BuzzerSharedState>;

  const [hasSubmitted, setHasSubmitted] = useState(false);
  useEffect(() => {
    const mySubmission = buzzer.getMySubmission(buzzerState, me?.id);
    if (mySubmission) setHasSubmitted(true);
  }, [buzzer, buzzerState, me?.id]);

  const onSpacebar = useLiveCallback((event: KeyboardEvent) => {
    if (
      event.code === 'Space' &&
      !hasSubmitted &&
      gameState === 'WAIT_FOR_BUZZERS'
    ) {
      onBuzzIn();
    }
  });

  useEffect(() => {
    window.addEventListener('keydown', onSpacebar);
    return () => {
      window.removeEventListener('keydown', onSpacebar);
    };
  }, [onSpacebar, gameState, onBuzzIn]);

  return (
    <button
      type='button'
      disabled={hasSubmitted || gameState !== 'WAIT_FOR_BUZZERS'}
      onClick={onBuzzIn}
      className={`btn-spotlight-main h-12 w-100 text-2xl text-center rounded-xl`}
    >
      <div className='flex flex-row items-center justify-center text-xl font-bold gap-2'>
        <img src={handIcon} alt='logo' className='w-7.5 h-7.5' />
        <div>{hasSubmitted ? 'You buzzed in!' : 'Buzzer'}</div>
      </div>
    </button>
  );
}

function JudgementResults(props: {
  inProgress?: boolean;
  corrects: (Participant | null)[];
  incorrects: (Participant | null)[];
}) {
  const { inProgress, corrects, incorrects } = props;

  const [correctOpacity, incorrectOpacity] = useMemo(() => {
    if (inProgress) return ['opacity-100', 'opacity-100'];
    if (
      corrects.length + incorrects.length > 0 &&
      corrects.length >= incorrects.length
    )
      return ['opacity-100', 'opacity-40'];
    return ['opacity-40', 'opacity-100'];
  }, [corrects.length, inProgress, incorrects.length]);

  return (
    <div className='w-full h-full bg-[#0029FF] border-[3px] border-white flex flex-col items-center shadow-xl rounded-xl'>
      <div
        className={`pt-5 pb-2 px-5 flex-none text-white font-bold text-base lg:text-lg uppercase ${
          inProgress ? 'opacity-50' : 'opacity-100'
        }`}
      >
        {inProgress ? 'Judging in progress...' : 'Judging complete!'}
      </div>

      <div className='flex-1 w-full flex py-4'>
        <div className='w-1/2 h-full border-r-[3px] border-white border-opacity-50 border-dashed px-4'>
          <div
            className={`w-full h-full flex flex-col items-center ${incorrectOpacity}`}
          >
            <div className='w-full pb-4'>
              <div className='w-full text-center text-[75px] text-white font-bold leading-snug'>
                {incorrects.length}
              </div>
              <div className='w-full text-center text-xl text-white font-bold uppercase'>
                👎 Incorrect
              </div>
            </div>
            <CrowdFramesStack participants={incorrects} direction='left' />
          </div>
        </div>
        <div className='w-1/2 h-full px-4'>
          <div
            className={`w-full h-full flex flex-col items-center ${correctOpacity}`}
          >
            <div className='w-full pb-4'>
              <div className='w-full text-center text-[75px] text-white font-bold leading-snug'>
                {corrects.length}
              </div>
              <div className='w-full text-center text-xl text-white font-bold uppercase'>
                👍 Correct
              </div>
            </div>
            <CrowdFramesStack participants={corrects} direction='right' />
          </div>
        </div>
      </div>
    </div>
  );
}

const targetCrowdFrameSize = 46;

function CrowdFramesStack(props: {
  participants: (Participant | null)[];
  direction: 'left' | 'right';
}) {
  const { participants, direction } = props;

  const [containerRef, containerRect] = useMeasure({
    polyfill: ResizeObserver,
  });

  const [crowdFrames, overflow] = useMemo(() => {
    const ps = [...participants];
    ps.sort((x, y) => {
      if (x === null) return 1;
      if (y === null) return -1;
      return 0;
    });
    const maxFramesPerRow = Math.floor(
      containerRect.width / targetCrowdFrameSize
    );
    const maxRows = Math.floor(containerRect.height / targetCrowdFrameSize);
    const maxFrames = maxFramesPerRow * maxRows - 1;
    const overflow = Math.max(0, ps.length - maxFrames);
    const frames = ps.slice(0, maxFrames);
    return [frames, overflow];
  }, [containerRect.width, containerRect.height, participants]);

  return (
    <div ref={containerRef} className='w-full flex-1 min-h-0'>
      <div
        className={`w-full flex flex-wrap items-center gap-0 ${
          direction === 'left' ? 'flex-row' : 'flex-row-reverse'
        }`}
        style={{ height: `${targetCrowdFrameSize}px` }}
      >
        {crowdFrames.map((participant, index) => (
          <div
            key={participant?.id ?? index}
            className='relative rounded-full overflow-hidden bg-lp-gray-004 shadow-xl'
            style={{
              width: `${targetCrowdFrameSize}px`,
              height: `${targetCrowdFrameSize}px`,
            }}
          >
            {participant && (
              <CrowdFramesAvatar
                participant={participant}
                profileIndex={ProfileIndex.wh100x100fps8}
                enablePointerEvents={false}
                renderFrameOnStage
              />
            )}
          </div>
        ))}
        {overflow > 0 && (
          <div
            className={`
              tabular-nums text-2xs text-white font-bold pt-1 text-center
              ${direction === 'left' ? 'pl-2' : 'pr-2'}
            `}
          >
            +{overflow}
            <br />
            more
          </div>
        )}
      </div>
    </div>
  );
}
