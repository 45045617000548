import React, {
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
} from 'react';
import { type ReactNode } from 'react';
import { proxy } from 'valtio';
import { devtools } from 'valtio/utils';

import { type ClientType, ClientTypeUtils } from '../../types';
import {
  markSnapshottable,
  useSnapshot,
  type ValtioSnapshottable,
} from '../../utils/valtio';
import { useMyTeamId } from '../Player';

type State = {
  myClientId: string;
  myClientType: ClientType;
  gameTeamVolumeBalancerValue: number;
  audioEnabled: boolean;
  venueBackgroundAudioMuted: boolean;
  gameBGMScale: number | null;
};

class VenuePlaygroundAPI {
  private _state;
  private undevtools;
  constructor(
    myClientId: string,
    myClientType: ClientType,
    initialGameTeamVolumeBalancerValue = 32
  ) {
    this._state = markSnapshottable<State>(
      proxy({
        myClientId,
        myClientType,
        gameTeamVolumeBalancerValue: initialGameTeamVolumeBalancerValue,
        audioEnabled: false,
        venueBackgroundAudioMuted: true,
        gameBGMScale: null,
      })
    );
    this.undevtools = devtools(this._state, { name: 'VenuePlaygroundAPI' });
  }

  destroy() {
    this.undevtools?.();
  }

  get state(): Readonly<ValtioSnapshottable<State>> {
    return this._state;
  }

  setGameTeamVolumeBalancerValue(next: number) {
    this._state.gameTeamVolumeBalancerValue = next;
  }

  notifyVenueBackgroundAudioMuted(muted: boolean) {
    this._state.venueBackgroundAudioMuted = muted;
  }

  setGameBGMScale(n: number | null) {
    this._state.gameBGMScale = n;
  }

  setAudioEnabled(enabled: boolean) {
    this._state.audioEnabled = enabled;
  }
}

type Context = {
  api: VenuePlaygroundAPI;
};

const context = React.createContext<Context | null>(null);

function useVenuePlaygroundContext() {
  const ctx = useContext(context);
  if (!ctx) throw new Error('VenuePlaygroundContext is not in the tree!');
  return ctx;
}

export function useVenuePlaygroundAPI() {
  return useVenuePlaygroundContext().api;
}

export function useMyClientId() {
  const api = useVenuePlaygroundAPI();
  return useSnapshot(api.state).myClientId;
}

export function useMyClientIdGetter() {
  const api = useVenuePlaygroundAPI();
  return useCallback(() => api.state.myClientId, [api]);
}

export function useMyClientType() {
  const api = useVenuePlaygroundAPI();
  return useSnapshot(api.state).myClientType;
}

export function useMyClientTypeGetter() {
  const api = useVenuePlaygroundAPI();
  return useCallback(() => api.state.myClientType, [api]);
}

export function useGameTeamVolumeBalancerValue() {
  const api = useVenuePlaygroundAPI();
  const value = useSnapshot(api.state).gameTeamVolumeBalancerValue;
  const setter = useMemo(
    () => api.setGameTeamVolumeBalancerValue.bind(api),
    [api]
  );
  return [value, setter] as const;
}

export function useGameBGMScale() {
  const api = useVenuePlaygroundAPI();
  const value = useSnapshot(api.state).gameBGMScale;
  const setter = useMemo(() => api.setGameBGMScale.bind(api), [api]);
  return [value, setter] as const;
}

export function useVenueBackgroundAudioMuted() {
  const api = useVenuePlaygroundAPI();
  const value = useSnapshot(api.state).venueBackgroundAudioMuted;
  const setter = useMemo(
    () => api.notifyVenueBackgroundAudioMuted.bind(api),
    [api]
  );
  return [value, setter] as const;
}

export function useAudioEnabled() {
  const api = useVenuePlaygroundAPI();
  return useSnapshot(api.state).audioEnabled;
}

export function ToggleAudioEnabled() {
  const myClientType = useMyClientType();
  const myTeamId = useMyTeamId();
  const api = useVenuePlaygroundAPI();

  useLayoutEffect(() => {
    api.setAudioEnabled(
      ClientTypeUtils.isHost(myClientType) ? true : !!myTeamId
    );
  }, [api, myClientType, myTeamId]);

  return null;
}

export const VenuePlaygroundContextProvider = (props: {
  myClientId: string;
  myClientType: ClientType;
  children?: ReactNode;
}): JSX.Element | null => {
  const { myClientId, myClientType } = props;
  const api = useMemo(
    () => new VenuePlaygroundAPI(myClientId, myClientType),
    [myClientId, myClientType]
  );

  useEffect(() => {
    return () => {
      api.destroy();
    };
  }, [api]);

  const ctxValue: Context = useMemo(() => ({ api }), [api]);

  return <context.Provider value={ctxValue}>{props.children}</context.Provider>;
};
