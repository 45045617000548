import { useMemo } from 'react';

import { type TeamV0 } from '../../../../types/team';
import { useTeams } from '../../../TeamAPI/TeamV1';
import { useTotalScoreMap } from '../../hooks';

export const useSelectTeamByScore = (): TeamV0[] => {
  const scoreByTeamId = useTotalScoreMap();
  const teams = useTeams({
    active: true,
    updateStaffTeam: true,
    excludeStaffTeam: true,
    excludeCohostTeam: true,
  });

  return useMemo(() => {
    return teams.sort((a, b) => {
      const scoreA = scoreByTeamId[a.id] || 0;
      const scoreB = scoreByTeamId[b.id] || 0;
      return scoreA === scoreB
        ? (a.name || '').localeCompare(b.name || '')
        : scoreB - scoreA;
    });
  }, [scoreByTeamId, teams]);
};
