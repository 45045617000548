import { type Logger } from '@lp-lib/logger-base';

import { getFeatureQueryParam } from '../../hooks/useFeatureQueryParam';
import { assertExhaustive } from '../../utils/common';
import { getDeviceIdFromTrack } from '../../utils/media';
import {
  type ActiveDeviceState,
  DefaultDeviceId,
  DefaultSpeakerDeviceOption,
  type DeviceOption,
  MediaErrorName,
  MediaKind,
  NoCameraDeviceOption,
  NoMicDeviceOption,
  type UserMediaResult,
} from './types';

const getUserMediaDebugEnabled = getFeatureQueryParam(
  'stream-get-user-media-debug'
);

export async function getUserMedia(
  constraints?: MediaStreamConstraints
): Promise<MediaStream> {
  try {
    const stream = await navigator.mediaDevices.getUserMedia(constraints);
    if (getUserMediaDebugEnabled)
      console.trace('======== new requested stream', {
        streamId: stream.id,
        numOfAudioTracks: stream.getAudioTracks().length,
        numOfVideoTracks: stream.getVideoTracks().length,
      });
    return stream;
  } catch (error) {
    throw error;
  }
}

export const getDevicesByKind = (
  devices: MediaDeviceInfo[],
  kind: MediaKind
): MediaDeviceInfo[] => {
  return devices.filter((item) => item.kind === kind && item.deviceId !== '');
};

export const getDeviceOptionsByKind = (
  devices: MediaDeviceInfo[],
  kind: MediaKind,
  fallbackDeviceOptions?: DeviceOption[]
): DeviceOption[] => {
  const deviceOptions = getDevicesByKind(devices, kind).map((item) => {
    return { value: item.deviceId, label: item.label };
  });
  if (deviceOptions.length > 0) {
    return deviceOptions;
  }
  return fallbackDeviceOptions || [];
};

export const buildDeviceOption = (
  result: Partial<DeviceOption>
): DeviceOption => {
  return { value: result.value || '', label: result.label || '' };
};

interface RetryPolicy {
  constraints: MediaStreamConstraints;
  ignorableErrorNames: string[];
}

export async function getUserMediaWithRetryPolicies(
  attemptPolicies: RetryPolicy[]
): Promise<MediaStream | null> {
  for (const policy of attemptPolicies) {
    try {
      return await getUserMedia(policy.constraints);
    } catch (error) {
      const err = error as Error;
      const found = policy.ignorableErrorNames.find(
        (errorName) => err.name === errorName
      );
      if (!found) {
        throw error;
      }
    }
  }
  return null;
}

/**
 * On Chrome, there is a virtual device called `default`, which will be the
 * alias of one real device in the list. When you switch the device on the system
 * level, the `default` device will be changed accordingly. But the MediaStream
 * API seems can not recognize the change with the same deviceId `default` while
 * the underlying device is switched to another one. So instead of using `default`,
 * this function will find the same device with the real deviceId. And we can use
 * the real deviceId to create MediaStream or pass it to the Agora API. If there is
 * device detected, it will return the one passed in.
 *
 * Currently there are two matching policies based on the observation,
 * 1. Default - MyMicrophone <=> MyMicrophone (label prefixed with 'Default - ')
 * 2. MyMicrophone <=> MyMicrophone (labels are exactly same)
 *
 * [Updated 02/16/2022]
 * This function is buggy because the label is localized, the `default` is `默认` if
 * the system language is Chinese.
 *
 * @param deviceOption
 * @param kind
 * @returns
 */
export const getAlternativeDeviceOption = async (
  deviceOption: DeviceOption,
  kind: MediaKind
): Promise<DeviceOption> => {
  const deviceId = deviceOption.value;
  if (deviceId !== DefaultDeviceId) return deviceOption;
  const devices = await navigator.mediaDevices.enumerateDevices();
  const label = deviceOption.label.replace(/^Default - /, '');
  const d = devices.find((d) => d.kind === kind && d.label === label);
  if (d) {
    return { label: d.label, value: d.deviceId };
  }
  return deviceOption;
};

export async function getActiveDeviceState(): Promise<ActiveDeviceState> {
  const devices = await navigator.mediaDevices.enumerateDevices();
  const state: ActiveDeviceState = {
    numOfAudioInputs: 0,
    numOfAudioOutputs: 0,
    numOfVideoInputs: 0,
  };
  devices.forEach((device) => {
    if (device.deviceId === '' || device.label === '') {
      return;
    }
    if (device.kind === MediaKind.AudioInput) {
      state.numOfAudioInputs++;
    } else if (device.kind === MediaKind.AudioOutput) {
      state.numOfAudioOutputs++;
    } else if (device.kind === MediaKind.VideoInput) {
      state.numOfVideoInputs++;
    }
  });
  return state;
}

enum MediaDeviceState {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
}

interface MediaDeviceInfoEx {
  deviceId: string;
  groupId: string;
  kind: MediaDeviceKind;
  label: string;
  state: MediaDeviceState;
  version: number;
}

type MediaDeviceStateChangedListener = (
  before: ActiveDeviceState,
  after: ActiveDeviceState
) => void;

/**
 * @deprecated This class should not be used
 */
export class DeviceStateWatcher {
  private audioInputs: MediaDeviceInfoEx[];
  private audioOutputs: MediaDeviceInfoEx[];
  private videoInputs: MediaDeviceInfoEx[];
  private timerId?: ReturnType<typeof setInterval>;
  private version: number;

  constructor(private log: Logger) {
    this.audioInputs = [];
    this.audioOutputs = [];
    this.videoInputs = [];
    this.version = 0;
  }

  private update(
    devices: MediaDeviceInfoEx[],
    device: MediaDeviceInfo
  ): MediaDeviceInfoEx | null {
    const foundDevice = devices.find((d) => d.deviceId === device.deviceId);
    if (!foundDevice) {
      const newDevice = {
        deviceId: device.deviceId,
        groupId: device.groupId,
        kind: device.kind,
        label: device.label,
        state: MediaDeviceState.Active,
        version: this.version,
      };
      devices.push(newDevice);
      return newDevice;
    } else {
      foundDevice.state = MediaDeviceState.Active;
      foundDevice.version = this.version;
      return null;
    }
  }

  private check(
    devices: MediaDeviceInfoEx[],
    version: number
  ): MediaDeviceInfoEx[] {
    const stateChanged: MediaDeviceInfoEx[] = [];
    devices.forEach((d) => {
      if (d.version < version) {
        if (d.state === MediaDeviceState.Active) {
          d.state = MediaDeviceState.Inactive;
          stateChanged.push(d);
        }
      }
    });
    return stateChanged;
  }

  private activeDeviceState(): ActiveDeviceState {
    return {
      numOfAudioInputs: this.audioInputs.filter(
        (d) => d.state === MediaDeviceState.Active
      ).length,
      numOfAudioOutputs: this.audioOutputs.filter(
        (d) => d.state === MediaDeviceState.Active
      ).length,
      numOfVideoInputs: this.videoInputs.filter(
        (d) => d.state === MediaDeviceState.Active
      ).length,
    };
  }

  async get(): Promise<ActiveDeviceState[]> {
    const devices = await navigator.mediaDevices.enumerateDevices();
    const before = this.activeDeviceState();
    this.version += 1;
    devices.forEach((device) => {
      if (device.deviceId === '' || device.label === '') {
        return;
      }
      if (device.kind === MediaKind.AudioInput) {
        this.update(this.audioInputs, device);
      } else if (device.kind === MediaKind.AudioOutput) {
        this.update(this.audioOutputs, device);
      } else if (device.kind === MediaKind.VideoInput) {
        this.update(this.videoInputs, device);
      }
    });
    this.check(this.audioInputs, this.version);
    this.check(this.audioOutputs, this.version);
    this.check(this.videoInputs, this.version);
    const after = this.activeDeviceState();
    return [before, after];
  }

  start(callback: MediaDeviceStateChangedListener): void {
    this.stop();
    this.timerId = setInterval(async () => {
      const [before, after] = await this.get();
      if (
        JSON.stringify(before) !== JSON.stringify(after) ||
        this.version === 1
      ) {
        this.log.debug('activeDeviceStatedChanged', {
          before: before,
          after: after,
        });
        callback(before, after);
      }
    }, 200);
  }

  stop(): void {
    if (this.timerId) {
      clearInterval(this.timerId);
    }
  }
}

export async function resolveUserMedia(
  audioConstraints: MediaTrackConstraints | boolean,
  videoConstraints: MediaTrackConstraints | boolean
): Promise<UserMediaResult> {
  const result: UserMediaResult = {
    audioStream: null,
    videoStream: null,
    audioError: null,
    videoError: null,
    audioDeviceOption: null,
    videoDeviceOption: null,
  };

  let mixedError: Error | null = null;
  try {
    if (audioConstraints || videoConstraints) {
      const constraints = {
        audio: audioConstraints,
        video: videoConstraints,
      };
      const stream = await getUserMedia(constraints);
      if (stream.getAudioTracks().length > 0) {
        result.audioStream = new MediaStream();
        result.audioStream.addTrack(stream.getAudioTracks()[0]);
      }
      if (stream.getVideoTracks().length > 0) {
        result.videoStream = new MediaStream();
        result.videoStream.addTrack(stream.getVideoTracks()[0]);
      }
    }
  } catch (error: UnassertedUnknown) {
    mixedError = error;
  }
  if (mixedError) {
    if (audioConstraints) {
      try {
        result.audioStream = await getUserMediaWithRetryPolicies([
          {
            constraints: { audio: audioConstraints, video: false },
            ignorableErrorNames: [MediaErrorName.OverconstrainedError],
          },
          {
            constraints: { audio: true, video: false },
            ignorableErrorNames: [],
          },
        ]);
      } catch (error: UnassertedUnknown) {
        result.audioError = error;
      }
    }

    if (videoConstraints) {
      try {
        result.videoStream = await getUserMediaWithRetryPolicies([
          {
            constraints: { audio: false, video: videoConstraints },
            ignorableErrorNames: [MediaErrorName.OverconstrainedError],
          },
          {
            constraints: { audio: false, video: { facingMode: 'user' } },
            ignorableErrorNames: [],
          },
        ]);
      } catch (error: UnassertedUnknown) {
        result.videoError = error;
      }
    }
  }

  const audioDeviceId = getDeviceIdFromTrack(
    result.audioStream?.getAudioTracks()[0]
  );
  const videoDeviceId = getDeviceIdFromTrack(
    result.videoStream?.getVideoTracks()[0]
  );
  if (audioDeviceId || videoDeviceId) {
    const devices = await navigator.mediaDevices.enumerateDevices();
    if (audioDeviceId) {
      result.audioDeviceOption =
        getDeviceOptionsByKind(devices, MediaKind.AudioInput).find(
          (d) => d.value === audioDeviceId
        ) || null;
    }
    if (videoDeviceId) {
      result.videoDeviceOption =
        getDeviceOptionsByKind(devices, MediaKind.VideoInput).find(
          (d) => d.value === videoDeviceId
        ) || null;
    }
  }
  return result;
}

export function formatError(kind: MediaKind, error: Error): string {
  if (error.name === MediaErrorName.OverconstrainedError) {
    switch (kind) {
      case MediaKind.AudioInput:
        return NoMicDeviceOption.label;
      case MediaKind.AudioOutput:
        return DefaultSpeakerDeviceOption.label;
      case MediaKind.VideoInput:
        return NoCameraDeviceOption.label;
      default:
        assertExhaustive(kind);
        break;
    }
  }
  return `[${error.name}]${error.message}`;
}
