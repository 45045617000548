import { useRef, useState } from 'react';

import { useOutsideClick } from '../../hooks/useOutsideClick';
import { SessionMode } from '../../types';
import { ArrowDownIcon, ArrowUpIcon } from '../icons/Arrows';

export interface SessionModeOptionValue {
  mode: SessionMode | null;
  onDemandControllerKind: string | null;
}

interface SessionModeOption {
  label: string;
  key: string;
  value: SessionModeOptionValue;
}

export const SESSION_MODE_OPTIONS: SessionModeOption[] = [
  {
    label: 'All Modes',
    key: 'all',
    value: {
      mode: null,
      onDemandControllerKind: null,
    },
  },
  {
    label: 'Live',
    key: 'live',
    value: {
      mode: SessionMode.Live,
      onDemandControllerKind: null,
    },
  },
  {
    label: 'OnDemand',
    key: 'ond',
    value: {
      mode: SessionMode.OnDemand,
      onDemandControllerKind: 'local',
    },
  },
  {
    label: 'OnDemand(cloud)',
    key: 'ond-cloud',
    value: {
      mode: SessionMode.OnDemand,
      onDemandControllerKind: 'cloud',
    },
  },
];

export const SessionModeFilter = (props: {
  value: SessionModeOptionValue;
  onChange: (val: SessionModeOptionValue, key: string) => void;
}): JSX.Element => {
  const { value, onChange } = props;

  const selectedOption = SESSION_MODE_OPTIONS.find(
    (o) =>
      o.value.mode === value.mode &&
      o.value.onDemandControllerKind === value.onDemandControllerKind
  );

  const [active, setActive] = useState(false);

  const ref = useRef<HTMLDivElement>(null);
  useOutsideClick(ref, () => {
    setActive(false);
  });

  const handleChange = (option: SessionModeOption) => {
    setActive(false);
    onChange(option.value, option.key);
  };

  return (
    <div className={`relative text-base text-white`} ref={ref}>
      <button
        type='button'
        className='btn-secondary text-white flex items-center pl-4 pr-2.5 py-2.5 gap-2.5 h-10'
        onClick={() => setActive(!active)}
      >
        <p>{selectedOption?.label || 'N/A'}</p>

        {active ? <ArrowUpIcon /> : <ArrowDownIcon />}
      </button>
      {active && (
        <div
          className='absolute flex flex-col border border-secondary rounded-xl
        px-6 py-3 mt-px bg-black whitespace-nowrap z-5 w-40'
        >
          {SESSION_MODE_OPTIONS.map((op) => (
            <div
              key={op.value.mode ?? '' + op.value.onDemandControllerKind ?? ''}
              className='py-1.5 truncate cursor-pointer text-sms w-full'
              onClick={() => handleChange(op)}
            >
              {op.label}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
