import { useState } from 'react';

import { useNextPersistentPointsScore } from '../../../PersistentPoints/Provider';
import { useMyTeamId } from '../../../Player';
import { GamePlayAnswerLayout } from '../Common/GamePlay/GamePlayAnswer';
import {
  type CreativePromptVoteCounts,
  useCreativePromptVoteCounts,
} from './hooks';

export function CreativePromptAnswer(): JSX.Element {
  const nextScoreDisplay = Math.round(useNextPersistentPointsScore() ?? 0);
  const [voteCounts, setVoteCounts] = useState<CreativePromptVoteCounts | null>(
    null
  );

  const teamId = useMyTeamId();
  useCreativePromptVoteCounts(setVoteCounts);

  return (
    <GamePlayAnswerLayout>
      {voteCounts && (
        <p className='max-w-4xl max-h-48 font-cairo font-extrabold text-6xl break-words line-clamp-3 overflow-hidden leading-normal text-center'>
          {`Your Submission got ${voteCounts.myTeamVoteCount} out of ${
            voteCounts.totalVoteCount
          } votes! (${
            voteCounts.totalVoteCount
              ? Math.round(
                  (voteCounts.myTeamVoteCount / voteCounts.totalVoteCount) * 100
                )
              : 0
          }%)`}
        </p>
      )}

      <div className='mt-10 mb-5 text-3xl h-12 font-cairo text-tertiary'>
        {teamId ? (
          <div className='flex items-center justify-center gap-2'>
            {`Your Team Earned ${nextScoreDisplay} points!`}
          </div>
        ) : (
          `You Earned ${nextScoreDisplay} Points!`
        )}
      </div>
    </GamePlayAnswerLayout>
  );
}
