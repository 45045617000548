export function ScoreboardBlockIcon(
  props: React.SVGProps<SVGSVGElement>
): JSX.Element {
  return (
    <svg
      className={props.className ?? 'w-3.5 h-3.5 fill-current'}
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox='0 0 14 14'
    >
      <path
        fill='#FBB707'
        d='M6 2a1 1 0 112 0v7.4a.6.6 0 01-.6.6h-.8a.6.6 0 01-.6-.6V2zM2 4a1 1 0 112 0v5.4a.6.6 0 01-.6.6h-.8a.6.6 0 01-.6-.6V4zM11 4a1 1 0 00-1 1v4.4a.6.6 0 00.6.6h.8a.6.6 0 00.6-.6V5a1 1 0 00-1-1zM1.6 11a.6.6 0 100 1.2h10.8a.6.6 0 100-1.2H1.6z'
      ></path>
    </svg>
  );
}
