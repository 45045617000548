import { useEffect, useMemo } from 'react';
import { useEffectOnce } from 'react-use';

import {
  type OverRoastedBlock,
  OverRoastedBlockGameSessionStatus,
} from '@lp-lib/game';

import { useInstance } from '../../../../hooks/useInstance';
import { ClientTypeUtils } from '../../../../types';
import { assertExhaustive } from '../../../../utils/common';
import { useSnapshot } from '../../../../utils/valtio';
import { Clock } from '../../../Clock';
import { useParticipantByUserIds } from '../../../Player';
import { useMyClientType } from '../../../Venue/VenuePlaygroundProvider';
import {
  useGameSessionLocalTimer,
  useGameSessionStatus,
  useIsLiveGamePlay,
} from '../../hooks';
import { ondWaitEnd } from '../../OndPhaseRunner';
import { next } from '../../store';
import { type GameControlProps } from '../Common/GameControl/types';
import { useStableBlock } from '../Common/hooks';
import {
  useOverRoastedGameSettings,
  useOverRoastedSummaryMap,
} from './OverRoastedProvider';
import {
  useOverRoastedGameControlAPI,
  useSelectCandidateGroupPlayersMap,
} from './OverRoastedProvider/OverRoastedGameControlProvider';
import { type OverRoastedGroupControl } from './OverRoastedProvider/OverRoastedGroupControl';
import { CupState, DispenserState, type Machine } from './types';
import { OverRoastedUtils } from './utils';

function useGameEndInAdvance() {
  const isLive = useIsLiveGamePlay();
  const nextFn = isLive ? next : ondWaitEnd;

  const settings = useOverRoastedGameSettings();
  const summaryMap = useOverRoastedSummaryMap();
  const participants = useParticipantByUserIds(Object.keys(summaryMap), true);

  const remainSec = useGameSessionLocalTimer() || 0;

  const shouldSwitch = useMemo(() => {
    if (remainSec <= 3 || remainSec === settings.gameTimeSec) return false;
    if (Object.keys(summaryMap).length === 0) return true;
    return (
      participants.length > 0 &&
      participants.every((p) => !!summaryMap[p.id]?.tutorialCompleted)
    );
  }, [participants, remainSec, settings.gameTimeSec, summaryMap]);

  useEffect(() => {
    if (!shouldSwitch) return;

    nextFn();
  }, [nextFn, shouldSwitch]);
}

function calDelayedMS(startedAt: Nullable<number>, defaultDelayedMS: number) {
  if (!startedAt) return defaultDelayedMS;

  const diff = Math.max(Clock.instance().now() - startedAt, 0);
  const rtt = 2 * diff;
  return Math.max(defaultDelayedMS - rtt, 0);
}

function MachineController(props: {
  machine: Machine;
  control: OverRoastedGroupControl;
}) {
  const { machine, control } = props;
  const settings = useOverRoastedGameSettings();

  // match order after filling
  useEffect(() => {
    if (machine.cup.state !== CupState.Filling) return;

    control.matchOrder(machine.id);
  }, [control, machine.cup.state, machine.id]);

  // release order & reset machine after deleting
  useEffect(() => {
    if (machine.cup.state !== CupState.Deleting) return;

    control.releaseOrder(machine.id);

    const delayed = calDelayedMS(machine.cup.stateChangedAt, 500);
    setTimeout(() => {
      control.resetMachine(machine.id);
    }, delayed);
    // return () => {
    //   clearTimeout(timer);
    // };
  }, [control, machine.cup.state, machine.cup.stateChangedAt, machine.id]);

  // complete order, add summary/score and reset machine after serving
  useEffect(() => {
    if (machine.dispenser.state !== DispenserState.Served) return;

    if (machine.cup.state === CupState.Matched) {
      const score = OverRoastedUtils.CalOrderPoints(
        settings.pointsPerOrder,
        machine.cup.ingredients?.length || 0
      );
      control.deleteOrder(machine.id);
      control.updateGameSummary(score, false);
      control.incrBlockDetailScore(score);
    }

    const delayed = calDelayedMS(machine.cup.servedAt, 1000);
    setTimeout(() => {
      control.resetMachine(machine.id);
    }, delayed);
    // return () => {
    //   clearTimeout(timer);
    // };
  }, [
    control,
    machine.cup.ingredients?.length,
    machine.cup.servedAt,
    machine.cup.state,
    machine.dispenser.state,
    machine.id,
    settings.pointsPerOrder,
  ]);

  return null;
}

function GroupController(props: { control: OverRoastedGroupControl }) {
  const { control } = props;

  const machineMap = (useSnapshot(control.state) as typeof control.state)
    .machineMap;

  return (
    <>
      {Object.values(machineMap || {})?.map((m) => (
        <MachineController key={m.id} machine={m} control={control} />
      ))}
    </>
  );
}

type SharedProps = GameControlProps<OverRoastedBlock>;

function Loaded(_props: SharedProps): JSX.Element | null {
  const api = useOverRoastedGameControlAPI();

  useEffectOnce(() => {
    api.resetGame();
  });

  return null;
}

function GameInit(props: SharedProps): JSX.Element | null {
  const api = useOverRoastedGameControlAPI();
  const settings = useInstance(() =>
    OverRoastedUtils.GetGameSettings(props.block)
  );
  const groupPlayersMap = useSelectCandidateGroupPlayersMap(
    props.block.fields.tutorialMode
  );

  useEffectOnce(() => {
    api.initGame(groupPlayersMap, settings);
  });

  return null;
}

function GameStart(_props: SharedProps): JSX.Element | null {
  const api = useOverRoastedGameControlAPI();
  const controllers = useInstance(() => api.getGroupControls());

  useEffectOnce(() => {
    api.startGame();
  });

  useGameEndInAdvance();

  return (
    <>
      {controllers.map((c) => (
        <GroupController key={c.groupId} control={c} />
      ))}
    </>
  );
}

function GameEnd(_props: SharedProps): JSX.Element | null {
  const api = useOverRoastedGameControlAPI();

  useEffectOnce(() => {
    api.stopGame();
  });

  useEffect(() => {
    return () => {
      api.deinitGame();
    };
  }, [api]);

  return null;
}

function END(_props: SharedProps): JSX.Element | null {
  const api = useOverRoastedGameControlAPI();

  useEffectOnce(() => {
    api.resetGame();
  });
  return null;
}

export function OverRoastedBlockGameControl(
  props: SharedProps
): JSX.Element | null {
  const block = useStableBlock(props.block);
  const gameSessionStatus =
    useGameSessionStatus<OverRoastedBlockGameSessionStatus>();

  const api = useOverRoastedGameControlAPI();

  useEffect(() => {
    return () => {
      api.resetGame();
    };
  }, [api]);

  // TODO(guoqiang): just a work around!
  const isLive = useIsLiveGamePlay();
  const isAudience = ClientTypeUtils.isAudience(useMyClientType());
  if (isLive && isAudience) return null;

  switch (gameSessionStatus) {
    case OverRoastedBlockGameSessionStatus.LOADED:
      return <Loaded block={block} />;
    case OverRoastedBlockGameSessionStatus.GAME_INIT:
      return <GameInit block={block} />;
    case OverRoastedBlockGameSessionStatus.GAME_START:
      return <GameStart block={block} />;
    case OverRoastedBlockGameSessionStatus.GAME_END:
      return <GameEnd block={block} />;
    case OverRoastedBlockGameSessionStatus.END:
      return <END block={block} />;
    case OverRoastedBlockGameSessionStatus.INTRO:
    case OverRoastedBlockGameSessionStatus.OUTRO:
    case OverRoastedBlockGameSessionStatus.RESULTS:
    case OverRoastedBlockGameSessionStatus.SCOREBOARD:
    case null:
    case undefined:
      break;
    default:
      assertExhaustive(gameSessionStatus);
      break;
  }

  return null;
}
