import { useEffect, useMemo } from 'react';
import { useLatest, usePrevious } from 'react-use';
import { proxy } from 'valtio';

import {
  type DrawingPromptBlock,
  DrawingPromptBlockGameSessionStatus,
} from '@lp-lib/game';

import { useInstance } from '../../../../hooks/useInstance';
import { ClientTypeUtils } from '../../../../types';
import { assertExhaustive } from '../../../../utils/common';
import { markSnapshottable, ValtioUtils } from '../../../../utils/valtio';
import { useClock } from '../../../Clock';
import { useGainPointsAnimationGamePlayTrigger } from '../../../GainPointsAnimation/useGainPointsAnimationGamePlayTrigger';
import { FloatBoard, FloatLayout } from '../../../Layout';
import { LayoutAnchor } from '../../../LayoutAnchors/LayoutAnchors';
import { useSyncPersistentPointsRevealAnswer } from '../../../PersistentPoints/Provider';
import { useMyTeamId } from '../../../Player';
import { useSoundEffect } from '../../../SFX';
import { useTeams } from '../../../TeamAPI/TeamV1';
import { useMyClientType } from '../../../Venue/VenuePlaygroundProvider';
import { GoAnimation } from '../../GameBlockCardAnimations';
import {
  useGameSessionStatus,
  useIsLiveGamePlay,
  useTimerRecover,
} from '../../hooks';
import { countdownV2, resetTimer, setTimer } from '../../store';
import { GamePlayMediaLayout } from '../Common/GamePlay/GamePlayMediaLayout';
import {
  buildGamePlayMedia,
  type GamePlayMedia,
  GamePlayMediaPlayer,
  type GamePlayMediaPlayerLayout,
  type GamePlayProps,
  Leaderboard,
  useGamePlayMediaPlayable,
  useGamePlayMediaUISync,
  useGamePlayUITransitionControl,
} from '../Common/GamePlay/Internal';
import { StageBgSingleFrame } from '../Common/GamePlay/StageBgSingleFrame';
import { useSubmissionStatusWaitEnder } from '../Common/GamePlay/SubmissionStatusProvider';
import { useRankedTeamScores, useStableBlock } from '../Common/hooks';
import { DrawingPromptBlockHostView } from './DrawingPromptBlockHostView';
import { DrawingPromptPlayground } from './DrawingPromptPlayground';
import {
  useAllPickedDrawings,
  useCurrMatch,
  useDrawingGame,
  useDrawingPromptGamePlayAPI,
  useDrawingPromptSharedAPI,
  usePreloadCanvasBackground,
} from './DrawingPromptProvider';
import { DrawingPromptUtils } from './utils';

type DrawingPromptGamePlayLocalState = {
  gamePlayMedia: Nullable<GamePlayMedia, false>;
  mediaPlayerLayout: GamePlayMediaPlayerLayout | null;
  mediaPlayerLoop: boolean;
  showResults: boolean;
};

class DrawingPromptGamePlayLocalAPI {
  private _state;
  constructor(
    private block: DrawingPromptBlock,
    isHost: boolean,
    isLive: boolean,
    private startTimerWorker = isHost ? !isLive : true
  ) {
    this._state = markSnapshottable(
      proxy<DrawingPromptGamePlayLocalState>(this.initState())
    );
  }

  async init() {
    setTimer('submission', this.block.fields.drawingTimeSec);
  }

  async startDrawingPhase() {
    await this.startTimer();
  }

  async initTeamVotePhase() {
    await resetTimer('submission');
    setTimer('submission', this.block.fields.votingTimeSec);
  }

  async startTeamVotePhase() {
    await this.startTimer();
  }

  async initTitleCreationPhase(teamsCount: number) {
    await resetTimer('submission');
    setTimer(
      'submission',
      DrawingPromptUtils.GetTitleCreationTimeSec(teamsCount)
    );
  }

  async startTitleCreationPhase() {
    await this.startTimer();
  }

  async startMatchPromptTimer(timer: number) {
    await resetTimer('submission');
    setTimer('submission', timer);
    await this.startTimer(true);
  }

  revealResults() {
    this.updateGamePlayMedia(null, undefined);
    this._state.showResults = true;
  }

  hideResults() {
    this._state.showResults = false;
  }

  async resetTimer() {
    await resetTimer('submission');
  }

  updateBackgroundMedia() {
    if (this._state.gamePlayMedia) return;
    const media = buildGamePlayMedia(
      {
        media: DrawingPromptUtils.GetBackgroundMedia(this.block),
        mediaData: this.block.fields.backgroundMediaData,
      },
      {
        stage: 'custom',
        isBackgroundMedia: true,
      }
    );
    this.updateGamePlayMedia(media, 'fullscreen', true);
  }

  async load() {
    await this.reset();
    this.updateBackgroundMedia();
  }

  async reset() {
    await resetTimer('submission');
    ValtioUtils.reset(this._state, this.initState());
  }

  private updateGamePlayMedia(
    media: Nullable<GamePlayMedia, false>,
    layout: GamePlayMediaPlayerLayout,
    loop?: boolean
  ) {
    this.state.gamePlayMedia = media;
    this.state.mediaPlayerLayout = layout;
    this.state.mediaPlayerLoop = !!loop;
  }

  private async startTimer(startTimerWorker?: boolean) {
    await countdownV2({
      debug: 'DrawingPromptBlockGamePlay',
      startTimeWorker: startTimerWorker ?? this.startTimerWorker,
      flushCountingStatus: false,
    });
  }

  private initState(): DrawingPromptGamePlayLocalState {
    return {
      gamePlayMedia: null,
      mediaPlayerLayout: null,
      mediaPlayerLoop: false,
      showResults: false,
    };
  }

  get state() {
    return this._state;
  }
}

function MatchPromptLocalControl(props: {
  control: DrawingPromptGamePlayLocalAPI;
}) {
  const { control } = props;
  const total = useAllPickedDrawings().length;
  const currMatch = useCurrMatch();
  const game = useDrawingGame();
  const clock = useClock();

  useEffect(() => {
    if (
      !game?.matchTimePerDrawing ||
      currMatch.drawingIndex >= total ||
      currMatch.phase !== 'vote'
    ) {
      return;
    }
    // time recovery covered
    const diff = Math.round((clock.now() - currMatch.drawingSwitchedAt) / 1000);
    const time = game.matchTimePerDrawing;
    const nextTime = diff > 1 && diff < time ? time - diff : time;
    control.startMatchPromptTimer(nextTime);
  }, [
    clock,
    control,
    currMatch.drawingIndex,
    currMatch.drawingSwitchedAt,
    currMatch.phase,
    game?.matchTimePerDrawing,
    total,
  ]);

  useEffect(() => {
    if (currMatch.phase !== 'reveal') return;
    control.resetTimer();
  }, [control, currMatch.phase]);
  return null;
}

function Results(): JSX.Element | null {
  const teamScores = useRankedTeamScores('currentScore', null, 'always');
  const myTeamId = useMyTeamId();
  const formatter = useInstance(() =>
    Intl.NumberFormat('en', { notation: 'compact' })
  );

  const { play } = useSoundEffect('showResults');

  useEffect(() => {
    play();
  }, [play]);

  return (
    <>
      <FloatBoard
        containerZIndex='z-20'
        containerDisplay='flex'
        bgStyle='border-2 border-cyan rounded-xl bg-black bg-opacity-60'
        title='GAME RESULTS'
      >
        <div className='w-full h-full pt-10 pb-20 px-16 overflow-y-auto scrollbar flex flex-col'>
          {teamScores.map((s) => {
            return (
              <div
                key={s.team.id}
                className={`w-full animate-fade-in-up min-h-12 mb-4 pl-6 rounded-3xl bg-black bg-opacity-95 flex flex-row justify-between items-center ${
                  s.team.id === myTeamId ? 'text-tertiary' : 'text-white'
                }`}
              >
                <p className={`text-lg font-bold font-cairo`}>{s.team.name}</p>
                <div className='flex flex-row justify-center items-center'>
                  <div className='w-11 h-11 mr-0.75 ml-3 rounded-full bg-warning text-black flex flex-col justify-center items-center'>
                    <div className='font-extrabold text-xl leading-5 font-cairo'>
                      {formatter.format(s.currentScore)}
                    </div>
                    <p className='text-3xs text-black'>pts</p>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </FloatBoard>
      <FloatLayout className='flex items-center justify-center'>
        <LayoutAnchor
          id='gameplay-points-animation-top'
          className='w-full max-w-4xl'
        />
      </FloatLayout>
    </>
  );
}

function MiniLeaderboard(): JSX.Element | null {
  return (
    <FloatLayout
      useCustomPaddingX
      className='left-4 flex items-start justify-center'
    >
      <div className='w-[240px] h-[320px]'>
        <Leaderboard header='Round Scores' />
      </div>
    </FloatLayout>
  );
}

export function DrawingPromptBlockGamePlay(
  props: GamePlayProps<DrawingPromptBlock>
): JSX.Element | null {
  const block = useStableBlock(props.block);
  const gss = useGameSessionStatus<DrawingPromptBlockGameSessionStatus>();
  const prevGss = usePrevious(gss);
  const [uiState, uiControl] = useGamePlayUITransitionControl();
  const isHost = ClientTypeUtils.isHost(useMyClientType());
  const isLive = useIsLiveGamePlay();
  const control = useMemo(() => {
    return new DrawingPromptGamePlayLocalAPI(block, isHost, isLive);
  }, [block, isHost, isLive]);
  const state = control.state;
  const teams = useLatest(
    useTeams({
      updateStaffTeam: true,
      excludeStaffTeam: true,
    })
  );
  const teamId = useMyTeamId();
  const sharedAPI = useDrawingPromptSharedAPI();
  const gamePlayAPI = useDrawingPromptGamePlayAPI();

  useTimerRecover(async (status) => {
    if (status === DrawingPromptBlockGameSessionStatus.DRAWING_START) {
      return block.fields.drawingTimeSec;
    } else if (
      status === DrawingPromptBlockGameSessionStatus.TEAM_VOTE_COUNTING
    ) {
      return block.fields.votingTimeSec;
    } else if (
      status === DrawingPromptBlockGameSessionStatus.TITLE_CREATION_COUNTING
    ) {
      const game = await sharedAPI.getGame();
      return (
        game?.titleCreationTimeSec ??
        DrawingPromptUtils.GetTitleCreationTimeSec(teams.current.length)
      );
    }
    // time recovery of MATCH_PROMPT_COUNTING is handled separately
  });
  useSyncPersistentPointsRevealAnswer(state.showResults);
  useGainPointsAnimationGamePlayTrigger();
  useSubmissionStatusWaitEnder();
  usePreloadCanvasBackground(block);

  useEffect(() => {
    sharedAPI.on();
    return () => sharedAPI.off();
  }, [sharedAPI]);

  useEffect(() => {
    if (!teamId) return;
    gamePlayAPI.on();
    return () => gamePlayAPI.off();
  }, [gamePlayAPI, teamId]);

  useEffect(() => {
    return () => {
      control.reset();
      sharedAPI.reset();
      gamePlayAPI.reset();
    };
  }, [control, gamePlayAPI, sharedAPI]);

  useEffect(() => {
    if (prevGss === gss) return;
    switch (gss) {
      case DrawingPromptBlockGameSessionStatus.LOADED:
        control.load();
        break;
      case DrawingPromptBlockGameSessionStatus.INIT:
        control.init();
        break;
      case DrawingPromptBlockGameSessionStatus.DRAWING_START:
        control.startDrawingPhase();
        break;
      case DrawingPromptBlockGameSessionStatus.DRAWING_END:
        control.initTeamVotePhase();
        break;
      case DrawingPromptBlockGameSessionStatus.TEAM_VOTE_COUNTING:
        control.startTeamVotePhase();
        break;
      case DrawingPromptBlockGameSessionStatus.TEAM_VOTE_DONE:
        control.initTitleCreationPhase(teams.current.length);
        break;
      case DrawingPromptBlockGameSessionStatus.TITLE_CREATION_COUNTING:
        control.startTitleCreationPhase();
        break;
      case DrawingPromptBlockGameSessionStatus.TITLE_CREATION_DONE:
        break;
      case DrawingPromptBlockGameSessionStatus.MATCH_PROMPT_INIT:
        control.resetTimer();
        break;
      case DrawingPromptBlockGameSessionStatus.MATCH_PROMPT_COUNTING:
        // check MatchPromptLocalControl
        break;
      case DrawingPromptBlockGameSessionStatus.MATCH_PROMPT_DONE:
        // check MatchPromptLocalControl
        break;
      case DrawingPromptBlockGameSessionStatus.REVIEW_ALL_DRAWINGS:
        break;
      case DrawingPromptBlockGameSessionStatus.RESULTS:
        control.revealResults();
        break;
      case DrawingPromptBlockGameSessionStatus.SCOREBOARD:
        control.hideResults();
        break;
      case DrawingPromptBlockGameSessionStatus.END:
        control.hideResults();
        break;
      case null:
      case undefined:
        break;
      default:
        assertExhaustive(gss);
        break;
    }
  }, [gss, prevGss, control, teams, sharedAPI]);

  useGamePlayMediaUISync({
    block,
    gameSessionStatus: gss,
    media: state.gamePlayMedia,
    state: uiState,
    control: uiControl,
  });

  const inGame =
    !!gss &&
    gss >= DrawingPromptBlockGameSessionStatus.INIT &&
    gss <= DrawingPromptBlockGameSessionStatus.REVIEW_ALL_DRAWINGS;

  useEffect(() => {
    if (!inGame) return;
    // recover background media if user joins in the middle of the game
    control.updateBackgroundMedia();
  }, [control, inGame]);

  const isMatchPromptPhase =
    !!gss &&
    gss >= DrawingPromptBlockGameSessionStatus.MATCH_PROMPT_COUNTING &&
    gss <= DrawingPromptBlockGameSessionStatus.MATCH_PROMPT_DONE;

  const mediaPlayable = useGamePlayMediaPlayable({
    block,
    gameSessionStatus: gss,
    media: state.gamePlayMedia,
    state: uiState,
    custom: () =>
      !!gss && gss >= DrawingPromptBlockGameSessionStatus.DRAWING_START,
  });

  const fullscreen = state.mediaPlayerLayout === 'fullscreen';

  return (
    <div className='fixed w-screen h-screen text-white'>
      {state.gamePlayMedia && state.mediaPlayerLayout && (
        <StageBgSingleFrame gamePlayMedia={state.gamePlayMedia} />
      )}
      {uiState.playGoAnimation && <GoAnimation />}
      <GamePlayMediaLayout fullscreen={fullscreen}>
        {state.gamePlayMedia && state.mediaPlayerLayout && (
          <GamePlayMediaPlayer
            gamePlayMedia={state.gamePlayMedia}
            play={mediaPlayable}
            mode={fullscreen || !uiState.mediaEndEffect ? 'full' : 'small'}
            layout={state.mediaPlayerLayout}
            loop={state.mediaPlayerLoop}
          />
        )}
      </GamePlayMediaLayout>
      {!isHost && isMatchPromptPhase && <MiniLeaderboard />}
      {isMatchPromptPhase && <MatchPromptLocalControl control={control} />}
      {inGame && (
        <FloatLayout className='flex items-center justify-center'>
          {isHost ? (
            <DrawingPromptBlockHostView block={block} uiControl={uiControl} />
          ) : (
            <DrawingPromptPlayground block={block} uiControl={uiControl} />
          )}
        </FloatLayout>
      )}
      {state.showResults && <Results />}
    </div>
  );
}
