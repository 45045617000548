import { useEffect, useState } from 'react';
import { useLatest } from 'react-use';

import { type SpotlightBlockV2 } from '@lp-lib/game';

import { type Participant } from '../../../../types';
import {
  useParticipantByUserIds,
  useParticipantsByClientIds,
} from '../../../Player';
import {
  useTeamMembers,
  useTeamMembersByTeamIds,
} from '../../../TeamAPI/TeamV1';
import { useGameSessionPreconfigVIPUserIds } from '../Common/GamePlay/GameSessionPreconfigProvider';
import { useSelectTeamByScore } from './hooks';

export const spotlightV2LastPlaceTeam = -1;
export const spotlightV2RandomSelection = -100;
export const spotlightV2AskforVolunteers = -101;
export const spotlightV2HeadToHead = -102;

export class SpotlightBlockV2Utils {
  static GetMaxVolunteers(block: SpotlightBlockV2): number {
    if (block.fields.preselectedTeamOrder === spotlightV2HeadToHead) return 2;
    if (block.fields.preselectedTeamOrder === spotlightV2AskforVolunteers)
      return 8;
    return 0;
  }

  static FormatSpotlightV2Message(template: string, names: string[]): string {
    if (names.length === 0) {
      return template;
    }

    if (names.length === 1) {
      return template.replace(/%Name%/g, names[0]);
    }

    const str = `${names.slice(0, -1).join(', ')} and ${
      names[names.length - 1]
    }`;
    return template.replace(/%Name%/g, str);
  }
  static GetStreamViewSize(numOfPlayers: number): string {
    return numOfPlayers === 1
      ? 'w-60 h-60'
      : numOfPlayers === 2
      ? 'w-55 h-55 xl:w-65 xl:h-65 2xl:w-75 2xl:h-75'
      : numOfPlayers < 4
      ? 'w-50 h-50'
      : 'w-40 h-40 xl:w-45 xl:h-45 2xl:w-50 2xl:h-50';
  }

  static GetFBPath(
    venueId: string,
    kind:
      | 'root'
      | 'game'
      | 'volunteering'
      | 'voting'
      | 'buzzing'
      | 'volunteers'
      | 'vote'
      | 'buzzers'
      | 'instantWinner'
      | 'mute'
  ): string {
    if (kind === 'root') return `spotlightV2/${venueId}`;
    return `spotlightV2/${venueId}/${kind}`;
  }

  static GetFBMemberPath(
    venueId: string,
    memberId: string,
    kind: 'volunteers' | 'buzzers' | 'mute' | 'vote'
  ): string {
    return `spotlightV2/${venueId}/${kind}/${memberId}`;
  }
}

export const usePreselectClientIds = (block: SpotlightBlockV2): string[] => {
  const preconfigedClientIds = usePreselectedClientIdsByPreConfig(block);
  const teamOrderedClientIds = usePreselectedClientIdsByTeamScore(block);
  const teamRandomSelectionClientIds = usePreselectedClientIdsByRandom(block);

  const ref = useLatest(
    preconfigedClientIds.length > 0
      ? preconfigedClientIds
      : teamOrderedClientIds.length > 0
      ? teamOrderedClientIds
      : teamRandomSelectionClientIds
  );

  const [res, setRes] = useState(ref.current);
  useEffect(() => {
    setRes(ref.current);
  }, [block.id, ref]);

  return res;
};

const usePreselectedClientIdsByPreConfig = (
  _block: SpotlightBlockV2
): string[] => {
  const vipUserIds = useGameSessionPreconfigVIPUserIds();
  const preselectedClientIds = useParticipantByUserIds(vipUserIds, true).map(
    (p) => p.clientId
  );
  return preselectedClientIds;
};

const usePreselectedClientIdsByRandom = (block: SpotlightBlockV2): string[] => {
  const teams = useSelectTeamByScore();
  const teamsMembers = useTeamMembersByTeamIds(teams.map((t) => t.id));
  const clientIds: Participant['clientId'][] = [];

  if (
    block.fields.preselectedTeamOrder === spotlightV2RandomSelection &&
    teams.length > 0
  ) {
    teams.forEach((team) => {
      const members = teamsMembers[team.id];
      if (members.length > 0) {
        clientIds.push(members[Math.floor(Math.random() * members.length)].id);
      }
    });
  }

  return useParticipantsByClientIds(clientIds)
    .sort((a, b) => a.username.localeCompare(b.username))
    .map((p) => p.clientId);
};
const usePreselectedClientIdsByTeamScore = (
  block: SpotlightBlockV2
): string[] => {
  const teams = useSelectTeamByScore();
  const preselectedOrder = block.fields.preselectedTeamOrder;
  const preSelectedTeam =
    preselectedOrder === 0 ||
    preselectedOrder > teams.length ||
    -preselectedOrder > teams.length ||
    preselectedOrder < spotlightV2LastPlaceTeam
      ? null
      : preselectedOrder < 0
      ? teams[teams.length + preselectedOrder]
      : teams[preselectedOrder - 1];

  const members = useTeamMembers(preSelectedTeam?.id);

  const preselectedClientIds = useParticipantsByClientIds(
    members?.map((m) => m.id) ?? []
  )
    .sort((a, b) => a.username.localeCompare(b.username))
    .map((p) => p.clientId);

  return preselectedClientIds;
};
