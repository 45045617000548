import { type Block, GameSessionUtil } from '@lp-lib/game';

import { useBlockRecorderVersion } from '../../../../GameRecorder/BlockRecorderProvider';
import {
  StreamingActiveMode,
  useStreamingMode,
} from '../../../../StreamingTools/hooks';
import { BlockCard } from '../../../GameBlockEditor';
import {
  useGameSessionBlock,
  useGameSessionStatus,
  useIsLiveGamePlay,
  useOndGameState,
  usePlayedBlockIds,
} from '../../../hooks';
import { BlockKnifeUtils } from '../../Shared';

export function ControllerBlockList(props: {
  blocks: Block[];
  selectedBlockIndex: number | null;
  setSelectedBlockIndex: (i: number | null) => void;
}): JSX.Element {
  const { blocks, selectedBlockIndex, setSelectedBlockIndex } = props;

  const isLive = useIsLiveGamePlay();
  const streamingMode = useStreamingMode();
  const isRecordMode = streamingMode === StreamingActiveMode.Rec;
  const gameSessionStatus = useGameSessionStatus();
  const gameSessionBlock = useGameSessionBlock();
  const ondGameState = useOndGameState();
  const map = GameSessionUtil.StatusMapFor(gameSessionBlock?.type);

  const playedBlockIds = usePlayedBlockIds();
  const blockRecorderVersion = useBlockRecorderVersion();

  return (
    <div className='flex flex-col gap-1'>
      {blocks.map((block, index: number) => (
        <BlockCard
          dataTestId={`controller-block-item-${index}`}
          key={block.id}
          index={index}
          block={block}
          isSelected={index === selectedBlockIndex}
          isSelectable={
            (!isLive &&
              (!ondGameState ||
                ondGameState === 'ended' ||
                ondGameState === 'paused')) ||
            (isLive &&
              (gameSessionBlock === null ||
                gameSessionStatus === null ||
                gameSessionStatus === map?.loaded ||
                gameSessionStatus === map?.intro ||
                gameSessionStatus === map?.end))
          }
          recordingVersionMismatch={
            !!block.recording &&
            block.recording.version !== blockRecorderVersion
          }
          isPlaying={gameSessionBlock?.id === block.id && !!gameSessionStatus}
          isPlayed={playedBlockIds.includes(block.id)}
          isDisabled={
            isRecordMode &&
            !BlockKnifeUtils.IsRecordable(block, blockRecorderVersion)
          }
          onSelect={() => setSelectedBlockIndex(index)}
          scrollOptions={{
            block: 'start',
            behavior: 'smooth',
          }}
          withRecordingLogButton
        />
      ))}
    </div>
  );
}
