import { getStaticAssetPath } from '../../utils/assets';

export function VideoBackground() {
  return (
    <div className='w-full h-full absolute top-0 left-0 -z-1 before:video-dark-overlay'>
      <video
        className='w-full h-full object-cover'
        src={getStaticAssetPath('videos/onboarding-background-2023_fhd.mp4')}
        muted
        loop
        autoPlay
      />
    </div>
  );
}
