import { useState } from 'react';

import { useOnDGameAnalytics } from '../../../analytics/useOndGameAnalytics';
import { useLiveCallback } from '../../../hooks/useLiveCallback';
import { NotificationType } from '../../../types';
import type { GamePack } from '../../../types/game';
import { uuidv4 } from '../../../utils/common';
import { useNotificationDataSourceRedux } from '../../Notification/Context';
import { useMyClientIdGetter } from '../../Venue';
import { useGameHostingCoordinatorGetter } from '../GameHostingProvider';
import { useOpenGamePackDetailModal } from '../GamePack';
import { useGameSessionGamePackId, useOndGameState } from '../hooks';
import { useOpenGameLibrary } from './GameLibraryContext';

function RequestThisExperienceDetailAction(props: { pack: GamePack }) {
  const getMyClientId = useMyClientIdGetter();
  const getCoordinator = useGameHostingCoordinatorGetter();
  const [hasRequested, setHasRequested] = useState(false);
  const notificationDataSource = useNotificationDataSourceRedux();
  const loadedGamePackId = useGameSessionGamePackId();
  const ondGameState = useOndGameState();
  const analytics = useOnDGameAnalytics();

  const handleRequest = useLiveCallback(async () => {
    const requestedByClientId = getMyClientId();
    const toUserClientId = getCoordinator()?.clientId;

    if (!toUserClientId) return;

    notificationDataSource.send({
      id: uuidv4(),
      toUserClientId,
      type: NotificationType.GamePackRequested,
      createdAt: Date.now(),
      metadata: {
        gamePackId: props.pack.id,
        requestedByClientId,
      },
    });
    analytics.trackExperienceRequested({
      gamePackId: props.pack.id,
      gamePackName: props.pack.name,
      source: 'explore-library',
      ondGameState,
    });
    return setHasRequested(true);
  });

  if (ondGameState && ondGameState !== 'ended') return null;

  const isLoaded = loadedGamePackId === props.pack.id;
  return (
    <div>
      <button
        type='button'
        className='btn-delete w-full h-15 rounded flex items-center justify-center gap-2 text-white'
        disabled={hasRequested || isLoaded}
        onClick={handleRequest}
      >
        {hasRequested ? <>Sent!</> : <>Request this Experience</>}
      </button>
      <div className='w-full text-center text-sms text-icon-gray p-2'>
        {isLoaded ? (
          <>
            Get ready to play! This experience is currently loaded in the venue.
            Let the organizer know you’re ready to start.
          </>
        ) : (
          <>
            Interested in playing? Click the button above and we’ll notify the
            organizer.
          </>
        )}
      </div>
    </div>
  );
}

export function useOndOpenParticipantExploreLibrary() {
  const openGameLibrary = useOpenGameLibrary();
  const openGamePackDetailModal = useOpenGamePackDetailModal();
  return useLiveCallback(() => {
    openGameLibrary({
      type: 'bottomPublicLibrary',
      onGamePackClick: async (gamePack) => {
        await openGamePackDetailModal(
          gamePack,
          <RequestThisExperienceDetailAction pack={gamePack} />
        );
        return false;
      },
    });
  });
}
