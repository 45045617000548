export const getInitials = (name = ''): string => {
  return name
    .replace(/\s+/, ' ')
    .split(' ')
    .slice(0, 2)
    .map((v) => v && v[0].toUpperCase())
    .join('');
};

export const removeDash = (uuid: string): string => {
  return uuid.replace(/-/g, '');
};

export const joinNames = (names: (string | number)[]): string => {
  if (names.length === 0) return '';
  if (names.length === 1) return String(names[0]);
  return `${names.slice(0, -1).join(', ')} and ${names[names.length - 1]}`;
};
