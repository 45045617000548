import { useEffect, useRef, useState } from 'react';

import { getAverageVolume, MicVolumeMeter } from '../../utils/MicVolumeMeter';

const VolumeMeterSegments = 8;

interface MicVolumeMeterVisualizerProps {
  stream: MediaStream | null;
  className?: string;
  segmentSize?: string;
  fps?: number;
}

export const MicVolumeMeterVisualizer = ({
  stream,
  className,
  segmentSize = 'w-[12.5%] h-1.5',
  fps = 10,
}: MicVolumeMeterVisualizerProps): JSX.Element => {
  const [volumeMeterIndex, setVolumeMeterIndex] = useState<number>(0);
  const [volumeMeterColor, setVolumeMeterColor] = useState<string>('');
  const micVolumeMeterRef = useRef<MicVolumeMeter | null>(null);
  const timerIdRef = useRef<ReturnType<typeof setTimeout> | null>(null);

  useEffect(() => {
    if (!stream) return;

    if (micVolumeMeterRef.current) {
      micVolumeMeterRef.current.close();
    }

    micVolumeMeterRef.current = new MicVolumeMeter(stream);

    const render = () => {
      if (micVolumeMeterRef.current) {
        const array = micVolumeMeterRef.current.getByteFrequencyData();
        const volume = getAverageVolume(array);
        const activeVolumeMeterIndex = Math.min(
          Math.round(volume / 10),
          VolumeMeterSegments
        );
        setVolumeMeterIndex(activeVolumeMeterIndex);
        if (activeVolumeMeterIndex === 1) {
          setVolumeMeterColor('bg-[#EE3529]');
        } else if (activeVolumeMeterIndex === 2) {
          setVolumeMeterColor('bg-[#FBB707]');
        } else {
          setVolumeMeterColor('bg-[#2CFF67]');
        }
      }
      timerIdRef.current = setTimeout(render, 1000 / fps);
    };
    render();
    return () => {
      setVolumeMeterIndex(0);
      if (timerIdRef.current) {
        clearTimeout(timerIdRef.current);
      }
    };
  }, [fps, stream]);

  return (
    <div className={`flex flex-row justify-between gap-1 ${className}`}>
      {[...Array(VolumeMeterSegments)].map((_, i) => {
        return (
          <div
            key={i}
            className={`${segmentSize} ${
              volumeMeterIndex > i ? volumeMeterColor : 'bg-secondary'
            }`}
          />
        );
      })}
    </div>
  );
};
