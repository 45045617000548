import pluralize from 'pluralize';
import { useEffect, useMemo, useState } from 'react';

import { type PuzzleBlock, PuzzleBlockGameSessionStatus } from '@lp-lib/game';

import type { TeamId } from '../../../../types';
import { assertExhaustive } from '../../../../utils/common';
import { TimeUtils } from '../../../../utils/time';
import { DoubleRightArrow } from '../../../icons/Arrows';
import { PlayIcon } from '../../../icons/PlayIcon';
import { RefreshIcon } from '../../../icons/RefreshIcon';
import { FilledSquareIcon } from '../../../icons/SquareIcon';
import { TimerIcon } from '../../../icons/TimerIcon';
import {
  useDetailScores,
  useGameSessionLocalTimer,
  useGameSessionStatus,
  useIsEndedBlock,
} from '../../hooks';
import {
  countdownV2,
  type EditPointData,
  next,
  present,
  triggerBlockTitleAnim,
} from '../../store';
import {
  BlockControllerActionButton,
  BlockControllerActionNone,
  BlockControllerActionTimeRemaining,
  useBlockControllerBlockTitlePlaying,
} from '../Common/Controller';
import {
  type MediaPlayback,
  MediaPreviewer,
  useControllerMediaPlayback,
  useControllerMediaPlayText,
} from '../Common/Controller/ControllerMedia';
import {
  ResultView,
  SubmissionsActionBox,
} from '../Common/Controller/ControllerSubmissions';
import { type ControllerProps } from '../Common/Controller/types';
import {
  ControllerLayout,
  DecreasingPointsBadge,
} from '../Common/Controller/Utilities';
import { useRankedTeamScores, useStableBlock } from '../Common/hooks';
import { BlockKnifeUtils } from '../Shared';
import { useGameProgressSummary } from './PuzzleProvider';
import { longDuration, PuzzleUtils } from './utils';

type SharedProps = ControllerProps<PuzzleBlock>;
type StageProps = SharedProps & {
  playback: MediaPlayback;
};

export function PuzzleBlockTeamProgressSummary(props: {
  block: PuzzleBlock;
  teamId: TeamId;
}) {
  const summary = useGameProgressSummary();
  const [correct, total] = useMemo(() => {
    const gridSize = props.block.fields.gridSize;
    return [
      summary[props.teamId]?.numCorrectPieces ?? 0,
      gridSize.numRows * gridSize.numCols,
    ];
  }, [props.block.fields.gridSize, props.teamId, summary]);

  return (
    <>
      Progress: {correct}/{total} correct
    </>
  );
}

function GameProgress(
  props: StageProps & { revealResults?: boolean; hideProgressDetail?: boolean }
): JSX.Element | null {
  const { selectedBlock: block, setEditPointsData, hideProgressDetail } = props;
  const teamDetailScoreMap = useDetailScores(props.selectedBlock.id);
  const teamScores = useRankedTeamScores('teamName', teamDetailScoreMap);
  const summary = useGameProgressSummary();
  const numPieces =
    block.fields.gridSize.numRows * block.fields.gridSize.numCols;

  return (
    <div className='relative w-full h-full flex flex-col justify-start items-center'>
      <div className='w-full h-10 px-4 py-1 leading-4 bg-black text-white text-2xs font-medium flex items-center'>
        {numPieces} Pieces - Max{' '}
        {block.fields.pointsPerCorrectPiece * numPieces} Points
      </div>
      <div className='w-full mb-2.5 pt-1 px-2.5 max-h-64 scrollbar-hide overflow-y-scroll'>
        {teamScores.map((score) => {
          const editPointData: EditPointData = {
            teamName: score.team.name,
            teamId: score.team.id,
            submitterUid: '',
            defaultPoints: score.currentScore,
          };
          const numCorrectPieces =
            summary[score.team.id]?.numCorrectPieces ?? 0;
          return (
            <SubmissionsActionBox
              key={score.team.id}
              teamId={score.team.id}
              view={props.revealResults ? 'result' : undefined}
              editPointData={editPointData}
              setEditPointsData={setEditPointsData}
            >
              <div className='w-4/5 h-full pl-2'>
                <p
                  className={`font-bold ${
                    hideProgressDetail
                      ? 'h-full flex items-center'
                      : 'h-1/3 pt-1'
                  }`}
                >
                  {score.team.name}
                </p>
                {!hideProgressDetail && (
                  <div
                    className={`w-full h-2/3 text-xs font-medium ${
                      numCorrectPieces > 0
                        ? 'text-icon-gray'
                        : 'text-[rgba(255,255,255,0.15)]'
                    } flex flex-col justify-center`}
                  >
                    <p className='w-full line-clamp-2 hyphens-auto'>
                      {numCorrectPieces} {pluralize('piece', numCorrectPieces)}{' '}
                      correct
                    </p>
                  </div>
                )}
              </div>
              <div className='w-1/5 h-full bg-secondary border-l border-secondary flex flex-col justify-center items-center'>
                {props.revealResults ? (
                  <ResultView
                    totalScore={score.totalScore}
                    previewPoints={score.currentScore}
                  />
                ) : (
                  <div className='w-8 h-7 text-white font-bold text-2xs text-center flex flex-col justify-center'>
                    <span>+{score.currentScore}</span>
                    <span className='text-3xs'>Points</span>
                  </div>
                )}
              </div>
            </SubmissionsActionBox>
          );
        })}
      </div>
    </div>
  );
}

function Badges(props: {
  block: PuzzleBlock;
  disabled?: boolean;
}): JSX.Element {
  return (
    <div className='w-full px-2.5 flex flex-row items-center justify-start self-start'>
      <DecreasingPointsBadge
        decreasingPointsTimer={props.block.fields.decreasingPointsTimer}
        disabled={props.disabled}
      />
    </div>
  );
}

function Loaded(props: StageProps): JSX.Element {
  const { selectedBlock: block, playback } = props;

  const { switchMedia, reset: resetPlayback } = playback.api;

  useEffect(() => {
    resetPlayback();
    const media = BlockKnifeUtils.Media(block, 'introMedia');
    switchMedia(media);
  }, [block, switchMedia, resetPlayback]);

  const onPresent = async () => {
    await triggerBlockTitleAnim(block);
    await present(block);
  };

  const actionComponent = useBlockControllerBlockTitlePlaying(block) ?? (
    <BlockControllerActionButton onClick={onPresent} icon={PlayIcon}>
      {playback.preview ? 'Present Block' : 'Present Game'}
    </BlockControllerActionButton>
  );
  return (
    <ControllerLayout action={actionComponent}>
      <div className='w-full flex flex-col'>
        <Badges block={block} />
        <MediaPreviewer
          playback={playback}
          alt={block.type}
          text={block.fields.text}
        />
      </div>
    </ControllerLayout>
  );
}

function Intro(props: StageProps): JSX.Element | null {
  const { selectedBlock: block, playback } = props;
  const mediaActionText = useControllerMediaPlayText(playback);
  const [presentIntro, setPresentIntro] = useState(!!playback.preview);
  const { play: playMedia } = playback.api;

  useEffect(() => {
    if (!playback.isVideoPlayed) return;
    setPresentIntro(false);
  }, [playback.isVideoPlayed]);

  useEffect(() => {
    playMedia();
  }, [playMedia]);

  useEffect(() => {
    if (presentIntro) return;
    next();
  }, [presentIntro]);

  if (presentIntro) {
    return (
      <ControllerLayout
        action={
          <BlockControllerActionNone icon={PlayIcon}>
            {mediaActionText}
          </BlockControllerActionNone>
        }
      >
        <div className='w-full flex flex-col'>
          <Badges block={block} disabled />
          <MediaPreviewer
            playback={playback}
            alt={block.type}
            text={block.fields.text}
          />
        </div>
      </ControllerLayout>
    );
  }
  return null;
}

function GameInit(props: StageProps): JSX.Element {
  const { selectedBlock: block, playback } = props;

  const { switchMedia } = playback.api;
  const [processing, setProcessing] = useState(false);
  const gameTimeSec = useGameSessionLocalTimer();
  const durationFormattedMMSS = block.fields.gameTimeSec >= longDuration;

  useEffect(() => {
    switchMedia(PuzzleUtils.GetBackgroundMedia(block));
  }, [switchMedia, block]);

  const onStartGame = async () => {
    if (gameTimeSec === 0) return;
    setProcessing(true);
    await countdownV2({
      debug: 'PuzzleBlockGameControl',
      startTimeWorker: true,
      flushCountingStatus: true,
      triggerTimesup: true,
    });
  };

  return (
    <ControllerLayout
      action={
        <BlockControllerActionButton
          onClick={onStartGame}
          icon={TimerIcon}
          disable={processing || gameTimeSec === 0}
        >
          {`Start Game Timer (${
            durationFormattedMMSS
              ? TimeUtils.DurationFormattedHHMMSS((gameTimeSec ?? 0) * 1000)
              : `${gameTimeSec}s`
          })`}
        </BlockControllerActionButton>
      }
    >
      <div className='w-full flex flex-col'>
        <Badges block={block} disabled />
        <MediaPreviewer
          playback={playback}
          alt={block.type}
          text={block.fields.text}
        />
      </div>
    </ControllerLayout>
  );
}

function GameStart(props: StageProps): JSX.Element {
  const { selectedBlock: block } = props;
  const time = useGameSessionLocalTimer();
  const durationFormattedMMSS = block.fields.gameTimeSec >= longDuration;

  return (
    <ControllerLayout
      action={
        <BlockControllerActionTimeRemaining
          remainingSec={time}
          durationFormattedMMSS={durationFormattedMMSS}
        />
      }
    >
      <GameProgress {...props} />
    </ControllerLayout>
  );
}

function GameEnd(props: StageProps): JSX.Element {
  const [processing, setProcessing] = useState(false);

  const onRevealResult = async () => {
    setProcessing(true);
    await next();
  };
  return (
    <ControllerLayout
      action={
        <BlockControllerActionButton
          onClick={onRevealResult}
          icon={DoubleRightArrow}
          disable={processing}
        >
          Reveal Results
        </BlockControllerActionButton>
      }
    >
      <GameProgress {...props} revealResults />
    </ControllerLayout>
  );
}

function Outro(props: StageProps): JSX.Element | null {
  const { selectedBlock: block, playback } = props;
  const mediaActionText = useControllerMediaPlayText(playback);
  const { switchMedia, play: playMedia } = playback.api;
  useEffect(() => {
    if (!playback.isVideoPlayed) return;
    next();
  }, [playback.isVideoPlayed]);

  useEffect(() => {
    const media = BlockKnifeUtils.Media(block, 'outroMedia');
    if (media) {
      switchMedia(media, () => playMedia());
    } else {
      next();
    }
  }, [block, switchMedia, playMedia]);

  if (playback.preview) {
    return (
      <ControllerLayout
        action={
          <BlockControllerActionNone icon={PlayIcon}>
            {mediaActionText}
          </BlockControllerActionNone>
        }
      >
        <GameProgress {...props} revealResults />
      </ControllerLayout>
    );
  }
  return null;
}

function Results(props: StageProps): JSX.Element {
  const onShowScoreboard = async () => {
    await next();
  };

  return (
    <ControllerLayout
      action={
        <>
          <BlockControllerActionButton
            onClick={onShowScoreboard}
            isSecondary={true}
          >
            Show Scoreboard
          </BlockControllerActionButton>
          <BlockControllerActionButton
            onClick={props.onEndBlock}
            icon={FilledSquareIcon}
          >
            End Block Sequence
          </BlockControllerActionButton>
        </>
      }
    >
      <GameProgress {...props} revealResults />
    </ControllerLayout>
  );
}

function Scoreboard(props: StageProps): JSX.Element {
  return (
    <ControllerLayout
      action={
        <BlockControllerActionButton
          onClick={props.onEndBlock}
          icon={FilledSquareIcon}
        >
          End Block Sequence
        </BlockControllerActionButton>
      }
    >
      <GameProgress {...props} revealResults />
    </ControllerLayout>
  );
}

function Ended(props: StageProps): JSX.Element {
  return (
    <ControllerLayout
      action={
        <BlockControllerActionButton
          onClick={() => props.setShowResetConfirmation(true)}
          icon={RefreshIcon}
          isDelete={true}
        >
          Reset Completed Block
        </BlockControllerActionButton>
      }
    >
      <GameProgress {...props} hideProgressDetail revealResults />
    </ControllerLayout>
  );
}

export function PuzzleBlockController(
  props: ControllerProps<PuzzleBlock>
): JSX.Element | null {
  const { selectedBlockIndex } = props;
  const block = useStableBlock(props.selectedBlock);
  const isEndedBlock = useIsEndedBlock(block.id);
  const gameSessionStatus =
    useGameSessionStatus<PuzzleBlockGameSessionStatus>();
  const playback = useControllerMediaPlayback();

  if (selectedBlockIndex === null) return null;

  if (isEndedBlock) {
    return <Ended {...props} playback={playback} />;
  }

  switch (gameSessionStatus) {
    case PuzzleBlockGameSessionStatus.LOADED:
      return <Loaded {...props} playback={playback} />;
    case PuzzleBlockGameSessionStatus.INTRO:
      return <Intro {...props} playback={playback} />;
    case PuzzleBlockGameSessionStatus.GAME_INIT:
      return <GameInit {...props} playback={playback} />;
    case PuzzleBlockGameSessionStatus.GAME_START:
      return <GameStart {...props} playback={playback} />;
    case PuzzleBlockGameSessionStatus.GAME_END:
      return <GameEnd {...props} playback={playback} />;
    case PuzzleBlockGameSessionStatus.OUTRO:
      return <Outro {...props} playback={playback} />;
    case PuzzleBlockGameSessionStatus.RESULTS:
      return <Results {...props} playback={playback} />;
    case PuzzleBlockGameSessionStatus.SCOREBOARD:
      return <Scoreboard {...props} playback={playback} />;
    case PuzzleBlockGameSessionStatus.END:
    case null:
    case undefined:
      break;
    default:
      assertExhaustive(gameSessionStatus);
      break;
  }

  return null;
}
