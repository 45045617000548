import React, { type ReactNode, useContext, useMemo } from 'react';

import { type Organization } from '../../types';

type OrgFeatureContext = {
  org: Organization;
  routePrefix: string;
  adminMode?: boolean;
};

const Context = React.createContext<OrgFeatureContext | null>(null);

export function useOrgFeatureContext(): OrgFeatureContext {
  const ctx = useContext(Context);
  if (!ctx) {
    throw new Error('OrgFeatureContext is not in the tree!');
  }
  return ctx;
}

export function OrgFeatureProvider(
  props: OrgFeatureContext & {
    children?: ReactNode;
  }
): JSX.Element {
  const ctxValue = useMemo(
    () => ({
      org: props.org,
      routePrefix: props.routePrefix,
      adminMode: props.adminMode,
    }),
    [props.org, props.routePrefix, props.adminMode]
  );
  return <Context.Provider value={ctxValue}>{props.children}</Context.Provider>;
}
