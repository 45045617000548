const WarningIcon = ({ className }: { className?: string }): JSX.Element => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      className={className || 'w-3.5 h-3.5 fill-current'}
      viewBox='0 0 22 22'
    >
      <path
        fillRule='evenodd'
        d='M.167 11C.167 5.02 5.009.167 10.989.167 16.98.167 21.834 5.02 21.834 11S16.98 21.833 10.989 21.833C5.01 21.833.168 16.98.168 11zm11.917-4.333c0-.596-.488-1.084-1.084-1.084-.595 0-1.083.488-1.083 1.083V11c0 .596.488 1.083 1.083 1.083.596 0 1.084-.487 1.084-1.083V6.667zm-1.084 13A8.664 8.664 0 012.334 11 8.664 8.664 0 0111 2.333 8.664 8.664 0 0119.667 11 8.664 8.664 0 0111 19.666zM9.917 14.25v2.166h2.167V14.25H9.917z'
        clipRule='evenodd'
      ></path>
    </svg>
  );
};

export { WarningIcon };
