import {
  type Control,
  Controller,
  type FieldPath,
  type FieldPathValue,
  type FieldValues,
} from 'react-hook-form';
import { type PathValue } from 'react-hook-form';
import Select, { type SingleValue } from 'react-select';

import { type Option } from '../common/Utilities';

type SelectControllerProps<
  T extends FieldValues,
  P extends FieldPath<T>,
  O // Option type
> = {
  control: Control<T>;
  name: P;
  options: Option<O>[];
  toOption: (value: PathValue<T, P>) => Option<O> | null;
  fromOption: (opt: Option<O> | null) => FieldPathValue<T, P>;
  disabled?: boolean;
};

export function SelectController<
  T extends FieldValues,
  P extends FieldPath<T>,
  O // Option type
>(props: SelectControllerProps<T, P, O>): JSX.Element {
  return (
    <Controller
      control={props.control}
      name={props.name}
      render={({ field }) => {
        const { value, onChange } = field;
        const selected = props.toOption(value);

        const handleChange = (newValue: SingleValue<Option<O>>) => {
          onChange(props.fromOption(newValue));
        };

        return (
          <Select<Option<O>>
            options={props.options}
            isSearchable={false}
            className='select-box flex-grow'
            classNamePrefix='select-box'
            value={selected}
            onChange={handleChange}
            isDisabled={props.disabled}
          />
        );
      }}
    />
  );
}
