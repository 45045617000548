import { memo, useEffect, useRef } from 'react';
import { usePrevious } from 'react-use';

import { ConnectionStatus } from '@lp-lib/shared-schema';

import { useLiteModeEnabled } from '../../../components/LiteMode';
import {
  Indicator,
  OnStage,
  Placeholder,
  Reconnecting,
  VideoSourceCrowdFrames,
} from '../../../components/Participant';
import {
  useParticipant,
  useParticipantFlags,
} from '../../../components/Player';
import { useIsStreamSessionAlive } from '../../../components/Session';
import { InvitedNotice as StageInvitedNotice } from '../../../components/Stage';
import { useTeam, useTeamColor } from '../../../components/TeamAPI/TeamV1';
import { useTownhallConfig } from '../../../components/Townhall';
import { useDebugStream } from '../../../components/WebRTC/RTCServiceContext';
import { useFeatureQueryParam } from '../../../hooks/useFeatureQueryParam';
import { useForceUpdate } from '../../../hooks/useForceUpdate';
import { type IRTCService, TrackState } from '../../../services/webrtc';
import { isReconnecting } from '../../../store/utils';
import { isStaff, type Participant } from '../../../types/user';
import { nullOrUndefined } from '../../../utils/common';
import { Badge } from './Badge';
import { PlayerInfoOverlay } from './PlayerInfoOverlay';
import { useSelectStreamState } from './RemoteStreamStateProvider';
import {
  type AudioSubscribeStrategy,
  type VideoSubscribeStrategy,
} from './types';

interface RemoteStreamViewProps {
  id: string;
  className: string;
  widthPx: number | undefined;
  heightPx: number | undefined;
  borderRadiusPx: number | undefined;
  targetMemberId: Participant['clientId'];
  currentMemberId: Participant['clientId'];
  teamMemberCount: number;
  rtcService: IRTCService;
  isRTCServiceSynced: boolean;
  miniMode: boolean;
  strategy?: {
    video?: VideoSubscribeStrategy;
    audio?: AudioSubscribeStrategy;
  };
  showLoadingIndicator?: boolean;
}

export const RemoteStreamView = memo(function RemoteStreamView({
  id,
  className,
  widthPx,
  heightPx,
  borderRadiusPx,
  targetMemberId,
  currentMemberId,
  rtcService,
  teamMemberCount,
  isRTCServiceSynced,
  miniMode,
  strategy,
}: RemoteStreamViewProps): JSX.Element | null {
  const me = useParticipant(currentMemberId);
  const participant = useParticipant(targetMemberId);
  const memberId = targetMemberId;
  const meFlags = useParticipantFlags(me?.clientId);
  const flags = useParticipantFlags(participant?.clientId);
  const streamState = useSelectStreamState(memberId);
  const ref = useRef<HTMLDivElement>(null);
  useDebugStream(ref.current, memberId, rtcService);
  const videoRef = useRef<HTMLDivElement>(null);
  const prevTrackState = usePrevious(streamState?.trackState);
  const liteMode = useLiteModeEnabled();
  const townhallConfig = useTownhallConfig();
  const remoteVideoStreamsFeatureEnabled = useFeatureQueryParam(
    'team-view-remote-video-streams'
  );
  const remoteVideoStreamsEnabled =
    remoteVideoStreamsFeatureEnabled && !liteMode;
  const team = useTeam(participant?.teamId);

  const townhallCrowdMode =
    !!townhallConfig?.enabled && team?.townhallMode === 'crowd';
  const videoAutoSubscribe = strategy?.video === 'auto';
  const teamColor = useTeamColor(participant?.teamId);
  const forceUpdate = useForceUpdate();
  const isStreamSessionAlive = useIsStreamSessionAlive();

  useEffect(() => {
    if (!isRTCServiceSynced) return;
    if (streamState?.audioSubscribed) {
      rtcService.playAudio(memberId);
    } else {
      rtcService.stopAudio(memberId);
    }
  }, [isRTCServiceSynced, memberId, rtcService, streamState?.audioSubscribed]);

  useEffect(() => {
    if (
      !videoRef.current ||
      !isRTCServiceSynced ||
      !remoteVideoStreamsEnabled ||
      nullOrUndefined(streamState?.trackState)
    )
      return;
    if (streamState?.videoSubscribed) {
      rtcService.playVideo(memberId, videoRef.current);
    } else {
      rtcService.stopVideo(memberId);
    }
  }, [
    isRTCServiceSynced,
    memberId,
    remoteVideoStreamsEnabled,
    rtcService,
    streamState?.trackState,
    streamState?.videoSubscribed,
  ]);

  // handle video auto recovery
  useEffect(() => {
    if (
      !streamState?.videoSubscribed ||
      !videoRef.current ||
      !isRTCServiceSynced ||
      !remoteVideoStreamsEnabled
    )
      return;
    if (
      prevTrackState === TrackState.Disconnected &&
      streamState.trackState === TrackState.Live
    ) {
      rtcService.playVideo(memberId, videoRef.current);
    }
  }, [
    memberId,
    rtcService,
    streamState?.videoSubscribed,
    streamState?.trackState,
    prevTrackState,
    isRTCServiceSynced,
    remoteVideoStreamsEnabled,
  ]);

  useEffect(() => {
    return () => {
      rtcService.stopAudio(memberId);
      rtcService.stopVideo(memberId);
    };
  }, [rtcService, memberId]);

  if (!participant || !flags) return null;

  const isParticipantReconnecting =
    participant.status === ConnectionStatus.Disconnected &&
    isReconnecting(participant);

  const showVideo =
    flags.video &&
    flags.hasCamera !== false &&
    !flags.onStage &&
    !isParticipantReconnecting &&
    streamState?.trackState === TrackState.Live;

  const disableBadge = miniMode || townhallCrowdMode;

  return (
    <div className={`relative group-1`}>
      <div
        className={`relative flex items-center justify-center overflow-hidden ${className}`}
        style={{
          borderColor: teamColor,
          width: widthPx,
          height: heightPx,
          borderRadius: borderRadiusPx,
        }}
        ref={ref}
        onTransitionEnd={forceUpdate}
      >
        <Indicator
          clientId={participant.clientId}
          volume={streamState?.volume || 0}
          local={false}
          miniMode={miniMode}
        />

        {isParticipantReconnecting && (
          <Reconnecting participant={participant} showDetail={!miniMode} />
        )}

        <div
          id={id}
          ref={videoRef}
          className={`w-full h-full absolute z-10 top-0 left-0 ${
            showVideo ? 'block' : 'hidden'
          }`}
        />

        <VideoSourceCrowdFrames
          clientId={participant.clientId}
          visible={!videoAutoSubscribe || !showVideo}
        />

        {/* Shows a small version of the stage invite animation above this stream. */}
        <StageInvitedNotice
          className='w-full h-full'
          zLayer='z-15'
          clientId={participant.clientId}
        />

        <OnStage
          zIndex='z-10'
          onStage={flags.onStage}
          showLabel={!miniMode}
          showDimMask={meFlags?.onStage}
        />

        <Placeholder
          clientId={participant.clientId}
          showLiteMode={!miniMode}
          noIndicators={miniMode}
        />

        {!miniMode && (
          // username hovers above the video stream, but below the indicators and menu.
          <PlayerInfoOverlay
            clientId={participant.clientId}
            teamId={participant.teamId}
            showTeamName={townhallCrowdMode && isStreamSessionAlive}
            hideMarkAsAway={flags.onStage}
          />
        )}
      </div>
      {!disableBadge && (
        <Badge
          participantClientId={participant.clientId}
          participantId={participant.id}
          participantTeamId={participant.teamId}
          teamMemberCount={teamMemberCount}
        />
      )}
      {townhallCrowdMode && townhallConfig.debug && (
        <TownhallDebug showVideo={!!showVideo} />
      )}
      {isStaff(me) && isStaff(participant) && flags.spectator && (
        <div className='absolute bottom-0 left-1/2 transform -translate-x-1/2 text-3xs bg-dark-gray rounded text-red-006 px-1 py-0.5'>
          Spectator
        </div>
      )}
    </div>
  );
});

function TownhallDebug(props: { showVideo: boolean }): JSX.Element {
  return (
    <div className='absolute -right-0 -top-0 text-white z-10 bg-black bg-opacity-25 w-4 h-4 flex items-center justify-center text-xs rounded'>
      {props.showVideo ? 'V' : 'C'}
    </div>
  );
}
