import { useEffect, useLayoutEffect, useMemo } from 'react';
import { match } from 'ts-pattern';
import { proxy } from 'valtio';

import { EnumsUserVesProfileKey } from '@lp-lib/api-service-client/public';
import { asFBReference } from '@lp-lib/firebase-typesafe';

import {
  getFeatureQueryParamArray,
  getFeatureQueryParamNumber,
} from '../../hooks/useFeatureQueryParam';
import { useTaskQueue } from '../../hooks/useTaskQueue';
import { useVenueMode } from '../../hooks/useVenueMode';
import { type useWindowDimensions } from '../../hooks/useWindowDimensions';
import { getLogger } from '../../logger/logger';
import { VenueMode } from '../../types';
import { sleep } from '../../utils/common';
import { createProvider } from '../../utils/createProvider';
import {
  markSnapshottable,
  useSnapshot,
  type ValtioSnapshottable,
  ValtioUtils,
} from '../../utils/valtio';
import { useDeviceAPI } from '../Device';
import {
  type IVideoEffectsMixer,
  useMaybeVideoEffectsMixer,
} from '../Device/video-stream-mixer';
import { type FirebaseService } from '../Firebase';
import {
  type FBMsgPassStorage,
  recv,
  send,
} from '../Firebase/FirebaseMessagePassing';
import { useOndGameState } from '../Game/hooks';
import { getSynchronousRawAnchorRect } from '../LayoutAnchors/LayoutAnchors';
import { useAmICohost } from '../Player';
import { useVenueId } from '../Venue';
import { type VideoEffectsSettingsStorage } from '../VideoEffectsSettings/types';
import { type CohostNamedPosition, type CohostVisibility } from './types';
import {
  cohostVideoEffectsSettingsFor,
  getCohostPositions,
  isCohostFullscreenPosition,
} from './utils';

type Position = {
  width: number;
  height: number;
  left: number;
  top: number;
  borderRadius: number;
  visible: boolean;
  zIndex: number;
  fullscreen: boolean;
};

type Config = {
  mixer: boolean;
  namedPosition: CohostNamedPosition;
  visibility: CohostVisibility;
};

type State = {
  position: Position;
  config: Config;
};

type ChangePositionCmd = {
  position: CohostNamedPosition;
};

type Dependencies = {
  mixer: IVideoEffectsMixer | null;
  vesStore: VideoEffectsSettingsStorage | null;
};

interface ICohostPositionManager {
  setMixerEnabled: (val: boolean) => Promise<void>;
  setNamedPosition: (
    position: CohostNamedPosition,
    ack?: boolean
  ) => Promise<void>;
  setNamedPositionOr: (
    position: CohostNamedPosition,
    or: CohostNamedPosition | null,
    ack?: boolean
  ) => Promise<void>;
  setVisibility: (val: CohostVisibility) => Promise<void>;
  update(windims: ReturnType<typeof useWindowDimensions>): void;
  watch(
    mixer: IVideoEffectsMixer | null,
    vesStore: VideoEffectsSettingsStorage
  ): void;
}

class CohostPositionManager {
  private _state = markSnapshottable(proxy<State>(this.initialState()));
  private deps: Dependencies | null = null;
  constructor(
    venueId: string,
    svc: FirebaseService,
    readonly townhallCohostPct = getFeatureQueryParamNumber(
      'townhall-cohost-pct'
    ),
    readonly fadeMs = getFeatureQueryParamNumber('cohost-fade-ms'),
    private configRef = svc.prefixedSafeRef<Config>(`cohost/${venueId}/config`),
    private changePositionCmdRef = asFBReference<FBMsgPassStorage>(
      svc.prefixedSafeRef(`cohost/${venueId}/position`)
    ),
    readonly log = getLogger().scoped('CohostPositionManager')
  ) {}

  async init() {
    const config = (await this.configRef.get()).val();
    if (config) {
      ValtioUtils.update(this._state.config, config);
      await this.maybeUpdateVesSettings(config.namedPosition);
    }
    this.configRef.on('value', (snapshot) => {
      const config = snapshot.val();
      if (config) ValtioUtils.update(this.state.config, config);
    });
  }

  deinit(): void {
    this.configRef.off();
    ValtioUtils.reset(this.state, this.initialState());
  }

  watch(
    mixer: IVideoEffectsMixer | null,
    vesStore: VideoEffectsSettingsStorage
  ) {
    if (this.deps) {
      this.deps.mixer = mixer;
      this.deps.vesStore = vesStore;
    } else {
      this.deps = { mixer, vesStore };
    }
    return recv(this.changePositionCmdRef, async (msg: ChangePositionCmd) => {
      await this.maybeUpdateVesSettings(msg.position);
      await this.configRef.update({
        namedPosition: msg.position,
      });
    });
  }

  private async maybeUpdateVesSettings(position: CohostNamedPosition) {
    if (!this.deps?.mixer || !this.deps?.vesStore) return;
    const vesStore = this.deps.vesStore;
    const vesSettings = match(position)
      .with('fullscreen-solo', () =>
        vesStore.getSettings(EnumsUserVesProfileKey.UserVesProfileKeyCohostSolo)
      )
      .with('fullscreen-interview', () =>
        vesStore.getSettings(
          EnumsUserVesProfileKey.UserVesProfileKeyCohostInterview
        )
      )
      .otherwise(() => cohostVideoEffectsSettingsFor(position));
    await this.deps.mixer.updateVideoEffectsSettings(vesSettings);
    await sleep(this.fadeMs);
  }

  get state(): Readonly<ValtioSnapshottable<State>> {
    return this._state;
  }

  async setNamedPosition(position: CohostNamedPosition, ack = true) {
    const msg: ChangePositionCmd = { position };
    await send(this.changePositionCmdRef, msg, {
      ack,
      cleanupAfterAck: true,
    });
  }

  async setVisibility(val: CohostVisibility) {
    await this.configRef.update({ visibility: val });
  }

  async setNamedPositionOr(
    position: CohostNamedPosition,
    or: CohostNamedPosition | null,
    ack = true
  ) {
    if (this.state.config.mixer) {
      await this.setNamedPosition(position, ack);
    } else {
      if (!or) return;
      await this.setNamedPosition(or, ack);
    }
  }

  async setMixerEnabled(enabled: boolean) {
    await this.configRef.update({ mixer: enabled });
  }

  update(windims: ReturnType<typeof useWindowDimensions>) {
    const pos = this.state.config.namedPosition;
    if (isCohostFullscreenPosition(pos)) {
      // we don't use width & height px values in fullscreen, but it's no harm
      // to set the right value.
      this._state.position.width = windims.width;
      this._state.position.height = windims.height;
      this._state.position.left = 0;
      this._state.position.top = 0;
      this._state.position.borderRadius = 0;
      this._state.position.visible = true;
      this._state.position.zIndex = 0;
      this._state.position.fullscreen = true;
      return;
    }

    const isCohostPanelPresent =
      getFeatureQueryParamArray('cohost-panel') !== 'disabled';

    // We have to get this manually because we are just using it for position,
    // not resizing. `react-use-measure` seems to have a bug where if an element
    // simply moves due to being pushed around by a sibling the hook will not be
    // updated. Note that this is the rectangle formed by the space townhall
    // actually occupies. It will always be smaller than `safezoneWidthPx`.
    const latestCohostSafeZone = getSynchronousRawAnchorRect(
      // when the cohost panel is present, we don't allow the cohost to overlap the safespace.
      isCohostPanelPresent
        ? 'lobby-top-spacing-anchor'
        : 'lobby-cohost-spacing-anchor'
    );

    if (!latestCohostSafeZone || latestCohostSafeZone.width === 0) return;

    // This is an integer, but we need a factor. Divide by 100 to convert.
    const cohostHeightPct = this.townhallCohostPct / 100;

    // We do not measure where the logo is, we just assume there is a _this many
    // pixels_ dead zone so the cohost does not overlap.
    const logoDeadZonePx = 65;

    // We provide a small amount of "extra" gap so that the cohost is not right
    // up against the stream view. This could be reduced to zero, but it will
    // probably look best as a multiple of 4.
    const cohostExtra = 16;

    // Allocate a specific percentage of the entire window for the cohost view.
    // It's best if this matches the corresponding townhall value, but it can be
    // larger. It just means there is a chance of overlapping the Game Console /
    // Lobby at smaller screen sizes.
    const availableHeight = windims.height * cohostHeightPct;

    let cohost = 0;
    let cohostLeft = 0;
    let cohostTop = 0;

    if (pos !== 'center') {
      // use all the hard-coded values above to put the cohost off to the side
      cohost = Math.min(
        latestCohostSafeZone.x - cohostExtra - logoDeadZonePx,
        availableHeight
      );
      cohostLeft = logoDeadZonePx;
      cohostTop = 4;
    } else {
      // Ignore the hard-coded values above and place the cohost large and in the
      // center of the screen.
      cohost = windims.height * 0.5;
      cohostLeft = windims.width * 0.5 - cohost * 0.5;
      cohostTop = windims.height * 0.5 - cohost * 0.5;
    }
    this._state.position.width = cohost;
    this._state.position.height = cohost;
    this._state.position.left = cohostLeft;
    this._state.position.top = cohostTop;
    this._state.position.borderRadius = 0.12 * cohost;
    this._state.position.visible = !!cohost && !!cohostLeft;
    this._state.position.zIndex = 30;
    this._state.position.fullscreen = false;
  }

  private initialState(): State {
    return {
      position: {
        width: 0,
        height: 0,
        left: 0,
        top: 0,
        borderRadius: 0,
        visible: false,
        zIndex: 0,
        fullscreen: false,
      },
      config: {
        mixer: false,
        namedPosition: 'default',
        visibility: 'placeholder',
      },
    };
  }
}

const { Provider, useCreatedContext } = createProvider<CohostPositionManager>(
  'CohostPositionManager'
);

export function useCohostPositionManager(): ICohostPositionManager {
  return useCreatedContext();
}

function useCohostState() {
  const manager = useCreatedContext();
  return useSnapshot(manager.state);
}

export function useCohostNamedPosition(): CohostNamedPosition {
  return useCohostState().config.namedPosition;
}

export function useCohostVisibility(): CohostVisibility {
  return useCohostState().config.visibility;
}

export function useCohostPosition() {
  return useCohostState().position;
}

export function useCohostMixerEnabled() {
  return useCohostState().config.mixer;
}

export function useCohostAvailableNamedPositions() {
  const mixerEnabled = useCohostMixerEnabled();
  return useMemo(() => getCohostPositions(mixerEnabled), [mixerEnabled]);
}

export function CohostSynchronizeMixerStatus(props: { mixerEnabled: boolean }) {
  const { mixerEnabled } = props;
  const isCohost = useAmICohost();
  const copman = useCohostPositionManager();
  useLayoutEffect(() => {
    if (!isCohost) return;
    copman.setMixerEnabled(mixerEnabled);
  }, [copman, mixerEnabled, isCohost]);
  return null;
}

export function CohostSynchronizePosition(props: {
  ready: boolean;
  vesStore: VideoEffectsSettingsStorage;
}) {
  const { ready, vesStore } = props;
  const deviceAPI = useDeviceAPI();
  const vbgMixer = useMaybeVideoEffectsMixer(deviceAPI.mixer);
  const copman = useCohostPositionManager();
  const isCohost = useAmICohost();
  useEffect(() => {
    if (!ready || !isCohost) return;
    return copman.watch(vbgMixer, vesStore);
  }, [copman, isCohost, ready, vbgMixer, vesStore]);
  return null;
}

// note: this is only used to synchronize the cohost visibility for the lobby and preparing state.
export function CohostSynchronizeVisibility(props: { ready: boolean }) {
  const { ready } = props;
  const copman = useCohostPositionManager();
  const isCohost = useAmICohost();
  const venueMode = useVenueMode();
  const ondGameState = useOndGameState();

  useEffect(() => {
    if (!ready || !isCohost) return;

    if (
      venueMode === VenueMode.Lobby ||
      ondGameState === null ||
      ondGameState === 'preparing'
    ) {
      copman.setVisibility('placeholder');
      return;
    }
  }, [copman, isCohost, ready, venueMode, ondGameState]);
  return null;
}

export function CohostPositionManagerProvider(props: {
  ready: boolean;
  svc: FirebaseService;
  children?: React.ReactNode;
}) {
  const { ready, svc } = props;
  const venueId = useVenueId();
  const instance = useMemo(
    () => new CohostPositionManager(venueId, svc),
    [svc, venueId]
  );

  const { addTask } = useTaskQueue({
    shouldProcess: true,
  });

  useEffect(() => {
    if (!ready) return;
    addTask(async function init() {
      await instance.init();
    });
    return () => {
      addTask(async function deinit() {
        instance.deinit();
      });
    };
  }, [addTask, instance, ready]);

  return <Provider value={instance}>{props.children}</Provider>;
}
