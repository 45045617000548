import { getFeatureQueryParam } from '../../hooks/useFeatureQueryParam';
import { isStaff } from '../../types';
import { getStaticAssetPath } from '../../utils/assets';
import { useRemoveStaffFlag, useUser } from '../UserContext';

export function ModeratorToggle() {
  const user = useUser();
  const removeStaffFlag = useRemoveStaffFlag();

  // The participant instance is not created yet, use feature flag to detect.
  const isStaffUser = getFeatureQueryParam('ferris-wheel') || isStaff(user);

  if (!isStaffUser) return null;

  return (
    <div className='absolute -bottom-15 flex items-center justify-center gap-1.5 p-2 bg-black rounded-lg w-60 h-10'>
      <img
        src={getStaticAssetPath('images/help-headphone.png')}
        alt='moderator'
        className='w-8 h-8'
      />
      <div className='flex items-center justify-center gap-2'>
        <div className='text-red-006 font-bold '>Moderator Mode</div>
        <div
          className='text-secondary cursor-pointer text-2xs underline'
          onClick={removeStaffFlag}
        >
          (disable)
        </div>
      </div>
    </div>
  );
}
