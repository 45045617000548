export interface TimerIntervals {
  submission: NodeJS.Timeout | null;
}

export interface TimersWorker {
  timerIntervals: TimerIntervals;
  start: (
    key: keyof TimerIntervals,
    cb: (time: number) => Promise<void> | void,
    startTime: number,
    increment?: boolean
  ) => Promise<void>;
  clear: (key: keyof TimerIntervals) => void;
  isRunning: (key: keyof TimerIntervals) => boolean;
}

export const timersWorker: TimersWorker = {
  timerIntervals: {
    submission: null,
  },
  start: async function (key, cb, startTime, increment = false) {
    const interval = this.timerIntervals[key];
    if (interval) {
      clearInterval(interval);
      this.timerIntervals[key] = null;
    }

    if (startTime === 0 && increment) {
      cb && cb(0);
    }

    this.timerIntervals[key] = setInterval(() => {
      if (increment) {
        startTime++;
      } else {
        startTime--;
      }
      const interval = this.timerIntervals[key];
      if ((startTime === null || startTime < 0) && interval) {
        clearInterval(interval);
        return;
      }
      cb(startTime);
    }, 1000);
  },
  clear: function (key) {
    const interval = this.timerIntervals[key];
    if (interval) {
      clearInterval(interval);
      this.timerIntervals[key] = null;
    }
  },
  isRunning: function (key) {
    return !!this.timerIntervals[key];
  },
};
