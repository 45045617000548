import { Mutex, type MutexInterface } from 'async-mutex';
import { useEffect, useMemo } from 'react';
import { useLatest, usePrevious } from 'react-use';

import { type EnumsPlatform } from '@lp-lib/api-service-client/public';
import { BlockType, GameSessionUtil } from '@lp-lib/game';

import { type Configuration } from '../../config';
import { useInstance } from '../../hooks/useInstance';
import { useIsCoordinator } from '../../hooks/useMyInstance';
import { useQueryParam } from '../../hooks/useQueryParam';
import logger from '../../logger/logger';
import {
  apiService,
  type SessionSnapshotItem,
  type UpdateSessionSnapshotRequest,
} from '../../services/api-service';
import { SessionStatus } from '../../types/session';
import { nullOrUndefined } from '../../utils/common';
import {
  useGameSessionBlock,
  useGameSessionGamePackId,
  useGameSessionStatus,
  useIsGamePlayBlocksCompleted,
  useOndGameState,
} from '../Game/hooks';
import { getScoreboardData } from '../Game/store';
import { usePairingId } from '../Pairing';
import { useAmICohost } from '../Player';
import { useUser } from '../UserContext';
import { useVenueId, useVenueOwner } from '../Venue/VenueProvider';
import {
  useIsStreamSessionInited,
  useStreamSession,
  useStreamSessionId,
  useStreamSessionStatus,
} from './StreamSessionContext';

const log = logger.scoped('session-tracking');

function buildRemoteItems(): SessionSnapshotItem[] {
  return [
    {
      key: 'participants',
      remote: true,
    },
    {
      key: 'session',
      remote: true,
    },
    {
      key: 'teams',
      remote: true,
    },
    {
      key: 'game-session-core',
      remote: true,
    },
    {
      key: 'game-session',
      remote: true,
    },
    {
      key: 'game-log',
      remote: true,
    },
    {
      key: 'anu-tracking',
      remote: true,
    },
  ];
}

export class Uploader {
  private lastFailedRequest: UpdateSessionSnapshotRequest | null;
  private mutex: MutexInterface;
  private timerId: ReturnType<typeof setInterval> | null;
  constructor(private vid: string, private sid: string, private logger = log) {
    this.lastFailedRequest = null;
    this.mutex = new Mutex();
    this.timerId = null;
  }
  async upload(req: UpdateSessionSnapshotRequest): Promise<void> {
    const release = await this.mutex.acquire();
    try {
      await apiService.session.updateSessionSnapshot(this.vid, this.sid, req);
      this.lastFailedRequest = null;
    } catch (error) {
      this.logger.error('update session snapshot failed', error);
      this.lastFailedRequest = req;
    } finally {
      release();
    }
  }
  stop(): void {
    if (this.timerId) clearInterval(this.timerId);
    if (this.lastFailedRequest) {
      this.logger.warn('non-uploaded snapshot found', {
        snapshot: this.lastFailedRequest,
      });
    }
  }
  start(interval = 60000): void {
    this.stop();
    this.timerId = setInterval(async () => {
      if (this.mutex.isLocked() || !this.lastFailedRequest) return;
      this.logger.info('retry session snapshot uploading');
      try {
        await apiService.session.updateSessionSnapshot(
          this.vid,
          this.sid,
          this.lastFailedRequest
        );
        this.lastFailedRequest = null;
      } catch (error) {
        this.logger.error('update session snapshot failed', error);
      }
    }, interval);
  }
  get failedRequest(): UpdateSessionSnapshotRequest | null {
    return this.lastFailedRequest;
  }
}

function transitionTo<T>(prev: Nullable<T>, curr: Nullable<T>, target: T) {
  return (
    !nullOrUndefined(prev) &&
    !nullOrUndefined(curr) &&
    prev !== target &&
    curr === target
  );
}

function SnapshotRecorder(props: {
  sessionId: string;
  snapshotCheckInterval: number;
  silientRefreshEnabled: boolean;
}): JSX.Element | null {
  const venueId = useVenueId();
  const { sessionId, snapshotCheckInterval, silientRefreshEnabled } = props;
  const gameSessionBlock = useGameSessionBlock();
  const isGamePlayBlocksCompleted = useIsGamePlayBlocksCompleted();
  const curr = useGameSessionStatus();
  const prev = usePrevious(curr);
  const uploader = useMemo(
    () => new Uploader(venueId, sessionId),
    [venueId, sessionId]
  );
  const remoteItems = useInstance(() => buildRemoteItems());

  useEffect(() => {
    uploader.start(snapshotCheckInterval);
    return () => {
      uploader.stop();
    };
  }, [uploader, snapshotCheckInterval]);

  useEffect(() => {
    if (!gameSessionBlock?.type || gameSessionBlock.type === BlockType.TITLE_V2)
      return;
    const status = GameSessionUtil.StatusMapFor(gameSessionBlock.type);
    if (!status) return;
    if (
      transitionTo(prev, curr, status.end) ||
      transitionTo(prev, curr, status.scoreboard)
    ) {
      const scoreboardData = getScoreboardData();
      const req: UpdateSessionSnapshotRequest = {
        items: [
          ...remoteItems,
          {
            key: 'scoreboard',
            remote: false,
            data: scoreboardData,
          },
        ],
        silentRefresh: silientRefreshEnabled,
        isGameCompleted: isGamePlayBlocksCompleted,
      };
      uploader.upload(req);
    }
  }, [
    prev,
    curr,
    gameSessionBlock?.type,
    uploader,
    remoteItems,
    silientRefreshEnabled,
    isGamePlayBlocksCompleted,
  ]);
  return null;
}

function SessionStatusTracker(props: {
  silientRefreshEnabled: boolean;
  platform?: EnumsPlatform;
}): JSX.Element | null {
  const { silientRefreshEnabled } = props;
  const curr = useStreamSessionStatus();
  const prev = usePrevious(curr);
  const venueId = useVenueId();
  const session = useLatest(useStreamSession());
  const orgId = useLatest(useVenueOwner().orgId);
  const gamePackId = useGameSessionGamePackId();
  const pairingId = usePairingId();
  const eventId = useQueryParam('event-id');
  const ondState = useOndGameState();
  const lastOndState = usePrevious(ondState);
  const isStreamSessionInited = useIsStreamSessionInited();
  const isGamePlayBlocksCompleted = useIsGamePlayBlocksCompleted();
  const isOndGameCompleted =
    lastOndState === 'ended' || isGamePlayBlocksCompleted;
  const myUid = useUser().id;
  const amICohost = useAmICohost();

  // Start Session
  useEffect(() => {
    if (!isStreamSessionInited) return;
    if (!session.current?.id || !session.current.startedAt) {
      log.warn('will not log start session, data is missing', {
        session: session.current,
      });
      return;
    }
    if (transitionTo(prev, curr, SessionStatus.Live)) {
      try {
        apiService.session.startSession(venueId, session.current.id, {
          orgId: orgId.current ?? null,
          mode: session.current.mode,
          startedAt: session.current.startedAt,
          gamePackId: gamePackId,
          pairingId: pairingId,
          eventId: eventId,
          cohostUid: amICohost ? myUid : null,
          timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          platform: props.platform,
        });
      } catch (error) {
        // TODO: retry with queue
        log.error('log start session failed', error);
      }
    }
  }, [
    curr,
    gamePackId,
    isStreamSessionInited,
    pairingId,
    eventId,
    prev,
    session,
    venueId,
    orgId,
    amICohost,
    myUid,
    props.platform,
  ]);

  // End Session
  useEffect(() => {
    if (!isStreamSessionInited) return;
    if (!session.current?.id || !session.current.endedAt) {
      log.warn('will not log end session, data is missing', {
        session: session.current,
      });
      return;
    }
    if (
      prev !== SessionStatus.NotStarted &&
      transitionTo(prev, curr, SessionStatus.Ended)
    ) {
      try {
        apiService.session.endSession(venueId, session.current.id, {
          endedAt: session.current.endedAt,
          isGameCompleted: isOndGameCompleted,
          silentRefresh: silientRefreshEnabled,
        });
      } catch (error) {
        // TODO: retry with queue
        log.error('log end session failed', error);
      }
    }
  }, [
    prev,
    curr,
    isOndGameCompleted,
    isStreamSessionInited,
    session,
    silientRefreshEnabled,
    venueId,
  ]);

  return null;
}

export function SessionTracking(props: {
  config: Configuration['session'];
  platform?: EnumsPlatform;
}): JSX.Element | null {
  const sessionId = useStreamSessionId();
  const isCoordinator = useIsCoordinator();
  if (!props.config.trackingEnabled || !isCoordinator) return null;

  return (
    <>
      <SessionStatusTracker
        silientRefreshEnabled={props.config.realtimeSilentRefreshEnabled}
        platform={props.platform}
      />
      {sessionId && (
        <SnapshotRecorder
          sessionId={sessionId}
          snapshotCheckInterval={props.config.snapshotCheckInterval}
          silientRefreshEnabled={props.config.realtimeSilentRefreshEnabled}
        />
      )}
    </>
  );
}
