import { type Block } from '@lp-lib/game';

/**
 * This duration is used in various parts of the code to determine how long to
 * play the Block Title animation for. When generating the V3 Recording, it is
 * typically halved (`durationMs / 2`) because it makes overlapping with the
 * previous block possible by having the system trigger the animation early.
 * Just be aware that if you change this value, the _start_ of the animation
 * will change during Ond. During LIVE the full duration is used since overlaps
 * are unnecessary.
 */
export function blockTitleAnimationHalfDuration(
  block: Block | null,
  durationMs = 3000
): number {
  if (!block) return 0;
  return (block.fields.title ? durationMs : 0) / 2;
}
