import React, {
  type ReactNode,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { ArrowLeftIcon } from '../icons/Arrows';
import {
  MusicNextIcon,
  MusicPauseIcon,
  MusicPlayIcon,
  MusicPrevIcon,
} from '../icons/MusicControlIcon';
import { useMusicPlayerContext } from './Context';

export const SongTitle = ({ title }: { title: string }): JSX.Element => {
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);

  const fa = useRef<HTMLDivElement>(null);
  const son = useRef<HTMLDivElement>(null);

  const distance =
    son.current && fa.current
      ? son.current.clientWidth + 4 - fa.current.clientWidth
      : 0;
  const duration = distance / 8;

  useEffect(() => {
    if (!son.current || !fa.current) {
      return;
    }

    if (son.current.clientWidth <= fa.current.clientWidth) {
      setWidth(son.current.clientWidth);
      setHeight(son.current.clientHeight);
      return () => {
        setWidth(0);
        setHeight(0);
      };
    }

    const ref = son.current;

    setWidth(son.current.clientWidth);
    setHeight(son.current.clientHeight);
    ref.classList.add('animate-song-title-move-left');
    return () => {
      setWidth(0);
      setHeight(0);
      ref.style.setProperty('left', `0`);
      ref.classList.remove('animate-song-title-move-left');
      ref.classList.remove('animate-song-title-move-right');
    };
  }, [title]);

  const handleAnimationEnd = (event: React.AnimationEvent) => {
    if (!son.current) {
      return;
    }

    if (event.animationName === 'song-title-move-left') {
      son.current.style.setProperty('left', `-${distance}px`);
      son.current.classList.remove('animate-song-title-move-left');
      son.current.classList.add('animate-song-title-move-right');
      return;
    }

    if (event.animationName === 'song-title-move-right') {
      son.current.style.setProperty('left', `0`);
      son.current.classList.remove('animate-song-title-move-right');
      return;
    }
  };

  const handleClick = () => {
    if (
      !son.current ||
      !fa.current ||
      son.current.clientWidth <= fa.current.clientWidth
    ) {
      return;
    }

    if (
      son.current.classList.contains('animate-song-title-move-left') ||
      son.current.classList.contains('animate-song-title-move-right')
    ) {
      return;
    }

    son.current.classList.add('animate-song-title-move-left');
  };

  return (
    <div
      ref={fa}
      className='relative overflow-hidden h-4 flex items-center'
      onClick={handleClick}
    >
      <div
        ref={son}
        className='absolute whitespace-nowrap'
        style={
          {
            '--song-title-duration': `${duration}s`,
            '--song-title-left': `-${distance}px`,
          } as React.CSSProperties
        }
        onAnimationEnd={handleAnimationEnd}
      >
        {title}
      </div>
      <div
        style={{
          width: width,
          height: height,
        }}
      ></div>
    </div>
  );
};

function convertElapsedTime(sec: number) {
  const seconds = Math.floor(sec % 60);
  const minutes = Math.floor((sec / 60) % 60);
  const hours = Math.floor(sec / 3600);

  if (hours > 0) {
    return `${hours}:${minutes < 10 ? '0' : ''}${minutes}:${
      seconds < 10 ? '0' : ''
    }${seconds}`;
  }

  return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
}

const convertElapsedTime2 = (sec: number): string => {
  const seconds = Math.floor(sec % 60);
  const minutes = Math.floor((sec / 60) % 60);
  const hours = Math.floor(sec / 3600);

  if (hours > 0) {
    return `${hours} hr ${minutes} min ${seconds} sec`;
  }
  return `${minutes} min ${seconds} sec`;
};

const Progress = (): JSX.Element => {
  const { currentAudio, getCurrentTime, seekTime, emitter } =
    useMusicPlayerContext();

  const [currentTime, setCurrentTime] = useState(getCurrentTime());
  const ref = useRef<HTMLDivElement>(null);

  const duration = currentAudio?.duration || 0;
  const percentage = duration ? (currentTime / duration) * 100 : 0;

  useEffect(() => {
    const handleTimeUpdate = (currentTime: number) => {
      setCurrentTime(currentTime);
    };
    emitter.on('on-time-update', handleTimeUpdate);
    return () => {
      emitter.off('on-time-update', handleTimeUpdate);
    };
  }, [emitter]);

  const handleClickBar = (event: React.MouseEvent) => {
    if (!ref.current || !duration) {
      return;
    }

    const barWidth = ref.current.clientWidth;
    const clickedX = event.clientX - ref.current.offsetLeft;
    const clickedTime = (duration * clickedX) / barWidth;
    seekTime(clickedTime);
    setCurrentTime(clickedTime);
  };

  return (
    <div className='w-full flex flex-col'>
      <div
        ref={ref}
        className={`w-full my-1 h-0.75 ${
          duration > 0 ? 'cursor-pointer' : ''
        } flex items-center bg-lp-gray-005`}
        onClick={handleClickBar}
      >
        <div
          className='relative bg-white h-0.75 flex items-center'
          style={{
            width: `${percentage}%`,
          }}
        >
          <div className='absolute -right-0.75 w-1.5 h-1.5 bg-white rounded-full'></div>
        </div>
      </div>
      <div className='w-full my-1 flex justify-between text-3xs text-secondary'>
        <div>{convertElapsedTime(currentTime)}</div>
        <div>
          {(duration > currentTime ? '-' : '') +
            convertElapsedTime(duration - currentTime)}
        </div>
      </div>
    </div>
  );
};

interface ButtonProps {
  key: string;
  show: boolean;
  disabled: boolean;
  icon: ReactNode;
  handleClick: () => void;
}

const ButtonGroup = (): JSX.Element => {
  const { prev, next, currentAudio, pause, play, isPlaying } =
    useMusicPlayerContext();

  const songSelected = currentAudio !== null;
  const buttonPropsArr = [
    {
      key: 'prev',
      show: true,
      disabled: !songSelected,
      icon: <MusicPrevIcon disabled={!songSelected} />,
      handleClick: () => {
        prev();
      },
    },
    {
      key: 'play',
      show: !isPlaying,
      disabled: false,
      icon: <MusicPlayIcon />,
      handleClick: () => {
        play('host tools');
      },
    },
    {
      key: 'pause',
      show: isPlaying,
      disabled: false,
      icon: <MusicPauseIcon />,
      handleClick: () => {
        pause('host tools');
      },
    },
    {
      key: 'next',
      show: true,
      disabled: !songSelected,
      icon: <MusicNextIcon disabled={!songSelected} />,
      handleClick: () => {
        next();
      },
    },
  ];

  return (
    <div className='flex justify-between'>
      {buttonPropsArr.map(
        (props: ButtonProps) =>
          props.show && (
            <div
              key={props.key}
              className={`flex items-center ${
                props.disabled ? '' : 'cursor-pointer'
              }`}
              onClick={() => {
                if (props.disabled) {
                  return;
                }

                props.handleClick();
              }}
            >
              {props.icon}
            </div>
          )
      )}
    </div>
  );
};

const SongList = (): JSX.Element => {
  const { playlist, currentIndex, select } = useMusicPlayerContext();

  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);
  const ref = useRef<HTMLDivElement>(null);

  // scroll selected song to the center
  useEffect(() => {
    ref.current?.scrollIntoView({
      block: 'center',
    });
  }, [currentIndex]);

  return (
    <div className='w-full'>
      {playlist?.audios.map((v, index) => {
        const playing = index === currentIndex;
        const selected = index === selectedIndex;
        return (
          <div
            ref={playing ? ref : undefined}
            key={v.id}
            className={`select-none w-full px-4 truncate flex py-2 cursor-pointer hover:bg-lp-gray-002 ${
              playing ? 'text-primary' : ''
            } ${selected ? 'bg-lp-gray-009' : ''}`}
            onDoubleClick={() => {
              select(index);
            }}
            onClick={() => {
              setSelectedIndex(index);
            }}
          >
            <div className='text-2xs w-3'>{index + 1}.</div>
            <div className='pl-2 truncate'>
              <div className='text-2xs truncate'>{v.title}</div>
              <div className='text-3xs text-secondary pt-1'>
                {convertElapsedTime(v.duration)}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export const MusicPlayer = ({
  handleClose,
}: {
  handleClose: () => void;
}): JSX.Element => {
  const { playlist, currentAudio, loading } = useMusicPlayerContext();
  const totalSec = useMemo(() => {
    return (
      playlist?.audios.map((v) => v.duration).reduce((a, b) => a + b, 0) || 0
    );
  }, [playlist?.audios]);

  if (loading) {
    return (
      <div className='w-full h-full flex justify-center items-center text-white'>
        Loading...
      </div>
    );
  }

  return (
    <div className='w-full h-full flex flex-col text-white overflow-hidden'>
      <div
        className='flex p-4 cursor-pointer hover:bg-lp-gray-002'
        onClick={handleClose}
      >
        <div>
          <ArrowLeftIcon />
        </div>
        <div className='pl-2'>
          <p className='text-2xs'>{playlist?.title || 'Unknown playlist'}</p>
          <p className='text-secondary text-3xs mt-1'>
            {`${playlist?.audios.length || 0} Songs, ${convertElapsedTime2(
              totalSec
            )}`}
          </p>
        </div>
      </div>

      <div className='w-full overflow-y-auto scrollbar h-96'>
        <SongList></SongList>
      </div>

      <div className='w-full p-4'>
        <div className='text-2xs py-1'>
          {currentAudio ? (
            <SongTitle title={currentAudio.title}></SongTitle>
          ) : (
            <div className='text-secondary'>No music selected</div>
          )}
        </div>

        <Progress />

        <ButtonGroup></ButtonGroup>
      </div>
    </div>
  );
};
