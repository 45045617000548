import { type UID } from 'agora-rtc-sdk-ng';

import { type Logger } from '@lp-lib/logger-base';

import { assertExhaustive } from '../../utils/common';
import { type IRTCService, TrackState } from './types';

type Recovery = {
  lastAttemptedAt: number;
  attempts: number;
};

export class AgoraVideoAutoRecovery {
  private timerId?: ReturnType<typeof setTimeout>;
  private recoveryMap: Map<UID, Recovery>;
  private enabled: boolean;
  constructor(
    private svc: IRTCService,
    private log: Logger,
    private interval: number = 5000,
    private maxAttempts: number = 10
  ) {
    this.recoveryMap = new Map<UID, Recovery>();
    this.enabled = true;
  }

  private tryRecover(uid: UID): void {
    const recovery = this.recoveryMap.get(uid) ?? {
      lastAttemptedAt: Date.now(),
      attempts: 0,
    };
    if (recovery.attempts > this.maxAttempts) return;
    recovery.attempts += 1;
    recovery.lastAttemptedAt = Date.now();
    this.recoveryMap.set(uid, recovery);
    this.log.info('try to recover user video', {
      remoteUid: uid,
      ...recovery,
      maxAttempts: this.maxAttempts,
    });
    this.svc.resubscribe(uid, 'video');
  }

  private tryClear(uid: UID): void {
    if (this.recoveryMap.has(uid)) {
      this.log.info('user video recovered, clear recovery state.', {
        remoteUid: uid,
      });
      this.recoveryMap.delete(uid);
    }
  }

  start(): void {
    this.log.info('video recovery started');
    this.reset();
    this.timerId = setInterval(() => {
      if (!this.enabled) return;
      const map = this.svc.getVideoTrackStateMap();
      for (const [uid, state] of Object.entries(map)) {
        switch (state) {
          case TrackState.Disconnected:
            this.tryRecover(uid);
            break;
          case TrackState.Live:
            this.tryClear(uid);
            break;
          case TrackState.Subscribed:
          case TrackState.Unknown:
            break;
          default:
            assertExhaustive(state);
            break;
        }
      }
    }, this.interval);
  }

  reset(): void {
    if (this.timerId) clearInterval(this.timerId);
    this.recoveryMap.clear();
  }

  disable(): void {
    this.enabled = false;
  }

  enable(): void {
    this.enabled = true;
  }
}
