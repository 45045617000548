const CorrectIcon = ({ className }: { className?: string }): JSX.Element => {
  return (
    <svg
      className={className || 'w-3.5 h-3.5'}
      viewBox='0 0 78 78'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M39 74C58.33 74 74 58.33 74 39C74 19.67 58.33 4 39 4C19.67 4 4 19.67 4 39C4 58.33 19.67 74 39 74ZM53.9765 29.2094C55.748 27.0911 55.4668 23.9378 53.3485 22.1663C51.2302 20.3948 48.0768 20.676 46.3054 22.7943L31.0627 41.0213L25.1728 36.2581C23.0256 34.5217 19.8773 34.8547 18.1409 37.0019C16.4045 39.149 16.7375 42.2973 18.8847 44.0337L29.3639 52.5082C31.0617 53.8812 33.5469 53.639 34.9476 51.964L53.9765 29.2094Z'
        fill='#39D966'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M53.3477 22.1665C55.466 23.938 55.7472 27.0913 53.9757 29.2096L34.9468 51.9642C33.5461 53.6392 31.0609 53.8814 29.3631 52.5084L18.8839 44.0339C16.7367 42.2975 16.4037 39.1492 18.1401 37.0021C19.8765 34.8549 23.0248 34.5219 25.172 36.2583L31.062 41.0215L46.3046 22.7945C48.0761 20.6762 51.2294 20.395 53.3477 22.1665Z'
        fill='white'
      />
    </svg>
  );
};

export { CorrectIcon };
