import React, { useRef } from 'react';

import { GamePackDetailsCard } from '../../../../app/components/GamePack/Details/GamePackDetailsCard';
import { GamePackBreadcrumbs } from '../../../../app/components/GamePack/GamePackBreadcrumbs';
import {
  GamePackAudienceDisclaimer,
  GamePackDifficulty,
  GamePackDuration,
  GamePackPlayerRange,
} from '../../../../app/components/GamePack/GamePackShared';
import { useLiveCallback } from '../../../hooks/useLiveCallback';
import { useOutsideClick } from '../../../hooks/useOutsideClick';
import {
  GamePackContextProvider,
  useGamePackContext,
} from '../../../pages/GamePack/Context';
import { RoleUtils } from '../../../types';
import { type GamePack } from '../../../types/game';
import { useAwaitFullScreenConfirmCancelModal } from '../../ConfirmCancelModalContext';
import { ModalWrapper } from '../../ConfirmCancelModalContext/ModalWrapper';
import { ArrowLeftIcon } from '../../icons/Arrows';
import { useUser } from '../../UserContext';
import { useGameLikeWorkspace } from '../GameCenter';
import { GamePackDetailActions } from './GamePackDetailActions';

export {
  GamePackAudienceDisclaimer,
  GamePackDifficulty,
  GamePackDuration,
  GamePackPlayerRange,
};

function FullscreenGamePackDetailModal(props: {
  onClose: () => void;
  top?: `top-${number}`;
  children?: React.ReactNode;
}): JSX.Element | null {
  const { onClose, children } = props;
  const ref = useRef<HTMLDivElement>(null);
  useOutsideClick(ref, () => onClose());

  return (
    <div
      className={`fixed z-50 ${
        props.top ?? 'top-15'
      } bottom-0 w-screen bg-lp-black-004 pt-5 px-20 flex justify-center items-center`}
    >
      <div
        ref={ref}
        className='w-full max-w-[1280px] min-w-[1080px] h-full bg-modal overflow-y-auto scrollbar border border-secondary rounded-t-2.5xl'
      >
        {children}
      </div>
    </div>
  );
}

export function GamePackDetailModalWorkspaceWrapper(): JSX.Element | null {
  const [activeGamePack, setActiveGamePack] = useGameLikeWorkspace(
    'gamePack',
    'active'
  );
  const ctx = useGamePackContext();
  const isAdmin = RoleUtils.isAdmin(useUser());

  if (!activeGamePack) {
    return null;
  }

  const primaryTag = activeGamePack.tags?.[0];

  const breadcrumbItems = activeGamePack.isPrime
    ? [
        {
          label: 'Explore',
          onClick: () => setActiveGamePack(null),
        },
        primaryTag && !ctx.embed
          ? {
              label: primaryTag.name,
              to: `${ctx.routePrefix}/tags/${primaryTag.slug}`,
              onClick: () => setActiveGamePack(null),
            }
          : null,
        { label: activeGamePack.name },
      ]
    : [{ label: activeGamePack.name }];

  return (
    <FullscreenGamePackDetailModal onClose={() => setActiveGamePack(null)}>
      <GamePackDetailsCard
        pack={activeGamePack}
        anonymous={false}
        isPublic={ctx.pageType === 'public'}
        showHistory={isAdmin}
        sideAction={<GamePackDetailActions pack={activeGamePack} />}
        breadCrumbs={<GamePackBreadcrumbs items={breadcrumbItems} />}
        onClickGame={
          isAdmin
            ? (game) => {
                window.open(`/admin/games/${game.id}`, '_blank');
              }
            : undefined
        }
        onClickBrand={
          isAdmin
            ? (brand) => {
                window.open(`/admin/brands/${brand.id}`, '_blank');
              }
            : undefined
        }
        onClickShowcaseCard={
          isAdmin
            ? (card) => {
                window.open(
                  `/admin/toolkit/shared-media?id=${card.id}`,
                  '_blank'
                );
              }
            : undefined
        }
      />
    </FullscreenGamePackDetailModal>
  );
}

export function useOpenGamePackDetailPage() {
  return useLiveCallback((pack: GamePack) => {
    window.open(`/home?pack-id=${pack.id}`, '_blank');
  });
}

export function useOpenGamePackDetailModal() {
  const triggerModal = useAwaitFullScreenConfirmCancelModal();
  return useLiveCallback(
    async (pack: GamePack, sideAction?: React.ReactNode) => {
      return await triggerModal({
        kind: 'custom',
        element: (p) => (
          <ModalWrapper
            containerClassName='w-[1280px]'
            innerClassName='overflow-hidden'
            borderStyle='gray'
            onClose={p.internalOnCancel}
          >
            <GamePackContextProvider noOnboardingTasks embed pageType='public'>
              <GamePackDetailsCard
                pack={pack}
                anonymous={false}
                isPublic={true}
                sideAction={sideAction}
                breadCrumbs={
                  <button
                    type='button'
                    className='
                        btn w-9 h-9 bg-main-layer hover:bg-lp-gray-003 text-white 
                        rounded-lg border border-secondary
                        flex items-center justify-center
                      '
                    onClick={p.internalOnCancel}
                  >
                    <ArrowLeftIcon className='w-4 h-4 fill-current' />
                  </button>
                }
              />
            </GamePackContextProvider>
          </ModalWrapper>
        ),
      });
    }
  );
}
