import { compressSync, decompressSync } from 'fflate';
import { Type } from 'js-binary';
import { ClientId, ProfileIndex } from './profile';

// To add a new message:
// Step 1: Add a new entry to CFMsgKind
// Step 2: call defMsg() with the type/schema
// Step 3: Add .create() return type to CFMsg
// Step 4: Spread .schema into CFMsgSchema

// The value of these enums must remain constant. Including the explicit value
// (= 0, = 1, etc) is one way to ensure that.
export enum CFMsgKind {
  UploadClientFilmstripToServer = 0,
  RegisterClientForTargetReception = 1,
  UnregisterClientForTargetReception = 2,
  ReceiveFilmstripFromServer = 3,
}

// Increment this anytime a field type changes, a new message is added, or a
// message is removed
const VERSION = 0;

// TODO: venue unlock key!

// Used to assert message construction is correct. Must remain synced with the
// HeaderFieldsSchema
type CFHeaderFields = {
  version: typeof VERSION;
};

// The Schema used by JSBinary to encode/decode these fields
const HeaderFieldsSchema: Record<keyof CFHeaderFields, unknown> = {
  // common to all
  version: 'int',
};

// Define a message schema, type, and creator function in one call. Slightly
// hacky, and not perfectly typesafe, but better than keeping them separate!
function defMsg<
  Kind extends CFMsgKind,
  TSMsg extends Record<string, unknown>,
  JSBinaryMsgSchema extends Record<string, unknown> = Record<string, unknown>
>(kind: Kind, msgSchema: JSBinaryMsgSchema) {
  const schemaEntry = {
    [kind + '?']: { ...HeaderFieldsSchema, ...msgSchema },
  };

  return {
    schema: schemaEntry,
    kind,
    from: (msg: null | Record<string, unknown>) => {
      return msg && 'kind' in msg && msg['kind'] === kind
        ? (msg[kind] as TSMsg & CFHeaderFields)
        : null;
    },
    create: (msg: TSMsg & Omit<CFHeaderFields, 'version'>) => ({
      kind,
      [kind]: { version: VERSION, ...msg },
    }),
  };
}

export const ReceiveFilmstripFromServerMsg = defMsg<
  CFMsgKind.ReceiveFilmstripFromServer,
  {
    target: {
      // The client this data represents
      cid: ClientId;
      profile: ProfileIndex;
      filmstrip: {
        // the image data, as data uri
        data: string;
      };
    };
  }
>(CFMsgKind.ReceiveFilmstripFromServer, {
  target: {
    // The client this data represents
    cid: 'string',
    profile: 'int',
    filmstrip: {
      // the image data, as data uri
      data: 'string',
    },
  },
});

export const UploadClientFilmstripToServerMsg = defMsg<
  CFMsgKind.UploadClientFilmstripToServer,
  {
    cid: ClientId;
    targets: {
      profile: ProfileIndex;
      filmstrip: { data: string };
    }[];
  }
>(CFMsgKind.UploadClientFilmstripToServer, {
  ...HeaderFieldsSchema,
  cid: 'string',
  targets: [
    {
      profile: 'int',
      filmstrip: { data: 'string' },
    },
  ],
});

export const RegisterClientForTargetReceptionMsg = defMsg<
  CFMsgKind.RegisterClientForTargetReception,
  {
    uid: string;
    cid: ClientId;
    targets: {
      cid: ClientId;
      profile: ProfileIndex;
    }[];
  }
>(CFMsgKind.RegisterClientForTargetReception, {
  ...HeaderFieldsSchema,
  uid: 'string',
  cid: 'string',
  targets: [
    {
      cid: 'string',
      profile: 'int',
    },
  ],
});

export const UnregisterClientForTargetReceptionMsg = defMsg<
  CFMsgKind.UnregisterClientForTargetReception,
  {
    uid: string;
    cid: ClientId;
    targets: {
      cid: ClientId;
      profile: ProfileIndex;
    }[];
  }
>(CFMsgKind.UnregisterClientForTargetReception, {
  ...HeaderFieldsSchema,
  uid: 'string',
  cid: 'string',
  targets: [
    {
      cid: 'string',
      profile: 'int',
    },
  ],
});

//
export type CFMsg =
  | ReturnType<typeof ReceiveFilmstripFromServerMsg.create>
  | ReturnType<typeof UploadClientFilmstripToServerMsg.create>
  | ReturnType<typeof RegisterClientForTargetReceptionMsg.create>
  | ReturnType<typeof UnregisterClientForTargetReceptionMsg.create>;

export type CFKnownMsg<M extends CFMsg, K extends CFMsgKind> = M extends {
  kind: K;
}
  ? M
  : never;

// The Schema used by JSBinary. This is exported only for testing purposes and
// should not be used directly.
export const CFMsgSchema = {
  kind: 'int', // matches CFMsgType enum values

  // Be sure to include new message types here!
  ...ReceiveFilmstripFromServerMsg.schema,
  ...UploadClientFilmstripToServerMsg.schema,
  ...RegisterClientForTargetReceptionMsg.schema,
  ...UnregisterClientForTargetReceptionMsg.schema,
};

const CFSchema = new Type(CFMsgSchema);

// A type to represent an already encoded, yet known message.
export type CFEncodedMsg<K extends CFMsgKind> = Uint8Array & { kind: K };

// export function encode(msg: CFMsg): Uint8Array;
export function encode<M extends CFMsg>(msg: M): CFEncodedMsg<M['kind']> {
  const encoded = CFSchema.encode(msg);
  return compressSync(encoded) as CFEncodedMsg<M['kind']>;
}

export function decode(buf: ArrayBuffer): CFMsg | null {
  const decompressed = decompressSync(new Uint8Array(buf));
  const msg = CFSchema.decode(Buffer.from(decompressed));
  if (!msg || msg.kind === null || msg.kind === undefined) return null;

  // Check for any `undefined` keys in the message body. They likely mean the
  // message was malformed or could not be decoded.

  if (process.env['NODE_ENV'] === 'development') {
    const body = msg[msg.kind];
    const keys = Object.keys(body);
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i] as string;
      if (body[key] === undefined) return null;
    }
  }

  return msg;
}

export function readAs<M extends CFMsg, K extends CFMsgKind>(
  kind: K,
  msg: CFMsg | null
): null | CFKnownMsg<M, K>[typeof kind] {
  if (!msg || !msg.kind || !msg[kind]) return null;
  return msg[kind] as CFKnownMsg<M, K>[typeof kind];
}
