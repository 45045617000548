import useSWR, { type SWRConfiguration } from 'swr';
import useSWRMutation from 'swr/mutation';

import {
  type DtoNullableProductResponse,
  type DtoProduct,
  type DtoProductDefaultsResponse,
  type DtoProductsResponse,
  type DtoUpdateProductDefaultsRequest,
} from '@lp-lib/api-service-client/public';

import { apiService } from '../../services/api-service';

export function useProducts(config?: SWRConfiguration<DtoProductsResponse>) {
  return useSWR(
    '/api/products',
    async () => {
      const resp = await apiService.product.getProducts();
      return resp.data;
    },
    {
      shouldRetryOnError: false,
      ...config,
    }
  );
}

export function useDraftProducts(config?: SWRConfiguration<DtoProduct[]>) {
  return useSWR(
    '/api/products/drafts',
    async () => {
      const resp = await apiService.product.getProducts();
      return resp.data.drafts;
    },
    {
      shouldRetryOnError: false,
      ...config,
    }
  );
}

export function useMarketedProducts(config?: SWRConfiguration<DtoProduct[]>) {
  return useSWR(
    '/api/products/marketed',
    async () => {
      const resp = await apiService.product.getPublicProducts();
      return resp.data.marketed;
    },
    {
      shouldRetryOnError: false,
      ...config,
    }
  );
}

export function usePublishedProducts(config?: SWRConfiguration<DtoProduct[]>) {
  return useSWR(
    '/api/products/published',
    async () => {
      const resp = await apiService.product.getPublicProducts();
      return resp.data.published;
    },
    {
      shouldRetryOnError: false,
      ...config,
    }
  );
}

export function useMostPopularProduct(
  config?: SWRConfiguration<DtoNullableProductResponse>
) {
  return useSWR(
    '/api/products/most-popular',
    async () => {
      const resp = await apiService.product.getMostPopularProduct();
      return resp.data;
    },
    {
      shouldRetryOnError: false,
      ...config,
    }
  );
}

export function useProductDefaults(
  config?: SWRConfiguration<DtoProductDefaultsResponse>
) {
  return useSWR(
    '/api/products/defaults',
    async () => {
      const resp = await apiService.product.getProductDefaults();
      return resp.data;
    },
    {
      shouldRetryOnError: false,
      ...config,
    }
  );
}

export function useUpdateProductDefaults() {
  return useSWRMutation(
    '/api/products/defaults',
    async (_, { arg }: { arg: DtoUpdateProductDefaultsRequest }) => {
      const resp = await apiService.product.updateProductDefaults(arg);
      return resp.data;
    },
    {
      populateCache: true,
      revalidate: false,
    }
  );
}
