import copy from 'copy-to-clipboard';
import { useEffect, useRef, useState } from 'react';

import { BrowserTimeoutCtrl } from '../utils/BrowserTimeoutCtrl';

/**
 * This assumes that the parent of `label` has `position: relative` so it can
 * automatically position a "Copied!" message appropriately.
 */
export function useCopyTextLink(props: {
  label: JSX.Element;
  getter: () => string;
}) {
  const { getter } = props;
  const afterCopiedRef = useRef<() => void>();
  const [copying, setCopying] = useState(false);
  useEffect(() => {
    const timeout = new BrowserTimeoutCtrl();
    if (copying) {
      setCopying(true);
      timeout.set(() => {
        setCopying(false);
        afterCopiedRef.current?.();
      }, 500);
    }
    return () => timeout.clear();
  }, [copying]);

  const onExecuteCopy = (onCopyFinished?: () => void) => {
    afterCopiedRef.current = onCopyFinished;
    const text = getter();
    copy(text);
    setCopying(true);
  };

  const label = (
    <>
      {copying ? (
        <span
          className={'absolute top-0 right-0 bottom-0 left-0 flex items-center'}
        >
          Copied!
        </span>
      ) : null}
      <span className={copying ? 'invisible' : ''}>{props.label}</span>
    </>
  );

  return [onExecuteCopy, label] as const;
}
