import { useLocation, useNavigate } from '@remix-run/react';
import { useEffect, useMemo, useRef, useState } from 'react';
import useSWR, { type SWRResponse } from 'swr';
import useSWRImmutable from 'swr/immutable';

import { EnumsGamePackVersion } from '@lp-lib/api-service-client/public';

import logger from '../../../logger/logger';
import {
  apiService,
  DummyPaginator,
  type Paginable,
  type Paginator,
} from '../../../services/api-service';
import {
  type GameLike,
  type GameLikeActionSheetSettings,
  type GameLikeAdvancedDropdownSettings,
  type GameLikeCollectionType,
  type GameLikeEditingContext,
  GameLikeLabelMap,
  type GameLikeType,
  type GamePack,
} from '../../../types/game';
import { type Tag, virtualTags } from '../../../types/tag';
import { RoleUtils } from '../../../types/user';
import { assertExhaustive } from '../../../utils/common';
import { useUser } from '../../UserContext';
import {
  type GameLikePageType,
  useGameLikeFiltersAsQueryString,
} from './Context';

export function useGamePacksByCollection(
  collectionType: GameLikeCollectionType,
  size?: number
): SWRResponse<GamePack[], Error> {
  const isAdmin = RoleUtils.isAdmin(useUser());
  const useSWRHook = isAdmin ? useSWR : useSWRImmutable;

  return useSWRHook(
    [`/game-packs/collections/${collectionType}`, size],
    async () =>
      (await apiService.gamePack.getGamePacksByCollection(collectionType, size))
        .data.gamePacks
  );
}

export function useGameLikeTagPaginator<
  P extends Paginable,
  T extends GameLike
>(props: {
  slug: string;
  getPaginatorByTagId: (tagId: number) => Paginator<P, T>;
  initTag?: Tag;
}): { inited: boolean; tag: Tag | null; paginator: Paginator<P, T> | null } {
  const { slug, getPaginatorByTagId, initTag } = props;
  const [tag, setTag] = useState<Tag | null>(initTag || null);
  const paginator = useRef<Paginator<P, T> | null>(null);
  const [inited, setInited] = useState(false);
  useEffect(() => {
    async function init() {
      try {
        const t = initTag
          ? initTag
          : (await apiService.tag.getTagBySlug(slug)).data.tag;
        if (!initTag) setTag(t);
        paginator.current = getPaginatorByTagId(t.id);
      } catch (error) {
        logger.error('get tag error', error);
        paginator.current = new DummyPaginator<P, T>();
        setTag(virtualTags.notFound);
      } finally {
        setInited(true);
      }
    }
    init();
  }, [initTag, slug, getPaginatorByTagId]);

  return { inited, tag, paginator: paginator.current };
}

export function useTagBySlug(
  slug: string | null
): SWRResponse<Tag | null, Error> {
  return useSWRImmutable(slug ? `/tags/${slug}` : null, async () => {
    if (!slug) return null;
    const resp = await apiService.tag.getTagBySlug(slug);
    return resp.data.tag;
  });
}

type GameLikeEditingTextMap = {
  [key in GameLikeEditingContext]: {
    title: string;
    button: string;
  };
};

export function useGameLikeEditingTextMap(
  type: GameLikeType
): GameLikeEditingTextMap {
  return useMemo(() => {
    const label = GameLikeLabelMap[type];
    return {
      create: {
        title: `Create ${label}`,
        button: 'Save',
      },
      edit: {
        title: `Edit ${label}`,
        button: 'Save',
      },
      publish: {
        title: `Publish ${label} to Library`,
        button: 'Publish',
      },
    };
  }, [type]);
}

export function useGameLikeActionSheetSettings(
  gameLike: GameLike,
  context: 'mini-card' | 'detail-card',
  pageType: GameLikePageType
): GameLikeActionSheetSettings {
  const isAdmin = RoleUtils.isAdmin(useUser());
  return useMemo((): GameLikeActionSheetSettings => {
    if (context === 'mini-card') {
      if (gameLike.isPrime) {
        if (isAdmin) {
          return {
            edit: true,
            delete: true,
            duplicate: pageType === 'default',
            addToMySpace: pageType === 'default',
            feature: pageType === 'public' && gameLike.type === 'gamePack',
            pin: (gameLike.tags?.length ?? 0) > 0,
            preview: gameLike.type === 'gamePack',
            export: true,
            copyPublicLink: gameLike.type === 'gamePack',
            copyEditLink: gameLike.type === 'gamePack',
            copyMyVenueLink: gameLike.type === 'gamePack',
            editPromotionalAssets:
              pageType === 'public' &&
              gameLike.type === 'gamePack' &&
              gameLike.isPrime,
            convertToBrand: gameLike.type === 'game',
            testGamePack: gameLike.type === 'gamePack',
            cohostGamePack: Boolean(
              gameLike.type === 'gamePack' && gameLike.cohostSettings?.enabled
            ),
            editSlides:
              gameLike.type === 'gamePack' &&
              gameLike.version === EnumsGamePackVersion.GamePackVersionV1,
            craftSlidesShareLink:
              gameLike.type === 'gamePack' &&
              gameLike.version === EnumsGamePackVersion.GamePackVersionV1,
          };
        } else {
          return {
            addToMySpace:
              gameLike.type === 'game' ||
              (gameLike.type === 'gamePack' &&
                gameLike.version === EnumsGamePackVersion.GamePackVersionV1),
          };
        }
      } else {
        return {
          edit: true,
          delete: true,
          duplicate: true,
          publish: true,
          preview: gameLike.type === 'gamePack',
          export: isAdmin,
          copyPublicLink: isAdmin && gameLike.type === 'gamePack',
          copyEditLink: isAdmin && gameLike.type === 'gamePack',
          copyMyVenueLink: isAdmin && gameLike.type === 'gamePack',
          convertToBrand: gameLike.type === 'game',
          testGamePack: gameLike.type === 'gamePack',
          cohostGamePack: Boolean(
            gameLike.type === 'gamePack' && gameLike.cohostSettings?.enabled
          ),
          editSlides:
            gameLike.type === 'gamePack' &&
            gameLike.version === EnumsGamePackVersion.GamePackVersionV1,
          craftSlidesShareLink:
            gameLike.type === 'gamePack' &&
            gameLike.version === EnumsGamePackVersion.GamePackVersionV1,
        };
      }
    } else if (context === 'detail-card') {
      if (gameLike.isPrime) {
        if (isAdmin) {
          return {
            edit: false,
            delete: true,
            addToMySpace: pageType === 'default',
            feature: pageType === 'public' && gameLike.type === 'gamePack',
            export: true,
            copyPublicLink: gameLike.type === 'gamePack',
            copyEditLink: gameLike.type === 'gamePack',
            copyMyVenueLink: gameLike.type === 'gamePack',
            editPromotionalAssets:
              pageType === 'public' &&
              gameLike.type === 'gamePack' &&
              gameLike.isFeatured,
            testGamePack: gameLike.type === 'gamePack',
            cohostGamePack: Boolean(
              gameLike.type === 'gamePack' && gameLike.cohostSettings?.enabled
            ),
            editSlides:
              gameLike.type === 'gamePack' &&
              gameLike.version === EnumsGamePackVersion.GamePackVersionV1,
            craftSlidesShareLink:
              gameLike.type === 'gamePack' &&
              gameLike.version === EnumsGamePackVersion.GamePackVersionV1,
          };
        } else {
          return {
            addToMySpace: true,
          };
        }
      } else {
        return {
          edit: false,
          delete: true,
          publish: true,
          export: isAdmin,
          copyPublicLink: isAdmin && gameLike.type === 'gamePack',
          copyEditLink: isAdmin && gameLike.type === 'gamePack',
          copyMyVenueLink: isAdmin && gameLike.type === 'gamePack',
          testGamePack: gameLike.type === 'gamePack',
          cohostGamePack: Boolean(
            gameLike.type === 'gamePack' && gameLike.cohostSettings?.enabled
          ),
          editSlides:
            gameLike.type === 'gamePack' &&
            gameLike.version === EnumsGamePackVersion.GamePackVersionV1,
          craftSlidesShareLink:
            gameLike.type === 'gamePack' &&
            gameLike.version === EnumsGamePackVersion.GamePackVersionV1,
        };
      }
    } else {
      assertExhaustive(context);
      throw new Error(`unknown context: ${context}`);
    }
  }, [context, gameLike, isAdmin, pageType]);
}

export function useGameLikeAdvancedDropdownSettings(
  gameLikeType: GameLikeType,
  pageType: GameLikePageType,
  context: GameLikeEditingContext
): GameLikeAdvancedDropdownSettings {
  return {
    feature:
      gameLikeType === 'gamePack' &&
      pageType === 'public' &&
      context === 'edit',
    free:
      gameLikeType === 'gamePack' &&
      pageType === 'public' &&
      context === 'edit',
  };
}

export function useShowGameLikeEditButton(gameLike: GameLike): boolean {
  const user = useUser();
  const isAdmin = RoleUtils.isAdmin(user);

  return (gameLike.isPrime && isAdmin) || gameLike.uid === user.id;
}

export function useRedirectToSearch(
  enabled: boolean,
  type: GameLikeType,
  path?: string
): void {
  const navigate = useNavigate();
  const location = useLocation();
  const filtersAsQueryString = useGameLikeFiltersAsQueryString(type);

  useEffect(() => {
    if (!enabled) return;
    if (filtersAsQueryString.length > 0) {
      navigate({
        pathname: path ?? `${location.pathname}/search`,
        search: filtersAsQueryString,
      });
    }
  }, [filtersAsQueryString, location.pathname, navigate, enabled, path]);
}
