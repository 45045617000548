import {
  type DtoCalendarRoundExtensions,
  type DtoChannelProgramLink,
  type DtoProgram,
  type DtoProgramRound,
  type DtoRecognitionProgramLinkExtensions,
  type ModelsCalendarProgramLinkExtensions,
  type ModelsIntrosProgramLinkExtensions,
  type ModelsIntrosRoundExtensions,
  type ModelsWaterCoolerProgramExtensions,
  type ModelsWaterCoolerProgramLinkExtensions,
  type ModelsWaterCoolerRoundExtensions,
} from '@lp-lib/api-service-client/public';

export type Program = DtoProgram;

export type CalendarProgramRound = Omit<DtoProgramRound, 'extensions'> & {
  extensions: Nullable<DtoCalendarRoundExtensions>;
};

export type CalendarProgramLink = Omit<DtoChannelProgramLink, 'extensions'> & {
  extensions: Nullable<ModelsCalendarProgramLinkExtensions>;
};

export type IntrosProgram = Omit<DtoProgram, 'extensions'>;

export type IntrosProgramRound = Omit<DtoProgramRound, 'extensions'> & {
  extensions: Nullable<ModelsIntrosRoundExtensions>;
};

export type IntrosProgramLink = Omit<DtoChannelProgramLink, 'extensions'> & {
  extensions: Nullable<ModelsIntrosProgramLinkExtensions>;
};

export type WaterCoolerProgram = Omit<DtoProgram, 'extensions'> & {
  extensions: Nullable<ModelsWaterCoolerProgramExtensions>;
};

export type WaterCoolerProgramRound = Omit<DtoProgramRound, 'extensions'> & {
  extensions: Nullable<ModelsWaterCoolerRoundExtensions>;
};

export type CelebrationsProgram = Omit<DtoProgram, 'extensions'>;

export type WaterCoolerProgramLink = Omit<
  DtoChannelProgramLink,
  'extensions'
> & {
  extensions: Nullable<ModelsWaterCoolerProgramLinkExtensions>;
};

export type RecognitionProgramLink = Omit<
  DtoChannelProgramLink,
  'extensions'
> & {
  extensions: Nullable<DtoRecognitionProgramLinkExtensions>;
};

export function castProgram<T>(program: DtoProgram): T {
  return program as unknown as T;
}

export function castProgramRounds<T>(rounds: DtoProgramRound[]): T[];
export function castProgramRounds<T>(
  rounds: DtoProgramRound[] | undefined
): T[] | undefined;
export function castProgramRounds<T>(
  rounds: DtoProgramRound[] | undefined
): T[] | undefined {
  if (rounds === undefined) return;
  return rounds as unknown as T[];
}

export function castProgramRound<T>(round: DtoProgramRound): T {
  return round as unknown as T;
}

export function castProgramLink<T>(link: DtoChannelProgramLink): T {
  return link as unknown as T;
}
