import Uppy, { type UploadResult, type UppyOptions } from '@uppy/core';
import { FileInput } from '@uppy/react';
import XHRUpload from '@uppy/xhr-upload';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { EnumsMediaScene } from '@lp-lib/api-service-client/public';
import { type Media, MediaType } from '@lp-lib/media';

import config from '../../config';
import { useUppy } from '../../hooks/useUppy';
import logger from '../../logger/logger';
import { uuidv4 } from '../../utils/common';
import { getToken } from '../../utils/getToken';
import { uncheckedIndexAccess_UNSAFE } from '../../utils/uncheckedIndexAccess_UNSAFE';

export type MediaUploaderState = {
  /**
   * A input element to trigger file uploads. This element should be mounted in
   * the DOM in your component. See: https://uppy.io/docs/react/file-input/. By
   * default this is a single button that triggers the file selector.
   *
   * You can hide this element and restyle it with a label, for example,
   *
   * ```ts
   * <label className='custom styles'>
   *   <div className='hidden'>{inputElement}</div>
   * </label>
   * ```
   *
   * In this case the clicking on the label will trigger the hidden input
   * element.
   */
  inputElement: JSX.Element;

  /**
   * True when an upload is in progress.
   */
  isUploading: boolean;

  /**
   * Present if there was an error uploading.
   */
  uploadError?: Error | null;

  /**
   * Returns the configured/uploaded media.
   */
  media?: Media | null;

  /**
   * Removes the media associated with this uploader.
   */
  deleteMedia: () => void;

  /**
   * The max file size (bytes) permitted with this uploader.
   */
  maxFileSize: number | undefined;

  /**
   * The file types permitted with this uploader.
   */
  allowedFileTypes: string[] | undefined;
};

export type MediaUploaderProps = {
  /**
   * Sitewide unique id for Uppy.
   *
   * See: https://uppy.io/docs/uppy/#id-39-uppy-39
   */
  id?: string;

  /**
   * The current media associated with this uploader, or null.
   */
  media?: Media | null;

  /**
   * True if images are permitted uploads.
   */
  image?: boolean;

  /**
   * True if videos are permitted uploads.
   */
  video?: boolean;

  /**
   * True if audios are permitted uploads.
   */
  audio?: boolean;

  /**
   * The scene, for controlling the encoding pipeline.
   */
  scene?: EnumsMediaScene;

  /**
   * Sets the debug flag on the Uppy instance.
   */
  debug?: boolean;

  /**
   * Sets the text on the default file input. If you plan to directly use the
   * inputElement returned from this hook, you can customize the copy.
   */
  inputText?: string;

  /**
   * Triggered when uploading starts.
   */
  onUploadStart?: () => void;

  /**
   * Triggered when uploading successfully finishes.
   */
  onUploadSuccess?: (media: Media) => void;

  /**
   * Triggered when uploading fails.
   */
  onUploadFailed?: () => void;

  /**
   * Triggered when uploading completes, regardless of success or failure. When `multiple` is true,
   * this fires after all uploads are complete.
   */
  onComplete?: (result: UploadResult) => void;

  onBeforeUpload?: UppyOptions['onBeforeUpload'];

  /**
   * Customized upload file size limit
   */
  overrideSizeLimit?: {
    image?: number;
    video?: number;
    audio?: number;
  };

  /**
   * Defaults to false.
   */
  multiple?: boolean;

  /**
   * Expected aspect ratio for the media.
   */
  aspectRatio?: [number, number];
};

type MediaUploadConstraint = {
  formats: string[];
  maxFileSize: {
    default: number;
    sceneOverride?: { [T in EnumsMediaScene]?: number };
  };
};

const MEDIA_UPLOAD_CONSTRAINTS: { [T in MediaType]: MediaUploadConstraint } = {
  [MediaType.Image]: {
    formats: ['.jpg', '.jpeg', '.png', '.gif'],
    maxFileSize: {
      default: 5 * 1024 * 1024, // 5MB
      sceneOverride: {
        [EnumsMediaScene.MediaSceneProgramCelebrationsMessage]: 2 * 1024 * 1024,
      },
    },
  },
  [MediaType.Video]: {
    formats: ['.mp4', '.webm'],
    maxFileSize: {
      default: 100 * 1024 * 1024, // 100MB
      sceneOverride: {
        [EnumsMediaScene.MediaSceneVenueBackground]: 500 * 1024 * 1024, // 500MB
        [EnumsMediaScene.MediaSceneBlockBackground]: 500 * 1024 * 1024, // 500MB
      },
    },
  },
  [MediaType.Audio]: {
    formats: ['.aac', '.mp3', '.wav', '.m4a'],
    maxFileSize: {
      default: 100 * 1024 * 1024, // 100MB
    },
  },
};

const INPUT_NAME = 'files';

type DerivedMediaConstraint = {
  allowedFileTypes?: string[];
  maxFileSize?: number;
};

function resolveMaxFileSize(
  constraint: MediaUploadConstraint,
  scene?: EnumsMediaScene,
  overrideSizeLimit?: number
): number {
  if (overrideSizeLimit) return overrideSizeLimit;
  return scene
    ? constraint.maxFileSize.sceneOverride?.[scene] ??
        constraint.maxFileSize.default
    : constraint.maxFileSize.default;
}

export function getMaxFileSizeByMediaType(
  mediaType: MediaType,
  scene?: EnumsMediaScene,
  overrideSizeLimit?: number
): number {
  return resolveMaxFileSize(
    MEDIA_UPLOAD_CONSTRAINTS[mediaType],
    scene,
    overrideSizeLimit
  );
}

function getDerivedMediaConstraint(
  props: MediaUploaderProps
): DerivedMediaConstraint {
  const { image, video, audio, scene, overrideSizeLimit } = props;
  const config = {} as DerivedMediaConstraint;
  const imageConstraint = MEDIA_UPLOAD_CONSTRAINTS[MediaType.Image];
  const videoConstraint = MEDIA_UPLOAD_CONSTRAINTS[MediaType.Video];
  const audioConstraint = MEDIA_UPLOAD_CONSTRAINTS[MediaType.Audio];

  if (image && video) {
    config.allowedFileTypes = [
      ...imageConstraint.formats,
      ...videoConstraint.formats,
    ];
    config.maxFileSize = resolveMaxFileSize(
      videoConstraint,
      scene,
      overrideSizeLimit?.video
    );
  } else if (image) {
    config.allowedFileTypes = [...imageConstraint.formats];
    config.maxFileSize = resolveMaxFileSize(
      imageConstraint,
      scene,
      overrideSizeLimit?.image
    );
  } else if (video) {
    config.allowedFileTypes = [...videoConstraint.formats];
    config.maxFileSize = resolveMaxFileSize(
      videoConstraint,
      scene,
      overrideSizeLimit?.video
    );
  } else if (audio) {
    config.allowedFileTypes = [...audioConstraint.formats];
    config.maxFileSize = resolveMaxFileSize(
      audioConstraint,
      scene,
      overrideSizeLimit?.audio
    );
  }

  return config;
}

export const useMediaUploader = (
  props: MediaUploaderProps
): MediaUploaderState => {
  const [media, setMedia] = useState<Media | null>(props.media ?? null);
  const [uploadError, setUploadError] = useState<Error | null>(null);
  const [isUploading, setIsUploading] = useState(false);
  const mediaConfig = useMemo(() => getDerivedMediaConstraint(props), [props]);
  const uppyId = useMemo(() => (props.id ? props.id : uuidv4()), [props.id]);

  const uppy = useUppy(uppyId, () => {
    return new Uppy({
      id: uppyId,
      autoProceed: true,
      allowMultipleUploadBatches: props.multiple ?? false,
      debug: props.debug,
      restrictions: {
        maxFileSize: mediaConfig.maxFileSize,
        minFileSize: null,
        maxTotalFileSize: mediaConfig.maxFileSize,
        maxNumberOfFiles: props.multiple ? null : 1,
        minNumberOfFiles: 1,
        allowedFileTypes: mediaConfig.allowedFileTypes,
      },
      locale: {
        strings: {
          chooseFiles: props.inputText,
          exceedsSize: 'Max file size exceeded',
          youCanOnlyUploadFileTypes: 'File type not supported',
        },
      },
      onBeforeFileAdded: () => {
        setUploadError(null);
        return true;
      },
      onBeforeUpload: (files) => {
        if (props.onBeforeUpload) {
          return props.onBeforeUpload(files);
        }
        return true;
      },
    })
      .use(XHRUpload, {
        endpoint: config.api.baseUrl + '/media/upload',
        method: 'post',
        formData: false,
        fieldName: INPUT_NAME,
        headers: (file) => {
          const extraHeaders = {
            authorization: `Bearer ${getToken()}`,
          };
          if (props.scene) {
            uncheckedIndexAccess_UNSAFE(extraHeaders)['x-lp-scene'] =
              props.scene;
          }
          if (props.aspectRatio) {
            uncheckedIndexAccess_UNSAFE(extraHeaders)['x-lp-aspect-ratio'] =
              props.aspectRatio.join(':');
          }
          if (file.type) {
            uncheckedIndexAccess_UNSAFE(extraHeaders)['Content-Type'] =
              file.type;
          }
          return extraHeaders;
        },
        timeout: 1800 * 1000, // 30 Mins
        limit: 0,
      })
      .on('upload', () => {
        setIsUploading(true);
        if (props.onUploadStart) {
          props.onUploadStart();
        }
      })
      .on('complete', (result) => {
        setIsUploading(false);
        props.onComplete?.(result);
        uppy.reset();
      })
      .on('restriction-failed', (_file, error) => {
        setUploadError(error);
      })
      .on('error', (error) => {
        logger.error('uploading error', error, {
          id: uppyId,
          scene: props.scene,
        });
        setUploadError(error);
        setIsUploading(false);
        uppy.reset();
      })
      .on('upload-success', (_file, resp) => {
        const uploadedMedia = resp.body.media as Media;
        if (props.onUploadSuccess) {
          props.onUploadSuccess(uploadedMedia);
        }
        setMedia(uploadedMedia);
      })
      .on('upload-error', (_file, error, resp) => {
        if (props.onUploadFailed) {
          props.onUploadFailed();
        }
        if (resp?.body && 'msg' in resp.body) {
          setUploadError(resp.body.msg);
        } else {
          setUploadError(error);
        }
      });
  });

  useEffect(() => {
    setUploadError(null);
  }, [uppyId]);

  useEffect(() => {
    setMedia(props.media || null);
  }, [props.media]);

  const inputElement = useMemo(() => {
    return <FileInput uppy={uppy} inputName={INPUT_NAME} />;
  }, [uppy]);

  const deleteMedia = useCallback(() => {
    setMedia(null);
  }, []);

  return {
    inputElement,
    isUploading,
    uploadError,
    media,
    deleteMedia,
    maxFileSize: mediaConfig.maxFileSize,
    allowedFileTypes: mediaConfig.allowedFileTypes,
  };
};
