import { add, format, sub } from 'date-fns';
import pluralize from 'pluralize';
import { match } from 'ts-pattern';

import {
  type DtoMessageLogicV2,
  EnumsMessageTriggerTimeOffsetDirection,
  EnumsMessageTriggerType,
  type ModelsMessageTrigger,
  type ModelsMessageTriggerTimeOffset,
} from '@lp-lib/api-service-client/public';

import { type MessageVars } from './MessageCampaign';
import { MESSAGE_TRIGGER_EVENT_CONFIG } from './MessageTrigger';

export class MessageTriggerUtils {
  static GetAbsoluteTimeLabel(triggerTime: string | null | undefined) {
    if (!triggerTime) return null;
    const date = new Date(triggerTime);
    return `${format(date, 'EEE, MMM do, yyyy')}\n${format(date, 'hh:mm a')}`;
  }

  static GetTimeOffsetLabel(offset: ModelsMessageTriggerTimeOffset) {
    if (!offset) return '';

    const arr = [];
    if (offset.days > 0) {
      arr.push(pluralize('day', offset.days, true));
    }
    if (offset.hours > 0) {
      arr.push(pluralize('hour', offset.hours, true));
    }
    if (arr.length === 0 || offset.minutes > 0) {
      arr.push(pluralize('min', offset.minutes, true));
    }

    return `${arr.join(' ')} \n ${offset.direction} the start`;
  }

  static GetWeekdaysOffsetLabel(offset: ModelsMessageTriggerTimeOffset) {
    if (!offset) return '';

    const arr = [];
    if (offset.days > 0) {
      arr.push(pluralize('weekday', offset.days, true));
    }
    if (offset.hours > 0) {
      arr.push(pluralize('hour', offset.hours, true));
    }
    if (arr.length === 0 || offset.minutes > 0) {
      arr.push(pluralize('min', offset.minutes, true));
    }

    return `${arr.join(' ')} \n ${offset.direction} the start`;
  }

  static GetCompareIdentify(trigger: ModelsMessageTrigger): string {
    return match(trigger.type)
      .with(EnumsMessageTriggerType.MessageTriggerTypeEventDriven, () => {
        if (!trigger.eventName) return `0`;
        return `0-${MESSAGE_TRIGGER_EVENT_CONFIG[trigger.eventName].label}`;
      })
      .with(EnumsMessageTriggerType.MessageTriggerTypeWeekdaysOffset, () => {
        if (!trigger.weekdaysOffset) return `1`;
        const func =
          trigger.weekdaysOffset.direction ===
          EnumsMessageTriggerTimeOffsetDirection.MessageTriggerTimeOffsetDirectionAfter
            ? add
            : sub;
        const est = func(new Date(), {
          days: trigger.weekdaysOffset.days,
          hours: trigger.weekdaysOffset.hours,
          minutes: trigger.weekdaysOffset.minutes,
        });
        return `1-${trigger.weekdaysOffset.triggerTime}-${est.getTime()}`;
      })
      .otherwise(() => {
        if (!trigger.timeOffset) return `2`;
        const func =
          trigger.timeOffset.direction ===
          EnumsMessageTriggerTimeOffsetDirection.MessageTriggerTimeOffsetDirectionAfter
            ? add
            : sub;
        const est = func(new Date(), {
          days: trigger.timeOffset.days,
          hours: trigger.timeOffset.hours,
          minutes: trigger.timeOffset.minutes,
        });
        return `2-${trigger.timeOffset.triggerTime}-${est.getTime()}`;
      });
  }

  static Compare(a: ModelsMessageTrigger, b: ModelsMessageTrigger): number {
    return this.GetCompareIdentify(a).localeCompare(this.GetCompareIdentify(b));
  }
}

export class MessageTemplateUtils {
  static TranslateText(text: string, vars: MessageVars['text']): string {
    if (!vars) return text;

    if (vars) {
      for (const [k, v] of Object.entries(vars)) {
        const regex = new RegExp(`%${k}%`, 'g');
        text = text.replace(regex, v);
      }
    }
    return text;
  }
}

export class MessageLogicUtils {
  static Sort(arr: DtoMessageLogicV2[] | null | undefined) {
    if (!arr) return [];
    return arr.sort((a, b) =>
      MessageTriggerUtils.Compare(a.trigger, b.trigger)
    );
  }
}
