import { type CSSProperties } from 'react';

import { type MemberId, type TeamId } from '../../../../types';

export enum GameState {
  None = 1,
  Inited,
  InProgress,
  Ended,
}

export type CardPackType = 'celebrities' | 'paintings' | 'dancing' | 'funface';

export type Game = {
  id: string;
  state: GameState;
};

export type GameSettings = {
  numOfPairs: number;
  hiddenMode: boolean;
  pointsPerMatch: number;
  gameTimeSec: number;
  pack: CardPackType;
};

export type Card = {
  index: number;
  pair: number;
  url: string;
  matched: 'done' | 'wrong' | 'unknown';
  showScore?: boolean;
  reveal:
    | {
        target: 'all';
        revealedAt: number;
      }
    | {
        target: 'someone';
        memberId: MemberId;
        revealedAt: number;
      }
    | null;
};

export type CardMap = {
  [key: number]: Card;
};

// used for rendering only
export type GridCard = (Card & { placeholder: false }) | { placeholder: true };

export type GameTeamInfo = {
  [key: TeamId]: {
    cards: Card[];
  };
};

export type GameProgressSummary = {
  [key: TeamId]: {
    numOfCorrectMatches: number;
  };
};

export type HiddenModeInfo = {
  lastRevealedMember?: {
    id: MemberId;
    joinedAt: number;
  };
  lastRevealMatched?: boolean;
};

export type CardUIState =
  | { state: 'unreveal' }
  | { state: 'wrong' }
  | { state: 'done' }
  | { state: 'reveal-to-all' }
  | { state: 'reveal-to-someone'; memberId: MemberId };

export type RevealUIState = Nullable<
  {
    blur: boolean;
    onlyYouCanSee: boolean;
    memberId: MemberId | null;
    viewer: boolean;
  },
  false
>;

export type FlipAnimation = {
  durationMs: number;
  style: CSSProperties;
};

export type ShakeAnimation = {
  durationMs: number;
  durationAnimationFormat: `${number}s`;
};
