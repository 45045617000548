import { useEffect, useRef, useState } from 'react';

import { useOutsideClick } from '../../hooks/useOutsideClick';
import { TriangleArrowDown, TriangleArrowUp } from '../icons/Arrows';

export function OneShotAnimatedImg(props: {
  src: string;
  alt: string;
  imgClassName?: string;
}): JSX.Element {
  const ref = useRef<HTMLImageElement>(null);

  useEffect(() => {
    const img = ref.current;
    return () => {
      if (!img) return;
      // Reset the src to guarantee that the next time the animation starts from
      // the beginning.
      img.src = '';
    };
  }, []);

  return (
    <img
      className={props.imgClassName || ''}
      ref={ref}
      src={props.src}
      alt={props.alt}
    />
  );
}

export function LegalDisclaimer(props: {
  text?: string;
  className?: string;
  downplay?: boolean;
}): JSX.Element {
  return (
    <p
      className={`text-center text-2xs ${
        props.downplay ? 'text-icon-gray' : 'text-white'
      } ${props.className}`}
    >
      {props.text || 'By signing up'}, you agree to our{' '}
      <a
        className={`${props.downplay ? 'underline' : 'text-tertiary'}`}
        href='https://lunapark.com/terms-of-use'
        target='_blank'
        rel='noopener noreferrer'
      >
        Terms of Use
      </a>{' '}
      and{' '}
      <a
        className={`${props.downplay ? 'underline' : 'text-tertiary'}`}
        href='https://lunapark.com/privacy-policy'
        target='_blank'
        rel='noopener noreferrer'
      >
        Privacy Policy
      </a>
      .
    </p>
  );
}

export interface Option<T> {
  label: string;
  value: T;
}
export interface SelectProps<T> {
  options: Option<T>[];
  value: Option<T> | null;
  disabled?: boolean;
  onChange?: (value: Option<T>) => void;
  optionIdPrefix?: string;
  justifyContent?: 'justify-center' | 'justify-between';
  fontSize?: 'text-sms' | 'text-2xs';
}

export function Select<T>(props: SelectProps<T>): JSX.Element {
  const [active, setActive] = useState(false);

  const justifyContent = props.justifyContent ?? 'justify-center';
  const padding = justifyContent === 'justify-between' ? 'px-3' : '';
  const fontSize = props.fontSize ?? 'text-sms';

  const ref = useRef<HTMLDivElement>(null);

  useOutsideClick(ref, () => setActive(false));

  const toggle = () => {
    if (props.disabled) return;
    setActive(!active);
  };
  const onChange = (option: Option<T>) => {
    if (props.disabled) return;
    if (props.onChange) props.onChange(option);
    setActive(false);
  };

  return (
    <div ref={ref} className={`w-full relative ${fontSize}`}>
      <div
        className={`flex items-center ${justifyContent} bg-black border border-secondary w-full h-10 
        ${active ? 'rounded-t-xl' : 'rounded-xl'}
        ${
          props.disabled
            ? 'cursor-not-allowed text-secondary'
            : 'cursor-pointer text-white'
        } 
        ${padding}`}
        onClick={toggle}
      >
        <p className='mr-2'>{props.value?.label}</p>
        {active ? <TriangleArrowUp /> : <TriangleArrowDown />}
      </div>
      <ul
        className={`absolute border-l border-r border-b border-secondary 
        bg-black w-full top-9 list-none rounded-b-xl max-h-30 
        overflow-y-scroll scrollbar select-none z-5 text-white ${
          active ? 'block' : 'hidden'
        }`}
      >
        {props.options.map((o, idx) => (
          <li
            id={
              props.optionIdPrefix
                ? `${props.optionIdPrefix}-${o.value}`
                : undefined
            }
            className={`flex items-center ${justifyContent} cursor-pointer hover:bg-secondary h-8 ${padding}`}
            key={idx}
            onClick={() => onChange(o)}
          >
            {o.label}
          </li>
        ))}
      </ul>
    </div>
  );
}

export function TertiaryTooltipBackground(
  props: React.SVGProps<SVGSVGElement>
): JSX.Element {
  return (
    <svg
      className={props.className}
      viewBox='0 0 253 90'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      preserveAspectRatio={props.preserveAspectRatio}
    >
      <mask id='path-1-inside-1_5:4' fill='white'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M8.52634 84H241C247.628 84 253 78.6274 253 72V12C253 5.37258 247.628 0 241 0H12.0003C5.37285 0 0.000274181 5.37258 0.000274181 12V77.0002L0 77L1.90735e-06 89.8154L8.52634 84Z'
        />
      </mask>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M8.52634 84H241C247.628 84 253 78.6274 253 72V12C253 5.37258 247.628 0 241 0H12.0003C5.37285 0 0.000274181 5.37258 0.000274181 12V77.0002L0 77L1.90735e-06 89.8154L8.52634 84Z'
        fill='#FBB707'
      />
      <path
        d='M8.52634 84V83H8.21778L7.96287 83.1739L8.52634 84ZM0.000274181 77.0002L-0.467119 77.8842L1.00027 78.66V77.0002H0.000274181ZM0 77L0.467393 76.116L-1 75.3402L-1 77L0 77ZM1.90735e-06 89.8154L-0.999998 89.8154L-0.999998 91.7079L0.563472 90.6416L1.90735e-06 89.8154ZM8.52634 85H241V83H8.52634V85ZM241 85C248.18 85 254 79.1797 254 72H252C252 78.0751 247.075 83 241 83V85ZM254 72V12H252V72H254ZM254 12C254 4.8203 248.18 -1 241 -1V1C247.075 1 252 5.92487 252 12H254ZM241 -1H12.0003V1H241V-1ZM12.0003 -1C4.82057 -1 -0.999726 4.8203 -0.999726 12H1.00027C1.00027 5.92487 5.92514 1 12.0003 1V-1ZM-0.999726 12V77.0002H1.00027V12H-0.999726ZM-0.467393 77.8841L-0.467119 77.8842L0.467667 76.1161L0.467393 76.116L-0.467393 77.8841ZM1 89.8154L1 77L-1 77L-0.999998 89.8154L1 89.8154ZM7.96287 83.1739L-0.563468 88.9893L0.563472 90.6416L9.08981 84.8261L7.96287 83.1739Z'
        fill='white'
        fillOpacity='0.4'
        mask='url(#path-1-inside-1_5:4)'
      />
    </svg>
  );
}
