export function SpotlightBlockIcon(
  props: React.SVGProps<SVGSVGElement>
): JSX.Element {
  return (
    <svg
      className={props.className || 'w-4 h-4'}
      xmlns='http://www.w3.org/2000/svg'
      width='14'
      height='14'
      fill='none'
      viewBox='0 0 14 14'
    >
      <ellipse
        cx='7'
        cy='12'
        fill={`${props.fill ?? '#FBB707'}`}
        rx='5'
        ry='2'
      ></ellipse>
      <path
        fill={`${props.fill ?? '#FBB707'}`}
        fillRule='evenodd'
        d='M11.63 11.3l.392.42-.404.28-.122-.13-.008.034-.509-.088.093-.398L7 7.078l-4.072 4.34.093.398-.509.088-.008-.034-.122.13-.404-.28.393-.42-.864-3.679L0 2.481l.503-.109 1.512 5.156.78 3.321 3.871-4.126L1.56 1.281 1.963 1 7 6.368 12.037 1l.404.28-5.107 5.443 3.872 4.126.781-3.332 1.51-5.145.503.11-1.507 5.14-.864 3.679z'
        clipRule='evenodd'
      ></path>
    </svg>
  );
}
