import { FloatLayout } from '../../../../Layout';
import { useMyTeamId } from '../../../../Player';
import {
  type TeamSubmissionStatus,
  useTeamSubmissionStatuses,
} from './SubmissionStatusProvider';

const statusCopy: Record<TeamSubmissionStatus['status'], string> = {
  waiting: 'In progress',
  submitted: 'Completed',
};

export function SubmissionStatusWidget(): JSX.Element | null {
  const myTeam = useMyTeamId();
  const { teamSubmissions: submissions } = useTeamSubmissionStatuses();
  const hasMyTeamSubmitted =
    submissions.find((s) => s.teamId === myTeam)?.status === 'submitted';

  if (!hasMyTeamSubmitted) return null;

  return (
    <div className='w-full h-full flex flex-col'>
      <div
        className='uppercase font-bold italic text-white'
        style={{
          textShadow: '4px 3px 0px rgba(0, 0, 0, 0.25)',
        }}
      >
        status
      </div>

      <div className='flex-1 flex overflow-hidden rounded bg-lp-black-001 border border-secondary'>
        <div className='flex-1 overflow-y-scroll overflow-x-none scrollbar'>
          <div className='flex flex-col gap-1'>
            {submissions.map((s) => (
              <div
                key={s.teamId}
                className={`
                  h-8 px-4 flex items-center justify-between gap-1
                  ${
                    s.status === 'submitted'
                      ? 'bg-lp-green-003 bg-opacity-10'
                      : 'bg-lp-black-002'
                  } 
                `}
              >
                <div
                  className={`
                    flex-1 font-bold text-2xs truncate
                    ${s.teamId === myTeam ? 'text-tertiary' : 'text-white'}
                  `}
                >
                  {s.name}
                </div>
                <div
                  className={`
                    flex-none font-bold text-2xs
                    ${
                      s.status === 'submitted' ? 'text-green-001' : 'text-white'
                    }
                  `}
                >
                  {statusCopy[s.status]}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export function SubmissionStatusWidgetAttachedOnLeft(props: {
  className?: string;
}): JSX.Element | null {
  return (
    <FloatLayout className={props.className}>
      <div className='absolute transform-gpu top-16 -translate-x-full w-[240px] h-[320px]'>
        <SubmissionStatusWidget />
      </div>
    </FloatLayout>
  );
}
