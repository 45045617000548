import { useCallback, useEffect, useRef } from 'react';
import {
  DndProvider,
  type DropTargetMonitor,
  useDrag,
  useDrop,
} from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import {
  Controller,
  FormProvider,
  useFieldArray,
  useForm,
  useFormContext,
} from 'react-hook-form';
import { usePrevious } from 'react-use';

import { EnumsMediaScene } from '@lp-lib/api-service-client/public';
import {
  type BlockFields,
  type Media,
  type MultipleChoiceBlock,
  type MultipleChoiceBlockMedia,
  type MultipleChoiceOption,
} from '@lp-lib/game';

import { apiService } from '../../../../services/api-service';
import { canMove } from '../../../../utils/dnd';
import { DeleteIcon } from '../../../icons/DeleteIcon';
import { MenuIcon } from '../../../icons/MenuIcon';
import { MiniMediaUploader } from '../../../MediaUploader/MiniMediaUploader';
import { useBlockEditorStore } from '../../../RoutedBlock';
import { PersonalityFieldEditor } from '../../../VoiceOver/PersonalityFieldEditor';
import { BLOCK_TIMER_OPTIONS, maxLengthRule } from '../Common/Editor';
import {
  AdditionalSettings,
  AdditionalSharedSettingsEditor,
  BlockMediaEditor,
  EditorBody,
  EditorLayout,
  type EditorProps,
  type Option,
  RHFCheckbox,
  RHFSelectField,
  useEditor,
} from '../Common/Editor/Internal';
import { PointsSelect } from '../Common/Editor/PointsUtilities';

function DeleteButton(props: { onDelete: () => void }) {
  const handleDelete = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    props.onDelete();
  };

  return (
    <button
      type='button'
      onClick={handleDelete}
      className={`
        btn w-7.5 h-7.5 flex items-center justify-center flex-shrink-0
        border border-secondary rounded-lg
      text-red-002 bg-black hover:bg-secondary
      `}
    >
      <DeleteIcon />
    </button>
  );
}

const maxQuestionLength = 300;
const maxAnswerLength = 50;

function Choice(props: {
  blockId: string;

  /**
   * The index of this choice in the collection.
   */
  index: number;

  /**
   * Note: this data should only be used for initial or default values in the form.
   * See: https://react-hook-form.com/api/usefieldarray
   * See: https://github.com/react-hook-form/react-hook-form/issues/4086
   */
  initialChoice: MultipleChoiceOption;

  /**
   * Used to trigger an async call to persist the changes.
   */
  updateAnswerChoices: () => void;

  /**
   * Called when this choice is dragged to a new index, but before the user has
   * 'dropped'. This permits UI updates but avoids sending the update to the
   * backend.
   */
  onDrag: (from: number, to: number) => void;

  /**
   * Called when this choice is dragged and subsequently dropped. It's not
   * guaranteed that this choice is in a new index; it's possible the choice was
   * dropped in its original index.
   */
  onDrop: () => void;

  /**
   * Called when a user clicks the delete button.
   */
  onDelete: () => void;

  /**
   * Called when the correct option is set for this choice.
   */
  onCorrectChange: () => void;
}): JSX.Element {
  const {
    register,
    control,
    formState: { errors },
  } = useFormContext<BlockFields<MultipleChoiceBlock>>();

  const { index, onDrag, onDelete } = props;
  const ref = useRef<HTMLDivElement>(null);

  const [, drop] = useDrop({
    accept: 'multiple-choice-option',
    hover(item: { index: number }, monitor: DropTargetMonitor) {
      if (!ref.current) return;
      const dragIndex = item.index;
      const hoverIndex = index;
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      if (canMove(dragIndex, hoverIndex, hoverBoundingRect, monitor)) {
        onDrag(dragIndex, hoverIndex);
        item.index = hoverIndex;
      }
    },
  });

  const [collected, drag, preview] = useDrag({
    type: 'multiple-choice-option',
    item: () => {
      return { index };
    },
    collect: (monitor) => ({
      opacity: monitor.isDragging() ? 'opacity-40' : '',
    }),
    end: () => {
      props.onDrop();
    },
  });

  preview(drop(ref));

  return (
    <div
      className={`flex items-center w-full ${collected.opacity} bg-transparent`}
      ref={ref}
    >
      <button
        ref={drag}
        type='button'
        className='btn cursor-move mx-2 flex-shrink-0'
      >
        <MenuIcon className='w-5 h-5' />
      </button>
      <div className='ml-2 w-full flex items-center'>
        <Controller
          control={control}
          name={`answerChoices.${index}.media`}
          render={({ field }) => {
            const handleMediaChange = (media: Media | null) => {
              field.onChange(media);
              props.updateAnswerChoices();
            };

            return (
              <MiniMediaUploader
                uploaderId={`${props.blockId}-answerChoice-${index}`}
                media={field.value}
                onDelete={() => handleMediaChange(null)}
                onUploadSuccess={handleMediaChange}
                customOutlineStyle='rounded-l-xl border border-secondary border-r-0 border-solid'
              />
            );
          }}
        />
        <input
          className={`h-13.5 mb-0 w-full rounded-l-none ${
            errors.answerChoices?.[index] ? 'field-error' : 'field'
          }`}
          placeholder={`Max ${maxAnswerLength} characters`}
          maxLength={maxAnswerLength}
          {...register(`answerChoices.${index}.text`, {
            maxLength: maxLengthRule(maxAnswerLength),
            onBlur: props.updateAnswerChoices,
          })}
          defaultValue={props.initialChoice.text}
        />
      </div>
      <Controller
        control={control}
        name={`answerChoices.${index}.correct`}
        defaultValue={props.initialChoice.correct}
        render={({ field }) => {
          const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
            if (e.target.checked) props.onCorrectChange();
          };

          return (
            <input
              type='radio'
              className='mx-4 field-radio flex-shrink-0'
              checked={field.value}
              onChange={handleChange}
            />
          );
        }}
      />

      <DeleteButton onDelete={onDelete} />
    </div>
  );
}

function ChoicesContainer(props: {
  blockId: string;
  updateAnswerChoices: () => void;
}) {
  const { setValue, getValues } =
    useFormContext<BlockFields<MultipleChoiceBlock>>();
  const { fields, append, move, remove } = useFieldArray<
    BlockFields<MultipleChoiceBlock>,
    'answerChoices'
  >({
    name: 'answerChoices',
  });

  const handleDrag = (from: number, to: number): void => {
    move(from, to);
  };

  const handleDrop = () => {
    props.updateAnswerChoices();
  };

  const handleAddMore = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    append({ text: '', correct: false, mediaId: null, media: null });
    props.updateAnswerChoices();
  };

  const handleCorrectChange = useCallback(
    (correctChoiceIndex: number) => {
      fields.forEach((_, index) => {
        setValue(
          `answerChoices.${index}.correct`,
          index === correctChoiceIndex
        );
      });
      props.updateAnswerChoices();
    },
    [props, fields, setValue]
  );

  const handleDelete = (index: number) => {
    remove(index);
    props.updateAnswerChoices();
  };

  // resets the 'correct' choice radio button if none of the choices are marked
  // as correct.
  useEffect(() => {
    if (fields.length === 0) return;
    const hasCorrectChoice = getValues('answerChoices').some((c) => c.correct);
    if (!hasCorrectChoice) {
      handleCorrectChange(0);
    }
  }, [fields.length, getValues, handleCorrectChange]);

  return (
    <div className='space-y-4'>
      <DndProvider backend={HTML5Backend}>
        <div className='flex flex-col gap-2'>
          {fields.map((choice, index) => (
            <Choice
              key={choice.id}
              blockId={props.blockId}
              index={index}
              initialChoice={choice}
              updateAnswerChoices={props.updateAnswerChoices}
              onDrag={handleDrag}
              onDrop={handleDrop}
              onDelete={() => handleDelete(index)}
              onCorrectChange={() => handleCorrectChange(index)}
            />
          ))}
        </div>
      </DndProvider>
      <button
        type='button'
        className='btn underline text-icon-gray text-sms'
        onClick={handleAddMore}
      >
        Add More
      </button>
    </div>
  );
}

export function MultipleChoiceBlockEditor(
  props: EditorProps<MultipleChoiceBlock>
): JSX.Element | null {
  const { block } = props;
  const blockId = block.id;
  const prevBlockId = usePrevious(props.block.id);
  const store = useBlockEditorStore();

  const form = useForm<BlockFields<MultipleChoiceBlock>>({
    defaultValues: {
      ...props.block.fields,
    },
  });

  const {
    register,
    getValues,
    reset,
    getFieldState,
    formState: { errors },
  } = form;

  useEffect(() => {
    if (prevBlockId === blockId) return;
    reset(props.block.fields);
  }, [blockId, prevBlockId, props.block.fields, reset]);

  const { updateFieldLocalFirst: updateField } = useEditor(props);

  const updateFieldOnBlur = (
    name: keyof BlockFields<MultipleChoiceBlock>
  ): void => {
    if (getFieldState(name).error) return;
    updateField(name, getValues(name));
  };

  const selectOnChange = (
    name: keyof BlockFields<MultipleChoiceBlock>,
    value: Nullable<Option['value'], false>
  ): void => {
    updateField(name, value);
  };

  const updateAnswerChoices = async () => {
    // note: the media controller above doesn't set the media id.
    const answerChoices = getValues('answerChoices').map((c) => ({
      ...c,
      mediaId: c.media?.id ?? null,
      media: c.media ?? null,
    }));

    // note: but when we persist, we want the id and not the media object.
    const answerChoicesWithoutMedia = answerChoices.map((choice) => ({
      ...choice,
      media: null,
    }));

    // note: we're not using updateField because this method is a bit more like
    // gameSlice.updateBlockMedia in that we want to persist data without the
    // media object, but we want the redux store to include it.
    props.setSavingChanges(true);
    try {
      await apiService.block.updateField<MultipleChoiceBlock>(blockId, {
        field: 'answerChoices',
        value: answerChoicesWithoutMedia,
      });
      store.setBlockField({
        blockId,
        blockField: { answerChoices },
      });
    } catch (err) {
      console.error(err);
    } finally {
      props.setSavingChanges(false);
    }
  };

  return (
    <EditorLayout
      bottomAccessory={
        <AdditionalSettings>
          <AdditionalSharedSettingsEditor {...props} />
          <PersonalityFieldEditor
            value={props.block.fields.personalityId}
            onChange={(value) => updateField('personalityId', value)}
          />
        </AdditionalSettings>
      }
    >
      <EditorBody>
        <div>
          <h1 className='text-white text-2xl font-medium mb-7'>
            Multiple Choice
          </h1>

          <FormProvider<BlockFields<MultipleChoiceBlock>> {...form}>
            <form className='w-full'>
              <div className='w-full flex gap-10 mb-16'>
                <div className='w-2/3'>
                  <label htmlFor='question'>
                    <div className='text-white mb-2'>Question/Prompt Text</div>
                    <textarea
                      className={`h-13.5 py-2 mb-0 scrollbar resize-none ${
                        errors.question ? 'field-error' : 'field'
                      }`}
                      maxLength={maxQuestionLength}
                      placeholder={`Max ${maxQuestionLength} characters`}
                      {...register('question', {
                        maxLength: maxLengthRule(maxQuestionLength),
                        onBlur: () => updateFieldOnBlur('question'),
                      })}
                    />
                  </label>

                  <label htmlFor='text' className='w-full inline-block mt-10'>
                    <div className='flex items-baseline justify-between text-white mb-4'>
                      Multiple Choice Options
                      <div className='mr-7.5 text-sms'>Correct</div>
                    </div>
                    <ChoicesContainer
                      blockId={blockId}
                      updateAnswerChoices={updateAnswerChoices}
                    />
                  </label>
                </div>

                <div className='w-1/3 flex flex-col items-center gap-4'>
                  <label htmlFor='questionMedia'>
                    <BlockMediaEditor<MultipleChoiceBlockMedia>
                      blockId={blockId}
                      title='Question/Prompt Media'
                      field='questionMedia'
                      video={true}
                      scene={EnumsMediaScene.MediaSceneBlockMedia}
                      volumeSelectable
                      mediaData={props.block.fields.questionMediaData}
                      media={props.block.fields.questionMedia}
                      extraNotice='Media will display to the audience when the question is presented.'
                    />
                  </label>
                  <label htmlFor='backgroundMedia'>
                    <BlockMediaEditor<MultipleChoiceBlockMedia>
                      blockId={blockId}
                      title='Background Media'
                      field='backgroundMedia'
                      video={true}
                      scene={EnumsMediaScene.MediaSceneBlockBackground}
                      media={props.block.fields.backgroundMedia}
                      extraNotice='Media will display and play in the background during the Game Timer.'
                    />
                  </label>
                  <label htmlFor='answerMedia'>
                    <BlockMediaEditor<MultipleChoiceBlockMedia>
                      blockId={blockId}
                      title='Answer Media'
                      field='answerMedia'
                      video={true}
                      scene={EnumsMediaScene.MediaSceneBlockMedia}
                      volumeSelectable
                      mediaData={props.block.fields.answerMediaData}
                      media={props.block.fields.answerMedia}
                      extraNotice='Media will display to the audience when the answer is revealed.'
                    />
                  </label>

                  <hr className='w-full my-4 border border-secondary' />

                  <label htmlFor='questionTimeSec' className='w-full'>
                    <RHFSelectField<MultipleChoiceBlock>
                      className='w-full h-10 text-white'
                      label='Question Timer'
                      name='questionTimeSec'
                      options={BLOCK_TIMER_OPTIONS}
                      onChange={selectOnChange}
                      value={props.block.fields.questionTimeSec}
                    />
                  </label>

                  <label htmlFor='points' className='w-full'>
                    <PointsSelect
                      key={`${block.id}-points-select`}
                      label='Points for Correct Answer'
                      className='w-full h-10 text-white'
                      defaultValue={{
                        points: block.fields.points,
                        displayPointsMultiplier:
                          block.fields.displayPointsMultiplier,
                      }}
                      onChange={({ points, displayPointsMultiplier }) => {
                        updateField('points', points);
                        updateField(
                          'displayPointsMultiplier',
                          displayPointsMultiplier
                        );
                      }}
                    />
                  </label>

                  <label htmlFor='decreasingPointsTimer' className='w-full'>
                    <RHFCheckbox<MultipleChoiceBlock>
                      label='Decreasing Points Timer'
                      name='decreasingPointsTimer'
                      value={props.block.fields.decreasingPointsTimer}
                      onChange={(_, checked: boolean): void => {
                        updateField('decreasingPointsTimer', checked);
                      }}
                      description={{
                        enabled:
                          'Enabled: Amount of points earned for submitting the correct answer decreases as the Question timer runs out.',
                        disabled:
                          'Disabled: Amount of points earned for submitting the correct answer does not change as the Question timer runs out.',
                      }}
                    />
                  </label>

                  {block.fields.decreasingPointsTimer && (
                    <label className='w-full'>
                      <RHFCheckbox<MultipleChoiceBlock>
                        label='Start Descending Immediately'
                        name='startDescendingImmediately'
                        value={block.fields.startDescendingImmediately ?? false}
                        onChange={(_, checked: boolean): void => {
                          updateField('startDescendingImmediately', checked);
                        }}
                        description={{
                          enabled:
                            'Enabled: Points start descending immediately',
                          disabled:
                            'Disabled: Points start descending after 25% of time.',
                        }}
                      />
                    </label>
                  )}

                  <label htmlFor='startVideoWithTimer' className='w-full'>
                    <RHFCheckbox<MultipleChoiceBlock>
                      label='Start Video with Timer'
                      name='startVideoWithTimer'
                      value={props.block.fields.startVideoWithTimer}
                      onChange={(_, checked: boolean): void => {
                        updateField('startVideoWithTimer', checked);
                      }}
                      description={{
                        enabled:
                          'Enabled: Presenting this Block will show both the Question Text and Video. Video will only play once the timer starts, and it cannot be replayed after.',
                        disabled:
                          'Disabled: Presenting this Block will auto-play the Question Video. Question Text will only show after the video finishes.',
                      }}
                    />
                  </label>
                </div>
              </div>
            </form>
          </FormProvider>
        </div>
      </EditorBody>
    </EditorLayout>
  );
}
