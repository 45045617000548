import React, { type ReactNode, useContext, useMemo, useState } from 'react';

export enum ContextMenuScope {
  Chat = 'chat',
  Teams = 'teams',
  Other = 'other',
}

export interface ContextMenuOptions {
  x: number;
  y: number;
  clientId: string;
  insideRightPanel?: boolean;
  scope: ContextMenuScope;
}

interface ContextMenuContext {
  options: ContextMenuOptions | null;
  setOptions: (options: ContextMenuOptions | null) => void;
}

const Context = React.createContext<ContextMenuContext>(
  {} as ContextMenuContext
);

export function useContextMenuContext(): ContextMenuContext {
  return useContext(Context);
}

export const ContextMenuProvider = (props: {
  children?: ReactNode;
}): JSX.Element => {
  const [options, setOptions] = useState<ContextMenuOptions | null>(null);
  const ctxValue = useMemo(
    () => ({ options, setOptions }),
    [options, setOptions]
  );
  return <Context.Provider value={ctxValue}>{props.children}</Context.Provider>;
};
