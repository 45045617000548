export function CardsIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      className={props.className ?? 'w-3.5 h-3.5 fill-current'}
      viewBox='0 0 30 30'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M17.0817 6.795L4.65236 9.18952L8.11529 27.1648L20.5447 24.7703L17.0817 6.795ZM4.24699 7.08535C3.08489 7.30923 2.32431 8.43279 2.54819 9.59489L6.01113 27.5702C6.23501 28.7323 7.35856 29.4928 8.52066 29.269L20.95 26.8744C22.1121 26.6506 22.8727 25.527 22.6488 24.3649L19.1859 6.38963C18.962 5.22753 17.8385 4.46696 16.6764 4.69084L4.24699 7.08535Z'
      />
      <path d='M10.6697 4.27562C10.7857 3.09784 11.8344 2.23704 13.0122 2.35297L25.8075 3.61239C26.9853 3.72832 27.8461 4.77707 27.7301 5.95484L25.841 25.1478C25.7251 26.3256 24.6763 27.1864 23.4986 27.0705L10.7033 25.811C9.52548 25.6951 8.66468 24.6464 8.78061 23.4686L10.6697 4.27562Z' />
    </svg>
  );
}
