import { useEffect, useState } from 'react';

import { type ModelsTTSScript } from '@lp-lib/api-service-client/public';
import {
  type TitleBlockV2,
  type TitleCard,
  type TitleV2TTSScriptTags,
  type TTSPlaybackOptions,
} from '@lp-lib/game';

import { uuidv4 } from '../../../../utils/common';
import { TagQuery } from '../../../../utils/TagQuery';
import { type useEditor } from '../Common/Editor/EditorUtilities';

type UpdateField = ReturnType<typeof useEditor<TitleBlockV2>>['updateField'];

export class TTSScriptsUpdater {
  constructor(public updateField: UpdateField, public block: TitleBlockV2) {}

  receiveDeps(updateField: UpdateField, block: TitleBlockV2) {
    this.updateField = updateField;
    this.block = block;
  }

  getSingleTTSScript(tags: (TitleV2TTSScriptTags | TitleCard['id'])[]) {
    const q = new TagQuery(this.block.fields.ttsScripts);
    return q.selectFirst(tags);
  }

  getSingleTTSPlaybackOption(tags: (TitleV2TTSScriptTags | TitleCard['id'])[]) {
    const q = new TagQuery(this.block.fields.ttsPlaybackOptions);
    return q.selectFirst(tags);
  }

  async handleTTSScriptUpdate(incoming: Omit<ModelsTTSScript, 'id'>) {
    const existingScripts = this.block.fields.ttsScripts;
    const q = new TagQuery(existingScripts);
    const existing = q.selectFirst(incoming.tags);
    const existingIdx =
      existingScripts?.findIndex((s) => s.id === existing?.id) ?? -1;

    const next = existingScripts ? [...existingScripts] : [];
    const idx = existingIdx === -1 ? next.length : existingIdx;
    const id = existing?.id ?? uuidv4();

    next[idx] = {
      id,
      ...incoming,
    };

    await this.updateField('ttsScripts', next);
  }

  async handleTTSPlaybackOptionsUpdate(
    options: Omit<TTSPlaybackOptions, 'id'>
  ) {
    const existingOptions = this.block.fields.ttsPlaybackOptions;
    const q = new TagQuery(existingOptions);
    const existing = q.selectFirst(options.tags);
    const existingIdx =
      existingOptions?.findIndex((s) => s.id === existing?.id) ?? -1;

    const next = existingOptions ? [...existingOptions] : [];
    const idx = existingIdx === -1 ? next.length : existingIdx;
    const id = existing?.id ?? uuidv4();

    next[idx] = {
      id,
      ...options,
    };

    await this.updateField('ttsPlaybackOptions', next);
  }
}

export function useTTSScriptsUpdater(
  updateField: UpdateField,
  block: TitleBlockV2
) {
  const [value] = useState(() => new TTSScriptsUpdater(updateField, block));

  useEffect(() => {
    value.receiveDeps(updateField, block);
  }, [block, updateField, value]);

  return value;
}
