import React, { type ReactNode, useContext, useEffect, useMemo } from 'react';
import { proxy } from 'valtio';
import { devtools } from 'valtio/utils';

import { type FirebaseSafeRead } from '@lp-lib/firebase-typesafe';
import { type Logger } from '@lp-lib/logger-base';

import { useInstance } from '../../../hooks/useInstance';
import logger from '../../../logger/logger';
import { type RemoteStreamState } from '../../../services/webrtc';
import { type MemberId } from '../../../types';
import { err2s } from '../../../utils/common';
import { ValtioUtils } from '../../../utils/valtio';
import { type FirebaseService } from '../../Firebase';
import { type NarrowedWebDatabaseReference } from '../../Firebase/types';
import { useUpdateParticipant } from '../../Player';
import {
  type MemberMap,
  type Stage,
  type StageMember,
  type StageMemberSettings,
  StageMode,
  type StreamStateMap,
} from '../types';

type State = {
  inited: boolean;
  stage: Stage;
  h2hEnabled: boolean;
  members: MemberMap;
  streamStates: StreamStateMap;
  confirmToBringTeamId?: string;
};

function initialState(): State {
  return {
    inited: false,
    stage: {
      mode: StageMode.BOS,
    },
    h2hEnabled: false,
    members: {},
    streamStates: {},
    confirmToBringTeamId: undefined,
  };
}

type Dependencies = {
  updateParticipant: ReturnType<typeof useUpdateParticipant>;
};

class StageControlAPI {
  private stageRef: NarrowedWebDatabaseReference<Stage>;
  private stageMembersRef: NarrowedWebDatabaseReference<
    FirebaseSafeRead<MemberMap>
  >;
  constructor(
    venueId: string,
    private state: State,
    firebaseService: FirebaseService,
    private log: Logger,
    private deps: Dependencies
  ) {
    this.stageRef = firebaseService.prefixedSafeRef<Stage>(`stage/${venueId}`);
    this.stageMembersRef = firebaseService.prefixedSafeRef<MemberMap>(
      `stage-members/${venueId}`
    );
  }
  async init(): Promise<void> {
    this.log.info('init stage');
    const stage = (await this.stageRef.get()).val();
    this.log.debug('stage first time fetched', { stage });
    if (stage) {
      ValtioUtils.update(this.state.stage, stage);
    }
    this.stageRef.on('value', (snapshot) => {
      const stage = snapshot.val();
      this.log.debug('stage value_changed', { stage });
      if (stage) ValtioUtils.update(this.state.stage, stage);
    });

    const members = (await this.stageMembersRef.get()).val();
    if (members) {
      ValtioUtils.update(this.state.members, members);
    }
    this.stageMembersRef.on('child_added', async (snapshot) => {
      const data = snapshot.val();
      this.log.debug('child_added', { stageMember: data });
      if (data) this.state.members[data.id] = data;
    });
    this.stageMembersRef.on('child_changed', async (snapshot) => {
      const data = snapshot.val();
      this.log.debug('child_changed', { stageMember: data });
      if (data) this.state.members[data.id] = data;
    });
    this.stageMembersRef.on('child_removed', async (snapshot) => {
      const data = snapshot.val();
      this.log.debug('child_removed', { stageMember: data });
      if (data) {
        delete this.state.members[data.id];
      }
    });
    this.log.debug('stage inited');
    this.state.inited = true;
  }

  reset(): void {
    this.stageRef.off();
    this.stageMembersRef.off();
    ValtioUtils.reset(this.state, initialState());
    this.log.debug('stage reset');
  }

  toggleH2H(enabled: boolean): void {
    this.state.h2hEnabled = enabled;
  }

  async updateStageMode(mode: StageMode): Promise<void> {
    if (mode === this.state.stage.mode) return;
    this.state.stage.mode = mode;
    await this.stageRef.update({ mode });
    this.log.info('updated stage mode', { mode });
  }

  async join(
    memberId: MemberId,
    mode = StageMode.BOS,
    options: StageMemberSettings = { disableInvitedNotice: false }
  ): Promise<void> {
    await this.updateStageMode(mode);
    const member: StageMember = {
      id: memberId,
      joinedAt: Date.now(),
      disableInvitedNotice: options.disableInvitedNotice ?? false,
    };
    this.state.members[memberId] = member;
    // TODO(jialin): batch update
    await this.stageMembersRef.update({ [memberId]: member as never });
    await this.deps.updateParticipant(memberId, {
      onStage: true,
      onStageMuted: false,
    });
    this.log.info('added member to stage', { member, mode });
  }

  async leave(memberId: MemberId): Promise<void> {
    delete this.state.members[memberId];
    // TODO(jialin): batch update
    const childRef = this.stageMembersRef.child(memberId);
    await childRef.remove();
    await this.deps.updateParticipant(memberId, {
      onStage: false,
      onStageMuted: false,
    });
    this.log.info('removed member from stage', { memberId });
  }

  async onStageMute(memberId: MemberId): Promise<void> {
    await this.deps.updateParticipant(memberId, { onStageMuted: true });
    this.log.info('mute member from stage', { memberId });
  }

  async onStageUnmute(memberId: MemberId): Promise<void> {
    await this.deps.updateParticipant(memberId, { onStageMuted: false });
    this.log.info('unmute member from stage', { memberId });
  }

  async leaveAll(excludeMemberIds?: Nullable<MemberId>[]): Promise<void> {
    const toBeRemoved: MemberId[] = [];
    for (const key in this.state.members) {
      if (excludeMemberIds?.includes(key)) continue;
      toBeRemoved.push(key);
    }

    const promises: Promise<void>[] = [];
    toBeRemoved.forEach((memberId: MemberId) => {
      promises.push(this.leave(memberId));
    });
    try {
      await Promise.all(promises);
      this.log.info('stage cleaned', { removed: toBeRemoved });
    } catch (error) {
      this.log.error('stage clean failed', err2s(error));
    }
  }

  async updateNetworkQuaility(
    memberId: string,
    uplinkNetworkQuality: number,
    downlinkNetworkQuality: number
  ): Promise<void> {
    const member = this.state.members[memberId];
    if (!member) return;
    if (
      member.downlinkNetworkQuality === downlinkNetworkQuality &&
      member.uplinkNetworkQuality === uplinkNetworkQuality
    ) {
      return;
    }
    member.uplinkNetworkQuality = uplinkNetworkQuality;
    member.downlinkNetworkQuality = downlinkNetworkQuality;
    this.state.members[memberId] = member;
    const childRef = this.stageMembersRef.child(memberId);
    // only update if member exists on Firebase
    await childRef.transaction((v) => {
      if (!v) return; // v is null if it's never been set
      return { ...v, uplinkNetworkQuality, downlinkNetworkQuality };
    });
  }

  updateStreamState(
    memberId: MemberId,
    newState: Partial<RemoteStreamState>
  ): void {
    if (!this.state.streamStates[memberId])
      this.state.streamStates[memberId] = {};
    const oldState = this.state.streamStates[memberId];
    this.state.streamStates[memberId] = { ...oldState, ...newState };
  }

  async replace(from: MemberId, to: MemberId): Promise<void> {
    await this.leave(from);
    await this.join(to, this.state.stage.mode);
    this.log.info('replace stage member', { from, to });
  }
}

type StageContext = {
  state: State;
  api: StageControlAPI;
};

type ReadonlyStageContext = {
  state: Readonly<State>;
  api: Readonly<StageControlAPI>;
};

const Context = React.createContext<StageContext | null>(null);

function useInternalStageContext(): StageContext {
  const ctx = useContext(Context);
  if (!ctx) {
    throw new Error('StageContext is not in the tree!');
  }
  return ctx;
}

export function useStageContext(): ReadonlyStageContext {
  return useInternalStageContext();
}

export function useStageControlAPI(): StageControlAPI {
  return useInternalStageContext().api;
}

export function StageProvider(props: {
  venueId: string;
  firebaseService: FirebaseService;
  children?: ReactNode;
}): JSX.Element {
  const { venueId, firebaseService } = props;
  const state = useInstance(() => proxy<State>(initialState()));
  const updateParticipant = useUpdateParticipant();
  const api = useMemo(
    () =>
      new StageControlAPI(
        venueId,
        state,
        firebaseService,
        logger.scoped('stage'),
        { updateParticipant }
      ),
    [firebaseService, state, updateParticipant, venueId]
  );

  // reset
  useEffect(() => {
    const unsub = devtools(state, { name: 'Stage Store' });
    return () => {
      api.reset();
      unsub?.();
    };
  }, [api, state]);

  const ctx: StageContext = useMemo(
    () => ({
      state,
      api,
    }),
    [api, state]
  );

  return <Context.Provider value={ctx}>{props.children}</Context.Provider>;
}
