import 'firebase/database';

import { proxy } from 'comlink';
import type firebase from 'firebase/app';
import { subscribe } from 'valtio/vanilla';

import { RTDBServerValueTIMESTAMP } from '@lp-lib/firebase-typesafe';
import {
  type BaseDetailScore,
  type Block,
  type BlockDetailScore,
  type BlockType,
  calculateScore,
  type CreativePromptBlockAnswerData,
  type GameSessionDetailScore,
  GameSessionUtil,
  type QuestionBlockAnswerData,
  type QuestionBlockAnswerGrade,
  type QuestionBlockDetailScore,
  type RapidBlockAnswersData,
  type TeamDataList,
  type TeamScoreboardData,
} from '@lp-lib/game';

import logger from '../../../../logger/logger';
import { store as reduxStore } from '../../../../store/configureStore';
import { type GamePack } from '../../../../types/game';
import { type TeamId } from '../../../../types/team';
import { required } from '../../../../utils/common';
import { uncheckedIndexAccess_UNSAFE } from '../../../../utils/uncheckedIndexAccess_UNSAFE';
import { firebaseService } from '../../../Firebase';
import { makeFirebaseSafe } from '../../../Firebase/makeFirebaseSafe';
import { increment } from '../../../Firebase/utils';
import { BlockKnifeUtils } from '../../Blocks/Shared';
import { getLocalGamePlayStore } from '../../GamePlayStore';
import { blockTitleAnimationHalfDuration } from '../../OndPhaseRunner/blockTitleAnimationHalfDuration';
import { OndVersionChecks } from '../../OndVersionChecks';
import {
  type BlockSession,
  type FirebaseRefs,
  type GameSession,
  type GameSessionControls,
  type GameSessionOndState,
  gameSessionStore,
  type GameSessionTeamData,
  initialState,
  type LocalTimers,
  type VideoPlayback,
} from '../gameSessionStore';
import { timersWorker } from '../timer';
import { log } from './shared';

const gameaudit = logger.scoped('game-system-audit');

export const isTimerRunning = async (): Promise<boolean> => {
  return timersWorker.isRunning('submission');
};

const getNextCountingStatus = (): number | null => {
  const { blockSession, status } = gameSessionStore.session;

  if (!blockSession?.block || !status) return null;
  return BlockKnifeUtils.NextCountingStatus(blockSession.block, status);
};

export const getDataPath = (
  refName: keyof FirebaseRefs,
  myTeamId?: string | null
): string => {
  const { venueId, session } = gameSessionStore;
  const blockId = session.blockSession?.block?.id ?? null;

  const pathMap = {
    session: `game-session-core/${venueId}`,
    controls: `game-session-controls/${venueId}`,
    ondState: `game-session-state/${venueId}`,
    detailScores: `game-session-scores/${venueId}`,
    scoreSummary: `game-session-score-summary/${venueId}`,
    teamData:
      blockId && myTeamId
        ? `game-session-team-data/${venueId}/${blockId}/${myTeamId}`
        : null,
    playerData: `game-session-player-data/${venueId}`,
  };

  const path = pathMap[refName];

  if (!path) {
    throw new Error(`Path not found: GameSession.getDataPath.${refName}`);
  }

  return path;
};

export const resetBlock = async (
  blockId: string,
  cb?: () => void
): Promise<void> => {
  const {
    refs,
    venueId,
    session: { blockSession, isLive },
  } = gameSessionStore;

  await gameSessionStore.gameSessionActionsSignalManager.fire(
    'reset-block',
    'before'
  );

  resetTimer('submission');

  const currentBlock = blockSession?.block ?? undefined;

  if (!refs.session) throw new Error('GameSession.resetBlock.refs.session');
  if (!isLive) {
    await refs.session.update({
      status: 0,
      blockSession: null,
      statusChangedAt: -1,
      blockTitleTransition: null,
    });
  } else {
    await refs.session.update({
      status: 0,
      statusChangedAt: Date.now(),
      blockTitleTransition: null,
    });
  }

  if (!refs.controls) throw new Error('GameSession.resetBlock.refs.controls');
  const endedBlockIds = gameSessionStore.controls.endedBlockIds || '';
  const endedBlockIdSet = new Set(endedBlockIds.split(',') || []);
  if (endedBlockIdSet.has(blockId)) {
    endedBlockIdSet.delete(blockId);
    await refs.controls.update({
      endedBlockIds: Array.from(endedBlockIdSet).join(','),
    });
  }

  if (!refs.detailScores)
    throw new Error('GameSession.resetBlock.refs.detailsScores');
  await refs.detailScores.child(`${blockId}`).remove();

  // Remove players data
  await firebaseService
    .prefixedRef(`game-session-team-data/${venueId}/${blockId}`)
    .remove();
  await firebaseService
    .prefixedRef(`game-session-player-data/${venueId}/${blockId}`)
    .remove();

  cb && cb();

  if (isLive) {
    currentBlock && (await loadGameSession({ blockToPreload: currentBlock }));
  }

  await gameSessionStore.gameSessionActionsSignalManager.fire(
    'reset-block',
    'after'
  );
};

export const resetPlayerData = async (): Promise<void> => {
  if (!gameSessionStore.isController) return;

  const {
    refs: { detailScores, scoreSummary },
    venueId,
  } = gameSessionStore;

  await firebaseService
    .prefixedRef(`game-session-team-data/${venueId}`)
    .remove();
  await firebaseService
    .prefixedRef(`game-session-player-data/${venueId}`)
    .remove();
  detailScores && (await detailScores.remove());
  scoreSummary && (await scoreSummary.remove());
};

export const reset = async (params?: {
  endSession?: boolean;
  force?: boolean;
  retainPreloadBlock?: boolean;
  retainGamePack?: boolean;
}): Promise<void> => {
  const {
    refs,
    session: { isLive, blockSession },
    terminateSession,
  } = gameSessionStore;
  const {
    endSession = false,
    force = false,
    retainPreloadBlock = true,
    retainGamePack = false,
  } = { ...params };

  if (!gameSessionStore.isController && !force) return;

  await gameSessionStore.gameSessionActionsSignalManager.fire(
    'reset',
    'before'
  );

  const loadedGamePack = retainGamePack
    ? gameSessionStore.session.gamePackId
    : null;
  const preloadBlock = blockSession?.block ?? undefined;

  // Clean up timers
  await resetTimer('submission');

  // Clean up subscribed refs.
  const promises = Object.keys(refs).map(async (key) => {
    const firebaseRef = uncheckedIndexAccess_UNSAFE(gameSessionStore.refs)[
      key
    ] as firebase.database.Reference | null;
    if (firebaseRef) {
      await firebaseRef.onDisconnect().cancel();
      await firebaseRef.remove();
    }
  });

  await Promise.all(promises);

  // Reset back to initial state
  const {
    refs: _0,
    // **** BEGIN KEYS THAT NEED MANUAL RESET ***
    // - Ensure these have something below that handles "resetting" (reinit,
    //   destroy, nulling, etc) them, if needed.
    venueId,
    isController,
    initialized,
    sessionStatusHookManager,
    gameSessionActionsSignalManager,
    // **** END KEYS THAT NEED MANUAL RESET ***
    ...resetObj
  } = initialState;
  Object.keys(resetObj).forEach((key) => {
    uncheckedIndexAccess_UNSAFE(gameSessionStore)[key] =
      uncheckedIndexAccess_UNSAFE(resetObj)[key];
  });

  // reload game session
  {
    const sessionRef = firebaseService.prefixedRef<GameSession>(
      getDataPath('session')
    );
    sessionRef.update({ isLive: isLive ?? false });
    // Re-init game session
    await loadGameSession({
      blockToPreload: retainPreloadBlock ? preloadBlock : undefined,
      gamePackToPreload: loadedGamePack,
    });
  }

  if ((!isLive || endSession) && terminateSession) {
    await terminateSession();
  }

  await resetPlayerData();
  await gameSessionStore.gameSessionActionsSignalManager.fire('reset', 'after');
};

export const resetTimer = async (key: keyof LocalTimers): Promise<void> => {
  log.debug('reset timer', { key });
  gameSessionStore.timers[key] = 0;
  await timersWorker.clear(key);
};

export const setTimer = (key: keyof LocalTimers, timer: number): void => {
  gameSessionStore.timers[key] = timer;
};

/**
 * NOTE: This is misleadingly named. All this does is copy the currently
 * _locally_ loaded gameLike ID into the game-session ref. It does not actually
 * "load" the gamepack.
 */
export const loadGameSession = async (
  opts: {
    blockToPreload?: Block;
    gamePackToPreload?: string | null;
  } = {}
): Promise<void> => {
  const store = getLocalGamePlayStore();
  const gameLike = store.getLoadedGameLike();

  const name = gameLike?.name;

  // Note(Jialin): We intentionally don't use refs.session and refs.controls here
  // because the loadGameSession(reset) can be called from organizer in the case the
  // cloud controller is not available and some refs are inited for controller only.
  const sessionRef = firebaseService.prefixedRef<GameSession>(
    getDataPath('session')
  );
  const controlsRef = firebaseService.prefixedRef<GameSessionControls>(
    getDataPath('controls')
  );

  const hooks = gameSessionStore.sessionStatusHookManager.getHooks(
    opts?.blockToPreload?.id,
    0
  );

  hooks.forEach((h) => h.before?.());

  await sessionRef.update(
    makeFirebaseSafe({
      blockSession: opts?.blockToPreload
        ? { block: opts.blockToPreload }
        : null,
      gamePackId:
        opts?.gamePackToPreload ??
        (gameLike?.type === 'gamePack' ? gameLike.id : null),
      name: name ?? null,
      status: 0,
      statusChangedAt: Date.now(),
    })
  );

  await controlsRef.update({ isScoreboardShowed: false });

  hooks.forEach((h) => h.after?.());
};

export const setGamePackInSession = async (
  gamePack: GamePack
): Promise<void> => {
  const { refs } = gameSessionStore;

  refs.session?.update({
    gamePackId: gamePack.id,
    name: gamePack.name,
  });
};

export const present = async (block: Block, cb?: () => void): Promise<void> => {
  const {
    refs,
    session: { blockSession },
    sessionStatusHookManager,
  } = gameSessionStore;

  const sessionRef = required(refs.session, 'sessionRef');
  const controlsRef = required(refs.controls, 'controlsRef');

  const targetStatus = GameSessionUtil.StatusMapFor(block.type)?.intro;
  if (!targetStatus) {
    log.error(
      `GameSession.present.targetStatus`,
      new Error('targetStatus not found')
    );
    return;
  }

  const sessionStatusHooks = sessionStatusHookManager.getHooks(
    block.id,
    targetStatus
  );

  await Promise.all(
    sessionStatusHooks.map((h) => {
      return h.before ? h.before() : Promise.resolve();
    })
  );

  await timersWorker.clear('submission');

  const updates: Partial<GameSession> = {
    status: targetStatus,
    statusChangedAt: Date.now(),
    allTeamsFinishedTransition: null,
  };

  if (!blockSession || blockSession.block?.id !== block.id) {
    updates.blockSession = { block };
  }

  try {
    await sessionRef.update(updates);
    await controlsRef.update({ isScoreboardShowed: false });

    await Promise.all(
      sessionStatusHooks.map((h) => {
        return h.after ? h.after() : Promise.resolve();
      })
    );
  } catch (err) {
    log.error('GameSession.present.update', err);
  }

  cb && cb();
};

/**
 * The rough way this works:
 * - PhaseRunner calls `trigger()` (this function)
 * - trigger maybe writes "running" to blockTitleTransition
 * - if it writes, it waits until the value changes to "ended"
 * - the _animation_ on the controller client calls `endBlockTitleAnim` to
 *   trigger "ended"
 * - PhaseRunner has been continuing as normal, not waiting
 */
export const triggerBlockTitleAnim = async (
  block: Block | null
): Promise<void> => {
  const durationMs = blockTitleAnimationHalfDuration(block) * 2;
  const title = block?.fields.title;

  // TODO(drew): may need to account for recording mode version, too. In V1, no
  // block title should be played, but in V3 it will be. Somewhat an edge case
  // since it's unlikely Recorded blocks will use Block Titles.
  const playbackVersion = gameSessionStore.ondState?.playbackVersion;
  if (
    (!gameSessionStore.session.isLive &&
      !OndVersionChecks(playbackVersion).ondAllowBlockTitles) ||
    // Do not even write if there is no title defined
    !title ||
    !durationMs ||
    gameSessionStore.session.blockTitleTransition?.state === 'running'
  )
    return;

  log.info('triggering block title', {
    blockId: block.id,
    title,
    durationMs,
  });

  // Note: setup the store sub _before_ writing, in case the write succeeds
  // within a single cycle and is directly set to `ended`. It will cause this to
  // hang indefinitely.
  const p = new Promise<void>((resolve) => {
    const unsub = subscribe(
      gameSessionStore,
      () => {
        if (gameSessionStore.session.blockTitleTransition?.state === 'ended') {
          unsub();
          resolve();
        }
      },
      true
    );
  });

  // Note: we send in durationMs instead of relying on the code-configured
  // values because we may want the ability to change it per a live vs ond game,
  // which would require centralized config.
  const sessionRef = required(gameSessionStore.refs.session, 'sessionRef');
  await sessionRef.update({
    blockTitleTransition: {
      state: 'running',
      durationMs,
      extra: {
        title,
        displaysPointsMultiplier:
          BlockKnifeUtils.DisplaysPointsMultiplier(block),
      },
    },
  });

  // Wait until the animation notifies that it is finished.
  await p;

  await sessionRef.update({
    blockTitleTransition: null,
  });
};

export const endBlockTitleAnim = async (): Promise<void> => {
  const sessionRef = required(gameSessionStore.refs.session, 'sessionRef');
  await sessionRef.update({
    blockTitleTransition: { state: 'ended', durationMs: 0 },
  });
};

export const triggerAllTeamsFinishedAnim = async (): Promise<void> => {
  const durationMs = 3000;
  if (gameSessionStore.session.allTeamsFinishedTransition?.state === 'running')
    return;

  // Note: setup the store sub _before_ writing, in case the write succeeds
  // within a single cycle and is directly set to `ended`. It will cause this to
  // hang indefinitely.
  const p = new Promise<void>((resolve) => {
    const unsub = subscribe(
      gameSessionStore,
      () => {
        if (
          gameSessionStore.session.allTeamsFinishedTransition?.state === 'ended'
        ) {
          unsub();
          resolve();
        }
      },
      true
    );
  });

  const sessionRef = required(gameSessionStore.refs.session, 'sessionRef');
  await sessionRef.update({
    allTeamsFinishedTransition: {
      state: 'running',
      durationMs,
    },
  });

  // Wait until the animation notifies that it is finished.
  await p;

  await sessionRef.update({
    allTeamsFinishedTransition: null,
  });
};

export const endAllTeamsFinishedAnim = async (): Promise<void> => {
  const sessionRef = required(gameSessionStore.refs.session, 'sessionRef');
  await sessionRef.update({
    allTeamsFinishedTransition: { state: 'ended', durationMs: 0 },
  });
};

export const next = async (options?: {
  afterUpdate?: () => Promise<void>;
  overrideNextStatus?: number | null;
}): Promise<void> => {
  const afterUpdate = options?.afterUpdate;
  const {
    refs,
    session: { blockSession, status },
    sessionStatusHookManager,
  } = gameSessionStore;

  const sessionRef = required(refs.session, 'sessionRef');
  const controlsRef = required(refs.controls, 'controlsRef');

  if (!status || !blockSession || !blockSession.block) return;

  const nextStatus = options?.overrideNextStatus ?? status + 1;

  const sessionStatusHooks = sessionStatusHookManager.getHooks(
    blockSession.block.id,
    nextStatus
  );

  await Promise.all(
    sessionStatusHooks.map((h) => {
      return h.before ? h.before() : Promise.resolve();
    })
  );

  if (
    nextStatus ===
    GameSessionUtil.StatusMapFor(blockSession.block.type)?.scoreboard
  ) {
    await controlsRef.update({
      isScoreboardShowed: true,
    });
  }

  try {
    await sessionRef.update({
      status: nextStatus,
      statusChangedAt: Date.now(),
    });
  } catch (err) {
    log.error(`GameSession.next.${nextStatus}`, err);
  }

  await Promise.all(
    sessionStatusHooks.map((h) => {
      return h.after ? h.after() : Promise.resolve();
    })
  );

  if (afterUpdate) {
    await afterUpdate();
  }
};

export const end = async (cb?: () => void): Promise<void> => {
  const {
    refs,
    session: { blockSession, status },
    controls: { endedBlockIds, isScoreboardShowed },
    detailScores,
    scoreSummary,
  } = gameSessionStore;

  const sessionRef = required(refs.session, 'sessionRef');
  const controlsRef = required(refs.controls, 'controlsRef');
  const scoreSummaryRef = required(refs.scoreSummary, 'scoreSummaryRef');

  if (!status || !blockSession || !blockSession.block) return cb && cb();

  const { id, type } = blockSession.block;

  try {
    if (detailScores && detailScores[id]) {
      await controlsRef.update({
        endedBlockIds: Array.from(
          new Set(endedBlockIds?.split(',') || []).add(id)
        ).join(','),
      });
    }

    await sessionRef.update({
      status: GameSessionUtil.StatusMapFor(type)?.end,
      statusChangedAt: Date.now(),
    });

    const updatedScoreSummary = Object.assign({}, scoreSummary);

    Object.keys(updatedScoreSummary).forEach((teamId) => {
      updatedScoreSummary[teamId].currentBlockScore = null;
      if (isScoreboardShowed) {
        updatedScoreSummary[teamId].prevScore =
          updatedScoreSummary[teamId].totalScore;
      }
    });

    await scoreSummaryRef.set(updatedScoreSummary);
  } catch (err) {
    log.error(`GameSession.end.update`, err);
  }

  cb && cb();
};

export const updateGameSession = async <K extends keyof GameSession>(
  key: K,
  value: GameSession[K],
  cb?: () => void
): Promise<void> => {
  const {
    refs: { session },
  } = gameSessionStore;

  if (!session) return;

  try {
    await session.update({ [key]: value });
  } catch (err) {
    log.error(`GameSession.updateSession.${key}`, err);
  } finally {
    cb && cb();
  }
};

export const setTimesup = async (): Promise<void> => {
  const { refs, isController } = gameSessionStore;

  if (!refs.session) return;

  gameSessionStore.timers.submission = 0;
  await timersWorker.clear('submission');
  const countingStatus = getNextCountingStatus();
  if (countingStatus === null) return;
  if (isController) {
    try {
      await refs.session.update({
        status: countingStatus + 1,
        statusChangedAt: Date.now(),
      });
    } catch (err) {
      log.error('GameSession.countdown.timesup', err);
    }
  }
};

export const countdown = async (options?: {
  debug?: string;
  timerOverride?: number;
  isRecover?: boolean;
}): Promise<void> => {
  const { debug, timerOverride, isRecover } = { ...options };
  const {
    refs: { session: sessionRef },
    session: { blockSession, status },
    isController,
  } = gameSessionStore;

  log.debug(`countdown triggered from ${debug ?? 'unknown'}`);

  if (!sessionRef || !status || !blockSession?.block) {
    log.warn('countdown trigger failed, something is missing', {
      sessionRef: !!sessionRef,
      gameSessionStatus: status,
      sessionBlockId: blockSession?.block?.id,
    });
    return;
  }

  const countingStatus = getNextCountingStatus();

  if (
    countingStatus === null ||
    status > countingStatus ||
    (isController && !isRecover && status === countingStatus) ||
    (!isController && !isRecover && status < countingStatus) // no counting down for audience if it's not counting status
  )
    return;

  await timersWorker.clear('submission');

  if (timerOverride && isRecover) {
    gameSessionStore.timers.submission = timerOverride;
  }

  if (gameSessionStore.timers.submission) {
    await timersWorker.start(
      'submission',
      proxy(async (timer: number) => {
        gameSessionStore.timers.submission = timer;
        if (gameSessionStore.timers.submission <= 0) {
          await setTimesup();
        }
      }),
      gameSessionStore.timers.submission
    );
  }

  if (!isRecover && isController) {
    try {
      await sessionRef.update({
        status: countingStatus,
        statusChangedAt: Date.now(),
      });
    } catch (err) {
      log.error('GameSession.countdown.counting', err);
    }
  }
};

/**
 * This is a temporary workaround, we should fix the logic once the team gets aligned
 * @param options
 * @returns
 */
export const countdownV2 = async (options?: {
  debug?: string;
  startTimeWorker?: boolean;
  flushCountingStatus?: boolean;
  triggerTimesup?: boolean;
}): Promise<void> => {
  const opts = Object.assign({}, options);
  const {
    refs: { session: sessionRef },
    session: { blockSession, status },
  } = gameSessionStore;

  log.debug(`countdownV2 triggered from ${opts.debug ?? 'unknown'}`);

  if (!sessionRef || !status || !blockSession?.block) {
    log.warn('countdown trigger failed, something is missing', {
      sessionRef: !!sessionRef,
      gameSessionStatus: status,
      sessionBlockId: blockSession?.block?.id,
    });
    return;
  }

  const countingStatus = getNextCountingStatus();

  if (countingStatus === null || status > countingStatus) {
    log.warn('countdown trigger failed, countingStatus mismatch', {
      countingStatus: countingStatus,
      gameSessionStatus: status,
    });
    return;
  }

  if (gameSessionStore.timers.submission && opts.startTimeWorker) {
    log.info('start submission timer worker', {
      debug: opts.debug ?? 'unknown',
      timer: gameSessionStore.timers.submission,
      startTimeWorker: !!opts.startTimeWorker,
      flushCountingStatus: !!opts.flushCountingStatus,
      triggerTimesup: !!opts.triggerTimesup,
      blockId: blockSession.block.id,
      sessionStatus: status,
    });
    await timersWorker.clear('submission');
    await timersWorker.start(
      'submission',
      proxy(async (timer: number) => {
        gameSessionStore.timers.submission = timer;
        if (gameSessionStore.timers.submission <= 0 && opts.triggerTimesup) {
          await setTimesup();
        }
      }),
      gameSessionStore.timers.submission
    );
  }

  if (opts.flushCountingStatus) {
    try {
      await sessionRef.update({
        status: countingStatus,
        statusChangedAt: Date.now(),
      });
    } catch (err) {
      log.error('GameSession.countdown.counting', err);
    }
  }
};

// Refresh game session block data. Same blockId only.
export const refreshBlock = async (block: Block): Promise<void> => {
  const {
    refs: { session },
    session: { blockSession },
  } = gameSessionStore;

  if (!session) {
    log.error(
      `GameSession.refreshBlock.refs`,
      new Error('Missing session ref')
    );
    return;
  }

  if (
    !blockSession ||
    !blockSession.block ||
    blockSession.block.id !== block.id
  )
    return;

  await session.update({
    blockSession: { block },
  });
};

export async function grade<
  B extends Block,
  T extends QuestionBlockDetailScore
>(
  grade: QuestionBlockAnswerGrade,
  teamIds: string[],
  blockType: BlockType,
  getDecreasingPointsTimer: (block: B) => boolean,
  getTimerLimit: (block: B) => number,
  getPoints: (block: B) => number,
  startDescendingImmediately: (block: B) => boolean
): Promise<void> {
  const {
    session: { blockSession },
    refs,
    detailScores,
  } = gameSessionStore;

  const block = blockSession?.block as B | null | undefined;

  gameaudit.info('host: grade chosen', {
    grade,
    teamIds,
    block: block ? BlockKnifeUtils.SummaryText(block) : null,
  });

  if (!block || !refs.detailScores || block.type !== blockType || !detailScores)
    return;

  try {
    if (teamIds.length === 0) return;

    const blockDetailScores = Object.assign(
      {},
      detailScores[block.id]
    ) as TeamDataList<T>;
    for (const teamId of teamIds) {
      const data = blockDetailScores[teamId] as T;

      const score = calculateScore(
        grade,
        getDecreasingPointsTimer(block),
        data?.timerWhenSubmitted ?? 0,
        getTimerLimit(block),
        getPoints(block),
        startDescendingImmediately(block)
      );

      blockDetailScores[teamId].grade = grade;
      blockDetailScores[teamId].score = score;
      blockDetailScores[teamId].scoredAt = RTDBServerValueTIMESTAMP;
    }

    await refs.detailScores.child(`/${block.id}`).update(blockDetailScores);

    gameaudit.info('host: grades written', {
      grade,
      teamIds,
      block: block ? BlockKnifeUtils.SummaryText(block) : null,
      blockDetailScores: JSON.parse(JSON.stringify(blockDetailScores)),
    });
  } catch (err) {
    log.error('GameSession.grade.update', err);
  }
}

export const adjustScore = async (
  teamId: string,
  override: number,
  loadedBlockId?: string | null
): Promise<void> => {
  const {
    session: { blockSession },
    detailScores,
    refs,
  } = gameSessionStore;
  if (!refs.detailScores) return;

  const blockId = blockSession?.block?.id || loadedBlockId || null;

  if (!blockId) {
    log.error('GameSession.adjustPoints.blockId', new Error('Missing blockId'));
    return;
  }

  const points = override >= 0 ? override : 0;
  const ref = refs.detailScores.child(`/${blockId}/${teamId}`);
  if (!(await ref.get()).exists()) {
    try {
      await ref.set({
        score: points,
        submittedAt: Date.now(),
      });
      gameaudit.info('host: adjusted score from nothing', {
        teamId,
        block: blockSession?.block
          ? BlockKnifeUtils.SummaryText(blockSession.block)
          : null,
        nextScore: points,
      });
    } catch (err) {
      log.error('GameSession.adjustScore.set', err);
    }
    return;
  }

  const targetScore = detailScores?.[blockId]?.[teamId]?.score ?? null;
  const update =
    targetScore !== null ? { score: points } : { scoreOverride: points };

  try {
    await ref.update(update);
    gameaudit.info('host: adjusted score from existing', {
      teamId,
      block: blockSession?.block
        ? BlockKnifeUtils.SummaryText(blockSession.block)
        : null,
      existing: targetScore,
      nextScore: update,
    });
  } catch (err) {
    log.error('GameSession.adjustScore.update', err);
  }
};

export const replayVideo = async (
  reset?: boolean,
  cb?: () => void
): Promise<void> => {
  const { refs } = gameSessionStore;

  const gameSessionRef = required(refs.session, 'gameSessionRef');

  const blockSessionRef = gameSessionRef.child<'blockSession', BlockSession>(
    'blockSession'
  );
  await blockSessionRef.update({ videoReplayAt: reset ? null : Date.now() });

  cb && cb();
};

export const updateIsRecording = async (
  isRecording: boolean
): Promise<void> => {
  const { refs, session } = gameSessionStore;

  if (session.isRecording === isRecording) return;

  const gameSessionRef = required(refs.session, 'gameSessionRef');
  await gameSessionRef.update({
    isRecording,
  });
};

// NOTE(drew): After some investigation, this is really only used by the music
// player to know whether it should play or not, and by gameplayvideo to know
// whether it should show the loading screen!
export const updateGameVideoPlaybackState = async (
  videoPlayback: VideoPlayback | null
): Promise<void> => {
  const { refs } = gameSessionStore;

  const gameSessionRef = required(refs.session, 'gameSessionRef');

  try {
    const blockSessionRef = gameSessionRef.child<'blockSession', BlockSession>(
      'blockSession'
    );
    await blockSessionRef.update({ videoPlayback });
  } catch (err) {
    log.error('GameSession.updateVideoPlayback.update', err);
  }
};

export const switchPlayMode = async (targetMode: boolean): Promise<void> => {
  const { refs, session } = gameSessionStore;

  if (session.isLive === targetMode) return;

  const gameSessionRef = required(refs.session, 'gameSessionRef');
  await gameSessionRef.update({
    isLive: targetMode,
  });
};

export const pauseOndSubmissionTimer = async (): Promise<void> => {
  await timersWorker.clear('submission');
};

export const hasPlayerData = (blockId: string | null | undefined): boolean => {
  if (!blockId) return false;

  const { detailScores } = gameSessionStore;
  return !!(
    detailScores &&
    detailScores[blockId] &&
    Object.keys(detailScores[blockId]).length > 0
  );
};

export const getScoreboardData = (
  scoreSummary = gameSessionStore.scoreSummary
): TeamScoreboardData[] => {
  const teams = reduxStore.getState().team.teams;

  const results: TeamScoreboardData[] = [];
  const createdTimestamp: number = Date.now();

  if (scoreSummary) {
    Object.keys(scoreSummary).forEach((teamId) => {
      const scoresData = scoreSummary[teamId];
      const totalScore = scoresData.totalScore;

      if (scoresData && totalScore !== null) {
        const team = teams[teamId];

        results.push({
          score: totalScore,
          currentScore: totalScore - scoresData.prevScore || 0,
          teamId,
          teamName: team?.name ?? '',
          createdTimestamp: createdTimestamp,
        });
      }
    });

    const applyRanks = (list: TeamScoreboardData[]) => {
      let rank = 0;
      let prev = -1;
      for (const s of list) {
        if (s.score === prev) {
          s.rank = rank;
        } else {
          rank++;
          prev = s.score;
          s.rank = rank;
        }
      }
    };

    applyRanks(results.sort((a, b) => b.score - a.score));
  }

  return results;
};

export const fetchDetailScoresData = async (
  blockId: string
): Promise<TeamDataList<BlockDetailScore> | null> => {
  const ref = firebaseService
    .prefixedRef<GameSessionDetailScore | null>(getDataPath('detailScores'))
    .child(`${blockId}`);

  const data = (await ref.get()).val();

  return data ?? null;
};

export const fetchAllTeamsData = async (
  blockId: string
): Promise<TeamDataList<
  GameSessionTeamData<
    | RapidBlockAnswersData
    | QuestionBlockAnswerData
    | CreativePromptBlockAnswerData
  >
> | null> => {
  const { venueId } = gameSessionStore;

  if (!blockId) return null;

  const allTeamsDataRef = firebaseService.prefixedRef<
    TeamDataList<
      GameSessionTeamData<
        | RapidBlockAnswersData
        | QuestionBlockAnswerData
        | CreativePromptBlockAnswerData
      >
    >
  >(`game-session-team-data/${venueId}/${blockId}`);
  const data = (await allTeamsDataRef.get()).val();

  return data;
};

export async function updateBlockDetailScore<
  T extends BlockDetailScore = BaseDetailScore
>(teamId: TeamId, detailScore: T): Promise<void> {
  const { venueId, session } = gameSessionStore;
  const blockId = session.blockSession?.block?.id;
  if (!blockId) return;
  const detailScoreRef = firebaseService.prefixedRef<T>(
    `game-session-scores/${venueId}/${blockId}/${teamId}`
  );
  await detailScoreRef.set({
    ...detailScore,
    scoredAt: RTDBServerValueTIMESTAMP,
  });
}

export async function incrementBlockDetailScore(
  teamId: TeamId,
  delta: number
): Promise<void> {
  const { venueId, session } = gameSessionStore;
  const blockId = session.blockSession?.block?.id;
  if (!blockId) return;
  const detailScoreRef = firebaseService.prefixedRef<BaseDetailScore>(
    `game-session-scores/${venueId}/${blockId}/${teamId}`
  );
  await detailScoreRef.update({
    score: increment(delta),
    scoredAt: RTDBServerValueTIMESTAMP,
  });
}

export async function pauseVideoMixerAndGameRunner(): Promise<void> {
  const {
    videoMixers: { ondHostVideo },
  } = gameSessionStore;
  const videoMixer = ondHostVideo;

  videoMixer?.pause();
  await gameSessionStore.ondGameRunner?.destroy();
}

export async function resetOnDPreparing(): Promise<void> {
  const ondStateRef = firebaseService.prefixedSafeRef<GameSessionOndState>(
    getDataPath('ondState')
  );
  // when a host/org recovers an async game session, we treat the `configuring` state
  // like the empty state. since configuration is driven by the host/org and cannot be
  // recovered, they host/org will need to start the game again.
  // when the game session is initialized, we've not initialized the async state.
  const snapshot = await ondStateRef.get();
  if (snapshot.val()?.state === 'preparing') {
    await ondStateRef.remove();
  }
}
