import { copy } from '../../utils/common';
import { type ContentRegistryItemBase } from './types';

export class ContentRegistry<T, Prefix extends string> {
  private db = new Map<`${Prefix}-${string}`, ContentRegistryItemBase & T>();

  constructor(private prefix: Prefix) {}

  insert(idSuffix: string, name: string, item: T): void {
    const id = `${this.prefix}-${idSuffix}` as const;
    if (this.db.has(id))
      throw new Error(`Duplicate ID for '${this.prefix}' registry: ${id}`);
    this.db.set(id, {
      id,
      name,
      ...item,
    });
  }

  dump(): (ContentRegistryItemBase & T)[] {
    return Array.from(this.db.values());
  }

  select(
    id: `${Prefix}-${string}` | string | null | undefined,
    returnCopy = true
  ): (ContentRegistryItemBase & T) | null {
    if (!id) return null;
    const item = this.db.get(id as `${Prefix}-${string}`);
    return item ? (returnCopy ? copy(item) : item) : null;
  }

  selectOrThrow(
    id: `${Prefix}-${string}` | string | null | undefined
  ): ContentRegistryItemBase & T {
    const item = this.select(id);
    if (!item) throw new Error(`Content not found with id: ${id}`);
    return item;
  }

  query(
    comparator: (a: ContentRegistryItemBase & T) => boolean
  ): (ContentRegistryItemBase & T) | null {
    for (const [, item] of this.db) {
      if (comparator(item)) return item;
    }
    return null;
  }

  first(): ContentRegistryItemBase & T {
    const first = this.db.values().next();
    if (!first.value) throw new Error('Registry is empty!');
    return first.value;
  }
}
