import debounce from 'lodash/debounce';
import { useState } from 'react';
import { type SingleValue } from 'react-select';
import AsyncSelect from 'react-select/async';

import { type SearchSlackChannelsParams } from '@lp-lib/api-service-client/public';

import { useInstance } from '../../hooks/useInstance';
import { apiService } from '../../services/api-service';
import { type SlackChannel, type SlackChannelType } from '../../types/slack';
import { buildReactSelectStyles } from '../../utils/react-select';

async function search(
  params: SearchSlackChannelsParams,
  callback: (channels: SlackChannel[]) => void
) {
  const resp = await apiService.slack.searchChannels(params);
  callback(resp.data.channels);
}

const debounceSearch = debounce(search, 250);

type ChannelPickerProps = {
  orgId: string;
  types: SlackChannelType | SlackChannelType[];
  onChange?: (value: SlackChannel) => void;
  defaultChannel?: SlackChannel;
  placeholder?: string;
  error?: unknown;
  disabled?: boolean;
};

export function SlackChannelPicker(
  props: ChannelPickerProps
): JSX.Element | null {
  const {
    orgId,
    types,
    onChange,
    defaultChannel,
    placeholder = '#select-existing-slack-channel',
    error,
    disabled,
  } = props;

  const styles = useInstance(() =>
    buildReactSelectStyles<SlackChannel>({
      override: {
        control: { height: '100%' },
      },
    })
  );
  const [selected, setSelected] = useState<SingleValue<SlackChannel> | null>(
    null
  );

  // NOTE: DO NOT return promise here. I do not know why but should be related
  // to the implementation of react-select. you can reproduce by the steps:
  // - type 'general', it show general channel as expected.
  // - type anything else, it always show no channels matched.
  const loadOptions = (
    q: string,
    callback: (channels: SlackChannel[]) => void
  ): void => {
    const keyword = q.startsWith('#') ? q.substring(1) : q;
    debounceSearch(
      {
        orgId,
        keyword,
        types: types instanceof Array ? types.join(',') : types,
        size: 100,
      },
      callback
    );
  };

  const handleSingleChange = (option: SingleValue<SlackChannel>) => {
    setSelected(option);
    if (!onChange) return;
    if (option) {
      onChange(option);
    }
  };

  return (
    <AsyncSelect<SlackChannel, false>
      placeholder={placeholder}
      styles={styles}
      cacheOptions
      loadOptions={loadOptions}
      classNamePrefix='select-box-v2'
      className={`h-full m-0 ${error ? 'field-error px-0' : ''}`}
      value={selected}
      noOptionsMessage={(obj) => {
        if (!obj.inputValue) return 'Start typing to search';
        return 'No channels matched';
      }}
      defaultInputValue={defaultChannel?.name}
      defaultValue={defaultChannel}
      getOptionValue={(option) => option.id}
      getOptionLabel={(option) => `#${option.name}`}
      onChange={handleSingleChange}
      isDisabled={disabled}
    />
  );
}
