import { useCallback, useEffect, useMemo } from 'react';

import {
  type RandomizerBlock,
  RandomizerGameSessionStatus,
} from '@lp-lib/game';

import { useParticipantsAsArray } from '../../../Player';
import { useTeamRandomizerAPI } from '../../../TeamRandomizer';
import { useSessionStatusHookManager } from '../../hooks';
import { resetPlayerData } from '../../store/';
import { type GameControlProps } from '../Common/GameControl/types';
import { selectConfig } from './utils';

export function RandomizerBlockControl(
  props: GameControlProps<RandomizerBlock>
): JSX.Element | null {
  const { block } = props;

  const participants = useParticipantsAsArray({
    filters: ['status:connected', 'host:false', 'cohost:false', 'staff:false'],
  });

  const allClientIds = useMemo(
    () => participants.map((p) => p?.clientId),
    [participants]
  );

  const hookManager = useSessionStatusHookManager();
  const { randomize, cleanup } = useTeamRandomizerAPI();

  const afterAction = useCallback(async () => {
    if (!allClientIds || allClientIds.length === 0) return;

    const { teamSize, maxTeamSize } = selectConfig(
      block.fields,
      allClientIds.length
    );

    await randomize({
      step: 'randomize',
      targetTeamSize: teamSize,
      maxTeamSize,
      countdownSec: 0,
      icebreakerSec: block.fields.timer,
      notificationStyle: 'disabled',
      showIcebreakerTimer: false,
      showResults: true,
      showAnimation: true,
    });
  }, [allClientIds, block.fields, randomize]);

  useEffect(() => {
    const hook = {
      blockId: block.id,
      sessionStatus: RandomizerGameSessionStatus.STARTING,
      before: resetPlayerData,
      after: afterAction,
    };

    hookManager.register(hook);
    return () => {
      hookManager.unregister(hook);
    };
  }, [afterAction, hookManager, block.id]);

  useEffect(() => {
    return () => {
      cleanup();
    };
  }, [cleanup]);

  return null;
}
