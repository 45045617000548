import { getFeatureQueryParamArray } from '../../../../../hooks/useFeatureQueryParam';
import { getStaticAssetPath } from '../../../../../utils/assets';
import { type GamePlayMedia } from './types';

const oneStreamEnabled =
  getFeatureQueryParamArray('game-play-media') === 'one-stream';

const tempStaticUrl = getStaticAssetPath('images/TEMP_ond-stage-001_hd.jpg');

export function StageBgSingleFrame(props: {
  gamePlayMedia: GamePlayMedia | null;
}): JSX.Element | null {
  if (!oneStreamEnabled || !props.gamePlayMedia) return null;
  return (
    <div
      className='absolute w-full h-full top-0 left-0 bg-cover bg'
      style={{
        backgroundImage: `url(${tempStaticUrl})`,
        backgroundPositionY: 'center',
        backgroundPositionX: 'center',
      }}
    ></div>
  );
}
