import {
  useHasReceivedInitial,
  useIsLiveGamePlay,
  useOndGameState,
} from '../components/Game/hooks';
import { useIsStreamSessionAliveOrAborted } from '../components/Session';
import { VenueMode } from '../types/venue';

export function useVenueMode(): VenueMode {
  const IsStreamSessionAliveOrAborted = useIsStreamSessionAliveOrAborted();
  const isLiveGame = useIsLiveGamePlay();
  const ondState = useOndGameState();
  if (
    (isLiveGame && IsStreamSessionAliveOrAborted) ||
    (!isLiveGame && ondState !== null)
  )
    return VenueMode.Game;
  return VenueMode.Lobby;
}

/**
 * The default value for `isLive` is `false`, so the app generally defaults to
 * ond mode. But there are some places where (such as team autojoin behavior) we
 * need to know whether we have confirmed (aka received an initialized session
 * via firebase) what mode the venue is actually in, LIVE vs OND vs UNKNOWN.
 */
export function useReceivedVenueMode(): VenueMode | null {
  const canTrustSession = useHasReceivedInitial('session');
  const canTrustOndState = useHasReceivedInitial('ondState');
  const mode = useVenueMode();
  return canTrustSession && canTrustOndState ? mode : null;
}
