import { ref } from 'valtio';

import { type Block, type BlockActionWaitModeConfig } from '@lp-lib/game';

import { Emitter, type EmitterListener } from '../../../utils/emitter';
import { gameSessionStore } from '../store/gameSessionStore';

export type OndPhaseEvents = {
  'boot-play-intro': (
    introPrimaryMediaDurationSec: number,
    nextBlock: Block | null
  ) => void;
  'block-tick': (
    block: Block,
    blockProgressSec: number,
    blockEndingSec: number,
    getNextBlock: () => Block | null
  ) => void;
  'block-enter-wait-mode': (
    block: Block,
    nextBlock: Block | null,
    waitModeInfo: BlockActionWaitModeConfig
  ) => void;
};

export class OndPhaseEmitterUtils {
  static CreateVRefed(): Emitter<OndPhaseEvents> {
    return ref(new Emitter<OndPhaseEvents>());
  }
}
// TODO(drew): this should be deprecated in favor of the phase runner calling out to whatever needs to be notified
export function useOndPhaseEmitterListener(): EmitterListener<OndPhaseEvents> {
  return gameSessionStore.ondPhaseEmitter;
}
