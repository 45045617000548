export function csvToArray(text: string): string[][] {
  // https://stackoverflow.com/questions/8493195/
  let p = '';
  let row = [''];
  const ret = [row];
  let i = 0;
  let r = 0;
  let s = true;

  for (let idx = 0; idx < text.length; idx++) {
    let l = text[idx];
    if ('"' === l) {
      if (s && l === p) row[i] += l;
      s = !s;
    } else if (',' === l && s) {
      l = row[++i] = '';
    } else if ('\n' === l && s) {
      if ('\r' === p) row[i] = row[i].slice(0, -1);
      row = ret[++r] = [(l = '')];
      i = 0;
    } else row[i] += l;
    p = l;
  }
  return ret;
}

/**
 * @deprecated Use `createCSVFile` instead.
 */
export function createDownloadCSVFile(headers: string[]): string {
  const placeholder = Array(headers.length).fill('""').join(',');
  return URL.createObjectURL(
    new Blob([[headers.join(','), placeholder].join('\n')], {
      type: 'text/csv',
    })
  );
}

/**
 * Creates a csv file from the given rows. To include headers, add them as the first row. These will be escaped.
 * @param rows
 */
export function createCSVFile(rows: string[][]): string {
  const dataRows: string[] = [];
  for (const row of rows) {
    dataRows.push(row.map((v) => `"${v.replace(/"/g, '""')}"`).join(','));
  }

  return URL.createObjectURL(
    new Blob([dataRows.join('\n')], {
      type: 'text/csv',
    })
  );
}
