import React, { type ReactNode, useContext, useEffect, useMemo } from 'react';
import { proxy, useSnapshot } from 'valtio';
import { devtools } from 'valtio/utils';

import { type RemoteStreamStateV2 } from '../../../services/webrtc';
import { type MemberId } from '../../../types';
import { ValtioUtils } from '../../../utils/valtio';

type State = {
  streamStates: Record<MemberId, RemoteStreamStateV2>;
};

function initialState(): State {
  return {
    streamStates: {},
  };
}

class ControlAPI {
  constructor(private state: State) {}

  reset(): void {
    ValtioUtils.reset(this.state, initialState());
  }

  clearStreamState(): void {
    for (const key of Object.keys(this.state.streamStates)) {
      delete this.state.streamStates[key];
    }
  }

  updateStreamState(
    memberId: MemberId,
    newState: Partial<RemoteStreamStateV2>
  ): void {
    if (!this.state.streamStates[memberId])
      this.state.streamStates[memberId] = {};
    const oldState = this.state.streamStates[memberId];
    this.state.streamStates[memberId] = { ...oldState, ...newState };
  }
}

type RemoteStreamStateContext = {
  state: State;
  api: ControlAPI;
};

const Context = React.createContext<RemoteStreamStateContext | null>(null);

function useRemoteStreamStateContext(): RemoteStreamStateContext {
  const ctx = useContext(Context);
  if (!ctx) {
    throw new Error('RemoteStreamStateContext is not in the tree!');
  }
  return ctx;
}

export function useRemoteStreamStateAPI(): ControlAPI {
  return useRemoteStreamStateContext().api;
}

export function useSelectStreamState(
  memberId: Nullable<MemberId>
): RemoteStreamStateV2 | undefined {
  const ctx = useRemoteStreamStateContext();
  return useSnapshot(ctx.state).streamStates[memberId ?? ''];
}

export function RemoteStreamStateProvider(props: {
  children?: ReactNode;
}): JSX.Element {
  const ctx: RemoteStreamStateContext = useMemo(() => {
    const state = proxy<State>(initialState());
    const api = new ControlAPI(state);

    return {
      state,
      api,
    };
  }, []);

  // reset
  useEffect(() => {
    const unsub = devtools(ctx.state, {
      name: 'Team Remote Stream State Store',
    });
    return () => {
      ctx.api.reset();
      unsub?.();
    };
  }, [ctx.api, ctx.state]);

  return <Context.Provider value={ctx}>{props.children}</Context.Provider>;
}
