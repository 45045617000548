import { useMemo } from 'react';

import { type FirebaseSafeRead } from '@lp-lib/firebase-typesafe';
import {
  type BlockDetailScore,
  type GameSessionScoreSummary,
  type TeamDataList,
} from '@lp-lib/game';

import { getLogger } from '../../../logger/logger';
import { firebaseService } from '../../Firebase';
import { increment } from '../../Firebase/utils';

class ScoreSummarySyncer {
  private gameaudit = getLogger().scoped('game-system-audit');
  private log = getLogger().scoped('score-summary-syncer');
  private scoreCache = new Map<string, number>();
  constructor(
    readonly venueId: string,
    readonly sessionId: string,
    readonly svc = firebaseService
  ) {}

  watch(blockId: string) {
    const ref = this.svc.prefixedSafeRef<TeamDataList<BlockDetailScore>>(
      `game-session-scores/${this.venueId}/${blockId}`
    );
    ref.on('child_added', async (snap) => {
      const teamId = snap.key;
      const data = snap.val();
      if (!data || !teamId) return;
      try {
        const prevScore = this.getCachedScore(blockId, teamId);
        const currScore = data.score ?? 0;
        this.setCachedScore(blockId, teamId, currScore);
        await this.onDataUpdated(
          blockId,
          teamId,
          prevScore,
          currScore,
          'child_added'
        );
      } catch (err) {
        this.log.error('onDataUpdated (child_added) err', err);
      }
    });
    ref.on('child_changed', async (snap) => {
      const teamId = snap.key;
      const data = snap.val();
      if (!data || !teamId) return;
      try {
        const prevScore = this.getCachedScore(blockId, teamId);
        const currScore = data.score ?? 0;
        this.setCachedScore(blockId, teamId, currScore);
        await this.onDataUpdated(
          blockId,
          teamId,
          prevScore,
          currScore,
          'child_changed'
        );
      } catch (err) {
        this.log.error('onDataUpdated (child_changed) err', err);
      }
    });
    ref.on('child_removed', async (snap) => {
      const teamId = snap.key;
      const data = snap.val();
      if (!data || !teamId) return;
      try {
        await this.onDataRemoved(teamId, data);
      } catch (err) {
        this.log.error('onDataRemoved err', err);
      }
    });

    return () => {
      ref.off('child_added');
      ref.off('child_changed');
      ref.off('child_removed');
    };
  }

  // this only happens in the live game that the host can reset a completed block
  private async onDataRemoved(
    teamId: string,
    data: FirebaseSafeRead<BlockDetailScore>
  ) {
    const blockScore = data.score ?? null;
    if (blockScore === null) return;

    const summaryRef = this.svc.prefixedRef<GameSessionScoreSummary>(
      `game-session-score-summary/${this.venueId}/${teamId}`
    );

    const summarySnap = await summaryRef.get();
    if (!summarySnap.exists()) return;

    await summaryRef.update({
      totalScore: increment(-blockScore),
      currentBlockScore: null,
    });
  }

  private async onDataUpdated(
    blockId: string,
    teamId: string,
    prevScore: number,
    currScore: number,
    event: string
  ) {
    const delta = currScore - prevScore;

    this.gameaudit.info(`summarizing team detail score`, {
      blockId,
      venueId: this.venueId,
      teamId,
      prevScore,
      currScore,
      delta,
      event,
    });

    if (delta === 0) return;
    if (prevScore === currScore && currScore !== 0) return;
    const summaryRef = this.svc.prefixedRef<GameSessionScoreSummary>(
      `game-session-score-summary/${this.venueId}/${teamId}`
    );
    const summarySnap = await summaryRef.get();
    if (summarySnap.exists()) {
      try {
        const summary = {
          totalScore: increment(delta) as never,
          currentBlockScore: currScore,
        };
        await summaryRef.update(summary);
        this.gameaudit.info(`summarized team score using existing`, {
          blockId,
          venueId: this.venueId,
          teamId,
          summary,
        });
      } catch (err) {
        this.log.error('summaryRef update err', err);
      }
    } else {
      const summary = {
        totalScore: currScore,
        prevScore: 0,
        currentBlockScore: currScore,
      };
      try {
        await summaryRef.set(summary);
        this.gameaudit.info(`summarized team score using nothing`, {
          blockId,
          venueId: this.venueId,
          teamId,
          summary,
        });
      } catch (err) {
        this.log.error('summaryRef set err', err);
      }
    }
  }

  private getCachedScore(blockId: string, teamId: string) {
    return this.scoreCache.get(`${blockId}:${teamId}`) ?? 0;
  }

  private setCachedScore(blockId: string, teamId: string, score: number) {
    return this.scoreCache.set(`${blockId}:${teamId}`, score);
  }
}

export function useScoreSummarySyncer(venueId: string, sessionId?: string) {
  return useMemo(() => {
    if (!sessionId) return;
    return new ScoreSummarySyncer(venueId, sessionId);
  }, [venueId, sessionId]);
}
