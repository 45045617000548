import sample from 'lodash/sample';
import React, {
  type ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useLatest } from 'react-use';

import {
  BlockType,
  RoundRobinMode,
  type RoundRobinQuestionBlock,
  type RoundRobinQuestionBlockDetailScore,
  RoundRobinQuestionBlockGameSessionStatus,
} from '@lp-lib/game';

import { useLiveCallback } from '../../../../hooks/useLiveCallback';
import { NotificationType } from '../../../../types';
import { ordinal, uuidv4 } from '../../../../utils/common';
import {
  useDatabaseSafeRef,
  useFirebaseBatchWrite,
  useFirebaseValue,
} from '../../../Firebase';
import { useNotificationDataSource } from '../../../Notification/Context';
import { useMyTeamId } from '../../../Player';
import { useParticipant } from '../../../Player';
import { useIsStreamSessionAlive } from '../../../Session';
import {
  useTeamMembers,
  useTeamMembersByTeamIds,
  useTeams,
} from '../../../TeamAPI/TeamV1';
import { useVenueId } from '../../../Venue/VenueProvider';
import { useGameSessionBlock, useGameSessionStatus } from '../../hooks';
import { updateBlockDetailScore } from '../../store';
import { useGamePlayEmitter } from '../Common/GamePlay/GamePlayProvider';
import { type GamePlayEndedState } from '../Common/GamePlay/types';
import { useRankedTeamScores } from '../Common/hooks';
import { BlockKnifeUtils } from '../Shared';
import {
  type GameProgressSummary,
  type Progress,
  type Question,
  type QuestionMap,
  QuestionStatus,
  type RaceTeam,
  type TeamData,
  type TeamsData,
} from './types';
import { log, RoundRobinQuestionUtils, SpriteGenerator } from './utils';

export interface TeamControlState {
  questions: Question[];
  currentQuestion: Nullable<Question>;
  currentProgress: Nullable<Progress>;
}

export interface TeamControlAPI {
  updateStatus: (next: QuestionStatus) => Promise<void>;
  nextQuestion: () => Promise<void>;
  pushQuestion: (question: Question) => Promise<void>;
  changeSubmitter: () => Promise<void>;
}

export type HotSeatMode = 'HOT SEAT' | 'GUESSER';

export function useTeamControl(props: {
  venueId: string;
  teamId: string;
}): [TeamControlState, TeamControlAPI] {
  const { venueId, teamId } = props;

  const [questions, writeQuestions] = useQuestions(teamId);
  const [progress, updateProgress] = useProgress(teamId);
  const ref = useDatabaseSafeRef<Question>(
    RoundRobinQuestionUtils.GetFBPathQuestion(
      venueId,
      teamId,
      progress?.questionIndex ?? 0
    )
  );
  const currentQuestion = progress && questions[progress.questionIndex];
  const teamMembers = useTeamMembers(teamId, true);
  const teamMembersRef = useLatest(teamMembers ?? []);
  const notificationDataSource = useNotificationDataSource();

  const updateStatus = useCallback(
    async (next: QuestionStatus) => {
      await ref.update({
        status: next,
        statusChangedAt: Date.now(),
      });
    },
    [ref]
  );

  const nextQuestion = useCallback(async () => {
    if (!progress) return;

    const nextProgressIndex = progress.questionIndex + 1;
    if (nextProgressIndex >= questions.length) return;

    const orderedMembers = RoundRobinQuestionUtils.OrderMembers(
      teamMembersRef.current,
      {
        clientId: progress?.submitterClientId || '',
        joinedAt: progress?.submitterJoinedAt || 0,
      }
    );

    const pickedMember = orderedMembers[0];
    if (!pickedMember) return;

    // waiting 550ms for transition
    setTimeout(() => {
      updateProgress({
        questionIndex: nextProgressIndex,
        submitterClientId: pickedMember.id,
        submitterJoinedAt: pickedMember.joinedAt,
      });
    }, 550);
  }, [progress, questions.length, teamMembersRef, updateProgress]);

  const changeSubmitter = useCallback(async () => {
    if (!progress) return;

    const pickedMember = sample(teamMembersRef.current);

    if (!pickedMember) return;

    Object.entries(teamMembersRef.current).forEach(([_, member]) => {
      notificationDataSource.send({
        id: uuidv4(),
        toUserClientId: member.id,
        type: NotificationType.GameChangeSubmitter,
        createdAt: Date.now(),
        metadata: {
          droppedPlayerClientId: progress.submitterClientId,
          newPlayerClientId: pickedMember.id,
        },
      });
    });

    updateProgress({
      questionIndex: progress.questionIndex,
      submitterClientId: pickedMember.id,
      submitterJoinedAt: pickedMember.joinedAt,
    });
  }, [notificationDataSource, progress, teamMembersRef, updateProgress]);

  const pushQuestion = useCallback(
    async (question: Question) => {
      writeQuestions([...questions, question]);
    },
    [questions, writeQuestions]
  );

  const state: TeamControlState = {
    questions,
    currentQuestion,
    currentProgress: progress,
  };
  const control: TeamControlAPI = {
    updateStatus,
    nextQuestion,
    changeSubmitter,
    pushQuestion,
  };

  return [state, control];
}

export function useAutoUpdateDetailScore(
  block: RoundRobinQuestionBlock,
  teamId: string,
  questions: Question[]
): void {
  const maxTotalScore = useMaxTotalScore(block);
  const totalScore = useMemo(() => {
    const clueSum = questions.reduce(
      (sum, q) =>
        sum +
        (q.clues?.reduce(
          (clueSum, c) => clueSum + (c.used ? c.points : 0),
          0
        ) ?? 0),
      0
    );

    const questionSum = questions.reduce(
      (sum, q) => sum + (q.gainedPoints || 0),
      0
    );
    return Math.min(questionSum + clueSum, maxTotalScore);
  }, [maxTotalScore, questions]);

  useEffect(() => {
    updateBlockDetailScore<RoundRobinQuestionBlockDetailScore>(teamId, {
      score: totalScore,
      submittedAt: Date.now(),
    });
  }, [teamId, totalScore]);
}

interface RoundRobinQuestionGameControlAPI {
  resetGame: (debug: string) => Promise<void>;
  initGame: (block: RoundRobinQuestionBlock) => Promise<void>;
}

export function useRoundRobinQuestionGameControl(): RoundRobinQuestionGameControlAPI {
  const venueId = useVenueId();
  const ref = useDatabaseSafeRef(
    RoundRobinQuestionUtils.GetFBRootPath(venueId)
  );
  const batchWrite = useFirebaseBatchWrite();
  const teams = useTeams({
    updateStaffTeam: true,
    excludeStaffTeam: true,
  });
  const teamIds = teams.map((t) => t.id);
  const teamMembers = useTeamMembersByTeamIds(teamIds);
  const teamMembersRef = useLatest(teamMembers);

  const resetGame = useCallback(
    async (debug: string) => {
      log.debug(`reset game: ${debug}`);
      await ref.remove();
    },
    [ref]
  );

  const initGame = useCallback(
    async (block: RoundRobinQuestionBlock) => {
      const updates: Record<string, TeamData> = {};

      const raceMode = block.fields.mode === RoundRobinMode.Race;
      const raceUnit =
        raceMode && block.fields.raceUnitMedia
          ? {
              thumbnailUrl:
                block.fields.raceUnitMedia.firstThumbnailUrl ?? null,
              mediaUrl: block.fields.raceUnitMedia.url,
            }
          : null;
      const spriteGenerator = new SpriteGenerator(
        raceUnit ? [raceUnit] : undefined
      );

      Object.entries(teamMembersRef.current).forEach(([teamId, members]) => {
        if (members.length === 0) return;

        const path = RoundRobinQuestionUtils.GetFBTeamPath(venueId, teamId);

        const pickedMember = sample(members);
        if (!pickedMember) return;

        const progress: Progress = {
          questionIndex: 0,
          submitterClientId: pickedMember.id,
          submitterJoinedAt: pickedMember.joinedAt,
          raceUnit: raceMode ? spriteGenerator.generate() : null,
        };

        updates[path] = {
          questions: RoundRobinQuestionUtils.GetGamePlayQuestionMap(block),
          progress,
        };
      });

      await batchWrite(updates);
    },
    [batchWrite, teamMembersRef, venueId]
  );

  return {
    resetGame,
    initGame,
  };
}

export function useIsGameCompleted(
  block: RoundRobinQuestionBlock,
  questions: Question[]
) {
  const raceMode = block.fields.mode === RoundRobinMode.Race;
  const maxScore = useMaxTotalScore(block);

  const completed = useMemo(() => {
    if (!questions.length) return false;
    if (raceMode) {
      const currScore = questions
        .filter(
          (q) => q.grade === 'Correct' && q.status === QuestionStatus.Ended
        )
        .reduce((acc, q) => (acc += q.points), 0);
      return currScore >= maxScore;
    } else {
      return questions.every((q) => q.status === QuestionStatus.Ended);
    }
  }, [questions, raceMode, maxScore]);

  return completed;
}

export function useEmitGamePlayEndedState(props: {
  block: RoundRobinQuestionBlock;
  gameSessionStatus:
    | RoundRobinQuestionBlockGameSessionStatus
    | null
    | undefined;
  questions: Question[];
}): void {
  const { block, gameSessionStatus, questions } = props;
  const emitter = useGamePlayEmitter();
  const [finalState, setFinalState] = useState<Nullable<GamePlayEndedState>>();
  const goalMedia = BlockKnifeUtils.GetGoalCompletionMedia(block);
  const raceMode = block.fields.mode === RoundRobinMode.Race;
  const raceTeams = useRaceTeams();
  const teamId = useMyTeamId();

  const getMyRaceTeam = useLiveCallback(() => {
    if (!raceMode) return;
    return raceTeams.find((t) => t.teamId === teamId);
  });

  const isCompleted = useIsGameCompleted(block, questions);

  // timesup
  useEffect(() => {
    if (finalState) return;
    if (
      gameSessionStatus === RoundRobinQuestionBlockGameSessionStatus.GAME_END
    ) {
      emitter.emit('ended', block.id, 'timesup');
      setFinalState('timesup');
    }
  }, [block.id, emitter, finalState, gameSessionStatus]);

  // finished
  useEffect(() => {
    if (
      finalState ||
      !isCompleted ||
      gameSessionStatus !== RoundRobinQuestionBlockGameSessionStatus.GAME_START
    )
      return;

    const raceTeam = getMyRaceTeam();

    const options = {
      animationMedia: goalMedia,
      primaryTextNode: raceTeam
        ? `${ordinal(raceTeam.rank)} Place!`
        : undefined,
      secondaryTextNode: raceTeam
        ? 'Congratulations on finishing race!'
        : undefined,
    };
    // NOTE(drew): in this case `ended` will be emitted by the Finished
    // animation itself!
    if (goalMedia)
      emitter.emit('ended-awaiting-goal-media', block.id, 'finished', options);
    else emitter.emit('ended', block.id, 'finished', options);
    setFinalState('finished');
  }, [
    block.id,
    emitter,
    finalState,
    gameSessionStatus,
    getMyRaceTeam,
    goalMedia,
    isCompleted,
    raceMode,
  ]);

  useEffect(() => {
    return () => {
      setFinalState(undefined);
    };
  }, [block.id]);
}

function useTeamsData() {
  const venueId = useVenueId();
  const isSessionAlive = useIsStreamSessionAlive();

  return useFirebaseValue<Nullable<TeamsData>>(
    RoundRobinQuestionUtils.GetFBTeamsPath(venueId),
    {
      enabled: isSessionAlive,
      seedValue: null,
      seedEnabled: false,
      readOnly: true,
      resetWhenUmount: true,
    }
  );
}

function useQuestions(
  teamId: string
): [Question[], (next: Question[]) => Promise<void>] {
  const venueId = useVenueId();
  const isSessionAlive = useIsStreamSessionAlive();

  const [questionMap, writeQuestionMap] = useFirebaseValue<
    Nullable<QuestionMap>
  >(RoundRobinQuestionUtils.GetFBPathByTeam(venueId, teamId, 'questions'), {
    enabled: isSessionAlive && !!teamId,
    seedValue: null,
    seedEnabled: false,
    readOnly: false,
    resetWhenUmount: true,
  });

  const questions = useMemo(() => {
    if (!questionMap) return [];
    return Object.values(questionMap).sort((a, b) => a.index - b.index);
  }, [questionMap]);
  const writeQuestions = useCallback(
    async (next: Question[]) => {
      const mp: QuestionMap = {};
      for (const q of next) {
        mp[q.index] = q;
      }

      await writeQuestionMap(mp);
    },
    [writeQuestionMap]
  );

  return [questions, writeQuestions];
}

function useProgress(
  teamId: string
): [Nullable<Progress>, (next: Progress | null | undefined) => Promise<void>] {
  const venueId = useVenueId();
  const isSessionAlive = useIsStreamSessionAlive();

  const [progress, writeProgress] = useFirebaseValue<Nullable<Progress>>(
    RoundRobinQuestionUtils.GetFBPathByTeam(venueId, teamId, 'progress'),
    {
      enabled: isSessionAlive && !!teamId,
      seedValue: null,
      seedEnabled: false,
      readOnly: false,
      resetWhenUmount: true,
    }
  );
  return [progress, writeProgress];
}

type Context = {
  questions: Question[];
  progress: Nullable<Progress>;
  teamsData: Nullable<TeamsData>;
};

const context = React.createContext<Nullable<Context, false>>(null);

function useRoundRobinContext(): Context {
  const ctx = useContext(context);
  if (!ctx) throw new Error('RoundRobinContext is not in the tree!');
  return ctx;
}

export function useRoundRobinTeamsData(): Nullable<TeamsData> {
  return useRoundRobinContext().teamsData;
}

export function useRoundRobinQuestions(): Question[] {
  return useRoundRobinContext().questions;
}

export function useRoundRobinProgress(): Nullable<Progress> {
  return useRoundRobinContext().progress;
}

export function useIsRoundRobinBlockGamePlay(): boolean {
  const isSessionAlive = useIsStreamSessionAlive();
  const gameSessionBlock = useGameSessionBlock();

  return (
    isSessionAlive && gameSessionBlock?.type === BlockType.ROUND_ROBIN_QUESTION
  );
}

export function useRoundRobinHotSeatMode(uid: string): HotSeatMode | null {
  const isRoundRobinQuestionBlockGamePlay = useIsRoundRobinBlockGamePlay();
  const gameSessionBlock = useGameSessionBlock();
  const gameSessionStatus =
    useGameSessionStatus<RoundRobinQuestionBlockGameSessionStatus>();
  const progress = useRoundRobinProgress();
  const submitter = useParticipant(progress?.submitterClientId);

  const InRoundRobinHotSeat =
    gameSessionBlock &&
    isRoundRobinQuestionBlockGamePlay &&
    !!gameSessionStatus &&
    [
      RoundRobinQuestionBlockGameSessionStatus.GAME_INIT,
      RoundRobinQuestionBlockGameSessionStatus.GAME_START,
      RoundRobinQuestionBlockGameSessionStatus.GAME_END,
    ].includes(gameSessionStatus) &&
    submitter?.id === uid;
  if (!InRoundRobinHotSeat) return null;

  switch (gameSessionBlock.type) {
    case BlockType.ROUND_ROBIN_QUESTION:
      return gameSessionBlock.fields.hotSeatUI ? 'HOT SEAT' : 'GUESSER';
    default:
      return null;
  }
}

export function useRoundRobinGameSummary(): GameProgressSummary {
  const teamsData = useRoundRobinTeamsData();

  return useMemo(() => {
    const summary: GameProgressSummary = {};
    if (!teamsData) return summary;

    Object.keys(teamsData).forEach((teamId) => {
      const questions = Object.values(teamsData[teamId]?.questions || {});
      const currentQuestion = (teamsData[teamId]?.questions || {})[
        teamsData[teamId]?.progress?.questionIndex ?? 0
      ];

      summary[teamId] = {
        questionsCount: questions.length,
        completedQuestionsCount: questions.filter(
          (q) => q.status >= QuestionStatus.ShowAnswer
        ).length,
        correctQuestionsCount: questions.filter((q) => q.grade === 'Correct')
          .length,
        usedCluesCount: questions.reduce(
          (cluesSum, q) =>
            cluesSum + (q.clues?.filter((clue) => clue.used).length ?? 0),
          0
        ),
        currentUsedCluesCount:
          currentQuestion?.clues?.filter((clue) => clue.used).length ?? 0,
      };
    });

    return summary;
  }, [teamsData]);
}

export function useRaceTeams() {
  const teamScores = useRankedTeamScores();
  const teamsData = useRoundRobinTeamsData();

  const getRaceUnit = useLiveCallback((teamId: string) => {
    const data = teamsData?.[teamId];
    if (!data) return;
    return data.progress?.raceUnit;
  });

  const getLastSubmittedAt = useLiveCallback((teamId: string) => {
    const data = teamsData?.[teamId];
    if (!data || !data.questions) return 0;
    const questions = Object.values(data.questions).filter(
      (q) => q.grade === 'Correct'
    );
    const lastQuestion = questions[questions.length - 1];
    if (!lastQuestion) return 0;
    return lastQuestion.submittedAt ?? 0;
  });

  const units = useMemo<RaceTeam[]>(() => {
    const teams = teamScores
      .map((s) => ({
        teamId: s.team.id,
        teamName: s.team.name,
        unit: getRaceUnit(s.team.id),
        color: s.team.color,
        score: s.currentScore,
        rank: 0,
        submittedAt: getLastSubmittedAt(s.team.id),
      }))
      .sort((a, b) => {
        if (a.score !== b.score) return b.score - a.score;
        return a.submittedAt - b.submittedAt;
      });

    const last = { score: Number.NEGATIVE_INFINITY, submittedAt: 0, rank: 0 };
    for (const curr of teams) {
      if (curr.score !== last.score || curr.submittedAt !== last.submittedAt) {
        last.rank += 1;
        curr.rank = last.rank;
      } else {
        curr.rank = last.rank;
      }
      last.score = curr.score;
      last.submittedAt = curr.submittedAt;
    }

    return teams.sort((a, b) => a.rank - b.rank);
  }, [getLastSubmittedAt, getRaceUnit, teamScores]);

  return units;
}

export function useMaxTotalScore(block: RoundRobinQuestionBlock) {
  const maxScore = useMemo(() => {
    const totalScore = Math.max(
      RoundRobinQuestionUtils.GetGamePlayableQuestions(block).reduce(
        (acc, q) => (acc += q.points),
        0
      ),
      1
    );
    if (block.fields.mode === RoundRobinMode.Default) return totalScore;
    return Math.ceil(
      (totalScore * (block.fields.raceWinPercentage || 100)) / 100
    );
  }, [block]);
  return maxScore;
}

export function RoundRobinProvider(props: {
  children?: ReactNode;
}): JSX.Element | null {
  const teamId = useMyTeamId() ?? '';

  const [teamsData] = useTeamsData();
  const [questions] = useQuestions(teamId);
  const [progress] = useProgress(teamId);

  const ctxValue: Context = useMemo(
    () => ({
      questions,
      progress,
      teamsData,
    }),
    [progress, questions, teamsData]
  );

  return <context.Provider value={ctxValue}>{props.children}</context.Provider>;
}
