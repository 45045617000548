import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';

import { type Block } from '@lp-lib/game';
import { uncheckedIndexAccess_UNSAFE } from '../../utils/uncheckedIndexAccess_UNSAFE';

interface StreamingToolsPanelStates {
  'recording-logs': boolean | { blockId: Block['id'] };
  'team-randomizer': boolean;
  'music-player': boolean;
  'more-tools': boolean;
  townhall: boolean;
}

interface StreamingToolsContext {
  showStreamingTools: boolean;
  setShowStreamingTools: (active: boolean) => void;
  panels: Readonly<StreamingToolsPanelStates>;
  setPanels: React.Dispatch<
    React.SetStateAction<StreamingToolsContext['panels']>
  >;
}

const Context = createContext<StreamingToolsContext | null>(null);

export const useStreamingToolsContext = (): StreamingToolsContext => {
  const ctx = useContext(Context);
  if (!ctx) throw new Error('StreamingToolsProvider not in tree');
  return ctx;
};

export const useShowStreamingToolsToggle = (): readonly [
  boolean,
  (val: boolean) => void
] => {
  const ctx = useStreamingToolsContext();
  return [ctx.showStreamingTools, ctx.setShowStreamingTools] as const;
};

export const useResetStreamingToolsSubpanels = (): (() => void) => {
  const { setPanels } = useStreamingToolsContext();

  return useCallback(() => {
    setPanels(() => ({
      'game-mode': false,
      'music-player': false,
      'recording-logs': false,
      'team-randomizer': false,
      'more-tools': false,
      townhall: false,
    }));
  }, [setPanels]);
};

export const useStreamingToolsSubpanelState = (): readonly [
  <K extends keyof StreamingToolsPanelStates>(
    panel: K
  ) => Readonly<StreamingToolsPanelStates>[K],
  <K extends keyof StreamingToolsPanelStates>(
    panel: K,
    openOrState: StreamingToolsPanelStates[K]
  ) => void
] => {
  const { panels, setPanels } = useStreamingToolsContext();

  const getPanelState = useCallback(
    <K extends keyof StreamingToolsPanelStates>(panel: K) => {
      return panels[panel];
    },
    [panels]
  );

  const setPanelState = useCallback(
    <K extends keyof StreamingToolsPanelStates>(
      panel: K,
      openOrState: StreamingToolsPanelStates[K]
    ) => {
      setPanels((p) => ({ ...p, [panel]: openOrState }));
    },
    [setPanels]
  );

  return [getPanelState, setPanelState] as const;
};

export const useIsStreamingToolsSubpanelOpen = (): boolean => {
  const ctx = useStreamingToolsContext();
  return Object.keys(ctx.panels).some((p) =>
    Boolean(uncheckedIndexAccess_UNSAFE(ctx.panels)[p])
  );
};

export const useIsStreamingToolsMoreToolsOpen = (): boolean => {
  const ctx = useStreamingToolsContext();

  return (
    Boolean(ctx.panels['more-tools']) &&
    !Object.keys(ctx.panels).some((p) => {
      if (p === 'more-tools') return false;
      return Boolean(uncheckedIndexAccess_UNSAFE(ctx.panels)[p]);
    })
  );
};

export const StreamingToolsProvider = ({
  children,
}: {
  children?: React.ReactNode;
}): JSX.Element => {
  const [showStreamingTools, setShowStreamingTools] = useState(false);
  const [panels, setPanels] = useState<StreamingToolsPanelStates>({
    'recording-logs': false,
    'team-randomizer': false,
    'music-player': false,
    'more-tools': false,
    townhall: false,
  });

  const ctxValue = useMemo(
    () => ({ showStreamingTools, setShowStreamingTools, panels, setPanels }),
    [panels, showStreamingTools]
  );

  return <Context.Provider value={ctxValue}>{children}</Context.Provider>;
};
