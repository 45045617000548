import { type HexColor, type Path, type Point } from '../../types/drawing';
import { Canvas2DHelper } from '../../utils/canvas';
import { Emitter } from '../../utils/emitter';
import { type EmitterListener } from '../../utils/emitter';
import { loadCanvasBlobAsPromise } from '../../utils/media';

function isHexColor(val: HexColor | string): val is HexColor {
  return val.startsWith('#') && val.length === 7;
}

type BrushEvents = {
  'paths-updated': (numOfPaths: number) => void;
};

/**
 * A brush tracks a collection of paths with their brushColor and burshSize.
 */
export class Brush implements EmitterListener<BrushEvents> {
  private _id: string;
  private _brushColor: HexColor;
  private _brushSize: number;
  private currPath: Path | null;
  private _paths: Path[];
  private drawing = false;
  private lastPoint: Point | null;
  private startedAt: number | null;
  private emitter = new Emitter<BrushEvents>();
  on = this.emitter.on.bind(this.emitter);
  off = this.emitter.off.bind(this.emitter);

  constructor(id: string, brushColor: HexColor, brushSize: number) {
    this._id = id;
    this._brushColor = brushColor;
    this._brushSize = brushSize;
    this.currPath = null;
    this._paths = [];
    this.lastPoint = null;
    this.startedAt = null;
  }

  get id(): string {
    return this._id;
  }

  get brushColor(): HexColor {
    return this._brushColor;
  }

  set brushColor(color: HexColor) {
    this._brushColor = color;
  }

  get brushSize(): number {
    return this._brushSize;
  }

  set brushSize(size: number) {
    this._brushSize = size;
  }

  get paths(): readonly Path[] {
    return this._paths;
  }

  startPath(point: Point): Point | undefined {
    if (this.drawing) return;
    const now = Date.now();
    this.currPath = {
      points: [{ ...point, ts: this.startedAt ? now - this.startedAt : 0 }],
      brushColor: this._brushColor,
      brushSize: this._brushSize,
    };
    this.lastPoint = point;
    if (!this.startedAt) {
      this.startedAt = now;
    }
    this.drawing = true;
    return point;
  }

  movePath(ctx: CanvasRenderingContext2D, point: Point): Point | undefined {
    if (!this.drawing || !this.lastPoint || !this.currPath || !this.startedAt)
      return;
    ctx.save();
    ctx.lineWidth = this.currPath.brushSize;
    ctx.strokeStyle = this.currPath.brushColor;
    ctx.beginPath();
    ctx.moveTo(this.lastPoint.x, this.lastPoint.y);
    ctx.lineTo(point.x, point.y);
    ctx.stroke();
    ctx.restore();
    this.lastPoint = point;
    this.currPath.points.push({ ...point, ts: Date.now() - this.startedAt });
    return point;
  }

  drawCircle(ctx: CanvasRenderingContext2D, point: Point): void {
    ctx.save();
    ctx.fillStyle = this.brushColor;
    ctx.beginPath();
    ctx.arc(point.x, point.y, this.brushSize / 2, 0, 2 * Math.PI);
    ctx.fill();
    ctx.restore();
  }

  endPath(ctx: CanvasRenderingContext2D): boolean {
    this.drawing = false;
    if (!this.currPath || this.currPath.points.length === 0) {
      this.currPath = null;
      return false;
    }
    if (this.currPath.points.length === 1) {
      this.drawCircle(ctx, this.currPath.points[0]);
    }
    this._paths.push(this.currPath);
    this.emitter.emit('paths-updated', this._paths.length);
    this.currPath = null;
    return true;
  }

  undo(): void {
    this._paths.pop();
    this.emitter.emit('paths-updated', this._paths.length);
  }

  redraw(ctx: CanvasRenderingContext2D): void {
    this._paths.forEach((path) => {
      if (path.points.length === 0) return;
      if (path.points.length === 1) {
        this.drawCircle(ctx, path.points[0]);
        return;
      }
      ctx.lineWidth = path.brushSize;
      ctx.strokeStyle = path.brushColor;
      ctx.beginPath();
      ctx.moveTo(path.points[0].x, path.points[0].y);
      for (let i = 1; i < path.points.length; i++) {
        ctx.lineTo(path.points[i].x, path.points[i].y);
      }
      ctx.stroke();
    });
  }

  reset(): void {
    this.lastPoint = null;
    this.startedAt = null;
    this.currPath = null;
    this._paths = [];
    this.emitter.emit('paths-updated', this._paths.length);
  }
}

export type DrawingCanvasEvents = {
  start: (p: Point) => void;
  drawing: (p: Point) => void;
  end: () => void;
  move: (p: Point) => void;
  leave: () => void;
};

/**
 * A DrawingCanvas holds a canvas element and a map of brushes. In single player
 * mode, it will be only one brush. In collaborative mode, each player will have
 * a corresponding brush.
 */
export class DrawingCanvas {
  private _drawable: boolean;
  private background: {
    color: HexColor;
    image?: HTMLImageElement;
    loaded?: boolean;
  };
  private cvs = document.createElement('canvas');
  private ctx;
  private ctxHelper;
  private brushMap = new Map<string, Brush>();
  private readonly emitter = new Emitter<DrawingCanvasEvents>();
  on = this.emitter.on.bind(this.emitter);
  off = this.emitter.off.bind(this.emitter);

  constructor(
    width: number,
    height: number,
    args?: {
      background?: HexColor | string | null;
      className?: string;
      drawable?: boolean;
    }
  ) {
    this.cvs.width = width;
    this.cvs.height = height;
    if (args?.className) {
      this.cvs.className = args.className;
    }
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    this.ctx = this.cvs.getContext('2d', { alpha: false })!;
    this.ctxHelper = new Canvas2DHelper(this.ctx);
    this._drawable = args?.drawable ?? false;
    this.ctx.lineJoin = 'round';
    this.ctx.lineCap = 'round';

    if (!args?.background) {
      this.background = { color: '#FFFFFF' };
    } else {
      if (isHexColor(args.background)) {
        this.background = { color: args.background };
      } else {
        this.background = this.setBackgroundImage(args.background);
      }
    }

    this.reset();
  }

  get drawable(): boolean {
    return this._drawable;
  }

  set drawable(val: boolean) {
    this._drawable = val;
  }

  get width(): number {
    return this.cvs.width;
  }

  get height(): number {
    return this.cvs.height;
  }

  get backgroundColor(): HexColor {
    return this.background.color;
  }

  setBackgroundImage(url: string): DrawingCanvas['background'] {
    const image = new Image();
    this.background = {
      color: '#FFFFFF',
      image,
      loaded: false,
    };
    image.crossOrigin = 'anonymous';
    image.onload = () => {
      this.background.loaded = true;
      this.redraw();
    };
    image.src = url;
    return this.background;
  }

  addEventListeners(brushId: string): void {
    this.cvs.addEventListener('pointerdown', (e) => {
      if (!this._drawable) return;
      const point = this.startPath(brushId, this.getPoint(e));
      if (!point) return;
      this.emitter.emit('start', point);
    });
    this.cvs.addEventListener('pointermove', (e) => {
      const point = this.getPoint(e);
      this.emitter.emit('move', point);
      if (!this._drawable) return;
      if (!!this.movePath(brushId, point)) {
        this.emitter.emit('drawing', point);
      }
    });
    this.cvs.addEventListener('pointerup', () => {
      if (!this._drawable) return;
      if (this.endPath(brushId)) {
        this.emitter.emit('end');
      }
    });
    this.cvs.addEventListener('pointerleave', () => {
      this.emitter.emit('leave');
      if (!this._drawable) return;
      if (this.endPath(brushId)) {
        this.emitter.emit('end');
      }
    });
  }

  startPath(brushId: string, point: Point): Point | undefined {
    return this.brushMap.get(brushId)?.startPath(point);
  }

  movePath(brushId: string, point: Point): Point | undefined {
    const brush = this.brushMap.get(brushId);
    if (!brush) return;
    return brush.movePath(this.ctx, point);
  }

  endPath(brushId: string): boolean {
    return this.brushMap.get(brushId)?.endPath(this.ctx) ?? false;
  }

  drawCircle(brushId: string, point: Point): void {
    return this.brushMap.get(brushId)?.drawCircle(this.ctx, point);
  }

  // TODO(jialin): no compatible in collaborative mode
  undo(brushId: string): void {
    this.brushMap.get(brushId)?.undo();
    this.redraw(brushId);
  }

  // TODO(jialin): no compatible in collaborative mode
  private redraw(brushId?: string): void {
    this.ctx.clearRect(0, 0, this.cvs.width, this.cvs.height);
    this.drawBackground();
    if (brushId) {
      this.brushMap.get(brushId)?.redraw(this.ctx);
    }
  }

  addBrush(brushId: string, brushColor: HexColor, brushSize = 5): Brush {
    let brush = this.brushMap.get(brushId);
    if (brush) return brush;
    brush = new Brush(brushId, brushColor, brushSize);
    this.brushMap.set(brushId, brush);
    return brush;
  }

  removeBrush(brushId: string): void {
    this.brushMap.delete(brushId);
  }

  private drawBackground() {
    this.ctx.fillStyle = this.background.color;
    this.ctx.fillRect(0, 0, this.cvs.width, this.cvs.height);
    if (this.background.image && this.background.loaded) {
      this.ctxHelper.drawImage(
        this.background.image,
        this.cvs.width,
        this.cvs.height
      );
    }
  }

  private getPoint(e: PointerEvent) {
    const cvsWidth = this.cvs.width;
    const cvsHeight = this.cvs.height;
    const rect = (e.target as HTMLCanvasElement).getBoundingClientRect();
    const x = (e.clientX - rect.left) * (cvsWidth / rect.width);
    const y = (e.clientY - rect.top) * (cvsHeight / rect.height);
    return { x, y };
  }

  attach(el: HTMLElement): void {
    el.appendChild(this.cvs);
  }

  detach(): void {
    this.cvs.parentNode?.removeChild(this.cvs);
  }

  async toBlob(type?: string, quality?: number): Promise<Blob | null> {
    return loadCanvasBlobAsPromise(this.cvs, type, quality);
  }

  reset(): void {
    this.drawBackground();
    this.redraw();
  }
}
