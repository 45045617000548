import { type RefObject, useEffect, useRef, useState } from 'react';
import { useInView } from 'react-intersection-observer';

import {
  type ClientId,
  type ProfileAddress,
  type ProfileIndex,
  toProfileAddress,
} from '../../../services/crowd-frames';
import { rsCounter } from '../../../utils/rstats.client';
import { useFPSContext } from './Context';
import { type RenderTarget } from './types';

// Get or initialize the render target data for this component.
function useTarget(
  cvs: RefObject<HTMLCanvasElement>,
  profileAddress: ProfileAddress
) {
  const { targets } = useFPSContext();

  useEffect(() => {
    const el = cvs.current;
    return () => {
      // Delete the target once the component is unmounted. Cleanup.
      if (!el) return;
      targets.delete(el);
    };
  }, [cvs, targets]);

  if (!cvs.current) return null;
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  if (targets.has(cvs.current)) return targets.get(cvs.current)!;

  const target: RenderTarget = {
    cvs: cvs.current,
    cvsWidth: cvs.current.width,
    cvsHeight: cvs.current.height,
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    ctx: cvs.current.getContext('2d')!,
    filmstrips: [],
    profileAddress,
    inView: false,
    hasReceivedAtLeastOneFilmstrip: false,
    setHasReceivedAtLeastOneFilmstrip: null,
    immediatelyRender: false,
    throttleRegistration: false,
  };

  targets.set(cvs.current, target);
  rsCounter('crowd-frame-anim-targets-c')?.set(targets.size);
  return target;
}

// Marry the React data hook state into the renderer context.
function useAsFPSTarget(
  target: RenderTarget | null,
  parentOccluding: null | HTMLElement
) {
  const { visibilityChange, getMostRecentFilmstripForAddress } =
    useFPSContext();
  const [hasReceivedAtLeastOneFilmstrip, setHasReceivedAtLeastOneFilmstrip] =
    useState(false);

  // "inView" might be false, but the intersection observer just hasn't been
  // initialized yet. "entry" denotes that it has been initialized.
  const [inViewRef, inView, entry] = useInView({ root: parentOccluding });
  const isInView = !!inView && !!entry;

  if (target) {
    target.inView = isInView;
    target.setHasReceivedAtLeastOneFilmstrip =
      setHasReceivedAtLeastOneFilmstrip;
    target.hasReceivedAtLeastOneFilmstrip = hasReceivedAtLeastOneFilmstrip;
  }

  useEffect(() => {
    // Keep isInView a dependency of this effect by making an always-true
    // reference. This allows eslint and tools to keep isInView a dependency of
    // this effect automatically.
    const always = isInView || !isInView;
    if (target && always) visibilityChange();
  }, [isInView, target, visibilityChange]);

  // On first mount of this target, attempt to immediately render. There could
  // be a cached frame.
  useEffect(() => {
    if (target) {
      target.immediatelyRender = true;
      if (getMostRecentFilmstripForAddress(target.profileAddress)) {
        setHasReceivedAtLeastOneFilmstrip(true);
      }
    }
  }, [getMostRecentFilmstripForAddress, target]);

  return [inViewRef] as const;
}

export const FPSRender = (props: {
  clientId: ClientId;
  profile: ProfileIndex;
  parentOccluding?: HTMLElement | null;
  throttleRegistration?: boolean;
  renderedWidth: number;
  renderedHeight: number;
  className?: string;
}): JSX.Element => {
  const cvs = useRef<HTMLCanvasElement>(null);
  const target = useTarget(
    cvs,
    toProfileAddress(props.clientId, props.profile)
  );
  const [inViewRef] = useAsFPSTarget(target, props.parentOccluding ?? null);

  if (target && props.throttleRegistration !== undefined)
    target.throttleRegistration = props.throttleRegistration;

  return (
    <div
      ref={inViewRef}
      className={`relative h-full w-full ${props.className ?? ''}`}
    >
      <canvas
        ref={cvs}
        width={props.renderedWidth}
        height={props.renderedHeight}
        className='absolute inset-0 h-full w-full'
      ></canvas>
    </div>
  );
};
