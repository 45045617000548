/* eslint-disable @lp-lib/eslint-rules/encapsulated-redux */
import {
  createContext,
  type ReactNode,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { ConnectionStatus } from '@lp-lib/shared-schema';

import { useLiveCallback } from '../../hooks/useLiveCallback';
import { useIsUnloading } from '../../hooks/useUnload';
import logger from '../../logger/logger';
import { getReduxRootState } from '../../store/configureStore';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import {
  ClientTypeUtils,
  type Participant,
  type ParticipantFlagMap,
  type ParticipantFlags,
  type ParticipantMap,
} from '../../types';
import { useFirebaseContext } from '../Firebase';
import * as v1Store from './participantSlice';

export function useMyInstance() {
  return useAppSelector((state) => {
    const clientId = state.participant.myClientId;
    return clientId ? state.participant.skims[clientId] ?? null : null;
  });
}

export function useMyInstanceGetter() {
  const get = useParticipantsGetter();
  return useLiveCallback(() => {
    const clientId = getReduxRootState().participant.myClientId;
    const participants = get();
    return clientId ? participants[clientId] ?? null : null;
  });
}

export function useMyTeamId(): string | null {
  return useMyInstance()?.teamId ?? null;
}

export function useParticipantByClientId(
  clientId: Nullable<string>
): Participant | null {
  return useAppSelector((state) =>
    clientId ? state.participant.skims[clientId] ?? null : null
  );
}

export function useHost(excludedDisconnected = true): Participant | null {
  const skims = useAppSelector((state) => state.participant.skims);

  // useMemo is in case this hook re-runs due to a parent, since this returns a
  // derived value.
  return useMemo(() => {
    const hostCandidates: Participant[] = [];

    for (const [, p] of Object.entries(skims)) {
      if (p && ClientTypeUtils.isHost(p)) hostCandidates.push(p);
    }
    return v1Store.selectLastJoined(hostCandidates, excludedDisconnected);
  }, [excludedDisconnected, skims]);
}

export function useHostClientId(): string | null {
  return useHost()?.clientId ?? null;
}

export function useAllHostClientIds() {
  const skims = useAppSelector((state) => state.participant.skims);
  // useMemo is in case this hook re-runs due to a parent, since this returns a
  // derived value.
  return useMemo(() => {
    const hostClientIds: string[] = [];
    for (const [, p] of Object.entries(skims)) {
      if (p && ClientTypeUtils.isHost(p)) hostClientIds.push(p.clientId);
    }
    return hostClientIds;
  }, [skims]);
}

export function useUpdateParticipant() {
  const store = usePlayerStore();
  return useMemo(() => store.update.bind(store), [store]);
}

export function useLastJoinedParticipantByUserId(
  userId: Nullable<string>,
  excludedDisconnected = true
): Participant | null {
  const skims = useAppSelector((state) => state.participant.skims);
  return useMemo(() => {
    const candidates = createParticipantListFromUids(skims, [userId ?? '']);
    return v1Store.selectLastJoined(candidates, excludedDisconnected);
  }, [excludedDisconnected, skims, userId]);
}

export function useParticipantByUserId(
  userId: Nullable<string>,
  excludedDisconnected?: boolean
): Participant | null {
  return useAppSelector((state) => {
    if (!userId) return null;
    const participants = Object.values(state.participant.skims).filter((p) => {
      if (p?.id !== userId) return false;
      if (excludedDisconnected) {
        return p.status === ConnectionStatus.Connected;
      }
      return true;
    }) as Participant[];
    return participants.length > 0 ? participants[0] : null;
  });
}

export function createParticipantListFromClientIds(
  skims: ParticipantMap,
  clientIds: string[]
) {
  const result: Participant[] = [];

  for (const clientId of clientIds) {
    const target = skims[clientId];
    if (target) {
      result.push(target);
    }
  }

  return result;
}

export function createParticipantListFromUids(
  skims: ParticipantMap,
  uids: string[]
) {
  const result: Participant[] = [];

  for (const p of Object.values(skims)) {
    if (!p) continue;
    if (uids.includes(p.id)) {
      result.push(p);
    }
  }

  return result;
}

export function useParticipantsByClientIds(
  /**
   * DANGER: ensure this is a memoized/stable array!
   */
  clientIds_REQUIREDSTABLE: string[]
): Participant[] {
  const skims = useAppSelector((state) => state.participant.skims);

  return useMemo(
    () => createParticipantListFromClientIds(skims, clientIds_REQUIREDSTABLE),
    [clientIds_REQUIREDSTABLE, skims]
  );
}

export function useParticipantByUserIds(
  userIds_REQUIREDSTABLE: string[],
  excludedDisconnected?: boolean
): Participant[] {
  const skims = useAppSelector((state) => state.participant.skims);

  return useMemo(() => {
    return Object.values(skims).filter((p) => {
      if (!p || !ClientTypeUtils.isAudience(p)) return false;
      if (userIds_REQUIREDSTABLE.findIndex((userId) => userId === p.id) === -1)
        return false;
      if (excludedDisconnected) {
        return p.status === ConnectionStatus.Connected;
      }
      return true;
    }) as Participant[];
  }, [excludedDisconnected, skims, userIds_REQUIREDSTABLE]);
}

export function useIsHeartbeatExpired() {
  return v1Store.isHeartbeatExpired;
}

export function useIsParticipantStoreInited() {
  return useAppSelector((state) => !!state.participant.venueId);
}

export function useParticipantJoin() {
  const store = usePlayerStore();
  return useMemo(() => store.join.bind(store), [store]);
}

export function useParticipantLeave() {
  const store = usePlayerStore();
  return useMemo(() => store.leave.bind(store), [store]);
}

export function useInitParticipantStore() {
  const store = usePlayerStore();
  return useMemo(() => store.init.bind(store), [store]);
}

export function useSyncParticipants() {
  const store = usePlayerStore();
  return useMemo(() => store.sync.bind(store), [store]);
}

export function useParticipantsGetter() {
  return useLiveCallback(() => {
    return getReduxRootState().participant
      .skims satisfies ParticipantMap as ParticipantMap;
  });
}

export function useParticipantsFlagsGetter() {
  return useLiveCallback(() => {
    return getReduxRootState().participant
      .flags satisfies ParticipantFlagMap as ParticipantFlagMap;
  });
}

export function useParticipantFlagsGetter(): (
  clientId: Nullable<string>
) => ParticipantFlags | null {
  return useLiveCallback((clientId: Nullable<string>) => {
    return clientId
      ? getReduxRootState().participant.flags[clientId] ?? null
      : null;
  });
}

export function useParticipants(): ParticipantMap {
  return useAppSelector((state) => state.participant.skims);
}

export function useParticipantsFlags(needsFlags = true): ParticipantFlagMap {
  const [empty] = useState<ParticipantFlagMap>(() => ({}));
  return useAppSelector((state) =>
    needsFlags ? state.participant.flags : empty
  );
}

export function useParticipantFlags(
  clientId: Nullable<string>
): ParticipantFlags | null {
  return useAppSelector((state) =>
    clientId ? state.participant.flags[clientId] ?? null : null
  );
}

export function useParticipantFlag<F extends keyof ParticipantFlags>(
  clientId: Nullable<string>,
  name: F
) {
  return useAppSelector((state) =>
    clientId ? state.participant.flags[clientId]?.[name] ?? null : null
  );
}

export function useParticipantSkim<K extends keyof Participant>(
  clientId: Nullable<string>,
  name: K
): Participant[K] | null {
  return useAppSelector((state) =>
    clientId ? state.participant.skims[clientId]?.[name] ?? null : null
  );
}

export function useLastJoinedParticipantGetter(): (
  userId: string,
  excludedDisconnected?: boolean
) => Participant | null {
  return useLiveCallback((userId: string, excludedDisconnected?: boolean) => {
    const state = getReduxRootState();
    const candidates = createParticipantListFromUids(state.participant.skims, [
      userId,
    ]);
    return v1Store.selectLastJoined(candidates, excludedDisconnected);
  });
}

export function useUpdateNetworkQuality() {
  const store = usePlayerStore();
  return useMemo(() => store.updateNetworkQuality.bind(store), [store]);
}

export function useTrackAgoraNumericUid() {
  const store = usePlayerStore();
  return useMemo(() => store.trackAgoraNumericUid.bind(store), [store]);
}

export function useParticipantNetworkQuality(clientId: null | string) {
  return useAppSelector((state) =>
    clientId ? state.participant.networkQualities[clientId] ?? null : null
  );
}

function FixupParticipantConnectionStatus(props: { venueId: string }) {
  const me = useMyInstance();
  const { svc, emitter } = useFirebaseContext();
  const [latestKnownConnectionEventValue, setLKCEV] = useState<null | boolean>(
    null
  );
  const writing = useRef(false);
  const isUnloading = useIsUnloading();

  useEffect(() => {
    const aborter = new AbortController();
    emitter.on('connection-state-changed', (status) => setLKCEV(status), {
      signal: aborter.signal,
    });
    return () => aborter.abort();
  }, [emitter]);

  useEffect(() => {
    async function exec() {
      if (
        latestKnownConnectionEventValue === null ||
        writing.current === true ||
        isUnloading
      )
        return;

      if (
        me &&
        // I appear to be disconnected.
        me.disconnectedAt &&
        me.status === ConnectionStatus.Disconnected &&
        // ...but as far as I know, firebase is connected
        latestKnownConnectionEventValue === true
      ) {
        // ...this is likely because onDisconnect has clobbered my connection
        // status by firing after I reconnected! aka "late".

        logger.scoped('participant').warn('fixing up connection status', {
          me: { ...me },
          latestKnownConnectionEventValue,
          isUnloading,
          writing,
        });

        try {
          // so tell the server data that I am actually connected.
          await v1Store.writeParticipantConnected(
            svc,
            props.venueId,
            me.clientId
          );
        } finally {
          writing.current = false;
        }
      }
    }

    exec();
  }, [
    emitter,
    isUnloading,
    latestKnownConnectionEventValue,
    me,
    props.venueId,
    svc,
  ]);

  return null;
}

type ParticipantStoreV1Context = {
  store: v1Store.ParticipantStoreV1;
};

const context = createContext<ParticipantStoreV1Context | null>(null);

function usePlayerStore() {
  const ctx = useContext(context);
  if (!ctx) throw new Error('PlayerStoreContext is not in the tree!');
  return ctx.store;
}

export function PlayerStoreProvider(props: {
  venueId: string;
  children?: ReactNode;
}) {
  const { svc, emitter } = useFirebaseContext();
  const dispatch = useAppDispatch();
  const store = useMemo(
    () =>
      new v1Store.ParticipantStoreV1(
        props.venueId,
        svc,
        emitter,
        dispatch,
        getReduxRootState
      ),
    [dispatch, emitter, props.venueId, svc]
  );

  const ctx = useMemo(() => ({ store }), [store]);

  return (
    <context.Provider value={ctx}>
      <FixupParticipantConnectionStatus venueId={props.venueId} />
      {props.children}
    </context.Provider>
  );
}
