import { useEffect } from 'react';
import { useEffectOnce, usePreviousDistinct } from 'react-use';

import {
  type RandomizerBlock,
  RandomizerGameSessionStatus,
} from '@lp-lib/game';

import config from '../../../../config';
import { useCountdown } from '../../../../hooks/useCountdown';
import { assertExhaustive } from '../../../../utils/common';
import { PlayIcon } from '../../../icons/PlayIcon';
import { ShuffleIcon } from '../../../icons/ShuffleIcon';
import { FilledSquareIcon } from '../../../icons/SquareIcon';
import { useParticipantsAsArray } from '../../../Player';
import {
  useTeamRandomizerAPI,
  useTeamRandomizerStepDetail,
} from '../../../TeamRandomizer';
import {
  useGameSessionStatus,
  useRefreshGameSessionBlock,
} from '../../hooks/gameSessionHooks';
import { next, present, triggerBlockTitleAnim } from '../../store';
import {
  BlockControllerActionButton,
  BlockControllerActionNone,
  ControllerLayout,
  type ControllerProps,
  useBlockControllerBlockTitlePlaying,
} from '../Common/Controller/Internal';
import { selectConfig } from './utils';

function RandomizerSettings({
  block,
}: {
  block: RandomizerBlock;
}): JSX.Element {
  const allPlayers = useParticipantsAsArray({
    filters: ['status:connected', 'host:false', 'cohost:false', 'staff:false'],
  });
  const numOfPlayers = allPlayers.length;
  const { teamSize, maxTeamSize } = selectConfig(block.fields, numOfPlayers);

  return (
    <div className='w-full flex flex-col px-4'>
      <p className='mt-12 font-bold text-sms'>Randomization Settings:</p>
      <p className='mt-6 text-xs'>Players: {numOfPlayers}</p>
      <p className='mt-6 text-xs'>Teams of: {teamSize}</p>
      <p className='mt-6 text-xs'>Max: {maxTeamSize || 'NA'}</p>
    </div>
  );
}

function LoadedStage({ block }: { block: RandomizerBlock }) {
  const onStart = async () => {
    await triggerBlockTitleAnim(block);
    await present(block);
  };

  return (
    <ControllerLayout
      action={
        useBlockControllerBlockTitlePlaying(block) ?? (
          <BlockControllerActionButton onClick={onStart} icon={PlayIcon}>
            Start Randomizer
          </BlockControllerActionButton>
        )
      }
    >
      <RandomizerSettings block={block} />
    </ControllerLayout>
  );
}

function StartingStage({
  block,
}: {
  block: RandomizerBlock;
}): JSX.Element | null {
  const [time, timer] = useCountdown(config.team.randomizer.countdown);
  const prevTime = usePreviousDistinct(time);

  useEffectOnce(() => timer.start());

  useEffect(() => {
    if (prevTime === 1 && time === 0) {
      next();
    }
  }, [prevTime, time]);

  return (
    <ControllerLayout
      action={
        <BlockControllerActionNone icon={ShuffleIcon}>
          {`Randomizing Teams (${time}s)`}
        </BlockControllerActionNone>
      }
    >
      <RandomizerSettings block={block} />
    </ControllerLayout>
  );
}

function ResultStage({
  block,
  onEndBlock,
}: {
  block: RandomizerBlock;
  onEndBlock: () => void;
}): JSX.Element | null {
  const { skipIcebreaker } = useTeamRandomizerAPI();
  const detail = useTeamRandomizerStepDetail();

  const onSkip = () => {
    skipIcebreaker();
  };

  const timestep = detail?.timestep ?? 0;

  const actionComponent =
    timestep > 0 ? (
      <>
        <BlockControllerActionButton onClick={onSkip} isSecondary={true}>
          Skip Timer
        </BlockControllerActionButton>
        <BlockControllerActionNone icon={ShuffleIcon}>
          {`Icebreaker Timer (${timestep})`}
        </BlockControllerActionNone>
      </>
    ) : (
      <BlockControllerActionButton onClick={onEndBlock} icon={FilledSquareIcon}>
        End Block Sequence
      </BlockControllerActionButton>
    );

  return (
    <ControllerLayout action={actionComponent}>
      <RandomizerSettings block={block} />
    </ControllerLayout>
  );
}

export function RandomizerBlockController(
  props: ControllerProps<RandomizerBlock>
): JSX.Element {
  const { selectedBlock, onEndBlock } = props;
  const gameSessionStatus = useGameSessionStatus<RandomizerGameSessionStatus>();

  useRefreshGameSessionBlock(selectedBlock);

  if (!selectedBlock) return <></>;

  switch (gameSessionStatus) {
    case RandomizerGameSessionStatus.LOADED:
      return <LoadedStage block={selectedBlock} />;
    case RandomizerGameSessionStatus.STARTING:
      return <StartingStage block={selectedBlock} />;
    case RandomizerGameSessionStatus.RESULT:
      return <ResultStage onEndBlock={onEndBlock} block={selectedBlock} />;
    case RandomizerGameSessionStatus.END:
    case null:
    case undefined:
      return <></>;
    default:
      assertExhaustive(gameSessionStatus);
      return <></>;
  }
}
