import { useEffect, useMemo, useReducer } from 'react';
import { useSnapshot } from 'valtio';

import { ConnectionStatus } from '@lp-lib/shared-schema';

import { useInstance } from '../../../../hooks/useInstance';
import { useRSHook } from '../../../../hooks/useRSHook';
import { type RemoteStreamState } from '../../../../services/webrtc';
import { isReconnecting } from '../../../../store/utils';
import { type MemberId } from '../../../../types';
import { BrowserTimeoutCtrl } from '../../../../utils/BrowserTimeoutCtrl';
import { useAllHostClientIds } from '../../../Player';
import { useParticipantsAsArray } from '../../../Player';
import {
  type MemberVOMap,
  type StageMember,
  type StageMemberVO,
  type StageMode,
} from '../../types';
import { StageUtils } from '../../utils';
import { useStageContext } from '../Provider';

export function useSeleteStageMemberStreamState(
  memberId: string
): RemoteStreamState | null {
  const ctx = useStageContext();
  const streamStates = useSnapshot(ctx.state).streamStates;
  return streamStates[memberId] ?? null;
}

export function useStageMemberVOMap(): MemberVOMap {
  const { miss } = useRSHook('use-stage-member-v0-map');
  const { state } = useStageContext();
  const members = useSnapshot(state).members;
  const participants = useParticipantsAsArray({
    // Apparently we want both connected and disconnected members, so the filter
    // list is blank.
    filters: [],
  });
  return useMemo(() => {
    miss();
    return StageUtils.GetMemberVOMap(participants, members);
  }, [members, miss, participants]);
}

export function useSelectOnStageMembers(
  mode: StageMode,
  options: {
    sort?: boolean;
    excludeMemberIds?: string[];
    myClientId?: string | null;
    includeReconnectingMembers?: boolean;
  } = {}
): StageMemberVO[] {
  const hostClientIds = useAllHostClientIds();
  const {
    sort = true,
    excludeMemberIds = hostClientIds,
    myClientId,
    includeReconnectingMembers = true,
  } = options;
  const ctx = useStageContext();
  const memberVOMap = useStageMemberVOMap();
  const currentMode = useSnapshot(ctx.state).stage.mode;
  const ctrl = useInstance(() => new BrowserTimeoutCtrl());
  const [forceUpdateCount, forceUpdate] = useReducer((c) => ++c, 1);

  useEffect(() => {
    return () => ctrl.clear();
  }, [ctrl]);

  return useMemo(() => {
    if (mode !== currentMode) return [];
    const stageMembers = Object.values(memberVOMap).filter((m) => {
      if (excludeMemberIds && excludeMemberIds.includes(m.id)) return false;
      if (m.status === ConnectionStatus.Connected) return true;
      if (
        includeReconnectingMembers &&
        m.status === ConnectionStatus.Disconnected &&
        isReconnecting(m)
      )
        return true;
      return false;
    });

    if (sort) {
      stageMembers.sort((a, b) => {
        const x = a.id === myClientId ? 1 : 0;
        const y = b.id === myClientId ? 1 : 0;
        const diff = y - x;
        return diff === 0 ? a.joinedAt - b.joinedAt : diff;
      });
    }
    const disconnected = stageMembers.filter(
      (m) => m.status === ConnectionStatus.Disconnected
    );

    // Note(jialin): when someone is disconnected, it will be included if within
    // the disconnection timeout (10s). If there is no additional state changes
    // this hook will not be re-calculuated so he can't finally being removed if
    // timeout. In order to achieve that we force trigger the re-evaluation after
    // 1s if there is at least one disconnected member being returned.
    // The `forceUpdateCount` is a not required check here, but if it's not being
    // used in the effect, we get complain of adding it as a dependency.
    if (disconnected.length > 0 && forceUpdateCount) {
      ctrl.set(() => {
        forceUpdate();
      }, 1000);
    }
    return stageMembers;
  }, [
    ctrl,
    currentMode,
    excludeMemberIds,
    forceUpdateCount,
    includeReconnectingMembers,
    memberVOMap,
    mode,
    myClientId,
    sort,
  ]);
}

export function useSelectStageMember(
  memberId: Nullable<MemberId>
): StageMember | null {
  const ctx = useStageContext();
  const members = useSnapshot(ctx.state).members;
  if (!memberId) return null;
  return members[memberId] ?? null;
}
