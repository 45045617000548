import type React from 'react';

import {
  type ChatParticipant,
  ClientTypeUtils,
  type Participant,
} from '../../types/user';
import { removeDash } from '../../utils/string';

export enum ChatMode {
  Full = 'full',
  Preview = 'preview',
  None = 'none',
}

interface ChannelTheme {
  label: string;
  background: string;
}

export enum ChannelType {
  Public = 1,
  Group = 2,
  Private = 3,
}

export enum StreamChatChannelType {
  Messaging = 'messaging',
  EventMetadata = 'EventMetadata',
}

export enum StreamChatEventType {
  MessageNew = 'message.new',
  MessageDeleted = 'message.deleted',
  TypingStart = 'typing.start',
  TypingStop = 'typing.stop',
}

export enum StreamChatMessageType {
  Regular = 'regular',
  Deleted = 'deleted',
}

export enum EventMetadataAction {
  createPair = 'createPair',
}

export enum ErrorMessageType {
  None = 0,
  MaxMessageTextSizeError = 1,
  EmptyMessageTextSizeError = 2,
  ChannelNotAvailableError = 3,
  DeleteMessageError = 4,
  DeleteMessageLaterError = 5,
}

export const TypingIndicatorConfig = Object.freeze({
  typingStartSendInterval: 5000,
  typingStopWaitInterval: 1000,
  buffer: 200,
});

export enum Direction {
  UP = 'up',
  DOWN = 'down',
}

export enum MessageFragmentType {
  Plain = 'plain',
  PlainLarge = 'plainLarge',
  Strong = 'strong',
  Link = 'link',
}

export interface Fragment {
  type: MessageFragmentType;
  fragment: string;
}

export const MaxMessageCount = 200;
export const MaxMessageTextSize = 280;
export const MentionTrigger = '@';

export const MessageFadeoutMS = 10000;

export const ChatRecipientSelectorDOMId = 'chat-recipient-selector';
export const ChatMessageInputDOMId = 'chat-message-input';
export const ChatMessageListDOMId = 'chat-message-list';
export const ChatUnreadMessagesCounterDOMId = 'chat-unread-messages-count';
export const ChatEmojiButtonDOMId = 'chat-emoji-button';

export const getChannelTypeLabel = (
  channelType: ChannelType,
  withParentheses = false
): string => {
  let label = '';
  if (channelType === ChannelType.Group) {
    label = 'Team chat';
  } else if (channelType === ChannelType.Private) {
    label = 'Private chat';
  }
  if (label && withParentheses) {
    label = `(${label})`;
  }
  return label;
};

export const getChannelTypeTheme = (
  channelType: ChannelType,
  mode?: ChatMode
): ChannelTheme => {
  if (channelType === ChannelType.Group) {
    return {
      label: '#FBB707',
      background: 'rgba(57, 46, 14, 0.6)',
    };
  } else if (channelType === ChannelType.Private) {
    return {
      label: '#8C6FFF',
      background: 'rgba(45, 34, 88, 0.6)',
    };
  }
  return {
    label: 'initial',
    background:
      mode === ChatMode.Preview
        ? 'rgba(0, 0, 0, 0.6)'
        : 'rgba(144, 144, 144, 0.1)',
  };
};

export const buildGroupChannelId = (
  venueId: string,
  teamId: string
): string | null => {
  if (!venueId || !teamId) {
    return null;
  }
  return `${removeDash(venueId)}-t-${teamId}`;
};

export const buildPrivateChannelId = (venueId: string): string => {
  return `${removeDash(venueId)}-p`;
};

export const getChatUserId = (p: ChatParticipant | Participant): string => {
  if (ClientTypeUtils.isHost(p)) {
    return `h_${p.id}`;
  }
  return `a_${p.id}`;
};

export const getChatUserName = (p: ChatParticipant | Participant): string => {
  if (ClientTypeUtils.isHost(p)) {
    return `Host (${p.username})`;
  }
  return p.username;
};

export const isEditingActive = (): boolean => {
  const _id = document.activeElement?.id;
  let active =
    _id === ChatMessageInputDOMId || _id === ChatRecipientSelectorDOMId;
  const chatRecipientSelectorEl = document.getElementById(
    ChatRecipientSelectorDOMId
  );
  if (
    chatRecipientSelectorEl &&
    chatRecipientSelectorEl.className &&
    chatRecipientSelectorEl.className.match(/\bopened\b/)
  ) {
    active = true;
  }
  return active;
};

export interface Recipient {
  id: string;
  username: string;
  type: ChannelType;
  icon: React.ReactNode | null;
  clientId?: string;
}

export const FakeChannelId = Object.freeze({
  Public: '00000000-0000-0000-0000-000000000001',
  Group: '00000000-0000-0000-0000-000000000002',
});

export const Scrollbar = (hideThumb?: boolean): string => `
  scrollbar-color: transparent transparent;
  scrollbar-width: thin;
  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background: transparent;
    border: ${hideThumb ? 'none' : '1px solid rgba(139, 146, 148, 0.3)'};
    transition: background 0.2s ease, border 0.2s ease;
  }

  &:hover {
    scrollbar-color: #8B9294 transparent;
    &::-webkit-scrollbar {
      opacity: 0.3;
    }
    &::-webkit-scrollbar-thumb {
      background: #8B9294;
      border-color: #8B9294;
    }
  }
`;

export type MessageFadeoutConfig = {
  opacity?: string;
  hoverEnabled?: boolean;
};
