import { useMemo, useState } from 'react';

import { type BPUnitlessProperties } from '../../../../breakpoints';
import { useUnitlessMatch } from '../../../../hooks/useUnitlessMatch';
import OverRoastedSheetInfo from './sprites-output/over-roasted-sheet.json';
import OverRoastedSheetImage from './sprites-output/over-roasted-sheet.png';

export type SpriteNames = keyof typeof OverRoastedSheetInfo.frames;

type SpriteInfo = {
  sprite: {
    w: number;
    h: number;
    x: number;
    y: number;
  };
  background: {
    w: number;
    h: number;
  };
};

export function useScaledSpriteInfo(
  name: SpriteNames,
  width?: number
): SpriteInfo {
  return useMemo(() => {
    const sprite = OverRoastedSheetInfo.frames[name];
    if (!width) {
      return {
        sprite: {
          w: sprite.frame.w,
          h: sprite.frame.h,
          x: sprite.frame.x,
          y: sprite.frame.y,
        },
        background: { ...OverRoastedSheetInfo.meta.size },
      };
    } else {
      const scale = width / sprite.frame.w;
      return {
        sprite: {
          w: width,
          h: sprite.frame.h * scale,
          x: sprite.frame.x * scale,
          y: sprite.frame.y * scale,
        },
        background: {
          w: OverRoastedSheetInfo.meta.size.w * scale,
          h: OverRoastedSheetInfo.meta.size.h * scale,
        },
      };
    }
  }, [name, width]);
}

export function Sprite(props: {
  name: SpriteNames;
  width?: number;
  widthBreakpoints?: BPUnitlessProperties;
}): JSX.Element {
  const responsiveWidth = useUnitlessMatch(props.widthBreakpoints || { '': 0 });
  const computed = useScaledSpriteInfo(
    props.name,
    responsiveWidth || props.width
  );
  return (
    <div
      style={{
        width: `${computed.sprite.w}px`,
        height: `${computed.sprite.h}px`,
        backgroundImage: `url(${OverRoastedSheetImage})`,
        backgroundPosition: `-${computed.sprite.x}px -${computed.sprite.y}px`,
        backgroundSize: `${computed.background.w}px ${computed.background.h}px`,
      }}
    />
  );
}

export type SpriteButtonProps = {
  default: SpriteNames;
  hover: SpriteNames;
  clicked: SpriteNames;
  width?: number;
  widthBreakpoints?: BPUnitlessProperties;
  onClick?: () => void;
  disabled?: boolean;
};

export function SpriteButton(props: SpriteButtonProps): JSX.Element {
  const [spriteName, setSpriteName] = useState(props.default);
  const onPointerEnter = () => {
    if (props.disabled) return;
    setSpriteName(props.hover);
  };
  const onPointerLeave = () => {
    if (props.disabled) return;
    setSpriteName(props.default);
  };
  const onPointerDown = () => {
    if (props.disabled) return;
    setSpriteName(props.clicked);
  };
  const onPointerUp = () => {
    if (props.disabled) return;
    setSpriteName(props.hover);
  };
  return (
    <div
      className={props.disabled ? undefined : 'cursor-pointer'}
      onPointerEnter={onPointerEnter}
      onPointerLeave={onPointerLeave}
      onPointerDown={onPointerDown}
      onPointerUp={onPointerUp}
      onClick={props.disabled ? undefined : props.onClick}
    >
      <Sprite
        name={spriteName}
        width={props.width}
        widthBreakpoints={props.widthBreakpoints}
      />
    </div>
  );
}
