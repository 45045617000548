import React, { useContext, useEffect, useMemo } from 'react';
import { proxy } from 'valtio';

import { useInstance } from '../../../../../hooks/useInstance';
import { useMyInstance } from '../../../../../hooks/useMyInstance';
import {
  markSnapshottable,
  useSnapshot,
  type ValtioSnapshottable,
  ValtioUtils,
} from '../../../../../utils/valtio';
import { type FirebaseService } from '../../../../Firebase';
import {
  type Game,
  type GameSettings,
  GameState,
  type GameSummary,
  type GroupId,
} from '../types';
import { OverRoastedFirebaseUtils } from '../utils';

export interface OverRoastedSharedState {
  game: Nullable<Game>;
  settings: GameSettings;
  summaryMap: Record<GroupId, Nullable<GameSummary>>;
}

function initialSettings(): GameSettings {
  return {
    numOfTrucks: 1,
    numOfDispensersPerTruck: 4,
    pointsPerOrder: 50,
    maxIngredientsPerPlayer: 2,
    maxIngredientsPerOrder: 2,
    gameTimeSec: 60,
    useCupColorForMatchedOrder: true,
    numOfOrders: 6,
    tutorialMode: false,
  };
}

function initialState(): OverRoastedSharedState {
  return {
    game: null,
    settings: initialSettings(),
    summaryMap: {},
  };
}

export class OverRoastedSharedAPI {
  constructor(
    venueId: string,
    private state: OverRoastedSharedState,
    firebaseService: FirebaseService,
    private gameHandle = OverRoastedFirebaseUtils.GameHandle(
      firebaseService,
      venueId
    ),
    private settingsHandle = OverRoastedFirebaseUtils.SettingsHandle(
      firebaseService,
      venueId
    ),
    private summaryHandle = OverRoastedFirebaseUtils.SummaryHandle(
      firebaseService,
      venueId
    )
  ) {}

  on(): void {
    this.gameHandle.on((val) => ValtioUtils.set(this.state, 'game', val));
    this.settingsHandle.on((val) => {
      ValtioUtils.set(this.state, 'settings', val ?? initialSettings());
    });
    this.summaryHandle.on((val) => {
      ValtioUtils.set(this.state, 'summaryMap', val ?? {});
    });
  }

  off(): void {
    this.gameHandle.off();
    this.settingsHandle.off();
    this.summaryHandle.off();
  }
}

type Context = {
  api: OverRoastedSharedAPI;
  state: ValtioSnapshottable<OverRoastedSharedState>;
};

const context = React.createContext<Context | null>(null);

export function useOverRoastedSharedContext(): Context {
  const ctx = useContext(context);
  if (!ctx) throw new Error('OverRoastedSharedContext is not in the tree!');
  return ctx;
}

export function useOverRoastedGame(): OverRoastedSharedState['game'] {
  const { state } = useOverRoastedSharedContext();
  return useSnapshot(state).game;
}

export function useOverRoastedGameSettings(): OverRoastedSharedState['settings'] {
  const { state } = useOverRoastedSharedContext();
  return useSnapshot(state).settings;
}

export function useOverRoastedSummaryMap(): OverRoastedSharedState['summaryMap'] {
  const { state } = useOverRoastedSharedContext();
  return useSnapshot(state).summaryMap;
}

export function useOverRoastedGamePlayable(): boolean {
  const game = useOverRoastedGame();
  return game?.state === GameState.InProgress;
}

export function useOverRoastedMyGroupId(): string {
  const me = useMyInstance();
  const settings = useOverRoastedGameSettings();
  if (!me?.teamId) return '';
  return settings.tutorialMode ? me.id : me.teamId;
}

export function useOverRoastedJoinedGroupIds(): string[] {
  const summaryMap = useOverRoastedSummaryMap();
  return Object.keys(summaryMap);
}

export function useOverRoastedMyGroupSummary(): GameSummary | null {
  const summaryMap = useOverRoastedSummaryMap();
  const myGroupId = useOverRoastedMyGroupId();
  return summaryMap?.[myGroupId] || null;
}

export function OverRoastedSharedProvider(props: {
  venueId: string;
  firebaseService: FirebaseService;
  ready: boolean;
  children?: React.ReactNode;
}): JSX.Element | null {
  const { venueId, firebaseService, ready, children } = props;

  const state = useInstance(() =>
    markSnapshottable(proxy<OverRoastedSharedState>(initialState()))
  );

  const ctxValue = useMemo(
    () => ({
      state,
      api: new OverRoastedSharedAPI(venueId, state, firebaseService),
    }),
    [firebaseService, state, venueId]
  );

  useEffect(() => {
    if (!ready) return;
    ctxValue.api.on();
    return () => ctxValue.api.off();
  }, [ctxValue.api, ready]);

  return <context.Provider value={ctxValue}>{children}</context.Provider>;
}
