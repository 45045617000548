import { useMemo, useState } from 'react';

import { useListLoader } from '../../hooks/useListLoader';
import { apiService } from '../../services/api-service';
import { type MessageCampaignType } from '../../services/api-service/messageCampaign.api';
import {
  type MessageLogicsResponse,
  type QueryMessageLogicRequest,
  type UpdateMessageLogicRequest,
} from '../../services/api-service/messageLogic.api';
import { type MessageLogic } from '../../types';
import { Loading } from '../Loading';
import { MessageCampaignSelect } from '../MessageCampaign/Select';
import { MessageTemplateSelect } from '../MessageTemplate';

const MessageLogicCard = (props: {
  logic: MessageLogic;
  onChange: (data: UpdateMessageLogicRequest) => void;
  disabled: boolean;
  editable: boolean;
}): JSX.Element => {
  const { logic, onChange, disabled, editable } = props;

  return (
    <div
      className={`w-144 h-55 bg-dark-gray rounded-xl p-5 flex flex-col justify-between ${
        disabled ? 'bg-opacity-60 text-white text-opacity-60' : ''
      }`}
    >
      <div>
        <div className='text-base font-bold'>{logic.title}</div>
        <div
          className='text-sms mt-2.5'
          dangerouslySetInnerHTML={{ __html: logic.description }}
        ></div>
      </div>

      {disabled && <p className='text-primary text-base font-bold'>Sent</p>}

      <MessageTemplateSelect
        templateId={logic.templateId}
        onSelect={(template) => {
          onChange({ id: logic.id, templateId: template.id });
        }}
        editable={editable}
        disabled={disabled}
      />
    </div>
  );
};

export const MessageLogicCards = (props: {
  parentId: string | null;
  campaignId: string | null;
  editable: boolean;
  onMessageTemplateChange: (data: UpdateMessageLogicRequest) => void;
  checkLogicDisabled?: (logic: MessageLogic) => boolean;
}): JSX.Element => {
  const {
    parentId,
    campaignId,
    onMessageTemplateChange,
    checkLogicDisabled,
    editable,
  } = props;

  const paginator = useMemo(() => {
    const params: QueryMessageLogicRequest = {};
    if (parentId) {
      params.parentId = parentId;
    }
    if (campaignId) {
      params.campaignId = campaignId;
    }
    return apiService.messageLogic.query(params);
  }, [campaignId, parentId]);
  const { items, state, dao } = useListLoader<
    MessageLogicsResponse,
    MessageLogic
  >(paginator, (a, b) => a.id === b.id);

  const handleChange = (data: UpdateMessageLogicRequest) => {
    dao.updateItem(data);
    onMessageTemplateChange(data);
  };

  if (state.isRunning) {
    return <Loading />;
  }

  if (items.length === 0) {
    return (
      <div className='w-full flex items-center justify-center font-bold text-secondary'>
        No Message Logics.
      </div>
    );
  }

  return (
    <div className='w-full flex gap-10 flex-wrap'>
      {items.map((logic) => (
        <MessageLogicCard
          key={logic.id}
          logic={logic}
          onChange={handleChange}
          disabled={checkLogicDisabled ? checkLogicDisabled(logic) : false}
          editable={editable}
        />
      ))}
    </div>
  );
};

export const MessageCampaign = (props: {
  parentId: string | null;
  campaignType: MessageCampaignType;
  messageCampaignId: string | null;
  onChange: (campaignId: string) => void;
  onMessageTemplateChange: (data: UpdateMessageLogicRequest) => void;
  checkLogicDisabled?: (logic: MessageLogic) => boolean;
  disabled: boolean;
}): JSX.Element => {
  const {
    parentId,
    onChange,
    messageCampaignId,
    onMessageTemplateChange,
    checkLogicDisabled,
    campaignType,
  } = props;

  const [campaignId, setCampaignId] = useState<string | null>(
    messageCampaignId
  );

  const handleUpdateMessageTemplate = (data: UpdateMessageLogicRequest) => {
    onMessageTemplateChange(data);
  };

  return (
    <div>
      <div className='text-base'>Message Campaign</div>
      <div className='pt-1 pb-6'>
        <MessageCampaignSelect
          campaignId={campaignId}
          campaignType={campaignType}
          onChange={(campaign) => {
            setCampaignId(campaign.id);
            onChange(campaign.id);
          }}
          disabled={props.disabled}
        />
      </div>
      {campaignId && (
        <MessageLogicCards
          editable={false}
          parentId={parentId}
          campaignId={campaignId}
          onMessageTemplateChange={handleUpdateMessageTemplate}
          checkLogicDisabled={checkLogicDisabled}
        />
      )}
    </div>
  );
};
