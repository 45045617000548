import { type TeamScoreboardData } from '@lp-lib/game';

import { bps, type BPUnitlessProperties } from '../../../../../breakpoints';

export class GamePlayStyle {
  static UseFloatLayoutPadding(hasMedia: boolean): string {
    return hasMedia
      ? bps('pb-4 xl:pb-8 lp-sm:pb-6 2xl:pb-6 3xl:pb-6 lp-md:pb-6 lp-lg:pb-6')
      : bps(
          'pt-20 xl:pt-20 lp-sm:pt-20 2xl:pt-20 3xl:pt-20 lp-md:pt-20 lp-lg:pt-20',
          'pb-8 xl:pb-8 lp-sm:pb-8 2xl:pb-8 3xl:pb-8 lp-md:pb-8 lp-lg:pb-8'
        );
  }
  static UseBlockTextLayout(hasMedia: boolean): string {
    return `${bps(
      'mx-15 xl:mx-15 lp-sm:mx-15 2xl:mx-15 3xl:mx-15 lp-md:mx-15 lp-lg:mx-15'
    )} ${
      hasMedia
        ? `${bps(
            'py-3 xl:py-3 lp-sm:py-3 2xl:py-3 3xl:py-3 lp-md:py-5 lp-lg:py-5'
          )}`
        : 'flex-grow'
    }`;
  }
  static UseBlockTextSize(hasMedia: boolean): string {
    return hasMedia
      ? bps([
          'text-xs',
          'xl:text-xs',
          'lp-sm:text-sms',
          '2xl:text-sms',
          '3xl:text-sms',
          'lp-md:text-sm',
          'lp-lg:text-base',
        ])
      : bps([
          'text-xl',
          'xl:text-3xl',
          'lp-sm:text-3xl',
          '2xl:text-4xl',
          '3xl:text-4xl',
          'lp-md:text-4xl',
          'lp-lg:text-4xl',
        ]);
  }
  static UseProgressRing(): {
    radius: BPUnitlessProperties;
    stroke: BPUnitlessProperties;
  } {
    return {
      radius: {
        '': 36,
        xl: 36,
        'lp-sm': 40,
        '2xl': 40,
        '3xl': 40,
        'lp-md': 40,
        'lp-lg': 40,
      },
      stroke: {
        '': 5,
        xl: 5,
        'lp-sm': 6,
        '2xl': 6,
        '3xl': 6,
        'lp-md': 6,
        'lp-lg': 6,
      },
    };
  }
}

export class PromptPoolManager<T> {
  private source: T[] = [];
  private pool: T[] = [];

  init(source: T[]) {
    this.source = source;
    this.resetPool();
  }

  private resetPool(): void {
    if (this.source.length === 0) throw new Error("source can't be empty");
    this.pool = [...this.source];
  }

  pick(): T {
    if (this.pool.length === 0) this.resetPool();
    const idx = Math.floor(Math.random() * this.pool.length);
    const val = this.pool[idx];
    this.pool.splice(idx, 1);
    return val;
  }
}

export function rankTeamScoreboardData(list: TeamScoreboardData[]) {
  list.sort((a, b) =>
    b.score - a.score !== 0
      ? b.score - a.score
      : b.createdTimestamp - a.createdTimestamp
  );

  let rank = 0;
  let prevScore = -1;
  let prevTimestamp = -1;
  for (const s of list) {
    if (s.score === prevScore && s.createdTimestamp === prevTimestamp) {
      s.rank = rank;
    } else {
      rank++;
      prevScore = s.score;
      prevTimestamp = s.createdTimestamp;
      s.rank = rank;
    }
  }
}
