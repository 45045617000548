import { type ReactNode, useEffect, useMemo, useRef } from 'react';

import { useForceUpdate } from '../../../../hooks/useForceUpdate';
import { type HexColor } from '../../../../types/drawing';
import { shadeColor } from '../../../../utils/css';
import { type Brush, type DrawingCanvas } from '../../../Drawing';
import { FilledCheckIcon } from '../../../icons/CheckIcon';
import { UndoIcon } from '../../../icons/UndoIcon';
import { log } from './utils';

function ColorButton(props: {
  myColor: HexColor;
  active: boolean;
  disabled: boolean;
  onClick: () => void;
  children?: ReactNode;
}) {
  return (
    <button
      className={`btn rounded-xl w-10 h-10 flex items-center justify-center relative
       border-white ${props.active ? 'border-2' : 'border border-opacity-80'}`}
      style={{
        backgroundColor: props.myColor,
      }}
      disabled={props.disabled}
      onClick={props.onClick}
    >
      {props.children}
      {props.active && (
        <div
          className='absolute -top-1 -right-1 w-4 h-4 rounded-full 
        bg-white text-black flex items-center justify-center'
          style={{
            filter: 'drop-shadow(-1px 1px 1px rgba(0, 0, 0, 0.25))',
          }}
        >
          <FilledCheckIcon className='w-3 h-3 fill-current' />
        </div>
      )}
    </button>
  );
}

function Toolkit(props: {
  themeColor: HexColor;
  currColor?: HexColor;
  drawable?: boolean;
  undoable?: boolean;
  onChangeColor: (color: HexColor) => void;
  onUndo: () => void;
}) {
  const { themeColor, currColor } = props;
  const colors = useMemo<HexColor[]>(
    () => [themeColor, shadeColor(themeColor, -30), '#000000'],
    [themeColor]
  );
  return (
    <div
      className='self-center w-max flex flex-col items-center justify-center 
    gap-2 bg-black bg-opacity-40 rounded-xl px-4 py-2 
    absolute top-0 -right-2 transform translate-x-full'
    >
      {colors.map((color, i) => (
        <ColorButton
          key={`${color}_${i}`}
          myColor={color}
          active={color === currColor}
          onClick={() => props.onChangeColor(color)}
          disabled={!props.drawable}
        />
      ))}
      {props.undoable && (
        <button
          className='btn active:bg-gray-400 active:bg-opacity-20 
         hover:bg-gray-400 hover:bg-opacity-40 rounded-xl w-10 h-10 
         flex items-center justify-center border-white border border-opacity-80'
          disabled={!props.drawable}
          onClick={props.onUndo}
        >
          <UndoIcon className='w-5 h-5 fill-current' />
        </button>
      )}
    </div>
  );
}

export function DrawingBoard(props: {
  cvs: DrawingCanvas;
  brush: Brush;
  width: number;
  height: number;
  themeColor: HexColor;
  drawable?: boolean;
  undoable?: boolean;
}): JSX.Element {
  const { cvs, brush, width, height, themeColor, drawable, undoable } = props;
  const ref = useRef<HTMLDivElement>(null);
  const forceUpdate = useForceUpdate();

  useEffect(() => {
    cvs.addEventListeners(brush.id);
  }, [cvs, brush]);

  useEffect(() => {
    if (!themeColor) return;
    brush.brushColor = themeColor as HexColor;
  }, [brush, themeColor]);

  useEffect(() => {
    if (!ref.current) return;
    cvs.attach(ref.current);
    log.info('cvs attached');
    return () => {
      cvs.detach();
      log.info('cvs detached');
    };
  }, [cvs]);

  useEffect(() => {
    log.info('cvs drawable', { drawable: !!drawable });
    cvs.drawable = !!drawable;
  }, [cvs, drawable]);

  const onUndo = () => {
    if (!undoable) return;
    cvs.undo(brush.id);
  };
  const onChangeBrushColor = (color: HexColor) => {
    brush.brushColor = color;
    forceUpdate();
  };

  return (
    <div className='relative'>
      <div
        className='transform-gpu transition-size duration-500'
        ref={ref}
        style={{
          boxShadow: '6px 8px 11px rgba(0, 0, 0, 0.66)',
          width,
          height,
        }}
      />
      <Toolkit
        themeColor={themeColor}
        currColor={brush.brushColor}
        onChangeColor={onChangeBrushColor}
        onUndo={onUndo}
        drawable={drawable}
        undoable={undoable}
      />
    </div>
  );
}
