import React, { useState } from 'react';

import { useLiveCallback } from '../../../../../hooks/useLiveCallback';
import { ArrowDownIcon } from '../../../../icons/Arrows';

export function FieldSectionHeader(props: { children: React.ReactNode }) {
  return (
    <div className='text-xl text-white font-bold py-2'>{props.children}</div>
  );
}

export function FieldContainer(props: {
  children: React.ReactNode;
  styles?: Partial<{
    bg: string;
    border: string;
    layout: string;
  }>;
}) {
  return (
    <div
      className={`
        w-full p-7.5
        ${props.styles?.bg ?? 'bg-main-layer'}
        ${props.styles?.border ?? 'border border-secondary rounded-xl'}
        ${props.styles?.layout ?? 'flex flex-row gap-5'}
      `}
    >
      {props.children}
    </div>
  );
}

export function FieldPanel(props: { children: React.ReactNode }) {
  return <div className='flex-1 flex flex-col gap-3'>{props.children}</div>;
}

export function FieldTitle(props: { children: React.ReactNode }) {
  return <div className='text-base text-white font-bold'>{props.children}</div>;
}

export function FieldDescription(props: { children: React.ReactNode }) {
  return <div className='text-sm text-icon-gray'>{props.children}</div>;
}

export function SimpleFieldEditor(props: {
  name: React.ReactNode;
  description?: React.ReactNode;
  children?: React.ReactNode;
  styles?: Parameters<typeof FieldContainer>[0]['styles'];
}) {
  return (
    <FieldContainer styles={props.styles}>
      <FieldPanel>
        <FieldTitle>{props.name}</FieldTitle>
        {props.description && (
          <FieldDescription>{props.description}</FieldDescription>
        )}
      </FieldPanel>
      <div className='w-1/2 xl:w-1/3'>{props.children}</div>
    </FieldContainer>
  );
}

export function CollapsibleFields(props: {
  children: React.ReactNode;
  buttonLabel: string;
  initiallyOpen?: boolean;
}) {
  const [isOpen, setIsOpen] = useState(props.initiallyOpen ?? false);
  const handleToggle = useLiveCallback(() => {
    setIsOpen((open) => !open);
  });

  return (
    <div className='w-full'>
      <div className='w-full flex justify-center py-4'>
        <button
          type='button'
          className='btn text-base text-white font-medium tracking-wide px-2 py-1 rounded flex items-center gap-1 transition-colors hover:bg-lp-gray-001'
          onClick={handleToggle}
        >
          {props.buttonLabel}
          <ArrowDownIcon
            className={`
              w-3.5 h-3.5 fill-current transform transition-transform
              ${isOpen ? '-rotate-180' : 'rotate-0'}
              delay-300 ease-in-out duration-300
            `}
          />
        </button>
      </div>
      {isOpen && <div className='space-y-3'>{props.children}</div>}
    </div>
  );
}
