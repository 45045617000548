import { useEffect, useRef, useState } from 'react';
import {
  useEffectOnce,
  useInterval,
  usePrevious,
  useTimeoutFn,
} from 'react-use';
import { proxy, useSnapshot } from 'valtio';

import {
  type JeopardyBlock,
  JeopardyBlockGameSessionStatus,
} from '@lp-lib/game';
import { ConnectionStatus } from '@lp-lib/shared-schema';

import { useLiveCallback } from '../../../../hooks/useLiveCallback';
import { isReconnecting } from '../../../../store/utils';
import { assertExhaustive, sleep } from '../../../../utils/common';
import {
  useCohostClientId,
  useHostClientId,
  useLastJoinedParticipantByUserId,
  useParticipantsAsArrayGetter,
} from '../../../Player';
import { useStageControlAPI } from '../../../Stage';
import { useLocalLoadedGamePack } from '../../GamePlayStore';
import {
  useGameSessionActionsSignalManager,
  useGameSessionLocalTimer,
  useGameSessionStatus,
} from '../../hooks';
import { ondWaitEnd } from '../../OndPhaseRunner';
import { type GameControlProps } from '../Common/GameControl/types';
import { useStableBlock } from '../Common/hooks';
import {
  makeJeopardyGameControlAPIInitialState,
  useJeopardyGameControlAPI,
  useJeopardyGameSharedAPI,
  useSubscribeSharedState,
} from './JeopardyBlockProvider';

function Loaded() {
  const api = useJeopardyGameControlAPI();
  useEffectOnce(() => {
    api?.resetGame();
  });
  return null;
}

function GameInit(props: GameControlProps<JeopardyBlock>): JSX.Element | null {
  const { block } = props;
  const api = useJeopardyGameControlAPI();
  const gamePack = useLocalLoadedGamePack();
  useEffectOnce(() => {
    api?.initGame(block, gamePack?.aiHostSettings?.voiceId);
  });
  return null;
}

function GameEnd(): JSX.Element | null {
  const api = useJeopardyGameControlAPI();
  useEffectOnce(() => {
    api?.resetGame();
  });
  return null;
}

function GameRunner() {
  const control = useJeopardyGameControlAPI();
  const [snapshottable] = useState(() =>
    proxy(makeJeopardyGameControlAPIInitialState())
  );
  const controlState = useSnapshot(control?.state ?? snapshottable);
  const currentGameState = controlState.currentState;

  switch (currentGameState) {
    case 'INIT_BOARD':
      return <InitBoard />;
    case 'PRESENT_CATEGORIES':
      return <PresentCategories />;
    case 'INIT_CLUE_SELECTOR':
      return <InitClueSelector />;
    case 'INSTRUCT_CLUE_SELECTOR':
      return <InstructClueSelector />;
    case 'WAIT_FOR_CLUE_SELECTION':
      return <WaitForClueSelection />;
    case 'START_TURN':
      return <StartTurn />;
    case 'WAIT_FOR_BUZZERS':
      return <WaitForBuzzers />;
    case 'PREPARE_CONTESTANT_TO_ANSWER':
      return <PrepareContestant />;
    case 'WAIT_FOR_ANSWER':
      return <WaitForAnswer />;
    case 'WAIT_FOR_JUDGEMENTS':
      return <WaitForJudgements />;
    case 'REVEAL_VERDICT':
      break;
    case 'REVEAL_ANSWER':
      return <RevealAnswer />;
    case 'GAME_OVER':
      return <GameOver />;
    case null:
    case undefined:
      break;
    default:
      assertExhaustive(currentGameState);
      break;
  }
  return null;
}

function useWatchParticipantAvailability(
  uid: string | null | undefined,
  onUnavailable: () => void
) {
  const participant = useLastJoinedParticipantByUserId(uid);
  const handleUnavailable = useLiveCallback(onUnavailable);

  const checkParticipantAvailablity = useLiveCallback(() => {
    if (!uid) return;
    if (
      !participant ||
      (participant.status === ConnectionStatus.Disconnected &&
        !isReconnecting(participant)) ||
      !!participant.away
    ) {
      handleUnavailable();
    }
  });

  useInterval(checkParticipantAvailablity, 1000);
}

function InitBoard() {
  const control = useJeopardyGameControlAPI();
  const transitioning = useRef(false);
  useTimeoutFn(() => {
    if (transitioning.current) return;
    transitioning.current = true;
    control?.boardReady();
  }, 4000);
  return null;
}

function PresentCategories() {
  const control = useJeopardyGameControlAPI();
  useEffectOnce(() => {
    async function run() {
      while (await control?.presentNextCategory()) {
        await sleep(1000);
      }
    }
    run();
  });
  return null;
}

function InitClueSelector() {
  const control = useJeopardyGameControlAPI();
  const getParticipants = useParticipantsAsArrayGetter();

  useEffectOnce(() => {
    const participants = getParticipants({
      filters: [
        'host:false',
        'cohost:false',
        'status:connected',
        'team:true',
        'staff:false',
        'away:false',
      ],
    });

    if (participants.length === 0) {
      // pick any clue
      control?.pickRandomClue();
    } else {
      const rando =
        participants[Math.floor(Math.random() * participants.length)];
      control?.setClueSelector(rando.id);
    }
  });
  return null;
}

function InstructClueSelector() {
  const control = useJeopardyGameControlAPI();
  useEffectOnce(() => {
    control?.instructClueSelector();
  });
  return null;
}

function WaitForClueSelection() {
  const control = useJeopardyGameControlAPI();
  const shared = useJeopardyGameSharedAPI();
  const gameState = useSnapshot(shared.state).game;
  const transitioning = useRef(false);
  const currTime = useGameSessionLocalTimer();
  const prevTime = usePrevious(currTime);
  const timesup = prevTime === 1 && currTime === 0;

  // transition if the selector is unavailable
  useWatchParticipantAvailability(gameState?.clueSelectorUid, () => {
    if (transitioning.current) return;
    transitioning.current = true;
    control?.pickRandomClue();
  });

  // transition if the selectedClueId is set.
  useEffect(() => {
    if (transitioning.current || !gameState?.selectedClueId) return;
    transitioning.current = true;
    control?.initTurn(gameState.selectedClueId);
  }, [gameState?.selectedClueId, control]);

  // transition if the timer is up.
  useEffect(() => {
    if (transitioning.current || !timesup) return;
    transitioning.current = true;
    control?.pickRandomClue();
  }, [timesup, control]);

  return null;
}

function StartTurn() {
  const control = useJeopardyGameControlAPI();
  const transitioning = useRef(false);

  useTimeoutFn(async () => {
    if (transitioning.current) return;
    transitioning.current = true;

    await control?.playVOQuestion();
    await control?.enableTurnBuzzer();
  }, 1000);

  return null;
}

// in the WAIT_FOR_BUZZERS state, we can progress when all known participants have buzzed in.
function useWatchAllParticipantsBuzzed(onAllBuzzed: () => void) {
  const getParticipants = useParticipantsAsArrayGetter();
  // in this phase we should watch the buzz queue...
  const buzzer = useJeopardyGameSharedAPI().buzzer;
  const submissionsSnap = useSnapshot(buzzer.state).submissions;
  const handleAllBuzzed = useLiveCallback(onAllBuzzed);

  useEffect(() => {
    const submissions = submissionsSnap;
    if (!submissions) return;
    const participants = getParticipants({
      filters: [
        'host:false',
        'cohost:false',
        'status:connected',
        'team:true',
        'staff:false',
        'away:false',
      ],
    });
    const allBuzzed = participants.every((p) => {
      return p.id in submissions;
    });
    if (allBuzzed) {
      handleAllBuzzed();
    }
  }, [submissionsSnap, handleAllBuzzed, getParticipants]);
}

function WaitForBuzzers() {
  const control = useJeopardyGameControlAPI();
  const transitioning = useRef(false);

  const currTime = useGameSessionLocalTimer();
  const prevTime = usePrevious(currTime);
  const timesup = prevTime === 1 && currTime === 0;

  // transition when the timer is up
  useEffect(() => {
    if (transitioning.current || !timesup) return;
    transitioning.current = true;
    control?.startTurn();
  }, [timesup, control]);

  // transition when everyone has buzzed
  useWatchAllParticipantsBuzzed(() => {
    if (transitioning.current) return;
    transitioning.current = true;
    control?.startTurn();
  });

  return null;
}

function PrepareContestant() {
  const control = useJeopardyGameControlAPI();
  const transitioning = useRef(false);
  useEffectOnce(() => {
    if (transitioning.current) return;
    transitioning.current = true;
    control?.prepareContestantToAnswer();
  });
  return null;
}

function WaitForAnswer() {
  const control = useJeopardyGameControlAPI();
  const shared = useJeopardyGameSharedAPI();
  const gameState = useSnapshot(shared.state).game;
  const transitioning = useRef(false);

  const currTime = useGameSessionLocalTimer();
  const prevTime = usePrevious(currTime);
  const timesup = prevTime === 1 && currTime === 0;

  // the user indicated they are done.
  useEffect(() => {
    if (transitioning.current || !gameState?.answerSubmitted) return;
    transitioning.current = true;
    control?.enableJudgements();
  }, [control, gameState?.answerSubmitted]);

  // transition if the timer is up.
  useEffect(() => {
    if (transitioning.current || !timesup) return;
    transitioning.current = true;
    control?.enableJudgements();
  }, [timesup, control]);

  return null;
}

// in the WAIT_FOR_JUDGEMENTS state, we can progress when all judges have judged.
function useWatchAllJudgments(onAllVoted: () => void) {
  const getParticipants = useParticipantsAsArrayGetter();
  // we need to know a participants role to determine if they are a judge.
  const shared = useJeopardyGameSharedAPI();
  // in this phase we should watch the polls...
  const poll = useJeopardyGameSharedAPI().poll;
  const votesSnap = useSnapshot(poll.state).votes;
  const handleAllVoted = useLiveCallback(onAllVoted);

  useEffect(() => {
    const votes = votesSnap;
    if (!votes) return;
    const participants = getParticipants({
      filters: [
        'host:false',
        'cohost:false',
        'status:connected',
        'team:true',
        'staff:false',
        'away:false',
      ],
    });
    const allVoted = participants.every((p) => {
      const turnRole = shared.getTurnRole(shared.state, p.id);
      switch (turnRole) {
        case 'judge':
          return p.id in votes;
        case 'contestant':
          return p.id in votes;
        default:
          return true;
      }
    });
    if (allVoted) {
      handleAllVoted();
    }
  }, [votesSnap, handleAllVoted, getParticipants, shared]);
}

function WaitForJudgements() {
  const control = useJeopardyGameControlAPI();
  const transitioning = useRef(false);

  const currTime = useGameSessionLocalTimer();
  const prevTime = usePrevious(currTime);
  const timesup = prevTime === 1 && currTime === 0;

  // transition if the timer is up.
  useEffect(() => {
    if (transitioning.current || !timesup) return;
    transitioning.current = true;
    control?.evaluateAnswerAttempt();
  }, [timesup, control]);

  // transition when all judges have judged.
  useWatchAllJudgments(() => {
    if (transitioning.current) return;
    transitioning.current = true;
    control?.evaluateAnswerAttempt();
  });

  return null;
}

function RevealAnswer() {
  const control = useJeopardyGameControlAPI();
  const transitioning = useRef(false);

  useTimeoutFn(async () => {
    if (transitioning.current) return;
    transitioning.current = true;
    control?.endTurn();
  }, 4000);

  return null;
}

function GameOver() {
  const transitioning = useRef(false);

  useTimeoutFn(async () => {
    if (transitioning.current) return;
    transitioning.current = true;
    ondWaitEnd();
  }, 4000);

  return null;
}

export function JeopardyBlockGameControl(
  props: GameControlProps<JeopardyBlock>
): JSX.Element | null {
  const block = useStableBlock(props.block);
  const gss = useGameSessionStatus<JeopardyBlockGameSessionStatus>();
  const api = useJeopardyGameControlAPI();
  const stageAPI = useStageControlAPI();
  useSubscribeSharedState();

  useEffect(() => {
    return () => {
      api?.resetGame(false);
    };
  }, [api]);

  // clear stage in case the game is reset.
  const hostClientId = useHostClientId();
  const cohostClientId = useCohostClientId();
  const signalMan = useGameSessionActionsSignalManager();
  useEffect(() => {
    return signalMan.connect({
      name: 'reset',
      before: async () => {
        await stageAPI.leaveAll([hostClientId, cohostClientId]);
      },
    });
  }, [cohostClientId, hostClientId, signalMan, stageAPI]);

  switch (gss) {
    case JeopardyBlockGameSessionStatus.LOADED:
      return <Loaded />;
    case JeopardyBlockGameSessionStatus.GAME_INIT:
      return <GameInit block={block} />;
    case JeopardyBlockGameSessionStatus.GAME_START:
      return <GameRunner />;
    case JeopardyBlockGameSessionStatus.END:
      return <GameEnd />;
    case JeopardyBlockGameSessionStatus.GAME_END:
    case JeopardyBlockGameSessionStatus.RESULTS:
    case JeopardyBlockGameSessionStatus.OUTRO:
    case null:
    case undefined:
      break;
    default:
      assertExhaustive(gss);
      break;
  }

  return null;
}
