import max from 'lodash/max';

import { type ModelsTTSScript } from '@lp-lib/api-service-client/public';
import {
  type BlockFields,
  type JeopardyBlock,
  type JeopardyBoard,
  type JeopardyCategory,
  type JeopardyClue,
  type JeopardyMediaLookup,
  type JeopardyMediaLookupKey,
} from '@lp-lib/game';
import { type Media } from '@lp-lib/media';

import logger from '../../../../logger/logger';
import { type FirebaseService, FirebaseValueHandle } from '../../../Firebase';
import { ondTemporaryStageMedia } from '../../ondTemporaryStage';
import {
  type Board,
  type BoardView,
  type Category,
  type Clue,
  type Game,
  type Root,
} from './types';

export const log = logger.scoped('jeopardy');

export class JeopardyUtils {
  static RootPath(venueId: string, instanceId: string): string {
    return `jeopardy/${venueId}/${instanceId}`;
  }

  static RootHandle(
    svc: FirebaseService,
    venueId: string,
    instanceId: string
  ): FirebaseValueHandle<Root> {
    return new FirebaseValueHandle(
      svc.prefixedSafeRef(this.RootPath(venueId, instanceId))
    );
  }

  static GamePath(venueId: string, instanceId: string): string {
    return `${this.RootPath(venueId, instanceId)}/game`;
  }

  static GameHandle(
    svc: FirebaseService,
    venueId: string,
    instanceId: string
  ): FirebaseValueHandle<Game> {
    return new FirebaseValueHandle(
      svc.prefixedSafeRef(this.GamePath(venueId, instanceId))
    );
  }

  static BoardPath(venueId: string, instanceId: string): string {
    return `${this.RootPath(venueId, instanceId)}/board`;
  }

  static BoardHandle(
    svc: FirebaseService,
    venueId: string,
    instanceId: string
  ): FirebaseValueHandle<Board> {
    return new FirebaseValueHandle(
      svc.prefixedSafeRef(this.BoardPath(venueId, instanceId))
    );
  }

  // note: the game play models are different from what is persisted in the database
  static AdaptBoardTypes(
    board: BlockFields<JeopardyBlock>['board'],
    boardSize: BlockFields<JeopardyBlock>['boardSize']
  ): Board {
    const items: [string, Clue | Category][] = [];
    const categories = board.categories ?? [];

    categories.forEach((category, col) => {
      if (col >= boardSize.numCols) return;

      items.push([
        category.id,
        {
          type: 'category',
          id: category.id,
          col,
          row: 0,
          category: category.name,
        },
      ]);

      category.clues.forEach((clue, row) => {
        if (row + 1 >= boardSize.numRows) return;
        items.push([
          clue.id,
          {
            type: 'clue',
            id: clue.id,
            col,
            row: row + 1,
            clue: clue.question,
            answer: clue.answer,
            value: clue.value,
          },
        ]);
      });
    });

    const itemMap = Object.fromEntries(items);
    return {
      ...boardSize,
      items: itemMap,
    };
  }

  static GetBackgroundMedia(
    block: JeopardyBlock,
    fallback = ondTemporaryStageMedia
  ): Media {
    return block.fields.backgroundMedia ?? fallback;
  }

  static GetCategoryVoiceOverMediaKey(
    item: JeopardyCategory | string
  ): JeopardyMediaLookupKey {
    if (typeof item === 'string') {
      return `${item}-category`;
    }
    return `${item.id}-category`;
  }

  static GetQuestionVoiceOverMediaKey(
    item: JeopardyClue | string
  ): JeopardyMediaLookupKey {
    if (typeof item === 'string') {
      return `${item}-question`;
    }
    return `${item.id}-question`;
  }

  static GetTTSScriptMediaKey(
    item: ModelsTTSScript | string
  ): JeopardyMediaLookupKey {
    if (typeof item === 'string') {
      return `host-script-${item}`;
    }
    return `host-script-${item.id}`;
  }

  static MediaLookupForUpdate(
    mediaLookup: JeopardyMediaLookup | null | undefined
  ): JeopardyMediaLookup {
    // we only need to send the mediaData to the server
    return Object.fromEntries(
      Object.entries(mediaLookup ?? {}).map(([key, value]) => [
        key,
        value ? { mediaData: value.mediaData } : undefined,
      ])
    );
  }

  static GetCategoryVoiceOverScript(category: JeopardyCategory): string {
    return category.nameScript || category.name;
  }

  static BoardSize(board: JeopardyBoard): JeopardyBlock['fields']['boardSize'] {
    const categories = board.categories ?? [];
    const numOfClues = max(categories.map((c) => c.clues.length)) ?? 0;
    const numOfCategoryRow = categories.length > 0 ? 1 : 0;
    return {
      numRows: numOfClues + numOfCategoryRow,
      numCols: categories.length,
    };
  }
}

export class BoardDirtyMap extends Map<string, boolean> {
  setCategoryDirty(itemId: string): void {
    this.set(itemId, true);
  }
  setClueDirty(itemId: string, view: BoardView): void {
    this.set(`${itemId}:${view}`, true);
  }
  isCategoryDirty(itemId: string): boolean {
    return this.get(itemId) ?? false;
  }
  isClueDirty(itemId: string, view: BoardView): boolean {
    return this.get(`${itemId}:${view}`) ?? false;
  }
}
