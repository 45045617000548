import { Link, NavLink } from '@remix-run/react';
import {
  createContext,
  type ReactNode,
  useContext,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react';

import { type DtoChannel } from '@lp-lib/api-service-client/public';

import { useLiveCallback } from '../../hooks/useLiveCallback';
import { safeWindowReload } from '../../logger/logger';
import { fromMediaDTO } from '../../utils/api-dto';
import { Emitter } from '../../utils/emitter';
import { MediaPickPriorityFHD, MediaUtils } from '../../utils/media';
import { BreadcrumbChain, Breadcrumbs } from '../Breadcrumbs';
import { ChannelMemberAvatar, SyncChannelMemberButton } from '../Channel';
import { ChannelUtils } from '../Channel/utils';
import { Loading } from '../Loading';
import { useOrgFeatureContext } from '../Organization';
import { type ProgramDetailProps } from './types';

function ChannelOwner(props: { channel: DtoChannel }): JSX.Element | null {
  const { channel } = props;
  return (
    <div className='flex flex-col items-end'>
      <div className='text-xs text-secondary'>Owner</div>
      <div className='text-sms text-white flex items-center justify-center'>
        <ChannelMemberAvatar
          exConnectType={channel.exConnectType}
          member={channel.creator}
        />
        <div>{channel.creator.fullName}</div>
      </div>
    </div>
  );
}

function Header(props: ProgramDetailProps): JSX.Element {
  const { program } = props;
  const iconUrl = program.basicSettings?.icon?.media?.url;
  const bannerUrl = MediaUtils.PickMediaUrl(
    fromMediaDTO(program.basicSettings?.banner?.media),
    { priority: MediaPickPriorityFHD }
  );

  if (bannerUrl) return <img className='h-30' src={bannerUrl} alt='banner' />;

  return (
    <div className='w-full bg-gradient-to-bl from-pairing-start to-pairing-end rounded-xl p-px'>
      <div className='w-full flex items-center justify-between bg-black h-20 rounded-xl px-7.5'>
        <div className='w-1/2 flex items-center'>
          {iconUrl && <img src={iconUrl} alt='icon' className='w-8 h-8 mr-3' />}
          <div className='text-white font-bold'>{program.name}</div>
        </div>
        <ChannelOwner {...props} />
      </div>
    </div>
  );
}

type Events = {
  'update-tag-right-node': (node: ReactNode) => void;
};

type ProgramDetailContext = {
  emitter: Emitter<Events>;
};

const Context = createContext<ProgramDetailContext | null>(null);

function ProgramDetailProvider(props: { children: ReactNode }) {
  const ctxValue = useMemo(() => ({ emitter: new Emitter<Events>() }), []);
  return <Context.Provider value={ctxValue}>{props.children}</Context.Provider>;
}

function useProgramDetailContext() {
  const ctx = useContext(Context);
  if (!ctx) {
    throw new Error('ProgramDetailProvider is not in the tree!');
  }
  return ctx;
}

export function useUpdateTabRightNode() {
  const { emitter } = useProgramDetailContext();
  return useLiveCallback((node: ReactNode) => {
    emitter.emit('update-tag-right-node', node);
  });
}

export function ProgramDetailLayout(
  props: ProgramDetailProps & {
    children?: ReactNode;
  }
): JSX.Element {
  const { channel, programLink, program } = props;
  const { routePrefix, adminMode, org } = useOrgFeatureContext();
  const channeName = ChannelUtils.ChannelName(channel, org);
  const breadcrumbs = useMemo(() => {
    const chain = new BreadcrumbChain();
    chain.add(
      'list',
      <div className='text-xl font-medium'>
        <Link to={`${routePrefix}/channels`}>All Channels</Link>
      </div>
    );
    chain.add('detail', <div className='text-xl font-bold'>{channeName}</div>);
    return chain;
  }, [channeName, routePrefix]);

  return (
    <ProgramDetailProvider>
      <div
        className={`w-full flex items-center justify-center ${
          !adminMode ? 'mt-10' : ''
        }`}
      >
        <div className='w-245'>
          <div className='flex items-center justify-between'>
            <Breadcrumbs chain={breadcrumbs} />
          </div>
          <div className='mt-4 mb-10'>
            <Header
              channel={channel}
              programLink={programLink}
              program={program}
            />
          </div>
          {props.children}
        </div>
      </div>
    </ProgramDetailProvider>
  );
}

export type ProgramDetailTabItem = {
  title: string;
  url: string;
  end?: boolean;
};

export function ProgramDetailTabs(props: {
  channelId: string;
  items: ProgramDetailTabItem[];
  right?: ReactNode;
}): JSX.Element {
  const { channelId, items } = props;
  const { adminMode } = useOrgFeatureContext();
  const [rightOverwrite, setRightOverwrite] = useState<ReactNode>(null);
  const { emitter } = useProgramDetailContext();

  useLayoutEffect(() => {
    if (!emitter) return;
    return emitter.on('update-tag-right-node', (node) => {
      setRightOverwrite(node);
    });
  }, [emitter]);

  return (
    <div className='flex items-center justify-between border-b border-secondary mb-8'>
      <div className='flex items-center gap-16'>
        {items.map((item) => (
          <NavLink
            end={item.end}
            key={item.title}
            to={item.url}
            className={({ isActive }) =>
              `tracking-[1.25px] pb-1 text-white ${
                isActive ? 'font-bold border-b-2 border-white mt-0.5' : ''
              }`
            }
          >
            {item.title}
          </NavLink>
        ))}
      </div>
      <div className='flex items-center gap-2 h-7.5'>
        {rightOverwrite ?? props.right}
        {adminMode && (
          <SyncChannelMemberButton
            channelId={channelId}
            afterSync={() => safeWindowReload()}
          />
        )}
      </div>
    </div>
  );
}

export function SubmitActions(props: {
  isSubmitting: boolean;
  isValid: boolean;
  onSave: () => void;
  onCancel: () => void;
}) {
  const { isSubmitting, isValid, onSave, onCancel } = props;
  return (
    <div className='flex items-center justify-center gap-2 mb-4'>
      <button
        className='btn-secondary w-26 h-10 flex items-center justify-center'
        type='button'
        onClick={onCancel}
      >
        Cancel
      </button>
      <button
        className='btn-primary w-26 h-10 flex items-center justify-center gap-1'
        type='button'
        disabled={!isValid || isSubmitting}
        onClick={onSave}
      >
        {isSubmitting && <Loading text='' />}
        Save
      </button>
    </div>
  );
}
