import {
  type MarketingBlock,
  MarketingBlockGameSessionStatus,
} from '@lp-lib/game';

import { BookNowModal } from '../../../Product/BookNow';
import { useFetchGameSessionGamePack, useGameSessionStatus } from '../../hooks';
import { type GamePlayProps } from '../Common/GamePlay/types';

export function MarketingBlockGamePlay(_props: GamePlayProps<MarketingBlock>) {
  const gss = useGameSessionStatus<MarketingBlockGameSessionStatus>();
  const gamePack = useFetchGameSessionGamePack();

  return (
    <div className='fixed w-screen h-screen text-white isolate'>
      {gss === MarketingBlockGameSessionStatus.PRESENTING && gamePack && (
        <BookNowModal gamePack={gamePack} trigger='marketing-block' />
      )}
    </div>
  );
}
