import { assertExhaustive } from '../../../../utils/common';
import {
  Points2XAnimation,
  Points3XAnimation,
  type PointsMultiplierAnimation,
} from '../../GameBlockCardAnimations';

/**
 * @param points the number of points at stake.
 * @returns an animation related to the multiplier of the point value, or null
 * if there isn't one. this is used primarily for the Question and
 * MultipleChoice block where there is a limited set of winnable points per
 * question.
 */
export function pointsTo2x3xCmp(
  points: null | '2x' | '3x'
): PointsMultiplierAnimation | null {
  switch (points) {
    case null:
      return null;
    case '2x':
      return Points2XAnimation;
    case '3x':
      return Points3XAnimation;
    default:
      assertExhaustive(points);
      return null;
  }
}
