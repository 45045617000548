import { useEffect, useRef } from 'react';
import { useSnapshot } from 'valtio';

import { useMyInstance } from '../../../../hooks/useMyInstance';
import logger from '../../../../logger/logger';
import { isReconnecting } from '../../../../store/utils';
import { ClientTypeUtils } from '../../../../types';
import { useParticipantsAsArray } from '../../../Player';
import { useStageContext, useStageControlAPI } from '../Provider';

const log = logger.scoped('stage');

export function useStageRecovery(): void {
  const ctx = useStageContext();
  const me = useMyInstance();
  const members = useSnapshot(ctx.state).members;

  const participants = useParticipantsAsArray({
    filters: [
      `id:${me?.id}`,
      'host:false',
      'cohost:false',
      `status:disconnected`,
    ],
    sorts: ['joinedAt:desc'],
  });

  const stageControl = useStageControlAPI();
  const recoveryAttemptedRef = useRef(false);

  useEffect(() => {
    if (
      !me?.clientId ||
      !ClientTypeUtils.isAudience(me.clientType) ||
      recoveryAttemptedRef.current ||
      participants.length === 0
    )
      return;
    recoveryAttemptedRef.current = true;
    const recoverableInstance = participants.filter((p) => {
      return members[p.clientId] && isReconnecting(p);
    })[0];
    if (!recoverableInstance) {
      log.info('recoverable instance not found');
      return;
    }
    stageControl.replace(recoverableInstance.clientId, me.clientId);
  }, [
    me?.clientId,
    me?.clientType,
    me?.id,
    members,
    participants,
    stageControl,
  ]);
}
