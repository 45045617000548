import { Fragment, useEffect, useMemo, useState } from 'react';

import { ProfileIndex } from '@lp-lib/crowd-frames-schema';
import { type DrawingPromptBlock } from '@lp-lib/game';

import { useAsyncCall } from '../../../../hooks/useAsyncCall';
import { useLiveCallback } from '../../../../hooks/useLiveCallback';
import { useMyInstance } from '../../../../hooks/useMyInstance';
import { ClientTypeUtils, isStaff } from '../../../../types';
import { BrowserTimeoutCtrl } from '../../../../utils/BrowserTimeoutCtrl';
import {
  type Classes,
  StagedTailwindTransition,
  type TailwindTransitionStage,
} from '../../../common/TailwindTransition';
import { CrowdFramesAvatar } from '../../../CrowdFrames';
import {
  LayoutAnchor,
  useLayoutAnchorRectValue,
} from '../../../LayoutAnchors/LayoutAnchors';
import { useParticipantByUserId } from '../../../Player';
import { useSoundEffect } from '../../../SFX';
import { useTeam } from '../../../TeamAPI/TeamV1';
import { useGameSessionLocalTimer } from '../../hooks';
import {
  type GamePlayUIStateControl,
  useCountdownPlaySFX,
  useOneTimePlayGoAnimation,
} from '../Common/GamePlay/GamePlayUtilities';
import { ProgressRing } from '../Common/GamePlay/ProgressRing';
import {
  useCurrMatch,
  useCurrPickedDrawing,
  useDrawing,
  useDrawingGame,
  useDrawingPromptGamePlayAPI,
  useDrawingPromptSharedAPI,
  useDrawingTitles,
  useDrawingTitleVote,
  useMyDrawingTitleVote,
} from './DrawingPromptProvider';
import {
  type DrawingTitle,
  type PickedDrawing,
  type VoteTitlePayload,
} from './types';
import { DrawingPromptUtils } from './utils';

function DrawingTitleVotePhase(props: {
  drawing: PickedDrawing;
  title: DrawingTitle;
  votable: boolean;
  onVote: (payload: VoteTitlePayload) => Promise<void | undefined>;
}): JSX.Element {
  const { drawing, title, votable, onVote } = props;
  const me = useMyInstance();
  const vote = useDrawingTitleVote(title.id);
  const isHost = ClientTypeUtils.isHost(me);
  const voted =
    isHost || !me?.id ? false : vote?.voters.map((v) => v.id).includes(me.id);

  const onClick = async () => {
    if (!votable || !me) return;
    await onVote({
      drawingId: drawing.id,
      titleId: title.id,
      titleTeamId: title.teamId,
      voterId: me.id,
    });
  };

  return (
    <div
      className={`flex items-center justify-center h-20
     bg-secondary border rounded-xl mx-4 px-4 py-2 select-none ${
       votable ? 'cursor-pointer hover:bg-[#444444]' : ''
     } ${voted ? 'border-tertiary' : 'border-secondary'}`}
      onClick={onClick}
    >
      <div className='text-sms text-center'>{title.text.toLowerCase()}</div>
    </div>
  );
}

function PointsAnimation(props: {
  points: number;
  color: string;
}): JSX.Element | null {
  const { points, color } = props;

  return (
    <div
      className={`font-cairo text-4xl font-black ${color} animate-slide-up`}
      style={
        {
          '--tw-slide-duration': '1.8s',
        } as React.CSSProperties
      }
    >
      {points >= 0 && '+'}
      {points}
    </div>
  );
}

function VoterDetail(props: {
  voter: { id: string; teamId: string };
}): JSX.Element | null {
  const p = useParticipantByUserId(props.voter.id);
  const team = useTeam(props.voter.teamId);

  if (!p) return null;

  return (
    <div
      className='relative w-20 h-20 rounded-3xl overflow-hidden'
      style={{ borderColor: team?.color, borderWidth: '3px' }}
    >
      <CrowdFramesAvatar
        participant={p}
        profileIndex={ProfileIndex.wh100x100fps8}
        enablePointerEvents={false}
        roundedClassname='rounded-none'
      />
    </div>
  );
}

function VoterList(props: {
  voters: { id: string; teamId: string }[];
}): JSX.Element {
  const { voters } = props;
  const height =
    useLayoutAnchorRectValue('drawing-voter-spacing-anchor', 'height') ?? 0;
  return (
    <div
      className='flex flex-wrap content-start gap-2 overflow-y-auto scrollbar'
      style={{
        height: height,
      }}
    >
      {voters.map((voter) => (
        <VoterDetail key={voter.id} voter={voter} />
      ))}
    </div>
  );
}

function DrawingTitleRevealPhase(props: {
  block: DrawingPromptBlock;
  drawing: PickedDrawing;
  title: DrawingTitle & { revealedAt: number };
  correct: boolean;
}) {
  const { block, drawing, title, correct } = props;
  const vote = useDrawingTitleVote(title.id);
  const votesCount = vote?.voters.length ?? 0;
  const teamId = correct ? drawing.teamId : title.teamId;
  const team = useTeam(teamId);

  const stages = useMemo<TailwindTransitionStage<Classes>[]>(() => {
    // rebuild the stages when title changed, this would never happen, just used
    // to avoid unused warning
    if (title.id === '') return [];
    return [
      { classes: 'translate-y-80 opacity-0' },
      { classes: 'translate-y-0 opacity-100' },
    ];
  }, [title.id]);

  const [showDetail, setShowDetail] = useState(false);

  useEffect(() => {
    return () => {
      setShowDetail(false);
    };
  }, [title.id]);

  const { play: playCashRegisterSFX } = useSoundEffect('drawingCashResgister');
  const { play: playFailPickSFX } = useSoundEffect('drawingFailPick');
  const { play: playWrongPickSFX } = useSoundEffect('drawingWrongPick');

  const playSFX = useLiveCallback(() => {
    if (correct) {
      if (votesCount > 0) {
        playCashRegisterSFX();
      } else {
        playFailPickSFX();
      }
    } else {
      playWrongPickSFX();
    }
  });

  const onTitleShowedUp = () => {
    setTimeout(() => {
      setShowDetail(true);
      playSFX();
    }, 1000);
  };

  const score = useMemo(() => {
    if (!vote) return 0;
    const teamPoints = DrawingPromptUtils.GetVotePoints(
      drawing.teamId,
      vote,
      correct,
      {
        correctPoints: block.fields.correctPromptPoints,
        incorrectPoints: block.fields.incorrectPromptPoints,
      }
    );
    return teamPoints.get(teamId) ?? 0;
  }, [
    block.fields.correctPromptPoints,
    block.fields.incorrectPromptPoints,
    correct,
    drawing.teamId,
    teamId,
    vote,
  ]);

  const textColor = correct ? 'text-[#39D966]' : 'text-[#8C6FFF]';
  const bgColor = correct ? 'bg-[#39D966]' : 'bg-[#8C6FFF]';

  return (
    <div className='w-full h-full px-4 flex flex-col items-start justify-start gap-4'>
      <StagedTailwindTransition
        stages={stages}
        debugKey='drawing-reveal-title'
        onComplete={onTitleShowedUp}
      >
        {(ref, initial) => (
          <div
            ref={ref}
            className={`text-xl text-center font-medium bg-secondary 
      w-full h-24 flex flex-col items-center justify-center px-2 rounded-xl
      transform-gpu transition-transform duration-500 ${initial}`}
          >
            {title.text.toLowerCase()}
            {showDetail && (
              <div
                className={`rounded-lg flex flex-col items-center justify-center
        text-white text-2xs 2xl:text-sms font-bold absolute left-1/2 top-0 
        transform-gpu -translate-x-1/2 -translate-y-1/3 uppercase whitespace-nowrap 
        px-2 py-1 italic ${bgColor}`}
              >
                {`${correct ? 'true' : 'decoy'} prompt!`}
              </div>
            )}
            <div
              className={`relative ${textColor} ${bgColor} h-6 absolute w-full 
              -bottom-2 flex items-center justify-center rounded-b-xl ${
                showDetail ? 'opacity-100' : 'opacity-0'
              }`}
            >
              <div
                className={`text-sms font-bold italic ${
                  correct
                    ? votesCount > 0
                      ? 'text-black'
                      : 'text-white'
                    : 'text-[#303436]'
                }`}
              >{`${team?.name ?? 'unknown'}${
                correct ? '’s drawing' : ' wrote this!'
              }!`}</div>
            </div>
            {showDetail && (
              <div className='absolute -top-1 right-0 transform-gpu -translate-y-full'>
                <PointsAnimation points={score} color={textColor} />
              </div>
            )}
          </div>
        )}
      </StagedTailwindTransition>
      {
        <div
          className={`${
            showDetail ? 'opacity-100' : 'opacity-0'
          } flex flex-col flex-grow`}
        >
          <div className='text-xl mt-2 mb-2'>
            {correct
              ? votesCount > 0
                ? 'Here’s who guessed correctly!'
                : 'Bummer! Nobody guessed correctly!!'
              : 'Look who got fooled!'}
          </div>
          <div className='relative w-full flex-grow'>
            <VoterList voters={vote?.voters ?? []} />
            <LayoutAnchor
              id='drawing-voter-spacing-anchor'
              className='absolute w-full h-full'
            />
          </div>
        </div>
      }
    </div>
  );
}

function DrawingTitleList(props: {
  titles: Nullable<DrawingTitle[]>;
  children: (title: DrawingTitle) => JSX.Element;
}): JSX.Element {
  const titles = props.titles ?? [];
  return (
    <div className='flex flex-col gap-8'>
      {titles.map((title) => (
        <Fragment key={title.id}>{props.children(title)}</Fragment>
      ))}
    </div>
  );
}

export function DrawingPromptMatchPrompt(props: {
  block: DrawingPromptBlock;
  uiControl: GamePlayUIStateControl;
}): JSX.Element | null {
  const { block, uiControl } = props;
  const pickedDrawing = useCurrPickedDrawing();
  const titles = useDrawingTitles(pickedDrawing?.teamId, pickedDrawing?.id);
  const drawing = useDrawing(pickedDrawing?.teamId, pickedDrawing?.id);
  const half = Math.ceil(titles.length / 2);
  const left = titles.slice(0, half);
  const right = titles.slice(half);
  const me = useMyInstance();
  const isMyTeamDrawing =
    !!pickedDrawing && !!me?.teamId && pickedDrawing?.teamId === me.teamId;
  const gameAPI = useDrawingPromptGamePlayAPI();
  const vote = useMyDrawingTitleVote(drawing?.id ?? '');
  const isHost = ClientTypeUtils.isHost(me);
  const currMatch = useCurrMatch();
  const sharedAPI = useDrawingPromptSharedAPI();
  const prompt = sharedAPI.getPrompt(pickedDrawing?.teamId);
  const remainingTime = useGameSessionLocalTimer();
  const game = useDrawingGame();
  const { play: playPageFlipSFX } = useSoundEffect('drawingPageFlip');
  const [ready, setReady] = useState(false);

  useCountdownPlaySFX(game?.matchTimePerDrawing ?? 0, remainingTime, true);

  // play go animiation & sfx and the beginning of the match once, for 2nd+
  // drawing, we will play page flip sfx.
  useOneTimePlayGoAnimation(uiControl, () => true);

  // when switching drawings, hide the last one first, so we can avoid seeing
  // the drawings move back to center (from css transtion). And show the new
  // one with a delay.
  useEffect(() => {
    const ctrl = new BrowserTimeoutCtrl();
    if (currMatch.drawingIndex === 0) {
      setReady(true);
    } else {
      ctrl.set(() => {
        setReady(true);
        playPageFlipSFX();
      }, 500);
    }
    return () => {
      ctrl.clear();
      setReady(false);
    };
  }, [currMatch.drawingIndex, playPageFlipSFX]);

  const {
    state: { transformed: state },
    call: voteTitle,
  } = useAsyncCall(
    useLiveCallback(
      async (payload: VoteTitlePayload) => await gameAPI.voteTitle(payload)
    )
  );

  const [showResults, setShowResults] = useState(false);
  const reveal = currMatch.phase === 'reveal';

  // show results after drawing moves to the left
  useEffect(() => {
    if (!reveal) return;
    const ctrl = new BrowserTimeoutCtrl();
    ctrl.set(() => setShowResults(true), 1000);
    return () => {
      ctrl.clear();
      setShowResults(false);
    };
  }, [reveal]);

  if (!drawing || !pickedDrawing || !game) return null;

  const votable =
    !isMyTeamDrawing && !state.isRunning && !vote && !isHost && !isStaff(me);
  const revealed = currMatch.revealedTitles
    ? currMatch.revealedTitles[currMatch.revealedTitles.length - 1]
    : null;
  const revealedTitle = titles.find((t) => t.id === revealed?.id);

  return (
    <div
      className={`w-full h-full flex flex-col items-center relative px-4 ${
        ready ? 'opacity-100' : 'opacity-0'
      }`}
    >
      {!reveal && (
        <ProgressRing
          className='absolute left-0 top-0 transform -translate-x-1/2 -translate-y-2/3'
          currentTime={remainingTime}
          totalTime={game.matchTimePerDrawing}
        />
      )}
      <div className='text-2xl'>Final Step:</div>
      {reveal ? (
        <div className='text-2xl text-tertiary font-bold mt-4'>
          Here’s what everyone picked
        </div>
      ) : isMyTeamDrawing ? (
        <div className='text-2xl text-pink-001 font-bold mt-4'>
          No need to choose. This is your team’s drawing!
        </div>
      ) : (
        <div className='text-2xl text-tertiary font-bold mt-4'>
          Earn points for your team by picking the correct prompt!
        </div>
      )}
      <div className={`w-full flex absolute z-0 top-24`}>
        <div
          className={`${
            reveal ? 'w-4' : 'w-1/3'
          } flex-shink-0 transform-gpu transition-size duration-500`}
        />
        <div className='w-1/3 flex-shink-0'>
          <img className='w-full rounded-xl' src={drawing.url} alt='drawing' />
        </div>
        <div
          className={`${
            reveal ? 'w-[calc(66.66%-1rem)]' : 'w-1/3'
          } transform-gpu transition-size duration-500`}
        >
          {showResults && revealedTitle && (
            <DrawingTitleRevealPhase
              block={block}
              drawing={pickedDrawing}
              title={{
                ...revealedTitle,
                revealedAt: revealed?.revealedAt ?? 0,
              }}
              correct={revealedTitle.id === prompt?.id}
            />
          )}
        </div>
      </div>
      {/* the scrollbar needs extra space, make the width smaller to compensate */}
      {!reveal && (
        <div className='w-full flex overflow-y-auto scrollbar z-5 pt-5'>
          <div className='w-[32%]'>
            <DrawingTitleList titles={left}>
              {(title) => (
                <DrawingTitleVotePhase
                  drawing={pickedDrawing}
                  title={title}
                  votable={votable}
                  onVote={voteTitle}
                />
              )}
            </DrawingTitleList>
          </div>
          <div className='w-[36%]'></div>
          <div className='w-[32%]'>
            <DrawingTitleList titles={right}>
              {(title) => (
                <DrawingTitleVotePhase
                  drawing={pickedDrawing}
                  title={title}
                  votable={votable}
                  onVote={voteTitle}
                />
              )}
            </DrawingTitleList>
          </div>
        </div>
      )}
    </div>
  );
}
