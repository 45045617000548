import { useEffect, useLayoutEffect, useMemo } from 'react';

import { useFeatureQueryParam } from './useFeatureQueryParam';
import { useWindowDimensions } from './useWindowDimensions';

export function useDebugCanvas():
  | readonly [null, null]
  | readonly [HTMLCanvasElement, CanvasRenderingContext2D] {
  const enabled = useFeatureQueryParam('debug-canvas');
  const cvs = useMemo(
    () => (enabled ? document.createElement('canvas') : null),
    [enabled]
  );
  const ctx = useMemo(
    () => (enabled && cvs ? cvs.getContext('2d') : null),
    [cvs, enabled]
  );

  // Allow recomputing canvas size on window resize
  const { ratio } = useWindowDimensions();

  useLayoutEffect(() => {
    if (!cvs || !ratio) return;
    if (cvs.width !== window.innerWidth) cvs.width = window.innerWidth;
    if (cvs.height !== window.innerHeight) cvs.height = window.innerHeight;
  }, [cvs, ratio]);

  useLayoutEffect(() => {
    if (!enabled || !cvs) return;
    if (!cvs.parentNode) {
      document.body.appendChild(cvs);
      cvs.style.position = 'absolute';
      cvs.style.width = '100%';
      cvs.style.height = '100%';
      cvs.classList.add('pointer-events-off');
    }
  }, [cvs, enabled]);

  useEffect(() => {
    return () => {
      if (!cvs) return;
      cvs.parentNode?.removeChild(cvs);
    };
  }, [cvs]);

  return !cvs || !ctx ? [null, null] : [cvs, ctx];
}
