import { ArrowRightIcon } from '../icons/Arrows';
import { MusicPlaylistIcon } from '../icons/MusicPlaylistIcon';
import { SettingIcon } from '../icons/SettingIcon';
import { Loading } from '../Loading';
import { useMusicPlayerContext } from '../MusicPlayer/Context';
import { MusicPlayer } from '../MusicPlayer/HostTools';
import { MUSIC_PLAYER_DEFAULT_LIST_ID } from '../MusicPlayer/types';
import { RecorderDetails } from './RecorderDetails';
import { StreamingModeControl } from './StreamingModeControl';
import { StreamingMoreTools } from './StreamingMoreTools';
import {
  useIsStreamingToolsSubpanelOpen,
  useStreamingToolsSubpanelState,
} from './StreamingToolsContext';
import { Control } from './Utilities';

const MusicPlayerControl = (props: { handleOpen: () => void }): JSX.Element => {
  const { joined } = useMusicPlayerContext();
  const handleClick = () => {
    if (!joined) return;
    props.handleOpen();
  };

  return (
    <div
      id='music-player-host-control-entry'
      className={`${joined ? 'cursor-pointer' : 'cursor-not-allowed'}`}
      onClick={handleClick}
    >
      <Control
        text='Music Player'
        info="Manage the Venue's music playlist"
        icon={
          joined ? (
            <MusicPlaylistIcon className='w-full h-full fill-current' />
          ) : (
            <Loading text='' imgClassName='w-3.5 h-3.5' />
          )
        }
        hover={true}
      >
        <div className='text-white'>
          <ArrowRightIcon />
        </div>
      </Control>
    </div>
  );
};

const StreamingMoreToolsControl = (props: {
  handleOpen: () => void;
}): JSX.Element => {
  const handleClick = () => {
    props.handleOpen();
  };

  return (
    <div
      id='more-tools-control-entry'
      data-testid='more-tools-control-entry'
      className='cursor-pointer'
      onClick={handleClick}
    >
      <Control
        text='More Tools'
        info='Team Randomizer, Team Size, Game Mode, Broadcast, Reset Venue, etc.'
        icon={<SettingIcon className='w-full h-full fill-current' />}
        hover={true}
      >
        <div className='text-white'>
          <ArrowRightIcon />
        </div>
      </Control>
    </div>
  );
};

export const StreamingTools = (): JSX.Element => {
  const { setPlaylistById } = useMusicPlayerContext();

  const [getSubpanelState, setSubpanelState] = useStreamingToolsSubpanelState();
  const isSubpanelOpen = useIsStreamingToolsSubpanelOpen();

  return (
    <div className='w-full h-full flex flex-col items-center'>
      <div className={!isSubpanelOpen ? 'block' : 'hidden'}>
        <StreamingModeControl />
        <MusicPlayerControl
          handleOpen={() => {
            setPlaylistById(MUSIC_PLAYER_DEFAULT_LIST_ID);
            setSubpanelState('music-player', true);
          }}
        />
        <StreamingMoreToolsControl
          handleOpen={() => setSubpanelState('more-tools', true)}
        />
        <RecorderDetails />
      </div>
      {!getSubpanelState('music-player') && (
        <StreamingMoreTools
          handleClose={() => setSubpanelState('more-tools', false)}
        />
      )}
      {getSubpanelState('music-player') && (
        <MusicPlayer
          handleClose={() => setSubpanelState('music-player', false)}
        />
      )}
    </div>
  );
};
