import { type SVGProps } from 'react';

export function UnlockIcon(props: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      className={props.className || 'w-3 h-3 fill-current'}
      viewBox='0 0 12 15'
    >
      <path
        fillRule='evenodd'
        d='M11.257 6.367c0-.778-.617-1.415-1.371-1.415H7.829V3.548c.004.068.06.452.685.452.686 0 .686-.463.686-.463C9.2 1.557 7.69 0 5.77 0S2.343 1.556 2.343 3.537v1.415h-.686c-.754 0-1.371.637-1.371 1.415v7.075c0 .778.617 1.415 1.371 1.415h8.229c.754 0 1.371-.637 1.371-1.415V6.367zM7.828 4.952V3.537v.01-.01c0-1.202-.89-2.122-2.057-2.122-1.165 0-2.057.92-2.057 2.122v1.415h4.114zm-6.17 8.49V6.367h8.228v7.075H1.657zM5.77 8.49c-.754 0-1.371.637-1.371 1.415s.617 1.415 1.371 1.415c.755 0 1.372-.637 1.372-1.415S6.526 8.49 5.77 8.49z'
        clipRule='evenodd'
      ></path>
    </svg>
  );
}
