import { backoffSleep } from './backoffSleep';

/** "Wait" for an executor to return a truthy value, repeating until the max
 * attempts are met. */
export async function backoffWait<T>(
  executor: () => T,
  config: {
    label: string;
    maxAttempts: number;
    maxMs: number;
  },
  impls: {
    timeoutImpl?: typeof setTimeout;
    randImpl?: () => number;
  } = {
    timeoutImpl: setTimeout,
    randImpl: Math.random,
  }
) {
  let attempt = 0;
  while (true) {
    const result = await executor();
    if (result) return result;
    await backoffSleep(
      attempt,
      config.maxMs,
      impls.timeoutImpl,
      impls.randImpl
    );
    attempt += 1;
    if (attempt > config.maxAttempts)
      throw new Error(`${config.label}: max attempts exceeded`);
  }
}
