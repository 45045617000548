const IncorrectIcon = ({ className }: { className?: string }): JSX.Element => {
  return (
    <svg
      className={className || 'w-3.5 h-3.5'}
      viewBox='0 0 78 78'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M39 74C58.33 74 74 58.33 74 39C74 19.67 58.33 4 39 4C19.67 4 4 19.67 4 39C4 58.33 19.67 74 39 74ZM52.2167 17.6089C54.6233 19.3693 55.1477 22.748 53.3879 25.1555L48.1062 32.3813L55.3283 37.6603C57.7358 39.42 58.2603 42.7975 56.4999 45.204C54.7396 47.6106 51.3609 48.135 48.9534 46.3752L41.7344 41.0986L36.4566 48.3191C34.6969 50.7266 31.3194 51.2511 28.9128 49.4907C26.5062 47.7304 25.9818 44.3517 27.7415 41.9442L33.0172 34.7267L25.7898 29.4439C23.3823 27.6841 22.8577 24.3067 24.6181 21.9001C26.3785 19.4935 29.7572 18.9691 32.1647 20.7289L39.389 26.0095L44.6729 18.7806C46.4326 16.3731 49.8101 15.8486 52.2167 17.6089Z'
        fill='#EE3529'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M53.387 25.1555C55.1467 22.748 54.6223 19.3693 52.2157 17.609C49.8091 15.8486 46.4316 16.3731 44.6719 18.7806L39.388 26.0095L32.1637 20.7289C29.7562 18.9692 26.3775 19.4935 24.6171 21.9001C22.8568 24.3067 23.3813 27.6841 25.7888 29.4439L33.0162 34.7267L27.7406 41.9442C25.9808 44.3517 26.5052 47.7304 28.9118 49.4908C31.3184 51.2511 34.6959 50.7266 36.4556 48.3191L41.7334 41.0986L48.9524 46.3753C51.3599 48.135 54.7386 47.6106 56.499 45.2041C58.2593 42.7975 57.7348 39.42 55.3273 37.6603L48.1053 32.3813L53.387 25.1555Z'
        fill='#FFFBFB'
      />
    </svg>
  );
};

export { IncorrectIcon };
