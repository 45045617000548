import { useEffect } from 'react';

import { useLiveCallback } from '../../hooks/useLiveCallback';
import { ConfirmCancelModalHeading } from '../ConfirmCancelModalContext';
import {
  ConfirmCancelModalBox,
  ConfirmCancelModalText,
} from '../ConfirmCancelModalContext/useCancelConfirmModalStateRoot';

export function TransferCloudHostModal(props: { exec: () => Promise<void> }) {
  const exec = useLiveCallback(() => props.exec());

  useEffect(() => {
    exec();
  }, [exec]);

  return (
    <ConfirmCancelModalBox className='px-6 py-6'>
      <ConfirmCancelModalHeading>
        Restarting Remote Game Server
      </ConfirmCancelModalHeading>
      <ConfirmCancelModalText>Please Wait</ConfirmCancelModalText>
    </ConfirmCancelModalBox>
  );
}
