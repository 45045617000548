import React, { useMemo, useState } from 'react';
import { usePopperTooltip } from 'react-popper-tooltip';
import Select from 'react-select';

import type {
  ModelsTTSRenderSettings,
  ModelsTTSScript,
} from '@lp-lib/api-service-client/public';

import { useLiveCallback } from '../../hooks/useLiveCallback';
import { uuidv4 } from '../../utils/common';
import { csvToArray } from '../../utils/csv';
import { buildReactSelectStyles } from '../../utils/react-select';
import { TagQuery } from '../../utils/TagQuery';
import { IconCopyButton } from '../common/CopyButton';
import { CSVUtils } from '../Game/Blocks/Common/Editor/EditorUtilities';
import {
  FieldContainer,
  FieldDescription,
  FieldPanel,
  FieldTitle,
} from '../Game/Blocks/Common/Editor/FieldEditorUtilities';
import { DeleteIcon } from '../icons/DeleteIcon';
import { PlusIcon } from '../icons/PlusIcon';
import { TTSPreviewButton } from './TTSPreviewButton';
import {
  type TemplateRenderer,
  type VariableRegistry,
} from './VariableRegistry';

export type ScriptScenarioOption = {
  label: string;
  description: string;
  tags: string[];
  supplementalTags?: string[];
  supportedVariables?: VariableRegistry;
  templateRenderer?: TemplateRenderer;
};

export function TTSScriptsEditor(props: {
  title: React.ReactNode;
  description: React.ReactNode;

  value: ModelsTTSScript[] | null | undefined;
  scenarioOptions?: ScriptScenarioOption[];

  onAdd: (value: ModelsTTSScript) => void;
  onRemove: (value: ModelsTTSScript) => void;
  onChange: (prev: ModelsTTSScript, next: ModelsTTSScript) => void;
  onUpload: (ttsScripts: ModelsTTSScript[]) => void;

  ttsRenderSettings?: ModelsTTSRenderSettings | null;
  csvDownloadName?: string;
  getTagLabel?: (tag: string) => string;
  checkForFallbacks?: (
    query: TagQuery<ModelsTTSScript>,
    selectedScenario: ScriptScenarioOption | null | undefined
  ) => { ok: boolean; message?: string };
}) {
  const styles = useMemo(
    () => buildReactSelectStyles<ScriptScenarioOption>(),
    []
  );

  const [selected, setSelected] = useState<ScriptScenarioOption | undefined>(
    props.scenarioOptions?.[0]
  );
  const handleChange = useLiveCallback((value: ScriptScenarioOption | null) => {
    if (value) setSelected(value);
  });
  const selectedScripts = useMemo(() => {
    if (!selected?.tags) return props.value ?? [];
    const query = new TagQuery(props.value);
    return query.select(selected.tags);
  }, [props.value, selected?.tags]);

  const handleUpload = useLiveCallback((content: string | ArrayBuffer) => {
    const csv = csvToArray(content.toString());
    if (csv.length <= 1) return;
    const rows = csv.splice(1);

    const nextHostScripts: ModelsTTSScript[] = [];
    rows.forEach((row) => {
      if (row.length <= 1) return;

      const [script = '', tagString = ''] = row.map((r) => r.trim());
      if (!script) return;

      const tags = tagString.split(',').map((t) => t.trim());
      if (!tags) return;
      nextHostScripts.push({ id: uuidv4(), script, tags });
    });
    props.onUpload(nextHostScripts);
  });

  const downloadHref = useMemo(
    () => createCSVTTSScriptsFile(props.value),
    [props.value]
  );

  const checkForFallbacks = props.checkForFallbacks;
  const fallbackCheck = useMemo(() => {
    if (!checkForFallbacks) return { ok: true };
    return checkForFallbacks(new TagQuery(props.value), selected);
  }, [props.value, checkForFallbacks, selected]);

  return (
    <FieldContainer styles={{ layout: 'flex flex-col gap-4' }}>
      <FieldPanel>
        <div className='w-full flex items-center justify-between'>
          <FieldTitle>{props.title}</FieldTitle>
          <CSVUtils
            onUpload={handleUpload}
            downloadHref={downloadHref}
            downloadName={props.csvDownloadName ?? `scripts-${Date.now()}.csv`}
          />
        </div>
        <FieldDescription>{props.description}</FieldDescription>
      </FieldPanel>
      <div className='w-full'>
        <div className='w-full flex justify-between text-white pb-4 gap-6'>
          <div className='flex-1'>
            <div className='text-white font-bold'>Scripts</div>
            {selected && (
              <>
                <div className='text-icon-gray text-sms'>
                  {selected.description}
                  {selected.supportedVariables && (
                    <>
                      {' '}
                      <SupportedVariables
                        variableRegistry={selected.supportedVariables}
                      />
                    </>
                  )}
                </div>
                {fallbackCheck.ok ? null : (
                  <div className='mt-2 text-red-002 text-sms'>
                    {fallbackCheck.message ?? 'Fallbacks are not defined.'}
                  </div>
                )}
              </>
            )}
          </div>
          {props.scenarioOptions && (
            <Select<ScriptScenarioOption, false>
              classNamePrefix='select-box-v2'
              className='text-xs'
              styles={styles}
              options={props.scenarioOptions}
              value={selected}
              onChange={handleChange}
              isSearchable={false}
            />
          )}
        </div>

        <TTSScriptsSubEditor
          {...props}
          scripts={selectedScripts}
          scenarioOption={selected}
        />
      </div>
    </FieldContainer>
  );
}

function createCSVTTSScriptsFile(
  ttsScripts: ModelsTTSScript[] | null | undefined
): string {
  const rows: string[] = [];
  for (const script of ttsScripts ?? []) {
    rows.push(
      [script.script, script.tags.join(',')]
        .map((v) => `"${v.replace(/"/g, '""')}"`)
        .join(',')
    );
  }

  return URL.createObjectURL(
    new Blob([[['script', 'tags'].join(','), ...rows].join('\n')], {
      type: 'text/csv',
    })
  );
}

function TTSScriptsSubEditor(props: {
  scripts: ModelsTTSScript[];
  scenarioOption: ScriptScenarioOption | undefined;

  onAdd: (value: ModelsTTSScript) => void;
  onRemove: (value: ModelsTTSScript) => void;
  onChange: (prev: ModelsTTSScript, next: ModelsTTSScript) => void;

  ttsRenderSettings?: ModelsTTSRenderSettings | null;
  getTagLabel?: (tag: string) => string;
}) {
  const { scripts, scenarioOption, getTagLabel, onAdd, onRemove, onChange } =
    props;

  const { tags, supplementalTags, supportedVariables, templateRenderer } =
    scenarioOption ?? {};

  const handleAddScript = useLiveCallback(() => {
    onAdd({
      id: uuidv4(),
      script: '',
      tags: tags ?? [],
    });
  });

  const toggleSupplementalTag = useLiveCallback(
    (script: ModelsTTSScript, tag: string) => {
      const hasTag = script.tags.includes(tag);
      onChange(script, {
        ...script,
        tags: hasTag
          ? script.tags.filter((t) => t !== tag)
          : [...script.tags, tag],
      });
    }
  );

  const handleBeforeRender = useLiveCallback(
    async (v: string | null | undefined) => {
      if (!v) return v;
      if (templateRenderer) return (await templateRenderer.render(v)).script;
      if (supportedVariables)
        return (await supportedVariables.render(v)).script;
      return v;
    }
  );

  const scriptEls = useMemo(() => {
    return scripts.map((script) => {
      return (
        <div
          key={script.id}
          className='w-full p-2 bg-layer-002 rounded-md flex items-center'
        >
          <div className='flex-1 flex flex-col gap-2'>
            <textarea
              className='field m-0 py-2 resize-y scrollbar'
              defaultValue={script.script}
              placeholder='Enter script...'
              onBlur={(e) => {
                onChange(script, {
                  ...script,
                  script: e.target.value,
                });
              }}
            />
            {supplementalTags && (
              <div className='w-full flex flex-wrap items-center gap-2'>
                {supplementalTags.map((tag) => {
                  return (
                    <TTSScriptTag
                      key={tag}
                      label={getTagLabel?.(tag) ?? tag}
                      hasTag={script.tags.includes(tag)}
                      onClick={() => toggleSupplementalTag(script, tag)}
                    />
                  );
                })}
              </div>
            )}
          </div>
          <TTSPreviewButton
            script={script.script}
            settings={props.ttsRenderSettings}
            onBeforeRender={async (v) => handleBeforeRender(v)}
          />
          <button
            type='button'
            className='flex-none pr-2 btn text-red-002 flex items-center justify-center transform transition-transform hover:scale-110'
            onClick={() => onRemove?.(script)}
          >
            <DeleteIcon className='fill-current w-4 h-4' />
          </button>
        </div>
      );
    });
  }, [
    scripts,
    supplementalTags,
    props.ttsRenderSettings,
    onChange,
    getTagLabel,
    toggleSupplementalTag,
    handleBeforeRender,
    onRemove,
  ]);

  return (
    <div className='w-full space-y-4'>
      {scriptEls}
      <button
        type='button'
        className='btn flex justify-center items-center text-primary gap-1 text-sm hover:underline'
        onClick={handleAddScript}
      >
        <PlusIcon />
        Add Script
      </button>
    </div>
  );
}

function TTSScriptTag(props: {
  label: string;
  hasTag: boolean;
  onClick: () => void;
}) {
  return (
    <button
      type='button'
      className={`
        flex-none flex items-center gap-1
        btn px-2.5 py-1 text-white text-sms rounded-lg bg-light-gray
        border border-secondary transition-all transform
        ${props.hasTag ? 'opacity-100' : 'opacity-30 hover:opacity-100'}
      `}
      onClick={props.onClick}
    >
      {props.label}
      <PlusIcon
        className={`fill-current w-2.5 h-2.5 transition-transform ${
          props.hasTag ? '-rotate-45' : 'rotate-0'
        }`}
      />
    </button>
  );
}

export function SupportedVariables(props: {
  variableRegistry: VariableRegistry;
}) {
  const { getTooltipProps, setTooltipRef, setTriggerRef, visible } =
    usePopperTooltip({
      trigger: 'hover',
      placement: 'auto',
      interactive: true,
      delayHide: 150,
    });

  const variables = useMemo(() => {
    return [...props.variableRegistry.keys()].map((key, i) => (
      <div key={i} className='w-full flex items-center justify-between gap-3'>
        {`%${key}%`}
        <div className='text-icon-gray'>
          <IconCopyButton key={i} text={`%${key}%`} iconSize='w-2.5 h-2.5' />
        </div>
      </div>
    ));
  }, [props.variableRegistry]);

  return (
    <div ref={setTriggerRef} className='inline-block'>
      <div
        className={`
          w-5 h-5 flex items-center justify-center
          rounded-full
          transition-colors hover:bg-layer-001 bg-light-gray
          text-icon-gray text-xs font-bold cursor-pointer
        `}
      >
        %
      </div>
      {visible && (
        <div
          ref={setTooltipRef}
          {...getTooltipProps({
            className: `w-64 h-auto rounded-md text-white text-xs px-3 py-2 bg-black border border-secondary z-40`,
          })}
        >
          <div className='font-bold pb-1'>Supported Variables</div>
          <div className='font-normal'>{variables}</div>
        </div>
      )}
    </div>
  );
}
