import { useEffect } from 'react';

import {
  asFBReference,
  RTDBServerValueTIMESTAMP,
} from '@lp-lib/firebase-typesafe';

import { useIsController } from '../../../../hooks/useMyInstance';
import { type FirebaseService } from '../../../Firebase';
import { useGameSessionActionsSignalManager } from '../../hooks/gameSessionHooks';

type BlockOutputSchema = {
  [blockId: string]: {
    updatedAt: number | RTDBServerValueTIMESTAMP;
    value: string;
  };
};

export class BlockOutputDAO {
  constructor(
    venueId: string,
    svc: FirebaseService,
    private ref = asFBReference<BlockOutputSchema>(
      svc.prefixedSafeRef(`block-output/${venueId}`)
    )
  ) {}

  async writeForBlock(blockId: string, output: string) {
    await this.ref.update({
      [blockId]: { updatedAt: RTDBServerValueTIMESTAMP, value: output },
    });
  }

  async readForBlock(blockId: string) {
    const snapshot = await this.ref.child(blockId).get();
    return snapshot.val();
  }

  async clear() {
    await this.ref.remove();
  }
}

export function BlockOutputsClearer(props: {
  venueId: string;
  svc: FirebaseService;
}) {
  const signalman = useGameSessionActionsSignalManager();
  const isController = useIsController();

  useEffect(() => {
    if (!isController) return;

    return signalman.connect({
      name: 'reset',
      after: async () => {
        const dao = new BlockOutputDAO(props.venueId, props.svc);
        await dao.clear();
      },
    });
  }, [isController, props.svc, props.venueId, signalman]);

  return null;
}
