export function IcebreakerBlockIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      className={props.className ?? 'w-3.5 h-3.5 fill-current'}
      viewBox='0 0 15 14'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g id='Icons / Cards'>
        <path
          id='Rectangle 3140 (Stroke)'
          fillRule='evenodd'
          clipRule='evenodd'
          d='M8.47149 3.17112L2.67112 4.28856L4.28715 12.677L10.0875 11.5596L8.47149 3.17112ZM2.48194 3.30662C1.93963 3.41109 1.5847 3.93542 1.68917 4.47773L3.30521 12.8662C3.40969 13.4085 3.93401 13.7634 4.47633 13.659L10.2767 12.5415C10.819 12.437 11.1739 11.9127 11.0695 11.3704L9.45343 2.98195C9.34895 2.43963 8.82463 2.0847 8.28232 2.18917L2.48194 3.30662Z'
          fill='#1EE4FF'
        />
        <path
          id='Rectangle 3139'
          d='M5.47919 1.99519C5.53329 1.44556 6.02271 1.04386 6.57234 1.09795L12.5435 1.68568C13.0931 1.73978 13.4948 2.2292 13.4407 2.77883L12.5591 11.7355C12.505 12.2852 12.0156 12.6869 11.466 12.6328L5.49483 12.0451C4.94521 11.991 4.5435 11.5015 4.5976 10.9519L5.47919 1.99519Z'
          fill='#1EE4FF'
        />
      </g>
    </svg>
  );
}
