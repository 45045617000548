import { proxy } from 'valtio';

import {
  isServerValue,
  RTDBServerValueTIMESTAMP,
} from '@lp-lib/firebase-typesafe';

import { type Participant } from '../../types';
import { BrowserTimeoutCtrl } from '../../utils/BrowserTimeoutCtrl';
import { rsCounter } from '../../utils/rstats.client';
import {
  markSnapshottable,
  type ValtioSnapshottable,
  ValtioUtils,
} from '../../utils/valtio';
import { type ExperienceMetricsManagerExt } from '../ExperienceMetrics/ExperienceMetricsManager';
import type { FirebaseService } from '../Firebase';
import {
  type LatencyResult,
  latencyResultCompute,
  latencyResultNew,
} from './LatencyResult';

type LatencyRecord = {
  serverTime?: number | RTDBServerValueTIMESTAMP;
  clientTime?: number;
  serverAckTime?: number | RTDBServerValueTIMESTAMP;
};

export type LatencyMeterExt = {
  avg: LatencyResult;
  latest: LatencyResult;
  smoothed: LatencyResult;
};

export type LatencyMeasure = {
  config: {
    measureMs: number;
    logEmitMs: number;
    windowSize: number;
    smoothing: number;
    colorProperty: keyof LatencyResult;
    periodLabel: string;
    goodMs: number;
    poorMs: number;
  };
  ext: ValtioSnapshottable<LatencyMeterExt>;
  results: LatencyResult[];
  nextMeasure: BrowserTimeoutCtrl;
};

export function latencyMeasureNew(
  config: LatencyMeasure['config']
): LatencyMeasure {
  const lm: LatencyMeasure = {
    config,
    ext: markSnapshottable(
      proxy<LatencyMeterExt>({
        avg: latencyResultNew(),
        latest: latencyResultNew(),
        smoothed: latencyResultNew(),
      })
    ),
    results: [],
    nextMeasure: new BrowserTimeoutCtrl(),
  };

  return lm;
}

export function latencyMeasureDestroy(lm: LatencyMeasure): void {
  lm.nextMeasure.clear();
}

export function latencyMeasureStart(
  lm: LatencyMeasure,
  clientId: Participant['clientId'],
  svc: FirebaseService,
  metricsMan: ExperienceMetricsManagerExt | null
): void {
  const ref = svc.prefixedRef<LatencyRecord>(`latency-measure/${clientId}`);

  const measure = async () => {
    const startTimeMs = Date.now();
    await ref.set({ serverTime: RTDBServerValueTIMESTAMP });
    const sendTimeMs = Date.now();
    await (await ref.get()).val();
    const readTimeMs = Date.now();
    await ref.update({ clientTime: Date.now() });
    await (await ref.get()).val();
    await ref.update({ serverAckTime: RTDBServerValueTIMESTAMP });
    const final = await (await ref.get()).val();
    const clientAckTime = Date.now();
    if (
      !final ||
      isServerValue(final.serverTime) ||
      isServerValue(final.serverAckTime)
    )
      return;

    // https://ankitbko.github.io/blog/2022/06/websocket-latency/
    const latency =
      (final.serverAckTime ?? 0) -
      (final.serverTime ?? 0) -
      (clientAckTime - (final.clientTime ?? 0)) * 0.5;

    // TODO(drew): Can probably delete these after a good refactor of LatencyMeter.
    // - legacy values:
    // How long did a client write take
    const sendMs = sendTimeMs - startTimeMs;
    // How long did a client read take
    const recvMs = readTimeMs - sendTimeMs;

    // Including the send time, how far apart are the written firebase server
    // timestamp and local clocks? Note: probably better to use
    // .info/serverTimeOffset here
    // (https://firebase.google.com/docs/database/web/offline-capabilities#clock-skew).
    // This property is mostly here for curiosity
    const driftMs = (final.serverTime ?? 0) - startTimeMs;

    lm.results.push(latencyResultNew(sendMs, recvMs, latency, driftMs));

    // Keep results trimmed to the window size
    while (lm.results.length > lm.config.windowSize) {
      lm.results.shift();
    }

    const computed = latencyResultCompute(lm.results, lm.config.smoothing);
    ValtioUtils.update(lm.ext, computed);
    lm.nextMeasure.set(measure, lm.config.measureMs);

    // NOTE: use the actual latency and not rtt.
    metricsMan?.markGameLatency(latency);
    rsCounter('firebase-rtt-clock-drift-ms')?.set(driftMs);
    rsCounter('firebase-rtt-ms')?.set(latency);
  };

  measure();
}
