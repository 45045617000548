export function EyeIcon(props: React.SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      className={props.className ?? 'w-3.5 h-3.5 fill-current'}
      viewBox='0 0 14 15'
    >
      <path d='M14 7.47l-.003-.042-.003-.023a.76.76 0 00-.006-.047l-.004-.018a.847.847 0 00-.011-.05l-.005-.017-.016-.047-.007-.02a.7.7 0 00-.018-.043l-.011-.022-.016-.031C13.793 6.92 11.242 2.5 7 2.5 2.76 2.5.208 6.921.101 7.11a.82.82 0 00.008.797.675.675 0 00.175.195.56.56 0 00.694-.01.682.682 0 00.17-.2C1.171 7.852 3.446 3.934 7 3.934a6.728 6.728 0 012.994.969A7.79 7.79 0 0112.38 7.19a.52.52 0 01.099.309.52.52 0 01-.099.309 7.79 7.79 0 01-2.386 2.288 6.73 6.73 0 01-2.994.97c-1.636-.068-3.196-.812-4.39-2.097a.6.6 0 00-.449-.194.606.606 0 00-.44.214.74.74 0 00-.133.243.813.813 0 00.021.56.717.717 0 00.15.228C3.185 11.553 5.05 12.434 7 12.5c4.181 0 6.718-4.293 6.892-4.596l.003-.006.005-.008.007-.015a.747.747 0 00.022-.042l.011-.027.014-.035.011-.035.009-.03.01-.039.005-.027.005-.04.004-.03.002-.035v-.066z'></path>
      <path d='M9.036 7.536a2.036 2.036 0 10-4.072 0 2.036 2.036 0 004.072 0zm-2.822 0a.786.786 0 111.572 0 .786.786 0 01-1.572 0z'></path>
    </svg>
  );
}
