import {
  TeamDataList,
  RapidBlockAnswersData,
  BlockType,
  RapidBlockAnswerGrade,
  RapidBlockDetailScore,
} from '@lp-lib/game';
import { useEffect } from 'react';
import { getLogger } from '../../../../logger/logger';
import { firebaseService } from '../../../Firebase';
import { GameSessionTeamData } from '../../store/gameSessionStore';

const gameaudit = getLogger().scoped('game-system-audit');
const log = getLogger().scoped('rapid-submission');

export class RapidBlockDetailScoreMetaWriter {
  constructor(
    readonly venueId: string,
    readonly blockId: string,
    readonly svc = firebaseService
  ) {}

  watch() {
    const ref = this.svc.prefixedRef<
      TeamDataList<GameSessionTeamData<RapidBlockAnswersData>>
    >(`game-session-team-data/${this.venueId}/${this.blockId}`);
    ref.on('value', (snapshot) => {
      const data = snapshot.val();
      if (!data) return;
      for (const [teamId, teamData] of Object.entries(data)) {
        this.updateScore(teamId, teamData.data);
      }
    });
    return () => ref.off('value');
  }

  async updateScore(teamId: string, data: RapidBlockAnswersData) {
    const venueId = this.venueId;
    const blockId = this.blockId;
    gameaudit.info(`rapid-change`, {
      venueId,
      blockId,
      teamId,
      data,
    });

    if (!this.isRapidBlockAnswersData(data)) {
      gameaudit.info(`rapid invalid data`, {
        venueId,
        blockId,
        teamId,
        data,
      });
      return;
    }

    let corrects = 0;
    let previewPoints = 0;

    Object.keys(data).forEach((answerIndex: string) => {
      const answer = data[answerIndex];
      if (
        answer &&
        (answer.grade === RapidBlockAnswerGrade.DUPLICATE ||
          answer.grade === RapidBlockAnswerGrade.CORRECT)
      ) {
        corrects = corrects + 1;

        previewPoints = previewPoints + answer.points;
      }
    });

    const scoreRef = this.svc.prefixedRef<RapidBlockDetailScore>(
      `game-session-scores/${venueId}/${blockId}/${teamId}`
    );

    try {
      const result = await scoreRef.transaction((data) => {
        if (!data) {
          return {
            corrects: corrects,
            previewPoints: previewPoints,
            submittedAt: Date.now(),
            score: null,
            scoreOverride: null,
          };
        }
        // the rapid score is increasing forever, use max instead of version lock
        data.corrects = Math.max(data.corrects || 0, corrects);
        data.previewPoints = Math.max(data.previewPoints || 0, previewPoints);
        return data;
      });

      gameaudit.info('rapid write complete', {
        venueId,
        blockId,
        teamId,
        committed: result.committed,
        snapsnot: result.snapshot?.val(),
      });
    } catch (error) {
      log.error(`rapid-update-score-error`, {
        venueId,
        blockId,
        teamId,
      });
    }
  }

  isRapidBlockAnswersData(
    data: RapidBlockAnswersData | null
  ): data is RapidBlockAnswersData {
    if (!data) return false;
    if (typeof data !== 'object') return false;
    const values = Object.values(data);
    const possibleAnswer = values[0];
    return (
      possibleAnswer &&
      typeof possibleAnswer === 'object' &&
      'blockType' in possibleAnswer &&
      possibleAnswer.blockType === BlockType.RAPID &&
      !isNaN(Number(Object.keys(data)[0]))
    );
  }
}

export function useWriteRapidBlockDetailScoreMeta(
  venueId: string,
  blockId: string
) {
  useEffect(() => {
    const writer = new RapidBlockDetailScoreMetaWriter(venueId, blockId);
    return writer.watch();
  }, [venueId, blockId]);
}
