const OptionsIcon = ({ className }: { className?: string }): JSX.Element => {
  return (
    <svg
      className={className || 'w-3/5 h-3/5 fill-current'}
      viewBox='0 0 14 14'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M1.55556 8.5C0.696446 8.5 0 7.82843 0 7C0 6.17157 0.696446 5.5 1.55556 5.5C2.41467 5.5 3.11111 6.17157 3.11111 7C3.11111 7.82843 2.41467 8.5 1.55556 8.5ZM7 8.5C6.14089 8.5 5.44444 7.82843 5.44444 7C5.44444 6.17157 6.14089 5.5 7 5.5C7.85911 5.5 8.55556 6.17157 8.55556 7C8.55556 7.82843 7.85911 8.5 7 8.5ZM12.4444 8.5C11.5853 8.5 10.8889 7.82843 10.8889 7C10.8889 6.17157 11.5853 5.5 12.4444 5.5C13.3036 5.5 14 6.17157 14 7C14 7.82843 13.3036 8.5 12.4444 8.5Z'
      />
    </svg>
  );
};

export { OptionsIcon };
