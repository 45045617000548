import { uncheckedIndexAccess_UNSAFE } from './uncheckedIndexAccess_UNSAFE';

type KeyValuePairs = { [key: string]: unknown };

export function flattenObject(
  obj: object,
  parentKey = '',
  result: KeyValuePairs = {}
): KeyValuePairs {
  if (typeof obj !== 'object' || obj === null) return result;

  for (const key in obj) {
    if (!obj.hasOwnProperty(key)) continue;

    const newKey = parentKey ? `${parentKey}.${key}` : key;
    const value = uncheckedIndexAccess_UNSAFE(obj)[key];

    if (Array.isArray(value)) {
      value.forEach((item, index) => {
        const arrayKey = `${newKey}[${index}]`;
        if (typeof item === 'object' && item !== null) {
          flattenObject(item, arrayKey, result);
        } else {
          result[arrayKey] = item;
        }
      });
    } else if (typeof value === 'object' && value !== null) {
      flattenObject(value as KeyValuePairs, newKey, result);
    } else {
      result[newKey] = uncheckedIndexAccess_UNSAFE(obj)[key];
    }
  }
  return result;
}
