import { type UID } from 'agora-rtc-sdk-ng';
import { useCallback, useEffect, useRef } from 'react';

import { useIsController } from '../../../../../hooks/useMyInstance';
import logger from '../../../../../logger/logger';
import {
  useDebugStream,
  useIsCoreChannelJoined,
  usePublishStream,
  useRTCService,
} from '../../../../WebRTC';
import { EchoCanceledVideo } from '../../../EchoCanceledVideo';
import { useGameHostingController } from '../../../GameHostingProvider';
import { useUpdateGameStreaming } from '../../../GameStreamingStatusProvider';
import {
  useIsLiveGamePlay,
  useOndGameState,
  useOndPlaybackVersion,
  useVideoMixerOutputStream,
} from '../../../hooks';
import { OndVersionChecks } from '../../../OndVersionChecks';

const log = logger.scoped('GamePlayHostVideo');

function LocalStream(): JSX.Element {
  const rtcService = useRTCService('ond');
  const mixerOutputStream = useVideoMixerOutputStream();
  const { enqueueSynchronize, enqueueOp } = usePublishStream(rtcService);
  const ondPlaybackVersion = useOndPlaybackVersion();

  useEffect(() => {
    if (!mixerOutputStream) return;

    enqueueOp((rtc) => {
      const audioTrack = mixerOutputStream.getAudioTracks()?.[0];
      const videoTrack = mixerOutputStream.getVideoTracks()?.[0];

      if (!videoTrack) {
        rtc.log.warn('no video track');
      } else if (!OndVersionChecks(ondPlaybackVersion).ondAudioOnly) {
        rtc.switchVideo(videoTrack);
      }

      if (audioTrack) {
        rtc.switchAudio(audioTrack);
      }
    });

    enqueueSynchronize(true);

    return () => {
      enqueueSynchronize(false);
    };
  }, [enqueueOp, enqueueSynchronize, mixerOutputStream, ondPlaybackVersion]);

  return <></>;
}

function RemoteStreamWrapper(props: {
  containerClassName: string;
  className: string;
}): JSX.Element | null {
  return (
    <div className={`${props.containerClassName}`}>
      <RemoteStream
        {...props}
        containerClassName='w-full h-full absolute rounded-xl overflow-hidden'
      />
    </div>
  );
}

function RemoteStream(props: {
  containerClassName: string;
}): JSX.Element | null {
  const ref = useRef<HTMLDivElement>(null);
  const rtcService = useRTCService('ond');
  const controllerClientId = useGameHostingController()?.clientId;
  useDebugStream(ref.current, controllerClientId, rtcService);
  const updateGameStreaming = useUpdateGameStreaming();

  useEffect(() => {
    if (!controllerClientId || !ref.current) return;
    const ret = rtcService.play(controllerClientId, ref.current, {
      fit: 'cover',
    });
    updateGameStreaming('ond', ret.video);
    return () => {
      rtcService.stop(controllerClientId);
      updateGameStreaming('ond', false);
    };
  }, [rtcService, controllerClientId, updateGameStreaming]);

  const publishedCallback = useCallback(
    async (uid: UID, mediaType: 'audio' | 'video') => {
      if (mediaType === 'audio') {
        rtcService.playAudio(uid);
      }
      if (mediaType === 'video') {
        if (ref.current) {
          const played = rtcService.playVideo(uid, ref.current, {
            fit: 'cover',
          });
          updateGameStreaming('ond', played);
        }
        try {
          await rtcService.setRemoteVideoStreamType(uid, 0);
        } catch (error) {
          log.error('setRemoteVideoStreamType failed', error);
        }
      }
    },
    [rtcService, updateGameStreaming]
  );

  const unpublishedCallback = useCallback(
    (uid: UID, mediaType: 'audio' | 'video') => {
      if (mediaType === 'audio') {
        rtcService.stopAudio(uid);
      }
      if (mediaType === 'video') {
        rtcService.stopVideo(uid);
        updateGameStreaming('ond', true);
      }
    },
    [rtcService, updateGameStreaming]
  );

  useEffect(() => {
    rtcService.on('remote-user-published', publishedCallback);
    rtcService.on('remote-user-unpublished', unpublishedCallback);
    return () => {
      rtcService.off('remote-user-published', publishedCallback);
      rtcService.off('remote-user-unpublished', unpublishedCallback);
    };
  }, [rtcService, publishedCallback, unpublishedCallback]);

  return <div ref={ref} className={props.containerClassName}></div>;
}

export const GamePlayHostVideo = (): JSX.Element | null => {
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const isController = useIsController();

  const joined = useIsCoreChannelJoined('ond');
  const ondState = useOndGameState();

  const mediaId = 'video-mixer-stream';
  const mixerStream = useVideoMixerOutputStream();
  const isLive = useIsLiveGamePlay();

  if (
    isLive ||
    !joined ||
    !ondState ||
    ondState === 'ended' ||
    ondState === 'preparing'
  )
    return null;

  const containerClassName = 'w-screen h-full z-1 absolute object-cover';
  const className = 'w-screen h-full z-1 absolute object-cover';

  if (!isController) {
    return (
      <RemoteStreamWrapper
        containerClassName={containerClassName}
        className={className}
      />
    );
  }

  return (
    <>
      <EchoCanceledVideo
        ref={videoRef}
        containerClassName={containerClassName}
        className={className}
        mediaId={mediaId}
        srcObject={mixerStream}
        autoPlay={true}
        debugKey='game-play-host-video'
        volumeControl='host'
      />
      {isController && joined && <LocalStream />}
    </>
  );
};
