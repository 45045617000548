import { type User } from 'stream-chat';

import { RTDBServerValueTIMESTAMP } from '@lp-lib/firebase-typesafe';
import {
  type GameSessionPlayerData,
  type QuestionBlock,
  type QuestionBlockAnswerData,
  QuestionBlockGameSessionStatus,
} from '@lp-lib/game';

import logger from '../../../../logger/logger';
import { firebaseService } from '../../../Firebase';
import { gameSessionStore } from '../../store';
import { type GameSubmissionInputBlockAdaptor } from '../Common/GamePlay/Internal';
import { BlockKnifeUtils } from '../Shared';

const gameaudit = logger.scoped('game-system-audit');
const log = logger.scoped('game-session-store');

export const submitQuestionAnswer = async (
  answer: string | null,
  teamId: string | null,
  submitter: Pick<User, 'id'>
): Promise<QuestionBlockAnswerData | null> => {
  const {
    venueId,
    session: { blockSession },
    timers: { submission },
    refs,
  } = gameSessionStore;

  const blockId = blockSession?.block?.id ?? null;
  const userId = submitter.id;

  gameaudit.info(`audience: submission start`, {
    block: blockSession?.block
      ? BlockKnifeUtils.SummaryText(blockSession.block)
      : null,
    answerRaw: answer,
    timerWhenSubmitted: submission || 0,
    teamId,
  });

  if (!blockId || !userId) return null;

  const teamDataRef = refs.teamData;

  if (!teamDataRef) {
    log.error(
      'GameSession.submitAnswer.register',
      new Error('Failed to register teamDataRef')
    );
    return null;
  }

  const data = {
    answer,
    timerWhenSubmitted: submission || 0,
  };

  try {
    await teamDataRef
      .child<'data', QuestionBlockAnswerData>('data')
      .update(data);
  } catch (err) {
    log.error('GameSession.submitAnswer.update', err);
  }

  const ref = firebaseService.prefixedRef<GameSessionPlayerData>(
    `game-session-player-data/${venueId}/${blockId}/${userId}`
  );

  const pdata: GameSessionPlayerData = {
    answer,
    submittedAt: RTDBServerValueTIMESTAMP,
    timerWhenSubmitted: submission || 0,
    teamId,
  };

  try {
    await ref.set(pdata);
  } catch (err) {
    log.error('GameSession.submitAnswer.set', err);
  }

  gameaudit.info(`audience: submission written`, {
    block: blockSession?.block
      ? BlockKnifeUtils.SummaryText(blockSession.block)
      : null,
    answerRaw: answer,
    timerWhenSubmitted: submission || 0,
    teamId,
  });

  return data;
};

export class QuestionBlockUtils {
  static ToSubmissionInputAdaptor(
    block: QuestionBlock
  ): GameSubmissionInputBlockAdaptor {
    return {
      presentStatus: QuestionBlockGameSessionStatus.PRESENTING,
      countingStatus: QuestionBlockGameSessionStatus.COUNTING,
      waitingStatus: QuestionBlockGameSessionStatus.PRESENTING,
      timesupStatus: QuestionBlockGameSessionStatus.END,
      blockTime: block.fields.time,
      submit: submitQuestionAnswer,
    };
  }
}
