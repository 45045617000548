export function HeadToHeadBlockIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox='0 0 14 14'
      className={props.className ?? 'w-3.5 h-3.5 fill-current'}
    >
      <g fill='#FF545E'>
        <path
          fillRule='evenodd'
          d='M4.5 6.5a2 2 0 100-4 2 2 0 000 4zm0 .5a2.5 2.5 0 100-5 2.5 2.5 0 000 5z'
          clipRule='evenodd'
        ></path>
        <path
          fillRule='evenodd'
          d='M9.5 11.5a2 2 0 100-4 2 2 0 000 4zm0 .5a2.5 2.5 0 100-5 2.5 2.5 0 000 5z'
          clipRule='evenodd'
        ></path>
        <circle cx='10.5' cy='10.5' r='2.5'></circle>
        <path d='M8 6l.667-3.333.666.666 1-1.333v1.667H12l-1.333 1 .666.666L8 6z'></path>
        <path d='M6 8l-.667 3.333-.666-.666-1 1.333v-1.667H2l1.333-1-.666-.666L6 8z'></path>
        <circle cx='3.5' cy='3.5' r='2.5'></circle>
      </g>
    </svg>
  );
}
