import range from 'lodash/range';
import { Controller, useFormContext } from 'react-hook-form';

import { type ModelsProgramGroupSettings } from '@lp-lib/api-service-client/public';

import { TeamIcon } from '../icons/TeamIcon';

export type ProgramGroupFormData = {
  groupSettings: ModelsProgramGroupSettings;
};

const GROUP_SIZE_OPTIONS = range(2, 7).map((v) => ({
  label: `${v}`,
  value: v,
}));

function GroupSizeEditor(props: { disabled?: boolean }) {
  const { control } = useFormContext<ProgramGroupFormData>();
  return (
    <Controller
      name='groupSettings.size'
      control={control}
      render={({ field: { value, onChange } }) => {
        return (
          <div>
            <div className='font-bold mb-2'>
              <div>Group Size</div>
              <div className='text-3xs text-icon-gray font-medium'>
                Luna Park will make Intros in groups of:
              </div>
            </div>
            <div className='flex gap-1'>
              {GROUP_SIZE_OPTIONS.map((o) => (
                <button
                  key={o.value}
                  type='button'
                  className={`btn w-15 h-10 bg-secondary rounded-xl border ${
                    value === o.value ? 'border-primary' : 'border-secondary'
                  }`}
                  onClick={async () => {
                    onChange(o.value);
                  }}
                  disabled={props.disabled}
                >
                  {o.label}
                </button>
              ))}
            </div>
          </div>
        );
      }}
    />
  );
}

export function ProgramGroupEditor(props: { disabled?: boolean }) {
  return (
    <section className='w-full flex flex-col gap-5'>
      <header className='flex items-center gap-2 text-2xl font-medium'>
        <TeamIcon className='w-7.5 h-7.5 fill-current' />
        <div>Group</div>
      </header>
      <main className='flex flex-col gap-10'>
        <GroupSizeEditor disabled={props.disabled} />
      </main>
    </section>
  );
}
