import React, {
  type ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { useMyInstance } from '../../hooks/useMyInstance';
import { useVenueMode } from '../../hooks/useVenueMode';
import { VenueMode } from '../../types';
import { type Participant } from '../../types/user';
import { assertExhaustive } from '../../utils/common';
import {
  type RightPanelHandleUIAction,
  RightPanelTabState,
  type RightPanelUIAction,
  type RightPanelUIState,
} from './types';

type RightPanelContext = {
  tab: RightPanelTabState;
  handlePanelUIAction: RightPanelHandleUIAction;
};

const context = React.createContext<null | RightPanelContext>(null);

function reducer(
  state: RightPanelUIState,
  action: RightPanelUIAction,
  me: Participant | null,
  defaultTab: RightPanelTabState,
  venueMode: VenueMode,
  disableOutsideClickToClose: boolean
): RightPanelUIState {
  if (!me) return { tab: defaultTab };

  if (action.input === 'click-people') {
    if (state.tab === RightPanelTabState.People) {
      return { tab: RightPanelTabState.MiniChat };
    } else {
      return { tab: RightPanelTabState.People };
    }
  } else if (action.input === 'click-chat') {
    if (state.tab === RightPanelTabState.FullChat) {
      return { tab: RightPanelTabState.MiniChat };
    } else {
      return { tab: RightPanelTabState.FullChat };
    }
  } else if (action.input === 'click-chat-message') {
    if (state.tab === RightPanelTabState.FullChat) {
      return state;
    } else {
      return { tab: RightPanelTabState.FullChat };
    }
  } else if (action.input === 'click-collapse') {
    return { tab: RightPanelTabState.MiniChat };
  } else if (action.input === 'click-outside-panel') {
    if (
      disableOutsideClickToClose ||
      venueMode === VenueMode.Lobby ||
      !me?.teamId
    ) {
      // the panel is 'sticky' and cannot be closed from outside.
      return state;
    }
    return { tab: RightPanelTabState.MiniChat };
  } else if (action.input === 'send-emoji') {
    return { tab: RightPanelTabState.MiniChat };
  } else {
    assertExhaustive(action.input);
  }

  // Always return the default if in doubt.
  return { tab: defaultTab };
}

type RightPanelUIValueAction = {
  value: RightPanelTabState;
};

type RightPanelActionChannel =
  | RightPanelUIAction
  | RightPanelUIValueAction
  | null;

type RightPanelProviderProps = {
  children?: ReactNode;
  /**
   * The tab to initially select. When absent, the initial tab is derived from the user.
   */
  initialTab?: RightPanelTabState;
  /**
   * When true, completely disables the outside click to close feature. Otherwise, outside
   * clicks will close (minimize) the right panel only in game mode.
   */
  disableOutsideClickToClose?: boolean;
};

export const RightPanelProvider = ({
  children,
  initialTab,
  disableOutsideClickToClose = false,
}: RightPanelProviderProps): JSX.Element => {
  const me = useMyInstance();
  const venueMode = useVenueMode();

  const [panelState, setPanelState] = useState<RightPanelUIState>({
    tab: initialTab,
  });
  const [panelAction, putPanelAction] = useState<RightPanelActionChannel>(null);

  useEffect(() => {
    // This is structured as a "channel" to prevent bugs with useReducer not
    // updating current values in its closure, and to allow it to have effects
    // beyond just the single value returned by the reducer.

    // No action to consume
    if (!panelAction) return;

    if ('input' in panelAction) {
      // Compute the complex interaction based on user-input:
      const nextState = reducer(
        panelState,
        panelAction,
        me,
        initialTab ?? RightPanelTabState.MiniChat,
        venueMode,
        disableOutsideClickToClose
      );
      if (nextState) setPanelState(nextState);
    } else if (panelAction.value) {
      // Just set the panel state
      setPanelState({ tab: panelAction.value });
    }

    // Mark the action as consumed
    putPanelAction(null);
  }, [
    me,
    panelAction,
    panelState,
    initialTab,
    venueMode,
    disableOutsideClickToClose,
  ]);

  useEffect(() => {
    // If the tab is undefined then we have not initialized it. Set it
    // according to the client type.
    if (!panelState.tab && me) {
      putPanelAction({
        value: RightPanelTabState.MiniChat,
      });
    }
  }, [panelState.tab, me]);

  const value = useMemo(() => {
    return {
      tab: panelState.tab ?? RightPanelTabState.MiniChat,
      handlePanelUIAction: putPanelAction,
    };
  }, [panelState.tab, putPanelAction]);

  return <context.Provider value={value}>{children}</context.Provider>;
};

export function useRightPanelUIAction(): React.Dispatch<RightPanelUIAction> {
  const ctx = useContext(context);
  const noop = useCallback(() => void 0, []);
  if (!ctx) return noop;
  return ctx.handlePanelUIAction;
}

export function useRightPanelTab(): RightPanelTabState {
  const ctx = useContext(context);
  if (!ctx) throw new Error('RightPanelContext not found in tree!');
  return ctx.tab;
}
