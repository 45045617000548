import debounce from 'lodash/debounce';
import uniqBy from 'lodash/uniqBy';
import pluralize from 'pluralize';
import { useCallback, useMemo, useState } from 'react';
import {
  components,
  type FormatOptionLabelMeta,
  type GroupBase,
  type MultiValue,
} from 'react-select';
import AsyncSelect from 'react-select/async';
import useSWRImmutable from 'swr/immutable';
import useSWRMutation from 'swr/mutation';

import { type DtoSlackUser } from '@lp-lib/api-service-client/public';

import {
  apiService,
  type InviteOrganizersRequest,
} from '../../services/api-service';
import {
  type Organization,
  type Organizer,
  type OrgConnection,
} from '../../types';
import { type SlackChannel } from '../../types/slack';
import { assertExhaustive } from '../../utils/common';
import { buildReactSelectStyles } from '../../utils/react-select';
import { useAwaitFullScreenConfirmCancelModal } from '../ConfirmCancelModalContext';
import { ModalWrapper } from '../ConfirmCancelModalContext/ModalWrapper';
import { Loading } from '../Loading';

type OptionUser = {
  kind: 'user';
  user: DtoSlackUser;
};

type OptionChannel = {
  kind: 'channel';
  channel: SlackChannel;
  users: DtoSlackUser[];
};

type Option = OptionUser | OptionChannel;

type GroupedOption =
  | {
      kind: 'users';
      label: string;
      options: OptionUser[];
    }
  | {
      kind: 'channels';
      label: string;
      options: OptionChannel[];
    };

export async function loadNewSlackUsers(
  orgId: string
): Promise<DtoSlackUser[]> {
  const users = (
    await apiService.slack.queryUsers({
      type: 'all',
      orgId,
    })
  ).data.users;

  const failedEmails = (
    await apiService.organization.checkOrganizersInvite(orgId, {
      emails: users.map((u) => u.email),
    })
  ).data.failedResults.map((r) => r.email.toLowerCase());

  return users.filter((u) => !failedEmails.includes(u.email.toLowerCase()));
}

export function NewSlackUsersBanner(props: {
  connection: OrgConnection;
  newUsersCount: number;
  onAddAll: () => void;
}): JSX.Element {
  return (
    <div
      className='w-full px-4 py-5 bg-gradient-to-bl from-pairing-start to-pairing-end rounded-xl 
        flex justify-between items-center gap-2.5'
    >
      <p className='flex-1 text-sms font-bold'>
        We found <span className='text-primary'>{props.newUsersCount}</span>{' '}
        users in the{' '}
        <span className='text-tertiary'>{props.connection.name}</span> workspace
        without Luna Park access
      </p>
      <button
        type='button'
        onClick={props.onAddAll}
        className='btn-primary w-26 h-10'
      >
        Add All
      </button>
    </div>
  );
}

async function searchUsers(
  orgId: string,
  q: string
): Promise<GroupedOption | null> {
  if (q.startsWith('#')) return null;
  if (q.startsWith('@')) q = q.substring(1);
  if (!q) return null;

  const users = (
    await apiService.slack.queryUsers({
      orgId: orgId,
      type: 'byKeywords',
      keyword: q.startsWith('@') ? q.substring(1) : q,
    })
  ).data.users;

  return {
    kind: 'users',
    label: 'Users',
    options: users.map((u) => ({ kind: 'user', user: u })),
  };
}

async function searchChannels(
  orgId: string,
  q: string
): Promise<GroupedOption | null> {
  if (q.startsWith('@')) return null;
  if (q.startsWith('#')) q = q.substring(1);
  if (!q) return null;

  const { channels, newUsersByChannelId } = (
    await apiService.slack.searchChannels({
      orgId: orgId,
      types: 'public',
      keyword: q.startsWith('#') ? q.substring(1) : q,
      size: 3,
      withNewUsers: true,
    })
  ).data;

  return {
    kind: 'channels',
    label: 'Channels',
    options: channels.map((c) => ({
      kind: 'channel',
      channel: c,
      users: newUsersByChannelId[c.id] || [],
    })),
  };
}

async function search(orgId: string, q: string): Promise<GroupedOption[]> {
  const options = await Promise.all([
    searchChannels(orgId, q),
    searchUsers(orgId, q),
  ]);
  return options.filter((o) => !!o) as GroupedOption[];
}

const searchDebounce = debounce(
  async (
    orgId: string,
    q: string,
    callback: (options: GroupedOption[]) => void
  ) => {
    const options = await search(orgId, q);
    return callback(options);
  },
  200
);

export function ImportSlackUsersInput(props: {
  organization: Organization;
  users: DtoSlackUser[];
  onChange: (value: DtoSlackUser[]) => void;
  autofocus?: boolean;
}): JSX.Element {
  const { organization, users, onChange, autofocus } = props;

  const styles = useMemo(
    () =>
      buildReactSelectStyles<Option, true>({
        override: {
          control: {
            height: '100%',
            flexDirection: 'column',
            border: 0,
          },
          valueContainer: {
            width: '100%',
            padding: '10px',
            alignContent: 'flex-start',
            overflow: 'auto',
          },
          indicatorsContainer: {
            flexDirection: 'row-reverse',
          },
        },
      }),
    []
  );

  const loadOptions = (
    q: string,
    callback: (options: GroupedOption[]) => void
  ): void => {
    searchDebounce(organization.id, q, callback);
  };

  const handleMultiChange = (options: MultiValue<Option>) => {
    if (!onChange) return;
    const users = options.flatMap((o) =>
      o.kind === 'user' ? [o.user] : o.users
    );
    const unique = uniqBy(users, (u) => u.id);
    onChange(unique);
  };

  const formatGroupLabel = (group: GroupBase<Option>) => {
    return (
      <div className='text-white text-sms font-bold transform-none'>
        {group.label}
      </div>
    );
  };

  const formatOptionLabel = (
    option: Option,
    meta: FormatOptionLabelMeta<Option>
  ) => {
    const kind = option.kind;
    switch (kind) {
      case 'user':
        if (meta.context === 'value') {
          return (
            <div className='text-sms font-normal text-white'>
              {option.user.fullName}
            </div>
          );
        }
        return (
          <div className='ml-4 w-full flex flex-col justify-start'>
            <p className='text-sms font-normal text-white'>
              {option.user.fullName}
            </p>
            <p className='text-3xs font-medium text-secondary'>
              {option.user.email}
            </p>
          </div>
        );
      case 'channel':
        return (
          <div className='ml-4 w-full flex flex-col justify-start'>
            <span className='text-sms font-normal text-white'>
              #{option.channel.name}
            </span>
            <span className='text-3xs font-medium text-secondary'>
              {option.users.length} new {pluralize('user', option.users.length)}{' '}
              found
            </span>
          </div>
        );
      default:
        assertExhaustive(kind);
        return null;
    }
  };

  return (
    <AsyncSelect<Option, true>
      value={users
        .sort((a, b) => a.fullName.localeCompare(b.fullName))
        .map((u) => ({
          kind: 'user',
          user: u,
        }))}
      loadOptions={loadOptions}
      cacheOptions
      onChange={handleMultiChange}
      isMulti
      placeholder={
        <span className='italic ml-2'>
          Start typing to search for a channel, or add users one at a time
        </span>
      }
      styles={styles}
      classNamePrefix='select-box-v2'
      className={`h-50 field m-0 p-0`}
      noOptionsMessage={(obj) => {
        if (!obj.inputValue) return 'Start typing to search';
        return 'No users or channels matched';
      }}
      formatGroupLabel={formatGroupLabel}
      formatOptionLabel={formatOptionLabel}
      getOptionValue={(option) =>
        option.kind === 'user' ? option.user.id : option.channel.id
      }
      isClearable
      autoFocus={autofocus}
      components={{
        DropdownIndicator: null,
        ClearIndicator: (props) => (
          <components.ClearIndicator {...props} children={'Clear all'} />
        ),
      }}
    />
  );
}

function ImportSlackMembersModal(props: {
  organization: Organization;
  connection: OrgConnection;
  onCancel: () => void;
  onImport: (organizers: Organizer[]) => void;
  onAddByEmail: () => void;
}) {
  const { organization } = props;

  const { data: newUsers, isLoading } = useSWRImmutable(
    `/organizations/${organization.id}/organizers/import-slack-members`,
    () => loadNewSlackUsers(organization.id)
  );

  const { trigger, isMutating } = useSWRMutation(
    `/organizations/${organization.id}/organizers/import-slack-members`,
    async (_, { arg }: { arg: InviteOrganizersRequest }) =>
      await apiService.organization.inviteOrganizers(organization.id, arg)
  );

  const [users, setUsers] = useState<DtoSlackUser[]>([]);
  const [sendEmail, setSendEmail] = useState(true);

  const handleAddAllNewUsers = () => {
    if (!newUsers) return;

    setUsers((prev) => {
      const updated = [...prev];
      for (const u of newUsers) {
        if (updated.find((p) => p.id === u.id)) continue;
        updated.push(u);
      }
      return updated;
    });
  };

  const handleSave = async () => {
    const resp = await trigger({
      invitedUsers: users.map((u) => ({
        email: u.email,
        fullName: u.fullName,
        exUserId: u.id,
      })),
      webEndpoint: window.location.origin,
      sendEmail: sendEmail,
    });
    if (resp) {
      const newOrganizers = resp.data.organizers.filter((o) =>
        resp.data.newInviteeUids.includes(o.uid)
      );
      props.onImport(newOrganizers);
    }
  };

  const saveDisabled = users.length === 0 || isMutating;

  if (isLoading) return <Loading />;
  return (
    <ModalWrapper containerClassName='w-160' borderStyle='white'>
      <div className='w-full p-8'>
        <header className='w-full text-center'>
          <h1 className='text-2xl font-medium'>Import Team</h1>
          <p className='text-sms font-normal text-icon-gray'>
            Share access to Luna Park with your teammates. <br />
            The more you invite, the more fun Luna Park becomes!
          </p>
        </header>

        <main className='mt-5 w-full'>
          {newUsers && newUsers.length > 0 && (
            <div className='w-full mb-2.5'>
              <NewSlackUsersBanner
                connection={props.connection}
                newUsersCount={newUsers?.length ?? 0}
                onAddAll={handleAddAllNewUsers}
              />
            </div>
          )}

          <ImportSlackUsersInput
            organization={props.organization}
            users={users}
            onChange={setUsers}
            autofocus
          />

          <label
            htmlFor='sendEmail'
            className='mt-7.5 w-full flex justify-center items-center gap-2.5 hover:cursor-pointer'
          >
            <input
              id='sendEmail'
              name='sendEmail'
              type='checkbox'
              className='w-4 h-4 checkbox-dark'
              checked={sendEmail}
              onChange={(event) => {
                setSendEmail(event.target.checked);
              }}
            ></input>
            <p className='text-secondary text-sms'>
              Send invite notification via email
            </p>
          </label>
        </main>

        <footer className='mt-7.5 w-full flex flex-col items-center gap-5'>
          <div className='flex justify-center items-center gap-4'>
            <button
              type='button'
              onClick={props.onCancel}
              className='btn-secondary w-40 h-10'
            >
              Cancel
            </button>
            <button
              type='button'
              onClick={handleSave}
              disabled={saveDisabled}
              className='btn-primary w-40 h-10'
            >
              Save
            </button>
          </div>
          <button
            type='button'
            onClick={props.onAddByEmail}
            className='btn text-2xs font-medium underline text-primary'
          >
            Add Team Members by Email
          </button>
        </footer>
      </div>
    </ModalWrapper>
  );
}

export interface TriggerImportSlackMembersModal {
  organization: Organization;
  connection: OrgConnection;
  onImport: (organizers: Organizer[]) => void;
  onAddByEmail: () => void;
}

export function useTriggerImportSlackMembersModal(): (
  props: TriggerImportSlackMembersModal
) => void {
  const triggerFullScreenModal = useAwaitFullScreenConfirmCancelModal();

  return useCallback(
    (props: TriggerImportSlackMembersModal) => {
      triggerFullScreenModal({
        kind: 'custom',
        element: (p) => (
          <ImportSlackMembersModal
            organization={props.organization}
            connection={props.connection}
            onCancel={p.internalOnCancel}
            onImport={(organizers) => {
              p.internalOnConfirm();
              props.onImport(organizers);
            }}
            onAddByEmail={() => {
              p.internalOnCancel();
              props.onAddByEmail();
            }}
          />
        ),
      });
    },
    [triggerFullScreenModal]
  );
}
