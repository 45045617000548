import {
  type CreativePromptBlock,
  CreativePromptBlockGameSessionStatus,
} from '@lp-lib/game';

import { nullOrUndefined } from '../../../../utils/common';
import { useGameSessionStatus } from '../../hooks';
import { type EditPointData, type SetEditPointsData } from '../../store';
import {
  ResultView,
  SubmissionsActionBox,
} from '../Common/Controller/Internal';
import { useCreativePromptTeamData } from './hooks';

interface CPBSubmissionsViewProps {
  block: CreativePromptBlock;
  showVotes?: boolean;
  view?: 'vote' | 'result';
  setEditPointsData?: SetEditPointsData;
  selectedBlockId?: string | null;
}

export const CPBSubmissionsView = (
  props: CPBSubmissionsViewProps
): JSX.Element => {
  const {
    block,
    showVotes = false,
    view = 'vote',
    setEditPointsData,
    selectedBlockId,
  } = props;
  const creativePromptTeamData = useCreativePromptTeamData(selectedBlockId);
  const gameSessionStatus =
    useGameSessionStatus<CreativePromptBlockGameSessionStatus>();

  const { teamDataList, totalTeamCount, submittedTeamCount } =
    creativePromptTeamData;

  const title = `Prompt: ${block.fields.prompt}`;
  const isAnswerSubmitted =
    !nullOrUndefined(gameSessionStatus) &&
    gameSessionStatus >= CreativePromptBlockGameSessionStatus.SUBMISSION_END;

  return (
    <div className='relative w-full h-full flex flex-col justify-between items-center'>
      <div className='w-full'>
        <div className='w-full h-10 px-2.5 py-1 leading-4 bg-black text-white text-2xs font-medium line-clamp-2'>
          {title}
        </div>
        <div className='w-full h-8 px-2.5 text-white text-2xs font-bold truncate flex flex-col justify-center'>
          Submissions {`(${submittedTeamCount}/${totalTeamCount} Teams)`}
        </div>
        <div className='w-full mb-2.5 pt-1 px-2.5 max-h-64 scrollbar-hide overflow-y-scroll'>
          {teamDataList?.map((data, i) => {
            const editPointData: EditPointData = {
              teamName: data.teamName || '',
              teamId: data.teamId,
              submitterUid: data.userId || '',
              defaultPoints: data.previewPoints || 0,
              answer: data.answer || undefined,
            };
            return (
              <SubmissionsActionBox
                key={`sub-${i}`}
                teamId={data.teamId}
                view={view}
                editPointData={editPointData}
                setEditPointsData={setEditPointsData}
              >
                <div className='w-4/5 h-full pl-2'>
                  <p className='h-1/3 pt-1 font-bold'>{data.teamName}</p>
                  <div
                    className={`w-full h-2/3 text-xs font-medium text-icon-gray flex flex-col justify-center ${
                      !data.answer && 'opacity-20'
                    }`}
                  >
                    <p className='w-full line-clamp-2 hyphens-auto'>
                      {data.answer
                        ? data.answer
                        : isAnswerSubmitted
                        ? '[No Answer Submitted]'
                        : '[Waiting for Answer]'}
                    </p>
                  </div>
                </div>
                <div className='w-1/5 h-full bg-secondary border-l border-secondary flex flex-col justify-center items-center'>
                  {view === 'result' ? (
                    <ResultView
                      totalScore={data.totalScore}
                      previewPoints={data.previewPoints}
                    />
                  ) : (
                    showVotes && (
                      <div className='w-8 h-7 text-primary font-bold text-2xs text-center'>
                        {data.votes || 0} Votes
                      </div>
                    )
                  )}
                </div>
              </SubmissionsActionBox>
            );
          })}
        </div>
      </div>
    </div>
  );
};
