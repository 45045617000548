import { useMemo, useState } from 'react';

import {
  type QuestionBlock,
  QuestionBlockGameSessionStatus,
} from '@lp-lib/game';

import { nullOrUndefined } from '../../../../utils/common';
import { useGameSessionStatus, useIsEndedBlock } from '../../hooks';
import { type EditPointData, type SetEditPointsData } from '../../store';
import {
  type ControllerSubmissionActionItem,
  ResultView,
  SubmissionsActionBox,
} from '../Common/Controller/Internal';
import { type QuestionTeamData, useQuestionTeamData } from './hooks';
import { QuestionBlockGradingCard } from './QuestionBlockGradingCard';

interface QBSubmissionsViewProps {
  block: QuestionBlock;
  view?: 'default' | 'result';
  setEditPointsData?: SetEditPointsData;
  selectedBlockId?: string | null;
}

function QBSubmissionsActionBox(
  props: QBSubmissionsViewProps & {
    data: QuestionTeamData;
    setGradingCardTeamId: (teamId: string | null) => void;
  }
): JSX.Element {
  const {
    block,
    view = 'default',
    setEditPointsData,
    selectedBlockId,
    data,
    setGradingCardTeamId,
  } = props;

  const questionTeamData = useQuestionTeamData(selectedBlockId);
  const gameSessionStatus =
    useGameSessionStatus<QuestionBlockGameSessionStatus>();
  const isEndedBlock = useIsEndedBlock(block.id);

  const canRegradeAnswer =
    !isEndedBlock &&
    !nullOrUndefined(gameSessionStatus) &&
    gameSessionStatus < QuestionBlockGameSessionStatus.ANSWER &&
    data.answer;

  const actions = useMemo<ControllerSubmissionActionItem[]>(
    () => [
      {
        key: 'action-regrade-answer',
        text: 'Regrade Answer',
        disabled: !canRegradeAnswer,
        handleClick: () => {
          if (!setGradingCardTeamId || !data?.teamId || !canRegradeAnswer) {
            return;
          }

          setGradingCardTeamId(data.teamId);
        },
      },
    ],
    [canRegradeAnswer, data.teamId, setGradingCardTeamId]
  );

  if (!questionTeamData || nullOrUndefined(gameSessionStatus)) return <></>;

  const isAfterTimesUp =
    gameSessionStatus >= QuestionBlockGameSessionStatus.TIMESUP;

  const editPointData: EditPointData = {
    teamName: data.teamName || '',
    teamId: data.teamId,
    submitterUid: data.userId || '',
    defaultPoints: data.score || 0,
    answer: data.answer || undefined,
  };

  return (
    <SubmissionsActionBox
      teamId={data.teamId}
      view={view}
      editPointData={editPointData}
      setEditPointsData={setEditPointsData}
      customActionItems={actions}
    >
      <div className='w-4/5 h-full pl-2'>
        <p className='h-1/3 pt-1 font-bold'>{data.teamName}</p>
        <div
          className={`w-full h-2/3 text-xs font-medium text-icon-gray flex flex-col justify-center ${
            !data.answer && 'opacity-20'
          }`}
        >
          <p className='w-full line-clamp-2 hyphens-auto'>
            {data.answer
              ? data.answer
              : isAfterTimesUp
              ? '[No Answer Submitted]'
              : '[Waiting for Answer]'}
          </p>
        </div>
      </div>
      <div className='w-1/5 h-full bg-secondary border-l border-secondary flex flex-col justify-center items-center'>
        {view === 'result' ? (
          <ResultView
            totalScore={data.totalScore}
            previewPoints={data.score || 0}
          />
        ) : (
          <>
            {data.score !== null ? (
              <div className='w-8 h-7 text-white font-bold text-2xs text-center flex flex-col justify-center'>
                <span>+{data.score}</span>
                <span className='text-3xs'>Points</span>
              </div>
            ) : null}
          </>
        )}
      </div>
    </SubmissionsActionBox>
  );
}
export const QBSubmissionsView = (
  props: QBSubmissionsViewProps
): JSX.Element => {
  const { block, selectedBlockId } = props;

  const questionTeamData = useQuestionTeamData(selectedBlockId);
  const gameSessionStatus =
    useGameSessionStatus<QuestionBlockGameSessionStatus>();
  const [gradingCardTeamId, setGradingCardTeamId] = useState<string | null>(
    null
  );

  if (!questionTeamData || nullOrUndefined(gameSessionStatus)) return <></>;

  const { questionData, totalTeamCount, answeredTeamCount } = questionTeamData;

  const title = `Correct Answer: ${block.fields.answer}`;

  return (
    <>
      <div className='relative w-full h-full flex flex-col justify-between items-center'>
        <div className='w-full'>
          <div className='w-full h-10 px-2.5 py-1 leading-4 bg-black text-white text-2xs font-medium line-clamp-2'>
            {title}
          </div>

          <div className='w-full h-8 px-2.5 text-white text-2xs font-bold truncate flex flex-col justify-center'>
            Submissions {`(${answeredTeamCount}/${totalTeamCount} Teams)`}
          </div>
          <div className='w-full mb-2.5 pt-1 px-2.5 max-h-64 scrollbar-hide overflow-y-scroll'>
            {questionData?.map((data, i) => (
              <QBSubmissionsActionBox
                key={`sub-${i}`}
                {...props}
                data={data}
                setGradingCardTeamId={setGradingCardTeamId}
              />
            ))}
          </div>
        </div>
      </div>
      <QuestionBlockGradingCard
        block={block}
        gradingCardTeamId={gradingCardTeamId ? gradingCardTeamId : undefined}
        setGradingCardTeamId={setGradingCardTeamId}
      />
    </>
  );
};
