const PoorConnectionIcon = ({
  className,
}: {
  className?: string;
}): JSX.Element => {
  return (
    <svg
      className={className || 'w-5 h-5 fill-current'}
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 16 14'
    >
      <g fill='#EE3529' filter='url(#filter0_d)'>
        <path
          fillRule='evenodd'
          d='M6.387 2.89a1.997 1.997 0 00-.226 1.375l.03.284c-1.079.336-2.07.97-2.914 1.836a.73.73 0 01-1.06-.006.8.8 0 01.006-1.102c1.158-1.191 2.584-2.04 4.164-2.388zM8 2.713c-.621 0-1.125.523-1.125 1.169 0 .089.01.175.028.259l.024.226a6.037 6.037 0 012.146 0l.024-.226c.018-.084.028-.17.028-.26 0-.645-.504-1.168-1.125-1.168zm1.808 1.835l.03-.284a2.025 2.025 0 00-.225-1.376c1.58.348 3.005 1.197 4.165 2.388a.8.8 0 01.005 1.102.73.73 0 01-1.06.006c-.844-.867-1.835-1.5-2.915-1.836zm-.902 1.383a5.08 5.08 0 00-1.816-.038l.16 1.495c0 .02 0 .038.002.057a3.641 3.641 0 011.492.043.817.817 0 00.006-.1l.156-1.457zm.555 1.812c.022-.098.035-.2.038-.304l.14-1.3a5.2 5.2 0 011.632.969.8.8 0 01.091 1.097.73.73 0 01-1.056.095 3.736 3.736 0 00-.845-.557zm-2.939-.086a1.624 1.624 0 01-.021-.218l-.147-1.37a5.186 5.186 0 00-1.837 1.039.8.8 0 00-.091 1.097.73.73 0 001.057.095 3.72 3.72 0 011.04-.643z'
          clipRule='evenodd'
        ></path>
        <path d='M8 11.285c.621 0 1.125-.523 1.125-1.169 0-.645-.504-1.169-1.125-1.169s-1.125.524-1.125 1.17c0 .645.504 1.168 1.125 1.168z'></path>
        <path
          fillRule='evenodd'
          d='M6.875 3.883c0-.646.504-1.17 1.125-1.17s1.125.524 1.125 1.17c0 .089-.01.175-.028.259L8.75 7.389c0 .43-.336.78-.75.78s-.75-.35-.75-.78l-.347-3.247a1.217 1.217 0 01-.028-.26z'
          clipRule='evenodd'
        ></path>
      </g>
      <defs>
        <filter
          id='filter0_d'
          width='16'
          height='12.571'
          x='0'
          y='0.714'
          colorInterpolationFilters='sRGB'
          filterUnits='userSpaceOnUse'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix'></feFlood>
          <feColorMatrix
            in='SourceAlpha'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
          ></feColorMatrix>
          <feOffset></feOffset>
          <feGaussianBlur stdDeviation='1'></feGaussianBlur>
          <feColorMatrix values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0'></feColorMatrix>
          <feBlend
            in2='BackgroundImageFix'
            result='effect1_dropShadow'
          ></feBlend>
          <feBlend
            in='SourceGraphic'
            in2='effect1_dropShadow'
            result='shape'
          ></feBlend>
        </filter>
      </defs>
    </svg>
  );
};

export { PoorConnectionIcon };
