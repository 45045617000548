import {
  type AIChatBlock,
  AIChatBlockGameSessionStatus,
  assertExhaustive,
  type Block,
  type BlockActionWaitModeConfig,
  BlockType,
  type CreativePromptBlock,
  CreativePromptBlockGameSessionStatus,
  type DrawingPromptBlock,
  DrawingPromptBlockGameSessionStatus,
  type GuessWhoBlock,
  GuessWhoBlockGameSessionStatus,
  type HeadToHeadBlock,
  HeadToHeadBlockGameSessionStatus,
  type HiddenPictureBlock,
  HiddenPictureBlockGameSessionStatus,
  type IcebreakerBlock,
  IcebreakerBlockGameSessionStatus,
  INSTRUCTION_MAX_DISPLAY_SECONDS,
  type InstructionBlock,
  InstructionBlockGameSessionStatus,
  type JeopardyBlock,
  JeopardyBlockGameSessionStatus,
  type MarketingBlock,
  MarketingBlockGameSessionStatus,
  type MemoryMatchBlock,
  MemoryMatchBlockGameSessionStatus,
  type MultipleChoiceBlock,
  MultipleChoiceGameSessionStatus,
  type OverRoastedBlock,
  OverRoastedBlockGameSessionStatus,
  type PuzzleBlock,
  PuzzleBlockGameSessionStatus,
  type QuestionBlock,
  QuestionBlockGameSessionStatus,
  type RandomizerBlock,
  type RapidBlock,
  RapidBlockGameSessionStatus,
  type RoundRobinQuestionBlock,
  RoundRobinQuestionBlockGameSessionStatus,
  type ScoreboardBlock,
  ScoreboardBlockGameSessionStatus,
  type SpotlightBlock,
  SpotlightBlockGameSessionStatus,
  type SpotlightBlockV2,
  SpotlightBlockV2GameSessionStatus,
  type TeamRelayBlock,
  TeamRelayBlockGameSessionStatus,
  type TitleBlockV2,
  TitleBlockV2GameSessionStatus,
} from '@lp-lib/game';

import {
  getFeatureQueryParam,
  getFeatureQueryParamNumber,
} from '../../../hooks/useFeatureQueryParam';
import { fromMediaDTO } from '../../../utils/api-dto';
import { MediaUtils } from '../../../utils/media';
import { TagQuery } from '../../../utils/TagQuery';
import { lvoCacheWarm } from '../../VoiceOver/LocalizedVoiceOvers';
import { lvoTTSRequestFromPlan } from '../../VoiceOver/LocalLocalizedVoiceOvers';
import { DrawingPromptUtils } from '../Blocks/DrawingPrompt';
import {
  GuessWhoUtils,
  TIME_TO_GUESS_WHO_DURATION_SEC,
} from '../Blocks/GuessWho/utils';
import { ScoreboardUtils } from '../Blocks/Scoreboard/utils';
import { BlockKnifeUtils } from '../Blocks/Shared';
import { getTeamRelayGameTime } from '../Blocks/TeamRelay';
import {
  expandCardIntoNormal,
  expandCardIntoTeamIntro,
  isEphemeralTitleCard,
} from '../Blocks/TitleV2/createVoiceOverCard';
import { BlockActionBuilder } from './BlockActionBuilder';
import { blockTitleAnimationHalfDuration } from './blockTitleAnimationHalfDuration';
import {
  type BlockRecordingExtra,
  type GameInfoSnapshot,
  type GeneratedBlockRecording,
  type GenerateV3RecordingOpts,
  makeBlockRecordingExtra,
} from './types';

export function recordingNeedsPreparation(block: Block): boolean {
  switch (block.type) {
    case BlockType.ICEBREAKER:
    case BlockType.GUESS_WHO:
    case BlockType.AI_CHAT:
    case BlockType.HIDDEN_PICTURE:
    case BlockType.DRAWING_PROMPT:
    case BlockType.OVERROASTED:
    case BlockType.INSTRUCTION:
    case BlockType.ROUND_ROBIN_QUESTION:
    case BlockType.PUZZLE:
    case BlockType.MEMORY_MATCH:
    case BlockType.MARKETING:
    case BlockType.QUESTION:
    case BlockType.MULTIPLE_CHOICE:
    case BlockType.TEAM_RELAY:
    case BlockType.CREATIVE_PROMPT:
    case BlockType.RAPID:
    case BlockType.JEOPARDY:
    case BlockType.RANDOMIZER:
    case BlockType.SLIDE:
      return false;

    case BlockType.TITLE_V2:
    case BlockType.SCOREBOARD:
    case BlockType.SPOTLIGHT:
    case BlockType.SPOTLIGHT_V2:
    case BlockType.HEAD_TO_HEAD:
      return true;

    default:
      assertExhaustive(block);
      return false;
  }
}

function defaultTimesUpDurationMs(additionMs = 0, baseMs = 1000) {
  return baseMs + additionMs;
}

/**
 * This is a utility function that helps you to build the WaitMode. `exitable`
 * and `exitableAfterSec` are newly introduced. They offer a way that we can
 * exit the wait mode from the outside of the runner.
 *
 * Notes: Those two new paramaters are used to exit the wait mode before the
 * end of a block. The timing of presenting the skip button needs to be aligned
 * with "End Block Sequence" button in the host controller. Each block type can
 * have an optional "outro" media, and the block itself defines when to present
 * the media at which session status. Some blocks make it as as implicit step
 * such as Question Block. The "outro" media is played right after _ANSWER_
 * status, and then revealing the results. But some of the blocks attach the
 * media to an extra session status before ending like Rapid
 * Submission Block. In RSB, the "outro" media is played in _RESULT_ session
 * status, and followed by _POINTS_DISTRIBUTED_ session status. So the
 * `maxWaitDurationSec` would either include or exclude the outro media duration
 * based on how the session statuses are defined on the block level. In order to
 * solve this problem, we introduced `exitableAfterSec` to control the offset.
 * Which tells the skip button when it should show up.
 */
function makeWaitMode(
  maxWaitDurationSec: number,
  exitable = false,
  exitableAfterSec = 0,
  lastWaitBeforeEnd = false
): BlockActionWaitModeConfig {
  return {
    wait: true,
    maxWaitDurationSec: Math.ceil(maxWaitDurationSec + exitableAfterSec),
    exitable,
    exitableAfterSec,
    lastWaitBeforeEnd,
  };
}

export async function generateV3Recording(
  block: Block,
  extra: GameInfoSnapshot | null,
  opts: GenerateV3RecordingOpts
): Promise<GeneratedBlockRecording | null> {
  if (!extra) return Promise.resolve(null);

  switch (block.type) {
    case BlockType.RAPID:
      return generateV3RapidBlockRecording(block, extra, opts);
    case BlockType.CREATIVE_PROMPT:
      return generateV3CreativePromptBlockRecording(block, extra, opts);
    case BlockType.QUESTION:
      return generateV3QuestionBlockRecording(block, extra, opts);
    case BlockType.SCOREBOARD:
      return generateV3ScoreboardBlockRecording(block, extra, opts);
    case BlockType.SPOTLIGHT:
      return generateV3SpotlightBlockRecording(block, extra, opts);
    case BlockType.SPOTLIGHT_V2:
      return generateV3SpotlightV2BlockRecording(block, extra, opts);
    case BlockType.RANDOMIZER:
      return generateV3RandomizerBlockRecording(block, extra, opts);
    case BlockType.TEAM_RELAY:
      return generateV3TeamRelayBlockRecording(block, extra, opts);
    case BlockType.MULTIPLE_CHOICE:
      return generateV3MultipleChoiceBlockRecording(block, extra, opts);
    case BlockType.MEMORY_MATCH:
      return generateV3MemoryMatchBlockRecording(block, extra, opts);
    case BlockType.PUZZLE:
      return generateV3PuzzleBlockRecording(block, extra, opts);
    case BlockType.ROUND_ROBIN_QUESTION:
      return generateV3RoundRobinQuestionBlockRecording(block, extra, opts);
    case BlockType.TITLE_V2:
      return generateV3TitleV2BlockRecording(block, extra, opts);
    case BlockType.INSTRUCTION:
      return generateV3InstructionBlockRecording(block, extra, opts);
    case BlockType.OVERROASTED:
      return generateV3OverRoastedBlockRecording(block, extra, opts);
    case BlockType.DRAWING_PROMPT:
      return generateV3DrawingPromptBlockRecording(block, extra, opts);
    case BlockType.HIDDEN_PICTURE:
      return generateV3HiddenPictureBlockRecording(block, extra, opts);
    case BlockType.AI_CHAT:
      return generateV3AIChatBlockRecording(block, extra, opts);
    case BlockType.GUESS_WHO:
      return generateV3GuessWhoBlockRecording(block, extra, opts);
    case BlockType.ICEBREAKER:
      return generateV3IcebreakerBlockRecording(block, extra, opts);
    case BlockType.MARKETING:
      return generateV3MarketingBlockRecording(block, extra, opts);
    case BlockType.JEOPARDY:
      return generateV3JeopardyBlockRecording(block, extra, opts);
    case BlockType.HEAD_TO_HEAD:
      return generateV3H2HBlockRecording(block, extra, opts);
    case BlockType.SLIDE:
      return null;
    default:
      assertExhaustive(block);
      return null;
  }
}

async function generateV3RapidBlockRecording(
  block: RapidBlock,
  extra: GameInfoSnapshot,
  _opts: GenerateV3RecordingOpts
) {
  const b = new BlockActionBuilder<RapidBlockGameSessionStatus>();
  b.push(RapidBlockGameSessionStatus.LOADED, 0);
  b.push(
    RapidBlockGameSessionStatus.PRESENTING,
    blockTitleAnimationHalfDuration(block)
  );
  b.push(
    RapidBlockGameSessionStatus.QUESTION_COUNTING,
    block.fields.startVideoWithTimer
      ? 2000
      : MediaUtils.GetMediaDurationMs(block.fields.questionMedia) + 2000,
    null,
    makeWaitMode(block.fields.questionTime)
  );
  b.push(
    RapidBlockGameSessionStatus.QUESTION_END,
    block.fields.questionTime * 1000
  );
  b.push(
    RapidBlockGameSessionStatus.POINTS_DISTRIBUTED,
    Math.max(
      MediaUtils.GetMediaDurationMs(block.fields.answerMedia),
      defaultTimesUpDurationMs()
    )
  );
  b.push(
    RapidBlockGameSessionStatus.RESULTS,
    3500,
    null,
    makeWaitMode(extra.pauseOnEndBlockMaxWaitSec, true, 0, true)
  );
  b.push(
    RapidBlockGameSessionStatus.END,
    extra.pauseOnEndBlockMaxWaitSec * 1000 + 3500
  );
  return b.intoV3Recording();
}

async function generateV3CreativePromptBlockRecording(
  block: CreativePromptBlock,
  extra: GameInfoSnapshot,
  _opts: GenerateV3RecordingOpts
) {
  const b = new BlockActionBuilder<CreativePromptBlockGameSessionStatus>();
  b.push(CreativePromptBlockGameSessionStatus.LOADED, 0);
  b.push(
    CreativePromptBlockGameSessionStatus.PRESENTING,
    blockTitleAnimationHalfDuration(block)
  );

  b.push(
    CreativePromptBlockGameSessionStatus.SUBMISSION_COUNTING,
    block.fields.startVideoWithTimer
      ? 2000
      : MediaUtils.GetMediaDurationMs(block.fields.submissionMedia) + 2000,
    null,
    makeWaitMode(block.fields.submissionTime)
  );

  b.push(
    CreativePromptBlockGameSessionStatus.SUBMISSION_END,
    block.fields.submissionTime * 1000
  );
  b.push(CreativePromptBlockGameSessionStatus.SHOW_SUBMISSIONS, 2000);

  // note: there's a timer but we do not entire wait mode.
  b.push(CreativePromptBlockGameSessionStatus.VOTE_COUNTING, 4000);

  // note(falcon): currently, for OnD, the creative prompt uses a 10 second voting time.
  // in live, it bases the voting time based on _submission_ count. when we generate this
  // recording, we only have access to the team count. to avoid any inconsistencies
  // we'll match the behaviour of the block.
  const votingTimeMs = 10 * 1000;
  b.push(CreativePromptBlockGameSessionStatus.VOTE_END, votingTimeMs);
  b.push(
    CreativePromptBlockGameSessionStatus.RESULTS,
    defaultTimesUpDurationMs()
  );

  b.push(
    CreativePromptBlockGameSessionStatus.POINTS_DISTRIBUTED,
    1000,
    null,
    makeWaitMode(extra.pauseOnEndBlockMaxWaitSec, true, 0, true)
  );

  b.push(
    CreativePromptBlockGameSessionStatus.END,
    extra.pauseOnEndBlockMaxWaitSec * 1000 + 3500
  );

  return b.intoV3Recording();
}

async function generateV3QuestionBlockRecording(
  block: QuestionBlock,
  extra: GameInfoSnapshot,
  _opts: GenerateV3RecordingOpts
) {
  const b = new BlockActionBuilder<QuestionBlockGameSessionStatus>();
  b.push(QuestionBlockGameSessionStatus.LOADED, 0);
  b.push(
    QuestionBlockGameSessionStatus.PRESENTING,
    blockTitleAnimationHalfDuration(block)
  );

  const questionMediaDurationMs = MediaUtils.GetMediaDurationMs(
    block.fields.questionMedia
  );

  // if the startVideoWithTimer is on, we ensure the full video can be played
  // through.
  const wait1 = makeWaitMode(
    block.fields.startVideoWithTimer
      ? Math.max(questionMediaDurationMs / 1000, block.fields.time)
      : block.fields.time
  );

  b.push(
    QuestionBlockGameSessionStatus.COUNTING,
    // wait for video to play
    block.fields.startVideoWithTimer
      ? 2000
      : MediaUtils.GetMediaDurationMs(block.fields.questionMedia) + 2000,
    null,
    wait1
  );
  b.push(
    QuestionBlockGameSessionStatus.TIMESUP,
    wait1.maxWaitDurationSec * 1000
  );

  const wait2 = makeWaitMode(
    extra.pauseOnEndBlockMaxWaitSec,
    true,
    MediaUtils.GetMediaDurationMs(block.fields.answerMedia) / 1000,
    true
  );

  b.push(
    QuestionBlockGameSessionStatus.ANSWER,
    defaultTimesUpDurationMs(),
    null,
    wait2
  );

  b.push(
    QuestionBlockGameSessionStatus.END,
    wait2.maxWaitDurationSec * 1000 + 3500
  );

  return b.intoV3Recording();
}

async function generateV3ScoreboardBlockRecording(
  block: ScoreboardBlock,
  extra: GameInfoSnapshot,
  opts: GenerateV3RecordingOpts
) {
  if (block.recording?.version === 3)
    return { recording: block.recording, extra: undefined };

  const voiceOverPlan = opts.voiceOverPlans?.[0]?.plan;
  if (voiceOverPlan && opts.waitForPreparedBlock) {
    // kick off loading...
    const templateRenderer = extra.variableRegistry;
    const req = await lvoTTSRequestFromPlan(voiceOverPlan, templateRenderer);
    lvoCacheWarm(req);
  }

  const b = new BlockActionBuilder<ScoreboardBlockGameSessionStatus>();
  b.push(ScoreboardBlockGameSessionStatus.LOADED, 0);
  b.push(
    ScoreboardBlockGameSessionStatus.PRESENTING,
    blockTitleAnimationHalfDuration(block)
  );

  let wait;
  if (voiceOverPlan) {
    wait = makeWaitMode(extra.pauseOnEndBlockMaxWaitSec, false, 0, true);
  } else {
    wait = makeWaitMode(
      extra.pauseOnEndBlockMaxWaitSec,
      true,
      ScoreboardUtils.GetWaitModeExitableAfterSec(
        block.fields.mode,
        extra.scoreboard.length,
        null,
        getFeatureQueryParam('game-play-scoreboard-animation')
      ),
      true
    );
  }

  b.push(ScoreboardBlockGameSessionStatus.SCOREBOARD, 2000, null, wait);

  b.push(
    ScoreboardBlockGameSessionStatus.END,
    wait.maxWaitDurationSec * 1000 + 3500
  );

  return b.intoV3Recording();
}

async function generateV3SpotlightBlockRecording(
  block: SpotlightBlock,
  extra: GameInfoSnapshot,
  opts: GenerateV3RecordingOpts
) {
  if (block.recording?.version === 3)
    return { recording: block.recording, extra: undefined };

  const voiceOverPlan = opts.voiceOverPlans?.[0]?.plan;
  if (voiceOverPlan && opts.waitForPreparedBlock) {
    // kick off loading...
    const templateRenderer = extra.variableRegistry;
    const req = await lvoTTSRequestFromPlan(voiceOverPlan, templateRenderer);
    lvoCacheWarm(req);
  }

  const b = new BlockActionBuilder<SpotlightBlockGameSessionStatus>();
  b.push(SpotlightBlockGameSessionStatus.LOADED, 0);
  b.push(
    SpotlightBlockGameSessionStatus.PRESENTING,
    blockTitleAnimationHalfDuration(block)
  );

  const maxWaitSec = 180;
  let wait;
  if (voiceOverPlan) {
    // the block will exit wait mode for us.
    wait = makeWaitMode(maxWaitSec, false, 0, true);
  } else {
    const durationMs = MediaUtils.GetMediaDurationMs(
      block.fields.backgroundMedia
    );
    wait = makeWaitMode(maxWaitSec, true, durationMs / 1000, true);
  }

  b.push(SpotlightBlockGameSessionStatus.CELEBRATING, 2000, null, wait);

  b.push(
    SpotlightBlockGameSessionStatus.END,
    wait.maxWaitDurationSec * 1000 + 3500
  );

  return b.intoV3Recording();
}

async function generateV3SpotlightV2BlockRecording(
  block: SpotlightBlockV2,
  extra: GameInfoSnapshot,
  opts: GenerateV3RecordingOpts
) {
  if (block.recording?.version === 3)
    return { recording: block.recording, extra: undefined };

  const voiceOverPlan = opts.voiceOverPlans?.[0]?.plan;
  if (voiceOverPlan && opts.waitForPreparedBlock) {
    // kick off loading...
    const templateRenderer = extra.variableRegistry;
    const req = await lvoTTSRequestFromPlan(voiceOverPlan, templateRenderer);
    lvoCacheWarm(req);
  }

  const b = new BlockActionBuilder<SpotlightBlockV2GameSessionStatus>();
  b.push(SpotlightBlockV2GameSessionStatus.LOADED, 0);
  b.push(
    SpotlightBlockV2GameSessionStatus.PRESENTING,
    blockTitleAnimationHalfDuration(block)
  );

  // note: this matches the game play logic.
  const shouldShowResults = block.fields.votingPoints > 0;
  let celebrationWait;
  if (voiceOverPlan) {
    // the block will exit wait mode for us.
    celebrationWait = makeWaitMode(
      extra.pauseOnEndBlockMaxWaitSec,
      false,
      0,
      !shouldShowResults
    );
  } else {
    const durationMs = MediaUtils.GetMediaDurationMs(
      block.fields.backgroundMedia
    );
    celebrationWait = makeWaitMode(
      extra.pauseOnEndBlockMaxWaitSec,
      true,
      durationMs / 1000,
      !shouldShowResults
    );
  }

  b.push(
    SpotlightBlockV2GameSessionStatus.CELEBRATING,
    2000,
    null,
    celebrationWait
  );

  if (shouldShowResults) {
    const wait = makeWaitMode(extra.pauseOnEndBlockMaxWaitSec, true, 0, true);
    b.push(
      SpotlightBlockV2GameSessionStatus.RESULTS,
      extra.pauseOnEndBlockMaxWaitSec * 1000,
      null,
      wait
    );
  }

  b.push(
    SpotlightBlockV2GameSessionStatus.END,
    extra.pauseOnEndBlockMaxWaitSec * 1000 + 3500
  );

  return b.intoV3Recording();
}

async function generateV3RandomizerBlockRecording(
  block: RandomizerBlock,
  _extra: GameInfoSnapshot,
  _opts: GenerateV3RecordingOpts
) {
  return block.recording?.version === 3
    ? { recording: block.recording, extra: undefined }
    : null;
}

async function generateV3TeamRelayBlockRecording(
  block: TeamRelayBlock,
  extra: GameInfoSnapshot,
  _opts: GenerateV3RecordingOpts
) {
  const b = new BlockActionBuilder<TeamRelayBlockGameSessionStatus>();
  b.push(TeamRelayBlockGameSessionStatus.LOADED, 0);
  b.push(
    TeamRelayBlockGameSessionStatus.INTRO,
    blockTitleAnimationHalfDuration(block)
  );
  b.push(
    TeamRelayBlockGameSessionStatus.GAME_INIT,
    MediaUtils.GetMediaDurationMs(block.fields.introMedia)
  );

  const totalGameTimeSeconds = await getTeamRelayGameTime(block, undefined);

  b.push(
    TeamRelayBlockGameSessionStatus.GAME_START,
    2000,
    null,
    makeWaitMode(totalGameTimeSeconds)
  );

  b.push(TeamRelayBlockGameSessionStatus.GAME_END, totalGameTimeSeconds * 1000);

  b.push(TeamRelayBlockGameSessionStatus.OUTRO, defaultTimesUpDurationMs());

  b.push(
    TeamRelayBlockGameSessionStatus.RESULTS,
    MediaUtils.GetMediaDurationMs(block.fields.outroMedia),
    null,
    makeWaitMode(extra.pauseOnEndBlockMaxWaitSec, true, 0, true)
  );

  // NOTE(drew): this might be made dynamic in the future based on the number
  // of teams.
  b.push(
    TeamRelayBlockGameSessionStatus.END,
    extra.pauseOnEndBlockMaxWaitSec * 1000 + 3500
  );

  return b.intoV3Recording();
}

async function generateV3MultipleChoiceBlockRecording(
  block: MultipleChoiceBlock,
  extra: GameInfoSnapshot,
  _opts: GenerateV3RecordingOpts
) {
  const b = new BlockActionBuilder<MultipleChoiceGameSessionStatus>();

  b.push(MultipleChoiceGameSessionStatus.LOADED, 0);
  // Note(falcon): MC block assumes it is always configured with question media. This may change in the future.
  b.push(
    MultipleChoiceGameSessionStatus.PRESENTING_QUESTION,
    blockTitleAnimationHalfDuration(block)
  );

  const questionMediaDurationMs = MediaUtils.GetMediaDurationMs(
    block.fields.questionMedia
  );
  if (block.fields.startVideoWithTimer) {
    b.push(MultipleChoiceGameSessionStatus.PRESENTED_QUESTION, 0);
    // ensure the full video can be played through.
    const countingDurationSec = Math.max(
      questionMediaDurationMs / 1000,
      block.fields.questionTimeSec
    );
    const wait1 = makeWaitMode(countingDurationSec);
    b.push(
      MultipleChoiceGameSessionStatus.SUBMISSION_TIMER_COUNTING,
      2000,
      null,
      wait1
    );
    b.push(
      MultipleChoiceGameSessionStatus.SUBMISSION_TIMER_DONE,
      wait1.maxWaitDurationSec * 1000
    );
  } else {
    b.push(
      MultipleChoiceGameSessionStatus.PRESENTED_QUESTION,
      questionMediaDurationMs
    );
    const wait2 = makeWaitMode(block.fields.questionTimeSec);
    b.push(
      MultipleChoiceGameSessionStatus.SUBMISSION_TIMER_COUNTING,
      2000,
      null,
      wait2
    );
    b.push(
      MultipleChoiceGameSessionStatus.SUBMISSION_TIMER_DONE,
      wait2.maxWaitDurationSec * 1000
    );
  }

  b.push(
    MultipleChoiceGameSessionStatus.PRESENTING_ANSWER,
    defaultTimesUpDurationMs()
  );
  b.push(
    MultipleChoiceGameSessionStatus.PRESENTED_ANSWER,
    MediaUtils.GetMediaDurationMs(block.fields.answerMedia)
  );
  b.push(
    MultipleChoiceGameSessionStatus.RESULTS,
    4000,
    null,
    makeWaitMode(extra.pauseOnEndBlockMaxWaitSec, true, 0, true)
  );

  b.push(
    MultipleChoiceGameSessionStatus.END,
    extra.pauseOnEndBlockMaxWaitSec * 1000 + 3500
  );

  return b.intoV3Recording();
}

async function generateV3MemoryMatchBlockRecording(
  block: MemoryMatchBlock,
  extra: GameInfoSnapshot,
  _opts: GenerateV3RecordingOpts
) {
  const b = new BlockActionBuilder<MemoryMatchBlockGameSessionStatus>();

  b.push(MemoryMatchBlockGameSessionStatus.LOADED, 0);
  b.push(
    MemoryMatchBlockGameSessionStatus.GAME_INIT,
    blockTitleAnimationHalfDuration(block)
  );
  b.push(
    MemoryMatchBlockGameSessionStatus.GAME_START,
    2000,
    null,
    makeWaitMode(block.fields.gameTimeSec)
  );
  b.push(
    MemoryMatchBlockGameSessionStatus.GAME_END,
    block.fields.gameTimeSec * 1000
  );
  b.push(
    MemoryMatchBlockGameSessionStatus.RESULTS,
    Math.trunc(
      defaultTimesUpDurationMs() * Math.sqrt(block.fields.cardPairs.length / 2)
    ),
    null,
    makeWaitMode(extra.pauseOnEndBlockMaxWaitSec, true, 0, true)
  );
  b.push(
    MemoryMatchBlockGameSessionStatus.END,
    extra.pauseOnEndBlockMaxWaitSec * 1000 + 3500
  );

  return b.intoV3Recording();
}

async function generateV3PuzzleBlockRecording(
  block: PuzzleBlock,
  extra: GameInfoSnapshot,
  _opts: GenerateV3RecordingOpts
) {
  const b = new BlockActionBuilder<PuzzleBlockGameSessionStatus>();

  b.push(PuzzleBlockGameSessionStatus.LOADED, 0);
  b.push(
    PuzzleBlockGameSessionStatus.INTRO,
    blockTitleAnimationHalfDuration(block)
  );
  b.push(
    PuzzleBlockGameSessionStatus.GAME_INIT,
    MediaUtils.GetMediaDurationMs(block.fields.introMedia) + 2000
  );
  b.push(
    PuzzleBlockGameSessionStatus.GAME_START,
    2000,
    null,
    makeWaitMode(block.fields.gameTimeSec)
  );
  b.push(
    PuzzleBlockGameSessionStatus.GAME_END,
    block.fields.gameTimeSec * 1000
  );
  b.push(
    PuzzleBlockGameSessionStatus.OUTRO,
    Math.trunc(
      defaultTimesUpDurationMs() * Math.sqrt(block.fields.dropSpots.length / 4)
    )
  );
  b.push(
    PuzzleBlockGameSessionStatus.RESULTS,
    MediaUtils.GetMediaDurationMs(block.fields.outroMedia),
    null,
    makeWaitMode(extra.pauseOnEndBlockMaxWaitSec, true, 0, true)
  );

  b.push(
    PuzzleBlockGameSessionStatus.END,
    extra.pauseOnEndBlockMaxWaitSec * 1000 + 3500
  );

  return b.intoV3Recording();
}

async function generateV3RoundRobinQuestionBlockRecording(
  block: RoundRobinQuestionBlock,
  extra: GameInfoSnapshot,
  _opts: GenerateV3RecordingOpts
) {
  const b = new BlockActionBuilder<RoundRobinQuestionBlockGameSessionStatus>();

  const gameTimeSec = block.fields.gameTimeSec || 2 * 60 * 60;

  b.push(RoundRobinQuestionBlockGameSessionStatus.LOADED, 0);
  b.push(
    RoundRobinQuestionBlockGameSessionStatus.GAME_INIT,
    blockTitleAnimationHalfDuration(block)
  );
  b.push(
    RoundRobinQuestionBlockGameSessionStatus.GAME_START,
    2000,
    null,
    makeWaitMode(gameTimeSec)
  );
  b.push(RoundRobinQuestionBlockGameSessionStatus.GAME_END, gameTimeSec * 1000);
  b.push(
    RoundRobinQuestionBlockGameSessionStatus.RESULTS,
    defaultTimesUpDurationMs(),
    null,
    makeWaitMode(extra.pauseOnEndBlockMaxWaitSec, true, 0, true)
  );

  b.push(
    RoundRobinQuestionBlockGameSessionStatus.END,
    extra.pauseOnEndBlockMaxWaitSec * 1000 + 3500
  );
  return b.intoV3Recording();
}

async function generateV3TitleV2BlockRecording(
  block: TitleBlockV2,
  extra: GameInfoSnapshot,
  opts: GenerateV3RecordingOpts
) {
  const cardDelayDurationMs = getFeatureQueryParamNumber(
    'game-on-demand-card-delay-duration'
  );

  if (block.recording?.version === 3)
    return { recording: block.recording, extra: undefined };

  const cards = block.fields.cards ?? [];

  const processed = new Set<string>();

  const titleCardsToTeams: BlockRecordingExtra['titleCardsToTeams'] = {};
  const titleCardsToVoiceOverPlans: BlockRecordingExtra['titleCardsToVoiceOverPlans'] =
    {};
  const titleCardsToVoiceOverDelayStartMs: BlockRecordingExtra['titleCardsToVoiceOverDelayStartMs'] =
    {};

  for (let i = 0; i < cards.length; i++) {
    const card = cards[i];

    // Already processed / created
    if (isEphemeralTitleCard(card)) continue;

    const generatedCards = card.teamIntroEnabled
      ? await expandCardIntoTeamIntro(
          card,
          block,
          titleCardsToTeams,
          titleCardsToVoiceOverPlans,
          titleCardsToVoiceOverDelayStartMs,
          extra,
          opts
        )
      : await expandCardIntoNormal(
          card,
          block,
          titleCardsToVoiceOverPlans,
          titleCardsToVoiceOverDelayStartMs,
          extra,
          opts
        );

    // Ensure we don't look at the generated cards.
    generatedCards.forEach((c) => processed.add(c.id));

    // Remove the current card, and insert the new cards
    cards.splice(i, 1, ...generatedCards);

    // cache warm
    if (opts.waitForPreparedBlock && !card.teamIntroEnabled) {
      const voiceOverPlan = titleCardsToVoiceOverPlans[card.id];
      if (voiceOverPlan) {
        const templateRenderer = extra.variableRegistry;
        const req = await lvoTTSRequestFromPlan(
          voiceOverPlan,
          templateRenderer
        );
        lvoCacheWarm(req);
      }
    }
  }

  // Finally, replace the cards on the incoming block!
  block.fields.cards = cards;

  const b = new BlockActionBuilder<TitleBlockV2GameSessionStatus>();
  b.push(TitleBlockV2GameSessionStatus.LOADED, 0);

  let mode: 'townhall' | 'teams' = 'townhall';

  const townhallIdealCountdownDurationMs = 3000;

  let deltaMs = blockTitleAnimationHalfDuration(block);
  for (let i = 0; i < cards.length; i++) {
    const card = cards[i];

    if (card.breakIntoTeams) {
      b.push(
        i,
        deltaMs,
        'townhall-to-teams',
        undefined,
        undefined,
        undefined,
        undefined,
        townhallIdealCountdownDurationMs
      );
      deltaMs = townhallIdealCountdownDurationMs;
      mode = 'teams';
    } else if (mode === 'teams') {
      b.push(
        i,
        deltaMs,
        'townhall-to-crowd',
        undefined,
        undefined,
        undefined,
        undefined,
        townhallIdealCountdownDurationMs
      );
      mode = 'townhall';
      deltaMs = townhallIdealCountdownDurationMs;
    }

    // If the card has a voiceover, assume the GameControl will exit wait mode
    // appropriately and provide a long time for it to do so. Otherwise, use a
    // default, drastically shorter duration in case the card also has no media.
    const maxCardDurationSecs = block.fields.waitAfterEachCardSec
      ? // if the media is longer, we want to use that.
        Math.max(
          1,
          MediaUtils.GetMediaDurationMs(card.media) / 1000,
          block.fields.waitAfterEachCardSec
        )
      : titleCardsToVoiceOverPlans[card.id]
      ? 60 * 60
      : card.media
      ? MediaUtils.GetMediaDurationMs(card.media) / 1000
      : 1;

    // Do not include the media because we don't want it to play via this system
    b.push(i + 1, deltaMs, null, makeWaitMode(maxCardDurationSecs));
    deltaMs = 0; // pushed, safe to reset

    deltaMs += cardDelayDurationMs; // + delayStartMs;
    // Reminder: wait mode duration must be accounted for in the list of
    // actions. An action that follows wait mode must occur _after_ the maximum
    // wait duration, on the timeline.
    deltaMs += maxCardDurationSecs * 1000;
  }

  if (mode === 'teams') {
    b.push(
      cards.length - 1,
      deltaMs,
      'townhall-to-crowd',
      undefined,
      undefined,
      undefined,
      undefined,
      townhallIdealCountdownDurationMs
    );
    deltaMs = townhallIdealCountdownDurationMs;
  }

  b.push(TitleBlockV2GameSessionStatus.END, deltaMs);
  return b.intoV3Recording(
    makeBlockRecordingExtra({
      titleCardsToTeams,
      titleCardsToVoiceOverPlans,
      titleCardsToVoiceOverDelayStartMs,
    })
  );
}

async function generateV3InstructionBlockRecording(
  block: InstructionBlock,
  _extra: GameInfoSnapshot,
  _opts: GenerateV3RecordingOpts
) {
  const b = new BlockActionBuilder<InstructionBlockGameSessionStatus>();

  b.push(InstructionBlockGameSessionStatus.LOADED, 0);
  b.push(
    InstructionBlockGameSessionStatus.GAME_INIT,
    blockTitleAnimationHalfDuration(block)
  );
  b.push(
    InstructionBlockGameSessionStatus.GAME_START,
    500,
    null,
    makeWaitMode(INSTRUCTION_MAX_DISPLAY_SECONDS)
  );
  b.push(
    InstructionBlockGameSessionStatus.GAME_END,
    INSTRUCTION_MAX_DISPLAY_SECONDS * 1000
  );
  b.push(InstructionBlockGameSessionStatus.END, 1000);
  return b.intoV3Recording();
}

async function generateV3OverRoastedBlockRecording(
  block: OverRoastedBlock,
  extra: GameInfoSnapshot,
  _opts: GenerateV3RecordingOpts
) {
  const b = new BlockActionBuilder<OverRoastedBlockGameSessionStatus>();

  b.push(OverRoastedBlockGameSessionStatus.LOADED, 0);
  b.push(
    OverRoastedBlockGameSessionStatus.INTRO,
    blockTitleAnimationHalfDuration(block)
  );
  b.push(
    OverRoastedBlockGameSessionStatus.GAME_INIT,
    MediaUtils.GetMediaDurationMs(BlockKnifeUtils.Media(block, 'introMedia'))
  );
  b.push(
    OverRoastedBlockGameSessionStatus.GAME_START,
    2000,
    null,
    makeWaitMode(block.fields.gameTimeSec)
  );
  b.push(
    OverRoastedBlockGameSessionStatus.GAME_END,
    block.fields.gameTimeSec * 1000
  );
  b.push(OverRoastedBlockGameSessionStatus.OUTRO, 4000);
  b.push(
    OverRoastedBlockGameSessionStatus.RESULTS,
    MediaUtils.GetMediaDurationMs(BlockKnifeUtils.Media(block, 'outroMedia')),
    null,
    block.fields.tutorialMode
      ? undefined
      : makeWaitMode(extra.pauseOnEndBlockMaxWaitSec, true, 0, true)
  );

  // Note(jialin): in practice, the tutorialMode will be used as a single
  // block, and in this mode, both intro/outro and results will be skipped.
  b.push(
    OverRoastedBlockGameSessionStatus.END,
    block.fields.tutorialMode
      ? 0
      : extra.pauseOnEndBlockMaxWaitSec * 1000 + 3500
  );

  return b.intoV3Recording();
}

async function generateV3DrawingPromptBlockRecording(
  block: DrawingPromptBlock,
  extra: GameInfoSnapshot,
  _opts: GenerateV3RecordingOpts
) {
  const b = new BlockActionBuilder<DrawingPromptBlockGameSessionStatus>();
  b.push(DrawingPromptBlockGameSessionStatus.LOADED, 0);
  b.push(
    DrawingPromptBlockGameSessionStatus.INIT,
    blockTitleAnimationHalfDuration(block)
  );

  b.push(
    DrawingPromptBlockGameSessionStatus.DRAWING_START,
    2000,
    null,
    makeWaitMode(block.fields.drawingTimeSec)
  );

  // wait up to 10s for submission when times up
  const waitForSubmissionSec = 10;
  b.push(
    DrawingPromptBlockGameSessionStatus.DRAWING_END,
    block.fields.drawingTimeSec * 1000,
    null,
    makeWaitMode(waitForSubmissionSec)
  );

  b.push(
    DrawingPromptBlockGameSessionStatus.TEAM_VOTE_COUNTING,
    waitForSubmissionSec * 1000,
    null,
    makeWaitMode(block.fields.votingTimeSec)
  );
  b.push(
    DrawingPromptBlockGameSessionStatus.TEAM_VOTE_DONE,
    block.fields.votingTimeSec * 1000
  );

  const titleCreationTimeSec = DrawingPromptUtils.GetTitleCreationTimeSec(
    extra.teamCount
  );
  b.push(
    DrawingPromptBlockGameSessionStatus.TITLE_CREATION_COUNTING,
    2000,
    null,
    makeWaitMode(titleCreationTimeSec)
  );
  b.push(
    DrawingPromptBlockGameSessionStatus.TITLE_CREATION_DONE,
    titleCreationTimeSec * 1000
  );

  b.push(DrawingPromptBlockGameSessionStatus.MATCH_PROMPT_INIT, 2000);

  const matchPromptTimeSec = DrawingPromptUtils.GetMatchPromptTimeSec(
    extra.teamCount
  );
  b.push(
    DrawingPromptBlockGameSessionStatus.MATCH_PROMPT_COUNTING,
    2000,
    null,
    makeWaitMode(matchPromptTimeSec)
  );
  b.push(
    DrawingPromptBlockGameSessionStatus.MATCH_PROMPT_DONE,
    matchPromptTimeSec * 1000
  );

  b.push(
    DrawingPromptBlockGameSessionStatus.REVIEW_ALL_DRAWINGS,
    2000,
    null,
    makeWaitMode(extra.pauseOnEndBlockMaxWaitSec, true, 0, true)
  );

  b.push(
    DrawingPromptBlockGameSessionStatus.RESULTS,
    extra.pauseOnEndBlockMaxWaitSec * 1000 + 2000
  );

  b.push(DrawingPromptBlockGameSessionStatus.END, 5000);
  return b.intoV3Recording();
}

async function generateV3HiddenPictureBlockRecording(
  block: HiddenPictureBlock,
  extra: GameInfoSnapshot,
  _opts: GenerateV3RecordingOpts
) {
  const b = new BlockActionBuilder<HiddenPictureBlockGameSessionStatus>();
  b.push(HiddenPictureBlockGameSessionStatus.LOADED, 0);
  b.push(
    HiddenPictureBlockGameSessionStatus.INTRO,
    blockTitleAnimationHalfDuration(block)
  );
  b.push(
    HiddenPictureBlockGameSessionStatus.GAME_INIT,
    MediaUtils.GetMediaDurationMs(block.fields.introMedia)
  );
  b.push(
    HiddenPictureBlockGameSessionStatus.GAME_START,
    2000,
    null,
    makeWaitMode(block.fields.gameTimeSec)
  );
  b.push(
    HiddenPictureBlockGameSessionStatus.GAME_END,
    block.fields.gameTimeSec * 1000
  );
  b.push(HiddenPictureBlockGameSessionStatus.OUTRO, defaultTimesUpDurationMs());
  b.push(
    HiddenPictureBlockGameSessionStatus.RESULTS,
    MediaUtils.GetMediaDurationMs(block.fields.outroMedia),
    null,
    makeWaitMode(extra.pauseOnEndBlockMaxWaitSec, true, 0, true)
  );
  b.push(
    HiddenPictureBlockGameSessionStatus.END,
    extra.pauseOnEndBlockMaxWaitSec * 1000 + 3500
  );
  return b.intoV3Recording();
}

async function generateV3AIChatBlockRecording(
  block: AIChatBlock,
  extra: GameInfoSnapshot,
  _opts: GenerateV3RecordingOpts
) {
  const b = new BlockActionBuilder<AIChatBlockGameSessionStatus>();

  b.push(AIChatBlockGameSessionStatus.LOADED, 0);
  b.push(
    AIChatBlockGameSessionStatus.INTRO,
    blockTitleAnimationHalfDuration(block)
  );
  b.push(
    AIChatBlockGameSessionStatus.GAME_INIT,
    MediaUtils.GetMediaDurationMs(BlockKnifeUtils.Media(block, 'introMedia'))
  );
  b.push(
    AIChatBlockGameSessionStatus.GAME_START,
    2000,
    null,
    makeWaitMode(block.fields.gameTimeSec)
  );
  b.push(
    AIChatBlockGameSessionStatus.GAME_END,
    block.fields.gameTimeSec * 1000
  );

  const winMediaDurationMs = MediaUtils.GetMediaDurationMs(
    block.fields.winMedia
  );
  const loseMediaDurationMs = MediaUtils.GetMediaDurationMs(
    block.fields.loseMedia
  );
  b.push(
    AIChatBlockGameSessionStatus.OUTRO,
    Math.max(winMediaDurationMs, loseMediaDurationMs) + 4000
  );

  b.push(
    AIChatBlockGameSessionStatus.RESULTS,
    MediaUtils.GetMediaDurationMs(BlockKnifeUtils.Media(block, 'outroMedia')),
    null,
    makeWaitMode(extra.pauseOnEndBlockMaxWaitSec, true, 0, true)
  );

  b.push(
    AIChatBlockGameSessionStatus.END,
    extra.pauseOnEndBlockMaxWaitSec * 1000 + 3500
  );

  return b.intoV3Recording();
}

async function generateV3GuessWhoBlockRecording(
  block: GuessWhoBlock,
  extra: GameInfoSnapshot,
  _opts: GenerateV3RecordingOpts
) {
  const b = new BlockActionBuilder<GuessWhoBlockGameSessionStatus>();
  b.push(GuessWhoBlockGameSessionStatus.LOADED, 0);
  b.push(
    GuessWhoBlockGameSessionStatus.INTRO,
    blockTitleAnimationHalfDuration(block)
  );
  b.push(
    GuessWhoBlockGameSessionStatus.PROMPT_INIT,
    MediaUtils.GetMediaDurationMs(block.fields.introMedia)
  );
  b.push(
    GuessWhoBlockGameSessionStatus.PROMPT_COUNTING,
    1000,
    null,
    makeWaitMode(block.fields.promptTimeSec)
  );
  b.push(
    GuessWhoBlockGameSessionStatus.PROMPT_DONE,
    block.fields.promptTimeSec * 1000
  );
  b.push(GuessWhoBlockGameSessionStatus.MATCH_PROMPT_INIT, 2000);

  const matchPromptTimeSec = GuessWhoUtils.GetMatchPromptTimeSec();
  b.push(
    GuessWhoBlockGameSessionStatus.MATCH_PROMPT_COUNTING,
    TIME_TO_GUESS_WHO_DURATION_SEC * 1000,
    null,
    makeWaitMode(matchPromptTimeSec)
  );
  b.push(
    GuessWhoBlockGameSessionStatus.MATCH_PROMPT_DONE,
    matchPromptTimeSec * 1000
  );
  b.push(
    GuessWhoBlockGameSessionStatus.RESULTS,
    2000,
    null,
    makeWaitMode(extra.pauseOnEndBlockMaxWaitSec, true, 0, true)
  );
  b.push(
    GuessWhoBlockGameSessionStatus.END,
    extra.pauseOnEndBlockMaxWaitSec * 1000
  );
  return b.intoV3Recording();
}

async function generateV3IcebreakerBlockRecording(
  block: IcebreakerBlock,
  _extra: GameInfoSnapshot,
  _opts: GenerateV3RecordingOpts
) {
  const b = new BlockActionBuilder<IcebreakerBlockGameSessionStatus>();

  const gameTimeSec = block.fields.gameTimeSec || 2 * 60 * 60;

  b.push(IcebreakerBlockGameSessionStatus.LOADED, 0);
  b.push(
    IcebreakerBlockGameSessionStatus.GAME_INIT,
    blockTitleAnimationHalfDuration(block)
  );
  b.push(
    IcebreakerBlockGameSessionStatus.GAME_START,
    1000,
    null,
    makeWaitMode(gameTimeSec)
  );
  b.push(
    IcebreakerBlockGameSessionStatus.RESULTS,
    gameTimeSec * 1000,
    null,
    block.fields.skipGameRecap ? undefined : makeWaitMode(60, true, 0, true)
  );
  b.push(
    IcebreakerBlockGameSessionStatus.END,
    block.fields.skipGameRecap ? 0 : 60 * 1000
  );

  return b.intoV3Recording();
}

async function generateV3MarketingBlockRecording(
  block: MarketingBlock,
  _extra: GameInfoSnapshot,
  _opts: GenerateV3RecordingOpts
) {
  const b = new BlockActionBuilder<MarketingBlockGameSessionStatus>();
  b.push(MarketingBlockGameSessionStatus.LOADED, 0);
  b.push(
    MarketingBlockGameSessionStatus.PRESENTING,
    blockTitleAnimationHalfDuration(block),
    null,
    makeWaitMode(5 * 60)
  );
  b.push(MarketingBlockGameSessionStatus.END, 5 * 60 * 1000);
  return b.intoV3Recording();
}

async function generateV3JeopardyBlockRecording(
  block: JeopardyBlock,
  extra: GameInfoSnapshot,
  _opts: GenerateV3RecordingOpts
) {
  const b = new BlockActionBuilder<JeopardyBlockGameSessionStatus>();

  const defaultGameTimeSec = 60 * 60;

  b.push(JeopardyBlockGameSessionStatus.LOADED, 0);
  b.push(
    JeopardyBlockGameSessionStatus.GAME_INIT,
    blockTitleAnimationHalfDuration(block)
  );
  b.push(
    JeopardyBlockGameSessionStatus.GAME_START,
    2000,
    null,
    makeWaitMode(defaultGameTimeSec)
  );
  b.push(JeopardyBlockGameSessionStatus.GAME_END, defaultGameTimeSec * 1000);
  b.push(JeopardyBlockGameSessionStatus.OUTRO, 1000);
  b.push(
    JeopardyBlockGameSessionStatus.RESULTS,
    MediaUtils.GetMediaDurationMs(BlockKnifeUtils.Media(block, 'outroMedia')),
    null,
    makeWaitMode(extra.pauseOnEndBlockMaxWaitSec, true, 0, true)
  );
  b.push(
    JeopardyBlockGameSessionStatus.END,
    extra.pauseOnEndBlockMaxWaitSec * 1000 + 3500
  );

  return b.intoV3Recording();
}

async function generateV3H2HBlockRecording(
  block: HeadToHeadBlock,
  extra: GameInfoSnapshot,
  opts: GenerateV3RecordingOpts
) {
  const b = new BlockActionBuilder<HeadToHeadBlockGameSessionStatus>();
  const gameTimeSec = block.fields.gameTimeSec || 2 * 60 * 60;

  const query = new TagQuery(opts.voiceOverPlans);
  const introVoiceOver = query.selectFirst(['intro']);
  if (introVoiceOver && opts.waitForPreparedBlock) {
    // kick off loading...
    const templateRenderer = extra.variableRegistry;
    const req = await lvoTTSRequestFromPlan(
      introVoiceOver.plan,
      templateRenderer
    );
    lvoCacheWarm(req);
  }

  b.push(HeadToHeadBlockGameSessionStatus.LOADED, 0);
  b.push(
    HeadToHeadBlockGameSessionStatus.GAME_INIT,
    blockTitleAnimationHalfDuration(block)
  );

  const introMediaDurationSec =
    MediaUtils.GetMediaDurationMs(
      fromMediaDTO(block.fields.introMedia?.media)
    ) / 1000;
  const introVODurationSec = introVoiceOver ? 60 : 0.5;
  const introMaxWaitSec = Math.max(introMediaDurationSec, introVODurationSec);
  b.push(
    HeadToHeadBlockGameSessionStatus.GAME_INTRO,
    1000,
    null,
    makeWaitMode(introMaxWaitSec)
  );

  b.push(
    HeadToHeadBlockGameSessionStatus.GAME_START,
    introMaxWaitSec * 1000 + 1000,
    null,
    makeWaitMode(gameTimeSec)
  );

  // When times up, the game can be still in the progress, wait up to 5 mins
  // for judges.
  const waitSecsBeforeShowResults = 5 * 60;
  b.push(
    HeadToHeadBlockGameSessionStatus.GAME_END,
    gameTimeSec * 1000,
    null,
    makeWaitMode(waitSecsBeforeShowResults)
  );

  if (block.fields.showResults) {
    b.push(
      HeadToHeadBlockGameSessionStatus.RESULTS,
      waitSecsBeforeShowResults * 1000,
      null,
      makeWaitMode(extra.pauseOnEndBlockMaxWaitSec, true, 0, true)
    );
  }

  b.push(
    HeadToHeadBlockGameSessionStatus.END,
    extra.pauseOnEndBlockMaxWaitSec * 1000 +
      (block.fields.showResults ? 3500 : 0)
  );
  return b.intoV3Recording();
}
