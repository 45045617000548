import { useMemo } from 'react';

import { getLogger } from '../../logger/logger';
import {
  type VirtualBackgroundEffects,
  VirtualBackgroundMixer,
  type VirtualBackgroundOptions,
} from '../../services/webrtc/virtual-background';
import { Emitter, type EmitterListener } from '../../utils/emitter';
import { type VideoEffectsSettings } from '../VideoEffectsSettings/types';

export type MixerFeatures = 'virtualBackgroundEffects' | 'videoEffects';

export type MixMode =
  | 'none' // no mixing
  | 'simple' // only camera track
  | 'full'; // camera track and additional tracks

export interface IVideoStreamMixer {
  readonly features: MixerFeatures[];
  init(): Promise<void>;
  updateCameraTrack(track: MediaStreamTrack | null): Promise<void>;
  get outputVideoTrack(): MediaStreamTrack | null;
  destroy(): Promise<void>;
  /**
   * Whether the mixer is going to mix any additional tracks other than the
   * camera track.
   */
  get mixMode(): MixMode;
}

export type VirtualBackgroundMixerEvents = {
  'virtual-background-track-updated': (track: MediaStreamTrack | null) => void;
};

export interface IVirtualBackgroundMixer
  extends EmitterListener<VirtualBackgroundMixerEvents> {
  setVirtualBackgroundEffects(
    effects: VirtualBackgroundEffects | null
  ): Promise<void>;
  enableVirtualBackground(): Promise<void>;
  disableVirtualBackground(): Promise<void>;
  get virtualBackgroundEnabled(): boolean;
  get virtualBackgroundAvailable(): boolean;
  get virtualBackgroundTrack(): MediaStreamTrack | null;
}

export interface IVideoEffectsMixer {
  getVideoEffectsSettings(): Readonly<VideoEffectsSettings>;
  updateVideoEffectsSettings(
    settings: Partial<VideoEffectsSettings>
  ): Promise<void>;
}

export class PlayerVideoMixer
  implements IVideoStreamMixer, IVirtualBackgroundMixer
{
  readonly features: MixerFeatures[] = ['virtualBackgroundEffects'];
  private vbgMixer: VirtualBackgroundMixer;
  private outputMediaStream: MediaStream;
  private emitter = new Emitter<VirtualBackgroundMixerEvents>();
  on = this.emitter.on.bind(this.emitter);
  off = this.emitter.off.bind(this.emitter);
  constructor(
    options?: {
      virtualBackground?: VirtualBackgroundOptions;
    },
    readonly log = getLogger().scoped('media-stream-mixer')
  ) {
    this.vbgMixer = new VirtualBackgroundMixer(
      {
        enabled: false,
        ...options?.virtualBackground,
      },
      log
    );
    this.outputMediaStream = new MediaStream();
  }

  async init() {
    await this.vbgMixer.init();
  }

  async updateCameraTrack(track: MediaStreamTrack | null) {
    const outputTrack = await this.vbgMixer.updateCameraTrack(track);
    const tracks = this.outputMediaStream.getTracks();
    for (const track of tracks) {
      this.outputMediaStream.removeTrack(track);
    }
    if (outputTrack) {
      this.outputMediaStream.addTrack(outputTrack);
    }
    this.emitter.emit('virtual-background-track-updated', outputTrack);
  }

  get outputVideoTrack() {
    return this.outputMediaStream.getVideoTracks()[0] ?? null;
  }

  get mixMode(): MixMode {
    return this.virtualBackgroundAvailable ? 'simple' : 'none';
  }

  async setVirtualBackgroundEffects(effects: VirtualBackgroundEffects | null) {
    await this.vbgMixer.setVirtualBackgroundEffects(effects);
  }

  async enableVirtualBackground() {
    await this.vbgMixer.toggle(true);
  }

  async disableVirtualBackground() {
    await this.vbgMixer.toggle(false);
  }

  get virtualBackgroundEnabled() {
    return this.vbgMixer.enabled;
  }

  get virtualBackgroundAvailable() {
    return this.vbgMixer.available;
  }

  get virtualBackgroundTrack() {
    return this.outputVideoTrack;
  }

  async destroy() {
    await this.vbgMixer.dispose();
  }
}

export class NoopVideoStreamMixer implements IVideoStreamMixer {
  readonly features = [];
  private track: MediaStreamTrack | null = null;

  async init() {
    return;
  }

  async updateCameraTrack(track: MediaStreamTrack | null) {
    this.track = track;
  }

  get outputVideoTrack() {
    return this.track;
  }

  get mixMode(): MixMode {
    return 'none';
  }

  async destroy() {
    return;
  }
}

export function useMustVirtualBackgroundMixer(
  mixer: IVideoStreamMixer
): IVirtualBackgroundMixer {
  const maybe = useMaybeVirtualBackgroundMixer(mixer);
  if (maybe) return maybe;
  throw new Error("Mixer doesn't support virtual background effects");
}

export function useMaybeVirtualBackgroundMixer(
  mixer: IVideoStreamMixer
): IVirtualBackgroundMixer | null {
  if (mixer.features.includes('virtualBackgroundEffects')) {
    return mixer as unknown as IVirtualBackgroundMixer;
  }
  return null;
}

export function useMustVideoEffectsMixer(
  mixer: IVideoStreamMixer
): IVideoEffectsMixer {
  const maybe = useMaybeVideoEffectsMixer(mixer);
  if (maybe) return maybe;
  throw new Error("Mixer doesn't support video effects");
}

export function useMaybeVideoEffectsMixer(
  mixer: IVideoStreamMixer
): IVideoEffectsMixer | null {
  if (mixer.features.includes('videoEffects')) {
    return mixer as unknown as IVideoEffectsMixer;
  }
  return null;
}

export function useCreateDefaultVideoStreamMixer() {
  return useMemo(() => new NoopVideoStreamMixer(), []);
}
