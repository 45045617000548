import { useEffect, useLayoutEffect } from 'react';
import { useEffectOnce, usePrevious } from 'react-use';

import {
  type GuessWhoBlock,
  GuessWhoBlockGameSessionStatus,
} from '@lp-lib/game';

import { useInstance } from '../../../../hooks/useInstance';
import { useLiveCallback } from '../../../../hooks/useLiveCallback';
import { assertExhaustive, err2s } from '../../../../utils/common';
import { useCohostClientId, useHostClientId } from '../../../Player';
import { useParticipantsAsArray } from '../../../Player';
import {
  StageMode,
  useSelectOnStageMembers,
  useStageControlAPI,
} from '../../../Stage';
import { useTownhallAPI, useTownhallEnabled } from '../../../Townhall';
import {
  useGameSessionLocalTimer,
  useGameSessionStatus,
  useIsLiveGamePlay,
} from '../../hooks';
import { ondWaitEnd } from '../../OndPhaseRunner';
import { type GameControlProps } from '../Common/GameControl/types';
import {
  useSubmissionStatusSubmittedTeamIds,
  useTeamSubmissionStatuses,
} from '../Common/GamePlay/SubmissionStatusProvider';
import { useStableBlock } from '../Common/hooks';
import {
  useCurrMatchPrompt,
  useCurrMatchState,
  useGeneratedMatchPrompts,
  useGuessWhoGameControlAPI,
} from './GuessWhoProvider';
import {
  GUESS_TIME_PER_SUBMISSION_SEC,
  log,
  REVEAL_TIME_PER_SUBMISSION_SEC,
} from './utils';

type SharedProps = GameControlProps<GuessWhoBlock>;

function Loaded(_props: SharedProps) {
  const api = useGuessWhoGameControlAPI();

  useEffectOnce(() => {
    api.resetGame();
  });

  return null;
}

function Init(props: SharedProps) {
  const { block } = props;
  const api = useGuessWhoGameControlAPI();

  useEffectOnce(() => {
    api.initGame(block.fields.prompts);
  });

  return null;
}

// note(falcon): i struggled with naming here. since much of this code was *ahem* inspired by the work on the drawing
// block, and given their similarities, i kept using the phrase "match prompt".
function MatchPromptInit(props: SharedProps) {
  const api = useGuessWhoGameControlAPI();

  const players = useParticipantsAsArray({
    filters: [
      'host:false',
      'cohost:false',
      'status:connected',
      'team:true',
      'staff:false',
    ],
  });

  useEffectOnce(() => {
    api.configureMatchPromptPhase(
      GUESS_TIME_PER_SUBMISSION_SEC,
      REVEAL_TIME_PER_SUBMISSION_SEC,
      props.block.fields.showGuessers,
      players
    );
  });

  return null;
}

function MatchPromptPhase(props: SharedProps) {
  const { block } = props;
  const controlAPI = useGuessWhoGameControlAPI();
  const generatedMatchPrompts = useGeneratedMatchPrompts();

  const players = useParticipantsAsArray({
    filters: [
      'host:false',
      'cohost:false',
      'status:connected',
      'team:true',
      'staff:false',
    ],
  });

  const currMatchState = useCurrMatchState();
  const currMatchPrompt = useCurrMatchPrompt();
  const stageControl = useStageControlAPI();

  const triggered = useInstance(() => new Set());
  const revealed = useInstance(() => new Set());
  const currTime = useGameSessionLocalTimer();
  const prevTime = usePrevious(currTime);
  const timesup = prevTime === 1 && currTime === 0;

  const hostClientId = useHostClientId();
  const cohostClientId = useCohostClientId();

  useEffectOnce(() => {
    if (!generatedMatchPrompts || generatedMatchPrompts.sequence.length === 0) {
      controlAPI.endMatch([hostClientId, cohostClientId]);
      setTimeout(() => {
        ondWaitEnd();
      }, 1000);
      return;
    }
    controlAPI.nextMatch(0);
  });

  // clear out the stage whenever we move to the next vote phase
  useEffect(() => {
    if (currMatchState.phase === 'vote') {
      stageControl.leaveAll([hostClientId, cohostClientId]);
    }
  }, [cohostClientId, currMatchState.phase, hostClientId, stageControl]);

  // enter reveal phase if timer is up
  useEffect(() => {
    if (
      !currMatchPrompt ||
      currMatchState.phase !== 'vote' ||
      triggered.has(currMatchPrompt.id) ||
      !timesup
    ) {
      return;
    }
    triggered.add(currMatchPrompt.id);
    setTimeout(() => {
      controlAPI.startRevealSequence(
        currMatchPrompt,
        block.fields.pointsPerCorrect,
        players,
        'times up'
      );
    }, 1500);
  }, [
    block.fields.pointsPerCorrect,
    controlAPI,
    currMatchPrompt,
    currMatchState,
    players,
    timesup,
    triggered,
  ]);

  // reveal & grade votes
  useEffect(() => {
    if (
      !currMatchPrompt ||
      currMatchState.phase !== 'reveal' ||
      revealed.has(currMatchPrompt.id)
    ) {
      return;
    }
    const player = players.find((p) => p.id === currMatchPrompt.playerId);
    if (!player) return;

    // bring the user on stage.
    revealed.add(currMatchPrompt.id);
    stageControl.join(player.clientId, StageMode.BLOCK_CONTROLLED);
  }, [currMatchPrompt, currMatchState, players, revealed, stageControl]);

  const next = useLiveCallback(async () => {
    const numPrompts = generatedMatchPrompts?.sequence.length ?? 0;
    const nextIndex = currMatchState.index + 1;
    if (nextIndex >= numPrompts) {
      await controlAPI.endMatch([hostClientId, cohostClientId]);
      await ondWaitEnd();
    } else {
      await controlAPI.nextMatch(nextIndex);
    }
  });

  // move to next phase on timesup of reveal
  useEffect(() => {
    if (!currMatchPrompt || currMatchState.phase !== 'reveal' || !timesup) {
      return;
    }
    next();
  }, [currMatchPrompt, currMatchState, next, timesup]);

  // move to next phase when user signals done
  useEffect(() => {
    if (!currMatchPrompt || currMatchState.phase !== 'done') {
      return;
    }
    next();
  }, [currMatchPrompt, currMatchState, next]);

  return null;
}

function useLiveTeamSubmittedTrigger(idealCountdownSec = 3) {
  const isLiveGame = useIsLiveGamePlay();
  const { sessionStatus } = useTeamSubmissionStatuses();
  const teams = useSubmissionStatusSubmittedTeamIds();

  const api = useTownhallAPI();
  const townhallEnabled = useTownhallEnabled();
  useLayoutEffect(() => {
    if (!townhallEnabled || !isLiveGame) return;
    if (sessionStatus === GuessWhoBlockGameSessionStatus.PROMPT_COUNTING) {
      if (teams.length > 0) {
        api.setNext({
          mode: 'crowd',
          countdownSec: idealCountdownSec,
          type: 'team',
          source: 'LiveGuessWhoBlockPromptSubmissionTeamSubmitted',
          teams,
        });
      }
    }

    if (sessionStatus === GuessWhoBlockGameSessionStatus.PROMPT_DONE) {
      // force everyone over to crowd mode.
      api.setNext({
        mode: 'crowd',
        countdownSec: idealCountdownSec,
        type: 'global',
        source: 'LiveGuessWhoBlockPromptSubmissionTeamSubmitted',
      });
    }
  }, [
    api,
    townhallEnabled,
    teams,
    isLiveGame,
    idealCountdownSec,
    sessionStatus,
  ]);
}

function PromptSubmissionPhase(_props: SharedProps) {
  // OnD is handled universally in OndTownhallTriggers.tsx
  useLiveTeamSubmittedTrigger();
  return null;
}

function End(_props: SharedProps) {
  const isLiveGame = useIsLiveGamePlay();
  const api = useTownhallAPI();
  const townhallEnabled = useTownhallEnabled();
  useEffect(() => {
    if (!isLiveGame || !townhallEnabled) return;
    api.setNext({
      mode: 'team',
      countdownSec: 3,
      type: 'global',
      source: 'LiveGuessWhoBlockEnd',
    });
  }, [api, isLiveGame, townhallEnabled]);
  return null;
}

export function GuessWhoBlockGameControl(
  props: SharedProps
): JSX.Element | null {
  const block = useStableBlock(props.block);
  const gss = useGameSessionStatus<GuessWhoBlockGameSessionStatus>();
  const api = useGuessWhoGameControlAPI();
  const hostClientId = useHostClientId();
  const cohostClientId = useCohostClientId();
  const stageMembers = useSelectOnStageMembers(StageMode.SPOTLIGHT_BLOCK, {
    includeReconnectingMembers: true,
  });
  const stageControl = useStageControlAPI();

  useEffect(() => {
    return () => {
      api.resetGame();
    };
  }, [api]);

  useEffect(() => {
    stageControl.updateStageMode(StageMode.BLOCK_CONTROLLED);
    return () => {
      stageControl.leaveAll([hostClientId, cohostClientId]);
      stageControl.updateStageMode(StageMode.BOS);
    };
  }, [cohostClientId, hostClientId, stageControl]);

  useEffect(() => {
    if (gss !== GuessWhoBlockGameSessionStatus.END) return;
    const promises = stageMembers.map((m) => {
      return stageControl.leave(m.id);
    });
    Promise.all(promises).catch((err) => {
      log.error('remove from stage failed', err2s(err));
    });
  }, [gss, stageControl, stageMembers]);

  switch (gss) {
    case GuessWhoBlockGameSessionStatus.LOADED:
      return <Loaded block={block} />;
    case GuessWhoBlockGameSessionStatus.INTRO:
      return null;
    case GuessWhoBlockGameSessionStatus.PROMPT_INIT:
      return <Init block={block} />;
    case GuessWhoBlockGameSessionStatus.PROMPT_COUNTING:
    case GuessWhoBlockGameSessionStatus.PROMPT_DONE:
      return <PromptSubmissionPhase block={block} />;
    case GuessWhoBlockGameSessionStatus.MATCH_PROMPT_INIT:
      return <MatchPromptInit block={block} />;
    case GuessWhoBlockGameSessionStatus.MATCH_PROMPT_COUNTING:
      return <MatchPromptPhase block={block} />;
    case GuessWhoBlockGameSessionStatus.MATCH_PROMPT_DONE:
      return <MatchPromptPhase block={block} />;
    case GuessWhoBlockGameSessionStatus.RESULTS:
    case GuessWhoBlockGameSessionStatus.SCOREBOARD:
      return null;
    case GuessWhoBlockGameSessionStatus.END:
      return <End block={block} />;
    case null:
    case undefined:
      break;
    default:
      assertExhaustive(gss);
      break;
  }
  return null;
}
