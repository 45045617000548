const AccountIcon = ({ className }: { className?: string }): JSX.Element => {
  return (
    <svg
      className={className || 'w-3.5 h-3.5 fill-current'}
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 20 20'
    >
      <path
        fill='#fff'
        fillRule='evenodd'
        d='M3.437 3.437A9.296 9.296 0 0110 .714 9.296 9.296 0 0119.286 10a9.202 9.202 0 01-.894 3.979.841.841 0 01-1.122.407.839.839 0 01-.393-1.127A7.536 7.536 0 0017.608 10a7.609 7.609 0 10-14.485 3.26.839.839 0 01-1.515.718A9.203 9.203 0 01.714 10a9.296 9.296 0 012.723-6.563zM15.93 16.534l.002.05v.036l-.002.041-.004.043-.005.034-.01.047-.008.031-.013.046-.013.035-.016.04-.02.04-.015.03-.027.043-.016.025-.03.04-.02.025-.03.034-.027.027-.03.026-.035.03-.01.01a9.288 9.288 0 01-11.202 0 .839.839 0 01-.33-.73 9.82 9.82 0 01.212-1.497c.262-.94.75-1.8 1.423-2.506a.839.839 0 011.259.05.876.876 0 01-.104 1.167 4.358 4.358 0 00-.965 1.711.891.891 0 00.414 1.053 7.623 7.623 0 007.317.035.928.928 0 00.475-.993 2.75 2.75 0 00-.09-.357A4.264 4.264 0 0010 12.371a3.741 3.741 0 112.898-1.296.27.27 0 00.066.414 5.935 5.935 0 012.628 3.148c.174.616.287 1.246.338 1.884v.013zM8.353 7.483a2.064 2.064 0 103.434 2.292 2.064 2.064 0 00-3.434-2.292z'
        clipRule='evenodd'
      />
    </svg>
  );
};

export { AccountIcon };
