import { addDays, getDay } from 'date-fns';
import path from 'path';
import { type UseFormReturn } from 'react-hook-form';

import {
  type DtoAddProgramLinkRequest,
  type DtoChannelProgramLink,
  type DtoProgram,
  type DtoProgramRound,
  type DtoProgramTagSettings,
  EnumsProgramCadenceFrequency,
  EnumsProgramType,
  type ModelsProgramTagSettings,
} from '@lp-lib/api-service-client/public';

import { apiService } from '../../services/api-service';
import { assertExhaustive } from '../../utils/common';
import { TimeUtils } from '../../utils/time';

export class ProgramUtils {
  static BuildURL(link: DtoChannelProgramLink, pathname?: string): string {
    return path.join('/programs', link.id, pathname ?? '');
  }

  static ConvertTagSettingsDtoToModel(
    dto: DtoProgramTagSettings | null | undefined
  ): ModelsProgramTagSettings {
    return {
      selectedTagIds: dto?.selectedTagIds ?? [],
    };
  }

  static async MakeAddProgramLinkRequest(
    channelId: string,
    programId: string,
    programType: EnumsProgramType,
    program: DtoProgram
  ): Promise<DtoAddProgramLinkRequest> {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    switch (programType) {
      case EnumsProgramType.ProgramTypeGlobalPairing:
      case EnumsProgramType.ProgramTypeGameDrops:
        return {
          programId,
          programType,
          programTargetId: channelId,
        };
      case EnumsProgramType.ProgramTypeBirthdayAndCelebrations:
        const resp = await apiService.celebration.createCelebration({
          channelId,
          timezone,
        });
        return {
          programId,
          programType,
          programTargetId: resp.data.celebration.id,
        };
      case EnumsProgramType.ProgramTypeIntros:
        const frequency =
          EnumsProgramCadenceFrequency.ProgramCadenceFrequencyEveryWeek;
        return {
          programId,
          programType,
          programTargetId: programId,
          cadenceSettings: {
            frequency,
            nextTriggerTime: ProgramUtils.NextCadenceAfter(
              frequency,
              []
            ).toISOString(),
            timezone: timezone,
            weekdays: [],
          },
        };
      case EnumsProgramType.ProgramTypeWaterCooler:
        return {
          programId,
          programType,
          programTargetId: programId,
          cadenceSettings: {
            frequency:
              EnumsProgramCadenceFrequency.ProgramCadenceFrequencyMultipleTimesPerWeek,
            weekdays: [1, 2, 3, 4, 5],
            nextTriggerTime: ProgramUtils.NextCadenceAfter(
              EnumsProgramCadenceFrequency.ProgramCadenceFrequencyEveryWeek,
              [1, 2, 3, 4, 5]
            ).toISOString(),
            timezone: timezone,
          },
          tagSettings: {
            selectedTagIds: program.tagSettings?.selectedTagIds ?? [],
          },
        };
      case EnumsProgramType.ProgramTypeDummy:
      case EnumsProgramType.ProgramTypeCalendar:
      case EnumsProgramType.ProgramTypeRecognition:
      case EnumsProgramType.ProgramTypeAiSurvey:
      case EnumsProgramType.ProgramTypeCustomTournament:
        throw new Error(`unsupported program: ${programType}`);
      default:
        assertExhaustive(programType);
        throw new Error(`unknown program: ${programType}`);
    }
  }
  static IsRoundStarted(round: DtoProgramRound) {
    return round.status === 'inProgress' || round.status === 'completed';
  }

  // same as api-service/models/program.model.go#NextCadenceAfter
  static NextCadenceAfter(
    frequency: EnumsProgramCadenceFrequency,
    weekdays: number[],
    curr?: Date,
    roundToMinutes = 1,
    defaultHour = 10
  ) {
    const now = new Date();
    let next = curr;
    if (!next) {
      next = new Date();
      next.setHours(defaultHour);
    }
    if (frequency === 0) {
      const clonedWeekdays = [...weekdays];
      if (clonedWeekdays.length === 0) {
        clonedWeekdays.push(1); // Monday
      }
      const sortedWeekdays = clonedWeekdays.sort();
      if (next <= now) {
        next = now;
        while (true) {
          next = TimeUtils.NextWorkDaySince(next);
          if (sortedWeekdays.includes(getDay(next))) {
            break;
          }
        }
      } else {
        while (true) {
          next = TimeUtils.NextWorkDaySinceIncluding(next);
          if (sortedWeekdays.includes(getDay(next))) {
            break;
          }
          next = addDays(next, 1);
        }
      }
    } else {
      if (next <= now) {
        if (!curr) {
          // for the first time init, instead of using frequency,
          // we find the most recent work day.
          next = TimeUtils.NextWorkDaySince(next);
        } else {
          next = TimeUtils.MaxDate(
            TimeUtils.NextWorkDaySince(now),
            TimeUtils.NextWorkDaySinceIncluding(addDays(next, 7 * frequency))
          );
        }
      } else {
        next = TimeUtils.NextWorkDaySinceIncluding(next);
      }
    }
    next = TimeUtils.RoundToNearest(next, roundToMinutes);
    next.setSeconds(0);
    next.setMilliseconds(0);
    return next;
  }
}

export function resetFormAndGoBack(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  form: UseFormReturn<any>,
  onBack: () => void,
  afterMs = 5
) {
  form.reset();
  // trigger in next tick so the _isDirty_ is updated. I tried
  // _requestAnimationFrame_, but it's not always working.
  setTimeout(() => onBack(), afterMs);
  // requestAnimationFrame(onBack);
}
