import { Link } from '@remix-run/react';

import { type TransformedAsyncCallState } from '../../../hooks/useAsyncCall';
import {
  type Game,
  type GameLike,
  GameLikeLabelMap,
  type GameLikeType,
  type GamePack,
} from '../../../types/game';
import { err2s } from '../../../utils/common';
import { BannerWarningMessage } from '../../common/BannerWarningMessage';
import { Modal } from '../../common/Modal';
import { makeSlickArrowButtons } from '../../common/Slick';
import { Loading } from '../../Loading';
import { useUser } from '../../UserContext';
export { ErrorMessage } from '../../../../app/components/ErrorMessage';

export const HomeBreadcrumb = (props: {
  label: string;
  to: string;
}): JSX.Element => {
  return (
    <div className='text-xl'>
      <Link to={props.to}>{props.label}</Link>
    </div>
  );
};

interface GameLikeModalProps {
  type: GameLikeType;
  state: TransformedAsyncCallState;
  error: Error | null;
  onCancel: () => void;
  onConfirm: () => void;
}

export function PrimeWarningMessage(props: {
  gameLike?: Game | GamePack | null;
}): JSX.Element {
  if (!props.gameLike || !props.gameLike.isPrime) return <></>;
  return (
    <BannerWarningMessage className='bg-lp-gray-001 text-white text-xl font-bold overflow-ellipsis'>
      <span>
        <span className='text-red-002'>Notice: </span> this is a published{' '}
        <>{GameLikeLabelMap[props.gameLike.type]}</>. Changes made will be live
        everywhere it is used. Don't 'F' It Up!
      </span>
    </BannerWarningMessage>
  );
}

export function DeleteNonPrimeGameLikeModal(
  props: GameLikeModalProps
): JSX.Element {
  return (
    <Modal borderStyle='white' stopPropagateMouseDown>
      <div className='w-80 p-4 text-white flex flex-col justify-center items-center'>
        <p className='text-2xl text-center'>
          Are you sure you want to delete this {GameLikeLabelMap[props.type]}?
        </p>
        <div className='mt-7 flex'>
          <button
            type='button'
            onClick={props.onCancel}
            className='btn-secondary w-32 h-10'
          >
            Cancel
          </button>
          <button
            type='button'
            onClick={props.onConfirm}
            className='btn-delete w-32 h-10 ml-2 flex items-center justify-center'
            disabled={props.state.isRunning}
          >
            {props.state.isRunning && (
              <Loading text='' containerClassName='mr-2' />
            )}
            <p>Delete</p>
          </button>
        </div>
        {props.error && (
          <div className='text-red-005 text-xs'>{err2s(props.error)}</div>
        )}
      </div>
    </Modal>
  );
}

export function DeletePrimeGameLikeModal(
  props: GameLikeModalProps
): JSX.Element {
  return (
    <Modal borderStyle='white' stopPropagateMouseDown>
      <div className='w-80 p-4 text-white flex flex-col justify-center items-center'>
        <p className='text-red-002 text-2xl font-medium'>HOLD UP!</p>
        <p className='text-2xl font-medium text-center'>
          You are about to <span className='text-red-002'>DELETE</span> a Prime{' '}
          {GameLikeLabelMap[props.type]}!
        </p>
        <p className='text-sms text-center mt-2'>
          Deleting a Prime {GameLikeLabelMap[props.type]} will remove it from
          every and any where it is used. <br />
          MAKE SURE YOU ARE SURE!
        </p>
        <div className='mt-7 flex'>
          <button
            type='button'
            onClick={props.onCancel}
            className='btn-secondary w-32 h-10'
          >
            Cancel
          </button>
          <button
            type='button'
            onClick={props.onConfirm}
            className='btn-delete w-32 h-10 ml-2 flex items-center justify-center'
            disabled={props.state.isRunning}
          >
            {props.state.isRunning && (
              <Loading text='' containerClassName='mr-2' />
            )}
            <p>OK DAD</p>
          </button>
        </div>
        {props.error && (
          <div className='text-red-005 text-xs'>{err2s(props.error)}</div>
        )}
      </div>
    </Modal>
  );
}

export function DuplicatePrimeGameLikeModal(
  props: GameLikeModalProps
): JSX.Element {
  return (
    <Modal borderStyle='white' stopPropagateMouseDown>
      <div className='w-80 p-4 text-white flex flex-col justify-center items-center'>
        <p className='text-2xl font-medium text-center'>
          Are you sure you want to duplicate this Prime{' '}
          {GameLikeLabelMap[props.type]}?
        </p>
        <p className='text-sms text-center mt-2'>
          The duplicated copy will be accessible on the public{' '}
          {GameLikeLabelMap[props.type]}
          Library.
        </p>
        <div className='mt-7 flex'>
          <button
            type='button'
            onClick={props.onCancel}
            className='btn-secondary w-32 h-10'
          >
            Cancel
          </button>
          <button
            type='button'
            onClick={props.onConfirm}
            className='btn-delete w-32 h-10 ml-2 flex items-center justify-center'
            disabled={props.state.isRunning}
          >
            {props.state.isRunning && (
              <Loading text='' containerClassName='mr-2' />
            )}
            <p>Confirm</p>
          </button>
        </div>
        {props.error && (
          <div className='text-red-005 text-xs'>{err2s(props.error)}</div>
        )}
      </div>
    </Modal>
  );
}

export function MyBadge<T extends GameLike>(props: {
  item: T;
}): JSX.Element | null {
  const user = useUser();
  const { item } = props;
  if (item.uid !== user.id) return null;
  return <BadgeContainer text={`My ${GameLikeLabelMap[props.item.type]}`} />;
}

export function FeaturedGameBadge(props: {
  pack: GamePack;
}): JSX.Element | null {
  if (!props.pack.isFeatured) return null;

  return <BadgeContainer text={`Featured Game`} />;
}

export function BadgeContainer(props: {
  text: string;
  className?: string;
}): JSX.Element {
  return (
    <div
      className={`flex items-center justify-center
      bg-warning text-black absolute bottom-0
      text-3xs font-bold w-full h-4 rounded-b-lg
      border border-secondary border-t-0 ${props.className}`}
    >
      {props.text}
    </div>
  );
}

const [ArrowPrev, ArrowNext] = makeSlickArrowButtons({
  base: 'rounded-full border border-secondary bg-lp-black-002 w-12 h-12 md:w-15 md:h-15 absolute top-1/3 transform -translate-y-1/3 z-5 flex items-center justify-center cursor-pointer',
  prev: {
    container: '-left-6',
    disabled: 'opacity-25',
    arrow: 'w-7 h-7 fill-current',
  },
  next: {
    container: '-right-6',
    disabled: 'opacity-25',
    arrow: 'w-7 h-7 fill-current',
  },
});

export { ArrowPrev, ArrowNext };
