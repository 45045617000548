import { proxy } from 'valtio';

import logger from '../../logger/logger';
import { err2s } from '../../utils/common';
import { StorageStore } from '../../utils/storage';
import {
  markSnapshottable,
  type ValtioSnapshottable,
} from '../../utils/valtio';

type Ext = {
  enabled: boolean;
  enabledAtMs: number;
};

const log = logger.scoped('lite-mode');

// Exported only for testing
export function maybeApplyPersistedValue(
  s: ValtioSnapshottable<Ext>,
  nowMs: number,
  enabled: boolean | undefined,
  timestampMs: number | undefined
): void {
  // The persisted lite mode values expire after 24 hours.
  const TWENTY_FOUR_HOURS_IN_MS = 3600 * 24 * 1000;
  if (
    enabled !== undefined &&
    timestampMs !== undefined &&
    timestampMs + TWENTY_FOUR_HOURS_IN_MS > nowMs
  ) {
    s.enabled = enabled;
    s.enabledAtMs = timestampMs;
  }
}

export class LiteModeManager {
  private s = markSnapshottable(
    proxy<Ext>({
      enabled: false,
      enabledAtMs: 0,
    })
  );

  private storage = new StorageStore<{ enabled: boolean; timestampMs: number }>(
    'litemode'
  );

  constructor(nowMs = Date.now()) {
    let enabled: boolean | undefined = undefined;
    let timestampMs: number | undefined = undefined;
    try {
      enabled = this.storage.get('enabled');
      timestampMs = this.storage.get('timestampMs');
    } catch (error) {
      log.error("fail to get 'litemode' from localStorage", err2s(error));
    }

    maybeApplyPersistedValue(this.s, nowMs, enabled, timestampMs);
  }

  toggle = (
    enabled: boolean,
    source: 'reset' | 'settings' | 'suggested'
  ): void => {
    log.info(`${enabled}: ${source}`);
    this.storage.set('enabled', enabled);
    this.storage.set('timestampMs', Date.now());
    this.s.enabled = enabled;
  };

  /**
   * Returns true if Lite Mode was enabled within the last `recentMs`. Useful
   * for auto-continuing through the Device Check on a refresh.
   */
  enabledRecently = (recentMs = 60 * 1000): boolean => {
    if (!this.s.enabled) return false;
    const deltaMs = Date.now() - this.s.enabledAtMs;
    if (deltaMs < recentMs) return true;
    return false;
  };

  listenable = (): Readonly<ValtioSnapshottable<Ext>> => {
    return this.s;
  };
}
