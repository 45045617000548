import { useEffect, useState } from 'react';
import { useLatest } from 'react-use';

import { type Media } from '@lp-lib/media';

import { useIsController } from '../../hooks/useMyInstance';
import { SessionMode } from '../../types';
import { MediaPickPriorityHD, MediaUtils } from '../../utils/media';
import { Draggable } from '../Draggable';
import { GamePlayVideo } from './Blocks/Common/GamePlay/GamePlayVideo';
import { BlockKnifeUtils } from './Blocks/Shared';
import { useLocalLoadedBlocks, useLocalLoadedGameLike } from './GamePlayStore';

function useIsGameLikeChanged(): number {
  const loadedGameLike = useLocalLoadedGameLike();

  useEffect(() => setReload(Date.now()), [loadedGameLike?.id]);
  const [reload, setReload] = useState(0);

  return reload;
}

export function GameVideoTestPlayback(): JSX.Element | null {
  const isController = useIsController();
  const blocks = useLatest(useLocalLoadedBlocks(SessionMode.Live));
  const changed = useIsGameLikeChanged();
  const [mediaList, setMediaList] = useState<Media[]>([]);
  const [activeMediaIndex, setActiveMediaIndex] = useState(-1);
  const [mediaId, setMediaId] = useState<string | null>(null);
  const [mediaUrl, setMediaUrl] = useState<string | null>(null);

  useEffect(() => {
    const mediaList: Media[] = [];
    blocks.current?.forEach((b) => {
      const fields = Object.keys(b.fields).filter((f) => f.endsWith('Media'));
      fields.forEach((f) => {
        const media = BlockKnifeUtils.Media(b, f as never);
        if (media) mediaList.push(media);
      });
      setMediaList(mediaList);
    });
    return () => {
      setMediaList([]);
    };
  }, [changed, blocks]);

  const handlePlayNext = async () => {
    let nextIndex = activeMediaIndex + 1;
    if (nextIndex >= mediaList.length) {
      nextIndex = 0;
    }
    const media = mediaList[nextIndex];
    if (!media) {
      setMediaId(null);
      setMediaUrl(null);
      setActiveMediaIndex(-1);
      return;
    }
    const url = MediaUtils.PickMediaUrl(media, {
      priority: MediaPickPriorityHD,
    });
    setMediaId(media.id);
    setMediaUrl(url);
    setActiveMediaIndex(nextIndex);
  };

  const handleStop = async () => {
    setMediaId(null);
    setMediaUrl(null);
    setActiveMediaIndex(-1);
  };

  return (
    <Draggable>
      {(containerRef, handleRef) => (
        <div
          className='bg-black absolute w-1/2 h-auto left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 border border-[#303436] rounded-xl p-1 z-45'
          ref={containerRef}
        >
          <header
            ref={handleRef}
            className='p-3 text-2xs font-bold text-white cursor-move flex flex-row items-center justify-between'
          >
            <div>Test Switch Videos</div>
          </header>
          <div className='relative'>
            <div className='aspect-w-16 aspect-h-9'>
              <GamePlayVideo
                mediaId={mediaId ?? ''}
                mediaUrl={mediaUrl ?? ''}
                playBackgroundMusicWithMedia={false}
                containerClassName=''
                className={`bg-black w-full h-full rounded-xl object-contain`}
                autoPlay
                pauseOnEnded
                likelyFullscreen={false}
              />
            </div>
          </div>
          {isController && (
            <div className='flex flex-row items-center justify-between mt-2'>
              {mediaList.length === 0 ? (
                <div className='w-full h-7 text-sms text-gray-400 flex items-center justify-center mt-2'>
                  Load the games with videos
                </div>
              ) : (
                <>
                  <button
                    type='button'
                    className='btn-primary w-full px-10 h-7 text-sms mr-1'
                    onClick={handlePlayNext}
                    disabled={mediaList.length === 0}
                  >
                    Play Next
                  </button>
                  <button
                    type='button'
                    className='btn-delete w-full px-10 h-7 text-sms ml-1'
                    onClick={handleStop}
                    disabled={activeMediaIndex < 0}
                  >
                    Stop
                  </button>
                </>
              )}
            </div>
          )}
          {!isController && (
            <div className='w-full h-7 text-sms text-gray-400 flex items-center justify-center mt-2'>
              Play the videos from the host/organizer page
            </div>
          )}
        </div>
      )}
    </Draggable>
  );
}
