import { proxy as comlinkProxy } from 'comlink';

import { createWorkerPoweredLoop } from '../components/Loop/createWorkerPoweredLoop';
import { createLoop } from '../components/Loop/loop';
import logger from '../logger/logger';
import {
  getAudioContext,
  getEchoCancelledAudioDestination,
} from '../services/audio/audio-context';
import { type VolumeController } from '../services/audio/types';
import { getStaticAssetPath } from './assets';
import { Canvas2DHelper, HiddenCanvas } from './canvas';
import { uuidv4, xDomainifyUrl } from './common';
import { Emitter, type EmitterListener } from './emitter';
import { releaseMediaStream } from './media';
import { rsCounter } from './rstats.client';

const log = logger.scoped('video-stream-proxy');

type VideoStreamProxyEvents = {
  'media-stream-updated': (mediaStream: MediaStream | undefined) => void;
};

type UpdateInputEvents = 'source-changed' | 'loaded' | 'played';

export interface IVideoStreamProxy
  extends EmitterListener<VideoStreamProxyEvents>,
    VolumeController {
  pipe(video: HTMLVideoElement | null): void;
  getMediaStream(): MediaStream | undefined;
  destroy(): void;
}

export class DummyProxy implements IVideoStreamProxy {
  protected emitter = new Emitter<VideoStreamProxyEvents>();
  on = this.emitter.on.bind(this.emitter);
  off = this.emitter.off.bind(this.emitter);
  updateVolume(_volume: number): void {
    return;
  }
  pipe(_video: HTMLVideoElement | null): void {
    return;
  }
  getMediaStream(): MediaStream | undefined {
    return;
  }
  destroy(): void {
    this.emitter.clear();
  }
}

abstract class BaseProxy implements IVideoStreamProxy {
  protected id: string;
  protected video?: {
    instance: HTMLVideoElement;
    playedTimes: number;
    ended: boolean;
  };
  protected readonly mesnMap = new Map<
    HTMLVideoElement,
    MediaElementAudioSourceNode
  >();
  protected emitter = new Emitter<VideoStreamProxyEvents>();
  on = this.emitter.on.bind(this.emitter);
  off = this.emitter.off.bind(this.emitter);
  constructor(
    protected audioCtx = getAudioContext(),
    protected msdn = audioCtx.createMediaStreamDestination(),
    protected localGain = audioCtx.createGain(),
    protected remoteGain = audioCtx.createGain()
  ) {
    this.id = uuidv4();
    this.localGain.connect(getEchoCancelledAudioDestination());
    this.remoteGain.connect(this.msdn);
  }

  updateVolume(volume: number): void {
    this.localGain.gain.value = volume;
  }

  private onVideoLoaded = (): void => {
    if (this.video) {
      const mesn = this.mesnMap.get(this.video.instance);
      if (mesn) {
        mesn.disconnect();
      } else {
        this.mesnMap.set(
          this.video.instance,
          this.audioCtx.createMediaElementSource(this.video.instance)
        );
      }
    }
    this.updateInput('loaded');
  };

  private onVideoPlayed = (): void => {
    if (!this.video) return;
    this.video.playedTimes += 1;
    // recreate the mediaStream when replaying
    if (this.video.playedTimes > 1) {
      this.video.ended = false;
      this.updateInput('played');
    }
  };

  private onVideoEnded = (): void => {
    if (!this.video) return;
    this.video.ended = true;
  };

  private addVideoListeners(): void {
    if (!this.video) return;
    const instance = this.video.instance;
    instance.addEventListener('loadedmetadata', this.onVideoLoaded);
    instance.addEventListener('play', this.onVideoPlayed);
    instance.addEventListener('ended', this.onVideoEnded);
  }

  private removeVideoListeners(): void {
    if (!this.video) return;
    const instance = this.video.instance;
    instance.removeEventListener('loadedmetadata', this.onVideoLoaded);
    instance.removeEventListener('play', this.onVideoPlayed);
    instance.removeEventListener('ended', this.onVideoEnded);
  }

  pipe(video: HTMLVideoElement | null): void {
    this.removeVideoListeners();
    if (this.video && this.mesnMap.has(this.video.instance)) {
      this.mesnMap.get(this.video.instance)?.disconnect();
      this.mesnMap.delete(this.video.instance);
    }
    if (video) {
      this.video = { instance: video, playedTimes: 0, ended: false };
      this.addVideoListeners();
    } else {
      this.video = undefined;
    }
    this.updateInput('source-changed');
  }

  destroy(): void {
    this.removeVideoListeners();
    this.emitter.clear();
    const it = this.mesnMap[Symbol.iterator]();
    for (const [, mesn] of it) {
      mesn.disconnect();
    }
    this.mesnMap.clear();
    this.localGain.disconnect();
    this.remoteGain.disconnect();
    this.msdn.disconnect();
  }

  protected abstract updateInput(event: UpdateInputEvents): void;
  abstract getMediaStream(): MediaStream | undefined;
}

export class CanvasProxy extends BaseProxy {
  private ocvs: HiddenCanvas;
  private cvs: HTMLCanvasElement;
  private ctx: CanvasRenderingContext2D;
  private ctxHelper: Canvas2DHelper;
  private destMediaStream: MediaStream;
  private cancelLoop: () => void;
  private pause: boolean;
  private placeholderVideo?: HTMLVideoElement;
  constructor(config?: {
    loopMethod?: 'raf' | 'timer';
    loopUseWorker?: boolean;
    fps?: number;
    clearCanvasDelayMs?: number;
    debug?: boolean;
    cvsWidth?: number;
    cvsHeight?: number;
    placeholderVideoUrl?: string;
    useVideoPlaceholder?: boolean;
  }) {
    super();
    const {
      loopMethod,
      loopUseWorker,
      fps,
      debug,
      cvsWidth,
      cvsHeight,
      placeholderVideoUrl,
      useVideoPlaceholder,
    } = Object.assign(
      {},
      {
        loopMethod: 'raf',
        loopUseWorker: false,
        fps: 20,
        clearCanvasDelayMs: 3000,
        cvsWidth: 1280,
        cvsHeight: 720,
        placeholderVideoUrl: getStaticAssetPath(
          'videos/game-media-placeholder-v2.mp4'
        ),
      },
      config
    );
    const canvasConfig = debug
      ? {
          visible: true,
          className: 'right-0 top-0 absolute z-50 w-60 border border-white',
        }
      : undefined;
    this.ocvs = new HiddenCanvas('video-stream-proxy', canvasConfig);
    this.cvs = this.ocvs.cvs;
    this.cvs.width = cvsWidth;
    this.cvs.height = cvsHeight;
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    this.ctx = this.cvs.getContext('2d', { alpha: false })!;
    this.ctxHelper = new Canvas2DHelper(this.ctx);
    this.ocvs.attach();
    this.destMediaStream = this.cvs.captureStream();
    this.destMediaStream.addTrack(this.msdn.stream.getAudioTracks()[0]);
    this.pause = false;

    if (useVideoPlaceholder && placeholderVideoUrl) {
      this.placeholderVideo = document.createElement('video');
      this.placeholderVideo.crossOrigin = 'anonymous';
      this.placeholderVideo.muted = true;
      this.placeholderVideo.autoplay = true;
      this.placeholderVideo.loop = true;
      this.placeholderVideo.src = xDomainifyUrl(placeholderVideoUrl);
    }

    log.info('loop config', { loopMethod, loopUseWorker });

    if (loopUseWorker) {
      const loopWorker = createWorkerPoweredLoop();
      // use comlinkProxy to avoid buggy valtio eslint complain
      loopWorker.start(loopMethod, comlinkProxy(this.tick), fps);
      this.cancelLoop = () => {
        loopWorker.stop();
        loopWorker.terminate();
      };
    } else {
      this.cancelLoop = createLoop({
        kind: loopMethod,
        tick: this.tick,
        fps,
      });
    }
  }

  private tick = () => {
    rsCounter('game-play-media-frame-ms')?.start();

    if (this.pause || !this.video) {
      this.fillPlaceholder();
    } else {
      this.ctxHelper.drawImage(
        this.video.instance,
        this.cvs.width,
        this.cvs.height
      );
    }

    rsCounter('game-play-media-frame-ms')?.end();
  };

  private fillPlaceholder(): void {
    if (this.placeholderVideo) {
      this.ctxHelper.drawImage(
        this.placeholderVideo,
        this.cvs.width,
        this.cvs.height
      );
      return;
    }
    this.ctx.fillStyle = '#000000';
    this.ctx.fillRect(0, 0, this.cvs.width, this.cvs.height);
  }

  protected updateInput(event: UpdateInputEvents): void {
    if (event === 'source-changed') {
      this.pause = true;
      this.fillPlaceholder();
      log.debug('source-changed');
      return;
    }
    if (!this.video) return;
    this.pause = false;
    this.mesnMap.get(this.video.instance)?.connect(this.localGain);
    this.mesnMap.get(this.video.instance)?.connect(this.remoteGain);
  }

  getMediaStream(): MediaStream | undefined {
    return this.destMediaStream;
  }

  destroy(): void {
    super.destroy();
    this.cancelLoop();
    releaseMediaStream(this.destMediaStream);
    this.ocvs.detach();
  }
}
