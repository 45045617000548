import { useSearchParams } from '@remix-run/react';
import { format } from 'date-fns';

import { useFeatureQueryParam } from '../../hooks/useFeatureQueryParam';
import { useEvent } from '../Event/useEvent';
import { Loading } from '../Loading';

export function LobbyEventPreview(): JSX.Element | null {
  const featureEnabled = useFeatureQueryParam('lobby-event-preview');
  const [params] = useSearchParams();
  const eventId = params.get('event-id');

  if (!featureEnabled || !eventId) {
    return null;
  }

  return <LobbyEventPreviewInternal eventId={eventId} />;
}

function LobbyEventPreviewInternal(props: {
  eventId: string;
}): JSX.Element | null {
  const { data: event, isLoading, error } = useEvent(props.eventId);

  const orgName =
    event?.organizer?.organization?.name ?? event?.data?.orgName ?? 'Unknown';
  const organizerName = event?.data?.organizerName
    ? event?.data?.organizerName
    : event?.organizer
    ? `${event.organizer.firstName} ${event.organizer.lastName}`
    : 'Unknown';

  return (
    <div className='bg-lp-black-001 rounded-xl p-4 text-sms'>
      <div className='font-bold text-base border-b border-white-002 pb-1.5 mb-1.5'>
        Event Details
      </div>
      {isLoading ? (
        <div className='py-2 w-full flex justify-center'>
          <Loading text='' />
        </div>
      ) : error || !event ? (
        <div className='mt-2 text-xs text-icon-gray w-full flex flex-col items-center justify-center text-center gap-4'>
          <span>
            Could not load event details for event-id{' '}
            <strong>{props.eventId}</strong>
            .
            <br />
            Double check the event-id and try again, or reach out to the
            producer.
          </span>
          {error?.message && (
            <span className='text-red-003'>{error.message}</span>
          )}
        </div>
      ) : (
        <>
          <div className='text-sm text-icon-gray pt-1 pb-3'>
            The following event information will be logged and associated with
            this session once you start the stream. Be sure to double check it!
            If this is the wrong event information, reach out to the producer.
          </div>

          <dl className='w-3/4'>
            <div className='grid grid-cols-3 gap-1'>
              <dt className='text-sm font-medium text-icon-gray'>
                Organization
              </dt>
              <dd className='text-sm leading-6 col-span-2'>{orgName}</dd>
            </div>
            <div className='grid grid-cols-3 gap-1'>
              <dt className='text-sm font-medium text-icon-gray'>Organizer</dt>
              <dd className='text-sm leading-6 col-span-2'>{organizerName}</dd>
            </div>
            <div className='grid grid-cols-3 gap-1'>
              <dt className='text-sm font-medium text-icon-gray'>Date</dt>
              <dd className='text-sm leading-6 col-span-2 flex items-baseline gap-1'>
                {format(new Date(event.startAt), 'M/d/yyyy @ h:mm aa')}
              </dd>
            </div>
            <div className='grid grid-cols-3 gap-1'>
              <dt className='text-sm font-medium text-icon-gray'>Event Id</dt>
              <dd className='text-sm leading-6 col-span-2'>{props.eventId}</dd>
            </div>
          </dl>
        </>
      )}
    </div>
  );
}
