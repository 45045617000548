export const MusicPlayIcon = ({
  className,
}: {
  className?: string;
}): JSX.Element => {
  return (
    <svg
      className={className}
      width='39'
      height='39'
      viewBox='0 0 39 39'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M19.5 39C30.2696 39 39 30.2696 39 19.5C39 8.73045 30.2696 0 19.5 0C8.73045 0 0 8.73045 0 19.5C0 30.2696 8.73045 39 19.5 39ZM26.2013 21.6419C27.4567 20.9217 27.4567 19.0783 26.2013 18.3581L17.0386 13.1018C15.8072 12.3954 14.2857 13.3029 14.2857 14.7437V25.2563C14.2857 26.6971 15.8072 27.6046 17.0386 26.8982L26.2013 21.6419Z'
        fill='url(#paint0_linear_46_17136)'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M19.5 39C30.2696 39 39 30.2696 39 19.5C39 8.73045 30.2696 0 19.5 0C8.73045 0 0 8.73045 0 19.5C0 30.2696 8.73045 39 19.5 39ZM26.2013 21.6419C27.4567 20.9217 27.4567 19.0783 26.2013 18.3581L17.0386 13.1018C15.8072 12.3954 14.2857 13.3029 14.2857 14.7437V25.2563C14.2857 26.6971 15.8072 27.6046 17.0386 26.8982L26.2013 21.6419Z'
        fill='white'
        fillOpacity='0.15'
      />
      <defs>
        <linearGradient
          id='paint0_linear_46_17136'
          x1='39'
          y1='0'
          x2='0'
          y2='39'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#2BE0FA' />
          <stop offset='1' stopColor='#127392' />
        </linearGradient>
      </defs>
    </svg>
  );
};

export const MusicPauseIcon = ({
  className,
}: {
  className?: string;
}): JSX.Element => {
  return (
    <svg
      className={className}
      width='39'
      height='39'
      viewBox='0 0 39 39'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M19.5 39C30.2696 39 39 30.2696 39 19.5C39 8.73045 30.2696 0 19.5 0C8.73045 0 0 8.73045 0 19.5C0 30.2696 8.73045 39 19.5 39ZM14.2857 14.7619C14.2857 13.7099 15.1385 12.8571 16.1905 12.8571C17.2424 12.8571 18.0952 13.7099 18.0952 14.7619V25.2381C18.0952 26.2901 17.2424 27.1429 16.1905 27.1429C15.1385 27.1429 14.2857 26.2901 14.2857 25.2381V14.7619ZM21.9048 14.7619C21.9048 13.7099 22.7576 12.8571 23.8095 12.8571C24.8615 12.8571 25.7143 13.7099 25.7143 14.7619V25.2381C25.7143 26.2901 24.8615 27.1429 23.8095 27.1429C22.7576 27.1429 21.9048 26.2901 21.9048 25.2381V14.7619Z'
        fill='url(#paint0_linear_46_17141)'
      />
      <defs>
        <linearGradient
          id='paint0_linear_46_17141'
          x1='39'
          y1='0'
          x2='0'
          y2='39'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#2BE0FA' />
          <stop offset='1' stopColor='#127392' />
        </linearGradient>
      </defs>
    </svg>
  );
};

export const MusicPrevIcon = ({
  className,
  disabled = false,
}: {
  className?: string;
  disabled?: boolean;
}): JSX.Element => {
  if (disabled) {
    return (
      <svg
        className={className}
        width='24'
        height='24'
        viewBox='0 0 24 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <g opacity='0.4'>
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M7.99463 7C7.99463 6.45 7.54463 6 6.99463 6C6.44463 6 5.99463 6.45 5.99463 7V17C5.99463 17.55 6.44463 18 6.99463 18C7.54463 18 7.99463 17.55 7.99463 17V7ZM16.4246 16.8899L10.6546 12.8199C10.0846 12.4199 10.0846 11.5799 10.6546 11.1799L16.4246 7.10988C17.0946 6.64988 18.0046 7.11988 18.0046 7.92988V16.0699C18.0046 16.8799 17.0846 17.3599 16.4246 16.8899Z'
            fill='#8B9294'
          />
        </g>
      </svg>
    );
  }

  return (
    <svg
      className={className}
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M7.99463 7C7.99463 6.45 7.54463 6 6.99463 6C6.44463 6 5.99463 6.45 5.99463 7V17C5.99463 17.55 6.44463 18 6.99463 18C7.54463 18 7.99463 17.55 7.99463 17V7ZM16.4246 16.8899L10.6546 12.8199C10.0846 12.4199 10.0846 11.5799 10.6546 11.1799L16.4246 7.10988C17.0946 6.64988 18.0046 7.11988 18.0046 7.92988V16.0699C18.0046 16.8799 17.0846 17.3599 16.4246 16.8899Z'
        fill='white'
      />
    </svg>
  );
};

export const MusicNextIcon = ({
  className,
  disabled = false,
}: {
  className?: string;
  disabled?: boolean;
}): JSX.Element => {
  if (disabled) {
    return (
      <svg
        className={className}
        width='24'
        height='24'
        viewBox='0 0 24 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <g opacity='0.4'>
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M16.0002 17V7C16.0002 6.45 16.4502 6 17.0002 6C17.5502 6 18.0002 6.45 18.0002 7V17C18.0002 17.55 17.5502 18 17.0002 18C16.4502 18 16.0002 17.55 16.0002 17ZM13.35 12.8198L7.58 16.8898C6.91 17.3498 6 16.8798 6 16.0698V7.92979C6 7.11979 6.91 6.64979 7.58 7.10979L13.35 11.1898C13.91 11.5798 13.91 12.4198 13.35 12.8198Z'
            fill='#8B9294'
          />
        </g>
      </svg>
    );
  }
  return (
    <svg
      className={className}
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M16.0002 17V7C16.0002 6.45 16.4502 6 17.0002 6C17.5502 6 18.0002 6.45 18.0002 7V17C18.0002 17.55 17.5502 18 17.0002 18C16.4502 18 16.0002 17.55 16.0002 17ZM13.35 12.8198L7.58 16.8898C6.91 17.3498 6 16.8798 6 16.0698V7.92979C6 7.11979 6.91 6.64979 7.58 7.10979L13.35 11.1898C13.91 11.5798 13.91 12.4198 13.35 12.8198Z'
        fill='white'
      />
    </svg>
  );
};
