import isEqual from 'lodash/isEqual';
import { useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import type { SingleValue } from 'react-select';
import AsyncSelect from 'react-select/async';

import {
  type DtoSharedAsset,
  type DtoTTSRenderRequest,
  EnumsSharedAssetPurpose,
  EnumsTTSCacheControl,
  EnumsTTSRenderPolicy,
  type ModelsTTSLabeledRenderSettings,
  type ModelsTTSRenderSettings,
} from '@lp-lib/api-service-client/public';
import { type Media, MediaType } from '@lp-lib/media';

import { useLiveCallback } from '../../hooks/useLiveCallback';
import { apiService } from '../../services/api-service';
import { once } from '../../utils/common';
import { MediaUtils } from '../../utils/media';
import { playWithCatch } from '../../utils/playWithCatch';
import { buildReactSelectStyles } from '../../utils/react-select';
import { UncontrolledRangeInput } from '../Form/UncontrolledRangeInput';
import { RefreshIcon } from '../icons/RefreshIcon';
import { Loading } from '../Loading';
import { SupportedVariables } from './TTSScriptsEditor';
import { type VariableRegistry } from './VariableRegistry';

export function TTSScriptEditorFullScreen(props: {
  defaultValue?: string | null | undefined;
  placeholder?: string | null;
  onCancel: () => void;
  onChange: (script: string, delayedStartMs?: number) => void;
  onBeforeRender?: (value: Nullable<string>) => Promise<Nullable<string>>;
  settings?: ModelsTTSLabeledRenderSettings | null;
  previewMedia?: Media | null;
  previewLabel?: string;
  supportedVariables?: VariableRegistry;
  delayedStartMs?: number;
}): JSX.Element {
  const audioRef = useRef<HTMLAudioElement>(null);
  const previewMediaRef = useRef<HTMLVideoElement>(null);
  const scriptRef = useRef<HTMLTextAreaElement>(null);

  const [isGenerating, setIsGenerating] = useState(false);
  const [preview, setPreview] = useState<string | undefined>(undefined);
  const [previewReq, setPreviewReq] = useState<DtoTTSRenderRequest | undefined>(
    undefined
  );
  const [error, setError] = useState<Error | undefined>(undefined);
  const [delayedStartMs, setDelayedStartMs] = useState(props.delayedStartMs);
  const [previewTTSSettings, setPreviewTTSSettings] =
    useState<ModelsTTSLabeledRenderSettings | null>(null);
  const [isDirty, setIsDirty] = useState(true);

  useLayoutEffect(() => {
    const src = MediaUtils.PickMediaUrl(props.previewMedia);
    if (src && previewMediaRef.current) {
      previewMediaRef.current.src = src;
      previewMediaRef.current.poster =
        props.previewMedia?.type === MediaType.Image
          ? src
          : props.previewMedia?.firstThumbnailUrl ?? '';
    }
  }, [props.previewMedia]);

  const delayedStartRef = useRef<ReturnType<typeof setTimeout> | null>(null);
  useEffect(() => {
    if (!previewMediaRef.current || !audioRef.current) return;

    const ctrl = new AbortController();
    const signal = ctrl.signal;
    const opts = { signal };
    previewMediaRef.current.addEventListener(
      'play',
      async () => {
        if (!previewMediaRef.current || !audioRef.current) return;

        const previewCurrentTimeMs = previewMediaRef.current.currentTime * 1000;
        const offsetMs = previewCurrentTimeMs - (delayedStartMs ?? 0);
        if (offsetMs < 0) {
          // we need to wait...
          audioRef.current.currentTime = 0;
          if (delayedStartRef.current) clearTimeout(delayedStartRef.current);
          delayedStartRef.current = setTimeout(() => {
            playWithCatch(audioRef.current);
          }, -offsetMs);
        } else {
          const nextTime = offsetMs / 1000;
          if (nextTime > audioRef.current.duration) return;

          audioRef.current.currentTime = nextTime;
          await once(audioRef.current, 'seeked');
          playWithCatch(audioRef.current);
        }
      },
      opts
    );

    previewMediaRef.current.addEventListener(
      'pause',
      () => {
        if (!previewMediaRef.current || !audioRef.current) return;

        // the preview media paused either because the user clicked pause, or the preview media ended.
        // if it's the latter, we should do nothing, the audio can continue playing.
        if (
          audioRef.current.duration + (delayedStartMs ?? 0) >
            previewMediaRef.current.duration &&
          previewMediaRef.current.currentTime >=
            previewMediaRef.current.duration
        )
          return;

        if (delayedStartRef.current) clearTimeout(delayedStartRef.current);
        audioRef.current?.pause();
      },
      opts
    );
    return () => {
      ctrl.abort();
      if (delayedStartRef.current) clearTimeout(delayedStartRef.current);
    };
  }, [delayedStartMs]);

  const makeRenderRequest = useLiveCallback(
    (script: string, ttsRenderSettings: ModelsTTSRenderSettings) => {
      const req: DtoTTSRenderRequest = {
        script,
        ttsRenderSettings,
        policy: EnumsTTSRenderPolicy.TTSRenderPolicyReadThrough,
        cacheControl: EnumsTTSCacheControl.TTSCacheControlShortLive,
      };
      return req;
    }
  );

  const handleGenerate = useLiveCallback(async () => {
    const ttsRenderSettings = previewTTSSettings ?? props.settings;
    if (!scriptRef.current || !audioRef.current || !ttsRenderSettings) return;

    let script: Nullable<string> = scriptRef.current.value;
    const onBeforeRender = props.onBeforeRender || (async (v) => v);
    script = await onBeforeRender(script);
    if (!script) return;

    setIsGenerating(true);
    setError(undefined);
    try {
      const req = makeRenderRequest(script, ttsRenderSettings);
      if (isEqual(req, previewReq) && preview) {
        audioRef.current.src = preview;
        return;
      } else {
        const r = await apiService.tts.render(req);
        const data = r.data;
        const previewBlobUrl = URL.createObjectURL(data);
        setPreview(previewBlobUrl);
        setPreviewReq(req);
        setIsDirty(false);
        audioRef.current.src = previewBlobUrl;
      }
    } catch (e) {
      if (e instanceof Error) {
        setError(e);
      } else {
        setError(new Error(String(e)));
      }
    } finally {
      setIsGenerating(false);
    }
  });

  const onSave = useLiveCallback(async () => {
    if (!scriptRef.current) return;
    const script = scriptRef.current.value;
    props.onChange(script, delayedStartMs ?? 0);
  });

  const handleScriptChange = useLiveCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      const settings = previewTTSSettings ?? props.settings;
      if (!settings) return;

      const req = makeRenderRequest(e.target.value, settings);
      setIsDirty(!isEqual(req, previewReq));
    }
  );
  const handlePreviewVoiceChange = useLiveCallback(
    (value: ModelsTTSLabeledRenderSettings | null) => {
      setPreviewTTSSettings(value);
      const settings = value ?? props.settings;
      if (!settings) return;

      const req = makeRenderRequest(scriptRef.current?.value ?? '', settings);
      setIsDirty(!isEqual(req, previewReq));
    }
  );

  return (
    <div className='relative w-full h-full overflow-y-scroll scrollbar'>
      <div className='w-full h-full flex flex-col'>
        <header className='sticky z-5 top-0 pt-3 pb-5 w-full px-15 flex items-center justify-between bg-black'>
          <div className='text-center text-white font-bold text-xl'>
            Script Editor
          </div>

          <div className='flex justify-center items-center gap-4'>
            <button
              type='button'
              className='btn-secondary w-32 h-10'
              disabled={isGenerating}
              onClick={props.onCancel}
            >
              Cancel
            </button>
            <button
              type='submit'
              className='btn-primary w-32 h-10 flex items-center justify-center'
              disabled={isGenerating}
              onClick={onSave}
            >
              Save
            </button>
          </div>
        </header>

        {error && (
          <div className='text-right text-sm font-bold text-red-001 px-15 pb-4'>
            {error?.message}
          </div>
        )}

        <div className='w-full h-full flex gap-4 px-15 pb-8'>
          <section className='w-1/2 h-full flex flex-col gap-2'>
            <div className='flex items-center gap-2'>
              <div className='font-bold'>Script</div>
              {props.supportedVariables && (
                <SupportedVariables
                  variableRegistry={props.supportedVariables}
                />
              )}
            </div>

            <textarea
              ref={scriptRef}
              className='field m-0 h-full py-2 scrollbar'
              defaultValue={props.defaultValue ?? undefined}
              placeholder={props.placeholder ?? 'Enter script...'}
              onChange={handleScriptChange}
            />

            {delayedStartMs !== undefined && (
              <div className='flex items-center'>
                <div>
                  <span className='text-xs text-icon-gray font-bold'>
                    Delayed Start
                  </span>
                  <p className='text-sms text-secondary'>
                    Wait this long (milliseconds) before starting the voice
                    over.
                  </p>
                </div>
                <UncontrolledRangeInput
                  className='flex justify-between items-center w-full gap-2'
                  min={0}
                  max={10000}
                  step={1}
                  defaultValue={delayedStartMs}
                  onChange={setDelayedStartMs}
                />
              </div>
            )}

            <div className='flex items-center gap-4'>
              <div className='flex-1'>
                <span className='text-xs text-icon-gray font-bold'>
                  Override Preview Voice
                </span>
                <p className='text-sms text-secondary'>
                  Select a voice for previewing. Note that this script may be
                  read with a different voice assigned by the gamepack. The
                  voice over timing may be different as a result. This value
                  will not persist.
                </p>
              </div>
              <div className='flex-1'>
                <SharedVoicePicker onChange={handlePreviewVoiceChange} />
              </div>
            </div>

            <div className='flex items-start justify-end gap-4'>
              <button
                type='button'
                className='flex-none btn btn-secondary flex items-center justify-center gap-2 w-48 h-10'
                disabled={
                  isGenerating ||
                  !isDirty ||
                  !(previewTTSSettings || props.settings)
                }
                onClick={handleGenerate}
              >
                {isGenerating ? (
                  <Loading text='' imgClassName='w-5 h-5' />
                ) : (
                  <>
                    <RefreshIcon className='w-4 h-4 fill-current' />
                    Generate Preview
                  </>
                )}
              </button>
            </div>
          </section>

          <section className='w-1/2'>
            <div className='flex flex-col gap-2'>
              {props.previewMedia && (
                <>
                  <label className='font-bold'>
                    {props.previewLabel} Preview
                  </label>

                  <video
                    ref={previewMediaRef}
                    className='w-full'
                    controls={props.previewMedia.type === MediaType.Video}
                  />
                  {props.previewMedia.type === MediaType.Video && (
                    <div className='text-icon-gray text-sm text-center pb-3'>
                      Click play to preview with voice over.
                    </div>
                  )}
                </>
              )}
              <label className='font-bold'>Voice Over Preview</label>
              <audio ref={audioRef} className='w-full' controls />
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}

function SharedVoicePicker(props: {
  onChange: (value: ModelsTTSLabeledRenderSettings | null) => void;
}): JSX.Element {
  const styles = useMemo(() => buildReactSelectStyles<DtoSharedAsset>(), []);

  const loadOptions = useLiveCallback(async (q: string) => {
    return apiService.media
      .searchSharedAsset({
        q,
        purposes: EnumsSharedAssetPurpose.SharedAssetPurposeVoice,
      })
      .next();
  });

  const handleSingleChange = (option: SingleValue<DtoSharedAsset>) => {
    if (option && option.data?.ttsRenderSettings) {
      props.onChange({
        id: option.id,
        label: option.label,
        ...option.data.ttsRenderSettings,
      });
    } else {
      props.onChange(null);
    }
  };

  return (
    <AsyncSelect<DtoSharedAsset, false>
      placeholder='Select preview voice (optional)'
      styles={styles}
      cacheOptions
      defaultOptions
      loadOptions={loadOptions}
      classNamePrefix='select-box-v2'
      noOptionsMessage={(obj) => {
        if (!obj.inputValue) return 'Start typing to search';
        return 'No media matched';
      }}
      getOptionValue={(option) => option.id}
      getOptionLabel={(option) => option.label}
      onChange={handleSingleChange}
      isSearchable={false}
      isClearable
    />
  );
}
