import pluralize from 'pluralize';
import { useEffect, useLayoutEffect, useMemo, useState } from 'react';

import { type RoundRobinQuestionBlock } from '@lp-lib/game';

import { useLiveCallback } from '../../../../hooks/useLiveCallback';
import { BrowserIntervalCtrl } from '../../../../utils/BrowserIntervalCtrl';
import { loadImageAsPromise } from '../../../../utils/media';
import { FloatLayout } from '../../../Layout';
import {
  LayoutAnchor,
  useLayoutAnchorRectValue,
} from '../../../LayoutAnchors/LayoutAnchors';
import { useMyTeamId } from '../../../Player';
import { useSoundEffect } from '../../../SFX';
import { useGameSessionLocalTimer } from '../../hooks';
import { useMaxTotalScore, useRaceTeams } from './RoundRobinQuestionProvider';
import { type RaceTeam } from './types';

function FinishLine() {
  return (
    <div className='z-0 absolute right-0 border-l-2 h-full border-l-green-001 transform-gpu translate-x-full'>
      <div
        className='bg-lp-green-003 text-black text-sms font-bold 
        flex items-center justify-center w-17.5 h-5.5'
      >
        Finish
      </div>
    </div>
  );
}

function RaceTrack(props: {
  team: RaceTeam;
  totalScore: number;
  isGamePlaying: boolean;
}) {
  const { team, totalScore, isGamePlaying } = props;
  const actualProgress = (team.score / totalScore) * 100;
  const visialProgress = actualProgress >= 100 ? 110 : actualProgress;
  const myTeamId = useMyTeamId();

  const thumbnailUrl = team.unit?.thumbnailUrl ?? team.unit?.mediaUrl;

  useEffect(() => {
    if (!team.unit?.mediaUrl) return;
    loadImageAsPromise(team.unit?.mediaUrl);
  }, [team.unit?.mediaUrl]);

  return (
    <div className='relative h-22.5 flex-shrink-0'>
      <div
        className='flex items-center absolute h-full transform-gpu -translate-x-full w-max transition-position duration-500'
        style={{ left: `${visialProgress}%` }}
      >
        <div className='h-full flex flex-col items-end justify-around flex-shrink-0 absolute top-0 left-0 transform-gpu -translate-x-full'>
          <div className='w-10 h-10 flex items-center justify-center text-white text-2xl font-bold rounded-full bg-black border border-white'>
            {team.rank}
          </div>
          <div
            className={`rounded-2xl bg-black bg-opacity-80 h-7.5 
                flex items-center justify-center px-4 ${
                  myTeamId === team.teamId
                    ? 'text-tertiary font-bold text-xs'
                    : 'text-white text-3xs'
                }`}
            style={{
              border: '2px solid',
              borderColor: team.color,
            }}
          >
            {myTeamId === team.teamId ? 'Your Team' : team.teamName}
          </div>
        </div>
        <div className='h-full relative'>
          <img
            src={isGamePlaying ? team.unit?.mediaUrl : thumbnailUrl}
            alt=''
            className='h-full flex-shrink-0 object-contain z-[1]'
            style={{ aspectRatio: '16/9' }}
          />
          <div
            className='w-4/5 h-4.5 opacity-40 filter blur-sm absolute -bottom-2 left-1/2 transform-gpu -translate-x-1/2 rounded-[50%] z-0'
            style={{
              backgroundColor: team.color,
            }}
          ></div>
        </div>
      </div>
    </div>
  );
}

function RaceTracks(props: {
  block: RoundRobinQuestionBlock;
  isGamePlaying: boolean;
}) {
  const { block, isGamePlaying } = props;
  const raceTeams = useRaceTeams();
  const winningTotalScore = useMaxTotalScore(block);
  const containerHeight = useLayoutAnchorRectValue(
    'round-robin-race-container',
    'height'
  );
  const numOfTeams = raceTeams.length;
  const myTeamId = useMyTeamId();

  const sortedRaceTeams = useMemo(
    () =>
      raceTeams.sort((a, b) => {
        const aMyTeam = a.teamId === myTeamId;
        const bMyTeam = b.teamId === myTeamId;
        if (aMyTeam === bMyTeam) {
          return a.rank - b.rank;
        } else if (aMyTeam) {
          return -1;
        } else if (bMyTeam) {
          return 1;
        }
        return 0;
      }),
    [myTeamId, raceTeams]
  );

  const { visible, invisible } = useMemo(() => {
    if (containerHeight === null) {
      return { visible: 0, invisible: numOfTeams };
    }
    const rowHeight = 100;
    const visible = Math.max(1, Math.floor(containerHeight / rowHeight));
    return { visible, invisible: numOfTeams - visible };
  }, [containerHeight, numOfTeams]);

  if (containerHeight === null) return null;

  return (
    <>
      <div className='w-full h-full flex flex-col absolute left-0 top-0 gap-3 z-5 justify-evenly'>
        {sortedRaceTeams.slice(0, visible).map((team) => (
          <RaceTrack
            key={team.teamId}
            team={team}
            totalScore={winningTotalScore}
            isGamePlaying={isGamePlaying}
          />
        ))}
      </div>
      {invisible > 0 && (
        <div
          className='h-7.5 px-4 flex items-center justify-center 
        bg-black bg-opacity-80 rounded-2xl text-white text-3xs
        absolute bottom-1 left-1/2 transform-gpu -translate-x-1/2 opacity-80'
        >
          and {invisible} other {pluralize('team', invisible)}
        </div>
      )}
    </>
  );
}

export function RoundRobinRace(props: {
  block: RoundRobinQuestionBlock;
  isGamePlaying: boolean;
}) {
  const { block, isGamePlaying } = props;

  return (
    <div className='w-full h-full relative pointer-events-none'>
      <RaceTracks block={block} isGamePlaying={isGamePlaying} />
      <FinishLine />
      <LayoutAnchor
        id='round-robin-race-container'
        className='w-full h-full absolute z-0'
      />
    </div>
  );
}

export function RoundRobinRaceGoAnimation(props: {
  gameTimeSec: number;
  onFinished?: () => void;
}) {
  const { gameTimeSec } = props;
  const { play: playBeepSFX } = useSoundEffect('roundRobinRaceCountdown');
  const { play: playBeepEndSFX } = useSoundEffect('roundRobinRaceGo');
  const localTimeSec = useGameSessionLocalTimer();
  const [timer, setTimer] = useState(-1);
  const [inited, setInited] = useState(false);

  const onFinished = useLiveCallback(() => {
    // https://github.com/facebook/react/issues/18178#issuecomment-595846312
    // not sure how to avoid this issue, requestAnimationFrame seems helpful
    requestAnimationFrame(() => props.onFinished?.());
  });

  useLayoutEffect(() => {
    if (!localTimeSec || inited) return;
    // if game is already started, mark finished immediately
    if (gameTimeSec - localTimeSec > 3) {
      onFinished();
    } else {
      setTimer(3);
    }
    setInited(true);
  }, [localTimeSec, inited, onFinished, gameTimeSec]);

  useLayoutEffect(() => {
    if (timer < 0 || !inited) return;
    if (timer >= 1) playBeepSFX();
    if (timer === 0) playBeepEndSFX();
  }, [timer, playBeepEndSFX, playBeepSFX, inited]);

  useLayoutEffect(() => {
    if (!inited) return;
    const ctrl = new BrowserIntervalCtrl();
    ctrl.set(() => {
      setTimer((c) => {
        if (c === 0) {
          ctrl.clear();
          onFinished();
        }
        return c - 1;
      });
    }, 1000);

    return () => ctrl.clear();
  }, [inited, onFinished]);

  if (timer < 0 || !inited) return null;

  return (
    <FloatLayout className='z-35'>
      <div
        className={`w-auto flex flex-col justify-center items-center pointer-events-off mt-32`}
      >
        <div
          className='text-red-006 font-bold text-8xl'
          style={{
            WebkitTextStrokeColor: '#FFF',
            WebkitTextStrokeWidth: '6px',
          }}
        >
          {timer === 0 ? 'GO!' : `${timer}`}
        </div>
      </div>
    </FloatLayout>
  );
}
