import { addDays } from 'date-fns';
import { useMemo } from 'react';

import {
  type DtoAddProgramLinkRequest,
  type DtoChannel,
  type DtoProgram,
  type DtoRecognitionProgramLinkExtensions,
  EnumsPairingParticipationMode,
  EnumsProgramCadenceFrequency,
  EnumsProgramType,
  type ModelsProgramCadenceSettings,
  type ModelsProgramGroupSettings,
  type ModelsProgramTagSettings,
} from '@lp-lib/api-service-client/public';

import { apiService } from '../../services/api-service';
import { type SlackChannel } from '../../types/slack';
import { getStaticAssetPath } from '../../utils/assets';
import { type ProgramLinkSettings } from './types';
import { ProgramUtils } from './utils';

export type MakeAddProgramLinkRequestFunc = (props: {
  slackChannel: SlackChannel;
  channel: DtoChannel;
  program: DtoProgram;
}) => Promise<DtoAddProgramLinkRequest>;

interface ProgramKnife<T extends EnumsProgramType> {
  GetProgramType(): T;
  ExtraSlackScopes(): string[];
  DefaultChannelName(): string;
  MakeAddProgramLinkRequest: MakeAddProgramLinkRequestFunc;
}

class ProgramKnifeBase<T extends EnumsProgramType> {
  constructor(private programType: T) {}

  GetProgramType(): T {
    return this.programType;
  }

  ExtraSlackScopes(): string[] {
    return [];
  }

  DefaultChannelName(): string {
    return `lunapark`;
  }

  MakeDefaultLinkSettings(program: DtoProgram): ProgramLinkSettings {
    return {
      programType: program.type,
    };
  }
}

class GlobalPairingProgramKnife
  extends ProgramKnifeBase<EnumsProgramType.ProgramTypeGlobalPairing>
  implements ProgramKnife<EnumsProgramType.ProgramTypeGlobalPairing>
{
  constructor() {
    super(EnumsProgramType.ProgramTypeGlobalPairing);
  }

  async MakeAddProgramLinkRequest(props: {
    slackChannel: SlackChannel;
    channel: DtoChannel;
    program: DtoProgram;
  }): Promise<DtoAddProgramLinkRequest> {
    return {
      programId: props.program.id,
      programType: props.program.type,
      programTargetId: props.channel.id,
    };
  }
}

class GameDropsProgramKnife
  extends ProgramKnifeBase<EnumsProgramType.ProgramTypeGameDrops>
  implements ProgramKnife<EnumsProgramType.ProgramTypeGameDrops>
{
  constructor() {
    super(EnumsProgramType.ProgramTypeGameDrops);
  }

  async MakeAddProgramLinkRequest(): Promise<DtoAddProgramLinkRequest> {
    throw new Error('Not implemented');
  }
}

class CelebrationsProgramKnife
  extends ProgramKnifeBase<EnumsProgramType.ProgramTypeBirthdayAndCelebrations>
  implements ProgramKnife<EnumsProgramType.ProgramTypeBirthdayAndCelebrations>
{
  constructor() {
    super(EnumsProgramType.ProgramTypeBirthdayAndCelebrations);
  }

  DefaultChannelName(): string {
    return 'lunapark-celebrations';
  }

  async MakeAddProgramLinkRequest(props: {
    slackChannel: SlackChannel;
    channel: DtoChannel;
    program: DtoProgram;
  }): Promise<DtoAddProgramLinkRequest> {
    const { program, channel } = props;

    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const resp = await apiService.celebration.createCelebration({
      channelId: channel.id,
      timezone,
    });
    const programTargetId = resp.data.celebration.id;

    return {
      programId: program.id,
      programType: program.type,
      programTargetId,
    };
  }
}

class DummyProgramKnife
  extends ProgramKnifeBase<EnumsProgramType.ProgramTypeDummy>
  implements ProgramKnife<EnumsProgramType.ProgramTypeDummy>
{
  constructor() {
    super(EnumsProgramType.ProgramTypeDummy);
  }

  async MakeAddProgramLinkRequest(): Promise<DtoAddProgramLinkRequest> {
    throw new Error('Not implemented');
  }
}

class CalendarProgramKnife
  extends ProgramKnifeBase<EnumsProgramType.ProgramTypeCalendar>
  implements ProgramKnife<EnumsProgramType.ProgramTypeCalendar>
{
  constructor() {
    super(EnumsProgramType.ProgramTypeCalendar);
  }

  async MakeAddProgramLinkRequest(): Promise<DtoAddProgramLinkRequest> {
    throw new Error('Not implemented');
  }
}

class IntrosProgramKnife
  extends ProgramKnifeBase<EnumsProgramType.ProgramTypeIntros>
  implements ProgramKnife<EnumsProgramType.ProgramTypeIntros>
{
  constructor() {
    super(EnumsProgramType.ProgramTypeIntros);
  }

  ExtraSlackScopes(): string[] {
    return [
      'im:history',
      'mpim:history',
      'groups:history',
      'channels:history',
      'reactions:read',
    ];
  }

  DefaultChannelName(): string {
    return 'lunapark-intros';
  }

  DefaultSettings() {
    const frequency =
      EnumsProgramCadenceFrequency.ProgramCadenceFrequencyEveryTwoWeeks;
    const curr = addDays(new Date(), 14);
    curr.setHours(10);
    curr.setMinutes(0);
    curr.setSeconds(0);

    const cadenceSettings: ModelsProgramCadenceSettings = {
      frequency,
      nextTriggerTime: ProgramUtils.NextCadenceAfter(
        frequency,
        [],
        curr
      ).toISOString(),
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      weekdays: [],
    };
    const groupSettings: ModelsProgramGroupSettings = {
      participationMode:
        EnumsPairingParticipationMode.PairingParticipationModeNone,
      size: 3,
    };

    return {
      cadenceSettings,
      groupSettings,
    };
  }

  async MakeAddProgramLinkRequest(props: {
    slackChannel: SlackChannel;
    channel: DtoChannel;
    program: DtoProgram;
  }): Promise<DtoAddProgramLinkRequest> {
    const settings = this.DefaultSettings();

    return {
      programId: props.program.id,
      programType: EnumsProgramType.ProgramTypeIntros,
      programTargetId: props.program.id,
      ...settings,
    };
  }
}

class WaterCoolerProgramKnife
  extends ProgramKnifeBase<EnumsProgramType.ProgramTypeWaterCooler>
  implements ProgramKnife<EnumsProgramType.ProgramTypeWaterCooler>
{
  constructor() {
    super(EnumsProgramType.ProgramTypeWaterCooler);
  }

  ExtraSlackScopes(): string[] {
    return [
      'im:history',
      'mpim:history',
      'groups:history',
      'channels:history',
      'reactions:read',
    ];
  }

  DefaultChannelName() {
    return 'lunapark-watercoolers';
  }

  DefaultSettings(program: DtoProgram) {
    const frequency =
      EnumsProgramCadenceFrequency.ProgramCadenceFrequencyMultipleTimesPerWeek;
    const weekdays = [2, 4];
    const curr = addDays(new Date(), 7);
    curr.setHours(10);
    curr.setHours(10);
    curr.setMinutes(0);
    curr.setSeconds(0);

    const tagSettings: ModelsProgramTagSettings =
      ProgramUtils.ConvertTagSettingsDtoToModel(program.tagSettings);

    const cadenceSettings: ModelsProgramCadenceSettings = {
      frequency,
      nextTriggerTime: ProgramUtils.NextCadenceAfter(
        frequency,
        weekdays,
        curr
      ).toISOString(),
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      weekdays,
    };

    return {
      tagSettings,
      cadenceSettings,
    };
  }

  async MakeAddProgramLinkRequest(props: {
    slackChannel: SlackChannel;
    channel: DtoChannel;
    program: DtoProgram;
  }): Promise<DtoAddProgramLinkRequest> {
    const defaultSettings = this.DefaultSettings(props.program);

    return {
      programId: props.program.id,
      programType: props.program.type,
      programTargetId: props.program.id,
      ...defaultSettings,
    };
  }
}

class RecognitionProgramKnife
  extends ProgramKnifeBase<EnumsProgramType.ProgramTypeRecognition>
  implements ProgramKnife<EnumsProgramType.ProgramTypeRecognition>
{
  constructor() {
    super(EnumsProgramType.ProgramTypeRecognition);
  }

  ExtraSlackScopes(): string[] {
    return [
      'im:history',
      'mpim:history',
      'groups:history',
      'channels:history',
      'reactions:read',
    ];
  }

  DefaultChannelName(): string {
    return 'lunapark-shoutouts';
  }

  DefaultSettings() {
    return {
      adminDailyGivingAmount: 5,
      membersDailyGivingAmount: 5,
    };
  }

  async MakeAddProgramLinkRequest(props: {
    slackChannel: SlackChannel;
    channel: DtoChannel;
    program: DtoProgram;
  }): Promise<DtoAddProgramLinkRequest> {
    const extensions: DtoRecognitionProgramLinkExtensions = {
      ...this.DefaultSettings(),
      initExGroupId: props.slackChannel.id,
    };
    return {
      programId: props.program.id,
      programType: props.program.type,
      programTargetId: props.program.id,
      extensions,
    };
  }
}

class AiSurveyProgramKnife
  extends ProgramKnifeBase<EnumsProgramType.ProgramTypeAiSurvey>
  implements ProgramKnife<EnumsProgramType.ProgramTypeAiSurvey>
{
  constructor() {
    super(EnumsProgramType.ProgramTypeAiSurvey);
  }

  async MakeAddProgramLinkRequest(): Promise<DtoAddProgramLinkRequest> {
    throw new Error('Not implemented');
  }
}

class CustomTournamentProgramKnife
  extends ProgramKnifeBase<EnumsProgramType.ProgramTypeCustomTournament>
  implements ProgramKnife<EnumsProgramType.ProgramTypeCustomTournament>
{
  constructor() {
    super(EnumsProgramType.ProgramTypeCustomTournament);
  }

  async MakeAddProgramLinkRequest(): Promise<DtoAddProgramLinkRequest> {
    throw new Error('Not implemented');
  }
}

export const ProgramKnifeFactory = {
  [EnumsProgramType.ProgramTypeIntros]: new IntrosProgramKnife(),
  [EnumsProgramType.ProgramTypeWaterCooler]: new WaterCoolerProgramKnife(),
  [EnumsProgramType.ProgramTypeBirthdayAndCelebrations]:
    new CelebrationsProgramKnife(),
  [EnumsProgramType.ProgramTypeRecognition]: new RecognitionProgramKnife(),
  [EnumsProgramType.ProgramTypeGlobalPairing]: new GlobalPairingProgramKnife(),
  [EnumsProgramType.ProgramTypeAiSurvey]: new AiSurveyProgramKnife(),
  [EnumsProgramType.ProgramTypeCalendar]: new CalendarProgramKnife(),
  [EnumsProgramType.ProgramTypeGameDrops]: new GameDropsProgramKnife(),
  [EnumsProgramType.ProgramTypeDummy]: new DummyProgramKnife(),
  [EnumsProgramType.ProgramTypeCustomTournament]:
    new CustomTournamentProgramKnife(),
} satisfies {
  [key in EnumsProgramType]: ProgramKnife<key>;
};

export function getProgramIcon(programType: EnumsProgramType) {
  switch (programType) {
    case EnumsProgramType.ProgramTypeWaterCooler:
      return getStaticAssetPath('images/programs/icon-watercooler.png');
    case EnumsProgramType.ProgramTypeBirthdayAndCelebrations:
      return getStaticAssetPath('images/programs/icon-celebrations.png');
    case EnumsProgramType.ProgramTypeIntros:
      return getStaticAssetPath('images/programs/icon-intros.png');
    case EnumsProgramType.ProgramTypeRecognition:
      return getStaticAssetPath('images/programs/icon-recognition.png');
    default:
      return null;
  }
}

export function ProgramIcon(props: {
  programType: EnumsProgramType;
  className?: string;
}) {
  const iconUrl = useMemo(() => {
    return getProgramIcon(props.programType);
  }, [props.programType]);

  if (!iconUrl) return null;
  return <img src={iconUrl} alt='' className={props.className} />;
}
