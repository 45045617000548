export const RecordFailedIcon = (props: {
  className?: string;
}): JSX.Element => {
  return (
    <svg
      className={props.className ?? `w-3.5 h-3.5 fill-current`}
      viewBox='0 0 14 14'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M2.77232 11.7164H2.14988V13.4332H1V8.57014H2.87715C3.44281 8.57014 3.88507 8.69817 4.20393 8.95424C4.5228 9.21031 4.68223 9.57214 4.68223 10.0397C4.68223 10.3782 4.61452 10.6588 4.47912 10.8814C4.34589 11.1041 4.13623 11.2845 3.85012 11.4225L4.84603 13.3831V13.4332H3.61425L2.77232 11.7164ZM2.14988 10.8113H2.87715C3.09555 10.8113 3.25935 10.7534 3.36855 10.6376C3.47993 10.5196 3.53563 10.3559 3.53563 10.1466C3.53563 9.93732 3.47993 9.77366 3.36855 9.65564C3.25717 9.5354 3.09337 9.47528 2.87715 9.47528H2.14988V10.8113Z'
        fill='white'
      />
      <path
        d='M8.33497 11.3724H6.52662V12.5314H8.66257V13.4332H5.37674V8.57014H8.66912V9.47528H6.52662V10.5007H8.33497V11.3724Z'
        fill='white'
      />
      <path
        d='M12.9902 11.7866C12.9749 12.125 12.8853 12.4245 12.7215 12.685C12.5577 12.9433 12.3273 13.1437 12.0303 13.2862C11.7355 13.4287 11.398 13.5 11.018 13.5C10.3912 13.5 9.89762 13.2918 9.53726 12.8754C9.1769 12.459 8.99672 11.8712 8.99672 11.1119V10.8714C8.99672 10.3949 9.07753 9.97851 9.23915 9.62224C9.40295 9.26375 9.63773 8.98764 9.94349 8.79392C10.2492 8.59797 10.6031 8.5 11.0049 8.5C11.5837 8.5 12.0489 8.65587 12.4005 8.9676C12.7521 9.27711 12.952 9.70463 13 10.2502H11.8534C11.8447 9.95402 11.7715 9.74137 11.6339 9.61222C11.4963 9.48308 11.2867 9.4185 11.0049 9.4185C10.7188 9.4185 10.5091 9.52761 10.3759 9.74582C10.2427 9.96404 10.1728 10.3125 10.1663 10.7912V11.1353C10.1663 11.6541 10.2296 12.0248 10.3563 12.2475C10.4851 12.4702 10.7057 12.5815 11.018 12.5815C11.2823 12.5815 11.4843 12.518 11.6241 12.3911C11.7639 12.2642 11.8381 12.0627 11.8468 11.7866H12.9902Z'
        fill='white'
      />
      <rect
        width='8.48516'
        height='1.41419'
        rx='0.707096'
        transform='matrix(0.707103 0.707111 -0.707103 0.707111 4.50012 0)'
        fill='#EE3529'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M9.99987 0.500066C10.276 0.776205 10.276 1.22392 9.99987 1.50006L4.99997 6.50001C4.72383 6.77615 4.27613 6.77615 3.99999 6.50001C3.72386 6.22387 3.72386 5.77616 3.99999 5.50002L8.99989 0.500065C9.27603 0.223925 9.72373 0.223926 9.99987 0.500066Z'
        fill='#EE3529'
      />
    </svg>
  );
};
