import { match } from 'ts-pattern';

import { bps } from '../../../../../breakpoints';

const defaultWidth = bps(
  'w-16 xl:w-16 lp-sm:w-16 2xl:w-16 3xl:w-16 lp-md:w-16 lp-lg:w-16'
);
const defaultHeight = bps(
  'h-7 xl:h-7 lp-sm:h-8 2xl:h-8 3xl:h-8 lp-md:h-8 lp-lg:h-8'
);

// keep up to 3 digits for value < 1_000_000
function format(val: number) {
  if (val < 1000) return val;
  const num = val / 1000;
  const fixedNumber = match(val)
    .when(
      (val) => val < 10000,
      () => num.toFixed(2)
    )
    .when(
      (val) => val < 100000,
      () => num.toFixed(1)
    )
    .otherwise(() => num.toFixed(0));
  // remove trailing zeros
  return Number(fixedNumber).toString() + 'K';
}

export function PointsBanner(props: {
  visible: boolean;
  pointValue: number;
  width?: string;
  height?: string;
}): JSX.Element {
  const width = props.width ?? defaultWidth;
  const height = props.height ?? defaultHeight;
  return (
    <div className={`font-cairo ${width} ${height} overflow-hidden`}>
      <div
        className={`transform ${
          !props.visible
            ? 'animate-slide-out-to-left'
            : 'animate-slide-in-to-right'
        }
        w-full h-full
        bg-tertiary text-black
        flex flex-col justify-center items-center
      `}
      >
        <p
          className={`w-full text-center font-extrabold ${bps(
            'text-lg xl:text-lg lp-sm:text-xl 2xl:text-xl 3xl:text-xl lp-md:text-xl lp-lg:text-xl',
            'leading-4 xl:leading-4 lp-sm:leading-5 2xl:leading-5 3xl:leading-5 lp-md:leading-5 lp-lg:leading-5'
          )}`}
        >
          {format(props.pointValue)}
        </p>
        <p className='w-full text-center text-4xs'>Points</p>
      </div>
    </div>
  );
}
