import {
  accelerate,
  add,
  copy,
  createPointEdgeProjectionResult,
  distance,
  inertia,
  magnitude,
  normalize,
  overlapCircleCircle,
  projectCposWithRadius,
  projectPointEdge,
  scale,
  set,
  solveDistanceConstraint,
  solveDrag,
  sub,
  v2,
  type Vector2,
} from 'pocket-physics';

import { getFeatureQueryParam } from '../../hooks/useFeatureQueryParam';
import { Emitter } from '../../utils/emitter';
import type { DPRCanvas } from '../PixelFx/DPICanvas';
import { type InterpolationFactor, type Ms } from '../PixelFx/GameLoop';
import {
  type AssetMap,
  FrameAnimatedSprite,
  PointsSpriteSheetInfo,
  type SpriteSheetMeta,
} from './AssetMap';
import {
  type AssuredEntityId,
  borrowAssuredEntityId,
  CES3,
  downgradeAssuredEntityId,
} from './ces3';

class MovementCmp {
  k = 'v-movement' as const;
  constructor(
    pos: Vector2,
    public cpos = copy(v2(), pos),
    public ppos = copy(v2(), pos),
    public acel = v2()
  ) {}
}

class GravityHasCmp {
  k = 'gravity-has' as const;
  constructor(public mass: number) {}
}

class GravityAffectedCmp {
  k = 'gravity-affected' as const;
  constructor(public mass: number) {}
}

class AgeCmp {
  k = 'age-dt' as const;
  constructor(public ageDt = 0, public deathAtAgeDt = Infinity) {}
}

class DynamicCollidableCircleCmp {
  k = 'collidable-circle-dynamic' as const;
  constructor(public radius: number) {}
}

class StaticCollidableCircleCmp {
  k = 'collidable-circle-static' as const;
  constructor(public radius: number) {}
}

class ConstraintCmp {
  k = 'spring-constraint' as const;
  constructor(
    public v1: AssuredEntityId<MovementCmp>,
    public v1Mass: number,
    public v2: AssuredEntityId<MovementCmp>,
    public v2Mass: number,
    public goal: number,
    public stiffness: number, // 0-1
    public impartsEnergy: boolean,
    public iterations = 1
  ) {}
}

class OrbCmp {
  k = 'orb' as const;
  constructor(
    public points: number,
    public initialColumn: number,
    public initialRow: number,
    public lifted = false,
    public pullConstraint: null | AssuredEntityId<ConstraintCmp> = null
  ) {}
}

class AnimatedSpriteCmp<S extends string = string, F extends number = number> {
  k = 'frame-animated-sprite' as const;
  dt = 0;

  constructor(
    meta: SpriteSheetMeta<S, F>,
    assetName: S,
    public frameRate: number,
    public loop = true,
    public fas = new FrameAnimatedSprite(meta, assetName, loop)
  ) {}

  get oneAnimDurationMs() {
    return (1000 / this.frameRate) * this.fas.numberOfFrames;
  }

  draw(
    interp: number,
    dprCanvas: DPRCanvas,
    spriteSheet: HTMLImageElement,
    mvmt: MovementCmp,
    sprite = this
  ): void {
    const info = sprite.fas.getFrame();

    if (!info) return;

    const centerX = mvmt.ppos.x + (mvmt.cpos.x - mvmt.ppos.x) * interp;
    const centerY = mvmt.ppos.y + (mvmt.cpos.y - mvmt.ppos.y) * interp;
    const halfW = info.frame.w / 2;
    const halfH = info.frame.h / 2;

    dprCanvas.ctx.drawImage(
      spriteSheet,
      info.frame.x,
      info.frame.y,
      info.frame.w,
      info.frame.h,
      centerX - halfW,
      centerY - halfH,
      info.frame.w,
      info.frame.h
    );
  }

  increment(dt: number, sprite = this) {
    sprite.dt += dt;
    const frameTime = 1000 / sprite.frameRate;
    if (sprite.dt > frameTime) {
      sprite.fas.advance();
      sprite.dt = sprite.dt - frameTime;
    }
  }
}

class GainPointsTagCmp {
  k = 'tag-gain-points' as const;
}

type Components =
  | MovementCmp
  | AgeCmp
  | OrbCmp
  | AnimatedSpriteCmp
  | GainPointsTagCmp
  | GravityAffectedCmp
  | GravityHasCmp
  | ConstraintCmp
  | StaticCollidableCircleCmp
  | DynamicCollidableCircleCmp;

type GainPointsAnimCES = CES3<Components>;

export class GainPointsAnimation {
  static MAX_ORBS_PER_ROW = 20; // eg. 100 points per row
  static MAX_ORBS_MEGA_PER_ROW = 10;

  static DEADLINE_MS = 10000;
  static ORB_POINT_VALUE = 5;
  static ORB_POINT_VALUE_MEGA = 1000;

  static GRAVITY_MIN_DIST_PX = 1;
  static CAPTURE_DIST_PX = 10;

  static GRAVITY_ACCEL_PX = 4;

  // This is applied to the incoming targetX/Y as an offset to make the
  // animation look better for collisions.
  static TARGET_POINT_FUDGE_Y = -25;
  static SECONDARY_GRAVITY_FUDGE_Y = 100; //100;

  ces: GainPointsAnimCES = new CES3();

  signals = new Emitter<{
    ready: () => void;
    'request-shutdown': () => void;
    'orb-hit': (points: number) => void;
    'all-mostly-gained': () => void;
  }>();

  readonly ready: Promise<void>;

  // An alternative to a class is to keep all of these entity ids within another
  // component, but since this animation is so specific it's fine to just use
  // class members.
  targetPoint: AssuredEntityId<
    MovementCmp | GravityHasCmp | StaticCollidableCircleCmp
  >;
  secondGravityWell: AssuredEntityId<MovementCmp | GravityHasCmp>;
  glowingPoint: AssuredEntityId<
    MovementCmp | AnimatedSpriteCmp | AgeCmp
  > | null = null;

  atLeastOneOrbDestroyed = false;

  constructor(
    private assetMap: AssetMap,
    pointsGained: number,
    spawnCenterX: number,
    spawnCenterY: number,
    spawnH: number,
    targetX: number,
    targetY: number,
    private debugDrawEnabled = getFeatureQueryParam(
      'gain-points-anim-debug-draw'
    )
  ) {
    // Should already be loaded but just in case
    this.ready = assetMap.preload().then(() => this.signals.emit('ready'));

    let megaOrbs = 0;
    let orbs = 0;

    const NEEDS_MEGA_ORBS =
      pointsGained > GainPointsAnimation.ORB_POINT_VALUE_MEGA;

    if (NEEDS_MEGA_ORBS) {
      megaOrbs = Math.floor(
        pointsGained / GainPointsAnimation.ORB_POINT_VALUE_MEGA
      );
      orbs = Math.floor(
        (pointsGained % GainPointsAnimation.ORB_POINT_VALUE_MEGA) /
          GainPointsAnimation.ORB_POINT_VALUE
      );
    } else {
      megaOrbs = 0;
      orbs = Math.floor(pointsGained / GainPointsAnimation.ORB_POINT_VALUE);
    }

    const lastExpectedFullRowNum =
      Math.floor(orbs / GainPointsAnimation.MAX_ORBS_PER_ROW) - 1;

    const PER_ORB_GAP_PX = 5;

    const lastRowRemainder = orbs % GainPointsAnimation.MAX_ORBS_PER_ROW;
    const emptyGapCount =
      GainPointsAnimation.MAX_ORBS_PER_ROW - lastRowRemainder;
    const columnCenterAlignedCount = Math.floor(emptyGapCount / 2);

    let firstDrawnY = 0;

    for (let i = 0; i < orbs; i++) {
      const spriteCmp = new AnimatedSpriteCmp(
        PointsSpriteSheetInfo,
        'points-orb',
        20
      );

      const frameInfo = spriteCmp.fas.getFrame();
      if (!frameInfo) continue;

      // The actual sprite is like 60px due to glow, but feels more like 30px
      const spriteSize = frameInfo.spriteSourceSize;
      const tightSize = { w: spriteSize.w / 2, h: spriteSize.h / 2 };
      const pixels = i * (tightSize.w + PER_ORB_GAP_PX);
      const maxWidth =
        GainPointsAnimation.MAX_ORBS_PER_ROW * (tightSize.w + PER_ORB_GAP_PX);
      const row = Math.floor(pixels / maxWidth);
      const emptyLeftSpace =
        columnCenterAlignedCount * (tightSize.w + PER_ORB_GAP_PX);
      const x =
        (row > lastExpectedFullRowNum ? emptyLeftSpace : 0) +
        pixels -
        row * maxWidth;
      const y = row * (tightSize.h + PER_ORB_GAP_PX);
      firstDrawnY = row === 0 ? y : firstDrawnY;

      const initialColumn =
        (row > lastExpectedFullRowNum ? columnCenterAlignedCount : 0) +
        (i % GainPointsAnimation.MAX_ORBS_PER_ROW);

      const startPoint = v2(
        spawnCenterX - maxWidth / 2,
        spawnCenterY - spawnH / 2
      );

      this.ces.entity([
        new MovementCmp(add(v2(), v2(x, y), startPoint)),
        new AgeCmp(),
        spriteCmp,
        new OrbCmp(GainPointsAnimation.ORB_POINT_VALUE, initialColumn, row),
        new DynamicCollidableCircleCmp(tightSize.w / 2),
      ]);
    }

    const expectedMegaRows = Math.ceil(
      megaOrbs / GainPointsAnimation.MAX_ORBS_MEGA_PER_ROW
    );

    for (let i = 0; i < megaOrbs; i++) {
      const spriteCmp = new AnimatedSpriteCmp(
        PointsSpriteSheetInfo,
        'points-orb',
        20
      );

      const frameInfo = spriteCmp.fas.getFrame();
      if (!frameInfo) continue;

      // Allow it to be natural size for now
      const spriteSize = frameInfo.spriteSourceSize;
      const tightSize = { w: spriteSize.w, h: spriteSize.h };
      const pixels = i * (tightSize.w + PER_ORB_GAP_PX);
      const maxWidth =
        GainPointsAnimation.MAX_ORBS_MEGA_PER_ROW *
        (tightSize.w + PER_ORB_GAP_PX);
      const row = Math.floor(pixels / maxWidth);

      const x = pixels - row * maxWidth;
      const y =
        row * (tightSize.h + PER_ORB_GAP_PX) -
        firstDrawnY -
        expectedMegaRows * tightSize.h -
        tightSize.h;

      const initialColumn = i % GainPointsAnimation.MAX_ORBS_MEGA_PER_ROW;

      const startPoint = v2(
        spawnCenterX - maxWidth / 2,
        spawnCenterY - spawnH / 2
      );

      const pos = add(v2(), v2(x, y), startPoint);

      this.ces.entity([
        new MovementCmp(pos),
        new AgeCmp(),
        spriteCmp,
        new OrbCmp(
          GainPointsAnimation.ORB_POINT_VALUE_MEGA,
          initialColumn,
          row
        ),
        new DynamicCollidableCircleCmp(tightSize.w),
      ]);

      const EXTRA_ORBS = 10;

      for (let j = 0; j < EXTRA_ORBS; j++) {
        // add some extra orbs to make it look more dense, but keep their scores at zero

        const circleOffsetX = Math.cos((j / EXTRA_ORBS) * Math.PI * 2);
        const circleOffsetY = Math.sin((j / EXTRA_ORBS) * Math.PI * 2);

        const radius = tightSize.w / 4;

        const jittered = v2(
          pos.x + circleOffsetX * radius,
          pos.y + circleOffsetY * radius
        );

        this.ces.entity([
          new MovementCmp(jittered),
          new AgeCmp(),
          spriteCmp,
          new OrbCmp(0, initialColumn, row),
          new DynamicCollidableCircleCmp(tightSize.w),
        ]);
      }
    }

    // deadline entity to cut off the animation in case of failure
    this.ces.entity([new AgeCmp(0), new GainPointsTagCmp()]);

    // target point handles the collisions
    this.targetPoint = this.ces.entity([
      new MovementCmp(v2(targetX, targetY)),
      new GravityHasCmp(100),
      new StaticCollidableCircleCmp(10),
    ]);

    // Add a second gravity well to help funnel orbs into the primary target
    this.secondGravityWell = this.ces.entity([
      new MovementCmp(v2(targetX, targetY)),
      new GravityHasCmp(2),
    ]);

    // Immediately set the proper target values to calculate offset/fudge
    this.updateTarget(targetX, targetY);
  }

  shutdown(): void {
    const ids = this.ces.allEntities();
    for (const id of ids) {
      if (id) this.ces.destroy(id);
    }

    this.ces.flushDestruction();
    this.signals.clear();
  }

  update(dt: number): void {
    this.ageSystem(dt);
    this.liftoffSystem();
    this.deadlineSystem();

    this.overlapSystem((eid) => {
      const points = this.ces.data(eid, 'orb')?.points || 0;
      this.signals.emit('orb-hit', points);
      this.ces.destroy(eid);
      this.atLeastOneOrbDestroyed = true;
    });

    this.captureSystem();

    this.mvmtConstantGravitationSystem();

    this.mvmtAccelerateSystem(dt);
    this.constraintSystem();
    this.mvmtInertiaSystem();

    // Always destroy any pending entities
    this.ces.flushDestruction();

    this.allPointsGainedSystem();
  }

  draw(interp: InterpolationFactor, dprCanvas: DPRCanvas, dt: Ms): void {
    const entities = this.ces.select(['v-movement', 'frame-animated-sprite']);
    const sheet = this.assetMap.getImage('points-sprite-sheet.png');
    for (let i = 0; i < entities.length; i++) {
      const eid = entities[i];
      const mvmt = this.ces.data(eid, 'v-movement');
      const sprite = this.ces.data(eid, 'frame-animated-sprite');
      if (!mvmt || !sprite) continue;
      sprite.draw(interp, dprCanvas, sheet, mvmt);
      sprite.increment(dt);
    }

    if (this.debugDrawEnabled) debugDrawSystem(this.ces, dprCanvas, interp);
  }

  updateTarget(x: number, y: number): void {
    const mvmt1 = this.ces.data(this.targetPoint, 'v-movement');
    const mvmt2 = this.ces.data(this.secondGravityWell, 'v-movement');
    if (!mvmt1 || !mvmt2) return;

    // Target point is a little higher than the visual target point to ensure
    // the orbs hit from the top. Gravity well is a little lower to really suck
    // them in a funnel.

    set(mvmt1.ppos, x, y + GainPointsAnimation.TARGET_POINT_FUDGE_Y);
    set(mvmt1.cpos, x, y + GainPointsAnimation.TARGET_POINT_FUDGE_Y);

    set(mvmt2.ppos, x, y + GainPointsAnimation.SECONDARY_GRAVITY_FUDGE_Y);
    set(mvmt2.cpos, x, y + GainPointsAnimation.SECONDARY_GRAVITY_FUDGE_Y);
  }

  ageSystem(dt: Ms): void {
    const entities = this.ces.select(['age-dt']);
    for (let i = 0; i < entities.length; i++) {
      const eid = entities[i];
      const cmp = this.ces.data(eid, 'age-dt');
      if (!cmp) continue;
      cmp.ageDt += dt;
      if (cmp.ageDt > cmp.deathAtAgeDt) this.ces.destroy(eid);
    }
  }

  liftoffSystem(): void {
    // The initial delay before _any_ orb lifts off
    const LIFTOFF_AGE_MS = 1000;
    // The delay for the next orb to lift off
    const LIFTOFF_STAGGER_MS = 50;
    // The delay the orb in a lower row but same column
    const ROW_STAGGER_MS = 250;

    // How hard to push the orbs on liftoff
    const HORIZONTAL_LIFTOFF_ACCEL = 5;
    const VERTICAL_LIFTOFF_ACCEL = 20;

    const entities = this.ces.select(['orb', 'v-movement', 'age-dt']);
    const halfCol = Math.floor(GainPointsAnimation.MAX_ORBS_PER_ROW / 2);

    for (let i = 0; i < entities.length; i++) {
      const eid = entities[i];
      const age = this.ces.data(eid, 'age-dt');
      const mvmt = this.ces.data(eid, 'v-movement');
      const orb = this.ces.data(eid, 'orb');

      // only once, give it a nudge upwards and add gravity

      if (
        orb &&
        !orb.lifted &&
        mvmt &&
        age &&
        age.ageDt >
          LIFTOFF_AGE_MS +
            LIFTOFF_STAGGER_MS * orb.initialColumn +
            orb.initialRow * ROW_STAGGER_MS
      ) {
        const col = orb.initialColumn;

        add(
          mvmt.acel,
          mvmt.acel,
          v2(
            col < halfCol
              ? HORIZONTAL_LIFTOFF_ACCEL * -1
              : HORIZONTAL_LIFTOFF_ACCEL,
            VERTICAL_LIFTOFF_ACCEL
          )
        );
        orb.lifted = true;
        this.ces.add(eid, new GravityAffectedCmp(1));
      }
    }
  }

  captureSystem(captureDistPx = GainPointsAnimation.CAPTURE_DIST_PX): void {
    const entities = this.ces.select(['orb', 'v-movement']);
    const mvmt2 = this.ces.data(this.targetPoint, 'v-movement');

    // Preallocate once and reuse for all tests this tick
    const projection = createPointEdgeProjectionResult();

    const attach = (
      eid: AssuredEntityId<MovementCmp | OrbCmp>,
      orb: OrbCmp,
      mvmt1: MovementCmp
    ) => {
      if (orb.pullConstraint) return;

      orb.pullConstraint = this.ces.entity([
        new ConstraintCmp(
          // Borrow this id so it can be referenced, but not deleted when this
          // constraint is deleted
          borrowAssuredEntityId(
            downgradeAssuredEntityId(this.targetPoint, 'v-movement')
          ),
          0, // pin the edge point using mass=0
          downgradeAssuredEntityId(eid, 'v-movement'),
          1,
          5,
          0.5,
          true,
          1
        ),
      ]);

      // Zero out velocity to prevent the orb from continuing beyond the
      // collision
      copy(mvmt1.ppos, mvmt1.cpos);

      // prevent gravity from further affecting the orb since the gravity
      // point has no radius, rely on the constraint
      this.ces.remove(eid, 'gravity-affected');
    };

    for (let i = 0; i < entities.length; i++) {
      const eid = entities[i];
      const mvmt1 = this.ces.data(eid, 'v-movement');
      const orb = this.ces.data(eid, 'orb');

      if (!mvmt1 || !mvmt2 || !orb) continue;
      const velocityV = sub(v2(), mvmt1.cpos, mvmt1.ppos);
      const projected = add(v2(), mvmt1.cpos, velocityV);
      const dist = distance(projected, mvmt2.cpos);

      const velocity = magnitude(velocityV);

      // Zero velocity is nearly impossible, but produces a zero-length edge
      if (velocity === 0) {
        if (dist <= captureDistPx) {
          attach(eid, orb, mvmt1);
        }
        continue;
      } else {
        // capsule / tunnel collide them both
        projectPointEdge(mvmt2.cpos, mvmt1.ppos, projected, projection);

        // NOTE: if the badge changes position, this will need to be inverted!
        if (projected.y <= mvmt2.cpos.y) {
          // orb has crossed the plane of badge.
          attach(eid, orb, mvmt1);
        }

        if (
          projection.distance <= captureDistPx &&
          projection.u >= 0 &&
          projection.u <= 1
        ) {
          // collision!
          attach(eid, orb, mvmt1);
        }
      }
    }
  }

  deadlineSystem(): void {
    const deadlineId = this.ces.selectFirst(['age-dt', 'tag-gain-points']);
    const deadline = this.ces.data(deadlineId, 'age-dt');
    if (deadline && deadline.ageDt >= GainPointsAnimation.DEADLINE_MS) {
      this.signals.emit('request-shutdown');
    }
  }

  mvmtAccelerateSystem(dt: Ms): void {
    const entities = this.ces.select(['v-movement']);
    for (let i = 0; i < entities.length; i++) {
      const eid = entities[i];
      const mvmt = this.ces.data(eid, 'v-movement');

      if (mvmt) {
        accelerate(mvmt, dt);
      }
    }
  }

  mvmtInertiaSystem(): void {
    const entities = this.ces.select(['v-movement']);
    for (let i = 0; i < entities.length; i++) {
      const eid = entities[i];
      const mvmt = this.ces.data(eid, 'v-movement');

      if (mvmt) {
        inertia(mvmt);
        solveDrag(mvmt, 0.95);
      }
    }
  }

  mvmtDragSystem(): void {
    const entities = this.ces.select(['v-movement']);
    for (let i = 0; i < entities.length; i++) {
      const eid = entities[i];
      const mvmt = this.ces.data(eid, 'v-movement');

      if (mvmt) {
        solveDrag(mvmt, 0.95);
      }
    }
  }

  mvmtConstantGravitationSystem(
    MIN_DIST_PX = GainPointsAnimation.GRAVITY_MIN_DIST_PX,
    GRAV_ACCEL = GainPointsAnimation.GRAVITY_ACCEL_PX
  ): void {
    const hasGravities = this.ces.select(['gravity-has', 'v-movement']);
    const affectedByGravities = this.ces.select([
      'gravity-affected',
      'v-movement',
    ]);
    for (let i = 0; i < hasGravities.length; i++) {
      const eid = hasGravities[i];
      const gravityHas = this.ces.data(eid, 'gravity-has');
      const mvmt1 = this.ces.data(eid, 'v-movement');

      if (!mvmt1 || !gravityHas) continue;

      for (let j = 0; j < affectedByGravities.length; j++) {
        const eid = affectedByGravities[j];
        const gravityAffected = this.ces.data(eid, 'gravity-affected');
        const mvmt2 = this.ces.data(eid, 'v-movement');

        if (!mvmt2 || !gravityAffected) continue;

        const dist = distance(mvmt2.cpos, mvmt1.cpos);

        // Applying gravity always will fling bodies outward once they hit the
        // singularity due to zeros and near-infinite gravity.
        if (dist > MIN_DIST_PX) {
          const delta = sub(v2(), mvmt1.cpos, mvmt2.cpos);
          const dir = normalize(v2(), delta);
          // Use an approximation for mass. We don't want to use actual gravity
          // calculation since it includes distance, and we need something
          // distance independent.
          const factor = 1 - gravityAffected.mass / gravityHas.mass;
          const gravity = scale(dir, dir, GRAV_ACCEL * factor);
          add(mvmt2.acel, mvmt2.acel, gravity);
        }
      }
    }
  }

  overlapSystem(
    onCollision: (
      eid: AssuredEntityId<MovementCmp | DynamicCollidableCircleCmp | OrbCmp>
    ) => void
  ): void {
    const groupA = this.ces.select(['v-movement', 'collidable-circle-dynamic']);
    const groupB = this.ces.select(['v-movement', 'collidable-circle-static']);

    // Preallocate once
    const projection = createPointEdgeProjectionResult();

    for (let i = 0; i < groupA.length; i++) {
      const dynamicEid = groupA[i];
      const circle1 = this.ces.data(dynamicEid, 'collidable-circle-dynamic');
      const mvmt1 = this.ces.data(dynamicEid, 'v-movement');

      if (!mvmt1 || !circle1) continue;

      const velocity = magnitude(sub(v2(), mvmt1.cpos, mvmt1.ppos));
      const projectedCpos = projectCposWithRadius(v2(), mvmt1, circle1.radius);

      for (let j = 0; j < groupB.length; j++) {
        const staticEid = groupB[j];
        const circle2 = this.ces.data(staticEid, 'collidable-circle-static');
        const mvmt2 = this.ces.data(staticEid, 'v-movement');

        if (!mvmt2 || !circle2) continue;

        // They might already be overlapping or embedded within each other
        // (which would fail an edge collision test), try a simple circle-circle
        // overlap.
        if (
          overlapCircleCircle(
            mvmt1.cpos.x,
            mvmt1.cpos.y,
            circle1.radius,
            mvmt2.cpos.x,
            mvmt2.cpos.y,
            circle2.radius
          )
        ) {
          onCollision(dynamicEid);
          continue;
        }

        // If zero velocity, there is no edge to collide against
        if (velocity > 0) {
          // capsule / tunnel collide them both
          projectPointEdge(mvmt2.cpos, mvmt1.ppos, projectedCpos, projection);

          if (
            // Velocity vector is embedded within circle2
            // projection.distance < circle2.radius ||
            // typical edge vs circle detection
            projection.distance < circle2.radius &&
            projection.u >= 0 &&
            projection.u <= 1
          ) {
            // collision!
            onCollision(dynamicEid);
          }
        }
      }
    }
  }

  constraintSystem(): void {
    const constraints = this.ces.select(['spring-constraint']);
    constraints.forEach((id) => {
      if (this.ces.isDestroyed(id)) return;
      const data = this.ces.data(id, 'spring-constraint');
      if (!data) return;
      const v1 = this.ces.data(data.v1, 'v-movement');
      const v2 = this.ces.data(data.v2, 'v-movement');
      if (!v1 || !v2) return;
      for (let i = 0; i < data.iterations; i++) {
        solveDistanceConstraint(
          v1,
          data.v1Mass,
          v2,
          data.v2Mass,
          data.goal,
          data.stiffness,
          data.impartsEnergy
        );
      }
    });
  }

  allPointsGainedSystem(): void {
    const orbs = this.ces.select(['orb']);
    const mvmt = this.ces.data(this.targetPoint, 'v-movement');

    const TRIGGER_GLOW_SPARKLE_WHEN_ORBS_REMAINING = 8;

    if (
      mvmt &&
      this.atLeastOneOrbDestroyed &&
      orbs.length < TRIGGER_GLOW_SPARKLE_WHEN_ORBS_REMAINING &&
      this.glowingPoint === null
    ) {
      const sprite = new AnimatedSpriteCmp(
        PointsSpriteSheetInfo,
        'points-glowing-effect',
        20,
        false
      );

      this.glowingPoint = this.ces.entity([
        new MovementCmp(
          v2(
            mvmt.cpos.x,
            mvmt.cpos.y + GainPointsAnimation.TARGET_POINT_FUDGE_Y * -1
          )
        ),
        sprite,
        new AgeCmp(0, sprite.oneAnimDurationMs),
      ]);
    }

    const TRIGGER_MOSTLY_GAINED_WHEN_ORBS_REMAINING = 0;

    if (orbs.length === TRIGGER_MOSTLY_GAINED_WHEN_ORBS_REMAINING) {
      this.signals.emit('all-mostly-gained');

      if (this.glowingPoint) {
        const glowIsStillPresent = this.ces.data(this.glowingPoint, 'age-dt');

        // `glowingPoint` has a death age due to `age-dt` component. It is
        // automatically destroyed when it reaches this age. If it is no longer
        // a valid entity id (but `glowingPoint` is non-null), that means it has
        // been spaawned, executed, and destroyed. Time to exit!

        if (!glowIsStillPresent) {
          this.signals.emit('request-shutdown');
        }
      }
    }
  }
}

const debugDrawSystem = (
  ces: GainPointsAnimCES,
  dprCanvas: DPRCanvas,
  interp: InterpolationFactor
) => {
  const points = ces.select(['v-movement']);
  const constraints = ces.select(['spring-constraint']);

  points.forEach((id) => {
    const data = ces.data(id, 'v-movement');
    if (!data) return;
    const { ctx } = dprCanvas;
    ctx.beginPath();
    ctx.fillStyle = 'blue';
    ctx.arc(
      data.ppos.x + (data.cpos.x - data.ppos.x) * interp,
      data.ppos.y + (data.cpos.y - data.ppos.y) * interp,
      1,
      0,
      Math.PI * 2
    );
    ctx.fill();
  });

  constraints.forEach((id) => {
    const data = ces.data(id, 'spring-constraint');
    if (!data) return;
    const v1 = ces.data(data.v1, 'v-movement');
    const v2 = ces.data(data.v2, 'v-movement');
    if (!v1 || !v2) return;
    const { ctx } = dprCanvas;
    ctx.beginPath();
    ctx.strokeStyle = 'black';
    ctx.lineWidth = 0.5;
    ctx.moveTo(
      v1.ppos.x + (v1.cpos.x - v1.ppos.x) * interp,
      v1.ppos.y + (v1.cpos.y - v1.ppos.y) * interp
    );
    ctx.lineTo(
      v2.ppos.x + (v2.cpos.x - v2.ppos.x) * interp,
      v2.ppos.y + (v2.cpos.y - v2.ppos.y) * interp
    );
    ctx.stroke();
  });
};
