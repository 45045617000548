import {
  type GameStateForVoiceOver,
  type VoiceOver,
  type VoiceOverMedia,
  type VoiceOverRenderDescription,
} from '@lp-lib/game';
import { type Media, type MediaData, VolumeLevel } from '@lp-lib/media';

import { UncontrolledRangeInput } from '../Form/UncontrolledRangeInput';
import { DeleteIcon } from '../icons/DeleteIcon';
import { MiniMediaEditor } from '../MediaUploader/MiniMediaEditor';
import { VoiceOverGeneratorButton } from './VoiceOverGenerator';

function VoiceOverMediaEditor(props: {
  id: string;
  voiceOverMedia?: VoiceOverMedia | null;
  onChange: (voiceOverMedia: VoiceOverMedia | null) => void;
  btnLabel?: string;
  previewMedia?: Media | null;
  previewLabel?: string;
  title?: string;
  submitBtnLabel?: string;
  noUpload?: boolean;
  withScriptVariables?: boolean;
  withVolumeLevel?: boolean;
  scriptSubtitle?: React.ReactNode;
  onGenerateExampleRuntimeState?: (
    state: GameStateForVoiceOver
  ) => GameStateForVoiceOver;
  btnClassName?: string;
  disabled?: boolean;
  volumeStyle?: 'col' | 'row';
  uiStyle?: 'button-first' | 'media-first';
}): JSX.Element {
  const handleChange = (mediaData: MediaData | null, media: Media | null) => {
    if (media === null) {
      props.onChange(null);
    } else {
      props.onChange({ ...props.voiceOverMedia, media, mediaData });
    }
  };

  const handleGeneratorUpload = (
    media: Media | null,
    renderDescription: VoiceOverRenderDescription
  ) => {
    props.onChange({
      media,
      mediaData:
        media === null
          ? null
          : {
              id: media.id,
              volumeLevel: VolumeLevel.Full,
            },
      renderDescription,
    });
  };

  const uiStyle = props.uiStyle ?? 'button-first';

  const button = (
    <VoiceOverGeneratorButton
      {...props}
      onSubmit={handleGeneratorUpload}
      initialVoiceOverMedia={props.voiceOverMedia?.media}
      initialData={props.voiceOverMedia?.renderDescription}
      label={props.btnLabel}
      className={props.btnClassName}
    />
  );

  const mediaEditor = (
    <MiniMediaEditor
      id={props.id}
      volumeStyle={props.volumeStyle ?? 'row'}
      audio
      volumeSelectable
      mediaData={props.voiceOverMedia?.mediaData}
      media={props.voiceOverMedia?.media}
      onChange={handleChange}
      disabled={props.disabled}
    />
  );

  if (uiStyle === 'button-first') {
    return (
      <div className='flex gap-2'>
        {button}
        {mediaEditor}
      </div>
    );
  }

  return (
    <div className='flex gap-2'>
      {mediaEditor}
      {button}
    </div>
  );
}

export function VoiceOverEditor(props: {
  id: string;
  voiceOver?: VoiceOver | null;
  onChange: (voiceOver: VoiceOver | null) => void;
  titleAccessory?: React.ReactNode;
  bottomAccessory?: React.ReactNode;
  previewMedia?: Media | null;
  previewLabel?: string;
  runtimeScriptSubtitle?: React.ReactNode;
  fallbackScriptSubtitle?: React.ReactNode;
  onGenerateExampleRuntimeState?: (
    state: GameStateForVoiceOver
  ) => GameStateForVoiceOver;
  overrideVariables?: readonly string[];
}): JSX.Element {
  return (
    <div className='space-y-4'>
      {props.titleAccessory ? props.titleAccessory : <></>}

      <div className=''>
        <div className='flex flex-col gap-2'>
          <label className='font-bold text-2xs text-icon-gray'>
            Runtime Voice ⚡️ (OnD Only)
          </label>

          <div className='flex items-center gap-4'>
            <VoiceOverGeneratorButton
              onSubmit={(_, runtime) => props.onChange({ runtime })}
              initialData={props.voiceOver?.runtime}
              previewMedia={props.previewMedia}
              previewLabel={props.previewLabel}
              submitBtnLabel='Save'
              label={
                props.voiceOver?.runtime
                  ? 'Edit Voice Over Config'
                  : 'Add Voice Over Config'
              }
              title='Runtime Voice Over Config ⚡️'
              runtime
              scriptSubtitle={props.runtimeScriptSubtitle}
              onGenerateExampleRuntimeState={
                props.onGenerateExampleRuntimeState
              }
              overrideVariables={props.overrideVariables}
            />

            {props.voiceOver?.runtime && (
              <button
                type='button'
                onClick={() => props.onChange({ runtime: null })}
                className='w-7.5 h-7.5 rounded-lg border border-secondary btn flex justify-center items-center text-red-002 bg-black'
              >
                <DeleteIcon />
              </button>
            )}

            <label htmlFor={`${props.id}-runtime-delay-start-ms`}>
              <span className='text-sms text-icon-gray '>Delay Start (ms)</span>
              <UncontrolledRangeInput
                name={`${props.id}-runtime-delay-start-ms`}
                className='flex justify-between items-center w-full gap-2'
                min={0}
                max={10000}
                step={1}
                defaultValue={props.voiceOver?.runtime?.delayStartMs ?? 0}
                disabled={!props.voiceOver?.runtime}
                onChange={(value) => {
                  if (!props.voiceOver?.runtime) return;
                  props.onChange({
                    runtime: {
                      ...props.voiceOver?.runtime,
                      delayStartMs: value,
                    },
                  });
                }}
              />
            </label>
          </div>
        </div>
        <div className='flex flex-col gap-2'>
          <label className='font-bold text-2xs text-icon-gray'>
            Fallback Voice (OnD Only)
          </label>
          <div className='flex items-center gap-4'>
            <VoiceOverMediaEditor
              id={`${props.id}-voice-over-fallback-media`}
              voiceOverMedia={props.voiceOver?.fallback}
              onChange={(fallback) => props.onChange({ fallback })}
              btnLabel={
                props.voiceOver?.fallback?.renderDescription
                  ? 'Edit Voice Over'
                  : 'Generate Voice Over'
              }
              previewMedia={props.previewMedia}
              previewLabel={props.previewLabel}
              title='Fallback Voice Over Generator'
              submitBtnLabel='Upload'
              scriptSubtitle={props.fallbackScriptSubtitle}
            />
            <label htmlFor={`${props.id}-fallback-delay-start-ms`}>
              <span className='text-sms text-icon-gray '>Delay Start (ms)</span>
              <UncontrolledRangeInput
                name={`${props.id}-fallback-delay-start-ms`}
                className='flex justify-between items-center w-full gap-2'
                min={0}
                max={10000}
                step={1}
                defaultValue={
                  props.voiceOver?.fallback?.renderDescription?.delayStartMs ??
                  0
                }
                disabled={!props.voiceOver?.fallback?.renderDescription}
                onChange={(value) => {
                  if (!props.voiceOver?.fallback?.renderDescription) return;
                  props.onChange({
                    fallback: {
                      ...props.voiceOver?.fallback,
                      renderDescription: {
                        ...props.voiceOver?.fallback?.renderDescription,
                        delayStartMs: value,
                      },
                    },
                  });
                }}
              />
            </label>
          </div>
        </div>
      </div>

      {props.bottomAccessory}
    </div>
  );
}

export function CompactVoiceOverEditor(props: {
  id: string;
  voiceOver?: VoiceOver | null;
  onChange: (voiceOver: VoiceOver | null) => void;
  previewMedia?: Media | null;
  previewLabel?: string;
  subtitle?: React.ReactNode;
  disabled?: boolean;
  runtime?: boolean;
  runtimeScriptSubtitle?: React.ReactNode;
  fallbackScriptSubtitle?: React.ReactNode;
  onGenerateExampleRuntimeState?: (
    state: GameStateForVoiceOver
  ) => GameStateForVoiceOver;
  overrideVariables?: readonly string[];
}): JSX.Element {
  return (
    <div className='space-y-4'>
      <div className='flex flex-col gap-2 relative'>
        {props.runtime && (
          <VoiceOverGeneratorButton
            onSubmit={(_, runtime) => props.onChange({ runtime })}
            initialData={props.voiceOver?.runtime}
            previewMedia={props.previewMedia}
            previewLabel={props.previewLabel}
            submitBtnLabel='Save'
            label={'VO'}
            title='Voice Over Config ⚡️'
            runtime
            scriptSubtitle={props.runtimeScriptSubtitle}
            onGenerateExampleRuntimeState={props.onGenerateExampleRuntimeState}
            overrideVariables={props.overrideVariables}
          />
        )}
      </div>
    </div>
  );
}
