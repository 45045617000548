import { proxy, ref } from 'valtio';
import { devtools } from 'valtio/utils';

import logger from '../../logger/logger';
import { type ISO8601String } from '../../types';
import { assertExhaustive } from '../../utils/common';
import {
  markSnapshottable,
  type ValtioSnapshottable,
} from '../../utils/valtio';
import { DetractorPct } from './DetractorPct';
import { LengthLimitedList } from './LengthLimitedList';
import { MinMaxValueBox } from './MinMaxValueBox';

type ContributorEntry<Meta = Record<string, unknown>> = {
  kind: ScoreContributor;
  value: number;
  durationMs: number;
  timestamp: ISO8601String;
  meta?: Meta;
};

type Ext = {
  score: number;
  detractPct: number;
  history: {
    [K in ScoreContributor]: LengthLimitedList<ContributorEntry>;
  };
};

type ScoreContributor = 'longframe';

const INITIAL_SCORE = 100;

const log = logger.scoped('experience-score');

// This is somewhat arbitrary. "Long Tasks" as defined in the long task spec are
// tasks longer than 50ms: https://w3c.github.io/longtasks/#sec-terminology.
// Since we're measuring FPS and not individual tasks, 100ms seems safe to
// define an _extremely_ long frame. Although our target is 60fps, some users
// might only be running at 30fps due to their monitor settings (!). 30fps means
// 33.3333ms per frame: probably too close to 50ms.
export const LONGFRAME_MS = 100;

export class ExperienceScoreManager {
  private detractorPct = new DetractorPct(this.getNowMs);
  private minMaxDetractorPct = new MinMaxValueBox(
    this.detractorPct.pct,
    this.getNowMs
  );

  private s = markSnapshottable(
    proxy<Ext>({
      score: INITIAL_SCORE,
      detractPct: this.detractorPct.pct,
      history: ref({
        'agora-drops': new LengthLimitedList(),
        'firebase-latency': new LengthLimitedList(),
        longframe: new LengthLimitedList(),
      }),
    })
  );

  private undevtools = devtools(this.s, { name: 'ExperienceScoreManager' });

  constructor(private getNowMs: () => number) {}

  // These methods are arrow-functions to ensure they have valid `this`
  // references. Since there is only one Manager, generally, the overhead is
  // minimal and worth it.
  reset = (): void => {
    this.s.score = INITIAL_SCORE;
    this.detractorPct = new DetractorPct(this.getNowMs);
    this.minMaxDetractorPct = new MinMaxValueBox(
      this.detractorPct.pct,
      this.getNowMs
    );
    for (const list of Object.values(this.s.history)) {
      list.empty();
    }
  };

  /**
   * Mark an event as negatively contributing to the experience score and
   * detractor percentage. Optional `meta` can be passed and will be logged when
   * emitted. The event is stored as "history" until the manager is reset.
   */
  contribute = (
    kind: ScoreContributor,
    meta?: Record<string, unknown> & { durationMs?: number }
  ): void => {
    switch (kind) {
      case 'longframe': {
        const defaultDetractorDurationMs = 100;
        // Whole milliseconds only for readability and consistency.
        const durationMs = Math.floor(
          meta?.durationMs ?? defaultDetractorDurationMs
        );
        // TODO(drew): these different types of detractors can eventually have
        // different scores or default durations. Default is that 100ms = -1 point.
        const value = Math.max(1, Math.round(durationMs / LONGFRAME_MS));

        this.detractorPct.detract(durationMs);

        this.s.score -= value;
        this.s.detractPct = this.detractorPct.pct;
        this.minMaxDetractorPct.update(this.s.detractPct);

        this.s.history[kind].push({
          kind,
          durationMs,
          value,
          timestamp: new Date(this.getNowMs()).toISOString(),
          meta: { ...meta, durationMs },
        });
        break;
      }

      default:
        assertExhaustive(kind);
    }
  };

  /**
   * Log the current history, score, and detractor pct.
   */
  emitLog = (
    blockType?: Nullable<string>,
    blockId?: Nullable<string>
  ): void => {
    const maxDetractorPct100 = Math.floor(this.minMaxDetractorPct.max * 100);

    const m = {
      score: this.s.score,
      detractorPct100: Math.floor(this.detractorPct.pct * 100),
      maxDetractorPct100: maxDetractorPct100,
      maxDetractorPctWhen: new Date(
        this.minMaxDetractorPct.maxWhen
      ).toISOString(),
      blockType,
      blockId,
    };

    const msg = [`pct: ${m.detractorPct100}`, `max: ${m.maxDetractorPct100}`];

    if (blockType) msg.push(`blockType: ${blockType}`);

    log.info(msg.join(', '), m);
  };

  listenable = (): Readonly<ValtioSnapshottable<Ext>> => {
    return this.s;
  };

  /**
   * Read the current Detractor Percentage _with side effects_ by recomputing
   * based on time elapsed.
   */
  pollDetractorPct = (): number => {
    return this.detractorPct.pct;
  };

  destroy() {
    this.undevtools?.();
  }
}
