import pluralize from 'pluralize';
import { useCallback, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';

import { EnumsMediaScene } from '@lp-lib/api-service-client/public';
import { type Block, BlockType } from '@lp-lib/game';
import { type Media } from '@lp-lib/media';

import {
  type TransformedAsyncCallState,
  useAsyncCall,
} from '../../hooks/useAsyncCall';
import { useOutsideClick } from '../../hooks/useOutsideClick';
import { apiService } from '../../services/api-service';
import {
  type Game,
  GameActionType,
  type GameLikeEditingContext,
} from '../../types/game';
import { NotificationType } from '../../types/notification';
import { type Tag, TagUtils } from '../../types/tag';
import { err2s, uuidv4 } from '../../utils/common';
import { Modal } from '../common/Modal';
import { useAwaitFullScreenConfirmCancelModal } from '../ConfirmCancelModalContext';
import { Loading } from '../Loading';
import { MediaUploader } from '../MediaUploader/MediaUploader';
import { useNotificationDataSource } from '../Notification/Context';
import { type TagOption, TagPicker } from '../Tagging/TagPicker';
import {
  type CreatingGameRequest,
  DeleteNonPrimeGameLikeModal,
  DeletePrimeGameLikeModal,
  DuplicatePrimeGameLikeModal,
  useGameLikeEditingTextMap,
  useGameLikeEventEmitter,
  useGameLikeWorkspace,
} from './GameCenter';
import { useEditingGame, useGameEditorStore } from './GameEditorStore';

interface FormData {
  id?: string;
  name: string;
  description?: string | null;
  coverMediaId?: string | null;
  tags?: string[] | null;
}

interface GameBaseEditorProps {
  game?: Game | null;
  error: Error | null;
  state: TransformedAsyncCallState;
  context: GameLikeEditingContext;
  onSubmit: (game: FormData) => void;
  onCancel?: () => void;
}

export const GameBaseEditor = (props: GameBaseEditorProps): JSX.Element => {
  const ref = useRef<HTMLDivElement | null>(null);
  const [media, setMedia] = useState<Media | null>(props.game?.cover || null);
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const { register, handleSubmit, formState } = useForm<FormData>({
    defaultValues: {
      name: props.game?.name,
      description: props.game?.description,
    },
  });
  const tagsRef = useRef<string[] | null>(
    props.game?.tags?.map((t) => t.name) || null
  );
  const textMap = useGameLikeEditingTextMap('game');

  useOutsideClick(ref, () => props.onCancel?.());

  const onSubmit = handleSubmit((data: FormData) => {
    props.onSubmit({
      id: props.game?.id,
      name: data.name,
      description: data.description,
      coverMediaId: media?.id,
      tags: tagsRef.current,
    });
  });

  const onUploadStart = () => {
    setIsUploading(true);
  };

  const onUploadSuccess = (media: Media) => {
    setMedia(media);
    setIsUploading(false);
  };

  const onUploadFailed = () => {
    setIsUploading(false);
  };

  const onMediaDelete = () => {
    setMedia(null);
  };

  const onTagsChange = (tags: Tag[]) => {
    tagsRef.current = tags.map((t) => t.name);
  };

  const formatTagOptionMeta = (option: TagOption): string => {
    const gamesCount = option.__isNew__
      ? 0
      : TagUtils.getPrimeGamesCount(option);
    if (option.__isNew__) {
      return '(Create New)';
    }
    return `(${gamesCount} ${pluralize('Minigame', gamesCount)})`;
  };

  return (
    <div ref={ref} className='w-160 flex flex-col items-center text-white px-7'>
      <h2 className='text-2xl mt-7.5'>{textMap[props.context].title}</h2>
      <form onSubmit={onSubmit} className='w-full my-7.5'>
        <label htmlFor='name' className='font-bold'>
          <div className='mb-2'>
            <span className='text-white'>Name</span>
            <span className='text-primary ml-2'>(Required)</span>
          </div>
          <input
            className={
              formState.errors.name ? 'field-error h-15' : 'field h-15'
            }
            {...register('name', { required: true, maxLength: 50 })}
            placeholder='Must be 1 to 50 characters'
            maxLength={50}
          />
        </label>
        <label htmlFor='description' className='mt-5 font-bold'>
          <div className='mb-2'>
            <span className='text-white'>Description</span>
          </div>
          <textarea
            className={`${
              formState.errors?.description ? 'field-error' : 'field'
            } h-30 py-2 scrollbar`}
            {...register('description', { maxLength: 1000 })}
            placeholder='Max 1000 characters'
            maxLength={1000}
          />
        </label>
        <label htmlFor='tags' className='mt-5 font-bold'>
          <div className='mb-2'>
            <span className='text-white'>Categories</span>
          </div>
          <div className='mb-5'>
            <TagPicker
              onChange={onTagsChange}
              tags={props.game?.tags}
              creatable
              multi={true}
              placeholder='Add existing or create new Categories'
              formatMeta={formatTagOptionMeta}
            />
          </div>
        </label>
        <label htmlFor='cover' className='mt-5 font-bold'>
          <div className='mb-2'>
            <span className='text-white'>Cover Image</span>
          </div>
          <MediaUploader
            video={false}
            scene={EnumsMediaScene.MediaSceneGameCover}
            media={media}
            onUploadStart={onUploadStart}
            onUploadSuccess={onUploadSuccess}
            onUploadFailed={onUploadFailed}
            onMediaDelete={onMediaDelete}
            // clipPath='game-cover-clip'
          />
        </label>
        <div className='text-red-005 text-xs'>{err2s(props.error)}</div>
        <div className='w-full mt-7.5 flex flex-row justify-center'>
          {props.onCancel && (
            <button
              type='button'
              onClick={props.onCancel}
              className='btn-secondary w-40 h-10 text-base'
            >
              Cancel
            </button>
          )}
          <button
            type='submit'
            className='btn-primary w-40 h-10 ml-5 text-base flex items-center justify-center'
            disabled={
              props.state.isRunning ||
              isUploading ||
              Object.keys(formState.errors).length > 0
            }
          >
            {props.state.isRunning && (
              <Loading text='' containerClassName='mr-2' />
            )}
            {isUploading ? 'Uploading' : textMap[props.context].button}
          </button>
        </div>
      </form>
    </div>
  );
};

export const CreateGameModal = (): JSX.Element => {
  const store = useGameEditorStore();
  const [creatingGame, setCreatingGame] = useGameLikeWorkspace(
    'game',
    'create'
  );
  const emitter = useGameLikeEventEmitter('game');
  let showCreateGameRequest: CreatingGameRequest;
  let openEditorAfterCreated = true;
  let initBlock = true;
  let game: Game | undefined;
  if (creatingGame !== undefined && typeof creatingGame !== 'boolean') {
    showCreateGameRequest = creatingGame as CreatingGameRequest;
    game = creatingGame.initialValues;
    if (showCreateGameRequest.openEditorAfterCreated !== undefined) {
      openEditorAfterCreated = showCreateGameRequest.openEditorAfterCreated;
    }
    if (showCreateGameRequest.initBlock !== undefined) {
      initBlock = showCreateGameRequest.initBlock;
    }
  }

  const { state, error, call } = useAsyncCall(
    useCallback(
      async (data: FormData) => {
        const game = (await apiService.game.create(data)).data.game;
        let blocks: Block[] = [];
        if (initBlock) {
          blocks = (
            await apiService.block.createGameBlock(game.id, {
              position: 0,
              type: BlockType.QUESTION,
            })
          ).data.blocks;
          game.blocks = blocks;
          game.blocksCount = blocks.length;
        }
        return { game, blocks };
      },
      [initBlock]
    )
  );

  const onSubmit = async (data: FormData) => {
    const resp = await call(data);
    if (!resp) return;
    const { game, blocks } = resp;
    emitter.emit('created', game);
    if (openEditorAfterCreated) {
      await store.setEditingGame({ ...game }, { blocks });
    }
    setCreatingGame(false);
  };

  const onCancel = () => {
    setCreatingGame(false);
  };

  if (!creatingGame) {
    return <></>;
  }

  return (
    <Modal borderStyle='white' stopPropagateMouseDown>
      <GameBaseEditor
        game={game}
        error={error}
        state={state.transformed}
        context='create'
        onSubmit={onSubmit}
        onCancel={onCancel}
      />
    </Modal>
  );
};

export function useTriggerEditGameModal(): () => void {
  const triggerModal = useAwaitFullScreenConfirmCancelModal();

  const editingGame = useEditingGame();
  const store = useGameEditorStore();
  const emitter = useGameLikeEventEmitter('game');
  const srcGame = editingGame;

  const { state, error, call } = useAsyncCall(
    useCallback(async (data: FormData) => {
      if (!data.id) return;
      return (
        await apiService.game.update(data.id, {
          ...data,
          coverMediaId: data.coverMediaId || '',
        })
      ).data.game;
    }, [])
  );

  return useCallback(() => {
    triggerModal({
      kind: 'custom',
      element: (p) => (
        <Modal borderStyle='white' stopPropagateMouseDown>
          <GameBaseEditor
            game={srcGame}
            error={error}
            state={state.transformed}
            context='edit'
            onSubmit={async (data) => {
              if (!data.id) return;
              const game = await call(data);
              if (!game) return;
              store.updateGame(game);
              emitter.emit('updated', game);
              p.internalOnConfirm();
            }}
            onCancel={p.internalOnCancel}
          />
        </Modal>
      ),
    });
  }, [call, emitter, error, srcGame, state.transformed, store, triggerModal]);
}

export const PublishGameModal = (): JSX.Element => {
  const [publishingGame, setPublishingGame] = useGameLikeWorkspace(
    'game',
    'publish'
  );
  const notificationDataSource = useNotificationDataSource();
  const emitter = useGameLikeEventEmitter('game');

  const { state, error, call } = useAsyncCall(
    useCallback(async (data: FormData) => {
      if (!data.id) return;
      return (
        await apiService.game.publish(data.id, {
          ...data,
          coverMediaId: data.coverMediaId || '',
        })
      ).data.game;
    }, [])
  );

  const onSubmit = async (data: FormData) => {
    if (!data.id) return;
    const game = await call(data);
    if (!game) return;
    notificationDataSource.send({
      id: uuidv4(),
      toUserClientId: '',
      type: NotificationType.GameAction,
      createdAt: Date.now(),
      metadata: {
        actionType: GameActionType.PublishGame,
        game: game,
      },
    });
    emitter.emit('published', game);
    setPublishingGame(null);
  };

  const onCancel = () => {
    setPublishingGame(null);
  };

  return (
    <Modal borderStyle='white' stopPropagateMouseDown>
      <GameBaseEditor
        game={publishingGame}
        error={error}
        state={state.transformed}
        context='publish'
        onSubmit={onSubmit}
        onCancel={onCancel}
      />
    </Modal>
  );
};

export const DeleteGameModal = (): JSX.Element | null => {
  const store = useGameEditorStore();
  const emitter = useGameLikeEventEmitter('game');
  const [deletingGame, setDeletingGame] = useGameLikeWorkspace(
    'game',
    'delete'
  );
  const [activeGame, setActiveGame] = useGameLikeWorkspace('game', 'active');
  const { state, error, call } = useAsyncCall(
    useCallback(async (gameId: string) => {
      return await apiService.game.delete(gameId);
    }, [])
  );

  const onCancel = () => {
    setDeletingGame(null);
  };

  const onDelete = async () => {
    if (!deletingGame) return;
    const resp = await call(deletingGame.id);
    if (!resp) return;
    store.deleteGame(deletingGame.id);
    emitter.emit('deleted', deletingGame);
    if (activeGame?.id === deletingGame.id) {
      setActiveGame(null);
    }
    setDeletingGame(null);
  };

  if (!deletingGame) {
    return null;
  }

  if (deletingGame.isPrime) {
    return (
      <DeletePrimeGameLikeModal
        type={deletingGame.type}
        state={state.transformed}
        error={error}
        onCancel={onCancel}
        onConfirm={onDelete}
      />
    );
  }

  return (
    <DeleteNonPrimeGameLikeModal
      type={deletingGame.type}
      state={state.transformed}
      error={error}
      onCancel={onCancel}
      onConfirm={onDelete}
    />
  );
};

export const DuplicateGameModal = (): JSX.Element | null => {
  const emitter = useGameLikeEventEmitter('game');
  const [duplicatingGame, setDuplicatingGame] = useGameLikeWorkspace(
    'game',
    'duplicate'
  );
  const { state, error, call } = useAsyncCall(
    useCallback(async (gameId: string) => {
      const { game, blocks } = (await apiService.game.duplicate(gameId)).data;
      game.blocks = blocks;
      return game;
    }, [])
  );

  const onCancel = () => {
    setDuplicatingGame(null);
  };

  const onDuplicate = async () => {
    if (!duplicatingGame) return;
    const game = await call(duplicatingGame.id);
    if (!game) return;
    emitter.emit('duplicated', game);
    setDuplicatingGame(null);
  };

  if (!duplicatingGame) {
    return null;
  }

  return (
    <DuplicatePrimeGameLikeModal
      type={duplicatingGame.type}
      state={state.transformed}
      error={error}
      onCancel={onCancel}
      onConfirm={onDuplicate}
    />
  );
};
