import { useLiveCallback } from '../../hooks/useLiveCallback';
import { type Participant } from '../../types/user';
import { useMyInstance, useMyInstanceGetter } from './exposed';
import {
  type ParticipantArrayOptions,
  useParticipantsAsArray,
  useParticipantsAsArrayGetter,
} from './useParticipantsAsArray';

const ArrayOptions: ParticipantArrayOptions = {
  filters: ['cohost:true', 'status:connected', 'staff:false'],
  sorts: ['joinedAt:asc'],
};

function pickCohost(participants: Participant[]) {
  if (participants.length === 0) return null;
  const first = participants.at(0);
  if (!first) return null;
  return first;
}

export function useCohosts() {
  const participants = useParticipantsAsArray(ArrayOptions);
  return participants;
}

export function useCohost() {
  const participants = useCohosts();
  return pickCohost(participants);
}

export function useCohostClientId() {
  const cohost = useCohost();
  return cohost?.clientId ?? null;
}

export function useCohostClientIdGetter() {
  const get = useCohostGetter();
  return useLiveCallback(() => {
    return get()?.clientId ?? null;
  });
}

export function useAmICohost() {
  const cohost = useCohost();
  const me = useMyInstance();
  return Boolean(cohost?.clientId && cohost?.clientId === me?.clientId);
}

export function useAmICohostGetter() {
  const getCohost = useCohostGetter();
  const getMyInstance = useMyInstanceGetter();
  return useLiveCallback(() => {
    const cohostClientId = getCohost()?.clientId;
    return Boolean(
      cohostClientId && cohostClientId === getMyInstance()?.clientId
    );
  });
}

export function useCohostsGetter() {
  const getParticipants = useParticipantsAsArrayGetter();
  return useLiveCallback(() => {
    const participants = getParticipants(ArrayOptions);
    return participants;
  });
}

export function useCohostGetter() {
  const get = useCohostsGetter();
  return useLiveCallback(() => {
    const participants = get();
    return pickCohost(participants);
  });
}
