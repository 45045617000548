// Returns true if the present item was replaced with null
export function nullIfPresent<T>(
  arr: (T | null)[],
  item: T,
  comparator: (a: T | null, b: T | null) => boolean
): boolean {
  const idx = arr.findIndex((v) => comparator(v, item));
  if (idx === -1) return false;
  arr[idx] = null;
  return true;
}
