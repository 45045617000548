import { useEffect, useMemo, useRef, useState } from 'react';
import { useLatest } from 'react-use';

import {
  BlockType,
  calculateScore,
  type CreativePromptBlock,
  type CreativePromptBlockAnswerData,
  type CreativePromptBlockGameSessionStatus,
  type FakedRapidBlockAnswerData,
  type GameSessionStatus,
  type QuestionBlock,
  type QuestionBlockAnswerData,
  QuestionBlockAnswerGrade,
  type QuestionBlockGameSessionStatus,
  type RapidBlock,
  type RapidBlockAnswerData,
  RapidBlockGameSessionStatus,
} from '@lp-lib/game';

import { bps } from '../../../../../breakpoints';
import { useIsController } from '../../../../../hooks/useMyInstance';
import { useStatsAwareTaskQueue } from '../../../../../hooks/useTaskQueue';
import { ClientTypeUtils, type User } from '../../../../../types/user';
import { ChatMessageInputDOMId } from '../../../../Chat/common';
import { useIsFirebaseConnected } from '../../../../Firebase';
import { useParticipantByClientId } from '../../../../Player';
import { useIsTeamCaptainScribe } from '../../../../TeamAPI/TeamV1';
import { useUser } from '../../../../UserContext';
import {
  useMyClientId,
  useMyClientType,
} from '../../../../Venue/VenuePlaygroundProvider';
import {
  useAudienceTeamData,
  useGameSessionBlock,
  useIsLiveGamePlay,
  useOndGameState,
} from '../../../hooks';
import { countdownV2, gameSessionStore } from '../../../store';
import {
  GamePlaySubmitButton,
  GamePlaySubmitButtonText,
} from './GamePlaySubmitButton';
import { PointsBanner } from './PointsBanner';
import { ProgressRing } from './ProgressRing';
import { useTeamSubmissionStatusAPI } from './SubmissionStatusProvider';

export type GameSubmissionInputBlockAdaptor = {
  presentStatus: number;
  countingStatus: number;
  waitingStatus: number;
  timesupStatus: number;
  blockTime: number | null;
  submit: (
    answer: string | null,
    teamId: string | null,
    submitter: Pick<User, 'id' | 'username'>
  ) =>
    | Promise<CreativePromptBlockAnswerData | null>
    | Promise<QuestionBlockAnswerData | null>
    | Promise<RapidBlockAnswerData | null>;
};

type AnswerInputStateSequence =
  | 'waiting'
  | 'timer-typing'
  | 'timer-submitting'
  | 'timer-submitted'
  | 'timesup-submitted'
  | 'timesup-with-answer-value'
  | 'timesup-empty-answer-value'
  | never;

const useAnswerInputStateSequence = ({
  gameSessionStatus,
  timer,
  inputRef,
  resetButton,
  myLastSubmitData,
  submitting,
  inputable,
  adaptor,
}: {
  gameSessionStatus: GameSessionStatus;
  timer: number | null;
  inputRef: React.MutableRefObject<HTMLInputElement | null>;
  resetButton?: boolean;
  myLastSubmitData:
    | QuestionBlockAnswerData
    | CreativePromptBlockAnswerData
    | RapidBlockAnswerData
    | FakedRapidBlockAnswerData
    | null;
  submitting: boolean;
  inputable: boolean;
  adaptor: GameSubmissionInputBlockAdaptor;
}): AnswerInputStateSequence => {
  const gameSessionBlock = useGameSessionBlock() as
    | QuestionBlock
    | CreativePromptBlock
    | RapidBlock
    | null;

  const inputValue = inputRef.current?.value ?? null;
  const teamData = useAudienceTeamData();

  const { presentStatus, countingStatus, timesupStatus } = adaptor;
  const isRapidBlock = gameSessionBlock?.type === BlockType.RAPID;

  let isAnswerSubmitted = false;
  if (isRapidBlock) {
    isAnswerSubmitted = !!myLastSubmitData;
  } else {
    const notRapidGameSessionData = teamData as
      | CreativePromptBlockAnswerData
      | QuestionBlockAnswerData
      | null;
    isAnswerSubmitted = (notRapidGameSessionData?.answer ?? '').length > 0;
  }

  const isWaiting = gameSessionStatus === presentStatus;
  const timesup = gameSessionStatus === timesupStatus || timer === 0;
  const noAnswerSubmitted = timesup && !isAnswerSubmitted;

  const answerPresentButUnsubmitted = isRapidBlock
    ? !!inputValue && inputValue.length > 0
    : noAnswerSubmitted && !!inputValue && inputValue.length > 0;

  let answerInputState: AnswerInputStateSequence = isWaiting
    ? 'waiting'
    : gameSessionStatus === countingStatus && !timesup && submitting
    ? 'timer-submitting'
    : gameSessionStatus === countingStatus && !timesup && !isAnswerSubmitted
    ? 'timer-typing'
    : gameSessionStatus === countingStatus && isAnswerSubmitted
    ? 'timer-submitted'
    : timesup &&
      isAnswerSubmitted &&
      !(isRapidBlock && answerPresentButUnsubmitted)
    ? 'timesup-submitted'
    : timesup && answerPresentButUnsubmitted
    ? 'timesup-with-answer-value'
    : timesup && noAnswerSubmitted
    ? 'timesup-empty-answer-value'
    : 'waiting';

  if (answerInputState === 'timer-submitted' && resetButton) {
    answerInputState = 'timer-typing';
  }

  useEffect(() => {
    if (!inputable || answerInputState !== 'timer-typing') return;
    if (inputRef.current) {
      inputRef.current.value = '';
      inputRef.current.focus();
    }
  }, [answerInputState, inputRef, inputable]);

  return answerInputState;
};

export const GameSubmissionInput = (props: {
  timer: number | null;
  gameSessionStatus:
    | QuestionBlockGameSessionStatus
    | CreativePromptBlockGameSessionStatus
    | RapidBlockGameSessionStatus
    | null;
  gameSessionBlock: QuestionBlock | CreativePromptBlock | RapidBlock;
  adaptor: GameSubmissionInputBlockAdaptor;
}): JSX.Element => {
  const { timer, gameSessionStatus, gameSessionBlock, adaptor } = props;

  const [resetButton, setResetButton] = useState(false);
  const [showInputLimitError, setShowInputLimitError] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [myLastSubmitData, setMyLastSubmitData] = useState<
    | QuestionBlockAnswerData
    | CreativePromptBlockAnswerData
    | RapidBlockAnswerData
    | FakedRapidBlockAnswerData
    | null
  >(null);

  const inputRef = useRef<HTMLInputElement | null>(null);
  const isQuestionBlock =
    gameSessionBlock !== null && gameSessionBlock.type === BlockType.QUESTION;
  const myClientType = useMyClientType();
  const isAudience = ClientTypeUtils.isAudience(myClientType);
  const isController = useIsController();
  const isLive = useIsLiveGamePlay();
  const clientId = useMyClientId();
  const participant = useParticipantByClientId(clientId);
  const user = useUser();
  const currentTeamId = participant?.teamId ?? null;

  const { countingStatus, blockTime, submit } = adaptor;

  const isCounting = gameSessionStatus === countingStatus;

  const isTeamCaptainScribe = useIsTeamCaptainScribe(currentTeamId, clientId);
  const teamData = useAudienceTeamData();

  const isRapidBlock = gameSessionBlock.type === BlockType.RAPID;

  const everyoneSubmits =
    isRapidBlock && gameSessionBlock.fields.everyoneSubmits;
  const inputable = isTeamCaptainScribe || (everyoneSubmits && isAudience);

  const answerInputState = useAnswerInputStateSequence({
    gameSessionStatus,
    timer,
    inputRef,
    resetButton,
    myLastSubmitData,
    submitting,
    inputable,
    adaptor,
  });

  const ondState = useOndGameState();
  const submissionStatusAPI = useTeamSubmissionStatusAPI();

  const canInput = inputable && answerInputState === 'timer-typing';

  let inputLimit = 50;
  switch (gameSessionBlock.type) {
    case BlockType.CREATIVE_PROMPT:
      inputLimit = 200;
  }

  const [showSubmitWarning, setShowSubmitWarning] = useState(false);
  const submitWarningTimerRef = useRef<ReturnType<typeof setTimeout> | null>(
    null
  );

  const firebaseConnected = useIsFirebaseConnected();

  const { addTask: addRSBSubmissionTask } = useStatsAwareTaskQueue({
    shouldProcess: firebaseConnected,
    stats: 'task-queue-rsb-submission-ms',
  });

  const getCurrentBlockId = () => {
    return gameSessionStore.session.blockSession?.block?.id ?? null;
  };

  useEffect(() => {
    if (
      gameSessionBlock?.type !== BlockType.RAPID ||
      gameSessionStatus !== RapidBlockGameSessionStatus.QUESTION_COUNTING
    ) {
      return;
    }

    const timer = setTimeout(() => {
      setShowSubmitWarning(true);
    }, 4000);
    submitWarningTimerRef.current = timer;
    return () => {
      setShowSubmitWarning(false);
      clearTimeout(timer);
    };
  }, [gameSessionBlock?.id, gameSessionBlock?.type, gameSessionStatus]);

  const inputOnChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e?.target?.value && e?.target?.value.length >= inputLimit) {
      setShowInputLimitError(true);
    } else {
      setShowInputLimitError(false);
    }
  };

  const submitHandler = async () => {
    if (
      !(
        answerInputState === 'timer-typing' ||
        answerInputState === 'timesup-with-answer-value'
      )
    )
      return;

    const inputVal = inputRef.current?.value;
    if (!inputVal || inputVal.length === 0) {
      return;
    }

    setShowSubmitWarning(false);
    if (submitWarningTimerRef.current) {
      clearTimeout(submitWarningTimerRef.current);
      submitWarningTimerRef.current = null;
    }

    setSubmitting(true);
    isRapidBlock && setResetButton(false);
    setMyLastSubmitData(null);

    if (!isRapidBlock) {
      const submission = await submit(inputVal, currentTeamId, user);
      setMyLastSubmitData(submission);
      setSubmitting(false);
      await submissionStatusAPI.markSubmitted();
    } else {
      const currentBlockId = getCurrentBlockId();
      addRSBSubmissionTask(async function submitRSB() {
        const submission = (await submit(
          inputVal,
          currentTeamId,
          user
        )) as RapidBlockAnswerData;

        if (!submission.committed && currentBlockId === getCurrentBlockId()) {
          // re-enqueue only if the block is still active
          addRSBSubmissionTask(submitRSB);
        }
      });
      setMyLastSubmitData({ submittedAnswer: inputVal, submitterUid: user.id });
      // make sure that "setSubmitting" doesn't change from true to false immediately,
      // otherwise InputStateSequence won't work
      Promise.resolve().then(() => setSubmitting(false));
      setResetButton(true);
    }
    showInputLimitError && setShowInputLimitError(false);
  };

  const pendingSubmissionCheckRef = useRef(false);
  const latestSubmitHandler = useLatest(submitHandler);

  // This along with autocomplete=off hopefully disables the 1Password and other
  // autocomplete dropdowns. It seems Chrome has decided to not support this
  // attribute because banks and other websites abused it in the name of false
  // security: https://bugs.chromium.org/p/chromium/issues/detail?id=914451
  const inputNameToDefeatAutocomplete = useMemo(
    () => `answer-input-${Math.random()}`,
    []
  );

  const currentAnswerPointValue =
    isQuestionBlock && blockTime
      ? Math.round(
          calculateScore(
            QuestionBlockAnswerGrade.CORRECT,
            gameSessionBlock.fields.decreasingPointsTimer ?? true,
            // If an answer has already been submitted, use the time at which it was
            // submitted to compute the displayed score. Otherwise, use the current
            // countdown. This causes the score to "stick" at whatever point value the
            // player has a chance to receive.
            (teamData as QuestionBlockAnswerData)?.timerWhenSubmitted ??
              timer ??
              0,
            blockTime,
            gameSessionBlock.fields.points,
            gameSessionBlock.fields.startDescendingImmediately
          )
        )
      : null;

  const onKeyPress = (e: React.KeyboardEvent) => {
    if (e && e.isTrusted && e.key === 'Enter') {
      submitHandler();
    }
  };

  const onMouseClick = (e: React.MouseEvent) => {
    // e.isTrusted is removed for bots
    if (e) {
      submitHandler();
    }
  };

  useEffect(() => {
    if (
      answerInputState === 'timesup-with-answer-value' &&
      !pendingSubmissionCheckRef.current
    ) {
      pendingSubmissionCheckRef.current = true;
      latestSubmitHandler.current();
    }
  }, [answerInputState, latestSubmitHandler]);

  useEffect(() => {
    // Note(jialin): Avoid start time worker if the OnD game is not running,
    // it happens when recovery from paused game
    if (!isLive && ondState !== 'running') return;

    if (inputRef.current && isCounting) {
      const isChatActive = document.activeElement?.id === ChatMessageInputDOMId;

      if (!isChatActive) {
        inputRef.current.focus();
      }

      countdownV2({
        debug: 'GameSubmissionInput',
        // Note(jialin): in the live game, the timers are triggered in the GameController
        startTimeWorker: isController ? !isLive : true,
        flushCountingStatus: false,
      });
    }
  }, [isCounting, isController, isLive, myClientType, ondState]);

  useEffect(() => {
    if (isRapidBlock && inputable) {
      setResetButton(true);
    }
  }, [isRapidBlock, inputable]);

  const btnSkew = `-skew-x-12`;
  const reverseBtnSkew = `skew-x-12`;

  const showSubmitBtn = isRapidBlock
    ? inputable &&
      answerInputState !== 'timesup-empty-answer-value' &&
      answerInputState !== 'timesup-submitted'
    : answerInputState === 'timesup-submitted' ||
      answerInputState === 'timer-submitted' ||
      (isTeamCaptainScribe &&
        answerInputState !== 'timesup-empty-answer-value');

  const submitBtnGradientStyle =
    answerInputState === 'timesup-with-answer-value'
      ? `bg-gradient-to-tr from-primary-start to-primary-end`
      : answerInputState === 'timer-submitted' ||
        answerInputState === 'timesup-submitted' ||
        answerInputState === 'timesup-empty-answer-value'
      ? `bg-gradient-to-tr from-primary-start to-primary-end`
      : `bg-gradient-to-r from-gamecard-btn-start via-white to-gamecard-btn-end`;

  const inputBgStyles = 'border-primary bg-black bg-opacity-50';

  let inputValue = undefined;
  if (
    answerInputState === 'timer-submitted' ||
    answerInputState === 'timesup-submitted'
  ) {
    if (isRapidBlock) {
      if (answerInputState === 'timesup-submitted') {
        inputValue = '';
      }
    } else {
      const teamAnswer = teamData as
        | QuestionBlockAnswerData
        | CreativePromptBlockAnswerData
        | null;
      inputValue = teamAnswer?.answer ?? '';
    }
  } else if (answerInputState === 'timesup-with-answer-value' && !inputable) {
    // Edge case: user transferred team captain scribe to another
    // while their input field was populated. Clear it.
    inputValue = '';
  }

  let placeholder = '[No answer submitted]';
  if (
    answerInputState === 'waiting' ||
    answerInputState === 'timer-typing' ||
    (answerInputState === 'timer-submitted' && isRapidBlock)
  ) {
    if (!inputable && isAudience) {
      placeholder = `Your team captain will answer ${
        timer !== null
          ? Array(4)
              .fill('\u2008')
              .fill('.', 0, 3 - (timer % 4))
              .join('')
          : ''
      }`;
    } else {
      const answerTextCopy = isRapidBlock ? 'answers' : 'answer';
      placeholder =
        answerInputState === 'waiting'
          ? `Get ready to enter your ${answerTextCopy}`
          : `Enter your ${answerTextCopy} here`;
    }
  } else if (
    isRapidBlock &&
    (answerInputState === 'timesup-submitted' ||
      answerInputState === 'timesup-empty-answer-value' ||
      answerInputState === 'timesup-with-answer-value')
  ) {
    placeholder = '[Time’s Up!]';
  }

  const durationFormattedMMSS = (blockTime ?? 0) > 240;

  return (
    <div
      className={`w-1/2 min-w-100 max-w-160 flex flex-col justify-center items-center`}
    >
      <div
        className={`
            w-full flex flex-row justify-center items-center
            transform ${bps(
              isQuestionBlock
                ? [
                    '-translate-x-3',
                    'xl:-translate-x-3',
                    'lp-sm:-translate-x-3',
                    '2xl:-translate-x-3',
                    '3xl:-translate-x-3',
                    'lp-md:-translate-x-3',
                    'lp-lg:-translate-x-3',
                  ]
                : [
                    '-translate-x-10',
                    'xl:-translate-x-10',
                    'lp-sm:-translate-x-10',
                    '2xl:-translate-x-10',
                    '3xl:-translate-x-10',
                    'lp-md:-translate-x-10',
                    'lp-lg:-translate-x-10',
                  ],
              [
                'h-12',
                'xl:h-12',
                'lp-sm:h-14',
                '2xl:h-14',
                '3xl:h-14',
                'lp-md:h-14',
                'lp-lg:h-14',
              ]
            )}`}
      >
        <div
          className={`relative transform ${bps([
            'translate-x-4',
            'xl:translate-x-4',
            'lp-sm:translate-x-4',
            '2xl:translate-x-4',
            '3xl:translate-x-4',
            'lp-md:translate-x-4',
            'lp-lg:translate-x-4',
          ])}`}
        >
          <ProgressRing
            className={`relative`}
            currentTime={timer}
            totalTime={blockTime}
            withPingAnimations
            durationFormattedMMSS={durationFormattedMMSS}
          />
        </div>

        <input
          ref={inputRef}
          key={
            // Use specific keys if the inputValue is defined to force react to
            // make a new component. This prevent react from considering the
            // `input` as a controlled element. We always want it to be considered
            // uncontrolled when user input is allowed.
            !isTeamCaptainScribe || inputValue !== undefined
              ? 'display-only-input'
              : 'uncontrolled-input'
          }
          autoComplete='off'
          name={inputNameToDefeatAutocomplete}
          className={`w-full h-full ${bps(
            'px-4 xl:px-4 lp-sm:px-4 2xl:px-4 3xl:px-4 lp-md:px-4 lp-lg:px-4'
          )} ${
            inputable
              ? 'font-normal'
              : answerInputState === 'timer-typing'
              ? 'font-bold'
              : ''
          } ${
            answerInputState === 'timesup-with-answer-value'
              ? 'text-blue-002 text-opacity-40'
              : 'text-blue-002'
          } text-center
            appearance-none outline-none
            rounded-xl border border-solid
            ${inputBgStyles}
            placeholder-primary-001 placeholder-shown:font-bold
            shadow-gamecard-input
            ${
              answerInputState === 'waiting' ||
              answerInputState === 'timesup-with-answer-value' ||
              answerInputState === 'timesup-empty-answer-value'
                ? 'disabled:border-opacity-25 disabled:bg-opacity-10 disabled:placeholder-opacity-50'
                : ''
            }
          `}
          placeholder={placeholder}
          disabled={!canInput}
          value={inputValue}
          onChange={inputOnChangeHandler}
          maxLength={inputLimit}
          onKeyPress={onKeyPress}
        />
        {showInputLimitError && (
          <div className='absolute w-full h-auto top-0.5 flex flex-row justify-center items-center pointer-events-off'>
            <div className='w-18 lp-sm:w-20' />
            <p className='text-3xs font-medium text-red-002'>
              {isRapidBlock
                ? 'Max character limit reached. Hit submit after each answer!'
                : 'Max character limit reached'}
            </p>
            {currentAnswerPointValue !== null && (
              <div
                className={`${bps(
                  'w-16 xl:w-16 lp-sm:w-16 2xl:w-16 3xl:w-16 lp-md:w-16 lp-lg:w-16'
                )}`}
              />
            )}
          </div>
        )}
        {currentAnswerPointValue !== null && (
          <PointsBanner
            pointValue={currentAnswerPointValue}
            visible={
              !(
                answerInputState === 'timesup-empty-answer-value' ||
                answerInputState === 'timesup-with-answer-value' ||
                answerInputState === 'timesup-submitted'
              )
            }
          />
        )}
      </div>

      {!showSubmitBtn ? (
        <div
          className={`${bps(
            'h-9 xl:h-9 lp-sm:h-9 2xl:h-9 3xl:h-9 lp-md:h-9 lp-lg:h-9'
          )}`}
        />
      ) : (
        <div
          className={`flex flex-row transform
        ${bps([
          '-translate-y-2',
          'xl:-translate-y-2',
          'lp-sm:-translate-y-3',
          '2xl:-translate-y-3',
          '3xl:-translate-y-3',
          'lp-md:-translate-y-3',
          'lp-lg:-translate-y-3',
        ])}`}
        >
          <GamePlaySubmitButton
            onClick={onMouseClick}
            disabled={answerInputState !== 'timer-typing'}
            style={{ skew: btnSkew }}
          >
            {answerInputState === 'waiting' ? (
              'Waiting'
            ) : (
              <GamePlaySubmitButtonText
                style={{
                  skew: reverseBtnSkew,
                  gradient: submitBtnGradientStyle,
                }}
              >
                {answerInputState === 'timesup-with-answer-value'
                  ? `Submitted!`
                  : answerInputState === 'timer-submitting' ||
                    answerInputState === 'timer-submitted' ||
                    answerInputState === 'timesup-submitted'
                  ? `Submitted!`
                  : `Submit`}
              </GamePlaySubmitButtonText>
            )}
          </GamePlaySubmitButton>
          {showSubmitWarning && (
            <div className='relative'>
              <div className='absolute left-2 flex flex-row h-[42px] px-2 bg-lp-red-001 bg-opacity-60 shadow-icon rounded-6.25xl text-white'>
                <div className='flex justify-center items-center'>
                  <div className='text-center text-sm'>&larr;</div>
                </div>
                <div className='w-[89px] pt-[2px] pb-[3px] flex items-center justify-center'>
                  <p className='text-center text-2xs font-bold'>
                    Submit after each answer!
                  </p>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};
