import {
  ClientTypeUtils,
  isStaff,
  type Participant,
  type ParticipantFlags,
} from '../../types';

export type ParticipantSkimFilterKey = Exclude<
  keyof Participant,
  'clientType' | 'away'
>;

export type ParticipantSkimFilterValue = Exclude<
  {
    [K in ParticipantSkimFilterKey]: `${K}:${Participant[K]}`;
  }[ParticipantSkimFilterKey],
  // Exclude undefineds
  | `${string}:undefined`
  | undefined
  // Exclude clientType in favor of synthetic host:
  | `clientType:${string}`
  | `away:${boolean}`
>;

export type ParticipantFlagFilterKey = keyof ParticipantFlags;

export type ParticipantFlagFilterValue = Exclude<
  {
    [K in keyof ParticipantFlags]: `${K}:${ParticipantFlags[K]}`;
  }[keyof ParticipantFlags],
  // Exclude undefineds
  `${string}:undefined` | undefined
>;

type ParticipantSyntheticFilterValue =
  `${(typeof ParticipantSyntheticFilterMatchers)[number][0]}:${boolean}`;

export type ParticipantFilterValue =
  | ParticipantSkimFilterValue
  | ParticipantFlagFilterValue
  | ParticipantSyntheticFilterValue;

export const ParticipantSyntheticFilterMatchers = [
  ['host', (p: Participant) => ClientTypeUtils.isHost(p)],
  ['staff', (p: Participant) => isStaff(p)],
  ['team', (p: Participant) => Boolean(p.teamId)],
  ['away', (p: Participant) => Boolean(p.away)],
] as const;

const PSorters = {
  'joinedAt:asc': (a: Participant, b: Participant) => a.joinedAt - b.joinedAt,
  'joinedAt:desc': (a: Participant, b: Participant) => b.joinedAt - a.joinedAt,
  'username:asc': (a: Participant, b: Participant) =>
    a.username.localeCompare(b.username),
  'username:desc': (a: Participant, b: Participant) =>
    b.username.localeCompare(a.username),
} as const;

export type ParticipantSorterValue = keyof typeof PSorters;
