import { useEffect, useLayoutEffect, useMemo, useState } from 'react';

import { MediaFormatVersion } from '@lp-lib/media';

import { getFeatureQueryParam } from '../../hooks/useFeatureQueryParam';
import { type Participant } from '../../types';
import { type Game, type GamePack } from '../../types/game';
import { MediaUtils } from '../../utils/media';
import { useOndOpenParticipantExploreLibrary } from '../Game/GameLibrary/useOndOpenParticipantExploreLibrary';
import {
  GamePackAudienceDisclaimer,
  GamePackDifficulty,
  GamePackDuration,
  GamePackPlayerRange,
} from '../Game/GamePack';
import { GamePackV2PlaybackConfigurator } from '../Game/GamePack/GamePackPlaybackConfigurator';
import { intoBlockIndex } from '../Game/Playback/intoPlayback';
import { GameCover, GamePackCover } from '../Game/Utilities';
import { GameIcon } from '../icons/GameIcon';
import {
  useOndGameUIControl,
  useOndGameUIControlState,
} from '../OnDGameUIControl';
import { type IFeatureChecker } from '../Product/FeatureChecker';
import { type VenueTrimmedEvent } from '../Venue';
import { OnDPlayNowButton, ShadowGamePackText } from './BannerGame';
import { useOrg } from './hooks';
import { type LobbyWidgetProps } from './LobbyGameWidgets';
import { useLobbyAPI } from './Provider';
import { type LobbyDecisionData } from './useLobbyDecisionData';

const GameOrGamePackCover = ({
  gameOrGamePack,
}: {
  gameOrGamePack: Game | GamePack | null;
}): JSX.Element => {
  if (!gameOrGamePack) {
    return (
      <div
        className={`w-full h-full bg-dark-gray game-cover-clip p-0.5 rounded`}
      >
        <div
          className='
            w-full h-full rounded
            flex flex-col items-center justify-center
            bg-dark-gray game-cover-clip object-cover
            font-bold text-xl
          '
        ></div>
      </div>
    );
  }

  if (gameOrGamePack.type === 'game') {
    return <GameCover game={gameOrGamePack} />;
  }

  return <GamePackCover pack={gameOrGamePack} />;
};

function OnDPlayPanel(props: {
  pack: Game | GamePack | null;
  isCoordinator: boolean;
  coordinator: Participant | null;
  featureChecker?: IFeatureChecker;
}): JSX.Element | null {
  const ondUICtrl = useOndGameUIControl();
  const ondUICtrlState = useOndGameUIControlState();
  const lobbyAPI = useLobbyAPI();
  const [offboarding, setOffboarding] = useState<boolean>(false);
  const [offboardingPackId, setOffboardingPackId] = useState<string>('');

  useLayoutEffect(() => {
    return lobbyAPI.offBoardingSignal.connect({
      name: 'offboarding',
      after: async () => {
        setOffboarding(true);
        if (props.pack?.id) setOffboardingPackId(props.pack.id);
      },
    });
  }, [lobbyAPI.offBoardingSignal, props.pack?.id]);

  if (!props.isCoordinator) {
    return null;
  }

  return (
    <div className='w-full flex flex-col mt-2.5 items-center justify-center'>
      <div className='w-full h-10 relative'>
        {ondUICtrl && (
          <OnDPlayNowButton
            ondUICtrl={ondUICtrl}
            variant={'primary'}
            gameOrGamePack={props.pack}
            disabled={ondUICtrlState.actionDisabled}
            replay={
              offboarding &&
              props.pack?.type === 'gamePack' &&
              props.pack.replayable &&
              offboardingPackId === props.pack.id
            }
            featureChecker={props.featureChecker}
          />
        )}
      </div>
    </div>
  );
}

function LiveSecondaryTitle() {
  return (
    <p className='text-sm text-tertiary gap-1'>
      <span className='font-bold'>Hang out with your team! </span>
      <span>The host will randomize the teams and start the game shortly!</span>
    </p>
  );
}

function ParticipantExploreLibrary(props: {
  isCoordinator: boolean;
  isOndGame: boolean;
}) {
  const handleExploreLibrary = useOndOpenParticipantExploreLibrary();
  if (props.isCoordinator || !props.isOndGame) return null;
  return (
    <button
      type='button'
      className='btn-secondary px-3 lp-sm:px-4 h-8 text-xs lp-sm:text-sms flex items-center justify-center gap-1'
      onClick={handleExploreLibrary}
    >
      <GameIcon className='w-3.5 h-3.5 lp-sm:w-4 lp-sm:h-4 fill-current' />
      Explore Library
    </button>
  );
}

function ConfigurableLobbyGameBanner(props: LobbyWidgetProps) {
  const isCoordinator = props.userIsCoordinator;
  const ondUICtrl = useOndGameUIControl();

  return (
    <div className={`relative w-full p-5`}>
      <div className='flex min-w-0 w-full justify-around gap-5'>
        <div className='flex-none flex flex-col items-center'>
          <div className='w-32 h-18 lp-sm:w-40 lp-sm:h-22.5'>
            <GameOrGamePackCover gameOrGamePack={props.gameOrGamePack} />
          </div>
          {isCoordinator && getFeatureQueryParam('lobby-switch-game') ? (
            <button
              type='button'
              className='btn text-sms text-icon-gray'
              onClick={() => ondUICtrl?.onClickGameExplore()}
            >
              {props.gameOrGamePack ? '(Change Game)' : '(Find a Game)'}
            </button>
          ) : (
            <></>
          )}
        </div>

        <div className='flex-1 overflow-hidden flex flex-col gap-2.5'>
          <div className='flex items-start justify-between'>
            <div className='flex-1 text-xl font-medium'>
              {props.gameOrGamePack
                ? props.gameOrGamePack.name
                : 'No Game Loaded'}
            </div>
            <ParticipantExploreLibrary
              isCoordinator={isCoordinator}
              isOndGame={props.isOndGame}
            />
          </div>
          {props.isOndGame &&
            props.gameOrGamePack &&
            props.gameOrGamePack.type === 'gamePack' && (
              <>
                <div className='flex items-center gap-x-2.5 flex-wrap text-icon-gray'>
                  <GamePackPlayerRange pack={props.gameOrGamePack} />
                  <GamePackDuration pack={props.gameOrGamePack} />
                  <GamePackDifficulty pack={props.gameOrGamePack} />
                </div>

                <UnitSelector decisionData={props} ondUICtrl={ondUICtrl} />

                <GamePackAudienceDisclaimer
                  audience={props.gameOrGamePack?.detailSettings?.audience}
                />
              </>
            )}

          {props.gameOrGamePack && !props.isOndGame && <LiveSecondaryTitle />}
        </div>

        {props.isOndGame && props.userIsCoordinator && (
          <div className='flex-none flex flex-col'>
            <OnDPlayPanel
              pack={props.gameOrGamePack}
              isCoordinator={props.userIsCoordinator}
              coordinator={props.coordinator}
              featureChecker={props.featureChecker}
            />
          </div>
        )}
      </div>
    </div>
  );
}

function UnitSelector(props: {
  ondUICtrl: ReturnType<typeof useOndGameUIControl>;
  decisionData: LobbyDecisionData;
}) {
  const targetId = props.decisionData.userIsCoordinator
    ? props.decisionData.coordinator?.id ?? null
    : null;

  const blocksChangedKey = props.decisionData.gamePackPlus?.blocks
    ?.map((b) => `${b.id}-${b.updatedAt}`)
    .join(':');
  const index = useMemo(
    () =>
      blocksChangedKey &&
      props.decisionData.gamePackPlus?.blocks &&
      props.decisionData.gamePackPlus?.gamePack
        ? intoBlockIndex(
            props.decisionData.gamePackPlus?.blocks,
            props.decisionData.gamePackPlus?.gamePack
          )
        : null,
    [
      blocksChangedKey,
      props.decisionData.gamePackPlus?.blocks,
      props.decisionData.gamePackPlus?.gamePack,
    ]
  );

  const onClickChooseNextGamePack = props.ondUICtrl?.onClickChooseNextGamePack;
  const gamePackId = props.decisionData.gamePackPlus?.gamePack?.id;
  const playbackSettings =
    props.decisionData.gamePackPlus?.gamePack?.playbackSettings;

  useEffect(() => {
    if (!playbackSettings && gamePackId) {
      // we likely have a v1 game pack, which has no configuration. We need to
      // enqueue the game pack into the uictrl, just like the configurator does.
      onClickChooseNextGamePack?.(gamePackId);
    }
  }, [gamePackId, onClickChooseNextGamePack, playbackSettings]);

  if (
    !targetId ||
    !props.ondUICtrl ||
    !props.decisionData.gamePackPlus ||
    !props.decisionData.gamePackPlus.gamePack
  )
    return null;

  const {
    brands,
    gamePack: { replayable },
    blockPlayedHistory,
  } = props.decisionData.gamePackPlus;

  const playedHistory = blockPlayedHistory?.[targetId];

  if (!index?.blocks || !brands || !gamePackId || !playbackSettings)
    return null;

  return (
    <GamePackV2PlaybackConfigurator
      blocks={index.blocks}
      brands={brands}
      playbackSettings={playbackSettings}
      playedHistory={playedHistory}
      replayable={replayable}
      onChange={(info) => {
        if (!info.requestedUnitCount || !info.selectedUnits) return;

        onClickChooseNextGamePack?.(gamePackId, {
          playHistoryTargetId: targetId,
          subscriberId: targetId,
          startUnitIndex: info.startUnitIndex,
          requestedUnitCount: info.requestedUnitCount,
        });
      }}
    />
  );
}

function WelcomeBanner(props: { event: VenueTrimmedEvent }) {
  const { event } = props;
  const { data: org } = useOrg(event.orgId ?? undefined);

  const logoSrc = MediaUtils.PickMediaUrl(org?.logo, {
    priority: [MediaFormatVersion.SM],
  });

  return (
    <div className={`relative w-full p-5`}>
      <div className='flex min-w-0 w-full justify-around gap-5'>
        {logoSrc && (
          <div className='flex-shrink w-24 h-24'>
            {
              <img
                className='rounded-2xl'
                src={logoSrc}
                alt={org?.name ?? event.orgName ?? undefined}
              />
            }
          </div>
        )}
        <div className='flex-1 flex flex-col gap-1'>
          <p className='text-xl font-medium'>
            Welcome {org?.name ?? event.orgName}
          </p>
          <LiveSecondaryTitle />
        </div>
      </div>
    </div>
  );
}

export function LobbyGameConsole(props: LobbyWidgetProps): JSX.Element {
  return (
    <div>
      {!props.isOndGame && props.event && !props.userIsHostClient ? (
        <WelcomeBanner event={props.event} />
      ) : (
        <>
          <ConfigurableLobbyGameBanner {...props} />
          {props.isOndGame && props.shadowGamePacksInfo && (
            <div className='pr-5 pl-5 pb-2'>
              <ShadowGamePackText info={props.shadowGamePacksInfo} />
            </div>
          )}
        </>
      )}
    </div>
  );
}
