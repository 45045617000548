import { getStaticAssetPath } from '../../utils/assets';
import { uncheckedIndexAccess_UNSAFE } from '../../utils/uncheckedIndexAccess_UNSAFE';
import PointsSpriteSheetInfo from './sprites-output/points-sprite-sheet.json';

const AssetRegistry = [
  {
    name: 'points-sprite-sheet.png',
    path: getStaticAssetPath(`images/points-sprite-sheet.png`),
  },
] as const;

type AssetNames = (typeof AssetRegistry)[number]['name'];

type LoadedAsset = {
  path: string;
  name: string;
  img: HTMLImageElement;
};

export class AssetMap {
  loadedAssets: { [key: string]: LoadedAsset } = {};

  async preload(opt_onlyLoad: Set<AssetNames> = new Set()): Promise<void> {
    await Promise.all(
      AssetRegistry.map(async (u) => {
        if (
          this.loadedAssets[u.name] ||
          (opt_onlyLoad.size > 0 && !opt_onlyLoad.has(u.name))
        )
          return;

        const img = await loadImage(u.path);
        this.loadedAssets[u.name] = { path: u.path, name: u.name, img };
      })
    );
  }

  getImage(name: AssetNames): HTMLImageElement {
    const asset = this.loadedAssets[name];
    if (!asset) throw new Error(`Asset ${name} not loaded!`);
    return asset.img;
  }
}

function loadImage(path: string): Promise<HTMLImageElement> {
  return new Promise((resolve, reject) => {
    const i = new Image();
    i.onload = () => resolve(i);
    i.onerror = reject;
    i.src = path;
  });
}

export type SpriteNames = keyof typeof PointsSpriteSheetInfo.animations;
export { PointsSpriteSheetInfo };

export interface FrameInfo {
  frame: FrameBox;
  spriteSourceSize: FrameBox;
  sourceSize: { w: number; h: number };
  rotated: boolean;
  trimmed: boolean;
}

export interface FrameBox {
  x: number;
  y: number;
  h: number;
  w: number;
}

export interface SpriteSheetMeta<
  SpriteName extends string,
  FrameCount extends number
> {
  frames: {
    [K in `${SpriteName}-${string}`]: FrameInfo;
  };
  animations: {
    [K in SpriteName]: string[] & { length: FrameCount };
  };
  meta: {
    image: string;
    size: { w: number; h: number };
  };
}

export class FrameAnimatedSprite<S extends string, F extends number> {
  private frame = 0;

  constructor(
    private sheetMeta: SpriteSheetMeta<S, F>,
    public readonly spriteName: S,
    public readonly loop: boolean,
    private frameCount: F = sheetMeta.animations[spriteName].length
  ) {}

  get numberOfFrames(): number {
    return this.frameCount;
  }

  advance(): void {
    this.frame += 1;
    if (this.frame >= this.frameCount && this.loop) {
      this.frame = 0;
    }
  }

  getFrame(): FrameInfo | null {
    const animation = this.sheetMeta.animations[this.spriteName];
    if (!animation || this.frame > animation.length) return null;
    const frameName = animation[this.frame];
    const frame = uncheckedIndexAccess_UNSAFE(this.sheetMeta.frames)[frameName];
    if (frame) return frame;
    return null;
  }
}
