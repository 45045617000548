export interface OutputParser<T> {
  parse: (text: string) => T;
}

export class JSONOutputParser implements OutputParser<unknown> {
  parse(text: string) {
    // TODO(jialin): Greedy search for 1st json candidate.
    return JSON.parse(text);
  }
}
