import { Link } from '@remix-run/react';
import { format } from 'date-fns';
import pluralize from 'pluralize';
import useSWR, { type SWRResponse } from 'swr';

import {
  type DtoBasicProgram,
  type DtoChannelProgramLink,
  type EnumsProgramType,
} from '@lp-lib/api-service-client/public';

import { apiService } from '../../services/api-service';
import { err2s } from '../../utils/common';
import {
  type SlackBindingStepConfigOverride,
  type SlackModalBinding,
} from '../Channel';
import { useUpcomingGlobalRound } from '../GlobalPairingTournament';
import { Loading } from '../Loading';
import { PAIRS_INTRO_MEDIA } from '../MediaUploader/consts';

type PredefinedProgramType =
  | 'global_pairing'
  | 'game_drops'
  | 'birthday_and_celebrations'
  // this is only used for the compatibility so that we can use the same create
  // channel flow even without a program
  | 'dummy';

function UpcomingGlobalRoundDate(): JSX.Element | null {
  const { data, error, isValidating } = useUpcomingGlobalRound();

  if (isValidating) {
    return (
      <Loading containerClassName='inline' text='' imgClassName='w-5 h-5' />
    );
  } else if (error) {
    return <span className='text-red-002 text-xs'>{err2s(error)}</span>;
  } else {
    return (
      <span className='text-tertiary font-medium'>
        {data ? format(new Date(data.startedAt), 'eeee, MMMM do') : 'Soon!'}
      </span>
    );
  }
}

function NewInviteesMessage(props: {
  binding: SlackModalBinding;
}): JSX.Element | null {
  const { binding } = props;
  if (!binding.newInvitees?.length) return null;
  let members = binding.newInvitees
    .slice(0, 3)
    .map((u) => `${u.firstName} ${u.lastName}`)
    .join(', ');
  const num = binding.newInvitees.slice(3).length;
  if (num > 0) {
    members = `${members}, and ${num} ${pluralize('other', num)}`;
  }
  return (
    <div className='text-secondary'>
      {members} that you invited to #{binding.channel?.name} were not Luna Park
      members, so we invited them.
    </div>
  );
}

function GlobalPairingBindingDone(props: {
  binding: SlackModalBinding;
}): JSX.Element {
  return (
    <section className='w-full items-center text-left text-sms my-10'>
      <div>Congrats on setting up your Luna Park channel on Slack.</div>
      <div className='mt-6'>A few things to note:</div>
      <ul className='list-disc ml-4'>
        {props.binding.sharedMessage && (
          <li>
            Your message has been shared out via #
            {props.binding.slackChannel?.name} on Slack
          </li>
        )}
        <li>
          Anyone you add to the #{props.binding.slackChannel?.name} Slack
          Channel will be invited to Luna Park
        </li>
        <li>
          We’ll be kicking off the next random pairing round{' '}
          <UpcomingGlobalRoundDate />
        </li>
      </ul>
      <div className='my-6'>
        <a
          target='_blank'
          href='https://lunapark.com/luna-park-pairs/'
          rel='noreferrer'
          className='font-bold text-primary'
        >
          Learn More
        </a>
      </div>
      <NewInviteesMessage binding={props.binding} />
    </section>
  );
}

function GameDropsBindingDone(props: {
  binding: SlackModalBinding;
}): JSX.Element {
  return (
    <section className='w-full items-center text-left text-sms my-5'>
      <div className='mt-4'>
        You can add as many team members as you’d like to the channel.
      </div>
      <div className='mt-4'>
        Here are some benefits of being in the Game Drops Channel:
      </div>
      <ul className='list-disc ml-4 mb-4'>
        <li>Get notified about new games added to the library</li>
        <li>
          Receive game recommendations for organizing your own On Demand games
          using our Event Scheduler
        </li>
      </ul>
      <NewInviteesMessage binding={props.binding} />
    </section>
  );
}

function DummyBindingDone(props: { binding: SlackModalBinding }): JSX.Element {
  return (
    <section className='w-full items-center text-left text-sms my-10'>
      <div>Congrats on setting up your Luna Park channel on Slack.</div>
      <div className='mt-6'>A few things to note:</div>
      <ul className='list-disc ml-4'>
        {props.binding.sharedMessage && (
          <li>
            Your message has been shared out via #
            {props.binding.slackChannel?.name} on Slack
          </li>
        )}
        <li>
          Anyone you add to the #{props.binding.slackChannel?.name} Slack
          Channel will be invited to Luna Park
        </li>
        <li>
          Please checkout our{' '}
          <Link to='/programs' className='font-bold text-primary'>
            Programs
          </Link>{' '}
          to see what else you can do with Luna Park
        </li>
      </ul>
      <NewInviteesMessage binding={props.binding} />
    </section>
  );
}

const PROGRAM_SLACK_BINDING_OVERRIDES: {
  [K in PredefinedProgramType]: SlackBindingStepConfigOverride | undefined;
} = {
  global_pairing: {
    bindingDoneModal: {
      description: GlobalPairingBindingDone,
    },
    shareMessageModal: {
      enabled: true,
      initialText: `Welcome to Luna Park Global Pairing Tournaments! 🚀

Every other month, you’ll be randomly paired to collaborate in a 10-minute game 🕹️

We'll notify you when the next round is about to kick off ⏰

You can opt-out of any round, and we'll automatically re-pair your partner 😉

That's it! Now, it’s time to top the Luna Park Pairs leaderboard! 🏆
`,
      attachedMedia: PAIRS_INTRO_MEDIA,
      mediaTitle: 'Luna Park Pairs Intro.mp4',
    },
  },
  game_drops: {
    bindingDoneModal: {
      description: GameDropsBindingDone,
    },
  },
  birthday_and_celebrations: {
    initStep: 'bind-exist',
    bindingExistModal: {
      description: () => null,
      backButtonText: 'Create a new Channel',
    },
    bindingDoneModal: {
      enabled: false,
    },
  },
  dummy: {
    bindingDoneModal: {
      description: DummyBindingDone,
    },
  },
};

export function getSlackBindingStepConfigOverride(
  type: EnumsProgramType
): SlackBindingStepConfigOverride | undefined {
  return PROGRAM_SLACK_BINDING_OVERRIDES[type as PredefinedProgramType];
}

export function useActivePrograms() {
  return useSWR('/programs/active', async () => {
    const resp = await apiService.program.getActivePrograms();
    return resp.data.activePrograms;
  });
}

export function useBasicProgramFromLink(
  link: DtoChannelProgramLink
): SWRResponse<DtoBasicProgram | null> {
  return useSWR(`/programs/${link.programId ?? link.programType}`, async () => {
    if (link.basicProgram) return link.basicProgram;
    if (link.programId) {
      const resp = await apiService.program.getProgram(link.programId);
      return resp.data.program;
    }
    return null;
  });
}

export function useProgramRounds(parentId: string) {
  return useSWR(`/program-rounds?parentId=${parentId}`, async () => {
    const resp = await apiService.program.queryRounds(parentId);
    return resp.data.programRounds;
  });
}
