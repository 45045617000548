import { Link } from '@remix-run/react';
import { useNavigate } from '@remix-run/react';
import { useCallback } from 'react';
import { useTitle } from 'react-use';
import useSWRImmutable from 'swr/immutable';

import waveImg from '../../assets/img/Wave.png';
import { useAsyncCall } from '../../hooks/useAsyncCall';
import { useQueryParam } from '../../hooks/useQueryParam';
import logger from '../../logger/logger';
import { apiService, type VerifyType } from '../../services/api-service';
import { assertExhaustive, makeTitle } from '../../utils/common';
import { GlobalLoading } from '../GlobalLoading';
import { Loading } from '../Loading';
import {
  buildSearchParamsWithRedirectToAsString,
  usePostLogin,
  useRedirectTo,
} from './hooks';
import { LoginHeader, LoginModalLayoutV2 } from './LoginModalLayout';
import { type LoginSuccessCallback } from './types';

const log = logger.scoped('login-modal-verify');

const InvalidLinkModal = ({
  type,
}: {
  type: VerifyType;
}): JSX.Element | null => {
  const redirectTo = useRedirectTo();
  let title = '';
  switch (type) {
    case 'login':
      title = 'Invalid or expired link';
      break;
    case 'invite':
      title = 'Invalid Invitation Link';
      break;
    default:
      assertExhaustive(type);
  }

  let content = <></>;
  switch (type) {
    case 'login':
      content = (
        <>
          <p>Sorry, this link has either already been used or has expired.</p>
          <p>Please log in again to receive a new link.</p>
        </>
      );
      break;
    case 'invite':
      content = (
        <>
          <p>Sorry, this link has either already been used or has expired.</p>
          <p>
            If you feel this is a mistake, please contact your account
            administrator, or reach out to us{' '}
            <a
              href='https://lunapark.com/contact/'
              target='_blank'
              rel='noreferrer'
            >
              here
            </a>{' '}
            to create an account.
          </p>
        </>
      );
      break;
    default:
      assertExhaustive(type);
  }

  return (
    <LoginModalLayoutV2>
      <div className='absolute text-white w-full flex justify-center'>
        <LoginHeader noSlogan />
        <div
          className='w-max h-full flex flex-col items-center bg-black
    bg-opacity-80 border border-secondary rounded-2.25xl 
    py-10 px-5 lg:px-15 gap-10'
        >
          <div className='w-80 lg:w-100 h-60 flex flex-col items-center justify-center gap-4'>
            <div className='font-bold'>{title}</div>
            <div className='text-sms text-center'>{content}</div>
            <div className='font-medium text-sms text-icon-gray'>
              <Link
                to={{
                  pathname: '/login',
                  search: buildSearchParamsWithRedirectToAsString(redirectTo),
                }}
              >
                Go to Login Options
              </Link>
            </div>
          </div>
        </div>
      </div>
    </LoginModalLayoutV2>
  );
};

const UserVerifyActionModal = (props: {
  code: string;
  verifyType: VerifyType;
  onVerify: LoginSuccessCallback;
}): JSX.Element => {
  const {
    state: { transformed: state },
    error,
    call,
  } = useAsyncCall(
    useCallback((code: string) => {
      return apiService.auth.verifyCallback({ code });
    }, [])
  );

  const handleClick = async () => {
    const resp = await call(props.code);
    if (!resp) {
      return;
    }

    props.onVerify(resp.data);
  };

  if (error) {
    log.error('auth verification failed', error);
    return <InvalidLinkModal type={props.verifyType} />;
  }

  return (
    <LoginModalLayoutV2>
      <div className='absolute text-white w-full flex justify-center'>
        <LoginHeader noSlogan />
        <div
          className='w-max h-full flex flex-col items-center bg-black
    bg-opacity-80 border border-secondary rounded-2.25xl 
    py-10 px-5 lg:px-15 gap-10'
        >
          <div className='w-80 lg:w-100 flex flex-col items-center justify-center gap-4'>
            <div className='text-tertiary text-3.5xl'>Welcome Back!</div>
            <img src={waveImg} alt='wave' className='w-29 h-29' />
            <div className='font-bold text-center'>
              Let’s get you right into your game
            </div>
            <div className='text-sms'>Click the button below to join</div>
            <button
              className='w-full h-12.5 flex items-center justify-center btn-primary'
              onClick={handleClick}
              type='button'
            >
              {state.isRunning && <Loading text='' containerClassName='mr-2' />}
              Continue
            </button>
          </div>
        </div>
      </div>
    </LoginModalLayoutV2>
  );
};

class InvalidVerificationCodeError extends Error {
  name = 'InvalidVerificationCodeError';
}

export const UserVerifyModel = (props: {
  verifyType: VerifyType;
}): JSX.Element | null => {
  useTitle(makeTitle('Login'));

  const code = useQueryParam('code');
  const redirectTo = useRedirectTo();
  const postLogin = usePostLogin();
  const navigate = useNavigate();

  // note: this is not exchanging the code. it only verifies it is valid.
  const { data, error, isValidating } = useSWRImmutable(
    () => (code ? `/verify-code/${code}` : null),
    () =>
      // note: this guard should always evaluate to true since the fetch
      // function is only called if the key returns a non-null value.
      code ? apiService.auth.verifyCode({ code }) : null
  );

  const handleVerify: LoginSuccessCallback = useCallback(
    (resp) => {
      postLogin(resp);

      switch (props.verifyType) {
        case 'login': {
          window.location.replace(redirectTo ?? '/home');
          break;
        }
        case 'invite': {
          navigate('/user-active', { replace: true });
          break;
        }
        default: {
          assertExhaustive(props.verifyType);
          break;
        }
      }
    },
    [props.verifyType, redirectTo, postLogin, navigate]
  );

  if (isValidating) {
    return <GlobalLoading />;
  }

  if (!code || error || !data || data.status === 404) {
    const err = new InvalidVerificationCodeError('verification code invalid', {
      cause: error,
    });
    log.error(err.message, err);
    return <InvalidLinkModal type={props.verifyType} />;
  }

  return (
    <UserVerifyActionModal
      code={code}
      verifyType={props.verifyType}
      onVerify={handleVerify}
    />
  );
};
