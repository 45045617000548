import chunk from 'lodash/chunk';
import shuffle from 'lodash/shuffle';

export function random<T>(items: T[], chunkSize: number, max?: number): T[][] {
  const shuffled = shuffle(items);
  const chunks = chunk(shuffled, chunkSize);
  if (chunks.length > 1) {
    const lastChunk = chunks[chunks.length - 1];
    const checkMax = max !== undefined ? chunkSize < max : true;
    if (lastChunk.length <= Math.floor(chunkSize / 2) && checkMax) {
      const len = lastChunk.length;
      for (let i = 0, j = 0; i < len; i++) {
        chunks[j].push(lastChunk[i]);
        j = j + 1 < chunks.length - 1 ? j + 1 : 0;
      }
      chunks.pop();
    }
  }
  return chunks;
}
