import {
  useGameHostingController,
  useGameHostingCoordinator,
  useGameHostingCoordinatorActive,
} from '../components/Game/GameHostingProvider';
import { useMyClientId } from '../components/Venue/VenuePlaygroundProvider';
export { useMyInstance } from '../components/Player';

/**
 * The controller is the user in the venue that "controls" or "powers" the game.
 *
 * OnD Game:
 *  - controller is either the cloud controller or organizer (local controller).
 * Live Game:
 *  - host is the controller.
 */
export const useIsController = (): boolean => {
  const clientId = useMyClientId();
  const controller = useGameHostingController();
  return clientId === controller?.clientId;
};

/**
 * The coordinator is the user in the venue that can "coordinates" the venue.
 * i.e., they can start/pause/stop the game. When using cloud hosting, this user
 * "coordinates" with the cloud host, i.e., they can issue commands to the cloud
 * host.
 *
 * OnD Game:
 *  - coordinator is organizer.
 * Live Game:
 *  - host is the coordinator.
 */
export const useIsCoordinator = (): boolean => {
  const clientId = useMyClientId();
  const coordinator = useGameHostingCoordinator();
  return clientId === coordinator?.clientId;
};

/**
 * When you need to know when the coordinator has been resolved as well as
 * whether the coordinator is connected/not timed out. This could always change
 * back to null if the coordinator changes!
 */
export const useDecidedIsCoordinator = (): boolean | null => {
  const clientId = useMyClientId();
  const coordinator = useGameHostingCoordinatorActive();
  return coordinator ? clientId === coordinator.clientId : null;
};
