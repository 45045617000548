import ReactSelect, { type Props as ReactSelectProps } from 'react-select';
import {
  type ITimezoneOption,
  type TimezoneSelectOptions,
  useTimezoneSelect,
} from 'react-timezone-select';

/**
 * TimezoneSelect's types are wonky (react-timezone-select@3.2.3,
 * react-select@5.8.0) because it tries to allow for value being `string |
 * ITimezoneOption` aka `ITimezone`, and this trickles all the way into
 * react-select, making the generics for styling get extremely confusing. It was
 * easier to just wrap and force the types to be what they actually are. See
 * https://github.com/ndom91/react-timezone-select/issues/101#issuecomment-1972482675
 */
export function ReactTimezoneSelectTypeFixed(
  props: {
    value: string;
    onChange: (val: ITimezoneOption | null) => void;
  } & TimezoneSelectOptions &
    Omit<ReactSelectProps<ITimezoneOption, false>, 'value' | 'onChange'>
) {
  const {
    value,
    onBlur,
    onChange,
    labelStyle,
    displayValue,
    timezones,
    currentDatetime,
    ...rest
  } = props;

  const { options, parseTimezone } = useTimezoneSelect({
    timezones,
    labelStyle,
    displayValue,
    currentDatetime,
  });

  const handleChange = (tz: ITimezoneOption | null) => {
    onChange && onChange(tz);
  };

  return (
    <ReactSelect
      value={parseTimezone(value)}
      onChange={handleChange}
      options={options}
      onBlur={onBlur}
      {...rest}
    />
  );
}
