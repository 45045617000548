import { useDetailScores } from '../../../hooks';
import { type EditPointData, type SetEditPointsData } from '../../../store';
import { useRankedTeamScores } from '../hooks';
import {
  type ControllerSubmissionActionItem,
  ResultView,
  SubmissionsActionBox,
} from './ControllerSubmissions';

export function ControllerProgress(props: {
  blockId: string;
  setEditPointsData: SetEditPointsData;

  header?: string;
  revealResults?: boolean;
  hideProgressDetail?: boolean;
  progressDetail?: (teamId: string) => string;
  accessoryDetail?: (teamId: string) => React.ReactNode;
  customActionItems?: ControllerSubmissionActionItem[];
}): JSX.Element | null {
  const {
    blockId,
    setEditPointsData,
    header,
    revealResults,
    hideProgressDetail,
    progressDetail,
    accessoryDetail,
    customActionItems,
  } = props;
  const teamDetailScoreMap = useDetailScores(blockId);
  const teamScores = useRankedTeamScores('teamName', teamDetailScoreMap);

  return (
    <div className='relative w-full h-full flex flex-col justify-start items-center'>
      <header className='w-full h-10 px-4 py-1 leading-4 bg-black text-white text-2xs font-medium flex items-center'>
        {header}
      </header>

      <div className='w-full mb-2.5 pt-1 px-2.5 max-h-64 scrollbar-hide overflow-y-scroll'>
        {teamScores.map((score) => {
          const editPointData: EditPointData = {
            teamName: score.team.name,
            teamId: score.team.id,
            submitterUid: '',
            defaultPoints: score.currentScore,
          };
          return (
            <SubmissionsActionBox
              key={score.team.id}
              teamId={score.team.id}
              view={revealResults ? 'result' : undefined}
              editPointData={editPointData}
              setEditPointsData={setEditPointsData}
              customActionItems={customActionItems}
            >
              <div className='w-4/5 h-full pl-2'>
                <p
                  className={`font-bold ${
                    hideProgressDetail
                      ? 'h-full flex items-center'
                      : 'h-1/3 pt-1'
                  }`}
                >
                  {score.team.name}
                </p>
                {!hideProgressDetail && (
                  <div
                    className={`w-full h-2/3 text-xs font-medium ${
                      score.currentScore > 0
                        ? 'text-icon-gray'
                        : 'text-[rgba(255,255,255,0.15)]'
                    } flex flex-col justify-center`}
                  >
                    <p className='w-full line-clamp-2 hyphens-auto'>
                      {progressDetail && progressDetail(score.team.id)}
                    </p>
                  </div>
                )}
              </div>
              <div className='w-1/5 h-full bg-secondary border-l border-secondary flex flex-col justify-center items-center'>
                {props.revealResults ? (
                  <ResultView
                    totalScore={score.totalScore}
                    previewPoints={score.currentScore}
                  />
                ) : accessoryDetail ? (
                  accessoryDetail(score.team.id)
                ) : (
                  <div className='w-8 h-7 text-white font-bold text-2xs text-center flex flex-col justify-center'>
                    <span>
                      {score.currentScore >= 0 && '+'}
                      {score.currentScore}
                    </span>
                    <span className='text-3xs'>Points</span>
                  </div>
                )}
              </div>
            </SubmissionsActionBox>
          );
        })}
      </div>
    </div>
  );
}
