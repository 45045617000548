import logger from '../../../logger/logger';
import { type FirebaseService, FirebaseValueHandle } from '../../Firebase';
import { type PostGameRecommendations } from './types';

export const log = logger.scoped('post-game');

export class PostGameFBUtils {
  static Path(venueId: string, kind: 'root' | 'recommendations'): string {
    if (kind === 'root') return `post-game/${venueId}`;
    return `post-game/${venueId}/${kind}`;
  }

  static RootHandle(
    svc: FirebaseService,
    venueId: string
  ): FirebaseValueHandle<unknown> {
    return new FirebaseValueHandle(
      svc.prefixedSafeRef(this.Path(venueId, 'root'))
    );
  }

  static RecommendationsHandle(
    svc: FirebaseService,
    venueId: string
  ): FirebaseValueHandle<PostGameRecommendations> {
    return new FirebaseValueHandle(
      svc.prefixedSafeRef(this.Path(venueId, 'recommendations'))
    );
  }
}
