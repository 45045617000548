import { useMemo, useState } from 'react';
import Select from 'react-select';

import { buildReactSelectStyles } from '../../../utils/react-select';
import { useGameSessionPreconfigAPI } from '../../Game/Blocks/Common/GamePlay/GameSessionPreconfigProvider';
import { useParticipantsAsArray } from '../../Player';

export interface Option {
  label: string;
  value: string;
}

export function VIPPreConfig(props: {
  onConfirm: () => void;
  onCancel: () => void;
}): JSX.Element {
  const api = useGameSessionPreconfigAPI();
  const participants = useParticipantsAsArray({
    filters: ['status:connected', 'host:false', 'cohost:false', 'staff:false'],
    sorts: ['username:asc'],
  });
  const options = useMemo(() => {
    return participants.map((p) => ({
      label: p.username,
      value: p.id,
    }));
  }, [participants]);

  const styles = useMemo(() => buildReactSelectStyles<Option, true>(), []);
  const [selectedOptions, setSelectedOptions] = useState<Option[]>([]);

  const handleConfirm = () => {
    api.setVIP(selectedOptions.map((o) => o.value));
    props.onConfirm();
  };

  return (
    <div className='p-12 h-full flex flex-col items-center text-white'>
      <p className='text-xl font-bold text-center'>Configure VIP</p>

      <p className='mt-7.5 text-base font-bold text-center'>
        This is a special game pack designed to celebrate an individual.{' '}
      </p>

      <p className='mt-7.5 text-xl font-bold text-center'>
        Who are we celebrating today?
      </p>

      <p className='mt-7.5 text-base font-normal text-center'>
        Select one or more people who are in the Venue, and they will be
        spotlighted center stage for a special moment!
      </p>

      <Select<Option, true>
        isMulti
        styles={styles}
        className='select-box text-white mt-3 h-14 mb-0 w-full'
        classNamePrefix='select-box-v2'
        options={options}
        value={selectedOptions}
        onChange={(newValue) => {
          setSelectedOptions(newValue.map((v) => v));
        }}
      />

      <button
        type='button'
        disabled={selectedOptions.length === 0}
        onClick={handleConfirm}
        className='mt-7 btn-primary w-40 h-10'
      >
        Yup, Got it!
      </button>

      <button
        type='button'
        onClick={props.onCancel}
        className='mt-auto btn text-icon-gray text-sms hover:bg-lp-gray-001 px-2 py-1 rounded transition-colors'
      >
        Cancel
      </button>
    </div>
  );
}
