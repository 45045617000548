import { useCallback } from 'react';
import useSWR from 'swr';

import { apiService } from '../../services/api-service';
import { type GameScore } from '../../services/api-service/gameScore.api';

export type LobbyLeaderboards = {
  company: GameScore[];
  global: GameScore[];
};

export type UseLobbyLeaderboards = {
  leaderboards: LobbyLeaderboards | undefined;
  isValidating: boolean;
  error: Error | undefined;
  refresh: () => Promise<void>;
};

async function loadGlobalLeaderboard(
  gamePackIds: string | null
): Promise<GameScore[]> {
  if (!gamePackIds) return [];
  const resp = await apiService.gameScore.searchGameScore(gamePackIds, {
    size: 999,
    minScore: 1,
  });
  return resp.data.gameScores;
}

async function loadCompanyLeaderboard(
  gamePackIds: string | null,
  orgId: string | null
) {
  if (!gamePackIds || !orgId) return [];
  const resp = await apiService.gameScore.searchGameScore(gamePackIds, {
    size: 20,
    organizationId: orgId,
    minScore: 1,
    joyCaptures: true,
  });
  return resp.data.gameScores.filter((gs) => gs.userIds !== null);
}

async function loadLeaderboards(
  gamePackIds: string | null,
  orgId: string | null
): Promise<LobbyLeaderboards> {
  const [global, company] = await Promise.all([
    loadGlobalLeaderboard(gamePackIds),
    loadCompanyLeaderboard(gamePackIds, orgId),
  ]);
  return {
    global,
    company,
  };
}

export function useLobbyLeaderboards(
  gamePackIds: string | null,
  orgId: string | null
): UseLobbyLeaderboards {
  const { data, error, isValidating, mutate } = useSWR<
    LobbyLeaderboards,
    Error
  >(
    gamePackIds === null ? null : ['/lobby/leaderboards', gamePackIds, orgId],
    () => loadLeaderboards(gamePackIds, orgId),
    {
      shouldRetryOnError: false,
    }
  );

  const refresh = useCallback(async () => {
    await mutate();
  }, [mutate]);

  return {
    leaderboards: data,
    isValidating,
    error,
    refresh,
  };
}
