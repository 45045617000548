import { useEffect } from 'react';

import { QuestionBlockGameSessionStatus } from '@lp-lib/game';

import { ClientTypeUtils } from '../../../../types';
import { useCohostClientId, useHostClientId } from '../../../Player';
import { StageMode, useStageControlAPI, useStageMode } from '../../../Stage';
import { HeadToHead } from '../../../Stage/HeadToHead';
import { useMyClientType } from '../../../Venue/VenuePlaygroundProvider';
import { useGameSessionBlock, useGameSessionStatus } from '../../hooks';
import { AnchoredGamePlayMediaLayout } from '../Common/GamePlay/Internal';

export function GamePlayH2H(): JSX.Element | null {
  const gameSessionStatus =
    useGameSessionStatus<QuestionBlockGameSessionStatus>();
  const gameSessionBlock = useGameSessionBlock();
  const myClientType = useMyClientType();
  const stageMode = useStageMode();
  const hostClientId = useHostClientId();
  const cohostClientId = useCohostClientId();
  const stageControl = useStageControlAPI();

  // mark the H2H can be used
  useEffect(() => {
    stageControl.toggleH2H(true);
    return () => {
      stageControl.toggleH2H(false);
    };
  }, [stageControl]);

  useEffect(() => {
    if (ClientTypeUtils.isAudience(myClientType)) return;
    return () => {
      stageControl.leaveAll([hostClientId, cohostClientId]);
      stageControl.updateStageMode(StageMode.BOS);
    };
  }, [myClientType, hostClientId, stageControl, cohostClientId]);

  if (stageMode !== StageMode.H2H || !gameSessionBlock) return null;

  return (
    <AnchoredGamePlayMediaLayout
      debugName='gameplayh2h'
      mode='small'
      z={
        gameSessionStatus === QuestionBlockGameSessionStatus.ANSWER
          ? 'z-20'
          : 'z-30'
      }
    >
      {gameSessionStatus === QuestionBlockGameSessionStatus.PRESENTING ||
      gameSessionStatus === QuestionBlockGameSessionStatus.COUNTING ||
      gameSessionStatus === QuestionBlockGameSessionStatus.TIMESUP ||
      gameSessionStatus === QuestionBlockGameSessionStatus.ANSWER ? (
        <HeadToHead />
      ) : null}
    </AnchoredGamePlayMediaLayout>
  );
}
