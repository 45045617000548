import { type SVGProps } from 'react';

export const TeamCaptainScribeBadge = (
  props: SVGProps<SVGElement> & { overrideClassName?: string }
): JSX.Element => {
  return (
    <svg
      role='img'
      aria-labelledby='team-captain-scribe-badge'
      viewBox='0 0 58 25'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={`${
        props.overrideClassName ? props.overrideClassName : 'w-[58px] h-[25px]'
      } ${props.className ?? ''}`}
    >
      <title id='team-captain-scribe-badge'>Team Captain</title>
      <path
        d='M0 4C0 1.79086 1.79086 0 4 0L46.8841 0C48.4971 0 49.9522 0.968824 50.5745 2.45699L57.6824 19.457C58.7843 22.0923 56.8484 25 53.992 25L4 25C1.79086 25 0 23.2091 0 21L0 4Z'
        fill='#FBB707'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M46.8841 1L4 1C2.34315 1 1 2.34315 1 4L1 21C1 22.6569 2.34315 24 4 24L53.992 24C56.1343 24 57.5862 21.8193 56.7598 19.8427L49.6519 2.84274C49.1852 1.72662 48.0938 1 46.8841 1ZM4 0C1.79086 0 0 1.79086 0 4L0 21C0 23.2091 1.79086 25 4 25L53.992 25C56.8484 25 58.7843 22.0923 57.6824 19.457L50.5745 2.45699C49.9522 0.968824 48.4971 0 46.8841 0L4 0Z'
        fill='white'
      />
      <path
        d='M4.2699 6.3182H6.4574V12H8.40342V6.3182H10.5909V4.72729H4.2699V6.3182Z'
        fill='black'
      />
      <path
        d='M11.334 12H16.5613V10.4091H13.3084V9.15911H16.3056V7.5682H13.3084V6.3182H16.5755V4.72729H11.334V12Z'
        fill='black'
      />
      <path
        d='M19.4439 12L19.8629 10.608H22.2635L22.6825 12H24.8132L22.4127 4.72729H19.7138L17.3132 12H19.4439ZM20.3104 9.1307L21.0348 6.73014H21.0916L21.8161 9.1307H20.3104Z'
        fill='black'
      />
      <path
        d='M25.5137 4.72729V12H27.4455V7.76707H27.5023L29.1216 11.9432H30.2864L31.9057 7.79548H31.9625V12H33.8944V4.72729H31.437L29.7466 8.84661H29.6614L27.9711 4.72729H25.5137Z'
        fill='black'
      />
      <path
        d='M11.2585 16.4546C11.1023 14.6506 9.78126 13.6279 7.94887 13.6279C5.98865 13.6279 4.44035 14.9347 4.44035 17.3637C4.44035 19.782 5.94603 21.0995 7.94887 21.0995C10.0796 21.0995 11.1591 19.6222 11.2585 18.3864L9.25569 18.3722C9.15626 18.9936 8.71592 19.3807 7.99149 19.3807C7.02913 19.3807 6.4716 18.6989 6.4716 17.3637C6.4716 16.0995 7.01137 15.3466 8.00569 15.3466C8.76564 15.3466 9.19887 15.7941 9.25569 16.4546H11.2585Z'
        fill='black'
      />
      <path
        d='M13.8189 21L14.2379 19.608H16.6385L17.0575 21H19.1882L16.7877 13.7273H14.0888L11.6882 21H13.8189ZM14.6854 18.1307L15.4098 15.7301H15.4666L16.1911 18.1307H14.6854Z'
        fill='black'
      />
      <path
        d='M19.8887 21H21.8631V18.8409H22.9569C24.6152 18.8409 25.7268 17.8644 25.7268 16.2983C25.7268 14.75 24.6437 13.7273 23.0279 13.7273H19.8887V21ZM21.8631 17.3068V15.304H22.5591C23.2516 15.304 23.6671 15.6733 23.6671 16.2983C23.6671 16.9198 23.2516 17.3068 22.5591 17.3068H21.8631Z'
        fill='black'
      />
      <path
        d='M26.2523 15.3182H28.4398V21H30.3858V15.3182H32.5733V13.7273H26.2523V15.3182Z'
        fill='black'
      />
      <path
        d='M34.2779 21L34.6969 19.608H37.0975L37.5165 21H39.6472L37.2466 13.7273H34.5478L32.1472 21H34.2779ZM35.1444 18.1307L35.8688 15.7301H35.9256L36.65 18.1307H35.1444Z'
        fill='black'
      />
      <path d='M42.3221 13.7273H40.3477V21H42.3221V13.7273Z' fill='black' />
      <path
        d='M49.5504 13.7273H47.576V17.5341H47.5192L44.934 13.7273H43.2578V21H45.2323V17.179H45.2749L47.9027 21H49.5504V13.7273Z'
        fill='black'
      />
    </svg>
  );
};

function ArrowDown() {
  return (
    <path
      d='M14.1 11.89C14.63 11.29 14.27 10.5 13.48 10.5H6.52C5.73 10.5 5.37 11.29 5.9 11.89L9.37 15.79C9.72 16.19 10.28 16.19 10.63 15.79L14.1 11.89Z'
      fill='black'
    />
  );
}

function ArrowUp() {
  return (
    <path
      d='M14.1 14.11C14.63 14.71 14.27 15.5 13.48 15.5H6.52C5.73 15.5 5.37 14.71 5.9 14.11L9.37 10.21C9.72 9.81 10.28 9.81 10.63 10.21L14.1 14.11Z'
      fill='black'
    />
  );
}

function TeamCaptainScribeBadgeClickable(
  props: SVGProps<SVGElement> & { arrow: 'up' | 'down' }
) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 76 25'
      fill='none'
      className={`w-[76px] h-[25px] ${props.className ?? ''}`}
    >
      <path
        fill='#FBB707'
        d='M0 4c0-2.21 2.192-4 4.897-4h56.5c1.974 0 3.756.969 4.517 2.457l8.702 17C75.965 22.092 73.595 25 70.098 25H4.897C2.192 25 0 23.21 0 21V4z'
      ></path>
      <path
        fill='#fff'
        fillRule='evenodd'
        d='M61.396 1h-56.5C2.87 1 1.225 2.343 1.225 4v17c0 1.657 1.645 3 3.673 3h65.201c2.623 0 4.4-2.18 3.388-4.157l-8.701-17C64.213 1.727 62.877 1 61.396 1zm-56.5-1C2.193 0 0 1.79 0 4v17c0 2.21 2.192 4 4.897 4h65.201c3.497 0 5.867-2.908 4.518-5.543l-8.702-17C65.153.969 63.371 0 61.396 0h-56.5z'
        clipRule='evenodd'
      ></path>
      <path
        fill='#000'
        d='M20 6.317h2.188V12h1.946V6.317h2.187v-1.59H20v1.59zM27.064 12h5.227v-1.592H29.04v-1.25h2.997v-1.59h-2.998v-1.25h3.268V4.726h-5.242v7.272zM35.174 12l.419-1.393h2.4l.42 1.392h2.13l-2.4-7.272h-2.7l-2.4 7.272h2.131zm.867-2.87l.724-2.4h.057l.724 2.4h-1.505zM41.244 4.727v7.272h1.932V7.766h.056l1.62 4.177h1.165l1.619-4.148h.057v4.204h1.931V4.727h-2.457l-1.69 4.119h-.085l-1.69-4.12h-2.458zM26.989 16.454c-.157-1.804-1.478-2.827-3.31-2.827-1.96 0-3.508 1.307-3.508 3.736 0 2.418 1.505 3.736 3.508 3.736 2.13 0 3.21-1.478 3.31-2.713l-2.003-.015c-.1.622-.54 1.009-1.264 1.009-.963 0-1.52-.682-1.52-2.017 0-1.264.54-2.017 1.534-2.017.76 0 1.193.447 1.25 1.108h2.003zM29.549 21l.419-1.393h2.4l.42 1.392h2.13l-2.4-7.272h-2.7l-2.4 7.272h2.131zm.867-2.87l.724-2.4h.057l.724 2.4h-1.505zM35.619 21h1.974v-2.16h1.094c1.658 0 2.77-.976 2.77-2.542 0-1.549-1.083-2.571-2.699-2.571h-3.14v7.272zm1.974-3.694v-2.003h.696c.693 0 1.108.37 1.108.995 0 .621-.415 1.008-1.108 1.008h-.696zM41.982 15.318h2.188v5.681h1.946v-5.681h2.187v-1.591h-6.32v1.59zM50.008 21l.419-1.393h2.4l.42 1.392h2.13l-2.4-7.272h-2.7l-2.4 7.272h2.131zm.866-2.87l.725-2.4h.057l.724 2.4h-1.506zM58.052 13.727h-1.974v7.272h1.974v-7.272zM65.28 13.727h-1.974v3.806h-.057l-2.585-3.806h-1.676v7.272h1.974v-3.82h.043l2.628 3.82h1.648v-7.272z'
      ></path>
      {props.arrow === 'up' ? <ArrowUp /> : <ArrowDown />}
    </svg>
  );
}

export const ResponsiveTeamCaptainScribeBadge = (props: {
  teamMemberCount: number;
  arrow: 'up' | 'down';
  className?: string;
  isCurrentUserTheCaptain?: boolean;
}): JSX.Element => {
  const { isCurrentUserTheCaptain } = props;
  const size =
    props.teamMemberCount < 5
      ? 'transform-gpu origin-left scale-85 2xl:scale-100'
      : props.teamMemberCount < 7
      ? 'transform-gpu origin-left scale-[0.75] 2xl:scale-85'
      : 'transform-gpu origin-left scale-[0.65] 2xl:scale-[0.75]';

  if (isCurrentUserTheCaptain) {
    return (
      <TeamCaptainScribeBadgeClickable
        className={`${props.className ?? ''} ${size}`}
        arrow={props.arrow}
      />
    );
  }

  return (
    <TeamCaptainScribeBadge className={`${props.className ?? ''} ${size}`} />
  );
};
