import { useEffect } from 'react';
import { usePreviousDistinct } from 'react-use';
import { proxy } from 'valtio';

import { type Logger } from '@lp-lib/logger-base';
import { ConnectionStatus } from '@lp-lib/shared-schema';

import logger from '../../logger/logger';
import { isReconnecting } from '../../store/utils';
import { type TeamId } from '../../types';
import {
  markSnapshottable,
  useSnapshot,
  type ValtioSnapshottable,
  ValtioUtils,
} from '../../utils/valtio';
import { type FirebaseService, FirebaseValueHandle } from '../Firebase';
import { useParticipant } from '../Player';
import { type useTeamMembersGetter } from '../TeamAPI/TeamV1';

type State = {
  currentPlayerId: Nullable<string>;
};

export class RoundRobinPlayerAPI {
  private _state;
  constructor(
    svc: FirebaseService,
    readonly teamId: TeamId,
    readonly path: string,
    private selectTeamMembers: ReturnType<typeof useTeamMembersGetter>,
    private log: Logger = logger.scoped('round-robin-player'),
    private handle = new FirebaseValueHandle<Nullable<State>>(
      svc.prefixedSafeRef(path)
    )
  ) {
    this._state = markSnapshottable(proxy<State>(this.initialState()));
  }

  get state(): Readonly<ValtioSnapshottable<State>> {
    return this._state;
  }

  on() {
    this.handle.on((val) => {
      if (!val) return;
      ValtioUtils.update(this._state, val);
    });
  }

  off() {
    this.handle.off();
  }

  async nextPlayer(debug?: string) {
    const sortedMembers = this.sortedMembers();
    const pickedMember = sortedMembers[0];
    this.log.info('picked member', {
      teamId: this.teamId,
      pickedMember,
      debug,
    });
    if (!pickedMember) return;
    await this.handle.set({ currentPlayerId: pickedMember.id });
    return pickedMember;
  }

  private sortedMembers() {
    const members = this.selectTeamMembers(this.teamId);
    if (!members) return [];
    const ordered = members.sort((a, b) => {
      if (a.joinedAt !== b.joinedAt) return a.joinedAt - b.joinedAt;
      return a.id.localeCompare(b.id);
    });
    const index = ordered.findIndex((m) => m.id === this.state.currentPlayerId);

    if (index === -1) return ordered;
    return [...ordered.slice(index + 1), ...ordered.slice(0, index + 1)];
  }

  async reset() {
    this.log.info('reset', { teamId: this.teamId });
    await this.handle.remove();
    ValtioUtils.reset(this._state, this.initialState());
  }

  private initialState(): State {
    return {
      currentPlayerId: null,
    };
  }
}

export function useCurrentPlayerId(api: RoundRobinPlayerAPI) {
  return useSnapshot(api.state).currentPlayerId;
}

export function useCurrentPlayer(api: RoundRobinPlayerAPI) {
  const currentPlayerId = useCurrentPlayerId(api);
  return useParticipant(currentPlayerId);
}

export function useAutoPickNextPlayerIfCurrentDropped(
  api: RoundRobinPlayerAPI,
  enabled = false,
  ingoreRecconecting = false
) {
  const currentPlayerId = useCurrentPlayerId(api);
  const currPlayer = useParticipant(currentPlayerId);
  const prevPlayer = usePreviousDistinct(currPlayer);
  const dropped = prevPlayer && !currPlayer;
  const disconnected =
    currPlayer &&
    currPlayer.status === ConnectionStatus.Disconnected &&
    (ingoreRecconecting ? true : !isReconnecting(currPlayer));
  useEffect(() => {
    if (!enabled) return;
    if (dropped || disconnected) {
      api.nextPlayer('auto-pick-next-player-if-current-dropped');
    }
  }, [api, dropped, disconnected, enabled]);
}
