import { getFeatureQueryParamArray } from '../../hooks/useFeatureQueryParam';
import { useLiveCallback } from '../../hooks/useLiveCallback';
import * as v1 from './v1';
import * as v2 from './v2';

const v1Enabled = getFeatureQueryParamArray('psv') === 'v1';

export const PlayerStoreProvider = v1Enabled
  ? v1.PlayerStoreProvider
  : v2.PlayerStoreProvider;

export const {
  useMyInstance,
  useMyInstanceGetter,
  useMyTeamId,
  useParticipantByClientId,
  useHost,
  useHostClientId,
  useAllHostClientIds,
  useUpdateParticipant,
  useLastJoinedParticipantByUserId,
  useParticipantByUserId,
  useParticipantsByClientIds,
  useParticipantByUserIds,
  useIsHeartbeatExpired,
  useIsParticipantStoreInited,
  useParticipantJoin,
  useParticipantLeave,
  useInitParticipantStore,
  useSyncParticipants,
  useParticipantsGetter,
  useParticipantsFlagsGetter,
  useParticipantFlagsGetter,
  useParticipants,
  useParticipantsFlags,
  useParticipantFlags,
  useParticipantFlag,
  useParticipantSkim,
  useLastJoinedParticipantGetter,
  useUpdateNetworkQuality,
  useParticipantNetworkQuality,
  useTrackAgoraNumericUid,
} = v1Enabled ? v1 : v2;

export function useHostClientIdGetter() {
  const id = useHostClientId();
  return useLiveCallback(() => id);
}

export function useAllHostClientIdsGetter() {
  const ids = useAllHostClientIds();
  return useLiveCallback(() => ids);
}

export type {
  ParticipantFilterValue,
  ParticipantSorterValue,
  ParticipantSkimFilterKey,
  ParticipantFlagFilterKey,
} from './filter-sorting';

export { ParticipantSyntheticFilterMatchers } from './filter-sorting';

export { useSpectatorFlag } from './useSpectatorFlag';
export {
  useCohost,
  useCohostClientId,
  useCohostClientIdGetter,
  useCohostGetter,
  useCohostsGetter,
  useCohosts,
  useAmICohost,
  useAmICohostGetter,
} from './useCohost';
export {
  createParticipantListFromClientIds,
  createParticipantListFromUids,
} from './v1';
