import { useLayoutEffect } from 'react';
import { proxy, ref } from 'valtio';

import { type IMediaDeviceRTCService } from '../../services/webrtc';
import { MicVolumeMeterProcessor } from '../../utils/MicVolumeMeter';
import { markSnapshottable, useSnapshot } from '../../utils/valtio';

type Store = {
  processor: MicVolumeMeterProcessor | null;
};

const store = markSnapshottable(proxy<Store>({ processor: null }));

function resetStore() {
  store.processor?.stop();
  store.processor = null;
}

export function useWebRTCMVMProcessor() {
  const processor = useSnapshot(store).processor;
  return processor as MicVolumeMeterProcessor | null;
}

export function useInitWebRTCMVMProcessor(rtcService: IMediaDeviceRTCService) {
  useLayoutEffect(() => {
    const updateProcessor = () => {
      resetStore();
      const volumeMeter = rtcService.getVolumeMeter();
      if (!volumeMeter) return;
      store.processor = ref(new MicVolumeMeterProcessor(volumeMeter));
      store.processor.start();
    };
    const off1 = rtcService.on('audio-toggled', updateProcessor);
    const off2 = rtcService.on('audio-switched', updateProcessor);
    return () => {
      off1();
      off2();
      resetStore();
    };
  }, [rtcService]);

  useLayoutEffect(() => {
    return () => resetStore();
  }, []);
}
