import { useCallback } from 'react';

import { SessionMode } from '../../types';
import { type Option, Select } from '../common/Utilities';
import {
  ConfirmCancelModalHeading,
  useAwaitFullScreenConfirmCancelModal,
} from '../ConfirmCancelModalContext';
import {
  useLocalDerivedBlockRecorderVersion,
  useLocalDerivedOndPlaybackVersion,
} from '../Game/GamePlayStore';
import {
  useBlockRecorderVersion,
  useSetBlockRecorderVersion,
} from '../GameRecorder/BlockRecorderProvider';
import { CameraIcon } from '../icons/CameraIcon';
import { Control } from './Utilities';

const recorderOptions = [
  {
    label: 'v1',
    value: 1,
  },
  {
    label: 'v3',
    value: 3,
  },
];

export const RecorderDetails = (): JSX.Element => {
  const recorderVersion = useBlockRecorderVersion();
  const derivedPlaybackVersion = useLocalDerivedOndPlaybackVersion(
    SessionMode.Live
  );
  const recommendedRecorderVersion = useLocalDerivedBlockRecorderVersion(
    SessionMode.Live
  );
  const setRecorderVersion = useSetBlockRecorderVersion();
  const confirmCancel = useAwaitFullScreenConfirmCancelModal();

  const handleChange = useCallback(
    async (opt: Option<number>) => {
      if (opt.value === recorderVersion) return;

      if (opt.value !== recommendedRecorderVersion) {
        const response = await confirmCancel({
          kind: 'confirm-cancel',
          prompt: (
            <div className='text-white flex-col items-center justify-center py-2 px-4'>
              <ConfirmCancelModalHeading>
                Are you sure?
              </ConfirmCancelModalHeading>
              <div className='text-sms text-center my-2'>
                We recommend using version {recommendedRecorderVersion}. You may
                change the recorder version, but blocks with different recording
                versions may not work as expected.
              </div>
            </div>
          ),
          confirmBtnLabel: 'Continue',
          cancelBtnLabel: 'Cancel',
          autoFocus: 'cancel',
        });

        if (response.result === 'confirmed') {
          setRecorderVersion(opt.value);
        }
      } else {
        setRecorderVersion(opt.value);
      }
    },
    [
      recorderVersion,
      setRecorderVersion,
      confirmCancel,
      recommendedRecorderVersion,
    ]
  );

  return (
    <Control
      text='Recorder Version'
      margin='m-0'
      info={
        <div>
          The current recorder version for new recordings. OnD playback version
          is determined by existing recordings and will use{' '}
          <strong className='text-white'>v{derivedPlaybackVersion}</strong>.
        </div>
      }
      icon={<CameraIcon className='w-full h-full fill-current' />}
    >
      <div className='w-12'>
        <Select
          options={recorderOptions}
          onChange={handleChange}
          value={
            recorderOptions.find((o) => o.value === recorderVersion) ??
            recorderOptions[1]
          }
        />
      </div>
    </Control>
  );
};
