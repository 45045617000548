import truncate from 'lodash/truncate';
import { match } from 'ts-pattern';

import {
  type AIChatBlock,
  AIChatBlockGameSessionStatus,
  assertExhaustive,
  type Block,
  type BlockMediaFields,
  type BlockMediaIdFields,
  type BlockSummary,
  BlockType,
  type CreativePromptBlock,
  CreativePromptBlockGameSessionStatus,
  type CreativePromptBlockMedia,
  type DrawingPromptBlock,
  DrawingPromptBlockGameSessionStatus,
  type DrawingPromptBlockMedia,
  type GameSessionStatus,
  type GuessWhoBlock,
  GuessWhoBlockGameSessionStatus,
  type GuessWhoBlockMedia,
  type HeadToHeadBlock,
  HeadToHeadBlockGameSessionStatus,
  type HiddenPictureBlock,
  HiddenPictureBlockGameSessionStatus,
  type HiddenPictureBlockMedia,
  type IcebreakerBlock,
  IcebreakerBlockGameSessionStatus,
  type IcebreakerBlockMedia,
  IcebreakerOnStageSelection,
  type InstructionBlock,
  InstructionBlockGameSessionStatus,
  type InstructionBlockMedia,
  type JeopardyBlock,
  JeopardyBlockGameSessionStatus,
  type MarketingBlock,
  type MemoryMatchBlock,
  MemoryMatchBlockGameSessionStatus,
  type MemoryMatchBlockMedia,
  type MultipleChoiceBlock,
  type MultipleChoiceBlockMedia,
  MultipleChoiceGameSessionStatus,
  type OverRoastedBlock,
  OverRoastedBlockGameSessionStatus,
  type OverRoastedBlockMedia,
  type PuzzleBlock,
  PuzzleBlockGameSessionStatus,
  type PuzzleBlockMedia,
  type QuestionBlock,
  QuestionBlockGameSessionStatus,
  type QuestionBlockMedia,
  type RandomizerBlock,
  type RapidBlock,
  RapidBlockGameSessionStatus,
  type RapidBlockMedia,
  type RapidCorrectAnswer,
  type RoundRobinQuestionBlock,
  RoundRobinQuestionBlockGameSessionStatus,
  type RoundRobinQuestionBlockMedia,
  type ScoreboardBlock,
  ScoreboardMode,
  type SlideBlock,
  type SpotlightBlock,
  type SpotlightBlockMedia,
  type SpotlightBlockV2,
  type TeamRelayBlock,
  TeamRelayBlockGameSessionStatus,
  type TeamRelayBlockMedia,
  type TitleBlockV2,
  type TitleBlockV2Media,
  type VoiceOver,
} from '@lp-lib/game';
import { type Media, MediaTranscodeStatus, MediaType } from '@lp-lib/media';

import { fromMediaDTO } from '../../../utils/api-dto';
import { getStaticAssetPath } from '../../../utils/assets';
import { uncheckedIndexAccess_UNSAFE } from '../../../utils/uncheckedIndexAccess_UNSAFE';
import {
  AIChatBlockIcon,
  CreativePromptBlockIcon,
  DrawingPromptBlockIcon,
  HeadToHeadBlockIcon,
  MemoryMatchBlockIcon,
  MultipleChoiceBlockIcon,
  PuzzleBlockIcon,
  QuestionBlockIcon,
  RapidBlockIcon,
  RoundRobinQuestionBlockIcon,
  ScoreboardBlockIcon,
  SpotlightBlockIcon,
  TeamRelayBlockIcon,
  TitleBlockIcon,
} from '../../icons/Block';
import { GuessWhoBlockIcon } from '../../icons/Block/GuessWhoBlockIcon';
import { HiddenPictureBlockIcon } from '../../icons/Block/HiddenPictureBlockIcon';
import { IcebreakerBlockIcon } from '../../icons/Block/IcebreakerBlockIcon';
import { InstructionBlockIcon } from '../../icons/Block/InstructionBlockIcon';
import { JeopardyBlockIcon } from '../../icons/Block/JeopardyBlockIcon';
import { OverRoastedBlockIcon } from '../../icons/Block/OverRoastedBlockIcon';
import { SlideBlockIcon } from '../../icons/Block/SlideBlockIcon';
import { ShuffleIcon } from '../../icons/ShuffleIcon';
import { TBDIcon } from '../../icons/TBDIcon';
import { VoiceOverUtils } from '../../VoiceOver/utils';
import { parseRapidAllAnswers } from './Rapid/utils';
import { SpotlightBlockUtils } from './Spotlight/utils';

export const BlockChoiceMap: {
  [key in BlockType]: {
    idx: number;
    type: BlockType;
    primary: string;
    secondary: string;
    // true when the block should be hidden in the admin tool.
    hidden?: boolean;
  };
} = {
  [BlockType.QUESTION]: {
    idx: 1,
    type: BlockType.QUESTION,
    primary: 'Open Ended Question',
    secondary: 'Grade answers as players submit',
  },
  [BlockType.CREATIVE_PROMPT]: {
    idx: 3,
    type: BlockType.CREATIVE_PROMPT,
    primary: 'Creative Prompt & Voting',
    secondary: 'Players vote for favorite answer',
  },
  [BlockType.RAPID]: {
    idx: 4,
    type: BlockType.RAPID,
    primary: 'Rapid Answer Submissions',
    secondary: 'Players try to match many answers',
  },
  [BlockType.SCOREBOARD]: {
    idx: 5,
    type: BlockType.SCOREBOARD,
    primary: 'Scoreboard',
    secondary: 'Display Scoreboard & Winners',
  },
  [BlockType.SPOTLIGHT]: {
    idx: 6,
    type: BlockType.SPOTLIGHT,
    primary: 'Spotlight Block V1',
    secondary: 'Feature players on stage w/ media',
  },
  [BlockType.SPOTLIGHT_V2]: {
    idx: 7,
    type: BlockType.SPOTLIGHT_V2,
    primary: 'Spotlight Block V2',
    secondary: 'Feature players on stage w/ media',
  },
  [BlockType.TEAM_RELAY]: {
    idx: 8,
    type: BlockType.TEAM_RELAY,
    primary: 'Team Relay',
    secondary: 'Players match keys in a race for pts',
  },
  [BlockType.RANDOMIZER]: {
    idx: 9,
    type: BlockType.RANDOMIZER,
    primary: 'Team Randomizer',
    secondary: 'Randomize Players into Teams',
  },
  [BlockType.MULTIPLE_CHOICE]: {
    idx: 10,
    type: BlockType.MULTIPLE_CHOICE,
    primary: 'Multiple Choice Question',
    secondary: 'Variations of Multiple Choice',
  },
  [BlockType.MEMORY_MATCH]: {
    idx: 11,
    type: BlockType.MEMORY_MATCH,
    primary: 'Memory Match',
    secondary: 'Players Match Cards from Memory',
  },
  [BlockType.PUZZLE]: {
    idx: 12,
    type: BlockType.PUZZLE,
    primary: 'Puzzle',
    secondary: 'Players put pieces in correct spots',
  },
  [BlockType.ROUND_ROBIN_QUESTION]: {
    idx: 13,
    type: BlockType.ROUND_ROBIN_QUESTION,
    primary: 'Round Robin Q&A',
    secondary: 'Players take turns answering',
  },
  [BlockType.TITLE_V2]: {
    idx: 14,
    type: BlockType.TITLE_V2,
    primary: 'Title Page',
    secondary: 'Display multiple cards sequentially',
  },
  [BlockType.INSTRUCTION]: {
    idx: 15,
    type: BlockType.INSTRUCTION,
    primary: 'Instruction',
    secondary: 'Self-guided Game Instructions',
  },
  [BlockType.OVERROASTED]: {
    idx: 16,
    type: BlockType.OVERROASTED,
    primary: 'Over-Roasted',
    secondary: 'Players fulfill coffee orders ASAP',
  },
  [BlockType.DRAWING_PROMPT]: {
    idx: 17,
    type: BlockType.DRAWING_PROMPT,
    primary: 'Drawing: Guess the Prompt',
    secondary: 'Which prompt inspired the drawing?',
  },
  [BlockType.HIDDEN_PICTURE]: {
    idx: 18,
    type: BlockType.HIDDEN_PICTURE,
    primary: 'Hidden Pictures',
    secondary: 'Find the hidden items in the picture',
  },
  [BlockType.AI_CHAT]: {
    idx: 19,
    type: BlockType.AI_CHAT,
    primary: 'AI Chat',
    secondary: 'Players converse with AI chatbot',
  },
  [BlockType.GUESS_WHO]: {
    idx: 20,
    type: BlockType.GUESS_WHO,
    primary: 'Guess Who',
    secondary: 'Onstage icebreaker game',
  },
  [BlockType.ICEBREAKER]: {
    idx: 21,
    type: BlockType.ICEBREAKER,
    primary: 'Icebreaker',
    secondary: 'Onstage icebreaker games',
  },
  [BlockType.MARKETING]: {
    idx: 22,
    type: BlockType.MARKETING,
    primary: 'Marketing',
    secondary: 'Market products mid-game.',
    hidden: true,
  },
  [BlockType.JEOPARDY]: {
    idx: 23,
    type: BlockType.JEOPARDY,
    primary: 'Jeopardy',
    secondary: 'Jeopardy-style trivia game',
  },
  [BlockType.HEAD_TO_HEAD]: {
    idx: 23,
    type: BlockType.HEAD_TO_HEAD,
    primary: 'Head To Head',
    secondary: 'Head To Head game',
  },
  [BlockType.SLIDE]: {
    idx: 24,
    type: BlockType.SLIDE,
    primary: 'Slide',
    secondary: 'Slide block',
    hidden: true,
  },
};

export const BlockIconMap: {
  [key in BlockType]: (props: React.SVGProps<SVGSVGElement>) => JSX.Element;
} = {
  [BlockType.QUESTION]: QuestionBlockIcon,
  [BlockType.CREATIVE_PROMPT]: CreativePromptBlockIcon,
  [BlockType.RAPID]: RapidBlockIcon,
  [BlockType.SCOREBOARD]: ScoreboardBlockIcon,
  [BlockType.SPOTLIGHT]: SpotlightBlockIcon,
  [BlockType.TEAM_RELAY]: TeamRelayBlockIcon,
  [BlockType.RANDOMIZER]: ShuffleIcon,
  [BlockType.MULTIPLE_CHOICE]: MultipleChoiceBlockIcon,
  [BlockType.MEMORY_MATCH]: MemoryMatchBlockIcon,
  [BlockType.PUZZLE]: PuzzleBlockIcon,
  [BlockType.ROUND_ROBIN_QUESTION]: RoundRobinQuestionBlockIcon,
  [BlockType.TITLE_V2]: TitleBlockIcon,
  [BlockType.INSTRUCTION]: InstructionBlockIcon,
  [BlockType.OVERROASTED]: OverRoastedBlockIcon,
  [BlockType.SPOTLIGHT_V2]: SpotlightBlockIcon,
  [BlockType.DRAWING_PROMPT]: DrawingPromptBlockIcon,
  [BlockType.HIDDEN_PICTURE]: HiddenPictureBlockIcon,
  [BlockType.AI_CHAT]: AIChatBlockIcon,
  [BlockType.ICEBREAKER]: IcebreakerBlockIcon,
  [BlockType.GUESS_WHO]: GuessWhoBlockIcon,
  [BlockType.MARKETING]: TBDIcon,
  [BlockType.JEOPARDY]: JeopardyBlockIcon,
  [BlockType.HEAD_TO_HEAD]: HeadToHeadBlockIcon,
  [BlockType.SLIDE]: SlideBlockIcon,
};

type BlockMediaKey =
  | KeyOf<BlockMediaFields<QuestionBlockMedia>>
  | KeyOf<BlockMediaFields<CreativePromptBlockMedia>>
  | KeyOf<BlockMediaFields<RapidBlockMedia>>
  // There is no media fields in ScoreboardBlockMedia,
  // adding this union changes the BlockMediaKey to string without the type guard
  // | KeyOf<BlockMediaFields<ScoreboardBlockMedia>>
  | KeyOf<BlockMediaFields<SpotlightBlockMedia>>
  | KeyOf<BlockMediaFields<TeamRelayBlockMedia>>
  | KeyOf<BlockMediaIdFields<MultipleChoiceBlockMedia>>
  | KeyOf<BlockMediaIdFields<MemoryMatchBlockMedia>>
  | KeyOf<BlockMediaIdFields<PuzzleBlockMedia>>
  | KeyOf<BlockMediaIdFields<RoundRobinQuestionBlockMedia>>
  | KeyOf<BlockMediaIdFields<TitleBlockV2Media>>
  | KeyOf<BlockMediaIdFields<InstructionBlockMedia>>
  | KeyOf<BlockMediaIdFields<OverRoastedBlockMedia>>
  | KeyOf<BlockMediaIdFields<DrawingPromptBlockMedia>>
  | KeyOf<BlockMediaIdFields<HiddenPictureBlockMedia>>
  | KeyOf<BlockMediaIdFields<IcebreakerBlockMedia>>
  | KeyOf<BlockMediaIdFields<GuessWhoBlockMedia>>;

export interface BlockKnife<T extends Block> {
  summary(block: T, full?: boolean): BlockSummary;
}

class RapidBlockKnife implements BlockKnife<RapidBlock> {
  summary(block: RapidBlock, full?: boolean): BlockSummary {
    const s: BlockSummary = {
      id: block.id,
      type: block.type,
      prettyTypeName: 'Rapid Submission',
      title: block.fields.internalLabel || block.fields.question,
      coverMedia: block.fields.questionMedia || block.fields.answerMedia,
    };
    if (full) {
      let allAnswers: RapidCorrectAnswer[] = [];
      try {
        allAnswers = JSON.parse(block.fields.answers);
      } catch (_) {}
      const picked = [];
      for (const answer of allAnswers) {
        if (answer.answer !== '') {
          picked.push(answer.answer);
          if (picked.length >= 3) break;
        }
      }
      s.subtitle = `Answers: ${picked.join(', ')}`;
    }
    return s;
  }
}

class CreativePromptBlockKnife implements BlockKnife<CreativePromptBlock> {
  summary(block: CreativePromptBlock): BlockSummary {
    return {
      id: block.id,
      type: block.type,
      prettyTypeName: 'Creative Prompt',
      title: block.fields.internalLabel || block.fields.prompt,
      coverMedia: block.fields.submissionMedia,
    };
  }
}

class QuestionBlockKnife implements BlockKnife<QuestionBlock> {
  summary(block: QuestionBlock): BlockSummary {
    return {
      id: block.id,
      type: block.type,
      prettyTypeName: 'Question',
      title: block.fields.internalLabel || block.fields.question,
      subtitle: `Answer: ${block.fields.answer}`,
      coverMedia: block.fields.questionMedia || block.fields.answerMedia,
    };
  }
}

class ScoreboardBlockKnife implements BlockKnife<ScoreboardBlock> {
  summary(block: ScoreboardBlock): BlockSummary {
    let style = '';
    switch (block.fields.mode) {
      case ScoreboardMode.GlobalTeams:
        style = 'Global Teams';
        break;
      case ScoreboardMode.OrgTeams:
        style = 'Company Teams';
        break;
      case ScoreboardMode.VenueTeams:
        style = 'Venue Teams';
        break;
      case ScoreboardMode.VenueGlobalTeams:
        style = 'Venue/Global Teams';
        break;
      default:
        style = 'Unknown Style';
        break;
    }
    return {
      id: block.id,
      type: block.type,
      prettyTypeName: 'Scoreboard',
      coverMedia: {
        id: '',
        type: MediaType.Image,
        url: getStaticAssetPath('images/scoreboard-block-cover.png'),
        hash: '50f61cdd9672c833fa9c2172f8acd7be',
        uid: '',
        transcodeStatus: MediaTranscodeStatus.Ready,
        scene: null,
        firstThumbnailUrl: undefined,
        lastThumbnailUrl: undefined,
        formats: [],
        createdAt: '',
        updatedAt: '',
      },
      title: block.fields.internalLabel || 'Scoreboard',
      subtitle: style,
    };
  }
}

class SpotlightBlockKnife implements BlockKnife<SpotlightBlock> {
  summary(block: SpotlightBlock): BlockSummary {
    return {
      id: block.id,
      type: block.type,
      prettyTypeName: 'Spotlight',
      title: block.fields.internalLabel || block.fields.message,
      subtitle: `Preselect: ${SpotlightBlockUtils.FormatPreselectedTeamOrder(
        block.fields.preselectedTeamOrder
      )}`,
      coverMedia: block.fields.backgroundMedia || block.fields.overlayMedia,
    };
  }
}

class SpotlightBlockV2Knife implements BlockKnife<SpotlightBlockV2> {
  summary(block: SpotlightBlockV2): BlockSummary {
    let label = '';
    switch (block.fields.preselectedTeamOrder) {
      case 0:
        label = 'None';
        break;
      case 1:
        label = '1st Place Team';
        break;
      case 2:
        label = '2nd Place Team';
        break;
      case 3:
        label = '3rd Place Team';
        break;
      case -1:
        label = 'Last Place Team';
        break;
      case -100:
        label = 'Random Selection';
        break;
      case -101:
        label = 'Ask for Volunteers';
        break;
      default:
        label = 'Unknown Preselection';
        break;
    }
    return {
      id: block.id,
      type: block.type,
      prettyTypeName: 'Spotlight V2',
      title: block.fields.internalLabel || block.fields.message,
      subtitle: `Preselect: ${label}, Voting Mode: ${
        block.fields.votingMode ? 'on' : 'off'
      }`,
      coverMedia: block.fields.backgroundMedia || block.fields.overlayMedia,
    };
  }
}

class RandomizerBlockKnife implements BlockKnife<RandomizerBlock> {
  summary(block: RandomizerBlock): BlockSummary {
    return {
      id: block.id,
      type: block.type,
      prettyTypeName: 'Team Randomizer',
      title: block.fields.internalLabel || 'Randomizer',
    };
  }
}

class TeamRelayBlockKnife implements BlockKnife<TeamRelayBlock> {
  summary(block: TeamRelayBlock): BlockSummary {
    const subtitle = `Levels: ${block.fields.difficultyLevel}`;
    return {
      id: block.id,
      type: block.type,
      title: block.fields.internalLabel || subtitle,
      subtitle,
      prettyTypeName: 'Team Relay',
      coverMedia: block.fields.introMedia,
    };
  }
}

class MultipleChoiceBlockKnife implements BlockKnife<MultipleChoiceBlock> {
  summary(block: MultipleChoiceBlock): BlockSummary {
    let subtitle = 'No Answer';
    for (const choice of block.fields.answerChoices) {
      if (choice.correct) {
        subtitle = `Answer: ${choice.text}`;
        break;
      }
    }
    return {
      id: block.id,
      type: block.type,
      prettyTypeName: 'Multiple Choice',
      title: block.fields.internalLabel || block.fields.question,
      subtitle,
      coverMedia: block.fields.questionMedia || block.fields.answerMedia,
    };
  }
}

class MemoryMatchBlockKnife implements BlockKnife<MemoryMatchBlock> {
  summary(block: MemoryMatchBlock): BlockSummary {
    return {
      id: block.id,
      type: block.type,
      prettyTypeName: 'Memory Match',
      title: block.fields.internalLabel || block.fields.text,
      subtitle: `${block.fields.numberOfCardPairs * 2} cards`,
      coverMedia: block.fields.backgroundMedia,
    };
  }
}

class PuzzleBlockKnife implements BlockKnife<PuzzleBlock> {
  summary(block: PuzzleBlock): BlockSummary {
    const gridSize = block.fields.gridSize;
    return {
      id: block.id,
      type: block.type,
      prettyTypeName: 'Puzzle',
      title: block.fields.internalLabel || block.fields.text,
      subtitle: `Grid: ${gridSize.numRows} x ${gridSize.numCols} (${
        gridSize.numRows * gridSize.numCols
      } pieces)`,
      coverMedia: block.fields.introMedia || block.fields.backgroundMedia,
    };
  }
}

class RoundRobinQuestionBlockKnife
  implements BlockKnife<RoundRobinQuestionBlock>
{
  summary(block: RoundRobinQuestionBlock): BlockSummary {
    return {
      id: block.id,
      type: block.type,
      prettyTypeName: 'Round Robin',
      title: block.fields.internalLabel || block.fields.questions[0]?.question,
      subtitle: `${block.fields.questions.length} questions`,
      coverMedia: block.fields.backgroundMedia,
    };
  }
}

export class TitleBlockV2Knife implements BlockKnife<TitleBlockV2> {
  summary(block: TitleBlockV2): BlockSummary {
    return {
      id: block.id,
      type: block.type,
      prettyTypeName: 'Title',
      title: block.fields.internalLabel || block.fields.cards?.[0]?.text,
      subtitle: `${block.fields.cards?.length ?? 0} slides`,
      coverMedia: block.fields.cards?.[0]?.media,
    };
  }
}

class InstructionBlockKnife implements BlockKnife<InstructionBlock> {
  summary(block: InstructionBlock): BlockSummary {
    return {
      id: block.id,
      type: block.type,
      prettyTypeName: 'Instruction',
      title: block.fields.internalLabel || block.fields.cards?.[0]?.text,
      subtitle: `${block.fields.cards?.length ?? 0} cards`,
      coverMedia: block.fields.cards?.[0]?.media,
    };
  }
}

class OverRoastedBlockKnife implements BlockKnife<OverRoastedBlock> {
  summary(block: OverRoastedBlock): BlockSummary {
    return {
      id: block.id,
      type: block.type,
      prettyTypeName: 'Over-Roasted',
      title: block.fields.internalLabel || block.fields.title,
      subtitle: [
        `Total Trucks: ${block.fields.trucksCount}`,
        `Coffee Dispensers Per Truck: ${block.fields.dispensersCountPerTruck}`,
        `Ingredients Per Player & Order: ${block.fields.maxIngredientsPerPlayer} - ${block.fields.maxIngredientsPerOrder}`,
      ].join(', '),
      coverMedia: block.fields.introMedia || block.fields.outroMedia,
    };
  }
}

class DrawingPromptBlockKnife implements BlockKnife<DrawingPromptBlock> {
  summary(block: DrawingPromptBlock): BlockSummary {
    return {
      id: block.id,
      type: block.type,
      prettyTypeName: 'Drawing: Guess the Prompt',
      title: block.fields.internalLabel || block.fields.prompts[0]?.correct,
      coverMedia: block.fields.backgroundMedia || block.fields.canvasMedia,
    };
  }
}

class HiddenPictureBlockKnife implements BlockKnife<HiddenPictureBlock> {
  summary(block: HiddenPictureBlock): BlockSummary {
    return {
      id: block.id,
      type: block.type,
      prettyTypeName: 'Hidden Picture',
      title: block.fields.internalLabel || block.fields.pictures?.[0]?.question,
      subtitle: `${block.fields.pictures?.length ?? 0} pictures`,
      coverMedia:
        block.fields.backgroundMedia || block.fields.pictures?.[0]?.mainMedia,
    };
  }
}

class AIChatBlockKnife implements BlockKnife<AIChatBlock> {
  summary(block: AIChatBlock): BlockSummary {
    return {
      id: block.id,
      type: block.type,
      prettyTypeName: 'AI Chat',
      title:
        block.fields.internalLabel ||
        truncate(block.fields.systemPrompt, {
          length: 20,
        }),
      coverMedia: block.fields.introMedia || block.fields.outroMedia,
    };
  }
}

class GuessWhoBlockKnife implements BlockKnife<GuessWhoBlock> {
  summary(block: GuessWhoBlock): BlockSummary {
    return {
      id: block.id,
      type: block.type,
      prettyTypeName: 'Guess Who',
      title: block.fields.internalLabel || block.fields.prompts[0]?.text,
      coverMedia: block.fields.introMedia ?? block.fields.backgroundMedia,
    };
  }
}

class IcebreakerBlockKnife implements BlockKnife<IcebreakerBlock> {
  summary(block: IcebreakerBlock): BlockSummary {
    return {
      id: block.id,
      type: block.type,
      prettyTypeName: 'Icebreaker',
      title:
        block.fields.internalLabel ||
        block.fields.cards[0]?.texts?.[0] ||
        block.fields.cards[0]?.options?.[0]?.text,
      coverMedia: block.fields.backgroundMedia,
    };
  }
}

class MarketingBlockKnife implements BlockKnife<MarketingBlock> {
  summary(block: MarketingBlock): BlockSummary {
    return {
      id: block.id,
      type: block.type,
      prettyTypeName: 'Marketing',
      title: block.fields.internalLabel || 'Marketing',
    };
  }
}

class JeopardyBlockKnife implements BlockKnife<JeopardyBlock> {
  summary(block: JeopardyBlock): BlockSummary {
    return {
      id: block.id,
      type: block.type,
      prettyTypeName: 'Jeopardy',
      title: block.fields.internalLabel || 'Jeopardy',
    };
  }
}

class HeadToHeadBlockKnife implements BlockKnife<HeadToHeadBlock> {
  summary(block: HeadToHeadBlock): BlockSummary {
    return {
      id: block.id,
      type: block.type,
      prettyTypeName: 'Head to Head',
      title: block.fields.internalLabel || block.fields.gameName,
      coverMedia: fromMediaDTO(block.fields.background?.media),
    };
  }
}

class SlideBlockKnife implements BlockKnife<SlideBlock> {
  summary(block: SlideBlock): BlockSummary {
    return {
      id: block.id,
      type: block.type,
      prettyTypeName: 'Slide',
      title: block.fields.internalLabel || block.fields.heading,
    };
  }
}

const BlockKnifeFactory: { [key in BlockType]: BlockKnife<Block> } = {
  [BlockType.QUESTION]: new QuestionBlockKnife(),
  [BlockType.CREATIVE_PROMPT]: new CreativePromptBlockKnife(),
  [BlockType.RAPID]: new RapidBlockKnife(),
  [BlockType.SCOREBOARD]: new ScoreboardBlockKnife(),
  [BlockType.SPOTLIGHT]: new SpotlightBlockKnife(),
  [BlockType.SPOTLIGHT_V2]: new SpotlightBlockV2Knife(),
  [BlockType.TEAM_RELAY]: new TeamRelayBlockKnife(),
  [BlockType.RANDOMIZER]: new RandomizerBlockKnife(),
  [BlockType.MULTIPLE_CHOICE]: new MultipleChoiceBlockKnife(),
  [BlockType.MEMORY_MATCH]: new MemoryMatchBlockKnife(),
  [BlockType.PUZZLE]: new PuzzleBlockKnife(),
  [BlockType.ROUND_ROBIN_QUESTION]: new RoundRobinQuestionBlockKnife(),
  [BlockType.TITLE_V2]: new TitleBlockV2Knife(),
  [BlockType.INSTRUCTION]: new InstructionBlockKnife(),
  [BlockType.OVERROASTED]: new OverRoastedBlockKnife(),
  [BlockType.DRAWING_PROMPT]: new DrawingPromptBlockKnife(),
  [BlockType.HIDDEN_PICTURE]: new HiddenPictureBlockKnife(),
  [BlockType.AI_CHAT]: new AIChatBlockKnife(),
  [BlockType.GUESS_WHO]: new GuessWhoBlockKnife(),
  [BlockType.ICEBREAKER]: new IcebreakerBlockKnife(),
  [BlockType.MARKETING]: new MarketingBlockKnife(),
  [BlockType.JEOPARDY]: new JeopardyBlockKnife(),
  [BlockType.HEAD_TO_HEAD]: new HeadToHeadBlockKnife(),
  [BlockType.SLIDE]: new SlideBlockKnife(),
};

export class BlockKnifeUtils {
  static Summary(block: Block, full = false): BlockSummary {
    return BlockKnifeFactory[block.type].summary(block, full);
  }

  static SummaryText(
    block: Block
  ): Omit<BlockSummary, 'coverMedia' | 'submissionMedia'> {
    const summary = BlockKnifeFactory[block.type].summary(block);
    delete summary.coverMedia;
    delete summary.submissionMedia;
    return summary;
  }

  static Media(block: Block, key: BlockMediaKey): Media | null {
    switch (block.type) {
      case BlockType.SCOREBOARD:
      case BlockType.RANDOMIZER:
      case BlockType.TITLE_V2:
      case BlockType.INSTRUCTION:
      case BlockType.MARKETING:
      case BlockType.SLIDE:
        return null;

      case BlockType.MEMORY_MATCH:
      case BlockType.HIDDEN_PICTURE:
      case BlockType.TEAM_RELAY:
      case BlockType.RAPID:
      case BlockType.CREATIVE_PROMPT:
      case BlockType.QUESTION:
      case BlockType.SPOTLIGHT_V2:
      case BlockType.SPOTLIGHT:
      case BlockType.AI_CHAT:
      case BlockType.DRAWING_PROMPT:
      case BlockType.GUESS_WHO:
      case BlockType.ICEBREAKER:
      case BlockType.MULTIPLE_CHOICE:
      case BlockType.OVERROASTED:
      case BlockType.PUZZLE:
      case BlockType.ROUND_ROBIN_QUESTION:
      case BlockType.JEOPARDY:
      case BlockType.HEAD_TO_HEAD:
        return uncheckedIndexAccess_UNSAFE(block.fields)[key] ?? null;

      default:
        assertExhaustive(block);
        return null;
    }
  }

  static NextCountingStatus(
    block: Block,
    currStatus: GameSessionStatus
  ): GameSessionStatus | null {
    if (currStatus === null) return null;

    switch (block.type) {
      case BlockType.RAPID:
        return RapidBlockGameSessionStatus.QUESTION_COUNTING;

      case BlockType.CREATIVE_PROMPT: {
        const isVotingPhase =
          currStatus >= CreativePromptBlockGameSessionStatus.SHOW_SUBMISSIONS;
        if (isVotingPhase) {
          return CreativePromptBlockGameSessionStatus.VOTE_COUNTING;
        }
        return CreativePromptBlockGameSessionStatus.SUBMISSION_COUNTING;
      }

      case BlockType.QUESTION:
        return QuestionBlockGameSessionStatus.COUNTING;

      case BlockType.SCOREBOARD:
      case BlockType.SPOTLIGHT:
      case BlockType.SPOTLIGHT_V2:
      case BlockType.RANDOMIZER:
      case BlockType.TITLE_V2:
      case BlockType.MARKETING:
      case BlockType.SLIDE:
        return null;

      case BlockType.MULTIPLE_CHOICE:
        return MultipleChoiceGameSessionStatus.SUBMISSION_TIMER_COUNTING;

      case BlockType.MEMORY_MATCH:
        return MemoryMatchBlockGameSessionStatus.GAME_START;

      case BlockType.PUZZLE:
        return PuzzleBlockGameSessionStatus.GAME_START;

      case BlockType.ROUND_ROBIN_QUESTION:
        return RoundRobinQuestionBlockGameSessionStatus.GAME_START;

      case BlockType.INSTRUCTION:
        return InstructionBlockGameSessionStatus.GAME_START;

      case BlockType.OVERROASTED:
        return OverRoastedBlockGameSessionStatus.GAME_START;

      case BlockType.DRAWING_PROMPT: {
        const countingStatuses = [
          DrawingPromptBlockGameSessionStatus.MATCH_PROMPT_COUNTING,
          DrawingPromptBlockGameSessionStatus.TITLE_CREATION_COUNTING,
          DrawingPromptBlockGameSessionStatus.TEAM_VOTE_COUNTING,
          DrawingPromptBlockGameSessionStatus.DRAWING_START,
        ];
        for (const status of countingStatuses) {
          if (currStatus >= status) {
            return status;
          }
        }
        return countingStatuses[countingStatuses.length - 1];
      }

      case BlockType.HIDDEN_PICTURE:
        return HiddenPictureBlockGameSessionStatus.GAME_START;

      case BlockType.AI_CHAT:
        return AIChatBlockGameSessionStatus.GAME_START;

      case BlockType.GUESS_WHO: {
        const countingStatuses = [
          GuessWhoBlockGameSessionStatus.MATCH_PROMPT_COUNTING,
          GuessWhoBlockGameSessionStatus.PROMPT_COUNTING,
        ];
        for (const status of countingStatuses) {
          if (currStatus >= status) {
            return status;
          }
        }
        return countingStatuses[countingStatuses.length - 1];
      }

      case BlockType.ICEBREAKER:
        return IcebreakerBlockGameSessionStatus.GAME_START;

      case BlockType.TEAM_RELAY:
        return TeamRelayBlockGameSessionStatus.GAME_START;

      case BlockType.JEOPARDY:
        return JeopardyBlockGameSessionStatus.GAME_START;

      case BlockType.HEAD_TO_HEAD:
        return HeadToHeadBlockGameSessionStatus.GAME_START;

      default:
        assertExhaustive(block);
        return null;
    }
  }

  static Points(block: Block | null): number {
    if (!block) return 0;

    switch (block.type) {
      case BlockType.QUESTION:
      case BlockType.CREATIVE_PROMPT:
      case BlockType.TEAM_RELAY:
      case BlockType.MULTIPLE_CHOICE:
        return block.fields.points;

      case BlockType.RAPID: {
        const map = parseRapidAllAnswers(block.fields.answers);
        for (const [, answer] of Object.entries(map)) {
          if (answer.points > 0) return answer.points;
        }
        return 0;
      }

      case BlockType.RANDOMIZER:
      case BlockType.SCOREBOARD:
      case BlockType.SPOTLIGHT:
      case BlockType.SPOTLIGHT_V2:
      case BlockType.ICEBREAKER:
      case BlockType.MARKETING:
      case BlockType.TITLE_V2:
      case BlockType.INSTRUCTION:
      case BlockType.SLIDE:
        return 0;
      case BlockType.MEMORY_MATCH:
        return block.fields.pointsPerMatch;
      case BlockType.PUZZLE:
        return block.fields.pointsPerCorrectPiece;
      case BlockType.ROUND_ROBIN_QUESTION: {
        for (const question of block.fields.questions) {
          if (question.points > 0) {
            return question.points;
          }
        }
        return 0;
      }
      case BlockType.OVERROASTED:
        return block.fields.pointsPerOrder;
      case BlockType.DRAWING_PROMPT:
        return block.fields.correctPromptPoints;

      case BlockType.HIDDEN_PICTURE: {
        const all =
          block.fields.pictures?.flatMap((p) => p.hotSpotsV2 ?? []) ?? [];
        for (const hotSpot of all) {
          if (hotSpot.points !== 0) {
            return hotSpot.points;
          }
        }
        return 0;
      }
      case BlockType.AI_CHAT:
        return block.fields.winningPoints;
      case BlockType.GUESS_WHO:
        return block.fields.pointsPerCorrect;
      case BlockType.JEOPARDY: {
        let points = 0;
        const categories = block.fields.board.categories ?? [];
        for (const category of categories) {
          const clues = category.clues ?? [];
          for (const clue of clues) {
            points += clue.value;
          }
        }
        return points;
      }

      case BlockType.HEAD_TO_HEAD:
        return block.fields.judgingPoints;
      default:
        assertExhaustive(block);
        return 0;
    }
  }

  static DisplaysPointsMultiplier(block: Block | null): '2x' | '3x' | null {
    if (!block) return null;

    switch (block.type) {
      case BlockType.QUESTION:
      case BlockType.MULTIPLE_CHOICE:
        if (!block.fields.displayPointsMultiplier) return null;
        return block.fields.points === 200
          ? '2x'
          : block.fields.points === 300
          ? '3x'
          : null;

      case BlockType.AI_CHAT:
      case BlockType.CREATIVE_PROMPT:
      case BlockType.DRAWING_PROMPT:
      case BlockType.GUESS_WHO:
      case BlockType.HIDDEN_PICTURE:
      case BlockType.ICEBREAKER:
      case BlockType.MEMORY_MATCH:
      case BlockType.OVERROASTED:
      case BlockType.PUZZLE:
      case BlockType.RAPID:
      case BlockType.ROUND_ROBIN_QUESTION:
      case BlockType.TEAM_RELAY:
      case BlockType.INSTRUCTION:
      case BlockType.MARKETING:
      case BlockType.RANDOMIZER:
      case BlockType.SCOREBOARD:
      case BlockType.SPOTLIGHT_V2:
      case BlockType.SPOTLIGHT:
      case BlockType.TITLE_V2:
      case BlockType.JEOPARDY:
      case BlockType.HEAD_TO_HEAD:
      case BlockType.SLIDE:
        return null;

      default:
        assertExhaustive(block);
        return null;
    }
  }

  // Logic should be synced with IFields in api-service/models/block.go
  static QualifiesAsGameplay(block: Block): boolean {
    const hasPoints = this.Points(block) > 0;

    switch (block.type) {
      // Note: instruction block has no points but unskippable,
      // it's just a hack since the two conceptions are mixed now.
      case BlockType.TITLE_V2:
        return true;
      case BlockType.PUZZLE:
        return hasPoints || block.fields.completionBonusPoints > 0;
      default:
        return hasPoints;
    }
  }

  static IsRecordable(
    block: Block | null | undefined,
    recorderVersion: number
  ): boolean {
    if (!block) return false;

    if (recorderVersion === 1) return true;

    switch (block.type) {
      case BlockType.ICEBREAKER:
      case BlockType.GUESS_WHO:
      case BlockType.AI_CHAT:
      case BlockType.HIDDEN_PICTURE:
      case BlockType.DRAWING_PROMPT:
      case BlockType.OVERROASTED:
      case BlockType.INSTRUCTION:
      case BlockType.ROUND_ROBIN_QUESTION:
      case BlockType.PUZZLE:
      case BlockType.MEMORY_MATCH:
      case BlockType.MARKETING:
      case BlockType.QUESTION:
      case BlockType.MULTIPLE_CHOICE:
      case BlockType.TEAM_RELAY:
      case BlockType.CREATIVE_PROMPT:
      case BlockType.SPOTLIGHT_V2:
      case BlockType.RAPID:
      case BlockType.SPOTLIGHT:
      case BlockType.JEOPARDY:
      case BlockType.HEAD_TO_HEAD:
      case BlockType.SLIDE:
        return false;

      case BlockType.RANDOMIZER:
        return true;

      case BlockType.TITLE_V2:
      case BlockType.SCOREBOARD:
        return this.HasAtLeastOneVoiceOver(block);

      default:
        assertExhaustive(block);
        return false;
    }
  }

  static GetGoalCompletionMedia(block: Block): Media | null {
    // NOTE(drew): I got sick of the repetition of this file, so I decided to
    // try this one as a "best effort" approach.
    return match(block)
      .with(
        { type: BlockType.ROUND_ROBIN_QUESTION },
        (b) => b.fields.goalAnimationMedia
      )
      .otherwise((b) =>
        'goalAnimationMedia' in b.fields ? b.fields.goalAnimationMedia : null
      );
  }

  // it indiciates whether this block is played by team capatin
  static IsEligibleForTeamCapainPlay(block: Block): boolean {
    switch (block.type) {
      case BlockType.RAPID:
        return !block.fields.everyoneSubmits;

      case BlockType.HIDDEN_PICTURE:
        return block.fields.pictures?.some((p) => !p.everyoneClicks) ?? false;

      case BlockType.CREATIVE_PROMPT:
      case BlockType.MEMORY_MATCH:
      case BlockType.MULTIPLE_CHOICE:
      case BlockType.QUESTION:
        return true;

      case BlockType.ICEBREAKER:
      case BlockType.GUESS_WHO:
      case BlockType.AI_CHAT:
      case BlockType.DRAWING_PROMPT:
      case BlockType.OVERROASTED:
      case BlockType.ROUND_ROBIN_QUESTION:
      case BlockType.PUZZLE:
      case BlockType.INSTRUCTION:
      case BlockType.MARKETING:
      case BlockType.RANDOMIZER:
      case BlockType.SCOREBOARD:
      case BlockType.SPOTLIGHT_V2:
      case BlockType.SPOTLIGHT:
      case BlockType.TITLE_V2:
      case BlockType.TEAM_RELAY:
      case BlockType.JEOPARDY:
      case BlockType.HEAD_TO_HEAD:
      case BlockType.SLIDE:
        return false;

      default:
        assertExhaustive(block);
        return false;
    }
  }

  static HasAtLeastOneVoiceOver(block: Block): boolean {
    switch (block.type) {
      case BlockType.ICEBREAKER:
      case BlockType.GUESS_WHO:
      case BlockType.AI_CHAT:
      case BlockType.HIDDEN_PICTURE:
      case BlockType.DRAWING_PROMPT:
      case BlockType.OVERROASTED:
      case BlockType.INSTRUCTION:
      case BlockType.ROUND_ROBIN_QUESTION:
      case BlockType.PUZZLE:
      case BlockType.MEMORY_MATCH:
      case BlockType.RANDOMIZER:
      case BlockType.MARKETING:
      case BlockType.QUESTION:
      case BlockType.MULTIPLE_CHOICE:
      case BlockType.TEAM_RELAY:
      case BlockType.CREATIVE_PROMPT:
      case BlockType.RAPID:
      case BlockType.JEOPARDY:
      case BlockType.SLIDE:
        return false;

      case BlockType.SCOREBOARD:
      case BlockType.SPOTLIGHT_V2:
      case BlockType.SPOTLIGHT:
        return VoiceOverUtils.HasAtLeastOneConfig(block.fields.voiceOver);

      case BlockType.TITLE_V2:
        return (
          (block.fields.cards?.some((c) =>
            VoiceOverUtils.HasAtLeastOneConfig(c.voiceOver)
          ) ||
            Array.from(block.fields.ttsScripts?.entries() ?? []).length > 0) ??
          false
        );
      case BlockType.HEAD_TO_HEAD:
        return this.GetVoiceOverList(block).some((v) =>
          VoiceOverUtils.HasAtLeastOneConfig(v)
        );
      default:
        assertExhaustive(block);
        return false;
    }
  }

  // TODO: update this to only return text[], not VoiceOvers[]
  static GetVoiceOverList(block: Block): VoiceOver[] {
    switch (block.type) {
      case BlockType.ICEBREAKER:
      case BlockType.GUESS_WHO:
      case BlockType.AI_CHAT:
      case BlockType.HIDDEN_PICTURE:
      case BlockType.DRAWING_PROMPT:
      case BlockType.OVERROASTED:
      case BlockType.INSTRUCTION:
      case BlockType.ROUND_ROBIN_QUESTION:
      case BlockType.PUZZLE:
      case BlockType.MEMORY_MATCH:
      case BlockType.RANDOMIZER:
      case BlockType.MARKETING:
      case BlockType.QUESTION:
      case BlockType.MULTIPLE_CHOICE:
      case BlockType.TEAM_RELAY:
      case BlockType.CREATIVE_PROMPT:
      case BlockType.RAPID:
      case BlockType.JEOPARDY:
      case BlockType.SLIDE:
        return [];

      case BlockType.SCOREBOARD:
      case BlockType.SPOTLIGHT_V2:
      case BlockType.SPOTLIGHT: {
        if (!block.fields.voiceOver) return [];
        return [block.fields.voiceOver];
      }

      case BlockType.TITLE_V2:
        if (!block.fields.cards) return [];
        return block.fields.cards
          .map((c) => c.voiceOver)
          .filter(Boolean) as VoiceOver[];

      case BlockType.HEAD_TO_HEAD:
        const voiceOvers: Nullable<VoiceOver>[] = [
          block.fields.introductoryVoiceOver,
        ];
        block.fields.cards.forEach((c) => {
          voiceOvers.push(
            c.default?.voiceOver,
            c.audience?.voiceOver,
            c.groupA?.voiceOver,
            c.groupB?.voiceOver
          );
        });
        return voiceOvers.filter(Boolean) as VoiceOver[];

      default:
        assertExhaustive(block);
        return [];
    }
  }

  static Votable(block: Block): boolean {
    switch (block.type) {
      case BlockType.AI_CHAT:
      case BlockType.CREATIVE_PROMPT:
      case BlockType.DRAWING_PROMPT:
      case BlockType.GUESS_WHO:
      case BlockType.HIDDEN_PICTURE:
      case BlockType.ICEBREAKER:
      case BlockType.MEMORY_MATCH:
      case BlockType.MULTIPLE_CHOICE:
      case BlockType.OVERROASTED:
      case BlockType.PUZZLE:
      case BlockType.QUESTION:
      case BlockType.RAPID:
      case BlockType.ROUND_ROBIN_QUESTION:
      case BlockType.TEAM_RELAY:
      case BlockType.JEOPARDY:
      case BlockType.HEAD_TO_HEAD:
      case BlockType.SLIDE:
        return true;

      case BlockType.INSTRUCTION:
      case BlockType.MARKETING:
      case BlockType.RANDOMIZER:
      case BlockType.SCOREBOARD:
      case BlockType.SPOTLIGHT_V2:
      case BlockType.SPOTLIGHT:
      case BlockType.TITLE_V2:
        return false;

      default:
        assertExhaustive(block);
        return false;
    }
  }

  static RequiresVIP(block: Block): boolean {
    switch (block.type) {
      case BlockType.ICEBREAKER:
        if (
          block.fields.onStageSelection === IcebreakerOnStageSelection.VIP ||
          block.fields.cards
            .flatMap((c) => c.options)
            .some((o) => o.text.includes('%vipNames'))
        ) {
          return true;
        }
        break;
      case BlockType.SPOTLIGHT:
      case BlockType.SPOTLIGHT_V2:
        if (block.fields.preselectedTeamOrder === 0) {
          return true;
        }
        break;
      case BlockType.AI_CHAT:
      case BlockType.CREATIVE_PROMPT:
      case BlockType.DRAWING_PROMPT:
      case BlockType.GUESS_WHO:
      case BlockType.HIDDEN_PICTURE:
      case BlockType.MEMORY_MATCH:
      case BlockType.MULTIPLE_CHOICE:
      case BlockType.OVERROASTED:
      case BlockType.PUZZLE:
      case BlockType.QUESTION:
      case BlockType.RAPID:
      case BlockType.ROUND_ROBIN_QUESTION:
      case BlockType.TEAM_RELAY:
      case BlockType.JEOPARDY:
      case BlockType.HEAD_TO_HEAD:
      case BlockType.INSTRUCTION:
      case BlockType.MARKETING:
      case BlockType.RANDOMIZER:
      case BlockType.SCOREBOARD:
      case BlockType.TITLE_V2:
      case BlockType.SLIDE:
        break;
      default:
        assertExhaustive(block);
        return false;
    }

    const voiceOverList = BlockKnifeUtils.GetVoiceOverList(block);
    return voiceOverList.some((voiceOver) =>
      voiceOver.runtime?.script.includes('%vipNames%')
    );
  }
}
