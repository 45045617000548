import { type ReactNode, useEffect, useRef, useState } from 'react';

import VoteAnimationURL from '../../assets/img/everyone-votes-animation-no-loop.png';
import GoAnimationURL from '../../assets/img/go-animation-no-loop-xl.png';
import Points2XAnimationURL from '../../assets/img/points-2x-no-loop.png';
import Points3XAnimationURL from '../../assets/img/points-3x-no-loop.png';
import { useFeatureQueryParam } from '../../hooks/useFeatureQueryParam';
import { OneShotAnimatedImg } from '../common/Utilities';
import { FloatLayout } from '../Layout';
import { type SoundEffectKeys, useSoundEffect } from '../SFX';

async function preloadAnimations() {
  new Image().src = Points2XAnimationURL;
  new Image().src = Points3XAnimationURL;
}

setTimeout(() => {
  preloadAnimations();
}, 2000);

function MaybeFloatLayout(props: {
  enabled: boolean;
  zIndex?: `z-${number}`;
  children: ReactNode;
}): JSX.Element {
  if (!props.enabled) return <>{props.children}</>;
  return <FloatLayout className={props.zIndex}>{props.children}</FloatLayout>;
}

export function ImgSFXAnimation(props: {
  className?: string;
  imgSrc: string;
  imgClassName?: string;
  imgDurationMs: number;
  imgDelayMs: number;
  imgAlt: string;
  sfxKey: SoundEffectKeys;
  cb?: () => void;
  showText?: boolean;
  zIndex?: `z-${number}`;
  useFloatLayout?: boolean;
}): JSX.Element | null {
  const ref = useRef<HTMLDivElement | null>(null);
  const [hide, setHide] = useState(false);
  const [runImg, setRunImg] = useState(false);
  const { sfxKey, imgDelayMs, showText = false, cb } = props;
  const useFloatLayout = props.useFloatLayout ?? true;

  const { play: playFx } = useSoundEffect(sfxKey);

  useEffect(() => {
    // only run once, ever
    playFx();
  }, [playFx]);

  useEffect(() => {
    if (!ref.current) return;

    let imgAnimTimer: Animation | undefined;

    // Time out the delay
    ref.current.animate([], {
      duration: imgDelayMs,
    }).onfinish = () => {
      if (!ref.current) return;
      // Then initiate the primary timer for the animation itself
      setRunImg(true);
      imgAnimTimer = ref.current.animate([], { duration: props.imgDurationMs });
      imgAnimTimer.onfinish = () => {
        setRunImg(false);
        setHide(true);
        cb && cb();
      };
    };

    return () => {
      if (imgAnimTimer) imgAnimTimer.onfinish = null;
    };
  }, [cb, imgDelayMs, props.imgDurationMs]);

  return !hide ? (
    <MaybeFloatLayout enabled={useFloatLayout} zIndex={props.zIndex}>
      <div
        ref={ref}
        className={`w-auto flex flex-col justify-center items-center pointer-events-off 
        ${!useFloatLayout ? props.zIndex : ''} ${props.className ?? ''}`}
      >
        {showText && (
          <p className='mb-20 text-3xl font-cairo text-white'>
            This Next Question is Worth
          </p>
        )}
        {runImg ? (
          <OneShotAnimatedImg
            imgClassName={props.imgClassName}
            src={props.imgSrc}
            alt={props.imgAlt}
          />
        ) : null}
      </div>
    </MaybeFloatLayout>
  ) : null;
}

export const VoteAnimation = (): JSX.Element => (
  <ImgSFXAnimation
    zIndex='z-35'
    className='mt-40'
    imgSrc={VoteAnimationURL}
    imgDurationMs={2000}
    imgDelayMs={0}
    imgAlt='VOTE!'
    sfxKey='everyoneVotes'
  />
);

export const GoAnimation = (): JSX.Element => {
  const enabled = useFeatureQueryParam('game-play-go-animation');

  if (!enabled) return <></>;

  return (
    <ImgSFXAnimation
      zIndex='z-35'
      className='mt-32'
      imgSrc={GoAnimationURL}
      imgClassName='w-full'
      imgDurationMs={1450}
      imgDelayMs={0}
      imgAlt='GO!'
      sfxKey='go'
    />
  );
};

type PointsMultiplierAnimationProps = {
  useFloatLayout?: boolean;
  className?: string;
  cb?: () => void;
  showText?: boolean;
};

export type PointsMultiplierAnimation = (
  props: PointsMultiplierAnimationProps
) => JSX.Element;

export const Points2XAnimation = ({
  useFloatLayout = true,
  className,
  cb,
  showText,
}: PointsMultiplierAnimationProps): JSX.Element => (
  <ImgSFXAnimation
    className={className ? className : 'mt-32'}
    imgSrc={Points2XAnimationURL}
    imgDurationMs={2600}
    imgDelayMs={100}
    imgAlt='2x Points!'
    sfxKey='xTimesPoints'
    cb={cb}
    showText={showText}
    useFloatLayout={useFloatLayout}
  />
);

export const Points3XAnimation = ({
  useFloatLayout = true,
  className,
  cb,
  showText,
}: PointsMultiplierAnimationProps): JSX.Element => (
  <ImgSFXAnimation
    className={className ? className : 'mt-32'}
    imgSrc={Points3XAnimationURL}
    imgDurationMs={2600}
    imgDelayMs={100}
    imgAlt='3x Points!'
    sfxKey='xTimesPoints'
    cb={cb}
    showText={showText}
    useFloatLayout={useFloatLayout}
  />
);
