import { ErrorMessage } from '@hookform/error-message';
import { useCallback } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { EnumsMediaScene } from '@lp-lib/api-service-client/public';

import config from '../../../config';
import { useLiveAsyncCall } from '../../../hooks/useAsyncCall';
import { type Organizer } from '../../../types';
import {
  type GamePack,
  type GamePackPromotionalAssets,
} from '../../../types/game';
import { err2s } from '../../../utils/common';
import { useAwaitFullScreenConfirmCancelModal } from '../../ConfirmCancelModalContext';
import { ModalWrapper } from '../../ConfirmCancelModalContext/ModalWrapper';
import { Loading } from '../../Loading';
import { MediaEditor } from '../../MediaUploader/MediaEditor';
import { OrganizerPicker } from '../../Organization/OrganizerPicker';
import { SwitcherControlled } from '../../Switcher';

function defaultPromotionalAssets(pack: GamePack): GamePackPromotionalAssets {
  return {
    pushable: true,
    title: pack.name,
    description: pack.description || '',
    cover: pack.cover
      ? {
          mediaData: {
            id: pack.cover.id,
          },
          media: pack.cover,
        }
      : undefined,
  };
}

export function GamePackPromotionalAssetsForm(props: {
  defaultValues: GamePackPromotionalAssets;
  confirmText?: string;
  onConfirm: (data: GamePackPromotionalAssets) => Promise<void>;
  onCancel: () => void;
}) {
  const { defaultValues, onConfirm, onCancel, confirmText } = props;

  const {
    register,
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
  } = useForm<GamePackPromotionalAssets>({
    defaultValues,
  });

  return (
    <form
      className='w-full px-5 py-7.5 text-white'
      onSubmit={handleSubmit(onConfirm)}
    >
      <header className='text-center text-2xl font-medium'>
        Promotional Assets
      </header>

      <main className='mt-10 flex flex-col gap-7.5'>
        <div>
          <h3 className='text-base font-bold'>Pushable?</h3>
          <div className='flex items-start gap-6 mt-1'>
            <p className='w-70 text-sms font-light'>
              Should this be included in the pool of games that get considered
              for promotional push?
            </p>

            <Controller
              control={control}
              name='pushable'
              render={({ field: { onChange, value, name } }) => (
                <SwitcherControlled
                  name={name}
                  className=''
                  checked={value}
                  onChange={onChange}
                />
              )}
            />
          </div>
        </div>

        <div>
          <h3 className='text-base font-bold'>Promotional Title (Optional)</h3>
          <input
            {...register('title', {
              required: true,
              minLength: 1,
              maxLength: 50,
            })}
            className={`w-full h-12.5 ${
              errors.title ? 'field-error' : 'field'
            } mb-0 mt-1`}
            placeholder='Must be 1 to 50 characters'
          />
        </div>

        <label>
          <h3 className='text-base font-bold'>
            Promotional Description (Optional)
          </h3>
          <textarea
            {...register('description', {
              required: true,
              minLength: 1,
              maxLength: 1000,
            })}
            className={`w-full h-25 px-4 py-2 ${
              errors.description ? 'field-error' : 'field'
            } mb-0 mt-1`}
            placeholder='Max 1000 characters'
          />
        </label>

        <Controller
          control={control}
          name='cover'
          rules={{
            required: true,
            validate: (value) => {
              if (value?.media) {
                return true;
              }
              return 'Cover image is required';
            },
          }}
          render={({ field: { onChange, value } }) => (
            <MediaEditor
              title='Promotional Cover Image (Optional)'
              video={false}
              scene={EnumsMediaScene.MediaSceneGamePackCover}
              mediaData={value?.mediaData ?? null}
              media={value?.media ?? null}
              onChange={(mediaData, media) => {
                onChange({
                  mediaData,
                  media,
                });
              }}
              extraNotice={
                <ErrorMessage
                  errors={errors}
                  name='cover'
                  as='p'
                  className='text-sms text-red-002'
                />
              }
            />
          )}
        />
      </main>

      <footer className='mt-8 flex justify-center items-center gap-5'>
        <button
          type='button'
          onClick={onCancel}
          className='btn-secondary w-45 h-10'
        >
          Cancel
        </button>
        <button
          type='submit'
          className='btn-primary w-45 h-10'
          disabled={isSubmitting}
        >
          {isSubmitting && <Loading containerClassName='mr-2' text='' />}
          {confirmText ?? 'Confirm'}
        </button>
      </footer>
    </form>
  );
}

export interface GamePackPromotionalAssetsModalProps {
  pack: GamePack;
  confirmText?: string;
  onConfirm: (data: GamePackPromotionalAssets) => Promise<void>;
}

export function useTriggerGamePackPromotionalAssetsModal(): (
  props: GamePackPromotionalAssetsModalProps
) => void {
  const triggerModal = useAwaitFullScreenConfirmCancelModal();

  return useCallback(
    async (props: GamePackPromotionalAssetsModalProps) => {
      triggerModal({
        kind: 'custom',
        element: (p) => (
          <ModalWrapper
            containerClassName='w-160'
            borderStyle='white'
            onClose={p.internalOnCancel}
          >
            <GamePackPromotionalAssetsForm
              defaultValues={
                props.pack.promotionalAssets ||
                defaultPromotionalAssets(props.pack)
              }
              confirmText={props.confirmText}
              onConfirm={async (data) => {
                p.internalOnConfirm();
                await props.onConfirm(data);
              }}
              onCancel={p.internalOnCancel}
            />
          </ModalWrapper>
        ),
      });
    },
    [triggerModal]
  );
}

export interface SendGameUpdatesFormData {
  recipient: Organizer | null;
}

export function SendGameUpdatesForm(props: {
  onClose: () => void;
  onConfirm: (data: SendGameUpdatesFormData) => Promise<void>;
}) {
  const { control, handleSubmit, formState } = useForm<SendGameUpdatesFormData>(
    {
      defaultValues: {
        recipient: null,
      },
    }
  );

  const {
    call: submit,
    state: { error },
  } = useLiveAsyncCall(async (data: SendGameUpdatesFormData) => {
    await props.onConfirm(data);
  });

  return (
    <form
      className='w-full px-5 py-7.5 text-white'
      onSubmit={handleSubmit(submit)}
    >
      <header className='text-2xl text-center font-medium'>
        Send Game Updates Preview
      </header>

      <main className='mt-10 flex flex-col gap-7.5'>
        <p className='text-center italic'>
          Note: Any unsaved modifications will not be reflected in the message.
        </p>

        <div>
          <h3 className='text-base font-bold mb-1'>Send to</h3>

          <Controller
            control={control}
            name='recipient'
            rules={{
              required: true,
            }}
            render={({ field: { onChange, value } }) => (
              <OrganizerPicker
                orgId={config.misc.lunaParkOrgId}
                onChange={onChange}
                defaultOrganizer={value || undefined}
                placeholder='Select a member in Luna Park organization'
                className='h-15'
              />
            )}
          />
        </div>
      </main>

      <footer className='mt-10 relative'>
        <div className='w-full mb-1 text-center text-red-500 text-sm'>
          {err2s(error)}
        </div>
        <div className='flex justify-center items-center gap-5'>
          <button
            type='button'
            onClick={props.onClose}
            className='btn-secondary w-40 h-10'
          >
            Cancel
          </button>

          <button
            type='submit'
            className='btn-primary w-40 h-10 flex justify-center items-center'
            disabled={formState.isSubmitting}
          >
            {formState.isSubmitting ? 'Sending...' : 'Send'}
          </button>
        </div>
      </footer>
    </form>
  );
}
