export function OverRoastedBlockIcon(
  props: React.SVGProps<SVGSVGElement>
): JSX.Element {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      className={props.className ?? 'w-3.5 h-3.5 fill-current'}
      fill='none'
      viewBox='0 0 14 14'
    >
      <path
        fill='#CB996B'
        fillRule='evenodd'
        d='M2.807 2.965l1.125 9.421v.001h6.134l1.125-9.42h.686v-.765c0-.325-.28-.59-.623-.59h-8.51c-.343 0-.623.265-.623.59v.763h.686zm3.981 4.848c.132-.224.198-.495.198-.813 0-.319-.066-.59-.198-.813a1.303 1.303 0 00-.535-.51 1.614 1.614 0 00-.76-.177 1.62 1.62 0 00-.763.177 1.306 1.306 0 00-.534.51C4.066 6.41 4 6.681 4 7c0 .317.065.588.196.811.131.224.309.394.534.512.225.118.48.177.763.177.281 0 .535-.059.76-.176.226-.117.405-.288.535-.511zm-.587-1.335c.068.142.102.316.102.522 0 .206-.034.38-.102.522a.722.722 0 01-.281.322.81.81 0 01-.427.11.731.731 0 01-.71-.432 1.224 1.224 0 01-.1-.522c0-.206.034-.38.1-.522a.732.732 0 01.71-.432.81.81 0 01.427.11c.121.073.215.18.281.322zm2.543-.938H7.486v2.92h.674V7.425h.492l.604 1.035H10l-.677-1.135.03-.011a.869.869 0 00.396-.32.894.894 0 00.137-.5.92.92 0 00-.135-.504.87.87 0 00-.39-.332 1.458 1.458 0 00-.617-.118zM8.16 6.929h.457a.912.912 0 00.321-.049.375.375 0 00.19-.145.43.43 0 00.064-.241.446.446 0 00-.064-.246.383.383 0 00-.191-.15.85.85 0 00-.323-.053H8.16v.884z'
        clipRule='evenodd'
      ></path>
    </svg>
  );
}
