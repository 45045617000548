import shuffle from 'lodash/shuffle';
import { type ReactNode, useEffect, useState } from 'react';
import { useEffectOnce } from 'react-use';

import { ProfileIndex } from '@lp-lib/crowd-frames-schema';

import RandomizerAnimationEveryoneUrl from '../../../assets/img/randomizer-animation-everyone-no-loop.png';
import { useCountdown } from '../../../hooks/useCountdown';
import { useMyInstance } from '../../../hooks/useMyInstance';
import { ClientTypeUtils, type Participant } from '../../../types/user';
import { randomPick } from '../../../utils/common';
import { isServer } from '../../../utils/isServer';
import { OneShotAnimatedImg } from '../../common/Utilities';
import { CrowdFramesAvatar } from '../../CrowdFrames';
import { useParticipantsAsArray } from '../../Player';
import { useRightPanelUIAction } from '../../RightPanelContext';
import { useSoundEffect } from '../../SFX';
import { useMyClientType } from '../../Venue/VenuePlaygroundProvider';

async function preloadAnimations() {
  new Image().src = RandomizerAnimationEveryoneUrl;
}

if (!isServer)
  setTimeout(() => {
    preloadAnimations();
  }, 2000);

const useRandomizerSFX = (initTime: number, timeInterval = 1000): void => {
  const [runningTime, runningTimeOps] = useCountdown(initTime, timeInterval);
  const [doneSFXPlayed, setDoneSFXPlayed] = useState(false);

  const { play: playRandomizingSFX, stop: stopRandomizingSFX } =
    useSoundEffect('teamRandomize');
  const { play: doneSFX } = useSoundEffect('teamRandomizeDone');

  useEffect(() => {
    playRandomizingSFX();
    runningTimeOps.start({ reset: true });
  }, [playRandomizingSFX, runningTimeOps]);

  useEffect(() => {
    if (runningTime === 0 && !doneSFXPlayed) {
      stopRandomizingSFX();
      doneSFX();
      setDoneSFXPlayed(true);
    }
  }, [doneSFX, doneSFXPlayed, runningTime, runningTimeOps, stopRandomizingSFX]);

  useEffect(() => {
    return () => {
      if (!doneSFXPlayed) {
        doneSFX();
        setDoneSFXPlayed(true);
      }
      stopRandomizingSFX();
    };
  }, [stopRandomizingSFX, doneSFX, doneSFXPlayed]);
};

function ColorStroke(props: {
  enabled: boolean;
  children: ReactNode;
}): JSX.Element {
  if (!props.enabled) return <>{props.children}</>;
  return (
    <div className='bg-team-randomizer-container relative'>
      {props.children}
    </div>
  );
}

export const TeamRandomizerAnimation = (props: {
  teamSize: number;
  colorStroke: boolean;
}): JSX.Element => {
  const { teamSize, colorStroke } = props;
  const handlePanelUIAction = useRightPanelUIAction();
  const myClientType = useMyClientType();
  const isHost = ClientTypeUtils.isHost(myClientType);
  const participants = useParticipantsAsArray({
    filters: [
      'host:false',
      'cohost:false',
      'status:connected',
      'video:true',
      'staff:false',
    ],
  });
  const me = useMyInstance();
  const [seats, setSeats] = useState<Participant[]>([]);
  useRandomizerSFX(3, 1000);

  useEffectOnce(() => {
    const _seats = [];
    const shuffled = shuffle(participants);
    let size = teamSize;
    // avoid duplication, push different participants
    while (size > 0) {
      const p = shuffled.pop();
      if (!p) break;
      _seats.push(p);
      size--;
    }
    // still have seats, randomly pick from the pool
    if (size > 0 && participants.length > 0) {
      for (let index = 0; index < size; index++) {
        _seats.push(randomPick(participants));
      }
    }
    // add current user to the seats
    if (!isHost && !me?.cohost && me) {
      const pos = Math.floor(teamSize / 2);
      if (_seats.length > pos)
        // put the current user in the center of the row
        _seats[pos] = me;
      else {
        _seats.push(me);
      }
    }
    setSeats(_seats);
  });

  useEffect(() => {
    handlePanelUIAction({ input: 'click-outside-panel' });
  }, [handlePanelUIAction]);

  return (
    <div
      className={`absolute w-screen h-full inset-0 ${
        isHost ? 'z-40' : 'z-50'
      } bg-lp-black-002 flex flex-col items-center justify-start pt-60`}
    >
      <div className='text-xl text-tertiary font-black mb-6'>
        Randomizing Everyone into Teams of {teamSize}
      </div>
      <ColorStroke enabled={colorStroke}>
        <div
          className={`team-container flex items-center justify-center ${
            colorStroke ? 'border-none' : ''
          }`}
        >
          {seats.map((p, i) => (
            <div
              key={i}
              className='w-20 h-20 rounded-full overflow-hidden mx-1 relative'
            >
              <CrowdFramesAvatar
                participant={p}
                profileIndex={ProfileIndex.wh100x100fps8}
                enablePointerEvents={false}
              />
            </div>
          ))}
        </div>
      </ColorStroke>
      <div className='text-white px-4 py-2 rounded-xl -mt-30'>
        <OneShotAnimatedImg
          src={RandomizerAnimationEveryoneUrl}
          alt='randomizer-anmiation'
        />
      </div>
    </div>
  );
};
