import { getStaticAssetPath } from '../../../../utils/assets';

export function WaitForMatch(props: { secondaryText?: string }): JSX.Element {
  return (
    <div className='w-full h-full flex flex-col items-center justify-center gap-2'>
      <img
        className='w-60 h-60'
        src={getStaticAssetPath('images/thinking-face.png')}
        alt='thinking face'
      />
      <div className='text-2xl mt-6'>All the captions have been written!</div>
      {props.secondaryText && (
        <div className='text-2xl text-tertiary text-center'>
          {props.secondaryText}
        </div>
      )}
    </div>
  );
}
