import { type RTDBServerValueTIMESTAMP } from '@lp-lib/firebase-typesafe';

import {
  type SequenceConfig,
  type TeamRelayLevel,
} from '../../../../types/block';
import { type TeamId } from '../../../../types/team';

export const MAX_ALLOWED_COLUMNS = 16;

export type Grid<T = number> = T[][];
export type PlayerId = string;

export enum RenderType {
  Tap = 1,
  HoldStart = -1,
  HoldJoin = 2,
  HoldEnd = -99,
  Placeholder = 0,
}

export const HITTABLE = new Set([
  RenderType.Tap,
  RenderType.HoldStart,
  RenderType.HoldEnd,
]);

export enum GameState {
  None = 1,
  Inited,
  InProgress,
  Ended,
}

export enum RelayNodeState {
  NotPlayed = 0,
  Hit,
  Miss,
}

export type RelayNode = {
  seqId: number;
  inputKey: string | null;
  renderType: RenderType;
};

export type Sequence = {
  grid: Grid<RelayNode>;
  direction: SequenceConfig['direction'];
};

export type TeamPlayerMap = {
  [key: TeamId]: PlayerId[];
};

export type TeamRelayGame = {
  id: string;
  state: GameState;
};

export type Progress = {
  idx: number;
  currentNodeIdx: number;
  lastNodeState: RelayNodeState;
  lastPlayerIdx?: number;
  // We use the updated timestamp for two cases,
  // 1. The updatedAt (server timstamp) is used for auto progress timeout checking (with better accuracy)
  // 2. The localUpdatedAt is used for reactive UI updating
  // Since the updatedAt will be written with RTDBServerValueTIMESTAMP, which caused the value changes events
  // fired twice, we have to keep another localUpdatedAt for UI only.
  // https://github.com/firebase/firebase-js-sdk/issues/2461
  updatedAt: number | RTDBServerValueTIMESTAMP;
  localUpdatedAt: number;
  initedGameTime: number; // the time comes from the game timer
};

export type GameProgressDetail = {
  [key: TeamId]: Nullable<Progress>;
};

export type GameTeamInfo = {
  [key: TeamId]: {
    playerIds: PlayerId[];
    sequence: Sequence;
  };
};

export type GameProgressSummary = {
  [key: TeamId]: {
    numOfSequenceFinished: number;
    score: number;
  };
};

export type GameSettings = {
  level: TeamRelayLevel;
  totalGameTime: number;
  pointsPerSequence: number;
  wrongTurnPenalty: boolean;
};

export type SequenceGridParams = {
  rows: number;
  cols: number;
  holdNode: {
    num: number;
    minSpans?: number;
    maxSpans?: number;
  };
};

export const ColorPalette = {
  Background: [
    'linear-gradient(135deg, #38E7FF 20.83%, #00414A 76.04%)',
    'linear-gradient(135deg, #FF56BB 20.83%, #540032 76.04%)',
    'linear-gradient(135deg, #FFD260 20.83%, #855F00 76.04%)',
    'linear-gradient(135deg, #68F6A1 20.83%, #005823 76.04%)',
    'linear-gradient(135deg, #426BFF 20.83%, #001A77 76.04%)',
    'linear-gradient(135deg, #FF7628 20.83%, #6D2800 76.04%)',
    'linear-gradient(135deg, #CA59FF 20.83%, #440064 76.04%)',
    'linear-gradient(135deg, #FF2323 20.83%, #530000 76.04%)',
  ],
  Outline: [
    'linear-gradient(135deg, #00414A 20.83%, #38E7FF 76.04%)',
    'linear-gradient(135deg, #540032 20.83%, #FF56BB 76.04%)',
    'linear-gradient(135deg, #855F00 20.83%, #FFD260 76.04%)',
    'linear-gradient(135deg, #005823 20.83%, #68F6A1 76.04%)',
    'linear-gradient(135deg, #001A77 20.83%, #426BFF 76.04%)',
    'linear-gradient(135deg, #6D2800 20.83%, #FF7628 76.04%)',
    'linear-gradient(135deg, #440064 20.83%, #CA59FF 76.04%)',
    'linear-gradient(135deg, #530000 20.83%, #FF2323 76.04%)',
  ],
  Primary: [
    '#38E7FF',
    '#FF56BB',
    '#FFD260',
    '#68F6A1',
    '#426BFF',
    '#FF7628',
    '#CA59FF',
    '#FF2323',
  ],
};
