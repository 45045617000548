import { useLayoutEffect, useRef, useState } from 'react';

import config from '../config';
import { useLiveCallback } from '../hooks/useLiveCallback';
import { getLogger } from '../logger/logger';
import { uncheckedIndexAccess_UNSAFE } from '../utils/uncheckedIndexAccess_UNSAFE';

const RECAPTCHA_SRC_URL = 'https://www.google.com/recaptcha/api.js';

export function ReCAPTCHAContainer(props: {
  enabled: boolean;
  onReady: (grecaptcha?: ReCaptchaV2.ReCaptcha) => void;
  onExecute: (token: string) => void;
  className?: string;
}) {
  const [logger] = useState(() => getLogger().scoped('ReCaptchaContainer'));
  const ref = useRef<null | HTMLDivElement>(null);
  const onReady = useLiveCallback(() => {
    if (!window.grecaptcha) {
      logger.warn('grecaptcha not loaded at time of ready callback!');
      return;
    }

    logger.trace('grecaptcha ready');
    props.onReady(window.grecaptcha);
  });
  const onExecute = useLiveCallback((token: string) => {
    logger.trace('grecaptcha onexecute');
    props.onExecute(token);
    logger.trace('grecaptcha reset');
    window.grecaptcha.reset();
  });

  useLayoutEffect(() => {
    if (!props.enabled) {
      logger.trace('grecaptcha disabled, ready');
      onReady();
      return;
    }

    const onloadGlobalName = `recaptcha_onload_${Date.now()}`;
    uncheckedIndexAccess_UNSAFE(window)[onloadGlobalName] = onReady;

    // NOTE: this is extremely important. reCAPTCHA makes requests internally
    // and is not "ready" directly after its script tag loads. You must hook
    // into its actual onload callback to know when you can actually interact
    // with the window.grecaptcha object, which is _not_ present until the
    // library is fully loaded.
    const rcURL = new URL(RECAPTCHA_SRC_URL);
    rcURL.searchParams.append('onload', onloadGlobalName);

    const script1 = document.createElement('script');
    script1.async = true;
    script1.defer = true;
    script1.src = rcURL.toString();

    ref.current?.appendChild(script1);
  }, [logger, onReady, props.enabled]);

  const [onexecuteGlobalName] = useState(
    () => `recaptcha_onexecute_${Date.now()}`
  );
  uncheckedIndexAccess_UNSAFE(window)[onexecuteGlobalName] = onExecute;

  return (
    <div ref={ref} className={`${props.className ?? ''} pointer-events-off`}>
      <div
        className='g-recaptcha pointer-events-on'
        data-sitekey={config.misc.reCaptchaSitekey}
        data-size='invisible'
        data-theme='dark'
        data-callback={onexecuteGlobalName}
      />
    </div>
  );
}
