import { useEffect, useMemo, useState } from 'react';
import { useCounter } from 'react-use';

import { useInstance } from '../../../../hooks/useInstance';
import { createLoop } from '../../../Loop/loop';
import { CoffeeCup } from './OverRoastedCoffeeCup';
import { DispenserButton } from './OverRoastedDispenser';
import {
  useOverRoastedDerivedCup,
  useOverRoastedGamePlayAPI,
  useOverRoastedGroupTruck,
} from './OverRoastedProvider/OverRoastedGamePlayProvider';
import { CupState, DispenserState, type GroupId, type Machine } from './types';
import { OverRoastedUtils } from './utils';

function useFillingPercentage(machine: Machine): number {
  const api = useOverRoastedGamePlayAPI();

  const [updating, setUpdating] = useState(false);
  const [value, { inc }] = useCounter(1);
  const percentage = useMemo(() => {
    if (!value || !machine.cup.fillingStartedAt) return 0;

    setUpdating(true);
    const percentage = api.calFillingPercentage(machine.id);
    if (percentage === 1) setUpdating(false);
    return percentage;
  }, [api, machine.cup.fillingStartedAt, machine.id, value]);

  useEffect(() => {
    if (!updating) return;
    const cancel = createLoop({
      kind: 'raf',
      tick: inc,
      fps: 24,
    });
    return () => cancel();
  }, [inc, updating]);

  return percentage;
}

function useWatchMachine(machine: Machine, percentage: number) {
  const api = useOverRoastedGamePlayAPI();
  const servePercentage = useInstance(
    () => OverRoastedUtils.CupStyleConfig().perfectPercentage
  );

  useEffect(() => {
    if (machine.dispenser.state !== DispenserState.Filling) return;
    if (percentage < servePercentage) return;

    api.readyToServe(machine.id);
  }, [api, machine.dispenser.state, machine.id, percentage, servePercentage]);

  useEffect(() => {
    if (machine.dispenser.state !== DispenserState.Serve) return;
    if (percentage < 1) return;

    api.overfilled(machine.id);
  }, [api, machine.dispenser.state, machine.id, percentage]);
}

export function FillingState(): JSX.Element | null {
  return <div className='z-[1] w-1 bg-[#3B1E0E] absolute top-1 bottom-4'></div>;
}

export function CoffeeMachine(props: {
  groupId: GroupId;
  machine: Machine;
  showSelectCupTooltip?: boolean;
}): JSX.Element | null {
  const { groupId, machine, showSelectCupTooltip } = props;

  const api = useOverRoastedGamePlayAPI();
  const truck = useOverRoastedGroupTruck(machine.truckId);
  const cup = useOverRoastedDerivedCup(machine);
  const percentage = useFillingPercentage(machine);
  useWatchMachine(machine, percentage);

  if (!truck || !cup) return null;

  const deletable =
    (cup.ingredients?.length ?? 0) > 0 &&
    ![CupState.Deleting, CupState.Matched, CupState.Mismatched].includes(
      cup.state
    );

  const onDelete = async () => {
    api.deleteCup(machine.id);
  };

  return (
    <div className='h-full flex flex-col items-center justify-between pt-1 pb-3 relative'>
      <DispenserButton
        groupId={groupId}
        machine={machine}
        cup={cup}
        onDelete={onDelete}
      />

      {cup.state === CupState.Filling && <FillingState />}

      <CoffeeCup
        truck={truck}
        machine={machine}
        cup={cup}
        fillingPercentage={percentage}
        delete={{
          deletable,
          onDelete,
        }}
        showSelectCupTooltip={showSelectCupTooltip}
      />
    </div>
  );
}
