import { useEffect, useRef, useState } from 'react';
import { useSnapshot } from 'valtio';

import { type InstructionBlock, type InstructionCard } from '@lp-lib/game';
import { MediaType } from '@lp-lib/media';

import { useLiveCallback } from '../../../../hooks/useLiveCallback';
import { useIsCoordinator } from '../../../../hooks/useMyInstance';
import { useWindowDimensions } from '../../../../hooks/useWindowDimensions';
import { BrowserTimeoutCtrl } from '../../../../utils/BrowserTimeoutCtrl';
import { MediaPickPriorityHD, MediaUtils } from '../../../../utils/media';
import { playWithCatch } from '../../../../utils/playWithCatch';
import { ArrowLeftIcon } from '../../../icons/Arrows';
import { InstructionBlockIcon } from '../../../icons/Block/InstructionBlockIcon';
import { Loading } from '../../../Loading';
import { useParticipant } from '../../../Player';
import { useGameHostingCoordinator } from '../../GameHostingProvider';
import {
  useInstructionBlockGamePlayStore,
  useInstructionBlockV2,
} from './InstructionBlockGamePlayStore';

function MediaPreloader(props: {
  cards: InstructionCard[];
}): JSX.Element | null {
  const { cards } = props;

  return (
    <div className='hidden'>
      {cards.map((card) => {
        const media = card.media;
        const mediaUrl = MediaUtils.PickMediaUrl(media, {
          priority: MediaPickPriorityHD,
        });

        if (!media || !mediaUrl) return null;

        if (media.type === MediaType.Image) {
          return <img key={media.id} src={mediaUrl} alt='luna-park' />;
        }

        if (media.type === MediaType.Video) {
          return <video key={media.id} src={mediaUrl} />;
        }

        return null;
      })}
    </div>
  );
}

const DEFAULT_MEDIA_DISPLAY_MS = 5000;

function InstructionCardMediaV1(props: {
  card: InstructionCard;
  play: boolean;
  jumbo: boolean;
  onMediaEnded?: () => void;
}): JSX.Element | null {
  const { card, play, jumbo, onMediaEnded } = props;
  const media = card.media;
  const mediaFormat = MediaUtils.PickMediaFormat(media, {
    priority: MediaPickPriorityHD,
  });
  const mediaUrl = mediaFormat?.url ?? media?.url ?? null;

  const videoRef = useRef<HTMLVideoElement | null>(null);
  const dimensions = useWindowDimensions();

  useEffect(() => {
    if (!play) return;

    const el = videoRef.current;
    if (!el) return;

    playWithCatch(el);
    return () => {
      el.pause();
    };
  }, [play, media?.id]);

  useEffect(() => {
    if (!mediaUrl || onMediaEnded === undefined) return;

    const ctrl = new AbortController();
    const signal = ctrl.signal;

    // we should use a timeout when the media is either an image
    // or a video shorter than the default display time
    const shouldUseTimeout =
      media?.type === MediaType.Image ||
      (media?.type === MediaType.Video &&
        mediaFormat?.length !== undefined &&
        mediaFormat.length <= DEFAULT_MEDIA_DISPLAY_MS);

    if (shouldUseTimeout) {
      const ctrl = new BrowserTimeoutCtrl();
      ctrl.set(onMediaEnded, DEFAULT_MEDIA_DISPLAY_MS);
      return () => {
        ctrl.clear();
      };
    } else if (media?.type === MediaType.Video && videoRef.current) {
      // use the video's duration to determine when to call the callback
      const opts = { signal };
      videoRef.current.addEventListener('ended', onMediaEnded, opts);
      return () => {
        ctrl.abort();
      };
    }
  }, [mediaUrl, media?.type, onMediaEnded, mediaFormat?.length]);

  if (!media || !mediaUrl) return null;

  const mediaItem =
    media.type === MediaType.Image ? (
      <img
        className='w-full h-full object-contain'
        src={mediaUrl}
        alt='luna-park'
      />
    ) : media.type === MediaType.Video ? (
      <video
        // when we want callbacks, we don't loop the video so that the ended event fires.
        loop={onMediaEnded === undefined}
        muted
        ref={videoRef}
        src={mediaUrl}
        className='w-full h-full object-contain'
      />
    ) : null;

  if (!mediaItem) return null;

  if (jumbo) {
    return (
      <div
        className={`${
          dimensions.ratio > 1 ? 'h-full' : 'w-full'
        } rounded-2.5xl overflow-hidden`}
      >
        {mediaItem}
      </div>
    );
  } else {
    return (
      <div className='w-full rounded-2.5xl overflow-hidden'>
        <div className='aspect-w-16 aspect-h-9'>{mediaItem}</div>
      </div>
    );
  }
}

function InstructionCardMediaV2(props: {
  card: InstructionCard;
  play: boolean;
  onMediaEnded?: () => void;
}): JSX.Element | null {
  const { card, play, onMediaEnded } = props;
  const media = card.media;
  const mediaFormat = MediaUtils.PickMediaFormat(media, {
    priority: MediaPickPriorityHD,
  });
  const mediaUrl = mediaFormat?.url ?? media?.url ?? null;

  const videoRef = useRef<HTMLVideoElement | null>(null);

  useEffect(() => {
    if (!play) return;

    const el = videoRef.current;
    if (!el) return;

    playWithCatch(el);
    return () => {
      el.pause();
    };
  }, [play, media?.id]);

  useEffect(() => {
    if (!mediaUrl || onMediaEnded === undefined) return;

    const ctrl = new AbortController();
    const signal = ctrl.signal;

    // we should use a timeout when the media is either an image
    // or a video shorter than the default display time
    const shouldUseTimeout =
      media?.type === MediaType.Image ||
      (media?.type === MediaType.Video &&
        mediaFormat?.length !== undefined &&
        mediaFormat.length <= DEFAULT_MEDIA_DISPLAY_MS);

    if (shouldUseTimeout) {
      const ctrl = new BrowserTimeoutCtrl();
      ctrl.set(onMediaEnded, DEFAULT_MEDIA_DISPLAY_MS);
      return () => {
        ctrl.clear();
      };
    } else if (media?.type === MediaType.Video && videoRef.current) {
      // use the video's duration to determine when to call the callback
      const opts = { signal };
      videoRef.current.addEventListener('ended', onMediaEnded, opts);
      return () => {
        ctrl.abort();
      };
    }
  }, [mediaUrl, media?.type, onMediaEnded, mediaFormat?.length]);

  if (!media || !mediaUrl) return null;

  return media.type === MediaType.Image ? (
    <img
      src={mediaUrl}
      alt='luna-park'
      className='w-full h-auto rounded-lg border border-black'
      style={{ aspectRatio: '16 / 9' }}
    />
  ) : media.type === MediaType.Video ? (
    <video
      // when we want callbacks, we don't loop the video so that the ended event fires.
      loop={onMediaEnded === undefined}
      muted
      ref={videoRef}
      src={mediaUrl}
      className='w-full h-auto rounded-lg border border-black'
      style={{ aspectRatio: '16 / 9' }}
    />
  ) : null;
}

function InstructionCardDetail(props: {
  index: number;
  card: InstructionCard;
  play: boolean;
}): JSX.Element | null {
  const { index, card, play } = props;
  const media = card.media;
  const mediaFormat = MediaUtils.PickMediaFormat(media, {
    priority: MediaPickPriorityHD,
  });
  const mediaUrl = mediaFormat?.url ?? media?.url ?? null;

  if (!media || !mediaUrl || media.type === MediaType.Audio) return null;

  return (
    <div className='w-full flex items-center gap-4 min-h-20 md:min-h-24 lg:min-h-32 xl:min-h-36 lp-sm:min-h-40'>
      <div className='flex-1 flex items-center'>
        {card.text.length > 0 && (
          <div className='flex items-start'>
            <span className='mr-2.5'>{index + 1}.</span>
            <span className='flex-1'>{card.text}</span>
          </div>
        )}
      </div>
      <div className='flex-none w-1/3 max-w-75'>
        <InstructionCardMediaV2 card={card} play={play} />
      </div>
    </div>
  );
}

function InstructionBlockCardsV1(props: {
  block: InstructionBlock;
  isPaused?: boolean;
  onClickCard?: (card: InstructionCard, index: number) => void;
}): JSX.Element {
  const { block, isPaused, onClickCard } = props;

  const api = useInstructionBlockGamePlayStore(block);
  const { cards, currentCardIndex, currentCard } = useSnapshot(
    api.state
  ) as typeof api.state;
  const [hasInteraction, setHasInteraction] = useState(false);
  const instructionListRef = useRef<HTMLDivElement | null>(null);

  const autoAdvance = () => {
    if (currentCardIndex === cards.length - 1) api.showCard(0);
    else api.nextCard();
  };

  const onClickCardText = useLiveCallback((index: number) => {
    setHasInteraction(true);
    onClickCard?.(cards[index], index);
    api.showCard(index);
  });

  // auto scroll in case we're auto advancing
  useEffect(() => {
    if (
      hasInteraction ||
      !instructionListRef.current ||
      !instructionListRef.current.children[currentCardIndex]
    )
      return;

    instructionListRef.current.children[currentCardIndex].scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
    });
  }, [currentCardIndex, hasInteraction]);

  const showJumboCard = cards.length === 1 && cards[0].text.length === 0;

  return (
    <div
      className={`px-4 w-full h-full min-h-0 ${
        showJumboCard ? '' : 'grid grid-cols-4 gap-1'
      }`}
    >
      <div
        ref={instructionListRef}
        className={`${
          showJumboCard ? 'hidden' : 'col-span-1'
        } scrollbar overflow-y-scroll`}
      >
        {cards.map((card, index) => (
          <div
            key={card.id}
            className={`
                  py-4 px-2 rounded-xl
                  cursor-pointer transition-colors
                  ${index === currentCardIndex ? 'bg-lp-gray-002' : ''}
                  text-sms lg:text-base
                `}
            onClick={() => onClickCardText(index)}
          >
            <p className='text-white font-bold'>
              {card.text || `Instructions ${index + 1}`}
            </p>
          </div>
        ))}
      </div>
      {currentCard && (
        <div className={`${showJumboCard ? 'w-full' : 'col-span-3'} h-full`}>
          <InstructionCardMediaV1
            card={currentCard}
            play={!isPaused}
            jumbo={showJumboCard}
            onMediaEnded={hasInteraction ? undefined : autoAdvance}
          />
        </div>
      )}
      <MediaPreloader cards={cards} />
    </div>
  );
}

function InstructionBlockCardsV2(props: {
  block: InstructionBlock;
  isPaused?: boolean;
  hostedTutorialAccessory?: React.ReactNode;
}): JSX.Element {
  const { block, isPaused, hostedTutorialAccessory } = props;

  const api = useInstructionBlockGamePlayStore(block);
  const { cards } = useSnapshot(api.state) as typeof api.state;

  return (
    <div className='w-full h-full min-h-0 flex items-center justify-center gap-5 p-2 px-14'>
      <div className='w-full h-full flex flex-col justify-start'>
        <div
          className={`
            w-full min-h-0 flex flex-col justify-center
            font-Montserrat font-bold
            text-base md:text-lg lg:text-xl xl:text-1.5xl lp-sm:text-2xl
          `}
        >
          <div className='flex-1 overflow-y-scroll scrollbar'>
            <div className='space-y-2.5 pr-1.5'>
              {cards.map((card, index) => (
                <InstructionCardDetail
                  key={card.id}
                  index={index}
                  card={card}
                  play={!isPaused}
                />
              ))}
            </div>
            {hostedTutorialAccessory}
          </div>
        </div>
      </div>
    </div>
  );
}

export function InstructionBlockCards(props: {
  block: InstructionBlock;
  isPaused?: boolean;
  onClickCard?: (card: InstructionCard, index: number) => void;
  hostedTutorialAccessory?: React.ReactNode;
}): JSX.Element {
  const v2Enabled = useInstructionBlockV2();
  return v2Enabled ? (
    <InstructionBlockCardsV2 {...props} />
  ) : (
    <InstructionBlockCardsV1 {...props} />
  );
}

export function HostedTutorialAccessory(props: {
  hidden: boolean;
}): JSX.Element | null {
  const coordinator = useGameHostingCoordinator();
  const coordinatorParticipant = useParticipant(coordinator?.clientId);
  const isCoordinator = useIsCoordinator();
  const v2Enabled = useInstructionBlockV2();
  if (props.hidden) return null;

  return v2Enabled ? (
    <div className='pt-7 pb-4 font-sans text-center text-sm font-medium text-icon-gray tracking-wide'>
      Still not sure how to play?{' '}
      {isCoordinator ? (
        <>Check the “Include Hosted Tutorial” box</>
      ) : (
        <>
          Ask {coordinatorParticipant?.firstName ?? 'the organizer'} to play the
          tutorial
        </>
      )}
    </div>
  ) : (
    <div className='text-2xs font-medium text-tertiary text-right truncate'>
      Still not sure how to play?
      <br />
      {isCoordinator ? (
        <>Check the “Include Hosted Tutorial” box</>
      ) : (
        <>
          Ask {coordinatorParticipant?.firstName ?? 'the organizer'} to play the
          tutorial
        </>
      )}
    </div>
  );
}

function InstructionBlockMainPanelV1(props: {
  title?: string | React.ReactNode | null;
  children?: React.ReactNode;
  leftAccessory?: React.ReactNode;
  rightAccessory?: React.ReactNode;
}): JSX.Element {
  const { title, leftAccessory, rightAccessory, children } = props;
  return (
    <div className='w-full h-full animate-fade-in'>
      <div className='w-full h-full flex flex-col gap-5 py-5'>
        <div className='grid grid-cols-4 gap-1 px-5'>
          {(leftAccessory || rightAccessory) && (
            <div className='col-span-1'>{leftAccessory}</div>
          )}
          <div
            className={`${
              leftAccessory || rightAccessory ? 'col-span-2' : 'col-span-full'
            } text-white text-xl tracking-wide font-bold text-center`}
          >
            {title}
          </div>
          {(leftAccessory || rightAccessory) && (
            <div className='col-span-1'>{rightAccessory}</div>
          )}
        </div>

        <div className='w-full h-full min-h-0'>{children}</div>
      </div>
    </div>
  );
}

function InstructionBlockMainPanelV2(props: {
  title?: string | React.ReactNode | null;
  onCancel?: () => void;
  children?: React.ReactNode;
}): JSX.Element {
  const { title, onCancel, children } = props;
  return (
    <div className='relative h-full animate-fade-in'>
      <div className='absolute -top-6'>
        {onCancel && (
          <button
            type='button'
            className='btn text-sms text-white tracking-wider font-medium flex items-center gap-1'
            onClick={onCancel}
          >
            <ArrowLeftIcon />
            Cancel
          </button>
        )}
      </div>
      <div className='w-full h-full flex flex-col'>
        {title && (
          <div
            className={`
              py-3.5 px-5
              bg-layer-001
              font-bold font-Montserrat
              rounded-tl-lg rounded-tr-lg
              text-center text-white
              text-base md:text-lg lg:text-xl xl:text-1.5xl lp-sm:text-2xl
            `}
          >
            <div className='flex items-center justify-center gap-2.5'>
              <InstructionBlockIcon className='w-6 h-6 fill-current' />
              <div>{title}</div>
            </div>
          </div>
        )}
        <div className='w-full h-full min-h-0'>{children}</div>
      </div>
    </div>
  );
}

export function InstructionBlockMainPanel(props: {
  title?: string | React.ReactNode | null;
  children?: React.ReactNode;
  onCancel?: () => void;
  leftAccessory?: React.ReactNode;
  rightAccessory?: React.ReactNode;
}): JSX.Element | null {
  const { title, leftAccessory, rightAccessory, children, onCancel } = props;
  const v2Enabled = useInstructionBlockV2();

  return (
    <div className='col-span-3 h-full rounded-lg bg-layer-001 bg-opacity-80'>
      {v2Enabled === null ? (
        <div className='w-full h-full flex items-center justify-center'>
          <Loading text='' />
        </div>
      ) : v2Enabled ? (
        <InstructionBlockMainPanelV2 title={title} onCancel={onCancel}>
          {children}
        </InstructionBlockMainPanelV2>
      ) : (
        <InstructionBlockMainPanelV1
          title={title}
          leftAccessory={leftAccessory}
          rightAccessory={rightAccessory}
        >
          {children}
        </InstructionBlockMainPanelV1>
      )}
    </div>
  );
}
