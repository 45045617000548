import { useRaf } from 'react-use';

const defaultColors: [number, number, number][] = [
  [105, 179, 76], // #69B34C
  [172, 179, 52], // #ACB334
  [250, 183, 52], // #FAB734
  [250, 13, 13], // #FF0D0D
];

// adapted from: https://github.com/vydimitrov/react-countdown-circle-timer/blob/master/packages/shared/src/useCountdown.ts
function linearEase(
  time: number,
  start: number,
  goal: number,
  duration: number
) {
  if (duration === 0) {
    return start;
  }

  const currentTime = time / duration;
  return start + goal * currentTime;
}

function tween(
  colors: [number, number, number][],
  timeMs: number,
  durationMs: number
): [number, number, number] {
  if (timeMs === 0 || durationMs === 0) return colors[0];
  if (timeMs === durationMs) return colors[colors.length - 1];

  const durationPerColorMs = durationMs / colors.length;

  const currentColorIndex = Math.floor(colors.length * (timeMs / durationMs));
  const nextColorIndex = currentColorIndex + 1;

  if (nextColorIndex === colors.length) {
    return colors[currentColorIndex];
  }

  const currentColor = colors[currentColorIndex];
  const nextColor = colors[nextColorIndex];

  return currentColor.map(
    (color, index) =>
      linearEase(
        timeMs - currentColorIndex * durationPerColorMs,
        color,
        nextColor[index] - color,
        durationPerColorMs
      ) | 0
  ) as [number, number, number];
}

function Wheel(props: {
  fillPercentage: number;
  stroke: string;
  className?: string;
}): JSX.Element {
  const circumference = 314;
  const offset = (1 - props.fillPercentage / 100) * circumference;

  // Note(falcon): this is built around a 100x100 square. the wheel can
  // be scaled by setting the classname.
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      className={`${props.className} filter drop-shadow-2xl`}
      viewBox='0 0 100 100'
      style={{
        transform: 'rotateY(-180deg) rotateZ(-90deg)',
      }}
    >
      <defs>
        <clipPath id='c'>
          <circle cx='50' cy='50' r='46' />
        </clipPath>
      </defs>

      <circle r='50' cx='50' cy='50' className='fill-black' />

      <circle
        r='50'
        cx='50'
        cy='50'
        style={{
          strokeWidth: 100,
          strokeDasharray: circumference,
          strokeDashoffset: offset,
          fill: 'none',
          stroke: props.stroke,
        }}
        clipPath='url(#c)'
      />
    </svg>
  );
}

export function StaminaWheel(props: {
  durationMs: number;
  className?: string;
}): JSX.Element {
  const elapsed = useRaf(props.durationMs, 0);
  const fillPercentage = (1 - elapsed) * 100;
  const strokeRGB = tween(
    defaultColors,
    props.durationMs * elapsed,
    props.durationMs
  );
  const stroke = `rgb(${strokeRGB.join(',')})`;
  return <Wheel fillPercentage={fillPercentage} stroke={stroke} />;
}
