import { useEffect, useRef } from 'react';

// This is based on the technique used in
// https://gist.github.com/jakearchibald/cb03f15670817001b1157e62a076fe95 and
// the accompanying video/explaination
// https://www.youtube.com/watch?v=MCi6AZMkxcU. I've expanded it to include
// pausable and resumable animation within a React hook. The gist is that by
// scheduling the future animation frame using setTimeout, we can avoid ticking
// at screen refresh rate constantly and rely on C++ to efficiently schedule the
// tick.

export function useInfrequentAnimationFrame(
  onFrame: (time: number) => void,
  frequencyMs: number,
  paused: boolean
): null {
  const infoRef = useRef<InfrequentAnimationFrameInfo>({
    resumableDelay: 0,
    targetNext: 0,
    timeAtAbort: 0,
  });

  const onFrameRef = useRef(onFrame);

  useEffect(() => {
    onFrameRef.current = onFrame;
  }, [onFrame]);

  useEffect(() => {
    const controller = new AbortController();
    if (paused) {
      controller.abort();
      return;
    }

    infrequentAnimationFrame(
      // inline the call so that the latest version of onFrameRef.current is
      // always used instead of the value at the time of the initial scheduling.
      (time) => onFrameRef.current(time),
      frequencyMs,
      controller.signal,
      infoRef.current
    );

    return () => {
      controller.abort();
    };
  }, [frequencyMs, paused]);

  return null;
}

type InfrequentAnimationFrameInfo = {
  resumableDelay: null | number;
  timeAtAbort: null | number;
  targetNext: number;
};

function infrequentAnimationFrame(
  onFrame: (time: number) => void,
  frequencyMs: number,
  signal: AbortSignal,
  info: InfrequentAnimationFrameInfo
) {
  const start =
    document.timeline && document.timeline.currentTime !== null
      ? Number(document.timeline.currentTime)
      : window.performance.now();

  function frame(time: number) {
    if (signal.aborted) return;
    onFrame(time);
    scheduleFrame(time);
  }

  const onAbort = () => {
    info.timeAtAbort = performance.now();
    info.resumableDelay = info.targetNext - performance.now();
    signal.removeEventListener('abort', onAbort);
  };

  signal.addEventListener('abort', onAbort);

  function scheduleFrame(time: number) {
    const elapsed = time - start;
    const roundedElapsed = Math.round(elapsed / frequencyMs) * frequencyMs;
    const targetNext = start + roundedElapsed + frequencyMs;
    const delay =
      info.resumableDelay !== null
        ? info.resumableDelay
        : targetNext - performance.now();
    info.targetNext = targetNext;
    info.timeAtAbort = info.resumableDelay = null;
    setTimeout(() => requestAnimationFrame(frame), delay);
  }

  scheduleFrame(start);
}
