export type TaskStep = 'countdown' | 'randomize' | 'results';

export enum ToolStep {
  Randomizer,
  ShowResult,
}

export type Task = {
  id: string;
  step: TaskStep;
  targetTeamSize: number;
  countdownSec: number;
  icebreakerSec: number;
  notificationStyle: 'notification' | 'switch-notice' | 'disabled';
  showIcebreakerTimer: boolean;
  showResults: boolean;
  showAnimation: boolean;
  icebreakerSkippableBy?: string;
  icebreakerSkippableEnabled?: boolean;
};

export type CreateTaskPayload = Pick<
  Task,
  | 'step'
  | 'targetTeamSize'
  | 'notificationStyle'
  | 'showIcebreakerTimer'
  | 'showResults'
  | 'showAnimation'
> & {
  maxTeamSize: number;
  countdownSec?: number;
  icebreakerSec?: number;
  icebreakerSkippableBy?: string;
  icebreakerSkippableEnabled?: boolean;
};
