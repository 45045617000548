import { useCallback } from 'react';

import {
  type DtoChannel,
  type DtoNewMember,
  type DtoSlackUser,
} from '@lp-lib/api-service-client/public';

import { apiService } from '../../../services/api-service';
import { type Organizer } from '../../../types';

export function useInviteUsers(): (
  orgId: string,
  channelId: string,
  users: DtoSlackUser[],
  options?: {
    removeUnmatched?: boolean;
    skipJoinExGroup?: boolean;
  }
) => Promise<Organizer[]> {
  return useCallback(
    async (
      orgId: string,
      channelId: string,
      users: DtoSlackUser[],
      options?: {
        removeUnmatched?: boolean;
        skipJoinExGroup?: boolean;
      }
    ) => {
      const resp = await apiService.organization.inviteOrganizers(orgId, {
        invitedUsers: users.map((u) => ({
          email: u.email,
          fullName: u.fullName,
          exUserId: u.id,
        })),
        webEndpoint: window.location.origin,
        sendEmail: true,
      });
      const organizers = resp.data.organizers;
      const newMembers = users.map<DtoNewMember>((u) => {
        const organizer = organizers.find((o) => o.exUserId === u.id);
        const uid = organizer?.uid;
        return {
          uid: uid ?? null,
          exUserId: u.id,
          isNewInvited: resp.data.newInviteeUids.includes(uid ?? ''),
        };
      });
      await apiService.channel.addMembers(channelId, {
        members: newMembers,
        removeUnmatched: options?.removeUnmatched,
        skipJoinExGroup: options?.skipJoinExGroup,
      });
      const newInvitees: Organizer[] = [];
      for (const uid of resp.data.newInviteeUids) {
        const organizer = organizers.find((o) => o.uid === uid);
        if (organizer) newInvitees.push(organizer);
      }
      return newInvitees;
    },
    []
  );
}

export function useInviteUsersFromChannel(): (
  channel: DtoChannel,
  options?: {
    removeUnmatched?: boolean;
  }
) => Promise<Organizer[]> {
  const inviteUsers = useInviteUsers();
  return useCallback(
    async (
      channel: DtoChannel,
      options?: {
        removeUnmatched?: boolean;
      }
    ) => {
      if (!channel.exGroupId) throw new Error('connect failed');
      const resp = await apiService.slack.queryUsers({
        type: 'byChannelId',
        channelId: channel.exGroupId,
        orgId: channel.orgId,
      });
      return await inviteUsers(channel.orgId, channel.id, resp.data.users, {
        removeUnmatched: options?.removeUnmatched,
        skipJoinExGroup: true,
      });
    },
    [inviteUsers]
  );
}
