import { useCallback, useEffect, useRef } from 'react';
import { useThrottledCallback } from 'use-debounce';

import { type Logger } from '@lp-lib/logger-base';

export function useThrottledCounterLog(
  name: string,
  logger: Logger,
  maxWaitMs = 2000
): (count: number) => void {
  const counterRef = useRef(0);
  const kickDebugLogger = useThrottledCallback(
    () => {
      logger.info(`${name} ${counterRef.current}`, {
        count: counterRef.current,
      });
      counterRef.current = 0;
    },
    maxWaitMs,
    { leading: false, trailing: true }
  );

  useEffect(() => {
    return () => {
      kickDebugLogger.flush();
    };
  }, [kickDebugLogger]);

  return useCallback(
    (count: number) => {
      counterRef.current += count;
      kickDebugLogger();
    },
    [kickDebugLogger]
  );
}
