import { useCallback, useEffect, useRef, useState } from 'react';

import { useInstance } from '../../hooks/useInstance';
import { assertExhaustive } from '../../utils/common';
import { usePersistentPoints } from '../PersistentPoints/Provider';
import { usePixelFxDraw, usePixelFxUpdate } from '../PixelFx/PixelFxProvider';
import { useSoundEffect } from '../SFX';
import { AssetMap } from './AssetMap';
import { GainPointsAnimation } from './GainPointsAnimation';

function preloadRequiredAssets(assetMap: AssetMap) {
  return Promise.all([assetMap.preload()] as const);
}

type PlayState = 'none' | 'waiting' | 'ready' | 'running' | 'finished';

function shouldTickForPlayState(state: PlayState) {
  switch (state) {
    case 'none':
    case 'waiting':
      return false;
    case 'ready':
    case 'running':
    case 'finished':
      return true;

    default:
      assertExhaustive(state);
  }
  return false;
}

type StartGainPointsAnimationProps = {
  initialPoints: number;
  gainedPoints: number;
  targetX: number;
  targetY: number;
  spawnCenterX: number;
  spawnCenterY: number;
  spawnHeight: number;
};

type StartAnimation = (props: StartGainPointsAnimationProps) => Promise<void>;
type SetAnimationTarget = (x: number, y: number) => void;
type ResetAnimation = () => void;

/**
 * This hook manages the persistent state for the animation across the entire
 * app. There can only be one instance of the animation running at a time.
 */
export function useGainPointsAnimation(): readonly [
  PlayState,
  StartAnimation,
  SetAnimationTarget,
  ResetAnimation
] {
  const animRef = useRef<GainPointsAnimation | null>(null);
  const { setOverriddenPoints, setPointsBadgeScale } = usePersistentPoints();
  const assetMap = useInstance(() => new AssetMap());
  const [playState, setPlayState] = useState<PlayState>('none');
  const { play: playHit } = useSoundEffect('gainPointsHit');
  const { play: playDone } = useSoundEffect('gainPointsDone');

  useEffect(() => {
    preloadRequiredAssets(assetMap);
  }, [assetMap]);

  const reset: ResetAnimation = useCallback(() => {
    if (animRef.current) {
      // Only reset to the start state if we had an animation in progress.
      animRef.current.shutdown();
      animRef.current = null;
      setOverriddenPoints(null);
      setPointsBadgeScale(1);

      // HACK(drew): PixelFx only clears the canvas before drawing, and it only
      // draws if there is a draw function registered (as an optimization). This
      // particular animation can potentially get shutdown "focibly" if it runs
      // for too long. Therefore, wait a few frames before finally marking the
      // animation as "done" so that a draw function remains registered (even
      // though `animRef.current` will be `null`) via `usePixelFxDraw` so the
      // canvas is cleared regardless. If there are multiple PixelFx animations,
      // then this will not be needed since rendering will continue.
      setTimeout(() => {
        setPlayState('none');
      }, 100);
    }
  }, [setOverriddenPoints, setPointsBadgeScale]);

  const start: StartAnimation = useCallback(
    async (props) => {
      if (animRef.current) return;

      setOverriddenPoints(props.initialPoints);
      const anim = (animRef.current = new GainPointsAnimation(
        assetMap,
        props.gainedPoints,
        props.spawnCenterX,
        props.spawnCenterY,
        props.spawnHeight,
        props.targetX,
        props.targetY
      ));
      setPlayState('waiting');

      let mostlyDone = false;

      anim.signals.on('ready', () => setPlayState('ready'));
      anim.signals.on('request-shutdown', () => {
        setPlayState('finished');
        reset();
      });

      anim.signals.on('orb-hit', (points) => {
        if (!mostlyDone && points > 0) {
          playHit();
        }
        setOverriddenPoints((p) => (p !== null ? p + points : p));
      });

      const onAllMostlyGained = () => {
        anim.signals.off('all-mostly-gained', onAllMostlyGained);
        mostlyDone = true;

        const MAX_POINTS_BADGE_SCALE = 1.5;
        const DURATION_AT_MAX_MS = 1000;

        playDone();
        setPointsBadgeScale(MAX_POINTS_BADGE_SCALE);
        setTimeout(() => {
          setPointsBadgeScale(1);
        }, DURATION_AT_MAX_MS);
      };

      anim.signals.on('all-mostly-gained', onAllMostlyGained);
    },
    [
      assetMap,
      playDone,
      playHit,
      reset,
      setOverriddenPoints,
      setPointsBadgeScale,
    ]
  );

  useEffect(() => {
    // cleanup on unmount
    return () => {
      reset();
    };
  }, [reset]);

  const updateTarget: SetAnimationTarget = useCallback((x, y) => {
    if (!animRef.current) return;
    animRef.current.updateTarget(x, y);
  }, []);

  usePixelFxDraw(
    shouldTickForPlayState(playState)
      ? (interp, dprCanvas, dt) => animRef.current?.draw(interp, dprCanvas, dt)
      : null
  );
  usePixelFxUpdate(
    shouldTickForPlayState(playState)
      ? (dt) => animRef.current?.update(dt)
      : null
  );

  return [playState, start, updateTarget, reset] as const;
}
