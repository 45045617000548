import 'firebase/database';

import noop from 'lodash/noop';
import React, {
  type ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { proxy } from 'valtio';

import config from '../../config';
import { useCountdown } from '../../hooks/useCountdown';
import { useLiveCallback } from '../../hooks/useLiveCallback';
import logger from '../../logger/logger';
import { NotificationType } from '../../types';
import { type TeamRandomizationSettings } from '../../types/game';
import { BrowserIntervalCtrl } from '../../utils/BrowserIntervalCtrl';
import { assertExhaustive, sleep, uuidv4 } from '../../utils/common';
import { markSnapshottable, ValtioUtils } from '../../utils/valtio';
import { type FirebaseSafeRef } from '../Firebase';
import {
  useDatabaseSafeRef,
  useFirebaseContext,
  useIsFirebaseConnected,
} from '../Firebase/Context';
import { type RandomizeConfig } from '../Game/Blocks/Randomizer/types';
import { selectOptionalConfig } from '../Game/Blocks/Randomizer/utils';
import { useNotificationDataSource } from '../Notification/Context';
import { useParticipantsAsArrayGetter } from '../Player';
import { useAwaitSwitchNotice } from '../SwitchNotice';
import {
  useConnectedTeamMemberCountsGetter,
  useCreateTeam,
  useJoinTeam,
} from '../TeamAPI/TeamV1';
import { useVenueId } from '../Venue/VenueProvider';
import { random } from './randomizer';
import { type CreateTaskPayload, type Task, type TaskStep } from './types';
import { TeamAssignmentGenerator, type TeamAssignmentRequest } from './utils';

const log = logger.scoped('team-randomizer');

type Cleanup = () => Promise<void>;

type FinishState = 'done' | 'skip' | 'aborted';

type Finalizer = (doCleanup: boolean) => Promise<FinishState>;

type TeamRandomizerAPI = {
  cleanup: Cleanup;
  randomize: (
    payload: CreateTaskPayload,
    animationSec?: number
  ) => Promise<Finalizer>;
  skipIcebreaker: () => void;
  getTask: () => Task | null;
  enableSkipIcebreaker: (enable: boolean) => void;
  skipIcebreakerIsEnabled: () => boolean;
};

type TeamRandomizerContext = TeamRandomizerAPI & {
  task: Task | null;
  countdownSec: number;
  icebreakerSec: number;
};

const Context = React.createContext<TeamRandomizerContext | null>(null);

function useTeamRandomizerContext(): TeamRandomizerContext {
  const ctx = useContext(Context);
  if (!ctx) throw new Error('TeamRandomizerContext is not in the tree!');
  return ctx;
}

export function useTeamRandomizerAPI(): TeamRandomizerAPI {
  const ctx = useTeamRandomizerContext();
  return {
    cleanup: ctx.cleanup,
    randomize: ctx.randomize,
    skipIcebreaker: ctx.skipIcebreaker,
    skipIcebreakerIsEnabled: ctx.skipIcebreakerIsEnabled,
    enableSkipIcebreaker: ctx.enableSkipIcebreaker,
    getTask: ctx.getTask,
  };
}

export function useTeamRandomizerTask(): Task | null {
  const ctx = useTeamRandomizerContext();
  return ctx.task;
}

function useTeamRandomizerFBRef(): FirebaseSafeRef<Task> {
  const venueId = useVenueId();
  return useDatabaseSafeRef<Task>(`team-randomizer/${venueId}`);
}

export function useTeamRandomizerStepDetail(): {
  taskId: string;
  step: TaskStep;
  timestep: number;
} | null {
  const { task, countdownSec, icebreakerSec } = useTeamRandomizerContext();
  return useMemo(() => {
    if (!task?.step) return null;
    switch (task.step) {
      case 'countdown':
        return { taskId: task.id, step: task.step, timestep: countdownSec };
      case 'randomize':
        return { taskId: task.id, step: task.step, timestep: countdownSec };
      case 'results':
        return { taskId: task.id, step: task.step, timestep: icebreakerSec };
      default:
        assertExhaustive(task.step);
        return null;
    }
  }, [countdownSec, icebreakerSec, task?.id, task?.step]);
}

export function useShowingRandomizerResult(): boolean {
  const { task } = useTeamRandomizerContext();
  return task?.step === 'results';
}

export const TeamRandomizerProvider = (props: {
  venueId: string;
  children?: ReactNode;
}): JSX.Element => {
  const firebaseConnected = useIsFirebaseConnected();
  const [task, setTask] = useState<Task | null>(null);
  const [countdownSec, countdownTimer] = useCountdown(0);
  const [icebreakerSec, icebreakerTimer] = useCountdown(0);
  const { dismissByType: dismissNotificationByType, send: sendNotification } =
    useNotificationDataSource();
  const ref = useTeamRandomizerFBRef();
  const icebreakerAborterRef = useRef<AbortController | null>(null);
  const createTeam = useCreateTeam();
  const ctrl = useRef<BrowserIntervalCtrl | null>(null);
  const switchNotice = useAwaitSwitchNotice();
  const { emitter } = useFirebaseContext();
  const getParticipants = useParticipantsAsArrayGetter();
  const joinTeam = useJoinTeam();

  useEffect(() => {
    return () => {
      countdownTimer.stop();
      icebreakerTimer.stop();
    };
  }, [countdownTimer, icebreakerTimer]);

  useEffect(() => {
    if (!task?.step) {
      countdownTimer.stop();
      icebreakerTimer.stop();
    } else if (task?.step === 'countdown') {
      countdownTimer.start({
        reset: true,
        newInitial: task.countdownSec,
      });
    } else if (task?.step === 'results') {
      icebreakerTimer.start({
        reset: true,
        newInitial: task.icebreakerSec,
      });
    }
  }, [
    task?.step,
    task?.countdownSec,
    task?.icebreakerSec,
    countdownTimer,
    icebreakerTimer,
  ]);

  useEffect(() => {
    if (!firebaseConnected) return;
    async function init() {
      const snapshot = await ref.get();
      const data = snapshot.val();
      setTask(data);
      ref.on('value', (snap) => {
        const data = snap.val();
        setTask(data);
      });
      log.debug('context initialized');
    }
    init();
    return () => {
      setTask(null);
      ref.off();
      log.debug('context released');
    };
  }, [ref, firebaseConnected]);

  const createTask = useCallback(
    async (payload: CreateTaskPayload): Promise<Task> => {
      const task: Task = {
        id: uuidv4(),
        step: payload.step,
        targetTeamSize: payload.targetTeamSize,
        countdownSec: payload.countdownSec ?? config.team.randomizer.countdown,
        icebreakerSec: payload.icebreakerSec ?? 0,
        notificationStyle: payload.notificationStyle,
        showIcebreakerTimer: payload.showIcebreakerTimer,
        showResults: payload.showResults,
        showAnimation: payload.showAnimation,
      };
      if (payload.icebreakerSkippableBy) {
        task.icebreakerSkippableBy = payload.icebreakerSkippableBy;
        task.icebreakerSkippableEnabled = payload.icebreakerSkippableEnabled;
      }
      setTask(task);
      icebreakerAborterRef.current = new AbortController();
      await ref.set(task);
      await ref.onDisconnect().remove();
      return task;
    },
    [ref]
  );

  const cleanup = useCallback(async () => {
    setTask(null);
    if (icebreakerAborterRef.current) {
      icebreakerAborterRef.current.abort('aborted');
    }
    icebreakerAborterRef.current = null;
    ctrl.current?.clear();
    ctrl.current = null;
    dismissNotificationByType(NotificationType.SkipTeamRandomizerIcebreaker);
    countdownTimer.reset();
    icebreakerTimer.reset();
    await ref.remove();
    await ref.onDisconnect().cancel();
  }, [countdownTimer, dismissNotificationByType, icebreakerTimer, ref]);

  const updateTask = useCallback(
    async (t: Partial<Task>) => {
      setTask((prev) => {
        if (!prev) return null;
        return { ...prev, ...t };
      });
      await ref.update(t);
    },
    [ref]
  );

  const getTeamMemberCounts = useConnectedTeamMemberCountsGetter();

  const randomize = useCallback(
    async (
      payload: CreateTaskPayload,
      animationSec = config.team.randomizer.animiationDuration
    ) => {
      await cleanup();
      log.info('creating randomize task', { payload });
      const task = await createTask(payload);
      if (task.countdownSec > 0) {
        await sleep(task.countdownSec * 1000);
        await updateTask({ step: 'randomize' });
      }

      if (payload.showAnimation) {
        await sleep(animationSec * 1000);
      }

      if (payload.targetTeamSize >= 1) {
        const participants = getParticipants({
          filters: [
            'status:connected',
            'host:false',
            'cohost:false',
            'staff:false',
            'away:false',
          ],
        });
        const generator = new TeamAssignmentGenerator((teamName, teamColor) =>
          createTeam({
            teamName,
            teamColor,
            cohost: false,
            debug: 'team-randomizer',
          })
        );
        const randomizedTeams = random(
          participants.map((p) => p.clientId),
          payload.targetTeamSize,
          payload.maxTeamSize
        ).map((team) => ({ memberClientIds: team }));

        const teamAssignment = await generator.makeTeamAssignment(
          randomizedTeams
        );

        log.info('teamAssignment', { teamAssignment });

        const joins = [];

        for (const [memberId, teamId] of Object.entries(teamAssignment)) {
          joins.push(
            joinTeam({
              teamId,
              memberId: memberId,
              debug: 'team-randomizer',
              skipUpdateLocalStorage: true,
              maxTeamSize: payload.maxTeamSize,
            })
          );

          log.info('user randomized', { teamId, memberId });
        }

        // Wait until all operations are completed
        await Promise.all(joins);

        const assignedTeamIds = new Set(Object.values(teamAssignment));

        // in case someone joined after team assignment
        let running = false;
        ctrl.current = new BrowserIntervalCtrl();
        ctrl.current.set(async () => {
          if (running) return;
          running = true;
          const participants = getParticipants({
            filters: [
              'status:connected',
              'host:false',
              'cohost:false',
              'staff:false',
              'away:false',
            ],
          });
          const memberCounts = getTeamMemberCounts();
          const joins = [];
          for (const p of participants) {
            if (
              !teamAssignment[p.clientId] &&
              (!p.teamId || !assignedTeamIds.has(p.teamId))
            ) {
              // Only use the current pool of known assigned teams. If we use
              // all teams, then we may end up assigning to a team that later
              // becomes a staff team.
              const teamIdsWithEmptySlots = Array.from(assignedTeamIds)
                .filter((teamId) => {
                  const count = memberCounts.get(teamId);
                  return count
                    ? count > 0 && count < payload.maxTeamSize
                    : false;
                })
                .sort(
                  (a, b) =>
                    (memberCounts.get(a) ?? 0) - (memberCounts.get(b) ?? 0)
                );

              const targetTeamId =
                teamIdsWithEmptySlots.length > 0
                  ? teamIdsWithEmptySlots[0]
                  : (await generator.makeNewTeam()).id;

              joins.push(
                joinTeam({
                  teamId: targetTeamId,
                  memberId: p.clientId,
                  debug: 'team-randomizer-fix',
                })
              );

              teamAssignment[p.clientId] = targetTeamId;
              assignedTeamIds.add(targetTeamId);
            }
          }
          await Promise.all(joins);
          running = false;
        }, 500);
      }

      await updateTask({ step: 'results' });

      let iceBreakerPromise: Promise<FinishState> | null = null;

      let cancel: () => void = noop;

      if (task.icebreakerSec > 0) {
        switch (payload.notificationStyle) {
          case 'notification':
            if (payload.icebreakerSkippableBy) {
              sendNotification({
                id: uuidv4(),
                type: NotificationType.SkipTeamRandomizerIcebreaker,
                toUserClientId: payload.icebreakerSkippableBy,
                createdAt: Date.now(),
              });
            }
            break;
          case 'switch-notice':
            const notice = await switchNotice({
              target: 'remote',
              countdownSec: task.icebreakerSec,
              renderer: {
                type: 'custom',
                key: 'teamRandomizerIceBreaker',
              },
            });
            cancel = notice.abort;
            break;
          case 'disabled':
            break;
          default:
            assertExhaustive(payload.notificationStyle);
            break;
        }
        const off = emitter.on('connection-state-changed', (connected) => {
          if (!connected) {
            icebreakerAborterRef.current?.abort();
          }
        });
        const resolveFinishState = (): FinishState => {
          const reason = icebreakerAborterRef.current?.signal['reason'];
          if (reason === 'aborted') {
            return 'aborted';
          } else if (reason === 'skip') {
            return 'skip';
          } else {
            log.warn('unknown abort reason', { reason });
            return 'done';
          }
        };

        iceBreakerPromise = new Promise<FinishState>((resolve) => {
          if (icebreakerAborterRef.current?.signal.aborted) {
            return resolve(resolveFinishState());
          }
          const timeout = setTimeout(
            () => resolve('done'),
            task.icebreakerSec * 1000
          );
          icebreakerAborterRef.current?.signal.addEventListener('abort', () => {
            icebreakerTimer.reset();
            clearTimeout(timeout);
            cancel();
            off();
            resolve(resolveFinishState());
          });
        });
      }

      return async (doCleanup: boolean): Promise<FinishState> => {
        if (iceBreakerPromise) {
          return await iceBreakerPromise;
        }
        if (doCleanup) await cleanup();
        return 'done';
      };
    },
    [
      cleanup,
      createTask,
      createTeam,
      emitter,
      getParticipants,
      getTeamMemberCounts,
      icebreakerTimer,
      joinTeam,
      sendNotification,
      switchNotice,
      updateTask,
    ]
  );

  const skipIcebreakerIsEnabled = useLiveCallback(
    () => task?.icebreakerSkippableEnabled ?? false
  );
  const enableSkipIcebreaker = useLiveCallback(
    (icebreakerSkippableEnabled: boolean) => {
      // note: this needs a transaction due to a race. we've observed in some cases that this update is happening
      // roughly at the same time as it is being deleted. in that case, the update will reset the task value in FB
      // but it will only set `icebreakerSkippableEnabled`, which caues the venue to enter an unrecoverable state.
      ref.transaction((current) => {
        if (!current || current.step !== 'results') return;
        return {
          ...current,
          icebreakerSkippableEnabled,
        };
      });
    }
  );

  const skipIcebreaker = useCallback(() => {
    icebreakerAborterRef.current?.abort('skip');
    dismissNotificationByType(NotificationType.SkipTeamRandomizerIcebreaker);
  }, [dismissNotificationByType]);

  const getTask = useLiveCallback(() => task);

  const ctxValue = useMemo(
    () => ({
      task,
      countdownSec,
      icebreakerSec,
      cleanup,
      randomize,
      skipIcebreaker,
      skipIcebreakerIsEnabled,
      enableSkipIcebreaker,
      getTask,
    }),
    [
      cleanup,
      countdownSec,
      enableSkipIcebreaker,
      getTask,
      icebreakerSec,
      randomize,
      skipIcebreaker,
      skipIcebreakerIsEnabled,
      task,
    ]
  );

  return <Context.Provider value={ctxValue}>{props.children}</Context.Provider>;
};

type OndTeamRandomizerState = {
  isRandomizing: boolean;
};

type OndTeamRandomizerDependencies = {
  createTeam: ReturnType<typeof useCreateTeam>;
  joinTeam: ReturnType<typeof useJoinTeam>;
  getParticipants: ReturnType<typeof useParticipantsAsArrayGetter>;
  animatedRandomize: (
    targetTeamSize: number,
    maxTeamSize: number
  ) => Promise<void>;
};

class OndTeamRandomizerAPI {
  private _state;

  constructor(private readonly deps: OndTeamRandomizerDependencies) {
    this._state = markSnapshottable(
      proxy<OndTeamRandomizerState>(this.initialState())
    );
  }

  async instantRandomize(
    targetTeamSize: number,
    maxTeamSize: number,
    usePlayerTeamNames = false
  ) {
    if (targetTeamSize <= 0 || this._state.isRandomizing) return;
    this._state.isRandomizing = true;
    try {
      await this.doRandomize(targetTeamSize, maxTeamSize, usePlayerTeamNames);
    } finally {
      this._state.isRandomizing = false;
    }
  }

  async animatedRandomize(targetTeamSize: number, maxTeamSize: number) {
    await this.deps.animatedRandomize(targetTeamSize, maxTeamSize);
  }

  async instantRandomizeWithSettings(
    settings: TeamRandomizationSettings | null | undefined,
    usePlayerTeamNames = false
  ) {
    if (this._state.isRandomizing) return;
    const recommended = this.selectRecommendedRandomizerSettings(settings);
    this._state.isRandomizing = true;
    try {
      await this.doRandomize(
        recommended.teamSize,
        recommended.maxTeamSize,
        usePlayerTeamNames
      );
    } finally {
      this._state.isRandomizing = false;
    }
  }

  selectRecommendedRandomizerSettings(
    settings: TeamRandomizationSettings | null | undefined
  ): RandomizeConfig {
    const participants = this.getParticipantsWithFilters();
    return selectOptionalConfig(settings, participants.length);
  }

  reset() {
    ValtioUtils.reset(this._state, this.initialState());
  }

  private initialState(): OndTeamRandomizerState {
    return {
      isRandomizing: false,
    };
  }

  private getParticipantsWithFilters() {
    return this.deps.getParticipants({
      filters: [
        'status:connected',
        'host:false',
        'cohost:false',
        'staff:false',
        'away:false',
      ],
    });
  }

  private async doRandomize(
    targetTeamSize: number,
    maxTeamSize: number,
    usePlayerTeamNames = false
  ) {
    const participants = this.getParticipantsWithFilters();

    const generator = new TeamAssignmentGenerator((teamName, teamColor) =>
      this.deps.createTeam({
        teamName,
        teamColor,
        cohost: false,
        debug: 'team-randomizer',
      })
    );

    const randomizedTeams = random(participants, targetTeamSize, maxTeamSize);
    const reqs: TeamAssignmentRequest[] = randomizedTeams.map((team) => {
      let teamName = null;
      if (usePlayerTeamNames && team.length > 0) {
        // use their name.
        const participant = team[0];
        teamName = `${participant.firstName ?? participant.username}'s Team`;
      }
      return {
        teamName,
        memberClientIds: team.map((p) => p.clientId),
      };
    });

    const teamAssignment = await generator.makeTeamAssignment(reqs);
    log.info('teamAssignment', { teamAssignment });

    const promises = [];

    for (const [memberId, teamId] of Object.entries(teamAssignment)) {
      promises.push(
        this.deps.joinTeam({
          teamId,
          memberId: memberId,
          debug: 'team-randomizer',
          skipUpdateLocalStorage: true,
        })
      );

      log.info('user randomized', { teamId, memberId });
    }

    await Promise.all(promises);
  }
}

export function useOndTeamRandomizerAPI(): OndTeamRandomizerAPI {
  const createTeam = useCreateTeam();
  const joinTeam = useJoinTeam();
  const getParticipants = useParticipantsAsArrayGetter();
  const api = useTeamRandomizerAPI();

  const animatedRandomize = useLiveCallback(
    async (targetTeamSize: number, maxTeamSize: number) => {
      const finalizer = await api.randomize({
        step: 'randomize',
        targetTeamSize,
        maxTeamSize,
        countdownSec: 0,
        icebreakerSec: 0,
        notificationStyle: 'switch-notice',
        showIcebreakerTimer: false,
        showResults: false,
        showAnimation: true,
      });
      await finalizer(true);
    }
  );

  return useMemo(() => {
    return new OndTeamRandomizerAPI({
      createTeam,
      joinTeam,
      getParticipants,
      animatedRandomize,
    });
  }, [animatedRandomize, createTeam, getParticipants, joinTeam]);
}
