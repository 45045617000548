import { useEffect, useMemo, useRef } from 'react';

import { useHasActiveGameStreaming } from '../../../../components/Game/GameStreamingStatusProvider';
import { useParticipantFlags } from '../../../../components/Player';
import { useTeamColor } from '../../../../components/TeamAPI/TeamV1';
import {
  type TownhallConfig,
  type TownhallMode,
  useTownhallConfig,
} from '../../../../components/Townhall';
import { useFeatureQueryParam } from '../../../../hooks/useFeatureQueryParam';
import {
  type TaskQueue,
  useStatsAwareTaskQueue,
} from '../../../../hooks/useTaskQueue';
import { type IMediaDeviceRTCService } from '../../../../services/webrtc';
import { type Participant, type Team } from '../../../../types';
import { PlayerInfoOverlay } from '../PlayerInfoOverlay';
import { useSelectStreamState } from '../RemoteStreamStateProvider';
import {
  type AudioSubscribeStrategy,
  type VideoSubscribeStrategy,
} from '../types';

function useVideoSubscribeStrategy(
  clientId: Participant['clientId'],
  params: {
    mode: TownhallMode | undefined;
    seatsCount: number;
    seatIndex: number;
    activeSpeakerThreshold: number;
    candidateSpeakers: string[];
    forceCrowdFrames: TownhallConfig['streamStrategy']['video']['forceCrowdFrames'];
    hasActiveGameStreaming: boolean;
  }
): VideoSubscribeStrategy | undefined {
  const {
    mode,
    seatsCount,
    seatIndex,
    activeSpeakerThreshold,
    candidateSpeakers,
    forceCrowdFrames,
    hasActiveGameStreaming,
  } = params;
  const keepStreamsForMostRecentSpeakers = useFeatureQueryParam(
    'townhall-keep-streams-for-most-recent-speakers'
  );
  if (!mode || forceCrowdFrames === 'enabled') return undefined;
  if (forceCrowdFrames === 'active-stream' && hasActiveGameStreaming)
    return undefined;
  if (mode === 'team') return 'auto';
  if (keepStreamsForMostRecentSpeakers) {
    // keep video streams for the most recent speakers
    const speakers = candidateSpeakers.slice(0, activeSpeakerThreshold);
    if (speakers.includes(clientId)) return 'auto';
    // if there is no enough speakers, whoever sits first has video stream on
    if (seatIndex < activeSpeakerThreshold - speakers.length) return 'auto';
  } else {
    // force using auto if num of remote users <= threshold
    if (seatsCount <= activeSpeakerThreshold) return 'auto';
  }
  return 'active-speaker';
}

export function useAudioSubscribeStrategy(
  clientId: Participant['clientId'],
  mode: TownhallMode | undefined,
  remoteUsersCount: number,
  threshold: number,
  candidateSpeakers: string[]
): AudioSubscribeStrategy | undefined {
  const keepStreamsForMostRecentSpeakers = useFeatureQueryParam(
    'townhall-keep-streams-for-most-recent-speakers'
  );
  if (!mode) return undefined;
  if (mode === 'team') return 'auto';

  // if the num of remote users is less than threshold, it's safe to auto
  // subscribe everyone.
  if (remoteUsersCount <= threshold) return 'auto';

  if (keepStreamsForMostRecentSpeakers) {
    // There are too many speakers, fallback everyone to mic-enabled strategy.
    if (candidateSpeakers.length > threshold) return 'mic-enabled';
    // Those who spoke before can retain the audio streams.
    if (candidateSpeakers.includes(clientId)) return 'auto';
    // Only subscribe the audio for thos who didn't speak before.
    return 'mic-enabled';
  }

  return 'mic-enabled';
}

function useSubscribeVideo(
  rtc: IMediaDeviceRTCService,
  clientId: Participant['clientId'],
  joined: boolean,
  videoStrategy: VideoSubscribeStrategy | undefined,
  queue: TaskQueue
) {
  const { addTask } = queue;
  const memberId = clientId;
  const streamState = useSelectStreamState(memberId);

  useEffect(() => {
    return () => {
      rtc.unsubscribeVideo(memberId, 'remote-stream-unmount');
    };
  }, [memberId, rtc]);

  // auto subscribe the video stream.
  useEffect(() => {
    if (videoStrategy !== 'auto' || !streamState?.videoPublished || !joined)
      return;
    addTask(async function sub() {
      await rtc.subscribeVideo(memberId, 'auto-subscribe-strategy');
    });
    return () => {
      addTask(async function unsub() {
        await rtc.unsubscribeVideo(memberId, 'auto-subscribe-strategy');
      });
    };
  }, [
    addTask,
    memberId,
    videoStrategy,
    rtc,
    streamState?.videoPublished,
    joined,
  ]);

  // subscribe the video stream if the remote user is talking.
  useEffect(() => {
    if (
      videoStrategy !== 'active-speaker' ||
      !streamState?.videoPublished ||
      !streamState.volume ||
      !joined
    )
      return;
    addTask(async function sub() {
      await rtc.subscribeVideo(memberId);
    });
    return () => {
      addTask(async function unsub() {
        await rtc.unsubscribeVideo(memberId);
      });
    };
  }, [
    addTask,
    memberId,
    videoStrategy,
    rtc,
    streamState?.videoPublished,
    streamState?.volume,
    joined,
  ]);
}

function useSubscribeAudio(
  rtc: IMediaDeviceRTCService,
  clientId: Participant['clientId'],
  joined: boolean,
  strategy: AudioSubscribeStrategy | undefined,
  queue: TaskQueue
) {
  const { addTask } = queue;
  const memberId = clientId;
  const streamState = useSelectStreamState(memberId);
  const flags = useParticipantFlags(memberId);
  const audioEnabled = flags?.audio;

  useEffect(() => {
    return () => {
      rtc.unsubscribeAudio(memberId, 'remote-stream-unmount');
    };
  }, [memberId, rtc]);

  // auto subscribe the audio stream.
  useEffect(() => {
    if (strategy !== 'auto' || !streamState?.audioPublished || !joined) return;
    addTask(async function sub() {
      await rtc.subscribeAudio(memberId, 'auto-subscribe-strategy');
    });
    return () => {
      addTask(async function unsub() {
        await rtc.unsubscribeAudio(memberId, 'auto-subscribe-strategy');
      });
    };
  }, [addTask, memberId, strategy, rtc, streamState?.audioPublished, joined]);

  // subscribe the audio stream if the remote user turned mic on.
  useEffect(() => {
    if (
      strategy !== 'mic-enabled' ||
      !streamState?.audioPublished ||
      !audioEnabled ||
      !joined
    )
      return;
    addTask(async function sub() {
      await rtc.subscribeAudio(memberId, 'mic-enabled-strategy');
    });
    return () => {
      addTask(async function unsub() {
        await rtc.unsubscribeAudio(memberId, 'mic-enabled-strategy');
      });
    };
  }, [
    addTask,
    memberId,
    rtc,
    joined,
    strategy,
    streamState?.audioPublished,
    audioEnabled,
  ]);
}

export function RemoteStreamSubscribeProvider(props: {
  rtc: IMediaDeviceRTCService;
  joined: boolean;
  clientId: Participant['clientId'];
  mode: TownhallMode | undefined;
  remoteSeatsCount: number;
  remoteSeatIndex: number;
  remoteUsersCount: number;
  candidateSpeakers: string[];
  children: (stategy: {
    video?: VideoSubscribeStrategy;
    audio?: AudioSubscribeStrategy;
  }) => JSX.Element;
}): JSX.Element {
  const {
    rtc,
    joined,
    clientId,
    mode,
    remoteSeatsCount,
    remoteSeatIndex,
    candidateSpeakers,
    remoteUsersCount,
  } = props;
  const config = useTownhallConfig();
  const hasActiveGameStreaming = useHasActiveGameStreaming();
  const videoStrategy = useVideoSubscribeStrategy(clientId, {
    mode,
    seatsCount: remoteSeatsCount,
    seatIndex: remoteSeatIndex,
    forceCrowdFrames: config.streamStrategy.video.forceCrowdFrames,
    activeSpeakerThreshold: config.streamStrategy.video.activeSpeakerThreshold,
    candidateSpeakers,
    hasActiveGameStreaming,
  });
  useSubscribeVideo(
    rtc,
    clientId,
    joined,
    videoStrategy,
    useStatsAwareTaskQueue({
      shouldProcess: true,
      stats: 'task-queue-remote-stream-sub-video-ms',
    })
  );

  const audioStrategy = useAudioSubscribeStrategy(
    clientId,
    mode,
    remoteUsersCount,
    config.streamStrategy.audio.micEnabledThreshold,
    candidateSpeakers
  );

  useSubscribeAudio(
    rtc,
    clientId,
    joined,
    audioStrategy,
    useStatsAwareTaskQueue({
      shouldProcess: true,
      stats: 'task-queue-remote-stream-sub-audio-ms',
    })
  );

  const strategy = useMemo(() => {
    rtc.log.info('subscribe strategy changed', {
      video: videoStrategy,
      audio: audioStrategy,
      memberId: clientId,
    });
    return { video: videoStrategy, audio: audioStrategy };
  }, [rtc.log, videoStrategy, audioStrategy, clientId]);

  return props.children(strategy);
}

type RemoteStillPlayingProps = {
  className: string;
  widthPx: number | undefined;
  heightPx: number | undefined;
  targetClientId: Participant['clientId'];
  targetTeamId: Nullable<Team['id']>;
};
export function RemoteStillPlaying(
  props: RemoteStillPlayingProps
): JSX.Element {
  const { className, targetClientId, targetTeamId, widthPx, heightPx } = props;
  const teamColor = useTeamColor(targetTeamId);
  const ref = useRef<HTMLDivElement>(null);
  const containerWidth = ref.current?.clientWidth;
  const fontSize = Math.min(containerWidth ? containerWidth / 8 : 13, 13);
  return (
    <div
      ref={ref}
      className={`bg-team-member-still-playing flex items-center justify-center
      rounded-full relative group-1 overflow-hidden border-2 animate-shimmer ${className}`}
      style={{
        borderColor: teamColor,
        width: widthPx,
        height: heightPx,
      }}
    >
      <div
        className='text-icon-gray italic'
        style={{
          fontSize,
        }}
      >
        Still Playing
      </div>
      <PlayerInfoOverlay clientId={targetClientId} teamId={targetTeamId} />
    </div>
  );
}
