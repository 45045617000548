import { useEffect, useMemo, useState } from 'react';

import {
  MediaFormatVersion,
  MediaType,
  type RapidBlock,
  type RapidBlockDetailScore,
  RapidBlockGameSessionStatus,
} from '@lp-lib/game';

import type { TeamId } from '../../../../types';
import { MediaUtils } from '../../../../utils/media';
import { DoubleRightArrow } from '../../../icons/Arrows';
import { MediaTypeIcon } from '../../../icons/MediaTypeIcon';
import { PlayIcon } from '../../../icons/PlayIcon';
import { RefreshIcon } from '../../../icons/RefreshIcon';
import { ScoreboardIcon } from '../../../icons/ScoreboardIcon';
import { FilledSquareIcon } from '../../../icons/SquareIcon';
import { TimerIcon } from '../../../icons/TimerIcon';
import {
  useDetailScores,
  useGameSessionBlock,
  useGameSessionLocalTimer,
  useGameSessionStatus,
  useIsEndedBlock,
} from '../../hooks';
import {
  countdown,
  next,
  present,
  replayVideo,
  triggerBlockTitleAnim,
} from '../../store';
import {
  BlockControllerActionButton,
  BlockControllerActionNone,
  BlockControllerActionTimeRemaining,
  type ControllerProps,
  formatVideoCounting,
  MediaPreviewerV1,
  StreamRequiredNotice,
  useBlockControllerBlockTitlePlaying,
} from '../Common/Controller/Internal';
import { BlockKnifeUtils } from '../Shared';
import { RBSubmissionsView } from './RapidSubmissionsView';

export function RapidBlockTeamProgressSummary(props: {
  block: RapidBlock;
  teamId: TeamId;
}) {
  const scores = useDetailScores<RapidBlockDetailScore>(props.block.id);
  const numCorrect = useMemo(() => {
    return scores?.[props.teamId]?.corrects ?? 0;
  }, [scores, props.teamId]);
  return <>Progress: {numCorrect} correct</>;
}

export const RapidBlockController = (
  props: ControllerProps<RapidBlock>
): JSX.Element => {
  const {
    selectedBlockIndex,
    setEditPointsData,
    setShowResetConfirmation,
    onEndBlock,
  } = props;
  const [showVideoCounting, setShowVideoCounting] = useState(0);
  const [showImageCounting, setShowImageCounting] = useState(0);
  const [isVideoPlayed, setIsVideoPlayed] = useState(false);

  const gameSessionStatus = useGameSessionStatus<RapidBlockGameSessionStatus>();
  const gameSessionBlock = useGameSessionBlock() as RapidBlock;
  const gameSessionLocalTimer = useGameSessionLocalTimer();
  const block = props.selectedBlock;
  const isEndedBlock = useIsEndedBlock(block.id);

  useEffect(() => {
    if (
      gameSessionStatus === null ||
      gameSessionStatus === undefined ||
      gameSessionStatus === RapidBlockGameSessionStatus.LOADED ||
      gameSessionStatus === RapidBlockGameSessionStatus.END
    ) {
      setShowVideoCounting(0);
      setIsVideoPlayed(false);
    }
  }, [gameSessionStatus]);

  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout> | null = null;

    if (showVideoCounting > 0) {
      timeout = setTimeout(() => {
        if (showVideoCounting <= 0) {
          if (timeout) {
            clearTimeout(timeout);
          }
        }
        setShowVideoCounting(showVideoCounting - 1);
        if (showVideoCounting === 1 && !isVideoPlayed) {
          setIsVideoPlayed(true);
        }
      }, 1000);
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [isVideoPlayed, showVideoCounting]);

  useEffect(() => {
    let interval: ReturnType<typeof setInterval> | null = null;

    if (showImageCounting > 0) {
      interval = setInterval(() => {
        if (showImageCounting <= 0) {
          if (interval) {
            clearInterval(interval);
          }
          setShowImageCounting(showImageCounting - 1);
        } else {
          setShowImageCounting(showImageCounting - 1);
        }
      }, 1000);
    }

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [showImageCounting]);

  const blockTitlePlayingAction = useBlockControllerBlockTitlePlaying(block);

  if (!block) return <></>;

  const questionMedia = BlockKnifeUtils.Media(block, 'questionMedia');
  const mediaUrl =
    MediaUtils.PickMediaUrl(questionMedia, {
      priority: [MediaFormatVersion.SM],
      videoThumbnail: 'first',
    }) ?? null;
  const mediaType = questionMedia?.type ?? null;
  const isVideo = mediaType === MediaType.Video;

  const onPresent = async () => {
    await triggerBlockTitleAnim(block);
    await present(block, () => {
      if (isVideo && !block.fields.startVideoWithTimer) {
        setShowVideoCounting(
          MediaUtils.GetAVPlayingDurationSeconds(questionMedia)
        );
      }
    });
  };

  const onReplayVideo = async () => {
    if (isVideo) {
      await replayVideo(false, () =>
        setShowVideoCounting(
          MediaUtils.GetAVPlayingDurationSeconds(questionMedia)
        )
      );
    }
  };

  const onShowScoreboard = async () => {
    await next();
  };

  const onCountDown = async () => {
    await countdown({ debug: 'RapidBlockController' });
  };

  const onRevealAnswers = async () => {
    await next({
      overrideNextStatus: RapidBlockGameSessionStatus.RESULTS,
      afterUpdate: async () => {
        const answerMedia = block.fields.answerMedia;
        if (!answerMedia) return;

        if (answerMedia.type === MediaType.Video) {
          setShowVideoCounting &&
            setShowVideoCounting(
              MediaUtils.GetAVPlayingDurationSeconds(answerMedia)
            );
        } else if (answerMedia.type === MediaType.Image) {
          setShowImageCounting && setShowImageCounting(3);
        }
      },
    });
  };

  const onDistributePoints = async () => {
    await next({
      overrideNextStatus: RapidBlockGameSessionStatus.POINTS_DISTRIBUTED,
    });
  };

  const questionTextSize =
    block.fields.question.length < 200 ? 'text-base' : '';

  let contentComponent = (
    <div className='relative px-2.5 w-full flex flex-col justify-center items-center overflow-ellipsis overflow-hidden'>
      <div className='h-11' />
      {mediaUrl && mediaType ? (
        <MediaPreviewerV1
          media={{ url: mediaUrl, type: mediaType }}
          alt={block.type}
          isVideoPlayed={isVideoPlayed}
          allowReplay={showVideoCounting <= 0}
          onReplayVideo={onReplayVideo}
        />
      ) : null}
      <p
        className={`w-full mt-3 hyphens-auto ${questionTextSize} flex flex-col justify-center items-center text-center`}
      >
        {block.fields.question}
      </p>
    </div>
  );

  let actionComponent = (
    <BlockControllerActionButton onClick={onPresent} icon={PlayIcon}>
      {isVideo && !block.fields.startVideoWithTimer
        ? 'Play Question Video'
        : 'Present Question'}
    </BlockControllerActionButton>
  );

  if (isEndedBlock) {
    contentComponent = (
      <RBSubmissionsView
        block={block}
        view='result'
        setEditPointsData={setEditPointsData}
        selectedBlockId={block.id}
      />
    );
    actionComponent = (
      <BlockControllerActionButton
        onClick={() => setShowResetConfirmation(true)}
        icon={RefreshIcon}
        isDelete={true}
      >
        Reset Completed Block
      </BlockControllerActionButton>
    );
  }

  if (gameSessionStatus && block.id === gameSessionBlock?.id) {
    switch (gameSessionStatus) {
      case RapidBlockGameSessionStatus.PRESENTING:
        actionComponent =
          showVideoCounting > 0 ? (
            <BlockControllerActionNone icon={PlayIcon}>
              Video is Playing {formatVideoCounting(showVideoCounting)}
            </BlockControllerActionNone>
          ) : (
            <BlockControllerActionButton onClick={onCountDown} icon={TimerIcon}>
              {isVideo && block.fields.startVideoWithTimer
                ? `Start Video & Timer (${block.fields.questionTime}s)`
                : `Start Submission Timer (${block.fields.questionTime}s)`}
            </BlockControllerActionButton>
          );
        break;
      case RapidBlockGameSessionStatus.QUESTION_COUNTING:
        contentComponent = <RBSubmissionsView block={gameSessionBlock} />;
        actionComponent = (
          <BlockControllerActionTimeRemaining
            remainingSec={gameSessionLocalTimer}
          />
        );
        break;
      case RapidBlockGameSessionStatus.QUESTION_END:
        contentComponent = <RBSubmissionsView block={gameSessionBlock} />;
        actionComponent = (
          <BlockControllerActionButton
            onClick={onDistributePoints}
            icon={DoubleRightArrow}
          >
            Distribute Points
          </BlockControllerActionButton>
        );
        break;
      case RapidBlockGameSessionStatus.RESULTS:
        contentComponent = (
          <RBSubmissionsView
            block={gameSessionBlock}
            view='result'
            setEditPointsData={setEditPointsData}
          />
        );
        if (showVideoCounting > 0) {
          actionComponent = (
            <BlockControllerActionNone icon={PlayIcon}>
              Video is Playing {formatVideoCounting(showVideoCounting)}
            </BlockControllerActionNone>
          );
        } else if (showImageCounting > 0) {
          actionComponent = (
            <BlockControllerActionNone
              icon={(props) => (
                <MediaTypeIcon {...props} type={MediaType.Image} />
              )}
            >{`Image is Showing (${showImageCounting}s)`}</BlockControllerActionNone>
          );
        } else {
          actionComponent = (
            <BlockControllerActionButton
              onClick={onEndBlock}
              icon={DoubleRightArrow}
            >
              End Block Sequence
            </BlockControllerActionButton>
          );
        }
        break;
      case RapidBlockGameSessionStatus.POINTS_DISTRIBUTED:
        contentComponent = (
          <RBSubmissionsView
            block={gameSessionBlock}
            view='result'
            setEditPointsData={setEditPointsData}
          />
        );
        actionComponent = (
          <div className='w-full flex flex-col justify-center items-center'>
            {block.fields.scoreboard ? (
              <>
                <BlockControllerActionButton
                  onClick={onEndBlock}
                  isSecondary={true}
                >
                  End Block Sequence
                </BlockControllerActionButton>
                <BlockControllerActionButton
                  onClick={onShowScoreboard}
                  icon={ScoreboardIcon}
                >
                  Show Scoreboard
                </BlockControllerActionButton>
              </>
            ) : (
              <>
                <BlockControllerActionButton
                  onClick={onShowScoreboard}
                  isSecondary={true}
                >
                  Show Scoreboard
                </BlockControllerActionButton>
                <BlockControllerActionButton
                  onClick={onRevealAnswers}
                  icon={FilledSquareIcon}
                >
                  Reveal Correct Answers
                </BlockControllerActionButton>
              </>
            )}
          </div>
        );

        break;
      case RapidBlockGameSessionStatus.SCOREBOARD:
        contentComponent = (
          <RBSubmissionsView
            block={gameSessionBlock}
            view='result'
            setEditPointsData={setEditPointsData}
          />
        );
        actionComponent = (
          <BlockControllerActionButton
            onClick={onEndBlock}
            icon={FilledSquareIcon}
          >
            End Block Sequence
          </BlockControllerActionButton>
        );
    }
  }

  // NOTE: this must be outside of the main gameSessionStatus truthy check
  // because it mainly activates on the transition from LOADED (0), which is
  // falsy.
  actionComponent = blockTitlePlayingAction ?? actionComponent;

  return (
    <>
      {selectedBlockIndex !== null ? (
        <div className='w-full pt-0 pb-2.5 relative h-full flex flex-col justify-between items-center text-white text-sms overflow-hidden'>
          {contentComponent}
          <div className='w-full px-2.5'>{actionComponent}</div>
          <StreamRequiredNotice />
        </div>
      ) : null}
    </>
  );
};
