import { type EnumsExConnectType } from '@lp-lib/api-service-client/public';

import { getInitials } from '../../utils/string';

type Props = {
  exConnectType: Nullable<EnumsExConnectType>;
  hideIfNoExConnection?: boolean;
  member: {
    icon?: string | null;
    fullName: string;
  };
  className?: string;
};

function Render(props: Props): JSX.Element | null {
  const { exConnectType, hideIfNoExConnection, member } = props;
  if (!exConnectType && hideIfNoExConnection) return null;
  if (member.icon)
    return (
      <img
        src={member.icon}
        alt='avatar'
        className='w-full h-full rounded-full'
      />
    );
  return (
    <div className='w-full h-full bg-black flex items-center justify-center rounded-full border border-secondary'>
      {getInitials(member.fullName)}
    </div>
  );
}

export function ChannelMemberAvatar(props: Props): JSX.Element {
  return (
    <div
      className={`rounded-full text-2xs flex-none flex items-center justify-center w-5 h-5 mr-3 ${
        props.className ?? ''
      }`}
    >
      <Render {...props} />
    </div>
  );
}
