import React, { type ReactNode, useMemo } from 'react';
import { Provider as ReduxProvider } from 'react-redux';

import { useUserAnalytics } from '../../analytics/user';
import { store } from '../../store/configureStore';
import { setAPIServiceClientSecureToken } from '../../utils/setAPIClientToken';
import { AdminAccess } from '../Access';
import { UserAccess } from '../Access/UserAccess';
import { ConfirmCancelModalProvider } from '../ConfirmCancelModalContext';
import { ProvidersList } from '../ProvidersList';
import { UserContextProvider } from '../UserContext';

setAPIServiceClientSecureToken();

class BlockStoreAPI {}

type Context = {
  api: BlockStoreAPI;
};

const context = React.createContext<Context | null>(null);

export function BlockStoreProvider(props: {
  children?: ReactNode;
}): JSX.Element | null {
  const ctx = useMemo(() => {
    return {
      api: new BlockStoreAPI(),
    };
  }, []);

  return <context.Provider value={ctx}>{props.children}</context.Provider>;
}

export function RoutedBlockProviders(props: { children?: JSX.Element }) {
  const providers = [
    <ReduxProvider store={store} children={[]} />,
    <UserContextProvider useUserAnalytics={useUserAnalytics} />,
    <ConfirmCancelModalProvider />,
    <UserAccess />,
    <AdminAccess />,
  ];

  return <ProvidersList providers={providers}>{props.children}</ProvidersList>;
}
