import { useCallback, useEffect, useState } from 'react';

import {
  type AggregatedAnswer,
  BlockType,
  type QuestionBlock,
  QuestionBlockAnswerGrade,
  type QuestionBlockDetailScore,
} from '@lp-lib/game';

import { useAggregatedAnswers } from '../../hooks';
import { grade } from '../../store';
import {
  type Grade,
  PartialCreditGradingCard,
  QuestionBlockAnswerGradeConverter,
} from '../Common/Controller/Internal';

interface QuestionBlockGradingCardProps {
  block: QuestionBlock;
  gradingCardTeamId?: string;
  setGradingCardTeamId?: (teamId: string | null) => void;
}

export const QuestionBlockGradingCard = (
  props: QuestionBlockGradingCardProps
): JSX.Element => {
  const { gradingCardTeamId, setGradingCardTeamId } = props;
  const gradingCards = useAggregatedAnswers();
  const [currentGradingCard, setCurrentGradingCard] =
    useState<AggregatedAnswer | null>(null);

  const getNextUngradedGradeData = useCallback(() => {
    if (!gradingCards) return null;

    if (gradingCardTeamId) {
      const gradedCard = gradingCards.find(
        (c) =>
          c.grade !== QuestionBlockAnswerGrade.NONE &&
          c.submitters.find((u) => u.teamId === gradingCardTeamId)
      );

      if (gradedCard) {
        return gradedCard;
      }
    }

    for (const card of gradingCards) {
      if (card && card.grade === QuestionBlockAnswerGrade.NONE) {
        return card;
      }
    }

    return null;
  }, [gradingCards, gradingCardTeamId]);

  useEffect(() => {
    if (!gradingCards) return;
    setCurrentGradingCard(getNextUngradedGradeData());
  }, [getNextUngradedGradeData, gradingCards]);

  const handleRegrade = async (newGrade: Grade) => {
    if (!currentGradingCard) return;
    const teamIds = currentGradingCard.submitters.map((s) => s.teamId) || [];
    await grade<QuestionBlock, QuestionBlockDetailScore>(
      QuestionBlockAnswerGradeConverter.To(newGrade),
      teamIds,
      BlockType.QUESTION,
      (block) => block.fields.decreasingPointsTimer ?? true,
      (block) => block.fields.time,
      (block) => block.fields.points,
      (block) => block.fields.startDescendingImmediately ?? false
    );
    setGradingCardTeamId && setGradingCardTeamId(null);
  };

  return (
    <>
      {currentGradingCard && currentGradingCard.answer && (
        <PartialCreditGradingCard
          answer={currentGradingCard.answer}
          currentGrade={QuestionBlockAnswerGradeConverter.From(
            currentGradingCard.grade
          )}
          submitters={currentGradingCard.submitters}
          onRegrade={handleRegrade}
        />
      )}
    </>
  );
};
