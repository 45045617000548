import { type DtoPromptTemplate } from '@lp-lib/api-service-client/public';
import { type Logger } from '@lp-lib/logger-base';

import { err2s } from '../../utils/common';
import { type ParsedAssistantMessage } from '../Game/Blocks/AIChat/types';
import {
  JSONOutputParser,
  type NarrowedChatCompletionMessage,
  type OutputParser,
} from '../OpenAI';

class AssistantMessageOutputParser extends JSONOutputParser {
  constructor(readonly log: Logger) {
    super();
  }
  parse(text: string): ParsedAssistantMessage {
    try {
      const parsed = super.parse(text);
      this.log.debug('parsed output', parsed);
      if (this.isSchemaValid(parsed)) {
        return parsed;
      }
      return this.fallback(text);
    } catch (error) {
      this.log.error('parse output error, fallback to default', err2s(error));
      return this.fallback(text);
    }
  }

  private fallback(text: string): ParsedAssistantMessage {
    return { completed: false, result: 'none', dialogue: text };
  }

  private isSchemaValid(parsed: unknown): parsed is ParsedAssistantMessage {
    if (!parsed || typeof parsed !== 'object') return false;
    if (!('completed' in parsed) || typeof parsed['completed'] !== 'boolean')
      return false;
    if (!('result' in parsed) || typeof parsed['result'] !== 'string')
      return false;
    if (!('dialogue' in parsed) || typeof parsed['dialogue'] !== 'string')
      return false;
    return true;
  }
}

// class OpenAIMessageOutputParser {
//   constructor(
//     readonly log: Logger,
//     private assistantMessageParser = new AssistantMessageOutputParser(log)
//   ) {}

//   parse(body: Record<string, unknown>): NarrowedChatCompletionMessage {
//     try {
//       if (this.isSchemaValid(body)) {
//         return body;
//       }
//       return this.fallback(text);
//     } catch (error) {
//       this.log.error('parse output error, fallback to default', err2s(error));
//       return this.fallback(text);
//     }
//   }

//   private isSchemaValid(
//     body: Record<string, unknown>
//   ): body is NarrowedChatCompletionMessage {
//     if (!body || typeof body !== 'object') return false;
//     if (typeof body['content'] !== 'string') return false;
//     if (typeof body['role'] !== 'string') return false;
//     return true;
//   }
// }

export class AIChatParserUtils {
  static GetOutputParser(
    template: Nullable<DtoPromptTemplate>,
    log: Logger
  ): OutputParser<ParsedAssistantMessage> {
    log.info('template', { template });
    return new AssistantMessageOutputParser(log);
  }

  static IsOpenAIMessage(
    body: Record<string, unknown>
  ): body is NarrowedChatCompletionMessage {
    if (!body || typeof body !== 'object') return false;
    if (typeof body['content'] !== 'string') return false;
    if (typeof body['role'] !== 'string') return false;
    return true;
  }
}
