import { type ReactNode, useEffect, useRef, useState } from 'react';

import { useForceUpdate } from '../../hooks/useForceUpdate';
import { useLiveCallback } from '../../hooks/useLiveCallback';
import { useDeviceAPI } from './Context';
import { useMustVirtualBackgroundMixer } from './video-stream-mixer';

type Props = {
  joined: boolean;
  mirror: boolean;
  show: boolean;
  mediaStream: MediaStream | null;
  deviceId: string | undefined;
  virtualBackground?: boolean;
  renderable: boolean;
  onOutputMediaStreamChanged?: (mediaStream: MediaStream | null) => void;
};

function mirrorToStyle(mirror?: boolean) {
  return mirror ? { transform: 'rotateY(180deg)' } : undefined;
}

export function VideoPreview(props: Props) {
  if (props.virtualBackground) {
    return <VideoPreviewWithMixer {...props} />;
  } else {
    return <VideoPreviewSimple {...props} />;
  }
}

function useJoinAwaredTrack(mediaStream: MediaStream | null, joined: boolean) {
  const [track, setTrack] = useState<MediaStreamTrack | null>(null);
  const mixer = useDeviceAPI().mixer;
  const vbgMixer = useMustVirtualBackgroundMixer(mixer);

  useEffect(() => {
    if (!joined) {
      if (!mediaStream) return;
      mixer
        .updateCameraTrack(mediaStream.getVideoTracks()[0] ?? null)
        .then(() => setTrack(mixer.outputVideoTrack));
      return;
    }

    // if user has joined venue, the _mixer.updateCameraTrack_ is handled by
    // the singleton video track manager, we should handle the preview only
    // here.
    setTrack(vbgMixer.virtualBackgroundTrack);
    return vbgMixer.on('virtual-background-track-updated', (t) => setTrack(t));
  }, [joined, mediaStream, mixer, vbgMixer]);

  return track;
}

function VideoPreviewWithMixer(props: Props) {
  const { show, mirror, mediaStream, renderable } = props;
  const ref = useRef<HTMLVideoElement>(null);
  const track = useJoinAwaredTrack(mediaStream, props.joined);

  const onOutputMediaStreamChanged = useLiveCallback(
    (mediaStream: MediaStream | null) => {
      props.onOutputMediaStreamChanged?.(mediaStream);
    }
  );

  useEffect(() => {
    if (!renderable) return;
    async function exec() {
      const s = new MediaStream();
      if (track) {
        s.addTrack(track);
      }
      if (ref.current) ref.current.srcObject = s;
      onOutputMediaStreamChanged(s);
    }
    exec();
  }, [onOutputMediaStreamChanged, renderable, track]);

  return (
    <video
      className={`w-full h-full object-cover rounded-xl z-10 left-0 top-0 ${
        show ? 'block' : 'hidden'
      }`}
      style={mirrorToStyle(mirror)}
      ref={ref}
      playsInline
      autoPlay
      muted
      preload='metadata'
    />
  );
}

function VideoPreviewSimple(props: Props) {
  const { show, mirror, mediaStream, renderable } = props;
  const ref = useRef<HTMLVideoElement>(null);
  const forceUpdate = useForceUpdate();

  useEffect(() => {
    if (!mediaStream || !ref.current || !renderable) return;
    ref.current.srcObject = mediaStream;
    forceUpdate();
  }, [renderable, forceUpdate, mediaStream]);

  return (
    <video
      className={`w-full h-full object-cover rounded-xl z-10 left-0 top-0 ${
        show ? 'block' : 'hidden'
      }`}
      style={mirrorToStyle(mirror)}
      ref={ref}
      playsInline
      autoPlay
      muted
      preload='metadata'
    />
  );
}

export function VideoPreviewMini(props: {
  mirror: boolean;
  mediaStream: MediaStream | null;
  placeholder?: ReactNode;
}) {
  const { mirror, mediaStream } = props;
  const ref = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    if (!mediaStream || !ref.current) return;
    ref.current.srcObject = mediaStream;
  }, [mediaStream]);

  return (
    <div className='flex flex-col items-center gap-2.5 w-full text-white '>
      <p className='text-3xs font-medium'>How you will look in venue:</p>
      <div
        className='w-full max-w-42 relative border-[3px] border-white-001 rounded-2.5xl overflow-hidden'
        style={{
          aspectRatio: '1/1',
        }}
      >
        <video
          ref={ref}
          className='w-full h-full object-cover z-10 absolute'
          style={{
            ...mirrorToStyle(mirror),
          }}
          playsInline
          autoPlay
          muted
          preload='metadata'
        />
        {props.placeholder}
      </div>
    </div>
  );
}
