import { useState } from 'react';

import { type HeadToHeadBlock } from '@lp-lib/game';

import { useLiveCallback } from '../../../../hooks/useLiveCallback';
import { useParticipant } from '../../../Player';
import { useGameHostingCoordinator } from '../../GameHostingProvider';
import { GamePlayFullScreenTransitionContainer } from '../Common/GamePlay/GamePlayFullScreenTransitionContainer';
import { GamePlayStageFinishedTransition } from '../Common/GamePlay/GamePlayStageFinishedTransition';
import { type TransitionInfo } from '../Common/GamePlay/useBlockAnimationExecutorRunner';

export function JudgingTransition(props: {
  block: HeadToHeadBlock;
  text: string;
  onEnd?: () => void;
}) {
  const { block, text } = props;
  const [transitionInfo, setTransitionInfo] = useState<TransitionInfo>({
    state: 'running',
    durationMs: 3000,
  });

  const onEnd = useLiveCallback(async () => {
    setTransitionInfo({
      state: 'ended',
      durationMs: 0,
    });
    props.onEnd?.();
  });

  return (
    <GamePlayStageFinishedTransition
      id={block.id}
      text={
        <div className='text-3xl flex flex-col items-center justify-center w-full h-full gap-1'>
          <div>{text}</div>
        </div>
      }
      transitionInfo={transitionInfo}
      onEnd={onEnd}
      debugName='head-to-head-round-end'
    />
  );
}

export function SelectNextGroupTransition(_props: { block: HeadToHeadBlock }) {
  const coordinator = useGameHostingCoordinator();
  const p = useParticipant(coordinator?.clientId);

  return (
    <NextTransition
      text={`${p?.firstName ?? p?.username ?? 'organizer'} is selecting next
          steps...`}
    />
  );
}

export function NextTransition(props: { text: string }) {
  return (
    <GamePlayFullScreenTransitionContainer>
      <div
        className={`w-full h-20 animatejit-[fade-in_2s_ease_1]`}
        style={{
          background:
            'linear-gradient(90deg, rgba(255, 255, 255, 0.00) 0%, rgba(255, 255, 255, 0.40) 13.02%, rgba(255, 255, 255, 0.40) 82.29%, rgba(255, 255, 255, 0.00) 100%)',
        }}
      >
        <div
          className='
            absolute top-0 left-1/2
            transform3d
            -translate-x-1/2
            w-full h-full
            flex justify-center items-center
            text-5xl italic font-bold text-white
            filter drop-shadow-lp-hard-cutout'
        >
          {props.text}
        </div>
      </div>
    </GamePlayFullScreenTransitionContainer>
  );
}
