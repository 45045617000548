import isEqual from 'lodash/isEqual';
import { useEffect, useLayoutEffect, useMemo, useRef } from 'react';
import { useEffectOnce, usePrevious } from 'react-use';

import {
  type DrawingPromptBlock,
  DrawingPromptBlockGameSessionStatus,
} from '@lp-lib/game';

import { getFeatureQueryParam } from '../../../../hooks/useFeatureQueryParam';
import { useInstance } from '../../../../hooks/useInstance';
import { useLiveCallback } from '../../../../hooks/useLiveCallback';
import { isNotStaff } from '../../../../types';
import { assertExhaustive, isSuperset } from '../../../../utils/common';
import { useParticipantsAsArray } from '../../../Player';
import { useTeams } from '../../../TeamAPI/TeamV1';
import { useTownhallAPI, useTownhallEnabled } from '../../../Townhall';
import {
  useGameSessionLocalTimer,
  useGameSessionStatus,
  useIsLiveGamePlay,
  useOndWaitMode,
} from '../../hooks';
import { ondWaitEnd } from '../../OndPhaseRunner';
import { setTimesup, triggerAllTeamsFinishedAnim } from '../../store';
import { type GameControlProps } from '../Common/GameControl/types';
import {
  useSubmissionStatusSubmittedTeamIds,
  useTeamSubmissionStatuses,
} from '../Common/GamePlay/SubmissionStatusProvider';
import { useStableBlock } from '../Common/hooks';
import {
  useAllPickedDrawings,
  useCurrMatch,
  useCurrPickedDrawing,
  useDrawingPromptGameControlAPI,
  useDrawingPromptSharedAPI,
  useDrawingSubmissionProgress,
  useDrawingTitleVotes,
  useTeamVotePhaseSkippable,
} from './DrawingPromptProvider';
import { type PickedDrawing } from './types';
import { DrawingPromptUtils, log } from './utils';

type SharedProps = GameControlProps<DrawingPromptBlock>;

function Loaded(_props: SharedProps) {
  const api = useDrawingPromptGameControlAPI();

  useEffectOnce(() => {
    api.resetGame();
  });

  return null;
}

function Init(props: SharedProps) {
  const { block } = props;
  const api = useDrawingPromptGameControlAPI();
  const teams = useTeams({
    updateStaffTeam: true,
    excludeStaffTeam: true,
  });

  useEffectOnce(() => {
    api.initGame(
      teams.map((t) => t.id),
      block.fields.prompts,
      getFeatureQueryParam('drawing-force-team-vote')
    );
  });

  return null;
}

function DrawingStart(_props: SharedProps) {
  const api = useDrawingPromptGameControlAPI();
  const players = useParticipantsAsArray({
    filters: ['host:false', 'cohost:false', 'status:connected', 'team:true'],
  });
  const timer = useGameSessionLocalTimer();
  const ids = players.filter((p) => isNotStaff(p)).map((p) => p.id);
  // track who is supposed to submit a drawing, we need to wait those submissions.
  useEffect(() => {
    if (!timer) return;
    api.drawingPlayerIds = ids;
  }, [api, ids, timer]);

  return null;
}

function DrawingEnd(_props: SharedProps) {
  const isOnDGame = !useIsLiveGamePlay();
  const { submittedPlayerIds, drawingPlayerIds } =
    useDrawingSubmissionProgress();
  const allSubmitted = useMemo(() => {
    const s1 = new Set(submittedPlayerIds);
    const s2 = new Set(drawingPlayerIds);
    return isSuperset(s1, s2);
  }, [submittedPlayerIds, drawingPlayerIds]);
  const waitMode = useOndWaitMode();

  useEffect(() => {
    if (!isOnDGame || waitMode !== 'wait') return;
    if (allSubmitted) {
      log.info('All players submitted their drawings, end wait');
      ondWaitEnd();
    } else {
      log.info('Not all players submitted their drawings, keep waiting');
    }
  }, [allSubmitted, isOnDGame, waitMode]);
  return null;
}

function TeamVoteStart(_props: SharedProps) {
  const isLiveGame = useIsLiveGamePlay();
  const pickedDrawings = useAllPickedDrawings();
  const done = useRef(false);
  const waitMode = useOndWaitMode();
  const { skippable, teamIds } = useTeamVotePhaseSkippable();

  // auto move to the next session status if all teams only have one drawing
  useEffect(() => {
    if (done.current || !skippable) return;

    const submittedTeamIds = pickedDrawings.map((t) => t.teamId).sort();
    if (!isEqual(teamIds.sort(), submittedTeamIds)) return;

    if (isLiveGame) {
      done.current = true;
      setTimesup();
    } else if (waitMode === 'wait') {
      done.current = true;
      ondWaitEnd();
    }
  }, [isLiveGame, pickedDrawings, skippable, teamIds, waitMode]);

  return null;
}

function TeamVoteDone(_props: SharedProps) {
  const api = useDrawingPromptGameControlAPI();
  const teams = useTeams({
    updateStaffTeam: true,
    excludeStaffTeam: true,
  });

  useEffectOnce(() => {
    api.configureTitleCreation(
      DrawingPromptUtils.GetTitleCreationTimeSec(teams.length)
    );
  });
  return null;
}

function useLiveTeamSubmittedTrigger(idealCountdownSec = 3) {
  const isLiveGame = useIsLiveGamePlay();
  const { sessionStatus } = useTeamSubmissionStatuses();
  const teams = useSubmissionStatusSubmittedTeamIds();
  const api = useTownhallAPI();
  const townhallEnabled = useTownhallEnabled();

  useLayoutEffect(() => {
    if (!townhallEnabled || !isLiveGame) return;
    if (
      sessionStatus ===
      DrawingPromptBlockGameSessionStatus.TITLE_CREATION_COUNTING
    ) {
      if (teams.length > 0) {
        api.setNext({
          mode: 'crowd',
          countdownSec: idealCountdownSec,
          type: 'team',
          source: 'LiveDrawingBlockTitleCreationTeamSubmitted',
          teams,
        });
      }
    }

    if (
      sessionStatus === DrawingPromptBlockGameSessionStatus.TITLE_CREATION_DONE
    ) {
      // force everyone over to crowd mode.
      api.setNext({
        mode: 'crowd',
        countdownSec: idealCountdownSec,
        type: 'global',
        source: 'LiveDrawingBlockTitleCreationTeamSubmitted',
      });
    }
  }, [
    api,
    townhallEnabled,
    teams,
    isLiveGame,
    idealCountdownSec,
    sessionStatus,
  ]);
}

function TitleCreationPhase(_props: SharedProps) {
  // OnD is handled universally in OndTownhallTriggers.tsx
  useLiveTeamSubmittedTrigger();
  return null;
}

function MatchPromptInit(_props: SharedProps) {
  const api = useDrawingPromptGameControlAPI();
  const teams = useTeams({
    updateStaffTeam: true,
    excludeStaffTeam: true,
  });

  useEffectOnce(() => {
    api.configureMatchPrompt(
      DrawingPromptUtils.GetMatchTimeSecPerDrawing(teams.length)
    );
  });

  return null;
}

function MatchPromptPhase(props: SharedProps) {
  const { block } = props;
  const sharedAPI = useDrawingPromptSharedAPI();
  const controlAPI = useDrawingPromptGameControlAPI();
  const players = useParticipantsAsArray({
    filters: [
      'host:false',
      'cohost:false',
      'status:connected',
      'team:true',
      'staff:false',
    ],
  });
  const currMatch = useCurrMatch();
  const pickedDrawing = useCurrPickedDrawing();
  const votes = useDrawingTitleVotes(pickedDrawing?.id ?? '');
  const triggered = useInstance(() => new Set());
  const revealed = useInstance(() => new Set());
  const currTime = useGameSessionLocalTimer();
  const prevTime = usePrevious(currTime);
  const timesup = prevTime === 1 && currTime === 0;

  useEffectOnce(() => {
    controlAPI.nextMatch(0);
  });

  // enter reveal phase if all players have voted
  useEffect(() => {
    if (
      currMatch.phase !== 'vote' ||
      !pickedDrawing?.id ||
      triggered.has(pickedDrawing.id)
    ) {
      return;
    }
    // all players should vote
    const s1 = new Set(
      players.filter((p) => p.teamId !== pickedDrawing.teamId).map((p) => p.id)
    );
    // all voted players
    const s2 = new Set(votes.map((v) => v.voters.map((v) => v.id)).flat());
    if (isSuperset(s2, s1)) {
      triggered.add(pickedDrawing.id);
      triggerAllTeamsFinishedAnim();
      // enter reveal phase after all team finished animation
      setTimeout(() => {
        controlAPI.revealCurrentMatch(
          currMatch.drawingIndex,
          'all players voted'
        );
      }, 3000);
    }
  }, [
    controlAPI,
    currMatch.drawingIndex,
    currMatch.phase,
    pickedDrawing?.id,
    pickedDrawing?.teamId,
    players,
    triggered,
    votes,
  ]);

  // enter reveal phase if timer is up
  useEffect(() => {
    if (
      currMatch.phase !== 'vote' ||
      !pickedDrawing?.id ||
      triggered.has(pickedDrawing.id) ||
      !timesup
    ) {
      return;
    }
    triggered.add(pickedDrawing.id);
    setTimeout(() => {
      controlAPI.revealCurrentMatch(currMatch.drawingIndex, 'times up');
    }, 1500);
  }, [
    controlAPI,
    currMatch.drawingIndex,
    currMatch.phase,
    pickedDrawing?.id,
    timesup,
    triggered,
  ]);

  const revealAndGoNext = useLiveCallback(async (drawing: PickedDrawing) => {
    const prompt = sharedAPI.getPrompt(drawing.teamId);
    const nextIndex = await controlAPI.revealVotes(
      drawing.teamId,
      drawing.id,
      prompt?.id ?? '',
      await sharedAPI.getNumOfPickedDrawings(),
      {
        correctPoints: block.fields.correctPromptPoints,
        incorrectPoints: block.fields.incorrectPromptPoints,
      }
    );
    if (nextIndex === -1) {
      await controlAPI.endMatch();
      await ondWaitEnd();
    } else {
      await controlAPI.nextMatch(nextIndex);
    }
  });

  // reveal & grade votes
  useEffect(() => {
    if (
      currMatch.phase !== 'reveal' ||
      !pickedDrawing?.id ||
      revealed.has(pickedDrawing.id)
    ) {
      return;
    }
    revealed.add(pickedDrawing.id);
    revealAndGoNext(pickedDrawing);
  }, [currMatch.phase, pickedDrawing, revealAndGoNext, revealed]);

  return null;
}

function End(_props: SharedProps) {
  const isLiveGame = useIsLiveGamePlay();
  const api = useTownhallAPI();
  const townhallEnabled = useTownhallEnabled();
  useEffect(() => {
    if (!isLiveGame || !townhallEnabled) return;
    api.setNext({
      mode: 'team',
      countdownSec: 3,
      type: 'global',
      source: 'LiveDrawingBlockEnd',
    });
  }, [api, isLiveGame, townhallEnabled]);
  return null;
}

export function DrawingPromptBlockGameControl(
  props: SharedProps
): JSX.Element | null {
  const block = useStableBlock(props.block);
  const gss = useGameSessionStatus<DrawingPromptBlockGameSessionStatus>();

  const api = useDrawingPromptGameControlAPI();

  useEffect(() => {
    return () => {
      api.resetGame();
    };
  }, [api]);

  switch (gss) {
    case DrawingPromptBlockGameSessionStatus.LOADED:
      return <Loaded block={block} />;
    case DrawingPromptBlockGameSessionStatus.INIT:
      return <Init block={block} />;
    case DrawingPromptBlockGameSessionStatus.DRAWING_START:
      return <DrawingStart block={block} />;
    case DrawingPromptBlockGameSessionStatus.DRAWING_END:
      return <DrawingEnd block={block} />;
    case DrawingPromptBlockGameSessionStatus.TEAM_VOTE_COUNTING:
      return <TeamVoteStart block={block} />;
    case DrawingPromptBlockGameSessionStatus.TEAM_VOTE_DONE:
      return <TeamVoteDone block={block} />;
    case DrawingPromptBlockGameSessionStatus.TITLE_CREATION_COUNTING:
      return <TitleCreationPhase block={block} />;
    case DrawingPromptBlockGameSessionStatus.TITLE_CREATION_DONE:
      return <TitleCreationPhase block={block} />;
    case DrawingPromptBlockGameSessionStatus.MATCH_PROMPT_INIT:
      return <MatchPromptInit block={block} />;
    case DrawingPromptBlockGameSessionStatus.MATCH_PROMPT_COUNTING:
      return <MatchPromptPhase block={block} />;
    case DrawingPromptBlockGameSessionStatus.MATCH_PROMPT_DONE:
      return <MatchPromptPhase block={block} />;
    case DrawingPromptBlockGameSessionStatus.REVIEW_ALL_DRAWINGS:
    case DrawingPromptBlockGameSessionStatus.RESULTS:
    case DrawingPromptBlockGameSessionStatus.SCOREBOARD:
      return null;
    case DrawingPromptBlockGameSessionStatus.END:
      return <End block={block} />;
    case null:
    case undefined:
      break;
    default:
      assertExhaustive(gss);
      break;
  }

  return null;
}
