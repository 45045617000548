const MarkIcon = (): JSX.Element => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='13'
      height='13'
      fill='none'
      viewBox='0 0 13 13'
    >
      <g clipPath='url(#clip0)'>
        <path
          fill='#fff'
          d='M5.445 11.366a.519.519 0 01-.368-.155L.393 6.46a.52.52 0 01.006-.733L2.25 3.9a.518.518 0 01.732.005l2.28 2.313a.259.259 0 00.369 0l3.874-3.922a.518.518 0 01.733-.005l1.85 1.828a.517.517 0 01-.006.744.533.533 0 01-.737-.022l-1.221-1.206a.346.346 0 00-.489.003l-3.82 3.869a.518.518 0 01-.369.154.518.518 0 01-.368-.154L2.85 5.248a.345.345 0 00-.488-.003l-.623.613a.345.345 0 00-.004.489l3.525 3.576a.259.259 0 00.369 0l4.322-4.377a.518.518 0 01.744.007.532.532 0 01-.022.737l-4.86 4.922a.517.517 0 01-.369.154z'
        ></path>
      </g>
      <defs>
        <clipPath id='clip0'>
          <path
            fill='#fff'
            d='M0 0H12V12H0z'
            transform='translate(.243 .428)'
          ></path>
        </clipPath>
      </defs>
    </svg>
  );
};

export { MarkIcon };
