/**
 * Track the maximum and minimum values ever passed, and when they were reached.
 */
export class MinMaxValueBox<T extends number> {
  private minTimestamp: number;
  private minBoxed: T;
  private maxTimestamp: number;
  private maxBoxed: T;

  constructor(initial: T, private getNowMs: () => number) {
    this.minBoxed = initial;
    this.maxBoxed = initial;
    this.minTimestamp = this.getNowMs();
    this.maxTimestamp = this.getNowMs();
  }

  update(value: T): void {
    if (value < this.minBoxed) {
      this.minTimestamp = this.getNowMs();
      this.minBoxed = value;
    }

    if (value > this.maxBoxed) {
      this.maxTimestamp = this.getNowMs();
      this.maxBoxed = value;
    }
  }

  get min(): T {
    return this.minBoxed;
  }

  get minWhen(): number {
    return this.minTimestamp;
  }

  get max(): T {
    return this.maxBoxed;
  }

  get maxWhen(): number {
    return this.maxTimestamp;
  }
}
