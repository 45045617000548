import { useEffect, useMemo } from 'react';

import {
  assertExhaustive,
  INSTRUCTION_MAX_DISPLAY_SECONDS,
  type InstructionBlock,
  InstructionBlockGameSessionStatus,
  type InstructionCard,
} from '@lp-lib/game';

import { useMyInstance } from '../../../../hooks/useMyInstance';
import { ClientTypeUtils } from '../../../../types';
import {
  useGameSessionStatus,
  useIsGamePlayPaused,
  useIsLiveGamePlay,
  useOndStateBlockBrandName,
  useOndStateBlockHasHostedTutorial,
  useTimerRecover,
} from '../../hooks';
import { countdownV2, resetTimer, setTimer } from '../../store';
import { type GamePlayProps } from '../Common/GamePlay/types';
import { useStableBlock } from '../Common/hooks';
import {
  HostedTutorialAccessory,
  InstructionBlockCards,
  InstructionBlockMainPanel,
} from './InstructionBlockMainPanel';
import { InstructionBlockPlaygroundLayout } from './InstructionBlockPlagroundLayout';
import {
  InstructionBlockRightPanel,
  InstructionBlockTeams,
} from './InstructionBlockRightPanel';

function useCountdown(props: {
  isAudience: boolean;
  isLive: boolean;
  gameSessionStatus: InstructionBlockGameSessionStatus | undefined | null;
}) {
  const { isAudience, isLive, gameSessionStatus } = props;

  useEffect(() => {
    switch (gameSessionStatus) {
      case InstructionBlockGameSessionStatus.LOADED:
        resetTimer('submission');
        return;
      case InstructionBlockGameSessionStatus.GAME_INIT:
        setTimer('submission', INSTRUCTION_MAX_DISPLAY_SECONDS);
        return;
      case InstructionBlockGameSessionStatus.GAME_START:
        countdownV2({
          debug: 'InstructionBlockGamePlay',
          startTimeWorker: !isAudience ? !isLive : true,
          flushCountingStatus: false,
        });
        return;
      case InstructionBlockGameSessionStatus.GAME_END:
        resetTimer('submission');
        return;
      case InstructionBlockGameSessionStatus.END:
      case null:
      case undefined:
        return;
      default:
        assertExhaustive(gameSessionStatus);
    }
  }, [gameSessionStatus, isAudience, isLive]);
}

export interface GamePlayState {
  cards: InstructionCard[];
  currentCard: InstructionCard | null;
  currentCardIndex: number;
}

export interface GamePlayDispatcher {
  nextCard: () => void;
  showCard: (index: number) => void;
}

export function InstructionBlockGamePlay(
  props: GamePlayProps<InstructionBlock>
): JSX.Element | null {
  const gameSessionBlock = useStableBlock(props.block);
  const gameSessionStatus =
    useGameSessionStatus<InstructionBlockGameSessionStatus>();

  const me = useMyInstance();
  const isAudience = ClientTypeUtils.isAudience(useMyInstance());
  const isPaused = useIsGamePlayPaused();
  const isLive = useIsLiveGamePlay();
  const brandName = useOndStateBlockBrandName();
  const hasHostedTutorial = useOndStateBlockHasHostedTutorial();
  const title = useMemo(() => {
    if (brandName) {
      return (
        <>
          How to Play <span className='text-tertiary'>{brandName}</span>
        </>
      );
    }
    return 'How to Play';
  }, [brandName]);

  useCountdown({
    isAudience,
    isLive,
    gameSessionStatus,
  });

  useTimerRecover(async (status) => {
    if (status === InstructionBlockGameSessionStatus.GAME_START) {
      return INSTRUCTION_MAX_DISPLAY_SECONDS;
    }
  });

  if (gameSessionStatus === null || gameSessionStatus === undefined)
    return null;

  const inGame = [
    InstructionBlockGameSessionStatus.GAME_INIT,
    InstructionBlockGameSessionStatus.GAME_START,
    InstructionBlockGameSessionStatus.GAME_END,
  ].includes(gameSessionStatus);

  const hostedTutorialAccessory = (
    <HostedTutorialAccessory hidden={!hasHostedTutorial} />
  );

  return (
    <div className={`fixed w-screen h-screen text-white`}>
      {inGame && me?.id && (
        <InstructionBlockPlaygroundLayout>
          <InstructionBlockMainPanel
            title={title}
            rightAccessory={hostedTutorialAccessory}
          >
            <InstructionBlockCards
              isPaused={isPaused}
              block={gameSessionBlock}
              hostedTutorialAccessory={hostedTutorialAccessory}
            />
          </InstructionBlockMainPanel>
          <InstructionBlockRightPanel>
            <InstructionBlockTeams teamId={me.teamId} />
          </InstructionBlockRightPanel>
        </InstructionBlockPlaygroundLayout>
      )}
    </div>
  );
}
