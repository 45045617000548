import GIF from 'gif.js';
import { decompressFrames, parseGIF } from 'gifuct-js';

import placeholder from '../../../assets/img/placeholder/bg1.png';
import { fromMediaDTO } from '../../../utils/api-dto';
import { xDomainifyUrl } from '../../../utils/common';
import { MediaUtils } from '../../../utils/media';
import {
  addBenchmarkCard,
  addSlideLayout,
  type AnalyticsSlideSharedProps,
  pxToInch,
  pxToPt,
} from './AnalyticsSlideShared';

const workerUrl = new URL('gif.js/dist/gif.worker.js', import.meta.url);

function createCircularGif(inputUrl: string, size: number): Promise<string> {
  return new Promise((resolve, reject) => {
    fetch(inputUrl)
      .then((response) => response.arrayBuffer())
      .then((arrayBuffer) => {
        const gif = parseGIF(arrayBuffer);
        const frames = decompressFrames(gif, true);

        const canvas = document.createElement('canvas');
        canvas.width = size;
        canvas.height = size;
        const ctx = canvas.getContext('2d');
        if (!ctx) {
          reject('Failed to get canvas context');
          return;
        }

        const frameCanvas = document.createElement('canvas');

        const gifObj = new GIF({
          workers: 2,
          quality: 100,
          width: size,
          height: size,
          workerScript: workerUrl.href,
          transparent: 'rgba(0,0,0,0)',
        });

        gifObj.on('finished', function (blob) {
          const reader = new FileReader();
          reader.onload = () => resolve(reader.result as string);
          reader.onerror = reject;
          reader.readAsDataURL(blob);
        });

        // 480x360
        frames.forEach((frame) => {
          frameCanvas.width = frame.dims.width;
          frameCanvas.height = frame.dims.height;
          const frameCtx = frameCanvas.getContext('2d');
          if (!frameCtx) return;
          frameCtx.putImageData(
            new ImageData(frame.patch, frame.dims.width, frame.dims.height),
            0,
            0
          );
          const frameSize = Math.min(frame.dims.width, frame.dims.height);
          const x = (frame.dims.width - frameSize) / 2;
          const y = (frame.dims.height - frameSize) / 2;

          ctx.clearRect(0, 0, size, size);
          ctx.save();
          ctx.beginPath();
          ctx.arc(size / 2, size / 2, size / 2, 0, Math.PI * 2);
          ctx.clip();
          ctx.drawImage(
            frameCanvas,
            x,
            y,
            frameSize,
            frameSize,
            0,
            0,
            size,
            size
          );
          ctx.restore();

          gifObj.addFrame(ctx, { copy: true, delay: frame.delay });
        });

        gifObj.render();
      })
      .catch(reject);
  });
}

export async function addSlideEngagement(props: AnalyticsSlideSharedProps) {
  const { pres, summary } = props;

  if (summary.topContributors.length === 0) {
    return;
  }

  const slide = pres.addSlide();

  addSlideLayout({
    ...props,
    slide,
    title: 'Overall Engagement Score & Top Contributors',
    subtitle:
      'The Engagement Score tracks and normalizes all value-added interactions on Luna Park and Slack.',
  });

  slide.addText('Thank you to our top engagement contributors!', {
    x: pxToInch(200),
    y: pxToInch(246),
    h: pxToInch(100),
    fontSize: pxToPt(42),
    color: 'FBB707',
    align: 'left',
    valign: 'top',
  });

  const width = 200;
  const height = 250;

  for (let i = 0; i < summary.topContributors.length; i++) {
    const contributor = summary.topContributors[i];

    const x = 180 + (i % 4) * (width + 80);
    const y = 400 + Math.floor(i / 4) * (height + 40);
    const url = MediaUtils.PickMediaUrl(
      fromMediaDTO(contributor.joyCapture?.media)
    );

    slide.addImage({
      path: url ? undefined : xDomainifyUrl(placeholder, 'analytics-slides'),
      data: url
        ? await createCircularGif(xDomainifyUrl(url, 'analytics-slides'), width)
        : undefined,
      x: pxToInch(x),
      y: pxToInch(y),
      w: pxToInch(width),
      h: pxToInch(width),
      rounding: true,
    });

    slide.addText(contributor.firstName, {
      x: pxToInch(x),
      y: pxToInch(y + width + 10),
      w: pxToInch(width),
      h: pxToInch(80),
      fontSize: pxToPt(32),
      align: 'center',
      valign: 'top',
      color: 'FFFFFF',
    });
  }

  if (summary.engagementScore) {
    const showUp =
      summary.engagementScoreBefore > 0 &&
      summary.engagementScore > summary.engagementScoreBefore;

    slide.addText(`${summary.engagementScore.toLocaleString()}`, {
      x: pxToInch(1920 - 480 - 90),
      y: pxToInch(460),
      w: showUp ? pxToInch(320) : pxToInch(480),
      h: pxToInch(180),
      fontSize: pxToPt(
        summary.engagementScore >= 1000
          ? 128
          : summary.engagementScore >= 100
          ? 166
          : 200
      ),
      bold: true,
      color: 'FBB707',
      shadow: {
        type: 'outer',
        color: '1F1F1F',
      },
      valign: 'middle',
      align: 'center',
    });
    if (showUp) {
      const percent = Math.ceil(
        ((summary.engagementScore - summary.engagementScoreBefore) /
          summary.engagementScoreBefore) *
          100
      );
      const text =
        percent < 100
          ? `Up ${percent.toFixed(0)}%`
          : `+ ${(
              summary.engagementScore - summary.engagementScoreBefore
            ).toLocaleString()}`;

      slide.addText(text, {
        x: pxToInch(1920 - 480 - 90 + 320),
        y: pxToInch(460),
        w: pxToInch(140),
        h: pxToInch(180),
        fontSize: pxToPt(28),
        color: 'FFFFFF',
        bold: true,
        valign: 'middle',
        align: 'center',
      });
    }

    slide.addText(
      showUp
        ? 'It captures game participation, chats, and other key activities to reflect team engagement and the % change in the last 3 months.'
        : 'It captures game participation, chats, and other key activities to reflect team engagement.',
      {
        x: pxToInch(1920 - 480 - 90),
        y: pxToInch(460 + 180 + 20),
        w: pxToInch(480),
        h: pxToInch(120),
        fontSize: pxToPt(20),
        color: 'FFFFFF',
        valign: 'top',
      }
    );
  }

  if (summary.engagementPercentile) {
    addBenchmarkCard({
      slide,
      x: 1920 - 480 - 90,
      y: 1080 - 158 - 120,
      w: 480,
      h: 158,
      title: `🏅 Top ${summary.engagementPercentile}%!`,
      content: `${props.organization.name} is in the top ${summary.engagementPercentile}% of all companies at Luna Park.`,
    });
  }
}
