import { isMusicShareSupported } from '../../components/MusicShareContext';
import { releaseMediaStream } from '../../utils/media';

export class MusicSharePermissionDeniedError extends Error {
  constructor(public name = 'MusicSharePermissionDeniedError') {
    super(name);
  }
}

export class MusicShareNotSupportedError extends Error {
  constructor(public name = 'MusicShareNotSupportedError') {
    super(name);
  }
}

export class MusicShareTrackEmptyError extends Error {
  constructor(public name = 'MusicShareTrackEmptyError') {
    super(`${name}: No audio track found in stream`);
  }
}

export class MusicShareCaptureMediaError extends Error {
  constructor(public name = 'MusicShareCaptureMediaError') {
    super(`${name}: No video track found in stream`);
  }
}

export class MusicShareStreamError extends Error {
  constructor(public name = 'MusicShareStreamError') {
    super(`${name}: No stream was returned from getDisplayMedia`);
  }
}

export async function createHighQualityMusicShare(): Promise<MediaStream> {
  const audioConstraints: MediaTrackConstraints = {
    channelCount: 2,
    sampleRate: 48000,
    autoGainControl: false,
    echoCancellation: true,
    noiseSuppression: false,
  };

  let stream: MediaStream | undefined;

  try {
    const gdm = navigator.mediaDevices.getDisplayMedia.bind(
      navigator.mediaDevices
    );

    stream = await gdm({
      video: true,
      audio: audioConstraints,
    });
  } catch (e: UnassertedUnknown) {
    releaseMediaStream(stream);

    // Match Agora's
    if (e.message?.match(/Permission denied/i)) {
      throw new MusicSharePermissionDeniedError();
    }

    throw e;
  }

  const audio = stream?.getAudioTracks()[0];
  const video = stream?.getVideoTracks()[0];

  if (!audio && !isMusicShareSupported()) {
    releaseMediaStream(stream);
    throw new MusicShareNotSupportedError();
  }

  if (!audio) {
    releaseMediaStream(stream);
    throw new MusicShareTrackEmptyError();
  }

  if (!video) throw new MusicShareCaptureMediaError();

  if (!stream) throw new MusicShareStreamError();

  return stream;
}
