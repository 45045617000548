export function TeamRelayBlockIcon(
  props: React.SVGProps<SVGSVGElement>
): JSX.Element {
  return (
    <svg
      className={props.className ?? 'w-3.5 h-3.5 fill-current'}
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox='0 0 14 14'
    >
      <path
        fill='url(#paint0_linear_5049_5036)'
        fillRule='evenodd'
        d='M6.718.99L1.06 6.646a.5.5 0 000 .708l5.657 5.656a.5.5 0 00.707 0l5.657-5.656a.5.5 0 000-.708L7.425.99a.5.5 0 00-.707 0zM.708 6.293a1 1 0 000 1.414l5.656 5.657a1 1 0 001.414 0l5.657-5.657a1 1 0 000-1.414L7.778.636a1 1 0 00-1.414 0L.707 6.293z'
        clipRule='evenodd'
      ></path>
      <path
        fill='#01ACC4'
        fillRule='evenodd'
        d='M2.757 6.293a1 1 0 000 1.414l3.536 3.536a1 1 0 001.414 0l3.535-3.536a1 1 0 000-1.414L7.707 2.757a1 1 0 00-1.414 0L2.757 6.293zm4.019.147V9h.68V5.7H6.86l-1.095.695.23.495.78-.45z'
        clipRule='evenodd'
      ></path>
      <defs>
        <linearGradient
          id='paint0_linear_5049_5036'
          x1='7.071'
          x2='7.071'
          y1='-0.071'
          y2='14.071'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#2BE0FA'></stop>
          <stop offset='1' stopColor='#127392'></stop>
        </linearGradient>
      </defs>
    </svg>
  );
}
