import AgoraRTC, {
  type ICameraVideoTrack,
  type ILocalVideoTrack,
  type UID,
  type VideoPlayerConfig,
} from 'agora-rtc-sdk-ng';

import { GreenScreenValuesUtils } from '@lp-lib/game';
import { type MediaFormat } from '@lp-lib/media';

import { type VideoEffectsSettings } from '../../components/VideoEffectsSettings/types';
import { VideoEffectsSettingsUtils } from '../../components/VideoEffectsSettings/VideoEffectsSettingsUtils';
import {
  SCurveCrossFade,
  type TrackId,
  TrackInitConfigBuilder,
  VideoMixer,
} from '../../components/VideoMixer';
import {
  createTrackEndAwaiter,
  createTrackFirstFrameAwaiter,
  createTrackStartAwaiter,
} from '../../components/VideoMixer/createTrackAwaiters';
import logger from '../../logger/logger';
import { xDomainifyUrl } from '../../utils/common';
import { rsCounter } from '../../utils/rstats.client';
import {
  UnplayableImageImpl,
  UnplayableVideoImpl,
} from '../../utils/unplayable';
import { MediaDeviceRTCService } from './agora';
import { type CustomVideoQualityPreset, profileForCustomVideo } from './types';

export type CameraVMMediaCtrl = {
  complete: Promise<void>;
  abort: () => void;
  trackStarted: Promise<void>;
  trackFirstFrameRendered: Promise<void>;
};

const log = logger.scoped('camera-vm');

export type CameraVideoMixerMinimalMedia = { url: string; length: number };

/**
 * This is the "public" external interface for the CameraVideoMixer. These
 * methods are relatively safe to be called from outside of a safe environment,
 * and the interfaces/return values have been designed with external consumers
 * in mind.
 */
export interface IExtCameraVideoMixer {
  updateVideoEffectsConfig(settings: VideoEffectsSettings): Promise<void>;
  playIntro(media: MediaFormat): Promise<CameraVMMediaCtrl>;
  playOutro(media: MediaFormat): Promise<CameraVMMediaCtrl>;
  playMedia(
    media: CameraVideoMixerMinimalMedia,
    fade?: { in?: boolean; out?: boolean }
  ): Promise<CameraVMMediaCtrl>;
  forceRebuildPersistentTracks(): Promise<void>;
}

const MEDIA_FADE_IN_OUT_DURATION_MS = 1000;

export class CameraVideoMixer implements IExtCameraVideoMixer {
  static VideoProfileForConfig(videoEncoderConfig: CustomVideoQualityPreset) {
    return profileForCustomVideo(videoEncoderConfig);
  }

  private vm: VideoMixer;
  private vmCameraTrackId: TrackId | null = null;
  private vmStageTrackId: TrackId | null = null;
  private vmPodiumTrack: TrackId | null = null;
  private videoEffectsSettings = VideoEffectsSettingsUtils.WithDefaults(null);
  private cameraMediaStreamTrack: MediaStreamTrack | null = null;

  constructor(videoEncoderConfig: CustomVideoQualityPreset) {
    const config = profileForCustomVideo(videoEncoderConfig);
    this.vm = new VideoMixer(
      {
        drawFps: config.framerate,
        renderWidth: config.width,
        renderHeight: config.height,
        stats: {
          beforeDraw: () => rsCounter('host-camera-mixer-draw-ms')?.start(),
          afterDraw: () => rsCounter('host-camera-mixer-draw-ms')?.end(),
          beforeTick: () => rsCounter('host-camera-mixer-tick-ms')?.start(),
          afterTick: () => rsCounter('host-camera-mixer-tick-ms')?.end(),
        },
      },
      false,
      'raf-accumulated'
    );

    this.vm.play();
  }

  async destroy(): Promise<void> {
    await this.vm.destroy();
    this.vmCameraTrackId = null;
    this.cameraMediaStreamTrack = null;
    log.debug('destroyed');
  }

  async updateVideoEffectsConfig(
    settings: VideoEffectsSettings
  ): Promise<void> {
    // TODO(drew): is there a better way to do this difffing? Arguably we could
    // just rebuild the tracks each time, but that could cause the stage to
    // restart and not loop continuously.

    const cameraDirtyProps = ['greenScreen', 'boundingBox'] as const;
    const cameraDirty = cameraDirtyProps.some(
      (p) =>
        JSON.stringify(settings[p]) !==
        JSON.stringify(this.videoEffectsSettings[p])
    );
    const podiumDirty =
      JSON.stringify(settings.podium) !==
      JSON.stringify(this.videoEffectsSettings.podium);
    const stageDirty =
      JSON.stringify(settings.stage) !==
      JSON.stringify(this.videoEffectsSettings.stage);

    this.videoEffectsSettings = settings;

    const ops = [];

    if (cameraDirty) ops.push(this.rebuildCameraTrack());
    if (stageDirty) ops.push(this.rebuildStageTrack());
    if (podiumDirty) ops.push(this.rebuildPodiumTrack());

    await Promise.all(ops);
  }

  async updateCameraTrack(track: MediaStreamTrack | null): Promise<void> {
    if (track === this.cameraMediaStreamTrack) return;
    log.debug('updating camera track');
    this.cameraMediaStreamTrack = track;
    await this.rebuildCameraTrack();
  }

  get outputVideoTrack(): MediaStreamTrack {
    const stream = this.vm.getOutputMediaStream();
    const track = stream.getVideoTracks()[0];
    if (!track) throw new Error('No Video Track');
    return track;
  }

  get outputAudioTrack(): MediaStreamTrack {
    const stream = this.vm.getOutputMediaStream();
    const track = stream.getAudioTracks()[0];
    if (!track) throw new Error('No Audio Track');
    return track;
  }

  async playIntro(media: MediaFormat): Promise<CameraVMMediaCtrl> {
    return await this.playMedia(media, { out: true });
  }

  /**
   * This starts a one-way trip of squelching the host, podium, and stage
   * tracks. If any need to be visible again, either re-init the
   * CameraVideoMixer or call forceRebuildPersistentTracks().
   */
  async playOutro(
    media: MediaFormat,
    stopHostAfterMs = MEDIA_FADE_IN_OUT_DURATION_MS
  ): Promise<CameraVMMediaCtrl> {
    const ctrl = await this.playMedia(media, { in: true });
    const timelineTimeEndMs = this.vm.playheadMs + stopHostAfterMs;
    this.vm.patchTrack(this.vmCameraTrackId, { timelineTimeEndMs });
    this.vm.patchTrack(this.vmPodiumTrack, { timelineTimeEndMs });
    this.vm.patchTrack(this.vmStageTrackId, { timelineTimeEndMs });
    return ctrl;
  }

  async forceRebuildPersistentTracks(): Promise<void> {
    await Promise.all([
      this.rebuildCameraTrack(),
      this.rebuildPodiumTrack(),
      this.rebuildStageTrack(),
    ]);
  }

  async playMedia(
    media: CameraVideoMixerMinimalMedia,
    fade?: { in?: boolean; out?: boolean }
  ): Promise<CameraVMMediaCtrl> {
    return await cameraVMPlayMediaImpl(this.vm, media, fade);
  }

  private async rebuildStageTrack() {
    log.debug('rebuilding stage vmtrack');

    if (this.vmStageTrackId) {
      this.vm.removeTrack(this.vmStageTrackId);
      this.vmStageTrackId = null;
    }

    const settings = this.videoEffectsSettings.stage;
    if (!settings || !settings.enabled) return;

    const umedia = new UnplayableVideoImpl(
      xDomainifyUrl(settings.config.mediaFormat.url)
    );

    umedia.media.muted = true;

    this.vmStageTrackId = this.vm.pushTrack(
      umedia.media,
      new TrackInitConfigBuilder()
        .setTimelineTimeStartMs(this.vm.playheadMs)
        .setDurationMs(settings.config.mediaFormat.length)
        .setLoop(true)
        .setZLayer(-1)
        .build()
    );

    await umedia.intoPlayable();
  }

  private async rebuildPodiumTrack() {
    log.debug('rebuilding podium vmtrack');

    if (this.vmPodiumTrack) {
      this.vm.removeTrack(this.vmPodiumTrack);
      this.vmPodiumTrack = null;
    }

    const settings = this.videoEffectsSettings.podium;
    if (!settings || !settings.enabled) return;

    const umedia = new UnplayableImageImpl(
      xDomainifyUrl(settings.config.mediaFormat.url)
    );

    this.vmPodiumTrack = this.vm.pushTrack(
      umedia.media,
      new TrackInitConfigBuilder()
        .setTimelineTimeStartMs(this.vm.playheadMs)
        .setDurationMs(settings.config.mediaFormat.length || Infinity)
        .setZLayer(2)
        .build()
    );

    await umedia.intoPlayable();
  }

  private async rebuildCameraTrack() {
    log.debug('rebuilding camera vmtrack');

    // if trackId is already present, attempt to delete
    this.vm.removeTrack(this.vmCameraTrackId);
    this.vmCameraTrackId = null;

    if (!this.cameraMediaStreamTrack) return;

    const stream = new MediaStream([this.cameraMediaStreamTrack]);
    const unplayable = new UnplayableVideoImpl(stream);

    const configBuilder = new TrackInitConfigBuilder()
      .setDurationMs(Infinity)
      .setTimelineTimeStartMs(0)
      .setZLayer(0)
      .setBoundingBox(this.videoEffectsSettings.boundingBox);

    if (this.videoEffectsSettings.greenScreen.enabled) {
      configBuilder
        .setChromakey(
          GreenScreenValuesUtils.ToGLCompat(
            this.videoEffectsSettings.greenScreen
          )
        )
        .setRectMask(this.videoEffectsSettings.greenScreen.maskPct);
    }

    this.vmCameraTrackId = this.vm.pushTrack(
      unplayable.media,
      configBuilder.build()
    );

    // A stream does not need to wait for the first frame and is not seekable
    await unplayable.intoPlayable(false);
  }
}

export async function cameraVMPlayMediaImpl(
  vm: VideoMixer,
  media: CameraVideoMixerMinimalMedia,
  fade?: { in?: boolean; out?: boolean }
): Promise<CameraVMMediaCtrl> {
  // play intro or outro
  log.info('playing media');

  const uv = new UnplayableVideoImpl(xDomainifyUrl(media.url));

  const configBuilder = new TrackInitConfigBuilder()
    .setDurationMs(media.length)
    // Note: this should be the highest z value to avoid other tracks from appearing on top.
    .setZLayer(3);

  if (fade?.in) {
    configBuilder.addVideoOpacityEnvelope(
      { ms: 0, value: 0 },
      { ms: MEDIA_FADE_IN_OUT_DURATION_MS, value: 1 },
      'in'
    );
  }

  if (fade?.out) {
    const AUDIO_FADE_OUT_MS = 1000;

    configBuilder
      .addVideoOpacityEnvelope(
        {
          ms: media.length - MEDIA_FADE_IN_OUT_DURATION_MS,
          value: 1,
        },
        { ms: media.length, value: 0 },
        'out'
      )
      // Adding a default s-curve fade helps to prevent popping at the end.
      .addAudioGainEnvelope(
        { ms: media.length - AUDIO_FADE_OUT_MS, value: 1 },
        { ms: media.length, value: 0 },
        { ...SCurveCrossFade, direction: 'out' }
      );
  }

  // Load first _before_ scheduling to prevent a slow load from seeking ahead
  // to stay syncrhonized.
  await uv.intoPlayable();
  configBuilder.setTimelineTimeStartMs(vm.playheadMs);

  const trackId = vm.pushTrack(
    uv.media,

    configBuilder.build()
  );

  const aborter = new AbortController();
  const abort = () => aborter.abort();

  const complete = new Promise<void>((resolve) => {
    aborter.signal.onabort = () => {
      vm.removeTrack(trackId);
      resolve();
    };

    const trackEnd = createTrackEndAwaiter(vm, trackId);
    trackEnd.then(() => resolve());
  });

  const trackStarted = createTrackStartAwaiter(vm, trackId);
  const trackFirstFrameRendered = createTrackFirstFrameAwaiter(vm, trackId);

  return { complete, abort: abort, trackStarted, trackFirstFrameRendered };
}

export class CameraVMMediaDeviceRTCService extends MediaDeviceRTCService {
  cameraVideoMixer: CameraVideoMixer | null = null;

  async enableCameraVideoMixer(): Promise<void> {
    if (this.cameraVideoMixer) {
      return;
    }

    this.log.info('enable camera video mixer');
    this.cameraVideoMixer = new CameraVideoMixer(this.videoEncoderConfig);

    const encoderConfig = profileForCustomVideo(this.videoEncoderConfig);

    const outputTrack = AgoraRTC.createCustomVideoTrack({
      ...encoderConfig,
      mediaStreamTrack: this.cameraVideoMixer.outputVideoTrack,
    });

    // Safe to always set if the track is the same
    this.audioBus.setMusicTrack(this.cameraVideoMixer.outputAudioTrack);

    // Ensure the camera mixer has the latest input track
    this.setInputVideoTrack(this.localUser.inputVideoTrack);

    await this.setOutputVideoTrack(outputTrack);
  }

  async disableCameraVideoMixer(): Promise<void> {
    if (!this.cameraVideoMixer) return;
    this.log.info('disable camera video mixer');
    await this.cameraVideoMixer.destroy();
    this.cameraVideoMixer = null;
    this.localUser.videoTrack?.close();
    await this.setOutputVideoTrack(this.localUser.inputVideoTrack);
  }

  async toggleVideo(val: boolean): Promise<void> {
    // The local user _always_ has video to publish if the cameraVideoMixer is
    // present. This allows the user to mute their camera, but still
    // publish/broadcast the stage and intro/outro video(s). Check the
    // MediaControls (audio/video mute/unmute buttons) for how this is
    // eventually resynchronized once the video mixer is disabled.
    super.toggleVideoInternal(this.cameraVideoMixer ? true : val, val);
  }

  stopVideo(uid: UID): void {
    if (uid === this.localUser.uid && this.cameraVideoMixer) {
      const videoTrack = this.localUser.inputVideoTrack;
      if (videoTrack && videoTrack.isPlaying) {
        videoTrack.stop();
      }
    } else {
      super.stopVideo(uid);
    }
  }

  playVideo(
    uid: UID,
    element: string | HTMLElement,
    config?: VideoPlayerConfig
  ) {
    // Agora defaults a Camera device track to the equivalent of `object-fit:
    // cover`, but once you use a custom video track, it defaults to
    // `object-fit: contain` since it assumes it's a screenshare. We know that
    // if a CameraVideoMixer exists, the local user video will be a custom video
    // track. It's not clear how to determine if a track instance is a
    // CustomVideoTrack or a CameraVideoTrack at runtime, either.
    if (this.cameraVideoMixer) {
      config = config || {};
      config.fit = config?.fit ?? 'cover';
    }

    return super.playVideo(uid, element, config);
  }

  async switchAudioInput(
    deviceId: string,
    forceRecreate = false
  ): Promise<void> {
    await super.switchAudioInput(deviceId, forceRecreate);
    if (this.cameraVideoMixer) {
      // Safe to always set if the track is the same
      this.audioBus.setMusicTrack(this.cameraVideoMixer.outputAudioTrack);
    }
  }

  async switchVideo(input: string | MediaStreamTrack): Promise<void> {
    await super.switchVideoInternal(input);
    if (!this.cameraVideoMixer) {
      // Directly passthrough reference
      await this.setOutputVideoTrack(this.localUser.inputVideoTrack);
    }
  }

  protected setInputVideoTrack(
    track: ICameraVideoTrack | ILocalVideoTrack | undefined
  ) {
    super.setInputVideoTrack(track);

    if (this.cameraVideoMixer) {
      // Always update the track if the video mixer is present, because it uses
      // MediaStreamTracks, which change whenever Agora enables/disables an
      // Agora VideoTrack.
      const cameraTrack = this.localUser.inputVideoTrack?.getMediaStreamTrack();
      if (cameraTrack && cameraTrack.readyState !== 'ended') {
        this.cameraVideoMixer.updateCameraTrack(cameraTrack);
      } else {
        this.cameraVideoMixer.updateCameraTrack(null);
      }
    }
  }

  async setEncoderConfiguration(
    config: CustomVideoQualityPreset,
    options?: {
      supressError?: boolean;
      persistConfig?: boolean;
    }
  ) {
    // TODO: This will only work if cameraVideoMixer is disabled, since setting
    // the encoder config can only be done on a Camera Track.
    return super.setEncoderConfiguration(config, options);
  }
}
