import { useMemo } from 'react';

import { rsIncrement } from '../utils/rstats.client';

/**
 * Given the name of a hook, mark when the hook was evaluated, and when what
 * ever it derived had to be re-computed / re-derived, aka a "cache miss".
 */
export function useRSHook(name: string) {
  rsIncrement(`eval-${name}-c`);
  const miss = useMemo(() => () => rsIncrement(`cache-miss-${name}-c`), [name]);
  return { miss } as const;
}
