import pluralize from 'pluralize';
import { useState } from 'react';
import { type ConnectDragSource } from 'react-dnd';

import { EnumsMediaScene } from '@lp-lib/api-service-client/public';
import { type Media } from '@lp-lib/media';

import { uuidv4 } from '../../utils/common';
import { DragDropList } from '../common/DragDrop';
import {
  ConfirmCancelModalHeading,
  ConfirmCancelModalText,
  useAwaitFullScreenConfirmCancelModal,
} from '../ConfirmCancelModalContext';
import { DeleteIcon } from '../icons/DeleteIcon';
import { MenuIcon } from '../icons/MenuIcon';
import { Loading } from '../Loading';
import { MiniMediaEditor } from '../MediaUploader/MiniMediaEditor';
import { useMediaUploader } from '../MediaUploader/useMediaUploader';
import { type Song } from './types';

function MultiSongsUploader(props: { onUpload: (songs: Song[]) => void }) {
  const triggerConfirmationModal = useAwaitFullScreenConfirmCancelModal();

  const [numUploads, setNumUploads] = useState(0);
  const [numUploadsCompleted, setNumUploadsCompleted] = useState(0);

  const { inputElement, isUploading } = useMediaUploader({
    id: `multi-songs-uploader`,
    image: false,
    video: false,
    audio: true,
    multiple: true,
    scene: EnumsMediaScene.MediaSceneMusicPlayer,
    onBeforeUpload: (files) => {
      setNumUploads(Object.keys(files).length);
      setNumUploadsCompleted(0);
      return true;
    },
    onUploadSuccess: () => {
      setNumUploadsCompleted((current) => current + 1);
    },
    onUploadFailed: () => {
      setNumUploadsCompleted((current) => current + 1);
    },
    onComplete: async (results) => {
      if (results.failed.length > 0) {
        await triggerConfirmationModal({
          kind: 'confirm-cancel',
          prompt: (
            <div className='px-5 py-2'>
              <ConfirmCancelModalHeading>
                Upload Failure
              </ConfirmCancelModalHeading>
              <ConfirmCancelModalText className='mt-4 text-sms font-normal'>
                <>
                  {results.successful.length > 0 && (
                    <>
                      Successfully uploaded{' '}
                      <span className='font-bold'>
                        {results.successful.length}
                      </span>{' '}
                      {pluralize('song', results.successful.length)}.{' '}
                    </>
                  )}
                  The following{' '}
                  <span className='font-bold'>{results.failed.length}</span>{' '}
                  {pluralize('image', results.failed.length)} failed to upload
                  successfully. Please try again.
                  <ul className='mt-2 text-xs text-left'>
                    {results.failed.map((result) => (
                      <li key={result.id}>{result.name}</li>
                    ))}
                  </ul>
                </>
              </ConfirmCancelModalText>
            </div>
          ),
          confirmOnly: true,
          confirmBtnLabel: 'Okay',
        });
      }

      const songs: Song[] = [];
      for (const result of results.successful) {
        const mainMedia = result.response?.body.media as Media;
        if (!mainMedia) continue;
        songs.push({
          id: uuidv4(),
          title: result.name,
          mediaId: mainMedia.id,
          media: mainMedia,
        });
      }
      props.onUpload(songs);
    },
  });

  return (
    <>
      {isUploading && (
        <div className='fixed inset-0 z-10 bg-lp-black-004'>
          <div className='w-full h-full flex flex-col items-center justify-center text-center text-icon-gray'>
            <Loading text='' />
            <div className='mt-2'>
              <p className='text-white text-lg font-bold'>Uploading</p>
              <p className='mt-1'>
                This may take a while depending on the number of songs you
                selected.
              </p>
              <p className='mt-1 font-mono'>
                {numUploadsCompleted} / {numUploads}
              </p>
            </div>
          </div>
        </div>
      )}

      <label
        className={`
        btn underline text-icon-gray text-sms cursor-pointer
        ${isUploading ? 'pointer-events-none' : ''}
      `}
      >
        Add Song(s)
        <div className='hidden w-0 h-0'>{inputElement}</div>
      </label>
    </>
  );
}

function SongRow(props: {
  song: Song;
  drag: ConnectDragSource;
  onChange: (song: Song) => void;
  onDelete: () => void;
}) {
  const { song, drag, onChange, onDelete } = props;

  const handleUpdate = (updates: Partial<Song>) => {
    onChange({ ...song, ...updates });
  };

  return (
    <div className='w-full'>
      <div className='w-full bg-secondary rounded-xl p-3 flex justify-between items-center gap-3.5'>
        <button type='button' ref={drag} className='btn cursor-move flex-none'>
          <MenuIcon />
        </button>

        <input
          className='field mb-0'
          value={props.song.title}
          onChange={(e) => handleUpdate({ title: e.target.value })}
          placeholder='title'
          maxLength={100}
        />

        <MiniMediaEditor
          id={song.id}
          audio
          media={song.media}
          mediaData={
            song.mediaId
              ? {
                  id: song.mediaId,
                }
              : null
          }
          onChange={(_, media) =>
            handleUpdate({
              mediaId: media?.id || null,
              media: media,
            })
          }
          scene={EnumsMediaScene.MediaSceneMusicPlayer}
        />

        <button
          type='button'
          onClick={onDelete}
          className='btn flex-none text-red-002'
        >
          <DeleteIcon />
        </button>
      </div>
    </div>
  );
}

export function PlaylistEditor(props: {
  songs: Song[];
  onMove: (from: number, to: number) => void;
  onAdd: (song: Song | Song[]) => void;
  onDelete: (index: number) => void;
  onChange: (index: number, song: Song) => void;
}) {
  const { songs, onMove, onAdd, onDelete, onChange } = props;

  const handleAdd = () => {
    const song: Song = {
      id: uuidv4(),
      title: '',
      mediaId: '',
      media: null,
    };
    onAdd(song);
  };

  return (
    <div className='w-full flex flex-col gap-1'>
      <div className='flex justify-between items-center'>
        <h3 className='text-base font-bold'>Songs ({songs.length})</h3>
        <MultiSongsUploader onUpload={onAdd} />
      </div>

      <DragDropList
        type='song'
        items={songs}
        onMove={onMove}
        render={({ item, index, drag, ref, style }) => (
          <div className={`w-full`} ref={ref} style={style}>
            <SongRow
              song={item}
              drag={drag}
              onChange={(song) => onChange(index, song)}
              onDelete={() => onDelete(index)}
            />
          </div>
        )}
      />

      <div>
        <button
          type='button'
          className='text-primary text-sms font-medium'
          onClick={handleAdd}
        >
          + New Song
        </button>
      </div>
    </div>
  );
}
