import { useEffect, useMemo, useState } from 'react';

import { type Option, Select } from '../common/Utilities';
import { TeamIcon } from '../icons/TeamIcon';
import { Control } from '../StreamingTools';
import { useTeamSizeContext } from './Context';

export function TeamSizeControl(): JSX.Element | null {
  const { defaultMaxMembers, maxMembers, updateMaxMembers } =
    useTeamSizeContext();

  const options = useMemo(
    () =>
      [...Array(defaultMaxMembers)].map((_, i) => ({
        label: `${i + 1}`,
        value: i + 1,
      })),
    [defaultMaxMembers]
  );

  const [activeOption, setActiveOption] = useState<Option<number>>(options[0]);

  useEffect(() => {
    setActiveOption(
      options.find((option) => option.value === maxMembers) || options[0]
    );
  }, [maxMembers, options]);

  const handleOptionChange = (option: Option<number>) => {
    setActiveOption(option);
    updateMaxMembers(option.value);
  };
  return (
    <div>
      <Control
        text='Max Team Size'
        info='The maximum number of players per team.'
        icon={<TeamIcon className='w-full h-full fill-current' />}
      >
        <div className='w-12'>
          <Select
            options={options}
            onChange={handleOptionChange}
            value={activeOption}
          />
        </div>
      </Control>
    </div>
  );
}
