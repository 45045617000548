import { getStaticAssetPath } from '../../utils/assets';

export const MALE_NAMES = [
  'James',
  'Robert',
  'John',
  'Michael',
  'David',
  'William',
  'Richard',
  'Joseph',
  'Thomas',
  'Christopher',
  'Charles',
  'Daniel',
  'Matthew',
  'Anthony',
  'Mark',
  'Donald',
  'Steven',
  'Andrew',
  'Paul',
  'Joshua',
  'Kenneth',
  'Kevin',
  'Brian',
  'George',
  'Timothy',
  'Ronald',
  'Jason',
  'Edward',
  'Jeffrey',
  'Ryan',
  'Jacob',
  'Gary',
  'Nicholas',
  'Eric',
  'Jonathan',
  'Stephen',
  'Larry',
  'Justin',
  'Scott',
  'Brandon',
  'Benjamin',
  'Samuel',
  'Gregory',
  'Alexander',
  'Patrick',
  'Frank',
  'Raymond',
  'Jack',
  'Dennis',
  'Jerry',
  'Tyler',
  'Aaron',
  'Jose',
  'Adam',
  'Nathan',
  'Henry',
  'Zachary',
  'Douglas',
  'Peter',
  'Kyle',
  'Noah',
  'Ethan',
  'Jeremy',
  'Walter',
  'Christian',
  'Keith',
  'Roger',
  'Terry',
  'Austin',
  'Sean',
  'Gerald',
  'Carl',
  'Harold',
  'Dylan',
  'Arthur',
  'Lawrence',
  'Jordan',
  'Jesse',
  'Bryan',
  'Billy',
  'Bruce',
  'Gabriel',
  'Joe',
  'Logan',
  'Alan',
  'Juan',
  'Albert',
  'Willie',
  'Elijah',
  'Wayne',
  'Randy',
  'Vincent',
  'Mason',
  'Roy',
  'Ralph',
  'Bobby',
  'Russell',
  'Bradley',
  'Philip',
  'Eugene',
];

export const FEMALE_NAMES = [
  'Mary',
  'Patricia',
  'Jennifer',
  'Linda',
  'Elizabeth',
  'Barbara',
  'Susan',
  'Jessica',
  'Sarah',
  'Karen',
  'Lisa',
  'Nancy',
  'Betty',
  'Sandra',
  'Margaret',
  'Ashley',
  'Kimberly',
  'Emily',
  'Donna',
  'Michelle',
  'Carol',
  'Amanda',
  'Melissa',
  'Deborah',
  'Stephanie',
  'Dorothy',
  'Rebecca',
  'Sharon',
  'Laura',
  'Cynthia',
  'Amy',
  'Kathleen',
  'Angela',
  'Shirley',
  'Brenda',
  'Emma',
  'Anna',
  'Pamela',
  'Nicole',
  'Samantha',
  'Katherine',
  'Christine',
  'Helen',
  'Debra',
  'Rachel',
  'Carolyn',
  'Janet',
  'Maria',
  'Catherine',
  'Heather',
  'Diane',
  'Olivia',
  'Julie',
  'Joyce',
  'Victoria',
  'Ruth',
  'Virginia',
  'Lauren',
  'Kelly',
  'Christina',
  'Joan',
  'Evelyn',
  'Judith',
  'Andrea',
  'Hannah',
  'Megan',
  'Cheryl',
  'Jacqueline',
  'Martha',
  'Madison',
  'Teresa',
  'Gloria',
  'Sara',
  'Janice',
  'Ann',
  'Kathryn',
  'Abigail',
  'Sophia',
  'Frances',
  'Jean',
  'Alice',
  'Judy',
  'Isabella',
  'Julia',
  'Grace',
  'Amber',
  'Denise',
  'Danielle',
  'Marilyn',
  'Beverly',
  'Charlotte',
  'Natalie',
  'Theresa',
  'Diana',
  'Brittany',
  'Doris',
  'Kayla',
  'Alexis',
  'Lori',
  'Marie',
];

export const BOT_VIDEOS: { gender: 'male' | 'female'; video: string }[] = [
  {
    gender: 'male',
    video: getStaticAssetPath('videos/bot-male-01.mp4'),
  },
  {
    gender: 'female',
    video: getStaticAssetPath('videos/bot-female-01.mp4'),
  },
  {
    gender: 'male',
    video: getStaticAssetPath('videos/bot-male-02.mp4'),
  },
  {
    gender: 'female',
    video: getStaticAssetPath('videos/bot-female-02.mp4'),
  },
];
