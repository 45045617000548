import { type CSSProperties, useEffect, useState } from 'react';

import { type Participant } from '../../../../types';
import { MediaUtils } from '../../../../utils/media';
import {
  useLayoutAnchorRect,
  useLayoutAnchorRectValue,
} from '../../../LayoutAnchors/LayoutAnchors';
import { type Question } from './types';

function QuestionCardContainer(props: {
  question: Question;
  children?: React.ReactNode;
}): JSX.Element {
  const { question, children } = props;

  const questionMediaUrl = MediaUtils.PickMediaUrl(question.questionMedia);
  const answerMediaUrl = MediaUtils.PickMediaUrl(question.answerMedia);

  return (
    <div
      className={`flex-shrink-0 w-43 h-24 rounded-xl bg-hot-seat border border-white-001`}
    >
      {children}

      {questionMediaUrl && (
        <img
          src={questionMediaUrl}
          alt='question media'
          className='hidden'
        ></img>
      )}
      {answerMediaUrl && (
        <img src={answerMediaUrl} alt='answer media' className='hidden'></img>
      )}
    </div>
  );
}

function QuestionCardPrompt(props: {
  title: string;
  submitterName: string;
}): JSX.Element {
  return (
    <div className='w-full h-full flex flex-col justify-center text-center py-4 px-6'>
      <p className='text-[#FFFF90] italic text-base font-black'>
        {props.title}
      </p>
      <p className='text-white text-sms font-bold line-clamp-2'>
        {props.submitterName}
      </p>
    </div>
  );
}

function NextQuestionCard(props: {
  question: Question;
  candidate: Participant | null;
  isTransition: boolean;
}): JSX.Element {
  const { question, candidate, isTransition } = props;

  const [className, setClassName] = useState('');
  const [showPrompt, setShowPrompt] = useState(true);

  const inputAnchorRect = useLayoutAnchorRect('gameplay-question-text-anchor');
  const distance = (inputAnchorRect?.width || 0) / 2;

  useEffect(() => {
    if (!isTransition) {
      return;
    }

    const timeout1 = setTimeout(() => {
      setClassName(`transition duration-200 transform`);
      setShowPrompt(false);
    }, 200);

    const timeout2 = setTimeout(() => {
      setClassName(`transition duration-200 transform opacity-0`);
      setShowPrompt(false);
    }, 400);

    return () => {
      clearTimeout(timeout1);
      clearTimeout(timeout2);

      setClassName('');
      setShowPrompt(true);
    };
  }, [isTransition]);

  return (
    <div
      className={className}
      style={
        {
          '--tw-translate-x': `${distance}px`,
        } as CSSProperties
      }
    >
      <QuestionCardContainer question={question}>
        {showPrompt && (
          <QuestionCardPrompt
            title='UP NEXT'
            submitterName={candidate?.username || ''}
          />
        )}
      </QuestionCardContainer>
    </div>
  );
}

function AfterThatQuestionCard(props: {
  question: Question;
  candidate: Participant | null;
  isTransition: boolean;
}): JSX.Element {
  const { question, candidate, isTransition } = props;

  const [className, setClassName] = useState('');
  const [showPrompt, setShowPrompt] = useState(true);

  useEffect(() => {
    if (!isTransition) {
      return;
    }

    const timeout1 = setTimeout(() => {
      setClassName('transition duration-200 transform translate-x-50');
      setShowPrompt(false);
    }, 200);

    const timeout2 = setTimeout(() => {
      setShowPrompt(false);
    }, 400);

    return () => {
      clearTimeout(timeout1);
      clearTimeout(timeout2);

      setClassName('');
      setShowPrompt(true);
    };
  }, [isTransition]);

  return (
    <div className={className}>
      <QuestionCardContainer question={question}>
        {showPrompt && (
          <QuestionCardPrompt
            title='AFTER THAT'
            submitterName={candidate?.username || ''}
          />
        )}
      </QuestionCardContainer>
    </div>
  );
}

export function ScheduledQuestionCards(props: {
  scheduledQuestions: Question[];
  candidates: Participant[];
  isTransition: boolean;
}): JSX.Element {
  const { isTransition, scheduledQuestions, candidates } = props;
  const top = useLayoutAnchorRectValue('round-robin-progress-ring', 'top') ?? 0;

  return (
    <div className='w-74 z-35 absolute' style={{ top }}>
      <div className='w-full flex flex-row-reverse items-center gap-7'>
        {scheduledQuestions.map((q, index) => {
          const candidate =
            candidates.length > 0
              ? candidates[index % candidates.length]
              : null;

          if (index === 0) {
            return (
              <NextQuestionCard
                key={q.index}
                question={q}
                candidate={candidate}
                isTransition={isTransition}
              />
            );
          }

          return (
            <AfterThatQuestionCard
              key={q.index}
              question={q}
              candidate={candidate}
              isTransition={isTransition}
            />
          );
        })}
      </div>
    </div>
  );
}
