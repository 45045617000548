import { useMemo } from 'react';
import Select, { type SingleValue } from 'react-select';

import { useInstance } from '../../hooks/useInstance';
import { type MessageTemplate } from '../../types/messageTemplate';
import { err2s } from '../../utils/common';
import { buildReactSelectStyles } from '../../utils/react-select';
import { type Option } from '../common/Utilities';
import { Loading } from '../Loading';
import { useMessageTemplates } from './hooks';
import { useTriggerEditMessageTemplateModal } from './Modals';

export function MessageTemplateSelect(props: {
  templateId: string | null;
  onSelect: (template: MessageTemplate) => void;
  disabled?: boolean;
  editable?: boolean;
}): JSX.Element {
  const { templateId, onSelect, disabled, editable } = props;

  const triggerEditModal = useTriggerEditMessageTemplateModal();
  const styles = useInstance(() =>
    buildReactSelectStyles<Option<MessageTemplate>>()
  );
  const { templates, error, isLoading, refresh } = useMessageTemplates({
    sortBy: 'title',
  });
  const options = useMemo(() => {
    return templates?.map((o) => ({ label: o.title, value: o })) || [];
  }, [templates]);

  if (isLoading) return <Loading containerClassName='justify-start' />;
  if (error) return <div className='text-3xs text-red-002'>{err2s(error)}</div>;

  const selectedOption = options.find((o) => o.value.id === templateId);

  const handleSelect = (option: SingleValue<Option<MessageTemplate>>) => {
    if (!option) return;

    onSelect(option.value);
  };

  const handleEdit = (template: MessageTemplate) => {
    triggerEditModal(template, () => {
      refresh();
    });
  };

  return (
    <div className='flex flex-row items-center'>
      <div className='w-120'>
        <Select<Option<MessageTemplate>>
          classNamePrefix='select-box-v2'
          options={options}
          onChange={handleSelect}
          styles={styles}
          value={selectedOption}
          isDisabled={disabled}
          noOptionsMessage={() => 'No Message Templates'}
        />
      </div>
      {editable && selectedOption && (
        <div
          className='pl-5 btn text-primary cursor-pointer'
          onClick={() => handleEdit(selectedOption.value)}
        >
          Edit
        </div>
      )}
    </div>
  );
}
