import { forwardRef, useRef } from 'react';
import { type UseFormRegisterReturn } from 'react-hook-form';

type SwitcherBaseProps = {
  name: string;
  disabled?: boolean;
  className?: string;
  children?: JSX.Element;
  title?: string;
};

type SwitcherControlledProps = SwitcherBaseProps & {
  checked: boolean;
  onChange: (checked: boolean) => void;
};

type SwitcherHookFormProps = SwitcherBaseProps & {
  defaultChecked: boolean;
} & UseFormRegisterReturn;

export const SwitcherHookForm = forwardRef<
  HTMLInputElement,
  SwitcherHookFormProps
>((props, extRef): JSX.Element => {
  return <SwitcherControlledInternal {...props} ref={extRef} />;
});

export const SwitcherControlled = forwardRef<
  HTMLInputElement,
  SwitcherControlledProps
>((props, extRef): JSX.Element => {
  return <SwitcherControlledInternal {...props} ref={extRef} />;
});

// React.forwardRef's types prevent the props from being discriminated at the
// callsite based on the presence of the `onChanged` property, possibly because
// strict function signature checking is turned off. A straightforward solution
// is to have multiple interfaces depending on the use case (react-hook-form vs
// a manually controlled component).
const SwitcherControlledInternal = forwardRef<
  HTMLInputElement,
  SwitcherControlledProps | SwitcherHookFormProps
>((props, extRef): JSX.Element => {
  const localRef = useRef<HTMLInputElement>(null);
  const ref = extRef ?? localRef;

  // Reminder: "controlled" means "react controls the value" rather than
  // implicitly by the DOM.
  const controlled = 'checked' in props;

  // note: using isolate so that the z-indices within this component don't affect
  // users of this component. this addresses a problem where the switch appears
  // above other elements, for example drop down lists.
  return (
    <div
      className={`relative isolate flex items-center w-10 align-middle select-none transition duration-200 ease-in switcher-primary${
        props.className ?? ''
      }`}
      title={props.title ?? ''}
    >
      <input
        ref={ref}
        type='checkbox'
        // This needs a z-index because otherwise when in a `disabled` state,
        // the label (gradient) has an implicitly higher stacking context due to
        // the gradient.
        className={`
          absolute block w-5 h-5 z-3
          rounded-full
          appearance-none cursor-pointer disabled:cursor-not-allowed`}
        name={props.name}
        data-testid={`switcher-${props.name}`}
        id={`checkbox-${props.name}`}
        disabled={props.disabled}
        {...(controlled
          ? {
              checked: props.checked ?? false,
              onChange: (e) => props.onChange(e.target.checked),
            }
          : {
              defaultChecked: props.defaultChecked,
              onBlur: props.onBlur,
              onChange: props.onChange,
            })}
      />
      <div
        className='
        switcher-icon
        absolute w-5 h-5 z-5
        rounded-full pointer-events-off
        flex justify-center items-center'
      >
        {props.children}
      </div>
      <label
        htmlFor={`checkbox-${props.name}`}
        className={`switcher-label`}
      ></label>
    </div>
  );
});
