import { useEffect, useState } from 'react';

// NOTE: these are not performant enough for realtime games during a gameloop!
// Only for things like admin/ui operations when clicking, for example.

function useHeld(mod: 'shiftKey' | 'metaKey') {
  const [down, setDown] = useState(false);
  useEffect(() => {
    const aborter = new AbortController();
    const opts = { signal: aborter.signal, capture: true };

    document.body.addEventListener(
      'keydown',
      (e) => setDown(() => e[mod]),
      opts
    );
    document.body.addEventListener('keyup', () => setDown(() => false), opts);
    return () => aborter.abort();
  }, [mod]);
  return down;
}

export function useShiftKeyHeld() {
  return useHeld('shiftKey');
}

export function useMetaKeyHeld() {
  return useHeld('metaKey');
}
