import { useEffect } from 'react';

import {
  useFetchGameSessionGamePack,
  useGameVideoPlaybackState,
  useOndGameProgress,
  useOndGameState,
} from '../Game/hooks';
import { useIsStreamSessionAlive } from '../Session';
import { useMusicPlayerContext } from './Context';
import { useMusicPlayerHotKeys as useSetupMusicPlayerHotKeys } from './hooks';
import { MUSIC_PLAYER_DEFAULT_LIST_ID } from './types';
import { useInteractWithGamePlayVideoAndOnd } from './useInteractWithGamePlayVideoAndOnd';

export const MusicPlayerControl = (): JSX.Element | null => {
  const { setPlaylistById, play, pause, isPlaying } = useMusicPlayerContext();
  const videoPlayback = useGameVideoPlaybackState();
  const ondState = useOndGameState();
  const ondGameProgress = useOndGameProgress();
  const isStreamAlive = useIsStreamSessionAlive();
  const gamePack = useFetchGameSessionGamePack();

  useSetupMusicPlayerHotKeys();
  useInteractWithGamePlayVideoAndOnd({
    isStreamAlive,
    ondState,
    ondGameProgress,
    videoPlayback,
    musicCtrl: {
      play,
      pause,
      isPlaying,
    },
  });

  const playlistId =
    gamePack?.marketingSettings?.musicPlaylistId ||
    MUSIC_PLAYER_DEFAULT_LIST_ID;
  useEffect(() => {
    if (!playlistId) return;

    setPlaylistById(playlistId);
  }, [playlistId, setPlaylistById]);

  return null;
};
