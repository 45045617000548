import { type ReactNode, useRef } from 'react';

import { matchesProfileAddress } from '../../../services/crowd-frames';
import { Context } from './Context';
import {
  type ExternalCallbacksMap,
  type FPSContext,
  type MostRecentUserFrameMap,
  type RenderlessTargetMap,
  type RenderTargetMap,
} from './types';
import { FPSAnimationMachine } from './useFPSAnimationMachine';

function initializeContext(): FPSContext {
  const targets: RenderTargetMap = new Map();
  const renderlessTargets: RenderlessTargetMap = new Map();
  const callbacks: { current: ExternalCallbacksMap | null } = { current: null };
  const lastFrames: MostRecentUserFrameMap = new Map();

  return {
    targets,

    renderlessTargets,

    setMostRecentFilmstripForAddress(address, frame) {
      lastFrames.set(address, frame);
    },

    getMostRecentFilmstripForAddress(address) {
      return lastFrames.get(address) ?? null;
    },

    handleUserLeftTheEvent(clientId) {
      for (const [address] of lastFrames) {
        if (matchesProfileAddress(clientId, address))
          lastFrames.delete(address);
      }
    },

    registerExternalCallbacks(map: ExternalCallbacksMap) {
      callbacks.current = { ...map };
    },

    visibilityChange: () => {
      if (!callbacks.current) return;
      callbacks.current.visibilityChange();
    },
  };
}

// The Root component / provider.
export const FPSProvider = (props: { children?: ReactNode }): JSX.Element => {
  const ctxRef = useRef<FPSContext | null>(null);
  if (!ctxRef.current) ctxRef.current = initializeContext();

  return (
    <Context.Provider value={ctxRef.current}>
      <FPSAnimationMachine />
      {props.children}
    </Context.Provider>
  );
};
