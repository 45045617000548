export const InfoIcon = (props: {
  className?: string;
  color?: string;
}): JSX.Element => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      className={props.className || 'w-3.5 h-3.5'}
      fill='none'
      viewBox='0 0 14 14'
    >
      <path
        fill={props.color || 'snow'}
        fillRule='evenodd'
        d='M1.923 7a5.077 5.077 0 1110.154 0A5.077 5.077 0 011.923 7zM7 1a6 6 0 100 12A6 6 0 007 1zM6 4.6a1 1 0 112 0 1 1 0 01-2 0zm.111 2.778a.889.889 0 011.778 0v2.444a.889.889 0 01-1.778 0V7.378z'
        clipRule='evenodd'
      ></path>
    </svg>
  );
};
