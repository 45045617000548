import useSWRMutable from 'swr';
import useSWRImmutable from 'swr/immutable';

import { apiService } from '../../services/api-service';

export function usePromptTemplates(immutable = true) {
  const useSWR = immutable ? useSWRImmutable : useSWRMutable;
  return useSWR([`/prompt-templates`], async () => {
    const resp = await apiService.promptTemplate.getTemplates();
    return resp.data.promptTemplates;
  });
}

export function usePromptTemplate(
  templateId: Nullable<string>,
  immutable = true
) {
  const useSWR = immutable ? useSWRImmutable : useSWRMutable;
  return useSWR(
    templateId ? `/prompt-templates/${templateId}` : null,
    async () => {
      if (!templateId) return;
      const resp = await apiService.promptTemplate.getTemplateById(templateId);
      return resp.data.promptTemplate;
    }
  );
}
