// https://stackoverflow.com/questions/68407187/potential-bug-in-official-useinterval-example/68409846#68409846

import { useEffect, useRef, useState } from 'react';

export function useTicker(delay: number | null): number {
  const [ticker, setTicker] = useState(0);
  useEffect(() => {
    if (delay !== null) {
      const timer = setInterval(() => setTicker((t) => t + 1), delay);
      return () => clearInterval(timer);
    }
  }, [delay]);

  return ticker;
}

export function useInterval(cbk: () => void, delay: number | null): void {
  const ticker = useTicker(delay);
  const cbkRef = useRef<() => void>(cbk);
  // always want the up to date callback from the caller
  useEffect(() => {
    cbkRef.current = cbk;
  }, [cbk]);

  // call the callback whenever the timer pops / the ticker increases.
  // This deliberately does not pass `cbk` in the dependencies as
  // otherwise the handler would be called on each render as well as
  // on the timer pop
  useEffect(() => {
    cbkRef.current();
  }, [ticker]);
}
