import { FBReference } from './experimental';

export type {
  FirebaseSafeRead,
  FirebaseSafeWrite,
  NarrowedDataSnapshot,
  OverriddenDataSnapshotProperties,
  SplitByDelimiter,
} from './common';
export { isServerValue, RTDBServerValueTIMESTAMP } from './server-timestamp';
export type {
  NarrowedDatabaseQuery,
  NarrowedDatabaseReference,
  OverriddenDatabaseReferenceProperties,
} from './web';

export type {
  FBPushable,
  FBChildable,
  FBReference,
  FBSettable,
  FBRemovable,
  FBTransactionable,
  FBUpdatable,
  FBGetable,
  FBSnappable as FBSnapable,
  FBListenable,
  FBQueryable,
} from './experimental';

export function asFBReference<T>(ref: unknown) {
  if (!ref || (ref !== null && typeof ref === 'object' && !('key' in ref)))
    throw new Error('Cannot convert ref!');
  return ref as unknown as FBReference<T>;
}
