import { useRTCService } from '../WebRTC';
import { StreamView } from './StreamView';
import { type StageMemberVO } from './types';

function StreamViewWrapper(props: {
  member: StageMemberVO | undefined | null;
  groupColor?: string;
  className?: string;
}) {
  const { member, groupColor } = props;
  const rtcService = useRTCService('stage');
  return (
    <StreamView
      className={`w-full h-full drop-shadow-lg overflow-hidden border-2 ${
        props.className ?? ''
      }`}
      member={member}
      rtcService={rtcService}
      disableRemove
      style={{
        borderColor: groupColor,
      }}
    />
  );
}

function SinglePlayerView(props: {
  stageMembers: StageMemberVO[];
  groupColor?: string;
}) {
  const { stageMembers, groupColor } = props;
  return (
    <div className={`w-full h-full absolute top-0 left-0`}>
      <StreamViewWrapper
        member={stageMembers[0]}
        groupColor={groupColor}
        className='rounded-5xl'
      />
    </div>
  );
}

function TwoPlayersView(props: {
  stageMembers: StageMemberVO[];
  groupColor?: string;
}) {
  const { stageMembers, groupColor } = props;
  return (
    <>
      <div className='w-[60%] h-[60%] absolute top-0 left-0'>
        <StreamViewWrapper
          member={stageMembers[0]}
          groupColor={groupColor}
          className='rounded-3.75xl'
        />
      </div>
      <div className='w-[60%] h-[60%] drop-shadow-lg absolute right-0 bottom-0'>
        <StreamViewWrapper
          member={stageMembers[1]}
          groupColor={groupColor}
          className='rounded-3.75xl'
        />
      </div>
    </>
  );
}

export function PlayerGroupView(props: {
  stageMembers: StageMemberVO[];
  groupColor?: string;
}) {
  const { stageMembers, groupColor } = props;

  if (stageMembers.length === 1) {
    return (
      <SinglePlayerView stageMembers={stageMembers} groupColor={groupColor} />
    );
  }

  if (stageMembers.length === 2) {
    return (
      <TwoPlayersView stageMembers={stageMembers} groupColor={groupColor} />
    );
  }

  // this assumes that each group(teams) has at most 9 members
  const maxAllowedStageMembers = stageMembers.slice(0, 9);
  const cols = maxAllowedStageMembers.length <= 4 ? 2 : 3;

  return (
    <div
      className='h-full grid gap-1 absolute top-0 left-1/2 transform-gpu -translate-x-1/2'
      style={{
        width: cols <= 2 ? '100%' : '150%',
        gridTemplateColumns: `repeat(${cols}, minmax(0, 1fr))`,
      }}
    >
      {maxAllowedStageMembers.map((m) => (
        <div className='w-full h-full'>
          <StreamViewWrapper
            member={m}
            groupColor={groupColor}
            className='rounded-2.5xl'
          />
        </div>
      ))}
    </div>
  );
}
