import pluralize from 'pluralize';
import { useEffect, useState } from 'react';

import {
  assertExhaustive,
  type GuessWhoBlock,
  GuessWhoBlockGameSessionStatus,
} from '@lp-lib/game';

import { useLiveCallback } from '../../../../hooks/useLiveCallback';
import { DoubleRightArrow } from '../../../icons/Arrows';
import { CorrectIcon } from '../../../icons/CorrectIcon';
import { PlayIcon } from '../../../icons/PlayIcon';
import { RefreshIcon } from '../../../icons/RefreshIcon';
import { FilledSquareIcon } from '../../../icons/SquareIcon';
import { TimerIcon } from '../../../icons/TimerIcon';
import { useParticipantsAsArray } from '../../../Player';
import {
  useGameSessionLocalTimer,
  useGameSessionStatus,
  useIsEndedBlock,
} from '../../hooks';
import { countdownV2, next, present, triggerBlockTitleAnim } from '../../store';
import {
  BlockControllerActionButton,
  BlockControllerActionNone,
  BlockControllerActionTimeRemaining,
  ControllerLayout,
  ControllerProgress,
  type ControllerProps,
  type MediaPlayback,
  MediaPreviewer,
  useBlockControllerBlockTitlePlaying,
  useControllerMediaPlayback,
  useControllerMediaPlayText,
} from '../Common/Controller/Internal';
import { useStableBlock } from '../Common/hooks';
import { BlockKnifeUtils } from '../Shared';
import {
  useCurrMatchPrompt,
  useCurrMatchState,
  useGeneratedMatchPrompts,
  useGuessWhoGame,
  useGuessWhoGameControlAPI,
  useGuessWhoPrompt,
  useTeamGuessSummary,
  useTeamSubmissionProgressSummary,
} from './GuessWhoProvider';

type SharedProps = ControllerProps<GuessWhoBlock>;
type StageProps = SharedProps & {
  playback: MediaPlayback;
};

function GameProgress(
  props: StageProps & {
    phase: 'prompt' | 'guess';
    revealResults?: boolean;
    hideProgressDetail?: boolean;
  }
): JSX.Element | null {
  const {
    selectedBlock: block,
    setEditPointsData,
    hideProgressDetail,
    revealResults,
    phase,
  } = props;

  const prompt = useGuessWhoPrompt();
  const submissionSummary = useTeamSubmissionProgressSummary();
  const guessSummary = useTeamGuessSummary();

  if (phase === 'prompt') {
    return (
      <ControllerProgress
        blockId={block.id}
        setEditPointsData={setEditPointsData}
        header={prompt?.text ?? '[No Prompt]'}
        revealResults={revealResults}
        hideProgressDetail={hideProgressDetail}
        progressDetail={(teamId: string) => {
          const teamSummary = submissionSummary[teamId];
          if (!teamSummary) return '[No Data]';
          return `[${teamSummary.numOfSubmissions}/${teamSummary.numOfPlayers} submissions]`;
        }}
        accessoryDetail={
          phase === 'prompt'
            ? (teamId: string) => {
                const teamSummary = submissionSummary[teamId];
                return teamSummary &&
                  teamSummary.numOfSubmissions >= teamSummary.numOfPlayers ? (
                  <CorrectIcon />
                ) : undefined;
              }
            : undefined
        }
      />
    );
  } else if (phase === 'guess') {
    return (
      <ControllerProgress
        blockId={block.id}
        setEditPointsData={setEditPointsData}
        header='Guess'
        revealResults={revealResults}
        hideProgressDetail={hideProgressDetail}
        progressDetail={(teamId: string) => {
          const teamSummary = guessSummary[teamId];
          if (!teamSummary) return '[No Data]';
          return `[${teamSummary.numOfGuesses}/${teamSummary.numOfPlayers} guesses; ${teamSummary.numCorrect} correct]`;
        }}
      />
    );
  }
  return null;
}

function BlockDetails(props: { block: GuessWhoBlock }): JSX.Element {
  return (
    <div className='flex flex-col items-center justify-center gap-1 mt-4'>
      <div>{props.block.fields.title}</div>
      <div>{props.block.fields.internalLabel}</div>
      <div className='h-4' />
      <div>
        Prompt Timer: {props.block.fields.promptTimeSec}{' '}
        {pluralize('second', props.block.fields.promptTimeSec)}
      </div>
      <div>Correct Points: {props.block.fields.pointsPerCorrect}pts/guess</div>
    </div>
  );
}

function Ended(props: StageProps): JSX.Element {
  return (
    <ControllerLayout
      action={
        <BlockControllerActionButton
          onClick={() => props.setShowResetConfirmation(true)}
          icon={RefreshIcon}
          isDelete={true}
        >
          Reset Completed Block
        </BlockControllerActionButton>
      }
    />
  );
}

function Loaded(props: StageProps): JSX.Element {
  const { selectedBlock: block, playback } = props;

  const { switchMedia, reset: resetPlayback } = playback.api;

  const [processing, setProcessing] = useState(false);

  useEffect(() => {
    resetPlayback();
    const media = BlockKnifeUtils.Media(block, 'introMedia');
    switchMedia(media);
  }, [block, switchMedia, resetPlayback]);

  const onPresent = async () => {
    setProcessing(true);
    await triggerBlockTitleAnim(block);
    await present(block);
  };

  const actionComponent = useBlockControllerBlockTitlePlaying(block) ?? (
    <BlockControllerActionButton
      onClick={onPresent}
      icon={PlayIcon}
      disable={processing || block.fields.prompts.length === 0}
    >
      {block.fields.prompts.length === 0 ? 'No Prompts' : 'Present Block'}
    </BlockControllerActionButton>
  );
  return (
    <ControllerLayout action={actionComponent}>
      <div className='w-full flex flex-col'>
        <MediaPreviewer playback={playback} alt={block.type} />
        <BlockDetails block={block} />
      </div>
    </ControllerLayout>
  );
}

function Intro(props: StageProps): JSX.Element | null {
  const { selectedBlock: block, playback } = props;
  const mediaActionText = useControllerMediaPlayText(playback);
  const [presentIntro, setPresentIntro] = useState(!!playback.preview);
  const { play: playMedia } = playback.api;

  useEffect(() => {
    if (!playback.isVideoPlayed) return;
    setPresentIntro(false);
  }, [playback.isVideoPlayed]);

  useEffect(() => {
    playMedia();
  }, [playMedia]);

  useEffect(() => {
    if (presentIntro) return;
    next();
  }, [presentIntro]);

  if (presentIntro) {
    return (
      <ControllerLayout
        action={
          <BlockControllerActionNone icon={PlayIcon}>
            {mediaActionText}
          </BlockControllerActionNone>
        }
      >
        <div className='w-full flex flex-col'>
          <MediaPreviewer playback={playback} alt={block.type} />
          <BlockDetails block={block} />
        </div>
      </ControllerLayout>
    );
  }
  return null;
}

function PromptInit(props: StageProps): JSX.Element {
  const { selectedBlock: block, playback } = props;

  const [processing, setProcessing] = useState(false);
  const time = useGameSessionLocalTimer();

  const onClick = async () => {
    if (time === 0) return;
    setProcessing(true);
    await countdownV2({
      debug: 'GuessWhoBlockGameController',
      startTimeWorker: true,
      flushCountingStatus: true,
      triggerTimesup: true,
    });
  };

  const actionComponent = useBlockControllerBlockTitlePlaying(block) ?? (
    <BlockControllerActionButton
      onClick={onClick}
      icon={TimerIcon}
      disable={processing || time === 0}
    >
      {`Start Prompt Timer (${time}s)`}
    </BlockControllerActionButton>
  );
  return (
    <ControllerLayout action={actionComponent}>
      <div className='w-full flex flex-col'>
        <MediaPreviewer playback={playback} alt={block.type} />
        <BlockDetails block={block} />
      </div>
    </ControllerLayout>
  );
}

function PromptCounting(props: StageProps): JSX.Element {
  const time = useGameSessionLocalTimer();
  return (
    <ControllerLayout
      action={<BlockControllerActionTimeRemaining remainingSec={time} />}
    >
      <GameProgress {...props} phase='prompt' />
    </ControllerLayout>
  );
}

function PromptEnd(props: StageProps): JSX.Element {
  const [processing, setProcessing] = useState(false);

  const onClick = async () => {
    setProcessing(true);
    await next();
  };

  return (
    <ControllerLayout
      action={
        <BlockControllerActionButton
          onClick={onClick}
          icon={DoubleRightArrow}
          disable={processing}
        >
          {`Present Guess Who Transition`}
        </BlockControllerActionButton>
      }
    >
      <GameProgress {...props} phase='prompt' />
    </ControllerLayout>
  );
}

function MatchPromptInit(props: StageProps): JSX.Element {
  const [processing, setProcessing] = useState(false);
  const game = useGuessWhoGame();
  const time = game?.guessTimePerSubmissionSec ?? 0;
  const generatedMatchPrompts = useGeneratedMatchPrompts();
  const total = generatedMatchPrompts?.sequence.length ?? 0;

  const onClick = async () => {
    if (time === 0) return;
    setProcessing(true);
    await next();
    // the timer for this phase is quite different. Each drawing has its own
    // timer, and they don't trigger the timesup that moves the game to the
    // next status. The real countdownV2 for this phase is handled by
    // MatchPromptLocalControl in DrawingPromptBlockGamePlay.tsx.
  };

  return (
    <ControllerLayout
      action={
        <BlockControllerActionButton
          onClick={onClick}
          icon={DoubleRightArrow}
          disable={processing || time === 0}
        >
          {`[0/${total}] Start Guessing Timer (${time}s)`}
        </BlockControllerActionButton>
      }
    >
      <GameProgress {...props} phase='guess' />
    </ControllerLayout>
  );
}

function MatchPromptStart(props: StageProps): JSX.Element {
  const time = useGameSessionLocalTimer();
  const controlAPI = useGuessWhoGameControlAPI();
  const generatedMatchPrompts = useGeneratedMatchPrompts();
  const currMatchPrompt = useCurrMatchPrompt();
  const currMatchState = useCurrMatchState();
  const players = useParticipantsAsArray({
    filters: [
      'host:false',
      'cohost:false',
      'status:connected',
      'team:true',
      'staff:false',
    ],
  });

  const total = generatedMatchPrompts?.sequence.length ?? 0;
  const index = Math.min(currMatchState.index + 1, total);
  const [processing, setProcessing] = useState(false);

  useEffect(() => {
    if (
      currMatchState.index + 1 >= total &&
      currMatchState.phase === 'reveal'
    ) {
      next();
    }
  }, [currMatchState.index, currMatchState.phase, total]);

  const skip = useLiveCallback(() => {
    if (!currMatchPrompt) return;
    if (currMatchState.phase === 'vote') {
      controlAPI.startRevealSequence(
        currMatchPrompt,
        props.selectedBlock.fields.pointsPerCorrect,
        players,
        'controller skip'
      );
    } else if (currMatchState.phase === 'reveal') {
      controlAPI.nextMatch(currMatchState.index + 1);
    }
  });

  // this is mainly introduced for testing, the block recording test expected a
  // clickable button to move forward.
  const onClick = async () => {
    if (total > 0) return;
    setProcessing(true);
    await next();
  };

  return (
    <ControllerLayout
      action={
        total === 0 ? (
          <BlockControllerActionButton
            onClick={onClick}
            icon={DoubleRightArrow}
            disable={processing}
          >
            {`[${index}/${total}] No Submissions, Skip`}
          </BlockControllerActionButton>
        ) : (
          <BlockControllerActionTimeRemaining
            remainingSec={time}
            customizeLabel={(remainingSec) =>
              `[${index}/${total}] ${
                currMatchState.phase === 'vote'
                  ? `Time Remaining (${remainingSec}s)`
                  : `Revealing (${remainingSec}s)`
              }`
            }
            onSkip={skip}
          />
        )
      }
    >
      <GameProgress {...props} phase='guess' />
    </ControllerLayout>
  );
}

function MatchPromptEnd(props: StageProps): JSX.Element {
  const [processing, setProcessing] = useState(false);

  const onClick = async () => {
    setProcessing(true);
    await next();
  };

  return (
    <ControllerLayout
      action={
        <BlockControllerActionButton
          onClick={onClick}
          icon={DoubleRightArrow}
          disable={processing}
        >
          Reveal Results
        </BlockControllerActionButton>
      }
    >
      <GameProgress {...props} phase='guess' />
    </ControllerLayout>
  );
}

function Results(props: StageProps): JSX.Element {
  const [processing, setProcessing] = useState(false);
  const onShowScoreboard = async () => {
    setProcessing(true);
    await next();
  };

  return (
    <ControllerLayout
      action={
        <>
          <BlockControllerActionButton
            onClick={onShowScoreboard}
            isSecondary={true}
            disable={processing}
          >
            Show Scoreboard
          </BlockControllerActionButton>
          <BlockControllerActionButton
            onClick={props.onEndBlock}
            icon={FilledSquareIcon}
          >
            End Block Sequence
          </BlockControllerActionButton>
        </>
      }
    >
      <GameProgress {...props} phase='guess' />
    </ControllerLayout>
  );
}

function Scoreboard(props: StageProps): JSX.Element {
  return (
    <ControllerLayout
      action={
        <BlockControllerActionButton
          onClick={props.onEndBlock}
          icon={FilledSquareIcon}
        >
          End Block Sequence
        </BlockControllerActionButton>
      }
    >
      <GameProgress {...props} phase='guess' />
    </ControllerLayout>
  );
}

export function GuessWhoBlockController(
  props: SharedProps
): JSX.Element | null {
  const { selectedBlockIndex } = props;
  const block = useStableBlock(props.selectedBlock);
  const isEndedBlock = useIsEndedBlock(block.id);
  const gss = useGameSessionStatus<GuessWhoBlockGameSessionStatus>();
  const playback = useControllerMediaPlayback();

  if (selectedBlockIndex === null) return null;

  if (isEndedBlock) {
    return <Ended {...props} playback={playback} />;
  }

  switch (gss) {
    case GuessWhoBlockGameSessionStatus.LOADED:
      return <Loaded {...props} playback={playback} />;
    case GuessWhoBlockGameSessionStatus.INTRO:
      return <Intro {...props} playback={playback} />;
    case GuessWhoBlockGameSessionStatus.PROMPT_INIT:
      return <PromptInit {...props} playback={playback} />;
    case GuessWhoBlockGameSessionStatus.PROMPT_COUNTING:
      return <PromptCounting {...props} playback={playback} />;
    case GuessWhoBlockGameSessionStatus.PROMPT_DONE:
      return <PromptEnd {...props} playback={playback} />;
    case GuessWhoBlockGameSessionStatus.MATCH_PROMPT_INIT:
      return <MatchPromptInit {...props} playback={playback} />;
    case GuessWhoBlockGameSessionStatus.MATCH_PROMPT_COUNTING:
      return <MatchPromptStart {...props} playback={playback} />;
    case GuessWhoBlockGameSessionStatus.MATCH_PROMPT_DONE:
      return <MatchPromptEnd {...props} playback={playback} />;
    case GuessWhoBlockGameSessionStatus.RESULTS:
      return <Results {...props} playback={playback} />;
    case GuessWhoBlockGameSessionStatus.SCOREBOARD:
      return <Scoreboard {...props} playback={playback} />;
    case GuessWhoBlockGameSessionStatus.END:
    case null:
    case undefined:
      break;
    default:
      assertExhaustive(gss);
      break;
  }

  return null;
}
