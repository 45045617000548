import upperFirst from 'lodash/upperFirst';
import { useMemo } from 'react';
import Select from 'react-select';

import {
  EnumsMediaScene,
  EnumsMessageTemplateMediaSource,
  type ModelsMessageTemplateMedia,
} from '@lp-lib/api-service-client/public';

import { useInstance } from '../../hooks/useInstance';
import { fromMediaDTO, toMediaDTO } from '../../utils/api-dto';
import { assertExhaustive } from '../../utils/common';
import { ImagePickPriorityDefault } from '../../utils/media';
import { buildReactSelectStyles } from '../../utils/react-select';
import { type Option } from '../common/Utilities';
import { MediaPreview } from '../MediaUploader/MediaPreview';
import { MediaUploader } from '../MediaUploader/MediaUploader';
import { type MessageVars } from './MessageCampaign';

const SOURCE_CONFIG = {
  [EnumsMessageTemplateMediaSource.MessageTemplateMediaSourceUpload]: {
    label: 'Upload File',
    value: EnumsMessageTemplateMediaSource.MessageTemplateMediaSourceUpload,
  },
  [EnumsMessageTemplateMediaSource.MessageTemplateMediaSourcePredefined]: {
    label: 'Predefined',
    value: EnumsMessageTemplateMediaSource.MessageTemplateMediaSourcePredefined,
  },
};

function MessageTemplateMediaSourceSelect(props: {
  value: EnumsMessageTemplateMediaSource;
  supportedSources: EnumsMessageTemplateMediaSource[];
  onChange: (value: EnumsMessageTemplateMediaSource) => void;
}) {
  const { value, supportedSources, onChange } = props;

  const options = useMemo(
    () => supportedSources.map((source) => SOURCE_CONFIG[source]),
    [supportedSources]
  );
  const styles = useInstance(() =>
    buildReactSelectStyles<Option<EnumsMessageTemplateMediaSource>>({
      override: { control: { height: 48 } },
    })
  );

  return (
    <Select<Option<EnumsMessageTemplateMediaSource>>
      options={options}
      value={SOURCE_CONFIG[value]}
      classNamePrefix='select-box-v2'
      className='w-80'
      styles={styles}
      onChange={(option) => {
        if (!option) return;
        onChange(option.value);
      }}
    />
  );
}

function MessageTemplateMediaPredefinedSelect(props: {
  value: string;
  onChange: (value: string) => void;
  vars: MessageVars['media'];
}) {
  const options = useMemo(
    () =>
      Object.keys(props.vars).map((key) => ({
        label: upperFirst(key),
        value: key,
      })),
    [props.vars]
  );

  const styles = useInstance(() =>
    buildReactSelectStyles<Option<string>>({
      override: { control: { height: 48 } },
    })
  );

  return (
    <Select<Option<string>>
      options={options}
      value={{ label: props.value, value: props.value }}
      classNamePrefix='select-box-v2'
      className='w-80'
      styles={styles}
      onChange={(option) => {
        if (!option) return;
        props.onChange(option.value);
      }}
    />
  );
}

export function MessageTemplateMediaEditor(props: {
  media?: ModelsMessageTemplateMedia | null;
  vars: MessageVars['media'];
  onChange: (val: ModelsMessageTemplateMedia | null) => void;
  setIsUploading: (isUploading: boolean) => void;
}) {
  const { media, vars, onChange, setIsUploading } = props;

  const mediaSource =
    media?.source ||
    EnumsMessageTemplateMediaSource.MessageTemplateMediaSourceUpload;
  const supportedSources = useMemo(() => {
    const results = [
      EnumsMessageTemplateMediaSource.MessageTemplateMediaSourceUpload,
    ];
    if (Object.keys(vars).length > 0) {
      results.push(
        EnumsMessageTemplateMediaSource.MessageTemplateMediaSourcePredefined
      );
    }
    return results;
  }, [vars]);

  const handleSourceChange = (source: EnumsMessageTemplateMediaSource) => {
    if (source === mediaSource) return;
    switch (source) {
      case EnumsMessageTemplateMediaSource.MessageTemplateMediaSourceUpload:
        onChange({
          source: source,
          mediaData: null,
          media: null,
        });
        break;
      case EnumsMessageTemplateMediaSource.MessageTemplateMediaSourcePredefined:
        if (!vars) return;
        const predefined = Object.keys(vars)[0];
        if (!predefined) return;
        onChange({
          source: source,
          predefined: predefined,
        });
        break;
      default:
        assertExhaustive(source);
    }
  };

  return (
    <div>
      <div className='font-bold'>Media</div>

      <div className='mt-2 flex flex-col gap-2'>
        {supportedSources.length > 1 && (
          <MessageTemplateMediaSourceSelect
            value={mediaSource}
            supportedSources={supportedSources}
            onChange={handleSourceChange}
          />
        )}

        {mediaSource ===
          EnumsMessageTemplateMediaSource.MessageTemplateMediaSourceUpload && (
          <MediaUploader
            video={true}
            scene={EnumsMediaScene.MediaSceneProgram}
            media={fromMediaDTO(media?.media)}
            onUploadStart={() => setIsUploading(true)}
            onUploadSuccess={(media) => {
              onChange({
                source: mediaSource,
                mediaData: { id: media.id },
                media: toMediaDTO(media),
              });
              setIsUploading(false);
            }}
            onUploadFailed={() => setIsUploading(false)}
            onMediaDelete={() => {
              onChange({
                source: mediaSource,
                mediaData: null,
                media: null,
              });
            }}
            width='w-full max-w-100'
          />
        )}

        {mediaSource ===
          EnumsMessageTemplateMediaSource.MessageTemplateMediaSourcePredefined &&
          vars && (
            <MessageTemplateMediaPredefinedSelect
              value={media?.predefined || ''}
              onChange={(v) => {
                onChange({
                  source: mediaSource,
                  predefined: v,
                });
              }}
              vars={vars}
            />
          )}
      </div>
    </div>
  );
}

export function MessageTemplateMediaPreview(props: {
  media?: ModelsMessageTemplateMedia | null;
  mediaVars: MessageVars['media'];
}) {
  if (!props.media) return null;

  const mediaSource =
    props.media.source ||
    EnumsMessageTemplateMediaSource.MessageTemplateMediaSourceUpload;
  switch (mediaSource) {
    case EnumsMessageTemplateMediaSource.MessageTemplateMediaSourceUpload:
      if (!props.media.media) return null;
      return (
        <MediaPreview
          media={fromMediaDTO(props.media.media)}
          priority={ImagePickPriorityDefault}
        />
      );
    case EnumsMessageTemplateMediaSource.MessageTemplateMediaSourcePredefined:
      if (!props.media.predefined || !props.mediaVars) return null;
      const media = props.mediaVars[props.media.predefined];
      if (!media) return null;
      return <MediaPreview media={media} priority={ImagePickPriorityDefault} />;
    default:
      assertExhaustive(mediaSource);
      return null;
  }
}
