import { useLayoutEffect, useState } from 'react';
import { useLatest } from 'react-use';

import { type Participant, type ParticipantFlags } from '../../../types';
import { ValtioUtils } from '../../../utils/valtio';
import { useGameHostingCoordinator } from '../../Game/GameHostingProvider';
import { useIsLiveGamePlay } from '../../Game/hooks';
import {
  useParticipantsAsArray,
  useParticipantsFlagsGetter,
} from '../../Player';
import { useUserContext } from '../../UserContext';
import { useMyClientId } from '../../Venue';
import {
  useTownhallAPI,
  useTownhallConfig,
  useTownhallCrowdLastSpokenAtMap,
} from '../Provider';

function intoMinimal(p: Participant, flags: ParticipantFlags | undefined) {
  return { clientId: p.clientId, audio: flags?.audio };
}

function intoMinimals(
  players: Participant[],
  getParticipantsFlag: ReturnType<typeof useParticipantsFlagsGetter>
) {
  const pFlags = getParticipantsFlag();
  return players.map((p) => intoMinimal(p, pFlags[p.clientId]));
}

export function useOndTownhallAutoMute() {
  const players = useParticipantsAsArray({
    filters: [
      'host:false',
      'cohost:false',
      'status:connected',
      'staff:false',
      'audio:true',
    ],
    sorts: ['joinedAt:desc'],
  });
  const getParticipantsFlag = useParticipantsFlagsGetter();
  const [micEnabledPlayers, setMicEnabledPlayers] = useState(
    intoMinimals(players, getParticipantsFlag)
  );
  const myClientId = useMyClientId();
  const config = useTownhallConfig();
  const townhallAPI = useTownhallAPI();
  const coordinator = useGameHostingCoordinator();
  const crowdLastSpokenAtMap = useLatest(useTownhallCrowdLastSpokenAtMap());
  const isOndGame = !useIsLiveGamePlay();
  const { toggleAudio } = useUserContext();
  const enabled = isOndGame && config.enabled && config.mode === 'crowd';

  // avoid triggering the "expensive" auto mute effect if unrelated state changes
  useLayoutEffect(() => {
    if (!enabled) return;
    const curr = intoMinimals(players, getParticipantsFlag);
    setMicEnabledPlayers((prev) => {
      if (prev.length !== curr.length) return curr;
      if (
        prev.every(
          (p, i) => curr[i].clientId === p.clientId && curr[i].audio === p.audio
        )
      ) {
        return prev;
      }
      return curr;
    });
  }, [enabled, getParticipantsFlag, players]);

  useLayoutEffect(() => {
    townhallAPI.log.debug('auto mute enabled', {
      enabled,
      micEnabledPlayers: micEnabledPlayers.length,
      threshold: config.ondAutoMuteThreshold,
    });
    if (!enabled) return;
    if (micEnabledPlayers.length <= config.ondAutoMuteThreshold) return;
    const muteCandidates = new Map<string, string>();
    const muteCount = micEnabledPlayers.length - config.ondAutoMuteThreshold;

    const lastSpokenAtLookup = crowdLastSpokenAtMap.current ?? {};

    townhallAPI.log.debug('auto mute count', {
      muteCount,
      lastSpokenAtLookup: ValtioUtils.detachCopy(lastSpokenAtLookup),
    });

    // find the candidates from non-speakers first
    for (const p of micEnabledPlayers) {
      if (p.clientId === coordinator?.clientId) continue;
      const lastSpokenAt = lastSpokenAtLookup[p.clientId];
      if (!lastSpokenAt) {
        muteCandidates.set(p.clientId, 'never speak');
        if (muteCandidates.size >= muteCount) break;
      }
    }

    // if we still don't have enough candidates, find them from speakers
    if (muteCandidates.size < muteCount) {
      const playerIdSet = new Set(micEnabledPlayers.map((p) => p.clientId));
      const sortedSpeakers = Object.entries(lastSpokenAtLookup)
        .sort((a, b) => a[1] - b[1])
        .map((p) => p[0]);

      for (const clientId of sortedSpeakers) {
        if (clientId === coordinator?.clientId) continue;
        if (!playerIdSet.has(clientId)) continue;
        if (muteCandidates.has(clientId)) continue;
        muteCandidates.set(
          clientId,
          `speaker lru ${lastSpokenAtLookup[clientId]}`
        );
        if (muteCandidates.size >= muteCount) break;
      }
    }

    const muteCandidatesForLogging = Object.fromEntries(muteCandidates);

    townhallAPI.log.debug('auto mute candidates', {
      muteCandidates: muteCandidatesForLogging,
    });

    if (muteCandidates.has(myClientId)) {
      townhallAPI.log.info('auto mute myself', {
        muteCandidates: muteCandidatesForLogging,
      });
      toggleAudio(false, 'system');
    }
  }, [
    config.ondAutoMuteThreshold,
    coordinator?.clientId,
    crowdLastSpokenAtMap,
    enabled,
    micEnabledPlayers,
    myClientId,
    toggleAudio,
    townhallAPI,
  ]);
}
