import React, {
  type ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useLatest, usePrevious, usePromise } from 'react-use';

import {
  isServerValue,
  RTDBServerValueTIMESTAMP,
} from '@lp-lib/firebase-typesafe';
import { BlockType, type TeamRelayBlock } from '@lp-lib/game';

import {
  getFeatureQueryParam,
  getFeatureQueryParamNumber,
} from '../../../../hooks/useFeatureQueryParam';
import { useInstance } from '../../../../hooks/useInstance';
import { useIsController } from '../../../../hooks/useMyInstance';
import { apiService } from '../../../../services/api-service';
import {
  type SequenceConfig,
  type TeamRelayBlockSettings,
  type TeamRelayLevel,
} from '../../../../types/block';
import { type TeamId } from '../../../../types/team';
import { assertExhaustive, sleep, uuidv4 } from '../../../../utils/common';
import { Emitter } from '../../../../utils/emitter';
import {
  useDatabaseRef,
  useFirebaseBatchWrite,
  useFirebaseValue,
} from '../../../Firebase';
import { useMyTeamId } from '../../../Player';
import { useParticipantsAsArray } from '../../../Player';
import { useIsStreamSessionAlive } from '../../../Session';
import { useTeamMembersByTeamIds, useTeams } from '../../../TeamAPI/TeamV1';
import { useVenueId } from '../../../Venue/VenueProvider';
import { useGameSessionLocalTimer } from '../../hooks';
import { useGamePlayEmitter } from '../Common/GamePlay/GamePlayProvider';
import { type GamePlayEndedState } from '../Common/GamePlay/Internal';
import { BlockKnifeUtils } from '../Shared';
import {
  type GameProgressDetail,
  type GameProgressSummary,
  type GameSettings,
  GameState,
  type GameTeamInfo,
  type PlayerId,
  type Progress,
  RelayNodeState,
  RenderType,
  type Sequence,
  type TeamPlayerMap,
  type TeamRelayGame,
} from './types';
import {
  buildTeamSequenceData,
  generateSequence,
  getDifficultyLevel,
  getTeamRelayFBPath,
  getTeamRelayFBPathByTeam,
  getTeamRelayGameTime,
  getTeamRelayLevel,
  isSequenceFinished,
  log,
  makeInitProgess,
} from './utils';
import { uncheckedIndexAccess_UNSAFE } from '../../../../utils/uncheckedIndexAccess_UNSAFE';

interface TeamRelayGameControlAPI {
  initGame: (block: TeamRelayBlock, level: TeamRelayLevel) => Promise<void>;
  startGame: () => Promise<void>;
  stopGame: () => Promise<void>;
  makeNextSequence: (
    teamId: TeamId,
    options: { idx: number; config: SequenceConfig; initedGameTime: number }
  ) => Promise<void>;
  resetGame: (debug: string) => Promise<void>;
  deinitGame: () => Promise<void>;
  batchUpdateProgress: (
    changes: { teamId: TeamId; progress: Progress }[]
  ) => Promise<void>;
  updateProgressSummary: (
    teamId: TeamId,
    summary: GameProgressSummary['number']
  ) => Promise<void>;
}

type TeamRelayEvents = {
  'sequence-finished': (teamId: TeamId, progress: Progress) => void;
};

type SharedContext = {
  game: Nullable<TeamRelayGame>;
  settings: Nullable<GameSettings>;
  summary: Nullable<GameProgressSummary>;
  blockSettings: TeamRelayBlockSettings;

  // We need these fields since organizer is using HostContext,
  // but need ability to play game as an audience.
  playerIds: PlayerId[];
  sequence: Nullable<Sequence>;
  progress: Nullable<Progress>;
  updateProgress: (next: Nullable<Progress, false>) => Promise<void>;
};

type HostContext = SharedContext & {
  updateGame: (next: Nullable<TeamRelayGame, false>) => Promise<void>;
  updateGameSettings: (next: Nullable<GameSettings, false>) => Promise<void>;
  isController: true;
  emitter: Emitter<TeamRelayEvents>;
  teamPlayerMap: React.MutableRefObject<TeamPlayerMap>;
  progressDetail: Nullable<GameProgressDetail>;
  teamInfo: Nullable<GameTeamInfo>;
};

type AudienceContext = SharedContext & {
  isController: false;
};

type Context = HostContext | AudienceContext;

const context = React.createContext<Nullable<Context, false>>(null);

export function useSharedContext(): Context {
  const ctx = useContext(context);
  if (!ctx) throw new Error('TeamRelayContext is not in the tree!');
  return ctx;
}

function useHostContext(): HostContext {
  const ctx = useSharedContext();
  const isController = useIsController();
  if (!ctx.isController || !isController)
    throw new Error('The context should be used from controller only');
  return ctx;
}

export function useAudienceContext(): AudienceContext {
  const ctx = useSharedContext();
  const isController = useIsController();
  if (ctx.isController || isController)
    throw new Error('The context should be used from player only');
  return ctx;
}

export function useTeamRelayGameState(): Nullable<GameState> {
  return useSharedContext().game?.state;
}

export function useTeamRelayGameSettings(): Nullable<GameSettings> {
  return useSharedContext().settings;
}

export function useTeamRelayBlockSettings(): TeamRelayBlockSettings {
  return useSharedContext().blockSettings;
}

export function useTeamRelayTotalGameTime(): number {
  return useTeamRelayGameSettings()?.totalGameTime || 0;
}

export function useTeamRelayGameProgressDetail(): Nullable<GameProgressDetail> {
  return useHostContext().progressDetail;
}

export function useTeamRelayGameTeamInfo(): Nullable<GameTeamInfo> {
  return useHostContext().teamInfo;
}

function useSelectCandidateTeams(): TeamPlayerMap {
  const teams = useTeams({
    updateStaffTeam: true,
    excludeStaffTeam: true,
  });
  const teamIds = teams.map((t) => t.id);
  const teamMembers = useTeamMembersByTeamIds(teamIds);
  const participants = useParticipantsAsArray({
    filters: ['host:false', 'cohost:false', 'status:connected', 'team:true'],
  });
  return useMemo(() => {
    const teamPlayerMap: TeamPlayerMap = {};
    const userMap = new Map<string, string>();
    participants.forEach((p) => {
      userMap.set(p.clientId, p.id);
    });
    teamIds.forEach((teamId) => {
      const members = teamMembers[teamId] || [];
      const playerIds: string[] = [];
      members.forEach((m) => {
        const uid = userMap.get(m.id);
        if (uid) {
          playerIds.push(uid);
        } else {
          log.warn('uid not found', { clientId: m.id });
        }
      });
      teamPlayerMap[teamId] = playerIds;
    });
    return teamPlayerMap;
  }, [teamIds, teamMembers, participants]);
}

export function useTeamRelayGameControl(): TeamRelayGameControlAPI {
  const venueId = useVenueId();
  const { settings, updateGame, updateGameSettings, game, teamPlayerMap } =
    useHostContext();
  const gameId = game?.id;
  const batchWrite = useFirebaseBatchWrite();
  const candidateTeamMap = useLatest(useSelectCandidateTeams());
  const ref = useDatabaseRef(getTeamRelayFBPath(venueId, 'root'));
  const minSpans = useInstance(() =>
    getFeatureQueryParamNumber('team-relay-extended-node-min-length')
  );

  const reset = useCallback(
    async (debug: string) => {
      await updateGame(null);
      await ref.remove();
      log.info(`reset game: ${debug}`);
    },
    [ref, updateGame]
  );

  const init = useCallback(
    async (block: TeamRelayBlock, level: TeamRelayLevel) => {
      if (level.configs.length === 0) return;
      const updates = uncheckedIndexAccess_UNSAFE({});
      for (const [teamId, playerIds] of Object.entries(
        candidateTeamMap.current
      )) {
        const data = buildTeamSequenceData(venueId, teamId, {
          playerIds,
        });
        for (const [k, v] of Object.entries(data)) updates[k] = v;
        log.info('init game for team', { data, teamId });
      }
      teamPlayerMap.current = candidateTeamMap.current;
      await batchWrite(updates);
      await updateGame({ id: uuidv4(), state: GameState.Inited });

      const totalGameTime = await getTeamRelayGameTime(block, level);

      const totalPoints = block.fields.points;
      const numOfSequences = level ? level.configs.length : 0;
      const pointsPerSequence =
        numOfSequences === 0 ? 0 : Math.round(totalPoints / numOfSequences);

      await updateGameSettings({
        level,
        totalGameTime,
        pointsPerSequence,
        wrongTurnPenalty: getFeatureQueryParam('team-relay-wrong-turn-penalty'),
      });
    },
    [
      batchWrite,
      updateGame,
      updateGameSettings,
      candidateTeamMap,
      teamPlayerMap,
      venueId,
    ]
  );

  const makeNextSequence = useCallback(
    async (
      teamId: TeamId,
      options: { idx: number; config: SequenceConfig; initedGameTime: number }
    ) => {
      const playerIds = teamPlayerMap.current[teamId] ?? [];
      const updates = buildTeamSequenceData(venueId, teamId, {
        sequence: generateSequence(playerIds, options.config, {
          minSpans: minSpans,
        }),
        progress: makeInitProgess(options.idx, options.initedGameTime),
      });
      log.info('make next sequence for team', { data: updates, teamId });
      await batchWrite(updates);
    },
    [teamPlayerMap, venueId, minSpans, batchWrite]
  );

  const start = useCallback(async () => {
    if (!gameId || !settings || settings.level.configs.length === 0) return;
    const updates = uncheckedIndexAccess_UNSAFE({});
    for (const [teamId, playerIds] of Object.entries(teamPlayerMap.current)) {
      const data = buildTeamSequenceData(venueId, teamId, {
        sequence: generateSequence(playerIds, settings.level.configs[0], {
          minSpans: minSpans,
        }),
        progress: makeInitProgess(0, settings.totalGameTime),
      });
      for (const [k, v] of Object.entries(data)) updates[k] = v;
    }
    await batchWrite(updates);
    await updateGame({
      id: gameId,
      state: GameState.InProgress,
    });
  }, [
    gameId,
    settings,
    batchWrite,
    updateGame,
    teamPlayerMap,
    venueId,
    minSpans,
  ]);

  const stop = useCallback(async () => {
    if (!gameId) return;
    await updateGame({ id: gameId, state: GameState.Ended });
  }, [updateGame, gameId]);

  const deinit = useCallback(async () => {
    if (!gameId) return;
    await updateGame({ id: gameId, state: GameState.None });
  }, [updateGame, gameId]);

  const batchUpdateProgress = useCallback(
    async (changes: { teamId: TeamId; progress: Progress }[]) => {
      const updates = uncheckedIndexAccess_UNSAFE({});
      changes.forEach((c) => {
        updates[getTeamRelayFBPathByTeam(venueId, c.teamId, 'progress')] =
          c.progress;
      });
      await batchWrite(updates);
    },
    [batchWrite, venueId]
  );

  const updateProgressSummary = useCallback(
    async (teamId: TeamId, summary: GameProgressSummary['number']) => {
      await batchWrite({
        [getTeamRelayFBPathByTeam(venueId, teamId, 'progress-summary')]:
          summary,
      });
    },
    [batchWrite, venueId]
  );

  return {
    initGame: init,
    makeNextSequence,
    resetGame: reset,
    startGame: start,
    stopGame: stop,
    deinitGame: deinit,
    batchUpdateProgress,
    updateProgressSummary,
  };
}

function useInitTeamRelayGame(options?: {
  enabled: boolean;
  readonly: boolean;
}) {
  const venueId = useVenueId();
  return useFirebaseValue<Nullable<TeamRelayGame, false>>(
    getTeamRelayFBPath(venueId, 'game'),
    {
      enabled: !!options?.enabled,
      seedValue: null,
      seedEnabled: false,
      readOnly: !!options?.readonly,
      resetWhenUmount: true,
    }
  );
}

function useInitGameSettings(options?: {
  enabled: boolean;
  readonly: boolean;
}) {
  const venueId = useVenueId();
  return useFirebaseValue<Nullable<GameSettings>>(
    getTeamRelayFBPath(venueId, 'game-settings'),
    {
      enabled: !!options?.enabled,
      seedValue: null,
      seedEnabled: false,
      readOnly: !!options?.readonly,
      resetWhenUmount: true,
    }
  );
}

function useInitGameProgressSummary(options?: { enabled: boolean }) {
  const venueId = useVenueId();
  return useFirebaseValue<Nullable<GameProgressSummary>>(
    getTeamRelayFBPath(venueId, 'game-progress-summary'),
    {
      enabled: !!options?.enabled,
      seedValue: null,
      seedEnabled: false,
      readOnly: false,
      resetWhenUmount: true,
    }
  );
}

function useInitTeamRelayBlockSettings(options?: {
  enabled: boolean;
}): TeamRelayBlockSettings {
  const enabled = !!options?.enabled;
  const mounted = usePromise();
  const [settings, setSettings] = useState<TeamRelayBlockSettings>({
    levels: [],
  });
  useEffect(() => {
    if (!enabled) return;
    async function init() {
      let maxAttempts = 10;
      while (maxAttempts > 0) {
        try {
          const resp = await mounted(
            apiService.block.getBlockSettings<TeamRelayBlockSettings>(
              BlockType.TEAM_RELAY
            )
          );
          if (!resp.data) return;
          setSettings(resp.data);
          break;
        } catch (error) {
          log.error('load levels error', error);
          await sleep(200);
        }
        maxAttempts--;
      }
    }
    init();
  }, [mounted, enabled]);
  return settings;
}

export function useTeamSequenceFinished(): Nullable<number, false> {
  const { progress } = useSharedContext();
  const settings = useTeamRelayGameSettings();
  const [sequenceIdx, setSequenceIdx] = useState<Nullable<number, false>>(null);

  useEffect(() => {
    if (!settings || !progress) return;
    if (isSequenceFinished(progress, settings)) {
      setSequenceIdx(progress.idx);
    }
  }, [progress, settings]);

  return sequenceIdx;
}

export function useTeamProgessSummary(): Nullable<GameProgressSummary> {
  return useSharedContext().summary;
}

export function useTeamRelayEmitter(): Emitter<TeamRelayEvents> {
  return useHostContext().emitter;
}

export function useEmitGamePlayEndedState(
  block: TeamRelayBlock
): Nullable<GamePlayEndedState> {
  const emitter = useGamePlayEmitter();
  const [finalState, setFinalState] = useState<Nullable<GamePlayEndedState>>();
  const goalMedia = BlockKnifeUtils.GetGoalCompletionMedia(block);
  const time = useGameSessionLocalTimer();
  const gameState = useTeamRelayGameState();
  const finishedSequenceIdx = useTeamSequenceFinished();
  const settings = useTeamRelayGameSettings();

  // timesup
  useEffect(() => {
    if (finalState) return;
    if (gameState === GameState.Ended) {
      emitter.emit('ended', block.id, 'timesup');
      setFinalState('timesup');
    }
  }, [time, gameState, emitter, block.id, finalState]);

  // finished
  useEffect(() => {
    if (
      finalState ||
      finishedSequenceIdx === null ||
      !settings ||
      gameState !== GameState.InProgress
    )
      return;
    if (finishedSequenceIdx + 1 === settings.level.configs.length) {
      if (goalMedia)
        emitter.emit('ended-awaiting-goal-media', block.id, 'finished', {
          animationMedia: goalMedia,
        });
      else
        emitter.emit('ended', block.id, 'finished', {
          animationMedia: goalMedia,
        });
      setFinalState('finished');
    }
  }, [
    block.fields.goalAnimationMedia,
    block.id,
    emitter,
    finalState,
    finishedSequenceIdx,
    gameState,
    goalMedia,
    settings,
  ]);

  useEffect(() => {
    return () => {
      setFinalState(undefined);
    };
  }, [block.id]);

  return finalState;
}

export function useDerivedTeamProgressMap(output: 'percentage' | 'counter'): {
  [key: TeamId]: number;
} {
  const teamProgressSummary = useTeamProgessSummary();
  const settings = useTeamRelayGameSettings();
  const [teamProgressMap, setTeamProgressMap] = useState<{
    [key: TeamId]: number;
  }>({});
  useEffect(() => {
    if (
      !teamProgressSummary ||
      !settings ||
      settings.level.configs.length === 0
    )
      return;
    const newMap = uncheckedIndexAccess_UNSAFE({});
    for (const [teamId, summary] of Object.entries(teamProgressSummary)) {
      switch (output) {
        case 'counter':
          newMap[teamId] = summary.numOfSequenceFinished;
          break;
        case 'percentage':
          newMap[teamId] =
            summary.numOfSequenceFinished / settings.level.configs.length;
          break;
        default:
          assertExhaustive(output);
          break;
      }
    }
    setTeamProgressMap(newMap);
  }, [teamProgressSummary, settings, output]);
  return teamProgressMap;
}

export function useSequenceAutoProgress(
  progressDetail: Nullable<GameProgressDetail>,
  teamInfoMap: Nullable<GameTeamInfo>,
  pause: boolean
): void {
  const { batchUpdateProgress } = useTeamRelayGameControl();
  const latestProgressDetail = useLatest(progressDetail);
  const latestTeamInfoMap = useLatest(teamInfoMap);
  const gameState = useTeamRelayGameState();
  const autoProgressConfig = useInstance(() => ({
    timeout: getFeatureQueryParamNumber('team-relay-auto-progress-timeout'),
    playerDisconnected: getFeatureQueryParam(
      'team-relay-auto-progress-if-player-disconnected'
    ),
  }));
  const latestParticipants = useLatest(
    useParticipantsAsArray({
      filters: ['host:false', 'cohost:false', 'status:connected', 'team:true'],
    })
  );

  const autoProgressIfTimeout = useCallback(
    (teamId: TeamId, progress: Progress) => {
      if (isServerValue(progress.updatedAt)) {
        log.warn('progress.updatedAt is the firebase TIMESTAMP placeholder', {
          teamId,
          progress,
        });
        return;
      }
      if (Date.now() - progress.updatedAt > autoProgressConfig.timeout) {
        log.info('no progress within the threshold, trigger auto progress', {
          teamId,
          progress,
        });
        return {
          teamId,
          progress: {
            idx: progress.idx,
            currentNodeIdx: progress.currentNodeIdx + 1,
            lastNodeState: RelayNodeState.Hit,
            localUpdatedAt: Date.now(),
            updatedAt: RTDBServerValueTIMESTAMP,
            initedGameTime: progress.initedGameTime,
          },
        };
      }
    },
    [autoProgressConfig]
  );

  const autoProgressIfPlayerUnavailable = useCallback(
    (teamId: TeamId, progress: Progress) => {
      if (!latestTeamInfoMap.current || !autoProgressConfig.playerDisconnected)
        return;
      const teamInfo = latestTeamInfoMap.current[teamId];
      if (!teamInfo) return;

      const currentNodePlayerIds: PlayerId[] = [];
      for (let i = 0; i < teamInfo.sequence.grid.length; i++) {
        const row = teamInfo.sequence.grid[i];
        const node = row[progress.currentNodeIdx];
        if (!node) {
          log.warn('autoProgressIfPlayerUnavailable node empty', {
            sequence: teamInfo.sequence,
            progress: progress,
            idx: i,
            teamId,
          });
          return;
        }
        // Each column should only have one interactive node (Rap, HoldStart, HoldEnd)
        switch (node.renderType) {
          case RenderType.Tap:
          case RenderType.HoldStart:
          case RenderType.HoldEnd:
            currentNodePlayerIds.push(teamInfo.playerIds[i]);
            break;
          case RenderType.HoldJoin:
          case RenderType.Placeholder:
            break;
          default:
            assertExhaustive(node.renderType);
            break;
        }
      }
      if (currentNodePlayerIds.length === 0) {
        log.warn('currentNodePlayerIds not found', { teamInfo, teamId });
        return;
      }
      if (currentNodePlayerIds.length > 1) {
        log.warn('it should have only one currentNodePlayerId', {
          teamInfo,
          teamId,
        });
        return;
      }
      const player = latestParticipants.current.find(
        (p) => p.id === currentNodePlayerIds[0]
      );
      // player is available, no need auto progress.
      if (player) return;

      return {
        teamId,
        progress: {
          idx: progress.idx,
          currentNodeIdx: progress.currentNodeIdx + 1,
          lastNodeState: RelayNodeState.Hit,
          localUpdatedAt: Date.now(),
          updatedAt: RTDBServerValueTIMESTAMP,
          initedGameTime: progress.initedGameTime,
        },
      };
    },
    [autoProgressConfig, latestParticipants, latestTeamInfoMap]
  );
  useEffect(() => {
    if (
      gameState !== GameState.InProgress ||
      autoProgressConfig.timeout === 0 ||
      pause
    )
      return;
    const timer = setInterval(() => {
      if (!latestProgressDetail.current) return;
      const changes: { teamId: TeamId; progress: Progress }[] = [];
      for (const [teamId, progress] of Object.entries(
        latestProgressDetail.current
      )) {
        if (!progress) continue;
        try {
          const c1 = autoProgressIfTimeout(teamId, progress);
          if (c1) changes.push(c1);
        } catch (error) {
          log.error('autoProgressIfTimeout', error);
        }
        try {
          const c2 = autoProgressIfPlayerUnavailable(teamId, progress);
          if (c2) changes.push(c2);
        } catch (error) {
          log.error('autoProgressIfPlayerUnavailable', error);
        }
      }
      if (changes.length > 0) batchUpdateProgress(changes);
    }, 1000);
    return () => {
      clearInterval(timer);
    };
  }, [
    autoProgressConfig,
    autoProgressIfPlayerUnavailable,
    autoProgressIfTimeout,
    batchUpdateProgress,
    gameState,
    latestProgressDetail,
    pause,
  ]);
}

export function useResolveTeamRelayLevel(
  block: TeamRelayBlock
): TeamRelayLevel | null {
  const mounted = usePromise();
  const blockSettings = useTeamRelayBlockSettings();
  const [level, setLevel] = useState<TeamRelayLevel | null>(null);
  const levelIdx = getDifficultyLevel(block);
  useEffect(() => {
    async function init() {
      setLevel(await mounted(getTeamRelayLevel(levelIdx, blockSettings)));
    }
    init();
  }, [levelIdx, blockSettings, mounted]);
  return level;
}

function HostBootstrap(): JSX.Element | null {
  const settings = useTeamRelayGameSettings();
  const { emitter, game, progressDetail } = useHostContext();
  const gameId = game?.id;
  const prevGameId = usePrevious(gameId);
  const emitted = useInstance(() => new Set<string>());

  useEffect(() => {
    if (prevGameId !== gameId) {
      emitted.clear();
    }
  }, [emitted, gameId, prevGameId]);

  useEffect(() => {
    return () => {
      emitted.clear();
    };
  }, [emitted]);

  // watch the game play and emitter the events of sequence-finished
  useEffect(() => {
    if (!progressDetail || !settings) return;
    for (const [teamId, progress] of Object.entries(progressDetail)) {
      if (!progress) continue;
      const finished = isSequenceFinished(progress, settings);
      if (finished) {
        const key = `${teamId}#${progress.idx}`;
        if (emitted.has(key)) continue;
        emitter.emit('sequence-finished', teamId, progress);
        emitted.add(key);
      }
    }
  }, [emitted, emitter, settings, progressDetail]);

  return null;
}

function HostProvider(props: { children?: ReactNode }): JSX.Element {
  const venueId = useVenueId();
  const teamId = useMyTeamId() || '';
  const emitter = useInstance(() => new Emitter<TeamRelayEvents>());
  const isSessionAlive = useIsStreamSessionAlive();
  const teamPlayerMap = useRef<TeamPlayerMap>({});
  const [game, writeGame] = useInitTeamRelayGame({
    enabled: isSessionAlive,
    readonly: false,
  });
  const [gameSettings, writeGameSettings] = useInitGameSettings({
    enabled: isSessionAlive,
    readonly: false,
  });
  const [summary] = useInitGameProgressSummary({ enabled: isSessionAlive });
  const blockSettings = useInitTeamRelayBlockSettings({
    enabled: isSessionAlive,
  });
  const [progressDetail, writeProgressDetail] = useFirebaseValue<
    Nullable<GameProgressDetail>
  >(getTeamRelayFBPath(venueId, 'game-progress'), {
    enabled: true,
    seedValue: null,
    seedEnabled: false,
    readOnly: false,
    resetWhenUmount: true,
  });
  const [teamInfo] = useFirebaseValue<Nullable<GameTeamInfo>>(
    getTeamRelayFBPath(venueId, 'teams'),
    {
      enabled: true,
      seedValue: null,
      seedEnabled: false,
      readOnly: false,
      resetWhenUmount: true,
    }
  );

  const updateProgress = useCallback(
    (next: Nullable<Progress, false>): Promise<void> => {
      return writeProgressDetail({
        [teamId]: next,
      });
    },
    [teamId, writeProgressDetail]
  );

  const ctxValue: HostContext = useMemo(
    () => ({
      isController: true,
      game: game,
      updateGame: writeGame,
      settings: gameSettings,
      updateGameSettings: writeGameSettings,
      emitter,
      teamPlayerMap,
      summary,
      blockSettings,
      progressDetail,
      teamInfo,

      playerIds: teamInfo?.[teamId]?.playerIds || [],
      sequence: teamInfo?.[teamId]?.sequence,
      progress: progressDetail?.[teamId],
      updateProgress,
    }),
    [
      blockSettings,
      emitter,
      game,
      gameSettings,
      progressDetail,
      summary,
      teamId,
      teamInfo,
      updateProgress,
      writeGame,
      writeGameSettings,
    ]
  );

  useEffect(() => {
    return () => {
      emitter.clear();
      teamPlayerMap.current = {};
    };
  }, [emitter]);

  return (
    <context.Provider value={ctxValue}>
      {isSessionAlive && <HostBootstrap />}
      {props.children}
    </context.Provider>
  );
}

function AudienceProvider(props: { children?: ReactNode }): JSX.Element {
  const venueId = useVenueId();
  const isSessionAlive = useIsStreamSessionAlive();
  const teamId = useMyTeamId() || '';
  const [syncedGame] = useInitTeamRelayGame({
    enabled: isSessionAlive,
    readonly: true,
  });
  const [gameSettings] = useInitGameSettings({
    enabled: isSessionAlive,
    readonly: true,
  });
  const [syncedSummary] = useInitGameProgressSummary({
    enabled: isSessionAlive,
  });
  const [syncedPlayerIds] = useFirebaseValue<string[]>(
    getTeamRelayFBPathByTeam(venueId, teamId, 'playerIds'),
    {
      enabled: !!teamId && isSessionAlive,
      seedValue: [],
      seedEnabled: false,
      readOnly: true,
      resetWhenUmount: true,
    }
  );
  const [syncedSequence] = useFirebaseValue<Sequence>(
    getTeamRelayFBPathByTeam(venueId, teamId, 'sequence'),
    {
      enabled: !!teamId && isSessionAlive,
      seedValue: { grid: [], direction: 'forward' },
      seedEnabled: false,
      readOnly: true,
      resetWhenUmount: true,
    }
  );
  const [sycnedProgress, writeSyncedProgess] = useFirebaseValue<
    Nullable<Progress, false>
  >(getTeamRelayFBPathByTeam(venueId, teamId, 'progress'), {
    enabled: !!teamId && isSessionAlive,
    seedValue: null,
    seedEnabled: false,
    readOnly: false,
    resetWhenUmount: true,
  });
  const blockSettings = useInitTeamRelayBlockSettings({
    enabled: isSessionAlive,
  });

  const ctxValue: AudienceContext = useMemo(
    () => ({
      isController: false,
      game: syncedGame,
      settings: gameSettings ?? null,
      summary: syncedSummary,
      playerIds: syncedPlayerIds || [],
      sequence: syncedSequence,
      progress: sycnedProgress,
      updateProgress: writeSyncedProgess,
      blockSettings,
    }),
    [
      blockSettings,
      gameSettings,
      sycnedProgress,
      syncedGame,
      syncedPlayerIds,
      syncedSequence,
      syncedSummary,
      writeSyncedProgess,
    ]
  );
  return <context.Provider value={ctxValue}>{props.children}</context.Provider>;
}

export function TeamRelayProvider(props: {
  children?: ReactNode;
}): JSX.Element | null {
  const isController = useIsController();

  if (isController) {
    return <HostProvider>{props.children}</HostProvider>;
  }
  return <AudienceProvider>{props.children}</AudienceProvider>;
}
