import React, { type ReactNode, useContext, useEffect, useMemo } from 'react';
import { proxy } from 'valtio';
import { devtools } from 'valtio/utils';

import { type FirebaseSafeRead } from '@lp-lib/firebase-typesafe';

import { useInstance } from '../../hooks/useInstance';
import { useIsCoordinator } from '../../hooks/useMyInstance';
import { useStatsAwareTaskQueue } from '../../hooks/useTaskQueue';
import { SignalManager } from '../../utils/SignalManager';
import {
  markSnapshottable,
  useSnapshot,
  type ValtioSnapshottable,
  ValtioUtils,
} from '../../utils/valtio';
import { type FirebaseService } from '../Firebase';
import { useVenueId } from '../Venue/VenueProvider';

const OffBoardingActions = ['offboarding'] as const;
type OffBoardingAction = (typeof OffBoardingActions)[number];

class OffBoardingActionsSignalManager extends SignalManager<OffBoardingAction> {
  static Create() {
    return new OffBoardingActionsSignalManager(
      'off-boarding-actions',
      OffBoardingActions
    );
  }
}

type GamePackLoads = {
  [key: string]: { loadedAt: number; isReplayable: boolean };
};

export type GuestConversionData = {
  orgId: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  from:
    | 'live-lobby-banner'
    | 'live-post-game'
    | 'ond-lobby-banner'
    | 'ond-post-game';
};

type State = {
  hide: boolean;
  /**
   * Represents the last known "offboarding" sessionId for this venue. Synced with clients after a session is "over",
   * to power the memories view. This value is set to null automatically by the coordinator.
   */
  offBoardingSessionId: string | null;
  gamePackPlay: GamePackLoads;
  ondConversion: false | GuestConversionData;
};

function initialState(): State {
  return {
    hide: false,
    offBoardingSessionId: null,
    gamePackPlay: {},
    ondConversion: false,
  };
}

class LobbyAPI {
  constructor(
    venueId: string,
    private state: State,
    svc: FirebaseService,
    private offBoardingActionsSignalManager: OffBoardingActionsSignalManager,
    private ref = svc.prefixedSafeRef<State>(`venues/${venueId}/lobby`)
  ) {}

  async init(isCoordinator: boolean) {
    const set = (state: FirebaseSafeRead<State> | null) => {
      this.state.hide = !!state?.hide;
      this.state.offBoardingSessionId = state?.offBoardingSessionId ?? null;
    };

    const state = (await this.ref.get()).val();
    set(state);
    this.ref.on('value', (snapshot) => set(snapshot.val()));

    if (isCoordinator) {
      await this.hide(false);
      await this.ref.onDisconnect().remove();
    }
  }

  async hide(val: boolean) {
    await this.ref.update({ hide: val });
  }

  async signalOffBoarding(sessionId: string) {
    await this.offBoardingActionsSignalManager.fire('offboarding', 'before');
    await this.ref.update({ offBoardingSessionId: sessionId });
    await this.offBoardingActionsSignalManager.fire('offboarding', 'after');
  }

  get offBoardingSignal(): Omit<OffBoardingActionsSignalManager, 'fire'> {
    return this.offBoardingActionsSignalManager;
  }

  set ondConversion(val: State['ondConversion']) {
    this.state.ondConversion = val;
  }

  reset(isCoordinator: boolean): void {
    this.ref.off();
    if (isCoordinator) this.ref.remove();
    ValtioUtils.reset(this.state, initialState());
  }
}

type LobbyContext = {
  state: ValtioSnapshottable<State>;
  api: LobbyAPI;
};

const Context = React.createContext<LobbyContext | null>(null);

function useLobbyContext(): LobbyContext {
  const ctx = useContext(Context);
  if (!ctx) {
    throw new Error('LobbyContext is not in the tree!');
  }
  return ctx;
}

export function useLobbyAPI(): LobbyAPI {
  const ctx = useLobbyContext();
  return ctx.api;
}

export function useLobbyState(): State {
  const ctx = useLobbyContext();
  return useSnapshot(ctx.state);
}

export function LobbyProvider(props: {
  ready: boolean;
  svc: FirebaseService;
  children?: ReactNode;
}): JSX.Element {
  const { ready, svc } = props;
  const venueId = useVenueId();
  const state = useInstance(() =>
    markSnapshottable(proxy<State>(initialState()))
  );
  // note: this change was to address https://luna-park.atlassian.net/browse/LP-1776
  // but, this may not be a long term solution. we may actually _want_ the controller to have control of this value.
  // however, currently, the showing/hiding the lobby is the responsibility of the coordinator; it is only used in
  // the host tools, or during ond configuration (for randomization), which happens on the coordinator.
  const isCoordinator = useIsCoordinator();

  const api = useMemo(
    () =>
      new LobbyAPI(
        venueId,
        state,
        svc,
        OffBoardingActionsSignalManager.Create()
      ),
    [state, svc, venueId]
  );

  const { addTask } = useStatsAwareTaskQueue({
    shouldProcess: true,
    stats: 'task-queue-lobby-api-ms',
  });

  useEffect(() => {
    if (!ready) return;
    addTask(async function init() {
      await api.init(isCoordinator);
    });
    return () => {
      addTask(async function reset() {
        api.reset(isCoordinator);
      });
    };
  }, [addTask, api, isCoordinator, ready]);

  useEffect(() => {
    return devtools(state, { name: 'LobbyProvider' });
  }, [state]);

  const ctx: LobbyContext = useMemo(
    () => ({
      state,
      api,
    }),
    [api, state]
  );

  return <Context.Provider value={ctx}>{props.children}</Context.Provider>;
}
