import { SessionMode } from '../../../../../types';
import {
  GameControllerEndRecordingButton,
  GameControllerStartRecordingButton,
  useBlockRecorderVersion,
  useIsGameRecordModeRunning,
  useManageAutoAdvanceRecording,
  useRecordGameSessionStatusChanges,
  useShouldShowEndRecordingButtonForBlock,
  useShouldShowStartRecordingButtonForBlock,
  useStartRecordingBlock,
} from '../../../../GameRecorder/BlockRecorderProvider';
import { useIsStreamSessionAlive } from '../../../../Session';
import {
  useLocalLoadedBlocks,
  useLocalSelectedPlaybackItem,
} from '../../../GamePlayStore';
import { useGameSessionStatus } from '../../../hooks';
import { type EditPointData, end } from '../../../store';
import { BlockKnifeUtils } from '../../Shared';
import { ControllerFactory } from './BlockControllerFactory';
import { StreamRequiredNotice } from './Utilities';

interface LiveGameViewProps {
  selectedBlockIndex: number | null;
  setShowResetConfirmation: (show: boolean) => void;
  setEditPointsData: (data: EditPointData | null) => void;
  setShowOverwriteExistingRecordingConfirmation: (show: boolean) => void;
  setSelectedBlockIndex: (index: number | null) => void;
}

export const LiveGameView = (props: LiveGameViewProps): JSX.Element => {
  const {
    setShowResetConfirmation,
    setEditPointsData,
    setShowOverwriteExistingRecordingConfirmation,
    selectedBlockIndex,
    setSelectedBlockIndex,
  } = props;

  const loadedBlocks = useLocalLoadedBlocks(SessionMode.Live);
  const selectedBlock =
    useLocalSelectedPlaybackItem(selectedBlockIndex, SessionMode.Live)?.block ??
    null;
  const gameSessionStatus = useGameSessionStatus();
  const sessionIsLive = useIsStreamSessionAlive();
  const shouldShowStartRecordingButton =
    useShouldShowStartRecordingButtonForBlock(
      selectedBlock,
      gameSessionStatus,
      sessionIsLive
    );
  const shouldShowEndRecordingButton = useShouldShowEndRecordingButtonForBlock(
    selectedBlock,
    gameSessionStatus,
    sessionIsLive
  );
  useRecordGameSessionStatusChanges(gameSessionStatus);
  const gameRecordingModeIsRunning = useIsGameRecordModeRunning();
  const startRecording = useStartRecordingBlock();
  const recorderVersion = useBlockRecorderVersion();
  useManageAutoAdvanceRecording(selectedBlock);

  const moveToNextBlock = async () => {
    if (selectedBlockIndex !== null && loadedBlocks) {
      const blocksLength = Object.keys(loadedBlocks).length;
      const nextIndex =
        blocksLength - 1 <= selectedBlockIndex ? null : selectedBlockIndex + 1;
      setSelectedBlockIndex(nextIndex);
    }
  };

  const onEndBlock = async () => {
    await end(() => {
      if (gameRecordingModeIsRunning) {
        // do nothing until end button is hit
      } else {
        moveToNextBlock();
      }
    });
  };

  let blockController: JSX.Element | null = null;

  if (loadedBlocks) {
    const blockType = selectedBlock?.type;
    if (blockType) {
      blockController = ControllerFactory({
        selectedBlockIndex,
        selectedBlock,
        setEditPointsData,
        setShowResetConfirmation,
        onEndBlock,
      });

      if (shouldShowStartRecordingButton) {
        blockController = (
          <div className='w-full p-2.5 pt-0 relative h-full flex flex-col-reverse justify-between items-center text-white text-sms overflow-hidden'>
            <GameControllerStartRecordingButton
              gameSessionStatus={gameSessionStatus}
              onClick={() => {
                if (selectedBlock.recording) {
                  setShowOverwriteExistingRecordingConfirmation(true);
                } else {
                  startRecording(selectedBlock, selectedBlock.gameId);
                }
              }}
            />
            <StreamRequiredNotice />
          </div>
        );
      }

      if (shouldShowEndRecordingButton) {
        const blocksLength = loadedBlocks.length;
        const canRecordNext =
          selectedBlockIndex !== null &&
          selectedBlockIndex + 1 < blocksLength &&
          BlockKnifeUtils.IsRecordable(
            loadedBlocks[selectedBlockIndex + 1],
            recorderVersion
          );

        blockController = (
          <div className='w-full p-2.5 pt-0 relative h-full flex flex-col-reverse justify-between items-center text-white text-sms overflow-hidden'>
            <GameControllerEndRecordingButton
              block={selectedBlock}
              canRecordNext={canRecordNext}
              gameSessionStatus={gameSessionStatus}
              onRecordingEnd={() => {
                moveToNextBlock();
              }}
            />
            <StreamRequiredNotice />
          </div>
        );
      }
    }
  }

  return <>{blockController}</>;
};
