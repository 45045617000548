import { type AIChatBot } from '@lp-lib/game';

import logger from '../../../../logger/logger';
import { type TeamId } from '../../../../types';
import { getStaticAssetPath } from '../../../../utils/assets';
import { MediaUtils } from '../../../../utils/media';
import { type FirebaseService, FirebaseValueHandle } from '../../../Firebase';
import { type Messages, type TeamSummary, type TeamSummaryMap } from './types';

export const log = logger.scoped('ai-chat');
const DEFAULT_BOT_AVATAR = getStaticAssetPath(
  'images/ai-chat-bot-avatar-20230619.png'
);

export class AIChatUtils {
  static GetBotName(bot: AIChatBot, fallback = 'AI') {
    return bot.name ?? fallback;
  }

  static GetBotAvatar(bot: AIChatBot, fallback = DEFAULT_BOT_AVATAR): string {
    return MediaUtils.PickMediaUrl(bot.avatarMedia) ?? fallback;
  }

  static GetBotDefaultAvatar(): string {
    return DEFAULT_BOT_AVATAR;
  }
}

export class AIChatFBUtils {
  static Path(
    venueId: string,
    kind: 'root' | 'teams' | 'team-summary'
  ): string {
    if (kind === 'root') return `ai-chat/${venueId}`;
    return `ai-chat/${venueId}/${kind}`;
  }

  static TeamPath(
    teamId: TeamId,
    kind: 'messages' | 'completionCall' | 'summary' | 'player'
  ): string {
    if (kind === 'summary') return `team-summary/${teamId}`;
    return `teams/${teamId}/${kind}`;
  }

  static AbsoluteTeamPath(
    venueId: string,
    teamId: TeamId,
    kind: Parameters<typeof this.TeamPath>[1]
  ): string {
    return `${this.Path(venueId, 'root')}/${this.TeamPath(teamId, kind)}`;
  }

  static RootHandle(
    svc: FirebaseService,
    venueId: string
  ): FirebaseValueHandle<unknown> {
    return new FirebaseValueHandle(
      svc.prefixedSafeRef(this.Path(venueId, 'root'))
    );
  }

  static MessagesHandle(
    svc: FirebaseService,
    venueId: string,
    teamId: string
  ): FirebaseValueHandle<Nullable<Messages>> {
    return new FirebaseValueHandle(
      svc.prefixedSafeRef(this.AbsoluteTeamPath(venueId, teamId, 'messages'))
    );
  }

  static SummaryHandle(
    svc: FirebaseService,
    venueId: string,
    teamId: string
  ): FirebaseValueHandle<Nullable<TeamSummary>> {
    return new FirebaseValueHandle(
      svc.prefixedSafeRef(this.AbsoluteTeamPath(venueId, teamId, 'summary'))
    );
  }

  static TeamSummaryMapHandle(
    svc: FirebaseService,
    venueId: string
  ): FirebaseValueHandle<Nullable<TeamSummaryMap>> {
    return new FirebaseValueHandle(
      svc.prefixedSafeRef(this.Path(venueId, 'team-summary'))
    );
  }
}
