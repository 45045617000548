import { useEffect, useMemo, useState } from 'react';
import { useLatest } from 'react-use';

import { type SpotlightBlock } from '@lp-lib/game';

import { useLiveCallback } from '../../../../hooks/useLiveCallback';
import {
  useParticipantByUserIds,
  useParticipantsByClientIds,
} from '../../../Player';
import { useTeamMembers } from '../../../TeamAPI/TeamV1';
import { useGameHostingCoordinator } from '../../GameHostingProvider';
import { useGameSessionPreconfigVIPUserIds } from '../Common/GamePlay/GameSessionPreconfigProvider';
import { useSelectTeamByScore } from './hooks';
import { SpotlightPreSelectedTeamOrder } from './types';

export class SpotlightBlockUtils {
  static FormatSpotlightMessage(template: string, names: string[]): string {
    if (names.length === 0) {
      return template;
    }

    if (names.length === 1) {
      return template.replace(/%Name%/g, names[0]);
    }

    const str = `${names.slice(0, -1).join(', ')} and ${
      names[names.length - 1]
    }`;
    return template.replace(/%Name%/g, str);
  }
  static GetStreamViewSize(numOfPlayers: number): string {
    return numOfPlayers === 1
      ? 'w-full h-full max-w-200'
      : numOfPlayers === 2
      ? 'w-80 h-80'
      : numOfPlayers === 3
      ? 'w-56 h-56'
      : 'w-42 h-42';
  }
  static FormatPreselectedTeamOrder(order: number): string {
    switch (order) {
      case SpotlightPreSelectedTeamOrder.Coordinator:
        return 'Coordinator';
      case SpotlightPreSelectedTeamOrder.SystemSelected:
        return 'Optimized Selection';
      case SpotlightPreSelectedTeamOrder.FirstPlaceTeam:
        return 'First Place Team';
      case SpotlightPreSelectedTeamOrder.SecondPlaceTeam:
        return 'Second Place Team';
      case SpotlightPreSelectedTeamOrder.ThirdPlaceTeam:
        return 'Third Place Team';
      case SpotlightPreSelectedTeamOrder.LastPlaceTeam:
        return 'Last Place Team';
      default:
        return 'None';
    }
  }
}

// note: used by live1 host controller. see SpotlightBlockProvider, for ond.
export const usePreselectClientIds = (block: SpotlightBlock): string[] => {
  const preConfiguredClientIds = usePreselectedClientIdsByPreConfig(block);
  const blockConfiguredClientIds = usePreselectedClientIdsByBlockConfig(block);

  const ref = useLatest(
    preConfiguredClientIds.length > 0
      ? preConfiguredClientIds
      : blockConfiguredClientIds
  );

  const [res, setRes] = useState(ref.current);
  useEffect(() => {
    setRes(ref.current);
  }, [block.id, ref]);

  return res;
};

const usePreselectedClientIdsByPreConfig = (
  _block: SpotlightBlock
): string[] => {
  const vipUserIds = useGameSessionPreconfigVIPUserIds();
  const preselectedClientIds = useParticipantByUserIds(vipUserIds, true).map(
    (p) => p.clientId
  );
  return preselectedClientIds;
};

// we assume the team order should be in the range of [-200, -1] (backward) or
// [1, 200] (forward). The value out of the range will be treated as special
// meaning and it should be handled separately.
function isValidTeamOrder(order: number, min = 1, max = 200) {
  return Math.abs(order) >= min && Math.abs(order) <= max;
}

export function usePreselectedTeamGetter() {
  const teams = useSelectTeamByScore();
  return useLiveCallback((order: SpotlightPreSelectedTeamOrder) => {
    return !isValidTeamOrder(order) ||
      order > teams.length ||
      -order > teams.length
      ? null
      : order < 0
      ? teams[teams.length + order]
      : teams[order - 1];
  });
}

function usePreselectClientIdsByTeamScore(
  order: SpotlightPreSelectedTeamOrder
) {
  const teams = useSelectTeamByScore();
  const preSelectedTeam =
    !isValidTeamOrder(order) || order > teams.length || -order > teams.length
      ? null
      : order < 0
      ? teams[teams.length + order]
      : teams[order - 1];
  const members = useTeamMembers(preSelectedTeam?.id);

  const preselectedClientIds = useParticipantsByClientIds(
    members?.map((m) => m.id) ?? []
  )
    .sort((a, b) => a.username.localeCompare(b.username))
    .map((p) => p.clientId);

  return preselectedClientIds;
}

function usePreselectedClientIdsBySpecialOrder(
  order: SpotlightPreSelectedTeamOrder
) {
  const coordinatorClientId = useGameHostingCoordinator()?.clientId;
  return useMemo(() => {
    switch (order) {
      case SpotlightPreSelectedTeamOrder.Coordinator:
        return coordinatorClientId ? [coordinatorClientId] : [];
      case SpotlightPreSelectedTeamOrder.SystemSelected:
        // note: for live1, we preselect nothing.
        return [];
      default:
        return [];
    }
  }, [coordinatorClientId, order]);
}

const usePreselectedClientIdsByBlockConfig = (
  block: SpotlightBlock
): string[] => {
  const list1 = usePreselectClientIdsByTeamScore(
    block.fields.preselectedTeamOrder
  );
  const list2 = usePreselectedClientIdsBySpecialOrder(
    block.fields.preselectedTeamOrder
  );
  return list1.length > 0 ? list1 : list2;
};
