/* eslint-disable @lp-lib/eslint-rules/encapsulated-redux */
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { shallowEqual } from 'react-redux';
import { usePrevious } from 'react-use';

import { RTDBServerValueTIMESTAMP } from '@lp-lib/firebase-typesafe';

import { useLiveCallback } from '../../hooks/useLiveCallback';
import { useIsController, useMyInstance } from '../../hooks/useMyInstance';
import { useRSHook } from '../../hooks/useRSHook';
import { useReceivedVenueMode } from '../../hooks/useVenueMode';
import logger from '../../logger/logger';
import { getReduxRootState } from '../../store/configureStore';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { type RootState } from '../../store/types';
import { FBPathUtils } from '../../store/utils';
import {
  type AwayMessage,
  ClientTypeUtils,
  isStaff,
  type MemberId,
  type Participant,
  type ParticipantMap,
  type Team,
  type TeamId,
  type TeamMember,
  type TeamV0,
  VenueMode,
} from '../../types';
import { type TownhallMode } from '../../types/townhall';
import { err2s, randomPick } from '../../utils/common';
import { type EmitterListener } from '../../utils/emitter';
import {
  filterTeams,
  resolveTeamFilterOptions,
  type TeamFilterOptions,
} from '../../utils/filterTeams';
import { isEqualSets } from '../../utils/set-utils';
import {
  ConfirmCancelModalHeading,
  useAwaitFullScreenConfirmCancelModal,
} from '../ConfirmCancelModalContext';
import { type FirebaseService, useFirebaseStatus } from '../Firebase';
import { type FirebaseEvents, FirebaseStatus } from '../Firebase/types';
import {
  useAmICohost,
  useCohosts,
  useCohostsGetter,
  useMyInstanceGetter,
  useParticipantByClientId,
  useParticipants,
  useParticipantsAsArray,
  useParticipantsAsArrayGetter,
  useParticipantsGetter,
  useParticipantSkim,
  useSyncParticipants,
  useUpdateParticipant,
} from '../Player';
// eslint-disable-next-line no-restricted-imports
import { isActiveParticipant } from '../Player/participantSlice';
import {
  useTownhallConfig,
  useTownhallEnabled,
  useTownhallShowTeam,
} from '../Townhall';
import {
  useMyClientId,
  useMyClientType,
} from '../Venue/VenuePlaygroundProvider';
import { useVenueDerivedSettings, useVenueId } from '../Venue/VenueProvider';
// eslint-disable-next-line no-restricted-imports
import {
  assignNewTeamCaptainScribe,
  createTeam,
  type CreateTeamPayload,
  cycleTeamCaptainScribes,
  filterActiveMembers,
  init,
  joinTeam,
  type JoinTeamPayload,
  leaveTeam,
  renameTeam,
  selectActiveTeamMembers,
  selectIsTeamRecovered,
  selectIsTeamRecoveryRunning,
  selectIsTeamStoreInited,
  selectShowTeamIsFullModel,
  selectTeamCaptainClientId,
  selectTeamCaptainScribe,
  selectTeamMember,
  selectTeamMembers,
  setMaxTeamMembers,
  setShowTeamIsFullModel,
  switchTeamTownhallMode,
  TeamStoreUtils,
  tryTeamRecovery,
} from './teamSlice';

const log = logger.scoped('team-v1');

export function useInitTeamStore() {
  const dispatch = useAppDispatch();
  return useLiveCallback((...args: Parameters<typeof init>) => {
    return dispatch(init(...args));
  });
}

export function EnsureTeamCaptainForTeams() {
  const teams = useTeams({ active: true, excludeStaffTeam: true });
  const participants = useParticipants();
  const dispatch = useAppDispatch();
  const running = useRef(false);
  const teamStoreInited = useIsTeamStoreInited();

  useEffect(() => {
    const exec = async () => {
      if (running.current || !teamStoreInited) return;
      running.current = true;
      for (let i = 0; i < teams.length; i++) {
        const team = teams[i];
        if (!team.captainScribe) {
          await dispatch(assignNewTeamCaptainScribe(team.id));
          continue;
        } else {
          const p = participants[team.captainScribe];
          if (!p || !isActiveParticipant(p) || p.teamId !== team.id) {
            await dispatch(assignNewTeamCaptainScribe(team.id));
            continue;
          }
        }
      }
      running.current = false;
    };

    exec().catch((err) => log.error('failed to ensure team captain(s)', err));
  }, [dispatch, participants, teamStoreInited, teams]);

  return null;
}

export function TeamOpsProvider(props: { children?: React.ReactNode }) {
  const isController = useIsController();

  return (
    <>
      {isController ? <EnsureTeamCaptainForTeams /> : null}
      {props.children}
    </>
  );
}

export function useIsParticipantJoinLocked(clientId: Participant['clientId']) {
  return useAppSelector((state) => !!state.team.joinLock[clientId]);
}

const makeTeamV0 = (
  participants: ParticipantMap,
  team: Nullable<Team>,
  teamMembers: Nullable<Record<MemberId, TeamMember>>,
  maxTeamMembers: number,
  staff: Participant['clientId'][] | undefined,
  cohosts: Participant['clientId'][] | undefined
) => {
  if (!team) return null;
  if (!teamMembers) return null;

  const activeMembers = filterActiveMembers(team.id, teamMembers, participants);

  let isStaffTeam = false;
  let teamName = team.name;

  if (staff !== undefined) {
    const staffClientIds = new Set([...staff, ...(cohosts ?? [])]);
    const staffMembers = activeMembers.filter((m) => staffClientIds.has(m.id));
    // NOTE: this in general is problematic to dervive based on the active
    // members. If a staff member temporarily disconnects, the team is no longer
    // a staff team. This could make the team eligible for randomization or
    // other game actions during that interim period.
    isStaffTeam =
      staffMembers.length === activeMembers.length && staffMembers.length > 0;

    if (isStaffTeam && !team.isCohostTeam)
      teamName = 'Luna Park Control Center';
  }

  const teamVO: TeamV0 = {
    ...team,
    membersCount: activeMembers.length,
    isFull: activeMembers.length >= maxTeamMembers,
    isStaffTeam,
    name: teamName,
  };
  return teamVO;
};

const makeTeamV0sArray = (
  state: RootState,
  participants: ParticipantMap,
  staff: Participant['clientId'][] | undefined,
  cohost: Participant['clientId'][] | undefined
): TeamV0[] => {
  const teams = Object.values(state.team.teams);

  const out = [];

  for (let i = 0; i < teams.length; i++) {
    const team = teams[i];
    if (!team) continue;
    const members = state.team.teamMembers[team.id];
    if (!members) continue;
    const v0 = makeTeamV0(
      participants,
      team,
      members,
      state.team.maxTeamMembers,
      staff,
      cohost
    );
    if (v0) out.push(v0);
  }

  return out;
};

const makeTeamMemberMap = (
  state: RootState,
  teamIds: Team['id'][],
  participants: ParticipantMap
): Record<Team['id'], TeamMember[]> => {
  const out: Record<Team['id'], TeamMember[]> = {};

  for (let i = 0; i < teamIds.length; i++) {
    const teamId = teamIds[i];
    if (!teamId) continue;

    const actives = selectActiveTeamMembers(state.team, teamId, participants);
    if (actives) out[teamId] = actives;
  }

  return out;
};

export function useTeamMembersGetter() {
  const getParticipants = useParticipantsGetter();
  return useLiveCallback((teamId: Team['id']) => {
    const state = getReduxRootState();
    const participants = getParticipants();
    return selectActiveTeamMembers(state.team, teamId, participants);
  });
}

export function useTeam(
  teamId: Nullable<Team['id']>,
  staff?: Participant['clientId'][],
  cohosts?: Participant['clientId'][]
) {
  const { miss } = useRSHook('use-team');
  const participants = useParticipants();
  const prev = useRef<TeamV0 | null>(null);
  const team = useAppSelector((state) =>
    teamId ? state.team.teams[teamId] : null
  );
  const members = useAppSelector((state) =>
    teamId ? state.team.teamMembers[teamId] : null
  );
  const maxTeamMembers = useAppSelector((state) => state.team.maxTeamMembers);

  const result = team
    ? makeTeamV0(participants, team, members, maxTeamMembers, staff, cohosts)
    : null;

  if (!shallowEqual(result, prev.current)) {
    miss();
    prev.current = result;
  }

  return prev.current;
}

function compareTeamV0s(a: TeamV0[], b: TeamV0[]) {
  if (a.length !== b.length) return false;
  for (let i = 0; i < a.length; i++) {
    const aTeam = a[i];
    const bTeam = b[i];
    if (!shallowEqual(aTeam, bTeam)) return false;
  }
  return true;
}

export function useTeams(options?: TeamFilterOptions) {
  const { miss } = useRSHook('use-teams');

  // These are destructured in order to have a stable useMemo reference.
  const {
    active,
    sort,
    pinTeamId,
    pinFullTeamsToBottom,
    updateStaffTeam,
    excludeStaffTeam,
    excludeCohostTeam,
  } = resolveTeamFilterOptions(options);

  const participants = useParticipants();

  const staffArr = useParticipantsAsArray({
    filters: ['host:false', 'cohost:false', 'staff:true', 'status:connected'],
  }).map((p) => p.clientId);
  const cohostArr = useCohosts().map((p) => p.clientId);

  const staff = updateStaffTeam ? staffArr : undefined;
  const cohost = updateStaffTeam ? cohostArr : undefined;

  const v0s = useAppSelector(
    (state) => makeTeamV0sArray(state, participants, staff, cohost),
    { equalityFn: compareTeamV0s }
  );

  return useMemo(() => {
    miss();
    const filtered = filterTeams(v0s, {
      active,
      sort,
      pinTeamId,
      pinFullTeamsToBottom,
      excludeStaffTeam,
      excludeCohostTeam,
    });
    return filtered;
  }, [
    active,
    excludeCohostTeam,
    excludeStaffTeam,
    miss,
    pinFullTeamsToBottom,
    pinTeamId,
    sort,
    v0s,
  ]);
}

export function useTeamsGetter() {
  const getParticipants = useParticipantsGetter();
  const getParticipantsAsArray = useParticipantsAsArrayGetter();
  const getCohosts = useCohostsGetter();
  return useLiveCallback((options?: TeamFilterOptions) => {
    const opts = resolveTeamFilterOptions(options);
    const root = getReduxRootState();
    const participants = getParticipants();
    const staffArr = getParticipantsAsArray({
      filters: ['host:false', 'cohost:false', 'staff:true', 'status:connected'],
    }).map((p) => p.clientId);
    const cohostArr = getCohosts().map((p) => p.clientId);
    const staff = opts.updateStaffTeam ? staffArr : undefined;
    const cohost = opts.updateStaffTeam ? cohostArr : undefined;
    const v0s = makeTeamV0sArray(root, participants, staff, cohost);
    const filtered = filterTeams(v0s, opts);
    return filtered;
  });
}

export function useTeamGetter() {
  const teams = useAppSelector((state) => state.team.teams);
  return useLiveCallback((teamId: Nullable<Team['id']>) => {
    return teamId ? teams[teamId] : null;
  });
}

export function useTeamCaptainLookup() {
  const teams = useAppSelector((state) => state.team.teams);
  return useLiveCallback((teamId: Nullable<Team['id']>) => {
    return teamId ? teams[teamId]?.captainScribe ?? null : null;
  });
}

export function useTeamMembers(
  teamId: Nullable<Team['id']>,
  sort = true,
  pinMemberId?: string
) {
  const { miss } = useRSHook('use-team-members');
  const prev = useRef<null | TeamMember[]>(null);
  const participants = useParticipants();
  const result = useAppSelector((state) => {
    const members = selectActiveTeamMembers(state.team, teamId, participants);
    if (sort) members?.sort((a, b) => a.joinedAt - b.joinedAt);
    if (pinMemberId) {
      const pinnedIdx = members?.findIndex((m) => m.id === pinMemberId) ?? -1;
      if (pinnedIdx > -1) {
        const pinned = members?.splice(pinnedIdx, 1);
        if (pinned?.length) members?.unshift(pinned[0]);
      }
    }
    return members;
  }, shallowEqual);

  if (result !== prev.current) {
    // NOTE: not react concurrent safe, fix in the future.
    miss();
    prev.current = result;
  }

  return result;
}

export function useTeamMembersByTeamIds(teamIds: Team['id'][]) {
  const { miss } = useRSHook('use-team-members-by-team-ids');
  const prev = useRef<null | Record<string, TeamMember[]>>(null);
  const participants = useParticipants();
  const result = useAppSelector(
    (state) => makeTeamMemberMap(state, teamIds, participants),
    (a, b) => {
      const keysA = new Set(Object.keys(a));
      const keysB = new Set(Object.keys(b));
      if (!isEqualSets(keysA, keysB)) return false;
      for (const k of keysA) {
        if (!shallowEqual(a[k], b[k])) return false;
      }

      return true;
    }
  );

  if (result !== prev.current) {
    // NOTE: not react concurrent safe, fix in the future.
    miss();
    prev.current = result;
  }

  return result;
}

export function useConnectedTeamMembers(teamId: Team['id']) {
  const teamMembers = useAppSelector((state) =>
    selectTeamMembers(state.team, teamId)
  );
  const participants = useParticipants();
  return useMemo(
    () => filterActiveMembers(teamId, teamMembers, participants),
    [participants, teamId, teamMembers]
  );
}

export function useTeamMember(
  teamId: Team['id'],
  clientId: Participant['clientId']
) {
  return useAppSelector((state) =>
    selectTeamMember(state.team, teamId, clientId)
  );
}

export function useSwitchTeamTownhallMode(): (
  teamId: string,
  mode: TownhallMode
) => void {
  const dispatch = useAppDispatch();
  return useCallback(
    (teamId: string, mode: TownhallMode) => {
      dispatch(switchTeamTownhallMode(teamId, mode));
    },
    [dispatch]
  );
}

export const useTeamTownhallMode = (
  id?: TeamId | null
): TownhallMode | undefined => {
  const team = useTeam(id);
  return team?.townhallMode;
};

export function useTeamIsFullModal() {
  const showing = useAppSelector(selectShowTeamIsFullModel);
  const dispatch = useAppDispatch();
  const hide = useLiveCallback(() => dispatch(setShowTeamIsFullModel(false)));
  return [showing, hide] as const;
}

export function useSetMaxTeamMembers() {
  const dispatch = useAppDispatch();
  return useLiveCallback((max: number) => dispatch(setMaxTeamMembers(max)));
}

export function useAssignNewTeamCaptain() {
  const dispatch = useAppDispatch();
  return useLiveCallback(
    (teamId: Team['id'], clientId?: Participant['clientId']) =>
      dispatch(assignNewTeamCaptainScribe(teamId, clientId))
  );
}

export function useCycleTeamCaptains() {
  const dispatch = useAppDispatch();
  return useLiveCallback(() => dispatch(cycleTeamCaptainScribes()));
}

export function useIsTeamStoreInited() {
  return useAppSelector(selectIsTeamStoreInited);
}

export function useIsTeamRecovered() {
  return useAppSelector(selectIsTeamRecovered);
}

export function useIsTeamRecoveryRunning() {
  return useAppSelector(selectIsTeamRecoveryRunning);
}

export function useTeamCaptainUserId(teamId?: Team['id']) {
  const clientId = useAppSelector((state) =>
    selectTeamCaptainClientId(state.team, teamId)
  );
  return useParticipantSkim(clientId, 'id');
}

export function useCreateTeam() {
  const dispatch = useAppDispatch();
  const username = useParticipantSkim(useMyClientId(), 'username');
  const updateParticipant = useUpdateParticipant();

  return useCallback(
    async (payload?: Omit<CreateTeamPayload, 'updateParticipant'>) => {
      return dispatch(
        createTeam({
          ...payload,
          username: username ?? '',
          cohost: payload?.cohost,
          updateParticipant,
        })
      );
    },
    [dispatch, username, updateParticipant]
  );
}

export function useJoinTeamExperience(optimalTeamSize = 4) {
  const activeTeamCount = useTeamsCount();
  const getOptimalTeamId = useOptimalTeamIdToManuallyJoin();
  const createTeam = useCreateTeam();
  const joinTeam = useJoinTeam();
  const memberId = useMyClientId();
  return useLiveCallback(async (forceOneTeam?: boolean) => {
    // 9999 is a magic number to force the user to join the existing team
    // regardless of the size
    const targetTeamId = getOptimalTeamId(
      forceOneTeam ? 9999 : optimalTeamSize
    );
    if (!targetTeamId) {
      log.warn(
        'No optimal team found when joining experience, creating a new team',
        {
          activeTeamCount,
        }
      );
      await createTeam({
        memberId,
        cohost: false,
        debug: 'join-team-experience',
      });
      return;
    }

    await joinTeam({
      memberId,
      teamId: targetTeamId,
      // Override maxTeamSize so that limits are ignored for this specific join.
      // We never want the user to be alone, and that means allowing teams
      // larger than the limit.
      maxTeamSize: -1,
      debug: 'join-team-experience',
    });
  });
}

/**
 * NOTE: does not attempt to find optimal team for staff members.
 */
function useOptimalTeamIdToManuallyJoin() {
  const getMe = useMyInstanceGetter();
  const getTeams = useTeamsGetter();
  const clientType = useMyClientType();
  const preferSinglePlayerTeams =
    useVenueDerivedSettings()?.preferSinglePlayerTeams;

  return useLiveCallback((optimalTeamSize: number) => {
    const me = getMe();
    const meIsStaff = isStaff(me);
    const isHost = ClientTypeUtils.isHost(clientType);
    const teams = getTeams({ updateStaffTeam: true });

    // Always create a new team in case we always want 1 player teams.
    if (isHost || meIsStaff || preferSinglePlayerTeams) return null;

    // Allow a team to be over the limit as a fallback: only used if a more
    // ideal team is not found first.

    const stageOneEligibleLimit = optimalTeamSize;
    const stageTwoEligibleLimit = optimalTeamSize + 2;

    const stageOneEligible = new Set<TeamV0['id']>();
    const stageTwoEligible = new Set<TeamV0['id']>();
    let fewest;

    for (const t of teams) {
      if (t.isStaffTeam) continue;

      if (t.membersCount < stageOneEligibleLimit) {
        stageOneEligible.add(t.id);
        if (!fewest || t.membersCount < fewest.membersCount) {
          // Update the ideal team to join
          fewest = t;
        }
      }
      if (t.membersCount < stageTwoEligibleLimit) {
        stageTwoEligible.add(t.id);
      }
    }

    if (fewest) {
      // Hopefully we found the most ideal team with the fewest current members
      return fewest.id;
    } else if (stageOneEligible.size > 0) {
      // But if not (e.g. multiple teams have the same underfilled size), pick a
      // random eligible team
      return randomPick([...stageOneEligible]);
    } else if (stageTwoEligible.size > 0) {
      // We allow a team to be N over the limit in an effort to prevent users
      // from being alone
      return randomPick([...stageTwoEligible]);
    } else {
      // If there are no eligible teams (they're all full?) then we do not pick
      // a team and assume a new team must be created
      return null;
    }
  });
}

// This is a separate hook to avoid unintentional effects from re-running due to
// a dependency on `teams`.
function useOptimalTeamIdToAutoJoin(optimalTeamSize: number) {
  const me = useMyInstance();
  const meIsStaff = isStaff(me);
  const isHost = ClientTypeUtils.isHost(useMyClientType());
  const isCohost = useAmICohost();
  const teams = useTeams({ updateStaffTeam: true });

  if (isHost || isCohost) return null;

  const next = new Set<TeamV0['id']>();

  for (const t of teams) {
    if (meIsStaff) {
      if (t.isStaffTeam) next.add(t.id);
    } else {
      if (!t.isStaffTeam && t.membersCount < optimalTeamSize) {
        next.add(t.id);
      }
    }
  }

  return randomPick([...next]);
}

function useDefaultAutoJoinTeam(optimalTeamSize = 4): void {
  const me = useMyInstance();
  const isHost = ClientTypeUtils.isHost(useMyClientType());
  const isTeamStoreInited = useIsTeamStoreInited();
  const isTeamRecovered = useIsTeamRecovered();

  const createTeam = useCreateTeam();
  const joinTeam = useJoinTeam();
  const venueMode = useReceivedVenueMode();
  const targetTeamIdToAutoJoin = useOptimalTeamIdToAutoJoin(optimalTeamSize);
  const updateParticipant = useUpdateParticipant();

  const isLobbyMode = venueMode === VenueMode.Lobby;
  const attempted = useRef(isHost);
  useEffect(() => {
    if (attempted.current) return;

    if (!me || !isTeamStoreInited || !isTeamRecovered || venueMode === null)
      return;
    attempted.current = true;

    if (me.teamId || !isLobbyMode) return;

    if (targetTeamIdToAutoJoin) {
      joinTeam({
        memberId: me.clientId,
        teamId: targetTeamIdToAutoJoin,
        debug: 'default-auto-join-team',
      });
    } else {
      createTeam({
        memberId: me.clientId,
        username: me.username,
        cohost: me.cohost,
        debug: 'default-auto-create-team',
      });
    }
  }, [
    createTeam,
    isLobbyMode,
    isTeamRecovered,
    isTeamStoreInited,
    joinTeam,
    me,
    targetTeamIdToAutoJoin,
    updateParticipant,
    venueMode,
  ]);
}

export function useAutoJoinTeam(): void {
  useDefaultAutoJoinTeam();
}

export function useIsJoinTeamLocked(): boolean {
  const myClientId = useMyClientId();
  return useIsParticipantJoinLocked(myClientId);
}

export function useJoinTeam(): (
  payload: Omit<JoinTeamPayload, 'updateParticipant'>
) => Promise<void> {
  const dispatch = useAppDispatch();
  const updateParticipant = useUpdateParticipant();
  return useLiveCallback(
    (payload: Omit<JoinTeamPayload, 'updateParticipant'>) =>
      dispatch(joinTeam({ ...payload, updateParticipant }))
  );
}

export function useLeaveTeam(): (debug?: string) => void {
  const me = useMyInstance();
  const teamId = me?.teamId;
  const clientId = me?.clientId;
  const dispatch = useAppDispatch();
  return useCallback(
    (debug?: string) => {
      if (!teamId || !clientId) return;
      dispatch(leaveTeam({ teamId, memberId: clientId, debug }));
    },
    [dispatch, clientId, teamId]
  );
}

export function useMarkAsAway() {
  const dispatch = useAppDispatch();
  return useCallback(
    async (
      clientId: string,
      teamId: string,
      actor: { reason: 'team' | 'host'; clientId: string }
    ) => {
      const markAsAway: AwayMessage = {
        teamId,
        reason: actor.reason,
        byClientId: actor.clientId,
        timestamp: RTDBServerValueTIMESTAMP,
      };
      await dispatch(
        leaveTeam({
          teamId,
          memberId: clientId,
          markAsAway,
          debug: `player-marked-as-away-${actor.reason}`,
        })
      );
      log.info('marked client as away', { clientId, teamId, actor });
    },
    [dispatch]
  );
}

export function useRemoveFromVenue() {
  const dispatch = useAppDispatch();
  return useCallback(
    async (
      clientId: string,
      teamId: string,
      byClientId: string,
      options?: Pick<AwayMessage, 'message' | 'redirectTo'>
    ) => {
      const markAsAway: AwayMessage = {
        teamId,
        reason: 'coordinator',
        byClientId,
        timestamp: RTDBServerValueTIMESTAMP,
        ...options,
      };
      await dispatch(
        leaveTeam({
          teamId,
          memberId: clientId,
          markAsAway,
          debug: `removed-from-venue-by-coordinator`,
        })
      );
      log.info('removed client from venue', {
        clientId,
        teamId,
        byClientId,
        options,
      });
    },
    [dispatch]
  );
}

export function useTeamRecovery(): void {
  const me = useMyInstance();
  const teamId = me?.teamId;
  const hasStaffFlag = isStaff(me);
  const amICohost = useAmICohost();
  const clientId = useMyClientId();
  const dispatch = useAppDispatch();
  const firebaseStatus = useFirebaseStatus();
  const prevFirebaseStatus = usePrevious(firebaseStatus);
  const initialRecoveryTriggeredRef = useRef(false);
  const isTeamRecoveryRunning = useIsTeamRecoveryRunning();
  const isTeamStoreInited = useIsTeamStoreInited();
  const syncParticipants = useSyncParticipants();
  const updateParticipant = useUpdateParticipant();

  // Onetime recovery after opening the page
  useEffect(() => {
    if (!isTeamStoreInited || initialRecoveryTriggeredRef.current) return;
    initialRecoveryTriggeredRef.current = true;
    if (teamId) return;
    dispatch(
      tryTeamRecovery(
        clientId,
        hasStaffFlag || amICohost,
        syncParticipants,
        updateParticipant
      )
    );
  }, [
    clientId,
    dispatch,
    hasStaffFlag,
    amICohost,
    isTeamStoreInited,
    syncParticipants,
    teamId,
    updateParticipant,
  ]);

  useEffect(() => {
    return () => {
      initialRecoveryTriggeredRef.current = false;
    };
  }, []);

  // Note(jialin): Repeated recovery when firebase connection status changed
  // If Firebase disconnected, the cloud function removeTeamMemberWhenParticipantLeft
  // would delete the teamMember, but keep the teamId on participant object.
  // We intentionally don't check the participant.teamId here.
  useEffect(() => {
    if (
      !initialRecoveryTriggeredRef.current ||
      isTeamRecoveryRunning ||
      !isTeamStoreInited
    )
      return;
    if (
      prevFirebaseStatus !== FirebaseStatus.Connected &&
      firebaseStatus === FirebaseStatus.Connected
    ) {
      dispatch(
        tryTeamRecovery(
          clientId,
          hasStaffFlag || amICohost,
          syncParticipants,
          updateParticipant
        )
      );
    }
  }, [
    dispatch,
    firebaseStatus,
    clientId,
    prevFirebaseStatus,
    teamId,
    isTeamRecoveryRunning,
    isTeamStoreInited,
    syncParticipants,
    hasStaffFlag,
    updateParticipant,
    amICohost,
  ]);
}

export function useTeamColor(teamId?: string | null): string | undefined {
  const config = useTownhallConfig();
  const showTeam = useTownhallShowTeam();
  const team = useTeam(teamId);

  if (!config.enabled || !showTeam) return undefined;
  return team?.color;
}

export function useLeaveTeamWithConfirm(): (debug?: string) => Promise<void> {
  const confirmCancel = useAwaitFullScreenConfirmCancelModal();
  const leaveTeam = useLeaveTeam();
  return useCallback(
    async (debug?: string) => {
      const response = await confirmCancel({
        kind: 'confirm-cancel',
        prompt: (
          <ConfirmCancelModalHeading>
            Are you sure you want to leave the Venue?
          </ConfirmCancelModalHeading>
        ),
        confirmBtnLabel: 'Leave',
        cancelBtnLabel: 'Cancel',
        autoFocus: 'confirm',
      });

      if (response.result === 'confirmed') {
        leaveTeam(debug);
        setTimeout(() => {
          window.location.href = '/home';
        }, 200);
      }
    },
    [confirmCancel, leaveTeam]
  );
}

// TODO(jialin): This finally should be independent to the Townhall
export function useIsTeamsOnTop(): boolean {
  const townhallEnabled = useTownhallEnabled();
  const isHost = ClientTypeUtils.isHost(useMyClientType());
  if (isHost) return false;
  return townhallEnabled;
}

export function useRenameTeam() {
  const dispatch = useAppDispatch();
  return useCallback(
    (teamId: string, newName: string) => {
      dispatch(renameTeam(teamId, newName));
    },
    [dispatch]
  );
}

export function useTeamWithStaff(teamId?: TeamId | null): TeamV0 | null {
  const staffArr = useParticipantsAsArray({
    filters: ['host:false', 'cohost:false', 'staff:true', 'status:connected'],
  });
  const cohosts = useCohosts();
  return useTeam(
    teamId,
    staffArr.map((p) => p.clientId),
    cohosts.map((p) => p.clientId)
  );
}

export const useTeamRaw = (id?: TeamId | null) => {
  const team = useAppSelector((state) => (id ? state.team.teams[id] : null));
  return team;
};

export const useConnectedTeamMemberCount = (id?: TeamId | null) => {
  const participants = useParticipantsAsArray({
    filters: ['team:true', 'status:connected'],
  });
  let count = 0;
  for (const p of participants) {
    if (p && p.teamId === id) count++;
  }
  return count;
};

export const useConnectedTeamMemberCountsGetter = () => {
  const getParticipants = useParticipantsAsArrayGetter();
  return useLiveCallback(() => {
    const participants = getParticipants({
      filters: ['team:true', 'status:connected'],
    });
    const lookup = new Map<string, number>();
    for (const p of participants) {
      if (p && p.teamId) {
        const count = lookup.get(p.teamId) ?? 0;
        lookup.set(p.teamId, count + 1);
      }
    }
    return lookup;
  });
};

export const useTeamsCount = () => {
  const participants = useParticipantsAsArray({
    filters: [
      'team:true',
      'status:connected',
      'host:false',
      'cohost:false',
      'staff:false',
    ],
  });

  const counts = new Set<string>();

  for (const p of participants) {
    if (p && p.teamId) {
      counts.add(p.teamId);
    }
  }

  return counts.size;
};

export const useTeamCaptainScribe = (
  teamId: TeamId | null | undefined,
  // only used when you want to get the scribe regardless his active state
  includeInactive?: boolean
): TeamMember | null => {
  const participants = useParticipants();
  return useAppSelector((state) =>
    selectTeamCaptainScribe(state, participants, teamId, includeInactive)
  );
};

export const useIsTeamCaptainScribe = (
  teamId: TeamId | null | undefined,
  memberId: MemberId | null | undefined
): boolean => {
  const participants = useParticipants();
  return useAppSelector(
    (state) =>
      !!memberId &&
      selectTeamCaptainScribe(state, participants, teamId)?.id === memberId
  );
};

export function useTeamCaptainParticipant(
  teamId: TeamId | null | undefined
): Participant | null {
  const captainMember = useTeamCaptainScribe(teamId);
  return useParticipantByClientId(captainMember?.id);
}

// TODO: When showing a list of team answers, do not use this function as it
// will iterate over every answer per team. Instead make a new function that
// creates an aggregate.

// export const useTeamCaptainScribeAnswerData = (
//   teamId: TeamId | null | undefined,
//   blockId: string | null | undefined
// ): GameSessionQuestionBlock | null => {
//   const playerDataList = useAppSelector((state) =>
//     blockId ? state.gameSession.current.data?.[blockId] ?? null : null
//   );

//   if (!playerDataList) return null;

//   for (const [, pdata] of Object.entries(playerDataList)) {
//     if (pdata.teamId === teamId && pdata.isTeamCaptainScribeAtSubmissionTime)
//       return pdata;
//   }

//   return null;
// };

export function useEnsureTeamMemberRemovedWhenDisconnected(
  svc: FirebaseService,
  emitter: EmitterListener<FirebaseEvents>
): void {
  const venueId = useVenueId();
  const me = useMyInstance();
  const teamId = me?.teamId;
  const clientId = me?.clientId;
  useEffect(() => {
    if (!teamId || !clientId) return;
    const member = {
      teamId: teamId,
      memberId: clientId,
    };
    const path = FBPathUtils.ForTeamMembers(svc, venueId, {
      ...member,
    });
    const ref = svc.ref(path);
    function registerOnDisconnectRemove() {
      ref.onDisconnect().remove((err) => {
        if (err) {
          log.error('register onDisconnect.remove error', err2s(err), {
            ...member,
          });
        } else {
          log.info('register onDisconnect.remove successful', {
            ...member,
          });
        }
      });
    }
    registerOnDisconnectRemove();
    // We keep the pariticpant.teamId even if Firebase gets disconnected and reconnected.
    // Once the disconnect happened, the above onDisconnect will be tirggered once,
    // after reconnected, we need to register the hook again.
    const off = emitter.on('connection-state-changed', (connected) => {
      if (connected) registerOnDisconnectRemove();
    });
    return () => {
      off();
      ref.onDisconnect().cancel((err) => {
        if (err) {
          log.error('register onDisconnect.cancel error', err2s(err), {
            ...member,
          });
        } else {
          log.info('register onDisconnect.cancel successful', { ...member });
        }
      });
    };
  }, [clientId, emitter, svc, teamId, venueId]);
}

/**
 * The team randomizer helps users join the target team on behalf of the
 * controller, this hook enures the last team change gets written into the
 * local storage. So it can be used in auto recovery after refreshing the page.
 *
 */
export function useEnsureUpdateTeamInfoInLocalStorage(): void {
  const me = useMyInstance();
  useEffect(() => {
    if (!me?.teamId) return;
    // TODO(drew): this should use getStore() + useAppSelector, instead of
    // relying on TeamStoreUtils, which are not reactive.
    const store = TeamStoreUtils.Get();
    store.localTeamStorage.joinTeam({
      teamId: me.teamId,
      memberId: me.clientId,
    });
  }, [me?.clientId, me?.teamId]);
}
