import type { DtoTTSRenderRequest } from '@lp-lib/api-service-client/public';

export type VoiceOverRegistryPlan = {
  entries: DtoTTSRenderRequest[];
};

export function makeVoiceOverRegistryPlan(): VoiceOverRegistryPlan {
  return {
    entries: [],
  };
}
