import { useState } from 'react';
import { usePopperTooltip } from 'react-popper-tooltip';

import { type Block, BlockType } from '@lp-lib/game';

import { useOutsideClick } from '../../../../../hooks/useOutsideClick';
import {
  useBlockRecorderVersion,
  useStopRecordingBlock,
} from '../../../../GameRecorder/BlockRecorderProvider';
import { ArrowRightIcon } from '../../../../icons/Arrows';
import { EditIcon } from '../../../../icons/EditIcon';
import { OptionsIcon } from '../../../../icons/OptionsIcon';
import { RefreshIcon } from '../../../../icons/RefreshIcon';
import {
  StreamingActiveMode,
  useStreamingMode,
} from '../../../../StreamingTools/hooks';
import { useGameEditorStore } from '../../../GameEditorStore';
import {
  useFindLocalLoadedGame,
  useLocalGamePlayStore,
  useRefreshLocalBlocksForGamePlay,
} from '../../../GamePlayStore';
import { useRecordingDeleteConfirmConfigForGame } from '../../../OndRecordingUtils';
import { hasPlayerData, resetBlock } from '../../../store';
import { SpolitBuzzerControlButtons } from '../../SpotlightV2';
import { BlockIcon } from '../BlockUtils';
import { useOndRecordingDetails } from '../Editor';

const BlockControllerButtons = (props: {
  block: Block;
}): JSX.Element | null => {
  switch (props.block.type) {
    case BlockType.SPOTLIGHT_V2:
      return <SpolitBuzzerControlButtons />;
    default:
      return null;
  }
};

export const BlockControllerHeader = ({
  block,
  setShowResetConfirmation,
  setShowEditBlockConfirmation,
}: {
  block: Block | null;
  setShowResetConfirmation: (s: boolean) => void;
  setShowEditBlockConfirmation: (s: boolean) => void;
}): JSX.Element => {
  const [isOnHover, setIsOnHover] = useState(false);
  const [showItemMenu, setShowItemMenu] = useState(false);
  const hasGameSessionPlayerData = hasPlayerData(block?.id);
  const gamePlayStore = useLocalGamePlayStore();
  const minigame = useFindLocalLoadedGame(block?.gameId);
  const store = useGameEditorStore();
  const recorderVersion = useBlockRecorderVersion();

  const {
    getTooltipProps,
    setTooltipRef,
    setTriggerRef,
    tooltipRef,
    triggerRef,
    visible,
  } = usePopperTooltip({
    trigger: 'click',
    placement: 'bottom-end',
    visible: showItemMenu,
  });

  const ondDetails = useOndRecordingDetails(
    block,
    recorderVersion,
    useRefreshLocalBlocksForGamePlay(minigame?.id),
    true,
    useRecordingDeleteConfirmConfigForGame(minigame)
  );

  const stopRecording = useStopRecordingBlock();

  const onReset = async () => {
    if (hasGameSessionPlayerData) {
      setShowResetConfirmation(true);
    } else if (block && block.id) {
      stopRecording(block, true);
      await resetBlock(block.id);
    }

    setShowItemMenu(false);
  };

  const onEditBlock = async () => {
    if (gamePlayStore.hasLoadedGameLike() && block?.id) {
      if (hasGameSessionPlayerData) {
        setShowEditBlockConfirmation(true);
      } else {
        stopRecording(block, true);
        const minigame = gamePlayStore.findGame(block.gameId) ?? null;
        await store.setEditingGame(minigame, { blockId: block.id });
      }
    }
    setShowItemMenu(false);
  };

  useOutsideClick(tooltipRef, () => setShowItemMenu(false), triggerRef);
  const streamingMode = useStreamingMode();

  const iconComponent = isOnHover ? (
    <ArrowRightIcon className='w-3 h-3 fill-current transform -rotate-90' />
  ) : (
    <OptionsIcon className='w-4 h-4 fill-current' />
  );

  if (!block) return <></>;

  let hideResetOption = false;
  let title = 'Controller';
  switch (block.type) {
    case BlockType.CREATIVE_PROMPT:
      title = 'Creative Prompt';
      break;
    case BlockType.RAPID:
      title = 'Rapid Submissions';
      break;
    case BlockType.RANDOMIZER:
      title = 'Team Randomizer';
      hideResetOption = true;
      break;
    case BlockType.SPOTLIGHT_V2:
      title = 'Spotlight V2';
      break;
    default:
      title = `${block.type}`;
  }

  return (
    <>
      <div className='w-full px-2.5 pt-1 pb-1.5 relative text-white flex flex-row justify-between items-center rounded-b-xl border-t border-secondary'>
        <div className='flex flex-row justify-between items-center'>
          <BlockIcon
            blockType={block.type}
            className='w-3 h-3 mr-1 fill-current'
          />
          <p className='text-sm font-bold capitalize'>{title}</p>
        </div>
        <div className='flex flex-row gap-1'>
          {BlockControllerButtons({ block: block })}
          <div className='flex flex-row'>
            {ondDetails.detailsModal}
            {ondDetails.warningModal}
            {streamingMode !== StreamingActiveMode.Live &&
              block.recording &&
              ondDetails.detailsLink}
            <button
              onMouseEnter={() => setIsOnHover(true)}
              onMouseLeave={() => setIsOnHover(false)}
              ref={setTriggerRef}
              onClick={() => setShowItemMenu(!showItemMenu)}
              className='w-7.5 h-7.5 relative rounded-lg appearance-none outline-none focus:outline-none bg-dark-gray border border-solid border-secondary flex justify-center items-center text-white'
              type='button'
            >
              {iconComponent}
            </button>
          </div>
        </div>
      </div>
      {visible && (
        <div
          ref={setTooltipRef}
          {...getTooltipProps({
            className:
              'w-32 h-auto border bg-black border-secondary rounded-lg text-white flex flex-col p-1 z-50 transition-opacity',
          })}
        >
          <button
            type='button'
            onMouseUp={onEditBlock}
            className='btn bg-black hover:bg-dark-gray w-full h-8 pl-2 border-0 rounded-lg flex flex-row justify-start items-center text-3xs'
          >
            <EditIcon />
            <div className='w-full text-left ml-2'>Edit Block</div>
          </button>
          {!hideResetOption && (
            <button
              type='button'
              onMouseUp={onReset}
              className='btn bg-black hover:bg-dark-gray w-full h-8 pl-2 border-0 rounded-lg flex flex-row justify-start items-center text-3xs'
            >
              <RefreshIcon />
              <div className='w-full text-left ml-2'>
                Reset: Clear all Answers
              </div>
            </button>
          )}
        </div>
      )}
    </>
  );
};
