import { useAsyncCall } from '../../hooks/useAsyncCall';
import { apiService } from '../../services/api-service';
import { assertExhaustive, err2s, ordinal } from '../../utils/common';
import { ModalWrapper } from '../ConfirmCancelModalContext/ModalWrapper';
import { useGameSessionGamePackId, useOndGameState } from '../Game/hooks';
import { type OndGameCommand } from '../Game/OndGameControl/types';
import { type OndGamePlayState } from '../Game/store';
import { CloseIcon } from '../icons/CloseIcon';
import { Loading } from '../Loading';
import { useOndGameUIControl } from '../OnDGameUIControl';
import { useMyOrganization } from '../Organization';
import { useStreamSessionId } from '../Session';
import { useVenueId } from '../Venue/VenueProvider';
import {
  useOnDGameCloudHosting,
  useOnDGameCommandDispatcher,
} from './OnDGameHostingManager';
import { log } from './shared';

function useRetryUI(
  failures: number,
  maxAttempts: number,
  ondState: OndGamePlayState | null
): {
  header: string;
  body: string;
  buttonStyle: string;
  buttonText: string;
  nextCommand: OndGameCommand | null;
} {
  const failureNote = failures > 1 ? `(${ordinal(failures)} attempt)` : '';

  let nextCommand: OndGameCommand | null = null;
  let buttonText = '';

  switch (ondState) {
    case null:
    case 'ended':
    case 'resuming':
      break;
    case 'preparing':
      nextCommand = 'startGame';
      buttonText = 'Start Game';
      break;
    case 'running':
      nextCommand = 'pauseGame';
      buttonText = 'Pause Game';
      break;
    case 'paused':
      nextCommand = 'resumeGame';
      buttonText = 'Resume Game';
      break;
    default:
      assertExhaustive(ondState);
  }

  const ui =
    failures <= maxAttempts
      ? {
          header: 'Oops!',
          body: `There is something wrong when connecting to the hosting server. Please try again. ${failureNote}`,
          buttonStyle: 'btn-primary',
          buttonText,
          nextCommand,
        }
      : {
          header: 'Unable to Reach our Remote Hosting Server',
          body: 'We apologize for the inconvenience. Please try again at another time.',
          buttonStyle: 'btn-secondary',
          buttonText: 'Close',
          nextCommand: null,
        };
  return ui;
}

export function CloudHostingError(props: {
  onComplete: () => void;
  onClose: () => void;
  maxAttempts: number;
}): JSX.Element {
  const { maxAttempts, onComplete, onClose } = props;
  const cloudHostingAPI = useOnDGameCloudHosting();
  const failures = cloudHostingAPI.getFailures();
  const org = useMyOrganization();
  const gamePackId = useGameSessionGamePackId();
  const venueId = useVenueId();
  const dispatcher = useOnDGameCommandDispatcher();
  const ondState = useOndGameState();
  const ui = useRetryUI(failures, maxAttempts, ondState);
  const ondUICtrl = useOndGameUIControl();
  const sessionId = useStreamSessionId();

  const {
    state: { transformed: retryState },
    error: retryError,
    call: retry,
    reset: clearRetryState,
  } = useAsyncCall(async (command: OndGameCommand): Promise<boolean> => {
    try {
      await cloudHostingAPI.apply();
    } catch (error) {
      cloudHostingAPI.trackFailures();
      log.error('coordinate controller retry failed', err2s(error));
      throw error;
    }
    await dispatcher.dispatch({ command });
    return true;
  });

  const reportFailure = async () => {
    const data = {
      orgId: org?.id,
      gamePackId,
      retryAttempts: failures,
    };
    if (!org?.id || !gamePackId) {
      log.warn('cannot report failure, data is missing', data);
      return;
    }
    try {
      apiService.session.reportFailure(venueId, {
        orgId: org.id,
        gamePackId,
        retryAttempts: failures,
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        sessionId,
      });
    } catch (error) {
      log.error('report failure failed', error, data);
    }
  };

  const handleReset = async () => {
    if (!ondUICtrl) return;
    reportFailure();
    await ondUICtrl.onClickReset(!ui.nextCommand);
    onComplete();
  };

  const handleRetry = async () => {
    if (!ui.nextCommand) return;
    reportFailure();
    log.info(`retry with ${ui.nextCommand}`, { command: ui.nextCommand });
    clearRetryState();
    const done = await retry(ui.nextCommand);
    if (!done) return;
    onComplete();
  };

  const retryable =
    failures <= maxAttempts && (!!ui.nextCommand || retryState.isRunning);

  return (
    <ModalWrapper containerClassName='w-85' borderStyle='gray'>
      <div className='w-full h-full min-h-52 flex flex-col items-center justify-center px-6 py-4'>
        <button
          type='button'
          className='btn absolute right-2 top-2 w-6 h-6 rounded-full flex justify-center items-center'
          onClick={onClose}
        >
          <CloseIcon className='w-3 h-3 fill-current' />
        </button>
        <header className='font-medium text-2xl text-center'>
          {ui.header}
        </header>
        <section className='w-full items-center text-sms text-center my-5'>
          {ui.body}
        </section>
        <footer className='w-full flex flex-col items-center justify-center gap-1'>
          <div className='w-full flex items-center justify-center gap-1'>
            {retryable ? (
              <button
                type='button'
                onClick={handleRetry}
                className={`btn ${ui.buttonStyle} w-60 h-10 flex items-center justify-center`}
                disabled={retryState.isRunning}
              >
                {retryState.isRunning && (
                  <Loading text='' containerClassName='mr-2' />
                )}
                {ui.buttonText}
              </button>
            ) : (
              <button
                type='button'
                className='btn-secondary w-60 h-10 flex items-center justify-center'
                onClick={handleReset}
              >
                Reset
              </button>
            )}
          </div>
          {retryError && (
            <div className='text-2xs text-red-002 mt-1'>Failed</div>
          )}
        </footer>
      </div>
    </ModalWrapper>
  );
}
