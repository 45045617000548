import { useEffect } from 'react';
import { useLatest } from 'react-use';

import { type RapidBlock, RapidBlockGameSessionStatus } from '@lp-lib/game';

import logger from '../../../../logger/logger';
import {
  useGameSessionFBRef,
  useOverrideDetailScores,
  useSessionStatusHookManager,
} from '../../hooks';
import { type GameControlProps } from '../Common/GameControl/types';
import { useWriteRapidBlockDetailScoreMeta } from './rapidDetailScoreWriter';
import { useVenueId } from '../../../Venue';

const log = logger.scoped('rapid-submission-block');

type SharedProps = GameControlProps<RapidBlock>;

export function RapidBlockGameControl(props: SharedProps): JSX.Element | null {
  const { block } = props;
  const hookManager = useSessionStatusHookManager();
  const overrideDetailScores = useLatest(useOverrideDetailScores(block.id));
  const detailScoresFBRef = useGameSessionFBRef('detailScores');
  const venueId = useVenueId();

  useWriteRapidBlockDetailScoreMeta(venueId, block.id);

  // update detailScores actual points based on the metadata
  useEffect(() => {
    const hook = {
      blockId: block.id,
      sessionStatus: RapidBlockGameSessionStatus.POINTS_DISTRIBUTED,
      before: async () => {
        const { before: beforeOverride, after: afterOverride } =
          overrideDetailScores.current;
        log.info(`rsb-point-distribute-data-pre-${block.id}`, beforeOverride);
        log.info(`rsb-point-distribute-data-after-${block.id}`, afterOverride);
        await detailScoresFBRef?.child(`${block.id}`).set(afterOverride);
      },
    };
    hookManager.register(hook);
    return () => {
      hookManager.unregister(hook);
    };
  }, [block.id, detailScoresFBRef, hookManager, overrideDetailScores]);

  return null;
}
