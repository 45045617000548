import '../../../assets/scss/slick-theme.scss';
import '../../../assets/scss/slick.scss';

import max from 'lodash/max';
import pluralize from 'pluralize';
import { useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import useMeasure from 'react-use-measure';
import { match } from 'ts-pattern';

import {
  type DtoBlockPlayedSnapshot,
  type DtoBrand,
  EnumsGamePackMakeup,
  type ModelsPlaybackSettings,
} from '@lp-lib/api-service-client/public';
import { type Block } from '@lp-lib/game';

import { useLiveCallback } from '../../../hooks/useLiveCallback';
import { ArrowLeftIcon, ArrowRightIcon } from '../../icons/Arrows';
import { RefreshIcon } from '../../icons/RefreshIcon';
import { useMakePlaybackPreview } from './hooks';

function GameComplete(props: { replayable: boolean }) {
  return (
    <div className='flex items-center gap-2 text-xs text-white'>
      {props.replayable ? (
        <>
          <RefreshIcon className='w-2.5 h-2.5 fill-current' />
          {'Game Replayable'}
        </>
      ) : (
        '✓ Game Complete!'
      )}
    </div>
  );
}

function YouWillPlay(props: {
  start: number;
  end: number;
  total: number;
  isAllUnitCompleted: boolean;
  makeup: 'round' | 'level';
}) {
  const count = props.end - props.start;
  const action = props.isAllUnitCompleted ? 'replay' : 'play';
  const content = useMemo(() => {
    if (props.total === 1) {
      return `You'll ${action} ${props.makeup} 1`;
    }
    if (props.total === count) {
      return `You'll ${action} all ${pluralize(
        props.makeup,
        props.total,
        true
      )}`;
    }
    return `You'll ${action} ${pluralize(props.makeup, count)} ${
      count > 1 ? `${props.start + 1}-${props.end}` : props.end
    } out of ${props.total}`;
  }, [action, count, props.end, props.makeup, props.start, props.total]);

  return <div className='text-xs text-icon-gray'>{content}</div>;
}

type ConfiguratorProps = {
  blocks: Block[];
  playbackSettings: Nullable<ModelsPlaybackSettings>;
  replayable: boolean;
  brands: Map<string, DtoBrand> | DtoBrand[];
  playedHistory?: DtoBlockPlayedSnapshot[];
  layout?: 'row' | 'col';
  onChange?: (info: {
    requestedUnitCount: number;
    startUnitIndex: number;
    selectedUnits: Block[][];
  }) => void;
};

function Level(props: {
  index: number;
  selectedStart: number;
  selectedEnd: number;
  onClick?: () => void;
  width: number;
}) {
  const { index, selectedStart, selectedEnd, onClick, width } = props;
  return (
    <div
      className={`flex-none p-0.5 ${onClick ? 'cursor-pointer' : ''} ${
        index >= selectedStart && index < selectedEnd
          ? `bg-primary bg-opacity-40 
              ${index === selectedStart ? 'rounded-l-xl' : ''} 
              ${index === selectedEnd - 1 ? 'rounded-r-xl' : ''}`
          : ''
      }`}
      onClick={onClick}
      style={{ scrollSnapAlign: 'start', width }}
    >
      <div
        className={`${
          onClick || index < selectedEnd
            ? `bg-secondary border rounded-xl ${
                index === selectedStart ? 'border-primary' : 'border-secondary'
              }`
            : ''
        }
        flex items-center justify-center w-full h-8`}
      >
        {index + 1}
      </div>
    </div>
  );
}

const levelWidthPx = 42;

export function GameMakeupOfLevels(props: {
  units: Array2D<Block>;
  start: number;
  end: number;
  layout: ConfiguratorProps['layout'];
  unplayedStart: number;
  allUnitsPlayed: boolean;
  setStart: (val: number) => void;
}) {
  const { units, start, end, unplayedStart, allUnitsPlayed } = props;
  const levelListRef = useRef<HTMLDivElement | null>(null);
  const [leftIndex, setLeftIndex] = useState(0);
  const [ref, bounds] = useMeasure({
    debounce: 100,
    polyfill: ResizeObserver,
  });

  const ready = bounds.width > 0;

  const visibleUnitsCount = Math.floor(bounds.width / levelWidthPx);
  const hideBack = !ready || leftIndex === 0;
  const hideForward =
    !ready || leftIndex + visibleUnitsCount > units.length - 1;

  const handleSetLeftIndex = (index: number) => {
    if (index + visibleUnitsCount > units.length - 1)
      index = units.length - visibleUnitsCount;
    if (index < 0) index = 0;
    setLeftIndex(index);
  };

  useEffect(() => {
    if (!levelListRef.current) return;

    levelListRef.current.children[leftIndex]?.scrollIntoView({
      behavior: 'smooth',
      inline: 'start',
    });
  }, [leftIndex]);

  const init = useLiveCallback(() => {
    handleSetLeftIndex(start);
  });
  useEffect(() => {
    if (!ready) return;

    init();
  }, [init, ready]);

  const handleBack = () => {
    handleSetLeftIndex(leftIndex - visibleUnitsCount);
  };

  const handleForward = () => {
    handleSetLeftIndex(leftIndex + visibleUnitsCount);
  };

  const n = end - start;
  const clickableStart = allUnitsPlayed ? units.length - 1 : unplayedStart;
  return (
    <div
      className='max-w-full w-[fit-content]
        flex items-center justify-start gap-4
      '
    >
      <p className='text-sms font-bold flex-none'>
        {n} {pluralize('level', n)}
      </p>

      <div className='flex-1 overflow-x-hidden flex items-center'>
        {!hideBack && (
          <button
            type='button'
            className={`flex-none btn text-white`}
            onClick={handleBack}
          >
            <ArrowLeftIcon className='w-3.5 h-3.5 fill-current' />
          </button>
        )}

        <div className='flex-1 overflow-x-hidden rounded-xl' ref={ref}>
          <div
            ref={levelListRef}
            className='w-full flex items-center overflow-x-scroll scrollbar-hide'
            style={{ scrollSnapType: 'x mandatory' }}
          >
            {units.map((_, index) => (
              <Level
                key={index}
                index={index}
                selectedStart={start}
                selectedEnd={end}
                onClick={
                  index <= clickableStart
                    ? () => props.setStart(index)
                    : undefined
                }
                width={levelWidthPx}
              />
            ))}

            <div
              className='flex-none'
              style={{
                width: levelWidthPx,
              }}
            ></div>
          </div>
        </div>
        {!hideForward && (
          <button
            type='button'
            className={`flex-none btn text-white`}
            onClick={handleForward}
          >
            <ArrowRightIcon className='w-3.5 h-3.5 fill-current' />
          </button>
        )}
      </div>
    </div>
  );
}

function usePlayedStatus(
  sections: Array3D<Block>,
  playedHistory?: DtoBlockPlayedSnapshot[]
) {
  return useMemo(() => {
    const mp = new Map(
      playedHistory?.map((snapshot) => [snapshot.blockId, snapshot])
    );
    const allUnitsPlayed = sections.flat(3).every((block) => mp.has(block.id));
    let nextSectionIndex = 0;
    let nextUnitIndex = 0;

    const lastPlayedAt = max(
      sections
        .flat(3)
        .map((block) => mp.get(block.id)?.playedAt)
        .filter(Boolean)
    );
    if (!!lastPlayedAt) {
      let prevBlockPlayedAt: string | null = null;
      for (let i = 0; i < sections.length; i++) {
        const units = sections[i];
        for (let j = 0; j < units.length; j++) {
          const unitBlocks = units[j];
          for (const block of unitBlocks) {
            if (lastPlayedAt === prevBlockPlayedAt) {
              nextSectionIndex = i;
              nextUnitIndex = j;
            }

            prevBlockPlayedAt = mp.get(block.id)?.playedAt ?? null;
          }
        }
      }
    }

    return { nextSectionIndex, nextUnitIndex, allUnitsPlayed };
  }, [playedHistory, sections]);
}

function resolveEndUnitIndex(
  total: number,
  start: number,
  unitsPerSession: number
) {
  const end = start + unitsPerSession;
  if (end > total) return total;
  return end;
}

export function GamePackV2PlaybackConfigurator(props: ConfiguratorProps) {
  const { blocks, playbackSettings, playedHistory, replayable } = props;
  const { sections } = useMakePlaybackPreview(blocks, playbackSettings);
  const units = useMemo(() => sections.flat(1), [sections]);
  const { nextSectionIndex, allUnitsPlayed } = usePlayedStatus(
    sections,
    playedHistory
  );
  const unitsPerSession = playbackSettings?.defaultUnitsPerSession ?? 0;
  const unplayedStart = nextSectionIndex * unitsPerSession;
  // [start, end)
  const [start, setStart] = useState(unplayedStart);
  const [end, setEnd] = useState(
    resolveEndUnitIndex(units.length, start, unitsPerSession)
  );
  const layout = props.layout ?? 'row';

  const onChange = useLiveCallback(props.onChange ?? (() => void 0));

  useLayoutEffect(() => {
    setEnd(resolveEndUnitIndex(units.length, start, unitsPerSession));
  }, [start, units.length, unitsPerSession]);

  useLayoutEffect(() => {
    const selectedUnits = units.slice(start, end);
    const requestedUnitCount = end - start;

    onChange({
      selectedUnits,
      startUnitIndex: start,
      requestedUnitCount,
    });
  }, [end, onChange, start, units]);

  return match(playbackSettings?.gameMakeup)
    .with(EnumsGamePackMakeup.GamePackMakeupMultipleLevels, () => (
      <div className='w-full flex flex-col gap-3 pt-3'>
        <GameMakeupOfLevels
          layout={layout}
          units={units}
          start={start}
          end={end}
          unplayedStart={unplayedStart}
          allUnitsPlayed={allUnitsPlayed}
          setStart={setStart}
        />

        {allUnitsPlayed && (
          <div className='flex items-center gap-2'>
            <GameComplete replayable={replayable} />
            <YouWillPlay
              start={start}
              end={end}
              total={units.length}
              isAllUnitCompleted={allUnitsPlayed}
              makeup='level'
            />
          </div>
        )}
      </div>
    ))
    .with(EnumsGamePackMakeup.GamePackMakeupMultipleRounds, () => (
      <div className='flex items-center gap-2'>
        {allUnitsPlayed && <GameComplete replayable={replayable} />}
        <YouWillPlay
          start={start}
          end={end}
          total={units.length}
          isAllUnitCompleted={allUnitsPlayed}
          makeup='round'
        />
      </div>
    ))
    .with(EnumsGamePackMakeup.GamePackMakeupOneBigGame, () =>
      allUnitsPlayed ? (
        <div className='flex items-center gap-2'>
          <GameComplete replayable={replayable} />
          {!replayable && (
            <p className='text-xs text-secondary'>
              You'll replay the same game
            </p>
          )}
        </div>
      ) : null
    )
    .with(null, () => null)
    .with(undefined, () => null)
    .exhaustive();
}
