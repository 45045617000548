export const TriangleArrowUp = (): JSX.Element => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='11'
      height='6'
      viewBox='0 0 11 6'
      className='fill-current'
    >
      <path d='M5.5 0L.737 6h9.526L5.5 0z'></path>
    </svg>
  );
};

export const TriangleArrowDown = (): JSX.Element => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='11'
      height='6'
      viewBox='0 0 11 6'
      className='fill-current'
    >
      <path d='M5.5 6L.737 0h9.526L5.5 6z'></path>
    </svg>
  );
};
