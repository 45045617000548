import chunk from 'lodash/chunk';
import shuffle from 'lodash/shuffle';
import React, { forwardRef, useLayoutEffect, useMemo, useRef } from 'react';
import useFitText from 'use-fit-text';

import { DefaultLogoIcon } from '../../../icons/LogoIcon';
import { type Board, type Category, type Clue } from './types';

const boardFillAnimationDurationSec = 3;

export function JeopardyBoard(props: {
  board: Board;
  availableClueIds?: string[] | null;
  onClickCell?: (item: Clue | Category) => void;
  selectedClueId?: string | null;
  renderSelectedClue?: (clue: Clue) => React.ReactNode;
  renderCell?: (item: Clue | Category | undefined) => React.ReactNode;
  animateBoardFill?: boolean;
  animateGameEnd?: boolean;
  hideCategories?: boolean;
}) {
  const {
    board,
    availableClueIds,
    onClickCell,
    selectedClueId,
    renderSelectedClue,
    renderCell,
    animateBoardFill,
    animateGameEnd,
    hideCategories,
  } = props;
  const { numRows, numCols, items } = board;

  const boardItemMap = useMemo(() => {
    const itemMap = new Map<string, Clue | Category>();
    for (const item of Object.values(items)) {
      if (!item) continue;
      itemMap.set(`${item.col}-${item.row}`, item);
    }
    return itemMap;
  }, [items]);

  const gridItemRefMap = useRef<Map<string, HTMLDivElement | null>>(new Map());

  const gridItems = useMemo(() => {
    const output = [];
    for (let row = 0; row < numRows; row++) {
      for (let col = 0; col < numCols; col++) {
        const item = boardItemMap.get(`${col}-${row}`);
        if (renderCell) {
          output.push(renderCell(item));
        } else {
          const isAvailable =
            item?.type !== 'clue' ||
            (availableClueIds && availableClueIds.includes(item?.id ?? ''));
          const handleClick = onClickCell
            ? () => {
                if (item) onClickCell(item);
              }
            : undefined;
          output.push(
            <BoardCell
              key={`gridItem-${col}-${row}`}
              ref={(e) => {
                gridItemRefMap.current.set(item?.id ?? `${col}-${row}`, e);
              }}
              col={col}
              row={row}
              item={isAvailable ? item : undefined}
              onClick={handleClick}
              hideCategories={hideCategories}
            />
          );
        }
      }
    }
    return output;
  }, [
    availableClueIds,
    boardItemMap,
    hideCategories,
    numCols,
    numRows,
    onClickCell,
    renderCell,
  ]);

  const clueDisplay = useMemo(() => {
    if (!selectedClueId) return null;
    const clue = items[selectedClueId];
    if (!clue || clue.type !== 'clue') return null;
    const clueRef = gridItemRefMap.current.get(selectedClueId);
    return (
      <ClueDisplay
        clue={clue}
        clueRef={clueRef}
        renderClue={renderSelectedClue}
      />
    );
  }, [selectedClueId, items, renderSelectedClue]);

  const hasAnimatedFill = useRef(false);
  useLayoutEffect(() => {
    if (!animateBoardFill || hasAnimatedFill.current) return;
    hasAnimatedFill.current = true;
    const gridItems = gridItemRefMap.current;
    const clueRefs: HTMLDivElement[] = [];
    for (const [itemId, ref] of gridItems.entries()) {
      if (board.items[itemId]?.type === 'clue' && ref) {
        clueRefs.push(ref);
      }
    }

    const shuffled = shuffle(clueRefs);
    // this is just a good number of steps for the animation. it's not perfect.
    const animationSteps = 2 * boardFillAnimationDurationSec;
    const idealChunkSize = Math.ceil(shuffled.length / animationSteps);
    const chunked = chunk(shuffled, idealChunkSize);

    chunked.forEach((chunk, index) => {
      const delay =
        index * ((boardFillAnimationDurationSec * 1000) / chunked.length);
      chunk.forEach((r) => {
        if (!r) return;
        r.style.opacity = '0';
        r.animate(
          [
            {
              opacity: 0,
            },
            {
              opacity: 1,
            },
          ],
          {
            duration: 50,
            fill: 'both',
            delay,
            easing: 'steps(2, jump-none)',
          }
        );
      });
    });
  }, [animateBoardFill, board.items]);

  const hasAnimatedGameEnd = useRef(false);
  useLayoutEffect(() => {
    if (!animateGameEnd || hasAnimatedGameEnd.current || !boardItemMap) return;
    hasAnimatedGameEnd.current = true;

    let delay = 0;
    for (let k = 0; k <= numCols + numRows - 2; k++) {
      for (let j = 0; j <= k; j++) {
        const i = k - j;
        if (i < numRows && j < numCols) {
          const item = boardItemMap.get(`${j}-${i}`);
          if (!item) return;
          const ref = gridItemRefMap.current.get(item.id);
          if (!ref) return;
          ref.animate(
            [
              {
                transform: 'scale(1)',
                offset: 0,
              },
              {
                transform: 'scale(0.65)',
                offset: 0.1,
              },
              {
                transform: 'scale(1.02)',
                offset: 0.8,
              },
              {
                transform: 'scale(0.98)',
                offset: 0.9,
              },
              {
                transform: 'scale(1)',
                offset: 1,
              },
            ],
            {
              duration: 750,
              fill: 'forwards',
              delay,
              easing: 'ease-in-out',
            }
          );
        }
      }
      delay += 75;
    }
  }, [animateGameEnd, boardItemMap, numCols, numRows]);

  const gridTemplateColumns = `repeat(${numCols}, minmax(0, 1fr)`;
  const gridTemplateRows = `repeat(${numRows}, minmax(0, 1fr)`;
  return (
    <div className='relative w-full h-full'>
      <div
        className='relative w-full h-full grid gap-1'
        style={{ gridTemplateColumns, gridTemplateRows }}
      >
        {gridItems}
      </div>
      {clueDisplay}
    </div>
  );
}

type BoardCellProps = {
  col: number;
  row: number;
  item: Nullable<Clue | Category>;
  onClick?: () => void;
  hideCategories?: boolean;
};

const BoardCell = forwardRef<HTMLDivElement, BoardCellProps>(
  (props: BoardCellProps, externalRef) => {
    const { item, onClick } = props;
    if (!item) {
      return <div ref={externalRef} className='bg-[#0B1887]' />;
    }

    if (item.type === 'category') {
      return (
        <div
          ref={externalRef}
          className={`
            bg-[#0B1887] flex items-center justify-center gap-1
            text-center font-bold text-white uppercase p-1
          `}
        >
          {props.hideCategories ? (
            <div className='w-full h-full p-4'>
              <DefaultLogoIcon />
            </div>
          ) : (
            <ScaleText>{item.category}</ScaleText>
          )}
        </div>
      );
    }

    if (item.type === 'clue') {
      return (
        <div
          ref={externalRef}
          className={`
          ${
            onClick
              ? 'bg-[#0029FF] hover:bg-[#2649FF] hover:ring-1 hover:ring-white cursor-pointer transition-colors'
              : 'bg-[#0B1887]'
          }
          flex items-center justify-center text-tertiary font-bold text-xl text-center gap-1
        `}
          onClick={onClick}
        >
          {`$${item.value}`}
        </div>
      );
    }
  }
);

function ScaleText(props: { children: React.ReactNode }) {
  const { fontSize, ref } = useFitText({ logLevel: 'none' });
  return (
    <div
      ref={ref}
      className='w-full h-full flex items-center justify-center text-center p-2'
      style={{ fontSize }}
    >
      {props.children}
    </div>
  );
}

function ClueDisplay(props: {
  clue: Clue;
  clueRef?: HTMLDivElement | null;
  renderClue?: (clue: Clue) => React.ReactNode;
}) {
  const displayRef = useRef<HTMLDivElement | null>(null);
  const ringRef = useRef<HTMLDivElement | null>(null);

  const body = useMemo(() => {
    if (props.renderClue) {
      return props.renderClue(props.clue);
    } else {
      return (
        <div className='w-full h-full flex items-center justify-center text-center text-white text-3xl'>
          {props.clue.clue}
        </div>
      );
    }
  }, [props]);

  useLayoutEffect(() => {
    if (!displayRef.current || !props.clueRef || !ringRef.current) return;

    const clueRect = props.clueRef.getBoundingClientRect();
    const displayRect = displayRef.current.getBoundingClientRect();
    const ringRect = ringRef.current.getBoundingClientRect();

    displayRef.current.style.setProperty('opacity', '0');
    ringRef.current.style.setProperty(
      'transform',
      `translate3d(${clueRect.left - ringRect.left}px, ${
        clueRect.top - ringRect.top
      }px, 0)`
    );
    ringRef.current.style.setProperty('width', `${clueRect.width}px`);
    ringRef.current.style.setProperty('height', `${clueRect.height}px`);
    ringRef.current.animate(
      [
        {
          opacity: 1,
        },
        {
          opacity: 0,
        },
      ],
      {
        duration: 166,
        fill: 'forwards',
        iterations: 3,
        easing: 'steps(2, jump-none)',
      }
    );

    displayRef.current.animate(
      [
        {
          transformOrigin: 'top left',
          transform: `translate3d(${clueRect.left - displayRect.left}px, ${
            clueRect.top - displayRect.top
          }px, 0) scale3d(${clueRect.width / displayRect.width}, ${
            clueRect.height / displayRect.height
          }, 0)`,
          opacity: 0.8,
        },
        {
          transformOrigin: 'top left',
          transform: `translate3d(0px, 0px) scale(1, 1)`,
          opacity: 1,
          easing: 'ease-in-out',
        },
      ],
      {
        duration: 450,
        fill: 'forwards',
        delay: 600,
      }
    );
  }, [props.clueRef]);

  return (
    <>
      <div
        ref={ringRef}
        className={`
          ${
            props.clueRef
              ? 'absolute inset-0 origin-top-left ring-2 ring-offset-black ring-offset-1 ring-tertiary'
              : 'hidden'
          }
          
        `}
      />
      <div
        ref={displayRef}
        className={`absolute inset-0 ${
          props.clueRef ? 'opacity-0' : 'opacity-1'
        }`}
      >
        <div className='w-full h-full bg-[#0B1887]'>{body}</div>
      </div>
    </>
  );
}
