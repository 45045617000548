import { type CSSProperties, type ReactNode, useEffect, useRef } from 'react';
import useMeasure from 'react-use-measure';

import { ResizeObserver } from '../../../../../utils/ResizeObserver';

export function ContainLayout(props: { children: ReactNode }): JSX.Element {
  return <AutoScale contentClassName='w-full'>{props.children}</AutoScale>;
}

export function AutoScale(props: {
  children: ReactNode;
  containerClassName?: string;
  contentClassName?: string;
}): JSX.Element {
  const { children, containerClassName, contentClassName } = props;
  const [containerRef, containerRect] = useMeasure({
    polyfill: ResizeObserver,
    // Ideally, I hope I can get the size immediately and subscribe the updates
    // by debounces, but seems there is no simple way.
    debounce: 0,
  });
  const contentRef = useRef<HTMLDivElement>(null);

  const scaleWidth =
    containerRect.width && contentRef.current?.offsetWidth
      ? containerRect.width / contentRef.current.offsetWidth
      : 1;
  const scaleHeight =
    containerRect.height && contentRef.current?.offsetHeight
      ? containerRect.height / contentRef.current.offsetHeight
      : 1;
  const scale = Math.min(scaleWidth, scaleHeight).toFixed(2);

  useEffect(() => {
    // Note(guoqiang): trigger resize event here, so that LayoutAnchor can capture
    // the position after being scaled.
    // TODO(guoqiang): add more properties to the event.
    window.dispatchEvent(new Event('resize'));
  }, [scale]);

  return (
    <div
      className={`w-full h-full flex justify-center items-center ${
        containerClassName ?? ''
      }`}
      ref={containerRef}
    >
      <div
        ref={contentRef}
        className={`transform ${contentClassName}`}
        style={
          {
            '--tw-scale-x': `${scale}`,
            '--tw-scale-y': `${scale}`,
          } as CSSProperties
        }
      >
        {children}
      </div>
    </div>
  );
}
