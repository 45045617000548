import React from 'react';

import { useLiveCallback } from '../../hooks/useLiveCallback';

export function HeadcountInput(props: {
  defaultValue?: number;
  value: number | undefined;
  onChange: (value: number | undefined) => void;
  min?: number;
  max?: number;
  autoFocus?: boolean;
}) {
  const { defaultValue, value, onChange, min, max, autoFocus } = props;
  const handleChange = useLiveCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event.currentTarget.valueAsNumber;
      const nextHeadcount = isNaN(value) ? undefined : value;
      onChange(nextHeadcount);
    }
  );
  return (
    <div className='w-full h-16 bg-layer-002 border border-secondary rounded-xl flex items-center gap-2'>
      <div className='flex-1 text-white pl-4'>
        <div className='font-bold'>Headcount</div>
        <div className='text-xs font-light'>Book now, increase later!</div>
      </div>

      <div className='pr-4 py-2 h-full w-24'>
        <input
          type='number'
          defaultValue={defaultValue}
          value={value}
          onChange={handleChange}
          min={min}
          max={max}
          className={`
            w-full h-full text-xl font-bold text-center m-0 p-0 transition-colors
            ${
              !value ||
              (min !== undefined && value < min) ||
              (max !== undefined && value > max)
                ? 'field-error text-red-006'
                : 'field'
            }
          `}
          autoFocus={autoFocus}
        />
      </div>
    </div>
  );
}
