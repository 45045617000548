import React, { type ReactNode, useContext, useEffect, useState } from 'react';

import { type ClientId } from '@lp-lib/crowd-frames-schema';

import {
  getFeatureQueryParam,
  getFeatureQueryParamNumber,
} from '../../hooks/useFeatureQueryParam';
import { useSnapshot } from '../../utils/valtio';
import { useExperienceMetricsManager } from '../ExperienceMetrics';
import { type FirebaseService } from '../Firebase';
import {
  type LatencyMeasure,
  latencyMeasureDestroy,
  latencyMeasureNew,
  latencyMeasureStart,
  type LatencyMeterExt,
} from './LatencyMeasure';
import {
  type LatencyColors,
  type LatencyResult,
  latencyResultColor,
} from './LatencyResult';

const Context = React.createContext<LatencyMeasure | null>(null);

function useFirebaseLatencyContext() {
  const ctx = useContext(Context);
  if (!ctx) throw new Error('FirebaseLatencyProvider not in tree');
  return ctx;
}

export function useFirebaseLatencyMeterColor(): LatencyColors {
  const ctx = useFirebaseLatencyContext();
  const snap = useSnapshot(ctx.ext);
  return latencyResultColor(
    snap.smoothed,
    ctx.config.colorProperty,
    ctx.config.goodMs,
    ctx.config.poorMs
  );
}

export function useFirebaseLatencyStats(): LatencyMeterExt {
  const ctx = useFirebaseLatencyContext();
  const snap = useSnapshot(ctx.ext);
  return snap;
}

export function useFirebaseLatencyMeterPeriod(): string {
  const ctx = useFirebaseLatencyContext();
  return ctx.config.periodLabel;
}

// Integrate with the external configuration
function initializeLatencyMeasure() {
  const minLogEmitMs = 10000;
  const logEmitMs = Math.max(
    minLogEmitMs,
    getFeatureQueryParamNumber('firebase-latency-log-emit-ms')
  );

  const minMeasureMs = 1000;
  const measureMs = Math.max(
    minMeasureMs,
    getFeatureQueryParamNumber('firebase-latency-measure-ms')
  );

  const maxWindowSize = 50;
  const windowSize = Math.min(
    maxWindowSize,
    getFeatureQueryParamNumber('firebase-latency-window-size')
  );

  const periodLabel = `${Math.floor((windowSize * measureMs) / 1000)} seconds`;

  const goodMs = getFeatureQueryParamNumber('firebase-latency-good-ms');
  const poorMs = getFeatureQueryParamNumber('firebase-latency-poor-ms');

  // Which property controls the color of the meter?
  const colorProperty: keyof LatencyResult = 'rttMs';

  const lm = latencyMeasureNew({
    logEmitMs,
    measureMs,
    smoothing: getFeatureQueryParamNumber('firebase-latency-smoothing') / 10000,
    windowSize,
    colorProperty,
    periodLabel,
    goodMs,
    poorMs,
  });

  return lm;
}

export function FirebaseLatencyProvider(props: {
  children?: ReactNode;
  isReady: boolean;
  firebaseService: FirebaseService;
  clientId: ClientId;
}): JSX.Element {
  const [contextValue] = useState<LatencyMeasure>(() =>
    initializeLatencyMeasure()
  );

  const metricsManager = useExperienceMetricsManager();

  useEffect(() => {
    if (getFeatureQueryParam('firebase-latency-measure') && props.isReady) {
      latencyMeasureStart(
        contextValue,
        props.clientId,
        props.firebaseService,
        metricsManager
      );
    }

    return () => {
      latencyMeasureDestroy(contextValue);
    };
  }, [
    contextValue,
    metricsManager,
    props.clientId,
    props.firebaseService,
    props.isReady,
  ]);

  return (
    <Context.Provider value={contextValue}>{props.children}</Context.Provider>
  );
}
