import { useRef, useState } from 'react';

import { EnumsProgramType } from '@lp-lib/api-service-client/public';

import { useOutsideClick } from '../../hooks/useOutsideClick';
import { type Option } from '../common/Utilities';
import { ArrowDownIcon, ArrowUpIcon } from '../icons/Arrows';

const ProgramTypeOptions: Option<EnumsProgramType>[] = [
  {
    label: 'Calendar',
    value: EnumsProgramType.ProgramTypeCalendar,
  },
  {
    label: 'Intros',
    value: EnumsProgramType.ProgramTypeIntros,
  },
  {
    label: 'Water Cooler',
    value: EnumsProgramType.ProgramTypeWaterCooler,
  },
  {
    label: 'Recognition',
    value: EnumsProgramType.ProgramTypeRecognition,
  },
  {
    label: 'AI Survey',
    value: EnumsProgramType.ProgramTypeAiSurvey,
  },
  {
    label: 'Custom Tournament',
    value: EnumsProgramType.ProgramTypeCustomTournament,
  },
];

function ProgramTypeChooser(props: {
  onAdd: (type: EnumsProgramType) => void;
  onCancel: () => void;
}): JSX.Element | null {
  const { onAdd, onCancel } = props;
  const ref = useRef<null | HTMLDivElement>(null);

  useOutsideClick(ref, () => onCancel());

  return (
    <div
      ref={ref}
      className='bg-modal border border-secondary rounded-xl text-white'
    >
      <div className='flex flex-col'>
        {ProgramTypeOptions.map((o) => (
          <button
            type='button'
            className='w-full p-2 hover:bg-lp-black-003 text-xs text-left'
            key={o.value}
            onClick={() => onAdd(o.value)}
          >
            {o.label}
          </button>
        ))}
      </div>
    </div>
  );
}

export function CreateProgramButton(props: {
  onAdd: (type: EnumsProgramType) => void;
  align?: 'left' | 'right';
}) {
  const [open, setOpen] = useState(false);
  const align = props.align ?? 'left';
  return (
    <div className='relative flex-none'>
      <button
        type='button'
        className='btn-primary h-10 px-4 flex items-center justify-center gap-1 font-medium'
        onClick={() => setOpen(!open)}
      >
        <div>New Program</div>
        {open ? (
          <ArrowUpIcon className='w-4 h-4 fill-current' />
        ) : (
          <ArrowDownIcon className='w-4 h-4 fill-current' />
        )}
      </button>
      {open && (
        <div
          className={`z-5 absolute mt-1 ${
            align === 'left' ? 'left-0' : 'right-0'
          }`}
        >
          <ProgramTypeChooser
            onAdd={(type) => {
              props.onAdd(type);
              setOpen(false);
            }}
            onCancel={() => setOpen(false)}
          />
        </div>
      )}
    </div>
  );
}
