import { useEffect, useRef, useState } from 'react';
import { useLatest } from 'react-use';

export function ProgressBar(props: {
  isRunning: boolean;
  result: 'Correct' | 'Wrong' | null;
  totalTimeSec: number;
  remainTimeSec: number;
}): JSX.Element {
  const { totalTimeSec, remainTimeSec, result, isRunning } = props;

  const remainPercentage =
    totalTimeSec > 0 ? (100 * remainTimeSec) / totalTimeSec : 0;

  const [showAnimation, setShowAnimation] = useState(false);
  const animationStyleRef = useRef<React.CSSProperties>({});
  const remainPercentageRef = useLatest(remainPercentage);
  const remainTimeRef = useLatest(remainTimeSec);

  useEffect(() => {
    if (!isRunning) return;

    animationStyleRef.current = {
      '--tw-zoom-out-duration': `${remainTimeRef.current}s`,
      '--tw-init-width': `${remainPercentageRef.current}%`,
      '--tw-target-width': '0',
    } as React.CSSProperties;
    setShowAnimation(true);

    return () => {
      animationStyleRef.current = {};
      setShowAnimation(false);
    };
  }, [isRunning, remainPercentageRef, remainTimeRef]);

  return (
    <div className='relative w-full h-full rounded-1.5lg overflow-hidden bg-lp-gray-002'>
      {result ? (
        <p
          className={`w-full h-full flex justify-center items-center text-base font-bold ${
            result === 'Correct' ? 'bg-green-001' : 'bg-lp-red-001'
          }`}
        >
          {result === 'Correct' ? 'CORRECT!' : 'INCORRECT'}
        </p>
      ) : (
        <>
          <div
            style={
              {
                width: showAnimation ? undefined : `${remainPercentage}%`,
                ...animationStyleRef.current,
              } as React.CSSProperties
            }
            className={`absolute h-full rounded-1.5lg ${
              remainPercentage > 50
                ? 'bg-gradient-to-tr from-primary-start to-primary-end'
                : 'bg-lp-red-001'
            } ${showAnimation ? 'animate-zoom-out' : ''}`}
          ></div>

          <div className='absolute w-full h-full px-4 flex justify-between items-center text-2xs font-bold'>
            <p>Question Timer</p>
            <p>{remainTimeSec}</p>
          </div>
        </>
      )}
    </div>
  );
}
