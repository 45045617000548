import debounce from 'lodash/debounce';
import { useMemo, useState } from 'react';
import AsyncSelect from 'react-select/async';

import { useLiveCallback } from '../../../hooks/useLiveCallback';
import { apiService } from '../../../services/api-service';
import { type GamePack } from '../../../types/game';
import { buildReactSelectStyles } from '../../../utils/react-select';
import { type Option } from '../../common/Utilities';
import { useAwaitFullScreenConfirmCancelModal } from '../../ConfirmCancelModalContext';
import { ModalWrapper } from '../../ConfirmCancelModalContext/ModalWrapper';

interface GamePackSelectProps {
  value?: GamePack | null;
  onChange: (pack: GamePack | null) => void;
  filter?: (pack: GamePack | null, inputValue: string) => boolean;
  autoFocus?: boolean;
  disabled?: boolean;
  className?: string;
  isError?: boolean;
  tagId?: number;
  placeholder?: string;
  isClearable?: boolean;
}

export const GamePackSelect = (props: GamePackSelectProps): JSX.Element => {
  const styles = useMemo(
    () =>
      buildReactSelectStyles<Option<GamePack | null>>({
        override: {
          control: { height: '100%', paddingLeft: '6px' },
        },
        isError: props.isError,
      }),
    [props.isError]
  );

  const loadOptions = debounce(
    async (q: string) => {
      try {
        const paginator = apiService.gamePack.searchGamePacks(q, {
          filterParams: {},
          primeOnly: true,
          tagId: props.tagId,
        });
        const packs = await paginator.next();
        return packs.map((p) => ({
          label: p.name,
          value: p,
        }));
      } catch {
        return [
          {
            label: 'Oops! Something went wrong!',
            value: null,
            isDisabled: true,
          },
        ];
      }
    },
    500,
    {
      leading: true,
    }
  );

  const filterOptions = (
    option: { data: { value: GamePack | null } },
    inputValue: string
  ) => {
    if (!props.filter) return true;
    return props.filter(option.data.value, inputValue);
  };

  return (
    <AsyncSelect<Option<GamePack | null>>
      value={
        props.value ? { label: props.value.name, value: props.value } : null
      }
      defaultOptions
      loadOptions={loadOptions}
      filterOption={filterOptions}
      onChange={(option) => {
        props.onChange(option ? option.value : null);
      }}
      autoFocus={props.autoFocus}
      noOptionsMessage={() => <div>0 matching results</div>}
      classNamePrefix='select-box-v2'
      className={`w-full h-full m-0 ${props.className}`}
      styles={styles}
      placeholder={props.placeholder ?? 'N/A'}
      isDisabled={props.disabled}
      isClearable={props.isClearable}
    />
  );
};

type SimpleGamePackPickerProps = {
  onSave: (pack: GamePack | null) => void;
} & Omit<GamePackSelectProps, 'onChange'>;

function SimpleGamePackPicker(
  props: SimpleGamePackPickerProps & {
    onCancel: () => void;
  }
) {
  const [selected, setSelected] = useState<GamePack | null>(
    props.value ?? null
  );
  return (
    <ModalWrapper containerClassName='w-120' borderStyle='gray'>
      <div className='w-full flex flex-col gap-4 items-center p-6'>
        <header className='text-xl font-bold'>{props.placeholder}</header>
        <GamePackSelect
          {...props}
          value={selected}
          onChange={(pack) => setSelected(pack)}
        />
        <footer className='flex items-center justify-center gap-4'>
          <button
            type='button'
            className='btn-secondary w-40 h-10'
            onClick={props.onCancel}
          >
            Cancel
          </button>
          <button
            type='button'
            className='btn-primary w-40 h-10'
            onClick={() => props.onSave(selected)}
          >
            Save
          </button>
        </footer>
      </div>
    </ModalWrapper>
  );
}

export function useOpenSimpleGamePackPicker() {
  const triggerModal = useAwaitFullScreenConfirmCancelModal();

  return useLiveCallback((props: SimpleGamePackPickerProps) => {
    triggerModal({
      kind: 'custom',
      element: (p) => (
        <SimpleGamePackPicker
          {...props}
          onCancel={p.internalOnCancel}
          onSave={(pack) => {
            props.onSave(pack);
            p.internalOnConfirm();
          }}
        />
      ),
    });
  });
}
