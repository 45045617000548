import { useMemo } from 'react';
import AsyncSelect from 'react-select/async';

import { type DtoBrand } from '@lp-lib/api-service-client/public';

import { useLiveCallback } from '../../hooks/useLiveCallback';
import { apiService } from '../../services/api-service';
import { buildReactSelectStyles } from '../../utils/react-select';
import { BrandUtils } from './utils';

export function BrandSelect(props: {
  value: DtoBrand | null;
  onChange: (value: DtoBrand | null) => void;
  className?: string;
  placeholder?: string;
  isClearable?: boolean;
  orphan?: boolean;
}) {
  const { value, onChange, className } = props;

  const styles = useMemo(
    () =>
      buildReactSelectStyles<DtoBrand>({
        override: {
          control: {
            width: '100%',
            height: '100%',
          },
        },
      }),
    []
  );

  const loadOptions = useLiveCallback(async (q: string) => {
    const brands = await apiService.brand.search(q).next();
    if (props.orphan) {
      brands.unshift(BrandUtils.FakeBrandForOrphanBlocks());
    }
    return brands;
  });

  return (
    <AsyncSelect<DtoBrand, false>
      value={value}
      cacheOptions
      defaultOptions
      loadOptions={loadOptions}
      onChange={(v) => onChange(v)}
      className={`mb-0 ${className}`}
      classNamePrefix='select-box-v2'
      styles={styles}
      isMulti={false}
      getOptionValue={(option) => option.id}
      getOptionLabel={(option) => option.name}
      placeholder={props.placeholder ?? 'Search Brand...'}
      isClearable={props.isClearable}
    />
  );
}
