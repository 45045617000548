import { useState } from 'react';

import { GameSessionUtil } from '@lp-lib/game';

import { getFeatureQueryParam } from '../../hooks/useFeatureQueryParam';
import { apiService } from '../../services/api-service';
import {
  useIsBroadcastingEnabled,
  useToggleBroadcastFeature,
} from '../Broadcast';
import { useGameSessionBlock, useGameSessionStatus } from '../Game/hooks';
import { useCanUseGameRecordMode } from '../GameRecorder/hooks/useCanUseGameRecordMode';
import { RecordingLogsPanel } from '../GameRecorder/HostTools';
import { ArrowLeftIcon, ArrowRightIcon } from '../icons/Arrows';
import { DeleteIcon } from '../icons/DeleteIcon';
import { MegaphoneIcon } from '../icons/MegaphoneIcon';
import { ShuffleIcon } from '../icons/ShuffleIcon';
import { UnlockIcon } from '../icons/UnlockIcon';
import { useIsStreamSessionAlive } from '../Session';
import { SwitcherControlled } from '../Switcher';
import { TeamRandomizer } from '../TeamRandomizer/HostTools';
import { TeamSizeControl } from '../TeamSizeControl/HostTools';
import { TownhallControl, TownhallHostTools } from '../Townhall';
import { useVenue, useVenueAPI, useVenueId } from '../Venue/VenueProvider';
import {
  useIsStreamingToolsMoreToolsOpen,
  useStreamingToolsSubpanelState,
} from './StreamingToolsContext';
import { Control } from './Utilities';

const BroadcastControl = (): JSX.Element => {
  const enabled = useIsBroadcastingEnabled();
  const toggle = useToggleBroadcastFeature();
  const handleChange = async (checked: boolean) => {
    await toggle(checked);
  };

  return (
    <Control
      text='Enable "Broadcast" feature'
      info='Allows audience members to broadcast their mic to everyone in the
            event.'
      icon={<MegaphoneIcon className='w-full h-full fill-current' />}
    >
      <SwitcherControlled
        name='broadcast'
        checked={enabled}
        onChange={handleChange}
      />
    </Control>
  );
};

// function RecordingLogsControl(props: { handleOpen: () => void }): JSX.Element {
//   return (
//     <div
//       id='recording-mode-host-control-entry'
//       data-testid='recording-mode-host-control-entry'
//       className={`cursor-pointer`}
//       onClick={() => props.handleOpen()}
//     >
//       <Control
//         text='Recording Logs'
//         info={`Check the upload status of recordings`}
//         icon={<OndIcon className='w-full h-full fill-current' />}
//         hover={true}
//       >
//         <div className={`text-white`}>
//           <ArrowRightIcon />
//         </div>
//       </Control>
//     </div>
//   );
// }

export const TeamRandomizerControl = (props: {
  handleOpen: () => void;
}): JSX.Element => {
  const map = GameSessionUtil.StatusMapFor(useGameSessionBlock());
  const gameSessionStatus = useGameSessionStatus();

  const enabled =
    map === null ||
    gameSessionStatus === null ||
    gameSessionStatus === undefined ||
    gameSessionStatus === map.loaded ||
    gameSessionStatus === map.end;

  const handleClick = () => {
    if (!enabled) return;
    props.handleOpen();
  };

  return (
    <div
      id='randomizer-host-control-entry'
      className={`${enabled ? 'cursor-pointer' : 'cursor-not-allowed'}`}
      onClick={handleClick}
    >
      <Control
        text='Randomize the Teams'
        info={
          enabled
            ? 'Randomly assign Players onto Teams'
            : 'End block to randomize the teams'
        }
        icon={<ShuffleIcon className='w-full h-full fill-current' />}
        disabled={!enabled}
        hover={enabled}
      >
        <div className={`${enabled ? 'text-white' : 'text-secondary'}`}>
          <ArrowRightIcon />
        </div>
      </Control>
    </div>
  );
};

const ConfirmModal = (props: {
  onCancel: () => void;
  onProcess: () => void;
}): JSX.Element => {
  return (
    <div className='absolute top-0 left-0 w-full h-full z-5 flex items-center justify-center px-2 bg-lp-black-002'>
      <div className='border border-white bg-black rounded-xl p-2'>
        <p className='text-white text-base text-center font-bold my-6'>
          Are you sure you want to reset venue?
        </p>
        <div className='flex'>
          <button
            type='button'
            className='btn-secondary w-1/2 h-10 mx-1'
            onClick={props.onCancel}
          >
            Cancel
          </button>
          <button
            type='button'
            className='btn-delete w-1/2 h-10 mx-1'
            onClick={props.onProcess}
          >
            Process
          </button>
        </div>
      </div>
    </div>
  );
};

export function ResetVenueControl(): JSX.Element {
  const [showModal, setShowModal] = useState(false);
  const isSessionLive = useIsStreamSessionAlive();
  const venueId = useVenueId();
  const handleProcess = async () => {
    await apiService.venue.cleanupVenue(venueId, true);
    setShowModal(false);
  };
  return (
    <div>
      <Control
        text='Reset Venue'
        info={
          isSessionLive
            ? "You can't reset venue when streaming"
            : 'Cleanup venue, all players will be force removed'
        }
        icon={<DeleteIcon className='w-full h-full fill-current' />}
      >
        <button
          type='button'
          className='btn btn-primary text-3xs w-14 h-7 self-center'
          onClick={() => setShowModal(true)}
          disabled={isSessionLive}
        >
          Reset
        </button>
      </Control>
      {showModal && (
        <ConfirmModal
          onCancel={() => setShowModal(false)}
          onProcess={handleProcess}
        />
      )}
    </div>
  );
}

function GuestUserControl(): JSX.Element {
  const [venue] = useVenue();
  const api = useVenueAPI();

  return (
    <Control
      text='Enable Guest Users'
      info='Allows Players to access the Venue without logging in.'
      icon={<UnlockIcon className='w-full h-full fill-current' />}
    >
      <SwitcherControlled
        name='guest-user'
        checked={venue.guestEnabled}
        onChange={(v) => api.persist({ guestEnabled: v })}
      />
    </Control>
  );
}

export const StreamingMoreTools = (props: {
  handleClose: () => void;
}): JSX.Element => {
  const teamRandomizerEnabled = getFeatureQueryParam('team-randomizer');
  const canToggleRecordMode = useCanUseGameRecordMode();
  const [getSubpanelState, setSubpanelState] = useStreamingToolsSubpanelState();
  const isMoreToolsOpen = useIsStreamingToolsMoreToolsOpen();

  return (
    <div className='flex flex-col h-full w-full'>
      {isMoreToolsOpen && (
        <div>
          <header
            onClick={() => props.handleClose()}
            className='flex items-center p-4 text-white text-xs cursor-pointer'
          >
            <ArrowLeftIcon className='' />
            <p className='ml-2'>More Tools</p>
          </header>
          {/* {teamRandomizerEnabled && (
            <TeamRandomizerControl
              handleOpen={() => setSubpanelState('team-randomizer', true)}
            />
          )} */}
          <TeamSizeControl />
          <BroadcastControl />
          <GuestUserControl />
          <TownhallControl
            handleOpen={() => setSubpanelState('townhall', true)}
          />
          <ResetVenueControl />
        </div>
      )}
      {teamRandomizerEnabled && getSubpanelState('team-randomizer') && (
        <TeamRandomizer
          handleClose={() => setSubpanelState('team-randomizer', false)}
        />
      )}
      {canToggleRecordMode && getSubpanelState('recording-logs') && (
        <RecordingLogsPanel
          handleClose={() => setSubpanelState('recording-logs', false)}
        />
      )}
      {getSubpanelState('townhall') && (
        <TownhallHostTools
          handleClose={() => setSubpanelState('townhall', false)}
        />
      )}
    </div>
  );
};
