import { useNavigate } from '@remix-run/react';
import copy from 'copy-to-clipboard';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useMountedState } from 'react-use';

import {
  type TransformedAsyncCallState,
  useAsyncCall,
} from '../../../hooks/useAsyncCall';
import { apiService } from '../../../services/api-service';
import {
  NotificationType,
  type Organization,
  type Organizer,
  OrganizerRole,
  OrganizerRoleUtils,
  OrganizerUtils,
  type OrgConnection,
  RoleUtils,
} from '../../../types';
import { err2s, uuidv4 } from '../../../utils/common';
import { EMAIL_PATTERN } from '../../Access';
import { type Action, ActionSheet } from '../../ActionSheet';
import { Modal } from '../../common/Modal';
import { useAwaitFullScreenConfirmCancelModal } from '../../ConfirmCancelModalContext';
import { AccountIcon } from '../../icons/AccountIcon';
import { CopyIcon } from '../../icons/CopyIcon';
import { DeleteIcon } from '../../icons/DeleteIcon';
import { EditIcon } from '../../icons/EditIcon';
import { RefreshIcon } from '../../icons/RefreshIcon';
import { ScoreboardIcon } from '../../icons/ScoreboardIcon';
import { SlackIcon } from '../../icons/SlackIcon';
import { Loading } from '../../Loading';
import { useNotificationDataSource } from '../../Notification/Context';
import { useUser } from '../../UserContext';

interface FormData {
  uid?: string;
  orgId: string;
  firstName: string;
  lastName: string;
  email: string;
}

function EditModal({
  org,
  organizer,
  onCancel,
  onUpdated,
}: {
  org: Organization;
  organizer: Organizer;
  onCancel: () => void;
  onUpdated: (org: Organizer) => void;
}) {
  const {
    state: { transformed: state },
    error,
    call,
  } = useAsyncCall(
    useCallback(
      (req: FormData) => {
        return apiService.organization.updateOrganizer(
          organizer.orgId,
          organizer.uid,
          req
        );
      },
      [organizer.orgId, organizer.uid]
    )
  );

  const handleSave = async (data: FormData) => {
    const resp = await call(data);
    if (!resp) return;
    onUpdated(resp.data.organizer);
  };

  return (
    <Modal borderStyle='gray' stopPropagateMouseDown>
      <OrganizerEditor
        org={org}
        organizer={organizer}
        state={state}
        error={error}
        onCancel={onCancel}
        onSave={handleSave}
      />
    </Modal>
  );
}

function OrganizerEditor({
  org,
  organizer,
  state,
  error,
  onCancel,
  onSave,
}: {
  org: Organization;
  organizer?: Organizer;
  state: TransformedAsyncCallState;
  error: Error | null;
  onCancel: () => void;
  onSave: (org: FormData) => void;
}): JSX.Element {
  const { register, handleSubmit, formState, setFocus } = useForm<FormData>({
    defaultValues: {
      orgId: org.id,
      ...organizer,
    },
  });

  const onSubmit = handleSubmit((data: FormData) => {
    onSave(data);
  });

  useEffect(() => {
    setFocus('firstName');
  }, [setFocus]);

  return (
    <form
      onSubmit={onSubmit}
      className='w-160 px-5 py-10 text-white flex flex-col'
    >
      <h1 className='w-full text-2xl text-center font-medium'>
        {organizer ? 'Edit Member' : 'Create Member'}
      </h1>

      <div className='w-full my-8 font-bold text-base'>
        <label htmlFor='firstName' className='inline-block w-full my-2'>
          <div>First Name</div>
          <input
            {...register('firstName', { required: true, maxLength: 50 })}
            placeholder='Must be 1 to 50 characters'
            className={`${
              formState.errors.firstName ? 'field-error' : 'field'
            } my-1`}
          ></input>
          {formState.errors.firstName && (
            <div className='text-red-005 font-medium text-3xs'>
              Must be 1 to 50 characters
            </div>
          )}
        </label>

        <label htmlFor='lastName' className='inline-block w-full my-2'>
          <div>Last Name</div>
          <input
            {...register('lastName', { required: true, maxLength: 50 })}
            placeholder='Must be 1 to 50 characters'
            className={`${
              formState.errors.lastName ? 'field-error' : 'field'
            } my-1`}
          ></input>
          {formState.errors.lastName && (
            <div className='text-red-005 font-medium text-3xs'>
              Must be 1 to 50 characters
            </div>
          )}
        </label>

        <label htmlFor='email' className='inline-block w-full my-2'>
          <div>Email Address</div>
          <input
            {...register('email', {
              required: true,
              maxLength: 50,
              pattern: EMAIL_PATTERN,
            })}
            placeholder='Must be 1 to 50 characters'
            className={`${
              formState.errors.email ? 'field-error' : 'field'
            } my-1`}
          ></input>
          {formState.errors.email && (
            <div className='text-red-005 font-medium text-3xs'>
              Please enter a valid email address
            </div>
          )}
        </label>
      </div>

      <div className='w-full'>
        {error && (
          <div className='w-full text-center text-3xs text-red-005'>
            Saving Failed. Err Msg: {err2s(error)}
          </div>
        )}
        <div className='flex justify-center'>
          <button
            type='button'
            onClick={onCancel}
            className='btn-secondary w-40 h-10 mx-2'
          >
            Cancel
          </button>
          <button
            type='submit'
            className='btn-primary w-40 h-10 mx-2 flex justify-center items-center'
            disabled={
              state.isRunning || Object.keys(formState.errors).length > 0
            }
          >
            {state.isRunning && <Loading text='' containerClassName='mr-2' />}
            Save
          </button>
        </div>
      </div>
    </form>
  );
}

export function DeleteModal({
  organizer,
  onCancel,
  onDeleted,
}: {
  organizer: Organizer;
  onCancel: () => void;
  onDeleted: (org: Organizer) => void;
}): JSX.Element {
  const {
    state: { transformed: state },
    error,
    call,
  } = useAsyncCall(
    useCallback(async (orgId: string, uid: string) => {
      return await apiService.organization.deleteOrganizer(orgId, uid);
    }, [])
  );

  const handleCancel = () => {
    onCancel();
  };

  const handleConfirm = async () => {
    const resp = await call(organizer.orgId, organizer.uid);
    if (!resp) return;
    onDeleted(organizer);
  };

  return (
    <Modal borderStyle='gray' stopPropagateMouseDown>
      <div className='w-88 px-8 text-white flex flex-col justify-center items-center'>
        <div className='w-full mt-5 text-2xl font-medium text-center'>
          <p className=''>Are you sure you want to remove this Member?</p>
        </div>

        <div className='w-full my-8'>
          {error && (
            <div className='w-full text-red-005 text-center text-xs mb-2'>
              {err2s(error)}
            </div>
          )}
          <div className='w-full flex justify-between'>
            <button
              type='button'
              onClick={handleCancel}
              className='btn-secondary w-33 h-10'
            >
              Cancel
            </button>
            <button
              type='button'
              onClick={handleConfirm}
              className='btn-delete w-33 h-10 ml-2 flex items-center justify-center'
              disabled={state.isRunning}
            >
              {state.isRunning && <Loading text='' containerClassName='mr-2' />}
              <p>Delete</p>
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
}

function UpdateRoleModal(props: {
  org: Organization;
  organizer: Organizer;
  onCancel: () => void;
  onUpdated: (org: Organizer) => void;
}): JSX.Element {
  const { org, onCancel, onUpdated } = props;
  const [organizer, setOrganizer] = useState<Organizer | null>(null);
  const [currentRole, setCurrentRole] = useState<OrganizerRole>(
    OrganizerRole.Default
  );
  const isMounted = useMountedState();
  const me = useUser();
  const isAdmin = RoleUtils.isAdmin(me);
  const orgRole = me.organizer?.role;

  const allRoles = useMemo(() => {
    if (isAdmin || OrganizerRoleUtils.isOwner(orgRole)) {
      return [OrganizerRole.Default, OrganizerRole.Admin, OrganizerRole.Owner];
    }
    if (OrganizerRoleUtils.isAdmin(orgRole)) {
      return [OrganizerRole.Default, OrganizerRole.Admin];
    }
    return [];
  }, [isAdmin, orgRole]);

  const {
    state: { transformed: loadState },
    error: loadError,
    call: load,
  } = useAsyncCall(
    useCallback(async () => {
      const resp = await apiService.organization.getOrganizer(
        props.organizer.orgId,
        props.organizer.uid
      );
      if (isMounted()) {
        setOrganizer(resp.data.organizer);
        setCurrentRole(resp.data.organizer.role);
      }
    }, [isMounted, props.organizer.orgId, props.organizer.uid])
  );

  const {
    state: { transformed: state },
    error,
    call,
  } = useAsyncCall(
    useCallback(
      (role: OrganizerRole) => {
        return apiService.organization.updateOrganizerRole(
          props.organizer.orgId,
          props.organizer.uid,
          role
        );
      },
      [props.organizer.orgId, props.organizer.uid]
    )
  );

  const handleSave = async () => {
    if (!organizer) return;
    if (currentRole === organizer.role) {
      onUpdated(organizer);
      return;
    }
    const resp = await call(currentRole);
    if (!resp) return;
    onUpdated({ ...organizer, role: currentRole });
  };

  useEffect(() => {
    load();
  }, [load]);

  if (!organizer) {
    return (
      <Modal borderStyle='white' stopPropagateMouseDown>
        <div className='w-160 px-5 py-8 text-white flex flex-col justify-center items-center'>
          {loadState.isRunning && <Loading />}
          {loadError && (
            <div className='w-full text-center text-3xs text-red-005'>
              Load organizer error: {err2s(loadError)}
            </div>
          )}
        </div>
      </Modal>
    );
  }

  return (
    <Modal borderStyle='white' stopPropagateMouseDown>
      <div className='w-160 px-5 py-8 text-white flex flex-col justify-center'>
        <div className='w-full text-2xl text-center font-medium'>
          Change {organizer.firstName}’s Role
        </div>
        <div className='my-8 text-center'>
          Select what level of permissions {organizer.firstName}{' '}
          {organizer.lastName} should have in {org.name}.
        </div>
        <div>
          {allRoles.map((r) => (
            <label
              key={r}
              className='flex items-center mx-8 my-2 cursor-pointer'
            >
              <input
                type='radio'
                name='role'
                className='field-radio'
                checked={currentRole === r}
                onChange={() => setCurrentRole(r)}
              />
              <p className='ml-8'>{OrganizerUtils.GetRoleName(r)}</p>
            </label>
          ))}
          <div
            className={`w-full text-center text-3xs text-red-005 ${
              currentRole === OrganizerRole.Owner &&
              currentRole !== organizer.role
                ? 'visible'
                : 'invisible'
            }`}
          >
            {isAdmin
              ? 'Designating a new Billing Admin will transfer the role from the current one.'
              : 'If you’d like to change the Owner, please reach out to info@golunapark.com'}
          </div>
        </div>
        <div className='w-full mt-8'>
          {error && (
            <div className='w-full text-center text-3xs text-red-005'>
              Update Role Failed: {err2s(error)}
            </div>
          )}
          <div className='flex justify-center mt-3'>
            <button
              type='button'
              onClick={onCancel}
              className='btn-secondary w-40 h-10 mx-2'
            >
              Cancel
            </button>
            <button
              type='submit'
              className='btn-primary w-40 h-10 mx-2 flex justify-center items-center'
              disabled={
                state.isRunning ||
                (currentRole === OrganizerRole.Owner && !isAdmin)
              }
              onClick={handleSave}
            >
              {state.isRunning && <Loading text='' containerClassName='mr-2' />}
              Save
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
}

type OrganizerActionSheetKeys =
  | 'update-organizer-role'
  | 'edit'
  | 'delete'
  | 'resend'
  | 'copy-login-link'
  | 'copy-slack-connect-link'
  | 'see-live-events';

export function OrganizerActionSheet(props: {
  organization: Organization;
  connection: OrgConnection | null;
  organizer: Organizer;
  onChange: (organizer: Organizer) => void;
  onDelete: (organizer: Organizer) => void;
}): JSX.Element {
  const { organization, connection, organizer, onChange } = props;

  const triggerFullScreenModal = useAwaitFullScreenConfirmCancelModal();
  const { send: sendNotification } = useNotificationDataSource();
  const user = useUser();
  const navigate = useNavigate();
  const isAdmin = RoleUtils.isAdmin(user);
  const isOrgManageable = OrganizerRoleUtils.isOwnerOrAdmin(user.organizer);

  const actions: Action<OrganizerActionSheetKeys>[] = [];

  if (isAdmin || isOrgManageable) {
    actions.push({
      kind: 'button',
      key: 'update-organizer-role',
      icon: <AccountIcon />,
      text: 'Change Role',
      disabled: !OrganizerUtils.CanIManage(user, organizer),
      onClick: () => {
        triggerFullScreenModal({
          kind: 'custom',
          element: (p) => (
            <UpdateRoleModal
              org={organization}
              organizer={organizer}
              onCancel={p.internalOnCancel}
              onUpdated={(organizer) => {
                p.internalOnConfirm();
                onChange(organizer);
              }}
            />
          ),
        });
      },
    });
  }

  actions.push({
    kind: 'button',
    key: 'edit',
    icon: <EditIcon />,
    text: 'Edit Info',
    disabled: !OrganizerUtils.CanIManage(user, organizer, true),
    onClick: () => {
      triggerFullScreenModal({
        kind: 'custom',
        element: (p) => (
          <EditModal
            org={organization}
            organizer={organizer}
            onCancel={p.internalOnCancel}
            onUpdated={(organizer) => {
              p.internalOnConfirm();
              onChange(organizer);
            }}
          />
        ),
      });
    },
  });

  if (!organizer.activated) {
    actions.push({
      kind: 'button',
      key: 'resend',
      icon: <RefreshIcon />,
      text: 'Resend',
      onClick: async () => {
        const resp = await apiService.organization.reinviteOrganizer(
          organizer.orgId,
          organizer.uid,
          {
            webEndpoint: window.location.origin,
          }
        );
        if (!resp) return;
        onChange(organizer);

        sendNotification({
          id: uuidv4(),
          toUserClientId: user.id,
          type: NotificationType.OrgAction,
          createdAt: Date.now(),
          metadata: {
            actionType: 'resend',
            organizer: organizer,
          },
        });
      },
    });
  }

  if (!connection?.asSourceOfTruth) {
    actions.push({
      kind: 'button',
      key: 'delete',
      icon: <DeleteIcon />,
      text: organizer.activated ? 'Delete' : 'Revoke',
      className: 'text-red-002',
      disabled: !OrganizerUtils.CanIManage(user, organizer),
      onClick: () => {
        triggerFullScreenModal({
          kind: 'custom',
          element: (p) => (
            <DeleteModal
              organizer={organizer}
              onCancel={p.internalOnCancel}
              onDeleted={(organizer) => {
                p.internalOnConfirm();
                props.onDelete(organizer);
              }}
            />
          ),
        });
      },
    });
  }

  if (isAdmin) {
    actions.push({
      kind: 'button',
      key: 'copy-login-link',
      icon: <CopyIcon />,
      text: 'Copy Login Link',
      onClick: async () => {
        const resp = await apiService.auth.genLoginURLByAdmin(organizer.uid);
        if (!resp) return;
        copy(resp.data.url);
      },
    });
    actions.push({
      kind: 'button',
      key: 'see-live-events',
      icon: <ScoreboardIcon />,
      text: 'See Live Events',
      onClick: async () => {
        const params = new URLSearchParams({
          eventOrganizerId: organizer.uid,
        });
        const url = `/admin/organizations/${
          organization.id
        }/sessions?${params.toString()}`;
        navigate(url);
      },
    });
  }

  if (isAdmin && OrganizerRoleUtils.isOwnerOrAdmin(organizer)) {
    actions.push({
      kind: 'button',
      key: 'copy-slack-connect-link',
      icon: <SlackIcon className='w-3 h-3' />,
      text: 'Copy Slack Connect Link',
      onClick: async () => {
        const resp = await apiService.auth.genSlackConnectURLByAdmin(
          organizer.uid
        );
        if (!resp) return;
        copy(resp.data.url);
      },
    });
  }

  return <ActionSheet actions={actions} />;
}
