import {
  type CSSProperties,
  Fragment,
  type ReactNode,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Controller, useForm } from 'react-hook-form';
import useSWR from 'swr';
import useSWRMutation from 'swr/mutation';
import { match } from 'ts-pattern';

import { type Media, MediaType } from '@lp-lib/media';

import { apiService } from '../../services/api-service';
import { type VirtualBackgroundEffects } from '../../services/webrtc/virtual-background';
import { type VirtualBackgroundSettings } from '../../types';
import { fromMediaDTO } from '../../utils/api-dto';
import { getStaticAssetPath } from '../../utils/assets';
import { Modal } from '../common/Modal';
import { useAwaitFullScreenConfirmCancelModal } from '../ConfirmCancelModalContext';
import { FilledCheckIcon } from '../icons/CheckIcon';
import { DeleteIcon } from '../icons/DeleteIcon';
import { EditIcon } from '../icons/EditIcon';
import { FlipIcon } from '../icons/FlipIcon';
import { XIcon } from '../icons/XIcon';
import { Loading } from '../Loading';
import { MiniMediaUploader } from '../MediaUploader/MiniMediaUploader';
import { useUserContext, useUserStates } from '../UserContext';
import { useDeviceAPI } from './Context';
import { useMustVirtualBackgroundMixer } from './video-stream-mixer';
import { VideoPreviewMini } from './VideoPreview';

type VBGNoneOption = { type: 'none' };
type VBGColorOption = { type: 'color'; color: string };
type VBGBlurOption = { type: 'blur'; blurDegree: number; background: string };
type VBGMediaOption = {
  id?: string;
  type: 'media';
  sourceType: 'video' | 'img';
  source: string;
};

type VBGOption =
  | VBGNoneOption
  | VBGBlurOption
  | VBGMediaOption
  | VBGColorOption;

const STATIC_VBG_OPTIONS: VBGOption[] = [
  {
    type: 'none',
  },
  {
    type: 'blur',
    blurDegree: 3,
    background: getStaticAssetPath('images/vbg-blur-example-1.png'),
  },
  {
    type: 'color',
    color: '#00ff00',
  },
  {
    type: 'media',
    sourceType: 'img',
    source: getStaticAssetPath('images/vbg-2024-001.jpg'),
  },
  // {
  //   type: 'media',
  //   sourceType: 'video',
  //   source: getStaticAssetPath('videos/vbg-2024-001.mp4'),
  // },
];

type ChangeEffects = (options: VirtualBackgroundEffects | null) => void;

function BackgroundPreviewLayout(props: {
  style?: CSSProperties;
  children?: ReactNode;
  onClick?: () => void;
  active?: boolean;
  accessory?: ReactNode;
}) {
  return (
    <div
      className={`bg-secondary group border ${
        props.active ? 'border-white' : 'border-secondary'
      } ${props.onClick ? 'cursor-pointer' : ''}
    text-sms flex items-center justify-center w-30 h-17 
    rounded-xl relative flex-shrink-0`}
      style={props.style}
    >
      <div
        className='w-full h-full flex items-center justify-center relative group-1'
        onClick={props.onClick}
      >
        {props.children}
        {props.onClick && (
          <div
            className='w-full h-full absolute inset-0 z-5 hidden group-1-hover:block 
      bg-white bg-opacity-15 rounded-xl pointer-events-none'
          />
        )}
      </div>
      {props.accessory}
    </div>
  );
}

function NoneBackground(props: {
  option: VBGNoneOption;
  active?: boolean;
  changeEffects: ChangeEffects;
}) {
  return (
    <BackgroundPreviewLayout
      onClick={() => props.changeEffects(null)}
      active={props.active}
    >
      None
    </BackgroundPreviewLayout>
  );
}

function ColorBackground(props: {
  option: VBGColorOption;
  active?: boolean;
  changeEffects: ChangeEffects;
}) {
  const [editing, setEditing] = useState(false);
  const [color, setColor] = useState(props.option.color);

  if (editing) {
    return (
      <BackgroundPreviewLayout active={props.active}>
        <div className='w-full h-full flex flex-col items-center justify-center gap-2 mt-1'>
          <input
            type='color'
            value={color}
            onChange={(e) => setColor(e.target.value)}
          />
          <div className='flex items-center justify-center gap-4'>
            <button
              type='button'
              className='w-5 h-5'
              onClick={() => setEditing(false)}
            >
              <XIcon className='w-full h-full fill-current stroke-current' />
            </button>
            <button
              type='button'
              className='w-5 h-5'
              onClick={() => {
                props.changeEffects({
                  type: 'color',
                  color: color,
                });
                setEditing(false);
              }}
            >
              <FilledCheckIcon className='w-full h-full fill-current stroke-current' />
            </button>
          </div>
        </div>
      </BackgroundPreviewLayout>
    );
  }

  return (
    <BackgroundPreviewLayout
      onClick={() => props.changeEffects(props.option)}
      active={props.active}
      style={{ backgroundColor: props.option.color }}
      accessory={
        <button
          type='button'
          onClick={(e) => {
            e.stopPropagation();
            setEditing(true);
          }}
          className={`absolute -right-1 -top-1 btn w-6 h-6 hidden group-hover:flex 
            items-center justify-center bg-secondary rounded-lg z-[6]`}
        >
          <EditIcon />
        </button>
      }
    >
      <p className='mix-blend-difference'>{props.option.color}</p>
    </BackgroundPreviewLayout>
  );
}

function BlurBackground(props: {
  option: VBGBlurOption;
  active?: boolean;
  changeEffects: ChangeEffects;
}) {
  const { option } = props;
  return (
    <BackgroundPreviewLayout
      style={{
        backgroundImage: `url(${option.background})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
      }}
      onClick={() => {
        props.changeEffects({
          type: 'blur',
          blurDegree: option.blurDegree,
        });
      }}
      active={props.active}
    >
      Blur
    </BackgroundPreviewLayout>
  );
}

function MediaBackground(props: {
  option: VBGMediaOption;
  active?: boolean;
  changeEffects: ChangeEffects;
  onDelete?: () => void;
}) {
  const { option } = props;

  return (
    <BackgroundPreviewLayout
      onClick={() =>
        props.changeEffects({
          type: option.sourceType,
          source: option.source,
        })
      }
      active={props.active}
      accessory={
        props.onDelete ? (
          <button
            type='button'
            onClick={(e) => {
              e.stopPropagation();
              props.onDelete?.();
            }}
            className={`absolute -right-1 -top-1 btn w-6 h-6 hidden group-hover:flex 
            items-center justify-center text-red-002 
            bg-secondary rounded-lg z-[6]`}
          >
            <DeleteIcon />
          </button>
        ) : null
      }
    >
      {option.sourceType === 'img' ? (
        <img
          src={option.source}
          className='w-full h-full object-cover rounded-xl'
          alt='background'
        />
      ) : (
        <video
          src={option.source}
          className='w-full h-full object-cover rounded-xl'
          playsInline
          autoPlay
          muted
          preload='metadata'
        />
      )}
    </BackgroundPreviewLayout>
  );
}

function useMyCustomBackgrounds() {
  return useSWR(
    '/my/settings/vbg',
    async () => {
      const resp = await apiService.userSettings.getMyVbgSettings();
      return resp.data.vbg?.value?.customBackgrounds;
    },
    { revalidateOnFocus: false }
  );
}

export function VirtualBackgroundSettingsModal(props: {
  onCancel: () => void;
  onConfirm: () => void;
  customizable?: boolean;
}) {
  const deviceAPI = useDeviceAPI();
  const vbgMixer = useMustVirtualBackgroundMixer(deviceAPI.mixer);
  const refFull = useRef<HTMLVideoElement>(null);
  const userStates = useUserStates();
  const { updateVirtualBackgroundEffects, updateMirror } = useUserContext();
  const { control, handleSubmit } = useForm({
    defaultValues: {
      virtualBackgroundEffects: userStates.virtualBackgroundEffects,
      mirror: userStates.mirror,
    },
  });
  const [track, setTrack] = useState(vbgMixer.virtualBackgroundTrack);

  useEffect(() => {
    return vbgMixer.on('virtual-background-track-updated', (t) => setTrack(t));
  }, [vbgMixer]);

  const mediaStream = useMemo(() => {
    const s = new MediaStream();
    if (track) s.addTrack(track);
    return s;
  }, [track]);

  useEffect(() => {
    if (!refFull.current) return;
    refFull.current.srcObject = mediaStream;
  }, [mediaStream]);

  const onCancel = () => {
    vbgMixer.setVirtualBackgroundEffects(userStates.virtualBackgroundEffects);
    props.onCancel();
  };

  const onSave = handleSubmit((data) => {
    updateMirror(data.mirror);
    updateVirtualBackgroundEffects(data.virtualBackgroundEffects);
    props.onConfirm();
  });

  return (
    <Fragment>
      <div className='w-full h-full flex flex-col gap-10 relative'>
        <Controller
          control={control}
          name='mirror'
          render={({ field: { onChange, value } }) => (
            <div className='w-full flex items-center gap-7.5'>
              <div className='flex flex-col gap-2.5 w-2/3'>
                <p className='font-bold'>Full Video Feed</p>
                <div className='relative'>
                  <video
                    ref={refFull}
                    className='w-full rounded-xl object-cover'
                    style={{
                      aspectRatio: '16/9',
                      transform: value ? 'rotateY(180deg)' : undefined,
                    }}
                    playsInline
                    autoPlay
                    muted
                    preload='metadata'
                  />

                  <div
                    className={`z-15 absolute right-1 bottom-1 p-px cursor-pointer ${
                      value ? 'text-white' : 'text-white opacity-80'
                    }`}
                    onClick={() => onChange(!value)}
                  >
                    <FlipIcon />
                  </div>
                </div>{' '}
              </div>
              <div className='w-1/3'>
                <VideoPreviewMini mirror={value} mediaStream={mediaStream} />
              </div>
            </div>
          )}
        />
        <Controller
          control={control}
          name='virtualBackgroundEffects'
          render={({ field: { onChange, value } }) => {
            const onChangeEffects = (
              options: VirtualBackgroundEffects | null
            ) => {
              onChange(options);
              vbgMixer.setVirtualBackgroundEffects(options);
            };
            return (
              <VirtualBackgroudOptions
                value={value}
                onChange={onChangeEffects}
                customizable={props.customizable}
              />
            );
          }}
        />
      </div>
      <div className='w-full flex items-center justify-center gap-2.5 mt-auto'>
        <button
          type='button'
          className='w-40 h-10 btn-secondary'
          onClick={onCancel}
        >
          Cancel
        </button>
        <button
          type='button'
          className='w-40 h-10 btn-primary'
          onClick={onSave}
        >
          Save
        </button>
      </div>
    </Fragment>
  );
}

function Uploader(props: {
  onUploadComplete?: (media: Media) => void;
  isUpdating?: boolean;
}) {
  const onUploadSuccess = (media: Media) => {
    props.onUploadComplete?.(media);
  };
  return (
    <MiniMediaUploader
      customSizeStyle='w-30 h-17'
      customBgStyle='bg-secondary'
      onUploadSuccess={onUploadSuccess}
      noPlayIcon
      customPreview={() => <Loading text='' />}
      hint={<p>{`Res <= 1280x720`}</p>}
    />
  );
}

function mediaToOption(media: Media | null): VBGMediaOption | undefined {
  if (!media || media.type === MediaType.Audio) {
    return;
  }
  return {
    id: media.id,
    type: 'media',
    sourceType: media.type === MediaType.Video ? 'video' : 'img',
    source: media.url,
  };
}

export function VirtualBackgroudOptions(props: {
  value: VirtualBackgroundEffects | null;
  onChange: ChangeEffects;
  customizable?: boolean;
}) {
  const { value, onChange } = props;
  const { data: customBackgrounds, isLoading } = useMyCustomBackgrounds();

  const { trigger: updateSettings, isMutating: isUpdateSettings } =
    useSWRMutation(
      '/my/settings/vbg',
      async (_, { arg }: { arg: VirtualBackgroundSettings }) =>
        await apiService.userSettings.updateMyVbgSettings(arg)
    );

  const options = useMemo(() => {
    const opts = [...STATIC_VBG_OPTIONS];
    if (!customBackgrounds) return opts;
    for (const bg of customBackgrounds) {
      const option = mediaToOption(fromMediaDTO(bg.media));
      if (option) opts.push(option);
    }
    return opts;
  }, [customBackgrounds]);

  const addCustomBackground = async (media: Media) => {
    const updates = (customBackgrounds ?? []).map((bg) => ({ data: bg.data }));
    updates.push({ data: { id: media.id } });
    await updateSettings({ customBackgrounds: updates });
  };

  const deleteCustomBackground = async (mediaId: string) => {
    const updates = (customBackgrounds ?? [])
      .filter((bg) => bg.data?.id !== mediaId)
      .map((bg) => ({ data: bg.data }));
    await updateSettings({ customBackgrounds: updates });
  };

  return (
    <div className='w-full text-white'>
      <p className='font-bold mb-3.5'>Virtual Backgrounds</p>
      {isLoading ? (
        <div className='flex items-center justify-start'>
          <Loading />
        </div>
      ) : (
        <div className='flex items-center gap-2 flex-wrap'>
          {options.map((option, index) =>
            match(option)
              .when(
                (o) => o.type === 'none',
                (o) => (
                  <NoneBackground
                    key={index}
                    option={o}
                    changeEffects={onChange}
                    active={!value}
                  />
                )
              )
              .when(
                (o) => o.type === 'color',
                (o) => (
                  <ColorBackground
                    key={index}
                    option={{
                      type: 'color',
                      color:
                        value?.type === 'color'
                          ? value.color ?? o.color
                          : o.color,
                    }}
                    changeEffects={onChange}
                    active={value?.type === 'color'}
                  />
                )
              )
              .when(
                (o) => o.type === 'blur',
                (o) => (
                  <BlurBackground
                    key={index}
                    option={o}
                    changeEffects={onChange}
                    active={value?.type === 'blur'}
                  />
                )
              )
              .when(
                (o) => o.type === 'media',
                (o) => (
                  <MediaBackground
                    key={index}
                    option={o}
                    changeEffects={onChange}
                    active={value?.source === o.source}
                    onDelete={
                      o.id
                        ? async () => {
                            await deleteCustomBackground(o.id ?? '');
                            if (value?.source === o.source) {
                              onChange(null);
                            }
                          }
                        : undefined
                    }
                  />
                )
              )
              .otherwise(() => null)
          )}
          {props.customizable && (
            <Uploader
              key={customBackgrounds?.length ?? 0}
              onUploadComplete={async (m) => {
                await addCustomBackground(m);
                const o = mediaToOption(m);
                if (o)
                  onChange({
                    type: o.sourceType,
                    source: o.source,
                  });
              }}
              isUpdating={isUpdateSettings}
            />
          )}
        </div>
      )}
    </div>
  );
}

export function VBGSettingsButton(props: { className?: string }) {
  const triggerModal = useAwaitFullScreenConfirmCancelModal();
  const openVirtualBackgroundSettings = () => {
    triggerModal({
      kind: 'custom',
      element: (p) => (
        <Modal
          borderStyle='gray'
          bgStyle='transparent'
          modalBgStyle='transparent'
        >
          <div
            className={`
            flex flex-col items-center gap-4 bg-modal rounded-xl
            text-white relative p-5 w-174 h-full min-h-136
          `}
          >
            <VirtualBackgroundSettingsModal
              onCancel={p.internalOnCancel}
              onConfirm={p.internalOnConfirm}
              customizable
            />
          </div>
        </Modal>
      ),
    });
  };
  return (
    <button
      type='button'
      className={props.className}
      onClick={() => openVirtualBackgroundSettings()}
    >
      VBG Settings
    </button>
  );
}
