import React, { useContext, useMemo } from 'react';
import { proxy } from 'valtio';

import {
  type Block,
  type BlockFields,
  type BlockMedia,
  type BlockMediaFields,
} from '@lp-lib/game';
import { type Media, type MediaData } from '@lp-lib/media';

import { apiService } from '../../services/api-service';
import { uncheckedIndexAccess_UNSAFE } from '../../utils/uncheckedIndexAccess_UNSAFE';
import {
  markSnapshottable,
  type ValtioSnapshottable,
} from '../../utils/valtio';

export type BlockEditorState = {
  blocks: Block[];
};

export class BlockEditorStore {
  private _state;
  constructor() {
    this._state = markSnapshottable(
      proxy<BlockEditorState>(this.initialState())
    );
  }

  get state(): Readonly<ValtioSnapshottable<BlockEditorState>> {
    return this._state;
  }

  private initialState(): BlockEditorState {
    return {
      blocks: [],
    };
  }

  setBlocks(src: Block[] | Block) {
    if (Array.isArray(src)) {
      this._state.blocks = src;
    } else {
      this._state.blocks = [src];
    }
  }

  getBlock<T extends Block = Block>(blockId: string): T | undefined {
    return this._state.blocks.find((b) => b.id === blockId) as T | undefined;
  }

  setBlockField(payload: {
    blockId: string;
    blockField: Partial<BlockFields>;
  }) {
    const { blockField, blockId } = payload;
    const key = Object.keys(blockField)[0];
    const block = this._state.blocks.find((b) => b.id === blockId);
    if (!block) return;
    if (
      uncheckedIndexAccess_UNSAFE(block.fields)[key] ===
      uncheckedIndexAccess_UNSAFE(blockField)[key]
    )
      return;
    // Note(drew): this Record<string, number> is necessary because the
    // product/union of all fields produces an invalid type, such as a
    // type where a property is both `text: string` and `text?: string`.
    // There's no combination of all the fields that can produce a
    // compatible type. Previously this worked because the
    // ScoreboardBlock['fields'] was `Record<string, unknown>` which
    // functioned as an accidental catch-all. This entire "gameSlice"
    // should be rewritten and removed to avoid these issues.
    (block.fields as Record<string, unknown>) = {
      ...block.fields,
      ...blockField,
    };
  }

  async updateEditingBlockField<
    B extends Block,
    T extends keyof BlockFields<B> = keyof BlockFields<B>
  >(blockId: string, field: T, value: BlockFields<B>[T]) {
    if (value === null || value === undefined) return;

    const blockField = (
      await apiService.block.updateField<B, typeof field>(blockId, {
        field,
        value,
      })
    ).data;

    this.setBlockField({
      blockId,
      blockField,
    });
  }

  // the remote first approach can cause the race condition issue when updating
  // the collection data.
  // 1. input A blur -> read snapshot -> build collection -> update remote
  // 2. when input B blur, read snapshot again, because of the remote call A is
  //    not complete yet, the local store is still old, so the new collection
  //    is outdated.
  async updateEditingBlockFieldLocalFirst<
    B extends Block,
    T extends keyof BlockFields<B> = keyof BlockFields<B>
  >(blockId: string, field: T, value: BlockFields<B>[T]) {
    this.setBlockField({
      blockId,
      blockField: { [field]: value },
    });

    await apiService.block.updateField<B, typeof field>(blockId, {
      field,
      value,
    });
  }

  async updateBlockMedia<B extends BlockMedia>(
    blockId: string,
    field: keyof BlockMediaFields<B>,
    media: Media | null
  ) {
    try {
      // TODO(jialin): type guard
      apiService.block.updateField(blockId, {
        field: `${String(field)}Id` as never,
        value: (media?.id || null) as never,
      });
      this.setBlockField({
        blockId,
        blockField: {
          [field]: media,
          [`${String(field)}Id`]: media?.id || null,
        },
      });
    } catch (err) {
      // TODO: (RGS)
      console.error(err);
    }
  }

  async updateBlockMediaV2<B extends BlockMedia>(
    blockId: string,
    field: keyof BlockMediaFields<B>,
    mediaData: MediaData | null,
    media: Media | null
  ) {
    try {
      apiService.block.updateField(blockId, {
        field: `${String(field)}Data` as never,
        value: mediaData as never,
      });
      this.setBlockField({
        blockId,
        blockField: {
          [field]: media,
          [`${String(field)}Data`]: mediaData,
        },
      });
    } catch (err) {
      console.error(err);
    }
  }

  nextBlockId(blockId: string): string | null {
    const idx = this._state.blocks.findIndex((b) => b.id === blockId);
    if (idx === -1) return null;
    return this._state.blocks[idx + 1]?.id || null;
  }

  prevBlockId(blockId: string): string | null {
    const idx = this._state.blocks.findIndex((b) => b.id === blockId);
    if (idx === -1) return null;
    return this._state.blocks[idx - 1]?.id || null;
  }
}

type Context = {
  store: BlockEditorStore;
};

const context = React.createContext<Context | null>(null);

export function BlockEditorStoreProvider(props: {
  store: BlockEditorStore;
  children: React.ReactNode;
}): JSX.Element {
  const ctxValue = useMemo(() => ({ store: props.store }), [props.store]);
  return <context.Provider value={ctxValue}>{props.children}</context.Provider>;
}

function useBlockEditorStoreContext(): Context {
  const ctx = useContext(context);
  if (!ctx) throw new Error('BlockEditorStore is not in the tree!');
  return ctx;
}

export function useBlockEditorStore() {
  return useBlockEditorStoreContext().store;
}
