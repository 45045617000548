import { type DtoChannel } from '@lp-lib/api-service-client/public';

import { type ISO8601String } from './api';
import { type GamePack } from './game';
import { type MessageTemplate } from './messageTemplate';
import {
  type Organization,
  type Organizer,
  OrganizerUtils,
} from './organization';
import { type SessionTrack } from './session';
import { type SlackUser } from './slack';

export enum GlobalPairingRoundStatus {
  New = 'New',
  Configuring = 'Configuring',
  Scheduled = 'Scheduled',
  InProgress = 'InProgress',
  Completed = 'Completed',
}

export enum ParticipationMode {
  None = 'none',
  OptInOnly = 'opt_in_only',
  OptInAndOut = 'opt_in_and_out',
}

export interface PublicGlobalPairingRound {
  id: string;
  mainGamePackId: string;
  shadowGamePackIds: string[];
  messageCampaignId: string;
  status: GlobalPairingRoundStatus;
  startedAt: string;
  endedAt: string;
  createdAt: string;
  updatedAt: string;
}

type GlobalRoundConfig = {
  participationMode: ParticipationMode;
  asLevels: boolean;
};

export interface GlobalPairingRound extends PublicGlobalPairingRound {
  orgsCount: number;
  playedOrgsCount: number;
  pairingsCount: number;
  completedPairingsCount: number;
  playersCount: number;
  completedPlayersCount: number;
  config: GlobalRoundConfig | null;
  mainGamePack: GamePack | null;
  shadowGamePacks: GamePack[] | null;
}

export enum PairingRoundStatus {
  Active = 'Active',
  Paused = 'Paused',
}

export interface PairingRound {
  id: string;
  globalRoundId: string;
  orgId: string;
  status: PairingRoundStatus;

  playersCount: number;
  completedPlayersCount: number;
  pairingsCount: number;
  completedPairingsCount: number;
  orgScore: number | null;
  lastSessionPlayedAt: string;

  createdAt: string;
  updatedAt: string;

  startedAt: string;
  endedAt: string;
  mainGamePack: GamePack | null;
  organization: Organization | null;
  globalRound: GlobalPairingRound | null;
  channel: DtoChannel | null;
}

export class PairingRoundUtils {
  static GetLeaderboardURL(round: PairingRound): string {
    return `${window.location.origin}/leaderboards/${round.id}`;
  }
}

export interface Pairing {
  id: string;
  roundId: string;
  organizerUid: string;
  teamId: string;
  teamName: string | null;
  score: number | null;
  isGameCompleted: boolean;
  createdAt: string;
  updatedAt: string;

  round: PairingRound | null;
  players: PairingPlayer[] | null;
  mainSession: SessionTrack | null;
  additionalSessions: SessionTrack[] | null;
  rank: number | null;
  lastPlayedAt: string | null;
}

export interface LeaderboardTeam {
  pairingId: string;
  roundId: string;
  orgId: string;
  sessionId: string | null;
  teamName: string;
  orgName: string;
  score: number | null;
  rank: number | null;
  players: LeaderboardTeamPlayer[] | null;
}

export class PairingUtils {
  static GetOrganizer(pairing: Pairing): PairingPlayer | null {
    return pairing.players?.find((p) => p.uid === pairing.organizerUid) || null;
  }
  static GetGameURL(pairing: Pairing): string {
    return `${window.location.origin}/pairings/${pairing.id}`;
  }
  static ContainsPlayer(pairing: Pairing, uid: string): boolean {
    return !!pairing.players?.find((p) => p.uid === uid);
  }
  static GetLastPlayed(pairing: Pairing): Date | null {
    return pairing.lastPlayedAt ? new Date(pairing.lastPlayedAt) : null;
  }
}

export interface PairingPlayer {
  pairingId: string;
  uid: string;
  roundId: string;
  sessionId: string | null;
  isGameCompleted: boolean;
  createdAt: string;
  updatedAt: string;

  organizer: Organizer | null;
}

export interface LeaderboardTeamPlayer {
  uid: string;
  displayName: string;
}

export class PairingPlayerUtils {
  static GetDisplayName(player: PairingPlayer | null): string {
    return OrganizerUtils.GetDisplayName(player?.organizer ?? null);
  }
  static IsOrganizer(player: PairingPlayer, Pairing: Pairing): boolean {
    return player.uid === Pairing.organizerUid;
  }
}

export interface recipient {
  email: string;
  uid: string;
  name: string;
}
export interface recipientData {
  mode?: 'All' | 'Users';
  recipients?: recipient[];
}

export interface MessageMedia {
  mediaUrl: string;
  title: string;
  thumbnailUrl: string;
  type: 'image' | 'video';
}

export interface MessageContent {
  text: string;
  mediaList: MessageMedia[];
}

export enum MessageStatus {
  Pending = 'Pending',
  Success = 'Success',
  Error = 'Error',
  Deleted = 'Deleted',
}

export interface Message {
  id: string;
  parentId: string;
  orgId: string;
  targetType: string;
  recipientData: recipientData;
  templateId: string;
  body: string;
  content: MessageContent;
  createdAt: string;
  updatedAt: string;
  exMessageId: string;
  exGroupId: string;
  status: MessageStatus;
  organization: Organization | null;
  messageTemplate: MessageTemplate | null;
}

export interface PairingGame {
  pairingId: string;
  roundId: string;
  venueId: string;
  venueName: string | null | undefined;
  teamId: string;
  teamName: string;
  isGameCompleted: boolean;
  completedGamePackIds: string[] | null | undefined;
  gamePacks: GamePack[];
  mainGamePack: GamePack;
  nextGamePack: GamePack;
  asLevels: boolean;
  score: number | null | undefined;
}

export class PairingGameUtils {
  static GetAllGamePackIds(p: PairingGame): string[] {
    return p.gamePacks?.map((gp) => gp.id) || [];
  }
}

export type PairingRepairLog = {
  id: string;
  roundId: string;
  globalRoundId: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any;
  createdAt: ISO8601String;
};

export type PairingParticipationStats = {
  optedIn: number;
  optedOut: number;
};

export type PairingParticipation = {
  id: string;
  roundId: string;
  globalRoundId: string;
  exUserId: string;
  status: 'opted_out' | 'opted_in' | 'unknown';
  mode: ParticipationMode;
  suggestions: Nullable<{
    exUserIds: string[];
  }>;
  createdAt: ISO8601String;
  updatedAt: ISO8601String;
  organizer: Nullable<Organizer>;
  suggestedUsers: Nullable<SlackUser[]>;
};
