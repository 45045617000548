import { useEffect, useRef } from 'react';

import { getFeatureQueryParam } from '../../../hooks/useFeatureQueryParam';
import { rsCounter } from '../../../utils/rstats.client';
import { useInfrequentAnimationFrame } from '../useInfrequentAnimationFrame';
import { useFPSContext } from './Context';
import { type FPSContext, type RenderTarget } from './types';

const renderingEnabled = getFeatureQueryParam('crowd-frames-avatar-rendering');
const infrequentRenderingEnabled = getFeatureQueryParam(
  'crowd-frames-infrequent-rendering'
);

function consumeFrame(fpsCtx: FPSContext, target: RenderTarget, delta: number) {
  const strip = target.filmstrips.length
    ? target.filmstrips[0]
    : fpsCtx.getMostRecentFilmstripForAddress(target.profileAddress);
  if (!strip) return;

  const timePerFrame = strip.profile.delayMs;
  strip.elapsedSinceLastRender += delta;

  // immediatelyRender overrides all other early out logic.
  if (
    !target.immediatelyRender &&
    (strip.elapsedSinceLastRender < timePerFrame || !target.inView)
  ) {
    return;
  }

  // Always cache the most recently rendered filmstrip to provide it
  // immediately if the target comes into view again, before a new strip
  // has arrived from the server.
  fpsCtx.setMostRecentFilmstripForAddress(target.profileAddress, strip);

  // A target is only allowed to immediately render once. This is an
  // optimization to hopefully immediately show a frame (assuming it
  // exists) on first display of an FPS target (such as switching to the
  // All Teams tab).
  if (target.immediatelyRender) {
    target.immediatelyRender = false;
  }

  strip.elapsedSinceLastRender = 0;

  target.ctx.drawImage(
    strip.filmstrip,
    0,
    strip.frameIndex * strip.profile.height,
    strip.profile.width,
    strip.profile.height,
    0,
    0,
    target.cvsWidth,
    target.cvsHeight
  );

  strip.frameIndex += 1;

  if (
    target.filmstrips.length > 1 &&
    strip.frameIndex >= strip.profile.perStrip
  ) {
    // this strip has been used up!
    target.filmstrips.shift();
  } else if (strip.frameIndex >= strip.profile.perStrip) {
    // set index to last frame to just keep rerending the last frame until
    // we get another filmstrip.
    strip.frameIndex = strip.profile.perStrip - 1;
  }
}

function useInfrequentFPSRender(enabled: boolean) {
  const fpsCtx = useFPSContext();
  const prevTimeRef = useRef(0);
  const minDelta = 1000 / 16;
  useInfrequentAnimationFrame(
    function tick(now) {
      const delta = now - prevTimeRef.current;
      prevTimeRef.current = now;
      rsCounter('crowd-frame-anim-consumed-ms')?.start();
      fpsCtx.targets.forEach((target) => consumeFrame(fpsCtx, target, delta));
      rsCounter('crowd-frame-anim-consumed-ms')?.end();
    },
    minDelta,
    !enabled
  );
}

function useAccurateFPSRender(enabled: boolean) {
  const fpsCtx = useFPSContext();
  // This component is responsible for all fps animations, globally.
  useEffect(() => {
    let prevTickTime = Date.now();

    let animRef: number;

    const scheduleNextTick = () => {
      animRef = requestAnimationFrame(tick);
    };

    const prepareForNextFrame = () => {
      prevTickTime = Date.now();
    };

    function tick() {
      const now = Date.now();
      const delta = now - prevTickTime;

      prevTickTime = now;

      rsCounter('crowd-frame-anim-consumed-ms')?.start();
      fpsCtx.targets.forEach((target) => consumeFrame(fpsCtx, target, delta));
      rsCounter('crowd-frame-anim-consumed-ms')?.end();
      rsCounter('crowd-frame-anim-targets-c')?.set(fpsCtx.targets.size);

      prepareForNextFrame();
      return scheduleNextTick();
    }

    if (enabled) scheduleNextTick();

    return () => {
      cancelAnimationFrame(animRef);
    };
  }, [enabled, fpsCtx, fpsCtx.targets]);
}

export function FPSAnimationMachine(): null {
  useInfrequentFPSRender(renderingEnabled && infrequentRenderingEnabled);
  useAccurateFPSRender(renderingEnabled && !infrequentRenderingEnabled);
  return null;
}
