import pluralize from 'pluralize';
import { useEffect, useMemo, useState } from 'react';

import {
  type TeamRelayBlock,
  TeamRelayBlockGameSessionStatus,
} from '@lp-lib/game';

import type { TeamId } from '../../../../types';
import { assertExhaustive } from '../../../../utils/common';
import { DoubleRightArrow } from '../../../icons/Arrows';
import { PlayIcon } from '../../../icons/PlayIcon';
import { RefreshIcon } from '../../../icons/RefreshIcon';
import { FilledSquareIcon } from '../../../icons/SquareIcon';
import { TimerIcon } from '../../../icons/TimerIcon';
import {
  useDetailScores,
  useGameSessionLocalTimer,
  useGameSessionStatus,
  useIsEndedBlock,
} from '../../hooks';
import {
  countdownV2,
  type EditPointData,
  next,
  present,
  triggerBlockTitleAnim,
} from '../../store';
import {
  BlockControllerActionButton,
  BlockControllerActionNone,
  BlockControllerActionTimeRemaining,
  ControllerLayout,
  type ControllerProps,
  DecreasingPointsBadge,
  type MediaPlayback,
  MediaPreviewer,
  ResultView,
  SubmissionsActionBox,
  useBlockControllerBlockTitlePlaying,
  useControllerMediaPlayback,
  useControllerMediaPlayText,
} from '../Common/Controller/Internal';
import { useRankedTeamScores, useStableBlock } from '../Common/hooks';
import { BlockKnifeUtils } from '../Shared';
import {
  useDerivedTeamProgressMap,
  useResolveTeamRelayLevel,
  useTeamRelayGameSettings,
  useTeamRelayTotalGameTime,
} from './Context';

type SharedProps = ControllerProps<TeamRelayBlock>;
type StageProps = ControllerProps<TeamRelayBlock> & { playback: MediaPlayback };

export function TeamRelayBlockTeamProgressSummary(props: {
  block: TeamRelayBlock;
  teamId: TeamId;
}) {
  const teamProgressMap = useDerivedTeamProgressMap('counter');
  const settings = useTeamRelayGameSettings();

  const [numFinished, total] = useMemo(() => {
    const numFinished = teamProgressMap[props.teamId];
    return [numFinished ?? 0, settings?.level.configs.length || 0];
  }, [teamProgressMap, props.teamId, settings]);

  return (
    <>
      Progress: {numFinished}/{total} completed
    </>
  );
}

function GameProgress(
  props: StageProps & { revealResults?: boolean; hideProgressDetail?: boolean }
): JSX.Element {
  const { selectedBlock: block, setEditPointsData, hideProgressDetail } = props;
  const teamDetailScoreMap = useDetailScores(props.selectedBlock.id);
  const teamScores = useRankedTeamScores('teamName', teamDetailScoreMap);
  const teamProgressMap = useDerivedTeamProgressMap('counter');
  const settings = useTeamRelayGameSettings();
  const numOfSequences = settings?.level.configs.length || 0;

  return (
    <div className='relative w-full h-full flex flex-col justify-start items-center'>
      <div className='w-full h-10 px-2.5 py-1 leading-4 bg-black text-white text-2xs font-medium line-clamp-2'>
        Prompt: {block.fields.text || 'N/A'}
      </div>
      <div className='w-full mb-2.5 pt-1 px-2.5 max-h-64 scrollbar-hide overflow-y-scroll'>
        {teamScores.map((score) => {
          const editPointData: EditPointData = {
            teamName: score.team.name,
            teamId: score.team.id,
            submitterUid: '',
            defaultPoints: score.currentScore,
          };
          const sequenceFinished = teamProgressMap[score.team.id];
          return (
            <SubmissionsActionBox
              key={score.team.id}
              teamId={score.team.id}
              view={props.revealResults ? 'result' : undefined}
              editPointData={editPointData}
              setEditPointsData={setEditPointsData}
            >
              <div className='w-4/5 h-full pl-2'>
                <p
                  className={`font-bold ${
                    hideProgressDetail
                      ? 'h-full flex items-center'
                      : 'h-1/3 pt-1'
                  }`}
                >
                  {score.team.name}
                </p>
                {!hideProgressDetail && (
                  <div
                    className={`w-full h-2/3 text-xs font-medium text-icon-gray flex flex-col justify-center`}
                  >
                    <p className='w-full line-clamp-2 hyphens-auto'>
                      {sequenceFinished
                        ? `${
                            sequenceFinished === numOfSequences
                              ? 'All'
                              : sequenceFinished
                          } ${pluralize('Sequence', sequenceFinished)} Complete`
                        : '[Waiting for Player Progress...]'}
                    </p>
                  </div>
                )}
              </div>
              <div className='w-1/5 h-full bg-secondary border-l border-secondary flex flex-col justify-center items-center'>
                {props.revealResults ? (
                  <ResultView
                    totalScore={score.totalScore}
                    previewPoints={score.currentScore}
                  />
                ) : (
                  <div className='w-8 h-7 text-white font-bold text-2xs text-center flex flex-col justify-center'>
                    <span>+{score.currentScore}</span>
                    <span className='text-3xs'>Points</span>
                  </div>
                )}
              </div>
            </SubmissionsActionBox>
          );
        })}
      </div>
    </div>
  );
}

function Badges(props: {
  block: TeamRelayBlock;
  disabled?: boolean;
}): JSX.Element {
  return (
    <div className='w-full px-2.5 flex flex-row items-center justify-start self-start'>
      <DecreasingPointsBadge
        decreasingPointsTimer={props.block.fields.decreasingPointsTimer}
        disabled={props.disabled}
      />
    </div>
  );
}

function Loaded(props: StageProps): JSX.Element {
  const { selectedBlock: block, playback } = props;

  const { switchMedia, reset: resetPlayback } = playback.api;

  useEffect(() => {
    resetPlayback();
    const media = BlockKnifeUtils.Media(block, 'introMedia');
    switchMedia(media);
  }, [block, switchMedia, resetPlayback]);

  const onPresent = async () => {
    await triggerBlockTitleAnim(block);
    await present(block);
  };

  const actionComponent = useBlockControllerBlockTitlePlaying(block) ?? (
    <BlockControllerActionButton onClick={onPresent} icon={PlayIcon}>
      {playback.preview ? 'Present Intro Media' : 'Present Game'}
    </BlockControllerActionButton>
  );
  return (
    <ControllerLayout action={actionComponent}>
      <div className='w-full flex flex-col'>
        <Badges block={block} />
        <MediaPreviewer
          playback={playback}
          alt={block.type}
          text={block.fields.text}
        />
      </div>
    </ControllerLayout>
  );
}

function Intro(props: StageProps): JSX.Element | null {
  const { selectedBlock: block, playback } = props;
  const mediaActionText = useControllerMediaPlayText(playback);
  const [presentIntro, setPresentIntro] = useState(!!playback.preview);
  const { play: playMedia } = playback.api;

  useEffect(() => {
    if (!playback.isVideoPlayed) return;
    setPresentIntro(false);
  }, [playback.isVideoPlayed]);

  useEffect(() => {
    playMedia();
  }, [playMedia]);

  useEffect(() => {
    if (presentIntro) return;
    next();
  }, [presentIntro]);

  if (presentIntro) {
    return (
      <ControllerLayout
        action={
          <BlockControllerActionNone icon={PlayIcon}>
            {mediaActionText}
          </BlockControllerActionNone>
        }
      >
        <div className='w-full flex flex-col'>
          <Badges block={block} disabled />
          <MediaPreviewer
            playback={playback}
            alt={block.type}
            text={block.fields.text}
          />
        </div>
      </ControllerLayout>
    );
  }
  return null;
}

function GameInit(props: StageProps): JSX.Element {
  const { selectedBlock: block, playback } = props;

  const { switchMedia } = playback.api;
  const level = useResolveTeamRelayLevel(block);
  const totalGameTime = useTeamRelayTotalGameTime();
  const [processing, setProcessing] = useState(false);

  useEffect(() => {
    switchMedia(BlockKnifeUtils.Media(block, 'backgroundMedia'));
  }, [switchMedia, block]);

  const onStartGame = async () => {
    setProcessing(true);
    await countdownV2({
      debug: 'TeamReplayBlockGameControl',
      startTimeWorker: true,
      flushCountingStatus: true,
      triggerTimesup: true,
    });
  };

  return (
    <ControllerLayout
      action={
        <BlockControllerActionButton
          onClick={onStartGame}
          icon={TimerIcon}
          disable={totalGameTime === 0 || processing || level === null}
        >
          {`Start Game Timer (${totalGameTime}s)`}
        </BlockControllerActionButton>
      }
    >
      <div className='w-full flex flex-col'>
        <Badges block={block} disabled />
        <MediaPreviewer
          playback={playback}
          alt={block.type}
          text={block.fields.text}
        />
      </div>
    </ControllerLayout>
  );
}

function GameStart(props: StageProps): JSX.Element {
  const time = useGameSessionLocalTimer();

  return (
    <ControllerLayout
      action={<BlockControllerActionTimeRemaining remainingSec={time} />}
    >
      <GameProgress {...props} />
    </ControllerLayout>
  );
}

function GameEnd(props: StageProps): JSX.Element {
  const [processing, setProcessing] = useState(false);

  const onPresentOutro = async () => {
    setProcessing(true);
    await next();
  };
  return (
    <ControllerLayout
      action={
        <BlockControllerActionButton
          onClick={onPresentOutro}
          icon={DoubleRightArrow}
          disable={processing}
        >
          Reveal Results
        </BlockControllerActionButton>
      }
    >
      <GameProgress {...props} revealResults />
    </ControllerLayout>
  );
}

function Outro(props: StageProps): JSX.Element | null {
  const { selectedBlock: block, playback } = props;
  const mediaActionText = useControllerMediaPlayText(playback);
  const { switchMedia, play: playMedia } = playback.api;
  useEffect(() => {
    if (!playback.isVideoPlayed) return;
    next();
  }, [playback.isVideoPlayed]);

  useEffect(() => {
    const media = BlockKnifeUtils.Media(block, 'outroMedia');
    if (media) {
      switchMedia(media, () => playMedia());
    } else {
      next();
    }
  }, [block, switchMedia, playMedia]);

  if (playback.preview) {
    return (
      <ControllerLayout
        action={
          <BlockControllerActionNone icon={PlayIcon}>
            {mediaActionText}
          </BlockControllerActionNone>
        }
      >
        <GameProgress {...props} revealResults />
      </ControllerLayout>
    );
  }
  return null;
}

function Results(props: StageProps): JSX.Element {
  const onShowScoreboard = async () => {
    await next();
  };

  return (
    <ControllerLayout
      action={
        <>
          <BlockControllerActionButton
            onClick={onShowScoreboard}
            isSecondary={true}
          >
            Show Scoreboard
          </BlockControllerActionButton>
          <BlockControllerActionButton
            onClick={props.onEndBlock}
            icon={FilledSquareIcon}
          >
            End Block Sequence
          </BlockControllerActionButton>
        </>
      }
    >
      <GameProgress {...props} revealResults />
    </ControllerLayout>
  );
}

function Scoreboard(props: StageProps): JSX.Element {
  return (
    <ControllerLayout
      action={
        <BlockControllerActionButton
          onClick={props.onEndBlock}
          icon={FilledSquareIcon}
        >
          End Block Sequence
        </BlockControllerActionButton>
      }
    >
      <GameProgress {...props} revealResults />
    </ControllerLayout>
  );
}

function Ended(props: StageProps): JSX.Element {
  return (
    <ControllerLayout
      action={
        <BlockControllerActionButton
          onClick={() => props.setShowResetConfirmation(true)}
          icon={RefreshIcon}
          isDelete={true}
        >
          Reset Completed Block
        </BlockControllerActionButton>
      }
    >
      <GameProgress {...props} hideProgressDetail revealResults />
    </ControllerLayout>
  );
}

export function TeamRelayBlockController(
  props: SharedProps
): JSX.Element | null {
  const { selectedBlockIndex } = props;
  const block = useStableBlock(props.selectedBlock);
  const isEndedBlock = useIsEndedBlock(block.id);
  const gameSessionStatus =
    useGameSessionStatus<TeamRelayBlockGameSessionStatus>();
  const playback = useControllerMediaPlayback();

  if (selectedBlockIndex === null) return null;

  if (isEndedBlock) {
    return <Ended {...props} playback={playback} />;
  }

  switch (gameSessionStatus) {
    case TeamRelayBlockGameSessionStatus.LOADED:
      return <Loaded {...props} playback={playback} />;
    case TeamRelayBlockGameSessionStatus.INTRO:
      return <Intro {...props} playback={playback} />;
    case TeamRelayBlockGameSessionStatus.GAME_INIT:
      return <GameInit {...props} playback={playback} />;
    case TeamRelayBlockGameSessionStatus.GAME_START:
      return <GameStart {...props} playback={playback} />;
    case TeamRelayBlockGameSessionStatus.GAME_END:
      return <GameEnd {...props} playback={playback} />;
    case TeamRelayBlockGameSessionStatus.OUTRO:
      return <Outro {...props} playback={playback} />;
    case TeamRelayBlockGameSessionStatus.RESULTS:
      return <Results {...props} playback={playback} />;
    case TeamRelayBlockGameSessionStatus.SCOREBOARD:
      return <Scoreboard {...props} playback={playback} />;
    case TeamRelayBlockGameSessionStatus.END:
    case null:
    case undefined:
      break;
    default:
      assertExhaustive(gameSessionStatus);
      break;
  }

  return null;
}
