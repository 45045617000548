const DownloadIcon = (props: { className?: string }): JSX.Element => {
  return (
    <svg
      className={props.className || 'w-5 h-5 fill-current'}
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 20 20'
    >
      <path
        fillRule='evenodd'
        d='M11.059 9.962V3.836a.979.979 0 10-1.958 0v6.126H7.563a.2.2 0 00-.155.326L9.77 13.19a.4.4 0 00.62 0l2.361-2.902a.2.2 0 00-.155-.326H11.06zm6.084 1.395a.928.928 0 10-1.857 0v4.357H4.714v-4.357a.929.929 0 00-1.857 0V16.643c0 .512.416.928.929.928h12.428a.929.929 0 00.929-.928v-5.286z'
        clipRule='evenodd'
      ></path>
    </svg>
  );
};

export { DownloadIcon };
