import React from 'react';

export function GreenCheckIcon(
  props: React.SVGProps<SVGSVGElement>
): JSX.Element {
  return (
    <svg
      className={props.className}
      xmlns='http://www.w3.org/2000/svg'
      width='60'
      height='60'
      fill='none'
      viewBox='0 0 60 60'
    >
      <path
        fill='#39D966'
        fillRule='evenodd'
        d='M50.136 13.792a3.776 3.776 0 01.356 5.332L25.956 47.143l-16.08-13.91a3.776 3.776 0 01-.383-5.33 3.784 3.784 0 015.334-.384l10.391 8.99L44.8 14.147a3.784 3.784 0 015.336-.355z'
        clipRule='evenodd'
      ></path>
    </svg>
  );
}
