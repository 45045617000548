import {
  assertExhaustive,
  type JeopardyBlock,
  JeopardyBlockGameSessionStatus,
} from '@lp-lib/game';

import { PlayIcon } from '../../../icons/PlayIcon';
import { RefreshIcon } from '../../../icons/RefreshIcon';
import { FilledSquareIcon } from '../../../icons/SquareIcon';
import { useGameSessionStatus, useIsEndedBlock } from '../../hooks';
import { present, triggerBlockTitleAnim } from '../../store';
import {
  BlockControllerActionButton,
  ControllerLayout,
  type ControllerProps,
  useBlockControllerBlockTitlePlaying,
} from '../Common/Controller/Internal';
import { useStableBlock } from '../Common/hooks';

type SharedProps = ControllerProps<JeopardyBlock>;

function Loaded(props: SharedProps) {
  const { selectedBlock: block } = props;

  const onPresent = async () => {
    await triggerBlockTitleAnim(block);
    await present(block);
  };

  const actionComponent = useBlockControllerBlockTitlePlaying(block) ?? (
    <BlockControllerActionButton onClick={onPresent} icon={PlayIcon}>
      Present Block
    </BlockControllerActionButton>
  );

  return (
    <ControllerLayout action={actionComponent}>
      <div />
    </ControllerLayout>
  );
}

function Presenting(props: SharedProps) {
  const { selectedBlock: block } = props;

  const actionComponent = useBlockControllerBlockTitlePlaying(block) ?? (
    <BlockControllerActionButton
      onClick={props.onEndBlock}
      icon={FilledSquareIcon}
    >
      End Block
    </BlockControllerActionButton>
  );
  return (
    <ControllerLayout action={actionComponent}>
      <div />
    </ControllerLayout>
  );
}

function Ended(props: SharedProps) {
  return (
    <ControllerLayout
      action={
        <BlockControllerActionButton
          onClick={() => props.setShowResetConfirmation(true)}
          icon={RefreshIcon}
          isDelete={true}
        >
          Reset Completed Block
        </BlockControllerActionButton>
      }
    />
  );
}

// note(falcon): this block is not supported in live!
export function JeopardyBlockController(
  props: SharedProps
): JSX.Element | null {
  const { selectedBlockIndex } = props;
  const block = useStableBlock(props.selectedBlock);
  const isEndedBlock = useIsEndedBlock(block.id);
  const gss = useGameSessionStatus<JeopardyBlockGameSessionStatus>();

  if (selectedBlockIndex === null) return null;

  if (isEndedBlock) {
    return <Ended {...props} />;
  }

  switch (gss) {
    case JeopardyBlockGameSessionStatus.LOADED:
      return <Loaded {...props} />;
    case JeopardyBlockGameSessionStatus.GAME_INIT:
      return <Presenting {...props} />;
    case JeopardyBlockGameSessionStatus.GAME_START:
    case JeopardyBlockGameSessionStatus.GAME_END:
    case JeopardyBlockGameSessionStatus.RESULTS:
    case JeopardyBlockGameSessionStatus.OUTRO:
    case JeopardyBlockGameSessionStatus.END:
    case null:
    case undefined:
      break;
    default:
      assertExhaustive(gss);
      break;
  }

  return null;
}
