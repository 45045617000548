import useSWR from 'swr';

import { apiService } from '../../../services/api-service';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function useUpcomingGlobalRound(immutable = false) {
  return useSWR(
    '/global-pairing-rounds/upcoming',
    async () => {
      const rounds = (await apiService.pairing.upcomingGlobalRounds()).data
        .globalRounds;
      return rounds[0] ?? null;
    },
    immutable
      ? {
          revalidateIfStale: false,
          revalidateOnFocus: false,
          revalidateOnReconnect: false,
        }
      : undefined
  );
}
