import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLatest } from 'react-use';

import { assertExhaustive } from '@lp-lib/game';

import { useOnDGameAnalytics } from '../../../analytics/useOndGameAnalytics';
import { getFeatureQueryParam } from '../../../hooks/useFeatureQueryParam';
import { useLiveCallback } from '../../../hooks/useLiveCallback';
import { useTaskQueue } from '../../../hooks/useTaskQueue';
import { SessionMode } from '../../../types';
import { backoffSleep } from '../../../utils/backoffSleep';
import { err2s, uuidv4 } from '../../../utils/common';
import {
  useGetStreamSessionId,
  useIsStreamSessionAborted,
  useStreamSessionControlAPI,
} from '../../Session';
import { useTownhallAPI } from '../../Townhall';
import { useBlockLifecycleRulesEvaluator } from '../Blocks/Common/LifecycleRules/BlockLifecycleRulesEvaluator';
import {
  useCreateGameInfoSnapshot,
  useGetOndGameResumePlaybackItemId,
  useGetOndPreparedContext,
  useIsGameSessionController,
  waitForOndPreparedPlayback,
} from '../hooks';
import { usePlaybackInfoWriteExtra } from '../Playback/PlaybackInfoProvider';
import { usePostGameControlAPI } from '../PostGame/Provider';
import { usePreGameControlAPI } from '../PreGame/Provider';
import { useOndGameControlRawAPI } from '../store';
import { useOnDGameControllerSemaphore } from './Provider';
import { log } from './shared';
import { type ControllerKind } from './types';

/**
 * This should only be used in exceptional cases, such as the failure of the
 * controller.
 *
 * This is generally the "synchronization point" between react-state (hooks) and
 * the "raw" API that generally relies on the gameSessionStore.
 */
export function useOndWrappedControlAPI() {
  // NOTE(drew): I've observed that any invalidation of the output API's
  // useCallbacks/useMemo will result in strange behavior. This means all data
  // needs to be mostly static once one of these handles has been used. An
  // example is `useGetOndPreparedContext`. It returns a getter, not the live
  // value. A reactive update would invalidate the callbacks, and this plays
  // havoc with the control API. It usually results in messages timing out.

  const streamSessionAborted = useLatest(useIsStreamSessionAborted());
  const api = useOndGameControlRawAPI();
  const {
    prepare: prepareStream,
    start: startStream,
    resume: resumeStream,
  } = useStreamSessionControlAPI();
  const createGameInfoSnaphot = useCreateGameInfoSnapshot();
  const getStreamSessionId = useGetStreamSessionId();
  const getPreparedContext = useGetOndPreparedContext();
  const getResumePlaybackItemId = useGetOndGameResumePlaybackItemId();
  const writeBlockExtra = usePlaybackInfoWriteExtra();
  const analytics = useOnDGameAnalytics();
  const postGameControlAPI = usePostGameControlAPI();
  const preGameControlAPI = usePreGameControlAPI();
  const townhallAPI = useTownhallAPI();
  const blockLifecycleRulesEvaluator = useBlockLifecycleRulesEvaluator();

  const prepare = useCallback(async () => {
    await prepareStream(uuidv4());
    const executor = await api.prepare(
      await waitForOndPreparedPlayback(),
      createGameInfoSnaphot,
      getStreamSessionId,
      writeBlockExtra,
      analytics,
      townhallAPI,
      blockLifecycleRulesEvaluator
    );
    if (executor) {
      executor().catch((err) => {
        log.error('prepare command failed', err);
      });
    }
  }, [
    prepareStream,
    api,
    createGameInfoSnaphot,
    getStreamSessionId,
    writeBlockExtra,
    analytics,
    townhallAPI,
    blockLifecycleRulesEvaluator,
  ]);

  const start = useCallback(async () => {
    const preparedContext = getPreparedContext();
    if (!preparedContext) return;
    await startStream(SessionMode.OnDemand);
    const firstBlockPromise = await api.start(preparedContext);
    if (firstBlockPromise) {
      firstBlockPromise().catch((err) =>
        log.error('start command failed during firstBlockPromise', err)
      );
    }
  }, [api, getPreparedContext, startStream]);

  const pause = useCallback(async () => {
    await api.pause();
  }, [api]);

  const resume = useCallback(async () => {
    if (streamSessionAborted.current) {
      await resumeStream();
    }
    const playbackDesc = await waitForOndPreparedPlayback();
    const resumePlaybackItemId = getResumePlaybackItemId() ?? undefined;
    await api.resume(
      playbackDesc,
      createGameInfoSnaphot,
      getStreamSessionId,
      writeBlockExtra,
      analytics,
      townhallAPI,
      blockLifecycleRulesEvaluator,
      resumePlaybackItemId
    );
  }, [
    streamSessionAborted,
    getResumePlaybackItemId,
    api,
    createGameInfoSnaphot,
    getStreamSessionId,
    writeBlockExtra,
    analytics,
    townhallAPI,
    blockLifecycleRulesEvaluator,
    resumeStream,
  ]);

  const reset = useCallback(
    async (params?: { force: boolean; retainGamePack?: boolean }) => {
      await Promise.allSettled([
        postGameControlAPI.reset(),
        preGameControlAPI.unpresent(),
        api.reset(params ? params : { force: false, retainGamePack: true }),
      ]);
    },
    [api, postGameControlAPI, preGameControlAPI]
  );

  const skipPreGameHostedTutorial = useCallback(async () => {
    const preparedContext = getPreparedContext();
    if (!preparedContext) return;
    await api.skipPreGameHostedTutorial(preparedContext);
  }, [getPreparedContext, api]);

  return useMemo(
    () => ({
      prepare,
      start,
      pause,
      resume,
      reset,
      skipPreGameHostedTutorial,
    }),
    [prepare, start, pause, resume, reset, skipPreGameHostedTutorial]
  );
}

export function useAcquireOnDGameControl(
  id: string,
  kind: ControllerKind,
  maxAttempts = 1
): boolean {
  const controllerSemaphore = useOnDGameControllerSemaphore();
  const api = useOndWrappedControlAPI();
  const isGameSessionController = useIsGameSessionController();
  const [acquired, setAcquired] = useState(false);
  const { addTask } = useTaskQueue({ shouldProcess: true });

  const acquireOnDGameControl = useLiveCallback(async () => {
    let attempts = 1;
    while (attempts <= maxAttempts) {
      try {
        const signal = await controllerSemaphore.acquire(
          id,
          kind,
          async (message) => {
            switch (message.command) {
              case 'prepareGame':
                await api.prepare();
                break;
              case 'startGame':
                await api.start();
                break;
              case 'pauseGame':
                await api.pause();
                break;
              case 'resumeGame':
                await api.resume();
                break;
              case 'resetGame':
                await api.reset();
                break;
              case 'skipPreGameHostedTutorial':
                await api.skipPreGameHostedTutorial();
                break;
              default:
                assertExhaustive(message.command);
                break;
            }
          },
          {
            pollInterval: 50,
            heartbeat: {
              firebase: true,
              inHouse: !getFeatureQueryParam('cloud-hosting-mock-api-call'),
            },
          }
        );
        return signal;
      } catch (error) {
        log.error('controller acquire failed', err2s(error));
        await backoffSleep(attempts - 1, 1000);
        attempts++;
      }
    }
  });
  useEffect(() => {
    if (!isGameSessionController) return;
    addTask(async function acquire() {
      const signal = await acquireOnDGameControl();
      if (!signal) return;
      setAcquired(true);
      const resp = await signal();
      log.info('signal received', { ...resp });
      if (resp.event === 'taken-over') {
        setAcquired(false);
      }
    });
    return () => {
      // if we receive the _taken-over_ event and it's a cloud controller,
      // it means there is a new binding in the backend with the target venueId,
      // we should not call backend release binding.
      // _controllerSemaphore.release_ will do nothing because it's already done
      // when receiving the event.
      addTask(async function release() {
        // it's no harm to call _release_ here without check if acquired,
        // because it's a no-op if the consumer is not created or the synced
        // controller id doesn't match.
        controllerSemaphore.release();
        setAcquired(false);
      });
    };
  }, [
    acquireOnDGameControl,
    addTask,
    controllerSemaphore,
    isGameSessionController,
    maxAttempts,
  ]);

  return acquired;
}
