import { useLayoutEffect } from 'react';

import { type ProfileAddress } from '@lp-lib/crowd-frames-schema';

import { useFPSContext } from './Context';

export function useRenderlessTarget(profileAddress: ProfileAddress): void {
  const { renderlessTargets, visibilityChange } = useFPSContext();

  useLayoutEffect(() => {
    renderlessTargets.set(
      profileAddress,
      renderlessTargets.get(profileAddress) ?? 0 + 1
    );
    visibilityChange();
    return () => {
      const refCounts = (renderlessTargets.get(profileAddress) ?? 0) - 1;
      if (refCounts <= 0) {
        renderlessTargets.delete(profileAddress);
      } else {
        renderlessTargets.set(profileAddress, refCounts);
      }
      visibilityChange();
    };
  }, [profileAddress, renderlessTargets, visibilityChange]);
}
