import React, { type ReactNode, useCallback, useContext, useMemo } from 'react';

import { RTDBServerValueTIMESTAMP } from '@lp-lib/firebase-typesafe';
import { type SpotlightBlockV2 } from '@lp-lib/game';

import { useIsController } from '../../../../hooks/useMyInstance';
import { type MemberId, type TeamId } from '../../../../types';
import { uuidv4 } from '../../../../utils/common';
import {
  useDatabaseRef,
  useFirebaseBatchWrite,
  useFirebaseValue,
} from '../../../Firebase';
import { useIsStreamSessionAlive } from '../../../Session';
import { useVenueId } from '../../../Venue/VenueProvider';
import { SpotlightBlockV2Utils } from './utils';

export enum SpotlightV2VotingStatus {
  Voting = 1,
  VotingEnd = 2,
  Celebrating = 3,
  CelebratingEnd = 4,
}

interface SpotlightV2ControlAPI {
  initGame: (block: SpotlightBlockV2) => Promise<void>;
  startVolunteer: () => Promise<void>;
  startBuzzer: () => Promise<void>;
  stopBuzzer: () => Promise<void>;
  startVote: () => Promise<void>;
  stopVote: () => Promise<void>;
  startVoteCelebrating: () => Promise<void>;
  stopVoteCelebrating: () => Promise<void>;
  startInstantWinner: () => Promise<void>;
  stopInstantWinner: () => Promise<void>;
  muted: (memberId: MemberId) => Promise<void>;
  unmuted: (memberId: MemberId) => Promise<void>;

  resetGame: (debug: string) => Promise<void>;
  deinitGame: () => Promise<void>;
}

interface SpotlightV2GamePlayAPI {
  volunteer: () => Promise<void>;
  buzzerIn: () => Promise<void>;
  vote: (memberId: MemberId) => Promise<void>;
}

export type Game = {
  id: string;
};

export type GameSettings = {
  preSelectMode: 'Team Place' | 'Ask For Volunteers' | 'Random Selection';
  maxVolunteer: number;
  votingMode: boolean;
  votingPoints: number;
};

export interface VolunteerData {
  memberId: MemberId;
  teamId: TeamId;
  submittedAtMs: number | RTDBServerValueTIMESTAMP;
}

export interface BuzzerData {
  memberId: MemberId;
  submittedAtMs: number | RTDBServerValueTIMESTAMP;
}

export interface BuzzersMap {
  [memberId: MemberId]: BuzzerData;
}

export interface VolunteersMap {
  [memberId: MemberId]: VolunteerData;
}

export interface MuteMembersMap {
  [memberId: MemberId]: boolean;
}

export interface MemberVoteMap {
  [teamId: MemberId]: MemberId;
}

export interface MemberVotedMap {
  [memberId: MemberId]: number;
}

type Context = {
  game: Nullable<Game>;
  updateGame: (next: Nullable<Game, false>) => Promise<void>;

  volunteering: Nullable<boolean>;
  voting: Nullable<SpotlightV2VotingStatus>;
  buzzing: Nullable<boolean>;
  instantWinner: Nullable<boolean>;

  muteMembers: Nullable<MuteMembersMap>;
  volunteers: Nullable<VolunteersMap>;
  memberVote: Nullable<MemberVoteMap>;
  buzzers: Nullable<BuzzersMap>;
};

const context = React.createContext<Nullable<Context, false>>(null);

function useSpotlightV2Context(): Context {
  const ctx = useContext(context);
  if (!ctx) throw new Error('spotlightV2Context is not in the tree!');
  return ctx;
}

function useInitGame(options?: { enabled: boolean; readonly: boolean }) {
  const venueId = useVenueId();
  return useFirebaseValue<Nullable<Game, false>>(
    SpotlightBlockV2Utils.GetFBPath(venueId, 'game'),
    {
      enabled: !!options?.enabled,
      seedValue: null,
      seedEnabled: false,
      readOnly: !!options?.readonly,
      resetWhenUmount: true,
    }
  );
}

export function useSpotlightV2Volunteering(): boolean {
  return useSpotlightV2Context().volunteering ?? false;
}

export function useSpotlightV2VolunteersMap(): VolunteersMap {
  return useSpotlightV2Context().volunteers ?? {};
}

export function useSpotlightV2Buzzing(): boolean {
  return useSpotlightV2Context().buzzing ?? false;
}

export function useSpotlightV2InstantWinner(): boolean {
  return useSpotlightV2Context().instantWinner ?? false;
}

export function useSpotlightV2VotingStatus(): SpotlightV2VotingStatus | null {
  return useSpotlightV2Context().voting ?? null;
}

export function useSpotlightV2Volunteers(length: number): VolunteerData[] {
  const volunteersMap = useSpotlightV2VolunteersMap();
  return useMemo(
    () =>
      Object.values(volunteersMap)
        .sort((a, b) => {
          if (
            typeof a.submittedAtMs !== 'number' ||
            typeof b.submittedAtMs !== 'number'
          )
            return 0;

          return a.submittedAtMs - b.submittedAtMs;
        })
        .filter((a) => typeof a.submittedAtMs === 'number')
        .slice(0, length),
    [length, volunteersMap]
  );
}

export function useSpotlightV2BuzzersMap(): BuzzersMap {
  return useSpotlightV2Context().buzzers ?? {};
}

export function useSpotlightV2Buzzers(): BuzzerData[] {
  const buzzersMap = useSpotlightV2BuzzersMap();

  return useMemo(
    () =>
      Object.values(buzzersMap)
        .sort((a, b) => {
          if (
            typeof a.submittedAtMs !== 'number' ||
            typeof b.submittedAtMs !== 'number'
          )
            return 0;

          return a.submittedAtMs - b.submittedAtMs;
        })
        .filter((a) => typeof a.submittedAtMs === 'number'),
    [buzzersMap]
  );
}

export function useSpotlightV2MuteMembersMap(): MuteMembersMap {
  return useSpotlightV2Context().muteMembers ?? {};
}

export function useSpotlightV2MuteMembers(): MemberId[] {
  const membersMap = useSpotlightV2MuteMembersMap();
  return useMemo(
    () => Object.keys(membersMap).filter((k) => membersMap[k]),
    [membersMap]
  );
}

export function useSpotlightV2VotingMap(): MemberVoteMap {
  return useSpotlightV2Context().memberVote ?? {};
}

export function useSpotlightV2MemberVotedMap(): MemberVotedMap {
  const memberVote = useSpotlightV2VotingMap();
  return useMemo(() => {
    const memberVotedMap: MemberVotedMap = {};
    Object.keys(memberVote).forEach((i) => {
      if (memberVotedMap[memberVote[i]]) {
        memberVotedMap[memberVote[i]]++;
      } else {
        memberVotedMap[memberVote[i]] = 1;
      }
    });

    return memberVotedMap;
  }, [memberVote]);
}

export function useSpotlightV2Control(): SpotlightV2ControlAPI {
  const venueId = useVenueId();
  const { updateGame, game } = useSpotlightV2Context();
  const gameId = game?.id;
  const batchWrite = useFirebaseBatchWrite();
  const ref = useDatabaseRef(SpotlightBlockV2Utils.GetFBPath(venueId, 'root'));

  const resetGame = useCallback(async () => {
    await ref.remove();
  }, [ref]);

  const initGame = useCallback(async () => {
    await updateGame({ id: uuidv4() });
  }, [updateGame]);

  const startVolunteer = useCallback(async () => {
    if (!gameId) return;
    await batchWrite({
      [SpotlightBlockV2Utils.GetFBPath(venueId, 'volunteering')]: true,
    });
  }, [batchWrite, gameId, venueId]);

  const startBuzzer = useCallback(async () => {
    if (!gameId) return;
    await batchWrite({
      [SpotlightBlockV2Utils.GetFBPath(venueId, 'buzzing')]: true,
    });
  }, [batchWrite, gameId, venueId]);

  const stopBuzzer = useCallback(async () => {
    if (!gameId) return;
    await batchWrite({
      [SpotlightBlockV2Utils.GetFBPath(venueId, 'buzzers')]: null,
      [SpotlightBlockV2Utils.GetFBPath(venueId, 'buzzing')]: false,
    });
  }, [batchWrite, gameId, venueId]);

  const startInstantWinner = useCallback(async () => {
    if (!gameId) return;
    await batchWrite({
      [SpotlightBlockV2Utils.GetFBPath(venueId, 'instantWinner')]: true,
    });
  }, [batchWrite, gameId, venueId]);

  const stopInstantWinner = useCallback(async () => {
    if (!gameId) return;
    await batchWrite({
      [SpotlightBlockV2Utils.GetFBPath(venueId, 'instantWinner')]: false,
    });
  }, [batchWrite, gameId, venueId]);

  const startVoteCelebrating = useCallback(async () => {
    if (!gameId) return;
    await batchWrite({
      [SpotlightBlockV2Utils.GetFBPath(venueId, 'voting')]:
        SpotlightV2VotingStatus.Celebrating,
    });
  }, [batchWrite, gameId, venueId]);

  const stopVoteCelebrating = useCallback(async () => {
    if (!gameId) return;
    await batchWrite({
      [SpotlightBlockV2Utils.GetFBPath(venueId, 'voting')]:
        SpotlightV2VotingStatus.CelebratingEnd,
    });
  }, [batchWrite, gameId, venueId]);

  const startVote = useCallback(async () => {
    if (!gameId) return;
    await batchWrite({
      [SpotlightBlockV2Utils.GetFBPath(venueId, 'voting')]:
        SpotlightV2VotingStatus.Voting,
    });
  }, [batchWrite, gameId, venueId]);

  const stopVote = useCallback(async () => {
    if (!gameId) return;
    await batchWrite({
      [SpotlightBlockV2Utils.GetFBPath(venueId, 'voting')]:
        SpotlightV2VotingStatus.VotingEnd,
    });
  }, [batchWrite, gameId, venueId]);

  const deinitGame = useCallback(async () => {
    if (!gameId) return;
    await resetGame();
    await updateGame({ id: gameId });
  }, [gameId, resetGame, updateGame]);

  const mute = useCallback(
    async (memberId: MemberId) => {
      if (!gameId) return;
      await batchWrite({
        [SpotlightBlockV2Utils.GetFBMemberPath(venueId, memberId, 'mute')]:
          true,
      });
    },
    [batchWrite, gameId, venueId]
  );

  const unmute = useCallback(
    async (memberId: MemberId) => {
      if (!gameId) return;
      await batchWrite({
        [SpotlightBlockV2Utils.GetFBMemberPath(venueId, memberId, 'mute')]:
          false,
      });
    },
    [batchWrite, gameId, venueId]
  );

  return {
    initGame,
    startVolunteer,
    startBuzzer,
    stopBuzzer,
    startVote,
    stopVote,
    startVoteCelebrating,
    stopVoteCelebrating,
    startInstantWinner,
    stopInstantWinner,
    resetGame,
    deinitGame,
    muted: mute,
    unmuted: unmute,
  };
}

export function useSpotlightV2GamePlay(
  memberId: MemberId,
  teamId: TeamId
): SpotlightV2GamePlayAPI {
  const venueId = useVenueId();
  const { game } = useSpotlightV2Context();
  const gameId = game?.id;
  const batchWrite = useFirebaseBatchWrite();

  const volunteer = useCallback(async () => {
    if (!gameId) return;
    await batchWrite({
      [SpotlightBlockV2Utils.GetFBMemberPath(venueId, memberId, 'volunteers')]:
        {
          memberId: memberId,
          submittedAtMs: RTDBServerValueTIMESTAMP,
          teamId: teamId,
        },
    });
  }, [batchWrite, gameId, memberId, teamId, venueId]);

  const buzzerIn = useCallback(async () => {
    if (!gameId) return;

    await batchWrite({
      [SpotlightBlockV2Utils.GetFBMemberPath(venueId, memberId, 'buzzers')]: {
        memberId: memberId,
        submittedAtMs: RTDBServerValueTIMESTAMP,
      },
    });
  }, [batchWrite, gameId, memberId, venueId]);

  const vote = useCallback(
    async (voteMemberId: MemberId) => {
      if (!gameId) return;

      await batchWrite({
        [SpotlightBlockV2Utils.GetFBMemberPath(venueId, memberId, 'vote')]:
          voteMemberId,
      });
    },
    [batchWrite, gameId, venueId, memberId]
  );

  return {
    volunteer,
    buzzerIn,
    vote,
  };
}

export function SpotlightV2Provider(props: {
  children?: ReactNode;
}): JSX.Element | null {
  const venueId = useVenueId();
  const isController = useIsController();
  const isSessionAlive = useIsStreamSessionAlive();
  const [game, updateGame] = useInitGame({
    enabled: isSessionAlive,
    readonly: !isController,
  });

  const [volunteering] = useFirebaseValue<Nullable<boolean>>(
    SpotlightBlockV2Utils.GetFBPath(venueId, 'volunteering'),
    {
      enabled: isSessionAlive,
      seedValue: null,
      seedEnabled: false,
      readOnly: true,
      resetWhenUmount: true,
    }
  );

  const [voting] = useFirebaseValue<Nullable<SpotlightV2VotingStatus>>(
    SpotlightBlockV2Utils.GetFBPath(venueId, 'voting'),
    {
      enabled: isSessionAlive,
      seedValue: null,
      seedEnabled: false,
      readOnly: true,
      resetWhenUmount: true,
    }
  );

  const [buzzing] = useFirebaseValue<Nullable<boolean>>(
    SpotlightBlockV2Utils.GetFBPath(venueId, 'buzzing'),
    {
      enabled: isSessionAlive,
      seedValue: null,
      seedEnabled: false,
      readOnly: true,
      resetWhenUmount: true,
    }
  );

  const [instantWinner] = useFirebaseValue<Nullable<boolean>>(
    SpotlightBlockV2Utils.GetFBPath(venueId, 'instantWinner'),
    {
      enabled: isSessionAlive,
      seedValue: null,
      seedEnabled: false,
      readOnly: true,
      resetWhenUmount: true,
    }
  );

  const [volunteers] = useFirebaseValue<Nullable<VolunteersMap>>(
    SpotlightBlockV2Utils.GetFBPath(venueId, 'volunteers'),
    {
      enabled: isSessionAlive,
      seedValue: null,
      seedEnabled: false,
      readOnly: true,
      resetWhenUmount: true,
    }
  );

  const [memberVote] = useFirebaseValue<Nullable<MemberVoteMap>>(
    SpotlightBlockV2Utils.GetFBPath(venueId, 'vote'),
    {
      enabled: isSessionAlive,
      seedValue: null,
      seedEnabled: false,
      readOnly: true,
      resetWhenUmount: true,
    }
  );

  const [buzzers] = useFirebaseValue<Nullable<BuzzersMap>>(
    SpotlightBlockV2Utils.GetFBPath(venueId, 'buzzers'),
    {
      enabled: isSessionAlive,
      seedValue: null,
      seedEnabled: false,
      readOnly: true,
      resetWhenUmount: true,
    }
  );

  const [muteMembers] = useFirebaseValue<Nullable<MuteMembersMap>>(
    SpotlightBlockV2Utils.GetFBPath(venueId, 'mute'),
    {
      enabled: isSessionAlive,
      seedValue: null,
      seedEnabled: false,
      readOnly: true,
      resetWhenUmount: true,
    }
  );

  const ctxValue: Context = useMemo(
    () => ({
      game,
      updateGame,
      volunteering,
      voting,
      buzzing,
      volunteers,
      memberVote,
      buzzers,
      instantWinner,
      muteMembers,
    }),
    [
      buzzers,
      buzzing,
      game,
      instantWinner,
      memberVote,
      muteMembers,
      updateGame,
      volunteering,
      volunteers,
      voting,
    ]
  );

  return <context.Provider value={ctxValue}>{props.children}</context.Provider>;
}
