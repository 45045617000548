import { useCallback, useMemo } from 'react';

import { ConnectionStatus } from '@lp-lib/shared-schema';

import { useLiveCallback } from '../../../../hooks/useLiveCallback';
import { type Participant, type TeamId } from '../../../../types';
import { useAwaitFullScreenConfirmCancelModal } from '../../../ConfirmCancelModalContext';
import { useHostClientId, useParticipantsGetter } from '../../../Player';
import { useTeamMembersGetter } from '../../../TeamAPI/TeamV1';
import { BringTeamModal } from '../../BringTeamModal';
import { MAX_STAGE_MEMBERS, StageMode } from '../../types';
import { StageUtils } from '../../utils';
import { useStageContext } from '../Provider';

export function useBringTeamOnStage(): (
  teamId: TeamId,
  stageMode?: StageMode,
  clearStageFirst?: boolean
) => Promise<void> {
  const { state, api } = useStageContext();
  const hostClientId = useHostClientId();
  const participantsGetter = useParticipantsGetter();
  const getTeamMembers = useTeamMembersGetter();
  return useLiveCallback(
    async (
      teamId: TeamId,
      stageMode = StageMode.BOS,
      clearStageFirst = false
    ) => {
      const memberIds = getTeamMembers(teamId)?.map((m) => m.id);
      if (!memberIds) return;
      await api.updateStageMode(stageMode);
      if (clearStageFirst) {
        const participants = Object.values(participantsGetter()).filter(
          (p): p is Participant => !!p
        );
        const stageMemberVOMap = StageUtils.GetMemberVOMap(
          participants,
          state.members
        );
        const leaveReqs: Promise<void>[] = [];
        for (const memberId in stageMemberVOMap) {
          const stageMember = stageMemberVOMap[memberId];
          // skip the disconnected stage members, they have been removed already
          if (stageMember.status !== ConnectionStatus.Connected) continue;
          // skip the stage member who is part of the target team
          if (memberIds?.includes(stageMember.id)) continue;
          // skip the host
          if (stageMember.id === hostClientId) continue;
          leaveReqs.push(api.leave(stageMember.id));
        }
        await Promise.all(leaveReqs);
      }
      const joinReqs: Promise<void>[] = [];
      for (const memberId of memberIds) {
        // only add the member who is not on stage
        if (!state.members[memberId]) {
          joinReqs.push(api.join(memberId, stageMode));
        }
      }
      await Promise.all(joinReqs);
    }
  );
}

function useLeaveTeamOnStage(): (teamId: TeamId) => Promise<void> {
  const { state, api } = useStageContext();
  const participantsGetter = useParticipantsGetter();
  return useLiveCallback(async (teamId: TeamId) => {
    const participantsMap = participantsGetter();
    const memberIds = Object.keys(state.members);
    const leaveReqs: Promise<void>[] = [];
    for (const memberId of memberIds) {
      if (participantsMap[memberId]?.teamId === teamId) {
        leaveReqs.push(api.leave(memberId));
      }
    }
    await Promise.all(leaveReqs);
  });
}

export function useBringTeamOnStageAPI() {
  const join = useBringTeamOnStage();
  const leave = useLeaveTeamOnStage();
  return useMemo(() => ({ join, leave }), [join, leave]);
}

function useCanBringTeamOnStage(): (teamId: TeamId) => boolean {
  const { state } = useStageContext();
  const participantsGetter = useParticipantsGetter();
  const getTeamMembers = useTeamMembersGetter();
  return useCallback(
    (teamId: TeamId): boolean => {
      const stageMembers = state.members;
      const mode = state.stage.mode;
      const teamMembers = getTeamMembers(teamId);
      const participants = Object.values(participantsGetter()).filter(
        (p): p is Participant => !!p
      );
      const stageMemberVOMap = StageUtils.GetMemberVOMap(
        participants,
        stageMembers
      );
      const stageMembersCount = StageUtils.GetMembersCount(stageMemberVOMap);
      if ((mode === StageMode.H2H && stageMembersCount > 1) || !teamMembers)
        return false;
      let requiredSeats = 0;
      for (const member of teamMembers) {
        const stageMember = stageMemberVOMap[member.id];
        if (!stageMember) {
          requiredSeats += 1;
        }
      }
      const remainingSeats = MAX_STAGE_MEMBERS - stageMembersCount;
      return remainingSeats >= requiredSeats;
    },
    [state.members, state.stage.mode, getTeamMembers, participantsGetter]
  );
}

export function useBringTeamOnStageWithConfirmation(): (
  teamId: TeamId
) => Promise<void> {
  const triggerFullScreenModal = useAwaitFullScreenConfirmCancelModal();
  const canBringTeamOnStage = useCanBringTeamOnStage();
  const bringTeamOnStage = useBringTeamOnStage();

  return useCallback(
    async (teamId: TeamId) => {
      let clearStageFirst = false;
      if (!canBringTeamOnStage(teamId)) {
        const confirmModal = await triggerFullScreenModal({
          kind: 'custom',
          containerClassName: 'bg-black bg-opacity-60',
          element: (p) => (
            <BringTeamModal
              onClose={p.internalOnCancel}
              onComplete={p.internalOnConfirm}
            />
          ),
        });
        if (confirmModal.result === 'canceled') return;
        clearStageFirst = true;
      }
      await bringTeamOnStage(teamId, StageMode.BOS, clearStageFirst);
    },
    [bringTeamOnStage, canBringTeamOnStage, triggerFullScreenModal]
  );
}
