import { type ReactNode } from 'react';

import {
  type FieldContainer,
  SimpleFieldEditor,
} from '../Game/Blocks/Common/Editor/FieldEditorUtilities';
import { PersonalitySelect } from './PersonalitySelect';

// note(falcon): this is to support the legacy editors.
export function PersonalityFieldEditor(props: {
  name?: ReactNode;
  description?: ReactNode;
  value?: string | null;
  onChange: (value: string | null) => void;
  styles?: Parameters<typeof FieldContainer>[0]['styles'];
}) {
  return (
    <SimpleFieldEditor
      name={props.name ?? 'Personality'}
      description={
        props.description ?? (
          <>
            Defines the voice/personality to use for TTS. <br />
            <strong>
              Note: this can only be used for the "V2" Luna Park experience and
              is ignored in venue.
            </strong>
          </>
        )
      }
      styles={props.styles}
    >
      <PersonalitySelect
        onChange={(p) => props.onChange(p?.id ?? null)}
        value={props.value}
      />
    </SimpleFieldEditor>
  );
}
