import { type Block } from '@lp-lib/game';

import { type AddBlockRecordingReq } from '../../services/api-service';
import { type Game } from '../../types/game';
import { type BlockRecordingData } from './types';

export class BlockRecordingUtils {
  static Blank(
    block: Block,
    gameId: Game['id'],
    version: number
  ): BlockRecordingData {
    return {
      block,
      gameId,
      actions: [],
      durationMs: 0,
      mediaId: null,
      version,
    };
  }

  static ToRequest(data: BlockRecordingData): AddBlockRecordingReq {
    return {
      mediaId: data.mediaId,
      durationMs: data.durationMs,
      actions: data.actions,
      version: data.version,
    };
  }
}
