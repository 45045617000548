import { type AxiosResponse } from 'axios';
import { type ReactNode, useRef, useState } from 'react';

import {
  type DtoTTSRenderRequest,
  EnumsTTSCacheControl,
  EnumsTTSRenderPolicy,
  type ModelsTTSRenderSettings,
} from '@lp-lib/api-service-client/public';

import { useLiveCallback } from '../../hooks/useLiveCallback';
import { apiService } from '../../services/api-service';
import { hashObject } from '../../utils/hash-object';
import { playWithCatch } from '../../utils/playWithCatch';

export type TTSCustomPrevievProps = {
  script: string | null | undefined;
  settings: ModelsTTSRenderSettings | null | undefined;
  onBeforeRender?: (value: Nullable<string>) => Promise<Nullable<string>>;
  render?: (script: string) => Promise<AxiosResponse<Blob>>;
};

export function TTSCustomPreview(
  props: TTSCustomPrevievProps & {
    children: (props: {
      audioRef: React.MutableRefObject<HTMLAudioElement | null>;
      handlePreview: () => Promise<void>;
      disabled: boolean;
      isGenerating: boolean;
    }) => ReactNode;
  }
) {
  const audioRef = useRef<HTMLAudioElement>(null);
  const [isGenerating, setIsGenerating] = useState(false);
  const [preview, setPreview] = useState<string | undefined>(undefined);
  const [previewHash, setPreviewHash] = useState<string | undefined>(undefined);

  const handlePreview = useLiveCallback(async () => {
    if (
      audioRef.current === null ||
      !audioRef.current.paused ||
      !props.settings
    )
      return;

    const onBeforeRender = props.onBeforeRender || (async (v) => v);
    const script = await onBeforeRender(props.script);
    if (!script) return;

    setIsGenerating(true);
    try {
      const req: DtoTTSRenderRequest = {
        script,
        ttsRenderSettings: props.settings,
        policy: EnumsTTSRenderPolicy.TTSRenderPolicyReadThrough,
        cacheControl: EnumsTTSCacheControl.TTSCacheControlShortLive,
      };
      const reqHash = await hashObject(req);
      if (reqHash === previewHash && preview) {
        audioRef.current.src = preview;
        playWithCatch(audioRef.current);
        return;
      } else {
        const r = props.render
          ? await props.render(script)
          : await apiService.tts.render(req);
        const data = r.data;
        const previewBlobUrl = URL.createObjectURL(data);
        setPreview(previewBlobUrl);
        setPreviewHash(reqHash);
        audioRef.current.src = previewBlobUrl;
        playWithCatch(audioRef.current);
      }
    } finally {
      setIsGenerating(false);
    }
  });

  return props.children({
    handlePreview,
    disabled: isGenerating || !props.settings,
    isGenerating,
    audioRef,
  });
}
