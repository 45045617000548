import { useEffect, useState } from 'react';

import { type OndGamePlayState, type VideoPlayback } from '../Game/store';

export function useInteractWithGamePlayVideoAndOnd(props: {
  isStreamAlive: boolean;
  ondState: OndGamePlayState | null;
  ondGameProgress: number;
  videoPlayback: VideoPlayback | null;
  musicCtrl: {
    play: (reason?: string) => void;
    pause: (reason?: string) => void;
    isPlaying: boolean;
  };
}): void {
  const { play, pause } = props.musicCtrl;
  const { ondState, ondGameProgress, videoPlayback, isStreamAlive } = props;

  /**
   * This code, generally, doesn't know about the contents of an OND game. With
   * playback v3.1, we do not have a separate intro: the first media of a title
   * card is used instead. In addition, the music player volume is boosted
   * during an OND game. But this poses a problem because it's likely that the
   * game will start, causing the music player to start, then within a second
   * the title card media stars, and probably pauses the music player. This will
   * cause a "spurt" of audio from the music player that sounds really messy and
   * bad. To avoid this, we ensure that X amount of time has passed during an
   * OND game before attempting to start the music player. A better solution
   * would be to move play/pause as an external dependency/interface to the
   * OndPhaseRunner (@see {OndGamePlaybackDependencies}).
   */
  const ondWaitUntilProgressBeforeStartingMusicPlayer = 1;

  // Sometimes, videoPlayback.state never reaches "ended" because the Ond
  // system (or other trigger) removes the video element before it has
  // finished absolutely playing. Instead, guard against this by checking if
  // videoPlayback is falsy. If it is falsy and we are not playing, that
  // usually means gamePlayMedia has just ended.
  const hasVideoPlayback = Boolean(videoPlayback);

  // TODO: save to local storage to support recovery
  const [resumable, setResumable] = useState(false);

  useEffect(() => {
    // During the start of an ond game, mark the music control as resumable so
    // that this hook has a chance to "take control".
    if (
      ondState === 'running' &&
      ondGameProgress <= ondWaitUntilProgressBeforeStartingMusicPlayer &&
      !resumable
    ) {
      setResumable(true);
    }
  }, [ondGameProgress, ondState, resumable]);

  // Manage ond autostart/stop on load.
  useEffect(() => {
    // Ensure that if an OND game finishes or paused, the music player is
    // paused as well.
    if (ondState === 'paused') {
      pause('ond paused');
      setResumable(true);
    } else if (ondState === 'ended' || ondState === null) {
      pause('ond ended or null');
      setResumable(false);
    }
  }, [ondState, pause, play]);

  useEffect(() => {
    if (
      hasVideoPlayback &&
      !videoPlayback?.playBackgroundMusicWithMedia &&
      videoPlayback?.state === 'playing'
    ) {
      pause('auto pause during gameplaymedia playback');
      setResumable(true);
    }
  }, [
    hasVideoPlayback,
    pause,
    videoPlayback?.playBackgroundMusicWithMedia,
    videoPlayback?.state,
  ]);

  useEffect(() => {
    if (!isStreamAlive || !resumable) return;

    if (
      hasVideoPlayback &&
      videoPlayback?.state !== 'ended' &&
      !videoPlayback?.playBackgroundMusicWithMedia
    )
      return;

    // Note: isStreamAlive && !ondState means a live game in this contenxt

    if (
      ondState === 'running' &&
      ondGameProgress > ondWaitUntilProgressBeforeStartingMusicPlayer
    ) {
      play('ond resume beyond wait period');
      setResumable(false);
    } else if (!ondState) {
      play('live resume after media');
      setResumable(false);
    }
  }, [
    hasVideoPlayback,
    isStreamAlive,
    ondGameProgress,
    ondState,
    play,
    resumable,
    videoPlayback?.playBackgroundMusicWithMedia,
    videoPlayback?.state,
  ]);
}
