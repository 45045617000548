import { useEffect, useRef } from 'react';

import { useLiveCallback } from '../../hooks/useLiveCallback';
import { useLoadGame, useQueryParamGamePackId } from '../../hooks/useLoadGame';
import { useIsCoordinator, useMyInstance } from '../../hooks/useMyInstance';
import logger from '../../logger/logger';
import { apiService } from '../../services/api-service';
import { SessionMode } from '../../types';
import { type GamePack } from '../../types/game';
import { fromDTOGamePack } from '../../utils/api-dto';
import { useAwaitFullScreenConfirmCancelModal } from '../ConfirmCancelModalContext';
import { ModalWrapper } from '../ConfirmCancelModalContext/ModalWrapper';
import { useLocalGamePlayStore } from '../Game/GamePlayStore';
import {
  useGameSessionGamePackId,
  useIsGameSessionInited,
  useOndGameState,
} from '../Game/hooks';
import { usePlaybackDesc } from '../Game/Playback/PlaybackInfoProvider';
import { GamePackCover } from '../Game/Utilities';
import { useIsPairingGamePublished, usePairing } from '../Pairing';

const log = logger.scoped('ond-game-init');

const getGamePackById = async (packId: string): Promise<GamePack | null> => {
  const resp = await apiService.gamePack.getGamePackById(packId);
  return fromDTOGamePack(resp.data.gamePack);
};

const GameResetModal = (props: {
  pack: GamePack;
  onComplete: () => void;
  onClose: () => void;
}): JSX.Element | null => {
  return (
    <ModalWrapper
      borderStyle='gray'
      onClose={props.onClose}
      containerClassName='w-160'
    >
      <div className='w-full h-full py-15 flex flex-col items-center gap-10 text-white'>
        <div className='flex flex-col items-center gap-4'>
          <div className='w-60'>
            <GamePackCover pack={props.pack} />
          </div>
          <div className='text-2xl font-medium text-center px-4'>
            Trying to load {props.pack.name}?
          </div>
          <div className='text-sms'>
            You have another game active, and this new game will override it.
            Continue?
          </div>
        </div>
        <div className='flex items-center gap-4'>
          <button
            type='button'
            onClick={props.onClose}
            className='btn-secondary w-33 h-10 font-medium'
          >
            Cancel
          </button>
          <button
            type='button'
            onClick={props.onComplete}
            className='btn-primary w-33 h-10 font-medium'
          >
            Continue
          </button>
        </div>
      </div>
    </ModalWrapper>
  );
};

// Overall process:
// 1 Recover the game pack in game session
// 2 Try to load target game pack from (pairs game, query params)
//     2.1 if no target game packs, do nothing and exit.
//     2.2 if the target game pack equals the game session's, do nothing and exit.
//     2.3 if current game session is inactive, load the game pack from query param directly and exit.
//     2.4 pop up to let the user confirm if he/she want to reset the game. If yes, reset game and load the target game pack
//         if no, `featured-game` query param will be updated, so that next time the user refresh the page,
//         he/she will no longer to be asked to confirm again.
export function useInitOnDGamePack(props: {
  resetGame: () => Promise<void>;
}): void {
  const resetGame = useLiveCallback(props.resetGame);
  const triggerFullScreenModal = useAwaitFullScreenConfirmCancelModal();
  const store = useLocalGamePlayStore();
  const isGameSessionInited = useIsGameSessionInited();
  const loadGame = useLoadGame();
  const ondGameState = useOndGameState();
  const isOndGameActive = !!ondGameState;

  const gameSessionGamePackId = useGameSessionGamePackId();
  const [paramGamePackId, updateParamGamePackId] = useQueryParamGamePackId();
  const isPairingPublished = useIsPairingGamePublished();
  const pairing = usePairing();
  const isCoordinator = useIsCoordinator();
  const me = useMyInstance();
  const playback = usePlaybackDesc(SessionMode.OnDemand);

  const state = useRef<'NotStarted' | 'Running' | 'Done'>('NotStarted');

  useEffect(() => {
    if (isCoordinator) return;
    log.info('coordinator changed, reset state');
    state.current = 'NotStarted';
  }, [isCoordinator]);

  useEffect(() => {
    if (!isCoordinator) return;
    if (state.current !== 'NotStarted') return;
    if (!isGameSessionInited || !isPairingPublished) return;

    const tryRecoverPlayingGamePack = async () => {
      if (playback) {
        log.info('recovering using playback', {
          playback: JSON.stringify(playback),
        });
        await store.load({ playback });
      } else if (gameSessionGamePackId) {
        log.info('no playback available, trying game pack', {
          gameSessionGamePackId,
        });
        const myId = me?.id ?? '';
        await store.load({
          pack: gameSessionGamePackId,
          playbackConfig: { playHistoryTargetId: myId, subscriberId: myId },
        });
      } else {
        log.info('no game pack loaded in the game session, nothing to recover');
        return;
      }
    };

    const tryLoadTargetGamePack = async () => {
      const targetGamePack = !!pairing?.nextGamePack
        ? pairing.nextGamePack
        : paramGamePackId
        ? await getGamePackById(paramGamePackId)
        : null;

      if (!targetGamePack) {
        log.info('no target game pack, do nothing', {
          paramGamePackId,
          pairingId: pairing?.pairingId,
        });
        return;
      }

      if (targetGamePack.id === gameSessionGamePackId) {
        log.info(
          'target game pack equals game pack in game session, do nothing'
        );
        return;
      }

      if (!isOndGameActive) {
        log.info('no active game session, load target game pack', {
          paramGamePackId,
          pairingGamePackId: pairing?.nextGamePack.id,
          pairingId: pairing?.pairingId,
        });
        await loadGame(targetGamePack);
        return;
      }

      const confirmModal = await triggerFullScreenModal({
        kind: 'custom',
        element: (p) => (
          <GameResetModal
            pack={targetGamePack}
            onComplete={p.internalOnConfirm}
            onClose={p.internalOnCancel}
          />
        ),
      });
      if (confirmModal.result === 'canceled') {
        log.info('cancel reset game', {
          paramGamePackId,
          gameSessionGamePackId,
        });
        updateParamGamePackId(gameSessionGamePackId);
        return;
      }

      log.info('override game', { paramGamePackId, gameSessionGamePackId });
      if (isOndGameActive) {
        await resetGame();
      }
      loadGame(targetGamePack);
    };

    const run = async () => {
      log.info('init ond game');
      state.current = 'Running';

      // NOTE(drew): these are separate try/catches because if one fails, we still
      // want to try the next. Otherwise, for example, failing to load the
      // Recovery pack due to a server-side error (404, etc) would prevent the
      // Target pack from even being attempted.

      try {
        await tryRecoverPlayingGamePack();
      } catch (error) {
        log.error('init ond game via recovery, failed', error);
      }

      try {
        await tryLoadTargetGamePack();
      } catch (error) {
        log.error('init ond game via load target, failed', error);
      }

      state.current = 'Done';
    };

    run();
  }, [
    store,
    gameSessionGamePackId,
    isOndGameActive,
    isGameSessionInited,
    loadGame,
    paramGamePackId,
    resetGame,
    triggerFullScreenModal,
    updateParamGamePackId,
    isPairingPublished,
    pairing?.nextGamePack,
    pairing?.pairingId,
    isCoordinator,
    me?.id,
    playback,
  ]);
}
