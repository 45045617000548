/**
 * useful for pattern matching to avoid returning a value
 */
export function throws<T extends string | Error>(msgOrErr: T): never {
  throw typeof msgOrErr === 'string' ? new Error(msgOrErr) : msgOrErr;
}

/**
 * Rewrap an error (or structure data) as the `cause` of a new error. See
 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Error/cause
 */
export function rethrows<K extends Error | unknown>(
  msg: string,
  cause: K
): never {
  throw new Error(msg, { cause });
}
