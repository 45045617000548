import { useLayoutEffect, useMemo } from 'react';
import { proxy } from 'valtio';

import {
  getFeatureQueryParamArray,
  getFeatureQueryParamNumber,
} from '../../hooks/useFeatureQueryParam';
import logger from '../../logger/logger';
import { BrowserTimeoutCtrl } from '../../utils/BrowserTimeoutCtrl';
import { assertExhaustive } from '../../utils/common';
import { type MicVolumeMeterProcessor } from '../../utils/MicVolumeMeter';
import {
  markSnapshottable,
  useSnapshot,
  ValtioUtils,
} from '../../utils/valtio';
import { useIsLiveGamePlay } from '../Game/hooks';
import { MicrophoneOffIcon } from '../icons/MicrophoneOffIcon';
import { useUserStates } from '../UserContext';
import { useWebRTCMVMProcessor } from './MicVolumeMeterProcessorStore';

type State = {
  showWarning: boolean;
  pendingHideWarning: boolean;
  audioOn: boolean;
  audioStatusUpdatedAt: number;
  lastWarningShowedAt: number;
};

class MicMutedWarningAPI {
  readonly state = markSnapshottable(proxy<State>(this.initialState()));
  private ctrl = new BrowserTimeoutCtrl();
  constructor(
    private processor: MicVolumeMeterProcessor,
    readonly log = logger.scoped('mic-mute-warning')
  ) {}

  setAudioOn(val: boolean) {
    this.state.audioOn = val;
    this.state.audioStatusUpdatedAt = Date.now();
  }

  on(
    config = {
      volumeThreshold: getFeatureQueryParamNumber('mic-mw-volume-threshold'),
      hideWarningAfterMs: getFeatureQueryParamNumber('mic-mw-hide-after-ms'),
      audioOffCooldownMs: getFeatureQueryParamNumber(
        'mic-mw-audio-off-cooldown-ms'
      ),
    }
  ) {
    const { volumeThreshold, hideWarningAfterMs, audioOffCooldownMs } = config;
    const off = this.processor.on('volume-changed', (volume) => {
      if (this.state.audioOn) {
        if (this.state.showWarning) {
          this.log.info('audio on, hide warning');
        }
        this.cancelScheduleCloseWarning();
        this.state.showWarning = false;
        return;
      }
      if (volume > volumeThreshold) {
        if (Date.now() - this.state.audioStatusUpdatedAt < audioOffCooldownMs) {
          this.log.info('audio was just turned off, skip showing warning');
          return;
        }
        if (
          this.state.showWarning ||
          this.state.lastWarningShowedAt > this.state.audioStatusUpdatedAt
        ) {
          return;
        }
        this.showWarning();
      } else {
        this.maybeScheduleCloseWarning(hideWarningAfterMs);
      }
    });
    return () => {
      off();
      this.reset();
    };
  }

  private cancelScheduleCloseWarning() {
    if (this.state.pendingHideWarning) {
      this.log.info('cancel hide warning');
    }
    this.ctrl.clear();
    this.state.pendingHideWarning = false;
  }

  private showWarning() {
    this.log.info('show warning');
    this.state.showWarning = true;
    this.state.lastWarningShowedAt = Date.now();
  }

  private maybeScheduleCloseWarning(afterMs: number) {
    if (this.state.pendingHideWarning || !this.state.showWarning) return;
    this.log.info(`hide warning after ${afterMs}ms`);
    this.state.pendingHideWarning = true;
    this.ctrl.set(() => {
      this.log.info('hide warning done');
      this.state.showWarning = false;
      this.state.pendingHideWarning = false;
    }, afterMs);
  }

  private reset() {
    this.cancelScheduleCloseWarning();
    ValtioUtils.reset(this.state, this.initialState());
  }

  private initialState(): State {
    return {
      showWarning: false,
      pendingHideWarning: false,
      audioOn: true,
      audioStatusUpdatedAt: 0,
      lastWarningShowedAt: 0,
    };
  }
}

function MicMutedWarningInternal(props: {
  processor: MicVolumeMeterProcessor;
}) {
  const { processor } = props;
  const { audio } = useUserStates();
  const api = useMemo(() => new MicMutedWarningAPI(processor), [processor]);
  const showWarning = useSnapshot(api.state).showWarning;

  useLayoutEffect(() => {
    return api.on();
  }, [api]);

  useLayoutEffect(() => {
    api.setAudioOn(audio);
  }, [audio, api]);

  return (
    <div
      className={`h-12.5 flex items-center justify-center flex-shrink-0 
      bg-modal bg-opacity-50 rounded-xl absolute bottom-3 left-1/2 transform-gpu 
      -translate-x-1/2 px-10 text-sm font-bold gap-1 text-white pointer-events-none
      transition-opacity duration-300 ${
        showWarning ? 'opacity-100' : 'opacity-0'
      }`}
    >
      <MicrophoneOffIcon className='w-4.5 h-4.5 fill-current' />
      <div>Trying to speak? Unmute with the mic icon on the left</div>
    </div>
  );
}

const micMutedWarningFeature = getFeatureQueryParamArray('mic-mw');

export function MicMutedWarning() {
  const processor = useWebRTCMVMProcessor();
  const isLiveGame = useIsLiveGamePlay();
  if (!processor) return null;

  switch (micMutedWarningFeature) {
    case 'disabled':
      return null;
    case 'live':
      if (!isLiveGame) return null;
      break;
    case 'ond':
      if (isLiveGame) return null;
      break;
    case 'enabled':
      break;
    default:
      assertExhaustive(micMutedWarningFeature);
      break;
  }
  return <MicMutedWarningInternal processor={processor} />;
}
