export function MultipleChoiceBlockIcon(
  props: React.SVGProps<SVGSVGElement>
): JSX.Element {
  return (
    <svg
      className={props.className ?? 'w-3.5 h-3.5 fill-current'}
      viewBox='0 0 14 14'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M3.5 7C5.433 7 7 5.433 7 3.5C7 1.567 5.433 0 3.5 0C1.567 0 0 1.567 0 3.5C0 5.433 1.567 7 3.5 7ZM3.25956 3.85221C3.2219 3.99755 3.20306 4.18683 3.20306 4.42004H3.51945C3.51945 4.24091 3.54581 4.09219 3.59855 3.97389C3.65504 3.85221 3.72661 3.74913 3.81324 3.66463C3.90363 3.57675 3.99591 3.49732 4.09007 3.42634C4.188 3.35536 4.28028 3.27931 4.36691 3.19819C4.4573 3.11708 4.52887 3.02244 4.5816 2.91428C4.63809 2.80612 4.66634 2.67092 4.66634 2.50869C4.66634 2.32279 4.61173 2.15886 4.5025 2.0169C4.39704 1.87157 4.25015 1.75834 4.06182 1.67722C3.87727 1.5961 3.66446 1.55554 3.4234 1.55554C3.21248 1.55554 3.02416 1.58934 2.85843 1.65694C2.69647 1.72454 2.56841 1.81918 2.47425 1.94086C2.38009 2.05915 2.33301 2.19435 2.33301 2.34645C2.33301 2.47151 2.37632 2.57797 2.46295 2.66585C2.54958 2.75035 2.65693 2.79429 2.78499 2.79767C2.90551 2.80105 3.00909 2.76556 3.09572 2.6912C3.18235 2.61684 3.22566 2.52897 3.22566 2.42757C3.22566 2.34307 3.19177 2.26702 3.12397 2.19942C3.05994 2.13182 2.96954 2.08281 2.85278 2.05239C2.96954 1.89016 3.1428 1.80904 3.37256 1.80904C3.56841 1.80904 3.72661 1.87326 3.84713 2.00169C3.97143 2.13013 4.03357 2.29575 4.03357 2.49855C4.03357 2.64388 4.01286 2.76218 3.97143 2.85344C3.93 2.94132 3.87727 3.01737 3.81324 3.08159C3.75297 3.14243 3.68706 3.20495 3.61549 3.26917C3.54393 3.33001 3.47613 3.40606 3.4121 3.49732C3.35184 3.58858 3.30099 3.70688 3.25956 3.85221ZM3.62114 5.35798C3.69647 5.29376 3.73414 5.21433 3.73414 5.11969C3.73414 5.01153 3.69836 4.92197 3.62679 4.85099C3.55523 4.78001 3.46484 4.74283 3.35561 4.73945C3.25391 4.73607 3.1654 4.76818 3.09007 4.83578C3.01851 4.9 2.98273 4.97942 2.98273 5.07406C2.98273 5.17884 3.01851 5.26672 3.09007 5.3377C3.1654 5.4053 3.2558 5.44079 3.36126 5.44417C3.46295 5.44755 3.54958 5.41882 3.62114 5.35798Z'
        fill='#82FF96'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M11.0833 6.41666C12.0498 6.41666 12.8333 5.63316 12.8333 4.66666C12.8333 3.70016 12.0498 2.91666 11.0833 2.91666C10.1168 2.91666 9.33333 3.70016 9.33333 4.66666C9.33333 5.63316 10.1168 6.41666 11.0833 6.41666ZM11.0833 7C12.372 7 13.4167 5.95533 13.4167 4.66666C13.4167 3.378 12.372 2.33333 11.0833 2.33333C9.79467 2.33333 8.75 3.378 8.75 4.66666C8.75 5.95533 9.79467 7 11.0833 7Z'
        fill='#82FF96'
      />
      <path
        d='M12.8337 3.49999C12.8337 4.78866 11.789 5.83333 10.5003 5.83333C9.21166 5.83333 8.16699 4.78866 8.16699 3.49999C8.16699 2.21133 9.21166 1.16666 10.5003 1.16666C11.789 1.16666 12.8337 2.21133 12.8337 3.49999Z'
        fill='#82FF96'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M11.0833 13.4167C12.0498 13.4167 12.8333 12.6332 12.8333 11.6667C12.8333 10.7002 12.0498 9.91671 11.0833 9.91671C10.1168 9.91671 9.33333 10.7002 9.33333 11.6667C9.33333 12.6332 10.1168 13.4167 11.0833 13.4167ZM11.0833 14C12.372 14 13.4167 12.9554 13.4167 11.6667C13.4167 10.378 12.372 9.33337 11.0833 9.33337C9.79467 9.33337 8.75 10.378 8.75 11.6667C8.75 12.9554 9.79467 14 11.0833 14Z'
        fill='#82FF96'
      />
      <path
        d='M12.8337 10.5C12.8337 11.7887 11.789 12.8334 10.5003 12.8334C9.21166 12.8334 8.16699 11.7887 8.16699 10.5C8.16699 9.21136 9.21166 8.16669 10.5003 8.16669C11.789 8.16669 12.8337 9.21136 12.8337 10.5Z'
        fill='#82FF96'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M4.08333 13.4167C5.04983 13.4167 5.83334 12.6332 5.83334 11.6667C5.83334 10.7002 5.04983 9.91671 4.08333 9.91671C3.11684 9.91671 2.33333 10.7002 2.33333 11.6667C2.33333 12.6332 3.11684 13.4167 4.08333 13.4167ZM4.08333 14C5.372 14 6.41667 12.9554 6.41667 11.6667C6.41667 10.378 5.372 9.33337 4.08333 9.33337C2.79467 9.33337 1.75 10.378 1.75 11.6667C1.75 12.9554 2.79467 14 4.08333 14Z'
        fill='#82FF96'
      />
      <path
        d='M5.83366 10.5C5.83366 11.7887 4.78899 12.8334 3.50033 12.8334C2.21166 12.8334 1.16699 11.7887 1.16699 10.5C1.16699 9.21136 2.21166 8.16669 3.50033 8.16669C4.78899 8.16669 5.83366 9.21136 5.83366 10.5Z'
        fill='#82FF96'
      />
    </svg>
  );
}
