import range from 'lodash/range';
import sampleSize from 'lodash/sampleSize';
import shuffle from 'lodash/shuffle';

import {
  type Media,
  type PuzzleBlock,
  type PuzzleDropSpot,
  type PuzzlePiece,
} from '@lp-lib/game';

import { bps } from '../../../../breakpoints';
import logger from '../../../../logger/logger';
import { type TeamId } from '../../../../types';
import { MediaUtils } from '../../../../utils/media';
import { uncheckedIndexAccess_UNSAFE } from '../../../../utils/uncheckedIndexAccess_UNSAFE';
import { type IClock } from '../../../Clock';
import { ondTemporaryStageMedia } from '../../ondTemporaryStage';
import {
  type Claim,
  type DropSpot,
  type DropSpotMap,
  type Piece,
  type PieceMap,
  type PlacementGrade,
} from './types';

export const log = logger.scoped('puzzle');

export const longDuration = 90;
export const defaultLeaseMs = 5000;

// small skews are ineffective at communicating a "stack" of cards.
const skews = [...range(-13, -5), ...range(6, 14)];
const traySkews = range(-13, 14);

export class PuzzleUtils {
  static GetFBPath(
    venueId: string,
    kind:
      | 'root'
      | 'game'
      | 'teams'
      | 'grade-on-placement'
      | 'completion-bonus-points'
  ): string {
    if (kind === 'root') return `puzzle/${venueId}`;
    return `puzzle/${venueId}/${kind}`;
  }

  static GetFBPathByTeam(
    venueId: string,
    teamId: TeamId,
    kind: 'pieces' | 'drop-spots'
  ): string {
    return `${PuzzleUtils.GetFBPath(venueId, 'teams')}/${teamId}/${kind}`;
  }

  static GetFBPiecePath(
    venueId: string,
    teamId: TeamId,
    pieceId: string
  ): string {
    return `${PuzzleUtils.GetFBPathByTeam(
      venueId,
      teamId,
      'pieces'
    )}/${pieceId}`;
  }

  static GetBackgroundMedia(
    block: PuzzleBlock,
    // TODO(drew): do we want this (and for other blocks) even in an audio-only world?
    fallback = ondTemporaryStageMedia
  ): Media {
    return block.fields.backgroundMedia ?? fallback;
  }

  static GridPositionLabel(row: number, col: number): string {
    return `${row + 1}${String.fromCharCode(col + 'A'.charCodeAt(0))}`;
  }

  static GridPositionLabelFromIndex(index: number, numCols: number): string {
    const row = Math.floor(index / numCols);
    const col = index % numCols;
    return PuzzleUtils.GridPositionLabel(row, col);
  }

  static BuildPuzzleConfig(
    dropSpots: PuzzleDropSpot[],
    pieces: PuzzlePiece[],
    numRows: number,
    numCols: number,
    randomize = false
  ): { dropSpots: PuzzleDropSpot[]; pieces: PuzzlePiece[] } {
    const numDropSpots = numRows * numCols;

    // NOTE(falcon): we still need to zip these up because that's the current expectation of the block.
    // however, the data model is more general.
    const pairs: [PuzzleDropSpot, PuzzlePiece][] = dropSpots
      .map((dp) => [dp, pieces.find((p) => p.value === dp.acceptedValue)])
      .filter(([_dp, p]) => p !== null) as [PuzzleDropSpot, PuzzlePiece][];

    const selected = randomize
      ? sampleSize(pairs, numDropSpots)
      : pairs.slice(0, numDropSpots);

    const selectedDropSpots = selected.map(([dp]) => dp);
    const selectedPieces = selected.map(([_dp, p]) => p);

    return {
      dropSpots: selectedDropSpots,
      pieces: selectedPieces,
    };
  }

  static BuildDropSpots(
    dropSpots: PuzzleDropSpot[],
    numCols: number
  ): DropSpotMap {
    const dropSpotMap = uncheckedIndexAccess_UNSAFE({});
    dropSpots.forEach((dp, index) => {
      const row = Math.floor(index / numCols);
      const col = index % numCols;
      const label = PuzzleUtils.GridPositionLabel(row, col);

      dropSpotMap[label] = {
        id: label,
        row,
        col,
        acceptedValue: dp.acceptedValue,
        url: MediaUtils.PickMediaUrl(dp.media) ?? '',
      };
    });
    return dropSpotMap;
  }

  static BuildPieces(pieces: PuzzlePiece[]): PieceMap {
    const result: Omit<Piece, 'position'>[] = [];
    pieces.forEach((piece) => {
      result.push({
        id: piece.id,
        value: piece.value,
        url: MediaUtils.PickMediaUrl(piece.media) ?? '',
        claim: null,
        updatedAt: null,
        grade: 'unknown',
        score: 0,
      });
    });

    const ordered = shuffle(result);
    const pieceMap: PieceMap = {};
    ordered.forEach((c) => {
      pieceMap[c.id] = {
        ...c,
        position: {
          location: 'tray',
          top: Math.random() * 0.75,
          left: Math.random() * 0.5,
        },
      };
    });
    return pieceMap;
  }

  static GetValidClaim(claim: Claim | null, clock: IClock): Claim | null {
    if (!claim) return null;

    const { timestamp } = claim;
    if (clock.now() > timestamp + defaultLeaseMs) return null;
    return claim;
  }

  static Skew(row: number, col: number, index: number): number {
    const hash = simpleHash(`${row}${col}${index}`);
    return skews[hash % skews.length] + Math.sin(hash);
  }

  static SkewInTray(pieceId: string): number {
    const hash = simpleHash(pieceId);
    return traySkews[hash % traySkews.length] + Math.sin(hash);
  }

  static GradePlacement(
    piece: Piece,
    dropSpot: DropSpot | null
  ): PlacementGrade {
    if (!dropSpot) return 'unknown';
    if (piece.value === dropSpot.acceptedValue) return 'correct';
    return 'incorrect';
  }

  static GridPoints(numRows: number, numCols: number): [number, number][] {
    const points: [number, number][] = [];
    for (let row = 0; row < numRows; row++) {
      for (let col = 0; col < numCols; col++) {
        points.push([row, col]);
      }
    }
    return points;
  }

  // note(falcon): reused from mmblock
  static UsePlaygroundMinWidth(): string {
    return bps([
      'min-w-194',
      'xl:min-w-194',
      'lp-sm:min-w-194',
      '2xl:min-w-212',
      '3xl:min-w-212',
      'lp-md:min-w-314',
      'lp-lg:min-w-314',
    ]);
  }
}

// see: https://stackoverflow.com/questions/299304/why-does-javas-hashcode-in-string-use-31-as-a-multiplier
// this is a simple hash, based on the Java hashcode impl.
function simpleHash(s: string): number {
  const hash = s
    .split('')
    .reduce(
      (prevHash, currVal) =>
        ((prevHash << 5) - prevHash + currVal.charCodeAt(0)) | 0,
      0
    );
  return hash < 0 ? -hash : hash;
}
