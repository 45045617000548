import onStageIcon from '../../assets/img/on-stage-icon.svg';

interface OnStageProps {
  zIndex?: `z${number}`;
  onStage: Nullable<boolean>;
  showLabel?: boolean;
  showDimMask?: boolean;
}

export const OnStage = ({
  zIndex,
  onStage,
  showLabel,
  showDimMask,
}: OnStageProps): JSX.Element | null => {
  if (!onStage) return null;
  return (
    <div
      className={`w-full h-full bg-lp-black-001 p-0.5 absolute left-0 top-0 flex flex-col items-center justify-center ${
        zIndex ?? ''
      }`}
    >
      <img src={onStageIcon} alt='onStage' />
      {showLabel && (
        <div className='bg-lp-red-001 text-white text-4xs font-bold py-0.5 px-1 rounded-sm'>
          ON-STAGE
        </div>
      )}
      {showDimMask && (
        <div className='w-full h-full absolute left-0 top-0 z-15 bg-lp-black-001' />
      )}
    </div>
  );
};
