import {
  type DrawingPrompt,
  type DrawingPromptBlock,
  type Media,
  MediaFormatVersion,
} from '@lp-lib/game';

import { type BPUnitlessProperties } from '../../../../breakpoints';
import logger from '../../../../logger/logger';
import { type TeamId } from '../../../../types';
import { roundToNearestOf, xDomainifyUrl } from '../../../../utils/common';
import { MediaUtils } from '../../../../utils/media';
import { type FirebaseService, FirebaseValueHandle } from '../../../Firebase';
import { ondTemporaryStageMedia } from '../../ondTemporaryStage';
import {
  type DrawingTitleVote,
  type Game,
  type MatchPromptState,
  type MiniDrawing,
  type PickedDrawing,
  type TeamStateMap,
  type TitleCreation,
} from './types';

export const log = logger.scoped('drawing-prompt');

const REVEAL_WAIT_SEC_PER_TITLE = 8;

export class DrawingPromptUtils {
  static GetBackgroundMedia(
    block: DrawingPromptBlock,
    fallback = ondTemporaryStageMedia
  ): Media {
    return block.fields.backgroundMedia ?? fallback;
  }

  static UseBoardBPSize(): BPUnitlessProperties {
    return {
      '': 480,
      xl: 480,
      'lp-sm': 480,
      '2xl': 540,
      '3xl': 600,
      'lp-md': 660,
      'lp-lg': 720,
    };
  }

  static GetCanvasBgUrl(media: Media | null): string | null {
    const url = MediaUtils.PickMediaUrl(media, {
      priority: [MediaFormatVersion.HD, MediaFormatVersion.Raw],
    });
    return url ? xDomainifyUrl(url, 'drawing') : null;
  }

  static GetTitleCreationTimeSec(teamsCount: number): number {
    return Math.max((teamsCount - 1) * 40, 10);
  }

  static GetMatchTimeSecPerDrawing(teamsCount: number): number {
    return roundToNearestOf(15 + 3 * teamsCount, 5);
  }

  static GetRevealWaitSecPerTitle(): number {
    return REVEAL_WAIT_SEC_PER_TITLE;
  }

  static GetMatchPromptTimeSec(teamsCount: number): number {
    // the total time should be 15s + 3s per team per drawing
    // In most cases, each team should submit 1 drawing, so numOfTeams should
    // be same as numOfDrawings. But it's still possible that new teams created
    // after drawing phase. For simplicity, we just treat they are same.
    const numOfDrawings = teamsCount;
    // Each drawing has 1 correct title, and (teamsCount - 1) fake titles
    const numOfTitles = teamsCount;
    return (
      (DrawingPromptUtils.GetMatchTimeSecPerDrawing(teamsCount) +
        REVEAL_WAIT_SEC_PER_TITLE * numOfTitles) *
      numOfDrawings
    );
  }

  static GetVotePoints(
    teamId: TeamId,
    vote: DrawingTitleVote,
    correct: boolean,
    score: {
      correctPoints: number;
      incorrectPoints: number;
    }
  ): Map<TeamId, number> {
    const teamPoints = new Map<TeamId, number>();
    if (correct) {
      const s = new Set<TeamId>();
      // teams who guess the correct prompt, each get points
      for (const voter of vote.voters) {
        s.add(voter.teamId);
        teamPoints.set(
          voter.teamId,
          teamPoints.get(voter.teamId) ?? 0 + score.correctPoints
        );
      }
      // if multiple teams guess the correct drawing, the team who drew it
      // only gets awarded once per team.
      teamPoints.set(
        teamId,
        teamPoints.get(teamId) ?? 0 + s.size * score.correctPoints
      );
    } else {
      // each incorrect guess earns points for the team that wrote it
      teamPoints.set(
        vote.titleTeamId,
        vote.voters.length * score.incorrectPoints
      );
    }
    return teamPoints;
  }
}

export class DrawingPromptFBUtils {
  static Path(
    venueId: string,
    kind: 'root' | 'game' | 'teams' | 'matchPrompt'
  ): string {
    if (kind === 'root') return `drawing-prompt/${venueId}`;
    return `drawing-prompt/${venueId}/${kind}`;
  }

  static TeamPath(
    teamId: TeamId,
    kind: 'prompt' | 'drawings' | 'votes' | 'pickedDrawing' | 'titleCreation'
  ): string {
    return `teams/${teamId}/${kind}`;
  }

  static AbsoluteTeamPath(
    venueId: string,
    teamId: TeamId,
    kind: Parameters<typeof this.TeamPath>[1]
  ): string {
    return `${this.Path(venueId, 'root')}/${this.TeamPath(teamId, kind)}`;
  }

  static RootHandle(
    svc: FirebaseService,
    venueId: string
  ): FirebaseValueHandle<unknown> {
    return new FirebaseValueHandle(
      svc.prefixedSafeRef(this.Path(venueId, 'root'))
    );
  }

  static GameHandle(
    svc: FirebaseService,
    venueId: string
  ): FirebaseValueHandle<Game> {
    return new FirebaseValueHandle(
      svc.prefixedSafeRef(this.Path(venueId, 'game'))
    );
  }

  static TeamStateHandle(
    svc: FirebaseService,
    venueId: string
  ): FirebaseValueHandle<TeamStateMap> {
    return new FirebaseValueHandle(
      svc.prefixedSafeRef(this.Path(venueId, 'teams'))
    );
  }

  static MatchPromptStateHandle(
    svc: FirebaseService,
    venueId: string
  ): FirebaseValueHandle<MatchPromptState> {
    return new FirebaseValueHandle(
      svc.prefixedSafeRef(this.Path(venueId, 'matchPrompt'))
    );
  }

  static PromptHandle(
    svc: FirebaseService,
    venueId: string,
    teamId: string
  ): FirebaseValueHandle<DrawingPrompt> {
    return new FirebaseValueHandle(
      svc.prefixedSafeRef(this.AbsoluteTeamPath(venueId, teamId, 'prompt'))
    );
  }

  static DrawingsHandle(
    svc: FirebaseService,
    venueId: string,
    teamId: string
  ): FirebaseValueHandle<Record<string, MiniDrawing>> {
    return new FirebaseValueHandle(
      svc.prefixedSafeRef(this.AbsoluteTeamPath(venueId, teamId, 'drawings'))
    );
  }

  static VotesHandle(
    svc: FirebaseService,
    venueId: string,
    teamId: string
  ): FirebaseValueHandle<Record<string, string>> {
    return new FirebaseValueHandle(
      svc.prefixedSafeRef(this.AbsoluteTeamPath(venueId, teamId, 'votes'))
    );
  }

  static PickedDrawingHandle(
    svc: FirebaseService,
    venueId: string,
    teamId: string
  ): FirebaseValueHandle<PickedDrawing> {
    return new FirebaseValueHandle(
      svc.prefixedSafeRef(
        this.AbsoluteTeamPath(venueId, teamId, 'pickedDrawing')
      )
    );
  }

  static TitlesHandle(
    svc: FirebaseService,
    venueId: string,
    teamId: string
  ): FirebaseValueHandle<TitleCreation> {
    return new FirebaseValueHandle(
      svc.prefixedSafeRef(
        this.AbsoluteTeamPath(venueId, teamId, 'titleCreation')
      )
    );
  }
}
