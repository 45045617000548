import {
  BlockType,
  type CreativePromptBlockAnswerData,
  Delimited,
  type QuestionBlockAnswerData,
  type RapidBlock,
  type RapidBlockAnswerData,
  RapidBlockAnswerGrade,
  type RapidBlockAnswersData,
  RapidBlockGameSessionStatus,
  type RapidCorrectAnswer,
} from '@lp-lib/game';

import logger from '../../../../logger/logger';
import { type User } from '../../../../types';
import { gameSessionStore } from '../../store';
import { type GameSubmissionInputBlockAdaptor } from '../Common/GamePlay/GameSubmissionInput';
import { log, type RapidAllAnswerMap } from './hooks';

export const normalizeRapidAnswer = (answer: string): string => {
  return answer.trim().toLowerCase();
};

export const parseRapidAllAnswers = (answers: string): RapidAllAnswerMap => {
  let correctAnswers: RapidCorrectAnswer[] = [];

  try {
    correctAnswers = JSON.parse(answers);
  } catch (e) {
    logger.error(`parse block answers error`, e);
    return {};
  }

  const correctAnswerMap: RapidAllAnswerMap = {};
  const delimited = new Delimited();

  for (let i = 0; i < correctAnswers.length; i++) {
    for (const answer of delimited.parse(correctAnswers[i].answer)) {
      const trimedAnswer = normalizeRapidAnswer(answer);
      if (trimedAnswer.length > 0) {
        correctAnswerMap[trimedAnswer] = {
          answerIndex: i.toString(),
          points: correctAnswers[i].points,
        };
      }
    }
  }

  return correctAnswerMap;
};

export const submitRapidAnswer = async (
  answer: string | null,
  teamId: string | null,
  submitter: Pick<User, 'id' | 'username'>
): Promise<RapidBlockAnswerData | null> => {
  const parsedRapidAllAnswers = parseRapidAllAnswers;

  const {
    session: { blockSession },
    refs,
  } = gameSessionStore;

  const block = (blockSession?.block ?? null) as RapidBlock | null;
  const userId = submitter.id;

  if (!block || !userId) return null;

  const teamDataRef = refs.teamData;

  if (!teamDataRef) {
    log.error(
      'GameSession.submitRapidAnswer.register',
      new Error(`Failed to register teamDataRef: ${teamId}`)
    );
    return null;
  }

  let answerData: RapidBlockAnswerData = {
    blockType: BlockType.RAPID,
    submittedAnswer: answer || '',
    grade: RapidBlockAnswerGrade.WRONG,
    points: 0,
    submittedAt: Date.now(),
    submitterUid: userId,
    submitterUsername: submitter.username,
    firstSubmittedAnswer: answer || '',
    firstSubmittedAt: Date.now(),
    firstSubmitterUid: userId,
    firstSubmitterUsername: submitter.username,
    answerIndex: '-1', // wrong answer as answerIndex:'-1'
    committed: false,
  };
  if (answer) {
    const trimedAnswer = normalizeRapidAnswer(answer);
    const allAnswers = parsedRapidAllAnswers(block.fields.answers);

    if (trimedAnswer in allAnswers) {
      answerData.points = allAnswers[trimedAnswer].points;
      answerData.grade = RapidBlockAnswerGrade.CORRECT;
      answerData.answerIndex = allAnswers[trimedAnswer].answerIndex;
    }

    const dataRef = teamDataRef.child<'data', RapidBlockAnswersData>('data');
    const ref = dataRef.child<`/${string}`, RapidBlockAnswerData>(
      `/${answerData.answerIndex}`
    );

    try {
      const resp = await ref.transaction((data) => {
        if (!data) {
          return answerData;
        }
        if (data.grade === RapidBlockAnswerGrade.CORRECT) {
          data.grade = RapidBlockAnswerGrade.DUPLICATE;
        }
        data.submittedAnswer = answer;
        data.submitterUid = userId;
        data.submittedAt = Date.now();
        data.submitterUsername = submitter.username;
        data.committed = true;
        return data;
      });
      answerData = resp.snapshot?.val() || answerData;
      answerData.committed = resp.committed;
    } catch (error) {
      answerData.committed = false;
      log.error('submit rapid answer error', error);
    }
  }

  if (!answerData.committed) {
    return answerData;
  }
  // use CF rapidScoreOnWriteHandler to update team score

  return answerData;
};

export function isRapidSubmission(
  submission:
    | CreativePromptBlockAnswerData
    | QuestionBlockAnswerData
    | RapidBlockAnswerData
    | null
): submission is RapidBlockAnswerData {
  if (!submission) return false;
  return 'grade' in submission;
}

export class RapidBlockUtils {
  static ToSubmissionInputAdaptor(
    block: RapidBlock
  ): GameSubmissionInputBlockAdaptor {
    return {
      presentStatus: RapidBlockGameSessionStatus.PRESENTING,
      countingStatus: RapidBlockGameSessionStatus.QUESTION_COUNTING,
      waitingStatus: RapidBlockGameSessionStatus.PRESENTING,
      timesupStatus: RapidBlockGameSessionStatus.QUESTION_END,
      blockTime: block.fields.questionTime,
      submit: submitRapidAnswer,
    };
  }
}
