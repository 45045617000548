import { type UID } from 'agora-rtc-sdk-ng';
import {
  createContext,
  type ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { useAnalytics } from '../../analytics/AnalyticsContext';
import { RefreshModalAnalytics } from '../../analytics/refresh';
import { getFeatureQueryParamArray } from '../../hooks/useFeatureQueryParam';
import { useIsController } from '../../hooks/useMyInstance';
import { useStatsAwareTaskQueue } from '../../hooks/useTaskQueue';
import { safeWindowReload } from '../../logger/logger';
import {
  CameraVMMediaDeviceRTCService,
  type IMediaDeviceRTCService,
  type IRTCService,
  WebRTCUtils,
} from '../../services/webrtc';
import { type CameraVideoMixer } from '../../services/webrtc/camera-video-mixer';
import { ClientTypeUtils } from '../../types';
import { uuidv4 } from '../../utils/common';
import { useAwaitFullScreenConfirmCancelModal } from '../ConfirmCancelModalContext';
import { ModalWrapper } from '../ConfirmCancelModalContext/ModalWrapper';
import { useIsLiveGamePlay } from '../Game/hooks';
import { useMyClientType } from '../Venue/VenuePlaygroundProvider';
import { useVenueId } from '../Venue/VenueProvider';
import { useJoinRTCService } from './hooks/useJoinRTCService';
import { type DebugStream, type RTCServiceMap } from './types';

interface DebugStreamContext {
  streams: DebugStream[];
  addDebugStream: (uid: UID, rtcService: IRTCService) => void;
  removeDebugStream: (uid: UID, rtcService: IRTCService) => void;
}

type CoreChannelJoined = {
  ond: boolean;
  stage: boolean;
  game: boolean;
};

type CoreChannelKeys = keyof CoreChannelJoined;

interface RTCServiceContext {
  rtcServiceMap: RTCServiceMap;
  debugStreamContext: DebugStreamContext;
  coreChannelJoined: CoreChannelJoined;
}

const Context = createContext<RTCServiceContext | null>(null);

function useRTCServiceContext(): RTCServiceContext {
  const ctx = useContext(Context);
  if (!ctx) throw new Error('RTCServiceContext is not in the tree!');
  return ctx;
}

export function useRTCService<K extends keyof RTCServiceMap>(
  key: K
): RTCServiceMap[K] {
  const ctx = useRTCServiceContext();
  return ctx.rtcServiceMap[key];
}

export function useMustCameraVMMediaDeviceRTCService(
  rtc: IMediaDeviceRTCService
): CameraVMMediaDeviceRTCService {
  return useMemo(() => {
    if (rtc instanceof CameraVMMediaDeviceRTCService) return rtc;
    throw new Error(`"${rtc.name}" is not a CameraVMMediaDeviceRTCService`);
  }, [rtc]);
}

export function useMaybeCameraVideoMixer(rtc: IMediaDeviceRTCService) {
  return 'cameraVideoMixer' in rtc
    ? (rtc.cameraVideoMixer as CameraVideoMixer)
    : null;
}

export function useIsCoreChannelJoined(
  keys: Nullable<CoreChannelKeys | CoreChannelKeys[]>
): boolean {
  const ctx = useRTCServiceContext();
  if (!keys) return false;

  if (Array.isArray(keys)) {
    for (const key of keys) {
      if (!ctx.coreChannelJoined[key]) return false;
    }
    return true;
  } else {
    return ctx.coreChannelJoined[keys];
  }
}

export function useLookupCoreChannelName(
  rtcService: IRTCService
): CoreChannelKeys | null {
  const ctx = useRTCServiceContext();
  const entries = Object.entries(ctx.rtcServiceMap);
  const validKeys = Object.keys(ctx.coreChannelJoined);
  for (const [key, value] of entries) {
    if (value === rtcService && validKeys.includes(key))
      return key as CoreChannelKeys;
  }
  return null;
}

export const useDebugStreamContext = (): DebugStreamContext => {
  const ctx = useRTCServiceContext();
  return ctx.debugStreamContext;
};

function WebRTCJoinFailed(): JSX.Element {
  const analytics = useAnalytics();
  const handleConfirm = async () => {
    const tracking = new RefreshModalAnalytics(analytics);
    await tracking.trackSuggestedRefreshModalClicked({
      reason: 'WebRTC Join Failed',
    });
    await safeWindowReload();
  };
  return (
    <ModalWrapper containerClassName='w-85' borderStyle='gray'>
      <div className='w-full h-full min-h-52 flex flex-col items-center justify-center px-6 py-4'>
        <header className='font-medium text-2xl text-center'>
          Connection Lost
        </header>
        <section className='w-full items-center text-sms text-center my-5'>
          We're unable to reconnect to a core service. Please click “Refresh” to
          attempt to resolve the issue
        </section>
        <footer className='w-full flex flex-col items-center justify-center'>
          <button
            type='button'
            onClick={handleConfirm}
            className={`btn btn-delete w-40 h-10 flex items-center justify-center`}
          >
            Refresh
          </button>
        </footer>
      </div>
    </ModalWrapper>
  );
}

export function useTriggerWebRTCJoinFailedModal(): () => Promise<void> {
  const triggerFullScreenModal = useAwaitFullScreenConfirmCancelModal();
  const analytics = useAnalytics();
  return useCallback(async () => {
    const tracking = new RefreshModalAnalytics(analytics);
    tracking.trackSuggestedRefreshModalDisplayed({
      reason: 'WebRTC Join Failed',
    });
    await triggerFullScreenModal({
      kind: 'custom',
      containerClassName: 'bg-black bg-opacity-60',
      element: () => <WebRTCJoinFailed />,
    });
  }, [analytics, triggerFullScreenModal]);
}

function WebRTCUIDBanned(): JSX.Element {
  const analytics = useAnalytics();
  const handleConfirm = async () => {
    const tracking = new RefreshModalAnalytics(analytics);
    tracking.trackSuggestedRefreshModalClicked({ reason: 'Agora UID_BANNED' });
    await safeWindowReload();
  };
  return (
    <ModalWrapper containerClassName='w-85' borderStyle='gray'>
      <div className='w-full h-full min-h-52 flex flex-col items-center justify-center px-6 py-4'>
        <header className='font-medium text-2xl text-center'>
          Connection Lost
        </header>
        <section className='w-full items-center text-sms text-center my-5'>
          We've detected that your audio may not be working properly. Please
          click "Refresh" to attempt to resolve this issue.
        </section>
        <footer className='w-full flex flex-col items-center justify-center'>
          <button
            type='button'
            onClick={handleConfirm}
            className={`btn btn-delete w-40 h-10 flex items-center justify-center`}
          >
            Refresh
          </button>
        </footer>
      </div>
    </ModalWrapper>
  );
}

export function useTriggerWebRTCUIDBannedModal(): () => Promise<void> {
  const triggerFullScreenModal = useAwaitFullScreenConfirmCancelModal();
  const analytics = useAnalytics();
  return useCallback(async () => {
    const tracking = new RefreshModalAnalytics(analytics);
    tracking.trackSuggestedRefreshModalDisplayed({
      reason: 'Agora UID_BANNED',
    });
    await triggerFullScreenModal({
      kind: 'custom',
      containerClassName: 'bg-black bg-opacity-60',
      element: () => <WebRTCUIDBanned />,
    });
  }, [analytics, triggerFullScreenModal]);
}

const isDebugging = getFeatureQueryParamArray('debug-tools') !== 'disabled';

export const useDebugStream = (
  el: HTMLElement | null,
  uid: UID | null | undefined,
  rtcService: IRTCService | undefined
): void => {
  const { addDebugStream, removeDebugStream } = useDebugStreamContext();

  const handleDoubleClick = useCallback(() => {
    if (rtcService && uid) addDebugStream(uid, rtcService);
  }, [addDebugStream, uid, rtcService]);

  useEffect(() => {
    if (!el || !isDebugging) return;
    el.addEventListener('dblclick', handleDoubleClick);
    return () => {
      el.removeEventListener('dblclick', handleDoubleClick);
    };
  }, [el, handleDoubleClick]);

  useEffect(() => {
    if (!uid || !isDebugging) return;
    return () => {
      if (rtcService) removeDebugStream(uid, rtcService);
    };
  }, [removeDebugStream, rtcService, uid]);
};

export const RTCServiceContextProvider = (props: {
  readyToJoin: boolean;
  rtcServiceMap: RTCServiceMap;
  children?: ReactNode;
}): JSX.Element => {
  const { readyToJoin, rtcServiceMap } = props;
  const venueId = useVenueId();
  const [debugStreams, setDebugStreams] = useState<DebugStream[]>([]);
  const isLiveGamePlay = useIsLiveGamePlay();
  const handleJoinFailure = useTriggerWebRTCJoinFailedModal();
  const handleUIDBanned = useTriggerWebRTCUIDBannedModal();
  const isAudience = ClientTypeUtils.isAudience(useMyClientType());
  const isController = useIsController();

  const addDebugStream = useCallback(
    (uid: UID, rtcService: IRTCService): void => {
      setDebugStreams((prev) => {
        const idx = prev.findIndex(
          (s) => s.uid === uid && s.rtcService === rtcService
        );
        if (idx === -1) {
          return [...prev, { id: uuidv4(), uid, rtcService }];
        }
        return prev;
      });
    },
    []
  );

  const removeDebugStream = useCallback(
    (uid: UID, rtcService: IRTCService): void => {
      setDebugStreams((prev) => {
        const idx = prev.findIndex(
          (s) => s.uid === uid && s.rtcService === rtcService
        );
        if (idx > -1) prev.splice(idx, 1);
        return [...prev];
      });
    },
    []
  );

  const stageJoined = useJoinRTCService(
    rtcServiceMap.stage,
    WebRTCUtils.ChannelFor('stage', venueId),
    'host',
    useStatsAwareTaskQueue({
      shouldProcess: true,
      stats: 'task-queue-rtc-stage-join-ms',
    }),
    {
      ready: readyToJoin,
      subscribeEvents: true,
      handleFailure: isAudience ? handleJoinFailure : undefined,
    }
  );

  const ondJoined = useJoinRTCService(
    rtcServiceMap.ond,
    WebRTCUtils.ChannelFor('ond', venueId),
    isController ? 'host' : 'audience',
    useStatsAwareTaskQueue({
      shouldProcess: true,
      stats: 'task-queue-rtc-ond-join-ms',
    }),
    {
      ready: readyToJoin && !isLiveGamePlay,
      subscribeEvents: true,
      handleFailure: isAudience ? handleJoinFailure : undefined,
    }
  );

  const gameJoined = useJoinRTCService(
    rtcServiceMap.game,
    WebRTCUtils.ChannelFor('game', venueId),
    isController ? 'host' : 'audience',
    useStatsAwareTaskQueue({
      shouldProcess: true,
      stats: 'task-queue-rtc-game-join-ms',
    }),
    {
      ready: readyToJoin,
      subscribeEvents: true,
      handleFailure: isAudience ? handleJoinFailure : undefined,
    }
  );

  useEffect(() => {
    if (!isAudience) return;

    // NOTE(drew): We can remove this once we discover why Agora is banning UIDs
    // on reconnect. 2024-09-18.

    const aborter = new AbortController();

    rtcServiceMap.stage.on(
      'connection-state-disconnected-uid-banned',
      () => handleUIDBanned(),
      { signal: aborter.signal }
    );

    rtcServiceMap.ond.on(
      'connection-state-disconnected-uid-banned',
      () => handleUIDBanned(),
      { signal: aborter.signal }
    );

    rtcServiceMap.game.on(
      'connection-state-disconnected-uid-banned',
      () => handleUIDBanned(),
      { signal: aborter.signal }
    );

    return () => {
      aborter.abort();
    };
  }, [
    handleUIDBanned,
    isAudience,
    rtcServiceMap.game,
    rtcServiceMap.ond,
    rtcServiceMap.stage,
  ]);

  // Destructuring to avoid a new object reference of the map itself. The
  // Services remain stable, but the map changes on any re-render.
  const { broadcast, game, music, ond, stage, audience, audienceV2 } =
    props.rtcServiceMap;

  const ctxValue = useMemo(
    () => ({
      rtcServiceMap: {
        broadcast,
        game,
        music,
        ond,
        stage,
        audience,
        audienceV2,
      },
      debugStreamContext: {
        streams: debugStreams,
        addDebugStream,
        removeDebugStream,
      },
      coreChannelJoined: {
        stage: stageJoined,
        ond: ondJoined,
        game: gameJoined,
      },
    }),
    [
      addDebugStream,
      audience,
      audienceV2,
      broadcast,
      debugStreams,
      game,
      gameJoined,
      music,
      ond,
      ondJoined,
      removeDebugStream,
      stage,
      stageJoined,
    ]
  );
  return <Context.Provider value={ctxValue}>{props.children}</Context.Provider>;
};
