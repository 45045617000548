export function indicesFromMiddle<T>(
  arr: T[],
  cb: (idx: number) => void
): void {
  const middle = Math.round(arr.length / 2) - 1;
  for (let i = 0; i <= middle; i++) {
    const right = middle + i + 1;
    const left = middle - i;
    if (left >= 0) cb(left);
    if (right < arr.length) cb(right);
  }
}
