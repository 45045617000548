import {
  type DtoChannel,
  type DtoChannelMember,
  type DtoChannelProgramLink,
} from '@lp-lib/api-service-client/public';

import { type Organization, type Organizer } from './organization';

export enum CelebrationMessageFrequency {
  Daily = 'daily',
  Weekly = 'weekly',
  Monthly = 'monthly',
}

export enum CelebrationEventType {
  Birthday = 'birthday',
  WorkAnniversary = 'work-anniversary',
}

export interface Birthday {
  month: number;
  day: number;
}

export interface WorkStartDate {
  year: number;
  month: number;
  day: number;
}

export interface CelebrationEventMessageSettings {
  enabled: boolean;
  customizedMessage: string;
  includeMedia: boolean;
}

export interface CelebrationMessageSettings {
  sendSeconds: number;
  timezone: string;
  frequency: CelebrationMessageFrequency;
  groupByCelebrationType: boolean;
  birthday: CelebrationEventMessageSettings;
  workAnniversary: CelebrationEventMessageSettings;
}

export interface CelebrationStats {
  birthdaysCount: number;
  anniversariesCount: number;
  celebrateBirthdaysCount: number;
}

export interface Celebration {
  id: string;
  messageSettings: CelebrationMessageSettings;
  stats: CelebrationStats;
  createdAt: string;
}

export interface BundledCelebration extends Celebration {
  organization: Organization | null;
  channel: DtoChannel | null;
  programLink: DtoChannelProgramLink | null;
  owner: Organizer | null;
}

export interface CelebrationParticipant {
  uid: string;
  celebrationId: string;
  birthday: Birthday | null;
  celebrateBirthday: boolean;
  workStartDate: WorkStartDate | null;
  channelMember: DtoChannelMember;
}

export interface CelebrationEventTarget {
  localRealTime: string;
  participant: CelebrationParticipant;
}

export interface CelebrationEvent {
  type: CelebrationEventType;
  sendTime: string;
  targets: CelebrationEventTarget[];
}
