import { type WaterCoolerNotification } from '../../../types';
import { type BaseNotificationProps } from '../../Notification/type';

function WaterCoolerNotificationView(
  props: BaseNotificationProps & {
    notification: WaterCoolerNotification;
  }
): JSX.Element {
  const metadata = props.notification.metadata;

  return (
    <div className='w-full px-5 py-2 rounded-xl flex items-center justify-center bg-secondary'>
      {metadata.content}
    </div>
  );
}

// Required for lazy import
// eslint-disable-next-line import/no-default-export
export default WaterCoolerNotificationView;
