import { getStaticAssetPath } from '../../utils/assets';
import { createSeedablePRNG } from '../../utils/rng';

const animatedAvatars = [
  // a bit redundant, but i prefer the explicitness
  ['aa-01-01', 'aa-01-02', 'aa-01-03', 'aa-01-04', 'aa-01-05'],
  ['aa-02-01', 'aa-02-02', 'aa-02-03', 'aa-02-04', 'aa-02-05'],
  ['aa-03-01', 'aa-03-02', 'aa-03-03', 'aa-03-04', 'aa-03-05'],
  ['aa-04-01', 'aa-04-02', 'aa-04-03', 'aa-04-04', 'aa-04-05'],
].map((characters) =>
  characters.map((filename) =>
    getStaticAssetPath(`videos/animated-avatars/${filename}.mp4`)
  )
);

const allAnimatedAvatars = animatedAvatars.flat();

export function getRandomAnimatedAvatar(
  seed: string = new Date().toISOString()
) {
  const prng = createSeedablePRNG(seed);
  const index = Math.floor(prng() * allAnimatedAvatars.length);
  return allAnimatedAvatars[index];
}

export function getRandomAnimatedAvatarVariant(
  characterIndex: number,
  seed: string = new Date().toISOString()
) {
  const prng = createSeedablePRNG(seed);
  const characterVariants =
    animatedAvatars[characterIndex % animatedAvatars.length];

  const index = Math.floor(prng() * characterVariants.length);
  return characterVariants[index];
}
