import {
  createContext,
  type ReactNode,
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from 'react';

import { useInstance } from '../../hooks/useInstance';
import { useIsCoordinator } from '../../hooks/useMyInstance';
import { BrowserIntervalCtrl } from '../../utils/BrowserIntervalCtrl';
import { assertExhaustive } from '../../utils/common';
import { useDatabaseRef, useFirebaseValue } from '../Firebase';
import { useVenueId } from '../Venue/VenueProvider';
import {
  type Config,
  type Control,
  type LocalConfig,
  type RemoteConfig,
  type SwitchNoticeConfigurator,
} from './types';
import { SwitchNoticeUtils } from './utils';

type SwitchNoticeContext = {
  configurator: SwitchNoticeConfigurator;
  indicator: JSX.Element | null;
};

const Context = createContext<SwitchNoticeContext | null>(null);

function render(config: Config): JSX.Element | null {
  if (config.countdownSec <= 0 || config.renderer === null) return null;

  const renderer = config.renderer;

  let body: JSX.Element | string | null = null;
  switch (renderer.type) {
    case 'native':
      body = renderer.render({ remainingSec: config.countdownSec });
      break;
    case 'template':
      body = renderer.template.replace(
        '{remainingSec}',
        `${config.countdownSec}`
      );
      break;
    case 'custom':
      const render = SwitchNoticeUtils.GetCustomRender(renderer.key);
      body = render ? render({ remainingSec: config.countdownSec }) : null;
      break;
    default:
      break;
  }

  if (body === null) return null;

  return (
    <div className='w-full flex items-center justify-center absolute top-0'>
      <div className='bg-townhall-switching-status text-white text-lg font-bold flex items-center justify-center min-w-140 h-8.5 z-45'>
        {body}
      </div>
    </div>
  );
}

export function useInternalConfigurator(
  venueId: string,
  isCoordinator: boolean
): readonly [JSX.Element | null, SwitchNoticeConfigurator] {
  const [localConfig, setLocalConfig] = useState<LocalConfig | null>(null);
  const path = useInstance(() => `venues/${venueId}/switch-status`);
  const ref = useDatabaseRef<Nullable<RemoteConfig, false>>(path);
  const [remoteConfig] = useFirebaseValue<RemoteConfig | null>(path, {
    enabled: true,
    seedValue: null,
    seedEnabled: false,
    readOnly: true,
  });
  const setRemoteConfig = useCallback(
    async (val: RemoteConfig | null) => {
      if (val === null) {
        await ref.remove();
        await ref.onDisconnect().cancel();
      }
      await ref.set(val);
      await ref.onDisconnect().remove();
    },
    [ref]
  );

  const updateConfig = useCallback(
    async (config: Config | null) => {
      if (config === null) {
        setLocalConfig(null);
        await setRemoteConfig(null);
        return;
      }
      const target = config.target;
      switch (target) {
        case 'local':
          setLocalConfig(config);
          break;
        case 'remote':
          await setRemoteConfig(config);
          break;
        default:
          assertExhaustive(target);
          break;
      }
    },
    [setRemoteConfig]
  );

  const config = localConfig || remoteConfig;
  const indicator = config ? render(config) : null;

  const ctrl = useRef<{
    aborter: AbortController;
    timer: BrowserIntervalCtrl;
  } | null>(null);
  const configurator = useCallback(
    async (config: Config): Promise<Control> => {
      if (config.target === 'remote' && !isCoordinator) {
        throw new Error('only coordinator can set up the remote switch notice');
      }
      const clear = async () => {
        ctrl.current?.timer.clear();
        ctrl.current = null;
        await updateConfig(null);
      };
      if (ctrl.current) {
        ctrl.current.aborter.abort();
        await clear();
      }
      await updateConfig(config);
      ctrl.current = {
        timer: new BrowserIntervalCtrl(),
        aborter: new AbortController(),
      };
      return {
        abort: () => ctrl.current?.aborter.abort(),
        response: new Promise((resolve) => {
          ctrl.current?.aborter.signal.addEventListener('abort', async () => {
            await clear();
            resolve('cancel');
          });
          let remainingSec = config.countdownSec;
          ctrl.current?.timer.set(async () => {
            if (remainingSec === 0) {
              await clear();
              resolve('done');
              return;
            }
            remainingSec -= 1;
            await updateConfig({
              ...config,
              countdownSec: remainingSec,
            });
          }, 1000);
        }),
      };
    },
    [isCoordinator, updateConfig]
  );

  return [indicator, configurator] as const;
}

export function SwitchNoticeProvider(props: {
  children?: ReactNode;
}): JSX.Element {
  const venueId = useVenueId();
  const isCoordinator = useIsCoordinator();
  const [indicator, configurator] = useInternalConfigurator(
    venueId,
    isCoordinator
  );

  const contextValue = useMemo(
    () => ({
      indicator,
      configurator,
    }),
    [configurator, indicator]
  );

  return (
    <Context.Provider value={contextValue}>{props.children}</Context.Provider>
  );
}

export function SwitchNotice(): JSX.Element | null {
  const ctx = useContext(Context);
  return ctx?.indicator ?? null;
}

export function useAwaitSwitchNotice(): SwitchNoticeConfigurator {
  const ctx = useContext(Context);
  if (!ctx) throw new Error('SwitchNoticeProvider context not in tree');
  return ctx.configurator;
}
