import { captureMessage } from '@sentry/remix';
import shuffle from 'lodash/shuffle';
import sum from 'lodash/sum';

import { RTDBServerValueTIMESTAMP } from '@lp-lib/firebase-typesafe';
import { BlockType, type TeamRelayBlock } from '@lp-lib/game';

import logger from '../../../../logger/logger';
import { apiService } from '../../../../services/api-service';
import {
  type SequenceConfig,
  type TeamRelayBlockSettings,
  type TeamRelayLevel,
} from '../../../../types/block';
import { type TeamId } from '../../../../types/team';
import {
  nullOrUndefined,
  randomBetween,
  randomPick,
  randomString,
} from '../../../../utils/common';
import {
  type GameSettings,
  type Grid,
  type PlayerId,
  type Progress,
  type RelayNode,
  RelayNodeState,
  RenderType,
  type Sequence,
  type SequenceGridParams,
} from './types';
import { uncheckedIndexAccess_UNSAFE } from '../../../../utils/uncheckedIndexAccess_UNSAFE';

export const log = logger.scoped('team-relay');

/**
 * generate a random sequence grid
 * number notes:
 *   0: placeholder
 *   1: standard node
 *  -1: start of the hold node
 *   2: joint of the hold node
 * -99: end of the hold node
 * @param {Object} config - configuration
 * @param {Object} config.rows - rows of the grid (num of players)
 * @param {Object} config.cols - cols of the grid (max columns)
 * @param {Object} config.numOfHoldNodes - num of hold nodes the sequence should create
 * @param {Object} config.holdNode - hold node configuration
 * @param {Object} config.holdNode.num - num of hold nodes the sequence should create
 * @param {Object} config.holdNode.minSpans - minimal spans of each node (start/end excluded)
 * @param {Object} config.holdNode.maxSpans - maximum spans of each node (start/end excluded)
 * @returns
 */
export function buildSequenceGrid(config: SequenceGridParams): Grid {
  if (config.cols < config.rows) {
    throw new Error(
      `cols can not be smaller than rows, ${JSON.stringify(config)}`
    );
  }
  const { rows: w, cols: h } = config;
  const grid = new Array<number[]>(h);
  for (let i = 0; i < h; i++) {
    grid[i] = new Array<number>(w).fill(0);
  }

  // generate the spans of hold nodes
  // minSpans means how many slots (include start/end) the hold node needs
  const minSpans = 2 + Math.min(w - 1, config.holdNode.minSpans ?? 0);
  // Adjust the total num of hold nodes the sequence can have
  const n = Math.min(Math.floor(h / minSpans), config.holdNode.num);
  const spans: number[] = [];
  let allocatable = h;
  for (let i = 0; i < n; i++) {
    // reserve the least need minSpans for other nodes, calcuate the max allocatable spans
    const allocatableMaxSpans = allocatable - minSpans * (n - i - 1);
    const maxSpans = nullOrUndefined(config.holdNode.maxSpans)
      ? allocatableMaxSpans
      : Math.min(allocatableMaxSpans, 2 + config.holdNode.maxSpans);
    const allocated = w === 1 ? minSpans : randomBetween(minSpans, maxSpans);
    allocatable -= allocated;
    spans.push(allocated);
  }

  // assign the starting position of each span
  // [-1, 2, 2, 2, -99]
  const shuffled = shuffle(spans);

  let left = 0;
  for (let i = 0; i < shuffled.length; i++) {
    const span = shuffled[i];
    const remainings = sum(shuffled.slice(i + 1));
    const right = h - remainings - span;
    const pos = randomBetween(left, right);
    const col = randomBetween(0, w - 1);
    for (let j = pos; j < pos + span; j++) {
      if (j === pos) {
        grid[j][col] = -1;
      } else if (j === pos + span - 1) {
        grid[j][col] = -99;
      } else {
        grid[j][col] = 2;
      }
    }
    left = pos + span;
  }

  do {
    for (let i = 0; i < h; i++) {
      // check if current row has either the start node or end node
      let skip = false;
      for (let j = 0; j < w; j++) {
        if (grid[i][j] === -1 || grid[i][j] === -99) {
          skip = true;
          break;
        }
      }
      if (skip) continue;
      // find the cols are still empty
      const cols: number[] = [];
      for (let j = 0; j < w; j++) {
        if (grid[i][j] === 0) cols.push(j);
      }
      const col = randomPick(cols);
      grid[i][col] = 1;
    }

    let hasEmptyCol = false;
    for (let i = 0; i < w; i++) {
      let empty = true;
      for (let j = 0; j < h; j++) {
        if (grid[j][i] !== 0) {
          empty = false;
          break;
        }
      }
      if (empty) {
        hasEmptyCol = true;
        break;
      }
    }
    if (!hasEmptyCol) break;
    // detect empty col, reset grid and try again
    for (let i = 0; i < w; i++) {
      for (let j = 0; j < h; j++) {
        if (grid[j][i] === 1) {
          grid[j][i] = 0;
        }
      }
    }
  } while (true);

  if (grid.length === 0) return grid;
  // rotate the grid -90 degrees
  return grid[0].map((_, index) =>
    grid.map((row) => row[row.length - 1 - index])
  );
}

export function generateSequence(
  playerIds: PlayerId[],
  sequenceConfig: SequenceConfig,
  options?: { minSpans?: number }
): Sequence {
  const config: SequenceGridParams = {
    rows:
      sequenceConfig.maxColumns < playerIds.length
        ? sequenceConfig.maxColumns
        : playerIds.length,
    cols: sequenceConfig.maxColumns,
    holdNode: {
      num: sequenceConfig.numOfHoldNodes,
      minSpans: options?.minSpans,
    },
  };
  const grid = buildSequenceGrid(config);
  const nodeGrid: Grid<RelayNode> = [];
  for (let i = 0; i < config.rows; i++) {
    const row: RelayNode[] = [];
    let inputKeyForHoldStart: string | null = null;
    for (let j = 0; j < config.cols; j++) {
      let inputKey: string | null = null;
      if (sequenceConfig.keys === 'letters') {
        switch (grid[i][j]) {
          case RenderType.Tap:
            inputKey = randomString(1);
            break;
          case RenderType.HoldStart:
            inputKey = randomString(1);
            inputKeyForHoldStart = inputKey;
            break;
          case RenderType.HoldJoin:
            inputKey = inputKeyForHoldStart;
            break;
          case RenderType.HoldEnd:
            inputKey = inputKeyForHoldStart;
            inputKeyForHoldStart = null;
            break;
          case RenderType.Placeholder:
            break;
          default:
            break;
        }
      }
      row.push({
        seqId: j,
        inputKey: inputKey,
        renderType: grid[i][j], // TODO: no type gard
      });
    }
    nodeGrid.push(row);
  }
  return { grid: nodeGrid, direction: sequenceConfig.direction };
}

const IGNORABLE_KEYS = new Set([
  'Down',
  'ArrowDown',
  'Up',
  'ArrowUp',
  'Left',
  'ArrowLeft',
  'Right',
  'ArrowRight',
  'Enter',
  'Esc',
  'Escape',
  'Shift',
  'Control',
  'Alt',
  'Meta',
  'Backspace',
  'Tab',
]);

export function isIgnorableKey(key: string): boolean {
  return IGNORABLE_KEYS.has(key);
}

export function getDifficultyLevel(block: TeamRelayBlock): number {
  return block.fields.difficultyLevel;
}

export async function getTeamRelayGameTime(
  block: TeamRelayBlock,
  preloadLevel?: TeamRelayLevel
): Promise<number> {
  const level =
    preloadLevel ?? (await getTeamRelayLevel(getDifficultyLevel(block)));
  if (!level) return 0;
  const total = level.configs.length * block.fields.sequenceTime;
  return Math.ceil(total / 5) * 5;
}

export async function getTeamRelayLevel(
  levelIdx: number,
  preloadBlockSettings?: TeamRelayBlockSettings
): Promise<TeamRelayLevel | null> {
  const blockSettings =
    preloadBlockSettings ??
    (
      await apiService.block.getBlockSettings<TeamRelayBlockSettings>(
        BlockType.TEAM_RELAY
      )
    ).data;
  if (!blockSettings) return null;
  const levels = blockSettings.levels;
  const level =
    levelIdx >= 1 && levelIdx <= levels.length ? levels[levelIdx - 1] : null;
  if (level) return level;
  return levels.length > 0 ? levels[0] : null;
}

export function getTeamRelayFBPath(
  venueId: string,
  kind:
    | 'root'
    | 'game'
    | 'game-settings'
    | 'teams'
    | 'game-progress'
    | 'game-progress-summary'
): string {
  if (kind === 'root') return `team-relay/${venueId}`;
  return `team-relay/${venueId}/${kind}`;
}

export function getTeamRelayFBPathByTeam(
  venueId: string,
  teamId: TeamId,
  kind: 'playerIds' | 'sequence' | 'progress' | 'progress-summary'
): string {
  if (kind === 'progress') {
    return `${getTeamRelayFBPath(venueId, 'game-progress')}/${teamId}`;
  } else if (kind === 'progress-summary') {
    return `${getTeamRelayFBPath(venueId, 'game-progress-summary')}/${teamId}`;
  }
  return `${getTeamRelayFBPath(venueId, 'teams')}/${teamId}/${kind}`;
}

export function buildTeamSequenceData(
  venueId: string,
  teamId: TeamId,
  data: {
    playerIds?: PlayerId[];
    sequence?: Sequence;
    progress?: Progress;
  }
): Record<string, unknown> {
  const updates = uncheckedIndexAccess_UNSAFE({});
  if (data.playerIds !== undefined) {
    updates[getTeamRelayFBPathByTeam(venueId, teamId, 'playerIds')] =
      data.playerIds;
  }
  updates[getTeamRelayFBPathByTeam(venueId, teamId, 'sequence')] =
    data.sequence ?? null;
  updates[getTeamRelayFBPathByTeam(venueId, teamId, 'progress')] =
    data.progress ?? null;
  return updates;
}

export function makeInitProgess(idx: number, initedGameTime: number): Progress {
  return {
    idx: idx,
    currentNodeIdx: 0,
    lastNodeState: RelayNodeState.NotPlayed,
    localUpdatedAt: Date.now(),
    updatedAt: RTDBServerValueTIMESTAMP,
    initedGameTime,
  };
}

export function isSequenceFinished(
  progress: Progress,
  settings: GameSettings
): boolean {
  if (progress.idx >= settings.level.configs.length) {
    captureMessage('unexpected team relay progress index', {
      extra: {
        level: JSON.stringify(settings.level),
        progress: JSON.stringify(progress),
      },
    });
    return false;
  }
  const currSequenceConfig = settings.level.configs[progress.idx];
  return (progress.currentNodeIdx || 0) >= currSequenceConfig.maxColumns;
}
