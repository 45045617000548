import { useEffect, useRef, useState } from 'react';

import onStageLoading from '../../assets/img/on-stage-loading.png';
import { type Participant } from '../../types';
import { useCloneSingletonMediaStream } from '../Device';
import { useParticipantFlag } from '../Player';
import { useSelectStageMember } from './Context';

interface InvitedNoticeProps {
  className?: string;
  contentClassName?: string;
  zLayer?: string;
  clientId: Participant['clientId'] | null;
  showLocalStream?: boolean;
  showMessage?: boolean;
}

export function InvitedNotice({
  className,
  contentClassName,
  zLayer,
  clientId,
  showLocalStream,
  showMessage,
}: InvitedNoticeProps): JSX.Element {
  const [showInviteNotice, setShowInviteNotice] = useState<boolean>(false);
  const localStream = useCloneSingletonMediaStream();
  const rootRef = useRef<HTMLDivElement>(null);
  const videoRef = useRef<HTMLVideoElement>(null);
  const animRef = useRef<Animation | null>(null);
  const stageMember = useSelectStageMember(clientId);
  const onStage = useParticipantFlag(clientId, 'onStage');

  useEffect(() => {
    if (onStage && !stageMember?.disableInvitedNotice) {
      const timeElasped = Date.now() - (stageMember?.joinedAt || 0);
      if (timeElasped < 10000) setShowInviteNotice(true);
    }
  }, [onStage, stageMember?.disableInvitedNotice, stageMember?.joinedAt]);

  useEffect(() => {
    if (!localStream || !videoRef.current) {
      return;
    }
    if (showInviteNotice) {
      videoRef.current.srcObject = localStream;
    } else {
      videoRef.current.srcObject = null;
    }
  }, [localStream, showInviteNotice]);

  useEffect(() => {
    if (showInviteNotice && rootRef.current) {
      if (animRef.current) animRef.current.cancel();

      const options: EffectTiming = {
        easing: 'ease-in-out',
        duration: 2500,
        fill: 'both',
      };

      const keyframes: Keyframe[] = [
        { opacity: 0, offset: 0 },
        { opacity: 1, offset: 0.05 },
        { opacity: 1, offset: 0.95 },
        { opacity: 0 },
      ];

      animRef.current = rootRef.current.animate(keyframes, options);
      animRef.current.play();
      animRef.current.finished.then(() => {
        setShowInviteNotice(false);
        animRef.current = null;
      });
    }
  }, [showInviteNotice]);

  useEffect(() => {
    return () => {
      if (animRef.current) {
        animRef.current.cancel();
        animRef.current = null;
      }
    };
  }, []);

  return (
    <div
      ref={rootRef}
      className={`bg-lp-black-001 absolute left-0 top-0 flex items-center justify-center opacity-0 ${className} ${
        showInviteNotice
          ? `visible ${zLayer} pointer-events-on`
          : 'invisible pointer-events-off'
      }`}
    >
      <div
        className={`${
          contentClassName ? contentClassName : 'w-full h-full'
        } flex items-center justify-center relative`}
      >
        <img
          src={onStageLoading}
          alt='loading'
          className='w-full h-full absolute left-0 top-0'
        />
        {showLocalStream && (
          <video
            ref={videoRef}
            className='w-45 h-45 rounded-full absolute border-2 border-black-002 object-cover'
            muted
            autoPlay
          />
        )}
        {showMessage && (
          <div className='text-white text-2xl font-bold absolute bottom-20'>
            The Host invited you on stage!
          </div>
        )}
      </div>
    </div>
  );
}
