/* eslint-disable @lp-lib/eslint-rules/encapsulated-redux */
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { toast } from 'react-toastify';

import { useShallowEqualSelector } from '../../hooks/useShallowEqualSelector';
import { useAppDispatch } from '../../store/hooks';
// eslint-disable-next-line no-restricted-imports
import {
  dismissNotification,
  dismissNotificationByType,
  selectNotifications,
  sendNotification,
} from '../../store/notificationSlice';
import {
  type Notification,
  type NotificationType,
} from '../../types/notification';
import { type NotificationDataSource } from './type';

export const useNotificationDataSourceRedux = (): NotificationDataSource => {
  const dispatch = useAppDispatch();
  const notifications = useShallowEqualSelector(selectNotifications);

  const getAll = useCallback((): Notification[] => {
    return notifications;
  }, [notifications]);

  const send = useCallback(
    (n: Notification): void => {
      dispatch(sendNotification(n));
    },
    [dispatch]
  );
  const dismiss = useCallback(
    (id: string): void => {
      dispatch(dismissNotification(id));
    },
    [dispatch]
  );
  const dismissByType = useCallback(
    (type: NotificationType, excludeNotificationId?: string): void => {
      dispatch(dismissNotificationByType(type, excludeNotificationId));
    },
    [dispatch]
  );

  return useMemo(() => {
    return {
      getAll,
      send,
      dismiss,
      dismissByType,
    };
  }, [dismiss, dismissByType, getAll, send]);
};

export const useNotificationDataSourceReactState =
  (): NotificationDataSource => {
    const [notifications, setNotifications] = useState<Notification[]>([]);
    const notificationsRef = useRef<Notification[]>(notifications);

    useEffect(() => {
      notificationsRef.current = notifications;
    }, [notifications]);

    const getAll = useCallback((): Notification[] => {
      return notifications;
    }, [notifications]);

    const send = useCallback((n: Notification): void => {
      setNotifications([n, ...notificationsRef.current]);
    }, []);
    const dismiss = useCallback((id: string): void => {
      const index = notificationsRef.current.findIndex((n) => n.id === id);
      if (index !== -1) {
        notificationsRef.current.splice(index, 1);
        setNotifications([...notificationsRef.current]);
        toast.dismiss(id);
      }
    }, []);
    const dismissByType = useCallback(
      (type: NotificationType, excludeNotificationId?: string): void => {
        const updated: Notification[] = [];
        notificationsRef.current.forEach((n) => {
          if (n.type === type && n.id !== excludeNotificationId) {
            toast.dismiss(n.id);
          } else {
            updated.push(n);
          }
        });
        notificationsRef.current = updated;
        setNotifications([...notificationsRef.current]);
      },
      []
    );
    return useMemo(() => {
      return {
        getAll,
        send,
        dismiss,
        dismissByType,
      };
    }, [dismiss, dismissByType, getAll, send]);
  };

interface NotificationContextValue {
  datasource: NotificationDataSource;
}

const NotificationContext = React.createContext<NotificationContextValue>(
  {} as NotificationContextValue
);

export const useNotificationDataSource = (): NotificationDataSource => {
  const ctx = useContext(NotificationContext);
  return ctx.datasource;
};

export const NotificationContextProvider = (props: {
  datasource: NotificationDataSource;
  children?: React.ReactNode;
}): JSX.Element => {
  const ctxValue = useMemo(
    () => ({ datasource: props.datasource }),
    [props.datasource]
  );
  return (
    <NotificationContext.Provider value={ctxValue}>
      {props.children}
    </NotificationContext.Provider>
  );
};
