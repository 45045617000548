import { useCallback, useMemo, useRef, useState } from 'react';

interface StartOptions {
  reset?: boolean;
  newInitial?: number;
  onFinished?: () => void;
}
interface StopOptions {
  setToZero?: boolean;
}
export interface CountdownOperations {
  start: (opts?: StartOptions) => void;
  stop: (opts?: StopOptions) => void;
  reset: (newInitial?: number | undefined) => void;
}

export function useCountdown(
  initial: number,
  interval = 1000
): [number, CountdownOperations] {
  if (initial < 0) throw new Error('initial value should be positive');

  const [value, setValue] = useState(initial);
  const timerIdRef = useRef<ReturnType<typeof setInterval> | null>(null);

  const clearTimer = useCallback(() => {
    if (timerIdRef.current) {
      clearInterval(timerIdRef.current);
      timerIdRef.current = null;
    }
  }, []);

  const start = useCallback(
    (opts?: StartOptions) => {
      clearTimer();
      if (opts?.reset) setValue(opts?.newInitial ?? initial);
      timerIdRef.current = setInterval(() => {
        setValue((current) => {
          if (current <= 0) {
            clearTimer();
            opts?.onFinished && opts.onFinished();
            return 0;
          }
          return current - 1;
        });
      }, interval);
    },
    [initial, interval, clearTimer]
  );

  const stop = useCallback(
    (opts?: StopOptions) => {
      if (opts?.setToZero) setValue(0);
      clearTimer();
    },
    [clearTimer]
  );

  const reset: CountdownOperations['reset'] = useCallback(
    (newInitial) => {
      clearTimer();
      setValue(typeof newInitial === 'number' ? newInitial : initial);
    },
    [clearTimer, initial]
  );

  const op = useMemo(() => ({ start, stop, reset }), [start, stop, reset]);

  return useMemo(() => [value, op], [value, op]);
}
