import React from 'react';

const ChatSendIcon = (): JSX.Element => {
  return (
    <svg
      className='w-3.5 h-3.5 fill-current'
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 15 14'
    >
      <path d='M.8 14a.642.642 0 01-.63-.532.67.67 0 01.012-.294l.928-3.66a.648.648 0 01.594-.485.64.64 0 01.267.045.658.658 0 01.38.78l-.467 1.839a.216.216 0 00.185.267.214.214 0 00.118-.021l9.499-4.746a.215.215 0 00.087-.307.215.215 0 00-.087-.079L2.16 2.047a.213.213 0 00-.298.123.216.216 0 00-.006.12l.338 1.405A3.476 3.476 0 003.416 5.61c.61.483 1.363.745 2.139.745h1.36a.659.659 0 01.67.626.649.649 0 01-.392.615.64.64 0 01-.25.051H2.326a.641.641 0 01-.625-.494L.18.822a.674.674 0 01.117-.58.643.643 0 01.788-.174L13.803 6.42a.644.644 0 01.356.579.649.649 0 01-.356.579L1.086 13.932A.638.638 0 01.8 14z' />
    </svg>
  );
};

const Memoed = React.memo(ChatSendIcon);
export { Memoed as ChatSendIcon };
