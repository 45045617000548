import pluralize from 'pluralize';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { usePrevious, usePromise } from 'react-use';

import { EnumsMediaScene } from '@lp-lib/api-service-client/public';
import {
  type BlockFields,
  BlockType,
  type TeamRelayBlock,
  type TeamRelayBlockMedia,
  TeamRelayBlockMode,
} from '@lp-lib/game';

import { bps } from '../../../../breakpoints';
import { apiService } from '../../../../services/api-service';
import {
  type TeamRelayBlockSettings,
  type TeamRelayLevel,
} from '../../../../types/block';
import {
  AdditionalSettings,
  AdditionalSharedSettingsEditor,
  BlockMediaEditor,
  BlockMediaUploader,
  EditorBody,
  EditorLayout,
  type EditorProps,
  type Option,
  RHFCheckbox,
  RHFSelectField,
  useEditor,
} from '../Common/Editor/Internal';

const modeOptions: { [key in TeamRelayBlockMode]: Option } = {
  [TeamRelayBlockMode.MovingTowardsGoal]: {
    value: TeamRelayBlockMode.MovingTowardsGoal,
    label: 'Moving Towards Goal',
  },
};

const pointOptions: Option[] = [100, 200, 300, 400, 500, 0].map((i) => ({
  value: i,
  label: `${i}`,
}));

const timeOptions: Option[] = [
  1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 20, 30,
].map((i) => ({ label: `${i} ${pluralize('second', i)}`, value: i }));

const MEDIA_RESPONSIVE_WIDTH = bps([
  'w-60',
  'xl:w-64',
  'lp-sm:w-68',
  '2xl:w-72',
  '3xl:w-76',
  'lp-md:w-85',
  'lp-lg:w-85',
]);

type LevelOption = Option & TeamRelayLevel;

function useLevelOptions(): LevelOption[] {
  const mounted = usePromise();
  const [options, setOptions] = useState<LevelOption[]>([]);
  useEffect(() => {
    async function init() {
      const resp = await mounted(
        apiService.block.getBlockSettings<TeamRelayBlockSettings>(
          BlockType.TEAM_RELAY
        )
      );
      if (!resp.data) return;
      setOptions(
        resp.data.levels.map((l, i) => ({
          label: `Level ${i + 1}`,
          value: i + 1,
          ...l,
        }))
      );
    }
    init();
  }, [mounted]);
  return options;
}

export function TeamRelayBlockEditor(
  props: EditorProps<TeamRelayBlock>
): JSX.Element | null {
  const {
    register,
    getValues,
    reset,
    formState: { errors },
  } = useForm<BlockFields<TeamRelayBlock>>({
    defaultValues: {
      ...props.block.fields,
    },
  });
  const blockId = props.block.id;
  const prevBlockId = usePrevious(props.block.id);
  const { updateField } = useEditor(props);
  const levelOptions = useLevelOptions();

  useEffect(() => {
    if (prevBlockId === blockId) return;
    reset(props.block.fields);
  }, [blockId, prevBlockId, props.block.fields, reset]);

  const updateFieldOnBlur = (name: keyof BlockFields<TeamRelayBlock>): void => {
    updateField(name, getValues(name));
  };

  const selectOnChange = (
    name: keyof BlockFields<TeamRelayBlock>,
    value: Nullable<Option['value'], false>
  ): void => {
    updateField(name, value);
  };

  return (
    <EditorLayout
      bottomAccessory={
        <AdditionalSettings>
          <AdditionalSharedSettingsEditor {...props} />
        </AdditionalSettings>
      }
    >
      <EditorBody>
        <p className='text-2xl text-white'>Team Relay Block</p>
        <form className='w-full my-7.5'>
          <div className='w-full flex flex-col'>
            <div className='w-full flex flex-row'>
              <div className='w-3/5 text-base font-bold'>
                <label htmlFor='text'>
                  <span className='text-white'>Game Text</span>
                  <textarea
                    className={`h-20 mt-1 mb-1 py-2 resize-none ${
                      errors.text ? 'field-error' : 'field'
                    }`}
                    placeholder='Max 300 characters'
                    {...register('text', {
                      maxLength: 300,
                      onBlur: () => updateFieldOnBlur('text'),
                    })}
                  />
                </label>
                <label htmlFor='text'>
                  <RHFSelectField<TeamRelayBlock>
                    className='w-full h-10 my-2'
                    label='Game Mode & Customization'
                    name='mode'
                    options={Object.values(modeOptions)}
                    disabled
                    onChange={selectOnChange}
                    value={props.block.fields.mode}
                  />
                </label>
                <div className='bg-layer-001 rounded-xl grid grid-cols-2 px-2.5'>
                  <label htmlFor='moving-object' className='my-2'>
                    <BlockMediaUploader<TeamRelayBlockMedia>
                      blockId={blockId}
                      title='Moving Object'
                      field='movingObjectMedia'
                      video={false}
                      scene={EnumsMediaScene.MediaSceneBlockMedia}
                      media={props.block.fields.movingObjectMedia}
                      extraNotice='Object moves as Team finishes sequences.'
                      width={MEDIA_RESPONSIVE_WIDTH}
                    />
                  </label>
                  <label htmlFor='stationary-goal' className='my-2'>
                    <BlockMediaUploader<TeamRelayBlockMedia>
                      blockId={blockId}
                      title='Stationary Goal'
                      field='stationaryGoalMedia'
                      video={false}
                      scene={EnumsMediaScene.MediaSceneBlockMedia}
                      media={props.block.fields.stationaryGoalMedia}
                      extraNotice='Finish line once all sequences are completed.'
                      width={MEDIA_RESPONSIVE_WIDTH}
                    />
                  </label>
                  <label htmlFor='goal-animation' className='my-2'>
                    <BlockMediaEditor<TeamRelayBlockMedia>
                      blockId={blockId}
                      title='Goal Animation'
                      field='goalAnimationMedia'
                      image={false}
                      video={true}
                      scene={EnumsMediaScene.MediaSceneBlockMedia}
                      mediaData={props.block.fields.goalAnimationMediaData}
                      media={props.block.fields.goalAnimationMedia}
                      extraNotice='Animation once Object reaches the Goal.'
                      width={MEDIA_RESPONSIVE_WIDTH}
                    />
                  </label>
                </div>
              </div>
              <div className='w-2/5 text-base font-bold flex flex-col items-center'>
                <label htmlFor='intro-media' className='my-2'>
                  <BlockMediaEditor<TeamRelayBlockMedia>
                    blockId={blockId}
                    title='Intro Media'
                    field='introMedia'
                    video={true}
                    scene={EnumsMediaScene.MediaSceneBlockMedia}
                    volumeSelectable
                    mediaData={props.block.fields.introMediaData}
                    media={props.block.fields.introMedia}
                    extraNotice='Media will display to the audience when the Block is presented.'
                  />
                </label>
                <label htmlFor='background-media' className='my-2'>
                  <BlockMediaEditor<TeamRelayBlockMedia>
                    blockId={blockId}
                    title='Background Media'
                    field='backgroundMedia'
                    video={true}
                    scene={EnumsMediaScene.MediaSceneBlockBackground}
                    volumeSelectable
                    mediaData={props.block.fields.backgroundMediaData}
                    media={props.block.fields.backgroundMedia}
                    extraNotice='Media will display in the background when the main part of the Block is started.'
                  />
                </label>
                <label htmlFor='outro-media' className='my-2'>
                  <BlockMediaEditor<TeamRelayBlockMedia>
                    blockId={blockId}
                    title='Outro Media'
                    field='outroMedia'
                    video={true}
                    scene={EnumsMediaScene.MediaSceneBlockMedia}
                    volumeSelectable
                    mediaData={props.block.fields.outroMediaData}
                    media={props.block.fields.outroMedia}
                    extraNotice='Media will display to the audience when the answer is revealed.'
                  />
                </label>
                <hr className='w-3/4 my-5 border border-secondary' />
                <label htmlFor='points' className='w-3/4 my-2'>
                  <RHFSelectField<TeamRelayBlock>
                    className='w-full h-10 text-white'
                    label='Points'
                    name='points'
                    options={pointOptions}
                    onChange={selectOnChange}
                    value={props.block.fields.points}
                  />
                </label>
                <label htmlFor='sequenceTime' className='w-3/4 my-2'>
                  <RHFSelectField<TeamRelayBlock>
                    className='w-full h-10 text-white'
                    label='Time Limit per Sequence'
                    name='sequenceTime'
                    options={timeOptions}
                    onChange={selectOnChange}
                    value={props.block.fields.sequenceTime}
                  />
                </label>
                <label htmlFor='difficultyLevel' className='w-3/4 my-2'>
                  <RHFSelectField<TeamRelayBlock>
                    className='w-full h-10 text-white'
                    label='Difficulty Level (Team vs Team)'
                    name='difficultyLevel'
                    options={levelOptions}
                    onChange={selectOnChange}
                    value={props.block.fields.difficultyLevel}
                  />
                </label>
                <label htmlFor='decreasingPointsTimer' className='w-3/4 my-2'>
                  <RHFCheckbox<TeamRelayBlock>
                    label='Decreasing Points Timer'
                    name='decreasingPointsTimer'
                    value={props.block.fields.decreasingPointsTimer}
                    onChange={(_, checked: boolean): void => {
                      updateField('decreasingPointsTimer', checked);
                    }}
                    description={{
                      enabled:
                        'Enabled: Teams earn more Points for completing their Sequence faster.',
                      disabled:
                        'Disabled: Teams earn the same amount of Points, regardless of speed.',
                    }}
                  />
                </label>
                {props.block.fields.decreasingPointsTimer && (
                  <label className='w-3/4 my-2'>
                    <RHFCheckbox<TeamRelayBlock>
                      label='Start Descending Immediately'
                      name='startDescendingImmediately'
                      value={
                        props.block.fields.startDescendingImmediately ?? false
                      }
                      onChange={(_, checked: boolean): void => {
                        updateField('startDescendingImmediately', checked);
                      }}
                      description={{
                        enabled: 'Enabled: Points start descending immediately',
                        disabled:
                          'Disabled: Points start descending after 25% of time.',
                      }}
                    />
                  </label>
                )}
              </div>
            </div>
            <div className='w-full h-60'></div>
          </div>
        </form>
      </EditorBody>
    </EditorLayout>
  );
}
