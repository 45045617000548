import { useEffect, useMemo, useState } from 'react';

import {
  type RoundRobinQuestionBlock,
  RoundRobinQuestionBlockGameSessionStatus,
} from '@lp-lib/game';

import { type TeamId } from '../../../../types';
import { assertExhaustive } from '../../../../utils/common';
import { TimeUtils } from '../../../../utils/time';
import { DoubleRightArrow } from '../../../icons/Arrows';
import { PlayIcon } from '../../../icons/PlayIcon';
import { RefreshIcon } from '../../../icons/RefreshIcon';
import { FilledSquareIcon } from '../../../icons/SquareIcon';
import { TimerIcon } from '../../../icons/TimerIcon';
import {
  useGameSessionLocalTimer,
  useGameSessionStatus,
  useIsEndedBlock,
} from '../../hooks';
import { countdownV2, next, present, triggerBlockTitleAnim } from '../../store';
import {
  BlockControllerActionButton,
  BlockControllerActionTimeRemaining,
  ControllerLayout,
  ControllerProgress,
  DecreasingPointsBadge,
  type MediaPlayback,
  MediaPreviewer,
  useBlockControllerBlockTitlePlaying,
  useControllerMediaPlayback,
} from '../Common/Controller/Internal';
import { type ControllerProps } from '../Common/Controller/types';
import { useStableBlock } from '../Common/hooks';
import { roundRobinGameLongDuration } from './RoundRobinQuestionBlockEditor';
import { useRoundRobinGameSummary } from './RoundRobinQuestionProvider';
import { RoundRobinQuestionUtils } from './utils';

type SharedProps = ControllerProps<RoundRobinQuestionBlock>;
type StageProps = SharedProps & {
  playback: MediaPlayback;
};

export function RoundRobinBlockTeamProgressSummary(props: {
  block: RoundRobinQuestionBlock;
  teamId: TeamId;
}) {
  const summary = useRoundRobinGameSummary();
  const total = useMemo(() => {
    const gamePlayableQuestions =
      RoundRobinQuestionUtils.GetGamePlayableQuestions(props.block);
    return gamePlayableQuestions.length;
  }, [props.block]);
  const [completed, correct] = useMemo(() => {
    return [
      summary[props.teamId]?.completedQuestionsCount ?? 0,
      summary[props.teamId]?.correctQuestionsCount ?? 0,
    ];
  }, [summary, props.teamId]);
  return (
    <>
      Progress: {completed}/{total} – {correct} correct
    </>
  );
}

function GameProgress(
  props: StageProps & { revealResults?: boolean; hideProgressDetail?: boolean }
): JSX.Element | null {
  const {
    selectedBlock: block,
    setEditPointsData,
    hideProgressDetail,
    revealResults,
  } = props;

  const summary = useRoundRobinGameSummary();
  const gamePlayableQuestions =
    RoundRobinQuestionUtils.GetGamePlayableQuestions(block);
  const questionsCount = gamePlayableQuestions.length;
  const pointsSummary = gamePlayableQuestions.reduce((sum, cur) => {
    return sum + cur.points;
  }, 0);

  return (
    <ControllerProgress
      blockId={block.id}
      setEditPointsData={setEditPointsData}
      header={`${questionsCount} Questions - Max ${pointsSummary} Points`}
      revealResults={revealResults}
      hideProgressDetail={hideProgressDetail}
      progressDetail={(teamId: string) => {
        const completedCount = summary[teamId]?.completedQuestionsCount ?? 0;
        const correctCount = summary[teamId]?.correctQuestionsCount ?? 0;
        return `[${completedCount} Completed, ${correctCount} Correct]`;
      }}
    />
  );
}

function Badges(props: {
  block: RoundRobinQuestionBlock;
  disabled?: boolean;
}): JSX.Element {
  return (
    <div className='w-full px-2.5 flex flex-row items-center justify-start self-start'>
      <DecreasingPointsBadge
        decreasingPointsTimer={props.block.fields.decreasingPointsTimer}
        disabled={props.disabled}
      />
    </div>
  );
}

function Loaded(props: StageProps): JSX.Element {
  const { selectedBlock: block, playback } = props;

  const { switchMedia, reset: resetPlayback } = playback.api;

  useEffect(() => {
    resetPlayback();
    switchMedia(RoundRobinQuestionUtils.GetBackgroundMedia(block));
  }, [block, switchMedia, resetPlayback]);

  const onPresent = async () => {
    await triggerBlockTitleAnim(block);
    await present(block);
  };

  const actionComponent = useBlockControllerBlockTitlePlaying(block) ?? (
    <BlockControllerActionButton onClick={onPresent} icon={PlayIcon}>
      Present Block
    </BlockControllerActionButton>
  );
  return (
    <ControllerLayout action={actionComponent}>
      <div className='w-full flex flex-col'>
        <Badges block={block} />
        <MediaPreviewer
          playback={playback}
          alt={block.type}
          text={'Round Robin Q&A'}
        />
      </div>
    </ControllerLayout>
  );
}

function GameInit(props: StageProps): JSX.Element {
  const { selectedBlock: block, playback } = props;

  const [processing, setProcessing] = useState(false);
  const localTimeSec = useGameSessionLocalTimer();
  const gameTimeSec = RoundRobinQuestionUtils.GameTimeSec(block);
  const durationFormattedMMSS = gameTimeSec >= roundRobinGameLongDuration;

  const onStartGame = async () => {
    if (localTimeSec === 0) return;
    setProcessing(true);
    await countdownV2({
      debug: 'RoundRobinQuestionBlockGameControl',
      startTimeWorker: true,
      flushCountingStatus: true,
      triggerTimesup: true,
    });
  };

  return (
    <ControllerLayout
      action={
        <BlockControllerActionButton
          onClick={onStartGame}
          icon={TimerIcon}
          disable={processing || localTimeSec === 0}
        >
          {`Start Game Timer (${
            durationFormattedMMSS
              ? TimeUtils.DurationFormattedHHMMSS((localTimeSec ?? 0) * 1000)
              : `${localTimeSec}s`
          })`}
        </BlockControllerActionButton>
      }
    >
      <div className='w-full flex flex-col'>
        <Badges block={block} disabled />
        <MediaPreviewer
          playback={playback}
          alt={block.type}
          text={'Round Robin Q&A'}
        />
      </div>
    </ControllerLayout>
  );
}

function GameStart(props: StageProps): JSX.Element {
  const time = useGameSessionLocalTimer();
  const gameTimeSec = RoundRobinQuestionUtils.GameTimeSec(props.selectedBlock);
  const durationFormattedMMSS = gameTimeSec >= roundRobinGameLongDuration;

  return (
    <ControllerLayout
      action={
        <BlockControllerActionTimeRemaining
          remainingSec={time}
          durationFormattedMMSS={durationFormattedMMSS}
        />
      }
    >
      <GameProgress {...props} />
    </ControllerLayout>
  );
}

function GameEnd(props: StageProps): JSX.Element {
  const [processing, setProcessing] = useState(false);

  const onRevealResult = async () => {
    setProcessing(true);
    await next();
  };
  return (
    <ControllerLayout
      action={
        <BlockControllerActionButton
          onClick={onRevealResult}
          icon={DoubleRightArrow}
          disable={processing}
        >
          Reveal Results
        </BlockControllerActionButton>
      }
    >
      <GameProgress {...props} revealResults />
    </ControllerLayout>
  );
}

function Results(props: StageProps): JSX.Element {
  const onShowScoreboard = async () => {
    await next();
  };

  return (
    <ControllerLayout
      action={
        <>
          <BlockControllerActionButton
            onClick={onShowScoreboard}
            isSecondary={true}
          >
            Show Scoreboard
          </BlockControllerActionButton>
          <BlockControllerActionButton
            onClick={props.onEndBlock}
            icon={FilledSquareIcon}
          >
            End Block Sequence
          </BlockControllerActionButton>
        </>
      }
    >
      <GameProgress {...props} revealResults />
    </ControllerLayout>
  );
}

function Scoreboard(props: StageProps): JSX.Element {
  return (
    <ControllerLayout
      action={
        <BlockControllerActionButton
          onClick={props.onEndBlock}
          icon={FilledSquareIcon}
        >
          End Block Sequence
        </BlockControllerActionButton>
      }
    >
      <GameProgress {...props} revealResults />
    </ControllerLayout>
  );
}

function Ended(props: StageProps): JSX.Element {
  return (
    <ControllerLayout
      action={
        <BlockControllerActionButton
          onClick={() => props.setShowResetConfirmation(true)}
          icon={RefreshIcon}
          isDelete={true}
        >
          Reset Completed Block
        </BlockControllerActionButton>
      }
    >
      Ended
      <GameProgress {...props} hideProgressDetail revealResults />
    </ControllerLayout>
  );
}

export function RoundRobinQuestionBlockController(
  props: ControllerProps<RoundRobinQuestionBlock>
): JSX.Element | null {
  const { selectedBlockIndex } = props;
  const block = useStableBlock(props.selectedBlock);
  const isEndedBlock = useIsEndedBlock(block.id);
  const gameSessionStatus =
    useGameSessionStatus<RoundRobinQuestionBlockGameSessionStatus>();
  const playback = useControllerMediaPlayback();

  if (selectedBlockIndex === null) return null;

  if (isEndedBlock) {
    return <Ended {...props} playback={playback} />;
  }

  switch (gameSessionStatus) {
    case RoundRobinQuestionBlockGameSessionStatus.LOADED:
      return <Loaded {...props} playback={playback} />;
    case RoundRobinQuestionBlockGameSessionStatus.GAME_INIT:
      return <GameInit {...props} playback={playback} />;
    case RoundRobinQuestionBlockGameSessionStatus.GAME_START:
      return <GameStart {...props} playback={playback} />;
    case RoundRobinQuestionBlockGameSessionStatus.GAME_END:
      return <GameEnd {...props} playback={playback} />;
    case RoundRobinQuestionBlockGameSessionStatus.RESULTS:
      return <Results {...props} playback={playback} />;
    case RoundRobinQuestionBlockGameSessionStatus.SCOREBOARD:
      return <Scoreboard {...props} playback={playback} />;
    case RoundRobinQuestionBlockGameSessionStatus.END:
    case null:
    case undefined:
      break;
    default:
      assertExhaustive(gameSessionStatus);
      break;
  }

  return null;
}
