import { useMemo, useRef } from 'react';

import {
  type DtoBlock,
  type DtoGamePack,
  type DtoGamePackUGCFile,
  type DtoNarrowedRunStep,
  type DtoSharedAsset,
  type DtoUGCGenerateBlockRequest,
  type DtoUGCGenerateBlockResponse,
  EnumsGamePackUGCFileStatus,
  type OpenaiFunctionCall,
  OpenaiRunStepStatus,
  OpenaiRunStepType,
} from '@lp-lib/api-service-client/public';
import { type Block } from '@lp-lib/game';

import { useAnalytics } from '../../../analytics/AnalyticsContext';
import { UGCAnalytics } from '../../../analytics/ugc';
import { useLiveAsyncCall } from '../../../hooks/useAsyncCall';
import { getLogger } from '../../../logger/logger';
import { apiService } from '../../../services/api-service';
import { APIServiceURL, publicFetchAPIService } from '../../../services/public';
import { type GamePack } from '../../../types/game';
import { fromDTOBlock } from '../../../utils/api-dto';
import { uuidv4 } from '../../../utils/common';
import { getToken } from '../../../utils/getToken';
import { getAgentInfo } from '../../../utils/user-agent';
import { BlockKnifeUtils } from '../Blocks/Shared';
import { type IndexedActivity } from './types';

export const log = getLogger().scoped('ugc');

export class UGCUtils {
  // prettier-ignore
  static AllowedFileTypes = [
    // media types supported by us
    '.jpg', '.jpeg', '.png', '.gif',
    '.mp4', '.mov', '.webm',
    '.wav', '.mp3', '.m4a',

    // file types supported by OpenAI
    // https://platform.openai.com/docs/assistants/tools/file-search/supported-files
    '.c', '.cpp', '.cs', '.css', '.doc', 
    '.docx', '.go', '.html', '.java', '.js',
    '.json', '.md', '.pdf', '.php', '.pptx',
    '.py', '.rb', '.sh', '.tex', '.ts',
    '.txt'
  ];
  static FromSharedAsset(asset: DtoSharedAsset) {
    return {
      id: asset.id,
      primaryText: asset.data?.showcaseCard.primaryText ?? '',
      secondaryText: asset.data?.showcaseCard.secondaryText ?? '',
      hint: asset.data?.showcaseCard.hint,
      media: asset.media,
    };
  }
  static MakeActivities(
    pack: DtoGamePack | GamePack | undefined,
    blocks: DtoBlock[],
    sharedAssets: DtoSharedAsset[]
  ): IndexedActivity[] {
    const customizableBlocks = blocks.filter((b) =>
      pack?.childrenIds?.includes(b.id)
    );
    return customizableBlocks.map((b, index) => {
      const narrowed = fromDTOBlock(b);
      const summary = BlockKnifeUtils.Summary(narrowed);
      const asset = sharedAssets.find(
        (a) => a.id === narrowed.fields.ugcAssetId
      );
      return {
        index,
        block: narrowed,
        asset: asset
          ? this.FromSharedAsset(asset)
          : {
              id: uuidv4(),
              primaryText: summary.title ?? 'Untitled',
              secondaryText: '',
              media: undefined,
            },
      };
    });
  }
  static VaildRunStep(step: DtoNarrowedRunStep) {
    return (
      step.type === OpenaiRunStepType.RunStepTypeToolCalls &&
      step.status === OpenaiRunStepStatus.RunStepStatusCompleted &&
      step.stepDetails.tool_calls.length > 0
    );
  }

  static ParseFunctionCall<T, R>(
    packId: string,
    block: Block,
    functionCall: OpenaiFunctionCall,
    parse: (data: T) => R
  ) {
    log.info('apply changes from function call', {
      packId: packId,
      blockId: block.id,
      type: block.type,
      functionCall,
    });
    try {
      return parse(JSON.parse(functionCall.arguments));
    } catch (error) {
      log.error('Failed to parse arguments', error, {
        packId: packId,
        blockId: block.id,
        type: block.type,
        functionCall,
      });
      throw new Error('Something went wrong, please try again');
    }
  }
  static IsOutstandingUGCFile(file: DtoGamePackUGCFile) {
    return (
      file.status ===
        EnumsGamePackUGCFileStatus.GamePackUGCFileStatusPreProcessing ||
      file.status === EnumsGamePackUGCFileStatus.GamePackUGCFileStatusInProgress
    );
  }
  static async DownloadFile(file: DtoGamePackUGCFile, openMedia = true) {
    if (openMedia && file.media?.media?.url) {
      window.open(file.media.media.url, '_blank');
      return;
    }
    const resp = await apiService.gamePack.createDownloadUGCFileToken(
      file.packId,
      file.id
    );
    window.open(
      `/custom-games/${file.packId}/files/${file.id}?token=${resp.data.token}`,
      '_blank'
    );
  }
}

export async function generateBlock(
  id: string,
  req: DtoUGCGenerateBlockRequest,
  opts?: { signal?: AbortSignal | null }
) {
  const url = new APIServiceURL(`/game-packs/${id}/generate-block`);
  const resp = await publicFetchAPIService<DtoUGCGenerateBlockResponse>(url, {
    method: 'POST',
    bodyObj: req,
    token: getToken(),
    signal: opts?.signal,
  });
  return { data: resp.json, status: resp.res.status };
}

export function useUGCAnalytics() {
  const analytics = useAnalytics();
  return useMemo(() => new UGCAnalytics(analytics), [analytics]);
}

export function useAbortableBlockGenAI(
  exec: (prompt: string, singal: AbortSignal) => Promise<boolean>
) {
  const aborterRef = useRef<AbortController | null>(null);
  const {
    state: { state, error },
    call,
  } = useLiveAsyncCall(async (prompt: string) => {
    const aborter = new AbortController();
    aborterRef.current = aborter;
    try {
      return await exec(prompt, aborter.signal);
    } catch (error) {
      if (aborter.signal.aborted) return false;
      throw error;
    }
  });
  const abort = () => aborterRef.current?.abort();
  return { generate: call, abort, state, error };
}

export function useModifierKeySymbol() {
  return useMemo(() => (getAgentInfo().os.isMac ? '⌘' : 'Ctrl+'), []);
}
