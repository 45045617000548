import {
  type AnalyticsBrowser,
  type EventProperties,
} from '@segment/analytics-next';
import { useMemo } from 'react';

import { useMyOrgId } from '../components/Organization';
import { useAnalytics } from './AnalyticsContext';

export class VenueAnalytics {
  constructor(
    private readonly analytics: AnalyticsBrowser,
    readonly orgId: string | null
  ) {}
  trackDeviceCheckViewed(props: { venueId?: string | null }) {
    this.analytics.track('Device Check Viewed', this.buildEventProps(props));
  }
  trackDeviceCheckCompleted(props: {
    venueId?: string | null;
    skipped?: boolean | null;
  }) {
    this.analytics.track('Device Check Completed', this.buildEventProps(props));
  }
  trackPlayerRangeConfirmationModalViewed(props: {
    venueId?: string | null;
    playerCount: number;
    minPlayers: number;
    maxPlayers?: number | null;
  }) {
    this.analytics.track(
      'Player Range Confirmation Modal Viewed',
      this.buildEventProps(props)
    );
  }
  trackPlayerRangeConfirmationModalDismissed(props: {
    venueId?: string | null;
  }) {
    this.analytics.track(
      'Player Range Confirmation Modal Dismissed',
      this.buildEventProps(props)
    );
  }
  trackPlayerRangeConfirmationModalConfirmed(props: {
    venueId?: string | null;
  }) {
    this.analytics.track(
      'Player Range Confirmation Modal Confirmed',
      this.buildEventProps(props)
    );
  }
  private buildEventProps(props?: EventProperties): EventProperties {
    return {
      ...props,
      orgId: this.orgId,
    };
  }
}

export function useVenueAnalytics(): VenueAnalytics {
  const analytics = useAnalytics();
  const orgId = useMyOrgId();
  return useMemo(
    () => new VenueAnalytics(analytics, orgId),
    [analytics, orgId]
  );
}
