import { type DropTargetMonitor, type XYCoord } from 'react-dnd';

export function canMove(
  dragIndex: number,
  hoverIndex: number,
  rect: DOMRect,
  monitor: DropTargetMonitor
): boolean {
  // Don't replace items with themselves
  if (dragIndex === hoverIndex) return false;

  // Get the middle of the item for both X and Y axes
  const hoverMiddleX = (rect.right - rect.left) / 2;
  const hoverMiddleY = (rect.bottom - rect.top) / 2;

  // Determine mouse position
  const clientOffset = monitor.getClientOffset();

  // Get pixels to the top and to the left
  const hoverClientY = (clientOffset as XYCoord).y - rect.top;
  const hoverClientX = (clientOffset as XYCoord).x - rect.left;

  // Calculate if we've crossed the middle on either the X or Y axis
  const isOverHorizontalMiddle =
    dragIndex < hoverIndex
      ? hoverClientX < hoverMiddleX
      : hoverClientX > hoverMiddleX;

  const isOverVerticalMiddle =
    dragIndex < hoverIndex
      ? hoverClientY < hoverMiddleY
      : hoverClientY > hoverMiddleY;

  // Determine if we're moving on the X or Y axis by checking the initial position
  if (monitor.getInitialSourceClientOffset()?.y === rect.y) {
    // Horizontal move
    return !isOverHorizontalMiddle;
  } else {
    // Vertical move
    return !isOverVerticalMiddle;
  }
}
