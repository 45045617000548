import { AIDenoiserExtension } from 'agora-extension-ai-denoiser';
import AgoraRTC from 'agora-rtc-sdk-ng';

import {
  type AIDenoiserExtensionPatched,
  type IAIDenoiserProcessorPatched,
} from './types';

export { AIDenoiserProcessorMode } from 'agora-extension-ai-denoiser';

export class DenoiserExtension {
  private static assetsPath: string;
  private static instance: DenoiserExtension;
  private _extension: AIDenoiserExtensionPatched;

  private constructor(assetsPath: string) {
    this._extension = new AIDenoiserExtension({
      assetsPath,
    }) as AIDenoiserExtensionPatched;
    AgoraRTC.registerExtensions([this._extension]);
  }

  public static Init(assetsPath: string): void {
    DenoiserExtension.assetsPath = assetsPath;
  }

  public static Instance(): DenoiserExtension {
    if (!DenoiserExtension.instance) {
      if (!DenoiserExtension.assetsPath) {
        throw new Error('DenoiserExtension is not inited with assetsPath');
      }
      DenoiserExtension.instance = new DenoiserExtension(
        DenoiserExtension.assetsPath
      );
    }
    return DenoiserExtension.instance;
  }

  public createProcessor(): IAIDenoiserProcessorPatched {
    return this._extension.createProcessor();
  }
}
