import {
  assertExhaustive,
  QuestionBlockAnswerGrade,
  type QuestionBlockAnswerGradeModel,
} from '@lp-lib/game';

// Note(falcon): this is similar to QuestionBlockAnswerGrade, but we want to be
// agnostic of any one block type. callers can adapt custom grading to this
// model if they so desire.
export type Grade = 'incorrect' | 'half' | 'correct';
export class QuestionBlockAnswerGradeConverter {
  static To(grade: Grade): QuestionBlockAnswerGrade {
    switch (grade) {
      case 'correct':
        return QuestionBlockAnswerGrade.CORRECT;
      case 'incorrect':
        return QuestionBlockAnswerGrade.INCORRECT;
      case 'half':
        return QuestionBlockAnswerGrade.HALF;
      default:
        assertExhaustive(grade);
        return QuestionBlockAnswerGrade.NONE;
    }
  }

  static From(from: QuestionBlockAnswerGrade): Grade | null {
    switch (from) {
      case QuestionBlockAnswerGrade.CORRECT:
        return 'correct';
      case QuestionBlockAnswerGrade.INCORRECT:
        return 'incorrect';
      case QuestionBlockAnswerGrade.HALF:
        return 'half';
      case QuestionBlockAnswerGrade.NONE:
        return null;
      default:
        assertExhaustive(from);
        return null;
    }
  }
}

export type GradeResult = {
  model?: QuestionBlockAnswerGradeModel;
  grade: QuestionBlockAnswerGrade;
  timeMs: number;
  error?: unknown;
  cached?: boolean;
};

export type GradeEvents = {
  'submission-graded': (args: {
    question: string;
    results: GradeResult[];
    userSubmission: string;
    correctAnswers: string[];
    submitterUid: string;
  }) => void;
};
