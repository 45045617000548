import { useEffect, useMemo, useState } from 'react';
import { Controller, FormProvider, useForm, useWatch } from 'react-hook-form';
import Select from 'react-select';

import {
  EnumsTTSGeneratorId,
  type ModelsTTSElevenLabsSettings,
  type ModelsTTSGeneratorSettings,
  type ModelsTTSLabeledRenderSettings,
  type ModelsTTSOpenAISettings,
  type ModelsTTSRenderSettings,
} from '@lp-lib/api-service-client/public';

import { useLiveCallback } from '../../hooks/useLiveCallback';
import { uuidv4 } from '../../utils/common';
import { buildReactSelectStyles } from '../../utils/react-select';
import type { Option } from '../common/Utilities';
import { DirectRangeInput } from '../Form/DirectRangeInput';
import {
  FieldDescription,
  FieldPanel,
  FieldTitle,
} from '../Game/Blocks/Common/Editor/FieldEditorUtilities';
import { TTSPreviewButton } from './TTSPreviewButton';
import { useTTSGenerators } from './useTTSGenerators';
import { useTTSGeneratorSettings } from './useTTSGeneratorSettings';

function ProviderSelector(props: {
  value?: EnumsTTSGeneratorId | null;
  onChange: (val: EnumsTTSGeneratorId | null) => void;
}): JSX.Element {
  const { generators, isLoading } = useTTSGenerators();

  const options = useMemo(() => {
    if (!generators) return [];
    return generators.map((o) => ({
      label: o.displayName,
      value: o.id,
    }));
  }, [generators]);

  const value = useMemo(() => {
    if (options.length === 0) return undefined;
    if (!props.value) return options[0];

    return options.find((o) => o.value === props.value);
  }, [props.value, options]);

  const styles = useMemo(
    () => buildReactSelectStyles<Option<EnumsTTSGeneratorId>>(),
    []
  );

  return (
    <Select<Option<EnumsTTSGeneratorId>, false>
      options={options}
      value={value}
      styles={styles}
      classNamePrefix='select-box-v2'
      isSearchable
      isLoading={isLoading}
      onChange={(v) => props.onChange(v === null ? null : v.value)}
    />
  );
}

function VoiceSelector(props: {
  generatorId: EnumsTTSGeneratorId | null | undefined;
  value?: string;
  onChange: (val: string | null) => void;
}): JSX.Element {
  const { generator, isLoading } = useTTSGeneratorSettings(props.generatorId);

  const options = useMemo(() => {
    if (!generator?.voices) return [];
    return generator.voices.map((o) => ({
      label: o.displayName,
      value: o.id,
    }));
  }, [generator?.voices]);

  const value = useMemo(() => {
    if (options.length === 0) return undefined;
    return options.find((o) => o.value === props.value);
  }, [props.value, options]);

  const styles = useMemo(() => buildReactSelectStyles<Option<string>>(), []);

  return (
    <Select<Option<string>, false>
      options={options}
      value={value}
      styles={styles}
      classNamePrefix='select-box-v2'
      isSearchable
      isLoading={isLoading}
      onChange={(v) => props.onChange(v === null ? null : v.value)}
    />
  );
}

function ModelSelector(props: {
  generatorId: EnumsTTSGeneratorId | null | undefined;
  value?: string;
  onChange: (val: string | null) => void;
}): JSX.Element {
  const { generator, isLoading } = useTTSGeneratorSettings(props.generatorId);

  const options = useMemo(() => {
    if (!generator?.models) return [];
    return generator.models.map((o) => ({
      label: o.displayName,
      value: o.id,
    }));
  }, [generator?.models]);

  const value = useMemo(() => {
    if (options.length === 0) return undefined;
    return options.find((o) => o.value === props.value);
  }, [props.value, options]);

  const styles = useMemo(() => buildReactSelectStyles<Option<string>>(), []);

  return (
    <Select<Option<string>, false>
      options={options}
      value={value}
      styles={styles}
      classNamePrefix='select-box-v2'
      isSearchable={false}
      isLoading={isLoading}
      onChange={(v) => props.onChange(v === null ? null : v.value)}
    />
  );
}

function ElevenLabsTTSSettings() {
  return (
    <>
      <div className='grid grid-cols-2 gap-x-6'>
        <FieldPanel>
          <FieldTitle>Model</FieldTitle>
          <FieldDescription>
            Select the model to use for rendering.{' '}
            <a
              href='https://elevenlabs.io/docs/speech-synthesis/models'
              className='underline text-primary'
            >
              Learn more.
            </a>
          </FieldDescription>
        </FieldPanel>
        <Controller<
          ModelsTTSRenderSettings,
          'generatorSettings.elevenLabs.modelId'
        >
          name='generatorSettings.elevenLabs.modelId'
          rules={{
            required: true,
          }}
          render={({ field: { value, onChange } }) => {
            return (
              <ModelSelector
                value={value ?? undefined}
                onChange={onChange}
                generatorId={EnumsTTSGeneratorId.TTSGeneratorIdElevenLabs}
              />
            );
          }}
        />
      </div>

      <div className='grid grid-cols-2 gap-x-6'>
        <FieldPanel>
          <FieldTitle>Voice</FieldTitle>
          <FieldDescription>
            Select the id of the voice to use.
          </FieldDescription>
        </FieldPanel>
        <Controller<
          ModelsTTSRenderSettings,
          'generatorSettings.elevenLabs.voiceId'
        >
          name='generatorSettings.elevenLabs.voiceId'
          rules={{
            required: true,
          }}
          render={({ field: { value, onChange } }) => {
            return (
              <VoiceSelector
                value={value}
                onChange={onChange}
                generatorId={EnumsTTSGeneratorId.TTSGeneratorIdElevenLabs}
              />
            );
          }}
        />
      </div>

      <div className='grid grid-cols-2 gap-x-6'>
        <FieldPanel>
          <FieldTitle>Stability</FieldTitle>
          <FieldDescription>
            Determines how stable the voice is and the randomness between each
            generation. Lowering this slider introduces a broader emotional
            range for the voice.
          </FieldDescription>
        </FieldPanel>
        <DirectRangeInput<ModelsTTSRenderSettings>
          className='flex justify-between items-center w-full gap-2'
          name='generatorSettings.elevenLabs.stability'
          min={0}
          max={1}
          step={0.01}
          initialValue={0.75}
        />
      </div>

      <div className='grid grid-cols-2 gap-x-6'>
        <FieldPanel>
          <FieldTitle>Similarity</FieldTitle>
          <FieldDescription>
            Dictates how closely the AI should adhere to the original voice when
            attempting to replicate it.
          </FieldDescription>
        </FieldPanel>
        <DirectRangeInput<ModelsTTSRenderSettings>
          className='flex justify-between items-center w-full gap-2'
          name='generatorSettings.elevenLabs.similarityBoost'
          min={0}
          max={1}
          step={0.01}
          initialValue={0.75}
        />
      </div>
    </>
  );
}

function OpenAITTSSettings() {
  return (
    <>
      <div className='grid grid-cols-2 gap-x-6'>
        <FieldPanel>
          <FieldTitle>Model</FieldTitle>
          <FieldDescription>
            Select the model to use for rendering.{' '}
            <a
              href='https://platform.openai.com/docs/models/tts'
              className='underline text-primary'
            >
              Learn more.
            </a>
          </FieldDescription>
        </FieldPanel>
        <Controller<ModelsTTSRenderSettings, 'generatorSettings.openAI.modelId'>
          name='generatorSettings.openAI.modelId'
          rules={{
            required: true,
          }}
          render={({ field: { value, onChange } }) => {
            return (
              <ModelSelector
                value={value ?? undefined}
                onChange={onChange}
                generatorId={EnumsTTSGeneratorId.TTSGeneratorIdOpenAI}
              />
            );
          }}
        />
      </div>

      <div className='grid grid-cols-2 gap-x-6'>
        <FieldPanel>
          <FieldTitle>Voice</FieldTitle>
          <FieldDescription>
            Select the id of the voice to use.
          </FieldDescription>
        </FieldPanel>
        <Controller<ModelsTTSRenderSettings, 'generatorSettings.openAI.voiceId'>
          name='generatorSettings.openAI.voiceId'
          rules={{
            required: true,
          }}
          render={({ field: { value, onChange } }) => {
            return (
              <VoiceSelector
                value={value}
                onChange={onChange}
                generatorId={EnumsTTSGeneratorId.TTSGeneratorIdOpenAI}
              />
            );
          }}
        />
      </div>

      <div className='grid grid-cols-2 gap-x-6'>
        <FieldPanel>
          <FieldTitle>Speed</FieldTitle>
          <FieldDescription>The speed of the generated audio.</FieldDescription>
        </FieldPanel>
        <DirectRangeInput<ModelsTTSRenderSettings>
          className='flex justify-between items-center w-full gap-2'
          name='generatorSettings.openAI.speed'
          min={0.25}
          max={4.0}
          step={0.01}
          initialValue={1.0}
        />
      </div>
    </>
  );
}

function GeneratorSettings() {
  const generatorId = useWatch<ModelsTTSLabeledRenderSettings, 'generatorId'>({
    name: 'generatorId',
  });

  return useMemo(() => {
    switch (generatorId) {
      case EnumsTTSGeneratorId.TTSGeneratorIdElevenLabs: {
        return <ElevenLabsTTSSettings />;
      }
      case EnumsTTSGeneratorId.TTSGeneratorIdOpenAI: {
        return <OpenAITTSSettings />;
      }
      default:
        return null;
    }
  }, [generatorId]);
}

export const defaultTTSElevenLabsSettings: ModelsTTSElevenLabsSettings = {
  modelId: 'eleven_monolingual_v1',
  voiceId: 'D38z5RcWu1voky8WS1ja',
  stability: 0.75,
  similarityBoost: 0.75,
};

export const defaultTTSOpenAISettings: ModelsTTSOpenAISettings = {
  modelId: 'tts-1',
  voiceId: 'alloy',
  speed: 1.0,
};

function makeDefaultGeneratorSettings(
  initial: ModelsTTSGeneratorSettings | null | undefined,
  forGeneratorId?: EnumsTTSGeneratorId | null
): ModelsTTSGeneratorSettings {
  if (!initial) {
    return { elevenLabs: defaultTTSElevenLabsSettings };
  } else if (
    initial.elevenLabs ||
    forGeneratorId === EnumsTTSGeneratorId.TTSGeneratorIdElevenLabs
  ) {
    return {
      elevenLabs: {
        modelId:
          initial.elevenLabs?.modelId ?? defaultTTSElevenLabsSettings.modelId,
        voiceId:
          initial.elevenLabs?.voiceId ?? defaultTTSElevenLabsSettings.voiceId,
        stability:
          initial.elevenLabs?.stability ??
          defaultTTSElevenLabsSettings.stability,
        similarityBoost:
          initial.elevenLabs?.similarityBoost ??
          defaultTTSElevenLabsSettings.similarityBoost,
      },
    };
  } else if (
    initial.openAI ||
    forGeneratorId === EnumsTTSGeneratorId.TTSGeneratorIdOpenAI
  ) {
    return {
      openAI: {
        modelId: initial.openAI?.modelId ?? defaultTTSOpenAISettings.modelId,
        voiceId: initial.openAI?.voiceId ?? defaultTTSOpenAISettings.voiceId,
        speed: initial.openAI?.speed ?? defaultTTSOpenAISettings.speed,
      },
    };
  } else {
    return initial;
  }
}

function Preview() {
  const raw = useWatch<ModelsTTSLabeledRenderSettings>();
  const settings = useMemo<ModelsTTSRenderSettings | null>(() => {
    if (!raw.generatorId) return null;
    if (!raw.generatorSettings)
      return { generatorId: raw.generatorId, generatorSettings: {} };

    let generatorSettings: ModelsTTSGeneratorSettings | null = null;
    switch (raw.generatorId) {
      case EnumsTTSGeneratorId.TTSGeneratorIdElevenLabs: {
        const elevenLabsSettings = raw.generatorSettings.elevenLabs;
        const voiceId = elevenLabsSettings?.voiceId;
        if (!elevenLabsSettings || !voiceId) return null;
        generatorSettings = {
          elevenLabs: {
            ...elevenLabsSettings,
            voiceId,
          },
        };
        break;
      }
      case EnumsTTSGeneratorId.TTSGeneratorIdOpenAI: {
        const openAISettings = raw.generatorSettings.openAI;
        const voiceId = openAISettings?.voiceId;
        if (!openAISettings || !voiceId) return null;
        generatorSettings = {
          openAI: {
            ...openAISettings,
            voiceId,
          },
        };
        break;
      }
      default:
        break;
    }

    if (!generatorSettings) return null;

    return {
      ...raw,
      generatorId: raw.generatorId,
      generatorSettings,
    };
  }, [raw]);

  const [previewScript, setPreviewScript] = useState(
    'Hello, world! This is a preview script.'
  );

  return (
    <div className='flex items-center gap-2 mr-auto'>
      <span className='text-sms font-bold'>Preview</span>
      <div className='flex-shrink-0'>
        <TTSPreviewButton script={previewScript} settings={settings} />
      </div>
      <textarea
        className='field m-0 min-h-13.5 py-2'
        onChange={(ev) => setPreviewScript(ev.target.value)}
        defaultValue={previewScript}
      ></textarea>
    </div>
  );
}

export function TTSLabeledSettingsEditor(props: {
  title: string;
  btnLabel: string;
  initial?: ModelsTTSLabeledRenderSettings;
  onSave: (settings: ModelsTTSLabeledRenderSettings) => void;
  onCancel: () => void;
  noGamePackProvider?: boolean;
}) {
  const form = useForm<ModelsTTSLabeledRenderSettings>({
    mode: 'onChange',
    defaultValues: {
      ...props.initial,
      id: props.initial?.id ?? uuidv4(),
      label: props.initial?.label ?? '',
      generatorId:
        props.initial?.generatorId ??
        EnumsTTSGeneratorId.TTSGeneratorIdElevenLabs,
      generatorSettings: makeDefaultGeneratorSettings(
        props.initial?.generatorSettings
      ),
    },
  });
  const { register, formState, handleSubmit, setValue } = form;
  const { isValid, isDirty } = formState;

  const handleSave = useLiveCallback(async () => {
    if (!isValid) return;
    await handleSubmit(props.onSave)();
  });

  return (
    <FormProvider<ModelsTTSLabeledRenderSettings> {...form}>
      <div className='border border-secondary bg-black rounded-xl px-5 py-3 min-w-152 w-4/5 xl:w-1/2 min-h-45'>
        <div className='w-full h-full flex flex-col text-white'>
          <div className='flex-none w-full py-2'>
            <div className='font-bold text-2xl'>{props.title}</div>
          </div>

          <div className='flex-grow flex-shrink-0 w-full py-6 space-y-5'>
            <div className='grid grid-cols-2 gap-x-6'>
              <FieldPanel>
                <FieldTitle>Label</FieldTitle>
                <FieldDescription>
                  Display name for this configuration.
                </FieldDescription>
              </FieldPanel>
              <input
                className='field h-10 m-0 w-full'
                placeholder='Max 100 characters'
                autoFocus
                {...register('label', {
                  maxLength: 100,
                  minLength: 1,
                  required: true,
                })}
              />
            </div>

            <div className='grid grid-cols-2 gap-x-6'>
              <FieldPanel>
                <FieldTitle>TTS Provider</FieldTitle>
                <FieldDescription>
                  Defines the provider to use for TTS.
                </FieldDescription>
              </FieldPanel>
              <Controller<ModelsTTSLabeledRenderSettings, 'generatorId'>
                name='generatorId'
                rules={{
                  required: true,
                }}
                render={({ field: { onChange, value } }) => {
                  const handleChange = (value: EnumsTTSGeneratorId | null) => {
                    onChange(value);
                    setValue(
                      'generatorSettings',
                      makeDefaultGeneratorSettings({}, value),
                      {
                        shouldDirty: true,
                        shouldTouch: true,
                      }
                    );
                  };
                  return (
                    <ProviderSelector onChange={handleChange} value={value} />
                  );
                }}
              />
            </div>
            <GeneratorSettings />
          </div>

          <div className='mt-auto w-full pb-2 flex items-center justify-end gap-4'>
            <Preview />
            <button
              type='button'
              className='btn-secondary w-40 py-2'
              onClick={props.onCancel}
            >
              Cancel
            </button>
            <button
              type='button'
              className='btn-primary w-40 py-2'
              onClick={handleSave}
              disabled={!isDirty || !isValid}
            >
              {props.btnLabel}
            </button>
          </div>
        </div>
      </div>
    </FormProvider>
  );
}

export function TTSRenderSettingsForm(props: {
  value?: ModelsTTSRenderSettings | null;
  onChange: (val: ModelsTTSRenderSettings) => void;
}) {
  const { value, onChange } = props;
  const form = useForm<ModelsTTSRenderSettings>({
    mode: 'onChange',
    defaultValues: {
      ...value,
      generatorId:
        value?.generatorId ?? EnumsTTSGeneratorId.TTSGeneratorIdElevenLabs,
      generatorSettings: makeDefaultGeneratorSettings(value?.generatorSettings),
    },
  });
  const { setValue, watch } = form;
  const allFields = watch();

  useEffect(() => {
    onChange(allFields);
  }, [onChange, allFields]);

  return (
    <FormProvider<ModelsTTSRenderSettings> {...form}>
      <div className='w-full h-full flex flex-col text-white'>
        <div className='flex-grow flex-shrink-0 w-full py-6 space-y-5'>
          <div className='grid grid-cols-2 gap-x-6'>
            <FieldPanel>
              <FieldTitle>TTS Provider</FieldTitle>
              <FieldDescription>
                Defines the provider to use for TTS.
              </FieldDescription>
            </FieldPanel>
            <Controller<ModelsTTSLabeledRenderSettings, 'generatorId'>
              name='generatorId'
              rules={{
                required: true,
              }}
              render={({ field: { onChange, value } }) => {
                const handleChange = (value: EnumsTTSGeneratorId | null) => {
                  onChange(value);
                  setValue(
                    'generatorSettings',
                    makeDefaultGeneratorSettings({}, value),
                    {
                      shouldDirty: true,
                      shouldTouch: true,
                    }
                  );
                };
                return (
                  <ProviderSelector onChange={handleChange} value={value} />
                );
              }}
            />
          </div>
          <GeneratorSettings />
          <Preview />
        </div>
      </div>
    </FormProvider>
  );
}
