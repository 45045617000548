import { type ReactNode, useEffect, useMemo, useState } from 'react';
import { usePrevious } from 'react-use';

import { type TeamId } from '../../../../types/team';
import { FloatLayout } from '../../../Layout';
import { LayoutAnchor } from '../../../LayoutAnchors/LayoutAnchors';
import { useMyTeamId } from '../../../Player';
import { Leaderboard } from '../Common/GamePlay/Internal';
import { useDerivedTeamProgressMap, useTeamRelayGameSettings } from './Context';
import {
  type GameProgressDetail,
  MAX_ALLOWED_COLUMNS,
  type Progress,
  RelayNodeState,
} from './types';

function TeamProgressSummary(props: {
  teamId: TeamId;
  percentage: number | undefined;
}): JSX.Element {
  const { teamId, percentage } = props;
  const [progressSummary, setProgressSummary] = useState<{
    width: number;
    percentage: number;
  }>({ width: 0, percentage: 0 });
  const myTeamId = useMyTeamId();
  const isMyTeam = myTeamId === teamId;

  const colorPalette = isMyTeam
    ? {
        progressBgColor: 'bg-tertiary',
      }
    : {
        progressBgColor: 'bg-lp-gray-002',
      };

  useEffect(() => {
    if (!percentage) return;
    // make sure the progress in the range of [0,1]
    const normalized = Math.min(Math.max(percentage, 0), 1);
    setProgressSummary({ width: normalized * 100, percentage: normalized });
  }, [percentage]);

  return (
    <div
      className={`absolute ${colorPalette.progressBgColor} inset-0 h-full transition-all`}
      style={{ width: `${progressSummary.width}%` }}
    >
      {progressSummary.percentage > 0 && progressSummary.percentage < 1 && (
        <div
          className={`game-play-leaderboard-progress absolute w-6 h-full z-0 ${colorPalette.progressBgColor} -right-6`}
        />
      )}
    </div>
  );
}

function TeamProgressDetail(props: {
  teamId: TeamId;
  progress: Progress;
}): JSX.Element | null {
  const { progress } = props;
  const updatedAt = progress.localUpdatedAt;
  const prevUpdatedAt = usePrevious(updatedAt);
  const settings = useTeamRelayGameSettings();
  const config = useMemo(() => {
    if (!settings) return;
    if (settings.level.configs.length <= progress.idx) return;
    return settings.level.configs[progress.idx];
  }, [progress, settings]);
  const width = useMemo(() => {
    if (!config) return 0;
    return config.maxColumns / MAX_ALLOWED_COLUMNS;
  }, [config]);
  const [hightlight, setHighlight] = useState<string | null>(null);

  useEffect(() => {
    if (!config || prevUpdatedAt === updatedAt) return;
    let highlightColor = null;
    if (progress.currentNodeIdx === config.maxColumns) {
      highlightColor = 'bg-green-001';
    }
    if (progress.lastNodeState === RelayNodeState.Miss) {
      highlightColor = 'bg-red-001';
    }
    if (!highlightColor) return;
    setHighlight(highlightColor);
    setTimeout(() => setHighlight(null), 200);
    return;
  }, [
    config,
    prevUpdatedAt,
    progress.currentNodeIdx,
    progress.lastNodeState,
    updatedAt,
  ]);

  if (!config) return null;

  return (
    <div className='absolute left-30 right-15 top-0 bottom-0 h-full transition-all'>
      <div
        className={`absolute h-0.5 ${
          hightlight ? `${hightlight} bg-opacity-30` : 'bg-white bg-opacity-15'
        } top-1/2 transform -translate-y-1/2`}
        style={{ width: `${width * 100}%` }}
      />
      <div
        className='h-full flex items-center justify-between'
        style={{ width: `${width * 100}%` }}
      >
        {[...Array(config.maxColumns)].map((_, i) => (
          <div
            key={i}
            className={`bg-[#FFE86D] ${
              progress.currentNodeIdx === i
                ? `w-2.5 h-2.5`
                : 'bg-opacity-40 w-1.5 h-1.5'
            } rounded-full`}
          />
        ))}
      </div>
    </div>
  );
}

function TeamProgress(props: {
  teamId: TeamId;
  percentage: number | undefined;
  progress: Nullable<Progress>;
}): JSX.Element {
  const { teamId, percentage, progress } = props;
  return (
    <>
      <TeamProgressSummary teamId={teamId} percentage={percentage} />
      {progress && <TeamProgressDetail teamId={teamId} progress={progress} />}
    </>
  );
}

export function TeamRelayLeaderboard(props: {
  header?: ReactNode;
  progressDetail?: Nullable<GameProgressDetail>;
}): JSX.Element | null {
  const { progressDetail } = props;
  const teamProgressMap = useDerivedTeamProgressMap('percentage');

  return (
    <Leaderboard
      header={props.header}
      teamNameWidth={progressDetail ? 'w-25' : undefined}
    >
      {(teamId) => (
        <TeamProgress
          teamId={teamId}
          percentage={teamProgressMap[teamId]}
          progress={progressDetail ? progressDetail[teamId] : undefined}
        />
      )}
    </Leaderboard>
  );
}

export function TeamRelayLeaderboardAsGameResults(props: {
  progressDetail?: Nullable<GameProgressDetail>;
}): JSX.Element | null {
  return (
    <>
      <FloatLayout className='flex items-center justify-center'>
        <LayoutAnchor
          id='gameplay-points-animation-top'
          className='w-full max-w-4xl'
        />
      </FloatLayout>
      <FloatLayout className='flex items-center justify-center'>
        <div className='w-120 h-68 xl:w-160 xl:h-90 2xl:w-220 2xl:h-124'>
          <TeamRelayLeaderboard
            header={
              <div
                className='w-full absolute -top-6 font-cairo font-black 
              text-4.25xl text-white text-center text-shadow-header'
              >
                GAME RESULTS
              </div>
            }
            progressDetail={props.progressDetail}
          />
        </div>
      </FloatLayout>
    </>
  );
}
