import React, {
  createContext,
  type ReactNode,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useRef,
} from 'react';

import { useInstance } from '../../hooks/useInstance';
import { type DPRCanvas, useDPRCanvas } from './DPICanvas';
import {
  type InterpolationFactor,
  type LoopOptions,
  type Ms,
} from './GameLoop';
import { useGameLoop } from './useGameLoop';

type OnDraw = (
  interp: InterpolationFactor,
  dprCanvas: DPRCanvas,
  dt: Ms
) => void;

type PixelFxContext = {
  dprCanvas: DPRCanvas;
  draws: Set<React.MutableRefObject<OnDraw | null>>;
  updates: Set<React.MutableRefObject<LoopOptions['update'] | null>>;
  markDirty: React.DispatchWithoutAction;
};

export const DrawTimeHz = 60 as const;
export const UpdateTimeHz = 30 as const;
export const DrawTimeDelta = 16.6666666 as const; // 1000 / DrawTimeHz;
export const UpdateTimeDelta = 33.3333333 as const; // 1000 / UpdateTimeHz;

const context = createContext<PixelFxContext | null>(null);

function usePixelFx(): PixelFxContext {
  const ctx = useContext(context);
  if (!ctx) throw new Error('No PixelFx Provider in the tree!');
  return ctx;
}

export function usePixelFxDraw(onDraw: OnDraw | null): void {
  const { draws, markDirty } = usePixelFx();
  const ref = useRef(onDraw);

  // If it has changed from null -> cb or vice versa, the count of Draw
  // functions has changed. Cause a recompute.
  useEffect(() => {
    if (ref.current !== onDraw && (ref.current === null || onDraw === null)) {
      markDirty();
    }
    ref.current = onDraw;
  });

  useEffect(() => {
    draws.add(ref);
    markDirty();

    return () => {
      draws.delete(ref);
      ref.current = null;
    };
  }, [draws, markDirty]);
}

export function usePixelFxUpdate(onUpdate: LoopOptions['update'] | null): void {
  const { updates, markDirty } = usePixelFx();
  const ref = useRef(onUpdate);

  // If it has changed from null -> cb or vice versa, the count of Update
  // functions has changed. Cause a recompute.
  useEffect(() => {
    if (
      ref.current !== onUpdate &&
      (ref.current === null || onUpdate === null)
    ) {
      markDirty();
    }

    ref.current = onUpdate;
  });

  useEffect(() => {
    updates.add(ref);
    markDirty();

    return () => {
      updates.delete(ref);
      ref.current = null;
    };
  }, [markDirty, updates]);
}

export function PixelFxProvider(props: { children?: ReactNode }): JSX.Element {
  const dprCanvas = useDPRCanvas();
  const [, markDirty] = useReducer((c) => c + 1, 0);

  const draws = useInstance<PixelFxContext['draws']>(() => new Set());
  const updates = useInstance<PixelFxContext['updates']>(() => new Set());

  const draw = useMemo<LoopOptions['draw']>(
    () => (interp, dt) => {
      dprCanvas.ctx.clearRect(0, 0, dprCanvas.width, dprCanvas.height);
      draws.forEach((draw) => draw.current?.(interp, dprCanvas, dt));
    },
    [dprCanvas, draws]
  );

  const update = useMemo<LoopOptions['update']>(
    () => (dt) => {
      updates.forEach((update) => update.current?.(dt));
    },
    [updates]
  );

  const hasDraws = draws.size > 0 && [...draws].some((d) => d.current !== null);
  const hasUpdates =
    updates.size > 0 && [...updates].some((d) => d.current !== null);

  useGameLoop(hasDraws || hasUpdates, {
    drawTime: DrawTimeDelta,
    updateTime: UpdateTimeDelta,
    draw,
    update,
  });

  const ctx = useMemo(
    () => ({ dprCanvas, draws, updates, markDirty }),
    [dprCanvas, draws, updates]
  );

  return <context.Provider value={ctx}>{props.children}</context.Provider>;
}
