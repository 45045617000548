import React, { type ReactNode, useContext, useEffect, useMemo } from 'react';
import { proxy } from 'valtio';

import {
  type EnumsGamePackRecommendationScenario,
  EnumsGamePackVersion,
} from '@lp-lib/api-service-client/public';
import { type Logger } from '@lp-lib/logger-base';

import { apiService } from '../../../services/api-service';
import { type Session } from '../../../types';
import { type GamePack } from '../../../types/game';
import { fromDTOGamePacks } from '../../../utils/api-dto';
import { markSnapshottable, ValtioUtils } from '../../../utils/valtio';
import {
  type FirebaseService,
  useFirebaseContext,
  useIsFirebaseConnected,
} from '../../Firebase';
import { GamePackLoader } from '../../OnDGameUIControl/OndGameUICtrl';
import { useVenueId } from '../../Venue/VenueProvider';
import { log } from '../Blocks/DrawingPrompt/utils';
import { PostGameFBUtils } from './utils';

export type PostGameSharedState = {
  sessionId: Nullable<Session['id']>;
  justPlayed: Nullable<GamePack['id']>;
  justPlayedMoreUnitsAvailable: boolean;
  justPlayedUnitLabel: string;
  recommended: Nullable<GamePack['id'][]>;
  selected: Nullable<GamePack['id']>;
};

class PostGameSharedAPI {
  private _state;

  constructor(
    venueId: string,
    svc: FirebaseService,
    private recsHandle = PostGameFBUtils.RecommendationsHandle(svc, venueId)
  ) {
    this._state = markSnapshottable(
      proxy<PostGameSharedState>(this.initialState())
    );
  }

  get state() {
    return this._state;
  }

  on(): void {
    this.recsHandle.on((val) => {
      this._state.sessionId = val?.sessionId ?? null;
      this._state.justPlayed = val?.justPlayed ?? null;
      this._state.justPlayedMoreUnitsAvailable =
        val?.justPlayedMoreUnitsAvailable ?? false;
      this._state.justPlayedUnitLabel = val?.justPlayedUnitLabel ?? 'game';
      this._state.recommended = val?.recommended ?? null;
      this._state.selected = val?.selected ?? null;
    });
  }

  off(): void {
    this.recsHandle.off();
  }

  reset() {
    ValtioUtils.reset(this._state, this.initialState());
  }

  private initialState(): PostGameSharedState {
    return {
      sessionId: null,
      justPlayed: null,
      justPlayedMoreUnitsAvailable: false,
      justPlayedUnitLabel: 'game',
      recommended: null,
      selected: null,
    };
  }
}

export type PostGameControlState = {
  loading: boolean;
  error: Nullable<unknown>;
  sessionId: Nullable<Session['id']>;
  justPlayed: Nullable<GamePack>;
  justPlayedMoreUnitsAvailable: boolean;
  justPlayedUnitLabel: string;
  recommended: Nullable<GamePack[]>;
  selected: Nullable<GamePack>;
};

class PostGameControlAPI {
  private _state = markSnapshottable(
    proxy<PostGameControlState>(this.initialState())
  );

  constructor(
    venueId: string,
    svc: FirebaseService,
    private log: Logger,
    private rootHandle = PostGameFBUtils.RootHandle(svc, venueId),
    private recsHandle = PostGameFBUtils.RecommendationsHandle(svc, venueId)
  ) {}

  get state() {
    return this._state;
  }

  async fetchAndSetRecommendations(
    sessionId: string,
    gamePackId: string,
    myId: string,
    scenario: EnumsGamePackRecommendationScenario
  ) {
    this.log.info('fetch and set recommendations');

    this._state.loading = true;
    try {
      const loader = new GamePackLoader();

      // Refetch the gamepack and compute the _next_ playback so we know if this
      // GP has more units to play!
      const playbackResult = await loader.load(gamePackId, {
        playHistoryTargetId: myId,
        subscriberId: myId,
      });

      let justPlayedUnitLabel = 'game';
      let justPlayedMoreUnitsAvailable = false;
      if (playbackResult) {
        const { playback, pack } = playbackResult;
        // We just recomputed, so "remainingUnits" could be zero: it means
        // "remaining units after the units in unitsThisSession" are played
        justPlayedMoreUnitsAvailable =
          pack.version === EnumsGamePackVersion.GamePackVersionV2 &&
          !playback.uiInfo.allUnitsPlayed;
        justPlayedUnitLabel = playback.uiInfo.unitLabel;
      }

      const gamePack = playbackResult?.pack;
      const resp = await apiService.gamePack.recommendGamePack({
        scenario,
        currentPlayedGamePackId: gamePack?.id,
      });

      const recommended = fromDTOGamePacks(resp.data.gamePacks);

      this._state.sessionId = sessionId;
      this._state.recommended = recommended;
      this._state.justPlayedUnitLabel = justPlayedUnitLabel;
      if (gamePack?.replayable || justPlayedMoreUnitsAvailable) {
        this._state.justPlayed = gamePack;
        this._state.justPlayedMoreUnitsAvailable = justPlayedMoreUnitsAvailable;
      } else {
        this._state.justPlayed = null;
        this._state.justPlayedMoreUnitsAvailable = false;
      }

      await this.recsHandle.set({
        sessionId,
        justPlayed: (this._state.justPlayed?.id ?? null) as never, // TODO(falcon): fix when firebase types are updated.
        justPlayedMoreUnitsAvailable: this._state.justPlayedMoreUnitsAvailable,
        justPlayedUnitLabel: this._state.justPlayedUnitLabel,
        recommended: recommended?.map((r) => r.id) ?? null,
        selected: null as never, // TODO(falcon): fix when firebase types are updated.
      });
    } catch (e) {
      this._state.error = e;
    } finally {
      this._state.loading = false;
    }
  }

  async reset() {
    this.log.info('reset post game state');
    ValtioUtils.reset(this._state, this.initialState());
    await this.rootHandle.remove();
  }

  async prependRecommendation(pack: GamePack) {
    const nextId = pack?.id;
    // don't add the just played game to the row.
    if (nextId === this._state.justPlayed?.id) return true;

    this.log.info('swap slot 0', { nextId });

    if (!this._state.recommended) {
      this._state.recommended = [pack];
    } else {
      this._state.recommended = [
        pack,
        ...this._state.recommended.filter((gp) => gp.id !== pack.id),
      ];
    }

    const trns = await this.recsHandle.ref.transaction((recs) => {
      // Deleted, bail.
      if (!recs) return recs;

      // don't add the just played game to the row.
      if (nextId === recs.justPlayed) return recs;

      const next = recs.recommended
        ? [nextId, ...recs.recommended.filter((id) => id !== nextId)]
        : [nextId];
      recs.recommended = next;
      return recs;
    });

    if (!trns.committed) {
      this.log.warn('swap slot 0 failed', { nextId });
      return false;
    }

    return true;
  }

  async selectRecommendedPack(gamePack: GamePack) {
    this._state.selected = gamePack;
    await this.recsHandle.update({ selected: gamePack.id });
  }

  private initialState(): PostGameControlState {
    return {
      loading: false,
      error: null,
      sessionId: null,
      justPlayed: null,
      justPlayedMoreUnitsAvailable: false,
      justPlayedUnitLabel: 'game',
      recommended: null,
      selected: null,
    };
  }
}

type Context = {
  sharedAPI: PostGameSharedAPI;
  controlAPI: PostGameControlAPI;
};

const context = React.createContext<Context | null>(null);

export function PostGameProvider(props: {
  children?: ReactNode;
}): JSX.Element | null {
  const venueId = useVenueId();
  const { svc } = useFirebaseContext();
  const firebaseConnected = useIsFirebaseConnected();

  const ctx = useMemo(() => {
    return {
      sharedAPI: new PostGameSharedAPI(venueId, svc),
      controlAPI: new PostGameControlAPI(venueId, svc, log),
    };
  }, [venueId, svc]);

  useEffect(() => {
    if (!firebaseConnected) return;
    ctx.sharedAPI.on();
    return () => ctx.sharedAPI.off();
  }, [ctx.sharedAPI, firebaseConnected]);

  return <context.Provider value={ctx}>{props.children}</context.Provider>;
}

function usePostGameContext(): Context {
  const ctx = useContext(context);
  if (!ctx) throw new Error('PostGameContext is not in the tree!');
  return ctx;
}

export function usePostGameControlAPI(): Context['controlAPI'] {
  return usePostGameContext().controlAPI;
}

export function usePostGameSharedAPI(): Context['sharedAPI'] {
  return usePostGameContext().sharedAPI;
}
