import { toHTML } from 'slack-markdown';

import { type MessageVariableMapping } from './types';

export function fillMessageWithVars(
  body: string,
  vars: MessageVariableMapping | undefined
): string {
  let message = `${body}`;
  if (vars) {
    for (const [k, v] of Object.entries(vars)) {
      const regex = new RegExp(`%${k}%`, 'g');
      message = message.replace(regex, v);
    }
  }
  return message;
}

export function slackMessageToHTML(
  body: string,
  vars?: MessageVariableMapping
): string {
  const message = fillMessageWithVars(body, vars);
  const transformed = toHTML(message, { escapeHTML: false });
  const urlCTARegex = /\[(schedule)?urlCTA[^[\]]+\]/gi;
  return transformed
    .replace(/(@[\w-]+)/g, "<span class='s-mention s-user'>$1</span>")
    .replace(/(#[\w-]+)/g, "<span class='s-mention s-channel'>$1</span>")
    .replace(urlCTARegex, '');
}
