import { type ReactNode, useEffect, useRef } from 'react';

import { type Block } from '@lp-lib/game';

import { TimeUtils } from '../../utils/time';
import { BlockIcon } from '../Game/Blocks';
import { BlockKnifeUtils } from '../Game/Blocks/Shared';
import { useRefreshLocalBlocksForGamePlay } from '../Game/GamePlayStore';
import { ArrowLeftIcon } from '../icons/Arrows';
import { useStreamingToolsSubpanelState } from '../StreamingTools/StreamingToolsContext';
import { useLocalVideoEffectsSettings } from '../VideoEffectsSettings/Storage';
import {
  getBlockRecordingUploadDesc,
  useBlockRecordingUploads,
  useRetryBlockRecordingUpload,
} from './hooks/uploads';
import { type BlockRecordingDataUpload } from './types';

const ToolsModal = (props: { children: ReactNode }) => {
  return (
    <div className='absolute top-0 left-0 w-full h-full z-5 flex items-center justify-center px-2 bg-lp-black-002'>
      {props.children}
    </div>
  );
};

export const AreYouSureYouWantToStopRecordingModal = (props: {
  onCancel: () => void;
  onConfirm: () => void;
}): JSX.Element => {
  return (
    <ToolsModal>
      <div className='border border-white bg-black rounded-xl p-2'>
        <p className='text-white text-base text-center font-bold my-6'>
          Are you sure you want to stop recording?
        </p>
        <div className='flex'>
          <button
            type='button'
            className='btn-secondary w-1/2 h-10 mx-1'
            onClick={props.onCancel}
          >
            Cancel
          </button>
          <button
            type='button'
            data-testid='block-recording-confirm-modal-stop-btn'
            className='btn-delete w-1/2 h-10 mx-1'
            onClick={props.onConfirm}
          >
            Stop
          </button>
        </div>
      </div>
    </ToolsModal>
  );
};

export const DoYouWantToOverwriteRecordingForBlock = (props: {
  onCancel: () => void;
  onConfirm: () => void;
}): JSX.Element => {
  return (
    <ToolsModal>
      <div className='border border-white bg-black rounded-xl p-2'>
        <p className='text-white text-base text-center font-bold my-6'>
          There’s already a recording for this Block, do you want to overwrite
          it?
        </p>
        <div className='flex'>
          <button
            type='button'
            className='btn-secondary w-1/2 h-10 mx-1'
            onClick={props.onCancel}
          >
            Cancel
          </button>
          <button
            type='button'
            data-testid='block-recording-confirm-modal-stop-btn'
            className='btn-delete w-1/2 h-10 mx-1'
            onClick={props.onConfirm}
          >
            Overwrite
          </button>
        </div>
      </div>
    </ToolsModal>
  );
};

function BlockRecordingLog(props: {
  block: Block;
  index: number;
  upload: BlockRecordingDataUpload;
  onRetryClick: () => void;
}) {
  const uploadDesc = getBlockRecordingUploadDesc(props.upload);
  const [getSubpanelState, setSubpanelState] = useStreamingToolsSubpanelState();
  const deeplink = getSubpanelState('recording-logs');

  const ref = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (typeof deeplink !== 'boolean' && deeplink.blockId === props.block.id) {
      ref.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
      });

      setSubpanelState('recording-logs', true);
    }
  }, [deeplink, props.block.id, setSubpanelState]);

  const bg =
    uploadDesc?.state === 'failed' ? 'bg-disabled-error' : 'bg-main-layer';

  const summary = BlockKnifeUtils.Summary(props.block);

  return !uploadDesc ? null : (
    <div
      ref={ref}
      className={`grid grid-cols-rec-logs gap-2 p-3 bg ${bg} text-white`}
    >
      <div className='flex justify-center items-center'>{uploadDesc.icon}</div>

      <div className='flex justify-between'>
        <header className='text-sm font-bold'>{uploadDesc.heading}</header>
        {uploadDesc.state === 'failed' && (
          <button
            type='button'
            className='text-primary text-2xs'
            onClick={props.onRetryClick}
          >
            Try Again
          </button>
        )}
      </div>
      <div className='row-start-2 col-start-2 flex gap-1.5 text-2xs min-w-0'>
        <BlockIcon
          className='w-3.5 h-3.5 fill-current flex-grow-0 flex-shrink-0'
          blockType={props.block.type}
        />{' '}
        <span className='truncate flex-grow'>
          {`${props.index + 1}. `}
          {summary.title || summary.prettyTypeName}
        </span>
      </div>
      <div className='row-start-3 col-start-2 text-3xs'>
        {TimeUtils.DurationFormattedHHMMSS(
          props.upload.actions.data.durationMs,
          false
        )}
      </div>
    </div>
  );
}

export function RecordingLogsPanel(props: {
  handleClose: () => void;
}): JSX.Element | null {
  const reloadBlocks = useRefreshLocalBlocksForGamePlay();
  const videoEffectsSettings = useLocalVideoEffectsSettings();
  const retry = useRetryBlockRecordingUpload(
    reloadBlocks,
    videoEffectsSettings
  );
  const uploads = useBlockRecordingUploads();

  return (
    <div className='flex flex-col h-full w-full'>
      <header
        onClick={() => props.handleClose()}
        className='flex items-center p-4 bg-modal text-white text-xs cursor-pointer'
      >
        <ArrowLeftIcon className='' />
        <p className='ml-2' data-testid='recording-logs-panel'>
          Recording Logs
        </p>
      </header>

      {!uploads.length ? (
        <div
          className='
          w-full h-full
          flex justify-center items-center
          text-xs text-disabled-gray
        '
        >
          No log entries
        </div>
      ) : (
        <div
          className='
        flex flex-col gap-0.5
      '
        >
          {uploads.map((upload, index) => (
            <BlockRecordingLog
              key={index}
              index={index}
              block={upload.actions.data.block}
              upload={upload}
              onRetryClick={() => retry(upload.actions.data.block)}
            />
          ))}
        </div>
      )}
    </div>
  );
}
