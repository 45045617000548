import { type ModelsTeamRandomizationSettings } from '@lp-lib/api-service-client/public';

import { type TeamRandomizationSettings } from '../../types/game';
import { type Team } from '../../types/team';
import { selectOptionalConfig } from '../Game/Blocks/Randomizer/utils';
import { ColorGenerator, NameGenerator } from './name-generator';
import { type Task, type TaskStep } from './types';

export function isTaskAtStep(task: Task | null, step: TaskStep): boolean {
  return task?.step === step;
}

export type TeamAssignmentRequest = {
  teamName?: string | null;
  memberClientIds: string[];
};

export class TeamAssignmentGenerator {
  constructor(
    private createTeam: (teamName: string, teamColor: string) => Promise<Team>,
    private name = new NameGenerator(),
    private color = new ColorGenerator()
  ) {}

  async makeTeamAssignment(
    assignmentRequests: TeamAssignmentRequest[]
  ): Promise<Record<string, string>> {
    const teamAssignment: Record<string, string> = {};
    const tasks = [];
    for (const req of assignmentRequests) {
      tasks.push(async () => {
        const team = await this.makeNewTeam(req.teamName);
        for (const memberId of req.memberClientIds) {
          teamAssignment[memberId] = team.id;
        }
      });
    }

    await Promise.all(tasks.map((t) => t()));
    return teamAssignment;
  }

  async makeNewTeam(teamName?: string | null): Promise<Team> {
    return await this.createTeam(
      teamName ?? this.name.generate(),
      this.color.generate()
    );
  }
}

export function resolveRandomizationSettings(
  settings:
    | TeamRandomizationSettings
    | ModelsTeamRandomizationSettings
    | null
    | undefined,
  totalNumOfPlayer: number
) {
  return selectOptionalConfig(settings, totalNumOfPlayer);
}
