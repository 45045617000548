import React, { useContext, useEffect, useMemo } from 'react';
import { proxy, useSnapshot } from 'valtio';

import { type Logger } from '@lp-lib/logger-base';

import { useInstance } from '../../../../../hooks/useInstance';
import { nullOrUndefined } from '../../../../../utils/common';
import {
  markSnapshottable,
  type ValtioSnapshottable,
  ValtioUtils,
} from '../../../../../utils/valtio';
import { Clock } from '../../../../Clock';
import { type FirebaseService } from '../../../../Firebase';
import { increment } from '../../../../Firebase/utils';
import { useUser } from '../../../../UserContext';
import {
  type Cup,
  CupState,
  DispenserState,
  GameState,
  type GroupId,
  type Indexable,
  type Ingredient,
  type Machine,
  type Order,
  type Player,
  type PlayerId,
  type Prop,
  type PropBag,
  type Truck,
  type TruckColor,
  type TruckId,
  type TutorialProgress,
} from '../types';
import { log, OverRoastedFirebaseUtils, OverRoastedUtils } from '../utils';
import {
  type OverRoastedSharedState,
  useOverRoastedGameSettings,
  useOverRoastedMyGroupId,
  useOverRoastedSharedContext,
} from './OverRoastedSharedProvider';
import { uncheckedIndexAccess_UNSAFE } from '../../../../../utils/uncheckedIndexAccess_UNSAFE';

export interface OverRoastedGamePlayState {
  truckMap: Nullable<Indexable<Truck>>;
  machineMap: Nullable<Record<string, Machine>>;
  propMap: Nullable<Indexable<Prop>>;
  orderMap: Nullable<Record<string, Order>>;
  playerMap: Nullable<Record<PlayerId, Player>>;
  tutorialProgress: Nullable<TutorialProgress>;

  localCupMap: Indexable<CupState | null>;
  localActiveMachineIdMap: Indexable<string | null>;
}

function initialState(): OverRoastedGamePlayState {
  return {
    truckMap: null,
    machineMap: null,
    propMap: null,
    orderMap: null,
    playerMap: null,
    tutorialProgress: null,

    localCupMap: {},
    localActiveMachineIdMap: {},
  };
}

class OverRoastedGamePlayAPI {
  constructor(
    readonly log: Logger,
    venueId: string,
    groupId: string,
    private sharedState: OverRoastedSharedState,
    private state: OverRoastedGamePlayState,
    firebaseService: FirebaseService,
    private trucksHandle = OverRoastedFirebaseUtils.TrucksHandle(
      firebaseService,
      venueId,
      groupId
    ),
    private machinesHandle = OverRoastedFirebaseUtils.MachinesHandle(
      firebaseService,
      venueId,
      groupId
    ),
    private propsHandle = OverRoastedFirebaseUtils.PropsHandle(
      firebaseService,
      venueId,
      groupId
    ),
    private ordersHandle = OverRoastedFirebaseUtils.OrdersHandle(
      firebaseService,
      venueId,
      groupId
    ),
    private playersHandle = OverRoastedFirebaseUtils.PlayersHandle(
      firebaseService,
      venueId,
      groupId
    ),
    private tutorialProgressHandle = OverRoastedFirebaseUtils.TutorialProgressHandle(
      firebaseService,
      venueId,
      groupId
    ),
    private summaryHandle = OverRoastedFirebaseUtils.SummaryHandle(
      firebaseService,
      venueId
    )
  ) {}

  on() {
    this.trucksHandle.on((val) => ValtioUtils.set(this.state, 'truckMap', val));
    this.machinesHandle.on((val) =>
      ValtioUtils.set(this.state, 'machineMap', val)
    );
    this.propsHandle.on((val) => ValtioUtils.set(this.state, 'propMap', val));
    this.ordersHandle.on((val) => ValtioUtils.set(this.state, 'orderMap', val));
    this.playersHandle.on((val) =>
      ValtioUtils.set(this.state, 'playerMap', val)
    );
    this.tutorialProgressHandle.on((val) =>
      ValtioUtils.set(this.state, 'tutorialProgress', val)
    );
  }
  off() {
    this.trucksHandle.off();
    this.machinesHandle.off();
    this.propsHandle.off();
    this.ordersHandle.off();
    this.playersHandle.off();
    this.tutorialProgressHandle.off();
  }

  reset() {
    this.state.localCupMap = {};
    this.state.localActiveMachineIdMap = {};
  }

  clearLocalState(truckId: number, machineId: string) {
    delete uncheckedIndexAccess_UNSAFE(this.state.localCupMap)[machineId];
    const localActiveMachineId = this.state.localActiveMachineIdMap[truckId];
    if (localActiveMachineId === machineId) {
      delete this.state.localActiveMachineIdMap[truckId];
    }
  }

  async nextTutorialStep() {
    this.log.info('nextTutorialStep', {
      curr: this.state.tutorialProgress?.step,
    });
    await this.tutorialProgressHandle.update({
      step: increment(1),
    });
  }

  async completeTutorial(groupId: GroupId) {
    this.log.info('completeTutorial');
    await this.summaryHandle.ref.child(groupId).update({
      tutorialCompleted: true,
    });
  }

  async switchTruck(playerId: PlayerId, truckId: TruckId) {
    await this.playersHandle.ref.child(playerId).update({
      activeTruckId: truckId,
      switchTruckAt: Clock.instance().now(),
    });
  }

  async activateMachine(truckId: number, machineId: string) {
    if (!this.state.localCupMap) return;
    for (const id of Object.keys(this.state.localCupMap)) {
      delete uncheckedIndexAccess_UNSAFE(this.state.localCupMap)[id];
    }
    uncheckedIndexAccess_UNSAFE(this.state.localCupMap)[machineId] =
      CupState.Selected;
    this.state.localActiveMachineIdMap[truckId] = machineId;
  }

  buildDerivedCup(machineId: string, cup: Cup, localState?: CupState): Cup {
    const localCupState =
      localState ??
      uncheckedIndexAccess_UNSAFE(this.state.localCupMap)[machineId];
    if (cup.state === CupState.Default && localCupState) {
      return { ...cup, state: localCupState };
    }
    return cup;
  }

  async addIngredient(machineId: string, ingredient: Ingredient, max = 3) {
    const result = await this.machinesHandle.ref
      .child(`${machineId}`)
      .transaction((machine) => {
        if (!machine) return;

        if (
          ![DispenserState.Default, DispenserState.Ready].includes(
            machine.dispenser.state
          )
        )
          return;
        if (![CupState.Default, CupState.Selected].includes(machine.cup.state))
          return;
        if ((machine.cup.ingredients ?? [])?.length >= max) return;

        machine.dispenser.state = DispenserState.Ready;
        machine.dispenser.stateChangedAt = Clock.instance().now();
        machine.cup.ingredients = [
          ...(machine.cup.ingredients ?? []),
          ingredient,
        ];

        return machine;
      });
    if (!result.committed) {
      this.log.warn('failed to addIngredient');
    }
  }

  async startDispenser(_truckId: number, machineId: string) {
    const result = await this.machinesHandle.ref
      .child(machineId)
      .transaction((machine) => {
        if (!machine) return;

        if (![DispenserState.Ready].includes(machine.dispenser.state)) return;

        machine.dispenser.state = DispenserState.Filling;
        machine.dispenser.stateChangedAt = Clock.instance().now();
        machine.cup.state = CupState.Filling;
        machine.cup.stateChangedAt = Clock.instance().now();
        machine.cup.fillingStartedAt = Clock.instance().now();

        return machine;
      });
    if (!result.committed) {
      this.log.warn('failed to startDispenser');
      return;
    }
  }

  async readyToServe(machineId: string) {
    const result = await this.machinesHandle.ref
      .child(machineId)
      .transaction((machine) => {
        if (!machine) return;

        if (![DispenserState.Filling].includes(machine.dispenser.state)) return;

        machine.dispenser.state = DispenserState.Serve;
        machine.dispenser.stateChangedAt = Clock.instance().now();

        return machine;
      });
    if (!result.committed) {
      this.log.warn('failed to readyToServe');
      return;
    }
  }

  async overfilled(machineId: string) {
    const result = await this.machinesHandle.ref
      .child(machineId)
      .transaction((machine) => {
        if (!machine) return;

        if (![DispenserState.Serve].includes(machine.dispenser.state)) return;

        machine.dispenser.state = DispenserState.Delete;
        machine.dispenser.stateChangedAt = Clock.instance().now();
        machine.cup.state = CupState.Overfilled;
        machine.cup.stateChangedAt = Clock.instance().now();

        return machine;
      });
    if (!result.committed) {
      this.log.warn('failed to overfilled');
      return;
    }
  }

  async deleteCup(machineId: string) {
    const result = await this.machinesHandle.ref
      .child(machineId)
      .transaction((machine) => {
        if (!machine) return;

        if (!machine.cup.ingredients?.length) return;
        if (
          ![
            CupState.Default,
            CupState.Selected,
            CupState.Filling,
            CupState.Overfilled,
          ].includes(machine.cup.state)
        )
          return;

        machine.dispenser.state = DispenserState.Default;
        machine.dispenser.stateChangedAt = Clock.instance().now();
        machine.cup.state = CupState.Deleting;
        machine.cup.stateChangedAt = Clock.instance().now();

        return machine;
      });
    if (!result.committed) {
      this.log.warn('failed to deleteCup');
      return false;
    }

    return true;
  }

  async serve(
    _groupId: GroupId,
    truckId: number,
    machineId: string
  ): Promise<CupState | undefined> {
    const matchedOrder = Object.values(this.state.orderMap ?? {}).find(
      (o) => o.truckId === truckId && o.matchedMachineId === machineId
    );

    const result = await this.machinesHandle.ref
      .child(machineId)
      .transaction((machine) => {
        if (!machine) return;

        if (![DispenserState.Serve].includes(machine.dispenser.state)) return;

        machine.dispenser.state = DispenserState.Served;
        machine.dispenser.stateChangedAt = Clock.instance().now();
        machine.cup.state = matchedOrder
          ? CupState.Matched
          : CupState.Mismatched;
        machine.cup.stateChangedAt = Clock.instance().now();
        machine.cup.servedAt = Clock.instance().now();

        return machine;
      });
    if (!result.committed) {
      this.log.warn('failed to serve');
      return;
    }

    if (!matchedOrder) {
      return CupState.Mismatched;
    }

    return CupState.Matched;
  }

  calFillingPercentage(machineId: string, totalTimeSec = 10) {
    const machine = this.state.machineMap?.[machineId];
    if (!machine) return 0;

    const game = this.sharedState.game;
    const cup = machine.cup;
    if (!cup.fillingStartedAt) return 0;
    const endTime = cup.servedAt
      ? cup.servedAt
      : game?.state === GameState.Ended
      ? game.stateUpdatedAt
      : Clock.instance().now();
    const elaspedSec = (endTime - cup.fillingStartedAt) / 1000;
    const percentage = Math.min(elaspedSec / totalTimeSec, 1);
    return percentage;
  }
}

type Context = {
  api: OverRoastedGamePlayAPI;
  state: ValtioSnapshottable<OverRoastedGamePlayState>;
};

const context = React.createContext<Context | null>(null);

function useOverRoastedGamePlayContext(): Context {
  const ctx = useContext(context);
  if (!ctx) throw new Error('OverRoastedGamePlayContext is not in the tree!');
  return ctx;
}

export function useOverRoastedGamePlayAPI(): Context['api'] {
  const { api } = useOverRoastedGamePlayContext();
  return api;
}

export function useOverRoastedGroupTrucks(): Truck[] {
  const { state } = useOverRoastedGamePlayContext();
  const trunkMap = useSnapshot(state).truckMap;
  return useMemo(() => {
    return Object.values(trunkMap || {}).sort((a, b) => a.id - b.id);
  }, [trunkMap]);
}

export function useOverRoastedGroupTruck(truckId: number): Truck | null {
  const { state } = useOverRoastedGamePlayContext();
  const trunkMap = useSnapshot(state).truckMap;
  const truck = trunkMap?.[truckId];
  return truck ?? null;
}

export function useOverRoastedGroupMachines(truckId: number): Machine[] {
  const { state } = useOverRoastedGamePlayContext();
  const machineMap = (useSnapshot(state) as typeof state).machineMap;
  return useMemo(() => {
    return Object.values(machineMap || {})
      .filter((m) => m.truckId === truckId)
      .sort((a, b) => a.index - b.index);
  }, [machineMap, truckId]);
}

export function useOverRoastedGroupMachine(
  machineId: Nullable<string>
): Machine | null {
  const { state } = useOverRoastedGamePlayContext();
  const machineMap = (useSnapshot(state) as typeof state).machineMap;
  if (nullOrUndefined(machineId)) return null;
  const machine = machineMap?.[machineId];
  return machine ?? null;
}

export function useOverRoastedActiveMachine(truckId: number): Machine | null {
  const { state } = useOverRoastedGamePlayContext();
  const activeMachineId = useSnapshot(state).localActiveMachineIdMap[truckId];
  return useOverRoastedGroupMachine(activeMachineId);
}

export function useOverRoastedDerivedCup(machine: Machine | null): Cup | null {
  const { state } = useOverRoastedGamePlayContext();
  const localCupMap = useSnapshot(state).localCupMap;
  const api = useOverRoastedGamePlayAPI();
  return useMemo(() => {
    if (!machine) return null;

    return api.buildDerivedCup(
      machine.id,
      machine.cup,
      uncheckedIndexAccess_UNSAFE(localCupMap)[machine.id]
    );
  }, [machine, api, localCupMap]);
}

export function useOverRoastedGroupPropBags(): PropBag[] {
  const { state } = useOverRoastedGamePlayContext();
  const propMap = useSnapshot(state).propMap;
  const user = useUser();
  return useMemo(() => {
    if (!propMap) return [];
    const bagMap = new Map<string, PropBag>();
    for (const [, prop] of Object.entries(propMap)) {
      const bag = bagMap.get(prop.playerId);
      if (bag) {
        bag.ingredients.push(prop.ingredient);
        bagMap.set(prop.playerId, bag);
      } else {
        bagMap.set(prop.playerId, {
          playerId: prop.playerId,
          ingredients: [prop.ingredient],
        });
      }
    }
    const bags = Array.from(bagMap.values());
    return bags.sort((a, b) => {
      const x = a.playerId === user.id ? 1 : 0;
      const y = b.playerId === user.id ? 1 : 0;
      const diff = y - x;
      return diff === 0 ? a.playerId.localeCompare(b.playerId) : diff;
    });
  }, [propMap, user.id]);
}

export function useOverRoastedTruckOrders(truckId: number): Order[] {
  const { state } = useOverRoastedGamePlayContext();
  const orderMap = (useSnapshot(state) as typeof state).orderMap;
  return useMemo(() => {
    if (!orderMap) return [];
    return Object.values(orderMap)
      .filter((o) => o.truckId === truckId)
      .sort((a, b) => a.seqNum - b.seqNum);
  }, [orderMap, truckId]);
}

export function useOverRoastedTruckActivePlayerIds(
  truckId: TruckId
): PlayerId[] {
  const { state } = useOverRoastedGamePlayContext();
  const playerMap = useSnapshot(state).playerMap;
  return useMemo(() => {
    if (!playerMap) return [];

    return Object.values(playerMap)
      .filter((p) => p.activeTruckId === truckId)
      .sort((a, b) => {
        if (a.switchTruckAt === b.switchTruckAt) {
          return a.playerId.localeCompare(b.playerId);
        }
        return a.switchTruckAt - b.switchTruckAt;
      })
      .map((p) => p.playerId);
  }, [playerMap, truckId]);
}

export function useOverRoastedActiveTruckId(): number {
  const { state } = useOverRoastedGamePlayContext();
  const playerMap = useSnapshot(state).playerMap;
  const user = useUser();
  return playerMap?.[user.id]?.activeTruckId || 0;
}

export function useOverRoastedTutorialProgress(): Nullable<TutorialProgress> {
  const { state } = useOverRoastedGamePlayContext();
  return useSnapshot(state).tutorialProgress;
}

export function useOverRoastedTruckTheme(truckId: TruckId): {
  colorName: TruckColor;
  colorCode: string;
} {
  return useMemo(() => {
    const colorName = OverRoastedUtils.GetTruckColor(truckId);
    const colorCode = OverRoastedUtils.GetTruckColorHexCode(colorName);
    return { colorName, colorCode };
  }, [truckId]);
}

export function useOverRoastedOrderPoints(machine: Machine): number {
  const settings = useOverRoastedGameSettings();
  return useMemo(() => {
    return OverRoastedUtils.CalOrderPoints(
      settings.pointsPerOrder,
      machine.cup.ingredients?.length || 0
    );
  }, [machine.cup.ingredients?.length, settings.pointsPerOrder]);
}

export function OverRoastedGamePlayProvider(props: {
  venueId: string;
  firebaseService: FirebaseService;
  ready: boolean;
  children?: React.ReactNode;
}): JSX.Element | null {
  const { venueId, firebaseService, ready, children } = props;

  const groupId = useOverRoastedMyGroupId();
  const { state: sharedState } = useOverRoastedSharedContext();
  const state = useInstance(() =>
    markSnapshottable(proxy<OverRoastedGamePlayState>(initialState()))
  );
  const ctxValue = useMemo(
    () => ({
      state,
      api: new OverRoastedGamePlayAPI(
        log,
        venueId,
        groupId,
        sharedState,
        state,
        firebaseService
      ),
    }),
    [firebaseService, groupId, sharedState, state, venueId]
  );

  useEffect(() => {
    if (!ready) return;
    ctxValue.api.on();
    return () => ctxValue.api.off();
  }, [ctxValue.api, ready]);

  return <context.Provider value={ctxValue}>{children}</context.Provider>;
}
