import { type User } from 'stream-chat';

import {
  type CreativePromptBlock,
  type CreativePromptBlockAnswerData,
  type CreativePromptBlockDetailScore,
  CreativePromptBlockGameSessionStatus,
  type RapidBlock,
} from '@lp-lib/game';

import logger from '../../../../logger/logger';
import { firebaseService } from '../../../Firebase';
import { increment } from '../../../Firebase/utils';
import { gameSessionStore } from '../../store';
import { type GameSubmissionInputBlockAdaptor } from '../Common/GamePlay/GameSubmissionInput';

const log = logger.scoped('creative-prompt-block');

export const submitCreativePromptAnswer = async (
  answer: string | null,
  teamId: string | null,
  submitter: Pick<User, 'id'>
): Promise<CreativePromptBlockAnswerData | null> => {
  const {
    venueId,
    session: { blockSession },
    refs,
  } = gameSessionStore;

  const block = (blockSession?.block ?? null) as RapidBlock | null;
  const submitterUid = submitter.id;

  if (!block || !submitterUid) return null;

  const data = {
    answer,
    voterUserIds: '',
    submitterUid,
    submittedAt: Date.now(),
  };

  if (answer) {
    const teamDataRef = refs.teamData;

    if (!teamDataRef) {
      log.error(
        'GameSession.submitCreativePromptAnswer.register',
        new Error('Failed to register teamDataRef')
      );
      return null;
    }

    const dataRef = teamDataRef.child<'data', CreativePromptBlockAnswerData>(
      'data'
    );
    await dataRef.set(data);

    const detailScoreRef =
      firebaseService.prefixedRef<CreativePromptBlockDetailScore>(
        `game-session-scores/${venueId}/${block.id}/${teamId}`
      );
    await detailScoreRef.set({
      answer,
      votes: 0,
      submittedAt: Date.now(),
      score: null,
      scoreOverride: null,
      previewPoints: null,
    });
  }

  return data;
};

export const vote = async (
  submissionTeamId: string,
  myUserId: string
): Promise<void> => {
  const {
    venueId,
    session: { blockSession },
  } = gameSessionStore;

  const blockId = blockSession?.block?.id ?? null;
  if (!venueId || !blockId) return;

  try {
    const submissionTeamRef =
      firebaseService.prefixedRef<CreativePromptBlockAnswerData>(
        `game-session-team-data/${venueId}/${blockId}/${submissionTeamId}/data`
      );
    const data = (await submissionTeamRef.get()).val();
    if (!data) return;
    const voterUserIdSet = new Set(data.voterUserIds.split(',') || []);
    voterUserIdSet.add(myUserId);

    await submissionTeamRef.update({
      voterUserIds: Array.from(voterUserIdSet).join(','),
    });

    await firebaseService
      .prefixedRef<CreativePromptBlockDetailScore>(
        `game-session-scores/${venueId}/${blockId}/${submissionTeamId}`
      )
      .update({
        votes: increment(1),
      });
  } catch (err) {
    log.error('GameSession.vote.update', err);
    return;
  }
};

export class CreativePromptBlockBlockUtils {
  static ToSubmissionInputAdaptor(
    block: CreativePromptBlock
  ): GameSubmissionInputBlockAdaptor {
    return {
      presentStatus: CreativePromptBlockGameSessionStatus.PRESENTING,
      countingStatus: CreativePromptBlockGameSessionStatus.SUBMISSION_COUNTING,
      waitingStatus: CreativePromptBlockGameSessionStatus.PRESENTING,
      timesupStatus: CreativePromptBlockGameSessionStatus.SUBMISSION_END,
      blockTime: block.fields.submissionTime,
      submit: submitCreativePromptAnswer,
    };
  }
}
