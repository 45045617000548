import React, {
  type ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useHotkeys } from 'react-hotkeys-hook';

import logger from '../../logger/logger';
import { NotificationType } from '../../types/notification';
import { ClientTypeUtils } from '../../types/user';
import { uuidv4 } from '../../utils/common';
import { useDatabaseRef, useIsFirebaseConnected } from '../Firebase/Context';
import { useNotificationDataSource } from '../Notification/Context';
import { useSetMaxTeamMembers } from '../TeamAPI/TeamV1';
import {
  useMyClientId,
  useMyClientType,
} from '../Venue/VenuePlaygroundProvider';

const log = logger.scoped('team-size-control');

interface TeamSizeContext {
  defaultMaxMembers: number;
  maxMembers: number;
  updateMaxMembers: (val: number) => Promise<void>;
}

const Context = React.createContext<TeamSizeContext>({} as TeamSizeContext);

export function useTeamSizeContext(): TeamSizeContext {
  return useContext(Context);
}

function useSetupHotKeys() {
  const { defaultMaxMembers, updateMaxMembers } = useTeamSizeContext();
  const [enabled, setEnabled] = useState(false);
  const myClientId = useMyClientId();
  const myClientType = useMyClientType();
  const notificationDataSource = useNotificationDataSource();
  const keys = useMemo(() => {
    return [...Array(defaultMaxMembers)]
      .map((_, i) => `ctrl+shift+${i + 1}`)
      .join(',');
  }, [defaultMaxMembers]);

  useEffect(() => {
    setEnabled(ClientTypeUtils.isHost(myClientType));
  }, [myClientType]);

  useHotkeys(
    keys,
    (e) => {
      try {
        // e.code => DigitN, N in [1,8]
        const n = parseInt(e.code[e.code.length - 1]);
        if (n < 1 || n > defaultMaxMembers) return;
        updateMaxMembers(n);
        notificationDataSource.send({
          id: uuidv4(),
          toUserClientId: myClientId,
          type: NotificationType.TeamSizeChanged,
          createdAt: Date.now(),
          metadata: { maxMembers: n },
        });
      } catch (error) {
        log.error('parse number error', error);
      }
    },
    {
      enabled,
    }
  );
}

function Bootstrap(): JSX.Element | null {
  useSetupHotKeys();
  return null;
}

export const TeamSizeContextProvider = (props: {
  venueId: string;
  defaultMaxMembers: number;
  children?: ReactNode;
}): JSX.Element => {
  const [maxMembers, setMaxMembers] = useState(props.defaultMaxMembers);
  const firebaseConnected = useIsFirebaseConnected();
  const ref = useDatabaseRef<number | null>(
    `venues/${props.venueId}/maxTeamSize`
  );
  const [inited, setInited] = useState(false);
  const emitMax = useSetMaxTeamMembers();

  const updateMaxMembers = useCallback(
    async (val: number) => {
      setMaxMembers(val);
      emitMax(val);
      await ref.set(val);
    },
    [emitMax, ref]
  );

  useEffect(() => {
    if (!firebaseConnected) return;
    ref.on('value', (snap) => {
      const val = snap.val() || props.defaultMaxMembers;
      setMaxMembers(val);
      emitMax(val);
      setInited(true);
    });
    return () => {
      ref.off();
      setInited(false);
    };
  }, [ref, firebaseConnected, props.defaultMaxMembers, emitMax]);

  const ctxValue: TeamSizeContext = useMemo(
    () => ({
      defaultMaxMembers: props.defaultMaxMembers,
      maxMembers,
      updateMaxMembers,
    }),
    [maxMembers, props.defaultMaxMembers, updateMaxMembers]
  );

  return (
    <Context.Provider value={ctxValue}>
      {inited && <Bootstrap />}
      {props.children}
    </Context.Provider>
  );
};
