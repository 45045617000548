import { useEffect, useRef, useState } from 'react';

import { type FBReference } from '@lp-lib/firebase-typesafe';
import { GameSessionUtil } from '@lp-lib/game';

import { useLiveCallback } from '../../hooks/useLiveCallback';
import { useIsController } from '../../hooks/useMyInstance';
import {
  apiService,
  type UpdateSessionSnapshotRequest,
} from '../../services/api-service';
import { SessionStatus } from '../../types';
import { firebaseService } from '../Firebase';
import { useScoreboardMode } from '../Game/Blocks/Scoreboard/hooks';
import { BlockKnifeUtils } from '../Game/Blocks/Shared';
import {
  useGameSessionActionsSignalManager,
  useGameSessionBlock,
  useGameSessionStatus,
  useScoreboardDataGetter,
  useSessionStatusHookManager,
} from '../Game/hooks';
import { usePlaybackInfoCurrentBlockBrand } from '../Game/Playback/PlaybackInfoProvider';
import { type SessionStatusHook } from '../Game/store/gameSessionStatusHook';
import { useMyInstance } from '../Player';
import {
  useGetStreamSessionId,
  useIsStreamSessionAlive,
  useStreamSession,
  useStreamSessionId,
  useStreamSessionStatus,
} from '../Session';
import { useTeams } from '../TeamAPI/TeamV1';
import { GameLogWriter } from './GameLog';
import {
  type GameLogCommonProperties,
  type GameLogData,
  type GameLogInfo,
  type GLInfoNarrow,
} from './GameLogInfos';

/**
 * A shortcut to append to the game log from the web-app. Everything outside of
 * this file is agnostic to the firebase implementation. This is important
 * because we may need the game-log to be writable from somewhere else, such as
 * a firebase function.
 */
export async function glAppend<Kind extends GameLogInfo['kind']>(
  kind: Kind,
  props: Omit<
    GLInfoNarrow<GameLogInfo, Kind>,
    keyof GameLogCommonProperties | 'kind'
  >,
  uniqueBy?:
    | keyof GameLogCommonProperties
    | keyof GLInfoNarrow<GameLogInfo, Kind>,
  svc = firebaseService
) {
  // get properties from store
  const complete = {
    ...getGameLogPropertyStore().getCommons(),
    ...props,
    kind,
  } as GLInfoNarrow<GameLogInfo, Kind>;

  // TODO: should this be a queue?
  if (!complete.venueId) return;

  const writer = new GameLogWriter(
    complete.venueId,
    (path: string) =>
      svc.prefixedSafeRef(path) as unknown as FBReference<GameLogData>
  );

  await writer.append(complete, uniqueBy as keyof GameLogInfo);
}

export function GameLogSynchronizeCommonProperties(props: { venueId: string }) {
  const store = getGameLogPropertyStore();
  const me = useMyInstance();
  const sessionId = useStreamSessionId();

  useEffect(() => {
    store.update({
      venueId: props.venueId,
      teamId: me?.teamId,
      userId: me?.id,
      sessionId,
      clientId: me?.clientId,
    });

    return () => {
      store.reset();
    };
  }, [me?.clientId, me?.id, me?.teamId, props.venueId, sessionId, store]);

  return null;
}

class SessionStoragePersister {
  has(key: string) {
    return Boolean(sessionStorage.getItem(key));
  }

  add(key: string) {
    return sessionStorage.setItem(key, new Date().toISOString());
  }
}

/**
 * This same call is used by SessionTracking to trigger snapshot saves. However
 * there are times when we want to cause the game log to be snapshotted without
 * the other snapshots, such as once the session has ended.
 */
export function useRequestGameLogSessionSync(venueId: Nullable<string>) {
  const getSessionId = useGetStreamSessionId();
  return useLiveCallback(async () => {
    const sid = getSessionId();

    if (!sid || !venueId) return;

    const req: UpdateSessionSnapshotRequest = {
      items: [{ key: 'game-log', remote: true }],
      // silentRefresh: false causes `isGameCompleted` to be ignored, as well as
      // preventing a session info refresh.
      silentRefresh: false,
      isGameCompleted: false,
    };
    await apiService.session.updateSessionSnapshot(venueId, sid, req);
  });
}

export function GameLogMonitorUserJoined() {
  const me = useMyInstance();
  const prevJoinedAt = useRef<number | null>(null);
  const prevTeamId = useRef<string | null>(null);

  useEffect(() => {
    if (me && me.joinedAt !== prevJoinedAt.current) {
      prevJoinedAt.current = me.joinedAt;
      glAppend('user-joined-venue', {});
    }

    if (me && me.teamId && me.teamId !== prevTeamId.current) {
      prevTeamId.current = me.teamId;
      glAppend('user-joined-team', {});
    }
  }, [me]);

  return null;
}

export function GameLogMonitorScoreboard() {
  const block = useGameSessionBlock();
  const [seenBlocks] = useState(() => new Set<string>());
  const man = useGameSessionActionsSignalManager();
  const mode = useScoreboardMode();
  const getScoreboard = useScoreboardDataGetter();
  const me = useMyInstance();

  // Only fires on controller, so we don't need to check for isController.
  const statusHookMan = useSessionStatusHookManager();
  useEffect(() => {
    const map = GameSessionUtil.StatusMapFor(block);
    if (!map || !block || !me) return;

    const hook: SessionStatusHook = {
      blockId: block.id,
      sessionStatus: map.scoreboard,
      before: async () => {
        if (seenBlocks.has(block.id)) return;
        seenBlocks.add(block.id);
        glAppend('scoreboard-shown', {
          scoreboard: await getScoreboard(mode),
        });
      },
    };

    statusHookMan.register(hook);

    return () => {
      statusHookMan.unregister(hook);
    };
  }, [block, getScoreboard, me, mode, seenBlocks, statusHookMan]);

  useEffect(() => {
    return man.connect({
      name: 'reset',
      before: async () => {
        seenBlocks.clear();
      },
    });
  }, [man, seenBlocks]);

  return null;
}

export function GameLogMonitorBrandsBlocks() {
  const block = useGameSessionBlock();
  const status = useGameSessionStatus();
  const brand = usePlaybackInfoCurrentBlockBrand();
  const [seenBrands] = useState(() => new Set<string>());
  const [seenBlockStarts] = useState(() => new Set<string>());
  const [seenBlockEnds] = useState(() => new Set<string>());
  const man = useGameSessionActionsSignalManager();
  const me = useMyInstance();
  const isController = useIsController();

  // These are together so we can control the effect order

  useEffect(() => {
    if (!me || !isController || !brand || seenBrands.has(brand.id)) return;

    seenBrands.add(brand.id);

    glAppend('brand-change', {
      brand: {
        id: brand.id,
        name: brand.name,
        showcaseText: brand.showcaseText,
      },
    });
  }, [brand, isController, me, seenBrands]);

  useEffect(() => {
    return man.connect({
      name: 'reset',
      before: async () => {
        seenBrands.clear();
        seenBlockStarts.clear();
        seenBlockEnds.clear();
      },
    });
  }, [man, seenBlockEnds, seenBlockStarts, seenBrands]);

  useEffect(() => {
    const map = GameSessionUtil.StatusMapFor(block);
    if (!map || !block || !me || !isController) return;

    if (map.intro === status && !seenBlockStarts.has(block.id)) {
      seenBlockStarts.add(block.id);
      glAppend('block-start', {
        block: BlockKnifeUtils.SummaryText(block),
      });
    } else if (map.end === status && !seenBlockEnds.has(block.id)) {
      seenBlockEnds.add(block.id);
      glAppend('block-end', {
        block: BlockKnifeUtils.SummaryText(block),
      });
    }
  }, [block, isController, me, seenBlockEnds, seenBlockStarts, status]);

  return null;
}

export function GameLogMonitorTeamNames() {
  const teams = useTeams({ excludeStaffTeam: true, active: true });
  const [seenTeamNames] = useState(() => new Map<string, string>());
  const me = useMyInstance();
  const isController = useIsController();

  useEffect(() => {
    if (!me || !isController) return;

    for (const team of teams) {
      const prevName = seenTeamNames.get(team.id);
      if (prevName === team.name) continue;
      seenTeamNames.set(team.id, team.name);
      // Do not consider it a change if the team is new!
      if (!prevName) continue;
      glAppend('team-name-change', {
        previousName: prevName,
        nextName: team.name,
      });
    }
  }, [isController, me, seenTeamNames, teams]);

  return null;
}

export function GameLogMonitorTeamCreation() {
  const teams = useTeams({ active: true });
  const [seenTeams] = useState(() => new Set<string>());
  const me = useMyInstance();
  const isController = useIsController();

  useEffect(() => {
    if (!me || !isController) return;

    for (const team of teams) {
      if (seenTeams.has(team.id)) continue;
      seenTeams.add(team.id);
      glAppend('team-created', {
        name: team.name,
      });
    }
  }, [isController, me, seenTeams, teams]);

  return null;
}

export function GameLogMonitorSession() {
  const session = useStreamSession();
  const sessionId = useStreamSessionId();
  const alive = useIsStreamSessionAlive();
  const status = useStreamSessionStatus();
  const [storage] = useState(() => new SessionStoragePersister());
  const isController = useIsController();

  useEffect(() => {
    if (!sessionId || !isController) return;

    let key;
    let kind: GameLogInfo['kind'] | undefined;
    let once = false;

    // It is implied that all of these have an id, so there is at least some
    // sort of session.

    const statusChangedAt = session?.statusChangedAt ?? 0;
    if (status === SessionStatus.NotStarted) {
      key = `${sessionId}-session-prepare-${statusChangedAt}`;
      kind = 'session-prepare';
      once = true;
    } else if (session?.abortedAt) {
      // In the real game, the 'abort' state is not exposed if it's in the
      // recovery window. We need to prioritize the abort state here.
      key = `${sessionId}-session-abort-${statusChangedAt}`;
      kind = 'session-abort';
    } else if (alive) {
      if (session?.firstStartedAt === statusChangedAt) {
        key = `${sessionId}-session-start-${statusChangedAt}`;
        kind = 'session-start';
        once = true;
      } else {
        key = `${sessionId}-session-recover-${statusChangedAt}`;
        kind = 'session-recover';
      }
    } else if (session?.endedAt) {
      key = `${sessionId}-session-end-${statusChangedAt}`;
      kind = 'session-end';
      once = true;
    }

    if (key && kind && !storage.has(key)) {
      // This is for efficiency: sessionStorage is cheaper than a network call
      // to Firebase
      storage.add(key);
      // Ensure we only log once per sessionId value for certain kinds.
      glAppend(kind, {}, once ? 'sessionId' : undefined);
    }
  }, [
    alive,
    isController,
    session?.abortedAt,
    session?.endedAt,
    session?.firstStartedAt,
    session?.statusChangedAt,
    sessionId,
    status,
    storage,
  ]);

  return null;
}

class GameLogPropertyStore {
  private props: {
    venueId: string | null;
    teamId: string | null;
    userId: string | null;
    clientId: string | null;
    sessionId: string | null;
  } = {
    venueId: null,
    teamId: null,
    userId: null,
    clientId: null,
    sessionId: null,
  };

  update(incoming: Partial<typeof this.props>) {
    const next = {
      ...this.props,
    };

    for (const k in incoming) {
      const key = k as keyof typeof incoming;
      if (incoming[key] === undefined) {
        // undefines are not allowed!
        next[key] = null;
      } else {
        next[key] = incoming[key];
      }
    }

    this.props = next;
  }

  reset() {
    this.props = {
      venueId: null,
      teamId: null,
      userId: null,
      clientId: null,
      sessionId: null,
    };
  }

  getCommons(): GameLogCommonProperties {
    return {
      venueId: this.props.venueId,
      teamId: this.props.teamId,
      userId: this.props.userId,
      clientId: this.props.clientId,
      sessionId: this.props.sessionId,
    };
  }
}

let store: GameLogPropertyStore | null = null;

/**
 * It's annoying to have to collect all the common properties a log item might
 * need at every call site. This in-memory store persists and allows writing to
 * the log from nearly everywhere without needing data from React. See the
 * {@see GameLogSynchronizeCommonProperties} component for how this works.
 */
function getGameLogPropertyStore() {
  if (!store) store = new GameLogPropertyStore();
  return store;
}
