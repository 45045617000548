import { useMemo } from 'react';
import { useController } from 'react-hook-form';
import Select from 'react-select';

import { EnumsMessageTarget } from '@lp-lib/api-service-client/public';

import { useInstance } from '../../hooks/useInstance';
import { buildReactSelectStyles } from '../../utils/react-select';
import { type Option } from '../common/Utilities';
import { type MessageLogicFormData } from './MessageLogicEditor';

export const MESSAGE_TARGET_CONFIG: {
  [Property in EnumsMessageTarget]: Option<EnumsMessageTarget> & {
    description: string;
  };
} = {
  [EnumsMessageTarget.MessageTargetOrgAdmins]: {
    label: 'Org Admins',
    value: EnumsMessageTarget.MessageTargetOrgAdmins,
    description: 'Send to all organization admins',
  },
  [EnumsMessageTarget.MessageTargetChannel]: {
    label: 'Channel',
    value: EnumsMessageTarget.MessageTargetChannel,
    description: 'Send to a specific channel',
  },
  [EnumsMessageTarget.MessageTargetChannelMembers]: {
    label: 'Channel Members',
    value: EnumsMessageTarget.MessageTargetChannelMembers,
    description: 'Send to all channel members',
  },
  [EnumsMessageTarget.MessageTargetGroups]: {
    label: 'Groups',
    value: EnumsMessageTarget.MessageTargetGroups,
    description: 'Send to all groups',
  },
  [EnumsMessageTarget.MessageTargetGroup]: {
    label: 'Group',
    value: EnumsMessageTarget.MessageTargetGroup,
    description: 'Send to a specific group',
  },
  [EnumsMessageTarget.MessageTargetMember]: {
    label: 'Member',
    value: EnumsMessageTarget.MessageTargetMember,
    description: 'Send to a specific member',
  },
  [EnumsMessageTarget.MessageTargetOrgOwners]: {
    label: 'Org Owners',
    value: EnumsMessageTarget.MessageTargetOrgOwners,
    description: 'Send to all organization owners',
  },
  [EnumsMessageTarget.MessageTargetOrgMembers]: {
    label: 'Org Members',
    value: EnumsMessageTarget.MessageTargetOrgMembers,
    description: 'Send to all organization members',
  },
  [EnumsMessageTarget.MessageTargetChannelCreator]: {
    label: 'Channel Creator',
    value: EnumsMessageTarget.MessageTargetChannelCreator,
    description: 'Send to the channel creator',
  },
};

export function MessageTargetEditor(props: {
  supportedTargets: EnumsMessageTarget[];
}) {
  const { supportedTargets } = props;

  const {
    field: { value, onChange },
  } = useController<MessageLogicFormData, 'target'>({
    name: 'target',
  });

  const options = useMemo(
    () =>
      supportedTargets.map((target) => {
        const option = MESSAGE_TARGET_CONFIG[target];
        if (!option) {
          throw new Error(`${target} not found in MESSAGE_TARGET_CONFIG`);
        }
        return option;
      }),
    [supportedTargets]
  );

  const styles = useInstance(() =>
    buildReactSelectStyles<Option<EnumsMessageTarget>>({
      override: { control: { height: 48 } },
    })
  );

  return (
    <div className='w-full'>
      <div className='mb-2 font-bold'>Target</div>
      <Select<Option<EnumsMessageTarget>>
        options={options}
        value={MESSAGE_TARGET_CONFIG[value]}
        classNamePrefix='select-box-v2'
        className='w-80'
        styles={styles}
        placeholder='Choose a target...'
        onChange={(option) => {
          onChange(option?.value);
        }}
      />
    </div>
  );
}
