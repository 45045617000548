import { type CustomRendererKeys, type Render } from './types';

export class SwitchNoticeUtils {
  private static rendererMap = new Map<CustomRendererKeys, Render>();
  static RegisterCustomRender(key: CustomRendererKeys, fn: Render): void {
    SwitchNoticeUtils.rendererMap.set(key, fn);
  }
  static GetCustomRender(key: CustomRendererKeys): Render | undefined {
    return SwitchNoticeUtils.rendererMap.get(key);
  }
}
