import { type ClientRole } from 'agora-rtc-sdk-ng';
import { useEffect, useState } from 'react';
import { useMountedState } from 'react-use';

import { getFeatureQueryParamNumber } from '../../../hooks/useFeatureQueryParam';
import { type TaskQueue } from '../../../hooks/useTaskQueue';
import {
  type AgoraJoinStatus,
  type IRTCService,
} from '../../../services/webrtc';
import {
  err2s,
  raceWithTimeout,
  sleep,
  TimeoutError,
} from '../../../utils/common';

export function useJoinRTCService(
  rtcService: IRTCService,
  channel: string,
  role: ClientRole,
  taskQueue: TaskQueue,
  options?: {
    // auto subscribe events after joining channel
    subscribeEvents?: boolean;
    // indicate if it's ready to join
    ready?: boolean;
    // how long it should wait for each join attempts
    joinTimeoutMs?: number;
    // how long it should wait before next retry
    retryIntervalMs?: number;
    // max retries
    maxRetries?: number;
    // inject fault N times, used to simuate the error
    maxFaultInjection?: number;
    // callback if we still can't join Agora after max retries
    handleFailure?: () => Promise<void>;
    onJoined?: (joinedStatus: AgoraJoinStatus | undefined) => Promise<void>;
  }
): boolean {
  const {
    subscribeEvents = false,
    ready = true,
    joinTimeoutMs = getFeatureQueryParamNumber('stream-join-timeout-ms'),
    retryIntervalMs = getFeatureQueryParamNumber(
      'stream-join-retry-interval-ms'
    ),
    maxRetries = getFeatureQueryParamNumber('stream-join-max-retries'),
    maxFaultInjection = getFeatureQueryParamNumber(
      'stream-join-fault-injection-times'
    ),
    handleFailure,
    onJoined,
  } = { ...options };
  const { addTask } = taskQueue;
  const [joined, setJoined] = useState(false);
  const mounted = useMountedState();

  useEffect(() => {
    if (!ready) return;
    const abortController = new AbortController();
    const metadata = { channel, role };
    addTask(async function joiner() {
      let attempts = 0;
      let faultInjection = maxFaultInjection;
      const totalMs = maxRetries * retryIntervalMs;
      const startMs = Date.now();
      let elapsedMs = 0;
      while (elapsedMs < totalMs) {
        elapsedMs = Date.now() - startMs;
        if (abortController.signal.aborted) {
          rtcService.log.info('join Agora aborted');
          return;
        }
        try {
          attempts += 1;
          if (faultInjection > 0) {
            faultInjection--;
            throw new Error('join Agora fault injection');
          }
          const joinStatus = rtcService.joinStatus();
          let needJoin = false;
          if (joinStatus) {
            if (joinStatus.role !== role || joinStatus.channel !== channel) {
              rtcService.log.info(
                'Agora join status mismatch, leave and rejoin'
              );
              await rtcService.leave();
              needJoin = true;
            } else {
              rtcService.log.info('Agora already joined');
            }
          } else {
            needJoin = true;
          }
          if (needJoin) {
            try {
              rtcService.log.info(`join Agora, attempts: ${attempts}`, {
                ...metadata,
                attempts,
                elapsedMs,
              });
              await raceWithTimeout(
                rtcService.join(channel, role),
                joinTimeoutMs
              );
            } catch (error) {
              if (error instanceof TimeoutError) {
                // There is no way to cancel the join, call leave once of trying abort join
                try {
                  rtcService.log.info(
                    'join Agora timeout, call leave to cleanup'
                  );
                  await rtcService.leave();
                } catch (error) {}
              }
              throw error;
            }
          }
          if (subscribeEvents) rtcService.subscribeEvents();
          if (mounted()) setJoined(true);
          onJoined?.(rtcService.joinStatus());
          rtcService.log.info(`join Agora successful, attempts: ${attempts}`, {
            ...metadata,
            attempts,
            elapsedMs,
          });
          return;
        } catch (error) {
          rtcService.log.error(
            `join Agora failed, attempts: ${attempts}`,
            err2s(error),
            { ...metadata, attempts, elapsedMs }
          );
          await sleep(retryIntervalMs);
        }
      }
      rtcService.log.error(
        'join Agora failed, max retries reached or total time exceeded',
        'max retries reached',
        { ...metadata, attempts, elapsedMs }
      );
      if (handleFailure) await handleFailure();
    });
    return () => {
      abortController.abort();
      addTask(async function leaver() {
        //Note(jialin): We never recieved the leave failure logs so far, keep it simple.
        try {
          if (subscribeEvents) rtcService.unsubscribeEvents();
          await rtcService.leave();
          if (mounted()) setJoined(false);
          rtcService.log.info('leave Agora successful', { ...metadata });
        } catch (error) {
          rtcService.log.error('leave Agora failed', err2s(error), {
            ...metadata,
          });
        }
      });
    };
  }, [
    addTask,
    channel,
    handleFailure,
    joinTimeoutMs,
    maxFaultInjection,
    maxRetries,
    mounted,
    onJoined,
    ready,
    retryIntervalMs,
    role,
    rtcService,
    subscribeEvents,
  ]);

  return joined;
}
