export function BillingIcon(props: React.SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='20'
      height='20'
      fill='none'
      viewBox='0 0 20 20'
      className={props.className}
    >
      <path
        fill='#fff'
        d='M4 3.572c-1.41 0-2.571 1.089-2.571 2.41v8.036c0 1.321 1.161 2.41 2.571 2.41h12c1.41 0 2.571-1.089 2.571-2.41V5.982c0-1.321-1.161-2.41-2.571-2.41H4zm0 1.607h12c.49 0 .857.344.857.803v.804H3.143v-.804c0-.459.368-.803.857-.803zm-.857 3.214h13.714v5.625c0 .46-.367.803-.857.803H4c-.49 0-.857-.344-.857-.803V8.393zm9.428 3.214a.888.888 0 00-.614.232.78.78 0 00-.255.572.78.78 0 00.255.572.89.89 0 00.614.231h1.715c.23.003.45-.08.614-.23a.78.78 0 00.255-.573.78.78 0 00-.255-.572.888.888 0 00-.614-.232H12.57z'
      ></path>
    </svg>
  );
}
