import { BrowserIntervalCtrl } from './BrowserIntervalCtrl';
import { isServer } from './isServer';

export function installUserActivationPolyfill() {
  if (!isServer() && !('userActivation' in navigator)) {
    const current = { hasBeenActive: false };

    Object.defineProperty(navigator, 'userActivation', {
      get() {
        return current;
      },
    });

    window.addEventListener(
      'click',
      (ev) => {
        if (ev.isTrusted) current.hasBeenActive = true;
      },
      { once: true }
    );
  }
}

export function userActivationHasBeenActive() {
  return isServer() ? false : navigator.userActivation.hasBeenActive;
}

export function installUserActivationWatcher(
  aborter: AbortController = new AbortController()
) {
  if (isServer()) return;
  const poller = new BrowserIntervalCtrl();
  const lastValue = { current: userActivationHasBeenActive() };

  poller.set(() => {
    if (aborter.signal?.aborted) return;
    const current = userActivationHasBeenActive();
    if (!current) return;

    // Once the value is `true` it cannot be reset, so clean everything up!

    lastValue.current = current;
    const event = new Event('user-activation-notice');
    document.dispatchEvent(event);

    aborter.abort();
  }, 500);

  aborter.signal?.addEventListener('abort', () => poller.clear());
}

export function onUserActivationChange(
  cb: (current: boolean) => void,
  signal?: AbortSignal
) {
  if (isServer()) return;
  const handler = () => cb(userActivationHasBeenActive());
  document.addEventListener('user-activation-notice', handler, { signal });
  return () => document.removeEventListener('user-activation-notice', handler);
}
