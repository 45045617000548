import { type Media, type RoundRobinClue } from '@lp-lib/game';

import { type TeamId } from '../../../../types';

export enum QuestionStatus {
  NotStarted,
  ShowQuestion,
  ShowAnswer,
  Ended,
}

export type MultipleChoiceAnswer = {
  id: string;
  text: string;
  correct: boolean;
};

export interface Question {
  index: number;

  question: string;
  backgroundMedia: Media | null;
  questionMedia: Media | null;
  questionMediaLoop: boolean;
  answers: string;
  answerMedia: Media | null;
  multipleChoiceAnswers?: Nullable<MultipleChoiceAnswer[]>;
  timeSec: number;
  points: number;
  status: QuestionStatus;
  statusChangedAt: number;
  clues?: Nullable<RoundRobinClue[]>;
  clueIndex: number;
  submitterUid?: Nullable<string>;
  submittedAnswer?: Nullable<string>;
  submittedAt?: Nullable<number>;
  grade?: Nullable<'Correct' | 'Wrong' | 'Skipped' | 'Unknown'>;
  gainedPoints?: Nullable<number>;
  isReplay: boolean;
}

export type QuestionMap = {
  [key: number]: Question;
};

export interface Progress {
  questionIndex: number;
  submitterClientId: string;
  submitterJoinedAt: number;
  raceUnit?: RaceUnitSimpleMedia | null;
}

export interface TeamData {
  questions: QuestionMap;
  progress: Nullable<Progress>;
}

export interface TeamsData {
  [teamId: TeamId]: TeamData;
}

export type GameProgressSummary = {
  [key: TeamId]: Nullable<{
    questionsCount: number;
    completedQuestionsCount: number;
    correctQuestionsCount: number;
    usedCluesCount: number;
    currentUsedCluesCount: number;
  }>;
};

export type RaceUnitSimpleMedia = {
  thumbnailUrl?: string | null;
  mediaUrl: string;
};

export type RaceTeam = {
  teamId: string;
  teamName: string;
  unit?: RaceUnitSimpleMedia | null;
  color?: string;
  score: number;
  rank: number;
  submittedAt: number;
};
