import {
  createContext,
  type ReactNode,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useMountedState } from 'react-use';
import { proxy, useSnapshot } from 'valtio';
import { devtools } from 'valtio/utils';

import { isServerValue } from '@lp-lib/firebase-typesafe';
import { type Logger } from '@lp-lib/logger-base';
import { ConnectionStatus } from '@lp-lib/shared-schema';

import {
  getFeatureQueryParam,
  getFeatureQueryParamArray,
  getFeatureQueryParamCommaSeparatedFeaturesString,
  getFeatureQueryParamNumber,
} from '../../hooks/useFeatureQueryParam';
import { useForceUpdate } from '../../hooks/useForceUpdate';
import { useLiveCallback } from '../../hooks/useLiveCallback';
import { useIsCoordinator } from '../../hooks/useMyInstance';
import { apiService } from '../../services/api-service';
import { BrowserIntervalCtrl } from '../../utils/BrowserIntervalCtrl';
import { assertExhaustive, err2s } from '../../utils/common';
import { markSnapshottable } from '../../utils/valtio';
import { type useExtSendChatNotifs } from '../ChatNotifs/ChatNotifs';
import { Clock } from '../Clock';
import { useAwaitFullScreenConfirmCancelModal } from '../ConfirmCancelModalContext';
import { type FirebaseService } from '../Firebase';
import {
  type CloudHostingConfig,
  CloudHostingUtils,
  OnDGameCommandDispatcher,
  type OnDGameController,
  type OnDGameControllerSemaphore,
  useAcquireOnDGameControl,
  useOnDGameControllerSemaphore,
} from '../Game/OndGameControl';
import { ErrorIcon } from '../icons/ErrorIcon';
import { useVenueId } from '../Venue/VenueProvider';
import { CloudHostingError } from './CloudHostingError';
import { log } from './shared';

type HostingState = {
  step: 'none' | 'init' | 'ready' | 'error' | 'local';
  failures: number;
  showWarning: boolean;
};

type CloudHostingOptions = CloudHostingConfig & {
  mockAPICall?: boolean;
  featureOverrides: string[];
};

const stateKey = Symbol('CloudHosting');

function buildHostingUrl(venueId: string, overrides: string[]): string {
  const base = new URLSearchParams(
    [
      'crowd-view=disabled',
      'crowd-frames-service=dummy',
      'crowd-frames-avatar-rendering=disabled',
      'crowd-frames-registration=disabled',
      'spotlight-block-overlay-media=disabled',
      'spotlight-block-welcome-message=disabled',
      'game-on-demand-video-mixer-loop-use-worker=disabled',
      'game-on-demand-video-mixer-loop-method=raf-accumulated',
      'game-play-video-loop-method=raf',
      'game-play-video-loop-use-worker=disabled',
      'stream-use-dual-stream=disabled',
      'ui-animations=disabled',
    ].join('&')
  );

  const parsedOverrides = new URLSearchParams(overrides.join('&'));

  // Parse then set so that the override is not an "append" operation but rather
  // a "replace" operation. URLSearchParams by default ignore the values any
  // subsequent/duplicate keys.
  for (const [key, value] of parsedOverrides) {
    base.set(key, value);
  }

  const params = base.toString();
  return `${window.location.origin}/cloud-host/venue/${venueId}?${params}`;
}

async function applyCloudHosting(
  venueId: string,
  url: string,
  mock?: boolean,
  group = getFeatureQueryParamArray('cloud-hosting-group')
) {
  const resp = mock
    ? ({ result: 'applied' } as const)
    : (await apiService.venue.applyCloudHosting(venueId, { url, group })).data;
  log.info('apply api result', { result: resp.result });
  const result = resp.result;
  switch (result) {
    case 'unavailable':
      throw new Error('cloud controller is not available');
    case 'applied':
    case 'binding-exists':
      // do nothing
      break;
    default:
      assertExhaustive(result);
      throw new Error(`unknown result: ${result}`);
  }
}

async function releaseCloudHosting(
  venueId: string,
  report: boolean,
  mock?: boolean
) {
  const resp = mock
    ? ({ result: 'released' } as const)
    : (await apiService.venue.releaseCloudHosting(venueId, report)).data;
  log.info('release api result', { result: resp.result });
}

class CloudHosting {
  private state = markSnapshottable(proxy<HostingState>(this.initialState()));
  constructor(
    readonly venueId: string,
    readonly controllerSemaphore: ReturnType<
      typeof useOnDGameControllerSemaphore
    >,
    readonly log: Logger,
    readonly options: CloudHostingOptions,
    readonly deps = {
      applyCloudHosting,
      releaseCloudHosting,
      getTimeMs: Clock.instance().now,
    }
  ) {}

  getState(_key: typeof stateKey) {
    return this.state;
  }

  async apply() {
    switch (this.state.step) {
      case 'none':
      case 'error':
        const controller = await this.controllerSemaphore.instance();
        if (!controller) {
          this.log.info(
            'no controller in firebase, cleanup the old binding if exists'
          );
          await this.tryRelease();
        }
        this.log.info('init cloud hosting', { prevState: this.state.step });
        return await this.tryApply();
      case 'init':
        this.log.info('cloud hosting is initializing, skipped');
        return await this.controllerSemaphore.ensureInstance();
      case 'ready':
        this.log.info('cloud hosting is ready');
        return await this.controllerSemaphore.ensureInstance();
      case 'local':
        this.log.info('cloud hosting uses local controller');
        return await this.controllerSemaphore.ensureInstance();
      default:
        assertExhaustive(this.state.step);
        throw new Error(`unknown state: ${this.state.step}`);
    }
  }

  updateStep(val: HostingState['step']) {
    this.state.step = val;
  }

  trackFailures(by = 1) {
    this.state.failures += by;
  }

  getCurrentStep(snap = this.state): HostingState['step'] {
    return snap.step;
  }

  getFailures(snap = this.state): number {
    return snap.failures;
  }

  registerDevtools() {
    return devtools(this.state, { name: 'Cloud Hosting Store' });
  }

  toggleWarning(controller: OnDGameController | null, thresholdMs = 3000) {
    this.state.showWarning =
      !!controller &&
      controller.kind === 'cloud' &&
      controller.status === ConnectionStatus.Disconnected &&
      controller.disconnectedAt !== undefined &&
      !isServerValue(controller.disconnectedAt) &&
      this.deps.getTimeMs() - controller.disconnectedAt > thresholdMs &&
      !CloudHostingUtils.NoHeartbeat(controller);
  }

  showWarning(snap = this.state) {
    return snap.showWarning;
  }

  async tryRelease(report?: boolean) {
    try {
      this.log.info('release cloud hosting attempt');
      await this.deps.releaseCloudHosting(
        this.venueId,
        report ?? false,
        this.options.mockAPICall
      );
      await this.controllerSemaphore.release();
      this.updateStep('none');
      this.log.info('release cloud hosting successful');
    } catch (error) {
      this.log.error('release cloud hosting failed', err2s(error));
    }
  }

  private async tryApply() {
    this.state.step = 'init';
    try {
      await this.deps.applyCloudHosting(
        this.venueId,
        buildHostingUrl(this.venueId, this.options.featureOverrides),
        this.options.mockAPICall
      );
      const controller = await this.controllerSemaphore.ensureInstance({
        getTimeoutMs: this.options.launchTimeout,
        heartbeatTimeoutMs: this.options.heartbeatTimeout,
      });
      this.log.info('init cloud hosting successful', { controller });
      this.state.step = 'ready';
      return controller;
    } catch (error) {
      this.state.step = 'none';
      this.log.error('init cloud hosting failed', err2s(error));
      throw error;
    }
  }

  private initialState(): HostingState {
    return {
      step: 'none',
      failures: 0,
      showWarning: false,
    };
  }
}

function useTriggerCloudHostingErrorModal() {
  const triggerFullScreenModal = useAwaitFullScreenConfirmCancelModal();

  return useLiveCallback(async (onComplete?: () => void) => {
    await triggerFullScreenModal({
      kind: 'custom',
      containerClassName: 'bg-black bg-opacity-60',
      element: (p) => (
        <CloudHostingError
          onComplete={() => {
            p.internalOnConfirm();
            onComplete?.();
          }}
          onClose={p.internalOnCancel}
          maxAttempts={2}
        />
      ),
    });
  });
}

export function useApplyControllerWithErrorHandler() {
  const triggerCloudHostingErrorModal = useTriggerCloudHostingErrorModal();
  const { cloudHosting } = useOnDGameHostingManagerContext();

  return useLiveCallback(async () => {
    const controller = await (async () => {
      try {
        return await cloudHosting.apply();
      } catch (error) {
        log.error('apply controller failed', err2s(error));
        cloudHosting.trackFailures();
      }
    })();
    const step = cloudHosting.getCurrentStep();
    if (!controller || (controller.kind === 'local' && step !== 'local')) {
      await triggerCloudHostingErrorModal();
      return false;
    }
    return true;
  });
}

class HealthCheckFailedError extends Error {
  name = 'HealthCheckFailedError';
}

function CloudHostingChatNotifContent() {
  return (
    <div className='text-black text-sms flex flex-col gap-3'>
      <div className='font-bold'>Game Stuck? Try this.</div>
      <div>
        <p>If you are experiencing issues with the game, please try the</p>
        <div className='inline-block bg-black px-2 py-1 rounded-lg mx-0.5'>
          <ErrorIcon className='fill-current text-red-004 w-3.5 h-3.5 inline mr-0.5' />
          <span className='text-xs text-white'>Stuck Game?</span>
        </div>
        button from the dropdown menu on the top right corner.
      </div>
    </div>
  );
}

function CloudHostingHealthCheck(props: {
  cloudHosting: CloudHosting;
  sendChatNotifs: ReturnType<typeof useExtSendChatNotifs>;
  notifyCooldown: number;
}) {
  const { cloudHosting, sendChatNotifs, notifyCooldown } = props;
  const isCoordinator = useIsCoordinator();
  const { controller } = useReadOndController(true);
  cloudHosting.toggleWarning(controller);
  const ready = controller?.kind === 'cloud';
  const lastNotifiedAt = useRef(0);

  useEffect(() => {
    return () => {
      lastNotifiedAt.current = 0;
    };
  }, []);

  useLayoutEffect(() => {
    if (!ready) return;
    const pollMs = cloudHosting.options.healthCheckInternal;
    log.info('start health check', { pollMs });
    const ctrl = new BrowserIntervalCtrl(true);
    ctrl.set(async () => {
      if (cloudHosting.getCurrentStep() === 'none') return;
      try {
        const controller =
          await cloudHosting.controllerSemaphore.ensureInstance({
            getTimeoutMs: cloudHosting.options.getTimeout,
            heartbeatTimeoutMs: cloudHosting.options.heartbeatTimeout,
            allowDisconnected: true,
          });
        log.debug('health check good', { controller });
      } catch (error) {
        const err = new HealthCheckFailedError('health check failed', {
          cause: error instanceof Error ? error : err2s(error),
        });
        log.error(err.message, err);
        // everyone do health check, but only coordinator get the notification
        if (!isCoordinator) return;
        const now = Date.now();
        if (now - lastNotifiedAt.current < notifyCooldown) return;
        sendChatNotifs({
          notifContent: <CloudHostingChatNotifContent />,
          withSoundEffect: true,
          containerClassName: '!bg-tertiary',
        });
        lastNotifiedAt.current = now;
      }
    }, pollMs);
    return () => {
      log.info('stop health check');
      ctrl.clear();
    };
  }, [cloudHosting, isCoordinator, notifyCooldown, ready, sendChatNotifs]);
  return null;
}

type OnDGameHostingManagerAPI = {
  dispatcher: OnDGameCommandDispatcher;
  cloudHosting: CloudHosting;
};

const Context = createContext<null | OnDGameHostingManagerAPI>(null);

function useOnDGameHostingManagerContext(): OnDGameHostingManagerAPI {
  const ctx = useContext(Context);
  if (!ctx) throw new Error('OnDGameHostingManagerContext is not in the tree!');
  return ctx;
}

function useExpose(cloudHosting: CloudHosting) {
  return useSnapshot(cloudHosting.getState(stateKey));
}

export function useOnDGameCommandDispatcher(): OnDGameCommandDispatcher {
  const ctx = useOnDGameHostingManagerContext();
  return ctx.dispatcher;
}

export function useOnDGameCloudHosting(): CloudHosting {
  const ctx = useOnDGameHostingManagerContext();
  return ctx.cloudHosting;
}

export function useTryReleaseController(): CloudHosting['tryRelease'] {
  const { cloudHosting } = useOnDGameHostingManagerContext();
  return useMemo(
    () => cloudHosting.tryRelease.bind(cloudHosting),
    [cloudHosting]
  );
}

export function useShowOndHostingWarning() {
  const cloudHosting = useOnDGameCloudHosting();
  const snap = useExpose(cloudHosting);
  return cloudHosting.showWarning(snap);
}

function useOptionalControllerSemaphore(): OnDGameControllerSemaphore | null {
  try {
    return useOnDGameControllerSemaphore();
  } catch (error) {
    return null;
  }
}

export function useReadOndController(
  enabled: boolean,
  getTimeoutMs = 500,
  pollMs = 1000
) {
  const controllerSemaphore = useOptionalControllerSemaphore();
  const [controller, setController] = useState<OnDGameController | null>(null);
  const [error, setError] = useState<unknown>();
  const mounted = useMountedState();
  const forceUpdate = useForceUpdate();

  useLayoutEffect(() => {
    if (!controllerSemaphore || !enabled) return;
    const ctrl = new BrowserIntervalCtrl();
    ctrl.set(async () => {
      try {
        const c = await controllerSemaphore.instance(true, true);
        if (mounted()) {
          setController(c);
          // if the controller is dead, the data is no longer updated. Use
          // __forceUpdate__ to trigger the rendering.
          forceUpdate();
        }
      } catch (error) {
        setError(error);
        forceUpdate();
      }
    }, pollMs);
    return () => ctrl.clear();
  }, [
    controllerSemaphore,
    enabled,
    forceUpdate,
    getTimeoutMs,
    mounted,
    pollMs,
  ]);

  return {
    controller,
    error,
  };
}

export function OnDGameHostingManagerProvider(props: {
  svc: FirebaseService;
  options?: Partial<CloudHostingOptions>;
  children?: ReactNode;
  sendChatNotifs: ReturnType<typeof useExtSendChatNotifs>;
}): JSX.Element {
  const { svc } = props;
  const venueId = useVenueId();
  const controllerSemaphore = useOnDGameControllerSemaphore();
  const dispatcher = useMemo(
    () => new OnDGameCommandDispatcher(venueId, svc, log),
    [svc, venueId]
  );
  const cloudHosting = useMemo(
    () =>
      new CloudHosting(venueId, controllerSemaphore, log, {
        ...CloudHostingUtils.GetDefaultConfig(),
        mockAPICall: getFeatureQueryParam('cloud-hosting-mock-api-call'),
        featureOverrides: getFeatureQueryParamCommaSeparatedFeaturesString(
          'cloud-hosting-feature-overrides'
        ),
        ...props.options,
      }),
    [controllerSemaphore, props.options, venueId]
  );

  useLayoutEffect(() => {
    return cloudHosting.registerDevtools();
  }, [cloudHosting]);

  const ctx: OnDGameHostingManagerAPI = useMemo(
    () => ({
      dispatcher,
      cloudHosting,
    }),
    [dispatcher, cloudHosting]
  );
  return (
    <Context.Provider value={ctx}>
      <CloudHostingHealthCheck
        cloudHosting={cloudHosting}
        sendChatNotifs={props.sendChatNotifs}
        notifyCooldown={getFeatureQueryParamNumber(
          'cloud-hosting-health-check-notify-cooldown'
        )}
      />
      {props.children}
    </Context.Provider>
  );
}

export function LaunchOnDGameLocalControl(props: {
  id: string;
}): JSX.Element | null {
  const cloudHosting = useOnDGameCloudHosting();
  // We added the debug tools that anyone can take over the OnD Game control.
  // With the local game control, both coordinator and controller are same
  // participant instance. If the coordiantor is taken over, the controller
  // should be updated as well. However, the _controllerSemaphore.release_ will
  // be done after the controller changes, we increase the attempts here so the
  // new instance can retry if the first/two attempts failed.
  const acquired = useAcquireOnDGameControl(props.id, 'local', 10);

  useLayoutEffect(() => {
    if (!acquired) return;
    cloudHosting.updateStep('local');
  }, [acquired, cloudHosting]);

  return null;
}
