import { type ReactNode, useMemo } from 'react';

import {
  EnumsTTSGeneratorId,
  type ModelsTTSLabeledRenderSettings,
} from '@lp-lib/api-service-client/public';

import { useLiveCallback } from '../../hooks/useLiveCallback';
import { uuidv4 } from '../../utils/common';
import { useAwaitFullScreenConfirmCancelModal } from '../ConfirmCancelModalContext';
import {
  type FieldContainer,
  SimpleFieldEditor,
} from '../Game/Blocks/Common/Editor/FieldEditorUtilities';
import {
  defaultTTSElevenLabsSettings,
  TTSLabeledSettingsEditor,
} from './TTSLabeledSettingsEditor';

export const defaultTTSOptions: ModelsTTSLabeledRenderSettings[] = [
  {
    id: uuidv4(),
    label: 'LP Default',
    generatorId: EnumsTTSGeneratorId.TTSGeneratorIdElevenLabs,
    generatorSettings: {
      elevenLabs: defaultTTSElevenLabsSettings,
    },
  },
];

// useful in case the data model supports an array of settings, but we only want to enable one.
export function TTSOptionEditor(props: {
  name?: ReactNode;
  description?: ReactNode;
  value?: ModelsTTSLabeledRenderSettings[] | null;
  onChange: (value: ModelsTTSLabeledRenderSettings[]) => void;
  styles?: Parameters<typeof FieldContainer>[0]['styles'];
}) {
  const triggerModal = useAwaitFullScreenConfirmCancelModal();

  const handleTTSSettingsClick = useLiveCallback(async (index: number) => {
    await triggerModal({
      kind: 'custom',
      element: (p) => {
        const handleSave = (settings: ModelsTTSLabeledRenderSettings) => {
          const copy = [...(props.value ?? [])];
          copy[index] = settings;
          props.onChange(copy);
          p.internalOnConfirm();
        };
        return (
          <TTSLabeledSettingsEditor
            title='Edit Settings'
            btnLabel='Save'
            initial={props.value?.[index]}
            onCancel={p.internalOnCancel}
            onSave={handleSave}
          />
        );
      },
    });
  });

  const handleAddDefaultTTSSettings = useLiveCallback(async () => {
    props.onChange(defaultTTSOptions);
  });

  const defaultOption = useMemo(() => {
    return props.value?.[0];
  }, [props.value]);

  return (
    <SimpleFieldEditor
      name={props.name ?? 'TTS Options'}
      description={
        props.description ?? 'Defines the available TTS options for the block.'
      }
      styles={props.styles}
    >
      {defaultOption ? (
        <div
          className='w-full bg-layer-002 rounded border border-secondary px-4 py-2 flex items-center justify-between cursor-pointer hover:border-primary transition-colors'
          onClick={() => handleTTSSettingsClick(0)}
        >
          <div className='text-white font-bold'>{defaultOption.label}</div>
          <div className='text-icon-gray italic text-sms'>Default</div>
        </div>
      ) : (
        <div className='w-full flex justify-end'>
          <button
            type='button'
            className='btn-secondary w-40 h-10'
            onClick={handleAddDefaultTTSSettings}
          >
            Add Default
          </button>
        </div>
      )}
    </SimpleFieldEditor>
  );
}
