import { useCallback } from 'react';

import { useSSRLayoutEffect } from '../../app/components/hooks/useSSRLayoutEffect';
import { type Paginable, type Paginator } from '../services/api-service';
import { type Repository, useArrayState } from './useArrayState';
import { type TransformedAsyncCallState, useAsyncCall } from './useAsyncCall';

export function useListLoader<P extends Paginable, T>(
  paginator: Paginator<P, T>,
  compare?: (a: T, b: Partial<T>) => boolean
): {
  items: T[];
  dao: Repository<T>;
  state: TransformedAsyncCallState;
  error: Error | null;
  handleRetry: () => void;
  handleLoadMore: () => void;
  hasMore: () => boolean;
} {
  const [items, setItems, dao] = useArrayState<T>({
    prepend: true,
    compare,
  });

  const {
    state,
    error,
    call: load,
    reset,
  } = useAsyncCall(
    useCallback(async () => {
      const id = paginator.getId();
      const next = await paginator.next();
      // data source changed, ignore the response
      const latestId = paginator.getId();
      if (id !== latestId) {
        return;
      }
      if (next) {
        setItems((prev) => {
          return [...prev, ...next];
        });
      }
    }, [paginator, setItems])
  );

  const resetAll = useCallback(() => {
    setItems([]);
    paginator.reset();
    reset();
  }, [setItems, paginator, reset]);

  useSSRLayoutEffect(() => {
    load();
    return () => {
      resetAll();
    };
  }, [load, resetAll]);

  const handleLoadMore = () => {
    if (!state.transformed.isRunning && paginator.hasMore()) load();
  };

  return {
    items,
    dao,
    state: state.transformed,
    error,
    handleLoadMore,
    handleRetry: load,
    hasMore: () => paginator.hasMore(),
  };
}
