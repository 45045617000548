import { useEffect, useRef, useState } from 'react';

import { asFBReference } from '@lp-lib/firebase-typesafe';

import { getLogger } from '../../logger/logger';
import { assertExhaustive } from '../../utils/common';
import { createProvider } from '../../utils/createProvider';
import { type FirebaseService, useFirebaseContext } from '../Firebase';
import {
  type FBMsgPassStorage,
  recv,
  send,
} from '../Firebase/FirebaseMessagePassing';
import {
  useAmICohostGetter,
  useMyInstanceGetter,
  useParticipantFlagsGetter,
} from '../Player';
import { useUserContext } from '../UserContext';

const { Provider, useCreatedContext: useAudienceControlReceiver } =
  createProvider<AudienceControlReceiver>('AudienceControlReceiver');

const {
  Provider: SenderProvider,
  useCreatedContext: useAudienceControlSender,
} = createProvider<AudienceControlSender>('AudienceControlSender');

export { useAudienceControlSender, useAudienceControlReceiver };

export function AudienceControlMsgSenderProvider(props: {
  children?: React.ReactNode;
  venueId: string;
}) {
  const svc = useFirebaseContext().svc;

  const [sender] = useState(() => {
    return new AudienceControlSender(svc, props.venueId);
  });

  return <SenderProvider value={sender}>{props.children}</SenderProvider>;
}

export function AudienceControlMsgReceiverProvider(props: {
  children?: React.ReactNode;
  venueId: string;
  ready: boolean;
}) {
  const svc = useFirebaseContext().svc;
  const { toggleAudio } = useUserContext();

  const getAmICohost = useAmICohostGetter();
  const getMyInstance = useMyInstanceGetter();
  const getParticipantFlags = useParticipantFlagsGetter();

  const deps = useRef(
    new LocalAudienceControlAPI({
      getAmICohost,
      getMyInstance,
      getParticipantFlags,
      toggleAudio,
    })
  );

  useEffect(() => {
    deps.current.syncDeps({
      getAmICohost,
      getMyInstance,
      getParticipantFlags,
      toggleAudio,
    });
  }, [getAmICohost, getMyInstance, getParticipantFlags, toggleAudio]);

  const [receiver] = useState(() => {
    return new AudienceControlReceiver(svc, props.venueId, deps.current);
  });

  useEffect(() => {
    if (!props.ready) return;
    receiver.init();
    return () => {
      getLogger().scoped('AudienceControlProvider').info(`destroy api`);
      receiver?.destroy();
    };
  }, [props.ready, receiver]);

  return <Provider value={receiver}>{props.children}</Provider>;
}

function audienceControlMessageTransport(
  venueId: string,
  svc: FirebaseService
) {
  const transport = asFBReference<FBMsgPassStorage>(
    svc.prefixedSafeRef(`audience-control-msg/${venueId}`)
  );
  return transport;
}

type AudienceControlMessage = {
  type: 'mute';
};

class AudienceControlSender {
  constructor(
    svc: FirebaseService,
    venueId: string,
    private transport = audienceControlMessageTransport(venueId, svc)
  ) {}

  async mute() {
    await this.send({ type: 'mute' });
  }

  private async send(msg: AudienceControlMessage) {
    await send(this.transport, msg, { cleanupAfterMs: 2000 });
  }
}

class AudienceControlReceiver {
  off: ReturnType<typeof recv<AudienceControlMessage>> | undefined;

  constructor(
    svc: FirebaseService,
    venueId: string,
    private api: LocalAudienceControlAPI,
    private transport = audienceControlMessageTransport(venueId, svc)
  ) {}

  init() {
    this.off?.();
    this.off = recv<AudienceControlMessage>(
      this.transport,
      async (req, _id) => {
        switch (req.type) {
          case 'mute':
            return this.api.mute();
          default:
            assertExhaustive(req.type);
        }
      }
    );
  }

  destroy() {
    this.off?.();
  }
}

type LocalAudienceControlDeps = {
  getAmICohost: () => boolean;
  getMyInstance: ReturnType<typeof useMyInstanceGetter>;
  getParticipantFlags: ReturnType<typeof useParticipantFlagsGetter>;
  toggleAudio: ReturnType<typeof useUserContext>['toggleAudio'];
};

class LocalAudienceControlAPI {
  deps: LocalAudienceControlDeps;

  constructor(deps: LocalAudienceControlDeps) {
    this.deps = deps;
  }

  syncDeps(deps: typeof this.deps) {
    this.deps = {
      ...this.deps,
      ...deps,
    };
  }

  mute() {
    if (this.deps.getAmICohost()) return;
    const myInstance = this.deps.getMyInstance();
    const myFlags = this.deps.getParticipantFlags(myInstance?.clientId);
    if (myFlags?.onStage) return;
    this.deps.toggleAudio(false, 'host');
  }
}
