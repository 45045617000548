import { type ReactNode, useState } from 'react';

import {
  type DrawingPromptBlock,
  DrawingPromptBlockGameSessionStatus,
} from '@lp-lib/game';

import { assertExhaustive } from '../../../../utils/common';
import { useTeams } from '../../../TeamAPI/TeamV1';
import { useGameSessionLocalTimer, useGameSessionStatus } from '../../hooks';
import {
  type GamePlayUIStateControl,
  Leaderboard,
  useCountdownPlaySFX,
  useOneTimePlayGoAnimation,
} from '../Common/GamePlay/Internal';
import { DrawingPromptMatchPrompt } from './DrawingPromptMatchPrompt';
import {
  useAllPickedDrawings,
  useGameProgressSummary,
  useTeamVotePhaseSkippable,
} from './DrawingPromptProvider';
import { ReviewAllDrawings } from './DrawingPromptReviewAllDrawings';
import { WaitForMatch } from './DrawingPromptWaitForMatch';
import { DrawingPromptUtils } from './utils';

function Layout(props: { children?: ReactNode }) {
  return (
    <div className='w-[90%] h-[90%] flex items-center justify-center'>
      {props.children}
    </div>
  );
}

function Header(props: { text: string }): JSX.Element {
  return (
    <div
      className='w-full absolute font-cairo text-3xl text-white 
            text-shadow-header transform -translate-y-2/3 left-4'
    >
      {props.text}
    </div>
  );
}

function DrawingPhase(props: {
  block: DrawingPromptBlock;
  uiControl: GamePlayUIStateControl;
  drawable?: boolean;
  ended?: boolean;
}): JSX.Element | null {
  const { block, uiControl, drawable, ended } = props;
  const summary = useGameProgressSummary();
  const time = useGameSessionLocalTimer();
  const remainingTime = ended ? 0 : time ?? 0;
  useCountdownPlaySFX(block.fields.drawingTimeSec, remainingTime, true);
  useOneTimePlayGoAnimation(uiControl, () => remainingTime > 0 && !!drawable);
  return (
    <Layout>
      <Leaderboard
        header={<Header text='Drawing Progress' />}
        rightColumn={{
          header: (
            <div className='flex flex-row w-80 justify-between font-medium'>
              <div className='w-60 text-left'>Prompt</div>
              <div className='w-20 text-right'>Progress</div>
            </div>
          ),
          body: (teamId) => {
            const info = summary[teamId];
            const numOfDrawings = info?.numOfDrawings ?? 0;
            // in case someone left after submitting drawing
            const numOfPlayers = Math.max(
              numOfDrawings,
              info?.numOfPlayers ?? 0
            );
            const percentage =
              numOfPlayers > 0
                ? Math.floor((numOfDrawings / numOfPlayers) * 100)
                : 0;
            return (
              <div className='flex flex-row w-80 justify-between font-bold'>
                <div className='w-60 text-left'>
                  {info?.prompt?.correct.toLowerCase() ?? 'N/A'}
                </div>
                <div className='w-20 text-right'>
                  {numOfDrawings}/{numOfPlayers} - {percentage}%
                </div>
              </div>
            );
          },
        }}
      />
    </Layout>
  );
}

function VotingPhase(props: {
  block: DrawingPromptBlock;
  uiControl: GamePlayUIStateControl;
  ended?: boolean;
}): JSX.Element | null {
  const { block, uiControl, ended } = props;
  const summary = useGameProgressSummary();
  const time = useGameSessionLocalTimer();
  const remainingTime = ended ? 0 : time ?? 0;
  const { skippable } = useTeamVotePhaseSkippable();
  useCountdownPlaySFX(block.fields.votingTimeSec, remainingTime, true);
  useOneTimePlayGoAnimation(uiControl, () => remainingTime > 0 && !skippable);

  return (
    <Layout>
      <Leaderboard
        header={<Header text='Voting Progress' />}
        rightColumn={{
          header: (
            <div className='flex flex-row w-40 justify-between font-medium'>
              <div className='w-full text-right'>Drawing Selected</div>
            </div>
          ),
          body: (teamId) => {
            const info = summary[teamId];
            return (
              <div className='flex flex-row w-40 justify-between'>
                <div
                  className={`w-full text-right font-bold ${
                    info?.pickedDrawing ? 'text-[#15DF96]' : 'text-red-002'
                  }`}
                >
                  {info?.pickedDrawing ? 'YES' : 'NO'}
                </div>
              </div>
            );
          },
        }}
      />
    </Layout>
  );
}

function TitleCreationPhase(props: {
  block: DrawingPromptBlock;
  uiControl: GamePlayUIStateControl;
  ended?: boolean;
}): JSX.Element | null {
  const { uiControl, ended } = props;
  const total = useAllPickedDrawings().length;
  const summary = useGameProgressSummary();
  const time = useGameSessionLocalTimer();
  const remainingTime = ended ? 0 : time ?? 0;
  const [titleCreationTimeSec] = useState(
    DrawingPromptUtils.GetTitleCreationTimeSec(
      useTeams({
        updateStaffTeam: true,
        excludeStaffTeam: true,
      }).length
    )
  );
  useCountdownPlaySFX(titleCreationTimeSec, remainingTime, true);
  useOneTimePlayGoAnimation(uiControl, () => remainingTime > 0);

  return (
    <Layout>
      <Leaderboard
        header={<Header text='Title Progress' />}
        rightColumn={{
          header: (
            <div className='flex flex-row w-40 justify-between'>
              <div className='w-full text-right'>Titles Complete</div>
            </div>
          ),
          body: (teamId) => {
            const info = summary[teamId];
            const mumOfTitles = info?.mumOfTitles ?? 0;
            // teams dont't need to title their own drawings
            const numOfTitleDrawings = Math.max(0, total - 1);
            const percentage =
              numOfTitleDrawings > 0
                ? Math.floor((mumOfTitles / numOfTitleDrawings) * 100)
                : 0;
            return (
              <div className='flex flex-row w-40 justify-between'>
                <div className={`w-full text-right font-bold`}>
                  {mumOfTitles}/{numOfTitleDrawings} - {percentage}%
                </div>
              </div>
            );
          },
        }}
      />
    </Layout>
  );
}

function MatchPromptPhaseIntro() {
  return (
    <Layout>
      <div className='w-full h-full bg-black bg-opacity-80 rounded-xl relative'>
        <WaitForMatch secondaryText='Get ready to match the drawing to the correct title! ' />
      </div>
    </Layout>
  );
}

function MatchPromptPhase(props: {
  block: DrawingPromptBlock;
  uiControl: GamePlayUIStateControl;
}): JSX.Element | null {
  const { block, uiControl } = props;
  return (
    <Layout>
      <div className='w-full h-full bg-black bg-opacity-80 rounded-xl relative py-4'>
        <DrawingPromptMatchPrompt block={block} uiControl={uiControl} />
      </div>
    </Layout>
  );
}

function ReviewAllDrawingsPhase(props: { block: DrawingPromptBlock }) {
  return (
    <Layout>
      <div className='w-full h-full bg-black bg-opacity-80 rounded-xl relative py-4'>
        <ReviewAllDrawings block={props.block} />
      </div>
    </Layout>
  );
}

export function DrawingPromptBlockHostView(props: {
  block: DrawingPromptBlock;
  uiControl: GamePlayUIStateControl;
}): JSX.Element | null {
  const { block, uiControl } = props;
  const gss = useGameSessionStatus<DrawingPromptBlockGameSessionStatus>();

  switch (gss) {
    case DrawingPromptBlockGameSessionStatus.LOADED:
      return null;
    case DrawingPromptBlockGameSessionStatus.INIT:
      return <DrawingPhase block={block} uiControl={uiControl} />;
    case DrawingPromptBlockGameSessionStatus.DRAWING_START:
      return <DrawingPhase block={block} uiControl={uiControl} drawable />;
    case DrawingPromptBlockGameSessionStatus.DRAWING_END:
      return <DrawingPhase block={block} uiControl={uiControl} ended />;
    case DrawingPromptBlockGameSessionStatus.TEAM_VOTE_COUNTING:
      return <VotingPhase block={block} uiControl={uiControl} />;
    case DrawingPromptBlockGameSessionStatus.TEAM_VOTE_DONE:
      return <VotingPhase block={block} uiControl={uiControl} ended />;
    case DrawingPromptBlockGameSessionStatus.TITLE_CREATION_COUNTING:
      return <TitleCreationPhase block={block} uiControl={uiControl} />;
    case DrawingPromptBlockGameSessionStatus.TITLE_CREATION_DONE:
      return <TitleCreationPhase block={block} uiControl={uiControl} ended />;
    case DrawingPromptBlockGameSessionStatus.MATCH_PROMPT_INIT:
      return <MatchPromptPhaseIntro />;
    case DrawingPromptBlockGameSessionStatus.MATCH_PROMPT_COUNTING:
      return <MatchPromptPhase block={block} uiControl={uiControl} />;
    case DrawingPromptBlockGameSessionStatus.MATCH_PROMPT_DONE:
      return <MatchPromptPhase block={block} uiControl={uiControl} />;
    case DrawingPromptBlockGameSessionStatus.REVIEW_ALL_DRAWINGS:
      return <ReviewAllDrawingsPhase block={block} />;
    case DrawingPromptBlockGameSessionStatus.RESULTS:
    case DrawingPromptBlockGameSessionStatus.SCOREBOARD:
    case DrawingPromptBlockGameSessionStatus.END:
    case null:
    case undefined:
      break;
    default:
      assertExhaustive(gss);
      break;
  }

  return null;
}
