import { createContext, type ReactNode, useContext } from 'react';
import { proxy } from 'valtio';

import { type VideoEffectsSettingsUser } from '@lp-lib/game';

import { StorageFactory } from '../../utils/storage';
import {
  markSnapshottable,
  useSnapshot,
  type ValtioSnapshottable,
  ValtioUtils,
} from '../../utils/valtio';
import {
  type VideoEffectsSettings,
  type VideoEffectsSettingsStorage,
} from './types';
import { VideoEffectsSettingsUtils } from './VideoEffectsSettingsUtils';

const context = createContext<VideoEffectsSettingsStorage | null>(null);

export function VideoEffectsSettingsStorageProvider(props: {
  store: VideoEffectsSettingsStorage;
  children?: ReactNode;
}): JSX.Element {
  const store = props.store;
  return <context.Provider value={store}>{props.children}</context.Provider>;
}

export class VideoEffectsSettingsDummyStore
  implements VideoEffectsSettingsStorage
{
  getSettings(): Readonly<VideoEffectsSettings> {
    return VideoEffectsSettingsUtils.WithDefaults();
  }

  async replaceFromExternal() {
    return;
  }

  toggleEffect() {
    return;
  }

  async load() {
    return;
  }

  async persist() {
    return;
  }
}

export class VideoEffectsSettingsLocalStore
  implements VideoEffectsSettingsStorage
{
  private storage;
  private s;

  constructor(persist: boolean, defaultValues?: Partial<VideoEffectsSettings>) {
    this.storage = StorageFactory<
      'userVideoEffectsSettings',
      VideoEffectsSettingsUser
    >(persist ? 'local' : 'dummy');
    this.s = proxy({
      ves: VideoEffectsSettingsUtils.WithDefaults(defaultValues),
    });
  }

  getSettings(): Readonly<VideoEffectsSettings> {
    return this.s.ves;
  }

  listenable = (): Readonly<ValtioSnapshottable<VideoEffectsSettings>> => {
    return markSnapshottable(this.s.ves);
  };

  async replaceFromExternal(_key: string, ves: VideoEffectsSettings) {
    ValtioUtils.update(this.s.ves, ves);
    this.persist();
  }

  toggleEffect(
    _key: string,
    effect: 'greenScreen' | 'podium' | 'intro' | 'outro',
    enabled: boolean
  ) {
    const setting = this.s.ves[effect];
    if (!setting) return;
    setting.enabled = enabled;
    this.persist();
  }

  async load() {
    const settings = this.storage.get('userVideoEffectsSettings');
    if (!settings) return;
    const full = VideoEffectsSettingsUtils.FromUser(settings);
    if (!full) return;
    ValtioUtils.update(this.s.ves, full);
  }

  async persist() {
    const full = this.s.ves;
    const settings = VideoEffectsSettingsUtils.ToUser(full);
    this.storage.set('userVideoEffectsSettings', settings);
  }
}

export function useVideoEffectsSettingsStore(): VideoEffectsSettingsStorage {
  const ctx = useContext(context);
  if (!ctx) throw new Error('VES Store not in tree!');
  return ctx;
}

export function useLocalVideoEffectsSettings(): Readonly<VideoEffectsSettings> {
  const ctx = useVideoEffectsSettingsStore();
  if (!(ctx instanceof VideoEffectsSettingsLocalStore)) {
    throw new Error('This is not a local store');
  }
  return useSnapshot(ctx.listenable());
}
