export type LatencyResult = {
  sendMs: number;
  recvMs: number;
  rttMs: number;
  driftMs: number;
};

type LatencyResultKeys = keyof LatencyResult;

export type LatencyColors = 'green' | 'yellow' | 'red';

export function latencyResultNew(
  sendMs = 0,
  recvMs = 0,
  rttMs = 0,
  driftMs = 0
): LatencyResult {
  const d = {
    sendMs,
    recvMs,
    rttMs,
    driftMs,
  } as const;

  return d;
}

export function latencyResultZero(d: LatencyResult): LatencyResult {
  d.sendMs = 0;
  d.recvMs = 0;
  d.rttMs = 0;
  d.driftMs = 0;
  return d;
}

function latencyResultRound(d: LatencyResult) {
  d.sendMs = Math.round(d.sendMs);
  d.recvMs = Math.round(d.recvMs);
  d.rttMs = Math.round(d.rttMs);
  d.driftMs = Math.round(d.driftMs);
  return d;
}

export function latencyResultColor<
  D extends LatencyResult,
  K extends LatencyResultKeys
>(d: D, key: K, goodMs: number, poorMs: number): LatencyColors {
  const value = d[key];

  if (value < goodMs) return 'green';
  else if (value < poorMs) return 'yellow';
  else if (value >= poorMs) return 'red';
  else return 'red';
}

// standard smoothing function: https://en.wikipedia.org/wiki/Exponential_smoothing
function smoothValue(initial: number, incoming: number, factor: number) {
  return factor * incoming + (1 - factor) * initial;
}

export function latencyResultCompute(
  results: LatencyResult[],
  smoothing: number
): {
  avg: LatencyResult;
  smoothed: LatencyResult;
  latest: LatencyResult;
} {
  const avg = latencyResultNew();
  const smoothed = latencyResultNew();

  for (let i = 0; i < results.length; i++) {
    const result = results[i];

    avg.recvMs += result.recvMs;
    avg.sendMs += result.sendMs;
    avg.rttMs += result.rttMs;
    avg.driftMs += result.driftMs;

    smoothed.recvMs = smoothValue(smoothed.recvMs, result.recvMs, smoothing);
    smoothed.sendMs = smoothValue(smoothed.sendMs, result.sendMs, smoothing);
    smoothed.rttMs = smoothValue(smoothed.rttMs, result.rttMs, smoothing);
    smoothed.driftMs = smoothValue(smoothed.driftMs, result.driftMs, smoothing);
  }

  avg.rttMs = avg.rttMs / results.length;
  avg.driftMs = avg.driftMs / results.length;
  avg.recvMs = avg.recvMs / results.length;
  avg.sendMs = avg.sendMs / results.length;

  const latest = results[results.length - 1] || latencyResultNew();

  return {
    avg: latencyResultRound(avg),
    smoothed: latencyResultRound(smoothed),
    latest: latencyResultRound(latest),
  };
}
