import { useEffect, useMemo, useRef } from 'react';
import { proxy } from 'valtio';

import { useInstance } from '../../hooks/useInstance';
import { useMyInstance } from '../../hooks/useMyInstance';
import { type HexColor, type Point } from '../../types/drawing';
import { assertExhaustive } from '../../utils/common';
import {
  markSnapshottable,
  useSnapshot,
  ValtioUtils,
} from '../../utils/valtio';
import {
  type FirebaseService,
  FirebaseValueHandle,
  useFirebaseContext,
} from '../Firebase';
import { FloatLayout } from '../Layout';
import { useLastJoinedParticipantByUserId } from '../Player';
import { useVenueId } from '../Venue/VenueProvider';
import { type Brush, DrawingCanvas } from './drawing';

const COLORS = [
  '#FF3975',
  '#3988FF',
  '#FBB707',
  '#15DF96',
  '#8C6FFF',
  '#E96B24',
  '#0029FF',
  '#8F00FF',
  '#CA2121',
  '#00D0C4',
  '#F0F33D',
  '#BF568F',
  '#79A669',
  '#706EBE',
  '#BE8260',
  '#537594',
  '#8D103D',
  '#0B2B99',
  '#978800',
  '#6D3C75',
] as const;

type Player = {
  id: string;
  color: HexColor;
  cursor?: Point;
};

type RemotePoint =
  | ({
      t: 'start';
    } & Point)
  | ({
      t: 'point';
    } & Point)
  | { t: 'end' };

type State = {
  players: Nullable<Record<string, Player>>;
};

class Utils {
  static PlayersHandle(
    svc: FirebaseService,
    venueId: string
  ): FirebaseValueHandle<Record<string, Player>> {
    return new FirebaseValueHandle(
      svc.prefixedSafeRef(`collab-drawing-exp/${venueId}/players`)
    );
  }
}

class FirebaseDrawingManager {
  constructor(
    private playerId: string,
    venueId: string,
    svc: FirebaseService,
    private cvs: DrawingCanvas,
    syncOnDisconnect?: boolean,
    private pointsRef = svc.prefixedSafeRef<
      Nullable<Record<string, RemotePoint>>
    >(`collab-drawing-exp/${venueId}/points/${playerId}`),
    private cursorRef = svc.prefixedSafeRef<Nullable<Point>>(
      `collab-drawing-exp/${venueId}/players/${playerId}/cursor`
    )
  ) {
    if (syncOnDisconnect) {
      this.cursorRef.onDisconnect().remove();
    }
  }

  on() {
    this.pointsRef.on('child_added', (snap) => {
      const point = snap.val();
      if (!point) return;
      this.draw(point);
    });
  }

  off() {
    this.pointsRef.off('child_added');
  }

  async sync() {
    const points = (await this.pointsRef.get()).val() ?? {};
    for (const point of Object.values(points)) {
      this.draw(point);
    }
  }

  private draw(point: RemotePoint) {
    const type = point.t;
    switch (type) {
      case 'start':
        this.cvs.startPath(this.playerId, point);
        break;
      case 'point':
        this.cvs.movePath(this.playerId, point);
        break;
      case 'end':
        this.cvs.endPath(this.playerId);
        break;
      default:
        assertExhaustive(type);
        break;
    }
  }

  async addPoint(p: RemotePoint) {
    const child = this.pointsRef.push();
    await child.set(p as never);
  }

  async updateCursor(p: Point | null) {
    if (p) {
      await this.cursorRef.set(p);
    } else {
      await this.cursorRef.remove();
    }
  }
}

class CollaborativeDrawingAPI {
  private _state;
  private local?: {
    player: Player;
    manager: FirebaseDrawingManager;
    brush: Brush;
  };
  private remote: Map<string, FirebaseDrawingManager> = new Map<
    string,
    FirebaseDrawingManager
  >();
  constructor(
    private cvs: DrawingCanvas,
    private venueId: string,
    private svc: FirebaseService,
    private playersHandle = Utils.PlayersHandle(svc, venueId)
  ) {
    this._state = markSnapshottable(proxy<State>(this.initialState()));
  }

  async init(playerId: string) {
    const players = (await this.playersHandle.get()) ?? {};
    const manager = new FirebaseDrawingManager(
      playerId,
      this.venueId,
      this.svc,
      this.cvs,
      true
    );
    const player = players[playerId];
    if (player) {
      this.local = {
        player,
        manager,
        brush: this.cvs.addBrush(playerId, player.color),
      };
    } else {
      const color = COLORS[Object.keys(players).length % COLORS.length];
      this.local = {
        player: {
          id: playerId,
          color,
        },
        manager,
        brush: this.cvs.addBrush(playerId, color),
      };
      await this.playersHandle.ref.child(playerId).set(this.local.player);
    }
    await manager.sync();
    this.cvs.addEventListeners(this.local.brush.id);
    this.cvs.drawable = true;
  }

  async subPlayer(playerId: string, brushColor: HexColor) {
    if (this.remote.has(playerId)) return;
    this.cvs.addBrush(playerId, brushColor);
    const manager = new FirebaseDrawingManager(
      playerId,
      this.venueId,
      this.svc,
      this.cvs
    );
    this.remote.set(playerId, manager);
    await manager.sync();
    manager.on();
  }

  async unsubPlayer(playerId: string) {
    const manager = this.remote.get(playerId);
    if (!manager) return;
    manager.off();
    this.cvs.removeBrush(playerId);
    this.remote.delete(playerId);
  }

  get state() {
    return this._state;
  }

  on(cursor?: boolean): void {
    this.cvs.on('start', async (p) => {
      if (!this.local) return;
      await this.local.manager.addPoint({
        t: 'start',
        ...p,
      });
    });
    this.cvs.on('drawing', async (p) => {
      if (!this.local) return;
      await this.local.manager.addPoint({
        t: 'point',
        ...p,
      });
    });
    this.cvs.on('end', async () => {
      if (!this.local) return;
      await this.local.manager.addPoint({
        t: 'end',
      });
    });
    if (cursor) {
      this.cvs.on('move', async (p) => {
        if (!this.local) return;
        await this.local.manager.updateCursor(p);
      });
      this.cvs.on('leave', async () => {
        if (!this.local) return;
        await this.local.manager.updateCursor(null);
      });
    }
    this.playersHandle.on((val) => {
      ValtioUtils.set(this._state, 'players', val);
    });
  }
  off(): void {
    this.playersHandle.off();
  }

  initialState(): State {
    return {
      players: null,
    };
  }
}

function usePlayers(api: CollaborativeDrawingAPI): Player[] {
  const players = useSnapshot(api.state).players;
  return useMemo(() => Object.values(players ?? {}), [players]);
}

function PlayerItem(props: {
  api: CollaborativeDrawingAPI;
  player: Player;
}): JSX.Element | null {
  const { api, player } = props;
  const p = useLastJoinedParticipantByUserId(player.id);
  const me = useMyInstance();

  useEffect(() => {
    if (player.id === me?.id) return;
    api.subPlayer(player.id, player.color);
    return () => {
      api.unsubPlayer(player.id);
    };
  }, [api, me?.id, player.color, player.id]);

  return (
    <div
      className='w-full text-sms font-bold px-2 py-2 flex items-center border-b border-white-001'
      style={{
        color: player.color,
      }}
    >
      {p?.firstName ?? p?.username ?? 'unknown'}{' '}
      {p?.status === 'connected' ? '🟢' : '🔴'}
    </div>
  );
}

function PlayerList(props: { api: CollaborativeDrawingAPI; height: number }) {
  const { api, height } = props;
  const players = usePlayers(api);

  return (
    <div
      className='w-50 flex-shrink-0 overflow-y-auto scrollbar bg-black bg-opacity-80 rounded-l-none rounded-xl'
      style={{
        height,
      }}
    >
      {players.map((p) => (
        <PlayerItem key={p.id} api={api} player={p} />
      ))}
    </div>
  );
}

// I think using DOM and css position to represent the cursor is a bad approach.
// This is primarily used demonstrate the cursor feature. We probably should
// use a canvas layer for this.
function Cursor(props: {
  api: CollaborativeDrawingAPI;
  player: Player;
}): JSX.Element | null {
  const { player } = props;
  const p = useLastJoinedParticipantByUserId(player.id);
  const me = useMyInstance();
  const name =
    me?.id === player.id ? 'You' : p?.firstName ?? p?.username ?? 'unknown';

  if (!player.cursor) return null;
  return (
    <div
      className='w-2 h-2 rounded-full absolute text-3xs'
      style={{
        border: '1px solid',
        borderColor: player.color,
        color: player.color,
        left: player.cursor.x,
        top: player.cursor.y,
      }}
    >
      <div className='absolute left-2 -bottom-3'>{name}</div>
    </div>
  );
}

function CursorLayer(props: { api: CollaborativeDrawingAPI }) {
  const { api } = props;
  const players = usePlayers(api);
  return (
    <div className='w-full h-full pointer-events-none absolute inset-0'>
      {players.map((p) => (
        <Cursor key={p.id} api={api} player={p} />
      ))}
    </div>
  );
}

export function CollaborativeDrawing(props: {
  enabled: boolean;
  cursor: boolean;
}): JSX.Element | null {
  const { enabled, cursor } = props;
  const venueId = useVenueId();
  const { svc } = useFirebaseContext();
  const ref = useRef<HTMLDivElement>(null);
  const { width, height } = useInstance(() => {
    const width = 720;
    const height = (width / 16) * 9;
    return { width, height };
  });
  const cvs = useInstance(() => {
    return new DrawingCanvas(width, height, {
      className: 'rounded-lg w-full h-full',
    });
  });
  const api = useInstance(() => new CollaborativeDrawingAPI(cvs, venueId, svc));
  const me = useMyInstance();

  useEffect(() => {
    if (!me?.id || !enabled) return;
    api.on(cursor);
    api.init(me.id);
    return () => {
      api.off();
    };
  }, [api, cursor, enabled, me?.id]);

  useEffect(() => {
    if (!ref.current || !enabled) return;
    cvs.attach(ref.current);
    return () => cvs.detach();
  }, [cvs, enabled]);

  if (!enabled) return null;

  return (
    <FloatLayout className='flex items-center justify-center z-40'>
      <div
        className='relative flex-shrink-0 cursor-none'
        style={{
          width,
          height,
          cursor: cursor ? 'none' : undefined,
        }}
      >
        <div
          className='w-full h-full absolute inset-0'
          ref={ref}
          style={{
            boxShadow: '6px 8px 11px rgba(0, 0, 0, 0.66)',
          }}
        />
        {cursor && <CursorLayer api={api} />}
      </div>
      <PlayerList api={api} height={height} />
    </FloatLayout>
  );
}
