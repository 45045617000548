import React, {
  type ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useLatest, useMountedState } from 'react-use';

import config from '../../config';
import { useUnload } from '../../hooks/useUnload';
import logger from '../../logger/logger';
import { type ClientType } from '../../types/user';
import { Screen } from '../common/Screen';
import {
  useFirebaseContext,
  useIsFirebaseConnected,
} from '../Firebase/Context';
import { useUser } from '../UserContext';

const log = logger.scoped('multiple-user-instances');

interface MultipleUserInstancesContext {
  clientId: string | null;
  multipleInstances: boolean;
  isHostClientIdChanged: boolean;
  init: (clientId: string, clientType: ClientType) => void;
  override: (clientId: string) => void;
  clear: (clientId: string) => void;
}

const Context = React.createContext<MultipleUserInstancesContext | null>(null);

export function useMultipleUserInstancesContext(): MultipleUserInstancesContext {
  const ctx = useContext(Context);
  if (!ctx) throw new Error('MultipleUserInstancesContext is not in the tree!');
  return ctx;
}

export function useInitMultipleUserInstancesWatcher(
  clientId: string,
  clientType: ClientType
): void {
  const { init, clear, isHostClientIdChanged } =
    useMultipleUserInstancesContext();

  const firebaseConnected = useIsFirebaseConnected();

  useEffect(() => {
    if (!firebaseConnected) return;
    init(clientId, clientType);
    return () => {
      clear(clientId);
    };
  }, [firebaseConnected, clientId, init, clear, clientType]);

  useEffect(() => {
    if (!isHostClientIdChanged) return;
    window.location.replace(config.app.homeUrlOrPathname);
  }, [isHostClientIdChanged]);

  useUnload(() => {
    clear(clientId);
  });
}

function useMultipleUserInstancesFBRef<T>(
  venueId: string,
  uid: string,
  clientType: ClientType | null
) {
  const ctx = useFirebaseContext();
  const ref = useMemo(() => {
    if (!uid || !clientType || !venueId) return null;
    return ctx.svc.prefixedRef<T>(
      `venues/${venueId}/multiUserInstances/${clientType}-${uid}`
    );
  }, [ctx.svc, uid, venueId, clientType]);
  return ref;
}

export const MultipleUserInstancesProvider = (props: {
  venueId: string;
  children?: ReactNode;
}): JSX.Element => {
  const user = useUser();
  const firebaseConnected = useIsFirebaseConnected();
  const [clientId, setClientId] = useState<string | null>(null);
  const [clientType, setClientType] = useState<ClientType | null>(null);
  const [multipleInstances, setMultipleInstances] = useState<boolean>(false);
  const [isHostClientIdChanged, setIsHostClientIdChanged] =
    useState<boolean>(false);
  const latestClientId = useLatest(clientId);
  const ref = useMultipleUserInstancesFBRef<string | null>(
    props.venueId,
    user.id,
    clientType
  );
  const mounted = useMountedState();

  useEffect(() => {
    if (!firebaseConnected || !ref) return;
    ref.on('value', async (snap) => {
      const syncedClientId = snap.val();
      if (syncedClientId) {
        await ref.onDisconnect().cancel();
        if (!mounted()) return;
        setClientId((prev) => {
          setIsHostClientIdChanged(!!prev && prev !== syncedClientId);
          return syncedClientId;
        });
      }
    });
    return () => {
      ref.off();
    };
  }, [ref, firebaseConnected, mounted]);

  const init = useCallback(
    async (clientId: string, clientType: ClientType) => {
      setClientType(clientType);
      if (!ref) return;
      const snap = await ref.get();
      const syncedClientId = snap.val();
      let removeOnDisconnect = true;

      if (!syncedClientId) {
        await ref.set(clientId);
        if (!mounted()) return;
        setClientId(clientId);
      } else if (syncedClientId !== clientId) {
        setMultipleInstances(true);
        removeOnDisconnect = false;
      }
      if (removeOnDisconnect) {
        await ref.onDisconnect().remove();
      }
    },
    [mounted, ref]
  );

  const override = useCallback(
    async (clientId: string) => {
      if (!ref) return;
      setMultipleInstances(false);
      setClientId(clientId);
      await ref.set(clientId);
      await ref.onDisconnect().remove();
      if (!mounted()) return;
    },
    [mounted, ref]
  );

  const clear = useCallback(
    (clientId: string) => {
      if (!latestClientId.current) return;
      if (clientId !== latestClientId.current) {
        // the clientId will be overwritten if another instance takes over
        log.info('clientId changed, skip cleanup.', {
          oldClientId: clientId,
          newClientId: latestClientId.current,
        });
        return;
      }
      ref && ref.remove();
      setMultipleInstances(false);
      setClientId(null);
    },
    [ref, latestClientId]
  );

  const ctxValue: MultipleUserInstancesContext = useMemo(
    () => ({
      clientId,
      multipleInstances,
      isHostClientIdChanged,
      init,
      override,
      clear,
    }),
    [clear, clientId, init, isHostClientIdChanged, multipleInstances, override]
  );

  return <Context.Provider value={ctxValue}>{props.children}</Context.Provider>;
};

export function MultipleUserInstancesWarning(props: {
  clientId: string;
  venueName?: string;
}): JSX.Element | null {
  const { override } = useMultipleUserInstancesContext();
  const endPrevSession = () => {
    override(props.clientId);
  };

  return (
    <Screen
      title={`Welcome to ${props.venueName}`}
      content='Oops! It looks like you already have Luna Park open in another window. Click below to end the previous session and resume access here.'
    >
      <button
        type='button'
        onClick={endPrevSession}
        className='btn-primary w-85 h-12.5 text-base mt-6'
      >
        End Previous Session
      </button>
    </Screen>
  );
}
