import React, { type ReactNode, useContext, useMemo } from 'react';

import { useBlockRecorderVersion } from '../../../../GameRecorder/BlockRecorderProvider';
import {
  useGameSessionBlock,
  useIsRecording,
  useOndPlaybackVersion,
} from '../../../hooks';
import { OndVersionChecks } from '../../../OndVersionChecks';

type Context = {
  /**
   * True when gameplay blocks should show the points multiplier animation.
   */
  showPointsMultiplierAnimation: boolean;

  /**
   * True when gameplay can show the submission status widget, if it desires.
   * Note(jialin): This also controls the leaderboard.
   */
  showSubmissionStatusWidget: boolean;

  /**
   * False when audio-only playback is enabled, and overrides any block-specific
   * settings. True does not indicate the minihost will always be shown, only
   * that a block _may_ show it during Ond playback.
   */
  allowMinihost: boolean;
};

const context = React.createContext<Nullable<Context, false>>(null);

export function useGamePlayUIConfiguration(): Context {
  const ctx = useContext(context);
  if (!ctx)
    throw new Error(
      'OnDPlaybackGamePlayConfigurationProvider is not in the tree!'
    );
  return ctx;
}

/** The BlockRecorder provider is only available on Host clients, since they are
 * used for recording. Ideally this info would just be passed into the the
 * GamePlayUIConfigurationProvider, but that forces initialization to be in the
 * root client components. */
export function GamePlayRecordingCapableUIConfigurationProvider(props: {
  children?: ReactNode;
}): JSX.Element | null {
  const isRecording = useIsRecording();
  const recorderVersion = useBlockRecorderVersion();
  const hasBlockTitle = Boolean(useGameSessionBlock()?.fields.title);
  const maybePlaybackVersion = useOndPlaybackVersion();

  const value: Context = useMemo(() => {
    const playbackVersion = isRecording
      ? recorderVersion
      : maybePlaybackVersion;
    return getContextValue(playbackVersion, hasBlockTitle);
  }, [isRecording, recorderVersion, maybePlaybackVersion, hasBlockTitle]);

  return <context.Provider value={value}>{props.children}</context.Provider>;
}

export function GamePlayUIConfigurationProvider(props: {
  children?: ReactNode;
}): JSX.Element | null {
  const maybePlaybackVersion = useOndPlaybackVersion();
  const hasBlockTitle = Boolean(useGameSessionBlock()?.fields.title);

  const value = useMemo(
    () => getContextValue(maybePlaybackVersion, hasBlockTitle),
    [hasBlockTitle, maybePlaybackVersion]
  );

  return <context.Provider value={value}>{props.children}</context.Provider>;
}

function getContextValue(
  playbackVersion: number | null | undefined,
  hasBlockTitle: boolean
): Context {
  const checks = OndVersionChecks(playbackVersion);
  return {
    showPointsMultiplierAnimation:
      !hasBlockTitle && checks.ondAllowPointsMultiplierAnimation,
    showSubmissionStatusWidget: checks.ondAllowSubmissionStatusWidget,
    allowMinihost: playbackVersion ? checks.ondAllowMinihost : true,
  };
}
