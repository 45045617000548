import { getAudioContext } from '../services/audio/audio-context';
import { BrowserIntervalCtrl } from './BrowserIntervalCtrl';
import { Emitter } from './emitter';

export class MicVolumeMeter {
  private audioContext: AudioContext;
  private analyser: AnalyserNode;
  private source: MediaStreamAudioSourceNode;
  private out_data: Uint8Array;
  constructor(source: MediaStream) {
    this.audioContext = getAudioContext();
    this.analyser = this.audioContext.createAnalyser();
    this.source = this.audioContext.createMediaStreamSource(source);
    this.analyser.smoothingTimeConstant = 0.3;
    this.analyser.fftSize = 1024;
    // this.analyser.minDecibels = -90
    // this.analyser.maxDecibels = -10
    this.source.connect(this.analyser);
    // TypedArrays are expensive to allocate, only do it once.
    this.out_data = new Uint8Array(this.analyser.frequencyBinCount);
  }

  getByteFrequencyData(): Readonly<Uint8Array> {
    this.analyser.getByteFrequencyData(this.out_data);
    return this.out_data as Readonly<Uint8Array>;
  }

  getByteTimeDomainData(): Readonly<Uint8Array> {
    this.analyser.getByteTimeDomainData(this.out_data);
    return this.out_data as Readonly<Uint8Array>;
  }

  close(): void {
    this.source.disconnect(this.analyser);
  }
}

export const getAverageVolume = (array: Uint8Array): number => {
  let values = 0;
  const length = array.length;
  for (let i = 0; i < length; i++) {
    values += array[i];
  }
  return values / length;
};

export class MicVolumeMeterFromTrack extends MicVolumeMeter {
  constructor(track: MediaStreamTrack) {
    const stream = new MediaStream();
    stream.addTrack(track);
    super(stream);
  }
}

type Events = {
  'volume-changed': (vol: number) => void;
};

export class MicVolumeMeterProcessor {
  private ctrl = new BrowserIntervalCtrl();
  private emitter = new Emitter<Events>();
  on = this.emitter.on.bind(this.emitter);
  off = this.emitter.off.bind(this.emitter);

  constructor(private meter: MicVolumeMeter) {}

  start(fps = 10) {
    this.stop();
    this.ctrl.set(() => {
      const array = this.meter.getByteFrequencyData();
      const volume = getAverageVolume(array);
      const vol = Math.min(Math.round(volume), 100);
      this.emitter.emit('volume-changed', vol);
    }, 1000 / fps);
    return this.stop.bind(this);
  }

  stop() {
    this.ctrl.clear();
  }

  close() {
    this.stop();
    this.meter.close();
  }
}
