import useSWR from 'swr';

import { type DtoTTSGenerator } from '@lp-lib/api-service-client/public';

import { apiService } from '../../services/api-service';

type UseTTSGenerators = {
  generators: DtoTTSGenerator[] | undefined;
  isLoading: boolean;
  isValidating: boolean;
  error: Error | undefined;
};

async function loadTTSGenerators(): Promise<DtoTTSGenerator[]> {
  const resp = await apiService.tts.getGenerators();
  return resp.data.generators;
}

export function useTTSGenerators(): UseTTSGenerators {
  const { data, error, isValidating, isLoading } = useSWR(
    '/tts/generators',
    loadTTSGenerators,
    {
      shouldRetryOnError: false,
    }
  );

  return {
    generators: data,
    isLoading,
    isValidating,
    error,
  };
}
