import { useEffect, useState } from 'react';
import { useMountedState } from 'react-use';

import { Loading } from '../../../components/Loading';

export function RetryJoinTeam(props: {
  onRetry: () => Promise<void>;
}): JSX.Element {
  const { onRetry } = props;
  const [showRetryJoinButton, setShowRetryJoinButton] = useState(false);
  const mounted = useMountedState();

  useEffect(() => {
    const timer = setTimeout(() => {
      if (!mounted()) return;
      setShowRetryJoinButton(true);
    }, 1500);
    return () => {
      clearTimeout(timer);
      setShowRetryJoinButton(false);
    };
  }, [mounted]);

  return (
    <div className='h-30 flex items-center justify-center pointer-events-auto'>
      {showRetryJoinButton ? (
        <button
          type='button'
          className='btn btn-delete w-80 h-10 text-xs'
          onClick={onRetry}
        >
          Disconnected from team, Click here to try again
        </button>
      ) : (
        <div className='bg-black bg-opacity-40 px-4 py-2 rounded-lg text-xs text-white'>
          <Loading text='connecting' imgClassName='w-5 h-5' />
        </div>
      )}
    </div>
  );
}
