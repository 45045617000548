import { type AnalyticsBrowser } from '@segment/analytics-next';

type Reason = 'Agora UID_BANNED' | 'WebRTC Join Failed';

export class RefreshModalAnalytics {
  constructor(private readonly analytics: AnalyticsBrowser) {}

  trackSuggestedRefreshModalDisplayed(props: { reason: Reason }) {
    this.analytics.track('Suggested Refresh Modal Displayed', props);
  }

  trackSuggestedRefreshModalClicked(props: { reason: Reason }) {
    this.analytics.track('Suggested Refresh Modal Clicked', props);
  }
}
