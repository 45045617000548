import pluralize from 'pluralize';
import { useEffect, useMemo, useState } from 'react';

import {
  type MemoryMatchBlock,
  MemoryMatchBlockGameSessionStatus,
} from '@lp-lib/game';

import { getFeatureQueryParamNumber } from '../../../../hooks/useFeatureQueryParam';
import { type TeamId } from '../../../../types';
import { assertExhaustive } from '../../../../utils/common';
import { DoubleRightArrow } from '../../../icons/Arrows';
import { PlayIcon } from '../../../icons/PlayIcon';
import { RefreshIcon } from '../../../icons/RefreshIcon';
import { FilledSquareIcon } from '../../../icons/SquareIcon';
import { TimerIcon } from '../../../icons/TimerIcon';
import {
  useDetailScores,
  useGameSessionLocalTimer,
  useGameSessionStatus,
  useIsEndedBlock,
} from '../../hooks';
import {
  countdownV2,
  type EditPointData,
  next,
  present,
  triggerBlockTitleAnim,
} from '../../store';
import {
  BlockControllerActionButton,
  BlockControllerActionTimeRemaining,
  ControllerLayout,
  type ControllerProps,
  DecreasingPointsBadge,
  type MediaPlayback,
  MediaPreviewer,
  ResultView,
  SubmissionsActionBox,
  useBlockControllerBlockTitlePlaying,
  useControllerMediaPlayback,
} from '../Common/Controller/Internal';
import { useRankedTeamScores, useStableBlock } from '../Common/hooks';
import { useGameProgressSummary } from './MemoryMatchProvider';
import { MemoryMatchUtils } from './utils';

type SharedProps = ControllerProps<MemoryMatchBlock>;
type StageProps = SharedProps & {
  playback: MediaPlayback;
};

export function MemoryMatchBlockTeamProgressSummary(props: {
  block: MemoryMatchBlock;
  teamId: TeamId;
}) {
  const summary = useGameProgressSummary();
  const numOfCorrectMatches = useMemo(() => {
    return summary[props.teamId]?.numOfCorrectMatches ?? 0;
  }, [summary, props.teamId]);

  return (
    <>
      Progress: {numOfCorrectMatches}/{props.block.fields.numberOfCardPairs}{' '}
      pairs found
    </>
  );
}

function GameProgress(
  props: StageProps & { revealResults?: boolean; hideProgressDetail?: boolean }
): JSX.Element | null {
  const { selectedBlock: block, setEditPointsData, hideProgressDetail } = props;
  const teamDetailScoreMap = useDetailScores(props.selectedBlock.id);
  const teamScores = useRankedTeamScores('teamName', teamDetailScoreMap);
  const summary = useGameProgressSummary();
  const numOfCardPairs = useMemo(
    () =>
      MemoryMatchUtils.PrepareAssets(
        block.fields.cardPairs,
        getFeatureQueryParamNumber('memory-match-num-of-pairs') ||
          block.fields.numberOfCardPairs
      ).length,
    [block.fields.cardPairs, block.fields.numberOfCardPairs]
  );
  return (
    <div className='relative w-full h-full flex flex-col justify-start items-center'>
      <div className='w-full h-10 px-4 py-1 leading-4 bg-black text-white text-2xs font-medium flex items-center'>
        {numOfCardPairs} Matches - Max{' '}
        {block.fields.pointsPerMatch * numOfCardPairs} Points
      </div>
      <div className='w-full mb-2.5 pt-1 px-2.5 max-h-64 scrollbar-hide overflow-y-scroll'>
        {teamScores.map((score) => {
          const editPointData: EditPointData = {
            teamName: score.team.name,
            teamId: score.team.id,
            submitterUid: '',
            defaultPoints: score.currentScore,
          };
          const numOfCorrectMatches =
            summary[score.team.id]?.numOfCorrectMatches ?? 0;
          return (
            <SubmissionsActionBox
              key={score.team.id}
              teamId={score.team.id}
              view={props.revealResults ? 'result' : undefined}
              editPointData={editPointData}
              setEditPointsData={setEditPointsData}
            >
              <div className='w-4/5 h-full pl-2'>
                <p
                  className={`font-bold ${
                    hideProgressDetail
                      ? 'h-full flex items-center'
                      : 'h-1/3 pt-1'
                  }`}
                >
                  {score.team.name}
                </p>
                {!hideProgressDetail && (
                  <div
                    className={`w-full h-2/3 text-xs font-medium ${
                      numOfCorrectMatches > 0
                        ? 'text-icon-gray'
                        : 'text-[rgba(255,255,255,0.15)]'
                    } flex flex-col justify-center`}
                  >
                    <p className='w-full line-clamp-2 hyphens-auto'>
                      [{numOfCorrectMatches}{' '}
                      {pluralize('match', numOfCorrectMatches)} found]
                    </p>
                  </div>
                )}
              </div>
              <div className='w-1/5 h-full bg-secondary border-l border-secondary flex flex-col justify-center items-center'>
                {props.revealResults ? (
                  <ResultView
                    totalScore={score.totalScore}
                    previewPoints={score.currentScore}
                  />
                ) : (
                  <div className='w-8 h-7 text-white font-bold text-2xs text-center flex flex-col justify-center'>
                    <span>+{score.currentScore}</span>
                    <span className='text-3xs'>Points</span>
                  </div>
                )}
              </div>
            </SubmissionsActionBox>
          );
        })}
      </div>
    </div>
  );
}

function Badges(props: {
  block: MemoryMatchBlock;
  disabled?: boolean;
}): JSX.Element {
  return (
    <div className='w-full px-2.5 flex flex-row items-center justify-start self-start'>
      <DecreasingPointsBadge
        decreasingPointsTimer={props.block.fields.decreasingPointsTimer}
        disabled={props.disabled}
      />
    </div>
  );
}

function Loaded(props: StageProps): JSX.Element {
  const { selectedBlock: block, playback } = props;

  const { switchMedia, reset: resetPlayback } = playback.api;

  useEffect(() => {
    resetPlayback();
    switchMedia(MemoryMatchUtils.GetBackgroundMedia(block));
  }, [block, switchMedia, resetPlayback]);

  const onPresent = async () => {
    await triggerBlockTitleAnim(block);
    await present(block);
  };

  const actionComponent = useBlockControllerBlockTitlePlaying(block) ?? (
    <BlockControllerActionButton onClick={onPresent} icon={PlayIcon}>
      Present Block
    </BlockControllerActionButton>
  );
  return (
    <ControllerLayout action={actionComponent}>
      <div className='w-full flex flex-col'>
        <Badges block={block} />
        <MediaPreviewer
          playback={playback}
          alt={block.type}
          text={block.fields.text}
        />
      </div>
    </ControllerLayout>
  );
}

function GameInit(props: StageProps): JSX.Element {
  const { selectedBlock: block, playback } = props;

  const [processing, setProcessing] = useState(false);
  const gameTimeSec = useGameSessionLocalTimer();

  const onStartGame = async () => {
    if (gameTimeSec === 0) return;
    setProcessing(true);
    await countdownV2({
      debug: 'MemoryMatchBlockGameControl',
      startTimeWorker: true,
      flushCountingStatus: true,
      triggerTimesup: true,
    });
  };

  return (
    <ControllerLayout
      action={
        <BlockControllerActionButton
          onClick={onStartGame}
          icon={TimerIcon}
          disable={processing || gameTimeSec === 0}
        >
          {`Start Game Timer (${gameTimeSec}s)`}
        </BlockControllerActionButton>
      }
    >
      <div className='w-full flex flex-col'>
        <Badges block={block} disabled />
        <MediaPreviewer
          playback={playback}
          alt={block.type}
          text={block.fields.text}
        />
      </div>
    </ControllerLayout>
  );
}

function GameStart(props: StageProps): JSX.Element {
  const time = useGameSessionLocalTimer();

  return (
    <ControllerLayout
      action={<BlockControllerActionTimeRemaining remainingSec={time} />}
    >
      <GameProgress {...props} />
    </ControllerLayout>
  );
}

function GameEnd(props: StageProps): JSX.Element {
  const [processing, setProcessing] = useState(false);

  const onRevealResult = async () => {
    setProcessing(true);
    await next();
  };
  return (
    <ControllerLayout
      action={
        <BlockControllerActionButton
          onClick={onRevealResult}
          icon={DoubleRightArrow}
          disable={processing}
        >
          Reveal Results
        </BlockControllerActionButton>
      }
    >
      <GameProgress {...props} revealResults />
    </ControllerLayout>
  );
}

function Results(props: StageProps): JSX.Element {
  const onShowScoreboard = async () => {
    await next();
  };

  return (
    <ControllerLayout
      action={
        <>
          <BlockControllerActionButton
            onClick={onShowScoreboard}
            isSecondary={true}
          >
            Show Scoreboard
          </BlockControllerActionButton>
          <BlockControllerActionButton
            onClick={props.onEndBlock}
            icon={FilledSquareIcon}
          >
            End Block Sequence
          </BlockControllerActionButton>
        </>
      }
    >
      <GameProgress {...props} revealResults />
    </ControllerLayout>
  );
}

function Scoreboard(props: StageProps): JSX.Element {
  return (
    <ControllerLayout
      action={
        <BlockControllerActionButton
          onClick={props.onEndBlock}
          icon={FilledSquareIcon}
        >
          End Block Sequence
        </BlockControllerActionButton>
      }
    >
      <GameProgress {...props} revealResults />
    </ControllerLayout>
  );
}

function Ended(props: StageProps): JSX.Element {
  return (
    <ControllerLayout
      action={
        <BlockControllerActionButton
          onClick={() => props.setShowResetConfirmation(true)}
          icon={RefreshIcon}
          isDelete={true}
        >
          Reset Completed Block
        </BlockControllerActionButton>
      }
    >
      <GameProgress {...props} hideProgressDetail revealResults />
    </ControllerLayout>
  );
}

export function MemoryMatchBlockController(
  props: SharedProps
): JSX.Element | null {
  const { selectedBlockIndex } = props;
  const block = useStableBlock(props.selectedBlock);
  const isEndedBlock = useIsEndedBlock(block.id);
  const gameSessionStatus =
    useGameSessionStatus<MemoryMatchBlockGameSessionStatus>();
  const playback = useControllerMediaPlayback();

  if (selectedBlockIndex === null) return null;

  if (isEndedBlock) {
    return <Ended {...props} playback={playback} />;
  }

  switch (gameSessionStatus) {
    case MemoryMatchBlockGameSessionStatus.LOADED:
      return <Loaded {...props} playback={playback} />;
    case MemoryMatchBlockGameSessionStatus.GAME_INIT:
      return <GameInit {...props} playback={playback} />;
    case MemoryMatchBlockGameSessionStatus.GAME_START:
      return <GameStart {...props} playback={playback} />;
    case MemoryMatchBlockGameSessionStatus.GAME_END:
      return <GameEnd {...props} playback={playback} />;
    case MemoryMatchBlockGameSessionStatus.RESULTS:
      return <Results {...props} playback={playback} />;
    case MemoryMatchBlockGameSessionStatus.SCOREBOARD:
      return <Scoreboard {...props} playback={playback} />;
    case MemoryMatchBlockGameSessionStatus.END:
    case null:
    case undefined:
      break;
    default:
      assertExhaustive(gameSessionStatus);
      break;
  }

  return null;
}
