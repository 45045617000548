import { useEffect, useMemo, useRef, useState } from 'react';
import { useLatest, usePrevious } from 'react-use';
import { proxy } from 'valtio';

import {
  type GuessWhoBlock,
  GuessWhoBlockGameSessionStatus,
} from '@lp-lib/game';
import { type Media, MediaType } from '@lp-lib/media';

import { useInstance } from '../../../../hooks/useInstance';
import { ClientTypeUtils } from '../../../../types';
import { assertExhaustive } from '../../../../utils/common';
import { playWithCatch } from '../../../../utils/playWithCatch';
import { markSnapshottable, ValtioUtils } from '../../../../utils/valtio';
import { useClock } from '../../../Clock';
import { useGainPointsAnimationGamePlayTrigger } from '../../../GainPointsAnimation/useGainPointsAnimationGamePlayTrigger';
import { FloatBoard, FloatLayout } from '../../../Layout';
import { LayoutAnchor } from '../../../LayoutAnchors/LayoutAnchors';
import { useSyncPersistentPointsRevealAnswer } from '../../../PersistentPoints/Provider';
import { useMyTeamId } from '../../../Player';
import { useSoundEffect } from '../../../SFX';
import { useTeams } from '../../../TeamAPI/TeamV1';
import { useMyClientType } from '../../../Venue/VenuePlaygroundProvider';
import { GoAnimation } from '../../GameBlockCardAnimations';
import {
  useGameSessionStatus,
  useIsGamePlayPaused,
  useIsLiveGamePlay,
  useTimerRecover,
} from '../../hooks';
import { ondTemporaryStageMedia } from '../../ondTemporaryStage';
import { countdownV2, resetTimer, setTimer } from '../../store';
import { GamePlayMediaLayout } from '../Common/GamePlay/GamePlayMediaLayout';
import {
  buildGamePlayMedia,
  type GamePlayMedia,
  GamePlayMediaPlayer,
  type GamePlayMediaPlayerLayout,
  type GamePlayProps,
  useGamePlayMediaPlayable,
  useGamePlayMediaUISync,
  useGamePlayUITransitionControl,
} from '../Common/GamePlay/Internal';
import { StageBgSingleFrame } from '../Common/GamePlay/StageBgSingleFrame';
import { useSubmissionStatusWaitEnder } from '../Common/GamePlay/SubmissionStatusProvider';
import { useRankedTeamScores, useStableBlock } from '../Common/hooks';
import { GuessWhoPlayground } from './GuessWhoPlayground';
import {
  useCurrMatchState,
  useGeneratedMatchPrompts,
  useGuessWhoGame,
  useGuessWhoGamePlayAPI,
  useGuessWhoSharedAPI,
} from './GuessWhoProvider';

type GuessWhoGamePlayLocalState = {
  gamePlayMedia: Nullable<GamePlayMedia, false>;
  mediaPlayerLayout: GamePlayMediaPlayerLayout | null;
  mediaPlayerLoop: boolean;
  showResults: boolean;
};

class GuessWhoGamePlayLocalAPI {
  private _state;
  constructor(
    private block: GuessWhoBlock,
    isHost: boolean,
    isLive: boolean,
    private startTimerWorker = isHost ? !isLive : true
  ) {
    this._state = markSnapshottable(
      proxy<GuessWhoGamePlayLocalState>(this.initState())
    );
  }

  async presentIntro() {
    this.updateGamePlayMedia(
      buildGamePlayMedia(
        {
          media: this.block.fields.introMedia,
          mediaData: this.block.fields.introMediaData,
        },
        {
          stage: 'intro',
        }
      ),
      'anchored'
    );
  }

  async init() {
    setTimer('submission', this.block.fields.promptTimeSec);
  }

  async startPromptPhase() {
    await this.startTimer();
  }

  async startMatchPromptPhase() {
    this.updateGamePlayMedia(null, undefined);
    this.resetTimer();
  }

  async startMatchPromptTimer(timer: number) {
    await resetTimer('submission');
    setTimer('submission', timer);
    await this.startTimer(true);
  }

  async startMatchPromptRevealTimer(timer: number) {
    await resetTimer('submission');
    setTimer('submission', timer);
    await this.startTimer(true);
  }

  revealResults() {
    this.updateGamePlayMedia(null, undefined);
    this._state.showResults = true;
  }

  hideResults() {
    this._state.showResults = false;
  }

  async resetTimer() {
    await resetTimer('submission');
  }

  async load() {
    await this.reset();
  }

  async reset() {
    await resetTimer('submission');
    ValtioUtils.reset(this._state, this.initState());
  }

  private updateGamePlayMedia(
    media: Nullable<GamePlayMedia, false>,
    layout: GamePlayMediaPlayerLayout,
    loop?: boolean
  ) {
    this.state.gamePlayMedia = media;
    this.state.mediaPlayerLayout = layout;
    this.state.mediaPlayerLoop = !!loop;
  }

  private async startTimer(startTimerWorker?: boolean) {
    await countdownV2({
      debug: 'GuessWhoBlockGamePlay',
      startTimeWorker: startTimerWorker ?? this.startTimerWorker,
      flushCountingStatus: true,
    });
  }

  private initState(): GuessWhoGamePlayLocalState {
    return {
      gamePlayMedia: null,
      mediaPlayerLayout: null,
      mediaPlayerLoop: false,
      showResults: false,
    };
  }

  get state() {
    return this._state;
  }
}

function MatchPromptLocalControl(props: { control: GuessWhoGamePlayLocalAPI }) {
  const { control } = props;
  const generatedMatchPrompts = useGeneratedMatchPrompts();
  const currMatchState = useCurrMatchState();
  const total = generatedMatchPrompts?.sequence.length ?? 0;
  const game = useGuessWhoGame();
  const clock = useClock();

  useEffect(() => {
    if (
      !game?.guessTimePerSubmissionSec ||
      !game?.revealTimePerSubmissionSec ||
      currMatchState.index >= total
    ) {
      return;
    }

    // time recovery covered
    const diff = Math.round((clock.now() - currMatchState.updatedAt) / 1000);
    if (currMatchState.phase === 'vote') {
      const time = game.guessTimePerSubmissionSec;
      const nextTime = diff > 1 && diff < time ? time - diff : time;
      control.startMatchPromptTimer(nextTime);
    } else if (currMatchState.phase === 'reveal') {
      const time = game.revealTimePerSubmissionSec;
      const nextTime = diff > 1 && diff < time ? time - diff : time;
      control.startMatchPromptRevealTimer(nextTime);
    }
  }, [
    clock,
    control,
    currMatchState.index,
    currMatchState.phase,
    currMatchState.updatedAt,
    game?.guessTimePerSubmissionSec,
    game?.revealTimePerSubmissionSec,
    total,
  ]);

  useEffect(() => {
    if (currMatchState.phase !== 'done') return;
    control.resetTimer();
  }, [control, currMatchState.phase]);

  return null;
}

function Results(): JSX.Element | null {
  const teamScores = useRankedTeamScores('currentScore', null, 'always');
  const myTeamId = useMyTeamId();
  const formatter = useInstance(() =>
    Intl.NumberFormat('en', { notation: 'compact' })
  );

  const { play } = useSoundEffect('showResults');

  useEffect(() => {
    play();
  }, [play]);

  return (
    <>
      <FloatBoard
        containerZIndex='z-20'
        containerDisplay='flex'
        bgStyle='border-2 border-cyan rounded-xl bg-black bg-opacity-60'
        title='GAME RESULTS'
      >
        <div className='w-full h-full pt-10 pb-20 px-16 overflow-y-auto scrollbar flex flex-col'>
          {teamScores.map((s) => {
            return (
              <div
                key={s.team.id}
                className={`w-full animate-fade-in-up min-h-12 mb-4 pl-6 rounded-3xl bg-black bg-opacity-95 flex flex-row justify-between items-center ${
                  s.team.id === myTeamId ? 'text-tertiary' : 'text-white'
                }`}
              >
                <p className={`text-lg font-bold font-cairo`}>{s.team.name}</p>
                <div className='flex flex-row justify-center items-center'>
                  <div className='w-11 h-11 mr-0.75 ml-3 rounded-full bg-warning text-black flex flex-col justify-center items-center'>
                    <div className='font-extrabold text-xl leading-5 font-cairo'>
                      {formatter.format(s.currentScore)}
                    </div>
                    <p className='text-3xs text-black'>pts</p>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </FloatBoard>
      <FloatLayout className='flex items-center justify-center'>
        <LayoutAnchor
          id='gameplay-points-animation-top'
          className='w-full max-w-4xl'
        />
      </FloatLayout>
    </>
  );
}

/**
 * Background media for this block is not streamed through Agora. Instead, we
 * render a local video element and start it when submissions are enabled.
 */
function BackgroundMediaPlayer(props: {
  media: Pick<Media, 'url' | 'type' | 'firstThumbnailUrl'> | null | undefined;
}): JSX.Element | null {
  const ref = useRef<HTMLVideoElement | null>(null);
  const isPaused = useIsGamePlayPaused();

  useEffect(() => {
    if (ref.current) {
      if (isPaused && !ref.current.paused) {
        ref.current.pause();
      } else if (!isPaused && ref.current.paused) {
        playWithCatch(ref.current);
      }
    }
  }, [isPaused]);

  return (
    <div
      className={`${
        props.media ? 'opacity-100' : 'opacity-0'
      } absolute w-full h-full z-[-1] transition-opacity duration-500`}
    >
      {props.media?.type === MediaType.Video && (
        <video
          ref={ref}
          src={props.media.url}
          className='block w-full h-full object-cover'
          muted
          autoPlay={false}
          poster={props.media.firstThumbnailUrl ?? undefined}
        />
      )}
      {props.media?.type === MediaType.Image && (
        <img
          className='w-full h-full object-cover'
          src={props.media.url}
          alt='luna-park'
        />
      )}
    </div>
  );
}

export function GuessWhoBlockGamePlay(
  props: GamePlayProps<GuessWhoBlock>
): JSX.Element | null {
  const block = useStableBlock(props.block);
  const gss = useGameSessionStatus<GuessWhoBlockGameSessionStatus>();
  const prevGss = usePrevious(gss);
  const [uiState, uiControl] = useGamePlayUITransitionControl();
  const isHost = ClientTypeUtils.isHost(useMyClientType());
  const isLive = useIsLiveGamePlay();
  const control = useMemo(() => {
    return new GuessWhoGamePlayLocalAPI(block, isHost, isLive);
  }, [block, isHost, isLive]);
  const state = control.state;
  const teams = useLatest(
    useTeams({
      updateStaffTeam: true,
      excludeStaffTeam: true,
    })
  );
  const teamId = useMyTeamId();
  const sharedAPI = useGuessWhoSharedAPI();
  const gamePlayAPI = useGuessWhoGamePlayAPI();

  useTimerRecover(async (status) => {
    if (status === GuessWhoBlockGameSessionStatus.PROMPT_COUNTING) {
      return block.fields.promptTimeSec;
    }
    // time recovery of MATCH_PROMPT_COUNTING is handled separately
  });
  useSyncPersistentPointsRevealAnswer(state.showResults);
  useGainPointsAnimationGamePlayTrigger();
  useSubmissionStatusWaitEnder();

  useEffect(() => {
    sharedAPI.on();
    return () => sharedAPI.off();
  }, [sharedAPI]);

  useEffect(() => {
    if (!teamId) return;
    gamePlayAPI.on();
    return () => gamePlayAPI.off();
  }, [gamePlayAPI, teamId]);

  useEffect(() => {
    return () => {
      control.reset();
      sharedAPI.reset();
      gamePlayAPI.reset();
    };
  }, [control, gamePlayAPI, sharedAPI]);

  useEffect(() => {
    if (prevGss === gss) return;
    switch (gss) {
      case GuessWhoBlockGameSessionStatus.LOADED:
        control.load();
        break;
      case GuessWhoBlockGameSessionStatus.INTRO:
        control.presentIntro();
        break;
      case GuessWhoBlockGameSessionStatus.PROMPT_INIT:
        control.init();
        break;
      case GuessWhoBlockGameSessionStatus.PROMPT_COUNTING:
        control.startPromptPhase();
        break;
      case GuessWhoBlockGameSessionStatus.PROMPT_DONE:
        break;
      case GuessWhoBlockGameSessionStatus.MATCH_PROMPT_INIT:
        control.startMatchPromptPhase();
        break;
      case GuessWhoBlockGameSessionStatus.MATCH_PROMPT_COUNTING:
        // check MatchPromptLocalControl
        break;
      case GuessWhoBlockGameSessionStatus.MATCH_PROMPT_DONE:
        // check MatchPromptLocalControl
        break;
      case GuessWhoBlockGameSessionStatus.RESULTS:
        control.revealResults();
        break;
      case GuessWhoBlockGameSessionStatus.SCOREBOARD:
        control.hideResults();
        break;
      case GuessWhoBlockGameSessionStatus.END:
        control.hideResults();
        break;
      case null:
      case undefined:
        break;
      default:
        assertExhaustive(gss);
        break;
    }
  }, [gss, prevGss, control, teams]);

  useGamePlayMediaUISync({
    block,
    gameSessionStatus: gss,
    media: state.gamePlayMedia,
    state: uiState,
    control: uiControl,
  });

  const [backgroundMedia] = useState(
    block.fields.backgroundMedia ?? ondTemporaryStageMedia
  );

  const inGame =
    !!gss &&
    gss >= GuessWhoBlockGameSessionStatus.PROMPT_INIT &&
    gss <= GuessWhoBlockGameSessionStatus.MATCH_PROMPT_DONE;

  const isMatchPromptPhase =
    !!gss &&
    gss >= GuessWhoBlockGameSessionStatus.MATCH_PROMPT_COUNTING &&
    gss <= GuessWhoBlockGameSessionStatus.MATCH_PROMPT_DONE;

  const mediaPlayable = useGamePlayMediaPlayable({
    block,
    gameSessionStatus: gss,
    media: state.gamePlayMedia,
    state: uiState,
  });

  const fullscreen = state.mediaPlayerLayout === 'fullscreen';

  return (
    <div className='fixed w-screen h-screen text-white isolate'>
      {!!gss &&
        gss >= GuessWhoBlockGameSessionStatus.PROMPT_INIT &&
        gss <= GuessWhoBlockGameSessionStatus.MATCH_PROMPT_DONE && (
          <BackgroundMediaPlayer media={backgroundMedia} />
        )}
      <div className='z-0'>
        {state.gamePlayMedia && state.mediaPlayerLayout && (
          <StageBgSingleFrame gamePlayMedia={state.gamePlayMedia} />
        )}
        {uiState.playGoAnimation && <GoAnimation />}
        <GamePlayMediaLayout
          fullscreen={fullscreen}
          className={
            // note: gives the media a bit of padding when in the prompt submission phase.
            gss && gss >= GuessWhoBlockGameSessionStatus.PROMPT_INIT
              ? isHost
                ? 'hidden'
                : 'mt-4'
              : ''
          }
        >
          {state.gamePlayMedia && state.mediaPlayerLayout && (
            <GamePlayMediaPlayer
              gamePlayMedia={state.gamePlayMedia}
              play={mediaPlayable}
              mode={fullscreen || !uiState.mediaEndEffect ? 'full' : 'small'}
              layout={state.mediaPlayerLayout}
              loop={state.mediaPlayerLoop}
            />
          )}
        </GamePlayMediaLayout>
        {isMatchPromptPhase && <MatchPromptLocalControl control={control} />}
        {inGame && (
          <FloatLayout className='flex items-center justify-center'>
            <GuessWhoPlayground
              block={block}
              uiControl={uiControl}
              isHost={isHost}
            />
          </FloatLayout>
        )}
        {state.showResults && <Results />}
      </div>
    </div>
  );
}
