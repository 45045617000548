import { Link, useSearchParams } from '@remix-run/react';
import pluralize from 'pluralize';
import { useCallback, useMemo, useState } from 'react';
import { useDebounce, useSet } from 'react-use';
import { Waypoint } from 'react-waypoint';

import { useLiveAsyncCall } from '../../../hooks/useAsyncCall';
import { useListLoader } from '../../../hooks/useListLoader';
import { apiService } from '../../../services/api-service';
import {
  type Organizer,
  OrganizerRole,
  OrganizerUtils,
  type OrgConnection,
} from '../../../types/organization';
import { nullOrUndefined } from '../../../utils/common';
import { type Action, ActionSheet } from '../../ActionSheet';
import { useAwaitFullScreenConfirmCancelModal } from '../../ConfirmCancelModalContext';
import { ErrorMessage } from '../../Game/GameCenter';
import { ArrowDownIcon, ArrowUpIcon } from '../../icons/Arrows';
import { BillingIcon } from '../../icons/BillingIcon';
import { InfoIcon } from '../../icons/InfoIcon';
import { TeamIcon } from '../../icons/TeamIcon';
import { Loading } from '../../Loading';
import { useNavigateToSubscriptionChange } from '../../Product/utils';
import { useUser } from '../../UserContext';
import { getVenueSlug } from '../../Venue/VenueProvider';
import { useOrgConnection } from '../hooks/useOrgConnection';
import { useTriggerImportOrganizersModal } from '../OrganizerImporter';
import { useOrgContext } from './Context';
import { OrganizerActionSheet } from './OrganizerActionSheet';

function SlackAsSourceOfTruthWarning(props: { connection: OrgConnection }) {
  return (
    <div className='w-full bg-modal rounded-xl px-5 py-2 flex items-center gap-4'>
      <div>
        <InfoIcon className='w-7 h-7 fill-current' />
      </div>
      <div className='text-base font-normal'>
        All user management is done through slack. Add or remove users from Luna
        Park, by adding or removing them from the {props.connection.name}
        slack workspace. Need help?{' '}
        <a href='mailto:support@lunapark.com' className='text-primary'>
          Reach out!
        </a>
      </div>
    </div>
  );
}

function ManageTeamButton(props: {
  importMembersDisabled: boolean;
  onImportMembers: () => void;
  onDeleteMembers: () => void;
}) {
  const actions: Action<string>[] = [
    {
      kind: 'button',
      key: 'import-members',
      text: 'Import Members',
      className: 'text-sms font-medium',
      disabled: props.importMembersDisabled,
      onClick: () => props.onImportMembers(),
    },
    {
      kind: 'button',
      key: 'delete-members',
      text: 'Delete Members',
      className: ' text-sms font-medium',
      onClick: () => props.onDeleteMembers(),
    },
  ];

  return (
    <ActionSheet
      actions={actions}
      optionsChildren={
        <div className='btn-primary w-44 h-10 flex justify-center items-center gap-2.5'>
          Manage Team <ArrowDownIcon className='w-5 h-5 fill-current' />
        </div>
      }
      placement='bottom'
    />
  );
}

function OrganizersRow(props: {
  organizer: Organizer;
  actionSheet: JSX.Element;
}) {
  const { organizer, actionSheet } = props;
  const user = useUser();

  const op = !organizer.activated
    ? 'Account Created'
    : Math.abs(
        new Date(organizer.activatedAt || 0).getTime() -
          new Date(organizer.updatedAt).getTime()
      ) < 1000
    ? 'Activated'
    : 'Updated';
  const date =
    !organizer.activated && organizer.invitedAt
      ? organizer.invitedAt
      : organizer.updatedAt;

  return (
    <tr className='text-sms h-8 hover:bg-lp-gray-002'>
      <td>
        <div className='relative flex items-center w-full h-full'>
          {organizer.role === OrganizerRole.Owner && (
            <div className='absolute -left-5.5'>
              <BillingIcon />
            </div>
          )}

          {OrganizerUtils.GetFullName(organizer)}
          {user.id === organizer.uid ? '(You)' : ''}
        </div>
      </td>

      <td>{OrganizerUtils.GetRoleName(organizer.role)}</td>

      <td>{organizer.email}</td>

      <td>
        <Link
          to={`/venue/${getVenueSlug(organizer)}`}
          reloadDocument
          className='underline'
        >
          venue/{organizer.venueName || organizer.venueId}
        </Link>
      </td>

      <td>
        {`${op} ${new Date(date).toLocaleDateString('en-US', {
          month: '2-digit',
          day: '2-digit',
          year: 'numeric',
        })}`}
      </td>

      <td>{actionSheet}</td>
    </tr>
  );
}

type OrgainzerRoleOption = {
  label: string;
  value: OrganizerRole | null;
};

function parseRole(role: string | null | undefined): OrganizerRole | null {
  if (nullOrUndefined(role)) return null;
  return parseInt(role);
}

const ROLE_OPTIONS: OrgainzerRoleOption[] = [
  {
    label: 'All Roles',
    value: null,
  },
  {
    label: 'Member',
    value: OrganizerRole.Default,
  },
  {
    label: 'Owner',
    value: OrganizerRole.Owner,
  },
  {
    label: 'Admin',
    value: OrganizerRole.Admin,
  },
];

export const OrganizerRoleFilter = (props: {
  value: OrganizerRole | null;
  onChange: (val: OrganizerRole | null) => void;
}): JSX.Element => {
  const { value, onChange } = props;

  const [active, setActive] = useState(false);

  const handleChange = (option: OrganizerRole | null) => {
    setActive(false);
    onChange(option);
  };

  return (
    <div className={`text-base text-white`}>
      <button
        type='button'
        className='btn-secondary text-white flex items-center pl-4 pr-2.5 py-2.5 gap-2.5 h-10'
        onClick={() => setActive(!active)}
      >
        <p>{ROLE_OPTIONS.find((r) => r.value === value)?.label}</p>

        {active ? <ArrowUpIcon /> : <ArrowDownIcon />}
      </button>
      {active && (
        <div
          className=' flex flex-col absolute border border-secondary rounded-xl
        px-6 py-3 mt-px bg-black whitespace-nowrap z-5 w-30'
        >
          {ROLE_OPTIONS.map((op) => (
            <div
              key={op.label}
              className='py-1.5 truncate cursor-pointer text-sms w-full'
              onClick={() => handleChange(op.value)}
            >
              {op.label}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

function OrganizerFilter(): JSX.Element | null {
  const [searchParams, setSearchParams] = useSearchParams();
  const [emailOrName, setEmailOrName] = useState<string>(
    searchParams.get('q') ?? ''
  );

  const handleValueChange = useCallback(() => {
    if (emailOrName === (searchParams.get('q') ?? '')) return;
    if (emailOrName) {
      searchParams.set('q', emailOrName);
    } else {
      searchParams.delete('q');
    }
    setSearchParams(searchParams);
  }, [emailOrName, searchParams, setSearchParams]);

  useDebounce(handleValueChange, 500, [emailOrName]);

  return (
    <div className='flex gap-2.5'>
      <input
        className={`w-50 h-9.5 field mb-0`}
        placeholder='Search Email or Name'
        value={emailOrName}
        onChange={(e) => setEmailOrName(e.target.value)}
      />
      <OrganizerRoleFilter
        value={
          searchParams.get('role')
            ? parseInt(searchParams.get('role') || '0')
            : null
        }
        onChange={(next) => {
          if (nullOrUndefined(next)) {
            searchParams.delete('role');
            setSearchParams(searchParams);
          } else {
            searchParams.set('role', `${next}`);
            setSearchParams(searchParams);
          }
        }}
      />
    </div>
  );
}

export const OrganizationDetailsMembers = (props: {
  className?: string;
  adminView?: boolean;
}): JSX.Element => {
  const { org, updateOrg } = useOrgContext();
  const { connection, isLoading: isConnectionLoading } = useOrgConnection({
    orgId: org.id,
  });

  const triggerModal = useAwaitFullScreenConfirmCancelModal();
  const triggerImportOrganizersModal = useTriggerImportOrganizersModal();
  const user = useUser();

  const [searchParams] = useSearchParams();
  const q = searchParams.get('q');
  const role = parseRole(searchParams.get('role'));
  const navigate = useNavigateToSubscriptionChange();

  const [showDeleteMode, setShowDeleteMode] = useState(false);
  const [selectedUidSet, selectedUidSetActions] = useSet<string>();

  const paginator = useMemo(
    () =>
      apiService.organization.getOrganizers(org.id, {
        q: q,
        role: role,
        size: 100,
      }),
    [org.id, q, role]
  );
  const {
    items,
    dao,
    state: listState,
    error: listError,
    handleLoadMore,
    handleRetry,
  } = useListLoader(paginator, (a, b) => a.uid === b.uid);
  const showEmptyMsg =
    listState.isDone &&
    !listError &&
    items.length === 0 &&
    !paginator.hasMore();
  const canLoadMore = listState.isDone && !listError && paginator.hasMore();
  const metOrExceededOrgSizeLimit =
    org.maxSize !== null && org.organizersCount >= org.maxSize;

  const handleImportMembers = () => {
    triggerImportOrganizersModal({
      organization: org,
      connection: connection,
      onImported: (organizers) => {
        dao.addItems(organizers);
        updateOrg({ organizersCount: org.organizersCount + organizers.length });
      },
      onDoNotUseSlack: () => {
        updateOrg({
          usesSlack: false,
        });
      },
    });
  };

  const {
    call: deleteMembers,
    state: {
      state: { isRunning: isDeletingMembers },
    },
  } = useLiveAsyncCall(async () => {
    const organizers = items.filter((item) => selectedUidSet.has(item.uid));
    await apiService.organization.deleteOrganizers(org.id, {
      uids: Array.from(selectedUidSet),
    });
    updateOrg({
      organizersCount: org.organizersCount - Array.from(selectedUidSet).length,
    });
    dao.deleteItems(organizers);
  });

  const handleClickDelete = async () => {
    const resp = await triggerModal({
      kind: 'confirm-cancel',
      prompt: (
        <div className='text-white text-center'>
          <p className=' text-2xl'>
            Are you sure you want to delete the selected{' '}
            {pluralize('team member', selectedUidSet.size, true)}?
          </p>
        </div>
      ),
      confirmBtnLabel: 'Delete',
      confirmBtnVariant: 'delete',
      cancelBtnLabel: 'Cancel',
    });
    if (resp.result === 'canceled') return;

    await deleteMembers();
    setShowDeleteMode(false);
  };

  if (isConnectionLoading) return <div></div>;

  return (
    <div className={`w-full h-full text-white ${props.className ?? ''}`}>
      {!props.adminView && metOrExceededOrgSizeLimit && (
        <div className='bg-red-004 w-full font-bold text-sm text-center py-3 px-10 mb-4'>
          {org.organizersCount === org.maxSize ? (
            <>
              You’ve reached your limit for member accounts. If you’d like to
              add a new member, please delete an existing member or{' '}
            </>
          ) : (
            <>You’ve exceeded your limit for member accounts. Please </>
          )}
          <button
            type='button'
            className='btn underline font-bold'
            onClick={navigate}
          >
            upgrade your account.
          </button>
        </div>
      )}

      <div className='flex items-center gap-2'>
        <TeamIcon className='w-5 h-5 fill-current' />
        <p className='font-medium text-2xl truncate'>
          Manage {org.name}’s Members{' '}
          {org.maxSize
            ? `(${org.organizersCount}/${org.maxSize})`
            : `(${org.organizersCount})`}
        </p>
      </div>

      {connection?.asSourceOfTruth && (
        <div className='mt-6'>
          <SlackAsSourceOfTruthWarning connection={connection} />
        </div>
      )}

      <div className='mt-9 flex flex-row justify-between'>
        <OrganizerFilter />
        {!connection?.asSourceOfTruth &&
          (showDeleteMode ? (
            <div className='flex items-center gap-2'>
              <button
                type='button'
                className='btn-secondary w-33 h-10'
                onClick={() => setShowDeleteMode(false)}
              >
                Cancel
              </button>
              <button
                type='button'
                className='btn-delete w-42 h-10'
                onClick={handleClickDelete}
                disabled={isDeletingMembers || selectedUidSet.size === 0}
              >
                {isDeletingMembers
                  ? 'Deleting...'
                  : selectedUidSet.size === 0
                  ? 'Delete Selected'
                  : `Delete ${selectedUidSet.size} Selected`}
              </button>
            </div>
          ) : (
            <ManageTeamButton
              importMembersDisabled={
                !props.adminView && metOrExceededOrgSizeLimit
              }
              onImportMembers={handleImportMembers}
              onDeleteMembers={() => {
                setShowDeleteMode(true);
                selectedUidSetActions.clear();
              }}
            />
          ))}
      </div>

      <table className='mt-6 w-full'>
        <thead>
          <tr className='text-left h-10'>
            <th>Name</th>
            <th>Role</th>
            <th>Email Address</th>
            <th>Venue URL</th>
            <th>Account Status</th>
            <th className='w-17'>{showDeleteMode ? 'Delete' : ''}</th>
          </tr>
        </thead>

        <tbody>
          {items.map((organizer) => (
            <OrganizersRow
              key={organizer.uid}
              organizer={organizer}
              actionSheet={
                showDeleteMode ? (
                  <input
                    type='checkbox'
                    className='w-5 h-5 checkbox-dark'
                    checked={selectedUidSet.has(organizer.uid)}
                    onChange={(e) => {
                      if (e.target.checked) {
                        selectedUidSetActions.add(organizer.uid);
                      } else {
                        selectedUidSetActions.remove(organizer.uid);
                      }
                    }}
                    disabled={!OrganizerUtils.CanIManage(user, organizer)}
                  />
                ) : (
                  <OrganizerActionSheet
                    organization={org}
                    connection={connection || null}
                    organizer={organizer}
                    onChange={(organizer) => dao.updateItem(organizer)}
                    onDelete={(organizer) => {
                      dao.deleteItem(organizer);
                      updateOrg({ organizersCount: org.organizersCount - 1 });
                    }}
                  />
                )
              }
            />
          ))}
        </tbody>
      </table>

      {canLoadMore && (
        <Waypoint onEnter={handleLoadMore} fireOnRapidScroll>
          <div>&nbsp;</div>
        </Waypoint>
      )}
      {listState.isRunning && <Loading />}

      {listError && (
        <div className='w-full flex items-center justify-center text-white'>
          <ErrorMessage text='Something went wrong' handleRetry={handleRetry} />
        </div>
      )}

      {showEmptyMsg && (
        <div className='w-full flex items-center justify-center text-secondary'>
          No members.
        </div>
      )}
    </div>
  );
};
