import useSWR from 'swr';

import {
  type DtoTTSGeneratorSettings,
  type EnumsTTSGeneratorId,
} from '@lp-lib/api-service-client/public';

import { apiService } from '../../services/api-service';

type UseTTSGeneratorSettings = {
  generator: DtoTTSGeneratorSettings | undefined;
  isLoading: boolean;
  isValidating: boolean;
  error: Error | undefined;
};

async function loadTTSGenerator(
  generatorId: EnumsTTSGeneratorId
): Promise<DtoTTSGeneratorSettings> {
  const resp = await apiService.tts.getGeneratorSettings(generatorId);
  return resp.data;
}

export function useTTSGeneratorSettings(
  generatorId: EnumsTTSGeneratorId | null | undefined
): UseTTSGeneratorSettings {
  const { data, error, isValidating, isLoading } = useSWR(
    generatorId ? ['/tts/generators/', generatorId] : null,
    ([_, generatorId]) => loadTTSGenerator(generatorId),
    {
      shouldRetryOnError: false,
    }
  );

  return {
    generator: data,
    isLoading,
    isValidating,
    error,
  };
}
