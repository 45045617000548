import { useLayoutEffect, useRef, useState } from 'react';

import * as tu from '../../../../../tailwind-utils.mjs';
import {
  type AbortSignalableRunner,
  YieldableAbortableRunner,
} from '../../../../../utils/AbortSignalableRunner';

export function playAnims(...anims: Animation[]): void {
  for (const a of anims) {
    a.play();
  }
}

export function commitAnims(...anims: Animation[]): void {
  for (const a of anims) {
    a.finish();
    a.commitStyles();
    a.cancel();
  }
}

export const xform = tu.xform;

const runner = YieldableAbortableRunner;

type AnimationExecutor<NamedElementKeys> = (
  reg: Map<NamedElementKeys, HTMLElement | null>,
  totalDurationMs: number
) => Generator<Promise<Animation[]>, void, unknown>;

export type TransitionInfo = {
  state: 'running' | 'ended';
  durationMs: number;
} | null;

export function useAnimationExecutorRunner<NamedElementKeys extends string>(
  id: string | null,
  info: TransitionInfo,
  Executor: AnimationExecutor<NamedElementKeys> | null = null,
  ender: null | (() => Promise<void>),
  debugName?: string
): {
  multiRef: (name: NamedElementKeys, element: null | HTMLElement) => void;
  renderNothing: boolean;
} {
  const currId = useRef<string | null>(null);
  const runnerCtrl = useRef<null | AbortSignalableRunner>(null);
  const durationMs = info?.durationMs ?? 0;
  const [reg] = useState(() => new Map<NamedElementKeys, HTMLElement | null>());

  useLayoutEffect(() => {
    // If the id changes, destroy the current runner!
    if (currId.current !== id) {
      currId.current = id ?? null;
    }

    return () => {
      if (runnerCtrl.current) ender?.();
      runnerCtrl.current?.destroy();
      runnerCtrl.current = null;
    };
  }, [id, ender, debugName]);

  useLayoutEffect(() => {
    if (info?.state === 'running' && !runnerCtrl.current && id) {
      // special case for safety, should never happen since the info should
      // never be written if no transition is needed.
      if (durationMs === 0) {
        ender?.();
        return;
      }

      const ctrl = runner(() => Executor?.(reg, durationMs));
      ctrl.start();

      runnerCtrl.current = ctrl;
      ctrl.finished?.finally(() => {
        runnerCtrl.current = null;
        ender?.();
      });
    }
  }, [runnerCtrl, info?.state, durationMs, reg, ender, Executor, id]);

  // NOTE: be careful with this. In order for the animations to be created and
  // execute, the DOM elements must be present.
  const renderNothing = !info || info.state === 'ended';

  return {
    multiRef: (name: NamedElementKeys, element: null | HTMLElement) => {
      reg.set(name, element);
    },
    renderNothing,
  };
}
