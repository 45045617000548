import { type ReactNode, useEffect, useState } from 'react';

import { MediaFormatVersion } from '@lp-lib/media';

import { createProvider } from '../../utils/createProvider';
import { MediaUtils } from '../../utils/media';
import { useOrg } from '../Lobby/hooks';
import { useVenueOrgId } from '../Venue/useVenueOrgId';
import { ColorFormatter } from './ColorFormatter';
import { getLogoBrandColor, isCloseToGrayscale } from './useLogoBrandColor';

type ProviderValue = {
  /** The actual color that was pulled out of the logo */
  derivedColor: Nullable<ColorFormatter>;
  /** The derived color, or if grayscale, the default text color. */
  scoreboardColor: Nullable<ColorFormatter>;
  /** The derived color, or if grayscale, the default background color. */
  backgroundColor: Nullable<ColorFormatter>;
};

const { useCreatedContext, Provider } = createProvider<ProviderValue>(
  'VenueOrgLogoAverageColor'
);

const DEFAULT_SCOREBOARD_COLOR = new ColorFormatter([255, 255, 255, 255]);
const DEFAULT_BACKGROUND_COLOR = new ColorFormatter([165, 132, 48, 255]);

export function VenueOrgLogoAverageColorProvider(props: {
  children?: ReactNode;
}) {
  const [value, setValue] = useState<ProviderValue>(() => ({
    derivedColor: null,
    scoreboardColor: DEFAULT_SCOREBOARD_COLOR,
    backgroundColor: DEFAULT_BACKGROUND_COLOR,
  }));

  const orgId = useVenueOrgId();

  const { data: org } = useOrg(orgId ?? undefined);

  const logoSrc = MediaUtils.PickMediaUrl(org?.logo, {
    priority: [MediaFormatVersion.SM],
  });

  useEffect(() => {
    async function exec() {
      if (!logoSrc) {
        setValue({
          derivedColor: null,
          scoreboardColor: DEFAULT_SCOREBOARD_COLOR,
          backgroundColor: DEFAULT_BACKGROUND_COLOR,
        });

        return;
      }

      const result = await getLogoBrandColor(logoSrc);
      const next = new ColorFormatter(result);
      const isGrayscale = isCloseToGrayscale(next.hsla_0_1);

      setValue({
        derivedColor: next,
        scoreboardColor: isGrayscale ? DEFAULT_SCOREBOARD_COLOR : next,
        backgroundColor: isGrayscale ? DEFAULT_BACKGROUND_COLOR : next,
      });
    }

    exec();
  }, [logoSrc]);

  return <Provider value={value}>{props.children}</Provider>;
}

export function useVenueOrgScoreboardColor() {
  const ctx = useCreatedContext();
  return ctx.scoreboardColor;
}

export function useVenueOrgBackgroundColor() {
  const ctx = useCreatedContext();
  return ctx.backgroundColor;
}
