import { type GuessWhoBlock } from '@lp-lib/game';
import { type Media } from '@lp-lib/media';

import logger from '../../../../logger/logger';
import { type TeamId } from '../../../../types';
import { type FirebaseService, FirebaseValueHandle } from '../../../Firebase';
import { ondTemporaryStageMedia } from '../../ondTemporaryStage';
import {
  type Game,
  type GeneratedMatchPrompts,
  type MatchPromptState,
  type PlayerStateMap,
  type PlayerSubmission,
} from './types';

export const log = logger.scoped('guess-who');

export const GUESS_TIME_PER_SUBMISSION_SEC = 120;
export const REVEAL_TRANSITION_SEC = 2;
export const REVEAL_TIME_PER_SUBMISSION_SEC = 120;
export const TIME_TO_GUESS_WHO_DURATION_SEC = 3;
export const MAX_MATCH_PROMPTS = 15;
export const MAX_CHOICES_PER_PROMPT = 15;

export class GuessWhoUtils {
  static GetBackgroundMedia(
    block: GuessWhoBlock,
    fallback = ondTemporaryStageMedia
  ): Media {
    return block.fields.backgroundMedia ?? fallback;
  }

  static GetMatchPromptTimeSec(): number {
    // a single match prompt is
    //  guess time + reveal transition + reveal time
    // times max match prompts
    return (
      (GUESS_TIME_PER_SUBMISSION_SEC +
        REVEAL_TRANSITION_SEC +
        REVEAL_TIME_PER_SUBMISSION_SEC) *
      MAX_MATCH_PROMPTS
    );
  }
}

export class GuessWhoFBUtils {
  static Path(
    venueId: string,
    kind: 'root' | 'game' | 'players' | 'generatedMatchPrompts' | 'matchPrompt'
  ): string {
    if (kind === 'root') return `guess-who/${venueId}`;
    return `guess-who/${venueId}/${kind}`;
  }

  static PlayerPath(uid: string, kind: 'submission'): string {
    return `players/${uid}/${kind}`;
  }

  static AbsolutePlayerPath(
    venueId: string,
    teamId: TeamId,
    kind: Parameters<typeof this.PlayerPath>[1]
  ): string {
    return `${this.Path(venueId, 'root')}/${this.PlayerPath(teamId, kind)}`;
  }

  static RootHandle(
    svc: FirebaseService,
    venueId: string
  ): FirebaseValueHandle<unknown> {
    return new FirebaseValueHandle(
      svc.prefixedSafeRef(this.Path(venueId, 'root'))
    );
  }

  static GameHandle(
    svc: FirebaseService,
    venueId: string
  ): FirebaseValueHandle<Game> {
    return new FirebaseValueHandle(
      svc.prefixedSafeRef(this.Path(venueId, 'game'))
    );
  }

  static PlayerStateHandle(
    svc: FirebaseService,
    venueId: string
  ): FirebaseValueHandle<PlayerStateMap> {
    return new FirebaseValueHandle(
      svc.prefixedSafeRef(this.Path(venueId, 'players'))
    );
  }

  static PlayerSubmissionHandle(
    svc: FirebaseService,
    venueId: string,
    playerId: string
  ): FirebaseValueHandle<PlayerSubmission> {
    return new FirebaseValueHandle(
      svc.prefixedSafeRef(
        this.AbsolutePlayerPath(venueId, playerId, 'submission')
      )
    );
  }

  static GeneratedMatchPromptsHandle(
    svc: FirebaseService,
    venueId: string
  ): FirebaseValueHandle<GeneratedMatchPrompts> {
    return new FirebaseValueHandle(
      svc.prefixedSafeRef(this.Path(venueId, 'generatedMatchPrompts'))
    );
  }

  static MatchPromptStateHandle(
    svc: FirebaseService,
    venueId: string
  ): FirebaseValueHandle<MatchPromptState> {
    return new FirebaseValueHandle(
      svc.prefixedSafeRef(this.Path(venueId, 'matchPrompt'))
    );
  }
}
