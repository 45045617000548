import AgoraRTC, { type UID } from 'agora-rtc-sdk-ng';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useEffectOnce } from 'react-use';
import useSWRMutation from 'swr/mutation';

import { EnumsWebRTCTestSource } from '@lp-lib/api-service-client/public';
import { LogLevel } from '@lp-lib/logger-base';

import logo from '../../assets/img/logo-text.svg';
import { Modal } from '../../components/common/Modal';
import { Header } from '../../components/Header';
import { CorrectIcon } from '../../components/icons/CorrectIcon';
import { IncorrectIcon } from '../../components/icons/IncorrectIcon';
import { PlayFillIcon } from '../../components/icons/PlayFillIcon';
import { Loading } from '../../components/Loading';
import { ReCAPTCHAContainer } from '../../components/ReCAPTCHA';
import { useUser } from '../../components/UserContext';
import config from '../../config';
import {
  type TransformedAsyncCallState,
  useLiveAsyncCall,
} from '../../hooks/useAsyncCall';
import {
  getFeatureQueryParam,
  getFeatureQueryParamNumber,
} from '../../hooks/useFeatureQueryParam';
import { useInstance } from '../../hooks/useInstance';
import { useLiveCallback } from '../../hooks/useLiveCallback';
import { useTitle } from '../../hooks/useTitle';
import { useUnload } from '../../hooks/useUnload';
import logger from '../../logger/logger';
import { apiService } from '../../services/api-service';
import {
  LogRecorder,
  type WebRTCTestResult,
  WebRTCTestRunner,
} from '../../services/webrtc';
import { getStaticAssetPath } from '../../utils/assets';
import { BrowserIntervalCtrl } from '../../utils/BrowserIntervalCtrl';
import { err2s, makeTitle, uuidv4 } from '../../utils/common';
import { playWithCatch } from '../../utils/playWithCatch';

const intro = getStaticAssetPath('videos/conntection-test-intro-v2.mp4');

function Intro(props: {
  disabled: boolean;
  handleStart: () => void;
  showLoading: boolean;
  error: Error | null;
  replay: boolean;
}): JSX.Element {
  return (
    <div className='w-full h-full absolute flex flex-col items-center justify-center bg-lp-black-001 z-50'>
      <div className='w-1/2 text-center text-white flex flex-col items-center justify-center'>
        {props.replay ? (
          <p className='text-3.5xl font-bold'>Replay Awesome Luna Park Video</p>
        ) : (
          <>
            <p className='text-3.5xl font-bold'>Welcome to Luna Park!</p>
            <p className='text-xl font-bold mt-8'>
              <span className='text-red-002'>If you are using a VPN</span>
              <span>
                , please turn it off before pressing play to start the video and
                test your connection to the platform!
              </span>
            </p>
            {props.error && (
              <p className='text-red-002 text-sms'>{err2s(props.error)}</p>
            )}
          </>
        )}
      </div>
      <button
        type='button'
        className='btn-primary mt-19 w-24 h-24 rounded-full flex items-center justify-center text-black'
        onClick={props.handleStart}
        disabled={props.disabled}
      >
        {props.showLoading ? (
          <Loading text='' imgClassName='w-8 h-8' />
        ) : (
          <PlayFillIcon className='w-8 h-8 fill-current ml-2' />
        )}
      </button>
    </div>
  );
}

function Succeeded(props: {
  report: (email: string) => Promise<void>;
  reportState: TransformedAsyncCallState;
  handleClose: () => void;
}): JSX.Element {
  useEffectOnce(() => {
    props.report('');
  });

  return (
    <div className='w-full h-full flex flex-col items-center justify-center relative'>
      <div className='font-medium absolute top-0'>
        All tests passed successfully!
      </div>
      {props.reportState.isRunning ? (
        <Loading text='' imgClassName='w-16 h-16' />
      ) : (
        <CorrectIcon className='w-16 h-16 fill-current' />
      )}
      <p className='text-xl font-medium mt-4'>
        You are cleared and ready to use Luna Park!
      </p>
      <button
        type='button'
        className='btn-secondary w-33 h-10 absolute bottom-10'
        onClick={props.handleClose}
      >
        Close
      </button>
    </div>
  );
}

type FormData = {
  email: string;
};

function Failed(props: {
  report: (email: string) => Promise<void>;
  reportState: TransformedAsyncCallState;
  reportError: Error | null;
  handleClose?: () => void;
}): JSX.Element {
  const { report, reportState, reportError, handleClose } = props;
  const { register, handleSubmit, formState } = useForm<FormData>();

  const onSubmit = handleSubmit(async (data: FormData) => {
    await report(data.email);
  });

  if (reportState.isDone && !reportError) {
    return (
      <div className='w-full h-full flex flex-col items-center justify-center relative'>
        <p className='w-4/5 text-center text-xl font-medium mb-10'>
          <p>
            Your test results and email address have to been sent to our team
            for review.
          </p>
          <p className='mt-8'>
            Please don't hesitate to reach out to your Luna Park point of
            contact for any other questions!
          </p>
        </p>
        {handleClose && (
          <button
            type='button'
            className='btn-secondary w-33 h-10 absolute bottom-8'
            onClick={handleClose}
          >
            Close
          </button>
        )}
      </div>
    );
  }

  return (
    <form
      className='w-full h-full flex flex-col items-center justify-center relative'
      onSubmit={onSubmit}
    >
      <div className='font-medium absolute top-0'>
        We found issues that must be resolved before you can access Luna Park.
      </div>
      <IncorrectIcon className='w-16 h-16 fill-current' />
      <p className='text-xl font-medium mt-4 text-center w-4/5'>
        Please provide your email address so that we can reach out to assist
        you!
      </p>
      <input
        className={`${
          formState.errors.email ? 'field-error' : 'field'
        } text-xl font-medium text-center my-6`}
        placeholder='Enter Email Address'
        type='email'
        required
        {...register('email')}
      />
      {reportError && (
        <p className='text-sms text-red-002'>{err2s(reportError)}</p>
      )}
      <button
        className='btn-primary w-40 h-10 absolute bottom-0 flex items-center justify-center'
        type='submit'
        disabled={reportState.isRunning}
      >
        {reportState.isRunning && (
          <Loading text='' imgClassName='w-5 h-5' containerClassName='mr-2' />
        )}
        <p>Submit</p>
      </button>
    </form>
  );
}

enum Step {
  Intro = 0,
  Running,
  Finished,
}

function useRecordConsoleLogs(recorder: LogRecorder) {
  useLayoutEffect(() => {
    const original = {
      log: console.log,
      debug: console.debug,
      info: console.info,
      error: console.error,
      warn: console.warn,
    };
    console.log = (...args) => {
      recorder.log(LogLevel.Trace, ...args);
      original.log(...args);
    };
    console.debug = (...args) => {
      recorder.log(LogLevel.Debug, ...args);
      original.debug(...args);
    };
    console.info = (...args) => {
      recorder.log(LogLevel.Info, ...args);
      original.info(...args);
    };
    console.error = (...args) => {
      recorder.log(LogLevel.Error, ...args);
      original.error(...args);
    };
    console.warn = (...args) => {
      recorder.log(LogLevel.Warning, ...args);
      original.warn(...args);
    };
    return () => {
      console.log = original.log;
      console.debug = original.debug;
      console.info = original.info;
      console.error = original.error;
      console.warn = original.warn;
    };
  }, [recorder]);
}

function useVerboseLogging(
  verboseEnabled = getFeatureQueryParam('verbose-local-logging'),
  agoraLogLevel = config.agora.logLevel
) {
  useLayoutEffect(() => {
    AgoraRTC.setLogLevel(0);
    logger.verbose(true);
    return () => {
      AgoraRTC.setLogLevel(verboseEnabled ? 0 : agoraLogLevel);
      logger.verbose(verboseEnabled);
    };
  }, [agoraLogLevel, verboseEnabled]);
}

export function ConnectionTest(props: {
  orgId?: string | null;
  withHeader?: boolean;
  reCaptchaEnabled?: boolean;
  customizeFinishStep?: boolean;
  onCompleted?: (result: WebRTCTestResult) => void;
}): JSX.Element | null {
  const { withHeader, reCaptchaEnabled, customizeFinishStep, onCompleted } =
    props;
  const logRecorder = useInstance(() => new LogRecorder());
  useVerboseLogging();
  useRecordConsoleLogs(logRecorder);

  const [reCaptcha, setReCaptcha] = useState<ReCaptchaV2.ReCaptcha | undefined>(
    undefined
  );

  const [result, setResult] = useState<WebRTCTestResult | null>(null);
  const [step, setStep] = useState<Step>(Step.Intro);
  const videoRef = useRef<HTMLVideoElement>(null);
  const [showModal, setShowModal] = useState(false);
  const [replay, setReplay] = useState(false);
  const [hasVideoError, setHasVideoError] = useState(false);

  const tempToken = useSWRMutation(
    'get-temp-token',
    async (_, extra: { arg: { captchaResponse: string } }) => {
      const temp = await apiService.auth.getTempToken(
        extra.arg.captchaResponse
      );
      return temp.data.token;
    }
  );

  const getLatestTempTokenRef = useLiveCallback(() => tempToken);

  const handleStart = async () => {
    if (replay) {
      playWithCatch(videoRef.current);
      setStep(Step.Running);
    } else {
      if (reCaptchaEnabled) {
        if (!reCaptcha) return;
        reCaptcha.execute();
      } else {
        const result = await tempToken.trigger({ captchaResponse: 'bypass' });
        if (!result) return;
        await startWebRTCTestRunner(result);
      }
    }
  };

  const onVideoEnded = () => {
    if (replay) {
      setStep(Step.Intro);
    } else {
      setShowModal(true && !customizeFinishStep);
      if (result) onCompleted?.(result);
    }
  };

  const onVideoError = () => {
    setHasVideoError(true);
    const r = {
      id: uuidv4(),
      succeeded: false,
      logs: [
        {
          level: LogLevel.Error,
          message: 'intro video play error',
          timestamp: new Date().toISOString(),
        },
      ],
      data: [],
      channelName: result?.channelName || '',
      startedAt: new Date().toISOString(),
      endedAt: new Date().toISOString(),
    };
    setResult(r);
    setStep(Step.Finished);
    setShowModal(true && !customizeFinishStep);
  };

  const handleClose = () => {
    setShowModal(false);
    setReplay(true);
    setStep(Step.Intro);
  };

  const startWebRTCTestRunner = useLiveCallback(async (token?: string) => {
    const runner = new WebRTCTestRunner({
      logRecorder,
      timeout: getFeatureQueryParamNumber('connection-test-timeout'),
      getToken: async (uid: UID, channel: string): Promise<string> => {
        const res = await apiService.auth.getAgoraRTCToken(
          {
            sessionUid: uid.toString(),
            channelName: channel,
          },
          token
        );
        return res.data.token;
      },
    });
    playWithCatch(videoRef.current);
    setStep(Step.Running);
    const r = await runner.run();
    setResult(r);
    setStep(Step.Finished);
  });

  const {
    state: { state: reportState, error: reportError },
    call: report,
  } = useLiveAsyncCall(async (email: string) => {
    if (!result) {
      alert('invalid result');
      return;
    }
    await apiService.misc.reportTestResult({
      id: result.id,
      from: window.location.href,
      source: EnumsWebRTCTestSource.WebRTCTestSourceStandalone,
      orgId: props.orgId,
      results: [result],
      email,
    });
  });

  useUnload((ev) => {
    if (reportState.isRunning) {
      ev.preventDefault();
      const ret =
        'Your test results are being sent to our team for review. Are you sure you want to leave?';
      ev.returnValue = ret;
      return ret;
    }
  });

  const [buttonEnabled, setButtonEnabled] = useState(false);

  // NOTE(drew): Polling should obviously be unnecessary, but without it, the
  // button is never enabled outside of local development, or fails the second
  // time the test is loaded! Seems like a race condition that I cannot pin
  // down. The race happens regardless if recaptcha is enabled or not, which
  // points at the video element.
  useEffect(() => {
    if (!reCaptchaEnabled) {
      setButtonEnabled(true);
      return;
    }
    const ctrl = new BrowserIntervalCtrl();
    ctrl.set(() => {
      if (
        window.grecaptcha &&
        videoRef.current?.duration &&
        !getLatestTempTokenRef().isMutating
      ) {
        setButtonEnabled(true);
        ctrl.clear();
      }
    }, 500);
  }, [getLatestTempTokenRef, reCaptchaEnabled]);

  return (
    <div className='h-full w-full bg-default'>
      {withHeader && <Header />}
      <div className='absolute inset-0 z-0'>
        <video
          ref={videoRef}
          src={intro}
          className='w-full h-full object-cover'
          controls={false}
          loop={false}
          playsInline
          onEnded={onVideoEnded}
          onError={onVideoError}
        />
      </div>
      {step === Step.Intro && (
        <Intro
          handleStart={handleStart}
          disabled={!buttonEnabled}
          showLoading={tempToken.isMutating}
          error={tempToken.error}
          replay={replay}
        />
      )}
      {step === Step.Finished && result && showModal && (
        <Modal>
          <div className='flex flex-col bg-modal rounded-xl text-white relative px-8 pt-12 pb-8 items-center w-160 h-120'>
            <img src={logo} className='w-80 absolute -top-36' alt='logo' />
            {result.succeeded ? (
              <Succeeded
                report={report}
                reportState={reportState}
                handleClose={handleClose}
              />
            ) : (
              <Failed
                report={report}
                reportError={reportError}
                reportState={reportState}
                handleClose={hasVideoError ? undefined : handleClose}
              />
            )}
          </div>
        </Modal>
      )}
      <ReCAPTCHAContainer
        className='absolute inset-0 z-55'
        enabled={reCaptchaEnabled === undefined ? false : reCaptchaEnabled}
        onReady={(grecaptcha) => {
          setReCaptcha(grecaptcha);
        }}
        onExecute={async (token) => {
          const result = await tempToken.trigger({ captchaResponse: token });
          if (!result) return;
          await startWebRTCTestRunner(result);
        }}
      />
    </div>
  );
}

// Easier to default export for lazy import
// eslint-disable-next-line import/no-default-export
export default function Component(props: { reCaptchaEnabled?: boolean }) {
  useTitle(makeTitle('Connection Test'));
  const user = useUser({ init: true });

  return (
    <ConnectionTest
      withHeader
      reCaptchaEnabled={props.reCaptchaEnabled}
      orgId={user.organizer?.orgId}
    />
  );
}
