import { useRef } from 'react';
import { useEffectOnce } from 'react-use';
import useSWRImmutable from 'swr/immutable';

import {
  type MultipleChoiceBlockDetailScore,
  QuestionBlockAnswerGrade,
  type TeamDataList,
} from '@lp-lib/game';

import { FloatBoard } from '../../../Layout';
import { LayoutAnchor } from '../../../LayoutAnchors/LayoutAnchors';
import { Loading } from '../../../Loading';
import { useMyTeamId } from '../../../Player';
import { useSoundEffect } from '../../../SFX';
import { fetchDetailScoresData } from '../../store';
import { type TeamScore, useRankedTeamScores } from '../Common/hooks';

type Results = {
  teamResults: {
    id: string;
    name: string;
    score: number;
    answer: string | undefined;
  }[];
  totalResults: number;
  totalCorrect: number;
  percentageCorrect: number;
};

async function loadResults(
  blockId: string,
  teamScores: TeamScore[]
): Promise<Results> {
  const detailScores: TeamDataList<MultipleChoiceBlockDetailScore> =
    (await fetchDetailScoresData(blockId)) ?? {};

  const totalCorrect = Object.values(detailScores).reduce((accum, curr) => {
    return curr.grade === QuestionBlockAnswerGrade.CORRECT ? accum + 1 : accum;
  }, 0);

  const totalResults = teamScores.length;
  const percentageCorrect =
    totalResults === 0
      ? 0
      : Math.round((totalCorrect / teamScores.length) * 100);

  const teamResults = teamScores.map((score) => ({
    id: score.team.id,
    name: score.team.name,
    score: score.currentScore,
    answer: detailScores[score.team.id]?.answer,
  }));

  return { teamResults, totalCorrect, totalResults, percentageCorrect };
}

export function MultipleChoiceResults(props: {
  blockId: string;
}): JSX.Element | null {
  const myTeamId = useMyTeamId();
  const teamScores = useRankedTeamScores('currentScore', null, 'always');
  // note(falcon): useSWRImmutable is ergonomic here, but it caches. this won't normally be a problem in a normal game,
  // but it does confuse development, where in some cases, you're getting stale data from useSWRImmutable. using
  // Date.now() will effectively treat each mount of the component (even of the same block) as separate keys, and
  // avoids the cache.
  const key = useRef([props.blockId, Date.now()]);

  const { data, isValidating } = useSWRImmutable(key.current, () =>
    loadResults(props.blockId, teamScores)
  );

  const { play: showResultsSFX } = useSoundEffect('showResults');

  useEffectOnce(() => {
    showResultsSFX();
  });

  return (
    <FloatBoard
      containerZIndex='z-20'
      containerDisplay='flex'
      bgStyle={`border-2 border-cyan bg-lp-black-001 rounded-xl`}
      title='RESULTS'
    >
      <div className='w-full h-full pt-10 pb-20 px-4'>
        {isValidating || !data ? (
          <div className='h-full flex items-center justify-center'>
            <Loading />
          </div>
        ) : (
          <>
            <div className='font-cairo text-center text-lg font-bold py-5'>
              {`${data.totalCorrect} out of ${data.totalResults} Teams answered correctly! (${data.percentageCorrect}%)`}
            </div>
            <div className='w-full h-full px-12 overflow-y-auto scrollbar'>
              <div className='w-full h-full flex flex-col'>
                {data.teamResults.map((row) => {
                  return (
                    <div
                      key={row.id}
                      className={`
                      w-full animate-fade-in-up min-h-12 mb-4
                      rounded-3xl bg-lp-gray-009 flex flex-row justify-between items-center ${
                        row.id === myTeamId ? 'text-tertiary' : 'text-white'
                      }`}
                    >
                      <p className='text-lg font-bold font-cairo ml-6'>
                        {row.name}
                      </p>
                      <div className='flex flex-row justify-center items-center'>
                        <p
                          className={`text-3xs font-bold mr-3 ${
                            row.id !== myTeamId && !row.answer
                              ? 'text-icon-gray'
                              : ''
                          }`}
                        >
                          {row.answer ?? '[Did not answer]'}
                        </p>
                        <div className='w-11 h-11 mr-0.75 rounded-full bg-warning text-black flex flex-col justify-center items-center'>
                          <div className='font-extrabold text-xl leading-5 font-cairo'>
                            {row.score}
                          </div>
                          <p className='text-3xs text-black'>pts</p>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </>
        )}
      </div>
      <LayoutAnchor
        id='gameplay-points-animation-top'
        className='absolute bottom-32 w-full max-w-4xl'
      />
    </FloatBoard>
  );
}
