export function AIIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='20'
      height='20'
      viewBox='0 0 20 20'
      className={props.className ?? 'w-5 h-5 fill-current'}
    >
      <g clipPath='url(#clip0_56320_11142)'>
        <path d='M8.288 3.689a.3.3 0 01.567 0l1.422 4.164a3 3 0 001.87 1.87l4.164 1.422a.3.3 0 010 .568l-4.164 1.422a3 3 0 00-1.87 1.87l-1.422 4.164a.3.3 0 01-.567 0l-1.423-4.165a3 3 0 00-1.87-1.87L.832 11.714a.3.3 0 010-.568l4.165-1.422a3 3 0 001.87-1.87L8.287 3.69z'></path>
        <path d='M15.572.416a.15.15 0 01.284 0l.711 2.082c.15.44.496.785.935.935l2.082.71a.15.15 0 010 .285l-2.082.71a1.5 1.5 0 00-.935.935l-.71 2.083a.15.15 0 01-.285 0l-.71-2.083a1.5 1.5 0 00-.935-.934l-2.083-.711a.15.15 0 010-.284l2.083-.711a1.5 1.5 0 00.934-.935l.711-2.082z'></path>
      </g>
      <defs>
        <clipPath id='clip0_56320_11142'>
          <path d='M0 0H20V20H0z'></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export function AIRedIcon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='26'
      height='26'
      fill='none'
      viewBox='0 0 26 26'
    >
      <circle cx='13' cy='13' r='13' fill='#FF0935'></circle>
      <g fill='#fff' clipPath='url(#clip0_61413_8901)'>
        <path d='M10.287 7.688a.3.3 0 01.568 0l1.422 4.165a3 3 0 001.87 1.87l4.164 1.421a.3.3 0 010 .568l-4.164 1.422a3 3 0 00-1.87 1.87l-1.422 4.164a.3.3 0 01-.568 0l-1.422-4.164a3 3 0 00-1.87-1.87l-4.164-1.422a.3.3 0 010-.567l4.165-1.423a3 3 0 001.87-1.87l1.421-4.164zM17.572 4.416a.15.15 0 01.284 0l.711 2.082c.15.44.496.785.935.935l2.082.71a.15.15 0 010 .285l-2.082.71a1.5 1.5 0 00-.935.935l-.71 2.083a.15.15 0 01-.285 0l-.71-2.083a1.5 1.5 0 00-.935-.934l-2.083-.711a.15.15 0 010-.284l2.083-.711a1.5 1.5 0 00.934-.935l.711-2.082z'></path>
      </g>
      <defs>
        <clipPath id='clip0_61413_8901'>
          <path fill='#fff' d='M0 0H20V20H0z' transform='translate(2 4)'></path>
        </clipPath>
      </defs>
    </svg>
  );
}
