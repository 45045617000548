// If any of these properties are not self-explainable, it's best to search for
// where they are read/referenced and infer the usage from there. `allow` is a
// convention that indicates that other conditions may need to be checked.
const DefaultChecks = {
  ondV1Badge: false,
  ondHostRecordingRequired: false,
  ondAudioOnly: false,
  ondAllowPointsMultiplierAnimation: false,
  ondAllowSubmissionStatusWidget: false,
  ondVenueBackground: false,
  ondAllowMinihost: true,
  ondAllowFallbackAnimatedStage: false,
  ondManageHostVisuals: false,
  ondAbsenceOfHostVideoIndicatesError: false,
  ondExpectIntro: false,
  ondExpectOutro: false,
  ondOutroRequiresInjectedBlock: false,
  ondAllowBlockTitles: false,
  ondAllowAllTeamsFinishedAnim: false,
  ondBoostMusicPlayerVolume: false,
  ondTownhallEnabled: false,
  ondMinigameTransition: false,
  ondTitleBlockDrivesVoiceOvers: false,
};

type OndVersionCheckSummary = typeof DefaultChecks;

export function OndVersionChecks(
  playbackVersion: number | null | undefined
): OndVersionCheckSummary {
  const result = { ...DefaultChecks };

  if (!playbackVersion) return result;

  // All versions have these possibilities.
  result.ondMinigameTransition = true;
  result.ondAllowPointsMultiplierAnimation = true;

  // Specific versions follow:

  if (playbackVersion === 1) {
    result.ondV1Badge = true;
    result.ondHostRecordingRequired = true;
    result.ondAllowMinihost = true;
    result.ondManageHostVisuals = true;
    result.ondAbsenceOfHostVideoIndicatesError = true;
    result.ondExpectIntro = true;
    result.ondExpectOutro = true;
  } else if (playbackVersion === 3) {
    result.ondHostRecordingRequired = false;
    result.ondAllowMinihost = true;
    result.ondAllowFallbackAnimatedStage = true;
    result.ondManageHostVisuals = true;
    result.ondAbsenceOfHostVideoIndicatesError = false;
    result.ondAllowBlockTitles = true;
    result.ondAllowAllTeamsFinishedAnim = true;
    result.ondTownhallEnabled = true;
  } else if (playbackVersion === 3.1) {
    result.ondHostRecordingRequired = false;
    result.ondAllowMinihost = false;
    result.ondAudioOnly = true;
    result.ondVenueBackground = true;
    result.ondOutroRequiresInjectedBlock = true;
    result.ondAllowBlockTitles = true;
    result.ondAllowAllTeamsFinishedAnim = true;
    result.ondBoostMusicPlayerVolume = true;
    result.ondTownhallEnabled = true;
    result.ondTitleBlockDrivesVoiceOvers = true;
  }

  return result;
}
