import { useEffect } from 'react';

import { useCohostClientId, useHostClientId } from '../../../Player';
import { StageMode } from '../../types';
import { useStageControlAPI } from '../Provider';

export function useSwitchStageMode(
  targetMode = StageMode.BLOCK_CONTROLLED,
  returnMode = StageMode.BOS
) {
  const stageControl = useStageControlAPI();
  const hostClientId = useHostClientId();
  const cohostClientId = useCohostClientId();
  useEffect(() => {
    stageControl.updateStageMode(targetMode);
    return () => {
      stageControl.leaveAll([hostClientId, cohostClientId]);
      stageControl.updateStageMode(returnMode);
    };
  }, [cohostClientId, hostClientId, returnMode, stageControl, targetMode]);
}
