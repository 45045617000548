const ScoreboardIcon = ({ className }: { className?: string }): JSX.Element => {
  return (
    <svg
      className={className || 'w-3.5 h-3.5 fill-current'}
      viewBox='0 0 14 14'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path d='M6 2C6 1.44772 6.44772 1 7 1C7.55228 1 8 1.44772 8 2V9.4C8 9.73137 7.73137 10 7.4 10H6.6C6.26863 10 6 9.73137 6 9.4V2Z' />
      <path d='M2 4C2 3.44772 2.44772 3 3 3C3.55228 3 4 3.44772 4 4V9.4C4 9.73137 3.73137 10 3.4 10H2.6C2.26863 10 2 9.73137 2 9.4V4Z' />
      <path d='M11 4C10.4477 4 10 4.44772 10 5V9.4C10 9.73137 10.2686 10 10.6 10H11.4C11.7314 10 12 9.73137 12 9.4V5C12 4.44772 11.5523 4 11 4Z' />
      <path d='M1.6 11C1.26863 11 1 11.2686 1 11.6C1 11.9314 1.26863 12.2 1.6 12.2H12.4C12.7314 12.2 13 11.9314 13 11.6C13 11.2686 12.7314 11 12.4 11H1.6Z' />
    </svg>
  );
};

export { ScoreboardIcon };
