import { useEffect, useRef } from 'react';

import {
  assertExhaustive,
  type RoundRobinQuestionBlock,
  RoundRobinQuestionBlockGameSessionStatus,
} from '@lp-lib/game';
import { ConnectionStatus } from '@lp-lib/shared-schema';

import { useLiveCallback } from '../../../../hooks/useLiveCallback';
import { isReconnecting } from '../../../../store/utils';
import { useParticipant } from '../../../Player';
import { useTeamMembers } from '../../../TeamAPI/TeamV1';
import { useVenueId } from '../../../Venue/VenueProvider';
import { useGameSessionStatus, useIsGamePlayPaused } from '../../hooks';
import { type GameControlProps } from '../Common/GameControl/types';
import { useStableBlock } from '../Common/hooks';
import {
  roundRobinChangeSubmitterTimerCutoffSec,
  roundRobinQuestionNoTimer,
} from './RoundRobinQuestionBlockEditor';
import {
  type TeamControlAPI,
  type TeamControlState,
  useAutoUpdateDetailScore,
  useRoundRobinQuestionGameControl,
  useRoundRobinTeamsData,
  useTeamControl,
} from './RoundRobinQuestionProvider';
import { type Question, QuestionStatus } from './types';
import { RoundRobinQuestionUtils } from './utils';

type QuestionControlSharedProps = {
  isPlaying: boolean;
  question: Question;
  block: RoundRobinQuestionBlock;

  state: TeamControlState;
  api: TeamControlAPI;
};

function QuestionControlNotStarted(props: QuestionControlSharedProps) {
  const {
    isPlaying,
    api: { updateStatus },
  } = props;

  useEffect(() => {
    if (!isPlaying) return;

    updateStatus(QuestionStatus.ShowQuestion);
  }, [isPlaying, updateStatus]);

  return null;
}

function QuestionControlShowQuestion(props: QuestionControlSharedProps) {
  const {
    isPlaying,
    question,
    state: { currentProgress },
    api: { updateStatus, changeSubmitter },
  } = props;

  const currentSubmitter = useParticipant(currentProgress?.submitterClientId);
  const remainingSec = useRef(
    RoundRobinQuestionUtils.ShowQuestionDurationSec(question)
  );

  useEffect(() => {
    if (!isPlaying) return;

    const timer = setInterval(() => {
      remainingSec.current = remainingSec.current - 1;
      if (remainingSec.current <= 0) {
        clearInterval(timer);
        updateStatus(QuestionStatus.ShowAnswer);
      }
    }, 1000);
    return () => {
      clearInterval(timer);
    };
  }, [isPlaying, updateStatus]);

  useEffect(() => {
    if (!isPlaying) return;
    if (
      question.timeSec !== roundRobinQuestionNoTimer &&
      question.timeSec <= roundRobinChangeSubmitterTimerCutoffSec
    )
      return;

    const timer = setInterval(() => {
      if (remainingSec.current <= roundRobinChangeSubmitterTimerCutoffSec)
        return;
      if (
        !currentSubmitter ||
        (currentSubmitter.status === ConnectionStatus.Disconnected &&
          !isReconnecting(currentSubmitter)) ||
        !!currentSubmitter.away
      ) {
        changeSubmitter();
      }
    }, 1000);
    return () => {
      clearInterval(timer);
    };
  }, [changeSubmitter, currentSubmitter, isPlaying, question.timeSec]);

  return null;
}

function QuestionControlShowAnswer(props: QuestionControlSharedProps) {
  const {
    isPlaying,
    question,
    block,
    state: { questions },
    api: { updateStatus, pushQuestion },
  } = props;

  const remainingSec = useRef(
    RoundRobinQuestionUtils.ShowAnswerDurationSec(question)
  );

  const onFrame = useLiveCallback(async () => {
    remainingSec.current = remainingSec.current - 1;

    if (block.fields.replayIncorrectQuestions && question.grade !== 'Correct') {
      await pushQuestion(
        RoundRobinQuestionUtils.CloneReplayQuestion(question, questions.length)
      );
      await updateStatus(QuestionStatus.Ended);
      return;
    }

    if (remainingSec.current <= 0) {
      await updateStatus(QuestionStatus.Ended);
    }
  });

  useEffect(() => {
    if (!isPlaying) return;

    const timer = setInterval(async () => {
      onFrame();
    }, 1000);
    return () => {
      clearInterval(timer);
    };
  }, [isPlaying, onFrame]);

  return null;
}

function QuestionControlEnded(props: QuestionControlSharedProps) {
  const {
    isPlaying,
    api: { nextQuestion },
  } = props;

  useEffect(() => {
    if (!isPlaying) return;

    nextQuestion();
  }, [isPlaying, nextQuestion]);

  return null;
}

function QuestionControl(props: QuestionControlSharedProps) {
  const { question } = props;

  switch (question.status) {
    case QuestionStatus.NotStarted:
      return <QuestionControlNotStarted {...props} />;
    case QuestionStatus.ShowQuestion:
      return <QuestionControlShowQuestion {...props} />;
    case QuestionStatus.ShowAnswer:
      return <QuestionControlShowAnswer {...props} />;
    case QuestionStatus.Ended:
      return <QuestionControlEnded {...props} />;
    default:
      assertExhaustive(question.status);
      return null;
  }
}

type SharedProps = GameControlProps<RoundRobinQuestionBlock>;

function Loaded(): JSX.Element | null {
  const { resetGame } = useRoundRobinQuestionGameControl();

  useEffect(() => {
    resetGame('loaded');
  }, [resetGame]);
  return null;
}

function GameInit(props: SharedProps): JSX.Element | null {
  const { block } = props;
  const { initGame } = useRoundRobinQuestionGameControl();

  useEffect(() => {
    initGame(block);
  }, [block, initGame]);

  return null;
}

function TeamControl(props: {
  gameSessionStatus: RoundRobinQuestionBlockGameSessionStatus;
  teamId: string;
  block: RoundRobinQuestionBlock;
}): JSX.Element | null {
  const { gameSessionStatus, teamId, block } = props;

  const venueId = useVenueId();
  const isGamePlayPaused = useIsGamePlayPaused();
  const teamMembers = useTeamMembers(teamId, true);

  const [state, control] = useTeamControl({
    venueId,
    teamId,
  });
  const { currentQuestion, currentProgress } = state;

  useAutoUpdateDetailScore(block, teamId, state.questions);

  if (!currentQuestion || !currentProgress) return null;
  return (
    <QuestionControl
      key={currentQuestion.index}
      isPlaying={
        gameSessionStatus ===
          RoundRobinQuestionBlockGameSessionStatus.GAME_START &&
        !isGamePlayPaused &&
        !!teamMembers &&
        teamMembers.length > 0
      }
      question={currentQuestion}
      block={block}
      state={state}
      api={control}
    />
  );
}

function GameInProgress(props: {
  gameSessionStatus: RoundRobinQuestionBlockGameSessionStatus;
  block: RoundRobinQuestionBlock;
}): JSX.Element | null {
  const { gameSessionStatus, block } = props;

  const teamsData = useRoundRobinTeamsData();

  return (
    <>
      {teamsData &&
        Object.keys(teamsData).map((teamId) => (
          <TeamControl
            key={teamId}
            gameSessionStatus={gameSessionStatus}
            teamId={teamId}
            block={block}
          />
        ))}
    </>
  );
}

export function RoundRobinQuestionBlockGameControl(
  props: GameControlProps<RoundRobinQuestionBlock>
): JSX.Element | null {
  const block = useStableBlock(props.block);
  const gameSessionStatus =
    useGameSessionStatus<RoundRobinQuestionBlockGameSessionStatus>();

  switch (gameSessionStatus) {
    case RoundRobinQuestionBlockGameSessionStatus.LOADED:
      return <Loaded />;
    case RoundRobinQuestionBlockGameSessionStatus.GAME_INIT:
      return <GameInit block={block} />;
    case RoundRobinQuestionBlockGameSessionStatus.GAME_START:
    case RoundRobinQuestionBlockGameSessionStatus.GAME_END:
      return (
        <GameInProgress gameSessionStatus={gameSessionStatus} block={block} />
      );
    case RoundRobinQuestionBlockGameSessionStatus.RESULTS:
    case RoundRobinQuestionBlockGameSessionStatus.SCOREBOARD:
    case RoundRobinQuestionBlockGameSessionStatus.END:
    case null:
    case undefined:
      break;
    default:
      assertExhaustive(gameSessionStatus);
      break;
  }

  return null;
}
