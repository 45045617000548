import {
  type ILocalAudioTrack,
  type ILocalVideoTrack,
  type IRemoteAudioTrack,
  type IRemoteVideoTrack,
  type LocalAudioTrackStats,
  type LocalVideoTrackStats,
  type RemoteAudioTrackStats,
  type RemoteVideoTrackStats,
  type UID,
} from 'agora-rtc-sdk-ng';
import { useCallback, useEffect, useState } from 'react';

import { useInstance } from '../../hooks/useInstance';
import { type DebugStream } from './types';

function toKbps(val: number | undefined): number {
  if (!val) return 0;
  return Math.round(val / 1000);
}

function StatsEntry(props: {
  label: string;
  value: number | string | undefined;
}): JSX.Element | null {
  return (
    <>
      <div className='text-right mr-2'>{props.label}: </div>
      <div>{props.value}</div>
    </>
  );
}

function LocalStreamStats(props: DebugStream): JSX.Element | null {
  const { rtcService, uid } = props;
  const [audioTrack, setAudioTrack] = useState<ILocalAudioTrack>();
  const [videoTrack, setVideoTrack] = useState<ILocalVideoTrack>();
  const [audioTrackStats, setAudioTrackStats] =
    useState<LocalAudioTrackStats>();
  const [videoTrackStats, setVideoTrackStats] =
    useState<LocalVideoTrackStats>();

  const updateTracks = useCallback(() => {
    const tracks = rtcService.getTracksByUid(uid);
    setAudioTrack(tracks[0] as ILocalAudioTrack);
    setVideoTrack(tracks[1] as ILocalVideoTrack);
  }, [uid, rtcService]);

  useEffect(() => {
    updateTracks();
    rtcService.on('audio-switched', updateTracks);
    rtcService.on('video-switched', updateTracks);
    return () => {
      rtcService.off('audio-switched', updateTracks);
      rtcService.off('video-switched', updateTracks);
    };
  }, [uid, rtcService, updateTracks]);

  useEffect(() => {
    const timerId = setInterval(() => {
      setAudioTrackStats(audioTrack?.getStats());
      setVideoTrackStats(videoTrack?.getStats());
    }, 1000);
    return () => clearInterval(timerId);
  }, [audioTrack, videoTrack]);

  const switchVideoProfile = (profile: string) => {
    if (!videoTrack) return;
    videoTrack.setEncoderConfiguration(profile);
  };

  const profiles = useInstance(() => ['120p', '240p', '360p', '720p']);

  return (
    <div className='text-white text-3xs px-3'>
      <section>
        <header className='font-bold border-b'>Audio Local Stats</header>
        <div className='grid grid-cols-2'>
          <StatsEntry
            label='sendBitrate'
            value={`${toKbps(audioTrackStats?.sendBitrate)} (kbps)`}
          />
          <StatsEntry
            label='packetsLostRate'
            value={audioTrackStats?.currentPacketLossRate.toFixed(2)}
          />
          <StatsEntry
            label='sendVolumeLevel'
            value={audioTrackStats?.sendVolumeLevel}
          />
        </div>
      </section>
      <section>
        <header className='font-bold border-b'>Video Local Stats</header>
        <div className='grid grid-cols-2'>
          <StatsEntry
            label='fps(capture/send)'
            value={`${videoTrackStats?.captureFrameRate?.toFixed(2) || 0} / ${
              videoTrackStats?.sendFrameRate?.toFixed(2) || 0
            }`}
          />
          <StatsEntry
            label='bitrate(capture/send)'
            value={`${toKbps(videoTrackStats?.targetSendBitrate)} / ${toKbps(
              videoTrackStats?.sendBitrate
            )} (kbps)`}
          />
          <StatsEntry
            label='resolution(capture/send)'
            value={`${videoTrackStats?.captureResolutionWidth || 0}x${
              videoTrackStats?.captureResolutionHeight || 0
            } 
            / ${videoTrackStats?.sendResolutionWidth || 0}x${
              videoTrackStats?.sendResolutionHeight || 0
            }`}
          />
          <StatsEntry
            label='packetsLostRate'
            value={videoTrackStats?.currentPacketLossRate.toFixed(2)}
          />
          <StatsEntry
            label='encodeDelay'
            value={`${videoTrackStats?.encodeDelay?.toFixed(2) || 0} (ms)`}
          />
          <StatsEntry
            label='totalFreezeTime'
            value={`${videoTrackStats?.totalFreezeTime || 0} (s)`}
          />
        </div>
      </section>
      <section>
        <header className='font-bold border-b'>Switch Video Profile</header>
        <select
          className='bg-transparent text-gray-50 h-4'
          disabled={!videoTrack}
          onChange={(e) => switchVideoProfile(e.target.value)}
        >
          <option value=''>Select a profile</option>
          {profiles.map((profile) => (
            <option key={profile} value={profile}>
              {profile}
            </option>
          ))}
        </select>
      </section>
    </div>
  );
}

function RemoteStreamStats(props: DebugStream): JSX.Element | null {
  const { rtcService, uid: clientId } = props;
  const [audioTrack, setAudioTrack] = useState<IRemoteAudioTrack>();
  const [videoTrack, setVideoTrack] = useState<IRemoteVideoTrack>();
  const [audioTrackStats, setAudioTrackStats] =
    useState<RemoteAudioTrackStats>();
  const [videoTrackStats, setVideoTrackStats] =
    useState<RemoteVideoTrackStats>();

  const publishedCallback = useCallback(
    async (uid: UID, mediaType: 'audio' | 'video') => {
      if (uid !== clientId) return;
      if (mediaType === 'audio') {
        setAudioTrack(rtcService.getTracksByUid(uid)[0] as IRemoteAudioTrack);
      }
      if (mediaType === 'video') {
        setVideoTrack(rtcService.getTracksByUid(uid)[1] as IRemoteVideoTrack);
      }
    },
    [clientId, rtcService]
  );

  const unpublishedCallback = useCallback(
    (uid: UID, mediaType: 'audio' | 'video') => {
      if (uid !== clientId) return;
      if (mediaType === 'audio') {
        setAudioTrack(undefined);
      }
      if (mediaType === 'video') {
        setVideoTrack(undefined);
      }
    },
    [clientId]
  );

  useEffect(() => {
    const tracks = rtcService.getTracksByUid(clientId);
    setAudioTrack(tracks[0] as IRemoteAudioTrack);
    setVideoTrack(tracks[1] as IRemoteVideoTrack);
    rtcService.on('remote-user-published', publishedCallback);
    rtcService.on('remote-user-unpublished', unpublishedCallback);
    return () => {
      rtcService.off('remote-user-published', publishedCallback);
      rtcService.off('remote-user-unpublished', unpublishedCallback);
    };
  }, [rtcService, clientId, publishedCallback, unpublishedCallback]);

  useEffect(() => {
    const timerId = setInterval(() => {
      setAudioTrackStats(audioTrack?.getStats());
      setVideoTrackStats(videoTrack?.getStats());
    }, 1000);
    return () => clearInterval(timerId);
  }, [audioTrack, videoTrack]);

  return (
    <div className='text-white text-3xs px-3'>
      <section>
        <header className='font-bold border-b'>Audio Remote Stats</header>
        <div className='grid grid-cols-2'>
          <StatsEntry
            label='recvBitrate'
            value={`${toKbps(audioTrackStats?.receiveBitrate)} (kbps)`}
          />
          <StatsEntry
            label='packetsLostRate'
            value={`${audioTrackStats?.currentPacketLossRate.toFixed(
              2
            )} / ${audioTrackStats?.packetLossRate.toFixed(2)}`}
          />
          <StatsEntry
            label='delay(e2e/recv/transport)'
            value={`${audioTrackStats?.end2EndDelay?.toFixed(2) || 0} / ${
              audioTrackStats?.receiveDelay?.toFixed(2) || 0
            } / ${audioTrackStats?.transportDelay?.toFixed(2) || 0} (ms)`}
          />
          <StatsEntry
            label='totalFreezeTime'
            value={`${audioTrackStats?.totalFreezeTime || 0} (s)`}
          />
          <StatsEntry
            label='freezeRate'
            value={`${audioTrackStats?.freezeRate.toFixed(2) || 0}`}
          />
          <StatsEntry
            label='receiveLevel'
            value={`${audioTrackStats?.receiveLevel || 0}`}
          />
        </div>
      </section>
      <section>
        <header className='font-bold border-b'>Video Remote Stats</header>
        <div className='grid grid-cols-2'>
          <StatsEntry
            label='fps(recv/decode/render)'
            value={`${videoTrackStats?.receiveFrameRate || 0} / ${
              videoTrackStats?.decodeFrameRate || 0
            } / ${videoTrackStats?.renderFrameRate || 0}`}
          />
          <StatsEntry
            label='recvBitrate'
            value={`${toKbps(videoTrackStats?.receiveBitrate)} (kbps)`}
          />
          <StatsEntry
            label='recvResolution'
            value={`${videoTrackStats?.receiveResolutionWidth || 0}x${
              videoTrackStats?.receiveResolutionHeight || 0
            }`}
          />
          <StatsEntry
            label='packetsLostRate'
            value={`${
              videoTrackStats?.currentPacketLossRate.toFixed(2) || 0
            } / ${videoTrackStats?.packetLossRate.toFixed(2) || 0}`}
          />
          <StatsEntry
            label='delay(e2e/recv/transport)'
            value={`${videoTrackStats?.end2EndDelay?.toFixed(2) || 0} / ${
              videoTrackStats?.receiveDelay?.toFixed(2) || 0
            } / ${videoTrackStats?.transportDelay?.toFixed(2) || 0} (ms)`}
          />
          <StatsEntry
            label='totalFreezeTime'
            value={`${videoTrackStats?.totalFreezeTime || 0} (s)`}
          />
          <StatsEntry
            label='freezeRate'
            value={`${videoTrackStats?.freezeRate.toFixed(2) || 0}`}
          />
        </div>
      </section>
    </div>
  );
}

export function StreamStats(props: DebugStream): JSX.Element | null {
  const { rtcService, uid } = props;

  if (rtcService.isLocalUser(uid)) {
    return <LocalStreamStats {...props} />;
  }
  return <RemoteStreamStats {...props} />;
}
