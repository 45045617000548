import { type CSSProperties, useCallback, useRef } from 'react';
import { default as ReactCanvasConfetti } from 'react-canvas-confetti';

export function useConfettiAnimation() {
  const refAnimationInstance = useRef<confetti.CreateTypes | null>(null);

  const canvasStyles: CSSProperties = {
    position: 'fixed',
    pointerEvents: 'none',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
  };

  const getInstance = useCallback((instance: confetti.CreateTypes | null) => {
    refAnimationInstance.current = instance;
  }, []);

  const makeShot = useCallback(
    (particleRatio: number, opts: confetti.Options) => {
      refAnimationInstance.current &&
        refAnimationInstance.current({
          ...opts,
          particleCount: Math.floor(800 * particleRatio),
        });
    },
    []
  );

  const fire = useCallback(() => {
    makeShot(0.25, {
      angle: 40,
      spread: 26,
      origin: { x: 0, y: 1 },
      decay: 0.96,

      startVelocity: 55,
    });

    makeShot(0.2, {
      angle: 40,
      origin: { x: 0, y: 1 },
      decay: 0.96,

      spread: 60,
    });

    makeShot(0.35, {
      angle: 40,
      spread: 100,
      decay: 0.96,
      origin: { x: 0, y: 1 },

      scalar: 0.8,
    });

    makeShot(0.1, {
      angle: 40,
      spread: 120,
      startVelocity: 25,
      decay: 0.96,
      origin: { x: 0, y: 1 },

      scalar: 1.2,
    });

    makeShot(0.1, {
      angle: 40,
      spread: 40,
      origin: { x: 0, y: 1 },
      decay: 0.96,

      startVelocity: 45,
    });

    makeShot(0.25, {
      angle: 40,
      spread: 26,
      origin: { x: 0, y: 1 },
      decay: 0.96,

      startVelocity: 55,
    });

    makeShot(0.2, {
      angle: 40,
      origin: { x: 0, y: 1 },
      decay: 0.96,

      spread: 60,
    });

    makeShot(0.35, {
      angle: 40,
      spread: 100,
      decay: 0.96,
      origin: { x: 0, y: 1 },

      scalar: 0.8,
    });

    makeShot(0.1, {
      angle: 40,
      spread: 40,
      origin: { x: 0, y: 1 },
      decay: 0.96,
      startVelocity: 45,
    });

    makeShot(0.25, {
      angle: 130,
      spread: 26,
      origin: { x: 1, y: 1 },
      decay: 0.96,

      startVelocity: 55,
    });

    makeShot(0.2, {
      angle: 130,
      origin: { x: 1, y: 1 },
      decay: 0.96,

      spread: 60,
    });

    makeShot(0.35, {
      angle: 130,
      spread: 100,
      decay: 0.96,
      origin: { x: 1, y: 1 },

      scalar: 0.8,
    });

    makeShot(0.1, {
      angle: 130,
      spread: 120,
      startVelocity: 25,
      decay: 0.96,
      origin: { x: 1, y: 1 },

      scalar: 1.2,
    });

    makeShot(0.1, {
      angle: 130,
      spread: 40,
      origin: { x: 1, y: 1 },
      decay: 0.96,

      startVelocity: 45,
    });

    makeShot(0.25, {
      angle: 130,
      spread: 26,
      origin: { x: 1, y: 1 },
      decay: 0.96,

      startVelocity: 55,
    });

    makeShot(0.2, {
      angle: 130,
      origin: { x: 1, y: 1 },
      decay: 0.96,

      spread: 60,
    });

    makeShot(0.35, {
      angle: 130,
      spread: 100,
      decay: 0.96,
      origin: { x: 1, y: 1 },

      scalar: 0.8,
    });

    makeShot(0.1, {
      angle: 130,
      spread: 40,
      origin: { x: 1, y: 1 },
      decay: 0.96,
      startVelocity: 45,
    });
  }, [makeShot]);

  return {
    fire: fire,
    canvasConfetti: (
      <ReactCanvasConfetti refConfetti={getInstance} style={canvasStyles} />
    ),
  };
}
