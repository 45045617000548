import { useCallback, useMemo, useRef, useState } from 'react';

import { useLiveCallback } from './useLiveCallback';

export type Repository<T> = {
  addItem: (item: T) => void;
  addItems: (items: T[]) => void;
  deleteItem: (item: T) => void;
  deleteItems: (items: T[]) => void;
  updateItem: (item: Partial<T>) => void;
  updateItems: React.Dispatch<React.SetStateAction<T[]>>;
  moveItem: (from: number, to: number) => void;
  hasItem: (item: T) => boolean;
  prependItem: (item: T) => void;
};

type Options<T> = {
  init?: T[];
  prepend?: boolean;
  compare?: (a: T, b: Partial<T>) => boolean;
};

export function useArrayState<T>(
  options?: Options<T>
): [T[], React.Dispatch<React.SetStateAction<T[]>>, Repository<T>] {
  const { init, compare, prepend } = Object.assign(
    {},
    {
      init: [],
      compare: (a: T, b: Partial<T>): boolean => {
        return a === b;
      },
    },
    options
  );
  const compareFn = useRef(compare);
  const [items, setItems] = useState<T[]>(init);

  const addItem = useCallback(
    (item: T) => {
      setItems((prev) => {
        if (prepend) return [item, ...prev];
        return [...prev, item];
      });
    },
    [prepend]
  );
  const addItems = useCallback(
    (items: T[]) => {
      setItems((prev) => {
        if (prepend) return [...items, ...prev];
        return [...prev, ...items];
      });
    },
    [prepend]
  );
  const deleteItem = useCallback((item: T) => {
    setItems((prev) => {
      const index = prev.findIndex((i) => compareFn.current(i, item));
      if (index === -1) return prev;
      const updated = [...prev];
      updated.splice(index, 1);
      return updated;
    });
  }, []);
  const deleteItems = useCallback((items: T[]) => {
    setItems((prev) => {
      const updated = [...prev];
      items.forEach((item) => {
        const index = updated.findIndex((i) => compareFn.current(i, item));
        if (index !== -1) updated.splice(index, 1);
      });
      return updated;
    });
  }, []);
  const updateItem = useCallback((item: Partial<T>) => {
    setItems((prev) => {
      const index = prev.findIndex((i) => compareFn.current(i, item));
      if (index === -1) return prev;
      const updated = [...prev];
      updated[index] = Object.assign({}, updated[index], item);
      return updated;
    });
  }, []);
  const moveItem = useCallback((from: number, to: number) => {
    setItems((prev) => {
      if (from === to) return prev;
      const updated = [...prev];
      const item = updated[from];
      updated.splice(from, 1);
      updated.splice(to, 0, item);
      return updated;
    });
  }, []);
  const hasItem = useLiveCallback((item: T) => {
    const index = items.findIndex((i) => compareFn.current(i, item));
    return index !== -1;
  });
  const prependItem = useLiveCallback((item: T) => {
    setItems((prev) => {
      return [item, ...prev];
    });
  });

  return [
    items,
    setItems,
    useMemo(
      () => ({
        addItem,
        addItems,
        deleteItem,
        deleteItems,
        updateItem,
        updateItems: setItems,
        moveItem,
        hasItem,
        prependItem,
      }),
      [
        addItem,
        addItems,
        deleteItem,
        deleteItems,
        updateItem,
        moveItem,
        hasItem,
        prependItem,
      ]
    ),
  ];
}
