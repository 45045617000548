const HostIcon = ({ className }: { className?: string }): JSX.Element => {
  return (
    <svg
      className={className || 'w-3 h-3 fill-current'}
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 12 12'
    >
      <path
        fill='#fff'
        fillRule='evenodd'
        d='M7.8.857A3.353 3.353 0 0111.143 4.2c0 .833-.316 1.606-.834 2.2l-.212-.517a1.183 1.183 0 00-2.034-.28C6.961 5.111 6.213 4.01 6.13 2.75c-.335.39-.558.892-.558 1.449 0 1.133.889 2.162 2.109 2.225l-.261.634-1.2.116c-.052.005-.1.013-.148.023l.113-.1A3.1 3.1 0 014.847 5.76L1.783 9.214c-.167.279-.167.669.056.892.222.278.612.278.891.055l2.486-2.205c-.147.426-.063.935.287 1.28l-2.049 1.817a1.86 1.86 0 01-1.95.223l-.557.557a.539.539 0 01-.78 0 .539.539 0 010-.78l.557-.557C.39 9.883.501 9.103.947 8.546l1.839-2.062-.167-.223a.539.539 0 010-.78.539.539 0 01.78 0l.167.168.891-1.059A3.357 3.357 0 017.8.857zm1.83 6.141l-.326-.789a.328.328 0 00-.233-.201 2.436 2.436 0 01-.38.215l-.545 1.32a3.329 3.329 0 001.483-.545zm-2.698.97l-.786.696-.033-.03c-.218-.201-.101-.578.188-.606l.63-.06zM9.972 4.7c.057-.167.057-.334.057-.501 0-1.393-1.282-2.507-2.73-2.173a2.213 2.213 0 002.674 2.674z'
        clipRule='evenodd'
      />
      <path
        fill='#FBB707'
        d='M8.696 6.21a.326.326 0 01.608 0l.603 1.463a.333.333 0 00.274.208l1.518.147c.29.028.406.405.188.605L10.74 9.685a.356.356 0 00-.104.337l.335 1.555c.064.296-.242.529-.492.373l-1.31-.813a.319.319 0 00-.34 0l-1.31.813c-.25.156-.556-.077-.492-.373l.335-1.555a.356.356 0 00-.105-.337L6.113 8.633c-.218-.2-.101-.577.188-.605l1.518-.147a.333.333 0 00.274-.208l.603-1.464z'
      />
    </svg>
  );
};

export { HostIcon };
