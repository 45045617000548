import { type BPUnitlessProperties } from '../../../../../breakpoints';
import { useUnitlessMatch } from '../../../../../hooks/useUnitlessMatch';
import { TimeUtils } from '../../../../../utils/time';
import { useCountdownStartTime } from './GamePlayUtilities';
import { GamePlayStyle } from './utils';

const ProgressRing = ({
  className,
  radiusBreakpoints,
  strokeBreakpoints,
  totalTime,
  currentTime,
  withPingAnimations,
  textClassName,
  durationFormattedMMSS,
}: {
  className: string;
  radiusBreakpoints?: BPUnitlessProperties;
  strokeBreakpoints?: BPUnitlessProperties;
  totalTime: number | null;
  currentTime: number | null;
  withPingAnimations?: boolean;
  textClassName?: string;
  durationFormattedMMSS?: boolean;
}): JSX.Element => {
  const style = GamePlayStyle.UseProgressRing();
  const radius = useUnitlessMatch(radiusBreakpoints || style.radius);
  const stroke = useUnitlessMatch(strokeBreakpoints || style.stroke);
  const countdownStartTime = useCountdownStartTime(totalTime);

  const normalizedRadius = radius - stroke * 2;
  const circumference = normalizedRadius * 2 * Math.PI;
  const progress =
    totalTime !== null && currentTime != null && totalTime !== 0
      ? currentTime / totalTime
      : 0;
  const strokeDashoffset = circumference - progress * circumference;
  const fillColor = currentTime === 0 ? '#EE3529' : 'black';
  const wormColor =
    currentTime === null || currentTime <= 3 ? '#EE3529' : '#2BE0FA';

  const pingAnimationClass =
    !withPingAnimations || currentTime === null
      ? ''
      : currentTime === 0
      ? 'animate-ping-translucent-once'
      : currentTime <= countdownStartTime
      ? 'animate-ping'
      : '';

  return (
    <div className={className}>
      <svg height={radius * 2} width={radius * 2}>
        {withPingAnimations && (
          <circle
            className={`${pingAnimationClass} origin-center`}
            fill={fillColor}
            // scale down the ping circle so it doesn't exceed the bounds of
            // the svg.
            r={normalizedRadius * 0.75}
            cx={radius}
            cy={radius}
          />
        )}
        <circle
          className='spinner-circle'
          stroke={wormColor}
          fill={fillColor}
          strokeDasharray={circumference + ' ' + circumference}
          style={{ strokeDashoffset }}
          strokeWidth={stroke}
          r={normalizedRadius}
          cx={radius}
          cy={radius}
        />
        <text
          className={`text-white text-xl font-cairo font-extrabold ${textClassName}`}
          fill='#ffffff'
          fontSize='20px'
          dominantBaseline='central'
          textAnchor='middle'
          x='50%'
          y='50%'
        >
          {durationFormattedMMSS
            ? TimeUtils.DurationFormattedHHMMSS((currentTime ?? 0) * 1000)
            : currentTime}
        </text>
      </svg>
    </div>
  );
};

export { ProgressRing };
