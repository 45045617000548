import React, { useContext, useMemo } from 'react';
import { proxy, useSnapshot } from 'valtio';

import { type PairingGame } from '../../../types';
import { markSnapshottable, ValtioUtils } from '../../../utils/valtio';
import { type FirebaseService } from '../../Firebase';
import { useGameSessionGamePackId } from '../../Game/hooks';
import { useVenueId } from '../../Venue/VenueProvider';

interface State {
  published: boolean;
  pairing: PairingGame | null;
}

function initialState(): State {
  return {
    published: false,
    pairing: null,
  };
}

class API {
  constructor(
    readonly venueId: string,
    private state: State,
    svc: FirebaseService,
    private ref = svc.prefixedRef<Nullable<PairingGame>>(
      `pairing/${venueId}/game`
    )
  ) {}

  async subscribe() {
    this.ref.on('value', (snapshot) => {
      const val = snapshot.val();
      if (!val) return;
      ValtioUtils.set(this.state, 'pairing', val);
    });
  }

  async publish(pairing: PairingGame | null) {
    ValtioUtils.set(this.state, 'pairing', pairing);
    await this.ref.set(pairing);
    this.state.published = true;
  }

  unpublish() {
    this.state.published = false;
  }

  async updatePairing(
    next: (prev: PairingGame) => Partial<PairingGame> | null
  ) {
    if (!this.state.pairing) return;
    const updates = next(this.state.pairing);
    if (!updates) return;
    await this.ref.update(updates);
  }

  async reset() {
    this.ref.off();
    ValtioUtils.reset(this.state, initialState());
  }
}

interface PairingGameContext {
  state: State;
  api: API;
}

const Context = React.createContext<PairingGameContext | null>(null);

function usePairingGameContext(): PairingGameContext {
  const ctx = useContext(Context);
  if (!ctx) {
    throw new Error('PairingGameContext is not in the tree!');
  }
  return ctx;
}

export function usePairingGameControlAPI(): API {
  const ctx = usePairingGameContext();
  return ctx.api;
}

// NOTE: only coordinator can publish pairing game, it's always false for non-coordinator
export function useIsPairingGamePublished(): boolean {
  const ctx = usePairingGameContext();
  return useSnapshot(ctx.state).published;
}

export function usePairing(): State['pairing'] {
  const ctx = usePairingGameContext();
  return (useSnapshot(ctx.state) as typeof ctx.state).pairing;
}

export function usePairingId(): string | null {
  const pairing = usePairing();
  return pairing?.pairingId || null;
}

export function useIsPairingGamePlay(): boolean {
  return !!usePairingId();
}

export function useIsPairingGamePackLoaded(): boolean {
  const pairing = usePairing();
  const gameSessionGamePackId = useGameSessionGamePackId();
  return useMemo(
    () => !!pairing?.gamePacks?.find((gp) => gp.id === gameSessionGamePackId),
    [gameSessionGamePackId, pairing?.gamePacks]
  );
}

export function useIsPairingGameAllCompleted(): boolean {
  const pairing = usePairing();
  return useMemo(
    () =>
      !!pairing?.gamePacks &&
      pairing.gamePacks.length > 1 &&
      !!pairing?.gamePacks?.every((p) =>
        pairing.completedGamePackIds?.includes(p.id)
      ),
    [pairing?.completedGamePackIds, pairing?.gamePacks]
  );
}

export const PairingGameProvider = ({
  svc,
  children,
}: {
  svc: FirebaseService;
  children?: React.ReactNode;
}): JSX.Element => {
  const venueId = useVenueId();

  const ctxValue = useMemo(() => {
    const state = markSnapshottable(proxy<State>());
    const api = new API(venueId, state, svc);
    return { state, api };
  }, [svc, venueId]);

  return <Context.Provider value={ctxValue}>{children}</Context.Provider>;
};
