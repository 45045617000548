import { type ReactNode } from 'react';

import { useIsController } from '../../../../../hooks/useMyInstance';
import {
  useAllTeamsFinishedAnimInfo,
  useGameSessionBlock,
} from '../../../hooks';
import { endAllTeamsFinishedAnim } from '../../../store';
import { GamePlayFullScreenTransitionContainer } from './GamePlayFullScreenTransitionContainer';
import {
  playAnims,
  type TransitionInfo,
  useAnimationExecutorRunner,
  xform,
} from './useBlockAnimationExecutorRunner';

type StageFinishedNamedElements =
  | 'mask'
  | 'strip'
  | 'text'
  | 'bg-rect-001'
  | 'bg-rect-002'
  | 'bg-rect-003';

// These are specified detached from the HTML because they are needed to make
// the keyframes more legible (e.g. it's easier to see the from->to states if
// there are fewer implicit states). You'll notice these same styles are
// referenced in both the JSX and the animation keyframes for consistency.

const initialCSSProps: {
  [K in StageFinishedNamedElements]: { [key: string]: string | number };
} = {
  mask: {},
  strip: {
    transform: xform({ translateX: '-100%' }),
  },
  text: {
    transform: xform({ translateX: '-100%' }),
    left: 0,
  },
  'bg-rect-001': {
    transform: xform({ rotateA: '16deg', translateX: '-100%' }),
  },
  'bg-rect-002': {
    transform: xform({ rotateA: '16deg', translateX: '-100%' }),
  },
  'bg-rect-003': {
    transform: xform({
      rotateA: '16deg',
      translateX: '-100%',
      translateY: '6%',
    }),
  },
};

function* StageFinishedAnimation(
  reg: Map<StageFinishedNamedElements, HTMLElement | null>,
  totalDurationMs: number
) {
  const mask = reg.get('mask');
  const strip = reg.get('strip');
  const text = reg.get('text');
  const rect001 = reg.get('bg-rect-001');
  const rect002 = reg.get('bg-rect-002');
  const rect003 = reg.get('bg-rect-003');

  if (!strip || !text || !mask || !rect001 || !rect002 || !rect003) return;

  const fieldDimensions = mask.getBoundingClientRect();
  const textDimensions = text.getBoundingClientRect();

  // The text will slowly transit for this distance, currently 50% of the text
  // width.
  const textHangDistance = textDimensions.width / 2;
  // The text will "stop warping" at this point on the screen. Currently
  // configured to the center of the screen + 25% of the text width. This means
  // the text finishes its enter to the right of center, and starts its exit to
  // the left of center.
  const textEntranceDestinationX =
    fieldDimensions.width / 2 + 0.25 * textDimensions.width;

  const entranceMs = 0.1 * totalDurationMs;
  const hangMs = 0.8 * totalDurationMs;
  const exitMs = 0.1 * totalDurationMs;

  const a: Animation[] = [];

  // NOTE: `offset: (entranceMs + hangMs + exitMs) / totalDurationMs,` as the
  // final offset of 1 is overkill, but it helps to make it explicit how the
  // math/timing works out.

  a.push(
    strip.animate(
      [
        {
          ...initialCSSProps.strip,
        },
        {
          transform: xform({ translateX: '0' }),
          offset: entranceMs / totalDurationMs,
        },
        {
          transform: xform({ translateX: '0' }),
          offset: (entranceMs + hangMs) / totalDurationMs,
        },
        {
          transform: xform({ translateX: '100%' }),
          offset: (entranceMs + hangMs + exitMs) / totalDurationMs,
        },
      ],
      { duration: totalDurationMs, fill: 'both', easing: 'linear' }
    )
  );

  a.push(
    text.animate(
      [
        {
          ...initialCSSProps.text,
          transform: xform({
            translateX: `${fieldDimensions.width + textHangDistance}px`,
          }),
        },
        {
          transform: xform({ translateX: `${textEntranceDestinationX}px` }),
          offset: entranceMs / totalDurationMs,
        },
        {
          transform: xform({
            translateX: `${textEntranceDestinationX - textHangDistance}px`,
          }),
          offset: (entranceMs + hangMs) / totalDurationMs,
        },
        {
          transform: xform({ translateX: `${textHangDistance * -1}px` }),
          offset: (entranceMs + hangMs + exitMs) / totalDurationMs,
        },
      ],
      { duration: totalDurationMs, fill: 'both', easing: 'linear' }
    )
  );

  a.push(
    rect001.animate(
      [
        { ...initialCSSProps['bg-rect-001'] },
        {
          transform: xform({ rotateA: '16deg', translateX: '-23%' }),
          offset: entranceMs / totalDurationMs,
        },
        {
          transform: xform({ rotateA: '16deg', translateX: '100%' }),
          offset: (entranceMs + hangMs + exitMs) / totalDurationMs,
        },
      ],
      { duration: totalDurationMs, fill: 'both', easing: 'linear' }
    )
  );

  a.push(
    rect002.animate(
      [
        { ...initialCSSProps['bg-rect-002'] },
        {
          transform: xform({ rotateA: '16deg', translateX: '-26%' }),
          offset: entranceMs / totalDurationMs,
        },
        {
          transform: xform({ rotateA: '16deg', translateX: '100%' }),
          offset: (entranceMs + hangMs + exitMs) / totalDurationMs,
        },
      ],
      { duration: totalDurationMs, fill: 'both', easing: 'linear' }
    )
  );

  a.push(
    rect003.animate(
      [
        { ...initialCSSProps['bg-rect-003'] },
        {
          transform: xform({ rotateA: '16deg', translateX: '-73%' }),
          offset: entranceMs / totalDurationMs,
        },
        {
          transform: xform({ rotateA: '16deg', translateX: '100%' }),
          offset: (entranceMs + hangMs + exitMs) / totalDurationMs,
        },
      ],
      { duration: totalDurationMs, fill: 'both', easing: 'linear' }
    )
  );

  playAnims(...a);
  yield Promise.all(a.map((a) => a.finished));
  a.length = 0;
}

export function GamePlayStageFinishedTransition(props: {
  id: string | null;
  text: ReactNode;
  transitionInfo: TransitionInfo;
  accessory?: React.ReactNode;
  onEnd?: (() => Promise<void>) | null;
  debugName?: string;
}): JSX.Element | null {
  const { multiRef, renderNothing } = useAnimationExecutorRunner(
    props.id,
    props.transitionInfo,
    StageFinishedAnimation,
    props.onEnd ?? null,
    props.debugName ?? 'stage-finished'
  );

  if (renderNothing) return null;

  return (
    <GamePlayFullScreenTransitionContainer>
      {props.accessory ?? null}
      <div
        ref={(el) => multiRef('mask', el)}
        className='w-full h-22 relative overflow-hidden'
        style={{ ...initialCSSProps['mask'] }}
      >
        <div
          ref={(el) => multiRef('bg-rect-001', el)}
          className={`
            absolute left-1/2 top-1/2
            transform3d
            -translate-post-x-1/2 -translate-post-y-1/2 rotate-3d-z-1
            w-[107%] h-[1350%]
            bg-[rgba(57,217,102,0.6)]
          `}
          style={{ ...initialCSSProps['bg-rect-001'] }}
          aria-hidden
        ></div>
        <div
          ref={(el) => multiRef('bg-rect-002', el)}
          className={`
            absolute left-1/2 top-1/2
            transform3d
            -translate-post-x-1/2 -translate-post-y-1/2 rotate-3d-z-1
            w-[107%] h-[1350%]
            bg-[rgba(25,154,108,0.8)]
          `}
          style={{ ...initialCSSProps['bg-rect-002'] }}
          aria-hidden
        ></div>

        <div
          ref={(el) => multiRef('bg-rect-003', el)}
          className={`
            absolute left-1/2 top-1/2
            transform3d
            -translate-post-x-1/2 -translate-post-y-1/2 rotate-3d-z-1
            w-[107%] h-[1350%]
            bg-[rgba(22,106,117,0.6)]
          `}
          style={{ ...initialCSSProps['bg-rect-003'] }}
          aria-hidden
        ></div>

        {/* Note: the strip needs to "mask" the rectangles, so it must cover them (be later in the DOM) */}
        <div
          ref={(el) => multiRef('strip', el)}
          className={`w-full h-full relative transform3d`}
          style={{
            background: `linear-gradient(
              90deg,
              rgba(55, 172, 88, 0) 0%,
              rgba(55, 172, 88, 0.8) 27.08%,
              rgba(55, 172, 88, 0.8) 73.44%,
              rgba(55, 172, 88, 0) 100%
            )`,
            textShadow: `4px 4px 0px #000000`,
            ...initialCSSProps.strip,
          }}
        ></div>

        <div
          ref={(el) => multiRef('text', el)}
          className='
            absolute top-0
            transform3d
            -translate-post-x-1/2
            h-full
            flex justify-center items-center
            text-5xl italic font-bold text-white
            filter drop-shadow-lp-hard-cutout
          '
          style={{ ...initialCSSProps['text'] }}
        >
          {props.text}
        </div>
      </div>
    </GamePlayFullScreenTransitionContainer>
  );
}

export function GamePlayAllTeamsFinishedTransition(): JSX.Element | null {
  const sessionBlockId = useGameSessionBlock()?.id ?? null;
  const transitionInfo = useAllTeamsFinishedAnimInfo();
  const onEnd = useIsController() ? endAllTeamsFinishedAnim : null;

  return (
    <GamePlayStageFinishedTransition
      id={sessionBlockId}
      text='ALL TEAMS FINISHED'
      transitionInfo={transitionInfo}
      onEnd={onEnd}
      debugName='all-teams-finished'
    />
  );
}
