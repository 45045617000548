import {
  createContext,
  type ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { GameSessionUtil } from '@lp-lib/game';

import {
  useCurrentBlockScore,
  useGameSessionBlock,
  useGameSessionStatus,
  useTeamScore,
} from '../Game/hooks';
import { useMyTeamId } from '../Player';
import {
  useIsTeamRecoveryRunning,
  useIsTeamStoreInited,
} from '../TeamAPI/TeamV1';

type PPContext = {
  teamPoints: number;
  nextTeamPoints: number | null;
  safeToReveal: boolean;
  setSafeToReveal: React.Dispatch<React.SetStateAction<boolean>>;
  revealingAnswer: boolean;
  setRevealingAnswer: React.Dispatch<React.SetStateAction<boolean>>;
  currentBlockIsExcluded: boolean;
  setOverriddenPoints: React.Dispatch<React.SetStateAction<number | null>>;
  setPointsBadgeScale: React.Dispatch<React.SetStateAction<number | null>>;
  pointsBadgeScale: number | null;
};

const context = createContext<PPContext>({} as PPContext);

export function usePersistentPoints(): PPContext {
  const ctx = useContext(context);
  return ctx;
}

export function usePersistentPointsScore(): number {
  const ctx = useContext(context);
  const teamId = useMyTeamId();
  if (teamId) return ctx.teamPoints;
  return 0;
}

export function usePersistentPointsBadgeScale(): number | null {
  const ctx = useContext(context);
  return ctx.pointsBadgeScale;
}

/**
 * @returns The number of points if the user/team submitted an answer, or null
 * if the user/team did not submit an answer
 */
export function useNextPersistentPointsScore(): number | null {
  const ctx = useContext(context);
  const teamId = useMyTeamId();
  if (teamId) return ctx.nextTeamPoints;
  return null;
}

export function useSyncPersistentPointsRevealAnswer(
  revealing: boolean
): boolean {
  const ctx = useContext(context);
  const { setRevealingAnswer, revealingAnswer: initialRevealing } = ctx;

  useEffect(() => {
    return () => {
      // Always return to false when unmounted
      setRevealingAnswer(false);
    };
  }, [setRevealingAnswer]);

  useEffect(() => {
    // Ensure we're in sync
    if (initialRevealing !== revealing) setRevealingAnswer(revealing);
  }, [initialRevealing, revealing, setRevealingAnswer]);

  return ctx.revealingAnswer;
}

export const PersistentPointsProvider = (props: {
  userId: null | string;
  children?: ReactNode;
}): JSX.Element => {
  const teamId = useMyTeamId();
  const teamsReady = useIsTeamStoreInited();
  const teamsRecoveryRunning = useIsTeamRecoveryRunning();
  const gameSessionBlock = useGameSessionBlock();
  const gameSessionStatus = useGameSessionStatus();
  const [teamPoints, setTeamPoints] = useState(0);
  const [nextTeamPoints, setNextTeamPoints] = useState<number | null>(null);
  const [overriddenPoints, setOverriddenPoints] = useState<number | null>(null);
  const [pointsBadgeScale, setPointsBadgeScale] = useState<number | null>(null);

  const [currentBlockIsExcluded, excludeCurrentBlock] = useState(true);
  const [revealingAnswer, setRevealingAnswer] = useState(false);
  const [safeToReveal, setSafeToReveal] = useState(false);
  const myCurrentBlockTeamScore = useCurrentBlockScore(teamId);
  const myTeamScoreWithoutCurrentBlock = useTeamScore(teamId, true);
  const myTeamScore = useTeamScore(teamId);

  useEffect(() => {
    // The points badge should not show the currently scored answer!
    if (!gameSessionBlock) return;
    const blockType = gameSessionBlock.type;

    const map = GameSessionUtil.StatusMapFor(blockType);
    if (!map) return;
    switch (gameSessionStatus) {
      case map.loaded:
      case map.end:
      case map.scoreboard: {
        excludeCurrentBlock(false);
        break;
      }
      default:
        if (safeToReveal) {
          excludeCurrentBlock(false);
        } else if (!currentBlockIsExcluded) {
          excludeCurrentBlock(true);
        }
        break;
    }
  }, [
    currentBlockIsExcluded,
    gameSessionBlock,
    gameSessionStatus,
    safeToReveal,
  ]);

  useEffect(() => {
    // Always track what the "next" score will be, as best as we know.
    setNextTeamPoints(
      myCurrentBlockTeamScore ? Math.round(myCurrentBlockTeamScore) : null
    );

    if (overriddenPoints !== null) {
      setTeamPoints(Math.round(overriddenPoints));
    } else if (currentBlockIsExcluded) {
      setTeamPoints(Math.round(myTeamScoreWithoutCurrentBlock ?? 0));
    } else {
      setTeamPoints(Math.round(myTeamScore ?? 0));
    }

    // Remove existing score if no team
    if (!teamId || !teamsReady || teamsRecoveryRunning) setTeamPoints(0);
  }, [
    currentBlockIsExcluded,
    myCurrentBlockTeamScore,
    myTeamScore,
    myTeamScoreWithoutCurrentBlock,
    overriddenPoints,
    teamId,
    teamsReady,
    teamsRecoveryRunning,
  ]);

  const value: PPContext = useMemo(
    () => ({
      teamPoints,
      nextTeamPoints,
      currentBlockIsExcluded,
      safeToReveal,
      setSafeToReveal,
      revealingAnswer,
      setRevealingAnswer,
      setOverriddenPoints,
      pointsBadgeScale,
      setPointsBadgeScale,
    }),
    [
      currentBlockIsExcluded,
      nextTeamPoints,
      pointsBadgeScale,
      revealingAnswer,
      safeToReveal,
      teamPoints,
    ]
  );

  return <context.Provider value={value}>{props.children}</context.Provider>;
};
