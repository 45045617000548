import React, {
  type ReactNode,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import { proxy } from 'valtio';

import { ConnectionStatus } from '@lp-lib/shared-schema';

import { useIsCoordinator } from '../../hooks/useMyInstance';
import { FBPathUtils } from '../../store/utils';
import {
  ClientType,
  hasDebugFlag,
  type MemberId,
  type Participant,
  type ParticipantFlags,
  type ParticipantFull,
  type Team,
  type TeamId,
  type TeamMember,
} from '../../types';
import { randomPick, uuidv4 } from '../../utils/common';
import { InfiniteGenerator } from '../../utils/generator';
import { rsCounter } from '../../utils/rstats.client';
import { uncheckedIndexAccess_UNSAFE } from '../../utils/uncheckedIndexAccess_UNSAFE';
import {
  markSnapshottable,
  useSnapshot,
  type ValtioSnapshottable,
} from '../../utils/valtio';
import { type FirebaseService, useFirebaseContext } from '../Firebase';
import {
  useSwitchTeamTownhallMode,
  useTeamMembersGetter,
  useTeamsGetter,
} from '../TeamAPI/TeamV1';
import { useTownhallConfig } from '../Townhall';
import { useUser } from '../UserContext';
import { useVenueId } from '../Venue/VenueProvider';
import { type Bot } from './types';
import { BOT_VIDEOS, FEMALE_NAMES, MALE_NAMES } from './utils';

export type State = {
  bots: Bot[];
};

class BotAPI {
  private userAgent = 'Luna Park Bot/1.0';
  private _state;
  constructor(
    readonly venueId: string,
    readonly svc: FirebaseService,
    private maxTeamSize = 8,
    private pRef = svc.ref<Record<string, Participant>>(
      FBPathUtils.ForParticipants(svc, venueId)
    ),
    private tRef = svc.ref<Record<TeamId, Team>>(
      FBPathUtils.ForTeams(svc, venueId)
    ),
    private mRef = svc.safeRef<Record<TeamId, Record<MemberId, TeamMember>>>(
      FBPathUtils.ForTeamMembers(svc, venueId)
    )
  ) {
    this._state = markSnapshottable(proxy<State>(this.initialState()));
  }

  get state(): Readonly<ValtioSnapshottable<State>> {
    return this._state;
  }

  participantIsBot(p: Participant): boolean {
    return p.userAgent === this.userAgent;
  }

  /**
   * demo mode is designed for sales call, we don't store them in participant
   * store, but a dedicated store. This is to avoid the logic that requires
   * participants interactions. Some examples,
   *  Instruction Block - the playback system waits all participants to be ready
   *  Round Robin Block - participants play in a round robin fashion
   * @param mode 'default' | 'demo'
   */
  async generate(
    mode: 'default' | 'demo',
    num: number,
    maxTeamSize?: number,
    joinOrCreateTeam?: boolean
  ): Promise<void> {
    switch (mode) {
      case 'default':
        await this.generateForDefault(num, maxTeamSize, joinOrCreateTeam);
        break;
      case 'demo':
        await this.generateForDemo(num);
        break;
      default:
        break;
    }
  }

  private async generateForDefault(
    num: number,
    maxTeamSize?: number,
    joinOrCreateTeam?: boolean
  ) {
    for (let i = 0; i < num; i++) {
      const clientId = uuidv4();
      const username = randomPick(MALE_NAMES);
      const teamId = joinOrCreateTeam
        ? await this.nextTeamId(clientId, username, maxTeamSize)
        : undefined;
      const participant: ParticipantFull = {
        id: uuidv4(),
        clientId,
        clientType: ClientType.Audience,
        username,
        joinedAt: Date.now(),
        status: ConnectionStatus.Connected,
        userAgent: this.userAgent,
        video: false,
        audio: false,
        teamId,
      };
      rsCounter(`bot-join-${participant.clientId}-ms`)?.start();
      const ref = this.pRef.child(participant.clientId);
      await ref.set(participant);
      if (teamId) await this.joinTeam(teamId, participant.clientId);
    }
  }

  private async generateForDemo(num: number) {
    const generator = new InfiniteGenerator(BOT_VIDEOS, 'sequential');
    for (let i = 0; i < num; i++) {
      const generated = generator.generate();
      this._state.bots.push({
        id: uuidv4(),
        username: randomPick(
          generated.gender === 'male' ? MALE_NAMES : FEMALE_NAMES
        ),
        createdAt: Date.now(),
        video: generated.video,
      });
    }
  }

  private async nextTeamId(
    clientId: string,
    username: string,
    maxTeamSize?: number
  ): Promise<TeamId> {
    const teamMembersMap = (await this.mRef.get()).val();
    if (teamMembersMap) {
      for (const [teamId, membersMap] of Object.entries(teamMembersMap)) {
        if (
          Object.values(membersMap).length < (maxTeamSize ?? this.maxTeamSize)
        ) {
          return teamId;
        }
      }
    }
    const team: Team = {
      id: Date.now().toString(),
      name: `${username}'s Team`,
      createdAt: Date.now(),
      captainScribe: clientId,
      isCohostTeam: false,
    };
    await this.tRef.child(team.id).set(team);
    return team.id;
  }

  private async joinTeam(teamId: TeamId, memberId: MemberId): Promise<void> {
    const member: TeamMember = {
      id: memberId,
      joinedAt: Date.now(),
    };
    const path = FBPathUtils.ForTeamMembers(this.svc, this.venueId, {
      teamId: teamId,
      memberId: memberId,
    });
    await this.svc
      .database()
      .ref()
      .update({
        [path]: member,
      });
  }

  async clear(): Promise<void> {
    await this.clearForDemo();
    await this.clearForDefault();
  }

  async getBots() {
    const participantsMap = (await this.pRef.get()).val();
    if (!participantsMap) return [];
    const bots = [];
    for (const participant of Object.values(participantsMap)) {
      if (!participant || !participant.clientId) continue;
      if (!this.participantIsBot(participant)) continue;
      bots.push(participant);
    }
    return bots;
  }

  async update(clientId: string, updates: Partial<ParticipantFull>) {
    const ref = this.pRef.child(clientId);
    await ref.update(updates);
  }

  private async clearForDefault() {
    const bots = await this.getBots();
    const updates = uncheckedIndexAccess_UNSAFE({});
    for (const participant of bots) {
      const { teamId, clientId } = participant;
      if (teamId) {
        updates[
          FBPathUtils.ForTeamMembers(this.svc, this.venueId, {
            teamId: teamId,
            memberId: clientId,
          })
        ] = null;
      }
      updates[FBPathUtils.ForParticipants(this.svc, this.venueId, clientId)] =
        null;
    }
    if (Object.keys(updates).length > 0) {
      await this.svc.database().ref().update(updates);
    }
    const teamsMap = (await this.tRef.get()).val();
    const teamMembersMap = (await this.mRef.get()).val();
    if (!teamsMap) return;
    const teamUpdates = uncheckedIndexAccess_UNSAFE({});
    for (const teamId of Object.keys(teamsMap)) {
      const members = teamMembersMap?.[teamId] ?? {};
      if (Object.values(members).length === 0) {
        teamUpdates[FBPathUtils.ForTeams(this.svc, this.venueId, teamId)] =
          null;
      }
    }
    if (Object.keys(teamUpdates).length > 0) {
      await this.svc.database().ref().update(teamUpdates);
    }
  }

  private async clearForDemo() {
    while (this._state.bots.length > 0) {
      this._state.bots.pop();
    }
  }

  private initialState(): State {
    return {
      bots: [],
    };
  }
}

type Context = {
  api: BotAPI;
};

const context = React.createContext<Context | null>(null);

function useBotContext(): Context {
  const ctx = useContext(context);
  if (!ctx) throw new Error('BotContext is not in the tree!');
  return ctx;
}

export function useBotAPI(): Context['api'] {
  return useBotContext().api;
}

export function useBots(): Bot[] {
  const api = useBotAPI();
  return useSnapshot(api.state).bots as Bot[];
}

export function useParticipantIsBot() {
  const api = useBotAPI();
  return useMemo(() => api.participantIsBot.bind(api), [api]);
}

export function useBotFakeParticipant(bot: Bot): Participant {
  return useMemo<Participant>(() => {
    return {
      id: bot.id,
      clientId: bot.id,
      clientType: ClientType.Audience,
      username: bot.username,
      joinedAt: bot.createdAt,
    };
  }, [bot]);
}

export function useBotFakeParticipantFlags(): ParticipantFlags {
  return useMemo<ParticipantFlags>(() => {
    return {
      hasCamera: true,
      hasMicrophone: true,
    };
  }, []);
}

export function useAutoActivateBots() {
  const me = useUser();
  const generator = useBotAPI();
  const enabled = useIsCoordinator() && hasDebugFlag(me);
  const activated = useRef(false);
  useEffect(() => {
    if (!enabled || activated.current) return;
    generator.generate('demo', 4);
    activated.current = true;
  }, [generator, enabled]);
}

function Bootstrap(): JSX.Element | null {
  useAutoActivateBots();
  return null;
}

function BotTownhallSwitcher() {
  const amICoordinator = useIsCoordinator();
  if (!amICoordinator) return null;
  return <BotTownhallSwitcherInternal />;
}

function BotTownhallSwitcherInternal() {
  const api = useBotAPI();
  const { enabled, mode } = useTownhallConfig();
  const switchTeamTownhallMode = useSwitchTeamTownhallMode();
  const getTeams = useTeamsGetter();
  const getTeamMembers = useTeamMembersGetter();
  const isRunning = useRef(false);

  useEffect(() => {
    async function run() {
      if (!enabled) return;
      const bots = await api.getBots();
      if (bots.length === 0) return;

      const teams = getTeams();
      for (const team of teams) {
        const members = getTeamMembers(team.id);
        if (!members || members.length === 0) continue;
        const isBotTeam = members.every((member) => {
          return bots.find((b) => b.clientId === member.id) !== undefined;
        });
        if (isBotTeam) {
          switchTeamTownhallMode(team.id, mode);
        }
      }
    }

    try {
      if (!isRunning.current) {
        isRunning.current = true;
        run();
      }
    } finally {
      isRunning.current = false;
    }
  }, [api, enabled, getTeamMembers, getTeams, mode, switchTeamTownhallMode]);
  return null;
}

export function BotProvider(props: {
  children?: ReactNode;
}): JSX.Element | null {
  const venueId = useVenueId();
  const { svc } = useFirebaseContext();

  const ctx = useMemo(() => {
    return {
      api: new BotAPI(venueId, svc),
    };
  }, [svc, venueId]);

  return (
    <context.Provider value={ctx}>
      <Bootstrap />
      <BotTownhallSwitcher />
      {props.children}
    </context.Provider>
  );
}
