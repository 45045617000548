import axios from 'axios';
import { useMemo } from 'react';
import useSWR from 'swr';

import { apiService } from '../../../services/api-service';
import { HttpStatusCode, type OrgConnection } from '../../../types';

export interface UseOrgConnectionReturned {
  connection?: OrgConnection | null;
  isValidating: boolean;
  isLoading: boolean;
  error: Error;
  refresh: () => void;
}

export function useOrgConnection(props: {
  orgId: string;
  immutable?: boolean;
}): UseOrgConnectionReturned {
  const immutable = props.immutable ?? false;
  const { data, error, isValidating, mutate } = useSWR(
    [`/organizations/${props.orgId}/connection`, immutable],
    async () => {
      try {
        const resp = await apiService.organization.getOrgConnection(
          props.orgId
        );
        return resp.data.connection;
      } catch (error) {
        if (axios.isAxiosError(error)) {
          if (error.response?.status === HttpStatusCode.NOT_FOUND) return null;
          throw error;
        } else {
          throw error;
        }
      }
    },
    immutable
      ? {
          revalidateIfStale: false,
          revalidateOnFocus: false,
          revalidateOnReconnect: false,
        }
      : undefined
  );

  return useMemo(
    () => ({
      connection: data,
      isValidating,
      isLoading: data === undefined && isValidating,
      error,
      refresh: mutate,
    }),
    [data, error, isValidating, mutate]
  );
}
