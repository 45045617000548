import { Fragment, type ReactNode, useEffect, useMemo } from 'react';

import { EnumsTTSRenderPolicy } from '@lp-lib/api-service-client/public';
import {
  type HeadToHeadBlock,
  HeadToHeadBlockGameSessionStatus,
} from '@lp-lib/game';

import lunaParkLogo from '../../../../assets/img/luna-park-logo.png';
import { useLiveCallback } from '../../../../hooks/useLiveCallback';
import { useIsController } from '../../../../hooks/useMyInstance';
import { fromMediaDataDTO, fromMediaDTO } from '../../../../utils/api-dto';
import { MediaUtils } from '../../../../utils/media';
import { useGameSessionStatus } from '../../hooks';
import { useGamePlayEmitter } from '../Common/GamePlay/GamePlayProvider';
import {
  buildGamePlayMedia,
  type GamePlayMedia,
  GamePlayMediaPlayer,
} from '../Common/GamePlay/Internal';
import {
  useCurrentCardVideoMediaAsset,
  useCurrentHeadToHeadGameCard,
  useHeadToHeadGamePlayAPI,
  useHeadToHeadGameProgress,
  useMyCurrentCardPrompt,
  useMyCurrentHeadToHeadRole,
  useMyNextCardPrompt,
  useNextHeadToHeadGameCard,
} from './HeadToHeadBlockProvider';
import { type H2HGamePlayCard, type H2HGamePlayCardPrompt } from './types';
import { HeadToHeadUtils } from './utils';

function CardContainer(props: {
  children?: ReactNode;
  className?: string;
  style?: React.CSSProperties;
}) {
  return (
    <div
      className={`w-full border border-white border-opacity-40 rounded-1.5lg overflow-hidden ${props.className}`}
      style={{
        aspectRatio: '16/9',
        boxShadow: '0px 4px 12px 0px rgba(0, 0, 0, 0.25)',
        ...props.style,
      }}
    >
      {props.children}
    </div>
  );
}

function CardUnavailable() {
  return (
    <CardContainer>
      <div className='w-full h-full flex items-center justify-center text-sms text-white bg-black'>
        No More Cards
      </div>
    </CardContainer>
  );
}

function CardVisible(props: {
  block: HeadToHeadBlock;
  card: H2HGamePlayCard;
  prompt: H2HGamePlayCardPrompt;
  mediaPlayer?: (mediaId: string | undefined) => ReactNode;
}) {
  const { block, card, prompt } = props;
  const role = useMyCurrentHeadToHeadRole(block);
  const visibility = HeadToHeadUtils.WhoCanNotSee(prompt, role);

  const { media } = useMemo(
    () => HeadToHeadUtils.LookupCardMediaAssets(block, prompt?.mediaId),
    [block, prompt?.mediaId]
  );
  const mediaUrl = MediaUtils.PickMediaUrl(media);

  const api = useHeadToHeadGamePlayAPI();
  useEffect(() => {
    api.playCardVOLocally(block, card.id, prompt);
  }, [block, card.id, api, prompt]);

  const text = prompt.text.trim();

  return (
    <CardContainer
      style={{
        aspectRatio: '16/9',
        background: !mediaUrl
          ? 'linear-gradient(156deg, #F6F6F6 18.43%, #D9D9D9 99.17%)'
          : undefined,
      }}
    >
      <div
        className={`w-full h-full relative ${
          !!mediaUrl ? 'text-white bg-black' : 'text-black'
        }`}
      >
        {!!text && (
          <div className='w-full h-full flex items-center justify-center left-0 top-0 absolute z-35'>
            {text}
          </div>
        )}
        <div className='w-full absolute bottom-4 flex items-center justify-center z-35'>
          {visibility ?? `(${visibility})`}
        </div>
        {mediaUrl && !!text && (
          <div className='w-full h-full bg-black bg-opacity-40 absolute z-[31]'></div>
        )}
        {props.mediaPlayer?.(prompt.mediaId)}
      </div>
    </CardContainer>
  );
}

function CardInvisible(props: {
  block: HeadToHeadBlock;
  card: H2HGamePlayCard;
  prompt: H2HGamePlayCardPrompt;
  revealed?: boolean;
}) {
  const { block, prompt, revealed } = props;
  const role = useMyCurrentHeadToHeadRole(block);
  const visibility = HeadToHeadUtils.WhoCanSee(prompt, role);

  return (
    <CardContainer
      style={{
        background: 'linear-gradient(180deg, #3A3A3A 0.6%, #1C1A1B 100%)',
      }}
    >
      <div className='w-full h-full relative'>
        {revealed && (
          <div className='w-full h-full flex items-center justify-center text-white text-base font-bold'>
            {visibility ?? `(${visibility})`}
          </div>
        )}
        <img
          src={lunaParkLogo}
          alt='Luna Park Logo'
          className={`w-1/3 absolute left-1/2 transform -translate-x-1/2 ${
            revealed ? 'bottom-4' : 'top-1/2 -translate-y-1/2'
          }`}
        />
      </div>
    </CardContainer>
  );
}

function usePrepareNextCardVO(block: HeadToHeadBlock) {
  const nextCard = useNextHeadToHeadGameCard();
  const nextPrompt = useMyNextCardPrompt(block);
  const api = useHeadToHeadGamePlayAPI();
  const role = useMyCurrentHeadToHeadRole(block);
  const process = useHeadToHeadGameProgress();

  // for the first voice cover, it's very likely to be cache miss. I tried to
  // avoid the magic time control, but using the read-through policy for the on
  // stage players. This is not perfect, but a good trade-off.
  const policy =
    process.currentCardIndex === 0 && role !== 'audience'
      ? EnumsTTSRenderPolicy.TTSRenderPolicyReadThrough
      : EnumsTTSRenderPolicy.TTSRenderPolicyReadCacheOnly;

  useEffect(() => {
    if (!nextCard?.id) return;
    api.prepareCardVOLocally(block, nextCard.id, nextPrompt, policy);
  }, [api, block, nextCard?.id, nextPrompt, policy]);
}

function GameCardInternal(props: {
  block: HeadToHeadBlock;
  mediaPlayer?: (mediaId?: string) => ReactNode;
}) {
  const { block } = props;
  const card = useCurrentHeadToHeadGameCard();
  const prompt = useMyCurrentCardPrompt(block);
  const role = useMyCurrentHeadToHeadRole(block);
  const progress = useHeadToHeadGameProgress();
  usePrepareNextCardVO(block);
  const turnsMode = HeadToHeadUtils.TurnsMode(block);

  const visible = useMemo(() => {
    if (prompt.visibility.includes('self')) return true;
    if (!turnsMode) return prompt.visibility.includes(role);
    if (role === 'audience') {
      return prompt.visibility.includes('audience');
    }
    if (role === progress.currentTurn) {
      return prompt.visibility.includes('groupA');
    } else {
      return prompt.visibility.includes('groupB');
    }
  }, [progress.currentTurn, prompt.visibility, role, turnsMode]);

  if (!card) return <CardUnavailable />;

  switch (progress.currentCardPhase) {
    case 'ready':
      return <CardInvisible block={block} card={card} prompt={prompt} />;
    case 'revealed':
      if (visible) {
        return (
          <CardVisible
            block={block}
            card={card}
            prompt={prompt}
            mediaPlayer={props.mediaPlayer}
          />
        );
      }
      return (
        <CardInvisible block={block} card={card} prompt={prompt} revealed />
      );
    default:
      break;
  }
}

// This is a hack solution to make sure the AgoraLocalStream can be initialize
// on the game controller side in all cases.
// * Live Game
// * Ond Game + Local Controller
// * Ond Game + Cloud Controller
function useForceRenderVideoPlayer(
  block: HeadToHeadBlock,
  gamePlayMedia: GamePlayMedia | null
) {
  const isController = useIsController();
  const prompt = useMyCurrentCardPrompt(block);
  const role = useMyCurrentHeadToHeadRole(block);

  if (!gamePlayMedia || !isController) return false;

  const visible =
    prompt.visibility.includes(role) || prompt.visibility.includes('self');
  // this usually means the game is played by local controller and the video
  // is visible to the local controller (player), so we don't need to force render another video player again
  if (gamePlayMedia.id === prompt?.mediaId && visible) return false;

  // if the controller can not see the video player, we need to force render it
  // to make sure Agora can work.
  return true;
}

function GameCardWithAgoraVideo(props: { block: HeadToHeadBlock }) {
  const { block } = props;
  const card = useCurrentHeadToHeadGameCard();
  const progress = useHeadToHeadGameProgress();
  const cardVideoMediaAsset = useCurrentCardVideoMediaAsset(block);
  const gss = useGameSessionStatus<HeadToHeadBlockGameSessionStatus>();
  const emitter = useGamePlayEmitter();

  const isIntro = useMemo(
    () => gss === HeadToHeadBlockGameSessionStatus.GAME_INTRO,
    [gss]
  );

  const gamePlayMedia = useMemo(() => {
    let mediaAsset = cardVideoMediaAsset;
    if (isIntro) {
      mediaAsset = block.fields.introMedia
        ? {
            media: fromMediaDTO(block.fields.introMedia.media),
            mediaData: fromMediaDataDTO(block.fields.introMedia.data),
          }
        : undefined;
    }

    return buildGamePlayMedia(
      {
        media: mediaAsset?.media ?? null,
        mediaData: mediaAsset?.mediaData ?? null,
      },
      {
        stage: 'custom',
      }
    );
  }, [cardVideoMediaAsset, isIntro, block.fields.introMedia]);

  const mediaPlayable = useMemo(
    () => isIntro || (!!card?.id && progress.currentCardPhase === 'revealed'),
    [isIntro, card?.id, progress.currentCardPhase]
  );

  const forceRenderVideoPlayer = useForceRenderVideoPlayer(
    block,
    gamePlayMedia
  );

  const onMediaEnded = useLiveCallback(() => {
    if (gss === HeadToHeadBlockGameSessionStatus.GAME_INTRO) {
      emitter.emit('h2h-intro-media-ended', block.id);
    }
  });

  const videoPlayer = (
    <GamePlayMediaPlayer
      gamePlayMedia={gamePlayMedia}
      play={mediaPlayable}
      onMediaEnded={onMediaEnded}
      layout='anchored'
    />
  );

  return (
    <Fragment>
      {isIntro && gamePlayMedia ? (
        <CardContainer
          style={{
            aspectRatio: '16/9',
          }}
        >
          <div className='w-full h-full relative text-white bg-black'>
            {videoPlayer}
          </div>
        </CardContainer>
      ) : (
        <GameCardInternal
          block={block}
          mediaPlayer={(mediaId) => {
            if (!mediaId) return null;
            if (mediaId === cardVideoMediaAsset?.mediaData?.id) {
              return videoPlayer;
            } else {
              const { media } = HeadToHeadUtils.LookupCardMediaAssets(
                block,
                mediaId
              );
              const mediaUrl = MediaUtils.PickMediaUrl(media);
              // this could be only image
              if (mediaUrl) {
                return (
                  <img
                    src={mediaUrl}
                    alt='card'
                    className='w-full h-full left-0 top-0 absolute object-cover z-0'
                  />
                );
              }
            }
          }}
        />
      )}
      {forceRenderVideoPlayer && (
        <CardContainer className='absolute -z-1'>{videoPlayer}</CardContainer>
      )}
    </Fragment>
  );
}

export { GameCardWithAgoraVideo as GameCard };
