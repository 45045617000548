import { useMemo } from 'react';
import { proxy } from 'valtio';

import { type InstructionBlock, type InstructionCard } from '@lp-lib/game';

import { useFeatureQueryParam } from '../../../../hooks/useFeatureQueryParam';
import { markSnapshottable, ValtioUtils } from '../../../../utils/valtio';
import { useCurrentSessionMode } from '../../hooks';
import { usePlaybackDesc } from '../../Playback/PlaybackInfoProvider';
import { InstructionBlockUtils, log } from './utils';

type InstructionBlockGamePlayState = {
  cards: InstructionCard[];
  currentCard: InstructionCard | null;
  currentCardIndex: number;
};

class InstructionBlockGamePlayStore {
  private readonly _state;

  constructor(private readonly cards: InstructionCard[]) {
    this._state = markSnapshottable(
      proxy<InstructionBlockGamePlayState>(this.initialState())
    );
  }

  get state() {
    return this._state;
  }

  nextCard() {
    const currentCardIndex = this._state.currentCardIndex;
    const numCards = this._state.cards.length;

    log.debug('switch to next card', {
      currentCardIndex,
    });

    const nextCardIndex = (currentCardIndex + 1) % numCards;
    this._state.currentCardIndex = nextCardIndex;
    this._state.currentCard = this._state.cards[nextCardIndex];
  }

  showCard(index: number) {
    log.debug('switch to card', { index });

    const currentCardIndex = this._state.currentCardIndex;
    const numCards = this._state.cards.length;

    if (index < 0 || index >= numCards || index === currentCardIndex) return;
    this._state.currentCardIndex = index;
    this._state.currentCard = this._state.cards[index];
  }

  reset() {
    ValtioUtils.reset(this._state, this.initialState());
  }

  private initialState(): InstructionBlockGamePlayState {
    return {
      cards: this.cards,
      currentCardIndex: 0,
      currentCard: this.cards[0] ?? null,
    };
  }
}

export function useInstructionBlockGamePlayStore(
  block: InstructionBlock
): InstructionBlockGamePlayStore {
  return useMemo(() => {
    const cards = InstructionBlockUtils.GetValidCards(block);
    return new InstructionBlockGamePlayStore(cards);
  }, [block]);
}

export function useInstructionBlockV2() {
  const mode = useCurrentSessionMode();
  const v2EnabledByParam = useFeatureQueryParam('instruction-block-v2');
  const playbackDesc = usePlaybackDesc(mode);

  return v2EnabledByParam
    ? true
    : playbackDesc === null
    ? null
    : playbackDesc.instructionBlockVersion === 2;
}
