import {
  createContext,
  type ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react';
import { usePrevious } from 'react-use';

import { useExperienceAnalytics } from '../../analytics/experience';
import { useInstance } from '../../hooks/useInstance';
import { useIsController } from '../../hooks/useMyInstance';
import { useUnload } from '../../hooks/useUnload';
import { SessionStatus } from '../../types';
import { BrowserIntervalCtrl } from '../../utils/BrowserIntervalCtrl';
import { useFirebaseContext } from '../Firebase';
import { useStreamSessionId, useStreamSessionStatus } from '../Session';
import { useMyClientId } from '../Venue/VenuePlaygroundProvider';
import {
  ExperienceMetricsManager,
  type ExperienceMetricsManagerExt,
} from './ExperienceMetricsManager';

const Context = createContext<ExperienceMetricsManagerExt | null>(null);

const DEFAULT_WINDOW_MS = 60000;

export function ExperienceMetricsProvider(props: {
  isReady: boolean;
  children?: ReactNode[];
}): JSX.Element {
  const targetWindowMs = DEFAULT_WINDOW_MS;
  const expAnalytics = useExperienceAnalytics();
  const manager = useInstance(() => ExperienceMetricsManager.FromStorage());
  const isController = useIsController();

  useEffect(() => {
    // Persist on unmount
    return () => {
      manager.persist();
    };
  }, [manager]);

  useUnload(() => {
    // Attempt to persist on browser beforeunload to track across refreshes

    // An unload means either a refresh of a shutdown of the page. Mark firebase
    // as disconnected to allow tracking disconnect/reconnect time if they
    // return within the time window.
    manager.trackReconnect(false);
    manager.persist();
  });

  useEffect(() => {
    if (!isController) return;
    // flush to analytics on a loop
    const ctrl = new BrowserIntervalCtrl();
    ctrl.set(() => {
      if (!props.isReady) return;
      const stats = manager.flush();
      expAnalytics.trackExperienceMeasured(stats);
    }, targetWindowMs);
    return () => ctrl.clear();
  }, [expAnalytics, isController, manager, props.isReady, targetWindowMs]);

  const sessionId = useStreamSessionId();
  const curr = useStreamSessionStatus();
  const prev = usePrevious(curr);
  const [emittedForSession] = useState(() => new Set<string>());

  useEffect(() => {
    if (
      sessionId &&
      !emittedForSession.has(sessionId) &&
      curr === SessionStatus.Ended &&
      prev &&
      prev !== curr &&
      prev !== SessionStatus.NotStarted
    ) {
      emittedForSession.add(sessionId);
      const stats = manager.flushSession();
      expAnalytics.trackSessionExperienceMeasured(stats);
    }
  }, [curr, emittedForSession, expAnalytics, manager, prev, sessionId]);

  useRefreshMonitor(manager);
  useFirebaseReconnectMonitor(manager);

  return <Context.Provider value={manager}>{props.children}</Context.Provider>;
}

export function useExperienceMetricsManager(): ExperienceMetricsManagerExt | null {
  const ctx = useContext(Context);
  return ctx;
}

/**
 * Track refreshes by comparing persisted clientIds and relying on
 * ExperienceMetricsManager only using recent data.
 */
function useRefreshMonitor(manager: ExperienceMetricsManager) {
  const clientId = useMyClientId();
  useEffect(() => {
    manager.trackRefresh(clientId);
  }, [clientId, manager]);
}

function useFirebaseReconnectMonitor(manager: ExperienceMetricsManager) {
  const fb = useFirebaseContext();
  useEffect(() => {
    return fb.emitter.on('connection-state-changed', (connected) => {
      manager.trackReconnect(connected);
    });
  }, [fb.emitter, manager]);
}
