import React from 'react';

import logger from '../../logger/logger';

const log = logger.scoped('breadcrumb');

const Node = (props: {
  index: number;
  length: number;
  children: React.ReactNode;
}): JSX.Element => {
  return (
    <>
      {props.index > 0 && <div className='text-secondary text-3xl mx-2'>/</div>}
      <div
        className={`${
          props.index === props.length - 1
            ? 'text-white '
            : 'text-secondary hover:text-primary hover:underline'
        }`}
      >
        {props.children}
      </div>
    </>
  );
};

export interface BreadcrumbNode {
  key: string;
  node: React.ReactNode;
}

export class BreadcrumbChain {
  private nodes: BreadcrumbNode[];
  constructor(initNode?: BreadcrumbNode) {
    this.nodes = initNode ? [initNode] : [];
  }
  add(key: string, node: React.ReactNode) {
    const nodeWithSameKey = this.nodes.find((e) => e.key === key);
    if (nodeWithSameKey) {
      log.warn(`Duplicated key in Breadcrumbs: ${key}`);
      return this;
    }
    this.nodes.push({ key, node });
    return this;
  }
  remove(key: string) {
    for (let i = 0; i < this.nodes.length; i++) {
      if (this.nodes[i].key === key) {
        this.nodes.splice(i, 1);
        break;
      }
    }
    return this;
  }
  reset(): void {
    while (this.nodes.length > 0) {
      this.nodes.pop();
    }
  }
  render(minNodes: number): JSX.Element | null {
    if (this.nodes.length < minNodes) {
      return null;
    }
    return (
      <div className='h-full flex items-center'>
        {this.nodes.map((n, i) => (
          <Node key={i} index={i} length={this.nodes.length}>
            {n.node}
          </Node>
        ))}
      </div>
    );
  }
}

interface BreadcrumbsProps {
  chain: BreadcrumbChain;
  minNodes?: number;
}

const Breadcrumbs = (p: BreadcrumbsProps): JSX.Element | null => {
  const props = Object.assign({}, { minNodes: 2 }, p);
  return props.chain.render(props.minNodes);
};

export { Breadcrumbs };
