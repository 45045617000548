import { useLayoutEffect, useRef, useState } from 'react';
import { useCountUp } from 'react-countup';
import { usePrevious } from 'react-use';

import { formatCurrency } from '../../../../utils/currency';
import { useSoundEffect } from '../../../SFX';

const scaleDurationMs = 150;
const countUpDurationMs = 1000;

export function JeopardyScore(props: { points: number | null }) {
  const prevPoints = usePrevious(props.points);
  const countUpRef = useRef<HTMLDivElement | null>(null);
  const [color, setColor] = useState(() => {
    return (props.points ?? 0) < 0 ? 'text-red-001' : 'text-white';
  });

  const { play: playScoreCounting } = useSoundEffect('scoreboardScoreCounting');

  const { reset, update } = useCountUp({
    ref: countUpRef,
    start: props.points ?? 0,
    end: props.points ?? 0,
    duration: countUpDurationMs / 1000,
    startOnMount: false,
    formattingFn: (value) => formatCurrency(value),
  });

  useLayoutEffect(() => {
    reset();
  }, [reset]);

  const isAnimating = useRef(false);
  useLayoutEffect(() => {
    async function animate(nextPoints: number | null) {
      if (
        isAnimating.current ||
        !countUpRef.current ||
        prevPoints === undefined ||
        prevPoints === nextPoints ||
        nextPoints === null
      )
        return;
      isAnimating.current = true;
      await countUpRef.current?.animate(
        [
          {
            transform: 'scale(1)',
          },
          {
            transform: 'scale(1.3)',
          },
        ],
        {
          duration: scaleDurationMs,
          easing: 'linear',
          fill: 'both',
        }
      ).finished;
      playScoreCounting();
      update(nextPoints);
      await countUpRef.current?.animate(
        [
          {
            transform: 'scale(1.3)',
          },
          {
            transform: 'scale(1)',
          },
        ],
        {
          duration: scaleDurationMs,
          delay: countUpDurationMs,
          easing: 'linear',
          fill: 'both',
        }
      ).finished;
      setColor((props.points ?? 0) < 0 ? 'text-red-001' : 'text-white');
      isAnimating.current = false;
    }
    animate(props.points).catch();
  }, [playScoreCounting, prevPoints, props.points, update]);

  return (
    <div
      className={`tabular-nums ${color} transition-colors`}
      ref={countUpRef}
    />
  );
}
