import { useEffect, useMemo } from 'react';
import { useLatest } from 'react-use';

import { useLiveCallback } from '../../hooks/useLiveCallback';
import { useStatsAwareTaskQueue } from '../../hooks/useTaskQueue';
import { assertExhaustive } from '../../utils/common';
import { useMyTeamId } from '../Player';
import { useAwaitSwitchNotice } from '../SwitchNotice/Provider';
import {
  useSwitchTeamTownhallMode,
  useTeamGetter,
  useTeamTownhallMode,
} from '../TeamAPI/TeamV1';
import {
  useTownhallAPI,
  useTownhallConfig,
  useTownhallEnabled,
  useTownhallNext,
} from './Provider';
import { type TownhallMode, type TownhallNext } from './types';

function useDerivedTownhallNext(
  next: Nullable<TownhallNext>
): Nullable<TownhallNext> {
  const myTeamId = useMyTeamId();
  return useMemo(() => {
    if (!next) return;
    if (next.type === 'global') return next;
    if (next.type === 'team' && myTeamId && next.teams?.includes(myTeamId)) {
      return next;
    }
    return null;
  }, [myTeamId, next]);
}

function useWrappedSwitchTeamTownhallMode() {
  const api = useTownhallAPI();
  const config = useTownhallConfig();
  const switchTeamTownhallMode = useSwitchTeamTownhallMode();
  const getTeam = useTeamGetter();
  return useLiveCallback((teamId: string, mode: TownhallMode) => {
    if (config.forceMode !== 'team') {
      const team = getTeam(teamId);
      if (team?.isCohostTeam && mode === 'team') {
        // note: for cohosts, they should always remain in crowd mode. however
        // we want them to have a "team" like experience where they see players
        // still "playing". for now, be sure they never enter team mode where
        // they cannot see anyone.
        api.log.warn('attempted to switch cohost team to team mode', {
          teamId,
          mode,
        });
        return;
      }
    }
    switchTeamTownhallMode(teamId, mode);
  });
}

function useTownhallSwitchModeFromNext() {
  const next = useDerivedTownhallNext(useTownhallNext());
  const switchNotice = useAwaitSwitchNotice();
  const enabled = useTownhallEnabled();
  const switchTeamTownhallMode = useWrappedSwitchTeamTownhallMode();
  const myTeamId = useMyTeamId();
  const teamTownhallMode = useTeamTownhallMode(myTeamId);
  const latestTeamTownhallMode = useLatest(teamTownhallMode);
  const { addTask } = useStatsAwareTaskQueue({
    shouldProcess: true,
    stats: 'task-queue-townhall-switch-ms',
  });

  useEffect(() => {
    if (!enabled || !next || !myTeamId) return;
    addTask(async function () {
      if (latestTeamTownhallMode.current === next.mode) return;
      const notice = await switchNotice({
        target: 'local',
        countdownSec: Math.max(next.countdownSec, 0),
        renderer: {
          type: 'native',
          render: ({ remainingSec }) => {
            switch (next.mode) {
              case 'team':
                return (
                  <div>
                    <span>Breaking out into</span>
                    <span className='text-tertiary'> Teams </span>
                    <span>in {remainingSec}...</span>
                  </div>
                );
              case 'crowd':
                return (
                  <div>
                    <span>Going to</span>
                    <span className='text-tertiary'> Full Group </span>
                    <span>in {remainingSec}...</span>
                  </div>
                );
              default:
                assertExhaustive(next.mode);
                return null;
            }
          },
        },
      });
      const response = await notice.response;
      if (response === 'done') {
        switchTeamTownhallMode(myTeamId, next.mode);
      }
    });
  }, [
    addTask,
    enabled,
    latestTeamTownhallMode,
    myTeamId,
    next,
    switchNotice,
    switchTeamTownhallMode,
  ]);
}

/**
 * Set initial team townhall mode if it's undefined
 */
function useTownhallInitMode() {
  const enabled = useTownhallEnabled();
  const mode = useTownhallConfig().mode;
  const switchTeamTownhallMode = useWrappedSwitchTeamTownhallMode();
  const myTeamId = useMyTeamId();
  const teamTownhallMode = useTeamTownhallMode(myTeamId);

  useEffect(() => {
    if (!enabled || !myTeamId || teamTownhallMode !== undefined) return;
    switchTeamTownhallMode(myTeamId, mode);
  }, [enabled, mode, switchTeamTownhallMode, myTeamId, teamTownhallMode]);
}

export function TownhallSwitchMode(): JSX.Element | null {
  useTownhallInitMode();
  useTownhallSwitchModeFromNext();

  return null;
}
