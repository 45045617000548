import pluralize from 'pluralize';
import { useEffect, useMemo } from 'react';
import { useEffectOnce, usePrevious } from 'react-use';
import { proxy } from 'valtio';

import {
  assertExhaustive,
  type OverRoastedBlock,
  OverRoastedBlockGameSessionStatus,
} from '@lp-lib/game';

import { useInstance } from '../../../../hooks/useInstance';
import { ClientTypeUtils } from '../../../../types';
import { getStaticAssetPath } from '../../../../utils/assets';
import { nullOrUndefined } from '../../../../utils/common';
import { loadImageAsPromise } from '../../../../utils/media';
import {
  markSnapshottable,
  useSnapshot,
  ValtioUtils,
} from '../../../../utils/valtio';
import { useGainPointsAnimationGamePlayTrigger } from '../../../GainPointsAnimation/useGainPointsAnimationGamePlayTrigger';
import { FloatBoard, FloatLayout } from '../../../Layout';
import { LayoutAnchor } from '../../../LayoutAnchors/LayoutAnchors';
import { useSyncPersistentPointsRevealAnswer } from '../../../PersistentPoints/Provider';
import { useMyTeamId } from '../../../Player';
import { useSoundEffect } from '../../../SFX';
import { useMyClientType } from '../../../Venue/VenuePlaygroundProvider';
import { GoAnimation } from '../../GameBlockCardAnimations';
import {
  useGameSessionLocalTimer,
  useGameSessionStatus,
  useIsLiveGamePlay,
  useTimerRecover,
} from '../../hooks';
import { countdownV2, resetTimer, setTimer } from '../../store';
import {
  buildGamePlayMedia,
  GamePlayMediaPlayer,
  type GamePlayMediaPlayerLayout,
  useGamePlayMediaPlayable,
  useGamePlayMediaUISync,
} from '../Common/GamePlay/GamePlayMedia';
import { GamePlayMediaLayout } from '../Common/GamePlay/GamePlayMediaLayout';
import {
  useCountdownPlaySFX,
  useGamePlayUITransitionControl,
} from '../Common/GamePlay/GamePlayUtilities';
import { StageBgSingleFrame } from '../Common/GamePlay/StageBgSingleFrame';
import {
  type GamePlayMedia,
  type GamePlayProps,
} from '../Common/GamePlay/types';
import { useRankedTeamScores, useStableBlock } from '../Common/hooks';
import { OverRoastedPlayground } from './OverRoastedPlayground';
import {
  useOverRoastedGame,
  useOverRoastedGameSettings,
  useOverRoastedSummaryMap,
} from './OverRoastedProvider';
import { useOverRoastedGamePlayAPI } from './OverRoastedProvider/OverRoastedGamePlayProvider';
import { DEFAULT_OVER_ROASTED_BACKGROUND_SOURCE, GameState } from './types';
import { OverRoastedUtils } from './utils';

function Results(props: {
  withSFX?: boolean;
  title?: string;
}): JSX.Element | null {
  const { withSFX = true, title = 'GAME RESULTS' } = props;

  const teamScores = useRankedTeamScores('currentScore', null, 'always');
  const myTeamId = useMyTeamId();
  const summaryMap = useOverRoastedSummaryMap();

  const { play } = useSoundEffect('showResults');

  useEffect(() => {
    if (withSFX) {
      play();
    }
  }, [play, withSFX]);

  return (
    <>
      <FloatBoard
        containerZIndex='z-20'
        containerDisplay={'block'}
        bgStyle='border-2 border-cyan rounded-xl bg-black bg-opacity-60'
        title={title}
      >
        <div className='w-full h-full pt-10 pb-20 px-16 overflow-y-auto scrollbar flex flex-col'>
          {teamScores.map((s) => {
            const completedCount =
              summaryMap?.[s.team.id]?.completedOrders ?? 0;
            return (
              <div
                key={s.team.id}
                className={`w-full animate-fade-in-up min-h-12 mb-4 pl-6 rounded-3xl bg-black bg-opacity-95 flex flex-row justify-between items-center ${
                  s.team.id === myTeamId ? 'text-tertiary' : 'text-white'
                }`}
              >
                <p className={`text-lg font-bold font-cairo`}>{s.team.name}</p>
                <div className='flex flex-row justify-center items-center'>
                  <p className='text-3xs font-bold'>
                    {`${completedCount} ${pluralize(
                      'Order',
                      completedCount
                    )} served`}
                  </p>
                  <div className='w-11 h-11 mr-0.75 ml-3 rounded-full bg-warning text-black flex flex-col justify-center items-center'>
                    <div
                      className={`font-extrabold ${
                        s.currentScore > 1000 ? ' text-sm' : 'text-xl'
                      } leading-5 font-cairo`}
                    >
                      {s.currentScore}
                    </div>
                    <p className='text-3xs text-black'>pts</p>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </FloatBoard>
      <FloatLayout className='flex items-center justify-center'>
        <LayoutAnchor
          id='gameplay-points-animation-top'
          className='w-full max-w-4xl'
        />
      </FloatLayout>
    </>
  );
}

function HostView(): JSX.Element {
  return <Results withSFX={false} title='GAME PROGRESS' />;
}

type OverRoastedGamePlayLocalState = {
  gamePlayMedia: Nullable<GamePlayMedia, false>;
  mediaPlayerLayout: GamePlayMediaPlayerLayout | null;
  mediaPlayerLoop: boolean;
  showResults: boolean;
};

function initialOverRoastedGamePlayLocalState(): OverRoastedGamePlayLocalState {
  return {
    gamePlayMedia: null,
    mediaPlayerLayout: null,
    mediaPlayerLoop: false,
    showResults: false,
  };
}

class OverRoastedGamePlayLocalAPI {
  constructor(
    private state: OverRoastedGamePlayLocalState,
    private block: OverRoastedBlock,
    private isHost: boolean,
    private isLive: boolean
  ) {}

  private updateGamePlayMedia(
    media: Nullable<GamePlayMedia, false>,
    layout: GamePlayMediaPlayerLayout,
    loop: boolean
  ) {
    this.state.gamePlayMedia = media;
    this.state.mediaPlayerLayout = layout;
    this.state.mediaPlayerLoop = loop;
  }

  presentIntro() {
    this.updateGamePlayMedia(
      buildGamePlayMedia(
        {
          media: OverRoastedUtils.GetMedia(this.block, 'introMedia'),
          mediaData: this.block.fields.introMediaData,
        },
        {
          stage: 'intro',
        }
      ),
      'anchored',
      false
    );
  }

  initGame(totalGameTime: number, recovery?: boolean) {
    const media = buildGamePlayMedia(DEFAULT_OVER_ROASTED_BACKGROUND_SOURCE, {
      stage: 'custom',
      isBackgroundMedia: true,
    });
    this.updateGamePlayMedia(media, 'fullscreen', true);
    if (!recovery) setTimer('submission', totalGameTime);
  }

  async startGame() {
    await countdownV2({
      debug: 'TeamRelayBlockGamePlay',
      startTimeWorker: this.isHost ? !this.isLive : true,
      flushCountingStatus: false,
    });
  }

  endGameTimer() {
    resetTimer('submission');
  }

  stopGame() {
    this.updateGamePlayMedia(null, undefined, false);
  }

  presentOutro() {
    this.updateGamePlayMedia(
      buildGamePlayMedia(
        {
          media: OverRoastedUtils.GetMedia(this.block, 'outroMedia'),
          mediaData: this.block.fields.outroMediaData,
        },
        {
          stage: 'outro',
        }
      ),
      'anchored',
      false
    );
  }

  revealResults() {
    this.updateGamePlayMedia(null, undefined, false);
    if (!this.block.fields.tutorialMode) {
      this.state.showResults = true;
    }
  }

  reset() {
    resetTimer('submission');
    ValtioUtils.reset(this.state, initialOverRoastedGamePlayLocalState());
  }
}

function MediaPreload(): JSX.Element | null {
  useEffectOnce(() => {
    loadImageAsPromise(
      getStaticAssetPath('images/over-roasted-animated-trash.png')
    );
    loadImageAsPromise(
      getStaticAssetPath('images/over-roasted-overfill-warning-v2.gif')
    );
  });

  return null;
}

export function OverRoastedBlockGamePlay(
  props: GamePlayProps<OverRoastedBlock>
): JSX.Element | null {
  const block = useStableBlock(props.block);
  const gameSessionStatus =
    useGameSessionStatus<OverRoastedBlockGameSessionStatus>();
  const prevGameSessionStatus = usePrevious(gameSessionStatus);
  const isHost = ClientTypeUtils.isHost(useMyClientType());
  const isLive = useIsLiveGamePlay();
  const time = useGameSessionLocalTimer();

  const rawState = useInstance(() =>
    markSnapshottable(
      proxy<OverRoastedGamePlayLocalState>(
        initialOverRoastedGamePlayLocalState()
      )
    )
  );
  const control = useMemo(
    () => new OverRoastedGamePlayLocalAPI(rawState, block, isHost, isLive),
    [rawState, block, isHost, isLive]
  );
  const state = useSnapshot(rawState);
  const game = useOverRoastedGame();
  const gameState = game?.state;
  const inGame = !!gameState && gameState >= GameState.Inited;
  const settings = useOverRoastedGameSettings();
  const api = useOverRoastedGamePlayAPI();
  const [uiState, uiControl] = useGamePlayUITransitionControl();

  useCountdownPlaySFX(
    settings.gameTimeSec,
    time,
    !!gameState && gameState >= GameState.InProgress && !settings.tutorialMode
  );
  useTimerRecover(async (status) => {
    if (status === OverRoastedBlockGameSessionStatus.GAME_START) {
      return block.fields.gameTimeSec;
    }
  });
  useSyncPersistentPointsRevealAnswer(state.showResults);
  useGainPointsAnimationGamePlayTrigger();

  // some actions need to be triggered by the game state
  useEffect(() => {
    if (!settings || nullOrUndefined(gameState)) return;
    switch (gameState) {
      case GameState.Inited:
        control.initGame(settings.gameTimeSec);
        break;
      // cover the case if user joins in the middle of the game or refreshes the page
      case GameState.InProgress:
        control.initGame(settings.gameTimeSec, true);
        break;
      case GameState.None:
      case GameState.Ended:
        break;
      default:
        assertExhaustive(gameState);
        break;
    }
  }, [control, settings, gameState]);

  useEffect(() => {
    // Protect the same action being tirggered more than once.
    // Technically it can happen in two cases,
    // 1. localGameControl
    // The `localGameControl` changed, current all the functions
    // have only one dependency `block`, which should not be changed
    // in the game play and same for the localGameControl instance.
    // 2. Hot module reloading in local dev
    // This is very likely, and the side effect will be run again in
    // this case.
    if (prevGameSessionStatus === gameSessionStatus) return;

    switch (gameSessionStatus) {
      case OverRoastedBlockGameSessionStatus.LOADED:
        control.reset();
        api.reset();
        break;
      case OverRoastedBlockGameSessionStatus.INTRO:
        control.presentIntro();
        break;
      case OverRoastedBlockGameSessionStatus.GAME_INIT:
        // handled by the game state above
        break;
      case OverRoastedBlockGameSessionStatus.GAME_START:
        control.startGame();
        break;
      case OverRoastedBlockGameSessionStatus.GAME_END:
        // we'd like to keep the game state for teams didn't finish the game,
        // so the stopGame is called in the next step.
        control.endGameTimer();
        break;
      case OverRoastedBlockGameSessionStatus.OUTRO:
        control.stopGame();
        control.presentOutro();
        break;
      case OverRoastedBlockGameSessionStatus.RESULTS:
        control.revealResults();
        break;
      case OverRoastedBlockGameSessionStatus.SCOREBOARD:
      case OverRoastedBlockGameSessionStatus.END:
      case null:
      case undefined:
        break;
      default:
        assertExhaustive(gameSessionStatus);
        break;
    }
  }, [gameSessionStatus, prevGameSessionStatus, control, api]);

  const { onMediaEnded, onMediaReplaying } = useGamePlayMediaUISync({
    block,
    gameSessionStatus,
    media: state.gamePlayMedia,
    state: uiState,
    control: uiControl,
  });

  const mediaPlayable = useGamePlayMediaPlayable({
    block,
    gameSessionStatus,
    media: state.gamePlayMedia,
    state: uiState,
    // This is only used for background media.
    // Play the background media when the game started
    custom: () => gameState === GameState.InProgress,
  });

  if (gameSessionStatus === null || gameSessionStatus === undefined)
    return null;

  const fullscreen = state.mediaPlayerLayout === 'fullscreen';

  return (
    <div className='fixed w-screen h-screen text-white'>
      {state.gamePlayMedia && state.mediaPlayerLayout && (
        <StageBgSingleFrame gamePlayMedia={state.gamePlayMedia} />
      )}
      {uiState.playGoAnimation && <GoAnimation />}

      <GamePlayMediaLayout fullscreen={fullscreen}>
        {state.gamePlayMedia && state.mediaPlayerLayout && (
          <GamePlayMediaPlayer
            gamePlayMedia={state.gamePlayMedia}
            play={mediaPlayable}
            mode={fullscreen || !uiState.mediaEndEffect ? 'full' : 'small'}
            onMediaEnded={!fullscreen ? onMediaEnded : undefined}
            onMediaReplaying={!fullscreen ? onMediaReplaying : undefined}
            layout={state.mediaPlayerLayout}
            loop={state.mediaPlayerLoop}
          />
        )}
      </GamePlayMediaLayout>

      {inGame &&
        (isHost ? (
          <HostView />
        ) : (
          <OverRoastedPlayground block={block} timer={time || 0} />
        ))}

      {state.showResults && <Results />}

      <MediaPreload />
    </div>
  );
}
