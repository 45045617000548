import { ref } from 'valtio';

import { type GameSessionStatus } from '@lp-lib/game';

export type SessionStatusHook = {
  blockId: string;
  sessionStatus: GameSessionStatus;
  before?: () => Promise<void>;
  after?: () => Promise<void>;
};

export class SessionStatusHookManager {
  private hooks: SessionStatusHook[];
  constructor() {
    this.hooks = [];
  }
  register(hook: SessionStatusHook): void {
    this.hooks.push(hook);
  }
  unregister(hook: SessionStatusHook): void {
    const index = this.hooks.findIndex((h) => h === hook);
    if (index === -1) return;
    this.hooks.splice(index, 1);
  }
  getHooks(
    blockId: string | null | undefined,
    sesssionStatus: GameSessionStatus
  ): SessionStatusHook[] {
    if (!blockId) return [];
    return this.hooks.filter(
      (h) => h.blockId === blockId && h.sessionStatus === sesssionStatus
    );
  }
  static CreateVRefed(): SessionStatusHookManager {
    return ref(new SessionStatusHookManager());
  }
}
