import 'react-toastify/dist/ReactToastify.css';
import '../../assets/scss/notification.scss';

import React, { Suspense, useEffect, useRef } from 'react';
import {
  Bounce,
  cssTransition,
  Flip,
  Slide,
  toast,
  ToastContainer,
  type ToastPosition,
  type ToastTransition,
  Zoom,
} from 'react-toastify';

import config from '../../config';
import { type Notification, NotificationType } from '../../types/notification';
import { assertExhaustive } from '../../utils/common';
import { Loading } from '../Loading';
import WaterCoolerNotificationView from '../Program/WaterCooler/WaterCoolerNotification';
import { useNotificationDataSource } from './Context';
import GeneralNotificationView from './impl/General';
import { type NotificationProps } from './type';

const limit = config.notification.limit;

const transition = cssTransition({
  enter: 'lunpark-notification-enter',
  exit: 'lunpark-notification-exit',
  appendPosition: true,
});

const TeamInviteNotifcationView = React.lazy(
  () =>
    import(
      /* webpackChunkName: "notif-team" */
      './impl/TeamInvite'
    )
);
const GameActionNotifcationView = React.lazy(
  () =>
    import(
      /* webpackChunkName: "notif-gameaction" */
      './impl/GameAction'
    )
);
const TeamRandomizerNotifcationView = React.lazy(
  () =>
    import(
      /* webpackChunkName: "notif-teamrandom" */
      '../TeamRandomizer/Notification'
    )
);
const TeamCaptainScribeNotificationView = React.lazy(() =>
  import(
    /* webpackChunkName: "notif-teamcaptain" */
    '../TeamCaptainScribe/TeamCaptainScribeNotification'
  ).then((m) => ({
    default: m.TeamCaptainScribeNotificationView,
  }))
);
const TeamSizeChangedNotifcationView = React.lazy(
  () =>
    import(
      /* webpackChunkName: "notif-teamsize" */
      '../TeamSizeControl/Notification'
    )
);
const GamePackActionNotificationView = React.lazy(
  () =>
    import(
      /* webpackChunkName: "notif-gamepackaction" */
      './impl/GamePackAction'
    )
);
const OrgActionNotificationView = React.lazy(
  () =>
    import(
      /* webpackChunkName: "notif-orgaction" */
      './impl/OrgAction'
    )
);
const SkipTeamRandomizerIcebreakerNotificationView = React.lazy(() =>
  import(
    /* webpackChunkName: "notif-skipicebreaker" */
    '../TeamRandomizer/SkipIcebreakerNotification'
  ).then((m) => ({
    default: m.SkipTeamRandomizerIcebreakerNotificationView,
  }))
);
const LiteModeSuggestionNotificationView = React.lazy(() =>
  import(
    /* webpackChunkName: "notif-litemode" */
    '../LiteModeNotification/LiteModeNotification'
  ).then((m) => ({
    default: m.LiteModeNotificationView,
  }))
);
const GameChangeSubmitterNotificationView = React.lazy(
  () =>
    import(
      /* webpackChunkName: "notif-gamechange" */
      './impl/GameChangeSubmitter'
    )
);
const BrandActionNotifcationView = React.lazy(
  () =>
    import(
      /* webpackChunkName: "notif-brandaction" */
      '../Brand/BrandNotification'
    )
);

const LargeGroupNotificationView = React.lazy(() =>
  import(
    /* webpackChunkName: "notif-brandaction" */
    '../Townhall/LiveTownhallLargeGroupSwticher'
  ).then((m) => ({
    default: m.LargeGroupNotificationView,
  }))
);

const GuestCouldNotJoinNotificationView = React.lazy(
  () =>
    import(
      /* webpackChunkName: "notif-guestcouldnotjoin" */
      './impl/GuestCouldNotJoin'
    )
);

const GamePackRequestedNotificationView = React.lazy(
  () =>
    import(
      /* webpackChunkName: "notif-gamepackrequested" */
      './impl/GamePackRequested'
    )
);

const NotificationView = (props: NotificationProps): JSX.Element | null => {
  const { notification: n, ...rest } = props;
  const type = n.type;
  switch (type) {
    case NotificationType.TeamInvite:
      return <TeamInviteNotifcationView notification={n} {...rest} />;
    case NotificationType.TeamRandomizer:
      return <TeamRandomizerNotifcationView notification={n} {...rest} />;
    case NotificationType.TeamCaptainScribe:
      return <TeamCaptainScribeNotificationView notification={n} {...rest} />;
    case NotificationType.TeamSizeChanged:
      return <TeamSizeChangedNotifcationView notification={n} {...rest} />;
    case NotificationType.GameAction:
      return <GameActionNotifcationView notification={n} {...rest} />;
    case NotificationType.GamePackAction:
      return <GamePackActionNotificationView notification={n} {...rest} />;
    case NotificationType.OrgAction:
      return <OrgActionNotificationView notification={n} {...rest} />;
    case NotificationType.SkipTeamRandomizerIcebreaker:
      return (
        <SkipTeamRandomizerIcebreakerNotificationView
          notification={n}
          {...rest}
        />
      );
    case NotificationType.LiteModeSuggestion:
      return <LiteModeSuggestionNotificationView notification={n} {...rest} />;
    case NotificationType.GameChangeSubmitter:
      return <GameChangeSubmitterNotificationView notification={n} {...rest} />;
    case NotificationType.BrandAction:
      return <BrandActionNotifcationView notification={n} {...rest} />;
    case NotificationType.WaterCoolerAction:
      return <WaterCoolerNotificationView notification={n} {...rest} />;
    case NotificationType.TownhallLargeGroup:
      return <LargeGroupNotificationView notification={n} {...rest} />;
    case NotificationType.General:
      return <GeneralNotificationView notification={n} {...rest} />;
    case NotificationType.GuestCouldNotJoin:
      return <GuestCouldNotJoinNotificationView notification={n} {...rest} />;
    case NotificationType.GamePackRequested:
      return <GamePackRequestedNotificationView notification={n} {...rest} />;
    default:
      assertExhaustive(type);
      return null;
  }
};

type NotificationTransitionType = 'lp' | 'slide' | 'zoom' | 'flip' | 'bounce';

function getTransition(type?: NotificationTransitionType): ToastTransition {
  switch (type) {
    case 'lp':
      return transition;
    case 'slide':
      return Slide;
    case 'zoom':
      return Zoom;
    case 'flip':
      return Flip;
    case 'bounce':
      return Bounce;
    default:
      return Slide;
  }
}

interface NotificationCenterProps {
  className?: string;
  toastClassName?: string;
  position?: ToastPosition;
  transition?: NotificationTransitionType;
  newestOnTop?: boolean;
  autoClose?: number | false;
  limits?: number;
}

const NotificationCenter = (props: NotificationCenterProps): JSX.Element => {
  const datasource = useNotificationDataSource();
  const notifications = datasource.getAll();
  const notificationsRef = useRef<Notification[]>(notifications);
  const presented = useRef<Set<string>>(new Set<string>());
  const timerId = useRef<ReturnType<typeof setInterval> | null>(null);

  useEffect(() => {
    notificationsRef.current = notifications;
  }, [notifications]);

  useEffect(() => {
    if (!timerId.current) {
      timerId.current = setInterval(() => {
        const toBeRemoved: string[] = [];
        for (const n of notificationsRef.current) {
          if (Date.now() - n.createdAt > config.team.inviteCooldown) {
            toBeRemoved.push(n.id);
          }
        }
        for (const id of toBeRemoved) {
          datasource.dismiss(id);
        }
      }, 1000);
    }
    return () => {
      if (timerId.current) {
        clearInterval(timerId.current);
        timerId.current = null;
      }
    };
  }, [datasource]);

  useEffect(() => {
    if (notifications.length > limit) {
      for (let index = 0; index < notifications.length - limit; index++) {
        toast.dismiss(notifications[index].id);
      }
    }
    for (const n of notifications) {
      if (presented.current.has(n.id)) {
        continue;
      }
      toast.dark(
        <Suspense
          fallback={
            <div className='h-15 mx-4 flex items-center text-2xs'>
              <Loading />
            </div>
          }
        >
          <NotificationView notification={n} />
        </Suspense>,
        { toastId: n.id }
      );
      presented.current.add(n.id);
    }
  }, [notifications]);

  return (
    <div className='absolute'>
      <ToastContainer
        className={props.className}
        toastClassName={() =>
          `${props.toastClassName ? props.toastClassName : ''}`
        }
        bodyClassName={() => ''}
        position={props.position}
        hideProgressBar
        draggable={false}
        autoClose={props.autoClose}
        transition={getTransition(props.transition)}
        limit={props.limits}
        closeOnClick={false}
        closeButton={false}
        newestOnTop={props.newestOnTop}
      />
    </div>
  );
};

export { NotificationCenter };
