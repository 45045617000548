import React, { useContext, useMemo } from 'react';
import { type DeepPartial, type FieldPath, get, set } from 'react-hook-form';
import { proxy } from 'valtio';

import { type RelativeRect } from '@lp-lib/game';

import { useInstance } from '../../hooks/useInstance';
import {
  markSnapshottable,
  useSnapshot,
  ValtioUtils,
} from '../../utils/valtio';
import { type VideoEffectsFormData, type VideoEffectsSettings } from './types';

class VideoEffectsSettingsStore {
  public s = markSnapshottable(
    proxy(ValtioUtils.detachCopy({ ...this.initialSettings, resetCount: 0 }))
  );

  constructor(public initialSettings: VideoEffectsSettings) {}

  get settings() {
    return this.s;
  }

  changeProcessorEnabled(
    name: 'greenScreen' | 'podium' | 'intro' | 'outro',
    enabled: boolean
  ) {
    const setting = this.s[name];
    if (!setting) return;
    setting.enabled = enabled;
  }

  updateMask = (rect: Partial<RelativeRect>) => {
    ValtioUtils.update(this.s.greenScreen.maskPct, rect);
  };

  updateOthers = (
    settings: DeepPartial<VideoEffectsFormData>,
    path?: FieldPath<VideoEffectsFormData>
  ) => {
    if (!path) return;
    set(this.s, path, get(settings, path));
  };

  resetToInitial() {
    this.resetToValues(this.initialSettings);
  }

  resetToValues(videoEffectsSettings: VideoEffectsSettings) {
    ValtioUtils.reset(this.s, videoEffectsSettings);
    this.s.resetCount += 1;
  }

  toJS(): VideoEffectsSettings {
    const { resetCount, ...rest } = this.s;
    return ValtioUtils.detachCopy(rest);
  }
}

type VESContext = {
  store: VideoEffectsSettingsStore;
};

const context = React.createContext<null | VESContext>(null);

export function usePreviewVideoEffectsSettings(): Readonly<VideoEffectsSettings> {
  const ctx = useContext(context);
  if (!ctx) throw new Error('VESContext not in tree!');
  return useSnapshot(ctx.store.settings);
}

export function usePreviewVideoEffectsSettingsStore(): VideoEffectsSettingsStore {
  const ctx = useContext(context);
  if (!ctx) throw new Error('VESContext not in tree!');
  return ctx.store;
}

export function PreviewVideoEffectsSettingsProvider(props: {
  children: React.ReactNode;
  initialSettings: VideoEffectsSettings;
}): JSX.Element {
  // TODO: consider invalidating the store based on prop value change
  const store = useInstance(
    () => new VideoEffectsSettingsStore(props.initialSettings)
  );

  const ctxValue = useMemo(() => ({ store }), [store]);

  return <context.Provider value={ctxValue}>{props.children}</context.Provider>;
}
