import { useMemo } from 'react';
import { useSnapshot } from 'valtio';

import {
  BlockType,
  Delimited,
  type RapidBlock,
  type RapidBlockAnswerData,
  RapidBlockAnswerGrade,
  type RapidBlockAnswersData,
  type RapidBlockDetailScore,
  RapidBlockGameSessionStatus,
  type RapidCorrectAnswer,
  type TeamDataList,
} from '@lp-lib/game';

import logger from '../../../../logger/logger';
import { store as reduxStore } from '../../../../store/configureStore';
import { nullOrUndefined } from '../../../../utils/common';
import { useTeams } from '../../../TeamAPI/TeamV1';
import { useUser } from '../../../UserContext';
import { useGameSessionStatus } from '../../hooks';
import { fetchDetailScoresData, gameSessionStore } from '../../store';

export interface RapidResult {
  teamId: string;
  teamName: string;
  corrects: number | null;
  previewPoints: number;
}

export interface RapidTeamData {
  teamId: string;
  userId: string | null;
  teamName: string | null;
  corrects: number | null;
  totalScore: number | null;
  previewPoints: number;
}
export interface RapidAllAnswerMap {
  [answer: string]: { answerIndex: string; points: number };
}

export const log = logger.scoped('rapid-submission');

export const useRapidTeamData = (
  selectedBlockId?: string | null
): RapidTeamData[] => {
  const teamList = useTeams();

  const session = useSnapshot(gameSessionStore).session;
  const blockId = selectedBlockId || (session.blockSession?.block?.id ?? null);
  const detailScores = useSnapshot(gameSessionStore).detailScores;
  const scoreSummary = useSnapshot(gameSessionStore).scoreSummary;
  const blockDetailScores = blockId ? detailScores?.[blockId] ?? null : null;
  const gameSessionStatus = session.status as RapidBlockGameSessionStatus;
  const sortBy =
    gameSessionStatus === RapidBlockGameSessionStatus.RESULTS
      ? 'points'
      : gameSessionStatus > RapidBlockGameSessionStatus.RESULTS
      ? 'score'
      : 'default';

  return useMemo(() => {
    const rapidData: RapidTeamData[] = [];

    teamList.forEach((t) => {
      const detailScore = blockDetailScores?.[
        t.id
      ] as RapidBlockDetailScore | null;
      const score = detailScore?.score ?? null;
      const scoreOverride = detailScore?.scoreOverride ?? null;
      const previewPoints = (
        score === null
          ? scoreOverride === null
            ? detailScore?.previewPoints ?? 0
            : scoreOverride
          : score
      ) as number;

      rapidData.push({
        teamId: t.id,
        teamName: t.name,
        corrects: detailScore?.corrects ?? null,
        userId: detailScore?.submitterUid ?? null,
        totalScore: scoreSummary?.[t.id]?.totalScore || 0,
        previewPoints,
      });
    });

    rapidData.sort(
      (a, b) =>
        a.teamName?.localeCompare(b.teamName || '', 'en', {
          sensitivity: 'base',
        }) || 0
    );

    if (sortBy !== 'default') {
      const sortFn =
        sortBy === 'score'
          ? (a: RapidTeamData, b: RapidTeamData) =>
              (b.totalScore || 0) - (a.totalScore || 0)
          : (a: RapidTeamData, b: RapidTeamData) =>
              (b.previewPoints || 0) - (a.previewPoints || 0);
      rapidData.sort(sortFn);
    }

    return rapidData;
  }, [blockDetailScores, scoreSummary, sortBy, teamList]);
};

export const useRapidSFXAnswer = (
  everyoneSubmits: boolean
): RapidBlockAnswerData | null => {
  const user = useUser();
  const teamData = useSnapshot(gameSessionStore).teamData;

  return useMemo(() => {
    if (!teamData?.data) return null;
    const rapidAnswerData = teamData.data as RapidBlockAnswersData;

    const validAnswers = Object.values(rapidAnswerData).filter((data) => {
      return (
        data &&
        (user.id === data.submitterUid ||
          !everyoneSubmits ||
          data.grade === RapidBlockAnswerGrade.CORRECT)
      );
    });

    if (validAnswers.length === 0) return null;

    return validAnswers.reduce((prev, cur) => {
      return (prev?.submittedAt || 0) < (cur?.submittedAt || 0) ? cur : prev;
    });
  }, [everyoneSubmits, teamData?.data, user.id]);
};

export const useRapidCorrectAnswers = (): RapidBlockAnswerData[] | null => {
  const gameSessionStatus = useGameSessionStatus<RapidBlockGameSessionStatus>();
  const teamData = useSnapshot(gameSessionStore).teamData;
  const rapidAnswerData = teamData?.data as RapidBlockAnswersData | null;

  return useMemo(() => {
    if (
      !rapidAnswerData ||
      Object.keys(rapidAnswerData).length === 0 ||
      nullOrUndefined(gameSessionStatus)
    )
      return null;
    const answers = Object.values(rapidAnswerData)
      .filter(
        (a) =>
          a &&
          (a.grade === RapidBlockAnswerGrade.CORRECT ||
            a.grade === RapidBlockAnswerGrade.DUPLICATE)
      )
      .sort((a, b) => b.firstSubmittedAt - a.firstSubmittedAt);

    if (gameSessionStatus >= RapidBlockGameSessionStatus.RESULTS) {
      answers.sort((a, b) => b.points - a.points);
    }

    return answers;
  }, [gameSessionStatus, rapidAnswerData]);
};

export const useRapidMissedAnswers = (): RapidCorrectAnswer[] => {
  const blockSession = useSnapshot(gameSessionStore).session.blockSession;
  const correctAnswers = useRapidCorrectAnswers();

  const block = blockSession?.block as RapidBlock | null;

  return useMemo(() => {
    if (!block?.fields.answers || block.type !== BlockType.RAPID) return [];

    let allAnswers: RapidCorrectAnswer[] = [];
    try {
      allAnswers = JSON.parse(block.fields.answers);
    } catch (_) {}

    let missedAnswers: RapidCorrectAnswer[] = [];
    const delimited = new Delimited();

    if (allAnswers.length > 0) {
      if (!correctAnswers || correctAnswers.length === 0) {
        missedAnswers = allAnswers;
      } else {
        const answerIndexses = correctAnswers.map((a) => {
          return a.answerIndex;
        });
        for (let i = 0; i < allAnswers.length; i++) {
          if (!answerIndexses.includes(i.toString())) {
            const missedAnswer: RapidCorrectAnswer = {
              answer: delimited.parse(allAnswers[i].answer)[0],
              points: allAnswers[i].points,
            };
            missedAnswers.push(missedAnswer);
          }
        }
      }
    }

    missedAnswers.sort((a, b) => b.points - a.points);

    return missedAnswers;
  }, [block?.fields.answers, block?.type, correctAnswers]);
};

export const useRapidTeamPoints = (): number => {
  let points = 0;
  const correctAnswers = useRapidCorrectAnswers();
  if (correctAnswers) {
    correctAnswers.forEach((a) => (points += a.points));
  }

  return points;
};

export const useRapidResults = (
  blockId: string,
  setResults: (results: RapidResult[]) => void
): void => {
  useMemo(async () => {
    const results = [] as RapidResult[];
    const teamDataList = (await fetchDetailScoresData(
      blockId
    )) as TeamDataList<RapidBlockDetailScore> | null;

    if (teamDataList) {
      const teams = reduxStore.getState().team.teams;

      Object.keys(teamDataList).forEach((teamId) => {
        const data = teamDataList[teamId];
        const team = teams[teamId];
        if (!data || !team) return;

        results.push({
          teamId,
          teamName: teams[teamId]?.name ?? '',
          corrects: data?.corrects ?? null,
          previewPoints: data.previewPoints ?? 0,
        });
      });
    }

    results.sort(
      (a: RapidResult, b: RapidResult) =>
        (b.previewPoints || 0) - (a.previewPoints || 0)
    );

    setResults(results);
  }, [blockId, setResults]);
};
