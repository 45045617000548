import { useEffect, useRef, useState } from 'react';

import { useOutsideClick } from '../../hooks/useOutsideClick';
import { type Team } from '../../types';
import { MarkIcon } from '../icons/MarkIcon';
import { TeamIcon } from '../icons/TeamIcon';
import { useRenameTeam } from '../TeamAPI/TeamV1';

const TeamNameMinLength = 3;
const TeamNameMaxLength = 25;

export function RenameTeam(props: {
  team: Team;
  handleClose: () => void;
  inputClassName?: string;
  containerClassName?: string;
  showTeamIcon?: boolean;
}): JSX.Element {
  const { team, handleClose } = props;
  const [disabled, setDisabled] = useState(false);
  const [editingError, setEditingError] = useState<string>('');
  const inputRef = useRef<HTMLInputElement | null>(null);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const renameTeam = useRenameTeam();

  useEffect(() => {
    inputRef.current?.focus();
  }, []);

  const cancelEditing = () => {
    setEditingError('');
    handleClose();
  };

  useOutsideClick(containerRef, cancelEditing);

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>): void => {
    if (e.key === 'Escape') {
      cancelEditing();
      return;
    } else if (e.key === 'Enter') {
      handleUpdateTeamName();
      return;
    }
  };

  const validateTeamNameChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const len = e.target.value.length;
    setDisabled(len < TeamNameMinLength || len >= TeamNameMaxLength);
    setEditingError(
      len >= TeamNameMaxLength ? 'Max character count reached.' : ''
    );
  };

  const handleUpdateTeamName = (): void => {
    if (disabled || !inputRef.current) {
      return;
    }

    const newTeamName = inputRef.current.value;

    renameTeam(props.team.id, newTeamName);
    cancelEditing();
  };

  return (
    <div
      ref={containerRef}
      className={`flex text-white items-center justify-center relative ${
        props.containerClassName ?? ''
      }`}
    >
      {props.showTeamIcon && (
        <TeamIcon className='fill-current w-5 h-5 flex-shrink-0 mr-1' />
      )}
      <input
        ref={inputRef}
        className={`w-full text-sm bg-transparent outline-none appearance-none caret-color-primary font-bold ${
          props.inputClassName ?? ''
        }`}
        defaultValue={team.name}
        maxLength={TeamNameMaxLength}
        onChange={validateTeamNameChange}
        onKeyDown={handleKeyDown}
      />
      <button
        className='flex-shrink-0 flex-grow-0 icon-btn btn-primary w-7.5 h-7.5 rounded-lg ml-1'
        onClick={handleUpdateTeamName}
        disabled={disabled}
      >
        <MarkIcon />
      </button>
      {editingError && (
        <div className='text-red-002 absolute -bottom-3 left-3 text-2xs'>
          {editingError}
        </div>
      )}
    </div>
  );
}
