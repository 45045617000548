import { useEffect, useState } from 'react';
import { useEffectOnce, usePrevious } from 'react-use';

import {
  assertExhaustive,
  type HeadToHeadBlock,
  HeadToHeadBlockGameSessionStatus,
} from '@lp-lib/game';

import { useLiveCallback } from '../../../../hooks/useLiveCallback';
import { fromMediaDataDTO, fromMediaDTO } from '../../../../utils/api-dto';
import { useSnapshot } from '../../../../utils/valtio';
import { useGainPointsAnimationGamePlayTrigger } from '../../../GainPointsAnimation/useGainPointsAnimationGamePlayTrigger';
import { FloatBoard, FloatLayout } from '../../../Layout';
import { LayoutAnchor } from '../../../LayoutAnchors/LayoutAnchors';
import { useSyncPersistentPointsRevealAnswer } from '../../../PersistentPoints/Provider';
import {
  usePixelFxDraw,
  usePixelFxUpdate,
} from '../../../PixelFx/PixelFxProvider';
import { useMyTeamId } from '../../../Player';
import { useSoundEffect } from '../../../SFX';
import { useGameSessionStatus } from '../../hooks/gameSessionHooks';
import {
  buildGamePlayMedia,
  GamePlayMediaPlayer,
  type GamePlayMediaPlayerLayout,
  useGamePlayMediaPlayable,
} from '../Common/GamePlay/GamePlayMedia';
import { GamePlayMediaLayout } from '../Common/GamePlay/GamePlayMediaLayout';
import { useGamePlayUITransitionControl } from '../Common/GamePlay/GamePlayUtilities';
import {
  type GamePlayMedia,
  type GamePlayProps,
} from '../Common/GamePlay/types';
import { useRankedTeamScores, useStableBlock } from '../Common/hooks';
import { useHeadToHeadGamePlayAPI } from './HeadToHeadBlockProvider';
import { useSyncLocalTimer } from './HeadToHeadGameTimer';
import { HeadToHeadPlayground } from './HeadToHeadPlayground';
import { type H2HPlayerRole } from './types';
import { HeadToHeadUtils } from './utils';

function Results(props: GamePlayProps<HeadToHeadBlock>): JSX.Element | null {
  const { block } = props;
  const teamScores = useRankedTeamScores('currentScore', null, 'always');
  const myTeamId = useMyTeamId();

  const { play } = useSoundEffect('showResults');

  useEffect(() => {
    if (!block.fields.showResults) return;
    play();
  }, [block.fields.showResults, play]);

  if (!block.fields.showResults) {
    return null;
  }

  return (
    <>
      <FloatBoard
        containerZIndex='z-20'
        containerDisplay='flex'
        bgStyle='border-2 border-cyan rounded-xl bg-black bg-opacity-60'
        title='GAME RESULTS'
      >
        <div className='w-full h-full pt-10 pb-20 px-16 overflow-y-auto scrollbar flex flex-col'>
          {teamScores.map((s) => {
            return (
              <div
                key={s.team.id}
                className={`w-full animate-fade-in-up min-h-12 mb-4 pl-6 rounded-3xl bg-black bg-opacity-95 flex flex-row justify-between items-center ${
                  s.team.id === myTeamId ? 'text-tertiary' : 'text-white'
                }`}
              >
                <p className={`text-lg font-bold font-cairo`}>{s.team.name}</p>
                <div className='flex flex-row justify-center items-center'>
                  <div className='w-11 h-11 mr-0.75 ml-3 rounded-full bg-warning text-black flex flex-col justify-center items-center'>
                    <div className='font-extrabold text-xl leading-5 font-cairo'>
                      {s.currentScore}
                    </div>
                    <p className='text-3xs text-black'>pts</p>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </FloatBoard>
      <FloatLayout className='flex items-center justify-center'>
        <LayoutAnchor
          id='gameplay-points-animation-top'
          className='w-full max-w-4xl'
        />
      </FloatLayout>
    </>
  );
}

function EmojisAnimationRender(props: { group: H2HPlayerRole }) {
  const api = useHeadToHeadGamePlayAPI();
  const emoji = api.deps.emoji[props.group];

  const shouldTick = useSnapshot(emoji.state).emojisCount > 0;

  useEffect(() => {
    emoji.on();
    return () => emoji.off();
  }, [emoji]);

  usePixelFxDraw(shouldTick ? emoji.draw : null);
  usePixelFxUpdate(shouldTick ? emoji.update : null);

  return null;
}

export function HeadToHeadBlockGamePlay(
  props: GamePlayProps<HeadToHeadBlock>
): JSX.Element | null {
  const block = useStableBlock(props.block);
  const gss = useGameSessionStatus<HeadToHeadBlockGameSessionStatus>();
  const prevGss = usePrevious(gss);
  const [showResults, setShowResults] = useState(false);
  const [gamePlayMedia, setGamePlayMedia] = useState<{
    media: Nullable<GamePlayMedia, false>;
    layout: GamePlayMediaPlayerLayout | null;
    loop: boolean;
  } | null>(null);
  const api = useHeadToHeadGamePlayAPI();

  useEffect(() => {
    const aborter = new AbortController();
    api.on(aborter);
    return () => {
      api.off();
      aborter.abort();
    };
  }, [api]);

  useEffect(() => {
    return () => {
      api.reset();
    };
  }, [api]);

  const setBackgroundMedia = useLiveCallback(() => {
    setGamePlayMedia({
      media: buildGamePlayMedia(
        {
          media: fromMediaDTO(block.fields.background?.media),
          mediaData: fromMediaDataDTO(block.fields.background?.data),
        },
        {
          stage: 'custom',
          isBackgroundMedia: true,
        }
      ),
      layout: 'fullscreen',
      loop: !!block.fields.background?.data?.loop,
    });
  });

  useEffect(() => {
    if (prevGss === gss) return;

    switch (gss) {
      case HeadToHeadBlockGameSessionStatus.LOADED:
        break;
      case HeadToHeadBlockGameSessionStatus.GAME_INIT:
        setBackgroundMedia();
        break;
      case HeadToHeadBlockGameSessionStatus.GAME_INTRO:
        break;
      case HeadToHeadBlockGameSessionStatus.GAME_START:
        break;
      case HeadToHeadBlockGameSessionStatus.GAME_END:
        break;
      case HeadToHeadBlockGameSessionStatus.RESULTS:
        setGamePlayMedia(null);
        setShowResults(true);
        break;
      case HeadToHeadBlockGameSessionStatus.END:
        setShowResults(false);
        break;
      case null:
      case undefined:
        break;
      default:
        assertExhaustive(gss);
        break;
    }
  }, [block.fields.showResults, gss, prevGss, setBackgroundMedia]);

  const inGame =
    !!gss &&
    gss >= HeadToHeadBlockGameSessionStatus.GAME_INIT &&
    gss <= HeadToHeadBlockGameSessionStatus.GAME_END;

  const gameEnd = gss === HeadToHeadBlockGameSessionStatus.GAME_END;

  useSyncLocalTimer(block, inGame && !gameEnd);
  useSyncPersistentPointsRevealAnswer(showResults);
  useGainPointsAnimationGamePlayTrigger();
  useEffectOnce(() => {
    HeadToHeadUtils.PreloadAssets();
  });

  const [uiState] = useGamePlayUITransitionControl();
  const mediaPlayable = useGamePlayMediaPlayable({
    block,
    gameSessionStatus: gss,
    media: gamePlayMedia?.media ?? null,
    state: uiState,
    // This is only used for background media.
    // Play the background media when the game started
    custom: () => !!gss && gss === HeadToHeadBlockGameSessionStatus.GAME_START,
  });

  const fullscreen = gamePlayMedia?.layout === 'fullscreen';

  return (
    <div className='fixed w-screen h-screen text-white'>
      <GamePlayMediaLayout fullscreen={fullscreen}>
        {gamePlayMedia?.media && gamePlayMedia.layout && (
          <GamePlayMediaPlayer
            gamePlayMedia={gamePlayMedia.media}
            play={mediaPlayable}
            mode={fullscreen ? 'full' : 'small'}
            layout={gamePlayMedia.layout}
            loop={gamePlayMedia.loop}
          />
        )}
      </GamePlayMediaLayout>
      {inGame && <HeadToHeadPlayground block={block} ended={gameEnd} />}
      {showResults && <Results block={block} />}
      <EmojisAnimationRender group='groupA' />
      <EmojisAnimationRender group='groupB' />
    </div>
  );
}
