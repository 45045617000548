export function SlackChannelInput(props: {
  name: string;
  onChange: (name: string) => void;
  className?: string;
  disabled?: boolean;
}) {
  const handleChange = (name: string) => {
    const normalized = name
      .toLowerCase()
      .replace(' ', '-')
      .replace(/[^a-zA-Z0-9/-]+/, '');
    props.onChange(normalized);
  };

  return (
    <input
      className={props.className}
      placeholder={'#enter-slack-channel-name'}
      value={props.name}
      onChange={(e) => handleChange(e.target.value)}
      disabled={props.disabled}
    />
  );
}
