import { type ReactNode } from 'react';

export function GamePlayFullScreenTransitionContainer(props: {
  children?: ReactNode;
}): JSX.Element {
  // NOTE: z-25 is the same as scoreboard and minigame transition. May need it
  // to be under...

  return (
    <div
      className='
        absolute top-0 left-0
        z-25
        w-screen h-screen
        flex flex-col justify-center items-center
      '
    >
      {props.children}
    </div>
  );
}
