import { useMemo } from 'react';
import { type SingleValue } from 'react-select';
import Select from 'react-select';
import useSWRImmutable from 'swr/immutable';

import { type ModelsHeyGenAvatar } from '@lp-lib/api-service-client/public';

import { apiService } from '../../services/api-service';
import { err2s } from '../../utils/common';
import { buildReactSelectStyles } from '../../utils/react-select';
import { type Option } from '../common/Utilities';
import { Loading } from '../Loading';

function useAvatars() {
  return useSWRImmutable(
    '/heygen/avatars',
    async () => (await apiService.tts.listHeyGenAvatars()).data.avatars || []
  );
}

function useAvatar(avatarId: string | null) {
  const { data: avatars } = useAvatars();
  return avatarId ? avatars?.find((a) => a.avatar_id === avatarId) : null;
}

export function HeyGenAvatarSelect(props: {
  avatarId: string | null;
  onChange: (avatar: ModelsHeyGenAvatar) => void;
}) {
  const styles = useMemo(
    () =>
      buildReactSelectStyles<Option<ModelsHeyGenAvatar | null>>({
        override: {
          control: {
            height: '40px',
          },
        },
      }),
    []
  );
  const { data: avatars, isLoading, error } = useAvatars();

  const options = useMemo(() => {
    if (!avatars) return [];

    const opts: Option<ModelsHeyGenAvatar | null>[] = avatars
      .sort((a, b) => a.avatar_name.localeCompare(b.avatar_name))
      .map((a) => ({
        label: a.avatar_name,
        value: a,
      }));
    return opts;
  }, [avatars]);

  const handleAvatarChange = (
    option: SingleValue<Option<ModelsHeyGenAvatar | null>>
  ) => {
    if (!option?.value) return;
    props.onChange(option.value);
  };

  if (isLoading) {
    return <Loading containerClassName='justify-start' />;
  }

  if (error) {
    return <div className='text-3xs text-red-002'>{err2s(error)}</div>;
  }

  return (
    <Select<Option<ModelsHeyGenAvatar | null>>
      className='w-full'
      classNamePrefix='select-box-v2'
      options={options}
      onChange={handleAvatarChange}
      styles={styles}
      formatOptionLabel={(opt) => (
        <span className='text-white'>{opt.label}</span>
      )}
      value={options.find((o) => o.value?.avatar_id === props.avatarId) ?? null}
      placeholder='Select Avatar'
      isClearable
      filterOption={(opt, input) => {
        const avatar = opt.data.value;
        if (!avatar) return false;
        return (
          avatar.avatar_name.toLowerCase().includes(input.toLowerCase()) ||
          avatar.avatar_id.toLowerCase().includes(input.toLowerCase())
        );
      }}
    />
  );
}

export function HeyGenAvatarPreview(props: {
  avatarId: string | null;
  className?: string;
}) {
  const avatar = useAvatar(props.avatarId);
  if (!avatar) return null;
  return (
    <video
      src={avatar.preview_video_url}
      className={props.className}
      controls
      style={{ aspectRatio: '16 / 9' }}
    />
  );
}
