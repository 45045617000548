import { assertExhaustive } from '@lp-lib/game';

import { type FirebaseValueHandle } from '../../../../Firebase';
import { type GroupId, type TutorialProgress, TutorialStep } from '../types';
import { log } from '../utils';
import { type OrderManager } from './OverRoastedOrderManager';

export class TutorialManager {
  private currentProgress: Nullable<TutorialProgress>;

  constructor(
    public groupId: GroupId,
    private fbHandle: FirebaseValueHandle<TutorialProgress>,
    private orderManager: OrderManager
  ) {}

  async start(): Promise<void> {
    log.info('start tutorial');

    this.fbHandle.on((next) => {
      if (next?.step && this.currentProgress?.step !== next.step) {
        this.onStep(next.step);
      }
      this.currentProgress = next;
    });

    await this.fbHandle.set({
      groupId: this.groupId,
      step: TutorialStep.LearnOrder,
    });
  }

  stop(): void {
    this.fbHandle.off();
  }

  private onStep(step: TutorialStep): void {
    switch (step) {
      case TutorialStep.LearnOrder:
        this.orderManager.build(1, 1);
        return;
      case TutorialStep.OneIngredientOrder:
        return;
      case TutorialStep.Free:
        this.orderManager.build(2);
        this.orderManager.startAutomation();
        return;
      default:
        assertExhaustive(step);
        return;
    }
  }
}
