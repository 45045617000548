export function LiteModeIcon(): JSX.Element {
  return (
    <div
      className='
        cursor-pointer
        rounded-full font-bold text-white text-4xs
        text-center
        px-1.5 py-0.5
        bg-primary uppercase
        flex justify-center items-center
      '
    >
      LITE
    </div>
  );
}
