import React, {
  type ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import ContentLoader, { type IContentLoaderProps } from 'react-content-loader';
import { type Settings as SliderSettings } from 'react-slick';
import { useWindowSize } from 'react-use';

import {
  BreadcrumbChain,
  type BreadcrumbNode,
} from '../../components/Breadcrumbs';
import {
  ArrowNext,
  ArrowPrev,
  type EmbedContext,
  type GameLikeContext,
  type GameLikeMode,
  useActiveInstanceWatcher,
  useGameLikeWorkspace,
  type WritableEmbedContext,
} from '../../components/Game/GameCenter';
import { useGameEditorStore } from '../../components/Game/GameEditorStore';
import { useInstance } from '../../hooks/useInstance';
import { useQueryParam } from '../../hooks/useQueryParam';
import { apiService } from '../../services/api-service';
import { type Game } from '../../types/game';
import { getGridStyle } from '../../utils/css';

const MyGameLoader = (props: IContentLoaderProps): JSX.Element => (
  <ContentLoader
    width={400}
    height={60}
    viewBox='0 0 400 60'
    backgroundColor='#101012'
    foregroundColor='#161616'
    {...props}
  >
    <rect x='6' y='6' rx='8' ry='8' width='88' height='48' />
    <rect x='112' y='12' rx='4' ry='4' width='50%' height='18' />
    <rect x='112' y='36' rx='4' ry='4' width='25%' height='13' />
  </ContentLoader>
);

export type GamePickedProps = {
  onGameClick?: (game: Game) => void;
};

export interface GameGridSettings {
  cols: number;
  rows: number;
}

const defaultGridSettings: GameGridSettings = {
  cols: 3,
  rows: 4,
};

type MinigameContext = GameLikeContext<Game> & {
  gridSettings: GameGridSettings & { total: number };
};

const Context = React.createContext<MinigameContext | null>(null);

export function useMinigameContext(): MinigameContext {
  const ctx = useContext(Context);
  if (!ctx) {
    throw new Error('MinigameContext is not in the tree!');
  }
  return ctx;
}

export function useGameLoader(options?: {
  useDynamicRows?: boolean;
  gamesCount?: number;
}): JSX.Element {
  const ctx = useMinigameContext();
  const { height } = useWindowSize();
  const rows = options?.useDynamicRows
    ? Math.round(height / 60)
    : options?.gamesCount
    ? Math.ceil(options?.gamesCount / 3)
    : ctx.gridSettings.rows;
  return (
    <div
      className={`grid gap-3 w-full h-full`}
      style={{ gridTemplateColumns: getGridStyle(ctx.gridSettings.cols) }}
    >
      {[...Array(ctx.gridSettings.cols * rows)].map((_, i) => (
        <MyGameLoader key={i} />
      ))}
    </div>
  );
}

export function useGameSliderSettings(): SliderSettings {
  const sliderSettings: SliderSettings = useMemo(() => {
    return {
      infinite: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      responsive: [],
      nextArrow: <ArrowNext />,
      prevArrow: <ArrowPrev />,
      className: 'minigame',
    };
  }, []);
  return sliderSettings;
}

function useEditingInstanceWatcher(): void {
  const view = useQueryParam('view');
  const [activeGame] = useGameLikeWorkspace('game', 'active');
  const store = useGameEditorStore();
  useEffect(() => {
    if (!activeGame || view !== 'edit') return;
    store.setEditingGame(activeGame, { fetchBlocks: true });
  }, [activeGame, store, view]);
}

type SharedProps = {
  breadcrumb?: BreadcrumbNode;
  gridSettings?: GameGridSettings;
  children?: ReactNode;
};

type ProviderProps =
  | (SharedProps & {
      embed: false;
      routePrefix: string;
      mode: GameLikeMode;
    })
  | (SharedProps & {
      embed: true;
      handleAdd?: (game: Game) => void;
      handleRemove?: (game: Game) => void;
      picked?: (id: string) => boolean;
    });

async function getGameById(id: string): Promise<Game> {
  const resp = await apiService.game.getGameById(id);
  return resp.data.game;
}

export function MinigameContextProvider(props: ProviderProps): JSX.Element {
  const picked = props.embed ? props.picked : undefined;
  const [embedCtx, setEmbedCtx] = useState<EmbedContext<Game>>({
    handleAdd: props.embed ? props.handleAdd : undefined,
    handleRemove: props.embed ? props.handleRemove : undefined,
    picked: picked,
  });
  const mode = props.embed ? 'host' : props.mode;
  const breadcrumbs = useInstance(() => new BreadcrumbChain(props.breadcrumb));
  const gridSettingsRaw = props.gridSettings || defaultGridSettings;

  useEffect(() => {
    if (!picked) return;
    setEmbedCtx((prev) => {
      return { ...prev, picked };
    });
  }, [picked]);

  useActiveInstanceWatcher({
    type: 'game',
    key: 'gameId',
    getInstanceById: getGameById,
    disabled: props.embed,
  });

  useEditingInstanceWatcher();

  useEffect(() => {
    return () => {
      breadcrumbs.reset();
    };
  }, [breadcrumbs]);

  const updateEmbedCtx = useCallback((ctx: WritableEmbedContext | null) => {
    setEmbedCtx((prev) => {
      if (ctx === null) {
        return { ...prev, search: undefined, tag: undefined };
      }
      return { ...prev, ...ctx };
    });
  }, []);

  const prefix = !props.embed ? props.routePrefix : '';

  const ctxValue: MinigameContext = useMemo(() => {
    const gridSettings = {
      ...gridSettingsRaw,
      total: gridSettingsRaw.rows * gridSettingsRaw.cols,
    };

    return props.embed
      ? {
          embed: true,
          embedCtx,
          updateEmbedCtx,
          breadcrumbs,
          gridSettings,
          pageType: 'default',
        }
      : {
          routePrefix: prefix,
          editRoutePrefix: prefix,
          embed: false,
          mode: mode,
          breadcrumbs,
          gridSettings,
          pageType: 'default',
        };
  }, [
    breadcrumbs,
    mode,
    embedCtx,
    gridSettingsRaw,
    prefix,
    props.embed,
    updateEmbedCtx,
  ]);

  return <Context.Provider value={ctxValue}>{props.children}</Context.Provider>;
}
