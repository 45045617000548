// https://github.com/bryc/code/blob/master/jshash/PRNGs.md#splitmix32
/* eslint-disable no-var */
// prettier-ignore
function splitmix32(a: number) {
  return function() {
    a |= 0; a = a + 0x9e3779b9 | 0;
    var t = a ^ a >>> 15; t = Math.imul(t, 0x85ebca6b);
        t = t ^ t >>> 13; t = Math.imul(t, 0xc2b2ae35);
    return ((t = t ^ t >>> 16) >>> 0) / 4294967296;
  }
}
/* eslint-enable no-var */

// https://github.com/bryc/code/blob/master/jshash/PRNGs.md#addendum-a-seed-generating-functions
/* eslint-disable no-var,@typescript-eslint/no-unused-expressions,no-sequences */
// prettier-ignore
function xmur3(str: string) {
  for (var i = 0, h = 1779033703 ^ str.length; i < str.length; i++)
    (h = Math.imul(h ^ str.charCodeAt(i), 3432918353)),
      (h = (h << 13) | (h >>> 19));
  return function () {
    (h = Math.imul(h ^ (h >>> 16), 2246822507)),
      (h = Math.imul(h ^ (h >>> 13), 3266489909));
    return (h ^= h >>> 16) >>> 0;
  };
}
/* eslint-enable no-var,@typescript-eslint/no-unused-expressions,no-sequences */

export function createSeedablePRNG(seed: string = new Date().toISOString()) {
  const seedFn = xmur3(seed);
  return splitmix32(seedFn());
}

/**
 * Creates a shallow copy of the array and shuffles using a stable PRNG.
 */
export function seededShuffle<T>(
  array: T[],
  seed: string = new Date().toISOString()
) {
  const prng = createSeedablePRNG(seed);
  const copy = array.slice();
  shuffleArr(copy, prng);
  return copy;
}

/**
 * In-place fischer-yates shuffle.
 */
export function shuffleArr<T>(array: T[], rng = () => Math.random()) {
  for (let i = array.length - 1; i > 0; i--) {
    const rand = Math.floor(rng() * (i + 1));
    [array[i], array[rand]] = [array[rand], array[i]];
  }
}
