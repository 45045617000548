import { useEffect } from 'react';

import { type DrawingPromptBlock } from '@lp-lib/game';

import { LayoutAnchor } from '../../../LayoutAnchors/LayoutAnchors';
import { useParticipantByUserId } from '../../../Player';
import { useSoundEffect } from '../../../SFX';
import { useAllDrawings } from './DrawingPromptProvider';
import { useCardWidth } from './hooks';
import { type MiniDrawing } from './types';

function DrawingCard(props: { drawing: MiniDrawing; width: number }) {
  const { drawing, width } = props;
  const participant = useParticipantByUserId(drawing.playerId);
  return (
    <div
      className='flex flex-col items-center gap-2 px-2 mb-4'
      style={{
        width: `${width}px`,
      }}
    >
      <div
        className='w-full rounded-xl'
        style={{
          backgroundImage: `url(${drawing.url})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          aspectRatio: '1/1',
        }}
      ></div>
      <div className='text-white text-sms'>
        {participant?.firstName ?? participant?.username ?? 'N/A'}
      </div>
    </div>
  );
}

export function ReviewAllDrawings(_props: {
  block: DrawingPromptBlock;
}): JSX.Element {
  const drawings = useAllDrawings();
  const maxCols = 4;
  const width = useCardWidth(Math.min(drawings.length, maxCols), 5 / 6, 160);
  const justifyContent =
    drawings.length < maxCols ? 'justify-center' : 'justify-start';
  const { play: playApplauseSFX } = useSoundEffect('drawingApplause');

  useEffect(() => {
    playApplauseSFX();
  }, [playApplauseSFX]);

  return (
    <div className='w-full h-full flex flex-col items-center relative px-4 gap-4'>
      <div className='text-white text-2xl text-center'>
        Hold tight while we tally the scores. Take a look at all the amazing art
        from this round while you wait!{' '}
      </div>
      <div className='w-full h-full relative overflow-y-auto scrollbar'>
        <LayoutAnchor
          id='drawing-present-spacing-anchor'
          className='absolute w-full h-full z-0'
        />
        <div
          className={`w-full h-full flex flex-wrap items-center ${justifyContent}`}
        >
          {drawings.map((drawing) => (
            <DrawingCard key={drawing.id} drawing={drawing} width={width} />
          ))}
        </div>
      </div>
    </div>
  );
}
