import { useMemo } from 'react';
import { useSnapshot } from 'valtio';

import { MAX_STAGE_MEMBERS, StageMode } from '../../types';
import { StageUtils } from '../../utils';
import { useStageContext } from '../Provider';
import { useStageMemberVOMap } from './useSelectStageMembers';

export function useStageMode(): StageMode {
  const ctx = useStageContext();
  return useSnapshot(ctx.state).stage.mode;
}

export function useIsStageFull(): boolean {
  const { state } = useStageContext();
  const memberVOMap = useStageMemberVOMap();
  const mode = useSnapshot(state).stage.mode;
  return useMemo(() => {
    const memberCount = StageUtils.GetMembersCount(memberVOMap);
    if (mode === StageMode.H2H) return memberCount >= 3;
    return memberCount >= MAX_STAGE_MEMBERS;
  }, [memberVOMap, mode]);
}

export function useIsStageEmpty(): boolean {
  const memberVOMap = useStageMemberVOMap();
  return useMemo(() => {
    const memberCount = StageUtils.GetMembersCount(memberVOMap);
    return memberCount <= 1;
  }, [memberVOMap]);
}

export function useSelectIsH2HAvailable(): boolean {
  const ctx = useStageContext();
  return useSnapshot(ctx.state).h2hEnabled;
}
