/**
 * Recursively remove undefined values from an object, while preserving `null`
 * values. Firebase treats `undefined` as an error when writing, and `nulls` as
 * a delete. https://jsbench.me/6nlvwtop29/1
 */
export function makeFirebaseSafe<Obj extends Record<string, unknown>>(
  obj: Obj,
  errors_out: unknown[] = []
) {
  const keys = Object.keys(obj);
  for (const key of keys) {
    const value = obj[key];
    try {
      if (value === undefined) {
        delete obj[key];
      } else if (value && typeof value === 'object') {
        (obj[key] as Record<string, unknown>) = makeFirebaseSafe(
          value as Record<string, unknown>
        );
      }
    } catch (err) {
      errors_out.push(err);
    }
  }

  return obj;
}
