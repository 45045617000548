import { useCallback, useEffect, useRef } from 'react';

export class ResumableTimeout {
  private aborter = new AbortController();
  private info: ResumableTimeoutInfo = {
    state: 'paused',
    resumableDelay: null,
    targetNext: 0,
    timeAtAbort: null,
  };

  constructor(
    private cb: () => void,
    public readonly timeoutMs: number,
    public readonly repeats = false
  ) {
    this.pause(false);
  }

  get paused(): boolean {
    return this.info.state === 'paused';
  }

  get canceled(): boolean {
    return this.info.state === 'canceled';
  }

  private resume(): void {
    this.info.state = 'running';
    this.aborter = new AbortController();
    if (this.repeats) {
      pauseableInterval(
        this.cb,
        this.timeoutMs,
        this.aborter.signal,
        this.info
      );
    } else {
      resumableTimeout(this.cb, this.timeoutMs, this.aborter.signal, this.info);
    }
  }

  cancel(): void {
    this.pause(true);
    this.info.state = 'canceled';
  }

  pause(paused: boolean): void {
    if (this.paused === paused) return;
    if (paused) {
      this.info.state = 'paused';
      this.aborter.abort();
    } else {
      this.resume();
    }
  }
}

export function useResumableTimeout(repeats = false): {
  setPaused: (paused: boolean) => void;
  setCb: (cb: () => void, durationMs: number) => void;
} {
  const ref = useRef<ResumableTimeout | null>(null);
  const durationMsRef = useRef<number | null>(null);
  const callbackRef = useRef<null | (() => void)>(null);

  const setCb = useCallback(
    (cb: () => void, durationMs: number) => {
      if (
        ref.current === null ||
        durationMs !== durationMsRef.current ||
        cb !== callbackRef.current
      ) {
        // reset state
        ref.current?.cancel();
        ref.current = new ResumableTimeout(cb, durationMs, repeats);
      }

      durationMsRef.current = durationMs;
      callbackRef.current = cb;
    },
    [repeats]
  );

  const setPaused = useCallback((paused: boolean) => {
    if (ref.current === null) return paused;
    return ref.current.pause(paused);
  }, []);

  useEffect(() => {
    // Teardown on unmount
    return () => {
      ref.current?.cancel();
    };
  }, []);

  return { setCb, setPaused };
}

type ResumableTimeoutInfo = {
  resumableDelay: null | number;
  timeAtAbort: null | number;
  targetNext: number;
  state: 'running' | 'paused' | 'canceled';
};

const getCurrentTime = () =>
  document.timeline && document.timeline.currentTime !== null
    ? Number(document.timeline.currentTime)
    : window.performance.now();

function resumableTimeout(
  callback: () => void,
  timeoutMs: number,
  signal: AbortSignal,
  info: ResumableTimeoutInfo
) {
  const start = getCurrentTime();

  function frame() {
    if (signal.aborted) return;
    callback();
  }

  const onAbort = () => {
    info.timeAtAbort = performance.now();
    info.resumableDelay = info.targetNext - performance.now();
    signal.removeEventListener('abort', onAbort);
  };

  signal.addEventListener('abort', onAbort);

  function scheduleFrame(time: number) {
    const elapsed = time - start;
    const roundedElapsed = Math.round(elapsed / timeoutMs) * timeoutMs;
    const targetNext = start + roundedElapsed + timeoutMs;
    const delay =
      info.resumableDelay !== null
        ? info.resumableDelay
        : targetNext - performance.now();
    info.targetNext = targetNext;
    info.timeAtAbort = info.resumableDelay = null;
    setTimeout(frame, delay);
  }

  scheduleFrame(start);
}

function pauseableInterval(
  callback: () => void,
  timeoutMs: number,
  signal: AbortSignal,
  info: ResumableTimeoutInfo
) {
  let start = getCurrentTime();

  function frame() {
    if (signal.aborted) return;
    callback();
    start = getCurrentTime();
    scheduleFrame(start);
  }

  const onAbort = () => {
    info.timeAtAbort = performance.now();
    info.resumableDelay = info.targetNext - performance.now();
    signal.removeEventListener('abort', onAbort);
  };

  signal.addEventListener('abort', onAbort);

  function scheduleFrame(time: number) {
    const elapsed = time - start;
    const roundedElapsed = Math.round(elapsed / timeoutMs) * timeoutMs;
    const targetNext = start + roundedElapsed + timeoutMs;
    const delay =
      info.resumableDelay !== null
        ? info.resumableDelay
        : targetNext - performance.now();
    info.targetNext = targetNext;
    info.timeAtAbort = info.resumableDelay = null;
    setTimeout(frame, delay);
  }

  scheduleFrame(start);
}
