import { assertExhaustive } from './utils';

export type Profile = {
  width: number;
  height: number;
  delayMs: number;
  perStrip: number;
};

export enum ProfileIndex {
  wh100x100fps8, // Currently the default for Crowd View
  wh100x100fps6,
  wh100x100fps4,

  wh75x75fps8,
  wh75x75fps6,
  wh75x75fps4,

  wh50x50fps8,
  wh50x50fps6,
  wh50x50fps4,

  wh36x36fps8, // Currently the default for Teams Pane
  wh36x36fps6,
  wh36x36fps4,

  // Each strip contains 2 seconds
  wh100x100fps8strip16,
  wh75x75fps8strip16,
  wh50x50fps8strip16,
  wh36x36fps8strip16,

  // Each strip contains 1 frame (1/8th of a second)
  wh100x100fps8strip1,
  wh75x75fps8strip1,
  wh50x50fps8strip1,
  wh36x36fps8strip1,
}

export function profileFor(num: ProfileIndex): Profile {
  switch (num) {
    case ProfileIndex.wh100x100fps8:
      return { width: 100, height: 100, delayMs: 1000 / 8, perStrip: 8 };
    case ProfileIndex.wh100x100fps6:
      return { width: 100, height: 100, delayMs: 1000 / 6, perStrip: 6 };
    case ProfileIndex.wh100x100fps4:
      return { width: 100, height: 100, delayMs: 1000 / 4, perStrip: 4 };

    case ProfileIndex.wh75x75fps8:
      return { width: 75, height: 75, delayMs: 1000 / 8, perStrip: 8 };
    case ProfileIndex.wh75x75fps6:
      return { width: 75, height: 75, delayMs: 1000 / 6, perStrip: 6 };
    case ProfileIndex.wh75x75fps4:
      return { width: 75, height: 75, delayMs: 1000 / 4, perStrip: 4 };

    case ProfileIndex.wh50x50fps8:
      return { width: 50, height: 50, delayMs: 1000 / 8, perStrip: 8 };
    case ProfileIndex.wh50x50fps6:
      return { width: 50, height: 50, delayMs: 1000 / 6, perStrip: 6 };
    case ProfileIndex.wh50x50fps4:
      return { width: 50, height: 50, delayMs: 1000 / 4, perStrip: 4 };

    case ProfileIndex.wh36x36fps8:
      return { width: 36, height: 36, delayMs: 1000 / 8, perStrip: 8 };
    case ProfileIndex.wh36x36fps6:
      return { width: 36, height: 36, delayMs: 1000 / 6, perStrip: 6 };
    case ProfileIndex.wh36x36fps4:
      return { width: 36, height: 36, delayMs: 1000 / 4, perStrip: 4 };

    case ProfileIndex.wh100x100fps8strip16:
      return { width: 100, height: 100, delayMs: 1000 / 8, perStrip: 16 };
    case ProfileIndex.wh75x75fps8strip16:
      return { width: 75, height: 75, delayMs: 1000 / 8, perStrip: 16 };
    case ProfileIndex.wh50x50fps8strip16:
      return { width: 50, height: 50, delayMs: 1000 / 8, perStrip: 16 };
    case ProfileIndex.wh36x36fps8strip16:
      return { width: 36, height: 36, delayMs: 1000 / 8, perStrip: 16 };

    case ProfileIndex.wh100x100fps8strip1:
      return { width: 100, height: 100, delayMs: 1000 / 8, perStrip: 1 };
    case ProfileIndex.wh75x75fps8strip1:
      return { width: 75, height: 75, delayMs: 1000 / 8, perStrip: 1 };
    case ProfileIndex.wh50x50fps8strip1:
      return { width: 50, height: 50, delayMs: 1000 / 8, perStrip: 1 };
    case ProfileIndex.wh36x36fps8strip1:
      return { width: 36, height: 36, delayMs: 1000 / 8, perStrip: 1 };

    default:
      assertExhaustive(num);
      return { width: 36, height: 36, delayMs: 1000 / 8, perStrip: 8 };
  }
}

export type ClientId = string & { __clientUuid: 'isClientUuid' };

export type ProfileAddress = string & {
  __profileAddress: 'ClientId:ProfileIndex';
};

export function toProfileAddress(
  clientId: ClientId,
  profile: ProfileIndex
): ProfileAddress {
  return `${clientId}:${profile}` as ProfileAddress;
}

export type ProfileAddressParts = { clientId: ClientId; profile: ProfileIndex };

export function fromProfileAddress(
  address: ProfileAddress
): null | ProfileAddressParts {
  const [clientId, profile] = address.split(':');
  if (!clientId || !profile) return null;
  return {
    clientId: clientId as ClientId,
    profile: parseInt(profile, 10) as ProfileIndex,
  };
}

export function matchesProfileAddress(
  clientId: ClientId,
  address: ProfileAddress
): boolean {
  return !!address.match(clientId);
}
