import { type BPUnitlessProperties, BreakpointsEntries } from '../breakpoints';
import { uncheckedIndexAccess_UNSAFE } from '../utils/uncheckedIndexAccess_UNSAFE';
import { useWindowDimensions } from './useWindowDimensions';

export function useUnitlessMatch<
  Breakpoints extends { '': number } = BPUnitlessProperties
>(def: Breakpoints, useWindowDimensionsInjected = useWindowDimensions): number {
  const { width: windowWidth } = useWindowDimensionsInjected();

  let unitless = def[''];
  for (const [prefix, minWidth] of BreakpointsEntries) {
    if (minWidth <= windowWidth && prefix in def) {
      unitless = uncheckedIndexAccess_UNSAFE(def)[prefix];
    }
  }

  return unitless;
}
