import Select, { type SingleValue } from 'react-select';

import { VolumeLevel } from '@lp-lib/game';

import { useInstance } from '../../hooks/useInstance';
import { buildReactSelectStyles } from '../../utils/react-select';
import { type Option } from '../common/Utilities';

const volumeOptions: Option<VolumeLevel>[] = [
  {
    label: 'Full Volume',
    value: VolumeLevel.Full,
  },
  {
    label: 'Mid-High',
    value: VolumeLevel.MidHigh,
  },
  {
    label: 'Mid-Low',
    value: VolumeLevel.MidLow,
  },
  {
    label: 'Background',
    value: VolumeLevel.Background,
  },
];

export function VolumeSelect(props: {
  volumeLevel?: VolumeLevel | null;
  onChange: (updated: VolumeLevel) => void;
  disabled?: boolean;
}): JSX.Element {
  const { volumeLevel, onChange, disabled } = props;
  const styles = useInstance(() =>
    buildReactSelectStyles<Option<VolumeLevel>>({
      override: {
        valueContainer: {
          padding: '2px 0px 2px 8px',
        },
        dropdownIndicator: {
          padding: '0 4px 0 0',
        },
      },
    })
  );

  const handleSelect = (option: SingleValue<Option<VolumeLevel>>) => {
    if (!option) return;

    onChange(option.value);
  };

  return (
    <Select<Option<VolumeLevel>>
      options={volumeOptions}
      value={volumeOptions.find(
        (o) => o.value === (volumeLevel || VolumeLevel.Full)
      )}
      onChange={handleSelect}
      isDisabled={disabled}
      classNamePrefix='select-box-v2'
      className='w-28'
      styles={styles}
      components={{
        IndicatorSeparator: () => null,
      }}
    />
  );
}
