import { useRef } from 'react';

export type UncontrolledRangeProps = {
  name?: string;
  className?: string;
  min: number;
  max: number;
  step: number;
  defaultValue: number;
  disabled?: boolean;
  onChange: (value: number) => void;
};

export function UncontrolledRangeInput(
  props: UncontrolledRangeProps
): JSX.Element {
  const rangeRef = useRef<HTMLInputElement | null>(null);
  const numberRef = useRef<HTMLInputElement | null>(null);

  return (
    <div className={props.className}>
      <input
        ref={rangeRef}
        type='range'
        min={props.min}
        max={props.max}
        step={props.step}
        disabled={props.disabled}
        className='w-full flex-grow-0 flex-shrink'
        defaultValue={props.defaultValue}
        onChange={(e) => {
          if (numberRef.current) numberRef.current.value = e.target.value;
          props.onChange(e.target.valueAsNumber);
        }}
      />
      <input
        ref={numberRef}
        className='field flex-grow-0 flex-shrink-0 m-0 w-20'
        defaultValue={props.defaultValue}
        type='number'
        min={props.min}
        max={props.max}
        step={props.step}
        disabled={props.disabled}
        onChange={(e) => {
          // Ensure no NaNs, default to min.
          const raw = e.currentTarget.valueAsNumber;
          const initial = isNaN(raw) ? props.min : raw;
          // Force to remain within (min,max)
          const limited = Math.max(Math.min(initial, props.max), props.min);

          if (rangeRef.current) rangeRef.current.value = limited.toString();
          props.onChange(limited);
        }}
      />
    </div>
  );
}
