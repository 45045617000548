import { useEffect, useState } from 'react';

import {
  type CreativePromptBlock,
  CreativePromptBlockGameSessionStatus,
  GameSessionUtil,
} from '@lp-lib/game';

import { bps } from '../../../../breakpoints';
import { ClientTypeUtils } from '../../../../types/user';
import { nullOrUndefined } from '../../../../utils/common';
import { MediaPickPriorityHD, MediaUtils } from '../../../../utils/media';
import { useOneTimeAutomaticBroadcastToggleOff } from '../../../Broadcast';
import { useGainPointsAnimationGamePlayTrigger } from '../../../GainPointsAnimation/useGainPointsAnimationGamePlayTrigger';
import { FloatBoard } from '../../../Layout';
import { useSyncPersistentPointsRevealAnswer } from '../../../PersistentPoints/Provider';
import { useMyTeamId } from '../../../Player';
import { useSoundEffect } from '../../../SFX';
import { useUser } from '../../../UserContext';
import { useMyClientType } from '../../../Venue/VenuePlaygroundProvider';
import { GoAnimation, VoteAnimation } from '../../GameBlockCardAnimations';
import {
  useGameSessionLocalTimer,
  useGameSessionStatus,
  useGameSessionVenueId,
  useIsLiveGamePlay,
  useIsRecording,
  useTimerRecover,
} from '../../hooks';
import { countdownV2, resetTimer, setTimer } from '../../store';
import { GamePlayMediaLayout } from '../Common/GamePlay/GamePlayMediaLayout';
import { useGamePlayUIConfiguration } from '../Common/GamePlay/GamePlayUIConfigurationProvider';
import {
  buildGamePlayMedia,
  GamePlayInput,
  type GamePlayMedia,
  GamePlayMediaPlayer,
  type GamePlayUIState,
  type GamePlayUIStateControl,
  GameSubmissionInput,
  useCountdownPlaySFX,
  useGamePlayMediaPlayable,
  useGamePlayMediaUISync,
  useGamePlayUITransitionControl,
  useIsGamePlayReady,
} from '../Common/GamePlay/Internal';
import { ProgressRing } from '../Common/GamePlay/ProgressRing';
import { StageBgSingleFrame } from '../Common/GamePlay/StageBgSingleFrame';
import { useSubmissionStatusWaitEnder } from '../Common/GamePlay/SubmissionStatusProvider';
import { SubmissionStatusWidgetAttachedOnLeft } from '../Common/GamePlay/SubmissionStatusWidget';
import { CreativePromptAnswer } from './CreativePromptBlockGamePlayAnswer';
import {
  type CreativePromptResults,
  type Submission,
  useCreativePromptResults,
  useCreativePromptSubmissions,
} from './hooks';
import { CreativePromptBlockBlockUtils, vote } from './utils';

const SubmissionButton = ({
  teamId,
  answer,
  votedSubmissionTeamId,
  setVotedSubmissionTeamId,
  disabled = false,
}: {
  teamId: string;
  answer: string;
  votedSubmissionTeamId: string | null;
  setVotedSubmissionTeamId: (id: string) => void;
  disabled?: boolean;
}): JSX.Element => {
  const myTeamId = useMyTeamId();

  const [isClicked, setIsClicked] = useState(false);

  const isMyTeamSubmission = teamId === myTeamId;
  const isSelected = teamId === votedSubmissionTeamId;
  const user = useUser();

  const onVote = async (e: React.MouseEvent) => {
    if (isMyTeamSubmission) return;

    if (e && e.isTrusted) {
      vote(teamId, user.id);
      setVotedSubmissionTeamId(teamId);
    }
  };

  const onButtonPress = () => {
    if (isMyTeamSubmission) return;

    setIsClicked(true);
  };

  const buttonStyles =
    isClicked || isSelected
      ? 'transform translate-y-1.3 from-yellow-start to-yellow-end'
      : 'from-primary-start to-primary-end shadow-submission-btn';

  return (
    <div className='relative animate-fade-in-up flex flex-col justify-center items-center'>
      <button
        type='button'
        title={
          isMyTeamSubmission ? 'Can’t vote for your own answer!' : undefined
        }
        onClick={onVote}
        onMouseDown={onButtonPress}
        disabled={disabled}
        className={`relative mb-1.5 w-68 min-h-12.5 px-2 py-2 
        flex flex-col justify-center items-center appearance-none outline-none focus:outline-none 
        border border-white-001 rounded-3xl
        ${
          isMyTeamSubmission
            ? 'cursor-not-allowed opacity-80'
            : 'hover:from-primary-hoverstart hover:to-primary-hoverend'
        }
        bg-gradient-to-tr ${buttonStyles}
        disabled:cursor-auto disabled:pointer-events-off
        ${isSelected ? 'disabled:opacity-100' : 'disabled:opacity-80'}`}
      >
        {isMyTeamSubmission && (
          <p className='mb-1 text-4xs font-medium'>Your Team’s submission</p>
        )}
        <p className='w-full font-cairo text-lg leading-5 font-semibold line-clamp-6 hyphens-auto'>
          {answer}
        </p>
      </button>
      {isMyTeamSubmission && (
        <p className='mt-0.75 text-3xs'>You can’t vote for your own Answer</p>
      )}
    </div>
  );
};

const Results = ({ blockId }: { blockId: string }): JSX.Element => {
  const [results, setResults] = useState<CreativePromptResults[]>([]);
  const myTeamId = useMyTeamId();
  useCreativePromptResults(blockId, setResults);

  return (
    <div className='w-full h-full px-16 overflow-y-auto scrollbar'>
      <div className='w-full h-full flex flex-col'>
        {results
          .filter((d) => d.answer)
          .map((data, i) => (
            <div
              key={`results-${i}`}
              className={`w-full animate-fade-in-up min-h-12 mb-4 pl-6 rounded-3xl bg-black bg-opacity-50 flex flex-row justify-between items-center ${
                data.teamId === myTeamId ? 'text-tertiary' : 'text-white'
              }`}
            >
              <p
                className={`text-lg font-bold font-cairo ${
                  !data.answer && 'opacity-10'
                }`}
              >
                {data.answer}
              </p>
              <div className='flex flex-row justify-center items-center'>
                <p className='text-3xs font-bold'>({data.teamName})</p>
                <div className='w-11 h-11 mr-0.75 ml-3 rounded-full bg-warning text-black flex flex-col justify-center items-center'>
                  <div className='font-extrabold text-xl leading-5 font-cairo'>
                    {data.votes || 0}
                  </div>
                  <p className='text-3xs text-black'>Votes</p>
                </div>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

const VotingStage = (props: {
  gameSessionBlock: CreativePromptBlock;
  uiState: GamePlayUIState;
  uiControl: GamePlayUIStateControl;
}): JSX.Element => {
  const { gameSessionBlock, uiState, uiControl } = props;

  const [submissions, setSubmissions] = useState<Submission[]>([]);
  const [votedSubmissionTeamId, setVotedSubmissionTeamId] = useState<
    string | null
  >(null);

  const gameSessionStatus =
    useGameSessionStatus<CreativePromptBlockGameSessionStatus>();
  const gameSessionLocalTimer = useGameSessionLocalTimer();
  const myClientType = useMyClientType();
  const isLive = useIsLiveGamePlay();
  const isRecording = useIsRecording();

  useCreativePromptSubmissions(gameSessionBlock.id, setSubmissions);
  const submissionCount = submissions?.length ?? 0;
  const votingTime =
    !isLive || isRecording
      ? 10
      : Math.min(Math.round((5 + 2 * submissionCount) / 5) * 5, 60);
  const prompt = gameSessionBlock.fields.prompt ?? '';

  const { play: showSubmissionsSFX } = useSoundEffect('showSubmissions');
  const { play: showResultsSFX } = useSoundEffect('showResults');

  const isCounting =
    gameSessionStatus === CreativePromptBlockGameSessionStatus.VOTE_COUNTING;
  const isVotingEnd =
    gameSessionStatus === CreativePromptBlockGameSessionStatus.VOTE_END;
  const isShowingResults =
    gameSessionStatus === CreativePromptBlockGameSessionStatus.RESULTS ||
    gameSessionStatus ===
      CreativePromptBlockGameSessionStatus.POINTS_DISTRIBUTED;

  useCountdownPlaySFX(
    votingTime,
    gameSessionLocalTimer,
    gameSessionStatus === CreativePromptBlockGameSessionStatus.VOTE_COUNTING ||
      gameSessionStatus === CreativePromptBlockGameSessionStatus.VOTE_END
  );

  useEffect(() => {
    return () => {
      resetTimer('submission');
    };
  }, []);

  useOneTimeAutomaticBroadcastToggleOff(
    () =>
      gameSessionStatus === CreativePromptBlockGameSessionStatus.VOTE_COUNTING
  );

  useEffect(() => {
    if (
      votingTime !== null &&
      (isCounting ||
        gameSessionStatus ===
          CreativePromptBlockGameSessionStatus.SHOW_SUBMISSIONS)
    ) {
      setTimer('submission', votingTime);
    }
  }, [gameSessionStatus, isCounting, votingTime]);

  useEffect(() => {
    if (gameSessionStatus === CreativePromptBlockGameSessionStatus.VOTE_END) {
      resetTimer('submission');
    }
  }, [gameSessionStatus]);

  useEffect(() => {
    if (
      gameSessionStatus ===
      CreativePromptBlockGameSessionStatus.SHOW_SUBMISSIONS
    ) {
      showSubmissionsSFX();
    }
  }, [gameSessionStatus, showSubmissionsSFX]);

  useEffect(() => {
    if (gameSessionStatus === CreativePromptBlockGameSessionStatus.RESULTS) {
      showResultsSFX();
    }
  }, [gameSessionStatus, showResultsSFX]);

  const isHost = ClientTypeUtils.isHost(myClientType);
  useEffect(() => {
    if (
      votingTime !== null &&
      votingTime === gameSessionLocalTimer &&
      isCounting
    ) {
      countdownV2({
        debug: 'CreatePromoptBlockGamePlay',
        startTimeWorker: isHost ? !isLive : true,
        flushCountingStatus: false,
      });
    }
  }, [gameSessionLocalTimer, isCounting, isHost, isLive, votingTime]);

  useEffect(() => {
    // Recover from audience reloads the page
    if (!uiState.showBoard) {
      uiControl.update({ showBoard: true });
    }
  }, [uiState.showBoard, uiControl]);

  if (
    nullOrUndefined(gameSessionStatus) ||
    gameSessionStatus < CreativePromptBlockGameSessionStatus.SHOW_SUBMISSIONS ||
    gameSessionStatus > CreativePromptBlockGameSessionStatus.POINTS_DISTRIBUTED
  )
    return <></>;

  const isVoted = !!votedSubmissionTeamId;
  const buttonDisabled = isVoted || isVotingEnd || !isCounting;

  const content = isShowingResults ? (
    <Results blockId={gameSessionBlock.id} />
  ) : submissionCount > 0 ? (
    <div className='relative w-full h-full overflow-y-auto scrollbar'>
      <div className='relative w-full mx-auto flex flex-row'>
        <div className='relative w-full grid grid-cols-votes auto-cols-max gap-y-8 justify-evenly items-start'>
          {submissions.map((data, i) => (
            <SubmissionButton
              key={`submission-1-${i}`}
              votedSubmissionTeamId={votedSubmissionTeamId}
              setVotedSubmissionTeamId={setVotedSubmissionTeamId}
              teamId={data.teamId}
              answer={data.answer}
              disabled={buttonDisabled}
            />
          ))}
        </div>
      </div>
    </div>
  ) : null;

  return (
    <>
      {!isShowingResults && (
        <ProgressRing
          className={`absolute -top-10`}
          currentTime={gameSessionLocalTimer}
          totalTime={votingTime}
        />
      )}
      <div className='w-full h-full pt-12 flex flex-col justify-start items-center'>
        <div
          className={`${bps(
            'mx-15 xl:mx-15 lp-sm:mx-15 2xl:mx-15 3xl:mx-15 lp-md:mx-15 lp-lg:mx-15'
          )} w-auto flex flex-col justify-center items-center`}
        >
          <p
            className={`text-white font-bold hyphens-auto text-center overflow-hidden ${bps(
              [
                'text-xs',
                'xl:text-xs',
                'lp-sm:text-sms',
                '2xl:text-sms',
                '3xl:text-sms',
                'lp-md:text-sm',
                'lp-lg:text-base',
              ]
            )}`}
          >
            {prompt}
          </p>
        </div>
        <div className='w-full h-full pt-10 pb-20'>{content}</div>
      </div>
    </>
  );
};

const SubmissionStage = (props: {
  gameSessionBlock: CreativePromptBlock;
  uiState: GamePlayUIState;
  uiControl: GamePlayUIStateControl;
}): JSX.Element => {
  const { gameSessionBlock, uiState, uiControl } = props;
  const gameSessionStatus =
    useGameSessionStatus<CreativePromptBlockGameSessionStatus>();
  const [gamePlayMedia, setGamePlayMedia] =
    useState<Nullable<GamePlayMedia, false>>(null);
  const gameSessionLocalTimer = useGameSessionLocalTimer();
  const gamePlayConfig = useGamePlayUIConfiguration();

  const submissionTime = gameSessionBlock?.fields.submissionTime ?? null;

  useCountdownPlaySFX(
    submissionTime,
    gameSessionLocalTimer,
    gameSessionStatus ===
      CreativePromptBlockGameSessionStatus.SUBMISSION_COUNTING ||
      gameSessionStatus === CreativePromptBlockGameSessionStatus.SUBMISSION_END
  );

  // When countdown starts
  useOneTimeAutomaticBroadcastToggleOff(
    () =>
      gameSessionStatus ===
      CreativePromptBlockGameSessionStatus.SUBMISSION_COUNTING
  );

  useEffect(() => {
    const map = GameSessionUtil.StatusMapFor(gameSessionBlock);
    if (!map || nullOrUndefined(gameSessionStatus)) return;
    const fields = gameSessionBlock.fields;
    const startVideoWithTimer = !!fields.startVideoWithTimer;
    if (map.introMediaRetained.includes(gameSessionStatus)) {
      setGamePlayMedia(
        buildGamePlayMedia(
          {
            media: fields.submissionMedia,
            mediaData: fields.submissionMediaData,
          },
          {
            stage: 'intro',
            startVideoWithTimer,
          }
        )
      );
    }
  }, [gameSessionBlock, gameSessionStatus]);

  useEffect(() => {
    if (
      submissionTime !== null &&
      (gameSessionStatus === CreativePromptBlockGameSessionStatus.PRESENTING ||
        gameSessionStatus ===
          CreativePromptBlockGameSessionStatus.SUBMISSION_COUNTING)
    ) {
      setTimer('submission', submissionTime);
    }
  }, [gameSessionStatus, submissionTime]);

  useEffect(() => {
    if (
      gameSessionStatus ===
        CreativePromptBlockGameSessionStatus.SUBMISSION_END &&
      gameSessionLocalTimer !== 0
    ) {
      resetTimer('submission');
    }
  }, [gameSessionLocalTimer, gameSessionStatus]);

  useEffect(() => {
    if (
      gameSessionStatus === null ||
      gameSessionStatus === CreativePromptBlockGameSessionStatus.LOADED ||
      gameSessionStatus === CreativePromptBlockGameSessionStatus.END
    ) {
      uiControl.reset();
      resetTimer('submission');
    }
  }, [gameSessionStatus, uiControl]);

  const { onMediaEnded, onMediaReplaying } = useGamePlayMediaUISync({
    block: gameSessionBlock,
    gameSessionStatus,
    media: gamePlayMedia || null,
    state: uiState,
    control: uiControl,
  });

  const mediaPlayable = useGamePlayMediaPlayable({
    block: gameSessionBlock,
    gameSessionStatus,
    media: gamePlayMedia || null,
    state: uiState,
  });

  if (nullOrUndefined(gameSessionStatus)) return <></>;
  return (
    <>
      <StageBgSingleFrame gamePlayMedia={gamePlayMedia} />
      <GamePlayInput
        text={gameSessionBlock.fields.prompt}
        zIndex='z-30'
        visible={uiState.showBoard}
        hasMedia={!!gamePlayMedia}
        inputBox={
          <GameSubmissionInput
            timer={gameSessionLocalTimer}
            gameSessionBlock={gameSessionBlock}
            gameSessionStatus={gameSessionStatus}
            adaptor={CreativePromptBlockBlockUtils.ToSubmissionInputAdaptor(
              gameSessionBlock
            )}
          />
        }
      />
      {uiState.playGoAnimation && <GoAnimation />}
      <GamePlayMediaLayout fullscreen={false}>
        {gamePlayMedia ? (
          <GamePlayMediaPlayer
            gamePlayMedia={gamePlayMedia}
            play={mediaPlayable}
            mode={uiState.mediaEndEffect ? 'small' : 'full'}
            onMediaEnded={onMediaEnded}
            onMediaReplaying={onMediaReplaying}
            layout='anchored'
          />
        ) : null}
      </GamePlayMediaLayout>
      {gamePlayConfig.showSubmissionStatusWidget && uiState.showBoard && (
        <SubmissionStatusWidgetAttachedOnLeft />
      )}
    </>
  );
};

const CreativePromptBlockGamePlay = (props: {
  gameSessionBlock: CreativePromptBlock;
}): JSX.Element | null => {
  const { gameSessionBlock } = props;
  const venueId = useGameSessionVenueId();
  const gameSessionStatus =
    useGameSessionStatus<CreativePromptBlockGameSessionStatus>();
  const [uiState, uiControl] = useGamePlayUITransitionControl();
  const isGamePlayReady = useIsGamePlayReady(
    gameSessionBlock,
    gameSessionStatus
  );
  const [voteAnimation, setVoteAnimation] = useState(false);

  const isShowingResults =
    gameSessionStatus === CreativePromptBlockGameSessionStatus.RESULTS ||
    gameSessionStatus ===
      CreativePromptBlockGameSessionStatus.POINTS_DISTRIBUTED;

  const showPointsDistributed =
    gameSessionStatus ===
    CreativePromptBlockGameSessionStatus.POINTS_DISTRIBUTED;

  useTimerRecover(async (status) => {
    if (status === CreativePromptBlockGameSessionStatus.SUBMISSION_COUNTING) {
      return gameSessionBlock.fields.submissionTime;
    } else if (status === CreativePromptBlockGameSessionStatus.VOTE_COUNTING) {
      return gameSessionBlock.fields.votingTime;
    }
  });
  useSyncPersistentPointsRevealAnswer(showPointsDistributed);
  useGainPointsAnimationGamePlayTrigger();
  useSubmissionStatusWaitEnder();

  useEffect(() => {
    return () => {
      resetTimer('submission');
    };
  }, [gameSessionBlock.id, venueId]);

  useEffect(() => {
    if (
      gameSessionStatus === null ||
      gameSessionStatus === CreativePromptBlockGameSessionStatus.LOADED ||
      gameSessionStatus === CreativePromptBlockGameSessionStatus.END
    ) {
      uiControl.update({ showBoard: false });
      resetTimer('submission');
    }
  }, [gameSessionStatus, uiControl]);

  useEffect(() => {
    if (
      gameSessionStatus ===
        CreativePromptBlockGameSessionStatus.VOTE_COUNTING &&
      !voteAnimation
    ) {
      setVoteAnimation(true);
    } else if (
      gameSessionStatus !==
        CreativePromptBlockGameSessionStatus.VOTE_COUNTING &&
      voteAnimation
    ) {
      setVoteAnimation(false);
    }
  }, [gameSessionStatus, voteAnimation]);

  if (!isGamePlayReady) return null;

  const overlayStyles = uiState.showBoard
    ? 'bg-black bg-opacity-40'
    : 'bg-black bg-opacity-60';
  const boardMediaUrl = MediaUtils.PickMediaUrl(
    gameSessionBlock?.fields.submissionMedia,
    {
      priority: MediaPickPriorityHD,
      videoThumbnail: 'last',
    }
  );
  const boardZIndex =
    gameSessionStatus ===
    CreativePromptBlockGameSessionStatus.POINTS_DISTRIBUTED
      ? 'z-20'
      : 'z-30';

  if (nullOrUndefined(gameSessionStatus)) return null;

  const afterSubmission =
    gameSessionStatus >= CreativePromptBlockGameSessionStatus.SHOW_SUBMISSIONS;

  return (
    <div className={`fixed w-screen h-screen ${overlayStyles} text-white`}>
      <FloatBoard
        containerZIndex={boardZIndex}
        containerDisplay={'block'}
        bgOpacity={uiState.showBoard ? 'opacity-100' : 'opacity-0'}
        bgStyle={`border-2 border-cyan board-bg-gradient-radial rounded-xl mt-8`}
        title={isShowingResults ? 'VOTING RESULTS' : undefined}
        bgImgSrc={afterSubmission ? boardMediaUrl : null}
      >
        {afterSubmission && (
          <VotingStage
            gameSessionBlock={gameSessionBlock}
            uiState={uiState}
            uiControl={uiControl}
          />
        )}
      </FloatBoard>
      {voteAnimation && <VoteAnimation />}
      {gameSessionStatus <
        CreativePromptBlockGameSessionStatus.SHOW_SUBMISSIONS && (
        <SubmissionStage
          gameSessionBlock={gameSessionBlock}
          uiState={uiState}
          uiControl={uiControl}
        />
      )}
      {showPointsDistributed && <CreativePromptAnswer />}
    </div>
  );
};

// eslint-disable-next-line import/no-default-export
export default CreativePromptBlockGamePlay;
