import { useMemo } from 'react';
import { proxy } from 'valtio';

import {
  type ClientId,
  ProfileIndex,
  toProfileAddress,
} from '@lp-lib/crowd-frames-schema';

import placeholder from '../../assets/img/placeholder/bg1.png';
import { getFeatureQueryParamNumber } from '../../hooks/useFeatureQueryParam';
import { useLiveCallback } from '../../hooks/useLiveCallback';
import { apiService } from '../../services/api-service';
import { type Participant } from '../../types/user';
import { HiddenCanvas } from '../../utils/canvas';
import { xDomainifyUrl } from '../../utils/common';
import { getInitials } from '../../utils/string';
import { UnplayableImageImpl } from '../../utils/unplayable';
import { markSnapshottable } from '../../utils/valtio';
import { useCrowdFramesIconRenderable } from '../CrowdFrames/CrowdFramesContext';
import { useFPSContext } from '../CrowdFrames/FPSManager';
import {
  type FPSContext,
  type RenderableFilmstrip,
} from '../CrowdFrames/FPSManager/types';
import {
  type FirebaseService,
  FirebaseValueHandle,
  useFirebaseContext,
} from '../Firebase';
import { FirebaseUtils } from '../Firebase/utils';
import { type DPRCanvas } from '../PixelFx/DPICanvas';
import { type InterpolationFactor, type Ms } from '../PixelFx/GameLoop';
import {
  useLastJoinedParticipantGetter,
  useParticipantFlagsGetter,
} from '../Player';
import { useVenueId } from '../Venue';
import { type EmojiSendCommandMap } from './type';
import { EmojiFBUtils } from './utils';

let placeholderCache: HTMLCanvasElement | null = null;
function getPlaceholder() {
  if (placeholderCache) return placeholderCache;
  const w = 40;
  const h = 40;

  const img = new UnplayableImageImpl(
    xDomainifyUrl(placeholder, 'emoji-board')
  );
  const hc = new HiddenCanvas('emoji-placeholder');

  hc.cvs.width = w;
  hc.cvs.height = h;
  hc.attach();
  const ctx = hc.cvs.getContext('2d');

  img
    .intoPlayable()
    .then((imgEl) => {
      ctx?.drawImage(imgEl, 0, 0, w, h);
    })
    .catch(() => void 0);

  placeholderCache = hc.cvs;
}

interface EmojiRenderOptions {
  emoji: string;
  strip: RenderableFilmstrip | null;
  stripFallback: HTMLImageElement | null;
  initials: string;
}

interface EmojiAnimationOptions {
  originalX: number;
  originalY: number;

  // speedX is a cosine function of time.
  // speedX = base * cos(2 * pi * t / durationMs * rounds) basically,
  // also add a random deltaMs to make the animation more interesting.

  speedX: {
    deltaMs: number;
    base: number;
    rounds: number;
  };
  // speedY is a linear function of time
  // speedY = initial + acceleration * t
  speedY: {
    initial: number;
    acceleration: number;
  };
  // durationMs is the total duration of the animation, including fade out.
  durationMs: number;
  fadeOutMs: number;
}

class AnimatedEmoji {
  constructor(
    readonly senderUid: string,
    readonly role: 'leader' | 'follower',
    private renderOptions: EmojiRenderOptions,
    private animationOptions: EmojiAnimationOptions,

    private runningMs = 0,
    private cx = animationOptions.originalX,
    private cy = animationOptions.originalY,
    private px = cx,
    private py = cy
  ) {}

  spawnFollower(emoji: string) {
    return new AnimatedEmoji(
      this.senderUid,
      'follower',
      {
        ...this.renderOptions,
        emoji,
      },
      this.animationOptions
    );
  }

  RunningMS() {
    return this.runningMs;
  }

  update(dt: Ms) {
    this.runningMs += dt;

    const speedX =
      this.animationOptions.speedX.base *
      Math.cos(
        ((this.animationOptions.speedX.deltaMs + this.runningMs) /
          this.animationOptions.durationMs) *
          2 *
          Math.PI *
          this.animationOptions.speedX.rounds
      );
    const speedY =
      this.animationOptions.speedY.initial +
      this.animationOptions.speedY.acceleration * this.runningMs;

    this.px = this.cx;
    this.py = this.cy;

    this.cx += speedX * dt;
    this.cy += speedY * dt;
  }

  draw(interp: InterpolationFactor, dprCanvas: DPRCanvas, _dt: Ms) {
    const x = this.cx + (this.cx - this.px) * interp;
    const y = this.cy + (this.cy - this.py) * interp;
    if (this.role === 'leader') {
      this.drawAvatar(dprCanvas.ctx, x, y);
    }
    this.drawEmoji(dprCanvas.ctx, x, y);
  }

  private drawAvatar(ctx: CanvasRenderingContext2D, x: number, y: number) {
    const radius = 20;
    const overlap = 15;
    const circleX = x + 40 - overlap + radius;
    const circleY = y - overlap + radius;

    ctx.save();
    ctx.beginPath();
    ctx.arc(circleX, circleY, radius, 0, 2 * Math.PI, false);
    ctx.closePath();
    ctx.clip();
    ctx.globalAlpha = this.opacity();

    if (this.renderOptions.strip) {
      ctx.drawImage(
        this.renderOptions.strip.filmstrip,
        0,
        this.renderOptions.strip.frameIndex *
          this.renderOptions.strip.profile.height,
        this.renderOptions.strip.profile.width,
        this.renderOptions.strip.profile.height,
        circleX - radius,
        circleY - radius,
        radius * 2,
        radius * 2
      );
    } else if (this.renderOptions.stripFallback) {
      ctx.drawImage(
        this.renderOptions.stripFallback,
        0,
        0,
        this.renderOptions.stripFallback.width,
        this.renderOptions.stripFallback.height,
        circleX - radius,
        circleY - radius,
        radius * 2,
        radius * 2
      );
    } else {
      const ph = getPlaceholder();
      if (ph)
        ctx.drawImage(
          ph,
          circleX - radius,
          circleY - radius,
          radius * 2,
          radius * 2
        );

      ctx.font = '16px Inter';
      ctx.fillStyle = 'white';
      ctx.textAlign = 'center';
      ctx.textBaseline = 'middle';
      ctx.fillText(this.renderOptions.initials, circleX, circleY, radius * 2);
    }

    ctx.restore();
  }

  private drawEmoji(ctx: CanvasRenderingContext2D, x: number, y: number) {
    ctx.font = '40px serif';
    ctx.globalAlpha = this.opacity();
    ctx.fillText(this.renderOptions.emoji, x, y);
    ctx.globalAlpha = 1;
  }

  private opacity() {
    if (this.runningMs >= this.animationOptions.durationMs) return 0;
    if (
      this.runningMs + this.animationOptions.fadeOutMs <=
      this.animationOptions.durationMs
    )
      return 1;
    return (
      (this.animationOptions.durationMs - this.runningMs) /
      this.animationOptions.fadeOutMs
    );
  }
}

export type EmojiAnimationState = {
  emojisCount: number;
};

class EmojiAnimationAPI {
  private emojis: Array<AnimatedEmoji> = [];
  private _state;

  constructor(
    svc: FirebaseService,
    path: string,
    private deps: {
      getMostRecentFilmstripForAddress: FPSContext['getMostRecentFilmstripForAddress'];
      getLastJoinedParticipant: ReturnType<
        typeof useLastJoinedParticipantGetter
      >;
      getParticipantFlags: ReturnType<typeof useParticipantFlagsGetter>;
      getBoundingRect: () => DOMRectReadOnly;
      getFilmstripFallback: (
        participant: Participant
      ) => Promise<HTMLImageElement | null>;
    },
    private options = {
      emojiDurationMS: getFeatureQueryParamNumber(
        'emoji-board-emoji-duration-ms'
      ),
      emojiFadeOutMS: getFeatureQueryParamNumber(
        'emoji-board-emoji-fadeout-ms'
      ),
      emojiSpeedX: getFeatureQueryParamNumber(
        'emoji-board-emoji-speed-x',
        true
      ),
      emojiGroupingMS: getFeatureQueryParamNumber(
        'emoji-board-emoji-grouping-ms'
      ),
    },
    private commandsHandle = new FirebaseValueHandle<EmojiSendCommandMap>(
      svc.prefixedSafeRef(path)
    )
  ) {
    this._state = markSnapshottable(
      proxy<EmojiAnimationState>({
        emojisCount: 0,
      })
    );
  }

  get state() {
    return this._state;
  }

  async send(emoji: string, uid: string) {
    const child = await this.commandsHandle.ref.push({
      emoji,
      uid,
    } as never);
    const rewrappedChild = FirebaseUtils.Rewrap(child);
    rewrappedChild.onDisconnect().remove();

    setTimeout(() => {
      rewrappedChild.remove();
      rewrappedChild.onDisconnect().cancel();
    }, 1000);
  }
  private onEmojiAdded = async (emoji: string, uid: string) => {
    const leader = this.emojis.findLast(
      (emoji) =>
        emoji.senderUid === uid &&
        emoji.role === 'leader' &&
        emoji.RunningMS() < this.options.emojiGroupingMS
    );
    if (!!leader) {
      this.emojis.push(leader.spawnFollower(emoji));
      this._state.emojisCount = this.emojis.length;
      return;
    }

    const participant = this.deps.getLastJoinedParticipant(uid);
    const flags = this.deps.getParticipantFlags(participant?.clientId);
    if (!participant || !flags) return;
    const strip = flags.video
      ? this.deps.getMostRecentFilmstripForAddress(
          toProfileAddress(
            participant.clientId as ClientId,
            ProfileIndex.wh100x100fps8
          )
        )
      : null;
    const stripFallback = flags.video
      ? await this.deps.getFilmstripFallback(participant)
      : null;

    const rect = this.deps.getBoundingRect();
    const { width, height, bottom, left } = rect;

    const animatedEmoji = new AnimatedEmoji(
      participant.id,
      'leader',
      {
        emoji,
        strip,
        stripFallback,
        initials: getInitials(participant.username),
      },
      {
        speedX: {
          rounds: 2.5,
          deltaMs: Math.random() * this.options.emojiDurationMS,
          base: this.options.emojiSpeedX,
        },
        speedY: {
          initial: -height / this.options.emojiDurationMS / 4,
          acceleration:
            (-1.5 * height) /
            this.options.emojiDurationMS /
            this.options.emojiDurationMS,
        },
        durationMs: this.options.emojiDurationMS,
        fadeOutMs: this.options.emojiFadeOutMS,
        originalX: left + Math.random() * (width - 60),
        originalY: bottom,
      }
    );
    this.emojis.push(animatedEmoji);

    this._state.emojisCount = this.emojis.length;
  };

  on(): void {
    this.commandsHandle.ref.on('child_added', (snap) => {
      const val = snap.val();
      if (!val) return;
      this.onEmojiAdded(val.emoji, val.uid);
    });
  }
  off() {
    this.commandsHandle.off();
  }

  draw = (interp: InterpolationFactor, dprCanvas: DPRCanvas, dt: Ms) => {
    this.emojis.forEach((emoji) => emoji.draw(interp, dprCanvas, dt));
  };
  update = (dt: Ms) => {
    this.emojis.forEach((emoji) => {
      emoji.update(dt);
    });
    const removeCount = this.emojis.filter(
      (emoji) => emoji.RunningMS() >= this.options.emojiDurationMS
    ).length;
    if (removeCount > 0) {
      this.emojis.splice(0, removeCount);
      this._state.emojisCount = this.emojis.length;
    }
  };
}

export function useGlobalEmojisAnimation() {
  const venueId = useVenueId();
  const getBoundingRect = useLiveCallback(() => {
    const width = 280;
    const height = Math.min(window.innerHeight * 0.7, 640);
    const bottom = window.innerHeight - 60;
    const right = window.innerWidth - 8;
    const left = right - width;
    return new DOMRectReadOnly(left, bottom - height, width, height);
  });
  return useEmojisAnimation(
    EmojiFBUtils.Path(venueId, 'commands'),
    getBoundingRect
  );
}

export function useEmojisAnimation(
  path: string,
  getBoundingRect: () => DOMRectReadOnly
) {
  const { svc } = useFirebaseContext();

  const { getMostRecentFilmstripForAddress } = useFPSContext();
  const getLastJoinedParticipant = useLastJoinedParticipantGetter();
  const getParticipantFlags = useParticipantFlagsGetter();
  const iconRenderable = useCrowdFramesIconRenderable();

  const getFilmstripFallback = useLiveCallback(
    async (participant: Participant): Promise<HTMLImageElement | null> => {
      if (!(participant?.icon && iconRenderable(participant.icon))) return null;
      const img = new UnplayableImageImpl(
        xDomainifyUrl(
          apiService.media.proxyUrl(participant.icon),
          'emoji-board'
        )
      );
      return await img.intoPlayable();
    }
  );

  const api = useMemo(
    () =>
      new EmojiAnimationAPI(svc, path, {
        getMostRecentFilmstripForAddress,
        getLastJoinedParticipant,
        getParticipantFlags,
        getBoundingRect,
        getFilmstripFallback,
      }),
    [
      getBoundingRect,
      getLastJoinedParticipant,
      getMostRecentFilmstripForAddress,
      getFilmstripFallback,
      getParticipantFlags,
      path,
      svc,
    ]
  );

  return api;
}
