import { type Uppy, type UppyEventMap } from '@uppy/core';
import { useCallback, useEffect, useRef } from 'react';
import { useSnapshot } from 'valtio';

import { type Block } from '@lp-lib/game';
import { type Media } from '@lp-lib/media';

import logger from '../../../logger/logger';
import { apiService } from '../../../services/api-service';
import { type Game } from '../../../types/game';
import { assertExhaustive } from '../../../utils/common';
import { RecordFailedIcon } from '../../icons/RecordFailedIcon';
import { RecordSuccessIcon } from '../../icons/RecordSuccessIcon';
import { ShareIcon } from '../../icons/ShareIcon';
import { type VideoEffectsSettings } from '../../VideoEffectsSettings/types';
import { VideoEffectsSettingsUtils } from '../../VideoEffectsSettings/VideoEffectsSettingsUtils';
import { BlockRecordingUtils } from '../BlockRecordingUtils';
import { IDBChunkedStreamRecorder } from '../IDBChunkedStreamRecorder';
import {
  useBlockRecorderState,
  useBlockRecorderStateGetter,
  useOptionalBlockRecorderStateGetter,
} from '../state';
import {
  type BlockId,
  type BlockRecorderState,
  type BlockRecordingDataUpload,
  type BlockRecordingUploadStatusable,
} from '../types';

export const log = logger.scoped('block-recorder-uploads');

async function enqueueHostVideo(
  writableUpload: BlockRecordingDataUpload,
  uppy: Uppy
) {
  if (!writableUpload.hostVideo || writableUpload.hostVideo.data.uppyId) return;

  try {
    const data = await IDBChunkedStreamRecorder.GetRecording(
      writableUpload.hostVideo.data.filename
    );
    log.info('enqueuing host video for upload', {
      name: writableUpload.hostVideo.data.filename,
      size: data.size,
      blockId: writableUpload.hostVideo.data.blockId,
    });

    // By default the mime type will have codec information too, but the
    // backend does not support this.
    const simplifiedMimeType = data.type.split(';')[0].trim();

    writableUpload.hostVideo.data.uppyId = uppy.addFile({
      data: data,
      name: writableUpload.hostVideo.data.filename,
      type: simplifiedMimeType,
    });
  } catch (err) {
    log.error('failed to enqueue host video for upload', err, {
      name: writableUpload.hostVideo.data.filename,
      blockId: writableUpload.hostVideo.data.blockId,
    });
  }
}

// This does not cancel an upload, only clear its state, assuming it is not
// already uploading
export function useClearUpload(): (
  blockId: BlockId,
  state?: BlockRecorderState
) => void {
  const getState = useOptionalBlockRecorderStateGetter();
  return useCallback(
    (blockId: BlockId) => {
      const state = getState();

      if (!state) return;

      const upload = state.uploads[blockId];
      if (!upload) return;

      if (
        upload.actions.status === 'uploading' ||
        upload.hostVideo?.status === 'uploading'
      ) {
        // This is not a cancel upload, just a remove the old state utility.
        return;
      }

      delete state.uploads[blockId];
    },
    [getState]
  );
}

export function useEnqueueUploads(
  state: BlockRecorderState,
  videoEffectsSettings: VideoEffectsSettings | null,
  onSaved: (gameId: Game['id']) => void
): void {
  const snap = useSnapshot(state.uploads);
  const uploads = Object.entries(snap);

  const onSavedRef = useRef(onSaved);
  useEffect(() => {
    onSavedRef.current = onSaved;
  });

  useEffect(() => {
    for (const [blockId, upload] of uploads) {
      if (!upload) continue;

      const writableUpload = state.uploads[blockId];

      if (writableUpload && upload.hostVideo?.status === 'none') {
        enqueueHostVideo(writableUpload, state.refs.uppy);
      }

      if (
        upload.hostVideo?.status === 'saved' &&
        upload.actions.status === 'none' &&
        writableUpload
      ) {
        uploadAndReportStatus(
          blockId,
          videoEffectsSettings,
          writableUpload,
          onSavedRef.current
        );
      }
    }
  }, [videoEffectsSettings, state.refs.uppy, state.uploads, uploads]);
}

async function uploadAndReportStatus(
  blockId: BlockId,
  videoEffectsSettings: VideoEffectsSettings | null,
  writable: BlockRecordingDataUpload,
  onSaved: (gameId: Game['id']) => void
) {
  const req = BlockRecordingUtils.ToRequest(writable.actions.data);
  writable.actions.status = 'uploading';

  try {
    await apiService.block.addRecording(blockId, req);
    if (videoEffectsSettings) {
      const blockVES = VideoEffectsSettingsUtils.ToBlock(videoEffectsSettings);
      await apiService.block.updateVideoEffectsSettings(blockId, blockVES);
    }
    writable.actions.status = 'saved';
    onSaved(writable.actions.data.gameId);
  } catch (err) {
    log.error(`Failed to save block recording actions`, err);
    writable.actions.status = 'failed';
  }
}

export function useManageUploadResults(state: BlockRecorderState): void {
  const uploads = Object.entries(useSnapshot(state.uploads));

  useEffect(() => {
    const progress: UppyEventMap['upload-progress'] = (file, progress) => {
      for (const [blockId] of uploads) {
        const upload = state.uploads[blockId];
        if (!upload) continue;
        if (upload.hostVideo?.data.uppyId === file.id) {
          if (upload.hostVideo?.status !== 'uploading') {
            log.info('got initial progress', {
              name: upload.hostVideo.data.filename,
              blockId: upload.hostVideo.data.blockId,
            });
            upload.hostVideo.status = 'uploading';
          }

          upload.hostVideo.progress =
            progress.bytesTotal > 0
              ? Math.floor((progress.bytesUploaded / progress.bytesTotal) * 100)
              : 0;
          return;
        }
      }
    };

    const success: UppyEventMap['upload-success'] = (file, response) => {
      for (const [blockId, upload] of Object.entries(state.uploads)) {
        if (!upload) continue;
        if (upload.hostVideo?.data.uppyId === file.id) {
          upload.hostVideo.status = 'saved';
          const body = response.body as { media: Media };
          upload.actions.data.mediaId = body.media.id;
          IDBChunkedStreamRecorder.DeleteRecording(
            upload.hostVideo.data.filename
          ).catch(() => void 0);
          state.refs.uppy.removeFile(file.id);
          log.info('video upload success', { blockId, url: body.media.url });
          return;
        }
      }
    };

    const error: UppyEventMap['upload-error'] = (file, error, response) => {
      for (const [blockId, upload] of Object.entries(state.uploads)) {
        if (!upload) continue;
        if (upload.hostVideo?.data.uppyId === file.id) {
          upload.hostVideo.status = 'failed';
          log.error('video upload failed', error, { blockId, response, file });
          return;
        }
      }
    };

    state.refs.uppy.on('upload-success', success);
    state.refs.uppy.on('upload-error', error);
    state.refs.uppy.on('upload-progress', progress);

    return () => {
      state.refs.uppy.off('upload-success', success);
      state.refs.uppy.off('upload-error', error);
      state.refs.uppy.off('upload-progress', progress);
    };
  }, [state.uploads, state.refs.uppy, uploads]);

  useEffect(() => {
    return () => {
      state.refs.uppy.cancelAll();
    };
  }, [state.refs.uppy]);
}

export function retryUpload(
  state: BlockRecorderState,
  videoEffectsSettings: VideoEffectsSettings | null,
  block: Block,
  onSaved: (gameId: Game['id']) => void
): void {
  const upload = state.uploads[block.id];
  const uppyId = upload?.hostVideo?.data.uppyId;

  if (!upload || !uppyId) return;

  if (upload.hostVideo?.status === 'failed') {
    state.refs.uppy.retryUpload(uppyId);
    return;
  }

  if (upload.actions.status === 'failed') {
    const writable = state.uploads[block.id];
    if (writable)
      uploadAndReportStatus(block.id, videoEffectsSettings, writable, onSaved);
    return;
  }
}

export function useRetryBlockRecordingUpload(
  onSaved: (gameId: Game['id']) => void,
  videoEffectsSettings: VideoEffectsSettings | null,
  state?: BlockRecorderState
): (block: Block) => void {
  const getState = useBlockRecorderStateGetter();
  return useCallback(
    (block: Block) =>
      retryUpload(state ?? getState(), videoEffectsSettings, block, onSaved),
    [getState, videoEffectsSettings, onSaved, state]
  );
}

export const useBlockRecordingUploads = (): BlockRecordingDataUpload[] => {
  const snap = useSnapshot(useBlockRecorderState().uploads);
  const uploads = Object.values(snap).filter((v) =>
    Boolean(v)
  ) as BlockRecordingDataUpload[];
  return uploads;
};

export const useBlockRecorderUploadForBlock = (
  block: Block | null
): BlockRecordingDataUpload | null => {
  const snap = useSnapshot(useBlockRecorderState().uploads) as ReturnType<
    typeof useBlockRecorderState
  >['uploads'];
  if (!block) return null;
  return snap[block.id] ?? null;
};

export const useBlockRecordingUploadDescForBlock = (
  block: Block | null,
  iconClassName?: string
): ReturnType<typeof getBlockRecordingUploadDesc> => {
  const snap = useSnapshot(useBlockRecorderState().uploads) as ReturnType<
    typeof useBlockRecorderState
  >['uploads'];
  if (!block) return null;
  return getBlockRecordingUploadDesc(snap[block.id] ?? null, iconClassName);
};

export function getBlockRecordingUploadDesc(
  blockUpload: BlockRecordingDataUpload | null,
  iconClassName?: string
): {
  heading: string;
  progress: string;
  icon: JSX.Element;
  state: 'none' | 'uploading' | 'failed' | 'saved';
} | null {
  if (!blockUpload) return null;

  const combinedUploadState = Object.entries(blockUpload).reduce(
    (status, [, upload]) => {
      // If anything is uploading or failed, they are both. Failure is not
      // reflected until uploading both both is either canceled or completed.
      switch (status) {
        case 'uploading':
        case 'failed': {
          return status;
        }
      }

      return upload?.status ?? 'none';
    },
    'none' as BlockRecordingUploadStatusable['status']
  );

  const icon =
    combinedUploadState === 'none' || combinedUploadState === 'uploading' ? (
      <ShareIcon className={iconClassName} />
    ) : combinedUploadState === 'saved' ? (
      <RecordSuccessIcon className={iconClassName} />
    ) : combinedUploadState === 'failed' ? (
      <RecordFailedIcon className={iconClassName} />
    ) : (
      (assertExhaustive(combinedUploadState), (<></>))
    );

  const progress =
    blockUpload.hostVideo?.progress !== undefined &&
    blockUpload.hostVideo?.progress !== null
      ? `${blockUpload.hostVideo.progress}%`
      : '';

  const heading =
    combinedUploadState === 'none' || combinedUploadState === 'uploading'
      ? `Uploading... ${progress}`
      : combinedUploadState === 'saved'
      ? 'Recording Saved'
      : combinedUploadState === 'failed'
      ? 'Upload Failed'
      : (assertExhaustive(combinedUploadState), '');

  return {
    heading,
    progress,
    icon,
    state: combinedUploadState,
  };
}
