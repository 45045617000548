import {
  createContext,
  type ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react';
import { proxy, useSnapshot } from 'valtio';
import { devtools } from 'valtio/utils';

import { type DtoBrand } from '@lp-lib/api-service-client/public';
import { type FirebaseSafeRead } from '@lp-lib/firebase-typesafe';
import { type FBReference } from '@lp-lib/firebase-typesafe/src/experimental';
import { type Block } from '@lp-lib/game';

import { useLiveCallback } from '../../../hooks/useLiveCallback';
import { SessionMode } from '../../../types';
import { type ValtioSnapshottable, ValtioUtils } from '../../../utils/valtio';
import {
  firebaseService,
  FirebaseValueHandle,
  useIsFirebaseConnected,
} from '../../Firebase';
import { makeFirebaseSafe } from '../../Firebase/makeFirebaseSafe';
import { type BlockId } from '../../GameRecorder/types';
import { BlockKnifeUtils } from '../Blocks/Shared';
import { type GamePlayStore } from '../GamePlayStore';
import { useCurrentSessionMode, useGameSessionBlockId } from '../hooks';
import { type BlockRecordingExtra } from '../OndPhaseRunner/types';
import { type PlaybackDesc, type PlaybackItemId } from './intoPlayback';

type ResolvedPlaybacks = {
  [SessionMode.Live]: Nullable<PlaybackDesc>;
  [SessionMode.OnDemand]: Nullable<PlaybackDesc>;
};

export type PlaybackItemExtra = {
  preparedExtra?: BlockRecordingExtra;
  unpreparedExtra?: BlockRecordingExtra;
};

type PlaybackExtras = {
  [PlaybackItemId: string]: PlaybackItemExtra;
};

type PlaybackSessionExtras = {
  [SessionMode.Live]: Nullable<PlaybackExtras>;
  [SessionMode.OnDemand]: Nullable<PlaybackExtras>;
};

export type PlaybackInfoData = {
  modes: Nullable<ResolvedPlaybacks>;
  extras: Nullable<PlaybackSessionExtras>;
};

function initialState(): PlaybackInfoData {
  return {
    modes: null,
    extras: {
      [SessionMode.Live]: null,
      [SessionMode.OnDemand]: null,
    },
  };
}

// This helps ensure that the store is inaccessible without obvious workarounds.
// This could be used to index into the class instance, but that's a little more
// exotic and could have performance implications.
const storeKey = Symbol('PBICStore');

class PlaybackInfoClient {
  private store = proxy(initialState());
  undevtools: undefined | (() => void) = undefined;

  constructor(
    readonly venueId: string,
    svc = firebaseService,
    private handle = new FirebaseValueHandle<PlaybackInfoData>(
      svc.prefixedSafeRef(`playback-info/${venueId}`)
    )
  ) {}

  /** You need the key to access the store. */
  getStore(_key: typeof storeKey) {
    return this.store;
  }

  on() {
    this.undevtools = devtools(this.store, { name: 'PlaybackInfoClient' });
    this.handle.on(async (val) => {
      if (!val) ValtioUtils.update(this.store, initialState());
      else ValtioUtils.update(this.store, val as PlaybackInfoData);
    });
    return this;
  }

  off() {
    this.undevtools?.();
    this.handle.off();
    ValtioUtils.update(this.store, initialState());
  }

  async write(info: ResolvedPlaybacks) {
    await this.handle.update({
      // TODO: fix these types!
      modes: makeFirebaseSafe(
        ValtioUtils.detachCopy(info)
      ) as FirebaseSafeRead<ResolvedPlaybacks>,
    });
  }

  async empty() {
    // TODO: fix these types!
    await this.handle.set(initialState() as FirebaseSafeRead<PlaybackInfoData>);
  }

  async writeExtra<
    Key extends keyof PlaybackExtras[string],
    Value extends PlaybackExtras[string][Key]
  >(
    mode: SessionMode,
    pbItemId: PlaybackItemId,
    update: { [key in Key]: Value }
  ) {
    const ref = this.handle.ref as unknown as FBReference<PlaybackInfoData>;
    await ref
      .child(`extras/${mode}/${String(pbItemId)}`)
      .update(makeFirebaseSafe(ValtioUtils.detachCopy(update)));
  }

  async writeBlockExtra<
    Key extends keyof PlaybackExtras[string],
    Value extends PlaybackExtras[string][Key]
  >(mode: SessionMode, blockId: BlockId, update: { [key in Key]: Value }) {
    const pbItemId = this.store.modes?.[mode]?.items.find(
      (item) => item.block.id === blockId
    )?.id;
    if (!pbItemId) return;

    await this.writeExtra(mode, pbItemId, update);
  }

  getPlayback(mode: SessionMode, snap = this.store) {
    return snap.modes?.[mode] ?? null;
  }

  getBrand(blockId: Block['id'], mode: SessionMode, snap = this.store) {
    return (
      snap.modes?.[mode]?.items.find((item) => item.block.id === blockId)
        ?.brand ?? null
    );
  }

  getBlockPlaybackItem(
    pbid: PlaybackItemId,
    mode: SessionMode,
    snap = this.store
  ) {
    const playback = snap.modes?.[mode];
    if (!playback) return null;
    if (playback.preGame?.instructions?.id === pbid)
      return playback.preGame.instructions;

    return playback?.items.find((item) => item.id === pbid) ?? null;
  }

  getBlockPlaybackItemByBlockId(
    blockId: string,
    mode: SessionMode,
    snap = this.store
  ) {
    const playback = snap.modes?.[mode];
    if (!playback) return null;
    if (playback.preGame?.instructions?.block.id === blockId)
      return playback.preGame.instructions;

    return playback?.items.find((item) => item.block.id === blockId) ?? null;
  }

  getFirstVoiceOverRenderDescription(mode: SessionMode, snap = this.store) {
    const playback = snap.modes?.[mode];
    if (!playback) return null;

    const pbItem =
      playback.items.find((item) =>
        BlockKnifeUtils.HasAtLeastOneVoiceOver(item.block)
      ) ?? null;

    const vo = pbItem
      ? BlockKnifeUtils.GetVoiceOverList(pbItem.block).at(0) ?? null
      : null;

    return vo?.fallback?.renderDescription ?? vo?.runtime ?? null;
  }
}

function useExpose(client: PlaybackInfoClient) {
  const exposed = client.getStore(storeKey);
  return useSnapshot(exposed) as ValtioSnapshottable<PlaybackInfoData>;
}

const Context = createContext<PlaybackInfoClient | null>(null);

export function PlaybackInfoProvider(props: {
  venueId: string;
  children?: ReactNode;
}) {
  const { venueId } = props;
  const firebaseConnected = useIsFirebaseConnected();
  const [instance, setInstance] = useState<PlaybackInfoClient | null>(
    () => new PlaybackInfoClient(venueId)
  );

  useEffect(() => {
    // Reading before FB is connected will throw, so we cannot initialize
    // outside of the useEffect :/
    if (!firebaseConnected) return;

    if (instance?.venueId !== venueId) {
      setInstance(new PlaybackInfoClient(venueId).on());
    } else {
      instance.on();
    }

    return () => instance?.off();
  }, [firebaseConnected, instance, venueId]);

  return <Context.Provider value={instance}>{props.children}</Context.Provider>;
}

function usePlaybackInfoContext() {
  const instance = useContext(Context);
  if (!instance) throw new Error('No PlaybackInfoClient found');
  return instance;
}

/**
 * Get the currently shared PlaybackDesc for the given mode.
 */
export function usePlaybackDesc(mode: SessionMode | null) {
  const instance = usePlaybackInfoContext();
  const snap = useExpose(instance);
  if (!mode) return null;
  return instance.getPlayback(mode, snap);
}

/**
 * Push the currently computed playback to be visible to all clients.
 */
export function usePlaybackInfoWrite() {
  const instance = usePlaybackInfoContext();
  return useLiveCallback(async (store: GamePlayStore) => {
    const update = {
      [SessionMode.Live]: store.getResolvedPlayback(SessionMode.Live),
      [SessionMode.OnDemand]: store.getResolvedPlayback(SessionMode.OnDemand),
    };

    await instance.write(update);
  });
}

/**
 * Write data that is related to the Playback or could be indexed by
 * PlaybackItemIds, but is only available _after_ the Playback has been
 * generated.
 */
export function usePlaybackInfoWriteExtra() {
  const instance = usePlaybackInfoContext();
  const [write] = useState(() => instance.writeExtra.bind(instance));
  return write;
}

/**
 * Remove the currently computed playback from all clients.
 */
export function usePlaybackInfoEmpty() {
  const instance = usePlaybackInfoContext();
  return useLiveCallback(async () => {
    await instance.empty();
  });
}

export function usePlaybackInfoClient() {
  return usePlaybackInfoContext();
}

/**
 * Using the currently shared playback, get the brand for the current block.
 */
export function usePlaybackInfoCurrentBlockBrand() {
  const mode = useCurrentSessionMode();
  const id = useGameSessionBlockId();
  const instance = usePlaybackInfoContext();
  const snap = useExpose(instance);
  if (!id || !mode) return null;
  return instance.getBrand(id, mode, snap);
}

/**
 * Using the currently shared playback, get the item for the current block.
 */
export function usePlaybackInfoCurrentBlock() {
  const mode = useCurrentSessionMode();
  const id = useGameSessionBlockId();
  const instance = usePlaybackInfoContext();
  const snap = useExpose(instance);
  if (!id || !mode) return null;
  return instance.getBlockPlaybackItemByBlockId(id, mode, snap);
}

/**
 * Using the currently shared playback and block, get the matching `extra`
 * playback data.
 */
export function usePlaybackInfoExtra() {
  const item = usePlaybackInfoCurrentBlock();
  const instance = usePlaybackInfoContext();
  const snap = useExpose(instance);
  const mode = useCurrentSessionMode();
  if (!mode || !item) return null;
  return snap.extras?.[mode]?.[item?.id] ?? null;
}

// Feel free to expose more utilities for reading PlaybackDesc here.

// note(falcon): we don't have a standard and consistent meaning of "game". in some cases it's roughly
// equivalent to a brand, but it's possible that within a single brand we have multiple, separate, and distinct "games".
// we're starting with a loose equivalence of a "game" to a "brand". i'm sure this won't be the last time we have to
// revisit this.
type GameOverview = {
  brand: DtoBrand;
  startIndex: number;
  endIndex: number;
  startPlaybackItem: PlaybackItemId;
  endPlaybackItem: PlaybackItemId;
};

export function getPlaybackGamesOverview(
  playbackDesc: Nullable<PlaybackDesc>
): GameOverview[] {
  if (!playbackDesc) return [];

  const overview: GameOverview[] = [];
  for (let i = 0; i < playbackDesc.items.length; i++) {
    const item = playbackDesc.items[i];
    const brand = item.brand;
    if (brand) {
      // this is the start of a new game...let's find the end.
      const startIndex = i;
      const startPlaybackItem = item.id;
      let endIndex = i;
      let endPlaybackItem = item.id;

      for (let j = i + 1; j < playbackDesc.items.length; j++) {
        const nextItem = playbackDesc.items[j];
        if (nextItem.brand?.id !== brand.id) {
          endIndex = j - 1;
          endPlaybackItem = nextItem.id;
          i = j - 1;
          break;
        } else if (j + 1 === playbackDesc.items.length) {
          // end of the list
          endIndex = j;
          endPlaybackItem = nextItem.id;
          i = j;
          break;
        }
      }

      overview.push({
        brand,
        startIndex,
        endIndex,
        startPlaybackItem,
        endPlaybackItem,
      });
    }
  }

  return overview;
}
