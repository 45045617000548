import {
  type ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  type FormatOptionLabelMeta,
  type MultiValue,
  type PropsValue,
  type SingleValue,
} from 'react-select';
import AsyncSelect from 'react-select/async';
import AsyncCreatableSelect from 'react-select/async-creatable';

import { useInstance } from '../../hooks/useInstance';
import { apiService } from '../../services/api-service';
import { type Tag } from '../../types/tag';
import { buildReactSelectStyles } from '../../utils/react-select';

export interface TagOption extends Tag {
  label: string;
  value: string;
  __isNew__?: boolean;
}

interface TagPickerSharedProps {
  tags?: Tag[];
  creatable?: boolean;
  placeholder?: string;
  filter?: (tag: Tag) => boolean;
  formatMeta?: (tagOption: TagOption) => string;
  disabled?: boolean;
}

type TagPickerProps =
  | (TagPickerSharedProps & {
      multi: true;
      onChange?: (value: Tag[]) => void;
    })
  | (TagPickerSharedProps & {
      multi: false;
      onChange?: (value: Tag) => void;
    });

function tagsToOptions(tags?: Tag[]): TagOption[] {
  if (!tags) return [];
  return tags.map((t) => ({ ...t, label: t.name, value: t.name }));
}

export function TagPicker(
  props: TagPickerProps & {
    styles?: Parameters<typeof buildReactSelectStyles>[0];
  }
): JSX.Element | null {
  const tagMap = useInstance(() => new Map<number, Tag>());
  const singleStyles = useMemo(
    () => buildReactSelectStyles<TagOption, false>(props.styles),
    [props.styles]
  );
  const multiStyles = useMemo(
    () => buildReactSelectStyles<TagOption, true>(props.styles),
    [props.styles]
  );
  const [selected, setSelected] = useState<PropsValue<TagOption> | null>(null);

  const addToMap = useCallback(
    (tags: Tag[]) => {
      tags.forEach((t) => {
        tagMap.set(t.id, t);
      });
    },
    [tagMap]
  );

  useEffect(() => {
    if (!props.tags) return;
    setSelected(tagsToOptions(props.tags));
    addToMap(props.tags);
  }, [props.tags, addToMap]);

  useEffect(() => {
    return () => tagMap.clear();
  }, [tagMap]);

  // TODO: fix pagination
  // This only load 100 tags without pagination because react-select
  // doesn't support it well. User can still narrow down the list by changing
  // the query keywords.
  const loadOptions = async (q: string): Promise<TagOption[]> => {
    const paginator = apiService.tag.search(q);
    const tags = await paginator.next();
    addToMap(tags);
    return tagsToOptions(tags);
  };

  const handleMultiChange = (options: MultiValue<TagOption>) => {
    if (!props.onChange || !props.multi) return;
    setSelected(options);
    const tags: Tag[] = [];
    options.forEach((o) => {
      if (o.__isNew__) {
        tags.push({ id: 0, name: o.label, slug: '', extensions: [] });
      }
      const tag = tagMap.get(o.id);
      if (tag) {
        tags.push(tag);
      }
    });
    props.onChange(tags);
  };

  const handleSingleChange = (option: SingleValue<TagOption>) => {
    if (!props.onChange || props.multi) return;
    setSelected(option);
    if (option) {
      const tag = tagMap.get(option.id);
      if (tag) props.onChange(tag);
      setSelected(null);
    }
  };

  const handleFormat = (
    data: TagOption,
    meta: FormatOptionLabelMeta<TagOption>
  ): ReactNode => {
    return (
      <div className='w-full flex flex-row justify-between'>
        <span>{data.label}</span>
        {meta.context === 'menu' && (
          <span className='text-secondary'>
            {props.formatMeta ? props.formatMeta(data) : ''}
          </span>
        )}
      </div>
    );
  };

  const handleFilter = (option: { data: TagOption }): boolean => {
    const tag = tagMap.get(option.data.id);
    if (props.filter && tag) {
      return props.filter(tag);
    }
    return true;
  };

  const Select = props.creatable ? AsyncCreatableSelect : AsyncSelect;

  const args = {
    placeholder: props.placeholder,
    styles: multiStyles,
    cacheOptions: true,
    defaultOptions: true,
    loadOptions: loadOptions,
    classNamePrefix: 'select-box-v2',
    noOptionsMessage: (): ReactNode => {
      return <div>0 matching results</div>;
    },
    formatOptionLabel: handleFormat,
    filterOption: handleFilter,
    value: selected,
    isDisabled: props.disabled,
  };

  if (props.multi) {
    return (
      <Select<TagOption, true> {...args} isMulti onChange={handleMultiChange} />
    );
  }
  return (
    <Select<TagOption, false>
      {...args}
      styles={singleStyles}
      onChange={handleSingleChange}
    />
  );
}
