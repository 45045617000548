import getAgent from '@egjs/agent';
import React, {
  type ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { useRTCService } from './WebRTC';

export function isMusicShareSupported(): boolean {
  const agent = getAgent();
  if (agent.browser.chromium) return true;
  return false;
}

type MusicShareContext = {
  musicShareActive: boolean;
  musicShareAvailable: boolean;
  start: () => void;
  stop: () => void;
};

const Context = React.createContext<MusicShareContext>({} as MusicShareContext);

export const useMusicShareContext = (): MusicShareContext => {
  const ctx = useContext(Context);
  return ctx;
};

export const MusicShareProvider = (props: {
  children?: ReactNode;
}): JSX.Element => {
  const rtc = useRTCService('stage');
  const [musicShareActive, setMusicShareActive] = useState(false);
  const [musicShareAvailable, setMusicShareAvailable] = useState(false);

  useEffect(() => {
    rtc.on('music-share-changed', setMusicShareActive);
    return () => {
      rtc.off('music-share-changed', setMusicShareActive);
    };
  }, [rtc]);

  useEffect(() => {
    setMusicShareAvailable(isMusicShareSupported());
  }, []);

  const start = useCallback(() => {
    return rtc.attemptMusicShare();
  }, [rtc]);

  const stop = useCallback(() => {
    return rtc.stopMusicShare();
  }, [rtc]);

  const ctxValue: MusicShareContext = useMemo(
    () => ({
      musicShareActive,
      musicShareAvailable,
      start,
      stop,
    }),
    [musicShareActive, musicShareAvailable, start, stop]
  );

  return <Context.Provider value={ctxValue}>{props.children}</Context.Provider>;
};
