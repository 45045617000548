import { useMemo } from 'react';
import Select from 'react-select';

import { EnumsBlockSearchSortBy } from '@lp-lib/api-service-client/public';

import { buildReactSelectStyles } from '../../utils/react-select';
import { type Option } from '../common/Utilities';

const ALL_OPTIONS = [
  {
    label: 'Best Match',
    value: EnumsBlockSearchSortBy.BlockSearchSortByBestMatch,
  },
  {
    label: 'Recent Updated',
    value: EnumsBlockSearchSortBy.BlockSearchSortByRecentUpdated,
  },
];

function convertToOption(value: EnumsBlockSearchSortBy) {
  return ALL_OPTIONS.find((o) => o.value === value);
}

export function BlockSortSelect(props: {
  value: EnumsBlockSearchSortBy | null;
  onChange: (value: EnumsBlockSearchSortBy | null) => void;
  className?: string;
  placeholder?: string;
  isClearable?: boolean;
}): JSX.Element {
  const { value, onChange, className } = props;

  const styles = useMemo(
    () =>
      buildReactSelectStyles<Option<EnumsBlockSearchSortBy>>({
        override: {
          control: {
            width: '100%',
            height: '100%',
          },
        },
      }),
    []
  );

  return (
    <Select<Option<EnumsBlockSearchSortBy>, false>
      value={value ? convertToOption(value) : null}
      options={ALL_OPTIONS}
      onChange={(v) => onChange(v?.value ?? null)}
      className={`mb-0 ${className}`}
      classNamePrefix='select-box-v2'
      styles={styles}
      isMulti={false}
      placeholder={props.placeholder ?? 'Select Sort Method'}
      isClearable={props.isClearable}
    />
  );
}
