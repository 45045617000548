import keyBy from 'lodash/keyBy';
import sample from 'lodash/sample';
import pluralize from 'pluralize';

import {
  type DtoTeam,
  EnumsBrandPredefinedBlockScenario,
} from '@lp-lib/api-service-client/public';

import { getFeatureQueryParamNumber } from '../../../hooks/useFeatureQueryParam';
import { useLiveCallback } from '../../../hooks/useLiveCallback';
import {
  type Participant,
  type ParticipantMap,
  SessionMode,
} from '../../../types';
import { type FirebaseService, useFirebaseContext } from '../../Firebase';
import { useCohostGetter, useParticipantsAsArrayGetter } from '../../Player';
import { useStreamSessionId } from '../../Session';
import { useTeamMembersByTeamIds, useTeams } from '../../TeamAPI/TeamV1';
import { useVenueId } from '../../Venue';
import { VariableRegistry } from '../../VoiceOver/VariableRegistry';
import { BlockOutputDAO } from '../Blocks/Common/block-outputs';
import {
  useGameSessionPreconfigNewParticipants,
  useGameSessionPreconfigVIPParticipants,
} from '../Blocks/Common/GamePlay/GameSessionPreconfigProvider';
import {
  useLocalGamePlayStore,
  useLocalLoadedGameLike,
} from '../GamePlayStore';
import { type GameInfoSnapshot } from '../OndPhaseRunner/types';
import {
  type PlaybackDesc,
  type PlaybackItemId,
} from '../Playback/intoPlayback';
import {
  usePlaybackDesc,
  usePlaybackInfoClient,
} from '../Playback/PlaybackInfoProvider';
import { gameSessionStore } from '../store';
import {
  useCurrentSessionMode,
  useGameSessionBlockId,
  useScoreSummary,
} from './gameSessionHooks';

/**
 * Anything that the BlockKnifeUtils need that is only available within react
 * should be accessed here and added to GameInfoSnapshot. The OnD system, for
 * example, uses a single, non-react loop to manage ticking. It must poll the
 * state.
 */
export function useCreateGameInfoSnapshot(): (
  // this is the playback item we want to generate a recording for.
  playbackItemId?: PlaybackItemId | null
) => GameInfoSnapshot | null {
  const sessionId = useStreamSessionId();
  const mode = useCurrentSessionMode();
  const gamePlayStore = useLocalGamePlayStore();
  const gameLike = useLocalLoadedGameLike();
  const blockId = useGameSessionBlockId();
  const scoreSummary = useScoreSummary();
  const playbackDesc = usePlaybackDesc(mode);
  const playbackInfoClient = usePlaybackInfoClient();
  const getParticipants = useParticipantsAsArrayGetter();
  const activeTeams = useTeams({
    active: true,
    excludeStaffTeam: true,
    excludeCohostTeam: true,
    updateStaffTeam: true,
  });
  const members = useTeamMembersByTeamIds(activeTeams.map((team) => team.id));
  const vipParticipants = useGameSessionPreconfigVIPParticipants();
  const newParticipants = useGameSessionPreconfigNewParticipants();
  const getCohost = useCohostGetter();
  const venueId = useVenueId();
  const svc = useFirebaseContext().svc;

  return useLiveCallback((pbid: PlaybackItemId | undefined | null) => {
    if (!sessionId) return null;

    const participants = getParticipants({
      filters: [
        'status:connected',
        'host:false',
        'cohost:false',
        'staff:false',
      ],
    });

    const lookup: ParticipantMap = Object.fromEntries(
      participants.map((p) => [p.clientId, p])
    );

    const teams = keyBy(
      activeTeams.map((team) => {
        const clientIds = members[team.id].map((m) => m.id);
        const players = [];
        for (const clientId of clientIds) {
          const p = lookup[clientId];
          if (p) players.push({ uid: p.id, name: p.firstName ?? p.username });
        }
        return {
          id: team.id,
          name: team.name,
          players,
        };
      }),
      'id'
    );

    // TODO(drew): I have a feeling there is a still a race condition here when
    // calling this function and expecting score data. detailScores are written,
    // depending on the block, during POINTS_DISTRIBUTED, which is probably the
    // same time this callback is expected to have score info. As a workaround,
    // we may need something like the following to allow the scores to
    // propagate. Another option is using the firebase ref directly via .on().

    // if ((blockId && !gameSessionStore.detailScores?.[blockId])) {
    //   await Promise.race([new Promise<void>(resolve => {
    //     const unsub1 = subscribe(gameSessionStore.detailScores, () => {
    //       resolve();
    //       unsub1();
    //     })
    //   }), new Promise(resolve => setTimeout(resolve, 2000))]);
    // }

    // Convert to DTO format
    const scoreboard = Object.entries(scoreSummary ?? {})
      .map(([teamId, ts]) => {
        return {
          score: ts.totalScore,
          prevScore: ts.prevScore,
          teamId,
        };
      })
      .sort((a, b) => b.score - a.score);

    const nextMiniGame = blockId
      ? gamePlayStore.getNextGamePackGame(blockId, SessionMode.OnDemand)
      : null;

    const blockPlaybackDesc =
      pbid && mode ? playbackInfoClient.getBlockPlaybackItem(pbid, mode) : null;

    const snap = {
      blockId: gameSessionStore.session?.blockSession?.block?.id ?? '',
      sessionId: sessionId,
      teams,
      scoreboard,
      gamePack:
        gameLike?.type === 'gamePack'
          ? {
              id: gameLike.id,
              name: gameLike.name,
              description: gameLike.description ?? undefined,
            }
          : undefined,
      miniGame:
        gameLike?.type === 'game'
          ? {
              id: gameLike.id,
              name: gameLike.name,
              description: gameLike.description ?? undefined,
            }
          : undefined,
      nextMiniGame: nextMiniGame
        ? {
            id: nextMiniGame.id,
            name: nextMiniGame.name,
            description: nextMiniGame.description ?? undefined,
          }
        : undefined,
      pauseOnEndBlockMaxWaitSec: getFeatureQueryParamNumber(
        'game-on-demand-pause-on-end-block-max-wait-sec'
      ),
      teamCount: activeTeams.length,
      participants,
      numUnitsInSession: playbackDesc?.uiInfo.unitsThisSession ?? 0,
      unitLabel: playbackDesc?.uiInfo.unitLabel ?? 'game',
      brand: blockPlaybackDesc?.brand
        ? {
            id: blockPlaybackDesc?.brand.id,
            name: blockPlaybackDesc?.brand.name,
            description: blockPlaybackDesc?.brand.showcaseText,
          }
        : undefined,
      sessionUnitIndex: blockPlaybackDesc?.sessionUnitIndex ?? undefined,
      blockScenario: blockPlaybackDesc?.scenario ?? undefined,

      vips: vipParticipants.map((p) => ({
        id: p.id,
        name: p.firstName ?? p.username,
      })),
      cohost: getCohost(),
    };

    const vr = makeVariableRegistrySnapshot(
      getParticipants,
      snap,
      newParticipants,
      playbackDesc,
      venueId,
      svc
    );
    return {
      ...snap,
      variableRegistry: vr,
    };
  });
}

function makeVariableRegistrySnapshot(
  getParticipants: ReturnType<typeof useParticipantsAsArrayGetter>,
  snap: Omit<GameInfoSnapshot, 'variableRegistry'>,
  newParticipants: Participant[],
  playbackDesc: Nullable<PlaybackDesc>,
  venueId: string,
  svc: FirebaseService
) {
  const vr = new VariableRegistry();

  vr.set('allTeamNames', async () =>
    Object.values(snap.teams)
      .map((team) => `"${team.name}"`)
      .join(', ')
  );
  vr.set('randomTeamName', async () => {
    const team = sample(Object.values(snap.teams));
    return team ? `"${team.name}"` : '';
  });
  vr.set('allPlayerNames', async () =>
    getParticipants({
      filters: [
        'status:connected',
        'host:false',
        'cohost:false',
        'staff:false',
      ],
    })
      .map(
        (participant) => `"${participant.firstName ?? participant.username}"`
      )
      .join(', ')
  );
  vr.set('randomPlayerName', async () => {
    const participant = sample(
      getParticipants({
        filters: [
          'status:connected',
          'host:false',
          'cohost:false',
          'staff:false',
        ],
      })
    );
    return participant
      ? `"${participant.firstName ?? participant.username}"`
      : '';
  });

  const registerTeamVars = (
    prefix: 'firstPlace' | 'secondPlace' | 'thirdPlace' | 'lastPlace',
    getTeam: () => DtoTeam | undefined,
    getScore: () => number | undefined
  ) => {
    vr.set(`${prefix}TeamName`, async () => {
      const team = getTeam();
      return team ? `"${team.name}"` : '';
    });
    vr.set(`${prefix}TeamPlayerNames`, async () => {
      const team = getTeam();
      return team ? team.players.map((p) => `"${p.name}"`).join(', ') : '';
    });
    vr.set(`${prefix}TeamScore`, async () => {
      const score = getScore();
      return score?.toString() ?? '';
    });
  };

  const scoreboard = snap.scoreboard;
  registerTeamVars(
    'firstPlace',
    () =>
      scoreboard[0]?.teamId ? snap.teams[scoreboard[0].teamId] : undefined,
    () => scoreboard[0]?.score
  );
  registerTeamVars(
    'secondPlace',
    () =>
      scoreboard[1]?.teamId ? snap.teams[scoreboard[1].teamId] : undefined,
    () => scoreboard[1]?.score
  );
  registerTeamVars(
    'thirdPlace',
    () =>
      scoreboard[2]?.teamId ? snap.teams[scoreboard[2].teamId] : undefined,
    () => scoreboard[2]?.score
  );
  registerTeamVars(
    'lastPlace',
    () =>
      scoreboard[scoreboard.length - 1]?.teamId
        ? snap.teams[scoreboard[scoreboard.length - 1].teamId]
        : undefined,
    () => scoreboard[scoreboard.length - 1]?.score
  );

  vr.set('brandName', async () =>
    snap.brand?.name ? `"${snap.brand.name}"` : ''
  );
  vr.set('brandDescription', async () => snap.brand?.description ?? '');
  vr.set('unitCount', async () => snap.numUnitsInSession.toString());
  vr.set('remainingUnitCount', async () =>
    snap.sessionUnitIndex === undefined
      ? ''
      : (snap.numUnitsInSession - (snap.sessionUnitIndex + 1)).toString()
  );
  vr.set(
    'currentUnitIndex',
    async () => snap.sessionUnitIndex?.toString() ?? ''
  );
  vr.set(
    'vipNames',
    async () => snap.vips?.map((vip) => `"${vip.name}"`).join(', ') ?? ''
  );
  vr.set('firstTimePlayerNames', async () => {
    if (newParticipants.length === 0) {
      return '';
    }
    return newParticipants
      .map((p) => `"${p.firstName ?? p.username}"`)
      .join(', ');
  });

  vr.set('gamePackTitle', async () =>
    snap.gamePack?.name ? `"${snap.gamePack.name}"` : ''
  );
  vr.set('gamePackDescription', async () => snap.gamePack?.description ?? '');
  vr.set(
    'cohostName',
    async () => snap.cohost?.firstName ?? snap.cohost?.username ?? ''
  );

  // llm variables
  vr.set('gameProgress', async () => {
    if (snap.sessionUnitIndex === undefined) return '';

    let roundPosition = 'middle';
    if (snap.blockScenario) {
      const scenario = snap.blockScenario;
      if (
        scenario ===
        EnumsBrandPredefinedBlockScenario.BrandPredefinedBlockScenarioOpeningTitle
      ) {
        roundPosition = 'beginning';
      }
      if (
        scenario ===
        EnumsBrandPredefinedBlockScenario.BrandPredefinedBlockScenarioScoreboard
      ) {
        roundPosition = 'end';
      }
    }

    const rounds = pluralize('round', snap.numUnitsInSession);
    const remainingUnitCount =
      snap.numUnitsInSession - (snap.sessionUnitIndex + 1);
    const isGameOver = remainingUnitCount === 0 && roundPosition === 'end';
    let gameProgress = `This is the ${roundPosition} of ${
      snap.sessionUnitIndex + 1
    } of ${snap.numUnitsInSession} ${rounds}.`;
    if (isGameOver) {
      gameProgress += '  The game is over.';
    }
    return gameProgress;
  });

  vr.set('previousScoreboard', async () => humanReadableScoreboard(snap, true));
  vr.set('currentScoreboard', async () => humanReadableScoreboard(snap, false));

  // Set the "block reference id" variables
  for (const item of playbackDesc?.items ?? []) {
    const refId = item.block.fields.referenceId;
    if (!refId) continue;
    vr.set(`block:${refId}`, async () => {
      const dao = new BlockOutputDAO(venueId, svc);
      const record = await dao.readForBlock(item.block.id);
      // Attempt to sanitize the value to prevent "voice over injection" of
      // variables.
      return record?.value.replace(/[%\u0025]/g, '') ?? '';
    });
  }

  return vr;
}

function humanReadableScoreboard(
  req: Omit<GameInfoSnapshot, 'variableRegistry'>,
  usePrevScore: boolean
): string {
  if (req.scoreboard.length === 0) {
    return 'Empty scoreboard';
  }

  const teamScores: Record<string, number> = {};
  for (const entry of req.scoreboard) {
    if (usePrevScore) {
      teamScores[entry.teamId] = entry.prevScore;
    } else {
      teamScores[entry.teamId] = entry.score;
    }
  }

  const scoreboard: {
    rank: number;
    teamName: string;
    teamMembers: string;
    score: number;
  }[] = [];
  for (const [teamId, score] of Object.entries(teamScores)) {
    const team = req.teams[teamId];
    if (!team) {
      continue;
    }
    const members = team.players.map((player) => player.name.trim()).join(', ');
    scoreboard.push({
      rank: 0,
      teamName: team.name.trim(),
      teamMembers: members,
      score: score,
    });
  }

  if (scoreboard.length === 0) {
    return 'Empty scoreboard';
  }

  scoreboard.sort((a, b) => b.score - a.score);

  let rank = 1;
  let prevScore = scoreboard[0].score;
  for (let i = 0; i < scoreboard.length; i++) {
    if (scoreboard[i].score !== prevScore) {
      rank++;
      prevScore = scoreboard[i].score;
    }
    scoreboard[i].rank = rank;
  }

  const result = scoreboard
    .map(
      (entry) =>
        `${entry.rank}. ${entry.teamName} (${entry.teamMembers}) - ${entry.score}`
    )
    .join('\n');

  return result.trim();
}

export function makeExampleVariableRegistrySnapshot() {
  const vr = new VariableRegistry();

  vr.set(
    'allTeamNames',
    async () => `"Gregarious Falcons", "Funny Cobras", and "Speedy Crocodiles"`
  );
  vr.set('randomTeamName', async () => `"Funny Cobras"`);
  vr.set(
    'allPlayerNames',
    async () => `"Alice", "Bob", "Charlie", "Jesse", and "David"`
  );
  vr.set('randomPlayerName', async () => `"Jesse"`);

  const registerTeamVars = (
    prefix: string,
    teamName: string,
    playerNames: string,
    score: number
  ) => {
    vr.set(`${prefix}TeamName`, async () => teamName);
    vr.set(`${prefix}TeamPlayerNames`, async () => playerNames);
    vr.set(`${prefix}TeamScore`, async () => score.toString());
  };

  registerTeamVars(
    'firstPlace',
    `"Gregarious Falcons"`,
    `"Alice" and "Bob"`,
    2378
  );
  registerTeamVars(
    'secondPlace',
    `"Funny Cobras"`,
    `"Charlie" and "Jesse"`,
    2001
  );
  registerTeamVars('thirdPlace', '"Speedy Crocodiles"', '"David"', 1999);
  registerTeamVars('lastPlace', '"Speedy Crocodiles"', '"David"', 1999);

  vr.set('brandName', async () => '"Hum That Tune!"');
  vr.set(
    'brandDescription',
    async () => 'Volunteer performs a song; first to name it wins'
  );
  vr.set('unitCount', async () => '3');
  vr.set('remainingUnitCount', async () => '2');
  vr.set('currentUnitIndex', async () => '1');
  vr.set('vipNames', async () => '"Jesse"');
  vr.set('firstTimePlayerNames', async () => '"Drew" and "Evan"');

  vr.set('gamePackTitle', async () => `"The Best Hour"`);
  vr.set(
    'gamePackDescription',
    async () =>
      "It's our curated collection of games that showcases the essence of Luna Park's magic! Through a blend of puzzles, trivia, and various interactive challenges, this series embodies what makes Luna Park truly special. It's a celebration of fun, creativity, and the joy of discovery, designed to deliver the BEST experience possible."
  );
  vr.set('cohostName', async () => 'Ahri');

  // llm variables
  vr.set(
    'gameProgress',
    async () => 'This is the 1 beginning of 1 of 3 rounds.'
  );

  vr.set('previousScoreboard', async () => 'Empty scoreboard');
  vr.set('currentScoreboard', async () => 'Empty scoreboard');

  vr.set('teamIntroTeamName', async () => 'Laughing Llamas');
  vr.set('teamIntroContestantNames', async () => 'Alice and Bob');
  return vr;
}
