import { useEffect } from 'react';

import { useUserContext } from '../UserContext';
import { useLiteModeEnabled } from './LiteModeContext';

/**
 * Automatically turn off the camera when lite mode is enabled.
 */
export function useLiteModeDisablesCamera(): void {
  const userCtx = useUserContext();
  const liteMode = useLiteModeEnabled();

  useEffect(() => {
    if (liteMode) {
      userCtx.updateUserStates({ lite: liteMode });
      userCtx.toggleVideo(false);
    }
  }, [liteMode, userCtx]);
}
