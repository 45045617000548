import { match } from 'ts-pattern';

import {
  type DtoBlock,
  type DtoBrand,
  EnumsBrandPredefinedBlockScenario,
} from '@lp-lib/api-service-client/public';
import { BlockType } from '@lp-lib/game';

export class BrandUtils {
  static PredefinedBlockScenarioDisplayName(
    scenario: EnumsBrandPredefinedBlockScenario
  ) {
    return match(scenario)
      .with(
        EnumsBrandPredefinedBlockScenario.BrandPredefinedBlockScenarioInstructions,
        () => 'Instructions'
      )
      .with(
        EnumsBrandPredefinedBlockScenario.BrandPredefinedBlockScenarioHostedInstructions,
        () => 'Hosted Instructions'
      )
      .with(
        EnumsBrandPredefinedBlockScenario.BrandPredefinedBlockScenarioOpeningTitle,
        () => 'Opening Title'
      )
      .with(
        EnumsBrandPredefinedBlockScenario.BrandPredefinedBlockScenarioDemo,
        () => 'Demo'
      )
      .with(
        EnumsBrandPredefinedBlockScenario.BrandPredefinedBlockScenarioScoreboard,
        () => 'Scoreboard'
      )
      .exhaustive();
  }
  static PredefinedBlockType(
    scenario: EnumsBrandPredefinedBlockScenario | null
  ) {
    return match(scenario)
      .with(
        EnumsBrandPredefinedBlockScenario.BrandPredefinedBlockScenarioInstructions,
        () => BlockType.INSTRUCTION
      )
      .with(
        EnumsBrandPredefinedBlockScenario.BrandPredefinedBlockScenarioHostedInstructions,
        () => BlockType.TITLE_V2
      )
      .with(
        EnumsBrandPredefinedBlockScenario.BrandPredefinedBlockScenarioOpeningTitle,
        () => BlockType.TITLE_V2
      )
      .with(
        EnumsBrandPredefinedBlockScenario.BrandPredefinedBlockScenarioScoreboard,
        () => BlockType.SCOREBOARD
      )
      .with(
        EnumsBrandPredefinedBlockScenario.BrandPredefinedBlockScenarioDemo,
        () => null
      )
      .with(null, () => null)
      .exhaustive();
  }

  static GetPredefinedBlocks(brand: DtoBrand | null | undefined): DtoBlock[] {
    if (!brand?.predefinedBlockData) return [];

    const blocks = [];
    for (const item of brand.predefinedBlockData) {
      const block = brand.allBlocks?.find((block) => block.id === item.id);
      if (block) {
        blocks.push(block);
      }
    }
    return blocks;
  }

  static GetOtherBlocks(brand: DtoBrand | null | undefined): DtoBlock[] {
    if (!brand?.blockIds) return [];

    const blocks = [];
    for (const id of brand.blockIds) {
      const block = brand.allBlocks?.find((block) => block.id === id);
      if (block) {
        blocks.push(block);
      }
    }
    return blocks;
  }

  static GetPredefinedBlock(
    brand: Nullable<DtoBrand>,
    scenario: EnumsBrandPredefinedBlockScenario
  ) {
    return brand?.predefinedBlockData?.find(
      (item) => item.scenario === scenario
    );
  }

  static FakeBrandForOrphanBlocks(): DtoBrand {
    return {
      id: '',
      name: '[Orphan Blocks]',
      blockLengthSec: 0,
      showcaseText: '',
    };
  }
}
