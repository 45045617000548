import { useEffect } from 'react';
import { useEffectOnce } from 'react-use';

import { type AIChatBlock, AIChatBlockGameSessionStatus } from '@lp-lib/game';

import { assertExhaustive } from '../../../../utils/common';
import { useGameSessionStatus } from '../../hooks';
import { type GameControlProps } from '../Common/GameControl/types';
import { useStableBlock } from '../Common/hooks';
import { useAIChatGameControlAPI } from './AIChatProvider';

function Loaded(_props: GameControlProps<AIChatBlock>) {
  const api = useAIChatGameControlAPI();

  useEffectOnce(() => {
    api.resetGame();
  });

  return null;
}

export function AIChatBlockGameControl(
  props: GameControlProps<AIChatBlock>
): JSX.Element | null {
  const block = useStableBlock(props.block);
  const gss = useGameSessionStatus<AIChatBlockGameSessionStatus>();

  const api = useAIChatGameControlAPI();

  useEffect(() => {
    return () => {
      api.resetGame();
    };
  }, [api]);

  switch (gss) {
    case AIChatBlockGameSessionStatus.LOADED:
      return <Loaded block={block} />;
    case AIChatBlockGameSessionStatus.INTRO:
    case AIChatBlockGameSessionStatus.GAME_INIT:
    case AIChatBlockGameSessionStatus.GAME_START:
    case AIChatBlockGameSessionStatus.GAME_END:
    case AIChatBlockGameSessionStatus.OUTRO:
    case AIChatBlockGameSessionStatus.RESULTS:
    case AIChatBlockGameSessionStatus.SCOREBOARD:
    case AIChatBlockGameSessionStatus.END:
    case null:
    case undefined:
      break;
    default:
      assertExhaustive(gss);
      break;
  }

  return null;
}
