import { useEffect, useRef, useState } from 'react';

export type DebouncedValueResult<T> = {
  /**
   * The value that has settled, or undefined if the value has not settled yet.
   */
  value: T | undefined;
  /**
   * Whether the value is initialling settling. Use this value to wait for the initial value to settle.
   */
  isInitializing: boolean;
  /**
   * True anytime the value is re-settling. This value may be true when `isLoading` is false.
   */
  isSettling: boolean;
};

export type UseDebouncedValueConfig = {
  /**
   * The time in milliseconds to wait before the value is considered settled.
   */
  settleAfterMs: number;

  /**
   * Whether to keep the previous value while the new value is settling.
   *
   * If true, the previous value will be kept until the new value has settled.
   * If false, the value is set to `undefined` while the new value is settling.
   */
  keepPreviousValue?: boolean;
};

/**
 * This hook is used to delay the rendering of a value until it has settled for a certain amount of time.
 *
 * @param value the value to be settled
 * @param config the configuration for the hook
 * @return the settled result
 */
export function useDebouncedValue<T>(
  value: T,
  config: UseDebouncedValueConfig
): DebouncedValueResult<T> {
  const [result, setResult] = useState<DebouncedValueResult<T>>({
    value: undefined,
    isInitializing: true,
    isSettling: true,
  });

  const hasLoaded = useRef(false);
  const hasSettled = useRef(false);
  const settleAfterMs = useRef(config.settleAfterMs);
  const keepPreviousValue = useRef(config.keepPreviousValue);

  useEffect(() => {
    if (hasSettled.current) {
      hasSettled.current = false;
      setResult((prev) => {
        if (keepPreviousValue.current) {
          return { ...prev, isSettling: true };
        }
        return { ...prev, value: undefined, isSettling: true };
      });
    }

    const timer = setTimeout(() => {
      hasLoaded.current = true;
      hasSettled.current = true;
      setResult({ value, isInitializing: false, isSettling: false });
    }, settleAfterMs.current);
    return () => {
      clearTimeout(timer);
    };
  }, [value]);

  useEffect(() => {
    settleAfterMs.current = config.settleAfterMs;
  }, [config.settleAfterMs]);

  useEffect(() => {
    keepPreviousValue.current = config.keepPreviousValue;
  }, [config.keepPreviousValue]);

  return result;
}
