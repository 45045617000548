import cloneDeep from 'lodash/cloneDeep';
import keyBy from 'lodash/keyBy';
import { useEffect, useMemo, useRef, useState } from 'react';
import {
  Controller,
  FormProvider,
  useFieldArray,
  useForm,
  useFormContext,
} from 'react-hook-form';
import { usePrevious } from 'react-use';
import { match } from 'ts-pattern';

import { EnumsMediaScene } from '@lp-lib/api-service-client/public';
import {
  type BlockFields,
  type GridSize,
  type Media,
  MediaTranscodeStatus,
  type PuzzleBlock,
  type PuzzleBlockMedia,
  type PuzzleDropSpot,
  PuzzleMode,
  type PuzzlePiece,
  type PuzzleSliceJob,
} from '@lp-lib/game';

import { useInstance } from '../../../../hooks/useInstance';
import { useLiveCallback } from '../../../../hooks/useLiveCallback';
import { apiService } from '../../../../services/api-service';
import { fromDTOBlock, toMediaDataDTO } from '../../../../utils/api-dto';
import { BrowserIntervalCtrl } from '../../../../utils/BrowserIntervalCtrl';
import { assertExhaustive, uuidv4 } from '../../../../utils/common';
import { MediaUtils } from '../../../../utils/media';
import { ValtioUtils } from '../../../../utils/valtio';
import {
  ConfirmCancelModalHeading,
  useAwaitFullScreenConfirmCancelModal,
} from '../../../ConfirmCancelModalContext';
import { Loading } from '../../../Loading';
import { MediaEditor } from '../../../MediaUploader/MediaEditor';
import { MiniMediaUploader } from '../../../MediaUploader/MiniMediaUploader';
import { useBlockEditorStore } from '../../../RoutedBlock';
import {
  AdditionalSettings,
  AdditionalSharedSettingsEditor,
  BlockMediaEditor,
  CreatableDurationSelect,
  EditorBody,
  EditorLayout,
  type EditorProps,
  formatDurationOption,
  type Option,
  RHFCheckbox,
  RHFSelectField,
  useEditor,
} from '../Common/Editor/EditorUtilities';
import { PuzzleUtils } from './utils';

const gridSizes: GridSize[] = [
  [1, 2],
  [1, 3],
  [1, 4],
  [1, 5],
  [1, 6],
  [2, 2],
  [2, 3],
  [2, 4],
  [2, 5],
  [3, 3],
  [3, 4],
  [3, 5],
  [3, 6],
  [4, 4],
  [4, 5],
  [4, 6],
  [5, 5],
  [5, 6],
  [6, 6],
].map(([numRows, numCols]) => ({ numRows, numCols }));

const squareGridSize = gridSizes.filter((s) => s.numCols === s.numRows);

const makeGridOption = (gridSize: GridSize, index: number) => ({
  label: `${gridSize.numRows} x ${gridSize.numCols} (${
    gridSize.numRows * gridSize.numCols
  } pieces)`,
  value: index,
});

const gridOptions: Option[] = gridSizes.map((s, i) => makeGridOption(s, i));
const squareGridOptions: Option[] = squareGridSize.map((s, i) =>
  makeGridOption(s, i)
);

const timeOptions: Option[] = [
  10, 15, 20, 30, 45, 60, 90, 120, 150, 180, 210, 240, 300, 600, 900, 1800,
].map((secs) => {
  return { label: formatDurationOption(secs), value: secs };
});

const pointOptions: Option[] = [
  0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 200, 300,
].map((i) => ({
  value: i,
  label: `${i}`,
}));

const bonusPointOptions: Option[] = [0, 25, 50, 100, 150, 200, 250, 300].map(
  (i) => ({
    value: i,
    label: `${i}`,
  })
);

function MediaPreview(props: {
  media: Media | null | undefined;
  alt: string;
}): JSX.Element | null {
  const mediaUrl = MediaUtils.PickMediaUrl(props.media);
  if (!mediaUrl) return null;

  return <img src={mediaUrl} className='w-full h-full' alt={props.alt} />;
}

type GridPreviewDisplay = 'label' | 'piece' | 'dropSpot';

function GridPreview(props: {
  block: PuzzleBlock;
  display?: GridPreviewDisplay;
  gridMax?: string;
}): JSX.Element {
  const { block } = props;
  const { numRows, numCols } = block.fields.gridSize;
  const display = props.display ?? 'label';

  const piecePairs = useMemo(
    () => computePiecePairs(block.fields.pieces, block.fields.dropSpots),
    [block.fields.dropSpots, block.fields.pieces]
  );

  const style = {
    gridTemplateColumns: `repeat(${numCols}, minmax(0, ${
      props.gridMax ?? '1fr'
    }))`,
  };

  const cells: JSX.Element[] = [];

  for (let row = 0; row < numRows; row++) {
    for (let col = 0; col < numCols; col++) {
      const label = PuzzleUtils.GridPositionLabel(row, col);
      const pair = piecePairs[row * numCols + col];

      let content;
      switch (display) {
        case 'dropSpot': {
          content = (
            <div className='w-full h-full border border-dashed'>
              <MediaPreview
                media={pair?.dropSpot.media}
                alt='Drop Spot Preview'
              />
            </div>
          );
          break;
        }
        case 'piece': {
          content = (
            <MediaPreview media={pair?.piece.media} alt='Piece Preview' />
          );
          break;
        }
        case 'label':
          content = <div className='text-center'>{label}</div>;
          break;
        default:
          assertExhaustive(display);
          break;
      }

      cells.push(
        <div
          key={label}
          className='bg-gray-500 aspect-w-16 aspect-h-9 flex items-center justify-center text-sms font-medium'
        >
          {content}
        </div>
      );
    }
  }

  return (
    <div className='grid gap-1 select-none pointer-events-none' style={style}>
      {cells}
    </div>
  );
}

function PiecePairEditor(props: {
  blockId: string;
  index: number;
  label: string;
  updatePieces: () => Promise<void>;
  updateDropSpots: () => Promise<void>;
}): JSX.Element {
  const { blockId, index, updatePieces, updateDropSpots } = props;
  const { control } = useFormContext<PuzzleBlockEditorFields>();

  return (
    <div className='flex h-16 w-full my-2 bg-black rounded-lg items-center'>
      <div className='w-50 ml-4 text-sms'>Piece {props.label}</div>
      <div className='ml-auto'></div>
      <div className='w-25 mx-2'>
        <Controller
          control={control}
          name={`piecePairs.${index}.piece`}
          render={({ field }) => {
            const handleMediaChange = async (media: Media | null) => {
              field.onChange({
                ...field.value,
                mediaId: media?.id ?? null,
                media,
              });
              await updatePieces();
            };

            return (
              <MiniMediaUploader
                uploaderId={`${blockId}-piecePairs-${index}-pieceMedia`}
                media={field.value.media}
                onDelete={() => handleMediaChange(null)}
                onUploadSuccess={handleMediaChange}
              />
            );
          }}
        />
      </div>
      <div className='w-25 mx-2'>
        <Controller
          control={control}
          name={`piecePairs.${index}.dropSpot`}
          render={({ field }) => {
            const handleMediaChange = async (media: Media | null) => {
              field.onChange({
                ...field.value,
                mediaId: media?.id ?? null,
                media,
              });
              await updateDropSpots();
            };

            return (
              <MiniMediaUploader
                uploaderId={`${blockId}-piecePairs-${index}-dropSpotMedia`}
                media={field.value.media}
                onDelete={() => handleMediaChange(null)}
                onUploadSuccess={handleMediaChange}
              />
            );
          }}
        />
      </div>
    </div>
  );
}

function PieceManagement(props: EditorProps<PuzzleBlock>): JSX.Element {
  const { block } = props;
  const store = useBlockEditorStore();
  const confirmCancel = useAwaitFullScreenConfirmCancelModal();

  const [preview, setPreview] = useState<'label' | 'piece' | 'dropSpot'>(
    'label'
  );
  const { updateField } = useEditor(props);
  const { fields, replace } = useFieldArray<
    PuzzleBlockEditorFields,
    'piecePairs'
  >({
    name: 'piecePairs',
  });
  const { getValues } = useFormContext<PuzzleBlockEditorFields>();
  useEffect(() => {
    setPreview('label');
  }, [block.id]);

  const persistFieldWithMediaChange = async function updateField<
    T extends keyof BlockFields<PuzzleBlock>
  >(
    field: T,
    valueWithoutMedia: BlockFields<PuzzleBlock>[T],
    valueWithMedia: BlockFields<PuzzleBlock>[T]
  ) {
    props.setSavingChanges(true);
    try {
      await apiService.block.updateField<PuzzleBlock>(block.id, {
        field,
        value: valueWithoutMedia,
      });
      store.setBlockField({
        blockId: block.id,
        blockField: { [field]: valueWithMedia },
      });
    } catch (err) {
      throw err;
    } finally {
      props.setSavingChanges(false);
    }
  };

  const updatePieces = async () => {
    const pieces = getValues('piecePairs')
      .map((p) => p.piece)
      .map((p) => ({
        ...p,
        mediaId: p.media?.id ?? null,
        media: p.media ?? null,
      }));

    const piecesWithoutMedia = pieces.map((p) => ({
      ...p,
      media: null,
    }));

    await persistFieldWithMediaChange('pieces', piecesWithoutMedia, pieces);
  };

  const updateDropSpots = async () => {
    const dropSpots = getValues('piecePairs')
      .map((p) => p.dropSpot)
      .map((p) => ({
        ...p,
        mediaId: p.media?.id ?? null,
        media: p.media ?? null,
      }));

    const dropSpotsWithoutMedia = dropSpots.map((p) => ({
      ...p,
      media: null,
    }));

    await persistFieldWithMediaChange(
      'dropSpots',
      dropSpotsWithoutMedia,
      dropSpots
    );
  };

  const currentGridSizeIndex = Math.max(
    0,
    gridSizes.findIndex(
      ({ numRows, numCols }) =>
        numRows === block.fields.gridSize.numRows &&
        numCols === block.fields.gridSize.numCols
    )
  );
  const currentGridSize = gridSizes[currentGridSizeIndex];

  const onGridSizeChange = async (
    _: keyof BlockFields<PuzzleBlock>,
    value: Nullable<Option['value'], false>
  ) => {
    if (typeof value !== 'number') return;
    const newGridSize = gridSizes[value];
    if (
      newGridSize.numRows < currentGridSize.numRows ||
      newGridSize.numCols < currentGridSize.numCols
    ) {
      const response = await confirmCancel({
        kind: 'confirm-cancel',
        prompt: (
          <div className='text-white flex-col items-center justify-center py-2 px-4'>
            <ConfirmCancelModalHeading>
              Remove excess pieces?
            </ConfirmCancelModalHeading>
            <div className='text-sms text-center my-2'>
              Reducing the number of rows or columns will delete some pieces
              from the list.
            </div>
          </div>
        ),
        confirmBtnLabel: 'Continue',
        cancelBtnLabel: 'Cancel',
        autoFocus: 'cancel',
      });

      if (response.result === 'canceled') return;
    }
    updateField('gridSize', gridSizes[value]);

    const existingPairs: Record<string, PiecePair> = {};
    fields.forEach((field, index) => {
      const label = PuzzleUtils.GridPositionLabelFromIndex(
        index,
        currentGridSize.numCols
      );
      existingPairs[label] = field;
    });

    // rebuild the grid...
    const newGrid: PiecePair[] = [];
    for (let row = 0; row < newGridSize.numRows; row++) {
      for (let col = 0; col < newGridSize.numCols; col++) {
        const label = PuzzleUtils.GridPositionLabel(row, col);
        newGrid.push(existingPairs[label] ?? newPiecePair());
      }
    }

    replace(newGrid);
    await Promise.all([updateDropSpots(), updatePieces()]);
  };

  const onDisplayChange = (
    e: React.MouseEvent<HTMLButtonElement>,
    display: GridPreviewDisplay
  ) => {
    e.preventDefault();
    setPreview(display);
  };

  return (
    <>
      <div className='flex items-top gap-8'>
        <div className='flex-grow'>
          <RHFSelectField<PuzzleBlock>
            className='flex-grow h-10 my-2 text-white'
            label='Grid Options'
            name='gridSize'
            options={gridOptions}
            onChange={onGridSizeChange}
            value={currentGridSizeIndex}
          />
          <div className='flex items-center gap-2'>
            <button
              type='button'
              className='btn btn-secondary text-sm px-4 py-2'
              onClick={(e) => onDisplayChange(e, 'piece')}
            >
              Show pieces
            </button>
            <button
              type='button'
              className='btn btn-secondary text-sm px-4 py-2'
              onClick={(e) => onDisplayChange(e, 'dropSpot')}
            >
              Show drop spots
            </button>
            <button
              type='button'
              className='btn btn-secondary text-sm px-4 py-2'
              onClick={(e) => onDisplayChange(e, 'label')}
            >
              Show labels
            </button>
          </div>
        </div>

        <div className='flex-none mt-8 w-1/3'>
          <GridPreview block={block} display={preview} gridMax='30px' />
        </div>
      </div>
      <div className='text-white w-4/5'>
        <header className='w-full flex'>
          <div className='w-50'>Puzzle Board Pieces</div>
          <div className='ml-auto'></div>
          <div className='w-25 mx-2 text-center'>Piece</div>
          <div className='w-25 mx-2 text-center'>Drop Spot</div>
        </header>
        <section className='w-full font-normal my-4'>
          {fields.map((field, i) => {
            const label = PuzzleUtils.GridPositionLabelFromIndex(
              i,
              currentGridSize.numCols
            );

            return (
              <PiecePairEditor
                key={field.id}
                blockId={block.id}
                index={i}
                label={label}
                updatePieces={updatePieces}
                updateDropSpots={updateDropSpots}
              />
            );
          })}
        </section>
        <div className='text-sms text-icon-gray'>
          Leaving the Drop Spot image blank will render an outlined box.
        </div>
      </div>
    </>
  );
}

type PiecePair = {
  piece: PuzzlePiece;
  dropSpot: PuzzleDropSpot;
};

// Note(falcon): since the editor does not match the data model, we use a computed field, and prevent editing the
// pieces and dropSpots fields directly.
//
// However, there are a few edge cases to be aware of. In general, this editor design cannot handle:
//   1. More pieces than drop spots,
//   2. Drop spots with "missing" pieces.
type PuzzleBlockEditorFields = Omit<
  BlockFields<PuzzleBlock>,
  'pieces' | 'dropSpots'
> & { piecePairs: PiecePair[] };

function computePiecePairs(
  pieces: PuzzlePiece[],
  dropSpots: PuzzleDropSpot[]
): PiecePair[] {
  const piecesByValue = keyBy(ValtioUtils.detachCopy(pieces), (p) => p.value);
  return ValtioUtils.detachCopy(dropSpots).map((dropSpot) => ({
    dropSpot,
    piece: piecesByValue[dropSpot.acceptedValue],
  }));
}

function newPiecePair(): PiecePair {
  const id = uuidv4();
  return {
    piece: {
      id,
      value: id,
      mediaId: null,
      media: null,
    },
    dropSpot: {
      acceptedValue: id,
      mediaId: null,
      media: null,
    },
  };
}

function isJobInProgress(job: Nullable<PuzzleSliceJob>) {
  return (
    job?.status === MediaTranscodeStatus.Pending ||
    job?.status === MediaTranscodeStatus.Processing
  );
}

function ModeField(props: EditorProps<PuzzleBlock>): JSX.Element {
  const { block } = props;
  const { updateField } = useEditor(props);
  const store = useBlockEditorStore();
  const confirmCancel = useAwaitFullScreenConfirmCancelModal();

  const modeOptions = useInstance(() => [
    {
      label: 'Puzzle',
      value: PuzzleMode.Puzzle,
    },
    {
      label: 'Drag and Drop',
      value: PuzzleMode.DragAndDrop,
    },
  ]);

  const onModeChange = async (value: string | number | null) => {
    const mode = modeOptions.find((o) => o.value === value)?.value;
    if (
      mode === PuzzleMode.DragAndDrop &&
      isJobInProgress(block.fields.sliceJob)
    ) {
      const response = await confirmCancel({
        kind: 'confirm-cancel',
        prompt: (
          <div className='text-white flex-col items-center justify-center py-2 px-4'>
            <ConfirmCancelModalHeading>
              Stop slicing the puzzle?
            </ConfirmCancelModalHeading>
            <div className='text-sms text-center my-2'>
              Changing the mode will stop slicing the puzzle. Are you sure you
              want to continue?
            </div>
          </div>
        ),
        confirmBtnLabel: 'Continue',
        cancelBtnLabel: 'Cancel',
        autoFocus: 'cancel',
      });
      if (response.result === 'canceled') return;
      // Note 1: we don't really "cancel" the actual job in the backend, as long
      // as the _sliceJob_ is gone, the job can not backfill the data anymore.
      // Note 2: _updateField_ doesn't accept nullish value
      await apiService.block.deletePuzzleSliceJob(block.id);
      store.setBlockField({
        blockId: block.id,
        blockField: { sliceJob: null },
      });
    }
    updateField('mode', mode);
  };

  return (
    <RHFSelectField<PuzzleBlock>
      className='flex-grow h-10 my-2 text-white'
      label='Mode'
      name='mode'
      options={modeOptions}
      onChange={(_, value) => onModeChange(value)}
      value={block.fields.mode ?? PuzzleMode.DragAndDrop}
    />
  );
}

function SliceJobManagement(props: EditorProps<PuzzleBlock>): JSX.Element {
  const { block } = props;
  const store = useBlockEditorStore();
  const { updateField } = useEditor(props);
  const sliceJob = block.fields.sliceJob;
  const source = useRef({
    media: sliceJob?.sourceMedia,
    mediaData: sliceJob?.sourceMediaData,
  });
  const [aspectRatioSafe, setAspectRatioSafe] = useState(false);

  const onToggleAspectRatioSafe = async (val: boolean) => {
    setAspectRatioSafe(val);
  };

  const ctrl = useInstance(() => new BrowserIntervalCtrl());
  const refreshBlock = useLiveCallback(async () => {
    const resp = await apiService.block.getBlock(block.id);
    const b = fromDTOBlock(resp.data.block) as PuzzleBlock;
    const job = b.fields.sliceJob;
    if (job?.status === MediaTranscodeStatus.Ready) {
      store.setBlockField({
        blockId: block.id,
        blockField: {
          pieces: b.fields.pieces,
          dropSpots: b.fields.dropSpots,
        },
      });
      ctrl.clear();
    } else if (job?.status === MediaTranscodeStatus.Failed) {
      ctrl.clear();
    }
    store.setBlockField({
      blockId: block.id,
      blockField: {
        sliceJob: job,
      },
    });
  });

  const jobInProgress = isJobInProgress(sliceJob);

  useEffect(() => {
    if (!jobInProgress) return;
    ctrl.set(refreshBlock, 1000);
    return () => ctrl.clear();
  }, [ctrl, jobInProgress, refreshBlock]);

  const cleanup = async () => {
    await apiService.block.deletePuzzleSliceJob(block.id);
    store.setBlockField({
      blockId: block.id,
      blockField: { sliceJob: null },
    });
    await updateField('showPreview', false);
    // TODO: remove pieces and dropSpots
  };

  const updateSliceJob = async () => {
    const b = store.getBlock<PuzzleBlock>(block.id);
    const mediaData = toMediaDataDTO(source.current.mediaData);
    const gridSize = b?.fields.gridSize;

    if (!mediaData || !gridSize) {
      await cleanup();
      return;
    }

    const resp = await apiService.block.updatePuzzleSliceJob(block.id, {
      mediaData,
      gridSize,
    });
    store.setBlockField({
      blockId: block.id,
      blockField: {
        sliceJob: (resp.data.block as PuzzleBlock).fields.sliceJob,
      },
    });
  };

  const usedGridOptions = aspectRatioSafe ? squareGridOptions : gridOptions;
  const usedGridSizes = aspectRatioSafe ? squareGridSize : gridSizes;

  const currentGridSizeIndex = Math.max(
    0,
    usedGridSizes.findIndex(
      ({ numRows, numCols }) =>
        numRows === block.fields.gridSize.numRows &&
        numCols === block.fields.gridSize.numCols
    )
  );

  const checkGridSize = useLiveCallback(async () => {
    const blockGridSize = block.fields.gridSize;
    const currGridSize = usedGridSizes[currentGridSizeIndex];
    if (
      blockGridSize.numRows !== currGridSize.numRows ||
      blockGridSize.numCols !== currGridSize.numCols
    ) {
      await updateField('gridSize', currGridSize);
      updateSliceJob();
    }
  });

  useEffect(() => {
    checkGridSize();
  }, [aspectRatioSafe, checkGridSize]);

  return (
    <div className='flex flex-col gap-4'>
      <div className='w-1/2'>
        <div className='flex items-center gap-4'>
          <span className='text-white font-bold'>Grid Options</span>
          <label className='flex items-center gap-1 cursor-pointer'>
            <span className='text-white text-xs font-normal'>
              Force 16x9 Aspect Ratio
            </span>
            <input
              type='checkbox'
              className='checkbox-dark'
              checked={aspectRatioSafe}
              onChange={(e) => onToggleAspectRatioSafe(e.target.checked)}
            />
          </label>
        </div>
        <RHFSelectField<PuzzleBlock>
          className='h-10 my-2 text-white'
          name='gridSize'
          options={usedGridOptions}
          onChange={async (_, value) => {
            if (typeof value !== 'number') return;
            await updateField('gridSize', usedGridSizes[value]);
            updateSliceJob();
          }}
          value={currentGridSizeIndex}
        />
      </div>
      <div className='flex justify-center'>
        <div className='w-1/2'>
          <MediaEditor
            video={false}
            scene={EnumsMediaScene.MediaSceneBlockPuzzleSource}
            title='Upload Your Image'
            media={sliceJob?.sourceMedia}
            mediaData={sliceJob?.sourceMediaData}
            onChange={(mediaData, media) => {
              source.current = { media, mediaData };
              updateSliceJob();
            }}
            extraNotice='Upload an image and we’ll slice it into puzzle pieces'
            overrideSizeLimit={{
              image: 20 * 1024 * 1024,
            }}
            aspectRatio={aspectRatioSafe ? [16, 9] : undefined}
          />
        </div>
        <div className='w-1/2 flex flex-col'>
          <div className='text-white capitalize font-bold mb-1'>Preview</div>
          {match(sliceJob?.status)
            .when(
              (s) =>
                s === MediaTranscodeStatus.Pending ||
                s === MediaTranscodeStatus.Processing,
              () => (
                <div className='text-white flex-1'>
                  <Loading
                    containerClassName='w-full h-full'
                    text='Creating Puzzle...'
                  />
                </div>
              )
            )
            .when(
              (s) => s === MediaTranscodeStatus.Failed,
              () => (
                <div className='text-red-002 text-sms'>
                  Fail to create puzzle, Please try again.
                </div>
              )
            )
            .when(
              (s) => s === MediaTranscodeStatus.Ready,
              () => <GridPreview block={block} display='piece' />
            )
            .otherwise(() => null)}
        </div>
      </div>
      <div className='w-1/2'>
        <RHFCheckbox<PuzzleBlock>
          label='Show Puzzle Preview'
          name='showPreview'
          value={block.fields.showPreview ?? false}
          onChange={(_, checked: boolean): void => {
            updateField('showPreview', checked);
          }}
          disabled={!block.fields.sliceJob?.sourceMediaData}
          description='Show the preview to the users for what the final puzzle looks like (puzzle box)'
        />
      </div>
    </div>
  );
}

export function PuzzleBlockEditor(
  props: EditorProps<PuzzleBlock>
): JSX.Element | null {
  const { block } = props;
  const form = useForm<PuzzleBlockEditorFields>({
    // note(falcon): the form gets really tripped up when you switch between puzzle blocks. i spent a long time
    // investigating, and concluded the issue was data not getting cleared out in memory somewhere. `shouldUnregister`
    // seems to be one way of ensuring data is cleared as the form switches. however, per
    // https://github.com/react-hook-form/react-hook-form/discussions/3715#discussioncomment-647450
    // we need a deep clone. empirically, this is in fact needed for `shouldUnregister` to work.
    //
    // i tried setting `key={block.id}` in various places to avoid this cloneDeep and shouldUnregister, but did not
    // have any success. i'll leave this here for now, but if it can be removed in the future, the better!
    defaultValues: cloneDeep({
      ...block.fields,
      piecePairs: computePiecePairs(
        block.fields.pieces,
        block.fields.dropSpots
      ),
    }),
    shouldUnregister: true,
  });

  const {
    register,
    getValues,
    reset,
    formState: { errors },
  } = form;

  const blockId = block.id;
  const prevBlockId = usePrevious(block.id);
  const { updateField } = useEditor(props);

  useEffect(() => {
    if (prevBlockId === blockId) return;
    reset({
      ...block.fields,
      piecePairs: computePiecePairs(
        block.fields.pieces,
        block.fields.dropSpots
      ),
    });
  }, [blockId, prevBlockId, block.fields, reset]);

  const selectOnChange = (
    name: keyof BlockFields<PuzzleBlock>,
    value: Nullable<Option['value'], false>
  ): void => {
    updateField(name, value);
  };

  return (
    <EditorLayout
      bottomAccessory={
        <AdditionalSettings>
          <AdditionalSharedSettingsEditor {...props} />
        </AdditionalSettings>
      }
    >
      <EditorBody>
        <p className='text-2xl text-white'>Puzzle Block</p>
        <FormProvider<PuzzleBlockEditorFields> {...form}>
          <form className='w-full my-7.5'>
            <div className='w-full flex flex-col'>
              <div className='w-full flex flex-row'>
                <div className='w-3/5 text-base font-bold flex flex-col gap-8'>
                  <div>
                    <span className='text-white'>Prompt Text</span>
                    <textarea
                      className={`h-13.5 mt-1 mb-0 py-2 resize-none ${
                        errors.text ? 'field-error' : 'field'
                      }`}
                      placeholder='Max 300 characters'
                      {...register('text', {
                        maxLength: 300,
                        onBlur: () => updateField('text', getValues('text')),
                      })}
                    />
                  </div>
                  <div className='w-1/2'>
                    <ModeField {...props} />
                  </div>
                  <div className='bg-white bg-opacity-5 rounded-xl p-4'>
                    <div
                      className={`${
                        block.fields.mode === PuzzleMode.Puzzle
                          ? 'block'
                          : 'hidden'
                      }`}
                    >
                      <SliceJobManagement {...props} />
                    </div>
                    <div
                      className={`${
                        block.fields.mode !== PuzzleMode.Puzzle
                          ? 'block'
                          : 'hidden'
                      }`}
                    >
                      <PieceManagement {...props} />
                    </div>
                  </div>
                </div>
                <div className='w-2/5 text-base font-bold flex flex-col items-center'>
                  <label htmlFor='intro-media' className='my-2'>
                    <BlockMediaEditor<PuzzleBlockMedia>
                      blockId={blockId}
                      title='Intro Media'
                      field='introMedia'
                      video
                      scene={EnumsMediaScene.MediaSceneBlockMedia}
                      volumeSelectable
                      mediaData={block.fields.introMediaData}
                      media={block.fields.introMedia}
                      extraNotice='Media will display to the audience when the question is presented.'
                    />
                  </label>
                  <label htmlFor='background-media' className='my-2'>
                    <BlockMediaEditor<PuzzleBlockMedia>
                      blockId={blockId}
                      title='Background Media'
                      field='backgroundMedia'
                      video
                      scene={EnumsMediaScene.MediaSceneBlockBackground}
                      volumeSelectable
                      loopSelectable
                      mediaData={block.fields.backgroundMediaData}
                      media={block.fields.backgroundMedia}
                      extraNotice='Media will display in the background during the Game Timer.'
                    />
                  </label>
                  <label htmlFor='goal-animation-media' className='my-2'>
                    <BlockMediaEditor<PuzzleBlockMedia>
                      blockId={blockId}
                      title='Completion Animation'
                      field='goalAnimationMedia'
                      image={false}
                      video={true}
                      scene={EnumsMediaScene.MediaSceneBlockMedia}
                      mediaData={block.fields.goalAnimationMediaData}
                      media={block.fields.goalAnimationMedia}
                      extraNotice='Media will play upon successfully matching all the pairs of the Block.'
                    />
                  </label>
                  <label htmlFor='outro-media' className='my-2'>
                    <BlockMediaEditor<PuzzleBlockMedia>
                      blockId={blockId}
                      title='Outro Media'
                      field='outroMedia'
                      video={true}
                      scene={EnumsMediaScene.MediaSceneBlockMedia}
                      volumeSelectable
                      mediaData={block.fields.outroMediaData}
                      media={block.fields.outroMedia}
                      extraNotice='Media will display to the audience when the answer is revealed.'
                    />
                  </label>
                  <hr className='w-full my-5 border border-secondary' />
                  <label htmlFor='gameTimeSec' className='w-5/6 my-2'>
                    <CreatableDurationSelect<PuzzleBlock, 'gameTimeSec'>
                      label='Game Time'
                      name='gameTimeSec'
                      description='For a custom duration, enter the desired time in seconds and press Enter.'
                      value={block.fields.gameTimeSec}
                      onChange={selectOnChange}
                      options={timeOptions}
                    />
                  </label>
                  <label htmlFor='points' className='w-5/6 my-2'>
                    <RHFSelectField<PuzzleBlock>
                      className='w-full h-10 text-white'
                      label='Points Per Correct Piece'
                      name='pointsPerCorrectPiece'
                      options={pointOptions}
                      onChange={selectOnChange}
                      value={block.fields.pointsPerCorrectPiece}
                    />
                  </label>
                  <label htmlFor='points' className='w-5/6 my-2'>
                    <RHFSelectField<PuzzleBlock>
                      className='w-full h-10 text-white'
                      label='Bonus Points for Finishing Puzzle'
                      name='completionBonusPoints'
                      options={bonusPointOptions}
                      onChange={selectOnChange}
                      value={block.fields.completionBonusPoints}
                    />
                  </label>
                  <label htmlFor='decreasingPointsTimer' className='w-5/6 my-2'>
                    <RHFCheckbox<PuzzleBlock>
                      label='Decreasing Points Timer'
                      name='decreasingPointsTimer'
                      value={block.fields.decreasingPointsTimer}
                      onChange={(_, checked: boolean): void => {
                        updateField('decreasingPointsTimer', checked);
                      }}
                      description={{
                        enabled:
                          'Enabled: Amount of points earned for a correct piece decreases as the Game Timer runs out.',
                        disabled:
                          'Disabled: Amount of points earned for a correct piece does not change as the Game Timer runs out.',
                      }}
                    />
                  </label>
                  {block.fields.decreasingPointsTimer && (
                    <label className='w-5/6 my-2'>
                      <RHFCheckbox<PuzzleBlock>
                        label='Start Descending Immediately'
                        name='startDescendingImmediately'
                        value={block.fields.startDescendingImmediately ?? false}
                        onChange={(_, checked: boolean): void => {
                          updateField('startDescendingImmediately', checked);
                        }}
                        description={{
                          enabled:
                            'Enabled: Points start descending immediately',
                          disabled:
                            'Disabled: Points start descending after 25% of time.',
                        }}
                      />
                    </label>
                  )}
                  <label htmlFor='gradeOnPlacement' className='w-5/6 my-2'>
                    <RHFCheckbox<PuzzleBlock>
                      label='Grade on Placement'
                      name='gradeOnPlacement'
                      value={block.fields.gradeOnPlacement}
                      onChange={(_, checked: boolean): void => {
                        updateField('gradeOnPlacement', checked);
                      }}
                      description='When enabled, pieces dropped in the correct location are shown to the user when they are dropped. When disabled, users have no feedback about correct pieces until all pieces are correctly placed or the Game Timer runs out.'
                    />
                  </label>
                  <label
                    htmlFor='showCompletionProgress'
                    className='w-5/6 my-2'
                  >
                    <RHFCheckbox<PuzzleBlock>
                      label='Show Completion Progress'
                      name='showCompletionProgress'
                      value={block.fields.showCompletionProgress ?? false}
                      onChange={(_, checked: boolean): void => {
                        updateField('showCompletionProgress', checked);
                      }}
                      description={{
                        enabled:
                          "Enabled: The team's progress is shown above the puzzle as a ratio of correct pieces to total pieces.",
                        disabled:
                          'Disabled: Teams have no inidication of progress.',
                      }}
                    />
                  </label>
                </div>
              </div>
              <div className='w-full h-60'></div>
            </div>
          </form>
        </FormProvider>
      </EditorBody>
    </EditorLayout>
  );
}
