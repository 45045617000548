import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { usePrevious } from 'react-use';

import { type PuzzleBlock, PuzzleBlockGameSessionStatus } from '@lp-lib/game';

import { ClientTypeUtils } from '../../../../types';
import { assertExhaustive, nullOrUndefined } from '../../../../utils/common';
import { useGainPointsAnimationGamePlayTrigger } from '../../../GainPointsAnimation/useGainPointsAnimationGamePlayTrigger';
import { FloatBoard, FloatLayout } from '../../../Layout';
import { LayoutAnchor } from '../../../LayoutAnchors/LayoutAnchors';
import { useSyncPersistentPointsRevealAnswer } from '../../../PersistentPoints/Provider';
import { useMyTeamId } from '../../../Player';
import { useSoundEffect } from '../../../SFX';
import { useIsTeamsOnTop } from '../../../TeamAPI/TeamV1';
import { useMyClientType } from '../../../Venue/VenuePlaygroundProvider';
import { GoAnimation } from '../../GameBlockCardAnimations';
import {
  useGameSessionLocalTimer,
  useGameSessionStatus,
  useIsLiveGamePlay,
} from '../../hooks';
import { countdownV2, resetTimer, setTimer } from '../../store';
import { GamePlayEndTransition } from '../Common/GamePlay/GamePlayEndTransition';
import {
  buildGamePlayMedia,
  GamePlayMediaPlayer,
  type GamePlayMediaPlayerLayout,
  useGamePlayMediaPlayable,
  useGamePlayMediaUISync,
} from '../Common/GamePlay/GamePlayMedia';
import { GamePlayMediaLayout } from '../Common/GamePlay/GamePlayMediaLayout';
import {
  useCountdownPlaySFX,
  useGamePlayUITransitionControl,
  useIsGamePlayReady,
} from '../Common/GamePlay/GamePlayUtilities';
import { Leaderboard } from '../Common/GamePlay/Leaderboard';
import { StageBgSingleFrame } from '../Common/GamePlay/StageBgSingleFrame';
import { useSubmissionStatusWaitEnder } from '../Common/GamePlay/SubmissionStatusProvider';
import {
  type GamePlayMedia,
  type GamePlayProps,
} from '../Common/GamePlay/types';
import { useRankedTeamScores, useStableBlock } from '../Common/hooks';
import { PuzzlePlayground } from './PuzzlePlayground';
import {
  useEmitGamePlayEndedState,
  useGameProgressSummary,
  usePuzzleGame,
  usePuzzlePieces,
} from './PuzzleProvider';
import { GameState } from './types';
import { PuzzleUtils } from './utils';

function Results(props: { visible: boolean }): JSX.Element | null {
  const teamScores = useRankedTeamScores('currentScore', null, 'always');
  const myTeamId = useMyTeamId();
  const summary = useGameProgressSummary();

  const { play } = useSoundEffect('showResults');

  useEffect(() => {
    if (props.visible) play();
  }, [play, props.visible]);

  if (!props.visible) return null;

  return (
    <>
      <FloatBoard
        containerZIndex='z-20'
        containerDisplay='flex'
        bgStyle='border-2 border-cyan rounded-xl bg-black bg-opacity-60'
        title='GAME RESULTS'
      >
        <div className='w-full h-full pt-10 pb-20 px-16 overflow-y-auto scrollbar flex flex-col'>
          {teamScores.map((s) => {
            const numCorrectPieces = summary[s.team.id]?.numCorrectPieces ?? 0;
            const numTotalPieces = summary[s.team.id]?.numTotalPieces ?? 0;
            const percentCorrect =
              numTotalPieces === 0
                ? 0
                : Math.round((numCorrectPieces / numTotalPieces) * 100);
            return (
              <div
                key={s.team.id}
                className={`w-full animate-fade-in-up min-h-12 mb-4 pl-6 rounded-3xl bg-black bg-opacity-95 flex flex-row justify-between items-center ${
                  s.team.id === myTeamId ? 'text-tertiary' : 'text-white'
                }`}
              >
                <p className={`text-lg font-bold font-cairo`}>{s.team.name}</p>
                <div className='flex flex-row justify-center items-center'>
                  <p className='text-3xs font-bold'>
                    {`${numCorrectPieces} / ${numTotalPieces} pieces (${percentCorrect}%)`}
                  </p>
                  <div className='w-11 h-11 mr-0.75 ml-3 rounded-full bg-warning text-black flex flex-col justify-center items-center'>
                    <div className='font-extrabold text-xl leading-5 font-cairo'>
                      {s.currentScore}
                    </div>
                    <p className='text-3xs text-black'>pts</p>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </FloatBoard>
      <FloatLayout className='flex items-center justify-center'>
        <LayoutAnchor
          id='gameplay-points-animation-top'
          className='w-full max-w-4xl'
        />
      </FloatLayout>
    </>
  );
}

type GamePlayLocalState = {
  gamePlayMedia: Nullable<GamePlayMedia, false>;
  mediaPlayerLayout: GamePlayMediaPlayerLayout;
  showResults: boolean;
};

interface GamePlayLocalControlAPI {
  presentIntro: () => void;
  initGame: (totalGameTime: number, recovery?: boolean) => void;
  startGame: () => void;
  endGameTimer: () => void;
  stopGame: () => void;
  presentOutro: () => void;
  revealResults: () => void;
  showScoreboard: () => void;
  reset: () => void;
}

function useGamePlayLocalControl(
  block: PuzzleBlock
): [GamePlayLocalState, GamePlayLocalControlAPI] {
  const [gamePlayMedia, setGamePlayMedia] =
    useState<Nullable<GamePlayMedia, false>>(null);
  const [mediaPlayerLayout, setMediaPlayerLayout] =
    useState<GamePlayMediaPlayerLayout>(undefined);
  const [showResults, setShowResults] = useState(false);
  const initedRef = useRef(false);
  const isHost = ClientTypeUtils.isHost(useMyClientType());
  const isLive = useIsLiveGamePlay();

  const updateGamePlayMedia = useCallback(
    (
      media: Nullable<GamePlayMedia, false>,
      layout: GamePlayMediaPlayerLayout
    ) => {
      setGamePlayMedia(media);
      setMediaPlayerLayout(layout);
    },
    []
  );

  const presentIntro = useCallback(() => {
    updateGamePlayMedia(
      buildGamePlayMedia(
        {
          media: block.fields.introMedia,
          mediaData: block.fields.introMediaData,
        },
        {
          stage: 'intro',
        }
      ),
      'anchored'
    );
  }, [block, updateGamePlayMedia]);

  const initGame = useCallback(
    (totalGameTime: number, recovery?: boolean) => {
      if (initedRef.current) return;
      setMediaPlayerLayout(undefined);
      const media = buildGamePlayMedia(
        {
          media: PuzzleUtils.GetBackgroundMedia(block),
          mediaData: block.fields.backgroundMediaData,
        },
        {
          stage: 'custom',
          isBackgroundMedia: true,
        }
      );
      if (media) updateGamePlayMedia(media, 'fullscreen');
      if (!recovery) setTimer('submission', totalGameTime);
      initedRef.current = true;
    },
    [block, updateGamePlayMedia]
  );

  const startGame = useCallback(async () => {
    await countdownV2({
      debug: 'PuzzleBlockGamePlay',
      startTimeWorker: isHost ? !isLive : true,
      flushCountingStatus: false,
    });
  }, [isHost, isLive]);

  const endGameTimer = useCallback(() => {
    resetTimer('submission');
  }, []);

  const stopGame = useCallback(() => {
    updateGamePlayMedia(null, undefined);
  }, [updateGamePlayMedia]);

  const presentOutro = useCallback(() => {
    updateGamePlayMedia(
      buildGamePlayMedia(
        {
          media: block.fields.outroMedia,
          mediaData: block.fields.outroMediaData,
        },
        {
          stage: 'outro',
        }
      ),
      'anchored'
    );
  }, [block, updateGamePlayMedia]);

  const revealResults = useCallback(() => {
    updateGamePlayMedia(null, undefined);
    setShowResults(true);
  }, [updateGamePlayMedia]);

  const showScoreboard = useCallback(() => {
    setShowResults(false);
  }, []);

  const reset = useCallback(() => {
    resetTimer('submission');
    updateGamePlayMedia(null, undefined);
    initedRef.current = false;
    setShowResults(false);
  }, [updateGamePlayMedia]);

  return [
    {
      gamePlayMedia,
      showResults,
      mediaPlayerLayout,
    },
    useMemo(
      () => ({
        presentIntro,
        initGame,
        startGame,
        endGameTimer,
        stopGame,
        presentOutro,
        revealResults,
        showScoreboard,
        reset,
      }),
      [
        presentIntro,
        initGame,
        startGame,
        endGameTimer,
        stopGame,
        presentOutro,
        revealResults,
        showScoreboard,
        reset,
      ]
    ),
  ];
}

function Timesup(): JSX.Element {
  const pieces = usePuzzlePieces();
  const [correct, total] = useMemo(() => {
    const correct = pieces.filter((p) => p.grade === 'correct').length;
    return [correct, pieces.length];
  }, [pieces]);

  return (
    <div className='w-full h-full z-40 inset-0 absolute flex flex-col items-center justify-center bg-black bg-opacity-40 text-shadow'>
      <p className='font-cairo text-red-002 font-black text-6xl'>Time’s up!</p>
      {correct > 0 && total > 0 && (
        <p className='text-white font-bold text-base mt-4'>
          {correct} / {total} pieces correct!
        </p>
      )}
    </div>
  );
}

function PlaygroundView(props: {
  block: PuzzleBlock;
  inGame: boolean;
  isHost: boolean;
}): JSX.Element | null {
  const { block, inGame, isHost } = props;
  const teamsOnTop = useIsTeamsOnTop();
  if (!inGame) return null;

  if (isHost) {
    return (
      <div className='w-full h-full flex items-center justify-center'>
        <div className='w-1/4 h-1/2'>
          <Leaderboard />
        </div>
      </div>
    );
  } else {
    return (
      <>
        <FloatLayout
          className={`flex flex-col items-center justify-center left-16 right-72 pt-10 pb-16 ${
            teamsOnTop ? 'bottom-[2vh]' : ''
          }`}
          useCustomPaddingX
        >
          <PuzzlePlayground block={block} />
        </FloatLayout>
        <GamePlayEndTransition timesup={() => <Timesup />} />
      </>
    );
  }
}

export function PuzzleBlockGamePlay(
  props: GamePlayProps<PuzzleBlock>
): JSX.Element | null {
  const block = useStableBlock(props.block);
  const gameSessionStatus =
    useGameSessionStatus<PuzzleBlockGameSessionStatus>();
  const prevGameSessionStatus = usePrevious(gameSessionStatus);
  const game = usePuzzleGame();
  const gameState = game?.state;
  const [uiState, uiControl] = useGamePlayUITransitionControl();
  const [state, control] = useGamePlayLocalControl(block);
  const isGamePlayReady = useIsGamePlayReady(block, gameSessionStatus);
  const inGame = !!gameState && gameState >= GameState.Inited;
  const isHost = ClientTypeUtils.isHost(useMyClientType());
  const time = useGameSessionLocalTimer();

  useCountdownPlaySFX(
    block.fields.gameTimeSec,
    time,
    !!gameState && gameState >= GameState.InProgress
  );
  useEmitGamePlayEndedState(block);
  useSyncPersistentPointsRevealAnswer(state.showResults);
  useGainPointsAnimationGamePlayTrigger();
  useSubmissionStatusWaitEnder();

  // some actions need to be triggered by the game state
  useEffect(() => {
    if (nullOrUndefined(gameState)) return;
    switch (gameState) {
      case GameState.Inited:
        control.initGame(block.fields.gameTimeSec);
        break;
      // cover the case if user joins in the middle of the game or refreshes the page
      case GameState.InProgress:
        control.initGame(block.fields.gameTimeSec, true);
        break;
      case GameState.None:
      case GameState.Ended:
        break;
      default:
        assertExhaustive(gameState);
        break;
    }
  }, [block.fields.gameTimeSec, control, gameState]);

  useEffect(() => {
    if (prevGameSessionStatus === gameSessionStatus) return;
    switch (gameSessionStatus) {
      case PuzzleBlockGameSessionStatus.LOADED:
        control.reset();
        break;
      case PuzzleBlockGameSessionStatus.INTRO:
        control.presentIntro();
        break;
      case PuzzleBlockGameSessionStatus.GAME_INIT:
        break;
      case PuzzleBlockGameSessionStatus.GAME_START:
        control.startGame();
        break;
      case PuzzleBlockGameSessionStatus.GAME_END:
        control.endGameTimer();
        break;
      case PuzzleBlockGameSessionStatus.OUTRO:
        control.stopGame();
        control.presentOutro();
        break;
      case PuzzleBlockGameSessionStatus.RESULTS:
        control.revealResults();
        break;
      case PuzzleBlockGameSessionStatus.SCOREBOARD:
        control.showScoreboard();
        break;
      case PuzzleBlockGameSessionStatus.END:
      case null:
      case undefined:
        break;
      default:
        assertExhaustive(gameSessionStatus);
        break;
    }
  }, [gameSessionStatus, prevGameSessionStatus, control]);

  const { onMediaEnded, onMediaReplaying } = useGamePlayMediaUISync({
    block,
    gameSessionStatus,
    media: state.gamePlayMedia,
    state: uiState,
    control: uiControl,
  });

  const mediaPlayable = useGamePlayMediaPlayable({
    block,
    gameSessionStatus,
    media: state.gamePlayMedia,
    state: uiState,
    custom: () => gameState === GameState.InProgress,
  });

  if (!isGamePlayReady) return null;

  const fullscreen = state.mediaPlayerLayout === 'fullscreen';

  return (
    <div className='fixed w-screen h-screen text-white'>
      {uiState.playGoAnimation && <GoAnimation />}
      {state.gamePlayMedia && state.mediaPlayerLayout && (
        <StageBgSingleFrame gamePlayMedia={state.gamePlayMedia} />
      )}
      <GamePlayMediaLayout fullscreen={fullscreen}>
        {state.gamePlayMedia && state.mediaPlayerLayout && (
          <GamePlayMediaPlayer
            gamePlayMedia={state.gamePlayMedia}
            play={mediaPlayable}
            mode={fullscreen || !uiState.mediaEndEffect ? 'full' : 'small'}
            onMediaEnded={!fullscreen ? onMediaEnded : undefined}
            onMediaReplaying={!fullscreen ? onMediaReplaying : undefined}
            layout={state.mediaPlayerLayout}
          />
        )}
      </GamePlayMediaLayout>
      <PlaygroundView block={block} inGame={inGame} isHost={isHost} />
      <Results visible={state.showResults} />
    </div>
  );
}
