import { ArrowLeftIcon, ArrowRightIcon } from '../icons/Arrows';
import { Control } from '../StreamingTools';
import { SwitcherControlled } from '../Switcher';
import { useTownhallAPI, useTownhallConfig, useTownhallNext } from './Provider';

function ModeControl(): JSX.Element {
  const config = useTownhallConfig();
  const api = useTownhallAPI();
  const next = useTownhallNext();

  const current = next?.mode || config.mode;
  const handleChange = async (v: boolean) => {
    api.setNext({
      mode: v ? 'crowd' : 'team',
      countdownSec: 0,
      type: 'global',
      source: 'host-tools',
      policy: 'mandatory',
    });
  };

  return (
    <Control
      text='Toggle Townhall Mode'
      info='Switch between team(off) and crowd(on)'
      margin='m-0'
    >
      <SwitcherControlled
        name='townhall-mode'
        checked={current === 'crowd'}
        disabled={!!next || !config.enabled}
        onChange={handleChange}
      />
    </Control>
  );
}

export function TownhallHostTools(props: {
  handleClose: () => void;
}): JSX.Element | null {
  const config = useTownhallConfig();
  const api = useTownhallAPI();

  return (
    <div className='flex flex-col w-full h-full relative'>
      <header
        onClick={() => props.handleClose()}
        className='flex items-center p-4 text-white text-xs cursor-pointer'
      >
        <ArrowLeftIcon className='' />
        <p className='ml-2'>Townhall</p>
      </header>
      <Control
        text='Enable/Disable Townhall'
        info='Enable/Disable Townhall'
        margin='m-0'
      >
        <SwitcherControlled
          name='townhall-toggle'
          checked={config.enabled}
          onChange={(v) => api.setEnabled(v)}
        />
      </Control>
      <ModeControl />
      <div className='absolute bottom-2 w-full flex px-2'>
        <button
          className='w-full h-10 btn-primary text-sms font-medium'
          onClick={() => api.reset()}
        >
          Reset
        </button>
      </div>
    </div>
  );
}

export function TownhallControl(props: {
  handleOpen: () => void;
}): JSX.Element {
  return (
    <div
      id='townhall-host-control-entry'
      className={`cursor-pointer`}
      onClick={() => props.handleOpen()}
    >
      <Control
        text='Townhall'
        info={`Configure Townhall`}
        icon={<div>🏢</div>}
        hover={true}
      >
        <div className={`text-white`}>
          <ArrowRightIcon />
        </div>
      </Control>
    </div>
  );
}
