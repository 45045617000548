import { type CSSProperties, type ReactNode } from 'react';

import { type SequenceConfig } from '../../../../types/block';
import { assertExhaustive } from '../../../../utils/common';
import {
  TeamRelayRegularIndicatorArrow,
  TeamRelayWrongIndicatorArrow,
} from '../../../icons/TeamRelay';
import { useTeamRelayGameSettings } from './Context';
import { type Progress, RelayNodeState } from './types';
import { isSequenceFinished } from './utils';

type IndicatorKind = 'regular' | 'wrong';

type IndicatorConfig = {
  arrow: ReactNode;
  bodyClassName: string;
};

const INDICATOR_CONFIG: { [key in IndicatorKind]: IndicatorConfig } = {
  regular: {
    arrow: <TeamRelayRegularIndicatorArrow />,
    bodyClassName: 'team-relay-regular-indicator',
  },
  wrong: {
    arrow: <TeamRelayWrongIndicatorArrow />,
    bodyClassName: 'team-relay-wrong-indicator',
  },
};

function usePositionStyle(
  currentNodeIdx: number,
  direction: SequenceConfig['direction']
): CSSProperties | undefined {
  switch (direction) {
    case 'forward':
      // The first column is user's profile
      return { left: `${(currentNodeIdx + 1) * 40 - 2}px` };
    case 'backward':
      return { right: `${currentNodeIdx * 40}px` };
    default:
      assertExhaustive(direction);
      break;
  }
}

function Indicator(props: {
  kind: IndicatorKind;
  currentNodeIdx: number;
  direction: SequenceConfig['direction'];
}): JSX.Element {
  const config = INDICATOR_CONFIG[props.kind];
  const position = usePositionStyle(props.currentNodeIdx, props.direction);
  return (
    <div className='h-full absolute' style={position}>
      <div className='w-10 h-full relative flex flex-col items-center'>
        <div className='absolute -top-1.5'>{config.arrow}</div>
        <div
          className={`w-4.5 ${config.bodyClassName} absolute z-0 h-full`}
        ></div>
        <div className='absolute -bottom-1.5 transform rotate-180'>
          {config.arrow}
        </div>
      </div>
    </div>
  );
}

export function NodeIndicator(props: {
  progress: Nullable<Progress>;
  direction: SequenceConfig['direction'];
}): JSX.Element | null {
  const { progress } = props;
  const settings = useTeamRelayGameSettings();

  if (!progress || !settings) return null;
  if (isSequenceFinished(progress, settings)) return null;

  switch (progress.lastNodeState) {
    case RelayNodeState.NotPlayed:
    case RelayNodeState.Hit:
      return (
        <Indicator
          kind='regular'
          currentNodeIdx={progress.currentNodeIdx}
          direction={props.direction}
        />
      );
    case RelayNodeState.Miss:
      return (
        <Indicator
          kind='wrong'
          currentNodeIdx={progress.currentNodeIdx}
          direction={props.direction}
        />
      );
    default:
      assertExhaustive(progress.lastNodeState);
      return null;
  }
}
