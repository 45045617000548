import 'firebase/database';

import { type Media } from '@lp-lib/media';

import config from '../../config';

export const MUSIC_PLAYER_DEFAULT_LIST_ID = config.misc.musicPlaylistId;

export const MUSIC_PLAYER_DEFAULT_VOLUME = 20;

export type Song = {
  id: string;
  title: string;
  mediaId: string | null;
  media: Media | null;
};
