import { useRef } from 'react';
import {
  DndProvider,
  type DropTargetMonitor,
  useDrag,
  useDrop,
} from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import { canMove } from '../utils/dnd';
import { DeleteIcon } from './icons/DeleteIcon';
import { MenuIcon } from './icons/MenuIcon';

export interface DraggableItem {
  id: string;
}

export type DraggableItemRender<T> = (item: T) => JSX.Element;
export type MoveHandler = (from: number, to: number) => void;
export type DeleteHandler<T> = (item: T) => void;

type DragObject = DraggableItem & {
  index: number;
};

function DraggableRow<T extends DraggableItem>(props: {
  item: T;
  index: number;
  sourceType: string;
  acceptableType: string | string[];
  render: DraggableItemRender<T>;
  onMove: MoveHandler;
  onDelete: DeleteHandler<T>;
}): JSX.Element {
  const { item, index, sourceType, acceptableType, render, onMove, onDelete } =
    props;
  const ref = useRef<HTMLDivElement>(null);

  const [, drop] = useDrop({
    accept: acceptableType,
    hover(obj: DragObject, monitor: DropTargetMonitor) {
      if (!ref.current) return;
      const dragIndex = obj.index;
      const hoverIndex = index;
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      if (canMove(dragIndex, hoverIndex, hoverBoundingRect, monitor)) {
        onMove(dragIndex, hoverIndex);
        obj.index = hoverIndex;
      }
    },
  });
  const [collected, drag, dragPreview] = useDrag({
    type: sourceType,
    item: () => {
      return { id: item.id, index };
    },
    collect: (monitor) => ({
      opacity: monitor.isDragging() ? 'opacity-40' : '',
    }),
  });

  dragPreview(drop(ref));

  const handleDelete = () => {
    onDelete(item);
  };

  return (
    <div
      className={`w-full flex items-center justify-between gap-4 ${collected.opacity}`}
      ref={ref}
    >
      <button ref={drag} className='btn cursor-move'>
        <MenuIcon />
      </button>

      <div className='flex-1 truncate'>{render(item)}</div>

      <button
        className='btn w-7.5 h-7.5 border border-secondary rounded-lg flex items-center justify-center text-red-002'
        onClick={handleDelete}
      >
        <DeleteIcon />
      </button>
    </div>
  );
}

// deprecated! use DragDropList instead.
export function DraggableList<T extends DraggableItem>(props: {
  items: T[];
  sourceType: string;
  acceptableType: string | string[];
  render: (item: T) => JSX.Element;
  onMove: (from: number, to: number) => void;
  onDelete: (item: T) => void;
}): JSX.Element {
  const { items, sourceType, acceptableType, render, onMove, onDelete } = props;

  return (
    <div className='w-full flex flex-col gap-3'>
      <DndProvider backend={HTML5Backend}>
        {items.map((item, i) => (
          <DraggableRow
            key={item.id}
            item={item}
            index={i}
            sourceType={sourceType}
            acceptableType={acceptableType}
            render={render}
            onMove={onMove}
            onDelete={onDelete}
          />
        ))}
      </DndProvider>
    </div>
  );
}
