import { flattenObject } from './flatten-object';

export async function hashObject(req: object) {
  const flattened = flattenObject(req);
  const sorted = Object.entries(flattened).sort((a, b) =>
    a[0].localeCompare(b[0])
  );
  const stringified = sorted.reduce((acc, [key, value]) => {
    return acc + key + value;
  }, '');
  const hashBuffer = await crypto.subtle.digest(
    'SHA-256',
    new TextEncoder().encode(stringified)
  );
  const hashArray = Array.from(new Uint8Array(hashBuffer)); // convert to byte array
  const hashHex = hashArray
    .map((b) => b.toString(16).padStart(2, '0'))
    .join(''); // convert bytes to hex string
  return hashHex;
}
