import { useMemo } from 'react';
import Select, {
  components,
  type OptionProps,
  type SingleValue,
  type SingleValueProps,
} from 'react-select';

import type { DtoPersonality } from '@lp-lib/api-service-client/public';
import { MediaFormatVersion } from '@lp-lib/media';

import { fromMediaDTO } from '../../utils/api-dto';
import { MediaUtils } from '../../utils/media';
import { buildReactSelectStyles } from '../../utils/react-select';
import { usePersonalities } from './usePersonalities';

export function PersonalitySelect(props: {
  value?: string | null;
  onChange: (value: DtoPersonality) => void;
}): JSX.Element {
  const { data, isLoading } = usePersonalities();

  const styles = useMemo(() => buildReactSelectStyles<DtoPersonality>(), []);

  const selectedValue = useMemo(() => {
    if (!props.value) return null;
    return data?.find((item) => item.id === props.value) ?? null;
  }, [data, props.value]);

  const handleSingleChange = (option: SingleValue<DtoPersonality>) => {
    if (!option) return;
    props.onChange(option);
  };

  return (
    <Select<DtoPersonality, false>
      styles={styles}
      options={data}
      value={selectedValue}
      classNamePrefix='select-box-v2'
      className='w-full text-white'
      getOptionLabel={(option) => option.displayLabel}
      getOptionValue={(option) => option.id}
      onChange={handleSingleChange}
      isLoading={isLoading}
      isSearchable
      components={sharedPersonalitySelectComponents}
    />
  );
}

export const sharedPersonalitySelectComponents = {
  SingleValue: SingleValueComp,
  Option: OptionComp,
};

function SingleValueComp({
  children,
  ...props
}: SingleValueProps<DtoPersonality>) {
  return (
    <components.SingleValue {...props}>
      <div className='flex min-w-0 items-center gap-1.5'>
        <ProfileImage personality={props.data} />
        <div className='truncate'>{children}</div>
      </div>
    </components.SingleValue>
  );
}

function OptionComp({ children, ...props }: OptionProps<DtoPersonality>) {
  return (
    <components.Option {...props}>
      <div className='flex items-center gap-1.5'>
        <ProfileImage personality={props.data} />
        <div className='truncate'>{children}</div>
      </div>
    </components.Option>
  );
}

function ProfileImage(props: { personality: DtoPersonality }) {
  const profileImageUrl = useMemo(() => {
    return MediaUtils.PickMediaUrl(
      fromMediaDTO(props.personality.profileImage?.media),
      {
        priority: [MediaFormatVersion.SM],
      }
    );
  }, [props.personality.profileImage?.media]);

  return (
    <div className='flex-none w-5 h-5 rounded-full bg-gray-500 overflow-hidden'>
      {profileImageUrl && (
        <img
          src={profileImageUrl}
          alt={props.personality.displayLabel}
          className='w-full h-full object-cover'
        />
      )}
    </div>
  );
}
