import 'emoji-mart/css/emoji-mart.css';

import { type BaseEmoji, Picker } from 'emoji-mart';
import isEqual from 'lodash/isEqual';
import { useMemo, useRef, useState } from 'react';

import {
  EnumsH2HCardSource,
  EnumsH2HJudgingMode,
  EnumsH2HJudgingSentiment,
  EnumsH2HJudgingUserGroup,
  EnumsH2HMode,
  EnumsH2HSelectPlayerStrategy,
  EnumsH2HSubTimerType,
  EnumsMediaScene,
  EnumsMediaType,
  type ModelsTTSScript,
} from '@lp-lib/api-service-client/public';
import {
  type BlockFields,
  type HeadToHeadBlock,
  type HeadToHeadCard,
  type HeadToHeadCardPrompt,
} from '@lp-lib/game';

import { useLiveCallback } from '../../../../hooks/useLiveCallback';
import { useOutsideClick } from '../../../../hooks/useOutsideClick';
import {
  fromMediaDataDTO,
  fromMediaDTO,
  toMediaDataDTO,
  toMediaDTO,
} from '../../../../utils/api-dto';
import { uuidv4 } from '../../../../utils/common';
import { ValtioUtils } from '../../../../utils/valtio';
import { DeleteIcon } from '../../../icons/DeleteIcon';
import { MediaEditor } from '../../../MediaUploader/MediaEditor';
import { MiniMediaEditor } from '../../../MediaUploader/MiniMediaEditor';
import { TTSOptionEditor } from '../../../VoiceOver/TTSOptionEditor';
import {
  type ScriptScenarioOption,
  TTSScriptsEditor,
} from '../../../VoiceOver/TTSScriptsEditor';
import { CompactVoiceOverEditor } from '../../../VoiceOver/VoiceOverEditor';
import { makeExampleVariableRegistrySnapshot } from '../../hooks/useCreateGameInfoSnapshot';
import {
  AdditionalSettings,
  AdditionalSharedSettingsEditor,
  CreatableDurationSelect,
  type EditorProps,
  formatDurationOption,
  type Option,
  Pagination,
  RHFCheckbox,
  RHFSelectField,
  useEditor,
} from '../Common/Editor/EditorUtilities';
import {
  FieldContainer,
  FieldDescription,
  FieldPanel,
  FieldSectionHeader,
  FieldTitle,
  SimpleFieldEditor,
} from '../Common/Editor/FieldEditorUtilities';
import { PointsInput } from '../Common/Editor/PointsUtilities';
import { H2H_VOICE_OVER_VARIABLES } from './types';
import { makeExampleHeadToHeadVariables } from './utils';

const h2hOverrideVariables = H2H_VOICE_OVER_VARIABLES.map((s) => `%${s}%`);

const gameTimeOptions: Option[] = [0, 300, 600, 900].map((secs) => {
  if (secs === 0) return { label: 'No Timer', value: 0 };
  return { label: formatDurationOption(secs), value: secs };
});

const subTimeOptions: Option[] = [0, 15, 30, 60, 90, 120].map((secs) => {
  if (secs === 0) return { label: 'No Timer', value: 0 };
  return { label: formatDurationOption(secs), value: secs };
});

const gameModeOptions: Option[] = [
  { label: 'Turns', value: EnumsH2HMode.H2HModeTurns },
  {
    label: 'Debate',
    value: EnumsH2HMode.H2HModeDebate,
  },
];

const selectPlayerStrategyOptions: (Option & { description: string })[] = [
  {
    label: 'Random Player',
    value: EnumsH2HSelectPlayerStrategy.H2HSelectPlayerStrategyRandomPlayer,
    description:
      'Randomly select a single player to play in each head-to-head slot.',
  },
  {
    label: 'Random Team',
    value: EnumsH2HSelectPlayerStrategy.H2HSelectPlayerStrategyRandomTeam,
    description:
      'Randomly select a team of players to play in each head-to-head slot. This option may pull multiple players from the same team.',
  },
];

const judgingUserGroupOptions: (Option & { description: string })[] = [
  {
    label: 'Audience',
    value: EnumsH2HJudgingUserGroup.H2HJudgingUserGroupAudience,
    description: 'Anyone in the audience may judge.',
  },
  {
    label: 'Organizer',
    value: EnumsH2HJudgingUserGroup.H2HJudgingUserGroupGameCoordinator,
    description: 'Only the game coordinator may judge.',
  },
];

const judgingModeOptions: (Option & { description: string })[] = [
  {
    label: 'Disabled',
    value: EnumsH2HJudgingMode.H2HJudgingModeDisabled,
    description: 'There is no judging in this game.',
  },
  {
    label: 'During Game Play',
    value: EnumsH2HJudgingMode.H2HJudgingModeDuringGamePlay,
    description: 'Judging occurs during game play.',
  },
  {
    label: 'End of Game Play',
    value: EnumsH2HJudgingMode.H2HJudgingModeEndOfGamePlay,
    description: 'Judging occurs at the end of the game.',
  },
];

const judgingSentimentOptions: Option[] = [
  {
    label: 'Good',
    value: EnumsH2HJudgingSentiment.H2HJudgingSentimentPositive,
  },
  {
    label: 'Bad',
    value: EnumsH2HJudgingSentiment.H2HJudgingSentimentNegative,
  },
];

type NamedPrompt = {
  name: keyof Omit<HeadToHeadCard, 'id'>;
  label: string;
  prompt: HeadToHeadCardPrompt | null;
};

function makePrompt(source?: EnumsH2HCardSource) {
  return {
    text: '',
    source: source ?? EnumsH2HCardSource.H2HCardSourceDefaultCard,
    cover: null,
    voiceOver: null,
  };
}

function CardPromptEditor(props: {
  cardId: string;
  name: NamedPrompt['name'];
  label: string;
  prompt: HeadToHeadCardPrompt | null;
  video: boolean;
  onUpdate: (
    name: NamedPrompt['name'],
    updates: Partial<HeadToHeadCardPrompt>
  ) => void;
}) {
  const { cardId, name, label, prompt, video } = props;

  const handleUpdate = (updates: Partial<HeadToHeadCardPrompt>) => {
    props.onUpdate(name, updates);
  };
  return (
    <div className='w-full flex items-start gap-2'>
      <div className='flex-grow'>
        <label className='font-bold'>{label}</label>
        <input
          key={`card-${cardId}-${label}-text`}
          className='field h-13.5 m-0 w-full'
          placeholder='Max 50 characters'
          maxLength={50}
          defaultValue={prompt?.text}
          onBlur={(e) =>
            handleUpdate({
              text: e.target.value,
            })
          }
        />
      </div>
      <div>
        <label className='font-bold text-2xs ml-2'>Card image</label>
        <MiniMediaEditor
          video={video}
          id={`card-${cardId}-${label}-cover-${video}`}
          scene={EnumsMediaScene.MediaSceneBlockMedia}
          objectFit='cover'
          mediaData={fromMediaDataDTO(prompt?.cover?.data)}
          media={fromMediaDTO(prompt?.cover?.media)}
          onChange={(mediaData, media) => {
            handleUpdate({
              cover: {
                data: toMediaDataDTO(mediaData),
                media: media ? toMediaDTO(media) : null,
              },
            });
          }}
          volumeSelectable
        />
      </div>
      <div>
        <label className={`font-bold text-2xs ml-2 text-white`}>Voice</label>
        <div className='flex-shrink-0'>
          <CompactVoiceOverEditor
            id={`card-${cardId}-${label}-voice-over-${video}`}
            voiceOver={prompt?.voiceOver}
            onChange={(voiceOver) => {
              handleUpdate({
                voiceOver: {
                  ...prompt?.voiceOver,
                  ...voiceOver,
                },
              });
            }}
            runtime
            overrideVariables={h2hOverrideVariables}
          />
        </div>
      </div>
    </div>
  );
}

function CardEditor(props: {
  card: HeadToHeadCard;
  cardIndex: number;
  onUpdate: (card: HeadToHeadCard) => void;
  onDelete: (card: HeadToHeadCard) => void;
  turnMode: boolean;
}) {
  const { card, cardIndex, onUpdate, onDelete, turnMode } = props;
  const rolePrompts: NamedPrompt[] = [
    { name: 'audience', label: 'Audience', prompt: card.audience },
    {
      name: 'groupA',
      label: turnMode ? 'My turn' : 'Pink',
      prompt: card.groupA,
    },
    {
      name: 'groupB',
      label: turnMode ? 'Not my turn' : 'Blue',
      prompt: card.groupB,
    },
  ];
  const defaultPrompts: NamedPrompt[] = [
    { name: 'default', label: 'Prompt', prompt: card.default },
  ];
  const customized = rolePrompts.some(
    (e) => e.prompt?.source === EnumsH2HCardSource.H2HCardSourceMyCard
  );

  const prompts = customized ? rolePrompts : defaultPrompts;

  const toggleCustomization = (enabled: boolean) => {
    const c = ValtioUtils.detachCopy(card);
    const source = enabled
      ? EnumsH2HCardSource.H2HCardSourceMyCard
      : EnumsH2HCardSource.H2HCardSourceDefaultCard;
    c.audience = { ...makePrompt(), ...c.audience, source };
    c.groupA = { ...makePrompt(), ...c.groupA, source };
    c.groupB = { ...makePrompt(), ...c.groupB, source };
    onUpdate(c);
  };

  const handleUpdatePrompt = (
    name: NamedPrompt['name'],
    updates: Partial<HeadToHeadCardPrompt>
  ) => {
    const c = ValtioUtils.detachCopy(card);
    const prompt = c[name];
    if (!prompt) return;
    c[name] = { ...prompt, ...updates };
    onUpdate(c);
  };

  const videoPrompts = prompts.filter(
    (p) => p.prompt?.cover?.media?.type === EnumsMediaType.MediaTypeVideo
  );

  const valid = videoPrompts.length <= 1;

  const check = (e: NamedPrompt) => {
    if (!customized) return true;
    if (videoPrompts.length === 0) return true;
    if (videoPrompts.length === 1) {
      return videoPrompts[0].name === e.name;
    }
    // invalid state, but we need to allow the user to fix it
    return !!videoPrompts.find((p) => p.name === e.name);
  };

  return (
    <FieldContainer
      styles={{
        layout: 'relative flex flex-col gap-4 text-white',
      }}
    >
      <div className='text-xl text-white'>Prompt Card {cardIndex}</div>
      {prompts.map((e, i) => (
        <CardPromptEditor
          key={`${card.id}-${i}`}
          cardId={card.id}
          name={e.name}
          label={e.label}
          prompt={e.prompt}
          video={check(e)}
          onUpdate={handleUpdatePrompt}
        />
      ))}
      {customized && (
        <div
          className={`${
            valid
              ? 'text-3xs text-secondary font-medium'
              : 'text-red-002 text-xs font-bold'
          }`}
        >
          {!valid && <span>Please Fix: </span>}
          <span>
            In customize editor, we support up to one video, it can be assigned
            to one of the roles, the rest of the roles can only use images.
          </span>
        </div>
      )}
      <div className='flex items-center justify-between'>
        {customized ? (
          <div></div>
        ) : (
          <div className='flex items-center gap-2'>
            <div className='font-bold'>Who can see this?</div>
            <div className='flex items-center gap-2'>
              {rolePrompts.map((p) => (
                <label
                  key={p.name}
                  className='flex items-center gap-1 cursor-pointer'
                >
                  <input
                    className='checkbox-dark'
                    type='checkbox'
                    checked={
                      p.prompt?.source ===
                      EnumsH2HCardSource.H2HCardSourceDefaultCard
                    }
                    onChange={(e) =>
                      handleUpdatePrompt(p.name, {
                        source: e.target.checked
                          ? EnumsH2HCardSource.H2HCardSourceDefaultCard
                          : EnumsH2HCardSource.H2HCardSourceNoCard,
                      })
                    }
                  />
                  <span className='text-sms'>{p.label}</span>
                </label>
              ))}
            </div>
          </div>
        )}
        <button
          type='button'
          className={`${
            customized ? 'text-red-002' : 'text-icon-gray'
          } text-sms font-medium underline`}
          onClick={() => toggleCustomization(!customized)}
        >
          {customized
            ? 'Remove customize cards by role'
            : '+Customize cards by role'}
        </button>
      </div>
      <button
        type='button'
        className='absolute top-2.5 right-2.5 text-red-002'
        onClick={() => onDelete(card)}
      >
        <DeleteIcon />
      </button>
    </FieldContainer>
  );
}

function CardsManagement(props: {
  block: HeadToHeadBlock;
  cards: HeadToHeadCard[];
  onChange: (cards: HeadToHeadCard[]) => void;
}) {
  const { cards, onChange } = props;
  const turnMode = props.block.fields.gameMode === EnumsH2HMode.H2HModeTurns;

  const [page, setPage] = useState(0);

  const handleAdd = useLiveCallback(() => {
    const numCards = cards.length;
    onChange([
      ...cards,
      {
        id: uuidv4(),
        default: makePrompt(),
        audience: makePrompt(),
        groupA: makePrompt(),
        groupB: makePrompt(),
      },
    ]);
    setPage(numCards);
  });

  const handleUpdate = useLiveCallback((card: HeadToHeadCard) => {
    onChange(cards.map((c) => (c.id === card.id ? card : c)));
  });

  const handleDelete = useLiveCallback((card: HeadToHeadCard) => {
    const nextCards = cards.filter((c) => c.id !== card.id);
    onChange(nextCards);
    if (page >= nextCards.length) {
      setPage(nextCards.length - 1);
    }
  });

  const cardEditor = useMemo(() => {
    const card = cards[page];
    if (!card) return null;
    return (
      <CardEditor
        card={cards[page]}
        cardIndex={page + 1}
        onUpdate={handleUpdate}
        onDelete={handleDelete}
        turnMode={turnMode}
      />
    );
  }, [cards, handleDelete, handleUpdate, page, turnMode]);

  return (
    <div className='mb-8 w-full flex flex-col gap-4'>
      <div className='w-full flex items-center justify-between gap-4 text-white overflow-hidden'>
        <div className='flex-none font-bold text-sms'>
          Prompt Cards
          <br />
          <span className='text-xs text-icon-gray font-normal'>
            {cards.length} total
          </span>
        </div>
        <Pagination count={cards.length} page={page} onChange={setPage} />
        <button
          type='button'
          className='flex-none btn-secondary px-4 py-2 text-sms font-bold'
          onClick={handleAdd}
        >
          Add Card
        </button>
      </div>
      {cardEditor}
    </div>
  );
}

function EmojiPicker(props: {
  block: HeadToHeadBlock;
  onChange: (value: string) => void;
}) {
  const { block, onChange } = props;
  const [open, setOpen] = useState(false);
  const ref = useRef(null);

  const onEmojiSelect = (emoji: BaseEmoji) => {
    onChange(emoji.native);
    setOpen(false);
  };

  useOutsideClick(ref, () => setOpen(false));

  return (
    <div ref={ref} className='relative'>
      <button
        type='button'
        className='icon-btn w-13.5 h-13.5 border border-secondary'
        onClick={() => setOpen(!open)}
      >
        {block.fields.judgingInGameSendingEmoji}
      </button>
      {open && (
        <Picker
          theme='dark'
          title='Pick your emoji…'
          style={{ position: 'absolute', bottom: '60px', right: '0' }}
          emoji='point_up'
          native={true}
          onSelect={onEmojiSelect}
        />
      )}
    </div>
  );
}

const exampleVariableRegistry = makeExampleVariableRegistrySnapshot();
const h2hScriptScenarioOptions: ScriptScenarioOption[] = [
  {
    label: 'Introduction',
    description:
      'The voice that plays at the start of the game, before game play begins.',
    tags: ['intro'],
    supportedVariables: exampleVariableRegistry,
    templateRenderer: exampleVariableRegistry,
  },
  {
    label: 'Judging',
    description:
      'This is the voice that plays during the judging stage (after the game is over). Note: these are only used with the end-game judging mode.',
    tags: ['judging'],
    supportedVariables: makeExampleHeadToHeadVariables(),
  },
];

export function HeadToHeadBlockEditor(
  props: EditorProps<HeadToHeadBlock>
): JSX.Element | null {
  const { block } = props;
  const { updateFieldLocalFirst: updateField } = useEditor(props);

  const turnsMode = block.fields.gameMode === EnumsH2HMode.H2HModeTurns;
  const debateMode = block.fields.gameMode === EnumsH2HMode.H2HModeDebate;
  const judgingInGame =
    block.fields.judgingMode ===
    EnumsH2HJudgingMode.H2HJudgingModeDuringGamePlay;

  const currentPlayerStrategyModeOption = selectPlayerStrategyOptions.find(
    (option) => option.value === block.fields.selectPlayerStrategy
  );

  const currentJudgingModeOption = judgingModeOptions.find(
    (option) => option.value === block.fields.judgingMode
  );

  const currentJudgingUserGroupOption = judgingUserGroupOptions.find(
    (option) => option.value === block.fields.judgingUserGroup
  );

  const handleTTSOptionsChange = useLiveCallback(
    (value: BlockFields<HeadToHeadBlock>['ttsOptions']) => {
      updateField('ttsOptions', value);
    }
  );

  const handleAddTTSScript = useLiveCallback(async (value: ModelsTTSScript) => {
    await updateField('ttsScripts', [
      ...(props.block.fields.ttsScripts ?? []),
      value,
    ]);
  });

  const handleRemoveTTSScript = useLiveCallback(
    async (value: ModelsTTSScript) => {
      const nextTTSScripts = (props.block.fields.ttsScripts ?? []).filter(
        (s) => s.id !== value.id
      );
      await updateField('ttsScripts', nextTTSScripts);
    }
  );

  const handleChangeTTSScript = useLiveCallback(
    async (prev: ModelsTTSScript, next: ModelsTTSScript) => {
      const nextTTSScripts = [...(props.block.fields.ttsScripts ?? [])];
      const index = nextTTSScripts.findIndex((s) => s.id === prev.id);
      if (index === -1) return;

      const hasChanged = !isEqual(prev, next);
      if (hasChanged) {
        // only update the field if there's a change.
        nextTTSScripts[index] = next;
        await updateField('ttsScripts', nextTTSScripts);
      }
    }
  );

  const handleUploadTTSScripts = useLiveCallback(
    async (nextTTSScripts: ModelsTTSScript[]) => {
      await updateField('ttsScripts', nextTTSScripts);
    }
  );

  return (
    <div className='w-full'>
      <h2 className='text-2xl text-white'>Head to Head</h2>
      <div className='pt-5 space-y-3'>
        <CardsManagement
          block={block}
          cards={block.fields.cards}
          onChange={(cards) => {
            updateField('cards', cards);
          }}
        />
        <SimpleFieldEditor
          name='Game Name'
          description='The title you choose for your head-to-head game, displayed prominently throughout the experience.'
        >
          <input
            name='gameName'
            key={`block-${block.id}-game-name`}
            className='field h-13.5 m-0 w-full'
            placeholder='Max 300 characters'
            maxLength={300}
            defaultValue={block.fields.gameName}
            onBlur={(event) => {
              updateField('gameName', event.target.value);
            }}
          />
        </SimpleFieldEditor>
        <SimpleFieldEditor
          name='Game Prompt'
          description='A brief description of your game that appears to all participants, including the audience and on-stage players.'
        >
          <input
            name='gamePrompt'
            key={`block-${block.id}-game-prompt`}
            className='field h-13.5 m-0 w-full'
            placeholder='Max 300 characters'
            maxLength={300}
            defaultValue={block.fields.gamePrompt}
            onBlur={(event) => {
              updateField('gamePrompt', event.target.value);
            }}
          />
        </SimpleFieldEditor>
        <SimpleFieldEditor
          name='Game Mode'
          description='Choose between “Turn” mode, where players alternate with individual timers, or “Debate” mode, which uses a single timer for the entire discussion.'
        >
          <RHFSelectField<HeadToHeadBlock>
            className='w-full h-10 text-white'
            name='gameMode'
            options={gameModeOptions}
            onChange={(_, val) => {
              if (typeof val !== 'number') return;
              const mode = val as unknown as EnumsH2HMode;
              updateField('gameMode', mode);
              updateField(
                'subTimerType',
                mode === EnumsH2HMode.H2HModeTurns
                  ? EnumsH2HSubTimerType.H2HSubTimerTypePerTurn
                  : EnumsH2HSubTimerType.H2HSubTimerTypePerRound
              );
            }}
            value={block.fields.gameMode}
          />
        </SimpleFieldEditor>
        <SimpleFieldEditor
          name='Master Timer'
          description={
            <>
              An overall time limit for the entire game, useful for managing the
              duration of extended back-and-forth interactions. For a custom
              duration, enter the desired time in seconds and press Enter.
            </>
          }
        >
          <CreatableDurationSelect<HeadToHeadBlock, 'gameTimeSec'>
            name='gameTimeSec'
            value={block.fields.gameTimeSec}
            onChange={(name, value) => {
              updateField(name, Number(value));
            }}
            options={gameTimeOptions}
          />
        </SimpleFieldEditor>
        <SimpleFieldEditor
          name={
            block.fields.subTimerType ===
            EnumsH2HSubTimerType.H2HSubTimerTypePerTurn
              ? 'Timer Per Card'
              : 'Timer Per Debate Round'
          }
          description={
            <>
              The amount of time allocated to users for each{' '}
              {block.fields.subTimerType ===
              EnumsH2HSubTimerType.H2HSubTimerTypePerTurn
                ? 'card'
                : 'round'}{' '}
              in the game. For a custom duration, enter the desired time in
              seconds and press Enter.
            </>
          }
        >
          <CreatableDurationSelect<HeadToHeadBlock, 'subTimeSec'>
            name='subTimeSec'
            value={block.fields.subTimeSec}
            onChange={(name, value) => {
              updateField(name, Number(value));
            }}
            options={subTimeOptions}
          />
        </SimpleFieldEditor>
        <SimpleFieldEditor
          name='Who Plays The Game?'
          description={
            <>
              Defines who should play.{' '}
              {currentPlayerStrategyModeOption && (
                <div className='pt-2'>
                  <strong>{currentPlayerStrategyModeOption.label}:</strong>{' '}
                  {currentPlayerStrategyModeOption.description}
                </div>
              )}
            </>
          }
        >
          <RHFSelectField<HeadToHeadBlock>
            className='w-full h-10 text-white'
            name='selectPlayerStrategy'
            options={selectPlayerStrategyOptions}
            onChange={updateField}
            value={block.fields.selectPlayerStrategy}
          />
        </SimpleFieldEditor>
        <SimpleFieldEditor
          name='Background Media'
          description='Defines the media to use as the background when the block is presented.'
        >
          <MediaEditor
            width='w-full'
            video
            volumeSelectable
            loopSelectable
            scene={EnumsMediaScene.MediaSceneBlockBackground}
            mediaData={fromMediaDataDTO(block.fields.background?.data)}
            media={fromMediaDTO(block.fields.background?.media)}
            onChange={(mediaData, media) => {
              updateField('background', {
                data: toMediaDataDTO(mediaData),
                media: media ? toMediaDTO(media) : null,
              });
            }}
            extraNotice='Media will display and play in the background during the Game Timer.'
          />
        </SimpleFieldEditor>
        <SimpleFieldEditor
          name='Intro Media'
          description='Defines the media to use as the during the introduction. This will play along with the introductory voice over, if defined.'
        >
          <MediaEditor
            width='w-full'
            video
            volumeSelectable
            loopSelectable
            scene={EnumsMediaScene.MediaSceneBlockMedia}
            mediaData={fromMediaDataDTO(block.fields.introMedia?.data)}
            media={fromMediaDTO(block.fields.introMedia?.media)}
            onChange={(mediaData, media) => {
              updateField('introMedia', {
                data: toMediaDataDTO(mediaData),
                media: media ? toMediaDTO(media) : null,
              });
            }}
            extraNotice='Media will display and play in the introduction phase.'
          />
        </SimpleFieldEditor>

        <FieldSectionHeader>Game Flow</FieldSectionHeader>
        {debateMode && (
          <SimpleFieldEditor
            name='Allow Skip Media / Card?'
            description='When enabled, the on stage player can skip card in debate mode.'
          >
            <RHFCheckbox<HeadToHeadBlock>
              label=''
              name='cardSkippable'
              value={block.fields.cardSkippable}
              onChange={(_, checked: boolean): void => {
                updateField('cardSkippable', checked);
              }}
            />
          </SimpleFieldEditor>
        )}
        <SimpleFieldEditor
          name='Can this game be played again?'
          description='When enabled, organizer can start a new round.'
        >
          <RHFCheckbox<HeadToHeadBlock>
            label=''
            name='replayable'
            value={block.fields.replayable}
            onChange={(_, checked: boolean): void => {
              updateField('replayable', checked);
            }}
          />
        </SimpleFieldEditor>
        <SimpleFieldEditor
          name='Show the game results at the end?'
          description='When enabled, we show the result screen at the end of the game.'
        >
          <RHFCheckbox<HeadToHeadBlock>
            label=''
            name='showResults'
            value={block.fields.showResults}
            onChange={(_, checked: boolean): void => {
              updateField('showResults', checked);
            }}
          />
        </SimpleFieldEditor>

        <FieldSectionHeader>Judging</FieldSectionHeader>
        <SimpleFieldEditor
          name='Judging Mode'
          description={
            <>
              Defines when judging may occur during the game.{' '}
              {currentJudgingModeOption && (
                <div className='pt-2'>
                  <strong>{currentJudgingModeOption.label}:</strong>{' '}
                  {currentJudgingModeOption.description}
                </div>
              )}
            </>
          }
        >
          <RHFSelectField<HeadToHeadBlock>
            className='w-full h-10 text-white'
            name='judgingMode'
            options={judgingModeOptions}
            onChange={updateField}
            value={
              block.fields.judgingMode ??
              EnumsH2HJudgingMode.H2HJudgingModeEndOfGamePlay
            }
          />
        </SimpleFieldEditor>
        {block.fields.judgingMode !==
          EnumsH2HJudgingMode.H2HJudgingModeDisabled && (
          <>
            <SimpleFieldEditor
              name='Who are the judges?'
              description={
                <>
                  Defines who may judge.{' '}
                  {currentJudgingUserGroupOption && (
                    <div className='pt-2'>
                      <strong>{currentJudgingUserGroupOption.label}:</strong>{' '}
                      {currentJudgingUserGroupOption.description}
                    </div>
                  )}
                </>
              }
            >
              <RHFSelectField<HeadToHeadBlock>
                className='w-full h-10 text-white'
                name='judgingUserGroup'
                options={judgingUserGroupOptions}
                onChange={updateField}
                value={block.fields.judgingUserGroup}
              />
            </SimpleFieldEditor>
            <SimpleFieldEditor
              name='Judging Headline'
              description='Appears above the judging prompt instructions. Defaults to "Cast Your Vote", if left empty'
            >
              <input
                name='judgingHeadline'
                key={`block-${block.id}-judging-headline`}
                className='field h-13.5 m-0 w-full'
                placeholder='Max 100 characters'
                maxLength={100}
                defaultValue={block.fields.judgingHeadline}
                onBlur={(event) => {
                  updateField('judgingHeadline', event.target.value);
                }}
              />
            </SimpleFieldEditor>
            <SimpleFieldEditor
              name='Judging Prompt Instructions'
              description='Instructions for the judges to follow when judging.'
            >
              <input
                name='judgingPrompt'
                key={`block-${block.id}-judging-prompt`}
                className='field h-13.5 m-0 w-full'
                placeholder='Max 100 characters'
                maxLength={100}
                defaultValue={block.fields.judgingPrompt}
                onBlur={(event) => {
                  updateField('judgingPrompt', event.target.value);
                }}
              />
            </SimpleFieldEditor>
            {turnsMode && (
              <SimpleFieldEditor
                name={
                  judgingInGame
                    ? 'Play X turns'
                    : 'Start judging process after X turns'
                }
                description={
                  judgingInGame ? (
                    <>
                      With 'in-game' judging the game continues even if there is
                      a majority vote winner before X turns. Non-positive number
                      means play as many cards as they can.
                    </>
                  ) : (
                    <>
                      With 'end-game' judging this setting means we auto
                      progress to the end-game judging after X turns are played.
                      Non-positive number means play as many cards as they can.
                    </>
                  )
                }
              >
                <input
                  name='judgingAfterXTurns'
                  key={`block-${block.id}-judging-after-x-turns`}
                  className='field h-13.5 m-0 w-full'
                  placeholder='After X turns'
                  type='number'
                  defaultValue={block.fields.judgingAfterXTurns}
                  onBlur={(event) => {
                    updateField(
                      'judgingAfterXTurns',
                      Number(event.target.value)
                    );
                  }}
                />
              </SimpleFieldEditor>
            )}
            {debateMode && (
              <SimpleFieldEditor
                name='Start judging process when times up'
                description='When enabled, starts judging process when the game timer runs out.'
              >
                <RHFCheckbox<HeadToHeadBlock>
                  label=''
                  name='judgingWhenTimesUp'
                  value={block.fields.judgingWhenTimesUp}
                  onChange={(_, checked: boolean): void => {
                    updateField('judgingWhenTimesUp', checked);
                  }}
                />
              </SimpleFieldEditor>
            )}
            {judgingInGame && (
              <>
                <SimpleFieldEditor
                  name='Show In-Game Judging Tool After X Turns'
                  description={
                    <>
                      Used to control when to show the in-game judging widget.
                      Non-positive number means entire judging panel will be
                      disabled.
                    </>
                  }
                >
                  <input
                    name='judgingInGameAfterXTurns'
                    key={`block-${block.id}-judging-in-game-after-x-turns`}
                    className='field h-13.5 m-0 w-full'
                    placeholder='After X turns'
                    type='number'
                    defaultValue={block.fields.judgingInGameAfterXTurns}
                    onBlur={(event) => {
                      updateField(
                        'judgingInGameAfterXTurns',
                        Number(event.target.value)
                      );
                    }}
                  />
                </SimpleFieldEditor>
                <FieldContainer>
                  <FieldPanel>
                    <FieldTitle>Judging In-Game Button Options</FieldTitle>
                    <FieldDescription>
                      Defines the button label judges see when judging as well
                      as the emoji emitted when voting.
                    </FieldDescription>
                  </FieldPanel>
                  <div className='w-1/2 xl:w-1/3 flex items-center gap-2 text-white'>
                    <div className='flex-1'>
                      <div className='mb-1 text-sms font-bold'>Button Text</div>
                      <input
                        name='judgingInGameButtonText'
                        key={`block-${block.id}-judging-in-game-button-text`}
                        className='field h-13.5 m-0 w-full'
                        placeholder='Max 20 characters'
                        maxLength={20}
                        defaultValue={block.fields.judgingInGameButtonText}
                        onBlur={(event) => {
                          updateField(
                            'judgingInGameButtonText',
                            event.target.value
                          );
                        }}
                      />
                    </div>
                    <div className=''>
                      <div className='mb-1 text-sms font-bold'>
                        Sending Emoji
                      </div>
                      <EmojiPicker
                        block={block}
                        onChange={(v) => {
                          updateField('judgingInGameSendingEmoji', v);
                        }}
                      />
                    </div>
                  </div>
                </FieldContainer>
                <SimpleFieldEditor
                  name='Is the vote good for them or bad for them?'
                  description={
                    <>
                      Example good for them: “Vote for who you like”
                      <br />
                      Example bad for them: “Press 'Wrong' if the user gets the
                      answer wrong”.
                    </>
                  }
                >
                  <RHFSelectField<HeadToHeadBlock>
                    label=''
                    className='w-full h-10 text-white'
                    name='judgingInGameSentiment'
                    options={judgingSentimentOptions}
                    onChange={updateField}
                    value={block.fields.judgingInGameSentiment}
                  />
                </SimpleFieldEditor>
              </>
            )}
            <SimpleFieldEditor
              name='How many points should be distributed?'
              description='Total amount of points to distribute to the winner.'
            >
              <PointsInput
                defaultValue={block.fields.judgingPoints}
                placeholder={'points'}
                onChange={(value) => {
                  updateField('judgingPoints', value);
                }}
              />
            </SimpleFieldEditor>
          </>
        )}

        <FieldSectionHeader>Voice Overs</FieldSectionHeader>
        <TTSScriptsEditor
          title='Host Scripts'
          description={<></>}
          value={props.block.fields.ttsScripts}
          scenarioOptions={h2hScriptScenarioOptions}
          onAdd={handleAddTTSScript}
          onRemove={handleRemoveTTSScript}
          onChange={handleChangeTTSScript}
          onUpload={handleUploadTTSScripts}
          ttsRenderSettings={props.block.fields.ttsOptions?.[0]}
          csvDownloadName={`h2h-host-scripts-${props.block.id}.csv`}
        />
      </div>
      <AdditionalSettings>
        <AdditionalSharedSettingsEditor {...props} />
        <TTSOptionEditor
          value={props.block.fields.ttsOptions}
          onChange={handleTTSOptionsChange}
        />
      </AdditionalSettings>
    </div>
  );
}
