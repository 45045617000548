import { ProfileIndex } from '@lp-lib/crowd-frames-schema';

import { getFeatureQueryParamArray } from '../../hooks/useFeatureQueryParam';
import { type TeamMember, type TeamV0 } from '../../types/team';
import { CrowdFramesAvatar } from '../CrowdFrames';
import { DupHostStreamViewWrapper } from '../Host/DupHostStreamView';
import { FloatBoard } from '../Layout';
import { useMyTeamId, useParticipantByClientId } from '../Player';
import { useIsStreamSessionAlive } from '../Session';
import { useTeamMembers, useTeams } from '../TeamAPI/TeamV1';
import { useMyClientId } from '../Venue/VenuePlaygroundProvider';
import { useTeamRandomizerStepDetail } from './Context';

const profileIndex = ProfileIndex[getFeatureQueryParamArray('mini-cf-profile')];

const TeamMemberCmp = (props: { teamMember: TeamMember }): JSX.Element => {
  const myClientId = useMyClientId();
  const participant = useParticipantByClientId(props.teamMember.id);
  const textColor =
    participant?.clientId === myClientId ? 'text-tertiary' : 'text-white';

  if (!participant) return <></>;
  return (
    <div className={`flex flex-row items-center`}>
      <div className='relative flex-grow-0 flex-shrink-0 w-6 h-6'>
        <CrowdFramesAvatar
          participant={participant}
          profileIndex={profileIndex}
          enablePointerEvents={false}
        />
      </div>
      <div className={`text-2xs font-medium ${textColor} truncate ml-1`}>
        {participant?.username}
      </div>
    </div>
  );
};

const Team = (props: { team: TeamV0 }): JSX.Element => {
  const myTeamId = useMyTeamId();
  const members = useTeamMembers(props.team.id);
  return (
    <div
      key={props.team.id}
      className={`w-full rounded-xl border ${
        props.team.id === myTeamId
          ? 'border-primary bg-gradient-to-bl from-team-randomizer-result-bg-start to-team-randomizer-result-bg-end'
          : 'border-white-001 bg-lp-gray-001'
      } p-2`}
    >
      <div className='text-sm font-bold'>{props.team.name}</div>
      <div className='grid grid-cols-2 gap-1 mt-2'>
        {members &&
          members.map((m) => <TeamMemberCmp key={m.id} teamMember={m} />)}
      </div>
    </div>
  );
};

function TeamRandomizerResult(props: {
  showIcebreakerTimer?: boolean;
}): JSX.Element {
  const teams = useTeams({ sort: true, updateStaffTeam: true });
  const isSessionAlive = useIsStreamSessionAlive();

  return (
    <div className='w-screen h-screen fixed'>
      <FloatBoard
        containerZIndex='z-30'
        containerDisplay='flex'
        title='TEAM RANDOMIZER RESULTS'
      >
        <div className='w-full h-full flex flex-col items-center justify-start'>
          <div className='w-full text-tertiary text-base font-bold text-center h-1/5 flex items-center justify-center pt-5'>
            <div className='flex-grow'>
              Say “Hi” to Your Teammates!
              {props.showIcebreakerTimer && (
                <span>
                  {' '}
                  Game Will Kick Off in{' '}
                  <div className='w-8 inline-block text-left'>
                    <IcebreakerTimer />
                  </div>
                </span>
              )}
            </div>
          </div>
          <div className='h-3/4 overflow-y-auto scrollbar w-2/3'>
            <div className='grid grid-cols-2 gap-2 text-white'>
              {teams.map((t) => !t.isStaffTeam && <Team key={t.id} team={t} />)}
            </div>
          </div>
        </div>
      </FloatBoard>
      {isSessionAlive && <DupHostStreamViewWrapper zIndex='z-30' />}
    </div>
  );
}

function IcebreakerTimer(): JSX.Element | null {
  const detail = useTeamRandomizerStepDetail();
  if (detail?.step !== 'results') return null;

  return <>{detail.timestep}</>;
}

export { TeamRandomizerResult };
