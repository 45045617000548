import { delMany, get, getMany, set } from 'idb-keyval';

import logger from '../../logger/logger';

type IDBChunkedFileMeta = {
  chunks: number;
  calculatedDurationMs: number;
};

const log = logger.scoped('IDBChunkedStreamRecorder');

export class IDBChunkedStreamRecorder {
  static async GetRecording(filename: string): Promise<Blob> {
    const keyname = IDBChunkedStreamRecorder.IDBMetaKeyFor(filename);
    const meta = await get<IDBChunkedFileMeta>(keyname);

    if (!meta) throw new Error(`ChunkedIDBFileMetaNotFound: ${filename}`);

    const keys = [...new Array(meta?.chunks)].map((_, idx) =>
      IDBChunkedStreamRecorder.IDBChunkKeyFor(filename, idx)
    );

    const chunks = (await getMany<Blob | undefined>(keys)).filter(
      (chunk) => chunk !== undefined
    ) as Blob[];

    if (chunks.length === 0)
      throw new Error(`ChunkedIDBEmptyFile: ${filename}`);

    const firstChunk = chunks[0];

    // Grab the first mimeType, as they should all be the same. Chunk MimeTypes
    // are ignored unless explicitly passed in.
    const type = firstChunk.type;

    // MediaRecorder does not include the duration in the generated file because
    // it does not buffer the entire file.
    // https://bugs.chromium.org/p/chromium/issues/detail?id=642012 This
    // prevents ffprobe and any other consumer from knowing the duration without
    // actually playing the file.
    return new Blob(chunks, { type });
  }

  static async DeleteRecording(filename: string): Promise<void> {
    const keyname = IDBChunkedStreamRecorder.IDBMetaKeyFor(filename);
    const meta = await get<IDBChunkedFileMeta>(keyname);

    if (!meta) throw new Error(`ChunkedIDBFileNotFound: ${filename}`);

    const keys = [...new Array(meta?.chunks)].map((_, idx) =>
      IDBChunkedStreamRecorder.IDBChunkKeyFor(filename, idx)
    );

    keys.push(IDBChunkedStreamRecorder.IDBMetaKeyFor(filename));

    await delMany(keys);
  }

  static IDBChunkKeyFor(filename: string, chunk: number): string {
    return `${filename}-${chunk}`;
  }

  static IDBMetaKeyFor(filename: string): string {
    return `${filename}-meta`;
  }

  static SupportedMimeTypes(): string[] {
    // From least desireable to most desireable
    return [
      // In Chrome, recording with VP8 will produce audio desync.
      // https://bugs.chromium.org/p/chromium/issues/detail?id=1102830. The
      // workaround is to use VP9 when possible.
      'video/webm;codecs=vp8,opus',
      'video/webm;codecs=vp9,opus',
    ].filter((m) => MediaRecorder.isTypeSupported(m));
  }

  private r: MediaRecorder;
  private count = 0;
  private start = Date.now();

  constructor(
    stream: MediaStream,
    public readonly filename: string,
    timesliceMs = 1000
  ) {
    const mimeType = IDBChunkedStreamRecorder.SupportedMimeTypes().pop();

    if (!mimeType) throw new Error('Could not find a supported mime-type');

    // MediaRecorder accepts a stream as a convenience aka "a bag of tracks"
    // (https://github.com/w3c/mediacapture-record/issues/4#issuecomment-281770575),
    // and does not support adding/removing tracks from the stream! The tracks
    // themselves are owned externally; the StreamRecorder does not assume it
    // can manipulate / close them, only read from them.
    const ownedStream = new MediaStream();
    stream.getTracks().forEach((t) => {
      ownedStream.addTrack(t);
    });

    this.r = new MediaRecorder(ownedStream, {
      // If the codec is not specfied, chrome ignores the mimeType completely
      // and instead uses video/x-matroska!
      // https://stackoverflow.com/questions/64233494/mediarecorder-does-not-produce-a-valid-webm-file
      // It also does not correlate to isTypeSupported:
      // https://chromium.googlesource.com/chromium/src/+/master/third_party/blink/web_tests/fast/mediarecorder/MediaRecorder-isTypeSupported.html
      mimeType,
    });
    this.r.onpause = () => log.debug('paused');
    this.r.addEventListener('error', (ev) => {
      // Often ev.error is undefined, even though it's supposed to be
      // present according to the spec.
      log.error('MediaRecorderError', 'error' in ev ? ev.error : undefined);
    });
    this.r.ondataavailable = (ev) => {
      log.debug(`ondataavailable`, { size: ev.data.size });
      if (ev.data.size > 0) {
        const keyname = IDBChunkedStreamRecorder.IDBChunkKeyFor(
          this.filename,
          this.count
        );
        set(keyname, ev.data);
        this.count++;
      }
    };

    this.r.start(timesliceMs);
  }

  async stop(): Promise<void> {
    const end = Date.now();

    const waitForStop = () => {
      return new Promise<void>((resolve) => {
        this.r.onstop = async () => {
          log.debug(`onstop fired`);

          // Write final metadata
          const keyname = IDBChunkedStreamRecorder.IDBMetaKeyFor(this.filename);
          const meta: IDBChunkedFileMeta = {
            chunks: this.count,
            // This will always be an estimated duration, since it relies on JS time.
            calculatedDurationMs: end - this.start,
          };

          await set(keyname, meta);
          log.debug(`stop: wrote metadata`, { meta });
          return resolve();
        };

        log.debug(`stopping: ${this.r.state}`);
        this.r.stop();
      });
    };

    if (this.r.state !== 'inactive') {
      // Calling .stop() without calling .start() at some point before throws.
      await waitForStop();
    }
  }
}
