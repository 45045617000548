import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useToggle } from 'react-use';

import { type GameSessionScoreSummary } from '@lp-lib/game';

import {
  getFeatureQueryParam,
  getFeatureQueryParamArray,
} from '../../hooks/useFeatureQueryParam';
import { useOutsideClick } from '../../hooks/useOutsideClick';
import { useVenueMode } from '../../hooks/useVenueMode';
import { type ClientId, ProfileIndex } from '../../services/crowd-frames';
import {
  useSendTeamInvite,
  useTeamInvitationByClientId,
} from '../../store/teamInviteHooks';
import { type TeamMember, type TeamV0 } from '../../types/team';
import {
  type ClientType,
  ClientTypeUtils,
  isStaff,
  type Participant,
} from '../../types/user';
import { VenueMode } from '../../types/venue';
import { hexToRgbaCSS } from '../../utils/css';
import {
  ContextMenuScope,
  useContextMenuContext,
} from '../ContextMenu/Context';
import { CrowdFramesAvatar } from '../CrowdFrames';
import { useDatabaseSafeRef } from '../Firebase';
import { increment } from '../Firebase/utils';
import { getDataPath } from '../Game/store';
import { ArrowDownIcon, ArrowUpIcon } from '../icons/Arrows';
import { CrownIcon } from '../icons/CrownIcon';
import { TeamIcon } from '../icons/TeamIcon';
import {
  useAmICohost,
  useMyInstance,
  useMyTeamId,
  useParticipantByClientId,
  useParticipantFlag,
} from '../Player';
import {
  type HandlePanelUIAction,
  type RightPanelTabProps,
  RightPanelTabState,
} from '../RightPanelContext';
import { useIsStreamSessionAlive } from '../Session';
import { useBringTeamOnStageWithConfirmation } from '../Stage';
import {
  useCreateTeam,
  useIsParticipantJoinLocked,
  useIsTeamCaptainScribe,
  useJoinTeam,
  useLeaveTeam,
  useTeamColor,
  useTeamMembers,
  useTeams,
} from '../TeamAPI/TeamV1';
import {
  useMyClientId,
  useMyClientType,
} from '../Venue/VenuePlaygroundProvider';
import { RenameTeam } from './RenameTeam';

const persistentCreateTeamEnabled = getFeatureQueryParam(
  'team-panel-persistent-create-team'
);

function CreateTeam(props: {
  participant: Participant;
  handlePanelUIAction: HandlePanelUIAction;
}) {
  const createTeam = useCreateTeam();
  const onClick = () => {
    createTeam({
      memberId: props.participant.clientId,
      username: props.participant.username,
      cohost: props.participant.cohost,
      debug: 'create-team-from-all-teams-panel',
    });
  };
  const isJoinLocked = useIsParticipantJoinLocked(props.participant.clientId);
  return (
    <div className='pb-3 pl-3 pr-3'>
      <button
        type='button'
        data-testid='create-team-btn'
        className='btn-primary w-full p-3'
        onClick={onClick}
        disabled={isJoinLocked}
      >
        Create New Team
      </button>
    </div>
  );
}

function DropdownActionItem(props: {
  onClick?: () => void;
  children: JSX.Element;
}) {
  return (
    <li
      className='
        p-1.5 hover:bg-lp-black-003 rounded-lg
        cursor-pointer select-none
        flex items-center'
      onClick={props.onClick}
    >
      {props.children}
    </li>
  );
}

function AwardExtraPoints(props: { teamId: string }) {
  const { teamId } = props;
  const [focus, setFocus] = useState(false);
  const [value, setValue] = useState<number>(0);
  const [showAwardText, setShowAwardText] = useState(false);
  const controlsRef = useDatabaseSafeRef<GameSessionScoreSummary>(
    `${getDataPath('scoreSummary')}/${teamId}`
  );

  const submitExtraPoints = () => {
    if (value === 0) {
      return;
    }
    setShowAwardText(true);

    controlsRef.update({
      totalScore: increment(value),
      prevScore: increment(0),
    });
    setTimeout(() => {
      setValue(0);
      setShowAwardText(false);
    }, 1000);
  };

  return (
    <div className='flex flex-row justify-around w-30'>
      {showAwardText && (
        <span className='text-2xs text-[#27CDE8]'>Extra Points Awarded!</span>
      )}
      {!showAwardText && (
        <>
          <input
            className='w-20 h-5.5 field rounded-lg p-1 mb-0 mr-2 text-2xs'
            placeholder='Extra Points'
            type={focus ? 'number' : 'text'}
            value={
              focus
                ? value !== 0
                  ? value
                  : ''
                : value !== 0
                ? value.toLocaleString('en-US')
                : ''
            }
            onFocus={() => setFocus(true)}
            onChange={(event) => {
              if (!isNaN(event.target.valueAsNumber)) {
                setValue(event.target.valueAsNumber);
              } else {
                setValue(0);
              }
            }}
            onBlur={() => {
              setFocus(false);
            }}
          />
          <button
            type='button'
            className='btn-primary w-7.5 h-5 rounded-lg p-0 text-2xs'
            onClick={submitExtraPoints}
          >
            GO
          </button>
        </>
      )}
    </div>
  );
}

function Dropdown(props: { team: TeamV0; handleRenameTeam: () => void }) {
  const [showDropdown, setShowDropdown] = useState(false);
  const handleCloseDropdown = useCallback(() => {
    setShowDropdown(false);
  }, []);

  const ref = useRef<HTMLDivElement | null>(null);
  useOutsideClick(ref, handleCloseDropdown);

  const handleToggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  const myClientType = useMyClientType();
  const myTeamId = useMyTeamId();
  const isMyTeam = myTeamId === props.team.id;
  const isSessionAlive = useIsStreamSessionAlive();
  const bringTeamOnStage = useBringTeamOnStageWithConfirmation();
  const isCohost = useAmICohost();

  const handleBringTeamOnStage = async () => {
    await bringTeamOnStage(props.team.id);
    handleCloseDropdown();
  };

  const actions = [];

  if (ClientTypeUtils.isHost(myClientType)) {
    if (isSessionAlive) {
      actions.push(
        <DropdownActionItem
          key={'bring-team-on-stage'}
          onClick={handleBringTeamOnStage}
        >
          <span>Bring Team on Stage</span>
        </DropdownActionItem>,
        <DropdownActionItem key={'bring-team-on-stage1'}>
          <AwardExtraPoints teamId={props.team.id} />
        </DropdownActionItem>
      );
    }
  }

  if (isCohost) {
    if (isSessionAlive) {
      actions.push(
        <DropdownActionItem>
          <AwardExtraPoints teamId={props.team.id} />
        </DropdownActionItem>
      );
    }
  }

  if (isMyTeam) {
    actions.push(
      <DropdownActionItem key='rename-team' onClick={props.handleRenameTeam}>
        <span>Rename Team</span>
      </DropdownActionItem>
    );
  }

  return (
    <div
      ref={ref}
      className={`relative ${actions.length === 0 ? 'invisible' : ''}`}
    >
      <button
        type='button'
        className='icon-btn-no-shadow bg-transparent w-7.5 h-7.5 rounded-lg'
        onClick={handleToggleDropdown}
      >
        {showDropdown ? <ArrowUpIcon /> : <ArrowDownIcon />}
      </button>
      {showDropdown && (
        <ul
          className='
            z-15 absolute top-9.5 -right-1.5 whitespace-nowrap
            bg-black text-white rounded-xl p-1 text-2xs
            before:tooltipArrowRightTop'
        >
          {actions}
        </ul>
      )}
    </div>
  );
}

const leaveTeamEnabledFeature = getFeatureQueryParam('leave-team');

function Team(props: {
  team: TeamV0;
  participant: Participant;
  myClientId: ClientId;
  myClientType: ClientType;
  isMyTeam: boolean;
  parentOccluding: HTMLElement | null;
  handlePanelUIAction: HandlePanelUIAction;
  showTeamColor?: boolean;
}) {
  const teamMembers = useTeamMembers(
    props.team.id,
    true,
    props.team.captainScribe ?? undefined
  );
  const isJoinLocked = useIsParticipantJoinLocked(props.myClientId);
  const joinTeam = useJoinTeam();
  const leaveTeam = useLeaveTeam();

  const isCohost = useAmICohost();
  const leaveTeamEnabled = leaveTeamEnabledFeature || isCohost;

  const handleJoinTeam = () => {
    props.handlePanelUIAction({ input: 'click-collapse' });
    joinTeam({
      teamId: props.team.id,
      debug: 'join-team-from-all-teams-panel',
      memberId: props.myClientId,
    });
  };

  const handleLeaveTeam = () => {
    leaveTeam('leave-team-from-all-teams-panel');
  };

  const [renaming, setRenaming] = useToggle(false);
  const teamColor = useTeamColor(props.team.id);

  const handleRenameTeam = () => {
    setRenaming(true);
  };

  if (!teamMembers) return null;

  const leaveTeamBtn = (
    <button
      type='button'
      data-testid='leave-team-btn'
      onClick={handleLeaveTeam}
      className='
        btn
        btn-delete
        flex-shrink-0
        text-xs pt-1.5 pr-2.5 pb-1.5 pl-2.5
        ml-1
      '
    >
      Leave
    </button>
  );

  const isFullBtn = (
    <button
      type='button'
      disabled
      className='
        btn
        flex-shrink-0
        text-red-002 text-xs pt-1.5 pr-2.5 pb-1.5 pl-2.5
        bg-gradient-to-bl from-full-team-start to-full-team-end
        ml-1
      '
    >
      Full
    </button>
  );

  const showJoinBtn = isCohost
    ? false
    : props.team.isStaffTeam
    ? isStaff(props.participant)
    : true;
  const joinTeamBtn = showJoinBtn ? (
    <button
      type='button'
      data-testid='join-team-btn'
      onClick={handleJoinTeam}
      disabled={isJoinLocked}
      className='
        btn-primary
        flex-shrink-0
        text-xs pt-1.5 pr-2.5 pb-1.5 pl-2.5
        ml-1
      '
    >
      Join Team
    </button>
  ) : null;

  const actionButton = ClientTypeUtils.isHost(props.myClientType)
    ? null
    : props.isMyTeam
    ? leaveTeamEnabled
      ? leaveTeamBtn
      : null
    : props.team.isFull
    ? isFullBtn
    : joinTeamBtn;

  return (
    <div
      key={props.team.id}
      className='
      flex-grow-0 flex-shrink-0
      pr-3.5 pb-4
      space-y-2.5
       bg-white bg-opacity-10
      ring-1 ring-inset ring-white ring-opacity-5 min-h-30'
      style={{
        backgroundColor:
          props.showTeamColor && teamColor
            ? hexToRgbaCSS(teamColor, 0.1)
            : undefined,
      }}
    >
      <div
        className={`flex justify-between items-center content-center ${
          renaming ? 'pt-2.5 pb-0.5' : 'pt-3.5 pb-1.5'
        }`}
      >
        {renaming ? (
          <RenameTeam
            team={props.team}
            containerClassName='h-10 w-full ml-2.5 pl-3 pr-1 rounded-xl bg-lp-black-002'
            handleClose={() => setRenaming(false)}
          />
        ) : (
          <>
            <div
              className='text-xs font-bold text-white pt-2 pb-2 pl-5 truncate'
              style={{
                color: props.showTeamColor ? teamColor : undefined,
              }}
            >
              {props.team.name}{' '}
            </div>
            <Dropdown team={props.team} handleRenameTeam={handleRenameTeam} />
            {actionButton}
          </>
        )}
      </div>
      <div className='grid grid-cols-2 gap-y-2.5'>
        {teamMembers.map((m) => (
          <TeamMemberToParticipantLookup
            key={m.id}
            parentOccluding={props.parentOccluding}
            teamMember={m}
          />
        ))}
      </div>
    </div>
  );
}

function TeamMemberToParticipantLookup(props: {
  teamMember: TeamMember;
  parentOccluding: HTMLElement | null;
}) {
  const participant = useParticipantByClientId(props.teamMember.id);
  const isCaptain = useIsTeamCaptainScribe(
    participant?.teamId,
    props.teamMember.id
  );
  if (!participant) return null;
  return (
    <ParticipantPlate
      isTeamCaptainScribe={isCaptain}
      participant={participant}
      parentOccluding={props.parentOccluding}
    />
  );
}

function TeamInviteState(props: { participant: Participant }) {
  const myClientId = useMyClientId();
  const myTeamId = useMyTeamId();
  const invitation = useTeamInvitationByClientId(props.participant.clientId);
  const sendTeamInvite = useSendTeamInvite();

  const [invited, setInvited] = useState<boolean>(false);
  const createTeam = useCreateTeam();

  useEffect(() => {
    if (invitation) return;
    if (!myTeamId) return;
    if (!invited) return;
    sendTeamInvite(myClientId, {
      teamId: myTeamId,
      inviteeClientId: props.participant.clientId,
      invitedAt: Date.now(),
    });
    setInvited(false);
  }, [
    invitation,
    myTeamId,
    invited,
    props.participant.clientId,
    myClientId,
    sendTeamInvite,
  ]);

  const handleInvite = () => {
    if (invitation) return;
    if (!myTeamId) {
      createTeam({
        memberId: myClientId,
        username: props.participant.username,
        cohost: props.participant.cohost,
        debug: 'create-team-from-team-invite',
      });
    }
    setInvited(true);
  };

  if (myClientId === props.participant.clientId) {
    return <div className='text-2xs text-right text-gray-001'>You</div>;
  }
  return (
    <div
      className={`${
        !!invitation ? 'text-gray-001' : 'text-blue-004 cursor-pointer'
      } text-2xs text-right w-30 whitespace-nowrap`}
      onClick={handleInvite}
    >
      {!!invitation ? 'Invite sent' : 'Invite to Team'}
    </div>
  );
}

const profileOverride = getFeatureQueryParamArray('mini-cf-profile');
if (!(profileOverride in ProfileIndex))
  throw new Error('Invalid profile requested in teams view');
const profileOverrideIndex = ProfileIndex[profileOverride];

function ParticipantPlate(props: {
  participant: Participant;
  isTeamCaptainScribe?: boolean;
  parentOccluding: HTMLElement | null;
  showInviteState?: boolean;
}) {
  const contextMenuCtx = useContextMenuContext();
  const myClientType = useMyClientType();
  const venueMode = useVenueMode();
  const onStage = useParticipantFlag(props.participant.clientId, 'onStage');
  const amICohost = useAmICohost();
  const showCrown = ClientTypeUtils.isHost(myClientType) || amICohost;

  const handleToggleContextMenu = (event: React.MouseEvent) => {
    contextMenuCtx.setOptions({
      x: event.pageX,
      y: event.pageY,
      clientId: props.participant.clientId,
      insideRightPanel: true,
      scope: ContextMenuScope.Teams,
    });
  };

  return (
    <div className='flex flex-row items-center h-9 pl-2.5 pr-2.5 relative'>
      <div
        className='flex flex-row items-center relative cursor-pointer space-x-2 w-full'
        onClick={handleToggleContextMenu}
      >
        <div className='relative flex-grow-0 flex-shrink-0 w-9 h-9'>
          <CrowdFramesAvatar
            participant={props.participant}
            profileIndex={profileOverrideIndex}
            enablePointerEvents={false}
            throttleRegistration={true}
          />
          {showCrown &&
            props.isTeamCaptainScribe &&
            venueMode === VenueMode.Game && (
              <div className='absolute -bottom-1 -left-1 z-10'>
                <CrownIcon variant='fill' />
              </div>
            )}
        </div>
        <div
          className={`flex flex-col overflow-hidden ${
            props.showInviteState ? 'max-w-30' : ''
          }`}
        >
          {onStage && (
            <div className='text-3xs text-red-002 font-bold'>ON STAGE</div>
          )}
          <div className='text-white text-xs truncate'>
            {props.participant.username}
          </div>
        </div>
      </div>
      {props.showInviteState && (
        <TeamInviteState participant={props.participant} />
      )}
    </div>
  );
}

function ZeroState(): JSX.Element {
  return (
    <div className='flex flex-col text-white items-center justify-center w-full h-full px-10 pb-16'>
      <TeamIcon className='w-10 h-10 fill-current' />
      <div className='text-base font-bold text-center my-4'>
        Teams will be formed once game starts!
      </div>
      <div className='text-3xs font-medium text-center'>
        Once the game begins, you’ll be randomized into teams. You’ll then be
        able to manage the teams here.
      </div>
    </div>
  );
}

function TeamsPanelInternal(
  props: RightPanelTabProps & {
    showTeamColor?: boolean;
    showZeroState?: boolean;
  }
): JSX.Element | null {
  const parentOccluding = useRef<HTMLDivElement | null>(null);
  const myClientId = useMyClientId() as ClientId;
  const myClientType = useMyClientType();
  const participant = useMyInstance();
  const teams = useTeams({
    sort: true,
    pinTeamId: participant?.teamId,
    updateStaffTeam: true,
    excludeStaffTeam:
      ClientTypeUtils.isAudience(myClientType) && !isStaff(participant),
  });

  if (!participant) return null;

  return (
    <div
      ref={parentOccluding}
      className='w-full h-full flex flex-col space-y-0.5'
    >
      {props.showZeroState ? (
        <>
          {persistentCreateTeamEnabled && (
            <CreateTeam
              handlePanelUIAction={props.handlePanelUIAction}
              participant={participant}
            />
          )}
          <ZeroState />
        </>
      ) : (
        <>
          {ClientTypeUtils.isAudience(myClientType) ||
          persistentCreateTeamEnabled ? (
            <CreateTeam
              handlePanelUIAction={props.handlePanelUIAction}
              participant={participant}
            />
          ) : null}
          <div className='scrollbar overflow-y-auto flex flex-col space-y-0.5'>
            {teams.map((t) => (
              <Team
                key={t.id}
                myClientId={myClientId}
                myClientType={participant.clientType}
                isMyTeam={t.id === participant.teamId}
                parentOccluding={parentOccluding.current}
                team={t}
                participant={participant}
                handlePanelUIAction={props.handlePanelUIAction}
                showTeamColor={props.showTeamColor}
              />
            ))}
          </div>
        </>
      )}
    </div>
  );
}

export function TeamsPanel(
  props: RightPanelTabProps & {
    showTeamColor?: boolean;
    showZeroState?: boolean;
  }
): JSX.Element | null {
  if (props.tab !== RightPanelTabState.People) return null;
  return <TeamsPanelInternal {...props} />;
}
