export function RapidBlockIcon(
  props: React.SVGProps<SVGSVGElement>
): JSX.Element {
  return (
    <svg
      className={props.className ?? 'w-3.5 h-3.5 fill-current'}
      viewBox='0 0 14 14'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M2.5 2C1.67157 2 1 2.67157 1 3.5C1 4.17688 1.44835 4.74905 2.06427 4.93573C2.25095 5.55165 2.82312 6 3.5 6H10.5C11.3284 6 12 5.32843 12 4.5C12 3.82312 11.5517 3.25095 10.9357 3.06427C10.7491 2.44835 10.1769 2 9.5 2H2.5ZM2.63378 5H9.5C10.2844 5 10.9282 4.39785 10.9944 3.63056C11.2964 3.80266 11.5 4.12755 11.5 4.5C11.5 5.05228 11.0523 5.5 10.5 5.5H3.5C3.12986 5.5 2.80669 5.2989 2.63378 5Z'
        fill='#FF6F6F'
      />
      <path
        d='M4.61572 14H5.9996V11.6797H7.00954L8.2496 14H9.7773L8.38703 11.456C9.1317 11.1364 9.54399 10.4876 9.54399 9.59273C9.54399 8.29195 8.68426 7.45459 7.19811 7.45459H4.61572V14ZM5.9996 10.5675V8.58598H6.93284C7.73184 8.58598 8.11856 8.94074 8.11856 9.59273C8.11856 10.2415 7.73184 10.5675 6.93923 10.5675H5.9996Z'
        fill='#FF6F6F'
      />
    </svg>
  );
}
