import { useLayoutEffect, useState } from 'react';

import { useInstance } from '../../hooks/useInstance';
import { useWindowDimensions } from '../../hooks/useWindowDimensions';

export type DPRCanvas = {
  width: number;
  height: number;
  cvs: HTMLCanvasElement;
  ctx: CanvasRenderingContext2D;
  dpr: number;
};

declare interface XImageSmoothing {
  webkitImageSmoothingEnabled: boolean;
  msImageSmoothingEnabled: boolean;
  mozImageSmoothingEnabled: boolean;
}

export function makeDPRCanvas(
  width: number,
  height: number,
  cvs: HTMLCanvasElement
): DPRCanvas {
  const ctx = cvs.getContext('2d');

  if (!ctx) throw new Error('Could not create Context2d!');

  // https://developer.mozilla.org/en-US/docs/Web/API/Window/devicePixelRatio

  const dpr = window.devicePixelRatio || 1;
  cvs.style.width = width + 'px';
  cvs.style.height = height + 'px';
  cvs.width = Math.floor(width * dpr);
  cvs.height = Math.floor(height * dpr);
  ctx.scale(dpr, dpr);

  // These need to be set each time the canvas resizes to ensure the backing
  // store retains crisp pixels.
  (ctx as unknown as XImageSmoothing).webkitImageSmoothingEnabled = false;
  (ctx as unknown as XImageSmoothing).msImageSmoothingEnabled = false;
  (ctx as unknown as XImageSmoothing).mozImageSmoothingEnabled = false;
  ctx.imageSmoothingEnabled = false;

  return {
    width,
    height,
    cvs,
    ctx,
    dpr,
  };
}

export function useDPRCanvas(): DPRCanvas {
  const cvs = useInstance(() => document.createElement('canvas'));
  const [dpr, setDpr] = useState(() => makeDPRCanvas(100, 100, cvs));

  // Allow recomputing canvas size on window resize
  const { ratio } = useWindowDimensions();

  useLayoutEffect(() => {
    if (!cvs || !ratio) return;
    if (dpr.height !== window.innerHeight || dpr.width !== window.innerWidth)
      setDpr(makeDPRCanvas(window.innerWidth, window.innerHeight, cvs));
  }, [cvs, dpr.height, dpr.width, ratio]);

  useLayoutEffect(() => {
    if (!cvs) return;
    if (!cvs.parentNode) {
      // TODO: consider accepting a layer or z-index prop
      cvs.setAttribute('data-key', 'dpi');
      document.body.appendChild(cvs);
      cvs.style.zIndex = '40';
      cvs.style.position = 'absolute';
      cvs.style.width = '100%';
      cvs.style.height = '100%';
      cvs.classList.add('pointer-events-off');
    }

    return () => {
      cvs.parentNode?.removeChild(cvs);
    };
  }, [cvs]);

  return dpr;
}
