import Uppy, { type UppyFile } from '@uppy/core';
import { FileInput } from '@uppy/react';
import {
  Field,
  FieldArray,
  type FieldArrayRenderProps,
  Form,
  Formik,
  type FormikErrors,
  type FormikProps,
  type FormikValues,
} from 'formik';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { EnumsMediaScene } from '@lp-lib/api-service-client/public';
import {
  type Block,
  type BlockFields,
  type RapidBlock,
  type RapidBlockMedia,
} from '@lp-lib/game';

import { useUppy } from '../../../../hooks/useUppy';
import { createDownloadCSVFile, csvToArray } from '../../../../utils/csv';
import { uncheckedIndexAccess_UNSAFE } from '../../../../utils/uncheckedIndexAccess_UNSAFE';
import { Modal } from '../../../common/Modal';
import { DeleteIcon } from '../../../icons/DeleteIcon';
import { DownloadIcon } from '../../../icons/DownloadIcon';
import { Loading } from '../../../Loading';
import { useBlockEditorStore } from '../../../RoutedBlock';
import {
  AdditionalSettings,
  AdditionalSharedSettingsEditor,
  BlockMediaEditor,
  CheckboxField,
  EditorBody,
  EditorLayout,
  type EditorProps,
  SelectField,
} from '../Common/Editor/Internal';
import { normalizeRapidAnswer } from './utils';

interface AnswerValues {
  answer: string;
  points: number;
}
interface RapidBlockFormValues {
  question: string;
  questionTime: number;
  answers: AnswerValues[];
  scoreboard: boolean;
  everyoneSubmits: boolean;
  showMissedAnswers: boolean;
  startVideoWithTimer: boolean;
}

interface RapidBlockFormErrors {
  question: string;
  questionTime: string;
  answers: {
    [index: string]: AnswerValues;
  };
  scoreboard: string;
  everyoneSubmits: string;
  showMissedAnswers: string;
  startVideoWithTimer: string;
}

const RSB_MAX_ANSWERS = 10000;
const ANSWER_MAX_CHARACTORS = 3000;
const CSV_MAX_ANSWER_ENTRIES = 30000;
const ERROR_MSG_MAX_CHARACTOR = 'Max character limit reached';
const EDITOR_FIELD_NAMES: Partial<keyof BlockFields<RapidBlock>>[] = [
  'question',
  'questionTime',
  'answers',
  'scoreboard',
  'everyoneSubmits',
  'showMissedAnswers',
  'startVideoWithTimer',
];

const renderBlock = (b?: Block | null) => {
  if (!b) return null;

  return {
    id: b.id,
    type: b.type,
    fields: EDITOR_FIELD_NAMES.map((name: string) => {
      return {
        name,
        value: uncheckedIndexAccess_UNSAFE(b.fields)[name],
      };
    }),
  };
};

const AnswerRow = ({
  index,
  arrayHelpers,
  fieldListOnUpdate,
  answersLength,
  errorsAnswers,
}: {
  index: number;
  arrayHelpers: FieldArrayRenderProps;
  fieldListOnUpdate: (
    e:
      | React.FocusEvent<HTMLInputElement>
      | React.FocusEvent<HTMLAreaElement>
      | React.MouseEvent<HTMLButtonElement>
      | React.KeyboardEvent
      | null,
    itemToRemove?: RapidBlockFormValues['answers'][number] | null
  ) => void;
  answersLength: number;
  errorsAnswers:
    | FormikErrors<{
        [index: string]: AnswerValues;
      }>
    | undefined;
}): JSX.Element => {
  const [onHover, setOnHover] = useState(false);
  const [onPointsInputFocus, setOnPointsInputFocus] = useState(false);

  const onPointsBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    fieldListOnUpdate(e);
    setOnPointsInputFocus(false);
  };

  const autoResize = (el: HTMLTextAreaElement | HTMLInputElement | null) => {
    if (!el) return;
    el.style.cssText = 'height: auto';
    const height = el.scrollHeight;
    el.style.cssText = `height: ${height}px`;
  };

  return (
    <div
      onMouseEnter={() => setOnHover(true)}
      onMouseLeave={() => setOnHover(false)}
      className='relative w-full flex flex-col cursor-pointer'
    >
      <div className='relative w-full mb-1 flex flex-row justify-between'>
        <Field
          className='relative field w-9/12 mb-1 p-2'
          name={`answers.${index}.answer`}
          id={`answer-${index}-answer`}
          placeholder={`Max ${ANSWER_MAX_CHARACTORS} characters`}
          maxLength={ANSWER_MAX_CHARACTORS}
          onBlur={fieldListOnUpdate}
          onClick={(e: React.MouseEvent<HTMLTextAreaElement>) => {
            autoResize(e.currentTarget);
          }}
          autoComplete='off'
          as='textarea'
          onKeyPress={(e: React.KeyboardEvent) => {
            e && autoResize(e.currentTarget as HTMLTextAreaElement);

            e && e.isTrusted && e.key === 'Enter' && fieldListOnUpdate(e);
          }}
        />
        <div
          className={`relative h-12 w-2/12 max-w-19 mb-0 ${
            onPointsInputFocus ? '' : 'after:pointsInputAfter'
          }`}
        >
          <Field
            type='number'
            className={`field w-full text-right ${
              onPointsInputFocus ? '' : 'pr-8'
            }`}
            name={`answers.${index}.points`}
            id={`answers-${index}-points`}
            onBlur={onPointsBlur}
            onFocus={() => setOnPointsInputFocus(true)}
            autoComplete='off'
            maxLength='3'
            onKeyPress={(e: React.KeyboardEvent) => {
              e && e.isTrusted && e.key === 'Enter' && fieldListOnUpdate(e);
            }}
          />
        </div>
        {answersLength > 1 ? (
          <button
            type='button'
            onClick={(e) => {
              const removed = arrayHelpers.remove(
                index
              ) as RapidBlockFormValues['answers'][number];
              if (removed) {
                fieldListOnUpdate(e, removed);
              }
            }}
            className={`${
              onHover ? 'visible' : 'invisible'
            } text-red-002 hover:text-white btn shadow-icon w-10 h-10 bg-black border border-secondary bg-opacity-60 flex justify-center items-center hover:bg-gradient-to-tr hover:from-delete-start hover:to-delete-end active:shadow-inner-lg`}
            name={`answers.remove.${index}`}
          >
            <DeleteIcon className='w-3.5 h-3.5 fill-current pointer-events-off' />
          </button>
        ) : (
          <div className='w-10 h-10' />
        )}
      </div>
      {errorsAnswers?.[index.toString()] ? (
        <div className='h-4.5 text-red-005 font-normal text-3xs'>
          {errorsAnswers[index + '']?.points ||
            errorsAnswers[index + '']?.answer}
        </div>
      ) : (
        <div className='h-4.5' />
      )}
    </div>
  );
};

enum PARSE_RESULT {
  TOO_LONG = 0,
  MISSING_POINTS = 1,
  DUPLICATE_ANSWER = 2,
  COUNT_LIMIT = 3,
}

function csvToAnswer(
  str: string,
  currentAnswers: AnswerValues[] | null
): {
  data: AnswerValues[];
  resultCodes: number[];
} {
  try {
    const csv = csvToArray(str);
    const headers = csv[0];
    const rows = csv.splice(1);

    if (
      !headers ||
      headers.length !== 2 ||
      !headers[0].toLowerCase().includes('answer') ||
      !headers[1].toLowerCase().includes('points')
    ) {
      throw new Error('Incorrect format: header');
    }

    const answerSet = new Set<string>();
    const errorSet = new Set<PARSE_RESULT>();

    if (currentAnswers && currentAnswers.length > 0) {
      currentAnswers.forEach((a) => {
        if (a.answer) {
          answerSet.add(a.answer.toLowerCase());
        }
      });
    }

    const arr = rows.map(function (values) {
      const el = headers.reduce(function (object, header, index) {
        let headerText: string | null = header
          .toString()
          .toLowerCase() as string;
        if (headerText.includes('answer')) {
          headerText = 'answer';
        } else if (headerText.includes('points')) {
          headerText = 'points';
        } else {
          headerText = null;
        }

        if (headerText) {
          let value: string | number | null = null;

          if (headerText === 'answer') {
            let answer = (values[index] || '').trim();
            if (answer.length > ANSWER_MAX_CHARACTORS) {
              answer = answer.substring(0, ANSWER_MAX_CHARACTORS);
              errorSet.add(PARSE_RESULT.TOO_LONG);
            }
            if (answerSet.has(answer.toLowerCase())) {
              errorSet.add(PARSE_RESULT.DUPLICATE_ANSWER);
              value = null;
            } else if (answer.length === 0) {
              value = null;
            } else {
              value = answer;
              answerSet.add(answer.toLowerCase());
            }
          } else if (headerText === 'points') {
            let points = (values[index] || '').trim().replace(/[^0-9]/g, '');
            if (points.length === 0) {
              errorSet.add(PARSE_RESULT.MISSING_POINTS);
              points = '10';
            }
            const parsedVal = parseInt(points, 10);
            value = isNaN(parsedVal) ? 10 : parsedVal;
          }

          if (value) {
            object[headerText] = value;
          }
        }

        return object;
      }, uncheckedIndexAccess_UNSAFE({}));

      return el;
    });

    let data = arr.filter((a) => Object.keys(a).length > 1) as AnswerValues[];

    if (data.length > CSV_MAX_ANSWER_ENTRIES) {
      data = data.slice(0, CSV_MAX_ANSWER_ENTRIES);
      errorSet.add(PARSE_RESULT.COUNT_LIMIT);
    }

    return { data, resultCodes: Array.from(errorSet) };
  } catch (err) {
    throw err;
  }
}

const AnswerList = ({
  blockId,
  answers,
  errorsAnswers,
  fieldListOnUpdate,
  setSavingChanges,
  setFileParseResults,
}: {
  blockId: string;
  setSavingChanges: (c: boolean) => void;
  setFileParseResults: (e: PARSE_RESULT[] | null) => void;
  answers: AnswerValues[];
  errorsAnswers:
    | FormikErrors<{
        [index: string]: AnswerValues;
      }>
    | undefined;
  fieldListOnUpdate: (
    e:
      | React.FocusEvent<HTMLInputElement>
      | React.FocusEvent<HTMLAreaElement>
      | React.MouseEvent<HTMLButtonElement>
      | React.KeyboardEvent
      | null,
    itemToRemove?: RapidBlockFormValues['answers'][number] | null
  ) => void;
}): JSX.Element => {
  const [isFileParseError, setIsFileParseError] = useState(false);
  const [isFileTypeError, setIsFileTypeError] = useState(false);
  const [showLoading, setShowLoading] = useState(false);

  const store = useBlockEditorStore();
  const uploaderId = `csv-uploader-${blockId}`;

  const fileParser = useCallback(
    (currentFile: UppyFile) => {
      setFileParseResults(null);
      setIsFileParseError(false);
      setShowLoading(true);
      setIsFileTypeError(false);

      if (!currentFile || currentFile.type !== 'text/csv') {
        setIsFileTypeError(true);
      }

      const reader = new FileReader();

      reader.onload = async (e) => {
        try {
          const text = (e.target?.result ?? '') as string;
          const { data, resultCodes } = csvToAnswer(text, answers);

          let newAnswers = answers.concat(data);
          if (newAnswers.length > RSB_MAX_ANSWERS) {
            newAnswers = newAnswers.slice(0, RSB_MAX_ANSWERS);
            if (resultCodes.indexOf(PARSE_RESULT.COUNT_LIMIT) === -1) {
              resultCodes.push(PARSE_RESULT.COUNT_LIMIT);
            }
          }

          newAnswers = newAnswers.filter(
            (a) => a.answer && a.answer.trim().length > 0
          );
          const fieldName = 'answers' as keyof BlockFields;
          const fieldValue = JSON.stringify(
            newAnswers
          ) as BlockFields[typeof fieldName];

          setSavingChanges(true);
          await store.updateEditingBlockField(blockId, fieldName, fieldValue);
          setSavingChanges(false);
          setShowLoading(false);
          if (resultCodes && resultCodes.length > 0) {
            setFileParseResults(resultCodes);
          }
        } catch (_) {
          setIsFileParseError(true);
          setShowLoading(false);
        }
      };

      reader.readAsText(currentFile.data);

      return false;
    },
    [answers, blockId, setFileParseResults, setSavingChanges, store]
  );

  const uppy = useUppy(
    uploaderId,
    () =>
      new Uppy({
        id: uploaderId,
        autoProceed: false,
        allowMultipleUploads: true,
        debug: true,
        restrictions: {
          maxFileSize: 1024 * 1024,
          minFileSize: null,
          maxTotalFileSize: 1024 * 1024,
          maxNumberOfFiles: null,
          minNumberOfFiles: 1,
          allowedFileTypes: ['.csv'],
        },
        locale: {
          strings: {
            chooseFiles: 'Upload CSV',
            exceedsSize: 'Max file size exceeded',
            youCanOnlyUploadFileTypes: 'File type not supported',
          },
        },
        onBeforeFileAdded: fileParser,
      })
  );

  useEffect(() => {
    if (blockId) {
      setIsFileParseError(false);
      setIsFileTypeError(false);
      setShowLoading(false);
    }
  }, [blockId]);

  useEffect(() => {
    if (uppy) {
      uppy.setOptions({
        onBeforeFileAdded: fileParser,
      });
    }
  }, [fileParser, uppy]);

  return (
    <>
      <FieldArray
        children={() => null}
        name='answers'
        render={(arrayHelpers) => (
          <div className='w-full h-full mb-40 mt-6'>
            <div className='w-full bg-dark-gray top-0 mb-5 flex flex-row justify-between'>
              <div className='mt-2 w-9/12 font-bold text-white text-base flex flex-col justify-start items-center'>
                <div className='w-full flex flew-row mb-2'>
                  <div className='mt-2'>Correct Answers ({answers.length})</div>
                  {uppy && (
                    <div id='editor-csv-uploader' className='ml-5 '>
                      <FileInput uppy={uppy} inputName={'files'} />
                    </div>
                  )}
                  <div className='ml-5 mt-3 text-xs underline text-icon-gray font-light'>
                    <a
                      className='flex flex-row'
                      href={createDownloadCSVFile([
                        'CORRECT ANSWERS',
                        'POINTS VALUES',
                      ])}
                      download='Rapid Submissions Template.csv'
                    >
                      <DownloadIcon className='w-3.5 h-3.5 fill-current border-b border-icon-gray' />
                      Template
                    </a>
                  </div>
                  {(isFileParseError || isFileTypeError) && (
                    <div className='ml-2.5 text-red-005 text-sms font-normal'>
                      Upload Failed.{' '}
                      {`${
                        isFileTypeError
                          ? 'Wrong file type.'
                          : 'Incorrect format.'
                      }`}
                    </div>
                  )}
                </div>
                <div className='w-full text-icon-gray text-sms font-normal block'>
                  Enter variations of the same answer in one answer field
                  separated by commas. In game results, the first variation will
                  show for missed answers.
                </div>
              </div>
              <div></div>
              <div className='relative w-2/12 max-w-20 mr-3 font-normal text-white text-sms flex flex-col justify-end'>
                Points Value
              </div>
              <div className='w-10' />
            </div>
            <div className='w-full overflow-y-auto scrollbar-hide'>
              {answers.map((_, i) => (
                <AnswerRow
                  key={`answer-row-${i}`}
                  index={i}
                  arrayHelpers={arrayHelpers}
                  fieldListOnUpdate={fieldListOnUpdate}
                  answersLength={answers.length}
                  errorsAnswers={errorsAnswers}
                />
              ))}
              <button
                type='button'
                disabled={answers.length >= RSB_MAX_ANSWERS}
                onClick={() => arrayHelpers.push({ answer: '', points: 10 })}
                className='btn text-secondary text-sms underline'
              >
                {answers.length >= RSB_MAX_ANSWERS ? 'Max Answers' : 'Add More'}
              </button>
            </div>
          </div>
        )}
      />
      {showLoading && (
        <div className='absolute top-0 w-full h-full flex flex-row justify-center items-center self-center z-10'>
          <div className='absolute top-12 w-45 h-16 bg-lp-black-001 border-2 border-black-001 rounded-xl text-white text-base font-bold flex items-center justify-center'>
            <Loading text='Loading...' />
          </div>
        </div>
      )}
    </>
  );
};

export function RapidBlockEditor(props: EditorProps<RapidBlock>): JSX.Element {
  const { block, setSavingChanges } = props;

  const store = useBlockEditorStore();
  const [fileParseResults, setFileParseResults] = useState<
    PARSE_RESULT[] | null
  >(null);

  const blockId = block?.id;

  const selectedBlock = useMemo(() => renderBlock(block), [block]);
  const initialValues = useMemo(
    () =>
      selectedBlock?.fields.reduce((acc, curr) => {
        const value =
          curr.value === null || curr.value === undefined ? '' : curr.value;

        if (curr.name === 'answers') {
          let objectValue = [{ answer: '', points: 10 }];
          try {
            objectValue = JSON.parse(value);
          } catch (_) {}

          acc[curr.name] = objectValue;
        } else {
          uncheckedIndexAccess_UNSAFE(acc)[curr.name] = value;
        }

        return acc;
      }, {} as RapidBlockFormValues),
    [selectedBlock?.fields]
  );

  if (!block || !selectedBlock || !initialValues) return <></>;

  if (initialValues) {
    uncheckedIndexAccess_UNSAFE(initialValues)['id'] = blockId;
  }

  const renderForms = (
    errors: FormikErrors<RapidBlockFormErrors>,
    values: RapidBlockFormValues,
    fieldOnChange: (
      e: React.ChangeEvent<HTMLInputElement> | null | boolean
    ) => void,
    fieldOnBlur: (e: React.FocusEvent<HTMLInputElement> | null) => void,
    fieldListOnUpdate: (
      e:
        | React.FocusEvent<HTMLInputElement>
        | React.MouseEvent<HTMLButtonElement>
        | React.FocusEvent<HTMLAreaElement>
        | React.KeyboardEvent
        | null,
      itemToRemove?: RapidBlockFormValues['answers'][number] | null
    ) => void
  ) => {
    return (
      <EditorBody>
        <p className='text-2xl text-white'>Rapid Submissions Block Editor</p>
        <Form className='w-full my-7.5'>
          <div className='w-full flex flex-col'>
            <div className='w-full flex flex-row'>
              <div className='w-3/5 flex flex-col'>
                <div className='w-full text-base font-bold'>
                  <div className='flex flex-row'>
                    <span className='text-white'>Question Text</span>
                  </div>
                  <Field
                    as='textarea'
                    className={`h-20 mt-1 mb-1 py-2 resize-none ${
                      errors?.question ? 'field-error' : 'field'
                    }`}
                    name='question'
                    id='question'
                    placeholder='Max 300 characters'
                    maxLength='301'
                    onChange={fieldOnChange}
                    onBlur={fieldOnBlur}
                  />
                  <div className='h-4.5 text-red-005 font-normal text-3xs'>
                    {errors?.question ?? null}
                  </div>
                </div>
                <div className='relative w-full h-full'>
                  <AnswerList
                    blockId={blockId}
                    setSavingChanges={setSavingChanges}
                    setFileParseResults={setFileParseResults}
                    answers={values.answers}
                    errorsAnswers={errors.answers}
                    fieldListOnUpdate={fieldListOnUpdate}
                  />
                </div>
              </div>
              <div className='w-2/5 h-full text-white text-base font-bold relative flex flex-col items-center'>
                {blockId ? (
                  <div>
                    <div className=' mb-8'>
                      <BlockMediaEditor<RapidBlockMedia>
                        blockId={blockId}
                        title='Intro Media'
                        scene={EnumsMediaScene.MediaSceneBlockMedia}
                        volumeSelectable
                        mediaData={block.fields?.questionMediaData ?? null}
                        media={block.fields?.questionMedia ?? null}
                        extraNotice='Media will display to the audience when the question is presented.'
                        objectFit='object-contain'
                        field='questionMedia'
                        overrideSizeLimit={{
                          video: 500 * 1024 * 1024,
                        }}
                      />
                    </div>
                    <div className=' mt-8'>
                      <BlockMediaEditor<RapidBlockMedia>
                        blockId={blockId}
                        title='Outro Media'
                        scene={EnumsMediaScene.MediaSceneBlockMedia}
                        volumeSelectable
                        mediaData={block.fields?.answerMediaData ?? null}
                        media={block.fields?.answerMedia ?? null}
                        extraNotice='Media will display to the audience when the question is presented.'
                        objectFit='object-contain'
                        field='answerMedia'
                        overrideSizeLimit={{
                          video: 500 * 1024 * 1024,
                        }}
                      />
                    </div>
                  </div>
                ) : null}
                <hr className='w-85 my-8 border border-secondary' />
                <label htmlFor='time' className='text-base font-bold'>
                  <div className='flex flex-row'>
                    <span className='text-white'>Question Timer</span>
                  </div>
                  <Field
                    as={SelectField}
                    className={`mt-1 mb-1 py-2 ${
                      errors?.questionTime ? 'field-error' : 'field'
                    }`}
                    name='questionTime'
                    id='questionTime'
                    onChange={fieldOnChange}
                    onBlur={fieldOnBlur}
                    options={[
                      {
                        value: 10,
                        label: '10 seconds',
                      },
                      {
                        value: 15,
                        label: '15 seconds',
                      },
                      {
                        value: 20,
                        label: '20 seconds',
                      },
                      {
                        value: 30,
                        label: '30 seconds',
                      },
                      {
                        value: 45,
                        label: '45 seconds',
                      },
                      {
                        value: 60,
                        label: '1 minute',
                      },
                      {
                        value: 90,
                        label: '1.5 minutes',
                      },
                      {
                        value: 120,
                        label: '2 minutes',
                      },
                      {
                        value: 180,
                        label: '3 minutes',
                      },
                      {
                        value: 240,
                        label: '4 minutes',
                      },
                      {
                        value: 300,
                        label: '5 minutes',
                      },
                      {
                        value: 360,
                        label: '6 minutes',
                      },
                    ]}
                  />
                  <div className='h-4.5 text-red-005 font-normal text-3xs'>
                    {errors?.questionTime ?? null}
                  </div>
                </label>
                <label
                  htmlFor='everyoneSubmits'
                  className='text-base font-bold'
                >
                  <div className='w-76 flex flex-row justify-between'>
                    <span className='text-white'>Everyone Submits</span>
                    <Field
                      as={CheckboxField}
                      className='field mt-1 mb-1 py-2 pr-5'
                      name='everyoneSubmits'
                      id='everyoneSubmits'
                      onChange={fieldOnChange}
                      onBlur={fieldOnBlur}
                    />
                  </div>
                  <div className='max-w-76 mt-2 text-secondary text-3xs font-medium'>
                    {values.everyoneSubmits
                      ? 'Enabled: All Players submit answers'
                      : 'Disabled: Only the Team Captain submits answers'}
                  </div>
                  <div className='h-4.5 text-red-005 font-normal text-3xs'>
                    {errors?.everyoneSubmits ?? null}
                  </div>
                </label>

                <label
                  htmlFor='showMissedAnswers'
                  className='text-base font-bold'
                >
                  <div className='w-76 flex flex-row justify-between'>
                    <span className='text-white'>Show Missed Answers</span>
                    <Field
                      as={CheckboxField}
                      className='field mt-1 mb-1 py-2 pr-5'
                      name='showMissedAnswers'
                      id='showMissedAnswers'
                      onChange={fieldOnChange}
                      onBlur={fieldOnBlur}
                    />
                  </div>
                  <div className='max-w-76 mt-2 text-secondary text-3xs font-medium'>
                    {values.showMissedAnswers
                      ? 'Enabled: Display the list of missed answers in the “Your Team’s Answers” panel when the game is over.'
                      : 'Disabled: Hide the list of missed answers in “Your Team’s Answers” panel and instead show the percentage of correct answers.'}
                  </div>
                  <div className='h-4.5 text-red-005 font-normal text-3xs'>
                    {errors?.showMissedAnswers ?? null}
                  </div>
                </label>

                <label
                  htmlFor='startVideoWithTimer'
                  className='text-base font-bold'
                >
                  <div className='max-w-76 flex flex-row justify-between'>
                    <span className='text-white'>Start Video With Timer</span>
                    <Field
                      as={CheckboxField}
                      className='field mt-1 mb-1 py-2 pr-5'
                      name='startVideoWithTimer'
                      id='startVideoWithTimer'
                      onChange={fieldOnChange}
                      onBlur={fieldOnBlur}
                    />
                  </div>
                  <div className='max-w-76 mt-2 text-secondary text-3xs font-medium'>
                    {values.startVideoWithTimer
                      ? 'Enabled: Presenting this Block will show both the Question Text and Video. Video will only play once the timer starts, and it cannot be replayed after.'
                      : 'Disabled: Presenting this Block will auto-play the Question Video. Question Text will only show after the video finishes.'}
                  </div>
                  <div className='h-4.5 text-red-005 font-normal text-3xs'>
                    {errors?.startVideoWithTimer ?? null}
                  </div>
                </label>
              </div>
            </div>
          </div>
        </Form>
      </EditorBody>
    );
  };

  const validate = (values: RapidBlockFormValues) => {
    const errors: FormikErrors<RapidBlockFormErrors> = {};

    if (values.question?.length > 300) {
      errors.question = ERROR_MSG_MAX_CHARACTOR;
    }

    const answers = values.answers || [];
    errors.answers = {};
    answers.forEach((a, i) => {
      const { answer, points } = a;

      let answerError = null;
      let pointsError = null;

      if (a.answer.length > ANSWER_MAX_CHARACTORS) {
        answerError = ERROR_MSG_MAX_CHARACTOR;
      }

      if (!answerError && answer && answer.length > 0) {
        const tempArray = Object.assign([], answers) as AnswerValues[];
        tempArray.splice(i, 1);

        const found = tempArray.find(
          (oa) =>
            oa.answer &&
            normalizeRapidAnswer(oa.answer) === normalizeRapidAnswer(answer)
        );
        if (found) {
          answerError = 'Duplicate answer';
        }
      }

      if (typeof points === 'string') {
        const parsedPoints = parseInt(points, 10);
        if (isNaN(parsedPoints) || parsedPoints < 1 || parsedPoints > 100) {
          pointsError = 'Points Value must be between 1 and 100 Points';
        }
      } else if (typeof points === 'number' && (points < 1 || points > 100)) {
        pointsError = 'Points Value must be between 1 and 100 Points';
      }

      if (answerError || pointsError) {
        if (!errors.answers) errors.answers = {};

        errors.answers[i.toString()] = {
          answer: answerError ? answerError : undefined,
          points: pointsError ? pointsError : undefined,
        };
      }
    });

    return errors;
  };

  return (
    <EditorLayout
      bottomAccessory={
        <AdditionalSettings>
          <AdditionalSharedSettingsEditor {...props} />
        </AdditionalSettings>
      }
    >
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validateOnChange={false}
        validateOnMount={true}
        validate={validate}
        onSubmit={(_values: FormikValues, actions: FormikValues) => {
          actions.setSubmitting(false);
        }}
      >
        {({
          errors,
          handleChange,
          handleBlur,
          values,
          validateForm,
        }: FormikProps<RapidBlockFormValues>) => {
          const formErrors = errors as FormikErrors<RapidBlockFormErrors>;

          const fieldListOnUpdate = async <
            N extends keyof BlockFields,
            V extends BlockFields[N]
          >(
            e:
              | React.FocusEvent<HTMLInputElement>
              | React.FocusEvent<HTMLAreaElement>
              | React.MouseEvent<HTMLButtonElement>
              | React.KeyboardEvent
              | null,
            itemToRemove?: RapidBlockFormValues['answers'][number] | null
          ) => {
            if (!e) return;

            const answerList =
              values.answers as RapidBlockFormValues['answers'];
            let target = null;
            if (e.type === 'blur') {
              handleBlur(e);
              target = e.target as HTMLInputElement;
            } else if (e.type === 'click') {
              target = e.target as HTMLButtonElement;
              if (itemToRemove) {
                const targetIndex = answerList.findIndex(
                  (a) =>
                    a.answer === itemToRemove.answer &&
                    a.points === itemToRemove.points
                );
                if (targetIndex > -1) {
                  answerList.splice(targetIndex, 1);
                  validateForm();
                }
              }
            } else if (e.type === 'keypress') {
              target = e.target as HTMLInputElement;
              (e.currentTarget as HTMLInputElement).blur();
            }

            if (!target) return;

            const fieldName = (target.name || '').split('.')[0] as N;
            const fieldValue = JSON.stringify(answerList) as V;
            setSavingChanges(true);
            await store.updateEditingBlockField(blockId, fieldName, fieldValue);
            setSavingChanges(false);
          };

          const fieldOnBlur = async <
            N extends keyof BlockFields,
            V extends BlockFields[N]
          >(
            e: React.FocusEvent<HTMLInputElement> | null
          ) => {
            handleBlur(e);
            if (e && e.target) {
              const name = e.target.name as N;
              const value = e.target.value as V;
              if (!blockId || !name || value === null || value === undefined)
                return;

              setSavingChanges(true);
              await store.updateEditingBlockField(blockId, name, value);
              setSavingChanges(false);
            }
          };

          const fieldOnChange = async <
            N extends keyof BlockFields<RapidBlock>,
            V extends BlockFields<RapidBlock>[N]
          >(
            e: React.ChangeEvent<HTMLInputElement> | null | boolean,
            name?: N,
            value?: V
          ) => {
            if (typeof e === 'boolean') {
              if (!blockId || !name) return;

              setSavingChanges(true);
              await store.updateEditingBlockField<RapidBlock>(
                blockId,
                name,
                e as V
              );
              setSavingChanges(false);
            } else if (
              e !== null &&
              'target' in e &&
              name === undefined &&
              value === undefined
            ) {
              handleChange(e);
              if (
                e.target.name === 'question' &&
                e.target.value?.length > 300
              ) {
                formErrors.question = ERROR_MSG_MAX_CHARACTOR;
              } else if (formErrors.question) {
                delete formErrors.question;
              }
            } else if (
              e === null &&
              name &&
              value !== null &&
              value !== undefined &&
              blockId
            ) {
              setSavingChanges(true);
              await store.updateEditingBlockField<RapidBlock>(
                blockId,
                name,
                value
              );
              setSavingChanges(false);
            }
          };
          return (
            <>
              {renderForms(
                formErrors,
                values,
                fieldOnChange,
                fieldOnBlur,
                fieldListOnUpdate
              )}
            </>
          );
        }}
      </Formik>
      {fileParseResults && fileParseResults.length > 0 && (
        <Modal borderStyle='white'>
          <div className='w-108 h-68 flex flex-col justify-between items-center'>
            <p className='mt-10 mb-5 text-2xl text-white font-medium'>
              Results of CSV Upload
            </p>
            <div className='w-96 h-21'>
              {fileParseResults.map((resultCode, i) => {
                let message = '';
                switch (resultCode) {
                  case PARSE_RESULT.TOO_LONG:
                    message = `- Answers that were too long were truncated at ${ANSWER_MAX_CHARACTORS} characters`;
                    break;
                  case PARSE_RESULT.MISSING_POINTS:
                    message =
                      '- Answers without Points Values were defaulted to 10 Points';
                    break;
                  case PARSE_RESULT.DUPLICATE_ANSWER:
                    message = '- Duplicate answers were removed';
                    break;
                  case PARSE_RESULT.COUNT_LIMIT:
                    message = `- Answers beyond the ${RSB_MAX_ANSWERS} limit were removed`;
                }

                return (
                  <p
                    key={`err-${i}`}
                    className='w-full font-light text-xs text-icon-gray'
                  >
                    {message}
                  </p>
                );
              })}
            </div>
            <button
              type='button'
              onClick={() => setFileParseResults(null)}
              className='btn-primary my-7.5 w-33 h-10'
            >
              OK
            </button>
          </div>
        </Modal>
      )}
    </EditorLayout>
  );
}
