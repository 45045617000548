import { useEffect, useRef, useState } from 'react';

export function useVisibilityChangeEffect<OnHiddenState extends () => void>(
  effectHandler: () => OnHiddenState
): void {
  const unloadRef = useRef<OnHiddenState>();
  const effectHandlerRef = useRef(effectHandler);
  const visible = usePageVisibility();

  useEffect(() => {
    effectHandlerRef.current = effectHandler;
  }, [effectHandler]);

  useEffect(() => {
    if (visible) {
      unloadRef.current = effectHandlerRef.current();
    } else if (unloadRef.current) {
      unloadRef.current();
    }
  }, [visible]);
}

export function usePageVisibility(): boolean {
  const [visibile, setVisible] = useState(
    document.visibilityState === 'visible'
  );
  useEffect(() => {
    const handleChange = () => {
      setVisible(document.visibilityState === 'visible');
    };

    window.addEventListener('visibilitychange', handleChange);
    handleChange();
    return () => window.removeEventListener('visibilitychange', handleChange);
  }, []);
  return visibile;
}
