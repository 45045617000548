import { createContext, type ReactNode, useContext, useMemo } from 'react';
import { proxy } from 'valtio';

import { useInstance } from '../../hooks/useInstance';
import { useLiveCallback } from '../../hooks/useLiveCallback';
import {
  markSnapshottable,
  useSnapshot,
  type ValtioSnapshottable,
} from '../../utils/valtio';

type State = {
  channels: {
    ond: boolean;
    game: boolean;
  };
};

function initialState(): State {
  return {
    channels: {
      ond: false,
      game: false,
    },
  };
}

const context = createContext<null | { state: ValtioSnapshottable<State> }>(
  null
);

function useGameStreamingStatusContext() {
  const ctx = useContext(context);
  if (!ctx) throw new Error('GameStreamingStatusProvider is not in the tree!');
  return ctx;
}

export function useHasActiveGameStreaming() {
  const { state } = useGameStreamingStatusContext();
  const channels = useSnapshot(state).channels;
  return channels.game || channels.ond;
}

export function useUpdateGameStreaming() {
  const { state } = useGameStreamingStatusContext();
  return useLiveCallback((key: keyof State['channels'], value: boolean) => {
    state.channels[key] = value;
  });
}

export function GameStreamingStatusProvider(props: {
  children?: ReactNode;
}): JSX.Element {
  const state = useInstance(() =>
    markSnapshottable(proxy<State>(initialState()))
  );

  const ctxValue = useMemo(() => ({ state }), [state]);

  return <context.Provider value={ctxValue}>{props.children}</context.Provider>;
}
