import {
  EnumsGamePackAudience,
  EnumsGamePackDifficulty,
} from '@lp-lib/api-service-client/public';

import {
  EnumsGamePackPlayHistoryFilter,
  type GameLikeFilterDuration,
  type GameLikeFilters,
} from '../../../types/game';

export type FilterOption<T> = {
  key: string;
  label: string;
  value: T;
};

export type GameLikeFilterOptions = {
  duration: FilterOption<GameLikeFilterDuration>[];
  difficulty: FilterOption<EnumsGamePackDifficulty>[];
  audience: FilterOption<EnumsGamePackAudience>[];
  playHistory: FilterOption<EnumsGamePackPlayHistoryFilter>[];
  // we only support one player count filter in the backend. we use an array here for consistency.
  playerCount: FilterOption<number>[];
};

export type GameLikeFilterName = keyof GameLikeFilterOptions;

export type Filter<T> = {
  name: GameLikeFilterName;
  displayName: string;
  options: FilterOption<T>[];
};

// note: not all filter keys have presets.
export const FilterPresets = {
  duration: {
    name: 'duration',
    displayName: 'Duration',
    options: [
      { key: '300_630', label: '5-10 Minutes', value: { min: 300, max: 630 } },
      {
        key: '600_1230',
        label: '10-20 Minutes',
        value: { min: 600, max: 1230 },
      },
      {
        key: '1200_1830',
        label: '20-30 Minutes',
        value: { min: 1200, max: 1830 },
      },
      {
        key: '1800_2730',
        label: '30-45 Minutes',
        value: { min: 1800, max: 2730 },
      },
      { key: '2700_INF', label: '45+ Minutes', value: { min: 2700 } },
    ],
  } as Filter<GameLikeFilterDuration>,
  difficulty: {
    name: 'difficulty',
    displayName: 'Difficulty',
    options: [
      {
        key: 'easy',
        label: 'Easy',
        value: EnumsGamePackDifficulty.GamePackDifficultyEasy,
      },
      {
        key: 'medium',
        label: 'Medium',
        value: EnumsGamePackDifficulty.GamePackDifficultyMedium,
      },
      {
        key: 'hard',
        label: 'Hard',
        value: EnumsGamePackDifficulty.GamePackDifficultyHard,
      },
    ],
  } as Filter<EnumsGamePackDifficulty>,
  audience: {
    name: 'audience',
    displayName: 'Audience',
    options: [
      {
        key: 'globalFriendly',
        label: 'Global Friendly Content',
        value: EnumsGamePackAudience.GamePackAudienceGlobal,
      },
    ],
  } as Filter<EnumsGamePackAudience>,
  playHistory: {
    name: 'playHistory',
    displayName: 'Play History',
    options: [
      {
        key: 'played',
        label: 'Games I’ve Played',
        value: EnumsGamePackPlayHistoryFilter.GamePackPlayHistoryFilterPlayed,
      },
      {
        key: 'not-played',
        label: 'Games I Haven’t Played',
        value:
          EnumsGamePackPlayHistoryFilter.GamePackPlayHistoryFilterNotPlayed,
      },
    ],
  } as Filter<EnumsGamePackPlayHistoryFilter>,
} as const;

function transformFilter<T>(
  options: FilterOption<T>[] | undefined
): T[] | undefined {
  if (options === undefined) return undefined;
  if (options.length === 0) return undefined;
  return options.map((o) => o.value);
}

export function toGameLikeFilters(
  filterOptions: Partial<GameLikeFilterOptions>
): GameLikeFilters {
  const params: GameLikeFilters = {};
  params.duration = transformFilter(filterOptions.duration);
  params.difficulty = transformFilter(filterOptions.difficulty);
  params.audience = transformFilter(filterOptions.audience);
  params.playHistory = transformFilter(filterOptions.playHistory);
  if (filterOptions.playerCount && filterOptions.playerCount.length > 0) {
    params.playerCount = filterOptions.playerCount[0].value;
  }
  return params;
}

export function getPlayerCountAsFilterOption(
  playerCount: number
): FilterOption<number> {
  return {
    key: `${playerCount}`,
    label: `Players: ${playerCount}`,
    value: playerCount,
  };
}

export const MIN_PLAYER_COUNT_FILTER = 1;
export const MAX_PLAYER_COUNT_FILTER = 99;

export function parsePlayerCountFilter(
  playerCount: string | undefined | null
): FilterOption<number> | undefined {
  if (!playerCount) return undefined;
  const value = parseInt(playerCount, 10);
  if (
    !isNaN(value) &&
    value >= MIN_PLAYER_COUNT_FILTER &&
    value <= MAX_PLAYER_COUNT_FILTER
  ) {
    return getPlayerCountAsFilterOption(value);
  }
  return undefined;
}
