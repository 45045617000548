import { makeFirebaseSafe } from '../../../Firebase/makeFirebaseSafe';
import { type PlaybackDesc } from '../../Playback/intoPlayback';
import { gameSessionStore } from '../gameSessionStore';

async function removeOnDisconnect(): Promise<void> {
  await gameSessionStore.refs.ondState?.onDisconnect().remove();
}

async function cancelOnDisconnect(): Promise<void> {
  await gameSessionStore.refs.ondState?.onDisconnect().cancel();
}

export const setOndGamePlayRunning = async (
  playbackVersion: number
): Promise<void> => {
  await gameSessionStore.refs.ondState?.update({
    state: 'running',
    playbackVersion,
  });
  await cancelOnDisconnect();
};

export const setOndGamePlayPaused = async (): Promise<void> => {
  await gameSessionStore.refs.ondState?.update({
    state: 'paused',
  });
};

export const setOndGamePlayPausedIfApplicable = async (): Promise<void> => {
  const snap = await gameSessionStore.refs.ondState?.get();
  const val = snap?.val();
  if (val?.state === 'running' || val?.state === 'resuming') {
    await setOndGamePlayPaused();
  }
};

export const setOndGamePlayResuming = async (timer: number): Promise<void> => {
  const { refs } = gameSessionStore;
  await refs.ondState?.update({
    state: 'resuming',
    resumingProgress: timer,
  });
  await cancelOnDisconnect();
};

export async function setOndGamePlayEnded() {
  await gameSessionStore.refs.ondState?.update({
    state: 'ended',
    currentPlaybackItemId: null,
    gamePlayVideoProgress: null,
    sessionProgressSec: 0,
    blockProgressSec: 0,
    blockEndingSec: 0,
    blockRemainderMs: 0,
  });
  await cancelOnDisconnect();
}

export async function setOndGamePlayPreparing() {
  await gameSessionStore.refs.ondState?.update({
    state: 'preparing',
  });
  await removeOnDisconnect();
}

export async function setOndPreparedPlayback(
  value: PlaybackDesc | null
): Promise<void> {
  await gameSessionStore.refs.ondState?.update({
    preparedPlayback: value ? (makeFirebaseSafe(value) as PlaybackDesc) : null,
  });
}
