import capitalize from 'lodash/capitalize';

import { ProfileIndex } from '@lp-lib/crowd-frames-schema';

import { getFeatureQueryParamArray } from '../../hooks/useFeatureQueryParam';
import {
  type ClientType,
  ClientTypeUtils,
  type Participant,
  type TeamMember,
  type TeamV0,
} from '../../types';
import { useBotFakeParticipant, useBots } from '../Bot';
import { type Bot } from '../Bot/types';
import { CrowdFramesAvatar } from '../CrowdFrames';
import { selectConfig } from '../Game/Blocks/Randomizer/utils';
import { useNumOfParticipants, useParticipantByClientId } from '../Player';
import { useTeamMembers } from '../TeamAPI/TeamV1';
import {
  useIsJoinTeamLocked,
  useJoinTeam,
  useTeamColor,
} from '../TeamAPI/TeamV1';
import { useTeamSizeContext } from '../TeamSizeControl/Context';
import { useMyClientId } from '../Venue/VenuePlaygroundProvider';
import { type LobbyDecisionData } from './useLobbyDecisionData';

const profile = ProfileIndex[getFeatureQueryParamArray('mini-cf-profile')];

function ParticipantTeamMember(props: {
  participant?: Participant;
  me: Participant;
}) {
  const { participant } = props;

  return (
    <div className={`flex flex-row items-center mx-px`}>
      <div className='relative flex-grow-0 flex-shrink-0 w-9 h-9'>
        {participant ? (
          <CrowdFramesAvatar
            participant={participant}
            profileIndex={profile}
            enablePointerEvents={
              !(ClientTypeUtils.isAudience(props.me) && !props.me.teamId)
            }
          />
        ) : (
          <div className='w-full h-full border border-secondary border-dashed bg-lp-gray-002 rounded-full' />
        )}
      </div>
      <div
        className={`text-2xs xl:text-xs 2xl:text-sms font-medium ${
          !participant
            ? 'text-lp-gray-002 italic'
            : participant.clientId === props.me.clientId
            ? 'text-tertiary'
            : 'text-white'
        } truncate ml-1 flex-grow`}
      >
        {participant?.firstName ?? participant?.username ?? 'Empty Seat'}
      </div>
    </div>
  );
}

function TeamMember(props: { teamMember: TeamMember; me: Participant }) {
  const participant = useParticipantByClientId(props.teamMember.id);

  if (!participant) return <></>;

  return <ParticipantTeamMember participant={participant} me={props.me} />;
}

function BotMember(props: { bot: Bot; me: Participant }) {
  const participant = useBotFakeParticipant(props.bot);
  return <ParticipantTeamMember participant={participant} me={props.me} />;
}

function YourTeamButton(props: { teamLabel: string }) {
  return (
    <button
      type='button'
      className='btn-secondary w-30 h-10 flex-shrink-0'
      disabled
    >
      Your {capitalize(props.teamLabel)}
    </button>
  );
}

function FullTeamButton() {
  return (
    <button
      type='button'
      className='btn-secondary w-12 h-10 flex-shrink-0 ml-2'
      disabled
    >
      Full
    </button>
  );
}

function JoinTeamButton(props: {
  team: TeamV0;
  teamLabel: string;
  secondary?: boolean;
}) {
  const joinTeam = useJoinTeam();
  const isJoinLocked = useIsJoinTeamLocked();
  const myClientId = useMyClientId();

  const handleJoin = () => {
    joinTeam({
      teamId: props.team.id,
      debug: 'join-team-from-lobby',
      memberId: myClientId,
    });
  };

  return (
    <button
      type='button'
      className={`${
        props.secondary ? 'btn-secondary' : 'btn-primary'
      } w-30 h-10 flex-shrink-0 ml-2`}
      onClick={handleJoin}
      disabled={isJoinLocked}
    >
      Join {capitalize(props.teamLabel)}
    </button>
  );
}

export function BannerTeam(props: {
  team: TeamV0;
  me: Participant;
  myClientType: ClientType;
  isMyTeam: boolean;
  teamLabel: string;
  showTeamName: boolean;
  showTeamColor?: boolean;
  showButton?: boolean;
  decisionData: LobbyDecisionData;
}): JSX.Element {
  const players = useNumOfParticipants({
    filters: ['staff:false', 'host:false'],
  });

  const { maxMembers } = useTeamSizeContext();

  const maxMembersPerTeam =
    props.decisionData.gamePackPlus?.gamePack?.teamRandomizationSettings &&
    props.decisionData.isOndGame
      ? selectConfig(
          props.decisionData.gamePackPlus?.gamePack?.teamRandomizationSettings,
          players
        ).maxTeamSize
      : maxMembers;

  const teamMembers =
    useTeamMembers(props.team.id, true, props.me.clientId) ?? [];
  const teamColor = useTeamColor(props.team.id);
  const bots = useBots();
  const seatsCount = Math.max(
    maxMembersPerTeam - teamMembers.length - bots.length,
    0
  );

  const verticalSpace =
    props.showTeamName && maxMembersPerTeam > 4
      ? 'py-4'
      : props.showTeamName
      ? 'py-4'
      : 'py-2';

  return (
    <div
      className={`
        w-full pl-7.5 pr-5
        bg-lp-black-004 rounded-xl
        flex items-center justify-between
        relative
        border border-secondary
        ${verticalSpace}
      `}
      style={{
        borderColor: props.showTeamColor ? teamColor : undefined,
        height: 'fit-content',
      }}
    >
      <div className='h-full flex-grow flex flex-col justify-center'>
        {props.showTeamName && (
          <div
            className='text-sms font-bold uppercase'
            style={{
              color: props.showTeamColor ? teamColor : undefined,
            }}
          >
            {props.team.name}
          </div>
        )}

        <div
          className={`w-full grid grid-cols-4 gap-y-2.5 gap-x-6 ${
            props.showTeamName ? 'pt-3' : ''
          }`}
        >
          {teamMembers.map((m) => (
            <TeamMember key={m.id} teamMember={m} me={props.me} />
          ))}
          {bots.map((bot) => (
            <BotMember key={bot.id} bot={bot} me={props.me} />
          ))}
          {[...Array(seatsCount)].map((_, i) => {
            return (
              <ParticipantTeamMember key={`empty-seat-${i}`} me={props.me} />
            );
          })}
        </div>
      </div>

      {ClientTypeUtils.isAudience(props.myClientType) && props.showButton && (
        <div className='min-w-32 h-full flex items-center justify-end'>
          {props.isMyTeam ? (
            <YourTeamButton teamLabel={props.teamLabel} />
          ) : props.team.isFull ? (
            <FullTeamButton />
          ) : (
            <JoinTeamButton
              team={props.team}
              teamLabel={props.teamLabel}
              secondary={!!props.me.teamId}
            />
          )}
        </div>
      )}
    </div>
  );
}
