export function AIChatBlockIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      className={props.className ?? 'w-3.5 h-3.5 fill-current'}
      fill='none'
      viewBox='0 0 19 18'
    >
      <g filter='url(#filter0_i_34334_127527)'>
        <rect
          width='1.822'
          height='4.352'
          y='10.299'
          fill='#3CAA7C'
          rx='0.911'
        ></rect>
      </g>
      <rect
        width='1.417'
        height='3.947'
        x='0.202'
        y='10.501'
        stroke='#2D6A5F'
        strokeWidth='0.405'
        rx='0.709'
      ></rect>
      <rect
        width='1.417'
        height='3.947'
        x='16.701'
        y='10.501'
        fill='#3CAA7C'
        stroke='#2D6A5F'
        strokeWidth='0.405'
        rx='0.709'
      ></rect>
      <path
        stroke='#2D6A5F'
        strokeWidth='0.405'
        d='M9.211 3.416L9.211 6.958'
      ></path>
      <g filter='url(#filter1_i_34334_127527)'>
        <rect
          width='4.454'
          height='2.632'
          x='6.984'
          y='5.238'
          fill='#3CAA7C'
          rx='1.316'
        ></rect>
      </g>
      <rect
        width='4.049'
        height='2.227'
        x='7.186'
        y='5.44'
        stroke='#2D6A5F'
        strokeWidth='0.405'
        rx='1.113'
      ></rect>
      <g filter='url(#filter2_i_34334_127527)'>
        <ellipse
          cx='9.211'
          cy='2.1'
          fill='#3CAA7C'
          rx='1.619'
          ry='1.619'
        ></ellipse>
      </g>
      <path
        stroke='#2D6A5F'
        strokeWidth='0.405'
        d='M10.628 2.1a1.417 1.417 0 11-2.834 0 1.417 1.417 0 012.834 0z'
      ></path>
      <g filter='url(#filter3_i_34334_127527)'>
        <path
          fill='#3CAA7C'
          d='M.91 12.222a5.567 5.567 0 015.568-5.567h5.364a5.567 5.567 0 015.567 5.567v2.631a2.733 2.733 0 01-2.733 2.733H3.644A2.733 2.733 0 01.91 14.853v-2.631z'
        ></path>
      </g>
      <path
        stroke='#2D6A5F'
        strokeWidth='0.405'
        d='M1.113 12.222a5.364 5.364 0 015.365-5.365h5.364a5.364 5.364 0 015.365 5.365v2.631a2.53 2.53 0 01-2.53 2.53H3.643a2.53 2.53 0 01-2.53-2.53v-2.631z'
      ></path>
      <circle
        cx='5.668'
        cy='10.501'
        r='2.024'
        fill='#fff'
        stroke='#2D6A5F'
        strokeWidth='0.405'
      ></circle>
      <circle cx='5.668' cy='10.501' r='1.113' fill='#0C0C0C'></circle>
      <ellipse
        cx='5.263'
        cy='10.299'
        fill='#fff'
        rx='0.405'
        ry='0.405'
      ></ellipse>
      <circle
        cx='12.551'
        cy='10.501'
        r='2.024'
        fill='#fff'
        stroke='#2D6A5F'
        strokeWidth='0.405'
      ></circle>
      <circle cx='12.551' cy='10.501' r='1.113' fill='#0C0C0C'></circle>
      <ellipse
        cx='12.146'
        cy='10.299'
        fill='#fff'
        rx='0.405'
        ry='0.405'
      ></ellipse>
      <path
        stroke='#2D6A5F'
        strokeLinecap='round'
        strokeWidth='0.405'
        d='M7.186 14.043c.81 1.57 3.087 1.57 3.948 0'
      ></path>
      <defs>
        <filter
          id='filter0_i_34334_127527'
          width='1.822'
          height='4.352'
          x='0'
          y='10.299'
          colorInterpolationFilters='sRGB'
          filterUnits='userSpaceOnUse'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix'></feFlood>
          <feBlend
            in='SourceGraphic'
            in2='BackgroundImageFix'
            result='shape'
          ></feBlend>
          <feColorMatrix
            in='SourceAlpha'
            result='hardAlpha'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
          ></feColorMatrix>
          <feOffset dx='0.81' dy='0.304'></feOffset>
          <feComposite
            in2='hardAlpha'
            k2='-1'
            k3='1'
            operator='arithmetic'
          ></feComposite>
          <feColorMatrix values='0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.25 0'></feColorMatrix>
          <feBlend
            in2='shape'
            result='effect1_innerShadow_34334_127527'
          ></feBlend>
        </filter>
        <filter
          id='filter1_i_34334_127527'
          width='4.453'
          height='2.632'
          x='6.984'
          y='5.238'
          colorInterpolationFilters='sRGB'
          filterUnits='userSpaceOnUse'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix'></feFlood>
          <feBlend
            in='SourceGraphic'
            in2='BackgroundImageFix'
            result='shape'
          ></feBlend>
          <feColorMatrix
            in='SourceAlpha'
            result='hardAlpha'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
          ></feColorMatrix>
          <feOffset dx='0.81' dy='0.304'></feOffset>
          <feComposite
            in2='hardAlpha'
            k2='-1'
            k3='1'
            operator='arithmetic'
          ></feComposite>
          <feColorMatrix values='0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.25 0'></feColorMatrix>
          <feBlend
            in2='shape'
            result='effect1_innerShadow_34334_127527'
          ></feBlend>
        </filter>
        <filter
          id='filter2_i_34334_127527'
          width='3.239'
          height='3.239'
          x='7.591'
          y='0.481'
          colorInterpolationFilters='sRGB'
          filterUnits='userSpaceOnUse'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix'></feFlood>
          <feBlend
            in='SourceGraphic'
            in2='BackgroundImageFix'
            result='shape'
          ></feBlend>
          <feColorMatrix
            in='SourceAlpha'
            result='hardAlpha'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
          ></feColorMatrix>
          <feOffset dx='0.81' dy='0.304'></feOffset>
          <feComposite
            in2='hardAlpha'
            k2='-1'
            k3='1'
            operator='arithmetic'
          ></feComposite>
          <feColorMatrix values='0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.25 0'></feColorMatrix>
          <feBlend
            in2='shape'
            result='effect1_innerShadow_34334_127527'
          ></feBlend>
        </filter>
        <filter
          id='filter3_i_34334_127527'
          width='16.498'
          height='10.931'
          x='0.911'
          y='6.655'
          colorInterpolationFilters='sRGB'
          filterUnits='userSpaceOnUse'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix'></feFlood>
          <feBlend
            in='SourceGraphic'
            in2='BackgroundImageFix'
            result='shape'
          ></feBlend>
          <feColorMatrix
            in='SourceAlpha'
            result='hardAlpha'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
          ></feColorMatrix>
          <feOffset dx='0.81' dy='0.304'></feOffset>
          <feComposite
            in2='hardAlpha'
            k2='-1'
            k3='1'
            operator='arithmetic'
          ></feComposite>
          <feColorMatrix values='0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.25 0'></feColorMatrix>
          <feBlend
            in2='shape'
            result='effect1_innerShadow_34334_127527'
          ></feBlend>
        </filter>
      </defs>
    </svg>
  );
}
