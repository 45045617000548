import { useEffect, useMemo, useRef } from 'react';

import { assertExhaustive } from '../../../../../utils/common';

export function InputBarView(props: {
  status:
    | 'NotStarted'
    | 'InProgress'
    | 'Correct'
    | 'Wrong'
    | 'Skipped'
    | 'Submitted';
  isMyTurn: boolean;
  submitterName: string;
  value: string;
  onChange: (value: string) => void;
  onSubmit: (value: string) => Promise<void>;
  textAlign?: 'text-left' | 'text-center' | 'text-right';
  customPlaceholder?: string;
  customButtonText?: string;
  maxLength?: number;
}): JSX.Element {
  const {
    isMyTurn,
    submitterName,
    status,
    value,
    onChange,
    onSubmit,
    customPlaceholder,
    maxLength,
  } = props;

  const enabled = isMyTurn && status === 'InProgress';
  const isSubmitted = ['Correct', 'Wrong', 'Submitted'].includes(status);
  const placeholder =
    status === 'Skipped'
      ? '[Skipped]'
      : isSubmitted
      ? '[No answer submitted]'
      : isMyTurn
      ? 'Enter your answer here'
      : `${submitterName} is answering the question`;
  const showSubmitButton = isMyTurn || isSubmitted;

  const ref = useRef<HTMLInputElement>(null);
  useEffect(() => {
    if (!enabled) return;
    ref.current?.focus();
  }, [enabled]);

  const handleSubmit = (value: string) => {
    if (!value) return;
    onSubmit(value);
  };

  const handleKeyPress = (e: React.KeyboardEvent) => {
    if (e && e.isTrusted && e.key === 'Enter') {
      handleSubmit(value);
    }
  };

  const defaultButtonText = useMemo(() => {
    switch (status) {
      case 'NotStarted':
        return 'Waiting';
      case 'InProgress':
        return 'Submit';
      case 'Skipped':
        return 'Skipped';
      case 'Correct':
      case 'Wrong':
      case 'Submitted':
        return 'Submitted!';
      default:
        assertExhaustive(status);
        return 'Submit';
    }
  }, [status]);

  return (
    <div className='relative w-full flex justify-center'>
      <input
        ref={ref}
        autoComplete='off'
        placeholder={customPlaceholder ?? placeholder}
        className={`block w-full h-14 px-4 rounded-1.5lg border border-solid ${
          props.textAlign ?? 'text-center'
        } 
          text-sms font-normal text-blue-002 placeholder-primary-001 outline-none bg-black border-primary
          ${
            status === 'Correct'
              ? 'bg-green-001 bg-gradient-to-t from-lp-black-001 to-lp-black-001 border-green-001'
              : ''
          }
          ${
            status === 'Wrong'
              ? 'bg-lp-red-001 bg-gradient-to-t from-lp-black-001 to-lp-black-001 border-red-001'
              : ''
          }
  `}
        disabled={!enabled}
        value={value}
        onChange={(event) => onChange(event.target.value)}
        onKeyPress={handleKeyPress}
        maxLength={maxLength}
      />

      {showSubmitButton && (
        <div className='absolute w-32 h-8 top-12'>
          <button
            type='button'
            disabled={!enabled}
            onClick={() => handleSubmit(value)}
            className='w-full h-full px-5 bg-black border border-primary-002 
            transform -skew-x-12
            hover:bg-gradient-to-tr hover:from-red-001 hover:via-white
            hover:to-blue-001 hover:text-black
            appearance-none outline-none focus:outline-none
            disabled:cursor-auto disabled:pointer-events-off
            disabled:bg-dark-gray disabled:border-black  disabled:opacity-80
            shadow-gamecard-btn active:shadow-none disabled:shadow-none'
          >
            <p
              className='w-auto transform skew-x-12 bg-black text-transparent bg-clip-text
              font-cairo font-black text-base
              bg-gradient-to-r from-gamecard-btn-start via-white to-gamecard-btn-end'
            >
              {props.customButtonText ?? defaultButtonText}
            </p>
          </button>
        </div>
      )}
    </div>
  );
}
