import { useCallback, useMemo } from 'react';
import useSWR from 'swr';

import { apiService } from '../../../services/api-service';
import { type PairingRound } from '../../../types';
import { assertExhaustive } from '../../../utils/common';

export interface UsePairingRoundsReturned {
  rounds: PairingRound[] | undefined;
  isLoading: boolean;
  error: Error;
  reload: () => void;
  update: (data: PairingRound) => void;
}

export interface UsePairingRoundsProps {
  globalRoundId?: string;
  orgId?: string;
  channelId?: string;

  orderBy: 'orgName' | 'startedAt';
}

export function useRounds(
  props: UsePairingRoundsProps
): UsePairingRoundsReturned {
  const { data, error, isValidating, mutate } = useSWR(
    ['/pairing-rounds', props.globalRoundId, props.orgId, props.channelId],
    async () =>
      (
        await apiService.pairing.queryRounds({
          globalRoundId: props.globalRoundId,
          orgId: props.orgId,
          channelId: props.channelId,
        })
      ).data.rounds || []
  );

  const rounds = useMemo(() => {
    if (!data) return data;

    const sorted = data.sort((a, b) => {
      switch (props.orderBy) {
        case 'orgName':
          const aName = a.organization?.name ?? '';
          const bName = b.organization?.name ?? '';
          return aName.localeCompare(bName);
        case 'startedAt':
          return b.startedAt.localeCompare(a.startedAt);
        default:
          assertExhaustive(props.orderBy);
          return 0;
      }
    });

    return sorted;
  }, [data, props.orderBy]);

  const reload = useCallback(() => {
    mutate();
  }, [mutate]);

  const update = useCallback(
    (data: PairingRound): void => {
      mutate(
        (current) => {
          if (!current) return;

          const index = current.findIndex((i) => i.id === data.id);
          if (index === -1) return current;
          const updated = [...current];
          updated[index] = data;
          return updated;
        },
        {
          revalidate: false,
        }
      );
    },
    [mutate]
  );

  return useMemo(
    () => ({
      rounds,
      isLoading: !data && isValidating,
      error,
      reload,
      update,
    }),
    [rounds, data, isValidating, error, reload, update]
  );
}

export interface UsePairingRoundReturned {
  round: PairingRound | undefined;
  isLoading: boolean;
  error: Error;
  set: (data: PairingRound) => void;
}

export function usePairingRound(props: {
  id?: string | null;
}): UsePairingRoundReturned {
  const { data, error, isValidating, mutate } = useSWR(
    props.id ? `/pairing-rounds/${props.id}` : null,
    async () =>
      (await apiService.pairing.getRoundById(props.id || '')).data.round
  );

  const set = useCallback(
    (data: PairingRound): void => {
      mutate(data, {
        revalidate: false,
      });
    },
    [mutate]
  );

  return useMemo(
    () => ({
      round: data,
      isLoading: !data && isValidating,
      error: error,
      set,
    }),
    [data, error, isValidating, set]
  );
}
