import { SessionMode } from '../../../../../types';
import { PauseIcon } from '../../../../icons/PauseIcon';
import { PlayIcon } from '../../../../icons/PlayIcon';
import { RefreshIcon } from '../../../../icons/RefreshIcon';
import { TimerIcon } from '../../../../icons/TimerIcon';
import {
  useHasLoadedGameLike,
  useLoadedGameLikeHasThingsToPlay,
  useLocalGamePlayStore,
  useLocalSelectedPlaybackItem,
} from '../../../GamePlayStore';
import {
  useIsEndedBlock,
  useOndFormattedGameProgress,
  useOndGameResumingProgress,
  useOndGameState,
} from '../../../hooks';
import { useOndGameControlHandlersStateRoot } from '../../../store';
import { BlockControllerActionButton } from './Internal';
import { ControllerLayout } from './Utilities';

interface OndGameViewProps {
  selectedBlockIndex: number | null;
  setShowResetConfirmation: (show: boolean) => void;
}

export const OndGameView = (props: OndGameViewProps): JSX.Element => {
  const { selectedBlockIndex, setShowResetConfirmation } = props;
  const gamePlayStore = useLocalGamePlayStore();
  const hasLoadedGameLike = useHasLoadedGameLike();
  // NOTE(drew): this, and other usages of SessionMode.Live might seem like a
  // bug. But in OND mode of the host view, we actually always want to use the
  // LIVE resolved playback, since it is devoid of play history and
  // unit-selector limitations.
  const hasPlayableItems = useLoadedGameLikeHasThingsToPlay(SessionMode.Live);

  const ondState = useOndGameState();
  const resumingProgress = useOndGameResumingProgress();
  const elapsedTime = useOndFormattedGameProgress();
  const selectedPBItem = useLocalSelectedPlaybackItem(
    selectedBlockIndex,
    SessionMode.Live
  );
  const isEndedBlock = useIsEndedBlock(selectedPBItem?.block?.id ?? null);

  const isPreparing = ondState === 'preparing';
  const isRunning = ondState === 'running';
  const isResuming = ondState === 'resuming';
  const resumingText = `Resuming in ${resumingProgress}...`;

  const { playPause, coordinatable, willResume, reset } =
    useOndGameControlHandlersStateRoot();

  const onPlayOnDemand = () => {
    playPause(
      gamePlayStore.getResolvedPlayback(SessionMode.Live),
      selectedPBItem?.id
    );
  };

  let actionComponent = <></>;
  if (isEndedBlock) {
    actionComponent = (
      <BlockControllerActionButton
        onClick={() => setShowResetConfirmation(true)}
        icon={RefreshIcon}
        isDelete={true}
      >
        Reset Completed Block
      </BlockControllerActionButton>
    );
  } else if (hasLoadedGameLike) {
    actionComponent = (
      <>
        {isResuming ? (
          <div
            data-testid='block-controller-action-none'
            className='w-full h-10 flex flex-row justify-between items-center border border-secondary rounded-lg text-white'
          >
            <div className='text-sms flex flex-row justify-start items-center'>
              <TimerIcon className='w-4 h-4 ml-3 mr-2.5 fill-current' />
              {resumingText}
            </div>
            <span className='text-3xs font-normal mr-3'>{elapsedTime}</span>
          </div>
        ) : (
          <div className='flex items-center justify-center gap-1'>
            {willResume && (
              <button
                type='button'
                className={`btn-secondary w-1/3 h-10 text-white text-sms flex flex-row justify-center items-center`}
                onClick={reset}
              >
                Reset
              </button>
            )}
            <button
              type='button'
              id='controller-action-primary'
              className={`${
                isRunning ? 'btn-warning' : 'btn-primary'
              } w-full h-10 flex flex-row justify-start items-center`}
              disabled={!hasPlayableItems || !coordinatable || isPreparing}
              onClick={onPlayOnDemand}
            >
              <div className='w-full ml-2 mr-3 flex flex-row justify-between items-center'>
                <div className='text-white text-sms flex flex-row justify-start items-center'>
                  {isRunning ? (
                    <PauseIcon className='w-4 h-4 ml-3 mr-2.5 fill-current' />
                  ) : (
                    <PlayIcon className='w-4 h-4 ml-3 mr-2.5 fill-current' />
                  )}
                  {isPreparing
                    ? 'Preparing / Generating...'
                    : isRunning
                    ? 'Pause Game'
                    : willResume
                    ? 'Resume Game'
                    : 'Play On Demand'}
                </div>
                <span className='text-white text-3xs font-normal'>
                  {elapsedTime}
                </span>
              </div>
            </button>
          </div>
        )}
      </>
    );
  }

  return <ControllerLayout action={actionComponent} />;
};
