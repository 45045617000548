import { useLayoutEffect } from 'react';

import { type MultipleChoiceBlock } from '@lp-lib/game';

import { type GameControlProps } from '../Common/GameControl/types';
import { useGradeAPI } from './grader';

export function MultipleChoiceGameControl(
  props: GameControlProps<MultipleChoiceBlock>
): JSX.Element | null {
  const gradeAPI = useGradeAPI(props.block);

  useLayoutEffect(() => {
    gradeAPI.start();
    return () => gradeAPI.stop();
  }, [gradeAPI]);

  return null;
}
