import pluralize from 'pluralize';
import { forwardRef, useState } from 'react';
import Select, { type SingleValue } from 'react-select';

import { type Option } from '../../../../common/Utilities';

export interface PointsBundle {
  points: number;
  displayPointsMultiplier: boolean;
}

type PointsBundleOption = Option<{
  points: number | null;
  displayPointsMultiplier: boolean;
}>;

const CUSTOM_OPTION: PointsBundleOption = {
  label: 'Custom points (max 1000)',
  value: {
    points: null,
    displayPointsMultiplier: false,
  },
};

const POINTS_OPTIONS: PointsBundleOption[] = [
  {
    label: 'No Points (0 points)',
    value: {
      points: 0,
      displayPointsMultiplier: false,
    },
  },
  {
    label: '100 points',
    value: {
      points: 100,
      displayPointsMultiplier: false,
    },
  },
  {
    label: '200 points',
    value: {
      points: 200,
      displayPointsMultiplier: false,
    },
  },
  {
    label: '2X w/ graphic (200 points)',
    value: {
      points: 200,
      displayPointsMultiplier: true,
    },
  },
  {
    label: '300 points',
    value: {
      points: 300,
      displayPointsMultiplier: false,
    },
  },
  {
    label: '3X w/ graphic (300 points)',
    value: {
      points: 300,
      displayPointsMultiplier: true,
    },
  },
  {
    label: '400 points',
    value: {
      points: 400,
      displayPointsMultiplier: false,
    },
  },
  {
    label: '500 points',
    value: {
      points: 500,
      displayPointsMultiplier: false,
    },
  },
  CUSTOM_OPTION,
];

export interface PointsInputProps {
  defaultValue: number | null;
  max?: number;
  min?: number;
  onChange: (value: number) => void;
  className?: string;
  placeholder?: string;
  autoFocus?: boolean;
  disabled?: boolean;
  renderValue?: (value: number) => string;
  nullValueToZero?: boolean;
}

export const PointsInput = forwardRef<HTMLInputElement, PointsInputProps>(
  (props, ref) => {
    const [focus, setFocus] = useState(false);
    const [value, setValue] = useState<number | null>(props.defaultValue);

    const isInvalid =
      (value === null && !focus) ||
      (props.max !== undefined && value !== null && value > props.max) ||
      (props.min !== undefined && value !== null && value < props.min);

    return (
      <input
        ref={ref}
        className={`${
          !isInvalid ? 'field' : 'field-error text-secondary'
        } h-10 mb-0 w-full px-4 ${props.className}`}
        type={focus ? 'number' : 'text'}
        placeholder={props.placeholder}
        autoFocus={props.autoFocus}
        disabled={props.disabled}
        value={
          value !== null
            ? focus
              ? value
              : props.renderValue
              ? props.renderValue(value)
              : `${value} ${pluralize('point', value)}`
            : undefined
        }
        max={props.max}
        min={0}
        onFocus={() => setFocus(true)}
        onChange={(event) => {
          setValue(
            isNaN(event.target.valueAsNumber)
              ? null
              : event.target.valueAsNumber
          );
        }}
        onBlur={() => {
          if (isInvalid) return;
          if (value === null) {
            if (props.nullValueToZero) {
              props.onChange(0);
              setValue(0);
              setFocus(false);
            }
            return;
          }
          setFocus(false);
          props.onChange(value);
        }}
      ></input>
    );
  }
);

export function PointsSelect(props: {
  label?: string;
  defaultValue: PointsBundle;
  onChange: (val: PointsBundle) => void;
  className?: string;
}): JSX.Element {
  const [points, setPoints] = useState<number | null>(
    props.defaultValue.points
  );
  const [displayPointsMultiplier, setDisplayPointsMultiplier] = useState(
    props.defaultValue.displayPointsMultiplier
  );

  const selected =
    POINTS_OPTIONS.find(
      (op) =>
        op.value.points === points &&
        op.value.displayPointsMultiplier === displayPointsMultiplier
    ) || CUSTOM_OPTION;

  const handleSelect = (option: SingleValue<PointsBundleOption>) => {
    if (!option) return;

    setPoints(option.value.points);
    setDisplayPointsMultiplier(option.value.displayPointsMultiplier);

    if (option.value.points !== null) {
      props.onChange({
        points: option.value.points,
        displayPointsMultiplier: option.value.displayPointsMultiplier,
      });
    }
  };

  const handlePointsInput = (val: number) => {
    setPoints(val);
    props.onChange({
      points: val,
      displayPointsMultiplier: false,
    });
  };

  return (
    <div className='w-full flex flex-col gap-1.5'>
      {props.label && (
        <span className='text-white font-bold'>{props.label}</span>
      )}

      <Select<PointsBundleOption>
        options={POINTS_OPTIONS}
        classNamePrefix='select-box'
        className={`select-box ${props.className}`}
        value={selected}
        onChange={handleSelect}
      />

      {selected === CUSTOM_OPTION && (
        <PointsInput
          autoFocus={points === null}
          defaultValue={points}
          max={1000}
          min={0}
          placeholder={'Enter custom points value'}
          onChange={handlePointsInput}
        />
      )}
    </div>
  );
}
