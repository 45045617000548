import { Route, Routes } from '@remix-run/react';
import useSWR from 'swr';

import { apiService } from '../../services/api-service';
import { SharedAssetLinks } from './SharedAssetLinks';
import { SharedAssetList } from './SharedAssetList';
export { useOpenShareAssetPickerModal } from './SharedAssetPicker';
export { SharedAssetCover } from './SharedAssetList';

export function AdminSharedAsset(): JSX.Element {
  return (
    <div className='w-full h-full flex flex-col text-white px-10 pb-10'>
      <Routes>
        <Route index Component={() => <SharedAssetList />} />
        <Route path=':id/links' Component={() => <SharedAssetLinks />} />
      </Routes>
    </div>
  );
}

export function useSharedAsset(id: Nullable<string>, enabled = true) {
  return useSWR(id && enabled ? `/media/shared/${id}` : null, async () => {
    if (!id) return;
    const resp = await apiService.media.getSharedAsset(id);
    return resp.data.sharedAsset;
  });
}
