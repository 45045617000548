import { useEffect, useRef } from 'react';

import { releaseMediaStream } from '../utils/media';
import { playWithCatch } from '../utils/playWithCatch';

export function useVideoElementFromMediaStream(
  stream: MediaStream | null
): HTMLVideoElement {
  const videoState = useRef<{
    video: HTMLVideoElement;
    playing: Promise<void> | null;
  }>();

  if (!videoState.current) {
    const video = document.createElement('video');
    videoState.current = {
      video,
      playing: null,
    };

    // unintuitive, but this supposedly prevents needing to worry about
    // `.play()` finishing/resolving before destroying/changing the srcObject.
    // https://developers.google.com/web/updates/2017/06/play-request-was-interrupted.
    // In Firefox looks like 'Unhandled Rejection (AbortError) the fetching
    // process for the media resource was aborted by the user agent at the
    // user's request'
    video.load();
  }

  useEffect(() => {
    if (
      stream &&
      videoState.current &&
      videoState.current.video.srcObject !== stream
    ) {
      const { video } = videoState.current;
      video.srcObject = stream;
      videoState.current = {
        video,
        playing: playWithCatch(video) ?? Promise.resolve(),
      };
    }

    return () => {
      if (videoState.current?.video && videoState.current.video.srcObject) {
        const { video, playing } = videoState.current;
        // Grab a reference to the current srcObject since it will be changed
        // during the next effect. We do not want to null-out the srcObject if
        // it has already been written by the next effect run.
        const srcObject = video.srcObject;

        releaseMediaStream(stream);

        // Still need to worry about playing state, even after calling .load(),
        // in Firefox.
        playing?.then(() => {
          // Only null it out if the references have not changed, Since
          // playing.then is async, a new srcObject might have already been set,
          // and we don't want to undo that work.
          if (video.srcObject === srcObject) {
            video.srcObject = null;
          }
        });
      }
    };
  }, [stream]);

  return videoState.current.video;
}
