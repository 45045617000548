import React, {
  type ReactNode,
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { useThrottledCallback } from 'use-debounce';

import { type Logger } from '@lp-lib/logger-base';

import {
  getFeatureQueryParam,
  getFeatureQueryParamArray,
} from '../../hooks/useFeatureQueryParam';
import { useLiveCallback } from '../../hooks/useLiveCallback';
import { useUnload } from '../../hooks/useUnload';
import { default as defaultLogger } from '../../logger/logger';
import {
  type ClientId,
  CrowdFramesService,
  DummyCrowdFramesService,
  type ICrowdFramesService,
  type ProfileAddress,
  type ReceiveFilmstripHandler,
} from '../../services/crowd-frames';
import { rsCounter } from '../../utils/rstats.client';
import { useToken } from '../../utils/token';
import { useLiteModeEnabled } from '../LiteMode';
import { FPSProvider, useFPSContext } from './FPSManager';
import { acceptCrowdFramesMessage } from './FPSManager/useAcceptCrowdFramesMessage';

type CrowdFramesContext = {
  service: ICrowdFramesService;
  iconRenderable: (icon: string) => boolean;
};

export const Context = React.createContext<CrowdFramesContext | null>(null);

export function useCrowdFramesContext(): CrowdFramesContext {
  const ctx = useContext(Context);
  if (!ctx) throw new Error('CrowdFramesProvider not in tree');
  return ctx;
}

export function useCrowdFramesIconRenderable(): (icon: string) => boolean {
  const ctx = useCrowdFramesContext();
  return ctx.iconRenderable;
}

const cfs = getFeatureQueryParamArray('crowd-frames-service');

export const CrowdFramesProvider = (props: {
  liteModeEnabled: boolean;
  myClientId: ClientId;
  userId: string;
  children?: ReactNode;
  iconRenderable?: (icon: string) => boolean;
}): JSX.Element => {
  const [log] = useState(() => defaultLogger.scoped('cf-context'));
  const ctxRef = useRef<CrowdFramesContext | null>(null);
  const [token] = useToken(false);

  const getContext = useCallback(() => {
    if (ctxRef.current) return ctxRef.current;

    // TODO: handle if token is expired, or somehow this provider is called
    // without a user token / user data.

    log.debug('initializing');
    const service =
      props.liteModeEnabled || cfs === 'dummy'
        ? new DummyCrowdFramesService()
        : new CrowdFramesService(props.userId, props.myClientId, token);

    ctxRef.current = {
      service,
      iconRenderable: props.iconRenderable ?? (() => false),
    };

    return ctxRef.current;
  }, [
    log,
    props.liteModeEnabled,
    props.userId,
    props.myClientId,
    props.iconRenderable,
    token,
  ]);

  const destroyContext = useCallback(
    (reason: string) => {
      log.debug(`destroying. reason: ${reason}`);
      ctxRef.current?.service.destroy();
      ctxRef.current = null;
    },
    [log]
  );

  useEffect(() => {
    return () => {
      destroyContext('unmount');
    };
  }, [destroyContext]);

  useUnload(() => {
    // TODO: probably put the entire service into a higher level initializer to
    // synchronize loading states across app
    destroyContext('unload');
  });

  return (
    <Context.Provider value={getContext()}>
      <FPSProvider>
        <MapFPSToCrowdFrames logger={log} />
        {props.children ?? <></>}
      </FPSProvider>
    </Context.Provider>
  );
};

type Store = {
  knownRegisters: Set<ProfileAddress>;
  pendingImmediateRegisters: Set<ProfileAddress>;
  pendingDeferredRegisters: Set<ProfileAddress>;
  pendingDeferredUnregisters: Set<ProfileAddress>;
};

function MapFPSToCrowdFrames(props: { logger: Logger }) {
  const fpsContext = useFPSContext();
  const cfContext = useCrowdFramesContext();

  const lastFlushTimeRef = useRef<number>(Date.now());
  const flushThrottleMs = 100;

  const maybeFlush = useThrottledCallback(
    ({
      knownRegisters,
      pendingImmediateRegisters,
      pendingDeferredRegisters,
      pendingDeferredUnregisters,
    }: Store) => {
      const now = Date.now();

      // flush any immediates
      if (pendingImmediateRegisters.size) {
        cfContext.service.registerUserProfilePairReceptions(
          Array.from(pendingImmediateRegisters)
        );
        pendingImmediateRegisters.forEach((r) => knownRegisters.add(r));
        pendingImmediateRegisters.clear();
      }

      // flush pending deferreds if enough time has passed
      if (now - lastFlushTimeRef.current >= flushThrottleMs) {
        lastFlushTimeRef.current = Date.now();

        if (pendingDeferredRegisters.size) {
          cfContext.service.registerUserProfilePairReceptions(
            Array.from(pendingDeferredRegisters).filter(
              (p) => !knownRegisters.has(p)
            )
          );
          pendingDeferredRegisters.forEach((r) => knownRegisters.add(r));
          pendingDeferredRegisters.clear();
        }
        if (pendingDeferredUnregisters.size) {
          cfContext.service.unregisterUserProfilePairReceptions(
            Array.from(pendingDeferredUnregisters)
          );
          pendingDeferredUnregisters.forEach((r) => knownRegisters.delete(r));
          pendingDeferredUnregisters.clear();
        }
      }
    },
    32,
    { leading: false, trailing: true }
  );

  const gather = useLiveCallback(
    ({
      knownRegisters,
      pendingImmediateRegisters,
      pendingDeferredRegisters,
      pendingDeferredUnregisters,
    }: Store) => {
      const immediateRefCounts = new Map<ProfileAddress, number>();
      const deferredRefCounts = new Map<ProfileAddress, number>();

      for (const [, target] of fpsContext.targets) {
        if (target.throttleRegistration) {
          let count = deferredRefCounts.get(target.profileAddress) ?? 0;
          if (target.inView) count++;
          deferredRefCounts.set(target.profileAddress, count);
        } else {
          let count = immediateRefCounts.get(target.profileAddress) ?? 0;
          if (target.inView) count++;
          immediateRefCounts.set(target.profileAddress, count);
        }
      }

      for (const address of knownRegisters) {
        const count =
          (immediateRefCounts.get(address) ?? 0) +
          (deferredRefCounts.get(address) ?? 0) +
          (fpsContext.renderlessTargets.get(address) ?? 0);
        if (count === 0) {
          pendingDeferredUnregisters.add(address);
          pendingDeferredRegisters.delete(address);
          pendingImmediateRegisters.delete(address);
        }
      }

      for (const [address, count] of immediateRefCounts) {
        if (count > 0 && !knownRegisters.has(address)) {
          pendingImmediateRegisters.add(address);
          pendingDeferredUnregisters.delete(address);
        }
      }

      for (const [address, count] of deferredRefCounts) {
        if (count > 0 && !knownRegisters.has(address)) {
          pendingDeferredRegisters.add(address);
          pendingDeferredUnregisters.delete(address);
        }
      }

      for (const [address, count] of fpsContext.renderlessTargets) {
        if (count > 0 && !knownRegisters.has(address)) {
          pendingDeferredRegisters.add(address);
          pendingDeferredUnregisters.delete(address);
        }
      }
    }
  );

  const liteMode = useLiteModeEnabled();

  useLayoutEffect(() => {
    if (!getFeatureQueryParam('crowd-frames-registration') || liteMode) return;
    const store = {
      knownRegisters: new Set<ProfileAddress>(),
      pendingImmediateRegisters: new Set<ProfileAddress>(),
      pendingDeferredRegisters: new Set<ProfileAddress>(),
      pendingDeferredUnregisters: new Set<ProfileAddress>(),
    };

    fpsContext.registerExternalCallbacks({
      visibilityChange: () => {
        gather(store);
        maybeFlush(store);
        rsCounter('crowd-frame-known-registers-c')?.set(
          store.knownRegisters.size
        );
      },
    });
  }, [fpsContext, gather, liteMode, maybeFlush]);

  useLayoutEffect(() => {
    const recvCb: ReceiveFilmstripHandler = (msg) => {
      acceptCrowdFramesMessage(fpsContext, msg, props.logger);
    };
    cfContext.service.addReceiveFilmstripListener(recvCb);
    return () => {
      cfContext.service.removeReceiveFilmstripListener(recvCb);
    };
  }, [cfContext.service, fpsContext, props.logger]);

  return null;
}
