import range from 'lodash/range';
import pluralize from 'pluralize';

import { EnumsMediaScene } from '@lp-lib/api-service-client/public';
import {
  type OverRoastedBlock,
  type OverRoastedBlockMedia,
} from '@lp-lib/game';

import {
  AdditionalSettings,
  AdditionalSharedSettingsEditor,
  BlockMediaEditor,
  EditorBody,
  EditorLayout,
  type EditorProps,
  RHFCheckbox,
  RHFSelectField,
  useEditor,
} from '../Common/Editor/EditorUtilities';
import { PointsInput } from '../Common/Editor/PointsUtilities';
import { generateBlockTimerOptions } from '../Common/Editor/shared';

const TRUCKS_COUNT_OPTIONS = range(1, 7, 1).map((count) => ({
  value: count,
  label: `${count} Coffee ${pluralize('Truck', count)}`,
}));

const DISPENSERS_COUNT_PER_TRUCK_OPTIONS = range(1, 7, 1).map((count) => ({
  value: count,
  label: `${count} ${pluralize('Dispenser', count)} per Truck`,
}));

const INGREDIENTS_COUNT_PER_PLAYER_OPTIONS = range(1, 3, 1).map((count) => ({
  value: count,
  label: `${count} ${pluralize('Ingredient', count)} per Player`,
}));

const INGREDIENTS_COUNT_PER_ORDER_OPTIONS = range(1, 4, 1).map((count) => ({
  value: count,
  label: `${count} ${pluralize('Ingredient', count)} per Order`,
}));

const GAME_TIME_OPTIONS = generateBlockTimerOptions([
  10, 30, 60, 90, 120, 150, 180, 210, 240, 270, 300, 330, 360,
]);

export function OverRoastedBlockEditor(
  props: EditorProps<OverRoastedBlock>
): JSX.Element | null {
  const { block } = props;
  const { updateField } = useEditor(props);

  return (
    <EditorLayout
      bottomAccessory={
        <AdditionalSettings>
          <AdditionalSharedSettingsEditor {...props} />
        </AdditionalSettings>
      }
    >
      <EditorBody>
        <h2 className='text-2xl text-white'>Over-Roasted Block</h2>
        <div className='w-full my-7.5 flex flex-col text-white'>
          <section className='w-full flex'>
            <div className='w-3/5 flex flex-col gap-8'>
              <div className='flex justify-between gap-5'>
                <RHFSelectField<OverRoastedBlock>
                  className='w-full h-10 text-white mt-1'
                  label='Total Coffee Trucks'
                  name='trucksCount'
                  options={TRUCKS_COUNT_OPTIONS}
                  onChange={(_, value) => {
                    if (typeof value !== 'number') return;
                    updateField('trucksCount', value);
                  }}
                  value={block.fields.trucksCount}
                  disabled={block.fields.tutorialMode}
                />

                <RHFSelectField<OverRoastedBlock>
                  className='w-full h-10 text-white mt-1'
                  label='Coffee Dispensers per Truck'
                  name='dispensersCountPerTruck'
                  options={DISPENSERS_COUNT_PER_TRUCK_OPTIONS}
                  onChange={(_, value) => {
                    if (typeof value !== 'number') return;
                    updateField('dispensersCountPerTruck', value);
                  }}
                  value={block.fields.dispensersCountPerTruck}
                  disabled={block.fields.tutorialMode}
                />
              </div>

              <div className='flex justify-between gap-5'>
                <RHFSelectField<OverRoastedBlock>
                  className='w-full h-10 text-white mt-1'
                  label='Ingredients per Player'
                  name='maxIngredientsPerPlayer'
                  options={INGREDIENTS_COUNT_PER_PLAYER_OPTIONS}
                  onChange={(_, value) => {
                    if (typeof value !== 'number') return;
                    updateField('maxIngredientsPerPlayer', value);
                  }}
                  value={block.fields.maxIngredientsPerPlayer}
                  disabled={block.fields.tutorialMode}
                />

                <RHFSelectField<OverRoastedBlock>
                  className='w-full h-10 text-white mt-1'
                  label='Max Ingredients per Order'
                  name='maxIngredientsPerOrder'
                  options={INGREDIENTS_COUNT_PER_ORDER_OPTIONS}
                  onChange={(_, value) => {
                    if (typeof value !== 'number') return;
                    updateField('maxIngredientsPerOrder', value);
                  }}
                  value={block.fields.maxIngredientsPerOrder}
                  disabled={block.fields.tutorialMode}
                />
              </div>

              <div className='flex justify-between gap-5'>
                <RHFSelectField<OverRoastedBlock>
                  className='w-full h-10 text-white mt-1'
                  label='Game Timer'
                  name='gameTimeSec'
                  options={GAME_TIME_OPTIONS}
                  onChange={(_, value) => {
                    if (typeof value !== 'number') return;
                    updateField('gameTimeSec', value);
                  }}
                  value={block.fields.gameTimeSec}
                  disabled={block.fields.tutorialMode}
                />

                <div className='w-full flex flex-col gap-1'>
                  <span className='text-white font-bold'>Points per Order</span>
                  <PointsInput
                    defaultValue={block.fields.pointsPerOrder}
                    max={1000}
                    min={0}
                    placeholder={'Max 1000 points'}
                    onChange={(value) => {
                      updateField('pointsPerOrder', value);
                    }}
                    disabled={block.fields.tutorialMode}
                  />
                  <div className='text-icon-gray text-3xs font-medium'>
                    Above is per 1-ingredient order. Each additional ingredient
                    in the order adds 50% more points.
                  </div>
                </div>
              </div>
            </div>

            <div className='w-2/5 flex flex-col items-center'>
              <div className='w-full flex flex-col items-center gap-4'>
                <BlockMediaEditor<OverRoastedBlockMedia>
                  blockId={block.id}
                  title='Intro Media'
                  scene={EnumsMediaScene.MediaSceneBlockMedia}
                  volumeSelectable
                  mediaData={block.fields?.introMediaData ?? null}
                  media={block.fields?.introMedia ?? null}
                  extraNotice='Media will display to the audience when the question is presented.'
                  objectFit='object-contain'
                  field='introMedia'
                />

                <BlockMediaEditor<OverRoastedBlockMedia>
                  blockId={block.id}
                  title='Outro Media'
                  scene={EnumsMediaScene.MediaSceneBlockMedia}
                  volumeSelectable
                  mediaData={block.fields?.outroMediaData ?? null}
                  media={block.fields?.outroMedia ?? null}
                  extraNotice='Media will display to the audience when the game results are revealed.'
                  objectFit='object-contain'
                  field='outroMedia'
                />
              </div>

              <hr className='w-85 my-8 border border-secondary' />

              <div className='w-85'>
                <RHFCheckbox<OverRoastedBlock>
                  label='Tutorial Mode'
                  name='tutorialMode'
                  value={block.fields.tutorialMode ?? false}
                  onChange={(_, checked: boolean): void => {
                    updateField('tutorialMode', checked);
                  }}
                  description='When enabled, this Block will override all its settings, and Players will go through the tutorial game of Over-Roasted.'
                />
              </div>
            </div>
          </section>
        </div>
      </EditorBody>
    </EditorLayout>
  );
}
