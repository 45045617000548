import React, {
  type ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { getFeatureQueryParam } from '../../hooks/useFeatureQueryParam';
import { useInstance } from '../../hooks/useInstance';
import { useIsController } from '../../hooks/useMyInstance';
import logger from '../../logger/logger';
import { apiService } from '../../services/api-service';
import {
  type getAudioContext,
  type getEchoCancelledAudioDestination,
} from '../../services/audio/audio-context';
import { mapLinearVolumeToLogarithmic } from '../../services/audio/utils';
import { type Playlist } from '../../types';
import { type Audio } from '../../types/music';
import { getStaticAssetPath } from '../../utils/assets';
import { Emitter } from '../../utils/emitter';
import { MediaUtils } from '../../utils/media';
import { useFirebaseValue } from '../Firebase';
import { useOndPlaybackVersion } from '../Game/hooks';
import { OndVersionChecks } from '../Game/OndVersionChecks';
import { useGameTeamVolumeBalancerValue } from '../Venue/VenuePlaygroundProvider';
import { useVenueId } from '../Venue/VenueProvider';
import { useRTCService } from '../WebRTC';
import {
  useJoinMusicRTCService,
  usePublishMusicRTC,
  useSubscribeMusicRTC,
} from './hooks';
import { MusicPlayerControl } from './MusicPlayerControl';
import { getMusicPlayerDTOFBPath, type MusicPlayerDTO } from './MusicPlayerDTO';
import { type Song } from './types';

const testTrack = getFeatureQueryParam('music-player-test-track')
  ? {
      id: 'bob-ross-001',
      title: 'Bob Ross Goes To Hollywood',
      author: 'Birocratic',
      duration: 189000,
      url: getStaticAssetPath(
        'audios/test-audio-birocratic-bob-ross-goes-to-hollywood-v0.mp3'
      ),
    }
  : null;

const log = logger.scoped('musicPlayer');
interface PlaylistContext {
  loading: boolean;
  playlist: Playlist | null;
  currentIndex: number | null;
  currentAudio: Audio | null;
  setPlaylistById: (playlistId?: string | null, autoPlay?: boolean) => void;
  prev: () => void;
  next: () => void;
  select: (index: number) => void;
}

const playlistContext = React.createContext<null | PlaylistContext>(null);

export const usePlaylistContext = (): PlaylistContext => {
  const ctx = useContext(playlistContext);
  if (!ctx) throw new Error('No value for PlaylistContext');
  return ctx;
};

function songToAudio(song: Song): Audio {
  return {
    id: song.id,
    title: song.title,
    duration: Math.round(MediaUtils.GetMediaDurationMs(song.media) / 1000),
    url: song.media?.url || '',
  };
}

async function getPlaylist(playlistId: string) {
  const resp = await apiService.media.getSharedAsset(playlistId);
  const asset = resp.data.sharedAsset;
  const songs = (asset.data?.playlist ?? []) as Song[];
  const playlist: Playlist = {
    id: asset.id,
    title: asset.label,
    audios: songs.map(songToAudio).filter((audio) => audio.url),
  };
  return playlist;
}

const PlaylistProvider = ({
  children,
}: {
  children?: ReactNode;
}): JSX.Element => {
  const [loading, setLoading] = useState(false);
  const [playlist, setPlaylist] = useState<Playlist | null>(null);
  const [currentIndex, setCurrentIndex] = useState<number | null>(null);
  const currentAudio =
    testTrack ??
    ((currentIndex === null ? null : playlist?.audios[currentIndex]) || null);

  const setPlaylistById = useCallback(
    async (playlistId?: string | null, autoPlay = false) => {
      if (!playlistId) return;
      if (playlist?.id === playlistId) {
        return;
      }

      setLoading(true);

      try {
        const playlist = await getPlaylist(playlistId);
        setPlaylist(playlist);
      } catch (err) {
        log.error('failed to get music playlist', err);
      }

      if (autoPlay) setCurrentIndex(0);

      setLoading(false);
    },
    [playlist?.id]
  );

  const prev = useCallback(() => {
    if (!playlist?.audios?.length) {
      return;
    }

    setCurrentIndex((currentIndex) =>
      currentIndex === null
        ? 0
        : (currentIndex - 1 + playlist.audios.length) % playlist.audios.length
    );
  }, [playlist?.audios?.length]);

  const next = useCallback(() => {
    if (!playlist?.audios.length) {
      return;
    }

    setCurrentIndex((cur) =>
      cur === null ? 0 : (cur + 1) % playlist.audios.length
    );
  }, [playlist?.audios?.length]);

  const ctxValue = useMemo(
    () => ({
      loading,
      playlist,
      currentIndex,
      currentAudio,
      setPlaylistById,
      prev,
      next,
      select: setCurrentIndex,
    }),
    [currentAudio, currentIndex, loading, next, playlist, prev, setPlaylistById]
  );

  return (
    <playlistContext.Provider value={ctxValue}>
      {children}
    </playlistContext.Provider>
  );
};

type TimeUpdateCallback = (currentTime: number) => void;

type MusicPlayerEvents = {
  'on-time-update': TimeUpdateCallback;
};

interface PlayerContext {
  joined: boolean;
  isPlaying: boolean;
  currentAudio: Audio | null;

  play: (reason?: string) => void;
  pause: (reason?: string) => void;
  getCurrentTime: () => number;
  seekTime: (time: number) => void;

  emitter: Emitter<MusicPlayerEvents>;
}

const playerContext = React.createContext<null | PlayerContext>(null);

export const usePlayerContext = (): PlayerContext => {
  const ctx = useContext(playerContext);
  if (!ctx) throw new Error('NO value for PlayerContext');
  return ctx;
};

class PlayerProviderError extends Error {
  name = 'PlayerProviderError';
}

export const PlayerProvider = ({
  children,
  audioContextGetter,
  echoCancelledAudioDestinationGetter,
}: {
  children?: ReactNode;
  audioContextGetter: typeof getAudioContext;
  echoCancelledAudioDestinationGetter: typeof getEchoCancelledAudioDestination;
}): JSX.Element => {
  const venueId = useVenueId();
  const rtcService = useRTCService('music');
  const { playlist, currentAudio, next } = usePlaylistContext();
  const isController = useIsController();
  const ondPlaybackVersion = useOndPlaybackVersion();

  const [isPlaying, setIsPlaying] = useState(false);
  const [gainNode, setGainNode] = useState<GainNode | null>(null);
  const emitter = useInstance(() => new Emitter<MusicPlayerEvents>());
  const audioRef = useRef<HTMLAudioElement>(null);
  const sourceRef = useRef<MediaElementAudioSourceNode | null>(null);

  const joined = useJoinMusicRTCService();
  usePublishMusicRTC(isPlaying);
  useSubscribeMusicRTC();

  const t = useInstance(() => new Date().getTime());
  const retryAttemptedCount = useRef(0);

  // create audio track
  useEffect(() => {
    if (!isController || !audioRef.current) return;

    const audioCtx = audioContextGetter();
    if (!sourceRef.current) {
      sourceRef.current = audioCtx.createMediaElementSource(audioRef.current);
    }
    sourceRef.current.disconnect();
    const remoteDest = audioCtx.createMediaStreamDestination();
    sourceRef.current.connect(remoteDest);
    if (remoteDest.stream.getAudioTracks().length > 0) {
      const track = remoteDest.stream.getAudioTracks()[0];
      rtcService.switchAudio(track);
    }

    const gainNode = audioCtx.createGain();
    const localDest = echoCancelledAudioDestinationGetter();
    sourceRef.current.connect(gainNode);
    gainNode.connect(localDest);
    setGainNode(gainNode);

    return () => {
      sourceRef.current?.disconnect();
      gainNode.disconnect();
      setGainNode(null);
    };
  }, [
    rtcService,
    audioContextGetter,
    echoCancelledAudioDestinationGetter,
    isController,
  ]);

  useEffect(() => {
    if (!isController || !audioRef.current || !currentAudio) {
      return;
    }

    audioRef.current.load();
    log.info('play', { reason: 'audio loaded' });
    audioRef.current.play().catch((err) => {
      const error = new PlayerProviderError('Failed to play music after load', {
        cause: err,
      });
      log.error(error.message, error, {
        retryCount: retryAttemptedCount.current,
        currentAudio,
      });
    });
    return;
  }, [currentAudio, isController]);

  const play = useCallback(
    (reason?: string) => {
      log.info('play', { reason });
      if (!audioRef.current?.src) {
        next();
        return;
      }

      audioRef.current?.play().catch((err) => {
        const error = new PlayerProviderError('Failed to play music', {
          cause: err,
        });
        log.error(error.message, error);
      });
    },
    [next]
  );

  const pause = useCallback((reason?: string) => {
    log.info('pause', { reason });
    audioRef.current?.pause();
  }, []);

  const getCurrentTime = useCallback(() => {
    return audioRef.current?.currentTime || 0;
  }, []);

  const seekTime = useCallback((time: number) => {
    if (!audioRef.current) {
      return;
    }

    audioRef.current.currentTime = time;
  }, []);

  const [gameVolume] = useGameTeamVolumeBalancerValue();
  useEffect(() => {
    const volume = OndVersionChecks(ondPlaybackVersion)
      .ondBoostMusicPlayerVolume
      ? mapLinearVolumeToLogarithmic(gameVolume * 0.9, 0, 100)
      : gameVolume / 50;

    if (!isController) {
      rtcService.setRemoteUserVolume(volume);
      return;
    }

    if (!!gainNode) {
      gainNode.gain.value = volume / 100;
    }
  }, [gainNode, gameVolume, isController, ondPlaybackVersion, rtcService]);

  const [syncedValue, writeSyncedValue] = useFirebaseValue<MusicPlayerDTO>(
    getMusicPlayerDTOFBPath(venueId),
    {
      enabled: true,
      seedValue: {
        currentAudio: null,
        isPlaying: false,
      },
      seedEnabled: true,
      readOnly: !isController,
    }
  );
  useEffect(() => {
    writeSyncedValue({
      currentAudio: currentAudio,
      isPlaying,
    });
  }, [currentAudio, isPlaying, writeSyncedValue]);

  const ctxValue = useMemo(
    () => ({
      currentAudio: isController
        ? currentAudio
        : syncedValue?.currentAudio ?? null,
      isPlaying,
      play,
      pause,
      getCurrentTime,
      seekTime,
      emitter,
      joined,
    }),
    [
      currentAudio,
      emitter,
      getCurrentTime,
      isController,
      isPlaying,
      joined,
      pause,
      play,
      seekTime,
      syncedValue?.currentAudio,
    ]
  );

  return (
    <playerContext.Provider value={ctxValue}>
      <audio
        crossOrigin='anonymous'
        ref={audioRef}
        loop={playlist?.audios.length === 1}
        onEnded={playlist?.audios.length === 1 ? undefined : next}
        onTimeUpdate={() => {
          emitter.emit(
            'on-time-update',
            Math.floor(audioRef.current?.currentTime || 0)
          );
        }}
        onPlay={() => {
          setIsPlaying(true);
        }}
        onPause={() => {
          setIsPlaying(false);
        }}
        onError={() => {
          if (retryAttemptedCount.current > 10) return;
          retryAttemptedCount.current += 1;

          setTimeout(() => {
            next();
          }, 1000);
        }}
        onPlaying={() => {
          retryAttemptedCount.current = 0;
        }}
        src={currentAudio?.url ? `${currentAudio?.url}?t=${t}` : undefined}
      ></audio>
      {children}
    </playerContext.Provider>
  );
};

type MusicPlayerContext = PlaylistContext & PlayerContext;

export const useMusicPlayerContext = (): MusicPlayerContext => {
  const playlistCtx = usePlaylistContext();
  const playerCtx = usePlayerContext();

  return {
    ...playlistCtx,
    ...playerCtx,
  };
};

export const MusicPlayerProvider = ({
  children,
  audioContextGetter,
  echoCancelledAudioDestinationGetter,
  disableControl,
}: {
  children?: ReactNode;
  audioContextGetter: typeof getAudioContext;
  echoCancelledAudioDestinationGetter: typeof getEchoCancelledAudioDestination;
  // this is introduced for testing, we get a bunch of following errors
  // Error: Not implemented: HTMLMediaElement.prototype.pause
  disableControl?: boolean;
}): JSX.Element => {
  const isController = useIsController();

  return (
    <PlaylistProvider>
      <PlayerProvider
        audioContextGetter={audioContextGetter}
        echoCancelledAudioDestinationGetter={
          echoCancelledAudioDestinationGetter
        }
      >
        {isController && !disableControl && <MusicPlayerControl />}
        {children}
      </PlayerProvider>
    </PlaylistProvider>
  );
};
