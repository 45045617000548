import { type TeamId } from '../../../../types';

export enum GameState {
  None = 1,
  Inited,
  InProgress,
  Ended,
}

export type Game = {
  id: string;
  state: GameState;
};

// pieces are position discretely on the board, but continuously in the tray.
// pieces in the tray are positioned by their top left corner on the unit grid.
// clients should translate the position to the user's screen when rendering.
export type Position =
  | {
      row: number;
      col: number;
      location: 'board';
    }
  | {
      top: number;
      left: number;
      location: 'tray';
    };

export type Claim = {
  clientId: string;
  timestamp: number;
};

export type PlacementGrade = 'correct' | 'incorrect' | 'unknown';

export type Piece = {
  id: string;
  value: string;
  claim: Claim | null;
  updatedAt: number | null;
  position: Position;
  url: string;
  grade: PlacementGrade;
  score: number;
};

export type PieceMap = {
  [key: string]: Piece;
};

export type DropSpot = {
  id: string;
  row: number;
  col: number;
  acceptedValue: string;
  url: string;
};

export type DropSpotMap = {
  [key: string]: DropSpot;
};

export type GameTeamInfo = {
  [key: TeamId]:
    | {
        pieces: PieceMap;
      }
    | undefined;
};

export type GameProgressSummary = {
  [key: TeamId]:
    | {
        numCorrectPieces: number;
        numTotalPieces: number;
        totalScore: number;
      }
    | undefined;
};
