import {
  type Control,
  Controller,
  type FieldPath,
  type FieldValues,
} from 'react-hook-form';

export type DirectRangeProps<T extends FieldValues> = {
  name: FieldPath<T>;
  className?: string;
  control?: Control<T>;
  min: number;
  max: number;
  step: number;
  initialValue: number;
  disabled?: boolean;
};

export function DirectRangeInput<T extends FieldValues>(
  props: DirectRangeProps<T>
): JSX.Element {
  return (
    <Controller
      name={props.name}
      control={props.control}
      rules={{
        min: props.min,
        max: props.max,
      }}
      render={({ field }) => {
        const value = field.value ?? props.initialValue;
        return (
          <div className={props.className}>
            <input
              type='range'
              min={props.min}
              max={props.max}
              step={props.step}
              disabled={props.disabled}
              className='w-full flex-grow-0 flex-shrink'
              {...field}
              value={value}
              onChange={(e) => field.onChange(e.target.valueAsNumber)}
            />
            <input
              className='field flex-grow-0 flex-shrink-0 m-0 w-17'
              value={value}
              type='number'
              min={props.min}
              max={props.max}
              step={props.step}
              disabled={props.disabled}
              onChange={(e) => {
                // Ensure no NaNs, default to min.
                const raw = e.currentTarget.valueAsNumber;
                const initial = isNaN(raw) ? props.min : raw;
                // Force to remain within (min,max)
                const limited = Math.max(
                  Math.min(initial, props.max),
                  props.min
                );
                field.onChange(limited);
              }}
            />
          </div>
        );
      }}
    />
  );
}
