import {
  createContext,
  type ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { proxy, useSnapshot } from 'valtio';

import { usePostGameAnalytics } from '../../analytics/postGame';
import { getFeatureQueryParam } from '../../hooks/useFeatureQueryParam';
import { useLoadGame } from '../../hooks/useLoadGame';
import { useIsCoordinator } from '../../hooks/useMyInstance';
import logger from '../../logger/logger';
import { useAwaitFullScreenConfirmCancelModal } from '../ConfirmCancelModalContext';
import { useIsFirebaseConnected } from '../Firebase';
import { useGameSessionPreconfigAPI } from '../Game/Blocks/Common/GamePlay/GameSessionPreconfigProvider';
import { useGameHostingCoordinator } from '../Game/GameHostingProvider';
import {
  type GameLibraryType,
  useCloseGameLibrary,
  useOpenGameLibrary,
} from '../Game/GameLibrary';
import { useLocalLoadedGamePack } from '../Game/GamePlayStore';
import {
  useGameSessionActionsSignalManager,
  useGameSessionBlockId,
  useOndGameState,
  useOndPreparedContextReady,
} from '../Game/hooks';
import { useOnDGameControllerSemaphore } from '../Game/OndGameControl';
import { useOndWrappedControlAPI } from '../Game/OndGameControl/hooks';
import { usePostGameControlAPI } from '../Game/PostGame/Provider';
import { usePreGameControlAPI, useShowPreGame } from '../Game/PreGame/Provider';
import { useRequestGameLogSessionSync } from '../GameLog/GameLogComponents';
import {
  useApplyControllerWithErrorHandler,
  useOnDGameCommandDispatcher,
} from '../OnDGameHosting';
import { useTryReleaseController } from '../OnDGameHosting/OnDGameHostingManager';
import {
  useNewPlayersGetter,
  useNumSeatOccupyingParticipantsGetter,
  useParticipantsGetter,
} from '../Player';
import { useTeamsGetter } from '../TeamAPI/TeamV1';
import { useOndTeamRandomizerAPI } from '../TeamRandomizer/Context';
import { useTownhallAPI } from '../Townhall';
import { useVenueDerivedSettings, useVenueEvent } from '../Venue';
import { useIsCoreChannelJoined } from '../WebRTC';
import { initialExternalState, OndGameUIControl } from './OndGameUICtrl';
import { useInitOnDGamePack } from './useInitOnDGamePack';

const log = logger.scoped('ond-game-ui-control');

const Context = createContext<OndGameUIControl | null>(null);

/**
 * NOTE: This context is purposefully not always in the component tree, such as
 * during a host-driven game, to prevent accidental usage.
 */
export function useOndGameUIControl(): OndGameUIControl | null {
  const ctx = useContext(Context);
  return ctx;
}

export function useOndGameUIControlState() {
  const ctx = useOndGameUIControl();
  const dummy = useMemo(() => proxy({ ...initialExternalState() }), []);
  const snap = useSnapshot(ctx?.state ?? dummy);
  return ctx ? snap : ({} as Partial<typeof dummy>);
}

export function OndGameBootstrapUIControlProvider(props: {
  venueId: string;
  gameLibraryType?: GameLibraryType;
  children?: ReactNode;
}): JSX.Element {
  const packId = useLocalLoadedGamePack()?.id ?? null;
  // NOTE(drew): coreChannelJoined "bounces" between false -> true -> false ->
  // true when starting a game. It would be nice to reduce this, as it causes a
  // jumpy UI.
  const { gameLibraryType } = props;
  const coreChannelJoined = useIsCoreChannelJoined(['ond', 'game']);
  const signalman = useGameSessionActionsSignalManager();
  const ondState = useOndGameState();
  const ondPreparedContextReady = useOndPreparedContextReady();
  const blockId = useGameSessionBlockId();

  const ondGameCommandDispatcher = useOnDGameCommandDispatcher();
  const wrappedOndGameCtrl = useOndWrappedControlAPI();

  const coordinatorClientId = useGameHostingCoordinator()?.clientId;
  const getParticipants = useParticipantsGetter();
  const getPlayerCount = useNumSeatOccupyingParticipantsGetter();
  const derivedVenueSettings = useVenueDerivedSettings();

  const triggerFullScreenModal = useAwaitFullScreenConfirmCancelModal();

  const openGameLibrary = useOpenGameLibrary();
  const closeGameLibrary = useCloseGameLibrary();

  const applyController = useApplyControllerWithErrorHandler();
  const controllerSemaphore = useOnDGameControllerSemaphore();
  const tryReleaseController = useTryReleaseController();
  const loadGame = useLoadGame();
  const postGameControlAPI = usePostGameControlAPI();
  const preGameControlAPI = usePreGameControlAPI();
  const randomizerAPI = useOndTeamRandomizerAPI();
  const postGameAnalytics = usePostGameAnalytics();
  const isCoordinator = useIsCoordinator();
  const showPreGame = useShowPreGame();
  const townhallAPI = useTownhallAPI();
  const getTeams = useTeamsGetter();
  const requestGameLogSessionSync = useRequestGameLogSessionSync(props.venueId);
  const venueEvent = useVenueEvent();
  const skipVIPBlocks = Boolean(
    getFeatureQueryParam('skip-vip-blocks') ||
      (venueEvent && !venueEvent.vipOnStage)
  );
  const preconfigAPI = useGameSessionPreconfigAPI();
  const getNewPlayers = useNewPlayersGetter();

  const [ctx] = useState(() => new OndGameUIControl());

  useEffect(() => {
    return () => {
      ctx.destroy().catch();
    };
  }, [ctx]);

  useEffect(() => {
    const coordinatorUid = coordinatorClientId
      ? getParticipants()[coordinatorClientId]?.id
      : undefined;

    ctx.syncHookedDeps({
      packId,
      coordinatorUid,
      coreChannelJoined,
      signalman,
      ondState,
      ondPreparedContextReady,

      blockId,
      getPlayerCount,
      getNewPlayers,
      derivedVenueSettings,

      wrappedOndGameCtrl,
      ondGameCommandDispatcher,
      tryReleaseController,

      triggerFullScreenModal,

      openGameLibrary,
      closeGameLibrary,

      requestGameLogSessionSync,

      applyController,
      controllerSemaphore,
      loadGame,
      postGameControlAPI,
      preGameControlAPI,
      randomizerAPI,
      postGameAnalytics,
      townhallAPI,
      getTeams,

      gameLibraryType,
      skipVIPBlocks,
      preconfigAPI,
    });
  }, [
    applyController,
    blockId,
    closeGameLibrary,
    controllerSemaphore,
    coreChannelJoined,
    loadGame,
    ondGameCommandDispatcher,
    ctx,
    ondPreparedContextReady,
    ondState,
    openGameLibrary,
    getPlayerCount,
    derivedVenueSettings,
    postGameAnalytics,
    postGameControlAPI,
    preGameControlAPI,
    randomizerAPI,
    signalman,
    triggerFullScreenModal,
    wrappedOndGameCtrl,
    packId,
    getParticipants,
    getNewPlayers,
    tryReleaseController,
    coordinatorClientId,
    townhallAPI,
    getTeams,
    requestGameLogSessionSync,
    gameLibraryType,
    skipVIPBlocks,
    preconfigAPI,
  ]);

  // Note: this block of code is related to system being somewhat in limbo
  // during the preparing phase. when a coordinator clicks start game, we enter
  // the preparing phase. the game does not start until the coordinator clicks
  // 'everyones ready'. however, if the coordinator refreshes or loses
  // connection, we need to recover once they return. alternatively, if a venue
  // is configured with 'shareable control', then the coordinator role is passed
  // to a new user. however, in both situations, the (new) coordinator cannot
  // click 'everyones ready' because they do not have a "startup runner" (since
  // they did not initiate starting the game, or they just refreshed). this
  // effect is designed to detect this and hard reset out of start up. the user
  // can simply start again once they are ready.

  const connected = useIsFirebaseConnected();

  useEffect(() => {
    // Wait until reconnected. A temporary disconnection will cause cascading
    // control / resets from the (possibly) new coordinator. And if you come
    // back online and are the coordinator, you can actually resume.
    if (!connected) return;

    async function run() {
      if (
        isCoordinator &&
        (showPreGame || ondState === 'preparing') &&
        !ctx.hasRunner()
      ) {
        // You are likely the new coordinator

        log.info(
          'coordinator in preparing/pregame and no start runner, resetting.'
        );

        await ctx.onNeedsRecoveryFromPreparing(isCoordinator);
      }

      if (
        !isCoordinator &&
        (showPreGame || ondState === 'preparing') &&
        ctx.hasRunner()
      ) {
        // You likely _were_ the coordinator, but it was given to another user
        // while you were disconnected.

        log.info(
          'non-coordinator in preparing/pregame and a start runner, resetting.'
        );

        await ctx.onNeedsRecoveryFromPreparing(isCoordinator);
      }
    }

    run();
  }, [isCoordinator, ondState, ctx, showPreGame, connected]);

  // Note(guoqiang): it's not a good practice to add the hook here. But since we
  // rely on the `reset` command. Let's move it once we move ond game commands
  // to a single place.
  useInitOnDGamePack({
    resetGame: async () => {
      await ctx.onWillOverrideCurrentActiveGame();
    },
  });

  return <Context.Provider value={ctx}>{props.children}</Context.Provider>;
}
