import { memo, type ReactNode, useMemo } from 'react';

import { MediaFormatVersion } from '@lp-lib/media';

import { useMyInstance } from '../../hooks/useMyInstance';
import { useVenueMode } from '../../hooks/useVenueMode';
import { ClientTypeUtils, isStaff, VenueMode } from '../../types';
import { MediaUtils } from '../../utils/media';
import { CopyVenueLinkStepBanner } from '../common/CopyVenueLink';
import { EventUtils } from '../Event';
import { useAmICohost } from '../Player';
import { useCreateTeam, useJoinTeamExperience } from '../TeamAPI/TeamV1';
import { useTeamRandomizerStepDetail } from '../TeamRandomizer';
import { useTownhallShowTeam } from '../Townhall';
import { isGuest, useUser } from '../UserContext';
import { useVenueOrgId } from '../Venue/useVenueOrgId';
import {
  useMyClientId,
  useMyClientType,
} from '../Venue/VenuePlaygroundProvider';
import { useVenue } from '../Venue/VenueProvider';
import { type LobbyAnalytics } from './analytics';
import { BannerTeam } from './BannerTeam';
import { useOrg } from './hooks';
import { LobbyEventPreview } from './LobbyEventPreview';
import { LobbyGameWidgets } from './LobbyGameWidgets';
import {
  LobbyGuestConversionLiveBanner,
  LobbyGuestConversionOndBanner,
  useOpenGuestConversionWizardAfterGame,
} from './LobbyGuestConversion';
import { useLobbyState } from './Provider';
import { type LobbyDecisionData } from './useLobbyDecisionData';
import { useLobbyTeams } from './useLobbyTeams';

function LobbyWidgetsForLive(props: {
  decisionData: LobbyDecisionData;
  analytics: LobbyAnalytics;
}) {
  const { decisionData, analytics } = props;
  const myClientType = useMyClientType();
  const isHost = ClientTypeUtils.isHost(myClientType);
  const event = decisionData.event;
  const eventNotEnd = useMemo(
    () => (event ? !EventUtils.IsEventEnded(event) : false),
    [event]
  );
  const { offBoardingSessionId } = useLobbyState();
  const showGuestConversionBanner =
    !isHost &&
    !decisionData.isOndGame &&
    !!event &&
    eventNotEnd &&
    !offBoardingSessionId;

  useOpenGuestConversionWizardAfterGame(event?.orgId, 'live-post-game');

  if (showGuestConversionBanner) {
    return (
      <LobbyGuestConversionLiveBanner event={event} analytics={analytics} />
    );
  }
  return <LobbyGameWidgets decisionData={decisionData} />;
}

function LobbyWidgetsForOnd(props: {
  decisionData: LobbyDecisionData;
  analytics: LobbyAnalytics;
}) {
  const { decisionData, analytics } = props;
  const user = useUser();
  const isGuestUser = isGuest(user);
  const orgId = useVenueOrgId();

  useOpenGuestConversionWizardAfterGame(orgId, 'ond-post-game');

  return (
    <>
      <LobbyGameWidgets decisionData={decisionData} />
      {isGuestUser && orgId && (
        <LobbyGuestConversionOndBanner orgId={orgId} analytics={analytics} />
      )}
    </>
  );
}

function BannerBubble(props: { children: ReactNode }) {
  return (
    <div
      className={`
        w-full min-h-30 pl-7.5 pr-5
        bg-lp-black-001 rounded-xl
        flex items-center justify-between gap-7.5
        border border-secondary
      `}
    >
      {props.children}
    </div>
  );
}

function BannerJoinExperience(props: {
  displayName: string;
  forceOneTeam?: boolean;
}) {
  const joinExperience = useJoinTeamExperience();
  return (
    <BannerBubble>
      <div className='flex flex-col justify-between'>
        <header className='text-tertiary font-bold text-2xl'>
          Welcome {props.displayName}!
        </header>
        <p>Your event is in progress. Let's get you onto a team!</p>
      </div>
      <button
        type='button'
        className='btn-primary w-30 h-10 flex-shrink-0'
        onClick={() => joinExperience(props.forceOneTeam)}
      >
        Join Event
      </button>
    </BannerBubble>
  );
}

function CohostJoinExperience(props: { displayName: string }) {
  const createTeam = useCreateTeam();
  const myClientId = useMyClientId();
  return (
    <BannerBubble>
      <div className='flex flex-col justify-between'>
        <header className='text-tertiary font-bold text-2xl'>
          Cohost Mode Active: {props.displayName}
        </header>
        <p></p>
      </div>
      <button
        type='button'
        className='btn-primary w-30 h-10 flex-shrink-0'
        onClick={() =>
          createTeam({
            memberId: myClientId,
            cohost: true,
            debug: 'create-team-from-cohost-join',
          })
        }
      >
        Rejoin
      </button>
    </BannerBubble>
  );
}

function StaffJoinExperience(props: { displayName: string }) {
  const createTeam = useCreateTeam();
  const myClientId = useMyClientId();
  return (
    <BannerBubble>
      <div className='flex flex-col justify-between'>
        <header className='text-tertiary font-bold text-2xl'>
          Moderator Mode Active: {props.displayName}
        </header>
        <p>Create a Staff Team or Join Manually</p>
      </div>
      <button
        type='button'
        className='btn-primary w-30 h-10 flex-shrink-0'
        onClick={() =>
          createTeam({
            memberId: myClientId,
            cohost: false,
            debug: 'create-team-from-staff-join',
          })
        }
      >
        Create Team
      </button>
    </BannerBubble>
  );
}

const Teams = memo(function Teams(props: { decisionData: LobbyDecisionData }) {
  const me = useMyInstance();
  const myClientType = useMyClientType();
  const meIsStaff = isStaff(me);
  const teams = useLobbyTeams();
  if (!me) return null;
  return (
    <>
      {teams.map((t) => (
        <BannerTeam
          key={t.id}
          me={me}
          myClientType={myClientType}
          isMyTeam={t.id === me.teamId}
          team={t}
          teamLabel='team'
          showTeamName={!t.isStaffTeam}
          showTeamColor={!t.isStaffTeam}
          showButton={meIsStaff}
          decisionData={props.decisionData}
        />
      ))}
    </>
  );
});

function WelcomeToLunaPark(props: { fallbackOrgName?: string }) {
  const orgId = useVenueOrgId();

  const { data: org } = useOrg(orgId ?? undefined);

  const logoSrc = MediaUtils.PickMediaUrl(org?.logo, {
    priority: [MediaFormatVersion.SM],
  });

  const logo = (
    <div
      className={`flex items-center gap-2.5 font-bold text-xl`}
      style={{
        width: 'fit-content',
      }}
    >
      {logoSrc && (
        <img
          src={logoSrc}
          className='w-10 h-10 rounded-lg'
          alt='Organization Logo'
        />
      )}
      <div className='font-bold'>
        {org?.name ?? props.fallbackOrgName ?? ''}
      </div>
    </div>
  );

  return (
    <>
      <div
        className='text-xl flex justify-center items-center gap-2 min-h-13 px-22'
        style={{
          background:
            'linear-gradient(90deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.6) 10%, rgba(0,0,0,0.6) 90%, rgba(0,0,0,0) 100%)',
        }}
      >
        {logo} Welcome to Luna Park
      </div>
    </>
  );
}

export function TownhallLobbyInternal(props: {
  onGameDetailsClick: () => void;
  decisionData: LobbyDecisionData;
  analytics: LobbyAnalytics;
  forceOneTeam?: boolean;
}): JSX.Element | null {
  const { decisionData, analytics } = props;
  const [venue] = useVenue();
  const me = useMyInstance();
  const myClientType = useMyClientType();
  const isHost = ClientTypeUtils.isHost(myClientType);
  const meIsStaff = isStaff(me);
  const venueMode = useVenueMode();
  const showTeam = useTownhallShowTeam();
  const detail = useTeamRandomizerStepDetail();
  const showingRandomizerResult = detail?.step === 'results';
  const showCopyVenueLinkBanner = !isHost || decisionData.isOndGame;
  const meIsCohost = useAmICohost();

  if (!me) return null;

  return (
    <div
      className='w-full h-full flex flex-col gap-3'
      data-testid='lobby-internal'
      data-debugid='lobby-townhall'
    >
      {venueMode === VenueMode.Game && (
        <div
          className='
          w-full flex-shrink-0 flex justify-center
          font-cairo font-black text-4xl text-shadow-lobby-title truncate
        '
        >
          {venue.displayName}
        </div>
      )}

      {/*
        min-h-0 is to force this container to both fill the remaining space (via flex-1)
        but _also_ give itself a height limited by the flex container. This allows the
        scrollable section to be contained properly without resorting to overflow:hidden
      */}

      <div
        className='flex-1 w-full min-h-0 flex flex-col gap-1.5'
        data-testid='lobby-modal-teams'
      >
        {venueMode === VenueMode.Lobby && !showingRandomizerResult && (
          <>
            {decisionData.isOndGame ? <WelcomeToLunaPark /> : null}
            {showCopyVenueLinkBanner && <CopyVenueLinkStepBanner />}
            {isHost && !decisionData.isOndGame && <LobbyEventPreview />}
            {decisionData.isOndGame ? (
              <LobbyWidgetsForOnd
                decisionData={decisionData}
                analytics={analytics}
              />
            ) : (
              <LobbyWidgetsForLive
                decisionData={decisionData}
                analytics={analytics}
              />
            )}
          </>
        )}

        {/* Note(jialin): This is hacky, hope there is a better way */}
        {venueMode === VenueMode.Lobby && showingRandomizerResult && (
          <div className='text-xl font-medium w-full text-center mb-5 text-shadow'>
            Randomization Result
          </div>
        )}

        {(showTeam || venueMode === VenueMode.Game) && (
          <div className='w-full flex-1 overflow-y-auto scrollbar flex flex-col gap-1.5 rounded-xl'>
            {venueMode === VenueMode.Game &&
              (meIsCohost ? (
                <CohostJoinExperience
                  displayName={me.firstName ?? me.username}
                />
              ) : meIsStaff ? (
                <StaffJoinExperience
                  displayName={me.firstName ?? me.username}
                />
              ) : (
                <BannerJoinExperience
                  displayName={me.firstName ?? me.username}
                  forceOneTeam={props.forceOneTeam}
                />
              ))}
            <Teams decisionData={decisionData} />
          </div>
        )}
      </div>
    </div>
  );
}
