import { useEffect, useState } from 'react';

import { type Block } from '@lp-lib/game';

import {
  useElapsedRecordingDurationFormatted,
  useIsGameRecordModeRecording,
} from '../../../../GameRecorder/BlockRecorderProvider';
import { type ValueEmitter } from './types';

export const BlockControllerDrawer = (props: {
  block: Block | null;
  mouseLeaveBuster: ValueEmitter;
  setShowStopRecordingConfirmation: (s: boolean) => void;
  visible: boolean;
  children: JSX.Element | null | (JSX.Element | null)[];
}): JSX.Element | null => {
  const [collapseOnHover, setCollapseOnHover] = useState(false);

  const { mouseLeaveBuster: subscribeMouseLeaveBuster } = props;

  // If the mouse is in the drawer, keep it tall. If the block has changed (due
  // to the user clicking), keep it tall. Otherwise, a hover of the group (the
  // entire GameController) should cause the BlockControllerDrawer to collapse.

  useEffect(() => {
    // Whenever a block is selected, do not collapse
    if (props.block) {
      setCollapseOnHover(false);
    }
  }, [props.block]);

  useEffect(() => {
    // Every mouseLeave on the parent is tracked so we know whether to re-enable
    // collapseOnHover. Without this, the user would have to re-enter the Block
    // controller before the collapseOnHover would be properly set.

    const aborter = new AbortController();

    props.mouseLeaveBuster.on('value', (v) => setCollapseOnHover(!!v), {
      signal: aborter.signal,
    });

    return () => {
      aborter.abort();
    };
  }, [props.mouseLeaveBuster, subscribeMouseLeaveBuster]);

  const isRecording = useIsGameRecordModeRecording();
  const recordingDuration = useElapsedRecordingDurationFormatted();

  const recordingBannerHeight = 'h-9'; // 2.25rem
  const maxDrawerHeight = !isRecording ? `h-112` : `h-121`;
  const displayStyle = !collapseOnHover
    ? ''
    : isRecording
    ? 'group-hover:h-19'
    : 'group-hover:h-10';

  return !props.block ? null : (
    <div
      onMouseEnter={() => setCollapseOnHover(false)}
      onMouseLeave={() => setCollapseOnHover(true)}
      className={`
        absolute bottom-0 w-full overflow-hidden
        ${props.visible ? 'flex' : 'hidden'} flex-col
        bg-modal rounded-b-xl
        transition-height duration-500 ease-in-out ${maxDrawerHeight} ${displayStyle}`}
    >
      {!isRecording ? null : (
        <div
          className={`
          bg-lp-red-001 text-white text-sms
          w-full ${recordingBannerHeight} px-2.5
          flex-shrink-0
          flex justify-between items-center
        `}
        >
          <span>Recording {recordingDuration}</span>
          <button
            type='button'
            data-testid='block-recording-banner-cancel-btn'
            onClick={() => props.setShowStopRecordingConfirmation(true)}
          >
            Cancel
          </button>
        </div>
      )}
      {props.children}
    </div>
  );
};
