import isNumber from 'lodash/isNumber';

import { VideoBackground } from '../common/VideoBackgroumd';
import { LunaParkLogo } from '../icons/LogoIcon';
import { CompaniesUsingLunaPark } from '../Marketing/CompaniesUsingLunaPark';

function ProgressBar(props: { percentage: number }) {
  return (
    <div className='w-full h-2 rounded-lg bg-lp-gray-003'>
      <div
        style={{
          width: `${props.percentage.toFixed(2)}%`,
        }}
        className='h-full rounded-lg bg-white'
      ></div>
    </div>
  );
}

export function OnboardingPageLayout(props: {
  children: React.ReactNode;
  className?: string;
  progress?: number;
  withBackground?: boolean;
}) {
  return (
    <div
      className={`relative w-full h-full p-2 flex flex-col overflow-auto scrollbar ${props.className}`}
    >
      {props.withBackground && <VideoBackground />}
      <header className='relative flex-none w-full h-20 flex justify-center items-center px-60'>
        {isNumber(props.progress) && (
          <div className='w-full max-w-140 2xl:max-w-160'>
            <ProgressBar percentage={props.progress} />
          </div>
        )}

        <div className='absolute left-5'>
          <LunaParkLogo className='w-45 h-12.5' />
        </div>
      </header>
      <main
        className={`relative flex-1 mb-20 flex flex-col justify-center items-center`}
      >
        {props.children}
      </main>
    </div>
  );
}

export function OnboardingModalLayout(props: {
  children: React.ReactNode;
  className?: string;
  title?: string;
  subtitle?: string;
}) {
  return (
    <div
      className={`w-140 bg-modal text-white rounded-2.5xl p-8 flex flex-col items-center ${props.className}`}
    >
      {props.children}
    </div>
  );
}

export function OnboardingMenuBox(props: {
  clickable?: boolean;
  children: React.ReactNode;
  className?: string;
}) {
  const { clickable = true, children, className } = props;

  return (
    <label
      className={`w-full bg-black
      rounded-xl border border-lp-gray-003 
      flex items-center justify-between gap-4
      ${clickable ? 'cursor-pointer' : ''}
      ${className}
  `}
    >
      {children}
    </label>
  );
}

export function OnboardingCompanies(props: { className?: string }) {
  return (
    <div className={`w-full flex flex-col items-center ${props.className}`}>
      <p className='text-sms text-icon-gray text-center italic'>
        Hundreds of companies subscribe to Luna Park to elevate their cultures
      </p>

      <CompaniesUsingLunaPark />
    </div>
  );
}
