import { isServer } from '../../../../utils/isServer';
import {
  type TimersWorker,
  timersWorker as inProcessTimersWorker,
} from './TimersWorker';

const enabled = true;

export const timersWorker =
  enabled && process.env.NODE_ENV !== 'test' && !isServer()
    ? new ComlinkWorker<TimersWorker>(
        new URL('./timer.worker.ts', import.meta.url)
      )
    : inProcessTimersWorker;
