import { useSlackAnalytics } from '../../analytics/slack';
import { useLiveAsyncCall } from '../../hooks/useAsyncCall';
import { SlackIcon } from '../icons/SlackIcon';
import { SlackUtils } from './utils';

export function SlackInstallButton({
  text = 'Add to Slack',
  from,
  className,
}: {
  text?: string;
  from?: string;
  className?: string;
}): JSX.Element {
  const analytics = useSlackAnalytics();

  const {
    call: handleClick,
    state: {
      state: { isRunning },
    },
  } = useLiveAsyncCall(async () => {
    analytics.trackSlackInstallButtonClicked({
      pathname: window.location.pathname,
    });

    window.location.href = await SlackUtils.GenerateSlackInstallURL({
      scenario: 'connect',
      redirectTo: from,
    });
  });

  return (
    <button
      className={`${
        className ?? 'btn-primary'
      } flex items-center justify-center px-4 py-2`}
      onClick={handleClick}
      type='button'
      disabled={isRunning}
    >
      <SlackIcon className='w-5 h-5 mr-3' />
      <div>{text}</div>
    </button>
  );
}
