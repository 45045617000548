import { type User } from 'stream-chat';

import { RTDBServerValueTIMESTAMP } from '@lp-lib/firebase-typesafe';
import {
  type GameSessionPlayerData,
  type QuestionBlockAnswerData,
} from '@lp-lib/game';

import logger from '../../../../logger/logger';
import { firebaseService } from '../../../Firebase';
import { gameSessionStore } from '../../store';

const log = logger.scoped('multiple-choice');

// This is intentionally duplicated code that helps on decoupling the code
export const submitQuestionAnswer = async (
  answer: string | null,
  teamId: string | null,
  submitter: Pick<User, 'id'>
) => {
  const {
    venueId,
    session: { blockSession },
    timers: { submission },
    refs,
  } = gameSessionStore;

  const blockId = blockSession?.block?.id ?? null;
  const userId = submitter.id;

  if (!blockId || !userId) return;

  const teamDataRef = refs.teamData;

  if (!teamDataRef) {
    log.error(
      'GameSession.submitAnswer.register',
      new Error('Failed to register teamDataRef')
    );
    return;
  }

  const data = {
    answer,
    timerWhenSubmitted: submission || 0,
  };

  try {
    await teamDataRef
      .child<'data', QuestionBlockAnswerData>('data')
      .update(data);
  } catch (err) {
    log.error('GameSession.submitAnswer.update', err);
  }

  const ref = firebaseService.prefixedRef<GameSessionPlayerData>(
    `game-session-player-data/${venueId}/${blockId}/${userId}`
  );

  const pdata: GameSessionPlayerData = {
    answer,
    submittedAt: RTDBServerValueTIMESTAMP,
    timerWhenSubmitted: submission || 0,
    teamId,
  };

  try {
    await ref.set(pdata);
  } catch (err) {
    log.error('GameSession.submitAnswer.set', err);
  }
};
