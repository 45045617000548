export function HiddenPictureBlockIcon(
  props: React.SVGProps<SVGSVGElement>
): JSX.Element {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      className={props.className ?? 'w-3.5 h-3.5 fill-current'}
      fill='none'
      viewBox='0 0 15 14'
    >
      <path
        d='M13.3006 11.8642L10.3576 8.9177C11.8373 6.99177 11.6894 4.20988 9.91374 2.44856C8.94371 1.47737 7.69419 1 6.42822 1C5.16226 1 3.91273 1.47737 2.94271 2.44856C1.0191 4.37449 1.0191 7.50206 2.94271 9.42798C3.91273 10.3992 5.16226 10.8765 6.42822 10.8765C7.48045 10.8765 8.53268 10.5473 9.40406 9.87243L12.3635 12.8025C12.495 12.9342 12.6594 13 12.8403 13C13.0047 13 13.1855 12.9342 13.317 12.8025C13.5637 12.5556 13.5637 12.1276 13.3006 11.8642ZM6.44466 9.54321C5.47464 9.54321 4.58682 9.16461 3.89629 8.48971C2.4988 7.09054 2.4988 4.80247 3.89629 3.38683C4.57038 2.71193 5.47464 2.33333 6.44466 2.33333C7.41469 2.33333 8.30251 2.71193 8.99303 3.38683C9.68356 4.06173 10.0453 4.96708 10.0453 5.93827C10.0453 6.90947 9.66712 7.79835 8.99303 8.48971C8.31895 9.18107 7.39825 9.54321 6.44466 9.54321Z'
        fill='#39D966'
      />
    </svg>
  );
}
