const DeleteIcon = ({ className }: { className?: string }): JSX.Element => {
  return (
    <svg
      className={className ?? 'w-3.5 h-3.5 fill-current'}
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 14 14'
    >
      <g>
        <path d='M10.684 4.798a.662.662 0 00-.625.672v6.811a.43.43 0 01-.43.43H4.08a.43.43 0 01-.43-.43V5.443a.645.645 0 00-.664-.645.662.662 0 00-.625.672v7.885a.644.644 0 00.646.645h7.698a.644.644 0 00.644-.645V5.443a.644.644 0 00-.664-.645zM12.065 2.506H1.645a.644.644 0 100 1.29h10.42a.644.644 0 000-1.29zM5.512 1.289h2.685a.644.644 0 100-1.289H5.512a.644.644 0 100 1.289z'></path>
        <path d='M6.232 10.856V6.552a.644.644 0 10-1.289 0v4.304a.644.644 0 101.29 0zM8.766 10.856V6.552a.644.644 0 10-1.29 0v4.304a.644.644 0 101.29 0z'></path>
      </g>
    </svg>
  );
};

export { DeleteIcon };
