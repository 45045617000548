import { useCallback, useMemo } from 'react';
import useSWR from 'swr';

import { apiService } from '../../../services/api-service';
import { type MessageTemplate } from '../../../types/messageTemplate';
import { assertExhaustive } from '../../../utils/common';
import { PREDEFINED_MESSAGE_TEMPLATES } from '../types';

export interface useMessageTemplatesReturned {
  templates?: MessageTemplate[];
  isLoading: boolean;
  error: Error;
  refresh: () => void;
}

export function useMessageTemplates(props?: {
  includePredefined?: boolean;
  sortBy?: 'title' | 'createdAtDesc';
}): useMessageTemplatesReturned {
  const { includePredefined, sortBy = 'createdAtDesc' } = props || {};

  const { data, error, isValidating, mutate } = useSWR(
    '/message-templates',
    async () => await apiService.messageTemplate.query().next()
  );

  const templates = useMemo(() => {
    if (!data) return data;

    const results = [...data];
    switch (sortBy) {
      case 'createdAtDesc':
        results.sort((a, b) => b.createdAt.localeCompare(a.createdAt));
        break;
      case 'title':
        results.sort((a, b) => a.title.localeCompare(b.title));
        break;
      default:
        assertExhaustive(sortBy);
    }

    if (includePredefined) {
      results.unshift(...PREDEFINED_MESSAGE_TEMPLATES);
    }
    return results;
  }, [data, includePredefined, sortBy]);

  const refresh = useCallback(() => {
    mutate();
  }, [mutate]);

  return useMemo(
    () => ({
      templates,
      isLoading: !data && isValidating,
      error,
      refresh,
    }),
    [data, error, isValidating, refresh, templates]
  );
}
