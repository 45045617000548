import { useEffect, useMemo, useState } from 'react';

import {
  getTimedScoringFunction,
  getTimedScoringKind,
  type TeamRelayBlock,
} from '@lp-lib/game';

import { type GamePlayEndedState } from '../Common/GamePlay/types';
import { useSharedContext, useTeamRelayGameSettings } from './Context';

export function TeamRelayDebugScore(props: {
  block: TeamRelayBlock;
  timeLeft: number;
  endedState: Nullable<GamePlayEndedState>;
}): JSX.Element | null {
  const { progress } = useSharedContext();
  const { block, timeLeft, endedState } = props;
  const settings = useTeamRelayGameSettings();
  const points = settings?.pointsPerSequence || 0;
  const scoring = useMemo(() => {
    return getTimedScoringFunction(
      getTimedScoringKind(
        block.fields.decreasingPointsTimer,
        block.fields.startDescendingImmediately
      ),
      points,
      block.fields.sequenceTime
    );
  }, [
    block.fields.decreasingPointsTimer,
    block.fields.sequenceTime,
    block.fields.startDescendingImmediately,
    points,
  ]);
  const [sequenceTimeLeft, setSequenceTimeLeft] = useState<number | null>(null);
  const [score, setScore] = useState<number>(points);

  useEffect(() => {
    if (!progress?.initedGameTime) return;
    const t = Math.max(
      Math.round(
        block.fields.sequenceTime - (progress.initedGameTime - timeLeft)
      ),
      0
    );
    const s = scoring.get(t);
    setSequenceTimeLeft(t);
    setScore(s);
    console.log(
      '=================debug team relay score:',
      block.fields.sequenceTime - t,
      s
    );
  }, [block.fields.sequenceTime, progress?.initedGameTime, scoring, timeLeft]);

  if (sequenceTimeLeft === null || endedState) return null;

  const percentage = (
    (1 - sequenceTimeLeft / block.fields.sequenceTime) *
    100
  ).toFixed(0);

  return (
    <div className='bg-black bg-opacity-60 rounded-lg text-3xs px-4 py-2'>
      <p className='text-2xs font-semibold mb-1'>Debug Sequence Score</p>
      <p>Time Left: {sequenceTimeLeft.toFixed(2)}s</p>
      <p>Time Percentage: {percentage}%</p>
      <p>Score: {score}</p>
    </div>
  );
}
