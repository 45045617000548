import {
  type HiddenPicture,
  type HiddenPictureBlock,
  HotSpotShape,
  type HotSpotShapeData,
  type HotSpotV2,
} from '@lp-lib/game';
import { type Media } from '@lp-lib/media';

import logger from '../../../../logger/logger';
import { type TeamId } from '../../../../types';
import { ondTemporaryStageMedia } from '../../ondTemporaryStage';
import { type FoundHotSpots } from './types';

export const log = logger.scoped('hidden-picture');

export const MIN_BOX_RATIO = 0.05;
export const ASPECT_RATIO = 16 / 9;

const minY = MIN_BOX_RATIO;
const maxY = 1 - minY;
const minX = MIN_BOX_RATIO / ASPECT_RATIO;
const maxX = 1 - minX;

export type Point = { x: number; y: number };
export type CoordinateSystem = { maxX: number; maxY: number };

export class HiddenPictureUtils {
  static FromUnitGridHotSpot(
    hotSpot: HotSpotV2,
    toCoordinateSystem: CoordinateSystem
  ): HotSpotV2 {
    const { shape, shapeData } = hotSpot;
    let newShapeData: HotSpotShapeData;

    switch (shape) {
      case HotSpotShape.Circle: {
        const circleData = shapeData.circle;
        if (!circleData) throw new Error('Circle data is missing in shapeData');
        newShapeData = {
          circle: {
            top: circleData.top * toCoordinateSystem.maxY,
            left: circleData.left * toCoordinateSystem.maxX,
            radius: circleData.radius * toCoordinateSystem.maxY,
          },
        };
        break;
      }
      case HotSpotShape.Rectangle: {
        const rectangleData = shapeData.rectangle;
        if (!rectangleData)
          throw new Error('Rectangle data is missing in shapeData');
        newShapeData = {
          rectangle: {
            top: rectangleData.top * toCoordinateSystem.maxY,
            left: rectangleData.left * toCoordinateSystem.maxX,
            width: rectangleData.width * toCoordinateSystem.maxX,
            height: rectangleData.height * toCoordinateSystem.maxY,
          },
        };
        break;
      }
      default:
        throw new Error(`Unsupported shape: ${shape}`);
    }

    return {
      ...hotSpot,
      shapeData: newShapeData,
    };
  }

  static ToUnitGridHotSpot(
    hotSpot: HotSpotV2,
    fromCoordinateSystem: CoordinateSystem
  ): HotSpotV2 {
    const { shape, shapeData } = hotSpot;
    let newShapeData: HotSpotShapeData;

    switch (shape) {
      case HotSpotShape.Circle: {
        const circleData = shapeData.circle;
        if (!circleData) throw new Error('Circle data is missing in shapeData');
        newShapeData = {
          circle: {
            top: circleData.top / fromCoordinateSystem.maxY,
            left: circleData.left / fromCoordinateSystem.maxX,
            radius: circleData.radius / fromCoordinateSystem.maxY,
          },
        };
        break;
      }
      case HotSpotShape.Rectangle: {
        const rectangleData = shapeData.rectangle;
        if (!rectangleData)
          throw new Error('Rectangle data is missing in shapeData');
        newShapeData = {
          rectangle: {
            top: rectangleData.top / fromCoordinateSystem.maxY,
            left: rectangleData.left / fromCoordinateSystem.maxX,
            width: rectangleData.width / fromCoordinateSystem.maxX,
            height: rectangleData.height / fromCoordinateSystem.maxY,
          },
        };
        break;
      }
      default:
        throw new Error(`Unsupported shape: ${shape}`);
    }

    return {
      ...hotSpot,
      shapeData: newShapeData,
    };
  }

  static ToUnitGrid(
    point: Point,
    fromCoordinateSystem: CoordinateSystem
  ): Point {
    return {
      x: point.x / fromCoordinateSystem.maxX,
      y: point.y / fromCoordinateSystem.maxY,
    };
  }

  static Bound(hotSpot: HotSpotV2): HotSpotV2 {
    const { shape, shapeData } = hotSpot;

    let newShapeData: HotSpotShapeData;
    switch (shape) {
      case HotSpotShape.Circle: {
        const circleData = shapeData.circle;
        if (!circleData) throw new Error('Circle data is missing in shapeData');
        newShapeData = {
          circle: circleData,
        };
        // we need the 16/9 ratio to be maintained when examining the horizontal bounds
        if (
          circleData.left + (circleData.radius / ASPECT_RATIO) * 2 < minX ||
          circleData.top + circleData.radius * 2 < minY
        ) {
          circleData.left = Math.max(
            circleData.left,
            minX - (circleData.radius / ASPECT_RATIO) * 2
          );
          circleData.top = Math.max(
            circleData.top,
            minY - circleData.radius * 2
          );
        }

        if (circleData.left > maxX || circleData.top > maxY) {
          circleData.left = Math.min(circleData.left, maxX);
          circleData.top = Math.min(circleData.top, maxY);
        }
        break;
      }
      case HotSpotShape.Rectangle: {
        const rect1 = shapeData.rectangle;
        if (!rect1) throw new Error('Rectangle data is missing in shapeData');

        newShapeData = {
          rectangle: rect1,
        };
        // left and top bounds check.
        if (
          rect1.left + rect1.width < minX ||
          rect1.top + rect1.height < minY
        ) {
          rect1.left = Math.max(rect1.left, minX - rect1.width);
          rect1.top = Math.max(rect1.top, minY - rect1.height);
        }

        // right and bottom bounds check.
        if (rect1.left > maxX || rect1.top > maxY) {
          rect1.left = Math.min(rect1.left, maxX);
          rect1.top = Math.min(rect1.top, maxY);
        }
        break;
      }
      default:
        throw new Error(`Unsupported shape: ${shape}`);
    }

    return {
      ...hotSpot,
      shapeData: newShapeData,
    };
  }

  static GetBackgroundMedia(
    block: HiddenPictureBlock,
    fallback = ondTemporaryStageMedia
  ): Media {
    return block.fields.backgroundMedia ?? fallback;
  }

  static RootPath(venueId: string): string {
    return `hidden-picture/${venueId}`;
  }

  static GamePath(venueId: string, blockId: string): string {
    return `${this.RootPath(venueId)}/${blockId}/game`;
  }

  static TeamGamePlayPath(
    venueId: string,
    blockId: string,
    teamId: TeamId,
    kind?: 'pins' | 'signals' | 'toolAssignment' | 'tools'
  ): string {
    return `${this.RootPath(venueId)}/${blockId}/team-gameplay/${teamId}${
      kind ? `/${kind}` : ''
    }`;
  }

  static TeamGamePlayToolPath(
    venueId: string,
    blockId: string,
    teamId: TeamId,
    tool: Omit<HiddenPicture['tool'], 'none'>
  ): string {
    return `${this.TeamGamePlayPath(
      venueId,
      blockId,
      teamId,
      'tools'
    )}/${tool}`;
  }

  static TeamProgressPath(
    venueId: string,
    blockId: string,
    teamId?: TeamId
  ): string {
    return `${this.RootPath(venueId)}/${blockId}/team-progress${
      teamId ? `/${teamId}` : ''
    }`;
  }

  static FindIntersectingHotSpots(
    point: Point,
    hotspots: HotSpotV2[]
  ): HotSpotV2[] {
    // these are all the hotspots that intersect with the point, in order.
    return hotspots.filter((hotspot) => {
      switch (hotspot.shape) {
        case HotSpotShape.Circle: {
          const circle = hotspot.shapeData.circle;
          if (!circle) return false;

          // this is checking intersection with an ellipse since the circle is skewed when mapped to the unit square.
          const centerX = circle.left + circle.radius / ASPECT_RATIO;
          const centerY = circle.top + circle.radius;

          const dx = point.x - centerX;
          const dy = point.y - centerY;
          const normalizedX = dx / (circle.radius / ASPECT_RATIO);
          const normalizedY = dy / circle.radius;

          const distance =
            normalizedX * normalizedX + normalizedY * normalizedY;
          return distance <= 1;
        }
        case HotSpotShape.Rectangle: {
          const rectangle = hotspot.shapeData.rectangle;
          if (!rectangle) return false;

          return (
            point.x >= rectangle.left &&
            point.x <= rectangle.left + rectangle.width &&
            point.y >= rectangle.top &&
            point.y <= rectangle.top + rectangle.height
          );
        }
        default:
          return false;
      }
    });
  }

  static GradeIntersectingHotSpots(
    intersectingHotSpots: HotSpotV2[],
    configuredHotSpots: HotSpotV2[],
    sequenced: boolean,
    foundHotSpotIds: Set<string>
  ): HotSpotV2[] {
    if (!sequenced) {
      // when not sequenced, we just return the intersecting hotspots.
      return intersectingHotSpots;
    }

    const nextHotspots = [];
    for (const i of intersectingHotSpots) {
      // Get the index of the current hotspot
      const idx = configuredHotSpots.findIndex((h) => h.id === i.id);
      // If it's not the first one and the previous one hasn't been found, break
      if (idx > 0 && !foundHotSpotIds.has(configuredHotSpots[idx - 1].id))
        break;

      nextHotspots.push(i);
      // add the current hotspot to the found list, in case we have multiple intersecting hotspots in a sequence
      foundHotSpotIds.add(i.id);
    }

    return nextHotspots;
  }

  static AllFound(
    configuredHotSpots: HotSpotV2[],
    foundHotSpots: FoundHotSpots | null | undefined
  ): boolean {
    if (configuredHotSpots.length === 0) return true;
    if (!foundHotSpots || Object.keys(foundHotSpots).length === 0) return false;

    // note: only count hotspots with positive points
    return configuredHotSpots
      .filter((h) => h.points >= 0)
      .every((h) => foundHotSpots[h.id]);
  }

  static GetProgressableHotSpots(
    configuredHotSpots: HotSpotV2[] | null | undefined
  ): HotSpotV2[] {
    if (!configuredHotSpots) return [];
    return configuredHotSpots.filter((h) => h.points >= 0);
  }

  static CountFoundProgressableHotSpots(
    configuredHotSpots: HotSpotV2[] | null | undefined,
    foundHotSpots: FoundHotSpots | null | undefined
  ): number {
    if (!foundHotSpots || !configuredHotSpots) return 0;
    const progressableHotSpotIds = new Set(
      this.GetProgressableHotSpots(configuredHotSpots).map((h) => h.id)
    );

    return Object.keys(foundHotSpots).filter((id) => {
      return progressableHotSpotIds.has(id);
    }).length;
  }

  static ConvertShape(hotSpot: HotSpotV2, shape: HotSpotShape) {
    const converter = shapeConverters[hotSpot.shape][shape];
    if (!converter) throw new Error(`Unsupported shape conversion: ${shape}`);
    return converter(hotSpot);
  }
}

const shapeConverters = {
  [HotSpotShape.Circle]: {
    [HotSpotShape.Circle]: (hotSpot: HotSpotV2) => hotSpot,
    [HotSpotShape.Rectangle]: (hotSpot: HotSpotV2) => {
      const circle = hotSpot.shapeData.circle;
      if (!circle) throw new Error('Circle data is missing in shapeData');
      const rectangle = {
        top: circle.top,
        left: circle.left,
        width: (circle.radius / ASPECT_RATIO) * 2,
        height: circle.radius * 2,
      };
      return {
        ...hotSpot,
        shape: HotSpotShape.Rectangle,
        shapeData: { rectangle },
      };
    },
  },
  [HotSpotShape.Rectangle]: {
    [HotSpotShape.Circle]: (hotSpot: HotSpotV2) => {
      const rect = hotSpot.shapeData.rectangle;
      if (!rect) throw new Error('Rectangle data is missing in shapeData');
      const circle = {
        top: rect.top,
        left: rect.left,
        radius: rect.height / 2,
      };
      return {
        ...hotSpot,
        shape: HotSpotShape.Circle,
        shapeData: { circle },
      };
    },
    [HotSpotShape.Rectangle]: (hotSpot: HotSpotV2) => hotSpot,
  },
};
