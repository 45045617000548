import useSWR from 'swr';

import { apiService } from '../../../services/api-service';
import { type GamePack } from '../../../types/game';
import { fromDTOGamePack } from '../../../utils/api-dto';

export function useGamePack(gamePackId?: GamePack['id'] | null) {
  return useSWR(
    gamePackId ? [`/api/game-packs/`, gamePackId] : null,
    async ([, id]) => {
      const resp = await apiService.gamePack.getGamePackById(id);
      return fromDTOGamePack(resp.data.gamePack);
    }
  );
}
