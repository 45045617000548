import React, { useContext, useEffect, useLayoutEffect, useState } from 'react';
import { type ReactNode, useMemo } from 'react';
import { useSnapshot } from 'valtio';

import { useLiveCallback } from '../../hooks/useLiveCallback';
import {
  type Participant,
  type ParticipantFlagMap,
  type ParticipantFlags,
  type ParticipantFull,
  type ParticipantMap,
} from '../../types';
import { useClock } from '../Clock';
import { useFirebaseContext } from '../Firebase';
// eslint-disable-next-line no-restricted-imports
import { ParticipantStore } from './ParticipantStore';

type Context = {
  store: ParticipantStore;
};

const context = React.createContext<Context | null>(null);

function usePlayerStoreContext() {
  const ctx = useContext(context);
  if (!ctx) throw new Error('PlayerStoreContext is not in the tree!');
  return ctx;
}

function useV2Store() {
  const ctx = usePlayerStoreContext();
  return ctx.store;
}

export function useMyInstance() {
  const v2Store = useV2Store();
  const [p, set] = useState<null | Participant>(null);
  useEffect(() => v2Store.handles_myInstance((p) => set(p)), [v2Store]);
  return p;
}

export function useMyInstanceGetter() {
  const get = useParticipantsGetter();
  const v2Store = useV2Store();
  return useLiveCallback(() => {
    const clientId = v2Store.state.myClientId;
    const participants = get();
    return clientId ? participants[clientId] ?? null : null;
  });
}

export function useMyTeamId() {
  const v2Store = useV2Store();
  const snap = useSnapshot(v2Store.state) as typeof v2Store.state;
  return v2Store.selectMyTeamId(snap);
}

export function useParticipantByClientId(clientId: Nullable<string>) {
  const v2Store = useV2Store();
  const snap = useSnapshot<ParticipantMap>(v2Store.state.skims);
  return v2Store.selectParticipantByClientId(clientId, snap);
}

export function useHost(excludedDisconnected = true) {
  const v2Store = useV2Store();
  const snap = useSnapshot<ParticipantMap>(v2Store.state.skims);
  return v2Store.selectHost(excludedDisconnected, snap);
}

export function useHostClientId() {
  const v2Store = useV2Store();
  const snap = useSnapshot<ParticipantMap>(v2Store.state.skims);
  return v2Store.selectHostClientId(snap);
}

export function useAllHostClientIds() {
  const v2Store = useV2Store();
  const snap = useSnapshot<ParticipantMap>(v2Store.state.skims);
  return v2Store.selectAllHostClientIds(snap);
}

export function useUpdateParticipant(): (
  clientId: string,
  data: Partial<ParticipantFull>
) => Promise<void> {
  const v2Store = useV2Store();
  return useLiveCallback(
    async (clientId: string, data: Partial<Participant>) => {
      await v2Store.update(clientId, data);
    }
  );
}

export function useLastJoinedParticipantByUserId(
  userId: Nullable<string>,
  excludedDisconnected = true
) {
  const v2Store = useV2Store();
  const snap = useSnapshot<ParticipantMap>(v2Store.state.skims);
  return v2Store.selectLastJoinedParticipantByUserId(
    userId,
    excludedDisconnected,
    snap
  );
}

export function useParticipantByUserId(
  userId: Nullable<string>,
  excludedDisconnected?: boolean
) {
  const v2Store = useV2Store();
  const snap = useSnapshot<ParticipantMap>(v2Store.state.skims);
  return v2Store.selectParticipantByUserId(userId, excludedDisconnected, snap);
}

export function useParticipantsByClientIds(clientIds: string[]) {
  const v2Store = useV2Store();
  const snap = useSnapshot<ParticipantMap>(v2Store.state.skims);
  return v2Store.selectParticipantsByClientIds(clientIds, snap);
}

export function useParticipantByUserIds(
  userIds: string[],
  excludedDisconnected?: boolean
) {
  const v2Store = useV2Store();
  const snap = useSnapshot<ParticipantMap>(v2Store.state.skims);
  return v2Store.selectParticipantByUserIds(
    userIds,
    excludedDisconnected,
    snap
  );
}

export function useIsHeartbeatExpired() {
  const v2Store = useV2Store();
  return useMemo(() => v2Store.isHeartbeatExpired.bind(v2Store), [v2Store]);
}

export function useIsParticipantStoreInited() {
  const v2Store = useV2Store();
  const [v, set] = useState(false);
  useEffect(() => v2Store.handles_inited((v) => set(v ?? false)), [v2Store]);
  return v;
}

export function useParticipantJoin() {
  const v2Store = useV2Store();
  return useMemo(() => v2Store.join.bind(v2Store), [v2Store]);
}

export function useParticipantLeave() {
  const v2Store = useV2Store();
  return useMemo(() => v2Store.leave.bind(v2Store), [v2Store]);
}

export function useInitParticipantStore() {
  const v2Store = useV2Store();
  return useMemo(() => v2Store.init.bind(v2Store), [v2Store]);
}

export function useSyncParticipants() {
  const v2Store = useV2Store();
  return useMemo(() => v2Store.syncFromFirebase.bind(v2Store), [v2Store]);
}

// This should be finally removed after all the v1 code is removed
export function useParticipantsGetter() {
  const v2Store = useV2Store();
  return useLiveCallback(() => {
    return v2Store.state.skims;
  });
}

export function useParticipantsFlagsGetter() {
  const v2Store = useV2Store();
  return useLiveCallback(() => {
    return v2Store.state.flags;
  });
}

export function useParticipantFlagsGetter() {
  const v2Store = useV2Store();
  return useLiveCallback((clientId: Nullable<string>) => {
    return clientId ? v2Store.state.flags[clientId] ?? null : null;
  });
}

export function useParticipants(): ParticipantMap {
  const v2Store = useV2Store();
  const snap = useSnapshot<ParticipantMap>(v2Store.state.skims);
  return snap;
}

export function useParticipantsFlags(_needsFlags = true): ParticipantFlagMap {
  const v2Store = useV2Store();
  const snap = useSnapshot(v2Store.state.flags);
  return snap;
}

export function useParticipantFlags(
  clientId: Nullable<string>
): ParticipantFlags | null {
  const v2Store = useV2Store();
  const snap = useSnapshot(v2Store.state.flags);
  return v2Store.selectParticipantFlagsByClientId(clientId, snap);
}

export function useParticipantFlag<F extends keyof ParticipantFlags>(
  clientId: Nullable<string>,
  name: F
) {
  const v2Store = useV2Store();
  const snap = useSnapshot(v2Store.state.flags);
  return (
    v2Store.selectParticipantFlagsByClientId(clientId, snap)?.[name] ?? null
  );
}

export function useLastJoinedParticipantGetter() {
  const v2Store = useV2Store();
  return useLiveCallback((userId: string, excludedDisconnected?: boolean) => {
    return v2Store.selectLastJoinedParticipantByUserId(
      userId,
      excludedDisconnected
    );
  });
}

export function useUpdateNetworkQuality() {
  // NOTE(drew): temporary no-op
  return useMemo(() => () => void 0, []);
}

export function useParticipantNetworkQuality() {
  // NOTE(drew): temporary no-op
  return null;
}

export function useTrackAgoraNumericUid() {
  // NOTE(jialin): temporary no-op
  return useMemo(() => () => void 0, []);
}

export function useParticipantSkim(
  _clientId: Nullable<string>,
  _name: keyof Participant
) {
  return null;
}

export function PlayerStoreProvider({
  venueId,
  children,
}: {
  venueId: string;
  children?: ReactNode;
}): JSX.Element {
  const { svc, emitter } = useFirebaseContext();
  const clock = useClock();

  const store = useMemo(
    () =>
      new ParticipantStore(venueId, svc, {
        emitter,
        getTime: clock.now,
      }),
    [clock.now, emitter, svc, venueId]
  );

  useLayoutEffect(() => {
    return store.registerDevtools();
  }, [store]);

  const ctx = useMemo(() => ({ store }), [store]);

  return <context.Provider value={ctx}>{children}</context.Provider>;
}
