import { useNavigate } from '@remix-run/react';
import { useEffect, useLayoutEffect } from 'react';
import { proxy } from 'valtio';

import { type ZoomEnv } from '../../../app/components/Zoom/utils';
import config from '../../config';
import { getStaticAssetPath } from '../../utils/assets';
import { createProvider } from '../../utils/createProvider';
import { markSnapshottable, useSnapshot } from '../../utils/valtio';
import { Modal } from '../common/Modal';
import { ModeratorToggle } from '../Device/ModeratorToggle';
import { useVenueAPI } from '../Venue';
import { useZoomClient } from './utils';

const zeroState = markSnapshottable(proxy({ numOfParticipants: 0 }));

const { Provider, useOptionalContext } =
  createProvider<ReturnType<typeof useZoomClient>>('ZoomVenue');

function useSyncVenueDependencies(
  client: ReturnType<typeof useZoomClient>,
  zoomEnv: ZoomEnv | undefined
) {
  const inZoom = !!zoomEnv;
  const navigate = useNavigate();
  const api = useVenueAPI();
  useEffect(() => {
    if (inZoom) {
      api.syncDeps({
        openUrl: (url) => {
          const fullUrl = url.startsWith('/')
            ? `${config.app.baseUrl}${url}`
            : url;
          client.openUrl(fullUrl);
        },
      });
    } else {
      api.syncDeps({
        openUrl: (url) => navigate(url),
      });
    }
  });
}

function InZoom(props: {
  zoomEnv: ZoomEnv | undefined;
  children?: React.ReactNode;
}) {
  const client = useZoomClient(props.zoomEnv?.mock);
  useLayoutEffect(() => {
    client.init();
  }, [client]);
  useSyncVenueDependencies(client, props.zoomEnv);
  return <Provider value={client}>{props.children}</Provider>;
}

export function ZoomVenueProvider(props: {
  zoomEnv: ZoomEnv | undefined;
  children?: React.ReactNode;
}) {
  const inZoom = !!props.zoomEnv;
  if (!inZoom) {
    return <>{props.children}</>;
  }
  return <InZoom {...props} />;
}

export function useZoomNumOfParticipants() {
  const client = useOptionalContext();
  return useSnapshot(client?.state ?? zeroState).numOfParticipants;
}

export function ZoomDeviceCheck(props: { onContinue: () => Promise<void> }) {
  return (
    <Modal borderStyle='gray'>
      <div
        className={`
            flex flex-col items-center
            bg-modal rounded-xl
            text-white relative
            py-10 px-25
            w-full h-full
          `}
      >
        <h1 className='text-xl font-bold'>🎧 Headphones Required</h1>
        <div className='text-sms mt-4 text-center'>
          To use Luna Park on Zoom, it’s required that you wear headphone.{' '}
          <br />
          Not wearing headphones will create an echo for everyone.
        </div>
        <img
          className='mt-5'
          width={380}
          height={205}
          src={getStaticAssetPath('images/zoom-wear-headphone.png')}
          alt='Wear Headphones'
        />
        <button
          type='button'
          className='btn-primary w-40 h-10 font-medium mt-10'
          onClick={props.onContinue}
        >
          🎧 Got it!
        </button>
        <ModeratorToggle />
      </div>
    </Modal>
  );
}
