import { type SWRResponse } from 'swr';
import useSWRImmutable from 'swr/immutable';

import { apiService } from '../../../services/api-service';
import { type SlackInstallation } from '../../../types/slack';

export function useSlackInstallation(): SWRResponse<
  SlackInstallation,
  unknown
> {
  return useSWRImmutable<SlackInstallation, unknown>(
    `/slack/installation`,
    async () => {
      const resp = await apiService.slack.getInstallation();
      return resp.data.installation;
    }
  );
}

export function useCheckSlackScopes(...scopes: string[]) {
  const { data: installation, isLoading } = useSlackInstallation();
  if (scopes.length === 0) return true;
  if (isLoading) return 'unknown';
  if (!installation) return false;
  return scopes.every((scope) => installation.scopes.includes(scope));
}
