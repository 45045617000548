import { useEffect } from 'react';
import { useSnapshot } from 'valtio';

import { safeWindowReload } from '../../logger/logger';
import { LiteModeManager } from './LiteModeManager';

// Note(drew): Initially this was a context/provider, but determining the actual
// priority order of other contexts that might depend on it was troublesome.
// Since it's basically just a boolean, for now, a global store seems ok.
const manager = new LiteModeManager();

export function useLiteModeToggle(): LiteModeManager['toggle'] {
  return manager.toggle;
}

export function useLiteModeEnabled(): boolean {
  const ext = useSnapshot(manager.listenable());
  return ext.enabled;
}

export function useLiteModeAutoDisable(): void {
  const enabled = useLiteModeEnabled();
  const toggle = useLiteModeToggle();
  useEffect(() => {
    if (enabled) {
      toggle(false, 'reset');
      safeWindowReload();
    }
  }, [enabled, toggle]);
}

export function useLiteModeEnabledRecently(): (recentMs?: number) => boolean {
  return manager.enabledRecently;
}
