import {
  type IRemoteAudioTrack,
  type IRemoteVideoTrack,
  type RemoteAudioTrackStats,
  type RemoteVideoTrackStats,
} from 'agora-rtc-sdk-ng';

import { LogLevel } from '@lp-lib/logger-base';

import { getStaticAssetPath } from '../../utils/assets';
import { uuidv4 } from '../../utils/common';
import { captureStreamFromVideo, releaseMediaStream } from '../../utils/media';
import { CanvasProxy } from '../../utils/videoStreamProxy';
import { getAudioContext } from '../audio/audio-context';
import { CustomRTCService } from './agora';
import {
  type GetAgoraToken,
  type ICustomRTCService,
  type LogEntry,
  type WebRTCTestResult,
} from './types';
import { defaultGetToken } from './utils';

export class LogRecorder {
  readonly logs: LogEntry[] = [];

  log(level: LogLevel, ...args: unknown[]): void {
    let message = '';
    const data = [...args];
    if (args.length > 0) {
      for (const arg of args) {
        if (typeof arg === 'string') {
          message += ` ${arg}`;
          data.shift();
        } else {
          break;
        }
      }
    }
    this.logs.push({
      level,
      message,
      data,
      timestamp: new Date().toISOString(),
    });
  }
}

interface ISender {
  init(): Promise<void>;
  dispose(): Promise<void>;
}

interface IReceiver {
  readonly data: RemoteVideoTrackStats[] | RemoteAudioTrackStats[];
  init(): Promise<void>;
  dispose(): Promise<void>;
  checkKeys(): readonly string[];
}

class VideoSender implements ISender {
  private client: ICustomRTCService;
  private mediaStream: MediaStream | null;
  private disposeProcs: (() => void)[];
  constructor(
    private id: string,
    private channel: string,
    private src: string,
    getToken: GetAgoraToken
  ) {
    this.client = new CustomRTCService({
      uid: `sender:${this.id}`,
      name: this.channel,
      getToken,
    });
    this.mediaStream = null;
    this.disposeProcs = [];
  }

  async init(): Promise<void> {
    const video = document.createElement('video');
    video.crossOrigin = 'anonymous';
    video.muted = true;
    video.preload = 'auto';
    video.src = `${this.src}?x-req=network-test-v2`;
    video.load();
    await video.play();
    this.mediaStream = captureStreamFromVideo(video) || null;
    if (!this.mediaStream) {
      this.mediaStream = this.createFallbackMediaStream(video) || null;
      if (!this.mediaStream) {
        throw new Error('failed to capture/create media stream');
      }
    }
    const track = this.mediaStream.getVideoTracks()[0];
    await this.client.join(this.channel, 'host');
    this.client.switchVideo(track);
    await this.client.publishVideo();
  }

  private createFallbackMediaStream(video: HTMLVideoElement) {
    const vProxy = new CanvasProxy({
      loopMethod: 'raf',
      loopUseWorker: true,
      debug: false,
      useVideoPlaceholder: false,
    });
    vProxy.pipe(video);
    this.disposeProcs.push(() => vProxy.destroy());
    return vProxy.getMediaStream();
  }

  async dispose(): Promise<void> {
    await this.client.close();
    this.disposeProcs.forEach((proc) => proc());
    if (this.mediaStream) releaseMediaStream(this.mediaStream);
  }
}

class AudioSender implements ISender {
  private client: ICustomRTCService;
  private mediaStream: MediaStream | null;
  private disposeProcs: (() => void)[];
  constructor(
    private id: string,
    private channel: string,
    private src: string,
    getToken: GetAgoraToken
  ) {
    this.client = new CustomRTCService({
      uid: `sender:${this.id}`,
      name: this.channel,
      getToken,
    });
    this.mediaStream = null;
    this.disposeProcs = [];
  }

  async init(): Promise<void> {
    const audio = document.createElement('audio');
    audio.crossOrigin = 'anonymous';
    audio.muted = true;
    audio.preload = 'auto';
    audio.src = `${this.src}?x-req=network-test-v2`;
    audio.load();
    await audio.play();
    this.mediaStream = captureStreamFromVideo(audio) || null;
    if (!this.mediaStream) {
      this.mediaStream = this.createFallbackMediaStream(audio);
    }
    const track = this.mediaStream.getAudioTracks()[0];
    await this.client.join(this.channel, 'host');
    this.client.switchAudio(track);
    await this.client.publishAudio();
  }

  private createFallbackMediaStream(audio: HTMLAudioElement) {
    const audioCtx = getAudioContext();
    const msdn = audioCtx.createMediaStreamDestination();
    const gain = audioCtx.createGain();
    gain.connect(msdn);
    const node = audioCtx.createMediaElementSource(audio);
    node.connect(gain);
    gain.gain.value = 0;

    this.disposeProcs.push(() => {
      node.disconnect();
      gain.disconnect();
      msdn.disconnect();
    });

    return msdn.stream;
  }

  async dispose(): Promise<void> {
    await this.client.close();
    this.disposeProcs.forEach((proc) => proc());
    if (this.mediaStream) releaseMediaStream(this.mediaStream);
  }
}

class VideoReceiver implements IReceiver {
  private client: ICustomRTCService;
  private track: IRemoteVideoTrack | null;
  private timerId: ReturnType<typeof setInterval> | null;
  readonly data: RemoteVideoTrackStats[];
  constructor(
    private id: string,
    private channel: string,
    getToken: GetAgoraToken
  ) {
    this.client = new CustomRTCService({
      uid: `receiver:${this.id}`,
      name: this.channel,
      getToken,
    });
    this.track = null;
    this.timerId = null;
    this.data = [];
  }

  async init(): Promise<void> {
    this.startStatCollector();
    await this.client.join(this.channel, 'audience');
    this.client.on('remote-user-published', (uid) => {
      const t = this.client.getTracksByUid(uid)[1];
      if (!t) return;
      this.track = t as IRemoteVideoTrack;
    });
    this.client.subscribeEvents();
  }

  private startStatCollector(): void {
    this.stopStatCollector();
    this.timerId = setInterval(() => {
      if (!this.track) return;
      this.data.push(this.track.getStats());
    }, 1000);
  }

  private stopStatCollector(): void {
    if (this.timerId) {
      clearInterval(this.timerId);
      this.timerId = null;
    }
  }

  async dispose(): Promise<void> {
    this.stopStatCollector();
    await this.client.close();
  }

  checkKeys(): readonly string[] {
    return [
      'receiveBitrate',
      'receiveBytes',
      'receiveFrameRate',
      'receiveResolutionWidth',
    ] as const;
  }
}

class AudioReceiver implements IReceiver {
  private client: ICustomRTCService;
  private track: IRemoteAudioTrack | null;
  private timerId: ReturnType<typeof setInterval> | null;
  readonly data: RemoteAudioTrackStats[];
  constructor(
    private id: string,
    private channel: string,
    getToken: GetAgoraToken
  ) {
    this.client = new CustomRTCService({
      uid: `receiver:${this.id}`,
      name: this.channel,
      getToken,
    });
    this.track = null;
    this.timerId = null;
    this.data = [];
  }

  async init(): Promise<void> {
    this.startStatCollector();
    await this.client.join(this.channel, 'audience');
    this.client.on('remote-user-published', (uid) => {
      const t = this.client.getTracksByUid(uid)[0];
      if (!t) return;
      this.track = t as IRemoteAudioTrack;
    });
    this.client.subscribeEvents();
  }

  private startStatCollector(): void {
    this.stopStatCollector();
    this.timerId = setInterval(() => {
      if (!this.track) return;
      this.data.push(this.track.getStats());
    }, 1000);
  }

  private stopStatCollector(): void {
    if (this.timerId) {
      clearInterval(this.timerId);
      this.timerId = null;
    }
  }

  async dispose(): Promise<void> {
    this.stopStatCollector();
    await this.client.close();
  }

  checkKeys(): readonly string[] {
    return ['receiveBitrate', 'receiveBytes'] as const;
  }
}

type Options = {
  id: string;
  timeout: number;
  mediaSource: string;
  mediaType: 'audio' | 'video';
  getToken: GetAgoraToken;
  logRecorder: LogRecorder;
  getAudioContext: typeof getAudioContext;
};

export class WebRTCTestRunner {
  private id: string;
  private channelName: string;
  private logRecorder: LogRecorder;
  private options: Options;
  private sender: ISender;
  private receiver: IReceiver;
  private timers: ReturnType<typeof setTimeout>[];
  constructor(options?: Partial<Options>) {
    this.options = {
      id: uuidv4(),
      timeout: 30000,
      mediaSource: getStaticAssetPath('videos/BigBuckBunny.mp4'),
      mediaType: 'video',
      getToken: defaultGetToken,
      getAudioContext: getAudioContext,
      logRecorder: new LogRecorder(),
      ...options,
    };
    this.id = this.options.id;
    this.channelName = `network-test:${this.id}`;
    this.logRecorder = this.options.logRecorder;
    if (this.options.mediaType === 'video') {
      this.sender = new VideoSender(
        this.id,
        this.channelName,
        this.options.mediaSource,
        this.options.getToken
      );
      this.receiver = new VideoReceiver(
        this.id,
        this.channelName,
        this.options.getToken
      );
    } else {
      this.sender = new AudioSender(
        this.id,
        this.channelName,
        this.options.mediaSource,
        this.options.getToken
      );
      this.receiver = new AudioReceiver(
        this.id,
        this.channelName,
        this.options.getToken
      );
    }
    this.timers = [];
  }

  private async runWithTimeout(): Promise<void> {
    const promise = new Promise<void>(async (resolve) => {
      await this.receiver.init();
      await this.sender.init();
      const id = setInterval(() => {
        if (this.receiver.data.length >= 10) {
          clearInterval(id);
          resolve();
        }
      }, 1000);
      this.timers.push(id);
    });
    const timeout = new Promise<void>((_, reject) => {
      const id = setTimeout(() => {
        clearTimeout(id);
        reject(`'timeout in ${this.options.timeout} ms`);
      }, this.options.timeout);
      this.timers.push(id);
    });
    return Promise.race<void>([promise, timeout]);
  }

  async run(): Promise<WebRTCTestResult> {
    const result = {
      logs: this.logRecorder.logs,
      data: this.receiver.data,
      channelName: this.channelName,
    };
    const startedAt = new Date().toISOString();
    try {
      this.logRecorder.log(LogLevel.Info, 'start connection test', {
        channelName: this.channelName,
      });
      await this.runWithTimeout();
      const entries = this.receiver.data.slice(-5);
      const keys = this.receiver.checkKeys();
      let totalChecks = 0;
      let passChecks = 0;
      for (const entry of entries) {
        for (const key of keys) {
          totalChecks += 1;
          const val = entry[key as keyof typeof entry];
          if (val) {
            passChecks += 1;
          }
        }
      }
      const endedAt = new Date().toISOString();
      if (totalChecks === 0 || passChecks / totalChecks < 0.5) {
        this.logRecorder.log(LogLevel.Warning, 'abnomal stats', {
          entries,
          checkKeys: keys,
        });
        return {
          id: this.id,
          succeeded: false,
          startedAt,
          endedAt,
          ...result,
        };
      }
      return {
        id: this.id,
        succeeded: true,
        startedAt,
        endedAt,
        ...result,
      };
    } catch (error) {
      this.logRecorder.log(LogLevel.Error, 'detect failed', { error });
      return {
        id: this.id,
        succeeded: false,
        startedAt,
        endedAt: new Date().toISOString(),
        ...result,
      };
    } finally {
      this.dispose();
    }
  }

  async dispose(): Promise<void> {
    await this.sender.dispose();
    await this.receiver.dispose();
    this.timers.forEach((id) => clearTimeout(id));
    this.timers.length = 0;
  }
}
