const UpDownArrowsIcon = ({
  className,
}: {
  className?: string;
}): JSX.Element => {
  return (
    <svg
      className={className ?? 'w-3.5 h-3.5 fill-current'}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path d='M4.16922 10.3516L4.16922 4.27274L4.83844 4.96434C4.95972 5.09044 5.12774 5.1647 5.30554 5.17078C5.48334 5.17686 5.65634 5.11426 5.7865 4.99675C5.91666 4.87925 5.99331 4.71646 5.99958 4.54421C6.00586 4.37195 5.94125 4.20433 5.81996 4.07823L3.99076 2.19796C3.92648 2.13523 3.85003 2.08544 3.7658 2.05147C3.68157 2.01749 3.59124 2 3.5 2C3.40876 2 3.31843 2.01749 3.2342 2.05147C3.14997 2.08544 3.07352 2.13523 3.00924 2.19796L1.18004 4.07823C1.05875 4.20433 0.994142 4.37195 1.00042 4.54421C1.00669 4.71646 1.08334 4.87925 1.2135 4.99675C1.34366 5.11426 1.51666 5.17686 1.69446 5.17078C1.87226 5.1647 2.04028 5.09044 2.16156 4.96434L2.83078 4.27274L2.83078 10.3516C2.83078 10.5236 2.90129 10.6885 3.02679 10.8101C3.15229 10.9317 3.32251 11 3.5 11C3.67749 11 3.84771 10.9317 3.97321 10.8101C4.09871 10.6885 4.16922 10.5236 4.16922 10.3516Z' />
      <path d='M12.82 9.92177C12.88 9.85933 12.9268 9.78604 12.9576 9.70609C12.9884 9.62614 13.0027 9.54109 12.9996 9.45579C12.9965 9.3705 12.9761 9.28664 12.9395 9.20899C12.9029 9.13134 12.851 9.06143 12.7865 9.00325C12.7221 8.94506 12.6464 8.89975 12.5639 8.86989C12.4814 8.84003 12.3936 8.82621 12.3055 8.82922C12.2175 8.83223 12.1309 8.85201 12.0508 8.88744C11.9707 8.92286 11.8985 8.97322 11.8384 9.03566L11.1692 9.72726L11.1692 3.64837C11.1692 3.47641 11.0987 3.3115 10.9732 3.1899C10.8477 3.06831 10.6775 3 10.5 3C10.3225 3 10.1523 3.06831 10.0268 3.1899C9.90129 3.3115 9.83078 3.47641 9.83078 3.64837L9.83078 9.72726L9.16156 9.03566C9.04028 8.90956 8.87226 8.8353 8.69446 8.82922C8.51666 8.82314 8.34366 8.88574 8.2135 9.00325C8.08334 9.12075 8.00669 9.28354 8.00042 9.45579C7.99414 9.62805 8.05875 9.79567 8.18004 9.92177L10.0092 11.802C10.0735 11.8648 10.15 11.9146 10.2342 11.9485C10.3184 11.9825 10.4088 12 10.5 12C10.5912 12 10.6816 11.9825 10.7658 11.9485C10.85 11.9146 10.9265 11.8648 10.9908 11.802L12.82 9.92177Z' />
    </svg>
  );
};

export { UpDownArrowsIcon };
