import { useMemo } from 'react';

import {
  type TitleBlockV2,
  type TitleBlockV2GameSessionStatus,
  type TitleCard,
} from '@lp-lib/game';

import { TitleV2Utils } from './utils';

export interface TitleBlockV2State {
  cardsCount: number;
  currentCardIndex: number | null;
  currentCard: TitleCard | null;
  nextCardIndex: number | null;
  nextCard: TitleCard | null;
}

export function useTitleBlockV2State(
  gameSessionBlock: TitleBlockV2,
  gameSessionStatus: TitleBlockV2GameSessionStatus | null | undefined
): TitleBlockV2State {
  const validCards = TitleV2Utils.GetValidCards(gameSessionBlock);
  const cardsCount = validCards.length;
  const currentCardIndex =
    TitleV2Utils.GameSessionStatusToCardIndex(gameSessionStatus);
  const currentCard = TitleV2Utils.GetCard(validCards, currentCardIndex);
  const nextCardIndex = TitleV2Utils.GetNextCardIndex(
    validCards,
    currentCardIndex
  );
  const nextCard = TitleV2Utils.GetCard(validCards, nextCardIndex);

  return useMemo(
    () => ({
      cardsCount,
      currentCardIndex,
      currentCard,
      nextCardIndex,
      nextCard,
    }),
    [cardsCount, currentCard, currentCardIndex, nextCard, nextCardIndex]
  );
}
