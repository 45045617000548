import { type ReactNode } from 'react';

import { type TeamId } from '../../../../../types/team';
import { useMyTeamId } from '../../../../Player';
import { type TeamScore, useRankedTeamScores } from '../hooks';

export function TeamScoreRow(props: {
  score: TeamScore;
  teamNameWidth?: string;
  children?: (teamId: TeamId) => JSX.Element;
  rightColumn?: {
    header: JSX.Element;
    body: (teamId: TeamId, score: TeamScore) => JSX.Element;
  };
}): JSX.Element {
  const { score, teamNameWidth, children, rightColumn } = props;
  const myTeamId = useMyTeamId();
  const isMyTeam = myTeamId === score.team.id;

  const colorPalette = isMyTeam
    ? children
      ? {
          containerBgColor: 'bg-lp-yellow-001',
          textColor: 'text-black',
        }
      : {
          containerBgColor: 'bg-lp-black-002',
          textColor: 'text-tertiary',
        }
    : {
        containerBgColor: 'bg-lp-black-002',
        textColor: 'text-white',
      };

  return (
    <div
      className={`flex items-center justify-between h-8 px-4 font-bold text-2xs ${colorPalette.containerBgColor} ${colorPalette.textColor} relative mb-1 last:mb-0`}
    >
      <div
        className={`${teamNameWidth ? teamNameWidth : 'w-2/3'} truncate z-5`}
      >
        {isMyTeam ? 'Your Team' : score.team.name}
      </div>
      {rightColumn ? (
        rightColumn.body(score.team.id, score)
      ) : (
        <>
          <div className='z-5'>{score.currentScore}</div>
          {children && children(score.team.id)}
        </>
      )}
    </div>
  );
}

/**
 *
 * @param props
 * @param props.header - customize header
 * @param props.children - customize visualization for each team
 * @returns
 */
export function Leaderboard(props: {
  header?: ReactNode | string;
  teamNameWidth?: string;
  children?: (teamId: TeamId) => JSX.Element;
  rightColumn?: {
    header: JSX.Element;
    body: (teamId: TeamId, score: TeamScore) => JSX.Element;
  };
}): JSX.Element | null {
  const teamScores = useRankedTeamScores('currentScore', null, 'always');
  const isStringHeader = typeof props.header === 'string';

  return (
    <div className='w-full h-full relative'>
      {props.header && !isStringHeader ? (
        props.header
      ) : (
        <header
          className='absolute text-2xl font-cairo font-bold italic uppercase -top-5 left-4'
          style={{
            textShadow: '4px 3px 0px rgba(0, 0, 0, 0.25)',
          }}
        >
          {props.header ?? 'LEADERBOARD'}
        </header>
      )}
      <div className='w-full h-full overflow-y-auto text-white rounded border border-white border-opacity-50 game-play-leaderboard scrollbar'>
        <div className='flex items-center justify-between h-10 px-4 font-medium text-2xs'>
          <div>Team</div>
          {props.rightColumn ? props.rightColumn.header : <div>Points</div>}
        </div>
        {teamScores.map((s) => (
          <TeamScoreRow
            key={s.team.id}
            score={s}
            teamNameWidth={props.teamNameWidth}
            children={props.children}
            rightColumn={props.rightColumn}
          />
        ))}
      </div>
    </div>
  );
}
