import { useLiveCallback } from '../../hooks/useLiveCallback';
import { useMyClientId } from '../Venue';
import { useParticipantFlag, useUpdateParticipant } from './exposed';

export function useSpectatorFlag() {
  const clientId = useMyClientId();
  const spectator = useParticipantFlag(clientId, 'spectator');
  const updateParticipant = useUpdateParticipant();
  const updateSpectator = useLiveCallback((v: boolean) => {
    updateParticipant(clientId, { spectator: v });
  });
  return [spectator, updateSpectator] as const;
}
