/* eslint-disable @lp-lib/eslint-rules/encapsulated-redux */
import { useEffect } from 'react';
import { usePrevious } from 'react-use';

import { useNotificationDataSourceRedux } from '../components/Notification/Context';
import { useMyTeamId } from '../components/Player';
import { useLiveCallback } from '../hooks/useLiveCallback';
import { NotificationType } from '../types/notification';
import { type TeamInvitation } from '../types/team';
import { useAppDispatch, useAppSelector } from './hooks';
// eslint-disable-next-line no-restricted-imports
import {
  resetTeamInvites,
  selectTeamInvitationByClientId,
  sendTeamInvite,
} from './teamInviteSlice';

export function useTeamInvitationByClientId(
  clientId: string | null | undefined
): TeamInvitation | undefined {
  return useAppSelector((state) =>
    selectTeamInvitationByClientId(state, clientId)
  );
}

export function useCleanupNotificationAfterSwitchTeam(): void {
  const dispatch = useAppDispatch();
  const notificationDataSource = useNotificationDataSourceRedux();
  const currTeamId = useMyTeamId();
  const prevTeamId = usePrevious(currTeamId);

  useEffect(() => {
    if (prevTeamId !== currTeamId) {
      dispatch(resetTeamInvites());
      // team changed, and the participant joined a team,
      // clean the pending notifications
      if (currTeamId) {
        notificationDataSource.dismissByType(NotificationType.TeamInvite);
      }
    }
  }, [dispatch, prevTeamId, notificationDataSource, currTeamId]);
}

export function useSendTeamInvite() {
  const dispatch = useAppDispatch();
  return useLiveCallback((...args: Parameters<typeof sendTeamInvite>) => {
    return dispatch(sendTeamInvite(...args));
  });
}
