import 'firebase/database';

import { proxy, ref } from 'valtio';
import { devtools } from 'valtio/utils';

import {
  type Block,
  type CreativePromptBlockAnswerData,
  type GameSessionDetailScore,
  type GameSessionPlayerData,
  type GameSessionScoreSummary,
  type GameSessionStatus,
  type QuestionBlockAnswerData,
  type RapidBlockAnswersData,
  type TeamDataList,
} from '@lp-lib/game';

import { type AbortSignalableRunner } from '../../../utils/AbortSignalableRunner';
import { type Emitter } from '../../../utils/emitter';
import { type NarrowedWebDatabaseReference } from '../../Firebase/types';
import { type VideoMixer } from '../../VideoMixer';
import { BlockToVideoMixerTrackMap } from '../OndPhaseRunner/BlockToVideoMixerTrackMap';
import {
  OndPhaseEmitterUtils,
  type OndPhaseEvents,
} from '../OndPhaseRunner/ond-phase-emitter';
import { type BlockPlaybackPlan } from '../OndPhaseRunner/ond-timing';
import { type OndPhaseContext } from '../OndPhaseRunner/OndPhaseRunner';
import {
  type GameSessionOndWaitModeDerivedData,
  type GameSessionOndWaitModeInfo,
  type OndPlaybackJump,
  type OndWaitModeExtSignal,
} from '../OndPhaseRunner/OndPhaseRunnerTypes';
import {
  type PlaybackDesc,
  type PlaybackItemId,
} from '../Playback/intoPlayback';
import { GameSessionActionsSignalManager } from './GameSessionActionsSignalManager';
import { SessionStatusHookManager } from './gameSessionStatusHook';

export interface EditPointData {
  teamName: string;
  teamId: string;
  submitterUid: string;
  defaultPoints: number;
  answer?: string;
}

export type SetEditPointsData = (data: EditPointData | null) => void;
export type SetGradingCardTeamId = (teamId: string) => void;

export type VideoPlaybackState =
  | 'loaded'
  | 'canplay'
  | 'playing'
  | 'ended'
  | 'waiting'
  | 'paused';

// Convention: these types are const arrays to allow for iteration and presence
// at runtime. The runtime version is plural, the `type` version is singular.

const OndGamePlayCoordinatableStates = [
  'running',
  'paused',
  'preparing',
  'ended',
] as const;

const OndGamePlayStates = [
  ...OndGamePlayCoordinatableStates,
  'resuming',
] as const;

export type OndGamePlayCoordinatableState =
  (typeof OndGamePlayCoordinatableStates)[number];

export type OndGamePlayState = (typeof OndGamePlayStates)[number];

export type GamePlayState = OndGamePlayState;

export interface VideoPlayback {
  mediaId: string;
  state: VideoPlaybackState;
  playBackgroundMusicWithMedia: boolean;
  updatedAt: number;
  fullscreen: boolean;
}

export interface RandomizerBlockSessionData {
  teamAssignment?: string;
  teamSize?: number;
  maxTeamSize?: number;
}

export type BlockSessionData = RandomizerBlockSessionData;

export interface BlockSession {
  block: Block | null;
  data?: BlockSessionData;
  videoReplayAt?: number | null;
  videoPlayback?: VideoPlayback | null;
}

// game-session/{venueId}
export interface GameSession {
  gamePackId: string | null;
  name: string | null;
  isLive: boolean;
  isRecording: boolean;
  blockSession: BlockSession | null;
  status: GameSessionStatus;
  statusChangedAt: number; // default to -1
  blockTitleTransition: null | {
    state: 'running' | 'ended';
    durationMs: number;
    extra?: {
      title: string;
      displaysPointsMultiplier: '2x' | '3x' | null;
    };
  };
  allTeamsFinishedTransition: null | {
    state: 'running' | 'ended';
    durationMs: number;
  };
}

// game-session-state/{venueId}
export interface GameSessionOndState {
  receivedInitial: boolean;
  state: OndGamePlayState;

  // Readonly, synced via firebase. Owned/updated by the OndPhaseRunner ONLY. If
  // present, indicates "wait mode is active". `GameSessionOndWaitModeInfo` is
  // present in Firebase/here only because it is required when resuming and
  // because other consumers may wish to know the value. The logical source of
  // truth is actually within the OndPhaseRunner at runtime, and it is then
  // written out per tick.
  waitModeInfo?: Readonly<
    GameSessionOndWaitModeInfo & {
      derived: GameSessionOndWaitModeDerivedData;
    }
  > | null;

  // Writable/readable by external callers. The OndPhaseRunner will read during
  // its tick and (possibly) react.
  waitModeExtSignal?: OndWaitModeExtSignal | null;
  sessionProgressSec?: number;
  resumingProgress?: number;
  blockProgressSec?: number;
  blockEndingSec?: number;
  blockRemainderMs?: number;
  blockBrandName?: string | null;
  blockBrandHasHostedTutorial?: boolean;
  gamePlayVideoProgress?: number | null;
  currentPlaybackItemId?: PlaybackItemId | null;
  jump?: OndPlaybackJump | null;
  immediateBlockEndExtSignal?: boolean;
  playbackVersion?: number | null;
  preparedContextReady?: boolean | null;
  preparedPlayback?: PlaybackDesc | null;
  shouldAwaitContextPreparation?: boolean;
  resumePlaybackItemId?: PlaybackItemId | null;
}

// game-session-controls/{venueId}
export interface GameSessionControls {
  endedBlockIds: string | null;
  isScoreboardShowed: boolean;
}

// game-session-team-data/{venueId}/{blockId}/{myTeamId}
export interface GameSessionTeamData<T> {
  data: T;
}

// Note(jialin): if you add a new firebase entry of game session,
// Remember to update the backend cleanup logic at api-service/services/firebase/session.go
export interface FirebaseRefs {
  // game-session/{venueId}
  session: NarrowedWebDatabaseReference<GameSession> | null;
  // game-session-state/{venueId}
  ondState: NarrowedWebDatabaseReference<GameSessionOndState> | null;
  // game-session-controls/{venueId}
  controls: NarrowedWebDatabaseReference<GameSessionControls> | null;
  // game-session-scores/{venueId}/{blockId}/{teamId}
  detailScores: NarrowedWebDatabaseReference<GameSessionDetailScore> | null;
  // game-session-score-summary/{venueId}/{teamId}
  scoreSummary: NarrowedWebDatabaseReference<
    TeamDataList<GameSessionScoreSummary>
  > | null;
  // game-session-team-data/{venueId}/{blockId}/{teamId}
  teamData: NarrowedWebDatabaseReference<
    GameSessionTeamData<
      | RapidBlockAnswersData
      | QuestionBlockAnswerData
      | CreativePromptBlockAnswerData
    >
  > | null;
  // game-session-player-data/{venueId}/{blockId}/{userId}
  playerData: NarrowedWebDatabaseReference<GameSessionPlayerData> | null;
}

export interface LocalTimers {
  submission: number;
}

export interface VideoMixerRefs {
  ondHostVideo: VideoMixer | null;
}

export interface GameSessionStore {
  initialized: boolean;
  venueId: string | null;
  isController: boolean;
  session: GameSession;
  ondState: GameSessionOndState | null;
  controls: GameSessionControls;
  detailScores: GameSessionDetailScore | null;
  scoreSummary: TeamDataList<GameSessionScoreSummary> | null;
  teamData: GameSessionTeamData<
    | RapidBlockAnswersData
    | QuestionBlockAnswerData
    | CreativePromptBlockAnswerData
  > | null;
  timers: LocalTimers;
  refs: FirebaseRefs;
  refsInitialReceived: { [K in keyof FirebaseRefs]: boolean };
  videoMixers: VideoMixerRefs;
  // TODO: move this to phaserunner? but it needs to persist beyond lifecycle in
  // order to soft-resume.
  ondHostVideoMixerTrackIds: BlockToVideoMixerTrackMap;
  // TODO: move this to phaserunner? but it needs to persist beyond lifecycle in
  // order to soft-resume.
  ondBlockPlaybackPlan: BlockPlaybackPlan | null;
  ondGameRunner: AbortSignalableRunner | null;
  ondPreparedContext: OndPhaseContext | null;
  ondPhaseEmitter: Emitter<OndPhaseEvents>;
  gameSessionActionsSignalManager: GameSessionActionsSignalManager;
  sessionStatusHookManager: SessionStatusHookManager;
  terminateSession?: () => Promise<void>;
}

const initialRefs = ref({
  session: null,
  ondState: null,
  controls: null,
  detailScores: null,
  scoreSummary: null,
  teamData: null,
  playerData: null,
});

const initialVideoMixers = {
  ondHostVideo: null,
};

export const initialState: GameSessionStore = {
  initialized: false,
  venueId: null,
  isController: false,
  session: {
    gamePackId: null,
    name: null,
    isLive: false,
    isRecording: false,
    blockSession: null,
    status: null,
    statusChangedAt: -1,
    blockTitleTransition: null,
    allTeamsFinishedTransition: null,
  },
  ondState: null,
  controls: {
    endedBlockIds: null,
    isScoreboardShowed: false,
  },
  detailScores: null,
  scoreSummary: null,
  teamData: null,
  timers: {
    submission: 0,
  },
  refs: initialRefs,
  refsInitialReceived: {
    session: false,
    ondState: false,
    controls: false,
    detailScores: false,
    scoreSummary: false,
    teamData: false,
    playerData: false,
  },
  videoMixers: initialVideoMixers,
  ondHostVideoMixerTrackIds: BlockToVideoMixerTrackMap.CreateVRefed(),
  ondBlockPlaybackPlan: null,
  ondGameRunner: null,
  ondPreparedContext: null,
  ondPhaseEmitter: OndPhaseEmitterUtils.CreateVRefed(),
  sessionStatusHookManager: SessionStatusHookManager.CreateVRefed(),
  gameSessionActionsSignalManager:
    GameSessionActionsSignalManager.CreateVRefed(),
};

export const gameSessionStore = proxy<GameSessionStore>(initialState);

devtools(gameSessionStore, { name: 'Game Session Store' });
