import { createContext, type ReactNode, useContext, useMemo } from 'react';

import { useGlobalEmojisAnimation } from '../EmojiBoard/EmojiAnimation';
import { useGainPointsAnimation } from '../GainPointsAnimation/useGainPointsAnimation';

type PixelFxSceneContext = {
  controls: {
    'gain-points': ReturnType<typeof useGainPointsAnimation>;
    emojis: ReturnType<typeof useGlobalEmojisAnimation>;
  };
};

const context = createContext<PixelFxSceneContext | null>(null);

function usePixelFxScene(): PixelFxSceneContext {
  const ctx = useContext(context);
  if (!ctx) throw new Error('No PixelFx Provider in the tree!');
  return ctx;
}

export function usePixelFxSceneControl<
  N extends keyof PixelFxSceneContext['controls']
>(name: N): PixelFxSceneContext['controls'][N] {
  const ctx = usePixelFxScene();
  return ctx.controls[name];
}

export function PixelFxSceneProvider(props: {
  children?: ReactNode;
}): JSX.Element {
  // Register pixel effects here, in this provider, so their state persists
  // between components.

  const gainPoints = useGainPointsAnimation();
  const emojis = useGlobalEmojisAnimation();

  const ctxValue = useMemo(() => {
    const controls: PixelFxSceneContext['controls'] = {
      'gain-points': gainPoints,
      emojis,
    };

    return {
      controls,
    };
  }, [emojis, gainPoints]);

  return <context.Provider value={ctxValue}>{props.children}</context.Provider>;
}
