import { useEffect, useMemo, useState } from 'react';

import {
  assertExhaustive,
  type DrawingPromptBlock,
  DrawingPromptBlockGameSessionStatus,
} from '@lp-lib/game';

import { BrowserTimeoutCtrl } from '../../../../utils/BrowserTimeoutCtrl';
import { isSuperset } from '../../../../utils/common';
import { DoubleRightArrow } from '../../../icons/Arrows';
import { RefreshIcon } from '../../../icons/RefreshIcon';
import { FilledSquareIcon } from '../../../icons/SquareIcon';
import { Loading } from '../../../Loading';
import {
  useGameSessionLocalTimer,
  useGameSessionStatus,
  useIsEndedBlock,
} from '../../hooks';
import { countdownV2, next, present, triggerBlockTitleAnim } from '../../store';
import {
  BlockControllerActionButton,
  BlockControllerActionTimeRemaining,
  ControllerLayout,
  type ControllerProps,
  type MediaPlayback,
  MediaPreviewer,
  useBlockControllerBlockTitlePlaying,
  useControllerMediaPlayback,
} from '../Common/Controller/Internal';
import { useStableBlock } from '../Common/hooks';
import {
  useAllPickedDrawings,
  useCurrMatch,
  useDrawingGame,
  useDrawingSubmissionProgress,
  useTeamVotePhaseSkippable,
} from './DrawingPromptProvider';
import { DrawingPromptUtils } from './utils';

type SharedProps = ControllerProps<DrawingPromptBlock>;
type StageProps = SharedProps & {
  playback: MediaPlayback;
};

type GamePhase = 'drawing' | 'team-vote' | 'title-creation' | 'match-prompt';

function GameProgressSummary(props: {
  block: DrawingPromptBlock;
  phase?: GamePhase;
}): JSX.Element {
  const { block, phase } = props;
  const phases = useMemo<{ phase: GamePhase; text: string }[]>(() => {
    return [
      {
        phase: 'drawing',
        text: `Drawing Timer: ${block.fields.drawingTimeSec}s`,
      },
      {
        phase: 'team-vote',
        text: 'Vote Timer',
      },
      {
        phase: 'title-creation',
        text: 'Write Prompts Timer',
      },
      {
        phase: 'match-prompt',
        text: 'Pick Prompt Timer',
      },
    ];
  }, [block.fields]);
  return (
    <div className='flex flex-col items-center justify-center gap-1 mt-2'>
      {phases.map((p) => (
        <div
          key={p.phase}
          className={`${phase === p.phase ? 'font-bold' : ''}`}
        >
          {p.text}
        </div>
      ))}
      <div className='mt-4'></div>
      <div>Correct Points: {block.fields.correctPromptPoints}pts/guess</div>
      <div>Incorrect Points: {block.fields.incorrectPromptPoints}pts/guess</div>
    </div>
  );
}

function Ended(props: StageProps): JSX.Element {
  return (
    <ControllerLayout
      action={
        <BlockControllerActionButton
          onClick={() => props.setShowResetConfirmation(true)}
          icon={RefreshIcon}
          isDelete={true}
        >
          Reset Completed Block
        </BlockControllerActionButton>
      }
    ></ControllerLayout>
  );
}

function Loaded(props: StageProps): JSX.Element {
  const { selectedBlock: block, playback } = props;

  const { switchMedia, reset: resetPlayback } = playback.api;

  const [processing, setProcessing] = useState(false);

  useEffect(() => {
    resetPlayback();
    switchMedia(DrawingPromptUtils.GetBackgroundMedia(block));
  }, [block, switchMedia, resetPlayback]);

  const onPresent = async () => {
    setProcessing(true);
    await triggerBlockTitleAnim(block);
    await present(block);
  };

  const actionComponent = useBlockControllerBlockTitlePlaying(block) ?? (
    <BlockControllerActionButton
      onClick={onPresent}
      icon={DoubleRightArrow}
      disable={processing || block.fields.prompts.length === 0}
    >
      {block.fields.prompts.length === 0 ? 'No Prompts' : 'Present Prompt'}
    </BlockControllerActionButton>
  );
  return (
    <ControllerLayout action={actionComponent}>
      <div className='w-full flex flex-col'>
        <MediaPreviewer
          playback={playback}
          alt={block.type}
          text={block.fields.title ?? 'N/A'}
        />
        <GameProgressSummary block={block} />
      </div>
    </ControllerLayout>
  );
}

function Init(props: StageProps): JSX.Element {
  const { selectedBlock: block, playback } = props;

  const [processing, setProcessing] = useState(false);
  const time = useGameSessionLocalTimer();

  const onClick = async () => {
    if (time === 0) return;
    setProcessing(true);
    await next();
    await countdownV2({
      debug: 'DrawingGameBlockGameController',
      startTimeWorker: true,
      flushCountingStatus: true,
      triggerTimesup: true,
    });
  };

  const actionComponent = useBlockControllerBlockTitlePlaying(block) ?? (
    <BlockControllerActionButton
      onClick={onClick}
      icon={DoubleRightArrow}
      disable={processing || time === 0}
    >
      {`Start Drawing Timer (${time}s)`}
    </BlockControllerActionButton>
  );
  return (
    <ControllerLayout action={actionComponent}>
      <div className='w-full flex flex-col'>
        <MediaPreviewer
          playback={playback}
          alt={block.type}
          text={block.fields.title ?? 'N/A'}
        />
        <GameProgressSummary block={block} phase='drawing' />
      </div>
    </ControllerLayout>
  );
}

function DrawingStart(props: StageProps): JSX.Element {
  const { selectedBlock: block, playback } = props;
  const time = useGameSessionLocalTimer();

  return (
    <ControllerLayout
      action={<BlockControllerActionTimeRemaining remainingSec={time} />}
    >
      <div className='w-full flex flex-col'>
        <MediaPreviewer
          playback={playback}
          alt={block.type}
          text={block.fields.title ?? 'N/A'}
        />
        <GameProgressSummary block={block} phase='drawing' />
      </div>
    </ControllerLayout>
  );
}

function DrawingEnd(props: StageProps): JSX.Element {
  const { selectedBlock: block, playback } = props;
  const [processing, setProcessing] = useState(false);
  const time = useGameSessionLocalTimer();
  const { submittedPlayerIds, drawingPlayerIds } =
    useDrawingSubmissionProgress();
  const allSubmitted = useMemo(() => {
    const s1 = new Set(submittedPlayerIds);
    const s2 = new Set(drawingPlayerIds);
    return isSuperset(s1, s2);
  }, [submittedPlayerIds, drawingPlayerIds]);
  const [waitTimeout, setWaitTimeout] = useState(false);
  const { skippable: voteSkippable } = useTeamVotePhaseSkippable();

  // avoid the infinite wait if something is wrong
  useEffect(() => {
    if (allSubmitted) return;
    const ctrl = new BrowserTimeoutCtrl();
    ctrl.set(() => setWaitTimeout(true), 10000);
    return () => ctrl.clear();
  }, [allSubmitted]);

  const waitForSubmission = waitTimeout ? false : !allSubmitted;

  const startVote = async () => {
    if (time === 0) return;
    setProcessing(true);
    await next();
    await countdownV2({
      debug: 'DrawingGameBlockGameController',
      startTimeWorker: true,
      flushCountingStatus: true,
      triggerTimesup: true,
    });
  };

  return (
    <ControllerLayout
      action={
        <BlockControllerActionButton
          onClick={startVote}
          icon={DoubleRightArrow}
          disable={processing || time === 0 || waitForSubmission}
        >
          {}
          {!waitForSubmission ? (
            voteSkippable ? (
              'One drawing per team, Skip Vote'
            ) : (
              `Start Vote Timer (${time}s)`
            )
          ) : (
            <div className='flex justify-center'>
              <Loading
                text=''
                imgClassName='w-3 h-3'
                containerClassName='mr-2'
              />
              <div>
                Waiting for submitting [{submittedPlayerIds.length}/
                {drawingPlayerIds.length}]
              </div>
            </div>
          )}
        </BlockControllerActionButton>
      }
    >
      <div className='w-full flex flex-col'>
        <MediaPreviewer
          playback={playback}
          alt={block.type}
          text={block.fields.title ?? 'N/A'}
        />
        <GameProgressSummary block={block} phase='team-vote' />
      </div>
    </ControllerLayout>
  );
}

function TeamVoteStart(props: StageProps): JSX.Element {
  const { selectedBlock: block, playback } = props;
  const time = useGameSessionLocalTimer();

  return (
    <ControllerLayout
      action={<BlockControllerActionTimeRemaining remainingSec={time} />}
    >
      <div className='w-full flex flex-col'>
        <MediaPreviewer
          playback={playback}
          alt={block.type}
          text={block.fields.title ?? 'N/A'}
        />
        <GameProgressSummary block={block} phase='team-vote' />
      </div>
    </ControllerLayout>
  );
}

function TeamVoteEnd(props: StageProps): JSX.Element {
  const { selectedBlock: block, playback } = props;
  const [processing, setProcessing] = useState(false);
  const time = useGameSessionLocalTimer();

  const startCreateFakePrompts = async () => {
    if (time === 0) return;
    setProcessing(true);
    await next();
    await countdownV2({
      debug: 'DrawingGameBlockGameController',
      startTimeWorker: true,
      flushCountingStatus: true,
      triggerTimesup: true,
    });
  };

  return (
    <ControllerLayout
      action={
        <BlockControllerActionButton
          onClick={startCreateFakePrompts}
          icon={DoubleRightArrow}
          disable={processing || time === 0}
        >
          {`Start Write Timer (${time}s)`}
        </BlockControllerActionButton>
      }
    >
      <div className='w-full flex flex-col'>
        <MediaPreviewer
          playback={playback}
          alt={block.type}
          text={block.fields.title ?? 'N/A'}
        />
        <GameProgressSummary block={block} phase='title-creation' />
      </div>
    </ControllerLayout>
  );
}

function WritePromptsStart(props: StageProps): JSX.Element {
  const { selectedBlock: block, playback } = props;
  const time = useGameSessionLocalTimer();

  return (
    <ControllerLayout
      action={<BlockControllerActionTimeRemaining remainingSec={time} />}
    >
      <div className='w-full flex flex-col'>
        <MediaPreviewer
          playback={playback}
          alt={block.type}
          text={block.fields.title ?? 'N/A'}
        />
        <GameProgressSummary block={block} phase='title-creation' />
      </div>
    </ControllerLayout>
  );
}

function WritePromptsEnd(props: StageProps): JSX.Element {
  const { selectedBlock: block, playback } = props;
  const [processing, setProcessing] = useState(false);

  const onClick = async () => {
    setProcessing(true);
    await next();
  };

  return (
    <ControllerLayout
      action={
        <BlockControllerActionButton
          onClick={onClick}
          icon={DoubleRightArrow}
          disable={processing}
        >
          {`Present Pick Prompt Intro`}
        </BlockControllerActionButton>
      }
    >
      <div className='w-full flex flex-col'>
        <MediaPreviewer
          playback={playback}
          alt={block.type}
          text={block.fields.title ?? 'N/A'}
        />
        <GameProgressSummary block={block} phase='match-prompt' />
      </div>
    </ControllerLayout>
  );
}

function MatchPromptInit(props: StageProps): JSX.Element {
  const { selectedBlock: block, playback } = props;
  const [processing, setProcessing] = useState(false);
  const game = useDrawingGame();
  const time = game?.matchTimePerDrawing ?? 0;
  const total = useAllPickedDrawings().length;

  const onClick = async () => {
    if (time === 0) return;
    setProcessing(true);
    await next();
    // the timer for this phase is quite different. Each drawing has its own
    // timer, and they don't trigger the timesup that moves the game to the
    // next status. The real countdownV2 for this phase is handled by
    // MatchPromptLocalControl in DrawingPromptBlockGamePlay.tsx.
  };

  return (
    <ControllerLayout
      action={
        <BlockControllerActionButton
          onClick={onClick}
          icon={DoubleRightArrow}
          disable={processing || time === 0}
        >
          {`[0/${total}] Start Pick Timer (${time}s)`}
        </BlockControllerActionButton>
      }
    >
      <div className='w-full flex flex-col'>
        <MediaPreviewer
          playback={playback}
          alt={block.type}
          text={block.fields.title ?? 'N/A'}
        />
        <GameProgressSummary block={block} phase='match-prompt' />
      </div>
    </ControllerLayout>
  );
}

function MatchPromptStart(props: StageProps): JSX.Element {
  const { selectedBlock: block, playback } = props;
  const time = useGameSessionLocalTimer();
  const total = useAllPickedDrawings().length;
  const currMatch = useCurrMatch();
  const index = Math.min(currMatch.drawingIndex + 1, total);
  const [processing, setProcessing] = useState(false);

  useEffect(() => {
    if (currMatch.drawingIndex + 1 >= total && currMatch.phase === 'reveal') {
      next();
    }
  }, [currMatch.drawingIndex, currMatch.phase, total]);

  // this is mainly introduced for testing, the block recording test expected a
  // clickable button to move forward.
  const onClick = async () => {
    if (total > 0) return;
    setProcessing(true);
    await next();
  };

  return (
    <ControllerLayout
      action={
        total === 0 ? (
          <BlockControllerActionButton
            onClick={onClick}
            icon={DoubleRightArrow}
            disable={processing}
          >
            {`[${index}/${total}] No Drawings, Skip`}
          </BlockControllerActionButton>
        ) : (
          <BlockControllerActionTimeRemaining
            remainingSec={time}
            customizeLabel={(remainingSec) =>
              `[${index}/${total}] ${
                currMatch.phase === 'vote'
                  ? `Time Remaining (${remainingSec}s)`
                  : 'Reveal Votes'
              }`
            }
            disableSkip
          />
        )
      }
    >
      <div className='w-full flex flex-col'>
        <MediaPreviewer
          playback={playback}
          alt={block.type}
          text={block.fields.title ?? 'N/A'}
        />
        <GameProgressSummary block={block} phase='match-prompt' />
      </div>
    </ControllerLayout>
  );
}

function MatchPromptEnd(props: StageProps): JSX.Element {
  const { selectedBlock: block, playback } = props;
  const [processing, setProcessing] = useState(false);

  const onClick = async () => {
    setProcessing(true);
    await next();
  };

  return (
    <ControllerLayout
      action={
        <BlockControllerActionButton
          onClick={onClick}
          icon={DoubleRightArrow}
          disable={processing}
        >
          Show All Drawings
        </BlockControllerActionButton>
      }
    >
      <div className='w-full flex flex-col'>
        <MediaPreviewer
          playback={playback}
          alt={block.type}
          text={block.fields.title ?? 'N/A'}
        />
        <GameProgressSummary block={block} />
      </div>
    </ControllerLayout>
  );
}

function ReviewAllDrawings(props: StageProps): JSX.Element {
  const { selectedBlock: block, playback } = props;
  const [processing, setProcessing] = useState(false);

  const onClick = async () => {
    setProcessing(true);
    await next();
  };

  return (
    <ControllerLayout
      action={
        <BlockControllerActionButton
          onClick={onClick}
          icon={DoubleRightArrow}
          disable={processing}
        >
          Reveal Results
        </BlockControllerActionButton>
      }
    >
      <div className='w-full flex flex-col'>
        <MediaPreviewer
          playback={playback}
          alt={block.type}
          text={block.fields.title ?? 'N/A'}
        />
        <GameProgressSummary block={block} />
      </div>
    </ControllerLayout>
  );
}

function Results(props: StageProps): JSX.Element {
  const { selectedBlock: block, playback } = props;
  const [processing, setProcessing] = useState(false);
  const onShowScoreboard = async () => {
    setProcessing(true);
    await next();
  };

  return (
    <ControllerLayout
      action={
        <>
          <BlockControllerActionButton
            onClick={onShowScoreboard}
            isSecondary={true}
            disable={processing}
          >
            Show Scoreboard
          </BlockControllerActionButton>
          <BlockControllerActionButton
            onClick={props.onEndBlock}
            icon={FilledSquareIcon}
          >
            End Block Sequence
          </BlockControllerActionButton>
        </>
      }
    >
      <div className='w-full flex flex-col'>
        <MediaPreviewer
          playback={playback}
          alt={block.type}
          text={block.fields.title ?? 'N/A'}
        />
        <GameProgressSummary block={block} />
      </div>
    </ControllerLayout>
  );
}

function Scoreboard(props: StageProps): JSX.Element {
  const { selectedBlock: block, playback } = props;
  return (
    <ControllerLayout
      action={
        <BlockControllerActionButton
          onClick={props.onEndBlock}
          icon={FilledSquareIcon}
        >
          End Block Sequence
        </BlockControllerActionButton>
      }
    >
      <div className='w-full flex flex-col'>
        <MediaPreviewer
          playback={playback}
          alt={block.type}
          text={block.fields.title ?? 'N/A'}
        />
        <GameProgressSummary block={block} />
      </div>
    </ControllerLayout>
  );
}

export function DrawingPromptBlockController(
  props: SharedProps
): JSX.Element | null {
  const { selectedBlockIndex } = props;
  const block = useStableBlock(props.selectedBlock);
  const isEndedBlock = useIsEndedBlock(block.id);
  const gss = useGameSessionStatus<DrawingPromptBlockGameSessionStatus>();
  const playback = useControllerMediaPlayback();

  if (selectedBlockIndex === null) return null;

  if (isEndedBlock) {
    return <Ended {...props} playback={playback} />;
  }

  switch (gss) {
    case DrawingPromptBlockGameSessionStatus.LOADED:
      return <Loaded {...props} playback={playback} />;
    case DrawingPromptBlockGameSessionStatus.INIT:
      return <Init {...props} playback={playback} />;
    case DrawingPromptBlockGameSessionStatus.DRAWING_START:
      return <DrawingStart {...props} playback={playback} />;
    case DrawingPromptBlockGameSessionStatus.DRAWING_END:
      return <DrawingEnd {...props} playback={playback} />;
    case DrawingPromptBlockGameSessionStatus.TEAM_VOTE_COUNTING:
      return <TeamVoteStart {...props} playback={playback} />;
    case DrawingPromptBlockGameSessionStatus.TEAM_VOTE_DONE:
      return <TeamVoteEnd {...props} playback={playback} />;
    case DrawingPromptBlockGameSessionStatus.TITLE_CREATION_COUNTING:
      return <WritePromptsStart {...props} playback={playback} />;
    case DrawingPromptBlockGameSessionStatus.TITLE_CREATION_DONE:
      return <WritePromptsEnd {...props} playback={playback} />;
    case DrawingPromptBlockGameSessionStatus.MATCH_PROMPT_INIT:
      return <MatchPromptInit {...props} playback={playback} />;
    case DrawingPromptBlockGameSessionStatus.MATCH_PROMPT_COUNTING:
      return <MatchPromptStart {...props} playback={playback} />;
    case DrawingPromptBlockGameSessionStatus.MATCH_PROMPT_DONE:
      return <MatchPromptEnd {...props} playback={playback} />;
    case DrawingPromptBlockGameSessionStatus.REVIEW_ALL_DRAWINGS:
      return <ReviewAllDrawings {...props} playback={playback} />;
    case DrawingPromptBlockGameSessionStatus.RESULTS:
      return <Results {...props} playback={playback} />;
    case DrawingPromptBlockGameSessionStatus.SCOREBOARD:
      return <Scoreboard {...props} playback={playback} />;
    case DrawingPromptBlockGameSessionStatus.END:
    case null:
    case undefined:
      break;
    default:
      assertExhaustive(gss);
      break;
  }

  return null;
}
