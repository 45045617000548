import useSWR from 'swr';

import { apiService } from '../../services/api-service';

export function useOrg(orgId?: string) {
  return useSWR(orgId ? `/organizations/${orgId}` : null, async () => {
    if (!orgId) return;
    const resp = await apiService.organization.getOrganization(orgId);
    return resp.data.organization;
  });
}
