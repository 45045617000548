import { type SVGProps } from 'react';

export function UndoIcon(props: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      className={props.className || 'w-3 h-3 fill-current'}
      fill='none'
      viewBox='0 0 18 20'
    >
      <path
        fill='#fff'
        fillRule='evenodd'
        d='M5.343 1.114a1 1 0 011.4-.2L10.4 3.657 7.657 7.314a1 1 0 01-1.6-1.2l.594-.792A6.145 6.145 0 009 17.142a6.143 6.143 0 004.3-10.529 1 1 0 011.4-1.428 8.143 8.143 0 11-8.185-1.942l-.972-.729a1 1 0 01-.2-1.4z'
        clipRule='evenodd'
      ></path>
    </svg>
  );
}
