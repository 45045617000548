import { Link } from '@remix-run/react';
import { type ReactNode } from 'react';

import { ClientTypeUtils } from '../../types';
import { CopyButton } from '../common/CopyButton';
import { useAudienceURLWithPairingInfoFromLocation } from '../common/CopyVenueLink';
import { CopyLinkIcon } from '../icons/CopyLinkIcon';
import { LeaveIcon } from '../icons/LeaveIcon';
import { DefaultLogoIcon, LunaParkLogoWithHomeArrow } from '../icons/LogoIcon';
import { MusicPlaylistIcon } from '../icons/MusicPlaylistIcon';
import { PauseIcon } from '../icons/PauseIcon';
import { Loading } from '../Loading';
import { useMusicPlayerContext } from '../MusicPlayer/Context';
import { SongTitle } from '../MusicPlayer/HostTools';
import {
  useShowStreamingToolsToggle,
  useStreamingToolsSubpanelState,
} from '../StreamingTools/StreamingToolsContext';
import { useLeaveTeamWithConfirm } from '../TeamAPI/TeamV1';
import { isGuest, useUser } from '../UserContext';
import {
  useMyClientType,
  useVenueBackgroundAudioMuted,
} from '../Venue/VenuePlaygroundProvider';
import { HeaderLayout } from './Common';
import { FirebaseLatencyMeter } from './FirebaseLatencyMeter';

const MusicPlayerTitle = (): JSX.Element | null => {
  const myClientType = useMyClientType();
  const [, setShowStreamingTools] = useShowStreamingToolsToggle();
  const [, setSubpanelState] = useStreamingToolsSubpanelState();
  const { isPlaying, currentAudio, joined } = useMusicPlayerContext();
  const [venueBackgroundAudioMuted] = useVenueBackgroundAudioMuted();

  const songTitle =
    currentAudio?.title ??
    (!venueBackgroundAudioMuted ? 'Lobby Background Music' : null);

  const isHost = ClientTypeUtils.isHost(myClientType);

  if (!songTitle) return null;

  const showMusicPaused = isHost && !isPlaying;

  const icon = joined ? (
    showMusicPaused ? (
      <PauseIcon className='w-5 h-5 fill-current' />
    ) : (
      <MusicPlaylistIcon className='w-5 h-5 fill-current' />
    )
  ) : (
    <Loading text='' imgClassName='w-5 h-5' />
  );

  const handleClick = () => {
    if (!joined || !isHost) return;
    setShowStreamingTools(true);
    setSubpanelState('music-player', true);
  };

  return (
    <div
      className={`flex items-center text-white ${
        joined ? 'cursor-pointer' : 'cursor-not-allowed'
      } filter drop-shadow-lp-upload-replace`}
      onClick={handleClick}
    >
      <div>{icon}</div>
      <div className='text-2xs mx-1 max-w-40 flex items-center'>
        {joined ? (
          <SongTitle
            title={showMusicPaused ? 'Music Player Paused' : songTitle}
          />
        ) : (
          <div className='ml-1'>Music is loading...</div>
        )}
      </div>
    </div>
  );
};

export const LeaveVenueButton = (): JSX.Element => {
  const leaveTeam = useLeaveTeamWithConfirm();

  const onLeaveTeam = async () => {
    await leaveTeam('header-leave-button');
  };

  return (
    <button
      className='btn w-4 h-4 filter drop-shadow-lp-upload-replace'
      onClick={onLeaveTeam}
      type='button'
    >
      <LeaveIcon className='fill-current text-red-002 transform scale-x-[-1]' />
    </button>
  );
};

function VenueInfo(): JSX.Element {
  const urlToCopy = useAudienceURLWithPairingInfoFromLocation();
  const isAudience = ClientTypeUtils.isAudience(useMyClientType());

  return (
    <div className='flex items-center justify-center gap-3 h-14'>
      {!isAudience && <MusicPlayerTitle />}
      <div className='filter drop-shadow-lp-upload-replace'>
        <FirebaseLatencyMeter />
      </div>
      <div className='filter drop-shadow-lp-upload-replace text-white'>
        <CopyButton copiedText={urlToCopy.toString()}>
          <CopyLinkIcon />
        </CopyButton>
      </div>
      <LeaveVenueButton />
    </div>
  );
}

export function HomeNavigator(props: {
  homeURL: string;
  canGuestUserNavigate?: boolean;
}): JSX.Element {
  const user = useUser();
  const isGuestUser = isGuest(user);

  if (isGuestUser && !props.canGuestUserNavigate) {
    return (
      <div className='w-10 h-10'>
        <DefaultLogoIcon />
      </div>
    );
  }

  return (
    <Link
      className={`relative flex items-center gap-1 group cursor-pointer`}
      to={props.homeURL}
      reloadDocument
    >
      <div className='w-10 h-10'>
        <LunaParkLogoWithHomeArrow className='filter drop-shadow' />
      </div>
    </Link>
  );
}

export function Header({
  left = <HomeNavigator homeURL='/home' />,
  right = <VenueInfo />,
  pinLeft,
  enableRight,
}: {
  left?: ReactNode;
  right?: ReactNode;
  pinLeft?: boolean;
  enableRight?: boolean;
}): JSX.Element {
  return (
    <HeaderLayout
      fill={false}
      left={left}
      right={<>{enableRight && right}</>}
      leftClassName={pinLeft ? 'z-55' : ''}
    />
  );
}
