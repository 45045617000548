import {
  type BlockAction,
  type BlockActionWaitModeConfig,
  type GameSessionStatus,
  type NamedBlockAction,
} from '@lp-lib/game';
import { type Media, type MediaData } from '@lp-lib/media';

import {
  type BlockRecordingExtra,
  type GeneratedBlockRecording,
} from './types';

export class BlockActionBuilder<T extends GameSessionStatus> {
  private durationMs = 0;
  private actions: BlockAction[] = [];
  private built = false;

  push(
    gameSessionStatus: T,
    deltaMs: number,
    action: NamedBlockAction | null = null,
    waitMode?: BlockActionWaitModeConfig,
    voiceOver: Media | null = null,
    voiceOverData: MediaData | null = null,
    voiceOverDelayStartMs = 0,
    durationMs = 0
  ) {
    if (this.built) throw new Error('Cannot push to already built builder');
    if (waitMode?.wait && waitMode.maxWaitDurationSec <= 0) {
      throw new Error(
        `maxWaitDurationSec must be greater than 0, got ${waitMode.maxWaitDurationSec}`
      );
    }

    this.durationMs += deltaMs;
    this.actions.push({
      gameSessionStatus,
      timestamp: this.durationMs,
      action,
      waitMode,
      durationMs,
      voiceOver:
        voiceOver && voiceOverData
          ? {
              mediaId: voiceOver.id,
              media: voiceOver,
              mediaData: voiceOverData,
              delayStartMs: voiceOverDelayStartMs,
            }
          : null,
    });
    return this;
  }

  private build() {
    this.built = true;
    return { actions: [...this.actions], durationMs: this.durationMs };
  }

  intoV3Recording(extra?: BlockRecordingExtra): GeneratedBlockRecording {
    const recording = this.build();
    return {
      recording: {
        mediaId: null,
        ...recording,
        version: recording.actions.some((a) => a.voiceOver) ? 3.1 : 3,
        updatedByUid: '',
        updatedAt: new Date().toISOString(),
      },
      extra,
    };
  }
}
