import { useLocation, useNavigate } from '@remix-run/react';
import { useCallback, useEffect, useRef, useState } from 'react';
import { usePopperTooltip } from 'react-popper-tooltip';

import { type Block, BlockType } from '@lp-lib/game';

import { useIsMounted } from '../../../../../hooks/useIsMounted';
import { useLiveCallback } from '../../../../../hooks/useLiveCallback';
import { isAutoloadGameLocationState } from '../../../../../hooks/useLoadGame';
import { useOutsideClick } from '../../../../../hooks/useOutsideClick';
import logger from '../../../../../logger/logger';
import { SessionMode } from '../../../../../types';
import { RoleUtils } from '../../../../../types/user';
import { Emitter } from '../../../../../utils/emitter';
import {
  useStartRecordingBlock,
  useStopRecordingBlock,
} from '../../../../GameRecorder/BlockRecorderProvider';
import {
  AreYouSureYouWantToStopRecordingModal,
  DoYouWantToOverwriteRecordingForBlock,
} from '../../../../GameRecorder/HostTools';
import {
  ArrowRightIcon,
  DoubleDownArrow,
  DoubleUpArrow,
} from '../../../../icons/Arrows';
import { DeleteIcon } from '../../../../icons/DeleteIcon';
import { EditIcon } from '../../../../icons/EditIcon';
import { EyeIcon } from '../../../../icons/EyeIcon';
import { GameIcon } from '../../../../icons/GameIcon';
import { LPLogo } from '../../../../icons/LPLogo';
import { ToolsIcon } from '../../../../icons/ToolsIcon';
import { UpDownArrowsIcon } from '../../../../icons/UpDownArrowsIcon';
import { XIcon } from '../../../../icons/XIcon';
import { Loading } from '../../../../Loading';
import {
  StreamingActiveMode,
  useStreamingMode,
} from '../../../../StreamingTools/hooks';
import { StreamingTools } from '../../../../StreamingTools/StreamingTools';
import {
  useResetStreamingToolsSubpanels,
  useShowStreamingToolsToggle,
} from '../../../../StreamingTools/StreamingToolsContext';
import { useUser } from '../../../../UserContext';
import { useGameLikeEventEmitter } from '../../../GameCenter';
import { useGameEditorStore } from '../../../GameEditorStore';
import {
  useOpenGameLibrary,
  useOpenGamePackEditor,
  useOpenGamePackPreview,
} from '../../../GameLibrary';
import {
  useHasLoadedGameLike,
  useIsLoadingGameLike,
  useLocalGamePlayStore,
  useLocalLoadedBlocks,
  useLocalLoadedGameLike,
  useLocalLoadedGamePack,
  useLocalSelectedPlaybackItem,
} from '../../../GamePlayStore';
import {
  useCurrentSessionMode,
  useGameSessionBlock,
  useIsLiveGamePlay,
  useOndGameState,
  usePlayedBlockIds,
} from '../../../hooks';
import { usePlaybackInfoEmpty } from '../../../Playback/PlaybackInfoProvider';
import {
  adjustScore,
  type EditPointData,
  loadGameSession,
  reset,
  resetBlock,
  resetPlayerData,
  switchPlayMode,
} from '../../../store';
import { ControllerBlockList } from './ControllerBlockList';
import { BlockControllerDrawer } from './ControllerDrawer';
import { BlockControllerHeader } from './ControllerHeader';
import { LiveGameView } from './ControllerLiveMode';
import { OndGameView } from './ControllerOndMode';
import { type ValueEmitter } from './types';

const log = logger.scoped('GameController');

interface ControllerPopupProps {
  message: string;
  onConfirm: () => void;
  onCancel: () => void;
}

const ControllerPopup = (props: ControllerPopupProps): JSX.Element => {
  const { message, onConfirm, onCancel } = props;
  return (
    <div className='absolute w-full h-full flex justify-center items-center overflow-x-hidden overflow-y-auto inset-0 z-45 outline-none focus:outline-none'>
      <div className='relative w-full mx-2 flex items-center m-auto rounded-xl border border-white z-45'>
        <div className='w-full h-44 px-2 py-3 bg-black text-white rounded-xl flex flex-col justify-between items-center font-bold'>
          <p className='text-center mt-4'>{message}</p>
          <div className='w-full flex flex-row items-center justify-around'>
            <button
              type='button'
              onClick={onCancel}
              className='btn-secondary w-28 h-10'
            >
              Cancel
            </button>
            <button
              type='button'
              onClick={onConfirm}
              className='btn-delete w-28 h-10'
            >
              Confirm
            </button>
          </div>
        </div>
      </div>
      <div className='opacity-60 absolute inset-0 z-30 bg-black' />
    </div>
  );
};

interface HeaderProps {
  minimized: boolean;
  setMinimized: (minized: boolean) => void;
  handleChangeGame: () => void;
  handleUnloadGame: (onlyReset?: boolean) => void;
  showStreamingTools: boolean;
  setShowStreamingTools: (show: boolean) => void;
  setShowSwitchGamPlayModeConfirmation: (b: boolean) => void;
  selectedBlock: Block | null;
}

const Header = (props: HeaderProps): JSX.Element => {
  const [showItemMenu, setShowItemMenu] = useState(false);
  const user = useUser();
  const isAdmin = RoleUtils.isAdmin(user);

  const store = useGameEditorStore();
  const loadedGameLike = useLocalLoadedGameLike();
  const openGamePackEditor = useOpenGamePackEditor();
  const openGamePackPreview = useOpenGamePackPreview();
  const resetPanels = useResetStreamingToolsSubpanels();

  const canEdit =
    loadedGameLike?.uid === user.id || (loadedGameLike?.isPrime && isAdmin);

  const {
    minimized,
    handleChangeGame,
    setMinimized,
    handleUnloadGame,
    showStreamingTools,
    setShowStreamingTools,
    selectedBlock,
  } = props;

  const {
    getTooltipProps,
    setTooltipRef,
    setTriggerRef,
    tooltipRef,
    triggerRef,
    visible,
  } = usePopperTooltip({
    trigger: 'click',
    placement: 'bottom-end',
    visible: showItemMenu,
  });

  useOutsideClick(tooltipRef, () => setShowItemMenu(false), triggerRef);

  const onUnloadGame = () => {
    handleUnloadGame();
    setShowItemMenu(false);
  };

  const onChangeGame = () => {
    handleChangeGame();
    setShowItemMenu(false);
  };

  const onEditGame = async () => {
    if (loadedGameLike?.type === 'gamePack') {
      openGamePackEditor(loadedGameLike);
    } else if (loadedGameLike?.type === 'game') {
      await store.setEditingGame(loadedGameLike, {
        blockId: selectedBlock?.id,
      });
    }
    setShowItemMenu(false);
  };

  const onPreviewGame = () => {
    if (loadedGameLike?.type === 'gamePack') {
      openGamePackPreview(loadedGameLike);
    }
  };

  const onCloseStreamingTools = () => {
    resetPanels();
    setShowStreamingTools(false);
  };
  const streamingMode = useStreamingMode();
  const gameType =
    loadedGameLike?.type === 'gamePack' ? 'Game Pack' : 'Minigame';
  const borderStyles = minimized ? 'rounded-b-xl' : 'border-b border-gray-800';
  const bgColor =
    streamingMode === StreamingActiveMode.Live
      ? 'bg-modal'
      : streamingMode === StreamingActiveMode.Rec
      ? 'bg-[#550500]'
      : 'bg-[#2D2258]';

  const itemMeunStyle =
    'btn w-full h-8 pl-2 bg-black hover:bg-dark-gray border-0 rounded-lg flex flex-row justify-start items-center text-3xs';

  return (
    <header
      className={`w-68 h-12 p-2 text-white sticky top-0 flex flex-row justify-between items-center ${bgColor} ${borderStyles} z-45`}
    >
      <div className='flex flex-row items-center justify-center'>
        <LPLogo className='w-9 h-9' />
        {showStreamingTools ? (
          <p className='w-36 ml-2'>Streaming Tools</p>
        ) : loadedGameLike ? (
          <>
            <p className='max-w-36 ml-2 mr-2 truncate'>{loadedGameLike.name}</p>
            <button
              className='w-7.5 h-7.5 relative rounded-lg appearance-none outline-none focus:outline-none  flex justify-center items-center text-white'
              type='button'
              ref={setTriggerRef}
              onClick={() => setShowItemMenu(!showItemMenu)}
            >
              <ArrowRightIcon
                className={`w-4 h-4 fill-current transform ${
                  showItemMenu ? '-rotate-90' : 'rotate-90'
                }`}
              />
            </button>
          </>
        ) : null}
      </div>

      <div className='w-auto h-full flex flex-row justify-center items-center pr-0.5 pb-0'>
        {!showStreamingTools ? (
          <div className='flex flex-col'>
            <button
              id='open-streaming-tools-btn'
              data-testid='open-streaming-tools-btn'
              className='
              rounded-lg appearance-none outline-none focus:outline-none
              flex flex-col justify-center items-center
            '
              type='button'
              onClick={() => {
                setShowStreamingTools(true);
                setMinimized(false);
              }}
            >
              <ToolsIcon className='w-4.5 h-4.5 mt-2.5' />
              <div className='text-xs'>{streamingMode}</div>
            </button>
          </div>
        ) : (
          <button
            id='close-streaming-tools-btn'
            data-testid='close-streaming-tools-btn'
            className='
              w-7.5 h-7.5
              rounded-lg appearance-none outline-none focus:outline-none
              flex justify-center items-center
            '
            type='button'
            onClick={onCloseStreamingTools}
          >
            <XIcon />
          </button>
        )}
      </div>
      {visible && (
        <div
          ref={setTooltipRef}
          {...getTooltipProps({
            className:
              'w-38 bg-black border border-secondary rounded-lg text-white flex flex-col p-1 z-50 transition-opacity',
          })}
        >
          {loadedGameLike?.type === 'gamePack' && (
            <button
              onMouseUp={onPreviewGame}
              type='button'
              className={itemMeunStyle}
            >
              <EyeIcon />
              <div className='ml-2'> Preview {gameType}</div>
            </button>
          )}
          {canEdit && (
            <button
              onMouseUp={onEditGame}
              type='button'
              className={itemMeunStyle}
            >
              <EditIcon />
              <div className='ml-2'>Edit {gameType}</div>
            </button>
          )}
          <button
            onMouseUp={onChangeGame}
            type='button'
            className={itemMeunStyle}
          >
            <UpDownArrowsIcon />
            <div className='ml-2'>Change {gameType}</div>
          </button>
          <button
            onMouseUp={onUnloadGame}
            type='button'
            className={itemMeunStyle}
          >
            <DeleteIcon />
            <div className='ml-2'>Unload {gameType}</div>
          </button>
        </div>
      )}
    </header>
  );
};

export function useSyncEditedGameToLoadedGame(): void {
  const emitter = useGameLikeEventEmitter('game');
  const store = useLocalGamePlayStore();
  useEffect(() => {
    const ac = new AbortController();
    const { signal } = ac;
    emitter.on('updated', (game) => store.updateGame(game), { signal });
  }, [emitter, store]);
}

/**
 * This hook attempts to avoid re-rendering the parent and child while still
 * sending a value to the child via a stable subscription. Otherwise the entire
 * GameController (and all blocks, icons, etc) will re-render just due to a
 * mouseleave.
 */
function useMouseBuster() {
  const [signal] = useState<ValueEmitter>(() => new Emitter());

  const onMouseLeave = useLiveCallback(() => {
    signal.emit('value', Math.random());
  });

  return { signal, onMouseLeave };
}

export function GameController(): JSX.Element {
  const [showEditBlockConfirmation, setShowEditBlockConfirmation] =
    useState(false);
  const [showResetConfirmation, setShowResetConfirmation] = useState(false);
  const [
    showSwitchGamPlayModeConfirmation,
    setShowSwitchGamPlayModeConfirmation,
  ] = useState(false);
  const [showUnloadConfirmation, setShowUnloadConfirmation] = useState(false);
  const [showChangeGameConfirmation, setShowChangeGameConfirmation] =
    useState(false);
  const [showStopRecordingConfirmation, setShowStopRecordingConfirmation] =
    useState(false);
  const [
    showOverwriteExistingRecordingConfirmation,
    setShowOverwriteExistingRecordingConfirmation,
  ] = useState(false);
  const [editPointsData, setEditPointsData] = useState<EditPointData | null>(
    null
  );
  const editPointsInputRef = useRef<HTMLInputElement | null>(null);
  const [minimized, setMinimized] = useState(false);
  const [selectedBlockIndex, setBlockIndex] = useState<number | null>(null);
  const [showStreamingTools, setShowStreamingTools] =
    useShowStreamingToolsToggle();

  const isLive = useIsLiveGamePlay();
  const sessionMode = useCurrentSessionMode();

  const store = useLocalGamePlayStore();
  const selectedBlock =
    useLocalSelectedPlaybackItem(selectedBlockIndex, sessionMode)?.block ??
    null;
  const playedBlockIds = usePlayedBlockIds();
  const startRecording = useStartRecordingBlock();
  const stopRecording = useStopRecordingBlock();
  const ondState = useOndGameState();
  const isNotOndPaused = ondState !== 'paused';
  const isNotOndResuming = ondState !== 'resuming';
  const gameSessionBlock = useGameSessionBlock();
  // Special Case: the host view can be in OND or LIVE mode, but we always want
  // all the units/blocks and to ignore play history for a GP2. Therefore,
  // always request LIVE blocks.
  const loadedBlocks = useLocalLoadedBlocks(SessionMode.Live);
  const loadedGamePack = useLocalLoadedGamePack();
  const isGameLoading = useIsLoadingGameLike();
  const hasLoadedGameLike = useHasLoadedGameLike();
  const location = useLocation();
  const navigate = useNavigate();
  const openGameLibrary = useOpenGameLibrary();
  const isMounted = useIsMounted();
  const emptyPlayback = usePlaybackInfoEmpty();
  const { signal, onMouseLeave } = useMouseBuster();

  useSyncEditedGameToLoadedGame();

  const { blockId, ...restLocationState } = isAutoloadGameLocationState(
    location.state
  )
    ? location.state
    : { blockId: undefined };

  const setSelectedBlockIndex = useCallback(
    (i: number | null) => {
      if (selectedBlockIndex !== i && isNotOndPaused) {
        loadGameSession({
          blockToPreload: i === null ? undefined : loadedBlocks[i],
        }).then(() => {
          if (isMounted()) setBlockIndex(i);
        });
        return;
      }

      setBlockIndex(i);
    },
    [isMounted, isNotOndPaused, loadedBlocks, selectedBlockIndex, setBlockIndex]
  );

  useEffect(() => {
    try {
      if (loadedBlocks) {
        if (blockId && loadedBlocks) {
          // Attempt deeplink to block

          const blockEntry = Object.entries(loadedBlocks).find(
            ([_k, b]) => b.id === blockId
          );
          if (blockEntry) {
            setSelectedBlockIndex(parseInt(blockEntry[0]));
          }

          navigate(location.pathname, {
            replace: true,
            state: restLocationState,
          });
        } else if (
          gameSessionBlock?.id &&
          loadedBlocks &&
          isNotOndPaused &&
          isNotOndResuming
        ) {
          const blockId = gameSessionBlock?.id;
          const blockEntry = Object.entries(loadedBlocks).find(
            ([_k, b]) => b.id === blockId
          );
          if (blockEntry) {
            setBlockIndex(parseInt(blockEntry[0]));
          }
        }
      }
    } catch (err: UnassertedUnknown) {
      log.error('load game error', err);
    }
  }, [
    blockId,
    gameSessionBlock?.id,
    loadedBlocks,
    location.pathname,
    navigate,
    restLocationState,
    setBlockIndex,
    setSelectedBlockIndex,
    isNotOndPaused,
    isNotOndResuming,
  ]);

  useEffect(() => {
    return () => {
      setShowStreamingTools(false);
    };
  }, [setShowStreamingTools]);

  const toggleMinimize = () => {
    setMinimized(!minimized);
  };

  const onEditPoints = async () => {
    if (editPointsData && editPointsInputRef.current) {
      const adjustment = parseInt(editPointsInputRef.current.value);
      if (!isNaN(adjustment)) {
        await adjustScore(editPointsData.teamId, adjustment, selectedBlock?.id);
      }
      setEditPointsData(null);
    }
  };

  const onEditPointsEnterPress = (e: React.KeyboardEvent) => {
    if (e && e.isTrusted && e.key === 'Enter') {
      onEditPoints();
    }
  };

  const onSwitchGamePlayModeConfirmed = async () => {
    const targetGamePlayMode = !isLive;
    await switchPlayMode(targetGamePlayMode);
    setShowSwitchGamPlayModeConfirmation(false);
  };

  const onResetBlockConfirmed = async () => {
    if (typeof selectedBlockIndex === 'number' && selectedBlock?.id) {
      if (!isLive && gameSessionBlock?.id === selectedBlock.id) {
        await resetPlayerData();
      } else {
        await resetBlock(selectedBlock.id);
      }
    }
    await stopRecording(null, true);
    setShowResetConfirmation(false);
  };

  const onEditBlockReset = async () => {
    if (typeof selectedBlockIndex === 'number' && selectedBlock?.id) {
      await resetBlock(selectedBlock.id);
    }
    stopRecording(null, true);
    setShowEditBlockConfirmation(false);
  };

  const onUnloadGame = async () => {
    store.unload();
    await emptyPlayback();
    await reset({ retainPreloadBlock: false });
    stopRecording(null, true);
    setShowUnloadConfirmation(false);
  };

  const onChangeGame = async () => {
    await reset({
      endSession: true,
    });
    stopRecording(null, true);
    openGameLibrary({ type: loadedGamePack ? 'gamePack' : 'game' });
  };

  const handleUnloadGame = () => {
    if (playedBlockIds.length > 0) {
      setShowUnloadConfirmation(true);
    } else {
      onUnloadGame();
    }
  };

  const handleChangeGame = () => {
    if (playedBlockIds.length > 0) {
      setShowChangeGameConfirmation(true);
    } else {
      onChangeGame();
    }
  };

  const onCancelRecordingConfirmed = async () => {
    await stopRecording(selectedBlock, true);
    await onResetBlockConfirmed();
    setShowStopRecordingConfirmation(false);
  };

  useEffect(() => {
    if (editPointsInputRef.current && editPointsData) {
      editPointsInputRef.current.value =
        editPointsData?.defaultPoints?.toString();
    }
  }, [editPointsData]);

  // TODO: Consolidate all the confirmation popups
  return (
    <div className='absolute w-68 z-45'>
      <Header
        minimized={minimized}
        setMinimized={setMinimized}
        handleChangeGame={handleChangeGame}
        handleUnloadGame={handleUnloadGame}
        showStreamingTools={showStreamingTools}
        setShowStreamingTools={setShowStreamingTools}
        setShowSwitchGamPlayModeConfirmation={
          setShowSwitchGamPlayModeConfirmation
        }
        selectedBlock={selectedBlock}
      />
      <div
        onMouseLeave={onMouseLeave}
        className={minimized ? 'hidden' : 'group relative w-full h-auto'}
      >
        <div
          className={`w-full ${
            minimized ? 'h-0' : 'h-144'
          } relative scrollbar overflow-y-auto rounded-b-xl bg-modal`}
        >
          {showStreamingTools ? (
            <StreamingTools />
          ) : hasLoadedGameLike ? (
            <div className='mb-112'>
              <ControllerBlockList
                blocks={loadedBlocks}
                selectedBlockIndex={selectedBlockIndex}
                setSelectedBlockIndex={setSelectedBlockIndex}
              />
            </div>
          ) : (
            <div className='w-full h-full relative px-5 flex flex-col items-center justify-center text-white text-sms'>
              {isGameLoading && <Loading text='' />}
              {!isGameLoading && (
                <>
                  <p>This is your host control panel.</p>
                  <p>Start by loading in a Game.</p>
                  <button
                    type='button'
                    onClick={() => {
                      openGameLibrary();
                    }}
                    className='btn-primary w-full h-20 mt-14 text-base flex flex-col items-center justify-center'
                  >
                    <GameIcon />
                    <p>Load a Game</p>
                  </button>
                </>
              )}
            </div>
          )}
        </div>
        <BlockControllerDrawer
          mouseLeaveBuster={signal}
          block={selectedBlock}
          setShowStopRecordingConfirmation={setShowStopRecordingConfirmation}
          visible={!showStreamingTools}
        >
          <BlockControllerHeader
            block={selectedBlock}
            setShowEditBlockConfirmation={setShowEditBlockConfirmation}
            setShowResetConfirmation={setShowResetConfirmation}
          />
          {isLive ? (
            <LiveGameView
              selectedBlockIndex={selectedBlockIndex}
              setShowResetConfirmation={setShowResetConfirmation}
              setEditPointsData={setEditPointsData}
              setShowOverwriteExistingRecordingConfirmation={
                setShowOverwriteExistingRecordingConfirmation
              }
              setSelectedBlockIndex={setSelectedBlockIndex}
            />
          ) : (
            <OndGameView
              selectedBlockIndex={selectedBlockIndex}
              setShowResetConfirmation={setShowResetConfirmation}
            />
          )}
        </BlockControllerDrawer>
      </div>
      <div className='flex justify-center items-center'>
        <button
          className='
            w-16 h-3
            appearance-none outline-none focus:outline-none
            flex justify-center items-center
            bg-rounded-menu-tab
          '
          type='button'
          onClick={toggleMinimize}
        >
          {minimized ? (
            <DoubleDownArrow className='w-2.5 h-2.5' />
          ) : (
            <DoubleUpArrow className='w-2.5 h-2.5' />
          )}
        </button>
      </div>
      <div
        className={` ${
          editPointsData ? 'block' : 'hidden'
        } absolute w-full px-2 h-full flex justify-center items-center overflow-x-hidden overflow-y-auto inset-0 z-45 outline-none focus:outline-none`}
      >
        <div className='relative w-full flex items-center m-auto rounded-xl border border-white z-45'>
          <div className='relative w-full h-65 px-2 py-3 bg-black rounded-xl flex flex-col justify-between items-center font-bold'>
            <div className='relative max-w-56 text-white text-sm text-center mt-2'>
              <p className=''>Edit Points earned by</p>
              <p className='w-auto text-primary truncate'>
                [{editPointsData?.teamName}]
              </p>
              <p>on this Block {editPointsData?.answer ? 'for ' : ''}</p>
              {editPointsData?.answer && (
                <div className='relative text-primary font-normal flex flex-row justify-center'>
                  <p className='relative text-sms px-1.5 line-clamp-2 hyphens-auto before:left-bracket after:right-bracket'>
                    {editPointsData.answer}
                  </p>
                </div>
              )}
              <p className='mt-2 text-3xs'>
                {selectedBlock?.type === BlockType.CREATIVE_PROMPT
                  ? '(out of 350 max)'
                  : '(out of 200 max)'}
              </p>
            </div>
            <input
              ref={editPointsInputRef}
              className='w-full h-10 mx-4 border text-white border-secondary rounded-lg bg-black text-center outline-none focus:outline-none'
              type='number'
              id='adjustment'
              name='adjustment'
              defaultValue={editPointsData?.defaultPoints}
              onKeyPress={onEditPointsEnterPress}
            />
            <div className='w-full flex flex-row items-center justify-around'>
              <button
                type='button'
                onClick={() => setEditPointsData(null)}
                className='btn-secondary w-28 h-10'
              >
                Cancel
              </button>

              <button
                type='button'
                onClick={onEditPoints}
                className='btn-primary w-28 h-10'
              >
                Update
              </button>
            </div>
          </div>
        </div>
        <div className='opacity-60 absolute inset-0 z-30 bg-black' />
      </div>
      {showResetConfirmation ? (
        <ControllerPopup
          message='Are you sure you want to reset this question? Any submitted answers will be reset.'
          onConfirm={onResetBlockConfirmed}
          onCancel={() => setShowResetConfirmation(false)}
        />
      ) : null}
      {showSwitchGamPlayModeConfirmation ? (
        <ControllerPopup
          message='Are you sure you want to end the current Game session and reset its data?'
          onConfirm={onSwitchGamePlayModeConfirmed}
          onCancel={() => setShowSwitchGamPlayModeConfirmation(false)}
        />
      ) : null}
      {showEditBlockConfirmation ? (
        <ControllerPopup
          message='Are you sure you want to edit this Block? Any submitted answers will be reset.'
          onConfirm={onEditBlockReset}
          onCancel={() => setShowEditBlockConfirmation(false)}
        />
      ) : null}
      {showUnloadConfirmation ? (
        <ControllerPopup
          message='Unload this Game and delete all players’ scores?'
          onConfirm={onUnloadGame}
          onCancel={() => setShowUnloadConfirmation(false)}
        />
      ) : null}
      {showChangeGameConfirmation ? (
        <ControllerPopup
          message='Are you sure you want to end the current Game session and reset its data?'
          onConfirm={onChangeGame}
          onCancel={() => setShowChangeGameConfirmation(false)}
        />
      ) : null}
      {showStopRecordingConfirmation ? (
        <AreYouSureYouWantToStopRecordingModal
          onCancel={() => {
            setShowStopRecordingConfirmation(false);
          }}
          onConfirm={onCancelRecordingConfirmed}
        />
      ) : null}
      {showOverwriteExistingRecordingConfirmation ? (
        <DoYouWantToOverwriteRecordingForBlock
          onCancel={() => setShowOverwriteExistingRecordingConfirmation(false)}
          onConfirm={() => {
            setShowOverwriteExistingRecordingConfirmation(false);
            if (selectedBlock)
              startRecording(selectedBlock, selectedBlock.gameId);
          }}
        />
      ) : null}
    </div>
  );
}
