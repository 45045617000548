import { LayoutAnchor } from '../../../LayoutAnchors/LayoutAnchors';
import { CoffeeMachine } from './OverRoastedCoffeeMachine';
import {
  useOverRoastedActiveTruckId,
  useOverRoastedGamePlayAPI,
  useOverRoastedGroupMachines,
  useOverRoastedTruckTheme,
} from './OverRoastedProvider/OverRoastedGamePlayProvider';
import { OverRoastedTrucksNavigationBar } from './OverRoastedTrucksNavigationBar';
import { type GroupId, type PlayerId, type Truck } from './types';

function TruckWorkspace(props: {
  groupId: GroupId;
  activeTruckId: number;
  showSelectCupTooltip: boolean;
}): JSX.Element {
  const { groupId, activeTruckId, showSelectCupTooltip } = props;

  const machines = useOverRoastedGroupMachines(activeTruckId);
  const truckTheme = useOverRoastedTruckTheme(activeTruckId);
  return (
    <div
      className='relative rounded-2.5xl px-5 2xl:px-15 py-5 2xl:py-7 h-68 transition-colors flex justify-center items-center'
      style={{
        backgroundColor: truckTheme.colorCode,
      }}
    >
      <LayoutAnchor
        id='over-roasted-truck-workspace'
        className='absolute w-full h-full'
        layoutReportDelayMs={500}
      />

      <div className='rounded-xl border-4 w-full h-full relative border-[#CECECE] bg-white'>
        <div className='absolute top-0 left-0 w-full h-17 rounded-t-lg bg-[#2C2C2C]'></div>

        <div className='absolute bottom-0 left-0 w-full h-3 rounded-b-lg bg-[#2C2C2C]'></div>

        <div className='absolute w-full h-full flex justify-center gap-5'>
          {machines.map((m, index) => (
            <CoffeeMachine
              key={m.id}
              groupId={groupId}
              machine={m}
              showSelectCupTooltip={showSelectCupTooltip && index === 0}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

export function OverRoastedTrucksPanel(props: {
  groupId: GroupId;
  playerId: PlayerId;
  showSelectCupTooltip: boolean;
}): JSX.Element {
  const { groupId, playerId, showSelectCupTooltip } = props;

  const activeTruckId = useOverRoastedActiveTruckId();
  const api = useOverRoastedGamePlayAPI();

  const handleSwitchTruck = async (truck: Truck) => {
    await api.switchTruck(playerId, truck.id);
  };

  return (
    <div className='w-full flex flex-col'>
      <OverRoastedTrucksNavigationBar
        activeTruckId={activeTruckId}
        switchTruck={handleSwitchTruck}
      />

      <TruckWorkspace
        groupId={groupId}
        activeTruckId={activeTruckId}
        showSelectCupTooltip={showSelectCupTooltip}
      />
    </div>
  );
}
