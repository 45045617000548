import { FloatLayout } from '../../../Layout';

export function InstructionBlockPlaygroundLayout(props: {
  children?: React.ReactNode;
}): JSX.Element {
  return (
    <FloatLayout useCustomPaddingX className='left-[5%] right-[5%]'>
      <div className='h-full w-full grid grid-rows-1 grid-cols-4 gap-2.5'>
        {props.children}
      </div>
    </FloatLayout>
  );
}
