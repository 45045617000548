import { BlockType } from '@lp-lib/game';

import { type TownhallMode } from './types';

export class TownhallUtils {
  static ModeForBlock(blockType: BlockType): TownhallMode {
    switch (blockType) {
      case BlockType.TITLE_V2:
      case BlockType.SPOTLIGHT:
      case BlockType.SCOREBOARD:
      case BlockType.ICEBREAKER:
      case BlockType.INSTRUCTION:
      case BlockType.HEAD_TO_HEAD:
      case BlockType.JEOPARDY:
        return 'crowd';
      default:
        return 'team';
    }
  }
}
