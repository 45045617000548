import { useMemo } from 'react';
import Select from 'react-select';

import { type BlockType } from '@lp-lib/game';

import { getFeatureQueryParam } from '../../hooks/useFeatureQueryParam';
import { useInstance } from '../../hooks/useInstance';
import { buildReactSelectStyles } from '../../utils/react-select';
import { type Option } from '../common/Utilities';
import { BlockChoiceMap } from '../Game/Blocks/Shared';

function convertToOption(blockType: BlockType): Option<BlockType> {
  return {
    label: BlockChoiceMap[blockType].primary,
    value: blockType,
  };
}

export function BlockTypeSelect(props: {
  value: BlockType | null;
  onChange: (value: BlockType | null) => void;
  className?: string;
  placeholder?: string;
  isClearable?: boolean;
}): JSX.Element {
  const { value, onChange, className } = props;

  const options = useInstance(() =>
    Object.values(BlockChoiceMap)
      .filter((b) => getFeatureQueryParam('show-hidden-blocks') || !b.hidden)
      .map((choice) => convertToOption(choice.type))
  );

  const styles = useMemo(
    () =>
      buildReactSelectStyles<Option<BlockType>>({
        override: {
          control: {
            width: '100%',
            height: '100%',
          },
        },
      }),
    []
  );

  return (
    <Select<Option<BlockType>, false>
      value={value ? convertToOption(value) : null}
      options={options}
      onChange={(v) => onChange(v?.value ?? null)}
      className={`mb-0 ${className}`}
      classNamePrefix='select-box-v2'
      styles={styles}
      isMulti={false}
      placeholder={props.placeholder ?? 'Select Block Type'}
      isClearable={props.isClearable}
    />
  );
}
