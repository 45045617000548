import { ErrorMessage } from '@hookform/error-message';
import {
  Controller,
  FormProvider,
  useFieldArray,
  useForm,
  useFormContext,
  useWatch,
} from 'react-hook-form';
import Select from 'react-select';

import {
  type DtoSharedAsset,
  EnumsAvatarGeneratorId,
  EnumsMediaScene,
  EnumsSharedAssetPurpose,
  type ModelsAvatarLabeledRenderSettings,
  type ModelsPersonality,
  type ModelsTTSRenderSettings,
} from '@lp-lib/api-service-client/public';
import { type Media, type MediaData } from '@lp-lib/media';

import { useInstance } from '../../hooks/useInstance';
import { apiService } from '../../services/api-service';
import { type GamePackShowcaseCardData } from '../../types/game';
import { fromMediaDataDTO, fromMediaDTO } from '../../utils/api-dto';
import { buildReactSelectStyles } from '../../utils/react-select';
import { AvatarRenderSettingsForm } from '../Avatar/AvatarLabeledSettingsEditor';
import { type Option } from '../common/Utilities';
import { ModalWrapper } from '../ConfirmCancelModalContext/ModalWrapper';
import { type FAQ } from '../FAQ';
import { FAQListEditor } from '../FAQ/FAQEditor';
import {
  FieldDescription,
  FieldPanel,
  FieldTitle,
} from '../Game/Blocks/Common/Editor/FieldEditorUtilities';
import { MediaEditor } from '../MediaUploader/MediaEditor';
import { MiniMediaEditor } from '../MediaUploader/MiniMediaEditor';
import { PlaylistEditor } from '../MusicPlayer/MusicPlayerEditor';
import { type Song } from '../MusicPlayer/types';
import { TTSRenderSettingsForm } from '../VoiceOver/TTSLabeledSettingsEditor';
import { ShareAssetPicker } from './SharedAssetPicker';
import { PURPOSE_OPTIONS } from './utils';

type SharedAssetData = {
  cardText: string | null;
  faqs: FAQ[] | null;
  showcaseCard: GamePackShowcaseCardData | null;
  ttsRenderSettings: ModelsTTSRenderSettings | null;
  playlist: Song[] | null;
  personality: ModelsPersonality | null;
  avatarRenderSettings: ModelsAvatarLabeledRenderSettings | null;
};

type FormData = {
  purpose: EnumsSharedAssetPurpose;
  media: {
    mediaData: MediaData;
    media: Media | null;
  } | null;
  data: SharedAssetData;
  label: string;
};

function SharedAssetPurposeSelect(props: {
  value: EnumsSharedAssetPurpose;
  onChange: (value: EnumsSharedAssetPurpose) => void;
  disabled?: boolean;
}) {
  const { value, onChange, disabled } = props;

  const styles = useInstance(() =>
    buildReactSelectStyles<Option<EnumsSharedAssetPurpose>>({
      override: { control: { height: 52 } },
    })
  );

  return (
    <Select<Option<EnumsSharedAssetPurpose>>
      options={PURPOSE_OPTIONS}
      value={PURPOSE_OPTIONS.find((opt) => opt.value === value)}
      classNamePrefix='select-box-v2'
      className='w-full'
      styles={styles}
      placeholder='Select a category'
      onChange={(option) => {
        if (!option) return;
        onChange(option.value);
      }}
      isDisabled={disabled}
    />
  );
}

function SharedAssetPurposeField(props: { item: DtoSharedAsset | null }) {
  return (
    <div className='w-full'>
      <h3 className='text-base font-bold'>
        Category (Immutable after creation)
      </h3>
      <Controller<FormData, 'purpose'>
        name='purpose'
        rules={{ required: 'category is required' }}
        render={({ field: { onChange, value } }) => (
          <SharedAssetPurposeSelect
            value={value}
            onChange={onChange}
            disabled={!!props.item}
          />
        )}
      />
    </div>
  );
}

function SharedAssetInternalLabelField() {
  const { register, formState } = useFormContext<FormData>();

  return (
    <div className='w-full'>
      <h3 className='text-base font-bold'>Internal Label</h3>
      <input
        {...register('label', {
          maxLength: 75,
          required: true,
          minLength: 1,
        })}
        className={`w-full h-12.5 ${
          formState.errors.label ? 'field-error' : 'field'
        } mb-0 mt-1`}
        placeholder='Max 75 characters'
      />
    </div>
  );
}

function SharedAssetMediaField() {
  const purpose = useWatch<FormData>({
    name: 'purpose',
  });

  if (
    purpose === EnumsSharedAssetPurpose.SharedAssetPurposeFaqGroup ||
    purpose === EnumsSharedAssetPurpose.SharedAssetPurposeVoice ||
    purpose === EnumsSharedAssetPurpose.SharedAssetPurposeMusicPlaylist ||
    purpose === EnumsSharedAssetPurpose.SharedAssetPurposePersonality ||
    purpose === EnumsSharedAssetPurpose.SharedAssetPurposeAvatar
  ) {
    return null;
  }

  return (
    <div className='w-full'>
      <h3 className='text-base font-bold'>Media</h3>
      <Controller<FormData, 'media'>
        name='media'
        rules={{ required: 'media is required' }}
        render={({ field: { onChange, value }, formState }) => (
          <>
            <MediaEditor
              video={true}
              scene={EnumsMediaScene.MediaSceneBlockMedia}
              mediaData={value?.mediaData ?? null}
              media={value?.media ?? null}
              onChange={(mediaData, media) => {
                onChange({
                  mediaData,
                  media,
                });
              }}
            />
            <ErrorMessage
              errors={formState.errors}
              name='media'
              as='p'
              className='text-sms text-red-002'
            />
          </>
        )}
      />
    </div>
  );
}

function SharedAssetDataCardTextField() {
  return (
    <div className='w-full'>
      <h3 className='text-base font-bold'>Card Text</h3>
      <Controller<FormData, 'data.cardText'>
        name='data.cardText'
        render={({ field: { onChange, value } }) => (
          <input
            className='w-full h-12.5 field'
            placeholder='Max 150 characters'
            value={value ?? ''}
            onChange={(e) => {
              onChange(e.target.value);
            }}
          />
        )}
      />
    </div>
  );
}

function SharedAssetDataFAQField() {
  const { fields, append, update, remove, move } = useFieldArray<
    FormData,
    'data.faqs',
    'key'
  >({
    name: 'data.faqs',
    keyName: 'key',
  });

  return (
    <FAQListEditor
      faqs={fields}
      onAdd={append}
      onDelete={remove}
      onMove={move}
      onChange={update}
    />
  );
}

function SharedAssetDataPlaylistField() {
  const { fields, append, update, remove, move } = useFieldArray<
    FormData,
    'data.playlist',
    'key'
  >({
    name: 'data.playlist',
    keyName: 'key',
  });

  return (
    <PlaylistEditor
      songs={fields}
      onAdd={append}
      onDelete={remove}
      onMove={move}
      onChange={update}
    />
  );
}

function SharedAssetDataShowcaseCardField() {
  return (
    <>
      <div className='w-full'>
        <h3 className='text-base font-bold'>Primary Text</h3>
        <Controller<FormData, 'data.showcaseCard.primaryText'>
          name='data.showcaseCard.primaryText'
          render={({ field: { onChange, value } }) => (
            <input
              className='w-full h-12.5 field'
              placeholder='Max 150 characters'
              value={value ?? ''}
              onChange={(e) => {
                onChange(e.target.value);
              }}
            />
          )}
        />
      </div>
      <div className='w-full'>
        <h3 className='text-base font-bold'>Secondary Text</h3>
        <Controller<FormData, 'data.showcaseCard.secondaryText'>
          name='data.showcaseCard.secondaryText'
          render={({ field: { onChange, value } }) => (
            <input
              className='w-full h-12.5 field'
              placeholder='Max 150 characters'
              value={value ?? ''}
              onChange={(e) => {
                onChange(e.target.value);
              }}
            />
          )}
        />
      </div>
      <div className='w-full'>
        <h3 className='text-base font-bold'>Hint (UGC - How it works)</h3>
        <Controller<FormData, 'data.showcaseCard.hint'>
          name='data.showcaseCard.hint'
          render={({ field: { onChange, value } }) => (
            <input
              className='w-full h-12.5 field'
              placeholder='Max 300 characters'
              value={value ?? ''}
              onChange={(e) => {
                onChange(e.target.value);
              }}
            />
          )}
        />
      </div>
    </>
  );
}

function SharedAssetDataPersonalityField() {
  const {
    register,
    formState: { errors },
  } = useFormContext<FormData>();

  return (
    <div className='w-full h-full flex flex-col text-white'>
      <div className='flex-grow flex-shrink-0 w-full py-6 space-y-5'>
        <div className='grid grid-cols-2 gap-x-6'>
          <FieldPanel>
            <FieldTitle>Display Label</FieldTitle>
            <FieldDescription>
              Public facing display label, e.g., "John (Voice Only)"
            </FieldDescription>
          </FieldPanel>
          <input
            className={`w-full m-0 ${
              errors.data?.personality?.displayLabel ? 'field-error' : 'field'
            }`}
            type='text'
            {...register('data.personality.displayLabel', { required: true })}
          />
        </div>
        <div className='grid grid-cols-2 gap-x-6'>
          <FieldPanel>
            <FieldTitle>Name</FieldTitle>
            <FieldDescription>
              The name of the personality, e.g., "Sally", "Linus", "Chuck".
            </FieldDescription>
          </FieldPanel>
          <input
            className={`w-full m-0 ${
              errors.data?.personality?.name ? 'field-error' : 'field'
            }`}
            type='text'
            {...register('data.personality.name', { required: true })}
          />
        </div>
        <div className='grid grid-cols-2 gap-x-6'>
          <FieldPanel>
            <FieldTitle>Description</FieldTitle>
            <FieldDescription>
              A description of the personality.
            </FieldDescription>
          </FieldPanel>
          <input
            className={`w-full m-0 ${
              errors.data?.personality?.description ? 'field-error' : 'field'
            }`}
            type='text'
            {...register('data.personality.description', { required: true })}
          />
        </div>
        <div className='grid grid-cols-2 gap-x-6'>
          <FieldPanel>
            <FieldTitle>Voice</FieldTitle>
            <FieldDescription>The voice to use for TTS.</FieldDescription>
          </FieldPanel>
          <Controller<FormData, 'data.personality.voiceId'>
            name='data.personality.voiceId'
            rules={{ required: 'voice is required' }}
            render={({ field: { onChange, value } }) => {
              return (
                <ShareAssetPicker
                  purposes={[EnumsSharedAssetPurpose.SharedAssetPurposeVoice]}
                  selected={value}
                  onChange={(value: DtoSharedAsset | null) =>
                    onChange(value?.id)
                  }
                />
              );
            }}
          />
        </div>
        <div className='grid grid-cols-2 gap-x-6'>
          <FieldPanel>
            <FieldTitle>Profile Image</FieldTitle>
            <FieldDescription>
              A profile image for this personality.
            </FieldDescription>
          </FieldPanel>
          <Controller<FormData, 'data.personality.profileImage'>
            name='data.personality.profileImage'
            render={({ field: { onChange, value } }) => (
              <MiniMediaEditor
                id='personality-profile-image-editor'
                video={true}
                scene={EnumsMediaScene.MediaSceneProfileImage}
                objectFit='cover'
                mediaData={fromMediaDataDTO(value?.data)}
                media={fromMediaDTO(value?.media)}
                onChange={(data, media) => onChange({ data, media })}
              />
            )}
          />
        </div>
        <div className='grid grid-cols-2 gap-x-6'>
          <FieldPanel>
            <FieldTitle>Avatar</FieldTitle>
            <FieldDescription>
              The interactive avatar to use for this personality.
            </FieldDescription>
          </FieldPanel>
          <Controller<FormData, 'data.personality.avatarId'>
            name='data.personality.avatarId'
            render={({ field: { onChange, value } }) => {
              return (
                <ShareAssetPicker
                  purposes={[EnumsSharedAssetPurpose.SharedAssetPurposeAvatar]}
                  selected={value}
                  onChange={(value: DtoSharedAsset | null) =>
                    onChange(value?.id)
                  }
                />
              );
            }}
          />
        </div>
      </div>
    </div>
  );
}

function SharedAssetDataField() {
  const purpose = useWatch<FormData>({
    name: 'purpose',
  });

  switch (purpose) {
    case EnumsSharedAssetPurpose.SharedAssetPurposeFaqGroup:
      return <SharedAssetDataFAQField />;
    case EnumsSharedAssetPurpose.SharedAssetPurposeTitleInstructionCard:
      return <SharedAssetDataCardTextField />;
    case EnumsSharedAssetPurpose.SharedAssetPurposeGamePackShowcaseCard:
      return <SharedAssetDataShowcaseCardField />;
    case EnumsSharedAssetPurpose.SharedAssetPurposeVoice:
      return (
        <Controller<FormData, 'data.ttsRenderSettings'>
          name='data.ttsRenderSettings'
          render={({ field: { onChange, value } }) => (
            <TTSRenderSettingsForm onChange={onChange} value={value} />
          )}
        />
      );
    case EnumsSharedAssetPurpose.SharedAssetPurposeAvatar:
      return (
        <Controller<FormData, 'data.avatarRenderSettings'>
          name='data.avatarRenderSettings'
          rules={{
            validate: (value) => {
              if (
                value?.generatorId ===
                EnumsAvatarGeneratorId.AvatarGeneratorIdSyncLabs
              ) {
                return !!value.syncLabs;
              }
              return true;
            },
          }}
          render={({ field: { onChange, value } }) => (
            <AvatarRenderSettingsForm onChange={onChange} value={value} />
          )}
        />
      );
    case EnumsSharedAssetPurpose.SharedAssetPurposeMusicPlaylist:
      return <SharedAssetDataPlaylistField />;
    case EnumsSharedAssetPurpose.SharedAssetPurposePersonality:
      return <SharedAssetDataPersonalityField />;
    default:
      return null;
  }
}

export function SharedAssetEditorModal(props: {
  item: DtoSharedAsset | null;
  onCancel: () => void;
  onSave: (data: DtoSharedAsset) => void;
}) {
  const { item, onCancel, onSave } = props;

  const formReturned = useForm<FormData>({
    defaultValues: {
      purpose:
        item?.purpose ||
        EnumsSharedAssetPurpose.SharedAssetPurposeTitleInstructionCard,
      label: item?.label || '',
      media: item?.media
        ? {
            mediaData: {
              id: item.media.id,
            },
            media: fromMediaDTO(item.media),
          }
        : null,
      data: item?.data || {},
    },
  });

  const handleSave = async (data: FormData) => {
    if (!item) {
      const resp = await apiService.media.createSharedAsset({
        purpose: data.purpose,
        label: data.label,
        mediaId: data.media?.media?.id || null,
        data: {
          ...data.data,
        },
      });
      onSave(resp.data.sharedAsset);
      return;
    }

    const resp = await apiService.media.updateSharedAsset(item.id, {
      purpose: data.purpose,
      label: data.label,
      mediaId: data.media?.media?.id || null,
      data: {
        ...data.data,
      },
    });
    onSave(resp.data.sharedAsset);
  };

  return (
    <ModalWrapper
      containerClassName='w-160'
      borderStyle='gray'
      onClose={onCancel}
    >
      <FormProvider {...formReturned}>
        <form
          className='w-full flex flex-col items-center justify-start gap-3 px-5 py-10'
          onSubmit={formReturned.handleSubmit(handleSave)}
        >
          <header className='text-2xl font-medium'>
            {item?.id ? 'Edit' : 'Create'} Shared Asset
          </header>

          <SharedAssetPurposeField item={item} />

          <SharedAssetMediaField />

          <SharedAssetDataField />

          <SharedAssetInternalLabelField />

          <div className='w-full flex justify-center items-center gap-2'>
            <button
              type='button'
              className='btn-secondary w-35 h-10'
              onClick={onCancel}
            >
              Cancel
            </button>
            <button type='submit' className='btn-primary w-35 h-10'>
              Save
            </button>
          </div>
        </form>
      </FormProvider>
    </ModalWrapper>
  );
}
