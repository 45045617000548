import debounce from 'lodash/debounce';
import max from 'lodash/max';
import pluralize from 'pluralize';
import { useEffect, useMemo, useRef, useState } from 'react';
import {
  DndProvider,
  type DropTargetMonitor,
  useDrag,
  useDrop,
} from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import CreatableSelect from 'react-select/creatable';

import { EnumsMediaScene } from '@lp-lib/api-service-client/public';
import {
  type RoundRobinClue,
  type RoundRobinIncorrectAnswer,
  RoundRobinMode,
  type RoundRobinQuestion,
  type RoundRobinQuestionBlock,
  type RoundRobinQuestionBlockMedia,
} from '@lp-lib/game';
import { type Media, MediaType } from '@lp-lib/media';

import { useArrayState } from '../../../../hooks/useArrayState';
import { useForceUpdate } from '../../../../hooks/useForceUpdate';
import { useInstance } from '../../../../hooks/useInstance';
import { useLiveCallback } from '../../../../hooks/useLiveCallback';
import { assertExhaustive, uuidv4 } from '../../../../utils/common';
import { canMove } from '../../../../utils/dnd';
import { MediaUtils } from '../../../../utils/media';
import { buildReactSelectStyles } from '../../../../utils/react-select';
import { ValtioUtils } from '../../../../utils/valtio';
import { DragDropList } from '../../../common/DragDrop';
import { type Option } from '../../../common/Utilities';
import {
  ConfirmCancelModalHeading,
  ConfirmCancelModalText,
  useAwaitFullScreenConfirmCancelModal,
} from '../../../ConfirmCancelModalContext';
import { ModalWrapper } from '../../../ConfirmCancelModalContext/ModalWrapper';
import { UncontrolledRangeInput } from '../../../Form/UncontrolledRangeInput';
import { ArrowDownIcon, ArrowUpIcon } from '../../../icons/Arrows';
import { DeleteIcon } from '../../../icons/DeleteIcon';
import { ImageIcon } from '../../../icons/ImageIcon';
import { MenuIcon } from '../../../icons/MenuIcon';
import { MiniMediaUploader } from '../../../MediaUploader/MiniMediaUploader';
import { SwitcherControlled } from '../../../Switcher';
import {
  AdditionalSettings,
  AdditionalSharedSettingsEditor,
  BlockMediaEditor,
  CreatableDurationSelect,
  EditorBody,
  EditorLayout,
  type EditorProps,
  formatDurationOption,
  getNewDurationOptionData,
  isValidNewDurationOption,
  RHFCheckbox,
  RHFSelectField,
  useEditor,
} from '../Common/Editor/EditorUtilities';
import { RoundRobinQuestionUtils } from './utils';

export const roundRobinQuestionNoTimer = 0;
export const roundRobinChangeSubmitterTimerCutoffSec = 10;

function secondsToTimeOption(secs: number): Option<number> {
  if (secs === roundRobinQuestionNoTimer) {
    return { label: `No Timer`, value: secs };
  }
  if (secs < 60) {
    return { label: `${secs} ${pluralize('second', secs)}`, value: secs };
  }

  const mins = secs / 60;
  return { label: `${mins} ${pluralize('minute', mins)}`, value: secs };
}

const gameTimeSecs = [
  10, 15, 20, 30, 45, 60, 90, 120, 180, 240, 300, 600, 900, 1800, 2700, 3600,
];

const gameTimeOptions = gameTimeSecs.map(secondsToTimeOption);

export const roundRobinQuestionNoTimerMaxSec =
  max(gameTimeSecs) ?? gameTimeSecs[-1];

export const roundRobinGameLongDuration = 240;

const questionTimeOptions = [
  5,
  10,
  15,
  20,
  30,
  60,
  120,
  180,
  240,
  300,
  roundRobinQuestionNoTimer,
].map(secondsToTimeOption);

const clueTimeOptions = [0, 60, 120, 300].map(secondsToTimeOption);

function modeToOption(value: RoundRobinMode): Option<RoundRobinMode> {
  switch (value) {
    case RoundRobinMode.Default:
      return { label: 'Default', value };
    case RoundRobinMode.Race:
      return { label: 'Race', value };
    default:
      assertExhaustive(value);
      throw new Error(`Unknown mode: ${value}`);
  }
}

const modeOptions = Object.values(RoundRobinMode).map(modeToOption);

interface DragQuestion {
  id: number;
  index: number;
}

type MoveHandler = (from: number, to: number) => void;
type DeleteHandler = () => void;

function QuestionReorderRow(props: {
  question: RoundRobinQuestion;
  index: number;
  onMove: MoveHandler;
  onDelete: DeleteHandler;
}): JSX.Element {
  const { question, index, onMove, onDelete } = props;
  const ref = useRef<HTMLDivElement>(null);

  const [, drop] = useDrop({
    accept: 'question-reorder',
    hover(item: DragQuestion, monitor: DropTargetMonitor) {
      if (!ref.current) return;
      const dragIndex = item.index;
      const hoverIndex = index;
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      if (canMove(dragIndex, hoverIndex, hoverBoundingRect, monitor)) {
        onMove(dragIndex, hoverIndex);
        item.index = hoverIndex;
      }
    },
  });
  const [collected, drag, drapPreview] = useDrag({
    type: 'question-reorder',
    item: () => {
      return { id: question.createdAt, index };
    },
    collect: (monitor) => ({
      opacity: monitor.isDragging() ? 'opacity-40' : '',
    }),
  });

  drapPreview(drop(ref));

  const handleDelete = () => {
    onDelete();
  };

  const mediaUrl = useMemo(() => {
    if (!question.questionMedia) return null;
    if (question.questionMedia.type === MediaType.Image)
      return MediaUtils.PickMediaUrl(question.questionMedia);
    return question.questionMedia.firstThumbnailUrl;
  }, [question.questionMedia]);

  return (
    <div
      className={`w-full px-3 flex items-center justify-between gap-2 ${collected.opacity}`}
      ref={ref}
    >
      <div className='flex-1 overflow-hidden flex items-center'>
        <button ref={drag} type='button' className='btn cursor-move'>
          <MenuIcon />
        </button>

        <div className='ml-5 w-22 h-12.5 rounded-lg'>
          {mediaUrl ? (
            <img
              src={mediaUrl}
              alt='luna park'
              className='w-full h-full object-contain rounded-lg'
            ></img>
          ) : (
            <div className='w-full h-full rounded-lg border border-secondary bg-dark-gray flex justify-center items-center'>
              <ImageIcon className='w-6 h-6 text-secondary' />
            </div>
          )}
        </div>

        <div className='flex-1 overflow-hidden ml-3'>
          <p className='text-base truncate'>
            <strong>Q:</strong>{' '}
            {question.question ? (
              <>{question.question}</>
            ) : (
              <span className='text-secondary italic'>
                No Question Text Available
              </span>
            )}
          </p>
          <p className='text-base truncate'>
            <strong>A:</strong>{' '}
            {question.answers ? (
              <>{question.answers}</>
            ) : (
              <span className='text-secondary italic'>
                No Answers Available
              </span>
            )}
          </p>
        </div>
      </div>

      <button
        type='button'
        className='btn w-7.5 h-7.5 border border-secondary rounded-lg flex items-center justify-center text-red-002'
        onClick={handleDelete}
      >
        <DeleteIcon />
      </button>
    </div>
  );
}

function ReorderCardsModal(props: {
  questions: RoundRobinQuestion[];
  onComplete: (reordered: RoundRobinQuestion[]) => void;
  onClose: () => void;
}): JSX.Element {
  const [questions, setQuestions] = useState(props.questions);

  const handleSave = () => {
    props.onComplete(questions);
  };

  const handleCancel = () => {
    props.onClose();
  };

  const handleMove = (from: number, to: number): void => {
    setQuestions((current) => {
      const next = [...current];
      const q = next[from];
      next.splice(from, 1);
      next.splice(to, 0, q);
      return next;
    });
  };

  const handleDelete = (createdAt: number) => {
    setQuestions((current) => {
      const index = current.findIndex((q) => q.createdAt === createdAt);
      if (index === -1) return current;
      const next = [...current];
      next.splice(index, 1);

      return next;
    });
  };

  return (
    <ModalWrapper
      onClose={props.onClose}
      borderStyle='gray'
      containerClassName='w-148'
    >
      <div className='w-full px-3 py-4 text-white flex flex-col items-center gap-6'>
        <h2 className='text-xl font-medium'>Reorder Cards</h2>

        <div className='w-full flex flex-col gap-7 max-h-120 overflow-y-auto scrollbar'>
          {/* TODO(guoqiang): a generic component for reorder */}
          <DndProvider backend={HTML5Backend}>
            {questions.map((q, i) => (
              <QuestionReorderRow
                key={q.createdAt}
                question={q}
                index={i}
                onMove={handleMove}
                onDelete={() => handleDelete(q.createdAt)}
              />
            ))}
          </DndProvider>
        </div>

        <div className='flex justify-center items-center gap-4'>
          <button
            type='button'
            className='btn-secondary w-32 h-10'
            onClick={handleCancel}
          >
            Cancel
          </button>
          <button
            type='button'
            className='btn-primary w-32 h-10'
            onClick={handleSave}
          >
            Save
          </button>
        </div>
      </div>
    </ModalWrapper>
  );
}

function PointsInput(props: {
  defaultValue: number;
  onUpdate: (value: number) => void;
}): JSX.Element {
  const [focus, setFocus] = useState(false);
  const [value, setValue] = useState(props.defaultValue.toString());

  return (
    <input
      className='field h-10 mb-0 w-25 text-center'
      type={focus ? 'number' : 'text'}
      value={focus ? value : `${value || 0} pts`}
      onFocus={() => setFocus(true)}
      onChange={(event) => {
        const value = event.target.value;
        if (value === '-' || !isNaN(Number(value))) {
          setValue(value);
        }
      }}
      onBlur={(event) => {
        setFocus(false);
        const value = event.target.value;
        if (!isNaN(Number(value))) {
          props.onUpdate(Number(value));
        }
      }}
    ></input>
  );
}

function ReorderAnswersModal(props: {
  question: RoundRobinQuestion;
  onCancel: () => void;
  onSave: (answerId: string[]) => void;
}) {
  const { question } = props;
  const [answers, , dao] = useArrayState({
    init: RoundRobinQuestionUtils.BuildMultipleChoiceAnswers(question) ?? [],
    compare: (a, b) => a.id === b.id,
  });

  const handleMove = (from: number, to: number) => {
    dao.moveItem(from, to);
  };

  const handleSave = () => {
    props.onSave(answers.map((a) => a.id));
  };

  return (
    <ModalWrapper containerClassName='w-148' borderStyle='gray'>
      <div className='w-full px-3 py-4 text-white flex flex-col items-center gap-6'>
        <h2 className='text-xl font-medium'>Reorder Answers</h2>

        <div className='w-full flex flex-col gap-2'>
          <DragDropList
            type={`rrq-${question.createdAt}-answers`}
            items={answers}
            onMove={handleMove}
            render={({ item: answer, drag, ref, style }) => {
              return (
                <div
                  className='w-full flex items-center text-white gap-2 relative'
                  ref={ref}
                  style={style}
                >
                  <button ref={drag} type='button' className='btn cursor-move'>
                    <MenuIcon />
                  </button>
                  <input
                    className='field h-10 mb-0 disabled:opacity-100'
                    disabled
                    value={answer.text || 'N/A'}
                  />
                  <div
                    className='absolute right-px flex items-center justify-center 
                  bg-secondary w-10 h-[38px] rounded-r-xl border-l border-secondary'
                  >
                    {answer.correct ? '✅' : '❌'}
                  </div>
                </div>
              );
            }}
          />
        </div>

        <div className='flex justify-center items-center gap-4'>
          <button
            type='button'
            className='btn-secondary w-32 h-10'
            onClick={props.onCancel}
          >
            Cancel
          </button>
          <button
            type='button'
            className='btn-primary w-32 h-10'
            onClick={handleSave}
          >
            Save
          </button>
        </div>
      </div>
    </ModalWrapper>
  );
}

function QuestionCard(props: {
  blockId: string;
  question: RoundRobinQuestion;
  showClues: boolean;
  onUpdate: (updated: RoundRobinQuestion) => void;
  onDelete: () => void;
  collapsed: boolean;
  toggleCollapse: (val: boolean) => void;
  mode: RoundRobinMode;
}): JSX.Element {
  const {
    blockId,
    question,
    onUpdate,
    onDelete,
    showClues,
    collapsed,
    toggleCollapse,
    mode,
  } = props;

  const styles = useMemo(() => buildReactSelectStyles<Option<number>>(), []);

  const handleUpdate = (updates: Partial<RoundRobinQuestion>) => {
    onUpdate({ ...question, ...updates });
  };

  const handleDelete = () => {
    onDelete();
  };

  const handleToggleCollapse = () => {
    toggleCollapse(!collapsed);
  };

  const handleToggleRandomize = (randomize: boolean) => {
    const orderedAnswerIds = randomize
      ? []
      : [
          RoundRobinQuestionUtils.GetCorrectAnswerId(question),
          ...(question.incorrectAnswers ?? []).map((a) => a.id),
        ];
    handleUpdate({
      answerOrder: { randomize, orderedAnswerIds },
    });
  };

  const handleUpdateIncorrectAnswers = (
    incorrectAnswers: RoundRobinIncorrectAnswer[]
  ) => {
    let orderedAnswerIds = [...(question.answerOrder?.orderedAnswerIds ?? [])];
    if (!question.answerOrder?.randomize) {
      const correctAnswerId =
        RoundRobinQuestionUtils.GetCorrectAnswerId(question);
      const incorrectAnswerIds = incorrectAnswers.map((a) => a.id);
      // add new ids to the end of orderedAnswerIds
      for (const answerId of incorrectAnswerIds) {
        if (!orderedAnswerIds.includes(answerId)) {
          orderedAnswerIds.push(answerId);
        }
      }
      // remove ids that are not in incorrectAnswerIds
      orderedAnswerIds = orderedAnswerIds.filter(
        (id) => id === correctAnswerId || incorrectAnswerIds.includes(id)
      );
    } else {
      orderedAnswerIds = [];
    }

    handleUpdate({
      incorrectAnswers,
      answerOrder: {
        randomize: !!question.answerOrder?.randomize,
        orderedAnswerIds,
      },
    });
  };

  const handleUpdateAnswerOrder = (answerIds: string[]) => {
    handleUpdate({
      answerOrder: {
        randomize: !!question.answerOrder?.randomize,
        orderedAnswerIds: answerIds,
      },
    });
  };

  const triggerFullScreenModal = useAwaitFullScreenConfirmCancelModal();

  const checkCorrectAnswer = useLiveCallback(async (): Promise<boolean> => {
    if (question.answers.length <= 50) return true;
    const res = await triggerFullScreenModal({
      kind: 'confirm-cancel',
      prompt: (
        <div className='px-5 py-2'>
          <ConfirmCancelModalHeading>
            Change to multiple choice layout?
          </ConfirmCancelModalHeading>
          <ConfirmCancelModalText className='mt-4 text-sms font-normal'>
            You will lose any answer variations in the correct answer field.
          </ConfirmCancelModalText>
        </div>
      ),
      confirmBtnLabel: 'Confirm',
      confirmBtnVariant: 'delete',
      cancelBtnLabel: 'Cancel',
    });
    if (res.result === 'canceled') return false;
    const truncated = question.answers.substring(0, 50);
    handleUpdate({ answers: truncated });
    return true;
  });

  const manageAnswerOrder = useLiveCallback(async () => {
    await triggerFullScreenModal({
      kind: 'custom',
      element: (props) => (
        <ReorderAnswersModal
          question={question}
          onSave={(answerIds) => {
            handleUpdateAnswerOrder(answerIds);
            props.internalOnConfirm();
          }}
          onCancel={props.internalOnCancel}
        />
      ),
    });
  });

  const hasIncorrectAnswer = (question.incorrectAnswers?.length ?? 0) > 0;
  const showOrderColumn =
    hasIncorrectAnswer && !question.answerOrder?.randomize;

  const mediaDisabled = mode === RoundRobinMode.Race;

  return (
    <div className='relative w-full bg-black rounded-xl p-6 pb-10 flex flex-col gap-6'>
      <button
        type='button'
        onClick={handleToggleCollapse}
        className='absolute right-2.5 top-2.5'
      >
        {collapsed ? <ArrowDownIcon /> : <ArrowUpIcon />}
      </button>

      <button
        type='button'
        onClick={handleDelete}
        className={`absolute right-2.5 bottom-2.5 text-red-002 text-xs font-light ${
          collapsed ? 'hidden' : 'flex'
        }`}
      >
        Delete Question
      </button>

      <section className={`items-start gap-6 ${collapsed ? 'hidden' : 'flex'}`}>
        <label className='w-3/5 flex flex-col gap-1'>
          <h4 className='text-base font-bold'>Question Timer</h4>
          <CreatableSelect<Option<number>>
            options={questionTimeOptions}
            name={'question timer'}
            className={`select-box text-white`}
            classNamePrefix='select-box-v2'
            defaultValue={
              questionTimeOptions.find((o) => o.value === question.timeSec) ?? {
                value: question.timeSec,
                label: formatDurationOption(question.timeSec),
              }
            }
            onChange={(option) => handleUpdate({ timeSec: option?.value })}
            formatCreateLabel={formatDurationOption}
            isValidNewOption={isValidNewDurationOption}
            getNewOptionData={getNewDurationOptionData}
            createOptionPosition='first'
            styles={styles}
          />
        </label>

        <div className='w-2/5 flex gap-3'>
          <div className='flex flex-col gap-1'>
            <h4 className='text-base font-bold'>Points</h4>
            <PointsInput
              defaultValue={question.points}
              onUpdate={(value) => handleUpdate({ points: value })}
            />
          </div>

          <div className='flex flex-col gap-1'>
            <h4
              className={`text-base font-bold items-center justify-between ${
                mediaDisabled ? 'opacity-50' : ''
              }`}
            >
              Question BG
            </h4>
            <MiniMediaUploader
              video
              uploaderId={`${blockId}-${question.createdAt}-background-media`}
              media={question.backgroundMedia}
              onDelete={() => {
                handleUpdate({
                  backgroundMedia: null,
                  backgroundMediaId: null,
                });
              }}
              onUploadSuccess={(media: Media | null) => {
                handleUpdate({
                  backgroundMedia: media,
                  backgroundMediaId: media?.id,
                });
              }}
              disabled={mediaDisabled}
            />
          </div>
        </div>
      </section>

      <section className='w-full flex items-end gap-6 -mt-4'>
        <label className='w-3/5 flex flex-col gap-1'>
          <h4 className='text-base font-bold'>Question</h4>
          {/* TODO: invalid state */}
          <input
            className='field h-10 mb-0'
            defaultValue={question.question}
            onBlur={(event) => {
              handleUpdate({ question: event.target.value });
            }}
            maxLength={100}
            placeholder='Max 100 characters'
          ></input>
        </label>

        <div className='w-2/5 flex flex-row items-center gap-3'>
          <MiniMediaUploader
            uploaderId={`${blockId}-${question.createdAt}-question-media`}
            media={question.questionMedia}
            video
            onDelete={() => {
              handleUpdate({ questionMedia: null, questionMediaId: null });
            }}
            onUploadSuccess={(media: Media | null) => {
              handleUpdate({
                questionMedia: media,
                questionMediaId: media?.id,
              });
            }}
            disabled={mediaDisabled}
          />
          <div
            className={`flex items-center justify-center gap-3 ${
              mediaDisabled
                ? 'pointer-events-none opacity-50'
                : 'pointer-events-auto'
            }`}
          >
            <input
              type='checkbox'
              className='checkbox-dark w-5 h-5'
              checked={question.questionMediaLoop ?? false}
              onChange={(event) => {
                handleUpdate({ questionMediaLoop: event.target.checked });
              }}
            />
            <div className='text-secondary'>Loop</div>
          </div>
        </div>
      </section>

      <section
        className={`w-full flex items-end gap-6 ${
          collapsed ? 'hidden' : 'flex'
        }`}
      >
        <label className='w-3/5 flex flex-col gap-1'>
          <h4 className='text-base font-bold flex items-center justify-between'>
            <div className='flex items-center'>
              <p>Correct Answer</p>
              {!hasIncorrectAnswer && (
                <p className='text-2xs text-secondary ml-1'>
                  (Separate variations with a comma)
                </p>
              )}
            </div>
            {showOrderColumn && (
              <div
                className='underline transform -translate-x-12 cursor-pointer'
                onClick={manageAnswerOrder}
              >
                Order
              </div>
            )}
          </h4>
          {/* TODO: invalid state */}
          <div className='flex gap-2'>
            <input
              className='field h-10 mb-0'
              defaultValue={question.answers}
              onBlur={(event) => handleUpdate({ answers: event.target.value })}
              maxLength={hasIncorrectAnswer ? 50 : 3000}
              placeholder={`Max ${
                hasIncorrectAnswer ? '50' : '3,000'
              } characters`}
            />
            {showOrderColumn && (
              <>
                <input
                  className='field h-10 w-13 text-center'
                  value={RoundRobinQuestionUtils.GetAnswerOrder(
                    question,
                    RoundRobinQuestionUtils.GetCorrectAnswerId(question)
                  )}
                  disabled
                />
                {/* a placeholder to make the columns aligned */}
                <div className='w-14'></div>
              </>
            )}
          </div>
        </label>
        <div className='w-2/5'>
          <MiniMediaUploader
            video
            uploaderId={`${blockId}-${question.createdAt}-answer-media`}
            media={question.answerMedia}
            onDelete={() => {
              handleUpdate({ answerMedia: null, answerMediaId: null });
            }}
            onUploadSuccess={(media: Media | null) => {
              handleUpdate({
                answerMedia: media,
                answerMediaId: media?.id,
              });
            }}
            disabled={mediaDisabled}
          />
        </div>
      </section>

      {mode !== RoundRobinMode.Race && (
        <section
          className={`w-full flex items-start gap-6 ${
            collapsed ? 'hidden' : 'flex'
          }`}
        >
          <QuestionIncorrectAnswers
            question={question}
            canAdd={checkCorrectAnswer}
            onUpdate={handleUpdateIncorrectAnswers}
            incorrectAnswers={question.incorrectAnswers}
          />
          {hasIncorrectAnswer && (
            <div className='w-2/5 mt-6'>
              <div className='w-full flex flex-col'>
                <div className='flex items-center justify-between'>
                  <span className='text-white font-bold'>
                    Randomize Answers
                  </span>
                  <SwitcherControlled
                    name={`randomizeAnswers-${question.createdAt}`}
                    className=''
                    checked={!!question.answerOrder?.randomize}
                    onChange={handleToggleRandomize}
                  />
                </div>
                <div className='text-icon-gray text-3xs font-medium mt-2'>
                  {!!question.answerOrder?.randomize
                    ? 'Enabled: Answers will be displayed in random order.'
                    : 'Disabled: Answers will be displayed in the order entered in the order field.'}
                </div>
              </div>
            </div>
          )}
        </section>
      )}

      {showClues && !collapsed && (
        <section className='w-full flex items-end gap-6'>
          <QuestionClues
            onUpdate={(value) => handleUpdate({ clues: value })}
            defaultClues={question.clues}
          />
        </section>
      )}
    </div>
  );
}

function QuestionIncorrectAnswers(props: {
  question: RoundRobinQuestion;
  canAdd: () => Promise<boolean>;
  onUpdate: (updated: RoundRobinIncorrectAnswer[]) => void;
  incorrectAnswers?: RoundRobinIncorrectAnswer[];
}): JSX.Element {
  const { question, canAdd, onUpdate } = props;
  const incorrectAnswers = props.incorrectAnswers ?? [];

  const handleAddMore = async () => {
    if (incorrectAnswers.length === 0) {
      if (!(await canAdd())) return;
    }
    onUpdate([...incorrectAnswers, { id: uuidv4(), text: '' }]);
  };
  const handleUpdate = (updated: RoundRobinIncorrectAnswer) => {
    const answers = ValtioUtils.detachCopy(incorrectAnswers);
    const index = answers.findIndex((v) => v.id === updated.id);
    if (index === -1) return;
    answers[index] = updated;
    onUpdate([...answers]);
  };
  const handleDelete = (updated: RoundRobinIncorrectAnswer) => {
    const index = incorrectAnswers.findIndex((v) => v.id === updated.id);
    if (index === -1) return;
    const next = [...incorrectAnswers];
    next.splice(index, 1);
    onUpdate(next);
  };
  return (
    <div className='w-3/5 flex flex-col gap-1'>
      {incorrectAnswers.length > 0 && (
        <h4 className='text-base font-bold'>Incorrect Answer(s)</h4>
      )}
      {incorrectAnswers.map((v) => (
        <div key={v.id} className='flex gap-2'>
          <input
            className='field h-10 mb-0'
            defaultValue={v.text}
            onBlur={(event) =>
              handleUpdate({ id: v.id, text: event.target.value })
            }
            maxLength={50}
            placeholder='Max 50 characters'
          />
          {!question.answerOrder?.randomize && (
            <input
              className='field h-10 w-13 text-center'
              value={RoundRobinQuestionUtils.GetAnswerOrder(question, v.id)}
              disabled
            />
          )}
          <button
            type='button'
            className='btn w-10 h-10 text-red-002 flex-shrink-0 border border-secondary rounded-xl flex items-center justify-center hover:bg-secondary'
            onClick={() => handleDelete(v)}
          >
            <DeleteIcon />
          </button>
        </div>
      ))}
      {incorrectAnswers.length < 3 && (
        <button
          type='button'
          className='btn underline text-icon-gray text-sms mt-2 self-start'
          onClick={() => handleAddMore()}
        >
          Add Multiple Choice Answer
        </button>
      )}
    </div>
  );
}

function QuestionClue(props: {
  defaultclue: RoundRobinClue;
  index: number;
  handleUpdate: (clue: RoundRobinClue) => void;
  handleDelete: (clue: RoundRobinClue) => void;
}): JSX.Element {
  const { defaultclue, handleUpdate, index, handleDelete } = props;
  const [clue, setClue] = useState(defaultclue);
  const [editingTitle, setEditingTitle] = useState(false);
  const [title, setTitle] = useState(clue.title);

  return (
    <div>
      <section className='w-full flex items-end'>
        <div className='w-full flex flex-col'>
          <div className='w-full flex flex-col gap-2'>
            {editingTitle ? (
              <div className='w-2/3 flex items-center gap-2'>
                <input
                  className='field h-10 mb-0'
                  value={title ?? ''}
                  onChange={(event) => setTitle(event.target.value)}
                  maxLength={15}
                  placeholder='Max 15 characters'
                />
                <button
                  className='text-primary text-3xs'
                  type='button'
                  onClick={() => {
                    const updated: RoundRobinClue = {
                      ...clue,
                      title,
                    };
                    if (updated.title === '') {
                      delete updated['title'];
                    }
                    setClue(updated);
                    handleUpdate(updated);
                    setEditingTitle(false);
                  }}
                >
                  Save
                </button>
                <div className='text-primary text-3xs'>|</div>
                <button
                  className='text-primary text-3xs'
                  type='button'
                  onClick={() => {
                    setTitle(clue.title);
                    setEditingTitle(false);
                  }}
                >
                  Cancel
                </button>
              </div>
            ) : (
              <h4 className='text-sms font-bold flex items-center gap-2'>
                <div>{title || `Clue ${index + 1}`}</div>
                <button
                  className='text-primary text-3xs'
                  type='button'
                  onClick={() => {
                    setEditingTitle(true);
                  }}
                >
                  Edit Title
                </button>
              </h4>
            )}
            <div className='flex items-center gap-6'>
              <div className='w-2/3'>
                <input
                  className='field h-10 mb-0'
                  defaultValue={defaultclue.text}
                  onBlur={(event) => {
                    const updated = {
                      ...clue,
                      text: event.target.value,
                    };
                    setClue(updated);
                    handleUpdate(updated);
                  }}
                  maxLength={125}
                  placeholder='Max 125 characters'
                />
              </div>
              <div className='w-1/3 flex items-center justify-center gap-6'>
                <div className='flex flex-col relative'>
                  {/* <h4 className='text-sms font-bold'>&nbsp;</h4> */}
                  <div className='flex flex-col justify-center'>
                    <PointsInput
                      defaultValue={defaultclue.points}
                      onUpdate={(value) => {
                        const updated = {
                          ...clue,
                          points: value,
                        };
                        setClue(updated);
                        handleUpdate(updated);
                      }}
                    />
                  </div>
                  <div className='text-secondary text-xs absolute bottom-0 transform translate-y-full'>
                    negative costs will subtract pts
                  </div>
                </div>
                <button
                  type='button'
                  className='btn w-7.5 h-7.5 flex-shrink-0 border border-secondary rounded-lg flex items-center justify-center text-red-002 hover:bg-secondary'
                  onClick={() => handleDelete(defaultclue)}
                >
                  <DeleteIcon />
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

function QuestionClues(props: {
  onUpdate: (updated: RoundRobinClue[]) => void;
  defaultClues?: RoundRobinClue[];
}): JSX.Element {
  const { onUpdate, defaultClues } = props;
  const [clues, setClues] = useState<RoundRobinClue[]>(
    defaultClues ? [...defaultClues] : []
  );

  const handleAddMore = () => {
    setClues((current) => {
      const next = [...current, { text: '', points: -25, clueId: uuidv4() }];

      onUpdate(next);
      return next;
    });
  };

  const handleUpdate = (updated: RoundRobinClue) => {
    setClues((current) => {
      const index = current.findIndex((c) => c.clueId === updated.clueId);
      if (index === -1) return current;
      const next = [...current];
      next[index] = updated;

      onUpdate(next);
      return next;
    });
  };

  const handleDelete = (updated: RoundRobinClue) => {
    setClues((current) => {
      const index = current.findIndex((c) => c.clueId === updated.clueId);
      if (index === -1) return current;
      const next = [...current];
      next.splice(index, 1);
      onUpdate(next);
      return next;
    });
  };

  return (
    <div className='w-full'>
      {clues.length > 0 && (
        <div className='w-4/5 flex flex-row gap-6 font-bold mb-2'>
          <div className='w-2/3'>Clues</div>
          <div className='w-1/3'>Cost</div>
        </div>
      )}
      <div className='w-4/5 flex flex-col gap-10'>
        {clues.map((clue, index) => (
          <QuestionClue
            defaultclue={clue}
            key={clue.clueId}
            index={index}
            handleUpdate={handleUpdate}
            handleDelete={handleDelete}
          />
        ))}
      </div>
      {clues.length < 4 && (
        <button
          type='button'
          className='btn underline text-icon-gray text-sms mt-6'
          onClick={handleAddMore}
        >
          Add Clue
        </button>
      )}
    </div>
  );
}

function QuestionCardManagement(props: {
  blockId: string;
  questions: RoundRobinQuestion[];
  showClues: boolean;
  onUpdate: (questions: RoundRobinQuestion[]) => void;
  mode: RoundRobinMode;
}): JSX.Element {
  const { blockId, questions, onUpdate, showClues } = props;

  const triggerFullScreenModal = useAwaitFullScreenConfirmCancelModal();

  const collapsedMap = useInstance(() => new Map<number, boolean>());
  const hasCollapsedQuestion = Array.from(collapsedMap.values()).find((v) => v);
  const forceUpdate = useForceUpdate();

  // reset collapsed map when blockId changes
  useEffect(() => {
    collapsedMap.clear();
  }, [blockId, collapsedMap]);

  const handleAdd = (): void => {
    onUpdate([
      ...questions,
      {
        question: '',
        answers: '',
        incorrectAnswers: [],
        answerOrder: {
          randomize: true,
          orderedAnswerIds: [],
        },
        questionMediaLoop: false,
        timeSec: 10,
        points: 10,
        createdAt: new Date().getTime(),
      },
    ]);
  };

  const handleDel = (createdAt: number): void => {
    const index = questions.findIndex((q) => q.createdAt === createdAt);
    if (index === -1) return;
    const next = [...questions];
    next.splice(index, 1);

    onUpdate(next);
  };

  const handleUpdate = (createdAt: number, updated: RoundRobinQuestion) => {
    const index = questions.findIndex((q) => q.createdAt === createdAt);
    if (index === -1) return;
    const next = [...questions];
    next[index] = updated;
    onUpdate(next);
  };

  const handleToggleCollapse = (createdAt: number, collapsed: boolean) => {
    collapsedMap.set(createdAt, collapsed);
    forceUpdate();
  };

  const handleToggleCollapseAll = () => {
    if (hasCollapsedQuestion) {
      collapsedMap.clear();
    } else {
      questions.forEach((q) => collapsedMap.set(q.createdAt, true));
    }
    forceUpdate();
  };

  const handleReorderCards = () => {
    triggerFullScreenModal({
      kind: 'custom',
      element: (p) => (
        <ReorderCardsModal
          questions={questions}
          onComplete={(reordered) => {
            onUpdate(reordered);
            p.internalOnConfirm();
          }}
          onClose={p.internalOnCancel}
        />
      ),
    });
    return;
  };

  return (
    <div className='w-full flex flex-col gap-3'>
      <header className='w-full flex items-center justify-between'>
        <h3 className='text-base font-bold'>
          Question Cards ({questions.length})
        </h3>
        <div className='flex gap-3'>
          <button
            type='button'
            className='btn text-sms'
            onClick={handleToggleCollapseAll}
          >
            {hasCollapsedQuestion ? 'Uncollapse All' : 'Collapse All'}
          </button>
          <button
            type='button'
            className='btn text-sms'
            onClick={handleReorderCards}
          >
            Reorder Cards
          </button>
        </div>
      </header>

      <main className='w-full flex flex-col gap-6'>
        {questions.map((question) => (
          <QuestionCard
            // Note: use createdAt as the identify of a question.
            // Should switch to question.id when we onboard id field.
            key={question.createdAt}
            blockId={blockId}
            question={question}
            showClues={showClues}
            onUpdate={(updated) => handleUpdate(question.createdAt, updated)}
            onDelete={() => handleDel(question.createdAt)}
            collapsed={!!collapsedMap.get(question.createdAt)}
            toggleCollapse={(val) =>
              handleToggleCollapse(question.createdAt, val)
            }
            mode={props.mode}
          />
        ))}
      </main>

      <footer>
        <button
          type='button'
          className='btn text-xs text-icon-gray underline font-light'
          onClick={handleAdd}
        >
          Add Question
        </button>
      </footer>
    </div>
  );
}

export function RoundRobinQuestionBlockEditor(
  props: EditorProps<RoundRobinQuestionBlock>
): JSX.Element | null {
  const { block } = props;
  const { updateFieldLocalFirst: updateField } = useEditor(props);
  const triggerFullScreenModal = useAwaitFullScreenConfirmCancelModal();
  const [version, setVersion] = useState(0);

  const handleFieldUpdate = (
    name: Parameters<typeof updateField>[0],
    value: Parameters<typeof updateField>[1]
  ) => {
    return updateField(name, value);
  };

  const handleHotSeatUIUpdate = async (checked: boolean): Promise<void> => {
    if (checked === true) {
      const havingClue = block.fields.questions.some(
        (question) => question.clues && question.clues.length > 0
      );
      if (havingClue) {
        const res = await triggerFullScreenModal({
          kind: 'confirm-cancel',
          prompt: (
            <div className='px-5 py-2'>
              <ConfirmCancelModalHeading>
                Are you sure you want to enable Hot Seat UI?
              </ConfirmCancelModalHeading>
              <ConfirmCancelModalText className='mt-4 text-sms font-normal'>
                All existing clues will be deleted
              </ConfirmCancelModalText>
            </div>
          ),
          confirmBtnLabel: 'Continue',
          confirmBtnVariant: 'delete',
          cancelBtnLabel: 'Cancel',
        });
        if (res.result === 'canceled') return;

        const questions = block.fields.questions.map((question) => ({
          ...question,
          clues: [],
        }));
        handleFieldUpdate('questions', questions);
      }
    }

    handleFieldUpdate('hotSeatUI', checked);
    setVersion(Date.now());
  };

  const handleChangeMode = async (value: string | number | null) => {
    if (typeof value !== 'string') return;
    if (value === RoundRobinMode.Race) {
      const res = await triggerFullScreenModal({
        kind: 'confirm-cancel',
        prompt: (
          <div className='px-5 py-2'>
            <ConfirmCancelModalHeading>Are you sure?</ConfirmCancelModalHeading>
            <div className='mt-4 text-sms font-normal text-white'>
              <div className='flex flex-col gap-2'>
                <div className='text-bold'>
                  Switch to Race Mode also applies the following changes:
                </div>
                <ul className='list-disc list-inside text-left'>
                  <li>The multiple choice answers will be deleted.</li>
                  <li>All question media will be ignored.</li>
                  <li>DPT will be disabled</li>
                  <li>Incorrect Answer Penalty will be disabled</li>
                  <li>Replay Incorrect Questions will be enabled</li>
                </ul>
              </div>
            </div>
          </div>
        ),
        confirmBtnLabel: 'Continue',
        confirmBtnVariant: 'delete',
        cancelBtnLabel: 'Cancel',
        boxDimensionsClassName: 'max-w-160 p-3',
      });
      if (res.result === 'canceled') return;

      const questions = block.fields.questions.map((question) => ({
        ...question,
        incorrectAnswers: [],
        answerOrder: null,
      }));
      await handleFieldUpdate('questions', questions);
      await handleFieldUpdate('decreasingPointsTimer', false);
      await handleFieldUpdate('replayIncorrectQuestions', true);
      await handleFieldUpdate('incorrectAnswerPenalty', false);
    }
    updateField('mode', value as RoundRobinMode | undefined);
  };

  const updateRaceWinPercentage = debounce((val: number) => {
    updateField('raceWinPercentage', val);
  }, 250);

  return (
    <EditorLayout
      bottomAccessory={
        <AdditionalSettings>
          <AdditionalSharedSettingsEditor {...props} />
        </AdditionalSettings>
      }
    >
      <EditorBody>
        <h2 className='text-2xl text-white mb-7'>Round Robin Q&A</h2>
        <div
          // Note: use div instead of form to prevent some form default behaviors.
          // For example, when click Enter key, it will redirect to another page.
          className='w-full my-7.5 flex text-white'
        >
          <section className='w-3/5 flex flex-col gap-8'>
            <div className='w-full flex items-center gap-4'>
              <label htmlFor='mode' className='w-1/2'>
                <RHFSelectField<RoundRobinQuestionBlock>
                  className='w-full h-10 text-white'
                  label='Game Mode'
                  name='mode'
                  options={modeOptions}
                  onChange={(_, value) => handleChangeMode(value)}
                  value={block.fields.mode ?? RoundRobinMode.Default}
                />
              </label>
              {block.fields.mode === RoundRobinMode.Race && (
                <label htmlFor='raceWinPercentage' className='w-1/2'>
                  <span className='text-white font-bold'>
                    Winning Percentage
                  </span>
                  <UncontrolledRangeInput
                    name={`${block.id}-race-win-percentage`}
                    className='flex justify-between items-center w-full gap-2'
                    min={1}
                    max={100}
                    step={1}
                    defaultValue={block.fields.raceWinPercentage || 100}
                    onChange={(value) => updateRaceWinPercentage(value)}
                  />
                </label>
              )}
            </div>

            <QuestionCardManagement
              key={`${block.id}-${version}`}
              blockId={block.id}
              questions={block.fields.questions}
              showClues={block.fields.hotSeatUI === false}
              onUpdate={(questions) =>
                handleFieldUpdate('questions', questions)
              }
              mode={block.fields.mode ?? RoundRobinMode.Default}
            />
          </section>

          <section className='w-2/5 flex flex-col items-center'>
            <label htmlFor='background-media' className='my-2'>
              <BlockMediaEditor<RoundRobinQuestionBlockMedia>
                blockId={block.id}
                title='Background Media'
                field='backgroundMedia'
                video
                volumeSelectable
                loopSelectable
                scene={EnumsMediaScene.MediaSceneBlockBackground}
                mediaData={block.fields.backgroundMediaData}
                media={block.fields.backgroundMedia}
                extraNotice='Media will display and play in the background during the Game Timer.'
              />
            </label>
            <label htmlFor='goal-animation-media' className='my-2'>
              <BlockMediaEditor<RoundRobinQuestionBlockMedia>
                blockId={block.id}
                title='Completion Animation'
                field='goalAnimationMedia'
                image={false}
                video={true}
                scene={EnumsMediaScene.MediaSceneBlockMedia}
                mediaData={block.fields.goalAnimationMediaData}
                media={block.fields.goalAnimationMedia}
                extraNotice='Media will play upon successfully answering all the questions of the Block.'
              />
            </label>
            {block.fields.mode === RoundRobinMode.Race && (
              <label htmlFor='race-animation-media' className='my-2'>
                <BlockMediaEditor<RoundRobinQuestionBlockMedia>
                  blockId={block.id}
                  title='Race Unit'
                  field='raceUnitMedia'
                  image={true}
                  video={false}
                  scene={EnumsMediaScene.MediaSceneBlockMedia}
                  mediaData={block.fields.raceUnitMediaData}
                  media={block.fields.raceUnitMedia}
                />
              </label>
            )}
            <hr className='w-full my-5 border border-secondary' />
            <label htmlFor='gameTimeSec' className='w-5/6 my-2'>
              <CreatableDurationSelect<RoundRobinQuestionBlock, 'gameTimeSec'>
                label='Game Timer'
                name='gameTimeSec'
                description='For a custom duration, enter the desired time in seconds and press Enter.'
                value={block.fields.gameTimeSec}
                onChange={(_, value) => {
                  if (typeof value !== 'number') return;
                  updateField('gameTimeSec', value);
                }}
                options={gameTimeOptions}
              />
            </label>
            <label htmlFor='clueTimeSec' className='w-5/6 my-2'>
              <CreatableDurationSelect<RoundRobinQuestionBlock, 'clueTimeSec'>
                label='Clue Timer'
                name='clueTimeSec'
                options={clueTimeOptions}
                onChange={(_, value) => {
                  if (typeof value !== 'number') return;
                  updateField('clueTimeSec', value);
                }}
                value={block.fields.clueTimeSec}
              />
              <div className='w-full mt-2 text-icon-gray text-xs font-normal'>
                This timer sets how long to wait on each question before showing
                that question’s clues. For a custom duration, enter the desired
                time in seconds and press Enter.
              </div>
            </label>
            <label htmlFor='decreasingPointsTimer' className='w-5/6 my-2'>
              <RHFCheckbox<RoundRobinQuestionBlock>
                label='Decreasing Points Timer'
                name='decreasingPointsTimer'
                value={block.fields.decreasingPointsTimer}
                onChange={(_, checked: boolean): void => {
                  handleFieldUpdate('decreasingPointsTimer', checked);
                }}
                description={{
                  enabled:
                    'Enabled: Amount of points earned for submitting the correct answer decreases as the Game Timer runs out.',
                  disabled:
                    'Disabled: Amount of points earned for submitting the correct answer does not change as the Question timer runs out.',
                }}
                disabled={block.fields.mode === RoundRobinMode.Race}
              />
            </label>
            {block.fields.decreasingPointsTimer && (
              <label className='w-5/6 my-2'>
                <RHFCheckbox<RoundRobinQuestionBlock>
                  label='Start Descending Immediately'
                  name='startDescendingImmediately'
                  value={block.fields.startDescendingImmediately ?? false}
                  onChange={(_, checked: boolean): void => {
                    updateField('startDescendingImmediately', checked);
                  }}
                  description={{
                    enabled: 'Enabled: Points start descending immediately',
                    disabled:
                      'Disabled: Points start descending after 25% of time.',
                  }}
                />
              </label>
            )}
            <label htmlFor='' className='w-5/6 my-2'>
              <RHFCheckbox<RoundRobinQuestionBlock>
                label='Incorrect Answer Penalty'
                name='incorrectAnswerPenalty'
                value={block.fields.incorrectAnswerPenalty}
                onChange={(_, checked: boolean): void => {
                  handleFieldUpdate('incorrectAnswerPenalty', checked);
                }}
                description={
                  block.fields.incorrectAnswerPenalty
                    ? 'Enabled: Incorrect answers or failure to submit an answer will result in the value of the question being subtracted instead of added.'
                    : 'Disabled: No penalty for getting an answer incorrect or not submitting an answer.'
                }
                disabled={block.fields.mode === RoundRobinMode.Race}
              />
            </label>
            <label htmlFor='randomizeQuestions' className='w-5/6 my-2'>
              <RHFCheckbox<RoundRobinQuestionBlock>
                label='Randomize Questions'
                name='randomizeQuestions'
                value={block.fields.randomizeQuestions}
                onChange={(_, checked: boolean): void => {
                  handleFieldUpdate('randomizeQuestions', checked);
                }}
                description={
                  block.fields.randomizeQuestions
                    ? 'Enabled: Question cards will be shown in a random order.'
                    : 'Disabled: Question cards will be shown in the order they are arranged.'
                }
              />
            </label>
            <label htmlFor='randomizeQuestions' className='w-5/6 my-2'>
              <RHFCheckbox<RoundRobinQuestionBlock>
                label='Rapid Submissions'
                name='rapidSubmissions'
                value={block.fields.rapidSubmissions}
                onChange={(_, checked: boolean): void => {
                  handleFieldUpdate('rapidSubmissions', checked);
                }}
                description={
                  block.fields.rapidSubmissions
                    ? 'Enabled: Enabled: Players can submit multiple answers per question until they get it correct. DOES NOT WORK FOR MULTIPLE CHOICE'
                    : 'Disabled: Players can submit only one answer per question.'
                }
              />
            </label>
            <label htmlFor='hotSeatUI' className='w-5/6 my-2'>
              <RHFCheckbox<RoundRobinQuestionBlock>
                label='Hot Seat UI'
                name='hotSeatUI'
                value={block.fields.hotSeatUI}
                onChange={(_, checked: boolean): void => {
                  handleHotSeatUIUpdate(checked);
                  // handleFiledUpdate('hotSeatUI', checked);
                }}
                description={
                  block.fields.hotSeatUI
                    ? 'Enabled: Add Hot Seat badge and “Up Next” cards.'
                    : 'Disabled: Display “GUESSER” badge and “Up Next” cards are hidden.'
                }
              />
            </label>
            <label htmlFor='replayIncorrectQuestions' className='w-5/6 my-2'>
              <RHFCheckbox<RoundRobinQuestionBlock>
                label='Replay Incorrect Questions'
                name='replayIncorrectQuestions'
                value={block.fields.replayIncorrectQuestions}
                onChange={(_, checked: boolean): void => {
                  handleFieldUpdate('replayIncorrectQuestions', checked);
                }}
                description={
                  block.fields.replayIncorrectQuestions
                    ? 'Enabled: Show incorrectly answered questions again at the end of the question list.'
                    : 'Disabled: Incorrectly do not return again at the end of the question list.'
                }
                disabled={block.fields.mode === RoundRobinMode.Race}
              />
            </label>
            <label htmlFor='questionsSkippable' className='w-5/6 my-2'>
              <RHFCheckbox<RoundRobinQuestionBlock>
                label='Questions are Skippable'
                name='questionsSkippable'
                value={block.fields.questionsSkippable}
                onChange={(_, checked: boolean): void => {
                  handleFieldUpdate('questionsSkippable', checked);
                }}
                description={
                  block.fields.questionsSkippable
                    ? 'Enabled: Users can click the skip button to pass on a question without losing points.'
                    : 'Disabled: Skip button is not available.'
                }
              />
            </label>
            <label htmlFor='muteBackgroundMusic' className='w-5/6 my-2'>
              <RHFCheckbox<RoundRobinQuestionBlock>
                label='Mute Background Music'
                name='muteBackgroundMusic'
                value={block.fields.muteBackgroundMusic ?? false}
                onChange={(_, checked: boolean): void => {
                  updateField('muteBackgroundMusic', checked);
                }}
                description={`When enabled, venue background music will be muted.`}
              />
            </label>
            <label htmlFor='hideAnswerField' className='w-5/6 my-2'>
              <RHFCheckbox<RoundRobinQuestionBlock>
                label='Hide Answer Field'
                name='hideAnswerField'
                value={block.fields.hideAnswerField ?? false}
                onChange={(_, checked: boolean): void => {
                  updateField('hideAnswerField', checked);
                }}
                description={`When enabled, the answer text field will be hidden`}
              />
            </label>
          </section>
        </div>
      </EditorBody>
    </EditorLayout>
  );
}
