import { useEffect, useRef } from 'react';

import { createGameLoop, type LoopOptions } from './GameLoop';

export function useGameLoop(running: boolean, options: LoopOptions): null {
  const loopRef = useRef<ReturnType<typeof createGameLoop> | null>(null);

  const { drawTime, updateTime, draw, update, panicAt, onPanic, onFPS } =
    options;

  useEffect(() => {
    if (loopRef.current) loopRef.current.stop();

    loopRef.current = createGameLoop({
      drawTime,
      updateTime,
      draw,
      update,
      panicAt,
      onPanic,
      onFPS,
    });

    if (!running) {
      loopRef.current.stop();
      loopRef.current = null;
    }

    return () => {
      if (loopRef.current) loopRef.current.stop();
      loopRef.current = null;
    };
  }, [draw, drawTime, onFPS, onPanic, panicAt, running, update, updateTime]);

  return null;
}
