import {
  type DtoGameStateForVoiceOver,
  type EnumsBrandPredefinedBlockScenario,
} from '@lp-lib/api-service-client/public';
import { type BlockRecording, type TitleCard } from '@lp-lib/game';

import { type Participant, type Team } from '../../../types';
import { type VariableRegistry } from '../../VoiceOver/VariableRegistry';
import { type VoiceOverRegistryPlan } from '../../VoiceOver/VoiceOverRegistryPlan';

export type GenerateV3RecordingOpts = {
  waitForPreparedBlock: boolean;
  scenario?: EnumsBrandPredefinedBlockScenario | null;
  aiHostVoiceId?: string | null;
  voiceOverPlans?: { plan: VoiceOverRegistryPlan; tags: string[] }[];
};

export type BlockRecordingExtra = {
  titleCardsToTeams?: Record<TitleCard['id'], Team['id']>;
  titleCardsToVoiceOverPlans?: Record<TitleCard['id'], VoiceOverRegistryPlan>;
  titleCardsToVoiceOverDelayStartMs?: Record<TitleCard['id'], number>;
};

export function makeBlockRecordingExtra(
  initialValues?: Partial<BlockRecordingExtra>
): BlockRecordingExtra {
  return {
    ...initialValues,
  };
}

export type GeneratedBlockRecording = {
  recording: BlockRecording;
  extra: undefined | BlockRecordingExtra;
};

export type GameInfoSnapshot = {
  teamCount: number;
  pauseOnEndBlockMaxWaitSec: number;
  participants: Participant[];
  variableRegistry: VariableRegistry;
  cohost: Participant | null;
} & DtoGameStateForVoiceOver;
