import { type BoundingBoxSettings } from '@lp-lib/game';

import { HiddenCanvas } from '../../../utils/canvas';
import { CanvasImageSourceFrameBuffer } from '../CanvasImageSourceFrameBuffer';
import { DrawableCanvasVideoFrameBuffer } from '../DrawableCanvasVideoFrameBuffer';
import { FitOperation } from '../FitOperation';
import { calcCanvasDrawable } from '../utils/calcCanvasDrawable';
import { matchDimensionsIfNeeded } from '../utils/matchDimensionsIfNeeded';
import type VideoFrameBuffer from '../vendor/amazon-chime-sdk-js/VideoFrameBuffer';
import type VideoFrameProcessor from '../vendor/amazon-chime-sdk-js/VideoFrameProcessor';

type BoundingBoxSettingsEx = BoundingBoxSettings & { stroke?: `#${string}` };

export class LoopingSourceProcessor implements VideoFrameProcessor {
  private canvasVideoFrameBuffer = new DrawableCanvasVideoFrameBuffer(
    new HiddenCanvas('looping-video-processor')
  );

  private source: CanvasImageSourceFrameBuffer | null = null;
  private boundingBox: BoundingBoxSettingsEx | null = null;
  private mediaController: HTMLVideoElement | null = null;
  public enabled = true;

  constructor(public ordering: 'under' | 'over' = 'under') {}

  setSource(
    source: HTMLImageElement | HTMLVideoElement | HTMLCanvasElement
  ): void {
    if (this.source) this.source.destroy();
    if (this.mediaController) this.mediaController = null;

    this.source = new CanvasImageSourceFrameBuffer(source);
    if (source instanceof HTMLVideoElement) {
      this.mediaController = source;
    }
  }

  setBoundingBox(boundingBox: BoundingBoxSettingsEx): void {
    this.boundingBox = boundingBox;
  }

  play(): void {
    if (this.mediaController && this.mediaController.paused) {
      this.mediaController.play();
    }
  }

  pause(): void {
    if (this.mediaController && !this.mediaController.paused) {
      this.mediaController.pause();
    }
  }

  process(buffers: VideoFrameBuffer[]): VideoFrameBuffer[] {
    const ctx = this.canvasVideoFrameBuffer.ctx;
    if (!ctx || !this.source || buffers.length === 0 || !this.enabled)
      return buffers;

    // Ensure the work buffer has a relevant size, using the first buffer
    const first = buffers[0];
    matchDimensionsIfNeeded(first, this.canvasVideoFrameBuffer);

    const width = this.canvasVideoFrameBuffer.width;
    const height = this.canvasVideoFrameBuffer.height;

    // Clear between iterations
    ctx.clearRect(0, 0, width, height);

    // Position the background video a 1:1 scale, directly in the center. Note:
    // videoWidth/videoHeight is guaranteed to be an integer value, so
    // floor(x,y) is safe and does not lose or add pixels.

    const boundingBox = this.boundingBox ?? {
      box: { width: 1, height: 1, x: 0, y: 0 },
      fit: 'cover',
    };

    const { sx, sy, sw, sh, destX, destY, destW, destH } = calcCanvasDrawable(
      boundingBox.box,
      FitOperation[boundingBox.fit],
      {
        width,
        height,
      },
      {
        width: this.source.width,
        height: this.source.height,
      },
      { top: 0, right: 0, bottom: 0, left: 0 }
    );

    if (this.ordering === 'under') {
      // First copy the "background" video
      const source = this.source.asCanvasImageSource();
      if (source)
        ctx.drawImage(source, sx, sy, sw, sh, destX, destY, destW, destH);
    }

    // Then composite the incoming, over
    for (const b of buffers) {
      const source = b.asCanvasImageSource();
      if (source)
        ctx.drawImage(source, 0, 0, width, height, 0, 0, width, height);
    }

    if (this.ordering === 'over') {
      // Then copy the "background" video
      const source = this.source.asCanvasImageSource();
      if (source)
        ctx.drawImage(source, sx, sy, sw, sh, destX, destY, destW, destH);
    }

    if (this.boundingBox?.stroke) {
      const boundingBoxPx = {
        x: boundingBox.box.x * width,
        y: boundingBox.box.y * height,
        width: boundingBox.box.width * width,
        height: boundingBox.box.height * height,
      };
      ctx.strokeStyle = this.boundingBox.stroke;
      ctx.lineWidth = 2;
      ctx.strokeRect(
        boundingBoxPx.x,
        boundingBoxPx.y,
        boundingBoxPx.width,
        boundingBoxPx.height
      );
    }

    buffers[0] = this.canvasVideoFrameBuffer;
    return buffers;
  }

  async destroy(): Promise<void> {
    this.canvasVideoFrameBuffer.destroy();
    this.source = null;
  }
}
