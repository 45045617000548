interface ScreenProps {
  className?: string;
  title: string;
  content: string | React.ReactNode;
  children?: React.ReactNode;
}

const Screen = ({
  className,
  title,
  content,
  children,
}: ScreenProps): JSX.Element => {
  return (
    <div className='w-full h-full flex flex-col justify-center items-center text-white'>
      <div
        className={`relative w-1/2 min-h-40 bg-welcome bg-no-repeat bg-center bg-stretch flex flex-col items-center justify-center ${
          className ? className : ''
        }`}
      >
        <span className='absolute -top-5 font-cairo font-extrabold text-4.5xl text-white'>
          {title}
        </span>
        <span className='absolute -top-4 font-cairo font-extrabold text-4.5xl text-white text-opacity-40'>
          {title}
        </span>
        <div className='w-full p-10 flex flex-col items-center justify-center'>
          <div className='max-h-100 overflow-y-scroll scrollbar text-center font-bold'>
            {content}
          </div>
          {children}
        </div>
      </div>
    </div>
  );
};

export { Screen };
