import { useEffect } from 'react';

import { useHostClientId } from '../../Player';
import { useRTCService } from '../../WebRTC';
import { type BlockRecorderState } from '../types';

function clearMediaStream(stream: MediaStream | null) {
  stream?.getTracks().forEach((track) => stream.removeTrack(track));
}

export function useGrabHostMediaStream(writable: BlockRecorderState): void {
  const uid = useHostClientId();
  const rtcService = useRTCService('stage');

  useEffect(() => {
    const updateStream = () => {
      // Always make a new stream for the BlockRecorder to be able to grab when
      // needed. When recording starts, the MediaRecorder uses the stream just
      // for a track snapshot. The recording will only be affected if the tracks
      // are stopped: don't do that! If they are not, the recording will
      // complete successfully, upload, and the tracks will be released
      // automatically once the MediaRecorder is garbage collected. This
      // implicitly ties the lifetime of the tracks (and therefore the camera
      // light) to the lifetime of the IDBChunkedStreamRecorder. This is not
      // ideal, but is a reasonable compromise (compared to: throwing errors,
      // halting recording, resetting the block system, etc) until a rework of
      // the recording system is completed.

      // NOTE: it is expected that the MediaRecorder does not respect "camera
      // mute", since it's stated in the spec that changing tracks currently is
      // not allowed. Setting .enabled=false on a track will also pause the
      // MediaRecorder and not allow .stop() to be called, invalidating the
      // upload. Mic mute is allowed because the MediaStreamTrack is not being
      // stopped, an upstream controller will always emit silence if the
      // originating device track is stopped.

      if (writable.refs.hostVideoStream) {
        clearMediaStream(writable.refs.hostVideoStream);
      }

      const tracks = rtcService.getTracksByUid(uid).filter(Boolean);
      const stream = tracks.length ? new MediaStream() : null;

      for (const track of tracks) {
        if (stream && track) {
          const mst = track.getMediaStreamTrack();
          if (mst.enabled && mst.readyState !== 'ended') stream.addTrack(mst);
        }
      }

      writable.refs.hostVideoStream = stream;
    };

    updateStream();
    rtcService.on('audio-switched', updateStream);
    rtcService.on('audio-toggled', updateStream);
    rtcService.on('video-switched', updateStream);
    rtcService.on('video-toggled', updateStream);
    return () => {
      rtcService.off('audio-switched', updateStream);
      rtcService.off('audio-toggled', updateStream);
      rtcService.off('video-switched', updateStream);
      rtcService.off('video-toggled', updateStream);
    };
  }, [rtcService, uid, writable.refs]);

  useEffect(() => {
    return () => {
      clearMediaStream(writable.refs.hostVideoStream);
      writable.refs.hostVideoStream = null;
    };
  }, [writable.refs]);
}
