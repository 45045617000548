import {
  createContext,
  type ReactNode,
  useContext,
  useEffect,
  useMemo,
} from 'react';

import { useIsCoordinator } from '../../../hooks/useMyInstance';
import { type EmitterListener } from '../../../utils/emitter';
import { type FirebaseEvents, type FirebaseService } from '../../Firebase';
import { useVenueId } from '../../Venue/VenueProvider';
import { OndGameControllerSemaphore } from './controller';
import { log } from './shared';

type OnDGameControlAPI = {
  controllerSemaphore: OndGameControllerSemaphore;
};

const ControlContext = createContext<null | OnDGameControlAPI>(null);

function useOnDGameControlContext(): OnDGameControlAPI {
  const ctx = useContext(ControlContext);
  if (!ctx) throw new Error('OnDGameControlContext is not in the tree!');
  return ctx;
}

export function useOnDGameControllerSemaphore(): OndGameControllerSemaphore {
  return useOnDGameControlContext().controllerSemaphore;
}

export function OnDGameControlProvider(props: {
  ready: boolean;
  svc: FirebaseService;
  emitter: EmitterListener<FirebaseEvents>;
  children?: ReactNode;
}): JSX.Element {
  const { ready, svc, emitter } = props;
  const venueId = useVenueId();
  const isCoordinator = useIsCoordinator();

  const controllerSemaphore = useMemo(
    () => new OndGameControllerSemaphore(venueId, { svc, emitter }, log),
    [venueId, svc, emitter]
  );

  useEffect(() => {
    if (!ready) return;
    log.info('start watching');
    controllerSemaphore.watch();
    return () => {
      controllerSemaphore.unwatch();
      log.info('stop watching');
    };
  }, [controllerSemaphore, ready]);

  useEffect(() => {
    if (!isCoordinator) return;
    controllerSemaphore.updateHeartbeatLogging({ receiveEnabled: true });
    return () => {
      controllerSemaphore.updateHeartbeatLogging({ receiveEnabled: false });
    };
  }, [controllerSemaphore, isCoordinator]);

  useEffect(() => {
    return () => {
      controllerSemaphore.dispose();
    };
  }, [controllerSemaphore]);

  const ctx: OnDGameControlAPI = useMemo(
    () => ({
      controllerSemaphore,
    }),
    [controllerSemaphore]
  );

  return (
    <ControlContext.Provider value={ctx}>
      {props.children}
    </ControlContext.Provider>
  );
}
