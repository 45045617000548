import React, {
  type ReactNode,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useSnapshot } from 'valtio';

import { useInterval } from '../../hooks/useInterval';
import { useClock } from '../Clock';
import { useGameSessionBlock } from '../Game/hooks';
import { ExperienceScoreManager } from './ExperienceScoreManager';

type ExperienceScoreContext = { manager: ExperienceScoreManager };
const Context = React.createContext<null | ExperienceScoreContext>(null);

export function ExperienceScoreProvider(props: {
  children?: ReactNode;
}): JSX.Element {
  const clock = useClock();
  const [ctx] = useState(() => ({
    manager: new ExperienceScoreManager(clock.now),
  }));

  useEffect(() => {
    return () => {
      ctx.manager.destroy();
    };
  }, [ctx.manager]);

  return <Context.Provider value={ctx}>{props.children}</Context.Provider>;
}

function useExperienceScoreContext() {
  const ctx = useContext(Context);
  if (!ctx) throw new Error('ExperienceScoreProvider not in tree!');
  return ctx;
}

/**
 * This is side-effect free, meaning it only returns the detractPct from the
 * last time it was computed. It does not update the computation.
 *
 * @returns 0-100 integer value (stable) representing the percentage of time
 * spent on events detracting from the user experience.
 */
export function useEXPSDetractorPct100(): number {
  const ctx = useExperienceScoreContext();
  const detractPct = useSnapshot(ctx.manager.listenable()).detractPct;
  return Math.floor(detractPct * 100);
}

/**
 * This is probably only useful for debug tools.
 */
export function useEXPSDetractorPctPoller(intervalMs = 1000): number {
  const ctx = useExperienceScoreContext();
  const [pct, setPct] = useState(0);
  useInterval(
    () => setPct(Math.floor(ctx.manager.pollDetractorPct() * 100)),
    intervalMs
  );
  return pct;
}

export function useExperienceScoreAPI(): ExperienceScoreManager {
  const ctx = useExperienceScoreContext();
  return ctx.manager;
}

export function ExperienceScoreBlockEndEmitter() {
  const block = useGameSessionBlock();
  const api = useExperienceScoreAPI();
  const prev = useRef<string | null>(null);
  const { id, type } = block ?? { id: null };

  useEffect(() => {
    if (prev.current === id) return;
    prev.current = id;

    if (!id) return;

    api.emitLog(type, id);
  }, [api, id, type]);

  return null;
}
