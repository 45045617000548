import { getFeatureQueryParamArray } from '../../hooks/useFeatureQueryParam';
import { useHostClientId, useParticipantByClientId } from '../Player';
import { useDebugStreamContext } from './RTCServiceContext';
import { StreamStats } from './StreamStats';
import { type DebugStream } from './types';

const isDebugging = getFeatureQueryParamArray('debug-tools') !== 'disabled';

function Header(props: DebugStream): JSX.Element {
  const { uid, rtcService } = props;
  const ctx = useDebugStreamContext();
  const participant = useParticipantByClientId(uid as string);
  const hostClientId = useHostClientId();
  const handleClose = () => {
    ctx.removeDebugStream(uid, rtcService);
  };
  return (
    <div className='text-white text-xs px-3 py-1 flex justify-between'>
      <div className='flex'>
        <div className='mr-2'>
          [{rtcService.isLocalUser(uid) ? 'local' : 'remote'}]
        </div>
        <div className='mr-4 truncate max-w-30'>
          {uid === hostClientId ? '🎤' : '👤'}{' '}
          <strong>{participant?.username}</strong>
        </div>
        <div>
          📶 <strong>{rtcService.name}</strong>
        </div>
      </div>
      <div>
        <button
          type='button'
          className='btn cursor-pointer'
          onClick={handleClose}
        >
          [x]
        </button>
      </div>
    </div>
  );
}

function FloatingStreamStats(props: DebugStream): JSX.Element | null {
  return (
    <div className='bg-black bg-opacity-70 w-80 h-56 flex-shrink-0 flex-grow-0 pointer-events-on'>
      <Header {...props} />
      <StreamStats {...props} />
    </div>
  );
}

export function DebugStreams(): JSX.Element | null {
  const ctx = useDebugStreamContext();

  if (!isDebugging) return null;
  if (ctx.streams.length === 0) return null;
  return (
    <div className='w-full h-full absolute z-50 pointer-events-off flex flex-wrap content-start'>
      {ctx.streams.map((stream) => (
        <FloatingStreamStats key={stream.id} {...stream} />
      ))}
    </div>
  );
}
