import { ConnectionStatus } from '@lp-lib/shared-schema';

import { type Participant } from '../../types';
import { type MemberMap, type MemberVOMap, type StageMemberVO } from './types';
import { uncheckedIndexAccess_UNSAFE } from '../../utils/uncheckedIndexAccess_UNSAFE';

export class StageUtils {
  static GetMemberVO = (
    participant: Participant | undefined,
    member: StageMemberVO
  ): StageMemberVO | null => {
    if (!member || !participant) return null;
    return {
      ...member,
      status: participant.status,
      disconnectedAt: participant.disconnectedAt,
    };
  };
  static GetMemberVOMap = (
    participants: Participant[],
    members: MemberMap
  ): MemberVOMap => {
    const memberVOMap = {};
    const pMap = participants.reduce(
      (map, p) => map.set(p.clientId, p),
      new Map<string, Participant>()
    );
    for (const [, member] of Object.entries(members)) {
      const memberVO = StageUtils.GetMemberVO(pMap.get(member.id), member);
      if (memberVO)
        uncheckedIndexAccess_UNSAFE(memberVOMap)[member.id] = memberVO;
    }
    return memberVOMap;
  };
  static GetMembersCount = (memberVOMap: MemberVOMap): number => {
    let membersCount = 0;
    for (const memberId in memberVOMap) {
      if (
        memberVOMap[memberId].id &&
        memberVOMap[memberId].status === ConnectionStatus.Connected
      ) {
        membersCount++;
      }
    }
    return membersCount;
  };
}
