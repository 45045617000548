import {
  type CSSProperties,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useLatest, usePreviousDistinct } from 'react-use';

import { ProfileIndex } from '@lp-lib/crowd-frames-schema';
import {
  getTimedScoringFunction,
  getTimedScoringKind,
  type MemoryMatchBlock,
} from '@lp-lib/game';

import {
  getFeatureQueryParam,
  getFeatureQueryParamNumber,
} from '../../../../hooks/useFeatureQueryParam';
import { useInstance } from '../../../../hooks/useInstance';
import { useIsMounted } from '../../../../hooks/useIsMounted';
import { useLiveCallback } from '../../../../hooks/useLiveCallback';
import { useMyInstance } from '../../../../hooks/useMyInstance';
import { type TeamId } from '../../../../types';
import { getStaticAssetPath } from '../../../../utils/assets';
import { BrowserTimeoutCtrl } from '../../../../utils/BrowserTimeoutCtrl';
import { assertExhaustive } from '../../../../utils/common';
import { loadImageAsPromise } from '../../../../utils/media';
import { CrowdFramesAvatar } from '../../../CrowdFrames';
import { FilledCheckIcon } from '../../../icons/CheckIcon';
import { EyeIcon } from '../../../icons/EyeIcon';
import { useMyTeamId, useParticipant } from '../../../Player';
import { useSoundEffect } from '../../../SFX';
import {
  useIsTeamCaptainScribe,
  useTeamCaptainParticipant,
} from '../../../TeamAPI/TeamV1';
import { useMyClientId } from '../../../Venue/VenuePlaygroundProvider';
import { useGameSessionLocalTimer } from '../../hooks';
import { AutoScale } from '../Common/GamePlay/ContainLayout';
import { useGamePlayEmitter } from '../Common/GamePlay/GamePlayProvider';
import { ProgressRing } from '../Common/GamePlay/ProgressRing';
import { useTeamSubmissionStatusAPI } from '../Common/GamePlay/SubmissionStatusProvider';
import {
  useMemoryMatchCards,
  useMemoryMatchGame,
  useMemoryMatchGamePlayAPI,
  usePairAutoGrading,
} from './MemoryMatchProvider';
import {
  type Card,
  type CardUIState,
  type FlipAnimation,
  GameState,
  type GridCard,
  type RevealUIState,
  type ShakeAnimation,
} from './types';
import { aLog, MemoryMatchStyle, MemoryMatchUtils } from './utils';

function CardBadge(props: {
  type: 'default' | 'matched';
  text?: string;
}): JSX.Element {
  const style =
    props.type === 'matched'
      ? { fill: '#39D966', content: <FilledCheckIcon /> }
      : {
          fill: '#1B1B1E',
          content: <div className='text-tertiary font-bold'>{props.text}</div>,
        };
  return (
    <div className='absolute w-[35px] h-[30px] bottom-0 right-0 flex items-center justify-center'>
      <svg
        className='absolute w-full h-full'
        xmlns='http://www.w3.org/2000/svg'
        fill='none'
        viewBox='0 0 35 30'
      >
        <path
          fill={style.fill}
          d='M0 20C0 8.954 8.954 0 20 0h15v18c0 6.627-5.373 12-12 12H0V20z'
        ></path>
      </svg>
      <div className='absolute ml-1'>{style.content}</div>
    </div>
  );
}

function CardContent(props: {
  url: string;
  debugUrl?: string;
  visible?: boolean;
  blur?: boolean;
  type: 'front' | 'back';
  transform?: CSSProperties['transform'];
}): JSX.Element {
  useEffect(() => {
    loadImageAsPromise(props.url);
  }, [props.url]);
  return (
    <div
      className={`absolute w-full h-full rounded-xl overflow-hidden`}
      style={{
        backfaceVisibility: 'hidden',
        transform: props.transform,
      }}
    >
      <div
        className='w-full h-full rounded-xl'
        style={{
          backgroundImage: `url(${props.url})`,
          backgroundColor: 'white',
          backgroundPosition: 'center',
          backgroundSize: props.type === 'front' ? 'cover' : 'contain',
          backgroundRepeat: 'no-repeat',
          filter: props.blur ? 'blur(80px)' : 'none',
        }}
      />
      {props.debugUrl && (
        <img
          src={props.debugUrl}
          alt=''
          className='absolute left-0 bottom-0 z-5 w-10 h-auto'
        />
      )}
    </div>
  );
}

function CardViewedBy(props: {
  teamId: TeamId;
  clientId: string;
  transform: CSSProperties['transform'];
}): JSX.Element | null {
  const { teamId, clientId, transform } = props;
  const participant = useParticipant(clientId);
  if (teamId !== participant?.teamId) return null;
  return (
    <div
      className='absolute w-full h-full rounded-xl bg-black bg-opacity-40 flex flex-col items-center justify-center'
      style={{ transform }}
    >
      <div className='text-3xs font-bold w-full truncate text-center mb-1'>
        <EyeIcon className='inline-block w-3.5 h-3.5 fill-current mr-0.5' />
        Viewable by{' '}
        <span className='text-tertiary'>{participant.username}</span>
      </div>
      <div className='w-1/3 relative'>
        <div className='aspect-w-1 aspect-h-1'>
          <CrowdFramesAvatar
            participant={participant}
            profileIndex={ProfileIndex.wh100x100fps8}
            enablePointerEvents={false}
          />
        </div>
      </div>
    </div>
  );
}

function OnlyYouCanSee(): JSX.Element {
  return (
    <div className='bg-red-001 w-[calc(100%+2px)] h-[calc(100%+24px)] absolute top-[-23px] -left-px rounded-xl text-center text-3xs font-bold'>
      <div className='mt-1.5'>
        <EyeIcon className='inline-block w-3.5 h-3.5 fill-current mr-0.5' />
        Only you can see
      </div>
    </div>
  );
}

function useCardUIState(card: Card): CardUIState {
  return useMemo<CardUIState>(() => {
    switch (card.matched) {
      case 'done':
        return {
          state: 'done',
        };
      case 'wrong':
        return {
          state: 'wrong',
        };
      case 'unknown':
        break;
      default:
        assertExhaustive(card.matched);
        break;
    }
    if (!card.reveal) {
      return {
        state: 'unreveal',
      };
    }
    const revealTarget = card.reveal.target;
    switch (revealTarget) {
      case 'all':
        return {
          state: 'reveal-to-all',
        };
      case 'someone':
        return {
          state: 'reveal-to-someone',
          memberId: card.reveal.memberId,
        };
      default:
        assertExhaustive(revealTarget);
        return {
          state: 'unreveal',
        };
    }
  }, [card.reveal, card.matched]);
}

function useRevealUIState(
  index: number,
  cardUIState: CardUIState,
  animation: { flip: FlipAnimation; shake: ShakeAnimation },
  cancelShakeWhenUnrevealWrongMatch = getFeatureQueryParam(
    'memory-match-cancel-shake-when-unreveal-wrong-match'
  ),
  log = aLog
): [boolean, boolean, RevealUIState] {
  const mounted = useIsMounted();
  const myClientId = useMyClientId();
  const revealTo =
    cardUIState.state === 'reveal-to-someone' ? cardUIState.memberId : null;
  const [revealCard, setRevealCard] = useState(false);
  const prevRevealCard = usePreviousDistinct(revealCard);
  const [shake, setShake] = useState(false);
  const [state, setState] = useState<RevealUIState>(null);
  const shakeTimer = useRef<ReturnType<typeof setTimeout>>();
  const flipCtrl = useInstance(() => new BrowserTimeoutCtrl());

  useEffect(() => {
    const state = cardUIState.state;
    switch (state) {
      case 'unreveal':
        if (shakeTimer.current && cancelShakeWhenUnrevealWrongMatch) {
          clearTimeout(shakeTimer.current);
        }
        setRevealCard(false);
        setShake(false);
        log.debug(`card #${index}, set reveal/shake false from unreveal state`);
        break;
      case 'done':
      case 'reveal-to-all':
      case 'reveal-to-someone':
        setRevealCard(true);
        log.debug(`card #${index}, set revealed true`);
        break;
      case 'wrong':
        log.debug(`card #${index}, wrong`);
        shakeTimer.current = setTimeout(() => {
          if (!mounted()) return;
          setShake(true);
          log.debug(`card #${index}, set shake true`);
        }, 2000);
        break;
      default:
        assertExhaustive(state);
        break;
    }
  }, [
    cancelShakeWhenUnrevealWrongMatch,
    cardUIState.state,
    index,
    log,
    mounted,
  ]);

  useEffect(() => {
    if (!shake) return;
    const timer = setTimeout(() => {
      if (mounted()) {
        setRevealCard(false);
        setShake(false);
        log.debug(`card #${index}, set reveal/shake false from timer`);
      }
    }, animation.shake.durationMs);
    return () => {
      clearTimeout(timer);
      setRevealCard(false);
      setShake(false);
      log.debug(`card #${index}, set reveal/shake false from cleanup`);
    };
  }, [animation.shake.durationMs, mounted, shake, index, log]);

  // When revealTo becomes non-nullish value, set the reveal state once.
  useEffect(() => {
    if (!revealTo) return;
    const revealToMe = revealTo === myClientId;
    setState({
      blur: !revealToMe,
      onlyYouCanSee: revealToMe,
      viewer: !revealToMe,
      memberId: revealTo,
    });
  }, [myClientId, revealTo]);

  useEffect(() => {
    if (!(prevRevealCard === true && revealCard === false)) return;
    // When revealCard becomes false from true, it triggers the flip back animation.
    // Clear the memberId first, so the crowd frame will be removed immediately.
    setState((prev) => {
      if (!prev) return null;
      return { ...prev, memberId: null, onlyYouCanSee: false };
    });
    // After the flip animation, clear the blur effect.
    flipCtrl.set(() => {
      if (!mounted()) return;
      setState(null);
    }, animation.flip.durationMs);
  }, [
    animation.flip.durationMs,
    flipCtrl,
    mounted,
    prevRevealCard,
    revealCard,
  ]);

  // if card is matched, clear the extra reveal UI state
  useEffect(() => {
    if (cardUIState.state !== 'done') return;
    setState(null);
  }, [cardUIState.state]);

  useEffect(() => {
    return () => flipCtrl.clear();
  }, [flipCtrl]);

  return [revealCard, shake, state];
}

function CardPlaceholder(): JSX.Element {
  return (
    <div className='m-1 relative'>
      <div className='rounded-xl filter aspect-w-16 aspect-h-9'></div>
    </div>
  );
}

// flip animation - https://w3collective.com/flip-card-css/
function CardView(props: {
  card: Card;
  teamId: TeamId;
  inGame: boolean;
  controllable: boolean;
  points: number;
  hiddenGameplay: boolean;
  debug: boolean;
  onShowClickWarning: () => void;
}): JSX.Element {
  const {
    card,
    teamId,
    inGame,
    controllable,
    hiddenGameplay,
    debug,
    onShowClickWarning,
  } = props;
  const api = useMemoryMatchGamePlayAPI(teamId, hiddenGameplay);
  const cardUIState = useCardUIState(card);
  const [actualPoints, setActualPoints] = useState<number>(-1);
  const showScore = !!card.showScore;
  const prevShowScore = usePreviousDistinct(showScore);
  const mounted = useIsMounted();
  const animation = useInstance(() => ({
    flip: MemoryMatchUtils.FlipAnimation(),
    shake: MemoryMatchUtils.ShakeAnimation(),
  }));
  const [revealed, shaking, revealUIState] = useRevealUIState(
    card.index,
    cardUIState,
    animation
  );
  const coverUrl = useInstance(() =>
    getStaticAssetPath('images/memory-match-card-cover.png')
  );
  const latestPoints = useLatest(props.points);

  const { play: playCorrectSFX } = useSoundEffect('memoryMatchCorrectMatch');

  const { play: playFlipForwardSFX } = useSoundEffect(
    'memoryMatchCardFlipForward'
  );
  const { play: playFlipBackwardSFX } = useSoundEffect(
    'memoryMatchCardFlipBackward'
  );

  useEffect(() => {
    loadImageAsPromise(card.url);
  }, [card.url]);

  useEffect(() => {
    if (!inGame) return;
    if (!revealed) return;
    playFlipForwardSFX();
    return () => {
      if (!mounted()) return;
      playFlipBackwardSFX();
    };
  }, [inGame, mounted, playFlipBackwardSFX, playFlipForwardSFX, revealed]);

  useEffect(() => {
    if (!(prevShowScore === false && showScore === true)) return;
    setActualPoints(latestPoints.current);
    playCorrectSFX();
    const timer = setTimeout(() => {
      if (mounted()) setActualPoints(-1);
    }, 2000);
    return () => clearTimeout(timer);
  }, [latestPoints, mounted, playCorrectSFX, prevShowScore, showScore]);

  const onClick = () => {
    if (!controllable) {
      onShowClickWarning();
      return;
    }
    if (revealed || !inGame || shaking) return;
    api.revealCard(card);
  };

  return (
    <div
      className={`m-1 relative ${
        shaking ? 'animate-horizontal-shake-once' : ''
      }`}
      style={
        {
          '--tw-horizontal-shake-duration':
            animation.shake.durationAnimationFormat,
        } as CSSProperties
      }
    >
      {revealUIState?.onlyYouCanSee && <OnlyYouCanSee />}
      <div
        className={`rounded-xl filter aspect-w-16 aspect-h-9 ${
          inGame && controllable ? 'cursor-pointer' : 'cursor-not-allowed'
        } ${inGame ? '' : 'opacity-50'}`}
        onClick={onClick}
      >
        <div className='flex items-center justify-center'>
          <div
            className={`absolute w-full h-full rounded-xl border 
            ${inGame && controllable ? 'hover:shadow-memory-match' : ''}
            ${
              cardUIState.state === 'done'
                ? 'border-[#39D966]'
                : 'border-secondary'
            }`}
            style={{
              transition: animation.flip.style.transition,
              transformStyle: animation.flip.style.transformStyle,
              transform: revealed ? animation.flip.style.transform : undefined,
            }}
          >
            <CardContent
              url={coverUrl}
              debugUrl={debug ? card.url : undefined}
              visible={!revealed}
              type='front'
            />
            <CardContent
              url={card.url}
              visible={revealed}
              blur={!!revealUIState?.blur}
              type='back'
              transform={animation.flip.style.transform}
            />
            {revealUIState?.viewer && revealUIState?.memberId && (
              <CardViewedBy
                teamId={teamId}
                clientId={revealUIState.memberId}
                transform={animation.flip.style.transform}
              />
            )}
          </div>
          <CardBadge
            type={cardUIState.state === 'done' ? 'matched' : 'default'}
            text={`${card.index + 1}`}
          />
          {actualPoints >= 0 && (
            <div
              className='absolute font-black text-green-001 text-3xl font-cairo bg-black bg-opacity-20 w-full h-full flex items-center justify-center'
              style={{
                textShadow: '0px 2px 4px #000000',
              }}
            >
              +{actualPoints}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

function TeamCaptainWatch(props: {
  block: MemoryMatchBlock;
  teamId: TeamId;
  points: number;
}): JSX.Element | null {
  const { teamId, points } = props;

  usePairAutoGrading(teamId, points);
  const submissionStatusAPI = useTeamSubmissionStatusAPI();
  const emitter = useGamePlayEmitter();

  useEffect(() => {
    return emitter.once('ended', (_, state) => {
      if (state === 'finished') {
        submissionStatusAPI.markSubmitted();
      }
    });
  }, [emitter, submissionStatusAPI]);

  return null;
}

function useGridCards(
  cards: Card[],
  cols: number,
  remainder: number
): GridCard[] {
  return useMemo(() => {
    const updatedCards = cards
      .slice(0, cards.length - remainder)
      .map<GridCard>((c) => ({ ...c, placeholder: false }));
    if (remainder === 0) return updatedCards;
    const emptySlots = cols - remainder;
    const leftSlots = Math.floor(emptySlots / 2);
    const rightSlots = emptySlots - leftSlots;
    for (let i = 0; i < leftSlots; i++) {
      updatedCards.push({ placeholder: true });
    }
    cards
      .slice(cards.length - remainder, cards.length)
      .forEach((c) => updatedCards.push({ ...c, placeholder: false }));
    for (let i = 0; i < rightSlots; i++) {
      updatedCards.push({ placeholder: true });
    }
    return updatedCards;
  }, [cards, cols, remainder]);
}

function useScoreFunction(
  block: MemoryMatchBlock
): ReturnType<typeof getTimedScoringFunction> {
  return useMemo(() => {
    return getTimedScoringFunction(
      getTimedScoringKind(
        block.fields.decreasingPointsTimer,
        block.fields.startDescendingImmediately
      ),
      block.fields.pointsPerMatch,
      block.fields.gameTimeSec
    );
  }, [
    block.fields.decreasingPointsTimer,
    block.fields.gameTimeSec,
    block.fields.pointsPerMatch,
    block.fields.startDescendingImmediately,
  ]);
}

function Prompt(props: {
  block: MemoryMatchBlock;
  time: number;
  points: number;
}): JSX.Element {
  const { block, time, points } = props;

  return (
    <div className='flex flex-row items-center justify-center relative ml-16 mb-2'>
      <ProgressRing
        className='absolute -left-16 z-[2]'
        currentTime={time}
        totalTime={block.fields.gameTimeSec}
      />
      <div className='bg-dark-gray w-130 h-15 rounded-2.5xl flex items-center justify-center text-sms font-bold px-10 z-[1]'>
        {block.fields.text}
      </div>
      <div className='bg-warning flex flex-col items-center justify-center text-black pl-4 pr-2 py-1 z-0 transform -translate-x-2'>
        <div
          className='font-cairo font-black text-2xl'
          style={{
            lineHeight: 1,
          }}
        >
          {points}
        </div>
        <div className='text-4xs'>points</div>
      </div>
    </div>
  );
}

function ClickWarning() {
  const myTeamId = useMyTeamId();
  const teamCaptain = useTeamCaptainParticipant(myTeamId);
  return (
    <div className='absolute inset-0 flex items-center justify-center bg-lp-black-001 rounded-2.5xl animate-fade-in-v2'>
      <div className='text-center text-tertiary'>
        <strong>Only your team captain can click!</strong>
        <br />
        Help{' '}
        {teamCaptain
          ? teamCaptain.firstName ?? teamCaptain.username
          : 'your team'}{' '}
        find matches by shouting out what you see!
      </div>
    </div>
  );
}

function CardContainer(props: {
  cards: Card[];
  render: (
    card: Card,
    index: number,
    onShowClickWarning: () => void
  ) => JSX.Element;
}): JSX.Element | null {
  const { cards, render } = props;
  const gridConfig = useMemo(
    () => MemoryMatchStyle.UseGridConfig(cards.length / 2),
    [cards.length]
  );
  const mounted = useIsMounted();
  const [showClickWarning, setShowClickWarning] = useState(false);
  const showClickWarningTimerRef = useRef<ReturnType<typeof setTimeout> | null>(
    null
  );
  const handleShowClickWarning = useLiveCallback(() => {
    if (showClickWarningTimerRef.current) return;
    setShowClickWarning(true);
    showClickWarningTimerRef.current = setTimeout(() => {
      if (!mounted()) return;
      setShowClickWarning(false);
      showClickWarningTimerRef.current = null;
    }, 2000);
  });

  const gridCards = useGridCards(cards, gridConfig.cols, gridConfig.remainder);
  if (gridCards.length === 0) return null;
  return (
    <div
      className={'relative bg-lp-black-002 grid p-2 rounded-2.5xl w-full'}
      style={{
        gridTemplateColumns: `repeat(${gridConfig.cols}, minmax(0, 1fr))`,
      }}
    >
      {gridCards.map((card, i) => {
        if (card.placeholder) return <CardPlaceholder key={i} />;
        return render(card, i, handleShowClickWarning);
      })}
      {showClickWarning && <ClickWarning />}
    </div>
  );
}

function MemoryMatchPlayable(props: {
  block: MemoryMatchBlock;
  time: number;
  points: number;
}): JSX.Element | null {
  const { block, time, points } = props;
  const cards = useMemoryMatchCards();
  const me = useMyInstance();
  const teamId = me?.teamId;
  const game = useMemoryMatchGame();
  const inGame = !!game?.state && game.state === GameState.InProgress;
  const isTeamCaptainScribe = useIsTeamCaptainScribe(me?.teamId, me?.clientId);
  const debug = useInstance(() => getFeatureQueryParam('memory-match-debug'));

  if (!teamId || time === null) return null;

  return (
    <>
      <CardContainer
        cards={cards}
        render={(card: Card, index: number, onShowClickWarning: () => void) => (
          <CardView
            key={index}
            card={card}
            inGame={inGame && time > 0}
            teamId={teamId}
            controllable={isTeamCaptainScribe}
            points={points}
            hiddenGameplay={block.fields.hiddenGameplay}
            debug={debug}
            onShowClickWarning={onShowClickWarning}
          />
        )}
      />
      {inGame && isTeamCaptainScribe && (
        <TeamCaptainWatch block={block} teamId={teamId} points={points} />
      )}
    </>
  );
}

function MemoryMatchViewOnly(props: {
  block: MemoryMatchBlock;
}): JSX.Element | null {
  const { block } = props;
  const cards = useMemo(() => {
    const assets = MemoryMatchUtils.PrepareAssets(
      block.fields.cardPairs,
      getFeatureQueryParamNumber('memory-match-num-of-pairs') ||
        block.fields.numberOfCardPairs
    );
    return MemoryMatchUtils.BuildCards(assets, false);
  }, [block.fields.cardPairs, block.fields.numberOfCardPairs]);
  const game = useMemoryMatchGame();
  const inGame = !!game?.state && game.state === GameState.InProgress;

  return (
    <CardContainer
      cards={cards}
      render={(card: Card, index: number) => (
        <div key={index} className='m-1 relative'>
          <div
            className={`rounded-xl filter aspect-w-16 aspect-h-9 cursor-not-allowed ${
              inGame ? '' : 'opacity-50'
            }`}
          >
            <CardContent url={card.url} visible type='back' />
          </div>
        </div>
      )}
    />
  );
}

export function MemoryMatchPlayground(props: {
  block: MemoryMatchBlock;
  minWidth: string;
  isHost: boolean;
}): JSX.Element | null {
  const { block, minWidth, isHost } = props;
  const scoring = useScoreFunction(block);
  const time = useGameSessionLocalTimer();

  if (time === null) return null;

  const points = scoring.get(time);
  return (
    <>
      <Prompt block={block} time={time} points={points} />
      <AutoScale>
        <div className={`flex flex-col items-center w-full ${minWidth}`}>
          {isHost ? (
            <MemoryMatchViewOnly block={block} />
          ) : (
            <MemoryMatchPlayable block={block} time={time} points={points} />
          )}
        </div>
      </AutoScale>
    </>
  );
}
