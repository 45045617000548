import React, {
  type ReactNode,
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import useMeasure, { type RectReadOnly } from 'react-use-measure';

import logger from '../../logger/logger';
import { Emitter } from '../../utils/emitter';
import { ResizeObserver } from '../../utils/ResizeObserver';

const log = logger.scoped('layout-anchors');

// To add a new anchor id, add it here as a string union (|)
export type AnchorId =
  | 'gameplay-question-text-anchor'
  | 'gameplay-points-animation-top'
  | 'points-badge-mini'
  | 'points-badge-team-header'
  | 'points-badge-venue-header'
  | 'round-robin-progress-ring'
  | 'round-robin-race-container'
  | 'ond-gameplay-progress-indicator'
  | 'right-panel-people-footer-button'
  | 'team-captain-scribe-badge-anchor'
  | 'team-stream-anchor'
  | 'team-relay-game-playground-anchor'
  | 'over-roasted-orders-panel'
  | `over-roasted-order-${string}`
  | `over-roasted-cup-${string}`
  | `over-roasted-bag-${string}`
  | `over-roasted-dispenser-${string}`
  | `over-roasted-truck-workspace`
  | 'dup-host-stream-anchor'
  | 'lobby-top-spacing-anchor'
  | 'lobby-cohost-spacing-anchor'
  | 'drawing-present-spacing-anchor'
  | 'drawing-voter-spacing-anchor'
  | 'gpv2-editor-blocks-anchor'
  | 'emojis-expanded-board-anchor'
  | 'emojis-collapsed-button-anchor'
  | 'h2h-group-a-anchor'
  | 'h2h-group-b-anchor';

type BoundsEmitterEvents = { rect: (bounds: RectReadOnly | null) => void };
type AnchorDesc = {
  bounds: RectReadOnly | null;
  emitter: Emitter<BoundsEmitterEvents>;
};

type LayoutAnchorContext = {
  anchors: Map<AnchorId, AnchorDesc>;
  getLatestBounds: (id: AnchorId) => null | RectReadOnly;
  broadcast: (id: AnchorId, bounds: RectReadOnly | null) => void;
  subscribe: (
    id: AnchorId,
    cb: (bounds: RectReadOnly | null) => void
  ) => () => void;
};

const layoutAnchorContext = React.createContext<LayoutAnchorContext | null>(
  null
);

function useLayoutAnchorContext() {
  const ctx = useContext(layoutAnchorContext);
  if (!ctx) throw new Error('No value for LayoutAnchorProvider');
  return ctx;
}

function getOrCreateAnchorDesc(
  ctxRef:
    | React.MutableRefObject<LayoutAnchorContext | null>
    | LayoutAnchorContext,
  id: AnchorId
) {
  // TODO: if lots of anchor IDs are created, consider using WeakRef to cleanup
  // descriptions when no longer needed
  const ctx = 'current' in ctxRef ? ctxRef.current : ctxRef;
  if (!ctx) throw new Error('No LayoutAnchor context!');
  let desc = ctx.anchors.get(id);
  if (!desc)
    desc = { bounds: null, emitter: new Emitter<BoundsEmitterEvents>() };
  ctx.anchors.set(id, desc);
  return desc;
}

export function LayoutAnchorProvider(props: {
  children?: ReactNode;
}): JSX.Element {
  const ctxRef = useRef<LayoutAnchorContext | null>(null);

  if (!ctxRef.current) {
    ctxRef.current = {
      anchors: new Map(),
      getLatestBounds(id) {
        const desc = getOrCreateAnchorDesc(ctxRef, id);
        return desc.bounds;
      },
      subscribe(id, cb) {
        const desc = getOrCreateAnchorDesc(ctxRef, id);
        return desc.emitter.on('rect', cb);
      },
      broadcast(id, bounds) {
        log.debug('broadcasting', { id, bounds });
        const desc = getOrCreateAnchorDesc(ctxRef, id);
        desc.bounds = bounds;
        desc.emitter.emit('rect', bounds);
      },
    };
  }

  return (
    <layoutAnchorContext.Provider value={ctxRef.current}>
      {props.children}
    </layoutAnchorContext.Provider>
  );
}

/**
 * Returns a non-stable reference to the most recent DOMRect. The rect will
 * change each time the component is rendered, so do not use the rect object for
 * useEffect() dependencies!
 */
export function useLayoutAnchorRect(id: AnchorId): RectReadOnly | null {
  const ctx = useLayoutAnchorContext();
  const [rect, setRect] = useState<RectReadOnly | null>(() =>
    ctx.getLatestBounds(id)
  );
  const desc = getOrCreateAnchorDesc(ctx, id);

  useEffect(() => {
    setRect(ctx.getLatestBounds(id));
  }, [ctx, id]);

  useEffect(() => {
    return desc.emitter.on('rect', setRect);
  }, [desc.emitter]);

  return rect;
}

/**
 * Returns a scalar value from the rect. If using as a useEffect dependency, be
 * sure to limit float point values to a specific precision (e.g. Math.floor(val
 * * 1e6), and then divide by 1e6 in the effect) to avoid always running the
 * effect!
 */
export function useLayoutAnchorRectValue(
  id: AnchorId,
  value: 'width' | 'height' | 'x' | 'y' | 'left' | 'right' | 'bottom' | 'top'
): number | null {
  const rect = useLayoutAnchorRect(id);
  if (!rect) return null;
  return Math.floor(rect[value]);
}

/**
 * Completely bypass react and ResizeObserver mechanisms and synchronously
 * measure the DOM. ResizeObserver does not fire when the x/y values change,
 * only when the bounds of the element (width/height) change.
 */
export function getSynchronousRawAnchorRect(id: AnchorId): RectReadOnly | null {
  const selector = `[data-layoutanchorid=${id}]`;
  const anchor = document.querySelector(selector);
  if (!anchor) return null;
  const rect = anchor.getBoundingClientRect();
  return rect as unknown as RectReadOnly;
}

/**
 * Register an anchor in the DOM tree for later measurement. This allows
 * disparate sections of the DOM tree to lay themselves out, without needing
 * element colocation. It also allows an element to know the absolute
 * coordinates (relative to the window) of another element.
 */
export function LayoutAnchor(props: {
  id: AnchorId;
  className?: string;
  layoutReportDelayMs?: number;
}): JSX.Element {
  const { broadcast } = useLayoutAnchorContext();
  const [ref, bounds] = useMeasure({
    debounce: props.layoutReportDelayMs,
    polyfill: ResizeObserver,
  });

  useLayoutEffect(() => {
    broadcast(props.id, bounds);
  }, [bounds, broadcast, props.id]);

  useEffect(() => {
    return () => {
      broadcast(props.id, null);
    };
  }, [broadcast, props.id]);

  return (
    <div
      data-layoutanchorid={props.id}
      ref={ref}
      className={props.className}
    ></div>
  );
}

export function useComposedLayoutAnchor(
  id: AnchorId,
  layoutReportDelayMs?: number
): (element: (HTMLElement | SVGElement) | null) => void {
  const { broadcast } = useLayoutAnchorContext();
  const [ref, bounds] = useMeasure({
    debounce: layoutReportDelayMs,
    polyfill: ResizeObserver,
  });

  useLayoutEffect(() => {
    broadcast(id, bounds);
  }, [bounds, broadcast, id]);

  return (el) => {
    el?.setAttribute('data-layoutanchorid', id);
    ref(el);
  };
}
