import { type ReactNode, useState } from 'react';

import {
  assertExhaustive,
  type HeadToHeadBlock,
  HeadToHeadBlockGameSessionStatus,
} from '@lp-lib/game';

import { PlayIcon } from '../../../icons/PlayIcon';
import { RefreshIcon } from '../../../icons/RefreshIcon';
import { FilledSquareIcon } from '../../../icons/SquareIcon';
import { TimerIcon } from '../../../icons/TimerIcon';
import { useGameSessionStatus, useIsEndedBlock } from '../../hooks';
import { next, present, triggerBlockTitleAnim } from '../../store';
import {
  BlockControllerActionButton,
  ControllerLayout,
  type ControllerProps,
  useBlockControllerBlockTitlePlaying,
} from '../Common/Controller/Internal';
import { useStableBlock } from '../Common/hooks';
import {
  useHeadToHeadGamePlayAPI,
  useHeadToHeadGameProgress,
  useNextRoundPhaseAfterPlaying,
} from './HeadToHeadBlockProvider';
import { HeadToHeadUtils } from './utils';

type SharedProps = ControllerProps<HeadToHeadBlock>;

function BlockDetails(props: SharedProps): JSX.Element {
  return (
    <div className='w-full flex flex-col p-4'>
      <div>{props.selectedBlock.fields.title}</div>
      <div className='h-4'></div>
      <div>{props.selectedBlock.fields.gameName}</div>
      <div>{props.selectedBlock.fields.cards.length} Cards in Deck</div>
    </div>
  );
}

function Loaded(props: SharedProps): JSX.Element {
  const { selectedBlock: block } = props;

  const [processing, setProcessing] = useState(false);

  const onPresent = async () => {
    setProcessing(true);
    await triggerBlockTitleAnim(block);
    await present(block);
  };

  const actionComponent = useBlockControllerBlockTitlePlaying(block) ?? (
    <BlockControllerActionButton
      onClick={onPresent}
      icon={PlayIcon}
      disable={processing || block.fields.cards.length === 0}
    >
      {block.fields.cards.length === 0 ? 'No Cards' : 'Present Block'}
    </BlockControllerActionButton>
  );
  return (
    <ControllerLayout action={actionComponent}>
      <div className='w-full flex flex-col gap-6'>
        <BlockDetails {...props} />
      </div>
    </ControllerLayout>
  );
}

function GameInit(_props: SharedProps): JSX.Element {
  const [processing, setProcessing] = useState(false);
  const handleStartGame = async () => {
    setProcessing(true);
    next();
  };

  return (
    <ControllerLayout
      action={
        <BlockControllerActionButton
          onClick={handleStartGame}
          icon={TimerIcon}
          disable={processing}
        >
          Present Intro
        </BlockControllerActionButton>
      }
    >
      <div className='w-full flex flex-col gap-6'></div>
    </ControllerLayout>
  );
}

function GameIntro(_props: SharedProps): JSX.Element {
  const [processing, setProcessing] = useState(false);
  const handleStartGame = async () => {
    setProcessing(true);
    next();
  };

  return (
    <ControllerLayout
      action={
        <BlockControllerActionButton
          onClick={handleStartGame}
          icon={TimerIcon}
          disable={processing}
        >
          Start Game
        </BlockControllerActionButton>
      }
    >
      <div className='w-full flex flex-col gap-6'></div>
    </ControllerLayout>
  );
}

function GameStart(props: SharedProps): JSX.Element {
  const [processing, setProcessing] = useState(false);

  const api = useHeadToHeadGamePlayAPI();
  const progress = useHeadToHeadGameProgress();
  const turnsMode = HeadToHeadUtils.TurnsMode(props.selectedBlock);
  const judgingEnabled = HeadToHeadUtils.JudgingEnabled(props.selectedBlock);
  const replayable = props.selectedBlock.fields.replayable;

  const nextPhase = useNextRoundPhaseAfterPlaying(props.selectedBlock);

  let action: ReactNode | undefined = undefined;
  switch (progress.roundPhase) {
    case 'playing':
      if (progress.currentCardPhase === 'ready') {
        action = (
          <BlockControllerActionButton onClick={() => api.revealCurrentCard()}>
            Ready
          </BlockControllerActionButton>
        );
      } else {
        action = (
          <>
            <BlockControllerActionButton onClick={() => api.nextCard()}>
              {turnsMode
                ? `Go to ${
                    progress.currentTurn === 'groupA' ? 'Blue' : 'Pink'
                  } Team`
                : 'Shuffle Card'}
            </BlockControllerActionButton>
            <BlockControllerActionButton
              isSecondary
              onClick={async () => {
                if (nextPhase) {
                  await api.setRoundPhase(nextPhase, 'host-clicked-end-round');
                  return;
                }
                await next();
              }}
            >
              {`End & ${judgingEnabled ? 'Distribute Points' : 'Move On'}`}
            </BlockControllerActionButton>
          </>
        );
      }
      break;
    case 'judging-after-playing':
      action = (
        <BlockControllerActionButton
          onClick={() =>
            api.setRoundPhase('reveal-winner', 'host-clicked-end-judging')
          }
        >
          End Judging
        </BlockControllerActionButton>
      );
      break;
    case 'reveal-winner':
      action = (
        <BlockControllerActionButton
          onClick={async () => {
            if (replayable) {
              await api.setRoundPhase('select-next', 'host-clicked-move-on');
            } else {
              await next();
            }
          }}
        >
          Move on
        </BlockControllerActionButton>
      );
      break;
    case 'select-next':
      action = (
        <BlockControllerActionButton
          onClick={() => {
            setProcessing(true);
            next();
          }}
        >
          {`Finish ${props.selectedBlock.fields.gameName || 'Game'}`}
        </BlockControllerActionButton>
      );
      break;
    default:
      assertExhaustive(progress.roundPhase);
      return (
        <BlockControllerActionButton
          onClick={() => {
            setProcessing(true);
            next();
          }}
          icon={FilledSquareIcon}
          disable={processing}
        >
          End Game
        </BlockControllerActionButton>
      );
  }

  return (
    <ControllerLayout action={action}>
      <div className='w-full flex flex-col gap-6'></div>
    </ControllerLayout>
  );
}

function Results(props: SharedProps): JSX.Element {
  return (
    <ControllerLayout
      action={
        <BlockControllerActionButton
          onClick={props.onEndBlock}
          icon={FilledSquareIcon}
        >
          End Block Sequence
        </BlockControllerActionButton>
      }
    >
      <div className='w-full flex flex-col gap-6'></div>
    </ControllerLayout>
  );
}

export function HeadToHeadBlockController(
  props: SharedProps
): JSX.Element | null {
  const { selectedBlockIndex } = props;
  const block = useStableBlock(props.selectedBlock);
  const isEndedBlock = useIsEndedBlock(block.id);
  const gss = useGameSessionStatus<HeadToHeadBlockGameSessionStatus>();

  if (selectedBlockIndex === null) return null;

  if (isEndedBlock) {
    return <Ended {...props} />;
  }

  function Ended(props: SharedProps): JSX.Element {
    return (
      <ControllerLayout
        action={
          <BlockControllerActionButton
            onClick={() => props.setShowResetConfirmation(true)}
            icon={RefreshIcon}
            isDelete={true}
          >
            Reset Completed Block
          </BlockControllerActionButton>
        }
      ></ControllerLayout>
    );
  }

  switch (gss) {
    case HeadToHeadBlockGameSessionStatus.LOADED:
      return <Loaded {...props} />;
    case HeadToHeadBlockGameSessionStatus.GAME_INIT:
      return <GameInit {...props} />;
    case HeadToHeadBlockGameSessionStatus.GAME_INTRO:
      return <GameIntro {...props} />;
    case HeadToHeadBlockGameSessionStatus.GAME_START:
    case HeadToHeadBlockGameSessionStatus.GAME_END:
      return <GameStart {...props} />;
    case HeadToHeadBlockGameSessionStatus.RESULTS:
      return <Results {...props} />;
    case HeadToHeadBlockGameSessionStatus.END:
    case null:
    case undefined:
      return null;
    default:
      assertExhaustive(gss);
      return null;
  }
}
