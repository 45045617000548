import { useMemo } from 'react';

import { useMyOrgId } from '../../components/Organization';
import { useAnalytics } from '../AnalyticsContext';
import { AppAnalytics } from './shared';

export function useAppAnalytics(): AppAnalytics {
  const analytics = useAnalytics();
  const orgId = useMyOrgId();
  return useMemo(
    () => new AppAnalytics(analytics, orgId ?? undefined, 'logged-in'),
    [analytics, orgId]
  );
}
