import { type TeamV0 } from '../types/team';

export function resolveTeamFilterOptions(options?: TeamFilterOptions) {
  return Object.assign(
    {},
    { active: true, excludeCohostTeam: true } satisfies TeamFilterOptions,
    options
  );
}

export function filterTeams(
  teamVOs: TeamV0[],
  options?: TeamFilterOptions
): TeamV0[] {
  const {
    active,
    sort,
    pinTeamId,
    pinFullTeamsToBottom,
    updateStaffTeam,
    excludeStaffTeam,
    excludeCohostTeam,
    membersCountGreaterThan,
  } = resolveTeamFilterOptions(options);

  const v0s = [];

  // filter
  for (const t of teamVOs) {
    if (excludeCohostTeam && t.isCohostTeam) continue;
    if (excludeStaffTeam && t.isStaffTeam) continue;
    if (active && t.membersCount === 0) continue;
    if (membersCountGreaterThan && t.membersCount <= membersCountGreaterThan)
      continue;
    v0s.push(t);
  }

  // no sorting or other manipulation necessary, early return
  if (!sort && !pinFullTeamsToBottom && !pinTeamId && !updateStaffTeam)
    return v0s;

  const magnitudes = {
    sort: 1,
    fullTeam: 2,
    staffTeam: 999,
  };

  // sort
  v0s.sort((a, b) => {
    let aWeight = 0;
    let bWeight = 0;

    if (sort) {
      aWeight += a.createdAt > b.createdAt ? magnitudes.sort : 0;
      bWeight += b.createdAt > a.createdAt ? magnitudes.sort : 0;
    }

    if (pinFullTeamsToBottom) {
      aWeight += a.isFull ? magnitudes.fullTeam : 0;
      bWeight += b.isFull ? magnitudes.fullTeam : 0;
    }

    if (updateStaffTeam) {
      aWeight += a.isStaffTeam ? magnitudes.staffTeam : 0;
      bWeight += b.isStaffTeam ? magnitudes.staffTeam : 0;
    }

    return aWeight - bWeight;
  });

  // pin
  if (pinTeamId) {
    const idx = v0s.findIndex((t) => t.id === pinTeamId);
    if (idx > 0) {
      const myTeam = v0s.splice(idx, 1);
      v0s.unshift(myTeam[0]);
    }
  }

  return v0s;
}

export type TeamFilterOptions = {
  active?: boolean;
  sort?: boolean;
  pinTeamId?: string | null;
  pinFullTeamsToBottom?: boolean;
  updateStaffTeam?: boolean;
  excludeStaffTeam?: boolean;
  excludeCohostTeam?: boolean;
  membersCountGreaterThan?: number;
};
