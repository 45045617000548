import { RollingWindowList } from './RollingWindowList';

export class DetractorPct {
  private storage: RollingWindowList<{
    timestampMs: number;
    durationMs: number;
  }>;

  constructor(
    private getNowMs: () => number,
    private readonly windowSizeMs = 30000
  ) {
    this.storage = new RollingWindowList(getNowMs, windowSizeMs);
  }

  detract(durationMs: number): void {
    this.storage.push({ durationMs, timestampMs: this.getNowMs() });
  }

  get pct(): number {
    let badMs = 0;

    // NOTE: this advances internal time and possibly mutates storage.
    const items = this.storage.all();
    for (const item of items) {
      badMs += item.durationMs;
    }

    return badMs / this.windowSizeMs;
  }
}
