import pluralize from 'pluralize';
import { type ValidationRule } from 'react-hook-form';

/**
 * @param maxLength
 * @returns a validation rule with a standarized error message.
 */
export function maxLengthRule(maxLength: number): ValidationRule<number> {
  return {
    value: maxLength,
    message: `Value may not exceed ${maxLength} ${pluralize(
      'character',
      maxLength
    )}`,
  };
}
