import { type Block } from '@lp-lib/game';

import { getFeatureQueryParam } from '../../hooks/useFeatureQueryParam';
import { BlockKnifeUtils } from './Blocks/Shared';

export class GameUtils {
  static DeriveBlockRecorderVersionFromBlocks(
    blocks: Block[] | null | undefined
  ): number {
    // There is no "block recorder version" > 3, but there is playback > 3.
    const playback = GameUtils.DeriveOndPlaybackVersionFromBlocks(blocks);
    return playback > 3 ? 3 : playback;
  }

  static DeriveOndPlaybackVersionFromBlocks(
    blocks: Block[] | null | undefined,
    forceAudioOnlyIfCapable = getFeatureQueryParam(
      'game-on-demand-force-v31-playback'
    )
  ): number {
    if (!blocks || blocks.length === 0) return 3;
    const versions = blocks
      .map((b) => b.recording?.version)
      .filter((v) => v !== undefined) as number[];

    const hasVoiceover =
      blocks.some((b) => BlockKnifeUtils.HasAtLeastOneVoiceOver(b)) ||
      forceAudioOnlyIfCapable;

    if (versions.length === 0) return hasVoiceover ? 3.1 : 3;
    const max = Math.max(...versions);
    // Any game with a voiceover is automatically upgraded to v3.1
    return max >= 3 && hasVoiceover ? 3.1 : max;
  }
}
