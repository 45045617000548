import { Link } from '@remix-run/react';
import { useEffect, useState } from 'react';

import { useMemoriesAnalytics } from '../../analytics/memories';
import { getStaticAssetPath } from '../../utils/assets';
import { type LobbyWidgetProps } from './LobbyGameWidgets';
import { useLobbyState } from './Provider';

const polaroidCamera = getStaticAssetPath('images/polaroid-camera.png');
const blurredMemory = getStaticAssetPath('images/blurred-memory.png');
const memorySample1 = getStaticAssetPath('images/memory-sample-1.gif');
const memorySample2 = getStaticAssetPath('images/memory-sample-2.gif');
const memorySample3 = getStaticAssetPath('images/memory-sample-3.gif');
const memorySample4 = getStaticAssetPath('images/memory-sample-4.gif');

export function LobbyMemories(props: LobbyWidgetProps): JSX.Element {
  const { offBoardingSessionId } = useLobbyState();
  const [lastKnownOffBoardingSessionId, setLastKnownOffBoardingSessionId] =
    useState<string | null>(offBoardingSessionId);
  const analytics = useMemoriesAnalytics();

  useEffect(() => {
    // in case the controller refreshes or causes the fb value to be set to null, we can ignore it.
    // in short, clients can try to retain this value as long as possible, but can reset it if it changes to a
    // newer value. it's okay if a user misses this. future product iterations will allow them to find this
    // page more easily.
    if (offBoardingSessionId === null) return;
    setLastKnownOffBoardingSessionId(offBoardingSessionId);
  }, [offBoardingSessionId]);

  useEffect(() => {
    analytics.trackLobbyMemoriesViewed({
      isPairingGame: props.isPairingGame,
      isOndGame: props.isOndGame,
      hasViewMemoryCta: lastKnownOffBoardingSessionId !== null,
    });
  }, [
    props.isPairingGame,
    props.isOndGame,
    lastKnownOffBoardingSessionId,
    analytics,
  ]);

  const handleViewMemoryClicked = () => {
    analytics.trackLobbyMemoriesClicked({
      isPairingGame: props.isPairingGame,
      isOndGame: props.isOndGame,
      cta: 'View Memory',
    });
  };

  if (lastKnownOffBoardingSessionId) {
    return (
      <div className='relative w-full h-[350px] flex flex-col items-center justify-center gap-1 overflow-hidden'>
        <div className='flex flex-col items-center justify-center gap-2'>
          <div className='font-bold text-center'>
            Nice game! We made something for you!
          </div>
          <Link
            to={`/sessions/${lastKnownOffBoardingSessionId}/memories`}
            target='_blank'
            rel='noopener noreferrer'
            onClick={handleViewMemoryClicked}
            className='space-y-2'
          >
            <button
              type='button'
              className={`
                btn w-50 h-9 flex justify-center items-center text-sms
                bg-gradient-to-bl from-pairing-start to-pairing-end
              `}
            >
              View Your Memory!
            </button>
            <img
              src={blurredMemory}
              alt='Sample Memory'
              className='w-50 h-50'
            />
          </Link>
        </div>
      </div>
    );
  } else {
    return (
      <div className='relative w-full h-[350px] flex flex-col items-center justify-center gap-1 overflow-hidden'>
        <img
          src={memorySample1}
          alt='Memory Sample 1'
          className='absolute shadow-xl w-42 top-8 -right-2'
          style={{
            transform: 'matrix(0.97, 0.23, -0.23, 0.97, 0, 0)',
          }}
        />
        <img
          src={memorySample2}
          alt='Memory Sample 2'
          className='absolute shadow-xl w-42 top-8 -left-4'
          style={{
            transform: 'matrix(0.99, -0.16, 0.16, 0.99, 0, 0)',
          }}
        />
        <img
          src={memorySample3}
          alt='Memory Sample 3'
          className='absolute shadow-xl w-42 bottom-0 -left-5 2xl:left-5'
          style={{
            transform: 'matrix(0.97, 0.22, -0.22, 0.97, 0, 0)',
          }}
        />
        <img
          src={memorySample4}
          alt='Memory Sample 4'
          className='absolute shadow-xl w-42 bottom-0 -right-3 2xl:right-1'
          style={{
            transform: 'matrix(0.97, -0.23, 0.23, 0.97, 0, 0)',
          }}
        />

        <img
          src={polaroidCamera}
          alt='Polaroid Camera'
          className='w-[143px] h-[143px]'
        />
        <div className='text-white text-center font-bold tracking-[0.15px] w-[274px]'>
          Stick around after the game to get your own{' '}
          <span className='bg-clip-text bg-memories-text text-transparent'>
            downloadable memory
          </span>
          !
        </div>
      </div>
    );
  }
}
