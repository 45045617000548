export function TeamRelayRegularIndicatorArrow(
  props: React.SVGProps<SVGSVGElement>
): JSX.Element {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      className={props.className || 'w-10 h-5'}
      viewBox='0 0 56 35'
    >
      <g fill='#FFE86D' filter='url(#filter0_d_689_134539)'>
        <path d='M28 15.692l-9.006-7.269h18.013L28 15.693z'></path>
        <path d='M48 10.846h-7.272L28 20.538l-12.445-9.692H8L28 27l20-16.154z'></path>
      </g>
      <defs>
        <filter
          id='filter0_d_689_134539'
          width='56'
          height='37'
          x='0'
          y='-2'
          colorInterpolationFilters='sRGB'
          filterUnits='userSpaceOnUse'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix'></feFlood>
          <feColorMatrix
            in='SourceAlpha'
            result='hardAlpha'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
          ></feColorMatrix>
          <feOffset></feOffset>
          <feGaussianBlur stdDeviation='4'></feGaussianBlur>
          <feComposite in2='hardAlpha' operator='out'></feComposite>
          <feColorMatrix values='0 0 0 0 1 0 0 0 0 0.909804 0 0 0 0 0.427451 0 0 0 1 0'></feColorMatrix>
          <feBlend
            in2='BackgroundImageFix'
            result='effect1_dropShadow_689_134539'
          ></feBlend>
          <feBlend
            in='SourceGraphic'
            in2='effect1_dropShadow_689_134539'
            result='shape'
          ></feBlend>
        </filter>
      </defs>
    </svg>
  );
}

export function TeamRelayWrongIndicatorArrow(
  props: React.SVGProps<SVGSVGElement>
): JSX.Element {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      className={props.className || 'w-10 h-5'}
      viewBox='0 0 48 27'
    >
      <g fill='#EE3529' filter='url(#filter0_d_689_134537)'>
        <path d='M24 11.692l-9.006-7.269h18.013L24 11.693z'></path>
        <path d='M44 6.846h-7.272L24 16.538 11.555 6.846H4L24 23 44 6.846z'></path>
      </g>
      <defs>
        <filter
          id='filter0_d_689_134537'
          width='48'
          height='29'
          x='0'
          y='-2'
          colorInterpolationFilters='sRGB'
          filterUnits='userSpaceOnUse'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix'></feFlood>
          <feColorMatrix
            in='SourceAlpha'
            result='hardAlpha'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
          ></feColorMatrix>
          <feOffset></feOffset>
          <feGaussianBlur stdDeviation='2'></feGaussianBlur>
          <feComposite in2='hardAlpha' operator='out'></feComposite>
          <feColorMatrix values='0 0 0 0 0.933333 0 0 0 0 0.207843 0 0 0 0 0.160784 0 0 0 1 0'></feColorMatrix>
          <feBlend
            in2='BackgroundImageFix'
            result='effect1_dropShadow_689_134537'
          ></feBlend>
          <feBlend
            in='SourceGraphic'
            in2='effect1_dropShadow_689_134537'
            result='shape'
          ></feBlend>
        </filter>
      </defs>
    </svg>
  );
}
