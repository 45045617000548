import pluralize from 'pluralize';

import { type Option } from './EditorUtilities';

/**
 * Common set of options for block timers.
 */
export const BLOCK_TIMER_OPTIONS: Option[] = [
  {
    value: 10,
    label: '10 seconds',
  },
  {
    value: 15,
    label: '15 seconds',
  },
  {
    value: 20,
    label: '20 seconds',
  },
  {
    value: 30,
    label: '30 seconds',
  },
  {
    value: 45,
    label: '45 seconds',
  },
  {
    value: 60,
    label: '1 minute',
  },
  {
    value: 90,
    label: '1.5 minutes',
  },
  {
    value: 120,
    label: '2 minutes',
  },
  {
    value: 240,
    label: '4 minutes',
  },
  {
    value: 600,
    label: '10 minutes',
  },
];

export function generateBlockTimerOptions(secondsList: number[]): Option[] {
  return secondsList.map((seconds) => {
    if (seconds < 60) {
      return {
        value: seconds,
        label: `${seconds} ${pluralize('second', seconds)}`,
      };
    }

    const minutes = seconds / 60;
    return {
      value: seconds,
      label: `${minutes} ${pluralize('minute', minutes)}`,
    };
  });
}

/**
 * Common set of options for answer reveal timers.
 */
export const ANSWER_REVEAL_TIMER_OPTIONS: Option[] = [
  {
    value: 0,
    label: 'No Timer',
  },
  ...BLOCK_TIMER_OPTIONS,
];

/**
 * Common set of options for points.
 */
export const POINTS_OPTIONS: Option[] = [
  {
    value: 100,
    label: 'Standard - 100 points',
  },
  {
    value: 200,
    label: 'Double (2x) - 200 points',
  },
  {
    value: 300,
    label: 'Triple (3x) - 300 points',
  },
  {
    value: 0,
    label: 'No Points - 0 points',
  },
];
