import { type CSSProperties, type ReactNode, useEffect, useMemo } from 'react';
import useMeasure from 'react-use-measure';

import { useDebugCanvas } from '../../../../../hooks/useDebugCanvas';
import { useFeatureQueryParam } from '../../../../../hooks/useFeatureQueryParam';
import { ResizeObserver } from '../../../../../utils/ResizeObserver';
import { FloatLayout } from '../../../../Layout';
import { useLayoutAnchorRectValue } from '../../../../LayoutAnchors/LayoutAnchors';
import { AutoScale } from './ContainLayout';

// Exported only for testing. If this is needed elsewhere, extract to a new
// file.
export class RatioBox {
  readonly width: number;
  readonly height: number;
  readonly halfWidth: number;
  readonly halfHeight: number;

  constructor(
    public centerX: number,
    public centerY: number,
    public readonly maxWidth: number,
    public readonly maxHeight: number,
    public readonly ratioWH: number
  ) {
    const maxRatioWH = maxWidth / maxHeight;
    this.width =
      ratioWH > 1 && ratioWH > maxRatioWH ? maxWidth : maxHeight * ratioWH;
    this.height =
      ratioWH > 1 && ratioWH > maxRatioWH ? maxWidth / ratioWH : maxHeight;
    this.halfWidth = this.width / 2;
    this.halfHeight = this.height / 2;
  }

  get top(): number {
    return this.centerY - this.halfHeight;
  }

  debugDrawMaxBounds(
    stroke: CanvasRenderingContext2D['strokeStyle'],
    ctx: CanvasRenderingContext2D
  ): void {
    ctx.strokeStyle = stroke;
    ctx.fillStyle = stroke;
    ctx.font = 'bold 16px Inter';
    ctx.strokeRect(
      this.centerX - this.maxWidth / 2,
      this.centerY - this.maxHeight / 2,
      this.maxWidth,
      this.maxHeight
    );
    ctx.fillText(
      `${this.maxWidth.toFixed(2)}x${this.maxHeight.toFixed(2)}`,
      this.centerX - this.maxWidth / 2,
      this.centerY + this.maxHeight / 2
    );
  }

  debugDrawBounds(
    stroke: CanvasRenderingContext2D['strokeStyle'],
    ctx: CanvasRenderingContext2D
  ): void {
    ctx.strokeStyle = stroke;
    ctx.fillStyle = stroke;
    ctx.strokeRect(
      this.centerX - this.halfWidth,
      this.centerY - this.halfHeight,
      this.width,
      this.height
    );
    ctx.fillText(
      `${this.width.toFixed(2)}x${this.height.toFixed(2)}`,
      this.centerX - this.halfWidth,
      this.centerY + this.halfHeight
    );
  }
}

export function AnchoredGamePlayMediaLayout(props: {
  debugName: string;
  mode: 'full' | 'small';
  z: 'z-20' | 'z-30';
  children: ReactNode;
  disableTransition?: boolean;
  justify?: 'start' | 'center';
  footer?: ReactNode;
}): JSX.Element {
  const [, ctx] = useDebugCanvas();
  const mediaZIndex = props.z;

  const questionAnchorY = useLayoutAnchorRectValue(
    'gameplay-question-text-anchor',
    'y'
  );
  const questionAnchorWidth = useLayoutAnchorRectValue(
    'gameplay-question-text-anchor',
    'width'
  );

  // The layout effects are dependent on this container having layout (non-zero
  // values). The most reliable way to do that is via ResizeObserver.
  const [containerRefCb, containerRect] = useMeasure({
    polyfill: ResizeObserver,
    debounce: props.disableTransition ? 0 : 800,
  });

  // It's important to set an aspect ratio to the media container so that the
  // image/video has a default width/height that is non-zero. Otherwise it will
  // "pop" out of nothingness. A better, but more complicated alternative
  // (especially given thumbnails) is to apply a width/height from the transcode
  // data.
  const ratioClasses = `aspect-w-16 aspect-h-9`;
  const ratioWH = 16 / 9;

  const debugDrawEnabled = useFeatureQueryParam('game-play-media-debug-layout');

  const hasRequiredNonZeroLayout =
    containerRect.width !== 0 &&
    containerRect.height !== 0 &&
    questionAnchorY !== null &&
    questionAnchorWidth !== null &&
    questionAnchorWidth !== 0;

  const mediaBox = useMemo(
    () =>
      new RatioBox(
        containerRect.x + containerRect.width / 2,
        containerRect.y + containerRect.height / 2,
        containerRect.width,
        containerRect.height,
        ratioWH
      ),
    [
      ratioWH,
      containerRect.height,
      containerRect.width,
      containerRect.x,
      containerRect.y,
    ]
  );

  const mediaBoxMaxTop = mediaBox.centerY - mediaBox.maxHeight / 2;
  const remainingHeight = !hasRequiredNonZeroLayout
    ? 0
    : Math.abs(questionAnchorY - mediaBoxMaxTop);
  const questionSpaceBox = useMemo(
    () =>
      new RatioBox(
        mediaBox.centerX,
        (hasRequiredNonZeroLayout ? questionAnchorY : 0) - remainingHeight / 2,
        mediaBox.maxWidth,
        remainingHeight,
        ratioWH
      ),
    [
      mediaBox.centerX,
      mediaBox.maxWidth,
      hasRequiredNonZeroLayout,
      questionAnchorY,
      ratioWH,
      remainingHeight,
    ]
  );

  const translate =
    props.mode === 'small' && hasRequiredNonZeroLayout
      ? Math.abs(mediaBox.centerY - questionSpaceBox.centerY) * -1
      : 0;

  const scale =
    props.mode === 'small' && hasRequiredNonZeroLayout
      ? questionSpaceBox.width > mediaBox.width
        ? mediaBox.width / questionSpaceBox.width
        : questionSpaceBox.width / mediaBox.width
      : 1;

  useEffect(() => {
    if (!hasRequiredNonZeroLayout || !debugDrawEnabled || !ctx) return;

    ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
    mediaBox.debugDrawMaxBounds('pink', ctx);
    mediaBox.debugDrawBounds('red', ctx);
    questionSpaceBox.debugDrawBounds('magenta', ctx);
    questionSpaceBox.debugDrawMaxBounds('yellow', ctx);

    ctx.strokeStyle = 'green';
    ctx.strokeRect(
      mediaBox.centerX - mediaBox.halfWidth,
      mediaBoxMaxTop,
      mediaBox.width,
      1
    );

    ctx.fillStyle = 'pink';
    ctx.arc(mediaBox.centerX, questionAnchorY, 3, 0, Math.PI * 2);
    ctx.closePath();
    ctx.fill();
  }, [
    ctx,
    debugDrawEnabled,
    mediaBox,
    mediaBoxMaxTop,
    hasRequiredNonZeroLayout,
    questionAnchorY,
    questionSpaceBox,
  ]);

  const justify =
    props.justify === 'start' ? 'justify-start' : 'justify-center';

  return (
    <div
      data-gameplaymedialayout={props.debugName}
      ref={containerRefCb}
      className={`absolute top-0 w-full h-full flex flex-col items-center ${justify}`}
    >
      <div
        className={`${mediaZIndex} transform ${
          props.disableTransition
            ? ''
            : 'transition-all duration-700 ease-in-out'
        } w-full`}
        style={
          hasRequiredNonZeroLayout
            ? ({
                width: `${mediaBox.width.toFixed(0)}px`,
                height: `${mediaBox.height.toFixed(0)}px`,
                '--tw-translate-y': `${translate}px`,
                '--tw-scale-x': `${scale}`,
                '--tw-scale-y': `${scale}`,
              } as CSSProperties)
            : {}
        }
      >
        <div className={`${ratioClasses}`}>
          <div className='absolute w-full h-full'>{props.children}</div>
        </div>
        {props.footer}
      </div>
    </div>
  );
}

export function FullscreenGamePlayMediaLayout(props: {
  debugName: string;
  children: ReactNode;
}): JSX.Element {
  return (
    <div
      data-gameplaymedialayout={props.debugName}
      className='absolute inset-0 w-full h-full'
    >
      {props.children}
    </div>
  );
}

export function ContainGamePlayMediaLayout(props: {
  children: ReactNode;
  footer?: ReactNode;
  className?: string;
}): JSX.Element {
  const { children, footer, className } = props;

  return (
    <AutoScale contentClassName={`w-full ${className}`}>
      <div className='w-full aspect-w-16 aspect-h-9'>
        <div className='absolute w-full h-full'>{children}</div>
      </div>

      {footer}
    </AutoScale>
  );
}

export function GamePlayMediaLayout(props: {
  fullscreen: boolean;
  hide?: boolean;
  className?: string;
  children?: ReactNode;
}): JSX.Element {
  if (props.fullscreen)
    return (
      <div
        className={`w-full h-full inset-0 absolute ${
          props.hide ? 'hidden' : ''
        }`}
      >
        {props.children}
      </div>
    );
  return (
    <FloatLayout
      className={`${props.hide ? 'hidden' : ''} ${
        props.className ? props.className : ''
      }`}
    >
      {props.children}
    </FloatLayout>
  );
}
