import { useIsController } from '../../../../../hooks/useMyInstance';
import { useBlockTitleAnimInfo } from '../../../hooks';
import { endBlockTitleAnim } from '../../../store';
import { pointsTo2x3xCmp } from '../pointUtils';
import { GamePlayTextTransition } from './GamePlayTextTransition';

export function GamePlayBlockTitleTransition(): JSX.Element | null {
  const info = useBlockTitleAnimInfo();
  const title = info?.extra?.title ?? null;
  const displayPointsMultiplier = info?.extra?.displaysPointsMultiplier ?? null;
  const pointsAnimation = pointsTo2x3xCmp(displayPointsMultiplier);
  const onEnd = useIsController() ? endBlockTitleAnim : null;

  if (!title) return null;
  return (
    <GamePlayTextTransition
      text={title}
      accessory={
        !pointsAnimation ? null : (
          /** NOTE: the width here controls the width of the image indirectly */
          <div className='relative w-76'>
            {pointsAnimation?.({
              className:
                'absolute bottom-4 w-full flex flex-col justify-center items-center',
              showText: false,
              useFloatLayout: false,
            })}
          </div>
        )
      }
      transitionInfo={info}
      onEnd={onEnd}
      debugName='block-title'
    />
  );
}
