import React, {
  type ReactNode,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useLatest, useMountedState } from 'react-use';
import { proxy, useSnapshot } from 'valtio';

import { RTDBServerValueTIMESTAMP } from '@lp-lib/firebase-typesafe';

import { useLiveCallback } from '../../hooks/useLiveCallback';
import logger from '../../logger/logger';
import { type Session, SessionMode, SessionStatus } from '../../types';
import { BrowserIntervalCtrl } from '../../utils/BrowserIntervalCtrl';
import { ValtioUtils } from '../../utils/valtio';
import { useClock } from '../Clock';
import {
  type FirebaseService,
  firebaseService,
  useFirebaseContext,
} from '../Firebase';
import { FirebaseStatus } from '../Firebase/types';

const log = logger.scoped('stream-session');

type State = {
  inited: boolean;
  current: Nullable<Session, false>;
};

function initialState(): State {
  return {
    inited: false,
    current: {
      status: SessionStatus.NotStarted,
      statusChangedAt: 0,
      mode: SessionMode.Live,
      duration: 0,
    },
  };
}

// Only expose specific methods to external callers
type StreamSessionControlAPI = Pick<
  StreamSessionContext,
  'init' | 'prepare' | 'start' | 'stop' | 'abort' | 'resume' | 'reset'
>;

class StreamSessionContext {
  state = proxy(initialState());

  private sessionCurrentRef = this.svc.prefixedRef<Nullable<Session, false>>(
    `session-current/${this.venueId}`
  );
  private sessionHistoryRef = this.svc.prefixedRef<Session>(
    `session-history/${this.venueId}`
  );

  constructor(
    private svc: FirebaseService,
    public readonly venueId: string,
    public recoveryTimeoutMs = 0,
    readonly deps: { now: () => number }
  ) {}

  // NOTE: these are bound because we don't know exactly how they're used.

  init = async (checkAbortRecovery?: boolean) => {
    log.info('init stream session');
    const snapshot = await this.sessionCurrentRef.get();
    const data = snapshot.val();
    log.debug('stream session first time fetched', { data });
    if (data) {
      if (checkAbortRecovery && data.status === SessionStatus.Aborted) {
        data.abortRecoveryDisabled = true;
        this.sessionCurrentRef.update({ abortRecoveryDisabled: true });
      }
      ValtioUtils.update(this.state, { current: data });
    }
    this.sessionCurrentRef.on('value', (snapshot) => {
      const data = snapshot.val();
      log.debug('session-current value_changed', { session: data });
      if (data) ValtioUtils.update(this.state, { current: data });
    });
    log.debug('stream session inited');
    this.state.inited = true;
  };

  prepare = async (sessionId: string) => {
    if (this.state.current?.id) {
      log.warn('stream session already prepared', {
        incoming: sessionId,
        current: this.state.current.id,
      });
    }
    const session: Session = {
      id: sessionId,
      status: SessionStatus.NotStarted,
      statusChangedAt: this.deps.now(),
      mode: SessionMode.Live,
      duration: 0,
    };
    ValtioUtils.update(this.state, { current: session });
    await this.sessionCurrentRef.set(session);
    log.info('prepared stream session', { session });
  };

  start = async (mode: SessionMode) => {
    const now = this.deps.now();
    const session: Session = {
      id: this.state.current?.id,
      status: SessionStatus.Live,
      statusChangedAt: now,
      mode,
      startedAt: now,
      firstStartedAt: now,
      endedAt: null,
      abortedAt: null,
      duration: 0,
      abortRecoveryDisabled: false,
      cleanupAt: null,
    };
    if (!session.id)
      throw new Error('sessionId is not set. Prepare not called?');
    ValtioUtils.update(this.state, { current: session });
    await this.sessionCurrentRef.set(session);
    await this.abortOnDisconnect();
    log.info('started stream session', { session });
  };

  stop = async () => {
    if (
      !this.state.current ||
      this.state.current.status === SessionStatus.Ended
    )
      return;
    const now = this.deps.now();
    this.state.current.status = SessionStatus.Ended;
    this.state.current.statusChangedAt = now;
    this.state.current.endedAt = now;
    this.state.current.abortedAt = null;
    if (this.state.current.startedAt) {
      this.state.current.duration +=
        this.state.current.endedAt - this.state.current.startedAt;
    } else {
      log.warn('unexpected empty timestamp', {
        startedAt: this.state.current.startedAt,
      });
    }
    await this.sessionCurrentRef.set(this.state.current);
    await this.sessionCurrentRef.onDisconnect().cancel((err) => {
      if (err) log.error('session-current onDisconnect.cancel failed', err);
    });
    log.info('stopped stream session', {
      session: ValtioUtils.detachCopy(this.state.current),
    });
    const newSessionHistoryRef = this.sessionHistoryRef.push();
    await newSessionHistoryRef.set(this.state.current);
  };

  abort = async (needRecovery: boolean) => {
    if (this.state.current?.status !== SessionStatus.Live) return;
    const now = this.deps.now();
    this.state.current.status = SessionStatus.Aborted;
    this.state.current.statusChangedAt = now;
    this.state.current.abortRecoveryDisabled = !needRecovery;
    this.state.current.abortedAt = now;
    await this.sessionCurrentRef.set(this.state.current);
    await this.sessionCurrentRef.onDisconnect().cancel((err) => {
      if (err) log.error('session-current onDisconnect.cancel failed', err);
    });
    log.info('aborted stream session', {
      session: ValtioUtils.detachCopy(this.state.current),
    });
  };

  resume = async () => {
    if (this.state.current?.status !== SessionStatus.Aborted) return;
    if (this.state.current.abortedAt && this.state.current.startedAt) {
      this.state.current.duration +=
        this.state.current.abortedAt - this.state.current.startedAt;
    } else {
      log.warn('unexpected empty timestamp', {
        startedAt: this.state.current.startedAt,
        abortedAt: this.state.current.abortedAt,
      });
    }
    const now = this.deps.now();
    this.state.current.status = SessionStatus.Live;
    this.state.current.statusChangedAt = now;
    this.state.current.startedAt = now;
    this.state.current.abortedAt = null;
    this.state.current.abortRecoveryDisabled = false;
    this.state.current.cleanupAt = null;
    await this.sessionCurrentRef.set(this.state.current);
    await this.abortOnDisconnect();
    log.info('resumed stream session', {
      session: ValtioUtils.detachCopy(this.state.current),
    });
  };

  recover = async () => {
    if (this.state.current?.status !== SessionStatus.Aborted) return;
    this.state.current.status = SessionStatus.Live;
    this.state.current.statusChangedAt = this.deps.now();
    this.state.current.abortedAt = null;
    this.state.current.endedAt = null;
    this.state.current.abortRecoveryDisabled = false;
    await this.sessionCurrentRef.set(this.state.current);
    await this.abortOnDisconnect();
    log.info('recovered stream session', {
      session: ValtioUtils.detachCopy(this.state.current),
    });
  };

  disableAbortRecovery = async () => {
    if (this.state.current?.status !== SessionStatus.Aborted) return;
    this.state.current.abortRecoveryDisabled = true;
    await this.sessionCurrentRef.set(this.state.current);
    log.info('disable abort recovery', {
      session: ValtioUtils.detachCopy(this.state.current),
    });
  };

  reset = () => {
    this.sessionCurrentRef.off();
    this.sessionHistoryRef.off();
    ValtioUtils.reset(this.state, initialState());
    log.info('reset stream session', {
      session: ValtioUtils.detachCopy(this.state.current),
    });
  };

  private abortOnDisconnect = async () => {
    await this.sessionCurrentRef.onDisconnect().update(
      {
        status: SessionStatus.Aborted,
        statusChangedAt: RTDBServerValueTIMESTAMP,
        abortedAt: RTDBServerValueTIMESTAMP,
        abortRecoveryDisabled: false,
      },
      (err) => {
        if (err) log.error('session-current onDisconnect.update failed', err);
      }
    );
  };
}

const Context = React.createContext<StreamSessionContext | null>(null);

function useStreamSessionContext(): StreamSessionContext {
  const ctx = useContext(Context);
  if (!ctx) {
    throw new Error('StreamSessionContext is not in the tree!');
  }
  return ctx;
}

export function useStreamSessionControlAPI(): StreamSessionControlAPI {
  // TODO: it's better to raise the error if the current participant is not host.
  return useStreamSessionContext();
}

function useInternalStreamSession(): Nullable<Session, false> {
  const ctx = useStreamSessionContext();
  return useSnapshot(ctx.state).current;
}

export function useStreamSession(): Nullable<Omit<Session, 'status'>, false> {
  const ctx = useStreamSessionContext();
  const session = useSnapshot(ctx.state).current;
  if (!session) return null;
  const { status, ...rest } = session;
  return rest;
}

function useIsStreamRecoverable(): boolean {
  const { recoveryTimeoutMs } = useStreamSessionContext();
  const session = useInternalStreamSession();
  const [, setChecking] = useState(false);
  const recoverable =
    session?.status === SessionStatus.Aborted &&
    !session.abortRecoveryDisabled &&
    !!session.abortedAt &&
    Date.now() - session.abortedAt < recoveryTimeoutMs;

  // Force trigger rendering after recoveryTimeout
  useEffect(() => {
    if (session?.status !== SessionStatus.Aborted) return;
    setChecking(true);
    const timer = setTimeout(() => {
      setChecking(false);
    }, recoveryTimeoutMs);
    return () => {
      clearTimeout(timer);
      setChecking(false);
    };
  }, [recoveryTimeoutMs, session?.status]);
  return recoverable;
}

export function useStreamSessionStatus(): SessionStatus | null {
  const ctx = useStreamSessionContext();
  const session = useSnapshot(ctx.state).current;
  const recoverable = useIsStreamRecoverable();
  if (!session?.status) return null;
  if (recoverable) return SessionStatus.Live;
  return session.status;
}

export function useStreamSessionId(): string | undefined {
  const ctx = useStreamSessionContext();
  return useSnapshot(ctx.state).current?.id;
}

export function useGetStreamSessionId(): () => string | undefined {
  const ctx = useStreamSessionContext();
  return useLiveCallback(() => ctx.state.current?.id);
}

/**
 * Only return the sessionId if the stream is considered alive. A stream could
 * be in a "prepared" state, where there is a sessionId present but the state
 * has never moved to LIVE yet.
 */
export function useStreamSessionIdAliveOrAborted(): string | undefined {
  const status = useIsStreamSessionAliveOrAborted();
  const id = useStreamSessionId();
  return status ? id : undefined;
}

export function useIsStreamSessionInited(): boolean {
  const ctx = useStreamSessionContext();
  return useSnapshot(ctx.state).inited;
}

export function useIsStreamSessionAlive(): boolean {
  const sessionStatus = useStreamSessionStatus();
  return sessionStatus === SessionStatus.Live;
}

export function useIsStreamSessionAliveOrAborted(): boolean {
  const sessionStatus = useStreamSessionStatus();
  return (
    sessionStatus === SessionStatus.Live ||
    sessionStatus === SessionStatus.Aborted
  );
}

export function useIsStreamSessionAborted(): boolean {
  const sessionStatus = useStreamSessionStatus();
  return sessionStatus === SessionStatus.Aborted;
}

export function useIsStreamSessionEnded(): [boolean, number] {
  const session = useStreamSession();
  const sessionStatus = useStreamSessionStatus();
  return [sessionStatus === SessionStatus.Ended, session?.endedAt ?? 0];
}

export function useIsStreamStartable(): boolean {
  const sessionStatus = useStreamSessionStatus();
  return (
    !!sessionStatus &&
    (sessionStatus === SessionStatus.NotStarted ||
      sessionStatus === SessionStatus.Ended)
  );
}

const calElapsedTime = (startTime: number, stopTime: number): number => {
  return Math.max(0, stopTime - startTime);
};

export function useStreamSessionElapsedTimeMs(): number {
  const isMounted = useMountedState();
  const [elapsedTime, setElapsedTime] = useState<number>(0);
  const timerIdRef = useRef<number>(0);
  const session = useStreamSession();
  const startedAt = session?.startedAt ?? 0;
  const abortedAt = session?.abortedAt ?? 0;
  const duration = session?.duration ?? 0;
  const sessionStatus = useStreamSessionStatus();

  useEffect(() => {
    if (sessionStatus === SessionStatus.Live) {
      const run = (): void => {
        if (!isMounted()) return;
        setElapsedTime(calElapsedTime(startedAt, Date.now() + duration));
        clearTimeout(timerIdRef.current);
        timerIdRef.current = window.setTimeout(run, 1000);
      };
      run();
    } else if (
      sessionStatus === SessionStatus.Aborted &&
      startedAt &&
      abortedAt
    ) {
      setElapsedTime(calElapsedTime(startedAt, abortedAt + duration));
    } else {
      setElapsedTime(0);
      clearInterval(timerIdRef.current);
    }
  }, [abortedAt, duration, isMounted, sessionStatus, startedAt]);
  return elapsedTime;
}

export function useStreamSessionRecovery(
  enabled: boolean,
  disableOnLoad = false
): void {
  const { recover, recoveryTimeoutMs, disableAbortRecovery } =
    useStreamSessionContext();
  const session = useInternalStreamSession();
  const firebaseStatus = useLatest(useFirebaseContext());
  const [inited, setInited] = useState(false);
  const sessionInited = useIsStreamSessionInited();
  const clock = useClock();

  // In live game, if the session is aborted, we don't want recover the session
  // automatically right after the host opens the page. He should manually click
  // the resume button.
  useEffect(() => {
    if (!enabled || !sessionInited || inited) return;
    if (disableOnLoad) {
      disableAbortRecovery().finally(() => setInited(true));
    } else {
      setInited(true);
    }
  }, [disableAbortRecovery, disableOnLoad, enabled, inited, sessionInited]);

  // RTDBServerValueTIMESTAMP will change the value twice.
  const abortedAt = useLatest(session?.abortedAt);

  useEffect(() => {
    if (
      !enabled ||
      !inited ||
      session?.status !== SessionStatus.Aborted ||
      !!session.abortRecoveryDisabled
    ) {
      return;
    }
    const ctrl = new BrowserIntervalCtrl();
    ctrl.set(async () => {
      const elapsedMs = clock.now() - (abortedAt.current ?? 0);
      if (elapsedMs > recoveryTimeoutMs) {
        log.warn('stream session recovery timeout', {
          now: clock.now(),
          abortedAt: abortedAt.current ?? 0,
          recoveryTimeoutMs,
          elapsedMs,
        });
        ctrl.clear();
      }
      if (firebaseStatus.current.status === FirebaseStatus.Connected) {
        ctrl.clear();
        await recover();
      }
    }, 100);
    return () => ctrl.clear();
  }, [
    abortedAt,
    clock,
    enabled,
    firebaseStatus,
    inited,
    recover,
    recoveryTimeoutMs,
    session?.abortRecoveryDisabled,
    session?.status,
  ]);
}

export const StreamSessionProvider = (props: {
  venueId: string;
  recoveryTimeoutMs: number;
  children?: ReactNode;
}): JSX.Element | null => {
  const { venueId } = props;
  const clock = useClock();
  const [ctrl, setCtrl] = useState(
    () =>
      new StreamSessionContext(
        firebaseService,
        venueId,
        props.recoveryTimeoutMs,
        { now: clock.now.bind(clock) }
      )
  );

  useEffect(() => {
    if (ctrl.venueId !== venueId) {
      ctrl.reset();
      const next = new StreamSessionContext(firebaseService, venueId, 0, {
        now: clock.now.bind(clock),
      });
      setCtrl(next);
    }
  }, [clock, ctrl, venueId]);

  useEffect(() => {
    ctrl.recoveryTimeoutMs = props.recoveryTimeoutMs;
  }, [ctrl, props.recoveryTimeoutMs]);

  // reset
  useEffect(() => {
    return () => ctrl.reset();
  }, [ctrl]);

  return <Context.Provider value={ctrl}>{props.children}</Context.Provider>;
};
