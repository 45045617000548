export function InstructionBlockIcon(
  props: React.SVGProps<SVGSVGElement>
): JSX.Element {
  return (
    <svg
      className={props.className ?? 'w-3.5 h-3.5 fill-[#7BC0FF]'}
      fill='none'
      viewBox='0 0 14 14'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path d='M12.645 10.5a.215.215 0 01-.066.155.23.23 0 01-.16.064H9.71a3.437 3.437 0 00-2.032.656v-7c0-1.087.91-1.969 2.033-1.969h1.58V1.094H9.71c-1.067 0-2.07.486-2.71 1.312a3.424 3.424 0 00-2.71-1.312H0V10.5c0 .846.707 1.531 1.58 1.531h2.71c1.122 0 2.033.882 2.033 1.97h1.354c0-1.088.91-1.97 2.033-1.97h2.71c.873 0 1.58-.685 1.58-1.53V8.093h-1.355V10.5zm-8.355.219H1.58a.223.223 0 01-.225-.219V2.406H4.29c1.122 0 2.033.882 2.033 1.969v7a3.443 3.443 0 00-2.033-.656z'></path>
      <path d='M12.646 2.625H14v3.938h-1.354V2.625zM14 .656c0 .875-1.354.875-1.354 0S14-.219 14 .656z'></path>
    </svg>
  );
}
