import isEqual from 'lodash/isEqual';

import {
  EnumsMediaScene,
  EnumsSpotlightBlockStageLayout,
  type ModelsTTSScript,
} from '@lp-lib/api-service-client/public';
import {
  type BlockFields,
  type SpotlightBlock,
  type SpotlightBlockMedia,
  type VoiceOver,
} from '@lp-lib/game';

import { useLiveCallback } from '../../../../hooks/useLiveCallback';
import { uuidv4 } from '../../../../utils/common';
import type { TagQuery } from '../../../../utils/TagQuery';
import { useAwaitFullScreenConfirmCancelModal } from '../../../ConfirmCancelModalContext';
import {
  defaultTTSOptions,
  TTSOptionEditor,
} from '../../../VoiceOver/TTSOptionEditor';
import {
  type ScriptScenarioOption,
  TTSScriptsEditor,
} from '../../../VoiceOver/TTSScriptsEditor';
import { VoiceOverUtils } from '../../../VoiceOver/utils';
import {
  hasLLMCodeFences,
  hasVariables,
} from '../../../VoiceOver/VariableRegistry';
import { VoiceOverEditor } from '../../../VoiceOver/VoiceOverEditor';
import { makeExampleVariableRegistrySnapshot } from '../../hooks/useCreateGameInfoSnapshot';
import {
  FieldContainer,
  FieldDescription,
  FieldPanel,
  FieldTitle,
  SimpleFieldEditor,
} from '../Common/Editor/FieldEditorUtilities';
import {
  AdditionalSettings,
  AdditionalSharedSettingsEditor,
  BlockMediaEditor,
  BlockMediaUploader,
  type EditorProps,
  type Option,
  RHFCheckbox,
  RHFSelectField,
  useEditor,
} from '../Common/Editor/Internal';
import { SpotlightPreSelectedTeamOrder } from './types';

const stageLayoutOptions: (Option & { description: string })[] = [
  {
    label: 'Center',
    value: EnumsSpotlightBlockStageLayout.SpotlightBlockStageLayoutCenter,
    description:
      'Players on stage are centered in the middle of the screen. When a cohost is present, they are not visible.',
  },
  {
    label: 'Interview',
    value: EnumsSpotlightBlockStageLayout.SpotlightBlockStageLayoutInterview,
    description:
      'Players on stage are positioned to the right for an interview. When a cohost is present, they are visible to the left.',
  },
];

const preSelectedTeamOrderOptions: (Option & { description: string })[] = [
  {
    label: 'None',
    value: SpotlightPreSelectedTeamOrder.None,
    description:
      'No preselection. Coordinator will be prompted when the game is started to select a player(s) to spotlight.',
  },
  {
    label: '1st Place Team',
    value: SpotlightPreSelectedTeamOrder.FirstPlaceTeam,
    description: 'Spotlights the team currently in first place.',
  },
  {
    label: '2nd Place Team',
    value: SpotlightPreSelectedTeamOrder.SecondPlaceTeam,
    description: 'Spotlights the team currently in second place.',
  },
  {
    label: '3rd Place Team',
    value: SpotlightPreSelectedTeamOrder.ThirdPlaceTeam,
    description: 'Spotlights the team currently in third place.',
  },
  {
    label: 'Last Place Team',
    value: SpotlightPreSelectedTeamOrder.LastPlaceTeam,
    description: 'Spotlights the team currently in last place.',
  },
  {
    label: 'Optimized Selection',
    value: SpotlightPreSelectedTeamOrder.SystemSelected,
    description:
      'Defers selection to the system. The system will decide which team to spotlight.',
  },
  {
    label: 'Coordinator',
    value: SpotlightPreSelectedTeamOrder.Coordinator,
    description: 'Brings the coordinator on stage.',
  },
];

const exampleVariableRegistry = makeExampleVariableRegistrySnapshot();
const spotlightScriptScenarioOptions: ScriptScenarioOption[] = [
  {
    label: 'Preferred',
    description:
      'The preferred scripts to use during playback. A script is chosen at random and played.',
    tags: ['runtime'],
    supportedVariables: exampleVariableRegistry,
    templateRenderer: exampleVariableRegistry,
  },
  {
    label: 'Fallback',
    description:
      'Used in case a preferred script cannot be rendered in time. These scripts should not use variables.',
    tags: ['fallback'],
  },
];

export function SpotlightBlockEditor(
  props: EditorProps<SpotlightBlock>
): JSX.Element | null {
  const { block } = props;
  const { updateFieldLocalFirst: updateField } = useEditor(props);
  const triggerModal = useAwaitFullScreenConfirmCancelModal();

  const handleVoiceOverChange = useLiveCallback(
    (voiceOver: VoiceOver | null) => {
      updateField('voiceOver', {
        ...block.fields.voiceOver,
        ...voiceOver,
      });
    }
  );

  const handleTTSOptionsChange = useLiveCallback(
    (value: BlockFields<SpotlightBlock>['ttsOptions']) => {
      updateField('ttsOptions', value);
    }
  );

  const handleAddTTSScript = useLiveCallback(async (value: ModelsTTSScript) => {
    await updateField('ttsScripts', [
      ...(props.block.fields.ttsScripts ?? []),
      value,
    ]);
  });

  const handleRemoveTTSScript = useLiveCallback(
    async (value: ModelsTTSScript) => {
      const nextTTSScripts = (props.block.fields.ttsScripts ?? []).filter(
        (s) => s.id !== value.id
      );
      await updateField('ttsScripts', nextTTSScripts);
    }
  );

  const handleChangeTTSScript = useLiveCallback(
    async (prev: ModelsTTSScript, next: ModelsTTSScript) => {
      const nextTTSScripts = [...(props.block.fields.ttsScripts ?? [])];
      const index = nextTTSScripts.findIndex((s) => s.id === prev.id);
      if (index === -1) return;

      const hasChanged = !isEqual(prev, next);
      if (hasChanged) {
        // only update the field if there's a change.
        nextTTSScripts[index] = next;
        await updateField('ttsScripts', nextTTSScripts);
      }
    }
  );

  const handleUploadTTSScripts = useLiveCallback(
    async (nextTTSScripts: ModelsTTSScript[]) => {
      await updateField('ttsScripts', nextTTSScripts);
    }
  );

  const handleMigrate = useLiveCallback(async () => {
    const voiceOver = props.block.fields.voiceOver;
    if (!voiceOver) return;

    if (voiceOver.fallback?.media) {
      const hasFallbackDescription = !!voiceOver.fallback.renderDescription;
      if (hasFallbackDescription) {
        const resp = await triggerModal({
          kind: 'confirm-cancel',
          prompt: (
            <div className='p-5 text-white text-center'>
              <p className='text-2xl font-medium'>Are you sure?</p>
              <p className='mt-4 text-sms'>
                The current voice over configuration has fallback media.
                However, there is a script that was used to generate the media
                that can be ported to the new voice over settings.
              </p>
              <p className='mt-4 text-sms text-tertiary'>
                {voiceOver.fallback.renderDescription?.script}
              </p>
              <p className='mt-4 text-sms'>
                We will no longer use the media, but can copy over the script.
              </p>
            </div>
          ),
          confirmBtnLabel: 'Continue',
          cancelBtnLabel: 'Cancel',
        });
        if (resp.result === 'canceled') return;
      } else {
        await triggerModal({
          kind: 'confirm-cancel',
          prompt: (
            <div className='p-5 text-white text-center'>
              <p className='text-2xl font-medium'>Are you sure?</p>
              <p className='mt-4 text-sms'>
                The current voice over configuration has fallback media, but
                there is no script that describes how the media was generated.
                We no longer support this moving forward. Please either remove
                the media or provide a fallback script.
              </p>
            </div>
          ),
          confirmBtnLabel: 'Ok',
          confirmOnly: true,
        });
        return;
      }
    }

    const nextTTSScripts: ModelsTTSScript[] = [];
    if (voiceOver.runtime) {
      nextTTSScripts.push({
        id: uuidv4(),
        script: voiceOver.runtime.script,
        tags: ['runtime'],
      });
    }
    if (voiceOver.fallback?.renderDescription) {
      nextTTSScripts.push({
        id: uuidv4(),
        script: voiceOver.fallback.renderDescription.script,
        tags: ['fallback'],
      });
    }

    await updateField('ttsScripts', nextTTSScripts);
    await updateField('voiceOver', {});
    if (
      !props.block.fields.ttsOptions ||
      props.block.fields.ttsOptions.length === 0
    ) {
      await updateField('ttsOptions', defaultTTSOptions);
    }
  });

  const checkForFallbacks = useLiveCallback(
    (query: TagQuery<ModelsTTSScript>) => {
      const needsFallbacks = query
        .select(['runtime'])
        .some((s) => hasVariables(s.script) || hasLLMCodeFences(s.script));

      const ok = !needsFallbacks || query.select(['fallback']).length > 0;
      if (ok) return { ok };
      return {
        ok,
        message:
          'At least one of your preferred scripts uses variables. Define a fallback script to be used in case of failure.',
      };
    }
  );

  const hasLegacyVoiceOvers = VoiceOverUtils.HasAtLeastOneConfig(
    props.block.fields.voiceOver
  );

  const currentPreselection = preSelectedTeamOrderOptions.find(
    (option) => option.value === props.block.fields.preselectedTeamOrder
  );

  const currentLayout =
    stageLayoutOptions.find(
      (option) => option.value === props.block.fields.stageLayout
    ) ?? stageLayoutOptions[0];

  return (
    <div className='w-full'>
      <h2 className='text-2xl text-white'>Spotlight!</h2>
      <div className='pt-5 space-y-3'>
        <FieldContainer
          styles={{
            layout: 'flex flex-col gap-5',
          }}
        >
          <div className='flex gap-4'>
            <label htmlFor='message' className='w-1/2'>
              <div className='text-white font-bold text-base mb-2'>Message</div>
              <input
                className='field h-10 mb-0'
                defaultValue={block.fields.message}
                onBlur={(e) => {
                  if (e.target.value.length > 30) return;
                  updateField('message', e.target.value);
                }}
                maxLength={30}
                placeholder='Max 30 characters'
              />
              <div className='text-secondary text-2xs mt-1'>
                Use %Name% to dynamically input the Username(s) of the
                person/people on stage. e.g. “Happy Birthday %Name%” will show
                “Happy Birthday Ben” if user Ben is on stage.
              </div>
            </label>

            <label htmlFor='decreasingPointsTimer' className='w-1/2'>
              <RHFCheckbox<SpotlightBlock>
                label='Play Confetti'
                name='playConfetti'
                value={block.fields.playConfetti}
                onChange={(_, checked: boolean): void => {
                  updateField('playConfetti', checked);
                }}
                description={
                  'Play confetti animation when starting celebration.'
                }
              />
            </label>
          </div>
        </FieldContainer>
        <SimpleFieldEditor
          name='Players to Spotlight'
          description={
            <>
              Determines which participant(s) or team should be spotlighted.
              {currentPreselection && (
                <div className='pt-2'>
                  <strong>{currentPreselection.label}:</strong>{' '}
                  {currentPreselection.description}
                </div>
              )}
            </>
          }
        >
          <RHFSelectField<SpotlightBlock>
            className='w-full h-10 text-white'
            name='preselectedTeamOrder'
            options={preSelectedTeamOrderOptions}
            value={block.fields.preselectedTeamOrder}
            onChange={(_, value) => {
              if (typeof value !== 'number') return;
              updateField('preselectedTeamOrder', value);
            }}
          />
        </SimpleFieldEditor>
        <SimpleFieldEditor
          name='Stage Layout'
          description={
            <>
              Determines how the spotlighted players are positioned on screen.
              When the cohost is the spotlighted player, this setting is
              ignored.
              {currentLayout && (
                <div className='pt-2'>
                  <strong>{currentLayout.label}:</strong>{' '}
                  {currentLayout.description}
                </div>
              )}
            </>
          }
        >
          <RHFSelectField<SpotlightBlock>
            className='w-full h-10 text-white'
            name='stageLayout'
            options={stageLayoutOptions}
            value={
              block.fields.stageLayout ??
              EnumsSpotlightBlockStageLayout.SpotlightBlockStageLayoutCenter
            }
            onChange={(_, value) => {
              if (typeof value !== 'string') return;
              updateField(
                'stageLayout',
                value as EnumsSpotlightBlockStageLayout
              );
            }}
          />
        </SimpleFieldEditor>
        <SimpleFieldEditor
          name='Background Media'
          description={
            'Media will display to the audience when the block is presented.'
          }
        >
          <BlockMediaEditor<SpotlightBlockMedia>
            blockId={props.block.id}
            title={<span />}
            field='backgroundMedia'
            video={true}
            scene={EnumsMediaScene.MediaSceneBlockBackground}
            volumeSelectable
            mediaData={block.fields.backgroundMediaData}
            media={block.fields.backgroundMedia}
            width='w-full'
          />
        </SimpleFieldEditor>
        <SimpleFieldEditor
          name='Overlay Media'
          description={
            'Media will display to the audience when the block is presented.'
          }
        >
          <BlockMediaUploader<SpotlightBlockMedia>
            blockId={props.block.id}
            title={<span />}
            field='overlayMedia'
            video={true}
            scene={EnumsMediaScene.MediaSceneBlockMedia}
            media={block.fields.overlayMedia}
            extraNotice=''
            width='w-full'
          />
        </SimpleFieldEditor>
        {hasLegacyVoiceOvers ? (
          <FieldContainer styles={{ layout: 'flex flex-col gap-4' }}>
            <FieldPanel>
              <FieldTitle>Host Scripts</FieldTitle>
              <FieldDescription>
                <div className='w-full flex gap-4 justify-between items-center'>
                  <div className='text-tertiary'>
                    The current configuration of this block is using deprecated
                    voice over settings. Please migrate to the new settings.
                  </div>
                  <button
                    type='button'
                    className='w-40 h-10 btn-secondary'
                    onClick={handleMigrate}
                  >
                    Migrate
                  </button>
                </div>
              </FieldDescription>
            </FieldPanel>
            <div className='w-full'>
              <VoiceOverEditor
                id={block.id}
                voiceOver={block.fields.voiceOver}
                onChange={handleVoiceOverChange}
                bottomAccessory={
                  <div className='text-icon-gray text-sms'>
                    Voice over audio. If uploaded, audio will start immediately
                    with the background media.
                  </div>
                }
                previewMedia={block.fields.backgroundMedia}
                previewLabel='Background Media'
              />
            </div>
          </FieldContainer>
        ) : (
          <TTSScriptsEditor
            title='Host Scripts'
            description={
              <>
                When specified, the AI host will read one of these scripts when
                the spotlight is presented.
              </>
            }
            value={props.block.fields.ttsScripts}
            scenarioOptions={spotlightScriptScenarioOptions}
            onAdd={handleAddTTSScript}
            onRemove={handleRemoveTTSScript}
            onChange={handleChangeTTSScript}
            onUpload={handleUploadTTSScripts}
            ttsRenderSettings={props.block.fields.ttsOptions?.[0]}
            csvDownloadName={`spotlight-host-scripts-${props.block.id}.csv`}
            checkForFallbacks={checkForFallbacks}
          />
        )}
      </div>
      <AdditionalSettings>
        <AdditionalSharedSettingsEditor {...props} />
        <TTSOptionEditor
          value={props.block.fields.ttsOptions}
          onChange={handleTTSOptionsChange}
        />
      </AdditionalSettings>
    </div>
  );
}
