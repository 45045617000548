import pluralize from 'pluralize';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useMountedState } from 'react-use';

import { useVenueMode } from '../../hooks/useVenueMode';
import { VenueMode } from '../../types';
import { type Option, Select } from '../common/Utilities';
import { usePlayedBlockIds } from '../Game/hooks';
import { ArrowLeftIcon } from '../icons/Arrows';
import { ShuffleIcon } from '../icons/ShuffleIcon';
import { HollowSquareIcon } from '../icons/SquareIcon';
import { useLobbyAPI } from '../Lobby';
import { useParticipantsAsArray } from '../Player';
import { useTeams } from '../TeamAPI/TeamV1';
import { useTeamSizeContext } from '../TeamSizeControl/Context';
import {
  useTeamRandomizerAPI,
  useTeamRandomizerStepDetail,
  useTeamRandomizerTask,
} from './Context';
import { random } from './randomizer';
import { ToolStep } from './types';
import { isTaskAtStep } from './utils';

const ConfirmModal = (props: {
  onCancel: () => void;
  onProcess: () => void;
}): JSX.Element => {
  return (
    <div className='absolute top-0 left-0 w-full h-full z-5 flex items-center justify-center px-2 bg-lp-black-002'>
      <div className='border border-white bg-black rounded-xl p-2'>
        <p className='text-white text-base text-center font-bold my-6'>
          Are you sure you want to randomize Teams? All scores will be reset to
          0.
        </p>
        <div className='flex'>
          <button
            className='btn-secondary w-1/2 h-10 mx-1'
            onClick={props.onCancel}
          >
            Cancel
          </button>
          <button
            className='btn-primary w-1/2 h-10 mx-1'
            onClick={props.onProcess}
          >
            Process
          </button>
        </div>
      </div>
    </div>
  );
};

const Randomizer = (props: { handleBack: () => void }): JSX.Element => {
  const detail = useTeamRandomizerStepDetail();
  const { randomize } = useTeamRandomizerAPI();
  const [showModal, setShowModal] = useState(false);
  const players = useParticipantsAsArray({
    filters: ['host:false', 'cohost:false', 'status:connected', 'staff:false'],
  });
  const [formation, setFormation] = useState<string[][]>([]);
  const isMounted = useMountedState();
  const hasScoreboard = usePlayedBlockIds().length > 0;
  const { maxMembers } = useTeamSizeContext();
  const venueMode = useVenueMode();
  const lobbyMode = venueMode === VenueMode.Lobby;
  const lobbyAPI = useLobbyAPI();

  const options = useMemo(
    () =>
      [...Array(maxMembers)].map((_, i) => ({
        label: `${i + 1}`,
        value: i + 1,
      })),
    [maxMembers]
  );
  const [activeOption, setActiveOption] = useState<Option<number>>(options[0]);

  useEffect(() => {
    if (options.length > 2) {
      setActiveOption(options[2]);
    } else {
      setActiveOption(options[options.length - 1]);
    }
  }, [options]);

  useEffect(() => {
    if (!isMounted()) return;
    const clientIds = players.map((p) => p.clientId);
    setFormation(random<string>(clientIds, activeOption.value, maxMembers));
  }, [players, activeOption, isMounted, maxMembers]);

  useEffect(() => {
    if (!detail || !lobbyMode) return;
    if (detail.step === 'randomize') {
      lobbyAPI.hide(true);
    } else if (detail.step === 'results') {
      lobbyAPI.hide(false);
    }
  }, [detail, lobbyAPI, lobbyMode]);

  const handleOptionChange = (option: Option<number>) => {
    setActiveOption(option);
  };

  const handleRandomize = () => {
    if (hasScoreboard) {
      setShowModal(true);
    } else {
      handleProcess();
    }
  };

  const handleProcess = async () => {
    setShowModal(false);
    await randomize({
      step: 'countdown',
      targetTeamSize: activeOption.value,
      maxTeamSize: maxMembers,
      notificationStyle: 'notification',
      showIcebreakerTimer: false,
      showResults: !lobbyMode,
      showAnimation: true,
    });
  };

  return (
    <div className='w-full h-full flex flex-col'>
      <header
        className={`text-white text-xs flex items-center p-4 ${
          detail?.taskId ? 'cursor-not-allowed' : 'cursor-pointer'
        }`}
        onClick={props.handleBack}
      >
        {!detail?.taskId && <ArrowLeftIcon />}
        <p className='ml-2'>Randomizing everyone</p>
      </header>
      <div className='w-full h-full flex flex-col items-center justify-center text-white relative'>
        <div className='px-4 flex flex-col items-center justify-center'>
          <div className='text-base font-bold text-center mb-8'>
            Target number of Players per Team
          </div>
          <div className='w-15'>
            <Select
              options={options}
              onChange={handleOptionChange}
              value={activeOption}
              disabled={!!detail}
              optionIdPrefix='randomizer-team-size'
            />
          </div>
          <div className='text-icon-gray text-3xs text-center mt-4'>
            Players will be distributed into Teams as close and evenly as
            possible to your target number. Some Teams may have +/- 1 Player.
          </div>
          <div className='mt-30 mb-6'>
            <div className='text-icon-gray text-3xs text-center font-medium'>
              Randomizing Teams will reset all scores
            </div>
            <div className='text-white text-3xs text-center font-medium mt-6'>
              <span className='font-bold'>{formation.length}</span> Teams will
              be created based on
              <br />
              <span className='font-bold'>{players.length}</span> Players to
              randomize
            </div>
          </div>
        </div>
        {showModal && (
          <ConfirmModal
            onCancel={() => setShowModal(false)}
            onProcess={handleProcess}
          />
        )}
        <div className='absolute bottom-2 w-full flex px-2'>
          {!detail && (
            <button
              id='randomizer-start-button'
              className='btn-primary pl-3 w-full h-10 flex items-center justify-start text-sms font-medium'
              disabled={formation.length === 0}
              onClick={handleRandomize}
            >
              <ShuffleIcon className='w-4 h-4 fill-current' />
              <p className='ml-2'>
                {formation.length === 0
                  ? 'No teams will be created'
                  : 'Randomize Teams'}
              </p>
            </button>
          )}
          {detail?.step === 'countdown' && (
            <div className='pl-3 w-full h-10 flex items-center justify-start text-sms font-medium bg-black rounded-lg'>
              <ShuffleIcon className='w-4 h-4 fill-current' />
              <p className='ml-2'>Randomize Teams ({detail.timestep})</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const ShowResult = (props: { handleClose: () => void }): JSX.Element => {
  const { cleanup } = useTeamRandomizerAPI();
  const teams = useTeams({ sort: true, updateStaffTeam: true });

  const handleClose = () => {
    cleanup();
    props.handleClose();
  };
  return (
    <div className='w-full h-full flex flex-col items-center justify-start text-white relative'>
      <div className='px-4 flex flex-col items-center justify-center w-full h-full'>
        <div className='flex flex-col w-full items-center justify-center h-1/3'>
          <div className='text-5xl'>🎉</div>
          <div className='text-base font-bold mt-3'>
            {teams.length} {pluralize('Team', teams.length)} Created!
          </div>
        </div>
        <div className='flex flex-col w-full h-2/3 overflow-y-auto scrollbar mb-20'>
          {teams.map((t) => (
            <div key={t.id} className='flex justify-between text-sm'>
              <div>{t.name}</div>
              <div>
                {t.membersCount} {pluralize('Player', t.membersCount)}
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className='absolute bottom-2 w-full flex flex-col px-2'>
        <div className='text-3xs text-secondary text-center px-4 py-2'>
          *Teams with an asterisk already existed and were not randomized
        </div>
        <button
          id='randomizer-close-button'
          className='btn-primary pl-3 w-full h-10 flex items-center justify-start text-sms font-medium'
          onClick={handleClose}
        >
          <HollowSquareIcon />
          <p className='ml-2'>Close Team Randomizer</p>
        </button>
      </div>
    </div>
  );
};

interface TeamRandomizerProps {
  handleClose: () => void;
}

export const TeamRandomizer = ({
  handleClose,
}: TeamRandomizerProps): JSX.Element => {
  const task = useTeamRandomizerTask();
  const { cleanup } = useTeamRandomizerAPI();
  const [toolStep, setToolStep] = useState<ToolStep>(ToolStep.Randomizer);
  const isShowResultStep = isTaskAtStep(task, 'results');

  useEffect(() => {
    return () => {
      cleanup();
    };
  }, [cleanup]);

  useEffect(() => {
    if (!isShowResultStep) return;
    setToolStep(ToolStep.ShowResult);
  }, [isShowResultStep]);

  const handleBack = useCallback(() => {
    if (task?.id) return;
    if (toolStep > ToolStep.Randomizer) {
      setToolStep(toolStep - 1);
    } else {
      handleClose();
    }
  }, [handleClose, task?.id, toolStep]);

  if (toolStep === ToolStep.Randomizer) {
    return <Randomizer handleBack={handleBack} />;
  } else if (toolStep === ToolStep.ShowResult) {
    return <ShowResult handleClose={handleClose} />;
  } else {
    return <></>;
  }
};
