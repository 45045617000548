import { ModalWrapper } from '../ConfirmCancelModalContext/ModalWrapper';

export function BringTeamModal(props: {
  onComplete: () => void;
  onClose: () => void;
}): JSX.Element {
  return (
    <ModalWrapper containerClassName='w-80' borderStyle='gray'>
      <div className='flex flex-col w-full h-full p-8 items-center justify-center'>
        <header className='text-white text-2xl text-center'>
          Do you want to clear the stage?
        </header>
        <div className='text-white text-sms text-center my-4'>
          There’s not enough room on stage! By clicking continue, you’ll remove
          everyone currently on the stage in order to add this team.
        </div>
        <div className='flex items-center gap-4'>
          <button
            className='btn-secondary w-30 h-10 mx-1'
            onClick={props.onClose}
          >
            Cancel
          </button>
          <button
            className='btn-primary w-30 h-10 mx-1'
            onClick={props.onComplete}
          >
            Continue
          </button>
        </div>
      </div>
    </ModalWrapper>
  );
}
