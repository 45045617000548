import { type RTDBServerValueTIMESTAMP } from '@lp-lib/firebase-typesafe';
import { type ConnectionStatus } from '@lp-lib/shared-schema';

export const FIREBASE_PREFIX = 'ond-game';

export type OndGameCommand =
  | 'prepareGame'
  | 'startGame'
  | 'pauseGame'
  | 'skipPreGameHostedTutorial'
  | 'resumeGame'
  | 'resetGame';

export type ControllerKind = 'local' | 'cloud';

export type OnDGameController = {
  id: string;
  kind: ControllerKind;
  acquiredAt: number | RTDBServerValueTIMESTAMP;
  pingedAt: number | RTDBServerValueTIMESTAMP;
  env: string;
  status: ConnectionStatus;
  disconnectedAt?: number | RTDBServerValueTIMESTAMP;
  disconnectedReason?: string;
};

export type CloudHostingConfig = {
  launchTimeout: number;
  heartbeatInterval: number;
  heartbeatTimeout: number;
  getTimeout: number;
  healthCheckInternal: number;
  coordinator: {
    callTimeout: number;
    checkInterval: number;
  };
};
