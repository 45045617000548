import {
  SCOREBOARD_MODE_DEFAULT,
  type ScoreboardBlock,
  type ScoreboardMode,
} from '@lp-lib/game';

import { useGameSessionBlock } from '../../hooks';

export const useScoreboardMode = (): ScoreboardMode => {
  const gameSessionBlock = useGameSessionBlock() as ScoreboardBlock;

  if (gameSessionBlock?.fields.mode) {
    return gameSessionBlock?.fields.mode as ScoreboardMode;
  }
  return SCOREBOARD_MODE_DEFAULT;
};
